import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs';
import { Pagination } from 'src/app/entities/boxx-response';
import { TooltipV2Model } from 'src/app/models/tooltip.V2.model ';
import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import { FormBuilder, FormGroup } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import {
  DOMAIN_CODE_PENDINGINFOREASON,
  DOMAIN_CODE_QUOTEFLOWSTATUS,
  SUB_DOMAIN_CODE_QUOTEFLOWSTATUS_PENDINGINFO,
  SUB_DOMAIN_CODE_REASONTYPE_PENDINGINFO,
  SUBDOMAINCODE_QUOTEFLOWSTATUS_TOREVIEW,
} from 'src/app/constants/quote-constant';
import {
  QUOTEFLOWSTATUS_APPROVED,
  QUOTEFLOWSTATUS_PREQUOTE,
  QUOTEFLOWSTATUS_TOREVIEW,
  QuoteStatus,
} from 'src/app/constants/quoteStatus';
import { DropdownListDto } from 'src/app/dtos/dropdownList.dto';
import { DomainsService } from 'src/app/services/domains.service';
import { PolicyRiskTrxQuoteFlowStatusService } from 'src/app/services/policy-risk-trx-quote-flow-status.service';
import { SharedFunctionService } from 'src/app/services/shared/shared-function.service';
import {
  getErrorMessage,
  getPositionToDisplayPopup,
} from 'src/app/utils/utils';
import { PolicyPeriodService } from 'src/app/services/policy-period.service';
import { NewQuoteService } from 'src/app/services/new-quote.service';
import { PolicyRiskTrxService } from 'src/app/services/policy-risk-trx.service';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import { USER_ROLES } from 'src/app/constants/security/systemUserType';
import { AlertService } from 'src/app/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import {
  formatISODateToDisplay,
  dateValidator,
  formatDateToISO,
  getDateOnlyValueISO,
  dateCompare,
} from 'src/app/utils/formatDate';
import { PolicyService } from 'src/app/services/policy.service';

@Component({
  selector: 'app-scroll-pagination-table',
  templateUrl: './scroll-pagination-table.component.html',
  styleUrls: ['./scroll-pagination-table.component.less'],
})
export class ScrollPaginationTableComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() tableData: { [key: string]: any }[] = [];
  @Input() tableColumns: string[] = [];
  @Input() tableColumnsExcluded: string[] = [];
  @Input() noDataMessage: string = 'No data available.';
  @Input() isLoading: boolean = true;
  @Input() pagination: Pagination;
  @Input() filterTab: number;
  @Input() quotesData;
  @Input() isRestrictedRoleSubmission: boolean = false;
  @Input() isTeamsQueue: boolean = false;
  @Input() resetSorting: boolean = false;

  @Output() getTableDataOnSort = new EventEmitter();
  @Output() getTableDataOnScroll = new EventEmitter();
  @Output() getTableDataOnRowClick = new EventEmitter();
  @Output() handleStatusChanged = new EventEmitter();

  isExpanded: boolean = false;
  currentSortColumn: string = '';
  sortOrder: string = '';
  isDefaultHeight: boolean = true;
  container: HTMLElement | null = null;
  currency: string = '';

  currentPage: number = 1;
  // totalPages: number = 1;
  buffer: number = 150;
  previousScrollTop: number = 0;

  initialToggle: boolean = false;
  isScrollListenerAdded = false;
  statusChangePopupIdx = null;
  pendingInfoReasons: DropdownListDto[];
  form: FormGroup;
  buttonDisabled: boolean = true;
  selectedReasonId: number = 0;
  selectedReasonDesc: string = '';
  quoteFlowStatus;
  showStatusChangedBadge: boolean = false;
  statusChangedBadgeIndex = null;
  statusChangePositionalStyles = {};
  reAssignReferPopupId = null;
  reAssignReferPopupPositionalStyles = {
    top: '0px',
    right: 'unset',
    left: 'unset',
  };

  uwSubmitted: boolean = false;
  selectedUnderWriterData: any;
  currentUnderWriterValue = '';
  underWriterOptions: Array<any> = [];
  underWriterErrMsg = '';

  reAssignBtnClicked: boolean = false;
  referBtnClicked: boolean = false;
  disableReferBtn: boolean = false;
  referredById: number = undefined;
  debounceTimer: any;

  editNeedByDatePopupIdx = null;
  shortDateFormat_2: string = 'MMM. DD, YYYY';
  showDateChangedBadge: boolean = false;
  showDateChangedBadgeIndex = null;
  boxxUsrId;

  constructor(
    private store: Store,
    private domainsService: DomainsService,
    private fb: FormBuilder,
    private policyRiskTrxQuoteFlowStatusService: PolicyRiskTrxQuoteFlowStatusService,
    private sharedFunctionService: SharedFunctionService,
    private policyPeriodService: PolicyPeriodService,
    private newQuoteService: NewQuoteService,
    private policyRiskTrxService: PolicyRiskTrxService,
    public boxxUserService: BoxxUserService,
    private translateService: TranslateService,
    private policyService: PolicyService,
  ) {
    this.form = this.fb.group(
      {
        reason: [''],
        underWriterInfo: [''],
        needByDateInfo: ['', [dateValidator.bind(this)]],
        receivedOnDateInfo: ['', [dateValidator.bind(this)]],
        effectiveDateInfo: ['', [dateValidator.bind(this)]],
        expiryDateInfo: ['', [dateValidator.bind(this)]],
      },
      { validators: this.checkDateValidation.bind(this) },
    );
  }

  async ngOnInit(): Promise<void> {
    this.boxxUsrId = Number(localStorage.getItem('bid'));
    this.handleAddScrollListener();
    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.shortDateFormat_2 =
        data.shortDateFormat_2 && data.shortDateFormat_2 === 'MMM. D, YYYY'
          ? this.shortDateFormat_2
          : this.shortDateFormat_2;
    });
    await this.getPendingInfoReasonsOptions();
    await this.getQuoteFlowStatus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    !this.isLoading && this.handleAddScrollListener();
    if (changes['filterTab']) {
      if (
        changes['filterTab'].currentValue !== changes['filterTab'].previousValue
      ) {
        this.currentSortColumn = '';
        this.sortOrder = '';
        this.currentPage = 1;
        this.closeAllPopups();
      }
    }

    if (
      changes['resetSorting'] &&
      changes['resetSorting'].currentValue !==
        changes['resetSorting'].previousValue
    ) {
      this.currentSortColumn = '';
      this.sortOrder = '';
      this.currentPage = 1;
    }
  }

  ngOnDestroy(): void {
    this.handleRemoveScrollListener();
  }

  handleScroll = () => {
    if (!this.container) return;

    this.statusChangePopupIdx = null;

    const scrollTop = this.container.scrollTop;
    const containerHeight = this.container.clientHeight;
    const scrollHeight = this.container.scrollHeight;

    const isScrollingDown = scrollTop > this.previousScrollTop;
    const isScrollingUp = scrollTop < this.previousScrollTop;

    this.previousScrollTop = scrollTop;

    if (
      isScrollingDown &&
      scrollTop > 0 &&
      scrollHeight - scrollTop - containerHeight < this.buffer &&
      this.currentPage < this.pagination.totalPages
    ) {
      this.handleRemoveScrollListener();
      this.currentPage++;
      this.handleTableData(this.currentPage, 'scrollingDown');
    }

    // else if (
    //   isScrollingUp &&
    //   scrollTop > 0 &&
    //   scrollTop < this.buffer &&
    //   this.currentPage > 1
    // ) {
    //   this.currentPage--;
    //   this.handleTableData(this.currentPage, 'scrollingUp');
    // }
  };

  async handleTableData(page: number, scrollDirection: string) {
    let filter = {
      page: this.currentPage,
      scrollDirection,
    };
    if (this.currentSortColumn !== '') {
      filter['sortColumn'] = this.currentSortColumn;
      filter['sortOrder'] = this.sortOrder;
    } else {
      delete filter['sortColumn'];
      delete filter['sortOrder'];
    }

    this.getTableDataOnScroll.emit(filter);
  }

  async handleSort(tableColumn: string): Promise<void> {
    this.currentPage = 1;
    if (tableColumn === this.currentSortColumn) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.currentSortColumn = tableColumn;
      this.sortOrder = 'asc';
    }
    this.getTableDataOnSort.emit({
      sortColumn: this.currentSortColumn,
      sortOrder: this.sortOrder,
      page: this.currentPage,
    });
  }

  handleTableCollapseAndExpand(): void {
    this.isExpanded = !this.isExpanded;
    this.isDefaultHeight = false;
    if (!this.initialToggle) {
      this.initialToggle = true;
      this.currentPage++;
      this.handleTableData(this.currentPage, 'scrollingDown');
    }
  }

  setupTooltipBranch(rowData): TooltipV2Model {
    if (rowData?.Broker?.branch)
      return {
        type: 'list',
        hiddenHead: false,
        hiddenHeadTxt: true,
        tooltipHeaderTxt: '',
        tooltipContentTxt: '',
        tooltipContentList: [rowData?.Broker.branch],
      };
    else
      return {
        type: '',
        hiddenHead: false,
        hiddenHeadTxt: true,
        tooltipHeaderTxt: '',
        tooltipContentTxt: '',
        tooltipContentList: [],
      };
  }

  handleRemoveScrollListener() {
    if (this.container && this.isScrollListenerAdded) {
      this.isScrollListenerAdded = false;
      this.container.removeEventListener('scroll', this.handleScroll);
    }
  }
  handleAddScrollListener() {
    if (!this.container) {
      this.container = document.getElementById(
        'scrollable-pagination-table-wrapper',
      );
    }

    if (this.container && !this.isScrollListenerAdded) {
      this.isScrollListenerAdded = true;
      this.container.addEventListener('scroll', this.handleScroll);
    }
  }

  async handleRowClick(
    rowData: { [key: string]: any },
    column: string,
    rowIndex: number,
  ) {
    if (this.isRestrictedRoleSubmission) {
      return;
    }
    // row click and navigation not allowed to Referred task tab
    if (this.filterTab === 1) {
      this.sharedFunctionService.triggerEvent({
        type: 'error',
        headerText: 'common.errorHeader',
        bodyText: this.translateService.instant(
          'dashboard.error.approvalNeededErrMsg',
        ),
      });
      return;
    }
    // make isRead true
    const updateReadFlagPayload = {
      isRead: true,
    };

    const quoteData = this.quotesData[rowIndex];

    this.newQuoteService.updateQuoteFlowStatusData({
      Status:
        quoteData?.policyRiskTrxes?.quoteProcessStatus?.subdomaincode ?? '',
      toReviewReferredBy: this.getReferredBy(quoteData) ?? '',
      pendingInfoReason: this.getPendingInfoReasons(quoteData) ?? [],
    });

    const policyPeriodId = quoteData.policyPeriod.id;
    const updatePolicyPeriodAPI = this.policyPeriodService.Update(
      policyPeriodId,
      updateReadFlagPayload,
    );
    const updateReadFlagResponse = await firstValueFrom(updatePolicyPeriodAPI);

    this.getTableDataOnRowClick.emit({ rowData, column });
  }

  async getPendingInfoReasonsOptions() {
    const reasons = await this.domainsService.GetDomainListAsync(
      DOMAIN_CODE_PENDINGINFOREASON,
    );
    this.pendingInfoReasons = reasons.map((data) => {
      return { key: data.value, value: data.id.toString(), id: data.id };
    });
  }

  async getQuoteFlowStatus() {
    const getDomainsAPI = this.domainsService.GetByDomainCode(
      DOMAIN_CODE_QUOTEFLOWSTATUS,
    );
    const quoteFlowStatusResponse = await firstValueFrom(getDomainsAPI);
    this.quoteFlowStatus = quoteFlowStatusResponse?.data;
  }

  handleStatusClick(event, index) {
    this.closeReAssignReferPopup();
    event.stopPropagation();
    if (this.isRestrictedRoleSubmission || this.isTeamsQueue) {
      return;
    }
    const quoteStatus =
      this.quotesData[index]?.policyRiskTrxes?.quoteStatus.description;
    const quoteFlowStatus =
      this.quotesData[index]?.policyRiskTrxes?.quoteProcessStatus?.description;
    if (
      quoteStatus?.toLowerCase() === QuoteStatus['Submission']?.toLowerCase() &&
      (quoteFlowStatus?.toLowerCase() ===
        QUOTEFLOWSTATUS_PREQUOTE?.toLowerCase() ||
        quoteFlowStatus?.toLowerCase() ===
          QUOTEFLOWSTATUS_TOREVIEW?.toLowerCase())
    ) {
      this.statusChangePopupIdx = index;
      // to position status change popup
      if (event.pageY > 500) {
        this.statusChangePositionalStyles = {
          top: '-200px',
        };
      } else {
        this.statusChangePositionalStyles = {
          top: '0px',
        };
      }
    }
  }

  closeStatusChangePopup() {
    this.statusChangePopupIdx = null;
  }

  reasonHandler(value: number) {
    this.selectedReasonId = value;
    this.selectedReasonDesc =
      this.pendingInfoReasons.find((a) => a.id === value)?.key || '';
    this.buttonDisabled = !this.isValidSelection(value);
  }

  private isValidSelection(value: number): boolean {
    return value && value > 0;
  }

  async handleStatusChange(rowIndex) {
    if (this.isTeamsQueue) return;
    const quoteFlowStatusCode = this.quoteFlowStatus.filter(
      (status) =>
        status.subdomaincode === SUB_DOMAIN_CODE_QUOTEFLOWSTATUS_PENDINGINFO,
    )[0]?.id;
    const policyRiskTrxId = this.quotesData[rowIndex]?.policyRiskTrxes?.id;
    if (!!quoteFlowStatusCode && !!this.selectedReasonId) {
      const payload = {
        quoteFlowStatus: quoteFlowStatusCode,
        reason: this.selectedReasonId,
      };
      try {
        const updateQuoteFlowStatusAPI =
          this.policyRiskTrxQuoteFlowStatusService.UpdateQuoteFlowStatus(
            policyRiskTrxId,
            payload,
          );
        const updateResponse = await firstValueFrom(updateQuoteFlowStatusAPI);
        this.statusChangedBadgeIndex = rowIndex;

        const quoteFlowStatus =
          this.quotesData[rowIndex]?.policyRiskTrxes?.quoteProcessStatus
            ?.description;
        if (
          quoteFlowStatus?.toLowerCase() ===
          QUOTEFLOWSTATUS_TOREVIEW?.toLowerCase()
        ) {
          this.tableData.splice(rowIndex, 1);
        } else {
          this.showStatusChangedBadge = true;
          setTimeout(() => {
            this.showStatusChangedBadge = false;
          }, 5000);
          this.handleStatusChanged.emit({
            rowIndex,
            selectedReasonDesc: this.selectedReasonDesc,
            toStatus: SUB_DOMAIN_CODE_QUOTEFLOWSTATUS_PENDINGINFO,
            removeRecordUI: false,
          });
        }

        if (
          this.selectedReasonDesc?.length > 0 &&
          this.tableData[rowIndex]?.['pendingInfoReason'].length === 0
        )
          this.tableData[rowIndex]?.['pendingInfoReason'].push(
            this.selectedReasonDesc,
          );
        this.statusChangePopupIdx = null;
      } catch (error) {
        this.statusChangePopupIdx = null;
        this.sharedFunctionService.triggerEvent({
          type: 'error',
          headerText: 'common.errorHeader',
          bodyText: getErrorMessage(error),
        });
      }
    }
  }

  getReferredBy(quoteData: any): string {
    return quoteData?.policyPeriod?.refferedBy
      ? quoteData?.policyPeriod?.refferedBy.firstName +
          ' ' +
          quoteData?.policyPeriod?.refferedBy.lastName
      : '';
  }

  getPendingInfoReasons(quoteData: any): string[] {
    return (
      quoteData?.policyRiskTrxes?.policyRiskTrxReason
        ?.filter(
          (reason) =>
            reason.reasonTypeDto.subdomaincode ===
            SUB_DOMAIN_CODE_REASONTYPE_PENDINGINFO,
        )
        .map((reason) => reason.comments || reason.reasonCodeDto.description) ??
      []
    );
  }

  handleReassignClick(event, index) {
    event.stopPropagation();
    if (this.isRestrictedRoleSubmission || this.filterTab === 1) {
      return;
    }
    this.closeStatusChangePopup();
    this.clearUWPopupdata();
    this.form.controls['underWriterInfo'].setValue('');

    if (this.tableData[index]?.['Status'] === 'QUOTEFLOWSTATUS_TOREVIEW') {
      this.referredById =
        this.quotesData?.[index]?.policyPeriod?.refferedBy?.id ?? undefined;
    } else {
      this.referredById = undefined;
    }

    const quoteStatus =
      this.quotesData[index]?.policyRiskTrxes?.quoteStatus.description;
    const quoteFlowStatus =
      this.quotesData[index]?.policyRiskTrxes?.quoteProcessStatus?.description;
    if (
      quoteStatus?.toLowerCase() === QuoteStatus['Submission']?.toLowerCase() &&
      quoteFlowStatus?.toLowerCase() === QUOTEFLOWSTATUS_APPROVED?.toLowerCase()
    ) {
      this.disableReferBtn = true;
    }
    this.reAssignReferPopupId = index;
    if (event.pageY > 500) {
      this.reAssignReferPopupPositionalStyles = {
        top: '-150px',
        right: !this.isTeamsQueue ? '112px' : 'unset',
        left: this.isTeamsQueue ? '70px' : 'unset',
      };
    } else {
      this.reAssignReferPopupPositionalStyles = {
        top: '0px',
        right: !this.isTeamsQueue ? '112px' : 'unset',
        left: this.isTeamsQueue ? '70px' : 'unset',
      };
    }
  }

  closeReAssignReferPopup() {
    this.reAssignReferPopupId = null;
    this.referredById = undefined;
    this.clearUWPopupdata();
    this.form.controls['underWriterInfo'].setValue('');
  }

  searchUnderWriter(searchValue) {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      this.handleUnderWriterSearch(searchValue);
    }, 500);
  }

  handleUnderWriterSearch(searchValue) {
    if (searchValue === '') {
      this.underWriterOptions = [];
      this.form.controls['underWriterInfo'].setValue('');
    }
    this.clearUWPopupdata();
    if (searchValue.length >= 3) {
      const searchKey = searchValue.trim();
      this.boxxUserService
        .GetUnderwriterlist(
          '',
          1,
          20,
          '',
          searchKey,
          -1,
          USER_ROLES.Underwriter,
        )
        .subscribe((data) => {
          let underwriterList = [];
          data.data?.map((underwriter: any) => {
            if (this.referredById !== underwriter.id) {
              underwriterList.push({
                main_text: underwriter.firstName + ' ' + underwriter.lastName,
                firstName: underwriter.firstName,
                lastName: underwriter.lastName,
                id: underwriter.id,
              });
            }
          });

          this.underWriterOptions = underwriterList;
        });
    } else {
      this.underWriterOptions = [];
      return;
    }
  }

  async selectUnderWriter(value) {
    let selectedValue = this.underWriterOptions.find((x) => x.id === value);
    this.selectedUnderWriterData = selectedValue;
    this.currentUnderWriterValue = selectedValue.main_text;
    this.form.controls['underWriterInfo'].setValue(
      this.currentUnderWriterValue,
    );
    this.underWriterErrMsg = '';
  }

  clearUWPopupdata() {
    this.underWriterErrMsg = '';
    this.uwSubmitted = false;
    this.selectedUnderWriterData = null;
    this.currentUnderWriterValue = '';
    this.reAssignBtnClicked = false;
    this.referBtnClicked = false;
    this.disableReferBtn = false;
  }

  async handleReassignQuote(rowIndex) {
    const uwData = this.selectedUnderWriterData;
    const uwId = uwData.id || undefined;
    const currentUWId =
      this.quotesData[rowIndex]?.underwriter[0]?.id || undefined;
    if (uwId === currentUWId) {
      this.sharedFunctionService.triggerEvent({
        type: 'error',
        headerText: 'common.errorHeader',
        bodyText: this.translateService.instant(
          'dashboard.error.alreadyAssignedtoSelectedUWErrMsg',
        ),
      });
      return;
    }

    this.uwSubmitted = true;
    this.reAssignBtnClicked = true;
    let removeRecordUI = true;

    const policyRiskTrxId = this.quotesData[rowIndex]?.policyRiskTrxes?.id;
    if (!!policyRiskTrxId && !!uwId) {
      try {
        const payload = {
          underwriterId: uwId,
        };

        const updateUnderwriterAPI = this.policyRiskTrxService.Update(
          policyRiskTrxId,
          payload,
        );
        const updateUnderwriterResponse =
          await firstValueFrom(updateUnderwriterAPI);
        this.uwSubmitted = false;

        // no need to remove record from UI - when - logged user reassigns a quote to different underwriter
        if (uwId !== this.boxxUsrId && this.isTeamsQueue) {
          removeRecordUI = false;
          this.tableData[rowIndex]['underwriterName'] = uwData?.main_text ?? '';
          this.quotesData[rowIndex].underwriter[0].id = uwId;
          this.quotesData[rowIndex].underwriter[0].firstName =
            uwData?.firstName;
          this.quotesData[rowIndex].underwriter[0].lastName = uwData?.lastName;
        }

        this.handleStatusChanged.emit({
          rowIndex,
          selectedReasonDesc: [],
          toStatus: 'Reassign',
          removeRecordUI: removeRecordUI,
        });

        this.closeReAssignReferPopup();
      } catch (error) {
        this.closeReAssignReferPopup();
        this.uwSubmitted = false;
        this.sharedFunctionService.triggerEvent({
          type: 'error',
          headerText: 'common.errorHeader',
          bodyText: getErrorMessage(error),
        });
      }
    }
  }

  async handleReferQuote(rowIndex) {
    const uwId = this.selectedUnderWriterData?.id || undefined;
    const currentUWId =
      this.quotesData[rowIndex]?.underwriter[0]?.id || undefined;
    const currentReferredToUWId =
      this.quotesData[rowIndex]?.policyPeriod?.refferedTo?.id || undefined;

    const currentStatus =
      this.quotesData[rowIndex]?.policyRiskTrxes?.quoteProcessStatus
        ?.subdomaincode ?? '';

    // need to restrict refer to logged in user - NOT IN TEAMS QUEUE
    if (this.boxxUsrId === uwId && !this.isTeamsQueue) {
      this.sharedFunctionService.triggerEvent({
        type: 'error',
        headerText: 'common.errorHeader',
        bodyText: this.translateService.instant(
          'dashboard.error.referSelfErrMsg',
        ),
      });
      return;
    }
    // need to restrict refer to the current underwriter
    if (currentUWId === uwId) {
      this.sharedFunctionService.triggerEvent({
        type: 'error',
        headerText: 'common.errorHeader',
        bodyText: this.translateService.instant(
          'dashboard.error.referCurrUWErrMsg',
        ),
      });
      return;
    }
    // need to restrict refer to the current approver
    if (
      currentReferredToUWId === uwId &&
      currentStatus === SUBDOMAINCODE_QUOTEFLOWSTATUS_TOREVIEW
    ) {
      this.sharedFunctionService.triggerEvent({
        type: 'error',
        headerText: 'common.errorHeader',
        bodyText: this.translateService.instant(
          'dashboard.error.alreadyReferredtoSameUWErrMsg',
        ),
      });
      return;
    }

    this.uwSubmitted = true;
    this.referBtnClicked = true;

    const quoteFlowStatusId = this.quoteFlowStatus.filter(
      (status) =>
        status.subdomaincode === SUBDOMAINCODE_QUOTEFLOWSTATUS_TOREVIEW,
    )[0]?.id;

    const policyRiskTrxId = this.quotesData[rowIndex]?.policyRiskTrxes?.id;
    if (!!quoteFlowStatusId && !!policyRiskTrxId && !!uwId) {
      try {
        const payload = {
          quoteFlowStatus: quoteFlowStatusId,
          refferedToUnderwriterId: uwId,
        };

        const updateQuoteFlowStatusAPI =
          this.policyRiskTrxQuoteFlowStatusService.UpdateQuoteFlowStatus(
            policyRiskTrxId,
            payload,
          );
        const updateResponse = await firstValueFrom(updateQuoteFlowStatusAPI);
        this.uwSubmitted = false;

        if (this.isTeamsQueue) {
          this.handleStatusChanged.emit({
            rowIndex,
            selectedReasonDesc: [],
            toStatus: SUBDOMAINCODE_QUOTEFLOWSTATUS_TOREVIEW,
            removeRecordUI: false,
          });
        } else {
          this.handleStatusChanged.emit({
            rowIndex,
            selectedReasonDesc: [],
            toStatus: 'Refer',
            removeRecordUI: true,
          });
        }

        this.closeReAssignReferPopup();
      } catch (error) {
        this.closeReAssignReferPopup();
        this.uwSubmitted = false;
        this.sharedFunctionService.triggerEvent({
          type: 'error',
          headerText: 'common.errorHeader',
          bodyText: getErrorMessage(error),
        });
      }
    }
  }

  closeAllPopups() {
    this.closeReAssignReferPopup();
    this.closeStatusChangePopup();
    this.form.controls['underWriterInfo'].setValue('');
  }

  needByDateClick(event, index, columnName) {
    event.stopPropagation();
    this.hideDateChangedBadge();
    // From dashboard, Need by date cell can only change to a date format regardless if its been a date or TBD
    if (
      this.isRestrictedRoleSubmission ||
      (columnName === 'Need by' &&
        !this.quotesData[index]?.policy?.needByDt &&
        this.quotesData[index]?.policyPeriod?.apiCreated === true)
    ) {
      return;
    }
    if (this.editNeedByDatePopupIdx === index) {
      this.needByDateUpdate(event, index);
    } else {
      const needbydatedummy = this.quotesData[index]?.policy?.needByDt ?? '';
      if (needbydatedummy) {
        const formattedNeedByDate = formatISODateToDisplay(
          needbydatedummy,
          this.shortDateFormat_2,
        );
        this.form.patchValue({ needByDateInfo: formattedNeedByDate });
      } else this.form.controls['needByDateInfo'].setValue('');

      let receivedOnDate = this.quotesData[index]?.policy?.receivedOnDt ?? '';
      if (receivedOnDate) {
        const formattedReceivedOnDate = formatISODateToDisplay(
          receivedOnDate,
          this.shortDateFormat_2,
        );
        this.form.patchValue({ receivedOnDateInfo: formattedReceivedOnDate });
      } else this.form.controls['receivedOnDateInfo'].setValue('');

      let expiryDate = this.quotesData[index]?.policyPeriod?.expiryDt ?? '';
      if (expiryDate) {
        const formattedExpiryDate = formatISODateToDisplay(
          expiryDate,
          this.shortDateFormat_2,
        );
        this.form.patchValue({ expiryDateInfo: formattedExpiryDate });
      } else this.form.controls['expiryDateInfo'].setValue('');

      let effectiveDate =
        this.quotesData[index]?.policyPeriod?.effectiveDt ?? '';
      if (effectiveDate) {
        const formattedEffectiveDate = formatISODateToDisplay(
          effectiveDate,
          this.shortDateFormat_2,
        );
        this.form.patchValue({ effectiveDateInfo: formattedEffectiveDate });
      } else this.form.controls['effectiveDateInfo'].setValue('');

      this.editNeedByDatePopupIdx = index;
    }
    this.closeAllPopups();
  }

  async needByDateUpdate(event, index) {
    try {
      const policyId = this.quotesData[index]?.policy?.id;
      if (!!policyId) {
        if (
          !(
            this.form.errors?.['lessthanReceivedDateErr'] ||
            this.form.errors?.['greaterthanExpiryDateErr'] ||
            this.form.errors?.['lessthanEffectiveDateErr']
          ) &&
          this.form.controls['needByDateInfo'].valid &&
          this.form.controls['needByDateInfo'].value
        ) {
          const currentNeedByDt = this.quotesData[index]?.policy.needByDt;
          const needByDtISO = formatDateToISO(
            this.form.controls['needByDateInfo'].value,
            this.shortDateFormat_2,
          );
          if (
            getDateOnlyValueISO(currentNeedByDt) !==
            getDateOnlyValueISO(needByDtISO)
          ) {
            const payload = {
              needByDt: needByDtISO,
            };
            const updateQuote = this.policyService.Update(policyId, payload);
            const updateResponse = await firstValueFrom(updateQuote);

            if (index !== null) {
              this.showDateChangedBadgeIndex = index;
              this.tableData[index]['Need by'] = formatISODateToDisplay(
                needByDtISO,
                this.shortDateFormat_2,
              );
              this.quotesData[index].policy.needByDt = needByDtISO;
            }

            this.closeNeedDateInput();
            this.showDateChangedBadge = true;
            setTimeout(() => {
              this.hideDateChangedBadge();
            }, 5000);
          }
        }
      }
    } catch (error) {
      this.editNeedByDatePopupIdx = null;
      this.sharedFunctionService.triggerEvent({
        type: 'error',
        headerText: 'common.errorHeader',
        bodyText: getErrorMessage(error),
      });
    }
  }

  closeNeedDateInput() {
    this.form.controls['needByDateInfo'].setValue('');
    this.form.controls['receivedOnDateInfo'].setValue('');
    this.form.controls['effectiveDateInfo'].setValue('');
    this.form.controls['expiryDateInfo'].setValue('');
    this.editNeedByDatePopupIdx = null;
  }

  hideDateChangedBadge() {
    this.showDateChangedBadge = false;
    this.showDateChangedBadgeIndex = null;
  }

  checkDateValidation(formGroup: FormGroup): { [key: string]: boolean } | null {
    const needDate = formGroup.get('needByDateInfo')?.value;
    const receivedDate = formGroup.get('receivedOnDateInfo')?.value;
    const expiryDate = formGroup.get('expiryDateInfo')?.value;
    const effectiveDate = formGroup.get('effectiveDateInfo')?.value;

    const receivedDateValidate = dateCompare(
      receivedDate,
      needDate,
      'receivedDateLower',
    );
    if (receivedDateValidate === null) {
      const expiryDateValidate = dateCompare(
        needDate,
        expiryDate,
        'expiryDateHigher',
      );
      if (expiryDateValidate === null) {
        if (receivedDate === '')
          // if received date available, we wont check effective date less validation
          return dateCompare(effectiveDate, needDate, 'effectiveDateLower');
        else return null;
      } else return expiryDateValidate;
    } else return receivedDateValidate;
  }

  getUWNameAvatarInitial(uwName: string = ''): string {
    if (uwName !== '') {
      const nameArr = uwName?.split(' ');
      return `${
        nameArr[0] && nameArr[0].length > 0 ? nameArr[0][0] + ' ' : ''
      }${nameArr[1] && nameArr[1].length > 0 ? nameArr[1][0] : ''}`;
    }
    return '-';
  }

  getDateMismatchErrorMsg(error: any): string {
    if (error['lessthanReceivedDateErr']) {
      return this.translateService.instant(
        'dashboard.error.lessthanReceivedDateErrMsg',
      );
    } else if (error['lessthanEffectiveDateErr']) {
      return this.translateService.instant(
        'dashboard.error.lessthanEffectiveDateErrMsg',
      );
    } else if (error['greaterthanExpiryDateErr']) {
      return this.translateService.instant(
        'dashboard.error.greaterthanExpiryDateErrMsg',
      );
    } else
      return this.translateService.instant('dashboard.error.invalidDateErrMsg');
  }
}
