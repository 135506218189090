import {
  handleApiError,
  isSuccessApiStatus,
} from 'src/app/dashboard/utils/lifecycle-utils';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  PolicyChangeResponse,
  PolicyReinstateRequest,
} from 'src/app/entities/policy-lifecycle';
import { PolicyLifecycleService } from 'src/app/services/policy-lifecycle-service';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Store, select } from '@ngrx/store';
import { getQuoteSelector } from 'src/app/store/create-quote/create-quote.selector';
import { Observable } from 'rxjs';
import { Quote } from 'src/app/models/quote.model';

@Component({
  selector: 'app-policy-reinstate-popup',
  templateUrl: './policy-reinstate-popup.component.html',
  styleUrls: ['./policy-reinstate-popup.component.less'],
})
export class PolicyReinstatePopupComponent implements OnInit {
  @Input() showModal: boolean = false;
  @Input() details;

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleActionConfirm = new EventEmitter<PolicyChangeResponse>();

  private results: PolicyReinstateRequest = this.createDefaultRequest();

  reinstatePolicyForm: FormGroup;
  buttonDisabled: boolean = true;

  isReasonValid: boolean = false;
  insuredTimeZone;
  form$: Observable<Quote>;

  constructor(
    private fb: FormBuilder,
    private policyLifecycleService: PolicyLifecycleService,
    private translate: TranslateService,
    private store: Store,
  ) {
    this.reinstatePolicyForm = this.fb.group({
      reason: [''],
    });
  }

  ngOnInit(): void {
    this.form$ = this.store.pipe(select(getQuoteSelector));
    this.form$.subscribe((event) => (this.insuredTimeZone = event.ui.timezone));
  }

  closeHandler() {
    this.handleClose.emit();
    this.resetForm();
  }

  reasonHandler(value: number) {
    this.isReasonValid = value && value > 0;

    if (this.isReasonValid) {
      this.results.reInstateReason = Number(value);
    }

    this.isFormValid();
  }

  handleReinstate(event) {
    if (!this.isFormValid()) {
      return;
    }

    const request: PolicyReinstateRequest = {
      policyPeriodId: this.details.policyPeriodId,
      reInstateReason: this.results.reInstateReason,
    };

    this.policyLifecycleService
      .PolicyReinstate(this.details.userId, request)
      .subscribe({
        next: (response) => {
          let result: PolicyChangeResponse = undefined;

          if (isSuccessApiStatus(response.status)) {
            result = {
              success: true,
              message: this.translate.instant(
                'Policy was successfully reinstated',
              ),
            };
          } else {
            result = {
              success: false,
              message:
                this.translate.instant('Error reinstating policy: ') +
                response.code?.toString(),
            };
          }

          this.handleActionConfirm.emit(result);
          this.resetForm();
        },
        error: (error) => {
          const result: PolicyChangeResponse = {
            success: false,
            message: handleApiError(error),
          };

          this.handleActionConfirm.emit(result);
          this.resetForm();
        },
      });
  }

  private isFormValid(): boolean {
    const isValid = this.isReasonValid;
    this.buttonDisabled = !isValid;

    return isValid;
  }

  private resetForm() {
    this.buttonDisabled = true;
    this.isReasonValid = false;
    this.reinstatePolicyForm.reset();
    this.results = this.createDefaultRequest();
  }

  private createDefaultRequest(): PolicyReinstateRequest {
    let request: PolicyReinstateRequest = {
      policyPeriodId: 0,
      reInstateDate: undefined,
      reInstateReason: 0,
    };

    return request;
  }
}
