<div class="add-form">
  <ng-container *ngIf="!showBodySpinner">
    <div class="form-input-hd">
      <boxxds-body
        sz="md"
        [text]="'insured.heading.customerContactDetails' | translate"
        [customStyle]="{ color: '#616162', marginBottom: '4px' }"
      ></boxxds-body>
      <boxxds-body
        sz="xs"
        [text]="'*' + ('common.required' | translate)"
        [customStyle]="{ color: '#8F8F91', textTransform: 'uppercase' }"
      >
      </boxxds-body>
    </div>
    <div class="form-inputs customer-contact">
      <boxxds-search-autocomplete
        *ngIf="fromSubmissionFlow === true"
        [form]="form"
        [isActive]="isActive"
        [formSubmitted]="checkValidation"
        [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
        [items]="insuredOptns"
        [title]="form.value?.firstName"
        [label]="'insured.label.firstName' | translate"
        [required]="true"
        [control]="'firstName'"
        [placeholder]="'insured.placeholder.enterFirstName' | translate"
        [autocapitalize]="'off'"
        [spellcheck]="'off'"
        [permissionStatus]="this.permissionList[this.currentScreen]"
        [containerStyle]="{ width: '400px' }"
        (searchQuery)="handleSearch($event)"
        (itemSelected)="insuredOptns = []; populateInsuredDetails($event)"
      ></boxxds-search-autocomplete>
      <boxxds-textinput
        *ngIf="fromSubmissionFlow === false"
        [form]="form"
        [placeholder]="'insured.placeholder.enterFirstName' | translate"
        [control]="'firstName'"
        [id]="'firstName'"
        [width]="'100%'"
        [containerStyle]="{ maxWidth: '300px' }"
        [label]="('insured.label.firstName' | translate) + '*'"
        class="customer-field"
        [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
        [formSubmitted]="checkValidation"
        maxLength="60"
        [readonly]="
          (insuredData && !insuredData?.active) ||
          !this.permissionList[this.currentScreen]
        "
        [class.noPointerEvents]="
          (insuredData && !insuredData?.active) ||
          !this.permissionList[this.currentScreen]
        "
      >
      </boxxds-textinput>
      <boxxds-textinput
        [form]="form"
        [placeholder]="'insured.placeholder.enterMiddleName' | translate"
        [control]="'middleName'"
        [id]="'middleName'"
        [width]="'100%'"
        [containerStyle]="{ maxWidth: '300px' }"
        [label]="'insured.label.middleName' | translate"
        class="customer-field"
        [formSubmitted]="checkValidation"
        maxLength="60"
        [readonly]="
          (insuredData && !insuredData?.active) ||
          !this.permissionList[this.currentScreen]
        "
        [class.noPointerEvents]="
          (insuredData && !insuredData?.active) ||
          !this.permissionList[this.currentScreen]
        "
      >
      </boxxds-textinput>
      <boxxds-textinput
        [form]="form"
        [placeholder]="'insured.placeholder.enterLastName' | translate"
        [control]="'lastName'"
        [id]="'lastName'"
        [width]="'100%'"
        [containerStyle]="{ maxWidth: '300px' }"
        [label]="('insured.label.lastName' | translate) + '*'"
        class="customer-field"
        [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
        [formSubmitted]="checkValidation"
        maxLength="60"
        [readonly]="
          (insuredData && !insuredData?.active) ||
          !this.permissionList[this.currentScreen]
        "
        [class.noPointerEvents]="
          (insuredData && !insuredData?.active) ||
          !this.permissionList[this.currentScreen]
        "
      >
      </boxxds-textinput>
      <boxxds-textinput
        [form]="form"
        [placeholder]="'insured.placeholder.enterEmailAddress' | translate"
        [control]="'email'"
        [id]="'customerEmail'"
        [width]="'100%'"
        [containerStyle]="{ maxWidth: '380px' }"
        [label]="('insured.label.emailAddress' | translate) + '*'"
        class="customer-field customer-email"
        [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
        [invalidErrMsg]="'error.thisFieldIsInvalid' | translate"
        [formSubmitted]="checkValidation"
        maxLength="150"
        [readonly]="
          (insuredData && !insuredData?.active) ||
          !this.permissionList[this.currentScreen]
        "
        [class.noPointerEvents]="
          (insuredData && !insuredData?.active) ||
          !this.permissionList[this.currentScreen]
        "
      >
      </boxxds-textinput>
      <boxxds-dropdown
        [form]="form"
        [control]="'householdMembers'"
        [placeholder]="'common.select' | translate"
        [options]="householdMemberOptns"
        (currentValueChange)="
          this.permissionList[this.currentScreen] && handleNoOfEmployees($event)
        "
        [currentValue]="form?.value?.householdMembers"
        [label]="('insured.label.numberOfHouseholdMembers' | translate) + '?*'"
        [btnStyle]="{ marginTop: '8px', width: '248px' }"
        [dropdownContainerStyle]="{ width: '248px' }"
        class="employees"
        [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
        [formSubmitted]="checkValidation"
        [isReadonly]="
          (insuredData && !insuredData?.active) ||
          !this.permissionList[this.currentScreen]
        "
        [class.noPointerEvents]="
          (insuredData && !insuredData?.active) ||
          !this.permissionList[this.currentScreen]
        "
      >
      </boxxds-dropdown>
    </div>
    <app-location-details-form
      (eventEmitRegionIdSelected)="onHandlerIndividualRegionIdSelected($event)"
      [form]="form"
      [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
      [formSubmitted]="checkValidation"
      [defaultValueObj]="form.value"
      [isActive]="
        !(insuredData && !insuredData?.active) &&
        this.permissionList[this.currentScreen]
      "
      [allowLoader]="allowLocationLoader"
      (eventEmitLoadedData)="completedLocationLoading($event)"
    >
    </app-location-details-form>
  </ng-container>
</div>
<app-footer-wrapper
  *ngIf="
    (quoteFlowSteps === '4' || quoteFlowSteps === 'workflow3') &&
    fromSubmissionFlow === true
  "
>
  <div class="footer-button-list">
    <div class="button-section" *ngIf="fromSubmissionFlow === true">
      <button class="linkButton" (click)="handleExitFlow()">
        Exit the flow
      </button>
    </div>
    <div class="button-section">
      <boxxds-button
        [buttonText]="continueButtonLabel"
        [btnType]="fromSubmissionFlow === true ? 'primary' : 'primary-outlined'"
        (handleClick)="addOrUpdateIndividualInsured($event)"
        [btnStyle]="{
          width: fromSubmissionFlow === true ? '199px' : '145px'
        }"
        [isDisabled]="
          insuredId ? (insuredData?.active === false ? true : false) : false
        "
      >
      </boxxds-button>
    </div>
  </div>
</app-footer-wrapper>
<div class="continue-btn-holder" [ngClass]="{ 'add-new': !insuredId }">
  <div class="timestamp" *ngIf="fromSubmissionFlow === false">
    <app-timestamp
      *ngIf="insuredData"
      [label]="'common.createdBy' | translate"
      value="{{ insuredData.createdBy }}, {{ insuredData.createdDt }}"
      [containerStyle]="{
        marginTop: '8px',
        marginLeft: '8px',
        position: 'relative',
        top: '-6px',
        textTransform: 'uppercase'
      }"
    ></app-timestamp>
    <app-timestamp
      *ngIf="insuredData && insuredData.updatedBy && insuredData.updatedDt"
      [label]="'common.updatedLastBy' | translate"
      value="{{ insuredData.updatedBy }}, {{ insuredData.updatedDt }}"
      [containerStyle]="{
        marginLeft: '8px',
        position: 'relative',
        top: '-20px',
        textTransform: 'uppercase'
      }"
    ></app-timestamp>
  </div>
  <div
    class="link-button-holder"
    *ngIf="this.permissionList[this.currentScreen]"
  >
    <boxxds-link
      *ngIf="insuredId && fromSubmissionFlow === false"
      [linkStyle]="{ marginRight: '49px' }"
      (handleClick)="deactivateOrReactivateInsured()"
      [name]="
        (insuredData?.active
          ? 'insured.label.deactivateInsured'
          : 'insured.label.reactivateInsured'
        ) | translate
      "
    ></boxxds-link>
    <boxxds-button
      *ngIf="
        fromSubmissionFlow === false &&
        (quoteFlowSteps === '4' || quoteFlowSteps === 'workflow3')
      "
      [buttonText]="
        continueButtonLabel ||
          (insuredId ? 'common.saveChanges' : 'insured.button.addNewInsured')
          | translate
      "
      [btnType]="fromSubmissionFlow === false ? 'primary-outlined' : 'primary'"
      (handleClick)="addOrUpdateIndividualInsured($event)"
      [btnStyle]="{
        width: insuredId ? '145px' : '199px'
      }"
      [isDisabled]="
        insuredId ? !(hasFormValueChanged() && insuredData?.active) : false
      "
    >
    </boxxds-button>
    <boxxds-button
      *ngIf="quoteFlowSteps === '7'"
      [buttonText]="
        continueButtonLabel ||
          (insuredId && fromSubmissionFlow === false
            ? 'common.saveChanges'
            : 'insured.button.addNewInsured') | translate
      "
      [btnType]="
        fromSubmissionFlow === false ? 'primary-outlined' : 'secondary-solid'
      "
      (handleClick)="addOrUpdateIndividualInsured($event)"
      [btnStyle]="{
        width:
          fromSubmissionFlow === false
            ? insuredId
              ? '145px'
              : '199px'
            : '199px'
      }"
      [isDisabled]="
        insuredId && fromSubmissionFlow === false
          ? !(hasFormValueChanged() && insuredData?.active)
          : false
      "
    >
    </boxxds-button>
  </div>
</div>
