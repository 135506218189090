<ng-container *ngIf="showBodySpinner">
  <div class="body-spinner">
    <boxxds-spinner
      [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
    ></boxxds-spinner>
  </div>
</ng-container>

<div *ngIf="!showBodySpinner" class="brokerage-profile-page">
  <app-navigation-v2
    [showHeaderBanner]="true"
    [mainHeading]="''"
    [subHeading]="''"
    [isNeeded]="false"
    [showHeader]="true"
    [items]="itemsMenu"
    (suggestedSearchSelected)="handleGlobalSearch($event)"
  >
  </app-navigation-v2>

  <!-- alert holder -->
  <div class="alert-holder">
    <boxxds-alert-v2
      (handleCloseEvent)="onHandleCloseEvent()"
      *ngIf="showSuccessBanner()"
      type="success"
      headerText="success!"
      [bodyText]="getBannerMsg()"
    >
    </boxxds-alert-v2>
  </div>
  <!-- alert holder end -->

  <!-- title header -->
  <section class="title-section">
    <div class="max-width-wrapper">
      <div class="title-description-container">
        <div class="title-container">
          <boxxds-heading
            *ngIf="this.brokerageSelectedId === (0 || undefined)"
            class="title"
            [sz]="'6'"
            [text]="'New Brokerage'"
            type="bold"
          ></boxxds-heading>
          <boxxds-heading
            *ngIf="this.brokerageSelectedId !== (0 || undefined)"
            class="title"
            [sz]="'6'"
            [text]="brokerageSelected.name"
            type="bold"
          ></boxxds-heading>
          <p
            *ngIf="
              !brokerageActive && this.brokerageSelectedId !== (0 || undefined)
            "
            class="danger-badge"
          >
            Inactive
          </p>
          <p
            *ngIf="
              brokerageActive && this.brokerageSelectedId !== (0 || undefined)
            "
            class="active-badge"
          >
            Active
          </p>
        </div>
        <boxxds-body
          *ngIf="this.brokerageSelectedId === (0 || undefined)"
          class="description"
          [text]="'Enter brokerage information'"
          [customStyle]="{
            fontFamily: 'Gibson',
            color: '#475467'
          }"
        >
        </boxxds-body>
        <boxxds-body
          *ngIf="this.brokerageSelectedId !== (0 || undefined)"
          class="description"
          [text]="'Main'"
          [customStyle]="{
            fontFamily: 'Gibson',
            color: '#475467'
          }"
        >
        </boxxds-body>
      </div>
      <div class="button-container">
        <boxxds-button-v2
          *ngIf="
            !brokerageActive && this.brokerageSelectedId !== (0 || undefined)
          "
          [buttonText]="'Reactivate' | translate"
          [type]="'submit'"
          [isDisabled]="showBodySpinner"
          [size]="'md'"
          [btnClass]="'secondary gray'"
          [btnType]="'default'"
          [isDropdown]="false"
          (handleClick)="!showBodySpinner && handleActivateBrokerage()"
        ></boxxds-button-v2>
        <boxxds-button-v2
          *ngIf="
            brokerageActive && this.brokerageSelectedId !== (0 || undefined)
          "
          [buttonText]="'Deactivate' | translate"
          [type]="'submit'"
          [isDisabled]="showBodySpinner"
          [size]="'md'"
          [btnClass]="'secondary gray'"
          [btnType]="'default'"
          [isDropdown]="false"
          (handleClick)="!showBodySpinner && handleDeactivateBrokerage()"
        ></boxxds-button-v2>
        <boxxds-button-v2
          *ngIf="
            activeCategoryFilter !== 'Branches' &&
            this.brokerageSelectedId !== (0 || undefined) &&
            enableCategory
          "
          [buttonText]="'Add branch' | translate"
          [type]="'submit'"
          [isDisabled]="!brokerageActive"
          [size]="'md'"
          [btnClass]="
            activeCategoryFilter === 'Brokerage information'
              ? ' secondary gray'
              : 'primary'
          "
          [btnType]="'default'"
          [isDropdown]="false"
          (handleClick)="handleAddBranch()"
        ></boxxds-button-v2>
        <boxxds-button-v2
          *ngIf="
            activeCategoryFilter !== 'Commission rates' &&
            this.brokerageSelectedId !== (0 || undefined) &&
            enableCategory
          "
          [buttonText]="'Add commission' | translate"
          [type]="'submit'"
          [isDisabled]="!brokerageActive"
          [size]="'md'"
          [btnClass]="'primary'"
          [btnType]="'default'"
          [isDropdown]="false"
          (handleClick)="handleCategoryFilter('Commission rates')"
        ></boxxds-button-v2>
      </div>
    </div>
  </section>
  <!-- title header end -->

  <!-- content-section -->
  <section class="content-section">
    <!-- tab switch -->
    <div class="max-width-wrapper">
      <div class="filter-section">
        <div class="category-filter">
          <ul>
            <ng-container *ngFor="let category of categoryFilters">
              <li
                (click)="
                  enableCategory &&
                    activeCategoryFilter !== category &&
                    this.brokerageSelectedId !== (0 || undefined) &&
                    handleCategoryFilter(category)
                "
                [class.active]="activeCategoryFilter === category"
              >
                {{ category }}
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
    <!-- tab switch end -->

    <!-- dynamic content section according to tab click -->
    <div class="max-width-wrapper">
      <!-- brokerage information -->
      <div
        class="dynamic-content-wrapper brokerage-information"
        *ngIf="activeCategoryFilter === 'Brokerage information'"
      >
        <!-- brokerage information form -->
        <div class="brokerage-information-wrapper">
          <div class="form-title-wrapper">
            <h2>{{ "common.brokerageInformation" | translate }}</h2>
            <h6>{{ "common.enterBrokerageInformation" | translate }}</h6>
          </div>
          <div class="form-wrapper">
            <form [formGroup]="form">
              <app-brokerage-details-v2
                [isActive]="brokerageActive && editable"
                [isFetching]="isFetching"
                [parentInitValue]="parentInitValue"
                [form]="brokerage"
                [requiredErrMsg]="requiredErrMsg"
                [invalidErrMsg]="invalidErrMsg"
                [parentCompanyInvalidErrMsg]="parentCompanyInvalidErrMsg"
                [formSubmitted]="formSubmitted"
                [logoProfile]="logoImage"
                (triggerProfilePicErrorToParent)="
                  triggerProfilePicErrorToParent($event)
                "
              >
              </app-brokerage-details-v2>
            </form>
          </div>
        </div>
        <!-- brokerage information form end -->

        <!-- brokerage address form -->
        <div class="brokerage-information-wrapper">
          <div class="form-title-wrapper">
            <h2>{{ "common.brokerageAddress" | translate }}</h2>
            <h6>{{ "common.enterBrokerageAddress" | translate }}</h6>
          </div>
          <div class="form-wrapper">
            <app-location-details-form-v2
              (eventEmitRegionIdSelected)="regionIdSelected($event)"
              [form]="location"
              [requiredErrMsg]="requiredErrMsg"
              [formSubmitted]="formSubmitted"
              [isActive]="brokerageActive && hasPermission && editable"
            >
            </app-location-details-form-v2>
          </div>
        </div>

        <!-- brokerage form footer -->
        <div class="brokerage-information-footer">
          <boxxds-button-v2
            [size]="'md'"
            [type]="'submit'"
            [btnStyle]="{
              padding: '14px 10px',
              width: '120',
              height: '40px'
            }"
            [buttonText]="'common.discard' | translate"
            [btnClass]="'tertiary gray'"
            [btnTextStyle]="{ fontFamily: 'Gibson' }"
            (click)="handleReset()"
          >
          </boxxds-button-v2>
          <boxxds-button-v2
            *ngIf="this.brokerageSelectedId !== (0 || undefined)"
            [size]="'md'"
            [type]="'submit'"
            [btnStyle]="{
              padding: '14px 10px',
              width: '120px',
              height: '40px'
            }"
            [buttonText]="'common.save' | translate"
            [btnClass]="'secondary gray'"
            [btnTextStyle]="{ fontFamily: 'Gibson' }"
            (click)="
              this.brokerageSelectedId !== (0 || undefined)
                ? handleUpdate($event)
                : handleSubmit($event)
            "
          >
          </boxxds-button-v2>
          <boxxds-button-v2
            *ngIf="this.brokerageSelectedId === (0 || undefined)"
            [size]="'md'"
            [type]="'submit'"
            [buttonText]="'common.continueToCommissionRates' | translate"
            [btnClass]="'primary'"
            [btnStyle]="{ width: '230px', height: '40px' }"
            (click)="continueToCommissionRates($event)"
          >
          </boxxds-button-v2>
        </div>
        <!-- brokerage form footer end -->
        <div
          class="footer-section"
          *ngIf="activeCategoryFilter === 'Brokerage information'"
        >
          <app-pagefooter-timestamp-v2
            *ngIf="createdBy && createdAt"
            [createdBy]="createdBy"
            [createdAt]="createdAt"
            [updatedBy]="updatedBy"
            [updatedAt]="updatedAt"
          >
          </app-pagefooter-timestamp-v2>
        </div>
      </div>
      <!-- brokerage address form end -->

      <!-- brokerage information end -->

      <!-- commission rates -->
      <div
        class="dynamic-content-wrapper commission-rates"
        *ngIf="activeCategoryFilter === 'Commission rates'"
      >
        <app-commission-rate-workflow3
          *ngIf="brokerageId"
          [brokerageId]="brokerageId"
          [type]="commissionType"
          [isBrokerActive]="brokerageActive"
        >
        </app-commission-rate-workflow3>
      </div>
      <!-- commission rates end -->

      <!-- branches -->
      <div
        class="dynamic-content-wrapper branches"
        *ngIf="activeCategoryFilter === 'Branches'"
      >
        <app-branch-profile
          [brokerageId]="brokerageId"
          [addBranchForm]="addBranchForm"
          [isBrokerageActive]="brokerageActive"
        ></app-branch-profile>
      </div>
      <!-- branches end -->
    </div>
    <!-- dynamic content section according to tab click end -->
  </section>
  <!-- content-section end -->
</div>
