import { Component, OnInit, Renderer2 } from '@angular/core';
import { Breadcrumb } from 'src/app/models/breadcrumb.model';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BrokerageProducerProfileService } from 'src/app/services/brokerage-producer-profile.service';
import { PolicyDashboardService } from 'src/app/services/policy-dashboard.service';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import {
  formatDate,
  dateTimeWithUserName,
  getDateString,
} from 'src/app/utils/formatDate';
import {
  excelFileDownload,
  formatAmountWithCurrency,
  formatPhoneNumber,
  getAlertHead,
  getErrorMessage,
  getStatusMappingForStyling,
} from 'src/app/utils/utils';
import { Pagination } from 'src/app/entities/boxx-response';
import { BrokerageProducerService } from 'src/app/services/brokerage-producer.service';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { LocationService } from 'src/app/services/location.service';
import { RiskQuestionService } from 'src/app/services/risk-question.service';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs';
import { resetProducerCreated } from 'src/app/store/brokerage/brokerage.action';
import { getProducerCreatedSelector } from 'src/app/store/brokerage/brokerage.select';
import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import {
  MAX_DEDUCTIBLE,
  MAX_LIMIT,
  MAX_PREMIUM,
  MIN_DEDUCTIBLE,
  MIN_LIMIT,
  MIN_PREMIUM,
} from 'src/app/constants/filter-constant';
import { AlertService } from 'src/app/services/alert.service';
import {
  QuoteStatus,
  isStatusDisabled,
  mapQuote,
} from 'src/app/constants/quoteStatus';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-producer-profile',
  templateUrl: './producer-profile.component.html',
  styleUrls: ['./producer-profile.component.less'],
})
export class ProducerProfileComponent implements OnInit {
  statusMappingToType = getStatusMappingForStyling();

  itemsMenu: Breadcrumb[] = [];
  loggedInUser: { name: string; photoUrl: string; role: string };
  infoTile = [
    { heading: 'brokerage.producer.label.bindRatio', count: '0%' },
    { heading: 'brokerage.producer.label.pendingQuotes', count: '0' },
  ];
  tableData = [];
  tableHeaders = [
    {
      'Quotes ID': '',
      'Date created': '',
      'Insured name': '',
      Product: '',
      Limit: '',
      Premium: '',
      Status: '',
    },
  ];
  totalDataCount = 0;
  pagination: Pagination;

  subNavData;
  showFilter = false;
  producerProfile: any;
  producerId: number;
  producer: any;
  createdBy: string | null;
  updatedBy: string | null;
  editProducerUrl: string = null;
  quoteList = [];
  public formatPhoneNumber = formatPhoneNumber;
  filterForm: FormGroup;
  products;
  addSortTo: string | Array<string>;
  sortBy = 'createdDt:desc';
  userID: number;
  showTblSpinner: boolean = false;
  pageNumber = 1;
  isNavigatedFromAddProducer = false;
  showErrorAlert = false;
  msgErrorAlert = '';
  currency = '';
  insuredData = {};
  policyPeriodIds = [];

  notificationAlert: {
    show?: boolean;
    bodyText?: string;
    type?: string;
    headerText?: string;
  } = { show: false };
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  quoteFlowSteps;
  quoteFlowUrl = '';
  shortDateFormat: string = '';
  longDateFormat: string = '';
  shortDateTimeFormat: string = '';
  longDateTimeFormat: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private brokerageProducerProfileService: BrokerageProducerProfileService,
    private policyDashboardService: PolicyDashboardService,
    private riskRegionService: RiskRegionService,
    private fb: FormBuilder,
    private brokerageProducerService: BrokerageProducerService,
    private localStorageService: LocalStorageService,
    private store: Store,
    private router: Router,
    private renderer: Renderer2,
    public locationService: LocationService,
    public riskQuestionService: RiskQuestionService,
    private alertService: AlertService,
  ) {
    this.filterForm = this.fb.group({
      periodFrom: [''],
      periodTo: [''],
      expiryTo: [''],
      quotedPeriod: [''],
      minLimit: [MIN_LIMIT, [this.isMinMaxValid(null, 'maxLimit')]],
      maxLimit: [MAX_LIMIT, [this.isMinMaxValid('minLimit', null)]],
      minDeductible: [
        MIN_DEDUCTIBLE,
        [this.isMinMaxValid(null, 'maxDeductible')],
      ],
      maxDeductible: [
        MAX_DEDUCTIBLE,
        [this.isMinMaxValid('minDeductible', null)],
      ],
      minPremium: [MIN_PREMIUM, [this.isMinMaxValid(null, 'maxPremium')]],
      maxPremium: [MAX_PREMIUM, [this.isMinMaxValid('minPremium', null)]],
      insuredName: [[]],
      products: [[]],
      producerId: '',
    });
    this.addSortTo = ['Quotes ID', 'Date created', 'Insured name', 'Status'];
  }

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
      this.shortDateTimeFormat = data.shortDateTimeFormat;
      this.longDateTimeFormat = data.longDateTimeFormat;
    });

    this.userID = this.localStorageService.getBoxxUserId();
    this.quoteFlowSteps = this.localStorageService.getQuoteFlowSteps();
    if (this.quoteFlowSteps === '7') {
      this.quoteFlowUrl = 'quote';
    } else if (this.quoteFlowSteps === '4') {
      this.quoteFlowUrl = 'quote4flow';
    } else {
      this.quoteFlowUrl = 'workflow3';
    }

    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));
    this.subNavData = [
      { name: 'All', active: true },
      { name: 'Open submissions', active: false },
      { name: 'Closed quotes', active: false },
    ];
    const route = this.activatedRoute.snapshot.params;
    this.producerId = route['producerId'];

    this.store
      .select(getProducerCreatedSelector)
      .pipe(take(1))
      .subscribe((value) => (this.isNavigatedFromAddProducer = value));
    this.store.dispatch(new resetProducerCreated());

    this.filterForm.controls['producerId'].setValue(this.producerId);
    this.loadResults(1, 10);
    this.riskRegionService.getAllRiskByUser(2).subscribe({
      next: (response) => {
        this.products = response.data.reduce((accumulator, value) => {
          return { ...accumulator, [value.name]: value };
        }, {});
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });
    this.loadProducerData();
  }

  isMinMaxValid(minValuePropName, maxValuePropName) {
    return (control: FormControl) => {
      if (!control || !control.parent) {
        return null;
      }
      let maxValue = 0;
      let minValue = 0;
      if (minValuePropName) {
        minValue = Number(control.parent.value[minValuePropName]);
        maxValue = Number(control.value);
      } else {
        maxValue = Number(control.parent.value[maxValuePropName]);
        minValue = Number(control.value);
      }

      if (maxValue < minValue) {
        return {
          customError: true,
        };
      } else {
        if (minValuePropName) {
          control.parent.controls[minValuePropName].errors = null;
          control.parent.controls[minValuePropName].status = 'VALID';
        } else {
          control.parent.controls[maxValuePropName].errors = null;
          control.parent.controls[maxValuePropName].status = 'VALID';
        }
      }

      return {};
    };
  }

  handleEditDetails(event) {
    event.stopPropagation();
    this.router.navigate([this.editProducerUrl], { skipLocationChange: true });
  }

  handleCloseSuccessEvent() {
    this.showErrorAlert = false;
  }
  loadProducerData() {
    this.brokerageProducerService
      .GetBrokerageProducerId(this.producerId)
      .subscribe({
        next: (response) => {
          if (!response.data) {
            let isMock = 'true';
            if (
              this.localStorageService.getMockExternalAPIs() === 'false' ||
              environment.mockExternalAPIs === false
            ) {
              isMock = 'false';
            }
            this.router.navigate(['dashboard/home'], {
              queryParams: {
                mock: isMock,
              },
              skipLocationChange: true,
            });
          }
          this.producer = response.data;
          const brokerageBranch = this.producer?.brokerageBranch;
          const brokerage = this.producer?.brokerageBranch?.brokerage;
          this.itemsMenu = [
            {
              label: 'Brokerage directory',
              path: '/dashboard/brokerage',
            },
            {
              label: `${brokerage?.name}`,
              path: `/dashboard/brokerage/view/${brokerage?.id}`,
            },
            {
              label: `${brokerageBranch?.name}`,
              path: `/dashboard/branch/view/${brokerageBranch?.id}/${brokerage?.id}`,
            },
            {
              label: `${this.producer?.firstName} ${this.producer?.lastName}`,
              path: null,
            },
          ];
          this.editProducerUrl = `/dashboard/branch/edit-producer/${brokerage?.id}/${brokerageBranch?.id}/${this.producerId}`;

          this.setSectionDate();
          const brokerageProducerBORId = this.producerId;
          const brokerageBranchBORId = brokerageBranch?.id;
          const brokerageBORId = brokerage?.id;
          this.brokerageProducerProfileService
            .GetBrokerageProduceCalcStatus({
              brokerageProducerBORId,
              brokerageBranchBORId,
              brokerageBORId,
            })
            .subscribe((data) => {
              const conversationRate = data.data.conversionRate ?? '0';
              const pendingQuotes = data.data.pendingQuotes ?? '0';

              this.infoTile = [
                {
                  heading: 'brokerage.producer.label.bindRatio',
                  count: Number((conversationRate * 100).toFixed(2)) + '%',
                },
                {
                  heading: 'brokerage.producer.label.pendingQuotes',
                  count: pendingQuotes,
                },
              ];
            });
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
    this.brokerageProducerService
      .GetProducerProfile(this.producerId)
      .subscribe({
        next: (response) => {
          this.producerProfile = response.data;
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });

    this.brokerageProducerProfileService
      .GetBrokerageAllProducerProfile(
        { brokerageProducerId: this.producerId, sort: 'id:desc' },
        1,
        1,
      )
      .subscribe({
        next: (response) => {
          // this.producerProfile = response.data[0];
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
  }

  handleNav(event, index) {
    const currentActiveIdx = this.subNavData.findIndex(
      (navObj) => navObj.active,
    );
    this.subNavData[currentActiveIdx].active = false;
    this.subNavData[index].active = true;
    this.loadResults(1, 10);
  }

  handleFilter() {
    this.showFilter = true;
    this.renderer.addClass(document.body, 'no-scroll');
  }

  handleExport() {
    this.showTblSpinner = true;
    const currentActiveIdx = this.subNavData.findIndex(
      (navObj) => navObj.active,
    );
    let filter = this.makeFilter();
    const quoteStatus = mapQuote(this.subNavData[currentActiveIdx].name);
    if (quoteStatus) {
      if (filter.quoteStatus) {
        filter.quoteStatus += ',' + quoteStatus;
      } else {
        filter = Object.assign(filter, { quoteStatus: quoteStatus });
      }
    }
    filter = Object.assign(filter, { entryType: 'quote' });
    filter = Object.assign(filter, { limit: this.totalDataCount });
    filter = Object.assign(filter, { page: 1 });

    this.policyDashboardService.PolicyExport(filter, this.sortBy).subscribe(
      (data: ArrayBuffer) => {
        excelFileDownload('ExportedData.xlsx', data);
        this.showTblSpinner = false;
      },
      (error) => {
        this.showTblSpinner = false;
        const alertData = {
          show: true,
          type: 'error',
          headerText: getAlertHead('error!'),
          bodyText: getErrorMessage(error),
        };
        this.alertService.addAlert(alertData);
      },
    );
  }

  formatPolicyRiskList(policyRiskList: any): {
    products: string;
    limit: string;
    premium: string;
  } {
    if (!policyRiskList || policyRiskList.length === 0) {
      return null;
    }
    let formattedObj: { products: string; limit: string; premium: string } = {
      products: '',
      limit: '',
      premium: '',
    };
    formattedObj.products = policyRiskList.map((item) => item.risk).join(', ');
    formattedObj.limit = policyRiskList
      .map((item) =>
        item.limit
          ? formatAmountWithCurrency(item.limit, this.currency)
          : formatAmountWithCurrency('0', this.currency),
      )
      .join(', ');
    formattedObj.premium = policyRiskList
      .map((item) =>
        item.premium
          ? formatAmountWithCurrency(item.premium, this.currency)
          : formatAmountWithCurrency('0', this.currency),
      )
      .join(', ');
    return formattedObj;
  }

  pageChangeHandler(pageNumber: number) {
    if (pageNumber > 0) {
      this.loadResults(pageNumber, 10);
    }
  }

  handleAscSort(key) {
    const sortBy = this.mapSortColumns(key);
    this.sortBy = sortBy + ':asc';
    this.loadResults(1, 10);
  }
  handleDescSort(key) {
    const sortBy = this.mapSortColumns(key);
    this.sortBy = sortBy + ':desc';
    this.loadResults(1, 10);
  }

  mapSortColumns(key) {
    let sortColumn;
    switch (key) {
      case 'Quotes ID':
        sortColumn = 'id';
        break;

      case 'Date created':
        sortColumn = 'createdDt';
        break;

      case 'Insured name':
        sortColumn = 'insuredName';
        break;

      case 'Status':
        sortColumn = 'quoteStatus';
        break;
    }
    return sortColumn;
  }

  handleFiltering(e) {
    this.loadResults(1, 10);
    this.showFilter = false;
    this.renderer.removeClass(document.body, 'no-scroll');
  }

  isArrSame(array1, array2) {
    return (
      array1.length == array2.length &&
      array1.every(function (element, index) {
        return element === array2[index];
      })
    );
  }

  makeFilter() {
    const periodFrom = this.filterForm.value['periodFrom'];
    const periodTo = this.filterForm.value['periodTo'];

    let periodDt = '';
    if (periodFrom && periodTo) {
      const formattedFromDate = getDateString(periodFrom, this.shortDateFormat);
      const formattedToDate = getDateString(periodTo, this.shortDateFormat);
      periodDt = `${formattedFromDate},${formattedToDate}`;
    }

    const quotePeriodVal = this.filterForm.value['quotedPeriod'];
    let quotePeriod = 0;
    if (quotePeriodVal == 'quotedToday') {
      quotePeriod = 1;
    } else if (quotePeriodVal == 'last7days') {
      quotePeriod = 7;
    } else if (quotePeriodVal == 'last30days') {
      quotePeriod = 30;
    }

    let filter: any = {
      brokerageProducerBORId: this.producerId,
      periodDt: periodDt,
      trxDays: quotePeriod,
    };

    const limitRange = `${this.filterForm.value[
      'minLimit'
    ].toString()},${this.filterForm.value['maxLimit'].toString()}`;
    filter.limitRange = limitRange;

    const deductibleRange = `${this.filterForm.value[
      'minDeductible'
    ].toString()},${this.filterForm.value['maxDeductible'].toString()}`;
    filter.deductibleRange = deductibleRange;

    const premiumRange = `${this.filterForm.value[
      'minPremium'
    ].toString()},${this.filterForm.value['maxPremium'].toString()}`;
    filter.premiumRange = premiumRange;

    const currentActiveIdx = this.subNavData.findIndex(
      (navObj) => navObj.active,
    );
    const quoteStatus = this.subNavData[currentActiveIdx].name;
    if (['Closed quotes', 'Open submissions'].includes(quoteStatus)) {
      if (quoteStatus == 'Closed quotes') {
        filter.quoteStatus = 'closed';
      } else {
        filter.quoteStatus = QuoteStatus.Submission.toLowerCase();
      }
    }

    const insuredNameArray = this.filterForm.value['insuredName'];
    if (insuredNameArray.length > 0) {
      const insuredIds = insuredNameArray.map((insuredName) => insuredName.id);
      filter.insuredId = insuredIds.join(',');
    }

    const productsArray = this.filterForm.value['products'];
    if (productsArray.length > 0) {
      const productIds = productsArray
        .map((p) => this.products[p].id)
        .join(',');
      filter.riskIds = productIds;
    }

    return filter;
  }

  loadResults(pageNumber: number, limit: number) {
    this.showTblSpinner = true;
    this.pageNumber = pageNumber;
    let filter = this.makeFilter();
    const args = {
      insuredType: -1,
      filter,
      page: pageNumber,
      limit,
      sort: this.sortBy,
    };
    this.policyDashboardService
      .GetAllQuoteByFilter(args)
      .subscribe({
        next: (response) => {
          const quoteList = response?.data?.map((dataObj) => {
            let brokerageCommissions =
              dataObj?.policyRiskTrxes?.['policyRisk'][0]
                ?.brokerCommissionPerc ?? 0;
            brokerageCommissions = brokerageCommissions * 100;
            return {
              brokerageCommission: brokerageCommissions * 100,
              quoteStatus: dataObj?.policyRiskTrxes?.quoteStatus?.description,
            };
          });

          const tableData = response.data?.map((dataObj) => {
            const filterdVal = this.formatPolicyRiskList(
              dataObj.policyRiskTrxes.policyRisk,
            );

            return {
              'Quotes ID': String(dataObj.policy.pkgPolicyNumber),
              'Date created': formatDate(
                dataObj.policy.createdDt,
                this.shortDateFormat,
              ),
              'Insured name': dataObj.policy.insured.companyName
                ? dataObj.policy.insured.companyName
                : dataObj.policy.insured.firstName +
                  ' ' +
                  dataObj.policy.insured.lastName,
              Products: filterdVal.products,
              Limit: formatAmountWithCurrency(filterdVal.limit, this.currency),
              Premium: formatAmountWithCurrency(
                filterdVal.premium,
                this.currency,
              ),
              Status:
                dataObj.policyRiskTrxes.quoteStatus.description.toLowerCase(),
            };
          });
          this.quoteList = quoteList.length == 0 ? this.quoteList : quoteList;
          this.tableData =
            tableData.length == 0 ? this.tableHeaders : tableData;
          this.totalDataCount = response.pagination.totalRecords;
          this.pagination = response.pagination;
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      })
      .add(() => {
        this.showTblSpinner = false;
      });
  }

  private setSectionDate() {
    this.createdBy = dateTimeWithUserName(
      this.producer.createdBy,
      this.producer.createdDt,
      this.longDateTimeFormat,
    );

    if (this.producer.updatedDt) {
      this.updatedBy = dateTimeWithUserName(
        this.producer.updatedBy,
        this.producer.updatedDt,
        this.longDateTimeFormat,
      );
    }
  }

  producerCreatedAlertClose() {
    this.isNavigatedFromAddProducer = false;
  }

  async quoteClickHandler(index) {
    this.store.dispatch(
      new CreateQuoteAction.UpdateInsuredAction(this.insuredData),
    );
    this.store.dispatch(
      new CreateQuoteAction.setPolicyPeriodId(this.policyPeriodIds[index]),
    );
    const quoteID = this.tableData[index]['Quotes ID'];
    const quoteStatus = this.tableData[index].Status;

    if (quoteStatus.toLowerCase() == QuoteStatus.Submission.toLowerCase()) {
      this.router.navigate(
        [`/dashboard/${this.quoteFlowUrl}/new/product/form/${quoteID}`],
        {
          relativeTo: this.activatedRoute,
          skipLocationChange: true,
        },
      );
    } else if (isStatusDisabled(this.quoteList[index]?.quoteStatus)) {
      //Checking whether the quote status included in disabled statuses, status should not  lowercase.

      this.router.navigate([`/dashboard/${this.quoteFlowUrl}/${quoteID}`], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
    } else if (this.quoteList[index].brokerageCommission === 0) {
      //Checking whether the commission is zero or not,
      this.router.navigate(
        [`/dashboard/${this.quoteFlowUrl}/new/product/form/${quoteID}`],
        {
          relativeTo: this.activatedRoute,
          skipLocationChange: true,
        },
      );
    } else {
      this.router.navigate([`/dashboard/${this.quoteFlowUrl}/${quoteID}`], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
    }
  }

  handleNotificationAlertClose() {
    this.notificationAlert.show = false;
  }
}
