import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { alertDetails } from '../entities/common';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private alerts: alertDetails[] = [];
  private alertSubject = new Subject<alertDetails[]>();
  alertTypeArray = ['success'];
  getAlerts(): Observable<alertDetails[]> {
    return this.alertSubject.asObservable();
  }

  addAlert(alert: alertDetails): boolean {
    let ind = this.alerts.findIndex((x) => x.bodyText === alert.bodyText);

    if (this.alertTypeArray.includes(alert.type) && ind == -1) {
      const sameTypeErrorIndex = this.alerts.findIndex(
        (x) => x.type === alert.type,
      );

      const sameTypeErrorCount = this.alerts.filter(
        (x) => x.type === alert.type,
      ).length;

      if (sameTypeErrorCount > 2) {
        this.alerts.splice(sameTypeErrorIndex, 1);
        this.alerts.push(alert);
      } else {
        this.alerts.push(alert);
      }
    } else {
      if (ind == -1) {
        this.alerts.push(alert);
      }
    }
    this.notifySubscribers();
    return true;
  }

  clearAlerts(index: number): void {
    if (index >= 0 && index < this.alerts.length) {
      this.alerts.splice(index, 1);
    } else {
      this.alerts = [];
    }
    this.notifySubscribers();
  }

  notifySubscribers(): void {
    this.alertSubject.next([...this.alerts]);
  }
}
