import { Brokerage } from 'src/app/entities/brokerage';
import * as actionTypes from './brokerage.action';

export interface BrokerageState {
  brokerage: Brokerage;
  brokerageCreated: boolean;
  branchCreated: boolean;
  producerCreated: boolean;
}

const initialState: BrokerageState = {
  brokerage: null,
  brokerageCreated: false,
  branchCreated: false,
  producerCreated: false,
};

export function createBrokerageReducer(
  state: BrokerageState = initialState,
  action: any,
): any {
  switch (action.type) {
    case actionTypes.SAVE_BROKERAGE:
      return {
        ...state,
        brokerage: { ...action.payload },
      };
    case actionTypes.SET_BROKERAGE_CREATED:
      return {
        ...state,
        brokerageCreated: true,
      };
    case actionTypes.RESET_BROKERAGE_CREATED:
      return {
        ...state,
        brokerageCreated: false,
      };
    case actionTypes.SET_BRANCH_CREATED:
      return {
        ...state,
        branchCreated: true,
      };
    case actionTypes.RESET_BRANCH_CREATED:
      return {
        ...state,
        branchCreated: false,
      };
    case actionTypes.SET_PRODUCER_CREATED:
      return {
        ...state,
        producerCreated: true,
      };
    case actionTypes.RESET_PRODUCER_CREATED:
      return {
        ...state,
        producerCreated: false,
      };
    default:
      return state;
  }
}
