import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-timestamp',
  templateUrl: './timestamp.component.html',
  styleUrls: ['./timestamp.component.less'],
})
export class TimestampComponent {
  @Input() label: string;
  @Input() value: string;
  @Input() containerStyle: { [klass: string]: any };
  @Input() valueStyle: { [klass: string]: any };

  constructor() {}
}
