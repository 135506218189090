import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  OnChanges,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.less'],
})
export class SideMenuComponent implements OnInit, OnChanges {
  releaseVersion: string;
  isPopoverOpen = false;
  versionPopupContent: any;
  @Input() sideMenu: Array<any> = [
    {
      src: 'assets/img/sidemenu/profile.png',
      hoverSrc: 'assets/img/sidemenu/hover/profile.png',
      href: '/route',
      name: 'profile',
    },
  ];

  @Output() toggleLogoutPopup = new EventEmitter();

  isExpanded = false;
  form: FormGroup;
  menu;
  isAllMenuExpanded: boolean = false;
  menuIconSrc: string;
  logoutIconSrc: string;

  constructor(private fb: FormBuilder) {
    this.releaseVersion = environment.releaseVersion;
  }
  ngOnInit(): void {
    this.form = this.fb.group({
      search: [''],
    });
    this.menu = this.sideMenu.map((menu) => ({
      ...menu,
      active: false,
      imgSrc: '',
    }));
  }

  ngOnChanges() {
    this.menu = this.sideMenu.map((menu) => ({
      ...menu,
      active: false,
      imgSrc: '',
    }));
  }

  toggleSidebar(event) {
    event.stopPropagation();
    this.isExpanded = true;
  }

  closeSidebar(event) {
    event.stopPropagation();
    this.isExpanded = false;
  }

  toggleSubMenu(event, index) {
    if (index !== 0) {
      event.preventDefault();
      this.menu[index].active = !this.menu[index].active;
      const collapsedMenus = this.menu.filter(
        (m) => m.submenu && m.submenu.length > 0 && m.active === false,
      );
      if (collapsedMenus.length <= 0) {
        this.isAllMenuExpanded = true;
      }
    } else {
      const currentUrl = window.location.pathname;
      if (currentUrl.includes('/dashboard/home')) {
        event.preventDefault();
      }
    }
  }

  handleExpandOrCollapseAll() {
    const collapsedMenus = this.menu.filter(
      (m) => m.submenu && m.submenu.length > 0 && m.active === false,
    );
    if (collapsedMenus.length <= 0) {
      this.menu = this.menu.map((menu) => ({ ...menu, active: false }));
      this.isAllMenuExpanded = false;
    } else {
      this.menu = this.menu.map((menu) => ({ ...menu, active: true }));
      this.isAllMenuExpanded = true;
    }
  }

  handleSubmit(e: any) {}

  onSearch(event) {
    if (!this.isExpanded) {
      this.isExpanded = !this.isExpanded;
    }
    const searchKey = event.target.value.toLowerCase();
    let target = this.sideMenu.map((menu) => ({ ...menu, active: true }));
    let getFilteredMenus = target.filter((m) => this.filterMenu(m, searchKey));
    getFilteredMenus.map((m, i) => {
      if (m.submenu && m.submenu.length > 0) {
        getFilteredMenus[i].submenu = m.submenu.filter((submenu) =>
          submenu.name.toLowerCase().includes(searchKey),
        );
      }
    });
    this.menu = getFilteredMenus;
    const collapsedMenus = this.menu.filter(
      (m) => m.submenu && m.submenu.length > 0 && m.active === false,
    );
    this.isAllMenuExpanded = collapsedMenus.length <= 0;
  }

  filterMenu(target, key) {
    if (target.name.toLowerCase().includes(key)) {
      return true;
    } else if (target.submenu && target.submenu.length > 0) {
      if (
        target.submenu.filter((s) => s.name.toLowerCase().includes(key))
          .length > 0
      ) {
        return true;
      }
    }
    return false;
  }

  stopMenuToggle(event) {
    if (this.isExpanded) {
      event.stopPropagation();
    }
  }

  handleLogout(event) {
    event.stopPropagation();
    this.toggleLogoutPopup.emit();
  }

  handleOutsideClick() {
    this.form.reset();
    this.menu = this.sideMenu;
    this.isExpanded = false;
  }
  populateVersionContent() {
    this.versionPopupContent = 'versionPopupContent';
  }
  openPopover() {
    if (!this.isPopoverOpen) {
      this.populateVersionContent();
      this.isPopoverOpen = true;
    } else {
      this.isPopoverOpen = false;
    }
  }

  closePopover() {
    this.isPopoverOpen = false;
  }
}
