<div class="modal-outer" *ngIf="showModal">
  <div class="modal-container">
    <div class="box">
      <span style="--i: 1"></span>
      <span style="--i: 2"></span>
      <span style="--i: 3"></span>
      <span style="--i: 4"></span>
      <span style="--i: 5"></span>
      <span style="--i: 6"></span>
      <span style="--i: 7"></span>
      <span style="--i: 8"></span>
      <span style="--i: 9"></span>
      <span style="--i: 10"></span>
    </div>
    <section class="modal-header">
      <div class="header-left">
        <div class="logout-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M16 17.5L21 12.5M21 12.5L16 7.5M21 12.5H9M9 3.5H7.8C6.11984 3.5 5.27976 3.5 4.63803 3.82698C4.07354 4.1146 3.6146 4.57354 3.32698 5.13803C3 5.77976 3 6.61984 3 8.3V16.7C3 18.3802 3 19.2202 3.32698 19.862C3.6146 20.4265 4.07354 20.8854 4.63803 21.173C5.27976 21.5 6.11984 21.5 7.8 21.5H9"
              stroke="#D46B00"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <h2 class="head">
          {{ "workFlow3.components.logoutPopup.label.logout" | translate }}
        </h2>
      </div>
      <div class="header-right">
        <boxxds-button-v2
          [isDisabled]="false"
          [size]="'lg'"
          [btnType]="'close'"
          (handleClick)="closeModal()"
        >
        </boxxds-button-v2>
      </div>
    </section>
    <section class="info-banner"></section>
    <section class="modal-content">
      <p class="paragh">
        {{ "workFlow3.components.logoutPopup.content" | translate }}
      </p>
    </section>
    <section class="modal-footer">
      <div class="footer-content">
        <boxxds-button-v2
          class="footer-button"
          [buttonText]="'workFlow3.components.shared.button.logout' | translate"
          [isDisabled]="false"
          [size]="'lg'"
          [btnClass]="'primary'"
          [btnType]="'default'"
          (handleClick)="handleLogoutClick()"
          [btnStyle]="{ width: '176px' }"
        >
        </boxxds-button-v2>
      </div>
    </section>
  </div>
</div>
