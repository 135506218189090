import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, firstValueFrom, take } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Quote } from 'src/app/models/quote.model';
import {
  getAllMailIds,
  getQuoteSelector,
  getUIData,
} from 'src/app/store/create-quote/create-quote.selector';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { PolicyRiskService } from 'src/app/services/policy-risk.service';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import { RiskTemplateService } from 'src/app/services/risk-template';
import { DomainsService } from 'src/app/services/domains.service';
import { RiskRegionSubjectivityService } from 'src/app/services/risk-region-subjectivity.service';
import { PolicySubjectivityService } from 'src/app/services/policy-subjectivity.service';
import { PolicyRiskDocService } from 'src/app/services/policy-risk-doc.service';
import moment from 'moment';
import { PolicyPeriodService } from 'src/app/services/policy-period.service';
import {
  TEMPLATETYPE,
  TEMPLATETYPE_ENDORSEMENT,
  TEMPLATETYPE_POLICYWORDING,
  DOCUMENTTYPE,
  DOCUMENTTYPE_ENDORSEMENT,
  DOCUMENTTYPE_POLICY,
  TEMPLATETYPE_DECLARATION,
  DOCUMENTTYPE_DECLARATION,
  INSURED_COMPANY_TYPE,
  GENERATE_DOC_SUCCESS_STATUS_CODE,
  EFFECTIVE_DATE_MAX_RANGE_LIMIT,
  DOC_GENERATION_WAIT_TIME,
} from 'src/app/constants/quote-constant';
import { PolicyRiskTrxService } from 'src/app/services/policy-risk-trx.service';
import { PolicyRiskDocProcessService } from 'src/app/services/policy-risk-doc-process.service';
import { PolicyRiskDocPreviewService } from 'src/app/services/policy-risk-doc-preview.service';
import {
  calculateDateAfterMonths,
  formatDate,
  getCityTime,
  getDateFormatForDatePicker,
  getDayAfterSpecifiedDays,
  getDayBeforeSpecifiedDays,
  getFormatDate,
} from 'src/app/utils/formatDate';
import { InsuredService } from 'src/app/services/insured.service';
import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import { getLifecycleState } from 'src/app/store/lifecycle/lifecycle.select';
import { TranslateService } from '@ngx-translate/core';
import { getAlertHead, getErrorMessage } from 'src/app/utils/utils';
import { DocumentTemplateService } from 'src/app/services/doc-template.service';
import { NewQuoteService } from 'src/app/services/new-quote.service';
import { GenerateQuoteService } from 'src/app/services/doc-create.service';
import { ConfMessageTemplateService } from 'src/app/services/message-template-services';
import { PolicyBindService } from 'src/app/services/policy-bind.service';
import { TransactionRiskActions } from 'src/app/dashboard/constants/lifecycle-actions';
import { PolicyDeclineRequest } from 'src/app/entities/policy-lifecycle';
import { PolicyLifecycleService } from 'src/app/services/policy-lifecycle-service';
import { AlertService } from 'src/app/services/alert.service';
import { MessageSendService } from 'src/app/services/message-send.service';
import { USER_ROLES } from 'src/app/constants/security/systemUserType';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/services/localstorage-service';
@Component({
  selector: 'app-policy-configuration',
  templateUrl: './policy-configuration.component.html',
  styleUrls: ['./policy-configuration.component.less'],
})
export class PolicyConfigurationComponent implements OnInit, OnDestroy {
  form$: Observable<Quote>;
  isContinueFormValid: boolean = true;
  isTBDValid: boolean = true;
  underWriters: string[] = [];
  underWriterOptions: any = [];
  docOptions = [
    {
      key: 'Document 3',
      value: 'Document 3',
      disabled: false,
      url: 'assets/docs/sampledocument.pdf',
    },
    {
      key: 'Document 4',
      value: 'Document 4',
      disabled: false,
      url: 'assets/docs/sampledocument.pdf',
    },
    {
      key: 'Insured Submission Form_2023',
      value: 'Insured Submission Form_2023',
      disabled: false,
      url: 'assets/docs/sampledocument.pdf',
    },
  ];
  wordSelectOptions = [
    { key: 'Word 1', value: 'Word 1' },
    { key: 'Word 2', value: 'Word 2' },
  ];
  showDocPreview = false;
  currentPreviewDocUrl = '';
  policyPeriodId: number = 0;
  quoteOptions = [];
  products = [];
  productsIds = [];
  riskRegionId = 0;
  templateCodeForEndorsement = 0;
  templateCodeForWordings = 0;
  templateCodeForDeclaration = 0;
  documentCodeForEndorsement = 0;
  documentCodeForWordings = 0;
  documentCodeForDeclaration = 0;
  riskTrnxs;
  underwriterTotalCount = 0;
  underwriterTotalPages = 1;
  disabledDropdownList: string[] = [];
  productList: { [x: string]: any }[] = [];

  endorsementOptions: any[] = [
    { key: '', value: '', disabled: false, url: '' },
  ];
  wordingsOptions: any[] = [{ key: '', value: '', disabled: false, url: '' }];
  subjectivityOptns: any[] = [{ key: '', value: '', disabled: false, url: '' }];
  declarationOptions: any[] = [
    { key: '', value: '', disabled: false, url: '' },
  ];
  subjectivityMandatory = [];
  selectedSubjectivities = [];
  endorsementsMandatory = [];
  selectedEndorsements = [];
  selectedWordings = [];
  selectedDeclarations = [];
  addons = [];
  policyRiskIds = [];
  showBodySpinner: boolean = false;
  policyRisks = [];
  underWritersList: any;
  wordingsDeclarationError = false;
  spinnerCounter = 0;
  endDateEditable: boolean = false;
  minStartDate = new Date();
  maxStartDate = new Date();
  startDate: string = '';
  endDate: string = '';
  defaultEffectiveDate;
  defaultExpiryDate;
  minEndDate;
  maxEndDate;
  isStartDateValid = true;
  isEndDateValid = true;
  startDateErrMsg;
  endDateErrMsg;
  insuredTimezone;
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  addedSubjectivities = [];
  editedSubjectivity = '';
  currentQuoteStatus: string = '';
  stageId: number;
  isLoader: boolean = false;
  showSendModal = false;
  showErrorAlert = false;
  alertMessage = '';
  docPopupDetails: {
    statusType: string;
    action: any;
    documentTemplate: any;
    policyPeriodId: any;
    stageId: any;
  };
  showDocTempSelectionPopup: boolean = false;
  isQuoteBounded = false;
  templateDetails: { [x: string]: string | number }[] = [];
  docDetails: { [x: string]: string | number | boolean }[] = [];
  rowDocDetails: { [x: string]: string | number | boolean } = {};
  messageTypeId: number;
  newTimeout: NodeJS.Timeout;
  isBindingPolicy = false;
  policyFeeEnabled = false;
  TRIAFeeEnabled = false;
  policyPremium: number;
  triaAmount: number;
  policyAmount: number;
  bindingStatus = 'default';
  packagePolicyNumber: string = '';
  currency = '';
  actionPopupDetails: {
    quoteNumber: string;
    effectiveDate: string;
    insured: string;
    brokerage: string;
    branch: string;
    reasonOptions: any[];
    action: TransactionRiskActions;
  };
  showActionPopup: boolean = false;
  public transactionRiskActions = TransactionRiskActions;
  policyRiskFee = [];
  totalBilledAnnually = 0;
  quoteId = null;
  mailData: { [x: string]: string | string[] } = {};
  brokerCommission = 0;
  tbdOptions = [];
  shortDateFormat: string = '';
  longDateFormat: string = '';
  endDateRef;
  startDateRef;
  policyRiskId;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private policyRiskService: PolicyRiskService,
    private boxxUserService: BoxxUserService,
    private riskRegionService: RiskRegionService,
    private riskTemplateService: RiskTemplateService,
    private domainsService: DomainsService,
    private riskRegionSubjectivityService: RiskRegionSubjectivityService,
    private policySubjectivityService: PolicySubjectivityService,
    private policyRiskDocService: PolicyRiskDocService,
    private policyRiskTrxService: PolicyRiskTrxService,
    private policyRiskDocProcessService: PolicyRiskDocProcessService,
    private PolicyRiskDocPreviewService: PolicyRiskDocPreviewService,
    private datePipe: DatePipe,
    private policyPeriodService: PolicyPeriodService,
    private insuredService: InsuredService,
    private translate: TranslateService,
    private DocumentTemplateService: DocumentTemplateService,
    private newQuoteService: NewQuoteService,
    private generateQuoteService: GenerateQuoteService,
    private messageTemplateService: ConfMessageTemplateService,
    private policyBindService: PolicyBindService,
    private policyLifecycleService: PolicyLifecycleService,
    private alertService: AlertService,
    private messageSendService: MessageSendService,
    private fb: FormBuilder,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    this.store.pipe(select(getLifecycleState)).subscribe((data) => {
      this.currentQuoteStatus = data.quoteStatus;
    });

    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));

    this.docPopupDetails = {
      statusType: 'Document template selection',
      action: 'docTempSelection',
      documentTemplate: [],
      policyPeriodId: 0,
      stageId: 0,
    };

    this.form$ = this.store.pipe(select(getQuoteSelector));
    this.form$.subscribe(
      (event) => (this.policyPeriodId = Number(event.ui.policyPeriodId)),
    );
    this.tbdOptions = [{ name: 'TBD', checked: this.isTBDValid }];
    this.form$.subscribe((event) => {
      this.productList = event.products;
      this.startDate = event.ui.startDate;
      this.endDate = event.ui.endDate;
    });
    this.populateUnderwriterOptions();
    this.loadPageData();
  }

  ngOnDestroy(): void {
    this.alertService.clearAlerts(-1);
    this.showBodySpinner = false;
  }

  async loadPageData(isFromSendQuote = false) {
    this.spinnerCounter = 0;
    this.showBodySpinner = false;
    this.spinnerCounter++;
    let policyFee = 0;
    let policyFeeDefault = 0;
    let triaAmount = 0;
    let triaAmountDefault = 0;
    let policyPremium = 0;
    let latestPolicyRiskFee = [];

    this.policyRiskService
      .GetAllByPolicyPeriodId(this.policyPeriodId)
      .subscribe(async (quoteData) => {
        this.policyRisks = quoteData?.data;
        this.policyRiskId = this.policyRisks[0].PolicyRiskTrxes[0].policyRiskId;

        this.quoteOptions = quoteData.data.flatMap((dataObj) => {
          latestPolicyRiskFee.push(...dataObj?.PolicyRiskTrxes);
          const quoteOptions = dataObj.PolicyRiskTrxes[0]?.QuoteOptions || [];
          return quoteOptions;
        });
        this.policyRiskFee = latestPolicyRiskFee;
        policyFee = Number(this.policyRiskFee[0].policyFee);
        triaAmount = Number(this.policyRiskFee[0].triaAmount);
        this.brokerCommission = Number(
          this.policyRiskFee[0].brokerCommissionPerc * 100,
        );
        this.policyRiskFee.forEach((product) => {
          policyPremium = policyPremium + Number(product.premium);
        });
        // todo
        // default valued for tria and policy fee are calculating here
        // need to do rework for multiple products
        const riskObj = quoteData.data[0].risk;
        if (riskObj.policyFeeEnabled === 1) {
          policyFeeDefault = Number(riskObj.policyFeeAmount) ?? 0;
        }
        if (riskObj.triaEnabled === true) {
          triaAmountDefault =
            (Number(policyPremium) * (Number(riskObj.triaPremiumPerc) * 100)) /
              100 ?? 0;
        }

        this.policyAmount = policyFee;
        this.triaAmount = triaAmount;
        this.policyPremium = policyPremium;
        this.TRIAFeeEnabled = Number(this.triaAmount || 0) !== 0;
        this.policyFeeEnabled = Number(this.policyAmount || 0) !== 0;
        const serviceAmount = this.getServices();
        const taxAmount = this.getTaxes();
        this.totalBilledAnnually = await this.getTotalBilledAnnual();
        this.store.dispatch(
          new CreateQuoteAction.updateUiContents({
            annualPremium: this.totalBilledAnnually,
            quote4FlowPremiumDetails: {
              policyAmount: this.policyAmount,
              triaAmount: this.triaAmount,
              triaAmountDefault: triaAmountDefault,
              policyPremium: this.policyPremium,
              policyFeeDefault: policyFeeDefault,
              TRIAFeeEnabled: this.TRIAFeeEnabled,
              policyFeeEnabled: this.policyFeeEnabled,
              totalBilledAnnually: this.totalBilledAnnually,
              serviceAmount: serviceAmount,
              taxAmount: taxAmount,
            },
          }),
        );
        const insuredRespObj =
          quoteData?.data[0]?.policyPeriod?.policy?.insured;
        const insuredId = quoteData?.data[0]?.policyPeriod?.policy?.insuredId;
        this.quoteId = quoteData?.data[0]?.policyPeriod?.policy?.id;

        const insuredDetails = {
          name:
            insuredRespObj?.insuredType == INSURED_COMPANY_TYPE
              ? insuredRespObj?.companyName
              : insuredRespObj?.firstName + insuredRespObj?.lastName,
        };

        this.insuredService.GetByInsuredId(insuredId).subscribe({
          next: async (data) => {
            this.insuredTimezone = data.data.insuredLocation.timezone;
          },
        });

        let currentDate = getCityTime(this.insuredTimezone);

        this.store.dispatch(
          new CreateQuoteAction.UpdateInsuredAction({
            insuredName: insuredDetails.name,
            insuredEmail: null,
            insuredId,
            policyId: Number(this.quoteId),
          }),
        );
        //Date details
        const effectiveExpiryDatesTBDFlag =
          quoteData?.data[0]?.policyPeriod?.effectiveExpiryDatesTBDFlag;
        this.tbdOptions[0].checked = effectiveExpiryDatesTBDFlag;
        this.isTBDValid = effectiveExpiryDatesTBDFlag;

        const effectiveDate = quoteData?.data[0]?.policyPeriod?.effectiveDt;
        const expiryDate = quoteData?.data[0]?.policyPeriod?.expiryDt;
        let startDate = getDateFormatForDatePicker(
          effectiveDate,
          this.shortDateFormat,
        );
        let endDate = getDateFormatForDatePicker(
          expiryDate,
          this.shortDateFormat,
        );
        if (!this.isTBDValid) {
          if (effectiveDate) {
            this.store.dispatch(
              new CreateQuoteAction.updateUiContents({
                startDate: startDate,
              }),
            );
          }
          if (expiryDate) {
            this.store.dispatch(
              new CreateQuoteAction.updateUiContents({
                endDate: endDate,
              }),
            );
          }
        }

        let defaultStartDate;
        let defaultEndDate;

        if (!effectiveDate) {
          defaultStartDate = currentDate.currentDate ?? this.getTomorrow();
          let startDate = this.getFormattedDate(defaultStartDate);
          if (!this.isTBDValid) {
            this.store.dispatch(
              new CreateQuoteAction.updateUiContents({
                startDate: startDate,
              }),
            );
          }
        } else {
          defaultStartDate = new Date(effectiveDate);
        }
        this.defaultEffectiveDate = this.datePipe.transform(
          new Date(effectiveDate),
          this.shortDateFormat,
        );
        if (!expiryDate) {
          // TODO check new date it will fail if user login from different location
          defaultEndDate =
            currentDate.endDate ??
            this.datePipe.transform(
              new Date(new Date().getTime() + 24 * 60 * 60 * 1000).setFullYear(
                new Date(
                  new Date().getTime() + 24 * 60 * 60 * 1000,
                ).getFullYear() + 1,
              ),
              this.shortDateFormat,
            );
          if (!this.isTBDValid) {
            this.store.dispatch(
              new CreateQuoteAction.updateUiContents({
                endDate: defaultEndDate,
              }),
            );
          }
        } else {
          defaultEndDate = new Date(expiryDate);
        }
        this.defaultExpiryDate = this.datePipe.transform(
          new Date(expiryDate),
          this.shortDateFormat,
        );
        this.minStartDate = new Date();
        this.maxStartDate = new Date();
        this.minStartDate.setDate(this.minStartDate.getDate() - 30);
        this.maxStartDate.setDate(
          this.maxStartDate.getDate() + EFFECTIVE_DATE_MAX_RANGE_LIMIT,
        );

        this.minEndDate = new Date(defaultStartDate);
        this.maxEndDate = new Date(defaultStartDate);
        this.minEndDate.setMonth(defaultStartDate.getMonth() + 8);
        this.maxEndDate.setMonth(defaultStartDate.getMonth() + 18);

        this.riskTrnxs = quoteData.data.flatMap((dataObj) => {
          const riskTrnxs = dataObj.PolicyRiskTrxes || {};
          return riskTrnxs;
        });
        let latestUnderwriterList = [];
        let latestUnderwriterMailIdList = [];
        this.riskTrnxs.forEach((trnx) => {
          this.policyRiskIds.push(trnx.policyRiskId);
          this.spinnerCounter++;
          this.boxxUserService
            .GetById(trnx.underwriterId)
            .subscribe((dataUW) => {
              latestUnderwriterList = [
                ...latestUnderwriterList,
                dataUW.data?.firstName + ' ' + dataUW.data?.lastName,
              ];
              latestUnderwriterMailIdList = [
                ...latestUnderwriterMailIdList,
                dataUW.data?.systemUser?.loginEmail ?? '',
              ];
              this.underWriters = latestUnderwriterList;
              const emailIds = this.store.pipe(select(getAllMailIds));
              emailIds
                .pipe(take(1))
                .subscribe((email) => (this.mailData = email));
              let mailIdList = {
                ...this.mailData,
                underwriter: [...latestUnderwriterMailIdList],
              };
              this.store.dispatch(
                new CreateQuoteAction.setAllMailId(mailIdList),
              );

              if (isFromSendQuote) {
                this.handleSendQuote();
              }
              if (this.underWriters.length === this.riskTrnxs.length) {
                this.productList.map((product: any) => {
                  if (product.hasSubProduct) {
                    this.disabledDropdownList = [
                      ...this.disabledDropdownList,
                      product.subProductName,
                    ];
                  }
                });
              }
              this.spinnerCounter--;
            });
        });

        this.products = quoteData.data.flatMap((dataObj) => {
          const products = dataObj.risk.name || '';
          return products;
        });
        this.productsIds = quoteData.data.flatMap((dataObj) => {
          const productsIds = dataObj.risk.id || '';
          return productsIds;
        });

        const selectedQuoteOption =
          this.quoteOptions.filter(
            (a) => a.optionSelected == 1 && a.QuoteOptionServices.length > 0,
          ) ?? [];
        if (selectedQuoteOption.length > 0) {
          const riskIds = this.productsIds.join(',');
          let addons = [];
          this.spinnerCounter++;
          this.riskRegionService
            .getRiskServicesById(riskIds)
            .subscribe((dataAddons) => {
              addons = dataAddons.data?.map((item: any, index, arr) => ({
                description: item.description,
                id: item.id,
                serviceName: item.serviceName,
                riskId: item.risk.id,
                riskName: item.risk.name,
              }));

              if (addons.length > 0) {
                this.productList.forEach((item: any) => {
                  this.addons[item.key] = [];
                  var addonArr =
                    addons.filter((a) => a.riskId == item.riskId) ?? [];
                  addonArr.forEach((addon: any) => {
                    var riskServInd =
                      selectedQuoteOption[0].QuoteOptionServices.findIndex(
                        (a) => a.riskServicesId == addon.id,
                      );
                    if (riskServInd != -1) {
                      this.addons[item.key].push(addon);
                    }
                  });
                });
              }
              this.spinnerCounter--;
            });
        }
        this.spinnerCounter++;
        this.domainsService
          .GetByDomainCode(TEMPLATETYPE)
          .subscribe((dataTemplate) => {
            this.templateCodeForEndorsement =
              dataTemplate.data.filter(
                (d) => d.subdomaincode == TEMPLATETYPE_ENDORSEMENT,
              )[0]?.id ?? -1;
            this.templateCodeForWordings =
              dataTemplate.data.filter(
                (d) => d.subdomaincode == TEMPLATETYPE_POLICYWORDING,
              )[0]?.id ?? -1;
            this.templateCodeForDeclaration =
              dataTemplate.data.filter(
                (d) => d.subdomaincode == TEMPLATETYPE_DECLARATION,
              )[0]?.id ?? -1;
            this.spinnerCounter++;
            this.domainsService
              .GetByDomainCode(DOCUMENTTYPE)
              .subscribe((dataDoc) => {
                this.documentCodeForEndorsement =
                  dataDoc.data.filter(
                    (d) => d.subdomaincode == DOCUMENTTYPE_ENDORSEMENT,
                  )[0]?.id ?? -1;
                this.documentCodeForWordings =
                  dataDoc.data.filter(
                    (d) => d.subdomaincode == DOCUMENTTYPE_POLICY,
                  )[0]?.id ?? -1;
                this.documentCodeForDeclaration =
                  dataDoc.data.filter(
                    (d) => d.subdomaincode == DOCUMENTTYPE_DECLARATION,
                  )[0]?.id ?? -1;

                for (const [indexval, obj] of quoteData.data.entries()) {
                  this.spinnerCounter++;
                  this.riskRegionService
                    .getRiskRegionByRegionId(
                      obj.policyPeriod.policy.insured.insuredLocation.regionId,
                      obj.risk.id,
                    )
                    .subscribe((dataRiskreg) => {
                      dataRiskreg.data.forEach((objRegion, indexReg) => {
                        this.riskRegionId = objRegion.id;
                        for (const [
                          index,
                          product,
                        ] of this.products.entries()) {
                          this.spinnerCounter++;
                          // get subjectivities for each product
                          this.getAllLatestSubjectivities(product, index);
                          this.spinnerCounter++;
                          // get endorsement
                          this.riskTemplateService
                            .getRiskTemplate(
                              1,
                              this.riskRegionId,
                              this.templateCodeForEndorsement,
                            )
                            .subscribe((dataEnd) => {
                              this.endorsementOptions[product] =
                                dataEnd.data.map((endorsementDoc) => {
                                  return {
                                    key: endorsementDoc.templateDescription,
                                    value: endorsementDoc.templateDescription,
                                    disabled: !!endorsementDoc.mandatory,
                                    mandatory: endorsementDoc.mandatory,
                                    url: endorsementDoc.templatePath,
                                    templateType: endorsementDoc.templateType,
                                    policyRiskId: this.policyRiskIds[index],
                                    riskTemplateId: endorsementDoc.id,
                                    dataInjection: endorsementDoc.dataInjection,
                                  };
                                });
                              this.selectedEndorsements[product] =
                                this.endorsementOptions[product]
                                  .filter(
                                    (endorsement) => endorsement.mandatory,
                                  )
                                  .map((endorsement) => ({
                                    ...endorsement,
                                    selected: true,
                                  }));
                              let distinctEndArray: any[] =
                                this.selectedEndorsements[product].filter(
                                  (obj, index, array) =>
                                    array.findIndex(
                                      (o) =>
                                        obj !== null &&
                                        o?.riskTemplateId ===
                                          obj?.riskTemplateId,
                                    ) === index,
                                );
                              this.selectedEndorsements[product] =
                                distinctEndArray;
                              this.spinnerCounter++;
                              this.policyRiskDocProcessService
                                .getPolicyRiskDocCore(this.policyRiskIds[index])
                                .subscribe((dataRiskTempExist) => {
                                  // get policy endorsement docs
                                  let selectedEndorsementDocs =
                                    dataRiskTempExist.data.filter(
                                      (doc) =>
                                        doc.templateType ==
                                        this.templateCodeForEndorsement,
                                    );
                                  let selectedEndorseDocs = {
                                    [product]: selectedEndorsementDocs.map(
                                      (doc) => {
                                        let endorsementDocDetails =
                                          this.endorsementOptions[
                                            product
                                          ].filter(
                                            (optn) =>
                                              optn.riskTemplateId ==
                                              doc?.riskTemplate.id,
                                          );

                                        return {
                                          key:
                                            endorsementDocDetails[0]?.key || '',
                                          value:
                                            endorsementDocDetails[0]?.value ||
                                            '',
                                          disabled:
                                            !!endorsementDocDetails[0]
                                              ?.mandatory,
                                          mandatory:
                                            endorsementDocDetails[0]?.mandatory,
                                          templateType:
                                            endorsementDocDetails[0]
                                              ?.templateType,
                                          policyRiskId:
                                            this.policyRiskIds[index],
                                          url:
                                            endorsementDocDetails[0]?.url ?? '',
                                          selected: true,
                                          documentTblEndorsId: doc?.id || '',
                                          riskTemplateId:
                                            endorsementDocDetails[0]
                                              ?.riskTemplateId ?? null,
                                        };
                                      },
                                    ),
                                  };

                                  if (selectedEndorseDocs[product].length > 0) {
                                    this.selectedEndorsements[product] = [
                                      ...selectedEndorseDocs[product],
                                    ];
                                    let distinctEndArray: any[] =
                                      this.selectedEndorsements[product].filter(
                                        (obj, index, array) =>
                                          array.findIndex(
                                            (o) =>
                                              obj !== null &&
                                              o?.riskTemplateId ===
                                                obj?.riskTemplateId,
                                          ) === index,
                                      );
                                    this.selectedEndorsements[product] =
                                      distinctEndArray;
                                  }
                                  this.spinnerCounter--;
                                });
                              this.spinnerCounter--;
                            });
                          this.spinnerCounter++;
                          // get wordings
                          this.riskTemplateService
                            .getRiskTemplate(
                              1,
                              this.riskRegionId,
                              this.templateCodeForWordings,
                            )
                            .subscribe((dataWord) => {
                              this.wordingsOptions[product] = dataWord.data.map(
                                (wordings) => {
                                  return {
                                    key: wordings.templateDescription,
                                    value: wordings.templateDescription,
                                    disabled: !!wordings.mandatory,
                                    mandatory: wordings.mandatory,
                                    url: wordings.templatePath,
                                    templateType: wordings.templateType,
                                    policyRiskId: this.policyRiskIds[index],
                                    riskTemplateId: wordings.id,
                                    dataInjection: wordings.dataInjection,
                                  };
                                },
                              );

                              this.selectedWordings[product] = this
                                .wordingsOptions[product]
                                ? this.wordingsOptions[product]
                                    .filter((wording) => wording.mandatory)
                                    .map((word, key) => ({
                                      ...word,
                                      selected: true,
                                    }))
                                : [];
                              let distinctWordArray: any[] =
                                this.selectedWordings[product].filter(
                                  (obj, index, array) =>
                                    array.findIndex(
                                      (o) =>
                                        obj !== null &&
                                        o?.riskTemplateId ===
                                          obj?.riskTemplateId,
                                    ) === index,
                                );
                              this.selectedWordings[product] =
                                distinctWordArray;
                              this.spinnerCounter++;
                              this.policyRiskDocProcessService
                                .getPolicyRiskDocCore(this.policyRiskIds[index])
                                .subscribe((dataRiskTempExist) => {
                                  // get policy wordings docs
                                  let selectedWordingsDocs =
                                    dataRiskTempExist.data.filter(
                                      (doc) =>
                                        doc?.templateType ==
                                        this.templateCodeForWordings,
                                    );

                                  let selectedWordingDocs = {
                                    [product]: selectedWordingsDocs.map(
                                      (doc) => {
                                        let wordingDocDetails =
                                          this.wordingsOptions[product].filter(
                                            (optn) =>
                                              optn.riskTemplateId ==
                                              doc?.riskTemplate.id,
                                          );

                                        return {
                                          key: wordingDocDetails[0]?.key || '',
                                          value:
                                            wordingDocDetails[0]?.value || '',
                                          disabled:
                                            !!wordingDocDetails[0]?.mandatory,
                                          mandatory:
                                            wordingDocDetails[0]?.mandatory,
                                          templateType:
                                            wordingDocDetails[0]?.templateType,
                                          policyRiskId:
                                            this.policyRiskIds[index],
                                          url: wordingDocDetails[0]?.url ?? '',
                                          selected: true,
                                          documentTblWordId: doc?.id,
                                          riskTemplateId:
                                            wordingDocDetails[0]
                                              ?.riskTemplateId ?? null,
                                        };
                                      },
                                    ),
                                  };

                                  if (selectedWordingDocs[product].length > 0) {
                                    this.selectedWordings[product] =
                                      selectedWordingDocs[product];
                                    let distinctWordArray: any[] =
                                      this.selectedWordings[product].filter(
                                        (obj, index, array) =>
                                          array.findIndex(
                                            (o) =>
                                              obj !== null &&
                                              o?.riskTemplateId ===
                                                obj?.riskTemplateId,
                                          ) === index,
                                      );
                                    this.selectedWordings[product] =
                                      distinctWordArray;
                                  }
                                  this.spinnerCounter--;
                                });
                              this.spinnerCounter--;
                            });
                          this.spinnerCounter++;
                          // get Declarations
                          this.riskTemplateService
                            .getRiskTemplate(
                              1,
                              this.riskRegionId,
                              this.templateCodeForDeclaration,
                            )
                            .subscribe((dataWord) => {
                              this.declarationOptions[product] =
                                dataWord.data.map((declarations) => {
                                  return {
                                    key: declarations.templateDescription,
                                    value: declarations.templateDescription,
                                    disabled: !!declarations.mandatory,
                                    mandatory: declarations.mandatory,
                                    url: declarations.templatePath,
                                    templateType: declarations.templateType,
                                    policyRiskId: this.policyRiskIds[index],
                                    riskTemplateId: declarations.id,
                                    dataInjection: declarations.dataInjection,
                                  };
                                });

                              this.selectedDeclarations[product] = this
                                .declarationOptions[product]
                                ? this.declarationOptions[product]
                                    .filter((wording) => wording.mandatory)
                                    .map((word, key) => ({
                                      ...word,
                                      selected: true,
                                    }))
                                : [];
                              let distinctDecArray: any[] =
                                this.selectedDeclarations[product].filter(
                                  (obj, index, array) =>
                                    array.findIndex(
                                      (o) =>
                                        obj !== null &&
                                        o?.riskTemplateId ===
                                          obj?.riskTemplateId,
                                    ) === index,
                                );
                              this.selectedDeclarations[product] =
                                distinctDecArray;
                              this.spinnerCounter++;
                              this.policyRiskDocProcessService
                                .getPolicyRiskDocCore(this.policyRiskIds[index])
                                .subscribe((dataRiskTempExist) => {
                                  // get policy declaration docs
                                  let selectedDeclarationsDocs =
                                    dataRiskTempExist?.data.filter(
                                      (doc) =>
                                        doc.templateType ==
                                        this.templateCodeForDeclaration,
                                    );

                                  let selectedDeclarationDocs = {
                                    [product]: selectedDeclarationsDocs.map(
                                      (doc) => {
                                        let declarationDocDetails =
                                          this.declarationOptions[
                                            product
                                          ].filter(
                                            (optn) =>
                                              optn.riskTemplateId ==
                                              doc?.riskTemplate.id,
                                          );

                                        return {
                                          key:
                                            declarationDocDetails[0]?.key || '',
                                          value:
                                            declarationDocDetails[0]?.value ||
                                            '',
                                          disabled:
                                            !!declarationDocDetails[0]
                                              ?.mandatory,
                                          mandatory:
                                            declarationDocDetails[0]?.mandatory,
                                          templateType:
                                            declarationDocDetails[0]
                                              ?.templateType,
                                          policyRiskId:
                                            this.policyRiskIds[index],
                                          url:
                                            declarationDocDetails[0]?.url ?? '',
                                          selected: true,
                                          documentTblDeclarationId: doc?.id,
                                          riskTemplateId:
                                            declarationDocDetails[0]
                                              ?.riskTemplateId ?? null,
                                        };
                                      },
                                    ),
                                  };

                                  if (
                                    selectedDeclarationDocs[product].length > 0
                                  ) {
                                    this.selectedDeclarations[product] =
                                      selectedDeclarationDocs[product];
                                    let distinctDecArray: any[] =
                                      this.selectedDeclarations[product].filter(
                                        (obj, index, array) =>
                                          array.findIndex(
                                            (o) =>
                                              obj !== null &&
                                              o?.riskTemplateId ===
                                                obj?.riskTemplateId,
                                          ) === index,
                                      );
                                    this.selectedDeclarations[product] =
                                      distinctDecArray;
                                  }
                                  this.spinnerCounter--;
                                });
                              this.spinnerCounter--;
                            });
                        }
                      });
                      this.spinnerCounter--;
                    });
                }
                this.spinnerCounter--;
              });
            this.spinnerCounter--;
          });
        this.spinnerCounter--;
      });
  }

  getTotalPremium() {
    let total = 0;
    this.policyRiskFee.map((product) => {
      total += Number(product.premium);
    });
    return total;
  }
  getServices() {
    let total = 0;
    total += Number(this.policyRiskFee[0].servicesAmount);
    return total || 0.0;
  }
  getTaxes() {
    let total = 0;
    total +=
      Number(this.policyRiskFee[0].taxesPremiumAmount) +
      Number(this.policyRiskFee[0].taxesServicesAmount);

    return total || 0.0;
  }

  async getTotalBilledAnnual() {
    const premium = this.getTotalPremium();
    const service = this.getServices();
    const tax = this.getTaxes();
    const tria = this.getTRIA();
    const policy = this.getPolicyFee();
    return (
      Number(premium) +
      Number(service) +
      Number(tax) +
      Number(tria) +
      Number(policy)
    );
  }

  loadMoreUnderwriters(pageNumber) {
    if (this.underwriterTotalCount > this.underWriterOptions?.length) {
      this.populateUnderwriterOptions(pageNumber);
    }
  }

  populateUnderwriterOptions(page = 1) {
    const sortOrder = 'firstName:asc,lastName:asc';
    const systemUserState = 1;
    this.boxxUserService
      .GetUnderwriterlist(
        '',
        page,
        10,
        sortOrder,
        '',
        1,
        USER_ROLES.Underwriter,
        systemUserState,
      )
      .subscribe((data) => {
        const tableData = data.data?.map((dataObj) => ({
          key: dataObj.firstName + ' ' + dataObj.lastName,
          value: dataObj.firstName + ' ' + dataObj.lastName,
          id: dataObj.id,
        }));
        this.underWritersList = {
          ...this.underWritersList,
          ...data.data.reduce((accumulator, value) => {
            return {
              ...accumulator,
              [value.firstName + ' ' + value.lastName]: value,
            };
          }, {}),
        };
        this.underwriterTotalCount = data?.pagination?.totalRecords;
        this.underwriterTotalPages = data?.pagination?.totalPages;
        this.underWriterOptions = [...this.underWriterOptions, ...tableData];
      });
  }
  updateCompliance(sub: any, value: boolean) {
    sub.isCompliedWith = value;
  }
  handleBackBtn() {
    if (this.isBindingPolicy) {
      return;
    }
    this.store.dispatch(new CreateQuoteAction.ResetIsNavigatedFromSummary());
    this.router.navigate(
      ['dashboard/quote4flow/quote-calculator-and-options'],
      {
        skipLocationChange: true,
      },
    );
  }

  getSelectedQuote(options) {
    return options.filter((option) => option.optionSelected == 1);
  }

  handleSubjectivitySearch(searchKey, product) {
    this.riskRegionSubjectivityService
      .getSubjectivity(this.riskRegionId, searchKey)
      .subscribe((data) => {
        this.subjectivityOptns[product] = data.data.map((sub) => {
          let selected = this.selectedSubjectivities[product].find(
            (x) =>
              x.riskRegionSubjectivitiesId &&
              x.riskRegionSubjectivitiesId === sub.id,
          )
            ? true
            : false;

          return {
            key: sub.description,
            value: sub.description,
            disabled: selected ? true : false,
            mandatory: sub.mandatory,
            riskRegionSubjectivitiesId: sub.id,
            isCompliedWith: !!sub.isCompliedWith,
            documentTblSubId: null,
          };
        });
      });
  }

  handleWordingSearch(searchKey, product) {
    const index = this.products.findIndex((p) => p == product);
    this.riskTemplateService
      .getRiskTemplate(
        1,
        this.riskRegionId,
        this.templateCodeForWordings,
        searchKey,
      )
      .subscribe((data) => {
        this.wordingsOptions[product] = data.data.map((doc) => {
          let selected = this.selectedWordings[product].find(
            (x) => x.riskTemplateId == doc.id,
          )
            ? true
            : false;

          return {
            key: doc.templateDescription,
            value: doc.templateDescription,
            disabled: selected ? true : false,
            mandatory: doc.mandatory,
            url: doc.templatePath,
            templateType: doc.templateType,
            policyRiskId: this.policyRiskIds[index],
            riskTemplateId: doc.id,
            documentTblWordId: null,
            dataInjection: doc.dataInjection,
          };
        });
      });
  }

  handleDeclarationSearch(searchKey, product) {
    const index = this.products.findIndex((p) => p == product);
    this.riskTemplateService
      .getRiskTemplate(
        1,
        this.riskRegionId,
        this.templateCodeForDeclaration,
        searchKey,
      )
      .subscribe((data) => {
        this.declarationOptions[product] = data.data.map((doc) => {
          let selected = this.selectedDeclarations[product].find(
            (x) => x.riskTemplateId == doc.id,
          )
            ? true
            : false;

          return {
            key: doc.templateDescription,
            value: doc.templateDescription,
            disabled: selected ? true : false,
            mandatory: doc.mandatory,
            url: doc.templatePath,
            templateType: doc.templateType,
            policyRiskId: this.policyRiskIds[index],
            riskTemplateId: doc.id,
            documentTblDeclarationId: null,
            dataInjection: doc.dataInjection,
          };
        });
      });
  }
  handleEndorsementSearch(searchKey, product) {
    const index = this.products.findIndex((p) => p == product);
    this.riskTemplateService
      .getRiskTemplate(
        1,
        this.riskRegionId,
        this.templateCodeForEndorsement,
        searchKey,
      )
      .subscribe((data) => {
        this.endorsementOptions[product] = data.data.map((endorsementDoc) => {
          let selected = this.selectedEndorsements[product].find(
            (x) => x.riskTemplateId && x.riskTemplateId == endorsementDoc.id,
          )
            ? true
            : false;

          return {
            key: endorsementDoc.templateDescription,
            value: endorsementDoc.templateDescription,
            disabled: selected ? true : false,
            mandatory: endorsementDoc.mandatory,
            url: endorsementDoc.templatePath,
            templateType: endorsementDoc.templateType,
            policyRiskId: this.policyRiskIds[index],
            riskTemplateId: endorsementDoc.id,
            documentTblEndorsId: null,
            dataInjection: endorsementDoc.dataInjection,
          };
        });
      });
  }

  deleteEndorsementSelect(
    product: string,
    endorsement: { [x: string]: any },
    index: number,
  ) {
    this.showBodySpinner = true;
    let endorseId = endorsement['documentTblEndorsId'];
    if (endorseId) {
      this.policyRiskDocProcessService
        .deletePolicyRiskDocCore(endorseId)
        .subscribe({
          next: () => {
            this.selectedEndorsements[product].splice(index, 1);
            this.showBodySpinner = false;
          },
          error: (error) => {
            this.showBodySpinner = false;
            if (![500].includes(error?.status)) {
              const alertData = {
                type: 'error',
                headerText: getAlertHead('error'),
                bodyText: error ?? '' ?? error?.message ?? '',
              };
              this.alertService.addAlert(alertData);
            }
          },
        });
    } else {
      this.showBodySpinner = false;
      this.endorsementOptions[product].map((x) => {
        if (x.key == endorsement['value']) {
          x.disabled = false;
          x.selected = false;
        }
      });
      this.selectedEndorsements[product].splice(index, 1);
    }
  }

  toggleWordingSelect(index, product) {
    this.selectedWordings[product].forEach((item, ind) => {
      if (index == ind) {
        this.selectedWordings[product][ind].selected = true;
      } else {
        this.selectedWordings[product][ind].selected = false;
      }
    });
  }

  toggleDeclarationSelect(index, product) {
    this.selectedDeclarations[product].forEach((item, ind) => {
      if (index == ind) {
        this.selectedDeclarations[product][ind].selected = true;
      } else {
        this.selectedDeclarations[product][ind].selected = false;
      }
    });
  }
  handleToggleSubSelect(index, product, data) {
    try {
      const isMandatory = this.selectedSubjectivities[product][index].mandatory;
      if (!isMandatory) {
        const value = !this.selectedSubjectivities[product][index]?.selected;
        const id = this.selectedSubjectivities[product][index].documentTblSubId;
        if (value) {
          if (this.numOfSubjectivity(product) < 10) {
            this.selectedSubjectivities[product][index] = {
              ...this.selectedSubjectivities[product][index],
              selected: !this.selectedSubjectivities[product][index]?.selected,
            };
          } else {
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: 'productSummary.error.subjectivityExceeded',
            };
            this.alertService.addAlert(alertData);
            return;
          }
        } else {
          this.selectedSubjectivities[product][index] = {
            ...this.selectedSubjectivities[product][index],
            selected: !this.selectedSubjectivities[product][index]?.selected,
          };
        }
        if (id) {
          if (value === true) {
            this.policySubjectivityService
              .restorePolicyRiskSubjectivity(id)
              .subscribe(
                (resp) => {},
                (error) => {
                  const alertData = {
                    type: 'error',
                    headerText: getAlertHead('error'),
                    bodyText: error ?? '' ?? error?.message ?? '',
                  };
                  this.alertService.addAlert(alertData);
                },
              );
          } else if (this.isNewSubjectivity(product, data)) {
            this.deleteSubjectivity(product, data, index);
          } else {
            this.policySubjectivityService
              .deletePolicyRiskSubjectivity(id)
              .subscribe(
                (resp) => {},
                (error) => {
                  const alertData = {
                    type: 'error',
                    headerText: getAlertHead('error'),
                    bodyText: error ?? '' ?? error?.message ?? '',
                  };
                  this.alertService.addAlert(alertData);
                },
              );
          }
        }
      }
    } catch (error) {
      if (![500].includes(error?.status)) {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: error ?? '' ?? error?.message ?? '',
        };
        this.alertService.addAlert(alertData);
      }
    }
  }

  underwriterChanged(underwriter: string, index) {
    this.productList.map((product: any) => {
      if (product.hasSubProduct) {
        let subProductIndex = this.products.findIndex(
          (prod) => prod === product.subProductName,
        );
        this.underWriters[index] = underwriter;
        this.underWriters[subProductIndex] = underwriter;
      } else {
        this.underWriters[index] = underwriter;
      }
    });
    let underwriterId;
    const policyRisk = this.policyRisks.find(
      (policyRisk) => policyRisk.riskId == this.productsIds[index],
    );
    if (policyRisk) {
      const policyRiskTrx = policyRisk.PolicyRiskTrxes[0];
      underwriterId =
        this.underWritersList[underwriter].id ?? policyRiskTrx?.underwriterId;
    }
    this.store.dispatch(
      new CreateQuoteAction.UpdateUnderwriterForPdtAction({
        index,
        underwriter,
        underwriterId,
      }),
    );
  }

  async getUnderwriter(i: string | number) {
    return this.underWriters[i];
  }

  handleAttachEndorsement(docName, product) {
    let endorsToAdd = this.endorsementOptions[product].filter(
      (sub) => sub.key == docName,
    )[0];
    endorsToAdd.disabled = true;
    if (Object.entries(endorsToAdd).length > 0) {
      endorsToAdd = {
        ...endorsToAdd,
        selected: true,
      };
    }
    var findInd = this.selectedEndorsements[product].findIndex(
      (a) => a.riskTemplateId == endorsToAdd.riskTemplateId,
    );
    if (findInd == -1)
      this.selectedEndorsements[product] = [
        ...this.selectedEndorsements[product],
        endorsToAdd,
      ];
  }

  handleAddSubjectives(name, product) {
    if (name.length < 3 || name.length > 500) {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: 'productSummary.error.subjectivityLength',
      };
      this.alertService.addAlert(alertData);
      return;
    }
    if (this.selectedSubjectivities[product].length < 10) {
      let subToAdd = this.subjectivityOptns[product].filter(
        (sub) => sub.key == name,
      )[0];
      subToAdd.disabled = true;
      if (Object.entries(subToAdd).length > 0) {
        subToAdd = {
          ...subToAdd,
          selected: true,
        };
      }
      var findInd = this.selectedSubjectivities[product].findIndex(
        (a) =>
          a.riskRegionSubjectivitiesId == subToAdd.riskRegionSubjectivitiesId,
      );
      if (findInd == -1)
        this.selectedSubjectivities[product] = [
          ...this.selectedSubjectivities[product],
          subToAdd,
        ];
    } else {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: 'productSummary.error.subjectivityExceeded',
      };
      this.alertService.addAlert(alertData);
      return;
    }
  }

  handleSelectWording(word, product) {
    let wordToAdd = this.wordingsOptions[product].filter(
      (w) => w.key == word,
    )[0];
    wordToAdd.disabled = true;
    if (Object.entries(wordToAdd).length > 0) {
      wordToAdd = {
        ...wordToAdd,
        selected: true,
      };
    }
    var findInd = this.selectedWordings[product].findIndex(
      (a) => a.riskTemplateId == wordToAdd.riskTemplateId,
    );
    if (findInd == -1) {
      this.selectedWordings[product] = this.selectedWordings[product].map(
        (item) => {
          return { ...item, selected: false };
        },
      );
      if (this.selectedWordings[product][0].documentTblWordId)
        // Since there will be only one wording can be selected under each quote so that 0 is given.
        wordToAdd.documentTblWordId =
          this.selectedWordings[product][0].documentTblWordId;
      this.selectedWordings[product] = [wordToAdd];
    }
  }

  handleSelectDeclaration(declaration, product) {
    let declarationToAdd = this.declarationOptions[product].filter(
      (w) => w.key == declaration,
    )[0];
    declarationToAdd.disabled = true;
    if (Object.entries(declarationToAdd).length > 0) {
      declarationToAdd = {
        ...declarationToAdd,
        selected: true,
      };
    }
    var findInd = this.selectedDeclarations[product].findIndex(
      (a) => a.riskTemplateId == declarationToAdd.riskTemplateId,
    );
    if (findInd == -1) {
      this.selectedDeclarations[product] = this.selectedDeclarations[
        product
      ].map((item) => {
        return { ...item, selected: false };
      });
      if (this.selectedDeclarations[product][0].documentTblDeclarationId)
        // Since there will be only one declaration can be selected under each quote so that 0 is given.
        declarationToAdd.documentTblDeclarationId =
          this.selectedDeclarations[product][0].documentTblDeclarationId;
      this.selectedDeclarations[product] = [declarationToAdd];
    }
  }

  async saveSubjectivities(product, index) {
    return new Promise<any>(async (resolve, reject) => {
      let dataSubCreate = [];
      let dataSubUpdate = [];
      let selectedSubjCreate = this.selectedSubjectivities[product].filter(
        (s) => s.selected == true && !s.documentTblSubId,
      );
      let selectedSubjUpdate = this.selectedSubjectivities[product].filter(
        (s) => s.selected == true && s.documentTblSubId,
      );
      dataSubCreate = selectedSubjCreate.map((sub) => ({
        policyRiskId: this.policyRiskIds[index],
        riskRegionSubjectivitiesId: sub.riskRegionSubjectivitiesId,
        description: sub.key,
        isCompliedWith: !!sub.isCompliedWith,
      }));
      dataSubUpdate = selectedSubjUpdate.map((sub) => ({
        documentTblSubId: sub.documentTblSubId,
        isCompliedWith: !!sub.isCompliedWith,
      }));
      const payloadForSubjectivityCreate = {
        policyRiskSubjectivity: dataSubCreate,
      };

      if (dataSubCreate.length > 0) {
        await this.policySubjectivityService
          .createPolicyRiskSubjectivity(payloadForSubjectivityCreate)
          .subscribe({
            next: (resp) => {
              this.isContinueFormValid = true;
              resolve(true);
              for (const [index, product] of this.products.entries()) {
                this.spinnerCounter++;
                // get subjectivities for each product
                this.getAllLatestSubjectivities(product, index);
              }
            },
            error: (error) => {
              this.isContinueFormValid = false;
              const alertData = {
                type: 'error',
                headerText: getAlertHead('error'),
                bodyText: getErrorMessage(error),
              };
              this.alertService.addAlert(alertData);
            },
          });
      }

      if (dataSubUpdate.length > 0) {
        dataSubUpdate.forEach(async (s) => {
          let dataSub = {
            isCompliedWith: s.isCompliedWith,
          };
          await this.policySubjectivityService
            .updatePolicyRiskSubjectivity(s.documentTblSubId, dataSub)
            .subscribe({
              next: (resp) => {
                this.isContinueFormValid = true;
                resolve(true);
              },
              error: (error) => {
                this.isContinueFormValid = false;
                const alertData = {
                  type: 'error',
                  headerText: getAlertHead('error'),
                  bodyText: getErrorMessage(error),
                };
                this.alertService.addAlert(alertData);
              },
            });
        });
      }
    });
  }

  saveEndorsements(product, index) {
    return new Promise<any>(async (resolve, reject) => {
      let endorsementDataCreate = [];
      let endorsementDataUpdate = [];

      let selectEndorsementsCreate = this.selectedEndorsements[product].filter(
        (s) => s.selected == true && !s.documentTblEndorsId,
      );
      let selectEndorsementsUpdate = this.selectedEndorsements[product].filter(
        (s) => s.selected == true && s.documentTblEndorsId,
      );
      endorsementDataCreate = selectEndorsementsCreate.map((endorsement) => ({
        riskTemplateId: endorsement.riskTemplateId,
        policyRiskId: this.policyRiskIds[index],
        documentName: endorsement.key,
        templateType: this.templateCodeForEndorsement,
        dataInjection: endorsement.dataInjection,
      }));
      endorsementDataUpdate = selectEndorsementsUpdate.map((endorsement) => ({
        riskTemplateId: endorsement.riskTemplateId,
        policyRiskId: this.policyRiskIds[index],
        documentName: endorsement.key,
        templateType: this.templateCodeForEndorsement,
        documentTblEndorsId: endorsement.documentTblEndorsId,
        dataInjection: endorsement.dataInjection,
      }));

      const payloadForPolicyRiskDocCreate = {
        policyRiskDocs: endorsementDataCreate,
      };

      if (endorsementDataCreate.length > 0) {
        await this.policyRiskDocProcessService
          .createPolicyRiskDoc(payloadForPolicyRiskDocCreate)
          .subscribe({
            next: (resp) => {
              this.isContinueFormValid = true;
              resolve(true);
            },
            error: (error) => {
              this.isContinueFormValid = false;
              const alertData = {
                type: 'error',
                headerText: getAlertHead('error'),
                bodyText: getErrorMessage(error),
              };
              this.alertService.addAlert(alertData);
            },
          });
      }
      if (endorsementDataUpdate.length > 0) {
        endorsementDataUpdate.forEach(async (e) => {
          await this.policyRiskDocProcessService
            .updatePolicyRiskDoc(e.documentTblEndorsId, e)
            .subscribe({
              next: (resp) => {
                this.isContinueFormValid = true;
                resolve(true);
              },
              error: (error) => {
                this.isContinueFormValid = false;
                const alertData = {
                  type: 'error',
                  headerText: getAlertHead('error'),
                  bodyText: getErrorMessage(error),
                };
                this.alertService.addAlert(alertData);
              },
            });
        });
      }
    });
  }

  saveWordings(product, index) {
    return new Promise<any>(async (resolve, reject) => {
      let selectWordings = this.selectedWordings[product].filter(
        (w) => w.selected == true,
      );
      let selectWordingsUpdate = this.selectedWordings[product].filter(
        (w) => w.documentTblWordId,
      );
      // req: there will be only one wording at a time to each product under quote
      if (selectWordings.length === 1) {
        const wordingsData = {
          riskTemplateId: selectWordings[0].riskTemplateId,
          policyRiskId: this.policyRiskIds[index],
          documentName: selectWordings[0].key,
          templateType: this.templateCodeForWordings,
          documentTblWordId: selectWordings[0].documentTblWordId,
          dataInjection: selectWordings[0].dataInjection,
        };
        const payloadForPolicyRiskDocCreate = {
          policyRiskDocs: [wordingsData],
        };

        if (selectWordingsUpdate.length === 0) {
          await this.policyRiskDocProcessService
            .createPolicyRiskDoc(payloadForPolicyRiskDocCreate)
            .subscribe({
              next: (resp) => {
                this.isContinueFormValid = true;
                resolve(true);
              },
              error: (error) => {
                this.isContinueFormValid = false;
                const alertData = {
                  type: 'error',
                  headerText: getAlertHead('error'),
                  bodyText: getErrorMessage(error),
                };
                this.alertService.addAlert(alertData);
              },
            });
        }
        if (selectWordingsUpdate.length === 1) {
          await this.policyRiskDocProcessService
            .updatePolicyRiskDoc(
              selectWordingsUpdate[0].documentTblWordId,
              wordingsData,
            )
            .subscribe({
              next: (resp) => {
                this.isContinueFormValid = true;
                resolve(true);
              },
              error: (error) => {
                this.isContinueFormValid = false;
                const alertData = {
                  type: 'error',
                  headerText: getAlertHead('error'),
                  bodyText: getErrorMessage(error),
                };
                this.alertService.addAlert(alertData);
              },
            });
        }
      }
    });
  }

  saveDeclarations(p, i) {
    return new Promise<any>(async (resolve, reject) => {
      let selectDeclarations = this.selectedDeclarations[p].filter(
        (w) => w.selected == true,
      );
      let selectDeclarationsUpdate = this.selectedDeclarations[p].filter(
        (w) => w.documentTblDeclarationId,
      );

      // req: there will be only one Declaration at a time to each product under quote
      if (selectDeclarations.length == 1) {
        const declarationsData = {
          riskTemplateId: selectDeclarations[0].riskTemplateId,
          policyRiskId: this.policyRiskIds[i],
          documentName: selectDeclarations[0].key,
          templateType: this.templateCodeForDeclaration,
          documentTblDeclarationId:
            selectDeclarations[0].documentTblDeclarationId,
          dataInjection: selectDeclarations[0].dataInjection,
        };

        const payloadForPolicyRiskDocCreate = {
          policyRiskDocs: [declarationsData],
        };
        if (selectDeclarationsUpdate.length === 0) {
          await this.policyRiskDocProcessService
            .createPolicyRiskDoc(payloadForPolicyRiskDocCreate)
            .subscribe({
              next: (resp) => {
                this.isContinueFormValid = true;
                resolve(true);
              },
              error: (error) => {
                this.isContinueFormValid = false;
                const alertData = {
                  type: 'error',
                  headerText: getAlertHead('error'),
                  bodyText: getErrorMessage(error),
                };
                this.alertService.addAlert(alertData);
              },
            });
        }
        if (selectDeclarationsUpdate.length === 1) {
          await this.policyRiskDocProcessService
            .updatePolicyRiskDoc(
              selectDeclarationsUpdate[0].documentTblDeclarationId,
              declarationsData,
            )
            .subscribe({
              next: (resp) => {
                this.isContinueFormValid = true;
                resolve(true);
              },
              error: (error) => {
                this.isContinueFormValid = false;
                const alertData = {
                  type: 'error',
                  headerText: getAlertHead('error'),
                  bodyText: getErrorMessage(error),
                };
                this.alertService.addAlert(alertData);
              },
            });
        }
      }
    });
  }

  async saveDateDetails() {
    let formData: any;
    this.form$.subscribe((event) => (formData = event));

    // save dates
    const policyPeriodData = {
      effectiveDt: getFormatDate(
        formData.ui.startDate,
        this.shortDateFormat,
        'YYYY-MM-DD',
      ),
      expiryDt: getFormatDate(
        formData.ui.endDate,
        this.shortDateFormat,
        'YYYY-MM-DD',
      ),
      effectiveExpiryDatesTBDFlag: this.isTBDValid ? 1 : 0,
    };
    this.policyPeriodService
      .Update(this.policyPeriodId, policyPeriodData)
      .pipe(take(1))
      .subscribe({
        next: (resp) => {
          this.isContinueFormValid = true;
          // this.loadPageData();
        },
        error: (error) => {
          this.isContinueFormValid = false;
          this.showBodySpinner = false;
          if (![500].includes(error?.status)) {
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
            return;
          }
        },
      });
  }

  saveUnderwriter(i) {
    return new Promise<any>(async (resolve, reject) => {
      const policyRisk = this.policyRisks.find(
        (policyRisk) => policyRisk.riskId == this.productsIds[i],
      );
      if (policyRisk) {
        const policyRiskTrx = policyRisk.PolicyRiskTrxes[0];
        const underwriter = await this.getUnderwriter(i);
        const riskTrxData = {
          underwriterId:
            this.underWritersList[underwriter]?.id ??
            policyRiskTrx?.underwriterId,
        };

        this.policyRiskTrxService
          .Update(policyRiskTrx.id, riskTrxData)
          .subscribe({
            next: (resp) => {
              this.isContinueFormValid = true;
              resolve(true);
            },
            error: (error) => {
              if (![500].includes(error.status)) {
                const alertData = {
                  type: 'error',
                  headerText: getAlertHead('error'),
                  bodyText: getErrorMessage(error),
                };
                this.alertService.addAlert(alertData);
              }
              this.isContinueFormValid = false;
              this.showBodySpinner = false;
            },
          });
      }
    });
  }

  async handleSaveDraft() {
    if (this.isBindingPolicy) {
      return;
    }
    await this.saveDetails(true);
    if (this.isContinueFormValid) {
      await this.loadPageData();
    } else this.showBodySpinner = false;
  }

  async handleProductSummaryAndBindPolicy() {
    if (this.isTBDValid) {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: 'error.tbdValidationError',
      };
      this.alertService.addAlert(alertData);
      return;
    }
    await this.saveDetails();
    if (this.isContinueFormValid) {
      await this.handleBindPolicy();
    }
  }

  async saveDetails(showSaveDraftSuccessMessage: boolean = false) {
    this.alertService.clearAlerts(-1);

    let isValid: boolean = await this.handlePolicyAndTriaValidation();
    if (!isValid) {
      this.isContinueFormValid = false;
      return;
    }

    try {
      if (!this.isEndDateValid || !this.isStartDateValid) {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: 'error.pleaseSelectDateFields',
        };
        this.alertService.addAlert(alertData);
        this.isContinueFormValid = false;
        return;
      }

      this.showBodySpinner = true;
      await this.updatePolicyPeriod(this.isTBDValid);
      await this.saveDateDetails();

      for (const [i, p] of this.products.entries()) {
        if (
          this.selectedDeclarations[p].length == 0 &&
          this.selectedWordings.length == 0
        ) {
          this.wordingsDeclarationError = true;
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: 'productSummary.error.wordingDeclarationMandatory',
          };
          this.alertService.addAlert(alertData);

          this.showBodySpinner = false;
          this.isContinueFormValid = false;
          return;
        } else {
          this.wordingsDeclarationError = false;
          try {
            await Promise.all([
              this.saveEndorsements(p, i),
              this.saveUnderwriter(i),
              this.saveWordings(p, i),
              this.saveDeclarations(p, i),
              this.saveSubjectivities(p, i),
            ]);
            if (this.wordingsDeclarationError || !this.isContinueFormValid) {
              // Need to add error message
              this.showBodySpinner = false;
              this.isContinueFormValid = false;
              return;
            }

            if (showSaveDraftSuccessMessage) {
              const alertData = {
                type: 'success',
                headerText: getAlertHead('success'),
                bodyText: 'productSummary.success.policyRiskUpdated',
              };
              this.alertService.addAlert(alertData);
            }
          } catch (error) {
            this.showBodySpinner = false;
            this.isContinueFormValid = false;
            return;
          }
        }
      }
    } catch (error) {
      if (![500].includes(error?.status)) {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: getErrorMessage(error),
        };
        this.alertService.addAlert(alertData);
      }
      this.showBodySpinner = false;
    }
  }

  toggleDocPreview() {
    this.showDocPreview = !this.showDocPreview;
  }

  handleDocClick(obj: any) {
    this.alertService.clearAlerts(-1);
    const templateId = obj?.riskTemplateId;
    try {
      this.showBodySpinner = true;
      if (templateId) {
        this.PolicyRiskDocPreviewService.GetPolicyRiskDocToPreview(
          templateId,
          'template',
        ).subscribe({
          next: (documentData) => {
            if (documentData?.data) {
              this.currentPreviewDocUrl = documentData.data.content;
              this.toggleDocPreview();
              this.showBodySpinner = false;
            } else {
              this.showDocPreview = false;
              this.currentPreviewDocUrl = '';
              this.showBodySpinner = false;
            }
          },
          error: (error) => {
            this.currentPreviewDocUrl = '';
            this.showBodySpinner = false;
            if (![500].includes(error?.status)) {
              const alertData = {
                type: 'error',
                headerText: getAlertHead('error'),
                bodyText: getErrorMessage(error),
              };
              this.alertService.addAlert(alertData);
            }
          },
        });
      } else {
        this.showDocPreview = false;
        this.currentPreviewDocUrl = '';
        this.showBodySpinner = false;
      }
    } catch (error) {
      if (![500].includes(error?.status)) {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: getErrorMessage(error),
        };
        this.alertService.addAlert(alertData);
      }
      this.currentPreviewDocUrl = '';
      this.showBodySpinner = false;
    }
  }
  checkIfStartDateValid(date) {
    const upperLimit = new Date(
      getDayAfterSpecifiedDays(new Date(), 60, this.shortDateFormat),
    );
    const lowerLimit = new Date(
      getDayBeforeSpecifiedDays(new Date(), 30, this.shortDateFormat),
    );

    if (!date) {
      this.isStartDateValid = false;
      this.startDateErrMsg = 'error.thisFieldIsRequired';
    } else if (date > upperLimit) {
      this.isStartDateValid = false;

      this.startDateErrMsg = 'error.policyMaxEffectiveErrorMessage';
    } else if (date < lowerLimit) {
      this.isStartDateValid = false;

      this.startDateErrMsg = 'error.policyMinEffectiveDateErrorMessage';
    } else {
      this.isStartDateValid = true;
      this.startDateErrMsg = null;
    }
  }
  getTomorrow() {
    return new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  }
  handleStartDateChange(date, endDate) {
    if (this.isTBDValid) {
      return;
    }
    this.checkIfStartDateValid(date);
    let getStartDate = getCityTime(this.insuredTimezone);

    if (getStartDate.eightMonthsLater && getStartDate.endDate) {
      this.minEndDate = getStartDate.eightMonthsLater;
      this.maxEndDate = getStartDate.endDate;
    } else {
      this.minEndDate = date ? new Date(date) : new Date();
      this.maxEndDate = date ? new Date(date) : new Date();

      this.minEndDate.setMonth(this.minEndDate.getMonth() + 8);
      this.maxEndDate.setMonth(this.maxEndDate.getMonth() + 18);
    }
    this.checkIfEndDateValid(endDate, date);
    if (date) {
      const formattedStartDate = formatDate(
        new Date(date),
        this.shortDateFormat,
      );

      let endDate = new Date(date).setFullYear(date.getFullYear() + 1);
      const formattedEndDate = formatDate(
        new Date(endDate),
        this.shortDateFormat,
      );
      this.store.dispatch(
        new CreateQuoteAction.updateUiContents({
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        }),
      );
      this.isEndDateValid = true;
      this.endDateErrMsg = null;
    } else {
      this.store.dispatch(
        new CreateQuoteAction.updateUiContents({
          startDate: this.defaultEffectiveDate,
        }),
      );
    }
  }

  checkIfEndDateValid(date, startDate) {
    if (!startDate) {
      startDate = this.getTomorrow();
    }
    this.endDateRef = date;
    this.startDateRef = startDate;
    let upperLimit = new Date(
      calculateDateAfterMonths(startDate, 18, this.shortDateFormat),
    );
    let lowerLimit = new Date(
      calculateDateAfterMonths(startDate, 8, this.shortDateFormat),
    );
    if (!date) {
      this.isEndDateValid = false;
      this.endDateErrMsg = 'error.thisFieldIsRequired';
    } else if (date > upperLimit) {
      this.isEndDateValid = false;

      this.endDateErrMsg = 'error.policyMaxExpiryDateErrorMessage';
    } else if (date < lowerLimit) {
      this.isEndDateValid = false;

      this.endDateErrMsg = 'error.policyMinExpiryDateErrorMessage';
    } else {
      this.isEndDateValid = true;
      this.endDateErrMsg = null;
    }
  }

  handleEndDateChange(endDate, startDate) {
    if (this.isTBDValid) {
      return;
    }
    if (startDate) {
      startDate = new Date(
        formatDate(new Date(startDate), this.shortDateFormat),
      );
    } else {
      startDate = new Date();
    }

    this.checkIfEndDateValid(endDate, startDate);
    if (endDate) {
      const formattedEndDate = formatDate(
        new Date(endDate),
        this.shortDateFormat,
      );
      this.store.dispatch(
        new CreateQuoteAction.updateUiContents({
          endDate: formattedEndDate,
        }),
      );
    } else {
      this.store.dispatch(
        new CreateQuoteAction.updateUiContents({
          endDate: this.defaultExpiryDate,
        }),
      );
    }
  }
  getFormattedDate(date) {
    return formatDate(date, this.shortDateFormat);
  }
  createNewSubjectivity(data, product) {
    if (data.length < 3 || data.length > 500) {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: 'productSummary.error.subjectivityLength',
      };
      this.alertService.addAlert(alertData);
      return;
    }

    if (this.numOfSubjectivity(product) < 10) {
      const isSubjectivityInDropdown = this.subjectivityOptns[product].filter(
        (subInDropdown) =>
          subInDropdown?.key?.toLowerCase() === data.toLowerCase(),
      );
      if (isSubjectivityInDropdown.length > 0) {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: 'productSummary.error.subjectivityAlreadyInDropdown',
        };
        this.alertService.addAlert(alertData);
        return;
      }
      let subToAdd = {
        disabled: true,
        documentTblSubId: null,
        isCompliedWith: false,
        key: data,
        mandatory: false,
        riskRegionSubjectivitiesId: null,
        selected: true,
        value: data,
        edit: false,
      };
      var findAd = this.selectedSubjectivities[product].findIndex(
        (a) => a.key === subToAdd.key,
      );
      if (findAd == -1) {
        if (
          this.addedSubjectivities[product] &&
          this.addedSubjectivities[product].length > 0
        ) {
          var findInd = this.addedSubjectivities[product].findIndex(
            (a) => a.key === subToAdd.key,
          );
          if (findInd == -1) {
            this.selectedSubjectivities[product] = [
              ...this.selectedSubjectivities[product],
              subToAdd,
            ];
            this.addedSubjectivities[product] = [
              ...this.addedSubjectivities[product],
              subToAdd,
            ];
          } else {
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: 'productSummary.error.subjectivityExists',
            };
            this.alertService.addAlert(alertData);
          }
        } else {
          this.selectedSubjectivities[product] = [
            ...this.selectedSubjectivities[product],
            subToAdd,
          ];
          this.addedSubjectivities[product] = [subToAdd];
        }
      } else {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: 'productSummary.error.subjectivityExists',
        };
        this.alertService.addAlert(alertData);
      }
    } else {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: 'productSummary.error.subjectivityExceeded',
      };
      this.alertService.addAlert(alertData);
    }
  }
  editSubjectivity(product, data, active) {
    this.selectedSubjectivities[product] = this.selectedSubjectivities[
      product
    ].map((x) => {
      x.edit = false;
      if (active == 1) {
        if (x.key == data.key) {
          x.edit = true;
          this.editedSubjectivity = data.key;
        }
      }
      return x;
    });
  }
  deleteSubjectivity(product, data, index) {
    this.showBodySpinner = true;
    if (data.documentTblSubId) {
      this.policySubjectivityService
        .deletePolicyRiskSubjectivity(data.documentTblSubId)
        .subscribe(
          (resp) => {
            this.removeFromSubjectivityList(product, data);
          },
          (error) => {
            this.showBodySpinner = false;
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
          },
        );
    } else {
      this.removeFromSubjectivityList(product, data);
    }
  }
  removeFromSubjectivityList(product, data) {
    var findAd = this.selectedSubjectivities[product].findIndex(
      (a) => a.key === data.key,
    );
    if (findAd >= 0) {
      this.selectedSubjectivities[product].splice(findAd, 1);
    }
    if (
      this.addedSubjectivities[product] &&
      this.addedSubjectivities[product].length > 0
    ) {
      var findInd = this.addedSubjectivities[product].findIndex(
        (x) => x.key == data.key,
      );
      if (findInd >= 0) {
        this.addedSubjectivities[product].splice(findInd, 1);
      }
    }
    this.subjectivityOptns[product].map((x) => {
      if (x.key == data.key) {
        x.disabled = false;
      }
    });
    this.showBodySpinner = false;
  }
  confirmEditSubjectivity(product, data) {
    if (!this.editedSubjectivity || this.editedSubjectivity === '') {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: 'productSummary.error.subjectivityEmpty',
      };
      this.alertService.addAlert(alertData);
      return;
    }
    if (
      this.editedSubjectivity.length < 3 ||
      this.editedSubjectivity.length > 500
    ) {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: 'productSummary.error.subjectivityLength',
      };
      this.alertService.addAlert(alertData);
      return;
    }
    this.selectedSubjectivities[product].some((x) => {
      if (x.key == this.editedSubjectivity) {
        if (x.key == data.key) {
          x.key = this.editedSubjectivity;
          x.value = this.editedSubjectivity;
          x.edit = false;
        } else {
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: 'productSummary.error.sameSubjectivity',
          };
          this.alertService.addAlert(alertData);
          return true;
        }
      } else {
        if (x.key == data.key) {
          x.key = this.editedSubjectivity;
          x.value = this.editedSubjectivity;
          x.edit = false;
        }
      }
      return false;
    });
  }
  isNewSubjectivity(product, data) {
    if (data.riskRegionSubjectivitiesId) {
      return false;
    } else {
      return true;
    }
  }
  numOfSubjectivity(product) {
    let selectedNum = 0;
    this.selectedSubjectivities[product].map((x) => {
      if (x.selected) {
        selectedNum++;
      }
    });
    return selectedNum;
  }

  handleExitFlow(): void {
    if (this.isBindingPolicy) {
      return;
    }
    let isMock = 'true';
    if (
      this.localStorageService.getMockExternalAPIs() === 'false' ||
      environment.mockExternalAPIs === false
    ) {
      isMock = 'false';
    }
    this.router.navigate(['dashboard/home'], {
      queryParams: {
        mock: isMock,
      },
      skipLocationChange: true,
    });
  }

  async getStageId(subDomain: string) {
    this.stageId = undefined;
    return new Promise<void>((resolve) => {
      this.domainsService.GetByDomainCode('DOCMERGESTAGE', true).subscribe({
        next: (response) => {
          let docStage = response.data.filter(
            (template) => template.subdomaincode === subDomain,
          )[0];
          this.stageId = docStage.id;
          resolve();
        },
        error: (error) => {
          this.isLoader = false;
          this.showSendModal = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.alertMessage = getErrorMessage(error);
        },
      });
    });
  }

  handleDocLevelAction(action) {
    this.showBodySpinner = false;
    this.docPopupDetails.action = action;
    this.showDocTempSelectionPopup = true;
  }

  async handleSuccessDocTemplate(event) {
    switch (event.action.toLowerCase()) {
      case 'bind':
        await this.callBindAPI();
        this.showDocTempSelectionPopup = false;
        this.handleCreateDocumentAfterBind('MESSAGETYPE_POLICY');
        break;
      case 'quote':
        this.showDocTempSelectionPopup = false;
        this.handleCreateDocument('MESSAGETYPE_QUOTE');
        break;

      default:
        this.handleCloseDocumentPopup();
    }
  }

  handleCloseDocumentPopup() {
    if (this.showBodySpinner) {
      return;
    }
    this.showDocTempSelectionPopup = false;
    this.bindingStatus = 'default';
    clearTimeout(this.newTimeout);
    this.closeAlert();
    this.docDetails = [];
    this.templateDetails = [];
    this.isLoader = false;
    this.generateWarningMessageForErrorAfterQuoteBind('');
    this.isBindingPolicy = false;
    this.isQuoteBounded = false;

    // To do needed to fix (code added just for quick fix)
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['dashboard/quote4flow/policy-configuration'], {
      skipLocationChange: true,
    });
  }

  translatesText(text: string): string {
    return this.translate.instant(text);
  }

  redirectToPolicies(message: string): void {
    if (this.isQuoteBounded) {
      this.router.navigate(['dashboard/policies'], {
        skipLocationChange: true,
      });
    } else {
      this.showErrorAlert = message !== '' ? true : false;
      this.alertMessage = message;
    }
  }

  generateWarningMessageForErrorAfterQuoteBind(
    defaultMsg: string,
    isSendMailFailed: boolean = false,
  ) {
    let packagePolicyNumber = null;
    let errMsg = defaultMsg;
    if (this.isQuoteBounded) {
      packagePolicyNumber = this.newQuoteService.getPackagePolicyNumber();
      errMsg =
        defaultMsg === ''
          ? ``
          : `${this.translatesText(
              'error.errorAfterBindQuote.policyTxt',
            )} ${packagePolicyNumber} ${this.translatesText(
              'error.errorAfterBindQuote.msg',
            )} ${
              isSendMailFailed
                ? `${this.translatesText(
                    'error.errorAfterBindQuote.processSendMail',
                  )}`
                : `${this.translatesText(
                    'error.errorAfterBindQuote.processGeneratingDocument',
                  )}`
            } ${this.translatesText('error.errorAfterBindQuote.retryText')}`;
      this.newQuoteService.setPolicyBindWarningMsg(errMsg);
    }
    this.redirectToPolicies(errMsg);
  }

  closeAlert() {
    this.showErrorAlert = false;
    this.alertMessage = '';
  }

  async populateMessageType(subDomain: string) {
    this.messageTypeId = undefined;
    return new Promise<void>((resolve) => {
      this.domainsService.GetByDomainCode('MESSAGETYPE', true).subscribe({
        next: (response) => {
          let messageType = response.data.filter(
            (template) => template.subdomaincode === subDomain,
          )[0];
          this.messageTypeId = messageType.id;
          resolve();
        },
        error: (error) => {
          this.isLoader = false;
          this.showSendModal = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.alertMessage = getErrorMessage(error);
        },
      });
    });
  }

  async getDocument() {
    if (!this.policyPeriodId || !this.stageId) {
      return;
    }
    this.policyRiskDocService
      .getPolicyRiskGeneratedDocCore(this.policyPeriodId, this.stageId)
      .subscribe({
        next: (response) => {
          let { data } = response;
          if (Object.entries(data).length === 0) return;

          if (!data.PolicyRiskDocument || data.PolicyRiskDocument.length === 0)
            return;
          if (response?.data?.status === GENERATE_DOC_SUCCESS_STATUS_CODE) {
            this.docDetails = data.PolicyRiskDocument;
          } else {
            this.docDetails = [];
          }
          this.rowDocDetails = data;
        },
        error: (error) => {
          clearTimeout(this.newTimeout);
          this.isLoader = false;
          this.showSendModal = false;
          this.generateWarningMessageForErrorAfterQuoteBind(
            getErrorMessage(error),
          );
        },
      });
  }

  async getMessageTemplates() {
    const riskId = this.productsIds[0] ?? 1;
    this.messageTemplateService
      .GetAllMessageTemplates(this.messageTypeId, riskId)
      .subscribe({
        next: (response) => {
          this.templateDetails = response?.data ?? [];
          this.isLoader = false;
        },
        error: (error) => {
          this.isLoader = false;
          this.showSendModal = false;
          this.generateWarningMessageForErrorAfterQuoteBind(
            getErrorMessage(error),
          );
        },
      });
  }

  async handleCreateDocument(messageSubDomain: string) {
    this.closeAlert();
    this.docDetails = [];
    this.rowDocDetails = {};
    this.showSendModal = !this.showSendModal;
    this.isLoader = !this.isLoader;
    this.showBodySpinner = false;
    const interval = 5000;
    const startTime = Date.now();

    if (this.showSendModal) {
      await this.populateMessageType(messageSubDomain);
      // await this.getDocument();
      if (this.docDetails.length === 0) {
        this.generateQuoteService
          .generateQuotePdf(this.policyPeriodId)
          .subscribe({
            next: async (response) => {
              while (
                Date.now() - startTime < DOC_GENERATION_WAIT_TIME &&
                this.rowDocDetails?.['status'] !== 1 &&
                this.rowDocDetails?.['status'] !== 2 &&
                (this.docDetails.length === 0 ||
                  this.rowDocDetails?.['status'] === 0)
              ) {
                await this.getDocument();
                await new Promise((resolve) => {
                  this.newTimeout = setTimeout(resolve, interval);
                });
              }
              if (this.docDetails.length === 0) {
                this.isLoader = false;
                this.showSendModal = false;
                this.showErrorAlert = true;
                this.showBodySpinner = false;
                const alertData = {
                  type: 'error',
                  headerText: getAlertHead('error'),
                  bodyText: this.translate.instant('common.docCreateFailMsg'),
                };
                this.alertService.addAlert(alertData);
              } else {
                await this.getMessageTemplates();
              }
            },
            error: (error) => {
              clearTimeout(this.newTimeout);
              this.isLoader = false;
              this.showSendModal = false;
              this.generateWarningMessageForErrorAfterQuoteBind(
                getErrorMessage(error),
              );
            },
          });
      }
      this.docDetails.length !== 0 && (await this.getMessageTemplates());
    }
  }
  async handleSendQuoteAfterSaving() {
    if (this.isBindingPolicy) {
      return;
    }
    await this.saveDetails();
    if (this.isContinueFormValid) {
      await this.handleSendQuote();
    }
  }

  async handleSendQuote() {
    if (this.isBindingPolicy) {
      return;
    }
    this.alertService.clearAlerts(-1);
    await this.getStageId('DOCMERGESTAGE_QUOTE');
    this.docPopupDetails.stageId = this.stageId;
    this.docPopupDetails.policyPeriodId = this.policyPeriodId;
    this.form$
      .pipe(take(1))
      .subscribe((event) => (this.policyPremium = Number(event.ui.premium)));

    if (this.policyPremium === 0 || this.brokerCommission === 0) {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: this.translate.instant(
          'quoteCalculator.error.invalidSelectedQuote',
        ),
      };
      this.alertService.addAlert(alertData);
      this.showBodySpinner = false;
      return;
    }
    if (this.stageId) {
      this.DocumentTemplateService.getDocumentTemplateList(
        this.policyRiskId,
        this.stageId,
      ).subscribe({
        next: (response) => {
          if (response?.data.length !== 0) {
            this.docPopupDetails.documentTemplate = response?.data;
            this.handleDocLevelAction(this.currentQuoteStatus);
          } else {
            this.generateWarningMessageForErrorAfterQuoteBind('');
            this.handleCreateDocument('MESSAGETYPE_QUOTE');
          }
        },
        error: (error) => {
          this.alertMessage = getErrorMessage(error.error);
        },
      });
    } else {
      return;
    }
  }

  isPolicyFeeValid(policyFee) {
    if (
      this.policyFeeEnabled &&
      (!policyFee || policyFee === '0' || policyFee < 1 || policyFee > 100000)
    ) {
      return false;
    }
    return true;
  }

  isTriaAmountValid(triaAmount) {
    if (
      this.TRIAFeeEnabled &&
      (!triaAmount ||
        triaAmount === '0' ||
        triaAmount < 1 ||
        triaAmount > this.policyPremium)
    ) {
      return false;
    }
    return true;
  }

  getPolicyFee() {
    if (this.policyFeeEnabled) {
      return this.policyAmount || '0';
    }
    return '0';
  }

  getTRIA() {
    if (this.TRIAFeeEnabled) {
      return this.triaAmount || '0';
    }
    return '0';
  }

  async callBindAPI() {
    try {
      const bindData = {
        policyPeriodId: this.policyPeriodId,
      };

      this.alertService.addAlert({
        type: 'info',
        headerText: getAlertHead('info'),
        bodyText: 'common.bindProcessMsg',
      });

      const createBindServiceObs =
        this.policyBindService.UpdateWithoutId(bindData);
      const bindResponse = await firstValueFrom(createBindServiceObs);
      this.packagePolicyNumber = bindResponse.data.pkgPolicyNumber;
      this.bindingStatus = 'done';
      this.newQuoteService.setPackagePolicyNumber(this.packagePolicyNumber);
      this.store.dispatch(
        new CreateQuoteAction.updateUiContents({
          packagePolicyNumber: this.packagePolicyNumber ?? '',
        }),
      );
      this.isBindingPolicy = false;
      this.isQuoteBounded = true;
    } catch (error) {
      if (![500].includes(error?.status)) {
        this.showErrorAlert = true;
        this.alertMessage = error?.message ?? error?.error?.message ?? '';
      }
      this.isLoader = false;
      this.showSendModal = false;
      this.bindingStatus = 'default';
      this.isBindingPolicy = false;
      this.isQuoteBounded = false;
    }
  }

  async handleCreateDocumentAfterBind(messageSubDomain: string) {
    this.closeAlert();
    this.docDetails = [];
    this.rowDocDetails = {};
    this.showSendModal = !this.showSendModal;
    this.isLoader = !this.isLoader;
    const interval = 5000;
    const startTime = Date.now();

    if (this.showSendModal) {
      await this.populateMessageType(messageSubDomain);
      await this.getDocument();
      if (this.docDetails.length === 0) {
        while (
          Date.now() - startTime < DOC_GENERATION_WAIT_TIME &&
          this.rowDocDetails?.['status'] !== 1 &&
          this.rowDocDetails?.['status'] !== 2 &&
          (this.docDetails.length === 0 || this.rowDocDetails?.['status'] === 0)
        ) {
          await this.getDocument();
          await new Promise((resolve) => {
            this.newTimeout = setTimeout(resolve, interval);
          });
        }
        if (this.docDetails.length === 0) {
          this.isLoader = false;
          this.showSendModal = false;
          this.generateWarningMessageForErrorAfterQuoteBind(
            `common.docCreateFailMsg`,
          );
        } else {
          await this.getMessageTemplates();
        }
      }
      this.docDetails.length !== 0 && (await this.getMessageTemplates());
    }
  }

  async handleDocumentTemplatePopup() {
    await this.getStageId('DOCMERGESTAGE_BIND');
    this.docPopupDetails.stageId = this.stageId;
    this.docPopupDetails.policyPeriodId = this.policyPeriodId;
    if (this.stageId) {
      this.DocumentTemplateService.getDocumentTemplateList(
        this.policyRiskId,
        this.stageId,
      ).subscribe({
        next: async (response) => {
          if (response?.data.length !== 0) {
            this.docPopupDetails.documentTemplate = response?.data;
            this.handleDocLevelAction('bind');
          } else {
            await this.callBindAPI();
            this.handleCreateDocumentAfterBind('MESSAGETYPE_POLICY');
          }
        },
        error: (error) => {
          this.alertMessage = getErrorMessage(error.error);
        },
      });
    } else {
      return;
    }
  }

  async handleBindPolicy() {
    if (this.isBindingPolicy) {
      return;
    }

    this.isBindingPolicy = true;
    this.bindingStatus = 'process-pay';
    try {
      this.showBodySpinner = false;
      await this.handleDocumentTemplatePopup();
    } catch (error) {
      this.showBodySpinner = false;
      if (![500].includes(error?.status)) {
        this.alertMessage = 'error.somethingWentWrong';
      }
    }
  }

  async handlePolicyAndTriaValidation() {
    let quote4FlowPremiumDetails: { [x: string]: any } =
      await this.getQuote4FlowPremiumDetails();
    if (Object.values(quote4FlowPremiumDetails).length > 0) {
      this.TRIAFeeEnabled =
        quote4FlowPremiumDetails?.['TRIAFeeEnabled'] ?? false;
      this.policyFeeEnabled =
        quote4FlowPremiumDetails?.['policyFeeEnabled'] ?? false;
      if (
        this.isPolicyFeeValid(+quote4FlowPremiumDetails['policyAmount']) ===
        false
      ) {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: 'quoteSummary.errors.policyFee',
        };
        this.alertService.addAlert(alertData);

        this.showBodySpinner = false;
        return false;
      }
      if (
        this.isTriaAmountValid(+quote4FlowPremiumDetails['triaAmount']) ===
        false
      ) {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: 'quoteSummary.errors.triaAmount',
        };
        this.alertService.addAlert(alertData);

        this.showBodySpinner = false;
        return false;
      }

      return true;
    }

    return false;
  }

  async getQuote4FlowPremiumDetails(): Promise<any> {
    let fetchResult: { [x: string]: any } = {};
    this.store.select(getUIData).subscribe((data: any) => {
      fetchResult = data?.quote4FlowPremiumDetails ?? {};
    });

    return fetchResult;
  }

  handleRiskAction(action: TransactionRiskActions) {
    if (this.isBindingPolicy) {
      return;
    }
    let uiData: any;
    this.store
      .pipe(select(getQuoteSelector))
      .subscribe((event) => (uiData = event.ui));
    this.actionPopupDetails = {
      quoteNumber: uiData?.policyId,
      effectiveDate: uiData?.policyPeriod,
      insured: uiData?.insuredName,
      brokerage: uiData?.brokerageName
        ? uiData?.brokerageName !== ''
          ? uiData?.brokerageName
          : uiData.broker
        : uiData.broker,
      branch: uiData?.branch,
      reasonOptions: [],
      action: this.transactionRiskActions.None,
    };
    this.actionPopupDetails.action = action;
    this.showActionPopup = true;
  }

  handleConfirmRiskAction(event: {
    action: TransactionRiskActions;
    selectedReasonId: number;
  }) {
    this.alertService.clearAlerts(-1);
    const PolicyDeclineRequest: PolicyDeclineRequest = {
      policyPeriodId: Number(this.policyPeriodId),
      declineReason: Number(event.selectedReasonId),
    };

    this.policyLifecycleService
      .TransactionRiskDecline(PolicyDeclineRequest)
      .subscribe({
        next: (response) => {
          this.handleRiskActionApiResponse(
            TransactionRiskActions.Decline,
            response,
          );
        },
        error: (e) => {
          this.handleApiError(e);
        },
        complete: () => {
          this.handleRiskActionComplete();
        },
      });
  }

  private handleRiskActionApiResponse(type, response: any) {
    this.showActionPopup = false;
    const alertData = {
      type: 'success',
      headerText: getAlertHead('success'),
      bodyText: this.translate.instant('policy.declined.success.body'),
    };
    this.alertService.addAlert(alertData);
  }

  handleApiError(error: any) {
    if (![500].includes(error?.status)) {
      let errorMessage = '';
      if (typeof error === 'string' || error instanceof String) {
        if (error?.toLowerCase().includes('error code: 404', 0)) {
          errorMessage = this.translate.instant(
            'quoteDetails.requestedQuoteNotFound',
          );
        }
      } else {
        errorMessage = getErrorMessage(error);
      }

      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: errorMessage,
      };
      this.alertService.addAlert(alertData);
    }
  }

  private handleRiskActionComplete() {
    this.showActionPopup = false;
    this.newQuoteService.setClosedAlertStatus(true);
    this.router.navigate([`/dashboard/quote4flow/${this.quoteId}`], {
      skipLocationChange: true,
    });
  }

  handleSend(formData: any) {
    formData.append('merginStageId', this.stageId);
    this.messageSendService.sendMessage(formData).subscribe({
      next: (response) => {
        this.docDetails = [];
        this.templateDetails = [];
        this.generateWarningMessageForErrorAfterQuoteBind('');
        this.newQuoteService.setPolicyBindWarningMsg('');
        this.showSendModal = !this.showSendModal;
      },
      error: (error) => {
        this.generateWarningMessageForErrorAfterQuoteBind(
          getErrorMessage(error),
          true,
        );
        this.showSendModal = !this.showSendModal;
      },
    });
  }

  closeSendPackage() {
    if (this.showBodySpinner) {
      return;
    }
    clearTimeout(this.newTimeout);
    this.closeAlert();
    this.docDetails = [];
    this.templateDetails = [];
    this.showSendModal = false;
    this.isLoader = false;
    this.generateWarningMessageForErrorAfterQuoteBind('');
  }

  handleTBDCheckbox(index, options) {
    options[index].checked = !options[index].checked;

    this.isEndDateValid = true;
    this.endDateErrMsg = null;

    this.isStartDateValid = true;
    this.startDateErrMsg = null;

    if (!options[index].checked) {
      this.checkIfEndDateValid(this.endDateRef, this.startDateRef);
      this.isTBDValid = false;
    } else {
      this.isTBDValid = true;
    }
  }

  async updatePolicyPeriod(isTBDValid: boolean) {
    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        policyPeriod: isTBDValid
          ? 'TBD'
          : `${formatDate(
              new Date(this.startDate),
              this.longDateFormat,
            )} - ${formatDate(new Date(this.endDate), this.longDateFormat)}`,
      }),
    );
  }

  // get subjectivities for each product
  getAllLatestSubjectivities(product, index) {
    this.riskRegionSubjectivityService
      .getSubjectivity(this.riskRegionId)
      .subscribe((dataSub) => {
        if (dataSub.data) {
          this.subjectivityOptns[product] = dataSub.data.map((sub) => {
            return {
              key: sub.description,
              value: sub.description,
              disabled: !!sub.mandatory,
              mandatory: sub.mandatory,
              riskRegionSubjectivitiesId: sub.id ?? '',
              isCompliedWith: !!sub.isCompliedWith,
              selected: !!sub.mandatory,
              documentTblSubId: null,
            };
          });
          this.subjectivityMandatory[product] = this.subjectivityOptns[
            product
          ].filter((sub) => sub.mandatory);
          this.selectedSubjectivities[product] = this.subjectivityMandatory[
            product
          ].map((sub) => ({
            ...sub,
            selected: true,
          }));
          let distinctSubArray: any[] = this.selectedSubjectivities[
            product
          ].filter(
            (obj, index, array) =>
              obj?.riskRegionSubjectivitiesId === '' ||
              array.findIndex(
                (o) =>
                  obj !== null &&
                  o?.riskRegionSubjectivitiesId ===
                    obj?.riskRegionSubjectivitiesId,
              ) === index,
          );
          this.selectedSubjectivities[product] = distinctSubArray;
          this.spinnerCounter++;
          // to get existing subjectivities
          this.policySubjectivityService
            .getPolicyRiskSubjectivity(this.policyRiskIds[index])
            .subscribe((dataSubExist) => {
              let selectedPolicySubj = {
                [product]: dataSubExist.data.map((policySub) => {
                  let subjOptn = this.subjectivityOptns[product].filter(
                    (subOptn) =>
                      subOptn.riskRegionSubjectivitiesId ==
                      policySub.riskRegionSubjectivitiesId,
                  );
                  if (!policySub.riskRegionSubjectivitiesId) {
                    subjOptn = [...subjOptn, policySub];
                  }
                  if (subjOptn.length > 0) {
                    // subjOptn[0].disabled = true;
                    return {
                      key: subjOptn[0]?.key || policySub?.description,
                      value: subjOptn[0]?.key || policySub?.description,
                      disabled: true,
                      mandatory: subjOptn[0]?.mandatory,
                      selected: true,
                      documentTblSubId: policySub?.id,
                      riskRegionSubjectivitiesId:
                        subjOptn[0]?.riskRegionSubjectivitiesId ?? '',
                      isCompliedWith: !!policySub?.isCompliedWith,
                    };
                  } else {
                    return null;
                  }
                }),
              };
              if (selectedPolicySubj[product]?.length > 0) {
                this.selectedSubjectivities[product] = [
                  ...selectedPolicySubj[product],
                ];
                let distinctSubArray: any[] = this.selectedSubjectivities[
                  product
                ].filter(
                  (obj, index, array) =>
                    obj?.riskRegionSubjectivitiesId === '' ||
                    array.findIndex(
                      (o) =>
                        obj !== null &&
                        o?.riskRegionSubjectivitiesId ===
                          obj?.riskRegionSubjectivitiesId,
                    ) === index,
                );
                this.selectedSubjectivities[product] = distinctSubArray;
                this.subjectivityOptns[product] = this.subjectivityOptns[
                  product
                ].map((subjOptn) => {
                  let selectedOptn = this.selectedSubjectivities[product].find(
                    (x) =>
                      x.riskRegionSubjectivitiesId ===
                      subjOptn.riskRegionSubjectivitiesId,
                  );
                  if (selectedOptn) {
                    return {
                      ...subjOptn,
                      disabled: true,
                      selected: true,
                    };
                  } else {
                    return { ...subjOptn };
                  }
                });
              }
              this.spinnerCounter--;
            });
        }
        this.spinnerCounter--;
      });
  }
}
