import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl,
  Validators,
} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import { PolicyDashboardService } from 'src/app/services/policy-dashboard.service';
import moment from 'moment';
import {
  MAX_DEDUCTIBLE,
  MAX_LIMIT,
  MAX_PREMIUM,
  MIN_DEDUCTIBLE,
  MIN_LIMIT,
  MIN_PREMIUM,
} from 'src/app/constants/filter-constant';
import { Store, select } from '@ngrx/store';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { formatDate } from 'src/app/utils/formatDate';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.less'],
})
export class FilterComponent implements OnInit {
  hasError = false;
  isPeriodHidden = true;
  productOptions = [];
  form: FormGroup;
  userID: number;
  showFromDateValidationMessage = false;
  showToDateValidationMessage = false;
  showFromOrToDateEmptyErrorMsg = false;
  formSubmitted: boolean = false;
  maxLimit = MAX_LIMIT;
  minLimit = MIN_LIMIT;
  maxDeductible = MAX_DEDUCTIBLE;
  minDeductible = MIN_DEDUCTIBLE;
  maxPremium = MAX_PREMIUM;
  minPremium = MIN_PREMIUM;

  @Input() inputForm: FormGroup;
  // TO DO -update below during API inetgration

  @Input() show: boolean = false;

  @Output() handleClearAllFilters = new EventEmitter<any>();
  @Output() handleFiltering = new EventEmitter<any>();

  insuredNameOptions = [];
  productSelected = [];
  insuredNameTotalRecords = 0;
  insuredNameSearch = '';
  insuredNameCurrentPage = 0;
  shortDateFormat: string = '';
  longDateFormat: string = '';

  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private riskRegionService: RiskRegionService,
    private policyDashboardService: PolicyDashboardService,
    private localStorageService: LocalStorageService,
    private store: Store,
  ) {
    this.form = this.fb.group({
      periodFrom: [''],
      periodTo: [''],
      quotedPeriod: [''],
      minLimit: [this.minLimit, [this.isMinMaxValid(null, 'maxLimit')]],
      maxLimit: [this.maxLimit, [this.isMinMaxValid('minLimit', null)]],
      minDeductible: [
        this.minDeductible,
        [this.isMinMaxValid(null, 'maxDeductible')],
      ],
      maxDeductible: [
        this.maxDeductible,
        [this.isMinMaxValid('minDeductible', null)],
      ],
      minPremium: [this.minPremium, [this.isMinMaxValid(null, 'maxPremium')]],
      maxPremium: [this.maxPremium, [this.isMinMaxValid('minPremium', null)]],
      insuredName: [''],
      products: [''],
      producerId: '',
    });
  }

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    this.userID = this.localStorageService.getBoxxUserId(); //added for userid fetched from the localstorage
    if (this.inputForm) {
      this.form = this.inputForm;
    }
    this.riskRegionService.getAllRiskByUser(this.userID).subscribe({
      next: (response) => {
        const tableData = response?.data?.map((dataObj) => ({
          key: dataObj.name,
          value: dataObj.name,
          disabled: false,
          id: dataObj.id,
        }));
        this.productOptions = [...tableData];
      },
      error: (error) => {
        this.productOptions = [];
      },
    });
  }

  isMinMaxValid(minValuePropName, maxValuePropName) {
    return (control: FormControl) => {
      if (!control || !control.parent) {
        return null;
      }
      let maxValue = 0;
      let minValue = 0;
      if (minValuePropName) {
        minValue = Number(control.parent.value[minValuePropName]);
        maxValue = Number(control.value);
      } else {
        maxValue = Number(control.parent.value[maxValuePropName]);
        minValue = Number(control.value);
      }

      if (maxValue < minValue) {
        return {
          customError: true,
        };
      } else {
        if (minValuePropName) {
          control.parent.controls[minValuePropName].errors = null;
          control.parent.controls[minValuePropName].status = 'VALID';
        } else {
          control.parent.controls[maxValuePropName].errors = null;
          control.parent.controls[maxValuePropName].status = 'VALID';
        }
      }

      return {};
    };
  }

  handlePolicyPeriodFrom(date: Date | undefined) {
    const formattedDate = date
      ? formatDate(new Date(date), this.shortDateFormat)
      : '';
    this.form.controls['periodFrom'].setValue(formattedDate);
    this.hasError = this.isValidDates();
  }

  handlePolicyPeriodTo(date: Date | undefined) {
    const formattedDate = date
      ? formatDate(new Date(date), this.shortDateFormat)
      : '';
    this.form.controls['periodTo'].setValue(formattedDate);
    this.hasError = this.isValidDates();
  }

  handleRadioCheck(event: any) {
    this.resetPeriods();
    let periodRangeType = event.target.id;
    this.isPeriodHidden = periodRangeType !== "'custom'" ? true : false;

    if (periodRangeType === "'quotedToday'") {
      // Get the current date in "date month year" format
      this.setFromAndToDates(
        moment().format(this.shortDateFormat),
        moment().format(this.shortDateFormat),
      );
    } else if (periodRangeType === "'last7days'") {
      // Get the date 6 days before the current date
      const sixDaysAgo = moment().subtract(6, 'days');

      // Format the date in "date month year" format
      const formattedDate = sixDaysAgo.format(this.shortDateFormat);

      this.setFromAndToDates(
        formattedDate,
        moment().format(this.shortDateFormat),
      );
    } else if (periodRangeType === "'last30days'") {
      // Get the date 29 days before the current date
      const twentyNineDaysAgo = moment().subtract(29, 'days');

      // Format the date in "date month year" format
      const formattedDate = twentyNineDaysAgo.format(this.shortDateFormat);
      this.setFromAndToDates(
        formattedDate,
        moment().format(this.shortDateFormat),
      );
    }
  }

  setFromAndToDates = (fromDate: string, toDate: string) => {
    this.form.controls['periodFrom'].setValue(fromDate);
    this.form.controls['periodTo'].setValue(toDate);
  };

  resetPeriods() {
    this.hasError = false;
    this.form.controls['periodFrom'].setValue('');
    this.form.controls['periodTo'].setValue('');
  }

  handleCheckbox(e) {}

  handleInsuredSelected(e) {
    this.form.controls['insuredName'].setValue(e);
    this.insuredNameOptions = [];
  }

  resetFilterForm() {
    this.hasError = false;
    this.isPeriodHidden = true;
    this.form.reset();
    this.form.controls['periodFrom'].setValue('');
    this.form.controls['periodTo'].setValue('');
    this.form.controls['quotedPeriod'].setValue('');
    this.form.controls['products'].setValue([]);
    this.form.controls['insuredName'].setValue([]);
    this.form.controls['minLimit'].setValue(this.minLimit);
    this.form.controls['maxLimit'].setValue(this.maxLimit);
    this.form.controls['minDeductible'].setValue(this.minDeductible);
    this.form.controls['maxDeductible'].setValue(this.maxDeductible);
    this.form.controls['minPremium'].setValue(this.minPremium);
    this.form.controls['maxPremium'].setValue(this.maxPremium);
    this.handleClearAllFilters.emit(this.form.value);
    this.handleClearAllFilters.emit();
  }

  handleFilter(e) {
    this.formSubmitted = true;
    if (!this.form.valid) {
      return;
    }
    const dateFromStr = this.form.value['periodFrom'];
    const dateToStr = this.form.value['periodTo'];

    if ((!dateFromStr && !dateToStr) || (dateFromStr && dateToStr)) {
      this.showFromOrToDateEmptyErrorMsg = false;
      if (dateFromStr && dateToStr) {
        const dateFromParts = dateFromStr.split('/');
        const dateFrom = new Date(dateFromStr);

        const dateToParts = dateToStr.split('/');
        const dateTo = new Date(dateToStr);

        if (dateFrom > dateTo) {
          this.hasError = true;
        } else {
          this.hasError = false;
          this.handleFiltering.emit(this.form.value);
        }
      } else {
        this.hasError = false;
        this.handleFiltering.emit(this.form.value);
      }
    } else {
      this.hasError = true;
      this.showFromOrToDateEmptyErrorMsg = true;
    }
  }

  handleProductsSelect(value) {
    const index = this.productOptions.findIndex(
      (docObj) => docObj.value == value,
    );
    this.productOptions[index].disabled = true;
    this.productSelected = [
      ...this.productSelected,
      this.productOptions[index].key,
    ];
    this.form.controls['products'].setValue(this.productSelected);
  }

  handleProductRemove(key) {
    const index = this.productOptions.findIndex((docObj) => docObj.key == key);
    this.productOptions[index].disabled = false;
    var indexOfKey = this.productSelected.indexOf(key);
    if (index !== -1) {
      this.productSelected.splice(indexOfKey, 1);
    }
    this.form.controls['products'].setValue(this.productSelected);
  }

  isValidDates() {
    let dateFromStr = this.form?.value?.['periodFrom'] ?? '';
    let dateToStr = this.form?.value?.['periodTo'] ?? '';
    let dateFrom: Date;
    let dateTo: Date;

    if (dateFromStr === '' || dateToStr === '') {
      return false;
    }

    const dateFromParts = dateFromStr.split('/');
    if (dateFromParts.length !== 3) {
      return false;
    }
    dateFrom = new Date(dateFromStr);

    const dateToParts = dateToStr.split('/');
    if (dateToParts.length !== 3) {
      return false;
    }
    dateTo = new Date(dateToStr);
    if (dateFrom > dateTo) {
      this.showFromDateValidationMessage = true;
      this.showToDateValidationMessage = true;
    } else {
      this.showFromDateValidationMessage = false;
      this.showToDateValidationMessage = false;
    }
    return dateFrom > dateTo;
  }
  loadMoreInsuredName() {
    if (this.insuredNameOptions.length < this.insuredNameTotalRecords) {
      let page = this.insuredNameCurrentPage + 1;
      this.getInsuredNameOption(this.insuredNameSearch, page, true);
    }
  }
  searchInsuredNameOption(value) {
    if (value.length >= 3) {
      this.getInsuredNameOption(value);
    } else {
      this.insuredNameOptions = [];
    }
  }
  getInsuredNameOption(value = '', page = 1, isLoadMore = false) {
    const policyFilter = {
      brokerageProducerBORId: this.form.value['producerId'],
      insuredName: value,
    };
    const args = {
      insuredType: -1,
      filter: policyFilter,
      page,
      limit: 10,
    };
    this.policyDashboardService.GetAllQuoteByFilter(args).subscribe({
      next: (response) => {
        const tableData = response?.data?.map((dataObj) => ({
          name: `${dataObj?.policy?.insured?.companyName}`,
          id: dataObj?.policy?.insured?.id,
          isAdded: false,
        }));
        if (isLoadMore) {
          this.insuredNameOptions = [...this.insuredNameOptions, ...tableData];
        } else {
          this.insuredNameOptions = [...tableData];
        }
        this.insuredNameSearch = value;
        this.insuredNameTotalRecords = response.pagination.totalRecords;
        this.insuredNameCurrentPage = response.pagination.currentPage;
      },
      error: (error) => {
        this.insuredNameOptions = [];
      },
    });
  }
}
