<div class="holder" [ngClass]="{ 'alert-visible': isNavFrmPolicyCreate }">
  <app-policy-grid-slideout
    *ngIf="showSlideOut"
    [showModal]="showSlideOut"
    (handleSave)="handleSave()"
    (handleClose)="handleClose()"
    (handleDownload)="handleDocDownload()"
  >
  </app-policy-grid-slideout>
  <app-document-preview
    *ngIf="showDocPreview"
    [docUrl]="currentPreviewDocUrl"
    (handleClose)="toggleDocPreview()"
  >
  </app-document-preview>
  <app-policy-grid-filter
    [show]="showFilter"
    (handleFiltering)="handleFiltering()"
    [inputForm]="filterForm"
    [pdtOptions]="pdtOptions"
  ></app-policy-grid-filter>
  <div class="header">
    <boxxds-heading [sz]="'5'" [text]="'Policies'"> </boxxds-heading>
    <ng-container
      *ngIf="
        this.permissionList[this.currentScreen] && this.permissionList['Policy']
      "
    >
      <boxxds-button
        sz="lg"
        [buttonText]="startQuoteBtnTxt"
        [type]="'submitted'"
        [btnStyle]="{ width: '210px' }"
        (handleClick)="handleStartNewQuote()"
      >
      </boxxds-button>
    </ng-container>
  </div>
  <div class="content">
    <div class="d-flex justify-content-between">
      <div class="sub-nav">
        <ul>
          <li *ngFor="let nav of subNavData; let i = index">
            <button
              [ngClass]="{ active: nav.active }"
              (click)="handleNav($event, i)"
            >
              {{ nav.name }}
            </button>
          </li>
        </ul>
      </div>
      <div class="d-flex align-items-center">
        <div class="filter-optns-container">
          <div class="filter-optn" *ngFor="let filter of getFilteredValues()">
            <div class="option-name">{{ filter }}</div>
            <button
              type="button"
              aria-label="close"
              class="close-btn"
              (click)="handleFilterOptnRemove(filter)"
            >
              <img src="assets/img/dashboard/quote/remove.png" />
            </button>
          </div>
        </div>
        <boxxds-button
          [isBtnActive]="showFilter"
          [leftImage]="showFilter ? 'filter-filled' : 'filter'"
          btnType="unfilled"
          buttonText="FILTERS"
          [type]="'submitted'"
          [btnStyle]="{ width: '65px', marginRight: '24px' }"
          (handleClick)="handleFilter()"
        >
        </boxxds-button>
        <boxxds-button
          leftImage="download"
          btnType="unfilled"
          buttonText="EXPORT"
          [type]="'submitted'"
          [btnStyle]="{ width: '65px' }"
          (handleClick)="handleExport()"
        >
        </boxxds-button>
      </div>
    </div>
    <boxxds-table
      [data]="tableData"
      [showSpinner]="showTblSpinner"
      [totalCount]="totalDataCount"
      (pageChanged)="pageChangeHandler($event)"
      class="table"
      [ngClass]="{
        'pointer-default': !this.permissionList[this.currentScreen]
      }"
      (sortUpClicked)="handleTableColSortUp($event)"
      (sortDownClicked)="handleTableColSortDown($event)"
      id="policy-table"
      [addSortTo]="addSortTo"
      [showStar]="false"
      [showEllipsis]="showEllipsis"
      [tableStyle]="{ marginBottom: '30px' }"
      [showStatusAsBadge]="true"
      [showBadgeFieldName]="'Status'"
      (starHandler)="
        this.permissionList[this.currentScreen] && starClickHandler($event)
      "
      (ellipsisHandler)="ellipsisClickHandler($event)"
      [mapStatusBadge]="statusMappingToType"
      (rowClicked)="policyClickHandler($event)"
      [isRowClickable]="true"
      [activePageNumber]="pageNumber"
    >
    </boxxds-table>
  </div>
</div>
