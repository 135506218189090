import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ImageCroppedEvent, CropperSettings } from 'ngx-image-cropper';
import { ImageLogoSubjectService } from '../subjects/image-logo.service';

@Component({
  selector: 'app-image-logo',
  templateUrl: './image-logo.component.html',
  styleUrls: ['./image-logo.component.less'],
})
export class ImageLogoComponent implements OnInit, OnDestroy {
  @Input() croppedImage?: string;
  @Input() title: string;
  @Output() handleImageChange = new EventEmitter<string>();
  @Output() clickImageLogoClose = new EventEmitter<string>();
  @Input() isActive = false;
  @Output() triggerProfilePicError = new EventEmitter<string>();
  @Input() previousImage: string;

  defaultImage = 'assets/img/logo.png';
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  cropperSettings: CropperSettings;
  allowedFormats = ['.jpg', '.jpeg', '.png'];
  maxFileSizeMB = 2; // Maximum file size in MB

  url: string | ArrayBuffer = 'assets/img/logo.png';
  imageChangedEvent: any = '';
  showCropper = false;
  notificationImageLogoSubject: any;

  constructor(private imageLogoSubjectService: ImageLogoSubjectService) {
    this.cropperSettings = new CropperSettings();
  }

  ngOnInit() {
    this.notificationImageLogoSubject = this.imageLogoSubjectService
      .getObservavle()
      .subscribe((data: boolean) => {
        this.croppedImage = null;
      });
    if (this.croppedImage == undefined || this.croppedImage == '')
      this.croppedImage = this.defaultImage;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    const favDialog = document.getElementById('myDialog') as HTMLDialogElement;
    favDialog.showModal();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.handleImageChange.emit(this.croppedImage);
  }

  imageLoaded() {
    this.showCropper = true;
  }

  loadImageFailed() {}

  base64textString: string = '';

  handleFileSelect(evt) {
    var files = evt.target.files;
    var file = files[0];

    if (files && file) {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = (e: any) => {
        this.url = e.target.result;
      };
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
  }

  ngOnDestroy() {
    if (this.notificationImageLogoSubject) {
      this.notificationImageLogoSubject.unsubscribe();
    }
  }

  closeImageModal() {
    if (!this.previousImage) this.croppedImage = this.defaultImage;
    else this.croppedImage = this.previousImage;
    this.clearFileInput();
    this.clickImageLogoClose.emit(this.croppedImage);
  }

  selectedImage() {
    if (this.croppedImage) this.previousImage = this.croppedImage;
  }

  clearFileInput() {
    this.fileInput.nativeElement.value = null;
  }

  onImageSelected(event: any): void {
    const file = event.target?.files[0];
    if (file) {
      const fileSizeMB = file.size / (1024 * 1024);
      if (fileSizeMB > this.maxFileSizeMB) {
        this.clearFileInput();
        const errorMsg = 'File size exceeds the maximum allowed size of 2MB.';
        this.triggerProfilePicError.emit(errorMsg);
        return;
      }

      const fileExtension = this.getFileExtension(file);
      if (!this.allowedFormats.includes(fileExtension.toLowerCase())) {
        this.clearFileInput();
        const errorMsg =
          'File format is not allowed. Allowed formats: .jpg, .jpeg, .png';
        this.triggerProfilePicError.emit(errorMsg);
        return;
      } else {
        this.triggerProfilePicError.emit('');
      }

      const reader = new FileReader();
      reader.onload = (fileEvent) => {
        this.imageChangedEvent = fileEvent.target?.result;
      };
      reader.readAsDataURL(file);

      this.imageChangedEvent = event;
      const favDialog = document.getElementById(
        'myDialog',
      ) as HTMLDialogElement;
      favDialog.showModal();
    }
  }

  getFileExtension(file: File): string {
    const filename = file.name;
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1) {
      return '';
    }
    return filename.substr(lastDotIndex).toLowerCase();
  }
}
