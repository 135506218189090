import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalAlertService } from 'src/app/services/modal-alert.service';
import { getAlertHead } from 'src/app/utils/utils';
@Component({
  selector: 'app-document-action-popup',
  templateUrl: './document-action-popup.component.html',
  styleUrls: ['./document-action-popup.component.less'],
})
export class DocumentActionPopupComponent implements OnChanges, OnDestroy {
  @Input() showModal: boolean = false;
  @Input() existingFileName: string;
  @Input() suggestedFileName: string;
  @Input() isUploadingProgress: boolean = false;
  @Input() isGettingSuggestionProgress: boolean = false;
  @Input() existingAlertData: { [x: string]: any }[] = [];

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleActionConfirm = new EventEmitter<any>();

  form: FormGroup;
  buttonDisabled: boolean = true;
  overwrite: boolean = true;
  checkValidation: boolean = false;
  showSpinner: boolean = false;

  constructor(
    private fb: FormBuilder,
    private alertService: ModalAlertService,
  ) {
    this.form = this.fb.group({
      uploading_type: 'overwrite',
      file_name: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-Z0-9_() .-]+$/)],
      ],
    });
  }

  ngOnInit(): void {
    if (this.existingAlertData.length === 0) {
      this.alertService.clearAllAlerts();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form.controls['file_name'].setValue(this.suggestedFileName);
    if (this.isGettingSuggestionProgress || this.isUploadingProgress) {
      this.showSpinner = true;
    } else {
      this.showSpinner = false;
    }
    if (this.isThereIsExistingAlert()) {
      this.existingAlertData.forEach((alert) => {
        this.alertService.addAlert(alert);
      });
    }
  }

  ngOnDestroy(): void {
    this.existingAlertData = [];
  }

  isThereIsExistingAlert() {
    return this.existingAlertData.length > 0;
  }

  getOverwriteLabelStyle() {
    let dynamicStyle = {};
    if (this.overwrite) {
      dynamicStyle['fontWeight'] = '500';
    } else {
      dynamicStyle['fontWeight'] = '400';
    }
    return dynamicStyle;
  }

  getRenameLabelStyle() {
    let dynamicStyle = {};
    if (!this.overwrite) {
      dynamicStyle['fontWeight'] = '500';
    } else {
      dynamicStyle['fontWeight'] = '400';
    }
    return dynamicStyle;
  }

  radioChanged() {
    this.alertService.clearAllAlerts();
    if (this.overwrite) {
      this.overwrite = false;
    } else {
      this.overwrite = true;
    }
  }

  closeHandler(isUserAction: boolean = false) {
    this.existingAlertData = [];
    this.alertService.clearAllAlerts();
    isUserAction && this.handleClose.emit();
    this.resetForm();
  }

  actionConfirmHandler(event) {
    this.showSpinner = true;
    if (this.overwrite) {
      this.handleActionConfirm.emit({
        overwrite: true,
      });
      this.closeHandler();
    } else {
      this.checkValidation = true;
      if (!this.form.invalid) {
        this.handleActionConfirm.emit({
          overwrite: false,
          newFileName: this.form.get('file_name').value,
        });
        this.closeHandler();
      } else {
        this.showSpinner = false;
      }
    }
  }

  resetForm() {
    this.form.reset();
    this.form.controls['uploading_type'].setValue('overwrite');
    this.checkValidation = false;
    this.overwrite = true;
  }
}
