import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from './base-services';
import { Region } from '../entities/region';
import { Observable, retry, catchError } from 'rxjs';
import { BoxxResponse } from '../entities/boxx-response';

@Injectable({
  providedIn: 'root',
})
export class UiContentService extends BaseService<any> {
  constructor(http: HttpClient) {
    const baseurl =
      environment.msConfigurationBaseUrl + environment.uiContentPathUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  GetByLangAdnKey(languageId, key): Observable<BoxxResponse<any>> {
    return this.http
      .get<BoxxResponse<any>>(
        this.baseurl +
          '?languageId=' +
          languageId +
          '&key=' +
          key +
          '&page=1&limit=10&sort=id%3AASC',
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
