import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.less'],
})
export class CreatePasswordComponent implements OnInit {
  constructor(private fb: FormBuilder) {}
  form: FormGroup;

  ngOnInit(): void {
    this.form = this.fb.group({
      password: [''],
      passwordConfirm: [''],
    });
  }

  handleBlur(e) {
    let isPasswordValid = true;
    const password = this.form.value.password;
    if (password !== '') {
      const characterRegex = new RegExp('^(?=.*[A-Za-z])');
      const specialCharacterRegex = new RegExp('^(?=.*[!@#$%^&*])');
      isPasswordValid =
        password.length >= 12 &&
        characterRegex.test(password) &&
        specialCharacterRegex.test(password);
    }
    if (!isPasswordValid) {
      this.form.controls['password'].setErrors({
        message:
          'Must be at least 12 characters, have at least one upper case, lower case and special character',
      });
    }
  }

  isBtnDisabled() {
    return !(
      this.form.value.password &&
      this.form.value.passwordConfirm &&
      this.form.status !== 'INVALID'
    );
  }

  handleSubmit(e: any) {
    if (this.form.value.password !== this.form.value.passwordConfirm) {
      this.form.controls['passwordConfirm'].setErrors({
        message: 'Both passwords must match',
      });
    }
  }
}
