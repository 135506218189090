<div class="modal-outer" *ngIf="showModalNotesPopup">
  <div class="modal-container" [ngStyle]="notesContainerStyle">
    <div class="loader spinner-container" *ngIf="isNoteLoader">
      <div class="spinner-and-message-container">
        <div class="spinner-icon">
          <img src="assets/img/dotted-spinner.svg" alt="spinner-icon" />
        </div>
      </div>
    </div>
    <ng-container *ngIf="!isNoteLoader">
      <section class="modal-header">
        <div class="header-left"></div>
        <div class="header-right">
          <boxxds-button-v2
            [isDisabled]="false"
            [size]="'lg'"
            [btnType]="'close'"
            (handleClick)="closeModal()"
            [btnClass]="'close-button'"
          >
          </boxxds-button-v2>
        </div>
      </section>
      <ng-container>
        <div class="modal-notes-body">
          <section class="nav-section">
            <div class="nav-container">
              <div
                class="nav"
                (click)="
                  activeMenu !== 'add_note' && handleNavClick('add_note')
                "
                [ngClass]="{
                  active: ['add_note', 'latest_note'].includes(activeMenu),
                  noPointerEvents: activeMenu === 'add_note'
                }"
                [ngStyle]="{
                  cursor: activeMenu === 'add_note' ? 'default' : 'pointer'
                }"
              >
                <div class="nav-item">
                  {{ "workFlow3.components.notes.label.newNote" | translate }}
                  <span class="menu-left-icon" *ngIf="activeMenu === 'add_note'"
                    ><img src="assets/img/plus-circle-v3.svg"
                  /></span>
                </div>
              </div>
              <div
                class="nav"
                (click)="
                  activeMenu !== 'all_notes' && handleNavClick('all_notes')
                "
                [ngClass]="{
                  active: ['all_notes', 'note_detail'].includes(activeMenu),
                  noPointerEvents: activeMenu === 'all_notes'
                }"
                [ngStyle]="{
                  cursor: activeMenu === 'all_notes' ? 'default' : 'pointer'
                }"
              >
                <div class="nav-item">
                  {{ "workFlow3.components.notes.label.allNotes" | translate }}
                </div>
              </div>
            </div>
          </section>
          <div style="position: relative">
            <app-modal-alert></app-modal-alert>
          </div>
          <div
            class="modal-content"
            [style.height]="['latest_note'].includes(activeMenu) ? '537px' : ''"
          >
            <div class="content-container">
              <ng-template #infoBanner>
                <div class="info-banner">
                  <div class="left-section">
                    {{
                      "workFlow3.components.notes.label.quoteId" | translate
                    }}: {{ policyId }}
                  </div>
                  <div class="right-section" *ngIf="trxType">
                    <boxxds-status-badge-v2
                      [status]="trxType"
                      [statusType]="'secondary'"
                      [badgeStyle]="{
                        display: 'flex',
                        padding: '2px 8px',
                        aligItems: 'center',
                        borderRadius: '9999px',
                        border: '1px solid #B9E6FE',
                        background: '#F0F9FF',
                        width: 'fit-content',
                        color: '#026AA2',
                        textAlign: 'center',
                        fontFamily: 'Gibson',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '18px'
                      }"
                    ></boxxds-status-badge-v2>
                  </div>
                </div>
              </ng-template>

              <ng-container *ngIf="activeMenu === 'add_note'">
                <ng-container *ngTemplateOutlet="infoBanner"></ng-container>

                <div class="notes-form form" [formGroup]="notesFormGroup">
                  <div class="form-group">
                    <div class="dropdowns">
                      <boxxds-dropdown-v2
                        [label]="
                          'workFlow3.components.notes.label.selectCategory'
                            | translate
                        "
                        [options]="notesCategory"
                        [form]="notesFormGroup"
                        [control]="'categoryId'"
                        [requiredErrMsg]="
                          'workFlow3.components.notes.error.thisFieldIsRequired'
                            | translate
                        "
                        [formSubmitted]="submitted"
                        [isRequired]="true"
                        [dropdownContainerStyle]="{
                          background: '#fff',
                          borderRadius: '8px'
                        }"
                        (currentValueChange)="handleCategoryValueChange($event)"
                        [currentSelectedOption]="currentCategorySelected"
                      >
                      </boxxds-dropdown-v2>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="notes-textaraea textareaStyle">
                      <boxxds-textarea
                        [label]="
                          'workFlow3.components.notes.label.noteInputLabel'
                            | translate
                        "
                        [placeholder]="
                          'workFlow3.components.notes.label.messageBody'
                            | translate
                        "
                        [form]="notesFormGroup"
                        control="note"
                        [lblStyle]="{
                          color: '#2D2D39',
                          fontFamily: 'Gibson',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: '500',
                          lineHeight: '20px',
                          height: '28px'
                        }"
                        [textareaStyle]="{
                          background: '#fff',
                          height: '149px !important',
                          border: '1px solid #D0D5DD'
                        }"
                        [formSubmitted]="errorMsgValid"
                        [customErrMsg]="customErrMsg | translate"
                        (handleKeyUp)="onKeyUpNoteValidation($event)"
                        maxLength="500"
                      ></boxxds-textarea>
                    </div>
                  </div>
                  <div class="form-group justify-content-end mb-5">
                    <boxxds-button-v2
                      class="footer-button"
                      [buttonText]="
                        'workFlow3.components.notes.button.addNote' | translate
                      "
                      [size]="'md'"
                      [btnClass]="'primary'"
                      [btnType]="'submit'"
                      [btnStyle]="{ padding: '8px 16px' }"
                      [isDisabled]="errorMsgValid || !hasPermissionForActions()"
                      (handleClick)="
                        hasPermissionForActions() && handleNewNoteAdd($event)
                      "
                    >
                    </boxxds-button-v2>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="activeMenu === 'latest_note'">
                <div class="notes-header">
                  <ng-container
                    *ngTemplateOutlet="
                      noteHeader;
                      context: { latestNote: latestNote }
                    "
                  ></ng-container>
                </div>
                <div class="note-detail-thread-container">
                  <div class="note-info-container">
                    <ng-container
                      *ngTemplateOutlet="
                        noteInfo;
                        context: { latestNote: latestNote, type: 'note' }
                      "
                    ></ng-container>
                  </div>
                  <div class="thread-list" *ngIf="noteData.length > 0">
                    <ng-container
                      *ngTemplateOutlet="
                        threadsList;
                        context: { noteData: noteData, type: 'thread' }
                      "
                    ></ng-container>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="activeMenu === 'note_detail'">
                <div class="notes-header">
                  <ng-container
                    *ngTemplateOutlet="
                      noteHeader;
                      context: { latestNote: latestNote }
                    "
                  ></ng-container>
                </div>
                <div class="note-detail-thread-container">
                  <div class="note-info-container">
                    <ng-container
                      *ngTemplateOutlet="
                        noteInfo;
                        context: { latestNote: latestNote, type: 'note' }
                      "
                    ></ng-container>
                  </div>
                  <div class="thread-list" *ngIf="noteData.length > 0">
                    <ng-container
                      *ngTemplateOutlet="
                        threadsList;
                        context: { noteData: noteData, type: 'thread' }
                      "
                    ></ng-container>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="activeMenu === 'all_notes'">
                <div class="all-notes-container">
                  <div
                    class="note-list-container"
                    [ngStyle]="{
                      width: showFilter ? '370px' : 'auto'
                    }"
                  >
                    <div
                      class="list-header"
                      [style.padding-top]="!showFilter ? '33px' : ''"
                    >
                      <div
                        class="left-section"
                        [style.margin-top]="!showFilter ? '12px' : ''"
                      >
                        <boxxds-body
                          sz="md"
                          type="bold"
                          [customStyle]="{
                            color: '#292933',
                            fontWeight: '600',
                            fontFamily: 'Gibson',
                            fontSize: '18px',
                            lineHeight: '18px'
                          }"
                          [text]="
                            'workFlow3.components.notes.label.noteList'
                              | translate
                          "
                        >
                        </boxxds-body>
                        <boxxds-status-badge-v2
                          [status]="
                            notesCount +
                            ' ' +
                            ('workFlow3.components.notes.label.notes'
                              | translate)
                          "
                          [statusType]="'secondary'"
                          [badgeStyle]="{
                            display: 'flex',
                            padding: '2px 8px',
                            alignItems: 'center',
                            borderRadius: '9999px',
                            border: '1px solid #D9D6FE',
                            background: '#F0F9FF',
                            width: 'fit-content',
                            color: '#5925DC',
                            textAlign: 'center',
                            fontFamily: 'Gibson',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '18px',
                            margin: '0 18px'
                          }"
                        ></boxxds-status-badge-v2>
                      </div>
                      <ng-container *ngIf="!showFilter">
                        <div
                          class="right-section"
                          (click)="handleCloseFilter()"
                        >
                          <img
                            class="filter-icon"
                            src="assets/img/filter-funnel-v2.svg"
                          />
                        </div>
                      </ng-container>
                    </div>

                    <div class="list">
                      <div class="notes-list">
                        <ng-container
                          *ngTemplateOutlet="
                            threadsList;
                            context: { noteData: noteData, type: 'note' }
                          "
                        ></ng-container>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="showFilter">
                    <div class="note-filter-wrapper">
                      <div class="note-filter-container">
                        <section class="modal-header">
                          <div class="header-left filter-heading">
                            {{
                              "workFlow3.components.notes.label.filterNotesBy"
                                | translate
                            }}
                          </div>
                          <div class="header-right">
                            <boxxds-button-v2
                              [isDisabled]="false"
                              [size]="'lg'"
                              [btnType]="'close'"
                              (handleClick)="handleCloseFilter()"
                              [btnClass]="'close-button'"
                            >
                            </boxxds-button-v2>
                          </div>
                        </section>

                        <div class="filter">
                          <boxxds-textinput-v2
                            [form]="searchForm"
                            [placeholder]="'common.search' | translate"
                            [label]="
                              'workFlow3.components.notes.label.keyword'
                                | translate
                            "
                            [id]="'key-word'"
                            [containerStyle]="{
                              marginRight: '40px',
                              marginBottom: '12px',
                              marginTop: '5px'
                            }"
                            width="261px"
                            (handleKeyUp)="handleKeywordFilter($event)"
                            (handleBlur)="handleKeywordFilter($event)"
                            [control]="'keyword'"
                            [invalidErrMsg]="minLengthErrMsg | translate"
                            [formSubmitted]="true"
                            [leftIcon]="'assets/img/search-lg.svg'"
                            [maxLength]="'500'"
                          >
                          </boxxds-textinput-v2>
                          <boxxds-searchable-dropdown-v2
                            [form]="searchForm"
                            [label]="
                              'workFlow3.components.notes.label.tag' | translate
                            "
                            [placeholder]="
                              'workFlow3.components.notes.label.selectTag'
                                | translate
                            "
                            [options]="currentTagOptions"
                            [control]="'tag'"
                            [currentValue]="searchForm?.value?.tag"
                            (currentValueChange)="tagChangeHandler($event)"
                            [dispatchTextInputChange]="false"
                            [hideSelectedValue]="false"
                            class="searchable-dropdown"
                          >
                          </boxxds-searchable-dropdown-v2>
                          <boxxds-datepicker-v2
                            [placeholder]="
                              'workFlow3.components.notes.placeholder.select'
                                | translate
                            "
                            [dateFormat]="shortDateFormat"
                            [label]="
                              'workFlow3.components.notes.label.from'
                                | translate
                            "
                            [containerStyle]="{
                              width: 'auto',
                              marginBottom: '12px',
                              color: '#667085'
                            }"
                            [bsValue]="searchForm.controls['fromDate']?.value"
                            (bsValueChange)="handleStartDateChange($event)"
                            [showErr]="invalidDateRange"
                            [errMsg]="startDateErrMsg | translate"
                          >
                          </boxxds-datepicker-v2>
                          <boxxds-datepicker-v2
                            [placeholder]="
                              'workFlow3.components.notes.placeholder.select'
                                | translate
                            "
                            [dateFormat]="shortDateFormat"
                            [label]="
                              'workFlow3.components.notes.label.to' | translate
                            "
                            [containerStyle]="{
                              width: 'auto',
                              marginBottom: '12px',
                              color: '#667085'
                            }"
                            [bsValue]="searchForm.controls['toDate']?.value"
                            (bsValueChange)="handleEndDateChange($event)"
                            [showErr]="invalidDateRange"
                            [errMsg]="endDateErrMsg | translate"
                          >
                          </boxxds-datepicker-v2>
                          <boxxds-dropdown-v2
                            [form]="searchForm"
                            [control]="'writer'"
                            [placeholder]="
                              'workFlow3.components.notes.placeholder.select'
                                | translate
                            "
                            [label]="
                              'workFlow3.components.notes.label.writer'
                                | translate
                            "
                            [options]="options"
                            (currentValueChange)="writerFilterHandler($event)"
                            [dropdownContainerStyle]="{
                              width: 'auto',
                              marginBottom: '12px'
                            }"
                            [currentValue]="searchForm.controls['writer'].value"
                            [hasScrollablePagination]="true"
                          >
                          </boxxds-dropdown-v2>
                          <ng-container>
                            <div class="btn-container">
                              <boxxds-button-v2
                                [btnStyle]="{
                                  color: '#2D2D39',
                                  fontSize: '14px',
                                  fontFamily: 'Gibson',
                                  fontWeight: '600',
                                  marginRight: '24px'
                                }"
                                [buttonText]="
                                  'workFlow3.components.notes.button.clear'
                                    | translate
                                "
                                [btnType]="'link gray'"
                                type="button"
                                (handleClick)="resetFilterForm()"
                              >
                              </boxxds-button-v2>

                              <boxxds-button-v2
                                [btnStyle]="{
                                  maxWidth: '100px',
                                  padding: '8px 30px',
                                  margin: '4px 0 0 4px'
                                }"
                                [buttonText]="
                                  'workFlow3.components.notes.button.apply'
                                    | translate
                                "
                                [btnType]="'secondary gray'"
                                [isDisabled]="
                                  !isSearchEnabled() || invalidDateRange
                                "
                                type="submit"
                                (handleClick)="handleNotesFilter()"
                              >
                              </boxxds-button-v2>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
          <ng-container *ngIf="['latest_note'].includes(activeMenu)">
            <section class="modal-footer">
              <div class="footer-content">
                <boxxds-button-v2
                  class="footer-button"
                  [buttonText]="
                    'workFlow3.components.notes.button.addNewNote' | translate
                  "
                  [size]="'md'"
                  [btnClass]="'primary'"
                  [btnType]="'default'"
                  (handleClick)="redirectNote('add_note')"
                  [btnStyle]="{ padding: '8px 12px' }"
                  [rightImage]="'plus-circle'"
                  [rightImageStyle]="{ margin: '0 4px' }"
                >
                </boxxds-button-v2>
              </div>
            </section>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #noteHeader let-latestNote="latestNote">
  <header class="note-detail-header-outer">
    <div class="back-button" *ngIf="activeMenu === 'note_detail'">
      <boxxds-button-v2
        [btnClass]="'link gray'"
        [btnType]="'default'"
        [size]="'sm'"
        [buttonText]="'workFlow3.components.shared.button.back' | translate"
        [leftImage]="'arrow-narrow-left'"
        (handleClick)="handleNavClick('all_notes')"
      ></boxxds-button-v2>
    </div>
    <div class="note-detail-header">
      <h1 class="head">
        {{ "workFlow3.components.notes.label.quoteId" | translate }}:
        {{ policyId }}
        <!-- {{
          // TODO get correct option number and product name from api in notes endpoint
          latestNote?.quoteOptionId
            ? "/" + getQuoteOption(latestNote?.quoteOptionId)
            : ""
        }} -->
        {{ activeMenu === "note_detail" ? "/" + product : "" }}
      </h1>
      <div class="tags-conatiner">
        <boxxds-status-badge-v2
          [status]="trxType"
          [statusType]="'secondary'"
          [badgeStyle]="{
            display: 'flex',
            padding: '2px 8px',
            aligItems: 'center',
            borderRadius: '9999px',
            border: '1px solid #B9E6FE',
            background: '#F0F9FF',
            width: 'fit-content',
            color: '#026AA2',
            textAlign: 'center',
            fontFamily: 'Gibson',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '18px'
          }"
        ></boxxds-status-badge-v2>
      </div>
    </div>
  </header>
</ng-template>
<ng-template #noteInfo let-latestNote="latestNote" let-type="type">
  <div class="note-detail-container">
    <div
      class="note-detail-info showMenuOnHover"
      [ngClass]="{
        'edit-notes-container': latestNote.edit,
        itemPopupHover: latestNote.id === activeId
      }"
      (mouseover)="
        !latestNote.edit &&
          showPopover(
            $event,
            menuPopoversTemplate,
            getEditAndDeleteNotesPermission(latestNote)
              ? ['edit', 'delete']
              : [],
            {
              type: 'note',
              info: latestNote,
              fromList: false
            }
          )
      "
      (mouseleave)="hidePopover($event, latestNote.id)"
    >
      <p class="note-detail-text">
        {{ latestNote.note }}
      </p>
      <div
        class="edit-detail-text"
        [formGroup]="
          type === 'thread' ? editReplyNotesFormGroup : editNotesFormGroup
        "
      >
        <textarea
          #textareaRef
          type="text"
          class="edit-text"
          (input)="autoResize()"
          formControlName="note"
          [value]="latestNote.note"
        ></textarea>
        <div class="edit-detail-buttons">
          <boxxds-button-v2
            [btnClass]="'secondary gray'"
            [btnType]="'default'"
            [size]="'sm'"
            [buttonText]="
              'workFlow3.components.shared.button.cancel' | translate
            "
            [btnStyle]="{ width: '80px', padding: '3px 12px' }"
            (handleClick)="cancelEditNote(type, latestNote, false, $event)"
          ></boxxds-button-v2>
          <boxxds-button-v2
            [isDisabled]="!hasPermissionForActions()"
            [btnClass]="'primary'"
            [btnType]="'default'"
            [size]="'sm'"
            [buttonText]="'workFlow3.components.shared.button.save' | translate"
            [btnStyle]="{ width: '80px', padding: '3px 12px' }"
            (handleClick)="
              hasPermissionForActions() &&
                saveEditNote(type, latestNote, false, $event)
            "
          ></boxxds-button-v2>
        </div>
      </div>
      <div class="tags-conatiner">
        <boxxds-status-badge-v2
          [status]="latestNote.TrxTypeStatus"
          [statusType]="'secondary'"
          [badgeStyle]="{
            display: 'flex',
            padding: '2px 8px',
            aligItems: 'center',
            borderRadius: '9999px',
            border: '1px solid #B9E6FE',
            background: '#F0F9FF',
            width: 'fit-content',
            color: '#026AA2',
            textAlign: 'center',
            fontFamily: 'Gibson',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '18px'
          }"
        ></boxxds-status-badge-v2>
      </div>
      <div class="note-detail-sub-text">
        <span class="sub-text">{{ latestNote.createdBy }} </span>
        <span class="round-dot"></span>
        <span class="sub-text">{{
          formatDateForNotes(latestNote.createdDt)
        }}</span>
        <span class="round-dot"></span>
        <span class="replies-text">{{ latestNote.replyCount }} Replies</span>
      </div>
    </div>
    <div class="note-detail-type-reply-container" *ngIf="type === 'note'">
      <boxxds-textarea
        [label]="
          'workFlow3.components.notes.label.replyNoteInputLabel' | translate
        "
        [placeholder]="
          'workFlow3.components.notes.label.messageBody' | translate
        "
        [form]="replyNotesFormGroup"
        control="note"
        [lblStyle]="{
          color: '#2D2D39',
          fontFamily: 'Gibson',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '20px',
          height: '28px'
        }"
        [textareaStyle]="{
          background: '#fff',
          height: '75px !important',
          border: '1px solid #D0D5DD'
        }"
        [formSubmitted]="errorMsgValid"
        [customErrMsg]="customErrMsg | translate"
        (handleKeyUp)="onKeyUpNoteReplyValidation($event)"
        maxLength="500"
      ></boxxds-textarea>
    </div>
    <div class="note-detail-reply-button" *ngIf="type === 'note'">
      <boxxds-button-v2
        [btnClass]="'primary'"
        [btnType]="'default'"
        [size]="'sm'"
        [buttonText]="'workFlow3.components.notes.label.reply' | translate"
        [isDisabled]="errorMsgValid || !hasPermissionForActions()"
        [btnStyle]="{ width: '110px' }"
        (handleClick)="
          hasPermissionForActions() && addReplyNote(type, latestNote)
        "
      ></boxxds-button-v2>
    </div>
  </div>
</ng-template>
<ng-template #threadsList let-noteData="noteData" let-type="type">
  <div class="note-detail-thread">
    <boxxds-body
      *ngIf="type === 'thread'"
      [text]="'workFlow3.components.notes.label.thread' | translate"
      [sz]="'md'"
      [customStyle]="{
        color: '#2D2D39',
        fontFamily: 'Gibson',
        fontStyle: 'normal',
        fontWeight: '500'
      }"
    ></boxxds-body>
    <div class="note-detail-threads-List" (scroll)="onScroll($event, type)">
      <div
        class="threads-container showMenuOnHover"
        [ngClass]="{
          'edit-threads-container': item.edit,
          itemPopupHover: item.id === activeId
        }"
        (mouseover)="
          !item.edit &&
            showPopover(
              $event,
              menuPopoversTemplate,
              type === 'thread'
                ? getEditAndDeleteNotesPermission(item)
                  ? ['edit', 'delete']
                  : []
                : [
                    getEditAndDeleteNotesPermission(item) ? 'edit' : '',
                    'reply',
                    getEditAndDeleteNotesPermission(item) ? 'delete' : ''
                  ],
              { type: type, info: item, fromList: true }
            )
        "
        (mouseleave)="hidePopover($event, item.id)"
        *ngFor="let item of noteData"
        (click)="!item.edit && clickThreadList(type, item)"
      >
        <p class="thread-info">{{ item.note }}</p>
        <div
          class="edit-detail-text"
          [formGroup]="
            type === 'thread' ? editReplyNotesFormGroup : editNotesFormGroup
          "
        >
          <textarea
            #textareaRef
            (input)="autoResize()"
            type="text"
            class="edit-text"
            formControlName="note"
            [value]="item.note"
          ></textarea>
          <div class="edit-detail-buttons">
            <boxxds-button-v2
              [btnClass]="'secondary gray'"
              [btnType]="'default'"
              [size]="'sm'"
              [buttonText]="
                'workFlow3.components.shared.button.cancel' | translate
              "
              [btnStyle]="{ width: '80px', padding: '3px 12px' }"
              (handleClick)="cancelEditNote(type, item, true, $event)"
            ></boxxds-button-v2>
            <boxxds-button-v2
              [isDisabled]="!hasPermissionForActions()"
              [btnClass]="'primary'"
              [btnType]="'default'"
              [size]="'sm'"
              [buttonText]="
                'workFlow3.components.shared.button.save' | translate
              "
              [btnStyle]="{ width: '80px', padding: '3px 12px' }"
              (handleClick)="
                hasPermissionForActions() &&
                  saveEditNote(type, item, true, $event)
              "
            ></boxxds-button-v2>
          </div>
        </div>

        <div class="tags-conatiner" *ngIf="type !== 'thread'">
          <boxxds-status-badge-v2
            [status]="item.TrxTypeStatus"
            [statusType]="'secondary'"
            [badgeStyle]="{
              display: 'flex',
              padding: '2px 8px',
              aligItems: 'center',
              borderRadius: '9999px',
              border: '1px solid #B9E6FE',
              background: '#F0F9FF',
              width: 'fit-content',
              color: '#026AA2',
              textAlign: 'center',
              fontFamily: 'Gibson',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '18px'
            }"
          ></boxxds-status-badge-v2>
        </div>
        <div class="note-detail-sub-text">
          <span class="sub-text">{{ item.createdBy }} </span>
          <span class="round-dot"></span>
          <span class="sub-text">{{ formatDateForNotes(item.createdDt) }}</span>
          <ng-container *ngIf="type !== 'thread'">
            <span class="round-dot"></span>
            <span class="replies-text">{{ item.replyCount }} Replies</span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template
  #menuPopoversTemplate
  let-menuItems="menuItems"
  let-position="position"
  let-context="context"
>
  <div
    class="note-detail-menu-popover"
    [style.top]="position.top"
    [style.left]="position.left"
    (mouseenter)="cancelHide()"
    (mouseleave)="closePopover()"
    *ngIf="!isNoteLoader"
  >
    <ul class="menu-list" type="none">
      <ng-container *ngFor="let item of menuItems">
        <li
          class="menu-item"
          *ngIf="item !== ''"
          (click)="handleMenuItem(item, context, $event)"
        >
          <span class="menu-icon menu-edit-icon" *ngIf="item === 'edit'">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M2.39735 15.0963C2.43564 14.7517 2.45478 14.5794 2.50691 14.4184C2.55316 14.2755 2.61851 14.1396 2.70118 14.0142C2.79436 13.8729 2.91694 13.7503 3.16209 13.5052L14.1673 2.49992C15.0878 1.57945 16.5802 1.57945 17.5007 2.49993C18.4211 3.4204 18.4211 4.91279 17.5007 5.83326L6.49542 16.8385C6.25027 17.0836 6.1277 17.2062 5.98639 17.2994C5.86102 17.3821 5.72506 17.4474 5.58219 17.4937C5.42115 17.5458 5.24887 17.5649 4.90429 17.6032L2.08398 17.9166L2.39735 15.0963Z"
                stroke="#667085"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span class="menu-icon menu-reply-icon" *ngIf="item === 'reply'">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10.4155 12.0833V7.08333M7.91554 9.58333H12.9155M10.4155 16.6667C14.3276 16.6667 17.4989 13.4953 17.4989 9.58333C17.4989 5.67132 14.3276 2.5 10.4155 2.5C6.50352 2.5 3.33221 5.67132 3.33221 9.58333C3.33221 10.375 3.46208 11.1363 3.70168 11.8472C3.79185 12.1147 3.83693 12.2484 3.84506 12.3512C3.85309 12.4527 3.84702 12.5238 3.82192 12.6225C3.7965 12.7223 3.74037 12.8262 3.62812 13.034L2.26507 15.557C2.07064 15.9168 1.97343 16.0968 1.99519 16.2356C2.01414 16.3566 2.08533 16.4631 2.18985 16.5269C2.30985 16.6001 2.51329 16.579 2.92016 16.537L7.18768 16.0958C7.31691 16.0825 7.38153 16.0758 7.44042 16.0781C7.49835 16.0803 7.53924 16.0857 7.59573 16.0987C7.65317 16.112 7.72539 16.1398 7.86984 16.1954C8.65988 16.4998 9.51823 16.6667 10.4155 16.6667Z"
                stroke="#667085"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span class="menu-icon menu-delete-icon" *ngIf="item === 'delete'">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M13.3333 4.99984V4.33317C13.3333 3.39975 13.3333 2.93304 13.1517 2.57652C12.9919 2.26292 12.7369 2.00795 12.4233 1.84816C12.0668 1.6665 11.6001 1.6665 10.6667 1.6665H9.33333C8.39991 1.6665 7.9332 1.6665 7.57668 1.84816C7.26308 2.00795 7.00811 2.26292 6.84832 2.57652C6.66667 2.93304 6.66667 3.39975 6.66667 4.33317V4.99984M8.33333 9.58317V13.7498M11.6667 9.58317V13.7498M2.5 4.99984H17.5M15.8333 4.99984V14.3332C15.8333 15.7333 15.8333 16.4334 15.5608 16.9681C15.3212 17.4386 14.9387 17.821 14.4683 18.0607C13.9335 18.3332 13.2335 18.3332 11.8333 18.3332H8.16667C6.76654 18.3332 6.06647 18.3332 5.53169 18.0607C5.06129 17.821 4.67883 17.4386 4.43915 16.9681C4.16667 16.4334 4.16667 15.7333 4.16667 14.3332V4.99984"
                stroke="#667085"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>
