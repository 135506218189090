import { Action, createAction, props } from '@ngrx/store';
import {
  CompanySearchForm,
  AddCompanyForm,
  AddIndividualForm,
  IndividualSearchForm,
  UISpecificValues,
} from '../../models/quote.model';
import { Insured } from 'src/app/entities/insured';
import { Pagination } from 'src/app/entities/boxx-response';
import { InsuredCompanyPostRequest } from 'src/app/entities/insured-company';
import { InsuredIndividualPostRequest } from 'src/app/entities/insured-individual';
export const UPDATE_INSURED = 'UPDATE_INSURED';
export const SEARCH_INSURED_COMPANY = 'SEARCH_INSURED_COMPANY';
export const SEARCH_INSURED_CUSTOMER = 'SEARCH_INSURED_CUSTOMER';
export const SET_SHOW_COMPANY_SEARCH_RESULT = 'SET_SHOW_COMPANY_SEARCH_RESULT';
export const UNSET_SHOW_COMPANY_SEARCH_RESULT =
  'UNSET_SHOW_COMPANY_SEARCH_RESULT';
export const SET_SHOW_CUSTOMER_SEARCH_RESULT = 'SET_SHOW_SEARCH_RESULT';
export const UNSET_SHOW_CUSTOMER_SEARCH_RESULT = 'UNSET_SHOW_SEARCH_RESULT';
export const ADD_INSURED_COMPANY = 'ADD_INSURED_COMPANY';
export const ADD_INSURED_CUSTOMER = 'ADD_INSURED_CUSTOMER';
export const RESET_INSURED_TYPE = 'RESET_INSURED_TYPE';
export const SET_ADD_COMPANY_EXPANDED = 'SET_ADD_COMPANY_EXPANDED';
export const UNSET_ADD_COMPANY_EXPANDED = 'UNSET_ADD_COMPANY_EXPANDED';
export const SET_ADD_CUSTOMER_EXPANDED = 'SET_ADD_CUSTOMER_EXPANDED';
export const UNSET_ADD_CUSTOMER_EXPANDED = 'UNSET_ADD_CUSTOMER_EXPANDED';
export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';
export const SELECT_ALL_PRODUCTS_CLICKED = 'SELECT_ALL_PRODUCTS_CLICKED';
export const UPDATE_START_DATE = 'UPDATE_START_DATE';
export const UPDATE_END_DATE = 'UPDATE_END_DATE';
export const UPDATE_CURRENT_PRODUCT = 'UPDATE_CURRENT_PRODUCT';
export const UPDATE_PRODUCT_UNDERWRITER = 'UPDATE_PRODUCT_UNDERWRITER';
export const UPDATE_PRODUCT_LIMIT = 'UPDATE_PRODUCT_LIMIT';
export const UPDATE_PRODUCT_ADDONS = 'export const';
export const UPDATE_RISK_ANSWER = 'UPDATE_RISK_ANSWER';
export const ADD_QUOTE_OPTION = 'ADD_QUOTE_OPTION';
export const DELETE_QUOTE_OPTION = 'DELETE_QUOTE_OPTION';
export const UPDATE_QUOTE_OPTION = 'UPDATE_QUOTE_OPTION';
export const SET_IS_QUOTE_BOX_EXPANDED = 'SET_IS_QUOTE_BOX_EXPANDED';
export const RESET_IS_QUOTE_BOX_EXPANDED = 'RESET_IS_QUOTE_BOX_EXPANDED';
export const SELECT_QUOTE = 'SELECT_QUOTE';
export const RETRIEVE_API_INSURED = '[Insured From List/API] Retrieve insured';
export const RETRIEVE_API_INSURED_BY_ID =
  '[Region From List/API] Retrieve Regions';
export const GET_INSURED_BY_COMPANY =
  '[Insured List] Retrieve Insureds by company';
export const GET_INSURED_BY_INDIVIDUAL =
  '[Insured List] Retrieve Insureds by individual';
export const LOAD_INSURED = '[Insured List] Load Insured Success';
export const RESET_PAGINATION = 'RESET_PAGINATION';
export const CREATE_INSURED_COMPANY = 'CREATE_INSURED_COMPANY';
export const CREATE_INSURED_INDIVIDUAL = 'CREATE_INSURED_INDIVIDUAL';
export const CREATE_INSURED_SUCCESS = 'CREATE_INSURED_SUCCESS';
export const CREATE_INSURED_FAILURE = 'CREATE_INSURED_FAILURE';
export const CREATE_LOCATION = 'CREATE_LOCATION';
export const LOAD_INDUSTRY_CLASS = 'LOAD_INDUSTRY_CLASS';
export const LOAD_INDUSTRY_BY_INDUSTRY_CLASS_ID =
  'LOAD_INDUSTRY_BY_INDUSTRY_CLASS_ID';
export const UPDATE_INDUSTRY_CLASS = 'UPDATE_INDUSTRY_CLASS';
export const UPDATE_INDUSTRY = 'UPDATE_INDUSTRY';
export const LOAD_RISK_REGION = 'LOAD_RISK_REGION';
export const GET_RISK_REGION_BY_VERSION = 'GET_RISK_REGION_BY_VERSION';
export const SET_BROKERAGE_BRANCH_PRODUCER = 'SET_BROKERAGE_BRANCH_PRODUCER';
export const RESET_STATE = 'RESET_STATE';
export const LOAD_RISK_QUESTIONS = 'LOAD_RISK_QUESTIONS';
export const GET_RISK_QUESTIONS = 'GET_RISK_QUESTIONS';
export const LOAD_RISK_QUESTIONS_ERROR = 'LOAD_RISK_QUESTIONS_ERROR';
export const SET_POLICY_PERIOD_ID = 'SET_POLICY_PERIOD_ID';
export const SET_POLICY_NUMBER_BOUNDED_QUOTE =
  'SET_POLICY_NUMBER_BOUNDED_QUOTE';
export const RESET_IS_NAVIGATED_FROM_LIFECYCLE =
  'RESET_IS_NAVIGATED_FROM_LIFECYCLE';
export const RESET_IS_NAVIGATED_FROM_QUOTE_CALC =
  'RESET_IS_NAVIGATED_FROM_QUOTE_CALC';
export const RESET_IS_NAVIGATED_FROM_SUMMARY =
  'RESET_IS_NAVIGATED_FROM_SUMMARY';
export const SET_DASHBOARD_PATH = 'SET_DASHBOARD_PATH';
export const SET_POLICY_ID = 'SET_POLICY_ID';
export const LOAD_RISK_COVERAGES = 'LOAD_RISK_COVERAGES';
export const GET_RISK_COVERAGES = 'GET_RISK_COVERAGES';
export const LOAD_RISK_COVERAGES_ERROR = 'LOAD_RISK_COVERAGES_ERROR';
export const UPDATE_RISK_COVERAGES = 'UPDATE_RISK_COVERAGES';
export const SET_ALL_MAIL_ID = 'SET_ALL_MAIL_ID';
export const UPDATE_UI_CONTENT = 'UPDATE_UI_CONTENT';
export const CLEAR_QUOTE_OPTION = 'CLEAR_QUOTE_OPTION';
export const SET_SANCTION = 'SET_SANCTION';
export const UPDATE_SHARED_DATA = 'UPDATE_SHARED_DATA';
export const CLEAR_RISK_QUESTIONS = 'CLEAR_RISK_QUESTIONS';
export const RESET_IS_NAVIGATED_FROM_RISK_ANALYSIS =
  'RESET_IS_NAVIGATED_FROM_RISK_ANALYSIS';

export class SearchInsuredCompanyAction implements Action {
  readonly type = SEARCH_INSURED_COMPANY;
  constructor(public payload: CompanySearchForm) {}
}

export class ResetState implements Action {
  readonly type = RESET_STATE;
}

export class setSanctionAction implements Action {
  readonly type = SET_SANCTION;
  constructor(public payload) {}
}

export class updateSharedDataAction implements Action {
  readonly type = UPDATE_SHARED_DATA;
  constructor(public payload) {}
}

export class ResetIsNavigatedFromLifecycle implements Action {
  readonly type = RESET_IS_NAVIGATED_FROM_LIFECYCLE;
  constructor(public newState: boolean) {}
}

export class ResetIsNavigatedFromSummary implements Action {
  readonly type = RESET_IS_NAVIGATED_FROM_SUMMARY;
}

export class clearQuoteOptions implements Action {
  readonly type = CLEAR_QUOTE_OPTION;
}

export class setIsNavigatedFromQuoteCalc implements Action {
  readonly type = RESET_IS_NAVIGATED_FROM_QUOTE_CALC;
}
export class setIsNavigatedFromRiskAnalysis implements Action {
  readonly type = RESET_IS_NAVIGATED_FROM_RISK_ANALYSIS;
}

export class UpdateInsuredAction implements Action {
  readonly type = UPDATE_INSURED;
  constructor(public payload) {}
}

export class SearchInsuredCustomerAction implements Action {
  readonly type = SEARCH_INSURED_CUSTOMER;
  constructor(public payload: IndividualSearchForm) {}
}

export class SetShowCompanySearchAction implements Action {
  readonly type = SET_SHOW_COMPANY_SEARCH_RESULT;
}

export class UnsetShowCompanySearchAction implements Action {
  readonly type = UNSET_SHOW_COMPANY_SEARCH_RESULT;
}

export class SetShowCustomerSearchAction implements Action {
  readonly type = SET_SHOW_CUSTOMER_SEARCH_RESULT;
}

export class UnsetShowCustomerSearchAction implements Action {
  readonly type = UNSET_SHOW_CUSTOMER_SEARCH_RESULT;
}

export class SetAddCompanyExpandedAction implements Action {
  readonly type = SET_ADD_COMPANY_EXPANDED;
}

export class UnsetAddCompanyExpandedAction implements Action {
  readonly type = UNSET_ADD_COMPANY_EXPANDED;
}

export class SetAddCustomerExpandedAction implements Action {
  readonly type = SET_ADD_CUSTOMER_EXPANDED;
}

export class UnsetAddCustomerExpandedAction implements Action {
  readonly type = UNSET_ADD_CUSTOMER_EXPANDED;
}

export class ResetInsuredTypeAction implements Action {
  readonly type = RESET_INSURED_TYPE;
}

export class AddInsuredCompanyAction implements Action {
  readonly type = ADD_INSURED_COMPANY;
  constructor(public payload: AddCompanyForm) {}
}

export class AddInsuredCustomerAction implements Action {
  readonly type = ADD_INSURED_CUSTOMER;
  constructor(public payload: AddIndividualForm) {}
}

export class UpdateProductsAction implements Action {
  readonly type = UPDATE_PRODUCTS;
  constructor(public index: number) {}
}

export class setPolicyPeriodId implements Action {
  readonly type = SET_POLICY_PERIOD_ID;
  constructor(public id: number) {}
}

export class UpdateCurrentproductAction implements Action {
  readonly type = UPDATE_CURRENT_PRODUCT;
  constructor(public product: string) {}
}

export class SelectAllProductAction implements Action {
  readonly type = SELECT_ALL_PRODUCTS_CLICKED;
  constructor(public changeCheckBoxTo: boolean) {}
}

export class UpdateStartDateAction implements Action {
  readonly type = UPDATE_START_DATE;
  constructor(public date: string) {}
}

export class UpdateEndDateAction implements Action {
  readonly type = UPDATE_END_DATE;
  constructor(public date: string) {}
}

export class UpdateUnderwriterForPdtAction implements Action {
  readonly type = UPDATE_PRODUCT_UNDERWRITER;
  constructor(
    public payload: {
      index: number;
      underwriter: string;
      underwriterId: number;
    },
  ) {}
}
export class UpdateLimitForPdtAction implements Action {
  readonly type = UPDATE_PRODUCT_LIMIT;
  constructor(
    public payload: {
      index: number;
      limit: string;
      limitId: number;
    },
  ) {}
}

export class UpdateAddonsForPdtAction implements Action {
  readonly type = UPDATE_PRODUCT_ADDONS;
  constructor(public payload: { index: number; addons: any }) {}
}

export class UpdateRiskAnswerAction implements Action {
  readonly type = UPDATE_RISK_ANSWER;
  constructor(
    public payload: {
      product: string;
      id?: number;
      value: boolean | number | string;
      category?: string;
    },
  ) {}
}

export class AddQuoteOption implements Action {
  readonly type = ADD_QUOTE_OPTION;
  constructor(public payload: { quote; duplicateIdx?: number }) {}
}

export class UpdateQuoteOption implements Action {
  readonly type = UPDATE_QUOTE_OPTION;
  constructor(public payload: { quote; index: number }) {}
}

export class DeleteQuoteOption implements Action {
  readonly type = DELETE_QUOTE_OPTION;
  constructor(public payload: { index: number }) {}
}

export class SetIsQuoteBoxExpanded implements Action {
  readonly type = SET_IS_QUOTE_BOX_EXPANDED;
  constructor() {}
}
export class ResetIsQuoteBoxExpanded implements Action {
  readonly type = RESET_IS_QUOTE_BOX_EXPANDED;
  constructor() {}
}

export class SelectQuoteOption implements Action {
  readonly type = SELECT_QUOTE;
  constructor(public payload) {}
}

export class setDashboardPath implements Action {
  readonly type = SET_DASHBOARD_PATH;
  constructor(public payload: { path: string; underwriter: string }) {}
}

export class setPolicyId implements Action {
  readonly type = SET_POLICY_ID;
  constructor(public payload: number) {}
}
export class setAllMailId implements Action {
  readonly type: string = SET_ALL_MAIL_ID;
  constructor(public payload: { [x: string]: string | string[] }) {}
}

export class updateUiContents implements Action {
  readonly type: string = UPDATE_UI_CONTENT;
  constructor(public payload: { [x: string]: any }) {}
}

export const loadInsuredSuccess = createAction(
  LOAD_INSURED,
  props<{ insureds: ReadonlyArray<Insured>; pagination: Pagination }>(),
);
export const loadProductsSuccess = createAction(
  LOAD_RISK_REGION,
  props<{ products: any }>(),
);
export const loadRiskQuestionsSuccess = createAction(
  LOAD_RISK_QUESTIONS,
  props<{ riskQuestions: any }>(),
);
export const loadRiskQuestionsError = createAction(LOAD_RISK_QUESTIONS_ERROR);
export const loadIndustrySuccess = createAction(
  UPDATE_INDUSTRY,
  props<{ insureds: ReadonlyArray<Insured> }>(),
);
export const loadIndustryClassSuccess = createAction(
  UPDATE_INDUSTRY_CLASS,
  props<{ insureds: ReadonlyArray<Insured> }>(),
);
export const loadRiskCoveragesSuccess = createAction(
  LOAD_RISK_COVERAGES,
  props<{ riskCoverages: any }>(),
);
export const loadRiskCoveragesError = createAction(LOAD_RISK_COVERAGES_ERROR);

export const resetPagination = createAction(RESET_PAGINATION);
/**Effects */
export const retrievedInsuredApi = createAction(RETRIEVE_API_INSURED);

export const retrievedInsuredsByCompany = createAction(
  GET_INSURED_BY_COMPANY,
  props<{
    userId: number;
    companyName: string;
    companyWebsite: string;
    address: string;
    page: number;
    sort: string;
    active?: number;
  }>(),
);

export const retrievedInsuredsByIndividual = createAction(
  GET_INSURED_BY_INDIVIDUAL,
  props<{
    userId: number;
    name: string;
    email: string;
    address: string;
    page: number;
    sort: string;
    active: number | null;
  }>(),
);

export const createInsuredCompany = createAction(
  CREATE_INSURED_COMPANY,
  props<{ userId: number; insured: InsuredCompanyPostRequest }>(),
);

export const createInsuredIndividual = createAction(
  CREATE_INSURED_INDIVIDUAL,
  props<{ userId: number; insured: InsuredIndividualPostRequest }>(),
);

export const creationInsuredSuccess = createAction(
  CREATE_INSURED_SUCCESS,
  props<any>(),
);

export const creationInsuredFailure = createAction(
  CREATE_INSURED_FAILURE,
  props<{ message: string }>(),
);

export const createLocation = createAction(
  CREATE_LOCATION,
  props<{ userId: number; location: any }>(),
);
export const loadInudustryClassdApi = createAction(LOAD_INDUSTRY_CLASS);

export const loadIndustryByIndustryClass = createAction(
  LOAD_INDUSTRY_BY_INDUSTRY_CLASS_ID,
  props<{ userId: number; industryClassId: number }>(),
);

export const loadProducts = createAction(
  GET_RISK_REGION_BY_VERSION,
  props<{ userId: number; regionId: number; versionId: number }>(),
);

export const loadRiskQuestions = createAction(
  GET_RISK_QUESTIONS,
  props<{
    userId: number;
    regionId: number;
    languageId: number;
    insuredId: number;
    product: string;
    limit: number;
  }>(),
);

export const loadRiskCoverages = createAction(
  GET_RISK_COVERAGES,
  props<{
    userId: number;
    regionId: number;
    riskId: number;
    riskRegionId: number;
    insuredId: number;
    product: string;
    limit: number;
  }>(),
);
export class UpdateRiskCoverageAction implements Action {
  readonly type = UPDATE_RISK_COVERAGES;
  constructor(
    public payload: {
      product: string;
      id?: number;
      value: boolean | number | string;
    },
  ) {}
}

export class setBrokerageBranchProducerAction implements Action {
  readonly type = SET_BROKERAGE_BRANCH_PRODUCER;
  constructor(public payload) {}
}

export class clearRiskQuestions implements Action {
  readonly type = CLEAR_RISK_QUESTIONS;
}
export type Actions =
  | SearchInsuredCompanyAction
  | SearchInsuredCustomerAction
  | SetShowCompanySearchAction
  | UnsetShowCompanySearchAction
  | SetShowCustomerSearchAction
  | UnsetShowCustomerSearchAction
  | AddInsuredCompanyAction
  | AddInsuredCustomerAction
  | UpdateProductsAction
  | setDashboardPath
  | setIsNavigatedFromQuoteCalc
  | setIsNavigatedFromRiskAnalysis
  | UpdateStartDateAction
  | UpdateEndDateAction
  | UpdateCurrentproductAction
  | UpdateUnderwriterForPdtAction
  | SelectQuoteOption
  | UpdateRiskAnswerAction
  | AddQuoteOption
  | UpdateQuoteOption
  | DeleteQuoteOption
  | SetIsQuoteBoxExpanded
  | ResetIsQuoteBoxExpanded
  | setBrokerageBranchProducerAction
  | setPolicyId
  | UpdateRiskCoverageAction
  | clearRiskQuestions;
