import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Pagination } from 'src/app/entities/boxx-response';
import { Breadcrumb } from 'src/app/models/breadcrumb.model';
import { Quote } from 'src/app/models/quote.model';
import { DomainsService } from 'src/app/services/domains.service';
import { PermissionService } from 'src/app/services/permission.service';
import { arrayToObjet } from 'src/app/utils/utils';
import { BoxxResponse } from 'src/app/entities/boxx-response';
import { Domains } from 'src/app/models/domains';
import { SYSTEM_USER_TYPES_SCREENS } from 'src/app/constants/security/systemUserType';
import { Permission } from 'src/app/entities/permission';
import { formatDateTime } from 'src/app/utils/formatDate';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import {
  getDashboardSelector,
  getUserTypes,
} from 'src/app/store/dashboard/dashboard.selector';
import { Store, select } from '@ngrx/store';
import { getErrorMessage } from 'src/app/utils/utils';

@Component({
  selector: 'app-edit-permission',
  templateUrl: './edit-permission.component.html',
  styleUrls: ['./edit-permission.component.less'],
})
export class EditPermissionComponent implements OnInit {
  itemsMenu: Breadcrumb[] = [
    { label: 'Roles & permissions', path: '/dashboard/security/roles' },
    { label: 'List of permissions', path: '/dashboard/security/permissions' },
    { label: 'Edit permissions', path: null },
  ];

  form: FormGroup;
  form$: Observable<Quote>;
  searchKey;
  submitted: boolean = false;
  totalDataCount = 0;
  pagination: Observable<Pagination>;
  isSearchResult: boolean = false;
  screens: Array<any> = [];
  systemUserTypes: Array<any> = [];
  permissionForm = new FormGroup({
    permissionName: new FormControl('', [Validators.required]),
    systemUserType: new FormControl('', [Validators.required]),
    screen: new FormControl('', [Validators.required]),
  });
  requiredErrMsg: string = 'This field is required';
  invalidErrMsg: string = 'This field is invalid';
  isSuccess = false;
  hasError = false;
  errorMessage = '';
  formSubmitted: boolean = false;
  showSpinner: boolean = false;
  showBodySpinner: boolean = false;
  systemUserType = '';
  screen = '';
  id = '';
  permission: any;
  createdDt = '';
  updatedDt = '';
  showErrorAlert = false;
  msgErrorAlert = '';
  userID: number;
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  shortDateTimeFormat: string = '';
  longDateTimeFormat: string = '';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private permissionService: PermissionService,
    private domainService: DomainsService,
    private localStorageService: LocalStorageService,
    private store: Store,
  ) {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
  }

  dropdownValueChanged() {}

  pageChangeHandler(pageNumber) {}

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateTimeFormat = data.shortDateTimeFormat;
      this.longDateTimeFormat = data.longDateTimeFormat;
    });

    this.store.pipe(select(getUserTypes)).subscribe((data) => {
      const tableData = data.map((dataObj) => ({
        key: dataObj.description,
        value: dataObj.id,
        id: dataObj.id,
      }));
      if (tableData.length === 0) {
        this.fetchSystemUserTypes();
      }
      this.systemUserTypes = [...tableData];
    });
    if (this.id) {
      this.fetchPermissions(this.id);
    }
  }

  fetchSystemUserTypes() {
    this.showBodySpinner = true;
    this.userID = this.localStorageService.getBoxxUserId();
    this.domainService.GetByDomainCode('SYSTEMUSERTYPE').subscribe({
      next: (response) => {
        this.showBodySpinner = false;
        this.setSystemUserTypesResult(response);
        if (this.id) {
          this.fetchPermissions(this.id);
        }
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });
  }

  setSystemUserTypesResult(data: BoxxResponse<Domains>) {
    this.showBodySpinner = true;
    const tableData = data.data?.map((dataObj) => ({
      key: `${dataObj.description}`,
      value: `${dataObj.id}`,
    }));
    this.systemUserTypes = [...tableData];
  }

  fetchPermissions(id: string) {
    this.showBodySpinner = true;
    this.permissionService.GetPermission(this.id).subscribe({
      next: (response) => {
        this.showBodySpinner = false;
        this.setPermissionResult(response);
        this.fetchScreens(response.data.systemUserType);
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });
  }

  fetchScreens(value) {
    const screenDomainCode = SYSTEM_USER_TYPES_SCREENS[`SCREEN${value}`];
    this.domainService.GetByDomainCode(screenDomainCode).subscribe({
      next: (response) => {
        const tableData = response.data.map((dataObj) => ({
          key: dataObj.description,
          value: dataObj.id,
        }));
        this.screens = tableData;
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });
    const systemUserTypes = arrayToObjet(this.systemUserTypes, 'value');
    const systemUserType = systemUserTypes[value];
    this.systemUserType = systemUserType.key;
  }

  setPermissionResult(data: BoxxResponse<Permission>) {
    const { data: permission } = data;
    this.permission = permission;
    this.populateForm();
  }

  populateForm() {
    const { permissionName, systemUserType, Screen } = this.permission;

    this.permissionForm.get('permissionName').setValue(permissionName);
    this.permissionForm.get('systemUserType').setValue(systemUserType);
    this.permissionForm.get('screen').setValue(Screen.id);

    this.screen = Screen.description;
    this.createdDt = formatDateTime(
      this.permission.createdDt,
      this.longDateTimeFormat,
    );
    this.updatedDt = formatDateTime(
      this.permission.updatedDt,
      this.longDateTimeFormat,
    );
  }

  handleAttachSubjectives(event, index) {}

  getSearchKeyWords(keywordObj = {}) {}

  addPermission(event) {}

  systemUserTypeChanged(value) {
    // reset screen fields
    this.screens = [];
    this.screen = '';
    this.permissionForm.get('screen').setValue('');

    const screenDomainCode = SYSTEM_USER_TYPES_SCREENS[`SCREEN${value}`];
    this.domainService.GetByDomainCode(screenDomainCode).subscribe({
      next: (response) => {
        const tableData = response.data.map((dataObj) => ({
          key: dataObj.description,
          value: dataObj.id,
        }));
        this.screens = tableData;
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });
    const systemUserTypes = arrayToObjet(this.systemUserTypes, 'value');
    const systemUserType = systemUserTypes[value];
    this.systemUserType = systemUserType.key;
    this.permissionForm.get('systemUserType').setValue(systemUserType.value);
  }

  screenChanged(value) {
    const screens = arrayToObjet(this.screens, 'value');
    const screen = screens[value];
    this.screen = screen.key;
  }

  resetPermisionForm() {
    this.permissionForm.reset();

    this.systemUserType = '';
    this.screen = '';
  }

  onSubmit(data) {
    this.formSubmitted = true;
    this.hasError = false;

    // check boxx user form is invalid
    if (this.permissionForm.invalid) {
      return;
    }
    this.showSpinner = true;
    this.permissionService.UpdatePermission(this.id, data).subscribe(
      (response) => {
        this.permission['updatedDt'] = this.updatedDt = formatDateTime(
          response?.data?.updatedDt,
          this.longDateTimeFormat,
        );
        this.permission['updatedBy'] = response?.data?.updatedBy;
        this.showSpinner = false;
        this.isSuccess = true;
        this.formSubmitted = false;
      },
      ({ error }) => {
        this.showSpinner = false;
        this.hasError = true;
        this.errorMessage = error.message;
      },
    );
  }

  handleNoOfPIIRecords(event) {}

  handleCloseSuccessEvent() {
    this.isSuccess = false;
    this.hasError = false;
  }
  handleCloseSuccessEventExt() {
    this.showErrorAlert = false;
  }
}
