<div class="holder">
  <div class="card">
    <div class="info-panel">
      <div class="content">
        <boxxds-heading
          [sz]="'5'"
          [text]="'You have successfully logged out'"
          type="bold"
          [customStyle]="{
            color: '#2D2D39',
            maxWidth: '317px',
            margin: '0 auto'
          }"
        ></boxxds-heading>
        <boxxds-body
          sz="sm"
          [text]="'Go to the login page to access your account'"
          [customStyle]="{ color: '#333334', marginTop: '8px' }"
        >
        </boxxds-body>
        <boxxds-button
          buttonText="Login page"
          [ngStyle]="{ display: 'flex', justifyContent: 'center' }"
          [btnStyle]="{ width: '208px', marginTop: '60px' }"
          (handleClick)="handleNavigateToLogin()"
        >
        </boxxds-button>
      </div>
    </div>
    <div class="logo-panel">
      <div class="logo">
        <img src="assets/img/boxx.png" alt="boxx logo" />
      </div>
    </div>
  </div>
</div>
