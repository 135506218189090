import { Action } from '@ngrx/store';
import { Brokerage } from 'src/app/entities/brokerage';

export const SAVE_BROKERAGE = 'SAVE_BROKERAGE';
export const SET_BROKERAGE_CREATED = 'SET_BROKERAGE_CREATED';
export const RESET_BROKERAGE_CREATED = 'RESET_BROKERAGE_CREATED';
export const SET_BRANCH_CREATED = 'SET_BRANCH_CREATED';
export const RESET_BRANCH_CREATED = 'RESET_BRANCH_CREATED';
export const SET_PRODUCER_CREATED = 'SET_PRODUCER_CREATED';
export const RESET_PRODUCER_CREATED = 'RESET_PRODUCER_CREATED';

export class saveBrokerageAction implements Action {
  readonly type = SAVE_BROKERAGE;
  constructor(public payload: Brokerage) {}
}

export class setBrokerageCreated implements Action {
  readonly type = SET_BROKERAGE_CREATED;
}

export class resetBrokerageCreated implements Action {
  readonly type = RESET_BROKERAGE_CREATED;
}

export class setBranchCreated implements Action {
  readonly type = SET_BRANCH_CREATED;
}

export class resetBranchCreated implements Action {
  readonly type = RESET_BRANCH_CREATED;
}

export class setProducerCreated implements Action {
  readonly type = SET_PRODUCER_CREATED;
}

export class resetProducerCreated implements Action {
  readonly type = RESET_PRODUCER_CREATED;
}

export type Actions =
  | saveBrokerageAction
  | setBrokerageCreated
  | resetBrokerageCreated
  | setBranchCreated
  | resetBranchCreated
  | setProducerCreated
  | resetProducerCreated;
