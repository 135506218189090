<div class="modal-outer sensitive-data" *ngIf="showModalFilterPopup">
  <div class="modal-container" [ngStyle]="filterContainerStyle">
    <div class="loader spinner-container" *ngIf="isFilterLoader">
      <div class="spinner-and-message-container">
        <div class="spinner-icon">
          <img src="assets/img/dotted-spinner.svg" alt="spinner-icon" />
        </div>
      </div>
    </div>
    <ng-container *ngIf="!isFilterLoader">
      <form [formGroup]="filterFormGroup">
        <div class="filter-modal">
          <section class="modal-header">
            <div class="header-left">
              <div class="filter-title">{{ filterTitle }}</div>
              <div class="filter-title-dec">{{ filterTitleDescription }}</div>
            </div>
            <div class="header-right">
              <boxxds-button-v2
                [isDisabled]="false"
                [size]="'lg'"
                [btnType]="'close'"
                (handleClick)="closeModal()"
                [btnClass]="'close-button'"
              >
              </boxxds-button-v2>
            </div>
          </section>
          <ng-container>
            <div class="modal-filter-body">
              <div style="position: relative">
                <app-modal-alert></app-modal-alert>
              </div>
              <div class="modal-content">
                <div class="list" [ngClass]="{ scrollable: rows.length > 6 }">
                  <section
                    class="mb-3"
                    *ngFor="let item of rows; let i = index"
                  >
                    <div
                      class="input-group"
                      [ngClass]="{
                        'disabled-error':
                          formSubmitted &&
                          filterFormGroup.get(item.selectedInputValue).invalid,
                        'hover-none': item.isRequired
                      }"
                      *ngIf="item"
                    >
                      <div class="input-group-prepend">
                        <div class="dropdown full-content">
                          <button
                            [disabled]="item.isRequired"
                            class="dropdown-toggle full-content d-flex justify-content-between align-items-center"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span
                              class="dropdown-input"
                              [title]="
                                getSelectedOptionLabel(item.selectedInputValue)
                              "
                            >
                              {{
                                getSelectedOptionLabel(item.selectedInputValue)
                              }}
                            </span>
                            <img src="assets/img/chevron-down.svg" alt="" />
                          </button>
                          <ul class="dropdown-menu dropdown-list">
                            <li
                              *ngFor="let option of item.filterInputOptions"
                              (click)="selectOption(item, option)"
                              [class.disabled]="isSelected(option.value)"
                              [class.dropdown-item-disabled]="
                                isSelected(option.value)
                              "
                              [title]="option.label"
                            >
                              {{ option.label }}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="input-section" *ngIf="item">
                        <ng-container
                          [formGroup]="filterFormGroup"
                          *ngTemplateOutlet="
                            getTemplate(item.selectedInputType);
                            context: { item: item }
                          "
                        ></ng-container>
                      </div>
                      <div class="action-button-section">
                        <div
                          class="close-button-section"
                          [class.disabled]="item.isRequired"
                        >
                          <img
                            (click)="clearSelection(item)"
                            src="assets/img/x-close-01.svg"
                          />
                        </div>
                        <ng-container *ngIf="!item.isRequired">
                          <div class="delete-icon-section">
                            <img
                              (click)="rows.length > 3 && deleteRow(i, item)"
                              src="assets/img/trash-02.svg"
                            />
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <span class="error-msg">
                      <div
                        *ngIf="
                          formSubmitted &&
                          filterFormGroup.get(item.selectedInputValue).invalid
                        "
                      >
                        <span
                          *ngIf="
                            filterFormGroup.get(item.selectedInputValue)
                              .errors &&
                            filterFormGroup.get(item.selectedInputValue).errors[
                              'required'
                            ]
                          "
                          >{{
                            "workFlow3.components.filter.error.thisFieldIsRequired"
                              | translate
                          }}</span
                        >
                        <span
                          *ngIf="
                            !filterFormGroup.get(item.selectedInputValue).errors
                          "
                          >{{ item.customErrMsg | translate }}</span
                        >
                        <!-- Add more error messages for other validators if needed -->
                      </div>
                    </span>
                  </section>
                </div>

                <div class="content-container">
                  <boxxds-button-v2
                    [btnStyle]="{
                      fontSize: '14px',
                      fontFamily: 'Gibson',
                      fontWeight: '600',
                      padding: '24px 24px 24px 0px'
                    }"
                    [leftImage]="'plus-circle'"
                    [buttonText]="
                      'workFlow3.components.filter.button.addNewFilter'
                        | translate
                    "
                    [btnType]="'link '"
                    type="button"
                    [isDisabled]="!isCheckAddNewEnabled"
                    (handleClick)="handleNewRowClick($event)"
                  >
                  </boxxds-button-v2>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container>
            <div class="modal-footer">
              <div class="footer-content">
                <boxxds-button-v2
                  *ngIf="clearAllBtn"
                  class="footer-button"
                  [buttonText]="
                    'workFlow3.components.shared.button.clearAll' | translate
                  "
                  [size]="'lg'"
                  [btnClass]="'secondary gray'"
                  [btnType]="'default'"
                  (handleClick)="handleClear($event)"
                  [btnStyle]="{ padding: '10px 14px' }"
                >
                </boxxds-button-v2>
                <boxxds-button-v2
                  class="footer-button"
                  [buttonText]="
                    'workFlow3.components.shared.button.apply' | translate
                  "
                  [size]="'lg'"
                  [btnClass]="'primary'"
                  [btnType]="'default'"
                  (handleClick)="handleFilter()"
                  [btnStyle]="{ padding: '10px 14px', marginLeft: '15px' }"
                >
                </boxxds-button-v2>
              </div>
            </div>
          </ng-container>
        </div>
      </form>
    </ng-container>

    <ng-template #textInputTemplate let-item="item">
      <boxxds-textinput-v2
        (input)="clearErrors(item.selectedInputValue)"
        class="full-content"
        [form]="filterFormGroup"
        [type]="'text'"
        [placeholder]="item.placeholder"
        [control]="item.selectedInputValue"
        (handleBlur)="handleTextInput($event, item)"
        [hasOuterBorder]="false"
        [width]="'100%'"
        [containerStyle]="{
          border: 'none',
          borderRadius: '0px',
          height: 'auto',
          marginBottom: '0px',
          marginTop: '0px',
          minWidth: '136px',
          background: 'transparent',
          fontSize: '16px'
        }"
      >
      </boxxds-textinput-v2>
      <!-- <input type="text"
       class="full-content"
       [formControlName]="item.selectedInputValue"
      
         /> -->
    </ng-template>

    <ng-template
      #numberInputTemplate
      let-item="item"
      [formGroup]="filterFormGroup"
    >
      <boxxds-textinput-v2
        (input)="clearErrors(item.selectedInputValue)"
        class="full-content"
        [form]="filterFormGroup"
        [type]="'number'"
        [placeholder]="item.placeholder"
        [control]="item.selectedInputValue"
        (handleBlur)="handleTextInput($event, item)"
        [hasOuterBorder]="false"
        [width]="'100%'"
        [containerStyle]="{
          border: 'none',
          borderRadius: '0px',
          height: 'auto',
          marginBottom: '0px',
          marginTop: '0px',
          minWidth: '136px',
          background: 'transparent',
          fontSize: '16px'
        }"
        pattern="/^\d+$/"
      >
      </boxxds-textinput-v2>
    </ng-template>

    <ng-template #dropdownInputTemplate let-item="item">
      <boxxds-dropdown-v2
        [isReadonly]="item.isRequired"
        [form]="filterFormGroup"
        [control]="item.selectedInputValue"
        [placeholder]="'Select'"
        [options]="item.dropdownOptions"
        [currentValue]="item.currentSelectedOption.key"
        (currentValueChange)="handledropdownValueChange($event, item)"
        [currentSelectedOption]="item.currentSelectedOption"
        class="full-content"
        [btnStyle]="{
          border: 'none',
          borderRadius: '0px',
          height: 'auto',
          marginBottom: '0px',
          minWidth: '136px',
          background: 'transparent'
        }"
        [dropdownContainerStyle]="{ margin: '0px' }"
        [lblStyle]="{ margin: '0px' }"
        [showKeyAsTitle]="true"
        [hasScrollablePagination]="true"
        (loadMoreOptions)="loadMoreOptions($event)"
      >
      </boxxds-dropdown-v2>
    </ng-template>

    <ng-template #dropdownCustomInputTemplate let-item="item">
      <boxxds-dropdown-v2
        *ngIf="
          !item.currentSelectedOption ||
          item.currentSelectedOption.value !== 'custom-amount'
        "
        [isReadonly]="item.isRequired"
        [form]="filterFormGroup"
        [control]="item.selectedInputValue"
        [placeholder]="'Select'"
        [options]="item.dropdownOptions"
        [currentValue]="
          item.currentSelectedOption.key ?? ''
            | currencyFormat: currency : 'symbol-narrow' : '1.0-0'
        "
        (currentValueChange)="handledropdownCustomValueChange($event, item)"
        [currentSelectedOption]="item.currentSelectedOption"
        class="full-content"
        [btnStyle]="{
          border: 'none',
          borderRadius: '0px',
          height: 'auto',
          marginBottom: '0px',
          minWidth: '136px',
          background: 'transparent'
        }"
        [dropdownContainerStyle]="{ margin: '0px' }"
        [lblStyle]="{ margin: '0px' }"
        [showKeyAsTitle]="true"
        [hasScrollablePagination]="true"
        (loadMoreOptions)="loadMoreOptions($event)"
      >
      </boxxds-dropdown-v2>
      <boxxds-textinput-v2
        *ngIf="
          item.currentSelectedOption &&
          item.currentSelectedOption.value === 'custom-amount'
        "
        (input)="clearErrors(item.selectedInputValue)"
        class="full-content"
        [form]="filterFormGroup"
        [type]="'number'"
        [placeholder]="''"
        prefix="$"
        [control]="item.selectedInputValue"
        (handleBlur)="handleTextInput($event, item)"
        [size]="'md'"
        [width]="'100%'"
        [noStateStyle]="true"
        [noBorder]="true"
        [inputStyle]="{
          marginTop: '0px'
        }"
        [maxLength]="'25'"
      ></boxxds-textinput-v2>
    </ng-template>

    <ng-template #dateRangeTemplate let-item="item">
      <div
        class="date-Range-contaner"
        *ngIf="getFormGroup(item.selectedInputValue) as dateForm"
      >
        <boxxds-datepicker-v2
          [placeholder]="'From'"
          [dateFormat]="'MM/DD/YYYY'"
          [bsValue]="dateForm.value['from']"
          (bsValueChange)="handleDateRangeValueChange($event, item, 'from')"
          [noStateStyle]="true"
          [noBorder]="true"
          [isDisabled]="false"
          [isRequired]="false"
        ></boxxds-datepicker-v2>
        <span class="border-vertical"></span>
        <boxxds-datepicker-v2
          [placeholder]="'To'"
          [dateFormat]="'MM/DD/YYYY'"
          [bsValue]="dateForm.value['to']"
          (bsValueChange)="handleDateRangeValueChange($event, item, 'to')"
          [noStateStyle]="true"
          [noBorder]="true"
          [isDisabled]="false"
          [isRequired]="false"
        ></boxxds-datepicker-v2>
      </div>
    </ng-template>

    <ng-template #dropdownRangeTemplate let-item="item">
      <div
        class="date-Range-contaner"
        *ngIf="getFormGroup(item.selectedInputValue) as rangeForm"
      >
        <div class="from-container">
          <boxxds-dropdown-v2
            *ngIf="
              !item.currentSelectedOption['from'] ||
              item.currentSelectedOption['from'].value !== 'custom-amount'
            "
            [isReadonly]="item.isRequired"
            [form]="rangeForm"
            [control]="'from'"
            [placeholder]="'From'"
            [options]="item.dropdownOptions"
            [currentValue]="
              item.currentSelectedOption['from']?.key ?? ''
                | currencyFormat: currency : 'symbol-narrow' : '1.0-0'
            "
            (currentValueChange)="
              handledropdownRangeValueChange($event, item, 'from')
            "
            [currentSelectedOption]="item.currentSelectedOption['from']"
            class="full-content"
            [noStateStyle]="true"
            [noBorder]="true"
            [showLabel]="false"
            [leftSymbol]="'$'"
            [placeholder]="'5,000' | translate"
            [showKeyAsTitle]="true"
            [subKeyStyle]="{
              fontSize: '12px',
              fontWeight: '400',
              color: '#D46B00'
            }"
          >
          </boxxds-dropdown-v2>
          <boxxds-textinput-v2
            *ngIf="
              item.currentSelectedOption['from'] &&
              item.currentSelectedOption['from'].value === 'custom-amount'
            "
            (input)="clearSubErrors(item.selectedInputValue, 'from')"
            class="full-content"
            [form]="rangeForm"
            [type]="'number'"
            [placeholder]="''"
            prefix="$"
            [control]="'from'"
            (handleBlur)="handleSubTextInput($event, item, 'from')"
            [size]="'md'"
            [width]="'100%'"
            [noStateStyle]="true"
            [noBorder]="true"
            [inputStyle]="{
              marginTop: '0px'
            }"
            [maxLength]="'25'"
          ></boxxds-textinput-v2>
        </div>
        <span class="border-vertical"></span>
        <div class="to-container">
          <boxxds-dropdown-v2
            *ngIf="
              !item.currentSelectedOption['to'] ||
              item.currentSelectedOption['to'].value !== 'custom-amount'
            "
            [isReadonly]="item.isRequired"
            [showLabel]="false"
            [form]="rangeForm"
            [control]="'to'"
            [placeholder]="'To'"
            [options]="item.dropdownOptions"
            [currentValue]="
              item.currentSelectedOption['to']?.key ?? ''
                | currencyFormat: currency : 'symbol-narrow' : '1.0-0'
            "
            (currentValueChange)="
              handledropdownRangeValueChange($event, item, 'to')
            "
            [currentSelectedOption]="item.currentSelectedOption['to']"
            class="full-content"
            [noStateStyle]="true"
            [noBorder]="true"
            [leftSymbol]="'$'"
            [placeholder]="'5,000' | translate"
            [showKeyAsTitle]="true"
            [subKeyStyle]="{
              fontSize: '12px',
              fontWeight: '400',
              color: '#D46B00'
            }"
          >
          </boxxds-dropdown-v2>
          <boxxds-textinput-v2
            *ngIf="
              item.currentSelectedOption['to'] &&
              item.currentSelectedOption['to'].value === 'custom-amount'
            "
            (input)="clearSubErrors(item.selectedInputValue, 'to')"
            class="full-content"
            [form]="rangeForm"
            [type]="'number'"
            [placeholder]="''"
            [control]="'to'"
            prefix="$"
            (handleBlur)="handleSubTextInput($event, item, 'to')"
            [size]="'md'"
            [width]="'100%'"
            [inputStyle]="{
              marginTop: '0px'
            }"
            [noStateStyle]="true"
            [noBorder]="true"
            [maxLength]="'25'"
          ></boxxds-textinput-v2>
        </div>
      </div>
    </ng-template>

    <ng-template #multiSearchTemplate let-item="item">
      <boxxds-multiselect-autocomplete-v2
        (handleSelect)="handlemultiDropdownSelect($event, item)"
        (updateValue)="handlemultiDropdownSelect($event, item)"
        [selectedOptions]="filterFormGroup.value[item.selectedInputValue]"
        [options]="item.dropdownOptions"
        (searchEvent)="searchOption($event, item)"
        (loadMore)="loadMoreOptions(item)"
        [validationMsg]="'common.filterLimitInfo' | translate"
        [searchable]="true"
        [manualOpen]="true"
        [noStateStyle]="true"
        [noBorder]="true"
      ></boxxds-multiselect-autocomplete-v2>
    </ng-template>
    <ng-template #multiDropdownTemplate let-item="item">
      <boxxds-multiselect-autocomplete-v2
        (handleSelect)="handlemultiDropdownSelect($event, item)"
        (updateValue)="handlemultiDropdownSelect($event, item)"
        [selectedOptions]="filterFormGroup.value[item.selectedInputValue]"
        [options]="item.dropdownOptions"
        (searchEvent)="searchOption($event, item)"
        (loadMore)="loadMoreOptions(item)"
        [validationMsg]="'common.filterLimitInfo' | translate"
        [searchable]="false"
        [manualOpen]="false"
        [noStateStyle]="true"
        [noBorder]="true"
        [readonly]="item.isRequired"
      ></boxxds-multiselect-autocomplete-v2>
    </ng-template>
  </div>
</div>
