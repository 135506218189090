import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Breadcrumb } from 'src/app/models/breadcrumb.model';
import { IBrokerageModel } from 'src/app/models/brokerage.model';
import {
  getFormGroup,
  mapFormGroupToModel,
  checkRegexPatternMainWebsite,
  checkRegexPatternMainEmail,
  checkRegexPatternMultidomain,
} from '../new-brokerage/factorys/formGroup.factory';
import { CommissionService } from '../services/commisssion.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BrokerageService } from 'src/app/services/brokerage.service';
import { Store, select } from '@ngrx/store';
import { BrokerageRelationshipService } from 'src/app/services/brokerage-relationship';
import { forkJoin, take, Subject, Subscription } from 'rxjs';
import { BrokerageProfileService } from 'src/app/services/brokerage-profile.service';
import {
  saveBrokerageParentCompany,
  saveBrokerageProfileAction,
} from 'src/app/store/brokerageProfile/brokerageProfile.action';
import { dateTimeWithUserName } from 'src/app/utils/formatDate';
import { ViewportScroller } from '@angular/common';
import { ICommission } from 'src/app/models/commission.model';
import { BrokerageCommissionService } from 'src/app/services/brokerage-commissions.service';
import { BrokerageProfile } from 'src/app/entities/brokerage-profile';
import { IBrokerageRelationship } from 'src/app/entities/brokerage-relationship';
import { LocationService } from 'src/app/services/location.service';
import { resetBrokerageCreated } from 'src/app/store/brokerage/brokerage.action';
import { getBrokerageCreatedSelector } from 'src/app/store/brokerage/brokerage.select';
import { formatDateTime } from 'src/app/utils/formatDate';
import { getErrorMessage } from 'src/app/utils/utils';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { Commission } from 'src/app/entities/commission';
import { Pagination } from 'src/app/entities/boxx-response';
import { BrokerageParentCompany } from 'src/app/entities/brokerage-parent-company';
import { getBrokerageCompanyDetails } from 'src/app/store/brokerageProfile/brokerageProfile.select';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import * as DashboardActionTypes from 'src/app/store/dashboard/dashboard.action';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-brokerage',
  templateUrl: './view-brokerage.component.html',
  styleUrls: ['./view-brokerage.component.less'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ViewBrokerageComponent implements OnInit, OnDestroy {
  form: FormGroup;
  formSubmitted: boolean = false;
  isQuote4Flow: boolean = false;
  workflow3: boolean = false;

  requiredErrMsg: string = 'This field is required';
  invalidErrMsg: string = 'This field is invalid';
  parentCompanyInvalidErrMsg: string = '';
  brokerageParents = [{ key: 'one', value: 'one' }];
  itemsMenu: Breadcrumb[] = [
    { label: 'Brokerage directory', path: `/dashboard/brokerage` },
    { label: '', path: null },
  ];
  brokerageSelectedId: number = 0;
  parentInitValue: string;
  brokerageSelected: any = null;
  brokerageProfileOldSelected: BrokerageProfile = null;
  brokerageRelationshipOldSelected: IBrokerageRelationship = null;
  createdBy: string;
  updatedBy: string;
  operationSuccess = false;
  hasError = false;
  errorMessage = '';
  successMessage = '';
  isFetching = true;
  listCommissions: ICommission[] = [];
  hasErrorBrokerage = false;
  errorMessageBrokerage = '';
  successMessageBrokerage = '';
  brokerageActive = true;
  errorMessageCommission = '';
  isNavigatedFromAddBrokerage = false;
  brokerageUpdatedSuccess = false;
  existingError = false;
  hasErrorProfile = false;
  errorMessageProfile = '';
  operationSuccessProfile = false;

  hasErrorRelationship = false;
  errorMessageRelationship = '';
  operationSuccessRelationship = false;
  locationRegionIdSelected = 0;
  locationOldSelection = null;
  showSpinner: boolean = false;
  operationSuccessLocations = false;
  hasErrorLocation = false;
  errorMessageLocation = false;
  showErrorAlert = false;
  msgErrorAlert = '';
  reloadBranchTable = false;
  notificationAlert: {
    show?: boolean;
    bodyText?: string;
    type?: string;
    headerText?: string;
  } = { show: false };

  resetAddCommissionFormSubject: Subject<boolean> = new Subject<boolean>();

  brokerageCommissions: { data: Commission[]; pagination: Pagination | null } =
    { data: [], pagination: null };
  private userId: number;
  showBodySpinner: boolean = false;

  initialBrokerageFormValue: any;
  initialLocationFormValue: any;
  isBrokerageFormDirty: boolean = false;
  isLocationFormDirty: boolean = false;
  otherFieldsUpdated: boolean = false;
  parentFieldsUpdated: boolean = false;
  logoFieldsUpdated: boolean = false;
  spinnerCounter: number = 0;

  btnStyle: { [key: string]: any } = { padding: '0 30px' };
  private brokerageFormSubscription: Subscription;
  private locationFormSubscription: Subscription;
  showConfirmationModal: boolean = false;
  commissionConfirmationData: any;
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  showBackToQuoteButton: boolean = false;
  previousNavigatedUrl;
  isNavigatedFromAlert: boolean = false;
  shortDateTimeFormat: string = '';
  longDateTimeFormat: string = '';

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private commissionService: CommissionService,
    private brokerageService: BrokerageService,
    private brokerageRelationshipService: BrokerageRelationshipService,
    private brokerageProfileService: BrokerageProfileService,
    private viewPortScroller: ViewportScroller,
    private brokerageCommissionService: BrokerageCommissionService,
    private locationService: LocationService,
    private router: Router,
    private localStorageService: LocalStorageService,
  ) {
    this.previousNavigatedUrl = this.router.getCurrentNavigation();
  }

  ngOnInit(): void {
    this.isQuote4Flow =
      this.localStorageService.getQuoteFlowSteps() === '4' ? true : false;
    this.workflow3 =
      this.localStorageService.getQuoteFlowSteps() === 'workflow3'
        ? true
        : false;

    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.isNavigatedFromAlert = data?.isNavigatedFromAlert;
      this.shortDateTimeFormat = data.shortDateTimeFormat;
      this.longDateTimeFormat = data.longDateTimeFormat;
    });
    this.isEnabledBackToQuoteLink();
    this.showBodySpinner = true;
    this.viewPortScroller.scrollToPosition([0, 0]);
    this.userId = this.localStorageService.getBoxxUserId();

    this.store
      .select(getBrokerageCreatedSelector)
      .pipe(take(1))
      .subscribe((value) => (this.isNavigatedFromAddBrokerage = value));

    this.store.dispatch(new resetBrokerageCreated());

    this.form = getFormGroup(this.fb);
    this.activatedRoute.params.subscribe((params) => {
      this.brokerageSelectedId = params['brokerageId'] as number;
      this.initLoadData(this.brokerageSelectedId);
      this.getAllCommission();
    });

    this.form.get('brokerage').valueChanges.subscribe((value) => {
      if (this.existingError === true) {
        this.isBrokerageFormDirty = !this.isFormUnchanged(
          'brokerage',
          this.initialBrokerageFormValue,
        );
      }
    });

    this.form.get('location').valueChanges.subscribe((value) => {
      if (this.existingError === true) {
        this.isLocationFormDirty = !this.isFormUnchanged(
          'location',
          this.initialLocationFormValue,
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.store.dispatch(
      new DashboardActionTypes.updateDashboardConfigAction({
        isNavigatedFromAlert: null,
      }),
    );
    this.unSubscriptionForms();
  }

  private initLoadData(brokerageSelectedId: number) {
    forkJoin({
      brokerage: this.brokerageService.GetBrokerage(brokerageSelectedId),
      brokerageRelationship: this.brokerageRelationshipService.getAllByFilter({
        childBrokerId: brokerageSelectedId,
      }),
      brokerageProfile: this.brokerageProfileService.Get(brokerageSelectedId),
    }).subscribe(({ brokerage, brokerageRelationship, brokerageProfile }) => {
      if (
        Object.entries(brokerage.data).length === 0 ||
        brokerageProfile.data.length === 0
      ) {
        let isMock = 'true';
        if (
          this.localStorageService.getMockExternalAPIs() === 'false' ||
          environment.mockExternalAPIs === false
        ) {
          isMock = 'false';
        }
        this.router.navigate(['dashboard/home'], {
          queryParams: {
            mock: isMock,
          },
          skipLocationChange: true,
        });
      }
      this.locationRegionIdSelected = brokerage?.data?.location?.region?.id;
      const relationship =
        brokerageRelationship.data.length > 0
          ? brokerageRelationship.data[0]
          : null;

      if (relationship) {
        let parentCompanyDetails: BrokerageParentCompany = {
          id: relationship?.parentBroker?.id ?? undefined,
          name: relationship?.parentBroker?.name ?? '',
          searchText: relationship?.parentBroker?.name ?? '',
        };
        this.store.dispatch(
          new saveBrokerageParentCompany(parentCompanyDetails),
        );
        this.brokerageRelationshipOldSelected = { ...relationship };
        this.parentInitValue = relationship.parentBroker.name;
        this.form
          .get('brokerage.parent')
          .setValue(relationship.parentBroker.id);
      }
      this.brokerageProfileOldSelected = brokerageProfile.data[0];
      if (brokerageProfile.data[0])
        this.form.get('brokerage.logo').setValue(brokerageProfile.data[0].logo);
      this.isFetching = false;
      this.brokerageSelected = brokerage.data;
      this.brokerageActive = this.brokerageSelected.active;
      this.form[this.brokerageSelected.active ? 'enable' : 'disable']({
        emitEvent: false,
      });
      this.itemsMenu[1] = { label: this.brokerageSelected.name, path: null };
      this.store.dispatch(
        new saveBrokerageProfileAction(brokerageProfile.data[0]),
      );
      this.initForm();
      setTimeout(() => {
        this.viewPortScroller.scrollToPosition([0, 0]);
      }, 0);
      this.showBodySpinner = false;
    });
  }

  private getAllCommission(pageNumber: number = 1) {
    this.brokerageCommissionService
      .GetAllCommissions({ brokerageId: this.brokerageSelectedId }, pageNumber)
      .subscribe({
        next: (response) => {
          ({
            data: this.brokerageCommissions.data,
            pagination: this.brokerageCommissions.pagination,
          } = response);
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
  }

  handleCloseSuccessEvent() {
    this.showErrorAlert = false;
  }

  private initForm() {
    this.unSubscriptionForms();
    if (this.brokerageSelected) {
      this.setSectionDate();

      this.isBrokerageFormDirty = this.isLocationFormDirty = false;
      this.form.controls['brokerage']
        .get('name')
        .setValue(this.brokerageSelected.name);
      this.form.controls['brokerage']
        .get('email')
        .setValue(this.brokerageSelected.email);
      this.form.controls['brokerage']
        .get('domains')
        .setValue(this.brokerageSelected.domain);
      this.form.controls['brokerage']
        .get('phoneNumber')
        .setValue(this.brokerageSelected.telephone ?? '');
      this.form.controls['brokerage']
        .get('website')
        .setValue(this.brokerageSelected.website);

      this.initialBrokerageFormValue = this.form.get('brokerage').value;
      this.brokerageFormSubscription = this.form
        .get('brokerage')
        .valueChanges.subscribe(() => {
          this.isBrokerageFormDirty = !this.isFormUnchanged(
            'brokerage',
            this.initialBrokerageFormValue,
          );
        });

      this.locationOldSelection = { ...this.brokerageSelected.location };
      this.form.controls['location']
        .get('city')
        .setValue(this.brokerageSelected.location.city);
      this.form.controls['location']
        .get('address')
        .setValue(this.brokerageSelected.location.address);
      this.form.controls['location']
        .get('addressLine2')
        .setValue(this.brokerageSelected.location.addressLine2 ?? '');
      this.form.controls['location']
        .get('country')
        .setValue(this.brokerageSelected.location.country);
      this.form.controls['location']
        .get('province')
        .setValue(this.brokerageSelected.location.region.name);
      this.form.controls['location']
        .get('zipPostalCode')
        .setValue(this.brokerageSelected.location.zipPostalCode);

      this.initialLocationFormValue = this.form.get('location').value;
      this.locationFormSubscription = this.form
        .get('location')
        .valueChanges.subscribe(() => {
          this.isLocationFormDirty = !this.isFormUnchanged(
            'location',
            this.initialLocationFormValue,
          );
        });
    }
  }

  private unSubscriptionForms() {
    if (this.brokerageFormSubscription) {
      this.brokerageFormSubscription.unsubscribe();
    }
    if (this.locationFormSubscription) {
      this.locationFormSubscription.unsubscribe();
    }
  }

  isFormUnchanged(formControlName: string, initialValues): boolean {
    let changedControls = [
      'name',
      'email',
      'domains',
      'phoneNumber',
      'website',
    ];
    if (formControlName === 'brokerage') {
      const controlNamesArray = Object.keys(initialValues);
      controlNamesArray.forEach((controlName) => {
        const initialValue = initialValues[controlName];
        const currentValue =
          this.form.controls[formControlName].get(controlName).value;
        if (JSON.stringify(currentValue) !== JSON.stringify(initialValue)) {
          if (controlName == 'logo') {
            this.logoFieldsUpdated = true;
          } else if (controlName == 'parent') {
            this.parentFieldsUpdated = true;
          } else if (changedControls.includes(controlName)) {
            this.otherFieldsUpdated = true;
          } else if (controlName == 'phoneNumber') {
            this.otherFieldsUpdated = true;
          }
        }
      });
    }

    return (
      JSON.stringify(this.form.get(formControlName).value) ===
      JSON.stringify(initialValues)
    );
  }

  private async setSectionDate() {
    this.createdBy = dateTimeWithUserName(
      this.brokerageSelected?.createdBy,
      this.brokerageSelected.createdDt,
      this.longDateTimeFormat,
    );

    if (
      this.brokerageSelected.updatedDt ||
      this.brokerageSelected?.location?.updatedDt ||
      this.brokerageRelationshipOldSelected?.updatedDt ||
      this.brokerageProfileOldSelected?.updatedDt
    ) {
      let latestUpdateDetails = await this.getLatestUpdateDetails();
      this.updatedBy = dateTimeWithUserName(
        latestUpdateDetails.name ?? '',
        latestUpdateDetails.date ?? '',
        this.longDateTimeFormat,
      );
    }
  }
  private async getLatestUpdateDetails() {
    let latestUpdatedBy = '';
    const brokerageDate = new Date(this.brokerageSelected?.updatedDt);
    const locationDate = new Date(this.brokerageSelected?.location?.updatedDt);
    const relationshipDate = new Date(
      this.brokerageRelationshipOldSelected?.updatedDt,
    );
    const profileDate = new Date(this.brokerageProfileOldSelected?.updatedDt);

    // Check if any of the dates are null or undefined.
    if (!brokerageDate && !locationDate && !profileDate && !relationshipDate) {
      return {
        name: '',
        date: '',
      };
    }
    let latest: Date | null =
      brokerageDate || locationDate || relationshipDate || profileDate;

    // Compare each date with the latest date if it's not null.
    if (brokerageDate >= latest) {
      latest = brokerageDate;
      latestUpdatedBy = this.brokerageSelected?.updatedBy;
    }
    if (locationDate >= latest) {
      latest = locationDate;
      latestUpdatedBy = this.brokerageSelected?.location?.updatedBy;
    }
    if (relationshipDate >= latest) {
      latest = relationshipDate;
      latestUpdatedBy = this.brokerageRelationshipOldSelected?.updatedBy;
    }
    if (profileDate >= latest) {
      latest = profileDate;
      latestUpdatedBy = this.brokerageProfileOldSelected?.updatedBy;
    }

    return {
      name: latestUpdatedBy,
      date: latest,
    };
  }

  get brokerage() {
    return this.form.get('brokerage') as FormGroup;
  }

  get location() {
    return this.form.get('location') as FormGroup;
  }

  brokerageCreatedAlertClose() {
    this.isNavigatedFromAddBrokerage = false;
  }

  showSuccessBanner() {
    return (
      this.spinnerCounter == 0 &&
      (this.brokerageUpdatedSuccess ||
        this.operationSuccessProfile ||
        this.operationSuccessRelationship ||
        this.operationSuccessLocations)
    );
  }

  getBannerMsg() {
    let msg = [];
    if (this.brokerageUpdatedSuccess) {
      msg.push('Brokerage');
    }
    if (this.operationSuccessProfile) {
      msg.push('Profile Logo');
    }
    if (this.operationSuccessRelationship) {
      msg.push('Relationship');
    }
    if (this.operationSuccessLocations) {
      msg.push('Location');
    }
    return `${msg.join(', ')} saved successfully!`;
  }

  onHandleCloseEvent() {
    this.hasError = false;
    this.operationSuccess = false;
    this.hasErrorBrokerage = false;
    this.brokerageUpdatedSuccess = false;
    this.operationSuccessRelationship = false;
    this.operationSuccessProfile = false;
    this.operationSuccessLocations = false;
    this.hasErrorRelationship = false;
    this.hasErrorLocation = false;
    this.notificationAlert.show = false;
  }

  handleBusinessSector(event: any) {}

  handleDeactivateBrokerage() {
    this.notificationAlert = { show: false };
    this.showBodySpinner = true;
    this.brokerageService
      .DeleteBrokerage(this.brokerageSelectedId)
      .subscribe({
        next: (response: any) => {
          this.notificationAlert = {
            show: true,
            type: 'success',
            headerText: 'success!',
            bodyText: 'Brokerage deactivated successfully',
          };
          this.brokerageSelected = { ...this.brokerageSelected, active: false };
          this.brokerageActive = false;
          const userDetails = response?.data;
          this.updatedBy = dateTimeWithUserName(
            userDetails.updatedBy,
            userDetails.updatedDt,
            this.longDateTimeFormat,
          );
        },
        error: (error) => {
          const errorMsg = getErrorMessage(error);
          if (![500].includes(error?.status)) {
            this.notificationAlert = {
              show: true,
              type: 'error',
              headerText: 'error!',
              bodyText: errorMsg,
            };
          }
        },
      })
      .add(() => {
        this.showBodySpinner = false;
        this.form[this.brokerageSelected.active ? 'enable' : 'disable']({
          emitEvent: false,
        });
        this.viewPortScroller.scrollToPosition([0, 0]);
      });
  }

  handleActivateBrokerage() {
    this.showBodySpinner = true;
    this.brokerageService
      .RestoreBrokerage(this.brokerageSelectedId)
      .subscribe({
        next: (response: any) => {
          this.brokerageSelected = { ...this.brokerageSelected, active: true };
          this.brokerageActive = true;
          this.notificationAlert = {
            show: true,
            type: 'success',
            headerText: 'success!',
            bodyText: 'Brokerage activated successfully',
          };
          this.reloadBranchTable = true;
          const userDetails = response?.data;
          this.updatedBy = dateTimeWithUserName(
            userDetails.updatedBy,
            userDetails.updatedDt,
            this.longDateTimeFormat,
          );
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.notificationAlert = {
              show: true,
              type: 'error',
              headerText: 'error!',
              bodyText: getErrorMessage(error?.error),
            };
          }
        },
      })
      .add(() => {
        this.showBodySpinner = false;
        this.viewPortScroller.scrollToPosition([0, 0]);
        this.form[this.brokerageSelected.active ? 'enable' : 'disable']({
          emitEvent: false,
        });
      });
  }

  handleSubmit(e: any) {
    this.unSubscriptionForms();
    this.isParentCompanyValid();
    if (!!!this.isBrokerageFormDirty && !!!this.isLocationFormDirty) {
      return;
    } else if (
      this.existingError === true &&
      this.isBrokerageFormDirty &&
      this.isLocationFormDirty
    ) {
      this.otherFieldsUpdated = true;
    }

    this.onHandleCloseEvent();
    this.operationSuccess = false;
    this.hasError = false;
    this.hasErrorBrokerage = false;
    this.hasErrorProfile = false;
    this.operationSuccessProfile = false;

    this.formSubmitted = true;
    this.spinnerCounter = 0;
    this.showSpinner = true;
    const mainWebsite = this.form.controls['brokerage']
      .get('website')
      .value.toLowerCase();
    if (mainWebsite) {
      if (!checkRegexPatternMainWebsite(mainWebsite)) {
        this.form.controls['brokerage']
          .get('website')
          .setErrors({ pattern: true });
        this.spinnerCounter = 0;
        this.showSpinner = false;
      }
    }
    const emailId = this.form.controls['brokerage']
      .get('email')
      .value.toLowerCase();
    if (!checkRegexPatternMainEmail(emailId)) {
      this.form.controls['brokerage'].get('email').setErrors({ pattern: true });
      this.spinnerCounter = 0;
      this.showSpinner = false;
    }
    const availableDomains = this.form.controls['brokerage']
      .get('domains')
      .value.toLowerCase();
    if (!checkRegexPatternMultidomain(availableDomains)) {
      this.form.controls['brokerage']
        .get('domains')
        .setErrors({ pattern: true });
      this.spinnerCounter = 0;
      this.showSpinner = false;
    }
    if (this.form.valid && this.form.enabled) {
      let brokerageForm = this.form.get('brokerage');
      if (brokerageForm.value.phoneNumber === '') {
        brokerageForm.value.phoneNumber = null;
      }

      const brokerageModel: IBrokerageModel = mapFormGroupToModel(this.form);

      if (this.otherFieldsUpdated == true || this.existingError == true) {
        this.spinnerCounter++;
        const brokerage = {
          name: brokerageModel.name,
          website: brokerageModel.website,
          domain: brokerageModel.domain,
          telephone: brokerageModel.telephone,
          email: brokerageModel.email,
        };
        this.brokerageService
          .UpdateBrokerage(this.brokerageSelected.id, brokerage)
          .subscribe({
            next: (response) => {
              this.itemsMenu[1] = { label: brokerage.name, path: null };
              this.brokerageSelected = {
                ...this.brokerageSelected,
                name: brokerage.name,
              };
              this.brokerageSelected.website = brokerage.website;
              this.brokerageUpdatedSuccess = true;
              this.viewPortScroller.scrollToPosition([0, 0]);
              const userDetails = response.data;
              this.updatedBy = dateTimeWithUserName(
                userDetails.updatedBy,
                userDetails.updatedDt,
                this.longDateTimeFormat,
              );
              this.otherFieldsUpdated = false;
              this.spinnerCounter--;
              if (this.spinnerCounter == 0) this.showSpinner = false;
              if (this.existingError === true) {
                this.existingError = false;
              }
              this.getBrokerage();
            },
            error: (error) => {
              this.handlerErrorBrokerage(error);
              this.otherFieldsUpdated = false;
              this.spinnerCounter--;
              if (this.spinnerCounter == 0) this.showSpinner = false;
            },
          });
      }

      const relationshipOldSelected =
        this.brokerageRelationshipOldSelected?.parentBroker.id || '';

      if (this.parentFieldsUpdated == true && !this.existingError) {
        if (brokerageModel.parentCompany || relationshipOldSelected) {
          if (
            !isNaN(brokerageModel.parentCompany) &&
            brokerageModel.parentCompany !== relationshipOldSelected
          ) {
            this.spinnerCounter++;
            const idRelationship = this.brokerageRelationshipOldSelected?.id;

            this.brokerageRelationshipService
              .update(
                idRelationship,
                brokerageModel.parentCompany,
                this.brokerageSelectedId,
              )
              .subscribe({
                next: (response) => {
                  const relationshipData = response?.data;
                  this.brokerageRelationshipOldSelected = {
                    ...relationshipData,
                  };
                  if (this.brokerageRelationshipOldSelected == null) {
                    this.brokerageRelationshipService
                      .getAllByFilter({
                        childBrokerId: this.brokerageSelectedId,
                      })
                      .subscribe({
                        next: (data) => {
                          const relationship =
                            data.data.length > 0 ? data.data[0] : null;
                          if (relationship) {
                            this.brokerageRelationshipOldSelected = {
                              ...relationship,
                            };
                            this.form
                              .get('brokerage.parent')
                              .setValue(
                                this.brokerageRelationshipOldSelected
                                  .parentBroker.id,
                              );
                            this.initForm();
                          }
                        },
                        error: (error) => {
                          if (![500].includes(error?.status)) {
                            this.hasError = true;
                          }
                          this.errorMessage = getErrorMessage(error?.error);
                        },
                      });
                  } else {
                    this.brokerageRelationshipOldSelected.parentBroker.id =
                      brokerageModel.parentCompany;
                    this.form
                      .get('brokerage.parent')
                      .setValue(
                        this.brokerageRelationshipOldSelected.parentBroker.id,
                      );
                    this.initForm();
                  }

                  if (relationshipData?.updatedBy) {
                    this.updatedBy = dateTimeWithUserName(
                      relationshipData?.updatedBy,
                      relationshipData?.updatedDt,
                      this.longDateTimeFormat,
                    );
                  }

                  this.hasErrorRelationship = false;
                  this.operationSuccessRelationship = true;
                  this.viewPortScroller.scrollToPosition([0, 0]);
                  this.parentFieldsUpdated = false;
                  this.spinnerCounter--;
                  if (this.spinnerCounter == 0) this.showSpinner = false;
                },
                error: (error) => {
                  if (![500].includes(error?.status)) {
                    this.hasErrorRelationship = true;
                  }
                  this.operationSuccessRelationship = false;
                  this.errorMessageRelationship = getErrorMessage(error?.error);
                  this.viewPortScroller.scrollToPosition([0, 0]);
                  this.parentFieldsUpdated = false;
                  this.spinnerCounter--;
                  if (this.spinnerCounter == 0) this.showSpinner = false;
                },
              });
          }
        }
      }

      if (
        this.logoFieldsUpdated == true &&
        brokerageModel.brokerageProfile.logo &&
        this.brokerageProfileOldSelected.logo !==
          brokerageModel.brokerageProfile.logo &&
        !this.existingError
      ) {
        this.spinnerCounter++;
        this.brokerageProfileService
          .UpdateLogo(this.brokerageProfileOldSelected.id, {
            logo: brokerageModel.brokerageProfile.logo,
          })
          .subscribe({
            next: (response) => {
              this.hasErrorProfile = false;
              this.operationSuccessProfile = true;
              this.viewPortScroller.scrollToPosition([0, 0]);
              const profileData = response?.data ?? {};
              if (profileData?.updatedBy) {
                this.updatedBy = dateTimeWithUserName(
                  profileData?.updatedBy,
                  profileData?.updatedDt,
                  this.longDateTimeFormat,
                );
              }
              this.getBrokerageProfile();
              this.form
                .get('brokerage.logo')
                .setValue(brokerageModel.brokerageProfile.logo);
              this.logoFieldsUpdated = false;
              this.spinnerCounter--;
              if (this.spinnerCounter == 0) this.showSpinner = false;
            },
            error: (error) => {
              this.operationSuccessProfile = false;
              if (![500].includes(error?.status)) {
                this.hasErrorProfile = true;
              }
              this.errorMessageProfile = getErrorMessage(error?.error);
              this.viewPortScroller.scrollToPosition([0, 0]);
              this.logoFieldsUpdated = false;
              this.spinnerCounter--;
              if (this.spinnerCounter == 0) this.showSpinner = false;
            },
          });
      }

      if (
        brokerageModel.location &&
        this.isLocationFormDirty &&
        !this.existingError
      ) {
        this.spinnerCounter++;
        brokerageModel.location.id = this.locationOldSelection.id;
        brokerageModel.location.regionId = this.locationRegionIdSelected;
        brokerageModel.location.addressLine2 =
          brokerageModel.location.addressLine2;
        this.locationService.updateLocation(brokerageModel.location).subscribe({
          next: (response) => {
            const locationDetails = response?.data;
            if (locationDetails?.updatedBy) {
              this.updatedBy = dateTimeWithUserName(
                locationDetails?.updatedBy,
                locationDetails?.updatedDt,
                this.longDateTimeFormat,
              );
            }
            this.operationSuccessLocations = true;
            this.hasErrorLocation = false;
            this.viewPortScroller.scrollToPosition([0, 0]);
            this.spinnerCounter--;
            if (this.spinnerCounter == 0) this.showSpinner = false;
            // this.getBrokerage();
          },
          error: (error) => {
            this.operationSuccessLocations = false;
            if (![500].includes(error?.status)) {
              this.hasErrorLocation = true;
            }
            this.errorMessageLocation = getErrorMessage(error?.error);
            this.viewPortScroller.scrollToPosition([0, 0]);
            this.spinnerCounter--;
            if (this.spinnerCounter == 0) this.showSpinner = false;
          },
        });
      }
      if (this.spinnerCounter == 0) {
        this.showSpinner = false;
      }
    } else {
      this.spinnerCounter = 0;
      this.showSpinner = false;
    }
  }

  regionIdSelected(idRegionSelected: number) {
    this.locationRegionIdSelected = idRegionSelected;
  }

  private handlerErrorBrokerage(error: any) {
    if (![500].includes(error?.status)) {
      this.hasErrorBrokerage = true;
      this.existingError = true;
    }
    this.errorMessageBrokerage = getErrorMessage(error);
    this.viewPortScroller.scrollToPosition([0, 0]);
  }

  private hasChangeDetails(): boolean {
    if (
      this.initialBrokerageFormValue.name !==
      this.form.get('brokerage.name').value
    ) {
      return true;
    }

    if (
      this.initialBrokerageFormValue.website !==
      this.form.get('brokerage.website').value
    ) {
      return true;
    }

    if (
      this.initialBrokerageFormValue.email !==
      this.form.get('brokerage.email').value
    ) {
      return true;
    }

    if (
      this.initialBrokerageFormValue.domain !==
      this.form.get('brokerage.domains').value
    ) {
      return true;
    }

    return (
      this.initialBrokerageFormValue.telephone !==
      this.form.get('brokerage.phoneNumber').value
    );
  }

  handleBrokerageCommissionModification(event) {
    const { commission, method } = event;

    (commission.brokerageId = Number(this.brokerageSelectedId)),
      (this.notificationAlert = { show: false });
    switch (method) {
      case 'create':
        // confirmation modal popup
        if (
          commission.riskRegionId == -1 &&
          this.brokerageCommissions.data?.length > 0
        ) {
          this.commissionConfirmationData = commission;
          this.showConfirmationModal = true;
        } else {
          commission.override = true;
          this.createBrokerageCommission(commission);
        }
        break;
      case 'update':
        delete commission.status;
        commission.type = 1;
        this.brokerageCommissionService
          .updateCommission(commission, commission.id)
          .subscribe({
            next: (_) => {
              this.notificationAlert = {
                show: true,
                type: 'success',
                headerText: 'success!',
                bodyText: 'Commission updated successfully!',
              };
              this.getAllCommission(
                this.brokerageCommissions.pagination.currentPage,
              );
            },
            error: (error) => {
              if (![500].includes(error?.status)) {
                this.notificationAlert = {
                  show: true,
                  type: 'error',
                  headerText: 'error!',
                  bodyText: getErrorMessage(error),
                };
              }
            },
          });
        break;
      case 'enable':
        this.brokerageCommissionService
          .restoreCommission(commission.id)
          .subscribe({
            next: (_) => {
              this.notificationAlert = {
                show: true,
                type: 'success',
                headerText: 'success!',
                bodyText: 'Commission enabled successfully!',
              };
              this.getAllCommission(
                this.brokerageCommissions.pagination.currentPage,
              );
            },
            error: (error) => {
              if (![500].includes(error?.status)) {
                this.notificationAlert = {
                  show: true,
                  type: 'error',
                  headerText: 'error!',
                  bodyText: getErrorMessage(error),
                };
              }
            },
          });
        break;
      case 'disable':
        this.brokerageCommissionService
          .deleteCommission(commission.id)
          .subscribe({
            next: (_) => {
              this.notificationAlert = {
                show: true,
                type: 'success',
                headerText: 'success!',
                bodyText: 'Commission disabled successfully!',
              };
              this.getAllCommission(
                this.brokerageCommissions.pagination.currentPage,
              );
            },
            error: (error) => {
              if (![500].includes(error?.status)) {
                this.notificationAlert = {
                  show: true,
                  type: 'error',
                  headerText: 'error!',
                  bodyText: getErrorMessage(error),
                };
              }
            },
          });
        break;

      default:
        console.error('No method found');
        break;
    }
  }

  handleCommissionOverride(commission: any) {
    this.createBrokerageCommission(commission);
  }

  createBrokerageCommission(commission: any) {
    this.brokerageCommissionService.createCommission(commission).subscribe({
      next: (_) => {
        this.closeModalConfirmation();
        this.notificationAlert = {
          show: true,
          type: 'success',
          headerText: 'success!',
          bodyText: 'Commission added successfully!',
        };
        this.resetAddCommissionFormSubject.next(true);
        this.getAllCommission();
      },
      error: (error) => {
        this.closeModalConfirmation();
        if (![500].includes(error?.status)) {
          this.notificationAlert = {
            show: true,
            type: 'error',
            headerText: 'error!',
            bodyText: getErrorMessage(error),
          };
        }
      },
    });
  }

  closeModalConfirmation() {
    this.showConfirmationModal = false;
    this.commissionConfirmationData = null;
  }

  handleCommissionPageChange(pageNumber: number) {
    this.getAllCommission(pageNumber);
  }

  private getBrokerageProfile() {
    this.brokerageProfileService.Get(this.brokerageSelectedId).subscribe({
      next: (response) => {
        this.brokerageProfileOldSelected = response.data[0];
        this.store.dispatch(new saveBrokerageProfileAction(response.data[0]));
        this.initForm();
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });
  }
  private getBrokerage() {
    this.brokerageService.GetBrokerage(this.brokerageSelectedId).subscribe({
      next: (response) => {
        this.brokerageSelected = response.data;
        this.brokerageActive = this.brokerageSelected.active;
        this.form[this.brokerageSelected.active ? 'enable' : 'disable']({
          emitEvent: false,
        });
        this.itemsMenu[1] = {
          label: this.brokerageSelected.name,
          path: null,
        };
        this.initForm();
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });
  }

  triggerProfilePicErrorToParent(msg: any) {
    if (msg) {
      this.notificationAlert = {
        show: true,
        bodyText: msg,
        type: 'error',
        headerText: 'error!',
      };
    } else {
      this.notificationAlert = {
        show: false,
        bodyText: '',
        headerText: '',
      };
    }
  }

  isParentCompanyValid() {
    let parentCompanyDetails: BrokerageParentCompany = {};
    this.store
      .select(getBrokerageCompanyDetails)
      .subscribe((parentCompany) => (parentCompanyDetails = parentCompany));
    if (
      parentCompanyDetails.searchText === '' ||
      parentCompanyDetails.searchText === parentCompanyDetails.name
    ) {
      this.parentCompanyInvalidErrMsg = '';
      return;
    }
    this.parentCompanyInvalidErrMsg = 'This field is invalid';
    this.form.controls['brokerage'].get('parent').setErrors({ pattern: true });
  }
  handleBackToQuote(event) {
    this.router.navigateByUrl(
      this.previousNavigatedUrl?.previousNavigation?.finalUrl.toString(),
      { skipLocationChange: true },
    );
  }
  isEnabledBackToQuoteLink() {
    if (this.isQuote4Flow && this.isNavigatedFromAlert) {
      this.showBackToQuoteButton = true;
    } else if (this.workflow3 && this.isNavigatedFromAlert) {
      this.showBackToQuoteButton = true;
    } else {
      this.showBackToQuoteButton = false;
    }
  }
}
