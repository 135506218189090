<div class="custom-modal" [formGroup]="form" *ngIf="show">
  <div class="modal-content">
    <div class="grid-container">
      <div class="grid-item col-1">
        <boxxds-datepicker
          [placeholder]="shortDateFormat"
          [dateFormat]="shortDateFormat"
          [label]="'filter.label.policyEffectiveFrom' | translate"
          [showErr]="effectiveHasError"
          [errMsg]="effectiveFrmErrMsg | translate"
          [containerStyle]="{ maxWidth: '186px' }"
          [bsValue]="form.value['effectiveFrm']"
          [isReadOnly]="disableDates"
          (bsValueChange)="handleEffectiveFrom($event)"
        >
        </boxxds-datepicker>
        <boxxds-datepicker
          [placeholder]="shortDateFormat"
          [dateFormat]="shortDateFormat"
          [label]="'filter.label.policyEffectiveTo' | translate"
          [showErr]="effectiveHasError"
          [errMsg]="effectiveToErrMsg | translate"
          [containerStyle]="{ maxWidth: '186px', marginTop: '12px' }"
          [bsValue]="form.value['effectiveTo']"
          [isReadOnly]="disableDates"
          (bsValueChange)="handleEffectiveTo($event)"
        >
        </boxxds-datepicker>
        <boxxds-datepicker
          [placeholder]="shortDateFormat"
          [dateFormat]="shortDateFormat"
          [label]="'filter.label.policyExpiryFrom' | translate"
          [showErr]="expiryHasError"
          [errMsg]="expiryFrmErrMsg | translate"
          [containerStyle]="{ maxWidth: '186px' }"
          [bsValue]="form.value['expiryFrm']"
          [isReadOnly]="disableDates"
          (bsValueChange)="handleExpiryFrom($event)"
        >
        </boxxds-datepicker>
        <boxxds-datepicker
          [placeholder]="shortDateFormat"
          [dateFormat]="shortDateFormat"
          [label]="'filter.label.poicyExpiryTo' | translate"
          [showErr]="expiryHasError"
          [errMsg]="expiryToErrMsg | translate"
          [containerStyle]="{ maxWidth: '186px', marginTop: '12px' }"
          [bsValue]="form.value['expiryTo']"
          [isReadOnly]="disableDates"
          (bsValueChange)="handleExpiryTo($event)"
        >
        </boxxds-datepicker>
        <div class="quote-period">
          <div class="d-flex">
            <input
              type="radio"
              formControlName="policyPeriod"
              value="boundToday"
              (change)="handleRadioCheck()"
              name="policyPeriod"
            />
            <label>{{ "filter.label.boundToday" | translate }}</label>
          </div>
          <div class="d-flex">
            <input
              type="radio"
              formControlName="policyPeriod"
              value="last7days"
              (change)="handleRadioCheck()"
              name="policyPeriod"
            />
            <label>{{ "filter.label.last7Days" | translate }}</label>
          </div>
          <div class="d-flex">
            <input
              type="radio"
              formControlName="policyPeriod"
              value="last30days"
              (change)="handleRadioCheck()"
              name="policyPeriod"
            />
            <label>{{ "filter.label.last30Days" | translate }}</label>
          </div>
        </div>
      </div>
      <div class="grid-item col-2">
        <app-min-max-range
          [form]="form"
          [min]="0"
          [max]="10000000"
          [step]="100000"
          [label]="'filter.label.limit' | translate"
          [customStyle]="{ marginBottom: '20px' }"
          [formSubmitted]="formSubmitted"
          minControl="minLimit"
          maxControl="maxLimit"
        ></app-min-max-range>
        <app-min-max-range
          [form]="form"
          [min]="0"
          [max]="1000000"
          [step]="100000"
          [label]="'common.deductible' | translate"
          [customStyle]="{ marginBottom: '20px' }"
          [formSubmitted]="formSubmitted"
          minControl="minDeductible"
          maxControl="maxDeductible"
        ></app-min-max-range>
        <app-min-max-range
          [form]="form"
          [min]="0"
          [max]="1000000"
          [step]="100000"
          [label]="'common.premium' | translate"
          [customStyle]="{ marginBottom: '20px' }"
          [formSubmitted]="formSubmitted"
          minControl="minPremium"
          maxControl="maxPremium"
        ></app-min-max-range>
        <boxxds-dropdown
          [form]="form"
          [control]="'status'"
          [label]="'filter.label.status' | translate"
          [placeholder]="'common.select' | translate"
          [options]="statusOptions"
          (currentValueChange)="handleStatusSelect($event)"
          [btnStyle]="{ height: '36px' }"
          [dropdownContainerStyle]="{ width: '300px', marginBottom: '16px' }"
          [hideSelectedValue]="true"
        >
        </boxxds-dropdown>
        <div class="status-container">
          <div
            class="status"
            *ngFor="let status of getStatuses(form.value['status'])"
          >
            <boxxds-status-badge
              [showClose]="true"
              [status]="status"
              [statusType]="getStatusType(status)"
              (closeHandler)="handleStatusRemove(status)"
              [badgeStyle]="{ textTransform: 'capitalize' }"
            ></boxxds-status-badge>
            <div></div>
          </div>
        </div>
      </div>
      <div class="grid-item col-3">
        <boxxds-multiselect-autocomplete
          [label]="'Brokerage, branch, producer'"
          (handleSelect)="handleBrokerageBranchProd($event)"
          [containerStyle]="{ marginBottom: '20px' }"
          [options]="brokerageBranchProdOptions"
          [selectedOptions]="form.value['brokerageBranchProd']"
          (searchEvent)="searchBrokerageDirectories($event)"
          (loadMore)="loadMoreBrokerage()"
          [validationMsg]="'common.filterLimitInfo' | translate"
        >
        </boxxds-multiselect-autocomplete>
        <boxxds-multiselect-autocomplete
          [label]="'Underwriter'"
          (handleSelect)="handleUnderwriter($event)"
          [containerStyle]="{ marginBottom: '20px' }"
          [selectedOptions]="form.value['underwriter']"
          [options]="underwritersOptions"
          (searchEvent)="searchUnderWriter($event)"
          (loadMore)="loadMoreUnderWriter()"
          [validationMsg]="'common.filterLimitInfo' | translate"
        >
        </boxxds-multiselect-autocomplete>
        <boxxds-dropdown
          [form]="form"
          [control]="'products'"
          [label]="'common.products' | translate"
          [placeholder]="'common.select' | translate"
          [options]="pdtOptions"
          (currentValueChange)="handleProductsSelect($event)"
          [btnStyle]="{ height: '36px' }"
          [dropdownContainerStyle]="{ width: '300px', marginBottom: '16px' }"
          [hideSelectedValue]="true"
        >
        </boxxds-dropdown>
        <div class="pdt-container">
          <div
            class="pdt"
            *ngFor="let pdt of getProducts(form.value['products'])"
          >
            <div class="pdt-name">{{ pdt }}</div>
            <button
              type="button"
              aria-label="close"
              class="close-btn"
              (click)="handleProductRemove(pdt)"
            >
              <img src="assets/img/dashboard/quote/remove.png" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-container">
      <button type="button" class="clear-btn" (click)="clearfilters()">
        Clear all filters
      </button>
      <boxxds-button
        [buttonText]="'Done'"
        btnType="secondary"
        [btnStyle]="{ width: '88px' }"
        type="submit"
        (handleClick)="handleFilter($event)"
      >
      </boxxds-button>
    </div>
  </div>
</div>
