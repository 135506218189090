import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from './base-services';
import { Region } from '../entities/region';
import { Observable, retry, catchError } from 'rxjs';
import { BoxxResponse } from '../entities/boxx-response';
import { Risk } from '../entities/risk';
import { RiskQuestion } from '../entities/risk-question';

@Injectable({
  providedIn: 'root',
})
export class RiskQuestionService extends BaseService<RiskQuestion> {
  constructor(http: HttpClient) {
    const baseurl =
      environment.msProductriskBaseUrl + environment.riskQuestionPathUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  GetByRegionId(
    regionId,
    languageId,
    insuredId,
    limit = 10,
    sort = 'seqOrderNum:asc',
  ): Observable<BoxxResponse<RiskQuestion>> {
    const url = `${this.baseurl}?riskRegionId=${regionId}&languageId=${languageId}&insuredId=${insuredId}&limit=${limit}&sort=${sort}`;
    return this.http
      .get<BoxxResponse<RiskQuestion>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetByRiskRegionIdAndCategory(
    riskRegionId,
    category,
    limit = 10,
    sort = 'seqOrderNum:asc',
  ) {
    const url = `${this.baseurl}?riskRegionId=${riskRegionId}&category=${category}&limit=${limit}&sort=${sort}`;
    return this.http
      .get<BoxxResponse<RiskQuestion>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
