import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Validators } from 'ngx-editor';
import { take } from 'rxjs';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { convertDate } from 'src/app/utils/formatDate';

@Component({
  selector: 'app-modal-submission-risk-analysis-questions',
  templateUrl: './modal-submission-risk-analysis-questions.component.html',
  styleUrls: ['./modal-submission-risk-analysis-questions.component.less'],
})
export class ModalSubmissionRiskAnalysisQuestionsComponent implements OnInit {
  @Input() showModal: boolean = false;
  @Input() existingQuoteOptionData;
  @Input() riskQuestions;
  @Input() category;

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleSave = new EventEmitter<any>();
  @Output() handleDiscardChanges = new EventEmitter<any>();

  longDateFormat = 'MMMM DD, YYYY';
  form: FormGroup;
  radioYesOrNoToAllForm: FormGroup;
  validateRiskAnswers = false;

  constructor(
    private store: Store,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    const formGroupConfig = {};
    this.riskQuestions.forEach((riskQuestion) => {
      formGroupConfig[riskQuestion.id] = ['', [Validators.required]];
    });
    this.form = this.fb.group(formGroupConfig);

    this.radioYesOrNoToAllForm = this.fb.group({
      yesToAll: [''],
    });

    this.store
      .select(getDashboardSelector)
      .pipe(take(1))
      .subscribe((data) => {
        this.longDateFormat = data.longDateFormat;
      });
  }

  isColumnLayout(explanation) {
    return explanation.split('<br>').length > 1;
  }
  getExplanationArr(explanation) {
    if (explanation.split('<br>').length > 1) {
      // <br> for grid styling
      return explanation.split('<br>');
    } else {
      // <li> for bullet point styling
      return explanation.split('<li>');
    }
  }

  handleRadioCheck() {
    this.radioYesOrNoToAllForm.controls['yesToAll'].setValue('');
  }

  checkYesOrNoToAll(event) {
    for (let question of this.riskQuestions) {
      if (this.form.contains(question.id)) {
        this.form.controls[`${question.id}`].setValue(event?.value);
      }
    }
  }

  handleSaveAndContinue() {
    this.validateRiskAnswers = true;
    if (!this.form.valid) {
      return;
    }
    const riskAnswerIds = Object.keys(this.form.value);
    const riskAnswers = riskAnswerIds.map((id) => ({
      riskQuestionId: id,
      riskAnswer: this.form.value[id],
    }));
    this.handleSave.emit(riskAnswers);
  }

  closeHandler() {
    this.handleClose.emit();
  }

  handleDiscard(event: any) {
    this.handleDiscardChanges.emit();
  }

  getFormattedDate(date: any, dateFormat: string) {
    return date ? convertDate(date, dateFormat) : '';
  }
}
