<div class="custom-modal" *ngIf="showModal">
  <div class="modal-content">
    <div class="close-btn">
      <button (click)="closeHandler()">
        <img src="assets/img/dashboard/card/close.png" />
        {{ "common.close" | translate }}
      </button>
    </div>
    <div class="content">
      <div class="d-flex flex-column align-items-center">
        <img
          src="assets/img/alert-triangle.jpg"
          width="66px"
          height="66px"
          alt="alert"
        />
        <boxxds-body
          sz="md"
          [text]="'submission.heading.riskAnalysisUpdate' | translate"
          [customStyle]="{
            color: '#FE802C',
            marginTop: '12px',
            marginBottom: '20px'
          }"
        ></boxxds-body>
      </div>
      <div class="modal-description">
        <boxxds-body
          sz="sm"
          [text]="'submission.subHeading.insuredDetailsChanged' | translate"
          [customStyle]="{
            color: '#000',
            width: '537px',
            textAlign: 'left',
            fontSize: '13px',
            margin: '0 auto'
          }"
        ></boxxds-body>
      </div>
      <div class="policy-details d-flex flex-column">
        <boxxds-body
          sz="md"
          [text]="existingQuoteOptionData.quoteNumber"
          [customStyle]="{ color: '#616162', marginTop: '20px' }"
        ></boxxds-body>
        <div class="d-flex mt-1">
          <boxxds-body
            sz="sm"
            [text]="'productForm.heading.effectiveDate:' | translate"
            [customStyle]="{
              color: '#616162',
              marginRight: '4px',
              lineHeight: '14px'
            }"
          ></boxxds-body>
          <boxxds-body
            sz="sm"
            [text]="
              getFormattedDate(
                existingQuoteOptionData.effectiveDate,
                longDateFormat
              )
            "
            [customStyle]="{ color: '#616162', lineHeight: '14px' }"
          ></boxxds-body>
        </div>
        <div class="d-flex mt-1">
          <boxxds-body
            sz="sm"
            [text]="'productForm.heading.insured:' | translate"
            [customStyle]="{
              color: '#616162',
              marginRight: '4px',
              lineHeight: '14px'
            }"
          ></boxxds-body>
          <boxxds-body
            sz="sm"
            [text]="existingQuoteOptionData.insured"
            [customStyle]="{ color: '#616162', lineHeight: '14px' }"
          ></boxxds-body>
        </div>
      </div>

      <div class="steps-holder">
        <div class="d-flex flex-column" *ngIf="currentStep === 1">
          <div class="d-flex">
            <boxxds-body
              sz="sz"
              [text]="('submission.subHeading.stepOne' | translate) + ':'"
              [customStyle]="{
                color: '#333334',
                marginBottom: '24px',
                fontWeight: '600'
              }"
            ></boxxds-body>
            <boxxds-body
              sz="sz"
              [text]="'submission.subHeading.sanctionReview' | translate"
              [customStyle]="{
                color: '#333334',
                marginBottom: '24px',
                marginLeft: '4px'
              }"
            ></boxxds-body>
          </div>
          <div class="sanctions-container">
            <boxxds-table
              [tblContainerStyle]="{ maxHeight: '750px', overflow: 'auto' }"
              [data]="data"
              [showPagination]="false"
              class="table"
              [isRowClickable]="false"
              id="restrictive-industry"
              [tableStyle]="{ marginBottom: '30px' }"
              [showEmptyCellIndicator]="true"
              [showPopOver]="true"
              [popOverColumn]="'Reasons'"
            >
            </boxxds-table>
          </div>
          <boxxds-button
            [buttonText]="'submission.button.declineQuote' | translate"
            [type]="'submitted'"
            btnType="secondary"
            [btnStyle]="{
              marginTop: '24px',
              marginBottom: '24px'
            }"
            [ngStyle]="{ alignSelf: 'flex-end' }"
            (handleClick)="handleDeclineQuote()"
          ></boxxds-button>
        </div>

        <div *ngIf="currentStep === 2">
          <div class="d-flex">
            <boxxds-body
              sz="sz"
              [text]="('submission.subHeading.stepTwo' | translate) + ':'"
              [customStyle]="{
                color: '#333334',
                marginBottom: '24px',
                fontWeight: '600'
              }"
            ></boxxds-body>
            <boxxds-body
              sz="sz"
              [text]="'submission.subHeading.answerRiskQuestions' | translate"
              [customStyle]="{
                color: '#333334',
                marginBottom: '24px',
                marginLeft: '4px'
              }"
            ></boxxds-body>
          </div>
          <div class="questions-holder">
            <div class="category-holder">
              <boxxds-body
                sz="md"
                [text]="category + '*'"
                [customStyle]="{
                  color: '#616162',
                  textTransform: 'capitalize',
                  fontSize: '18px',
                  fontFamily: 'canada-type-gibson, sans-serif'
                }"
                class="category-label"
              >
              </boxxds-body>
              <div *ngIf="riskQuestions.length > 1">
                <div class="radio-all-btn">
                  <boxxds-radio
                    [control]="'yesToAll'"
                    class="radio-all"
                    [items]="[
                      {
                        label: ('common.yesToAll' | translate),
                        currentValue:
                          radioYesOrNoToAllForm.value['yesToAll'] === 'true',
                        value: 'true'
                      },
                      {
                        label: ('common.noToAll' | translate),
                        currentValue:
                          radioYesOrNoToAllForm.value['yesToAll'] === 'false',
                        value: 'false'
                      }
                    ]"
                    [form]="radioYesOrNoToAllForm"
                    [formSubmitted]="false"
                    (handleChange)="checkYesOrNoToAll($event)"
                    [radioFill]="true"
                    [lblStyle]="{
                      color: '#56565C',
                      fontSize: '18px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '24px'
                    }"
                  ></boxxds-radio>
                </div>
              </div>
            </div>

            <div class="questions">
              <div
                class="question"
                *ngFor="let q of riskQuestions; let questnIdx = index"
              >
                <div
                  class="boolean-query"
                  *ngIf="q.dataType.description === 'Boolean'"
                >
                  <div class="boolean-question-container">
                    <div class="boolean-question">{{ q.question }}</div>
                    <div class="radio-btn">
                      <ng-container *ngIf="form">
                        <boxxds-radio
                          [control]="q.id"
                          [items]="[
                            {
                              label: ('common.yes' | translate),
                              currentValue: q.value,
                              value: 'true'
                            },
                            {
                              label: ('common.no' | translate),
                              currentValue: q.value,
                              value: 'false'
                            }
                          ]"
                          [form]="form"
                          [formSubmitted]="validateRiskAnswers"
                          [requiredErrMsg]="
                            'error.thisFieldIsRequired' | translate
                          "
                          (click)="handleRadioCheck()"
                          [radioFill]="true"
                        ></boxxds-radio>
                      </ng-container>
                    </div>
                  </div>
                  <div class="subQuestion" *ngIf="q.sub">
                    <ul>
                      <li *ngFor="let point of getExplanationArr(q.sub)">
                        {{ point }}
                      </li>
                    </ul>
                  </div>

                  <div class="explanation" *ngIf="q.explanation">
                    <ul
                      [ngClass]="{
                        'column-layout': true
                      }"
                    >
                      <li
                        *ngFor="let point of getExplanationArr(q.explanation)"
                      >
                        {{ point }}
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  class="boolean-query"
                  *ngIf="q.dataType.description === 'Numeric'"
                >
                  <div>{{ q.question }}</div>
                  <div class="explanation" *ngIf="q.explanation">
                    <ul
                      [ngClass]="{
                        'column-layout': q.explanation.length > 12
                      }"
                    >
                      <li *ngFor="let point of q.explanation">
                        {{ point }}
                      </li>
                    </ul>
                  </div>
                  <div class="radio-btn">
                    <input
                      class="no-stepper"
                      [id]="q.id"
                      [value]="q.value || 0"
                      type="number"
                      [required]="true"
                      autocomplete="off"
                      [formControlName]="q.id"
                      placeholder="Enter"
                      [ngStyle]="{
                        'border-color':
                          (validateRiskAnswers &&
                            this.form?.controls?.[q.id]?.errors?.[
                              'required'
                            ]) ||
                          (this.form?.controls?.[q.id]?.errors?.['pattern'] &&
                            !this.form?.controls?.[q.id]?.errors?.[
                              'required'
                            ]) ||
                          this.form?.controls?.[q.id]?.errors?.['required']
                            ? '#CA3434'
                            : null
                      }"
                    />
                  </div>
                  <div
                    *ngIf="
                      this.form?.controls?.[q.id]?.errors?.['required'] &&
                      validateRiskAnswers
                    "
                    class="error-msg"
                  >
                    {{ "error.thisFieldIsRequired" | translate }}
                  </div>
                  <div
                    *ngIf="
                      this.form?.controls?.[q.id]?.errors?.['pattern'] &&
                      !this.form?.controls?.[q.id]?.errors?.['required'] &&
                      validateRiskAnswers
                    "
                    class="error-msg"
                  >
                    {{ "productForm.error.numberRiskAnswer" | translate }}
                  </div>
                </div>
                <div
                  class="boolean-query"
                  *ngIf="q.dataType.description === 'String'"
                >
                  <div>{{ q.question }}</div>
                  <div class="explanation" *ngIf="q.explanation">
                    <ul
                      [ngClass]="{
                        'column-layout': q.explanation.length > 12
                      }"
                    >
                      <li *ngFor="let point of q.explanation">
                        {{ point }}
                      </li>
                    </ul>
                  </div>
                  <div class="radio-btn">
                    <input
                      type="text"
                      [id]="q.id"
                      [value]="q.value || ''"
                      [required]="true"
                      autocomplete="off"
                      [formControlName]="q.id"
                      placeholder="Enter"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-buttons">
        <boxxds-button
          *ngIf="currentStep === 1"
          [buttonText]="'submission.button.nextStep' | translate"
          [type]="'submitted'"
          btnType="primary"
          [btnStyle]="{
            width: '125px',
            padding: '0px 20px'
          }"
          (handleClick)="handleNext()"
        >
        </boxxds-button>
        <ng-container *ngIf="currentStep === 2">
          <a
            class="cancel-link"
            href="javascript:;"
            (click)="handleDiscard($event)"
          >
            {{ "common.discardChanges" | translate }}
          </a>
          <boxxds-button
            [buttonText]="'submission.button.declineQuote' | translate"
            [type]="'submitted'"
            btnType="secondary"
            [btnStyle]="{
              marginLeft: '20px'
            }"
            [ngStyle]="{ alignSelf: 'flex-end' }"
            (handleClick)="handleDeclineQuote()"
          ></boxxds-button>
          <boxxds-button
            [buttonText]="'common.saveAndContinue' | translate"
            [type]="'submitted'"
            btnType="primary"
            [btnStyle]="{
              width: '171px',
              padding: '0px 20px',
              marginLeft: '20px'
            }"
            (handleClick)="handleSaveAndContinue()"
          >
          </boxxds-button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
