import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ALLOWED_ENDORSEMENTS_COUNT } from 'src/app/constants/quote-constant';
import { ModalAlertService } from 'src/app/services/modal-alert.service';
import { RiskTemplateService } from 'src/app/services/risk-template';
import { getAlertHead } from 'src/app/utils/utils';

@Component({
  selector: 'app-modal-endorsements',
  templateUrl: './modal-endorsements.component.html',
  styleUrls: ['./modal-endorsements.component.less'],
})
export class ModalEndorsementsComponent implements OnInit {
  form: FormGroup;
  @Input() showModal: boolean = false;
  @Input() riskRegionId;
  @Input() product;
  @Input() selectedEndorsements;
  @Input() policyRiskIds;
  @Input() products;
  @Input() templateCodeForEndorsement;

  @Output() handleClose = new EventEmitter<any>();
  @Output() addHandler = new EventEmitter<any>();

  endorsementOptions = [];
  currentPage = 1;
  totalPages = 1;
  totalCount = 1;
  endorsementsSelectedToAdd = [];
  addBtnClicked = false;

  constructor(
    private fb: FormBuilder,
    private riskTemplateService: RiskTemplateService,
    private alertServiceModal: ModalAlertService,
  ) {}

  ngOnInit() {
    this.alertServiceModal.clearAllAlerts();
    this.form = this.fb.group({
      filterKeyWord: [''],
    });
    this.handleEndorsementSearch('', this.product);
  }

  handleModalClick(event) {
    event.stopPropagation();
  }

  handleBackDropClick() {
    this.alertServiceModal.clearAllAlerts();
    this.showModal = false;
    this.handleClose.emit();
  }

  async onSearch(event) {
    const keyword = event.target.value?.trim();
    if (keyword?.length >= 3 && keyword?.length <= 1000) {
      await this.handleEndorsementSearch(keyword, this.product, 1, false, 10);
    } else {
      this.handleEndorsementSearch('', this.product, 1, false, 10);
    }
  }

  handleEndorsementSearch(
    searchKey,
    product,
    page = 1,
    isDefaultLoad = true,
    limit = 10,
  ) {
    const index = this.products.findIndex((p) => p == product);
    this.riskTemplateService
      .getRiskTemplate(
        1,
        this.riskRegionId,
        this.templateCodeForEndorsement,
        searchKey,
      )
      .subscribe((data) => {
        this.currentPage = data.pagination.currentPage;
        this.totalPages = data.pagination.totalPages;
        this.totalCount = data.pagination.totalRecords;
        const newList = data.data.map((endorsementDoc) => {
          let selected = this.selectedEndorsements[product].find(
            (x) => x.riskTemplateId && x.riskTemplateId == endorsementDoc.id,
          )
            ? true
            : false;

          return {
            key: endorsementDoc.templateDescription,
            value: endorsementDoc.templateDescription,
            disabled: selected ? true : false,
            mandatory: endorsementDoc.mandatory,
            url: endorsementDoc.templatePath,
            templateType: endorsementDoc.templateType,
            policyRiskId: this.policyRiskIds[index],
            riskTemplateId: endorsementDoc.id,
            documentTblEndorsId: -1,
            dataInjection: endorsementDoc.dataInjection,
            ignoreTemplate: endorsementDoc.ignoreTemplate,
          };
        });

        if (isDefaultLoad && this.endorsementOptions[product]?.length > 0) {
          this.endorsementOptions[product] = [
            ...this.endorsementOptions[product],
            ...newList,
          ];
        } else {
          this.endorsementOptions[product] = newList;
        }
      });
  }

  clearValue() {
    this.form.reset();
    this.handleEndorsementSearch('', this.product, 1, false, 10);
  }

  getFilteredResult(keyName) {
    return keyName?.replace(
      this.form.value['filterKeyWord'],
      `<strong>${this.form.value['filterKeyWord']}</strong>`,
    );
  }

  handleEndorsementSelect(endorse, from: string = 'body') {
    this.alertServiceModal.clearAllAlerts();
    const alreadySelectedIdx = this.endorsementsSelectedToAdd.findIndex(
      (selectedEndorsement) => selectedEndorsement.key === endorse.key,
    );
    if (alreadySelectedIdx !== -1) {
      if (from === 'close')
        this.endorsementsSelectedToAdd.splice(alreadySelectedIdx, 1);
    } else {
      const currentCount = this.selectedEndorsements[this.product]?.length ?? 0;
      const newCount = this.endorsementsSelectedToAdd?.length ?? 0;
      if (currentCount + newCount < ALLOWED_ENDORSEMENTS_COUNT) {
        this.endorsementsSelectedToAdd.push({
          ...endorse,
          selectedToAdd: true,
        });
      } else {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText:
            'workFlow3.components.policyConfiguration.error.endorsementExceeded',
        };
        this.alertServiceModal.addAlert(alertData);
        return;
      }
    }
  }

  isSelected(endorse) {
    return this.endorsementsSelectedToAdd.filter(
      (selectedEndorsement) => selectedEndorsement.key === endorse.key,
    )[0]?.selectedToAdd;
  }

  onScroll(event: any): void {
    const currentScrollPosition = event.target.scrollTop;
    const maxScrollPosition =
      event.target.scrollHeight - event.target.clientHeight;

    if (this.canLoadMoreOptions(currentScrollPosition, maxScrollPosition)) {
      this.currentPage++;
      this.handleEndorsementSearch(
        this.form.value['filterKeyWord'],
        this.product,
        this.currentPage,
      );
    }
  }

  canLoadMoreOptions(
    currentScrollPosition: number,
    maxScrollPosition: number,
    threshold: number = 4,
  ): boolean {
    return (
      maxScrollPosition - currentScrollPosition <= threshold &&
      this.endorsementOptions[this.product].length <= this.totalCount &&
      this.currentPage <= this.totalPages
    );
  }

  addClickHandler() {
    this.addBtnClicked = true;
    this.alertServiceModal.clearAllAlerts();
    const index = this.products.findIndex((p) => p == this.product);
    const selectedEndorsements = this.endorsementsSelectedToAdd.map(
      ({ selectedToAdd, ...rest }) => rest,
    );
    const keyword = this.form.value['filterKeyWord']
      ? this.form.value['filterKeyWord'].trim()
      : '';
    if (keyword.length >= 3) {
      const customEndorsement = {
        key: keyword,
      };
      selectedEndorsements.push(customEndorsement);
    }
    const currentCount = this.selectedEndorsements[this.product]?.length ?? 0;
    const newCount = selectedEndorsements?.length ?? 0;
    if (currentCount + newCount <= ALLOWED_ENDORSEMENTS_COUNT) {
      this.addHandler.emit({
        endorsementsToAdd: selectedEndorsements,
        product: this.product,
      });
    } else {
      this.addBtnClicked = false;
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText:
          'workFlow3.components.policyConfiguration.error.endorsementExceeded',
      };
      this.alertServiceModal.addAlert(alertData);
      return;
    }
  }

  handleRemoveTypedEndorsement() {
    this.form.value['filterKeyWord'] = '';
    this.clearValue();
  }

  getEndorseCount(): number {
    const keyword = this.form.value['filterKeyWord']
      ? this.form.value['filterKeyWord'].trim()
      : '';
    if (keyword.length >= 3) {
      const alreadySelectedIdx = this.endorsementsSelectedToAdd.findIndex(
        (selectedEndorsement) => selectedEndorsement.key.trim() === keyword,
      );
      if (alreadySelectedIdx === -1) return 1;
      else return 0;
    } else return 0;
  }
}
