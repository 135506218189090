<div class="holder">
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!showBodySpinner">
    <boxxds-alert
      [type]="alert.type"
      [headerText]="alert.header | translate"
      [bodyText]="alert.body | translate"
      (handleCloseEvent)="handleAlertClear()"
      *ngIf="alert.show"
    ></boxxds-alert>
    <boxxds-breadcrumb
      (linkClicked)="breadcrumbClicked()"
      [items]="itemsMenu"
      [containerStyle]="{ marginBottom: '7px' }"
    ></boxxds-breadcrumb>
    <boxxds-heading
      sz="5"
      [customStyle]="{ marginBottom: '0' }"
      [text]="
        isEditMode ? insuredName : ('submission.heading.newInsured' | translate)
      "
    >
    </boxxds-heading>
    <boxxds-body
      *ngIf="!isEditMode"
      sz="md"
      [text]="'insured.label.insuredType' | translate"
      [customStyle]="{
        color: '#616162',
        textAlign: 'left',
        marginBottom: '8px',
        marginTop: '18px'
      }"
    ></boxxds-body>
    <span class="toggle" *ngIf="!isEditMode">
      <button
        class="toggle-menu"
        (click)="onInsuredTypeToggle(true, isInsuredTypeCompany)"
        [ngClass]="{ active: isInsuredTypeCompany }"
      >
        {{ "submission.button.company" | translate }}
      </button>
      <button
        class="toggle-menu"
        (click)="onInsuredTypeToggle(false, isInsuredTypeCompany)"
        [ngClass]="{ active: !isInsuredTypeCompany }"
      >
        {{ "submission.button.individual" | translate }}
      </button>
    </span>

    <app-company-insured-form
      *ngIf="isInsuredTypeCompany"
      [form]="addCompanyForm"
      [form$]="form$"
      [checkValidation]="formSubmitted"
      (handleContinue)="addOrUpdateCompanyInsured($event)"
      [insuredId]="insuredId"
      [fromSubmissionFlow]="this.fromSubmissionFlow"
      (updateBreadCrumbs)="updateBreadCrumbs($event)"
      (handleErrorOrSuccess)="handleErrorOrSuccess($event)"
    ></app-company-insured-form>
    <app-individual-insured-form
      *ngIf="!isInsuredTypeCompany"
      [checkValidation]="formSubmitted"
      [insuredId]="insuredId"
      [form]="addIndividualForm"
      (updateBreadCrumbs)="updateBreadCrumbs($event)"
      (handleErrorOrSuccess)="handleErrorOrSuccess($event)"
      (handleContinue)="addOrUpdateIndividualInsured($event)"
    ></app-individual-insured-form>
    <app-submission-recalculate-confirmation-popup
      *ngIf="showRecalcConfModal"
      [showModal]="showRecalcConfModal"
      [existingQuoteOptionData]="actionPopupDetails"
      (handleClose)="closeModalRecalculateConf()"
      (handleSubmissionRecalculate)="handleSubmissionRecalculate($event, null)"
      (handleDiscardRecalculate)="handleDiscardRecalculate($event)"
    >
    </app-submission-recalculate-confirmation-popup>

    <app-modal-submission-risk-analysis-questions
      *ngIf="showModalRiskQuestions"
      [riskQuestions]="riskQuestions"
      [showModal]="showModalRiskQuestions"
      [existingQuoteOptionData]="actionPopupDetails"
      (handleClose)="closeModal()"
      (handleSave)="handleSaveRiskAnalysis($event)"
      [category]="questionsCategory"
      (handleDiscardChanges)="handleDiscardRecalculate($event)"
    ></app-modal-submission-risk-analysis-questions>
    <app-modal-sanctions-risk-questions
      *ngIf="showSanctionsRiskQuestionModal"
      [riskQuestions]="riskQuestions"
      [showModal]="showSanctionsRiskQuestionModal"
      [existingQuoteOptionData]="actionPopupDetails"
      (handleClose)="closeModal()"
      (handleSave)="handleSaveRiskAnalysis($event)"
      [category]="questionsCategory"
      (handleDiscardChanges)="handleDiscardRecalculate($event)"
      [data]="sanctionPayload"
      [isRestrictedIndustry]="isRestrictedIndustry"
      (handleDecline)="handleDeclineAndSave()"
    ></app-modal-sanctions-risk-questions>
    <app-modal-sanction
      *ngIf="showSanctionModal"
      [showModal]="showSanctionModal"
      [existingQuoteOptionData]="actionPopupDetails"
      (handleClose)="closeSanctionPopup()"
      [data]="sanctionPayload"
      [isRestrictedIndustry]="isRestrictedIndustry"
      (handleProceed)="handleSanctionProceed()"
      (handleDiscard)="handleDiscardRecalculate($event)"
      (handleDeclineAndSaveChanges)="handleDeclineAndSave()"
    ></app-modal-sanction>
    <app-modal-insured-state-update
      *ngIf="showModalStateChange"
      [showModal]="showModalStateChange"
      [existingQuoteOptionData]="actionPopupDetails"
      (handleClose)="closeModal()"
      (handleDecline)="handleDeclineAndSave()"
      (handleDiscardChanges)="handleDiscardRecalculate($event)"
    >
    </app-modal-insured-state-update>
    <app-modal-submission-risk-analysis
      *ngIf="showModalRiskAnalysis"
      [showModal]="showModalRiskAnalysis"
      [existingQuoteOptionData]="actionPopupDetails"
      (handleClose)="closeModal()"
      (handleSave)="handleSaveRevenueChanges()"
      (handleDiscardChanges)="handleDiscardRecalculate($event)"
    >
    </app-modal-submission-risk-analysis>
  </ng-container>
</div>
