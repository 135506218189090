import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { BoxxResponse, Pagination } from 'src/app/entities/boxx-response';
import { Role } from 'src/app/entities/role';
import { Breadcrumb } from 'src/app/models/breadcrumb.model';
import { Quote } from 'src/app/models/quote.model';
import { DomainsService } from 'src/app/services/domains.service';
import { RoleService } from 'src/app/services/roles.service';
import { Domains } from 'src/app/models/domains';
import { arrayToObjet, getErrorMessage } from 'src/app/utils/utils';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { getUserTypes } from 'src/app/store/dashboard/dashboard.selector';

@Component({
  selector: 'app-new-role',
  templateUrl: './new-role.component.html',
  styleUrls: ['./new-role.component.less'],
})
export class NewRoleComponent implements OnInit {
  itemsMenu: Breadcrumb[] = [
    { label: 'Roles & permissions', path: '/dashboard/security/roles' },
    { label: 'New role', path: null },
  ];

  subNavData;
  options: Array<any> = [{ key: 'select', value: 'select' }];

  roleOptions: Array<any> = [{ key: 'Systems admin', value: 'Systems admin' }];
  docOptions: Array<any> = [
    { name: '0', key: 'permission 1' },
    { name: '1', key: 'permission 2' },
  ];
  form: FormGroup;
  form$: Observable<Quote>;
  searchKey;
  // requiredErrMsg: string = "This field is required";
  // invalidErrMsg: string = "This field is invalid";
  submitted: boolean = false;
  totalDataCount = 0;
  pagination: Observable<Pagination>;
  isSearchResult: boolean = false;

  systemUserTypes: Array<any> = [];

  roleForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    systemUserType: new FormControl('', [Validators.required]),
  });

  requiredErrMsg: string = 'This field is required';
  invalidErrMsg: string = 'This field is invalid';

  isSuccess = false;
  hasError = false;
  errorMessage = '';

  formSubmitted: boolean = false;

  manager = '';
  title = '';

  showSpinner: boolean = false;
  systemUserType = '';

  showBodySpinner: boolean = false;
  showErrorAlert = false;
  msgErrorAlert = '';
  userID: number;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activedRoute: ActivatedRoute,
    private roleService: RoleService,
    private domainsService: DomainsService,
    private localStorageService: LocalStorageService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.userID = this.localStorageService.getBoxxUserId();

    this.store.pipe(select(getUserTypes)).subscribe((data) => {
      const tableData = data.map((dataObj) => ({
        key: dataObj.description,
        value: dataObj.id,
        id: dataObj.id,
      }));
      if (tableData.length === 0) {
        this.fetchSystemUserTypes();
      }
      this.systemUserTypes = [...tableData];
    });
  }

  fetchSystemUserTypes() {
    this.showBodySpinner = true;
    this.domainsService.GetByDomainCode('SYSTEMUSERTYPE').subscribe({
      next: (response) => {
        this.showBodySpinner = false;
        this.setSystemUserTypesResult(response);
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });
  }

  setSystemUserTypesResult(data: BoxxResponse<Domains>) {
    const tableData = data.data?.map((dataObj) => ({
      key: `${dataObj.description}`,
      value: `${dataObj.id}`,
    }));
    this.systemUserTypes = [...tableData];
  }

  resetRoleForm() {
    this.roleForm.reset();
    this.systemUserType = '';
  }

  onSubmit(data) {
    this.formSubmitted = true;
    this.hasError = false;

    // check boxx user form is invalid
    if (this.roleForm.invalid) {
      return;
    }
    this.showSpinner = true;
    this.roleService.CreateRole(data).subscribe(
      (response) => {
        this.showSpinner = false;
        this.isSuccess = true;
        this.formSubmitted = false;
        this.resetRoleForm();
        const { data: role } = response;
        setTimeout(() => {
          this.router.navigate([`/dashboard/security/roles/${role.id}`], {
            skipLocationChange: true,
          });
        }, 2000);
      },
      ({ error }) => {
        this.showSpinner = false;
        this.hasError = true;
        this.errorMessage = getErrorMessage(error);
      },
    );
  }
  dropdownValueChanged(value) {
    const systemUserTypes = arrayToObjet(this.systemUserTypes, 'value');
    const systemUserType = systemUserTypes[value];
    this.systemUserType = systemUserType.key;
    this.roleForm.get('systemUserType').setValue(systemUserType.value);
  }
  pageChangeHandler(pageNumber) {}

  handleAttachSubjectives(event, index) {}

  getSearchKeyWords(keywordObj = {}) {}

  addRole(event) {}

  handleNoOfPIIRecords(event) {}
  handleCloseSuccessEvent() {
    this.isSuccess = false;
    this.hasError = false;
  }
  handleCloseSuccessEventExt() {
    this.showErrorAlert = false;
  }
}
