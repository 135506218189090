import { createFeatureSelector, createSelector } from '@ngrx/store';
import { stores } from '..';

export const selectLifecycleFeature = createFeatureSelector<stores>('stores');

export const getLifecycleState = createSelector(
  selectLifecycleFeature,
  (state: stores) => state.stateLifecycle,
);
export const getLifecycleSlideout = createSelector(
  selectLifecycleFeature,
  (state: stores) => state.stateLifecycle,
);
