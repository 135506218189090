import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, take } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { Pagination } from 'src/app/entities/boxx-response';
import { Insured } from 'src/app/models/insured.model';
import { InsuredService } from 'src/app/services/insured.service';
import { getInsuredSelector } from 'src/app/store/insured/insured.selector';
import * as InsuredAction from 'src/app/store/insured/insured.action';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { LocalStorageService } from 'src/app/services/localstorage-service';

@Component({
  selector: 'app-insured-directory',
  templateUrl: './insured-directory.component.html',
  styleUrls: ['./insured-directory.component.less'],
})
export class InsuredDirectoryComponent implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private insuredService: InsuredService,
    private router: Router,
    private translateService: TranslateService,
    private store: Store,
    private localStorageService: LocalStorageService,
    private activatedRoute: ActivatedRoute,
  ) {}

  form$: Observable<Insured>;
  isInsuredTypeCompany: boolean = true;
  private skipNgOnDestroy = false;
  form: FormGroup;
  companyColumnsToSort: Array<string> = ['Insured Name', 'Contact', 'Status'];
  activeCompanySearchPgNo: number = 1;
  showTblSpinner: boolean = false;
  tableData = [{ 'Insured Name': '', Contact: '', Address: '', Status: '' }];
  pagination: Pagination = {
    currentPage: 1,
    totalPages: 1,
    totalRecords: 0,
    hasNext: false,
    hasPrevious: false,
  };
  sortBy = 'createdDt:desc';
  sortedColumn = '';
  enteredOnSearch: boolean = false;
  searchResults = [];
  showSpinner = false;
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  quoteFlowSteps;

  async ngOnInit(): Promise<void> {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
    });

    this.form = this.fb.group({
      searchKey: ['', [Validators.pattern(/^.{3,}$/)]],
    });
    this.form$ = this.store.select(getInsuredSelector);
    this.form$.pipe(take(1)).subscribe(async (event) => {
      if (event.ui.isNavigatedFromInsuredIndividualForm) {
        this.isInsuredTypeCompany = false;
        await this.populateInsured({ sort: this.sortBy });
        this.onToggle(false, this.isInsuredTypeCompany);
      } else {
        await this.populateInsured({ sort: this.sortBy });
      }
    });
    this.quoteFlowSteps = this.localStorageService.getQuoteFlowSteps();
  }

  ngOnDestroy(): void {
    if (!this.skipNgOnDestroy) {
      this.setIsNavigatedFromInsuredInStore(false);
    }
  }

  handleAddNewInsured() {
    this.skipNgOnDestroy = true;
    if (this.quoteFlowSteps === '4') {
      this.router.navigate(['/dashboard/insured/form'], {
        skipLocationChange: true,
      });
      return;
    } else if (this.quoteFlowSteps === 'workflow3') {
      this.router.navigate(['/dashboard/workflow3/insured/form'], {
        skipLocationChange: true,
      });
      return;
    }
    this.router.navigate(['/dashboard/insured/form'], {
      skipLocationChange: true,
    });
  }

  setIsNavigatedFromInsuredInStore(value: boolean) {
    this.store.dispatch(
      new InsuredAction.setIsNavigatedFromInsuredIndividual({
        isNavigatedFromIndividual: value,
      }),
    );
  }

  onToggle(isCompany, isCompanyPrevSelected) {
    if (!isCompany) {
      this.setIsNavigatedFromInsuredInStore(true);
    } else {
      this.setIsNavigatedFromInsuredInStore(false);
    }
    if (isCompany == isCompanyPrevSelected) {
      return;
    }
    this.form.controls['searchKey'].setValue('');
    this.isInsuredTypeCompany = isCompany;
    if (this.sortedColumn !== '') {
      const sortBy = this.mapSortColumns(this.sortedColumn);
      this.sortBy = sortBy + ':asc,id:asc';
    }
    this.populateInsured({
      insuredName: this.form.value['searchKey'],
      sort: this.sortBy,
    });
  }

  handleSearch(event) {
    if (event.key == 'Enter') {
      this.enteredOnSearch = true;
      const searchName = event?.target?.value;
      if (searchName?.length >= 3 && searchName?.length <= 45) {
        this.populateInsured({ insuredName: searchName, sort: this.sortBy });
      } else if (searchName?.length === 0) {
        this.populateInsured({ insuredName: '', sort: this.sortBy });
      }
    } else {
      this.enteredOnSearch = false;
    }
  }

  handleInsuredRowClick(index) {
    const insuredId = this.searchResults[index]?.id;
    if (this.quoteFlowSteps === '4') {
      if (this.isInsuredTypeCompany) {
        this.router.navigate(['/dashboard/insured/form', insuredId], {
          skipLocationChange: true,
        });
      } else {
        this.router.navigate(['/dashboard/insured/form', insuredId, 0], {
          skipLocationChange: true,
        });
      }
      return;
    } else if (this.quoteFlowSteps === 'workflow3') {
      if (this.isInsuredTypeCompany) {
        this.router.navigate(['/dashboard/workflow3/insured/form', insuredId], {
          skipLocationChange: true,
        });
      } else {
        this.router.navigate(
          ['/dashboard/workflow3/insured/form', insuredId, 0],
          {
            skipLocationChange: true,
          },
        );
      }
      return;
    }
    if (this.isInsuredTypeCompany) {
      this.router.navigate(['/dashboard/insured/form', insuredId], {
        skipLocationChange: true,
      });
    } else {
      this.router.navigate(['/dashboard/insured/form', insuredId, 0], {
        skipLocationChange: true,
      });
    }
  }

  handleTablePageChange(page = 1) {
    const searchName = this.form.value['searchKey'];
    this.populateInsured({ insuredName: searchName, page, sort: this.sortBy });
  }

  mapSortColumns(key: string) {
    let sortColumn: string;
    switch (key) {
      case 'Insured Name':
        if (this.isInsuredTypeCompany) {
          sortColumn = 'companyName';
        } else {
          sortColumn = 'firstName';
        }
        break;
      case 'Contact':
        if (this.isInsuredTypeCompany) {
          sortColumn = 'companyWebsite';
        } else {
          sortColumn = 'email';
        }
        break;
      case 'Status':
        sortColumn = 'active';
        break;
      default:
        sortColumn = 'createdDt';
        break;
    }
    return sortColumn;
  }

  handleCompanyTableColSortUp(columnName) {
    this.sortedColumn = columnName;
    const sortBy = this.mapSortColumns(columnName);
    this.sortBy = sortBy + ':asc,id:asc';
    this.populateInsured({
      insuredName: this.form.value['searchKey'],
      page: this.pagination.currentPage,
      sort: this.sortBy,
    });
  }

  handleCompanyTableColSortDown(columnName) {
    this.sortedColumn = columnName;
    const sortBy = this.mapSortColumns(columnName);
    this.sortBy = sortBy + ':desc,id:asc';
    this.populateInsured({
      insuredName: this.form.value['searchKey'],
      page: this.pagination.currentPage,
      sort: this.sortBy,
    });
  }

  populateInsured({ insuredName = '', page = 1, limit = 10, sort = '' } = {}) {
    this.showTblSpinner = true;
    let insuredType = 1;
    if (this.isInsuredTypeCompany) {
      insuredType = 2;
    }
    this.insuredService
      .GetByInsuredNameAndType(
        encodeURIComponent(insuredName),
        insuredType,
        page,
        limit,
        sort,
      )
      .subscribe({
        next: (response) => {
          if (response?.data?.length > 0) {
            this.searchResults = response?.data;
            this.tableData = response?.data.map((insuredData) => {
              const insuredStatusTranslationKey =
                insuredData.active === true
                  ? 'common.status.active'
                  : 'common.status.inactive';
              if (this.isInsuredTypeCompany) {
                return {
                  'Insured Name': insuredData?.companyName ?? '',
                  Contact: insuredData?.companyWebsite ?? '',
                  Address: insuredData?.insuredLocation?.fullAddress ?? '',
                  Status: this.translateService.instant(
                    insuredStatusTranslationKey,
                  ),
                };
              } else {
                return {
                  'Insured Name':
                    (insuredData?.firstName ?? '') +
                    ' ' +
                    (insuredData?.lastName ?? ''),
                  Contact: insuredData?.email ?? '',
                  Address: insuredData?.insuredLocation?.fullAddress ?? '',
                  Status: this.translateService.instant(
                    insuredStatusTranslationKey,
                  ),
                };
              }
            });
            this.pagination = response?.pagination;
          } else {
            this.tableData = [
              { 'Insured Name': '', Contact: '', Address: '', Status: '' },
            ];
          }
          this.showTblSpinner = false;
        },
        error: (error) => {
          this.showTblSpinner = false;
        },
      });
  }
}
