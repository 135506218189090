<div [ngStyle]="containerStyle" class="container">
  <div class="form-group checkbox-wrapper" [ngStyle]="formGroupStyle">
    <label class="container">
      <p *ngIf="label" class="checkbox-label">{{ label }}</p>
      <input
        type="checkbox"
        [checked]="checked"
        #checkbox
        [disabled]="isDisabled"
        (change)="onCheckboxChange($event)"
      />
      <span class="checkmark" [ngClass]="{ disabled: isDisabled }"></span>
    </label>
  </div>
  <ng-container *ngIf="sublabel">
    <ng-container *ngIf="!isCombinedSubLabel">
      <label
        *ngFor="let sub of sublabel"
        class="sub-label"
        [ngStyle]="subLabelStyle"
      >
        {{ sub }}
      </label>
    </ng-container>
    <ng-container *ngIf="isCombinedSubLabel">
      <label class="sub-label" [ngStyle]="subLabelStyle">
        {{ sublabel.join("/") }}
      </label>
    </ng-container>
  </ng-container>
</div>
