import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'currencyFormat',
})
export class CurrencyFormatPipe extends CurrencyPipe implements PipeTransform {
  override transform(
    value: number | string,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string,
  ): string | null;
  override transform(
    value: null | undefined,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string,
  ): null;
  override transform(
    value: number | string | null | undefined,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string,
  ): string | null;
  override transform(
    value: any,
    currencyCode?: string,
    display?: string | boolean,
    digitsInfo?: string,
    locale?: string,
  ): string | null {
    if (!value) value = '0';
    const numericValue =
      typeof value === 'string'
        ? parseFloat(value.replace(/[^0-9.]/g, ''))
        : value;
    let result = super.transform(
      numericValue,
      currencyCode,
      display,
      digitsInfo,
      locale,
    );
    return result;
  }
}
