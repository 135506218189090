<div class="holder">
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!showBodySpinner">
    <div class="content-holder">
      <div class="title-holder">
        <div class="title-div d-flex align-items-center">
          <boxxds-heading
            [sz]="'5'"
            [text]="'workFlow3.commissions.heading.commissionRates' | translate"
            [customStyle]="{
              color: '#2D2D39',
              fontFamily: 'Gibson',
              fontSize: '18px',
              fontWeight: '600',
              lineHeight: '28px'
            }"
          >
          </boxxds-heading>
          <div class="total-records">
            {{ totalDataCount + " " + "Records" }}
          </div>
        </div>
      </div>
      <div class="tbl-container">
        <app-commission-table-workflow3
          [data]="tableData"
          id="commission-table"
          [showSpinner]="showTblSpinner"
          (pageChanged)="handleCommissionPageChange($event)"
          (sortUpClicked)="handleTableColSortUp($event)"
          (sortDownClicked)="handleTableColSortDown($event)"
          id="commission-table"
          [addSortTo]="addSortTo"
          [activePageNumber]="pageNumber"
          class="table"
          [totalCount]="totalDataCount"
          [showPagination]="true"
          [showToggle]="true"
          (commissionEnable)="handleEnableDisableAction($event)"
          (editHandler)="handleEditCommission($event)"
          [tblHeaders]="tableHeaders"
          [columnsToHide]="columnsToHide"
          [columnHeadsToHide]="columnHeadsToHide"
          [brokerageId]="brokerageId"
          [producerId]="producerId"
          [type]="type"
          [inlineEditProps]="editColumns"
          [isProducerActive]="isProducerActive"
          [isBrokerActive]="isBrokerActive"
          (submitBrokerCommission)="saveBrokerageCommission($event)"
          (updateBrokerCommission)="handleUpdateCommission($event)"
          (submitProducerCommission)="saveProducerCommission($event)"
          (updateProducerCommission)="handleUpdateCommission($event)"
        >
        </app-commission-table-workflow3>
      </div>
    </div>
    <app-modal-commission-change
      *ngIf="showCommissionchangePopup"
      [showModal]="showCommissionchangePopup"
      [existingQuoteOptionData]="existingQuoteOptionData"
      [quoteTableData]="quoteList"
      [totalCount]="totalCount"
      [commissionId]="commissionId"
      [action]="commissionAction"
      [commissionData]="commissionData.commission"
      (handleClose)="handleCloseCommissionPopup()"
      (handleSave)="handleSaveCommissionChangePopup($event)"
    >
    </app-modal-commission-change>
  </ng-container>
</div>
