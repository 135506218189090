import { createFeatureSelector, createSelector } from '@ngrx/store';
import { stores } from '..';

export const selectBrokerageFeature = createFeatureSelector<stores>('stores');

export const getBrokerageSelector = createSelector(
  selectBrokerageFeature,
  (state: stores) => state.stateBrokerage.brokerage,
);

export const getBrokerageCreatedSelector = createSelector(
  selectBrokerageFeature,
  (state: stores) => state.stateBrokerage.brokerageCreated,
);

export const getBranchCreatedSelector = createSelector(
  selectBrokerageFeature,
  (state: stores) => state.stateBrokerage.branchCreated,
);

export const getProducerCreatedSelector = createSelector(
  selectBrokerageFeature,
  (state: stores) => state.stateBrokerage.producerCreated,
);
