import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { BaseService } from './base-services';
import { BrokerageProducerTeam } from 'src/app/models/brokerageProducerTeam';
@Injectable({
  providedIn: 'root',
})
export class BrokerageProducerTeamsService extends BaseService<any> {
  constructor(http: HttpClient) {
    const baseurl =
      environment.msBrokerBaseUrl + environment.brokerageProducerTeamPathUrl;
    super(http, baseurl);
  }

  addProducerTeam(model: BrokerageProducerTeam): Observable<any> {
    const url = `${this.baseurl}`;
    return this.http.post(url, model);
  }

  updateProducerTeam(model: BrokerageProducerTeam): Observable<any> {
    const url = `${this.baseurl}`;
    return this.http
      .put(url, model)
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
