<section (mouseleave)="hideTooltip()">
  <div
    class="info-tooltip"
    *ngIf="
      tooltipShow &&
      (tooltipObject.tooltipHeaderTxt ||
        tooltipObject.tooltipContentTxt ||
        tooltipObject.tooltipContentList.length > 0)
    "
    [class.tooltip-top]="isTopPosition"
    [class.tooltip-bottom]="!isTopPosition"
    [ngStyle]="{ top: position.top, left: position.left }"
    [ngClass]="tooltipObject.hiddenHeadTxt ? 'reduce-gap' : ''"
  >
    <div class="tooltip-head" *ngIf="!tooltipObject.hiddenHead">
      <span class="head-txt" *ngIf="!tooltipObject.hiddenHeadTxt">{{
        tooltipObject.tooltipHeaderTxt
      }}</span>
    </div>
    <div class="tooltip-content">
      <ng-container [ngSwitch]="tooltipObject.type">
        <ng-container *ngSwitchCase="'list'">
          <ul>
            <li *ngFor="let single of tooltipObject.tooltipContentList">
              <span *ngIf="single">{{ single }}</span>
            </li>
          </ul>
        </ng-container>
        <ng-container *ngSwitchDefault
          ><span *ngIf="tooltipObject.tooltipContentTxt">{{
            tooltipObject.tooltipContentTxt
          }}</span>
        </ng-container>
      </ng-container>
    </div>
  </div>
</section>
