import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from './base-services';
import { IndustryClass } from '../entities/industry-class';
import { BoxxResponse } from '../entities/boxx-response';
import { Observable, catchError, retry } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IndustryClassService extends BaseService<IndustryClass> {
  constructor(http: HttpClient) {
    const baseurl =
      environment.msInsuredBaseUrl + environment.industryClassPathUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  GetIndustryClassByName(
    name = '',
    page = 1,
    limit = 10,
    sort = '',
  ): Observable<BoxxResponse<IndustryClass>> {
    const url =
      this.baseurl +
      '?name=' +
      name +
      '&page=' +
      page +
      '&limit=' +
      limit +
      '&sort=' +
      sort;
    return this.http
      .get<BoxxResponse<IndustryClass>>(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
