import { createFeatureSelector, createSelector } from '@ngrx/store';
import { stores } from '..';

export const selectBrokerageProfileFeature =
  createFeatureSelector<stores>('stores');

export const getBrokerageProfileSelector = createSelector(
  selectBrokerageProfileFeature,
  (state: stores) => state.stateBrokerageProfile.brokerageProfile,
);

export const getLogoBrokerageProfileSelector = createSelector(
  selectBrokerageProfileFeature,
  (state: stores) => state.stateBrokerageProfile.brokerageProfile.logo,
);

export const getBrokerageCompanyDetails = createSelector(
  selectBrokerageProfileFeature,
  (state: stores) => state.stateBrokerageProfile.brokerageCompany,
);
