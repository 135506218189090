<div class="reset-container">
  <div class="reset-card">
    <div class="reset-info-panel">
      <a class="back-btn" href="/login/signin" tabindex="0">
        <img src="assets/img/arrow-left.png" />
        <p>Back</p>
      </a>
      <div class="reset-info-content">
        <div class="reset-header">
          <boxxds-heading
            [sz]="'6'"
            [text]="'Reset Password'"
            type="bold"
          ></boxxds-heading>
          <boxxds-body
            [text]="'Enter the email associated with your account to verfiy.'"
          ></boxxds-body>
        </div>

        <form [formGroup]="resetForm" (ngSubmit)="handleSubmit($event)">
          <boxxds-textinput
            [form]="resetForm"
            [placeholder]="'Enter your email'"
            [label]="'Email'"
            [control]="'email'"
            [id]="'email'"
            [containerStyle]="{ marginBottom: '8px', marginTop: '16px' }"
          >
          </boxxds-textinput>
          <div class="button-container">
            <boxxds-button
              [buttonText]="'Send email'"
              [type]="'submit'"
              [isDisabled]="!resetForm.value.email"
            ></boxxds-button>
          </div>
        </form>
      </div>
    </div>
    <div class="reset-logo-panel">
      <div class="logo">
        <img src="assets/img/boxx.png" alt="boxx logo" />
      </div>
    </div>
  </div>
</div>
