<div class="container">
  <div class="circle"></div>
  <div class="circle"></div>
  <div class="circle"></div>
  <div class="circle"></div>
  <div class="circle"></div>
  <div class="circle"></div>
  <div class="center-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
        fill="#FFF8E9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.098 7.38967L9.93797 14.2997L8.03797 12.2697C7.68797 11.9397 7.13797 11.9197 6.73797 12.1997C6.34797 12.4897 6.23797 12.9997 6.47797 13.4097L8.72797 17.0697C8.94797 17.4097 9.32797 17.6197 9.75797 17.6197C10.168 17.6197 10.558 17.4097 10.778 17.0697C11.138 16.5997 18.008 8.40967 18.008 8.40967C18.908 7.48967 17.818 6.67967 17.098 7.37967V7.38967Z"
        fill="#E09600"
      />
    </svg>
    <p>{{ message }}</p>
  </div>
</div>
