<div class="holder">
  <div class="alert-holder" *ngIf="showBanner">
    <boxxds-alert
      type="error"
      [headerText]="'common.errorMsg' | translate"
      [bodyText]="alertMessage | translate"
      [containerStyle]="{
        width: 'calc(100vw - 320px)',
        maxWidth: '1115px',
        marginBottom: '2'
      }"
      (handleCloseEvent)="handleAlertClose()"
    ></boxxds-alert>
  </div>
  <boxxds-heading
    sz="5"
    [customStyle]="{ marginBottom: '12px' }"
    [text]="'New submission' | translate"
    ><span></span>
  </boxxds-heading>
  <div class="insured-name">{{ (form$ | async)?.ui.insuredName }}</div>

  <div class="brokerage-selection" *ngIf="form$ | async as formDetails">
    <div class="brokerage-selection-title">
      <boxxds-body
        text="Select your information below"
        sz="md"
        [customStyle]="{ color: '#616162', marginBottom: '8px' }"
      >
      </boxxds-body>
      <boxxds-body
        sz="xs"
        [text]="'*' + ('REQUIRED' | translate)"
        [customStyle]="{ color: '#8F8F91' }"
      ></boxxds-body>
    </div>
    <div
      class="fields-container"
      [formGroup]="brokerageForm"
      [appConnectForm]="form$ | async"
    >
      <boxxds-searchable-dropdown
        [form]="brokerageForm"
        [control]="'broker'"
        [label]="('common.brokerage' | translate) + '*'"
        [placeholder]="'productSelection.placeholder.brokerage' | translate"
        [options]="brokerageOptions"
        [dispatchTextInputChange]="true"
        (textInputChangeEventEmmiter)="handleBrokerageSearch($event)"
        [invalidErrMsg]="submitted ? (invalidSearch | translate) : ''"
        (currentValueChange)="
          this.permissionList[this.currentScreen] &&
            this.currentQuoteStatus !== 'quote-closed' &&
            brokerageValueChanged($event)
        "
        [currentValue]="getValue('broker')"
        [requiredErrMsg]="requiredErrMsg | translate"
        [formSubmitted]="submitted"
        [btnStyle]="{ height: '36px' }"
        [hideSelectedValue]="false"
        [dropdownContainerStyle]="{ width: '250px' }"
        [isDisabled]="
          (form$ | async)?.ui?.isNavigatedFromQuoteCalc ||
          !this.permissionList[this.currentScreen] ||
          this.currentQuoteStatus === 'quote-closed'
        "
        [class.noPointerEvents]="
          (form$ | async)?.ui?.isNavigatedFromQuoteCalc ||
          !this.permissionList[this.currentScreen] ||
          this.currentQuoteStatus === 'quote-closed'
        "
      >
      </boxxds-searchable-dropdown>
      <boxxds-dropdown
        [form]="brokerageForm"
        [label]="('common.branch' | translate) + '*'"
        [placeholder]="'placeholder.select' | translate"
        [options]="branchOptions"
        [control]="'branch'"
        [currentValue]="getValue('branch')"
        [requiredErrMsg]="requiredErrMsg | translate"
        [formSubmitted]="submitted"
        (currentValueChange)="
          this.permissionList[this.currentScreen] &&
            this.currentQuoteStatus !== 'quote-closed' &&
            branchValueChanged($event)
        "
        [dropdownContainerStyle]="{ width: '250px' }"
        [disabled]="(form$ | async)?.ui?.isNavigatedFromQuoteCalc"
        (loadMoreOptions)="loadMoreBranches($event)"
        [hasScrollablePagination]="true"
        [totalCount]="branchTotalCount"
        [totalPages]="branchTotalPages"
        [isReadonly]="
          !getValue('broker') ||
          !this.permissionList[this.currentScreen] ||
          this.currentQuoteStatus === 'quote-closed'
        "
        [class.noPointerEvents]="
          !getValue('broker') ||
          !this.permissionList[this.currentScreen] ||
          this.currentQuoteStatus === 'quote-closed'
        "
      >
      </boxxds-dropdown>
      <boxxds-dropdown
        [form]="brokerageForm"
        [label]="('common.producer' | translate) + '*'"
        [placeholder]="'placeholder.select' | translate"
        [options]="producerOptions"
        [control]="'producer'"
        [currentValue]="getValue('producer')"
        [requiredErrMsg]="requiredErrMsg | translate"
        [formSubmitted]="submitted"
        (currentValueChange)="
          this.permissionList[this.currentScreen] &&
            this.currentQuoteStatus !== 'quote-closed' &&
            producerValueChanged($event)
        "
        [dropdownContainerStyle]="{ width: '250px' }"
        [disabled]="(form$ | async)?.ui?.isNavigatedFromQuoteCalc"
        (loadMoreOptions)="loadMoreProducers($event)"
        [hasScrollablePagination]="true"
        [totalCount]="producerTotalCount"
        [totalPages]="producerTotalPages"
        [isReadonly]="
          !getValue('branch') ||
          !this.permissionList[this.currentScreen] ||
          this.currentQuoteStatus === 'quote-closed'
        "
        [class.noPointerEvents]="
          !getValue('branch') ||
          !this.permissionList[this.currentScreen] ||
          this.currentQuoteStatus === 'quote-closed'
        "
      >
      </boxxds-dropdown>
    </div>
  </div>

  <div class="product-header">{{ "chooseProducts" | translate }}</div>
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!showBodySpinner">
    <ng-container *ngIf="(form$ | async)?.products as pdts">
      <div
        class="select-all"
        *ngIf="
          pdts.length > 1 &&
          this.permissionList[this.currentScreen] &&
          this.currentQuoteStatus !== 'quote-closed'
        "
      >
        <div class="input-container">
          <div class="checkmark" [ngClass]="{ show: allselect.checked }"></div>
          <input
            #allselect
            type="checkbox"
            [checked]="isSelectAllChecked(pdts)"
            class="select-all-input"
            (change)="onSelectAllCheck(allselect.checked)"
          />
        </div>
        <span
          [ngClass]="{ checked: allselect.checked }"
          [ngStyle]="{ color: '#616162' }"
          >{{ "Select all" | translate }}</span
        >
      </div>
    </ng-container>
    <ng-container
      *ngFor="
        let option of getStandaloneSellablePdts((form$ | async)?.products);
        let i = index
      "
    >
      <div class="checkbox" [ngClass]="{ inactive: !option.active }">
        <div class="content">
          <div class="input-container">
            <div
              class="checkmark"
              [ngClass]="{
                show: checkbox.checked || option.checked,
                'read-only-check':
                  !this.permissionList[this.currentScreen] ||
                  this.currentQuoteStatus === 'quote-closed'
              }"
            ></div>
            <input
              #checkbox
              type="checkbox"
              [disabled]="
                !option.active ||
                !this.permissionList[this.currentScreen] ||
                this.currentQuoteStatus === 'quote-closed'
              "
              [value]="option.value"
              [checked]="option.checked"
              (change)="
                this.permissionList[this.currentScreen] &&
                  this.currentQuoteStatus !== 'quote-closed' &&
                  onCheckboxChange($event, i)
              "
              [id]="'checkbox-' + i"
            />
          </div>
          <label
            [for]="'checkbox-' + i"
            class="product-name"
            [ngClass]="{ checked: checkbox.checked }"
            >{{ option.key }}</label
          >
          <div [innerHTML]="getSanitizedHtmlContent(option.description)"></div>
        </div>
      </div>
      <div class="inactive-reason" *ngIf="!option.active">
        {{ option.inactiveReason }}
      </div>
    </ng-container>
  </ng-container>
  <div class="continue-btn-holder">
    <boxxds-button
      [buttonText]="'Back' | translate"
      btnType="secondary"
      [btnStyle]="{ width: '88px' }"
      type="submit"
      (handleClick)="
        this.permissionList[this.currentScreen] &&
          this.currentQuoteStatus !== 'quote-closed' &&
          handleBackBtn()
      "
      class="back-btn"
      [isDisabled]="
        (form$ | async)?.policyId !== null ||
        !this.permissionList[this.currentScreen] ||
        this.currentQuoteStatus === 'quote-closed'
      "
      [class.noPointerEvents]="
        (form$ | async)?.policyId !== null ||
        !this.permissionList[this.currentScreen] ||
        this.currentQuoteStatus === 'quote-closed'
      "
    >
    </boxxds-button>
    <div class="save-continue">
      <boxxds-button
        [isDisabled]="!isProductSelected((form$ | async)?.products)"
        [buttonText]="'submission.continueToStep3Of7' | translate"
        btnType="secondary-solid"
        (handleClick)="handleContinue($event)"
        [btnStyle]="{ width: '199px' }"
      >
      </boxxds-button>
    </div>
  </div>
</div>
