import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BoxxResponse } from '../entities/boxx-response';
import { Policy, PolicyQuoteCount } from '../entities/policy';
import { BaseService } from './base-services';

@Injectable({
  providedIn: 'root',
})
export class PolicyStatsService extends BaseService<PolicyQuoteCount> {
  constructor(http: HttpClient) {
    const baseurl =
      environment.msPolicyBaseUrl + environment.dashboardStatsPathUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  override Get(): Observable<BoxxResponse<PolicyQuoteCount>> {
    return this.http
      .get<BoxxResponse<Policy>>(this.baseurl)
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
