import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BoxxResponse } from '../entities/boxx-response';
import { Policy } from '../entities/policy';
import { BaseService } from './base-services';

@Injectable({
  providedIn: 'root',
})
export class NAICSService extends BaseService<any> {
  constructor(http: HttpClient) {
    const baseurl = environment.msInsuredBaseUrl + environment.naics;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  // POST
  createNaics(data): Observable<any> {
    return this.http.post<any>(this.baseurl, data, this.httpOptions);
  }

  GetByInsuredId(
    insuredId,
    page = 1,
    limit = 10,
  ): Observable<BoxxResponse<any>> {
    const url =
      this.baseurl +
      `?insuredId=${insuredId}` +
      '&active=' +
      0 +
      '&page=' +
      page +
      '&limit=' +
      limit;
    return this.http.get<BoxxResponse<any>>(url);
  }
}
