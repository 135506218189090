// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { commonEnv } from './environment.common';

const env: Partial<typeof commonEnv> = {
  production: false,
  baseUrl: 'https://cadev-api-piboxx.boxxinsurance.com',
  //productrisk-ms
  msProductriskBaseUrl:
    'https://cadev-api-piboxx.boxxinsurance.com/ms-productrisk/api',
  //security-ms
  msSecurityBseUrl:
    'https://cadev-api-piboxx.boxxinsurance.com/ms-security/api/v1',
  //insured-ms
  msInsuredBaseUrl:
    'https://cadev-api-piboxx.boxxinsurance.com/ms-insured/api/v1',

  //quotepolicy-ms
  msPolicyBaseUrl:
    'https://cadev-api-piboxx.boxxinsurance.com/ms-quotepolicy/api/v1',

  //broker-ms
  msBrokerBaseUrl:
    'https://cadev-api-piboxx.boxxinsurance.com/ms-broker/api/v1',

  //rating-ms
  msRatingBaseUrl:
    'https://cadev-api-piboxx.boxxinsurance.com/ms-rating/api/v1',

  //configuration-ms
  msConfigurationBaseUrl:
    'https://cadev-api-piboxx.boxxinsurance.com/ms-configuration/api',
  // cognito config
  cognitoBaseUrl: 'https://boxx-user-dev.auth.ca-central-1.amazoncognito.com',
  cognitoRedirectUrl:
    'https://cadev-piboxx.boxxinsurance.com/portal-uwcore-ui/cognito',
  cognitoBypassRedirectUrl:
    'https://cadev-piboxx.boxxinsurance.com/portal-uwcore-ui/cognito?bypassMaintenance=true',
  cognito4FlowRedirectUrl:
    'https://cadev-piboxx.boxxinsurance.com/portal-uwcore-ui/cognito4flow',
  cognito7FlowRedirectUrl:
    'https://cadev-piboxx.boxxinsurance.com/portal-uwcore-ui/cognito7flow',
  workFlow3RedirectUrl:
    'https://cadev-piboxx.boxxinsurance.com/portal-uwcore-ui/workflow3',
  loginUrl:
    'https://boxx-user-dev.auth.ca-central-1.amazoncognito.com/oauth2/authorize?client_id=1cjo7jjd9oiebk36u6dguvk87q&response_type=code&scope=email+openid+phone+profile&redirect_uri=https://cadev-piboxx.boxxinsurance.com/portal-uwcore-ui/cognito',
  cognito4LoginUrl:
    'https://boxx-user-dev.auth.ca-central-1.amazoncognito.com/oauth2/authorize?client_id=1cjo7jjd9oiebk36u6dguvk87q&response_type=code&scope=email+openid+phone+profile&redirect_uri=https://cadev-piboxx.boxxinsurance.com/portal-uwcore-ui/cognito4flow',
  cognito7LoginUrl:
    'https://boxx-user-dev.auth.ca-central-1.amazoncognito.com/oauth2/authorize?client_id=1cjo7jjd9oiebk36u6dguvk87q&response_type=code&scope=email+openid+phone+profile&redirect_uri=https://cadev-piboxx.boxxinsurance.com/portal-uwcore-ui/cognito7flow',
  workflow3LoginUrl:
    'https://boxx-user-dev.auth.ca-central-1.amazoncognito.com/oauth2/authorize?client_id=1cjo7jjd9oiebk36u6dguvk87q&response_type=code&scope=email+openid+phone+profile&redirect_uri=https://cadev-piboxx.boxxinsurance.com/portal-uwcore-ui/workflow3',
  logoutUrl:
    'https://boxx-user-dev.auth.ca-central-1.amazoncognito.com/logout?client_id=1cjo7jjd9oiebk36u6dguvk87q&logout_uri=https://cadev-piboxx.boxxinsurance.com/portal-uwcore-ui/logout',
};
export const environment = Object.assign(commonEnv, env);
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
