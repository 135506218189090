import { Injectable } from '@angular/core';

declare var mouseflow: any;

@Injectable({
  providedIn: 'root',
})
export class MouseflowService {
  constructor() {}

  init() {
    if (typeof window !== 'undefined' && window?._mfq) {
      // Add Mouseflow events or configurations here if needed
      if (typeof mouseflow !== 'undefined') {
        console.log('Mouseflow variable');
        // mouseflow.mask("input[type='text']");
        console.log('inside Mouseflow init');
      }
      console.log('Mouseflow initialized');
    }
  }

  trackPageView(url: string) {
    if (typeof window !== 'undefined' && window?._mfq) {
      window._mfq.push(['newPageView', url]);
    }
  }

  maskElement(selector: string) {
    const element = document.querySelector(selector);
    if (element) {
      element.classList.add('mf-hide'); // Mouseflow hides elements with this class
    } else {
      console.warn(`Element not found for selector: ${selector}`);
    }
  }

  mask(selector: string) {
    const elements = document.querySelectorAll(selector);
    elements.forEach((element) => element.classList.add('mf-hide'));
  }

  exclude(selector: string) {
    const elements = document.querySelectorAll(selector);
    elements.forEach((element) => element.classList.add('mf-exclude'));
  }
}
