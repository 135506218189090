<div class="restricted-warning" [ngStyle]="containerStyle">
  <div class="alert-icon">
    <div class="alert-content">
      <div class="icon">
        <div class="outer-most-circle">
          <div class="outer-circle">
            <img src="assets/img/alert-triangle.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="warning-message">
    <div class="warning-heading">
      {{ "workFlow3.components.restrictedIndustryWarning.heading" | translate }}
    </div>
    <div class="warning-sub-heading" *ngIf="isUASubmission">
      {{
        "workFlow3.components.restrictedIndustryWarning.subHeading" | translate
      }}
    </div>
  </div>
</div>
