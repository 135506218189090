import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as objects from '../../policy-lifecycle-v2/lifecycle-v2-object';

@Component({
  selector: 'app-lifecycle-information',
  templateUrl: './lifecycle-information.component.html',
  styleUrls: ['./lifecycle-information.component.less'],
})
export class LifecycleInformationComponent implements OnInit {
  @Input() details!: objects.PolicyDetail;
  @Input() hasWritePermission: boolean = false;

  @Output() changeProducer = new EventEmitter();

  openInfo: boolean = false;
  isQuote: boolean = false;
  constructor() {}

  ngOnInit(): void {
    this.isQuote = !isNaN(+this.details.id);
  }

  openSub() {
    this.openInfo = !this.openInfo;
  }

  handleProducerChange() {
    this.changeProducer.emit();
  }

  getNavigationUrl(insuredId) {
    return `/dashboard/workflow3/insured/form/${insuredId}`;
  }
}
