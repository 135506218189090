export enum SYSTEM_USER_TYPES {
  BoxxUser = 'BOXX User',
  BoxxUsers = 'BOXX Users',
  Broker = 'Broker',
  Brokers = 'Brokers',
  Producers = 'Producers',
  Producer = 'Producer',
  Insured = 'Insured',
  ServiceProvider = 'serviceProvider',
}

export enum SYSTEM_USER_TYPES_SCREENS {
  'SCREEN19' = 'SCREENUWP', // boxx user
  'SCREEN20' = 'SCREENBRP', // producer
  'SCREEN21' = 'NOSCREEN', // insured
}

export enum MENU_SCREENS {
  'Dashboard' = 'Dashboard', //TO DO : need to do
  'BoxxUser' = 'Boxx User',
  'Role_Permissions' = 'Role & Permissions',
  'Brokerage_Directory' = 'Brokerage Directory',
  'SystemUser' = 'System User',
  'Quote' = 'Quote',
  'Policy' = 'Policy',
  'Domains' = 'Domains',
  'Language' = 'Language',
  'Claims' = 'Claims',
  'Insured' = 'Insured',
  'Industry_Class' = 'Industry Class',
  'Risk' = 'Risk',
  'Risk_Region' = 'Risk Region',
  'Plan' = 'Plan',
  'Rating' = 'Rating',
  'Currency' = 'Currency',
}

export const screensAndAssociatedRoutes = {
  '/dashboard/home': MENU_SCREENS.Dashboard,
  '/dashboard/brokerage': MENU_SCREENS.Brokerage_Directory,
  '/dashboard/branch/': MENU_SCREENS.Brokerage_Directory,
  '/dashboard/boxx-user-directory': MENU_SCREENS.BoxxUser,
  '/dashboard/system-user-directory': MENU_SCREENS.SystemUser,
  '/dashboard/security/user/': MENU_SCREENS.SystemUser,
  '/dashboard/security/roles': MENU_SCREENS.Role_Permissions,
  '/dashboard/security/permissions': MENU_SCREENS.Role_Permissions,
  '/dashboard/insured/directory': MENU_SCREENS.Insured,
  '/dashboard/insured/form': MENU_SCREENS.Insured,
  '/dashboard/quotes': MENU_SCREENS.Quote,
  '/dashboard/quote/': MENU_SCREENS.Quote,
  '/dashboard/quote4flow/': MENU_SCREENS.Quote,
  '/dashboard/workflow3/': MENU_SCREENS.Quote,
  'dashboard/workflow3/quotes': MENU_SCREENS.Quote,
  '/dashboard/policies': MENU_SCREENS.Policy,
  '/dashboard/policy/': MENU_SCREENS.Policy,
  '/dashboard/workflow3/brokerage': MENU_SCREENS.Brokerage_Directory,
};

export const alertHeaders = {
  error: 'common.errorMsg',
  warning: 'common.warnMsg',
  info: 'common.infoMsg',
  success: 'common.successMsg',
};

export enum USER_ROLES {
  Underwriter = 'Underwriter',
}

export enum USER_ROLES_NAME {
  UnderwriterAssistant = 'underwriter assistant',
  UnderwriterAssistantShort = 'ua',
  TechinicalAssistant = 'technical assistant',
  TechinicalAssistantShort = 'ta',
}
