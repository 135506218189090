<div class="custom-modal" *ngIf="showModal">
  <div class="modal-content">
    <div class="box">
      <span style="--i: 1"></span>
      <span style="--i: 2"></span>
      <span style="--i: 3"></span>
      <span style="--i: 4"></span>
      <span style="--i: 5"></span>
      <span style="--i: 6"></span>
      <span style="--i: 7"></span>
      <span style="--i: 8"></span>
      <span style="--i: 9"></span>
      <span style="--i: 10"></span>
    </div>
    <div class="header">
      <div class="icon">
        <img src="assets/img/mail-v2.svg" />
      </div>
      <div class="title">
        <boxxds-heading
          [sz]="'7'"
          [text]="popupHeading"
          type="bold"
        ></boxxds-heading>
      </div>
      <div class="close">
        <button (click)="closeHandler()">
          <img src="assets/img/close-v2.svg" />
        </button>
      </div>
    </div>
    <div style="position: relative">
      <app-modal-alert></app-modal-alert>
    </div>
    <div class="info-banner">
      <div class="info-image">
        <div class="image-section">
          <img
            src="assets/img/workflow3/select-template.svg"
            style="margin-left: 40px"
          />
          <div class="line"></div>
          <img
            src="assets/img/workflow3/send-package.svg"
            [ngStyle]="{ opacity: showModal ? '0.4' : '1' }"
            style="margin-right: 35px"
          />
        </div>

        <div class="label-section">
          <span>{{
            "workFlow3.emailPopupV2.label.selectTemplate" | translate
          }}</span>
          <span [ngStyle]="{ opacity: showModal ? '0.4' : '1' }">
            {{ "workFlow3.emailPopupV2.label.sendPackageImage" | translate }}
          </span>
        </div>
      </div>
    </div>
    <div class="loader" *ngIf="isLoader">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
    <div class="content-holder" *ngIf="!isLoader">
      <boxxds-body
        sz="md"
        [text]="'workFlow3.emailPopupV2.label.documentType' | translate"
        [customStyle]="{
          color: '#333334',
          fontFamily: 'Gibson',
          fontSize: '18px',
          fontWeight: '400',
          textShadow: '1px 1px #EAECF0'
        }"
      ></boxxds-body>
      <div
        class="doc-template-selection-container"
        [ngStyle]="docTempContainerStyle"
      >
        <div class="content">
          <div class="body-content">
            <div class="row-container">
              <div class="left-content">
                <div class="left-content-label">
                  <boxxds-body
                    sz="sm"
                    [text]="
                      'workFlow3.emailPopupV2.label.availDocumentType'
                        | translate
                    "
                    [customStyle]="{
                      color: '#333334',
                      fontFamily: 'Gibson',
                      fontSize: '12px',
                      fontWeight: '500'
                    }"
                  ></boxxds-body>
                </div>
                <div class="doc-template-section" *ngIf="docOptions">
                  <div class="doc-section" id="listGroup">
                    <ul class="doc-group doc-group-flush">
                      <li
                        class="doc-group-item"
                        [ngClass]="{ isActive: isSelected[docItem.value] }"
                        *ngFor="let docItem of docOptions; let i = index"
                        (click)="handleDocumentClick(docItem.value)"
                        [attr.id]="'menu-item-' + docItem.value"
                      >
                        <span
                          class="selected-dot"
                          *ngIf="isSelected[docItem.value]"
                          ><img src="assets/img/Dot_selected.svg"
                        /></span>
                        <span class="doc-key" [title]="docItem.key">
                          {{ docItem.key }}</span
                        ><span class="arrow ms-auto">
                          <img
                            class="arrow-right"
                            [ngClass]="{ active: isSelected[docItem.value] }"
                            [src]="
                              isSelected[docItem.value]
                                ? 'assets/img/chevron-right-active.svg'
                                : 'assets/img/chevron-right.svg'
                            "
                          />
                        </span>

                        <div
                          [attr.id]="'submenu-' + docItem.value"
                          *ngIf="tempOptions"
                        >
                          <ul
                            class="temp-group submenu"
                            *ngIf="isSubMenuOpen(docItem.value)"
                            [ngClass]="submenuDirection[docItem.value]"
                            [ngStyle]="{
                              left: submenuDirectionLeft[docItem.value]
                            }"
                            [attr.id]="'submenu-' + docItem.value"
                          >
                            <li
                              class="temp-group-item"
                              [ngClass]="{
                                isTempActive: isTempSelected[tempItem.value]
                              }"
                              *ngFor="let tempItem of tempOptions"
                              (click)="
                                handleTemplateSelection(
                                  tempItem.value,
                                  docItem.value,
                                  $event
                                )
                              "
                            >
                              <span
                                class="selected-dot"
                                *ngIf="isTempSelected[tempItem.value]"
                                ><img src="assets/img/Dot_selected.svg"
                              /></span>
                              <span class="temp-key" [title]="tempItem.key">{{
                                tempItem.key
                              }}</span>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="right-content">
                <div class="right-content-label">
                  <boxxds-body
                    sz="sm"
                    [text]="
                      'workFlow3.emailPopupV2.label.selectedType' | translate
                    "
                    [customStyle]="{
                      color: '#333334',
                      fontFamily: 'Gibson',
                      fontSize: '12px',
                      fontWeight: '500'
                    }"
                  ></boxxds-body>
                </div>
                <div
                  class="doc-template-preview-section"
                  *ngIf="docTempOptions.length > 0"
                >
                  <div class="doc-template-preview-list">
                    <li
                      class="preview-list-group-item"
                      *ngFor="let pdt of docTempOptions; let i = index"
                    >
                      <div class="pdt">
                        <div
                          class="pdt-name"
                          [title]="
                            (pdt.doc ? pdt.doc : '') +
                            (pdt.doc && pdt.template ? ' / ' : '') +
                            (pdt.template ? pdt.template : '')
                          "
                        >
                          {{ pdt.doc ? pdt.doc : "" }}
                          {{ pdt.template ? "/ " + pdt.template : "" }}
                        </div>
                        <!-- <button
                          *ngIf="pdt.docTempProcessId"
                          type="button"
                          aria-label="close"
                          class="close-btn"
                          (click)="
                            handleDocTempRemove(pdt.doc, pdt.template, i)
                          "
                        >
                          <img src="assets/img/workflow3/badge_close.svg" />
                        </button> -->
                      </div>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-holder">
      <boxxds-button-v2
        [isDisabled]="false"
        [buttonText]="'workFlow3.emailPopupV2.button.next' | translate"
        [type]="'submitted'"
        [btnClass]="'primary'"
        [btnStyle]="{ width: '187px', height: '44px' }"
        (handleClick)="handleNext()"
      >
      </boxxds-button-v2>
    </div>
  </div>
</div>
