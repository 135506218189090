import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs';
import { getQuoteSelector } from 'src/app/store/create-quote/create-quote.selector';
import { getCurrencySelector } from 'src/app/store/dashboard/dashboard.selector';

@Component({
  selector: 'app-info-banner-v2',
  templateUrl: './info-banner-v2.component.html',
  styleUrls: ['./info-banner-v2.component.less'],
})
export class InfoBannerV2Component implements OnInit {
  @Input() infoTitle: string;
  @Input() insuredInfo: Array<any> = [];
  @Input() brokerageInfo: Array<any> = [];
  @Input() showInfoBanner: boolean = false;
  @Input() notesInfo: Array<any> = [];
  @Input() quoteId;
  @Input() transactionType;
  @Input() hasNotifications: boolean = false;

  @Output() handleNotesAction = new EventEmitter();

  showExpansion: boolean = false;
  currency = '';
  showModalNotesPopup: boolean = false;
  policyPeriodId;
  insuredNavigationUrl = '';

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.quoteId = this.quoteId ?? '-';
    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));
    let form = this.store.pipe(select(getQuoteSelector));
    form.pipe(take(1)).subscribe((event) => {
      const uiData = event.ui;
      this.policyPeriodId = uiData.policyPeriodId;
    });
  }

  getNavigationUrl(insuredId) {
    return `/dashboard/workflow3/insured/form/${insuredId}`;
  }

  expandInfoBanner() {
    this.showExpansion = !this.showExpansion;
  }

  handleNotesPopup(e) {
    this.showModalNotesPopup = !this.showModalNotesPopup;
    e.stopPropagation();
  }
  handleNotesPopupClose($event) {
    this.showModalNotesPopup = false;
  }
}
