import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-mfa',
  templateUrl: './mfa.component.html',
  styleUrls: ['./mfa.component.less'],
})
export class MfaComponent implements OnInit {
  constructor(private fb: FormBuilder) {}
  form: FormGroup;

  ngOnInit(): void {
    this.form = this.fb.group({
      digit1: [''],
      digit2: [''],
      digit3: [''],
      digit4: [''],
      digit5: [''],
      digit6: [''],
    });
  }
  isBtnDisabled() {
    return !(
      this.form.value.digit1 &&
      this.form.value.digit2 &&
      this.form.value.digit3 &&
      this.form.value.digit4 &&
      this.form.value.digit5 &&
      this.form.value.digit6
    );
  }

  onDigitInput(event) {
    let element;
    if (event.code !== 'Backspace')
      element = event.srcElement.nextElementSibling;

    if (event.code === 'Backspace')
      element = event.srcElement.previousElementSibling;

    if (element == null) return;
    else element.focus();
  }

  handleSubmit(e: Event) {}
}
