import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ProducerModel } from 'src/app/models/producer.model';
import { regexHelpers } from 'src/app/utils/utils';
import moment from 'moment';
import { getFormatDate } from 'src/app/utils/formatDate';

export const mapFormGroupToModel = (
  form: FormGroup,
  customCommissions: boolean,
  croppedImage: string,
  branchId: number,
): ProducerModel => {
  const {
    firstName,
    lastName,
    email,
    phone,
    photo,
    licenseNumber,
    licenseExpiry,
  } = form.value;

  const producerModel: ProducerModel = {
    firstName,
    lastName,
    email,
    telephone: phone,
    customCommission: Number(customCommissions),
    brokerageBranchId: Number(branchId),
    licenseNumber,
    licenseExpiry: licenseExpiry
      ? getFormatDate(licenseExpiry, 'MM-DD-YYYY', 'YYYY-MM-DD')
      : null,
  };

  return producerModel;
};

export const getFormGroup = (fb: FormBuilder): FormGroup => {
  const formBuilder = fb.group({
    firstName: [
      '',
      [Validators.required, Validators.pattern(regexHelpers.AlphaNumericSpace)],
    ],
    lastName: [
      '',
      [Validators.required, Validators.pattern(regexHelpers.AlphaNumericSpace)],
    ],
    email: [
      '',
      [
        Validators.required,
        Validators.email,
        Validators.pattern(regexHelpers.MailId),
      ],
    ],
    phone: [
      '',
      [Validators.required, Validators.pattern(regexHelpers.PhoneNumber)],
    ],
    customCommission: [''],
    licenseNumber: [
      '',
      [Validators.pattern(regexHelpers.AlphaNumeric), noLicenseNumber()],
    ],
    licenseExpiry: ['', [dateValidator()]],
  });
  return formBuilder;
};
export function noLicenseNumber(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let expiry = control.parent?.get('licenseExpiry').value;
    let licenseNumber = control.value;
    if (expiry) {
      if (licenseNumber && licenseNumber != '') {
        return null;
      } else {
        return { customError: true };
      }
    } else {
      return null;
    }
  };
}
export function dateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let date = control.value ? new Date(control.value) : null;
    let today = moment();
    let minDate = moment().subtract(12, 'months');
    let maxDate = moment().add(5, 'years');
    if (moment(date).isBefore(minDate)) {
      return { date: 'brokerage.producer.error.minExpiryDate' };
    } else if (moment(date).isAfter(maxDate)) {
      return { date: 'brokerage.producer.error.maxExpiryDate' };
    } else {
      return null;
    }
  };
}
