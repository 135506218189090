<div class="insured-directory-page">
  <app-navigation-v2
    [showHeaderBanner]="true"
    [mainHeading]="''"
    [subHeading]="''"
    [isNeeded]="false"
    [showHeader]="true"
    [items]="itemsMenu"
    (suggestedSearchSelected)="handleGlobalSearch($event)"
  >
  </app-navigation-v2>
  <section class="title-section">
    <div class="max-width-wrapper">
      <div class="title-description-container">
        <div class="title-container">
          <boxxds-heading
            class="title"
            [sz]="'6'"
            [text]="
              (brokerageProducerOldSelected?.firstName ?? '') +
              ' ' +
              (brokerageProducerOldSelected?.lastName ?? '')
            "
            type="bold"
          ></boxxds-heading>
          <div
            class="status-badge"
            [ngClass]="
              getBadgeClass(producerSelected?.active ? 'Active' : 'Inactive')
            "
          >
            <span class="status-circle"></span>
            <p>
              {{ producerSelected?.active ? "Active" : "Inactive" }}
            </p>
          </div>
        </div>
        <boxxds-body
          class="brokerage-name"
          [text]="
            (brokerageData?.name ?? '') +
            '/' +
            (brokerageBranchData?.name ?? '')
          "
          [customStyle]="{
            fontFamily: 'Gibson',
            color: '#475467'
          }"
        >
        </boxxds-body>
        <boxxds-body
          class="contacts"
          [text]="
            (brokerageProducerOldSelected?.telephone ?? '') +
            '/' +
            (brokerageProducerOldSelected?.email ?? '')
          "
          [customStyle]="{
            fontFamily: 'Inter',
            color: '#667085',
            fontSize: '10px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '16px'
          }"
        >
        </boxxds-body>
      </div>
      <div class="button-container">
        <boxxds-button-v2
          *ngIf="uiData.isNavigatedFromChangeProducerPopup"
          [buttonText]="'common.backToQlc' | translate"
          [type]="'submit'"
          [size]="'md'"
          [btnClass]="'secondary gray'"
          [btnType]="'default'"
          [isDropdown]="false"
          (handleClick)="
            !showTblSpinner &&
              !contentLoader &&
              uiData.isNavigatedFromChangeProducerPopup &&
              handleBackToQlc()
          "
        ></boxxds-button-v2>
        <boxxds-button-v2
          [buttonText]="
            (producerSelected?.active
              ? 'common.deactivate'
              : 'common.reactivate'
            ) | translate
          "
          [type]="'submit'"
          [isDisabled]="showTblSpinner || contentLoader || showBodySpinner"
          [size]="'md'"
          [btnClass]="'secondary gray'"
          [btnType]="'default'"
          [isDropdown]="false"
          (handleClick)="
            !showTblSpinner &&
              !contentLoader &&
              !showBodySpinner &&
              handleProducerChangeStatus()
          "
        ></boxxds-button-v2>
        <boxxds-button-v2
          *ngIf="activeCategoryFilter !== 'Commission rates'"
          [buttonText]="'Add commission rate' | translate"
          [type]="'submit'"
          [isDisabled]="
            showTblSpinner || contentLoader || !producerSelected?.active
          "
          [size]="'md'"
          [btnClass]="'primary'"
          [btnType]="'default'"
          [isDropdown]="false"
          (handleClick)="
            !showTblSpinner &&
              !contentLoader &&
              handleCategoryFilter('Commission rates')
          "
        ></boxxds-button-v2>
      </div>
    </div>
  </section>
  <section class="table-with-filters">
    <div class="max-width-wrapper">
      <div class="filter-section">
        <div class="category-filter">
          <ul>
            <ng-container *ngFor="let category of categoryFilters">
              <li
                (click)="
                  activeCategoryFilter !== category &&
                    !showTblSpinner &&
                    !contentLoader &&
                    handleCategoryFilter(category)
                "
                [class.active]="activeCategoryFilter === category"
                [class.noPointerEvents]="
                  activeCategoryFilter === category ||
                  showTblSpinner ||
                  contentLoader
                "
              >
                {{ category }}
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>

    <!-- Loader -->
    <div class="max-width-wrapper" *ngIf="contentLoader">
      <div class="content-loader-wrapper body-spinner">
        <boxxds-spinner
          [containerStyle]="{
            width: '112px',
            height: '112px',
            '--b': '15px'
          }"
        ></boxxds-spinner>
      </div>
    </div>
    <!-- Loader -->

    <div class="max-width-wrapper" *ngIf="!contentLoader">
      <!-- Transaction section start -->
      <div
        class="table-wrapper-container"
        *ngIf="activeCategoryFilter === 'Transactions overview'"
      >
        <app-dynamic-filter-v2-modal
          *ngIf="showFilter"
          (handleClose)="handleCloseFilterPopup($event)"
          [filterInputOptions]="filterInputOptions"
          [filterFormGroup]="filterForm"
          [filterTitle]="'Filter'"
          [filterTitleDescription]="
            'Apply filters to find the result you are looking for.'
          "
          (handleSubmit)="handleFiltering($event)"
          (handleSearch)="dynamicSearchFilter($event)"
          (handleLoadMore)="dynamicLoadMore($event)"
          (handleFilterClear)="handleFilterClear($event)"
        >
        </app-dynamic-filter-v2-modal>
        <div class="search-result-and-filters-container">
          <div class="result-section" *ngIf="searchValue !== ''">
            <p class="title" *ngIf="searchValue !== ''">
              Search result : &nbsp;<span [title]="searchValue">{{
                searchValue
              }}</span>
            </p>
            <p class="total-count-badge" *ngIf="searchValue !== ''">
              {{ pagination.totalRecords + " Records" }}
            </p>
          </div>
          <div class="filter-section">
            <ul class="quote-state-filters">
              <ng-container *ngFor="let quoteState of quoteStates">
                <li
                  class="quote-state"
                  [class.noPointerEvents]="
                    quoteState.value === activeQuoteStatus.value
                  "
                  (click)="
                    quoteState.value !== activeQuoteStatus.value &&
                      handleQuoteState(quoteState)
                  "
                  [ngClass]="
                    quoteState.value === activeQuoteStatus.value
                      ? 'activeQuoteState'
                      : ''
                  "
                >
                  {{ quoteState.name }}
                </li>
              </ng-container>
            </ul>

            <div
              class="filter-optns-container"
              *ngIf="getFilteredValues().length > 0"
            >
              <div
                class="filter-optn"
                *ngFor="let filter of getFilteredValues()"
              >
                <div class="option-name">{{ filter.value }}</div>
                <button
                  type="button"
                  aria-label="close"
                  class="close-btn"
                  (click)="handleFilterOptnRemove(filter)"
                >
                  <img src="assets/img/dashboard/quote/remove.png" />
                </button>
              </div>
            </div>
            <div class="search-input">
              <boxxds-textinput-v2
                [form]="insuredForm"
                [placeholder]="'Search insured'"
                [control]="'searchKey'"
                [id]="'search'"
                [leftIcon]="'assets/img/workflow3/sidemenu/search.png'"
                [width]="'200px'"
                [maxLength]="'40'"
                (handleKeyUp)="handleSearch($event)"
              >
              </boxxds-textinput-v2>
            </div>
            <boxxds-button-v2
              sz="sm"
              [isBtnActive]="showFilter"
              [btnClass]="'secondary gray'"
              [buttonText]="''"
              [leftImage]="'filter-funnel-02'"
              [type]="'submitted'"
              [btnStyle]="{
                width: '36px',
                height: '36px',
                padding: '8px'
              }"
              (handleClick)="handleFilter()"
            >
            </boxxds-button-v2>
            <boxxds-button-v2
              sz="sm"
              [isBtnActive]="true"
              [btnClass]="'secondary gray'"
              [buttonText]="''"
              [leftImage]="'download-01'"
              [type]="'submitted'"
              [btnStyle]="{
                width: '36px',
                height: '36px',
                padding: '8px'
              }"
              (handleClick)="handleExport()"
            >
            </boxxds-button-v2>
          </div>
        </div>
        <boxxds-table-v2
          id="insured-table"
          class="insured-table"
          [tableData]="tableData"
          [columnsToHide]="columnsToHide"
          [sortableColumns]="sortableColumns"
          [iconColumns]="iconColumns"
          [customColumnWidth]="customColumnWidth"
          (sortHandler)="handleSorting($event)"
          (columnClickHandler)="columnClickHandle($event)"
          [showPopOver]="true"
          [columnHeadsToHide]="columnHeadsToHide"
          [totalCount]="pagination.totalRecords"
          (pageChanged)="handleTablePageChange($event)"
          [activePageNumber]="pagination.currentPage"
          [showSpinner]="showTblSpinner"
          (editHandler)="handleEditInsured($event)"
          [tableContainerStyle]="{ maxHeight: '550px', minHeight: '550px' }"
          [multiContentColumns]="multiContentColumns"
          [sensitiveDataColumns]="['ID', 'Insured name']"
        >
        </boxxds-table-v2>
      </div>
      <!-- Transaction section end -->

      <!-- producer information section start -->
      <div
        class="producer-form-container"
        *ngIf="activeCategoryFilter === 'Producer information'"
      >
        <div class="producer-details-section">
          <div class="left-container">
            <div class="title-description-container">
              <boxxds-body
                [text]="'Producer information' | translate"
                [customStyle]="{
                  color: '#292933',
                  fontFamily: 'Gibson',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '20px'
                }"
              >
              </boxxds-body>
              <boxxds-body
                [text]="'Enter the producer information.' | translate"
                [customStyle]="{
                  color: '#475467',
                  fontFamily: 'Gibson',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '20px'
                }"
              >
              </boxxds-body>
            </div>
          </div>
          <div class="right-container">
            <div class="producer-details-form">
              <boxxds-textinput-v2
                [inputAutoFocus]="true"
                [form]="form"
                [placeholder]="
                  'brokerage.producer.placeholder.enterFirstName' | translate
                "
                [control]="'firstName'"
                [id]="'first-name'"
                [width]="'100%'"
                [label]="'Name' | translate"
                [isRequired]="true"
                [requiredErrMsg]="
                  'brokerage.producer.error.requiredErrMsg' | translate
                "
                [invalidErrMsg]="
                  'brokerage.producer.error.invalidErrMsg' | translate
                "
                [formSubmitted]="submitted"
                [style]="{ width: '100%' }"
                [isDisabled]="!producerSelected?.active"
              >
              </boxxds-textinput-v2>

              <boxxds-textinput-v2
                [form]="form"
                [placeholder]="
                  'brokerage.producer.placeholder.enterLastName' | translate
                "
                [control]="'lastName'"
                [id]="'last-name'"
                [width]="'100%'"
                [label]="'brokerage.producer.label.lastName' | translate"
                [isRequired]="true"
                [requiredErrMsg]="
                  'brokerage.producer.error.requiredErrMsg' | translate
                "
                [invalidErrMsg]="
                  'brokerage.producer.error.invalidErrMsg' | translate
                "
                [formSubmitted]="submitted"
                [style]="{ width: '100%' }"
                [isDisabled]="!producerSelected?.active"
              >
              </boxxds-textinput-v2>

              <boxxds-textinput-v2
                [form]="form"
                [placeholder]="'olivia@untitledui.com'"
                [control]="'email'"
                [id]="'email'"
                [width]="'100%'"
                [label]="'brokerage.producer.label.email' | translate"
                [leftIcon]="'assets/img/email.svg'"
                [requiredErrMsg]="
                  'brokerage.producer.error.requiredErrMsg' | translate
                "
                [isRequired]="true"
                [invalidErrMsg]="
                  'brokerage.producer.error.enterAValidEmail' | translate
                "
                [formSubmitted]="submitted"
                [style]="{ width: '100%' }"
                [isDisabled]="!producerSelected?.active"
              >
              </boxxds-textinput-v2>

              <boxxds-textinput-v2
                [form]="form"
                [mask]="'000-000-0000'"
                [placeholder]="'111-111-1111'"
                [control]="'phone'"
                [id]="'phoneNumber'"
                [width]="'100%'"
                [label]="'Phone' | translate"
                [requiredErrMsg]="
                  'brokerage.producer.error.requiredErrMsg' | translate
                "
                [invalidErrMsg]="
                  'brokerage.producer.error.invalidErrMsg' | translate
                "
                [isRequired]="true"
                [formSubmitted]="submitted"
                [style]="{ width: '100%' }"
                [isDisabled]="!producerSelected?.active"
              >
              </boxxds-textinput-v2>

              <boxxds-textinput-v2
                [form]="form"
                [placeholder]="'Enter license number' | translate"
                [control]="'licenseNumber'"
                [id]="'licenseNumber'"
                [width]="'100%'"
                [label]="'brokerage.producer.label.licenseNumber' | translate"
                [invalidErrMsg]="
                  'brokerage.producer.error.invalidLicenseNumber' | translate
                "
                [formSubmitted]="submitted"
                [customErrMsg]="
                  'brokerage.producer.error.noLicenseNumber' | translate
                "
                [style]="{ width: '100%' }"
                [readonly]="!this.permissionList[this.currentScreen]"
                [isDisabled]="!producerSelected?.active"
              >
              </boxxds-textinput-v2>

              <boxxds-datepicker-v2
                [placeholder]="
                  'brokerage.producer.placeholder.selectDate' | translate
                "
                [label]="
                  'brokerage.producer.label.licenseExpiryDate' | translate
                "
                [showErr]="errorExpiry().show"
                [errMsg]="errorExpiry().msg | translate"
                [bsValue]="form.value['licenseExpiry']"
                [dateFormat]="shortDateFormat"
                (bsValueChange)="handleExpiryDate($event)"
                [style]="{ width: '100%' }"
                [isDisabled]="!producerSelected?.active"
              >
              </boxxds-datepicker-v2>

              <div class="profile-image">
                <div class="image-container">
                  <img [src]="croppedImage" alt="Avatar" class="avatar" />
                </div>
                <div class="image-handler-section">
                  <label class="drag-n-drop-wrapper">
                    <div class="drag-n-drop">
                      <div class="upload-icon-holder">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M6.66602 13.3333L9.99935 10M9.99935 10L13.3327 13.3333M9.99935 10V17.5M16.666 13.9524C17.6839 13.1117 18.3327 11.8399 18.3327 10.4167C18.3327 7.88536 16.2807 5.83333 13.7493 5.83333C13.5673 5.83333 13.3969 5.73833 13.3044 5.58145C12.2177 3.73736 10.2114 2.5 7.91602 2.5C4.46424 2.5 1.66602 5.29822 1.66602 8.75C1.66602 10.4718 2.36222 12.0309 3.48847 13.1613"
                            stroke="#2D2D39"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <h1>Click to upload <span>or drag and drop</span></h1>
                      <h6>JPG, JPEG, PNG or GIF (max. 800x400px)</h6>
                    </div>
                    <input
                      [disabled]="!producerSelected?.active"
                      #fileInput
                      type="file"
                      (click)="fileInput.value = ''"
                      (change)="onImageSelected($event)"
                      accept="image/*"
                    />
                  </label>
                </div>
              </div>
              <dialog id="myDialog" #mi>
                <form method="dialog">
                  <header>
                    <h2>{{ "producerPhoto" | translate }}</h2>
                  </header>
                  <main>
                    <image-cropper
                      [resizeToWidth]="256"
                      [cropperMinWidth]="128"
                      [imageChangedEvent]="imageChangedEvent"
                      [aspectRatio]="4 / 4"
                      format="png"
                      (imageCropped)="imageCropped($event)"
                      (imageLoaded)="imageLoaded()"
                      (loadImageFailed)="loadImageFailed()"
                      *ngIf="imageChangedEvent"
                    ></image-cropper>
                  </main>
                  <footer>
                    <span class="profile-note">{{
                      "workFlow3.brokerageProfile.message.profilePictureNoteV2"
                        | translate
                    }}</span>
                    <menu>
                      <button
                        id="dialog-footer--confirm-btn"
                        class="dialog-submit-btn"
                        (click)="selectedImage()"
                      >
                        {{ "common.confirm" | translate }}
                      </button>
                      <button
                        id="close_dialog"
                        class="dialog-close-btn"
                        (click)="closeImageModal(); mi.close()"
                      >
                        {{ "common.close" | translate }}
                      </button>
                    </menu>
                  </footer>
                </form>
              </dialog>
            </div>
          </div>
        </div>
        <div class="producer-access-toggle-button-section">
          <div class="left-container"></div>
          <div class="right-container">
            <div class="access-button-wrapper">
              <boxxds-switch-v2
                [disabled]="false"
                name="system-access-switch"
                id="system-access-switch"
                label="This producer will have system access"
                (changed)="producerSelected.active && handleSystemAccess()"
                [checked]="hasSystemAccess"
                [switchStyle]="{
                  background: hasSystemAccess ? '#FCBD32' : '#9090AF'
                }"
                [disabled]="!producerSelected.active"
              >
              </boxxds-switch-v2>
            </div>
          </div>
        </div>
        <div class="producer-action-buttons-section">
          <div class="left-container"></div>
          <div class="right-container">
            <div class="action-buttons-container">
              <boxxds-button-v2
                [buttonText]="'Discard' | translate"
                [type]="'submit'"
                [isDisabled]="!!!this.isFormDirty && !isSystemAccessUpdated()"
                [size]="'md'"
                [btnClass]="'link gray'"
                [btnType]="'default'"
                [isDropdown]="false"
                (handleClick)="
                  (!!!!this.isFormDirty || isSystemAccessUpdated()) &&
                    handleDiscardChanges()
                "
                [btnStyle]="{ padding: '10px 30px' }"
              ></boxxds-button-v2>

              <boxxds-button-v2
                [buttonText]="'Save' | translate"
                [type]="'submit'"
                [isDisabled]="
                  (producerSelected && !producerSelected.active) ||
                  (!!!this.isFormDirty && !isSystemAccessUpdated())
                "
                [size]="'md'"
                [btnClass]="'secondary gray'"
                [btnType]="'default'"
                [isDropdown]="false"
                (handleClick)="
                  (!!!!this.isFormDirty || isSystemAccessUpdated()) &&
                    handleSubmit($event)
                "
                [btnStyle]="{ padding: '10px 30px' }"
              ></boxxds-button-v2>

              <boxxds-button-v2
                [buttonText]="
                  ((producerSelected?.active
                    ? 'common.deactivate'
                    : 'common.reactivate'
                  ) | translate) +
                  ' ' +
                  ('common.producer' | translate)
                "
                [type]="'submit'"
                [isDisabled]="false"
                [size]="'md'"
                [btnClass]="'secondary gray'"
                [btnType]="'default'"
                [isDropdown]="false"
                (handleClick)="handleProducerChangeStatus()"
                [btnStyle]="{ padding: '10px 30px' }"
              ></boxxds-button-v2>
            </div>
          </div>
        </div>
        <app-pagefooter-timestamp-v2
          *ngIf="createdBy && createdAt"
          [createdBy]="createdBy"
          [createdAt]="createdAt"
          [updatedBy]="updatedBy"
          [updatedAt]="updatedAt"
        >
        </app-pagefooter-timestamp-v2>
      </div>
      <!-- producer information section end -->
      <!-- producer commission start -->
      <div
        class="producer-form-container"
        *ngIf="activeCategoryFilter === 'Commission rates'"
      >
        <app-commission-rate-workflow3
          *ngIf="producerId"
          [producerId]="producerId"
          [type]="commissionType"
          [isProducerActive]="producerSelected?.active ? true : false"
        >
        </app-commission-rate-workflow3>
      </div>
      <!-- producer commission end-->
    </div>
  </section>
</div>
