import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';

@Component({
  selector: 'app-container-commission-detail',
  templateUrl: './container-commission-detail.component.html',
  styleUrls: ['./container-commission-detail.component.less'],
})
export class ContainerCommissionDetailComponent implements OnInit {
  @Input() isActive: boolean;
  @Input() commissionData: any;
  /* eslint-disable */
  @Output() onPageChanged = new EventEmitter();
  @Output() onEdited = new EventEmitter();
  @Output() onUpdated = new EventEmitter();
  @Output() onDisabled = new EventEmitter();
  @Output() onEnabled = new EventEmitter();
  /* eslint-disable */
  currentPage: number = 1;
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
    });

    this.currentPage = this.commissionData?.pagination?.currentPage || 1;
  }

  handlePageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.onPageChanged.emit(pageNumber);
  }
  handleEditing(commission) {
    commission.status = 3;
  }

  handleCancelEdit(commission) {
    commission.status = 1;
  }

  handleUpdating(commission) {
    this.onUpdated.emit(commission);
  }
  handleDisabling(commission) {
    this.onDisabled.emit(commission);
  }
  handleEnabling(commission) {
    this.onEnabled.emit(commission);
  }
}
