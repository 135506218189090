<div class="holder">
  <boxxds-alert
    *ngIf="showErrorAlert"
    (handleCloseEvent)="handleCloseSuccessEvent()"
    type="error"
    headerText="error!"
    [bodyText]="msgErrorAlert"
  >
  </boxxds-alert>
  <div class="heading-holder">
    <boxxds-heading
      sz="5"
      [text]="'System user directory' | translate"
    ></boxxds-heading>
  </div>
  <label class="checkbox-header">{{ ("SEARCH BY" | translate) + ":" }}</label>
  <boxxds-checkbox
    [options]="searchOptions"
    (handleCheckbox)="handleCheckbox($event, searchOptions)"
    [containerStyle]="{ display: 'flex' }"
  >
  </boxxds-checkbox>
  <boxxds-textinput
    [form]="form"
    [placeholder]="'Search directory' | translate"
    [control]="'searchKey'"
    maxLength="45"
    [id]="'searchKey'"
    [leftIcon]="'assets/img/dashboard/search/search.png'"
    [containerStyle]="{
      marginTop: '12px',
      marginBottom: !this.permissionList[this.currentScreen] ? '12px' : '0px'
    }"
    (handleKeyUp)="handleSearch($event)"
    [readonly]="isSearchInpReadOnly()"
  >
  </boxxds-textinput>
  <ng-container *ngIf="this.permissionList[this.currentScreen]">
    <boxxds-dropdown-button
      placeholder="Add system user"
      [options]="systemUserTypes"
      (handleValueSelection)="statusActionBtnClicked($event)"
      [btnStyle]="{ height: '32px' }"
      [dropdownContainerStyle]="{
        width: '176px',
        marginBottom: '12px',
        marginLeft: 'auto'
      }"
    >
    </boxxds-dropdown-button>
  </ng-container>
  <boxxds-table
    [data]="data"
    [totalCount]="totalDataCount"
    [showSpinner]="showTblSpinner"
    (pageChanged)="handlePageChange($event)"
    class="table"
    (sortUpClicked)="handleAscSort($event)"
    (sortDownClicked)="handleDescSort($event)"
    id="user-table"
    addCopyBtnToField="Email"
    [addSortTo]="addSortTo"
    [styleInactive]="true"
    [linkDetails]="[{ name: 'edit details', icon: 'edit' }]"
    [showLinks]="this.permissionList[this.currentScreen] ? true : false"
    [tableStyle]="{ marginBottom: isSearchResult ? '58px' : '27.5px' }"
    [activePageNumber]="pageNumber"
  >
  </boxxds-table>
</div>
