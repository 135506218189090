<boxxds-alert
  *ngIf="showErrorAlert"
  (handleCloseEvent)="handleCloseSuccessEvent()"
  type="error"
  headerText="error!"
  [bodyText]="msgErrorAlert"
>
</boxxds-alert>
<ng-container *ngIf="this.permissionList[this.currentScreen]">
  <div class="add-producer">
    <boxxds-button
      [isDisabled]="!isActive"
      [leftImage]="'add'"
      (handleClick)="onHandlerClick()"
      [buttonText]="'Add branch' | translate"
      [type]="'button'"
      btnType="secondary"
      [ngStyle]="{ 'margin-top': '30px' }"
      [btnStyle]="{ background: 'none', border: '1px solid #9090AF' }"
    >
    </boxxds-button>
  </div>
</ng-container>

<div class="tbl-producer">
  <boxxds-table
    [data]="data"
    addCopyBtnToField="Contact"
    [totalCount]="totalRecords"
    class="table"
    id="brokerage-table-branches"
    tblHeading="Branches details list"
    [addSortTo]="addSortTo"
    (pageChanged)="handlePageChange($event)"
    [tableStyle]="{ marginBottom: '0px' }"
    [styleInactive]="true"
    [linkDetails]="[{ name: 'View' }]"
    [showLinks]="true"
    [tblContainerStyle]="{
      backgroundColor: '#fff',
      padding: '40px 68px',
      marginBottom: '36px',
      border: '1px solid #D6D6D9',
      borderRadius: '8px'
    }"
    (sortUpClicked)="handleAscSort($event)"
    (sortDownClicked)="handleDescSort($event)"
    [activePageNumber]="pageNumber"
  >
  </boxxds-table>
</div>
