<ng-container *ngIf="isQuoteOptionUpdating">
  <div class="loader-wrapper">
    <div class="tab-body-spinner">
      <boxxds-spinner
        [containerStyle]="{
          width: '112px',
          height: '112px',
          '--b': '15px'
        }"
      ></boxxds-spinner>
    </div>
  </div>
</ng-container>

<div *ngIf="!isQuoteOptionUpdating" class="quote-selection">
  <div class="header-section">
    <p>{{ headerText }} <span>*</span></p>
    <div class="sendComparison-btn-container">
      <boxxds-button-v2
        class="linkButton"
        (click)="($event)"
        [size]="'sm'"
        [btnClass]="'link'"
        [buttonText]="'Send comparison'"
        (handleClick)="sendComparison()"
        [isDisabled]="!comparisonEnabled"
      >
      </boxxds-button-v2>
    </div>
  </div>

  <div class="body-section">
    <div class="quote-options">
      <!-- ----------------- -->
      <div class="quote-card-table">
        <div class="quote-card-title">
          <div class="quote-card-title-wrap">
            <div class="quote-row option-tab"></div>
            <div class="quote-row">
              <p>Limit</p>
            </div>
            <div class="quote-row">
              <p>Retention</p>
            </div>
            <div class="quote-row">
              <p>
                {{
                  "workFlow3.components.quoteSelection.totalPremium" | translate
                }}
              </p>
              <span>{{
                "workFlow3.components.quoteSelection.otherFee" | translate
              }}</span>
            </div>
          </div>
        </div>
        <div class="quote-option">
          <div
            class="quote-option-slider custom-scrollbar"
            id="pricingTableSlider"
            [ngClass]="{ enableScroll: enableScrollQuotes }"
          >
            <div
              class="swiper-wrapper"
              *ngFor="let quote of sortedQuoteOption; let i = index"
            >
              <div
                class="quote-option-item"
                [ngClass]="{
                  selectedQuote: selectedQuoteOptions.includes(
                    quote.quoteOptionId
                  )
                }"
              >
                <div class="quote-item">
                  <div
                    class="quote-item-wrap"
                    (click)="
                      !isQuoteOptionUpdating &&
                        !isReadOnly &&
                        quoteSelected(quote)
                    "
                    [ngClass]="{
                      selectedQuote: selectedQuoteOptions.includes(
                        quote.quoteOptionId
                      )
                    }"
                  >
                    <div class="quote-row">
                      <div
                        class="option-tab"
                        [ngClass]="{
                          'selected-option': selectedQuoteOptions.includes(
                            quote.quoteOptionId
                          )
                        }"
                      >
                        <boxxds-checkbox-v2
                          [options]="[
                            {
                              name: '',
                              value: true,
                              checked: selectedQuoteOptions.includes(
                                quote.quoteOptionId
                              ),
                              id: quote.quoteOptionId
                            }
                          ]"
                          [checkBoxStyle]="{ margin: '0' }"
                          [style]="{ width: '20px' }"
                          [class.noPointerEvents]="true"
                        ></boxxds-checkbox-v2>
                        <p>
                          {{
                            "workFlow3.components.quoteSelection.option"
                              | translate
                          }}
                        </p>
                        <p class="quote-option-number">{{ i + 1 }}</p>
                        <div class="quote-option-id-container">
                          <p ngClass="selected-option-id">
                            {{
                              "(" +
                                ("common.id" | translate) +
                                " " +
                                quote.optionNumber +
                                ")"
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="quote-row">
                      <p class="simple-values">
                        {{
                          quote.limit
                            | currency: currency : "symbol-narrow" : "1.0-0"
                        }}
                      </p>
                    </div>
                    <div class="quote-row">
                      <p class="simple-values">
                        {{
                          quote.deductible
                            | currency: currency : "symbol-narrow" : "1.0-0"
                        }}
                      </p>
                    </div>
                    <div class="quote-row total-premium">
                      <p class="pt-1">
                        {{
                          quote.totalAmount
                            | currency: currency : "symbol-narrow" : "1.2-2"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="other-fees" *ngIf="selectedQuoteOptions.length <= 1">
            <div class="fees-info">
              <div class="fee-description">
                <p class="title">Other fees included in premiums</p>
                <p>
                  Editing the fees will change the total premium in all the
                  quote options.
                </p>
              </div>
              <div class="fee-details">
                <div class="grid-item">
                  <p class="title">Sales tax</p>
                  <p>${{ otherFeeData["taxesServicesAmount"] }}</p>
                </div>
                <div class="grid-item">
                  <p class="title">Insurance tax</p>
                  <p>${{ otherFeeData["taxesPremiumAmount"] }}</p>
                </div>
                <div class="grid-item">
                  <p class="title">Services</p>
                  <p>${{ otherFeeData["servicesAmount"] }}</p>
                </div>

                <div class="grid-item toggle-div">
                  <div
                    class="toggle-info"
                    [ngClass]="{ 'fee-edit-mode': isTRIAAmountEditMode }"
                  >
                    <p class="title">TRIA</p>
                    <div class="fee-input-grid">
                      <boxxds-body
                        *ngIf="!isTRIAAmountEditMode && triaEnabled"
                        sz="sm"
                        [text]="getTRIA() | currency: currency"
                        [customStyle]="{
                          textTransform: 'uppercase'
                        }"
                      >
                      </boxxds-body>
                      <button
                        [disabled]="currentQuoteStatus === 'quote-closed'"
                        *ngIf="triaEnabled && !isTRIAAmountEditMode"
                        class="btn-edit"
                        (click)="toggleTRIAAmountEdit($event)"
                      >
                        <img src="assets/img/edit-icon.png" alt="edit" />
                      </button>
                    </div>
                    <ng-container *ngIf="isTRIAAmountEditMode">
                      <boxxds-textinput-v2
                        [hasOuterBorder]="false"
                        [validatePositiveInteger]="true"
                        [inputAutoFocus]="true"
                        [form]="policyFeeForm"
                        [placeholder]="'quoteSummary.enterTria' | translate"
                        [control]="'triaAmount'"
                        [id]="'triaAmount'"
                        [width]="'100%'"
                        mask="separator.2"
                        maxLength="10"
                        [prefix]="'$'"
                        thousandSeparator=","
                        [containerStyle]="{ maxWidth: '125px' }"
                        [invalidErrMsg]="
                          'quoteSummary.errors.triaAmount' | translate
                        "
                        (handleBlur)="toggleTRIAAmountEdit($event, true)"
                        class="tria-input"
                      >
                      </boxxds-textinput-v2>
                    </ng-container>
                  </div>
                  <div class="toggle">
                    <boxxds-switch-v2
                      [disabled]="currentQuoteStatus === 'quote-closed'"
                      name="tria-fee"
                      (changed)="triaToggle()"
                      [ngStyle]="{ marginBottom: '5px', marginTop: '5px' }"
                      [checked]="triaEnabled"
                      [switchStyle]="triaEnabled && { background: '#9090AF' }"
                    >
                    </boxxds-switch-v2>
                  </div>
                </div>

                <div class="grid-item toggle-div">
                  <div
                    class="toggle-info"
                    [ngClass]="{ 'fee-edit-mode': isPolicyFeeEditMode }"
                  >
                    <p class="title">Policy fee</p>
                    <div class="fee-input-grid">
                      <boxxds-body
                        *ngIf="!isPolicyFeeEditMode && policyFeeEnabled"
                        sz="sm"
                        [text]="getPolicyFee() | currency: currency"
                        [customStyle]="{
                          textTransform: 'uppercase'
                        }"
                      >
                      </boxxds-body>
                      <button
                        [disabled]="currentQuoteStatus === 'quote-closed'"
                        *ngIf="policyFeeEnabled && !isPolicyFeeEditMode"
                        class="btn-edit"
                        (click)="togglePolicyFeeEdit($event)"
                      >
                        <img src="assets/img/edit-icon.png" alt="edit" />
                      </button>
                    </div>
                    <ng-container *ngIf="isPolicyFeeEditMode">
                      <boxxds-textinput-v2
                        [hasOuterBorder]="false"
                        [validatePositiveInteger]="true"
                        [inputAutoFocus]="true"
                        [form]="policyFeeForm"
                        [placeholder]="
                          'quoteSummary.enterPolicyFees' | translate
                        "
                        [control]="'policyFee'"
                        [id]="'policyFee'"
                        [width]="'100%'"
                        mask="separator.2"
                        maxLength="10"
                        [prefix]="'$'"
                        thousandSeparator=","
                        [containerStyle]="{ maxWidth: '125px' }"
                        [invalidErrMsg]="
                          'quoteSummary.errors.policyFee' | translate
                        "
                        class="policy-input"
                        (handleBlur)="togglePolicyFeeEdit($event, true)"
                      >
                      </boxxds-textinput-v2>
                    </ng-container>
                  </div>
                  <div class="toggle">
                    <boxxds-switch-v2
                      [disabled]="currentQuoteStatus === 'quote-closed'"
                      name="policy-fee"
                      (changed)="policyFeeToggle()"
                      [ngStyle]="{ marginBottom: '5px', marginTop: '5px' }"
                      [checked]="policyFeeEnabled"
                      [switchStyle]="
                        policyFeeEnabled && { background: '#9090AF' }
                      "
                    >
                    </boxxds-switch-v2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
