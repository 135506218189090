import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { firstValueFrom, Observable, Subject, take } from 'rxjs';
import { ViewportScroller } from '@angular/common';
import { Quote } from 'src/app/models/quote.model';
import { DomainsService } from 'src/app/services/domains.service';
import { IndustryClassService } from 'src/app/services/industry-class.service';
import { IndustryService } from 'src/app/services/industry.service';
import { InsuredService } from 'src/app/services/insured.service';
import { getQuoteSelector } from 'src/app/store/create-quote/create-quote.selector';
import * as InsuredAction from 'src/app/store/insured/insured.action';
import {
  arrayToObjet,
  getErrorMessage,
  regexHelpers,
  removeDuplicatedKeys,
} from 'src/app/utils/utils';
import { formatDateTime } from 'src/app/utils/formatDate';
import { BillingCompanyContactService } from 'src/app/services/billing-company-contact.service';
import { DOMAIN_CODE_RANGENUMEMPLOYEES } from 'src/app/constants/quote-constant';
import {
  getCountrySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { TranslateService } from '@ngx-translate/core';
import { UNITED_STATES } from 'src/app/constants/location-constant';
import { NAICSPredictionService } from 'src/app/services/naicsPrediction.service';
import { environment } from 'src/environments/environment';
import { NewQuoteService } from 'src/app/services/new-quote.service';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-company-insured-form',
  templateUrl: './company-insured-form.component.html',
  styleUrls: ['./company-insured-form.component.less'],
})
export class CompanyInsuredFormComponent
  implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy
{
  @Input() form: FormGroup;
  @Input() form$: Observable<Quote>;
  @Input() checkValidation: boolean = false;
  @Input() insuredId;
  @Input() continueButtonLabel;
  @Input() isActive: boolean = true;
  @Input() fromSubmissionFlow: boolean = false;
  @Input() allowLocationLoader: boolean = true;

  @Output() handleContinue = new EventEmitter<any>();
  @Output() updateBreadCrumbs = new EventEmitter<any>();
  @Output() handleErrorOrSuccess = new EventEmitter<any>();
  @Output() spinnerChanged = new EventEmitter<any>();
  @Output() toggleEvent = new EventEmitter<any>();
  @Output() handleCompletedLocationLoading = new EventEmitter<any>();

  industryPredictionForm: FormGroup;
  noOfEmpOptns: any;
  numberOfEmp: any;
  businessSectorOptns: any = [];
  businessIndustrySectorOptns = [];
  businessIndustryClass;
  businessIndustry;
  insuredOptns: any = [];
  isInsuredTypeCompany: boolean;
  percentageRegex = regexHelpers.percentage;
  industryClass = {};
  selectedIndustryId = 0;
  insuredLocationId = null;
  insuredData = null;
  initialFormData = null;
  billingCompanyContactId = null;
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  showBodySpinner: boolean = false;
  private ngUnsubscribe$ = new Subject<void>();
  quoteFlowSteps;
  showContactDetails: boolean = false;
  contactToggleEnabled: boolean = false;
  toggleLabel: string = 'insured.heading.showContactDetails';
  country: string = '';
  shortDateTimeFormat: string = '';
  longDateTimeFormat: string = '';
  industryPredictions = [];
  showIndustrySection = false;
  enableConfirmButton = false;
  predictionsReturned = [];
  showIndustryDropdowns = true;
  showPredictions = true;
  regionCode = '';
  showBanner = false;
  alertData = {
    show: false,
    type: '',
    header: '',
    body: '',
  };
  confirmIndustryShow: boolean = true;

  regionId;
  // mock NAICS
  mockExternalAPIs = true;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private viewportScroller: ViewportScroller,
    private industryService: IndustryService,
    private industryClassService: IndustryClassService,
    private insuredService: InsuredService,
    private domainsService: DomainsService,
    private changeDetector: ChangeDetectorRef,
    private billingCompanyContactService: BillingCompanyContactService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    private naicsPredictionService: NAICSPredictionService,
    private newQuoteService: NewQuoteService,
    private locationService: LocationService,
  ) {
    this.industryPredictionForm = this.fb.group({
      industry: [''],
    });
  }

  async ngOnInit(): Promise<void> {
    if (
      this.localStorageService.getMockExternalAPIs() === 'false' ||
      environment.mockExternalAPIs === false
    ) {
      this.mockExternalAPIs = false;
    }
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.isActive = this.permissionList[this.currentScreen] ?? false;
      this.country = data.country;
      this.shortDateTimeFormat = data.shortDateTimeFormat;
      this.longDateTimeFormat = data.longDateTimeFormat;
    });

    this.form$ = this.store.pipe(select(getQuoteSelector));

    this.form$.subscribe(
      (event) => (this.isInsuredTypeCompany = event.ui.isInsuredTypeCompany),
    );
    this.quoteFlowSteps = this.localStorageService.getQuoteFlowSteps();
    await this.domainsService
      .GetByDomainCode(DOMAIN_CODE_RANGENUMEMPLOYEES)
      .subscribe(async (data) => {
        const tableData = data.data.map((dataObj) => ({
          key: dataObj.description,
          value: dataObj.description,
          id: dataObj.id,
        }));
        this.noOfEmpOptns = tableData;
        this.numberOfEmp = arrayToObjet(data.data, 'description');
        if (this.insuredId) {
          await this.populateInsuredDetails(this.insuredId);
        }
      });

    this.store.dispatch(
      new InsuredAction.setIsNavigatedFromInsuredIndividual({
        isNavigatedFromIndividual: false,
      }),
    );
    if (this.fromSubmissionFlow === true) {
      if (!this.insuredId) {
        this.form$.subscribe((event) => (this.insuredId = event.ui?.insuredId));
      }

      if (this.insuredId) {
        this.newQuoteService.clearInsuredId();
        this.populateInsuredDetails(this.insuredId);
      }
    }
    if (this.country.toUpperCase() == UNITED_STATES) {
      this.form.get('revenuePercentUS').setValue('100');
      this.form.get('revenuePercentCA').setValue('0');
      this.form.get('revenuePercentOther').setValue('0');
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  async populateInsuredDetails(insuredId) {
    this.showPredictions = false;
    this.showIndustrySection = true;
    this.confirmIndustryShow = true;
    this.showBodySpinner = true;
    this.spinnerChanged.emit(true);
    try {
      const getInsuredData = this.insuredService.GetByInsuredId(insuredId);
      let insuredData = await firstValueFrom(getInsuredData);
      const getBillingContactData =
        this.billingCompanyContactService.GetByInsuredId(insuredId);
      let insuredBillingContactData = await firstValueFrom(
        getBillingContactData,
      );
      this.billingCompanyContactId = insuredBillingContactData?.data[0]?.id;
      if (insuredData?.data) {
        const insured = insuredData?.data;
        this.regionCode = insured?.insuredLocation?.region?.regionCode;
        this.regionId = insured?.insuredLocation?.regionId;

        this.form.setValue({
          businessName: insured?.companyName ?? '',
          website: insured?.companyWebsite ?? '',
          revenue: insured?.revenue ? Number(insured?.revenue) : '',
          noOfEmployees: this.getNumberOfEmployees(
            insured?.numOfEmployees ?? '',
          ),
          industry: insured?.industry?.name ?? '',
          industryClass: insured?.industryClass?.name ?? '',
          revenuePercentUS: insured?.revenueUSPerc ?? '',
          revenuePercentCA: insured?.revenueCAPerc ?? '',
          revenuePercentOther: insured?.revenueOtherPerc ?? '',
          firstName: insuredBillingContactData?.data[0]?.firstName ?? '',
          lastName: insuredBillingContactData?.data[0]?.lastName ?? '',
          email: insuredBillingContactData?.data[0]?.email ?? '',
          country: insured?.insuredLocation?.country ?? '',
          province: insured?.insuredLocation?.region?.name ?? '',
          city: insured?.insuredLocation?.city ?? '',
          address: insured?.insuredLocation?.address ?? '',
          zipPostalCode: insured?.insuredLocation?.zipPostalCode ?? '',
          addressLine2: insured?.insuredLocation?.addressLine2 ?? '',
        });
        if (
          insuredBillingContactData?.data[0]?.firstName ||
          insuredBillingContactData?.data[0]?.lastName ||
          insuredBillingContactData?.data[0]?.email
        ) {
          this.showContactDetails = true;
          this.contactToggleEnabled = true;
        }
        this.initialFormData = this.form.value;
        this.insuredData = insuredData.data;
        // format date for timestamp
        this.insuredData = {
          ...this.insuredData,
          updatedDt: formatDateTime(
            this.insuredData?.updatedDt,
            this.longDateTimeFormat,
          ),
          createdDt: formatDateTime(
            this.insuredData?.createdDt,
            this.longDateTimeFormat,
          ),
        };
        this.updateBreadCrumbs.emit(insured?.companyName ?? '');
        this.insuredLocationId = insured?.insuredLocation?.id;
      }
      this.showBodySpinner = false;
      this.spinnerChanged.emit(false);
    } catch (error) {
      if (![500].includes(error?.status)) {
        this.handleErrorOrSuccess.emit({
          show: true,
          type: 'error',
          header: 'common.errorMsg',
          body: getErrorMessage(error),
        });
      }
    }
    this.showBodySpinner = false;
    this.spinnerChanged.emit(false);
  }

  hasFormValueChanged(): boolean {
    const stringifyAndSort = (obj: any): string =>
      JSON.stringify(obj, Object.keys(obj).sort());

    const normalizeObject = (obj: any): any =>
      JSON.parse(JSON.stringify(obj), (key, value) =>
        typeof value === 'string' && !isNaN(Number(value))
          ? this.roundNumber(Number(value), 2)
          : value,
      );
    if (this.form?.value && this.initialFormData) {
      const normalizedObj1 = normalizeObject(this.form.value);
      const normalizedObj2 = normalizeObject(this.initialFormData);

      const stringifiedObj1 = stringifyAndSort(normalizedObj1);
      const stringifiedObj2 = stringifyAndSort(normalizedObj2);

      return stringifiedObj1 !== stringifiedObj2;
    } else return false;
  }
  roundNumber(value: number, precision: number): number {
    const factor = Math.pow(10, precision);
    return Math.round(value * factor) / factor;
  }

  getNumberOfEmployees(id) {
    let numberOfEmployees = '';
    if (id) {
      const selectedNumberOfEmployeesIdx = this.noOfEmpOptns?.findIndex(
        (numOfEmployee) => numOfEmployee?.id === id,
      );
      if (selectedNumberOfEmployeesIdx !== -1) {
        numberOfEmployees =
          this.noOfEmpOptns[selectedNumberOfEmployeesIdx]?.value ?? '';
      }
    }
    return numberOfEmployees;
  }

  onHandlercompanyRegionIdSelected(regionId: number) {}
  completedLocationLoading(loadStatus: boolean) {
    this.handleCompletedLocationLoading.emit(loadStatus);
  }

  handleNoOfEmployees(event) {}

  handleIndustryClassSearch(searchKey) {
    this.form.controls['industry'].patchValue('');
    if (searchKey?.length >= 2) {
      this.industryClassService
        .GetIndustryClassByName(searchKey)
        .subscribe((data) => {
          const tableData = data.data?.map((dataObj) => ({
            key: dataObj.name,
            value: dataObj.name,
          }));

          data.data = data.data.reverse();
          this.businessIndustryClass = data.data?.reduce(
            (accumulator, value) => {
              return { ...accumulator, [value.name]: value };
            },
            {},
          );
          this.businessSectorOptns = removeDuplicatedKeys(tableData);
          if (this.businessSectorOptns?.length === 0) {
            this.form.controls['industryClass'].patchValue('');
          }
        });
    } else {
      this.businessSectorOptns = [];
      this.form.controls['industryClass'].patchValue('');
    }
  }

  handleBusinessSector(value) {
    // clear industry value when industryClass changes
    this.form.controls['industry'].patchValue('');
    this.industryClass = this.businessIndustryClass[value];
    this.businessSectorOptns = [];
  }

  handleIndustrySearch(searchKey) {
    if (searchKey?.length >= 2) {
      const industryClass = this.businessIndustryClass
        ? this.businessIndustryClass[this.form.value.industryClass]
        : this.insuredData?.industryClass;
      this.industryService
        .GetByIndustryClassIdAndName(industryClass?.id, searchKey, 1, 10)
        .subscribe((data) => {
          const tableData = data.data?.map((dataObj) => ({
            key: dataObj.name,
            value: dataObj.name,
          }));
          this.businessIndustrySectorOptns = removeDuplicatedKeys(tableData);

          this.businessIndustry = data.data?.reduce((accumulator, value) => {
            return { ...accumulator, [value.name]: value };
          }, {});
          if (this.businessIndustrySectorOptns?.length === 0) {
            this.form.controls['industry'].patchValue('');
          }
        });
    } else {
      this.businessIndustrySectorOptns = [];
      this.form.controls['industry'].patchValue('');
    }
  }

  handleBusinessIndustrySector(industryName) {
    this.selectedIndustryId = this.businessIndustry[industryName]?.id;
    this.businessIndustrySectorOptns = [];
  }

  async deactivateOrReactivateInsured() {
    if (this.insuredData.active) {
      try {
        const deleteInsured = this.insuredService.Delete(this.insuredId);
        const getDeleteResponse = await firstValueFrom(deleteInsured);
        this.handleErrorOrSuccess.emit({
          show: true,
          type: 'success',
          header: 'common.successHeader',
          body: 'insured.success.insuredDeactivated',
        });
      } catch (error) {
        if (![500].includes(error?.status)) {
          this.handleErrorOrSuccess.emit({
            show: true,
            type: 'error',
            header: 'common.errorMsg',
            body: getErrorMessage(error),
          });
        }
      }
    } else {
      try {
        const restoreInsured = this.insuredService.Restore(this.insuredId);
        const getRestoreResponse = await firstValueFrom(restoreInsured);
        this.handleErrorOrSuccess.emit({
          show: true,
          type: 'success',
          header: 'common.successHeader',
          body: 'insured.success.insuredReactivated',
        });
      } catch (error) {
        if (![500].includes(error?.status)) {
          this.handleErrorOrSuccess.emit({
            show: true,
            type: 'error',
            header: 'common.errorMsg',
            body: getErrorMessage(error),
          });
        }
      }
    }
    this.populateInsuredDetails(this.insuredId);
  }

  handleRadioCheck(param) {
    const valueSelected = this.industryPredictionForm.value['industry'];
    if (valueSelected === this.industryPredictions.length - 1) {
      this.form.get('industryClass').setValue('');
      this.form.get('industry').setValue('');
      this.selectedIndustryId = null;
      this.showIndustryDropdowns = true;
    } else {
      this.form
        .get('industryClass')
        .setValue(this.predictionsReturned[valueSelected]?.industryClass.name);
      this.form
        .get('industry')
        .setValue(this.predictionsReturned[valueSelected]?.industry.name);
      this.selectedIndustryId =
        this.predictionsReturned[valueSelected]?.industry.id;
      this.showIndustryDropdowns = false;
    }
  }

  async handleGetIndustryPredictions() {
    this.handleAlertClose();
    if (
      !this.form.value['businessName'] ||
      !this.form.value['address'] ||
      !this.form.value['province'] ||
      !this.form.value['country']
    ) {
      this.checkValidation = true;
      this.alertData = {
        show: true,
        type: 'error',
        header: 'common.errorMsg',
        body: 'error.confirmIndustryValidation',
      };
      return;
    }
    this.showBodySpinner = true;
    this.insuredOptns = [];
    let country = 'can';
    this.store
      .select(getCountrySelector)
      .pipe(take(1))
      .subscribe((countryName) => {
        if (countryName?.toUpperCase() === UNITED_STATES) {
          country = 'usa';
        }
      });
    if (!this.regionCode && this.form.value['province']) {
      const region = await firstValueFrom(
        this.locationService.getRegionByName(this.form.value['province']),
      );
      this.regionCode = region?.data[0]?.regionCode;
    }
    this.naicsPredictionService
      .getNaicsPredictions({
        name: this.form.value?.businessName,
        address: this.form.value.address,
        state: this.regionCode,
        country: country,
        isMock: this.mockExternalAPIs,
      })
      .subscribe({
        next: (response) => {
          if (response.data?.length > 0) {
            this.predictionsReturned = response.data;
            const predictions = [];
            response.data.forEach((prediction, index) => {
              predictions.push({
                label: `Class: ${prediction?.industryClass?.name}`,
                label2: `Industry: ${prediction?.industry?.name}`,
                currentValue: this.industryPredictionForm.value['industry'],
                value: index,
              });
              if (index === 0) {
                this.industryPredictionForm.get('industry').setValue(0);
                this.form
                  .get('industryClass')
                  .setValue(prediction?.industryClass?.name);
                this.form.get('industry').setValue(prediction?.industry?.name);
                this.selectedIndustryId = prediction?.industry?.id;
              }
            });
            predictions.push({
              label:
                'None of the above. I already know the industry class code and the industry.',
              label2: ' ',
              currentValue: this.industryPredictionForm.value['industry'],
              value: predictions?.length,
            });

            this.industryPredictions = predictions;
            this.showPredictions = true;
            this.showIndustryDropdowns = false;
            this.confirmIndustryShow = true;
          } else {
            // this.alertData = {
            //   show: true,
            //   type: 'warn',
            //   header: 'common.warnMsg',
            //   body: 'error.noPredictionReturned',
            // };
            this.handleErrorOrSuccess.emit({
              show: true,
              type: 'warn',
              header: 'common.warnMsg',
              body: 'error.noPredictionReturned',
            });
            this.form.get('industryClass').setValue('');
            this.form.get('industry').setValue('');
            this.showPredictions = false;
            this.showIndustryDropdowns = true;
            this.confirmIndustryShow = true;
          }
          this.showIndustrySection = true;
          this.showBodySpinner = false;
        },
        error: (error) => {
          // this.alertData = {
          //   show: true,
          //   type: 'warn',
          //   header: 'common.warnMsg',
          //   body: 'error.noPredictionReturned',
          // };
          this.handleErrorOrSuccess.emit({
            show: true,
            type: 'warn',
            header: 'common.warnMsg',
            body: 'error.noPredictionReturned',
          });
          this.showPredictions = false;
          this.showIndustrySection = true;
          this.showBodySpinner = false;
          this.showIndustryDropdowns = true;
          this.confirmIndustryShow = true;
        },
      });
  }

  handleAlertClose() {
    this.alertData = {
      show: false,
      type: '',
      header: '',
      body: '',
    };
  }

  handleRegionCode(regionCode) {
    this.regionCode = regionCode;
  }

  enableConfirmIndustry() {
    return this.form.value['businessName'] &&
      this.form.value['address'] &&
      this.form.value['province'] &&
      this.form.value['country']
      ? true
      : false;
  }

  getIndustryData(industryClass) {
    const prediction = this.predictionsReturned.filter(
      (industryObj) => industryObj.industryClass.name === industryClass,
    );
    if (prediction.length > 0) {
      return prediction[0];
    }
    return null;
  }

  async addOrUpdateCompanyInsured(event) {
    if (
      this.insuredId &&
      this.fromSubmissionFlow === false &&
      !(this.insuredData.active && this.hasFormValueChanged())
    ) {
      return;
    }
    this.checkValidation = true;
    if (this.form.valid) {
      const predictionIndustryData = this.getIndustryData(
        this.form.value.industryClass,
      );
      const industryClass =
        (this.businessIndustryClass
          ? this.businessIndustryClass[this.form.value.industryClass]
          : predictionIndustryData
          ? predictionIndustryData.industryClass
          : this.insuredData?.industryClass) ?? this.insuredData?.industryClass;
      const industry =
        (this.businessIndustry
          ? this.businessIndustry[this.form.value.industry]
          : predictionIndustryData
          ? predictionIndustryData.industry
          : this.insuredData?.industry) ?? this.insuredData?.industry;
      const numberOfEmp = this.numberOfEmp[this.form.value.noOfEmployees];
      const insuredId = this.insuredData?.id ?? 0;
      const currentFormValue = this.form.value;
      this.handleContinue.emit({
        industryClass,
        industry,
        numberOfEmp,
        insuredLocationId: this.insuredLocationId,
        billingContactId: this.billingCompanyContactId,
        initialFormData: this.initialFormData,
        insuredId,
        currentFormValue,
        regionId: this.regionId,
      });
    } else {
      if (this.form.controls['website'].hasError('maxlength')) {
        this.setMaxLengthErrorMessage('website');
      } else if (this.form.controls['address'].hasError('maxlength')) {
        this.setMaxLengthErrorMessage('address');
      } else if (this.form.controls['addressLine2'].hasError('maxlength')) {
        this.setMaxLengthErrorMessage('addressLine2');
      } else if (this.form.controls['city'].hasError('maxlength')) {
        this.setMaxLengthErrorMessage('city');
      } else if (this.form.controls['zipPostalCode'].hasError('maxlength')) {
        this.setMaxLengthErrorMessage('zipPostalCode');
      } else if (this.form.controls['firstName'].hasError('maxlength')) {
        this.setMaxLengthErrorMessage('firstName');
      } else if (this.form.controls['lastName'].hasError('maxlength')) {
        this.setMaxLengthErrorMessage('lastName');
      } else if (this.form.controls['email'].hasError('maxlength')) {
        this.setMaxLengthErrorMessage('email');
      }
    }
  }
  setMaxLengthErrorMessage(fieldName: string) {
    const charLimit =
      this.form.controls[fieldName].errors['maxlength']?.requiredLength ?? 0;
    const errMsg =
      charLimit !== 0
        ? this.translateService
            .instant('error.maximumAllowedCharErroMsg')
            ?.replace('{charLimit}', charLimit)
        : this.translateService.instant(
            'error.maximumAllowedCharDefaultErroMsg',
          );
    this.form.controls[fieldName].setErrors({
      message: errMsg,
    });
  }
  handleExitFlow() {
    let isMock = 'true';
    if (
      this.localStorageService.getMockExternalAPIs() === 'false' ||
      environment.mockExternalAPIs === false
    ) {
      isMock = 'false';
    }
    this.router.navigate(['/dashboard/home'], {
      queryParams: {
        mock: isMock,
      },
      skipLocationChange: true,
    });
  }
  handleSearch(query: string) {
    if (query) query = query.trim();
    if (query.length >= 3) {
      const page = 1;
      const limit = 10;
      const sort = '';
      let insuredType = 2;
      this.insuredService
        .GetByInsuredNameAndType(query, insuredType, page, limit, sort)
        .subscribe((response: any) => {
          this.insuredOptns = response.data.map((dataObj) => ({
            main_text: dataObj?.companyName,
            id: dataObj?.id,
          }));
          this.confirmIndustryShow = false;
        });
    } else {
      this.insuredOptns = [];
    }
  }

  toggleChanged(event: boolean) {
    this.showContactDetails = !this.showContactDetails;
    this.contactToggleEnabled = !this.contactToggleEnabled;
    if (this.contactToggleEnabled === true) {
      this.toggleLabel = 'insured.heading.hideContactDetails';
    } else {
      this.toggleLabel = 'insured.heading.showContactDetails';
    }
    this.toggleEvent.emit({
      showContactDetails: this.showContactDetails,
      contactToggleEnabled: this.contactToggleEnabled,
    });
  }
}
