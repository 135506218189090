import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { NewQuoteService } from 'src/app/services/new-quote.service';
import {
  getQuoteSelector,
  getSanctionsData,
} from 'src/app/store/create-quote/create-quote.selector';

@Component({
  selector: 'app-restrictive-industries',
  templateUrl: './restrictive-industries.component.html',
  styleUrls: ['./restrictive-industries.component.less'],
})
export class RestrictiveIndustriesComponent implements OnInit, OnDestroy {
  data: any = [];
  insured = '';
  industry = '';
  isRestrictedIndustry = false;
  quoteFlowSteps;
  quoteFlowUrl = '';
  quoteId;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private newQuoteService: NewQuoteService,
    private alertService: AlertService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    window.document.body.style.overflow = 'unset';
    this.quoteFlowSteps = this.localStorageService.getQuoteFlowSteps();
    if (this.quoteFlowSteps === '7') {
      this.quoteFlowUrl = 'quote';
    } else {
      this.quoteFlowUrl = 'quote4flow';
    }
    this.store
      .pipe(select(getSanctionsData))
      .pipe(take(1))
      .subscribe((data) => {
        this.industry = data.industry;
        this.insured = data.insured;
        this.data = data?.clearance;
        this.isRestrictedIndustry = data?.isRestrictedIndustry;
      });
    this.store.select(getQuoteSelector).subscribe((data) => {
      this.quoteId = data.ui.policyId;
    });

    if (this.data.length == 0) {
      const sanction = this.newQuoteService.getSanctionData();
      this.data = sanction?.clearance;
      this.isRestrictedIndustry = sanction?.isRestricted;
      this.newQuoteService.clearSanction();
    }
    this.data = this.data.map((sanction, index) => {
      if (index === 0 && this.isRestrictedIndustry) {
        return sanction;
      } else {
        const popOverData = [];
        popOverData.push(`Full Name: ${sanction.fullName}`);
        popOverData.push(
          `Citizenship: ${
            sanction.citizenship?.length > 0
              ? sanction.citizenship?.join(', ')
              : '-'
          }`,
        );
        popOverData.push(`Remarks: ${sanction?.remarks || '-'}`);
        popOverData.push(
          `Additional Sanctions: ${
            sanction.additionalSanctions?.length > 0
              ? sanction.additionalSanctions.join(', ')
              : '-'
          }`,
        );
        popOverData.push(
          `Programs: ${
            sanction.programs?.length > 0 ? sanction.programs.join(', ') : '-'
          }`,
        );

        return {
          Type: 'OFAC',
          Description: 'Sanction is subject to secondary sanction',
          Reasons: 'Sanction reasons',
          Source: sanction?.source || 'SDN',
          Score: sanction?.score?.toString(),
          popOverContent: popOverData,
        };
      }
    });
  }

  ngOnDestroy(): void {
    this.alertService.clearAlerts(-1);
  }

  handleBackBtn() {
    this.router.navigate([`/dashboard/${this.quoteFlowUrl}/new`], {
      relativeTo: this.activatedRoute,
      skipLocationChange: true,
    });
  }

  handleContinue() {
    let url = `/dashboard/${this.quoteFlowUrl}/new/product/form`;
    if (this.quoteId) {
      url += '/' + this.quoteId;
    }
    this.router.navigate([url], {
      relativeTo: this.activatedRoute,
      skipLocationChange: true,
    });
  }
}
