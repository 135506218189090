<div
  class="dropdown-collection"
  [ngClass]="btnType === '2' && 'btn-style-2'"
  [ngStyle]="dropdownContainerStyle"
>
  <button
    [ngStyle]="btnStyle"
    [ngClass]="isStatusBadgeBtn ? [statusType, 'status-badge'] : null"
    class="dropdown-button"
    aria-haspopup="listbox"
    aria-labelledby="dropdown-label"
    (click)="handleBtnClick()"
    (clickOutside)="dropdownOpen = false"
  >
    <span class="label-text">{{ placeholder }}</span>
    <div
      class="drop-down-btn-wrapper"
      [ngClass]="{ 'drop-down-active': dropdownOpen }"
    >
      <span *ngIf="isStatusBadgeBtn" class="plus-icon"></span>
      <span *ngIf="!isStatusBadgeBtn" (click)="toggleDropdown($event)">
        <img [src]="'assets/img/dropdown-icon.png'" alt="" />
      </span>
    </div>
  </button>
  <ul
    class="dropdown-list"
    role="listbox"
    aria-expanded="false"
    [ngClass]="{ 'dropdown-open': dropdownOpen }"
  >
    <li
      *ngFor="let o of options"
      id="dropdown-selected"
      tabindex="0"
      (click)="select(o)"
      role="option"
      [innerHTML]="o.key"
    ></li>
  </ul>
</div>
