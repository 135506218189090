<div class="maintenance-banner-holder">
  <div class="message-sentence-1">
    {{ ("maintenance.bannerMessageContent1" | translate) + ":" }}
  </div>
  <div
    class="message-schedule"
    *ngIf="startDateTime !== '' && endDateTime !== ''"
  >
    {{ startDateTime + " - " + endDateTime }}
  </div>
  <div class="message-sentence-2">
    {{ "maintenance.bannerMessageContent2" | translate }}
  </div>
</div>
