<div class="quote-option-container">
  <div
    class="quote-option-box"
    [ngClass]="{ 'option-selected': !!quote.ui.optionSelected }"
  >
    <div>
      <div class="input-container">
        <div
          class="checkmark"
          [ngClass]="{
            show: checkbox.checked || !!quote.ui.optionSelected,
            'read-only-check':
              !this.permissionList[this.currentScreen] ||
              this.currentQuoteStatus === 'quote-closed'
          }"
        ></div>
        <input
          #checkbox
          type="checkbox"
          [disabled]="
            !this.permissionList[this.currentScreen] ||
            this.currentQuoteStatus === 'quote-closed'
          "
          [value]="!!quote.ui.optionSelected"
          [checked]="!!quote.ui.optionSelected"
          (change)="
            this.currentQuoteStatus !== 'quote-closed' &&
              this.permissionList[this.currentScreen] &&
              handleQuoteSelect(quote?.products[0]?.optionNumber, quote)
          "
          [id]="'checkbox-' + idxOfQuote"
        />
      </div>

      <div
        class="hdr-quote-option box-style"
        [ngClass]="{ selected: quote?.ui?.optionSelected }"
      >
        {{
          ("quoteCompare.quoteOption" | translate) +
            " " +
            quote?.products[0]?.optionNumber
        }}
      </div>
      <div
        class="pdt-values-box"
        [ngStyle]="
          quote?.products[0].quoteStatus === domainIdForReferral && {
            marginTop: '12px'
          }
        "
        *ngIf="quote.products as productValues"
      >
        <div *ngIf="quote?.products[0].quoteStatus === domainIdForReferral">
          <boxxds-status-badge
            [status]="'common.status.referral' | translate"
            [statusType]="'primary'"
            [badgeStyle]="{
              textTransform: 'capitalize',
              marginLeft: '24px',
              background: '#FFD6AE',
              border: '1px solid #DC6803',
              color: '#56565C',
              fontSize: '16px'
            }"
            [rightImage]="'ReferalIcon'"
            (click)="
              handleOpenReasonModalPopup(quote?.products[0].quoteOptionId)
            "
          ></boxxds-status-badge>
          <app-modal-reasons-popup
            *ngIf="selectedQuoteOptionId === quote?.products[0].quoteOptionId"
            [quoteOptionId]="quote?.products[0].quoteOptionId"
            (handleClose)="handleReasonModalClosePopup($event)"
          ></app-modal-reasons-popup>
        </div>
        <ng-container *ngIf="toggle[quote.products[0].key]">
          <div
            class="pdt-values box-style"
            [ngClass]="{ selected: quote?.ui?.optionSelected }"
          >
            <div class="pdt-value">
              {{
                productValues.length > 0
                  ? productValues[0].limit
                    ? (productValues[0].limit
                      | currencyFormat: currency : "symbol-narrow" : "1.0-0")
                    : ("0"
                      | currencyFormat: currency : "symbol-narrow" : "1.0-0")
                  : ""
              }}
            </div>
            <div class="pdt-value">
              {{
                productValues.length > 0
                  ? isNumber(productValues[0].deductables)
                    ? (productValues[0].deductables
                      | currency: currency : "symbol-narrow" : "1.0-0")
                    : productValues[0].deductables
                  : ""
              }}
            </div>
            <div class="pdt-value">
              {{
                productValues.length > 0
                  ? isNumber(productValues[0].premium)
                    ? (productValues[0].premium
                      | currency: currency : "symbol-narrow" : "1.0-0")
                    : productValues[0].premium
                  : ""
              }}
            </div>
            <div class="pdt-value">
              {{
                productValues.length > 0
                  ? productValues[0].brokerCommissionPerc + "%"
                  : ""
              }}
            </div>
            <div class="pdt-value">
              <button
                class="sublimit"
                tabindex="0"
                (click)="
                  handleSublimitAction(
                    quote?.products[0]?.optionNumber,
                    product
                  )
                "
                (mouseout)="
                  sublimitIconSrc =
                    'assets/img/dashboard/quote/lifecycle/arrow-right-circle.png'
                "
                (mouseover)="
                  sublimitIconSrc =
                    'assets/img/dashboard/quote/lifecycle/arrow-right-circle-hover.png'
                "
                (focus)="
                  changeIconSrc(
                    'assets/img/dashboard/quote/lifecycle/arrow-right-circle-focus.png'
                  )
                "
                (blur)="
                  changeIconSrc(
                    'assets/img/dashboard/quote/lifecycle/arrow-right-circle.png'
                  )
                "
              >
                <div class="label">
                  {{ "common.sublimits" | translate | uppercase }}
                  <img [src]="sublimitIconSrc" width="12px" height="12px" />
                </div>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="pdt-values-box">
        <ng-container *ngIf="toggle.expandFeeSection">
          <div
            class="pdt-values box-style"
            [ngClass]="{ selected: quote?.ui?.optionSelected }"
          >
            <div class="pdt-value">
              {{
                isNumber(quote.total.policyFee)
                  ? (quote.total.policyFee
                    | currency: currency : "symbol-narrow")
                  : quote.total.policyFee
              }}
            </div>
            <div class="pdt-value">
              {{
                isNumber(quote.total.servicesAmount)
                  ? (quote.total.servicesAmount
                    | currency: currency : "symbol-narrow")
                  : quote.total.servicesAmount
              }}
            </div>
            <div class="pdt-value">
              {{
                isNumber(quote.total.taxesServicesAmount)
                  ? (quote.total.taxesServicesAmount
                    | currency: currency : "symbol-narrow")
                  : quote.total.taxesServicesAmount
              }}
            </div>
            <div class="pdt-value">
              {{
                isNumber(quote.total.triaAmount)
                  ? (quote.total.triaAmount
                    | currency: currency : "symbol-narrow")
                  : quote.total.triaAmount
              }}
            </div>
            <div class="pdt-value">
              {{
                isNumber(quote.total.taxesPremiumAmount)
                  ? (quote.total.taxesPremiumAmount
                    | currency: currency : "symbol-narrow")
                  : quote.total.taxesPremiumAmount
              }}
            </div>
          </div>
        </ng-container>
      </div>

      <div class="pdt-values-box">
        <ng-container *ngIf="toggle.expandPremiumSection">
          <div
            class="premium-values box-style"
            [ngClass]="{ selected: quote?.ui?.optionSelected }"
          >
            <div class="subtotal-premium">
              {{
                isNumber(quote.total.premium)
                  ? (quote.total.premium | currency: currency : "symbol-narrow")
                  : quote.total.premium
              }}
            </div>
            <div class="edited-premium">
              {{
                isNumber(quote.total.totalAmount)
                  ? (quote.total.totalAmount
                    | currency: currency : "symbol-narrow")
                  : quote.total.totalAmount
              }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column">
    <button
      *ngIf="this.currentQuoteStatus !== 'quote-closed'"
      class="trash-btn"
      (click)="
        handleDuplicateClick(quote?.products[0]?.optionNumber, idxOfQuote)
      "
    >
      <img src="assets/img/dashboard/quote/4stepflow/copy.png" />
    </button>
    <button
      *ngIf="this.currentQuoteStatus !== 'quote-closed'"
      class="trash-btn"
      (click)="handleQuoteDelete(idxOfQuote, quote)"
    >
      <img src="assets/img/dashboard/quote/4stepflow/trash.png" />
    </button>
    <button
      *ngIf="this.currentQuoteStatus !== 'quote-closed'"
      class="trash-btn"
      (click)="
        this.permissionList[this.currentScreen] && handleEdit(quote, idxOfQuote)
      "
    >
      <img src="assets/img/dashboard/quote/4stepflow/edit.png" />
    </button>
  </div>
</div>
