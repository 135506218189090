import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-wrapper',
  templateUrl: './footer-wrapper.component.html',
  styleUrls: ['./footer-wrapper.component.less'],
})
export class FooterWrapperComponent {
  constructor() {}
}
