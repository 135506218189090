import { createReducer, on } from '@ngrx/store';
import { RegionModel, RiskModel } from 'src/app/models/riskRegion.model';
import {
  addRegions,
  addRiskSuccess,
  cleanRegionsData,
} from './riskRegion.actions';

export interface RiskRegionState {
  risks: ReadonlyArray<RiskModel>;
  regions: ReadonlyArray<RegionModel>;
}

export const initialState: RiskRegionState = {
  regions: [],
  risks: [],
};

export const RiskRegionReducer = createReducer(
  initialState,

  on(addRiskSuccess, (state, action) => ({
    ...state,
    risks: [...action.items],
    regions: [],
  })),
  on(addRegions, (state, action) => ({
    ...state,
    regions: [...action.items],
  })),
  on(cleanRegionsData, (state) => ({
    ...state,
    regions: [],
  })),
);
