import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
} from '@angular/core';
import { InsuredService } from 'src/app/services/insured.service';
import { PolicyDashboardService } from 'src/app/services/policy-dashboard.service';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { getErrorMessage } from 'src/app/utils/utils';
@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.less'],
})
export class SearchInputComponent implements OnInit {
  @Input() id: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() showError: boolean;
  @Input() isRequired: boolean;
  @Input() control: string;
  @Input() type: string = 'text';
  @Input() leftIcon: string = '';
  @Input() handleBlur: () => void;
  @Input() maxWidth: string = '';
  @Input() inputListHdr: string = '';
  @Output() suggestedSearchSelected = new EventEmitter();

  inputOptions: any;
  dropdownOptions: any = [
    { key: 'Insured name', value: 'Insured name' },
    { key: 'Policy number', value: 'Policy number' },
  ];
  public searchListOpen: boolean = false;
  public get dropdownSearchElement(): Element {
    return this.elem.nativeElement.querySelector('.search-list');
  }

  private currentSearchListIndex = -1;

  @Output() handleKeyUp = new EventEmitter();

  isLeftIcon: boolean;
  showClose: boolean = false;
  selectedSearch: string;
  searchKey: string;
  searchIn: string = this.dropdownOptions[0].value;
  showErrorAlert = false;
  msgErrorAlert = '';
  userID: number;
  constructor(
    private elem: ElementRef,
    public policyService: PolicyDashboardService,
    public insuredService: InsuredService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.isLeftIcon = this.leftIcon.length !== 0;
    this.userID = this.localStorageService.getBoxxUserId();
  }

  @HostListener('document:keydown', ['$event'])
  handleSearchKeyboardEvents($event: KeyboardEvent) {
    if (!this.searchListOpen) {
      return;
    }
    if ($event.code === 'ArrowUp') {
      $event.preventDefault();
      if (this.currentSearchListIndex < 0) {
        this.currentSearchListIndex = 0;
      } else if (this.currentSearchListIndex > 0) {
        this.currentSearchListIndex--;
      }
      this.elem.nativeElement
        .querySelectorAll('.search-list-item')
        .item(this.currentSearchListIndex)
        .focus();
    } else if ($event.code === 'ArrowDown') {
      $event.preventDefault();
      if (this.currentSearchListIndex < 0) {
        this.currentSearchListIndex = 0;
      } else if (this.currentSearchListIndex < this.inputOptions.length - 1) {
        this.currentSearchListIndex++;
      }
      this.elem.nativeElement
        .querySelectorAll('.search-list-item')
        .item(this.currentSearchListIndex)
        .focus();
    } else if (
      ($event.code === 'Enter' || $event.code === 'NumpadEnter') &&
      this.currentSearchListIndex >= 0
    ) {
      $event.preventDefault();
      this.selectBySearchListIdx(this.currentSearchListIndex);
    } else if ($event.code === 'Escape') {
      $event.preventDefault();
      this.closeSearchDropdown();
    }
  }

  selectBySearchListIdx(i: number) {
    let value = this.inputOptions[i];
    this.selectSuggested(value);
  }
  handleCloseSuccessEvent() {
    this.showErrorAlert = false;
  }
  handleCloseBtnClick(event) {
    this.showClose = false;
  }

  getIconSrc() {
    return this.leftIcon;
  }

  onKeyUp(event) {
    // TODO - API call to get search suggestions.

    if (this.searchIn === this.dropdownOptions[1].value) {
      if (this.searchKey.length >= 1) {
        const pkgPolicyNumber = encodeURIComponent(this.searchKey);
        const page = 1;
        const limit = 5;
        const sort = '';

        this.policyService
          .GetAllByPolicyNumber(pkgPolicyNumber, page, limit, sort)
          .subscribe({
            next: (response) => {
              const tableData = response.data?.map((dataObj) => ({
                name: dataObj.policy.pkgPolicyNumber,
                value: dataObj.policy.pkgPolicyNumber,
              }));
              this.inputOptions = tableData;
            },
            error: (error) => {
              if (![500].includes(error?.status)) {
                this.showErrorAlert = true;
              }
              this.msgErrorAlert = getErrorMessage(error);
            },
          });

        // TODO - no need to show suggestion list if no matches found
        this.showClose = true;
        this.searchListOpen = true;
        this.dropdownSearchElement.setAttribute('aria-expanded', 'true');
      } else {
        this.closeSearchDropdown();
      }
    } else {
      if (this.searchKey.length >= 3) {
        const page = 1;
        const limit = 5;
        const sort = '';
        this.policyService
          .GetAllByInsuredName(
            encodeURIComponent('%' + this.searchKey),
            page,
            limit,
            sort,
          )
          .subscribe({
            next: (response) => {
              const tableData = response.data?.map((dataObj) => ({
                name: this.makeSuggest(dataObj.policy.insured),
                value: this.makeSuggest(dataObj.policy.insured),
                id: dataObj.policy?.insured?.id,
                type:
                  dataObj.policy.insured.insuredType === 2
                    ? 'company'
                    : 'individual',
              }));

              this.inputOptions = tableData.filter(
                (obj, index, self) =>
                  index === self.findIndex((o) => o.id === obj.id),
              );
            },
            error: (error) => {
              if (![500].includes(error?.status)) {
                this.showErrorAlert = true;
              }
              this.msgErrorAlert = getErrorMessage(error);
            },
          });

        // TODO - no need to show suggestion list if no matches found
        this.showClose = true;
        this.searchListOpen = true;
        this.dropdownSearchElement.setAttribute('aria-expanded', 'true');
      } else {
        this.closeSearchDropdown();
      }
    }

    this.handleKeyUp.emit(event);
  }
  makeSuggest(insured) {
    if (insured.insuredType === 2) return insured.companyName;
    return insured.firstName + ' ' + insured.lastName;
  }

  closeSearchDropdown() {
    this.searchListOpen = false;
    this.dropdownSearchElement.setAttribute('aria-expanded', 'false');
  }

  dropdownValueChanged(value) {
    this.searchIn = value;
  }

  selectSuggested(selectedObj) {
    this.selectedSearch =
      this.inputOptions[
        this.inputOptions.findIndex((obj) => obj.value == selectedObj.value)
      ];

    this.searchKey = selectedObj.value;
    this.closeSearchDropdown();
    this.suggestedSearchSelected.emit({
      selectedSearch: this.selectedSearch,
      searchIn: this.searchIn,
      type: selectedObj.type,
    });
  }

  toggleDropdown() {}

  getSuggestedValue(value) {
    let strArr = [];
    const index =
      value &&
      this.searchKey &&
      value.toLowerCase().indexOf(this.searchKey.toLowerCase());

    if (index !== -1) {
      strArr[0] = value.substring(0, index);
      strArr[1] = value.substring(
        index + (this.searchKey && this.searchKey.length),
      );
    } else {
      strArr[0] = value;
    }
    return strArr;
  }

  hasSuggestedResults(value) {
    const index =
      value &&
      this.searchKey &&
      value.toLowerCase().indexOf(this.searchKey.toLowerCase());
    return index !== -1;
  }

  clearSearchValue() {
    this.searchKey = '';
  }
}
