<div class="holder">
  <boxxds-breadcrumb [items]="itemsMenu"></boxxds-breadcrumb>
  <boxxds-heading sz="5" [text]="'New Branch'"> </boxxds-heading>

  <div class="alert-holder">
    <boxxds-alert
      *ngIf="hasError"
      type="error"
      headerText="error!"
      [bodyText]="errorMessage"
      (handleCloseEvent)="onHandleCloseEvent()"
    >
    </boxxds-alert>
  </div>

  <form [formGroup]="form" (ngSubmit)="handleSubmit($event)">
    <app-branch-details
      [isActive]="true"
      [form]="branch"
      [requiredErrMsg]="requiredErrMsg"
      [invalidErrMsg]="invalidErrMsg"
      [formSubmitted]="submitted"
    >
    </app-branch-details>

    <app-location-details-form
      (eventEmitRegionIdSelected)="regionIdSelected($event)"
      [formSubmitted]="submitted"
      [form]="location"
      [requiredErrMsg]="requiredErrMsg"
    >
    </app-location-details-form>

    <div class="footer-add-branch">
      <button
        *ngIf="showSpinner"
        class="btn-processing"
        tabindex="0"
        [ngStyle]="{
          height: '32px',
          border: '1px solid #FFBE3D',
          borderRadius: '8px',
          width: '119px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '4px 8px',
          background: '#FCF8EF'
        }"
      >
        <boxxds-spinner></boxxds-spinner>
      </button>

      <boxxds-button
        *ngIf="!showSpinner"
        [buttonText]="'Add branch'"
        btnType="primary-outlined"
        [btnStyle]="{
          background: 'none',
          border: '1px solid #FFBE3D',
          padding: '0px 28px',
          margin: '30px 0px'
        }"
      >
      </boxxds-button>
    </div>
  </form>
</div>
