<div class="quote-slideOut-container">
  <div class="header">
    <div class="title-status-wrapper">
      <boxxds-heading
        [text]="'common.policy' | translate"
        [customStyle]="{
          color: '#2D2D39',
          lineHeight: '32px',
          fontSize: '28px',
          fontStyle: 'normal',
          fontWeight: '600',
          fontFamily: 'Gibson'
        }"
      ></boxxds-heading>
      <boxxds-status-badge-v2
        [status]="status"
        [statusType]="getStatusType()"
        [badgeStyle]="{
          display: 'flex',
          padding: '2px 8px',
          alignItems: 'center',
          borderRadius: '9999px',
          width: 'fit-content',
          textAlign: 'center',
          fontFamily: 'Gibson',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '18px',
          gap: '4px'
        }"
        [rightImage]="''"
      ></boxxds-status-badge-v2>
    </div>
    <div class="details">
      <boxxds-body
        [text]="('common.id' | translate) + ':' + details['id'] ?? ''"
        [customStyle]="{
          color: '#475467',
          fontSize: '16px',
          fontWeight: '500',
          lineHeight: '24px',
          fontFamily: 'Gibson',
          fontStyle: 'normal'
        }"
      ></boxxds-body>
    </div>
  </div>
  <div class="content-wrapper">
    <div class="section-titles-wrapper">
      <ul>
        <ng-container *ngFor="let section of sectionTitles">
          <li
            (click)="
              activeSectionFilter !== section &&
                !isLoading &&
                handleSectionFilter(section)
            "
            [class.active]="activeSectionFilter === section"
            [class.noPointerEvents]="
              activeSectionFilter === section || isLoading
            "
          >
            {{ section }}
          </li>
        </ng-container>
      </ul>
    </div>
    <div class="details-section">
      <ng-container *ngIf="activeSectionFilter === 'Policy documents'">
        <app-slideout-document-v2
          *ngIf="policyRiskId"
          [details]="details"
          [policyRiskId]="policyRiskId"
          [policyPeriodId]="policyPeriodId"
          [showDocPreview]="showDocPreview"
          [showImagePreview]="showImagePreview"
          [isPolicy]="true"
          [closeModalClicked]="closeModalClicked"
          (handleShowGenerateDocMsg)="showGenerateDocMsg()"
          (handleLoading)="handleLoader($event)"
          [isProductActive]="isProductActive"
        >
        </app-slideout-document-v2>
      </ng-container>
      <ng-container *ngIf="activeSectionFilter === 'History'">
        <app-slideout-history-v2
          [isPolicy]="true"
          [policyId]="policyId"
          [policyRiskTrxId]="policyRiskTrxId"
          [policyNumber]="policyNumber"
          (handleLoading)="handleLoader($event)"
        >
        </app-slideout-history-v2>
      </ng-container>
    </div>
  </div>
</div>
