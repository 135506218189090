import {
  ChangeDetectionStrategy,
  Component,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { ICommission } from 'src/app/models/commission.model';

@Component({
  selector: 'app-actions-commission',
  templateUrl: './actions-commission.component.html',
  styleUrls: ['./actions-commission.component.less'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ActionsCommissionComponent {
  @Input() commission: ICommission;
  @Input() isActive: boolean;
  @Output() handleEdit = new EventEmitter();
  @Output() handleDisable = new EventEmitter();
  @Output() handleEnable = new EventEmitter();

  constructor() {}

  handleDisableFunction(e, commission) {
    if (this.isActive) {
      this.handleDisable.emit(commission);
    } else {
      e.preventDefault();
    }
  }

  handleEnableFunction(e, commission) {
    if (this.isActive) {
      this.handleEnable.emit(commission);
    } else {
      e.preventDefault();
    }
  }

  handleEditFunciton(e, commission) {
    if (this.isActive) {
      this.handleEdit.emit(commission);
    } else {
      e.preventDefault();
    }
  }
}
