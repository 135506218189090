<div class="holder">
  <boxxds-alert
    *ngIf="showErrorAlert"
    (handleCloseEvent)="handleCloseSuccessEvent()"
    type="error"
    headerText="error!"
    [bodyText]="msgErrorAlert"
  >
  </boxxds-alert>
  <div class="heading-holder">
    <boxxds-heading
      sz="5"
      [text]="'Roles & Permissions' | translate"
    ></boxxds-heading>
  </div>
  <div class="spinner-container">
    <ng-container *ngIf="showBodySpinner">
      <div class="body-spinner">
        <boxxds-spinner
          [containerStyle]="{
            width: '112px',
            height: '112px',
            '--b': '15px'
          }"
        ></boxxds-spinner>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="!showBodySpinner">
    <div class="link-btn-container">
      <boxxds-link
        url="/dashboard/security/permissions"
        name="See full list of permissions"
      ></boxxds-link>
      <ng-container
        *ngIf="
          this.permissionList[this.currentScreen] &&
          this.permissionList['Role & Permissions']
        "
      >
        <boxxds-button
          [buttonText]="'Add new role'"
          btnType="secondary"
          type="submit"
          leftImage="add"
          (handleClick)="navigateToAddRole($event)"
          [btnStyle]="{ padding: '0 20px 0 20px' }"
        >
        </boxxds-button>
      </ng-container>
    </div>
    <boxxds-table
      [data]="roles"
      [totalCount]="totalDataCount"
      (pageChanged)="handlePageChange($event)"
      class="table"
      id="user-table"
      addCopyBtnToField="Email"
      [addSortTo]="addSortTo"
      [styleInactive]="true"
      [showSpinner]="showTblSpinner"
      [tableStyle]="{ marginBottom: '27.5px' }"
      [linkDetails]="[{ name: 'View' }]"
      [showLinks]="true"
      (sortUpClicked)="handleAscSort($event)"
      (sortDownClicked)="handleDescSort($event)"
      [activePageNumber]="pageNumber"
    >
    </boxxds-table>
  </ng-container>
</div>
