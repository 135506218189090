import { Component, OnInit } from '@angular/core';
import { MaintenanceService } from '../services/maintenance.service';
import { getLocalDateTimeFromUtcString } from '../utils/utils';

@Component({
  selector: 'app-maintenance-banner',
  templateUrl: './maintenance-banner.component.html',
  styleUrls: ['./maintenance-banner.component.less'],
})
export class MaintenanceBannerComponent implements OnInit {
  startDateTime = '';
  endDateTime = '';

  constructor(private maintenanceService: MaintenanceService) {}

  ngOnInit(): void {
    const startDateTimeUtcString =
      this.maintenanceService.getStartDateTimeString();
    const endDateTimeUtcString = this.maintenanceService.getEndDateTimeString();
    if (startDateTimeUtcString !== '' && endDateTimeUtcString !== '') {
      this.startDateTime = getLocalDateTimeFromUtcString(
        startDateTimeUtcString,
      );
      this.endDateTime = getLocalDateTimeFromUtcString(endDateTimeUtcString);
    }
  }
}
