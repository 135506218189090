import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataLayerService } from './services/data-layer.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  readonly home = { icon: 'pi pi-home', url: 'home' };
  menuItems: any[];

  constructor(
    private translate: TranslateService,
    private dataLayerService: DataLayerService,
    private router: Router,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.dataLayerService.logPageView(event.url);
      }
    });

    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es/) ? browserLang : 'en');
  }

  title = 'Boxx Insurance';
}
