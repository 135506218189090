<div class="item">
  <boxxds-body
    sz="sm"
    [text]="'Broker logo:' | translate"
    [customStyle]="{ color: '#2D2D39', textAlign: 'left', marginBottom: '8px' }"
    [ngStyle]="{ maxWidth: '179px' }"
  ></boxxds-body>
  <div class="d-flex">
    <img
      [src]="croppedImage ? croppedImage : defaultImage"
      alt="Avatar"
      class="avatar"
    />
    <br />
    <label class="fileContainer" *ngIf="isActive">
      <input
        #fileInput
        type="file"
        [disabled]="!isActive"
        (click)="fileInput.value = ''"
        (change)="onImageSelected($event)"
        accept="image/*"
      />
      <img width="14" height="14" src="assets/img/upload.png" />
    </label>
  </div>
</div>

<dialog id="myDialog" #mi>
  <form method="dialog">
    <header>
      <h2>{{ title }}</h2>
    </header>
    <main>
      <image-cropper
        [resizeToWidth]="256"
        [cropperMinWidth]="128"
        [imageChangedEvent]="imageChangedEvent"
        [aspectRatio]="4 / 4"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (loadImageFailed)="loadImageFailed()"
        *ngIf="imageChangedEvent"
      ></image-cropper>
    </main>
    <footer>
      <span class="profile-note">{{
        "common.profilePictureNote" | translate
      }}</span>
      <menu>
        <button
          id="dialog-footer--confirm-btn"
          class="dialog-submit-btn"
          (click)="selectedImage()"
        >
          {{ "common.confirm" | translate }}
        </button>
        <button
          id="close_dialog"
          class="dialog-close-btn"
          (click)="closeImageModal(); mi.close()"
        >
          {{ "common.close" | translate }}
        </button>
      </menu>
    </footer>
  </form>
</dialog>
