<div
  class="holder"
  (clickOutside)="handleOutsideClick()"
  [ngClass]="{
    expanded: isExpanded,
    collapsed: !isExpanded
  }"
>
  <div class="menu-icon-holder">
    <div class="logo-holder">
      <svg
        *ngIf="!isExpanded"
        xmlns="http://www.w3.org/2000/svg"
        width="66"
        height="23"
        viewBox="0 0 66 23"
        fill="none"
      >
        <path
          d="M65.3925 21.8608C65.3925 21.7708 65.3774 21.6659 65.3774 21.5909C65.3622 21.6659 65.3319 21.7408 65.3016 21.8308L65.1804 22.1456H65.1198L65.0138 21.8458C64.9835 21.7558 64.9532 21.6659 64.9381 21.5909C64.9381 21.6659 64.9381 21.7858 64.9229 21.8758L64.9078 22.1456H64.832L64.8775 21.501H64.9835L65.0896 21.8158C65.1198 21.8908 65.135 21.9657 65.1501 22.0257C65.1653 21.9657 65.1956 21.8908 65.2259 21.8158L65.3471 21.501H65.4531L65.4986 22.1456H65.4228L65.3925 21.8608Z"
          fill="white"
        />
        <path
          d="M64.5016 21.5759H64.3047V21.501H64.7894V21.5759H64.5774V22.1456H64.4865V21.5759H64.5016Z"
          fill="white"
        />
        <path
          d="M63.1816 22.1464H60.6367V18.3984H63.1816V18.6533H60.8791V20.1075H63.015V20.3624H60.8791V21.9065H63.1816V22.1464Z"
          fill="white"
        />
        <path
          d="M54.2886 22.1464C53.1828 22.1464 52.2891 21.3068 52.2891 20.2724C52.2891 19.238 53.1828 18.3984 54.2886 18.3984C54.8188 18.3984 55.3187 18.5933 55.6974 18.9381L55.758 18.9981L55.5763 19.163L55.5308 19.118C55.1975 18.8182 54.7582 18.6533 54.3038 18.6533C53.3343 18.6533 52.5617 19.3879 52.5617 20.2874C52.5617 21.1869 53.3495 21.9215 54.3038 21.9215C54.7582 21.9215 55.2127 21.7416 55.546 21.4268L55.6066 21.3818L55.7883 21.5617L55.7277 21.6217C55.3339 21.9515 54.8188 22.1464 54.2886 22.1464Z"
          fill="white"
        />
        <path
          d="M44.4724 22.1461V18.7729L47.3203 22.1161L47.3354 22.1461H47.6081V18.3981H47.3657V21.7713L44.5027 18.3981H44.3512L44.3209 18.3682V18.3981H44.2148V22.1461H44.4724Z"
          fill="white"
        />
        <path
          d="M39.672 22.1464H39.3842L38.7934 20.992H36.5666L35.9909 22.1464H35.7031L37.5664 18.3984H37.7633L39.672 22.1464ZM36.6878 20.7521H38.6722L37.6876 18.7582L36.6878 20.7521Z"
          fill="white"
        />
        <path
          d="M31.2201 22.1464H30.9172L30.0083 20.6772H29.0236V22.1464H28.7812V18.3984H30.1598C30.8263 18.3984 31.3716 18.9082 31.3716 19.5378C31.3716 20.1075 30.902 20.6022 30.2961 20.6622L31.2201 22.1464ZM29.0388 20.4373H30.1598C30.6899 20.4373 31.1141 20.0325 31.1141 19.5378C31.1141 19.0431 30.6899 18.6383 30.1598 18.6383H29.0388V20.4373Z"
          fill="white"
        />
        <path
          d="M22.2976 22.1464C21.419 22.1464 20.707 21.4717 20.707 20.6472V18.3984H20.9646V20.6472C20.9646 21.3368 21.5705 21.8915 22.2976 21.8915C23.0399 21.8915 23.6307 21.3368 23.6458 20.6472V18.3984H23.9033V20.6472C23.8882 21.4717 23.1762 22.1464 22.2976 22.1464Z"
          fill="white"
        />
        <path
          d="M14.9041 22.147C14.3134 22.147 13.8438 21.6972 13.8438 21.1426V21.0676H14.0861V21.1426C14.0861 21.5623 14.4497 21.9071 14.9041 21.9071C15.3586 21.9071 15.7221 21.5623 15.7221 21.1575C15.7221 20.7378 15.3586 20.393 14.9041 20.393C14.3134 20.393 13.8438 19.9432 13.8438 19.4035C13.8438 18.8638 14.3134 18.4141 14.9041 18.4141C15.4949 18.4141 15.9645 18.8638 15.9645 19.4185V19.4935H15.7221V19.4185C15.7221 18.9987 15.3586 18.6689 14.9041 18.6689C14.4497 18.6689 14.0861 19.0137 14.0861 19.4185C14.0861 19.8383 14.4497 20.1681 14.9041 20.1681C15.4949 20.1681 15.9645 20.6178 15.9645 21.1575C15.9645 21.6972 15.4798 22.147 14.9041 22.147Z"
          fill="white"
        />
        <path
          d="M8.84274 18.3984V21.7716L5.99485 18.3984H5.84337C5.84337 18.3984 5.84337 18.3984 5.82822 18.3984H5.81307H5.70703V22.1464H5.96455V18.7732L8.82759 22.1464H8.96393H8.97908H8.99422H9.08511V18.3984H8.84274Z"
          fill="white"
        />
        <path
          d="M0.480655 18.3984H0.238281V22.1464H0.480655V18.3984Z"
          fill="white"
        />
        <path
          d="M57.0018 5.5048C57.0624 5.42984 57.1078 5.36988 57.1381 5.30991C58.1379 3.8857 59.1529 2.47647 60.1527 1.05226C60.2284 0.947319 60.3041 0.902344 60.4253 0.902344C61.1676 0.902344 61.9099 0.902344 62.6673 0.902344C62.7127 0.902344 62.7733 0.902344 62.8642 0.917336C61.3494 3.07614 59.8345 5.20497 58.3197 7.36377C59.9406 9.64252 61.5615 11.9213 63.1975 14.23C63.1217 14.23 63.0611 14.245 63.0005 14.245C62.2431 14.245 61.5009 14.245 60.7434 14.245C60.6071 14.245 60.5314 14.2 60.4708 14.0951C59.3649 12.5209 58.2591 10.9618 57.1533 9.40265C57.1078 9.34268 57.0624 9.29771 57.0169 9.22275C56.7594 9.58255 56.5019 9.94235 56.2595 10.2872C55.3658 11.5615 54.4569 12.8358 53.5631 14.11C53.5025 14.2 53.4268 14.26 53.3056 14.245C52.5482 14.245 51.7908 14.245 51.0333 14.245C50.9879 14.245 50.9273 14.245 50.8516 14.23C52.4876 11.9362 54.0933 9.65751 55.7293 7.34878C54.2296 5.21996 52.7148 3.07614 51.1848 0.902344C51.2757 0.902344 51.3363 0.902344 51.3969 0.902344C52.1392 0.902344 52.8814 0.902344 53.6389 0.902344C53.76 0.902344 53.8358 0.947319 53.8964 1.03727C54.8962 2.46148 55.896 3.8857 56.9109 5.30991C56.9109 5.38487 56.9564 5.42984 57.0018 5.5048Z"
          fill="white"
        />
        <path
          d="M46.727 0.917969C46.1968 1.68255 45.6818 2.40215 45.1667 3.12175C44.1972 4.486 43.2429 5.85024 42.2734 7.21449C42.1825 7.33442 42.1977 7.40938 42.2734 7.52932C43.8034 9.67313 45.3334 11.8319 46.8634 13.9907C46.924 14.0657 46.9694 14.1407 47.0451 14.2456C46.9845 14.2456 46.924 14.2606 46.8785 14.2606C46.1211 14.2606 45.3485 14.2606 44.5911 14.2606C44.4699 14.2606 44.3942 14.2156 44.3336 14.1107C43.2277 12.5515 42.1219 10.9924 41.0161 9.43326C40.9706 9.3733 40.9252 9.31333 40.8646 9.22338C40.7434 9.38829 40.6374 9.53821 40.5313 9.68812C39.5012 11.1573 38.456 12.6265 37.4259 14.0957C37.3502 14.2156 37.2593 14.2606 37.123 14.2606C36.3807 14.2606 35.6384 14.2606 34.9113 14.2606C34.8507 14.2606 34.7901 14.2606 34.6992 14.2606C34.7447 14.1856 34.775 14.1407 34.8053 14.0957C36.3504 11.9069 37.9107 9.71811 39.4558 7.52932C39.5467 7.40938 39.5467 7.33442 39.4558 7.21449C38.0167 5.17562 36.5625 3.12175 35.1234 1.08288C35.0931 1.0379 35.0628 0.992927 35.0173 0.917969C35.1082 0.917969 35.1688 0.917969 35.2294 0.917969C35.9565 0.917969 36.6988 0.917969 37.4259 0.917969C37.5623 0.917969 37.638 0.962944 37.7137 1.06789C38.7135 2.4921 39.7133 3.91631 40.7283 5.34053C40.7586 5.3855 40.804 5.43048 40.8495 5.50543C40.8949 5.46046 40.9252 5.41548 40.9555 5.3855C41.9704 3.94629 42.9854 2.52208 43.9852 1.08288C44.0761 0.962944 44.1669 0.917969 44.3184 0.917969C45.0456 0.93296 45.7727 0.917969 46.4998 0.917969C46.5604 0.917969 46.621 0.917969 46.727 0.917969Z"
          fill="white"
        />
        <path
          d="M20.6095 3.86992C20.1096 3.3752 19.6249 2.89546 19.125 2.40073C21.3367 0.496787 25.351 0.181961 28.0625 2.79052C30.8347 5.44405 30.5772 9.50681 28.5927 11.7706C28.108 11.2908 27.6081 10.7961 27.1082 10.3014C27.9262 9.162 28.1989 7.8877 27.8808 6.50846C27.6687 5.57898 27.1839 4.79941 26.472 4.16976C24.9874 2.85049 22.5334 2.50568 20.6095 3.86992Z"
          fill="white"
        />
        <path
          d="M17.8395 3.76465C18.3394 4.25938 18.8393 4.76909 19.324 5.23384C19.1726 5.6536 18.9756 6.08837 18.8696 6.52312C18.2182 9.22163 20.0663 11.8152 22.8536 12.13C23.8231 12.235 24.732 12.0551 25.5955 11.6053C25.7015 11.5453 25.7621 11.5454 25.853 11.6353C26.2771 12.0701 26.7164 12.4898 27.1558 12.9246C27.186 12.9546 27.2163 12.9995 27.2466 13.0295C25.0804 14.6336 21.4297 14.8435 18.8544 12.5348C16.1883 10.1661 15.9763 6.31324 17.8395 3.76465Z"
          fill="white"
        />
        <path
          d="M9.60054 7.06394C10.2216 7.55867 10.6609 8.15833 10.9184 8.87794C11.7971 11.2616 10.1762 13.9002 7.6161 14.215C7.43432 14.23 7.2374 14.245 7.05561 14.245C4.81366 14.245 2.5717 14.245 0.329742 14.245C0.269148 14.245 0.208554 14.245 0.132812 14.245C0.132812 9.79243 0.132812 5.36988 0.132812 0.902344C0.193406 0.902344 0.269148 0.902344 0.329742 0.902344C2.48081 0.902344 4.64702 0.902344 6.79809 0.902344C8.93401 0.902344 10.6609 2.71634 10.5094 4.81518C10.4791 5.66971 10.161 6.4193 9.60054 7.06394ZM2.28388 12.1461C2.35962 12.1461 2.40507 12.1461 2.46566 12.1461C3.99565 12.1461 5.51048 12.1461 7.04047 12.1461C7.13136 12.1461 7.22225 12.1311 7.31314 12.1311C8.31293 12.0112 9.08549 11.1267 9.0249 10.1822C8.96431 9.10281 8.13115 8.32324 7.08591 8.32324C5.55593 8.32324 4.02594 8.32324 2.49596 8.32324C2.43536 8.32324 2.35962 8.32324 2.28388 8.32324C2.28388 9.59754 2.28388 10.8718 2.28388 12.1461ZM2.28388 6.19442C2.35962 6.19442 2.43536 6.20941 2.49596 6.20941C3.9199 6.20941 5.359 6.20941 6.78294 6.20941C7.69185 6.20941 8.40382 5.5048 8.40382 4.62029C8.40382 3.72079 7.69185 3.01618 6.7678 3.01618C5.34385 3.01618 3.90475 3.01618 2.48081 3.01618C2.42022 3.01618 2.34447 3.01618 2.28388 3.01618C2.28388 4.08059 2.28388 5.13001 2.28388 6.19442Z"
          fill="white"
        />
      </svg>

      <svg
        *ngIf="isExpanded"
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="26"
        viewBox="0 0 80 26"
        fill="none"
      >
        <path
          d="M79.8696 25.6503C79.8696 25.5402 79.851 25.4118 79.851 25.3201C79.8325 25.4118 79.7954 25.5035 79.7583 25.6136L79.61 25.9989H79.5358L79.4061 25.632C79.369 25.5219 79.3319 25.4118 79.3134 25.3201C79.3134 25.4118 79.3134 25.5586 79.2948 25.6687L79.2763 25.9989H79.1836L79.2392 25.21H79.369L79.4988 25.5953C79.5358 25.687 79.5544 25.7788 79.5729 25.8521C79.5915 25.7788 79.6285 25.687 79.6656 25.5953L79.8139 25.21H79.9437L79.9993 25.9989H79.9066L79.8696 25.6503Z"
          fill="white"
        />
        <path
          d="M78.7762 25.3017H78.5352V25.21H79.1284V25.3017H78.8689V25.9989H78.7576V25.3017H78.7762Z"
          fill="white"
        />
        <path
          d="M77.1616 26.0001H74.0469V21.4131H77.1616V21.725H74.3435V23.5048H76.9576V23.8167H74.3435V25.7065H77.1616V26.0001Z"
          fill="white"
        />
        <path
          d="M66.2793 26.0001C64.9259 26.0001 63.832 24.9726 63.832 23.7066C63.832 22.4406 64.9259 21.4131 66.2793 21.4131C66.9282 21.4131 67.54 21.6516 68.0035 22.0736L68.0776 22.147L67.8552 22.3488L67.7995 22.2938C67.3917 21.9268 66.854 21.725 66.2978 21.725C65.1113 21.725 64.1658 22.6241 64.1658 23.7249C64.1658 24.8258 65.1298 25.7249 66.2978 25.7249C66.854 25.7249 67.4102 25.5047 67.8181 25.1194L67.8922 25.0644L68.1147 25.2845L68.0406 25.3579C67.5585 25.7616 66.9282 26.0001 66.2793 26.0001Z"
          fill="white"
        />
        <path
          d="M54.2683 25.9997V21.8714L57.7538 25.963L57.7723 25.9997H58.106V21.4127H57.8094V25.541L54.3054 21.4127H54.12L54.0829 21.376V21.4127H53.9531V25.9997H54.2683Z"
          fill="white"
        />
        <path
          d="M48.3887 26.0001H48.0364L47.3134 24.5873H44.588L43.8835 26.0001H43.5312L45.8116 21.4131H46.0527L48.3887 26.0001ZM44.7363 24.2937H47.165L45.96 21.8534L44.7363 24.2937Z"
          fill="white"
        />
        <path
          d="M38.0474 26.0001H37.6766L36.5642 24.202H35.3591V26.0001H35.0625V21.4131H36.7496C37.5654 21.4131 38.2328 22.0369 38.2328 22.8075C38.2328 23.5048 37.6581 24.1103 36.9165 24.1836L38.0474 26.0001ZM35.3777 23.9084H36.7496C37.3985 23.9084 37.9176 23.413 37.9176 22.8075C37.9176 22.2021 37.3985 21.7067 36.7496 21.7067H35.3777V23.9084Z"
          fill="white"
        />
        <path
          d="M27.1264 26.0001C26.0511 26.0001 25.1797 25.1744 25.1797 24.1653V21.4131H25.4949V24.1653C25.4949 25.0093 26.2365 25.6882 27.1264 25.6882C28.0348 25.6882 28.7579 25.0093 28.7764 24.1653V21.4131H29.0916V24.1653C29.073 25.1744 28.2017 26.0001 27.1264 26.0001Z"
          fill="white"
        />
        <path
          d="M18.079 26.0003C17.356 26.0003 16.7812 25.4499 16.7812 24.771V24.6792H17.0779V24.771C17.0779 25.2847 17.5228 25.7067 18.079 25.7067C18.6352 25.7067 19.0802 25.2847 19.0802 24.7893C19.0802 24.2756 18.6352 23.8536 18.079 23.8536C17.356 23.8536 16.7812 23.3031 16.7812 22.6426C16.7812 21.9821 17.356 21.4316 18.079 21.4316C18.8021 21.4316 19.3768 21.9821 19.3768 22.661V22.7527H19.0802V22.661C19.0802 22.1472 18.6352 21.7436 18.079 21.7436C17.5228 21.7436 17.0779 22.1656 17.0779 22.661C17.0779 23.1747 17.5228 23.5784 18.079 23.5784C18.8021 23.5784 19.3768 24.1288 19.3768 24.7893C19.3768 25.4499 18.7835 26.0003 18.079 26.0003Z"
          fill="white"
        />
        <path
          d="M10.658 21.4131V25.5414L7.17257 21.4131H6.98717C6.98717 21.4131 6.98717 21.4131 6.96863 21.4131H6.95009H6.82031V26.0001H7.13549V21.8718L10.6395 26.0001H10.8064H10.8249H10.8434H10.9547V21.4131H10.658Z"
          fill="white"
        />
        <path
          d="M0.425543 21.4131H0.128906V26.0001H0.425543V21.4131Z"
          fill="white"
        />
        <path
          d="M69.5975 5.63286C69.6716 5.54112 69.7272 5.46773 69.7643 5.39433C70.9879 3.65127 72.2301 1.92655 73.4537 0.183481C73.5464 0.0550442 73.6391 0 73.7875 0C74.6959 0 75.6044 0 76.5313 0C76.587 0 76.6611 4.19681e-07 76.7724 0.0183485C74.9184 2.66047 73.0644 5.2659 71.2104 7.90802C73.1942 10.6969 75.1779 13.4858 77.1802 16.3114C77.0875 16.3114 77.0134 16.3298 76.9392 16.3298C76.0122 16.3298 75.1038 16.3298 74.1768 16.3298C74.0099 16.3298 73.9172 16.2747 73.8431 16.1463C72.4897 14.2198 71.1363 12.3116 69.7829 10.4034C69.7272 10.33 69.6716 10.2749 69.616 10.1832C69.3008 10.6235 68.9857 11.0639 68.689 11.4859C67.5952 13.0455 66.4828 14.6051 65.3889 16.1647C65.3148 16.2747 65.2221 16.3481 65.0738 16.3298C64.1468 16.3298 63.2198 16.3298 62.2928 16.3298C62.2372 16.3298 62.163 16.3298 62.0703 16.3114C64.0726 13.5042 66.0378 10.7153 68.0401 7.88967C66.2047 5.28425 64.3507 2.66047 62.4782 0C62.5894 0 62.6636 0 62.7377 0C63.6462 0 64.5546 0 65.4816 0C65.6299 0 65.7227 0.0550445 65.7968 0.165133C67.0204 1.9082 68.2441 3.65127 69.4862 5.39433C69.4862 5.48607 69.5418 5.54112 69.5975 5.63286Z"
          fill="white"
        />
        <path
          d="M57.0253 0.0195312C56.3764 0.955283 55.746 1.83599 55.1157 2.7167C53.9291 4.38637 52.7611 6.05605 51.5746 7.72572C51.4633 7.87251 51.4819 7.96425 51.5746 8.11103C53.4471 10.7348 55.3196 13.3769 57.1921 16.019C57.2663 16.1108 57.3219 16.2025 57.4146 16.331C57.3405 16.331 57.2663 16.3493 57.2107 16.3493C56.2837 16.3493 55.3382 16.3493 54.4112 16.3493C54.2629 16.3493 54.1702 16.2943 54.096 16.1658C52.7426 14.2576 51.3892 12.3494 50.0358 10.4412C49.9802 10.3678 49.9245 10.2945 49.8504 10.1844C49.7021 10.3862 49.5723 10.5697 49.4425 10.7532C48.1818 12.5513 46.9026 14.3494 45.6418 16.1475C45.5491 16.2943 45.4379 16.3493 45.2711 16.3493C44.3626 16.3493 43.4542 16.3493 42.5642 16.3493C42.4901 16.3493 42.4159 16.3493 42.3047 16.3493C42.3603 16.2576 42.3974 16.2025 42.4345 16.1475C44.3255 13.4687 46.2351 10.7898 48.1262 8.11103C48.2374 7.96425 48.2374 7.87251 48.1262 7.72572C46.3649 5.23038 44.5851 2.7167 42.8238 0.22136C42.7867 0.166316 42.7496 0.111272 42.694 0.0195312C42.8053 0.0195312 42.8794 0.0195312 42.9536 0.0195312C43.8435 0.0195312 44.7519 0.0195312 45.6418 0.0195312C45.8087 0.0195312 45.9014 0.0745755 45.9941 0.203012C47.2177 1.94608 48.4414 3.68915 49.6835 5.43221C49.7206 5.48726 49.7762 5.5423 49.8318 5.63404C49.8875 5.579 49.9245 5.52395 49.9616 5.48726C51.2038 3.72584 52.446 1.98277 53.6696 0.22136C53.7808 0.0745752 53.8921 0.0195312 54.0775 0.0195312C54.9674 0.0378793 55.8573 0.0195312 56.7472 0.0195312C56.8213 0.0195313 56.8955 0.0195312 57.0253 0.0195312Z"
          fill="white"
        />
        <path
          d="M25.0669 3.63272C24.4551 3.02723 23.8618 2.4401 23.25 1.83461C25.9568 -0.495595 30.8699 -0.880904 34.1885 2.31166C37.5813 5.55927 37.2661 10.5316 34.8374 13.3022C34.2441 12.715 33.6323 12.1095 33.0205 11.504C34.0216 10.1096 34.3553 8.55 33.966 6.86198C33.7064 5.7244 33.1132 4.7703 32.2418 3.99968C30.4249 2.38505 27.4215 1.96305 25.0669 3.63272Z"
          fill="white"
        />
        <path
          d="M21.6718 3.50391C22.2836 4.10939 22.8954 4.73323 23.4887 5.30202C23.3033 5.81576 23.0622 6.34786 22.9325 6.87995C22.1353 10.1826 24.3971 13.3568 27.8084 13.7421C28.995 13.8706 30.1074 13.6504 31.1641 13.0999C31.2939 13.0266 31.3681 13.0266 31.4793 13.1366C31.9984 13.6687 32.5361 14.1825 33.0737 14.7146C33.1108 14.7513 33.1479 14.8063 33.185 14.843C30.5338 16.8063 26.0657 17.0631 22.9139 14.2375C19.6509 11.3385 19.3914 6.62308 21.6718 3.50391Z"
          fill="white"
        />
        <path
          d="M11.5874 7.54106C12.3475 8.14654 12.8852 8.88047 13.2003 9.76118C14.2756 12.6785 12.2919 15.9078 9.15866 16.2931C8.93618 16.3114 8.69516 16.3298 8.47268 16.3298C5.72879 16.3298 2.98491 16.3298 0.241018 16.3298C0.166859 16.3298 0.0926989 16.3298 0 16.3298C0 10.8804 0 5.46773 0 0C0.0741591 0 0.166859 0 0.241018 0C2.87367 0 5.52486 0 8.15751 0C10.7716 0 12.8852 2.22012 12.6998 4.78885C12.6627 5.83469 12.2733 6.75209 11.5874 7.54106ZM2.63265 13.7611C2.72535 13.7611 2.78097 13.7611 2.85513 13.7611C4.72765 13.7611 6.58162 13.7611 8.45414 13.7611C8.56538 13.7611 8.67662 13.7427 8.78786 13.7427C10.0115 13.5959 10.957 12.5134 10.8829 11.3575C10.8087 10.0364 9.78901 9.0823 8.50976 9.0823C6.63724 9.0823 4.76473 9.0823 2.89221 9.0823C2.81805 9.0823 2.72535 9.0823 2.63265 9.0823C2.63265 10.6419 2.63265 12.2015 2.63265 13.7611ZM2.63265 6.47687C2.72535 6.47687 2.81805 6.49522 2.89221 6.49522C4.63495 6.49522 6.39623 6.49522 8.13897 6.49522C9.25135 6.49522 10.1227 5.63286 10.1227 4.55032C10.1227 3.44944 9.25135 2.58708 8.12043 2.58708C6.37769 2.58708 4.61641 2.58708 2.87367 2.58708C2.79951 2.58708 2.70681 2.58708 2.63265 2.58708C2.63265 3.88979 2.63265 5.17416 2.63265 6.47687Z"
          fill="white"
        />
      </svg>
    </div>
    <button
      #inputSearch
      class="expand-collapse-icon"
      (click)="toggleSidebar($event)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M9 18L15 12L9 6"
          stroke="#667085"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
    <div class="search-icon">
      <button *ngIf="!isExpanded" (click)="openSidebar($event)">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
        >
          <path
            d="M21 21.0488L17.5001 17.5488M20 11.5488C20 16.2432 16.1944 20.0488 11.5 20.0488C6.80558 20.0488 3 16.2432 3 11.5488C3 6.85441 6.80558 3.04883 11.5 3.04883C16.1944 3.04883 20 6.85441 20 11.5488Z"
            stroke="#667085"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <div class="search-input-container" *ngIf="isExpanded">
        <boxxds-textinput-v2
          [showClearButton]="true"
          (handleClearValue)="clearValue()"
          [form]="form"
          [inputAutoFocus]="focusInput"
          [placeholder]="
            'workFlow3.components.shared.placeholder.search' | translate
          "
          [label]="''"
          [control]="'search'"
          [id]="'search'"
          [leftIcon]="'assets/img/workflow3/sidemenu/search.png'"
          [width]="'184px'"
          (click)="stopMenuToggle($event)"
          (handleKeyUp)="onSearch($event)"
          [maxLength]="'40'"
        >
        </boxxds-textinput-v2>
      </div>
    </div>
    <div class="menu-container collapsed-menus">
      <div class="menu-item" *ngFor="let m of menu; let i = index">
        <div
          class="menu"
          (click)="stopMenuToggle($event); toggleSubMenu($event, i)"
        >
          <a
            class="menu-link"
            [ngClass]="{
              expanded: isExpanded,
              'active-menus': m.active && !!!form.value['search']
            }"
          >
            <img [src]="m.imgSrc || m.src" [alt]="m.name" />
            <span [ngClass]="{ 'expanded-menu-name': isExpanded }">{{
              m.name
            }}</span>
            <img
              class="expand-collapse"
              [ngClass]="{ 'active-menu': m.active }"
              *ngIf="isExpanded && m.submenu && m.submenu.length > 0"
              src="assets/img/workflow3/sidemenu/chevron-down.svg"
            />
          </a>
          <div
            class="submenus"
            *ngIf="isExpanded && m.submenu && m.submenu.length > 0 && m.active"
          >
            <div class="submenu-holder" *ngFor="let submenu of m.submenu">
              <a
                (click)="handleSubMenuNavigation(submenu, m)"
                [ngClass]="{ 'disabled-submenu': submenu?.disabled }"
              >
                <span
                  class="submenu"
                  [ngClass]="{
                    'filtered-submenu': form.value['search'] !== ''
                  }"
                  >{{ submenu.name }}</span
                >
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="popup-holder" *ngIf="isExpanded">
      <div *ngIf="isPopoverOpen" class="popover-content">
        <div class="d-flex align-items-start justify-content-between my-1 pt-2">
          <span class="heading"> {{ "copyrightInfo" | translate }}</span>
          <div class="close-btn justify-content-end align-items-end">
            <button (click)="closePopover($event)" aria-label="Close">
              <img src="assets/img/dashboard/card/close.png" />
            </button>
          </div>
        </div>
        <div class="d-flex align-items-start">
          <div class="reasons">
            {{ versionPopupContent | translate }}
          </div>
        </div>
      </div>
    </div>
    <a *ngIf="isExpanded" #inputSearch autofocus href="#"></a>
    <div
      class="bottom-menus"
      [ngClass]="{ 'collapsed-bottom-menu': !isExpanded }"
    >
      <div
        class="logout-container"
        [ngClass]="{ 'logout-visible': isExpanded }"
      >
        <a class="profile-link" (click)="handleProfileClick()">
          <ngx-avatars
            *ngIf="getName() !== ''"
            class="workflow3"
            [textSizeRatio]="1.5"
            [name]="getName()"
          ></ngx-avatars>
        </a>
        <div *ngIf="isExpanded">
          <button class="logout" (click)="handleShowLogoutPopup($event)">
            <img src="assets/img/workflow3/sidemenu/logout.svg" alt="logout" />
            <span class="logout-menu">{{ "Logout" | translate }}</span>
          </button>
        </div>
      </div>
      <img
        class="language-selector"
        [ngClass]="{ 'language-selector-expanded': isExpanded }"
        src="assets/img/workflow3/sidemenu/US.png"
        [title]="'workFlow3.components.sideMenu.english' | translate"
        alt="English (US)"
      />
    </div>
    <button
      type="button"
      class="version-text"
      *ngIf="isExpanded"
      (click)="openPopover()"
    >
      {{ releaseVersion }}
    </button>
  </div>
  <app-modal-exit-quoting-process
    *ngIf="showExitQuotingPopup"
    [showModal]="showExitQuotingPopup"
    (handleExit)="handleExitQuotingProcess()"
    (handleCancel)="handleCancelExiting()"
    (handleClose)="handleCancelExiting()"
  >
  </app-modal-exit-quoting-process>
  <app-modal-logout
    *ngIf="showLogoutPopup"
    [showModal]="showLogoutPopup"
    (handleLogout)="handleLogoutClick()"
    (handleCancel)="handleCancelExiting()"
    (handleClose)="handleCancelExiting()"
  >
  </app-modal-logout>
</div>
