<div class="holder">
  <boxxds-heading
    sz="5"
    [customStyle]="{ marginBottom: '12px' }"
    [text]="'submission.heading.submissionClearanceProcess' | translate"
    ><span></span>
  </boxxds-heading>
  <div class="insured-name">{{ insured }}</div>
  <div class="content">
    <img
      src="assets/img/alert-triangle.jpg"
      width="66px"
      height="66px"
      alt="alert"
    />
    <boxxds-body
      [text]="'restrictiveIndustries.cannotOfferQuoteMessage' | translate"
      sz="sm"
      [customStyle]="{
        color: '#616162',
        marginBottom: '60px',
        marginTop: '20px'
      }"
    >
    </boxxds-body>
    <boxxds-table
      [data]="data"
      [showPagination]="false"
      class="table"
      [isRowClickable]="false"
      id="restrictive-industry"
      [tableStyle]="{ marginBottom: '30px' }"
      [showEmptyCellIndicator]="true"
      [showPopOver]="true"
      [popOverColumn]="'Reasons'"
    >
    </boxxds-table>

    <div class="continue-btn-holder">
      <boxxds-button
        [buttonText]="'common.back' | translate"
        btnType="secondary"
        [btnStyle]="{ width: '88px' }"
        type="submit"
        (handleClick)="handleBackBtn()"
        class="back-btn"
      >
      </boxxds-button>
      <div class="save-continue">
        <boxxds-button
          [isDisabled]="isRestrictedIndustry"
          [buttonText]="'common.continue' | translate"
          btnType="secondary-solid"
          (handleClick)="!isRestrictedIndustry && handleContinue()"
          [btnStyle]="{ width: '199px' }"
          [class.noPointerEvents]="isRestrictedIndustry"
        >
        </boxxds-button>
      </div>
    </div>
  </div>
</div>
