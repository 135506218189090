import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BoxxResponse } from '../entities/boxx-response';
import { Policy } from '../entities/policy';
import { BaseService } from './base-services';

@Injectable({
  providedIn: 'root',
})
export class PolicyPeriodService extends BaseService<any> {
  private alertDataSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public alertData$: Observable<any> = this.alertDataSubject.asObservable();

  constructor(http: HttpClient) {
    const baseurl =
      environment.msPolicyBaseUrl + environment.policyPeriodPathUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  GetAllByPolicyId(
    policyId,
    page = 1,
    limit = 10,
    sort = '',
  ): Observable<BoxxResponse<any>> {
    return this.http
      .get<BoxxResponse<any>>(
        this.baseurl +
          '?policyId=' +
          policyId +
          '&page=' +
          page +
          '&limit=' +
          limit +
          '&active=1',
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  setAlertData(alertData: any) {
    this.alertDataSubject.next(alertData);
  }
}
