<div class="broker-modal" *ngIf="showCommissionModal">
  <div class="body-spinner" *ngIf="showSpinner">
    <boxxds-spinner
      [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
    ></boxxds-spinner>
  </div>
  <div class="modal-container">
    <div class="box">
      <span style="--i: 1"></span>
      <span style="--i: 2"></span>
      <span style="--i: 3"></span>
      <span style="--i: 4"></span>
      <span style="--i: 5"></span>
      <span style="--i: 6"></span>
      <span style="--i: 7"></span>
      <span style="--i: 8"></span>
      <span style="--i: 9"></span>
      <span style="--i: 10"></span>
    </div>
    <div class="header">
      <div class="icon">
        <img src="assets/img/alert-triangle.svg" />
      </div>
      <div class="heading">
        <boxxds-heading
          [sz]="'7'"
          [text]="headerText"
          type="bold"
        ></boxxds-heading>
      </div>
      <div class="close">
        <button (click)="handleCloseModal()">
          <img src="assets/img/close-v2.svg" />
        </button>
      </div>
    </div>
    <div class="broker-info">
      <div class="insured">
        <label>
          {{ "workFlow3.components.brokerCommissionPopup.insured" | translate }}
        </label>
        <p>{{ insured }}</p>
      </div>
      <div class="brokerage">
        <label>
          {{
            "workFlow3.components.brokerCommissionPopup.brokerage" | translate
          }}
        </label>
        <p>{{ brokerage }}</p>
      </div>
    </div>
    <div class="form" [formGroup]="formCommissionPopup">
      <div class="form-info">
        <p>
          {{
            "workFlow3.components.brokerCommissionPopup.selectTheProduct"
              | translate
          }}
        </p>
      </div>
      <div class="form-group">
        <div class="dropdowns">
          <boxxds-dropdown-v2
            [label]="
              'workFlow3.components.brokerCommissionPopup.product' | translate
            "
            [placeholder]="'placeholder.select' | translate"
            [options]="products"
            [isReadonly]="!isActive"
            [form]="formCommissionPopup"
            [control]="'productId'"
            [options]="products"
            class="input-fields"
            [requiredErrMsg]="
              'workFlow3.components.brokerCommissionPopup.error.requiredErrMsg'
                | translate
            "
            [formSubmitted]="submitted"
            (currentValueChange)="onChangeProduct($event)"
            [isDisabled]="!isActive"
            [isRequired]="true"
            (currentValueChange)="onChangeProduct($event)"
          >
          </boxxds-dropdown-v2>
          <boxxds-dropdown-v2
            [label]="
              'workFlow3.components.brokerCommissionPopup.region' | translate
            "
            [placeholder]="'placeholder.select' | translate"
            [options]="regions"
            [isReadonly]="
              !isActive || !productId || productId === '' || regions.length <= 1
            "
            [form]="formCommissionPopup"
            [control]="'regionId'"
            [options]="regions"
            class="input-fields"
            [requiredErrMsg]="
              'workFlow3.components.brokerCommissionPopup.error.requiredErrMsg'
                | translate
            "
            [formSubmitted]="submitted"
            [currentValue]="formCommissionPopup?.value?.regionId"
            (currentValueChange)="onChangeRegion($event)"
            [isDisabled]="
              !isActive || !productId || productId === '' || regions.length <= 1
            "
            [isRequired]="true"
            [dropdownListStyle]="{ maxHeight: '205px' }"
          >
          </boxxds-dropdown-v2>
        </div>
        <div class="stepper-inputs">
          <boxxds-stepper-input-v2
            [label]="'common.minRangePercentage' | translate"
            [control]="'minPercentage'"
            [isDisabled]="!isActive"
            [isRequired]="true"
            [isDoubleDigitInput]="true"
            [hasOuterBorder]="true"
            [isDoubleDigitInput]="true"
            [isDisabled]="!isActive"
            [isGridRowAlign]="true"
            [showEffects]="true"
            [max]="100"
            [value]="minPercentage"
            [formSubmitted]="submitted"
            [form]="formCommissionPopup"
            [inputStyle]="isActive && { background: 'transparent' }"
            (handleChange)="onChangeMinPercentage($event)"
            (handleKeyPress)="handleMinPercentageValidate($event)"
          >
          </boxxds-stepper-input-v2>
          <boxxds-stepper-input-v2
            [label]="'common.maxRangePercentage' | translate"
            [control]="'maxPercentage'"
            [isDisabled]="!isActive"
            [isRequired]="true"
            [hasOuterBorder]="true"
            [isGridRowAlign]="true"
            [isDoubleDigitInput]="true"
            [isDisabled]="!isActive"
            [max]="100"
            [formSubmitted]="submitted"
            [value]="maxPercentage"
            [form]="formCommissionPopup"
            (handleChange)="onChangeMaxPercentage($event)"
            (handleKeyPress)="handleMaxPercentageValidate($event)"
          >
          </boxxds-stepper-input-v2>
          <boxxds-stepper-input-v2
            [label]="'common.defaultPercentage' | translate"
            [control]="'defaultPercentage'"
            [isRequired]="true"
            [isDisabled]="!isActive"
            [hasOuterBorder]="true"
            [isGridRowAlign]="true"
            [isDoubleDigitInput]="true"
            [isDisabled]="!isActive"
            [min]="minPercentage ?? 0"
            [max]="maxPercentage ?? 100"
            [value]="defaultPercentage"
            [formSubmitted]="submitted"
            [form]="formCommissionPopup"
            (handleChange)="onChangeDefaultPercentage($event)"
            (handleKeyPress)="handleDefaultPercentageValidate($event)"
          >
          </boxxds-stepper-input-v2>
        </div>
        <div>
          <boxxds-switch-v2
            *ngIf="showEnableCommissionSwitch()"
            name="enable-commission"
            [label]="
              'workFlow3.components.producerInformationComponent.error.enableNewCommissions'
                | translate
            "
            [checked]="showEnableCommissionFlag"
            (changed)="switchChangeEnableCommission()"
          >
          </boxxds-switch-v2>
        </div>
      </div>
    </div>
    <div class="action-buttons">
      <div class="modal-buttons">
        <boxxds-button-v2
          [isDisabled]="!disableAddCommBtn"
          [buttonText]="
            'workFlow3.components.brokerCommissionPopup.addNewRegions'
              | translate
          "
          [size]="'md'"
          [btnClass]="'secondary gray'"
          [btnType]="'default'"
          (handleClick)="handleConfirmNoOverride($event)"
        >
        </boxxds-button-v2>
        <boxxds-button-v2
          [isDisabled]="!disableAddCommBtn"
          [buttonText]="
            'workFlow3.components.brokerCommissionPopup.overrideAndAddCommission'
              | translate
          "
          [type]="'submitted'"
          [size]="'md'"
          [btnClass]="'secondary gray'"
          [btnType]="'default'"
          (handleClick)="handleCommissionOverride($event)"
        >
        </boxxds-button-v2>
        <boxxds-button-v2
          [isDisabled]="
            disableAddCommBtn ||
            (showEnableCommissionSwitch() && !showEnableCommissionFlag)
          "
          [buttonText]="
            'workFlow3.components.brokerCommissionPopup.addCommission'
              | translate
          "
          [size]="'md'"
          [btnClass]="'primary'"
          [btnType]="'default'"
          [rightImage]="plusCircle"
          (handleClick)="handleSubmitBroker($event)"
        >
        </boxxds-button-v2>
      </div>
    </div>
  </div>
</div>
