<div class="info-container" *ngIf="details">
  <div class="info-header">
    <h1 class="head">{{ details.header }} information</h1>
    <p class="period">Current period: {{ details.currentPeriod }}</p>
  </div>
  <div class="information-container" [ngClass]="{ openSub: openInfo }">
    <div class="dropdown-icon" (click)="openSub()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M5 7.5L10 12.5L15 7.5"
          stroke="#98A2B3"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div class="Information">
      <div class="main">
        <div class="info">
          <h5 class="head">Insured</h5>
          <a
            class="insured-link"
            [routerLink]="getNavigationUrl(details.insuredId)"
            skipLocationChange="true"
            [ngStyle]="{ textDecoration: 'none' }"
          >
            <p class="content">{{ details.insuredName }}</p>
          </a>
        </div>
        <div class="info">
          <h5 class="head">Insured Revenue</h5>
          <p class="content">{{ details.revenue }}</p>
        </div>
        <div class="info">
          <h5 class="head">Insured Industry</h5>
          <p class="content">{{ details.industry }}</p>
        </div>
        <div class="info">
          <h5 class="head">First Bind Date</h5>
          <p class="content">{{ details.firstBindDate }}</p>
        </div>
        <div class="info">
          <h5 class="head">Policy period</h5>
          <p class="content">{{ details.policyPeriod }}</p>
        </div>
      </div>
      <div class="sub">
        <div class="info">
          <h5 class="head">Brokerage</h5>
          <p class="content">{{ details.brokerage }}</p>
        </div>
        <div class="info">
          <h5 class="head">Branch</h5>
          <p class="content">{{ details.branch }}</p>
        </div>
        <div class="info">
          <h5 class="head">Producer</h5>
          <div class="producer-section">
            <p class="content">{{ details.producer }}</p>
            <button
              *ngIf="isQuote"
              [disabled]="!hasWritePermission"
              [class.noPointerEvents]="!hasWritePermission"
              (click)="hasWritePermission && handleProducerChange()"
              class="producer-change-btn"
            >
              {{ "common.changeTxt" | translate }}
            </button>
          </div>
        </div>
        <div class="info">
          <h5 class="head">Risks</h5>
          <div class="product-content">
            <span class="product" *ngFor="let product of details.product">{{
              product.name
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
