<div class="custom-modal" *ngIf="showModal">
  <div class="modal-content custom-scroll">
    <div class="close-btn">
      <button (click)="handleCloseClk()">
        <img src="assets/img/dashboard/card/close.png" />Close
      </button>
    </div>

    <div class="header">
      <boxxds-body
        [text]="'Policy ID:' + ' ' + packagePolicyNumber"
        sz="xl"
        [customStyle]="{
          color: '#333334',
          height: '32px',
          marginRight: '40px'
        }"
      >
      </boxxds-body>
      <boxxds-status-badge
        [status]="status"
        [statusType]="getStatusType(status || '')"
      ></boxxds-status-badge>
    </div>
    <ng-container *ngIf="showBodySpinner">
      <div class="body-spinner">
        <boxxds-spinner
          [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
        ></boxxds-spinner>
      </div>
    </ng-container>
    <div class="content" *ngIf="!showBodySpinner">
      <div class="summary-content d-flex">
        <div class="left-col">
          <boxxds-heading
            [sz]="'44'"
            [text]="
              getTotalBilledAnnual() | currency: currency : 'symbol-narrow'
            "
            type="bold"
            [customStyle]="{ color: '#333334' }"
          >
          </boxxds-heading>
          <div class="border-content">
            <boxxds-body
              sz="xs"
              [text]="'quoteSummary.totalBilledAnnually' | translate"
              [customStyle]="{
                color: '#8F8F91',
                textAlign: 'left',
                textTransform: 'uppercase',
                marginBottom: '18px'
              }"
            >
            </boxxds-body>

            <div class="quote-optn">
              <boxxds-body
                sz="xs"
                [text]="
                  'Quote Option ' +
                  getSelectedQuote(quoteOptions)[0]?.optionNumber
                "
                type="bold"
                [customStyle]="{
                  color: '#8F8F91',
                  fontSize: '20px',
                  fontWeight: '600',
                  lineHeight: '32px',
                  textAlign: 'left',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
              <boxxds-body
                sz="xs"
                [text]="'quoteSummary.coverageDates' | translate"
                [customStyle]="{
                  color: '#8F8F91',
                  marginTop: '8px',
                  marginBottom: '8px',
                  textAlign: 'left',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
              <boxxds-body
                sz="md"
                [text]="
                  (effDate | date: 'MMMM dd, yyyy') +
                  ' - ' +
                  (expDate | date: 'MMMM dd, yyyy')
                "
                [customStyle]="{ color: '#333334', textAlign: 'left' }"
              >
              </boxxds-body>
            </div>
            <div class="amt-details d-flex">
              <boxxds-body
                sz="xs"
                [text]="'quoteSummary.subtotal' | translate"
                [customStyle]="{
                  color: '#8F8F91',
                  textAlign: 'left',
                  width: '60px',
                  marginRight: '8px',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
              <boxxds-body
                sz="lg"
                [text]="getSubTotal() | currency: currency : 'symbol-narrow'"
                [customStyle]="{
                  color: '#333334',
                  textAlign: 'left',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
            </div>
            <div class="amt-details d-flex">
              <boxxds-body
                sz="xs"
                [text]="'quoteSummary.services' | translate"
                [customStyle]="{
                  color: '#8F8F91',
                  textAlign: 'left',
                  width: '60px',
                  marginRight: '8px',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
              <boxxds-body
                sz="lg"
                [text]="getServices() | currency: currency : 'symbol-narrow'"
                [customStyle]="{
                  color: '#333334',
                  textAlign: 'left',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
            </div>
            <div class="amt-details d-flex">
              <boxxds-body
                sz="xs"
                [text]="'common.tria' | translate"
                [customStyle]="{
                  color: '#8F8F91',
                  textAlign: 'left',
                  width: '60px',
                  marginRight: '8px',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
              <boxxds-body
                sz="lg"
                [text]="getTriaAmount() | currency: currency : 'symbol-narrow'"
                [customStyle]="{
                  color: '#333334',
                  textAlign: 'left',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
            </div>
            <div class="amt-details d-flex">
              <boxxds-body
                sz="xs"
                [text]="'quoteSummary.policyFees' | translate"
                [customStyle]="{
                  color: '#8F8F91',
                  textAlign: 'left',
                  width: '60px',
                  marginRight: '8px',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
              <boxxds-body
                sz="lg"
                [text]="getPolicyFee() | currency: currency : 'symbol-narrow'"
                [customStyle]="{
                  color: '#333334',
                  textAlign: 'left',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
            </div>
            <div class="amt-details d-flex">
              <boxxds-body
                sz="xs"
                [text]="'quoteSummary.taxes' | translate"
                [customStyle]="{
                  color: '#8F8F91',
                  textAlign: 'left',
                  width: '60px',
                  marginRight: '8px',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
              <boxxds-body
                sz="lg"
                [text]="getTaxes() | currency: currency : 'symbol-narrow'"
                [customStyle]="{
                  color: '#333334',
                  textAlign: 'left',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
            </div>
          </div>
        </div>
        <div class="right-col">
          <app-product-detail-accordion
            [policyPeriodId]="policyPeriodId"
            [productList]="products"
            [policyRiskId]="policyRiskId"
            [quoteOptions]="quoteOptions"
            [products]="products"
            [underWriters]="underWriters"
            [policyRiskIds]="policyRiskIds"
            [riskTrnxs]="riskTrnxs"
            [productsIds]="productsIds"
            [riskId]="riskId"
            [regionId]="regionId"
          >
          </app-product-detail-accordion>
        </div>
      </div>
    </div>
  </div>
</div>
