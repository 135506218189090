import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import moment from 'moment';
import { formatDate } from 'src/app/utils/formatDate';
import {
  handleApiError,
  isSuccessApiStatus,
} from 'src/app/dashboard/utils/lifecycle-utils';
import {
  PolicyCancelRequest,
  PolicyChangeResponse,
  PolicyReturnPremiumCancelledRequest,
} from 'src/app/entities/policy-lifecycle';
import { PolicyLifecycleService } from 'src/app/services/policy-lifecycle-service';
import { TranslateService } from '@ngx-translate/core';
import { Store, select } from '@ngrx/store';
import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import { take } from 'rxjs';

@Component({
  selector: 'app-policy-cancel-popup',
  templateUrl: './policy-cancel-popup.component.html',
  styleUrls: ['./policy-cancel-popup.component.less'],
})
export class PolicyCancelPopupComponent implements OnInit {
  @Input() showModal: boolean = false;
  @Input() details;

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleActionConfirm = new EventEmitter<PolicyChangeResponse>();

  private results: PolicyCancelRequest = this.createDefaultRequest();
  private shortRate: string = 'ShortRate';

  cancelPolicyForm: FormGroup;
  buttonDisabled: boolean = true;
  showCancelConfirm: boolean = false;
  showCancellationFee: boolean = false;

  isReasonValid: boolean = false;
  isTypeValid: boolean = false;
  isDateValid: boolean = false;
  isValidCancellationFee: boolean = false;

  errorMessage: string = '';
  minDate: Date;
  returnPremium: string = '0';
  cancellationFee: string = '0';
  cancellationDate: string = '0';

  currency = '';
  shortDateFormat: string = '';
  longDateFormat: string = '';

  constructor(
    private fb: FormBuilder,
    private policyLifecycleService: PolicyLifecycleService,
    private translate: TranslateService,
    private store: Store,
  ) {
    this.cancelPolicyForm = this.fb.group({
      reason: [''],
      date: [''],
      type: [''],
      cancellationFee: [''],
    });

    this.minDate = moment().add(1, 'days').toDate();
  }

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));
  }

  closeHandler() {
    this.handleClose.emit();
    this.resetForm();
  }

  reasonHandler(value: number) {
    this.isReasonValid = value && value > 0;

    if (this.isReasonValid) {
      this.results.cancellationReason = Number(value);
    }

    this.isFormValid();
  }

  handleCancelDate(value) {
    const date: Date = new Date(value);
    this.isDateValid = !isNaN(date.getDate());

    if (this.isDateValid) {
      this.results.cancellationDate = date;
    }

    this.isFormValid();
  }

  handleCancelType(event) {
    const value: string = event.target.value;
    this.isTypeValid = !(!value || !value.length);

    if (this.isTypeValid) {
      this.results.cancellationType = value;

      if (value == this.shortRate) {
        this.showCancellationFee = true;
        this.isValidCancellationFee = false;
      } else {
        this.showCancellationFee = false;
        this.isValidCancellationFee = true;
        this.results.cancellationPenalty = 0;
        this.cancelPolicyForm.get('cancellationFee').setValue('');
      }
    }

    this.isFormValid();
  }

  handleCancellationFeeType(event) {
    const value: number = parseFloat(
      event.target.value?.replace('$', '').split(',').join(''),
    );
    this.isValidCancellationFee = value > 0;

    if (this.isValidCancellationFee) {
      this.results.cancellationPenalty = value;
    }

    this.isFormValid();
  }

  handleContinue(event) {
    this.errorMessage = '';

    if (!this.isFormValid()) {
      return;
    }

    const request: PolicyReturnPremiumCancelledRequest = {
      policyPeriodId: this.details.policyPeriodId,
      cancellationDate: this.results.cancellationDate,
      cancellationType: this.results.cancellationType,
      cancellationPenalty: this.results.cancellationPenalty,
    };

    this.policyLifecycleService
      .PolicyReturnPremiumCancelledPolicy(this.details.userId, request)
      .subscribe({
        next: (response) => {
          this.returnPremium = response.data.returnPremium;
          this.cancellationFee = this.results.cancellationPenalty?.toString();
          this.cancellationDate = formatDate(
            this.results.cancellationDate,
            this.longDateFormat,
          );
          this.showCancelConfirm = true;
        },
        error: (error) => {
          this.resetConfimValues();
          this.errorMessage = handleApiError(error);
        },
      });
  }

  handleConfirm(event) {
    if (!this.isFormValid()) {
      return;
    }

    const request: PolicyCancelRequest = {
      policyPeriodId: this.details.policyPeriodId,
      cancellationDate: this.results.cancellationDate,
      cancellationType: this.results.cancellationType,
      cancellationReason: this.results.cancellationReason,
      cancellationPenalty: this.results.cancellationPenalty,
    };

    this.policyLifecycleService
      .PolicyCancel(this.details.userId, request)
      .subscribe({
        next: (response) => {
          let result: PolicyChangeResponse = undefined;

          if (isSuccessApiStatus(response.status)) {
            result = {
              success: true,
              message: this.translate.instant(
                'Policy was successfully cancelled',
              ),
            };
          } else {
            const result: PolicyChangeResponse = {
              success: false,
              message:
                this.translate.instant('Error cancelling policy:') +
                response.code.toString(),
            };
          }

          this.handleActionConfirm.emit(result);
          this.resetForm();
        },
        error: (error) => {
          const result: PolicyChangeResponse = {
            success: false,
            message: handleApiError(error),
          };

          this.handleActionConfirm.emit(result);
          this.resetForm();
        },
      });
  }

  private isFormValid(): boolean {
    const isValid =
      this.isDateValid &&
      this.isReasonValid &&
      this.isTypeValid &&
      this.isValidCancellationFee;
    this.buttonDisabled = !isValid;

    return isValid;
  }

  private resetForm() {
    this.buttonDisabled = true;
    this.showCancelConfirm = false;
    this.showCancellationFee = false;
    this.isDateValid = false;
    this.isReasonValid = false;
    this.isTypeValid = false;
    this.isValidCancellationFee = false;
    this.cancelPolicyForm.reset();

    this.resetConfimValues();

    this.results = this.createDefaultRequest();
  }

  private resetConfimValues() {
    this.returnPremium = '0';
    this.cancellationFee = '0';
    this.cancellationDate = '';
  }

  private createDefaultRequest(): PolicyCancelRequest {
    let request: PolicyCancelRequest = {
      policyPeriodId: 0,
      cancellationDate: undefined,
      cancellationType: '',
      cancellationReason: 0,
      cancellationPenalty: 0,
    };

    return request;
  }
}
