import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardHomeComponent } from './dashboard/dashboard-home/dashboard-home.component';
import { SearchResultComponent } from './dashboard/search-result/search-result.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BrokerageDirectoryComponent } from './dashboard/brokerage/brokerage-directory/brokerage-directory.component';
import { NewBranchComponent } from './pages/branch/new-branch/new-branch.component';
import { NewProducerComponent } from './pages/branch/new-producer/new-producer.component';
import { ViewBranchComponent } from './pages/branch/view-branch/view-branch.component';
import { NewBrokerageComponent } from './pages/brokerage/new-brokerage/new-brokerage.component';
import { ViewBrokerageComponent } from './pages/brokerage/view-brokerage/view-brokerage.component';
import { PolicyGridComponent } from './dashboard/policy-grid/policy-grid.component';
import { ProducerProfileComponent } from './dashboard/brokerage/producer-profile/producer-profile.component';
import { SecurityRoleComponent } from './dashboard/security/roles/roles.component';
import { QuotesGridComponent } from './dashboard/quotes-grid/quotes-grid.component';
import { BoxxUserDirectoryComponent } from './dashboard/boxx-user-directory/boxx-user-directory.component';
import { SystemUserDirectoryComponent } from './dashboard/system-user-directory/system-user-directory.component';
import { NewSystemUserComponent } from './dashboard/system-user-directory/new-system-user/new-system-user.component';
import { NewBoxxUserComponent } from './dashboard/boxx-user-directory/new-boxx-user/new-boxx-user.component';
import { CognitoComponent } from './cognito/cognito.component';
import { CognitoGuard } from './guard/cognito.guard';
import { UnauthorizedComponent } from './auth/unauthorized/unauthorized.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { NewPermissionSystemUserComponent } from './dashboard/security/new-system-user/new-system-user.component';
import { RoleDetailsComponent } from './dashboard/security/role-details/role-details.component';
import { PermissionsComponent } from './dashboard/security/permissions/permissions.component';
import { PolicyLifecycleComponent } from './dashboard/policy-lifecycle/policy-lifecycle.component';
import { NewRoleComponent } from './dashboard/security/new-role/new-role.component';
import { NewPermissionComponent } from './dashboard/security/new-permission/new-permission.component';
import { EditProducerComponent } from './pages/branch/edit-producer/edit-producer.component';
import { EditRoleComponent } from './dashboard/security/edit-role/edit-role.component';
import { ComingSoonComponent } from './auth/coming-soon/coming-soon.component';
import { EditBoxxUserComponent } from './dashboard/boxx-user-directory/edit-boxx-user/edit-boxx-user.component';
import { EditSystemUserComponent } from './dashboard/system-user-directory/edit-system-user/edit-system-user.component';
import { EditPermissionComponent } from './dashboard/security/edit-permission/edit-permission.component';

import { LogoutGuard } from './guard/logout.guard';
import { InsuredComponent } from './dashboard/insured/insured.component';
import { InsuredDirectoryComponent } from './dashboard/insured/insured-directory/insured-directory.component';
import { InsuredFormComponent } from './dashboard/insured/insured-form/insured-form.component';
import { Cognito4FlowComponent } from './cognito4Flow/cognito4Flow.component';
import { Cognito7FlowComponent } from './cognito7-flow/cognito7-flow.component';
import { Workflow3Component } from './workflow3/workflow3.component';
import { QuotesGridV2Component } from './quote-workflow3/components/quotes-grid-v2/quotes-grid-v2.component';
import { PolicyDirectoryComponent } from './quote-workflow3/policy-directory/policy-directory.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { MaintenanceGuard } from './guard/maintenance.guard';
// import { SearchResultV2Component } from './quote-workflow3/components/search-result-v2/search-result-v2.component';
import { PolicyLifecycleV2Component } from './quote-workflow3/policy-lifecycle-v2/policy-lifecycle-v2.component';
import { DashboardHomeV2Component } from './dashboard/dashboard-home-v2/dashboard-home-v2.component';

const routes: Routes = [
  { path: 'maintenance', component: MaintenanceComponent },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    canActivate: [LogoutGuard],
  },
  { path: 'logout', component: LogoutComponent, canActivate: [LogoutGuard] },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: { breadcrumb: 'dashboard' },
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        component: DashboardHomeV2Component,
        canActivate: [CognitoGuard, MaintenanceGuard],
      },
      {
        path: 'search/:type/:key/:id',
        component: SearchResultComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'brokerage',
        component: BrokerageDirectoryComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'brokerage/new',
        component: NewBrokerageComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'brokerage/view/:brokerageId',
        component: ViewBrokerageComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'branch/new/:brokerageId',
        component: NewBranchComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'branch/view/:branchId/:brokerageId',
        component: ViewBranchComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'branch/new-producer/:branchId/:brokerageId',
        component: NewProducerComponent,
        data: { breadcrumb: 'new producer' },
        canActivate: [CognitoGuard],
      },
      {
        path: 'branch/edit-producer/:brokerageId/:branchId/:producerId',
        component: EditProducerComponent,
        data: { breadcrumb: 'Edit Producer' },
        canActivate: [CognitoGuard],
      },
      {
        path: 'branch/profile/producer/:producerId',
        component: ProducerProfileComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'boxx-user-directory',
        component: BoxxUserDirectoryComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'boxx-user-directory/new',
        component: NewBoxxUserComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'boxx-user-directory/edit/:id',
        component: EditBoxxUserComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'system-user-directory',
        component: SystemUserDirectoryComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'security/roles',
        component: SecurityRoleComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'security/role-details',
        component: RoleDetailsComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'security/new-system-user',
        component: NewPermissionSystemUserComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'security/new-permission',
        component: NewPermissionComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'security/permissions',
        component: PermissionsComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'security/roles/new',
        component: NewRoleComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'security/roles/:roleId',
        component: RoleDetailsComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'security/roles/edit/:roleId',
        component: EditRoleComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'security/permissions/new',
        component: NewPermissionComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'security/permissions/edit/:id',
        component: EditPermissionComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'security/new-role',
        component: NewRoleComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'security/user/:id/type/:type',
        component: EditSystemUserComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'system-user-directory',
        component: SystemUserDirectoryComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'system-user-directory/new',
        component: NewSystemUserComponent,
        canActivate: [CognitoGuard],
      },

      {
        path: 'policy/:id',
        component: PolicyLifecycleComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'policies',
        component: PolicyGridComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'quotes',
        component: QuotesGridComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'workflow3/quotes',
        component: QuotesGridV2Component,
        canActivate: [CognitoGuard],
      },
      {
        path: 'workflow3/policies',
        component: PolicyDirectoryComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'workflow3/policies/:id',
        component: PolicyLifecycleV2Component,
        canActivate: [CognitoGuard],
      },
      {
        path: 'coming-soon',
        component: ComingSoonComponent,
        canActivate: [CognitoGuard],
      },
      {
        path: 'insured',
        component: InsuredComponent,
        canActivate: [CognitoGuard],
        children: [
          {
            path: 'directory',
            component: InsuredDirectoryComponent,
            canActivate: [CognitoGuard],
          },
          {
            path: 'form',
            component: InsuredFormComponent,
            canActivate: [CognitoGuard],
          },
          {
            path: 'form/:id',
            component: InsuredFormComponent,
            canActivate: [CognitoGuard],
          },
          {
            path: 'form/:id/:type',
            component: InsuredFormComponent,
            canActivate: [CognitoGuard],
          },
        ],
      },
      {
        path: 'quote',
        loadChildren: () =>
          import('./quote/quote.module').then((m) => m.QuoteModule),
      },
      {
        path: 'quote4flow',
        loadChildren: () =>
          import('./quote4-flow/quote4-flow.module').then(
            (m) => m.Quote4FlowModule,
          ),
      },
      {
        path: 'workflow3',
        loadChildren: () =>
          import('./quote-workflow3/quote-workflow3.module').then(
            (m) => m.QuoteWorkflow3Module,
          ),
      },
    ],
  },
  // {
  //   path: 'dashboard/home',
  //   component: DashboardHomeV2Component,
  //   canActivate: [CognitoGuard, MaintenanceGuard],
  // },
  { path: 'cognito', component: CognitoComponent },
  { path: 'cognito4flow', component: Cognito4FlowComponent },
  { path: 'cognito7flow', component: Cognito7FlowComponent },
  { path: 'workflow3', component: Workflow3Component },
  { path: '**', redirectTo: '/dashboard/home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
