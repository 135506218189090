<div class="holder">
  <boxxds-alert
    *ngIf="showErrorAlert"
    (handleCloseEvent)="handleCloseSuccessEvent()"
    type="error"
    headerText="error!"
    [bodyText]="msgErrorAlert"
  >
  </boxxds-alert>
  <div class="heading-holder">
    <boxxds-heading sz="5" [text]="'Brokerage directory' | translate"
      ><span></span
    ></boxxds-heading>
    <ng-container
      *ngIf="
        this.permissionList[this.currentScreen] &&
        this.permissionList['Brokerage Directory']
      "
    >
      <boxxds-button
        [buttonText]="'Add new brokerage' | translate"
        sz="lg"
        class="new-brokerage-btn"
        (handleClick)="handleAddNewBrokerage()"
      >
      </boxxds-button>
    </ng-container>
  </div>
  <label class="checkbox-header">{{ ("SEARCH BY" | translate) + ":" }}</label>
  <boxxds-checkbox
    [options]="searchOptions"
    (handleCheckbox)="handleCheckbox($event, searchOptions)"
    [containerStyle]="{ display: 'flex' }"
  >
  </boxxds-checkbox>
  <boxxds-textinput
    [form]="form"
    [placeholder]="'Search directory' | translate"
    [control]="'searchKey'"
    [id]="'searchKey'"
    [leftIcon]="'assets/img/dashboard/search/search.png'"
    maxLength="45"
    [containerStyle]="{ marginBottom: '28px', marginTop: '12px' }"
    (handleKeyUp)="handleSearch($event)"
    [readonly]="isSearchInpReadOnly()"
  >
  </boxxds-textinput>
  <div class="sort-container">
    <boxxds-button
      [buttonText]="'Export to excel' | translate"
      btnType="secondary"
      class="export-excel"
      (handleClick)="handleExportExcel()"
    >
    </boxxds-button>
    <div class="quick-sort" *ngIf="!isSearchResult">
      <label class="quick-sort-header">{{ "QUICK SORTS" | translate }}</label>
      <div class="sort-btns">
        <button
          #sortBtnRef
          class="sort-button"
          [ngClass]="{ active: sortBtn.active }"
          *ngFor="let sortBtn of quickSort; let i = index"
          (click)="handleQuickSort(i, sortBtnRef)"
        >
          {{ sortBtn.name }}
        </button>
      </div>
    </div>
  </div>
  <boxxds-table
    [data]="data"
    [totalCount]="totalDataCount"
    (pageChanged)="handlePageChange($event)"
    class="table"
    (sortUpClicked)="handleAscSort($event)"
    (sortDownClicked)="handleDescSort($event)"
    id="brokerage-table"
    addCopyBtnToField="Email"
    [addSortTo]="addSortTo"
    [styleInactive]="true"
    [linkDetails]="[{ name: 'View' }]"
    [showLinks]="true"
    [showSpinner]="showTblSpinner"
    [tableStyle]="{ marginBottom: isSearchResult ? '58px' : '27.5px' }"
    [activePageNumber]="pageNumber"
  >
  </boxxds-table>
</div>
