import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs';
import { Pagination } from 'src/app/entities/boxx-response';
import { PolicyPeriodService } from 'src/app/services/policy-period.service';
import { PolicyQuoteAuditService } from 'src/app/services/policy-quote-audit.service';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { formatDate, formatDateMoment } from 'src/app/utils/formatDate';

@Component({
  selector: 'app-slideout-history-v2',
  templateUrl: './slideout-history-v2.component.html',
  styleUrls: ['./slideout-history-v2.component.less'],
})
export class SlideoutHistoryV2Component implements OnInit {
  constructor(
    private policyPeriodService: PolicyPeriodService,
    private store: Store,
    private policyQuoteAuditService: PolicyQuoteAuditService,
  ) {}

  @Input() policyId: number;
  @Input() policyRiskTrxId: number;
  @Input() policyNumber: string;
  @Input() isPolicy: boolean = false;

  @Output() handleLoading = new EventEmitter();

  pastPolicies = [];
  auditDetails = [];
  totalCount: number = 0;
  totalPages: number = 1;
  currentPage: number = 1;
  itemsPerPage = 10;
  activityData: { pagination: Pagination | null } = {
    pagination: null,
  };
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  shortDateFormat: string = '';
  longDateFormat: string = '';
  showModalSpinner: boolean = true;
  pastPoliciesLoading: boolean = true;

  async ngOnInit(): Promise<void> {
    this.isPolicy && this.populatePastPolicies();
    await this.populateActivityLog(this.currentPage).then(() => {
      this.handleLoaderEmitter(false);
    });

    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
  }

  private populatePastPolicies() {
    if (!this.policyId) {
      this.pastPoliciesLoading = false;
      return;
    }

    this.policyPeriodService
      .GetAllByPolicyId(this.policyId)
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.pastPolicies = response.data?.map((policy) => ({
            policyNumber: this.policyNumber,
            period:
              formatDateMoment(policy?.effectiveDt, this.shortDateFormat) +
              ' - ' +
              formatDateMoment(policy?.expiryDt, this.shortDateFormat),
          }));
          this.pastPoliciesLoading = false;
        },
        error: (error) => {
          this.pastPoliciesLoading = false;
        },
      });
  }

  async populateActivityLog(pageNumber: number) {
    if (!this.policyRiskTrxId) {
      this.showModalSpinner = false;
      return;
    }
    this.showModalSpinner = true;
    this.policyQuoteAuditService
      .getPolicyQuoteAuditData(
        ['PolicyPeriod', 'PolicyRisk', 'PolicyRiskTrx'],
        this.policyRiskTrxId,
        pageNumber,
        this.itemsPerPage,
      )
      .subscribe({
        next: (response) => {
          ({ pagination: this.activityData.pagination } = response);
          this.auditDetails = response.data?.map((audit) => ({
            user: audit?.createdBy,
            type: audit?.auditType,
            changeFrom: audit?.auditChangeFrom,
            changeTo: audit?.auditChangeTo,
            date: formatDate(audit?.createdDt, this.longDateFormat),
            table: audit?.auditTable,
            tableDomain: audit?.auditTableDomain,
          }));
          this.showModalSpinner = false;
        },
        error: (error) => {
          this.showModalSpinner = false;
        },
      });

    this.totalPages = this.activityData.pagination?.totalPages;
    this.currentPage = pageNumber;
    this.totalCount = this.activityData.pagination?.totalRecords;
  }

  getConnectionString(table, type, user) {
    switch (type) {
      case 'create':
        return table + ' Created by ' + user;
      case 'update':
        return table + ' Updated by ' + user;
      case 'delete':
        return table + ' Deleted by ' + user;
      default:
        return '';
    }
  }

  getFilteredData(details: string) {
    let filterChecks = ['null', ' null'];
    let filteredDetails = '';
    let comaSeparatedData = details.split(',');
    let filteredComaSeparatedData = [];

    if (comaSeparatedData.length > 0) {
      comaSeparatedData.map((data: string) => {
        if (!data.includes('null')) {
          filteredComaSeparatedData.push(data);
        } else {
          let keyValuePair = data.split(':');
          if (keyValuePair.length > 0) {
            if (!filterChecks.includes(keyValuePair[1])) {
              filteredComaSeparatedData.push(data);
            }
          }
        }
      });
    }
    filteredDetails = filteredComaSeparatedData.join(', ');
    return filteredDetails;
  }

  handleLoaderEmitter(isLoading: boolean): void {
    this.handleLoading.emit({ isLoading });
  }
}
