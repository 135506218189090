<div [ngStyle]="customStyle">
  <boxxds-body
    sz="sm"
    [text]="label"
    [customStyle]="{ color: '#56565C', marginBottom: '8px', marginLeft: '8px' }"
  ></boxxds-body>
  <div class="d-flex">
    <boxxds-stepper-input
      [max]="max"
      [inputStyle]="{ background: 'transparent' }"
      mask="separator.0"
      thousandSeparator=","
      prefix="$"
      [min]="min"
      [isPercentageMode]="false"
      [step]="step"
      [form]="form"
      [control]="minControl"
      [formSubmitted]="formSubmitted"
      [customErrMsg]="'common.invalidErrorMessage' | translate"
      [value]="form.controls[minControl].value"
      (handleChange)="handleMinValueChange($event)"
    ></boxxds-stepper-input>
    <boxxds-body
      sz="xs"
      [text]="'to'"
      [customStyle]="{
        color: '#8F8F91',
        marginRight: '14px',
        marginLeft: '14px',
        lineHeight: '14px',
        marginTop: '10px'
      }"
    ></boxxds-body>
    <boxxds-stepper-input
      [max]="max"
      [inputStyle]="{ background: 'transparent' }"
      mask="separator.0"
      thousandSeparator=","
      prefix="$"
      [min]="min"
      [isPercentageMode]="false"
      [form]="form"
      [control]="maxControl"
      [value]="form.controls[maxControl].value"
      [step]="step"
      [formSubmitted]="formSubmitted"
      [customErrMsg]="'common.invalidErrorMessage' | translate"
      (handleChange)="handleMaxValueChange($event)"
    ></boxxds-stepper-input>
  </div>
  <boxxds-body
    sz="xs"
    [text]="'Range limits: ' + min + ' to ' + (max | abbreviateNumber: 2)"
    [customStyle]="{
      color: '#8F8F91',
      marginTop: '8px',
      marginLeft: '8px',
      lineHeight: '14px'
    }"
  ></boxxds-body>
</div>
