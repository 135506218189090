import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-commission-confirmation-v2',
  templateUrl: './modal-commission-confirmation-v2.component.html',
  styleUrls: ['./modal-commission-confirmation-v2.component.less'],
})
export class ModalCommissionConfirmationV2Component {
  @Input() showModal: boolean = false;
  @Input() commissionData;

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleSubmitCommissionOverride = new EventEmitter<any>();

  constructor() {}

  closeHandler() {
    this.handleClose.emit();
  }

  actionConfirmNoOverride(event) {
    this.commissionData.override = false;
    this.handleSubmitCommissionOverride.emit(this.commissionData);
  }
  actionConfirmOverride(event) {
    this.commissionData.override = true;
    this.handleSubmitCommissionOverride.emit(this.commissionData);
  }
}
