<div class="custom-modal" *ngIf="showModal">
  <div class="modal-content">
    <div class="close-btn">
      <button (click)="closeHandler()">
        <img src="assets/img/dashboard/card/close.png" />{{
          "common.close" | translate
        }}
      </button>
    </div>
    <div class="content">
      <div class="icont-title d-flex flex-column align-items-center">
        <img
          src="assets/img/alert-triangle.jpg"
          width="66px"
          height="66px"
          alt="alert"
        />
        <boxxds-body
          sz="md"
          [text]="
            'brokerage.commission.createAndUpdateCommissionMultiRegionText'
              | translate
          "
          [customStyle]="{ color: '#FE802C' }"
        ></boxxds-body>
      </div>
      <div class="modal-description">
        <boxxds-body
          sz="sm"
          [text]="
            'brokerage.commission.multiRegionCommissionConfirmationText'
              | translate
          "
          [customStyle]="{ color: '#333334', width: '353px' }"
        ></boxxds-body>
      </div>
      <div class="content-separator"></div>
      <div class="modal-buttons">
        <a class="cancel-link" href="javascript:;" (click)="closeHandler()">
          {{ "common.cancel" | translate }}
        </a>
        <boxxds-button
          [buttonText]="'brokerage.commission.onlyAddNewRegion' | translate"
          [type]="'submitted'"
          btnType="secondary"
          [btnStyle]="{ width: '194', padding: '0px 25px' }"
          (handleClick)="actionConfirmNoOverride($event)"
        >
        </boxxds-button>
        <boxxds-button
          [buttonText]="'brokerage.commission.overrrideAndAdd' | translate"
          [type]="'submitted'"
          btnType="primary"
          [btnStyle]="{
            width: '175px',
            padding: '0px 25px',
            marginLeft: '20px'
          }"
          (handleClick)="actionConfirmOverride($event)"
        >
        </boxxds-button>
      </div>
    </div>
  </div>
</div>
