import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BoxxResponse } from '../entities/boxx-response';
import { Policy } from '../entities/policy';
import { BaseService } from './base-services';

@Injectable({
  providedIn: 'root',
})
export class PolicyDashboardService extends BaseService<Policy> {
  constructor(http: HttpClient) {
    const baseurl = environment.msPolicyBaseUrl + environment.dashboardPathUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  GetAllByPolicyNumber(
    pkgPolicyNumber,
    page = 1,
    limit = 10,
    sort = '',
  ): Observable<BoxxResponse<Policy>> {
    return this.http
      .get<BoxxResponse<Policy>>(
        this.baseurl +
          '?pkgPolicyNumber=' +
          pkgPolicyNumber +
          '&page=' +
          page +
          '&limit=' +
          limit +
          '&active=1',
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetAllByInsuredName(
    insuredName,
    page = 1,
    limit = 10,
    sort = '',
  ): Observable<BoxxResponse<Policy>> {
    let url =
      this.baseurl +
      '?insuredName=' +
      insuredName +
      '&page=' +
      page +
      '&limit=' +
      limit +
      '&active=1';
    if (sort) {
      url += '&sort=' + sort;
    }
    return this.http
      .get<BoxxResponse<Policy>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetAllByUnderwriter(
    underwriterId,
    page = 1,
    limit = 10,
    sort = '',
  ): Observable<BoxxResponse<Policy>> {
    return this.http
      .get<BoxxResponse<Policy>>(
        this.baseurl +
          '?underwriterId=' +
          underwriterId +
          '&page=' +
          page +
          '&limit=' +
          limit +
          '&isDashboard=1' +
          '&active=1',
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetAllByInsuredId(
    insuredId,
    page = 1,
    limit = 10,
    sort = '',
  ): Observable<BoxxResponse<Policy>> {
    let url =
      this.baseurl +
      '?insuredId=' +
      insuredId +
      '&page=' +
      page +
      '&limit=' +
      limit +
      '&active=1';
    if (sort) {
      url += '&sort=' + sort;
    }
    return this.http
      .get<BoxxResponse<Policy>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetAllPoliciesByInsuredId(
    insuredId,
    policyStatus,
    page = 1,
    limit = 10,
    sort = '',
  ): Observable<BoxxResponse<Policy>> {
    return this.http
      .get<BoxxResponse<Policy>>(
        this.baseurl +
          '?insuredId=' +
          insuredId +
          '&policyStatus=' +
          policyStatus +
          '&page=' +
          page +
          '&limit=' +
          limit,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetAllByQuote(
    insuredType,
    page = 1,
    limit = 10,
    sort = '',
    quoteStatus = undefined,
  ): Observable<BoxxResponse<Policy>> {
    let url =
      this.baseurl +
      '?insuredType=' +
      insuredType +
      '&page=' +
      page +
      '&limit=' +
      limit +
      '&active=1';
    if (quoteStatus) {
      url += '&quoteStatus=' + quoteStatus;
    }
    if (insuredType === 2) {
      url += '&policyStatus=QUOTE';
    } else {
      url += '&policyStatus=BOUND';
    }
    return this.http
      .get<BoxxResponse<Policy>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetAllQuoteByFilter(filters): Observable<BoxxResponse<Policy>> {
    let {
      insuredType = -1,
      filter,
      page = 1,
      limit = 10,
      sort = '',
      entryType = undefined,
      isDashboardCount = undefined,
      isTeamsQueue = false,
    } = filters;
    let url = `${
      this.baseurl
    }?page=${page}&limit=${limit}&active=1&isTeamsQueue=${
      isTeamsQueue ? 1 : 0
    }`;

    if (isDashboardCount) {
      url += `&isDashboardCount=1`;
    }

    if (entryType === undefined) {
      url = `${url}&entryType=quote`;
    } else if (entryType && entryType !== '') {
      url = `${url}&entryType=${entryType}`;
    }

    if (insuredType !== -1) {
      url = `${url}&insuredType=${insuredType}`;
    }
    if (
      entryType === undefined ||
      entryType !== 'policy' ||
      !filter?.isDashboard
    ) {
      if (filter?.quoteStatus) {
        url += '&quoteStatus=' + filter?.quoteStatus;
      }
    }
    if (filter?.insuredName) {
      url += '&insuredName=' + encodeURIComponent(filter?.insuredName);
    }
    if (filter?.trxDays) {
      url += '&trxDays=' + filter.trxDays;
    }
    if (filter?.underwriterId) {
      url += '&underwriterId=' + filter.underwriterId;
    }
    if (filter?.isDashboard) {
      url += `&isDashboard=${filter.isDashboard}`;
    }
    if (filter?.brokerageBORId) {
      url += '&brokerageBORId=' + filter.brokerageBORId;
    }
    if (filter?.brokerageBranchBORId) {
      url += '&brokerageBranchBORId=' + filter.brokerageBranchBORId;
    }
    if (filter?.brokerageProducerBORId) {
      url += '&brokerageProducerBORId=' + filter.brokerageProducerBORId;
    }
    if (filter?.riskIds) {
      url += '&riskIds=' + filter.riskIds;
    }
    if (filter?.periodDt) {
      url += '&effectiveDt=' + filter.periodDt;
    }
    if (filter?.effectiveDt) {
      url += '&effectiveDt=' + filter.effectiveDt;
    }
    if (filter?.createdDt) {
      url += '&createdDt=' + filter.createdDt;
    }
    if (filter?.limitRange) {
      url += '&limitRange=' + filter.limitRange;
    }
    if (filter?.deductibleRange) {
      url += '&deductibleRange=' + filter.deductibleRange;
    }
    if (filter?.premiumRange) {
      url += '&premiumRange=' + filter.premiumRange;
    }
    if (filter?.insuredId) {
      url += '&insuredId=' + filter.insuredId;
    }
    if (filter?.policyStatus) {
      url += '&policyStatus=' + filter.policyStatus;
    }

    if (filter?.needByDt) {
      url += '&needByDt=' + filter.needByDt;
    }

    if (filter?.quoteProcessStatus) {
      url += '&quoteProcessStatus=' + filter.quoteProcessStatus;
    }

    if (filter?.brokerageBORId) {
      url += '&brokerageBORId=' + filter.brokerageBORId;
    }

    if (filter?.revenue) {
      url += '&revenue=' + filter.revenue;
    }

    if (filter?.industryId) {
      url += '&industryId=' + filter.industryId;
    }

    if (sort) {
      url += '&sort=' + sort;
    }

    return this.http
      .get<BoxxResponse<Policy>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  dashboardExport(filters): Observable<ArrayBuffer> {
    let {
      insuredType = -1,
      filter,
      sort = '',
      limit,
      entryType = undefined,
      isDashboardCount = undefined,
      isTeamsQueue = false,
    } = filters;

    let url = `${
      this.baseurl
    }?page=1&limit=${limit}&isExport=1&active=1&isTeamsQueue=${
      isTeamsQueue ? 1 : 0
    }`;

    if (isDashboardCount) {
      url += `&isDashboardCount=1`;
    }

    if (entryType === undefined) {
      url = `${url}&entryType=quote`;
    } else if (entryType && entryType !== '') {
      url = `${url}&entryType=${entryType}`;
    }

    if (insuredType !== -1) {
      url = `${url}&insuredType=${insuredType}`;
    }
    if (
      entryType === undefined ||
      entryType !== 'policy' ||
      !filter?.isDashboard
    ) {
      if (filter?.quoteStatus) {
        url += '&quoteStatus=' + filter?.quoteStatus;
      }
    }
    if (filter?.insuredName) {
      url += '&insuredName=' + encodeURIComponent(filter?.insuredName);
    }
    if (filter?.trxDays) {
      url += '&trxDays=' + filter.trxDays;
    }
    if (filter?.underwriterId) {
      url += '&underwriterId=' + filter.underwriterId;
    }
    if (filter?.isDashboard) {
      url += `&isDashboard=${filter.isDashboard}`;
    }
    if (filter?.brokerageBORId) {
      url += '&brokerageBORId=' + filter.brokerageBORId;
    }
    if (filter?.brokerageBranchBORId) {
      url += '&brokerageBranchBORId=' + filter.brokerageBranchBORId;
    }
    if (filter?.brokerageProducerBORId) {
      url += '&brokerageProducerBORId=' + filter.brokerageProducerBORId;
    }
    if (filter?.riskIds) {
      url += '&riskIds=' + filter.riskIds;
    }
    if (filter?.periodDt) {
      url += '&effectiveDt=' + filter.periodDt;
    }
    if (filter?.effectiveDt) {
      url += '&effectiveDt=' + filter.effectiveDt;
    }
    if (filter?.createdDt) {
      url += '&createdDt=' + filter.createdDt;
    }
    if (filter?.limitRange) {
      url += '&limitRange=' + filter.limitRange;
    }
    if (filter?.deductibleRange) {
      url += '&deductibleRange=' + filter.deductibleRange;
    }
    if (filter?.premiumRange) {
      url += '&premiumRange=' + filter.premiumRange;
    }
    if (filter?.insuredId) {
      url += '&insuredId=' + filter.insuredId;
    }
    if (filter?.policyStatus) {
      url += '&policyStatus=' + filter.policyStatus;
    }
    if (filter?.needByDt) {
      url += '&needByDt=' + filter.needByDt;
    }

    if (filter?.quoteProcessStatus) {
      url += '&quoteProcessStatus=' + filter.quoteProcessStatus;
    }

    if (filter?.revenue) {
      url += '&revenue=' + filter.revenue;
    }

    if (filter?.industryId) {
      url += '&industryId=' + filter.industryId;
    }
    if (sort) {
      url += '&sort=' + sort;
    }
    return this.http
      .get(url, { responseType: 'arraybuffer' })
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetAllByPolicy(
    page = 1,
    limit = 10,
    sort = '',
    policyStatus = undefined,
  ): Observable<BoxxResponse<Policy>> {
    let url = this.baseurl + '?page=' + page + '&limit=' + limit + '&active=1';
    if (policyStatus) {
      url += '&policyStatus=' + policyStatus;
    }
    return this.http
      .get<BoxxResponse<Policy>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetAllPolicyFilter(
    filter,
    page = 1,
    limit = 10,
    sort = '',
    policyStatus = undefined,
  ): Observable<BoxxResponse<Policy>> {
    let url =
      this.baseurl +
      '?page=' +
      page +
      '&limit=' +
      limit +
      '&active=1' +
      '&entryType=policy';

    if (filter?.policyStatus) {
      url += '&policyStatus=' + filter?.policyStatus;
    }
    if (filter?.trxDays) {
      url += '&trxDays=' + filter.trxDays;
      // url += '&trxType=' + filter.trxType
    }
    if (filter?.underwriterId) {
      url += '&underwriterId=' + filter.underwriterId;
    }
    if (filter?.brokerageBORId) {
      url += '&brokerageBORId=' + filter.brokerageBORId;
    }
    if (filter?.brokerageBranchBORId) {
      url += '&brokerageBranchBORId=' + filter.brokerageBranchBORId;
    }
    if (filter?.brokerageProducerBORId) {
      url += '&brokerageProducerBORId=' + filter.brokerageProducerBORId;
    }
    if (filter?.riskIds) {
      url += '&riskIds=' + filter.riskIds;
    }
    if (filter?.effectiveDt) {
      url += '&effectiveDt=' + filter.effectiveDt;
    }
    if (filter?.expiryDt) {
      url += '&expiryDt=' + filter.expiryDt;
    }
    if (filter?.premiumRange) {
      url += '&premiumRange=' + filter.premiumRange;
    }
    if (filter?.deductibleRange) {
      url += '&deductibleRange=' + filter.deductibleRange;
    }
    if (filter?.limitRange) {
      url += '&limitRange=' + filter.limitRange;
    }
    if (filter?.type) {
      url += '&type=' + filter.type;
    }
    if (sort) {
      url += '&sort=' + sort;
    }
    if (filter?.insuredName) {
      url += '&insuredName=' + encodeURIComponent(filter.insuredName);
    }

    return this.http
      .get<BoxxResponse<Policy>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  PolicyExport(filter, sort = ''): Observable<ArrayBuffer> {
    let url = environment.msPolicyBaseUrl + environment.policyExportUrl;

    url += '?active=1';

    if (filter?.limit) {
      url += '&limit=' + filter?.limit;
    }
    if (filter?.page) {
      url += '&page=' + filter?.page;
    }
    if (filter?.policyStatus) {
      url += '&policyStatus=' + filter?.policyStatus;
    }
    if (filter?.quoteStatus) {
      url += '&quoteStatus=' + filter?.quoteStatus;
    }
    if (filter?.insuredName) {
      url += '&insuredName=' + filter?.insuredName;
    }
    if (filter?.entryType) {
      url += '&entryType=' + filter?.entryType;
    }
    if (filter?.trxDays) {
      url += '&trxDays=' + filter.trxDays;
    }
    if (filter?.underwriterId) {
      url += '&underwriterId=' + filter.underwriterId;
    }
    if (filter?.brokerageBORId) {
      url += '&brokerageBORId=' + filter.brokerageBORId;
    }
    if (filter?.brokerageBranchBORId) {
      url += '&brokerageBranchBORId=' + filter.brokerageBranchBORId;
    }
    if (filter?.brokerageProducerBORId) {
      url += '&brokerageProducerBORId=' + filter.brokerageProducerBORId;
    }
    if (filter?.periodDt) {
      url += '&effectiveDt=' + filter.periodDt;
    }
    if (filter?.limitRange) {
      url += '&limitRange=' + filter.limitRange;
    }
    if (filter?.riskIds) {
      url += '&riskIds=' + filter.riskIds;
    }
    if (filter?.effectiveDt) {
      url += '&effectiveDt=' + filter.effectiveDt;
    }
    if (filter?.expiryDt) {
      url += '&expiryDt=' + filter.expiryDt;
    }
    if (filter?.premiumRange) {
      url += '&premiumRange=' + filter.premiumRange;
    }
    if (filter?.deductibleRange) {
      url += '&deductibleRange=' + filter.deductibleRange;
    }
    if (filter?.insuredId) {
      url += '&insuredId=' + filter.insuredId;
    }
    if (filter?.type) {
      url += '&type=' + filter.type;
    }
    if (sort) {
      url += '&sort=' + sort;
    }

    return this.http
      .get(url, { responseType: 'arraybuffer' })
      .pipe(retry(1), catchError(this.errorHandl));
  }

  // to get the quote already exists for the selected insured
  GetExistingPoliciesByInsuredId(
    insuredId,
    policyStatus,
  ): Observable<BoxxResponse<Policy>> {
    return this.http
      .get<BoxxResponse<Policy>>(
        this.baseurl +
          '?insuredId=' +
          insuredId +
          '&policyStatus=' +
          policyStatus,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
