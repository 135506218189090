<div class="doc-template-selection-container" [ngStyle]="docTempContainerStyle">
  <div class="content">
    <div class="body-content">
      <div class="row-container">
        <div class="left-content">
          <div class="doc-template-section" *ngIf="docOptions">
            <div class="doc-section" id="listGroup">
              <ul class="doc-group doc-group-flush">
                <li
                  class="doc-group-item"
                  [ngClass]="{ isActive: isSelected[docItem.value] }"
                  *ngFor="let docItem of docOptions; let i = index"
                  (click)="handleDocumentClick(docItem.value)"
                  [attr.id]="'menu-item-' + docItem.value"
                >
                  <span class="selected-dot" *ngIf="isSelected[docItem.value]"
                    ><img src="assets/img/Dot_selected.svg"
                  /></span>
                  <span class="doc-key" [title]="docItem.key">
                    {{ docItem.key }}</span
                  ><span class="arrow ms-auto">
                    <img
                      class="arrow-right"
                      [ngClass]="{ active: isSelected[docItem.value] }"
                      [src]="
                        isSelected[docItem.value]
                          ? 'assets/img/chevron-right-active.svg'
                          : 'assets/img/chevron-right.svg'
                      "
                    />
                  </span>

                  <div
                    [attr.id]="'submenu-' + docItem.value"
                    *ngIf="tempOptions"
                  >
                    <ul
                      class="temp-group submenu"
                      *ngIf="isSubMenuOpen(docItem.value)"
                      [ngClass]="submenuDirection[docItem.value]"
                      [ngStyle]="{ left: submenuDirectionLeft[docItem.value] }"
                      [attr.id]="'submenu-' + docItem.value"
                    >
                      <li
                        class="temp-group-item"
                        [ngClass]="{
                          isTempActive: isTempSelected[tempItem.value]
                        }"
                        *ngFor="let tempItem of tempOptions"
                        (click)="
                          handleTemplateSelection(
                            tempItem.value,
                            docItem.value,
                            $event
                          )
                        "
                      >
                        <span
                          class="selected-dot"
                          *ngIf="isTempSelected[tempItem.value]"
                          ><img src="assets/img/Dot_selected.svg"
                        /></span>
                        <span class="temp-key" [title]="tempItem.key">{{
                          tempItem.key
                        }}</span>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="right-content">
          <div
            class="doc-template-preview-section"
            *ngIf="docTempOptions.length > 0"
          >
            <boxxds-body
              [customStyle]="{
                padding: '9px 10px',
                color: '#475467',
                fontFamily: 'Gibson',
                fontSize: '12px',
                fontWeight: '400',
                lineHeight: '18px'
              }"
              [text]="previewTitle"
            ></boxxds-body>

            <div class="doc-template-preview-list">
              <li
                class="preview-list-group-item"
                *ngFor="let pdt of docTempOptions; let i = index"
              >
                <div class="pdt">
                  <div
                    class="pdt-name"
                    [title]="
                      (pdt.doc ? pdt.doc : '') +
                      (pdt.doc && pdt.template ? ' / ' : '') +
                      (pdt.template ? pdt.template : '')
                    "
                  >
                    {{ pdt.doc ? pdt.doc : "" }}
                    {{ pdt.template ? "/ " + pdt.template : "" }}
                  </div>
                  <button
                    *ngIf="!pdt.docTempProcessId"
                    type="button"
                    aria-label="close"
                    class="close-btn"
                    (click)="handleDocTempRemove(pdt.doc, pdt.template, i)"
                  >
                    <img src="assets/img/badge_close_x.svg" />
                  </button>
                </div>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
