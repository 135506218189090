<div>
  <div *ngIf="showLabel" class="label-container">
    <label class="dropdown-label" *ngIf="label" innerHtml="{{ label }}"></label>
  </div>
  <div class="dropdown-collection" [ngStyle]="dropdownContainerStyle">
    <div class="input-holder">
      <img
        class="search-icon"
        src="assets/img/dashboard/search/search.png"
        *ngIf="!readonly"
      />
      <input
        type="text"
        [placeholder]="placeholder"
        [ngStyle]="btnStyle"
        [(ngModel)]="currentValue"
        class="dropdown-button"
        aria-haspopup="listbox"
        aria-labelledby="dropdown-label"
        (click)="toggleDropdown($event)"
        (keyup)="!readonly && handleSearch($event)"
        (clickOutside)="dropdownOpen = false"
        [ngStyle]="{
          marginTop: label && '8px',
          'border-color':
            (formSubmitted &&
              form.controls[control].errors &&
              form.controls[control].errors['required']) ||
            invalidErrMsg !== ''
              ? '#CA3434'
              : currentValue && currentValue !== '' && '#9090AF'
        }"
        [attr.readonly]="readonly ? true : null"
        [attr.disabled]="disabled ? true : null"
        [ngClass]="{ 'disabled-field': disabled, 'readonly-field': readonly }"
        (ngModelChange)="(readonly ? currentValue : $event)"
      />

      <button
        type="button"
        class="dropdown-icon"
        (click)="toggleDropdown($event)"
        *ngIf="!readonly"
      >
        <img src="assets/img/dropdown-icon.png" />
      </button>
    </div>

    <ul
      *ngIf="
        options.length > 0 && currentValue !== '' && !readonly && !disabled
      "
      class="dropdown-list"
      role="listbox"
      aria-expanded="false"
      [ngClass]="{ 'dropdown-open': dropdownOpen }"
    >
      <li
        *ngFor="let o of options"
        id="dropdown-selected"
        [ngClass]="{ selected: currentValue === o.value, disabled: o.disabled }"
        tabindex="0"
        (click)="!o.disabled && select(o)"
        role="option"
        [innerHTML]="o.key"
      ></li>
    </ul>
    <div
      *ngIf="
        formSubmitted &&
        form.controls[control]?.errors &&
        form.controls[control]?.errors['required']
      "
      class="error-msg"
    >
      {{ requiredErrMsg }}
    </div>
    <div *ngIf="invalidErrMsg !== '' && !dropdownOpen" class="error-msg">
      {{ invalidErrMsg }}
    </div>
  </div>
</div>
