import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { EMPTY, Observable, from, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CognitoService } from '../cognito-service';
import { environment } from 'src/environments/environment';

import { getAlertHead, getErrorMessage } from 'src/app/utils/utils';
import { AlertService } from '../alert.service';
import { MaintenanceService } from '../maintenance.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private access_token = 'access_token';
  private id_token = 'idtoken';

  constructor(
    private router: Router,
    private cognito: CognitoService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private maintenanceService: MaintenanceService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.headers.has('Authorization')) {
      return next.handle(req);
    }

    const token = this.cognito.getToken();
    const idToken = this.cognito.getIDToken();

    if (token) {
      req = this.addAuthHeaders(req, idToken);
    } else if (
      req.url?.includes(environment.baseUrl) &&
      !req.url?.includes(environment.authenticationUrl)
    ) {
      this.cognito.logout();
      this.router.navigate(['unauthorized'], { skipLocationChange: true });
      return new Observable<HttpEvent<any>>();
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if ([403].includes(error.status)) {
          this.cognito.logout();
          this.router.navigate(['unauthorized'], { skipLocationChange: true });
        } else if ([401].includes(error.status)) {
          return this.handle401Error(req, next);
        }
        if ([500].includes(error.status)) {
          if (error.error.code === -20) {
            return throwError(error);
          }
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: getErrorMessage(error),
          };
          this.alertService.addAlert(alertData);
          let errorWithStatus = { ...error };
          errorWithStatus.error['status'] = error.status;
          return throwError(errorWithStatus);
        }
        return throwError(error);
      }),
    );
  }

  private handle401Error(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const refreshToken = this.cognito.getRefreshToken();
    if (refreshToken) {
      return this.cognito.refreshAccessToken(refreshToken).pipe(
        switchMap((response: any) => {
          this.updatedIdTokenAndAccessToken(
            response.data.access_token,
            response.data.id_token,
          );
          // handle maintenance mode check
          return from(this.maintenanceService.isMaintenanceMode()).pipe(
            switchMap((maintenanceMode: boolean) => {
              const queryParams = this.activatedRoute.snapshot.queryParams;
              const bypassMaintenance =
                queryParams['bypassMaintenance'] === 'true';
              if (maintenanceMode && !bypassMaintenance) {
                this.router.navigate(['/maintenance']);
                return EMPTY;
              } else {
                return next.handle(
                  this.addAuthHeaders(req, response.data.id_token),
                );
              }
            }),
          );
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404) {
            return throwError(error);
          }
          if (
            error.status !== 400 ||
            (error.status === 400 &&
              error.url.split('/').pop().split('?')[0] === 'refresh')
          ) {
            this.cognito.logout();
            this.router.navigate(['unauthorized'], {
              skipLocationChange: true,
            });
          }
          return throwError(error);
        }),
      );
    } else {
      this.cognito.logout();
      return throwError('Refresh token missing');
    }
  }

  private addAuthHeaders(
    req: HttpRequest<any>,
    idToken: string | null,
  ): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${idToken}`,
        'X-Api-Key': `${idToken}`,
      },
    });
  }

  private updatedIdTokenAndAccessToken(accessToken: string, idToken: string) {
    localStorage.removeItem(this.id_token);
    localStorage.removeItem(this.access_token);
    localStorage.setItem(this.access_token, accessToken);
    localStorage.setItem(this.id_token, idToken);
  }
}
