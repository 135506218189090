<div class="custom-modal" *ngIf="showModal">
  <div class="modal-content">
    <img src="assets/img/logout.png" width="47px" height="47px" alt="" />
    <div class="d-flex flex-column">
      <boxxds-heading
        [sz]="'7'"
        [text]="'Are you sure you want to logout?'"
        [customStyle]="{ color: '#333334', marginBottom: '12px' }"
      ></boxxds-heading>
      <boxxds-body
        sz="sm"
        [text]="
          'Any unfinished tasks may result in lost data. If you have any pending transactions or forms, please make sure to complete before proceeding.'
        "
        [customStyle]="{ color: '#56565C', marginBottom: '30px' }"
      >
      </boxxds-body>
      <div class="d-flex justify-content-end">
        <boxxds-button
          buttonText="Cancel"
          btnType="primary-outlined"
          [btnStyle]="{ width: '98px' }"
          (handleClick)="handleCancel()"
        >
        </boxxds-button>
        <boxxds-button
          buttonText="Logout"
          [btnStyle]="{ width: '98px', marginLeft: '20px' }"
          (handleClick)="handleLogout()"
        >
        </boxxds-button>
      </div>
    </div>
  </div>
</div>
