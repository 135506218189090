<div class="holder">
  <boxxds-breadcrumb [items]="itemsMenu"></boxxds-breadcrumb>
  <boxxds-alert
    *ngIf="showErrorAlert"
    (handleCloseEvent)="handleCloseSuccessEvent()"
    type="error"
    headerText="error!"
    [bodyText]="msgErrorAlert"
  >
  </boxxds-alert>
  <boxxds-heading
    sz="5"
    [ngStyle]="{ marginBottom: '50px' }"
    [text]="'List of Permissions' | translate"
  >
  </boxxds-heading>

  <div class="d-flex justify-content-between align-items-center">
    <boxxds-textinput
      [form]="form"
      [placeholder]="'Search permissions' | translate"
      [control]="'searchKey'"
      [id]="'searchKey'"
      [leftIcon]="'assets/img/dashboard/search/search.png'"
      maxLength="45"
      [containerStyle]="{ marginTop: '80px', marginBottom: '22px' }"
      (handleKeyUp)="handleSearch($event)"
    >
    </boxxds-textinput>
    <ng-container *ngIf="this.permissionList[this.currentScreen]">
      <boxxds-button
        [buttonText]="'Create new permission'"
        btnType="secondary"
        type="button"
        leftImage="add"
        [btnStyle]="{ padding: '0 20px 0 20px' }"
        (handleClick)="navigateToAddPermission($event)"
      >
      </boxxds-button>
    </ng-container>
  </div>
  <boxxds-table
    [data]="permissions"
    [totalCount]="totalDataCount"
    (pageChanged)="handlePageChange($event)"
    class="table"
    (sortUpClicked)="handleAscSort($event)"
    (sortDownClicked)="handleDescSort($event)"
    [showSpinner]="showTblSpinner"
    addCopyBtnToField="Email"
    [addSortTo]="addSortTo"
    [styleInactive]="true"
    [linkDetails]="[{ name: 'View' }]"
    [showLinks]="true"
    [tableStyle]="{ marginBottom: isSearchResult ? '58px' : '27.5px' }"
    [activePageNumber]="pageNumber"
  >
  </boxxds-table>
</div>
