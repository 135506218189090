import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Breadcrumb } from 'src/app/models/breadcrumb.model';
import { PolicyService } from 'src/app/services/policy.service';
import * as utils from '../../dashboard/utils/lifecycle-utils';
import { Observable, firstValueFrom, lastValueFrom, take } from 'rxjs';
import { InsuredService } from 'src/app/services/insured.service';
import { PolicyPeriodService } from 'src/app/services/policy-period.service';
import { AlertService } from 'src/app/services/alert.service';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import * as objects from './lifecycle-v2-object';
import { Quote, UISpecificValues } from 'src/app/models/quote.model';
import { getLifecycleState } from 'src/app/store/lifecycle/lifecycle.select';
import {
  formatDate,
  getFormatDate,
  getFormattedDateTime,
  getFormattedPolicyPeriod,
} from 'src/app/utils/formatDate';
import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import { UNDERWRITER_CLEARANCE } from 'src/app/constants/systemType';
import { KeyValueDto } from 'src/app/dtos/keyvalueList.dto';
import { PolicyPeriod } from 'src/app/entities/policy';
import { PolicyRiskService } from 'src/app/services/policy-risk.service';
import {
  setInsuredIdAction,
  setPolicyRiskTrxIdAction,
  setPolicySlideoutData,
  setRiskIdAction,
} from 'src/app/store/lifecycle/lifecycle.action';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import {
  formatAmountWithCurrency,
  getAlertHead,
  getErrorMessage,
  removeDuplicatedKeys,
  toSentenceCase,
} from 'src/app/utils/utils';
import { DropdownListDto } from 'src/app/dtos/dropdownList.dto';
import { BoxxUser } from 'src/app/entities/boxx-user';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import { Pagination } from 'src/app/entities/boxx-response';
import { PolicyRiskTrxService } from 'src/app/services/policy-risk-trx-service';
import { DomainsService } from 'src/app/services/domains.service';
import { getQuoteSelector } from 'src/app/store/create-quote/create-quote.selector';
import { FormBuilder, FormGroup } from '@angular/forms';
import { getPolicyStatusTypeV2 } from 'src/app/constants/policyStatus';
import { PolicyLifecycleService } from 'src/app/services/policy-lifecycle-service';
import { DocumentTemplateService } from 'src/app/services/doc-template.service';
import { PolicyChangeResponse } from 'src/app/entities/policy-lifecycle';
import { GenerateQuoteService } from 'src/app/services/doc-create.service';
import { ConfMessageTemplateService } from 'src/app/services/message-template-services';
import { GENERATE_DOC_SUCCESS_STATUS_CODE } from 'src/app/constants/quote-constant';
import { PolicyRiskDocService } from 'src/app/services/policy-risk-doc.service';
import { MessageSendService } from 'src/app/services/message-send.service';
import { handleApiError } from '../../dashboard/utils/lifecycle-utils';
import { NewQuoteService } from 'src/app/services/new-quote.service';

@Component({
  selector: 'app-policy-lifecycle-v2',
  templateUrl: './policy-lifecycle-v2.component.html',
  styleUrls: ['./policy-lifecycle-v2.component.less'],
})
export class PolicyLifecycleV2Component implements OnInit {
  private policyId: number;
  private policyStatus: DropdownListDto[];
  private policyRiskTrxs: Array<objects.PolicyRiskTrxItem> = [];
  showBodySpinner: boolean = false;
  form$: Observable<Quote>;
  itemsMenu: Breadcrumb[] = [
    {
      label: 'Policy Directory',
      path: '/dashboard/workflow3/policies',
    },
    { label: 'New Policy', path: null },
  ];
  packagePolicyNumber: string;
  transactionType: string = '';
  brokerageId;
  producerId;
  brokerageData;
  status: string;
  riskId: number;
  firstPolicyRiskTrx;
  sortByNewAsc: boolean = true;

  sortDirection: string = 'desc';
  public policyRiskTrxId: number;
  private insuredId: number;
  longDateFormat: string = '';
  shortDateFormat: string = '';
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  isUnderwriter = false;
  periodOptions: KeyValueDto[] = [];
  details: objects.PolicyDetail = {
    header: '',
    currentPeriod: '',
    id: '',
    policyRiskTrxId: 0,
    policyRiskTrxIds: '',
    latestPolicyRiskTrxId: 0,
    userId: 0,
    status: '',
    brokerage: '',
    branch: '',
    producer: '',
    firstBindDate: '',
    policyPeriod: '',
    insuredName: '',
    isUnreadNotesPresent: false,
    isInvoiced: false,
    product: [],
    currentTagOptions: undefined,
  };
  private userId: number;
  actionPopupDetails: objects.PolicyActionPopupDetail = {
    id: '',
    policyPeriodId: 0,
    userId: 0,
    period: '',
    insured: '',
    brokerage: '',
    branch: '',
    reasonOptions: [],
    action: '',
  };
  selectedPolicyPeriodId: number = 0;
  isInvoiced: boolean = false;
  policyData: Array<objects.PolicyDataItem> = [];
  subNavData: Array<objects.PolicySubNavItem> = [];
  regionId: Number;
  insuredDetail;
  private cancelReasons: KeyValueDto[];
  private reinstateReasons: KeyValueDto[];
  private policyRisks: Array<objects.PolicyRiskItem> = [];

  totalCount: number = 0;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalPages: number = 0;
  policyRiskId;

  policyPeriodId;

  timeLineData: Array<objects.PolicyTimeLineItem> = [];
  productForm: FormGroup;

  openFooter: boolean = false;
  showCancelModal: boolean = false;
  showReinstateModal: boolean = false;
  shwStrtNewPeriodModal: boolean = false;
  currency = '';
  riskTrxTypes: any[];
  showForm: boolean = false;
  showNotesPopup: boolean = false;
  docPopupDetails: {
    statusType: string;
    action: any;
    documentTemplate: any;
    policyPeriodId: any;
    stageId: any;
    policyRiskId: any;
  };
  showDocTempSelectionPopup: boolean = false;
  docDetails: { [x: string]: string | number | boolean }[] = [];
  stageId: number;
  showSendModal = false;
  isLoader: boolean = false;
  templateDetails: { [x: string]: string | number }[] = [];
  newTimeout: NodeJS.Timeout;
  showErrorAlert: boolean = false;
  alertErrMsg = [];
  rowDocDetails: { [x: string]: string | number | boolean } = {};
  messageTypeId: number;
  currentQuoteStatus: string = '';
  hasTemplatePopup: boolean = false;
  domainArray: any;
  showPolicyChange: boolean = false;
  policyChangeEditMode: boolean = false;
  isTrxPolicyChange: boolean = false;
  quoteFlowSteps;
  quoteFlowUrl;
  showSlideOut: boolean = false;
  currentProductDetails: any = {};
  isLoading: boolean = true;

  policyEffectiveDate;
  policyExpiryDate;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private router: Router,
    private policyService: PolicyService,
    private insuredService: InsuredService,
    private policyPeriodService: PolicyPeriodService,
    private alertService: AlertService,
    private policyRiskService: PolicyRiskService,
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private boxxUserService: BoxxUserService,
    private policyRiskTrxService: PolicyRiskTrxService,
    private domainsService: DomainsService,
    private fb: FormBuilder,
    private policyLifecycleService: PolicyLifecycleService,
    private DocumentTemplateService: DocumentTemplateService,
    private generateQuoteService: GenerateQuoteService,
    private messageTemplateService: ConfMessageTemplateService,
    private policyRiskDocService: PolicyRiskDocService,
    private messageSendService: MessageSendService,
    private newQuoteService: NewQuoteService,
  ) {
    this.productForm = this.fb.group({
      product: [''],
    });
  }

  async ngOnInit() {
    this.showBodySpinner = true;
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      const underWriterPermission = data.permissions?.filter(
        (permissionData: any) =>
          permissionData?.permission?.permissionName?.toLowerCase() ===
          UNDERWRITER_CLEARANCE,
      );
      if (underWriterPermission?.length > 0) {
        this.isUnderwriter = underWriterPermission[0].readWrite ? true : false;
      } else {
        this.isUnderwriter = false;
      }
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    this.userId = this.localStorageService.getBoxxUserId();
    this.packagePolicyNumber = this.activatedRoute.snapshot.paramMap.get('id');
    this.itemsMenu[1] = {
      label: `${this.packagePolicyNumber}`,
      path: null,
    };
    this.policyId = 0;

    this.docPopupDetails = {
      statusType: 'Document template selection',
      action: 'docTempSelection',
      documentTemplate: [],
      policyPeriodId: 0,
      stageId: 0,
      policyRiskId: 0,
    };
    this.getStageId();
    this.form$ = this.store.pipe(select(getQuoteSelector));
    this.form$
      .pipe(take(1))
      .subscribe(
        (event) => (this.policyPeriodId = Number(event.ui.policyPeriodId)),
      );
    let products;
    this.form$.subscribe((event) => {
      products = event.products;
      this.regionId = event.ui.regionId;
    });

    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));

    await this.populateDomainLists();
    await this.getPolicyStatuses();

    this.showBodySpinner = false;
    this.populateData();
    this.quoteFlowSteps = this.localStorageService.getQuoteFlowSteps();
    if (this.quoteFlowSteps === '7') {
      this.quoteFlowUrl = 'quote';
    } else if (this.quoteFlowSteps === '4') {
      this.quoteFlowUrl = 'quote4flow';
    } else {
      this.quoteFlowUrl = 'workflow3';
    }
  }

  private async getPolicyFromApi(): Promise<any> {
    if (utils.isNumeric(this.packagePolicyNumber)) {
      this.router.navigate([`../../quote/${this.packagePolicyNumber}`], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });

      return;
    }

    const policy$ = this.policyService.GetByPolicyNumber(
      this.packagePolicyNumber,
    );
    const policy = await lastValueFrom(policy$);

    if (policy.data.length > 0) {
      this.policyId = policy.data[0].id;
    }

    return policy;
  }
  private async populateData() {
    this.showBodySpinner = true;
    try {
      const policy = await this.getPolicyFromApi();
      const getRegionId = await this.getRegionId(policy?.data[0]?.insured?.id);
      if (policy?.data.length == 0) {
        this.handleApiError('error code: 404');
        return;
      }

      const periods = await this.getPolicyPeriodsFromApi();
      let products;

      this.packagePolicyNumber = policy?.data[0]?.pkgPolicyNumber;
      this.brokerageId = periods?.data[0]?.brokerageId;
      // this.riskId = 1;
      this.producerId = periods?.data[0]?.brokerageProducerId;

      ////////////////////
      this.brokerageData = {
        brokerageId: policy?.brokerageBORId,
        producerId: policy?.brokerageProducerBORId,
      };

      this.store.dispatch(
        new CreateQuoteAction.updateUiContents({
          ...this.brokerageData,
        }),
      );
      this.form$.subscribe((event) => (products = event.products));
      this.store
        .select(getLifecycleState)
        .pipe(take(1))
        .subscribe((state) => {
          this.policyRiskTrxId = state?.policyRiskTrxId;
          this.status = state?.policyRiskTrxStatus;
          this.insuredId = state?.insuredId;
          this.riskId = state?.riskId;
          this.policyPeriodId = state?.policyPeriodId;
        });

      /////////////////////////

      this.populateDataFromApi(policy?.data[0], periods?.data);
    } catch (error) {
      this.showBodySpinner = false;
      if (![500].includes(error?.status)) {
        this.handleApiError(error);
      }
    }
  }
  private async populateDataFromApi(policy: any, periods: any[]) {
    this.populatePeriodDropDown(periods);
    this.policyEffectiveDate = periods[0]?.effectiveDt?.split('T')[0] ?? '';
    this.policyExpiryDate = periods[0]?.expiryDt.split('T')[0] ?? '';
    if (!policy) {
      return;
    }
    const status = this.getPolicyStatus(policy.policyStatus);
    const firstBindDate = getFormattedDateTime(
      policy.firstBindDt,
      this.longDateFormat,
    );
    const currentPeriod = this.periodOptions[0].key;
    const policyId = utils.getPolicyNumber(policy);
    const insuredName = utils.getInsuredName(policy.insured);
    const getPolicyByID = this.policyRiskService.GetByPolicyId(this.policyId);
    const risk = await firstValueFrom(getPolicyByID);
    const insuredId = policy?.insured?.id;
    this.timeLineData = risk.data;

    let mailIdList = {
      broker: policy?.brokerageBOR?.email ?? '',
      branch: policy?.brokerageBranchBOR?.email ?? '',
      producer: policy?.brokerageProducerBOR?.email ?? '',
      insured: policy?.insured?.email ?? '',
    };
    this.store.dispatch(new CreateQuoteAction.setAllMailId(mailIdList));

    this.store.dispatch(new setInsuredIdAction(policy?.insured?.id));
    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        policyId: policy?.id,
        policyPeriodId: policy?.policyPeriodId,
        broker: policy?.brokerageBOR?.name ?? '',
        branch: policy?.brokerageBranchBOR?.name ?? '',
        producer:
          policy?.brokerageProducerBOR?.firstName ??
          '' + policy?.brokerageProducerBOR?.lastName ??
          '',
        insuredName: policy?.insured?.companyName ?? '',
        packagePolicyNumber: policy?.pkgPolicyNumber ?? '',
      }),
    );
    let tempProducts: string[] = [];
    let productList = [];
    risk.data.map((risk) => {
      if (!tempProducts.includes(risk.risk.name)) {
        tempProducts.push(risk.risk.name);
        productList.push(risk.risk);
      }
    });
    this.details = {
      header: this.translate.instant('Policy'),
      currentPeriod: currentPeriod,
      id: policyId,
      policyRiskTrxId: 0,
      latestPolicyRiskTrxId: 0,
      policyRiskTrxIds: '',
      userId: this.userId,
      status: toSentenceCase(status),
      brokerage: policy.brokerageBOR.name,
      branch: policy.brokerageBranchBOR.name,
      producer: `${policy.brokerageProducerBOR.firstName} ${policy.brokerageProducerBOR.lastName}`,
      firstBindDate: firstBindDate ? firstBindDate : 'n/a',
      policyPeriod: this.details.policyPeriod ?? '',
      insuredName: insuredName,
      isUnreadNotesPresent: false, // TODO: Where does this come from?
      isInvoiced: this.details.isInvoiced ?? false,
      product: productList ?? [],
      currentTagOptions: [],
      policyPeriodId: periods[0]?.id,
      policyRiskId: risk.data[0].id,
      insuredId: insuredId,
      industry: this.insuredDetail.industry.name,
      revenue: formatAmountWithCurrency(
        this.insuredDetail.revenue,
        this.currency,
      ),
    };
    this.policyPeriodId = periods[0]?.id;
    this.actionPopupDetails = {
      id: policyId,
      policyPeriodId: undefined,
      userId: this.userId,
      period: '',
      insured: insuredName,
      brokerage: policy.brokerageBOR.name,
      branch: policy.brokerageBranchBOR.name,
      action: '',
      reasonOptions: [],
    };
    await this.populateAfterPeriodChange();
    this.transactionType = this.getPdtDetails()?.status;
  }

  private async getPolicyRisksFromApi(policyPeriodId: number): Promise<any> {
    const risk$ = this.policyRiskService.GetByPolicyIdAndPolicyPeriodId(
      this.policyId,
      policyPeriodId,
    );
    const risk = await lastValueFrom(risk$);

    this.store.dispatch(new setRiskIdAction(risk.data[0]?.riskId));
    this.riskId = risk.data[0]?.riskId;
    return risk;
  }
  private async populateAfterPeriodChange(): Promise<void> {
    const risks = await this.getPolicyRisksFromApi(this.selectedPolicyPeriodId);
    if (risks.data && risks.data.length > 0) {
      const policyPeriod = utils.getRiskPeriod(
        risks.data[0],
        this.longDateFormat,
      );

      this.isInvoiced = this.isPolicyInvoiced(
        risks.data[0].PolicyRiskTrxes.map((invoice) => {
          if (invoice.invoiceNumber) {
            this.isInvoiced = true;
          }
        }),
      );
      this.policyRiskTrxs = [];
      this.details['policyPeriod'] = policyPeriod;
      this.details['isInvoiced'] = this.isInvoiced;
      this.actionPopupDetails.period = policyPeriod;

      this.policyRisks = risks.data.map((data) => ({
        id: data.id,
        name: data.risk.name,
        policyNumber: data.policyNumber,
        period: data.policyPeriod.period,
        effectiveDt: data.policyPeriod.effectiveDt,
      }));
      let startDate = formatDate(
        risks.data[0].policyPeriod.effectiveDt,
        this.longDateFormat,
      );
      let endDate = formatDate(
        risks.data[0].policyPeriod.expiryDt,
        this.longDateFormat,
      );
      this.store.dispatch(
        new CreateQuoteAction.updateUiContents({
          startDate:
            getFormatDate(
              startDate,
              this.longDateFormat,
              this.shortDateFormat,
            ) ?? '',
          endDate:
            getFormatDate(endDate, this.longDateFormat, this.shortDateFormat) ??
            '',
        }),
      );

      await this.createPolicyDataTabs();
    }
  }

  private async createPolicyDataTabs(): Promise<void> {
    this.policyData = this.policyRisks.map((data) => ({
      risk: utils.addSpaceBeforeCapitalLetter(data.name),
      policyId: data.policyNumber,
      policyRiskTrxId: 0,
      limit: 0,
      premium: 0,
      deductible: 0,
      invoiceNumber: '',
      trnxDate: '',
      underwriter: '',
      annualPremium: 0,
      status: '',
      reason: '',
      policyPeriod: '',
      policyChange: false,
      isPopulated: false,
    }));

    this.subNavData = this.policyData.map((product, index) => ({
      name: product.risk,
      policyRiskTrxId: product.policyRiskTrxId,
      active: index == 0,
      currentValue: 0,
      value: index,
    }));
    this.productForm.get('product').setValue(0);

    await this.populateActiveTab(0);
  }

  private async populateActiveTab(index: number): Promise<void> {
    this.showBodySpinner = true;
    const policyRisk = this.policyRisks[index];
    const policyRiskId = policyRisk.id;
    this.policyRiskId = policyRiskId;
    this.sortByNewAsc = this.sortDirection === 'asc' ? true : false;

    this.store.dispatch(
      new setPolicySlideoutData({
        packagePolicyNumber: this.packagePolicyNumber,
        brokerageId: this.brokerageId,
        riskId: this.riskId,
        producerId: this.producerId,
        riskRegionId: 1,
        policyRiskId: policyRiskId,
        regionId: this.regionId,
      }),
    );
    this.store.dispatch(
      new CreateQuoteAction.setPolicyPeriodId(this.policyPeriodId),
    );

    if (this.policyData[index].isPopulated) {
      // Tab has already been populated, update the timeline.
      await this.populateTimelineData(index);
      return;
    }
    const effectiveDate = getFormattedPolicyPeriod(
      policyRisk.period,
      'MMMM DD, YYYY',
    );
    const policyRiskTrxs = await this.getPolicyRiskTrxesFromApi(policyRiskId);
    this.firstPolicyRiskTrx = policyRiskTrxs.data[0];
    const remainingPolicyRiskTrx = policyRiskTrxs.data.slice(1);

    this.policyData[index].totalAnnualPremium = policyRiskTrxs.data?.reduce(
      (acc, t) => acc + (!!!t.invoiceNumber ? 0 : Number(t.totalInvoiceAmount)),
      0,
    );
    const totalSalesTax = policyRiskTrxs.data?.reduce(
      (acc, t) =>
        acc + (!!!t.invoiceNumber ? 0 : Number(t.taxesServicesAmount)),
      0,
    );
    const totalInsuranceTax = policyRiskTrxs.data?.reduce(
      (acc, t) => acc + (!!!t.invoiceNumber ? 0 : Number(t.taxesPremiumAmount)),
      0,
    );
    const totalServicesAmount = policyRiskTrxs.data?.reduce(
      (acc, t) => acc + (!!!t.invoiceNumber ? 0 : Number(t.servicesAmount)),
      0,
    );
    const totalTRIA = policyRiskTrxs.data?.reduce(
      (acc, t) => acc + (!!!t.invoiceNumber ? 0 : Number(t.triaAmount)),
      0,
    );
    const totalPolicyFee = policyRiskTrxs.data?.reduce(
      (acc, t) => acc + (!!!t.invoiceNumber ? 0 : Number(t.policyFee)),
      0,
    );
    this.firstPolicyRiskTrx = {
      ...this.firstPolicyRiskTrx,
      totalAnnualPremium: this.policyData[index].totalAnnualPremium,
      totalSalesTax,
      totalInsuranceTax,
      totalServicesAmount,
      totalTRIA,
      totalPolicyFee,
    };

    const policyRiskTrxPagination: Pagination = {
      ...policyRiskTrxs.pagination,
      totalRecords: policyRiskTrxs.pagination.totalRecords - 1, // to decrease the latest transaction from the total count
    };

    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        annualPremium: this.firstPolicyRiskTrx?.['totalInvoiceAmount'] ?? null,
        deductible: this.firstPolicyRiskTrx?.['deductible'] ?? null,
        premium: this.firstPolicyRiskTrx?.['premium'] ?? null,
        policyEffectiveDate:
          this.firstPolicyRiskTrx?.['transactionEffectiveDate'] ?? null,
      }),
    );

    this.policyRiskTrxs[index] = {
      RiskTrxs: remainingPolicyRiskTrx,
      Pagination: policyRiskTrxPagination,
    };

    let status = this.firstPolicyRiskTrx.type
      ? this.firstPolicyRiskTrx.type
      : this.firstPolicyRiskTrx.quoteStatus;
    if (!isNaN(status)) {
      status = this.setPolicyStatusType(status);
    }
    this.policyData[index].policyId = this.details.id;
    this.policyData[index].policyRiskTrxId = this.firstPolicyRiskTrx.id;
    this.policyData[index].limit = this.firstPolicyRiskTrx.limit;
    this.policyData[index].premium = this.firstPolicyRiskTrx.premium;
    this.policyData[index].deductible = this.firstPolicyRiskTrx.deductible;
    this.policyData[index].invoiceNumber =
      this.firstPolicyRiskTrx.invoiceNumber;
    this.policyData[index].trnxDate = formatDate(
      this.firstPolicyRiskTrx.createdDt,
      this.longDateFormat,
    );
    this.policyData[index].underwriter = await this.getUnderwriter(
      this.firstPolicyRiskTrx.underwriterId,
    );
    this.policyData[index].annualPremium =
      this.firstPolicyRiskTrx.totalInvoiceAmount;

    this.policyData[index].status = utils.addSpaceBeforeCapitalLetter(status);

    this.policyData[index].reason = this.firstPolicyRiskTrx.notes;
    this.policyData[index].policyPeriod = this.populatePolicyPeriod(
      this.firstPolicyRiskTrx.transactionEffectiveDate,
      policyRisk.effectiveDt,
    );
    this.policyData[index].policyChange =
      this.policyData[index].status?.toLowerCase() === 'policy change'
        ? true
        : false;
    this.policyData[index].isPopulated = true;

    this.subNavData[index].policyRiskTrxId =
      this.policyData[index].policyRiskTrxId;

    this.isInvoiced = this.isPolicyInvoiced(
      this.policyData[index].invoiceNumber,
    );
    this.policyData[index].trxReasons =
      this.firstPolicyRiskTrx?.PolicyRiskTrxReasons;

    // Updating the properties does not trigger ngOnChanges() on the lifecycle-details component
    // because the object reference does not change, only the properties changed.
    this.details.policyRiskTrxId = this.policyData[index].policyRiskTrxId;
    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        latestPolicyRiskTrxId: this.details?.['policyRiskTrxId'] ?? '',
      }),
    );

    this.details.latestPolicyRiskTrxId = this.policyData[index].policyRiskTrxId;
    this.details.isInvoiced = this.isInvoiced;
    this.details.policyRiskTrxIds = policyRiskTrxs.data
      .map((p) => p.id)
      .join(',');

    let trxTypes = policyRiskTrxs.data?.map((dataObj) => ({
      key: dataObj.type,
      value: dataObj.type,
      id: dataObj.id,
    }));
    trxTypes = removeDuplicatedKeys(trxTypes);

    this.details.currentTagOptions = trxTypes;
    // Hack to update the properties to force ngOnChanges()
    // Clone the whole object which updates the object reference.
    this.details = Object.assign({}, this.details);

    await this.populateTimelineData(index);
    this.showBodySpinner = false;
  }

  private async populateTimelineData(index: number) {
    const data = this.policyRiskTrxs[index];
    const pagination = data.Pagination;

    this.currentPage = pagination.currentPage;
    this.totalCount = pagination.totalRecords;
    this.totalPages = pagination.totalPages;
    this.showBodySpinner = false;
  }
  private populatePeriodDropDown(periods: PolicyPeriod[]) {
    // Sort periods by sequence value in descending order
    // Should be using the period value, but it is null in may cases.
    const sortedPeriod = periods.sort((a, b) => b.sequence - a.sequence);

    this.periodOptions = sortedPeriod.map((data) => {
      // NOTE: boxxds-dropdown has the key and value backwards

      // TODO: Remove once the "Start next period" API call is fixed!!!!!
      const peroid = data.period
        ? data.period.toString()
        : new Date().getFullYear().toString();
      const kv: KeyValueDto = { key: peroid, value: data.id?.toString() };

      //const kv: KeyValueDto = { key: data.period.toString(), value: data.id?.toString() };
      return kv;
    });

    if (this.periodOptions.length == 0) {
      return;
    }

    this.selectedPolicyPeriodId = Number(this.periodOptions[0].value);
    this.policyPeriodId = Number(this.periodOptions[0].value);
  }
  private getPolicyStatus(id: number): string {
    return utils.getValueFromList(this.policyStatus, id);
  }
  async getRegionId(insuredId) {
    await this.insuredService
      .GetByInsuredId(insuredId)
      .subscribe((insuredData) => {
        this.regionId =
          insuredData?.data?.insuredLocation?.regionId ??
          insuredData?.data?.insuredLocation?.region?.id;
        this.insuredDetail = insuredData?.data;
        this.store.dispatch(
          new CreateQuoteAction.UpdateInsuredAction({
            regionId: this.regionId,
            timezone:
              insuredData?.data?.insuredLocation?.timeZoneCode ??
              'America/New_York',
          }),
        );
      });
  }
  handleApiError(error: any) {
    if (![500].includes(error?.status)) {
      let errorMessage = '';
      if (typeof error === 'string' || error instanceof String) {
        if (error?.toLowerCase().includes('error code: 404', 0)) {
          errorMessage = this.translate.instant('Requested policy not found.');
        } else {
          errorMessage = getErrorMessage(error);
        }
      } else {
        errorMessage = getErrorMessage(error);
      }
      const alertData = {
        show: true,
        type: 'error',
        headerText: 'error!',
        bodyText: errorMessage,
      };
      this.alertService.addAlert(alertData);
    }
  }
  private async getPolicyPeriodsFromApi(): Promise<any> {
    const period$ = this.policyPeriodService.GetAllByPolicyId(this.policyId);
    const period = await lastValueFrom(period$);

    return period;
  }
  getPdtDetails() {
    const currentActiveIdx = this.getActiveTabIndex();
    this.currentProductDetails =
      this.timeLineData?.[currentActiveIdx]?.['risk'] ?? {};
    return this.policyData[currentActiveIdx];
  }
  getPdtDetailsByIndex(index) {
    return this.policyData[index];
  }

  private getActiveTabIndex(): number {
    return this.subNavData.findIndex((navObj) => navObj.active);
  }

  private populatePolicyPeriod(
    transactionEffectiveDate: any,
    policyRiskEffectiveDate: any,
  ) {
    return transactionEffectiveDate
      ? formatDate(transactionEffectiveDate, this.longDateFormat)
      : formatDate(policyRiskEffectiveDate, this.longDateFormat);
  }

  private isPolicyInvoiced(invoiceNumber: string): boolean {
    return !!invoiceNumber;
  }

  private async getBoxxUserFromApi(userId: number): Promise<BoxxUser> {
    try {
      const user$ = this.boxxUserService.GetById(userId);
      const user = await lastValueFrom(user$);
      return user.data;
    } catch (error) {
      return null;
    }
  }

  private async getUnderwriter(id: number): Promise<string> {
    var user: any = await this.getBoxxUserFromApi(id);

    if (user) {
      this.store.dispatch(
        new CreateQuoteAction.setAllMailId({
          underwriter: user?.systemUser?.loginEmail
            ? [`${user.systemUser.loginEmail}`]
            : [],
        }),
      );
      return `${user.firstName} ${user.lastName}`;
    }

    return Promise.resolve('n/a');
  }

  private async getPolicyRiskTrxesFromApi(policyRiskId: number): Promise<any> {
    this.sortDirection = 'desc';
    this.sortByNewAsc = false;
    const riskTrx$ = this.policyRiskTrxService.GetByPolicyRiskId(
      policyRiskId,
      this.currentPage,
      11, // to get 10 trxns excluding the latest one
      this.sortDirection,
    );
    const riskTrx = await lastValueFrom(riskTrx$);

    return riskTrx;
  }

  private async populateDomainLists() {
    try {
      this.policyStatus =
        await this.domainsService.GetDomainListAsync('POLICYSTATUS');

      const cancel =
        await this.domainsService.GetDomainListAsync('CANCELLATIONREASON');
      this.cancelReasons = cancel.map((data) => utils.mapListReverse(data));

      const reinstate =
        await this.domainsService.GetDomainListAsync('REINSTATEREASON');
      this.reinstateReasons = reinstate.map((dataObj) =>
        utils.mapListReverse(dataObj),
      );
    } catch (error) {
      this.showBodySpinner = false;
      if (![500].includes(error?.status)) {
        this.handleApiError(error);
      }
    }
  }
  handleNav(event, index) {
    const currentActiveIdx = this.getActiveTabIndex();
    this.subNavData[currentActiveIdx].active = false;
    this.subNavData[index].active = true;
    this.details.policyRiskTrxId = this.policyData[index].policyRiskTrxId;

    this.populateActiveTab(index);
  }

  handleShowPeriod(value) {
    const id = Number(value);
    if (this.selectedPolicyPeriodId == id) {
      return;
    }

    this.selectedPolicyPeriodId = id;
    this.policyPeriodId = id;
    this.populateAfterPeriodChange();
  }

  handleBind() {
    this.router.navigate(['../summary/product/', this.details.id], {
      relativeTo: this.activatedRoute,
      skipLocationChange: true,
    });
  }

  handleShowRenew() {
    this.actionPopupDetails.action = 'Renew';
    this.actionPopupDetails.reasonOptions = [];

    // TODO: Call API to make changes
    const alertData = {
      show: true,
      type: 'error',
      headerText: 'error!',
      bodyText: 'Action is not implemented yet.',
    };
    this.alertService.addAlert(alertData);
  }

  handleShowCancel() {
    this.actionPopupDetails.action = 'Cancel';
    this.actionPopupDetails.policyPeriodId = this.selectedPolicyPeriodId;
    this.actionPopupDetails.reasonOptions = this.cancelReasons;

    this.showCancelModal = !this.showCancelModal;
  }

  handleShowStartNextPeriod() {
    this.actionPopupDetails.action = 'StartNextPeriod';
    this.actionPopupDetails.policyPeriodId = this.selectedPolicyPeriodId;
    this.actionPopupDetails.reasonOptions = [];
    this.shwStrtNewPeriodModal = true;
  }

  handleShowReinstate() {
    this.actionPopupDetails.action = 'Reinstate';
    this.actionPopupDetails.policyPeriodId = this.selectedPolicyPeriodId;
    this.actionPopupDetails.reasonOptions = this.reinstateReasons;
    this.showReinstateModal = !this.showReinstateModal;
  }

  getStatusType(status: string) {
    switch (status.toLowerCase()) {
      case 'new submissions':
      case 'submission':
      case 'reinstated':
      case 'bind':
        return 'success';
      case 'bound':
      case 'renewed':
      case 'renewal quote':
      case 'renewal app':
      case 'quote':
      case 'quoted':
      case 'change-saved':
      case 'policy changed':
      case 'executed':
      case 'policy changed':
      case 'policy change':
      case 'change-saved':
      case 'executed':
        return 'success';
      case 'endorsed':
      case 'endorsement':
      case 'not taken up':
        if (this.isInvoiced !== null || this.isInvoiced !== undefined) {
          if (!this.isInvoiced) {
            return 'secondary';
          }
        }
        return 'success';
      case 'referral':
        return 'warning';
      case 'endrs-saved':
        return 'secondary';
      case 'quote closed':
      case 'cancelled':
      case 'lapsed':
      case 'declined':
      case 'closed':
        return 'error';
    }
    return 'default';
  }

  getTransLevelActions(transactionType: string, isInvoiced: boolean) {
    switch (transactionType.toLowerCase()) {
      case 'bind':
      case 'renewal':
        if (isInvoiced) {
          return ['Endorse', 'Policy change'];
        } else {
          return ['Edit'];
        }
      case 'reinstated':
      case 'endorsement':
        if (isInvoiced) {
          return ['Endorse', 'Policy change'];
        } else {
          return ['Edit', 'Delete'];
        }
      case 'policy change':
        if (isInvoiced) {
          return ['Endorse', 'Policy change'];
        }
        this.isTrxPolicyChange = true;
        return ['Edit', 'Delete'];
      case 'cancellation':
        if (!isInvoiced) {
          return ['Edit'];
        } else return [];
      default:
        return [];
    }
  }

  async handleTransLevelAction(action, product) {
    this.actionPopupDetails.action = action;

    switch (action.toLowerCase()) {
      case 'policy change':
        if (!this.showForm) {
          this.showPolicyChange = true;
        }
        break;
      case 'execute':
        break;
      case 'delete':
        await this.handleDeleteTransaction(product);
        break;
      case 'endorse':
        this.handleEndorse(product);
        break;
      case 'invoice':
        break;
      case 'edit':
        if (product.status.toLowerCase() === 'policy change') {
          this.showPolicyChange = true;
        } else if (product.status.toLowerCase() === 'endorsement') {
          this.handleEndorse(product);
        }
        break;
      default:
    }
  }
  handleEndorse(product) {
    if (!this.showPolicyChange) {
      this.showForm = true;
    }
  }
  async handleDeleteTransaction(product) {
    let request = {
      policyRiskTrxId: product.policyRiskTrxId,
    };
    this.policyLifecycleService.TransactionRiskDelete(request).subscribe({
      next: (response) => {
        const alertData = {
          show: true,
          type: 'success',
          headerText: 'success!',
          bodyText: this.translate.instant('policy.delete.success.body'),
        };
        this.alertService.addAlert(alertData);
        this.showPolicyChange = false;
        this.isTrxPolicyChange = false;
        Promise.all([this.populatePolicyTileData()]);
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          const alertData = {
            show: true,
            type: 'error',
            headerText: 'error!',
            bodyText: '',
          };
          this.alertService.addAlert(alertData);
        }
      },
    });
  }

  async handleExecuteTransaction(event: any) {
    if (event === 'directExecute') {
      setTimeout(() => {
        this.executeTransaction(event);
      }, 1500);
    } else {
      this.executeTransaction();
    }
  }

  executeTransaction(type: string = '') {
    let request = {
      policyRiskTrxId: this.firstPolicyRiskTrx.id,
    };
    this.policyLifecycleService.TransactionRiskExecute(request).subscribe({
      next: (response) => {
        let msg = this.translate.instant('policy.update.success.body');
        if (type === 'directExecute') {
          msg = this.translate.instant('policy.change.success.body');
        }
        const alertData = {
          show: true,
          type: 'success',
          headerText: 'success!',
          bodyText: msg,
        };
        this.alertService.addAlert(alertData);
        this.showPolicyChange = false;
        this.isTrxPolicyChange = false;
        this.populateData();
      },
      error: (error) => {
        const message = handleApiError(error);
        if (![500].includes(error?.status)) {
          const alertData = {
            show: true,
            type: 'error',
            headerText: 'error!',
            bodyText: message,
          };
          this.alertService.addAlert(alertData);
        }
      },
    });
  }

  getPolicyStatuses() {
    return new Promise<any>(async (resolve, reject) => {
      this.domainsService.GetByDomainCode('POLICYRISKTRXTYPE').subscribe({
        next: (data) => {
          let riskTypeList = [];
          data.data.map((dataObj) => {
            if (
              dataObj.description !== 'QUOTE' &&
              dataObj.description !== 'QUOTE-CLOSED'
            ) {
              riskTypeList = [
                ...riskTypeList,
                {
                  description: dataObj.description,
                  status: getPolicyStatusTypeV2(dataObj.description),
                  seqId: dataObj.seqId,
                  id: dataObj.id,
                },
              ];
            }
          });
          this.riskTrxTypes = riskTypeList;
          resolve(true);
        },
        error: (error) => {
          reject(false);
        },
      });
    });
  }
  setPolicyStatusType(type) {
    const filteredArray = this.riskTrxTypes.filter((item) => item.id === type);

    const valuesOfRenewal = filteredArray.map((item) => item.description);

    return valuesOfRenewal[0];
  }
  getStatusName(status) {
    return objects.getStatusName(status, this.isInvoiced);
  }
  handleNotesPopupClose($event) {
    this.showNotesPopup = false;
  }
  toggleNotesPopup(e) {
    this.showNotesPopup = !this.showNotesPopup;
    e.stopPropagation();
  }

  handleCancelConfirm(event: PolicyChangeResponse) {
    this.showCancelModal = false;
    this.handlePolicyChangeEvent(event);
  }
  handlePolicyChangeEvent(event: PolicyChangeResponse) {
    // this.handleCloseSuccessEvent();
    // this.handleCloseErrorEvent();

    if (event.success) {
      const alertData = {
        show: true,
        type: 'success',
        headerText: 'success!',
        bodyText: event.message,
      };
      this.alertService.addAlert(alertData);
      this.populateData();
      return;
    }

    const alertData = {
      show: true,
      type: 'error',
      headerText: 'common.errorHeader',
      bodyText: event.message,
    };
    this.alertService.addAlert(alertData);
  }
  handleReinstateConfirm(event: PolicyChangeResponse) {
    this.showReinstateModal = false;
    this.handlePolicyChangeEvent(event);
  }
  handleStartNewPeriodConfirm(event: PolicyChangeResponse) {
    this.shwStrtNewPeriodModal = false;
    this.handlePolicyChangeEvent(event);
  }
  async handleFormSuccess() {
    this.showForm = false;
    Promise.all([this.populatePolicyTileData()]);
  }
  async populatePolicyTileData() {
    await this.createPolicyDataTabs();
    this.handleEndorseClose();
  }
  handleEndorseClose() {
    this.showForm = false;
  }

  async getStageId(subDomain = 'DOCMERGESTAGE_BIND') {
    this.stageId = undefined;
    return new Promise<void>((resolve) => {
      this.domainsService.GetByDomainCode('DOCMERGESTAGE', true).subscribe({
        next: (response) => {
          let docStage = response.data.filter(
            (template) => template.subdomaincode === subDomain,
          )[0];
          this.stageId = docStage.id;
          resolve();
        },
        error: (error) => {
          this.isLoader = false;
          this.showSendModal = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
          }
        },
      });
    });
  }

  async handleSendQuote() {
    if (this.currentQuoteStatus == 'quote-closed') {
      this.showErrorAlert = true;
      this.alertErrMsg.push('error.noTemplateAvailable');
      return;
    }
    this.docDetails = [];
    this.docPopupDetails.stageId = this.stageId;
    this.docPopupDetails.policyPeriodId = this.details['policyPeriodId'];
    this.docPopupDetails.policyRiskId = this.details['policyRiskId'];
    if (this.stageId) {
      this.DocumentTemplateService.getDocumentTemplateList(
        this.policyRiskId,
        this.stageId,
      ).subscribe({
        next: (response) => {
          let templateList = response?.data.filter((item) => {
            return item.templates.map(
              (template) => template.docProcessId === null,
            );
          });
          if (templateList.length !== 0) {
            this.hasTemplatePopup = true;
            this.docPopupDetails.documentTemplate = templateList;
            this.showDocTempSelectionPopup = true;
            this.handleDocLevelAction(this.currentQuoteStatus);
            this.isLoader = false;
          } else {
            this.hasTemplatePopup = false;
            this.handleSendPackage();
          }
        },
        error: (error) => {
          this.isLoader = false;
          this.alertErrMsg.push(getErrorMessage(error.error));
        },
      });
    } else {
      return;
    }
  }

  handleDocLevelAction(action) {
    this.docPopupDetails.action = action;
    this.showDocTempSelectionPopup = true;
  }

  handleExit(event) {
    this.showDocTempSelectionPopup = false;
  }

  handleSuccessDocTemplate(event) {
    this.showDocTempSelectionPopup = false;
    this.showSendModal = true;
    this.handleSendPackage();
  }

  async handleSendPackage() {
    this.closeAlert();
    this.docDetails = [];
    this.rowDocDetails = {};
    this.showSendModal = true;
    this.isLoader = !this.isLoader;
    if (this.showSendModal) {
      await this.populateMessageType();
      await this.getDocument();
      this.details.header.toLowerCase() !== 'quote' &&
        (await this.getMessageTemplates(this.details?.product[0]?.id));
    }
  }

  closeAlert() {
    this.showNotesPopup = false;
    this.showErrorAlert = false;
  }

  async populateMessageType() {
    this.messageTypeId = undefined;
    let messageType;
    return new Promise<void>((resolve) => {
      this.domainsService.GetByDomainCode('MESSAGETYPE', true).subscribe({
        next: (response) => {
          messageType = response.data.filter(
            (template) => template.subdomaincode === 'MESSAGETYPE_POLICY',
          )[0];
          this.messageTypeId = messageType.id;
          resolve();
        },
        error: (error) => {
          this.isLoader = false;
          this.showSendModal = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
            this.alertErrMsg.push(getErrorMessage(error));
          }
        },
      });
    });
  }

  async getDocument() {
    this.policyRiskDocService
      .getPolicyRiskGeneratedDocCore(
        this.details['policyPeriodId'],
        this.stageId,
      )
      .subscribe({
        next: (response) => {
          let { data } = response;
          if (Object.entries(data).length === 0) return;

          if (!data.PolicyRiskDocument || data.PolicyRiskDocument.length === 0)
            return;
          // if (response?.data?.status === GENERATE_DOC_SUCCESS_STATUS_CODE) {
          this.docDetails = data.PolicyRiskDocument;
          // } else {
          //   this.showSendModal = false;
          //   this.isLoader = false;
          //   this.docDetails = [];
          //   this.alertService.addAlert({
          //     type: 'error',
          //     headerText: getAlertHead('error'),
          //     bodyText: 'common.documentGenerationFailed',
          //   })
          // }
          this.rowDocDetails = data;
        },
        error: (error) => {
          this.isLoader = false;
          clearTimeout(this.newTimeout);
          this.showSendModal = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
            this.alertErrMsg.push(getErrorMessage(error));
          }
        },
      });
  }

  docGenFinishHandler() {
    this.isLoader = false;
  }

  async getMessageTemplates(riskId) {
    this.messageTemplateService
      .GetAllMessageTemplates(this.messageTypeId, riskId)
      .subscribe({
        next: (response) => {
          this.templateDetails = response?.data ?? [];
          // this.isLoader = false;
        },
        error: ({ error }) => {
          this.isLoader = false;
          this.showSendModal = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
            this.alertErrMsg.push(getErrorMessage(error));
          }
        },
      });
  }

  handleBack() {
    this.showDocTempSelectionPopup = true;
    this.isLoader = false;
    this.showSendModal = false;
  }

  async handleSend(formData: any) {
    formData.append('merginStageId', this.stageId);
    this.messageSendService.sendMessage(formData).subscribe({
      next: (response) => {
        this.docDetails = [];
        this.templateDetails = [];
        this.showSendModal = !this.showSendModal;
        const alertData = {
          type: 'success',
          headerText: getAlertHead('success'),
          bodyText: this.translate.instant(
            'workFlow3.emailPopupV2.success.packageSent',
          ),
        };
        this.alertService.addAlert(alertData);
      },
      error: (error) => {
        this.isLoader = false;
        this.showSendModal = !this.showSendModal;
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
          this.alertErrMsg.push(getErrorMessage(error.error));
        }
      },
    });
  }

  closeSendPackage(isDocGenFailed: boolean = false) {
    if (isDocGenFailed) {
      this.alertService.addAlert({
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: 'common.documentGenerationFailed',
      });
    }
    clearTimeout(this.newTimeout);
    this.closeAlert();
    this.docDetails = [];
    this.templateDetails = [];
    this.showSendModal = false;
    this.isLoader = false;
  }

  handlePolicyChangeSuccess($event: any) {
    this.showPolicyChange = false;
    Promise.all([this.populatePolicyTileData()]);
  }

  closePolicyChange(event: any) {
    this.showPolicyChange = false;
  }

  handleSearch(object) {
    if (object.searchIn === 'Insured name') {
      let insuredType = 1;
      if (object?.type?.toLowerCase() == 'company') {
        insuredType = 2;
      }

      this.router.navigateByUrl(
        `dashboard/search/${insuredType}/${object.selectedSearch.value}/${object.selectedSearch.id}`,
        { skipLocationChange: true },
      );
    } else {
      this.router.navigateByUrl(
        `dashboard/workflow3/policies/${object.selectedSearch.value}`,
        { skipLocationChange: true },
      );
    }
  }

  handleStartNewQuote() {
    this.store.dispatch(new CreateQuoteAction.ResetState());
    if (this.quoteFlowUrl === 'workflow3') {
      this.newQuoteService.clearInsuredId();
      this.router.navigate(['../../workflow3'], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
      return;
    } else {
      this.router.navigate([`../../workflow3/new`], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
    }
  }

  handleSlideOutClose() {
    this.showSlideOut = false;
  }

  showQuoteSlideOut(event) {
    const latestPolicyRiskTrxId = this.policyData[0].policyRiskTrxId;
    if (latestPolicyRiskTrxId) {
      this.store.dispatch(new setPolicyRiskTrxIdAction(latestPolicyRiskTrxId));
    }
    this.showSlideOut = true;
  }

  toSentenceCase(status: string) {
    let statusText = status ? toSentenceCase(status) : '';
    let invoicedText = '';
    if (this.isInvoiced) {
      if (statusText?.toLowerCase() === 'policy change') {
        invoicedText = ' - Executed';
      } else {
        invoicedText = ' - Invoiced';
      }
    }
    return statusText + invoicedText;
  }

  handleLoading(loaderEvent: any) {
    this.isLoading = loaderEvent.isLoading ? true : false;
  }
}
