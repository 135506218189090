import { Component } from '@angular/core';

@Component({
  selector: 'app-brokerage-module',
  templateUrl: './brokerage-module.component.html',
  styleUrls: ['./brokerage-module.component.less'],
})
export class BrokerageModuleComponent {
  constructor() {}
}
