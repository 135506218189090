import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { DomainsService } from './domains.service';
import {
  calculateHourDifference,
  checkIfCurrentDateTime,
  isBetweenOneHourAndOneHourTenMinutes,
  isWithinDateRange,
  isWithinNextHour,
} from '../utils/utils';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  private timeDifference: string = '';
  private startDateTimeString: string = '';
  private endDateTimeString: string = '';
  private isMaintenanceWithinNextHour: boolean = false;
  private isMaintenanceModeOn: boolean = false;

  constructor(
    private http: HttpClient,
    private domainService: DomainsService,
    private router: Router,
    private activatedRouter: ActivatedRoute,
  ) {}
  async isMaintenanceMode(): Promise<boolean> {
    try {
      const queryParams = this.activatedRouter.snapshot.queryParams;
      const bypassMaintenance =
        queryParams['bypassMaintenance'] === 'true' ||
        (localStorage.getItem('bypassmaintenance') &&
          localStorage.getItem('bypassmaintenance') === 'true');
      if (bypassMaintenance) {
        return false;
      }
      let isMaintenance = false;
      const maintenanceConfigAPI =
        await this.domainService.GetByDomainCode('MAINTENANCEMODE');
      const maintenanceConfigResponse =
        await firstValueFrom(maintenanceConfigAPI);
      const maintenanceFlag = maintenanceConfigResponse?.data[0]?.valueInt1;
      const maintenanceStartDateTime =
        maintenanceConfigResponse?.data[0]?.valueString1;
      this.startDateTimeString =
        maintenanceFlag === 1 ? maintenanceStartDateTime : '';
      const maintenanceExpiryDateTime =
        maintenanceConfigResponse?.data[0]?.valueString2;
      this.endDateTimeString =
        maintenanceFlag === 1 ? maintenanceExpiryDateTime : '';
      const isMaintenanceWindow = isWithinDateRange(
        maintenanceStartDateTime,
        maintenanceExpiryDateTime,
      );

      isMaintenance =
        maintenanceFlag === 1 && isMaintenanceWindow ? true : false;
      this.isMaintenanceModeOn = isMaintenance;
      if (isMaintenance) {
        this.timeDifference = calculateHourDifference(
          maintenanceExpiryDateTime,
        );
        this.router.navigate(['/maintenance']);
      } else {
        const isUpcomingMaintenance = isWithinNextHour(
          this.startDateTimeString,
        );
        this.setIsMaintenanceWithinNextHour(isUpcomingMaintenance);

        if (
          isBetweenOneHourAndOneHourTenMinutes(this.startDateTimeString) ||
          isUpcomingMaintenance
        ) {
          // checkAndExecuteAtGivenTime(this.startDateTimeString, this.isMaintenanceMode);
          const interval = setInterval(() => {
            if (checkIfCurrentDateTime(this.startDateTimeString)) {
              clearInterval(interval);
              this.isMaintenanceMode();
            }
          }, 1000);
        }
      }
      return isMaintenance;
    } catch (e) {
      return this.isMaintenanceModeOn;
    }
  }

  getTimeDifference() {
    return this.timeDifference;
  }

  getStartDateTimeString() {
    return this.startDateTimeString;
  }

  getEndDateTimeString() {
    return this.endDateTimeString;
  }

  clearTimeDifference() {
    this.timeDifference = '';
  }

  getIsMaintenanceWithinNextHour() {
    return this.isMaintenanceWithinNextHour;
  }

  setIsMaintenanceWithinNextHour(value: boolean) {
    this.isMaintenanceWithinNextHour = value;
  }
}
