import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BaseService } from './base-services';
import { QuoteOption } from '../entities/quote-option';
import { BoxxResponse } from '../entities/boxx-response';

@Injectable({
  providedIn: 'root',
})
export class QuoteOptionReasonsService extends BaseService<QuoteOption> {
  constructor(http: HttpClient) {
    const baseurl =
      environment.msPolicyBaseUrl + environment.quoteOptionReasonsPathUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getByQuoteOptionId(quoteOptionId: number): Observable<any> {
    const url = `${this.baseurl}?quoteOptionId=${quoteOptionId}`;

    return this.http
      .get<BoxxResponse<any>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
