import { Component, OnInit, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.less'],
})
export class NavigationComponent implements OnInit {
  @Input() marginLeft: string;
  @Input() navData: Array<any> = [
    {
      name: 'Quotes',
      routerLink: '/dashboard/quotes',
      permissionName: 'Quote',
    },
    {
      name: 'Policies',
      routerLink: '/dashboard/policies',
      permissionName: 'Policy',
    },
    {
      name: 'Support',
      routerLink: '/dashboard/coming-soon',
      permissionName: 'Support',
    },
  ];

  permissionKeyList: string[] = [];
  quoteFlowSteps = '4';

  constructor(
    private store: Store,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.permissionKeyList = Object.keys(data.litePermissionList);
    });
    this.quoteFlowSteps = this.localStorageService.getQuoteFlowSteps();
    if (this.quoteFlowSteps === 'workflow3') {
      this.navData[0].routerLink = '/dashboard/workflow3/quotes';
      this.navData[1].routerLink = '/dashboard/workflow3/policies';
    }
  }
}
