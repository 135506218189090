import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { getFormattedPeriod } from 'src/app/utils/formatDate';

@Component({
  selector: 'app-modal-insured-state-change',
  templateUrl: './modal-insured-state-change.component.html',
  styleUrls: ['./modal-insured-state-change.component.less'],
})
export class ModalInsuredStateChangeComponent implements OnInit {
  @Input() showModal: boolean = false;
  @Input() existingQuoteOptionData;
  @Input() isSubmissionFlow = true;
  @Input() isTbd = true;

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleDecline = new EventEmitter<any>();
  @Output() handleNewInsured = new EventEmitter<any>();

  shortDateFormat: string = '';
  longDateFormat: string = 'MMM DD, YYYY';

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
  }

  closeModal() {
    this.handleClose.emit(true);
  }
  handleDeclineQuote() {
    this.handleDecline.emit();
  }

  handleCreateNewInsured() {
    this.handleNewInsured.emit();
  }

  formatDate(date) {
    return date
      ? getFormattedPeriod(date, 'YYYY-MM-DDTHH:mm:ss.sssZ', 'MMM DD,YYYY')
      : '';
  }
}
