import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.less'],
})
export class ProgressBarComponent implements OnInit, OnChanges {
  @Input() steps: string[] = [];
  @Input() totalSteps: number = 0;
  @Input() completedSteps: number = 0;
  @Input() activeStep: number = 0;
  @Input() showProgressBar: boolean = false;
  progressPercentage = '0%';

  constructor() {}

  ngOnInit(): void {
    let progressPercentage =
      (Number(this.completedSteps) / (Number(this.totalSteps) - 1)) * 100;
    this.progressPercentage = progressPercentage + '%';
  }
  ngOnChanges(): void {
    let progressPercentage =
      (Number(this.completedSteps) / (Number(this.totalSteps) - 1)) * 100;
    this.progressPercentage = progressPercentage + '%';
  }
}
