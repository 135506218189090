import { Observable, Subject } from 'rxjs';

export abstract class AbstractSubjectServices {
  constructor() {}

  private subject = new Subject<any>();

  dispatch(state: any) {
    this.subject.next(state);
  }

  getObservavle(): Observable<any> {
    return this.subject.asObservable();
  }
}
