import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Breadcrumb } from 'src/app/models/breadcrumb.model';
import { BrokerageBranch } from 'src/app/models/brokergeBranch';
import { BrokerageBranchService } from 'src/app/services/brokerage-branch/brokerage-branch.service';
import {
  getFormGroup,
  mapFormGroupToModel,
} from '../factorys/formGroup.factory';
import { BrokerageService } from 'src/app/services/brokerage.service';
import { Store } from '@ngrx/store';
import { getErrorMessage } from 'src/app/utils/utils';
import { setBranchCreated } from 'src/app/store/brokerage/brokerage.action';
import { LocalStorageService } from 'src/app/services/localstorage-service';

@Component({
  selector: 'app-new-branch',
  templateUrl: './new-branch.component.html',
  styleUrls: ['./new-branch.component.less'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NewBranchComponent implements OnInit {
  form: FormGroup;
  submitted: boolean = false;
  userID: number;
  brokerageId: number;

  itemsMenu: Breadcrumb[] = [
    { label: 'Brokerage directory', path: `/dashboard/brokerage` },
    { label: '', path: null },
    { label: 'New branch', path: null },
  ];
  operationSuccess = false;
  hasError = false;
  errorMessage = '';
  locationRegionIdSelected: number = 0;
  showSpinner = false;
  requiredErrMsg: string = 'This field is required';
  invalidErrMsg: string = 'This field is invalid';
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private brokerageBranchService: BrokerageBranchService,
    private store: Store,
    private brokerageService: BrokerageService,
    private localStorageService: LocalStorageService,
  ) {
    activatedRoute.params.subscribe((params) => {
      this.brokerageId = params['brokerageId'] as number;
    });
  }

  ngOnInit(): void {
    this.userID = this.localStorageService.getBoxxUserId();
    this.form = getFormGroup(this.fb);

    this.brokerageService.GetBrokerage(this.brokerageId).subscribe({
      next: (brokerage) => {
        this.itemsMenu[1] = {
          label: brokerage.data.name,
          path: `/dashboard/brokerage/view/${this.brokerageId}`,
        };
      },
      error: (error) => {
        this.handlerErrorBranch(error);
      },
    });
  }

  get location() {
    return this.form.get('location') as FormGroup;
  }

  get branch() {
    return this.form.get('branch') as FormGroup;
  }

  onHandleCloseEvent() {
    this.hasError = false;
  }

  regionIdSelected(idregionSelected: number) {
    this.locationRegionIdSelected = idregionSelected;
  }

  handleSubmit(e: any) {
    this.submitted = true;

    let branchForm = this.form.get('branch');

    if (branchForm.value.email === '') {
      delete branchForm.value.email;
    }
    if (this.form.valid) {
      this.showSpinner = true;

      const brokerageBranch: BrokerageBranch = mapFormGroupToModel(
        this.form,
        Number(this.brokerageId),
      );

      if (brokerageBranch.location) {
        brokerageBranch.location.regionId = this.locationRegionIdSelected;
      }

      this.brokerageBranchService.addBranch(brokerageBranch).subscribe({
        next: (response) => {
          this.operationSuccess = true;
          this.form.reset();
          this.submitted = false;
          this.showSpinner = false;
          this.store.dispatch(new setBranchCreated());
          this.router.navigate(
            [`../../view/${response?.data?.id}/${this.brokerageId}`],
            { relativeTo: this.activatedRoute, skipLocationChange: true },
          );
        },
        error: (error) => {
          this.handlerErrorBranch(error);
          this.showSpinner = false;
        },
      });
    }
  }

  private handlerErrorBranch(error: any) {
    if (![500].includes(error?.status)) {
      this.hasError = true;
    }
    this.errorMessage = getErrorMessage(error?.error);
  }
}
