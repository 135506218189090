<div class="holder-wrapper">
  <div class="holder" *ngIf="form$ | async as form">
    <ng-container *ngIf="showBodySpinner || spinnerCounter > 0">
      <div class="body-spinner">
        <boxxds-spinner
          [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
        ></boxxds-spinner>
      </div>
    </ng-container>
    <app-modal-send
      *ngIf="showSendModal"
      [isLoader]="isLoader"
      [showModal]="showSendModal"
      (handleClose)="closeSendPackage()"
      [formDetails]="form.ui"
      [templateDetails]="templateDetails"
      [docDetails]="docDetails"
      [title]="
        isQuoteBounded
          ? ('quoteDetails.sendingQuotePaymentSummary' | translate)
          : ('quoteDetails.sendingQuoteSummary' | translate)
      "
      (handleSend)="handleSend($event)"
    >
    </app-modal-send>

    <ng-container *ngIf="!showBodySpinner && spinnerCounter === 0">
      <app-quote-action-popup
        [showModal]="showActionPopup"
        [details]="actionPopupDetails"
        (handleActionConfirm)="
          this.permissionList[this.currentScreen] &&
            handleConfirmRiskAction($event)
        "
        (handleClose)="showActionPopup = false"
      >
      </app-quote-action-popup>
      <app-doc-template-selection-popup
        [showModal]="showDocTempSelectionPopup"
        [details]="docPopupDetails"
        (handleSuccess)="handleSuccessDocTemplate($event)"
        (handleActionConfirm)="
          this.permissionList[this.currentScreen] &&
            handleDocLevelAction($event)
        "
        (handleClose)="handleCloseDocumentPopup()"
      ></app-doc-template-selection-popup>
      <app-document-preview
        *ngIf="showDocPreview"
        [docUrl]="currentPreviewDocUrl"
        (handleClose)="toggleDocPreview()"
      >
      </app-document-preview>
      <section class="policy-configuration">
        <section class="policy-configuration-container">
          <section class="policy-period-section">
            <boxxds-heading
              [customStyle]="{
                fontSize: '20px',
                lineHeight: '32px',
                marginBottom: '10px',
                color: '#616162'
              }"
              [text]="'common.policyPeriod' | translate"
            >
            </boxxds-heading>
            <div class="date-details">
              <ng-container *ngIf="form$ | async as asyncForm">
                <boxxds-datepicker
                  [placeholder]="shortDateFormat"
                  [dateFormat]="shortDateFormat"
                  [label]="('common.selectEffectiveDate' | translate) + '*'"
                  [containerStyle]="{ width: '186px' }"
                  [bsValue]="isTBDValid ? '' : (form$ | async)?.ui.startDate"
                  (bsValueChange)="
                    this.permissionList[this.currentScreen] &&
                      this.currentQuoteStatus !== 'quote-closed' &&
                      handleStartDateChange($event, asyncForm?.ui.endDate)
                  "
                  [minDate]="minStartDate"
                  [maxDate]="maxStartDate"
                  [showErr]="!isStartDateValid"
                  [errMsg]="startDateErrMsg | translate"
                  [isDisabled]="
                    isTBDValid ||
                    !this.permissionList[this.currentScreen] ||
                    this.currentQuoteStatus === 'quote-closed'
                  "
                >
                </boxxds-datepicker>
              </ng-container>
              <div class="end-date">
                <label class="lbl-end-date">{{
                  ("common.expiredDate" | translate) + "*"
                }}</label>
                <div class="text-date" *ngIf="!endDateEditable">
                  <p>
                    {{
                      !isTBDValid && (form$ | async)?.ui.endDate
                        ? (form$ | async)?.ui.endDate
                        : shortDateFormat
                    }}
                  </p>
                  <button
                    *ngIf="
                      this.permissionList[this.currentScreen] &&
                      this.currentQuoteStatus !== 'quote-closed'
                    "
                    [disabled]="isTBDValid"
                    class="btn-edit"
                    (click)="endDateEditable = !endDateEditable"
                  >
                    <img src="assets/img/edit-icon.png" alt="edit" />
                  </button>
                </div>
                <ng-container *ngIf="form$ | async as asyncForm">
                  <boxxds-datepicker
                    *ngIf="endDateEditable"
                    [bsValue]="isTBDValid ? '' : (form$ | async)?.ui.endDate"
                    (bsValueChange)="
                      this.permissionList[this.currentScreen] &&
                        this.currentQuoteStatus !== 'quote-closed' &&
                        handleEndDateChange($event, asyncForm?.ui.startDate)
                    "
                    [placeholder]="shortDateFormat"
                    [dateFormat]="shortDateFormat"
                    [containerStyle]="{ width: '186px' }"
                    [minDate]="minEndDate"
                    [maxDate]="maxEndDate"
                    [showErr]="!isEndDateValid"
                    [errMsg]="endDateErrMsg | translate"
                    [isDisabled]="isTBDValid"
                  >
                  </boxxds-datepicker>
                </ng-container>
              </div>
              <boxxds-checkbox
                [options]="tbdOptions"
                (handleCheckbox)="
                  this.permissionList[this.currentScreen] &&
                    this.currentQuoteStatus !== 'quote-closed' &&
                    handleTBDCheckbox($event, tbdOptions)
                "
                [lblStyle]="{
                  color: '#56565C',
                  fontSize: '14px',
                  fontWeight: '400px',
                  lineHeight: '20px',
                  fontFamily: 'Roboto'
                }"
                [containerStyle]="{ marginTop: '38px', marginLeft: '38px' }"
                [isDisabled]="
                  !this.permissionList[this.currentScreen] ||
                  this.currentQuoteStatus === 'quote-closed'
                "
              >
              </boxxds-checkbox>
            </div>
          </section>
        </section>
      </section>

      <div class="quote">
        <div
          class="product"
          *ngFor="let product of getSelectedQuote(quoteOptions); let i = index"
        >
          <div class="product-container">
            <div class="product-head-section">
              <h2 class="product-title">{{ products[i] }}</h2>
              <div class="product-transaction-details">
                <div>
                  <label class="transaction-label" [for]="'premium' + i">{{
                    "common.premium" | translate
                  }}</label>
                  <p class="transaction-value" [id]="'premium' + i">
                    {{
                      product?.premium
                        | currency: currency : "symbol-narrow" : "1.2-2"
                    }}
                  </p>
                </div>
                <div>
                  <label class="transaction-label" [for]="'limit' + i">{{
                    "quoteCalculator.label.limit" | translate
                  }}</label>
                  <p class="transaction-value" [id]="'limit' + i">
                    {{
                      product?.limit
                        | currency: currency : "symbol-narrow" : "1.2-2"
                    }}
                  </p>
                </div>
                <div>
                  <label class="transaction-label" [for]="'deductible' + i">{{
                    "quoteSummary.deductible" | translate
                  }}</label>
                  <p class="transaction-value" [id]="'deductible' + i">
                    {{
                      product?.deductible
                        | currency: currency : "symbol-narrow" : "1.2-2"
                    }}
                  </p>
                </div>
              </div>
              <div class="">
                <div class="pdt-details">
                  <div class="underwriter-col">
                    <boxxds-dropdown
                      [disabled]="
                        !this.permissionList[this.currentScreen] ||
                        this.currentQuoteStatus === 'quote-closed' ||
                        disabledDropdownList.includes(products[i])
                      "
                      [label]="
                        'productSummary.label.underwriterAssignedToRisk'
                          | translate
                      "
                      [placeholder]="'Select' | translate"
                      [options]="underWriterOptions"
                      (currentValueChange)="
                        this.permissionList[this.currentScreen] &&
                          this.currentQuoteStatus !== 'quote-closed' &&
                          underwriterChanged($event, i)
                      "
                      [currentValue]="underWriters[i]"
                      (loadMoreOptions)="
                        this.permissionList[this.currentScreen] &&
                          this.currentQuoteStatus !== 'quote-closed' &&
                          loadMoreUnderwriters($event)
                      "
                      [hasScrollablePagination]="true"
                      [totalCount]="underwriterTotalCount"
                      [totalPages]="underwriterTotalPages"
                      [dropdownContainerStyle]="{ width: '186px' }"
                      [lblStyle]="{ color: '#616162' }"
                    >
                    </boxxds-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="grid-section justify-content-between">
            <div class="grid-column">
              <div class="endorsement docs">
                <label class="lbl-grid-column">
                  {{ "common.endorsement" | translate }}
                </label>
                <ng-container
                  *ngIf="
                    this.permissionList[this.currentScreen] &&
                    this.currentQuoteStatus !== 'quote-closed'
                  "
                >
                  <boxxds-searchable-dropdown
                    [label]="'common.addAdditionalEndorsement' | translate"
                    [placeholder]="'Select' | translate"
                    [options]="endorsementOptions[products[i]]"
                    [dispatchTextInputChange]="true"
                    (textInputChangeEventEmmiter)="
                      handleEndorsementSearch($event, products[i])
                    "
                    (currentValueChange)="
                      handleAttachEndorsement($event, products[i])
                    "
                    [btnStyle]="{ height: '36px' }"
                    [hideSelectedValue]="true"
                    [dropdownContainerStyle]="{
                      width: '186px',
                      marginBottom: '19px',
                      marginLeft: '26px'
                    }"
                    class="search-dropdown"
                  >
                  </boxxds-searchable-dropdown>
                </ng-container>
                <div
                  class="hdr-docs"
                  *ngIf="selectedEndorsements[products[i]]?.length > 0"
                >
                  {{ "quoteDetails.endorsementListDescription" | translate }}
                </div>
                <div class="documents-holder">
                  <div
                    class="rqd-doc"
                    *ngIf="selectedEndorsements[products[i]]?.length > 0"
                  >
                    <div
                      class="doc d-flex justify-content-between align-items-start"
                      *ngFor="
                        let doc of selectedEndorsements[products[i]];
                        let selectedIdx = index
                      "
                    >
                      <button
                        [ngStyle]="{
                          overflowWrap: 'break-word',
                          maxWidth: '242px',
                          textAlign: 'left'
                        }"
                        (click)="handleDocClick(doc)"
                      >
                        {{ doc.key + (doc.mandatory ? "*" : "") }}
                      </button>
                      <div
                        *ngIf="
                          this.permissionList[this.currentScreen] &&
                          !['quote-closed'].includes(this.currentQuoteStatus)
                        "
                        class="manipulateIconContainer d-flex flex-column gap-2"
                      >
                        <button
                          class="manipulateIcon"
                          *ngIf="!doc.mandatory"
                          (click)="
                            deleteEndorsementSelect(
                              products[i],
                              doc,
                              selectedIdx
                            )
                          "
                        >
                          <img src="assets/img/trash-2.png" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid-column">
              <div class="subjectives docs">
                <label class="lbl-grid-column">
                  {{ "common.subjectivities" | translate }}
                </label>
                <ng-container
                  *ngIf="
                    this.permissionList[this.currentScreen] &&
                    this.currentQuoteStatus !== 'quote-closed'
                  "
                >
                  <boxxds-create-dropdown
                    [label]="'common.addAdditionalSubjectivities' | translate"
                    [placeholder]="'Select' | translate"
                    [options]="subjectivityOptns[products[i]]"
                    [dispatchTextInputChange]="true"
                    (textInputChangeEventEmmiter)="
                      handleSubjectivitySearch($event, products[i])
                    "
                    (currentValueChange)="
                      handleAddSubjectives($event, products[i])
                    "
                    (createNew)="createNewSubjectivity($event, products[i])"
                    [btnStyle]="{ height: '36px' }"
                    [hideSelectedValue]="true"
                    [dropdownArrow]="true"
                    [createUndefined]="true"
                    [createText]="'Add '"
                    [numberOfField]="1"
                    [dropdownContainerStyle]="{
                      width: 'calc(100% - 26px)',
                      marginBottom: '19px',
                      marginLeft: '26px'
                    }"
                    class="search-dropdown"
                  >
                  </boxxds-create-dropdown>
                </ng-container>
                <div
                  class="hdr-docs"
                  *ngIf="selectedSubjectivities[products[i]]?.length > 0"
                >
                  {{ "quoteDetails.subjectivitiesListDescription" | translate }}
                </div>
                <div class="documents-holder">
                  <div
                    class="rqd-doc"
                    *ngIf="selectedSubjectivities[products[i]]?.length > 0"
                  >
                    <div
                      class="subjectivity-list"
                      *ngFor="
                        let sub of selectedSubjectivities[products[i]];
                        let selectedIdx = index
                      "
                    >
                      <div class="d-flex flex-column">
                        <div class="listKey">
                          <ng-container *ngIf="!sub.edit; else subEdit">
                            {{ sub.key + (sub.mandatory ? "*" : "") }}
                          </ng-container>
                          <ng-template #subEdit>
                            <textarea
                              type="text"
                              class="listKey-textArea"
                              [(ngModel)]="editedSubjectivity"
                              [value]="editedSubjectivity"
                            ></textarea>
                          </ng-template>
                        </div>
                        <div class="radio-btn" *ngIf="!sub.edit">
                          <span class="radio-group-label"
                            >{{ "productSummary.label.comply" | translate }}?
                          </span>
                          <input
                            type="radio"
                            value="true"
                            [required]="true"
                            [checked]="sub.isCompliedWith === true"
                            [name]="sub.id + '_' + i + '_' + selectedIdx"
                            [id]="sub.id + '_' + i + '_' + selectedIdx"
                            (change)="
                              this.permissionList[this.currentScreen] &&
                                this.currentQuoteStatus !== 'quote-closed' &&
                                updateCompliance(sub, true)
                            "
                            [disabled]="
                              !this.permissionList[this.currentScreen] ||
                              this.currentQuoteStatus === 'quote-closed'
                            "
                          />
                          <label [for]="sub.id + '_' + i + '_' + selectedIdx">{{
                            "Yes" | translate
                          }}</label>
                          <input
                            type="radio"
                            value="false"
                            [required]="true"
                            [checked]="sub.isCompliedWith === false"
                            [name]="sub.id + '_' + i + '_' + selectedIdx"
                            [id]="sub.id + '_' + i + '_' + selectedIdx"
                            (change)="
                              this.permissionList[this.currentScreen] &&
                                this.currentQuoteStatus !== 'quote-closed' &&
                                updateCompliance(sub, false)
                            "
                            [disabled]="
                              !this.permissionList[this.currentScreen] ||
                              this.currentQuoteStatus === 'quote-closed'
                            "
                          />
                          <label [for]="sub.id + '_' + i + '_' + selectedIdx">{{
                            "No" | translate
                          }}</label>
                        </div>
                      </div>
                      <div
                        class="manipulateIconContainer d-flex flex-column"
                        *ngIf="
                          this.permissionList[this.currentScreen] &&
                          this.currentQuoteStatus !== 'quote-closed'
                        "
                      >
                        <ng-container *ngIf="!sub.edit; else subEditIcons">
                          <span
                            class="manipulateIcon"
                            *ngIf="!sub.mandatory"
                            (click)="
                              deleteSubjectivity(products[i], sub, selectedIdx)
                            "
                          >
                            <img src="assets/img/trash-2.png" />
                          </span>
                          <span
                            class="manipulateIcon editIcon"
                            *ngIf="isNewSubjectivity(products[i], sub)"
                            (click)="editSubjectivity(products[i], sub, 1)"
                          >
                            <img src="assets/img/edit-icon.png" />
                          </span>
                        </ng-container>
                        <ng-template #subEditIcons>
                          <span
                            class="manipulateIcon"
                            (click)="confirmEditSubjectivity(products[i], sub)"
                          >
                            <img src="assets/img/check.png" />
                          </span>
                          <span
                            class="manipulateIcon editIcon"
                            (click)="editSubjectivity(products[i], sub, 0)"
                          >
                            <img src="assets/img/cancel-edit.png" />
                          </span>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid-column">
              <div class="docs">
                <div class="sub-section">
                  <label class="lbl-grid-column">
                    {{ "common.wording" | translate }}
                  </label>
                  <ng-container
                    *ngIf="
                      this.permissionList[this.currentScreen] &&
                      this.currentQuoteStatus !== 'quote-closed'
                    "
                  >
                    <boxxds-searchable-dropdown
                      [label]="'common.addWordingSelection' | translate"
                      [placeholder]="'Select' | translate"
                      [options]="wordingsOptions[products[i]]"
                      (currentValueChange)="
                        handleSelectWording($event, products[i])
                      "
                      [btnStyle]="{ height: '36px' }"
                      [dispatchTextInputChange]="true"
                      (textInputChangeEventEmmiter)="
                        handleWordingSearch($event, products[i])
                      "
                      [hideSelectedValue]="true"
                      [dropdownContainerStyle]="{
                        width: '186px',
                        marginLeft: '26px',
                        marginBottom: '19px'
                      }"
                      class="search-dropdown"
                    >
                    </boxxds-searchable-dropdown>
                  </ng-container>
                  <div
                    class="hdr-docs"
                    *ngIf="selectedWordings[products[i]]?.length > 0"
                  >
                    {{ "quoteDetails.wordingListDescription" | translate }}
                  </div>
                  <div class="documents-holder">
                    <div
                      class="rqd-doc"
                      *ngIf="selectedWordings[products[i]]?.length > 0"
                    >
                      <div
                        class="doc d-flex"
                        *ngFor="
                          let doc of selectedWordings[products[i]];
                          let selectedIdx = index
                        "
                      >
                        <button
                          [ngStyle]="{
                            overflowWrap: 'break-word',
                            maxWidth: '300px',
                            textAlign: 'left'
                          }"
                          (click)="handleDocClick(doc)"
                        >
                          {{ doc.key + (doc.mandatory ? "*" : "") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sub-section">
                  <label class="lbl-grid-column">
                    {{ "common.declaration" | translate }}
                  </label>
                  <ng-container
                    *ngIf="
                      this.permissionList[this.currentScreen] &&
                      this.currentQuoteStatus !== 'quote-closed'
                    "
                  >
                    <boxxds-searchable-dropdown
                      [label]="'common.addDeclarationDocument' | translate"
                      [placeholder]="'Select' | translate"
                      [options]="declarationOptions[products[i]]"
                      (currentValueChange)="
                        handleSelectDeclaration($event, products[i])
                      "
                      [btnStyle]="{ height: '36px' }"
                      [dispatchTextInputChange]="true"
                      (textInputChangeEventEmmiter)="
                        handleDeclarationSearch($event, products[i])
                      "
                      [hideSelectedValue]="true"
                      [dropdownContainerStyle]="{
                        width: '186px',
                        marginLeft: '26px',
                        marginBottom: '19px'
                      }"
                      class="search-dropdown"
                    >
                    </boxxds-searchable-dropdown>
                  </ng-container>
                  <div
                    class="hdr-docs"
                    *ngIf="selectedDeclarations[products[i]]?.length > 0"
                  >
                    {{ "quoteDetails.declarationListDescription" | translate }}
                  </div>
                  <div class="documents-holder">
                    <div
                      class="rqd-doc"
                      *ngIf="selectedDeclarations[products[i]]?.length > 0"
                    >
                      <div
                        class="doc d-flex"
                        *ngFor="
                          let doc of selectedDeclarations[products[i]];
                          let selectedIdx = index
                        "
                      >
                        <button
                          [ngStyle]="{
                            overflowWrap: 'break-word',
                            maxWidth: '300px',
                            textAlign: 'left'
                          }"
                          (click)="handleDocClick(doc)"
                        >
                          {{ doc.key + (doc.mandatory ? "*" : "") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div
              class="addons"
              *ngIf="addons[products[i]] && addons[products[i]].length > 0"
            >
              <div class="addson-hdr">
                {{ "common.selectedCyberAddOns" | translate }}
              </div>
              <div class="addson-holder d-flex justify-content-between gap-3">
                <ng-container
                  *ngFor="
                    let addon of addons[products[i]];
                    let addonIdx = index
                  "
                >
                  <div class="checkbox">
                    <div class="content">
                      <span class="label">{{ addon?.serviceName || "" }}</span>
                      <div class="description">
                        {{ addon?.description || "" }}
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!showBodySpinner && spinnerCounter === 0">
      <app-footer-wrapper>
        <div class="footer-button-list">
          <div class="button-section">
            <button class="linkButton" (click)="handleBackBtn()">
              {{ "common.back" | translate }}
            </button>
            <button class="linkButton" (click)="handleExitFlow()">
              {{ "submission.link.exitTheFlow" | translate }}
            </button>
            <ng-container
              *ngIf="
                this.permissionList[this.currentScreen] &&
                this.currentQuoteStatus !== 'quote-closed'
              "
            >
              <boxxds-button
                *ngIf="!showBodySpinner"
                [buttonText]="'common.saveDraft' | translate"
                btnType="secondary"
                [btnStyle]="{ width: '122px', marginRight: '12px' }"
                type="submit"
                (handleClick)="handleSaveDraft()"
              >
              </boxxds-button>
            </ng-container>
          </div>
          <div class="button-section">
            <ng-container
              *ngIf="
                this.permissionList[this.currentScreen] &&
                this.currentQuoteStatus !== 'quote-closed'
              "
            >
              <boxxds-button
                *ngIf="!showBodySpinner"
                [buttonText]="'Decline' | translate"
                btnType="secondary"
                [btnStyle]="{ width: '122px', marginRight: '12px' }"
                type="submit"
                (handleClick)="handleRiskAction(transactionRiskActions.Decline)"
              >
              </boxxds-button>
            </ng-container>
            <div
              class="btns-holder d-flex"
              *ngIf="
                this.permissionList[this.currentScreen] &&
                this.currentQuoteStatus !== 'quote-closed'
              "
            >
              <boxxds-button
                rightImage="send"
                [buttonText]="'quoteSummary.sendQuote' | translate"
                [type]="'submitted'"
                btnType="primary-outlined"
                [btnStyle]="{ width: '141px' }"
                (handleClick)="handleSendQuoteAfterSaving()"
                [isDisabled]=""
              >
              </boxxds-button>
            </div>
            <ng-container
              *ngIf="
                this.permissionList[this.currentScreen] &&
                this.currentQuoteStatus !== 'quote-closed'
              "
            >
              <boxxds-button
                [isDisabled]="isTBDValid"
                *ngIf="!showBodySpinner"
                [buttonText]="'common.bindPolicy' | translate"
                btnType="secondary"
                [btnStyle]="{ width: '122px', marginRight: '12px' }"
                type="submit"
                (handleClick)="handleProductSummaryAndBindPolicy()"
              >
              </boxxds-button>
            </ng-container>
          </div>
        </div>
      </app-footer-wrapper>
    </ng-container>
  </div>
</div>
