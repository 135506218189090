<ng-container>
  <boxxds-search-autocomplete-v2
    [maxLength]="'50'"
    [form]="form"
    [isActive]="isActive"
    [formSubmitted]="formSubmitted"
    [requiredErrMsg]="requiredErrMsg"
    [items]="locations"
    [label]="'location.label.addressLine1' | translate"
    [required]="true"
    [control]="'address'"
    [placeholder]="'location.label.searchForLocation' | translate"
    [autocapitalize]="'off'"
    [spellcheck]="'off'"
    [permissionStatus]="this.permissionList[this.currentScreen]"
    [containerStyle]="{ width: '376px' }"
    (searchQuery)="
      this.permissionList[this.currentScreen] && isActive && getPlaces($event)
    "
    (itemSelected)="
      this.permissionList[this.currentScreen] &&
        isActive &&
        selectPlaceById($event)
    "
    [containerStyle]="{ marginBottom: '20px' }"
    [selectedFromSuggestion]="
      showPrefilledStyleFromInitialValue
        ? isAutoFilled(form.value['address'], initialValues?.address)
        : selectedFromSuggestion
    "
  ></boxxds-search-autocomplete-v2>

  <div class="form-control-wrapper">
    <boxxds-textinput-v2
      [form]="form"
      [placeholder]="'Enter apartment/suite/building number' | translate"
      [control]="'addressLine2'"
      [id]="'address'"
      [width]="'100%'"
      [label]="'location.label.addressLine2(optional)' | translate"
      class="input-fields"
      class="address2"
      maxLength="10"
      [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
      [formSubmitted]="formSubmitted"
      [containerStyle]="{ marginBottom: '20px', minWidth: '245px' }"
      [selectedFromSuggestion]="
        showPrefilledStyleFromInitialValue
          ? isAutoFilled(
              form.value['addressLine2'],
              initialValues?.addressLine2
            )
          : selectedFromSuggestion
      "
    >
    </boxxds-textinput-v2>

    <boxxds-textinput-v2
      [form]="form"
      [placeholder]="'location.placeholder.city' | translate"
      [control]="'city'"
      [id]="'city'"
      [width]="'100%'"
      [label]="'City' | translate"
      class="input-fields"
      [requiredErrMsg]="requiredErrMsg"
      [formSubmitted]="formSubmitted"
      class="city"
      [isRequired]="true"
      maxLength="25"
      [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
      [containerStyle]="{ marginBottom: '20px', minWidth: '245px' }"
      [selectedFromSuggestion]="
        showPrefilledStyleFromInitialValue
          ? isAutoFilled(form.value['city'], initialValues?.city)
          : selectedFromSuggestion
      "
    >
    </boxxds-textinput-v2>
  </div>

  <boxxds-textinput-v2
    [form]="form"
    [control]="'country'"
    [placeholder]="'Select' | translate"
    [label]="'Country' | translate"
    maxLength="250"
    class="input-fields"
    [formSubmitted]="formSubmitted"
    class="country"
    [readonly]="true"
    [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
    [containerStyle]="{ marginBottom: '20px', display: 'none' }"
    [selectedFromSuggestion]="
      showPrefilledStyleFromInitialValue
        ? isAutoFilled(form.value['country'], initialValues?.country)
        : selectedFromSuggestion
    "
  >
  </boxxds-textinput-v2>

  <div class="form-control-wrapper">
    <boxxds-dropdown-v2
      [form]="form"
      [control]="'province'"
      [placeholder]="'Select' | translate"
      (currentValueChange)="
        this.permissionList[this.currentScreen] &&
          isActive &&
          handleProvinceChange($event)
      "
      [options]="provinceOrStateList"
      [label]="province ?? '' | translate"
      class="input-fields"
      [requiredErrMsg]="requiredErrMsg"
      [formSubmitted]="formSubmitted"
      class="province"
      [currentValue]="form?.value?.province"
      [isRequired]="true"
      [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
      (loadMoreOptions)="
        this.permissionList[this.currentScreen] &&
          isActive &&
          loadMoreRegion($event)
      "
      [hasScrollablePagination]="true"
      [totalCount]="regionTotalRecords"
      [totalPages]="regionTotalPages"
      [dropdownContainerStyle]="{ marginBottom: '20px', minWidth: '245px' }"
      [isSuggestedOption]="
        showPrefilledStyleFromInitialValue
          ? isAutoFilled(form.value['province'], initialValues?.province)
          : selectedFromSuggestion
      "
      [currentSelectedOption]="currentProvinceObj"
    >
    </boxxds-dropdown-v2>

    <boxxds-textinput-v2
      [form]="form"
      [placeholder]="
        ('common.enter' | translate) + ' ' + (zip ? zip.toLowerCase() : '')
      "
      [control]="'zipPostalCode'"
      [id]="'postalCode'"
      [width]="'100%'"
      [label]="zip ?? '' | translate"
      class="input-fields"
      [customErrMsg]="'common.invalidErrorMessage' | translate"
      [requiredErrMsg]="requiredErrMsg"
      [formSubmitted]="formSubmitted"
      [isRequired]="true"
      class="postal-code"
      maxLength="10"
      [mask]="mask"
      [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
      [dropSpecialCharacters]="false"
      [containerStyle]="{ marginBottom: '20px', minWidth: '245px' }"
      [selectedFromSuggestion]="
        showPrefilledStyleFromInitialValue
          ? isAutoFilled(
              form.value['zipPostalCode'],
              initialValues?.zipPostalCode
            )
          : selectedFromSuggestion
      "
    >
    </boxxds-textinput-v2>
  </div>
</ng-container>
