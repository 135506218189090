<div class="custom-modal" *ngIf="showModal">
  <div class="modal-content">
    <div class="close-btn">
      <button (click)="closeHandler()">
        <img src="assets/img/dashboard/card/close.png" />
        {{ "common.close" | translate }}
      </button>
    </div>
    <div class="content">
      <div class="d-flex flex-column align-items-center">
        <img
          src="assets/img/alert-triangle.jpg"
          width="66px"
          height="66px"
          alt="alert"
        />
        <boxxds-body
          sz="md"
          [text]="'sanctionModal.heading' | translate"
          [customStyle]="{ color: '#FE802C', marginTop: '12px' }"
        ></boxxds-body>
      </div>
      <div class="modal-description">
        <boxxds-body
          sz="sm"
          [text]="'sanctionModal.subHeading' | translate"
          [customStyle]="{
            color: '#333334',
            textAlign: 'center',
            fontSize: '13px'
          }"
        ></boxxds-body>
      </div>
      <div class="policy-details d-flex flex-column">
        <div class="d-flex mt-1">
          <boxxds-body
            sz="sm"
            [text]="'productForm.heading.insured:' | translate"
            [customStyle]="{
              color: '#616162',
              marginRight: '4px',
              lineHeight: '14px'
            }"
          ></boxxds-body>
          <boxxds-body
            sz="sm"
            [text]="existingQuoteOptionData.insured"
            [customStyle]="{ color: '#616162', lineHeight: '14px' }"
          ></boxxds-body>
        </div>
      </div>
      <div class="sanctions-container">
        <boxxds-body
          sz="md"
          [text]="'sanctionModal.tableHeader' | translate"
          [customStyle]="{ color: '#616162', marginBottom: '10px' }"
        ></boxxds-body>
        <boxxds-table
          [tblContainerStyle]="{ maxHeight: '750px', overflow: 'auto' }"
          [data]="data"
          [showPagination]="false"
          class="table"
          [isRowClickable]="false"
          id="restrictive-industry"
          [tableStyle]="{ marginBottom: '30px' }"
          [showEmptyCellIndicator]="true"
          [showPopOver]="true"
          [popOverColumn]="'Reasons'"
        >
        </boxxds-table>
      </div>
      <div class="modal-buttons">
        <a
          class="cancel-link"
          href="javascript:;"
          (click)="actionDiscard($event)"
        >
          {{ "common.discardChanges" | translate }}
        </a>
        <boxxds-button
          [buttonText]="
            'sanctionModal.button.declineAndSaveChanges' | translate
          "
          [type]="'submitted'"
          btnType="secondary"
          [btnStyle]="{
            marginLeft: '20px'
          }"
          (handleClick)="actionDeclineAndSaveChanges()"
        >
        </boxxds-button>
        <boxxds-button
          [isDisabled]="isRestrictedIndustry"
          [buttonText]="'sanctionModal.button.proceedWithChanges' | translate"
          [type]="'submitted'"
          btnType="primary"
          [btnStyle]="{
            width: '171px',
            marginLeft: '20px'
          }"
          (handleClick)="actionProceedWithChanges($event)"
        >
        </boxxds-button>
      </div>
    </div>
  </div>
</div>
