<div class="holder" *ngIf="(form$ | async)?.quoteOptions as quoteOptions">
  <boxxds-alert
    *ngIf="showErrorAlert"
    type="error"
    [headerText]="'common.errorMsg' | translate"
    [bodyText]="alertMsg | translate"
    (handleCloseEvent)="closeAlert()"
  >
  </boxxds-alert>
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>
  <div class="header" *ngIf="form$ | async as form">
    <div class="insured-name-wraper">
      <boxxds-heading
        [sz]="'5'"
        [text]="
          ('common.quote' | translate) + ' - ' + (form$ | async)?.ui.insuredName
        "
        [customStyle]="{ marginTop: '2px' }"
      >
      </boxxds-heading>
      <boxxds-body
        class="quote-id"
        [text]="('common.quoteId' | translate) + ': ' + policyId"
      ></boxxds-body>
    </div>
    <div
      class="btns-holder d-flex"
      *ngIf="
        this.permissionList[this.currentScreen] &&
        this.currentQuoteStatus !== 'quote-closed'
      "
    >
      <boxxds-button
        rightImage="send"
        [buttonText]="'quoteCompare.sendComparisons' | translate"
        [type]="'submitted'"
        btnType="primary-outlined"
        [btnStyle]="{ width: '187px' }"
        (handleClick)="handleSendQuoteComparison()"
        [sz]="'sm'"
      >
      </boxxds-button>
    </div>
    <app-modal-send
      *ngIf="showSendModal"
      [isLoader]="isLoader"
      [showModal]="showSendModal"
      (handleClose)="closeSendPackage()"
      [formDetails]="form.ui"
      [templateDetails]="templateDetails"
      [docDetails]="docDetails"
      [title]="
        ('quoteCompare.sending' | translate) +
        ' ' +
        quotesToCompare.length +
        ' ' +
        ('quoteCompare.quoteOptionComparison' | translate)
      "
      (handleSend)="handleSend($event)"
    >
    </app-modal-send>
  </div>

  <div
    class="compare-box"
    [ngClass]="{
      'grid-column-3': quotesToCompare.length === 2,
      'grid-column-4': quotesToCompare.length === 3
    }"
  >
    <div class="product-column"></div>
    <div
      class="hdr-container"
      *ngFor="let quote of getQuotesToCompare(quoteOptions); let i = index"
    >
      <div class="remove-holder" *ngIf="quotesToCompare.length > 2">
        <button class="btn-remove" (click)="handleRemove(quote.index, quote)">
          <img src="assets/img/dashboard/quote/remove.png" />{{
            "common.remove" | translate
          }}
        </button>
      </div>
      <div
        class="px-xl-5 px-lg-3 hdr-quote-option box-style"
        [ngClass]="{ selected: quote?.ui?.optionSelected }"
      >
        {{
          ("quoteCompare.quoteOption" | translate) +
            " " +
            quote?.products[0]?.optionNumber
        }}
      </div>
    </div>
    <ng-container *ngFor="let product of getProducts()">
      <div class="product-row">
        <div class="product-name">
          <button class="btn-toggle" (click)="toggleProduct(product)">
            <img src="assets/img/dashboard/quote/chevron-up.png" />{{ product }}
          </button>
        </div>
      </div>
      <div
        class="box-style pdt-row"
        [ngClass]="{ selected: quote?.ui?.optionSelected }"
        *ngFor="let quote of quotesToCompare; let i = index"
      ></div>
      <div
        class="divider"
        [ngClass]="{
          'grid-col-4': quotesToCompare.length === 2,
          'grid-col-5': quotesToCompare.length === 3
        }"
      ></div>
      <ng-container *ngIf="this.toggle[product]">
        <div class="lbls-pdt-details">
          <div class="lbl-pdt-details">{{ "common.limit" | translate }}</div>
          <div class="lbl-pdt-details">
            {{ "common.deductible" | translate }}
          </div>
          <div class="lbl-pdt-details">{{ "common.premium" | translate }}</div>
          <div class="lbl-pdt-details">
            {{ "common.commission" | translate }}
          </div>
        </div>
        <ng-container *ngFor="let quote of quotesToCompare; let i = index">
          <div
            class="pdt-values-box"
            *ngIf="getProductValuesOfQuote(i, product) as productValues"
          >
            <div
              class="pdt-values box-style"
              [ngClass]="{ selected: quote?.ui?.optionSelected }"
            >
              <div class="pdt-value">
                {{
                  productValues.length > 0
                    ? productValues[0].limit
                      ? (productValues[0].limit
                        | currencyFormat: currency : "symbol-narrow" : "1.0-0")
                      : ("0"
                        | currencyFormat: currency : "symbol-narrow" : "1.0-0")
                    : ""
                }}
              </div>
              <div class="pdt-value">
                {{
                  productValues.length > 0
                    ? isNumber(productValues[0].deductables)
                      ? (productValues[0].deductables
                        | currency: currency : "symbol-narrow" : "1.0-0")
                      : productValues[0].deductables
                    : ""
                }}
              </div>
              <div class="pdt-value">
                {{
                  productValues.length > 0
                    ? isNumber(productValues[0].premium)
                      ? (productValues[0].premium
                        | currency: currency : "symbol-narrow" : "1.0-0")
                      : productValues[0].premium
                    : ""
                }}
              </div>
              <div class="pdt-value">
                {{
                  productValues.length > 0
                    ? productValues[0].brokerCommissionPerc + "%"
                    : ""
                }}
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <div class="product-row">
      <div class="product-name">
        <button
          class="btn-toggle"
          (click)="this.toggle.expandFeeSection = !this.toggle.expandFeeSection"
        >
          <img src="assets/img/dashboard/quote/chevron-up.png" />{{
            "common.feesServicesAndTaxes" | translate
          }}
        </button>
      </div>
    </div>
    <div
      class="box-style pdt-row"
      [ngClass]="{ selected: quote?.ui?.optionSelected }"
      *ngFor="let quote of quotesToCompare; let i = index"
    ></div>
    <div
      class="divider"
      [ngClass]="{
        'grid-col-4': quotesToCompare.length === 2,
        'grid-col-5': quotesToCompare.length === 3
      }"
    ></div>
    <ng-container *ngIf="this.toggle.expandFeeSection">
      <div class="lbls-pdt-details">
        <div class="lbl-pdt-details">{{ "common.policyFees" | translate }}</div>
        <div class="lbl-pdt-details">
          {{ "common.serviceAmount" | translate }}
        </div>
        <div class="lbl-pdt-details">{{ "common.salesTaxes" | translate }}</div>
        <div class="lbl-pdt-details">{{ "common.tria" | translate }}</div>
        <div class="lbl-pdt-details">
          {{ "common.insuranceTaxes" | translate }}
        </div>
      </div>
      <div
        class="pdt-values-box"
        *ngFor="let quote of quotesToCompare; let i = index"
      >
        <div
          class="pdt-values box-style"
          [ngClass]="{ selected: quote?.ui?.optionSelected }"
        >
          <div class="pdt-value">
            {{
              isNumber(quote.total.policyFee)
                ? (quote.total.policyFee | currency: currency : "symbol-narrow")
                : quote.total.policyFee
            }}
          </div>
          <div class="pdt-value">
            {{
              isNumber(quote.total.servicesAmount)
                ? (quote.total.servicesAmount
                  | currency: currency : "symbol-narrow")
                : quote.total.servicesAmount
            }}
          </div>
          <div class="pdt-value">
            {{
              isNumber(quote.total.taxesServicesAmount)
                ? (quote.total.taxesServicesAmount
                  | currency: currency : "symbol-narrow")
                : quote.total.taxesServicesAmount
            }}
          </div>
          <div class="pdt-value">
            {{
              isNumber(quote.total.triaAmount)
                ? (quote.total.triaAmount
                  | currency: currency : "symbol-narrow")
                : quote.total.triaAmount
            }}
          </div>
          <div class="pdt-value">
            {{
              isNumber(quote.total.taxesPremiumAmount)
                ? (quote.total.taxesPremiumAmount
                  | currency: currency : "symbol-narrow")
                : quote.total.taxesPremiumAmount
            }}
          </div>
        </div>
      </div>
    </ng-container>

    <div class="product-row">
      <div class="product-name">
        <button
          class="btn-toggle"
          (click)="
            this.toggle.expandPremiumSection = !this.toggle.expandPremiumSection
          "
        >
          <img src="assets/img/dashboard/quote/chevron-up.png" />{{
            "common.premium" | translate
          }}
        </button>
      </div>
    </div>
    <div
      class="box-style pdt-row"
      *ngFor="let quote of quotesToCompare; let i = index"
      [ngClass]="{ selected: quote?.ui?.optionSelected }"
    ></div>
    <div
      class="divider"
      [ngClass]="{
        'grid-col-4': quotesToCompare.length === 2,
        'grid-col-5': quotesToCompare.length === 3
      }"
    ></div>
    <ng-container *ngIf="this.toggle.expandPremiumSection">
      <div class="lbls-pdt-details">
        <div class="lbl-pdt-details lbl-subtotal-premium">
          {{ "common.subtotalPremium" | translate }}
        </div>
        <div class="lbl-pdt-details">
          {{ "common.totalAmount" | translate }}
        </div>
      </div>
      <div
        class="pdt-values-box"
        *ngFor="let quote of quotesToCompare; let i = index"
      >
        <div
          class="premium-values box-style"
          [ngClass]="{ selected: quote?.ui?.optionSelected }"
        >
          <div class="subtotal-premium">
            {{
              isNumber(quote.total.premium)
                ? (quote.total.premium | currency: currency : "symbol-narrow")
                : quote.total.premium
            }}
          </div>
          <div class="edited-premium">
            {{
              isNumber(quote.total.totalAmount)
                ? (quote.total.totalAmount
                  | currency: currency : "symbol-narrow")
                : quote.total.totalAmount
            }}
          </div>
        </div>
      </div>
    </ng-container>
    <div></div>
    <ng-container
      *ngIf="
        this.permissionList[this.currentScreen] &&
        this.currentQuoteStatus !== 'quote-closed'
      "
    >
      <div
        class="select-holder pr-xl-5 pr-lg-3"
        *ngFor="let quote of quotesToCompare; let i = index"
      >
        <button
          type="button"
          class="btn-select"
          (click)="handleQuoteSelect(quote?.products[0]?.optionNumber, quote)"
        >
          <img
            [src]="
              quote?.ui?.optionSelected
                ? 'assets/img/plus-circle-filled.png'
                : 'assets/img/plus-circle.png'
            "
          /><span>{{ "common.select" | translate }}</span>
        </button>
      </div>
    </ng-container>
  </div>

  <div class="continue-btn-holder">
    <boxxds-button
      [buttonText]="'common.back' | translate"
      btnType="secondary"
      [btnStyle]="{ width: '88px' }"
      type="submit"
      (handleClick)="handleBackBtn()"
      class="back-btn"
    >
    </boxxds-button>
    <div class="save-continue">
      <boxxds-button
        [buttonText]="'submission.continueToStep5Of7' | translate"
        btnType="secondary-solid"
        (handleClick)="handleContinue($event)"
        [btnStyle]="{ width: '199px' }"
      >
      </boxxds-button>
    </div>
  </div>
  <!-- Document Template selection-->

  <app-doc-template-selection-popup
    *ngIf="showDocTempSelectionPopup"
    [showModal]="showDocTempSelectionPopup"
    [details]="docPopupDetails"
    (handleSuccess)="handleSuccessDocTemplate($event)"
    (handleActionConfirm)="
      this.permissionList[this.currentScreen] && handleDocLevelAction($event)
    "
    (handleClose)="showDocTempSelectionPopup = false"
  ></app-doc-template-selection-popup>
  <!-- Document Template selection-->
</div>
