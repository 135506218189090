<div class="holder">
  <div *ngIf="isPopoverOpen" class="popover-content">
    <div class="body-spinner" *ngIf="!reasonsLoaded">
      <boxxds-spinner
        [containerStyle]="{
          width: '30px',
          height: '30px',
          '--b': '4px',
          marginTop: '4px'
        }"
      ></boxxds-spinner>
    </div>
    <div
      class="d-flex align-items-start justify-content-between"
      *ngIf="reasonsLoaded"
      (clickOutside)="closePopover()"
    >
      <div class="reasons">
        <ul>
          <li *ngFor="let reason of reasons; let i = index">
            {{ i + 1 }}. {{ reason }}
          </li>
        </ul>
      </div>
      <div class="close-btn">
        <button (click)="closePopover()" aria-label="Close">
          <img src="assets/img/dashboard/card/close.png" />
        </button>
      </div>
    </div>
  </div>
</div>
