<div
  class="company-container"
  [ngStyle]="isSuccess && hasError && { paddingTop: '140px' }"
>
  <div class="alert-holder">
    <boxxds-alert
      *ngIf="showErrorAlert"
      (handleCloseEvent)="handleCloseSuccessEventExt()"
      type="error"
      [headerText]="'common.errorMsg' | translate"
      [bodyText]="msgErrorAlert"
    >
    </boxxds-alert>
    <boxxds-alert
      *ngIf="isSuccess"
      (handleCloseEvent)="handleCloseSuccessEvent()"
      type="success"
      [headerText]="'common.successMsg' | translate"
      [bodyText]="'common.savedSuccesfully' | translate"
      [containerStyle]="{
        width: 'calc(100vw - 320px)',
        maxWidth: '1115px',
        position: 'fixed',
        top: '100px',
        zIndex: '2',
        marginTop: '8px'
      }"
    >
    </boxxds-alert>

    <boxxds-alert
      *ngIf="hasError"
      (handleCloseEvent)="handleCloseSuccessEvent()"
      type="error"
      [headerText]="'common.errorMsg' | translate"
      [bodyText]="errorMessage"
      [containerStyle]="{
        width: 'calc(100vw - 320px)',
        maxWidth: '1115px',
        position: 'fixed',
        top: '100px',
        zIndex: '2',
        marginTop: '8px'
      }"
    >
    </boxxds-alert>
  </div>
  <div class="create-title-container">
    <boxxds-body
      sz="lg"
      [text]="'systemUser.label.producerDetails' | translate"
      [customStyle]="{
        color: '#616162',
        textAlign: 'left',
        marginBottom: '8px',
        fontSize: '16px'
      }"
    ></boxxds-body>
    <span style="text-transform: uppercase"
      >*{{ "common.required" | translate }}</span
    >
  </div>
  <div class="search-container">
    <form [formGroup]="producerUserForm">
      <div class="add-container">
        <div class="d-flex">
          <boxxds-searchable-dropdown
            [form]="producerUserForm"
            [control]="'brokerId'"
            [placeholder]="'placeholder.searchBrokerage' | translate"
            [options]="brokers"
            [label]="('common.brokerage' | translate) + '*'"
            [btnStyle]="{ marginTop: '8px', width: '264px' }"
            [dropdownContainerStyle]="{ width: '264px', marginRight: '40px' }"
            class="employees"
            [requiredErrMsg]="requiredErrMsg | translate"
            [invalidErrMsg]="formSubmitted ? (invalidProducer | translate) : ''"
            [formSubmitted]="formSubmitted"
            [currentValue]="broker"
            (currentValueChange)="brokerValueChanged($event)"
            (textInputChangeEventEmmiter)="searchBrokers($event)"
            [dispatchTextInputChange]="true"
          >
          </boxxds-searchable-dropdown>
          <boxxds-dropdown
            [form]="producerUserForm"
            [control]="'branchId'"
            [placeholder]="'placeholder.select' | translate"
            [options]="branches"
            [label]="('systemUser.label.branch' | translate) + '*'"
            [btnStyle]="{ marginTop: '8px', width: '264px' }"
            [dropdownContainerStyle]="{
              width: '264px',
              marginBottom: '30px',
              marginRight: '40px'
            }"
            class="employees"
            [requiredErrMsg]="requiredErrMsg | translate"
            [formSubmitted]="formSubmitted"
            [currentValue]="branch"
            (currentValueChange)="branchValueChanged($event)"
            (loadMoreOptions)="loadMoreBranchOptions($event)"
            [hasScrollablePagination]="true"
            [totalCount]="branchListTotalCount"
            [totalPages]="branchListTotalPages"
            [isReadonly]="!broker"
          >
          </boxxds-dropdown>

          <boxxds-searchable-dropdown
            [form]="producerUserForm"
            [control]="'roleId'"
            [placeholder]="'placeholder.searchRole' | translate"
            [options]="roles"
            [label]="('systemUser.label.role' | translate) + '*'"
            [invalidErrMsg]="formSubmitted ? (invalidRole | translate) : ''"
            [btnStyle]="{ marginTop: '18px', width: '264px' }"
            [dropdownContainerStyle]="{ width: '264px' }"
            class="employees"
            [requiredErrMsg]="requiredErrMsg | translate"
            [formSubmitted]="formSubmitted"
            [currentValue]="role"
            (currentValueChange)="roleValueChanged($event)"
            (textInputChangeEventEmmiter)="searchRoles($event)"
            [dispatchTextInputChange]="true"
          >
          </boxxds-searchable-dropdown>
        </div>
        <div class="d-flex">
          <boxxds-dropdown
            [form]="producerUserForm"
            [control]="'producerId'"
            [placeholder]="'systemUser.placeholder.selectProducer' | translate"
            [options]="producers"
            [label]="('systemUser.label.producer' | translate) + '*'"
            [btnStyle]="{ marginTop: '8px', width: '264px' }"
            [dropdownContainerStyle]="{ width: '264px', marginRight: '40px' }"
            class="employees"
            [requiredErrMsg]="requiredErrMsg | translate"
            [formSubmitted]="formSubmitted"
            [currentValue]="producer"
            (currentValueChange)="producerValueChanged($event)"
            (loadMoreOptions)="loadMoreProducerOptions($event)"
            [hasScrollablePagination]="true"
            [totalCount]="producerListTotalCount"
            [totalPages]="producerListTotalPages"
            [isReadonly]="!branch"
          >
          </boxxds-dropdown>

          <boxxds-textinput
            [placeholder]="'systemUser.placeholder.email' | translate"
            [form]="producerUserForm"
            [control]="'loginEmail'"
            [id]="'email'"
            [width]="'380px'"
            [label]="('systemUser.label.loginEmail' | translate) + '*'"
            [requiredErrMsg]="requiredErrMsg | translate"
            [invalidErrMsg]="invalidErrMsg | translate"
            [containerStyle]="{ marginRight: '40px' }"
            [formSubmitted]="formSubmitted"
            maxLength="150"
          >
          </boxxds-textinput>
        </div>
      </div>
      <div class="btn-container">
        <boxxds-button
          *ngIf="!showSpinner"
          [buttonText]="'systemUser.label.addProducer' | translate"
          btnType="primary-outlined"
          [btnStyle]="{ padding: '8px 28px' }"
          type="submit"
          [sz]="'sm'"
          (handleClick)="onSubmit(producerUserForm.value)"
        >
        </boxxds-button>
        <button
          *ngIf="showSpinner"
          class="btn-processing"
          tabindex="0"
          [ngStyle]="{
            border: '1px solid #FFBE3D',
            borderRadius: '8px',
            width: '150px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4px 8px',
            background: '#FCF8EF'
          }"
        >
          <boxxds-spinner></boxxds-spinner>
        </button>
      </div>
    </form>
  </div>
</div>
