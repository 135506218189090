import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BoxxResponse, Pagination } from 'src/app/entities/boxx-response';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { SystemUser } from 'src/app/entities/system-user';
import { RoleService } from 'src/app/services/roles.service';
import { Role } from 'src/app/entities/role';
import { getErrorMessage } from 'src/app/utils/utils';
import { RolePermissionService } from 'src/app/services/role-permissions.service';
import { SystemUserService } from 'src/app/services/system-user.service';
import { Permission } from 'src/app/entities/permission';
import { BrokerageProducerService } from 'src/app/services/brokerage-producer.service';
import { BrokerageBranchService } from 'src/app/services/brokerage-branch.service';
import { getUserTypes } from 'src/app/store/dashboard/dashboard.selector';
import { Store, select } from '@ngrx/store';
import { SYSTEM_USER_TYPES } from 'src/app/constants/security/systemUserType';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.less'],
})
export class UserProfileComponent implements OnInit {
  totalDataCount = 0;
  form: FormGroup;
  isRoleNameEditMode = false;
  addSortTo: string | Array<string> = 'all';
  pagination: Pagination;
  permissions: any = [];
  permissionsDataCount = 0;
  permissionsPagination: Pagination;
  options = [
    { key: '2022', value: '2022' },
    { key: '2021', value: '2021' },
  ];
  fullNme;
  managerName;
  roleName;
  active;
  type;
  title;
  loginEmail;
  brokerage;
  branch;
  roleId;
  brokerageName;
  branchName;
  insuredType;
  boxxuser: boolean = false;
  producer: boolean = false;
  insured: boolean = false;
  userTypes: Array<any> = [];

  showErrorAlert = false;
  msgErrorAlert = '';
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activedRoute: ActivatedRoute,
    private systemUserService: SystemUserService,
    private roleService: RoleService,
    private rolePermissionService: RolePermissionService,
    private brokerageProducerService: BrokerageProducerService,
    private brokerageBranchService: BrokerageBranchService,
    private store: Store,
  ) {
    this.form = fb.group({
      roleName: [this.roleName],
    });
  }

  ngOnInit(): void {
    const route = this.activedRoute.snapshot.params;
    const systemUserId = 1;
    let userId = route['id'];

    this.store.pipe(select(getUserTypes)).subscribe((data) => {
      const tableData = data.map((dataObj) => ({
        key: dataObj.description,
        value: dataObj.id,
        id: dataObj.id,
      }));

      this.userTypes = [...tableData];
    });
    let systemUserType = route['type'];

    let selectedSystemUserType = this.userTypes.find(
      (item) => item.id === Number(systemUserType),
    )?.key;
    let userType = this.convertToCamelCase(
      selectedSystemUserType.toLowerCase(),
    );

    if (
      systemUserType == this.toLowerWithoutSpace(SYSTEM_USER_TYPES.BoxxUser)
    ) {
      this.boxxuser = true;
      this.insured = false;
      this.producer = false;
    } else if (
      systemUserType == SYSTEM_USER_TYPES.Broker.toLowerCase() ||
      systemUserType == SYSTEM_USER_TYPES.Producer.toLowerCase()
    ) {
      this.boxxuser = false;
      this.insured = false;
      this.producer = true;
    } else if (systemUserType == SYSTEM_USER_TYPES.Insured.toLowerCase()) {
      this.boxxuser = false;
      this.insured = true;
      this.producer = false;
    }
    this.fetchBoxxUsers(systemUserId, userId, systemUserType);
  }

  toLowerWithoutSpace(input) {
    let result = input.replace(/[A-Z]/g, (match) => ' ' + match.toLowerCase());
    result = result.replace(/\s+/g, '');
    return result;
  }

  convertToCamelCase(userType) {
    return userType
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, '');
  }

  fetchBoxxUsers(systemUserId, userId, systemUserType) {
    this.systemUserService.GetSystemUserProfile(systemUserType).subscribe({
      next: (response) => {
        const dataObj = response.data;
        if (this.boxxuser) {
          if (dataObj.boxxManager != null)
            this.managerName =
              dataObj.boxxManager.firstName +
              ' ' +
              dataObj.boxxManager.lastName;
          this.fullNme =
            dataObj.boxxUser.firstName + ' ' + dataObj.boxxUser.lastName;
          if (dataObj.systemUser != null) {
            this.roleName = dataObj.systemUser.role.name;
            this.roleId = dataObj.systemUser.role.id;
          }
          this.type = 'Boxx User';
          if (dataObj.systemUser != null) {
            this.title = dataObj.title;
          }
        }
        if (this.producer) {
          this.type = 'Producer';
          if (dataObj.producer != null) {
            this.fullNme =
              dataObj.producer.firstName + ' ' + dataObj.producer.lastName;
            const producerId = dataObj.producer.id;
            this.brokerageProducerService
              .GetBrokerageProducerId(producerId)
              .subscribe({
                next: (producerData) => {
                  const producer = producerData.data;
                  const brokerageBranchId = producer.brokerageBranch.id;
                  this.brokerageName = producer.brokerageBranch.name;
                  this.brokerageBranchService
                    .GetBrokerageBranch(brokerageBranchId)
                    .subscribe({
                      next: (branchData) => {
                        const branch = branchData.data;
                        this.branchName = branch.brokerage.name;
                      },
                      error: (error) => {
                        if (![500].includes(error?.status)) {
                          this.showErrorAlert = true;
                        }
                        this.msgErrorAlert = getErrorMessage(error);
                      },
                    });
                },
                error: (error) => {
                  if (![500].includes(error?.status)) {
                    this.showErrorAlert = true;
                  }
                  this.msgErrorAlert = getErrorMessage(error);
                },
              });
          }
        }
        if (this.insured) {
          if (dataObj.insured != null) {
            this.fullNme =
              dataObj.insured.firstName + ' ' + dataObj.insured.lastName;
          }
          this.type = 'Insured';
          if (dataObj.insured.insuredType == 2) {
            this.insuredType = 'Company';
          } else {
            this.insuredType = 'Individual';
          }
        }
        this.loginEmail = dataObj.loginEmail;
        this.active = dataObj.active ? 'ACTIVE' : 'INACTIVE';
        this.roleName = dataObj.role.name;
        this.roleId = dataObj.role.id;
        if (this.roleId) this.fetchPermissions(this.roleId);
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });
  }

  fetchPermissions(roleId) {
    this.rolePermissionService.GetAllPermissions(roleId, 'id:desc').subscribe({
      next: (response) => {
        this.setPermissionsResult(response);
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });
  }

  setPermissionsResult(data: BoxxResponse<Permission>) {
    const tableData = data.data?.map((dataObj) => {
      const { permission } = dataObj;
      return {
        ID: `${dataObj.id}`,
        'Permission name': permission.permissionName,
        Screen: 'screen',
      };
    });
    this.permissions =
      tableData.length == 0 ? this.getPermissionHeaders() : tableData;
    this.permissionsDataCount = data.pagination.totalRecords;
    this.permissionsPagination = data.pagination;
  }

  getPermissionHeaders() {
    return {
      ID: '',
      'Permission name': '',
      Screen: '',
    };
  }

  togglePolicyFeeEdit(event, isRoleNameEditMode) {
    event.stopPropagation();
    this.isRoleNameEditMode = !isRoleNameEditMode;
  }

  permissionPageChangeHandler(pageNumber) {
    if (pageNumber > 0) {
      this.rolePermissionService
        .GetAllPermissions(this.roleId, 'id:desc', pageNumber)
        .subscribe({
          next: (response) => {
            this.setPermissionsResult(response);
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    }
  }
  handleCloseSuccessEvent() {
    this.showErrorAlert = false;
  }
}
