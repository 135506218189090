<div class="holder">
  <boxxds-alert
    (handleCloseEvent)="handleNotificationAlertClose()"
    *ngIf="notificationAlert?.show"
    [type]="notificationAlert?.type"
    [headerText]="notificationAlert?.headerText"
    [bodyText]="notificationAlert?.bodyText"
  >
  </boxxds-alert>
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>
  <a
    class="back-btn"
    routerLink="/dashboard/home"
    [queryParams]="{ mock: mockExternalAPIs }"
    skipLocationChange="true"
    tabindex="0"
  >
    <img src="assets/img/dashboard/back.png" />
    <p>{{ "Back" | translate }}</p>
  </a>
  <div class="d-flex">
    <boxxds-heading
      sz="5"
      [text]="'Search results: ' | translate"
    ></boxxds-heading>
    <a
      [routerLink]="insuredNavigationUrl"
      skipLocationChange="true"
      [ngStyle]="{ textDecoration: 'none' }"
    >
      <boxxds-heading
        sz="5"
        [text]="searchKey"
        [customStyle]="{
          textDecoration: 'underline',
          textUnderlineOffset: '4px',
          textDecorationColor: '#333334',
          marginLeft: '4px'
        }"
      >
      </boxxds-heading>
    </a>
  </div>
  <div class="d-flex">
    <boxxds-body
      sz="xxs"
      text="Insured type:"
      [customStyle]="{ textTransform: 'uppercase', color: '#8F8F91' }"
    >
    </boxxds-body>
    <boxxds-body
      sz="xxs"
      [text]="insuredType"
      [customStyle]="{
        textTransform: 'uppercase',
        color: '#56565C',
        marginLeft: '4px'
      }"
    >
    </boxxds-body>
  </div>
  <div class="table-container">
    <boxxds-table
      [data]="tableData"
      [totalCount]="totalDataCount"
      (pageChanged)="handlePageChange($event)"
      class="table"
      (rowClicked)="handleRowClick($event)"
      [isRowClickable]="true"
      [showStatusAsBadge]="true"
      [mapStatusBadge]="statusMappingToType"
      [showBadgeFieldName]="'Status'"
      [addSortTo]="addSortTo"
      (sortUpClicked)="handleTableColSortUp($event)"
      (sortDownClicked)="handleTableColSortDown($event)"
      [showSpinner]="showTblSpinner"
    >
    </boxxds-table>
  </div>
</div>
