<div class="holder">
  <boxxds-breadcrumb [items]="itemsMenu"></boxxds-breadcrumb>
  <boxxds-heading
    sz="5"
    [ngStyle]="{ marginBottom: '50px' }"
    [text]="'New BOXX user' | translate"
    ><span></span>
  </boxxds-heading>
  <boxxds-alert
    *ngIf="showErrorAlert"
    (handleCloseEvent)="handleCloseSuccessEventExt()"
    type="error"
    headerText="error!"
    [bodyText]="msgErrorAlert"
  >
  </boxxds-alert>
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!showBodySpinner">
    <div class="company-container">
      <boxxds-alert
        *ngIf="isSuccess"
        (handleCloseEvent)="handleCloseSuccessEvent()"
        type="success"
        headerText="success!"
        bodyText="Saved successfully."
      >
      </boxxds-alert>

      <boxxds-alert
        *ngIf="hasError"
        (handleCloseEvent)="handleCloseSuccessEvent()"
        type="error"
        headerText="error!"
        [bodyText]="errorMessage"
      >
      </boxxds-alert>
      <div class="create-permission-title-container">
        <boxxds-body
          sz="lg"
          [text]="'BOXX user details' | translate"
          [customStyle]="{
            color: '#616162',
            textAlign: 'left',
            marginBottom: '8px'
          }"
        ></boxxds-body>
        <span>*REQUIRED</span>
      </div>
      <form [formGroup]="boxxUserForm">
        <div class="search-container">
          <div class="add-container">
            <div class="d-flex">
              <boxxds-textinput
                [placeholder]="'placeholder.enterFirstName' | translate"
                [form]="boxxUserForm"
                [control]="'firstName'"
                [id]="'firstName'"
                [width]="'264px'"
                [label]="'boxxUser.label.firstName' | translate"
                [requiredErrMsg]="requiredErrMsg | translate"
                [invalidErrMsg]="invalidErrMsg | translate"
                [containerStyle]="{ marginRight: '40px' }"
                [formSubmitted]="formSubmitted"
                maxLength="45"
              >
              </boxxds-textinput>
              <boxxds-textinput
                [placeholder]="'placeholder.enterLastName' | translate"
                [form]="boxxUserForm"
                [control]="'lastName'"
                [id]="'lastName'"
                [width]="'264px'"
                [label]="'boxxUser.label.lastName' | translate"
                [requiredErrMsg]="requiredErrMsg | translate"
                [invalidErrMsg]="invalidErrMsg | translate"
                [formSubmitted]="formSubmitted"
                maxLength="45"
              >
              </boxxds-textinput>
            </div>
            <div class="d-flex">
              <boxxds-textinput
                [placeholder]="'boxxUser.placeholder.enterTitle' | translate"
                [form]="boxxUserForm"
                [control]="'title'"
                [id]="'title'"
                [label]="('boxxUser.label.title' | translate) + '*'"
                [requiredErrMsg]="requiredErrMsg | translate"
                [invalidErrMsg]="invalidErrMsg | translate"
                [containerStyle]="{ width: '264px', marginRight: '40px' }"
                [formSubmitted]="formSubmitted"
                maxLength="150"
              >
              </boxxds-textinput>

              <boxxds-searchable-dropdown
                [form]="boxxUserForm"
                [control]="'manager'"
                [placeholder]="'placeholder.searchManager' | translate"
                [options]="managers"
                [invalidErrMsg]="
                  formSubmitted ? (invalidManager | translate) : ''
                "
                [label]="'boxxUser.label.manager' | translate"
                [btnStyle]="{ width: '264px' }"
                [dropdownContainerStyle]="{ width: '264px' }"
                class="employees"
                [requiredErrMsg]="requiredErrMsg | translate"
                [formSubmitted]="formSubmitted"
                [currentValue]="manager"
                (currentValueChange)="managerChanged($event)"
                (textInputChangeEventEmmiter)="searchManagers($event)"
                [dispatchTextInputChange]="true"
              >
              </boxxds-searchable-dropdown>
            </div>
          </div>
          <div class="btn-container">
            <boxxds-button
              *ngIf="!showSpinner"
              [buttonText]="'Add Boxx user' | translate"
              btnType="primary-outlined"
              [btnStyle]="{ padding: '0 30px 0 30px' }"
              [sz]="'sm'"
              type="submit"
              (handleClick)="onSubmit(boxxUserForm.value)"
            >
            </boxxds-button>
            <button
              *ngIf="showSpinner"
              class="btn-processing"
              tabindex="0"
              [ngStyle]="{
                border: '1px solid #FFBE3D',
                borderRadius: '8px',
                width: '150px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '4px 8px',
                background: '#FCF8EF'
              }"
            >
              <boxxds-spinner></boxxds-spinner>
            </button>
          </div>
        </div>
      </form>
    </div>
  </ng-container>
</div>
