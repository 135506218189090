import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs';
import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import { getLifecycleState } from 'src/app/store/lifecycle/lifecycle.select';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-quote-option',
  templateUrl: './quote-option.component.html',
  styleUrls: ['./quote-option.component.less'],
})
export class QuoteOptionComponent implements OnInit {
  form: FormGroup;

  @Input() quote;
  @Input() startDate;
  @Input() expiryDate;
  @Input() idxOfQuote: number;
  @Input() isDuplicateDisabled: boolean = false;
  @Input() domainIdForReferral: number;

  @Output() handleEdit = new EventEmitter();
  @Output() handleQuoteDelete = new EventEmitter();
  @Output() handleCompareSelected = new EventEmitter();
  @Output() handleDuplicateClick = new EventEmitter();
  @Output() handleQuoteSelected = new EventEmitter();
  @Output() handleToggleBtn = new EventEmitter();
  @Output() handleSublimitClick = new EventEmitter();

  currency = '';
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  currentQuoteStatus: string = '';
  sublimitIconSrc =
    'assets/img/dashboard/quote/lifecycle/arrow-right-circle.png';

  constructor(
    private store: Store,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
    });
    this.store.pipe(select(getLifecycleState)).subscribe((data) => {
      this.currentQuoteStatus = data.quoteStatus;
    });

    this.form = this.fb.group({
      quote: !!this.quote.ui.optionSelected,
    });
    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));
  }

  changeIconSrc(iconPath: string) {
    this.sublimitIconSrc = iconPath;
  }

  handleQuoteOptnEdit() {
    this.handleEdit.emit(this.quote);
  }

  isNumber(number) {
    return !isNaN(number);
  }

  getFinalPremium() {
    return this.quote.total.totalAmount
      ? this.quote.total.totalAmount
      : this.quote.total.premium;
  }

  showTotalAmount() {
    const referralWithPremiumZero = this.quote.products.filter(
      (product) =>
        product?.quoteStatus === this.domainIdForReferral &&
        (!product.premium || Number(product.premium) === 0),
    );
    if (referralWithPremiumZero?.length > 0) {
      return false;
    }
    return true;
  }

  format(date) {
    if (date) {
      var dateParts = date.split('/');
      return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    }
    return '';
  }

  handleDelete() {
    this.handleQuoteDelete.emit({ index: this.idxOfQuote, quote: this.quote });
  }

  handleCheckbox(index, value) {
    this.handleCompareSelected.emit(this.idxOfQuote);
  }

  handleDuplicate() {
    this.handleDuplicateClick.emit({
      optionNumber: this.quote?.products[0]?.optionNumber,
      index: this.idxOfQuote,
    });
  }

  handleQuoteSelect(optionNumber) {
    this.handleQuoteSelected.emit(optionNumber);
    this.form.controls['quote'].setValue(!!this.quote.ui.optionSelected);
  }

  handleToggle() {
    this.handleToggleBtn.emit(this.idxOfQuote);
  }

  handleSublimitAction(quoteOptionId, product) {
    this.sublimitIconSrc =
      'assets/img/dashboard/quote/lifecycle/arrow-right-circle-clicked.png';
    this.handleSublimitClick.emit({
      quoteOptionId,
      product,
      quote: this.quote,
    });
  }
}
