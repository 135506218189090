import { Action } from '@ngrx/store';

import { Quote } from '../../models/quote.model';
import * as actionTypes from './insured.action';
import { Insured } from 'src/app/models/insured.model';

const initialState: Insured = {
  name: '',
  alert: {
    show: false,
    type: '',
    header: '',
    body: '',
  },
  ui: {
    isNavigatedFromInsuredIndividualForm: false,
    insuredNavigationTab: '',
  },
};

export function insuredReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_INSURED_NAME:
      return {
        ...state,
        name: action?.name,
      };
    case actionTypes.SET_INSURED_ALERT:
      return {
        ...state,
        alert: {
          ...state.alert,
          ...action?.alertPayload,
        },
      };
    case actionTypes.RESET_IS_NAVIGATED_FROM_INDIVIDUAL_INSURED:
      return {
        ...state,
        ui: {
          ...state.ui,
          isNavigatedFromInsuredIndividualForm:
            action.alertPayload.isNavigatedFromIndividual,
        },
      };
    case actionTypes.SET_INSURED_NAVIGATION_TAB:
      return {
        ...state,
        ui: {
          ...state.ui,
          insuredNavigationTab: action.payload.navigationTab,
        },
      };
    default:
      return state;
  }
}
