<div class="custom-modal" *ngIf="showModal">
  <boxxds-alert
    (handleCloseEvent)="handleNotificationAlertClose()"
    *ngIf="notificationAlert?.show"
    [type]="notificationAlert?.type"
    [headerText]="notificationAlert?.headerText | translate"
    [bodyText]="notificationAlert?.bodyText | translate"
    [containerStyle]="{
      left: '50%',
      transform: 'translateX(-50%)',
      top: '10px'
    }"
  >
  </boxxds-alert>
  <div class="docPreviewLoader" *ngIf="docPreviewLoader && !isLoader">
    <div class="wrapper">
      <div class="close-btn">
        <button
          (click)="closeDocPreview()"
          [disabled]="docPreviewLoader && !isLoader"
        >
          <img src="assets/img/dashboard/card/close.png" />{{
            "common.close" | translate
          }}
        </button>
      </div>
      <div class="loader-section">
        <boxxds-spinner
          [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
        ></boxxds-spinner>
        <p class="loading-msg">
          {{ "common.generatingDocumentPreview" | translate }}
        </p>
      </div>
    </div>
  </div>
  <div class="docPreviewContainer">
    <app-document-preview
      *ngIf="showDocPreview && !docPreviewLoader && currentPreviewDocUrl"
      [docUrl]="currentPreviewDocUrl"
      (handleClose)="closeDocPreview()"
    >
    </app-document-preview>
  </div>
  <div class="modal-content">
    <div>
      <div class="close-btn">
        <button (click)="handleCloseClk()" [disabled]="isSending || isLoader">
          <img src="assets/img/dashboard/card/close.png" />{{
            "common.close" | translate
          }}
        </button>
      </div>
      <div class="modal-hdr">{{ title }}</div>
    </div>
    <div class="loader" *ngIf="isLoader">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
      <p class="loading-msg">{{ "common.generatingDocument" | translate }}</p>
      <div class="doc-progress-section" *ngFor="let document of docData">
        <div class="individual-doc-progress-section">
          <boxxds-body
            class="doc-name"
            sz="sm"
            [text]="document?.documentName"
            [customStyle]="{ color: '#8F8F91' }"
          >
          </boxxds-body>
          <boxxds-body
            class="doc-name-progress-status-separator"
            sz="sm"
            [text]="'---'"
            [customStyle]="{ color: '#8F8F91' }"
          >
          </boxxds-body>
          <boxxds-body
            class="doc-progress-status"
            sz="sm"
            [text]="document?.status"
            [customStyle]="{ color: '#8F8F91' }"
          >
          </boxxds-body>
        </div>
      </div>
    </div>
    <div *ngIf="!isLoader">
      <div class="grid">
        <div class="col-left">
          <div class="send-to-section">
            <div class="hdr">{{ "emailPopup.sendTo" | translate }}</div>
            <boxxds-checkbox
              [options]="sendOptions"
              (handleCheckbox)="handleCheckbox($event)"
              [lblStyle]="{ color: '#56565C' }"
            >
            </boxxds-checkbox>
            <div class="email-container">
              <ng-container *ngFor="let email of sendToEmailIds">
                <p class="email-id" *ngIf="isString(email.mailId)">
                  {{ email.mailId }}
                </p>
                <ng-container *ngIf="isObject(email.mailId)">
                  <p class="email-id" *ngFor="let mail_id of email.mailId">
                    {{ mail_id }}
                  </p>
                </ng-container>
              </ng-container>
            </div>

            <boxxds-dropdown
              [label]="'emailPopup.selectTemplateLabel' | translate"
              placeholder="Select"
              [options]="templateOptions"
              (currentValueChange)="handleEmailTemplate($event)"
              [btnStyle]="{ height: '36px' }"
              [dropdownContainerStyle]="{ width: '284px' }"
            >
            </boxxds-dropdown>
            <boxxds-textinput
              [placeholder]="'emailPopup.subjectPlaceholder' | translate"
              [form]="form"
              control="subject"
              [width]="'100%'"
              [label]="'emailPopup.subjectLabel' | translate"
              maxLength="250"
              [containerStyle]="{ maxWidth: '400px', marginTop: '14px' }"
            >
            </boxxds-textinput>
          </div>
        </div>
        <div class="col-right">
          <div class="document-heading">
            {{ "emailPopup.attachmentMainLabel" | translate }}
            <div class="sub-heading">
              {{ "emailPopup.attachmentSubLabel" | translate }}
            </div>
          </div>

          <div class="documents-holder">
            <div class="docs-container" *ngIf="docOptions?.length > 0">
              <ng-container
                *ngFor="let doc of docsAttached; let selectedIdx = index"
              >
                <div class="doc">
                  <button class="doc-name" (click)="handleDocPreview(doc)">
                    {{ doc }}
                  </button>
                  <button
                    type="button"
                    aria-label="close"
                    class="close-btn"
                    (click)="handleDocRemove(doc)"
                  >
                    <img src="assets/img/dashboard/quote/remove.png" />
                  </button>
                </div>
              </ng-container>
              <ng-container
                *ngFor="let doc of getUnselectedDocs(); let i = index"
              >
                <div class="rqd-doc">
                  <div class="d-flex">
                    <button (click)="handleAttachDoc(doc.value)">
                      <img [src]="'assets/img/plus-circle-filled.png'" />
                    </button>
                    <button
                      [ngStyle]="{
                        overflowWrap: 'break-word',
                        maxWidth: '300px',
                        textAlign: 'left'
                      }"
                      (click)="handleDocPreview(doc.key)"
                    >
                      {{ doc.key + (doc.mandatory ? "*" : "") }}
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="message-container" [formGroup]="form">
        <label> {{ "emailPopup.messageBody" | translate }}</label>
        <div>
          <angular-editor
            formControlName="message"
            [config]="editorConfig"
          ></angular-editor>
        </div>
      </div>
      <div class="send-wrapper">
        <boxxds-button
          *ngIf="!isSending"
          buttonText="Send"
          [type]="'submitted'"
          [btnStyle]="{ padding: '0 28px', height: '32px', marginLeft: 'auto' }"
          [isDisabled]="isSendDisabled()"
          (handleClick)="sendHandler()"
        >
        </boxxds-button>
        <button
          *ngIf="isSending"
          class="btn-processing"
          tabindex="0"
          [ngStyle]="{
            border: '1px solid #FFBE3D',
            borderRadius: '8px',
            width: '88px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4px 8px',
            background: '#FCF8EF',
            marginLeft: 'auto'
          }"
        >
          <boxxds-spinner></boxxds-spinner>
        </button>
      </div>
    </div>
  </div>
</div>
