import { Action, createAction } from '@ngrx/store';
import { ICommission } from 'src/app/models/commission.model';
import { ActionsCommission } from 'src/app/pages/brokerage/new-brokerage/constants/action-commission';

export const UPDATE_COMMISSION = 'UPDATE_COMMISSION';
export const CREATE_COMMISSION = 'CREATE_COMMISSION';
export const DESABLE_COMMISSION = 'DESABLE_COMMISSION';
export const ENABLE_COMMISSION = 'ENABLE_COMMISSION';
export const DELETE_COMMISSION = 'DELETE_COMMISSION';
export const UPDATE_STATE_COMMISSION = 'UPDATE_STATE_COMMISSION';
export const SET_FETCH_LOADER = 'SET_FETCH_LOADER';
export const SET_FETCH_REGION_ADD_COMMISSION_LOADER =
  'SET_FETCH_REGION_ADD_COMMISSION_LOADER';
export const SET_FETCH_REGION_UPDATE_COMMISSION_LOADER =
  'SET_FETCH_REGION_UPDATE_COMMISSION_LOADER';
export const SET_FETCH_COMMISSION_SUCCESS = 'SET_FETCH_COMMISSION_SUCCESS';
export const RESET_COMMISSIONS = 'RESET_COMMISSIONS';

export class SaveCommissionAction implements Action {
  readonly type = CREATE_COMMISSION;
  constructor(public payload: ICommission) {}
}

export class UpdateCommissionAction implements Action {
  readonly type = UPDATE_COMMISSION;
  constructor(public payload: { item: ICommission }) {}
}

export class DesableCommissionAction implements Action {
  readonly type = DESABLE_COMMISSION;
  constructor(public payload: { guiId: string }) {}
}

export class EnableCommissionAction implements Action {
  readonly type = ENABLE_COMMISSION;
  constructor(public payload: { guiId: string }) {}
}

export class DeleteCommissionAction implements Action {
  readonly type = DELETE_COMMISSION;
  constructor(public payload: { guId: string }) {}
}

export class UpdateStateCommissionAction implements Action {
  readonly type = UPDATE_STATE_COMMISSION;
  constructor(public payload: { guId: string; state: ActionsCommission }) {}
}

export class setFetchLoader implements Action {
  readonly type = SET_FETCH_LOADER;
  constructor(public payload: { isFetching: boolean }) {}
}

export class setFetchRegionAddCommissionLoader implements Action {
  readonly type = SET_FETCH_REGION_ADD_COMMISSION_LOADER;
  constructor(public payload: { isFetching: boolean }) {}
}

export class setFetchRegionUpdateCommissionLoader implements Action {
  readonly type = SET_FETCH_REGION_UPDATE_COMMISSION_LOADER;
  constructor(public payload: { isFetching: boolean }) {}
}

export class setFetchCommissionsSuccess implements Action {
  readonly type = SET_FETCH_COMMISSION_SUCCESS;
  constructor(public payload: { items: ICommission[] }) {}
}

export class resetCommissions implements Action {
  readonly type = RESET_COMMISSIONS;
  constructor() {}
}

export type Actions =
  | SaveCommissionAction
  | UpdateCommissionAction
  | DesableCommissionAction
  | EnableCommissionAction
  | DeleteCommissionAction
  | setFetchLoader
  | setFetchRegionAddCommissionLoader
  | setFetchRegionUpdateCommissionLoader
  | setFetchCommissionsSuccess
  | resetCommissions;
