import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { RiskRegionSubjectivityService } from 'src/app/services/risk-region-subjectivity.service';
import { RiskTemplateService } from 'src/app/services/risk-template';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { convertDate, getFormattedPeriod } from 'src/app/utils/formatDate';

@Component({
  selector: 'app-modal-declarations',
  templateUrl: './modal-declarations.component.html',
  styleUrls: ['./modal-declarations.component.less'],
})
export class ModalDeclarationsComponent implements OnInit {
  form: FormGroup;
  @Input() showModal: boolean = false;
  @Input() riskRegionId;
  @Input() product;
  @Input() selectedDeclarations;
  @Input() policyRiskIds;
  @Input() products;
  @Input() templateCodeForDeclaration;

  @Output() handleClose = new EventEmitter<any>();
  @Output() addHandler = new EventEmitter<any>();

  declarationOptions = [];
  currentPage = 1;
  totalPages = 1;
  totalCount = 1;

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private riskTemplateService: RiskTemplateService,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      filterKeyWord: [''],
    });
    this.handleDeclarationSearch('', this.product);
  }

  handleModalClick(event) {
    event.stopPropagation();
  }

  handleBackDropClick() {
    this.showModal = false;
    this.handleClose.emit();
  }

  async onSearch(event) {
    const keyword = event.target.value;
    if (keyword?.length >= 3 && keyword?.length <= 500) {
      await this.handleDeclarationSearch(keyword, this.product, 1, false, 10);
    } else {
      this.handleDeclarationSearch('', this.product, 1, false, 10);
    }
  }

  handleDeclarationSearch(
    searchKey,
    product,
    page = 1,
    isDefaultLoad = true,
    limit = 10,
  ) {
    const index = this.products.findIndex((p) => p == product);
    this.riskTemplateService
      .getRiskTemplate(
        1,
        this.riskRegionId,
        this.templateCodeForDeclaration,
        searchKey,
      )
      .subscribe((data) => {
        const newList = data.data.map((doc) => {
          let selected = this.selectedDeclarations[product].find(
            (x) => x.riskTemplateId == doc.id,
          )
            ? true
            : false;

          return {
            key: doc.templateDescription,
            value: doc.templateDescription,
            disabled: selected ? true : false,
            mandatory: doc.mandatory,
            url: doc.templatePath,
            templateType: doc.templateType,
            policyRiskId: this.policyRiskIds[index],
            riskTemplateId: doc.id,
            documentTblDeclarationId: null,
            dataInjection: doc.dataInjection,
          };
        });
        if (isDefaultLoad && this.declarationOptions[product]?.length > 0) {
          this.declarationOptions[product] = [
            ...this.declarationOptions[product],
            ...newList,
          ];
        } else {
          this.declarationOptions[product] = newList;
        }
      });
  }

  clearValue() {
    this.form.reset();
    this.handleDeclarationSearch('', this.product, 1, false, 10);
  }

  getFilteredResult(keyName) {
    return keyName?.replace(
      this.form.value['filterKeyWord'],
      `<strong>${this.form.value['filterKeyWord']}</strong>`,
    );
  }

  onScroll(event: any): void {
    const currentScrollPosition = event.target.scrollTop;
    const maxScrollPosition =
      event.target.scrollHeight - event.target.clientHeight;

    if (this.canLoadMoreOptions(currentScrollPosition, maxScrollPosition)) {
      this.currentPage++;
      this.handleDeclarationSearch(
        this.form.value['filterKeyWord'],
        this.product,
        this.currentPage,
      );
    }
  }

  canLoadMoreOptions(
    currentScrollPosition: number,
    maxScrollPosition: number,
    threshold: number = 4,
  ): boolean {
    return (
      maxScrollPosition - currentScrollPosition <= threshold &&
      this.declarationOptions[this.product].length <= this.totalCount &&
      this.currentPage <= this.totalPages
    );
  }

  addClickHandler(sub) {
    this.addHandler.emit({
      declaration: sub.key,
      product: this.product,
    });
  }
}
