<div [ngStyle]="containerStyle" class="container">
  <div class="form-group checkbox" [ngStyle]="formGroupStyle">
    <input
      type="checkbox"
      [ngClass]="{ CheckboxDisabled: isDisabled }"
      [disabled]="isDisabled"
      [checked]="checked"
      [ngStyle]="checkboxStyle"
      (change)="onCheckboxChange($event)"
    />
    <label class="checkbox-label" *ngIf="label" [ngStyle]="lblStyle">{{
      label
    }}</label>
  </div>
  <label *ngIf="sublabel" class="sub-label" [ngStyle]="subLabelStyle">
    {{ sublabel }}
  </label>
</div>
