import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from './base-services';
import { Region } from '../entities/region';
import { Observable, retry, catchError } from 'rxjs';
import { BoxxResponse } from '../entities/boxx-response';
import { Risk } from '../entities/risk';
import { RiskQuestion } from '../entities/risk-question';
import { ICommissionModel } from '../models/commission.model';
import { Commission } from 'src/app/entities/commission';

@Injectable({
  providedIn: 'root',
})
export class BrokerageProducerCommissionService extends BaseService<any> {
  // Base url
  baseurlBroker =
    environment.msBrokerBaseUrl +
    environment.brokerageProducerCommissionPathUrl;

  constructor(http: HttpClient) {
    const baseurl =
      environment.msProductriskBaseUrl + environment.brokerageCommissionPathUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  GetByRegionIdAndRiskIds(regionId, riskId): Observable<BoxxResponse<any>> {
    return this.http
      .get<BoxxResponse<any>>(
        this.baseurl + '?regionId=' + regionId + '&riskId=' + riskId,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetByRegionIdAndRiskIdsAndProducerId(
    regionId,
    riskId,
    producerId,
    active = -1,
  ): Observable<BoxxResponse<any>> {
    const { baseUrl, apiVersion, msBroker } = environment;
    let url = `${baseUrl}${msBroker}${apiVersion}${environment.brokerageProducerCommissionPathUrl}?regionId=${regionId}&riskId=${riskId}&brokerageProducerId=${producerId}`;
    if (active !== -1) {
      url = url + `&active=${active}`;
    }
    return this.http.get<BoxxResponse<any>>(url);
  }

  CreateCommission(commission: ICommissionModel): Observable<any> {
    const url = `${this.baseurlBroker}`;

    return this.http
      .post(url, JSON.stringify(commission), this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  UpdateCommission(
    commission: ICommissionModel,
    brokerageProducerCommissionId: number,
  ): Observable<any> {
    const url = `${this.baseurlBroker}${brokerageProducerCommissionId}`;

    return this.http
      .put(url, JSON.stringify(commission), this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  DeleteCommission(
    brokerageProducerCommissionId: number,
    queryParams?: any,
  ): Observable<any> {
    let url = `${this.baseurlBroker}${brokerageProducerCommissionId}`;
    let isFirstQueryParam = true;
    for (const key in queryParams) {
      if (
        queryParams.hasOwnProperty(key) &&
        queryParams[key] !== undefined &&
        queryParams[key] !== null &&
        queryParams[key] !== ''
      ) {
        if (isFirstQueryParam) {
          url += `?${key}=${queryParams[key]}`;
          isFirstQueryParam = false;
        } else {
          url += `&${key}=${queryParams[key]}`;
        }
      }
    }
    return this.http
      .delete(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  RestoreCommission(brokerageProducerCommissionId: number): Observable<any> {
    const url = `${this.baseurlBroker}restore/${brokerageProducerCommissionId}`;

    return this.http
      .put(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetAllBrokerageProducerCommission(
    filter: { brokerageProducerId: number } = null,
    page = 1,
    limit = 10,
    sort = 'id:desc',
  ): Observable<BoxxResponse<Commission>> {
    let queryParams = '';
    if (filter !== null) {
      queryParams =
        '&' +
        Object.entries(filter)
          .map(
            ([key, value]) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
          )
          .join('&');
    }
    const url = `${this.baseurlBroker}?&page=${page}&limit=${limit}&sort=${sort}${queryParams}`;
    return this.http.get<BoxxResponse<Commission>>(url);
  }

  GetAllActiveQuoteList(
    brokerageProducerCommissionId: number,
    queryParams,
    page = 1,
    limit = 10,
    sort = 'effectiveDt:desc',
  ): Observable<BoxxResponse<Commission>> {
    let url = `${this.baseurlBroker}activeQuotes/${brokerageProducerCommissionId}?&page=${page}&limit=${limit}&sort=${sort}`;
    // Append additional queryParams to the URL
    for (const key in queryParams) {
      if (
        queryParams.hasOwnProperty(key) &&
        queryParams[key] !== undefined &&
        queryParams[key] !== null &&
        queryParams[key] !== ''
      ) {
        url += `&${key}=${queryParams[key]}`;
      }
    }

    return this.http
      .get<BoxxResponse<Commission>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
