import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { take, Observable, firstValueFrom } from 'rxjs';
import { Store, select } from '@ngrx/store';

import {
  addSpaceBeforeCapitalLetter,
  convertStatusType,
} from 'src/app/dashboard/utils/lifecycle-utils';
import { PolicyRiskTrxService } from 'src/app/services/policy-risk-trx.service';
import { ConfCurrencyService } from 'src/app/services/conf-currency.service';
import {
  getLifecycleState,
  getLifecycleSlideout,
} from 'src/app/store/lifecycle/lifecycle.select';
import {
  appendTimeStamp,
  formatDate,
  formatDateMoment,
  getDateFormatForDatePicker,
} from 'src/app/utils/formatDate';
import { PolicyRiskTrxNotesService } from 'src/app/services/policy-risk-trx-notes-service';
import {
  formatAmountWithCurrency,
  removeDuplicatedKeys,
  getErrorMessage,
  valInDigits,
  getAlertHead,
  excelFileDownload,
} from 'src/app/utils/utils';
import { DomainsService } from 'src/app/services/domains.service';
import { ConfDeductibleService } from 'src/app/services/conf-deductible.service';
import { PolicyRiskDocService } from 'src/app/services/policy-risk-doc.service';
import { BrokerageProducerCommissionService } from 'src/app/services/brokerage-producer-commission.service';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import { Quote } from 'src/app/models/quote.model';
import { getQuoteSelector } from 'src/app/store/create-quote/create-quote.selector';
import { PolicyQuoteOptionService } from 'src/app/services/policy-quote-option.service';
import { UpdatePolicyLifeCycle } from '../../entities/policy';
import { PolicyQuoteAuditService } from 'src/app/services/policy-quote-audit.service';
import { Pagination } from 'src/app/entities/boxx-response';
import { isRead } from 'src/app/utils/notesUtils';
import { PolicyDetail } from 'src/app/dashboard/policy-lifecycle/objects/policy-lifecycle-objects';
import { PolicyRiskTrxReasons } from 'src/app/services/policy-risk-trx-reasons.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfTaxService } from 'src/app/services/conf-tax.service';
import { getCurrencySelector } from 'src/app/store/dashboard/dashboard.selector';
import { DatePipe } from '@angular/common';
import { PolicyPeriodService } from 'src/app/services/policy-period.service';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import {
  PolicyTransactionEndorsementRequestBody,
  PolicyTransactionInvoicesRequestBody,
} from 'src/app/entities/policy-lifecycle';
import { BrokerageCommissionService } from 'src/app/services/brokerage-commissions.service';
import { PolicyRiskDocUploadService } from 'src/app/services/policy-risk-doc-upload.service';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-policy-slideout',
  templateUrl: './policy-slideout.component.html',
  styleUrls: ['./policy-slideout.component.less'],
})
export class PolicySlideoutComponent implements OnInit, AfterContentChecked {
  @ViewChild('fileDropRef') fileDropRef!: ElementRef<HTMLInputElement>;
  insuredTimezone;
  isSaveButtonValid: boolean = false;
  showTabLoader: boolean = false;
  isSaveProcessing: boolean = false;
  showBodySpinner: boolean = true;
  showModalSpinner: boolean = true;
  allowedSaveStatuses: string[] = ['Endorsement'];
  notesEditPermissionStatus: string[] = ['Endorsement', 'PolicyChange'];
  limit: any;
  deductables: any;
  taxPercent;
  newTaxInvoiceamount;
  showLimitCustomInput: boolean = false;
  showDeductibleCustomInput: boolean = false;
  showEditedPremiumCustomField: boolean = false;
  showInvalidLimitErr: boolean = false;
  showInvalidLimitErrMsg: '';
  showInvalidDeductibleErr: boolean = false;
  showInvalidDeductibleErrMsg = '';
  isPopoverOpen = false;
  reasonsLoaded: boolean = false;
  private userId: number;
  policyRiskTrxId: number;
  private insuredId: number;
  policyRiskId: number;
  // private documentTypeId: number;
  activityData: { pagination: Pagination | null } = {
    pagination: null,
  };
  filesData: { pagination: Pagination | null } = {
    pagination: null,
  };
  underwriterId: number;
  currency;
  currencyId: any;
  showDocPreview = false;
  showImagePreview = false;
  currentPreviewDocUrl = '';
  currentFileType = '';
  policyNumber: string;
  status: string;
  riskId: number;
  form: FormGroup;
  private brokerageId: number;
  private producerId: number;
  private packagePolicyNumber: string;
  form$: Observable<Quote>;
  isPolicyFormDirty: boolean = false;
  policyFormSubscription;
  initialFormValues: any;
  policyId: number;
  formData: FormData;
  closeModalClicked: boolean = false;

  @Input() details!: PolicyDetail;
  @Input() showModal: boolean = false;
  @Input() transactionRiskLevelAction: string = '';

  @Input() isUnderwriter: boolean = false;

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleSave = new EventEmitter<any>();
  @Output() handleDownload = new EventEmitter<any>();
  tableHeaders = [
    'common.fileName',
    'common.updatedBy',
    'common.dateUpdated',
    'common.createdBy',
    'common.dateCreated',
    'common.fileDescription',
  ];

  subNavData = [
    { name: 'common.formDetails', value: 'form', active: true },
    { name: 'common.policyDocuments', value: 'documents', active: false },
    { name: 'common.history', value: 'history', active: false },
    { name: 'common.notes', value: 'notes', active: false },
  ];
  regionId = null;
  options = [{ key: '', value: '', id: '' }];
  currencyOptions = [{ id: '', key: '', value: '' }];
  limitOptions = [{ key: '', value: '' }];
  deductibleOptions = [{ key: '', value: '' }];
  files: any[] = [];
  notes: any;
  pastPolicies = [];
  auditDetails = [];
  totalCount: number = 1;
  currentPage: number = 1;
  itemsPerPage = 10;
  totalPages: number = 1;
  brokerageCommissionMax = 20;
  brokerageCommissionMin = 0;
  brokerageCommissionText;
  brokerageProducerCommissionMax = 0;
  brokerageProducerCommissionMin = 0;
  riskRegionId: number;
  brokerCommissionPerc: number;
  policyPeriodId: number = null;
  productList: { [x: string]: any }[] = [];
  products;
  riskTrxReasons: any;
  data = {
    products: [],
    total: {
      premium: 0,
      premiumCalculated: 0,
      policyFee: 0,
      netAmount: 0,
      taxesAmount: 0,
      totalAmount: 0,
      servicesAmount: 0,
      taxesPremiumAmount: 0,
      taxesServicesAmount: 0,
    },
    ui: {
      selectedForCompare: false,
      isQuoteBoxExpanded: true,
      optionSelected: false,
    },
  };
  limitExceedErrMsg = this.translate.instant(
    'quoteCalculator.error.limitExceedErrMsg',
  );
  limitMinimumErrMsg = this.translate.instant(
    'quoteCalculator.error.limitMinimumErrMsg',
  );
  deductibleExceedErrMsg = this.translate.instant(
    'quoteCalculator.error.deductibleExceedErrMsg',
  );
  deductibleMinimumErrMsg = this.translate.instant(
    'quoteCalculator.error.deductibleMinimumErrMsg',
  );
  isBrokerCommissionDisabled = false;
  limitErr;
  brokerageCommisionText;
  writerOptions: [];
  filterData: any;
  notesData: { pagination: Pagination | null; filterData: {} } = {
    pagination: null,
    filterData: {},
  };
  notesSearchKeyWord = '';
  dateSearchKeyWord = '';
  fromDateSearchKeyWord = '';
  toDateSearchKeyWord = '';
  writerSearchKeyWord: number;
  minLengthErrMsg: string = '';
  isEndDateValid: boolean = true;
  isStartDateValid: boolean = true;
  endDateErrMsg = '';
  startDateErrMsg = '';
  isNoteFormValid: boolean = false;
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  currentTagOptions: any[];
  docStageId = undefined;
  allowedFormats = [
    '.jpg',
    '.jpeg',
    '.gif',
    '.txt',
    '.doc',
    '.docx',
    '.pdf',
    '.pptx',
  ];
  maxFileSizeMB = 5;
  showDeleteFileConfirm = false;
  currentFile: { [x: string]: string } = {};
  currentFileIndex: number = undefined;
  shortDateFormat: string = '';
  longDateFormat: string = '';

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private policyRiskTrxService: PolicyRiskTrxService,
    private confCurrencyService: ConfCurrencyService,
    private policyRiskTrxNotesService: PolicyRiskTrxNotesService,
    private domainsService: DomainsService,
    private confDeductibleService: ConfDeductibleService,
    private policyRiskDocService: PolicyRiskDocService,
    private PolicyQuoteAuditService: PolicyQuoteAuditService,
    private brokerageProducerCommissionService: BrokerageProducerCommissionService,
    private policyQuoteOptionService: PolicyQuoteOptionService,
    private PolicyRiskTrxReasons: PolicyRiskTrxReasons,
    private translate: TranslateService,
    private ConfTaxService: ConfTaxService,
    private datePipe: DatePipe,
    private policyPeriodService: PolicyPeriodService,
    private brokerageCommissionService: BrokerageCommissionService,
    private policyRiskDocUploadService: PolicyRiskDocUploadService,
    private alertService: AlertService,
  ) {
    this.form = this.fb.group({
      notesSearchForm: this.fb.group({
        noteKeyWordSearch: [''],
        noteFromDateSearch: [''],
        noteToDateSearch: [''],
        noteWriterSearch: [''],
      }),
      inputNotes: ['', [Validators.maxLength(500)]],
      writerOptions: [],
      notes: [''], //Notes tab
      notesPagination: [],
      effectiveDate: ['', Validators.required],
      issuanceDate: [''],
      invoiceNumber: [''],
      currency: ['', Validators.required],
      limit: ['', Validators.required],
      aggregatedLimit: ['', Validators.required],
      deductible: ['', Validators.required],
      premium: ['', Validators.required],
      calcPremium: [''],
      taxes: [''],
      brokerCommission: [''],
      brokerCommissionPerc: [''],
      note: [''],
      tag: [''],
    });
  }
  get notesSearchFormCntrl() {
    return (<FormGroup>this.form.get('notesSearchForm')).controls;
  }
  get notesSearchForm() {
    return this.form.get('notesSearchForm') as FormGroup;
  }
  async ngOnInit(): Promise<void> {
    this.alertService.clearAlerts(-1);
    this.showModalSpinner = true;

    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });

    this.currentPage = this.activityData?.pagination?.currentPage || 1;
    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));
    this.store
      .select(getLifecycleState)
      .pipe(take(1))
      .subscribe((state) => {
        this.policyRiskTrxId = state?.policyRiskTrxId;
        this.status = state?.policyRiskTrxStatus;
        this.insuredId = state?.insuredId;
        this.riskId = state?.riskId;
      });

    this.store
      .select(getLifecycleSlideout)
      .pipe(take(1))
      .subscribe((state) => {
        this.brokerageId = state?.brokerageId;
        this.producerId = state?.producerId;
        this.packagePolicyNumber = state?.packagePolicyNumber;
        this.riskRegionId = state?.riskRegionId;
        this.policyRiskId = state?.policyRiskId;
        this.regionId = state?.regionId;
      });
    this.form$ = this.store.pipe(select(getQuoteSelector));
    this.form$
      .pipe(take(1))
      .subscribe(
        (event) => (
          (this.insuredTimezone = event.ui?.timezone),
          (this.policyPeriodId = Number(event.ui.policyPeriodId))
        ),
      );

    this.populateCurrencies();
    await Promise.all([
      await this.populateDropdownOptions(),
      await this.populateFormDetails(),
      await this.populateReferalReasons(),
    ]);
    this.showModalSpinner = false;
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  hasInvoiceNumber() {
    return (
      (this.form.controls['invoiceNumber'].value &&
        this.form.controls['invoiceNumber'].value !== '') ||
      this.form.controls['invoiceNumber'].value === -1
    );
  }

  isFieldsEditable() {
    if (
      this.transactionRiskLevelAction === 'endorse' ||
      this.transactionRiskLevelAction === 'policy change'
    ) {
      return false;
    } else if (
      this.notesEditPermissionStatus.includes(this.form.controls['tag'].value)
    ) {
      return false;
    } else if (!this.hasInvoiceNumber()) {
      return true;
    }
    return true;
  }

  isFieldsDisabled(transactionType = '') {
    if (
      (!this.hasInvoiceNumber() &&
        this.status?.toLowerCase() === 'endorsement') ||
      transactionType === 'endorsement' ||
      this.transactionRiskLevelAction?.toLowerCase() === 'endorse'
    ) {
      return false;
    } else if (
      this.hasInvoiceNumber() ||
      !this.allowedSaveStatuses.includes(this.form.controls['tag'].value)
    ) {
      return true;
    } else {
      return false;
    }
  }

  filterNotesList(pageNumber: number) {
    let policyRiskTrxIds = this.details.policyRiskTrxId.toString();
    this.currentTagOptions = [];

    const product = this.details.product;
    this.policyRiskTrxNotesService
      .getByPolicyRiskTrxId(policyRiskTrxIds, pageNumber, this.itemsPerPage)
      .subscribe({
        next: (resp) => {
          ({ pagination: this.notesData.pagination } = resp);
          const notes = resp.data.map((data) => ({
            content: data.note,
            writer: data.createdBy,
            writerPermission: data?.updatedBy ? data?.updatedBy : '',
            product: product,
            tag: data.tags,
            date: formatDate(data.createdDt, this.longDateFormat),
            id: data.id,
            updatedDt: data?.updatedDt
              ? formatDate(data.updatedDt, this.longDateFormat)
              : '',
            read: isRead(data.updatedDt ? data.updatedDt : data.createdDt),
          }));
          this.details.isUnreadNotesPresent =
            notes.filter((p) => !p.read).length > 0;
          this.notesData.filterData = '';
          this.form.controls['notes'].setValue(notes);
          this.form.controls['notesPagination'].setValue(this.notesData);
          this.showTabLoader = false;
        },
        error: (e) => {
          this.showTabLoader = false;
        },
      });
    this.currentPage = pageNumber;
  }

  populateFormDetails() {
    return new Promise<any>(async (resolve, reject) => {
      if (!this.policyRiskTrxId) {
        this.showTabLoader = false;
        return;
      }
      this.details.policyRiskTrxId = this.policyRiskTrxId;

      this.policyRiskTrxService
        .getByPolicyRiskTrxId(this.policyRiskTrxId)
        .subscribe({
          next: async (response) => {
            const data = response.data;

            if (!data?.brokerCommissionPerc) {
              this.brokerCommissionPerc = Number(0.0);
            }
            this.policyId = data?.policyRisk?.policyPeriod?.policy?.id;

            const status = data?.quoteStatus ? data.quoteStatus : data.type;
            this.form.controls['effectiveDate'].setValue(
              data?.transactionEffectiveDate !== null
                ? getDateFormatForDatePicker(
                    data?.transactionEffectiveDate,
                    this.shortDateFormat,
                  )
                : '',
            );

            this.form.controls['issuanceDate'].setValue(
              data?.issuanceDate !== null
                ? this.formatDate(data?.issuanceDate)
                : '',
            );

            this.form.controls['invoiceNumber'].setValue(
              data?.invoiceNumber ?? '',
            );
            this.form.controls['currency'].setValue(data?.currency?.name);
            this.form.controls['limit'].setValue(data?.limit);
            this.form.controls['aggregatedLimit'].setValue(
              data?.aggregatedLimit,
            );
            this.form.controls['deductible'].setValue(data?.deductible);
            this.form.controls['premium'].setValue(data?.premium);
            this.form.controls['calcPremium'].setValue(data?.premiumCalculated);
            this.form.controls['taxes'].setValue(data?.taxesPremiumAmount);
            this.deductables = data?.deductible;
            this.limit = data?.limit;

            if (data?.brokerCommissionPerc) {
              const brokerCommissionPercs = (
                parseFloat(data?.brokerCommissionPerc) * 100
              ).toFixed(2);
              this.form.controls['brokerCommissionPerc'].setValue(
                Number(brokerCommissionPercs),
              );

              this.brokerCommissionPerc = Number(brokerCommissionPercs);
            }
            if (this.transactionRiskLevelAction == 'endorse') {
              this.form.controls['tag'].setValue('Endorsement');
            } else if (this.transactionRiskLevelAction == 'policy change') {
              this.form.controls['tag'].setValue('PolicyChange');
            } else {
              this.form.controls['tag'].setValue(data?.type);
            }
            this.form.controls['note'].setValue(
              data?.notes !== null ? data?.notes : '',
            );
            this.policyNumber = this.details.id;
            this.status = addSpaceBeforeCapitalLetter(status);
            this.policyRiskId = data?.policyRisk?.id;
            this.underwriterId = data?.underwriterId;

            this.currencyId = this.currencyOptions
              ?.filter(
                (currencyOption) =>
                  currencyOption?.key.includes(data?.currency?.currencyCode),
              )
              .map((currencyOption) => currencyOption?.id)
              .join(',');
            this.handleSaveButtonEnabled();

            this.populatePastPolicies();
            this.populateActivityLog(this.currentPage);

            this.showTabLoader = false;
            resolve('true');
          },
          error: (error) => {
            this.showTabLoader = false;
            reject(error);
          },
        });
      this.initialFormValues = this.form.value;
    });
  }

  async populateReferalReasons() {
    return new Promise<any>(async (resolve, reject) => {
      if (!this.policyRiskTrxId) {
        return;
      }
      try {
        this.reasonsLoaded = false;
        this.PolicyRiskTrxReasons.getPolicyRiskTrxReasons(
          this.policyRiskTrxId,
        ).subscribe({
          next: async (reasonsData) => {
            const quoteOptionReasons = reasonsData?.data;

            if (quoteOptionReasons?.length > 0) {
              this.riskTrxReasons = quoteOptionReasons?.map((reason) =>
                reason?.comments
                  ? reason?.comments
                  : reason?.reasonCodeDto?.description,
              );
              this.reasonsLoaded = true;
            }
            resolve('true');
          },
          error: (error) => {
            reject(getErrorMessage(error));
          },
        });
        this.reasonsLoaded = true;
      } catch (error) {
        this.reasonsLoaded = true;
      }
    });
  }

  async populateDropdownOptions() {
    return new Promise<any>(async (resolve) => {
      if (!this.insuredId) {
        return;
      }
      await this.populateLimitOptions(this.regionId);
      await this.populateDeductibleOptions(this.regionId);
      await this.populateBrokerageCommission(
        this.riskId,
        this.brokerageId,
        this.producerId,
      );
      resolve('true');
    });
  }

  private populateLimitOptions(regionId) {
    this.domainsService.GetByDomainCode('CONFLIMITS').subscribe((data) => {
      const limitListFromDomain = data.data.map((dataObj) => ({
        key: formatAmountWithCurrency(dataObj.valueInt1, this.currency),
        value: valInDigits(dataObj.valueInt1),
        id: dataObj.id,
      }));

      this.limitOptions = [...limitListFromDomain];
      this.limitOptions.push({
        key: 'Custom amount',
        value: 'custom-amount',
      });
    });
  }

  populateDeductibleOptions(regionId) {
    this.confDeductibleService
      .GetByRegionIdAndRiskIds(regionId, this.riskId)
      .subscribe((data) => {
        const deductibleOptns = data.data?.map((dataObj) => ({
          key: formatAmountWithCurrency(dataObj.value, this.currency),
          value: valInDigits(dataObj.value),
          id: dataObj.id,
        }));

        deductibleOptns.push({ key: 'Custom amount', value: 'custom-amount' });
        this.deductibleOptions = removeDuplicatedKeys(deductibleOptns);
      });
  }

  private populatePastPolicies() {
    if (!this.policyId) {
      return;
    }

    this.policyPeriodService
      .GetAllByPolicyId(this.policyId)
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.pastPolicies = response.data?.map((policy) => ({
            policyNumber: this.policyNumber,
            period:
              formatDateMoment(policy?.effectiveDt, this.shortDateFormat) +
              ' - ' +
              formatDateMoment(policy?.expiryDt, this.shortDateFormat),
          }));
        },
        error: (error) => {},
      });
  }

  private populateCurrencies() {
    // To DO - save currencies in store
    this.confCurrencyService.GetAllCurrencies().subscribe({
      next: (response) => {
        this.currencyOptions = response?.data?.map((currencyObj) => ({
          id: currencyObj.id,
          key: currencyObj.currencyCode,
          value: currencyObj.name,
        }));
      },
      error: (error) => {},
    });
  }

  // temp fn to handle date formats from "07-04-2021 01:04:25"
  formatDate(date) {
    if (date && date.split(' ').length > 1) {
      return moment(date, 'DD-MM-YYYY HH:mm:ss').format(this.shortDateFormat);
    }
    return moment(date).toDate();
  }
  formatDateOnly(date) {
    return moment(date, 'DD-MM-YYYY HH:mm:ss').format('YYYY/MM/DD');
  }

  formatPerc(value) {
    return Number(value) * 100 + '%';
  }

  isActive(index) {
    return this.subNavData[index].active;
  }
  handleChangePremium(event) {
    this.newTaxInvoiceamount = 0;
    let updatedPremium;
    // get tax details based on region Id
    this.ConfTaxService.getTaxByRegionId(this.regionId)
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          this.taxPercent = data?.data[0]?.percentage;
        },
      });
    updatedPremium = this.form.controls['premium'].value;
    if (this.taxPercent) {
      this.newTaxInvoiceamount = updatedPremium * this.taxPercent;
    }
    this.form.controls['taxes'].setValue(
      Number(this.newTaxInvoiceamount).toFixed(2),
    );
  }

  async createNoteAPI(policyRiskTrxId, isPolicyChange = false) {
    const createReq = {
      policyRiskTrxId: policyRiskTrxId,
      tags: this.form.controls['tag'].value,
      noteParentId: 1,
      note: this.form.controls['note'].value,
    };
    const notesCreateObs = this.policyRiskTrxNotesService.Create(createReq);
    const policyNoteCreateData = await firstValueFrom(notesCreateObs);
    if (isPolicyChange) {
      this.handleCloseClk();
    }

    return;
  }
  async saveForm() {
    this.isSaveProcessing = true;
    if (!this.isSaveButtonValid) {
      this.isSaveProcessing = false;
      return;
    }

    if (this.form.controls['tag'].value.toLowerCase() === 'policychange') {
      if (this.form.controls['note'].value) {
        await this.createNoteAPI(this.policyRiskTrxId, true);
        this.isSaveProcessing = false;
        const alertData = {
          show: true,
          type: 'success',
          headerText: 'success!',
          bodyText: 'policy.update.success.body',
          wrapperStyle: { margin: '30px 0px 0px -75px' },
        };
        this.alertService.addAlert(alertData);
      }
    } else if (
      this.form.controls['tag'].value.toLowerCase() === 'endorsement'
    ) {
      const UpdatePolicyLifeCycleRequest: UpdatePolicyLifeCycle = {
        policyRiskTrxId: this.policyRiskTrxId,
        limit: Number(this.limit),
        aggregatedLimit: Number(this.form.controls['aggregatedLimit'].value),
        deductible: Number(this.deductables),
        premium: Number(this.form.controls['premium'].value),
        transactionEffectiveDate: appendTimeStamp(
          this.form.controls['effectiveDate'].value,
          this.shortDateFormat,
        ),
        brokerCommissionPerc:
          this.form.controls['brokerCommissionPerc'].value / 100,
      };

      //update API
      this.policyRiskTrxService
        .updateByPolicyRiskTrxId(
          this.policyRiskTrxId,
          UpdatePolicyLifeCycleRequest,
        )
        .subscribe({
          next: (response) => {
            this.isSaveProcessing = false;
            const alertData = {
              show: true,
              type: 'success',
              headerText: 'success!',
              bodyText: 'policy.update.success.body',
              wrapperStyle: { margin: '30px 0px 0px -75px' },
            };
            this.alertService.addAlert(alertData);
          },
          error: (error) => {
            this.isSaveProcessing = false;
            if (![500].includes(error?.status)) {
              const alertData = {
                show: true,
                type: 'error',
                headerText: 'error!',
                bodyText: getErrorMessage(error),
                wrapperStyle: { margin: '30px 0px 0px -75px' },
              };
              this.alertService.addAlert(alertData);
            }
          },
        });

      if (this.form.controls['note'].value) {
        await this.createNoteAPI(this.policyRiskTrxId);
        this.isSaveProcessing = false;
        const alertData = {
          show: true,
          type: 'success',
          headerText: 'success!',
          bodyText: 'policy.update.success.body',
          wrapperStyle: { margin: '30px 0px 0px -75px' },
        };
        this.alertService.addAlert(alertData);
      }
    }
    return;
  }

  async saveHandler() {
    switch (this.transactionRiskLevelAction) {
      case 'policy change':
        this.handleNotificationAlertClose();
        this.handlePolicyChange();
        break;
      case 'endorse':
        this.handleNotificationAlertClose();
        this.handleEndorseAction();
        break;
      case 'invoice':
        this.handleNotificationAlertClose();
        this.handleInvoicesAction();
        break;

      default:
        await this.saveForm();
        break;
    }
  }

  handleEndorseAction() {
    let note = this.form.controls['note'].value
      ? this.form.controls['note'].value.trim()
      : '';
    let data: PolicyTransactionEndorsementRequestBody = {
      policyRiskTrxId: this.details.policyRiskTrxId,
      limit: +this.form.controls['limit'].value,
      aggregatedLimit: +this.form.controls['aggregatedLimit'].value,
      deductible: +this.form.controls['deductible'].value,
      premium: +this.form.controls['premium'].value,
      transactionEffectiveDate: appendTimeStamp(
        this.form.controls['effectiveDate'].value,
        this.shortDateFormat,
      ),
      note: note,
      brokerCommissionPerc: this.form.value['brokerCommissionPerc'] / 100,
    };
    this.handleSave.emit({ data: data, action: 'ENDORSE' });
  }

  handleInvoicesAction() {
    let data: PolicyTransactionInvoicesRequestBody = {
      policyRiskTrxId: this.details.policyRiskTrxId,
    };
    this.handleSave.emit({ data: data, action: 'INVOICE' });
  }

  handlePolicyChange() {
    let note = this.form.controls['note'].value
      ? this.form.controls['note'].value.trim()
      : '';
    if (note && note != '' && note.length >= 3) {
      let data = {
        policyRiskTrxId: this.details.policyRiskTrxId,
        note: note,
      };
      this.handleSave.emit({ data: data, action: 'POLICY_CHANGE' });
    } else {
      const alertData = {
        show: true,
        type: 'error',
        headerText: 'error!',
        bodyText: 'policy.policySlideOut.errorNotes',
        wrapperStyle: { margin: '30px 0px 0px -75px' },
      };
      this.alertService.addAlert(alertData);
    }
  }

  handleNav(event, index) {
    this.alertService.clearAlerts(-1);
    this.showTabLoader = true;

    const currentActiveIdx = this.subNavData.findIndex(
      (navObj) => navObj.active,
    );
    this.subNavData[currentActiveIdx].active = false;
    this.subNavData[index].active = true;

    Promise.all([this.callApiBasedOnTab(this.subNavData[index].value)]);
  }

  callApiBasedOnTab(tabName: string) {
    switch (tabName.toLowerCase()) {
      case 'form':
        this.populateFormDetails();
        break;
      case 'documents':
        this.showTabLoader = false;
        break;
      case 'history':
        this.populatePastPolicies();
        this.populateActivityLog(this.currentPage);

        break;
      case 'notes':
        this.populateNotesUnderwriterOptions();
        this.filterNotesList(this.currentPage);

        break;
      default:
        break;
    }
  }

  handleTransactionEffDate(date) {
    if (date) {
      const formattedEffectiveDate = formatDate(
        new Date(date),
        this.shortDateFormat,
      );
      this.form.controls['effectiveDate'].setValue(formattedEffectiveDate);
    }
  }

  checkIfStartDateValid(date, endDate) {
    if (!date) {
      this.resetStartDateControls();
    } else if (isNaN(date.getTime())) {
      this.resetStartDateControls();
    } else if (
      this.formatDateOnly(endDate) &&
      this.formatDateOnly(date) > this.formatDateOnly(endDate)
    ) {
      this.handleInvalidStartDate('error.minStartDateError');
    } else {
      this.setValidStartDate(date);
    }
  }

  closeDocPreview() {
    this.currentPreviewDocUrl = '';
    this.currentFileType = '';
    this.showBodySpinner = false;
    this.showModalSpinner = false;
    this.showDocPreview = false;
    this.showImagePreview = false;
    this.showDeleteFileConfirm = false;
    this.currentFile = {};
    this.currentFileIndex = undefined;
  }

  checkIfEndDateValid(date, startDate) {
    if (!date) {
      this.resetEndDateControls();
    } else if (isNaN(date.getTime())) {
      this.resetEndDateControls();
    } else if (
      this.formatDateOnly(startDate) &&
      this.formatDateOnly(date) < this.formatDateOnly(startDate)
    ) {
      this.handleInvalidEndDate('error.minEndDateError');
    } else {
      this.setValidEndDate(date);
    }
  }

  resetStartDateControls() {
    this.form.controls['notesSearchForm']
      .get('noteFromDateSearch')
      .setValue('');
    this.fromDateSearchKeyWord = '';
    this.isStartDateValid = true;
    this.startDateErrMsg = null;
  }

  handleInvalidStartDate(errorMessage) {
    this.isStartDateValid = false;
    this.startDateErrMsg = errorMessage;
  }

  setValidStartDate(date) {
    this.isStartDateValid = true;
    this.startDateErrMsg = null;
    this.isEndDateValid = true;
    this.endDateErrMsg = null;
    this.fromDateSearchKeyWord = this.formatDateOnly(date);
  }

  resetEndDateControls() {
    this.form.controls['notesSearchForm'].get('noteToDateSearch').setValue('');
    this.toDateSearchKeyWord = '';
    this.isEndDateValid = true;
    this.startDateErrMsg = null;
  }

  handleInvalidEndDate(errorMessage) {
    this.isEndDateValid = false;
    this.endDateErrMsg = errorMessage;
  }

  setValidEndDate(date) {
    this.isStartDateValid = true;
    this.startDateErrMsg = null;
    this.isEndDateValid = true;
    this.endDateErrMsg = null;
    this.toDateSearchKeyWord = this.formatDateOnly(date);
  }

  handleKeywordFilter(e) {
    const filterKeyword = e.target.value.toLowerCase();
    this.notesSearchKeyWord = filterKeyword;
    if (e.target.value.length < 3) {
      this.notesSearchKeyWord = '';
      this.handleCheckNoteFormValid();
      this.minLengthErrMsg = 'error.minThreeLengthsearchErrMsg';
      return this.form.controls['notesSearchForm']
        .get('noteKeyWordSearch')
        .setErrors({ pattern: true });
    }
    this.handleCheckNoteFormValid();
  }
  handleStartDateChange(date, endDate) {
    this.form.controls['notesSearchForm']
      .get('noteFromDateSearch')
      .setValue(date);
    this.fromDateSearchKeyWord = this.formatDateOnly(
      this.form.controls['notesSearchForm'].get('noteFromDateSearch').value,
    );
    this.checkIfStartDateValid(date, endDate);
    this.handleCheckNoteFormValid();
  }
  handleEndDateChange(date, startDate) {
    this.form.controls['notesSearchForm']
      .get('noteToDateSearch')
      .setValue(date);
    this.toDateSearchKeyWord = this.formatDateOnly(
      this.form.controls['notesSearchForm'].get('noteToDateSearch').value,
    );
    this.checkIfEndDateValid(date, startDate);
    this.handleCheckNoteFormValid();
  }
  writerFilterHandler(value) {
    if (value === 'Select') {
      this.writerSearchKeyWord = null;
    } else {
      this.writerSearchKeyWord = Number(
        this.options
          .filter(
            (option) => option.value.toLowerCase() === value.toLowerCase(),
          )
          .map((option) => option.id),
      );
    }
    this.handleCheckNoteFormValid();
  }

  updateNotes(newNote) {
    this.populateNotesUnderwriterOptions();
    this.filterNotesList(this.currentPage);
    this.resetFilterForm();
  }

  currencyValueChanged(e) {}

  handleLimitChange(value) {
    this.resetPremiumOnValChange();
    if (value == 'custom-amount') {
      this.showLimitCustomInput = true;
    } else {
      this.limit = value;
      this.showInvalidLimitErr = false;
      this.showInvalidLimitErrMsg = '';
    }
  }
  handleDeductableChange(value) {
    this.resetPremiumOnValChange();
    if (value == 'custom-amount') {
      this.showDeductibleCustomInput = true;
    } else {
      this.deductables = value;

      this.showInvalidDeductibleErr = false;
      this.showInvalidDeductibleErrMsg = '';
    }
  }
  isNumber(number) {
    return !isNaN(number);
  }
  handleDeductableCustomValue(event) {
    this.resetPremiumOnValChange();
    const formattedAmount = event.target.value
      ? event.target.value?.replace(/[$,]/g, '')
      : 0;
    this.deductables = formattedAmount;
    this.deductibleOptions;
    let deductibleOptionsArr = this.deductibleOptions
      ?.filter(
        (deductible) =>
          this.isNumber(valInDigits(deductible.key)) &&
          deductible.key != 'Custom amount',
      )
      .map((deductible) => valInDigits(deductible.key));
    const minDeductible = Math.min(...deductibleOptionsArr);
    const maxDeductible = Math.max(...deductibleOptionsArr);
    if (valInDigits(formattedAmount) > maxDeductible) {
      this.showInvalidDeductibleErr = true;
      this.showInvalidDeductibleErrMsg = this.deductibleExceedErrMsg
        .replace('[productName]', this.details.product)
        .replace(
          '[maximumDeductible]',
          formatAmountWithCurrency(maxDeductible, this.currency)?.toString(),
        );
      this.showDeductibleCustomInput = false;
    } else if (valInDigits(formattedAmount) < minDeductible) {
      this.showInvalidDeductibleErr = true;
      this.showInvalidDeductibleErrMsg = this.deductibleMinimumErrMsg
        .replace('[productName]', this.details.product)
        .replace(
          '[minimumDeductible]',
          formatAmountWithCurrency(minDeductible, this.currency)?.toString(),
        );
      this.showDeductibleCustomInput = false;
    } else {
      this.showInvalidDeductibleErr = false;
      this.showInvalidDeductibleErrMsg = '';
      this.showDeductibleCustomInput = true;
    }
  }
  handleLimitCustomValue(event) {
    this.resetPremiumOnValChange();
    const formattedLimit = event.target.value
      ? event.target.value?.replace(/[$,]/g, '')
      : 0;
    this.limit = formattedLimit;
    const limitOptionsArr = this.limitOptions
      ?.filter(
        (limit) =>
          this.isNumber(valInDigits(limit.key)) && limit.key != 'Custom amount',
      )
      .map((limit) => valInDigits(limit.key));
    const minLimit = Math.min(...limitOptionsArr);
    const maxLimit = Math.max(...limitOptionsArr);
    if (valInDigits(formattedLimit) > maxLimit) {
      this.showInvalidLimitErr = true;
      this.showInvalidLimitErrMsg = this.limitExceedErrMsg
        .replace('[productName]', this.details.product)
        .replace(
          '[maximumLimit]',
          formatAmountWithCurrency(maxLimit, this.currency)?.toString(),
        );
      this.showLimitCustomInput = false;
    } else if (valInDigits(formattedLimit) < minLimit) {
      this.showInvalidLimitErr = true;
      this.showInvalidLimitErrMsg = this.limitMinimumErrMsg
        .replace('[productName]', this.details.product)
        .replace(
          '[minimumLimit]',
          formatAmountWithCurrency(minLimit, this.currency)?.toString(),
        );
      this.showLimitCustomInput = false;
    } else {
      this.showInvalidLimitErr = false;
      this.showInvalidLimitErrMsg = '';
      this.showLimitCustomInput = true;
    }
  }
  showLimitCustomField() {
    return this.showLimitCustomInput;
  }
  showDeductibleCustomField() {
    return this.showDeductibleCustomInput;
  }

  getDeductibleValue() {
    return this.deductables;
  }
  getLimitValue() {
    return this.limit;
  }
  isLimitDisabledOnAddSubProduct(riskId) {
    return false;
  }

  resetPremiumOnValChange() {}
  aggregatedLimitHandler(e) {}
  deductibleHandler(e) {}

  handleCloseClk() {
    this.closeModalClicked = true;
    this.alertService.clearAlerts(-1);
    this.showModal = false;
    this.handleClose.emit();
  }

  getConnectionString(table, type, user) {
    switch (type) {
      case 'create':
        return table + ' Created by ' + user;
      case 'update':
        return table + ' Updated by ' + user;
      case 'delete':
        return table + ' Deleted by ' + user;
      default:
        return '';
    }
  }

  handleBrokerCommision(value) {
    this.form.controls['brokerCommissionPerc'].setValue(value);
    // this.resetPremiumOnValChange(pdt);
    // this.data.products.filter(p => p.value == pdt)[0].brokerCommissions = value;
  }

  getBrokerageCommission() {
    return 0;
    // return this.data.products.length > 0 ? this.data.products?.filter(p => p.value == pdt)[0]?.brokerCommissions : null;
  }

  isCalcBtnEnabled() {
    return true;
  }

  async handlePremiumCalc(currentPdtValue) {
    let invoiceNumber = this.form.controls['invoiceNumber'].value;
    let limit = valInDigits(this.form.controls['limit'].value);
    let deductibles = this.form.controls['deductible'].value;
    let brokerCommission = this.form.controls['brokerCommission'].value;
    let brokerCommissionPerc = this.form.controls['brokerCommissionPerc'].value;
    let aggregatedLimit = this.form.controls['aggregatedLimit'].value;
    let premium = this.form.controls['premium'].value;
    let calcPremium = this.form.controls['calcPremium'].value;
    let taxes = this.form.controls['taxes'].value;
    let editedPremium = this.form.controls['premium'].value;
    let currencyId = this.form.controls['premium'].value;
    let transactionEffectiveDate = this.form.controls['effectiveDate'].value;

    if (this.hasInvoiceNumber()) {
      return;
    }

    const totals = {
      policyFee: 0,
      netAmount: 0,
      taxesAmount: 0,
      totalAmount: 0,
      servicesAmount: 0,
      taxesPremiumAmount: 0,
      premium: 0,
      premiumCalculated: 0,
    };

    const currentProduct = this.data.products.filter(
      (product) => product.value == currentPdtValue,
    )[0];
    try {
      const UpdatePolicyLifeCycle = {
        policyRiskTrxId: Number(currentProduct?.policyRiskTrxId ?? 0),
        limit: valInDigits(limit),
        deductible: valInDigits(deductibles),
        brokerCommissionPerc: Number((brokerCommissionPerc / 100).toFixed(4)),
        quoteOptionServices: 1,
        premiumCalculated: Number(editedPremium ?? 0),
        invoiceNumber: invoiceNumber,
        totalInvoiceAmount: calcPremium,
        currencyId: Number(this.currencyId),
        aggregatedLimit: aggregatedLimit,
        underwriterId: this.underwriterId,
        premium: premium,
        transactionEffectiveDate: transactionEffectiveDate,
      };
      const createOption = this.policyQuoteOptionService.Update(
        currentProduct.quoteOptionId,
        UpdatePolicyLifeCycle,
      );
      //update API
      this.policyRiskTrxService
        .updateByPolicyRiskTrxId(this.policyRiskTrxId, UpdatePolicyLifeCycle)
        .subscribe({
          next: (response) => {},
        });
      let data = await firstValueFrom(createOption);
      //addons
    } catch (error) {}
  }

  handleEditedPremium(val, pdt) {
    const currentPdtObj = this.data.products.filter((p) => p.value === pdt)[0];
    if (val === '-20%') {
      currentPdtObj.editedPremiumDeviation = '20% decreased deviation';
      currentPdtObj.premium = null;
      currentPdtObj.premium = Math.round(
        (Number(currentPdtObj.premiumCalculated) * 80) / 100,
      );
    } else if (val === '+20%') {
      currentPdtObj.editedPremiumDeviation = '20% increased deviation';
      currentPdtObj.premium = null;
      currentPdtObj.premium = Math.round(
        (Number(currentPdtObj.premiumCalculated) * 120) / 100,
      );
    } else if (val === 'custom-amount') {
      currentPdtObj.editedPremiumDeviation = '';
      currentPdtObj.showEditedPremiumCustomField = true;
    }
    this.data.total.premium = Math.round(
      this.data.products.reduce((acc, currentPdtObj) => {
        return acc + Number(currentPdtObj.premium);
      }, 0),
    );
  }

  getStatusType(status) {
    return convertStatusType(status);
  }
  populateNotesUnderwriterOptions() {
    if (!this.policyRiskTrxId) {
      return;
    }
    this.policyRiskTrxNotesService
      .getNotesUnderWriterList(this.policyRiskTrxId)
      .subscribe({
        next: (response) => {
          this.options = response.data?.map((options) => ({
            key:
              options?.boxxUser?.firstName + ' ' + options?.boxxUser?.lastName,
            value:
              options?.boxxUser?.firstName + ' ' + options?.boxxUser?.lastName,
            id: options?.id,
          }));
          this.options = removeDuplicatedKeys(this.options);
          this.options.unshift({ key: 'Select', value: 'Select', id: '' });

          this.form.controls['writerOptions']?.setValue(this.options);
        },
        error: (error) => {},
      });
  }
  populateActivityLog(pageNumber: number) {
    if (!this.policyRiskTrxId) {
      this.showTabLoader = false;
      return;
    }
    this.PolicyQuoteAuditService.getPolicyQuoteAuditData(
      ['PolicyPeriod', 'PolicyRisk', 'PolicyRiskTrx'],
      this.policyRiskTrxId,
      pageNumber,
      this.itemsPerPage,
    ).subscribe({
      next: (response) => {
        ({ pagination: this.activityData.pagination } = response);
        this.auditDetails = response.data?.map((audit) => ({
          user: audit?.createdBy,
          type: audit?.auditType,
          changeFrom: audit?.auditChangeFrom,
          changeTo: audit?.auditChangeTo,
          date: formatDate(audit?.createdDt, this.longDateFormat),
          table: audit?.auditTable,
          tableDomain: audit?.auditTableDomain,
        }));
        this.showTabLoader = false;
      },
      error: (error) => {
        this.showTabLoader = false;
      },
    });

    this.totalPages = this.activityData?.pagination?.totalPages;
    this.currentPage = pageNumber;
    this.totalCount = this.activityData?.pagination?.totalRecords;
  }
  handleNotificationAlertClose() {
    this.alertService.clearAlerts(-1);
  }

  async populateBrokerageCommission(riskId, brokerageId, producerId) {
    return new Promise<any>(async (resolve, reject) => {
      this.brokerageProducerCommissionService
        .GetByRegionIdAndRiskIdsAndProducerId(this.regionId, riskId, producerId)
        .subscribe({
          next: async (data) => {
            let producerCommissionNotExistsFor = [];
            // GET COMMISSIONS FROM PRODUCER
            if (data.data?.length > 0) {
              const brokerForRiskId = data.data.filter(
                (broker) =>
                  broker.active && broker?.riskRegion.risk.id === riskId,
              );
              if (brokerForRiskId.length > 0) {
                this.brokerageCommissionMax = Number(
                  (Number(brokerForRiskId[0].commissionMax) * 100).toFixed(2),
                );
                this.brokerageCommissionMin = Number(
                  (Number(brokerForRiskId[0].commissionMin) * 100).toFixed(2),
                );
                this.brokerageCommissionText = `Range: ${this.brokerageCommissionMin}% - ${this.brokerageCommissionMax}%`;
              } else {
                producerCommissionNotExistsFor.push(riskId);
              }
            } else {
              producerCommissionNotExistsFor.push(riskId);
            }
            if (producerCommissionNotExistsFor.length > 0) {
              let riskIdWithoutCommission = [];
              await this.brokerageCommissionService
                .GetByRegionIdAndRiskIdsAndBrokerageId(
                  this.regionId,
                  riskId,
                  brokerageId,
                )
                .subscribe((data) => {
                  let riskIdsToAddCommission = [];
                  if (data.data.length === 0) {
                    riskIdsToAddCommission = riskId;
                    riskIdWithoutCommission = riskIdsToAddCommission;
                  } else {
                    riskIdsToAddCommission = producerCommissionNotExistsFor;
                  }
                  // GET COMMISSIONS FROM BROKERAGE
                  if (data.data?.length > 0) {
                    riskIdsToAddCommission.forEach((riskId) => {
                      const commissionForRiskId = data.data.filter(
                        (broker) =>
                          broker.active &&
                          broker?.riskRegion.risk.id === riskId,
                      );
                      if (commissionForRiskId.length > 0) {
                        this.brokerageCommissionMax = Number(
                          (Number(data.data[0].commissionMax) * 100).toFixed(2),
                        );
                        this.brokerageCommissionMin = Number(
                          (Number(data.data[0].commissionMin) * 100).toFixed(2),
                        );
                        this.brokerageCommissionText = `Range: ${this.brokerageCommissionMin}% - ${this.brokerageCommissionMax}%`;
                      } else {
                        riskIdWithoutCommission.push(riskId);
                      }
                    });
                  }
                  if (riskIdWithoutCommission?.length > 0) {
                    this.isBrokerCommissionDisabled = true;
                  }
                });
            }
            resolve('true');
          },
          error: (error) => {
            reject(getErrorMessage(error));
            this.handleCloseClk();
          },
        });
    });
  }
  getBrokerageCommissionPerc() {
    const num = Number(this.form.controls['brokerCommissionPerc'].value);
    return num;
  }

  openPopover() {
    this.populateReferalReasons();
    this.isPopoverOpen = true;
  }

  closePopover() {
    this.isPopoverOpen = false;
    this.alertService.clearAlerts(-1);
  }
  getLimitErrMsg() {
    return this.showInvalidLimitErr ? this.showInvalidLimitErrMsg : '';
  }
  getDeductibleErrMsg() {
    return this.showInvalidDeductibleErr
      ? this.showInvalidDeductibleErrMsg
      : '';
  }
  handleNotesFilter(e) {
    if (!this.handleCheckNoteFormValid) {
      return;
    }
    if (!this.form.get('notesSearchForm').valid) {
      return;
    }
    let policyRiskTrxIds = this.details.policyRiskTrxId.toString();
    const queryParams = {};

    if (this.notesSearchKeyWord) {
      queryParams['note'] = this.notesSearchKeyWord;
    }

    if (this.fromDateSearchKeyWord && this.toDateSearchKeyWord) {
      queryParams['startDate'] = this.fromDateSearchKeyWord;
      queryParams['endDate'] = this.toDateSearchKeyWord;
    }

    if (
      this.writerSearchKeyWord !== undefined &&
      this.writerSearchKeyWord !== null
    ) {
      queryParams['createdBy'] = this.writerSearchKeyWord;
    }

    const product = this.details.product;
    this.policyRiskTrxNotesService
      .getByPolicyRiskTrxIdNotesFilter(
        policyRiskTrxIds,
        queryParams,
        1,
        this.itemsPerPage,
      )
      .subscribe({
        next: (resp) => {
          ({ pagination: this.notesData.pagination } = resp);
          const notes = resp.data.map((data) => ({
            content: data.note,
            writer: data.createdBy,
            writerPermission: data?.updatedBy ? data?.updatedBy : '',
            product: product,
            tag: data.tags,
            date: formatDate(data.createdDt, this.longDateFormat),
            id: data.id,
            updatedDt: data?.updatedDt
              ? formatDate(data.updatedDt, this.longDateFormat)
              : '',
            read: isRead(data.updatedDt ? data.updatedDt : data.createdDt),
          }));

          this.details.isUnreadNotesPresent =
            notes.filter((p) => !p.read).length > 0;
          this.form.controls['notes'].setValue(notes);
          this.notesData.filterData = queryParams;
          this.form.controls['notesPagination'].setValue(this.notesData);
        },
        error: (e) => {},
      });
  }
  resetFilterForm() {
    this.form.controls['notesSearchForm'].reset();
    this.form.get('notesSearchForm').updateValueAndValidity();
    this.form.controls['notesSearchForm'].get('noteToDateSearch').setValue('');
    this.form.controls['notesSearchForm']
      .get('noteFromDateSearch')
      .setValue('');
    this.isNoteFormValid = false;
    this.isEndDateValid = true;
    this.isStartDateValid = true;
    this.fromDateSearchKeyWord = '';
    this.toDateSearchKeyWord = '';
    this.writerSearchKeyWord = null;
    this.notesSearchKeyWord = '';
    this.form.controls['notesSearchForm']
      .get('noteWriterSearch')
      .setValue(null);
    this.form.controls['notesSearchForm'].get('noteKeyWordSearch').setValue('');
    this.filterNotesList(this.currentPage);
  }

  handleCheckNoteFormValid() {
    if (this.writerSearchKeyWord || this.notesSearchKeyWord) {
      if (this.isStartDateValid && this.isEndDateValid) {
        if (this.fromDateSearchKeyWord && this.toDateSearchKeyWord) {
          this.isNoteFormValid = true;
        } else if (!this.fromDateSearchKeyWord && !this.toDateSearchKeyWord) {
          this.isNoteFormValid = true;
        } else {
          this.isNoteFormValid = false;
        }
      } else {
        this.isNoteFormValid = false;
      }
    } else if (this.isStartDateValid && this.isEndDateValid) {
      if (this.fromDateSearchKeyWord && this.toDateSearchKeyWord) {
        this.isNoteFormValid = true;
      } else {
        this.isNoteFormValid = false;
      }
    } else {
      this.isNoteFormValid = false;
    }
  }

  handleSaveButtonEnabled() {
    if (
      !this.hasInvoiceNumber() &&
      this.notesEditPermissionStatus.includes(this.form.controls['tag'].value)
    ) {
      this.isSaveButtonValid = true;
    } else if (
      !this.hasInvoiceNumber() &&
      this.allowedSaveStatuses.includes(this.form.controls['tag'].value)
    ) {
      this.isSaveButtonValid = true;
    } else if (
      this.transactionRiskLevelAction === 'policy change' ||
      this.transactionRiskLevelAction === 'endorse'
    ) {
      this.isSaveButtonValid = true;
    } else if (this.hasInvoiceNumber()) {
      this.isSaveButtonValid = false;
    } else {
      this.isSaveButtonValid = false;
    }
  }
  showGenerateDocMsg() {
    if (this.closeModalClicked === false) {
      let notificationAlert = {
        type: 'success',
        headerText: getAlertHead('success'),
        bodyText: this.translate.instant(
          'policy.document.success.regenerageSuccessMsg',
        ),
        wrapperStyle: { margin: '30px 0px 0px -75px' },
      };
      this.alertService.addAlert(notificationAlert);
    }
  }
}
