<div class="scrollable-tbl-container">
  <div
    class="scroll-range"
    #scrollContainer
    [ngClass]="{ 'no-scroll': !isScrollable }"
  >
    <table class="scrollable-table">
      <thead>
        <tr class="header-row">
          <ng-container *ngFor="let tblHeader of tableHeaders">
            <th
              *ngIf="
                tblHeader !== 'isReadOnly' &&
                tblHeader !== 'allowOverwriteDefault' &&
                tblHeader !== 'factorForLimit' &&
                tblHeader !== 'included' &&
                tblHeader !== 'defaultLimit' &&
                tblHeader !== 'defaultDeductible'
              "
              class="tbl-hd fixed-side"
            >
              {{ tblHeader }}
            </th>
          </ng-container>
          <th class="tbl-hd fixed-last-col">
            <button
              *ngIf="isScrollable"
              class="icon-button"
              [ngClass]="{ 'chevron-left': !scrollToRight }"
              (click)="scrollRight()"
            >
              <img src="assets/img/scroll-table/chevron-down.png" />
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of data; let indexOfelement = index">
          <ng-container *ngIf="!isExpanded[indexOfelement]">
            <tr
              [ngClass]="{
                'read-only-row': item['isReadOnly'] || showAsReadOnly
              }"
            >
              <ng-container
                *ngFor="let key of tableHeaders; let tblIdx = index"
              >
                <td
                  class="table-data"
                  *ngIf="
                    key !== 'isReadOnly' &&
                    key !== 'allowOverwriteDefault' &&
                    key !== 'factorForLimit' &&
                    key !== 'included' &&
                    key !== 'defaultLimit' &&
                    key !== 'defaultDeductible'
                  "
                  [title]="item[key]"
                  [ngClass]="{ 'fixed-side': tblIdx === 0 }"
                >
                  <span
                    *ngIf="
                      (key === 'Sublimit' || key === 'Deductibles') &&
                      tblIdx !== 0
                    "
                  >
                    {{
                      isNumber(item[key])
                        ? (item[key]
                          | currency: currency : "symbol-narrow" : "1.0-0")
                        : item[key]
                    }}</span
                  >
                  <span
                    *ngIf="
                      key !== 'Sublimit' &&
                      key !== 'Deductibles' &&
                      tblIdx !== 0
                    "
                  >
                    {{ item[key] }}</span
                  >
                  <div class="d-flex align-items-center" *ngIf="tblIdx === 0">
                    <boxxds-switch
                      *ngIf="tblIdx === 0"
                      name="sublimit-included"
                      (changed)="
                        !['quote-closed'].includes(this.currentQuoteStatus) &&
                          handleSublimitIncludeToggle($event, indexOfelement)
                      "
                      [ngStyle]="{ marginBottom: '0px' }"
                      [checked]="item['included']"
                      [disabled]="
                        !!item['isReadOnly'] ||
                        ['quote-closed'].includes(this.currentQuoteStatus)
                      "
                      [class.noPointerEvents]="
                        !!item['isReadOnly'] ||
                        ['quote-closed'].includes(this.currentQuoteStatus)
                      "
                    >
                    </boxxds-switch>
                    <span class="sublimit-name" [title]="item[key]">
                      {{ item[key] }}</span
                    >
                  </div>
                </td>
              </ng-container>
              <td
                class="fixed-last-col"
                *ngIf="
                  this.permissionList[this.currentScreen] &&
                  this.currentQuoteStatus !== 'quote-closed'
                "
              >
                <button
                  *ngIf="
                    item['included'] && !showAsReadOnly && !item['isReadOnly']
                  "
                  class="icon-button"
                  (click)="handleEdit(indexOfelement)"
                >
                  <img src="assets/img/scroll-table/edit.png" />
                </button>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="isExpanded[indexOfelement]">
            <tr class="edited-row">
              <td
                class="table-data"
                [title]="item['Coverage']"
                class="fixed-side"
              >
                {{ item["Coverage"] }}
              </td>

              <td>
                <boxxds-dropdown
                  *ngIf="!showLimitCustomInput"
                  [currentValue]="
                    form.value.sublimitAmount
                      | currencyFormat: currency : 'symbol-narrow' : '1.0-0'
                  "
                  [placeholder]="'common.select' | translate"
                  [options]="
                    currentCoverageName
                      ?.toLowerCase()
                      ?.includes(financialCoverageMatchString)
                      ? sublimitOptionsForFinancialCoverage
                      : limitOptionsForProducts
                  "
                  [disabled]="
                    (currentCoverageName
                      ?.toLowerCase()
                      ?.includes(financialCoverageMatchString) &&
                      form.value.retentionAmount <
                        financialCoverageMinDeductible) ||
                    item['allowOverwriteDefault']
                  "
                  [control]="'sublimitAmount'"
                  [dropdownContainerStyle]="{ maxWidth: '150px' }"
                  class="input-fields"
                  (currentValueChange)="handleSublimitChange($event)"
                >
                </boxxds-dropdown>
                <boxxds-textinput
                  *ngIf="showLimitCustomInput"
                  [isDisabled]="item['allowOverwriteDefault']"
                  [placeholder]="
                    'quoteCalculator.modal.sublimitAmount' | translate
                  "
                  [control]="'sublimitAmount'"
                  [id]="'sublimitAmount'"
                  [width]="'100%'"
                  minLength="3"
                  maxLength="14"
                  [form]="form"
                  mask="separator.0"
                  thousandSeparator=","
                  prefix="$"
                >
                </boxxds-textinput>
              </td>
              <td>
                <boxxds-dropdown
                  [currentValue]="form.value.occurenceType"
                  [placeholder]="'common.select' | translate"
                  [options]="occurrenceTypeDropdownValues"
                  [control]="'occurenceType'"
                  [dropdownContainerStyle]="{ maxWidth: '150px' }"
                  class="input-fields"
                  (currentValueChange)="
                    handleValueChange($event, 'occurenceType')
                  "
                >
                </boxxds-dropdown>
              </td>
              <td>
                <boxxds-dropdown
                  *ngIf="!showDeductibleCustomField"
                  [disabled]="item['allowOverwriteDefault']"
                  [currentValue]="
                    form.value.retentionAmount
                      | currencyFormat: currency : 'symbol-narrow' : '1.0-0'
                  "
                  [placeholder]="'common.select' | translate"
                  [options]="coverageDeductibles"
                  (currentValueChange)="
                    handleDeductibleChange(
                      $event,
                      currentCoverageName
                        ?.toLowerCase()
                        ?.includes(financialCoverageMatchString)
                    )
                  "
                  [control]="'retentionAmount'"
                  [dropdownContainerStyle]="{ maxWidth: '150px' }"
                  class="input-fields retentionAmount-field"
                >
                </boxxds-dropdown>
                <boxxds-textinput
                  *ngIf="showDeductibleCustomField"
                  [placeholder]="
                    'quoteCalculator.modal.deductiblesAmount' | translate
                  "
                  [control]="'retentionAmount'"
                  [id]="'retentionAmount'"
                  [width]="'100%'"
                  minLength="3"
                  maxLength="14"
                  mask="separator.0"
                  thousandSeparator=","
                  prefix="$"
                  [form]="form"
                  [isDisabled]="item['allowOverwriteDefault']"
                  (handleBlur)="
                    handleCustomDeductible(
                      $event,
                      currentCoverageName
                        ?.toLowerCase()
                        ?.includes(financialCoverageMatchString)
                    )
                  "
                >
                </boxxds-textinput>
              </td>
              <td>
                <boxxds-dropdown
                  [placeholder]="'common.select' | translate"
                  [currentValue]="form.value.occurenceTypeDeductible"
                  [control]="'occurenceTypeDeductible'"
                  [dropdownContainerStyle]="{ maxWidth: '150px' }"
                  class="input-fields"
                  [options]="occurrenceTypeDropdownValues"
                  (currentValueChange)="
                    handleValueChange($event, 'occurenceTypeDeductible')
                  "
                >
                </boxxds-dropdown>
              </td>
              <td class="fixed-last-col">
                <div class="d-flex flex-column">
                  <button
                    class="icon-button mb-2"
                    (click)="handleEditSave(indexOfelement)"
                  >
                    <img src="assets/img/scroll-table/tick.png" />
                  </button>
                  <button
                    class="icon-button mt-2"
                    (click)="handleEditCancel(indexOfelement)"
                  >
                    <img src="assets/img/scroll-table/cancel.png" />
                  </button>
                </div>
              </td>
            </tr>
            <tr class="edited-row">
              <td class="fixed-side"></td>
              <td class="edit-label">
                {{ "quoteCalculator.modal.indemnityPeriod" | translate }}
              </td>
              <td>
                <boxxds-textinput
                  placeholder="00"
                  [control]="'indemnityPeriod'"
                  [id]="'indemnityPeriod'"
                  [width]="'100%'"
                  minLength="3"
                  maxLength="100"
                  [form]="form"
                  class="input-fields"
                  minLength="0"
                  maxLength="4"
                  [containerStyle]="{ maxWidth: '150px' }"
                  [formSubmitted]="!!form.value.indemnityPeriod"
                  [invalidErrMsg]="'error.thisFieldIsInvalid' | translate"
                  mask="separator.0"
                >
                </boxxds-textinput>
              </td>
              <td class="edit-label">
                {{ "quoteCalculator.modal.waitingPeriod" | translate }}
              </td>
              <td>
                <boxxds-textinput
                  placeholder="00"
                  [control]="'waitingPeriod'"
                  [id]="'waitingPeriod'"
                  [width]="'100%'"
                  minLength="0"
                  maxLength="4"
                  [containerStyle]="{ maxWidth: '150px' }"
                  [formSubmitted]="!!form.value.waitingPeriod"
                  [invalidErrMsg]="'error.thisFieldIsInvalid' | translate"
                  mask="separator.0"
                  [form]="form"
                  class="input-fields"
                >
                </boxxds-textinput>
              </td>
            </tr>
            <tr class="edited-row">
              <td class="fixed-side"></td>
              <td class="edit-label">
                {{ "quoteCalculator.modal.indemnityPeriodMeasure" | translate }}
              </td>
              <td>
                <boxxds-dropdown
                  [currentValue]="form.value.indemnityPeriodMeasure"
                  [placeholder]="'common.select' | translate"
                  [control]="'indemnityPeriodMeasure'"
                  [dropdownContainerStyle]="{ maxWidth: '150px' }"
                  class="input-fields"
                  [options]="measuresDropdownValues"
                  (currentValueChange)="handleIPMeasureChange($event)"
                >
                </boxxds-dropdown>
              </td>
              <td class="edit-label">
                {{ "quoteCalculator.modal.waitingPeriodMeasure" | translate }}
              </td>
              <td>
                <boxxds-dropdown
                  [currentValue]="form.value.waitingPeriodMeasure"
                  [placeholder]="'common.select' | translate"
                  [control]="'waitingPeriodMeasure'"
                  [dropdownContainerStyle]="{ maxWidth: '150px' }"
                  class="input-fields"
                  [options]="measuresDropdownValues"
                  (currentValueChange)="handleWPMeasureChange($event)"
                >
                </boxxds-dropdown>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
