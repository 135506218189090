import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, retry, catchError } from 'rxjs';
import { BoxxResponse } from '../entities/boxx-response';
import { BaseService } from './base-services';

@Injectable({
  providedIn: 'root',
})
export class GenerateQuoteService extends BaseService<any> {
  constructor(http: HttpClient) {
    const baseurl = environment.msPolicyBaseUrl + environment.generateQuoteUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  generateQuotePdf(quoteId: number): Observable<BoxxResponse<any>> {
    let url = `${this.baseurl}quote/${quoteId}`;
    return this.http.put<BoxxResponse<any>>(url, this.httpOptions);
  }

  generateMultiQuotePdf(
    policyRiskTrxId: number,
    quoteOptionIds: number[],
  ): Observable<BoxxResponse<any>> {
    let url = `${this.baseurl}quoteMultiple/${policyRiskTrxId}`;
    const selectedQuoteIds = {
      quoteOptionIds: quoteOptionIds,
    };
    return this.http.put<BoxxResponse<any>>(
      url,
      selectedQuoteIds,
      this.httpOptions,
    );
  }

  generateQuoteComparePdf(quoteIds: any): Observable<BoxxResponse<any>> {
    let url = `${this.baseurl}quote/comparison`;
    const selectedQuoteIds = {
      quoteOption: quoteIds,
    };
    return this.http.put<BoxxResponse<any>>(
      url,
      selectedQuoteIds,
      this.httpOptions,
    );
  }
}
