import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BrokerageService } from 'src/app/services/brokerage.service';
import { BrokerageBranchService } from 'src/app/services/brokerage-branch.service';
import { BrokerageProducerService } from 'src/app/services/brokerage-producer.service';
import { RoleService } from 'src/app/services/roles.service';
import { SystemUserService } from 'src/app/services/system-user.service';
import { arrayToObjet, getErrorMessage } from 'src/app/utils/utils';
import { BoxxResponse } from 'src/app/entities/boxx-response';
import { Role } from 'src/app/entities/role';
import { SystemUser } from 'src/app/entities/system-user';
import { formatDateTime } from 'src/app/utils/formatDate';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { regexHelpers } from 'src/app/utils/utils';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { Store, select } from '@ngrx/store';
import { getUserTypes } from 'src/app/store/dashboard/dashboard.selector';
import { SYSTEM_USER_TYPES } from 'src/app/constants/security/systemUserType';

@Component({
  selector: 'app-edit-system-producer-user',
  templateUrl: './edit-system-producer-user.component.html',
  styleUrls: ['./edit-system-producer-user.component.less'],
})
export class EditSystemProducerUserComponent implements OnInit {
  @Output() updateTitleEvent = new EventEmitter<string>();
  @Output() updateStatusEvent = new EventEmitter<boolean>();

  @Input() systemUserType: string;
  brokers: Array<any> = [];

  branches: Array<any> = [];

  producers: Array<any> = [];

  roles: Array<any> = [];

  tBrokers: any;
  tBranches: any;
  tProducers: any;
  tRoles: any;
  showBodySpinner: boolean = false;

  producerUserForm = new FormGroup({
    brokerId: new FormControl('', [Validators.required]),
    branchId: new FormControl('', [Validators.required]),
    producerId: new FormControl('', [Validators.required]),
    roleId: new FormControl('', [Validators.required]),
    loginEmail: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(regexHelpers.MailId),
    ]),
    password: new FormControl(''),
    systemUserType: new FormControl(0),
    status: new FormControl('string'),
    apiUser: new FormControl(true),
  });

  requiredErrMsg: string = 'error.thisFieldIsRequired';
  invalidErrMsg: string = 'error.thisFieldIsInvalid';
  isSuccess = false;
  hasError = false;
  errorMessage = '';
  message = '';

  invalidRole: string = '';

  formSubmitted: boolean = false;
  broker = '';
  branch = '';
  producer = '';
  role = '';
  showSpinner: boolean = false;
  currentSystemUser: any;
  currentProducer: any;
  currentBranch: any;
  currentBroker: any;
  userId = '';
  toggleActive = false;
  processingActivation = false;
  showErrorAlert = false;
  msgErrorAlert = '';
  loggedUserId: number;
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  showReloadModal: boolean = false;
  shortDateTimeFormat: string = '';
  longDateTimeFormat: string = '';
  selectedSystemUserType: number = 0;

  constructor(
    private brokerageService: BrokerageService,
    private brokerageBranchService: BrokerageBranchService,
    private brokerageProducer: BrokerageProducerService,
    private roleService: RoleService,
    private systemUser: SystemUserService,
    private activedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private store: Store,
  ) {
    const route = this.activedRoute.snapshot.params;
    this.userId = route['id'];
  }

  updateTitle(value: string) {
    this.updateTitleEvent.emit(value);
  }

  updateStatus(status: boolean) {
    this.updateStatusEvent.emit(status);
  }

  handleCloseReloadWarning() {
    this.showReloadModal = false;
  }

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateTimeFormat = data.shortDateTimeFormat;
      this.longDateTimeFormat = data.longDateTimeFormat;
    });
    this.store.pipe(select(getUserTypes)).subscribe(async (data) => {
      this.selectedSystemUserType = data.find(
        (item) =>
          this.toLowerWithoutSpace(item.description) ===
          SYSTEM_USER_TYPES.Producer.toLowerCase(),
      )?.id;
    });
    this.producerUserForm
      .get('systemUserType')
      .setValue(this.selectedSystemUserType);
    this.loggedUserId = this.localStorageService.getBoxxUserId();
    this.showBodySpinner = true;
    this.fetchCurrentSystemUser(this.userId);
  }

  toLowerWithoutSpace(input) {
    let result = input.replace(/[A-Z]/g, (match) => ' ' + match.toLowerCase());
    result = result.replace(/\s+/g, '');
    return result;
  }

  fetchCurrentSystemUser(userId) {
    this.systemUser.GetSystemUser(userId).subscribe({
      next: (response) => {
        this.setCurrentSystemUserResult(response);
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });

    this.producerUserForm[this.toggleActive ? 'enable' : 'disable']({
      emitEvent: false,
    });
  }

  setCurrentSystemUserResult(data: BoxxResponse<SystemUser>) {
    const { data: SystemUser } = data;
    this.currentSystemUser = SystemUser;
    const { producer } = SystemUser;
    this.currentProducer = producer;
    this.fetchProducerBranch(producer?.id).subscribe({
      next: (response) => {
        const {
          data: { brokerageBranch },
        } = response;
        this.currentBranch = brokerageBranch;
        this.fetchBranchBroker(brokerageBranch.id).subscribe({
          next: (response) => {
            const {
              data: { brokerage },
            } = response;
            this.currentBroker = brokerage;

            this.populateForm();
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });
  }

  fetchProducerBranch(producerID): any {
    return this.brokerageProducer.GetBrokerageProducer(producerID);
  }

  fetchBranchBroker(branchID): any {
    return this.brokerageBranchService.GetBrokerageBranch(branchID);
  }

  populateForm() {
    const {
      producer: { id: producerID, firstName, lastName },
      role,
      loginEmail,
      active,
    } = this.currentSystemUser;
    this.role = role.name;

    this.producerUserForm.get('brokerId').setValue(this.currentBroker.id);
    this.producerUserForm.get('branchId').setValue(this.currentBranch.id);
    this.producerUserForm.get('producerId').setValue(this.currentProducer.id);
    this.producerUserForm.get('roleId').setValue(role.id);
    this.producerUserForm.get('loginEmail').setValue(loginEmail);

    this.broker = this.currentBroker.name;
    this.branch = this.currentBranch.name;
    const name =
      this.currentProducer.firstName + ' ' + this.currentProducer.lastName;
    this.producer = name;
    this.role = role.name;

    this.toggleActive = !!active;
    this.producerUserForm[this.toggleActive ? 'enable' : 'disable']({
      emitEvent: false,
    });

    this.updateTitle(name);
    this.updateStatus(active);
    this.currentSystemUser['createdDt'] = formatDateTime(
      this.currentSystemUser.createdDt,
      this.longDateTimeFormat,
    );
    this.currentSystemUser['updatedDt'] = formatDateTime(
      this.currentSystemUser.updatedDt,
      this.longDateTimeFormat,
    );
    this.showBodySpinner = false;
  }

  searchBrokers(name) {
    this.producerUserForm.get('brokerId').setValue(null);
    if (name.length >= 3) {
      let limit = 10,
        page = 1,
        sort = '';
      let active = 1;
      this.brokerageService
        .GetBrokeragesByName(name, page, limit, sort, active)
        .subscribe({
          next: (response) => {
            const tableData = response.data?.map((dataObj) => ({
              key: `${dataObj.name}`,
              value: dataObj.id,
            }));
            this.brokers = [...tableData];
            this.tBrokers = arrayToObjet(response.data, 'id');
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    } else {
      this.brokers = [];
    }
  }

  fetchBranches(brokerageId) {
    this.producerUserForm.get('branchId').setValue(null);
    let limit = 10,
      page = 1,
      sort = '';
    let active = 1;

    this.brokerageBranchService
      .GetBranchByBrokerageId(brokerageId, page, limit, sort, active)
      .subscribe({
        next: (response) => {
          const tableData = response.data?.map((dataObj) => ({
            key: `${dataObj.name}`,
            value: dataObj.id,
          }));
          this.branches = [...tableData];
          this.tBranches = arrayToObjet(response.data, 'id');
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
  }

  fetchProducers(brokerageBranchId) {
    this.producerUserForm.get('producerId').setValue(null);
    let limit = 10,
      page = 1,
      sort = '';
    let active = 1;
    this.brokerageProducer
      .GetByBranchId(brokerageBranchId, page, limit, sort, active)
      .subscribe({
        next: (response) => {
          const tableData = response.data?.map((dataObj) => ({
            key: `${dataObj.firstName} ${dataObj.lastName}`,
            value: dataObj.id,
          }));
          this.producers = [...tableData];
          this.tProducers = arrayToObjet(response.data, 'id');
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
  }

  brokerValueChanged(event) {
    const broker = this.tBrokers[event];
    this.broker = `${broker.name}`;
    this.fetchBranches(event);
    this.branch = '';
  }

  branchValueChanged(event) {
    const branch = this.tBranches[event];
    this.branch = `${branch.name}`;
    this.fetchProducers(event);
    this.producer = '';
  }

  producerValueChanged(event) {
    const producer = this.tProducers[event];
    this.producer = `${producer.firstName} ${producer.lastName}`;
  }

  roleValueChanged(event) {
    this.invalidRole = '';
    let id = this.roles.find((x) => x.value == event).id;
    const role = this.tRoles[id];
    this.role = `${role.name}`;
    this.producerUserForm.get('roleId').setValue(role.id);
  }

  handleCloseSuccessEvent() {
    this.isSuccess = false;
    this.hasError = false;
  }

  onSubmit(data) {
    if (this.producerUserForm.disabled) {
      return;
    }
    this.formSubmitted = true;
    this.hasError = false;
    // check boxx user form is invalid
    if (this.producerUserForm.invalid) {
      return;
    }
    this.showSpinner = true;

    this.systemUser.UpdateSystemUser(this.userId, data).subscribe(
      (response) => {
        let currentSystemUserId = this.localStorageService.getSystemUserId();
        if (currentSystemUserId === +this.userId) {
          this.showReloadModal = true;
        } else {
          this.isSuccess = true;
        }
        this.currentSystemUser['updatedDt'] = formatDateTime(
          response?.data?.updatedDt,
          this.longDateTimeFormat,
        );
        this.currentSystemUser['updatedBy'] = response?.data?.updatedBy;
        this.message = 'common.saveSuccessMsg';
        this.showSpinner = false;
        this.formSubmitted = false;
      },
      ({ error }) => {
        this.showSpinner = false;
        this.hasError = true;
        this.errorMessage = error.message ? error.message : error.error;
      },
    );
  }

  searchRoles(firstName) {
    // reset form group's boxxUserId value
    this.producerUserForm.get('roleId').setValue(null);
    this.invalidRole = '';
    if (firstName.length >= 3) {
      this.roleService
        .GetByNameNsystemUserType(firstName, this.systemUserType)
        .subscribe({
          next: (response) => {
            this.setRoleSearchResult(response, firstName);
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    } else {
      this.roles = [];
    }
  }

  setRoleSearchResult(data: BoxxResponse<Role>, name) {
    const tableData = data.data?.map((dataObj) => ({
      key: `${dataObj.name}`,
      value: `${dataObj.name}`,
      id: dataObj.id,
    }));
    this.roles = [...tableData];
    this.tRoles = arrayToObjet(data.data, 'id');
    if (tableData.length > 0) {
      this.invalidRole = 'systemUser.error.invalidRole';
    } else {
      this.invalidRole = '';
    }
  }

  initProcessing() {
    this.processingActivation = true;
    this.isSuccess = false;
    this.hasError = false;
  }

  deActivateSystemUser() {
    //
    this.initProcessing();
    this.systemUser.DeleteSystemUser(this.userId).subscribe(
      (response) => {
        this.currentSystemUser['updatedDt'] = formatDateTime(
          response?.data?.updatedDt,
          this.longDateTimeFormat,
        );
        this.currentSystemUser['updatedBy'] = response?.data?.updatedBy;
        this.message = 'common.userDeActivateMsg';
        this.isSuccess = true;
        this.processingActivation = false;
        this.toggleActive = false;
        this.updateStatus(false);
        this.producerUserForm[this.toggleActive ? 'enable' : 'disable']({
          emitEvent: false,
        });
      },
      ({ error }) => {
        this.hasError = true;
        this.processingActivation = false;
        this.errorMessage = error.message ? error.message : error.error;
      },
    );
  }

  activateSystemUser() {
    this.initProcessing();
    this.systemUser.RestoreSystemUser(this.userId).subscribe(
      (response) => {
        this.currentSystemUser['updatedDt'] = formatDateTime(
          response?.data?.updatedDt,
          this.longDateTimeFormat,
        );
        this.currentSystemUser['updatedBy'] = response?.data?.updatedBy;
        this.message = 'common.userActivateMsg';
        this.isSuccess = true;
        this.processingActivation = false;
        this.toggleActive = true;
        this.updateStatus(true);
        this.producerUserForm[this.toggleActive ? 'enable' : 'disable']({
          emitEvent: false,
        });
      },
      ({ error }) => {
        this.hasError = true;
        this.processingActivation = false;
        this.errorMessage = error.message ? error.message : error.error;
      },
    );
  }

  handleCloseSuccessEventExt() {
    this.showErrorAlert = false;
  }
  checkUser() {
    return String(this.loggedUserId) != this.userId;
  }
}
