<div class="branch-information">
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>
  <div class="max-width-wrapper">
    <div class="alert-holder">
      <boxxds-alert-v2
        *ngIf="alertData?.show"
        [type]="alertData?.type"
        [headerText]="alertData?.header | translate"
        [bodyText]="alertData?.body | translate"
        (handleCloseEvent)="handleAlertClose()"
      ></boxxds-alert-v2>
    </div>
    <!-- brokerage information -->
    <form [formGroup]="form">
      <div class="branch-information-wrapper">
        <div class="form-title-wrapper">
          <h2>
            {{ "workFlow3.branchProfile.label.branchInformation" | translate }}
          </h2>
          <h6>
            {{
              "workFlow3.branchProfile.label.enterBranchInformation" | translate
            }}
          </h6>
        </div>
        <div class="form-wrapper">
          <boxxds-textinput-v2
            [form]="branch"
            [readonly]="!this.permissionList[this.currentScreen] || !isActive"
            [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
            [placeholder]="'ABC Company' | translate"
            [control]="'name'"
            [id]="'name'"
            [width]="'100%'"
            [label]="'workFlow3.branchProfile.label.name' | translate"
            [isRequired]="true"
            [requiredErrMsg]="requiredErrMsg"
            [invalidErrMsg]="invalidErrMsg"
            [formSubmitted]="submitted"
            [containerStyle]="{ marginBottom: '20px' }"
          >
          </boxxds-textinput-v2>
          <boxxds-textinput-v2
            [form]="branch"
            [readonly]="!this.permissionList[this.currentScreen] || !isActive"
            [isDisabled]="
              !this.permissionList[this.currentScreen] ||
              !branchSelected?.active
            "
            [placeholder]="'olivia@untitledui.com' | translate"
            [control]="'email'"
            [id]="'email'"
            [width]="'100%'"
            [label]="
              'workFlow3.branchProfile.label.mainEmailAddress' | translate
            "
            [isRequired]="true"
            [requiredErrMsg]="requiredErrMsg"
            [invalidErrMsg]="invalidErrMsg"
            [formSubmitted]="submitted"
            [containerStyle]="{ marginBottom: '20px' }"
          >
          </boxxds-textinput-v2>
          <boxxds-textinput-v2
            [form]="branch"
            [readonly]="!this.permissionList[this.currentScreen] || !isActive"
            [isDisabled]="
              !this.permissionList[this.currentScreen] ||
              !branchSelected?.active
            "
            [mask]="'(000) 000-0000'"
            [placeholder]="'111-111-11111' | translate"
            [control]="'phone'"
            [id]="'phoneNumber'"
            [width]="'100%'"
            [label]="'workFlow3.branchProfile.label.phoneNumber' | translate"
            [isRequired]="false"
            [requiredErrMsg]="requiredErrMsg"
            [invalidErrMsg]="invalidErrMsg"
            [formSubmitted]="submitted"
            [containerStyle]="{ marginBottom: '20px' }"
          >
          </boxxds-textinput-v2>
        </div>
      </div>
      <div class="branch-information-wrapper multi-form-section">
        <section class="address-wrapper">
          <div class="form-title-wrapper">
            <h2>
              {{ "workFlow3.branchProfile.label.branchAddress" | translate }}
            </h2>
            <h6>
              {{
                "workFlow3.branchProfile.label.enterBranchAddress" | translate
              }}
            </h6>
          </div>
          <div class="form-wrapper">
            <app-location-details-form-v2
              (eventEmitRegionIdSelected)="
                this.permissionList[this.currentScreen] &&
                  isActive &&
                  regionIdSelected($event)
              "
              [formSubmitted]="submitted"
              [form]="location"
              [requiredErrMsg]="requiredErrMsg"
              [isActive]="isActive"
            ></app-location-details-form-v2>
          </div>
        </section>
        <section class="teams-wrapper">
          <div class="teams-title-wrapper">
            <h2>
              {{ "workFlow3.branchProfile.label.branchTeams" | translate }}
            </h2>
            <h6>
              {{ "workFlow3.branchProfile.label.addBranchTeams" | translate }}
            </h6>
          </div>
          <div class="teams-form-wrapper">
            <div class="team-input-add-btn-container">
              <div class="input-container">
                <label for="teamName" class="team-input-label">
                  {{ "workFlow3.branchProfile.label.teamsName" | translate }}
                </label>
                <input
                  type="text"
                  name="teamName"
                  id="teamName"
                  class="team-input-field"
                  [placeholder]="
                    'workFlow3.branchProfile.label.teamPlaceholder' | translate
                  "
                  [value]="newTeamName"
                  (keyup)="handleTeamNameChange($event)"
                />
              </div>
              <button
                class="add-team-btn"
                [disabled]="!isTeamNameValid()"
                [class.noPointerEvents]="!isTeamNameValid()"
                (click)="isTeamNameValid() && addNewTeam()"
              >
                <img
                  [src]="
                    isTeamNameValid()
                      ? 'assets/img/plus-circle-v3.svg'
                      : 'assets/img/plus-circle-filled.png'
                  "
                />
                <span>Add</span>
              </button>
            </div>
            <div class="teams-list custom-scrollbar">
              <ng-container *ngFor="let team of teamList; let i = index">
                <div class="teams-list-item">
                  <span class="team-name ellipsis">{{ team.name }}</span>
                  <button class="delete-btn" (click)="deleteTeam(i, team)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M10.6667 4.00065V3.46732C10.6667 2.72058 10.6667 2.34721 10.5213 2.062C10.3935 1.81111 10.1895 1.60714 9.93865 1.47931C9.65344 1.33398 9.28007 1.33398 8.53333 1.33398H7.46667C6.71993 1.33398 6.34656 1.33398 6.06135 1.47931C5.81046 1.60714 5.60649 1.81111 5.47866 2.062C5.33333 2.34721 5.33333 2.72058 5.33333 3.46732V4.00065M6.66667 7.66732V11.0007M9.33333 7.66732V11.0007M2 4.00065H14M12.6667 4.00065V11.4673C12.6667 12.5874 12.6667 13.1475 12.4487 13.5753C12.2569 13.9516 11.951 14.2576 11.5746 14.4493C11.1468 14.6673 10.5868 14.6673 9.46667 14.6673H6.53333C5.41323 14.6673 4.85318 14.6673 4.42535 14.4493C4.04903 14.2576 3.74307 13.9516 3.55132 13.5753C3.33333 13.1475 3.33333 12.5874 3.33333 11.4673V4.00065"
                        stroke="#2D2D39"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
        </section>
      </div>
      <div class="branch-information-wrapper button-wrapper">
        <div class="btn-wrapper">
          <boxxds-button-v2
            [isDisabled]="
              !!!this.isBranchFormDirty && !!!this.isLocationFormDirty
            "
            [buttonText]="'workFlow3.branchProfile.label.discard' | translate"
            [size]="'md'"
            [btnClass]="'secondary gray'"
            [btnType]="'default'"
            [btnStyle]="{ width: '130px', background: '#fff', height: '40px' }"
            (handleClick)="handleDiscardClick()"
          >
          </boxxds-button-v2>
          <boxxds-button-v2
            [isDisabled]="
              !isActive ||
              (!!!this.isBranchFormDirty && !!!this.isLocationFormDirty)
            "
            [buttonText]="'workFlow3.branchProfile.label.save' | translate"
            [type]="'submitted'"
            [size]="'md'"
            [btnClass]="'secondary gray'"
            [btnType]="'default'"
            [btnStyle]="{ width: '130px', background: '#fff', height: '40px' }"
            (handleClick)="handleUpdateBranchClick($event)"
          >
          </boxxds-button-v2>
          <boxxds-button-v2
            *ngIf="!isActive"
            [isDisabled]="showBodySpinner"
            [buttonText]="'Activate'"
            [size]="'md'"
            [btnClass]="'secondary gray'"
            [btnType]="'default'"
            [btnStyle]="{ width: '130px', background: '#fff', height: '40px' }"
            (handleClick)="!showBodySpinner && handleActivateBranch($event)"
          >
          </boxxds-button-v2>
          <boxxds-button-v2
            *ngIf="isActive"
            [isDisabled]="showBodySpinner"
            [buttonText]="'Deactivate'"
            [size]="'md'"
            [btnClass]="'secondary gray'"
            [btnType]="'default'"
            [btnStyle]="{ width: '130px', background: '#fff', height: '40px' }"
            (handleClick)="!showBodySpinner && handleDeactivateBranch($event)"
          >
          </boxxds-button-v2>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="footer-section">
  <app-pagefooter-timestamp-v2
    *ngIf="createdBy && createdAt"
    [createdBy]="createdBy"
    [createdAt]="createdAt"
    [updatedBy]="updatedBy"
    [updatedAt]="updatedAt"
  >
  </app-pagefooter-timestamp-v2>
</div>
