<div class="quote" *ngIf="quoteOptions.length > 0">
  <div class="accrdn-body-expand">
    <button
      [ngClass]="!showAllCards ? 'collapse-btn' : 'expand-btn'"
      (click)="toggleshowAllCards()"
    >
      <span [ngStyle]="{ 'margin-right': '6px' }">{{
        (showAllCards ? "common.collapseBtnLabel" : "common.expandBtnLabel")
          | translate
      }}</span>
    </button>
  </div>
  <div
    class="product"
    *ngFor="let product of getSelectedQuote(quoteOptions); let i = index"
  >
    <div class="prod-details-accrdn">
      <div class="accordion">
        <div class="accordion-card">
          <div class="head" [id]="'product' + i">
            <div class="heading">
              <boxxds-heading
                [sz]="'7'"
                [text]="products[i]"
                type="bold"
                [customStyle]="{ marginBottom: '8px' }"
              >
              </boxxds-heading>
            </div>
            <div class="head-list-contents">
              <div class="headcontents-wrapper">
                <div class="d-flex">
                  <div class="head-contents">
                    <boxxds-body
                      sz="xs"
                      [text]="'quoteSummary.limits' | translate"
                      [customStyle]="{
                        color: '#8F8F91',
                        marginBottom: '2px',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        fontWeight: '400',
                        fontSize: '10px',
                        lineHeight: '16px'
                      }"
                    >
                    </boxxds-body>
                    <boxxds-body
                      sz="md"
                      [text]="
                        isNumber(product.limit)
                          ? (product.limit
                            | currency: currency : 'symbol-narrow' : '1.0-0')
                          : product.limit
                      "
                      [customStyle]="{
                        color: '#56565C',
                        textAlign: 'left',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px'
                      }"
                    >
                    </boxxds-body>
                  </div>
                  <div class="head-contents">
                    <boxxds-body
                      sz="xs"
                      [text]="'quoteSummary.premium' | translate"
                      [customStyle]="{
                        color: '#8F8F91',
                        marginBottom: '2px',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        fontWeight: '400',
                        fontSize: '10px',
                        lineHeight: '16px'
                      }"
                    >
                    </boxxds-body>
                    <boxxds-body
                      sz="md"
                      [text]="
                        product.premium
                          | currency: currency : 'symbol-narrow' : '1.0-0'
                      "
                      [customStyle]="{
                        color: '#56565C',
                        textAlign: 'left',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px'
                      }"
                    >
                    </boxxds-body>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="head-contents">
                    <boxxds-body
                      sz="xs"
                      [text]="'quoteSummary.deductible' | translate"
                      [customStyle]="{
                        color: '#8F8F91',
                        marginBottom: '2px',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        fontWeight: '400',
                        fontSize: '10px',
                        lineHeight: '16px'
                      }"
                    >
                    </boxxds-body>
                    <boxxds-body
                      sz="md"
                      [text]="
                        isNumber(product.deductible)
                          ? (product.deductible
                            | currency: currency : 'symbol-narrow' : '1.0-0')
                          : product.deductible
                      "
                      [customStyle]="{
                        color: '#56565C',
                        textAlign: 'left',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px'
                      }"
                    >
                    </boxxds-body>
                  </div>
                  <div class="head-contents">
                    <boxxds-body
                      sz="xs"
                      [text]="'quoteSummary.commissions' | translate"
                      [customStyle]="{
                        color: '#8F8F91',
                        marginBottom: '2px',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        fontWeight: '400',
                        fontSize: '10px',
                        lineHeight: '16px'
                      }"
                    >
                    </boxxds-body>
                    <boxxds-body
                      sz="md"
                      [text]="
                        ((product.brokerCommissionPerc &&
                          getBrokerageCommissionPerc(
                            product.brokerCommissionPerc
                          )) ||
                          0) + '%'
                      "
                      [customStyle]="{
                        color: '#56565C',
                        textAlign: 'left',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px'
                      }"
                    >
                    </boxxds-body>
                  </div>
                </div>
              </div>
              <button
                class="collapse-btn"
                *ngIf="!openCardList.includes(i)"
                (click)="toggleShowCard(i)"
              >
                <span>{{ "quoteSummary.seeMoreDetails" | translate }}</span
                ><img src="assets/img/dashboard/quote/chevron-up.png" />
              </button>
            </div>
          </div>
          <div
            class="accrdn-body customScroll custom-scroll"
            [ngClass]="{ active: openCardList.includes(i) }"
          >
            <div class="accrdn-body-contents">
              <div class="first-col"></div>
              <div class="second-col-grid">
                <div class="second-col">
                  <div
                    class="body-content"
                    *ngIf="underWriters && underWriters[i]?.length > 0"
                  >
                    <boxxds-body
                      [text]="'quoteSummary.underwriter' | translate"
                      [customStyle]="{
                        color: '#8F8F91',
                        marginBottom: '2px',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        fontWeight: '400',
                        fontSize: '10px',
                        lineHeight: '16px'
                      }"
                    >
                    </boxxds-body>
                    <boxxds-body
                      sz="md"
                      [text]="underWriters[i]"
                      [customStyle]="{
                        color: '#56565C',
                        textAlign: 'left',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px'
                      }"
                    >
                    </boxxds-body>
                  </div>
                  <div
                    class="body-content"
                    *ngIf="selectedWordings[products[i]]?.length > 0"
                  >
                    <boxxds-body
                      [text]="'quoteSummary.wordings' | translate"
                      [customStyle]="{
                        color: '#8F8F91',
                        marginBottom: '2px',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        fontWeight: '400',
                        fontSize: '10px',
                        lineHeight: '16px'
                      }"
                    >
                    </boxxds-body>
                    <ul>
                      <li
                        *ngFor="
                          let doc of selectedWordings[products[i]];
                          let selectedIdx = index
                        "
                      >
                        {{ getFilename(doc.key) }} included
                      </li>
                    </ul>
                  </div>

                  <div
                    class="body-content"
                    *ngIf="selectedDeclarations[products[i]]?.length > 0"
                  >
                    <boxxds-body
                      [text]="'quoteSummary.declarations' | translate"
                      [customStyle]="{
                        color: '#8F8F91',
                        marginBottom: '2px',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        fontWeight: '400',
                        fontSize: '10px',
                        lineHeight: '16px'
                      }"
                    >
                    </boxxds-body>
                    <ul>
                      <li
                        *ngFor="
                          let doc of selectedDeclarations[products[i]];
                          let selectedIdx = index
                        "
                      >
                        {{ getFilename(doc.key) }} included
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="second-col">
                  <div
                    class="body-content"
                    *ngIf="
                      pdtDescription[products[i]] &&
                      pdtDescription[products[i]]?.length > 0
                    "
                  >
                    <boxxds-body
                      [text]="'quoteSummary.planDetails' | translate"
                      [customStyle]="{
                        color: '#8F8F91',
                        marginBottom: '2px',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        fontWeight: '400',
                        fontSize: '10px',
                        lineHeight: '16px'
                      }"
                    >
                    </boxxds-body>
                    <ul>
                      <li *ngFor="let desc of pdtDescription[products[i]]">
                        {{ desc }}
                      </li>
                    </ul>
                  </div>
                  <div
                    class="body-content"
                    *ngIf="
                      addons[products[i]] && addons[products[i]].length > 0
                    "
                  >
                    <boxxds-body
                      sz="xs"
                      [text]="'quoteSummary.addOns' | translate"
                      [customStyle]="{
                        color: '#8F8F91',
                        marginBottom: '2px',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        fontWeight: '400',
                        fontSize: '10px',
                        lineHeight: '16px'
                      }"
                    >
                    </boxxds-body>
                    <ul>
                      <li
                        *ngFor="
                          let addon of addons[products[i]];
                          let addonIdx = index
                        "
                      >
                        {{ addon?.description || "" }}
                      </li>
                    </ul>
                  </div>
                  <div
                    class="body-content"
                    *ngIf="selectedEndorsements[products[i]]?.length > 0"
                  >
                    <boxxds-body
                      [text]="'quoteSummary.endorsements' | translate"
                      [customStyle]="{
                        color: '#8F8F91',
                        marginBottom: '2px',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        fontWeight: '400',
                        fontSize: '10px',
                        lineHeight: '16px'
                      }"
                    >
                    </boxxds-body>
                    <ul>
                      <li
                        *ngFor="
                          let doc of selectedEndorsements[products[i]];
                          let selectIdx = index
                        "
                      >
                        {{ getFilename(doc.key) }} included
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="second-col">
                  <div
                    class="body-content"
                    *ngIf="selectedSubjectivities[products[i]]?.length > 0"
                  >
                    <boxxds-body
                      [text]="'quoteSummary.subjectivities' | translate"
                      [customStyle]="{
                        color: '#8F8F91',
                        marginBottom: '2px',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        fontWeight: '400',
                        fontSize: '10px',
                        lineHeight: '16px'
                      }"
                    >
                    </boxxds-body>
                    <ul>
                      <span
                        *ngFor="
                          let sub of selectedSubjectivities[products[i]];
                          let selectedIdx = index
                        "
                      >
                        <li *ngIf="sub">
                          <div class="subjectivity-list">
                            {{ sub.key }} included
                          </div>
                        </li>
                      </span>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="accrdn-body-footer">
              <button class="expand-btn" (click)="toggleShowCard(i)">
                <span>{{ "quoteSummary.hideDetails" | translate }}</span
                ><img src="assets/img/dashboard/quote/chevron-up.png" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
