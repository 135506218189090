import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs';
import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';

@Component({
  selector: 'app-modal-submisison-submitted',
  templateUrl: './modal-submission-submitted.component.html',
  styleUrls: ['./modal-submission-submitted.component.less'],
})
export class ModalSubmissionSubmittedWorkFlow3Component implements OnInit {
  @Input() showModal: boolean = false;
  @Input() existingQuoteOptionData;

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleStartSubmission = new EventEmitter<any>();
  @Output() handleGotoDashboard = new EventEmitter<any>();

  isRestrictedRoleSubmission: boolean = false;
  currency = '';

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.isRestrictedRoleSubmission = data.isRestrictedRoleSubmission;
    });
    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));
  }
  closeModal() {
    this.handleClose.emit();
  }
  startSubmission() {
    this.handleStartSubmission.emit(true);
  }
  gotoDashboard() {
    this.handleGotoDashboard.emit(true);
  }
}
