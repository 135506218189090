import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BoxxResponse } from 'src/app/entities/boxx-response';
import { BoxxUser } from 'src/app/entities/boxx-user';
import { Breadcrumb } from 'src/app/models/breadcrumb.model';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import {
  arrayToObjet,
  getAlertType,
  regexHelpers,
  getErrorMessage,
} from 'src/app/utils/utils';
import { formatDateTime } from 'src/app/utils/formatDate';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { Store, select } from '@ngrx/store';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';

@Component({
  selector: 'app-edit-boxx-user',
  templateUrl: './edit-boxx-user.component.html',
  styleUrls: ['./edit-boxx-user.component.less'],
})
export class EditBoxxUserComponent implements OnInit {
  itemsMenu: Breadcrumb[] = [];
  managers: any = [];
  requiredErrMsg: string = 'error.thisFieldIsRequired';
  invalidErrMsg: string = 'error.thisFieldIsInvalid';
  invalidManager: string = '';
  toggleActive: boolean = false;
  formSubmitted: boolean = false;
  manager = '';
  title = '';
  showSpinner: boolean = false;
  fullName: string;
  editForm: FormGroup;
  isEditMode: boolean = false;
  editIndex: number = -1;
  id: string;
  user: any;
  active: any;
  processingActivation = false;
  showBodySpinner: boolean = false;
  showErrorAlert = false;
  msgErrorAlert = '';
  userID: number;

  alterDetails: {
    isActive: boolean;
    type: string;
    headerText: string;
    bodyText: string;
  } = {
    isActive: false,
    type: '',
    headerText: '',
    bodyText: '',
  };
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  shortDateTimeFormat: string = '';
  longDateTimeFormat: string = '';

  constructor(
    private boxxUserService: BoxxUserService,
    private router: Router,
    private activedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private store: Store,
  ) {
    this.id = this.activedRoute.snapshot.paramMap.get('id');

    if (this.id) {
      this.fetchUserDetails(this.id);
    }
  }

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateTimeFormat = data.shortDateTimeFormat;
      this.longDateTimeFormat = data.longDateTimeFormat;
    });

    this.showBodySpinner = true;
    this.userID = this.localStorageService.getBoxxUserId();
    this.editForm = new FormGroup({
      firstName: new FormControl('', [
        Validators.required,
        Validators.pattern(regexHelpers.AlphaNumericSpace),
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.pattern(regexHelpers.AlphaNumericSpace),
      ]),
      title: new FormControl('', [Validators.required]),
      manager: new FormControl('', [Validators.required]),
    });
    this.editForm[this.active ? 'enable' : 'disable']({ emitEvent: false });
  }

  setUserResult(data: BoxxResponse<BoxxUser>) {
    const { data: user } = data;
    this.user = user;
    this.showBodySpinner = false;
    this.populateForm(this.user);
  }

  populateForm(user) {
    this.fullName = this.user.firstName + ' ' + this.user.lastName;
    this.itemsMenu = [
      { label: 'Boxx users directory', path: '/dashboard/boxx-user-directory' },
      { label: this.fullName, path: null },
    ];
    this.active = this.user.active;
    this.editForm[this.active ? 'enable' : 'disable']({ emitEvent: false });
    //values to form fields
    this.editForm.get('firstName').setValue(user.firstName);
    this.editForm.get('lastName').setValue(user.lastName);
    this.editForm.get('title').setValue(user.title);
    this.editForm.get('manager').setValue(user.boxxManager.id);
    this.manager = user.boxxManager.firstName + ' ' + user.boxxManager.lastName;
    this.user['createdDt'] = formatDateTime(
      user.createdDt,
      this.longDateTimeFormat,
    );
    this.user['updatedDt'] = formatDateTime(
      user.updatedDt,
      this.longDateTimeFormat,
    );
  }

  searchManagers(name) {
    this.invalidManager = '';
    this.editForm.get('manager').setValue(null);
    if (name.length >= 3) {
      let limit = 10,
        page = 1,
        sort = '';
      let active = 1;
      this.boxxUserService
        .GetAllUserByName(name, page, limit, sort, active)
        .subscribe({
          next: (response) => {
            const tableData = response.data?.map((dataObj) => ({
              key: `${dataObj.name}`,
              value: dataObj.name,
              id: dataObj.id,
            }));
            this.showBodySpinner = false;
            this.managers = [...tableData];
            if (tableData.length > 0) {
              this.invalidManager = 'error.thisFieldIsRequired';
            } else {
              this.invalidManager = '';
            }
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    } else {
      this.managers = [];
    }
  }

  resetBanner() {
    this.handleAlertDetails(false, '', '', '');
  }

  managerChanged(value) {
    const managers = arrayToObjet(this.managers, 'value');
    const manager = managers[value];
    this.manager = manager.key;
    this.editForm.get('manager').setValue(manager.id);
    this.invalidManager = '';
  }

  fetchUserDetails(roleId) {
    this.isEditMode = true;
    this.boxxUserService.Get(roleId).subscribe(
      (data) => {
        this.setUserResult(data);
      },
      (error) => {
        this.showSpinner = false;
        this.showBodySpinner = false;
        this.handleAlertDetails(
          true,
          'error',
          'common.errorMsg',
          error.message,
        );
      },
    );
  }

  onSubmitEdit(data) {
    this.resetBanner();
    if (this.editForm.disabled) {
      return;
    }
    this.formSubmitted = true;
    this.handleAlertDetails(false, '', '', '');

    if (this.editForm.invalid) {
      return;
    }
    this.showSpinner = true;

    this.boxxUserService.UpdateBoxxUser(this.id, data).subscribe(
      (response) => {
        this.showSpinner = false;
        this.showBodySpinner = false;
        this.handleAlertDetails(
          true,
          'success',
          'common.successMsg',
          'common.updateSuccessMsg',
        );
        this.formSubmitted = false;
        this.user = {
          ...this.user,
          ...response.data,
        };
        this.populateForm(this.user);
      },
      ({ error }) => {
        this.showSpinner = false;
        this.showBodySpinner = false;
        this.handleAlertDetails(
          true,
          'error',
          'common.errorMsg',
          error.message,
        );
      },
    );
  }

  deActivateSystemUser() {
    this.processingActivation = true;
    this.handleAlertDetails(false, '', '', '');
    this.showBodySpinner = true;
    this.boxxUserService.DeleteBoxxUser(this.id).subscribe(
      (response) => {
        this.user['updatedDt'] = formatDateTime(
          response?.data?.updatedDt,
          this.longDateTimeFormat,
        );
        this.user['updatedBy'] = response?.data?.updatedBy;
        this.active = false;
        this.showSpinner = false;
        this.showBodySpinner = false;
        this.formSubmitted = false;
        this.processingActivation = false;
        this.editForm['disable']({ emitEvent: false });
        let alertType = getAlertType(response?.code ?? 'OPERATION_SUCCESS');

        let alertHead =
          alertType === 'warn'
            ? 'common.warnMsg'
            : alertType === 'info'
            ? 'common.infoMsg'
            : alertType === 'success'
            ? 'common.successMsg'
            : 'common.errorMsg';

        this.handleAlertDetails(
          true,
          alertType,
          alertHead,
          response?.warning ?? 'common.userDeActivateMsg',
        );
      },
      ({ error }) => {
        this.showBodySpinner = false;
        this.handleAlertDetails(
          true,
          'error',
          'common.errorMsg',
          error.message,
        );
      },
    );
  }
  activateSystemUser() {
    this.processingActivation = true;
    this.handleAlertDetails(false, '', '', '');
    this.showBodySpinner = true;
    this.boxxUserService.RestoreBoxxUser(this.id).subscribe(
      (response) => {
        this.user['updatedDt'] = formatDateTime(
          response?.data?.updatedDt,
          this.longDateTimeFormat,
        );
        this.user['updatedBy'] = response?.data?.updatedBy;
        this.active = true;
        this.showSpinner = false;
        this.showBodySpinner = false;
        this.formSubmitted = false;
        this.processingActivation = false;
        this.editForm['enable']({ emitEvent: false });
        let alertType = getAlertType(response?.code ?? 'OPERATION_SUCCESS');

        let alertHead =
          alertType === 'warn'
            ? 'common.warnMsg'
            : alertType === 'info'
            ? 'common.infoMsg'
            : alertType === 'success'
            ? 'common.successMsg'
            : 'common.errorMsg';

        this.handleAlertDetails(
          true,
          alertType,
          alertHead,
          response?.warning ?? 'common.userActivateMsg',
        );
      },
      ({ error }) => {
        this.handleAlertDetails(
          true,
          'error',
          'common.errorMsg',
          error.message,
        );
        this.processingActivation = false;
        this.showBodySpinner = false;
      },
    );
  }
  handleCloseSuccessEventExt() {
    this.showErrorAlert = false;
  }
  checkUser() {
    return String(this.userID) != this.id;
  }

  handleAlertDetails(
    status: boolean,
    type: string,
    headerTxt: string,
    bodyTxt: string,
  ) {
    this.alterDetails = {
      isActive: status,
      type: type,
      headerText: headerTxt,
      bodyText: bodyTxt,
    };
  }
}
