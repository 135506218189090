import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';
import { StoreModule } from '@ngrx/store';
import { CommonModule, DatePipe } from '@angular/common';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BoxxdsModule } from '@boxx/boxxds';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxMaskModule } from 'ngx-mask';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthComponent } from './auth/auth.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { CreatePasswordComponent } from './auth/create-password/create-password.component';
import { CheckInboxComponent } from './auth/check-inbox/check-inbox.component';
import { MfaComponent } from './auth/mfa/mfa.component';
import { DashboardHomeComponent } from './dashboard/dashboard-home/dashboard-home.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { SearchInputComponent } from './components/search/search-input.component';
import { NotificationBadgeComponent } from './components/notification-badge/notification-badge.component';
import { InfoTileComponent } from './components/info-tile/info-tile.component';
import { CardComponent } from './components/card/card.component';
import { SearchResultComponent } from './dashboard/search-result/search-result.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BrokerageDirectoryComponent } from './dashboard/brokerage/brokerage-directory/brokerage-directory.component';
import { NewBranchComponent } from './pages/branch/new-branch/new-branch.component';
import { QuoteComponent } from './dashboard/quote/quote.component';
import { NewSubmissionComponent } from './dashboard/quote/new-submission/new-submission.component';
import { ProductSelectionComponent } from './dashboard/quote/product-selection/product-selection.component';
import { LocationDetailsFormComponent } from './components/location-details-form/location-details-form.component';
import { MainContactComponent } from './components/main-contact/main-contact.component';
import { AbbreviateNumberPipe } from './pipes/abbreviate-number.pipe';
import { ConnectFormDirective } from './directives/connect-form.directive';
import { ProductFormComponent } from './dashboard/quote/product-form/product-form.component';
import { BranchDetailsComponent } from './pages/branch/components/branch-details/branch-details.component';
import { ViewBranchComponent } from './pages/branch/view-branch/view-branch.component';
import { BrokerageService } from './services/brokerage.service';
import { BrokerageProducerService } from './services/brokerage-producer.service';
import { BrokerageBranchService } from './services/brokerage-branch.service';
import { BrokerageDirectoryService } from './services/brokerage-directory.service';
import { NewBrokerageComponent } from './pages/brokerage/new-brokerage/new-brokerage.component';
import { NewProducerComponent } from './pages/branch/new-producer/new-producer.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { QuoteCalculatorComponent } from './dashboard/quote/quote-calculator/quote-calculator.component';
import { ViewBrokerageComponent } from './pages/brokerage/view-brokerage/view-brokerage.component';
import { QuoteOptionComponent } from './dashboard/quote/quote-calculator/quote-option/quote-option.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { featureReducers } from './store';
import { EffectsModule } from '@ngrx/effects';
import { RiskRegionEffects } from './store/riskRegion/riskRegion.effects';
import { QuoteCompareComponent } from './dashboard/quote/quote-compare/quote-compare.component';
import { ModalSendComponent } from './components/modal-send/modal-send.component';
import { CommissionDetailsComponent } from './components/commission/commission-details/commission-details.component';
import { AddCommissionComponent } from './components/commission/add-commission/add-commission.component';
import { UpdateCommissionComponent } from './components/commission/update-commission/update-commission.component';
import { ActionsCommissionComponent } from './components/commission/actions-commission/actions-commission.component';
import { ViewCommissionComponent } from './components/commission/view-commission/view-commission.component';
import { ContainerCommissionDetailComponent } from './components/commission/container-commission-detail/container-commission-detail.component';
import { SpinnerLoadComponent } from './components/spinner-load/spinner-load.component';
import { DocumentPreviewComponent } from './components/document-preview/document-preview.component';
import { SafePipe } from './pipes/safe.pipe';
import { ProductSummaryComponent } from './dashboard/quote/product-summary/product-summary.component';
import { InsuredService } from './services/insured.service';
import { RoleService } from './services/roles.service';
import { PolicyService } from './services/policy.service';
import { RolePermissionService } from './services/role-permissions.service';
import { SystemUserService } from './services/system-user.service';
import { BoxxUserService } from './services/boxx-user.service';
import { AvatarModule } from 'ngx-avatars';
import { PolicyDashboardService } from './services/policy-dashboard.service';
import { InsuredEffects } from './store/create-quote/create-quote.effect';
import { BrokerageDetailsComponent } from './pages/brokerage/components/brokerage-details/brokerage-details.component';
import { CommissionEffects } from './store/commission/commission.effect';
import { BranchTableComponent } from './pages/brokerage/components/branch-table/branch-table.component';
import { ImageLogoComponent } from './components/image-logo/image-logo.component';
import { QuoteSummaryComponent } from './dashboard/quote/quote-summary/quote-summary.component';
import { BindPolicyButtonComponent } from './components/bind-policy-button/bind-policy-button.component';
import { PolicyGridComponent } from './dashboard/policy-grid/policy-grid.component';
import { TimestampComponent } from './components/timestamp/timestamp.component';
import { ProducerProfileComponent } from './dashboard/brokerage/producer-profile/producer-profile.component';
import { FilterComponent } from './components/filter/filter.component';
import { PolicyStatsService } from './services/policy-stats.service';
import { SecurityRoleComponent } from './dashboard/security/roles/roles.component';
import { QuotesGridComponent } from './dashboard/quotes-grid/quotes-grid.component';
import { BoxxUserDirectoryComponent } from './dashboard/boxx-user-directory/boxx-user-directory.component';
import { SystemUserDirectoryComponent } from './dashboard/system-user-directory/system-user-directory.component';
import { PolicySlideoutComponent } from './components/policy-slideout/policy-slideout.component';
import { MarketReservationComponent } from './dashboard/quote/market-reservation/market-reservation.component';
import { NewSystemUserComponent } from './dashboard/system-user-directory/new-system-user/new-system-user.component';
import { CognitoComponent } from './cognito/cognito.component';
import { NotesComponent } from './components/notes/notes.component';
import { NewBoxxUserComponent } from './dashboard/boxx-user-directory/new-boxx-user/new-boxx-user.component';
import { QuoteLifecycleComponent } from './dashboard/quote-lifecycle/quote-lifecycle.component';
import { LifecycleDetailsComponent } from './components/lifecycle-details/lifecycle-details.component';
import { NewPermissionSystemUserComponent } from './dashboard/security/new-system-user/new-system-user.component';
import { NewSystemBoxxUserComponent } from './dashboard/system-user-directory/new-system-user/new-system-boxx-user.component';
import { NewSystemInsuredUserComponent } from './dashboard/system-user-directory/new-system-user/new-system-insured-user.component';
import { NewSystemProducerUserComponent } from './dashboard/system-user-directory/new-system-user/new-system-producer-user.component';
import { CardSkeletonComponent } from './components/card-skeleton/card-skeleton.component';
import { QuoteGridFilterComponent } from './components/quote-grid-filter/quote-grid-filter.component';
import { PolicyGridFilterComponent } from './components/policy-grid-filter/policy-grid-filter.component';
import { UnauthorizedComponent } from './auth/unauthorized/unauthorized.component';
import { LogoutPopupComponent } from './components/logout-popup/logout-popup.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { SearchableListComponent } from './components/searchablelist/searchablelist.component';
import { PermissionsComponent } from './dashboard/security/permissions/permissions.component';
import { QuoteActionPopupComponent } from './components/quote-action-popup/quote-action-popup.component';
import { PolicyCancelPopupComponent } from './components/policy-cancel-popup/policy-cancel-popup.component';
import { PolicyLifecycleComponent } from './dashboard/policy-lifecycle/policy-lifecycle.component';
import { PolicyReinstatePopupComponent } from './components/policy-reinstate-popup/policy-reinstate-popup.component';
import { PolicyNewPeriodPopupComponent } from './components/policy-new-period-popup/policy-new-period-popup.component';
import { UserProfileComponent } from './dashboard/security/user-profile/user-profile.component';
import { RoleDetailsComponent } from './dashboard/security/role-details/role-details.component';
import { MapService } from '@boxx/boxxds';
import { MAP_SERVICE_CONFIG } from '@boxx/boxxds';
import { NewRoleComponent } from './dashboard/security/new-role/new-role.component';
import { NewPermissionComponent } from './dashboard/security/new-permission/new-permission.component';
import { EditProducerComponent } from './pages/branch/edit-producer/edit-producer.component';
import { EditRoleComponent } from './dashboard/security/edit-role/edit-role.component';
import { ComingSoonComponent } from './auth/coming-soon/coming-soon.component';
import { BrokerageProducerProfileService } from './services/brokerage-producer-profile.service';
import { EditBoxxUserComponent } from './dashboard/boxx-user-directory/edit-boxx-user/edit-boxx-user.component';
import { EditSystemUserComponent } from './dashboard/system-user-directory/edit-system-user/edit-system-user.component';
import { EditSystemBoxxUserComponent } from './dashboard/system-user-directory/edit-system-user/edit-system-boxx-user.component';
import { EditSystemInsuredUserComponent } from './dashboard/system-user-directory/edit-system-user/edit-system-insured-user.component';
import { EditSystemProducerUserComponent } from './dashboard/system-user-directory/edit-system-user/edit-system-producer-user.component';
import { EditPermissionComponent } from './dashboard/security/edit-permission/edit-permission.component';
import { CoverageLimitComponent } from './components/coverage-limit/coverage-limit.component';
import { DocTemplateSelectionPopupComponent } from './components/doc-template-selection-popup/doc-template-selection-popup.component';
import { ModalReasonsPopupComponent } from './components/modal-reasons-popup/modal-reasons-popup.component';

import { AuthInterceptor } from './services/interceptors/auth.interceptor';
import { NgxEditorModule } from 'ngx-editor';
import { schema } from 'ngx-editor/schema';
import { MinMaxRangeComponent } from './components/min-max-range/min-max-range.component';
import { PolicyGridSlideoutComponent } from './components/policy-grid-slideout/policy-grid-slideout.component';
import { RestrictiveIndustriesComponent } from './dashboard/quote/restrictive-industries/restrictive-industries.component';
import { ViewReferralReasonsComponent } from './dashboard/quote/quote-calculator/view-referral-reasons/view-referral-reasons.component';
import { InsuredComponent } from './dashboard/insured/insured.component';
import { InsuredDirectoryComponent } from './dashboard/insured/insured-directory/insured-directory.component';
import { InsuredFormComponent } from './dashboard/insured/insured-form/insured-form.component';
import { CompanyInsuredFormComponent } from './components/company-insured-form/company-insured-form.component';
import { ProductDetailAccordionComponent } from './components/product-detail-accordion/product-detail-accordion.component';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { ModalCommissionConfirmationComponent } from './components/modal-commissionConfirmation/modal-commissionConfirmation.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SubmissionRecalculateConfComponent } from './components/modal-submissionRecalculateConfirmation/modal-submissionRecalculateConf.component';
import { ModalSublimitSlideOutComponent } from './components/modal-sublimitSlideOut/modal-sublimitSlideOut.component';
import { ScrollableTableComponent } from './components/scrollable-table/scrollable-table.component';
import { QuoteSlideoutComponent } from './components/quote-slideout/quote-slideout.component';
import { ReloadModalComponent } from './components/reload-modal/reload-modal.component';
import { Cognito4FlowComponent } from './cognito4Flow/cognito4Flow.component';
import { IndividualInsuredFormComponent } from './components/individual-insured-form/individual-insured-form.component';
import { NewSubmissionQuote4FlowComponent } from './quote4-flow/new-submission/new-submission.component';
import { PolicyConfigurationComponent } from './quote4-flow/policy-configuration/policy-configuration.component';
import { FooterWrapperComponent } from './quote4-flow/components/footer-wrapper/footer-wrapper.component';
import { ModalSanctionComponent } from './components/modal-sanction/modal-sanction.component';

import { Quote4FlowHeaderComponent } from './quote4-flow/components/quote4-flow-header/quote4-flow-header.component';
import { ProgressBarComponent } from './quote4-flow/components/progress-bar/progress-bar.component';
import { Quote4FlowComponent } from './quote4-flow/quote4-flow.component';
import { QuoteCalculatorAndOptionsComponent } from './quote4-flow/quote-calculator-and-options/quote-calculator-and-options.component';
import { TruncateTextPipe } from './pipes/truncate-text.pipe';
import { ProductInfoComponent } from './quote4-flow/product-info/product-info.component';
import { QuoteOptionsComponent } from './quote4-flow/components/quote-option/quote-options.component';
import { DropdownButtonComponent } from './quote4-flow/components/dropdown-button/dropdown-button.component';
import { Cognito7FlowComponent } from './cognito7-flow/cognito7-flow.component';
import { DeleteConfirmationPopupComponent } from './components/delete-confirmation-popup/delete-confirmation-popup.component';
import { ImageDocPreviewComponent } from './components/image-doc-preview/image-doc-preview.component';
import { SlideoutDocumentComponent } from './components/slideout-document/slideout-document.component';
import { DocumentActionPopupComponent } from './components/document-action-popup/document-action-popup.component';
import { RestrictedIndustryOrSanctionPopupComponent } from './components/restricted-industry-or-sanction-popup/restricted-industry-or-sanction-popup.component';
import { QuoteWorkflow3Component } from './quote-workflow3/quote-workflow3.component';
import { SubmissionWorkflow3Component } from './quote-workflow3/submission/submission.component';
import { ModalSubmissionRiskAnalysisComponent } from './components/modal-submission-risk-analysis/modal-submission-risk-analysis.component';
import { ModalSubmissionRiskAnalysisQuestionsComponent } from './components/modal-submission-risk-analysis-questions/modal-submission-risk-analysis-questions.component';
import { ModalSanctionsRiskQuestionsComponent } from './components/modal-sanctions-risk-questions/modal-sanctions-risk-questions.component';
import { ModalInsuredStateUpdateComponent } from './components/modal-insured-state-update/modal-insured-state-update.component';
import { PopoverV2Component } from './quote-workflow3/components/popover-v2/popover-v2.component';
import { QuoteActionPopupV2Component } from './quote-workflow3/components/quote-action-popup-v2/quote-action-popup-v2.component';
import { ModalExistingQuoteWorkFlow3Component } from './quote-workflow3/components/modal-existing-quote/modal-existing-quote.component';
import { ModalMarketReservationWorkFlow3Component } from './quote-workflow3/components/modal-market-reservation/modal-market-reservation.component';
import { ModalPremiumRecalculateWorkFlow3Component } from './quote-workflow3/components/modal-premium-recalculate/modal-premium-recalculate.component';
import { SideMenuWorkflow3Component } from './quote-workflow3/components/side-menu-workflow3/side-menu-workflow3.component';
import { ModalExitQuotingProcessComponent } from './quote-workflow3/components/modal-exit-quoting-process/modal-exit-quoting-process.component';
import { ModalLogoutComponent } from './quote-workflow3/components/modal-logout/modal-logout.component';
import { DocTemplateSelectionComponent } from './quote-workflow3/components/doc-template-selection/doc-template-selection.component';
import { ModalRiskAnalysisComponent } from './quote-workflow3/components/modal-risk-analysis/modal-risk-analysis.component';
import { ModalInsuredStateChangeComponent } from './quote-workflow3/components/modal-insured-state-change/modal-insured-state-change.component';
import { InfoBannerV2Component } from './quote-workflow3/components/info-banner-v2/info-banner-v2.component';
import { ProducerInformationComponent } from './quote-workflow3/components/producer-information/producer-information.component';
import { BrokerCommissionv2ModalComponent } from './quote-workflow3/components/broker-commission-v2-modal/broker-commission-v2-modal.component';
import { BrokerageRedirectModalComponent } from './quote-workflow3/components/brokerage-redirect-modal/brokerage-redirect-modal.component';
import { QuoteSelectionComponent } from './quote-workflow3/components/quote-selection/quote-selection.component';
import { RiskAnalysisWorkflow3Component } from './quote-workflow3/risk-analysis/risk-analysis.component';
import { PolicyConfigurationWF3Component } from './quote-workflow3/policy-configuration/policy-configuration.component';
import { ModalSendQuoteComponent } from './quote-workflow3/components/modal-send-quote/modal-send-quote.component';
import { DropdownMenuButtonV2Component } from './quote-workflow3/components/dropdown-menu-button-v2/dropdown-menu-button-v2.component';
import { QuotingComponent } from './quote-workflow3/quoting/quoting.component';
import { SublimitSlideOutComponent } from './quote-workflow3/components/sublimit-slideout/sublimit-slideout.component';
import { ScrollableTableV2Component } from './quote-workflow3/components/scrollable-table-v2/scrollable-table-v2.component';
import { CheckboxV2Component } from './quote-workflow3/components/checkbox-v2/checkbox-v2.component';
import { InsuredWF3Component } from './quote-workflow3/insured/insured.component';
import { InsuredDirectoryWF3Component } from './quote-workflow3/insured/insured-directory/insured-directory.component';
import { NotesV2Component } from './quote-workflow3/components/notes-v2/notes-v2.component';
import { NavigationV2Component } from './quote-workflow3/components/navigation-v2/navigation-v2.component';
import { ButtonGroupComponent } from './quote-workflow3/components/button-group/button-group.component';
import { ModalSubjectivitiesComponent } from './quote-workflow3/components/modal-subjectivities/modal-subjectivities.component';
import { DocumentPreviewV2Component } from './components/document-preview-v2/document-preview-v2.component';
import { ModalDeclarationsComponent } from './quote-workflow3/components/modal-declarations/modal-declarations.component';
import { ModalWordingsComponent } from './quote-workflow3/components/modal-wordings/modal-wordings.component';
import { QuotesGridV2Component } from './quote-workflow3/components/quotes-grid-v2/quotes-grid-v2.component';
import { ModalAlertComponent } from './quote-workflow3/components/modal-alert/modal-alert.component';
import { ModalEndorsementsComponent } from './quote-workflow3/components/modal-endorsements/modal-endorsements.component';
import { RevenueOrSanctionPopupComponent } from './quote-workflow3/components/revenue-or-sanction-popup/revenue-or-sanction-popup.component';
import { QuoteNotesV2Component } from './quote-workflow3/components/quote-notes-v2/quote-notes-v2.component';
import { PolicyDirectoryComponent } from './quote-workflow3/policy-directory/policy-directory.component';
import { SearchResultV2Component } from './quote-workflow3/components/search-result-v2/search-result-v2.component';
import { DynamicFilterv2ModalComponent } from './quote-workflow3/components/dynamic-filter-v2-modal/dynamic-filter-v2-modal.component';
import { PlcEndorsementSectionV2Component } from './quote-workflow3/components/plc-endorsement-section-v2/plc-endorsement-section-v2.component';
import { ModalCommissionChangeWorkFlow3Component } from './quote-workflow3/components/modal-commission-change/modal-commission-change.component';

import { BusinessInsuredFormWorkflow3Component } from './quote-workflow3/business-insured-form-workflow3/business-insured-form-workflow3.component';
import { IndividualInsuredFormWorkflow3Component } from './quote-workflow3/individual-insured-form-workflow3/individual-insured-form-workflow3.component';
import { InsuredFormWorkflow3Component } from './quote-workflow3/insured-form-workflow3/insured-form-workflow3.component';
import { BranchProfileComponent } from './quote-workflow3/brokerage-module/branch-profile/branch-profile.component';
import { BrokerageModuleComponent } from './quote-workflow3/brokerage-module/brokerage-module.component';
import { BrokerageProfileComponent } from './quote-workflow3/brokerage-module/brokerage-profile/brokerage-profile.component';
import { ProducerComponent } from './quote-workflow3/brokerage-module/producer-profile/producer-profile.component';
import { CommissionRateWorkflow3Component } from './quote-workflow3/components/commission-workflow3/commission-rate-workflow3/commission-rate-workflow3.component';
import { BrokerageDirectoryWorkflow3Component } from './quote-workflow3/brokerage-module/brokerage-directory/brokerage-directory-workflow3.component';
import { CommissionTableWorkflow3Component } from './quote-workflow3/components/commission-workflow3/commission-rate-workflow3/commission-table-workflow3/commission-table-workflow3.component';
import { LocationDetailsFormV2Component } from './quote-workflow3/components/location-details-form-v2/location-details-form-v2.component';
import { BrokerageDetailsV2Component } from './quote-workflow3/components/brokerage-details-v2/brokerage-details-v2.component';
import { ViewBranchV2Component } from './quote-workflow3/brokerage-module/branch-profile/view-branch-v2/view-branch-v2.component';
import { AddBranchV2Component } from './quote-workflow3/brokerage-module/branch-profile/add-branch-v2/add-branch-v2.component';
import { BranchProducerComponent } from './quote-workflow3/brokerage-module/branch-profile/branch-producer/branch-producer.component';
import { ImageLogoV2Component } from './quote-workflow3/components/image-logo-v2/image-logo-v2.component';
import { ModalCommissionConfirmationV2Component } from './quote-workflow3/components/modal-commission-confirmation-v2/modal-commission-confirmation-v2.component';
import { AddProducerComponent } from './quote-workflow3/brokerage-module/producer-profile/add-producer/add-producer.component';
import { ViewProducerV2Component } from './quote-workflow3/brokerage-module/producer-profile/view-producer-v2/view-producer-v2.component';
import { ReferralBadgeComponent } from './quote-workflow3/components/referral-badge/referral-badge.component';
import { RegeneratePolicyDocumentPopupComponent } from './components/regenerate-policy-document-popup/regenerate-policy-document-popup.component';
import { ChangeProducerPopupComponent } from './components/change-producer-popup/change-producer-popup.component';
import { ProductCardComponent } from './quote-workflow3/components/product-card/product-card.component';
import { ModalSubmissionSubmittedWorkFlow3Component } from './quote-workflow3/components/modal-submission-submitted/modal-submission-submitted.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { MaintenanceBannerComponent } from './maintenance-banner/maintenance-banner.component';
import { ModalEmailQuoteV2Component } from './quote-workflow3/components/modal-email-quote-v2/modal-email-quote-v2.component';
import { DocTemplateSelectionV2Component } from './quote-workflow3/components/doc-template-selection-v2/doc-template-selection-v2.component';
import { ModalSendPackageWorkflow3Component } from './quote-workflow3/components/modal-send-package-workflow3/modal-send-package-workflow3.component';
import { SlideoutPopupBaseComponent } from './quote-workflow3/components/slideout-popup-base/slideout-popup-base.component';
import { QuoteSlideoutV2Component } from './quote-workflow3/components/quote-slideout-v2/quote-slideout-v2.component';
import { SlideoutDocumentV2Component } from './quote-workflow3/components/slideout-document-v2/slideout-document-v2.component';
import { SlideoutHistoryV2Component } from './quote-workflow3/components/slideout-history-v2/slideout-history-v2.component';
import { PolicySlideoutV2Component } from './quote-workflow3/components/policy-slideout-v2/policy-slideout-v2.component';
import { PolicyLifecycleV2Component } from './quote-workflow3/policy-lifecycle-v2/policy-lifecycle-v2.component';
import { LifecycleHeaderComponent } from './quote-workflow3/components/lifecycle-header/lifecycle-header.component';
import { LifecycleInformationComponent } from './quote-workflow3/components/lifecycle-information/lifecycle-information.component';
import { QuoteLifecycleV2Component } from './quote-workflow3/quote-lifecycle-v2/quote-lifecycle-v2.component';
import { PLCTimelineComponent } from './quote-workflow3/components/plc-timeline/plc-timeline.component';
import { CheckboxV3Component } from './quote-workflow3/components/checkbox-v3/checkbox-v3.component';
import { PlcChangeSectionV2Component } from './quote-workflow3/components/plc-change-section-v2/plc-change-section-v2.component';
import { ChangeProducerPopupV2Component } from './components/change-producer-popup-v2/change-producer-popup-v2.component';
import { RegeneratePolicyDocumentPopupV2Component } from './quote-workflow3/components/regenerate-policy-document-popup-v2/regenerate-policy-document-popup-v2.component';
import { RestrictedIndustryWarningComponent } from './quote-workflow3/components/restricted-industry-warning/restricted-industry-warning.component';
import { PagefooterTimestampV2Component } from './quote-workflow3/components/pagefooter-timestamp-v2/pagefooter-timestamp.component';
import { PolicyNewPeriodPopupV2Component } from './components/policy-new-period-popup-v2/policy-new-period-popup-v2.component';
import { WindowReferenceService } from './services/window-reference.service';
import { DataLayerService } from './services/data-layer.service';
import { SearchV2Component } from './components/search-v2/search-v2.component';
import { DashboardHomeV2Component } from './dashboard/dashboard-home-v2/dashboard-home-v2.component';
import { ScrollPaginationTableComponent } from './dashboard/scroll-pagination-table/scroll-pagination-table.component';
import { StatusBadgeV2Component } from './dashboard/status-badge-v2/status-badge-v2.component';
import { InfoTooltipV2Component } from './dashboard/info-tooltip-v2/info-tooltip-v2.component';
import { RippleAnimationComponent } from './components/ripple-animation/ripple-animation.component';
import { BranchTeamsComponent } from './quote-workflow3/brokerage-module/branch-profile/branch-teams/branch-teams.component';
import { BranchTeamsTableComponent } from './quote-workflow3/brokerage-module/branch-profile/branch-teams-table/branch-teams-table.component';
import { BadgeListComponent } from './quote-workflow3/badge-list/badge-list.component';
import { DashboardFilterComponent } from './dashboard/dashboard-filter/dashboard-filter.component';

@NgModule({
  declarations: [
    RegeneratePolicyDocumentPopupV2Component,
    SlideoutPopupBaseComponent,
    QuoteSlideoutV2Component,
    PolicySlideoutV2Component,
    NewSubmissionQuote4FlowComponent,
    PolicyConfigurationWF3Component,
    InsuredWF3Component,
    InsuredDirectoryWF3Component,
    PolicyConfigurationComponent, //quote4-flow
    FooterWrapperComponent, //quote4-flow
    QuoteCalculatorAndOptionsComponent, //quote4-flow
    NewSystemUserComponent,
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    CreatePasswordComponent,
    AuthComponent,
    CheckInboxComponent,
    MfaComponent,
    DashboardHomeComponent,
    SideMenuComponent,
    NavigationComponent,
    SearchInputComponent,
    NotificationBadgeComponent,
    InfoTileComponent,
    CardComponent,
    SearchResultComponent,
    DashboardComponent,
    BrokerageDirectoryComponent,
    NewBranchComponent,
    QuoteComponent,
    NewSubmissionComponent,
    ProductSelectionComponent,
    LocationDetailsFormComponent,
    MainContactComponent,
    AbbreviateNumberPipe,
    ConnectFormDirective,
    ProductFormComponent,
    BranchDetailsComponent,
    ViewBranchComponent,
    NewBrokerageComponent,
    CommissionDetailsComponent,
    AddCommissionComponent,
    NewProducerComponent,
    QuoteCalculatorComponent,
    ViewBrokerageComponent,
    QuoteOptionComponent,
    NewProducerComponent,
    UpdateCommissionComponent,
    ActionsCommissionComponent,
    ViewCommissionComponent,
    ContainerCommissionDetailComponent,
    QuoteCompareComponent,
    ModalSendComponent,
    SpinnerLoadComponent,
    DocumentPreviewComponent,
    SafePipe,
    ProductSummaryComponent,
    BrokerageDetailsComponent,
    BranchTableComponent,
    ImageLogoComponent,
    QuoteSummaryComponent,
    BindPolicyButtonComponent,
    PolicyGridComponent,
    TimestampComponent,
    ProducerProfileComponent,
    FilterComponent,
    BindPolicyButtonComponent,
    SecurityRoleComponent,
    QuotesGridComponent,
    BoxxUserDirectoryComponent,
    SystemUserDirectoryComponent,
    PolicySlideoutComponent,
    MarketReservationComponent,
    NewBoxxUserComponent,
    CognitoComponent,
    Cognito4FlowComponent,
    NotesComponent,
    NewBoxxUserComponent,
    QuoteLifecycleComponent,
    LifecycleDetailsComponent,
    NewSystemBoxxUserComponent,
    NewSystemInsuredUserComponent,
    NewSystemProducerUserComponent,
    CardSkeletonComponent,
    QuoteGridFilterComponent,
    PolicyGridFilterComponent,
    UnauthorizedComponent,
    LogoutPopupComponent,
    LogoutComponent,
    NewPermissionSystemUserComponent,
    SearchableListComponent,
    PermissionsComponent,
    QuoteActionPopupComponent,
    PolicyCancelPopupComponent,
    PolicyLifecycleComponent,
    PolicyReinstatePopupComponent,
    PolicyNewPeriodPopupComponent,
    PolicyNewPeriodPopupV2Component,
    NewRoleComponent,
    UserProfileComponent,
    RoleDetailsComponent,
    NewPermissionComponent,
    EditProducerComponent,
    EditRoleComponent,
    ComingSoonComponent,
    EditSystemUserComponent,
    EditSystemBoxxUserComponent,
    EditSystemInsuredUserComponent,
    EditSystemProducerUserComponent,
    EditBoxxUserComponent,
    EditPermissionComponent,
    CoverageLimitComponent,
    MinMaxRangeComponent,
    PolicyGridSlideoutComponent,
    RestrictiveIndustriesComponent,
    ViewReferralReasonsComponent,
    InsuredComponent,
    InsuredDirectoryComponent,
    InsuredFormComponent,
    CompanyInsuredFormComponent,
    ProductDetailAccordionComponent,
    CurrencyFormatPipe,
    ModalCommissionConfirmationComponent,
    IndividualInsuredFormComponent,
    SubmissionRecalculateConfComponent,
    ModalSublimitSlideOutComponent,
    ScrollableTableComponent,
    QuoteSlideoutComponent,
    ReloadModalComponent,
    DocTemplateSelectionPopupComponent,
    ModalSanctionComponent,
    Quote4FlowHeaderComponent,
    ProgressBarComponent,
    Quote4FlowComponent,
    TruncateTextPipe,
    ProductInfoComponent,
    QuoteOptionsComponent,
    DropdownButtonComponent,
    ModalReasonsPopupComponent,
    Cognito7FlowComponent,
    ImageDocPreviewComponent,
    DeleteConfirmationPopupComponent,
    SlideoutDocumentComponent,
    SlideoutDocumentV2Component,
    SlideoutHistoryV2Component,
    DocumentActionPopupComponent,
    RestrictedIndustryOrSanctionPopupComponent,
    QuoteWorkflow3Component,
    SubmissionWorkflow3Component,
    ModalSubmissionRiskAnalysisComponent,
    ModalSubmissionRiskAnalysisQuestionsComponent,
    ModalSanctionsRiskQuestionsComponent,
    ModalInsuredStateUpdateComponent,
    ModalExistingQuoteWorkFlow3Component,
    ModalMarketReservationWorkFlow3Component,
    ModalPremiumRecalculateWorkFlow3Component,
    InfoBannerV2Component,
    SideMenuWorkflow3Component,
    ModalExitQuotingProcessComponent,
    ModalLogoutComponent,
    RiskAnalysisWorkflow3Component,
    PopoverV2Component,
    QuoteActionPopupV2Component,
    DocTemplateSelectionComponent,
    ModalRiskAnalysisComponent,
    ModalInsuredStateChangeComponent,
    InfoBannerV2Component,
    ProducerInformationComponent,
    QuoteWorkflow3Component,
    SubmissionWorkflow3Component,
    BrokerCommissionv2ModalComponent,
    BrokerageRedirectModalComponent,
    QuoteSelectionComponent,
    ModalSendQuoteComponent,
    DropdownMenuButtonV2Component,
    SublimitSlideOutComponent,
    QuotingComponent,
    ScrollableTableV2Component,
    CheckboxV2Component,
    ReferralBadgeComponent,
    PolicyDirectoryComponent,
    IndividualInsuredFormWorkflow3Component,
    NotesV2Component,
    QuoteNotesV2Component,
    NavigationV2Component,
    BusinessInsuredFormWorkflow3Component,
    InsuredFormWorkflow3Component,
    ButtonGroupComponent,
    ModalSubjectivitiesComponent,
    DocumentPreviewV2Component,
    ModalEndorsementsComponent,
    ModalDeclarationsComponent,
    ModalWordingsComponent,
    QuotesGridV2Component,
    RevenueOrSanctionPopupComponent,
    ModalAlertComponent,
    SearchResultV2Component,
    DynamicFilterv2ModalComponent,
    BrokerageModuleComponent,
    BrokerageProfileComponent,
    BranchProfileComponent,
    ProducerComponent,
    CommissionRateWorkflow3Component,
    BrokerageDirectoryWorkflow3Component,
    CommissionTableWorkflow3Component,
    LocationDetailsFormV2Component,
    BrokerageDetailsV2Component,
    ViewBranchV2Component,
    AddBranchV2Component,
    BranchProducerComponent,
    ImageLogoV2Component,
    ModalCommissionConfirmationV2Component,
    AddProducerComponent,
    ViewProducerV2Component,
    RegeneratePolicyDocumentPopupComponent,
    ChangeProducerPopupComponent,
    ProductCardComponent,
    ModalSubmissionSubmittedWorkFlow3Component,
    MaintenanceComponent,
    MaintenanceBannerComponent,
    ModalEmailQuoteV2Component,
    DocTemplateSelectionV2Component,
    ModalSendPackageWorkflow3Component,
    PolicyLifecycleV2Component,
    QuoteLifecycleV2Component,
    LifecycleHeaderComponent,
    LifecycleInformationComponent,
    PlcEndorsementSectionV2Component,
    PLCTimelineComponent,
    ModalCommissionChangeWorkFlow3Component,
    CheckboxV3Component,
    PlcChangeSectionV2Component,
    ProductCardComponent,
    ChangeProducerPopupV2Component,
    ModalSubmissionSubmittedWorkFlow3Component,
    RestrictedIndustryWarningComponent,
    PagefooterTimestampV2Component,
    SearchV2Component,
    DashboardHomeV2Component,
    ScrollPaginationTableComponent,
    StatusBadgeV2Component,
    RippleAnimationComponent,
    InfoTooltipV2Component,
    BranchTeamsComponent,
    BranchTeamsTableComponent,
    BadgeListComponent,
    DashboardFilterComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BoxxdsModule,
    NgSelectModule,
    FormsModule,
    ImageCropperModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    ClipboardModule,
    BsDatepickerModule.forRoot(),
    NgxMaskModule.forRoot(),
    BrowserAnimationsModule,
    AvatarModule,
    NgxPaginationModule,
    PopoverModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot({}),
    StoreModule.forFeature('stores', featureReducers),
    EffectsModule.forRoot([
      RiskRegionEffects,
      InsuredEffects,
      CommissionEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
    }),
    NgxEditorModule,
    NgxExtendedPdfViewerModule,
    AngularEditorModule,
  ],
  providers: [
    BrokerageService,
    BrokerageProducerService,
    BrokerageBranchService,
    BrokerageDirectoryService,
    PolicyService,
    PolicyDashboardService,
    InsuredService,
    RoleService,
    RolePermissionService,
    SystemUserService,
    BoxxUserService,
    PolicyService,
    PolicyDashboardService,
    InsuredService,
    RoleService,
    RolePermissionService,
    SystemUserService,
    BoxxUserService,
    PolicyStatsService,
    BrokerageProducerProfileService,
    WindowReferenceService,
    DataLayerService,
    DatePipe,
    MapService,
    {
      provide: MAP_SERVICE_CONFIG,
      useValue: {
        googleMapsApiKey: `${environment.googleMapsApiKey}`,
        googleMapsCountry: `${environment.googleMapsCountry}`,
      },
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `${environment.baseHref}assets/i18n/`,
    '.json',
  );
}
