import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IBrokerageModel } from 'src/app/models/brokerage.model';
import { ILocation } from 'src/app/models/location';
import { regexHelpers } from 'src/app/utils/utils';

export const mapFormGroupToModel = (form: FormGroup): IBrokerageModel => {
  const { name, website, domains, parent, phoneNumber, email, logo } =
    form.value['brokerage'];
  const location: ILocation = form.value['location'];

  const brokerageModel: IBrokerageModel = {
    name,
    website,
    email,
    domain: domains,
    parentCompany: parent,
    telephone: phoneNumber,
    location: location.address === '' ? null : location,
    brokerageProfile: { logo: logo },
    brokerageCommissions: [],
  };

  return brokerageModel;
};

export const getFormGroup = (fb: FormBuilder): FormGroup => {
  const formBuiler = fb.group({
    brokerage: fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.pattern(regexHelpers.AlphaNumericSpace),
        ],
      ],
      website: [''],
      email: ['', [Validators.email, Validators.required]],
      domains: ['', [Validators.required]],
      phoneNumber: ['', [Validators.pattern(regexHelpers.PhoneNumber)]],
      parent: [''],
      logo: [''],
    }),
    location: fb.group({
      country: ['', Validators.required],
      province: ['', Validators.required],
      city: ['', Validators.required],
      address: ['', Validators.required],
      zipPostalCode: ['', Validators.required],
      addressLine2: [''],
    }),
  });

  return formBuiler;
};

export const checkRegexPatternMainWebsite = (value: string): boolean => {
  const regexPattern = regexHelpers.Website;
  return regexPattern.test(value);
};

export const checkRegexPatternMainEmail = (value: string): boolean => {
  const regexPattern = regexHelpers.MailId;
  return regexPattern.test(value);
};

export const checkRegexPatternMultidomain = (value: string): boolean => {
  const regexPattern = regexHelpers.MultiWebSite;
  return regexPattern.test(value);
};
