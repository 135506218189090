<section class="popup-container">
  <section class="popup">
    <section class="title">
      <div class="icon-popup-title-wrapper">
        <div class="icon-box-wrapper">
          <div class="icon-box">
            <div class="icon-container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M9 15.5H15M21 8.5H3M16 2.5V5.5M8 2.5V5.5M7.8 22.5H16.2C17.8802 22.5 18.7202 22.5 19.362 22.173C19.9265 21.8854 20.3854 21.4265 20.673 20.862C21 20.2202 21 19.3802 21 17.7V9.3C21 7.61984 21 6.77976 20.673 6.13803C20.3854 5.57354 19.9265 5.1146 19.362 4.82698C18.7202 4.5 17.8802 4.5 16.2 4.5H7.8C6.11984 4.5 5.27976 4.5 4.63803 4.82698C4.07354 5.1146 3.6146 5.57354 3.32698 6.13803C3 6.77976 3 7.61984 3 9.3V17.7C3 19.3802 3 20.2202 3.32698 20.862C3.6146 21.4265 4.07354 21.8854 4.63803 22.173C5.27976 22.5 6.11984 22.5 7.8 22.5Z"
                  stroke="#D46B00"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div class="rings-wrapper">
            <div class="rings">
              <span style="--i: 1"></span>
              <span style="--i: 2"></span>
              <span style="--i: 3"></span>
              <span style="--i: 4"></span>
              <span style="--i: 5"></span>
              <span style="--i: 6"></span>
            </div>
          </div>
        </div>
        <h1>
          {{ "common.startNewPeriod" | translate }}
        </h1>
      </div>
      <boxxds-button-v2
        [isDisabled]="false"
        [size]="'lg'"
        [btnType]="'close'"
        (handleClick)="closeHandler()"
      >
      </boxxds-button-v2>
    </section>
    <section class="popup-body">
      <ng-container *ngIf="showSpinner">
        <div class="body-spinner">
          <boxxds-spinner
            [containerStyle]="{
              width: '112px',
              height: '112px',
              '--b': '15px'
            }"
          ></boxxds-spinner>
        </div>
      </ng-container>
      <div
        class="details-wrapper"
        [ngStyle]="{ opacity: showSpinner ? '0' : '1' }"
      >
        <div class="details-banner">
          <div class="details-container">
            <p class="details-head">
              {{ "common.policy" | translate }}
            </p>
            <p class="details" [title]="details?.['id'] ?? ''">
              {{ details?.["id"] ?? "" }}
            </p>
          </div>
          <div class="details-container">
            <p class="details-head">
              {{ "common.insured" | translate }}
            </p>
            <p class="details" [title]="details?.['insured'] ?? ''">
              {{ details?.["insured"] ?? "" }}
            </p>
          </div>
          <div class="details-container">
            <p class="details-head">
              {{ "common.brokerage" | translate }}
            </p>
            <p
              class="details"
              [title]="
                (details?.['brokerage'] ?? '') +
                ', ' +
                (details?.['branch'] ?? '')
              "
            >
              {{ details?.["brokerage"] ?? "" }},
              {{ details?.["branch"] ?? "" }}
            </p>
          </div>
          <div class="details-container">
            <p class="details-head">
              {{ "common.policyPeriod" | translate }}
            </p>
            <p class="details" [title]="details?.period ?? ''">
              {{ details?.period ?? "" }}
            </p>
          </div>
        </div>
        <form
          [formGroup]="newPeriodPolicyForm"
          (ngSubmit)="handleSubmit($event)"
        >
          <div class="update-section">
            <p class="body-head">
              {{ "common.pleaseSelectStartDateForNewPeriod" | translate }}
            </p>
            <div class="input-container">
              <boxxds-datepicker-v2
                [placeholder]="'common.selectStartDateOfNewPeriod' | translate"
                [dateFormat]="shortDateFormat"
                [bsValue]="newPeriodPolicyForm.value['date']"
                [minDate]="minDate"
                (bsValueChange)="handleDate($event)"
                [inputOuterStyle]="{ width: '100%' }"
                [inputStyle]="{
                  height: 'auto',
                  padding: '10px 14px 10px 40px'
                }"
              >
              </boxxds-datepicker-v2>
            </div>
          </div>
          <div class="btn-container">
            <boxxds-button-v2
              class="change-button"
              [buttonText]="'common.confirm' | translate"
              [isDisabled]="buttonDisabled"
              [size]="'lg'"
              [btnClass]="'primary'"
              [btnType]="'default'"
              [type]="'submit'"
              [btnStyle]="{ width: '219px' }"
            >
            </boxxds-button-v2>
          </div>
        </form>
      </div>
    </section>
  </section>
</section>
