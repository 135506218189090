import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { PolicyDashboardService } from 'src/app/services/policy-dashboard.service';
import { Pagination } from 'src/app/entities/boxx-response';
import {
  convertDate,
  formatDateMoment,
  getDateString,
  getFormattedDateTime,
  getFormattedPolicyPeriod,
} from 'src/app/utils/formatDate';
import {
  QuoteMapping,
  QuoteStatus,
  isStatusDisabled,
  mapQuote,
} from 'src/app/constants/quoteStatus';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import {
  makeSorting,
  formatAmountWithCurrency,
  excelFileDownload,
  getAlertHead,
  getErrorMessage,
  getStatusMappingForStyling,
} from 'src/app/utils/utils';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { PolicyPeriodService } from 'src/app/services/policy-period.service';
import { firstValueFrom, take } from 'rxjs';
import { LocationService } from 'src/app/services/location.service';
import { RiskQuestionService } from 'src/app/services/risk-question.service';
import { PolicyRiskTrxService } from 'src/app/services/policy-risk-trx.service';
import { PolicyDashboard } from 'src/app/entities/policy-dashboard';
import { getDashboardPath } from 'src/app/store/create-quote/create-quote.selector';
import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import {
  setQuoteStatus,
  setTransactionLvlQuoteStatus,
} from 'src/app/store/lifecycle/lifecycle.action';
import { AlertService } from 'src/app//services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { NewQuoteService } from 'src/app/services/new-quote.service';
import { DomainsService } from 'src/app/services/domains.service';
import {
  StatusMapping,
  getPolicyStatusTypeV2,
} from 'src/app/constants/policyStatus';
import { BrokerageDirectoryService } from 'src/app/services/brokerage-directory.service';
import {
  isBranch,
  isBroker,
  isProducer,
} from 'src/app/constants/broker-constant';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import { USER_ROLES } from 'src/app/constants/security/systemUserType';
import { PolicyLifecycleService } from 'src/app/services/policy-lifecycle-service';
import { SUB_DOMAIN_CODE_REASONTYPE_PENDINGINFO } from 'src/app/constants/quote-constant';

@Component({
  selector: 'app-quotes-grid-v2',
  templateUrl: './quotes-grid-v2.component.html',
  styleUrls: ['./quotes-grid-v2.component.less'],
})
export class QuotesGridV2Component implements OnInit {
  statusMappingToType = getStatusMappingForStyling();

  subNavData;
  tableData = [];
  tableHeaders = [
    {
      'Quote ID': '',
      Status: '',
      'Insured name': '',
      'Industry class': '',
      Revenue: '',
      Risks: '',
      'Created by/Date': '',
    },
  ];
  policyPeriodIds = [];
  pdtOptions = [];
  hasError: boolean = false;
  errorMessage: string = '';
  hasSuccess: boolean = false;
  successMessage: string = '';
  showTblSpinner: boolean = false;
  showBodySpinner: boolean = false;

  colQuoteHeader = {
    'Quotes ID': [{ key: 'policy.id', order: '' }],
    Status: [{ key: 'quoteStatus', order: '' }],
    'Effective dates': [{ key: 'effectiveDt', order: '' }],
    'Insured name': [
      { key: 'policy.insured.companyName', order: '' },
      { key: 'policy.insured.firstName', order: '' },
      { key: 'policy.insured.lastName', order: '' },
    ],
  };
  colQuoteHeaderIndividual = {
    'Quotes ID': [{ key: 'policy.id', order: '' }],
    Status: [{ key: 'quoteStatus', order: '' }],
    'Effective dates': [{ key: 'effectiveDt', order: '' }],
    'Insured name': [
      { key: 'policy.insured.companyName', order: '' },
      { key: 'policy.insured.firstName', order: '' },
      { key: 'policy.insured.lastName', order: '' },
    ],
  };

  statusBtnActions = [
    { key: 'Trigger refferal', value: 'Trigger refferal' },
    { key: 'Duplicate quote', value: 'Duplicate quote' },
  ];
  showFilter = false;
  isInsuredTypeCompany = true;
  totalDataCount = 0;
  pagination: Pagination;
  products;
  sortingKeys = 'followUp:desc, policyId:desc';
  filterForm: FormGroup;
  addSortTo: string[] = [
    'Quotes ID',
    'Effective dates',
    'Status',
    'Insured name',
  ];
  sortBy: string = '';
  pageNumber: number = 1;
  userID: number;
  underwriterNameNav: string = '';
  insuredData = {};

  quotesList: PolicyDashboard[];
  statusOptions = [];

  notificationAlert: {
    show?: boolean;
    bodyText?: string;
    type?: string;
    headerText?: string;
  } = { show: false };
  currency = '';
  insuredTimezone;
  quoteFlowSteps;
  quoteFlowUrl = '';

  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  shortDateFormat: string = '';
  longDateFormat: string = '';
  insuredSearchFilterOn = false;
  searchQuery: any;
  underWriterOptions = [];
  brokerageBranchProducerOptions = [];
  premiumOptions = [];
  filteredOptions = [];
  filterSelectedOptions: any;
  filterInputOptions = [];

  brokerageBranchProdOptions = [];
  brokerageDirectorySearch = '';
  brokerageDirectorCurrentPage = 0;
  brokerageDirectoryTotalRecords = 0;
  underwriterSearch = '';
  underwriterCurrentPage = 0;
  underwriterTotalRecords = 0;
  underwriters = [];
  underwritersOptions = [];
  showViewButton: boolean = true;
  isRestrictedRoleSubmission: boolean = false;
  startQuoteBtnTxt: string = '';
  showReasonIcon: boolean = false;
  showActionButtons: boolean = false;
  showIndicatedButton: boolean = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private policyDashboardService: PolicyDashboardService,
    private riskRegionService: RiskRegionService,
    private policyPeriodService: PolicyPeriodService,
    public locationService: LocationService,
    public riskQuestionService: RiskQuestionService,
    private store: Store,
    private localStorageService: LocalStorageService,
    private policyRiskTrxService: PolicyRiskTrxService,
    private renderer: Renderer2,
    private alertService: AlertService,
    private translateService: TranslateService,
    private newQuoteService: NewQuoteService,
    private domainsService: DomainsService,
    public brokerageDirectoryService: BrokerageDirectoryService,
    public boxxUserService: BoxxUserService,
    private policyLifecycleService: PolicyLifecycleService,
  ) {
    this.filterForm = this.fb.group({
      effectiveDt: this.fb.group({
        from: [''],
        to: [''],
      }),
      createdDt: this.fb.group({
        from: [''],
        to: [''],
      }),
      brokerageBranchProd: [[]],
      underwriter: [[]],
      status: [[]],
      risks: [[]],
      insuredName: [''],
      premiumRange: this.fb.group({
        from: [''],
        to: [''],
      }),
    });
  }

  async ngOnInit() {
    localStorage.removeItem('timezone');
    this.localStorageService.removeTimeZone();
    this.startQuoteBtnTxt = this.translateService.instant(
      'common.startNewQuote',
    );

    this.newQuoteService.setIsNavigatedFromPolicyConfig(false);
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
      this.isRestrictedRoleSubmission = data.isRestrictedRoleSubmission;
    });
    this.store.dispatch(new setQuoteStatus(''));
    this.store.dispatch(new setTransactionLvlQuoteStatus(''));
    this.quoteFlowSteps = this.localStorageService.getQuoteFlowSteps();
    if (this.quoteFlowSteps === '7') {
      this.quoteFlowUrl = 'quote';
    } else if (this.quoteFlowSteps === '4') {
      this.quoteFlowUrl = 'quote4flow';
    } else {
      this.quoteFlowUrl = 'workflow3';
    }

    this.subNavData = [
      { name: 'View all', active: true, value: 'All' },
      { name: 'New business', active: false, value: 'Submission' },
      { name: 'Declined', active: false, value: 'Declined' },
      { name: 'Closed', active: false, value: 'Closed' },
    ];
    this.userID = this.localStorageService.getBoxxUserId();
    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));
    const sideMenuNavigation = this.newQuoteService
      ?.getSideMenu()
      ?.toLowerCase();
    if (sideMenuNavigation !== '') {
      this.newQuoteService.clearSideMenu();
      switch (sideMenuNavigation) {
        case 'new business':
          this.handleNav(1);
          break;
        case 'declined':
          this.handleNav(2);
          break;
        case 'closed':
          this.handleNav(3);
          break;
        default:
          break;
      }
    }
    this.store
      .select(getDashboardPath)
      .pipe(take(1))
      .subscribe((item) => {
        if (item?.pathOptions?.path) {
          if (item.pathOptions?.path == 'open quotes') {
            this.filterForm
              .get('underwriter')
              .setValue([
                { id: this.userID, name: item.pathOptions?.underwriter },
              ]);
            this.handleNav(1);
            this.store.dispatch(
              new CreateQuoteAction.setDashboardPath({
                path: '',
                underwriter: '',
              }),
            );
          } else if (item.pathOptions?.path == 'new referrals') {
            this.filterForm.get('status').setValue(['Referral']);
            this.filterForm
              .get('underwriter')
              .setValue([
                { id: this.userID, name: item.pathOptions?.underwriter },
              ]);
            this.loadResults(1, 10, this.sortingKeys);
            this.store.dispatch(
              new CreateQuoteAction.setDashboardPath({
                path: '',
                underwriter: '',
              }),
            );
          }
        } else {
          this.store.dispatch(new CreateQuoteAction.ResetState());
          this.loadResults(1, 10, this.sortingKeys);
        }
      });

    this.riskRegionService.getAllRiskByUser(this.userID).subscribe((data) => {
      this.products = data.data.reduce((accumulator, value) => {
        return { ...accumulator, [value.name]: value };
      }, {});

      this.pdtOptions = data.data?.map((dataObj) => ({
        key: dataObj.name,
        value: dataObj.name,
        name: dataObj.name,
        disabled: false,
        id: dataObj.id,
      }));
    });

    this.policyPeriodService.alertData$.pipe(take(1)).subscribe((alertData) => {
      if (alertData) {
        this.policyPeriodService.setAlertData('');
        this.alertService.addAlert(alertData);
      }
    });

    if (this.isRestrictedRoleSubmission === true) {
      this.showViewButton = false;
      this.startQuoteBtnTxt = this.translateService.instant(
        'common.startNewSubmission',
      );
    }

    this.populatePremiumOptions();
    await this.fetchQuoteStatusLists();
  }

  async onInsuredSearch(event) {
    const value = event.target.value;
    this.searchQuery = event.target.value;
    this.filteredOptions = [];
    this.filterSelectedOptions = undefined;
    if (event.key == 'Enter' && value.length >= 3 && value.length <= 40) {
      this.showBodySpinner = true;
      this.insuredSearchFilterOn = true;
      await this.loadResults(1, 10, this.sortingKeys);
      this.showBodySpinner = false;
    } else if (
      value?.length === 0 ||
      (event.key == 'Enter' && value?.length < 3)
    ) {
      this.insuredSearchFilterOn = false;
      this.loadResults(1, 10, this.sortingKeys);
    }
  }

  getFilteredValues() {
    let filteredOptions = [];
    let filterText = '';

    if (this.filterSelectedOptions) {
      if (this.filterSelectedOptions.insuredName) {
        filterText = this.filterSelectedOptions?.insuredName;
        filteredOptions.push({ key: 'insuredName', value: filterText });
      }
      if (this.filterSelectedOptions.status.length > 0) {
        let status = this.filterSelectedOptions.status;
        status.forEach((b) => {
          if (b.fromNav) {
            return;
          }
          filteredOptions.push({ key: 'status', value: b.name });
        });
      }
      if (this.filterSelectedOptions?.brokerageBranchProd) {
        const brokerVal = this.filterSelectedOptions?.brokerageBranchProd;
        if (brokerVal?.length > 0) {
          brokerVal.forEach((b) => {
            filteredOptions.push({ key: 'brokerageBranchProd', value: b.name });
          });
        }
        filterText = this.filterSelectedOptions.insuredType;
      }
      if (this.filterSelectedOptions.underwriter) {
        const underWriter = this.filterSelectedOptions.underwriter;
        if (underWriter?.length > 0) {
          underWriter.forEach((uw) => {
            filteredOptions.push({ key: 'underwriter', value: uw.name });
          });
        }
      }
      if (this.filterSelectedOptions.risks) {
        const risks = this.filterSelectedOptions.risks;
        if (risks?.length > 0) {
          risks.forEach((uw) => {
            filteredOptions.push({ key: 'risks', value: uw.name });
          });
        }
      }
      if (this.filterSelectedOptions.createdDt) {
        const createDt = this.filterSelectedOptions.createdDt;
        let createRange = '';
        if (createDt.from && createDt.to) {
          const formattedFromDate = formatDateMoment(
            createDt.from,
            this.shortDateFormat,
          );
          const formattedToDate = formatDateMoment(
            createDt.to,
            this.shortDateFormat,
          );
          createRange = `${formattedFromDate} - ${formattedToDate}`;
          filteredOptions.push({ key: 'createdDt', value: createRange });
        }
      }
      if (this.filterSelectedOptions.effectiveDt) {
        const effectiveDt = this.filterSelectedOptions.effectiveDt;
        let createRange = '';
        if (effectiveDt.from && effectiveDt.to) {
          const formattedFromDate = formatDateMoment(
            effectiveDt.from,
            this.shortDateFormat,
          );
          const formattedToDate = formatDateMoment(
            effectiveDt.to,
            this.shortDateFormat,
          );
          createRange = `${formattedFromDate} - ${formattedToDate}`;
          filteredOptions.push({ key: 'effectiveDt', value: createRange });
        }
      }
      if (this.filterSelectedOptions.premiumRange) {
        const premiumRange = this.filterSelectedOptions.premiumRange;
        let createRange = '';
        if (
          premiumRange.from !== null &&
          premiumRange.from !== undefined &&
          premiumRange.from !== '' &&
          premiumRange.to !== null &&
          premiumRange.to !== undefined &&
          premiumRange.to !== ''
        ) {
          const formattedFrom =
            this.premiumOptions.find((x) => x.id == premiumRange.from)?.value ??
            premiumRange.from;
          const formattedTo =
            this.premiumOptions.find((x) => x.id == premiumRange.to)?.value ??
            premiumRange.to;
          createRange = `${formatAmountWithCurrency(
            formattedFrom,
            this.currency,
          )} - ${formatAmountWithCurrency(formattedTo, this.currency)}`;
          filteredOptions.push({ key: 'premiumRange', value: createRange });
        }
      }
      this.filteredOptions = filteredOptions;
    }

    return filteredOptions;
  }

  handleFilterOptnRemove(option) {
    if (this.filterSelectedOptions.hasOwnProperty(option.key)) {
      switch (option.key) {
        case 'underwriter':
          let ind = this.filterSelectedOptions[option.key].findIndex(
            (x) => x.name === option.value,
          );
          if (ind >= 0) {
            this.filterSelectedOptions[option.key].splice(ind, 1);
          }
          break;
        case 'brokerageBranchProd':
          let ind1 = this.filterSelectedOptions[option.key].findIndex(
            (x) => x.name === option.value,
          );
          if (ind1 >= 0) {
            this.filterSelectedOptions[option.key].splice(ind1, 1);
          }
          break;
        case 'premiumRange':
          this.filterSelectedOptions[option.key] = { from: '', to: '' };
          break;
        case 'risks':
          let ind2 = this.filterSelectedOptions[option.key].findIndex(
            (x) => x.name === option.value,
          );
          if (ind2 >= 0) {
            this.filterSelectedOptions[option.key].splice(ind2, 1);
          }
          break;
        case 'status':
          let ind3 = this.filterSelectedOptions[option.key].findIndex(
            (x) => x.name === option.value,
          );
          if (ind3 >= 0) {
            this.filterSelectedOptions[option.key].splice(ind3, 1);
          }
          break;
        case 'createdDt':
          this.filterSelectedOptions[option.key] = { from: '', to: '' };
          break;
        case 'insuredName':
          this.filterSelectedOptions[option.key] = '';
          this.filterForm.get('insuredName').setValue('');
          break;
        case 'effectiveDt':
          this.filterSelectedOptions[option.key] = { from: '', to: '' };
          break;
        default:
      }
    }

    this.loadResults(1, 10, this.sortingKeys);
    this.getFilteredValues();

    // this.handleFiltering(undefined);
  }

  handleStartNewQuote() {
    this.store.dispatch(new CreateQuoteAction.ResetState());
    if (this.quoteFlowSteps === 'workflow3') {
      this.newQuoteService.clearInsuredId();
      this.router.navigate(['../../workflow3'], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
      return;
    } else {
      this.router.navigate([`../${this.quoteFlowUrl}/new`], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
    }
  }

  async handleNav(index: number) {
    await this.fetchQuoteStatusLists();
    const currentActiveIdx = this.subNavData.findIndex(
      (navObj: { name: string; active: boolean; value: string }) =>
        navObj.active,
    );

    if (currentActiveIdx !== index) {
      let currentStatusFilters = this.filterForm.value['status'] ?? [];
      if (currentStatusFilters.includes(this.subNavData[index].value)) {
        this.filterForm.controls['status'].setValue([
          `${this.subNavData[index].value}`,
        ]);
      } else {
        this.filterForm.controls['status'].setValue([]);
      }
      this.subNavData[currentActiveIdx].active = false;
      this.subNavData[index].active = true;
      await this.populateFilterOptions();
      let statusFilter = this.filterInputOptions.find(
        (item) => item.value === 'status',
      );

      if (index != 0) {
        if (!this.filterSelectedOptions) {
          this.filterSelectedOptions = this.filterForm.value;
        }
        this.filterSelectedOptions.status = [
          {
            fromNav: true,
            ...this.statusOptions.find(
              (x) =>
                x.value.toLowerCase() ===
                this.subNavData[index].value.toLowerCase(),
            ),
          },
        ];

        statusFilter.isDefault = true;
        statusFilter.isRequired = true;
      } else {
        let ind = this.filterSelectedOptions.status.findIndex((x) => x.fromNav);
        if (ind >= 0) {
          this.filterSelectedOptions.status.splice(ind, 1);
        }
        statusFilter.isDefault = false;
        statusFilter.isRequired = false;
      }
      this.loadResults(1, 10, this.sortingKeys);
    }
  }

  loadResults(pageNumber, limit, sort = '') {
    this.showTblSpinner = true;
    this.tableData = this.tableHeaders;

    const currentActiveIdx = this.subNavData.findIndex(
      (navObj) => navObj.active,
    );
    let insuredType = this.getInsuredType();
    const quoteStatus = mapQuote(this.subNavData[currentActiveIdx].name);

    let filter = this.makeFilter();
    if (quoteStatus) {
      if (filter.quoteStatus) {
        filter.quoteStatus += ',' + quoteStatus;
      } else {
        filter = Object.assign(filter, { quoteStatus: quoteStatus });
      }
    }
    if (filter.trxDays) {
      if (filter.quoteStatus) {
        let temp = filter.quoteStatus
          .split(',')
          .findIndex((x) => x == mapQuote('Quoted'));
        if (temp == -1) {
          filter.quoteStatus += ',' + mapQuote('Quoted');
        }
      } else {
        filter = Object.assign(filter, { quoteStatus: mapQuote('Quoted') });
      }
    }
    this.policyPeriodIds = [];
    this.pageNumber = pageNumber;
    const args = {
      insuredType,
      filter,
      page: pageNumber,
      limit,
      sort,
    };
    this.policyDashboardService.GetAllQuoteByFilter(args).subscribe(
      (data) => {
        this.showTblSpinner = false;
        this.quotesList = [...data.data];
        const tableData = data.data?.map((dataObj) => {
          this.insuredTimezone =
            dataObj.policy.insured.insuredLocation.timeZoneCode ??
            'America/New_York';
          this.policyPeriodIds.push(dataObj.policyPeriod.id);
          const filterdVal = this.formatPolicyRiskList(
            dataObj.policyRiskTrxes.policyRisk,
          );
          const status =
            StatusMapping[
              dataObj.policyRiskTrxes?.quoteStatus?.description.toLowerCase()
            ] ?? '';

          let tableRowData = {
            'Quote ID': String(dataObj.policy.pkgPolicyNumber),
            Status: status,
            'Insured name': dataObj.policy.insured.companyName
              ? dataObj.policy.insured.companyName
              : dataObj.policy.insured.firstName +
                ' ' +
                dataObj.policy.insured.lastName,
            'Industry class':
              dataObj?.policy?.insured?.industryClass?.name ?? '',
            Revenue: formatAmountWithCurrency(
              dataObj.policy.insured.revenue,
              this.currency,
            ),
            Risks: filterdVal.products,
            'Created by/Date': `${dataObj?.policyPeriod
              ?.createdBy}<br>${getFormattedDateTime(
              dataObj?.policyPeriod?.createdDt,
              this.shortDateFormat,
            )}`,
            showStar: !!dataObj.policyRiskTrxes.followUp,
            showEllipsis:
              status == QuoteMapping['indicated'] ||
              status == QuoteMapping['quoted'],
          };

          let activeTab = this.subNavData.find((x) => x.active);
          if (['Declined', 'Closed'].includes(activeTab.value)) {
            delete tableRowData['Status'];
            let reasons: string[] = [];
            if (dataObj?.policyRiskTrxes?.reason) {
              reasons.push(dataObj.policyRiskTrxes.reason);
            }
            tableRowData['Reasons'] = reasons;
            this.showReasonIcon = true;
          } else {
            delete tableRowData['Reasons'];
            this.showReasonIcon = false;
          }

          return tableRowData;
        });

        this.tableData = tableData.length == 0 ? this.tableHeaders : tableData;
        this.totalDataCount = data.pagination.totalRecords;
        this.pagination = data.pagination;
        this.showTblSpinner = false;
      },
      (error) => {
        this.showTblSpinner = false;
      },
    );
  }

  pageChangeHandler(pageNumber) {
    if (pageNumber > 0) {
      this.loadResults(pageNumber, 10, this.sortingKeys);
    }
  }

  handleTableColSortUp(e) {
    let order = 'asc';
    if (this.isInsuredTypeCompany) {
      this.sortingKeys = makeSorting(e, this.colQuoteHeader, order);
      this.sortingKeys = `${this.sortingKeys},id:asc`;
    } else {
      this.sortingKeys = makeSorting(e, this.colQuoteHeaderIndividual, order);
      this.sortingKeys = `${this.sortingKeys},id:asc`;
    }
    this.loadResults(this.pageNumber, 10, this.sortingKeys);
  }

  handleTableColSortDown(e) {
    let order = 'desc';

    if (this.isInsuredTypeCompany) {
      this.sortingKeys = makeSorting(e, this.colQuoteHeader, order);
      this.sortingKeys = `${this.sortingKeys},id:asc`;
    } else {
      this.sortingKeys = makeSorting(e, this.colQuoteHeaderIndividual, order);
      this.sortingKeys = `${this.sortingKeys},id:asc`;
    }
    this.loadResults(this.pageNumber, 10, this.sortingKeys);
  }

  removeColSort() {
    this.sortingKeys = 'followUp:desc, policyId:desc';
    this.loadResults(this.pageNumber, 10, this.sortingKeys);
  }

  starClickHandler(index) {
    this.alertService.clearAlerts(-1);
    const policyRiskTrxes = { ...this.quotesList[index].policyRiskTrxes };
    const followUp = { followUp: policyRiskTrxes.followUp ? 0 : 1 };
    this.policyRiskTrxService
      .UpdateFollowUpByPolicyRiskTrxId(
        this.userID,
        policyRiskTrxes.id,
        followUp,
      )
      .subscribe({
        next: (_) => {
          this.tableData[index].showStar = !!!policyRiskTrxes.followUp;
          this.quotesList[index].policyRiskTrxes.followUp =
            policyRiskTrxes.followUp ? 0 : 1;
          const alertData = {
            show: true,
            type: 'success',
            headerText: 'success!',
            bodyText: this.translateService.instant('policy.FollowUpSuccess'),
          };
          this.alertService.addAlert(alertData);
          this.loadResults(1, 10, this.sortingKeys);
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            const alertData = {
              show: true,
              type: 'error',
              headerText: 'error!',
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
          }
        },
      });
  }
  ellipsisClickHandler(index) {}

  handleFilter() {
    this.populateFilterOptions();
    this.createFilterFormGroup();
    this.showFilter = true;
    this.renderer.addClass(document.body, 'no-scroll');
  }
  filterSelectedHasValue() {
    let flag = 0;
    if (this.filterSelectedOptions) {
      if (this.filterSelectedOptions.brokerageBranchProd) {
        flag +=
          this.filterSelectedOptions.brokerageBranchProd.length > 0 ? 1 : 0;
      }
      if (this.filterSelectedOptions.risks) {
        flag += this.filterSelectedOptions.risks.length > 0 ? 1 : 0;
      }
      if (this.filterSelectedOptions.status) {
        flag += this.filterSelectedOptions.status.length > 0 ? 1 : 0;
      }
      if (this.filterSelectedOptions.underwriter) {
        flag += this.filterSelectedOptions.underwriter.length > 0 ? 1 : 0;
      }
      if (this.filterSelectedOptions.insuredName) {
        flag += this.filterSelectedOptions.insuredName !== '' ? 1 : 0;
      }
      if (this.filterSelectedOptions.premiumRange) {
        if (
          this.filterSelectedOptions.premiumRange.from !== null &&
          this.filterSelectedOptions.premiumRange.from !== undefined &&
          this.filterSelectedOptions.premiumRange.from !== '' &&
          this.filterSelectedOptions.premiumRange.to !== null &&
          this.filterSelectedOptions.premiumRange.to !== undefined &&
          this.filterSelectedOptions.premiumRange.to !== ''
        ) {
          flag++;
        }
      }
      if (this.filterSelectedOptions.effectiveDt) {
        if (
          this.filterSelectedOptions.effectiveDt.from &&
          this.filterSelectedOptions.effectiveDt.from !== '' &&
          this.filterSelectedOptions.effectiveDt.to &&
          this.filterSelectedOptions.effectiveDt.to !== ''
        ) {
          flag++;
        }
      }
      if (this.filterSelectedOptions.createdDt) {
        if (
          this.filterSelectedOptions.createdDt.from &&
          this.filterSelectedOptions.createdDt.from !== '' &&
          this.filterSelectedOptions.createdDt.to &&
          this.filterSelectedOptions.createdDt.to !== ''
        ) {
          flag++;
        }
      }
    }
    if (flag > 0) {
      return true;
    } else {
      return false;
    }
  }
  async populateFilterOptions() {
    let activeTab = this.subNavData.find((x) => x.active);
    if (this.filterSelectedHasValue()) {
      let defaultOptions = 0;
      let filteredOptions = this.filterInputOptions.map((option) => {
        const { value } = option;
        const selectedValue = this.filterSelectedOptions[value];
        if (option.type === 'dateRange' || option.type === 'dropdownRange') {
          if (
            selectedValue.from !== null &&
            selectedValue.from !== undefined &&
            selectedValue.from !== '' &&
            selectedValue.to !== null &&
            selectedValue.to !== undefined &&
            selectedValue.to !== ''
          ) {
            defaultOptions++;
            return { ...option, selectedValue, isDefault: true };
          }
        } else if (
          option.type === 'multiDropdown' ||
          option.type === 'multiSearch'
        ) {
          if (selectedValue && selectedValue.length > 0) {
            defaultOptions++;
            return { ...option, selectedValue, isDefault: true };
          }
        } else if (
          selectedValue !== null &&
          selectedValue !== undefined &&
          selectedValue !== ''
        ) {
          defaultOptions++;
          return { ...option, selectedValue, isDefault: true };
        }

        return { ...option, selectedValue, isDefault: false };
      });
      if (defaultOptions < 3) {
        filteredOptions = filteredOptions.map((option) => {
          if (defaultOptions < 3) {
            if (!option.isDefault) {
              defaultOptions++;
              return { ...option, isDefault: true };
            }
          }
          return { ...option };
        });
      }

      this.filterInputOptions = filteredOptions;
    } else {
      this.filterInputOptions = [
        {
          label: 'Policy period',
          value: 'effectiveDt',
          type: 'dateRange',
          disabled: false,
          isDefault: true,
          selectedValue: '',
          isRequired: false,
          hasValidation: true,
        },
        {
          label: 'Insured name',
          value: 'insuredName',
          type: 'text',
          disabled: false,
          selectedValue: '',
          isDefault: true,
          isRequired: false,
          hasValidation: false,
        },
        {
          label: 'Date created',
          value: 'createdDt',
          type: 'dateRange',
          disabled: false,
          isDefault: true,
          selectedValue: '',
          isRequired: false,
          hasValidation: true,
        },
        {
          label: 'Status',
          value: 'status',
          type: 'multiDropdown',
          disabled: false,
          isDefault: false,
          selectedValue: '',
          dropdownOptions: this.statusOptions,
          isRequired: false,
          hasValidation: false,
        },
        {
          label: 'Products',
          value: 'risks',
          type: 'multiDropdown',
          disabled: false,
          isDefault: false,
          selectedValue: '',
          dropdownOptions: this.pdtOptions,
          isRequired: false,
          hasValidation: false,
        },
        // {
        //   label: 'Annual premium',
        //   value: 'premiumRange',
        //   type: 'dropdownRange',
        //   disabled: false,
        //   isDefault: false,
        //   selectedValue: '',
        //   isRequired: false,
        //   hasValidation: true,
        //   dropdownOptions: this.premiumOptions,
        //   min: 0,
        //   max: 1000000,
        //   minMaxErrmsg: 'Values should be between the range of 0 to 1M',
        // },
        {
          label: 'Brokerage, branch, producer',
          value: 'brokerageBranchProd',
          type: 'multiSearch',
          disabled: false,
          isDefault: false,
          selectedValue: '',
          dropdownOptions: this.brokerageBranchProducerOptions,
          isRequired: false,
          hasValidation: false,
        },
        {
          label: 'Underwriter',
          value: 'underwriter',
          type: 'multiSearch',
          disabled: false,
          isDefault: false,
          selectedValue: '',
          dropdownOptions: this.underWriterOptions,
          isRequired: false,
          hasValidation: false,
        },
      ];
    }
  }
  createFilterFormGroup() {
    const formGroup = {};
    this.filterInputOptions.forEach((control) => {
      const isRequired = control.hasValidation ? Validators.required : '';
      let selectedValue;
      switch (control.type) {
        case 'text':
          selectedValue = control.selectedValue ? control.selectedValue : '';
          formGroup[control.value] = [selectedValue, isRequired];
          break;
        case 'number':
          selectedValue = control.selectedValue ? control.selectedValue : '';
          formGroup[control.value] = [selectedValue, isRequired];
          break;
        case 'dropdown':
          selectedValue = control.selectedValue ? control.selectedValue : '';
          formGroup[control.value] = [selectedValue, isRequired];
          break;
        case 'dateRange':
          selectedValue = control.selectedValue
            ? control.selectedValue
            : { from: '', to: '' };
          formGroup[control.value] = this.fb.group({
            from: [selectedValue.from],
            to: [selectedValue.to],
          });
          break;
        case 'dropdownRange':
          selectedValue = control.selectedValue
            ? control.selectedValue
            : { from: '', to: '' };
          formGroup[control.value] = this.fb.group({
            from: [selectedValue.from],
            to: [selectedValue.to],
          });
          break;
        case 'multiDropdown':
          selectedValue = control.selectedValue ? control.selectedValue : [];
          formGroup[control.value] = [selectedValue, isRequired];
          break;
        case 'multiSearch':
          selectedValue = control.selectedValue ? control.selectedValue : [];
          formGroup[control.value] = [selectedValue, isRequired];
          break;
        default:
          selectedValue = control.selectedValue ? control.selectedValue : '';
          formGroup[control.value] = [selectedValue, isRequired];
      }
    });
    this.filterForm = this.fb.group(formGroup);
  }
  makeFilter() {
    let insuredName =
      this.filterSelectedOptions?.insuredName ?? this.searchQuery;
    const brokerVal = this.filterSelectedOptions?.brokerageBranchProd ?? [];
    const brokerIds = brokerVal
      .filter((item) => item.type === 'Brokerage')
      .map((currentValue) => currentValue.id)
      .join(',');
    const branchIds = brokerVal
      .filter((item) => item.type === 'Branch')
      .map((currentValue) => currentValue.id)
      .join(',');
    const producersIds = brokerVal
      .filter((item) => item.type === 'Producer')
      .map((currentValue) => currentValue.id)
      .join(',');
    const productsVal = this.filterSelectedOptions?.risks ?? [];
    const productIds = productsVal.map((p) => p.id).join(',');
    const underwritersVal = this.filterSelectedOptions?.underwriter ?? [];
    const underwriterIds = underwritersVal
      .map((currentValue) => currentValue.id)
      .join(',');
    const statusVal = this.filterSelectedOptions?.status
      .map((f) => mapQuote(f.value))
      .join(',');
    const createDt = this.filterSelectedOptions?.createdDt;
    const effectiveDt = this.filterSelectedOptions?.effectiveDt;
    const premium = this.filterSelectedOptions?.premiumRange;
    let createRange = '';
    let effectiveRange = '';
    let premiumRange = '';
    if (createDt && createDt?.from && createDt?.to) {
      const formattedFromDate = getDateString(
        createDt.from,
        this.shortDateFormat,
      );
      const formattedToDate = getDateString(createDt.to, this.shortDateFormat);
      createRange = `${formattedFromDate},${formattedToDate}`;
    }
    if (effectiveDt && effectiveDt?.from && effectiveDt?.to) {
      const formattedFromDate = getDateString(
        effectiveDt.from,
        this.shortDateFormat,
      );
      const formattedToDate = getDateString(
        effectiveDt.to,
        this.shortDateFormat,
      );
      effectiveRange = `${formattedFromDate},${formattedToDate}`;
    }
    if (premium && premium?.from !== '' && premium?.to !== '') {
      const formattedFrom =
        this.premiumOptions.find((x) => x.id == premium.from)?.value ??
        premium.from;
      const formattedTo =
        this.premiumOptions.find((x) => x.id == premium.to)?.value ??
        premium.to;
      premiumRange = `${formattedFrom},${formattedTo}`;
    }
    const filter = {
      quoteStatus: statusVal,
      effectiveDt: effectiveRange,
      trxDays: 0,
      underwriterId: underwriterIds,
      brokerageBORId: brokerIds,
      brokerageBranchBORId: branchIds,
      brokerageProducerBORId: producersIds,
      riskIds: productIds,
      createdDt: createRange,
      premiumRange: premiumRange,
      insuredName: insuredName,
    };
    return filter;
  }

  getInsuredType() {
    let insuredType = 1;
    if (this.isInsuredTypeCompany) {
      insuredType = 2;
    }
    return insuredType;
  }

  handleFiltering(e) {
    this.renderer.removeClass(document.body, 'no-scroll');
    this.filterSelectedOptions = {};
    this.showFilter = false;
    this.filterSelectedOptions = e;
    this.loadResults(1, 10, this.sortingKeys);
  }

  handleFilterClear(e) {
    this.renderer.removeClass(document.body, 'no-scroll');
    this.filterSelectedOptions = {};
    this.filterSelectedOptions = e;
    this.loadResults(1, 10, this.sortingKeys);
  }
  handleExport() {
    this.showTblSpinner = true;
    const currentActiveIdx = this.subNavData.findIndex(
      (navObj) => navObj.active,
    );
    let insuredType = this.getInsuredType();
    let filter = this.makeFilter();
    const quoteStatus = mapQuote(this.subNavData[currentActiveIdx].name);
    if (quoteStatus) {
      if (filter.quoteStatus) {
        filter.quoteStatus += ',' + quoteStatus;
      } else {
        filter = Object.assign(filter, { quoteStatus: quoteStatus });
      }
    }
    filter = Object.assign(filter, { entryType: 'quote' });
    filter = Object.assign(filter, { insuredType: insuredType });
    filter = Object.assign(filter, { limit: this.totalDataCount });
    filter = Object.assign(filter, { page: 1 });

    this.policyDashboardService
      .PolicyExport(filter, this.sortingKeys)
      .subscribe(
        (data: ArrayBuffer) => {
          excelFileDownload('ExportedData.xlsx', data);
          this.showTblSpinner = false;
        },
        (error) => {
          this.showTblSpinner = false;
          const alertData = {
            show: true,
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: getErrorMessage(error),
          };
          this.alertService.addAlert(alertData);
        },
      );
  }
  formatPolicyRiskList(policyRiskList: any): {
    products: string;
    limit: string;
    premium: string;
  } {
    if (!policyRiskList || policyRiskList.length === 0) {
      return null;
    }
    let formattedObj: { products: string; limit: string; premium: string } = {
      products: '',
      limit: '',
      premium: '',
    };
    formattedObj.products = policyRiskList.map((item) => item.risk).join(', ');
    formattedObj.limit = policyRiskList
      .map((item) =>
        item.limit
          ? formatAmountWithCurrency(item.limit, this.currency)
          : formatAmountWithCurrency('0', this.currency),
      )
      .join(', ');
    formattedObj.premium = policyRiskList
      .map((item) =>
        item.premium
          ? formatAmountWithCurrency(item.premium, this.currency)
          : formatAmountWithCurrency('0', this.currency),
      )
      .join(', ');
    return formattedObj;
  }
  onInsuredTypeToggle(isCompany) {
    if (isCompany == this.isInsuredTypeCompany) {
      return;
    }
    this.isInsuredTypeCompany = isCompany;
    this.loadResults(1, 10, this.sortingKeys);
  }

  async quoteClickHandler(index) {
    if (this.isRestrictedRoleSubmission === true) {
      this.alertService.clearAlerts(-1);
      const alertData = {
        type: 'warn',
        headerText: getAlertHead('warning'),
        bodyText: this.translateService.instant(
          'common.error.noAccessToPageErrorMsg',
        ),
      };
      this.alertService.addAlert(alertData);
      return;
    }
    let quoteTimeZone =
      this.quotesList[index].policy.insured?.['insuredLocation']?.[
        'timeZoneCode'
      ] ?? 'America/New_York';
    localStorage.setItem('timezone', quoteTimeZone);

    const quoteData = this.quotesList[index];

    this.newQuoteService.updateQuoteFlowStatusData({
      Status:
        quoteData?.policyRiskTrxes?.['quoteProcessStatus']?.subdomaincode ?? '',
      toReviewReferredBy: this.getReferredBy(quoteData) ?? '',
      pendingInfoReason: this.getPendingInfoReasons(quoteData) ?? [],
    });

    this.showBodySpinner = true;
    this.store.dispatch(
      new CreateQuoteAction.UpdateInsuredAction(this.insuredData),
    );
    this.store.dispatch(
      new CreateQuoteAction.setPolicyPeriodId(this.policyPeriodIds[index]),
    );

    const quoteID = this.tableData[index]['Quote ID'];
    this.router.navigate([`../../${this.quoteFlowUrl}/${quoteID}`], {
      relativeTo: this.activatedRoute,
      skipLocationChange: true,
    });

    let brokerageCommission =
      this.quotesList[index].policyRiskTrxes?.['policyRisk'][0]
        ?.brokerCommissionPerc ?? 0;
    brokerageCommission = brokerageCommission * 100;

    let quoteStatusCase =
      this.quotesList[index]?.policyRiskTrxes?.quoteStatus?.description;
    const quoteStatus = this.tableData[index].Status;

    if (quoteStatus.toLowerCase() == QuoteStatus.Submission.toLowerCase()) {
      this.showBodySpinner = false;
      this.router.navigate(
        [`../../${this.quoteFlowUrl}/new/product/form/${quoteID}`],
        {
          relativeTo: this.activatedRoute,
          skipLocationChange: true,
        },
      );
    } else if (isStatusDisabled(quoteStatusCase)) {
      //Checking whether the quote status included in disabled statuses, status should not  lowercase.
      this.showBodySpinner = false;
      this.router.navigate([`../../${this.quoteFlowUrl}/${quoteID}`], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
    } else if (brokerageCommission === 0) {
      //Checking whether the commission is zero or not,
      this.showBodySpinner = false;
      this.router.navigate(
        [`../../${this.quoteFlowUrl}/new/product/form/${quoteID}`],
        {
          relativeTo: this.activatedRoute,
          skipLocationChange: true,
        },
      );
    } else {
      this.showBodySpinner = false;
      this.router.navigate([`../../${this.quoteFlowUrl}/${quoteID}`], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
    }
  }

  handleNotificationAlertClose() {
    this.notificationAlert.show = false;
  }
  handleCloseErrorEvent() {
    this.hasError = false;
    this.errorMessage = '';
  }
  handleCloseSuccessEvent() {
    this.hasSuccess = false;
    this.successMessage = '';
  }
  handleCloseFilterPopup(event) {
    this.showFilter = false;
  }
  private async fetchQuoteStatusLists() {
    return new Promise<any>(async (resolve, reject) => {
      this.domainsService.GetByDomainCode('QUOTESTATUS').subscribe({
        next: (data) => {
          this.statusOptions = data.data.map((dataObj) => ({
            key: dataObj.description,
            value: dataObj.description,
            name: dataObj.description,
            status: getPolicyStatusTypeV2(dataObj.description),
            seqId: dataObj.seqId,
            id: dataObj.id,
          }));
          resolve(true);
        },
        error: (error) => {
          reject(false);
        },
      });
    });
  }

  populatePremiumOptions() {
    const premiumOptions = [];
    for (let i = 0; i <= 10; i += 1) {
      premiumOptions.push({
        key: i * 100000,
        value: i * 100000,
        id: i,
      });
    }
    let length = premiumOptions.length;
    premiumOptions.push({
      key: 'Custom',
      subKey: '( Between 0 to 1M only. )',
      value: 'custom-amount',
      id: length + 1,
    });
    this.premiumOptions = premiumOptions;
  }
  dynamicSearchFilter(e) {
    switch (e.item.selectedInputValue) {
      case 'underwriter':
        this.searchUnderWriter(e.search);
        break;
      case 'brokerageBranchProd':
        this.searchBrokerageDirectories(e.search);
        break;
      case 'premiumRange':
        break;
      case 'risks':
        break;
      case 'status':
        break;
      case 'createdDt':
        break;
      case 'insuredName':
        break;
      case 'effectiveDt':
        break;
      default:
    }
  }
  dynamicLoadMore(e) {
    switch (e.selectedInputValue) {
      case 'underwriter':
        this.loadMoreUnderWriter();
        break;
      case 'brokerageBranchProd':
        this.loadMoreBrokerage();
        break;
      case 'premiumRange':
        break;
      case 'risks':
        break;
      case 'status':
        break;
      case 'createdDt':
        break;
      case 'insuredName':
        break;
      case 'effectiveDt':
        break;
      default:
    }
  }

  loadMoreBrokerage() {
    if (
      this.brokerageBranchProdOptions.length <
      this.brokerageDirectoryTotalRecords
    ) {
      let page = this.brokerageDirectorCurrentPage + 1;
      this.getBrokerageDirectories(this.brokerageDirectorySearch, page, true);
    }
  }
  searchBrokerageDirectories(value) {
    if (value.length >= 3) {
      this.getBrokerageDirectories(value);
    } else {
      this.brokerageBranchProdOptions = [];
    }
  }
  getBrokerageDirectories(value = '', page = 1, isLoadMore = false) {
    this.brokerageDirectoryService
      .GetBrokerageDirectories(page, 10, '', true, true, true, value)
      .subscribe((data) => {
        const tableData = data.data?.map((dataObj) => {
          if (isBroker(dataObj.entity)) {
            return {
              name: dataObj.brokerage.name,
              id: dataObj.id,
              type: dataObj.entity,
            };
          } else if (isBranch(dataObj.entity)) {
            return {
              name: dataObj.brokerageBranch.name,
              id: dataObj.id,
              type: dataObj.entity,
            };
          } else if (isProducer(dataObj.entity)) {
            return {
              name: dataObj.brokerageProducer.name,
              id: dataObj.id,
              type: dataObj.entity,
            };
          } else {
            return {
              name: '',
              id: '',
              type: '',
            };
          }
        });
        if (isLoadMore) {
          this.brokerageBranchProdOptions = [
            ...this.brokerageBranchProdOptions,
            ...tableData,
          ];
        } else {
          this.brokerageBranchProdOptions = tableData;
        }
        let ind = this.filterInputOptions.findIndex(
          (x) => x.value === 'brokerageBranchProd',
        );
        this.filterInputOptions[ind].dropdownOptions = [
          ...this.brokerageBranchProdOptions,
        ];
        this.filterInputOptions = [...this.filterInputOptions];

        this.brokerageDirectorySearch = value;
        this.brokerageDirectorCurrentPage = data.pagination.currentPage;
        this.brokerageDirectoryTotalRecords = data.pagination.totalRecords;
      });
  }
  loadMoreUnderWriter() {
    if (this.underwritersOptions.length < this.underwriterTotalRecords) {
      let page = this.underwriterCurrentPage + 1;
      this.getUnderWriter(this.underwriterSearch, page, true);
    }
  }
  searchUnderWriter(name) {
    if (name.length >= 3) {
      this.getUnderWriter(name);
    } else {
      this.underwriters = [];
      this.underwritersOptions = [];
    }
  }
  getUnderWriter(name = '', page = 1, isLoadMore = false) {
    this.boxxUserService
      .GetUnderwriterlist('', page, 10, '', name, -1, USER_ROLES.Underwriter)
      .subscribe((data) => {
        const tableData = data.data?.map((dataObj) => ({
          name: dataObj.firstName + ' ' + dataObj.lastName,
          id: dataObj.id,
        }));
        let dataObject = data.data.reduce((accumulator, value) => {
          return {
            ...accumulator,
            [value.firstName + ' ' + value.lastName]: value,
          };
        }, {});
        if (isLoadMore) {
          (this.underwriters = { ...this.underwriters, ...dataObject }),
            (this.underwritersOptions = [
              ...this.underwritersOptions,
              ...tableData,
            ]);
        } else {
          this.underwriters = dataObject;
          this.underwritersOptions = tableData;
        }
        let ind = this.filterInputOptions.findIndex(
          (x) => x.value === 'underwriter',
        );

        this.filterInputOptions[ind].dropdownOptions = [
          ...this.underwritersOptions,
        ];
        this.filterInputOptions = [...this.filterInputOptions];

        this.underwriterSearch = name;
        this.underwriterCurrentPage = data.pagination.currentPage;
        this.underwriterTotalRecords = data.pagination.totalRecords;
      });
  }

  handleEllipsisClick(rowIndex) {
    const quoteData = this.quotesList[rowIndex];
    const currentQuoteStatus =
      quoteData.policyRiskTrxes.quoteStatus.description;
    if (currentQuoteStatus === QuoteMapping['quoted']) {
      this.showIndicatedButton = true;
    } else if (currentQuoteStatus === QuoteMapping['indicated']) {
      this.showIndicatedButton = false;
    }
    this.showActionButtons = true;
  }

  handleActionButtonClickOutside() {
    this.showActionButtons = false;
  }

  async handleQuoteStatus(isIndicatedClicked, rowIndex) {
    const quoteData = this.quotesList[rowIndex];
    const currentQuoteStatus =
      quoteData.policyRiskTrxes.quoteStatus.description;
    const policyPeriodId = quoteData.policyPeriod.id;
    if (
      policyPeriodId &&
      (currentQuoteStatus === QuoteMapping['quoted'] ||
        currentQuoteStatus === QuoteMapping['indicated'])
    ) {
      let requestBody: {
        policyPeriodId: number;
        status?: number;
      } = {
        policyPeriodId,
      };
      if (isIndicatedClicked) {
        requestBody = {
          ...requestBody,
          status: 1,
        };
      } else {
        requestBody = {
          ...requestBody,
          status: 0,
        };
      }

      if (requestBody) {
        try {
          const indicateAPI =
            this.policyLifecycleService.SwitchIndicate(requestBody);
          const indicateQuoteResponse = await firstValueFrom(indicateAPI);
          this.loadResults(this.pageNumber, 10, this.sortingKeys);
          const alertData = {
            show: true,
            type: 'success',
            headerText: 'success!',
            bodyText: this.translateService.instant(
              'common.success.quoteStatusChanged',
            ),
          };
          this.alertService.addAlert(alertData);
          this.showActionButtons = false;
        } catch (error) {
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: getErrorMessage(error),
          };
          this.alertService.addAlert(alertData);
          this.showActionButtons = false;
        }
      }
    }
  }

  hideActionsButtonTemplate() {
    if (this.showActionButtons) {
      this.showActionButtons = false;
    }
  }

  getReferredBy(quoteData: any): string {
    return quoteData?.policyPeriod?.refferedBy
      ? quoteData?.policyPeriod?.refferedBy.firstName +
          ' ' +
          quoteData?.policyPeriod?.refferedBy.lastName
      : '';
  }
  getPendingInfoReasons(quoteData: any): string[] {
    return (
      quoteData?.policyRiskTrxes?.policyRiskTrxReason?.filter(
        (reason) =>
          reason.reasonTypeDto.subdomaincode ===
          SUB_DOMAIN_CODE_REASONTYPE_PENDINGINFO,
      ) ?? []
    );
  }
}
