<div class="holder" *ngIf="storeData$ | async as storeData">
  <ng-container *ngIf="showErrorAlert">
    <div class="alert-holder">
      <boxxds-alert
        *ngFor="
          let errorMessage of removeDuplicatesFromArray(alertErrMsg);
          let i = index
        "
        type="error"
        headerText="Error!"
        [bodyText]="errorMessage | translate"
        (handleCloseEvent)="closeAlert()"
        [containerStyle]="i !== 0 ? { top: (i + 1) * 85 + 'px' } : null"
      ></boxxds-alert>
    </div>
  </ng-container>
  <div>
    <boxxds-heading
      [sz]="'5'"
      [text]="details.header"
      [customStyle]="{ marginBottom: '24px' }"
    >
    </boxxds-heading>
    <div class="d-flex">
      <boxxds-body
        sz="xxs"
        [text]="'quoteDetails.currentPeriod' | translate"
        [customStyle]="{
          color: '#8F8F91',
          textTransform: 'uppercase',
          marginRight: '8px'
        }"
      ></boxxds-body>
      <boxxds-body
        sz="xxs"
        [text]="details.currentPeriod"
        [customStyle]="{ color: '#56565C' }"
      ></boxxds-body>
    </div>
    <boxxds-heading
      [sz]="'7'"
      [text]="
        (isQuote
          ? ('common.quoteId' | translate)
          : ('common.policyId' | translate)) +
        ': ' +
        details.id
      "
      [customStyle]="{ marginTop: '4px', marginBottom: '4px' }"
    >
    </boxxds-heading>
    <boxxds-status-badge
      [status]="details.status"
      [statusType]="getStatusType(details.status)"
      [badgeStyle]="{ textTransform: 'capitalize' }"
    ></boxxds-status-badge>
  </div>
  <div>
    <div class="d-flex align-items-center mb-2">
      <boxxds-body
        sz="sm"
        [text]="'quoteDetails.showPeriod' | translate"
        [customStyle]="{ color: '#333334', marginRight: '8px' }"
      >
      </boxxds-body>
      <boxxds-dropdown
        [placeholder]="'Current' + ': ' + details.currentPeriod"
        [options]="showPeriodOptions"
        (currentValueChange)="
          this.permissionList[this.currentScreen] &&
            currentProductDetails.active &&
            showPeriodChanged($event)
        "
        [dropdownContainerStyle]="{ width: '169px' }"
        [class.noPointerEvents]="
          !this.permissionList[this.currentScreen] ||
          !currentProductDetails.active
        "
      >
      </boxxds-dropdown>
    </div>
    <div>
      <div class="d-flex justify-content-between gap-4 flex-row">
        <div class="mr-3 d-flex column-width-280">
          <div>
            <boxxds-body
              sz="xxs"
              [text]="'quoteDetails.borBrokerage' | translate"
              [customStyle]="{
                color: '#8F8F91',
                textTransform: 'uppercase',
                marginTop: '8px',
                marginRight: '20px'
              }"
            >
            </boxxds-body>
            <boxxds-body
              sz="xxs"
              [text]="'quoteDetails.borBranch' | translate"
              [customStyle]="{
                color: '#8F8F91',
                textTransform: 'uppercase',
                marginTop: '8px',
                marginRight: '20px'
              }"
            >
            </boxxds-body>
            <boxxds-body
              sz="xxs"
              [text]="'quoteDetails.borProducer' | translate"
              [customStyle]="{
                color: '#8F8F91',
                textTransform: 'uppercase',
                marginTop: '8px',
                marginRight: '20px'
              }"
            >
            </boxxds-body>
          </div>

          <div>
            <boxxds-body
              sz="sm"
              [text]="details.brokerage"
              [customStyle]="{ color: '#56565C', marginTop: '4px' }"
            >
            </boxxds-body>
            <boxxds-body
              sz="sm"
              [text]="details.branch"
              [customStyle]="{ color: '#56565C', marginTop: '4px' }"
            ></boxxds-body>
            <div class="d-flex">
              <boxxds-body
                sz="sm"
                [text]="details.producer"
                [customStyle]="{ color: '#56565C', marginTop: '4px' }"
              ></boxxds-body>
              <boxxds-link
                *ngIf="
                  showChangeProducerLink() && currentProductDetails?.active
                "
                [linkStyle]="{
                  marginLeft: '16px',
                  marginTop: '8px',
                  border: 'none',
                  padding: '0'
                }"
                (handleClick)="changeProducer()"
                [name]="'Change' | translate"
              ></boxxds-link>
            </div>
          </div>
        </div>
        <div class="d-flex mr-3">
          <div>
            <boxxds-body
              sz="xxs"
              [text]="'quoteDetails.firstBindDate' | translate"
              [customStyle]="{
                color: '#8F8F91',
                textTransform: 'uppercase',
                marginTop: '8px',
                marginRight: '20px'
              }"
            >
            </boxxds-body>
            <boxxds-body
              sz="xxs"
              [text]="'quoteDetails.inForcePolicyPeriod' | translate"
              [customStyle]="{
                color: '#8F8F91',
                textTransform: 'uppercase',
                marginTop: '8px',
                marginRight: '20px'
              }"
            >
            </boxxds-body>
            <boxxds-body
              sz="xxs"
              [text]="'quoteDetails.insuredInformation' | translate"
              [customStyle]="{
                color: '#8F8F91',
                textTransform: 'uppercase',
                marginTop: '8px',
                marginRight: '20px'
              }"
            >
            </boxxds-body>
          </div>
          <div>
            <boxxds-body
              sz="sm"
              [text]="details.firstBindDate"
              [customStyle]="{ color: '#56565C', marginTop: '4px' }"
            >
            </boxxds-body>
            <boxxds-body
              sz="sm"
              [text]="details.policyPeriod"
              [customStyle]="{ color: '#56565C', marginTop: '4px' }"
            >
            </boxxds-body>
            <div *ngIf="details.insuredName.length > 19">
              <p
                class="insuredName"
                (mouseenter)="namePopoverOpen = true"
                (mouseleave)="namePopoverOpen = false"
              >
                {{ details.insuredName | truncateText: 20 : "..." }}
              </p>
              <boxxds-popover
                [contentAsNumberedList]="false"
                [popOverContent]="[details.insuredName]"
                [showCloseButton]="false"
                [isPopoverOpen]="namePopoverOpen"
                [containerStyle]="{
                  padding: '0px 0px 5px 10px',
                  maxWidth: '340px',
                  width: 'max-content',
                  minWidth: '100px',
                  left: '0px',
                  marginTop: '10px',
                  borderRadius: '0',
                  zIndex: '6'
                }"
                [dataStyle]="{
                  marginTop: '5px',
                  marginRight: '10px',
                  wordBreak: 'break-word'
                }"
              ></boxxds-popover>
            </div>
            <boxxds-body
              *ngIf="details.insuredName.length < 20"
              sz="sm"
              [text]="details.insuredName"
              [customStyle]="{ color: '#56565C', marginTop: '4px' }"
            >
            </boxxds-body>
          </div>
        </div>

        <div class="d-flex ml-5">
          <div>
            <!-- notes -->
            <div class="position-relative align-self-center">
              <div class="ellipse" *ngIf="details.isUnreadNotesPresent"></div>
              <button
                class="btn-notes"
                [disabled]="!currentProductDetails.active"
                [class.noPointerEvents]="!currentProductDetails.active"
                (click)="
                  currentProductDetails.active && toggleNotesPopup($event)
                "
              >
                <boxxds-body
                  sz="xs"
                  [text]="'common.notes' | translate"
                  [customStyle]="{ color: '#8A8796' }"
                >
                </boxxds-body>
                <img src="assets/img/notes.png" alt="" />
              </button>

              <app-notes-v2
                *ngIf="showNotesPopup"
                [showModalNotesPopup]="showNotesPopup"
                [policyPeriodId]="policyPeriodId"
                (handleClose)="handleNotesPopupClose($event)"
              ></app-notes-v2>

              <!-- <div
                *ngIf="showNotesPopup"
                class="notes-popup"
                (clickOutside)="handleCloseClk()"
              >
                <div class="col-md-12 d-flex">
                  <boxxds-body
                    sz="xxs"
                    [text]="'quoteDetails.recentFor2023Period' | translate"
                    [customStyle]="{
                      color: '#BDBDBF',
                      textTransform: 'uppercase',
                      marginBottom: '20px'
                    }"
                  >
                  </boxxds-body>
                  <div class="close-btn ml-auto">
                    <button (click)="handleCloseClk()">
                      <img src="assets/img/dashboard/card/close.png" />{{
                        "common.close" | translate
                      }}
                    </button>
                  </div>
                </div>

                <app-notes
                  [form]="form"
                  textareaControl="inputNotes"
                  [showTag]="true"
                  [details]="details"
                  [notesContainerStyle]="{ maxHeight: '270px' }"
                  [actionPopupStyle]="{ width: '155px', left: '-148px' }"
                  (handleCurrentTag)="hanldeCurrentTag($event)"
                  [currentTagOptions]="currentTagOptions"
                ></app-notes>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="policy-actions-holder mr-5">
      <boxxds-body
        sz="xxs"
        [text]="'quoteDetails.policyLevelActions:' | translate"
        [customStyle]="{
          color: '#616162',
          textTransform: 'uppercase',
          marginBottom: '8px'
        }"
      ></boxxds-body>
      <div class="action-btns">
        <button
          [class.noPointerEvents]="
            !this.permissionList[this.currentScreen] ||
            this.currentQuoteStatus === 'quote-closed' ||
            isPolicyActionBtnsDisabled.bind ||
            !currentProductDetails.active
          "
          [disabled]="
            !this.permissionList[this.currentScreen] ||
            this.currentQuoteStatus === 'quote-closed' ||
            isPolicyActionBtnsDisabled.bind ||
            !currentProductDetails.active
          "
          (click)="
            this.permissionList[this.currentScreen] &&
              currentProductDetails.active &&
              bindHandler($event)
          "
          (mouseout)="
            bindIconSrc = 'assets/img/dashboard/quote/lifecycle/bind.png'
          "
          (mouseover)="
            bindIconSrc = 'assets/img/dashboard/quote/lifecycle/bind-hover.png'
          "
        >
          {{ "common.bind" | translate }}
          <img
            [src]="
              !this.permissionList[this.currentScreen] ||
              isPolicyActionBtnsDisabled.bind ||
              !currentProductDetails.active
                ? 'assets/img/dashboard/quote/lifecycle/bind-disabled.png'
                : bindIconSrc || 'assets/img/dashboard/quote/lifecycle/bind.png'
            "
            alt=""
          />
        </button>
        <button
          [class.noPointerEvents]="
            !this.permissionList[this.currentScreen] ||
            isPolicyActionBtnsDisabled.renew ||
            !currentProductDetails.active
          "
          [disabled]="
            !this.permissionList[this.currentScreen] ||
            isPolicyActionBtnsDisabled.renew ||
            !currentProductDetails.active
          "
          (click)="
            this.permissionList[this.currentScreen] &&
              currentProductDetails.active &&
              renewHandler($event)
          "
          (mouseout)="
            renewIconSrc = 'assets/img/dashboard/quote/lifecycle/renew.png'
          "
          (mouseover)="
            renewIconSrc =
              'assets/img/dashboard/quote/lifecycle/renew-hover.png'
          "
        >
          {{ "common.renew" | translate }}
          <img
            [src]="
              isPolicyActionBtnsDisabled.renew
                ? 'assets/img/dashboard/quote/lifecycle/renew-disabled.png'
                : renewIconSrc ||
                  'assets/img/dashboard/quote/lifecycle/renew.png'
            "
            alt=""
          />
        </button>
        <button
          [class.noPointerEvents]="
            !this.permissionList[this.currentScreen] ||
            isPolicyActionBtnsDisabled.cancel
          "
          [disabled]="
            !this.permissionList[this.currentScreen] ||
            isPolicyActionBtnsDisabled.cancel
          "
          (click)="
            this.permissionList[this.currentScreen] && cancelHandler($event)
          "
          (mouseout)="
            cancelIconSrc = 'assets/img/dashboard/quote/lifecycle/cancel.png'
          "
          (mouseover)="
            cancelIconSrc =
              'assets/img/dashboard/quote/lifecycle/cancel-hover.png'
          "
        >
          {{ "common.cancel" | translate }}
          <img
            [src]="
              isPolicyActionBtnsDisabled.cancel
                ? 'assets/img/dashboard/quote/lifecycle/cancel-disabled.png'
                : cancelIconSrc ||
                  'assets/img/dashboard/quote/lifecycle/cancel.png'
            "
            alt=""
          />
        </button>
        <button
          [class.noPointerEvents]="
            !this.permissionList[this.currentScreen] ||
            isPolicyActionBtnsDisabled.reinstate ||
            !currentProductDetails.active
          "
          [disabled]="
            !this.permissionList[this.currentScreen] ||
            isPolicyActionBtnsDisabled.reinstate ||
            !currentProductDetails.active
          "
          (click)="
            this.permissionList[this.currentScreen] &&
              currentProductDetails.active &&
              reinstateHandler($event)
          "
          (mouseout)="
            reinstateIconSrc =
              'assets/img/dashboard/quote/lifecycle/reinstate.png'
          "
          (mouseover)="
            reinstateIconSrc =
              'assets/img/dashboard/quote/lifecycle/reinstate-hover.png'
          "
        >
          {{ "common.reInstate" | translate }}
          <img
            [src]="
              isPolicyActionBtnsDisabled.reinstate
                ? 'assets/img/dashboard/quote/lifecycle/reinstate-disabled.png'
                : reinstateIconSrc ||
                  'assets/img/dashboard/quote/lifecycle/reinstate.png'
            "
            alt=""
          />
        </button>
        <button
          [class.noPointerEvents]="
            !this.permissionList[this.currentScreen] ||
            isPolicyActionBtnsDisabled.startNewPeriod ||
            !currentProductDetails.active
          "
          [disabled]="
            !this.permissionList[this.currentScreen] ||
            isPolicyActionBtnsDisabled.startNewPeriod ||
            !currentProductDetails.active
          "
          (click)="
            this.permissionList[this.currentScreen] &&
              currentProductDetails.active &&
              startNextPeriodHandler($event)
          "
          (mouseout)="
            startNewPeriodIconSrc =
              'assets/img/dashboard/quote/lifecycle/period.png'
          "
          (mouseover)="
            startNewPeriodIconSrc =
              'assets/img/dashboard/quote/lifecycle/period-hover.png'
          "
        >
          {{ "common.startNextPeriod" | translate }}
          <img
            [src]="
              isPolicyActionBtnsDisabled.startNewPeriod ||
              !currentProductDetails.active
                ? 'assets/img/dashboard/quote/lifecycle/period-disabled.png'
                : startNewPeriodIconSrc ||
                  'assets/img/dashboard/quote/lifecycle/period.png'
            "
            alt=""
          />
        </button>

        <button
          [class.noPointerEvents]="
            !this.permissionList[this.currentScreen] ||
            ['quote-closed'].includes(this.currentQuoteStatus) ||
            this.isPolicyActionBtnsDisabled.sendPackage ||
            !currentProductDetails.active
          "
          [disabled]="
            !this.permissionList[this.currentScreen] ||
            ['quote-closed'].includes(this.currentQuoteStatus) ||
            this.isPolicyActionBtnsDisabled.sendPackage ||
            !currentProductDetails.active
          "
          (click)="
            this.permissionList[this.currentScreen] &&
              !['quote-closed'].includes(this.currentQuoteStatus) &&
              !this.isPolicyActionBtnsDisabled.sendPackage &&
              currentProductDetails.active &&
              handleSendQuote()
          "
          (mouseout)="
            sendPackageIconSrc =
              'assets/img/dashboard/quote/lifecycle/send-package.png'
          "
          (mouseover)="
            this.permissionList[this.currentScreen] &&
              !['quote-closed'].includes(this.currentQuoteStatus) &&
              (sendPackageIconSrc =
                'assets/img/dashboard/quote/lifecycle/send-package-hover.png')
          "
        >
          {{
            isQuote
              ? ("quoteSummary.sendQuote" | translate)
              : ("common.sendPackage" | translate)
          }}
          <img
            [src]="
              !this.permissionList[this.currentScreen] ||
              ['quote-closed'].includes(this.currentQuoteStatus) ||
              isPolicyActionBtnsDisabled.sendPackage ||
              !currentProductDetails.active
                ? 'assets/img/dashboard/quote/lifecycle/send-package-disabled.png'
                : sendPackageIconSrc ||
                  'assets/img/dashboard/quote/lifecycle/send-package.png'
            "
            alt=""
          />
        </button>
        <app-modal-send
          *ngIf="showSendModal"
          [isLoader]="isLoader"
          [showModal]="showSendModal"
          (handleClose)="closeSendPackage()"
          [formDetails]="storeData.ui"
          [templateDetails]="templateDetails"
          [docDetails]="docDetails"
          (handleSend)="handleSend($event)"
          [title]="'quoteDetails.sendingQuoteOptionPackage' | translate"
        >
        </app-modal-send>

        <!-- Document Template selection-->

        <app-doc-template-selection-popup
          *ngIf="showDocTempSelectionPopup"
          [showModal]="showDocTempSelectionPopup"
          [details]="docPopupDetails"
          (handleSuccess)="handleSuccessDocTemplate($event)"
          (handleActionConfirm)="
            this.permissionList[this.currentScreen] &&
              handleDocLevelAction($event)
          "
          (handleClose)="showDocTempSelectionPopup = false"
        ></app-doc-template-selection-popup>
        <!-- Document Template selection-->
      </div>
    </div>
  </div>
</div>

<app-change-producer-popup
  [showModal]="showChangeProducerPopup"
  [regionId]="regionId"
  [details]="details"
  [effectiveDate]="effectiveDate"
  (handleActionConfirm)="producerChanged($event)"
  (handleClose)="closeChangeProducer()"
>
</app-change-producer-popup>
