import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, retry, catchError } from 'rxjs';
import { BoxxResponse } from '../entities/boxx-response';
import { BaseService } from './base-services';

@Injectable({
  providedIn: 'root',
})
export class MessageSendService extends BaseService<any> {
  constructor(http: HttpClient) {
    // const baseurl = environment.msPolicyBaseUrl + environment.messageSendUrl;
    const baseurl = environment.msPolicyBaseUrl + environment.messageSendUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      //   'Content-Type': 'multipart/form-data',
    }),
  };

  sendMessage(data: any): Observable<BoxxResponse<any>> {
    let url = `${this.baseurl}`;
    return this.http.post<BoxxResponse<any>>(url, data, this.httpOptions);
  }
}
