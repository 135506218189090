import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
})
export class LoginComponent implements OnInit {
  constructor(private fb: FormBuilder) {}
  loginForm: FormGroup;

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: [''],
      password: [''],
    });
  }

  isSignInBtnDisabled() {
    return !(
      this.loginForm.value.email &&
      this.loginForm.value.password &&
      this.loginForm.status !== 'INVALID'
    );
  }

  handleSubmit(e: any) {
    this.loginForm.controls['email'].setErrors({ message: ' ' });
    this.loginForm.controls['password'].setErrors({
      message: 'Incorrect credentials',
    });
  }
}
