import { Component, OnInit, Input } from '@angular/core';
import { Quote } from 'src/app/models/quote.model';
import { take, Observable } from 'rxjs';
import { PolicyRiskDocProcessService } from 'src/app/services/policy-risk-doc-process.service';
import {
  TEMPLATETYPE,
  TEMPLATETYPE_ENDORSEMENT,
  TEMPLATETYPE_POLICYWORDING,
  TEMPLATETYPE_DECLARATION,
} from 'src/app/constants/quote-constant';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import { RiskTemplateService } from 'src/app/services/risk-template';
import { PolicyRiskLimitService } from 'src/app/services/policy-risk-limit';
import { PolicySubjectivityService } from 'src/app/services/policy-subjectivity.service';
import { RiskRegionSubjectivityService } from 'src/app/services/risk-region-subjectivity.service';
import { PolicyRiskService } from 'src/app/services/policy-risk.service';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import { DomainsService } from 'src/app/services/domains.service';

@Component({
  selector: 'app-product-detail-accordion',
  templateUrl: './product-detail-accordion.component.html',
  styleUrls: ['./product-detail-accordion.component.less'],
})
export class ProductDetailAccordionComponent implements OnInit {
  form$: Observable<Quote>;
  showBodySpinner: boolean = false;
  showAllCards: boolean = false;
  @Input() policyRiskId: number = null;
  @Input() underWriters = [];
  @Input() policyRiskIds = [];
  addonsFromPolicyServ = [];
  addons = [];
  domainCodeForEndorsement = 0;
  domainCodeForWordings = 0;
  domainCodeForDeclarations = 0;

  endorsementOptions: any[] = [
    { key: '', value: '', disabled: false, url: '' },
  ];
  wordingsOptions: any[] = [{ key: '', value: '', disabled: false, url: '' }];
  subjectivityOptns: any[] = [{ key: '', value: '', disabled: false, url: '' }];
  subjectivityMandatory = [];
  selectedSubjectivities = [];
  endorsementsMandatory = [];
  selectedEndorsements = [];
  selectedWordings = [];
  selectedDeclarations = [];
  declarationOptions: any[] = [
    { key: '', value: '', disabled: false, url: '' },
  ];
  openCardList = [];
  currency = '';
  pdtDescription = [];
  cyberPdtDesc = [];
  @Input() products = [];
  @Input() quoteOptions = [];
  @Input() policyPeriodId: any;
  @Input() productList: any[] = [];

  @Input() riskTrnxs;
  @Input() productsIds = [];
  @Input() regionId = 0;
  @Input() riskId = 0;
  riskRegionId = 0;

  constructor(
    private policyRiskService: PolicyRiskService,
    private boxxUserService: BoxxUserService,
    private riskRegionService: RiskRegionService,
    private domainsService: DomainsService,
    private policyRiskLimitService: PolicyRiskLimitService,
    private riskRegionSubjectivityService: RiskRegionSubjectivityService,
    private policySubjectivityService: PolicySubjectivityService,
    private riskTemplateService: RiskTemplateService,
    private policyRiskDocProcessService: PolicyRiskDocProcessService,
  ) {}

  async ngOnInit() {
    const selectedQuoteOption =
      this.quoteOptions.filter(
        (a) => a.optionSelected == 1 && a.QuoteOptionServices.length > 0,
      ) ?? [];
    if (selectedQuoteOption.length > 0) {
      const riskIds = this.productsIds.join(',');
      let addons = [];
      this.riskRegionService.getRiskServicesById(riskIds).subscribe((data) => {
        addons = data.data?.map((item: any, index, arr) => ({
          description: item.description,
          id: item.id,
          serviceName: item.serviceName,
          riskId: item.risk.id,
          riskName: item.risk.name,
        }));

        if (addons.length > 0) {
          this.productList.forEach((item: any) => {
            this.addons[item.key] = [];
            var addonArr = addons.filter((a) => a.riskId == item.riskId) ?? [];
            addonArr.forEach((addon: any) => {
              var riskServInd =
                selectedQuoteOption[0].QuoteOptionServices.findIndex(
                  (a) => a.riskServicesId == addon.id,
                );
              if (riskServInd != -1) {
                this.addons[item.key].push(addon);
              }
            });
          });
        }
      });
    }

    this.riskRegionService
      .getRiskRegionByRegionId(this.regionId, this.riskId)
      .subscribe((data) => {
        this.riskRegionId = data.data[0].id;

        this.domainsService
          .GetByDomainCode(TEMPLATETYPE)
          .pipe(take(1))
          .subscribe((data) => {
            this.domainCodeForEndorsement =
              data.data.filter(
                (d) => d.subdomaincode === TEMPLATETYPE_ENDORSEMENT,
              )[0].id ?? -1;
            this.domainCodeForWordings =
              data.data.filter(
                (d) => d.subdomaincode === TEMPLATETYPE_POLICYWORDING,
              )[0].id ?? -1;
            this.domainCodeForDeclarations =
              data.data.filter(
                (d) => d.subdomaincode === TEMPLATETYPE_DECLARATION,
              )[0].id ?? -1;

            this.products.forEach((product, index) => {
              this.policyRiskLimitService
                .getPolicyRiskLimits(this.policyRiskIds[index])
                .pipe(take(1))
                .subscribe((data) => {
                  const pdtDescriptions = data?.data
                    ?.filter((coverage) => coverage.active)
                    .map((conf) => conf.confLimit.coverageName);
                  this.pdtDescription[product] = pdtDescriptions;
                });

              this.policySubjectivityService
                .getPolicyRiskSubjectivity(this.policyRiskIds[index])
                .subscribe((data) => {
                  let selectedPolicySubj = {
                    [product]: data.data.map((policySub) => {
                      return {
                        key: policySub?.description,
                        value: policySub?.description,
                        selected: true,
                        id: policySub.riskRegionSubjectivitiesId,
                      };
                    }),
                  };
                  if (selectedPolicySubj[product]?.length > 0) {
                    this.selectedSubjectivities[product] = [
                      ...selectedPolicySubj[product],
                    ];
                  }
                });
              this.policyRiskDocProcessService
                .getPolicyRiskDocCore(this.policyRiskIds[index])
                .subscribe((policyRiskDoc) => {
                  // get policy endorsement docs
                  let selectedEndorsementDocs = policyRiskDoc?.data?.filter(
                    (doc) => doc.templateType == this.domainCodeForEndorsement,
                  );
                  let selectedEndorseDocs = {
                    [product]: selectedEndorsementDocs.map((doc) => {
                      return {
                        key: doc.riskTemplate.templateDescription || '',
                        value: doc.riskTemplate.templateDescription || '',
                        templateType: doc.riskTemplate.templateType,
                        policyRiskId: this.policyRiskIds[index],
                        url: doc.riskTemplate.templatePath,
                        selected: true,
                        documentTblWordId: doc?.id,
                        riskTemplateId: doc.riskTemplate.id ?? null,
                      };
                    }),
                  };

                  if (selectedEndorseDocs[product]?.length > 0) {
                    this.selectedEndorsements[product] = [
                      ...selectedEndorseDocs[product],
                    ];
                  }

                  // get wordings

                  // get policy wordings docs
                  let selectedWordingsDocs = policyRiskDoc?.data?.filter(
                    (doc) => doc.templateType == this.domainCodeForWordings,
                  );
                  let selectedWordingDocs = {
                    [product]: selectedWordingsDocs.map((doc) => {
                      return {
                        key: doc.riskTemplate.templateDescription || '',
                        value: doc.riskTemplate.templateDescription || '',
                        templateType: doc.riskTemplate.templateType,
                        policyRiskId: this.policyRiskIds[index],
                        url: doc.riskTemplate.templatePath,
                        selected: true,
                        documentTblWordId: doc?.id,
                        riskTemplateId: doc.riskTemplate.id ?? null,
                      };
                    }),
                  };

                  if (selectedWordingDocs[product]?.length > 0) {
                    this.selectedWordings[product] = [
                      ...selectedWordingDocs[product],
                    ];
                  }

                  // get Declarations
                  // get policy declaration docs
                  let selectedDeclarationsDocs = policyRiskDoc?.data?.filter(
                    (doc) =>
                      doc?.templateType == this.domainCodeForDeclarations,
                  );

                  let selectedDeclarationDocs = {
                    [product]: selectedDeclarationsDocs.map((doc) => {
                      return {
                        key: doc.riskTemplate.templateDescription || '',
                        value: doc.riskTemplate.templateDescription || '',
                        templateType: doc.riskTemplate.templateType,
                        policyRiskId: this.policyRiskIds[index],
                        url: doc.riskTemplate.templatePath,
                        selected: true,
                        documentTblWordId: doc?.id,
                        riskTemplateId: doc.riskTemplate.id ?? null,
                      };
                    }),
                  };

                  if (selectedDeclarationDocs[product].length > 0) {
                    this.selectedDeclarations[product] =
                      selectedDeclarationDocs[product];
                  }
                });
            });
          });
      });
  }
  isNumber(number) {
    return !isNaN(number);
  }
  getFilename(path) {
    return path.substring(path.lastIndexOf('/') + 1);
  }
  getBrokerageCommissionPerc(brokerageCommissionPerc) {
    if (!brokerageCommissionPerc) {
      return '0';
    }
    return (Number(brokerageCommissionPerc) * 100)?.toFixed(2)?.toString();
  }
  toggleShowCard(cardId) {
    this.openCardList.includes(cardId)
      ? this.openCardList.splice(this.openCardList.indexOf(cardId), 1)
      : this.openCardList.push(cardId);
  }
  getSelectedQuote(options) {
    return options.filter((option) => option.optionSelected == 1);
  }
  toggleshowAllCards() {
    let noOfCards = this.quoteOptions.length;
    if (this.showAllCards) {
      this.openCardList = [];
      this.showAllCards = !this.showAllCards;
    } else {
      for (let i = 0; i < noOfCards; i++) {
        this.openCardList.push(i);
      }
      this.showAllCards = !this.showAllCards;
    }
  }
}
