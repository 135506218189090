import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-image-doc-preview',
  templateUrl: './image-doc-preview.component.html',
  styleUrls: ['./image-doc-preview.component.less'],
})
export class ImageDocPreviewComponent {
  @Input() url = '';
  @Input() fileType = 'image';

  @Output() handleClose = new EventEmitter<any>();

  constructor() {}

  handleCloseClk() {
    this.handleClose.emit();
  }
}
