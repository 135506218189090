import { Action } from '@ngrx/store';

import * as actionTypes from './dashboard.action';
import { IDashboard } from 'src/app/models/dashboard.model';

const initialState: IDashboard = {
  permissions: [],
  currency: '',
  country: '',
  litePermissionList: {},
  currentScreenDescription: '',
  userRollId: undefined,
  isNavigatedFromAlert: null,
  shortDateFormat: '',
  longDateFormat: '',
  shortDateTimeFormat: '',
  longDateTimeFormat: '',
  userTypes: [],
  isRestrictedRoleSubmission: false,
};

export function dashboardReducer(state: IDashboard = initialState, action) {
  switch (action.type) {
    case actionTypes.SAVE_PERMISSIONS:
      return {
        ...state,
        permissions: action.permissions,
      };
    case actionTypes.UPDATE_DASHBOARD_CONFIG:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SAVE_USERTYPES:
      return {
        ...state,
        ...action.userTypes,
      };
    case actionTypes.SET_RESTRICTED_ROLE_SUBMISSION:
      return {
        ...state,
        isRestrictedRoleSubmission: action.newValue,
      };
    default:
      return state;
  }
}
