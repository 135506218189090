import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { DomainsService } from 'src/app/services/domains.service';
import { getAlertHead, getErrorMessage } from 'src/app/utils/utils';
import { checkRegexPatternNotes } from 'src/app/utils/notesUtils';
import {
  formatDate,
  getDateFormatForDatePicker,
  formatDateTime,
} from 'src/app/utils/formatDate';

import { firstValueFrom, take } from 'rxjs';
import { PolicyPeriodNotesService } from 'src/app/services/policy-period-notes.service';
import { Store, select } from '@ngrx/store';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { getQuoteSelector } from 'src/app/store/create-quote/create-quote.selector';
import { PolicyRiskService } from 'src/app/services/policy-risk.service';
import moment from 'moment';
import { removeDuplicatedKeys } from 'src/app/utils/utils';
import { ModalAlertService } from 'src/app/services/modal-alert.service';
import { getLifecycleState } from 'src/app/store/lifecycle/lifecycle.select';

@Component({
  selector: 'app-notes-v2',
  templateUrl: './notes-v2.component.html',
  styleUrls: ['./notes-v2.component.less'],
})
export class NotesV2Component implements OnInit, AfterViewInit {
  @Input() showModalNotesPopup: boolean = false;
  @Input() policyPeriodId;
  @Input() quoteOptionId;
  @Input() notesContainerStyle: { [klass: string]: any };
  @Input() quoteId;
  @Output() handleClose = new EventEmitter<any>();
  notesFormGroup: FormGroup;
  submitted: boolean = false;
  activeMenu = 'add_note';
  notesCategory = [];
  shortDateFormat: string = '';
  longDateFormat: string = '';
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  disableAddButton: boolean = false;
  customErrMsg: any;
  errorMsgValid: boolean = true;
  errorMsgUpdateNoteValid: boolean = false;
  isNoteLoader: boolean = true;
  trxType;
  trxTypeId;
  policyId;
  categoryId;
  currentCategorySelected;
  notes = [];
  policyRisks;
  showFilter: boolean = true;
  tagSearchKey;

  notesSearchKeyWord = '';
  dateSearchKeyWord = '';
  fromDateSearchKeyWord = '';
  toDateSearchKeyWord = '';
  writerSearchKeyWord: number;
  noteTagSearch;
  minLengthErrMsg: string = '';
  isEndDateValid: boolean = true;
  isStartDateValid: boolean = true;
  endDateErrMsg = '';
  startDateErrMsg = '';
  isNoteFormValid: boolean = false;
  options;
  tag;
  noteList;
  searchForm: FormGroup;
  currentTagOptions;
  editReplyNotesFormGroup: FormGroup;
  replyNotesFormGroup: FormGroup;
  editNotesFormGroup: FormGroup;
  isPopoverOpen = false;
  popoverPosition = { top: '0', left: '0' };
  menuItems: string[];
  menuContext: any;
  hideTimeout: any;
  filter;
  all;
  invalidDateRange: boolean = false;
  @ViewChildren('textareaRef') textareas: QueryList<ElementRef>;
  activeId: number;
  noteData = [];
  currentPage;
  totalCount;
  totalPages;
  allNotesList = [];
  allCurrentPage;
  allTotalCount;
  allTotalPages;
  latestNoteData: any;
  noteParentId = null;
  latestNote = null;
  notesCount = 0;
  noteDataList = [];
  product;
  currentQuoteStatus;
  constructor(
    private fb: FormBuilder,
    private domainService: DomainsService,
    private alertService: ModalAlertService,
    private dashboardAlertService: AlertService,
    private policyPeriodNotesService: PolicyPeriodNotesService,
    private policyRiskService: PolicyRiskService,
    private store: Store,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.notesFormGroup = this.fb.group({
      note: ['', [Validators.required, Validators.maxLength(500)]],
      categoryId: ['', [Validators.required]],
    });

    this.searchForm = this.fb.group(
      {
        keyword: [''],
        tag: [''],
        fromDate: [''],
        toDate: [''],
        writer: [''],
      },
      { validators: this.dateRangeValidator },
    );
    this.editNotesFormGroup = this.fb.group({
      note: ['', [Validators.required, Validators.maxLength(500)]],
    });
    this.replyNotesFormGroup = this.fb.group({
      note: ['', [Validators.required, Validators.maxLength(500)]],
    });
    this.editReplyNotesFormGroup = this.fb.group({
      note: ['', [Validators.required, Validators.maxLength(500)]],
    });
  }

  async ngOnInit(): Promise<void> {
    this.alertService.clearAllAlerts();
    this.dashboardAlertService.clearAlerts(-1);
    let form = this.store.pipe(select(getQuoteSelector));
    form.pipe(take(1)).subscribe((event) => {
      const uiData = event.ui;
      this.policyId = uiData.policyId;
      this.policyPeriodId = uiData.policyPeriodId;
      this.trxType = uiData.transactionType;
      this.trxTypeId = uiData.transactionTypeId;
    });
    await Promise.all([
      await this.fetchNotesCategoryList(),
      await this.getPolicyData(),
    ]);
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;

      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    this.store
      .select(getLifecycleState)
      .pipe(take(1))
      .subscribe((state) => {
        this.currentQuoteStatus = state.quoteStatus;
      });

    this.isNoteLoader = false;
  }
  hasPermissionForActions(): boolean {
    const quoteStatusCheck = !['quote-closed'].includes(
      this.currentQuoteStatus,
    );
    const permissionCheck = this.permissionList[this.currentScreen];
    const hasPermission = quoteStatusCheck && permissionCheck;
    return hasPermission;
  }

  async getPolicyData() {
    await this.policyRiskService
      .GetAllByPolicyPeriodId(this.policyPeriodId)
      .subscribe((data) => {
        if (data?.data[0]) {
          this.policyRisks = data?.data;
          this.policyId = this.policyRisks[0].policyPeriod.policyId;
          const latestTransactionTypeIndex =
            this.policyRisks[0].PolicyRiskTrxes.length - 1;
          this.trxType =
            this.policyRisks[0].PolicyRiskTrxes[
              latestTransactionTypeIndex
            ].policyRiskTrxType.description;
          this.trxTypeId = Number(
            this.policyRisks[0].PolicyRiskTrxes[latestTransactionTypeIndex]
              .type,
          );
          let trxTypes = this.policyRisks[0].PolicyRiskTrxes?.map(
            (dataObj) => ({
              key: dataObj.policyRiskTrxType.description,
              value: dataObj.policyRiskTrxType.description,
              id: dataObj.policyRiskTrxType.id,
            }),
          );
          trxTypes = removeDuplicatedKeys(trxTypes);

          this.currentTagOptions = trxTypes;
          this.product = this.policyRisks[0].risk.name;
        }
      });
  }

  dateRangeValidator(group: FormGroup) {
    const fromDate = group.get('fromDate').value;
    const toDate = group.get('toDate').value;

    if (fromDate && toDate && fromDate <= toDate) {
      return true;
    } else if (!fromDate && !toDate) {
      return true;
    } else {
      return false;
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  isSearchEnabled() {
    return Object.values(this.searchForm.value).some(
      (value) => value !== null && value !== '',
    );
  }

  handleTagFilter(value) {
    this.searchForm.controls['tag'].setValue(value);
  }
  ngAfterViewInit(): void {
    this.autoResize();
  }

  closeModal() {
    this.showModalNotesPopup = false;
    this.isNoteLoader = false;
    this.customErrMsg = '';
    this.notesFormGroup.reset();
    this.resetFilterForm();
    this.handleClose.emit(true);
    this.alertService.clearAllAlerts();
  }
  redirectNote(activetab) {
    this.errorMsgValid = true;
    switch (activetab) {
      case 'latest_note':
        this.noteData = [];
    }
    this.notesFormGroup.reset();
    this.activeMenu = activetab;
  }

  async fetchNotesCategoryList() {
    return new Promise<any>(async (resolve, reject) => {
      await this.domainService.GetByDomainCode('NOTESSCOPETYPE').subscribe({
        next: (response) => {
          const notesCategory = response.data.map((item) => {
            return {
              value: item.description,
              key: item.description,
              id: item.id,
            };
          });
          this.notesCategory = [...this.notesCategory, ...notesCategory];
          this.categoryId = notesCategory[0].id;
          this.currentCategorySelected = {
            id: notesCategory[0].id,
            key: notesCategory[0].key,
            value: notesCategory[0].value,
          };
          resolve(true);
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            const errAlert = {
              show: true,
              type: 'error',
              headerText: 'common.errorHeader',
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(errAlert);
          }
          reject('true');
        },
      });
    });
  }
  async populateCurrentTagList() {
    await this.policyRiskService
      .GetAllByPolicyPeriodId(this.policyPeriodId)
      .subscribe((data) => {
        if (data?.data[0]) {
          this.policyRisks = data?.data;

          let trxTypes = this.policyRisks[0].PolicyRiskTrxes?.map(
            (dataObj) => ({
              key: dataObj.policyRiskTrxType.description,
              value: dataObj.policyRiskTrxType.description,
              id: dataObj.policyRiskTrxType.id,
            }),
          );
          trxTypes = removeDuplicatedKeys(trxTypes);

          this.currentTagOptions = trxTypes;
        }
      });
  }

  async handleNavClick(menu: string) {
    this.activeMenu = menu;
    this.alertService.clearAllAlerts();
    this.isNoteLoader = true;
    this.customErrMsg = '';
    this.notesFormGroup.reset();
    switch (menu) {
      case 'all_notes':
        this.filter = this.makeFilter();
        this.all = true;
        await Promise.all([
          await this.populateNotesUnderwriterOptions(),
          await this.loadNotesList(1, 10, this.filter, this.all, ''),
          await this.populateCurrentTagList(),
        ]);
        this.showFilter = true;
    }
    this.replyNotesFormGroup.reset();
    this.resetFilterForm();
    this.clearEdit();
    this.isNoteLoader = false;
    this.errorMsgValid = true;
  }

  clearEdit() {
    if (this.latestNote) {
      this.latestNote.edit = false;
    }
    if (this.noteData.length > 0) {
      this.noteData.forEach((x) => {
        x.edit = false;
      });
    }
  }

  async handleNewNoteAdd(e) {
    if (!this.hasPermissionForActions()) {
      const errAlert = {
        show: true,
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: 'workFlow3.components.notes.error.permissionValidationError',
      };
      this.alertService.addAlert(errAlert);
      return;
    }
    this.isNoteLoader = true;
    try {
      this.errorMsgValid = false;
      let newNote = this.notesFormGroup.value['note'];
      let scopeType = this.categoryId;
      let tag = this.trxTypeId;
      if (!this.checkNoteInputValid(newNote)) {
        this.errorMsgValid = true;
        this.isNoteLoader = false;
        return;
      }

      const note = await this.createNoteAPI(
        tag,
        newNote,
        this.policyPeriodId,
        scopeType,
      );
      if (note) {
        const successAlert = {
          show: true,
          type: 'success',
          headerText: getAlertHead('success'),
          bodyText: 'workFlow3.components.notes.success.createNoteSuccess',
        };
        this.alertService.addAlert(successAlert);
      }
      this.redirectNote('latest_note');
    } catch (error) {
      const errAlert = {
        show: true,
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: getErrorMessage(error),
      };
      this.alertService.addAlert(errAlert);
    }
    this.isNoteLoader = false;
  }

  checkNoteInputValid(newNote) {
    if (!newNote) {
      this.errorMsgValid = true;
      this.customErrMsg = 'error.thisFieldIsRequired';
      return false;
    } else if (newNote && newNote.trim() === '') {
      this.errorMsgValid = true;
      this.customErrMsg = 'common.invalidErrorMessage';
      return false;
    } else if (newNote.length > 500) {
      this.customErrMsg = 'error.maxLengthValidationNotesErrMsg';
      return false;
    }

    return true;
  }

  onKeyUpNoteValidation(event) {
    this.errorMsgValid = false;
    this.disableAddButton = null;
    if (
      this.notesFormGroup.value['note'] &&
      this.notesFormGroup.value['note'].trim() === ''
    ) {
      this.errorMsgValid = true;
      this.customErrMsg = 'common.invalidErrorMessage';
    } else if (this.notesFormGroup.value['note']?.length > 500) {
      this.errorMsgValid = true;
      this.customErrMsg = 'error.maxLengthValidationNotesErrMsg';
    } else if (!this.notesFormGroup.value['note']) {
      this.errorMsgValid = true;
    } else {
      this.customErrMsg = '';
      this.errorMsgValid = false;
    }
  }
  onKeyUpNoteReplyValidation(event) {
    this.errorMsgValid = false;
    this.disableAddButton = null;
    if (
      this.replyNotesFormGroup.value['note'] &&
      this.replyNotesFormGroup.value['note'].trim() === ''
    ) {
      this.errorMsgValid = true;
      this.customErrMsg = 'common.invalidErrorMessage';
    } else if (this.replyNotesFormGroup.value['note']?.length > 500) {
      this.errorMsgValid = true;
      this.customErrMsg = 'error.maxLengthValidationNotesErrMsg';
    } else if (!this.replyNotesFormGroup.value['note']) {
      this.errorMsgValid = true;
    } else {
      this.customErrMsg = '';
      this.errorMsgValid = false;
    }
  }

  async createNoteAPI(tag, newNote, policyPeriodId, scopeType) {
    let noteContent = newNote.trim();

    const createReq = {
      policyPeriodId: policyPeriodId,
      trxType: tag,
      scopeType: scopeType,
      note: checkRegexPatternNotes(noteContent),
    };
    const notesCreateObs = this.policyPeriodNotesService.Create(createReq);
    const policyNoteCreateData = await firstValueFrom(notesCreateObs);
    const note = {
      content: noteContent,
      writer: policyNoteCreateData?.data?.createdBy,
      id: policyNoteCreateData?.data.id,
      tag: policyNoteCreateData?.data?.TrxTypeStatus,
      scopeType: policyNoteCreateData?.data?.ScopeTypeStatus,
      date: formatDate(
        policyNoteCreateData?.data?.createdDt,
        this.longDateFormat,
      ),
      updatedDt: policyNoteCreateData?.updatedDt
        ? formatDate(policyNoteCreateData?.data?.updatedDt, this.longDateFormat)
        : '',
      replyCount: policyNoteCreateData?.data?.replyCount,
    };
    this.latestNote = policyNoteCreateData.data;
    this.filter = this.makeFilter(note.id);
    this.all = false;
    this.loadNotesList(1, 10, this.filter, this.all, '');
    return note;
  }

  handleCategoryValueChange(value) {
    this.categoryId = value;
  }

  handleCloseFilter() {
    this.showFilter = !this.showFilter;
    this.resetFilterForm();
  }

  async handleNotesFilter() {
    this.alertService.clearAllAlerts();
    this.isNoteLoader = true;
    if (!this.searchForm.valid) {
      this.validateAllFormFields(this.searchForm);
      this.isNoteLoader = false;
      return;
    }

    this.filter = this.makeFilter();
    this.all = true;
    await this.loadNotesList(1, 10, this.filter, this.all, '');
    this.alertService.clearAllAlerts();
  }

  formatDateOnly(date) {
    return moment(date, 'DD-MM-YYYY HH:mm:ss').format('YYYY/MM/DD');
  }

  handleKeywordFilter(e) {
    const filterKeyword = e.target.value.toLowerCase();
    this.notesSearchKeyWord = filterKeyword;
  }

  handleStartDateChange(date) {
    this.alertService.clearAllAlerts();
    this.searchForm.controls['fromDate'].setValue(date);
    this.fromDateSearchKeyWord = this.formatDateOnly(
      this.searchForm.controls['fromDate'].value,
    );
    const endDate = this.searchForm.controls['toDate'].value;
    if (endDate) {
      this.checkDateValidation();
    }
  }
  checkDateValidation() {
    if (this.dateRangeValidator(this.searchForm)) {
      this.invalidDateRange = false;
    } else {
      const errAlert = {
        show: true,
        type: 'error',
        headerText: 'common.errorHeader',
        bodyText: 'workFlow3.components.notes.error.dateRangeValidationError',
      };

      this.alertService.addAlert(errAlert);
      this.invalidDateRange = true;
    }
  }
  handleEndDateChange(date) {
    this.alertService.clearAllAlerts();
    this.searchForm.controls['toDate'].setValue(date);
    this.toDateSearchKeyWord = this.formatDateOnly(
      this.searchForm.controls['toDate'].value,
    );
    const startDate = this.searchForm.controls['fromDate'].value;
    if (startDate) {
      this.checkDateValidation();
    }
  }
  writerFilterHandler(value) {
    if (value === 'Select') {
      this.writerSearchKeyWord = null;
    } else {
      this.writerSearchKeyWord = Number(value);
    }
  }
  tagChangeHandler(value) {
    if (value === 'Select') {
      this.tagSearchKey = null;
    } else {
      this.tagSearchKey = Number(value);
    }
  }
  async populateNotesUnderwriterOptions() {
    return new Promise<any>(async (resolve, reject) => {
      await this.policyPeriodNotesService
        .getNotesUnderWriterList(this.policyPeriodId)
        .subscribe({
          next: (response) => {
            this.options = response.data?.map((options) => ({
              key:
                options?.boxxUser?.firstName +
                ' ' +
                options?.boxxUser?.lastName,
              value:
                options?.boxxUser?.firstName +
                ' ' +
                options?.boxxUser?.lastName,
              id: options?.id,
            }));
            this.options = removeDuplicatedKeys(this.options);
            this.options.unshift({ key: 'Select', value: 'Select', id: '' });

            this.notesFormGroup.controls['writerOptions']?.setValue(
              this.options,
            );
            resolve(true);
          },
          error: (error) => {
            reject(true);
          },
        });
    });
  }

  async resetFilterForm() {
    this.alertService.clearAllAlerts();
    this.isNoteLoader = true;
    this.searchForm.controls['tag'].setValue('');
    this.searchForm.reset();
    this.writerSearchKeyWord = null;
    this.fromDateSearchKeyWord = '';
    this.fromDateSearchKeyWord = '';
    this.tagSearchKey = '';
    this.notesSearchKeyWord = '';
    this.invalidDateRange = false;
    this.filter = this.makeFilter();
    this.all = true;
    await Promise.all([
      await this.loadNotesList(1, 10, this.filter, this.all, ''),
    ]);
  }

  async editNoteAPI(id, editNote: string, fromList) {
    try {
      let noteContent = editNote.trim();
      let index = this.noteData.findIndex((x) => x.id === id);
      const updateReq = {
        note: checkRegexPatternNotes(noteContent),
      };
      const notesUpdateObs = this.policyPeriodNotesService.Update(
        id,
        updateReq,
      );
      const policyNoteUpdateData = await firstValueFrom(notesUpdateObs);

      if (fromList) {
        this.noteData[index] = policyNoteUpdateData?.data;
        this.allNotesList[index] = policyNoteUpdateData?.data;
      } else {
        this.latestNote = policyNoteUpdateData?.data;
      }
      const successAlert = {
        show: true,
        type: 'success',
        headerText: 'common.successHeader',
        bodyText: 'workFlow3.components.notes.success.updateNoteSuccess',
      };
      this.alertService.addAlert(successAlert);
      this.isNoteLoader = false;
    } catch (error) {
      const errAlert = {
        show: true,
        type: 'error',
        headerText: 'common.errorHeader',
        bodyText: getErrorMessage(error),
      };

      this.alertService.addAlert(errAlert);
      this.isNoteLoader = false;
    }
  }
  async deleteNoteAPI(id, type, fromList) {
    this.isNoteLoader = true;
    try {
      let index = this.noteData.findIndex((x) => x.id === id);

      if (!this.noteParentId) {
        this.noteParentId = this.noteData[index]?.noteParentId ?? 0;
      }

      const notesDeleteObs = this.policyPeriodNotesService.Delete(id);
      const policyNoteUpdateData = await firstValueFrom(notesDeleteObs);
      if (type === 'note') {
        if (fromList) {
          this.filter = this.makeFilter();
          this.all = true;
          await this.loadNotesList(1, 10, this.filter, this.all, '');
        } else {
          this.handleNavClick('all_notes');
        }
      } else if (type === 'thread') {
        if (fromList) {
          this.filter = this.makeFilter(this.noteParentId);
          this.all = true;
          await this.loadNotesList(1, 10, this.filter, this.all, '');
        } else {
          this.handleNavClick('all_notes');
        }
      }
      const successAlert = {
        show: true,
        type: 'success',
        headerText: 'common.successHeader',
        bodyText: 'workFlow3.components.notes.success.deleteNoteSuccess',
      };
      this.alertService.addAlert(successAlert);
      this.isNoteLoader = false;
    } catch (error) {
      const errAlert = {
        show: true,
        type: 'error',
        headerText: 'common.errorHeader',
        bodyText: getErrorMessage(error),
      };

      this.alertService.addAlert(errAlert);
      this.isNoteLoader = false;
    }
  }
  addReplyNote(type, latestNote) {
    this.handleNewReplyAdd(
      latestNote,
      this.replyNotesFormGroup.controls['note'].value,
    );
  }

  async handleNewReplyAdd(parent, noteValue) {
    if (!this.hasPermissionForActions()) {
      const errAlert = {
        show: true,
        type: 'error',
        headerText: 'common.errorHeader',
        bodyText: 'workFlow3.components.notes.error.permissionValidationError',
      };
      this.alertService.addAlert(errAlert);
      return;
    }

    this.isNoteLoader = true;
    try {
      this.errorMsgValid = false;
      let newNote = noteValue;
      let scopeType = parent.scopeType;
      let tag = parent.trxType;
      let noteParentId = parent.id;
      if (!this.checkNoteInputValid(newNote)) {
        this.isNoteLoader = false;
        return;
      }

      const note = await this.createReplyAPI(
        tag,
        newNote,
        this.policyPeriodId,
        scopeType,
        noteParentId,
      );
      if (note) {
        const successAlert = {
          show: true,
          type: 'success',
          headerText: 'common.successHeader',
          bodyText: 'workFlow3.components.notes.success.createReplyNoteSuccess',
        };
        this.alertService.addAlert(successAlert);
        this.filter = this.makeFilter(noteParentId);
        this.all = true;
        await this.loadNotesList(1, 10, this.filter, this.all, '');
        this.errorMsgValid = true;
      }
      this.replyNotesFormGroup.reset();
    } catch (error) {
      const errAlert = {
        show: true,
        type: 'error',
        headerText: 'common.errorHeader',
        bodyText: getErrorMessage(error),
      };
      this.alertService.addAlert(errAlert);
    }
    this.isNoteLoader = false;
  }

  async createReplyAPI(tag, newNote, policyPeriodId, scopeType, noteParentId) {
    let noteContent = newNote.trim();

    const createReq = {
      policyPeriodId: policyPeriodId,
      trxType: tag,
      scopeType: scopeType,
      noteParentId: noteParentId,
      note: checkRegexPatternNotes(noteContent),
    };
    const notesCreateObs = this.policyPeriodNotesService.Create(createReq);
    const policyNoteCreateData = await firstValueFrom(notesCreateObs);
    const note = {
      content: noteContent,
      writer: policyNoteCreateData?.data?.createdBy,
      id: policyNoteCreateData?.data.id,
      tag: policyNoteCreateData?.data?.TrxTypeStatus,
      scopeType: policyNoteCreateData?.data?.ScopeTypeStatus,
      date: formatDate(
        policyNoteCreateData?.data?.createdDt,
        this.longDateFormat,
      ),
      noteParentId: policyNoteCreateData?.data?.noteParentId,
      updatedDt: policyNoteCreateData?.updatedDt
        ? formatDate(policyNoteCreateData?.data?.updatedDt, this.longDateFormat)
        : '',
      replyCount: policyNoteCreateData?.data?.replyCount,
    };
    this.latestCreatedReplyData(note);
    return note;
  }
  latestCreatedReplyData(note) {
    this.filter = this.makeFilter(note.noteParentId);
    this.all = false;
    this.loadNotesList(1, 10, this.filter, this.all, '');
  }

  async loadNotesList(pageNumber, limit, filter, all = true, sort = '') {
    this.closePopover();
    return new Promise<any>(async (resolve, reject) => {
      this.policyPeriodNotesService
        .GetAllNotesByFilter(filter, pageNumber, limit, sort)
        .subscribe(
          (data) => {
            this.currentPage = data.pagination.currentPage;
            this.totalPages = data.pagination.totalPages;
            this.totalCount = data.pagination.totalRecords;
            this.notesCount = data.pagination.totalRecords;
            if (this.currentPage === 1) {
              this.noteData = [...data.data];
            } else {
              this.noteData = [...this.noteData, ...data.data];
            }
            if (filter?.noteParentId) {
              this.latestNote['replyCount'] = this.totalCount;
            }

            if (all) {
              this.allCurrentPage = data.pagination.currentPage;
              this.allTotalPages = data.pagination.totalPages;
              this.allTotalCount = data.pagination.totalRecords;
              if (this.allCurrentPage === 1) {
                this.allNotesList = [...this.noteData];
              } else {
                this.allNotesList = [...this.allNotesList, ...this.noteData];
              }
            }
            this.isNoteLoader = false;
            resolve('true');
          },
          (error) => {
            const errAlert = {
              show: true,
              type: 'error',
              headerText: 'common.errorHeader',
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(errAlert);
            this.isNoteLoader = false;
            reject('true');
          },
        );
    });
  }

  makeFilter(notesParentId?: number) {
    const policyPeriodId = this.policyPeriodId;
    const trxType = this.tagSearchKey;
    const noteParentId = notesParentId ? notesParentId : 0;
    const quoteOptionId = this.quoteOptionId ?? 0;
    const note = this.notesSearchKeyWord;
    const active = 1;
    const createdBy = this.writerSearchKeyWord;
    const startDate = this.fromDateSearchKeyWord;
    const endDate = this.toDateSearchKeyWord;

    let filter = {
      policyPeriodId: policyPeriodId,
      trxType: trxType,
      noteParentId: noteParentId,
      quoteOptionId: quoteOptionId,
      note: note,
      active: active,
      createdBy: createdBy,
      startDate: startDate,
      endDate: endDate,
    };
    return filter;
  }
  cancelEditNote(type, item, fromList = false, event) {
    event.stopPropagation();
    switch (type) {
      case 'thread':
        if (fromList) {
          this.editReplyNotesFormGroup.controls['note'].setValue(item.note);
          let ind = this.noteData.findIndex((x) => x.id === item.id);
          this.noteData[ind].edit = false;
        } else {
          return;
        }
        break;
      case 'note':
        if (fromList) {
          this.editNotesFormGroup.controls['note'].setValue(item.note);
          let ind = this.noteData.findIndex((x) => x.id === item.id);
          this.noteData[ind].edit = false;
        } else {
          this.editNotesFormGroup.controls['note'].setValue(
            this.latestNote.note,
          );
          this.latestNote.edit = false;
        }
        break;
      default:
        return;
    }
  }
  saveEditNote(type, item, fromList = false, event) {
    if (!this.hasPermissionForActions()) {
      const errAlert = {
        show: true,
        type: 'error',
        headerText: 'common.errorHeader',
        bodyText: 'workFlow3.components.notes.error.permissionValidationError',
      };
      this.alertService.addAlert(errAlert);
      return;
    }
    this.isNoteLoader = true;

    event.stopPropagation();
    let note = '';
    switch (type) {
      case 'thread':
        if (fromList) {
          note = this.editReplyNotesFormGroup.controls['note'].value;
          let ind = this.noteData.findIndex((x) => x.id === item.id);
          this.noteData[ind].edit = false;
          this.editNoteAPI(item.id, note, fromList);
        } else {
          this.isNoteLoader = false;
          return;
        }
        break;
      case 'note':
        if (fromList) {
          note = this.editNotesFormGroup.controls['note'].value;
          let ind = this.noteData.findIndex((x) => x.id === item.id);
          this.noteData[ind].edit = false;
          this.editNoteAPI(item.id, note, fromList);
        } else {
          this.latestNote.edit = false;
          note = this.editNotesFormGroup.controls['note'].value;
          this.editNoteAPI(item.id, note, fromList);
        }
        break;
      default:
        this.isNoteLoader = false;
        return;
    }
  }
  formatDateForNotes(date) {
    return (
      getDateFormatForDatePicker(date, 'DD MMM') +
      ' at ' +
      formatDateTime(date, 'hh:mm A')
    );
  }
  autoResize() {
    if (this.textareas && this.textareas.length > 0) {
      this.textareas.forEach((textarea) => {
        textarea.nativeElement.style.overflow = 'hidden';

        textarea.nativeElement.style.height = 'auto';
        textarea.nativeElement.style.height =
          textarea.nativeElement.scrollHeight + 'px';
      });
    }
  }
  async noteRowClick(item) {
    this.isNoteLoader = true;
    this.activeMenu = 'note_detail';
    this.noteParentId = item.id;
    this.latestNote = item;
    this.filter = this.makeFilter(item.id);
    this.all = false;
    Promise.all([await this.loadNotesList(1, 100, this.filter, this.all, '')]);
    this.clearEdit();
    this.closePopover();
    this.isNoteLoader = false;
    this.errorMsgValid = true;
  }
  clickThreadList(type, item) {
    switch (type) {
      case 'thread':
        return;
        break;
      case 'note':
        this.noteRowClick(item);
        break;
      default:
        return;
    }
  }
  handleMenuItem(item, context, event) {
    event.stopPropagation();
    switch (item) {
      case 'edit':
        if (this.latestNote) {
          this.latestNote.edit = false;
        }
        if (this.noteData.length > 0) {
          this.noteData.forEach((x) => {
            x.edit = false;
          });
        }
        if (context.type === 'note') {
          if (context.fromList) {
            let ind = this.noteData.findIndex((x) => x.id === context.info.id);
            this.editNotesFormGroup.controls['note'].setValue(
              context.info.note,
            );
            this.noteData[ind].edit = true;
          } else {
            this.editNotesFormGroup.controls['note'].setValue(
              context.info.note,
            );
            this.latestNote.edit = true;
          }
        } else if (context.type === 'thread') {
          if (context.fromList) {
            let ind = this.noteData.findIndex((x) => x.id === context.info.id);
            this.editReplyNotesFormGroup.controls['note'].setValue(
              context.info.note,
            );
            this.noteData[ind].edit = true;
          } else {
            this.editReplyNotesFormGroup.controls['note'].setValue(
              context.info.note,
            );
            this.latestNote.edit = true;
            return;
          }
        }
        break;
      case 'reply':
        if (context.type === 'note') {
          if (context.fromList) {
            this.noteRowClick(context.info);
          } else {
            return;
          }
        }
        break;
      case 'delete':
        if (!this.hasPermissionForActions()) {
          const errAlert = {
            show: true,
            type: 'error',
            headerText: 'common.errorHeader',
            bodyText:
              'workFlow3.components.notes.error.permissionValidationError',
          };
          this.alertService.addAlert(errAlert);
          return;
        }
        this.deleteNoteAPI(context.info.id, context.type, context.fromList);
        break;
      default:
    }
  }

  onScroll(event: any, type): void {
    this.closePopover();
    const currentScrollPosition = event.target.scrollTop;
    const maxScrollPosition =
      event.target.scrollHeight - event.target.clientHeight;

    if (
      this.canLoadMoreOptions(currentScrollPosition, maxScrollPosition, type)
    ) {
      this.currentPage++;
      this.loadNotesList(this.currentPage, 10, this.filter, this.all, '');
    }
  }

  canLoadMoreOptions(
    currentScrollPosition: number,
    maxScrollPosition: number,
    type: string,
  ): boolean {
    if (type === 'thread') {
      return (
        currentScrollPosition >= maxScrollPosition &&
        this.noteData?.length <= this.totalCount &&
        this.currentPage <= this.totalPages
      );
    } else {
      return (
        currentScrollPosition >= maxScrollPosition &&
        this.allNotesList?.length <= this.totalCount &&
        this.currentPage <= this.totalPages
      );
    }
  }

  showPopover(
    event: MouseEvent,
    template: TemplateRef<any>,
    items: string[],
    context: any,
  ) {
    if (this.isNoteLoader) {
      return;
    }
    this.activeId = context.info.id;
    let target = event.target as HTMLElement;
    while (target && !target.classList.contains('showMenuOnHover')) {
      target = target.parentElement;
    }

    const buttonRect = target.getBoundingClientRect();
    this.menuItems = items;
    this.isPopoverOpen = true;
    this.menuContext = context;
    this.popoverPosition = this.calculatePopupPosition(buttonRect);

    this.viewContainerRef.clear();
    this.viewContainerRef.createEmbeddedView(template, {
      menuItems: this.menuItems,
      position: this.popoverPosition,
      context: this.menuContext,
    });
    clearTimeout(this.hideTimeout);
  }

  calculatePopupPosition(buttonRect: DOMRect) {
    const offsetX = 45;
    const offsetY = 0;
    let popupLeft = buttonRect.right - offsetX;

    let popupTop = buttonRect.top + offsetY;

    return { top: popupTop + 'px', left: popupLeft + 'px' };
  }
  closePopover(event?: MouseEvent) {
    this.hideTimeout = setTimeout(() => {
      this.isPopoverOpen = false;
      this.viewContainerRef.clear();
    }, 200);
  }
  hidePopover(event: MouseEvent, item) {
    if (this.activeId !== item) {
      this.activeId = null;
    }

    if (this.isPopoverOpen) {
      this.closePopover();
    }
  }

  cancelHide() {
    clearTimeout(this.hideTimeout);
  }

  getQuoteOption(id) {
    const quoteOptionNo =
      this.policyRisks[0].PolicyRiskTrxes[0].QuoteOptions.find(
        (x) => x.id === id,
      ).optionNumber;

    return 'Option ' + quoteOptionNo;
  }

  getEditNotesPermission(item) {
    const boxxUsrId = Number(localStorage.getItem('sid'));
    return Number(item.createdById) === boxxUsrId;
  }
  getDeleteNotesPermission(item) {
    return Number(item.replyCount) === 0;
  }
  getEditAndDeleteNotesPermission(item) {
    const boxxUsrId = Number(localStorage.getItem('sid'));
    return (
      Number(item.createdById) === boxxUsrId && Number(item.replyCount) === 0
    );
  }
}
