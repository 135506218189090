<div class="modal-outer" *ngIf="showModal">
  <div class="modal-container">
    <div class="box">
      <span style="--i: 1"></span>
      <span style="--i: 2"></span>
      <span style="--i: 3"></span>
      <span style="--i: 4"></span>
      <span style="--i: 5"></span>
      <span style="--i: 6"></span>
      <span style="--i: 7"></span>
      <span style="--i: 8"></span>
      <span style="--i: 9"></span>
      <span style="--i: 10"></span>
    </div>
    <section class="modal-header">
      <div class="header-left">
        <div class="warning-icon">
          <svg
            class="icon-svg"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
          >
            <g clip-path="url(#clip0_29700_3791)">
              <path
                d="M12.2478 9.50023V13.5002M12.2478 17.5002H12.2578M10.8631 4.39195L2.63823 18.5986C2.18203 19.3866 1.95393 19.7806 1.98764 20.1039C2.01705 20.386 2.16482 20.6423 2.39417 20.809C2.65713 21.0002 3.11239 21.0002 4.02292 21.0002H20.4727C21.3832 21.0002 21.8385 21.0002 22.1014 20.809C22.3308 20.6423 22.4786 20.386 22.508 20.1039C22.5417 19.7806 22.3136 19.3866 21.8574 18.5986L13.6325 4.39195C13.1779 3.60679 12.9506 3.21421 12.6541 3.08235C12.3954 2.96734 12.1002 2.96734 11.8415 3.08235C11.545 3.21421 11.3177 3.60679 10.8631 4.39195Z"
                stroke="#D46B00"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_29700_3791">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0.25 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <h2 class="head">
          {{
            "workFlow3.components.riskAnalysisPopup.label.riskAnalysisUpdateRequired"
              | translate
          }}
        </h2>
      </div>
      <div class="header-right">
        <boxxds-button-v2
          [isDisabled]="false"
          [size]="'lg'"
          [btnType]="'close'"
          (handleClick)="closeModal()"
        >
        </boxxds-button-v2>
      </div>
    </section>
    <section class="info-banner">
      <div class="info">
        <p class="info-header">
          {{ "workFlow3.components.shared.label.quote" | translate }}
        </p>
        <p class="info-value">{{ existingQuoteOptionData.quoteNumber }}</p>
      </div>
      <div class="info">
        <p class="info-header">
          {{ "workFlow3.components.shared.label.insured" | translate }}
        </p>
        <p class="info-value">{{ existingQuoteOptionData.insured }}</p>
      </div>
      <div class="info">
        <p class="info-header">
          {{ "workFlow3.components.shared.label.effectiveDate" | translate }}
        </p>
        <p class="info-value">
          {{
            isSubmissionFlow
              ? ("common.tbd" | translate)
              : isTbd
              ? ("common.tbd" | translate)
              : formatDate(existingQuoteOptionData.quotePeriod)
          }}
        </p>
      </div>
    </section>
    <section class="modal-content">
      <p class="paragh">
        {{ "workFlow3.components.riskAnalysisPopup.content" | translate }}
      </p>
    </section>
    <section class="modal-footer">
      <div class="footer-content">
        <boxxds-button-v2
          class="footer-button"
          [buttonText]="
            'workFlow3.components.shared.button.discardChanges' | translate
          "
          [isDisabled]="false"
          [size]="'lg'"
          [btnClass]="'tertiary gray'"
          [btnType]="'default'"
          (handleClick)="handleDiscardChanges()"
          [btnStyle]="{ width: '176px' }"
        >
        </boxxds-button-v2>
        <boxxds-button-v2
          class="footer-button"
          [buttonText]="
            'workFlow3.components.shared.button.saveContinue' | translate
          "
          [isDisabled]="false"
          [size]="'lg'"
          [btnClass]="'primary'"
          [btnType]="'default'"
          (handleClick)="handleSaveAndContinue()"
          [btnStyle]="{ width: '184px', marginLeft: '24px' }"
        >
        </boxxds-button-v2>
      </div>
    </section>
  </div>
</div>
