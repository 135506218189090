<div class="holder">
  <ng-container *ngIf="showModalSpinner">
    <div class="loader-wrapper">
      <div class="modal-body-spinner">
        <boxxds-spinner
          [containerStyle]="{
            width: '112px',
            height: '112px',
            '--b': '15px'
          }"
        ></boxxds-spinner>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!showModalSpinner">
    <div class="notes">
      <div class="notes-container" [ngStyle]="notesContainerStyle">
        <div
          class="note"
          *ngFor="
            let n of form.value['notes']
              | paginate
                : {
                    id: 'notes-pagination',
                    itemsPerPage: itemsPerPage,
                    currentPage:
                      form.value['notesPagination']?.pagination?.currentPage,
                    totalItems:
                      form.value['notesPagination']?.pagination?.totalRecords
                  };
            let i = index
          "
        >
          <div class="content">
            <ng-container>
              <div class="position-relative">
                <boxxds-body
                  *ngIf="currentEditNodeIdx !== i"
                  [text]="n.content"
                  sz="xs"
                  [customStyle]="{ color: '#56565C', marginBottom: '6px' }"
                >
                </boxxds-body>
                <!-- TO DO --update the below ngIf logic for unread -->
                <div class="ellipse" *ngIf="!n.read"></div>
              </div>

              <ng-container
                *ngIf="
                  this.permissionList[this.currentScreen] &&
                  currentEditNodeIdx === i
                "
              >
                <boxxds-textarea
                  (keyup)="onKeyUpNoteValidation($event)"
                  placeholder="Type here"
                  [form]="editForm"
                  [formSubmitted]="errorMsgUpdateNoteValid"
                  [customErrMsg]="customErrMsg | translate"
                  control="note"
                  [lblStyle]="{
                    color: '#8F8F91',
                    height: '20px',
                    marginBottom: '4px'
                  }"
                ></boxxds-textarea>
                <div class="d-flex justify-content-end">
                  <button class="btn-cancel" (click)="handleCancel()">
                    Cancel
                  </button>
                  <button class="btn-save" (click)="handleSave(i, $event)">
                    Save
                  </button>
                </div>
              </ng-container>
            </ng-container>
            <div class="properties" *ngIf="currentEditNodeIdx !== i">
              <boxxds-body
                [text]="
                  concat(
                    n.writer + (n.updatedDt ? ' (edited)' : ''),
                    n.product,
                    n.tag
                  )
                "
                sz="xxs"
                [customStyle]="{ color: '#BDBDBF', textTransform: 'uppercase' }"
              >
              </boxxds-body>
              <boxxds-body
                [text]="n.date"
                sz="xxs"
                [customStyle]="{
                  color: '#BDBDBF',
                  paddingRight: !doesHavePermissionForEditNotes(
                    n.writer,
                    n.writerPermission
                  )
                    ? '24px'
                    : '3px'
                }"
              >
              </boxxds-body>
            </div>
          </div>
          <div
            class="action"
            *ngIf="
              doesHavePermissionForEditNotes(n.writer, n.writerPermission) &&
              this.permissionList[this.currentScreen]
            "
          >
            <button
              class="ellipsis"
              (click)="handleEllipsisClick($event, i)"
              (clickOutside)="closeDropdown()"
            >
              <div class="ellipsis-container">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </button>
            <ul
              [ngStyle]="actionPopupStyle"
              class="dropdown-list"
              role="listbox"
              aria-expanded="false"
              [ngClass]="{ 'dropdown-open': dropdownOpenIdx === i }"
            >
              <li
                *ngFor="let o of dropdownOptions"
                id="dropdown-selected"
                tabindex="0"
                (click)="select(o, i)"
                role="option"
                [innerHTML]="o"
              ></li>
            </ul>
          </div>
        </div>
      </div>
      <!-- pagination -->
      <div class="notes-footer">
        <div class="total-count">
          {{ "policy.policySlideOut.totalRecordsFound" | translate }}:
          <span>
            {{
              form.value["notesPagination"]?.pagination?.totalRecords ??
                form.value?.notes?.length
            }}</span
          >
        </div>

        <div
          class="pagination-container"
          *ngIf="form.value['notesPagination']?.pagination?.totalPages > 1"
        >
          <pagination-template
            id="notes-pagination"
            #p="paginationApi"
            (pageChange)="PolicyRiskTrxIdNotesFilter($event)"
          >
            <ul class="tbl-pagination">
              <li class="tbl-pagination-prev tbl-page-item">
                <a
                  (keyup.enter)="p.previous()"
                  (click)="p.previous()"
                  [class.disabled]="p.isFirstPage()"
                  class="tbl-page-link"
                  tabindex="0"
                  aria-label="Previous"
                  >{{ "common.previous" | translate }}</a
                >
              </li>
              <li
                *ngFor="let page of p.pages"
                [class.active]="p.getCurrent() === page.value"
                class="tbl-page-item"
              >
                <a
                  (keyup.enter)="p.setCurrent(page.value)"
                  (click)="p.setCurrent(page.value)"
                  class="tbl-page-link"
                  tabindex="0"
                  aria-label="page.label"
                >
                  {{ page.label }}
                </a>
              </li>
              <li
                class="pagination-next tbl-page-item"
                [class.disabled]="p.isLastPage()"
              >
                <a
                  (keyup.enter)="p.next()"
                  *ngIf="!p.isLastPage()"
                  (click)="p.next()"
                  class="tbl-page-link"
                  tabindex="0"
                  >{{ "common.next" | translate }}</a
                >
              </li>
            </ul>
          </pagination-template>
        </div>
      </div>
      <ng-container *ngIf="this.permissionList[this.currentScreen]">
        <boxxds-textarea
          placeholder="Type here"
          [form]="form"
          [formSubmitted]="errorMsgValid"
          [customErrMsg]="customErrMsg | translate"
          [isDisabled]="currentEditNodeIdx !== -1"
          control="inputNotes"
          [lblStyle]="{ color: '#8F8F91', height: '20px', marginBottom: '4px' }"
          (handleKeyUp)="handleNewNoteINputChanged($event)"
        ></boxxds-textarea>
      </ng-container>

      <div
        class="d-flex mt-2 align-items-center"
        [ngClass]="showTag ? 'justify-content-between' : 'justify-content-end'"
      >
        <div *ngIf="showTag" class="d-flex align-items-center">
          <boxxds-body
            sz="sm"
            [text]="'Current tag:'"
            [customStyle]="{ color: '#8F8F91', marginRight: '10px' }"
          >
          </boxxds-body>
          <boxxds-dropdown
            [form]="form"
            [control]="'currentTag'"
            [insideSearch]="true"
            [options]="currentTagOptions"
            (currentValueChange)="
              this.permissionList[this.currentScreen] &&
                currentTagChanged($event)
            "
            [class.noPointerEvents]="!this.permissionList[this.currentScreen]"
          >
          </boxxds-dropdown>
        </div>
        <ng-container *ngIf="this.permissionList[this.currentScreen]">
          <button
            class="btn-save-note"
            *ngIf="currentEditNodeIdx === -1"
            [attr.disabled]="
              disableAddButton || form.value['inputNotes'].trim() !== ''
                ? null
                : ''
            "
            (click)="
              !disableAddButton &&
                form.value['inputNotes'] !== '' &&
                handleNewNoteAdd($event)
            "
          >
            {{ "policy.policySlideOut.addNote" | translate }}
          </button>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
