<div class="holder">
  <div class="d-flex flex-column align-items-center">
    <boxxds-heading
      [sz]="'4'"
      [text]="'COMING SOON'"
      type="bold"
      [customStyle]="{
        color: '#56565C',
        borderBottom: '11px solid #FFBE3D',
        lineHeight: '150%',
        maxWidth: '300px',
        marginTop: '100px'
      }"
    ></boxxds-heading>
    <img src="assets/img/coming-soon.png" alt="" height="400px" />

    <boxxds-body
      sz="md"
      [text]="
        'This module is currently under development. Please check back again later.'
      "
      [customStyle]="{ color: '#56565C' }"
    >
    </boxxds-body>
  </div>
</div>
