import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, retry, catchError } from 'rxjs';
import { BoxxResponse } from '../entities/boxx-response';

import { BaseService } from './base-services';
@Injectable({
  providedIn: 'root',
})
export class RiskTemplateService extends BaseService<any> {
  constructor(http: HttpClient) {
    const baseurl =
      environment.msProductriskBaseUrl + environment.riskTemplatePathUrl;
    super(http, baseurl);
  }
  getRiskTemplate(
    active: number | string,
    riskRegionId: number,
    templateType: number,
    templateDescription = '',
    limit = 10,
  ): Observable<any> {
    const url = `${this.baseurl}?active=${active}&riskRegionId=${riskRegionId}&templateType=${templateType}&templateDescription=${templateDescription}&limit=${limit}`;
    return this.http
      .get<BoxxResponse<any>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
