import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalAlertService } from 'src/app/services/modal-alert.service';

@Component({
  selector: 'app-slideout-popup-base',
  templateUrl: './slideout-popup-base.component.html',
  styleUrls: ['./slideout-popup-base.component.less'],
})
export class SlideoutPopupBaseComponent {
  @Input() isPopupOpen: boolean = false;
  @Input() isLoading: boolean = false;

  @Output() handleClose = new EventEmitter<any>();

  constructor(private alertService: ModalAlertService) {}

  handleCloseClick() {
    this.alertService.clearAllAlerts();
    this.handleClose.emit();
  }
}
