import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { BoxxResponse, Pagination } from 'src/app/entities/boxx-response';
import { SystemUser } from 'src/app/entities/system-user';
import { DomainsService } from 'src/app/services/domains.service';
import { SystemUserService } from 'src/app/services/system-user.service';
import { Domains } from 'src/app/models/domains';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { Store, select } from '@ngrx/store';
import { getErrorMessage } from 'src/app/utils/utils';
import {
  getDashboardSelector,
  getUserTypes,
} from 'src/app/store/dashboard/dashboard.selector';
import { SYSTEM_USER_TYPES } from 'src/app/constants/security/systemUserType';

@Component({
  selector: 'app-system-user-directory',
  templateUrl: './system-user-directory.component.html',
  styleUrls: ['./system-user-directory.component.less'],
})
export class SystemUserDirectoryComponent implements OnInit {
  form: FormGroup;
  showErrorAlert = false;
  msgErrorAlert = '';
  searchOptions: Array<any> = [];

  systemUserTypes: any = [];
  userTypes: any = [];

  data: [] = [];
  totalDataCount = 0;
  isSearchResult: boolean = false;
  addSortTo: string | Array<string> = ['Name', 'Type', 'Role', 'Status'];
  pagination: Pagination;

  sortBy = 'id:desc';

  selectedSystemUserType;
  searchWord = '';

  pageNumber = 1;
  rowsPerPage = 10;
  showTblSpinner: boolean = true;
  userID: number;
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public systemUserService: SystemUserService,
    public domainsService: DomainsService,
    private localStorageService: LocalStorageService,
    private store: Store,
  ) {
    this.form = fb.group({
      searchBy: new FormArray([]),
      searchKey: [''],
    });

    this.addSortTo = ['Type', 'Name', 'Role', 'Status'];
  }

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
    });

    this.userID = this.localStorageService.getBoxxUserId();
    this.showTblSpinner = true;
    this.store.pipe(select(getUserTypes)).subscribe(async (data) => {
      this.selectedSystemUserType = data.map((item) => item.id);
    });
    this.fetchSystemUserTypes();
  }

  fetchSystemUsers() {
    if (this.searchOptions.filter((s) => s.checked).length > 0) {
      this.showTblSpinner = true;
      this.systemUserService
        .GetSystemUsers(
          this.pageNumber,
          this.rowsPerPage,
          this.sortBy,
          this.selectedSystemUserType,
          this.searchWord,
        )
        .subscribe({
          next: (response) => {
            this.setSearchResult(response);
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    }
  }

  setSearchResult(data: BoxxResponse<SystemUser> | any) {
    this.data = [];
    if (this.searchOptions.filter((s) => s.checked).length == 0) {
      data = this.returnEmptyResponse();
    }
    const tableData = data.data?.map((dataObj) => {
      const obj = {
        Name: this.getName(dataObj),
        Type: this.getType(dataObj.SystemUserType),
        Role: dataObj.role.name,
        Status: dataObj.active ? 'Active' : 'Inactive',
      };
      if (
        this.permissionList[this.currentScreen] &&
        this.permissionList['System User']
      ) {
        obj['ActionView'] =
          '/dashboard/security/user/' +
          dataObj.id +
          '/type/' +
          dataObj.SystemUserType.id;
      }

      return obj;
    });

    this.data = tableData.length == 0 ? this.getTableHeaders() : tableData;
    this.totalDataCount = data.pagination.totalRecords;
    this.pagination = data.pagination;
    this.showTblSpinner = false;
  }

  getTableHeaders() {
    return [{ Name: '', Type: '', Role: '', Status: '' }];
  }

  fetchSystemUserTypes() {
    this.showTblSpinner = true;
    this.domainsService.GetByDomainCode('SYSTEMUSERTYPE').subscribe({
      next: (response) => {
        this.setSystemUserTypesResult(response);
        this.fetchSystemUsers();
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });
  }

  setSystemUserTypesResult(data: BoxxResponse<Domains>) {
    this.showTblSpinner = true;
    const tableData = data.data?.map((dataObj) => ({
      key: `${dataObj.description}`,
      value: `${dataObj.id}`,
    }));

    this.systemUserTypes = [...tableData];

    const searchOptions = data.data?.map((dataObj) => ({
      name: `${dataObj.description}`,
      value: `${dataObj.id}`,
      checked: true,
      id: `${dataObj.id}`,
    }));

    this.searchOptions = [...searchOptions];
  }

  isSearchInpReadOnly() {
    const isAnyCheckboxChecked = this.searchOptions.findIndex(
      (obj) => obj.checked === true,
    );
    return isAnyCheckboxChecked === -1;
  }

  getType(systemUserType) {
    let type: string = '';
    systemUserType = systemUserType.id ?? systemUserType;
    type = this.systemUserTypes.find(
      (item) => Number(item.value) === Number(systemUserType),
    )?.key;
    return type;
  }

  getName(user): string {
    let name: string = '';
    this.systemUserTypes.find(
      (item) => Number(item.value) === user.SystemUserType.id,
    )?.key;
    let filteredUser = this.systemUserTypes.filter(
      (item) => Number(item.value) === user.SystemUserType.id,
    );
    let userType = this.convertToCamelCase(filteredUser[0].key.toLowerCase());
    if (userType === SYSTEM_USER_TYPES.Insured.toLowerCase()) {
      return (name = this.getInsuredName(user[userType]));
    } else if (
      userType.toLowerCase() === SYSTEM_USER_TYPES.ServiceProvider.toLowerCase()
    ) {
      return (name = this.getServiceProviderName(user[userType]));
    } else {
      return (name = this.getFullName(user[userType]));
    }
  }

  convertToCamelCase(userType) {
    return userType
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, '');
  }

  getFullName(role): string {
    if (role !== null) {
      return role.firstName + ' ' + role.lastName;
    }
    return '';
  }
  getInsuredName(role): string {
    if (role !== null) {
      return role.insuredType === 1
        ? role.firstName + ' ' + role.lastName
        : role.companyName;
    }
    return '';
  }
  getServiceProviderName(userType): string {
    if (userType !== null) {
      return userType?.username;
    }
    return '';
  }

  dropdownValueChanged(event) {}

  isChecked(option) {
    return option.checked === true;
  }

  getOptionVal(option) {
    return option.value;
  }

  handleCheckbox(index, options) {
    this.showTblSpinner = true;
    options[index].checked = !options[index].checked;

    const searchKey = this.form.value['searchKey'];
    this.pageNumber = 1; // resetting page number when search changes
    this.selectedSystemUserType = options
      .filter(this.isChecked)
      .map(this.getOptionVal)
      .join(',');

    if (this.searchOptions.filter((s) => s.checked).length > 0) {
      this.systemUserService
        .GetSystemUsers(
          this.pageNumber,
          this.rowsPerPage,
          this.sortBy,
          this.selectedSystemUserType,
          searchKey,
        )
        .subscribe({
          next: (response) => {
            this.showTblSpinner = false;
            this.setSearchResult(response);
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    } else {
      this.showTblSpinner = false;
      this.setSearchResult(this.returnEmptyResponse());
    }
  }

  returnEmptyResponse() {
    return {
      status: 'OPERATION SUCCESS',
      code: 200,
      data: [],
      pagination: {
        currentPage: 1,
        totalPages: 1,
        totalRecords: 0,
        hasNext: false,
        hasPrevious: false,
      },
    };
  }

  sanitizeSearchInput(userInput: string): string {
    // Define the list of special characters that you want to remove
    const specialCharacters = /[\^\$\\*+?.`/~()!=|[\]{}%#]/g;

    // Remove special characters and underscore from the user input
    const sanitizedInput = userInput
      .replace(specialCharacters, '')
      .replace(/^[ _]+/, '');

    // Return the sanitized input
    return sanitizedInput;
  }

  handleSearch(event) {
    if (event.key == 'Enter') {
      // TODO - call API for search results
      this.isSearchResult = true;
      this.showTblSpinner = true;
      this.addSortTo = ['Type', 'Name', 'Role', 'Status'];

      this.searchWord = this.form.value['searchKey'];
      this.pageNumber = 1; // resetting page number when search changes

      this.selectedSystemUserType = this.searchOptions
        .filter(this.isChecked)
        .map(this.getOptionVal)
        .join(',');

      this.systemUserService
        .GetSystemUsers(
          this.pageNumber,
          this.rowsPerPage,
          this.sortBy,
          this.selectedSystemUserType,
          this.searchWord,
        )
        .subscribe({
          next: (response) => {
            this.showTblSpinner = false;
            this.setSearchResult(response);
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    } else {
      let sanitizedtext = this.sanitizeSearchInput(
        this.form.value['searchKey'],
      );
      this.form.patchValue({
        searchKey: sanitizedtext,
      });
    }
  }

  handlePageChange(pageNumber) {
    this.showTblSpinner = true;
    this.selectedSystemUserType = this.searchOptions
      .filter(this.isChecked)
      .map(this.getOptionVal)
      .join(',');

    if (pageNumber > 0) {
      this.pageNumber = pageNumber;
      this.systemUserService
        .GetSystemUsers(
          pageNumber,
          this.rowsPerPage,
          this.sortBy,
          this.selectedSystemUserType,
          this.searchWord,
        )
        .subscribe({
          next: (response) => {
            this.showTblSpinner = false;
            this.setSearchResult(response);
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    }
  }

  statusActionBtnClicked(e) {
    this.router.navigateByUrl(
      `/dashboard/system-user-directory/new?display=${e}`,
      { skipLocationChange: true },
    );
  }

  mapSortColumns(key) {
    let sortColumn;
    switch (key) {
      case 'Name':
        sortColumn = 'name';
        break;

      case 'Type':
        sortColumn = 'SystemUserType.description';
        break;

      case 'Role':
        sortColumn = 'role.name';
        break;

      case 'Status':
        sortColumn = 'active';
        break;

      default:
        sortColumn = 'id';
        break;
    }
    return sortColumn;
  }

  handleAscSort(key) {
    const sortBy = this.mapSortColumns(key);
    this.sortBy = sortBy + ':asc';
    this.fetchSystemUsers();
  }

  handleDescSort(key) {
    const sortBy = this.mapSortColumns(key);
    this.sortBy = sortBy + ':desc';
    this.fetchSystemUsers();
  }

  handleCloseSuccessEvent() {
    this.showErrorAlert = false;
  }
}
