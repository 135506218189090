import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Role } from '../entities/role';
import { BaseService } from './base-services';
import { Observable, throwError } from 'rxjs';
import { BoxxResponse } from '../entities/boxx-response';
import { catchError, retry } from 'rxjs/operators';
import { CognitoService } from './cognito-service';

@Injectable({
  providedIn: 'root',
})
export class RoleService extends BaseService<Role> {
  constructor(
    http: HttpClient,
    private cognito: CognitoService,
  ) {
    const baseurl = environment.msSecurityBseUrl + environment.rolePathUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  CreateRole(data): Observable<BoxxResponse<Role>> {
    let url = `${this.baseurl}`;
    return this.http.post<BoxxResponse<Role>>(url, data, this.httpOptions);
  }

  // get all roles
  GetAllRoles(
    userCount = 0,
    page = 1,
    limit = 10,
    sort = '',
  ): Observable<BoxxResponse<Role>> {
    let url =
      this.baseurl +
      '?page=' +
      page +
      '&limit=' +
      limit +
      '&sort=' +
      sort +
      '&active=1';
    if (userCount) {
      url += '&userCount=' + 1;
    }
    return this.http
      .get<BoxxResponse<Role>>(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  UpdateRole(roleId, data): Observable<BoxxResponse<Role>> {
    let url = `${this.baseurl}${roleId}`;
    return this.http.put<BoxxResponse<Role>>(url, data, this.httpOptions);
  }

  GetByNameNsystemUserType(
    name = '',
    systemUserType = null,
    userCount = 0,
    page = 1,
    limit = 10,
    sort = '',
  ): Observable<BoxxResponse<Role>> {
    let url = this.baseurl + '?page=' + page;

    if (name) {
      url += '&name=' + name;
    }

    if (systemUserType) {
      url += '&systemUserType=' + systemUserType;
    }

    if (userCount) {
      url += '&userCount=' + 1;
    }

    url += '&limit=' + limit + '&sort=' + sort;

    return this.http
      .get<BoxxResponse<Role>>(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetRole(roleId): Observable<BoxxResponse<Role>> {
    let url = `${this.baseurl}${roleId}`;
    return this.http.get<BoxxResponse<Role>>(url, this.httpOptions);
  }
}
