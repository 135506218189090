<div class="holder">
  <boxxds-breadcrumb [items]="itemsMenu"></boxxds-breadcrumb>
  <boxxds-alert
    *ngIf="showErrorAlert"
    (handleCloseEvent)="handleCloseSuccessEventExt()"
    type="error"
    [headerText]="'common.errorMsg' | translate"
    [bodyText]="msgErrorAlert"
  >
  </boxxds-alert>
  <boxxds-heading sz="5" [ngStyle]="{ marginBottom: '50px' }" [text]="userName">
  </boxxds-heading>
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!showBodySpinner">
    <boxxds-body
      sz="lg"
      [text]="'Select system user type' | translate"
      [customStyle]="{
        color: '#616162',
        textAlign: 'left',
        marginBottom: '8px',
        fontSize: '14px',
        marginTop: '50px'
      }"
    ></boxxds-body>
    <boxxds-dropdown
      [insideSearch]="false"
      [options]="systemUserTypes"
      placeholder=""
      (currentValueChange)="systemUserTypeChanged($event)"
      [dropdownContainerStyle]="{ width: '176px' }"
      [currentValue]="currentSystemUserType"
      [isReadonly]="
        !this.permissionList[this.currentScreen] || isActive === true
      "
      [disabled]="
        !this.permissionList[this.currentScreen] || isActive === false
      "
    >
    </boxxds-dropdown>

    <app-edit-system-boxx-user
      *ngIf="showBoxxUser"
      [systemUserType]="systemUserTypeId"
      (updateTitleEvent)="updateUserName($event)"
      (updateStatusEvent)="updateUserStatus($event)"
    ></app-edit-system-boxx-user>
    <app-edit-system-producer-user
      *ngIf="showProducer"
      [systemUserType]="systemUserTypeId"
      (updateTitleEvent)="updateUserName($event)"
      (updateStatusEvent)="updateUserStatus($event)"
    ></app-edit-system-producer-user>
    <app-edit-system-insured-user
      *ngIf="showInsured"
      [systemUserType]="systemUserTypeId"
      (updateTitleEvent)="updateUserName($event)"
      (updateStatusEvent)="updateUserStatus($event)"
    ></app-edit-system-insured-user>
  </ng-container>
</div>
