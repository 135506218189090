<div class="custom-modal" *ngIf="showModal">
  <div class="modal-content">
    <ng-container *ngIf="showBodySpinner">
      <div class="body-spinner">
        <boxxds-spinner
          [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
        ></boxxds-spinner>
      </div>
    </ng-container>
    <ng-container *ngIf="!showBodySpinner">
      <div class="close-btn">
        <button (click)="closeHandler()">
          <img src="assets/img/dashboard/card/close.png" />{{
            "common.close" | translate
          }}
        </button>
      </div>
      <div class="header-content">
        <boxxds-alert
          (handleCloseEvent)="handleNotificationAlertClose()"
          *ngIf="notificationAlert?.show"
          [type]="notificationAlert?.type"
          [headerText]="notificationAlert?.headerText | translate"
          [bodyText]="notificationAlert?.bodyText | translate"
          [containerStyle]="{
            left: '50%',
            transform: 'translateX(-50%)',
            top: '10px'
          }"
        >
        </boxxds-alert>
        <boxxds-heading
          [sz]="'6'"
          [text]="title"
          type="bold"
          [customStyle]="{
            color: '#2D2D39',
            fontWeight: 400
          }"
        ></boxxds-heading>
        <boxxds-body
          [text]="insuredDetals"
          class="subtitle"
          [customStyle]="{
            padding: '10px 0',
            color: '#56565C',
            fontWeight: 400
          }"
        ></boxxds-body>
      </div>
      <div class="quote-option-container">
        <div class="quote-option-header">
          {{
            ("quoteCalculator.label.quoteOption" | translate) +
              " " +
              productDetails?.optionNumber +
              ":"
          }}
        </div>
        <div class="quote-option-content">
          <div class="mr-4">
            <boxxds-dropdown
              *ngIf="!showQuoteOptionLimitCustomField"
              [label]="'quoteCalculator.label.limit' | translate"
              [placeholder]="'common.select' | translate"
              [options]="limitOptionsForProducts[productDetails.key]"
              [invalidErrMsg]="showInvalidLimitErrMsg"
              [dropdownContainerStyle]="{ width: '200px' }"
              (currentValueChange)="
                this.permissionList[this.currentScreen] &&
                  this.currentQuoteStatus !== 'quote-closed' &&
                  quoteOptionLimitChange($event, title)
              "
              [currentValue]="
                productDetails?.limit
                  | currencyFormat: currency : 'symbol-narrow' : '1.0-0'
              "
              [isReadonly]="
                !this.permissionList[this.currentScreen] ||
                this.currentQuoteStatus === 'quote-closed'
              "
              [class.noPointerEvents]="
                !this.permissionList[this.currentScreen] ||
                this.currentQuoteStatus === 'quote-closed'
              "
            >
            </boxxds-dropdown>

            <div class="custom-value" *ngIf="showQuoteOptionLimitCustomField">
              <label class="text-input-label">{{
                "quoteCalculator.label.limit" | translate
              }}</label>
              <input
                type="text"
                mask="separator.0"
                thousandSeparator=","
                prefix="$"
                (blur)="handleQuoteOptionLimitCustomValue($event, title)"
                maxlength="14"
                [autofocus]="true"
              />
            </div>
          </div>
          <div>
            <boxxds-dropdown
              *ngIf="!showQuoteOptionDeductibleCustomField"
              [label]="'quoteCalculator.label.deductibles' | translate"
              [placeholder]="'common.select' | translate"
              [options]="deductibleOptions"
              [dropdownContainerStyle]="{ width: '200px' }"
              (currentValueChange)="
                this.permissionList[this.currentScreen] &&
                  this.currentQuoteStatus !== 'quote-closed' &&
                  handleDeductibleChange($event, productDetails?.value)
              "
              [invalidErrMsg]="showInvalidDeductibleErrMsg"
              [currentValue]="
                productDetails?.deductables
                  | currencyFormat: currency : 'symbol-narrow' : '1.0-0'
              "
              [isReadonly]="
                !this.permissionList[this.currentScreen] ||
                this.currentQuoteStatus === 'quote-closed'
              "
              [class.noPointerEvents]="
                !this.permissionList[this.currentScreen] ||
                this.currentQuoteStatus === 'quote-closed'
              "
            >
            </boxxds-dropdown>

            <div
              class="custom-value"
              *ngIf="showQuoteOptionDeductibleCustomField"
            >
              <label class="text-input-label">{{
                "quoteCalculator.label.deductibles" | translate
              }}</label>
              <input
                type="text"
                mask="separator.0"
                thousandSeparator=","
                prefix="$"
                maxlength="14"
                (blur)="
                  handleDeductibleCustValue($event, productDetails?.value)
                "
                [autofocus]="true"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="nav nav-tabs">
        <ul class="products">
          <li
            *ngFor="let coverage of coverageSublimits; let i = index"
            (click)="setCurrentTabActive(i)"
            class="product"
          >
            <div
              class="tab-item"
              [ngClass]="{
                active: coverageActiveIdx === i,
                included: coverage.coverage?.coverageIncluded
              }"
            >
              <div class="d-flex align-items-center">
                <div
                  class="coverage-name"
                  [title]="coverage.coverage?.coverageName"
                >
                  {{ coverage.coverage?.coverageName }}
                </div>
                <div
                  *ngIf="!coverage.coverage?.questionnaireCategoryName"
                  class="circle-flag"
                ></div>
                <img
                  *ngIf="coverage.coverage?.questionnaireCategoryName"
                  src="assets/img/exclamation-circle.png"
                  alt=""
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
      <ng-container
        *ngFor="let data of coverageSublimits; let coverageSublimitIdx = index"
      >
        <div *ngIf="coverageSublimitIdx === coverageActiveIdx">
          <div class="body-content">
            <div
              class="tab-body-header"
              [ngClass]="{
                active: coverageActiveIdx === coverageSublimitIdx,
                editable: coverageEditable
              }"
            >
              <div class="sublimit-toggle-div">
                <div
                  class="coverage-item"
                  [ngClass]="{
                    active: coverageEditable
                  }"
                >
                  <button
                    class="toggle"
                    tab-index="0"
                    type="button"
                    [disabled]="
                      !this.permissionList[this.currentScreen] ||
                      this.currentQuoteStatus === 'quote-closed'
                    "
                    [class.noPointerEvents]="
                      !this.permissionList[this.currentScreen] ||
                      this.currentQuoteStatus === 'quote-closed'
                    "
                  >
                    <span
                      class="toggle-menu"
                      [ngClass]="{ active: data?.coverage?.coverageIncluded }"
                      value="true"
                      (click)="
                        this.permissionList[this.currentScreen] &&
                          this.currentQuoteStatus !== 'quote-closed' &&
                          !data?.coverage?.coverageReadOnly &&
                          onToggleClick(data?.coverage, true, data?.coverage)
                      "
                      id="coverageItem.id"
                      >{{
                        data?.coverage?.coverageIncluded
                          ? "Included"
                          : ("Include" | translate)
                      }}</span
                    >
                    <span
                      class="toggle-menu"
                      [ngClass]="{ active: !data?.coverage?.coverageIncluded }"
                      value="false"
                      (click)="
                        this.permissionList[this.currentScreen] &&
                          this.currentQuoteStatus !== 'quote-closed' &&
                          !data?.coverage?.coverageReadOnly &&
                          onToggleClick(data?.coverage, false, data?.coverage)
                      "
                      id="coverageItem.id"
                      >{{
                        data?.coverage?.coverageIncluded
                          ? "Exclude"
                          : ("Excluded" | translate)
                      }}</span
                    >
                  </button>
                </div>
              </div>

              <div
                class="d-flex mr-5"
                *ngIf="
                  !data?.coverage?.coverageIncluded &&
                  data?.coverage?.questionnaireCategoryName
                "
              >
                <div class="navigation-info">
                  <div class="nav-info-text">
                    <img src="assets/img/exclamation-circle.png" alt="" />
                    <span>{{
                      "quoteCalculator.info.navQuestionnaire" | translate
                    }}</span>
                  </div>
                  <boxxds-button
                    (handleClick)="handleNavigation()"
                    [buttonText]="
                      'quoteCalculator.label.completeQuestionnaire' | translate
                    "
                    [type]="'button'"
                    btnType="secondary"
                    [ngStyle]="{ 'margin-top': '30px' }"
                    [btnStyle]="{
                      marginTop: '24px',
                      marginLeft: 'auto',
                      marginBottom: '24px'
                    }"
                  >
                  </boxxds-button>
                </div>
              </div>

              <div
                class="sublimit-inputs-div"
                *ngIf="data?.coverage?.coverageIncluded"
              >
                <ng-container>
                  <div [ngClass]="{ 'btn-container': coverageEditable }">
                    <div
                      class="sublimit-inputs"
                      [ngClass]="{ editCoverage: coverageEditable }"
                    >
                      <boxxds-textinput
                        *ngIf="!coverageEditable"
                        [placeholder]="
                          'quoteCalculator.modal.sublimitAmount' | translate
                        "
                        [control]="'sublimitAmount'"
                        [id]="'sublimitAmount'"
                        [width]="'100%'"
                        minLength="3"
                        [label]="
                          ('quoteCalculator.modal.sublimitAmount' | translate) +
                          '*'
                        "
                        maxLength="200"
                        [form]="form"
                        [isDisabled]="!coverageEditable"
                      >
                      </boxxds-textinput>
                      <boxxds-dropdown
                        *ngIf="
                          coverageEditable && !showCoverageLimitCustomField
                        "
                        [currentValue]="
                          form.value.sublimitAmount
                            | currencyFormat
                              : currency
                              : 'symbol-narrow'
                              : '1.0-0'
                        "
                        [placeholder]="'common.select' | translate"
                        [options]="
                          data?.coverage?.coverageName
                            ?.toLowerCase()
                            ?.includes(financialCoverageMatchString)
                            ? sublimitOptionsForFinancialCoverage
                            : limitOptionsForProducts[productDetails.key]
                        "
                        [label]="
                          ('quoteCalculator.modal.sublimitAmount' | translate) +
                          '*'
                        "
                        [control]="'sublimitAmount'"
                        [dropdownContainerStyle]="{ maxWidth: '250px' }"
                        class="input-fields"
                        (currentValueChange)="
                          this.permissionList[this.currentScreen] &&
                            this.currentQuoteStatus !== 'quote-closed' &&
                            handleCoverageLimitOnChange($event)
                        "
                        [isReadonly]="
                          !this.permissionList[this.currentScreen] ||
                          this.currentQuoteStatus === 'quote-closed'
                        "
                        [class.noPointerEvents]="
                          !this.permissionList[this.currentScreen] ||
                          this.currentQuoteStatus === 'quote-closed'
                        "
                        [disabled]="
                          data?.coverage?.coverageName
                            ?.toLowerCase()
                            ?.includes(financialCoverageMatchString) &&
                          setMinDeductibleValidation === true &&
                          getNumber(form.value.retentionAmount) <
                            financialCoverageRuleMinDeductible
                        "
                      >
                      </boxxds-dropdown>

                      <div
                        *ngIf="coverageEditable && showCoverageLimitCustomField"
                      >
                        <label class="custom-value-label">{{
                          "quoteCalculator.modal.sublimitAmount" | translate
                        }}</label>
                        <input
                          type="text"
                          class="custom-value"
                          mask="separator.0"
                          thousandSeparator=","
                          prefix="$"
                          (blur)="handleLimitCustomValueClick($event)"
                          maxlength="14"
                          [autofocus]="true"
                        />
                      </div>

                      <boxxds-textinput
                        *ngIf="!coverageEditable"
                        [placeholder]="
                          'quoteCalculator.modal.deductiblesAmount' | translate
                        "
                        [control]="'retentionAmount'"
                        [id]="'retentionAmount'"
                        [width]="'100%'"
                        minLength="3"
                        [label]="
                          ('quoteCalculator.modal.deductiblesAmount'
                            | translate) + '*'
                        "
                        maxLength="250"
                        [form]="form"
                        [isDisabled]="
                          !coverageEditable ||
                          !this.permissionList[this.currentScreen] ||
                          this.currentQuoteStatus === 'quote-closed'
                        "
                      >
                      </boxxds-textinput>
                      <boxxds-dropdown
                        *ngIf="coverageEditable && !showDeductibleCustomField"
                        [currentValue]="
                          form.value.retentionAmount
                            | currencyFormat
                              : currency
                              : 'symbol-narrow'
                              : '1.0-0'
                        "
                        [placeholder]="'common.select' | translate"
                        [options]="coverageDeductibles"
                        [label]="
                          ('quoteCalculator.modal.deductiblesAmount'
                            | translate) + '*'
                        "
                        (currentValueChange)="
                          this.permissionList[this.currentScreen] &&
                            this.currentQuoteStatus !== 'quote-closed' &&
                            handleCoverageDeductableOnChange(
                              $event,
                              data?.coverage?.coverageName
                                ?.toLowerCase()
                                ?.includes(financialCoverageMatchString)
                            )
                        "
                        [control]="'retentionAmount'"
                        [dropdownContainerStyle]="{ maxWidth: '250px' }"
                        class="input-fields retentionAmount-field"
                        [isReadonly]="
                          !this.permissionList[this.currentScreen] ||
                          this.currentQuoteStatus === 'quote-closed'
                        "
                        [class.noPointerEvents]="
                          !this.permissionList[this.currentScreen] ||
                          this.currentQuoteStatus === 'quote-closed'
                        "
                      >
                      </boxxds-dropdown>

                      <div
                        *ngIf="coverageEditable && showDeductibleCustomField"
                        class="retentionAmount-field"
                      >
                        <label class="custom-value-label">{{
                          "quoteCalculator.modal.deductiblesAmount" | translate
                        }}</label>
                        <input
                          type="text"
                          class="custom-value retentionAmount-field"
                          mask="separator.0"
                          thousandSeparator=","
                          prefix="$"
                          (blur)="
                            handleRetentionCustomValueClick(
                              $event,
                              data?.coverage?.coverageName
                                ?.toLowerCase()
                                ?.includes(financialCoverageMatchString)
                            )
                          "
                          maxlength="14"
                          [autofocus]="true"
                        />
                      </div>

                      <ng-container *ngIf="coverageEditable"> </ng-container>
                    </div>
                    <div
                      class="d-flex flex-column edit-icons"
                      *ngIf="
                        coverageEditable &&
                        this.permissionList[this.currentScreen] &&
                        this.currentQuoteStatus !== 'quote-closed'
                      "
                    >
                      <button
                        class="icon-button mb-2"
                        (click)="saveCoverageData(coverageSublimitIdx)"
                      >
                        <img src="assets/img/scroll-table/tick.png" />
                      </button>
                      <button class="icon-button mt-2" (click)="handleCancel()">
                        <img src="assets/img/scroll-table/cancel.png" />
                      </button>
                    </div>
                  </div>
                  <ng-container
                    *ngIf="
                      !data.coverage?.coverageReadOnly &&
                      !isAllSublimitDisabled(data.coverage?.coverageId)
                    "
                  >
                    <ng-container
                      *ngIf="
                        this.permissionList[this.currentScreen] &&
                        this.currentQuoteStatus !== 'quote-closed'
                      "
                    >
                      <button
                        class="sublimit-icon"
                        *ngIf="!coverageEditable"
                        (click)="onCoverageEdit(data?.sublimit[0])"
                      >
                        <img src="assets/img/edit.png" alt="edit" />
                      </button>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
          <div
            class="sublimit-tbl-container"
            *ngIf="data?.coverage?.coverageIncluded"
          >
            <app-scrollable-table
              *ngIf="data?.sublimit?.length > 0"
              [limitOptionsForProducts]="
                limitOptionsForProducts[productDetails?.key]
              "
              [sublimitOptionsForFinancialCoverage]="
                sublimitOptionsForFinancialCoverage
              "
              [occurrenceTypeDropdownValues]="occurrenceTypeDropdownValues"
              [coverageDeductibles]="coverageDeductibles"
              [measuresDropdownValues]="measuresDropdownValues"
              [showAsReadOnly]="data?.coverage?.coverageReadOnly"
              [currentQuoteOptionDeductible]="currentQuoteOptionDeductible"
              [currency]="currency"
              [currentCoverageName]="data?.coverage?.coverageName"
              (handleRowEditSave)="
                this.permissionList[this.currentScreen] &&
                  this.currentQuoteStatus !== 'quote-closed' &&
                  handleSublimitRowSave($event, coverageSublimitIdx)
              "
              [data]="data?.sublimit"
              [form]="sublimitForm"
              [product]="productDetails?.key"
              [quoteOptionLimit]="quoteOptionLimit || quoteOptionCustomLimit"
              [quoteOptionDeductible]="
                quoteOptionDeductible || quoteOptionCustomDeductible
              "
              (disableSaveAndContinue)="disableSaveAndContinue()"
              (enableSaveAndContinue)="enableSaveAndContinue()"
            ></app-scrollable-table>
          </div>
        </div>
        <ng-container
          *ngIf="
            this.permissionList[this.currentScreen] &&
            this.currentQuoteStatus !== 'quote-closed'
          "
        >
          <div
            class="recalc-holder"
            *ngIf="coverageSublimitIdx === coverageActiveIdx"
          >
            <div
              class="d-flex"
              [ngClass]="{ excluded: !data.coverage?.coverageIncluded }"
            >
              <a
                class="reset-form-link"
                [ngClass]="{ 'link-disabled': isButtonDisabled }"
                href="javascript:;"
                (click)="handleReset()"
              >
                {{ "quoteCalculator.modal.discardChanges" | translate }}
              </a>

              <boxxds-button
                [buttonText]="'common.saveAndClose' | translate"
                [type]="'submitted'"
                [btnStyle]="{
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  marginLeft: '24px',
                  marginRight: '41px',
                  border: '1px solid #D6D6D9'
                }"
                (handleClick)="handleRecalculate()"
                [isDisabled]="isButtonDisabled"
              >
              </boxxds-button>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <div class="info-text">
        <img src="assets/img/exclamation-circle.png" alt="" />
        <span>coverage includes a questionnaire. </span>
      </div>
    </ng-container>
  </div>
</div>
