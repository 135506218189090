import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { take } from 'rxjs';
import { Store, select } from '@ngrx/store';

import {
  addSpaceBeforeCapitalLetter,
  convertStatusType,
} from 'src/app/dashboard/utils/lifecycle-utils';
import { PolicyRiskTrxService } from 'src/app/services/policy-risk-trx.service';
import { ConfCurrencyService } from 'src/app/services/conf-currency.service';
import { getLifecycleState } from 'src/app/store/lifecycle/lifecycle.select';
import { formatDate, formatDateMoment } from 'src/app/utils/formatDate';
import { getAlertHead, getErrorMessage } from 'src/app/utils/utils';
import { PolicyRiskTrxNotesService } from 'src/app/services/policy-risk-trx-notes-service';
import { ConfRiskLimitsService } from 'src/app/services/conf-risk-limits.service';
import { formatNumber, removeDuplicatedKeys } from 'src/app/utils/utils';
import { InsuredService } from 'src/app/services/insured.service';
import { DomainsService } from 'src/app/services/domains.service';
import { ConfDeductibleService } from 'src/app/services/conf-deductible.service';
import { PolicyRiskDocService } from 'src/app/services/policy-risk-doc.service';
import { PolicyQuoteAuditService } from 'src/app/services/policy-quote-audit.service';
import { Pagination } from 'src/app/entities/boxx-response';
import { PolicyRiskDocPreviewService } from 'src/app/services/policy-risk-doc-preview.service';
import { isRead } from 'src/app/utils/notesUtils';
import { PolicyDetail } from 'src/app/dashboard/policy-lifecycle/objects/policy-lifecycle-objects';
import { PolicyPeriodService } from 'src/app/services/policy-period.service';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { PolicyRiskDocUploadService } from 'src/app/services/policy-risk-doc-upload.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alert.service';
import { PolicyRiskTrxReasons } from 'src/app/services/policy-risk-trx-reasons.service';

@Component({
  selector: 'app-quote-slideout',
  templateUrl: './quote-slideout.component.html',
  styleUrls: ['./quote-slideout.component.less'],
})
export class QuoteSlideoutComponent implements OnInit, AfterContentChecked {
  @ViewChild('fileDropRef') fileDropRef!: ElementRef<HTMLInputElement>;
  private policyRiskTrxId: number;
  private insuredId: number;
  policyRiskId: number;
  activityData: { pagination: Pagination | null } = {
    pagination: null,
  };

  showTabLoader: boolean = false;
  showModalSpinner: boolean = true;
  showDocPreview = false;
  currentPreviewDocUrl = '';
  policyNumber: string;
  status: string;
  riskId: number;
  form: FormGroup;
  policyId: number;
  brokerCommissionPerc = -1;
  reasonsLoaded: boolean = false;
  isPopoverOpen = false;
  showImagePreview = false;

  @Input() details!: PolicyDetail;
  @Input() showModal: boolean = false;

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleSave = new EventEmitter<any>();
  @Output() handleDownload = new EventEmitter<any>();
  tableHeaders = [
    'common.fileName',
    'common.dateUploaded',
    'common.uploadedBy',
    '',
  ];
  subNavData = [
    { name: 'common.formDetails', value: 'form', active: true },
    { name: 'common.policyDocuments', value: 'documents', active: false },
    { name: 'common.history', value: 'history', active: false },
    { name: 'common.notes', value: 'notes', active: false },
  ];
  regionId = null;
  options = [{ key: '', value: '', id: '' }];
  currencyOptions = [{ key: '', value: '' }];
  limitOptions = [{ key: '', value: '' }];
  deductibleOptions = [{ key: '', value: '' }];
  files: any[] = [];
  notes: any;
  pastPolicies = [];
  auditDetails = [];
  totalCount: number = 1;
  currentPage: number = 1;
  itemsPerPage = 10;
  totalPages: number = 1;
  brokerageCommissionMax = 20;
  brokerageCommissionMin = 0;
  brokerageCommissionText;
  writerOptions: [];
  filterData: any;
  notesData: { pagination: Pagination | null; filterData: {} } = {
    pagination: null,
    filterData: {},
  };
  notesSearchKeyWord = '';
  dateSearchKeyWord = '';
  fromDateSearchKeyWord;
  toDateSearchKeyWord;
  writerSearchKeyWord: number;
  minLengthErrMsg: string = '';
  isEndDateValid: boolean = true;
  isStartDateValid: boolean = true;
  endDateErrMsg = '';
  startDateErrMsg = '';
  isNoteFormValid: boolean = false;
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  currentQuoteStatus: string = '';
  formData: FormData;
  riskTrxReasons: any;
  docStageId = undefined;
  allowedFormats = [
    '.jpg',
    '.jpeg',
    '.gif',
    '.txt',
    '.doc',
    '.docx',
    '.pdf',
    '.pptx',
  ];
  maxFileSizeMB = 5;
  shortDateFormat: string = '';
  longDateFormat: string = '';

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private policyRiskTrxService: PolicyRiskTrxService,
    private confCurrencyService: ConfCurrencyService,
    private policyRiskTrxNotesService: PolicyRiskTrxNotesService,
    private confRiskLimitsService: ConfRiskLimitsService,
    private insuredService: InsuredService,
    private domainsService: DomainsService,
    private confDeductibleService: ConfDeductibleService,
    private policyRiskDocService: PolicyRiskDocService,
    private policyQuoteAuditService: PolicyQuoteAuditService,
    private PolicyRiskDocPreviewService: PolicyRiskDocPreviewService,
    private policyPeriodService: PolicyPeriodService,
    private policyRiskDocUploadService: PolicyRiskDocUploadService,
    private translate: TranslateService,
    private alertService: AlertService,
    private PolicyRiskTrxReasons: PolicyRiskTrxReasons,
  ) {
    this.form = this.fb.group({
      notesSearchForm: this.fb.group({
        noteKeyWordSearch: [''],
        noteFromDateSearch: [''],
        noteToDateSearch: [''],
        noteWriterSearch: [''],
      }),
      inputNotes: ['', [Validators.maxLength(500)]],
      writerOptions: [],
      notes: [''], //Notes tab
      notesPagination: [],
      effectiveDate: ['', Validators.required],
      issuanceDate: [''],
      invoiceNumber: [''],
      currency: ['', Validators.required],
      limit: ['', Validators.required],
      aggregatedLimit: ['', Validators.required],
      deductible: ['', Validators.required],
      premium: ['', Validators.required],
      calcPremium: [''],
      taxes: [''],
      brokerCommission: [''],
      brokerCommissionPerc: [''],
      note: [''],
      tag: [''],
    });
  }

  get notesSearchFormCntrl() {
    return (<FormGroup>this.form.get('notesSearchForm')).controls;
  }
  get notesSearchForm() {
    return this.form.get('notesSearchForm') as FormGroup;
  }

  async ngOnInit(): Promise<void> {
    this.showModalSpinner = true;

    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });

    this.currentPage = this.activityData?.pagination?.currentPage || 1;

    this.store
      .select(getLifecycleState)
      .pipe(take(1))
      .subscribe((state) => {
        this.currentQuoteStatus = state.quoteStatus;
        this.policyRiskTrxId = state?.policyRiskTrxId;
        this.status = state?.policyRiskTrxStatus;
        this.insuredId = state?.insuredId;
        this.riskId = state?.riskId;
      });

    await Promise.all([
      await this.populateFormDetails(),
      await this.populateReferalReasons(),
    ]);
    this.showModalSpinner = false;
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
    this.options = this.form.value['writerOptions'];
  }

  hasInvoiceNumber() {
    return (
      this.form.controls['invoiceNumber'].value &&
      this.form.controls['invoiceNumber'].value !== ''
    );
  }

  private populateFormDetails() {
    if (!this.policyRiskTrxId) {
      this.showTabLoader = false;
      return;
    }
    this.details.policyRiskTrxId = this.policyRiskTrxId;
    this.policyRiskTrxService
      .getByPolicyRiskTrxId(this.policyRiskTrxId)
      .subscribe({
        next: (response) => {
          const data = response.data;
          this.policyId = data?.policyRisk?.policyPeriod?.policy?.id;
          const status = data?.quoteStatus ? data.quoteStatus : data.type;

          data?.transactionEffectiveDate
            ? this.form.controls['effectiveDate'].setValue(
                this.formatDate(data?.transactionEffectiveDate),
              )
            : '';

          data?.issuanceDate
            ? this.form.controls['issuanceDate'].setValue(
                this.formatDate(data?.issuanceDate),
              )
            : '';

          this.form.controls['invoiceNumber'].setValue(
            data?.invoiceNumber ?? '',
          );
          this.form.controls['currency'].setValue(data?.currency?.name);
          this.form.controls['limit'].setValue(data?.limit);
          this.form.controls['aggregatedLimit'].setValue(data?.aggregatedLimit);
          this.form.controls['deductible'].setValue(data?.deductible);
          this.form.controls['premium'].setValue(data?.premium);
          this.form.controls['calcPremium'].setValue(data?.premiumCalculated);
          this.form.controls['taxes'].setValue(data?.taxesPremiumAmount);
          this.form.controls['brokerCommission'].setValue(
            data?.brokerCommission,
          );
          if (data?.brokerCommissionPerc) {
            const brokerCommissionPercs = (
              parseFloat(data?.brokerCommissionPerc) * 100
            ).toFixed(2);
            this.form.controls['brokerCommissionPerc'].setValue(
              Number(brokerCommissionPercs),
            );

            this.brokerCommissionPerc = Number(brokerCommissionPercs);
          }
          this.form.controls['tag'].setValue(data?.type);
          this.form.controls['note'].setValue(data?.notes);
          this.policyNumber = this.details.id;
          this.status = addSpaceBeforeCapitalLetter(status);
          this.policyRiskId = data?.policyRisk?.id;
          this.showTabLoader = false;
        },
        error: (error) => {
          this.showTabLoader = false;
        },
      });
  }
  async populateReferalReasons() {
    return new Promise<any>(async (resolve, reject) => {
      if (!this.policyRiskTrxId) {
        return;
      }
      try {
        this.reasonsLoaded = false;
        this.PolicyRiskTrxReasons.getPolicyRiskTrxReasons(
          this.policyRiskTrxId,
        ).subscribe({
          next: async (reasonsData) => {
            const quoteOptionReasons = reasonsData?.data;

            if (quoteOptionReasons?.length > 0) {
              this.riskTrxReasons = quoteOptionReasons?.map((reason) =>
                reason?.comments
                  ? reason?.comments
                  : reason?.reasonCodeDto?.description,
              );
              this.reasonsLoaded = true;
            }
            resolve('true');
          },
          error: (error) => {
            reject(getErrorMessage(error));
          },
        });
        this.reasonsLoaded = true;
      } catch (error) {
        this.reasonsLoaded = true;
      }
    });
  }

  openPopover() {
    this.populateReferalReasons();
    this.isPopoverOpen = true;
  }
  closePopover() {
    this.isPopoverOpen = false;
  }

  async getStageId() {
    this.docStageId = undefined;
    return new Promise<void>((resolve) => {
      this.domainsService.GetByDomainCode('PROCESSINGSTATUS', true).subscribe({
        next: (response) => {
          let docStage = response.data.filter(
            (template) =>
              template.subdomaincode === 'PROCESSINGSTATUS_COMPLETE',
          )[0];
          this.docStageId = docStage.id;
          resolve();
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
          }
        },
      });
    });
  }

  async populateDocuments() {
    if (!this.policyRiskId) {
      this.showTabLoader = false;
      return;
    }
    await this.getStageId();
    this.policyRiskDocService
      .getPolicyRiskDocCore(this.policyRiskId, this.docStageId)
      .subscribe((docData) => {
        this.files = docData?.data.map((doc) => ({
          id: doc.id,
          name: this.getFileName(doc.documentPath),
          path: doc.documentPath,
          dateUploaded: formatDate(doc.createdDt, this.longDateFormat),
          uploadedBy: doc.createdBy || '',
        }));
        this.showTabLoader = false;
      });
  }
  toggleDocPreview() {
    this.showDocPreview = !this.showDocPreview;
  }
  handleDocDownload(obj: any) {
    this.showTabLoader = true;
    const documentId = obj?.id;
    try {
      if (documentId) {
        this.PolicyRiskDocPreviewService.GetPolicyRiskDocToPreview(
          documentId,
          'document',
        ).subscribe({
          next: (documentData) => {
            if (documentData?.data) {
              const base64String = documentData?.data?.content;
              this.currentPreviewDocUrl = base64String;
              this.toggleDocPreview();
              this.showTabLoader = false;
            } else {
              this.showDocPreview = false;
              this.currentPreviewDocUrl = '';
              this.showTabLoader = false;
            }
          },
          error: (error) => {
            this.currentPreviewDocUrl = '';
            this.showTabLoader = false;
            if (![500].includes(error?.status)) {
              const alertData = {
                show: true,
                type: 'error',
                headerText: 'error!',
                bodyText: getErrorMessage(error),
                wrapperStyle: { margin: '30px 0px 0px -75px' },
              };
              this.alertService.addAlert(alertData);
            }
          },
        });
      } else {
        this.showDocPreview = false;
        this.currentPreviewDocUrl = '';
        this.showTabLoader = false;
      }
    } catch (error) {
      if (![500].includes(error?.status)) {
        const alertData = {
          show: true,
          type: 'error',
          headerText: 'error!',
          bodyText: error?.message ?? '',
          wrapperStyle: { margin: '30px 0px 0px -75px' },
        };
        this.alertService.addAlert(alertData);
      }
      this.currentPreviewDocUrl = '';
      this.showTabLoader = false;
    }
  }

  getFileName(path) {
    const pathSplit = path.split('/');
    return pathSplit[pathSplit.length - 1];
  }

  populateDropdownOptions() {
    if (!this.insuredId) {
      return;
    }

    this.insuredService
      .GetByInsuredId(this.insuredId)
      .subscribe((insuredData) => {
        const regionId = insuredData?.data.insuredLocation?.regionId;
        this.populateLimitOptions(regionId);
        this.populateDeductibleOptions(regionId);
      });
  }

  private populateLimitOptions(regionId) {
    this.confRiskLimitsService
      .GetByRegionIdAndRiskIds(regionId, this.riskId)
      .subscribe((data) => {
        const limitList = data.data?.map((dataObj) => ({
          key: formatNumber(Number(dataObj.maxLimit), 0),
          value: formatNumber(Number(dataObj.maxLimit), 0),
          id: dataObj.id,
        }));

        this.domainsService.GetByDomainCode('CONFLIMITS').subscribe((data) => {
          const limitListFromDomain = data.data.map((dataObj) => ({
            key: formatNumber(Number(dataObj.valueInt1), 0),
            value: formatNumber(Number(dataObj.valueInt1), 0),
            id: dataObj.id,
          }));
          const uniquelimitOptions = removeDuplicatedKeys(limitList);
          this.limitOptions = [...uniquelimitOptions, ...limitListFromDomain];
          limitList.push({ key: 'Custom amount', value: 'custom-amount' });
        });
      });
  }

  populateDeductibleOptions(regionId) {
    this.confDeductibleService
      .GetByRegionIdAndRiskIds(regionId, this.riskId)
      .subscribe((data) => {
        const deductibleOptns = data.data?.map((dataObj) => ({
          key: formatNumber(Number(dataObj.value), 0),
          value: formatNumber(Number(dataObj.value), 0),
          id: dataObj.id,
        }));

        deductibleOptns.push({ key: 'Custom amount', value: 'custom-amount' });
        this.deductibleOptions = removeDuplicatedKeys(deductibleOptns);
      });
  }

  private populatePastPolicies() {
    if (!this.policyId) {
      return;
    }

    this.policyPeriodService
      .GetAllByPolicyId(this.policyId)
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.pastPolicies = response.data?.map((policy) => ({
            policyNumber: this.policyNumber,
            period:
              formatDateMoment(policy?.effectiveDt, this.shortDateFormat) +
              ' - ' +
              formatDateMoment(policy?.expiryDt, this.shortDateFormat),
          }));
          this.showTabLoader = false;
        },
        error: (error) => {
          this.showTabLoader = false;
        },
      });
  }

  private populateCurrencies() {
    // To DO - save currencies in store
    this.confCurrencyService.GetAllCurrencies().subscribe({
      next: (response) => {
        this.currencyOptions = response?.data?.map((currencyObj) => ({
          key: currencyObj.name,
          value: currencyObj.name,
        }));
      },
      error: (error) => {},
    });
  }

  // temp fn to handle date formats from "07-04-2021 01:04:25"
  formatDate(date) {
    if (date && date.split(' ').length > 1) {
      return moment(date, 'DD-MM-YYYY HH:mm:ss').format(this.shortDateFormat);
    }
    return moment(date).toDate();
  }
  formatDateOnly(date) {
    return moment(date, 'DD-MM-YYYY HH:mm:ss').format('YYYY/MM/DD');
  }

  formatPerc(value) {
    return Number(value) * 100 + '%';
  }

  isActive(index) {
    return this.subNavData[index].active;
  }

  saveHandler() {
    this.handleSave.emit();
  }

  handleNav(event, index) {
    this.alertService.clearAlerts(-1);
    this.showTabLoader = true;
    const currentActiveIdx = this.subNavData.findIndex(
      (navObj) => navObj.active,
    );
    this.subNavData[currentActiveIdx].active = false;
    this.subNavData[index].active = true;
    // TO DO - API call for different quote status selection
    Promise.all([this.callApiBasedOnTab(this.subNavData[index].value)]);
  }
  callApiBasedOnTab(tabName: string) {
    switch (tabName.toLowerCase()) {
      case 'form':
        this.populateFormDetails();
        break;
      case 'documents':
        this.populateDocuments();

        break;
      case 'history':
        this.populatePastPolicies();
        this.populateActivityLog(this.currentPage);

        break;
      case 'notes':
        this.populateNotesUnderwriterOptions();
        this.filterNotesList(this.currentPage);

        break;
      default:
        break;
    }
  }
  handleTransactionEffDate(e) {}

  handleKeywordFilter(e) {
    const filterKeyword = e.target.value.toLowerCase();
    this.notesSearchKeyWord = filterKeyword;
    if (e.target.value.length < 3) {
      this.notesSearchKeyWord = '';
      this.handleCheckNoteFormValid();
      this.minLengthErrMsg = 'error.minThreeLengthsearchErrMsg';
      return this.form.controls['notesSearchForm']
        .get('noteKeyWordSearch')
        .setErrors({ pattern: true });
    }
    this.handleCheckNoteFormValid();
  }

  handleDateFilter(date) {
    if (date) {
      date = formatDate(date, this.longDateFormat);
      const filteredNotes = this.notes.filter(
        (note) => new Date(note.date)?.getTime() == new Date(date)?.getTime(),
      );
      this.form.controls['notes'].setValue(filteredNotes);
    } else {
      this.form.controls['notes'].setValue(this.notes);
    }
  }

  updateNotes(newNote) {
    this.populateNotesUnderwriterOptions();
    this.filterNotesList(this.currentPage);
    this.resetFilterForm();
  }
  writerFilterHandler(value) {
    if (value === 'Select') {
      this.writerSearchKeyWord = null;
    } else {
      this.writerSearchKeyWord = Number(
        this.options
          .filter(
            (option) => option.value.toLowerCase() === value.toLowerCase(),
          )
          .map((option) => option.id),
      );
    }
    this.handleCheckNoteFormValid();
  }

  currencyValueChanged(e) {}
  limitHandler(e) {}
  aggregatedLimitHandler(e) {}
  deductibleHandler(e) {}

  handleCloseClk() {
    this.showModal = false;
    this.alertService.clearAlerts(-1);
    this.handleClose.emit();
  }

  onFileDropped(event) {
    this.prepareFilesList(event);
  }

  fileBrowseHandler(e) {
    let files: any = e.target.files;
    this.prepareFilesList(files);
  }

  deleteFile(index: number, fileID) {
    this.policyRiskDocService.deletePolicyRiskDocCore(fileID, 1).subscribe({
      next: (response) => {
        this.files.splice(index, 1);
        const alertData = {
          show: true,
          type: 'success',
          headerText: 'success!',
          bodyText: 'policy.document.delete.success.body',
          wrapperStyle: { margin: '30px 0px 0px -75px' },
        };
        this.alertService.addAlert(alertData);
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          const alertData = {
            show: true,
            type: 'error',
            headerText: 'error!',
            bodyText: getErrorMessage(error),
            wrapperStyle: { margin: '30px 0px 0px -75px' },
          };
          this.alertService.addAlert(alertData);
        }
      },
    });
  }

  prepareFilesList(files: Array<any>) {
    try {
      for (const item of files) {
        this.showModalSpinner = true;
        const fileSizeMB = item.size / (1024 * 1024);
        if (fileSizeMB <= this.maxFileSizeMB) {
          const fileExtension = this.getFileExtension(item);
          if (this.allowedFormats.includes(fileExtension.toLowerCase())) {
            this.formData = new FormData();
            this.formData.append('file', item, item.name);
            this.formData.append('policyRiskId', this.policyRiskId.toString());
            this.policyRiskDocUploadService
              .uploadPolicyRiskDoc(this.formData)
              .subscribe({
                next: (response) => {
                  const alertData = {
                    show: true,
                    type: 'success',
                    headerText: 'success!',
                    bodyText: 'policy.document.upload.success.body',
                    wrapperStyle: { margin: '30px 0px 0px -75px' },
                  };
                  this.alertService.addAlert(alertData);
                  this.showModalSpinner = false;
                  this.clearFileInput();
                  this.populateDocuments();
                },
                error: (error) => {
                  let errMsg: string = '';
                  if (typeof error === 'string') {
                    if (
                      error.includes(
                        this.translate.instant(
                          'policy.document.upload.error.responseText',
                        ),
                      )
                    ) {
                      errMsg = this.translate.instant(
                        'policy.document.upload.error.filesize',
                      );
                    }
                  }
                  const alertData = {
                    show: true,
                    type: 'error',
                    headerText: 'error!',
                    bodyText: errMsg ? errMsg : getErrorMessage(error),
                    wrapperStyle: { margin: '30px 0px 0px -75px' },
                  };
                  this.alertService.addAlert(alertData);
                  this.showModalSpinner = false;
                  this.clearFileInput();
                },
              });
          } else {
            throw new Error('policy.document.upload.error.fileExtension');
          }
        } else {
          throw new Error('policy.document.upload.error.filesize');
        }
      }
    } catch (error) {
      this.showModalSpinner = false;
      const alertData = {
        show: true,
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: getErrorMessage(error),
        wrapperStyle: { margin: '30px 0px 0px -75px' },
      };
      this.alertService.addAlert(alertData);
    }
  }

  clearFileInput() {
    if (this.fileDropRef && this.fileDropRef.nativeElement)
      this.fileDropRef.nativeElement.value = '';
  }
  getFileExtension(file: File): string {
    const filename = file.name;
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1) {
      return '';
    }
    return filename.substr(lastDotIndex).toLowerCase();
  }
  checkIfStartDateValid(date, endDate) {
    if (!date) {
      this.resetStartDateControls();
    } else if (isNaN(date.getTime())) {
      this.resetStartDateControls();
    } else if (
      this.formatDateOnly(endDate) &&
      this.formatDateOnly(date) > this.formatDateOnly(endDate)
    ) {
      this.handleInvalidStartDate('error.minStartDateError');
    } else {
      this.setValidStartDate(date);
    }
  }

  checkIfEndDateValid(date, startDate) {
    if (!date) {
      this.resetEndDateControls();
    } else if (isNaN(date.getTime())) {
      this.resetEndDateControls();
    } else if (
      this.formatDateOnly(startDate) &&
      this.formatDateOnly(date) < this.formatDateOnly(startDate)
    ) {
      this.handleInvalidEndDate('error.minEndDateError');
    } else {
      this.setValidEndDate(date);
    }
  }

  getConnectionString(table, type, user) {
    switch (type) {
      case 'create':
        return table + ' Created by ' + user;
      case 'update':
        return table + ' Updated by ' + user;
      case 'delete':
        return table + ' Deleted by ' + user;
      default:
        return '';
    }
  }

  handleBrokerCommision() {
    // this.resetPremiumOnValChange(pdt);
    // this.data.products.filter(p => p.value == pdt)[0].brokerCommissions = value;
  }

  getBrokerageCommission() {
    return 0;
    // return this.data.products.length > 0 ? this.data.products?.filter(p => p.value == pdt)[0]?.brokerCommissions : null;
  }

  isCalcBtnEnabled() {
    return true;
  }

  handlePremiumCalc() {}

  getStatusType(status) {
    return convertStatusType(status);
  }

  resetStartDateControls() {
    this.form.controls['notesSearchForm']
      .get('noteFromDateSearch')
      .setValue('');
    this.fromDateSearchKeyWord = '';
    this.isStartDateValid = true;
    this.startDateErrMsg = null;
  }
  handleInvalidStartDate(errorMessage) {
    this.isStartDateValid = false;
    this.startDateErrMsg = errorMessage;
  }

  setValidStartDate(date) {
    this.isStartDateValid = true;
    this.startDateErrMsg = null;
    this.isEndDateValid = true;
    this.endDateErrMsg = null;
    this.fromDateSearchKeyWord = this.formatDateOnly(date);
  }

  resetEndDateControls() {
    this.form.controls['notesSearchForm'].get('noteToDateSearch').setValue('');
    this.toDateSearchKeyWord = '';
    this.isEndDateValid = true;
    this.startDateErrMsg = null;
  }

  handleInvalidEndDate(errorMessage) {
    this.isEndDateValid = false;
    this.endDateErrMsg = errorMessage;
  }

  setValidEndDate(date) {
    this.isStartDateValid = true;
    this.startDateErrMsg = null;
    this.isEndDateValid = true;
    this.endDateErrMsg = null;
    this.toDateSearchKeyWord = this.formatDateOnly(date);
  }

  populateActivityLog(pageNumber: number) {
    if (!this.policyRiskTrxId) {
      this.showTabLoader = false;
      return;
    }
    this.policyQuoteAuditService
      .getPolicyQuoteAuditData(
        ['PolicyPeriod', 'PolicyRisk', 'PolicyRiskTrx'],
        this.policyRiskTrxId,
        pageNumber,
        this.itemsPerPage,
      )
      .subscribe({
        next: (response) => {
          ({ pagination: this.activityData.pagination } = response);
          this.auditDetails = response.data?.map((audit) => ({
            user: audit?.createdBy,
            type: audit?.auditType,
            changeFrom: audit?.auditChangeFrom,
            changeTo: audit?.auditChangeTo,
            date: formatDate(audit?.createdDt, this.longDateFormat),
            table: audit?.auditTable,
            tableDomain: audit?.auditTableDomain,
          }));
          this.showTabLoader = false;
        },
        error: (error) => {
          this.showTabLoader = false;
        },
      });

    this.totalPages = this.activityData.pagination?.totalPages;
    this.currentPage = pageNumber;
    this.totalCount = this.activityData.pagination?.totalRecords;
  }

  populateNotesUnderwriterOptions() {
    if (!this.policyRiskTrxId) {
      this.showTabLoader = false;
      return;
    }
    this.policyRiskTrxNotesService
      .getNotesUnderWriterList(this.policyRiskTrxId)
      .subscribe({
        next: (response) => {
          this.options = response.data?.map((options) => ({
            key:
              options?.boxxUser?.firstName + ' ' + options?.boxxUser?.lastName,
            value:
              options?.boxxUser?.firstName + ' ' + options?.boxxUser?.lastName,
            id: options?.id,
          }));
          this.options = removeDuplicatedKeys(this.options);
          this.options.unshift({ key: 'Select', value: 'Select', id: '' });

          this.form.controls['writerOptions']?.setValue(this.options);
          this.showTabLoader = false;
        },
        error: (error) => {
          this.showTabLoader = false;
        },
      });
  }

  handleStartDateChange(date, endDate) {
    this.form.controls['notesSearchForm']
      .get('noteFromDateSearch')
      .setValue(date);
    this.fromDateSearchKeyWord = this.formatDateOnly(
      this.form.controls['notesSearchForm'].get('noteFromDateSearch').value,
    );
    this.checkIfStartDateValid(date, endDate);
    this.handleCheckNoteFormValid();
  }

  handleEndDateChange(date, startDate) {
    this.form.controls['notesSearchForm']
      .get('noteToDateSearch')
      .setValue(date);
    this.toDateSearchKeyWord = this.formatDateOnly(
      this.form.controls['notesSearchForm'].get('noteToDateSearch').value,
    );
    this.checkIfEndDateValid(date, startDate);
    this.handleCheckNoteFormValid();
  }

  filterNotesList(pageNumber: number) {
    let policyRiskTrxIds = this.details.policyRiskTrxId.toString();

    const product = this.details.product;
    this.policyRiskTrxNotesService
      .getByPolicyRiskTrxId(policyRiskTrxIds, pageNumber, this.itemsPerPage)
      .subscribe({
        next: (resp) => {
          ({ pagination: this.notesData.pagination } = resp);
          const notes = resp.data.map((data) => ({
            content: data.note,
            writer: data.createdBy,
            writerPermission: data?.updatedBy ? data?.updatedBy : '',
            product: product,
            tag: data.tags,
            date: formatDate(data.createdDt, this.longDateFormat),
            id: data.id,
            updatedDt: data?.updatedDt
              ? formatDate(data.updatedDt, this.longDateFormat)
              : '',
            read: isRead(data.updatedDt ? data.updatedDt : data.createdDt),
          }));
          this.details.isUnreadNotesPresent =
            notes.filter((p) => !p.read).length > 0;
          this.notesData.filterData = '';
          this.form.controls['notes'].setValue(notes);
          this.form.controls['notesPagination'].setValue(this.notesData);
          this.showTabLoader = false;
        },
        error: (e) => {
          this.showTabLoader = false;
        },
      });
    this.currentPage = pageNumber;
  }

  resetFilterForm() {
    this.form.controls['notesSearchForm'].reset();
    this.form.get('notesSearchForm').updateValueAndValidity();
    this.form.controls['notesSearchForm'].get('noteToDateSearch').setValue('');
    this.form.controls['notesSearchForm']
      .get('noteFromDateSearch')
      .setValue('');
    this.isNoteFormValid = false;
    this.isEndDateValid = true;
    this.isStartDateValid = true;
    this.fromDateSearchKeyWord = '';
    this.toDateSearchKeyWord = '';
    this.writerSearchKeyWord = null;
    this.notesSearchKeyWord = '';
    this.form.controls['notesSearchForm']
      .get('noteWriterSearch')
      .setValue(null);
    this.form.controls['notesSearchForm'].get('noteKeyWordSearch').setValue('');
    this.filterNotesList(this.currentPage);
  }

  handleNotesFilter(e) {
    if (!this.handleCheckNoteFormValid) {
      return;
    }
    if (!this.form.get('notesSearchForm').valid) {
      return;
    }
    let policyRiskTrxIds = this.details.policyRiskTrxId.toString();
    const queryParams = {};

    if (this.notesSearchKeyWord) {
      queryParams['note'] = this.notesSearchKeyWord;
    }

    if (this.fromDateSearchKeyWord && this.toDateSearchKeyWord) {
      queryParams['startDate'] = this.fromDateSearchKeyWord;
      queryParams['endDate'] = this.toDateSearchKeyWord;
    }

    if (
      this.writerSearchKeyWord !== undefined &&
      this.writerSearchKeyWord !== null
    ) {
      queryParams['createdBy'] = this.writerSearchKeyWord;
    }

    const product = this.details.product;
    this.policyRiskTrxNotesService
      .getByPolicyRiskTrxIdNotesFilter(
        policyRiskTrxIds,
        queryParams,
        1,
        this.itemsPerPage,
      )
      .subscribe({
        next: (resp) => {
          ({ pagination: this.notesData.pagination } = resp);
          const notes = resp.data.map((data) => ({
            content: data.note,
            writer: data.createdBy,
            writerPermission: data?.updatedBy ? data?.updatedBy : '',
            product: product,
            tag: data.tags,
            date: formatDate(data.createdDt, this.longDateFormat),
            id: data.id,
            updatedDt: data?.updatedDt
              ? formatDate(data.updatedDt, this.longDateFormat)
              : '',
            read: isRead(data.updatedDt ? data.updatedDt : data.createdDt),
          }));

          this.details.isUnreadNotesPresent =
            notes.filter((p) => !p.read).length > 0;
          this.form.controls['notes'].setValue(notes);
          this.notesData.filterData = queryParams;
          this.form.controls['notesPagination'].setValue(this.notesData);
        },
        error: (e) => {},
      });
  }

  handleCheckNoteFormValid() {
    if (this.writerSearchKeyWord || this.notesSearchKeyWord) {
      if (this.isStartDateValid && this.isEndDateValid) {
        if (this.fromDateSearchKeyWord && this.toDateSearchKeyWord) {
          this.isNoteFormValid = true;
        } else if (!this.fromDateSearchKeyWord && !this.toDateSearchKeyWord) {
          this.isNoteFormValid = true;
        } else {
          this.isNoteFormValid = false;
        }
      } else {
        this.isNoteFormValid = false;
      }
    } else if (this.isStartDateValid && this.isEndDateValid) {
      if (this.fromDateSearchKeyWord && this.toDateSearchKeyWord) {
        this.isNoteFormValid = true;
      } else {
        this.isNoteFormValid = false;
      }
    } else {
      this.isNoteFormValid = false;
    }
  }
}
