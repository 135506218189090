import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, retry, catchError } from 'rxjs';
import { BoxxResponse } from '../entities/boxx-response';
import { BaseService } from './base-services';

@Injectable({
  providedIn: 'root',
})
export class PolicyRiskTrxService extends BaseService<any> {
  constructor(http: HttpClient) {
    const baseurl = environment.msPolicyBaseUrl + environment.policyRiskTrxUrl;
    super(http, baseurl);
  }

  GetByPolicyRiskId(
    policyRiskId: number,
    page?: number,
    limit?: number,
    sort?: string,
    skipLatestTrx?: boolean,
  ): Observable<any> {
    sort = sort ?? 'desc';
    // TODO: Sorting by createDt does not usually work because some of the transactions happen in the same second
    //const url = `${this.baseurl}${userId}?policyRiskId=${policyRiskId}&page=${page}&limit=${limit}&active=1&sort=createdDt%3Adesc`;
    let url = `${this.baseurl}?policyRiskId=${policyRiskId}&page=${page}&limit=${limit}&active=1&sort=id%3A${sort}`;
    if (skipLatestTrx) {
      url = url + '&skipLatestTrx=true';
    }
    return this.http
      .get<BoxxResponse<any>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
