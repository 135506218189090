import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Breadcrumb } from 'src/app/models/breadcrumb.model';
import { BrokerageBranch } from 'src/app/models/brokergeBranch';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BrokerageBranchService } from 'src/app/services/brokerage-branch/brokerage-branch.service';
import {
  getFormGroup,
  mapFormGroupToModel,
} from '../factorys/formGroup.factory';
import { BrokerageService } from 'src/app/services/brokerage.service';
import { getDate } from 'src/app/utils/formatDate';
import { ViewportScroller } from '@angular/common';
import { BrokerageProducerService } from 'src/app/services/brokerage-producer.service';
import { BrokerageProducer } from 'src/app/entities/brokerage-producer';
import {
  getBranchCreatedSelector,
  getProducerCreatedSelector,
} from 'src/app/store/brokerage/brokerage.select';
import { Store, select } from '@ngrx/store';
import { take, Subscription } from 'rxjs';
import {
  resetBranchCreated,
  resetProducerCreated,
} from 'src/app/store/brokerage/brokerage.action';
import { getAlertHead, getErrorMessage } from 'src/app/utils/utils';
import { formatDateTime, formatDateToDay } from 'src/app/utils/formatDate';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { AlertService } from 'src/app/services/alert.service';
import { BrokerageBranchTeamsService } from 'src/app/services/brokerage-branch/brokerage-branch-teams.service';

@Component({
  selector: 'app-view-branch-v2',
  templateUrl: './view-branch-v2.component.html',
  styleUrls: ['./view-branch-v2.component.less'],
})
export class ViewBranchV2Component implements OnInit {
  @Input() branchId: number;
  @Input() brokerageId: number;
  @Input() isActive: boolean = false;
  @Output() handleDiscard = new EventEmitter();
  @Output() handleUpdateBranch = new EventEmitter();
  @Output() handleDeactivateBranch1 = new EventEmitter();
  @Output() handleDeactivateBranchChild = new EventEmitter();

  form: FormGroup;
  submitted: boolean = false;
  requiredErrMsg: string = 'This field is required';
  invalidErrMsg: string = 'This field is invalid';
  createdBy: string | null;
  updatedBy: string | null;
  itemsMenu: Breadcrumb[] = [
    { label: 'Brokerage', path: `/dashboard/workflow3/brokerage/` },
  ];

  listProducers: any[] = [];

  underWriterOptions = [{ key: 'Add one', value: 'Add_one' }];
  addSortTo: string | Array<string> = ['Name', 'Last Active'];
  operationSuccess = false;
  hasError = false;
  errorMessage = '';
  successMessage = '';
  title: string;
  branchSelected: any = null;
  iisActivesActive = false;
  isBrokerageActive = false;
  totalRecords = 0;
  locationRegionIdSelected = 0;
  locationOldSelection = null;
  showSpinner = false;
  tableHeader = [{ Name: '', Contact: '', 'Last Active': '', Status: '' }];
  isNavigatedFromAddBranch = false;
  isNavigatedFromAddProducer = false;
  userID: number;
  sortBy = 'id:desc';
  showErrorAlert = false;
  msgErrorAlert = '';
  limit = 10;
  pageNumber: number = 1;
  showBodySpinner: boolean = false;
  initialBranchFormValue: any;
  initialLocationFormValue: any;
  isBranchFormDirty: boolean = false;
  isLocationFormDirty: boolean = false;

  btnStyle: { [key: string]: any } = { padding: '0 30px' };
  private branchFormSubscription: Subscription;
  private locationFormSubscription: Subscription;
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  shortDateFormat: string = '';
  longDateFormat: string = '';
  shortDateTimeFormat: string = '';
  longDateTimeFormat: string = '';
  deactivateBtnLabel: string = this.isActive ? 'Deactivate' : 'Activate';

  alertData = {
    show: false,
    type: '',
    header: '',
    body: '',
  };
  createdAt: string;
  updatedAt: string;
  teamList: { id: number | undefined; name: string }[] = [];
  newTeamName: string = '';
  teamNameRegex = /^[a-zA-Z0-9_-]+(?: [a-zA-Z0-9_-]+)*$/;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    activatedRoute: ActivatedRoute,
    private brokerageBranchService: BrokerageBranchService,
    private brokerageService: BrokerageService,
    private viewPortScroller: ViewportScroller,
    private store: Store,
    private brokerageProducerService: BrokerageProducerService,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    public brokerageBranchTeamsService: BrokerageBranchTeamsService,
  ) {
    activatedRoute.params.subscribe((params) => {
      //this.brokerageId = params['id'] as number;
    });
  }

  regionIdSelected(idregionSelected: number) {
    this.locationRegionIdSelected = idregionSelected;
  }

  async ngOnInit() {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
      this.shortDateTimeFormat = data.shortDateTimeFormat;
      this.longDateTimeFormat = data.longDateTimeFormat;
    });
    this.userID = this.localStorageService.getBoxxUserId();
    this.showBodySpinner = true;
    this.form = getFormGroup(this.fb);

    this.store
      .select(getBranchCreatedSelector)
      .pipe(take(1))
      .subscribe((value) => (this.isNavigatedFromAddBranch = value));
    this.store.dispatch(new resetBranchCreated());

    this.store
      .select(getProducerCreatedSelector)
      .pipe(take(1))
      .subscribe((value) => (this.isNavigatedFromAddProducer = value));
    this.store.dispatch(new resetProducerCreated());

    await this.getBrokerageBranch();

    this.brokerageService.GetBrokerage(this.brokerageId).subscribe((item) => {
      this.isBrokerageActive = item.data.active;

      // this.form[this.isActive ? 'enable' : 'disable']({ emitEvent: false });
      this.itemsMenu[1] = {
        label: item.data.name,
        path: `/dashboard/workflow3/brokerage/profile/${this.brokerageId}`,
      };
    });

    this.brokerageProducerService
      .GetBrokerageProducers(this.branchId)
      .subscribe({
        next: (response) => {
          this.formatData(response.data);
          this.totalRecords = response.pagination.totalRecords;
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
    this.showBodySpinner = false;
  }
  ngOnDestroy(): void {
    this.unSubscriptionForms();
  }
  handleCloseSuccessEvent() {
    this.showErrorAlert = false;
  }

  handleTeamNameChange(event: Event): void {
    const name = (event.target as HTMLInputElement).value;
    this.newTeamName = name;
  }

  addNewTeam() {
    this.brokerageBranchTeamsService
      .addBranchTeams({
        brokerageBranchId: +this.branchId,
        teamNames: [this.newTeamName],
      })
      .subscribe({
        next: (response) => {
          const newTeam = { id: response.data[0].id, name: this.newTeamName };
          this.teamList = [...this.teamList, newTeam];
          const alertData = {
            type: 'success',
            headerText: getAlertHead('success'),
            bodyText: 'workFlow3.branchProfile.success.teamAddSuccess',
          };
          this.alertService.addAlert(alertData);
          this.newTeamName = '';
        },
        error: (error) => {
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: getErrorMessage(error),
          };
          this.alertService.addAlert(alertData);
        },
      });
  }
  deleteTeam(teamIndex: number, teamInfo: { id: number; name: string }) {
    this.brokerageBranchTeamsService.deleteBranchTeams(teamInfo.id).subscribe({
      next: (response) => {
        this.teamList.splice(teamIndex, 1);
        const alertData = {
          type: 'success',
          headerText: getAlertHead('success'),
          bodyText: 'workFlow3.branchProfile.success.teamDeleteSuccess',
        };
        this.alertService.addAlert(alertData);
      },
      error: (error) => {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: getErrorMessage(error),
        };
        this.alertService.addAlert(alertData);
      },
    });
  }

  isTeamNameValid(): boolean {
    let isValid = false;
    if (this.teamNameRegex.test(this.newTeamName)) {
      isValid = true;
    }

    return isValid;
  }

  updateTeamList(teams: any[]) {
    this.teamList = teams;
  }

  getBrokerageBranch() {
    return new Promise<void>((resolve) => {
      this.unSubscriptionForms();
      this.isBranchFormDirty = this.isLocationFormDirty = false;
      this.brokerageBranchService.getBranchById(this.branchId).subscribe({
        next: (data) => {
          resolve();
          this.updateTeamList(data?.data?.[0]?.BrokerageBranchTeams ?? []);
          this.isActive = data.data[0].active;
          this.brokerageId = data.data[0].brokerage.id;
          if (this.isActive === true) {
            this.deactivateBtnLabel = 'Deactivate';
          } else {
            this.deactivateBtnLabel = 'Activate';
          }
          this.form[this.isActive ? 'enable' : 'disable']({ emitEvent: false });
          this.branchSelected = data.data[0];
          this.title = data.data[0].name;
          this.itemsMenu[2] = {
            label: this.title,
            path: `/dashboard/workflow3/brokerage/branch/${this.branchId}`,
          };
          const branchControl = this.form.controls['branch'];
          branchControl.get('name').setValue(data.data[0].name);
          branchControl.get('email').setValue(data.data[0].email ?? '');
          branchControl.get('phone').setValue(data.data[0].telephone ?? '');
          this.initialBranchFormValue = this.form.get('branch').value;
          this.branchFormSubscription = this.form
            .get('branch')
            .valueChanges.subscribe(() => {
              this.isBranchFormDirty = !this.isFormUnchanged(
                'branch',
                this.initialBranchFormValue,
              );
            });

          if (data.data[0].location) {
            const {
              address,
              addressLine2,
              city,
              country,
              zipPostalCode,
              region,
            } = data.data[0].location;
            this.locationOldSelection = { ...data.data[0].location };
            const locationControl = this.form.controls['location'];
            locationControl.get('city').setValue(city);
            locationControl.get('address').setValue(address);
            locationControl.get('addressLine2').setValue(addressLine2);
            locationControl.get('country').setValue(country);
            locationControl.get('province').setValue(region.name);
            locationControl.get('zipPostalCode').setValue(zipPostalCode);
          }
          this.initialLocationFormValue = this.form.get('location').value;
          this.locationFormSubscription = this.form
            .get('location')
            .valueChanges.subscribe(() => {
              this.isLocationFormDirty = !this.isFormUnchanged(
                'location',
                this.initialLocationFormValue,
              );
            });
          this.form[data.data[0].active ? 'enable' : 'disable']({
            emitEvent: false,
          });
          this.showBodySpinner = false;
          this.setSectionDate();
          this.viewPortScroller.scrollToPosition([0, 0]);
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showBodySpinner = false;
          }
          this.handlerErrorBranch(error);
        },
      });
    });
  }
  private unSubscriptionForms() {
    if (this.branchFormSubscription) {
      this.branchFormSubscription.unsubscribe();
    }
    if (this.locationFormSubscription) {
      this.locationFormSubscription.unsubscribe();
    }
  }

  isFormUnchanged(formControlName: string, initialValues): boolean {
    return (
      JSON.stringify(this.form.get(formControlName).value) ===
      JSON.stringify(initialValues)
    );
  }

  handlePageChange(pageNumber) {
    this.pageNumber = pageNumber;
    this.brokerageProducerService
      .GetBrokerageProducers(this.branchId, pageNumber, this.limit, this.sortBy)
      .subscribe({
        next: (response) => {
          this.formatData(response.data);
          this.totalRecords = response.pagination.totalRecords;
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
  }

  branchCreatedAlertClose() {
    this.isNavigatedFromAddBranch = false;
  }

  producerCreatedAlertClose() {
    this.isNavigatedFromAddProducer = false;
  }
  get location() {
    return this.form.get('location') as FormGroup;
  }

  get branch() {
    return this.form.get('branch') as FormGroup;
  }

  handleUpdateBranchClick(e: any) {
    this.alertService.clearAlerts(-1);
    if (!this.isActive) {
      return;
    }
    if (!!!this.isBranchFormDirty && !!!this.isLocationFormDirty) {
      const alertData = {
        type: 'info',
        headerText: getAlertHead('info'),
        bodyText: 'workFlow3.components.shared.error.alreadyUpToDate',
      };
      this.alertService.clearAlerts(-1);
      this.alertService.addAlert(alertData);
      return;
    }
    this.submitted = true;

    if (this.form.valid && this.form.enabled) {
      this.showSpinner = true;
      this.alertService.clearAlerts(-1);

      let branchForm = this.form.get('branch');
      if (branchForm.value.email === '') {
        branchForm.value.email = null;
      }
      if (branchForm.value.phone === '') {
        branchForm.value.phone = null;
      }

      const brokerageBranch: BrokerageBranch = mapFormGroupToModel(
        this.form,
        this.brokerageId,
      );

      if (brokerageBranch.location) {
        brokerageBranch.location.id = this.locationOldSelection.id;
        if (this.locationRegionIdSelected != 0)
          brokerageBranch.location.regionId = this.locationRegionIdSelected;
      }

      this.brokerageBranchService
        .updateBranch(brokerageBranch, this.branchId)
        .subscribe({
          next: (responseData) => {
            const responseBranch = responseData?.data;
            this.updatedBy = `${responseBranch.updatedBy}, ${formatDateTime(
              responseBranch.updatedDt,
              this.longDateTimeFormat,
            )}`;
            this.operationSuccess = true;
            this.hasError = false;
            const alertData = {
              type: 'success',
              headerText: 'success!',
              bodyText: 'brokerage.branch.success.updateMessage',
            };
            this.alertService.clearAlerts(-1);
            this.alertService.addAlert(alertData);
            this.submitted = false;
            this.showSpinner = false;
            this.itemsMenu[2] = { label: brokerageBranch.name, path: null };
            this.title = brokerageBranch.name;
            this.viewPortScroller.scrollToPosition([0, 0]);
            this.getBrokerageBranch();
          },
          error: (error) => {
            const alertData = {
              type: 'error',
              headerText: 'error!',
              bodyText: getErrorMessage(error),
            };
            this.alertService.clearAlerts(-1);
            this.alertService.addAlert(alertData);
            this.handlerErrorBranch(error);
            this.operationSuccess = false;
            this.showSpinner = false;
          },
        });
    }
  }

  onHandleCloseEvent() {
    this.hasError = false;
    this.operationSuccess = false;
  }

  handleDeactivateBranch(event) {
    if (this.isActive) {
      this.handleDiscardClick();
      this.showBodySpinner = true;
      this.brokerageBranchService.deleteBranch(this.branchId).subscribe({
        next: (responseData: any) => {
          const responseBranch = responseData?.data;
          this.updatedBy = `${responseBranch.updatedBy}, ${formatDateTime(
            responseBranch.updatedDt,
            this.longDateTimeFormat,
          )}`;
          this.operationSuccess = true;
          this.hasError = false;
          this.successMessage = 'brokerage.branch.success.deActivateMessage';
          this.branchSelected.active = false;
          this.viewPortScroller.scrollToPosition([0, 0]);
          this.form[this.isActive ? 'enable' : 'disable']({
            emitEvent: false,
          });
          this.isActive = false;
          this.showBodySpinner = false;
          this.handleDeactivateBranchChild.emit(false);
          this.alertService.clearAlerts(-1);
          this.deactivateBtnLabel = 'Activate';
          this.alertData = {
            show: true,
            type: 'success',
            header: 'common.successHeader',
            body: 'Branch deactivated succesfully',
          };
        },
        error: (error) => {
          this.showBodySpinner = false;
          this.operationSuccess = false;
          this.alertData = {
            show: true,
            type: 'error',
            header: 'common.errorHeader',
            body: getErrorMessage(error),
          };
          this.handlerErrorBranch(error);
        },
      });
    }
  }

  handleActivateBranch(event) {
    if (!this.isActive) {
      this.showBodySpinner = true;
      this.brokerageBranchService.restoreBranch(this.branchId).subscribe({
        next: (responseData: any) => {
          const responseBranch = responseData?.data;
          this.updatedBy = `${responseBranch.updatedBy}, ${formatDateTime(
            responseBranch.updatedDt,
            this.longDateTimeFormat,
          )}`;
          this.operationSuccess = true;
          this.hasError = false;
          this.successMessage = 'brokerage.branch.success.activateMessage';
          this.branchSelected.active = true;
          this.viewPortScroller.scrollToPosition([0, 0]);
          this.form[this.isActive ? 'enable' : 'disable']({
            emitEvent: false,
          });
          this.isActive = true;
          this.showBodySpinner = false;
          this.handleDeactivateBranchChild.emit(true);
          this.alertService.clearAlerts(-1);
          this.deactivateBtnLabel = 'Deactivate';
          this.getBrokerageBranch();
          this.alertData = {
            show: true,
            type: 'success',
            header: 'common.successHeader',
            body: 'Branch activated succesfully',
          };
        },
        error: (error) => {
          this.showBodySpinner = false;
          this.operationSuccess = false;
          this.handlerErrorBranch(error);
        },
      });
    }
  }

  dropdownValueChanged() {
    if (this.branchSelected && !this.branchSelected.active) {
      return;
    }

    this.router.navigate(
      [`/dashboard/branch/new-producer/${this.branchId}/${this.brokerageId}`],
      { skipLocationChange: true },
    );
  }

  private setSectionDate() {
    (this.createdBy = this.branchSelected.createdBy),
      (this.createdAt = formatDateTime(
        this.branchSelected.createdDt,
        this.longDateTimeFormat,
      ));

    this.updatedBy = this.branchSelected.updatedBy;
    this.updatedAt = formatDateTime(
      this.branchSelected.updatedDt,
      this.longDateTimeFormat,
    );
  }

  formatData(items: BrokerageProducer[]) {
    this.listProducers =
      items.length == 0
        ? this.tableHeader
        : items.map((item) => {
            let row = {
              Name: `${item.firstName} ${item.lastName} `,
              Contact: `${item.email}<br>${item.telephone || ''}`,
              'Last Active': formatDateToDay(
                item.updatedDt ? item.updatedDt : item.createdDt,
                this.longDateFormat,
              ),
              Status: item?.active ? 'Active' : 'Inactive',
              ActionView: `/dashboard/branch/profile/producer/${item.id}`,
            };

            return row;
          });
  }

  private handlerErrorBranch(error: any) {
    if (![500].includes(error?.status)) {
      this.hasError = true;
    }
    this.errorMessage = getErrorMessage(error);
    this.viewPortScroller.scrollToPosition([0, 0]);
  }

  handleAscSort(key: string) {
    let dir = 'asc';
    this.sortBy = this.mapSortColumns(key, dir);
    this.brokerageProducerService
      .GetBrokerageProducers(
        this.branchId,
        this.pageNumber,
        this.limit,
        this.sortBy,
      )
      .subscribe({
        next: (response) => {
          this.formatData(response.data);
          this.totalRecords = response.pagination.totalRecords;
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
  }

  handleDescSort(key: string) {
    let dir = 'desc';
    this.sortBy = this.mapSortColumns(key, dir);
    this.brokerageProducerService
      .GetBrokerageProducers(
        this.branchId,
        this.pageNumber,
        this.limit,
        this.sortBy,
      )
      .subscribe({
        next: (response) => {
          this.formatData(response.data);
          this.totalRecords = response.pagination.totalRecords;
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
  }

  mapSortColumns(key, dir) {
    let sortColumn;
    switch (key) {
      case 'Name':
        sortColumn = 'firstName:' + dir + ',lastName:' + dir;
        break;

      case 'Last Active':
        sortColumn = 'updatedDt:' + dir;
        break;

      default:
        sortColumn = 'id:' + dir;
        break;
    }
    return sortColumn;
  }

  handleDiscardClick() {
    if (this.isBranchFormDirty || this.isLocationFormDirty) {
      this.getBrokerageBranch();
    }
  }

  handleAlertClose() {
    this.alertData = {
      show: false,
      type: '',
      header: '',
      body: '',
    };
  }
}
