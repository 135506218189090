import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.less'],
})
export class ResetPasswordComponent implements OnInit {
  constructor(private fb: FormBuilder) {}
  resetForm: FormGroup;

  ngOnInit(): void {
    this.resetForm = this.fb.group({
      email: [''],
    });
  }

  handleSubmit(e: any) {}
}
