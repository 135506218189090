import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Breadcrumb } from 'src/app/models/breadcrumb.model';
import {
  ImageCroppedEvent,
  ImageTransform,
  CropperSettings,
} from 'ngx-image-cropper';
import { getFormGroup, mapFormGroupToModel } from './new-producer.factory';
import { select, Store } from '@ngrx/store';
import { getCommissionSelector } from 'src/app/store/commission/commission.select';
import { ICommission, ICommissionModel } from 'src/app/models/commission.model';
import { ActivatedRoute, Router } from '@angular/router';
import { BrokerageBranchService } from 'src/app/services/brokerage-branch.service';
import { BrokerageService } from 'src/app/services/brokerage.service';
import { forkJoin } from 'rxjs';
import { BrokerageProducerService } from 'src/app/services/brokerage-producer.service';
import { DatePipe, ViewportScroller } from '@angular/common';
import { getErrorMessage } from 'src/app/utils/utils';
import { resetCommissions } from 'src/app/store/commission/commission.action';
import { setProducerCreated } from 'src/app/store/brokerage/brokerage.action';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './new-producer.component.html',
  styleUrls: ['./new-producer.component.less'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NewProducerComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  cropperSettings: CropperSettings;
  allowedFormats = ['.jpg', '.jpeg', '.png'];
  maxFileSizeMB = 2; // Maximum file size in MB
  previousImage: string;

  form: FormGroup;
  submitted: boolean = false;
  requiredErrMsg: string = 'This field is required';
  invalidErrMsg: string = 'This field is invalid';
  listCommissions: ICommission[] = [];
  customCommission: boolean = false;
  branchId: number;
  brokerageId: number;
  itemsMenu: Breadcrumb[] = [
    { label: 'Brokerage directory', path: '/dashboard/brokerage' },
  ];

  url: string | ArrayBuffer = 'assets/img/default-photo.png';
  croppedImage: any = 'assets/img/default-photo.png';
  userID: number;
  operationSuccess = false;
  hasError = false;
  errorMessage = '';
  isPrfileImgUpdatefalied = false;
  prfileImgUpdatefaliedErrMsg = '';
  checked = false;
  producerId = undefined;
  private userId: number;

  constructor(
    private viewPortScroller: ViewportScroller,
    private brokerageProducerService: BrokerageProducerService,
    private brokerageService: BrokerageService,
    private brokerageBranchService: BrokerageBranchService,
    private fb: FormBuilder,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private router: Router,
    private localStorageService: LocalStorageService,
    private translate: TranslateService,
  ) {
    this.cropperSettings = new CropperSettings();
    activatedRoute.params.subscribe((params) => {
      this.branchId = params['branchId'] as number;
      this.brokerageId = params['brokerageId'] as number;
    });
  }

  ngOnInit(): void {
    this.userId = this.localStorageService.getBoxxUserId();
    this.form = getFormGroup(this.fb);
    this.initialData();
  }

  initialData() {
    forkJoin({
      brokerage: this.brokerageService.GetBrokerage(this.brokerageId),
      brokeragebranch: this.brokerageBranchService.GetBrokerageBranch(
        this.branchId,
      ),
    }).subscribe(({ brokerage, brokeragebranch }) => {
      const brokerageData = brokerage.data;
      this.itemsMenu.push({
        label: brokerageData.name,
        path: `/dashboard/brokerage/view/${brokerageData.id}`,
      });

      const branchData = brokeragebranch.data;
      this.itemsMenu.push({
        label: branchData.name,
        path: `/dashboard/branch/view/${branchData.id}/${brokerageData.id}`,
      });

      this.itemsMenu.push({ label: 'New producer', path: null });
    });
  }

  toggleChanged(event: boolean) {
    this.customCommission = event;
    this.checked = event;
  }

  name = 'Angular';
  imageChangedEvent: any = '';

  blob: any;
  blob2: any;
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  imageTransform: ImageTransform = {};
  alertList = [];

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    const favDialog = document.getElementById('myDialog') as HTMLDialogElement;
    favDialog.showModal();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.showCropper = true;
  }

  loadImageFailed() {}

  handleSubmit(e: any) {
    e.preventDefault();
    this.submitted = true;
    if (this.form.valid) {
      const producerModel = mapFormGroupToModel(
        this.form,
        this.customCommission,
      );
      producerModel.brokerageBranchId = +this.branchId;
      if (this.croppedImage != 'assets/img/default-photo.png') {
        producerModel.brokerageProducerProfile = {
          headShotImage: this.croppedImage,
        };
      } else {
        producerModel.brokerageProducerProfile = {};
      }

      this.brokerageProducerService
        .CreateAtomicBrokerageProducer(producerModel)
        .subscribe({
          next: (response) => {
            const { data } = response;
            this.operationSuccess = true;
            this.form.reset();
            this.viewPortScroller.scrollToPosition([0, 0]);
            this.submitted = false;
            this.producerId = data.id ?? undefined;
            this.checked = false;
            this.store.dispatch(new setProducerCreated());

            //reset the profile image
            this.croppedImage = 'assets/img/default-photo.png';

            //redirect the user to view or edit-producer page accoring to the customCommission value.
            this.redirectToProducerPage();
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.addAlert('error', 'error!', getErrorMessage(error));
            }
          },
        });
    }
  }

  base64textString: string = '';

  handleFileSelect(evt) {
    var files = evt.target.files;
    var file = files[0];

    if (files && file) {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = (e: any) => {
        this.url = e.target.result;
      };
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
  }

  redirectToProducerPage() {
    this.customCommission
      ? this.router.navigate(
          [
            `../../../edit-producer/${this.brokerageId}/${this.branchId}/${this.producerId}`,
          ],
          { relativeTo: this.activatedRoute, skipLocationChange: true },
        )
      : this.router.navigate([`../../../profile/producer/${this.producerId}`], {
          relativeTo: this.activatedRoute,
          skipLocationChange: true,
        });
  }

  handleCloseSuccessEvent() {
    this.hasError = false;
    this.isPrfileImgUpdatefalied = false;
    this.prfileImgUpdatefaliedErrMsg = '';
  }

  closeImageModal() {
    if (!this.previousImage) this.croppedImage = 'assets/img/default-photo.png';
    else this.croppedImage = this.previousImage;
    this.clearFileInput();
  }

  selectedImage() {
    if (this.croppedImage) this.previousImage = this.croppedImage;
  }

  clearFileInput() {
    this.fileInput.nativeElement.value = null;
  }

  onImageSelected(event: any): void {
    const file = event.target?.files[0];
    if (file) {
      const fileSizeMB = file.size / (1024 * 1024);
      if (fileSizeMB > this.maxFileSizeMB) {
        this.clearFileInput();
        const errorMsg = this.translate.instant(
          'brokerage.producer.error.imageSizeError',
        );
        this.addAlert('error', 'error!', errorMsg);
        return;
      }

      const fileExtension = this.getFileExtension(file);
      if (!this.allowedFormats.includes(fileExtension.toLowerCase())) {
        this.clearFileInput();
        const errorMsg = this.translate.instant(
          'brokerage.producer.error.imageFormatError',
        );
        this.addAlert('error', 'error!', errorMsg);
        return;
      }

      const reader = new FileReader();
      reader.onload = (fileEvent) => {
        this.imageChangedEvent = fileEvent.target?.result;
      };
      reader.readAsDataURL(file);
      this.imageChangedEvent = event;
      const favDialog = document.getElementById(
        'myDialog',
      ) as HTMLDialogElement;
      favDialog.showModal();
    }
  }

  getFileExtension(file: File): string {
    const filename = file.name;
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1) {
      return '';
    }
    return filename.substr(lastDotIndex).toLowerCase();
  }

  handleExpiryDate(date: Date | undefined) {
    const formattedDate = date
      ? this.datePipe.transform(new Date(date), 'MMM dd, YYYY')
      : '';
    this.form.controls['licenseExpiry'].setValue(formattedDate);
    this.form.controls['licenseNumber'].updateValueAndValidity();
  }
  errorExpiry() {
    let show = !this.form.controls['licenseExpiry'].valid;
    let error = { show: show, msg: '' };
    error.msg = show
      ? this.form.controls['licenseExpiry'].getError('date')
      : '';
    return error;
  }
  addAlert(type: string, headerText: string, bodyText: string) {
    this.alertList.push({
      type: type,
      headerText: headerText,
      bodyText: bodyText,
    });
  }
  removeAlert(index) {
    this.alertList.splice(index, 1);
  }
}
