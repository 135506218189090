import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, firstValueFrom, take } from 'rxjs';
import { Quote } from 'src/app/models/quote.model';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import { InsuredService } from 'src/app/services/insured.service';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { LocationService } from 'src/app/services/location.service';
import { ConfMessageTemplateService } from 'src/app/services/message-template-services';
import { NewQuoteService } from 'src/app/services/new-quote.service';
import { PolicyBindService } from 'src/app/services/policy-bind.service';
import { PolicyRiskService } from 'src/app/services/policy-risk.service';
import { getQuoteSelector } from 'src/app/store/create-quote/create-quote.selector';
import { DomainsService } from 'src/app/services/domains.service';
import { PolicyRiskDocService } from 'src/app/services/policy-risk-doc.service';
import { MessageSendService } from 'src/app/services/message-send.service';
import { GenerateQuoteService } from 'src/app/services/doc-create.service';
import { formatDate, convertDate } from 'src/app/utils/formatDate';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';

import {
  DOC_GENERATION_WAIT_TIME,
  GENERATE_DOC_SUCCESS_STATUS_CODE,
} from 'src/app/constants/quote-constant';
import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import { TranslateService } from '@ngx-translate/core';
import { PolicyRiskTrxService } from 'src/app/services/policy-risk-trx.service';
import { PolicyTRIAUpdate } from 'src/app/entities/policy';
import { isSuccessApiStatus } from 'src/app/dashboard/utils/lifecycle-utils';
import { getAlertHead, getErrorMessage } from 'src/app/utils/utils';
import { getLifecycleState } from 'src/app/store/lifecycle/lifecycle.select';
import { DocumentTemplateService } from 'src/app/services/doc-template.service';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-quote-summary',
  templateUrl: './quote-summary.component.html',
  styleUrls: ['./quote-summary.component.less'],
})
export class QuoteSummaryComponent implements OnInit, OnDestroy {
  form$: Observable<Quote>;
  templateDetails: { [x: string]: string | number }[] = [];
  docDetails: { [x: string]: string | number | boolean }[] = [];
  rowDocDetails: { [x: string]: string | number | boolean } = {};
  isLoader: boolean = false;
  stageId: number;
  packagePolicyNumber: string = '';
  showSendModal = false;
  showDocPreview = false;
  currentPreviewDocUrl;
  isPolicyFeeEditMode = false;
  isTRIAAmountEditMode = false;
  policyFeeForm: FormGroup;
  bindingStatus = 'default';
  isBindingPolicy = false;
  showErrorAlert = false;
  alertMessage = '';
  policyPeriodId: number = 0;
  policyRiskId: number = null;
  policyId: Number = 0;
  quoteOptions = [];
  policyRiskFee = [];
  effDate = '';
  expDate = '';
  policyFeeEnabled = false;
  TRIAFeeEnabled = false;
  products = [];
  productsIds = [];
  riskId = 0;
  regionId = 0;
  insured = {
    name: '',
    industry: '',
    address: '',
    industryClass: '',
  };
  riskTrnxs;
  underWriters = [];
  policyRiskIds = [];
  isQuoteBounded = false;
  documentId: number;
  merginStageId: number;
  messageTypeId: number;
  addonsFromPolicyServ = [];
  addons = [];
  boxxUserId;
  totalBilledAnnually = 0;
  showBodySpinner: boolean = false;
  spinnerCounter = 0;
  newTimeout: NodeJS.Timeout;

  showAllCards: boolean = false;
  openCardList = [];
  riskRegionId = 0;
  domainCodeForEndorsement = 0;
  domainCodeForWordings = 0;
  domainCodeForDeclarations = 0;

  endorsementOptions: any[] = [
    { key: '', value: '', disabled: false, url: '' },
  ];
  wordingsOptions: any[] = [{ key: '', value: '', disabled: false, url: '' }];
  subjectivityOptns: any[] = [{ key: '', value: '', disabled: false, url: '' }];
  subjectivityMandatory = [];
  selectedSubjectivities = [];
  endorsementsMandatory = [];
  selectedEndorsements = [];
  selectedWordings = [];
  selectedDeclarations = [];
  declarationOptions: any[] = [
    { key: '', value: '', disabled: false, url: '' },
  ];
  productList: { [x: string]: any }[] = [];
  pdtDescription = [];
  cyberPdtDesc = [];
  currency = '';
  policyPeriod = '';
  triaAmount: number;
  triaAmountDefault: number;
  policyFeeDefault: number;
  policyPremium: number;
  policyAmount: number;
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  currentQuoteStatus: string = '';
  showDocTempSelectionPopup: boolean = false;
  docPopupDetails: {
    statusType: string;
    action: any;
    documentTemplate: any;
    policyPeriodId: any;
    stageId: any;
  };
  isSendingQuote = false;
  shortDateFormat: string = '';
  longDateFormat: string = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private fb: FormBuilder,
    private localStorageService: LocalStorageService,
    private newQuoteService: NewQuoteService,
    private policyRiskService: PolicyRiskService,
    private insuredService: InsuredService,
    private policyBindService: PolicyBindService,
    private locationService: LocationService,
    private boxxUserService: BoxxUserService,
    private domainsService: DomainsService,
    private policyRiskDocService: PolicyRiskDocService,
    private messageTemplateService: ConfMessageTemplateService,
    private messageSendService: MessageSendService,
    private generateQuoteService: GenerateQuoteService,
    private translate: TranslateService,
    private policyRiskTrxService: PolicyRiskTrxService,
    private DocumentTemplateService: DocumentTemplateService,
    private alertService: AlertService,
  ) {
    this.policyFeeForm = this.fb.group({
      policyFee: ['0'],
      triaAmount: ['0'],
    });
  }

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });

    this.store.pipe(select(getLifecycleState)).subscribe((data) => {
      this.currentQuoteStatus = data.quoteStatus;
    });
    this.docPopupDetails = {
      statusType: 'Document template selection',
      action: 'docTempSelection',
      documentTemplate: [],
      policyPeriodId: 0,
      stageId: 0,
    };
    this.spinnerCounter = 0;
    this.showBodySpinner = true;
    this.spinnerCounter++;
    let underwriter, defaultEffDate, defaultExpDate;
    let policyFee = 0;
    let triaAmount = 0;
    let policyPremium = 0;
    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));

    this.form$ = this.store.pipe(select(getQuoteSelector));
    this.form$.subscribe((event) => (defaultEffDate = event.startDate));
    this.form$.subscribe((event) => (defaultExpDate = event.endDate));
    if (defaultEffDate) {
      this.effDate = convertDate(defaultEffDate, this.shortDateFormat);
    }
    if (defaultExpDate) {
      this.expDate = convertDate(defaultExpDate, this.shortDateFormat);
    }
    this.boxxUserId = this.localStorageService.getBoxxUserId();

    this.form$.subscribe(
      (event) => (this.policyPeriodId = Number(event.ui.policyPeriodId)),
    );
    this.form$.subscribe((event) => (this.policyId = event.ui.policyId));
    this.form$.subscribe((event) => (this.productList = event.products));
    this.policyRiskService
      .GetAllByPolicyPeriodId(this.policyPeriodId)
      .subscribe((data) => {
        this.policyRiskId = data.data[0].id;
        data.data?.forEach((risk, index) => {
          this.policyRiskFee.push(...risk?.PolicyRiskTrxes);
          this.quoteOptions.push(...risk?.PolicyRiskTrxes[0]?.QuoteOptions);
          this.products.push(risk?.risk.name);

          if (this.products[index]?.underwriterId) {
            underwriter = this.products[index]?.underwriterId;
          } else {
            underwriter = risk?.PolicyRiskTrxes[0].underwriterId;
          }
          this.spinnerCounter++;
          this.boxxUserService
            .GetById(underwriter)
            .pipe(take(1))
            .subscribe((data) => {
              this.underWriters.push(
                data.data?.firstName + ' ' + data.data?.lastName,
              ) || '';
              this.spinnerCounter--;
              if (this.spinnerCounter == 0) this.showBodySpinner = false;
            });
        });

        if (!defaultEffDate) {
          this.effDate = formatDate(
            data?.data[0]?.policyPeriod?.effectiveDt,
            this.longDateFormat,
          );
        }
        if (!defaultExpDate) {
          this.expDate = formatDate(
            data?.data[0]?.policyPeriod?.expiryDt,
            this.longDateFormat,
          );
        }
        this.regionId =
          data.data[0]?.policyPeriod.policy.insured.insuredLocation.regionId;
        this.riskId = data.data[0]?.risk.id;
        this.addonsFromPolicyServ = data.data[0]?.PolicyRiskServices;
        this.policyRiskFee.forEach((optn) => {
          policyFee = policyFee + Number(optn.policyFee);
          triaAmount = triaAmount + Number(optn.triaAmount);
          policyPremium = policyPremium + Number(optn.premium);
        });
        this.policyAmount = policyFee;
        this.triaAmount = triaAmount;
        this.policyPremium = policyPremium;
        const riskObj = data.data[0].risk;
        if (riskObj.policyFeeEnabled === 1) {
          this.policyFeeDefault = Number(riskObj.policyFeeAmount) ?? 0;
        }
        if (riskObj.triaEnabled === true) {
          this.triaAmountDefault =
            (Number(policyPremium) * (Number(riskObj.triaPremiumPerc) * 100)) /
              100 ?? 0;
        }
        this.store.dispatch(
          new CreateQuoteAction.updateUiContents({
            premium: policyPremium,
            startDate: this.effDate,
            endDate: this.expDate,
          }),
        );
        this.TRIAFeeEnabled = this.triaAmount !== 0;
        this.policyFeeEnabled = Number(this.policyAmount || 0) !== 0;
        this.policyFeeForm.controls['policyFee'].setValue(policyFee);
        this.policyFeeForm.controls['triaAmount'].setValue(this.triaAmount);
        this.riskTrnxs = data.data[0].PolicyRiskTrxes;

        this.riskTrnxs.forEach((trnx) => {
          this.policyRiskIds.push(trnx.policyRiskId);
        });

        this.productsIds = data.data.flatMap((dataObj) => {
          const productsIds = dataObj.risk.id || '';
          return productsIds;
        });
        this.spinnerCounter++;
        this.insuredService
          .GetByInsuredId(data.data[0]?.policyPeriod.policy.insuredId)
          .subscribe((data) => {
            this.insured.industry = data.data?.industry.name;
            this.insured.industryClass = data.data?.industryClass.name;

            this.insured.name =
              data.data.insuredType?.toLowerCase() == 'company'
                ? data.data.companyName
                : data.data.firstName +
                  ' ' +
                  data.data.middleName +
                  ' ' +
                  data.data.lastName;
            this.spinnerCounter++;
            this.locationService
              .GetLocationDetailsById(data.data?.insuredLocation.id)
              .subscribe((data) => {
                this.insured.address = data.data?.fulladdress;
                this.spinnerCounter--;
                if (this.spinnerCounter == 0) this.showBodySpinner = false;
              });

            this.totalBilledAnnually = this.getTotalBilledAnnual();
            this.spinnerCounter--;
            if (this.spinnerCounter == 0) this.showBodySpinner = false;
          });

        this.spinnerCounter--;
        if (this.spinnerCounter == 0) this.showBodySpinner = false;
      });
  }

  ngOnDestroy(): void {
    this.alertService.clearAlerts(-1);
  }

  closeAlert() {
    this.showErrorAlert = false;
    this.alertMessage = '';
  }

  getSelectedQuote(options) {
    return options.filter((option) => option.optionSelected == 1);
  }
  getSubTotal() {
    let total = 0;
    this.quoteOptions
      .filter((option) => option.optionSelected == 1)
      .forEach((optn) => {
        total = total + (Number(optn.premium) || 0);
      });
    return total;
  }
  getServices() {
    let total = 0;
    this.quoteOptions
      .filter((option) => option.optionSelected == 1)
      .forEach((optn) => {
        total = total + Number(optn.servicesAmount);
      });
    return total || 0.0;
  }

  getUnderWriter(id) {
    let uw = '';
    this.boxxUserService.GetById(id).subscribe((data) => {
      uw = data.data?.firstName + data.data?.lastName || '';
    });
    return uw;
  }

  getTotalBilledAnnual() {
    const subTotal = this.getSubTotal();
    const service = this.getServices();
    const tax = this.getTaxes();
    return (
      Number(subTotal) +
      Number(service) +
      Number(tax) +
      Number(this.getTRIA()) +
      Number(this.getPolicyFee())
    );
  }

  getTaxes() {
    let total = 0;
    this.quoteOptions
      .filter((option) => option.optionSelected == 1)
      .forEach((optn) => {
        total =
          total +
          Number(optn.taxesPremiumAmount) +
          Number(optn.taxesServicesAmount);
      });
    return total || 0.0;
  }
  handleBackBtn() {
    this.router.navigate(['./product'], {
      relativeTo: this.activatedRoute,
      skipLocationChange: true,
    });
  }

  handleContinue(e) {}

  handleSaveDraft(e) {}

  async getStageId(subDomain: string) {
    this.stageId = undefined;
    return new Promise<void>((resolve) => {
      this.domainsService.GetByDomainCode('DOCMERGESTAGE', true).subscribe({
        next: (response) => {
          let docStage = response.data.filter(
            (template) => template.subdomaincode === subDomain,
          )[0];
          this.stageId = docStage.id;
          resolve();
        },
        error: (error) => {
          this.isLoader = false;
          this.showSendModal = false;
          this.isSendingQuote = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.alertMessage = getErrorMessage(error);
        },
      });
    });
  }

  async populateMessageType(subDomain: string) {
    this.messageTypeId = undefined;
    return new Promise<void>((resolve) => {
      this.domainsService.GetByDomainCode('MESSAGETYPE', true).subscribe({
        next: (response) => {
          let messageType = response.data.filter(
            (template) => template.subdomaincode === subDomain,
          )[0];
          this.messageTypeId = messageType.id;
          resolve();
        },
        error: (error) => {
          this.isLoader = false;
          this.showSendModal = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.alertMessage = getErrorMessage(error);
        },
      });
    });
  }

  redirectToPolicies(message: string): void {
    if (this.isQuoteBounded) {
      this.router.navigate(['dashboard/policies'], {
        skipLocationChange: true,
      });
    } else {
      this.showErrorAlert = message !== '' ? true : false;
      this.alertMessage = message;
    }
  }

  translatesText(text: string): string {
    return this.translate.instant(text);
  }

  generateWarningMessageForErrorAfterQuoteBind(
    defaultMsg: string,
    isSendMailFailed: boolean = false,
  ) {
    let packagePolicyNumber = null;
    let errMsg = defaultMsg;
    if (this.isQuoteBounded) {
      packagePolicyNumber = this.newQuoteService.getPackagePolicyNumber();
      errMsg =
        defaultMsg === ''
          ? ``
          : `${this.translatesText(
              'error.errorAfterBindQuote.policyTxt',
            )} ${packagePolicyNumber} ${this.translatesText(
              'error.errorAfterBindQuote.msg',
            )} ${
              isSendMailFailed
                ? `${this.translatesText(
                    'error.errorAfterBindQuote.processSendMail',
                  )}`
                : `${this.translatesText(
                    'error.errorAfterBindQuote.processGeneratingDocument',
                  )}`
            } ${this.translatesText('error.errorAfterBindQuote.retryText')}`;
      this.newQuoteService.setPolicyBindWarningMsg(errMsg);
    }
    this.redirectToPolicies(errMsg);
  }

  async getDocument() {
    this.policyRiskDocService
      .getPolicyRiskGeneratedDocCore(this.policyPeriodId, this.stageId)
      .subscribe({
        next: (response) => {
          let { data } = response;
          if (Object.entries(data).length === 0) return;

          if (!data.PolicyRiskDocument || data.PolicyRiskDocument.length === 0)
            return;
          if (response?.data?.status === GENERATE_DOC_SUCCESS_STATUS_CODE) {
            this.docDetails = data.PolicyRiskDocument;
          } else {
            this.docDetails = [];
          }
          this.rowDocDetails = data;
        },
        error: (error) => {
          clearTimeout(this.newTimeout);
          this.isLoader = false;
          this.showSendModal = false;
          this.generateWarningMessageForErrorAfterQuoteBind(
            getErrorMessage(error),
          );
        },
      });
  }

  async getMessageTemplates() {
    this.messageTemplateService
      .GetAllMessageTemplates(this.messageTypeId)
      .subscribe({
        next: (response) => {
          this.templateDetails = response?.data ?? [];
          this.isLoader = false;
        },
        error: (error) => {
          this.isLoader = false;
          this.showSendModal = false;
          this.generateWarningMessageForErrorAfterQuoteBind(
            getErrorMessage(error),
          );
        },
      });
  }

  async handleCreateDocument(
    documentSubDomain: string,
    messageSubDomain: string,
  ) {
    this.closeAlert();
    this.docDetails = [];
    this.rowDocDetails = {};
    this.showSendModal = !this.showSendModal;
    this.isLoader = !this.isLoader;
    const interval = 5000;
    const startTime = Date.now();

    if (this.showSendModal) {
      await this.getStageId(documentSubDomain);
      await this.populateMessageType(messageSubDomain);
      await this.getDocument();
      if (this.docDetails.length === 0) {
        this.generateQuoteService
          .generateQuotePdf(this.policyPeriodId)
          .subscribe({
            next: async (response) => {
              while (
                Date.now() - startTime < DOC_GENERATION_WAIT_TIME &&
                this.rowDocDetails?.['status'] !== 1 &&
                this.rowDocDetails?.['status'] !== 2 &&
                (this.docDetails.length === 0 ||
                  this.rowDocDetails?.['status'] === 0)
              ) {
                await this.getDocument();
                await new Promise((resolve) => {
                  this.newTimeout = setTimeout(resolve, interval);
                });
              }
              if (this.docDetails.length === 0) {
                this.isLoader = false;
                this.showSendModal = false;
                this.generateWarningMessageForErrorAfterQuoteBind(
                  `common.docCreateFailMsg`,
                );
              } else {
                await this.getMessageTemplates();
              }
            },
            error: (error) => {
              clearTimeout(this.newTimeout);
              this.isLoader = false;
              this.showSendModal = false;
              this.generateWarningMessageForErrorAfterQuoteBind(
                getErrorMessage(error),
              );
            },
          });
      }
      this.docDetails.length !== 0 && (await this.getMessageTemplates());
    }
  }

  async handleCreateDocumentAfterBind(
    documentSubDomain: string,
    messageSubDomain: string,
  ) {
    this.closeAlert();
    this.docDetails = [];
    this.rowDocDetails = {};
    this.templateDetails = [];
    this.showSendModal = !this.showSendModal;
    this.isLoader = !this.isLoader;
    const interval = 5000;
    const startTime = Date.now();

    if (this.showSendModal) {
      await this.getStageId(documentSubDomain);
      await this.populateMessageType(messageSubDomain);
      await this.getDocument();
      if (this.docDetails.length === 0) {
        while (
          Date.now() - startTime < DOC_GENERATION_WAIT_TIME &&
          this.rowDocDetails?.['status'] !== 1 &&
          this.rowDocDetails?.['status'] !== 2 &&
          (this.docDetails.length === 0 || this.rowDocDetails?.['status'] === 0)
        ) {
          await this.getDocument();
          await new Promise((resolve) => {
            this.newTimeout = setTimeout(resolve, interval);
          });
        }
        if (this.docDetails.length === 0) {
          this.isLoader = false;
          this.showSendModal = false;
          this.generateWarningMessageForErrorAfterQuoteBind(
            `common.docCreateFailMsg`,
          );
        } else {
          await this.getMessageTemplates();
        }
      }
      this.docDetails.length !== 0 && (await this.getMessageTemplates());
    }
  }

  closeSendPackage() {
    clearTimeout(this.newTimeout);
    this.closeAlert();
    this.docDetails = [];
    this.templateDetails = [];
    this.showSendModal = false;
    this.isLoader = false;
    this.generateWarningMessageForErrorAfterQuoteBind('');
  }

  async handleSendQuote() {
    this.isSendingQuote = true;
    await this.getStageId('DOCMERGESTAGE_QUOTE');
    this.docPopupDetails.stageId = this.stageId;
    this.docPopupDetails.policyPeriodId = this.policyPeriodId;
    if (this.stageId) {
      this.DocumentTemplateService.getDocumentTemplateList(
        this.policyRiskId,
        this.stageId,
      ).subscribe({
        next: (response) => {
          if (response?.data.length !== 0) {
            this.docPopupDetails.documentTemplate = response?.data;
            this.handleDocLevelAction('quote');
            this.templateDetails = [];
          } else {
            this.generateWarningMessageForErrorAfterQuoteBind('');
            this.handleCreateDocument(
              'DOCMERGESTAGE_QUOTE',
              'MESSAGETYPE_QUOTE',
            );
          }
          this.isSendingQuote = false;
        },
        error: (error) => {
          this.alertMessage = getErrorMessage(error.error);
          this.isSendingQuote = false;
        },
      });
    } else {
      this.isSendingQuote = false;
      return;
    }
  }

  async policyFeeSwitchHandler(value) {
    if (this.isBindingPolicy) {
      return;
    }
    this.alertMessage = '';
    this.showErrorAlert = false;

    this.policyFeeEnabled = !value;
    try {
      this.policyAmount = this.policyFeeDefault;
      this.policyFeeForm.controls['policyFee'].setValue(this.policyAmount);
      await this.saveTriaOrPolicyFee();
      this.totalBilledAnnually = this.getTotalBilledAnnual();
    } catch (error) {
      if (![500].includes(error?.status)) {
        this.showErrorAlert = true;
        this.alertMessage = getErrorMessage(error);
      }
      this.policyFeeEnabled = value;
    }
  }

  async triaSwitchHandler(value) {
    if (this.isBindingPolicy) {
      return;
    }
    this.alertMessage = '';
    this.showErrorAlert = false;
    this.TRIAFeeEnabled = !value;
    try {
      this.triaAmount = this.triaAmountDefault;
      this.policyFeeForm.controls['triaAmount'].setValue(this.triaAmount);
      await this.saveTriaOrPolicyFee();
      this.totalBilledAnnually = this.getTotalBilledAnnual();
    } catch (error) {
      if (![500].includes(error?.status)) {
        this.showErrorAlert = true;
        this.alertMessage = getErrorMessage(error);
      }
      this.TRIAFeeEnabled = value;
    }
  }

  isNumber(number) {
    return !isNaN(number);
  }

  togglePolicyFeeEdit(event) {
    event.stopPropagation();
    if (this.isBindingPolicy) {
      return;
    }
    if (this.isPolicyFeeEditMode) {
      this.handleFeeChange(event, true);
    } else {
      this.isPolicyFeeEditMode = true;
    }
  }

  toggleTRIAAmountEdit(event) {
    event.stopPropagation();
    if (this.isBindingPolicy) {
      return;
    }
    if (this.isTRIAAmountEditMode) {
      this.handleFeeChange(event, false);
    } else {
      this.isTRIAAmountEditMode = true;
    }
  }

  downloadSummaryHandler() {
    if (this.isBindingPolicy) {
      return;
    }
  }

  isPolicyFeeValid(policyFee) {
    if (
      this.policyFeeEnabled &&
      (!policyFee || policyFee === '0' || policyFee < 1 || policyFee > 100000)
    ) {
      return false;
    }
    return true;
  }

  isTriaAmountValid(triaAmount) {
    if (
      this.TRIAFeeEnabled &&
      (!triaAmount ||
        triaAmount === '0' ||
        triaAmount < 1 ||
        triaAmount > this.policyPremium)
    ) {
      return false;
    }
    return true;
  }

  async handleBindPolicy() {
    if (this.isBindingPolicy) {
      return;
    }
    if (!this.isPolicyFeeValid(this.policyFeeForm.value['policyFee'])) {
      this.showErrorAlert = true;
      this.alertMessage = 'quoteSummary.errors.policyFee';
      return;
    }
    if (!this.isTriaAmountValid(this.policyFeeForm.value['triaAmount'])) {
      this.showErrorAlert = true;
      this.alertMessage = 'quoteSummary.errors.triaAmount';
      return;
    }
    this.isBindingPolicy = true;

    this.bindingStatus = 'process-pay';
    this.alertMessage =
      'One moment - The quote is currently being processed for binding.';
    try {
      await this.handleDocumentTemplatePopup();
    } catch (error) {
      if (![500].includes(error?.status)) {
        this.alertMessage = 'error.somethingWentWrong';
      }
    }
  }

  async callBindAPI() {
    const policyFee = this.getPolicyFee();
    const triaAmount = this.getTRIA();
    try {
      const bindData = {
        policyPeriodId: this.policyPeriodId,
      };

      const createBindServiceObs =
        this.policyBindService.UpdateWithoutId(bindData);
      const bindResponse = await firstValueFrom(createBindServiceObs);
      this.packagePolicyNumber = bindResponse.data.pkgPolicyNumber;
      this.bindingStatus = 'done';
      this.newQuoteService.setPackagePolicyNumber(this.packagePolicyNumber);
      this.store.dispatch(
        new CreateQuoteAction.updateUiContents({
          packagePolicyNumber: this.packagePolicyNumber ?? '',
        }),
      );
      this.isBindingPolicy = false;
      this.isQuoteBounded = true;
    } catch (error) {
      if (![500].includes(error?.status)) {
        this.showErrorAlert = true;
        this.alertMessage = error?.message ?? error?.error?.message ?? '';
      }
      this.isLoader = false;
      this.showSendModal = false;
      this.bindingStatus = 'default';
      this.isBindingPolicy = false;
      this.isQuoteBounded = false;
    }
  }

  getPolicyFee() {
    if (this.policyFeeEnabled) {
      return this.policyFeeForm.value.policyFee || '0';
    }
    return '0';
  }

  getTRIA() {
    if (this.TRIAFeeEnabled) {
      return this.policyFeeForm.value.triaAmount || '0';
    }
    return '0';
  }

  handleSend(formData: any) {
    formData.append('merginStageId', this.stageId);
    this.messageSendService.sendMessage(formData).subscribe({
      next: (response) => {
        this.docDetails = [];
        this.templateDetails = [];
        this.generateWarningMessageForErrorAfterQuoteBind('');
        this.newQuoteService.setPolicyBindWarningMsg('');
        this.showSendModal = !this.showSendModal;
        const alertData = {
          show: true,
          type: 'success',
          headerText: getAlertHead('success'),
          bodyText: 'Quote sent successfully',
        };
        this.alertService.addAlert(alertData);
        this.alertService.clearAlerts(-1);
      },
      error: (error) => {
        this.generateWarningMessageForErrorAfterQuoteBind(
          getErrorMessage(error),
          true,
        );
        this.showSendModal = !this.showSendModal;
      },
    });
  }

  async handleFeeChange(event, isPolicyFeeChange) {
    let policyFee = Number(this.policyFeeForm.value['policyFee']);
    let triaAmount = Number(this.policyFeeForm.value['triaAmount']);

    //TRIA/ Policy Fee validation
    if (!isPolicyFeeChange && !this.isTriaAmountValid(triaAmount)) {
      this.showErrorAlert = true;
      this.alertMessage = 'quoteSummary.errors.triaAmount';
      this.policyFeeForm.controls['policyFee'].setValue(triaAmount);
      return;
    }

    if (isPolicyFeeChange && !this.isPolicyFeeValid(policyFee)) {
      this.showErrorAlert = true;
      this.alertMessage = 'quoteSummary.errors.policyFee';
      this.policyFeeForm.controls['policyFee'].setValue(policyFee);
      return;
    }

    this.showErrorAlert = false;
    this.alertMessage = '';

    //API
    try {
      const bindData: PolicyTRIAUpdate = {
        policyPeriodId: this.policyPeriodId,
        policyFee: Number(policyFee || 0) ?? 0,
        triaAmount: Number(triaAmount || 0) ?? 0,
      };

      const editTriaFeeObj = this.policyRiskTrxService
        .PolicyTRIAUpdate(this.policyPeriodId, bindData)
        .subscribe({
          next: (response) => {
            if (isSuccessApiStatus(response.status)) {
              if (!isPolicyFeeChange) {
                this.isTRIAAmountEditMode = !this.isTRIAAmountEditMode;
                this.totalBilledAnnually = this.getTotalBilledAnnual();
              } else {
                this.isPolicyFeeEditMode = !this.isPolicyFeeEditMode;
                this.totalBilledAnnually = this.getTotalBilledAnnual();
              }
            } else {
              this.showErrorAlert = true;
              this.alertMessage = 'error.somethingWentWrong';
            }
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.alertMessage = getErrorMessage(error);
          },
        });

      this.policyFeeForm.controls['policyFee'].setValue(policyFee);
      this.policyFeeForm.controls['triaAmount'].setValue(triaAmount);
    } catch (error) {
      if (![500].includes(error?.status)) {
        this.showErrorAlert = true;
        this.alertMessage = 'error.somethingWentWrong';
      }
    }
  }

  async saveTriaOrPolicyFee() {
    const policyFee = this.policyFeeForm.value['policyFee'];
    const triaAmount = this.policyFeeForm.value['triaAmount'];

    const bindData: PolicyTRIAUpdate = {
      policyPeriodId: this.policyPeriodId,
      policyFee: this.policyFeeEnabled ? Number(policyFee || 0) ?? 0 : 0,
      triaAmount: this.TRIAFeeEnabled ? Number(triaAmount || 0) ?? 0 : 0,
    };

    const updatedInsuranceCharges = this.policyRiskTrxService.PolicyTRIAUpdate(
      this.policyPeriodId,
      bindData,
    );
    const updateAPIResponse = await firstValueFrom(updatedInsuranceCharges);
  }

  handleDocLevelAction(action) {
    this.docPopupDetails.action = action;
    this.showDocTempSelectionPopup = true;
  }
  async handleSuccessDocTemplate(event) {
    switch (event.action.toLowerCase()) {
      case 'bind':
        await this.callBindAPI();
        this.showDocTempSelectionPopup = false;
        this.handleCreateDocumentAfterBind(
          'DOCMERGESTAGE_BIND',
          'MESSAGETYPE_POLICY',
        );
        break;
      case 'quote':
        this.showDocTempSelectionPopup = false;
        this.handleCreateDocument('DOCMERGESTAGE_QUOTE', 'MESSAGETYPE_QUOTE');
        break;

      default:
        this.handleCloseDocumentPopup();
    }
  }

  async handleDocumentTemplatePopup() {
    await this.getStageId('DOCMERGESTAGE_BIND');
    this.docPopupDetails.stageId = this.stageId;
    this.docPopupDetails.policyPeriodId = this.policyPeriodId;
    if (this.stageId) {
      this.DocumentTemplateService.getDocumentTemplateList(
        this.policyRiskId,
        this.stageId,
      ).subscribe({
        next: async (response) => {
          if (response?.data.length !== 0) {
            this.docPopupDetails.documentTemplate = response?.data;
            this.handleDocLevelAction('bind');
          } else {
            await this.callBindAPI();
            this.handleCreateDocumentAfterBind(
              'DOCMERGESTAGE_BIND',
              'MESSAGETYPE_POLICY',
            );
          }
        },
        error: (error) => {
          this.alertMessage = getErrorMessage(error.error);
        },
      });
    } else {
      return;
    }
  }

  handleCloseDocumentPopup() {
    this.showDocTempSelectionPopup = false;
    this.bindingStatus = 'default';
    clearTimeout(this.newTimeout);
    this.closeAlert();
    this.docDetails = [];
    this.templateDetails = [];
    this.isLoader = false;
    this.generateWarningMessageForErrorAfterQuoteBind('');
    this.isBindingPolicy = false;
    this.isQuoteBounded = false;
  }
}
