<div class="holder">
  <div class="heading-holder">
    <boxxds-heading sz="5" [text]="'insured.heading.insured' | translate"
      ><span></span
    ></boxxds-heading>
    <ng-container
      *ngIf="
        this.permissionList[this.currentScreen] &&
        this.permissionList['Insured']
      "
    >
      <boxxds-button
        [buttonText]="'insured.button.addNewInsured' | translate"
        sz="lg"
        class="add-new-btn"
        (handleClick)="handleAddNewInsured()"
      >
      </boxxds-button>
    </ng-container>
  </div>
  <boxxds-body
    sz="md"
    [text]="'insured.label.insuredType' | translate"
    [customStyle]="{ color: '#616162', textAlign: 'left', marginBottom: '8px' }"
  ></boxxds-body>
  <span class="toggle">
    <button
      class="toggle-menu"
      (click)="onToggle(true, isInsuredTypeCompany)"
      [ngClass]="{ active: isInsuredTypeCompany }"
    >
      {{ "submission.button.company" | translate }}
    </button>
    <button
      class="toggle-menu"
      (click)="onToggle(false, isInsuredTypeCompany)"
      [ngClass]="{ active: !isInsuredTypeCompany }"
    >
      {{ "submission.button.individual" | translate }}
    </button>
  </span>

  <boxxds-textinput
    [form]="form"
    [placeholder]="'insured.placeholder.searchInsuredName' | translate"
    [control]="'searchKey'"
    [id]="'search-insured-name'"
    [leftIcon]="'assets/img/dashboard/search/search.png'"
    maxLength="45"
    [containerStyle]="{ marginBottom: '28px', marginTop: '17px' }"
    (handleKeyUp)="handleSearch($event)"
    [invalidErrMsg]="'insured.error.enterMinChars' | translate"
    [formSubmitted]="enteredOnSearch"
  >
  </boxxds-textinput>
  <boxxds-table
    [data]="tableData"
    [totalCount]="pagination.totalRecords"
    (pageChanged)="handleTablePageChange($event)"
    class="insured-table"
    (sortUpClicked)="handleCompanyTableColSortUp($event)"
    [activePageNumber]="pagination.currentPage"
    (sortDownClicked)="handleCompanyTableColSortDown($event)"
    (rowClicked)="handleInsuredRowClick($event)"
    [isRowClickable]="true"
    id="insured-table"
    [addSortTo]="companyColumnsToSort"
    [tableStyle]="{ marginBottom: '30px' }"
    [showSpinner]="showTblSpinner"
    [styleInactive]="true"
  >
  </boxxds-table>
</div>
