import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, retry, catchError } from 'rxjs';
import { BoxxResponse } from '../entities/boxx-response';
import { BaseService } from './base-services';

@Injectable({
  providedIn: 'root',
})
export class PolicyRiskTrxNotesService extends BaseService<any> {
  constructor(http: HttpClient) {
    const baseurl =
      environment.msPolicyBaseUrl + environment.policyRiskTrxNotesUrl;
    super(http, baseurl);
  }

  getByPolicyRiskTrxId(
    policyRiskTrxId: string,
    page: number = 1,
    limit = 100,
  ): Observable<any> {
    const url = `${this.baseurl}?policyRiskTrxId=${policyRiskTrxId}&page=${page}&limit=${limit}&active=1&sort=createdDt%3Adesc`;

    return this.http
      .get<BoxxResponse<any>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  getByPolicyRiskTrxIdNotesFilter(
    policyRiskTrxId: string,
    queryParams: any,
    page: number = 1,
    limit = 100,
  ): Observable<any> {
    let url = `${this.baseurl}?policyRiskTrxId=${policyRiskTrxId}&page=${page}&limit=${limit}&active=1&sort=createdDt%3Adesc`;
    // Append additional queryParams to the URL
    for (const key in queryParams) {
      if (
        queryParams.hasOwnProperty(key) &&
        queryParams[key] !== undefined &&
        queryParams[key] !== null &&
        queryParams[key] !== ''
      ) {
        url += `&${key}=${queryParams[key]}`;
      }
    }

    return this.http
      .get<BoxxResponse<any>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  getNotesUnderWriterList(
    policyRiskTrxId: number,
    page: number = 1,
    limit = 100,
  ): Observable<any> {
    let url = `${this.baseurl}writer/?policyRiskTrxId=${policyRiskTrxId}&page=${page}&limit=${limit}&active=1&sort=createdDt%3Adesc`;

    return this.http
      .get<BoxxResponse<any>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
