import { Component, OnInit } from '@angular/core';
import { CognitoService } from 'src/app/services/cognito-service';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cognito',
  templateUrl: './cognito.component.html',
  styleUrls: ['./cognito.component.less'],
})
export class Cognito4FlowComponent implements OnInit {
  invalidCode = false;

  constructor(
    private router: Router,
    private activedRoute: ActivatedRoute,
    public cognitoService: CognitoService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.activedRoute.queryParams.subscribe((params) => {
      const code = params['code'];
      this.cognitoService
        .GetAuthToken(code, '4', environment.cognito4FlowRedirectUrl)
        .subscribe(
          (data) => {
            this.router.navigate(['dashboard/home'], { replaceUrl: true });
          },
          (err) => {
            this.invalidCode = true;
          },
          () => console.log('HTTP request completed.'),
        );
    });
  }
}
