<section class="quoteCalculatorAndOptionsWrapper">
  <div class="holder">
    <ng-container *ngIf="showBodySpinner">
      <div class="body-spinner">
        <boxxds-spinner
          [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
        ></boxxds-spinner>
      </div>
    </ng-container>
    <app-quote-action-popup
      [showModal]="showActionPopup"
      [details]="actionPopupDetails"
      (handleActionConfirm)="
        this.permissionList[this.currentScreen] &&
          handleConfirmRiskAction($event)
      "
      (handleClose)="showActionPopup = false"
    >
    </app-quote-action-popup>
    <ng-container *ngIf="!showBodySpinner">
      <ng-container *ngIf="showErrorAlert">
        <boxxds-alert
          *ngFor="
            let errorMessage of removeDuplicatesFromArray(alertErrMsg);
            let i = index
          "
          type="error"
          [headerText]="'policyPeriod.update.error.header' | translate"
          [bodyText]="errorMessage | translate"
          (handleCloseEvent)="closeAlert()"
          [containerStyle]="i !== 0 ? { top: (i + 1) * 85 + 'px' } : null"
        ></boxxds-alert>
      </ng-container>
      <div class="header" *ngIf="form$ | async as form">
        <div
          class="btns-holder d-flex"
          *ngIf="(form$ | async)?.quoteOptions as quoteOptions"
        >
          <app-modal-send
            *ngIf="showSendModal"
            [isLoader]="isLoader"
            [showModal]="showSendModal"
            (handleClose)="closeSendPackage()"
            [formDetails]="form.ui"
            [templateDetails]="templateDetails"
            [docDetails]="docDetails"
            [title]="
              ('quoteDetails.sendingQuoteOption' | translate) +
              ' ' +
              selectedQuoteOptionNumber
            "
            (handleSend)="handleSend($event)"
          >
          </app-modal-send>

          <app-modal-send
            *ngIf="showSendCompareModal"
            [isLoader]="isLoader"
            [showModal]="showSendCompareModal"
            (handleClose)="closeSendPackage()"
            [formDetails]="form.ui"
            [templateDetails]="templateDetails"
            [docDetails]="docDetails"
            [title]="
              ('quoteCompare.sending' | translate) +
              ' ' +
              quoteOptions.length +
              ' ' +
              ('quoteCompare.quoteOptionComparison' | translate)
            "
            (handleSend)="handleSendCompare($event)"
          >
          </app-modal-send>

          <app-modal-sublimit
            *ngIf="showSublimitModal"
            [showModal]="showSublimitModal"
            (handleClose)="closeSublimitModal()"
            [title]="
              ('quoteCalculator.modal.sublimitsModalHeading' | translate) +
              ' ' +
              title
            "
            (handleSubmitCoverageDetails)="handleSubmitCoverageDetails($event)"
            [sublimitOptionsForFinancialCoverage]="
              sublimitOptionsForFinancialCoverage
            "
            [coverageDetails]="coverageDetails"
            [coverageDeductibles]="coverageDeductibles"
            [productDetails]="productDetails"
            [insuredDetals]="insuredDetails"
            [formDetails]="coverageForm"
            [limitOptionsForProducts]="limitOptionsForProducts"
            [occurrenceTypeDropdownValues]="occurrenceTypeDropdownValues"
            [measuresDropdownValues]="measuresDropdownValues"
            [deductibleOptions]="deductibleOptions"
            [showDeductibleCustomField]="showCoverageDeductibleCustomField"
            [quoteOptionId]="quoteOptionIdForSublimitSlideout"
            (handleBackBtn)="handleBackBtn()"
            (handleLimitCustomValue)="
              this.permissionList[this.currentScreen] &&
                this.currentQuoteStatus !== 'quote-closed' &&
                handleLimitCustomValue($event, title)
            "
            (handleRetentionCustomValue)="
              this.permissionList[this.currentScreen] &&
                this.currentQuoteStatus !== 'quote-closed' &&
                handleDeductableCustomValue($event, title)
            "
            (handleQuoteOptionDeductibleChange)="
              this.permissionList[this.currentScreen] &&
                this.currentQuoteStatus !== 'quote-closed' &&
                handleDeductableChange($event, title)
            "
            (handleQuoteOptionLimitChange)="
              this.permissionList[this.currentScreen] &&
                this.currentQuoteStatus !== 'quote-closed' &&
                handleLimitChange($event, title)
            "
            (handlePremiumRecalc)="
              this.permissionList[this.currentScreen] &&
                this.currentQuoteStatus !== 'quote-closed' &&
                handlePremiumCalculateFromSublimit($event)
            "
            (handleQuestionnaireNav)="handleBackBtn()"
            [currency]="currency"
          >
          </app-modal-sublimit>
        </div>
      </div>
      <div class="quote-calc">
        <div
          class="body-spinner spinner-body-fade"
          *ngIf="calledCalculate === 1"
        >
          <boxxds-spinner
            [containerStyle]="{
              width: '112px',
              height: '112px',
              '--b': '15px'
            }"
          ></boxxds-spinner>
        </div>
        <div *ngIf="calledCalculate === 1" class="calc-overlay"></div>
        <div class="calc-header">
          <div class="d-flex">
            <div class="header-text">
              {{
                isEditMode
                  ? ("quoteCalculator.editQuoteOption" | translate) +
                    " " +
                    currentEditQuoteOptnNumber
                  : ("quoteCalculator.createQuoteOptions" | translate)
              }}
            </div>
            <ng-container
              *ngIf="
                this.permissionList[this.currentScreen] &&
                this.currentQuoteStatus !== 'quote-closed'
              "
            >
              <button
                class="clear-values"
                *ngIf="showCalc"
                (click)="handleClear()"
              >
                {{ "common.addOption" | translate }}
              </button>
            </ng-container>
          </div>
          <button class="btn-toggle-calc" (click)="handleCalcToggle()">
            {{ showCalc ? "Hide" : "Show" }}
          </button>
        </div>
        <div class="calc-content" *ngIf="showCalc">
          <table id="tbl-quote-calc">
            <thead>
              <tr class="tbl-hd">
                <th
                  *ngFor="let key of tableHeaders; let indexOfHeaders = index"
                  scope="col"
                  class="th"
                >
                  <div class="tbl-header">
                    {{ key }}
                  </div>
                </th>
              </tr>
            </thead>
            <tr
              *ngFor="
                let product of getSelectedPdtOptions((form$ | async)?.products);
                let i = index
              "
            >
              <td>
                <div class="d-flex align-items-center">
                  <ng-container
                    *ngIf="!product?.standaloneSellable && !isEditMode"
                  >
                    <button class="close-button" (click)="removeExcess()">
                      <img
                        class="btn-del"
                        [src]="'assets/img/dashboard/product/close.png'"
                        alt="close"
                      />
                    </button>
                  </ng-container>
                  <p class="pdt-name">{{ product.key }}</p>
                </div>
                <div
                  class="add-ons"
                  *ngIf="product.addons && product.addons.length > 0"
                >
                  <div class="add-ons-hdr">
                    <p>
                      {{
                        "quoteCalculator.heading.additionalAddons" | translate
                      }}
                    </p>
                    <button
                      class="expand-button"
                      (click)="toggleAddOnsView(product.value)"
                    >
                      <img
                        class="btn-del"
                        [ngClass]="{
                          expanded: getExpandAddOnsVal(product.value)
                        }"
                        src="assets/img/dashboard/quote/arrow-down-circle.png"
                        alt="expand"
                      />
                    </button>
                  </div>
                  <div *ngIf="getExpandAddOnsVal(product.value)">
                    <boxxds-checkbox
                      [options]="getAddOns(product.value, product.addons)"
                      (handleCheckbox)="handleCheckbox($event, product.value)"
                      [containerStyle]="{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '25px',
                        marginLeft: '25px',
                        marginBottom: '26px'
                      }"
                      [lblStyle]="{ color: '#56565C', marginRight: '0px' }"
                    >
                    </boxxds-checkbox>
                  </div>
                </div>

                <!-- Add excess link -->
                <ng-container *ngIf="(form$ | async)?.products as products">
                  <button
                    class="add-excess"
                    *ngIf="product.hasSubProduct"
                    (click)="addExcess(products, product.subProductName)"
                  >
                    {{
                      ("quoteDetails.wantToAddCyberExcessLimit" | translate)
                        ?.replace("{productName}", product.subProductName)
                        .replace("{limit}", cyberExcessLimit | abbreviateNumber)
                    }}
                  </button>
                </ng-container>
              </td>
              <td class="limit">
                <boxxds-dropdown
                  *ngIf="
                    !showLimitCustomField(product.value) &&
                    !isLimitDisabledOnAddSubProduct(product.riskId) &&
                    brokerageCommissionMin[product.riskId]
                  "
                  [placeholder]="'common.select' | translate"
                  [options]="limitOptionsForProducts[product.key]"
                  [dropdownContainerStyle]="{ maxWidth: '150px' }"
                  (currentValueChange)="
                    this.permissionList[this.currentScreen] &&
                      this.currentQuoteStatus !== 'quote-closed' &&
                      handleLimitChange($event, product.value)
                  "
                  [invalidErrMsg]="getLimitErrMsg(product.key)"
                  [currentValue]="
                    getLimitValue(product.riskId)
                      ? (getLimitValue(product.riskId)
                        | currencyFormat: currency : 'symbol-narrow' : '1.0-0')
                      : ''
                  "
                  [isReadonly]="
                    !this.permissionList[this.currentScreen] ||
                    this.currentQuoteStatus === 'quote-closed'
                  "
                  [class.noPointerEvents]="
                    !this.permissionList[this.currentScreen] ||
                    this.currentQuoteStatus === 'quote-closed'
                  "
                >
                </boxxds-dropdown>
                <input
                  *ngIf="
                    product.standaloneSellable &&
                    isLimitDisabledOnAddSubProduct(product.riskId)
                  "
                  [value]="
                    getLimitValue(product.riskId)
                      ? (getLimitValue(product.riskId)
                        | currencyFormat: currency : 'symbol-narrow')
                      : ''
                  "
                  [attr.disabled]="true"
                  class="disabled-field"
                />

                <div
                  class="custom-value"
                  *ngIf="showLimitCustomField(product.value)"
                >
                  <input
                    type="text"
                    mask="separator.0"
                    thousandSeparator=","
                    prefix="$"
                    (blur)="handleLimitCustomValue($event, product.value)"
                    maxlength="14"
                    [autofocus]="true"
                  />
                </div>
              </td>
              <td class="deductables">
                <boxxds-dropdown
                  *ngIf="
                    !showDeductibleCustomField(product.riskId) &&
                    product.standaloneSellable &&
                    brokerageCommissionMin[product.riskId]
                  "
                  [placeholder]="'common.select' | translate"
                  [options]="deductibleOptions"
                  [dropdownContainerStyle]="{ maxWidth: '150px' }"
                  (currentValueChange)="
                    this.permissionList[this.currentScreen] &&
                      this.currentQuoteStatus !== 'quote-closed' &&
                      handleDeductableChange($event, product.value)
                  "
                  [invalidErrMsg]="getDeductibleErrMsg(product.key)"
                  [currentValue]="
                    getDeductibleValue(product.riskId)
                      ? (getDeductibleValue(product.riskId)
                        | currencyFormat: currency : 'symbol-narrow' : '1.0-0')
                      : ''
                  "
                  [disabled]="!product.standaloneSellable"
                  [isReadonly]="
                    !this.permissionList[this.currentScreen] ||
                    this.currentQuoteStatus === 'quote-closed'
                  "
                >
                </boxxds-dropdown>
                <input
                  *ngIf="!product.standaloneSellable"
                  [value]="
                    getDeductibleValue(product?.riskId)
                      ? (getDeductibleValue(product?.riskId)
                        | currencyFormat: currency : 'symbol-narrow')
                      : ''
                  "
                  [attr.disabled]="true"
                  class="disabled-field"
                />
                <div
                  class="custom-value"
                  *ngIf="
                    showDeductibleCustomField(product.riskId) &&
                    product.standaloneSellable
                  "
                >
                  <input
                    type="text"
                    mask="separator.0"
                    thousandSeparator=","
                    prefix="$"
                    maxlength="14"
                    (blur)="handleDeductableCustomValue($event, product.value)"
                    [autofocus]="true"
                  />
                </div>
              </td>
              <td class="broker-commission">
                <div
                  class="stepper-input"
                  [ngClass]="{ 'disabled-input': !product.standaloneSellable }"
                >
                  <boxxds-stepper-input
                    *ngIf="
                      !clearingCalc &&
                      product.standaloneSellable &&
                      brokerageCommissionMin[product.riskId]
                    "
                    [containerStyle]="{ maxWidth: '110px' }"
                    [max]="brokerageCommissionMax[product.riskId]"
                    [min]="brokerageCommissionMin[product.riskId]"
                    [step]="brokerCommissionStep"
                    (handleChange)="
                      this.permissionList[this.currentScreen] &&
                        this.currentQuoteStatus !== 'quote-closed' &&
                        handleBrokerCommissionPerc($event, product)
                    "
                    (handleKeyPress)="
                      this.permissionList[this.currentScreen] &&
                        this.currentQuoteStatus !== 'quote-closed' &&
                        handleBrokerCommissionValidate($event, product)
                    "
                    [value]="getBrokerageCommissionPerc(product.riskId)"
                    [isDisabled]="
                      !product.standaloneSellable ||
                      !this.permissionList[this.currentScreen] ||
                      this.currentQuoteStatus === 'quote-closed'
                    "
                    [readonly]="
                      !this.permissionList[this.currentScreen] ||
                      this.currentQuoteStatus === 'quote-closed'
                    "
                    [class.noPointerEvents]="
                      !this.permissionList[this.currentScreen] ||
                      this.currentQuoteStatus === 'quote-closed'
                    "
                    [isCalcPage]="true"
                  ></boxxds-stepper-input>
                  <input
                    *ngIf="!product.standaloneSellable"
                    [value]="
                      getBrokerageCommissionPerc(product?.riskId)
                        ? getBrokerageCommissionPerc(product?.riskId) + '%'
                        : '0.0%'
                    "
                    [attr.disabled]="true"
                    class="disabled-field"
                    [ngStyle]="{ maxWidth: '110px' }"
                  />

                  <div
                    [ngClass]="{
                      'sublimit-col d-flex justify-content-around': isEditMode
                    }"
                  >
                    <p>{{ brokerageCommissionText[product.riskId] }}</p>
                    <button
                      *ngIf="isEditMode"
                      class="grid-item sublimit d-flex"
                      tabindex="0"
                      (click)="
                        handleSublimitAction(
                          $event,
                          product.key,
                          policyPeriodId,
                          product,
                          i
                        )
                      "
                      (mouseout)="
                        sublimitIconSrc =
                          'assets/img/dashboard/quote/lifecycle/arrow-right-circle.png'
                      "
                      (mouseover)="
                        sublimitIconSrc =
                          'assets/img/dashboard/quote/lifecycle/arrow-right-circle-hover.png'
                      "
                      (focus)="
                        changeIconSrc(
                          'assets/img/dashboard/quote/lifecycle/arrow-right-circle-focus.png'
                        )
                      "
                      (blur)="
                        changeIconSrc(
                          'assets/img/dashboard/quote/lifecycle/arrow-right-circle.png'
                        )
                      "
                    >
                      <div class="label">
                        {{ "common.sublimits" | translate | uppercase }}
                      </div>
                      <img [src]="sublimitIconSrc" width="12px" height="12px" />
                    </button>
                  </div>
                </div>
              </td>
              <td class="btn-calc">
                <ng-container
                  *ngIf="(form$ | async)?.quoteOptions as quoteOptns"
                >
                  <ng-container
                    *ngIf="
                      this.permissionList[this.currentScreen] &&
                      this.currentQuoteStatus !== 'quote-closed'
                    "
                  >
                    <boxxds-button
                      *ngIf="
                        product.standaloneSellable &&
                        showPremiumCalcBtn(product.riskId)
                      "
                      [buttonText]="
                        'quoteCalculator.label.calculate' | translate
                      "
                      btnType="secondary"
                      [isDisabled]="
                        !isCalcBtnEnabled(
                          product.key?.toLowerCase(),
                          data.products
                        )
                      "
                      [ngStyle]="{ maxWidth: '115px' }"
                      (handleClick)="
                        handlePremiumCalc(product.value, quoteOptns.length)
                      "
                    >
                    </boxxds-button>
                  </ng-container>

                  <input
                    type="text"
                    *ngIf="!showPremiumCalcBtn(product.riskId)"
                    [value]="
                      getPremiumValue(product.value)
                        | currency: currency : 'symbol-narrow' : '1.0-0'
                    "
                    disabled
                  />
                  <div
                    *ngIf="
                      !showPremiumCalcBtn(product.riskId) &&
                      isReferral(product.riskId)
                    "
                  >
                    <app-view-referral-reasons
                      [quoteOptionId]="getQuoteOptionId(product.riskId)"
                    ></app-view-referral-reasons>
                  </div>
                </ng-container>
              </td>
              <td
                class="edited-premium"
                [ngStyle]="
                  getDeviationText(product.value) !== '' && {
                    paddingBottom: '0'
                  }
                "
              >
                <div *ngIf="!showPremiumCalcBtn(product.riskId)">
                  <boxxds-dropdown
                    *ngIf="!showEditedPremCustomField(product.value)"
                    placeholder="'common.select' | translate"
                    [options]="editedPremiumOptions"
                    [dropdownContainerStyle]="{ maxWidth: '150px' }"
                    (currentValueChange)="
                      this.permissionList[this.currentScreen] &&
                        this.currentQuoteStatus !== 'quote-closed' &&
                        handleEditedPremium($event, product.value)
                    "
                    [currentValue]="
                      isNumber(getEditedPremium(product.value))
                        ? (getEditedPremium(product.value)
                          | currency: currency : 'symbol-narrow' : '1.0-0')
                        : getEditedPremium(product.value)
                    "
                    [isReadonly]="
                      !this.permissionList[this.currentScreen] ||
                      this.currentQuoteStatus === 'quote-closed'
                    "
                    [class.noPointerEvents]="
                      !this.permissionList[this.currentScreen] ||
                      this.currentQuoteStatus === 'quote-closed'
                    "
                    [hideSelectedValue]="true"
                  >
                  </boxxds-dropdown>
                  <p *ngIf="getDeviationText(product.value) !== ''">
                    {{ getDeviationText(product.value) | translate }}
                  </p>
                  <div
                    class="custom-value"
                    *ngIf="showEditedPremCustomField(product.value)"
                  >
                    <input
                      type="text"
                      [ngStyle]="
                        getEditedPremiumErr(product.value) !== '' && {
                          border: '1px solid #CA3434'
                        }
                      "
                      mask="separator.0"
                      thousandSeparator=","
                      prefix="$"
                      maxlength="11"
                      (blur)="handleEditedPremiumCustVal($event, product.value)"
                      [autofocus]="true"
                      [min]="getEditedPremiumMinVal(product.value)"
                      [max]="getEditedPremiumMaxVal(product.value)"
                    />
                    <div
                      *ngIf="getEditedPremiumErr(product.value) !== ''"
                      class="error-msg"
                    >
                      {{ getEditedPremiumErr(product.value) }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
          <div class="subtotal">
            <div class="subtotal-container">
              <div class="amt-row">
                <div class="subtotal-header">
                  {{ "quote4Flow.subTotalPremium" | translate }}
                </div>
                <div class="premium-amt">
                  <div class="amt" *ngIf="!data.total.premiumCalculated">
                    $_____.__
                  </div>
                  <div
                    class="calculated-amt"
                    *ngIf="data.total.premiumCalculated"
                  >
                    {{
                      data.total.premiumCalculated
                        | currency: currency : "symbol-narrow" : "1.0-0"
                    }}
                  </div>
                  <div class="amt-info">
                    {{ "quoteCalculator.totalAmountInfo" | translate }}
                  </div>
                </div>
                <div class="amt-edited-premium">
                  <div class="amt-edited">
                    {{
                      data.total.premium
                        ? (data.total.premium
                          | currency: currency : "symbol-narrow" : "1.0-0")
                        : "$____.__"
                    }}
                  </div>
                  <div class="amt-info-edited">
                    {{ "quoteCalculator.label.editedPremium" | translate }}
                  </div>
                </div>
              </div>
              <ng-container
                *ngIf="
                  this.permissionList[this.currentScreen] &&
                  this.currentQuoteStatus !== 'quote-closed'
                "
              >
                <div
                  class="btn-row"
                  *ngIf="(form$ | async)?.quoteOptions as quoteOptions"
                >
                  <boxxds-button
                    leftImage="refresh"
                    [buttonText]="
                      'quoteCalculator.label.recalculateAll' | translate
                    "
                    [type]="'submitted'"
                    btnType="primary-outlined"
                    [btnStyle]="{ width: '162px' }"
                    [isDisabled]="
                      !this.permissionList[this.currentScreen] ||
                      !isRecalcEnabled()
                    "
                    (handleClick)="
                      this.permissionList[this.currentScreen] &&
                        handleRecalculate($event)
                    "
                  >
                  </boxxds-button>
                  <boxxds-button
                    [buttonText]="'common.save' | translate"
                    [type]="'submitted'"
                    [btnStyle]="{
                      paddingLeft: '85px',
                      paddingRight: '85px',
                      marginLeft: '24px'
                    }"
                    [isDisabled]="
                      !this.permissionList[this.currentScreen] ||
                      !isSaveEditedPremiumBtnEnabled(quoteOptions.length)
                    "
                    (handleClick)="
                      this.permissionList[this.currentScreen] &&
                        saveEditedPremiumHandler(quoteOptions.length)
                    "
                  >
                  </boxxds-button>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div
        class="quote-options-container"
        *ngIf="(form$ | async)?.quoteOptions as quoteOptions"
      >
        <div class="quote-options-labels">
          <div class="dummy-cell"></div>
          <div class="quote-options-divider-bold"></div>
          <div class="d-flex flex-column">
            <ng-container *ngFor="let product of getProducts(quoteOptions)">
              <div class="product-row">
                <div class="product-name">
                  <button class="btn-toggle" (click)="toggleProduct(product)">
                    <img
                      src="assets/img/dashboard/quote/chevron-up.png"
                      [ngClass]="{ 'chevron-down': !toggle[product] }"
                    />{{ product }}
                  </button>
                </div>
              </div>
              <div class="lbls-pdt-details" *ngIf="toggle[product]">
                <div class="lbl-pdt-details">
                  {{ "common.limit" | translate }}
                </div>
                <div class="lbl-pdt-details">
                  {{ "common.deductible" | translate }}
                </div>
                <div class="lbl-pdt-details">
                  {{ "common.premium" | translate }}
                </div>
                <div class="lbl-pdt-details">
                  {{ "common.commission" | translate }}
                </div>
                <div class="lbl-pdt-details"></div>
              </div>
              <div class="quote-options-divider" style="margin-top: 25px"></div>
            </ng-container>

            <div>
              <div class="product-row">
                <div class="product-name">
                  <button
                    class="btn-toggle"
                    (click)="
                      this.toggle.expandFeeSection =
                        !this.toggle.expandFeeSection
                    "
                  >
                    <img
                      src="assets/img/dashboard/quote/chevron-up.png"
                      [ngClass]="{ 'chevron-down': !toggle.expandFeeSection }"
                    />{{ "common.feesServicesAndTaxes" | translate }}
                  </button>
                </div>
              </div>

              <div class="lbls-pdt-details" *ngIf="toggle.expandFeeSection">
                <div class="lbl-pdt-details">
                  {{ "common.policyFees" | translate }}
                </div>
                <div class="lbl-pdt-details">
                  {{ "common.serviceAmount" | translate }}
                </div>
                <div class="lbl-pdt-details">
                  {{ "common.salesTaxes" | translate }}
                </div>
                <div class="lbl-pdt-details">
                  {{ "common.tria" | translate }}
                </div>
                <div class="lbl-pdt-details">
                  {{ "common.insuranceTaxes" | translate }}
                </div>
              </div>
              <div class="quote-options-divider-bold"></div>
            </div>
            <div>
              <div class="product-row">
                <div class="product-name">
                  <button
                    class="btn-toggle"
                    (click)="
                      this.toggle.expandPremiumSection =
                        !this.toggle.expandPremiumSection
                    "
                  >
                    <img
                      src="assets/img/dashboard/quote/chevron-up.png"
                      [ngClass]="{
                        'chevron-down': !toggle.expandPremiumSection
                      }"
                    />{{ "common.premium" | translate }}
                  </button>
                </div>
              </div>

              <div class="lbls-pdt-details" *ngIf="toggle.expandPremiumSection">
                <div class="lbl-pdt-details lbl-subtotal-premium">
                  {{ "common.subtotalPremium" | translate }}
                </div>
                <div class="lbl-pdt-details">
                  {{ "common.totalAmount" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="!showBodySpinner">
          <div class="quote-options d-flex">
            <app-quote-options
              [quote]="quote"
              [toggle]="toggle"
              [idxOfQuote]="i"
              [domainIdForReferral]="domainIdForReferral"
              [selectedQuoteOptionId]="selectedQuoteOptionId"
              *ngFor="let quote of (form$ | async)?.quoteOptions; let i = index"
              (edit)="handleEdit($event)"
              (quoteDelete)="handleQuoteDelete($event)"
              (duplicateClick)="handleDuplicateClick($event)"
              (quoteSelected)="handleQuoteSelected($event)"
              (openReasonModalPopup)="openReasonModalPopup($event)"
              (reasonModalClosePopup)="handleReasonModalClosePopup()"
              (handleSublimitClick)="
                handleSublimitAction(
                  $event,
                  quote.products[0]?.key,
                  policyPeriodId,
                  quote.products[0],
                  i,
                  quote
                )
              "
            >
            </app-quote-options>
          </div>
        </ng-container>
      </div>

      <app-footer-wrapper>
        <div class="footer-button-list">
          <div class="button-section">
            <button class="linkButton" (click)="handleBackBtn()">
              {{ "common.back" | translate }}
            </button>
            <button class="linkButton" (click)="handleExitFlow()">
              {{ "submission.link.exitTheFlow" | translate }}
            </button>
          </div>
          <div class="button-section">
            <ng-container
              *ngIf="
                this.permissionList[this.currentScreen] &&
                this.currentQuoteStatus !== 'quote-closed'
              "
            >
              <boxxds-button
                *ngIf="!showSpinner"
                [buttonText]="'common.decline' | translate"
                btnType="secondary"
                [btnStyle]="{ width: '122px', marginRight: '12px' }"
                type="submit"
                (handleClick)="handleRiskAction(transactionRiskActions.Decline)"
              >
              </boxxds-button>
            </ng-container>
            <div
              class="btns-holder d-flex"
              *ngIf="
                this.permissionList[this.currentScreen] &&
                this.currentQuoteStatus !== 'quote-closed'
              "
            >
              <ng-container
                *ngIf="(form$ | async)?.quoteOptions as quoteOptions"
              >
                <boxxds-dropdown-menu-button
                  [options]="sendQuoteDropdownOptions"
                  btnType="secondary"
                  (handleValueSelection)="
                    isDropdownActive &&
                      !sendButtonDisabled &&
                      handleSendDropdown($event, quoteOptions)
                  "
                  [placeholder]="
                    modalPopupType === ''
                      ? ('quoteSummary.sendQuote' | translate)
                      : ('quoteSummary.sendComparison' | translate)
                  "
                  [dropdownContainerStyle]="{ width: '192px' }"
                  (handleButtonClick)="handleSendQuote()"
                  [disabled]="sendButtonDisabled || !isDropdownActive"
                  [class.noPointerEvents]="
                    sendButtonDisabled || !isDropdownActive
                  "
                >
                </boxxds-dropdown-menu-button>
              </ng-container>
            </div>
            <ng-container *ngIf="this.permissionList[this.currentScreen]">
              <boxxds-button
                [isDisabled]="isContinueDisabled((form$ | async)?.quoteOptions)"
                [buttonText]="'submission.continueToStep4Of4' | translate"
                btnType="primary"
                (handleClick)="handleContinue($event)"
                [btnStyle]="{ paddingLeft: '20px', paddingRight: '20px' }"
              >
              </boxxds-button>
            </ng-container>
          </div>
        </div>
      </app-footer-wrapper>

      <!-- Document Template selection-->

      <app-doc-template-selection-popup
        *ngIf="showDocTempSelectionPopup"
        [showModal]="showDocTempSelectionPopup"
        [details]="docPopupDetails"
        (handleSuccess)="handleSuccessDocTemplate($event)"
        (handleActionConfirm)="
          this.permissionList[this.currentScreen] &&
            handleDocLevelAction($event)
        "
        (handleClose)="closeSendPackage()"
      ></app-doc-template-selection-popup>
      <!-- Document Template selection-->
    </ng-container>
  </div>
</section>
