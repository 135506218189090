import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, retry, catchError } from 'rxjs';
import { BoxxResponse } from '../entities/boxx-response';
import { BaseService } from './base-services';

@Injectable({
  providedIn: 'root',
})
export class PolicyRiskLimitService extends BaseService<any> {
  constructor(http: HttpClient) {
    const baseurl =
      environment.msPolicyBaseUrl + environment.policyRiskLimitUrl;
    super(http, baseurl);
  }

  getPolicyRiskLimits(policyRiskId: number, limit = 10): Observable<any> {
    const url = `${this.baseurl}?&policyRiskId=${policyRiskId}&limit=${limit}`;

    return this.http
      .get<BoxxResponse<any>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  // PUT
  UpdateBulkCoverageLimits(userId: number, data): Observable<any> {
    const url = `${this.baseurl}`;

    return this.http
      .put<BoxxResponse<any>>(url, data)
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
