import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BoxxResponse, Pagination } from 'src/app/entities/boxx-response';
import { getErrorMessage } from 'src/app/utils/utils';
import { Role } from 'src/app/entities/role';
import { RoleService } from 'src/app/services/roles.service';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { DomainsService } from 'src/app/services/domains.service';
import * as DashboardActionTypes from 'src/app/store/dashboard/dashboard.action';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.less'],
})
export class SecurityRoleComponent implements OnInit {
  form: FormGroup;

  roles: any = [];
  totalDataCount = 0;
  isSearchResult: boolean = false;
  addSortTo: string | Array<string> = 'all';
  pagination: Pagination;
  sortBy: string;
  sortColumn: string = 'id';
  sortDirection: string = 'desc';
  pageNumber: number = 1;
  showTblSpinner: boolean = false;
  showErrorAlert = false;
  msgErrorAlert = '';
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  showBodySpinner: boolean = false;
  userTypes: Array<any> = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private roleService: RoleService,
    private store: Store,
    private domainsService: DomainsService,
  ) {
    this.form = fb.group({
      searchBy: new FormArray([]),
      searchKey: [''],
    });
    this.addSortTo = [
      'Role',
      'System user type',
      'Number of members',
      'Status',
    ];
    this.sortBy = this.sortColumn + ':' + this.sortDirection;
  }

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
    });
    this.showBodySpinner = true;
    this.domainsService.GetByDomainCode('SYSTEMUSERTYPE').subscribe({
      next: (response) => {
        this.showBodySpinner = false;
        this.handleUserTypes(response);
        this.fetchRoles();
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });
  }

  fetchRoles() {
    this.showTblSpinner = true;
    this.roleService
      .GetAllRoles(1, this.pageNumber, 10, this.sortBy)
      .subscribe({
        next: (response) => {
          this.showTblSpinner = false;
          this.setSearchResult(response);
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
  }

  setSearchResult(data: BoxxResponse<Role>) {
    const tableData = data.data?.map((dataObj) => {
      const { name } = dataObj;
      return {
        Role: `${name}`,
        'System user type': this.getType(dataObj.systemUserType),
        'Number of members': `${dataObj.userCount ? dataObj.userCount : 0}`,
        Status: dataObj.active ? 'Active' : 'Inactive',
        ActionView: this.getViewUrl(dataObj.id),
        recordStatus: dataObj.active ? 'ACTIVE' : 'INACTIVE',
      };
    });
    this.roles = tableData.length == 0 ? this.getTableHeaders() : tableData;
    this.totalDataCount = data.pagination.totalRecords;
    this.pagination = data.pagination;
  }

  getType(systemUserType) {
    let type: string = '';
    type = this.userTypes.find((item) => item.id === systemUserType)
      ?.description;
    return type;
  }

  getViewUrl(id) {
    return `dashboard/security/roles/${id}`;
  }

  getTableHeaders() {
    return [{ Role: '', 'System user type': '', 'Number of members': '' }];
  }

  navigateToAddRole($event) {
    this.router.navigate(['dashboard/security/roles/new'], {
      skipLocationChange: true,
    });
  }

  handlePageChange(pageNumber) {
    const searchKey = this.form.value['searchKey'];
    this.pageNumber = pageNumber;
    if (this.pageNumber > 0) {
      this.roleService
        .GetAllRoles(1, this.pageNumber, 10, this.sortBy)
        .subscribe({
          next: (response) => {
            this.setSearchResult(response);
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    }
  }

  mapSortColumns(key) {
    switch (key) {
      case 'Role':
        this.sortColumn = 'name';
        break;
      case 'System user type':
        this.sortColumn = 'SystemUserType.description';
        break;
      case 'Number of members':
        this.sortColumn = 'userCount';
        break;
      case 'Status':
        this.sortColumn = 'active';
        break;
      default:
        this.sortColumn = 'id';
        break;
    }
  }

  handleAscSort(key) {
    if (key) {
      this.mapSortColumns(key);
    }
    this.sortDirection = 'asc';
    this.sortBy = this.sortColumn + ':' + this.sortDirection;
    this.fetchRoles();
  }

  handleDescSort(key) {
    if (key) {
      this.mapSortColumns(key);
    }
    this.sortDirection = 'desc';
    this.sortBy = this.sortColumn + ':' + this.sortDirection;
    this.fetchRoles();
  }
  handleCloseSuccessEvent() {
    this.showErrorAlert = false;
  }

  handleUserTypes(response) {
    const userTypeData = response.data?.map((dataObj) => ({
      description: dataObj.description,
      id: dataObj.id,
    }));
    this.userTypes = [...userTypeData];
    this.store.dispatch(
      new DashboardActionTypes.saveUserTypes({
        userTypes: this.userTypes,
      }),
    );
  }
}
