import { createAction, props } from '@ngrx/store';
import { RegionModel, RiskModel } from 'src/app/models/riskRegion.model';

export const ADD_RISK_REGIONS = '[Risk] Add Risk';
export const CLEAN_REGIONS = '[Region] Clean Risk';
export const ADD_REGIONS = '[Region] Add Regions';
export const RETRIEVE_API_RISK = '[Risk From List/API] Retrieve Risk';
export const RETRIEVE_API_REGIONS_BY_RISK =
  '[Region From List/API] Retrieve Regions';

/**Actions */
export const addRiskSuccess = createAction(
  ADD_RISK_REGIONS,
  props<{ items: ReadonlyArray<RiskModel> }>(),
);

export const cleanRegionsData = createAction(CLEAN_REGIONS);

export const addRegions = createAction(
  ADD_REGIONS,
  props<{ items: ReadonlyArray<RegionModel> }>(),
);

/**Effects */
export const retrievedRiskApi = createAction(RETRIEVE_API_RISK);

export const retrievedRegionsByRiskIdApi = createAction(
  RETRIEVE_API_REGIONS_BY_RISK,
  props<{ riskId: number }>(),
);
