<div
  class="collapsable-container"
  *ngIf="showPremiumDetails || showInsurerDetails || showBrokerDetails"
>
  <!-- BOX 1 -->

  <div
    class="box collapsable-insurer"
    *ngIf="showInsurerDetails && insuredInfo.length > 0"
  >
    <ng-container *ngFor="let insurer of insuredInfo">
      <div class="grid-container">
        <div class="grid-item merged">
          <span>{{ "quote4Flow.businessName" | translate }}</span>
          <p
            class="businessName"
            (mouseenter)="namePopoverOpen = true"
            (mouseleave)="namePopoverOpen = false"
          >
            {{ insurer.companyName | truncateText: 35 : "..." }}
          </p>
          <boxxds-popover
            [contentAsNumberedList]="false"
            [popOverContent]="[insurer.companyName]"
            [showCloseButton]="false"
            [isPopoverOpen]="namePopoverOpen"
            [containerStyle]="{
              padding: '0px 0px 5px 10px',
              maxWidth: '340px',
              width: 'max-content',
              minWidth: '100px',
              left: '0px',
              marginTop: '10px',
              borderRadius: '0',
              zIndex: '6'
            }"
            [dataStyle]="{
              marginTop: '5px',
              marginRight: '10px',
              wordBreak: 'break-word'
            }"
          ></boxxds-popover>
        </div>
        <div
          class="grid-item col-merged"
          [ngClass]="{ 'broker-expanded': isBrokerExpand }"
        >
          <button class="collapse-btn" (click)="toggleShowCard(1)">
            <img src="assets/img/dashboard/quote/chevron-up.png" />
          </button>
        </div>
        <div class="grid-item annual-revenue">
          <span>{{ "quote4Flow.annualRevenue" | translate }}</span>
          <p *ngIf="insurer.revenue">
            {{
              valueInDigits(insurer.revenue)
                | currencyFormat: currency : "symbol-narrow" : "1.2-2"
            }}
          </p>
        </div>
        <div class="grid-item">
          <span>{{ "quote4Flow.industry" | translate }}</span>
          <p
            class="insured-industry"
            (mouseenter)="industryPopoverOpen = true"
            (mouseleave)="industryPopoverOpen = false"
          >
            {{ insurer.industry }}
          </p>
          <boxxds-popover
            [contentAsNumberedList]="false"
            [popOverContent]="[insurer.industry]"
            [showCloseButton]="false"
            [isPopoverOpen]="industryPopoverOpen"
            [containerStyle]="{
              padding: '0px 0px 5px 10px',
              maxWidth: '340px',
              width: 'max-content',
              minWidth: '100px',
              marginTop: '10px',
              borderRadius: '0',
              zIndex: '6',
              left: '0px'
            }"
            [dataStyle]="{
              marginTop: '5px',
              marginRight: '10px',
              wordBreak: 'break-word'
            }"
          ></boxxds-popover>
        </div>
      </div>

      <div
        class="broker-container-expand grid-container"
        *ngIf="isBrokerExpand"
      >
        <div class="grid-item no_of_emp">
          <span>{{ "quote4Flow.#OfEmployees" | translate }}</span>
          <p>{{ insurer.numOfEmployees ? insurer.numOfEmployees : "__" }}</p>
        </div>
        <div class="grid-item industry_class">
          <span>{{ "quote4Flow.industryClass" | translate }}</span>
          <p>{{ insurer.industryClass }}</p>
        </div>
        <div class="grid-item merged-row">
          <span>{{ "quote4Flow.contactDetails" | translate }}</span>
          <p>
            {{ insurer.fullAddress }}
          </p>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- BOX 2 -->

  <div
    class="box collapsable-broker"
    *ngIf="showBrokerDetails && brokerageInfo.length > 0"
  >
    <div class="grid-container" *ngFor="let broker of brokerageInfo">
      <div class="grid-item">
        <span>{{ "quote4Flow.producer" | translate }} </span>
        <p>
          {{ broker.brokerage }} \ {{ broker.branch }} \ {{ broker.producer }}
        </p>
      </div>
    </div>
  </div>

  <!-- BOX 3 -->

  <div class="box collapsable-premium" *ngIf="showPremiumDetails && quoteInfo">
    <ng-container>
      <div class="grid-container premiumTriaGridContainer">
        <div class="grid-item merged premiumTotal">
          <span>{{ "quote4Flow.totalAmount" | translate }}</span>
          <p>{{ quoteInfo.totalInvoiceAmount | currencyFormat: currency }}</p>
        </div>

        <div class="grid-item">
          <span>{{ "quote4Flow.tria" | translate }}</span>
        </div>

        <div class="grid-item input-grid-item">
          <boxxds-body
            *ngIf="!isTRIAAmountEditMode && TRIAFeeEnabled"
            sz="sm"
            [text]="getTRIA() | currency: currency"
            [customStyle]="{
              color: '#333334',
              textAlign: 'left',
              textTransform: 'uppercase'
            }"
          >
          </boxxds-body>
          <ng-container *ngIf="isTRIAAmountEditMode && TRIAFeeEnabled">
            <boxxds-textinput
              [validatePositiveInteger]="true"
              [inputAutoFocus]="true"
              [form]="policyFeeForm"
              [placeholder]="'quoteSummary.enterTria' | translate"
              [control]="'triaAmount'"
              [id]="'triaAmount'"
              [width]="'100%'"
              mask="separator.2"
              maxLength="10"
              thousandSeparator=","
              [containerStyle]="{ maxWidth: '125px', marginTop: '-8px' }"
              [invalidErrMsg]="'quoteSummary.errors.triaAmount' | translate"
              (handleBlur)="toggleTRIAAmountEdit($event, true)"
            >
            </boxxds-textinput>
          </ng-container>
          <button
            *ngIf="TRIAFeeEnabled && currentQuoteStatus !== 'quote-closed'"
            class="btn-edit"
            (click)="toggleTRIAAmountEdit($event)"
          >
            <img src="assets/img/edit-icon.png" alt="edit" />
          </button>
        </div>

        <div class="grid-item">
          <boxxds-switch
            [disabled]="currentQuoteStatus === 'quote-closed'"
            name="tria-fee"
            (changed)="triaSwitchHandler(TRIAFeeEnabled)"
            [ngStyle]="{ marginBottom: '0px' }"
            [checked]="TRIAFeeEnabled"
          >
          </boxxds-switch>
        </div>

        <div
          class="grid-item merged"
          [ngClass]="{ 'premium-expanded': isPremiumExpand }"
        >
          <button class="collapse-btn" (click)="toggleShowCard(2)">
            <img src="assets/img/dashboard/quote/chevron-up.png" />
          </button>
        </div>

        <div class="grid-item">
          <span>{{ "quote4Flow.policyFee" | translate }}</span>
        </div>

        <div class="grid-item input-grid-item">
          <boxxds-body
            *ngIf="!isPolicyFeeEditMode && policyFeeEnabled"
            sz="sm"
            [text]="getPolicyFee() | currency: currency"
            [customStyle]="{
              color: '#333334',
              textAlign: 'left',
              textTransform: 'uppercase'
            }"
          >
          </boxxds-body>
          <ng-container *ngIf="isPolicyFeeEditMode && policyFeeEnabled">
            <boxxds-textinput
              [validatePositiveInteger]="true"
              [inputAutoFocus]="true"
              [form]="policyFeeForm"
              [placeholder]="'quoteSummary.enterPolicyFees' | translate"
              [control]="'policyFee'"
              [id]="'policyFee'"
              [width]="'100%'"
              mask="separator.2"
              maxLength="10"
              thousandSeparator=","
              [containerStyle]="{ maxWidth: '125px', marginTop: '-8px' }"
              [invalidErrMsg]="'quoteSummary.errors.policyFee' | translate"
              (handleBlur)="togglePolicyFeeEdit($event, true)"
            >
            </boxxds-textinput>
          </ng-container>
          <button
            *ngIf="policyFeeEnabled && currentQuoteStatus !== 'quote-closed'"
            class="btn-edit"
            (click)="togglePolicyFeeEdit($event)"
          >
            <img src="assets/img/edit-icon.png" alt="edit" />
          </button>
        </div>

        <div class="grid-item">
          <boxxds-switch
            [disabled]="currentQuoteStatus === 'quote-closed'"
            name="policy-fee"
            (changed)="policyFeeSwitchHandler(policyFeeEnabled)"
            [ngStyle]="{ marginBottom: '5px', marginTop: '5px' }"
            [checked]="policyFeeEnabled"
          >
          </boxxds-switch>
        </div>
      </div>

      <div
        class="premium-container-expand flex-container"
        *ngIf="isPremiumExpand"
      >
        <div class="grid-item">
          <span>{{ "quote4Flow.subTotalPremium" | translate }}: </span>
          <p>{{ quoteInfo.premium | currency: currency }}</p>
        </div>
        <div class="grid-item">
          <span>{{ "quote4Flow.services" | translate }}: </span>
          <p>{{ quoteInfo.servicesAmount | currency: currency }}</p>
        </div>
        <div class="grid-item">
          <span>{{ "quote4Flow.taxes" | translate }}: </span>
          <p>{{ quoteInfo.taxesPremiumAmount | currency: currency }}</p>
        </div>
      </div>
    </ng-container>
  </div>
</div>
