import { Component, OnInit, Input } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import { DomainsService } from 'src/app/services/domains.service';
import { InsuredService } from 'src/app/services/insured.service';
import { RoleService } from 'src/app/services/roles.service';
import { SystemUserService } from 'src/app/services/system-user.service';
import { arrayToObjet } from 'src/app/utils/utils';
import { BoxxResponse } from 'src/app/entities/boxx-response';
import { Role } from 'src/app/entities/role';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { regexHelpers, getErrorMessage } from 'src/app/utils/utils';
import { getUserTypes } from 'src/app/store/dashboard/dashboard.selector';
import { Store, select } from '@ngrx/store';
import { SYSTEM_USER_TYPES } from 'src/app/constants/security/systemUserType';

@Component({
  selector: 'app-new-system-insured-user',
  templateUrl: './new-system-insured-user.component.html',
  styleUrls: ['./new-system-insured-user.component.less'],
})
export class NewSystemInsuredUserComponent implements OnInit {
  @Input() systemUserType: string;

  insuredUsers: Array<any> = [];

  insuredTypes: Array<any> = [
    { key: 'Company', value: 'company' },
    { key: 'Person', value: 'Person' },
  ];

  roles: Array<any> = [];

  tInsuredUsers: any;
  tInsuredTypes: any;
  tRoles: any;

  insuredUserForm = new FormGroup({
    insuredId: new FormControl('', [Validators.required]),
    insuredType: new FormControl('', [Validators.required]),
    roleId: new FormControl('', [Validators.required]),
    loginEmail: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(regexHelpers.MailId),
    ]),
    password: new FormControl(''),
    systemUserType: new FormControl(0),
    status: new FormControl('string'),
    apiUser: new FormControl(true),
  });

  requiredErrMsg: string = 'error.thisFieldIsRequired';
  invalidErrMsg: string = 'error.thisFieldIsInvalid';

  invalidName: string = '';
  invalidRole: string = '';

  isSuccess = false;
  hasError = false;
  errorMessage = '';

  formSubmitted: boolean = false;

  insuredUser = '';
  insuredType = '';
  roleId = '';

  insuredTypeId: number;

  showSpinner: boolean = false;

  showErrorAlert = false;
  msgErrorAlert = '';
  userID: number;
  selectedSystemUserType: number = 0;

  constructor(
    private router: Router,
    private boxxUser: BoxxUserService,
    private roleService: RoleService,
    private systemUser: SystemUserService,
    private insuredService: InsuredService,
    private domainService: DomainsService,
    private localStorageService: LocalStorageService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.userID = this.localStorageService.getBoxxUserId();
    this.store.pipe(select(getUserTypes)).subscribe(async (data) => {
      this.selectedSystemUserType = data.find(
        (item) =>
          this.toLowerWithoutSpace(item.description) ===
          this.toLowerWithoutSpace(SYSTEM_USER_TYPES.Insured),
      )?.id;
    });
    this.insuredUserForm
      .get('systemUserType')
      .setValue(this.selectedSystemUserType);
    this.fetchInsureTypes();
  }

  toLowerWithoutSpace(input) {
    let result = input.replace(/[A-Z]/g, (match) => ' ' + match.toLowerCase());
    result = result.replace(/\s+/g, '');
    return result;
  }

  searchInsureds(name) {
    this.insuredUserForm.get('insuredId').setValue(null);
    this.invalidName = '';
    if (name.length >= 3) {
      this.fetchInsureds(this.insuredTypeId, name);
    } else {
      this.insuredUsers = [];
    }
  }

  fetchInsureds(insuredTypeId, name = null) {
    this.insuredService.GetAllByInsuredType(insuredTypeId, name).subscribe({
      next: (response) => {
        const tableData = response.data?.map((dataObj) => {
          if (this.insuredTypeId == 1) {
            return {
              key: `${dataObj.firstName} ${dataObj.lastName}`,
              value: `${dataObj.firstName} ${dataObj.lastName}`,
              id: dataObj.id,
            };
          } else {
            return {
              key: `${dataObj.companyName} `,
              value: `${dataObj.companyName} `,
              id: dataObj.id,
            };
          }
        });
        this.insuredUsers = [...tableData];
        if (insuredTypeId == 1) {
          const obj = response.data.reduce((accumulator, value) => {
            return {
              ...accumulator,
              [value['firstName'] + ' ' + value['lastName']]: value,
            };
          }, {});
          this.tInsuredUsers = arrayToObjet(response.data, 'id');
        } else {
          this.tInsuredUsers = arrayToObjet(response.data, 'id');
        }
        if (tableData.length > 0) {
          this.invalidName = 'systemUser.error.invalidInsuredName';
        } else {
          this.invalidName = '';
        }
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });
  }

  fetchInsureTypes() {
    this.domainService.GetByDomainCode('ENTITYTYPE').subscribe({
      next: (response) => {
        const tableData = response.data?.map((dataObj) => ({
          key: `${dataObj.description}`,
          value: dataObj.id,
        }));
        this.insuredTypes = [...tableData];
        this.tInsuredTypes = arrayToObjet(response.data, 'id');
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });
  }

  onSubmit(data) {
    this.handleCloseSuccessEvent();
    this.formSubmitted = true;
    this.hasError = false;
    // check boxx user form is invalid
    if (this.insuredUserForm.invalid) {
      return;
    }
    this.showSpinner = true;

    this.systemUser.CreateSystemUser(data).subscribe(
      (response) => {
        this.showSpinner = false;
        this.isSuccess = true;
        this.formSubmitted = false;
        this.resetinsuredForm();
      },
      ({ error }) => {
        this.showSpinner = false;
        this.hasError = true;
        this.errorMessage = error.message ? error.message : error.error;
      },
    );
  }

  handleCloseSuccessEvent() {
    this.isSuccess = false;
    this.hasError = false;
  }

  resetinsuredForm() {
    // this.insuredUserForm.reset();
    this.insuredUserForm.get('insuredId').setValue(null);
    this.insuredUserForm.get('insuredType').setValue(null);
    this.insuredUserForm.get('roleId').setValue(null);
    this.insuredUserForm.get('loginEmail').setValue(null);
    this.insuredUserForm.get('password').setValue(null);

    this.insuredUser = '';
    this.insuredType = '';
    this.roleId = '';
  }

  insureUserValueChanged(event) {
    this.invalidName = '';
    if (this.insuredTypeId == 1) {
      const id = this.insuredUsers.find((x) => x.value == event).id;
      const user = this.tInsuredUsers[id];
      this.insuredUser = `${user.firstName} ${user.lastName}`;
      this.insuredUserForm.get('insuredId').setValue(user.id);
    } else {
      const id = this.insuredUsers.find((x) => x.value == event).id;
      const user = this.tInsuredUsers[id];
      this.insuredUser = `${user.companyName}`;
      this.insuredUserForm.get('insuredId').setValue(user.id);
    }
  }

  insureTypeValueChanged(event) {
    this.insuredUserForm.get('insuredId').setValue(null);
    this.insuredUsers = [];
    this.insuredUser = '';
    const insuredType = this.tInsuredTypes[event];
    this.insuredType = `${insuredType.description}`;
    this.insuredTypeId = event;
    // this.fetchInsureds(this.insuredTypeId);
  }

  boxxRoleValueChanged(event) {
    const id = this.roles.find((x) => x.value == event).id;
    const role = this.tRoles[id];
    this.roleId = `${role.name}`;
    this.invalidRole = '';
    this.insuredUserForm.get('roleId').setValue(role.id);
  }

  searchRoles(firstName) {
    // reset form group's boxxUserId value
    this.invalidRole = '';
    this.insuredUserForm.get('roleId').setValue(null);
    if (firstName.length >= 3) {
      this.roleService
        .GetByNameNsystemUserType(firstName, this.systemUserType)
        .subscribe({
          next: (response) => {
            this.setRoleSearchResult(response, firstName);
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    } else {
      this.roles = [];
    }
  }

  setRoleSearchResult(data: BoxxResponse<Role>, name = '') {
    const tableData = data.data?.map((dataObj) => ({
      key: `${dataObj.name}`,
      value: `${dataObj.name}`,
      id: dataObj.id,
    }));
    this.roles = [...tableData];
    this.tRoles = arrayToObjet(data.data, 'id');
    if (tableData.length > 0) {
      this.invalidRole = 'systemUser.error.invalidRole';
    } else {
      this.invalidRole = '';
    }
  }
  handleCloseSuccessEventExt() {
    this.showErrorAlert = false;
  }
}
