import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-brokerage-redirect-modal',
  templateUrl: './brokerage-redirect-modal.component.html',
  styleUrls: ['./brokerage-redirect-modal.component.less'],
})
export class BrokerageRedirectModalComponent implements OnInit {
  @Input() headerText: string = 'Save insured information.';
  @Input() insured: string;
  @Input() showBrokerRedirectModal: boolean = false;

  @Output() saveInsured = new EventEmitter<any>();
  @Output() closeModal = new EventEmitter<any>();

  constructor(private router: Router) {}

  ngOnInit(): void {
    console.log();
  }

  handleCloseModal() {
    this.closeModal.emit();
  }

  handleredirectToBroker() {
    this.showBrokerRedirectModal = false;
    this.saveInsured.emit();
  }
}
