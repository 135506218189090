import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Pagination } from 'src/app/entities/boxx-response';
import { Insured } from 'src/app/entities/insured';
import { InsuredCompanyPostRequest } from 'src/app/entities/insured-company';
import { InsuredIndividualPostRequest } from 'src/app/entities/insured-individual';
import { Domains } from 'src/app/models/domains';
import { Breadcrumb } from 'src/app/models/breadcrumb.model';
import { Quote } from 'src/app/models/quote.model';
import { DomainsService } from 'src/app/services/domains.service';
import { IndustryClassService } from 'src/app/services/industry-class.service';
import { IndustryService } from 'src/app/services/industry.service';
import { LocationService } from 'src/app/services/location.service';
import { RegionService } from 'src/app/services/region.service';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import {
  retrievedInsuredApi,
  retrievedInsuredsByCompany,
} from 'src/app/store/create-quote/create-quote.action';
import {
  getInsuredCompanySelector,
  getInsuredIndividualSelector,
  getPaginationSelector,
  getQuoteSelector,
} from 'src/app/store/create-quote/create-quote.selector';
import { arrayToObjet, removeDuplicatedKeys } from 'src/app/utils/utils';

@Component({
  selector: 'app-permission-new-system-user',
  templateUrl: './new-system-user.component.html',
  styleUrls: ['./new-system-user.component.less'],
})
export class NewPermissionSystemUserComponent implements OnInit {
  itemsMenu: Breadcrumb[] = [
    { label: 'Roles & permissions', path: '' },
    { label: 'New system user', path: null },
  ];
  dropdownOptions: Array<any> = [
    { key: 'Boxx user', value: 'boxxuser' },
    { key: 'Producer', value: 'producer' },
    { key: 'Insured', value: 'insured' },
  ];

  options: Array<any> = [{ key: 'select', value: 'select' }];

  roleOptions: Array<any> = [{ key: 'Systems admin', value: 'Systems admin' }];

  form: FormGroup;
  form$: Observable<Quote>;
  searchKey;

  companySearchData: Observable<any>;
  companyColumnsToSort: Array<string> = ['Name', 'Website'];
  noOfEmpOptns: any;
  numberOfEmp: any;
  noOfPIIRecordOptns: any;
  noOfPIIRecord: any;
  businessSectorOptns: any;
  businessIndustryClass = [];
  businessIndustry = [];
  requiredErrMsg: string = 'This field is required';
  invalidErrMsg: string = 'This field is invalid';
  submitted: boolean = false;
  totalDataCount = 0;
  showIndividualSearchRslt: boolean = false;
  addCustomerExpanded: boolean = false;
  householdMemberOptns: any;
  householdMember: any;
  individualSearchData: Observable<any>;
  customerColumnsToSort: Array<string> = ['Name', 'Email'];
  pagination: Observable<Pagination>;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activedRoute: ActivatedRoute,
    private store: Store,
    private regionService: RegionService,
    private industryService: IndustryService,
    private industryClassService: IndustryClassService,
    private domainsService: DomainsService,
    private locationSercice: LocationService,
  ) {
    this.form = this.fb.group({
      companySearchForm: this.fb.group({
        businessName: [''],
        website: [''],
        address: [''],
      }),
    });
  }
  dropdownValueChanged(e) {}
  get companySearchFormCntrl() {
    return (<FormGroup>this.form.get('companySearchForm')).controls;
  }
  get individualSearchFormCntrl() {
    return (<FormGroup>this.form.get('individualSearchForm')).controls;
  }

  get addCompanyForm() {
    return this.form.get('addCompanyForm') as FormGroup;
  }

  get companySearchForm() {
    return this.form.get('companySearchForm') as FormGroup;
  }

  get addIndividualForm() {
    return this.form.get('addIndividualForm') as FormGroup;
  }

  get individualSearchForm() {
    return this.form.get('individualSearchForm') as FormGroup;
  }

  ngOnInit(): void {
    this.form$ = this.store.pipe(select(getQuoteSelector));

    this.domainsService
      .GetByDomainCode('RANGENUMEMPLOYEES')
      .subscribe((data) => {
        const tableData = data.data.data.map((dataObj) => ({
          key: dataObj.description,
          value: dataObj.description,
        }));
        this.noOfEmpOptns = tableData;
        this.numberOfEmp = arrayToObjet(data.data.data, 'description');
      });

    this.domainsService
      .GetByDomainCode('RANGENUMHOUSEMEMBERS')
      .subscribe((data) => {
        const tableData = data.data.data?.map((dataObj) => ({
          key: dataObj.description,
          value: dataObj.description,
        }));

        this.householdMemberOptns = tableData;
        this.householdMember = arrayToObjet(data.data.data, 'description');
      });

    this.domainsService
      .GetByDomainCode('RANGENUMPIIRECORDS')
      .subscribe((data) => {
        const tableData = data.data.data?.map((dataObj) => ({
          key: dataObj.description,
          value: dataObj.description,
        }));

        this.noOfPIIRecordOptns = tableData;
        this.noOfPIIRecord = arrayToObjet(data.data.data, 'description');
      });
    this.industryClassService.GetAll().subscribe((data) => {
      const tableData = data.data?.map((dataObj) => ({
        key: dataObj.name,
        value: dataObj.name,
      }));

      data.data = data.data.reverse();
      this.businessIndustryClass = data.data.reduce((accumulator, value) => {
        return { ...accumulator, [value.name]: value };
      }, {});

      this.businessSectorOptns = removeDuplicatedKeys(tableData);
    });
  }

  getSearchKeyWords(keywordObj = {}) {}

  addUser(event) {}

  handleNoOfPIIRecords(event) {}
}
