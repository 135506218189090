import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Input,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { firstValueFrom, Observable, Subject, take, takeUntil } from 'rxjs';
import { ViewportScroller } from '@angular/common';
import { Actions, ofType } from '@ngrx/effects';
import { Pagination } from 'src/app/entities/boxx-response';
import { InsuredIndividualPostRequest } from 'src/app/entities/insured-individual';
import { Quote } from 'src/app/models/quote.model';
import { InsuredService } from 'src/app/services/insured.service';
import * as InsuredAction from 'src/app/store/insured/insured.action';
import { LocationService } from 'src/app/services/location.service';
import { PolicyMarketReservationService } from 'src/app/services/policy-market.service';
import { RegionService } from 'src/app/services/region.service';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import {
  getInsuredIndividualSelector,
  getPaginationSelector,
  getQuoteSelector,
} from 'src/app/store/create-quote/create-quote.selector';
import {
  arrayToObjet,
  getErrorMessage,
  postalCodeValidator,
  regexHelpers,
} from 'src/app/utils/utils';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { NewQuoteService } from 'src/app/services/new-quote.service';
import { PolicyPeriodService } from 'src/app/services/policy-period.service';
import { formatDateMoment } from 'src/app/utils/formatDate';
import { AlertService } from 'src/app/services/alert.service';
import {
  getCountrySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import { CANADA, UNITED_STATES } from 'src/app/constants/location-constant';
import { DomainsService } from 'src/app/services/domains.service';
import { MapService } from 'src/app/services/map.service';
import { DOMAIN_CODE_RANGENUMHOUSEMEMBERS } from 'src/app/constants/quote-constant';
import { LanguageService } from 'src/app/services/language.service';
import { UiContentService } from 'src/app/services/uiContent.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { PolicyDashboardService } from 'src/app/services/policy-dashboard.service';
import { isStatusDisabled } from 'src/app/constants/quoteStatus';
import { REVENUE_MARGIN_VALUE } from 'src/app/constants/submission-constant';
import { BrokerageProducerService } from 'src/app/services/brokerage-producer.service';
import { alertDetails } from 'src/app/entities/common';
import { RiskRegionService } from 'src/app/services/risk-region.service';

@Component({
  selector: 'app-individual-insured-form-workflow3',
  templateUrl: './individual-insured-form-workflow3.component.html',
  styleUrls: ['./individual-insured-form-workflow3.component.less'],
})
export class IndividualInsuredFormWorkflow3Component
  implements OnInit, AfterContentChecked, AfterViewInit, OnDestroy
{
  @Input() regionId: number;
  @Input() alertInfo;
  @Input() form: FormGroup;
  @Input() form$: Observable<Quote>;
  @Input() checkValidation: boolean = false;
  @Input() insuredId;
  @Input() productId;
  @Input() productName;
  @Input() isActive: boolean = true;
  @Input() fromSubmissionFlow: boolean = false;
  @Input() isExistingInsuredFormEdit: boolean = false;
  @Input() disableProduct: boolean = false;

  // @Output() handleContinue = new EventEmitter<any>();
  @Output() enableProducerInfo = new EventEmitter<any>();
  @Output() selectInsured = new EventEmitter<any>();
  @Output() clearInsuredForm = new EventEmitter<any>();
  @Output() populateLatestInsured = new EventEmitter<any>();

  searchSubmitted: boolean = false;
  householdMemberOptns: any;
  householdMember: any;
  individualSearchData: Observable<any>;
  customerColumnsToSort: Array<string> = ['Name', 'Email'];
  pagination: Observable<Pagination>;
  isInsuredTypeCompany: boolean;
  individuaLocationlRegionIdSelected: number = 0;
  showBanner = false;
  bannerMsg = '';
  errorAPIMsg = 'error.errorAPIMsg';
  colIndividualHeader = {
    Name: { key: 'firstName', order: '' },
    Email: { key: 'email', order: '' },
    Address: { key: 'address', order: '' },
  };
  sortingIndividualKeys = '';
  boxxUserId;
  activeIndividualSearchPgNo = 1;
  hasMinimumCharacter: boolean = false;
  showBodySpinner: number = 0;
  showSpinner: boolean = false;
  isLoaded: boolean = false;
  percentageRegex = regexHelpers.percentage;
  industryClass = {};
  selectedIndustryId = 0;
  linkIconSrc: string = '';
  sanctionPayload = [];
  private ngUnsubscribe$ = new Subject<void>();
  isEditMode = false;
  insuredName = '';
  quoteExist: number = 0; // 0- No, 1-exist with selected producer, 2-exist with different producer
  actionPopupDetails: {
    quoteNumber: string;
    effectiveDate: string;
    insured?: string;
    quotePeriod?: {
      effectiveDt: string;
      expiryDt: string;
    };
    producerId?: number;
    producerName?: string;
    branchId?: number;
    branchName?: string;
    brokerageId?: number;
    brokerageName?: string;
  };
  formDataAddIndividualForm: any;
  policyPeriodId;
  showRecalcConfModal: boolean = false;
  latestFormData;
  showSanctionModal = false;
  formDataBack: any;
  isOfacError = false;
  shortDateFormat: string = '';
  longDateFormat: string = '';

  showMarketReservationModal = false;
  showStateChangeModal = false;
  showRiskAnalysisUpdateModal = false;

  columnsToHide = ['currentState'];
  sortableColumns = [];
  iconColumns: string[] = ['Reasons'];
  customColumnWidth: {
    columnName: string;
    minWidth: string;
    maxWidth: string;
  }[] = [
    {
      columnName: 'Type',
      minWidth: '112',
      maxWidth: '120',
    },
    {
      columnName: 'Description',
      minWidth: '250',
      maxWidth: '250',
    },
  ];
  revenueMarginValue = REVENUE_MARGIN_VALUE;
  debounceTimer: any;

  //************************************************************* */

  insuredOptns: any = [];
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  country: string = '';
  shortDateTimeFormat: string = '';
  longDateTimeFormat: string = '';
  isNavigatedFromRiskAnalysis = false;
  insuredData = null;
  insuredDataIndividual = null;
  initialFormData = null;
  initialFormDataIndividual = null;
  billingCompanyContactId = null;
  enableConfirmButton = false;
  regionCode = '';
  alertData = {
    show: false,
    type: '',
    header: '',
    body: '',
  };
  formSubmitted: boolean = false;

  listRegions: any[] = [];
  regionTotalRecords = 0;
  regionTotalPages = 0;
  countrySelected = '';
  address: string;

  provinceOrStateList = [];
  userID: number;
  province: string;
  zip: string;
  mask: string = 'S0S 0S0';
  locations: any;
  currentProvinceObj: {
    id: number;
    value: string;
    key: string;
    subKey?: string;
    img?: string;
    code?: string;
  };

  insuredTypeForm: FormGroup<any>;
  typeBusinessInsured: boolean = true;
  mockExternalAPIs = true;
  isReferral: boolean = false;
  quoteFlowUrl = '';
  showExistingQuoteModal = false;
  activeQuoteId = null;
  selectedFromSuggestion = false;
  isSuggestedOption = false;
  insuredLocationId = null;
  firstNameAutoFocus = false;
  showAddressField = true;
  showAddress = false;
  addressAutoFocus = false;
  products = [];
  selectedProductOption;
  selectedRegionOption;
  isRestrictedRoleSubmission: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private viewportScroller: ViewportScroller,
    private regionService: RegionService,
    private actions$: Actions,
    private insuredService: InsuredService,
    private locationService: LocationService,
    private policyMarketReservationService: PolicyMarketReservationService,
    private changeDetector: ChangeDetectorRef,
    private localStorageService: LocalStorageService,
    private newQuoteService: NewQuoteService,
    private policyPeriodService: PolicyPeriodService,
    private alertService: AlertService,
    private domainsService: DomainsService,
    private mapService: MapService,
    private languageService: LanguageService,
    private uiContentService: UiContentService,
    private translateService: TranslateService,
    private policyDashboardService: PolicyDashboardService,
    private brokerageProducerService: BrokerageProducerService,
    private riskRegionService: RiskRegionService,
  ) {}

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  ngAfterViewInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
    this.firstNameAutoFocus = true;
  }

  // get addIndividualForm() {
  //   return this.form.get('addIndividualForm') as FormGroup;
  // }

  hasEmptyValueOrInvalid(...values) {
    return values.some((value) => {
      return value === '' || !this.percentageRegex.test(value);
    });
  }

  async ngOnInit(): Promise<void> {
    this.showBodySpinner++;
    if (
      this.localStorageService.getMockExternalAPIs() === 'false' ||
      environment.mockExternalAPIs === false
    ) {
      this.mockExternalAPIs = false;
    }
    this.firstNameAutoFocus = true;
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.isActive = this.permissionList[this.currentScreen] ?? false;
      this.country = data.country;
      this.shortDateTimeFormat = data.shortDateTimeFormat;
      this.longDateTimeFormat = data.longDateTimeFormat;
      this.isRestrictedRoleSubmission = data.isRestrictedRoleSubmission;
    });
    this.form$ = this.store.select(getQuoteSelector);
    this.form$.subscribe((event) => {
      ({ isNavigatedFromRiskAnalysis: this.isNavigatedFromRiskAnalysis } =
        event.ui);
      this.isNavigatedFromRiskAnalysis = event.ui.isNavigatedFromRiskAnalysis;
    });
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    this.boxxUserId = this.localStorageService.getBoxxUserId();
    // this.quoteFlowSteps = this.localStorageService.getQuoteFlowSteps();

    this.linkIconSrc = 'assets/img/plus-circle.png';
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.boxxUserId = this.localStorageService.getBoxxUserId();
    this.actions$
      .pipe(
        ofType(
          CreateQuoteAction.SET_ADD_COMPANY_EXPANDED,
          CreateQuoteAction.SET_ADD_CUSTOMER_EXPANDED,
        ),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => {
        setTimeout(() => {
          this.viewportScroller.scrollToAnchor('btn-add');
        }, 0);
      });

    this.store
      .select(getCountrySelector)
      .pipe(take(1))
      .subscribe((country) => {
        this.country = country?.toUpperCase();
        if (this.country === UNITED_STATES) {
          this.mask = '00000||00000-0000';
        }
        this.updatePostalCodeValidation(this.country);
      });
    this.userID = this.localStorageService.getBoxxUserId();
    this.getRegionList();
    this.domainsService.GetByDomainCode('INSTANCECONFIG').subscribe((data) => {
      if (data.data.length > 0) {
        const tableData = data.data.map((dataObj) => ({
          key: dataObj.description,
          value: dataObj.description,
        }));
      }
    });

    this.languageService.getLanguageId().subscribe((data) => {
      const languageId = data.data[0]?.id;
      this.uiContentService
        .GetByLangAdnKey(languageId, 'LOCATIONPROVSTATE')
        .subscribe((data) => {
          this.province = data.data[0].value;
          this.uiContentService
            .GetByLangAdnKey(languageId, 'LOCATIONZIPPOSTAL')
            .subscribe((data) => {
              this.zip = data.data[0].value;
            });
        });
    });

    this.form$ = this.store.pipe(select(getQuoteSelector));

    this.form$.subscribe(
      (event) => (this.isInsuredTypeCompany = event.ui.isInsuredTypeCompany),
    );
    if (!this.isInsuredTypeCompany) {
      this.individualSearchData = this.store.pipe(
        select(getInsuredIndividualSelector),
      );
    }
    this.pagination = this.store.pipe(select(getPaginationSelector));
    this.pagination.subscribe((pagination) => {
      if (!this.isInsuredTypeCompany) {
        this.activeIndividualSearchPgNo = pagination.currentPage;
      }
    });

    let isInsuredTypeCompany;

    this.form$.subscribe(
      (event) => (isInsuredTypeCompany = event.ui.isInsuredTypeCompany),
    );
    if (this.isRestrictedRoleSubmission === true) {
      this.initLoadStore();
    }
    await this.domainsService
      .GetByDomainCode(DOMAIN_CODE_RANGENUMHOUSEMEMBERS)
      .subscribe(async (data) => {
        const tableData = data.data?.map((dataObj) => ({
          key: dataObj.description,
          value: dataObj.description,
          id: dataObj.id,
        }));

        this.householdMemberOptns = tableData;
        this.householdMember = arrayToObjet(data.data, 'description');
        if (this.insuredId) {
          await this.populateInsuredDetailsIndividual(this.insuredId);
          if (this.insuredDataIndividual.active == false) {
            this.form.get('address').disable();
          } else {
            this.form.get('address').enable();
          }
          //this.newQuoteService.clearInsuredId();
        } else {
          this.showBodySpinner--;
        }
      });
  }

  // DoCheck() {
  //   if (this.typeBusinessInsured) {
  //     let addCompanyform = this.form.controls['addCompanyForm'] as FormGroup;
  //     if (!addCompanyform.controls['brokerageinfo'].valid) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   } else {
  //     let addIndividualform = this.form.controls[
  //       'addIndividualForm'
  //     ] as FormGroup;
  //     if (!addIndividualform.controls['brokerageinfo'].valid) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   }
  // }

  ngOnDestroy(): void {
    this.alertService.clearAlerts(-1);
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
  private initLoadStore = () => {
    this.riskRegionService.getAllRisk().subscribe({
      next: (response) => {
        this.products = response.data.map((product) => {
          return { value: product.name, key: product.name, id: product.id };
        });
      },
    });
  };

  onChangeProduct(productId) {
    this.form.get('productId').setValue(productId);
  }
  closeSanctionPopup() {
    this.showSanctionModal = false;
    this.sanctionPayload = [];
    this.quoteExist = 0;
  }

  // Individual
  getCustomerName(form) {
    return form.firstName + ' ' + form.middleName + ' ' + form.lastName;
  }

  clearIndividualResults() {
    // this.form.controls['individualSearchForm'].reset();
    // this.sortingIndividualKeys = '';
    // this.store.dispatch(new CreateQuoteAction.UnsetShowCustomerSearchAction());
    // this.store.dispatch(new CreateQuoteAction.UnsetAddCustomerExpandedAction());
    // this.submitted = false;
    // this.searchSubmitted = false;
  }
  handleNotificationAlertClose() {
    this.showBanner = false;
    this.bannerMsg = '';
  }

  handleErrorOrSuccess(alertData) {
    if (alertData.type === 'error') {
      this.bannerMsg = alertData?.body;
      this.showBanner = true;
      // this.setLoader(false, 'unset');
      this.showBodySpinner--;
      if (this.showBodySpinner < 0) this.showBodySpinner = 0;
      this.showSpinner = false;
      let errAlert: alertDetails = {
        type: alertData.type,
        headerText: alertData?.header,
        bodyText: alertData?.body,
      };
      this.alertService.addAlert(errAlert);
    }
  }
  setLoader(status, type) {
    this.showBodySpinner = status;
    window.document.body.style.overflow = type;
  }

  hasLocationDataChanged(latest, previous) {
    // const formname =
    //   this.typeBusinessInsured === true
    //     ? 'addCompanyForm'
    //     : 'addIndividualForm';

    const latestLocationData = {
      address: latest?.address,
      addressLine2: latest?.addressLine2,
      city: latest?.city,
      country: latest?.country,
      province: this.form.value.province,
      zipPostalCode: latest?.zipPostalCode,
    };

    const previousLocationData = {
      address: previous?.address,
      addressLine2: previous?.addressLine2,
      city: previous?.city,
      country: previous?.country,
      province: previous?.province,
      zipPostalCode: previous?.zipPostalCode,
    };

    if (
      JSON.stringify(latestLocationData) !==
      JSON.stringify(previousLocationData)
    ) {
      return true;
    }
    return false;
  }

  getChangedFieldsStatus(
    obj1: any,
    obj2: any,
    fieldsToCheck: string[],
  ): boolean {
    const stringifyAndSort = (obj: any): string =>
      JSON.stringify(obj, Object.keys(obj).sort());

    const normalizeObject = (obj: any): any =>
      JSON.parse(JSON.stringify(obj), (key, value) =>
        typeof value === 'string' && !isNaN(Number(value))
          ? this.roundNumber(Number(value), 2)
          : value,
      );

    const normalizedObj1 = normalizeObject(obj1);
    const normalizedObj2 = normalizeObject(obj2);

    const stringifiedObj1 = stringifyAndSort(normalizedObj1);
    const stringifiedObj2 = stringifyAndSort(normalizedObj2);

    if (stringifiedObj1 === stringifiedObj2) {
      return false;
    }

    const changedFields: string[] = [];
    fieldsToCheck.forEach((field) => {
      if (normalizedObj1[field] !== normalizedObj2[field]) {
        changedFields.push(field);
      }
    });

    return changedFields.length === 0 ? false : true;
  }

  roundNumber(value: number, precision: number): number {
    const factor = Math.pow(10, precision);
    return Math.round(value * factor) / factor;
  }

  checkForStateChange(formControlName: string, initialValues): boolean {
    return (
      this.form.controls[formControlName].value.province !==
      initialValues?.province
    );
  }

  closeModalMarketReservation() {
    this.showMarketReservationModal = false;
    this.quoteExist = 0;
  }

  closeExistingQuote() {
    this.showExistingQuoteModal = false;
    this.quoteExist = 0;
  }

  closeModalInsuredStateChange() {
    this.showStateChangeModal = false;
    this.quoteExist = 0;
  }

  closeModalRiskAnalysisUpdate() {
    this.showRiskAnalysisUpdateModal = false;
    this.quoteExist = 0;
  }

  handleCreateNewInsured() {
    this.form.reset();
    this.enableProducerInfo.emit(false);
    this.checkValidation = false;
    this.formSubmitted = false;
    this.insuredId = null;
    this.insuredData = null;
    this.initialFormData = null;
    this.showStateChangeModal = false;
    this.quoteExist = 0;
  }

  handleRegionCode(regionCode) {
    this.regionCode = regionCode;
  }

  handleExitFlow() {
    let isMock = 'true';
    if (
      this.localStorageService.getMockExternalAPIs() === 'false' ||
      environment.mockExternalAPIs === false
    ) {
      isMock = 'false';
    }
    this.router.navigate(['/dashboard/home'], {
      queryParams: {
        mock: isMock,
      },
      skipLocationChange: true,
    });
  }
  hasFormValueChanged(): boolean {
    const stringifyAndSort = (obj: any): string =>
      JSON.stringify(obj, Object.keys(obj).sort());

    const normalizeObject = (obj: any): any =>
      JSON.parse(JSON.stringify(obj), (key, value) =>
        typeof value === 'string' && !isNaN(Number(value))
          ? this.roundNumber(Number(value), 2)
          : value,
      );
    if (this.form?.value && this.initialFormData) {
      const normalizedObj1 = normalizeObject(this.form.value);
      const normalizedObj2 = normalizeObject(this.initialFormData);

      const stringifiedObj1 = stringifyAndSort(normalizedObj1);
      const stringifiedObj2 = stringifyAndSort(normalizedObj2);

      return stringifiedObj1 !== stringifiedObj2;
    } else return false;
  }

  //**************************************************************** */

  //******location***************************************** */

  getPlaces(query: string) {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      this.getPlacesApiHandler(query);
    }, 500);
  }

  getPlacesApiHandler(query: string) {
    this.mapService.getPlaces(query).subscribe((response: any) => {
      if (response?.data && response?.data instanceof Array) {
        this.locations = response?.data?.map((obj) => {
          const { place_id, ...rest } = obj;
          return { ...rest, id: place_id };
        });
      }
    });
  }

  selectPlaceById(placeId: string) {
    this.selectedFromSuggestion = true;
    this.mapService.getPlaceById(placeId).subscribe((response: any) => {
      if (response.data.target === 'address') {
        const addressArray = response.data.address.split(',');
        const extractedAddress = addressArray[0].trim();
        this.form.get('address').setValue(extractedAddress);
        this.form.get('city').setValue(response.data.city);
        this.form.get('zipPostalCode').setValue(response.data.postalCode);
        this.form.get('country').setValue(response.data.country);
        this.form.get('province').setValue(response.data.province);
        this.currentProvinceObj = {
          id: response.data.regionId,
          value: response.data.province,
          key: response.data.province,
        };

        this.regionId = response.data.regionId;
        this.regionCode = response?.data?.regionCode;
      }
    });
  }
  handleProvinceChange(provinceId) {
    this.currentProvinceObj =
      this.listRegions.reduce((resultArray, item) => {
        if (item.id === provinceId) {
          resultArray.push({
            key: item.name,
            value: item.name,
            id: item.id,
            code: item.code,
          });
        }
        return resultArray;
      }, [])[0] ?? {};
    this.form.get('province').setValue(this.currentProvinceObj?.value);
    this.regionId = provinceId;
    this.regionCode = this.currentProvinceObj?.code;
  }
  getRegionList(page = 1, isLoadMore = false) {
    this.locationService.getAllRegions(page).subscribe((region) => {
      const regions = region.data.map((item: any) => {
        const data = { id: item.id, name: item.name, code: item.regionCode };
        return data;
      });
      const stateList = regions.map((item: any) => {
        return { key: item.name, value: item.name, id: item.id };
      });
      if (isLoadMore) {
        this.listRegions = [...this.listRegions, ...regions];
        this.provinceOrStateList = [...this.provinceOrStateList, ...stateList];
      } else {
        this.listRegions = regions;
        this.provinceOrStateList = stateList;
      }
      this.regionTotalRecords = region.pagination.totalRecords;
      this.regionTotalPages = region.pagination.totalPages;
    });
  }
  loadMoreRegion(page) {
    if (this.regionTotalRecords > this.listRegions.length) {
      this.getRegionList(page, true);
    }
  }

  private updatePostalCodeValidation(country: string) {
    const zipPostalCodeControl = this.form.get('zipPostalCode');

    // Add new validator based on the selected country
    if (
      country?.toUpperCase() === UNITED_STATES ||
      country?.toUpperCase() === CANADA
    ) {
      // Clear previous validators for the postal code control
      zipPostalCodeControl.clearValidators();
      zipPostalCodeControl.updateValueAndValidity();

      zipPostalCodeControl.setValidators([
        Validators.required,
        postalCodeValidator(country), // Custom validator for US postal code
      ]);
    }

    // Update the postal code control's validation status
    zipPostalCodeControl.updateValueAndValidity();
  }
  //*********************************************** */

  handleInsuredTypeChange() {
    this.typeBusinessInsured = !this.typeBusinessInsured;
    this.store.dispatch(new CreateQuoteAction.ResetInsuredTypeAction());
    // this.form.get('addCompanyForm').reset();
    // this.form.get('addIndividualForm').reset();
  }

  setMaxLengthErrorMessage(formName: string, fieldName: string) {
    const charLimit =
      this.form.get([fieldName]).errors['maxlength']?.requiredLength ?? 0;
    const errMsg =
      charLimit !== 0
        ? this.translateService
            .instant('error.maximumAllowedCharErroMsg')
            ?.replace('{charLimit}', charLimit)
        : this.translateService.instant(
            'error.maximumAllowedCharDefaultErroMsg',
          );
    this.form.get([fieldName]).setErrors({
      message: errMsg,
    });
  }

  handleNoOfEmployees(id) {
    this.form.get('numberOfHouseholdMembersId').setValue(id);
  }

  handleClearForm() {
    this.showAddress = false;
    this.clearInsuredForm.emit();
  }

  selectInsuredSearch(insuredId) {
    if (this.fromSubmissionFlow === true) this.selectInsured.emit(insuredId);
  }
  async populateInsuredDetailsIndividual(insuredId) {
    this.showBodySpinner++;
    this.insuredDataIndividual = null;
    this.initialFormDataIndividual = null;
    // this.enableProducerInfo.emit(true);
    this.formSubmitted = false;
    this.selectedFromSuggestion = true;
    this.firstNameAutoFocus = false;
    this.showAddress = true;
    this.showBodySpinner--;
    try {
      const getInsuredData = this.insuredService.GetByInsuredId(insuredId);
      let insuredData = await firstValueFrom(getInsuredData);
      const insured = insuredData?.data;
      this.populateLatestInsured.emit(insuredData?.data);
      if (insured) {
        const numOfHouseHoldMembers = this.householdMemberOptns.filter(
          (data) => data.id === insured?.numOfHouseholdMem,
        )?.[0]?.key;

        this.regionId = insuredData?.data?.insuredLocation?.regionId;
        this.store.dispatch(
          new CreateQuoteAction.updateUiContents({
            regionId: this.regionId ?? 0,
          }),
        );
        if (this.isRestrictedRoleSubmission === true)
          await Promise.all([this.checkForActiveQuote(insuredId)]);

        this.form.setValue({
          firstName: insured?.firstName ?? '',
          middleName: insured?.middleName ?? '',
          lastName: insured?.lastName ?? '',
          email: insured?.email ?? '',
          phone: insured?.phone ?? '',
          householdMembers: numOfHouseHoldMembers ?? '',
          country: insured?.insuredLocation?.country ?? '',
          province: insured?.insuredLocation?.region?.name ?? '',
          city: insured?.insuredLocation?.city ?? '',
          address: insured?.insuredLocation?.address ?? '',
          zipPostalCode: insured?.insuredLocation?.zipPostalCode ?? '',
          addressLine2: insured?.insuredLocation?.addressLine2 ?? '',
          insuredLocationId: insured?.insuredLocation?.id,
          numberOfHouseholdMembersId: insured?.numOfHouseholdMem,
          insuredId: insured?.id,
          brokerageinfo: insured?.brokerageinfo ?? null,
          productId: this.productId ?? '',
          productName: this.productName ?? '',
        });
        this.initialFormDataIndividual = this.form.value;
        this.insuredDataIndividual = insured;
        this.insuredId = insured?.id;
        this.showBodySpinner--;
        if (this.showBodySpinner < 0) this.showBodySpinner = 0;
      } else {
        this.showBodySpinner--;
        if (this.showBodySpinner < 0) this.showBodySpinner = 0;
      }
    } catch (error) {
      if (![500].includes(error?.status)) {
        this.handleErrorOrSuccess({
          show: true,
          type: 'error',
          header: 'common.errorMsg',
          body: getErrorMessage(error),
        });
      }
    }
    this.showBodySpinner--;
    if (this.showBodySpinner < 0) this.showBodySpinner = 0;
  }

  handleAlertClose() {
    this.alertData = {
      show: false,
      type: '',
      header: '',
      body: '',
    };
  }

  handleAlertInfo(event) {
    this.alertData = {
      type: event.type,
      header: event.header,
      body: event.body,
      show: event?.show,
    };
  }

  // clickAddressDetais() {
  //   if(this.addressAutoFocus === true){
  //   if (this.typeBusinessInsured === true) this.showAddressField = false;
  //   this.enableProducerInfo.emit(true);
  //   setTimeout(() => {
  //     this.showAddressField = true;
  //   }, 0);
  // }
  // }

  showAddressSection() {
    this.showAddress = true;
    this.firstNameAutoFocus = false;
    this.addressAutoFocus = true;
    if (this.addressAutoFocus === true) {
      this.enableProducerInfo.emit(true);
    }
  }

  handleBusinessNameSearch(query: string, insuredType: number = 2) {
    this.insuredName = this.form?.value?.firstName;
    if (query) query = query.trim();
    if (query.length >= 3) {
      const page = 1;
      const limit = 10;
      const sort = '';
      // to reset the existing data
      this.insuredDataIndividual = null;
      this.insuredData = null;
      this.initialFormDataIndividual = null;
      this.initialFormData = null;
      this.insuredService
        .GetByInsuredNameAndType(query, insuredType, page, limit, sort, 1)
        .subscribe((response: any) => {
          this.insuredOptns = response.data.map((dataObj) => ({
            main_text:
              insuredType === 2 ? dataObj?.companyName : dataObj?.firstName,
            id: dataObj?.id,
          }));
        });
    } else {
      this.insuredOptns = [];
      this.insuredDataIndividual = null;
      this.insuredData = null;
      this.initialFormDataIndividual = null;
      this.initialFormData = null;
    }
  }

  checkForActiveQuote(insuredId: any) {
    return new Promise<any>(async (resolve, reject) => {
      this.policyMarketReservationService.GetByInsuredId(insuredId).subscribe({
        next: async (resp) => {
          const quoteDetails = resp?.data;
          this.activeQuoteId = quoteDetails?.policyId;
          if (quoteDetails?.policyId) {
            this.policyPeriodService
              .GetAllByPolicyId(quoteDetails?.policyId)
              .subscribe(async (data) => {
                this.policyPeriodId = data.data[0]?.id;
                if (this.isRestrictedRoleSubmission === true) {
                  const policyDashboardServiceObs =
                    this.policyDashboardService.Get(this.policyPeriodId);
                  const policyDashboardData = await firstValueFrom(
                    policyDashboardServiceObs,
                  );
                  this.productId =
                    policyDashboardData?.data?.PolicyRisks[0]?.risk.id ?? '';
                  this.productName =
                    policyDashboardData?.data?.PolicyRisks[0]?.risk.name ?? '';
                }
                resolve(true);
              });
          } else {
            resolve(true);
          }
        },
        error: (error) => {
          reject(getErrorMessage(error));
        },
      });
    });
  }
}
