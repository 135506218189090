import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { getFormattedPeriod } from 'src/app/utils/formatDate';

@Component({
  selector: 'app-modal-risk-analysis',
  templateUrl: './modal-risk-analysis.component.html',
  styleUrls: ['./modal-risk-analysis.component.less'],
})
export class ModalRiskAnalysisComponent implements OnInit {
  @Input() showModal: boolean = false;
  @Input() existingQuoteOptionData;
  @Input() isSubmissionFlow = true;
  @Input() isTbd = true;

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleDiscard = new EventEmitter<any>();
  @Output() handleContinue = new EventEmitter<any>();

  shortDateFormat: string = '';
  longDateFormat: string = 'MMM DD, YYYY';

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
  }

  closeModal() {
    this.handleClose.emit();
  }
  handleDiscardChanges() {
    this.handleDiscard.emit();
  }

  handleSaveAndContinue() {
    this.handleContinue.emit();
  }

  formatDate(date) {
    return date
      ? getFormattedPeriod(date, 'YYYY-MM-DDTHH:mm:ss.sssZ', 'MMM DD,YYYY')
      : '';
  }
}
