<div class="broker-modal" *ngIf="showBrokerRedirectModal">
  <div class="modal-container">
    <div class="box">
      <span style="--i: 1"></span>
      <span style="--i: 2"></span>
      <span style="--i: 3"></span>
      <span style="--i: 4"></span>
      <span style="--i: 5"></span>
      <span style="--i: 6"></span>
      <span style="--i: 7"></span>
      <span style="--i: 8"></span>
      <span style="--i: 9"></span>
      <span style="--i: 10"></span>
    </div>
    <div class="header">
      <div class="icon">
        <img src="assets/img/save.svg" />
      </div>
      <div class="heading">
        <boxxds-heading
          [sz]="'7'"
          [text]="headerText"
          type="bold"
        ></boxxds-heading>
      </div>
      <div class="close">
        <button (click)="handleCloseModal()">
          <img src="assets/img/close-v2.svg" />
        </button>
      </div>
    </div>

    <div class="broker-info">
      <div class="insured">
        <label> {{ "quote4Flow.insured" | translate }} </label>
        <p>{{ insured }}</p>
      </div>
    </div>

    <div class="form">
      <div class="form-info">
        <p>
          {{ "quote4Flow.youAreAbout" | translate }}
        </p>
      </div>
    </div>

    <div class="action-buttons">
      <div class="modal-buttons">
        <boxxds-button-v2
          [buttonText]="'Save insured'"
          [size]="'md'"
          [btnClass]="'primary'"
          [btnType]="'default'"
          [btnStyle]="{ width: '222px' }"
          (handleClick)="handleredirectToBroker()"
        >
        </boxxds-button-v2>
      </div>
    </div>
  </div>
</div>
