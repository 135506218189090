import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ChangeDetectorRef,
  OnChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import { ICommissionModel } from 'src/app/models/commission.model';
import { BrokerageProducerService } from 'src/app/services/brokerage-producer.service';
import { BrokerageService } from 'src/app/services/brokerage.service';
import { LocationService } from 'src/app/services/location.service';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import { getErrorMessage } from 'src/app/utils/utils';

@Component({
  selector: 'app-commission-table-workflow3',
  templateUrl: './commission-table-workflow3.component.html',
  styleUrls: ['./commission-table-workflow3.component.less'],
})
export class CommissionTableWorkflow3Component implements OnInit, OnChanges {
  @Input() data: Array<{
    Product: '';
    State: '';
    'Min.range %': '';
    'Max.range %': '';
    'Default %': '';
    Active: true;
    id: '';
    riskRegionId: '';
    regionId: '';
    riskId: '';
    '': '';
  }>;
  @Input() totalCount: number = 0;
  @Input() showStatusAsBadge: boolean = false;
  @Input() mapStatusBadge: Object;
  @Input() showBadgeFieldName: string;
  @Input() showWithBorder: boolean = false;
  @Input() showWithBorderFieldName: string;
  @Input() showStatusBadgeWithActions: boolean = false;
  @Input() statusBtnActions;
  @Input() addCopyBtnToField: string;
  @Input() showStar: boolean = false;
  @Input() showEllipsis: boolean = false;
  @Input() showPagination: boolean = true;
  @Input() id: string;
  @Input() tblHeading: string = '';
  @Input() addSortTo: string | Array<string>;
  @Input() styleInactive: boolean = false;
  @Input() tableStyle: { [klass: string]: any };
  @Input() tblContainerStyle: { [klass: string]: any };
  @Input() showEditLink: boolean = false;
  @Input() showIcon: boolean = false;
  @Input() linkButtonLabel: string;
  @Input() showLinks: boolean = false;
  @Input() linkDetails;
  @Input() isRowClickable: boolean = false;
  @Input() isRowExpandable: boolean = false;
  @Input() expandRowContext: any;
  @Input() showSpinner: boolean = false;
  @Input() activePageNumber: number;
  @Input() inlineEditProps: Array<any> = [];
  @Input() headingStyle: { [klass: string]: any };
  @Input() showEmptyCellIndicator: boolean = false;
  @Input() showAddBtn: boolean = false;
  @Input() showPopOver: boolean = false;
  @Input() popOverColumn: string = '';
  @Input() linkIconSrc: string;
  @Input() showViewButton: boolean = false;
  @Input() showEditIcon: boolean = false;
  @Input() showToggle: boolean = false;
  @Input() tblHeaders: object[] = [];
  @Input() columnsToHide: string[] = [];
  @Input() columnHeadsToHide: string[] = [];
  @Input() isProducerActive: boolean = true;
  @Input() isBrokerActive: boolean = true;
  @ContentChild(TemplateRef) templateOutlet!: TemplateRef<any>;

  @Input() brokerageId;
  @Input() producerId;
  @Input() type;

  @Output() pageChanged = new EventEmitter();
  @Output() rowClicked = new EventEmitter();
  @Output() removeSortHandler = new EventEmitter();
  @Output() sortUpClicked = new EventEmitter();
  @Output() sortDownClicked = new EventEmitter();
  @Output() starHandler = new EventEmitter();
  @Output() ellipsisHandler = new EventEmitter();
  @Output() statusActionButtonHandler = new EventEmitter();
  @Output() saveEditHandler = new EventEmitter();
  @Output() deleteHandler = new EventEmitter();
  @Output() addBtnHandler = new EventEmitter();
  @Output() viewClickHandler = new EventEmitter();
  @Output() editHandler = new EventEmitter<any>();
  @Output() commissionEnable = new EventEmitter<any>();
  @Output() submitBrokerCommission = new EventEmitter<any>();
  @Output() updateBrokerCommission = new EventEmitter<any>();
  @Output() submitProducerCommission = new EventEmitter<any>();
  @Output() updateProducerCommission = new EventEmitter<any>();

  tableHeaders: Array<string>;
  totalPages: number = 0;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  isExpanded: Array<boolean>;
  currentEditingIdx = null;
  isInlineEdit: boolean = false;
  editingForm: FormGroup;
  isAscending: boolean = true;
  activeSort: string = '';
  paginationId = 'pagination';
  lastSortedColumn = '';

  formCommission: FormGroup;
  maxPercentage?: number = 0;
  minPercentage?: number = 0;
  defaultPercentage?: number = 0;
  minRangePercentage: number = 1;
  maxRangePercentage: number = 50;

  maxPercentageEdit?: number = 0;
  minPercentageEdit?: number = 0;
  defaultPercentageEdit?: number = 0;
  minRangePercentageEdit: number = 1;
  maxRangePercentageEdit: number = 50;

  products = [];
  regions = [];
  regionsEdit = [];
  submitted: boolean = false;
  regionId = '';
  productId: string;
  commissionOverride: boolean = true;

  provinceOrStateList = [];
  formSubmitted: boolean = false;

  listRegions: any[] = [];
  regionTotalRecords = 0;
  regionTotalPages = 0;

  commissionAddObj: object = {
    Product: '',
    State: '',
    'Min.range %': '',
    'Max.range %': '',
    'Default %': '',
    Active: '',
    id: '',
    riskRegionId: '',
    regionId: '',
    riskId: '',
  };
  showConfirmationModal: boolean = false;
  commission: object = {};
  editSubmitted: boolean = false;
  activeStatus: boolean;
  currentRegionObj: any;
  currentProductObj: any;

  constructor(
    private clipboardApi: ClipboardService,
    private router: Router,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private locationService: LocationService,
    private riskRegionService: RiskRegionService,
    private translate: TranslateService,
    private brokerageService: BrokerageService,
    private brokerageProducerService: BrokerageProducerService,
  ) {
    // this.editingForm = this.fb.group({});
  }

  async ngOnInit(): Promise<void> {
    //this.getRegionList();
    this.formCommission = this.fb.group({
      productId: ['', [Validators.required]],
      productName: ['', [Validators.required]],
      regionName: ['', [Validators.required]],
      regionId: ['', [Validators.required]],
      maxPercentage: [
        0,
        [
          Validators.max(this.maxRangePercentage),
          Validators.min(this.minRangePercentage),
        ],
      ],
      minPercentage: [
        0,
        [
          (control: AbstractControl) =>
            Validators.max(this.maxPercentage)(control),
          Validators.max(this.maxRangePercentage),
          Validators.min(this.minRangePercentage),
        ],
      ],
      defaultPercentage: [
        0,
        [
          Validators.max(this.maxPercentage),
          Validators.min(this.minPercentage),
        ],
      ],
    });
    this.editingForm = this.fb.group({
      productId: ['', [Validators.required]],
      productName: ['', [Validators.required]],
      regionName: ['', [Validators.required]],
      regionId: ['', [Validators.required]],
      riskRegionId: ['', [Validators.required]],
      id: ['', [Validators.required]],
      maxPercentage: [
        0,
        [
          Validators.max(this.maxRangePercentageEdit),
          Validators.min(this.minRangePercentageEdit),
        ],
      ],
      minPercentage: [
        0,
        [
          (control: AbstractControl) =>
            Validators.max(this.maxPercentageEdit)(control),
          Validators.max(this.maxRangePercentageEdit),
          Validators.min(this.minRangePercentageEdit),
        ],
      ],
      defaultPercentage: [
        0,
        [
          Validators.max(this.maxPercentageEdit),
          Validators.min(this.minPercentageEdit),
        ],
      ],
    });
    this.initLoadStore();

    this.isExpanded = Array(this.data?.length).fill(false);
    this.isInlineEdit = true; //this.inlineEditProps.length > 0 ? true : false;

    if (this.id) {
      this.paginationId = this.id + '-pagination';
    }

    if (this.isInlineEdit) {
      // this.inlineEditProps.forEach((col) => {
      //   this.editingForm.addControl(col?.column, this.fb.control(''));
      // });
    }

    if (this.productId)
      await Promise.all([this.loadRegionWithRisk(this.productId)]);
  }

  ngOnChanges() {
    if (this.brokerageId != undefined) {
      this.brokerageService.GetBrokerage(this.brokerageId).subscribe((data) => {
        this.activeStatus = data.data.active;
      });
    } else if (this.producerId != undefined) {
      this.brokerageProducerService
        .GetBrokerageProducerId(this.producerId)
        .subscribe((data) => {
          this.activeStatus = data.data.active;
        });
    } else {
      this.activeStatus = true;
    }

    this.totalPages = Math.ceil(this.totalCount / this.itemsPerPage);
    if (this.activePageNumber) {
      this.currentPage = this.activePageNumber;
    } else if (this.totalPages < this.currentPage) {
      this.currentPage = 1;
    }
    if (this.data.length > 0)
      this.tableHeaders =
        this.data && this.data?.length > 0 && Object.keys(this.data[0]);
    else
      this.tableHeaders =
        this.tblHeaders &&
        this.tblHeaders?.length &&
        Object.keys(this.tblHeaders[0]);
  }

  private initLoadStore = () => {
    this.riskRegionService.getAllRisk().subscribe({
      next: (response) => {
        this.products = response.data.map((product) => {
          return { value: product.name, key: product.name, id: product.id };
        });
      },
    });
    this.currentRegionObj = this.formCommission.controls['regionName'];
    this.currentProductObj = this.formCommission.controls['productName'];
  };

  loadRegionWithRisk(productId, isEdit: boolean = false) {
    this.showSpinner = true;
    return new Promise<any>(async (resolve, reject) => {
      this.riskRegionService.getRiskRegion(Number(productId)).subscribe({
        next: async (response) => {
          const regions = response.data.map((item) => {
            return {
              value: item.region.name,
              key: item.region.name,
              id: item.id,
            };
          });
          if (isEdit === false) {
            this.regions = [
              {
                value: this.translate.instant('common.selectAll'),
                key: this.translate.instant('common.selectAll'),
                id: -1,
              },
            ];
            this.regions = [...this.regions, ...regions];
          }
          this.regionsEdit = [...regions];
          this.showSpinner = false;
          resolve(true);
        },
        error: (error) => {
          this.showSpinner = false;
          reject(getErrorMessage(error));
        },
      });
    });
  }

  onPageChange(pageNumber: number) {
    this.currentEditingIdx = null;
    this.totalPages = Math.ceil(this.totalCount / this.itemsPerPage);
    this.currentPage = pageNumber;
    this.pageChanged.emit(pageNumber);
  }

  isEditableCol(colName) {
    // const editableColumns = this.inlineEditProps.map((col) => col.column);
    // if (editableColumns.includes(colName)) {
    return true;
    // }
    // return false;
  }

  showEditTextBox(colName) {
    const editPropForCol = this.inlineEditProps.filter(
      (col) => col.column == colName,
    );
    if (editPropForCol.length > 0 && editPropForCol[0].type == 'text') {
      return true;
    }
    return false;
  }

  handleEditClick(commission: any, index: number) {
    this.editHandler.emit({ commission: commission, index: index });
  }

  handleEnableDisable(commission, index) {
    this.commissionEnable.emit({ commission: commission, index: index });
  }

  showEditCheckBox(colName) {
    const editPropForCol = this.inlineEditProps.filter(
      (col) => col.column == colName,
    );
    if (editPropForCol.length > 0 && editPropForCol[0].type == 'checkbox') {
      return true;
    }
    return false;
  }

  onCheckboxChange(event, colName: string) {
    // const checked = event.target.checked;
    // this.editingForm?.get(colName)?.setValue(checked, { emitEvent: false });
    // this.cdr.detectChanges();
  }

  getBoolean = (value: any): boolean | undefined => {
    if (!value) return undefined;
    const lowercasedValue = String(value).toLowerCase();
    switch (lowercasedValue) {
      case 'true':
      case '1':
      case 'on':
      case 'yes':
        return true;
      case 'false':
      case '0':
      case 'off':
      case 'no':
      default:
        return false;
    }
  };

  async startEditing(item: object, index: number) {
    // const editableColumns = this.inlineEditProps.map((col) => col.column);
    // this.inlineEditProps.forEach((col) => {
    //   if (col.type == 'checkbox') {
    //     this.editingForm?.controls[col.column].setValue(
    //       this.getBoolean(this.data[index][col.column]),
    //     );
    //   } else {
    //     this.editingForm?.controls[col.column].setValue(
    //       this.data[index][col.column],
    //     );
    //   }
    // });
    this.cancelEditing();
    this.editingForm.patchValue(item);
    this.editingForm.get('productName').setValue(item['Product']);
    this.editingForm.get('productId').setValue(item['riskId']);
    this.editingForm.get('regionName').setValue(item['State']);
    this.editingForm.get('minPercentage').setValue(item['Min.range %']);
    this.editingForm.get('maxPercentage').setValue(item['Max.range %']);
    this.editingForm.get('defaultPercentage').setValue(item['Default %']);
    this.minPercentageEdit = item['Min.range %'];
    this.maxPercentageEdit = item['Max.range %'];
    this.defaultPercentageEdit = item['Default %'];

    if (item['riskId'])
      await Promise.all([this.loadRegionWithRisk(item['riskId'], true)]);

    this.currentEditingIdx = index;
    this.cdr.detectChanges();
  }

  saveEditing(commission: any, index: number) {
    this.editSubmitted = true;
    this.editingForm.get('maxPercentage').setValue(this.maxPercentageEdit);
    this.editingForm.get('minPercentage').setValue(this.minPercentageEdit);
    this.editingForm
      .get('defaultPercentage')
      .setValue(this.defaultPercentageEdit);

    if (this.editingForm.controls['maxPercentage'].hasError('max')) {
      this.editingForm.controls['maxPercentage'].setErrors({
        message: this.translate.instant('error.maxValueValidationErrMsg'),
      });
    }

    if (this.editingForm.controls['maxPercentage'].hasError('min')) {
      this.editingForm.controls['maxPercentage'].setErrors({
        message: this.translate.instant('error.minValueValidationErrMsg'),
      });
    }

    if (this.editingForm.controls['minPercentage'].hasError('min')) {
      this.editingForm.controls['minPercentage'].setErrors({
        message: this.translate.instant('error.minValueValidationErrMsg'),
      });
    }

    if (this.editingForm.controls['minPercentage'].hasError('max')) {
      this.editingForm.controls['minPercentage'].setErrors({
        message: this.translate.instant('error.minValueGreaterErrMsg'),
      });
      this.formCommission.controls['maxPercentage'].setErrors({
        message: this.translate.instant('error.maxValueLesserErrMsg'),
      });
    }

    if (
      this.editingForm.controls['defaultPercentage'].value &&
      this.editingForm.controls['defaultPercentage'].value >=
        this.editingForm.controls['minPercentage'].value &&
      this.editingForm.controls['defaultPercentage'].value <=
        this.editingForm.controls['maxPercentage'].value
    ) {
      this.editingForm.controls['defaultPercentage'].setErrors(null);
    } else {
      this.editingForm.controls['defaultPercentage'].setErrors({
        message: this.translate.instant('error.defaultValueValidationErrMsg'),
      });
    }
    if (this.editingForm.valid) {
      const regionIdSelected = this.editingForm.get('regionId').value;
      const riskId = this.editingForm.get('productId').value;

      if (this.type === 'Brokerage' && this.brokerageId) {
        commission = {
          active: true,
          brokerageId: Number(this.brokerageId),
          commissionMax: Number(this.maxPercentageEdit) / 100 ?? 0,
          commissionMin: Number(this.minPercentageEdit) / 100 ?? 0,
          commissionDefault: Number(this.defaultPercentageEdit) / 100 ?? 0,
          riskRegionId: Number(regionIdSelected),
          riskId: Number(riskId),
          regionId: Number(commission.regionId),
          id: Number(commission.id),
          type: 1,
          override: false,
        };
        this.modifyBrokerCommission(commission, index);
      } else if (this.type === 'Producer' && this.producerId) {
        commission = {
          active: true,
          brokerageProducerId: Number(this.producerId),
          commissionMax: Number(this.maxPercentageEdit) / 100 ?? 0,
          commissionMin: Number(this.minPercentageEdit) / 100 ?? 0,
          commissionDefault: Number(this.defaultPercentageEdit) / 100 ?? 0,
          riskRegionId: Number(regionIdSelected),
          riskId: Number(riskId),
          regionId: Number(commission.regionId),
          id: Number(commission.id),
          type: 1,
          override: false,
        };
        this.modifyProducerCommission(commission, index);
      }
      this.clearEditForm();
      this.currentEditingIdx = null;
    }
  }

  cancelEditing(index: number = 0) {
    this.clearEditForm();
    this.currentEditingIdx = null;
  }

  clearEditForm() {
    this.editSubmitted = false;
    this.editingForm.reset();
    this.minPercentageEdit = 0;
    this.maxPercentageEdit = 0;
    this.defaultPercentage = 0;
  }

  isFieldEmpty(value) {
    const splitArray = value?.split('<br>');
    const isEmptyArray = splitArray?.every((item) => item.trim() === '');
    return isEmptyArray;
  }

  deleteRow(index: number) {
    this.deleteHandler.emit({ index, form: this.editingForm.value });
  }

  handleRowClick(event, index) {
    this.rowClicked.emit(index);
  }

  handleSort(event, index, key) {
    const sortByColumn = this.tableHeaders[index];
    if (this.lastSortedColumn != sortByColumn) {
      this.handleAscSortClick(event, index, key);
    } else {
      if (this.isAscending) {
        this.handleAscSortClick(event, index, key);
      } else {
        this.handleDescSortClick(event, index, key);
      }
    }
    this.lastSortedColumn = sortByColumn;
  }

  handleSortRemove() {
    this.isAscending = true;
    this.activeSort = '';
    this.removeSortHandler.emit();
  }

  handleAscSortClick(event, index, key) {
    this.activeSort = key;
    this.sortUpClicked.emit(this.tableHeaders[index]);
    this.isAscending = false;
  }

  handleDescSortClick(event, index, key) {
    this.activeSort = key;
    this.sortDownClicked.emit(this.tableHeaders[index]);
    this.isAscending = true;
  }

  isColumnSortable(columnName) {
    const sortFields = this.addSortTo;
    if (sortFields && columnName !== 'recordStatus') {
      if (sortFields === 'all') {
        return true;
      } else if (Array.isArray(sortFields)) {
        return sortFields.includes(columnName);
      }
    }
    return false;
  }

  closeModalConfirmation() {
    this.showConfirmationModal = false;
    this.resetForm();
  }

  handleCommissionOverride(event) {
    if (this.type === 'Brokerage')
      this.submitBrokerCommission.emit({ commission: event });
    else if (this.type === 'Producer')
      this.submitProducerCommission.emit({ commission: event });
  }

  copyText(event, content) {
    event.stopPropagation();
    const formattedContent = content.split('<br>').join('');
    this.clipboardApi.copyFromContent(formattedContent);
  }

  handleStarClick(index) {
    this.starHandler.emit(index);
  }

  handleEllipsisClick(index) {
    if (this.isRowExpandable) {
      this.isExpanded[index] = !this.isExpanded[index];
    } else {
      this.ellipsisHandler.emit(index);
    }
  }

  statusActionBtnClicked(actionName) {
    this.statusActionButtonHandler.emit(actionName);
  }

  getIconSrc(imageName) {
    switch (imageName) {
      case 'refresh':
        return 'assets/img/refresh.png';
      case 'send':
        return 'assets/img/send.png';
      case 'filter':
        return 'assets/img/filter.png';
      case 'filter-filled':
        return 'assets/img/filter-filled.png';
      case 'download':
        return 'assets/img/download.png';
      case 'add':
        return 'assets/img/add.png';
      case 'edit':
        return 'assets/img/edit-icon.png';
      default:
        return '';
    }
  }

  redirectTo(url) {
    this.router.navigate([url], { skipLocationChange: true });
  }

  isNoRecords(data) {
    if (
      (data?.length <= 1 &&
        data[0] &&
        Object.values(data[0]).every((x) => !x)) ||
      data.length === 0
    ) {
      return true;
    }
    return false;
  }

  handleAddCompanyBtn(event) {
    this.addBtnHandler.emit();
  }

  handleViewClick(index) {
    this.viewClickHandler.emit(index);
  }

  onChangeMinPercentage(value) {
    this.minPercentage = value;
    this.handleMinPercentageValidate(value);
    this.handleDefaultPercentageValidate(this.defaultPercentage);
  }

  handleMinPercentageValidate(event) {
    const minPercentage = event?.target?.value ? event?.target?.value : event;
    const maxPercentage =
      this.formCommission.controls['maxPercentage'].value ?? 0;

    if (Number(minPercentage) >= 1) {
      this.formCommission.controls['minPercentage'].setErrors(null);
      if (Number(maxPercentage) >= 1) {
        this.formCommission.controls['maxPercentage'].setErrors(null);
      }
    } else {
      this.formCommission.controls['minPercentage'].setErrors({
        message: this.translate.instant('error.minValueValidationErrMsg'),
      });
    }
  }

  onChangeMaxPercentage(value) {
    this.maxPercentage = value;
    this.handleMaxPercentageValidate(value);
    this.handleDefaultPercentageValidate(this.defaultPercentage);
  }

  handleMaxPercentageValidate(event) {
    const maxPercentage = event?.target?.value ? event?.target?.value : event;
    const minPercentage =
      this.formCommission.controls['minPercentage'].value ?? 0;

    if (Number(maxPercentage) >= 1) {
      this.formCommission.controls['maxPercentage'].setErrors(null);
      if (Number(minPercentage) >= 1) {
        this.formCommission.controls['minPercentage'].setErrors(null);
      }
    } else {
      this.formCommission.controls['maxPercentage'].setErrors({
        message: this.translate.instant('error.minValueValidationErrMsg'),
      });
    }
  }

  onChangeDefaultPercentage(value: number) {
    this.defaultPercentage = value;
    this.handleDefaultPercentageValidate(value);
  }

  handleDefaultPercentageValidate(event) {
    const defaultPercentage = event?.target?.value
      ? event?.target?.value
      : event;
    if (
      defaultPercentage >= this.minPercentage &&
      defaultPercentage <= this.maxPercentage
    ) {
      this.formCommission.controls['defaultPercentage'].setErrors(null);
    } else {
      this.formCommission.controls['defaultPercentage'].setErrors({
        message: this.translate.instant('error.defaultValueValidationErrMsg'),
      });
    }
  }

  handleSubmitCommission() {
    this.submitted = true;
    this.formCommission.get('maxPercentage').setValue(this.maxPercentage);
    this.formCommission.get('minPercentage').setValue(this.minPercentage);
    this.formCommission
      .get('defaultPercentage')
      .setValue(this.defaultPercentage);

    if (this.formCommission.controls['maxPercentage'].hasError('max')) {
      this.formCommission.controls['maxPercentage'].setErrors({
        message: this.translate.instant('error.maxValueValidationErrMsg'),
      });
    }

    if (this.formCommission.controls['maxPercentage'].hasError('min')) {
      this.formCommission.controls['maxPercentage'].setErrors({
        message: this.translate.instant('error.minValueValidationErrMsg'),
      });
    }

    if (this.formCommission.controls['minPercentage'].hasError('min')) {
      this.formCommission.controls['minPercentage'].setErrors({
        message: this.translate.instant('error.minValueValidationErrMsg'),
      });
    }

    if (this.formCommission.controls['minPercentage'].hasError('max')) {
      this.formCommission.controls['minPercentage'].setErrors({
        message: this.translate.instant('error.minValueGreaterErrMsg'),
      });
      this.formCommission.controls['maxPercentage'].setErrors({
        message: this.translate.instant('error.maxValueLesserErrMsg'),
      });
    }

    if (
      this.formCommission.controls['defaultPercentage'].value &&
      this.formCommission.controls['defaultPercentage'].value >=
        this.formCommission.controls['minPercentage'].value &&
      this.formCommission.controls['defaultPercentage'].value <=
        this.formCommission.controls['maxPercentage'].value
    ) {
      this.formCommission.controls['defaultPercentage'].setErrors(null);
    } else {
      this.formCommission.controls['defaultPercentage'].setErrors({
        message: this.translate.instant('error.defaultValueValidationErrMsg'),
      });
    }

    if (this.formCommission.valid) {
      const regionIdSelected = this.formCommission.get('regionId').value;
      const riskId = this.formCommission.get('productId').value;
      if (this.type === 'Brokerage' && this.brokerageId) {
        this.commission = {
          active: true,
          brokerageId: Number(this.brokerageId),
          commissionMax: Number(this.maxPercentage) / 100 ?? 0,
          commissionMin: Number(this.minPercentage) / 100 ?? 0,
          commissionDefault: Number(this.defaultPercentage) / 100 ?? 0,
          riskRegionId: Number(regionIdSelected),
          riskId: Number(riskId),
          type: 1,
          override: this.commissionOverride,
        };
        if (
          regionIdSelected === -1 &&
          this.data.filter((single) => single.riskId === riskId).length > 0
        ) {
          this.showConfirmationModal = true;
          return;
        } else this.createBrokerCommission(this.commission);
      } else if (this.type === 'Producer' && this.producerId) {
        this.commission = {
          active: true,
          brokerageProducerId: Number(this.producerId),
          commissionMax: Number(this.maxPercentage) / 100 ?? 0,
          commissionMin: Number(this.minPercentage) / 100 ?? 0,
          commissionDefault: Number(this.defaultPercentage) / 100 ?? 0,
          riskRegionId: Number(regionIdSelected),
          riskId: Number(riskId),
          type: 1,
          override: this.commissionOverride,
        };
        if (
          regionIdSelected === -1 &&
          this.data.filter((single) => single.riskId === riskId).length > 0
        ) {
          this.showConfirmationModal = true;
          return;
        } else this.createProducerCommission(this.commission);
      }
    }
  }

  createBrokerCommission(commission) {
    this.submitBrokerCommission.emit({ commission: commission });
    this.submitted = false;
  }

  createProducerCommission(commission) {
    this.submitProducerCommission.emit({ commission: commission });
    this.submitted = false;
  }

  modifyBrokerCommission(commission, index) {
    this.updateBrokerCommission.emit({ commission: commission, index: index });
    this.editSubmitted = false;
  }

  modifyProducerCommission(commission, index) {
    this.updateProducerCommission.emit({
      commission: commission,
      index: index,
    });
    this.editSubmitted = false;
  }

  handleAddRegionChange(regionId) {
    this.formCommission.get('regionId').setValue(regionId);
  }
  async handleAddProductChange(productId) {
    this.formCommission.get('productId').setValue(productId);
    if (productId) await Promise.all([this.loadRegionWithRisk(productId)]);
  }

  async handleEditProductChange(productId) {
    this.editingForm.get('productId').setValue(productId);
    if (productId)
      await Promise.all([this.loadRegionWithRisk(productId, true)]);
  }
  handleEditRegionChange(regionId) {
    this.editingForm.get('regionId').setValue(regionId);
  }

  resetForm() {
    this.submitted = false;
    this.editSubmitted = false;
    this.formCommission.reset();
    this.maxPercentage = 0;
    this.minPercentage = 0;
    this.defaultPercentage = 0;
    this.maxPercentageEdit = 0;
    this.minPercentageEdit = 0;
    this.defaultPercentageEdit = 0;
    this.productId = '';
    this.regionId = '';
    this.currentRegionObj = '';
    this.currentProductObj = '';
  }

  onChangeMinPercentageEdit(value) {
    this.minPercentageEdit = value;
    this.handleMinPercentageValidateEdit(value);
    this.handleDefaultPercentageValidateEdit(this.defaultPercentageEdit);
  }

  handleMinPercentageValidateEdit(event) {
    const minPercentage = event?.target?.value ? event?.target?.value : event;

    if (Number(minPercentage) >= 1) {
      this.editingForm.controls['minPercentage'].setErrors(null);
    } else {
      this.editingForm.controls['minPercentage'].setErrors({
        message: this.translate.instant('error.minValueValidationErrMsg'),
      });
    }
  }

  onChangeMaxPercentageEdit(value) {
    this.maxPercentageEdit = value;
    this.handleMaxPercentageValidateEdit(value);
    this.handleDefaultPercentageValidateEdit(this.defaultPercentageEdit);
  }

  handleMaxPercentageValidateEdit(event) {
    const maxPercentage = event?.target?.value ? event?.target?.value : event;

    if (Number(maxPercentage) >= 1) {
      this.editingForm.controls['maxPercentage'].setErrors(null);
    } else {
      this.editingForm.controls['maxPercentage'].setErrors({
        message: this.translate.instant('error.minValueValidationErrMsg'),
      });
    }
  }

  onChangeDefaultPercentageEdit(value: number) {
    this.defaultPercentageEdit = value;
    this.handleDefaultPercentageValidateEdit(value);
  }

  handleDefaultPercentageValidateEdit(event) {
    const defaultPercentage = event?.target?.value
      ? event?.target?.value
      : event;
    if (
      defaultPercentage >= this.minPercentageEdit &&
      defaultPercentage <= this.maxPercentageEdit
    ) {
      this.editingForm.controls['defaultPercentage'].setErrors(null);
    } else {
      this.editingForm.controls['defaultPercentage'].setErrors({
        message: this.translate.instant('error.defaultValueValidationErrMsg'),
      });
    }
  }
}
