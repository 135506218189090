import { ViewportScroller } from '@angular/common';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-coverage-limit',
  templateUrl: './coverage-limit.component.html',
  styleUrls: ['./coverage-limit.component.less'],
})
export class CoverageLimitComponent implements OnInit, AfterViewInit {
  @Input() coverageList: any[];
  @Input() productId: any; // Assuming you have already defined the 'Product' interface in the parent component

  @Output() coverageSelectedIds: EventEmitter<any[]> = new EventEmitter<
    any[]
  >();

  updatedCoverageList: any[];
  activeCoverageIds: any;
  selectedIds: any;

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private viewPortScroller: ViewportScroller,
  ) {
    this.form = this.fb.group({});
  }

  ngOnInit(): void {
    const formControls = this.coverageList.reduce((acc, coverage) => {
      acc[coverage.coverageName] = [coverage.maxLimit];
      return acc;
    }, {});

    this.form = this.fb.group(formControls);
  }

  ngAfterViewInit(): void {
    this.viewPortScroller.scrollToPosition([0, 0]);
  }
  onToggleClick(coverageIndex: number, status: boolean): void {
    const changedCoverageList = this.coverageList.map((coverageItem, i) =>
      i === coverageIndex
        ? { ...coverageItem, isIncluded: status }
        : coverageItem,
    );
    this.coverageList = changedCoverageList;

    const activeCoverageIds: {
      id: number;
      maxLimit: number;
      active: number;
    }[] = this.coverageList.map((data) => ({
      id: data.id,
      maxLimit: Number(data.maxLimit),
      active: data.isIncluded === true ? 1 : 0,
    }));

    let dataToEmit: any = {
      selectedIds: activeCoverageIds,
      productId: this.productId,
      updatedCoverageList: this.coverageList,
      coverageIndex,
    };
    this.coverageSelectedIds.emit(dataToEmit);
  }
}
