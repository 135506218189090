import { Component, OnDestroy, OnInit } from '@angular/core';
import { MaintenanceService } from '../services/maintenance.service';
import { Router } from '@angular/router';
import { LocalStorageService } from '../services/localstorage-service';
import {
  getLocalDateTimeFromUtcString,
  isPastLocalDateTime,
} from '../utils/utils';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.less'],
})
export class MaintenanceComponent implements OnInit, OnDestroy {
  constructor(
    private maintenanceService: MaintenanceService,
    private router: Router,
    private localStorageService: LocalStorageService,
  ) {}

  upTimeHours: string = '';
  remainingTime: number;
  timerInterval: any;
  checkIfApplicationUpInterval: any;
  applicationAvailabilityDateTime: string;

  async ngOnInit() {
    let downTimeExpiryTime = this.maintenanceService.getEndDateTimeString();

    if (!!!downTimeExpiryTime) {
      await this.maintenanceService.isMaintenanceMode();
      downTimeExpiryTime = this.maintenanceService.getEndDateTimeString();
    }

    this.applicationAvailabilityDateTime =
      getLocalDateTimeFromUtcString(downTimeExpiryTime);
    this.upTimeHours = this.maintenanceService.getTimeDifference();
    this.convertTimeToSeconds();
    this.startCountdown();
    this.checkIfApplicationUp();
  }

  convertTimeToSeconds(): void {
    const parts = this.upTimeHours.split(':');
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    const seconds = parseInt(parts[2], 10);
    this.remainingTime = hours * 3600 + minutes * 60 + seconds;
  }

  async checkIfApplicationUp() {
    this.checkIfApplicationUpInterval = setInterval(
      async () => {
        const isMaintenanceWindow =
          await this.maintenanceService.isMaintenanceMode();
        if (!isMaintenanceWindow) {
          this.clearCheckIfApplicationUpInterval();
          this.router.navigate(['dashboard/home']);
        }
      },
      10 * 60 * 1000,
    );
  }

  clearCheckIfApplicationUpInterval() {
    if (this.checkIfApplicationUpInterval) {
      clearInterval(this.checkIfApplicationUpInterval);
    }
  }

  startCountdown(): void {
    this.timerInterval = setInterval(() => {
      if (
        this.remainingTime > 0 &&
        !isPastLocalDateTime(this.maintenanceService.getEndDateTimeString())
      ) {
        this.remainingTime--;
      } else {
        clearInterval(this.timerInterval);
        this.router.navigate(['/dashboard/home']);
      }
    }, 1000);
  }

  formatTime(): string {
    const hours = Math.floor(this.remainingTime / 3600);
    const minutes = Math.floor((this.remainingTime % 3600) / 60);
    const seconds = this.remainingTime % 60;
    return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
  }

  pad(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  handleRefreshClick() {
    this.router.navigate(['/dashboard/home']);
  }

  ngOnDestroy() {
    this.maintenanceService.clearTimeDifference();
    this.clearCheckIfApplicationUpInterval();
  }
}
