<div class="lifecycleHeader">
  <div class="left">
    <h1 class="LifecycleHead">{{ header }}</h1>
    <p class="lifecycleId">{{ policyId }}</p>
  </div>
  <div class="right" *ngIf="isPolicyActionBtns">
    <boxxds-button-v2
      *ngIf="isPolicyActionBtns.close"
      [buttonText]="
        'workFlow3.components.policyDirectory.button.close' | translate
      "
      [isDisabled]="!isProductActive"
      [size]="'md'"
      [btnClass]="'secondary gray'"
      [btnType]="'default'"
      [isDropdown]="false"
      [class.noPointerEvents]="!isProductActive"
      (handleClick)="isProductActive && closePolicy($event)"
      [btnStyle]="{ width: '100px' }"
    >
    </boxxds-button-v2>
    <boxxds-button-v2
      *ngIf="isPolicyActionBtns.decline"
      [buttonText]="
        'workFlow3.components.policyDirectory.button.decline' | translate
      "
      [isDisabled]="false"
      [size]="'md'"
      [btnClass]="'secondary gray'"
      [btnType]="'default'"
      [isDropdown]="false"
      (handleClick)="declinePolicy($event)"
      [btnStyle]="{ width: '100px' }"
    >
    </boxxds-button-v2>
    <boxxds-button-v2
      *ngIf="isPolicyActionBtns.cancel"
      [buttonText]="
        'workFlow3.components.policyDirectory.button.cancel' | translate
      "
      [isDisabled]="!isProductActive"
      [size]="'md'"
      [btnClass]="'secondary gray'"
      [btnType]="'default'"
      [isDropdown]="false"
      [class.noPointerEvents]="!isProductActive"
      (handleClick)="isProductActive && cancelPolicy($event)"
      [btnStyle]="{ width: '100px' }"
    >
    </boxxds-button-v2>
    <boxxds-button-v2
      *ngIf="isPolicyActionBtns.bind"
      [buttonText]="
        'workFlow3.components.policyDirectory.button.bind' | translate
      "
      [isDisabled]="!isProductActive"
      [size]="'md'"
      [btnClass]="'primary'"
      [btnType]="'default'"
      [isDropdown]="false"
      [class.noPointerEvents]="!isProductActive"
      (handleClick)="isProductActive && bindPolicy($event)"
      [btnStyle]="{ width: '200px' }"
    >
    </boxxds-button-v2>
    <boxxds-button-v2
      *ngIf="isPolicyActionBtns.reinstate"
      [buttonText]="
        'workFlow3.components.policyDirectory.button.reInstate' | translate
      "
      [isDisabled]="!isProductActive"
      [size]="'md'"
      [btnClass]="'primary'"
      [btnType]="'default'"
      [isDropdown]="false"
      [class.noPointerEvents]="!isProductActive"
      (handleClick)="isProductActive && reinstatePolicy($event)"
      [btnStyle]="{ width: '200px' }"
    >
    </boxxds-button-v2>
    <boxxds-button-v2
      *ngIf="isPolicyActionBtns.renew"
      [buttonText]="
        'workFlow3.components.policyDirectory.button.renew' | translate
      "
      [isDisabled]="!isProductActive"
      [size]="'md'"
      [btnClass]="'primary'"
      [btnType]="'default'"
      [isDropdown]="false"
      [class.noPointerEvents]="!isProductActive"
      (handleClick)="isProductActive && renewPolicy($event)"
      [btnStyle]="{ width: '200px' }"
    >
    </boxxds-button-v2>
    <boxxds-button-v2
      *ngIf="isPolicyActionBtns.startNewPeriod"
      [buttonText]="
        'workFlow3.components.policyDirectory.button.startNewPeriod' | translate
      "
      [isDisabled]="!isProductActive"
      [size]="'md'"
      [btnClass]="'primary'"
      [btnType]="'default'"
      [isDropdown]="false"
      [class.noPointerEvents]="!isProductActive"
      (handleClick)="isProductActive && startNewPeriodPolicy($event)"
      [btnStyle]="{ width: '200px' }"
    >
    </boxxds-button-v2>
  </div>
</div>
