import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import { take } from 'rxjs';
import { formatAmountWithCurrency, getAlertHead } from 'src/app/utils/utils';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { LocationService } from 'src/app/services/location.service';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { isStatusDisabled } from 'src/app/constants/quoteStatus';
import { AlertService } from 'src/app/services/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.less'],
})
export class CardComponent implements OnInit {
  @Input() data;

  currency = '';
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  permissionKeyList: string[] = [];
  isPolicy: boolean = false;
  quoteFlowSteps;
  quoteFlowUrl = '';
  shortDateFormat: string = '';
  longDateFormat: string = '';
  isRestrictedRoleSubmission: boolean = false;

  constructor(
    private router: Router,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    public locationService: LocationService,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.permissionKeyList = Object.keys(data.litePermissionList);
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
      this.isRestrictedRoleSubmission = data.isRestrictedRoleSubmission;
    });
    this.quoteFlowSteps = this.localStorageService.getQuoteFlowSteps();
    if (this.quoteFlowSteps === '7') {
      this.quoteFlowUrl = 'quote';
    } else if (this.quoteFlowSteps === '4') {
      this.quoteFlowUrl = 'quote4flow';
    } else {
      this.quoteFlowUrl = 'workflow3';
    }
    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));
    this.isPolicy = isNaN(this.data.id);
  }

  async handleClick() {
    if (this.isRestrictedRoleSubmission === true) {
      this.alertService.clearAlerts(-1);
      const alertData = {
        type: 'warn',
        headerText: getAlertHead('warning'),
        bodyText: this.translateService.instant(
          'common.error.noAccessToPageErrorMsg',
        ),
      };
      this.alertService.addAlert(alertData);
      return;
    }
    if (this.data?.id && this.data.id != '') {
      if (!Number(this.data?.id)) {
        this.router.navigateByUrl(
          `dashboard/workflow3/policies/${this.data?.id}`,
          {
            skipLocationChange: true,
          },
        );
      } else {
        this.store.dispatch(
          new CreateQuoteAction.setPolicyPeriodId(this.data.policyPeriodId),
        );

        if (this.data.quoteStatus.toLowerCase() == 'submission') {
          this.router.navigate(
            [`../${this.quoteFlowUrl}/new/product/form/${this.data?.id}`],
            {
              relativeTo: this.activatedRoute,
              skipLocationChange: true,
            },
          );
        } else if (isStatusDisabled(this.data?.quoteStatus)) {
          //Checking whether the quote status included in disabled statuses,

          this.router.navigate([`../${this.quoteFlowUrl}/${this.data?.id}`], {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          });
        } else if (this.data?.brokerageCommission === 0) {
          //Checking whether the commission is zero or not,
          this.router.navigate(
            [`../${this.quoteFlowUrl}/new/product/form/${this.data?.id}`],
            {
              relativeTo: this.activatedRoute,
              skipLocationChange: true,
            },
          );
        } else {
          this.router.navigate([`../${this.quoteFlowUrl}/${this.data?.id}`], {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          });
        }
      }
    }
  }

  getProductLimit(productsArr) {
    return productsArr
      .map((pdtObj) => {
        return `${pdtObj.risk} (${formatAmountWithCurrency(
          pdtObj.limit,
          this.currency,
        )})`;
      })
      .join(',');
  }
}
