<div class="holder">
  <boxxds-breadcrumb [items]="itemsMenu"></boxxds-breadcrumb>
  <boxxds-heading
    *ngIf="brokerageSelected"
    sz="5"
    [text]="brokerageSelected.name"
  >
  </boxxds-heading>
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!showBodySpinner">
    <div class="alert-holder">
      <boxxds-alert
        (handleCloseEvent)="onHandleCloseEvent()"
        *ngIf="notificationAlert?.show"
        [type]="notificationAlert?.type"
        [headerText]="notificationAlert?.headerText"
        [bodyText]="notificationAlert?.bodyText"
      >
      </boxxds-alert>
      <boxxds-alert
        (handleCloseEvent)="brokerageCreatedAlertClose()"
        *ngIf="isNavigatedFromAddBrokerage"
        type="success"
        headerText="success!"
        bodyText="Brokerage saved successfully."
      >
      </boxxds-alert>
      <boxxds-alert
        (handleCloseEvent)="onHandleCloseEvent()"
        *ngIf="operationSuccess"
        type="success"
        headerText="success!"
        [bodyText]="successMessage"
      >
      </boxxds-alert>
      <boxxds-alert
        (handleCloseEvent)="onHandleCloseEvent()"
        *ngIf="showSuccessBanner()"
        type="success"
        headerText="success!"
        [bodyText]="getBannerMsg()"
      >
      </boxxds-alert>

      <boxxds-alert
        *ngIf="hasErrorBrokerage"
        type="error"
        headerText="error!"
        [bodyText]="errorMessageBrokerage"
        (handleCloseEvent)="onHandleCloseEvent()"
      >
      </boxxds-alert>

      <boxxds-alert
        (handleCloseEvent)="onHandleCloseEvent()"
        *ngIf="hasErrorProfile"
        type="error"
        headerText="error!"
        [bodyText]="errorMessageProfile"
      >
      </boxxds-alert>

      <boxxds-alert
        (handleCloseEvent)="onHandleCloseEvent()"
        *ngIf="hasErrorRelationship"
        type="error"
        headerText="error!"
        [bodyText]="errorMessageRelationship"
      >
      </boxxds-alert>

      <boxxds-alert
        (handleCloseEvent)="onHandleCloseEvent()"
        *ngIf="hasErrorLocation"
        type="error"
        headerText="error!"
        [bodyText]="errorMessageLocation"
      >
      </boxxds-alert>
    </div>

    <div class="form-panel-title">
      <boxxds-body
        sz="lg"
        [text]="'Brokerage details'"
        [ngStyle]="{ color: '#BDBDBF', textAlign: 'left' }"
      >
      </boxxds-body>

      <boxxds-body
        sz="xs"
        [text]="'*REQUIRED'"
        [ngStyle]="{
          color: '#BDBDBF',
          textAlign: 'right',
          'margin-top': '5px'
        }"
      >
      </boxxds-body>
    </div>

    <form [formGroup]="form">
      <app-brokerage-details
        [isActive]="brokerageActive"
        [isFetching]="isFetching"
        [parentInitValue]="parentInitValue"
        [form]="brokerage"
        [requiredErrMsg]="requiredErrMsg"
        [invalidErrMsg]="invalidErrMsg"
        [parentCompanyInvalidErrMsg]="parentCompanyInvalidErrMsg"
        [formSubmitted]="formSubmitted"
        (triggerProfilePicErrorToParent)="
          triggerProfilePicErrorToParent($event)
        "
      >
      </app-brokerage-details>

      <app-location-details-form
        (eventEmitRegionIdSelected)="regionIdSelected($event)"
        [form]="location"
        [requiredErrMsg]="requiredErrMsg"
        [formSubmitted]="formSubmitted"
        [isActive]="brokerageActive && this.permissionList[this.currentScreen]"
      >
      </app-location-details-form>

      <div class="footer-add-branch">
        <div class="footer-left-container">
          <app-timestamp
            label="Created by"
            [value]="createdBy"
            [containerStyle]="{ marginLeft: '10px', top: '-10px' }"
          ></app-timestamp>
          <app-timestamp
            *ngIf="updatedBy"
            label="Updated last by"
            [value]="updatedBy"
            [containerStyle]="{
              marginLeft: '10px',
              position: 'relative',
              top: '-10px'
            }"
          ></app-timestamp>
        </div>
        <div
          *ngIf="this.permissionList[this.currentScreen] && brokerageSelected"
          class="footer-rigth-container"
        >
          <a
            *ngIf="brokerageSelected.active"
            href="javascript:void(0)"
            (click)="handleDeactivateBrokerage()"
            [ngStyle]="{ 'margin-right': '64px', 'margin-top': '12px' }"
            >{{
              ("common.deactivate" | translate) +
                " " +
                ("common.brokerage" | translate)
            }}</a
          >

          <a
            *ngIf="!brokerageSelected.active"
            href="javascript:void(0)"
            (click)="handleActivateBrokerage()"
            [ngStyle]="{ 'margin-right': '64px', 'margin-top': '12px' }"
            >{{
              ("common.reactivate" | translate) +
                " " +
                ("common.brokerage" | translate)
            }}</a
          >

          <button
            *ngIf="showSpinner"
            class="btn-processing"
            tabindex="0"
            [ngStyle]="{
              height: '32px',
              border: '1px solid #FFBE3D',
              borderRadius: '8px',
              width: '119px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '4px 8px',
              background: '#FCF8EF'
            }"
          >
            <boxxds-spinner></boxxds-spinner>
          </button>

          <boxxds-button
            *ngIf="!showSpinner"
            [buttonText]="'Save changes'"
            [isDisabled]="
              !brokerageActive ||
              (!!!this.isBrokerageFormDirty && !!!this.isLocationFormDirty)
            "
            (handleClick)="handleSubmit($event)"
            [type]="'button'"
            btnType="primary-outlined"
            [sz]="'sm'"
            [btnStyle]="btnStyle"
          >
          </boxxds-button>
        </div>
      </div>

      <app-commission-details
        [isActive]="brokerageActive"
        (onCommissionChange)="handleBrokerageCommissionModification($event)"
        [resetAddFormSubject]="resetAddCommissionFormSubject"
        [commissionData]="brokerageCommissions"
        (onCommissionPageChange)="handleCommissionPageChange($event)"
      >
        <div class="form-panel-title">
          <boxxds-body
            sz="lg"
            [text]="'Brokerage commissions details'"
            [customStyle]="{ textAlign: 'left' }"
          >
          </boxxds-body>
        </div>
      </app-commission-details>

      <div class="form-footer-container" *ngIf="showBackToQuoteButton">
        <div class="form-panel-footer-right">
          <boxxds-button
            [buttonText]="'common.quoteBackButtonLabel' | translate"
            [type]="'button'"
            btnType="primary-outlined"
            [btnStyle]="{ background: 'none', padding: '0 30px' }"
            (handleClick)="handleBackToQuote($event)"
            [isDisabled]="!showBackToQuoteButton"
          >
          </boxxds-button>
        </div>
      </div>
      <app-modal-commission-confirmation
        *ngIf="showConfirmationModal"
        [showModal]="showConfirmationModal"
        [commissionData]="commissionConfirmationData"
        (handleClose)="closeModalConfirmation()"
        (handleSubmitCommissionOverride)="handleCommissionOverride($event)"
      >
      </app-modal-commission-confirmation>

      <div class="add-producer-panel">
        <div class="neutral-gradient"></div>
        <div class="panel-content">
          <app-branch-table
            *ngIf="brokerageSelectedId"
            [isActive]="brokerageActive"
            [brokerageId]="brokerageSelectedId"
          ></app-branch-table>
        </div>
      </div>
    </form>
  </ng-container>
</div>
