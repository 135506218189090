<div
  class="company-container"
  [ngStyle]="isSuccess && hasError && { paddingTop: '140px' }"
>
  <div class="alert-holder">
    <boxxds-alert
      *ngIf="showErrorAlert"
      (handleCloseEvent)="handleCloseSuccessEventExt()"
      type="error"
      headerText="error!"
      [bodyText]="msgErrorAlert"
    >
    </boxxds-alert>
    <boxxds-alert
      *ngIf="isSuccess"
      (handleCloseEvent)="handleCloseSuccessEvent()"
      type="success"
      headerText="success!"
      bodyText="Saved successfully."
      [containerStyle]="{
        width: 'calc(100vw - 320px)',
        maxWidth: '1115px',
        position: 'fixed',
        top: '100px',
        zIndex: '2',
        marginTop: '8px'
      }"
    >
    </boxxds-alert>

    <boxxds-alert
      *ngIf="hasError"
      (handleCloseEvent)="handleCloseSuccessEvent()"
      type="error"
      headerText="error!"
      [bodyText]="errorMessage"
      [containerStyle]="{
        width: 'calc(100vw - 320px)',
        maxWidth: '1115px',
        position: 'fixed',
        top: '100px',
        zIndex: '2',
        marginTop: '8px'
      }"
    >
    </boxxds-alert>
  </div>
  <div class="create-title-container">
    <boxxds-body
      sz="lg"
      [text]="'systemUser.label.boxxUserDetails' | translate"
      [customStyle]="{
        color: '#616162',
        textAlign: 'left',
        marginBottom: '8px',
        fontSize: '16px'
      }"
    ></boxxds-body>
    <span style="text-transform: uppercase"
      >*{{ "common.required" | translate }}</span
    >
  </div>
  <div class="search-container">
    <form [formGroup]="boxxUserForm">
      <div class="add-container">
        <div class="d-flex">
          <boxxds-searchable-dropdown
            [form]="boxxUserForm"
            [control]="'boxxUserId'"
            [label]="('systemUser.label.searchBoxxUser' | translate) + '*'"
            [placeholder]="
              'systemUser.placeholder.enterBoxxUserName' | translate
            "
            [options]="users"
            [dispatchTextInputChange]="true"
            (textInputChangeEventEmmiter)="searchUsers($event)"
            [invalidErrMsg]="formSubmitted ? (invalidUser | translate) : ''"
            (currentValueChange)="boxxUserValueChanged($event)"
            [currentValue]="boxxUserId"
            [requiredErrMsg]="requiredErrMsg | translate"
            [formSubmitted]="formSubmitted"
            [btnStyle]="{ marginTop: '8px', width: '264px' }"
            [hideSelectedValue]="false"
            [dropdownContainerStyle]="{ width: '264px', marginRight: '40px' }"
          >
          </boxxds-searchable-dropdown>
          <boxxds-searchable-dropdown
            [form]="boxxUserForm"
            [control]="'roleId'"
            [placeholder]="'placeholder.searchRole' | translate"
            [id]="roleId"
            [options]="roles"
            [label]="('systemUser.label.role' | translate) + '*'"
            [btnStyle]="{ marginTop: '8px', width: '264px' }"
            [invalidErrMsg]="formSubmitted ? (invalidRole | translate) : ''"
            [dropdownContainerStyle]="{ width: '264px', marginRight: '40px' }"
            class="employees"
            [requiredErrMsg]="requiredErrMsg | translate"
            [formSubmitted]="formSubmitted"
            [currentValue]="roleId"
            (currentValueChange)="boxxRoleValueChanged($event)"
            (textInputChangeEventEmmiter)="searchRoles($event)"
            [dispatchTextInputChange]="true"
          >
          </boxxds-searchable-dropdown>
          <boxxds-textinput
            [placeholder]="'systemUser.placeholder.email' | translate"
            [form]="boxxUserForm"
            [control]="'loginEmail'"
            [id]="'email'"
            [width]="'406px'"
            [label]="('systemUser.label.loginEmail' | translate) + '*'"
            [requiredErrMsg]="requiredErrMsg | translate"
            [invalidErrMsg]="invalidErrMsg | translate"
            [containerStyle]="{ marginTop: '0px' }"
            [formSubmitted]="formSubmitted"
            maxLength="150"
          >
          </boxxds-textinput>
        </div>
      </div>
      <div class="btn-container">
        <boxxds-button
          [type]="'button'"
          *ngIf="!showSpinner"
          [buttonText]="'systemUser.label.addBoxxUser' | translate"
          btnType="primary-outlined"
          type="submit"
          [sz]="'sm'"
          (handleClick)="onSubmit(boxxUserForm.value)"
        >
        </boxxds-button>
        <button
          *ngIf="showSpinner"
          class="btn-processing"
          tabindex="0"
          [ngStyle]="{
            border: '1px solid #FFBE3D',
            borderRadius: '8px',
            width: '150px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4px 8px',
            background: '#FCF8EF'
          }"
        >
          <boxxds-spinner></boxxds-spinner>
        </button>
      </div>
    </form>
  </div>
</div>
