<div class="endorse-outer-container">
  <ng-container *ngIf="showTabSpinner">
    <div class="loader-wrapper">
      <div class="tab-body-spinner">
        <boxxds-spinner
          [containerStyle]="{
            width: '112px',
            height: '112px',
            '--b': '15px'
          }"
        ></boxxds-spinner>
      </div>
    </div>
  </ng-container>
  <ng-container>
    <div
      class="endorsement-container"
      [ngStyle]="policyChangeContainerStyle"
      *ngIf="!showTabSpinner"
    >
      <div class="endorsement-header">
        <div class="left">
          <h2 class="endorsement-title">
            {{ "workFlow3.plc.policyChange.label.title" | translate }}
          </h2>
          <p class="endorsement-subtitle">
            {{ "workFlow3.plc.policyChange.label.subtitle" | translate }}
          </p>
        </div>
        <div class="right">
          <div>
            <boxxds-button-v2
              [isDisabled]="false"
              [size]="'lg'"
              [btnType]="'close'"
              (handleClick)="closePolicyChange()"
              [btnClass]="'close-button'"
              [btnStyle]="{
                borderRadius: '31px',
                background: '#D0D5DD',
                width: '36px',
                height: '36px'
              }"
            >
            </boxxds-button-v2>
          </div>
        </div>
      </div>
      <form [formGroup]="PolicyChangeForm">
        <div class="endorse-row col-md-12">
          <div class="col-md-12 policy-change-col-12">
            <div class="form col-md-12">
              <div class="form-group">
                <div class="endorse-textarea">
                  <boxxds-textarea
                    [label]="
                      'workFlow3.components.notes.label.noteInputLabel'
                        | translate
                    "
                    [placeholder]="
                      'workFlow3.components.notes.label.messageBody' | translate
                    "
                    [form]="PolicyChangeForm"
                    [control]="'notes'"
                    [lblStyle]="{
                      color: '#667085',
                      fontFamily: 'Gibson',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: '20px',
                      height: '28px'
                    }"
                    [textareaStyle]="{
                      maxWidth: '100%'
                    }"
                    [formSubmitted]="errorMsgValid"
                    (handleKeyUp)="onKeyUptextareaValidation($event)"
                    maxLength="500"
                    [isDisabled]="false"
                  ></boxxds-textarea>
                </div>

                <div class="textarea-footer">
                  <div class="textarea-footer-label">
                    {{ remainingChars }}
                    {{
                      "workFlow3.plc.policyChange.label.characterLeftLabel"
                        | translate
                    }}
                  </div>
                  <div>
                    <boxxds-status-badge
                      [status]="trxType"
                      [statusType]="'secondary'"
                      [badgeStyle]="{
                        display: 'flex',
                        padding: '2px 8px',
                        aligItems: 'center',
                        borderRadius: '9999px',
                        border: '1px solid #B1F5C9',
                        background: '#F0F9FF',
                        width: 'fit-content',
                        color: '#008932',
                        textAlign: 'center',
                        fontFamily: 'Gibson',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '18px'
                      }"
                    ></boxxds-status-badge>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="endorsement-footer">
          <div class="right">
            <div class="button-wrapper">
              <div class="btn-wrapper">
                <boxxds-button-v2
                  [isDisabled]="false"
                  [btnStyle]="{
                    color: '#2D2D39',
                    fontSize: '14px',
                    fontFamily: 'Gibson',
                    fontWeight: '600',
                    width: '124px',
                    background: '#fff',
                    height: '40px'
                  }"
                  [buttonText]="
                    'workFlow3.plc.policyChange.button.discard' | translate
                  "
                  [btnType]="'link gray'"
                  type="button"
                  (handleClick)="handleDiscard()"
                >
                </boxxds-button-v2>
                <boxxds-button-v2
                  [isDisabled]="false"
                  [buttonText]="
                    'workFlow3.plc.policyChange.button.save' | translate
                  "
                  [type]="'submitted'"
                  [size]="'md'"
                  [btnClass]="'secondary gray'"
                  [btnType]="'default'"
                  [btnStyle]="{
                    width: '151px',
                    background: '#fff',
                    height: '40px',
                    color: '#B17806',
                    border: '1px solid #FCBD32',
                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'
                  }"
                  (handleClick)="handleSave()"
                >
                </boxxds-button-v2>
                <boxxds-button-v2
                  *ngIf="true"
                  [isDisabled]="false"
                  [buttonText]="
                    'workFlow3.plc.policyChange.button.execute' | translate
                  "
                  [size]="'md'"
                  [btnClass]="'primary'"
                  [btnType]="'default'"
                  [btnStyle]="{ width: '151px', height: '40px' }"
                  (handleClick)="handleTrxExecute()"
                >
                </boxxds-button-v2>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </ng-container>
</div>
