import { Component, OnInit } from '@angular/core';
import { CognitoService } from 'src/app/services/cognito-service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cognito',
  templateUrl: './cognito.component.html',
  styleUrls: ['./cognito.component.less'],
})
export class CognitoComponent implements OnInit {
  invalidCode = false;

  constructor(
    private router: Router,
    private activedRoute: ActivatedRoute,
    public cognitoService: CognitoService,
  ) {}

  ngOnInit(): void {
    this.activedRoute.queryParams.subscribe((params) => {
      const code = params['code'];
      const redirectUrl =
        params['bypassMaintenance'] && params['bypassMaintenance'] === 'true'
          ? environment.cognitoBypassRedirectUrl
          : environment.cognitoRedirectUrl;
      if (
        params['bypassMaintenance'] &&
        params['bypassMaintenance'] === 'true'
      ) {
        localStorage.setItem('bypassmaintenance', 'true');
      } else {
        localStorage.setItem('bypassmaintenance', 'false');
      }
      this.cognitoService
        .GetAuthToken(code, environment.defaultFlow, redirectUrl)
        .subscribe(
          (data) => {
            if (
              params['bypassMaintenance'] &&
              params['bypassMaintenance'] === 'true'
            ) {
              localStorage.setItem('bypassmaintenance', 'true');
            } else {
              localStorage.setItem('bypassmaintenance', 'false');
            }
            this.router.navigate(['dashboard/home'], { replaceUrl: true });
          },
          (err) => {
            this.invalidCode = true;
          },
          () => console.log('HTTP request completed.'),
        );
    });
  }
}
