import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, firstValueFrom, take } from 'rxjs';

import { Quote } from 'src/app/models/quote.model';
import {
  getQuoteSelector,
  getSelectedQuoteOption,
} from 'src/app/store/create-quote/create-quote.selector';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { PolicyRiskService } from 'src/app/services/policy-risk.service';
import { PolicyQuoteOptionService } from 'src/app/services/policy-quote-option.service';
import { DomainsService } from 'src/app/services/domains.service';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { PolicyRiskDocService } from 'src/app/services/policy-risk-doc.service';
import { GenerateQuoteService } from 'src/app/services/doc-create.service';
import { ConfMessageTemplateService } from 'src/app/services/message-template-services';
import { MessageSendService } from 'src/app/services/message-send.service';
import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import {
  DOC_GENERATION_WAIT_TIME,
  GENERATE_DOC_SUCCESS_STATUS_CODE,
} from 'src/app/constants/quote-constant';
import { getErrorMessage } from 'src/app/utils/utils';
import { getLifecycleState } from 'src/app/store/lifecycle/lifecycle.select';
import { DocumentTemplateService } from 'src/app/services/doc-template.service';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-quote-compare',
  templateUrl: './quote-compare.component.html',
  styleUrls: ['./quote-compare.component.less'],
})
export class QuoteCompareComponent implements OnInit, OnDestroy {
  form$: Observable<Quote>;
  quotesToCompare = [];
  userID: number;
  stageId: number;
  messageTypeId: number;
  isLoader: boolean = false;
  policyPeriodId: number = null;
  templateDetails: { [x: string]: string | number }[] = [];
  docDetails: { [x: string]: string | number | boolean }[] = [];
  rowDocDetails: { [x: string]: string | number | boolean } = {};
  newTimeout: NodeJS.Timeout;
  toggle;
  productsAdded = [];
  quoteSelected = [];
  showSendModal = false;
  showDocPreview = false;
  currentPreviewDocUrl;
  selectedQuoteDetails;
  quoteOptions = [];
  showErrorAlert = false;
  alertMsg = '';
  showBodySpinner: boolean = false;

  currency = '';
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  currentQuoteStatus: string = '';
  showDocTempSelectionPopup: boolean = false;
  docPopupDetails: {
    statusType: string;
    action: any;
    documentTemplate: any;
    policyPeriodId: any;
    stageId: any;
  };
  alertErrMsg = [];
  policyId;
  policyRiskId;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private policyRiskService: PolicyRiskService,
    private policyQuoteOptionService: PolicyQuoteOptionService,
    private domainsService: DomainsService,
    private localStorageService: LocalStorageService,
    private policyRiskDocService: PolicyRiskDocService,
    private generateQuoteService: GenerateQuoteService,
    private messageTemplateService: ConfMessageTemplateService,
    private messageSendService: MessageSendService,
    private DocumentTemplateService: DocumentTemplateService,
    private alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
    });

    this.store.pipe(select(getLifecycleState)).subscribe((data) => {
      this.currentQuoteStatus = data.quoteStatus;
    });

    this.userID = this.localStorageService.getBoxxUserId();
    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));
    this.form$ = this.store.pipe(select(getQuoteSelector));
    this.form$
      .pipe(take(1))
      .subscribe(
        (event) => (
          (this.policyPeriodId = Number(event.ui.policyPeriodId)),
          (this.policyId = Number(event.ui.policyId))
        ),
      );
    this.toggle = { expandFeeSection: true, expandPremiumSection: true };
    let uiData;
    this.form$.subscribe((event) => (uiData = event.ui));
    this.policyRiskService
      .GetAllByPolicyPeriodId(uiData.policyPeriodId)
      .subscribe((data) => {
        this.quoteOptions = data.data[0].PolicyRiskTrxes[0].QuoteOptions;
        this.policyRiskId = data.data[0].PolicyRiskTrxes[0].policyRiskId;
      });
    this.docPopupDetails = {
      statusType: 'Document template selection',
      action: 'docTempSelection',
      documentTemplate: [],
      policyPeriodId: 0,
      stageId: 0,
    };
  }

  ngOnDestroy(): void {
    this.alertService.clearAlerts(-1);
  }

  getQuotesToCompare(quoteOptns) {
    const quoteOptionsWithIdx = quoteOptns.map((quote, index) => ({
      ...quote,
      index,
    }));
    const selectedQuotes = quoteOptionsWithIdx.filter(
      (quote) => quote.ui.selectedForCompare,
    );
    this.quotesToCompare = selectedQuotes;

    return selectedQuotes;
  }

  getProducts() {
    this.quotesToCompare.forEach((quote) => {
      quote['products']?.forEach((productObj) => {
        if (this.productsAdded.indexOf(productObj.key) === -1) {
          this.productsAdded.push(productObj.key);
          this.toggle = {
            ...this.toggle,
            [productObj.key]: !this.toggle[productObj.key],
          };
        }
      });
    });

    return this.productsAdded;
  }

  getProductValuesOfQuote(index, product) {
    return this.quotesToCompare[index].products.filter(
      (pdtObj) => pdtObj.key == product,
    );
  }

  toggleProduct(product) {
    this.toggle = {
      ...this.toggle,
      [product]: !this.toggle[product],
    };
  }

  getCopy(dataObj) {
    return {
      products: dataObj.products.map((object) => ({ ...object })),
      total: { ...dataObj.total },
      ui: { ...dataObj.ui },
    };
  }

  handleRemove(index, quote) {
    this.closeAlert();
    const quoteCpy = this.getCopy(quote);
    let updatedQuote = {
      ...quoteCpy,
      ui: {
        ...quoteCpy.ui,
        selectedForCompare: false,
      },
    };
    this.store.dispatch(
      new CreateQuoteAction.UpdateQuoteOption({ quote: updatedQuote, index }),
    );
  }

  async handleQuoteSelect(optionNumber, quote) {
    // const index = this.quotesToCompare.findIndex(quote => quote?.products[0]?.optionNumber == optionNumber);
    // this.selectedQuoteDetails = { index: this.quotesToCompare[index].index, quote: this.quotesToCompare[index] };
    // const selectedIdx = this.quoteSelected.findIndex(val => val == true);
    // this.quoteSelected[selectedIdx] = false;
    // this.quoteSelected[index] = true;

    // this.isQuoteSelected = true;

    let quoteOptions;
    this.form$.subscribe((event) => (quoteOptions = event.quoteOptions));
    const selectedQuoteIdx = quoteOptions.findIndex(
      (quoteOptn) => quoteOptn?.products[0]?.optionNumber == optionNumber,
    );
    if (selectedQuoteIdx !== -1) {
      this.selectedQuoteDetails = { index: selectedQuoteIdx, quote };

      const previousSelectedQuoteOptionIdx = quoteOptions.findIndex(
        (quoteOptn) => quoteOptn?.ui?.optionSelected,
      );
      if (previousSelectedQuoteOptionIdx !== -1) {
        const previousQuoteOption =
          quoteOptions[previousSelectedQuoteOptionIdx];
        let updatedQuote = {
          ...previousQuoteOption,
          ui: { ...previousQuoteOption.ui, optionSelected: false },
        };
        this.store.dispatch(
          new CreateQuoteAction.UpdateQuoteOption({
            quote: updatedQuote,
            index: previousSelectedQuoteOptionIdx,
          }),
        );
      }
      const selectedQuoteOption = quoteOptions[selectedQuoteIdx];
      if (selectedQuoteOption) {
        let updatedQuote = {
          ...selectedQuoteOption,
          ui: { ...selectedQuoteOption.ui, optionSelected: true },
        };
        this.store.dispatch(
          new CreateQuoteAction.UpdateQuoteOption({
            quote: updatedQuote,
            index: selectedQuoteIdx,
          }),
        );
      }

      let quoteOption = {
        optionSelected: 1,
      };

      for (const currentProduct of quote.products) {
        try {
          // Update quote option in the backend as times as products has the quote
          const quoteUpdate = this.policyQuoteOptionService.Update(
            currentProduct.quoteOptionId,
            quoteOption,
          );
          await firstValueFrom(quoteUpdate);
          // Update in the front the Selected quote option object
          this.store.dispatch(
            new CreateQuoteAction.SelectQuoteOption(this.selectedQuoteDetails),
          );
        } catch (error) {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
            this.alertMsg = error?.message || error?.error;
          }
        }
      }
    }
  }

  closeAlert() {
    this.showErrorAlert = false;
    this.alertMsg = '';
  }

  handleBackBtn() {
    this.closeAlert();
    if (this.selectedQuoteDetails) {
      this.store.dispatch(
        new CreateQuoteAction.SelectQuoteOption(this.selectedQuoteDetails),
      );
    }
    this.router.navigate(['/dashboard/quote/new/options'], {
      relativeTo: this.activatedRoute,
      skipLocationChange: true,
    });
  }

  async getStageId() {
    this.stageId = undefined;
    return new Promise<void>((resolve) => {
      this.domainsService.GetByDomainCode('DOCMERGESTAGE', true).subscribe({
        next: (response) => {
          let docStage = response.data.filter(
            (template) =>
              template.subdomaincode === 'DOCMERGESTAGE_QUOTECOMPARISON',
          )[0];
          this.stageId = docStage.id;
          resolve();
        },
        error: (error) => {
          this.isLoader = false;
          this.showSendModal = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.alertMsg = getErrorMessage(error);
        },
      });
    });
  }

  async populateMessageType() {
    this.messageTypeId = undefined;
    return new Promise<void>((resolve) => {
      this.domainsService.GetByDomainCode('MESSAGETYPE', true).subscribe({
        next: (response) => {
          let messageType = response.data.filter(
            (template) => template.subdomaincode === 'MESSAGETYPE_QUOTE',
          )[0];
          this.messageTypeId = messageType.id;
          resolve();
        },
        error: (error) => {
          this.isLoader = false;
          this.showSendModal = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.alertMsg = getErrorMessage(error);
        },
      });
    });
  }

  async getDocument() {
    this.policyRiskDocService
      .getPolicyRiskGeneratedDocCore(this.policyPeriodId, this.stageId)
      .subscribe({
        next: (response) => {
          let { data } = response;
          if (Object.entries(data).length === 0) return;

          if (!data.PolicyRiskDocument || data.PolicyRiskDocument.length === 0)
            return;
          if (response?.data?.status === GENERATE_DOC_SUCCESS_STATUS_CODE) {
            this.docDetails = data.PolicyRiskDocument;
          } else {
            this.docDetails = [];
          }
          this.rowDocDetails = data;
        },
        error: (error) => {
          clearTimeout(this.newTimeout);
          this.isLoader = false;
          this.showSendModal = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.alertMsg = getErrorMessage(error);
        },
      });
  }

  async updateSelectedPremium() {
    let quoteOptions = [];
    this.form$.subscribe((event) => (quoteOptions = event.quoteOptions));
    const selectedQuote = quoteOptions?.filter(
      (quoteOption) => quoteOption?.ui?.optionSelected,
    );
    if (selectedQuote?.length > 0) {
      const premium = selectedQuote[0]?.total?.premium || 0;
      if (premium && premium > 0) {
        this.store.dispatch(
          new CreateQuoteAction.updateUiContents({
            premium,
          }),
        );
      }
    }
  }

  async getMessageTemplates() {
    this.messageTemplateService
      .GetAllMessageTemplates(this.messageTypeId)
      .subscribe({
        next: (response) => {
          this.templateDetails = response?.data ?? [];
          this.isLoader = false;
        },
        error: (error) => {
          this.isLoader = false;
          this.showSendModal = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.alertMsg = getErrorMessage(error);
        },
      });
  }

  async handleSendComparison() {
    await this.updateSelectedPremium();
    this.closeAlert();
    this.docDetails = [];
    this.rowDocDetails = {};
    this.showSendModal = !this.showSendModal;
    this.isLoader = !this.isLoader;
    const interval = 5000;
    const startTime = Date.now();

    const payload = this.quotesToCompare.map((quote) => ({
      id: quote.products[0]?.quoteOptionId,
    }));
    if (this.showSendModal) {
      await this.getStageId();
      await this.populateMessageType();
      await this.getDocument();
      if (this.docDetails.length === 0) {
        this.generateQuoteService.generateQuoteComparePdf(payload).subscribe({
          next: async (response) => {
            while (
              Date.now() - startTime < DOC_GENERATION_WAIT_TIME &&
              this.rowDocDetails?.['status'] !== 1 &&
              this.rowDocDetails?.['status'] !== 2 &&
              (this.docDetails.length === 0 ||
                this.rowDocDetails?.['status'] === 0)
            ) {
              await this.getDocument();
              await new Promise(
                (resolve) => (this.newTimeout = setTimeout(resolve, interval)),
              );
            }
            if (this.docDetails.length === 0) {
              this.isLoader = false;
              this.showSendModal = false;
              this.showErrorAlert = true;
              this.alertMsg = `common.docCreateFailMsg`;
            } else {
              await this.getMessageTemplates();
            }
          },
          error: ({ error }) => {
            clearTimeout(this.newTimeout);
            this.isLoader = false;
            this.showSendModal = false;
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.alertMsg = getErrorMessage(error);
          },
        });
      }
      this.docDetails.length !== 0 && (await this.getMessageTemplates());
    }
  }

  closeSendPackage() {
    clearTimeout(this.newTimeout);
    this.closeAlert();
    this.docDetails = [];
    this.templateDetails = [];
    this.showSendModal = false;
    this.isLoader = false;
  }

  isNumber(number) {
    return !isNaN(number);
  }

  handleSaveDraft(e) {}

  isZeroNullOrStringZero(value) {
    return (
      value === 0 || value === null || value === '0' || Number(value) === 0
    );
  }

  isSelectedQuoteOptionValid() {
    let selectedQuoteOption;
    this.store
      .pipe(select(getSelectedQuoteOption))
      .pipe(take(1))
      .subscribe((quoteOption) => (selectedQuoteOption = quoteOption));
    const invalidPremium = selectedQuoteOption['products']?.findIndex(
      (product) =>
        this.isZeroNullOrStringZero(product?.brokerCommissionPerc) ||
        this.isZeroNullOrStringZero(product?.premium),
    );
    if (invalidPremium == -1) {
      return true;
    }
    return false;
  }

  handleContinue(e) {
    if (!this.isSelectedQuoteOptionValid()) {
      this.alertMsg = 'quoteCalculator.error.invalidSelectedQuote';
      this.showErrorAlert = true;
      return;
    }
    this.store.dispatch(
      new CreateQuoteAction.SelectQuoteOption(this.selectedQuoteDetails),
    );
    this.router.navigate(['/dashboard/quote/summary/product'], {
      relativeTo: this.activatedRoute,
      skipLocationChange: true,
    });
  }

  handleSend(formData: any) {
    formData.append('merginStageId', this.stageId);
    this.messageSendService.sendMessage(formData).subscribe({
      next: (response) => {
        this.docDetails = [];
        this.templateDetails = [];
        this.showSendModal = !this.showSendModal;
      },
      error: (error) => {
        this.showSendModal = !this.showSendModal;
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.alertMsg = getErrorMessage(error);
      },
    });
  }

  async handleSendQuoteComparison() {
    this.docDetails = [];
    await this.getStageId();
    this.docPopupDetails.stageId = this.stageId;
    this.docPopupDetails.policyPeriodId = this.policyPeriodId;

    if (this.stageId) {
      this.DocumentTemplateService.getDocumentTemplateList(
        this.policyRiskId,
        this.stageId,
      ).subscribe({
        next: (response) => {
          if (response?.data.length !== 0) {
            this.docPopupDetails.documentTemplate = response?.data;
            this.handleDocLevelAction(this.currentQuoteStatus);
          } else {
            this.handleSendComparison();
          }
        },
        error: (error) => {
          this.alertErrMsg.push(getErrorMessage(error.error));
        },
      });
    } else {
      return;
    }
  }
  handleDocLevelAction(action) {
    this.docPopupDetails.action = action;
    this.showDocTempSelectionPopup = true;
  }
  handleSuccessDocTemplate(event) {
    this.showDocTempSelectionPopup = false;
    this.handleSendComparison();
  }
}
