import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Store, select } from '@ngrx/store';
import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';

import {
  appendTimeStamp,
  formatDate,
  getDateFormatForDatePicker,
} from 'src/app/utils/formatDate';
import {
  formatAmountWithCurrency,
  generateIncrementedValues,
  getAlertHead,
  getErrorMessage,
  removeDuplicatedKeys,
  removeSpecialSymbols,
  valInDigits,
} from 'src/app/utils/utils';
import { TranslateService } from '@ngx-translate/core';
import { ConfCurrencyService } from 'src/app/services/conf-currency.service';
import { getLifecycleState } from 'src/app/store/lifecycle/lifecycle.select';
import { PolicyRiskTrxService } from 'src/app/services/policy-risk-trx.service';
import { firstValueFrom, Observable, of, take } from 'rxjs';
import moment from 'moment';
import { addSpaceBeforeCapitalLetter } from 'src/app/dashboard/utils/lifecycle-utils';
import { BrokerageCommissionService } from 'src/app/services/brokerage-commissions.service';
import { BrokerageProducerCommissionService } from 'src/app/services/brokerage-producer-commission.service';
import { DomainsService } from 'src/app/services/domains.service';
import { ConfDeductibleService } from 'src/app/services/conf-deductible.service';
import { ConfTaxService } from 'src/app/services/conf-tax.service';
import { AlertService } from 'src/app/services/alert.service';
import {
  PolicyTransactionEndorsementRequestBody,
  PolicyTransactionInvoicesRequestBody,
} from 'src/app/entities/policy-lifecycle';
import { PolicyLifecycleService } from 'src/app/services/policy-lifecycle-service';
import { UpdatePolicyLifeCycle } from 'src/app/entities/policy';
import { getQuoteSelector } from 'src/app/store/create-quote/create-quote.selector';
import { PolicyPeriodNotesService } from 'src/app/services/policy-period-notes.service';
import { checkRegexPatternNotes } from 'src/app/utils/notesUtils';
import { ConfRiskLimitsService } from 'src/app/services/conf-risk-limits.service';
import {
  DOCMERGESTAGE_ENDORSEMENT,
  DOC_GENERATION_WAIT_TIME,
  GENERATE_DOC_ERROR_STATUS_CODE,
  GENERATE_DOC_SUCCESS_STATUS_CODE,
} from 'src/app/constants/quote-constant';
import { DocumentTemplateService } from 'src/app/services/doc-template.service';
import { PolicyRiskDocProcessService } from 'src/app/services/policy-risk-doc-process.service';
import { PolicyRiskDocService } from 'src/app/services/policy-risk-doc.service';

@Component({
  selector: 'app-plc-endorsement-section-v2',
  templateUrl: './plc-endorsement-section-v2.component.html',
  styleUrls: ['./plc-endorsement-section-v2.component.less'],
})
export class PlcEndorsementSectionV2Component implements OnInit {
  @Input() showEndorseForm: boolean = false;
  @Input() isEdit: boolean = false;
  @Input() product = 'CyberBoxx';
  @Input() policyRiskTrxId;
  @Input() transactionStatus = 'Endorsement';
  @Input() endorsementContainerStyle: { [klass: string]: any };
  @Input() trxTypesList: any[];
  @Input() details: any;
  @Input() policyEffectiveDate: string = '';
  @Input() policyExpiryDate: string = '';

  @Output() handleEndorseSuccess = new EventEmitter<any>();
  @Output() handleInvoiceSuccess = new EventEmitter<any>();
  @Output() handleSave = new EventEmitter<any>();
  @Output() handleClose = new EventEmitter<any>();
  isBrokerCommissionDisabled: boolean = false;
  EndorseForm: FormGroup;
  deductibleOptions = [];
  initialFormValue: [];
  permissionList: { [x: string]: boolean } = {};
  limitOptionsForProducts = {};
  brokerageCommissionMax = {};
  brokerageCommissionMin = {};
  brokerageCommissionDefault = {};
  brokerageCommissionText = {};
  currencyOptions = [];
  limitOptions;
  commissionOptions;
  showTabSpinner: boolean = true;
  isEffectiveDateValid: boolean = true;
  showCoverageLimitCustomField: boolean = false;
  showLimitCustomInput: boolean = false;
  showDeductibleCustomInput: boolean = false;
  showEditedPremiumCustomField: boolean = false;
  showInvalidLimitErr: boolean = false;
  showCommissionCustomInput: boolean = false;
  showInvalidDeductibleErr: boolean = false;
  showInvalidCommissionErr: boolean = false;
  hasEndorsed: boolean = false;
  errorMsgValid: boolean = false;
  maxLength: number = 500;
  remainingChars: number = this.maxLength;
  trxType = 'Endorsed';
  shortDateFormat: string = '';
  longDateFormat: string = '';
  currentScreen: string = '';
  limitExceedErrMsg = this.translateService.instant(
    'quoteCalculator.error.limitExceedErrMsg',
  );
  limitMinimumErrMsg = this.translateService.instant(
    'quoteCalculator.error.limitMinimumErrMsg',
  );
  deductibleExceedErrMsg = this.translateService.instant(
    'quoteCalculator.error.deductibleExceedErrMsg',
  );
  deductibleMinimumErrMsg = this.translateService.instant(
    'quoteCalculator.error.deductibleMinimumErrMsg',
  );
  brokerCommissionErrMsg = this.translateService.instant(
    'workFlow3.plc.endorsement.error.brokerCommissionErrMsg',
  );
  maximumAllowedCharErrorMsg = this.translateService.instant(
    'workFlow3.plc.endorsement.error.maximumAllowedCharErrorMsg',
  );

  invalidAggregatedLimitErrMsg = '';
  invalidEditedPremiumLimitErrMsg = '';
  showInvalidLimitErrMsg = '';
  showInvalidDeductibleErrMsg = '';
  showInvalidCommissionErrMsg = '';
  effectiveDateErrMsg = '';
  notesTextareaErrorMsg = '';
  brokerageProducerCommissionMax = 0;
  brokerageProducerCommissionMin = 0;
  brokerageCommissionMaxRaw = 0;
  brokerageCommissionMinRaw = 0;
  limitErr;
  policyRiskId;
  isFormDirty;
  status;
  currency;
  brokerCommissionPerc;
  currencyId;
  deductables;
  limit;
  regionId;
  riskId;
  brokerageId;
  producerId;
  minEffectiveDate;
  maxEffectiveDate;
  taxPercent;
  policyStartDate;
  policyEndDate;
  formSubmitted: boolean = true;
  scopeCategory = [];
  scopeType;
  policyPeriodId;
  trxTypeId;
  endorsementTemplateSelected: boolean = false;
  stageId;
  docPopupDetails;
  loadDocApiCount;
  newTimeout: NodeJS.Timeout;
  documentStatusData;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private translateService: TranslateService,
    private confCurrencyService: ConfCurrencyService,
    private policyRiskTrxService: PolicyRiskTrxService,
    private brokerageCommissionService: BrokerageCommissionService,
    private brokerageProducerCommissionService: BrokerageProducerCommissionService,
    private domainsService: DomainsService,
    private confDeductibleService: ConfDeductibleService,
    private confTaxService: ConfTaxService,
    private alertService: AlertService,
    private policyLifecycleService: PolicyLifecycleService,
    private policyPeriodNotesService: PolicyPeriodNotesService,
    private confRiskLimitsService: ConfRiskLimitsService,
    private documentTemplateService: DocumentTemplateService,
    private policyRiskDocProcessService: PolicyRiskDocProcessService,
    private policyRiskDocService: PolicyRiskDocService,
  ) {
    this.EndorseForm = this.fb.group({
      notes: [''],
      effectiveDate: ['', Validators.required],
      issuanceDate: [''],
      invoiceNumber: [''],
      currency: ['', Validators.required],
      limit: ['', Validators.required],
      aggregatedLimit: [
        '',
        Validators.required,
        this.isValid('aggregatedLimit'),
      ],
      deductible: ['', Validators.required],
      premium: ['', Validators.required, this.isValid('premium')],
      calcPremium: [''],
      taxes: [''],
      brokerCommission: [''],
      brokerCommissionPerc: [''],
      tag: [],
    });
  }
  async ngOnInit() {
    this.minEffectiveDate = moment(this.policyEffectiveDate, 'YYYY-MM-DD')
      .add(1, 'days')
      .toDate();
    this.maxEffectiveDate = moment(this.policyExpiryDate, 'YYYY-MM-DD')
      .subtract(1, 'days')
      .toDate();
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));

    this.store
      .select(getLifecycleState)
      .pipe(take(1))
      .subscribe((state) => {
        this.brokerageId = state?.brokerageId;
        this.producerId = state?.producerId;
        this.policyRiskId = state?.policyRiskId;
        this.regionId = state?.regionId;
        this.riskId = state?.riskId;
        this.policyPeriodId = state?.policyPeriodId;
      });
    this.store
      .select(getQuoteSelector)
      .pipe(take(1))
      .subscribe((quote) => {
        this.policyStartDate = quote?.startDate;
        this.policyEndDate = quote?.endDate;
      });
    this.populateCurrencies();
    await Promise.all([
      await this.populateDropdownOptions(),
      await this.populateFormDetails(),
      await this.fetchNotesCategoryList(),
      await this.getStageId(),
    ]);
    if (this.isEdit) {
      let note = this.EndorseForm.controls['notes'].value;
      if (!note) {
        await this.populateNotes();
      }
    }
    this.showTabSpinner = false;
  }

  async populateNotes() {
    this.showTabSpinner = true;
    this.policyPeriodNotesService
      .getByPolicyPeriodId(this.policyPeriodId)
      .subscribe((res) => {
        if (res.data.length > 0) {
          this.EndorseForm.controls['notes'].setValue(res.data[0].note);
          const event = { target: { value: res.data[0].note } };
          this.onKeyUptextareaValidation(event);
        }
      });
  }

  isEndorseFormValid() {
    if (
      !this.isEffectiveDateValid ||
      this.showInvalidCommissionErr ||
      this.showInvalidLimitErr ||
      this.showInvalidDeductibleErr ||
      this.errorMsgValid ||
      !this.EndorseForm.valid
    ) {
      this.formSubmitted = false;
    } else {
      this.formSubmitted = true;
    }
    return this.formSubmitted;
  }

  checkIfEffectiveDateValid(date) {
    if (!!!this.policyEffectiveDate || !!!this.policyExpiryDate) {
      return;
    }

    let formattedMinEffectiveDate = moment(this.policyEffectiveDate).toDate();
    let formattedMaxEffectiveDate = moment(this.policyExpiryDate).toDate();
    const formattedEffectiveDate = moment(date).toDate();
    if (
      formattedEffectiveDate <= formattedMinEffectiveDate ||
      formattedEffectiveDate >= formattedMaxEffectiveDate
    ) {
      this.isEffectiveDateValid = false;
      this.effectiveDateErrMsg = this.translateService.instant(
        'workFlow3.plc.endorsement.error.transactionEffectiveDate',
      );
    }
  }

  handleLimitChange(value) {
    let currentValue = this.limitOptions?.filter(
      (limitObj) => limitObj.id === value,
    )[0]?.value;

    if (currentValue == 'custom-amount') {
      this.showLimitCustomInput = true;
    } else {
      this.limit = currentValue;

      this.EndorseForm.get('limit').setValue(this.limit);
      this.showInvalidLimitErr = false;
      this.showInvalidLimitErrMsg = '';
    }
  }
  handleDeductableChange(value) {
    let currentValue = this.deductibleOptions?.filter(
      (limitObj) => limitObj.id === value,
    )[0]?.value;

    if (currentValue == 'custom-amount') {
      this.showDeductibleCustomInput = true;
    } else {
      this.deductables = currentValue;

      this.EndorseForm.get('deductible').setValue(this.deductables);
      this.showInvalidDeductibleErr = false;
      this.showInvalidDeductibleErrMsg = '';
    }
  }
  isNumber(number) {
    return !isNaN(number);
  }
  handleDeductableCustomValue(event) {
    let formattedAmount = 0;
    if (event?.target) {
      formattedAmount = event?.target?.value
        ? event.target.value?.replace(/[$,]/g, '')
        : 0;
    } else {
      formattedAmount = event?.replace(/[$,]/g, '');
    }
    this.deductables = formattedAmount;
    this.EndorseForm.get('deductible').setValue(this.deductables);
    this.deductibleOptions;
    let deductibleOptionsArr = this.deductibleOptions
      ?.filter(
        (deductible) =>
          this.isNumber(valInDigits(deductible.key)) &&
          deductible.key != 'Custom',
      )
      .map((deductible) => valInDigits(deductible.key));
    const minDeductible = Math.min(...deductibleOptionsArr);
    const maxDeductible = Math.max(...deductibleOptionsArr);
    if (valInDigits(formattedAmount) > maxDeductible) {
      this.showInvalidDeductibleErr = true;
      this.showInvalidDeductibleErrMsg = this.deductibleExceedErrMsg
        .replace('[productName]', this.product)
        .replace(
          '[maximumDeductible]',
          formatAmountWithCurrency(maxDeductible, this.currency)?.toString(),
        );
      this.showDeductibleCustomInput = false;
    } else if (valInDigits(formattedAmount) < minDeductible) {
      this.showInvalidDeductibleErr = true;
      this.showInvalidDeductibleErrMsg = this.deductibleMinimumErrMsg
        .replace('[productName]', this.product)
        .replace(
          '[minimumDeductible]',
          formatAmountWithCurrency(minDeductible, this.currency)?.toString(),
        );
      this.showDeductibleCustomInput = false;
    } else {
      this.showInvalidDeductibleErr = false;
      this.showInvalidDeductibleErrMsg = '';
      this.showDeductibleCustomInput = true;
    }
  }

  handleAggregatedLimit(event) {
    this.EndorseForm.get('aggregatedLimit').setValue(event.target.value);
  }
  handleLimitCustomValue(event) {
    let formattedLimit = 0;

    if (event?.target) {
      formattedLimit = event?.target?.value
        ? event.target.value?.replace(/[$,]/g, '')
        : 0;
    } else {
      formattedLimit = event?.replace(/[$,]/g, '');
    }

    this.limit = formattedLimit;
    this.EndorseForm.get('limit').setValue(this.limit);
    const limitOptionsArr = this.limitOptions
      ?.filter(
        (limit) =>
          this.isNumber(valInDigits(limit.key)) && limit.key != 'Custom',
      )
      .map((limit) => valInDigits(limit.key));
    const minLimit = Math.min(...limitOptionsArr);
    const maxLimit = Math.max(...limitOptionsArr);
    if (valInDigits(formattedLimit) > maxLimit) {
      this.showInvalidLimitErr = true;
      this.showInvalidLimitErrMsg = this.limitExceedErrMsg
        .replace('[productName]', this.product)
        .replace(
          '[maximumLimit]',
          formatAmountWithCurrency(maxLimit, this.currency)?.toString(),
        );
      this.showLimitCustomInput = false;
    } else if (valInDigits(formattedLimit) < minLimit) {
      this.showInvalidLimitErr = true;
      this.showInvalidLimitErrMsg = this.limitMinimumErrMsg
        .replace('[productName]', this.product)
        .replace(
          '[minimumLimit]',
          formatAmountWithCurrency(minLimit, this.currency)?.toString(),
        );
      this.showLimitCustomInput = false;
    } else {
      this.showInvalidLimitErr = false;
      this.showInvalidLimitErrMsg = '';
      this.showLimitCustomInput = true;
    }
  }
  showLimitCustomField() {
    return this.showLimitCustomInput;
  }
  showDeductibleCustomField() {
    return this.showDeductibleCustomInput;
  }
  showCommissionCustomField() {
    return this.showCommissionCustomInput;
  }

  getDeductibleValue() {
    return this.deductables;
  }
  getLimitValue() {
    return this.limit;
  }
  isLimitDisabledOnAddSubProduct(riskId) {
    return false;
  }
  private populateCurrencies() {
    this.confCurrencyService.GetAllCurrencies().subscribe({
      next: (response) => {
        this.currencyOptions = response?.data?.map((currencyObj) => ({
          id: currencyObj.id,
          key: currencyObj.currencyCode,
          value: currencyObj.name,
        }));
      },
      error: (error) => {},
    });
  }
  populateFormDetails() {
    return new Promise<any>(async (resolve, reject) => {
      if (!this.policyRiskTrxId) {
        this.showTabSpinner = false;
        return;
      }
      this.policyRiskTrxService
        .getByPolicyRiskTrxId(this.policyRiskTrxId)
        .subscribe({
          next: (response) => {
            const data = response.data;

            if (!data?.brokerCommissionPerc) {
              this.brokerCommissionPerc = Number(0.0);
            }
            const status = data?.quoteStatus
              ? data.quoteStatus
              : this.transactionStatus;
            this.EndorseForm.controls['effectiveDate'].setValue(
              data?.transactionEffectiveDate !== null
                ? getDateFormatForDatePicker(
                    data?.transactionEffectiveDate,
                    this.shortDateFormat,
                  )
                : '',
            );

            this.EndorseForm.controls['issuanceDate'].setValue(
              data?.issuanceDate !== null
                ? this.formatDate(data?.issuanceDate)
                : 'NA',
            );

            this.EndorseForm.controls['invoiceNumber'].setValue(
              data?.invoiceNumber ?? '',
            );

            if (
              this.setPolicyStatusType(data.type).toLowerCase() ===
                'endorsement' &&
              !data.invoiceNumber
            ) {
              this.hasEndorsed = true;
            }

            this.EndorseForm.controls['currency'].setValue(
              data?.currency?.currencyCode,
            );
            this.EndorseForm.controls['limit'].setValue(data?.limit);
            this.EndorseForm.controls['aggregatedLimit'].setValue(
              data?.aggregatedLimit,
            );
            this.EndorseForm.controls['deductible'].setValue(data?.deductible);
            this.EndorseForm.controls['premium'].setValue(data?.premium);
            this.EndorseForm.controls['calcPremium'].setValue(
              data?.premiumCalculated,
            );
            this.EndorseForm.controls['taxes'].setValue(
              data?.taxesPremiumAmount,
            );
            this.deductables = data?.deductible;
            this.limit = data?.limit;

            if (data?.brokerCommissionPerc) {
              const brokerCommissionPercs = (
                parseFloat(data?.brokerCommissionPerc) * 100
              ).toFixed(2);
              this.EndorseForm.controls['brokerCommissionPerc'].setValue(
                Number(brokerCommissionPercs),
              );

              this.brokerCommissionPerc = Number(brokerCommissionPercs);
            }
            this.trxTypeId = data.type;
            this.EndorseForm.controls['tag'].setValue(this.transactionStatus);
            this.trxType =
              this.setPolicyStatusType(data.type).toLowerCase() !==
              'endorsement'
                ? 'Endorse'
                : this.trxType;
            this.EndorseForm.controls['notes'].setValue(
              data?.notes !== null ? data?.notes : '',
            );

            this.status = addSpaceBeforeCapitalLetter(status);
            this.policyRiskId = data?.policyRisk?.id;

            this.currencyId = this.currencyOptions
              ?.filter(
                (currencyOption) =>
                  currencyOption?.key.includes(data?.currency?.currencyCode),
              )
              .map((currencyOption) => currencyOption?.id)
              .join(',');

            this.initialFormValue = this.EndorseForm.value;
            resolve('true');
          },
          error: (error) => {
            this.showTabSpinner = false;
            reject(error);
          },
        });
    });
  }
  formatDate(date) {
    if (date && date.split(' ').length > 1) {
      return moment(date, 'DD-MM-YYYY HH:mm:ss').format(this.shortDateFormat);
    }
    return moment(date).toDate();
  }

  async populateDropdownOptions() {
    return new Promise<any>(async (resolve) => {
      await this.populateLimitOptions(this.regionId);
      await this.populateDeductibleOptions();
      await this.populateBrokerageCommission(
        this.riskId,
        this.brokerageId,
        this.producerId,
      );
      resolve('true');
    });
  }

  async populateBrokerageCommission(riskId, brokerageId, producerId) {
    return new Promise<any>(async (resolve, reject) => {
      this.brokerageProducerCommissionService
        .GetByRegionIdAndRiskIdsAndProducerId(this.regionId, riskId, producerId)
        .subscribe({
          next: async (data) => {
            let producerCommissionNotExistsFor = [];
            // GET COMMISSIONS FROM PRODUCER
            if (data.data?.length > 0) {
              const brokerForRiskId = data.data.filter(
                (broker) =>
                  broker.active && broker?.riskRegion.risk.id === riskId,
              );
              if (brokerForRiskId.length > 0) {
                this.brokerageCommissionMax = Number(
                  (Number(brokerForRiskId[0].commissionMax) * 100).toFixed(2),
                );
                this.brokerageCommissionMin = Number(
                  (Number(brokerForRiskId[0].commissionMin) * 100).toFixed(2),
                );
                this.brokerageCommissionText = `${this.brokerageCommissionMin}% - ${this.brokerageCommissionMax}%`;
                this.populateCommissionsDropdown(
                  this.brokerageCommissionMin,
                  this.brokerageCommissionMax,
                  riskId,
                  this.brokerageCommissionText,
                );
              } else {
                producerCommissionNotExistsFor.push(riskId);
              }
            } else {
              producerCommissionNotExistsFor.push(riskId);
            }
            if (producerCommissionNotExistsFor.length > 0) {
              let riskIdWithoutCommission = [];
              await this.brokerageCommissionService
                .GetByRegionIdAndRiskIdsAndBrokerageId(
                  this.regionId,
                  riskId,
                  brokerageId,
                )
                .subscribe((data) => {
                  let riskIdsToAddCommission = [];
                  if (data.data.length === 0) {
                    riskIdsToAddCommission = riskId;
                    riskIdWithoutCommission = riskIdsToAddCommission;
                  } else {
                    riskIdsToAddCommission = producerCommissionNotExistsFor;
                  }
                  // GET COMMISSIONS FROM BROKERAGE
                  if (data.data?.length > 0) {
                    riskIdsToAddCommission.forEach((riskId) => {
                      const commissionForRiskId = data.data.filter(
                        (broker) =>
                          broker.active &&
                          broker?.riskRegion.risk.id === riskId,
                      );
                      if (commissionForRiskId.length > 0) {
                        this.brokerageCommissionMax = Number(
                          (Number(data.data[0].commissionMax) * 100).toFixed(2),
                        );
                        this.brokerageCommissionMin = Number(
                          (Number(data.data[0].commissionMin) * 100).toFixed(2),
                        );
                        this.brokerageCommissionText = `${this.brokerageCommissionMin}% - ${this.brokerageCommissionMax}%`;
                        this.populateCommissionsDropdown(
                          this.brokerageCommissionMin,
                          this.brokerageCommissionMax,
                          riskId,
                          this.brokerageCommissionText,
                        );
                      } else {
                        riskIdWithoutCommission.push(riskId);
                      }
                    });
                  }
                  if (riskIdWithoutCommission?.length > 0) {
                    this.isBrokerCommissionDisabled = true;
                  }
                });
            }
            resolve('true');
          },
          error: (error) => {
            reject(getErrorMessage(error));
          },
        });
    });
  }
  getBrokerageCommissionPerc() {
    const num = Number(this.EndorseForm.controls['brokerCommissionPerc'].value);
    return num;
  }
  populateCommissionsDropdown(
    brokerCommissionMin,
    brokerCommissionMax,
    riskId,
    brokerageCommissionText,
  ) {
    const validCommissions = generateIncrementedValues(
      brokerCommissionMin,
      brokerCommissionMax,
      0.5,
    );
    let options: Array<{
      key: number | string;
      value: number | string;
      id: number;
      subKey?: string;
    }> = validCommissions.map((commission, index) => ({
      key: `${commission}%`,
      value: `${commission}%`,
      id: index + 1,
    }));
    options.push({
      key: 'Custom',
      value: 'Custom',
      id: null,
    });
    this.commissionOptions = options;
  }

  private populateLimitOptions(regionId) {
    this.confRiskLimitsService
      .GetByRegionIdAndRiskIds(this.regionId, this.riskId)
      .subscribe({
        next: (response) => {
          const limitOptions = response.data.map((dataObj, index) => ({
            key: formatAmountWithCurrency(dataObj.limit, this.currency),
            value: valInDigits(dataObj.limit),
            id: index + 1,
          }));
          limitOptions.push({
            key: 'Custom',
            value: 'custom-amount',
            id: response.data?.length + 1,
          });
          this.limitOptions = limitOptions;
        },
        error: (error) => {
          const limitOptions = [{ key: 'Custom', value: 'custom-amount' }];
          this.limitOptions = limitOptions;
        },
      });
  }

  populateDeductibleOptions() {
    this.confDeductibleService
      .GetByRegionIdAndRiskIds(this.regionId, this.riskId)
      .subscribe((data) => {
        const tableData = data.data?.map((dataObj, index) => ({
          key: formatAmountWithCurrency(dataObj.value, this.currency),
          value: valInDigits(dataObj.value),
          id: index + 1,
        }));

        tableData.push({
          id: data.data?.length + 1,
          key: 'Custom',
          value: 'custom-amount',
        });
        this.deductibleOptions = removeDuplicatedKeys(tableData);
      });
  }

  onKeyUptextareaValidation(event) {
    this.errorMsgValid = false;
    //update Remaining Chars length
    this.remainingChars = this.maxLength - event.target.value.length;
    let noteTrimLength = event.target.value.trim().length;
    if (this.maxLength < event.target.value.length) {
      this.errorMsgValid = true;
      this.notesTextareaErrorMsg = this.maximumAllowedCharErrorMsg.replace(
        '[charLimit]',
        this.maxLength,
      );
    } else if (noteTrimLength <= 2 && event.target.value.length !== 0) {
      this.errorMsgValid = true;
      this.notesTextareaErrorMsg = this.translateService.instant(
        'workFlow3.plc.endorsement.error.minThreeLengthErrMsg',
      );
    }
  }

  isFieldEditable() {
    return (
      this.permissionList[this.currentScreen] && this.status !== 'Cancellation'
    );
  }

  handleBrokerCommissionPerc(id) {
    if (!id) {
      this.showCommissionCustomInput = true;
    } else {
      const value = this.commissionOptions
        ?.filter((commission) => commission.id === id)[0]
        ?.value?.replace('%', '');
      this.brokerCommissionPerc = value;
      this.EndorseForm.get('brokerCommissionPerc').setValue(
        this.brokerCommissionPerc,
      );
      this.handleBrokerCommissionValidate(value);
    }
  }

  handleCommissionCustomChange(event: any) {
    this.handleBrokerCommissionValidate(event?.target?.value);
    this.showCommissionCustomInput = false;
  }
  handleBrokerCommissionValidate(event: any) {
    const value = event?.target?.value ? event.target.value : event;
    const brokerCommsionValue = Number(value?.replace('%', ''))
      ? Number(value?.replace('%', ''))
      : 0;
    if (
      brokerCommsionValue >= Number(this.brokerageCommissionMin) &&
      brokerCommsionValue <= Number(this.brokerageCommissionMax)
    ) {
      this.brokerCommissionPerc = brokerCommsionValue;
      this.EndorseForm.get('brokerCommissionPerc').setValue(
        this.brokerCommissionPerc,
      );
      this.showInvalidCommissionErr = false;
      this.showInvalidCommissionErrMsg = '';
    } else {
      this.showInvalidCommissionErr = true;
      this.showInvalidCommissionErrMsg = this.brokerCommissionErrMsg.replace(
        '[range]',
        this.brokerageCommissionText,
      );
    }
    this.brokerCommissionPerc = brokerCommsionValue;
    this.EndorseForm.get('brokerCommissionPerc').setValue(
      this.brokerCommissionPerc,
    );
  }

  closeEndorseForm() {
    this.showEndorseForm = false;
    this.clearForm();
    this.handleClose.emit(true);
  }
  clearForm() {
    this.EndorseForm.reset();
  }
  handleTransactionEffDate(date) {
    this.isEffectiveDateValid = true;
    this.effectiveDateErrMsg = '';
    if (date) {
      const formattedEffectiveDate = formatDate(
        new Date(date),
        this.shortDateFormat,
      );

      this.EndorseForm.controls['effectiveDate'].setValue(
        formattedEffectiveDate,
      );

      this.checkIfEffectiveDateValid(date);
    } else {
      this.EndorseForm.controls['effectiveDate'].setValue('');
      this.isEffectiveDateValid = false;
      this.effectiveDateErrMsg = this.translateService.instant(
        'workFlow3.plc.endorsement.error.requiredErrMsg',
      );
    }
  }
  handleChangePremium(event) {
    let newTaxInvoiceamount = 0;
    let updatedPremium;
    // get tax details based on region Id
    this.confTaxService
      .getTaxByRegionId(this.regionId)
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          this.taxPercent = data?.data[0]?.percentage;
        },
      });
    updatedPremium = this.EndorseForm?.value?.premium;
    if (this.taxPercent) {
      newTaxInvoiceamount = updatedPremium * this.taxPercent;
      this.EndorseForm.controls['taxes'].setValue(
        Number(newTaxInvoiceamount).toFixed(2),
      );
    }
  }

  handleEndorseTransaction() {
    if (!this.isEndorseFormValid()) {
      return;
    }
    let note = this.EndorseForm.controls['notes'].value
      ? this.EndorseForm.controls['notes'].value.trim()
      : '';
    let data: PolicyTransactionEndorsementRequestBody = {
      policyRiskTrxId: this.policyRiskTrxId,
      limit: +this.EndorseForm.controls['limit'].value,
      aggregatedLimit: +this.EndorseForm.controls['aggregatedLimit'].value,
      deductible: +this.EndorseForm.controls['deductible'].value,
      premium: +this.EndorseForm.controls['premium'].value,
      transactionEffectiveDate: appendTimeStamp(
        this.EndorseForm.controls['effectiveDate'].value,
        this.shortDateFormat,
      ),
      note: note,
      brokerCommissionPerc:
        this.EndorseForm.value['brokerCommissionPerc'] / 100,
    };

    this.policyLifecycleService.TransactionRiskEndorse(data).subscribe({
      next: (response) => {
        this.handleEndorseSuccess.emit(true);
        this.closeEndorseForm();
        const alertData = {
          show: true,
          type: 'success',
          headerText: 'success!',
          bodyText: this.translateService.instant(
            'workFlow3.plc.endorsement.success.endorsementSaveSuccess',
          ),
        };
        this.alertService.addAlert(alertData);
      },
      error: (error) => {
        this.handleEndorseSuccess.emit(false);
        this.closeEndorseForm();
        //const message = handleApiError(error);
        if (![500].includes(error?.status)) {
          const alertData = {
            show: true,
            type: 'error',
            headerText: 'error!',
            bodyText: getErrorMessage(error),
          };
          this.alertService.addAlert(alertData);
        }
      },
    });
  }

  async handleInvoiceTransaction() {
    if (!this.policyRiskTrxId) {
      return;
    }
    this.showTabSpinner = true;
    let invoiceData: PolicyTransactionInvoicesRequestBody = {
      policyRiskTrxId: this.policyRiskTrxId,
    };
    let note = this.EndorseForm.controls['notes'].value
      ? this.EndorseForm.controls['notes'].value.trim()
      : '';
    return new Promise<any>(async (resolve, reject) => {
      this.policyLifecycleService
        .TransactionRiskInvoice(invoiceData)
        .subscribe({
          next: async (response) => {
            const alertData = {
              show: true,
              type: 'success',
              headerText: 'success!',
              bodyText: this.translateService.instant(
                'workFlow3.plc.endorsement.success.endorsementInvoicedSuccess',
              ),
            };
            this.alertService.addAlert(alertData);
            await this.generateEndorsementDocuments();
            this.closeEndorseForm();
            this.showTabSpinner = false;
          },
          error: (error) => {
            this.handleInvoiceSuccess.emit(false);
            this.closeEndorseForm();
            if (![500].includes(error?.status)) {
              const alertData = {
                show: true,
                type: 'error',
                headerText: 'error!',
                bodyText: getErrorMessage(error),
              };
              this.alertService.addAlert(alertData);
              this.showTabSpinner = false;
            }
            resolve(true);
          },
        });
      if (note) {
        await this.createNoteAPI();
        resolve(true);
      } else {
        resolve(true);
      }
    });
  }

  handleDiscard() {
    if (this.isCheckFormchange(this.initialFormValue)) {
      this.EndorseForm.reset(this.initialFormValue);
    }
    this.isEffectiveDateValid = true;
    this.showInvalidCommissionErr = false;
    this.showInvalidLimitErr = false;
    this.showInvalidDeductibleErr = false;
    this.errorMsgValid = true;
    this.showInvalidLimitErrMsg = '';
    this.effectiveDateErrMsg = '';
    this.notesTextareaErrorMsg = '';
    this.showInvalidDeductibleErrMsg = '';
    this.showInvalidCommissionErrMsg = '';
    this.invalidAggregatedLimitErrMsg = '';
    this.invalidEditedPremiumLimitErrMsg = '';
  }

  isCheckFormchange(initialValues): boolean {
    let formchange;
    if (
      JSON.stringify(this.EndorseForm.value) === JSON.stringify(initialValues)
    ) {
      formchange = false;
    } else {
      formchange = true;
    }
    return formchange;
  }

  async handleSaveTransaction(skipReload = false) {
    if (!this.isEndorseFormValid()) {
      return;
    }

    let note = this.EndorseForm.controls['notes'].value
      ? this.EndorseForm.controls['notes'].value.trim()
      : '';
    const UpdatePolicyLifeCycleRequest: UpdatePolicyLifeCycle = {
      policyRiskTrxId: this.policyRiskTrxId,
      limit: +this.EndorseForm.controls['limit'].value,
      aggregatedLimit: +this.EndorseForm.controls['aggregatedLimit'].value,
      deductible: +this.EndorseForm.controls['deductible'].value,
      premium: +this.EndorseForm.controls['premium'].value,
      transactionEffectiveDate: appendTimeStamp(
        this.EndorseForm.controls['effectiveDate'].value,
        this.shortDateFormat,
      ),
      brokerCommissionPerc:
        this.EndorseForm.controls['brokerCommissionPerc'].value / 100,
    };
    try {
      const saveEndorsementAPI =
        this.policyRiskTrxService.updateByPolicyRiskTrxId(
          this.policyRiskTrxId,
          UpdatePolicyLifeCycleRequest,
        );
      const response = await firstValueFrom(saveEndorsementAPI);
      if (response) {
        if (!!!skipReload) {
          this.handleSave.emit(true);
          const alertData = {
            show: true,
            type: 'success',
            headerText: 'success!',
            bodyText:
              'workFlow3.plc.endorsement.success.endorsementUpdateSuccess',
          };
          this.alertService.addAlert(alertData);
        }
      }
    } catch (error) {
      if (![500].includes(error?.status)) {
        const alertData = {
          show: true,
          type: 'error',
          headerText: 'error!',
          bodyText: getErrorMessage(error),
        };
        this.alertService.addAlert(alertData);
      }
    }

    if (note) {
      await this.createNoteAPI();
    }
  }

  async createNoteAPI() {
    const createReq = {
      policyPeriodId: this.policyPeriodId,
      trxType: this.trxTypeId,
      scopeType: this.scopeType,
      note: checkRegexPatternNotes(this.EndorseForm.controls['notes'].value),
    };
    const notesCreateObs = this.policyPeriodNotesService.Create(createReq);
    const policyNoteCreateData = await firstValueFrom(notesCreateObs);
  }
  setPolicyStatusType(type) {
    const filteredArray = this.trxTypesList.filter((item) => item.id === type);
    const valuesOfRenewal = filteredArray.map((item) => item.description);
    return valuesOfRenewal?.[0] ?? '';
  }

  getScopeCategory(
    key: string,
    compareKey: string,
    list: { [x: string]: any }[],
  ): { [x: string]: any } {
    let category: { [x: string]: any } = {};
    list.forEach((item) => {
      if (item[compareKey] === key) {
        category = item;
      }
    });
    return category;
  }
  async fetchNotesCategoryList() {
    await this.domainsService.GetByDomainCode('NOTESSCOPETYPE').subscribe({
      next: (response) => {
        if (response.data.length > 0) {
          const scopeCategory = response.data.map((item) => {
            return {
              value: item.description,
              key: item.description,
              id: item.id,
            };
          });
          this.scopeCategory = [...this.scopeCategory, ...scopeCategory];

          let category = this.getScopeCategory(
            'Transaction changes and extra information',
            'key',
            scopeCategory,
          );
          this.scopeType = category['id'];
        }
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          const errAlert = {
            show: true,
            type: 'error',
            headerText: 'common.errorHeader',
            bodyText: getErrorMessage(error),
          };
          this.alertService.addAlert(errAlert);
        }
      },
    });
  }

  handleLengthValidation(
    event: Event,
    controller: string,
    maxLength: number = 14,
  ): void {
    let targetValue = event?.target?.['value'] ?? '0';
    let filteredValue = removeSpecialSymbols(targetValue);
    if (filteredValue.length > maxLength) {
      let limitErrMsg = this.maximumAllowedCharErrorMsg.replace(
        '[charLimit]',
        maxLength,
      );
      if (controller === 'aggregatedLimit') {
        this.invalidAggregatedLimitErrMsg = limitErrMsg;
      }
      if (controller === 'premium') {
        this.invalidEditedPremiumLimitErrMsg = limitErrMsg;
      }
    } else {
      if (controller === 'aggregatedLimit') {
        this.invalidAggregatedLimitErrMsg = '';
      }
      if (controller === 'premium') {
        this.invalidEditedPremiumLimitErrMsg = '';
      }
    }
  }

  isValid(controller: string): AsyncValidatorFn {
    return (): Observable<ValidationErrors | null> => {
      if (controller === 'aggregatedLimit') {
        return of(
          this.invalidAggregatedLimitErrMsg !== ''
            ? { customError: true }
            : null,
        );
      }
      if (controller === 'premium') {
        return of(
          this.invalidEditedPremiumLimitErrMsg !== ''
            ? { customError: true }
            : null,
        );
      }
      return of(null);
    };
  }

  toggleEndorsementTemplateSelection(value: boolean) {
    this.endorsementTemplateSelected = value;
  }

  async handleEndorsementInvoice() {
    await this.handleSaveTransaction(true);
    if (this.endorsementTemplateSelected) {
      if (this.stageId) {
        this.docPopupDetails = this.details;
        this.docPopupDetails.policyRiskId = this.policyRiskId;
        this.docPopupDetails.policyRiskTrxId = this.policyRiskTrxId;
        this.documentTemplateService
          .getDocumentTemplateList(
            this.policyRiskId,
            this.stageId,
            this.policyRiskTrxId,
          )
          .subscribe({
            next: async (response) => {
              let templateList = response?.data.filter((item) => {
                return item.templates.map(
                  (template) => template.docProcessId === null,
                );
              });
              if (templateList.length === 1) {
                // TO DO - handle multiple templates
                const midTermEndorsementTemplate = templateList[0].templates[0];

                const docTempOptionsData: any = {
                  riskTemplateId: midTermEndorsementTemplate.id,
                  policyRiskId: this.policyRiskId,
                  documentName: midTermEndorsementTemplate.templateDescription,
                  documentUniqueName:
                    midTermEndorsementTemplate.templateDescription,
                  dataInjection: midTermEndorsementTemplate.dataInjection,
                  templateType: templateList[0].templateType.id,
                  policyRiskTrxId: Number(this.details.policyRiskTrxId),
                };

                const payloadForPolicyRiskDocCreate = {
                  policyRiskDocs: [docTempOptionsData],
                };

                try {
                  await this.policyRiskDocProcessService
                    .createPolicyRiskDoc(payloadForPolicyRiskDocCreate)
                    .toPromise();
                  await Promise.all[await this.handleInvoiceTransaction()];
                } catch (error) {
                  this.showDocumentGenerateErrorMsg();
                }
              } else {
                // TO DO - handle multiple mid term endorsements
              }
            },
            error: (error) => {},
          });
      } else {
        return;
      }
    } else {
      await Promise.all[await this.handleInvoiceTransaction()];
    }
  }

  async generateEndorsementDocuments() {
    this.loadDocApiCount = 0;
    if (this.policyPeriodId && this.stageId && this.policyRiskTrxId) {
      const interval = 5000;
      const startTime = Date.now();
      return new Promise<any>(async (resolve, reject) => {
        this.documentTemplateService
          .generateStageDocument(
            this.policyPeriodId,
            this.stageId,
            this.policyRiskTrxId,
          )
          .subscribe({
            next: async (resp) => {
              while (
                Date.now() - startTime < DOC_GENERATION_WAIT_TIME &&
                this.loadDocApiCount < 5
              ) {
                await this.checkDocumentStatus();
                await new Promise(
                  (resolve) =>
                    (this.newTimeout = setTimeout(resolve, interval)),
                );
              }
              if (this.loadDocApiCount === 5) {
                this.handleInvoiceSuccess.emit(true);
                resolve(true);
              }
            },
            error: (error) => {
              this.handleInvoiceSuccess.emit(true);
              clearTimeout(this.newTimeout);
              this.showDocumentGenerateErrorMsg(getErrorMessage(error));
              reject(getErrorMessage(error));
              this.showTabSpinner = false;
            },
          });
      });
    } else {
      this.showDocumentGenerateErrorMsg();
    }
  }

  checkDocumentStatus() {
    return new Promise<void>((resolve, reject) => {
      if (this.policyPeriodId && this.stageId) {
        this.policyRiskDocService
          .getPolicyRiskGeneratedDocCore(
            this.policyPeriodId,
            this.stageId,
            this.policyRiskTrxId,
          )
          .subscribe({
            next: async (response) => {
              if (response?.data?.status === GENERATE_DOC_SUCCESS_STATUS_CODE) {
                this.loadDocApiCount = 5;
                const alertData = {
                  show: true,
                  type: 'success',
                  headerText: 'success!',
                  bodyText: this.translateService.instant(
                    'workFlow3.plc.endorsement.success.endorsementDocumentsGeneratedSuccess',
                  ),
                };
                this.alertService.addAlert(alertData);
                this.handleInvoiceSuccess.emit(true);
                resolve();
              } else if (
                response?.data?.status === GENERATE_DOC_ERROR_STATUS_CODE
              ) {
                this.loadDocApiCount = 5;
                this.handleInvoiceSuccess.emit(true);
                this.showDocumentGenerateErrorMsg();
                this.showTabSpinner = false;
              } else {
                this.loadDocApiCount++;
                resolve();
              }
              this.documentStatusData = response;
            },
            error: (error) => {
              this.loadDocApiCount = 5;
              clearTimeout(this.newTimeout);
              this.showDocumentGenerateErrorMsg(getErrorMessage(error));
              reject(getErrorMessage(error));
              return;
            },
          });
      } else {
        this.showDocumentGenerateErrorMsg();
        reject('error');
      }
    });
  }

  showDocumentGenerateErrorMsg(
    msg: string = 'policy.document.error.endorsementDocumentGenerationError',
  ) {
    let notificationAlert = {
      type: 'error',
      headerText: getAlertHead('error'),
      bodyText: this.translateService.instant(msg),
    };
    this.alertService.addAlert(notificationAlert);
  }

  async getStageId(subDomain = DOCMERGESTAGE_ENDORSEMENT) {
    this.stageId = undefined;
    return new Promise<void>((resolve) => {
      this.domainsService.GetByDomainCode('DOCMERGESTAGE', true).subscribe({
        next: (response) => {
          let docStage = response.data.filter(
            (template) => template.subdomaincode === subDomain,
          )[0];
          this.stageId = docStage.id;
          resolve();
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
          }
        },
      });
    });
  }
}
