import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ElementRef,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Breadcrumb } from 'src/app/models/breadcrumb.model';
import {
  ImageCroppedEvent,
  ImageTransform,
  CropperSettings,
} from 'ngx-image-cropper';
import { Store, select } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { BrokerageBranchService } from 'src/app/services/brokerage-branch.service';
import { BrokerageProducerCommissionService } from 'src/app/services/brokerage-producer-commission.service';
import { BrokerageService } from 'src/app/services/brokerage.service';
import { forkJoin, Subject, take, Subscription } from 'rxjs';
import { BrokerageProducerService } from 'src/app/services/brokerage-producer.service';
import { DatePipe, ViewportScroller } from '@angular/common';
import {
  BrokerageProducer,
  BrokerageProducerProfile,
} from 'src/app/entities/brokerage-producer';
import { BrokerageProfileService } from 'src/app/services/brokerage-profile.service';
import { ProducerModel } from 'src/app/models/producer.model';
import { getFormGroup, mapFormGroupToModel } from './edit-producer.factory';
import { dateTimeWithUserName } from 'src/app/utils/formatDate';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { getProducerCreatedSelector } from 'src/app/store/brokerage/brokerage.select';
import { resetProducerCreated } from 'src/app/store/brokerage/brokerage.action';
import { Commission } from 'src/app/entities/commission';
import { Pagination } from 'src/app/entities/boxx-response';
import { SystemUserService } from 'src/app/services/system-user.service';
import { TranslateService } from '@ngx-translate/core';
import { getErrorMessage } from 'src/app/utils/utils';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-producer',
  templateUrl: './edit-producer.component.html',
  styleUrls: ['./edit-producer.component.less'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class EditProducerComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  @Output() updateStatusEvent = new EventEmitter<boolean>();
  cropperSettings: CropperSettings;
  allowedFormats = ['.jpg', '.jpeg', '.png'];
  maxFileSizeMB = 2; // Maximum file size in MB
  previousImage: string;

  form: FormGroup;
  submitted: boolean = false;
  requiredErrMsg: string = 'This field is required';
  invalidErrMsg: string = 'This field is invalid';
  customCommission: boolean = false;
  userID: number;
  systemUserId: number;
  branchId: number;
  brokerageId: number;
  producerId: number;
  producerProfileId: number;
  producerTitle: string;
  producerSelected: BrokerageProducer;
  itemsMenu: Breadcrumb[] = [
    { label: 'Brokerage directory', path: '/dashboard/brokerage' },
  ];
  brokerageProducer: BrokerageProducer;
  producerProfileOldSelected: BrokerageProducerProfile;
  brokerageProducerOldSelected: BrokerageProducer;
  checked = false;
  url: string | ArrayBuffer = 'assets/img/default-photo.png';
  croppedImage: any = 'assets/img/default-photo.png';

  operationProducerSuccess = false;
  operationProducerHasError = false;
  operationProducerErrorMessage = '';

  operationProducerProfileSuccess = false;
  operationProducerProfileHasError = false;
  operationProducerProfileErrorMessage = '';

  isPrfileImgUploadError = false;
  prfileImgUpdateErrMsg = '';

  createdBy: string | null;
  updatedBy: string | null;
  showErrorAlert = false;
  msgErrorAlert = '';
  notificationAlert: {
    show?: boolean;
    bodyText?: string;
    type?: string;
    headerText?: string;
  } = { show: false };
  spinnerCounter: number = 0;
  showSpinner: Boolean = false;
  brokerageProducerCommissions: {
    data: Commission[];
    pagination: Pagination | null;
  } = { data: [], pagination: null };
  resetAddCommissionFormSubject: Subject<boolean> = new Subject<boolean>();
  isNavigatedFromAddProducer = false;
  private userId: number;
  showBodySpinner: boolean = false;
  initialFormValue: any;
  isFormDirty: boolean = false;
  isSuccess = false;
  processingActivation = false;
  toggleActive = false;
  private formSubscription: Subscription;
  btnStyle: { [key: string]: any } = { padding: '0 20px' };
  message: string;
  hasError: boolean;
  errorMessage: any;
  successMessage: string;
  branchDataStatus: true;
  alertList = [];
  formUpdated: boolean = false;
  showConfirmationModal: boolean = false;
  commissionConfirmationData: any;
  longDateFormat: string = '';
  shortDateFormat: string = '';
  shortDateTimeFormat: string = '';
  longDateTimeFormat: string = '';

  constructor(
    private viewPortScroller: ViewportScroller,
    private brokerageProducerService: BrokerageProducerService,
    private brokerageService: BrokerageService,
    private brokerageBranchService: BrokerageBranchService,
    private brokerageProducerCommissionService: BrokerageProducerCommissionService,
    private brokerageProfileService: BrokerageProfileService,
    private fb: FormBuilder,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private systemUser: SystemUserService,
    private datePipe: DatePipe,
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
  ) {
    activatedRoute.params.subscribe((params) => {
      this.branchId = params['branchId'] as number;
      this.brokerageId = params['brokerageId'] as number;
      this.producerId = params['producerId'] as number;
    });
  }
  updateStatus(status: boolean) {
    this.updateStatusEvent.emit(status);
  }
  ngOnInit(): void {
    this.userID = this.localStorageService.getBoxxUserId();
    this.form = getFormGroup(this.fb);
    this.store
      .select(getProducerCreatedSelector)
      .pipe(take(1))
      .subscribe((value) => {
        if (value) {
          const successMsg = this.translate.instant(
            'brokerage.producer.success.producerSaved',
          );
          this.addAlert('success', 'common.successMsg', successMsg);
        }
      });
    this.store.dispatch(new resetProducerCreated());
    this.showBodySpinner = true;
    this.initialData();
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
      this.shortDateTimeFormat = data.shortDateTimeFormat;
      this.longDateTimeFormat = data.longDateTimeFormat;
    });
  }
  ngOnDestroy(): void {
    this.unSubscriptionForms();
  }

  initialData() {
    forkJoin({
      brokerage: this.brokerageService.GetBrokerage(this.brokerageId),
      brokeragebranch: this.brokerageBranchService.GetBrokerageBranch(
        this.branchId,
      ),
      brokerageProducer: this.brokerageProducerService.GetBrokerageProducer(
        this.producerId,
      ),
    }).subscribe(({ brokerage, brokeragebranch, brokerageProducer }) => {
      if (
        Object.entries(brokerage.data).length === 0 ||
        brokeragebranch.data === null ||
        brokerageProducer.data === null
      ) {
        let isMock = 'true';
        if (
          this.localStorageService.getMockExternalAPIs() === 'false' ||
          environment.mockExternalAPIs === false
        ) {
          isMock = 'false';
        }
        this.router.navigate(['dashboard/home'], {
          queryParams: {
            mock: isMock,
          },
          skipLocationChange: true,
        });
      }

      const brokerageData = brokerage.data;
      this.brokerageProducerOldSelected = { ...brokerageProducer.data };

      //setting createdBy and updatedBy
      this.createdBy = dateTimeWithUserName(
        this.brokerageProducerOldSelected.createdBy,
        this.brokerageProducerOldSelected.createdDt,
        this.longDateTimeFormat,
      );
      this.updatedBy = dateTimeWithUserName(
        this.brokerageProducerOldSelected.updatedBy,
        this.brokerageProducerOldSelected.updatedDt,
        this.longDateTimeFormat,
      );

      this.checked =
        this.brokerageProducerOldSelected.customCommission ?? false;
      this.customCommission = this.checked;
      this.itemsMenu.push({
        label: brokerageData.name,
        path: `/dashboard/brokerage/view/${brokerageData.id}`,
      });

      const branchData = brokeragebranch.data;
      this.branchDataStatus = branchData.active;
      this.itemsMenu.push({
        label: branchData.name,
        path: `/dashboard/branch/view/${branchData.id}/${brokerageData.id}`,
      });
      this.producerSelected = { ...brokerageProducer.data };
      this.form[this.producerSelected.active ? 'enable' : 'disable']();
      this.itemsMenu.push({
        label:
          this.producerSelected.firstName +
          ' ' +
          this.producerSelected?.lastName,
        path: null,
      });
      this.brokerageProducerService
        .GetProducerProfile(this.producerSelected.id)
        .subscribe({
          next: (response) => {
            if (
              Object.keys(response.data) &&
              Object.keys(response.data).length > 0
            ) {
              this.producerProfileOldSelected = {
                ...response.data,
              };
              this.croppedImage =
                this.producerProfileOldSelected?.headShotImage ??
                'assets/img/default-photo.png';
              this.previousImage =
                this.producerProfileOldSelected?.headShotImage ??
                'assets/img/default-photo.png';
              this.producerProfileId = this.producerProfileOldSelected.id;
              if (
                new Date(this.producerProfileOldSelected?.updatedDt) >
                new Date(this.brokerageProducerOldSelected?.updatedDt)
              ) {
                this.updatedBy = dateTimeWithUserName(
                  this.producerProfileOldSelected?.updatedBy,
                  this.producerProfileOldSelected?.updatedDt,
                  this.longDateTimeFormat,
                );
              }
            }
            this.initForm();
          },
          error: (error) => {
            const errorMsg = getErrorMessage(error);
            if (![500].includes(error?.status)) {
              this.addAlert('error', 'common.errorMsg', errorMsg, 'preload');
            }
          },
        });
    });

    this.getAllCommission();
  }

  getAllCommission(pageNumber: number = 1) {
    this.brokerageProducerCommissionService
      .GetAllBrokerageProducerCommission(
        { brokerageProducerId: this.producerId },
        pageNumber,
      )
      .subscribe({
        next: (response) => {
          ({
            data: this.brokerageProducerCommissions.data,
            pagination: this.brokerageProducerCommissions.pagination,
          } = response);
        },
        error: (error) => {
          const errorMsg = getErrorMessage(error);
          if (![500].includes(error?.status)) {
            this.addAlert('error', 'common.errorMsg', errorMsg, 'preload');
          }
        },
      });
  }

  initForm() {
    this.unSubscriptionForms();
    this.isFormDirty = false;
    this.form.get('firstName').setValue(this.producerSelected.firstName);
    this.form.get('lastName').setValue(this.producerSelected.lastName);
    this.form.get('phone').setValue(this.producerSelected.telephone);
    this.form.get('email').setValue(this.producerSelected.email);
    this.form
      .get('customCommission')
      .setValue(this.producerSelected.customCommission);
    this.form
      .get('licenseNumber')
      .setValue(this.producerSelected.licenseNumber ?? '');
    this.form
      .get('licenseExpiry')
      .setValue(
        this.producerSelected.licenseExpiry
          ? this.datePipe.transform(
              new Date(this.producerSelected.licenseExpiry),
              'MMM dd, YYYY',
            )
          : '',
      );
    this.showBodySpinner = false;
    this.initialFormValue = this.form.value;
    this.formSubscription = this.form.valueChanges.subscribe(() => {
      this.isFormDirty = !this.isFormUnchanged(this.initialFormValue);
    });
  }
  private unSubscriptionForms() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  isFormUnchanged(initialValues): boolean {
    let formUnchanged = false;
    if (this.previousImage != this.croppedImage) {
      this.formUpdated = true;
      formUnchanged = false;
    } else {
      this.formUpdated = false;
      formUnchanged = true;
    }
    if (JSON.stringify(this.form.value) === JSON.stringify(initialValues)) {
      this.formUpdated = false;
      formUnchanged = true;
    } else {
      this.formUpdated = true;
      formUnchanged = false;
    }
    return formUnchanged;
  }

  updateForm(producerModel: ProducerModel) {
    this.unSubscriptionForms();
    this.isFormDirty = false;
    this.form.get('firstName').setValue(producerModel.firstName);
    this.form.get('lastName').setValue(producerModel.lastName);
    this.form.get('phone').setValue(producerModel.telephone);
    this.form.get('email').setValue(producerModel.email);
    this.form.get('customCommission').setValue(producerModel.customCommission);
    this.form.get('licenseNumber').setValue(producerModel.licenseNumber);
    this.form
      .get('licenseExpiry')
      .setValue(
        producerModel.licenseExpiry
          ? this.datePipe.transform(
              new Date(producerModel.licenseExpiry),
              'MMM dd, YYYY',
            )
          : '',
      );
    this.resetFormChanges();
  }

  private resetFormChanges() {
    this.initialFormValue = this.form.value;
    this.formSubscription = this.form.valueChanges.subscribe(() => {
      this.isFormDirty = !this.isFormUnchanged(this.initialFormValue);
    });
  }

  toggleChanged(event: boolean) {
    const producerModel = {
      customCommission: Number(event),
      brokerageBranchId: Number(this.branchId),
    };
    this.brokerageProducerService
      .UpdateBrokerageProducer(this.producerId, producerModel)
      .subscribe({
        next: (response: any) => {
          let updateDetails = response?.data ?? {};
          const successMsg = this.translate.instant(
            'brokerage.producer.success.producerUpdate',
          );
          this.addAlert('success', 'common.successMsg', successMsg, 'status');
          this.checked = event;
          this.customCommission = event;
          this.brokerageProducerOldSelected.customCommission = event;
          this.updatedBy =
            updateDetails.updatedBy && updateDetails.updatedDt
              ? dateTimeWithUserName(
                  updateDetails.updatedBy,
                  updateDetails.updatedDt,
                  this.longDateTimeFormat,
                )
              : '';
          this.getBrokerageProducer();
        },
        error: (error) => {
          const errorMsg = getErrorMessage(error);
          if (![500].includes(error?.status)) {
            this.addAlert('error', 'common.errorMsg', errorMsg, 'status');
          }
          this.checked = !event;
          this.customCommission = !event;
          this.brokerageProducerOldSelected.customCommission = !event;
          this.form.get('customCommission').setValue(!event);
        },
      });
  }

  name = 'Angular';
  imageChangedEvent: any = '';

  blob: any;
  blob2: any;
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  imageTransform: ImageTransform = {};

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    const favDialog = document.getElementById('myDialog') as HTMLDialogElement;
    favDialog.showModal();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.showCropper = true;
  }

  loadImageFailed() {}

  handleSubmit(e: any) {
    if (!!!this.isFormDirty) {
      return;
    }
    this.onHandleCloseEvent();
    this.submitted = true;
    let alertMessage = true;
    if (this.form.valid && this.form.enabled) {
      let spinnerCounter = 0;
      let imageUpdated = false;
      if (
        this.producerProfileOldSelected?.headShotImage != this.croppedImage &&
        this.producerProfileId
      ) {
        imageUpdated = true;
      }
      const producerModel = mapFormGroupToModel(
        this.form,
        this.customCommission,
        this.croppedImage,
        this.branchId,
      );
      this.showSpinner = true;
      spinnerCounter++;

      if (
        (imageUpdated && this.formUpdated) ||
        this.formUpdated ||
        this.hasError
      ) {
        alertMessage = false;
        this.brokerageProducerService
          .UpdateBrokerageProducer(this.producerId, producerModel)
          .subscribe({
            next: (response: any) => {
              let updateDetails = response?.data ?? {};
              if (alertMessage === false) {
                const successMsg = this.translate.instant(
                  'brokerage.producer.success.producerUpdate',
                );
                this.addAlert(
                  'success',
                  'common.successMsg',
                  successMsg,
                  'producer',
                );
              }
              this.updateForm(producerModel);
              this.itemsMenu[3] = {
                label: `${updateDetails.firstName} ${updateDetails.lastName}`,
                path: null,
              };
              this.producerSelected.firstName = updateDetails.firstName;
              this.producerSelected.lastName = updateDetails.lastName;
              this.updatedBy =
                updateDetails.updatedBy && updateDetails.updatedDt
                  ? dateTimeWithUserName(
                      updateDetails.updatedBy,
                      updateDetails.updatedDt,
                      this.longDateTimeFormat,
                    )
                  : '';
              this.getBrokerageProducer();
            },
            error: (error) => {
              const errorMsg = getErrorMessage(error);
              if (![500].includes(error?.status)) {
                this.hasError = true;
                this.addAlert('error', 'common.errorMsg', errorMsg, 'producer');
              }
            },
          })
          .add(() => {
            this.showSpinner = false;
            this.viewPortScroller.scrollToPosition([0, 0]);
          });
        this.formUpdated = false;
      }

      if (imageUpdated) {
        if (
          this.producerProfileOldSelected?.headShotImage != this.croppedImage &&
          this.producerProfileId
        ) {
          this.showSpinner = true;
          spinnerCounter++;
          this.brokerageProducerService
            .updateProducerProfile(+this.producerProfileId, this.croppedImage)
            .subscribe({
              next: (response: any) => {
                if (alertMessage !== false) {
                  const successMsg = this.translate.instant(
                    'brokerage.producer.success.profileUpdate',
                  );
                  this.addAlert(
                    'success',
                    'common.successMsg',
                    successMsg,
                    'profile',
                  );
                }

                let updateDetails = response?.data ?? {};
                this.updatedBy =
                  updateDetails.updatedBy && updateDetails.updatedDt
                    ? dateTimeWithUserName(
                        updateDetails.updatedBy,
                        updateDetails.updatedDt,
                        this.longDateTimeFormat,
                      )
                    : '';

                if (this.producerProfileOldSelected)
                  this.producerProfileOldSelected.headShotImage =
                    this.croppedImage;
                this.unSubscriptionForms();
                this.resetFormChanges();
              },
              error: (error) => {
                const errorMsg = getErrorMessage(error);
                if (![500].includes(error?.status)) {
                  this.addAlert(
                    'error',
                    'common.errorMsg',
                    errorMsg,
                    'profile',
                  );
                  this.hasError = true;
                }
              },
            })
            .add(() => {
              this.showSpinner = false;
              this.viewPortScroller.scrollToPosition([0, 0]);
            });
        }
        this.isFormDirty = false;
      }

      if (this.hasError) {
        this.isFormDirty = true;
      }

      if (spinnerCounter == 0) this.showSpinner = false;
    }
  }

  base64textString: string = '';

  handleFileSelect(evt) {
    var files = evt.target.files;
    var file = files[0];

    if (files && file) {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = (e: any) => {
        this.url = e.target.result;
      };
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
  }

  onHandleCloseEvent() {
    this.notificationAlert.show = false;
    this.operationProducerSuccess = false;
    this.operationProducerHasError = false;

    this.operationProducerProfileSuccess = false;
    this.operationProducerProfileHasError = false;

    this.isPrfileImgUploadError = false;
    this.prfileImgUpdateErrMsg = '';
  }

  handleProducerChangeStatus() {
    this.onHandleCloseEvent();
    if (this.producerSelected?.active) {
      this.showBodySpinner = true;
      this.brokerageProducerService
        .DeleteBrokerageProducer(this.producerSelected?.id)
        .subscribe({
          next: (response: any) => {
            const msg = this.translate.instant(
              'brokerage.producer.success.producerDeactivated',
            );
            this.addAlert('success', 'common.successMsg', msg, 'status');

            if (this.producerSelected.systemUserId != null) {
              this.systemUserId = this.producerSelected.systemUserId;
              this.systemUser
                .DeleteSystemUser(this.systemUserId)
                .subscribe((response) => {
                  this.hasError = false;
                  this.showBodySpinner = false;
                  this.producerSelected.active = false;
                  this.isSuccess = true;
                  this.processingActivation = false;
                  this.toggleActive = false;
                  this.updateStatus(false);
                  this.form[this.toggleActive ? 'enable' : 'disable']({
                    emitEvent: false,
                  });
                });
            } else {
              this.showBodySpinner = true;
              const msg = this.translate.instant(
                'brokerage.producer.success.producerDeactivated',
              );
              this.addAlert('success', 'common.successMsg', msg, 'status');
            }
            this.producerSelected.active = !this.producerSelected?.active;
            this.showBodySpinner = false;
            let updateDetails = response?.data ?? {};
            this.updatedBy =
              updateDetails.updatedBy && updateDetails.updatedDt
                ? dateTimeWithUserName(
                    updateDetails.updatedBy,
                    updateDetails.updatedDt,
                    this.longDateTimeFormat,
                  )
                : '';
          },
          error: (error) => {
            const msg = getErrorMessage(error);
            if (![500].includes(error?.status)) {
              this.addAlert('error', 'common.errorMsg', msg, 'status');
            }
            this.showBodySpinner = false;
          },
        })
        .add(() => {
          this.form[this.producerSelected.active ? 'enable' : 'disable']({
            emitEvent: false,
          });
          this.viewPortScroller.scrollToPosition([0, 0]);
        });
    } else {
      this.showBodySpinner = true;
      this.brokerageProducerService
        .RestoreBrokerageProducer(this.producerSelected?.id)
        .subscribe({
          next: (response: any) => {
            if (this.producerSelected.systemUserId != null) {
              const msg = this.translate.instant(
                'brokerage.producer.success.producerActivatedWithoutAccess',
              );
              this.addAlert('success', 'common.successMsg', msg, 'status');
            } else {
              const msg = this.translate.instant(
                'brokerage.producer.success.producerActivated',
              );
              this.addAlert('success', 'common.successMsg', msg, 'status');
            }
            this.producerSelected.active = !this.producerSelected?.active;
            this.showBodySpinner = false;
            let updateDetails = response?.data ?? {};
            this.updatedBy =
              updateDetails.updatedBy && updateDetails.updatedDt
                ? dateTimeWithUserName(
                    updateDetails.updatedBy,
                    updateDetails.updatedDt,
                    this.longDateTimeFormat,
                  )
                : '';
          },
          error: (error) => {
            const msg = getErrorMessage(error);
            if (![500].includes(error?.status)) {
              this.addAlert('error', 'common.errorMsg', msg, 'status');
            }
            this.showBodySpinner = false;
          },
        })
        .add(() => {
          this.form[this.producerSelected.active ? 'enable' : 'disable']({
            emitEvent: false,
          });
          this.viewPortScroller.scrollToPosition([0, 0]);
        });
    }
  }

  handleBrokerageProducerCommissionModification(event) {
    const { commission, method } = event;

    (commission.brokerageProducerId = Number(this.producerId)),
      (this.notificationAlert = { show: false });
    switch (method) {
      case 'create':
        // confirmation modal popup
        if (
          commission.riskRegionId == -1 &&
          this.brokerageProducerCommissions.data?.length > 0
        ) {
          this.commissionConfirmationData = commission;
          this.showConfirmationModal = true;
        } else {
          commission.override = true;
          this.createProducerCommission(commission);
        }
        break;
      case 'update':
        delete commission.status;
        commission.type = 1;
        this.brokerageProducerCommissionService
          .UpdateCommission(commission, commission.id)
          .subscribe({
            next: (_) => {
              const msg = this.translate.instant(
                'brokerage.producer.success.commissionUpdated',
              );
              this.addAlert('success', 'common.successMsg', msg, 'status');
              this.getAllCommission(
                this.brokerageProducerCommissions.pagination.currentPage,
              );
            },
            error: (error) => {
              const msg = getErrorMessage(error);
              if (![500].includes(error?.status)) {
                this.addAlert('error', 'common.errorMsg', msg, 'status');
              }
            },
          });
        break;
      case 'enable':
        this.brokerageProducerCommissionService
          .RestoreCommission(commission.id)
          .subscribe({
            next: (_) => {
              const msg = this.translate.instant(
                'brokerage.producer.success.commissionEnabled',
              );
              this.addAlert('success', 'common.successMsg', msg, 'status');
              this.getAllCommission(
                this.brokerageProducerCommissions.pagination.currentPage,
              );
            },
            error: (error) => {
              const msg = getErrorMessage(error);
              if (![500].includes(error?.status)) {
                this.addAlert('error', 'common.errorMsg', msg, 'status');
              }
            },
          });
        break;
      case 'disable':
        this.brokerageProducerCommissionService
          .DeleteCommission(commission.id)
          .subscribe({
            next: (_) => {
              const msg = this.translate.instant(
                'brokerage.producer.success.commissionDisabled',
              );
              this.addAlert('success', 'common.successMsg', msg, 'status');
              this.getAllCommission(
                this.brokerageProducerCommissions.pagination.currentPage,
              );
            },
            error: (error) => {
              const msg = getErrorMessage(error);
              if (![500].includes(error?.status)) {
                this.addAlert('error', 'common.errorMsg', msg, 'status');
              }
            },
          });
        break;

      default:
        console.error('No method found');
        break;
    }
  }

  handleCommissionOverride(commission: any) {
    this.createProducerCommission(commission);
  }

  createProducerCommission(commission: any) {
    this.brokerageProducerCommissionService
      .CreateCommission(commission)
      .subscribe({
        next: (_) => {
          this.closeModalConfirmation();
          const msg = this.translate.instant(
            'brokerage.producer.success.commissionActivated',
          );
          this.addAlert('success', 'common.successMsg', msg, 'status');
          this.resetAddCommissionFormSubject.next(true);
          this.getAllCommission();
        },
        error: (error) => {
          this.closeModalConfirmation();
          const msg = getErrorMessage(error);
          if (![500].includes(error?.status)) {
            this.addAlert('error', 'common.errorMsg', msg, 'status');
          }
        },
      });
  }

  closeModalConfirmation() {
    this.showConfirmationModal = false;
    this.commissionConfirmationData = null;
  }

  handleCommissionPageChange(pageNumber: number) {
    this.getAllCommission(pageNumber);
  }

  producerCreatedAlertClose() {
    this.isNavigatedFromAddProducer = false;
  }

  closeImageModal() {
    if (!this.producerProfileOldSelected?.headShotImage)
      this.croppedImage = this.previousImage ?? 'assets/img/default-photo.png';
    else
      this.croppedImage = this.producerProfileOldSelected?.headShotImage
        ? this.previousImage
        : 'assets/img/default-photo.png';
    this.clearFileInput();
    this.isFormDirty = !this.formUpdated ? false : true;
  }

  handleCloseSuccessEvent() {
    this.showErrorAlert = false;
    this.isSuccess = false;
  }
  selectedImage() {
    if (this.croppedImage) this.previousImage = this.croppedImage;
    if (
      this.producerProfileOldSelected?.headShotImage != this.croppedImage ||
      this.isFormDirty
    ) {
      this.isFormDirty = true;
    } else {
      this.isFormDirty = false;
    }
  }

  clearFileInput() {
    this.fileInput.nativeElement.value = null;
  }

  onImageSelected(event: any): void {
    const file = event.target?.files[0];
    if (file) {
      const fileSizeMB = file.size / (1024 * 1024);
      if (fileSizeMB > this.maxFileSizeMB) {
        this.clearFileInput();
        const errorMsg = this.translate.instant(
          'brokerage.producer.error.imageSizeError',
        );
        this.addAlert('error', 'common.errorMsg', errorMsg, 'profile');
        return;
      }

      const fileExtension = this.getFileExtension(file);
      if (!this.allowedFormats.includes(fileExtension.toLowerCase())) {
        this.clearFileInput();
        const errorMsg = this.translate.instant(
          'brokerage.producer.error.imageFormatError',
        );
        this.addAlert('error', 'common.errorMsg', errorMsg, 'profile');
        return;
      }

      const reader = new FileReader();
      reader.onload = (fileEvent) => {
        this.imageChangedEvent = fileEvent.target?.result;
      };
      reader.readAsDataURL(file);
      this.imageChangedEvent = event;
      const favDialog = document.getElementById(
        'myDialog',
      ) as HTMLDialogElement;
      favDialog.showModal();
    }
  }

  getFileExtension(file: File): string {
    const filename = file.name;
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1) {
      return '';
    }
    return filename.substr(lastDotIndex).toLowerCase();
  }
  getBrokerageProducer() {
    this.brokerageProducerService
      .GetBrokerageProducer(this.producerId)
      .subscribe({
        next: (response) => {
          this.brokerageProducerOldSelected = { ...response.data };
        },
        error: (error) => {
          const errorMsg = getErrorMessage(error);
          if (![500].includes(error?.status)) {
            this.addAlert('error', 'common.errorMsg', errorMsg, 'preload');
          }
        },
      });
  }
  handleExpiryDate(date: Date | undefined) {
    const formattedDate = date
      ? this.datePipe.transform(new Date(date), 'MMM dd, YYYY')
      : '';
    this.form.controls['licenseExpiry'].setValue(formattedDate);
    this.form.controls['licenseExpiry'].markAsDirty();
    this.form.controls['licenseNumber'].updateValueAndValidity();
  }
  errorExpiry() {
    let error = { show: false, msg: '' };
    if (this.producerSelected.active) {
      let show = !this.form.controls['licenseExpiry'].valid;
      error.show = show;
      error.msg = show
        ? this.form.controls['licenseExpiry'].getError('date')
        : '';
    }
    return error;
  }
  addAlert(
    type: string,
    headerText: string,
    bodyText: string,
    from: string = '',
  ) {
    if (from != '' && from != 'preload') {
      let ind = this.alertList.findIndex((x) => x.from == from);
      if (ind >= 0) {
        this.alertList.splice(ind, 1);
      }
    }
    this.alertList.push({
      type: type,
      headerText: headerText,
      bodyText: bodyText,
      from: from,
    });
  }
  removeAlert(index) {
    this.alertList.splice(index, 1);
  }
}
