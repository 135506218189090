import { createFeatureSelector, createSelector } from '@ngrx/store';
import { stores } from '..';

export const dashboardSelector = createFeatureSelector<stores>('stores');

export const getDashboardSelector = createSelector(
  dashboardSelector,
  (state: stores) => state.stateDashboard,
);

export const getCurrencySelector = createSelector(
  dashboardSelector,
  (state: stores) => state.stateDashboard.currency,
);

export const getCountrySelector = createSelector(
  dashboardSelector,
  (state: stores) => state.stateDashboard.country,
);

export const getUserTypes = createSelector(
  dashboardSelector,
  (state: stores) => state.stateDashboard.userTypes,
);
