<div class="custom-modal" *ngIf="showModal">
  <div class="modal-content">
    <div class="close-btn">
      <button (click)="closeHandler()">
        <img src="assets/img/dashboard/card/close.png" />
        {{ "common.close" | translate }}
      </button>
    </div>
    <div class="content">
      <div class="d-flex flex-column align-items-center">
        <img
          src="assets/img/alert-triangle.jpg"
          width="66px"
          height="66px"
          alt="alert"
        />
        <boxxds-body
          sz="md"
          [text]="'submission.heading.riskAnalysisUpdate' | translate"
          [customStyle]="{
            color: '#FE802C',
            marginTop: '12px',
            marginBottom: '20px'
          }"
        ></boxxds-body>
      </div>
      <div class="modal-description">
        <boxxds-body
          sz="sm"
          [text]="'submission.modal.riskAnalysisUpdate' | translate"
          [customStyle]="{
            color: '#333334',
            width: '537px',
            textAlign: 'left',
            fontSize: '13px',
            margin: '0 auto'
          }"
        ></boxxds-body>
      </div>
      <div class="policy-details d-flex flex-column">
        <boxxds-body
          sz="md"
          [text]="existingQuoteOptionData.quoteNumber"
          [customStyle]="{ color: '#616162', marginTop: '20px' }"
        ></boxxds-body>
        <div class="d-flex mt-1">
          <boxxds-body
            sz="sm"
            [text]="'productForm.heading.effectiveDate:' | translate"
            [customStyle]="{
              color: '#616162',
              marginRight: '4px',
              lineHeight: '14px'
            }"
          ></boxxds-body>
          <boxxds-body
            sz="sm"
            [text]="
              getFormattedDate(
                existingQuoteOptionData.effectiveDate,
                longDateFormat
              )
            "
            [customStyle]="{ color: '#616162', lineHeight: '14px' }"
          ></boxxds-body>
        </div>
        <div class="d-flex mt-1">
          <boxxds-body
            sz="sm"
            [text]="'productForm.heading.insured:' | translate"
            [customStyle]="{
              color: '#616162',
              marginRight: '4px',
              lineHeight: '14px'
            }"
          ></boxxds-body>
          <boxxds-body
            sz="sm"
            [text]="existingQuoteOptionData.insured"
            [customStyle]="{ color: '#616162', lineHeight: '14px' }"
          ></boxxds-body>
        </div>
      </div>
      <div class="modal-buttons">
        <a
          class="cancel-link"
          href="javascript:;"
          (click)="handleDiscard($event)"
        >
          {{ "common.discardChanges" | translate }}
        </a>
        <boxxds-button
          [buttonText]="'common.saveAndContinue' | translate"
          [type]="'submitted'"
          btnType="primary"
          [btnStyle]="{
            width: '171px',
            padding: '0px 20px',
            marginLeft: '20px'
          }"
          (handleClick)="handleSaveAndContinue()"
        >
        </boxxds-button>
      </div>
    </div>
  </div>
</div>
