<div class="custom-modal" *ngIf="showModal">
  <div class="modal-content">
    <ng-container *ngIf="showBodySpinner">
      <div class="body-spinner">
        <boxxds-spinner
          [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
        ></boxxds-spinner>
      </div>
    </ng-container>
    <ng-container *ngIf="!showBodySpinner">
      <div class="close-btn">
        <boxxds-button-v2
          [isDisabled]="false"
          [size]="'lg'"
          [btnType]="'close'"
          (handleClick)="closeHandler()"
        >
        </boxxds-button-v2>
      </div>
      <div class="header-content">
        <boxxds-alert
          (handleCloseEvent)="handleNotificationAlertClose()"
          *ngIf="notificationAlert?.show"
          [type]="notificationAlert?.type"
          [headerText]="notificationAlert?.headerText | translate"
          [bodyText]="notificationAlert?.bodyText | translate"
          [containerStyle]="{
            left: '50%',
            transform: 'translateX(-50%)',
            top: '10px'
          }"
        >
        </boxxds-alert>
        <boxxds-heading
          [sz]="'7'"
          [text]="
            ('workFlow3.components.sublimit.heading.quoteOption' | translate) +
            ' ' +
            (productDetails?.quoteIndex + 1) +
            ' - ' +
            productDetails?.key +
            '- ' +
            ('workFlow3.components.sublimit.heading.sublimits' | translate)
          "
          type="bold"
          [customStyle]="{
            fontSize: '30px',
            color: '#292933',
            fontWeight: '600',
            lineHeight: '38px'
          }"
        ></boxxds-heading>
        <boxxds-heading
          [sz]="'7'"
          [text]="
            ('workFlow3.components.sublimit.subHeading.product' | translate) +
            productDetails?.key
          "
          type="bold"
          [customStyle]="{
            fontSize: '14px',
            color: '#475467',
            fontWeight: '400',
            lineHeight: '20px'
          }"
        ></boxxds-heading>
      </div>

      <div class="quote-option-container">
        <div class="quote-option-content">
          <div class="quote-option-header d-flex flex-column">
            <boxxds-heading
              [sz]="'7'"
              [text]="
                ('workFlow3.components.sublimit.heading.limitAndRetention'
                  | translate) +
                (productDetails?.quoteIndex + 1) +
                '.'
              "
              type="bold"
              [customStyle]="{
                fontSize: '14px',
                color: '#2D2D39',
                fontWeight: '500',
                lineHeight: '20px',
                marginBottom: '8px'
              }"
            ></boxxds-heading>
            <boxxds-heading
              [sz]="'7'"
              [text]="
                sublimitSlideOutSubHeading
                  ?.replace('{number}', productDetails?.quoteIndex + 1)
                  ?.replace('{product}', productDetails?.key)
              "
              type="bold"
              [customStyle]="{
                fontSize: '12px',
                color: '#667085',
                fontWeight: '400',
                lineHeight: '18px',
                marginBottom: '20px',
                maxWidth: '480px'
              }"
            ></boxxds-heading>
          </div>
          <div class="mr-4">
            <boxxds-dropdown-v2
              *ngIf="!showQuoteOptionLimitCustomField"
              [leftSymbol]="'$'"
              [label]="'quoteCalculator.label.limit' | translate"
              [placeholder]="'common.select' | translate"
              [options]="limitOptionsForProducts[productDetails.key]"
              [invalidErrMsg]="showInvalidLimitErrMsg"
              [dropdownContainerStyle]="{ width: '200px' }"
              (currentValueChange)="
                this.permissionList[this.currentScreen] &&
                  this.currentQuoteStatus !== 'quote-closed' &&
                  quoteOptionLimitChange(
                    $event,
                    title,
                    limitOptionsForProducts[productDetails.key]
                  )
              "
              [currentValue]="
                productDetails?.limit
                  | currencyFormat: currency : 'symbol-narrow' : '1.0-0'
              "
              [isReadonly]="
                !this.permissionList[this.currentScreen] ||
                this.currentQuoteStatus === 'quote-closed'
              "
              [class.noPointerEvents]="
                !this.permissionList[this.currentScreen] ||
                this.currentQuoteStatus === 'quote-closed'
              "
            >
            </boxxds-dropdown-v2>

            <div class="custom-value" *ngIf="showQuoteOptionLimitCustomField">
              <label class="text-input-label">{{
                "quoteCalculator.label.limit" | translate
              }}</label>
              <input
                type="text"
                mask="separator.0"
                thousandSeparator=","
                prefix="$"
                (blur)="handleQuoteOptionLimitCustomValue($event, title)"
                maxlength="14"
                [autofocus]="true"
              />
            </div>
          </div>
          <div>
            <boxxds-dropdown-v2
              [leftSymbol]="'$'"
              *ngIf="!showQuoteOptionDeductibleCustomField"
              [label]="
                'workFlow3.components.sublimit.label.retention' | translate
              "
              [placeholder]="'common.select' | translate"
              [options]="deductibleOptions"
              [dropdownContainerStyle]="{ width: '200px' }"
              (currentValueChange)="
                this.permissionList[this.currentScreen] &&
                  this.currentQuoteStatus !== 'quote-closed' &&
                  handleDeductibleChange($event, productDetails?.value)
              "
              [invalidErrMsg]="showInvalidDeductibleErrMsg"
              [currentValue]="
                productDetails?.deductables
                  | currencyFormat: currency : 'symbol-narrow' : '1.0-0'
              "
              [isReadonly]="
                !this.permissionList[this.currentScreen] ||
                this.currentQuoteStatus === 'quote-closed'
              "
              [class.noPointerEvents]="
                !this.permissionList[this.currentScreen] ||
                this.currentQuoteStatus === 'quote-closed'
              "
            >
            </boxxds-dropdown-v2>

            <div
              class="custom-value"
              *ngIf="showQuoteOptionDeductibleCustomField"
            >
              <label class="text-input-label">{{
                "quoteCalculator.label.deductibles" | translate
              }}</label>
              <input
                type="text"
                mask="separator.0"
                thousandSeparator=","
                prefix="$"
                maxlength="14"
                (blur)="
                  handleDeductibleCustValue($event, productDetails?.value)
                "
                [autofocus]="true"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="sublimits-container">
        <boxxds-heading
          [sz]="'7'"
          [text]="
            sublimitCoverageSlideOutHeading?.replace(
              '{product}',
              productDetails?.key
            )
          "
          type="bold"
          [customStyle]="{
            fontSize: '14px',
            color: '#2D2D39',
            fontWeight: '500',
            lineHeight: '20px',
            paddingBottom: '11px',
            borderBottom: '1px solid #D0D5DD',
            marginBottom: '16px'
          }"
        ></boxxds-heading>
        <div class="coverages-sublimits-container">
          <div class="coverages-section">
            <ul class="coverages">
              <li
                *ngFor="let coverage of coverageSublimits; let i = index"
                (click)="setCurrentTabActive(i)"
                class="coverages-item"
                [ngClass]="{
                  active: coverageActiveIdx === i
                }"
              >
                <div class="coverage-item">
                  <div
                    class="coverage-name"
                    [title]="coverage.coverage?.coverageName"
                  >
                    {{ coverage.coverage?.coverageName }}
                  </div>

                  <boxxds-switch-v2
                    *ngIf="
                      this.permissionList[this.currentScreen] &&
                      this.currentQuoteStatus !== 'quote-closed'
                    "
                    [checked]="coverage.coverage?.coverageIncluded"
                    name="contact_details"
                    [disabled]="false"
                    [ngStyle]="{ marginLeft: '4px' }"
                    (changed)="onToggleClick($event, coverage?.coverage, i)"
                  >
                  </boxxds-switch-v2>
                </div>
              </li>
            </ul>
          </div>
          <ng-container
            *ngFor="
              let data of coverageSublimits;
              let coverageSublimitIdx = index
            "
          >
            <div
              class="sublimits-section"
              [ngClass]="{ backdrop: !data?.coverage?.coverageIncluded }"
              *ngIf="coverageSublimitIdx === coverageActiveIdx"
            >
              <div class="sublimits-header">
                <div
                  class="coverage-name"
                  [title]="data.coverage?.coverageName"
                >
                  {{ data.coverage?.coverageName }}
                </div>
                <div class="limit-retention">
                  <div [ngClass]="{ 'btn-container': coverageEditable }">
                    <div
                      class="sublimit-inputs"
                      [ngClass]="{ editCoverage: coverageEditable }"
                    >
                      <boxxds-dropdown-v2
                        [leftSymbol]="'$'"
                        *ngIf="!showCoverageLimitCustomField"
                        [currentValue]="
                          form.value.sublimitAmount
                            | currencyFormat
                              : currency
                              : 'symbol-narrow'
                              : '1.0-0'
                        "
                        [placeholder]="'common.select' | translate"
                        [options]="
                          data?.coverage?.coverageName
                            ?.toLowerCase()
                            ?.includes(financialCoverageMatchString)
                            ? sublimitOptionsForFinancialCoverage
                            : limitOptionsForProducts[productDetails.key]
                        "
                        [label]="
                          'workFlow3.components.sublimit.label.sublimit'
                            | translate
                        "
                        [control]="'sublimitAmount'"
                        [dropdownContainerStyle]="{
                          width: '200px'
                        }"
                        class="input-fields"
                        (currentValueChange)="
                          this.permissionList[this.currentScreen] &&
                            this.currentQuoteStatus !== 'quote-closed' &&
                            data?.coverage?.coverageIncluded &&
                            handleCoverageLimitOnChange(
                              $event,
                              data?.coverage?.coverageName
                                ?.toLowerCase()
                                ?.includes(financialCoverageMatchString)
                                ? sublimitOptionsForFinancialCoverage
                                : limitOptionsForProducts[productDetails.key],
                              coverageSublimitIdx
                            )
                        "
                        [isReadonly]="
                          !this.permissionList[this.currentScreen] ||
                          this.currentQuoteStatus === 'quote-closed'
                        "
                        [class.noPointerEvents]="
                          !this.permissionList[this.currentScreen] ||
                          this.currentQuoteStatus === 'quote-closed'
                        "
                        [isDisabled]="
                          data.coverage?.coverageReadOnly ||
                          isAllSublimitDisabled(data.coverage?.coverageId) ||
                          (data?.coverage?.coverageName
                            ?.toLowerCase()
                            ?.includes(financialCoverageMatchString) &&
                            setFinancialSublimitValidation === true &&
                            getNumber(form.value.retentionAmount) <
                              financialCoverageRuleMinDeductible)
                        "
                        [invalidErrMsg]="limitValidationMsg"
                      >
                      </boxxds-dropdown-v2>

                      <div *ngIf="showCoverageLimitCustomField">
                        <label class="custom-value-label">{{
                          "workFlow3.components.sublimit.label.sublimit"
                            | translate
                        }}</label>
                        <input
                          type="text"
                          class="custom-value"
                          mask="separator.0"
                          thousandSeparator=","
                          prefix="$"
                          (blur)="
                            handleLimitCustomValueClick(
                              $event,
                              coverageSublimitIdx
                            )
                          "
                          maxlength="14"
                          [autofocus]="true"
                        />
                      </div>

                      <boxxds-dropdown-v2
                        [leftSymbol]="'$'"
                        *ngIf="!showDeductibleCustomField"
                        [currentValue]="
                          form.value.retentionAmount
                            | currencyFormat
                              : currency
                              : 'symbol-narrow'
                              : '1.0-0'
                        "
                        [placeholder]="
                          'workFlow3.components.sublimit.placeholder.retention'
                            | translate
                        "
                        [options]="coverageDeductibles"
                        [label]="
                          'workFlow3.components.sublimit.label.retention'
                            | translate
                        "
                        (currentValueChange)="
                          this.permissionList[this.currentScreen] &&
                            this.currentQuoteStatus !== 'quote-closed' &&
                            data?.coverage?.coverageIncluded &&
                            handleCoverageDeductableOnChange(
                              $event,
                              data?.coverage?.coverageName
                                ?.toLowerCase()
                                ?.includes(financialCoverageMatchString),
                              coverageSublimitIdx
                            )
                        "
                        [control]="'retentionAmount'"
                        [dropdownContainerStyle]="{ width: '200px' }"
                        class="input-fields retentionAmount-field"
                        [isReadonly]="
                          !this.permissionList[this.currentScreen] ||
                          this.currentQuoteStatus === 'quote-closed'
                        "
                        [class.noPointerEvents]="
                          !this.permissionList[this.currentScreen] ||
                          this.currentQuoteStatus === 'quote-closed'
                        "
                        [isDisabled]="
                          data.coverage?.coverageReadOnly ||
                          isAllSublimitDisabled(data.coverage?.coverageId)
                        "
                        [invalidErrMsg]="retentionValidationMsg"
                      >
                      </boxxds-dropdown-v2>

                      <div
                        *ngIf="showDeductibleCustomField"
                        class="retentionAmount-field"
                      >
                        <label class="custom-value-label">{{
                          "workFlow3.components.sublimit.label.retention"
                            | translate
                        }}</label>
                        <input
                          type="text"
                          class="custom-value retentionAmount-field"
                          mask="separator.0"
                          thousandSeparator=","
                          prefix="$"
                          (blur)="
                            handleRetentionCustomValueClick(
                              $event,
                              data?.coverage?.coverageName
                                ?.toLowerCase()
                                ?.includes(financialCoverageMatchString),
                              coverageSublimitIdx
                            )
                          "
                          maxlength="14"
                          [autofocus]="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="sublimit-tbl-container">
                <app-scrollable-table-v2
                  *ngIf="data?.sublimit?.length > 0"
                  [setFinancialSublimitValidation]="
                    setFinancialSublimitValidation
                  "
                  [initialData]="initialData[coverageSublimitIdx]?.sublimit"
                  [limitOptionsForProducts]="
                    limitOptionsForProducts[productDetails?.key]
                  "
                  [sublimitOptionsForFinancialCoverage]="
                    sublimitOptionsForFinancialCoverage
                  "
                  [occurrenceTypeDropdownValues]="occurrenceTypeDropdownValues"
                  [coverageDeductibles]="coverageDeductibles"
                  [measuresDropdownValues]="measuresDropdownValues"
                  [showAsReadOnly]="data?.coverage?.coverageReadOnly"
                  [currentQuoteOptionDeductible]="currentQuoteOptionDeductible"
                  [currency]="currency"
                  [currentCoverageName]="data?.coverage?.coverageName"
                  (handleRowEditSave)="
                    this.permissionList[this.currentScreen] &&
                      this.currentQuoteStatus !== 'quote-closed' &&
                      handleSublimitRowSave($event, coverageSublimitIdx)
                  "
                  [data]="data?.sublimit"
                  [form]="sublimitForm"
                  [product]="productDetails?.key"
                  [quoteOptionLimit]="
                    quoteOptionLimit || quoteOptionCustomLimit
                  "
                  [quoteOptionDeductible]="
                    quoteOptionDeductible || quoteOptionCustomDeductible
                  "
                  (disableSaveAndContinue)="disableSaveAndContinue()"
                  (enableSaveAndContinue)="enableSaveAndContinue()"
                ></app-scrollable-table-v2>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <ng-container
        *ngIf="
          this.permissionList[this.currentScreen] &&
          this.currentQuoteStatus !== 'quote-closed'
        "
      >
        <div class="recalc-holder">
          <div class="d-flex">
            <boxxds-button-v2
              [buttonText]="
                'workFlow3.components.shared.button.discardChanges' | translate
              "
              [isDisabled]="isButtonDisabled"
              [btnClass]="'gray'"
              [btnType]="'secondary'"
              (handleClick)="handleReset()"
              [btnStyle]="{ width: '140px', marginRight: '40px' }"
            >
            </boxxds-button-v2>

            <boxxds-button-v2
              [buttonText]="
                'workFlow3.components.shared.button.saveChanges' | translate
              "
              [type]="'submitted'"
              [btnClass]="'primary'"
              [btnType]="'default'"
              [btnStyle]="{ width: '140px' }"
              (handleClick)="handleRecalculate()"
              [isDisabled]="isButtonDisabled"
            >
            </boxxds-button-v2>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
