<div
  class="company-container"
  [ngStyle]="isSuccess && hasError && { paddingTop: '140px' }"
>
  <div class="alert-holder">
    <boxxds-alert
      *ngIf="showErrorAlert"
      (handleCloseEvent)="handleCloseSuccessEventExt()"
      type="error"
      headerText="error!"
      [bodyText]="msgErrorAlert"
    >
    </boxxds-alert>
    <boxxds-alert
      *ngIf="isSuccess"
      (handleCloseEvent)="handleCloseSuccessEvent()"
      type="success"
      headerText="success!"
      bodyText="Saved successfully."
      [containerStyle]="{
        width: 'calc(100vw - 320px)',
        maxWidth: '1115px',
        position: 'fixed',
        top: '100px',
        zIndex: '2',
        marginTop: '8px'
      }"
    >
    </boxxds-alert>

    <boxxds-alert
      *ngIf="hasError"
      (handleCloseEvent)="handleCloseSuccessEvent()"
      type="error"
      headerText="error!"
      [bodyText]="errorMessage"
      [containerStyle]="{
        width: 'calc(100vw - 320px)',
        maxWidth: '1115px',
        position: 'fixed',
        top: '100px',
        zIndex: '2',
        marginTop: '8px'
      }"
    >
    </boxxds-alert>
  </div>
  <div class="create-title-container">
    <boxxds-body
      sz="lg"
      [text]="'Insured details' | translate"
      [customStyle]="{
        color: '#616162',
        textAlign: 'left',
        marginBottom: '8px',
        fontSize: '16px'
      }"
    ></boxxds-body>

    <span>*REQUIRED</span>
  </div>
  <div class="search-container">
    <form [formGroup]="insuredUserForm">
      <div class="add-container">
        <div class="d-flex">
          <boxxds-dropdown
            [form]="insuredUserForm"
            [control]="'insuredType'"
            [placeholder]="'placeholder.select' | translate"
            [options]="insuredTypes"
            [label]="('systemUser.label.insuredType' | translate) + '*'"
            [btnStyle]="{ marginTop: '18px', width: '264px' }"
            [dropdownContainerStyle]="{ width: '264px', marginRight: '40px' }"
            class="employees"
            [requiredErrMsg]="requiredErrMsg | translate"
            [formSubmitted]="formSubmitted"
            [currentValue]="insuredType"
            (currentValueChange)="insureTypeValueChanged($event)"
          >
          </boxxds-dropdown>

          <boxxds-searchable-dropdown
            [form]="insuredUserForm"
            [control]="'insuredId'"
            [placeholder]="
              'systemUser.placeholder.enterInsuredName' | translate
            "
            [options]="insuredUsers"
            [label]="('systemUser.label.insured' | translate) + '*'"
            [btnStyle]="{ marginTop: '8px', width: '264px' }"
            [dropdownContainerStyle]="{ width: '264px', marginRight: '40px' }"
            class="employees"
            [requiredErrMsg]="requiredErrMsg | translate"
            [formSubmitted]="formSubmitted"
            [currentValue]="insuredUser"
            (currentValueChange)="insureUserValueChanged($event)"
            [invalidErrMsg]="formSubmitted ? (invalidName | translate) : ''"
            (textInputChangeEventEmmiter)="searchInsureds($event)"
            [readonly]="!insuredType"
            [dispatchTextInputChange]="true"
          >
          </boxxds-searchable-dropdown>
          <boxxds-searchable-dropdown
            [form]="insuredUserForm"
            [control]="'roleId'"
            [placeholder]="'placeholder.searchRole' | translate"
            [options]="roles"
            [label]="('systemUser.label.role' | translate) + '*'"
            [invalidErrMsg]="formSubmitted ? (invalidRole | translate) : ''"
            [btnStyle]="{ marginTop: '18px', width: '264px' }"
            [dropdownContainerStyle]="{ width: '264px' }"
            class="employees"
            [requiredErrMsg]="requiredErrMsg | translate"
            [formSubmitted]="formSubmitted"
            [currentValue]="roleId"
            (currentValueChange)="boxxRoleValueChanged($event)"
            (textInputChangeEventEmmiter)="searchRoles($event)"
            [dispatchTextInputChange]="true"
          >
          </boxxds-searchable-dropdown>
        </div>
        <div class="d-flex">
          <boxxds-textinput
            [placeholder]="'systemUser.placeholder.email' | translate"
            [form]="insuredUserForm"
            [control]="'loginEmail'"
            [id]="'email'"
            [width]="'380px'"
            [label]="('systemUser.label.loginEmail' | translate) + '*'"
            [requiredErrMsg]="requiredErrMsg | translate"
            [invalidErrMsg]="invalidErrMsg | translate"
            [containerStyle]="{ marginRight: '40px', marginTop: '30px' }"
            [formSubmitted]="formSubmitted"
            maxLength="150"
          >
          </boxxds-textinput>
        </div>
      </div>
      <div class="btn-container">
        <boxxds-button
          *ngIf="!showSpinner"
          [buttonText]="'Add Insured' | translate"
          btnType="primary-outlined"
          [btnStyle]="{ padding: '8px 28px' }"
          type="submit"
          [sz]="'sm'"
          (handleClick)="onSubmit(insuredUserForm.value)"
        >
        </boxxds-button>
        <button
          *ngIf="showSpinner"
          class="btn-processing"
          tabindex="0"
          [ngStyle]="{
            border: '1px solid #FFBE3D',
            borderRadius: '8px',
            width: '150px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4px 8px',
            background: '#FCF8EF'
          }"
        >
          <boxxds-spinner></boxxds-spinner>
        </button>
      </div>
    </form>
  </div>
</div>
