<div class="holder">
  <boxxds-alert
    *ngIf="showErrorAlert"
    (handleCloseEvent)="handleCloseSuccessEvent()"
    type="error"
    headerText="error!"
    [bodyText]="msgErrorAlert"
  >
  </boxxds-alert>
  <boxxds-breadcrumb [items]="itemsMenu"></boxxds-breadcrumb>
  <boxxds-heading sz="5" [text]="title"> </boxxds-heading>
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!showBodySpinner">
    <div class="alert-holder">
      <boxxds-alert
        (handleCloseEvent)="branchCreatedAlertClose()"
        *ngIf="isNavigatedFromAddBranch"
        type="success"
        headerText="success!"
        [bodyText]="'Branch saved successfully'"
        [containerStyle]="{
          width: 'calc(100vw - 320px)',
          maxWidth: '1115px',
          marginBottom: '2'
        }"
      >
      </boxxds-alert>
      <boxxds-alert
        (handleCloseEvent)="producerCreatedAlertClose()"
        *ngIf="isNavigatedFromAddProducer"
        type="success"
        headerText="success!"
        bodyText="Producer saved successfully."
        [containerStyle]="{
          width: 'calc(100vw - 320px)',
          maxWidth: '1115px',
          marginBottom: '2'
        }"
      >
      </boxxds-alert>
      <boxxds-alert
        *ngIf="operationSuccess"
        (handleCloseEvent)="onHandleCloseEvent()"
        type="success"
        headerText="success!"
        [bodyText]="successMessage | translate"
        [containerStyle]="{
          width: 'calc(100vw - 320px)',
          maxWidth: '1115px',
          marginBottom: '2'
        }"
      >
      </boxxds-alert>

      <div class="alert-holder">
        <boxxds-alert
          (handleCloseEvent)="branchCreatedAlertClose()"
          *ngIf="isNavigatedFromAddBranch"
          type="success"
          headerText="success!"
          [bodyText]="'Branch saved successfully'"
        >
        </boxxds-alert>
        <boxxds-alert
          (handleCloseEvent)="producerCreatedAlertClose()"
          *ngIf="isNavigatedFromAddProducer"
          type="success"
          headerText="success!"
          bodyText="Producer saved successfully."
        >
        </boxxds-alert>
        <boxxds-alert
          *ngIf="operationSuccess"
          (handleCloseEvent)="onHandleCloseEvent()"
          type="success"
          headerText="success!"
          [bodyText]="successMessage | translate"
        >
        </boxxds-alert>

        <boxxds-alert
          *ngIf="hasError"
          type="error"
          (handleCloseEvent)="onHandleCloseEvent()"
          headerText="error!"
          [bodyText]="errorMessage"
        >
        </boxxds-alert>
      </div>
    </div>

    <form [formGroup]="form" (ngSubmit)="handleSubmit($event)">
      <app-branch-details
        [isActive]="branchSelected?.active"
        [form]="branch"
        [requiredErrMsg]="requiredErrMsg"
        [formSubmitted]="submitted"
      >
      </app-branch-details>

      <app-location-details-form
        (eventEmitRegionIdSelected)="regionIdSelected($event)"
        [form]="location"
        [requiredErrMsg]="requiredErrMsg"
        [formSubmitted]="submitted"
        [isActive]="
          branchSelected?.active && this.permissionList[this.currentScreen]
        "
      >
      </app-location-details-form>

      <div
        class="footer-add-branch"
        [ngStyle]="{ marginTop: '60px', marginBottom: '20px' }"
      >
        <div class="footer-left-container">
          <app-timestamp
            [label]="'common.createdBy' | translate"
            [value]="createdBy"
            [containerStyle]="{ marginLeft: '10px', top: '-10px' }"
          ></app-timestamp>
          <app-timestamp
            *ngIf="updatedBy"
            [label]="'common.updatedLastBy' | translate"
            [value]="updatedBy"
            [containerStyle]="{
              marginLeft: '10px',
              position: 'relative',
              top: '-10px'
            }"
          ></app-timestamp>
        </div>
        <div
          *ngIf="branchSelected && this.permissionList[this.currentScreen]"
          class="footer-rigth-container"
        >
          <a
            [ngClass]="{ 'desable-link': !isActive }"
            *ngIf="branchSelected.active"
            href="javascript:void(0)"
            (click)="handleDeactivateBranch()"
            [ngStyle]="{ 'margin-right': '64px', 'margin-top': '12px' }"
            >{{
              ("common.deactivate" | translate) +
                " " +
                ("common.branch" | translate)
            }}</a
          >

          <a
            [ngClass]="{ 'desable-link': !isActive }"
            *ngIf="!branchSelected.active"
            href="javascript:void(0)"
            (click)="handleActivateBranch()"
            [ngStyle]="{ 'margin-right': '64px', 'margin-top': '12px' }"
            >{{
              ("common.reactivate" | translate) +
                " " +
                ("common.branch" | translate)
            }}</a
          >
          <boxxds-button
            *ngIf="!showSpinner; else spinnerTemplate"
            [buttonText]="'common.saveChanges' | translate"
            [isDisabled]="
              (branchSelected && !branchSelected.active) ||
              (!!!isBranchFormDirty && !!!isLocationFormDirty)
            "
            [type]="'button'"
            btnType="primary-outlined"
            [btnStyle]="{ padding: '0px 30px' }"
            [sz]="'sm'"
            (handleClick)="handleSubmit($event)"
          >
          </boxxds-button>
          <ng-template #spinnerTemplate>
            <button
              class="btn-processing"
              [ngStyle]="{
                height: '32px',
                border: '1px solid #FFBE3D',
                borderRadius: '8px',
                width: '149px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '4px 8px',
                background: '#FCF8EF'
              }"
            >
              <boxxds-spinner></boxxds-spinner>
            </button>
          </ng-template>
        </div>
      </div>
    </form>
    <div class="add-producer-panel">
      <div class="neutral-gradient"></div>
      <div class="panel-content">
        <ng-container *ngIf="this.permissionList[this.currentScreen]">
          <div class="add-producer">
            <boxxds-button
              [isDisabled]="branchSelected && !branchSelected.active"
              [leftImage]="'add'"
              (handleClick)="dropdownValueChanged()"
              [buttonText]="'Add producer' | translate"
              [type]="'button'"
              btnType="secondary"
              [ngStyle]="{ 'margin-top': '30px' }"
              [btnStyle]="{ background: 'none', border: '1px solid #9090AF' }"
            ></boxxds-button>
          </div>
        </ng-container>

        <div class="tbl-producer">
          <boxxds-table
            (pageChanged)="handlePageChange($event)"
            [data]="listProducers"
            [linkDetails]="[{ name: 'View' }]"
            [showLinks]="true"
            addCopyBtnToField="Contact"
            [totalCount]="totalRecords"
            class="table"
            id="brokerage-table"
            tblHeading="Producers details list"
            [addSortTo]="addSortTo"
            [tableStyle]="{ marginBottom: '0px' }"
            [styleInactive]="true"
            (sortUpClicked)="handleAscSort($event)"
            (sortDownClicked)="handleDescSort($event)"
            [activePageNumber]="pageNumber"
            [tblContainerStyle]="{
              backgroundColor: '#fff',
              padding: '40px 68px',
              marginBottom: '36px',
              border: '1px solid #D6D6D9',
              borderRadius: '8px'
            }"
          >
          </boxxds-table>
        </div>
      </div>
    </div>
  </ng-container>
</div>
