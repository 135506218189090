import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from './base-services';
import { Location } from '../entities/location';
import { BoxxResponse } from '../entities/boxx-response';
import { Observable } from 'rxjs';
import { Brokerage } from '../entities/brokerage';

@Injectable({
  providedIn: 'root',
})
export class LocationService extends BaseService<Location> {
  constructor(http: HttpClient) {
    const baseurl = environment.msInsuredBaseUrl + environment.locationPathUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getAllRegions(page = 1, limit = 10): Observable<BoxxResponse<any>> {
    const url =
      environment.msConfigurationBaseUrl +
      environment.regionPathUrl +
      '?active=true&page=' +
      page +
      '&limit=' +
      limit;
    return this.http.get<BoxxResponse<any>>(url);
  }
  getRegionByCode(regioncode = ''): Observable<BoxxResponse<any>> {
    const url =
      environment.msConfigurationBaseUrl +
      environment.regionPathUrl +
      '?active=true&regionCode=' +
      regioncode;
    return this.http.get<BoxxResponse<any>>(url);
  }

  updateLocation(data: any): Observable<BoxxResponse<Brokerage>> {
    const url =
      environment.msInsuredBaseUrl + environment.locationPathUrl + data.id;
    return this.http.put<BoxxResponse<Brokerage>>(url, data);
  }

  GetLocationDetailsById(
    id,
    page = 1,
    limit = 10,
  ): Observable<BoxxResponse<any>> {
    const url = this.baseurl + id + '?page=' + page + '&limit=' + limit;
    return this.http.get<BoxxResponse<any>>(url);
  }

  getRegionByName(name) {
    const url =
      environment.msConfigurationBaseUrl +
      environment.regionPathUrl +
      '?active=true&name=' +
      name;
    return this.http.get<BoxxResponse<any>>(url);
  }
}
