<div
  class="modal-outer-endorsement"
  *ngIf="showModal"
  (click)="handleBackDropClick()"
>
  <div class="modal-container" (click)="handleModalClick($event)">
    <div class="modal-content-list">
      <div style="position: relative" *ngIf="showModal">
        <app-modal-alert></app-modal-alert>
      </div>
      <boxxds-textinput-v2
        [showClearButton]="true"
        (handleClearValue)="clearValue()"
        [form]="form"
        [placeholder]="
          'workFlow3.components.modalSearchDocuments.placeholder.endorsements'
            | translate
        "
        [label]="''"
        [control]="'filterKeyWord'"
        [id]="'search'"
        [leftIcon]="'assets/img/workflow3/sidemenu/search.png'"
        [width]="'517px'"
        (handleKeyUp)="onSearch($event)"
        maxLength="100"
      >
      </boxxds-textinput-v2>

      <div class="endorsement-list" (scroll)="onScroll($event)">
        <ng-container *ngIf="form.value['filterKeyWord']?.length >= 3">
          <div class="list-item selected">
            <button class="list-item-name">
              <div class="list-text">
                {{ form.value["filterKeyWord"] }}
              </div>
              <img
                *ngIf="form.value['filterKeyWord']?.length >= 3"
                src="assets/img/x-close-02.svg"
                alt="clear"
                class="endorse-clear-btn"
                (click)="handleRemoveTypedEndorsement()"
              />
            </button>
          </div>
        </ng-container>
        <ng-container
          *ngFor="let endorse of endorsementOptions[product]; let i = index"
        >
          <div
            class="list-item"
            [ngClass]="{
              selected: isSelected(endorse),
              'filtered-result': form.value['filterKeyWord']?.length >= 3
            }"
          >
            <button
              class="list-item-name"
              [attr.disabled]="endorse.disabled ? true : null"
            >
              <span
                [innerHtml]="
                  form.value['filterKeyWord']?.length >= 3
                    ? getFilteredResult(endorse.key)
                    : endorse.key
                "
                (click)="handleEndorsementSelect(endorse)"
              >
              </span>
              <img
                *ngIf="isSelected(endorse)"
                src="assets/img/x-close-02.svg"
                alt="clear"
                class="endorse-clear-btn"
                (click)="handleEndorsementSelect(endorse, 'close')"
              />
            </button>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="add-btn-holder">
      <div class="badge">
        {{
          endorsementsSelectedToAdd?.length +
            getEndorseCount() +
            " " +
            ("common.selected" | translate)
        }}
      </div>
      <boxxds-button-v2
        *ngIf="!addBtnClicked"
        [buttonText]="'common.add' | translate"
        [btnClass]="'primary'"
        [btnType]="'default'"
        [size]="'lg'"
        (handleClick)="
          endorsementsSelectedToAdd?.length + getEndorseCount() > 0 &&
            addClickHandler()
        "
        [isDisabled]="
          endorsementsSelectedToAdd?.length + getEndorseCount() === 0
        "
        [btnStyle]="{ width: '87px' }"
      ></boxxds-button-v2>
      <button
        *ngIf="addBtnClicked"
        class="btn-processing"
        tabindex="0"
        [ngStyle]="{
          border: '1px solid #FFBE3D',
          borderRadius: '8px',
          width: '87px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px 16px',
          background: '#FCF8EF',
          marginLeft: 'auto',
          lineHeight: '24px'
        }"
      >
        <boxxds-spinner></boxxds-spinner>
      </button>
    </div>
  </div>
</div>
