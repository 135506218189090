import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Validators } from 'ngx-editor';
import { take } from 'rxjs';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { convertDate } from 'src/app/utils/formatDate';

@Component({
  selector: 'app-modal-sanctions-risk-questions',
  templateUrl: './modal-sanctions-risk-questions.component.html',
  styleUrls: ['./modal-sanctions-risk-questions.component.less'],
})
export class ModalSanctionsRiskQuestionsComponent implements OnInit {
  @Input() showModal: boolean = false;
  @Input() existingQuoteOptionData;
  @Input() riskQuestions;
  @Input() category;
  @Input() data = [];
  @Input() isRestrictedIndustry = false;

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleSave = new EventEmitter<any>();
  @Output() handleDiscardChanges = new EventEmitter<any>();
  @Output() handleDecline = new EventEmitter<any>();

  longDateFormat = 'MMMM DD, YYYY';
  form: FormGroup;
  radioYesOrNoToAllForm: FormGroup;
  validateRiskAnswers = false;
  currentStep = 1;

  // sanctions
  tableData = [];

  constructor(
    private store: Store,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    const formGroupConfig = {};
    this.riskQuestions.forEach((riskQuestion) => {
      formGroupConfig[riskQuestion.id] = ['', [Validators.required]];
    });
    this.form = this.fb.group(formGroupConfig);

    this.radioYesOrNoToAllForm = this.fb.group({
      yesToAll: [''],
    });

    this.store
      .select(getDashboardSelector)
      .pipe(take(1))
      .subscribe((data) => {
        this.longDateFormat = data.longDateFormat;
      });

    this.data = this.data.map((sanction, index) => {
      if (index === 0 && this.isRestrictedIndustry) {
        return sanction;
      } else {
        const popOverData = [];
        popOverData.push(`Full Name: ${sanction.fullName}`);
        popOverData.push(
          `Citizenship: ${
            sanction.citizenship?.length > 0
              ? sanction.citizenship?.join(', ')
              : '-'
          }`,
        );
        popOverData.push(`Remarks: ${sanction?.remarks || '-'}`);
        popOverData.push(
          `Additional Sanctions: ${
            sanction.additionalSanctions?.length > 0
              ? sanction.additionalSanctions.join(', ')
              : '-'
          }`,
        );
        popOverData.push(
          `Programs: ${
            sanction.programs?.length > 0 ? sanction.programs.join(', ') : '-'
          }`,
        );

        return {
          Type: 'OFAC',
          Description: 'Sanction is subject to secondary sanction',
          Reasons: 'Sanction reasons',
          Source: sanction?.source || 'SDN',
          Score: sanction?.score?.toString(),
          popOverContent: popOverData,
        };
      }
    });
  }

  isColumnLayout(explanation) {
    return explanation.split('<br>').length > 1;
  }
  getExplanationArr(explanation) {
    if (explanation.split('<br>').length > 1) {
      // <br> for grid styling
      return explanation.split('<br>');
    } else {
      // <li> for bullet point styling
      return explanation.split('<li>');
    }
  }

  handleRadioCheck() {
    this.radioYesOrNoToAllForm.controls['yesToAll'].setValue('');
  }

  checkYesOrNoToAll(event) {
    for (let question of this.riskQuestions) {
      if (this.form.contains(question.id)) {
        this.form.controls[`${question.id}`].setValue(event?.value);
      }
    }
  }

  handleSaveAndContinue() {
    this.validateRiskAnswers = true;
    if (!this.form.valid) {
      return;
    }
    const riskAnswerIds = Object.keys(this.form.value);
    const riskAnswers = riskAnswerIds.map((id) => ({
      riskQuestionId: id,
      riskAnswer: this.form.value[id],
    }));
    this.handleSave.emit(riskAnswers);
  }

  closeHandler() {
    this.handleClose.emit();
  }

  handleDiscard(event: any) {
    this.handleDiscardChanges.emit();
  }

  getFormattedDate(date: any, dateFormat: string) {
    return date ? convertDate(date, dateFormat) : '';
  }

  handleDeclineQuote() {
    this.handleDecline.emit();
  }

  handleNext() {
    this.currentStep = 2;
  }
}
