import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Quote } from 'src/app/models/quote.model';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { PolicyDashboardService } from 'src/app/services/policy-dashboard.service';
import { getQuoteSelector } from 'src/app/store/create-quote/create-quote.selector';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { AlertService } from 'src/app/services/alert.service';
import { isStatusDisabled } from 'src/app/constants/quoteStatus';

@Component({
  selector: 'app-market-reservation',
  templateUrl: './market-reservation.component.html',
  styleUrls: ['./market-reservation.component.less'],
})
export class MarketReservationComponent implements OnInit, OnDestroy {
  form$: Observable<Quote>;
  quoteFlowSteps;
  quoteFlowUrl = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private localStorageService: LocalStorageService,
    private policyDashboardService: PolicyDashboardService,
    private alertService: AlertService,
  ) {}
  redirectPath: string = null;
  isReferral: boolean = false;
  userID: number;
  showBodySpinner: boolean = false;

  ngOnInit(): void {
    this.form$ = this.store.pipe(select(getQuoteSelector));
    this.userID = this.localStorageService.getBoxxUserId();
    this.getRedirectPath();
    this.quoteFlowSteps = this.localStorageService.getQuoteFlowSteps();
    if (this.quoteFlowSteps === '7') {
      this.quoteFlowUrl = 'quote';
    } else {
      this.quoteFlowUrl = 'quote4flow';
    }
  }

  ngOnDestroy(): void {
    this.alertService.clearAlerts(-1);
  }

  handleBackBtn() {
    this.router.navigate([`/dashboard/${this.quoteFlowUrl}/new`], {
      relativeTo: this.activatedRoute,
      skipLocationChange: true,
    });
  }

  getRedirectPath() {
    this.showBodySpinner = true;
    let insuredId;
    this.form$.subscribe((event) => (insuredId = event.ui.insuredId));
    this.policyDashboardService
      .GetExistingPoliciesByInsuredId(insuredId, 'Quote')
      .subscribe(async (data) => {
        const quoteStatus =
          data.data[0]?.policyRiskTrxes.quoteStatus.description;
        const quoteID = data?.data[0]?.policy?.id;
        const policyPeriodId = data.data[0]?.policyPeriod?.id;
        this.store.dispatch(
          new CreateQuoteAction.setPolicyPeriodId(policyPeriodId),
        );
        let brokerageCommission =
          data?.data[0]?.policyRiskTrxes?.['policyRisk'][0]
            ?.brokerCommissionPerc ?? 0;
        brokerageCommission = brokerageCommission * 100;

        if (quoteStatus.toLowerCase() == 'submission' && policyPeriodId) {
          // case 1: Quote status is “Submission” - we should take the users to Product risk form screen (/dashboard/quote/new/product/form)
          this.redirectPath =
            `/dashboard/${this.quoteFlowUrl}/new/product/form/` + quoteID;
          this.showBodySpinner = false;
        } else if (isStatusDisabled(quoteStatus)) {
          //Checking whether the quote status included in disabled statuses for blocking user to edit the fields,
          this.redirectPath = `/dashboard/${this.quoteFlowUrl}/` + quoteID;
          this.showBodySpinner = false;
        } else if (brokerageCommission === 0) {
          //Checking whether the commission is zero or not,
          this.redirectPath =
            `/dashboard/${this.quoteFlowUrl}/new/product/form/` + quoteID;
          this.showBodySpinner = false;
        } else if (quoteStatus.toLowerCase() == 'quoted' && quoteID) {
          // case 2: Quote status is “Quoted” - users should be navigated to Quote lifecycle screen (dashboard/quote/<quote id>)
          this.redirectPath = `/dashboard/${this.quoteFlowUrl}/` + quoteID;
          this.showBodySpinner = false;
        } else if (quoteStatus.toLowerCase() == 'referral' && quoteID) {
          // case 3: Quote status is “Referral” - we should take the users to Quote lifecycle screen (dashboard/quote/<quote id>)
          this.isReferral = true;
          this.redirectPath = `/dashboard/${this.quoteFlowUrl}/` + quoteID;
          this.showBodySpinner = false;
        } else {
          this.redirectPath = null;
          this.showBodySpinner = false;
        }
      });
  }
}
