import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  FINANCIAL_COVERAGE,
  FINANCIAL_COVERAGE_LIMIT,
  FINANCIAL_COVERAGE_RULE_MIN_DEDUCTIBLE,
} from 'src/app/constants/quote-constant';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { getLifecycleState } from 'src/app/store/lifecycle/lifecycle.select';
import { formatAmountWithCurrency } from 'src/app/utils/utils';

@Component({
  selector: 'app-scrollable-table',
  templateUrl: './scrollable-table.component.html',
  styleUrls: ['./scrollable-table.component.less'],
})
export class ScrollableTableComponent implements OnInit {
  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  @Input() data: Array<object>;
  @Input() form;
  @Input() product;
  @Input() limitOptionsForProducts;
  @Input() occurrenceTypeDropdownValues;
  @Input() coverageDeductibles;
  @Input() measuresDropdownValues;
  @Input() showAsReadOnly = false;
  @Input() isSubLimitDisabled = false;
  @Input() currentQuoteOptionDeductible;
  @Input() currentCoverageName = '';
  @Input() currency;
  @Input() quoteOptionLimit;
  @Input() quoteOptionDeductible;
  @Input() sublimitOptionsForFinancialCoverage;

  @Output() handleRowEditSave = new EventEmitter<any>();
  @Output() disableSaveAndContinue = new EventEmitter<any>();
  @Output() enableSaveAndContinue = new EventEmitter<any>();

  constructor(private store: Store) {}

  tableHeaders;
  scrollToRight = true;
  isExpanded: Array<boolean>;
  isScrollable = true;
  coverageEditable: boolean = false;
  showLimitCustomInput = false;
  showDeductibleCustomField = false;
  initialForm;

  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  currentQuoteStatus: string = '';

  financialCoverageMatchString = FINANCIAL_COVERAGE;
  financialCoverageLimit = FINANCIAL_COVERAGE_LIMIT;
  financialCoverageMinDeductible = FINANCIAL_COVERAGE_RULE_MIN_DEDUCTIBLE;

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
    });

    this.store.pipe(select(getLifecycleState)).subscribe((data) => {
      this.currentQuoteStatus = data.quoteStatus;
    });

    this.isExpanded = Array(this.data?.length).fill(false);
    if (!this.tableHeaders) {
      this.tableHeaders = Object.keys(this.data[0]);
    }
  }

  scrollRight() {
    const container: HTMLElement = this.scrollContainer.nativeElement;
    if (this.scrollToRight) {
      container.scrollLeft += 640;
    } else {
      container.scrollLeft -= 640;
    }
    this.scrollToRight = !this.scrollToRight;
  }
  initForm(rowIndex) {
    const initialFormValue = this.data[rowIndex];
    this.form.patchValue({
      sublimitAmount: initialFormValue['Sublimit'],
      occurenceType: initialFormValue['Occurrence type'],
      retentionAmount: initialFormValue['Deductibles'],
      occurenceTypeDeductible: initialFormValue['Occurrence type '],
      waitingPeriod: initialFormValue['Waiting period'] ?? null,
      waitingPeriodMeasure: initialFormValue['Waiting period measure'] ?? null,
      indemnityPeriod: initialFormValue['Indemnity period'],
      indemnityPeriodMeasure: initialFormValue['Indemnity period measure'],
      included: initialFormValue['included'],
    });
  }

  handleEdit(rowIndex) {
    this.initialForm = this.form.value;
    this.initForm(rowIndex);
    if (!this.scrollToRight) {
      const container: HTMLElement = this.scrollContainer.nativeElement;
      container.scrollLeft -= 690;
    }
    this.isExpanded = this.isExpanded.map((value) => false);
    this.isExpanded[rowIndex] = true;
    this.isScrollable = false;
    this.disableSaveAndContinue.emit();
  }

  handleEditSave(index) {
    this.isScrollable = true;
    this.isExpanded[index] = !this.isExpanded[index];
    this.scrollToRight = true;
    this.handleRowEditSave.emit({ form: this.form.value, index });
  }
  handleEditCancel(index) {
    this.isScrollable = true;
    this.scrollToRight = true;
    this.isExpanded[index] = !this.isExpanded[index];
    this.showLimitCustomInput = false;
    this.showDeductibleCustomField = false;
    this.form.patchValue(this.initialForm);
    this.enableSaveAndContinue.emit();
  }

  handleSublimitChange(value) {
    if (value == 'custom-amount') {
      this.showLimitCustomInput = true;
    } else {
      this.showLimitCustomInput = false;
      this.form.get('sublimitAmount').setValue(value);
    }
  }

  handleDeductibleChange(value, isFinancialCoverage = false) {
    if (value == 'custom-amount') {
      this.showDeductibleCustomField = true;
    } else {
      this.form.get('retentionAmount').setValue(value);
      if (
        isFinancialCoverage &&
        Number(value) < this.financialCoverageMinDeductible
      ) {
        this.form.get('sublimitAmount').setValue(this.financialCoverageLimit);
      }
    }
  }

  handleCustomDeductible(value, isFinancialCoverage) {
    if (
      isFinancialCoverage &&
      Number(value) < this.financialCoverageMinDeductible
    ) {
      this.form.get('sublimitAmount').setValue(this.financialCoverageLimit);
    }
  }

  handleWPMeasureChange(value) {
    this.form.get('waitingPeriodMeasure').setValue(value);
  }

  handleIPMeasureChange(value) {
    this.form.get('indemnityPeriodMeasure').setValue(value);
  }

  isNumber(number) {
    return !isNaN(number);
  }

  handleValueChange(value, control) {
    this.form.get(control).setValue(value);
  }

  handleSublimitIncludeToggle(value, rowIndex) {
    this.initialForm = this.form.value;
    const initialFormValue = this.data[rowIndex];
    if (initialFormValue['isReadOnly']) {
      return;
    }

    if (!value) {
      this.form.patchValue({
        sublimitAmount: '0',
        occurenceType: initialFormValue['Occurrence type'],
        retentionAmount: '0',
        occurenceTypeDeductible: initialFormValue['Occurrence type '],
        waitingPeriod: initialFormValue['Waiting period'] ?? null,
        waitingPeriodMeasure:
          initialFormValue['Waiting period measure'] ?? null,
        indemnityPeriod: initialFormValue['Indemnity period'],
        indemnityPeriodMeasure: initialFormValue['Indemnity period measure'],
        included: value,
      });
    } else {
      let limitToUpdate = 0;
      let deductibleToUpdate = 0;
      if (
        initialFormValue['allowOverwriteDefault'] &&
        !this.currentCoverageName
          ?.toLowerCase()
          ?.includes(this.financialCoverageMatchString)
      ) {
        if (initialFormValue['factorForLimit'] === 0) {
          limitToUpdate = this.quoteOptionLimit;
        } else {
          limitToUpdate =
            this.quoteOptionLimit * initialFormValue['factorForLimit'];
        }
        deductibleToUpdate = this.quoteOptionDeductible;
      } else if (
        this.currentCoverageName
          ?.toLowerCase()
          ?.includes(this.financialCoverageMatchString)
      ) {
        limitToUpdate = this.financialCoverageLimit;
        deductibleToUpdate = initialFormValue['defaultDeductible'];
      } else {
        limitToUpdate = initialFormValue['defaultLimit'];
        deductibleToUpdate = initialFormValue['defaultDeductible'];
      }

      this.form.patchValue({
        sublimitAmount: limitToUpdate,
        occurenceType: initialFormValue['Occurrence type'],
        retentionAmount: deductibleToUpdate,
        occurenceTypeDeductible: initialFormValue['Occurrence type '],
        waitingPeriod: initialFormValue['Waiting period'] ?? null,
        waitingPeriodMeasure:
          initialFormValue['Waiting period measure'] ?? null,
        indemnityPeriod: initialFormValue['Indemnity period'],
        indemnityPeriodMeasure: initialFormValue['Indemnity period measure'],
        included: value,
      });
    }
    this.handleRowEditSave.emit({ form: this.form.value, index: rowIndex });
  }
}
