<div class="custom-modal" *ngIf="showModal">
  <div class="modal-content">
    <div class="close-btn">
      <button (click)="closeHandler()">
        <img src="assets/img/dashboard/card/close.png" />Close
      </button>
    </div>
    <div class="content">
      <div class="d-flex flex-column align-items-center">
        <img
          src="assets/img/alert-triangle.jpg"
          width="66px"
          height="66px"
          alt="alert"
        />
        <boxxds-body
          sz="md"
          [text]="'Re-instate confirmation: Action can be undone'"
          [customStyle]="{ color: '#FE802C', marginTop: '12px' }"
        ></boxxds-body>
      </div>
      <div class="policy-details d-flex flex-column mt-1">
        <boxxds-body
          sz="md"
          [text]="details.id"
          [customStyle]="{ color: '#616162', marginTop: '20px' }"
        ></boxxds-body>
        <div class="d-flex mt-1">
          <boxxds-body
            sz="xs"
            [text]="'Policy Period:'"
            [customStyle]="{
              color: '#616162',
              marginRight: '4px',
              lineHeight: '14px'
            }"
          ></boxxds-body>
          <boxxds-body
            sz="xs"
            [text]="details.period"
            [customStyle]="{ color: '#616162', lineHeight: '14px' }"
          ></boxxds-body>
        </div>
        <div class="d-flex mt-1">
          <boxxds-body
            sz="xs"
            [text]="'Insured:'"
            [customStyle]="{
              color: '#616162',
              marginRight: '4px',
              lineHeight: '14px'
            }"
          ></boxxds-body>
          <boxxds-body
            sz="xs"
            [text]="details.insured"
            [customStyle]="{ color: '#616162', lineHeight: '14px' }"
          ></boxxds-body>
        </div>
        <div class="d-flex mt-1">
          <boxxds-body
            sz="xs"
            [text]="details.brokerage + ', '"
            [customStyle]="{
              color: '#616162',
              marginRight: '4px',
              lineHeight: '14px'
            }"
          ></boxxds-body>
          <boxxds-body
            sz="xs"
            [text]="details.branch"
            [customStyle]="{ color: '#616162', lineHeight: '14px' }"
          ></boxxds-body>
        </div>
      </div>
      <form
        [formGroup]="reinstatePolicyForm"
        (ngSubmit)="handleReinstate($event)"
      >
        <div class="d-flex">
          <div class="flex-grow-1">
            <boxxds-body
              sz="sm"
              [text]="'Reason for re-instate'"
              [customStyle]="{ color: '#333334' }"
            ></boxxds-body>
            <boxxds-dropdown
              [form]="reinstatePolicyForm"
              [control]="'reason'"
              [placeholder]="'Select'"
              [options]="details.reasonOptions"
              [dropdownContainerStyle]="{ maxWidth: '250px', marginTop: '8px' }"
              (currentValueChange)="reasonHandler($event)"
            ></boxxds-dropdown>
          </div>
        </div>
        <boxxds-button
          buttonText="Re-instate"
          [type]="'submit'"
          [btnStyle]="{ width: '121px', marginLeft: 'auto', marginTop: '40px' }"
          [isDisabled]="buttonDisabled"
        >
        </boxxds-button>
      </form>
    </div>
  </div>
</div>
