<div class="holder">
  <boxxds-breadcrumb [items]="itemsMenu"></boxxds-breadcrumb>
  <boxxds-heading
    sz="5"
    [ngStyle]="{ marginBottom: '50px' }"
    [text]="'Adding system user' | translate"
  >
  </boxxds-heading>
  <boxxds-body
    sz="lg"
    [text]="'Select system user type' | translate"
    [customStyle]="{
      color: '#616162',
      textAlign: 'left',
      marginBottom: '8px',
      fontSize: '14px'
    }"
  ></boxxds-body>
  <boxxds-dropdown
    [insideSearch]="false"
    [options]="dropdownOptions"
    placeholder=""
    (currentValueChange)="dropdownValueChanged($event)"
    [dropdownContainerStyle]="{ width: '176px' }"
  >
  </boxxds-dropdown>
  <div
    *ngIf="(form$ | async)?.ui.isInsuredTypeCompany"
    class="company-container"
  >
    <boxxds-body
      sz="lg"
      [text]="'BOXX user details' | translate"
      [customStyle]="{
        color: '#616162',
        textAlign: 'left',
        marginBottom: '8px',
        fontSize: '16px'
      }"
    ></boxxds-body>
    <div class="search-container">
      <form [formGroup]="form" [appConnectForm]="form$ | async">
        <div class="add-container">
          <div class="d-flex">
            <boxxds-dropdown
              [control]="'boxxUser'"
              [placeholder]="'Select' | translate"
              [options]="dropdownOptions"
              [label]="('Boxx user' | translate) + '*'"
              [btnStyle]="{ marginTop: '8px', width: '264px' }"
              [dropdownContainerStyle]="{ width: '264px', marginRight: '40px' }"
              class="employees"
              [requiredErrMsg]="requiredErrMsg"
              [formSubmitted]=""
            >
            </boxxds-dropdown>
            <boxxds-dropdown
              [control]="'role'"
              [placeholder]="'Select' | translate"
              [options]="roleOptions"
              [currentValue]=""
              (currentValueChange)="dropdownValueChanged($event)"
              [label]="('Role' | translate) + '*'"
              [btnStyle]="{ marginTop: '8px', width: '264px' }"
              [dropdownContainerStyle]="{ width: '264px' }"
              class="employees"
              [requiredErrMsg]="requiredErrMsg"
              [formSubmitted]=""
            >
            </boxxds-dropdown>
          </div>
          <div class="d-flex">
            <boxxds-textinput
              [placeholder]="'Login email' | translate"
              [control]="'email'"
              [id]="'email'"
              [width]="'466px'"
              [label]="('Login email' | translate) + '*'"
              [requiredErrMsg]="requiredErrMsg"
              [containerStyle]="{ marginRight: '40px', marginTop: '30px' }"
              [invalidErrMsg]="invalidErrMsg"
              [formSubmitted]=""
              maxLength="150"
            >
            </boxxds-textinput>
            <div class="reset-container">
              <boxxds-textinput
                [placeholder]="'Password' | translate"
                [control]="'role'"
                [type]="'password'"
                [id]="'password'"
                [width]="'100%'"
                [label]="'Password' | translate"
                maxLength="200"
                [containerStyle]="{ marginTop: '30px', marginBottom: '10px' }"
              >
              </boxxds-textinput>
              <a class="reset-link" href="#" tabindex="0">Reset password?</a>
            </div>
          </div>
        </div>
        <div class="btn-container">
          <boxxds-button
            [buttonText]="'Add member' | translate"
            btnType="secondary"
            [btnStyle]="{
              border: '1px solid #FFBE3D',
              bordeRadius: '8px',
              padding: '0 30px 0 30px'
            }"
            type="submit"
            (click)="addUser($event)"
          >
          </boxxds-button>
        </div>
      </form>
    </div>
  </div>
  <app-timestamp
    label="CREATED BY,"
    value="Deepti Bhawarthi, Nov 02,2023, 11:28AM"
    [containerStyle]="{
      marginLeft: '10px',
      position: 'relative',
      top: '-8px',
      marginTop: '150px'
    }"
  ></app-timestamp>
</div>
