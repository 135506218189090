import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Brokerage } from '../entities/brokerage';
import { environment } from 'src/environments/environment';
import { BoxxResponse } from '../entities/boxx-response';
import {
  BrokerageProducer,
  BrokerageProducerProfile,
} from '../entities/brokerage-producer';

@Injectable({
  providedIn: 'root',
})
export class BrokerageProducerService {
  // Base url
  baseurl = environment.msBrokerBaseUrl + environment.brokerageProducerPathUrl;
  constructor(private http: HttpClient) {}
  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  private producerId = new BehaviorSubject<number>(0);
  prodId$ = this.producerId.asObservable();

  // POST
  CreateBrokerageProducer(data): Observable<BrokerageProducer> {
    return this.http
      .post<BrokerageProducer>(
        this.baseurl,
        JSON.stringify(data),
        this.httpOptions,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  CreateAtomicBrokerageProducer(data): Observable<any> {
    return this.http
      .post<BoxxResponse<any>>(
        `${environment.msBrokerBaseUrl}${environment.brokerageProducerAtomicTrxPathUrl}`,
        JSON.stringify(data),
        this.httpOptions,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  // GET
  GetBrokerageProducer(id): Observable<BoxxResponse<BrokerageProducer>> {
    return this.http
      .get<BoxxResponse<BrokerageProducer>>(this.baseurl + id)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  // GET ALL
  GetBrokerageProducers(
    branchId,
    page = 1,
    limit = 10,
    sort = '',
  ): Observable<BoxxResponse<BrokerageProducer>> {
    return this.http
      .get<BoxxResponse<BrokerageProducer>>(
        this.baseurl +
          '?brokerageBranchId=' +
          branchId +
          '&page=' +
          page +
          '&limit=' +
          limit +
          '&sort=' +
          sort,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  // GET ALL
  GetBrokerageProducerId(
    brokerageProducerId,
  ): Observable<BoxxResponse<BrokerageProducer>> {
    return this.http
      .get<BoxxResponse<BrokerageProducer>>(this.baseurl + brokerageProducerId)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetByBranchId(
    brokerageBranchId,
    page = 1,
    limit = 10,
    sort = '',
    active = null,
  ): Observable<BoxxResponse<BrokerageProducer>> {
    let url =
      this.baseurl +
      '?brokerageBranchId=' +
      brokerageBranchId +
      '&page=' +
      page +
      '&limit=' +
      limit +
      '&sort=' +
      sort;
    if (active) {
      url += '&active=' + active;
    }
    return this.http
      .get<BoxxResponse<BrokerageProducer>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  updateProducerProfile(
    brokerageProducerProfileId: number,
    headShotImage: string,
  ): Observable<BoxxResponse<any>> {
    const url = `${environment.msBrokerBaseUrl}${environment.brokerageProducerProfile}${brokerageProducerProfileId}`;
    return this.http.put<BoxxResponse<any>>(url, { headShotImage });
  }
  createProducerProfile(
    brokerageProducerId: number,
    headShotImage: string,
  ): Observable<BoxxResponse<any>> {
    const url = `${environment.msBrokerBaseUrl}${environment.brokerageProducerProfile}`;
    return this.http.post<BoxxResponse<any>>(url, {
      brokerageProducerId,
      headShotImage,
    });
  }

  // PUT
  UpdateBrokerageProducer(id, data): Observable<BrokerageProducer> {
    return this.http
      .put<BrokerageProducer>(
        this.baseurl + id,
        JSON.stringify(data),
        this.httpOptions,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }
  RestoreBrokerageProducer(id): Observable<BrokerageProducer> {
    return this.http
      .put<BrokerageProducer>(this.baseurl + 'restore/' + id, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  getProducerCommission(
    brokerageProducer: number,
  ): Observable<BoxxResponse<any>> {
    const url = `${environment.msBrokerBaseUrl}${environment.brokerageProducerCommissionPathUrl}?brokerageProducerId=${brokerageProducer}&page=1&limit=10`;
    return this.http.get<BoxxResponse<any>>(url);
  }

  GetProducerProfile(id): Observable<BoxxResponse<BrokerageProducerProfile>> {
    return this.http
      .get<BoxxResponse<BrokerageProducerProfile>>(
        `${environment.msBrokerBaseUrl}${environment.brokerageProducerProfile}${id}`,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }
  // DELETE
  DeleteBrokerageProducer(id) {
    return this.http
      .delete<BrokerageProducer>(this.baseurl + id, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  // Error handling
  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else if (error.status == 400) {
      errorMessage = error.error;
    } else if (error.status == 500) {
      errorMessage = error.error;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => {
      return errorMessage;
    });
  }

  //GET
  GetBrokerageProducerStats(
    filter: {
      brokerageProducerBORId: number;
      brokerageBranchBORId: number;
      brokerageBORId: number;
    } = null,
  ): Observable<BoxxResponse<BrokerageProducerProfile>> {
    let queryParams = '';
    if (filter !== null) {
      queryParams =
        '?' +
        Object.entries(filter)
          .map(
            ([key, value]) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
          )
          .join('&');
    }
    return this.http
      .get<BoxxResponse<BrokerageProducerProfile>>(
        `${environment.msBrokerBaseUrl}${environment.brokerageProducerStatusPathUrl}${queryParams}`,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }
  // Filter
  GetFilterBrokerageProducers(
    brokerageBranchId,
    fullName,
    active,
    page = 1,
    limit = 10,
    sort = '',
  ): Observable<BoxxResponse<BrokerageProducer>> {
    return this.http
      .get<BoxxResponse<BrokerageProducer>>(
        this.baseurl +
          '?brokerageBranchId=' +
          brokerageBranchId +
          '&fullName=' +
          fullName +
          '&active=' +
          active +
          '&page=' +
          page +
          '&limit=' +
          limit +
          '&sort=' +
          sort,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  //Export producer
  ExportBrokerageProducer(
    page = 1,
    limit = 10,
    sort = '',
    active = '',
    fullName = '',
    brokerageBranchId?,
  ): Observable<ArrayBuffer> {
    let url = environment.msBrokerBaseUrl + environment.brokerageProducerExport;

    return this.http
      .get(
        url +
          '?page=' +
          page +
          '&limit=' +
          limit +
          '&sort=' +
          sort +
          '&active=' +
          active +
          '&fullName=' +
          fullName +
          '&brokerageBranchId=' +
          brokerageBranchId,
        { responseType: 'arraybuffer' },
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  setProducerId(id: number) {
    this.producerId.next(id);
  }
}
