import { PolicyRiskService } from 'src/app/services/policy-risk.service';
import { PolicyService } from 'src/app/services/policy.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomainsService } from 'src/app/services/domains.service';
import { combineLatest, firstValueFrom, lastValueFrom, take } from 'rxjs';
import {
  formatDate,
  getDateFormatForDatePicker,
  getFormattedDateTime,
  getFormatDate,
} from 'src/app/utils/formatDate';
import { PolicyPeriodService } from 'src/app/services/policy-period.service';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import { DropdownListDto } from 'src/app/dtos/dropdownList.dto';
import { BoxxUser } from 'src/app/entities/boxx-user';
import { PolicyLifecycleService } from 'src/app/services/policy-lifecycle-service';
import { TransactionRiskActions } from '../constants/lifecycle-actions';
import {
  PolicyDeclineRequest,
  PolicyCloseRequest,
  PolicyReferRequest,
} from 'src/app/entities/policy-lifecycle';
import {
  setPolicyRiskTrxIdAction,
  setPolicyRiskTrxStatusAction,
  setQuoteStatus,
} from 'src/app/store/lifecycle/lifecycle.action';
import * as objects from '../policy-lifecycle/objects/policy-lifecycle-objects';
import * as utils from '../utils/lifecycle-utils';
import { Store, select } from '@ngrx/store';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { TranslateService } from '@ngx-translate/core';
import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import {
  removeDuplicatedKeys,
  getErrorMessage,
  getAlertHead,
} from 'src/app/utils/utils';
import { PolicyRiskTrxService } from 'src/app/services/policy-risk-trx-service';
import { STANDALONE_SELLABLE_TRUE } from 'src/app/constants/quote-constant';
import { BrokerageBranchService } from 'src/app/services/brokerage-branch.service';
import { PolicyDashboardService } from 'src/app/services/policy-dashboard.service';
import { InsuredService } from 'src/app/services/insured.service';
import { LocationService } from 'src/app/services/location.service';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import { LanguageService } from 'src/app/services/language.service';
import { RiskQuestionService } from 'src/app/services/risk-question.service';
import { PolicyAnswerService } from 'src/app/services/policy-answer.service';
import { AlertService } from 'src/app/services/alert.service';
import { NewQuoteService } from 'src/app/services/new-quote.service';

@Component({
  selector: 'app-quote-lifecycle',
  templateUrl: './quote-lifecycle.component.html',
  styleUrls: ['./quote-lifecycle.component.less'],
})
export class QuoteLifecycleComponent implements OnInit, OnDestroy {
  private policyId: string;
  private userId: number;
  private policyStatus: DropdownListDto[];
  private quoteStatus: DropdownListDto[];
  private riskTrkType: DropdownListDto[];

  public transactionRiskActions = TransactionRiskActions;
  hasError: boolean = false;
  errorMessage: string = '';
  hasSuccess: boolean = false;
  successMessage: string = '';
  transactionType: string = '';
  reasonDetails: string = '';

  isTileExpanded: boolean = true;
  showActionPopup: boolean = false;
  showSlideOut: boolean = false;
  isSlideOutEnabled: boolean = false;

  dataFromAPI = [];
  options = [];
  subNavData = [];
  policyPeriodId: any;
  details: objects.PolicyDetail;
  showBodySpinner: boolean = false;
  insuredData = {};
  actionPopupDetails: {
    quoteNumber: string;
    effectiveDate: string;
    insured: string;
    brokerage: string;
    branch: string;
    reasonOptions: any[];
    action: TransactionRiskActions;
  };

  currency = '';
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  policyRiskId;
  currentProductDetails: any = {};
  currentQuoteStatus: string = '';
  isQuote4Flow: boolean = false;
  isWorkFlow3: boolean = false;
  shortDateFormat: string = '';
  longDateFormat: string = '';
  quoteOptionList = [];
  regionId: any;
  effectiveDate: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private domainsService: DomainsService,
    private policyService: PolicyService,
    private policyRiskService: PolicyRiskService,
    private policyPeriodService: PolicyPeriodService,
    private policyLifecycleService: PolicyLifecycleService,
    private policyRiskTrxService: PolicyRiskTrxService,
    private boxxUserService: BoxxUserService,
    private localStorageService: LocalStorageService,
    private store: Store,
    private translate: TranslateService,
    private brokerageBranchService: BrokerageBranchService,
    private policyDashboardService: PolicyDashboardService,
    private insuredService: InsuredService,
    public locationService: LocationService,
    private riskRegionService: RiskRegionService,
    private languageService: LanguageService,
    public riskQuestionService: RiskQuestionService,
    private policyAnswerService: PolicyAnswerService,
    private alertService: AlertService,
    private newQuoteService: NewQuoteService,
  ) {}

  async ngOnInit(): Promise<void> {
    let alertData;
    const closedAlertStatus = this.newQuoteService.getClosedAlertStatus();
    if (closedAlertStatus) {
      alertData = {
        type: 'success',
        headerText: getAlertHead('success'),
        bodyText: this.translate.instant('policy.declined.success.body'),
      };
      this.newQuoteService.setClosedAlertStatus(false);
    }

    this.showBodySpinner = true;
    this.policyId = this.activatedRoute.snapshot.paramMap.get('id');

    this.store.pipe(select(getDashboardSelector)).subscribe((data: any) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.isQuote4Flow =
        this.localStorageService.getQuoteFlowSteps() === '4' ? true : false;
      this.isWorkFlow3 =
        this.localStorageService.getQuoteFlowSteps() === 'workflow3';
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    if (this.policyId) {
      await this.getPolicyData(this.policyId);
    }
    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));
    this.userId = this.localStorageService.getBoxxUserId();

    this.intiValues();

    await this.populateDomainLists();

    await this.populateData();
    if (alertData) {
      this.alertService.addAlert(alertData);
    }
  }

  ngOnDestroy(): void {
    this.alertService.clearAlerts(-1);
  }

  /**
   * Pre-populate the variables so the HTML will not complain
   */
  private intiValues() {
    this.details = {
      header: '',
      currentPeriod: '',
      id: '',
      policyRiskTrxId: 0,
      latestPolicyRiskTrxId: 0,
      policyRiskTrxIds: '',
      userId: 0,
      status: '',
      brokerage: '',
      branch: '',
      producer: '',
      firstBindDate: '',
      policyPeriod: '',
      insuredName: '',
      isUnreadNotesPresent: false,
      isInvoiced: false,
      product: '',
      active: false,
      currentTagOptions: [],
      insuredId: 0,
      policyRiskId: 0,
      riskId: 1,
    };

    this.dataFromAPI = [
      {
        risk: '',
        quoteId: '',
        limit: '',
        premium: '',
        deductible: '',
        invoiceNumber: '',
        trnxDate: '',
        underwriter: '',
        annualPremium: '',
        status: '',
        reason: '',
        trxReasons: [],
      },
    ];
  }

  /**
   * Populate lists used for the drop downs or lookups
   */
  private async populateDomainLists() {
    try {
      this.policyStatus =
        await this.domainsService.GetDomainListAsync('POLICYSTATUS');
      this.quoteStatus =
        await this.domainsService.GetDomainListAsync('QUOTESTATUS');
      this.riskTrkType =
        await this.domainsService.GetDomainListAsync('POLICYRISKTRXTYPE');
    } catch (error) {
      this.handleApiError(error);
    }
  }

  /**
   * Make the required API calls to fetch the quote data
   */
  private async populateData() {
    this.showBodySpinner = true;
    const policy$ = this.policyService.GetByPolicyId(this.policyId);
    const risk$ = this.policyRiskService.GetByPolicyId(
      this.policyId,
      1,
      10,
      '',
    );
    const peroid$ = this.policyPeriodService.GetAllByPolicyId(this.policyId);

    combineLatest([policy$, risk$, peroid$]).subscribe({
      next: ([policy, risk, period]) => {
        this.populateDataFromApi(policy.data, risk.data[0], period.data);
      },
      error: (error) => {
        this.handleApiError(error);
        this.showBodySpinner = false;
      },
    });
  }

  private async populateDataFromApi(policy: any, risk: any, peroid: any) {
    const status = this.getPolicyStatus(policy.policyStatus);
    const firstBindDate = policy.firstBindDt
      ? getFormattedDateTime(policy.firstBindDt, this.longDateFormat)
      : 'n/a';
    const policyPeriod = risk?.policyPeriod?.effectiveExpiryDatesTBDFlag
      ? 'TBD'
      : utils.getRiskPeriod(risk, this.longDateFormat);
    this.effectiveDate = policyPeriod;
    const policyId = utils.getPolicyNumber(policy);
    const insuredName = utils.getInsuredName(policy.insured);
    this.currentQuoteStatus = status !== 'n/a' ? status.toLowerCase() : '';
    this.store.dispatch(new setQuoteStatus(this.currentQuoteStatus));
    const insuredId = policy?.insured?.id;

    let mailIdList = {
      broker: policy?.brokerageBOR?.email ?? '',
      branch: policy?.brokerageBranchBOR?.email ?? '',
      producer: policy?.brokerageProducerBOR?.email ?? '',
      insured: policy?.insured?.email ?? '',
    };
    this.store.dispatch(new CreateQuoteAction.setAllMailId(mailIdList));

    this.store.dispatch(
      new CreateQuoteAction.setPolicyPeriodId(risk?.policyPeriodId),
    );
    this.policyPeriodId = risk?.policyPeriodId;
    const brokerData = {
      branch: policy?.brokerageBranchBOR?.name,
      broker: policy?.brokerageBOR?.name,
      producer: `${policy?.brokerageProducerBOR?.firstName} ${policy?.brokerageProducerBOR?.lastName}`,
    };
    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({ ...brokerData }),
    );
    this.store.dispatch(
      new CreateQuoteAction.ResetIsNavigatedFromLifecycle(true),
    );

    this.details = {
      header: status,
      currentPeriod: this.getPeroid(risk.policyPeriod),
      id: policyId,
      policyRiskTrxId: 0,
      latestPolicyRiskTrxId: 0,
      policyRiskTrxIds: '',
      userId: this.userId,
      status: status,
      brokerage: policy.brokerageBOR.name,
      branch: policy.brokerageBranchBOR.name,
      producer: `${policy.brokerageProducerBOR.firstName} ${policy.brokerageProducerBOR.lastName}`,
      firstBindDate: firstBindDate,
      policyPeriod: policyPeriod,
      insuredName: insuredName,
      isUnreadNotesPresent: false, // TODO: If notes are created between today and 3 days ago = true
      isInvoiced: false,
      product: risk.risk.name,
      active: risk.risk.active,
      currentTagOptions: [],
      policyPeriodId: this.policyPeriodId,
      insuredId: insuredId,
      policyRiskId: this.policyRiskId,
      riskId: risk?.riskId,
    };

    // Policy Level Actions
    this.actionPopupDetails = {
      quoteNumber: policyId,
      effectiveDate: formatDate(
        risk.policyPeriod.effectiveDt,
        this.longDateFormat,
      ),
      insured: insuredName,
      brokerage: policy.brokerageBOR.name,
      branch: policy.brokerageBranchBOR.name,
      reasonOptions: [],
      action: TransactionRiskActions.None,
    };

    const latestTransactionTypeIndex = risk.PolicyRiskTrxes.length - 1;
    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        transactionType:
          risk.PolicyRiskTrxes[latestTransactionTypeIndex].policyRiskTrxType
            .description,
        transactionTypeId: Number(
          risk.PolicyRiskTrxes[latestTransactionTypeIndex].type,
        ),
      }),
    );
    this.quoteOptionList =
      risk.PolicyRiskTrxes[latestTransactionTypeIndex].QuoteOptions;

    this.dataFromAPI = risk.PolicyRiskTrxes.map((trx) => ({
      risk: risk.risk.name,
      active: risk.risk.active,
      quoteId: policyId,
      quoteOption:
        risk.PolicyRiskTrxes[latestTransactionTypeIndex].QuoteOptions,
      policyRiskTrxId: trx.id,
      limit: trx.limit,
      premium: trx.premium,
      deductible: trx.deductible,
      invoiceNumber: trx.invoiceNumber,
      trnxDate: formatDate(policy.createdDt, this.longDateFormat),
      underwriter: trx.underwriterId,
      annualPremium: utils.calculateAnnualAmount(trx),
      status: this.getQuoteOrRiskTrxStatus(trx),
      reason: trx.notes,
      trxReasons: trx?.PolicyRiskTrxReasons,
    }));
    let startDate = formatDate(
      risk.policyPeriod.effectiveDt,
      this.longDateFormat,
    );
    let endDate = formatDate(risk.policyPeriod.expiryDt, this.longDateFormat);
    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        annualPremium: this.dataFromAPI?.[0]?.['annualPremium'] ?? null,
        deductible: this.dataFromAPI?.[0]?.['deductible'] ?? null,
        premium: this.dataFromAPI?.[0]?.['premium'] ?? null,
        startDate:
          getFormatDate(startDate, this.longDateFormat, this.shortDateFormat) ??
          '',
        endDate:
          getFormatDate(endDate, this.longDateFormat, this.shortDateFormat) ??
          '',
        quoteCreatedDate: peroid[0]?.createdDt ?? '',
      }),
    );

    for await (const item of this.dataFromAPI) {
      item.underwriter = await this.getUnderwriter(item.underwriter);
    }

    // Populate Period drop down
    this.options = peroid.map((prd) => ({
      key: this.getPeroid(prd),
      value: this.getPeroid(prd),
    }));

    // Populate Tabs
    this.subNavData = this.dataFromAPI.map((product, index) => ({
      name: product.risk,
      policyRiskTrxId: product.policyRiskTrxId,
      active: index == 0,
    }));

    this.details.policyRiskTrxId = this.dataFromAPI[0].policyRiskTrxId;
    this.details.latestPolicyRiskTrxId = this.dataFromAPI[0].policyRiskTrxId;
    this.details.policyRiskTrxIds = risk.PolicyRiskTrxes.map((p) => p.id).join(
      ',',
    );
    const policyRiskTrxs = await this.getPolicyRiskTrxesFromApi(risk.id);

    let trxTypes = policyRiskTrxs.data?.map((dataObj) => ({
      key: dataObj.type,
      value: dataObj.type,
      id: dataObj.id,
    }));

    trxTypes = removeDuplicatedKeys(trxTypes);

    this.details.currentTagOptions = trxTypes;
    this.transactionType = this.dataFromAPI[0].status.toLowerCase();
    this.showBodySpinner = false;
    await this.getPdtDetails();
  }

  private async getPolicyRiskTrxesFromApi(policyRiskId: number): Promise<any> {
    const riskTrx$ = this.policyRiskTrxService.GetByPolicyRiskId(policyRiskId);
    const riskTrx = await lastValueFrom(riskTrx$);

    return riskTrx;
  }

  private async getBoxxUserFromApi(userId: number): Promise<BoxxUser> {
    try {
      const user$ = this.boxxUserService.GetById(userId);
      const user = await lastValueFrom(user$);
      return user.data;
    } catch (error) {
      return null;
    }
  }

  private async getUnderwriter(id: number): Promise<string> {
    const user: any = await this.getBoxxUserFromApi(id);
    if (user) {
      this.store.dispatch(
        new CreateQuoteAction.setAllMailId({
          underwriter: user?.systemUser?.loginEmail
            ? [user.systemUser.loginEmail]
            : [],
        }),
      );
      return `${user.firstName} ${user.lastName}`;
    }

    return Promise.resolve('n/a');
  }

  private getPolicyStatus(id: number): string {
    return utils.getValueFromList(this.policyStatus, id);
  }

  private getQuoteStatus(id: number): string {
    return utils.getValueFromList(this.quoteStatus, id);
  }

  private getRiskTrkType(id: number): string {
    return utils.getValueFromList(this.riskTrkType, id);
  }

  private getQuoteOrRiskTrxStatus(policyRiskTrx: any): string {
    return policyRiskTrx.quoteStatus
      ? this.getQuoteStatus(policyRiskTrx.quoteStatus)
      : this.getRiskTrkType(policyRiskTrx.type);
  }

  private getPeroid(period: any): string {
    if (period.period) {
      return period.period;
    }
    if (period.effectiveDt) {
      return new Date(period.effectiveDt).getFullYear().toString();
    }
    return 'n/a';
  }

  async handleNav(event, index) {
    const currentActiveIdx = this.getActiveTabIndex();
    this.subNavData[currentActiveIdx].active = false;
    this.subNavData[index].active = true;

    this.details.policyRiskTrxId = this.dataFromAPI[index].policyRiskTrxId;
    await this.getPdtDetails();
  }

  handleShowPeriod(value) {}

  async getPdtDetails() {
    const currentActiveIdx = this.getActiveTabIndex();
    this.currentProductDetails = this.dataFromAPI[currentActiveIdx];
  }

  private getActiveTabIndex(): number {
    return this.subNavData.findIndex((navObj) => navObj.active);
  }

  getStatusType(status) {
    return utils.convertStatusType(status);
  }

  showRiskLevelActions(status) {
    return (
      status.toLowerCase() == 'quoted' ||
      status.toLowerCase() == 'renewal quote' ||
      status.toLowerCase() == 'referral'
    );
  }

  showReason(status) {
    return (
      status.toLowerCase() == 'closed' ||
      status.toLowerCase() == 'declined' ||
      status.toLowerCase() == 'not taken up' ||
      status.toLowerCase() == 'referral'
    );
  }

  handleTileExpandCollapse() {
    this.isTileExpanded = !this.isTileExpanded;
  }

  handleRiskAction(action: TransactionRiskActions) {
    this.actionPopupDetails.action = action;
    this.showActionPopup = true;
  }

  handleConfirmRiskAction(event: {
    action: TransactionRiskActions;
    selectedReasonId: number;
  }) {
    const PolicyDeclineRequest: PolicyDeclineRequest = {
      policyPeriodId: Number(this.policyPeriodId),
      declineReason: Number(event.selectedReasonId),
    };
    const policyCloseRequest: PolicyCloseRequest = {
      policyPeriodId: Number(this.policyPeriodId),
      closeReason: Number(event.selectedReasonId),
    };
    const policyReferRequest: PolicyReferRequest = {
      policyPeriodId: Number(this.policyPeriodId),
      referralReason: Number(event.selectedReasonId),
    };
    switch (event.action) {
      case TransactionRiskActions.Close:
        this.policyLifecycleService
          .TransactionRiskClose(policyCloseRequest)
          .subscribe({
            next: (response) => {
              this.handleRiskActionApiResponse(
                TransactionRiskActions.Close,
                response,
              );
            },
            error: (e) => {
              this.handleApiError(e);
            },
            complete: () => {
              this.handleRiskActionComplete();
            },
          });
        break;
      case TransactionRiskActions.Decline:
        this.policyLifecycleService
          .TransactionRiskDecline(PolicyDeclineRequest)
          .subscribe({
            next: (response) => {
              this.handleRiskActionApiResponse(
                TransactionRiskActions.Decline,
                response,
              );
            },
            error: (e) => {
              this.handleApiError(e);
            },
            complete: () => {
              this.handleRiskActionComplete();
            },
          });
        break;
      case TransactionRiskActions.Refer:
        this.policyLifecycleService
          .TransactionRiskRefer(policyReferRequest)
          .subscribe({
            next: (response) => {
              this.handleRiskActionApiResponse(
                TransactionRiskActions.Refer,
                response,
              );
            },
            error: (e) => {
              this.handleApiError(e);
            },
            complete: () => {
              this.handleRiskActionComplete();
            },
          });
        break;
      default:
        const errorMessage = this.translate.instant('Invalid risk action.');
        this.handleApiError(errorMessage);
        this.showActionPopup = false;
        break;
    }
  }

  private handleRiskActionApiResponse(type, response: any) {
    this.showActionPopup = false;
    if (response && type === TransactionRiskActions.Close) {
      this.hasSuccess = true;
      this.successMessage = 'policy.closed.success.body';
    } else if (response && type === TransactionRiskActions.Decline) {
      this.hasSuccess = true;
      this.successMessage = 'policy.declined.success.body';
    } else if (response && type === TransactionRiskActions.Refer) {
      this.hasSuccess = true;
      this.successMessage = 'policy.referred.success.body';
    } else {
      this.hasError = true;
      this.errorMessage = this.translate.instant(
        'error.actionIsNotImplementedYet',
      );
    }
  }

  private handleRiskActionComplete() {
    this.showActionPopup = false;
    if (this.hasSuccess) {
      this.hasSuccess = true;
      this.hasError = false;
    } else {
      this.hasError = true;
      this.hasSuccess = false;
    }

    this.populateData();
  }

  handleEllipsisClick(id: any, status: any) {
    this.store.dispatch(new setPolicyRiskTrxIdAction(id));
    this.store.dispatch(new setPolicyRiskTrxStatusAction(status));
    this.showSlideOut = true;
  }

  handleSlideOutSave() {
    this.showSlideOut = false;
  }

  handleSlideOutClose() {
    this.showSlideOut = false;
  }

  async handleBind() {
    await this.getPdtDetails();
    let product = this.currentProductDetails;
    if (product?.['premium'] > 0) {
      if (this.isQuote4Flow) {
        this.router.navigate(['dashboard/quote4flow/policy-configuration'], {
          skipLocationChange: true,
        });
      } else if (this.isWorkFlow3) {
        this.router.navigate(['dashboard/workflow3/policy-configuration'], {
          skipLocationChange: true,
        });
      } else {
        this.router.navigate(['../summary/product/'], {
          relativeTo: this.activatedRoute,
          skipLocationChange: true,
        });
      }
    } else {
      if (this.isQuote4Flow) {
        this.router.navigate(
          ['dashboard/quote4flow/quote-calculator-and-options'],
          {
            skipLocationChange: true,
          },
        );
      } else if (this.isWorkFlow3) {
        this.router.navigate(['dashboard/workflow3/quoting'], {
          skipLocationChange: true,
        });
      } else {
        this.router.navigate(['../new/options/'], {
          relativeTo: this.activatedRoute,
          skipLocationChange: true,
        });
      }
    }
  }

  handleApiError(error: any) {
    if (![500].includes(error?.status)) {
      this.hasError = true;
    }

    if (typeof error === 'string' || error instanceof String) {
      if (error?.toLowerCase().includes('error code: 404', 0)) {
        this.errorMessage = this.translate.instant(
          'Requested quote not found.',
        );
        return;
      }
    }
    this.errorMessage = getErrorMessage(error);
  }

  handleCloseErrorEvent() {
    this.hasError = false;
    this.errorMessage = '';
  }
  handleCloseSuccessEvent() {
    this.hasSuccess = false;
    this.successMessage = '';
  }

  async getPolicyData(quoteID) {
    const getPolicyByID = this.policyPeriodService.GetAllByPolicyId(quoteID);
    let dataPId = await firstValueFrom(getPolicyByID);
    const policyPeriodId = dataPId.data[0].id;
    const policyDashboardServiceObs =
      this.policyDashboardService.Get(policyPeriodId);
    const policyDashboardData = await firstValueFrom(policyDashboardServiceObs);
    const insured = policyDashboardData.data.policy.insured;
    const insuredServiceObs = this.insuredService.Get(insured.id);
    let insuredData = await firstValueFrom(insuredServiceObs);
    const locationId = insuredData.data.insuredLocation?.id;
    const getLocation = this.locationService.Get(locationId);
    let locationData = await firstValueFrom(getLocation);
    const getLanguage = this.languageService.getLanguageId();
    let langData = await firstValueFrom(getLanguage);
    const languageId = langData.data[0]?.id;
    const regionId = locationData.data.region.id;
    this.regionId = locationData.data.region.id;

    const getRiskVersion = this.riskRegionService.getRiskRegionAndVersion(
      regionId,
      insured.insuredType,
      STANDALONE_SELLABLE_TRUE,
    );
    let riskRegion = await firstValueFrom(getRiskVersion);
    const policyRisks = policyDashboardData.data.PolicyRisks;
    let products = await this.makeProducts(riskRegion.data, policyRisks);
    //UPDATE STORE UI with PRODUCTS
    this.store.dispatch(
      CreateQuoteAction.loadProductsSuccess({ products: products }),
    );

    riskRegion?.data.forEach(async (riskRegion) => {
      const getQuestions = this.riskQuestionService.GetByRegionId(
        riskRegion.id,
        languageId,
        insured.id,
        500,
      );
      let dataQuestions = await firstValueFrom(getQuestions);
      const questionAnswers = dataQuestions?.data;

      const policyRiskData = policyDashboardData.data.PolicyRisks.filter(
        (policyRisk) => policyRisk.risk.id == riskRegion.risk.id,
      );
      if (policyRiskData.length > 0) {
        const policyRiskId = policyRiskData[0].id;
        this.policyRiskId = policyRiskId;
        const policyRiskValue = policyRiskData[0].risk.name;
        const policyQuestionList =
          this.policyAnswerService.GetAllByQuestionIdAndPolicyRiskId(
            policyRiskId,
            1,
            500,
          );
        let dataPolicyQuestionList = await firstValueFrom(policyQuestionList);
        const policyQuestionLists = dataPolicyQuestionList?.data;
        const selectedQuestions = questionAnswers.map((questions) => {
          const policyQuestionList = policyQuestionLists.find(
            (policyQuestions) =>
              policyQuestions.riskQuestionId === questions.id,
          );

          return {
            ...questions,
            value: policyQuestionList?.riskAnswer
              ? policyQuestionList?.riskAnswer
              : null,
          };
        });
        //UPDATE STORE UI with Question Answers
        this.store.dispatch(
          CreateQuoteAction.loadRiskQuestionsSuccess({
            riskQuestions: { [policyRiskValue]: selectedQuestions },
          }),
        );
      }
    });

    const getPolicyServiceObs = this.policyService.Get(quoteID);
    const policyData = await firstValueFrom(getPolicyServiceObs);
    const policyRiskTrxId =
      policyDashboardData.data.PolicyRisks[0].PolicyRiskTrxes[0].id;

    const brokerData = {
      branch: policyData.data.brokerageBranchBOR.name,
      broker: policyDashboardData.data.policy.brokerageBOR.name,
      producer: this.getBrokerNames(
        policyDashboardData.data.policy.brokerageProducerBOR,
      ),
    };
    const startDate = getDateFormatForDatePicker(
      policyDashboardData.data.effectiveDt,
      this.shortDateFormat,
    );
    const endDate = getDateFormatForDatePicker(
      policyDashboardData.data.expiryDt,
      this.shortDateFormat,
    );
    //UPDATE STORE DROPDOWNS with DATES
    //UPDATE STORE DROPDOWNS with BROKER
    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        startDate: startDate,
        endDate: endDate,
        ...brokerData,
      }),
    );

    const branchObs = this.brokerageBranchService.GetBranchByBrokerageId(
      policyDashboardData.data.policy.brokerageBORId,
      1,
      10000000,
    );
    let branches = await firstValueFrom(branchObs);
    const branch = branches.data.filter(
      (b) => b.name === policyData.data.brokerageBranchBOR.name,
    )[0];

    this.insuredData = {
      brokerageId: Number(policyDashboardData.data.policy.brokerageBORId),
      branchId: Number(branch.id),
      producerId: Number(
        policyDashboardData.data.policy.brokerageProducerBORId,
      ),
      brokerageName: policyDashboardData.data.policy.brokerageBOR.name,
      regionId,
    };
    //UPDATE STORE UI with BROKER
    this.store.dispatch(
      new CreateQuoteAction.UpdateInsuredAction({
        brokerageId: Number(policyDashboardData.data.policy.brokerageBORId),
        branchId: Number(branch.id),
        producerId: Number(
          policyDashboardData.data.policy.brokerageProducerBORId,
        ),
        broker: policyDashboardData.data.policy.brokerageBOR.name,
        regionId,
      }),
    );

    //UPDATE STORE UI with PRODUCTS
    this.store.dispatch(
      CreateQuoteAction.loadProductsSuccess({ products: products }),
    );

    let isInsuredTypeCompany = true;
    if (insured.insuredType == 1) isInsuredTypeCompany = false;
    this.store.dispatch(
      new CreateQuoteAction.UpdateInsuredAction({
        insuredId: Number(insured.id),
        insuredName: this.getInsuredName(insured),
        policyId: Number(quoteID),
        saveAtomicTrx: true,
        policyRiskTrxId: Number(policyRiskTrxId),
        insuredEmail: insuredData.data.email,
        revenue: insuredData.data.revenue,
        noOfEmployees: insuredData.data?.numberOfEmployeesDomain?.description,
        industryClass: insuredData.data.industryClass.name,
        industry: insuredData.data.industry.name,
        address: insuredData.data.insuredLocation.fullAddress,
        isInsuredTypeCompany: isInsuredTypeCompany,
        policyPeriodId: Number(policyPeriodId),
      }),
    );

    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({ policyId: quoteID }),
    );
  }

  async makeProducts(data, policyRisk) {
    const tableDataPromises = data?.map(async (item, index, arr) => {
      const policyRiskData = policyRisk.filter(
        (policyRisk) => policyRisk.risk.id == item.risk.id,
      );
      let underwriter = '';
      let isChecked = false;
      let underwriterId;
      if (policyRiskData.length > 0) {
        const underwriterObj =
          policyRiskData[0]?.PolicyRiskTrxes[0]?.underwriter;
        underwriter =
          underwriterObj?.firstName + ' ' + underwriterObj?.lastName;
        underwriterId = underwriterObj?.id;
        isChecked = true;
      }

      let hasSubProduct = false,
        subProductName = '',
        subProductRiskId = null;
      let hasParentProduct = false,
        parentRiskId = null;
      if (item.risk?.standaloneSellable == 1) {
        const getSubProduct = this.riskRegionService.getBySellableWithRiskId(
          item.risk.id,
        );
        const getSubProductResponse = await firstValueFrom(getSubProduct);

        if (getSubProductResponse?.data?.length > 0) {
          hasSubProduct = true;
          subProductName = getSubProductResponse?.data[0]?.name;
          subProductRiskId = getSubProductResponse?.data[0]?.id;
        }
      } else {
        const getParentProduct = this.riskRegionService.getRiskById(
          item.risk.id,
        );
        const getParentProductResponse = await firstValueFrom(getParentProduct);

        if (
          getParentProductResponse?.data?.id &&
          getParentProductResponse?.data?.sellableWithRiskId
        ) {
          hasParentProduct = true;
          parentRiskId = getParentProductResponse?.data?.id;
        }
      }

      return {
        key: item.risk.name,
        value: item.risk.name,
        description: item.risk.description,
        checked: isChecked,
        active: true,
        addons: [],
        underwriter: underwriter,
        riskId: item.risk.id,
        underwriterId: underwriterId,
        standaloneSellable:
          item.risk?.standaloneSellable === STANDALONE_SELLABLE_TRUE,
        hasSubProduct: hasSubProduct,
        subProductName: subProductName,
        subProductRiskId: subProductRiskId,
        hasParentProduct: hasParentProduct,
        parentRiskId: parentRiskId,
      };
    });

    // Wait for all the promises to resolve
    const resolvedTableData = await Promise.all(tableDataPromises);

    return resolvedTableData;
  }

  getInsuredName(insured) {
    if (insured.insuredType == 2) {
      return insured.companyName;
    } else {
      let name = insured.firstName + ' ';
      name += insured.middleName ? insured.middleName : '';
      name += ' ' + insured.lastName;
      return name;
    }
  }
  getBrokerNames(dataObj) {
    return dataObj.firstName + ' ' + dataObj.lastName;
  }
}
