import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import { RoleService } from 'src/app/services/roles.service';
import { SystemUserService } from 'src/app/services/system-user.service';
import { arrayToObjet } from 'src/app/utils/utils';
import { BoxxResponse } from 'src/app/entities/boxx-response';
import { Role } from 'src/app/entities/role';
import { SystemUser } from 'src/app/entities/system-user';
import { formatDateTime } from 'src/app/utils/formatDate';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { regexHelpers, getErrorMessage } from 'src/app/utils/utils';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { Store, select } from '@ngrx/store';

@Component({
  selector: 'app-edit-system-boxx-user',
  templateUrl: './edit-system-boxx-user.component.html',
  styleUrls: ['./edit-system-boxx-user.component.less'],
})
export class EditSystemBoxxUserComponent implements OnInit {
  @Output() updateTitleEvent = new EventEmitter<string>();
  @Output() updateStatusEvent = new EventEmitter<boolean>();
  @Input() systemUserType: string;
  users: Array<any> = [];
  roles: Array<any> = [];
  tUsers: any;
  tRoles: any;
  boxxUserForm = new FormGroup({
    boxxUser: new FormControl('', [Validators.required]),
    roleId: new FormControl('', [Validators.required]),
    loginEmail: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(regexHelpers.MailId),
    ]),
  });
  requiredErrMsg: string = 'error.thisFieldIsRequired';
  invalidErrMsg: string = 'error.thisFieldIsInvalid';
  isSuccess = false;
  hasError = false;
  errorMessage = '';
  message = '';

  formSubmitted: boolean = false;
  boxxUserId = '';
  roleName = '';
  showSpinner: boolean = false;
  currentSystemUser: any;
  userId = '';
  toggleActive = false;
  processingActivation = false;
  showBodySpinner: boolean = false;
  showErrorAlert = false;
  msgErrorAlert = '';
  loggedUserId: number;
  invalidRole: string = '';
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  showReloadModal: boolean = false;
  shortDateTimeFormat: string = '';
  longDateTimeFormat: string = '';

  constructor(
    private boxxUser: BoxxUserService,
    private activedRoute: ActivatedRoute,
    private roleService: RoleService,
    private systemUser: SystemUserService,
    private localStorageService: LocalStorageService,
    private store: Store,
  ) {
    const route = this.activedRoute.snapshot.params;
    this.userId = route['id'];
  }

  updateTitle(value: string) {
    this.updateTitleEvent.emit(value);
  }

  updateStatus(status: boolean) {
    this.updateStatusEvent.emit(status);
  }

  ngOnInit() {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateTimeFormat = data.shortDateTimeFormat;
      this.longDateTimeFormat = data.longDateTimeFormat;
    });

    this.loggedUserId = this.localStorageService.getBoxxUserId();
    this.showBodySpinner = true;
    this.fetchCurrentSystemUser(this.userId);
  }

  fetchCurrentSystemUser(userId) {
    this.systemUser.GetSystemUser(userId).subscribe({
      next: (response) => {
        this.setCurrentSystemUserResult(response);
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });
    this.boxxUserForm[this.toggleActive ? 'enable' : 'disable']({
      emitEvent: false,
    });
  }

  setCurrentSystemUserResult(data: BoxxResponse<SystemUser>) {
    const { data: SystemUser } = data;
    this.currentSystemUser = SystemUser;
    this.populateForm(this.currentSystemUser);
  }

  populateForm(userDetails) {
    const {
      boxxUser: { firstName, lastName },
      role,
      loginEmail,
      active,
    } = userDetails;
    this.roleName = role.name;
    const name = `${firstName} ${lastName}`;
    this.boxxUserForm.get('boxxUser').setValue(name);
    this.boxxUserForm.get('roleId').setValue(role.id);
    this.boxxUserForm.get('loginEmail').setValue(loginEmail);

    this.toggleActive = !!active;
    this.boxxUserForm[this.toggleActive ? 'enable' : 'disable']({
      emitEvent: false,
    });

    this.updateTitle(name);
    this.updateStatus(active);
    this.currentSystemUser['createdDt'] = formatDateTime(
      this.currentSystemUser.createdDt,
      this.longDateTimeFormat,
    );
    this.currentSystemUser['updatedDt'] = formatDateTime(
      this.currentSystemUser.updatedDt,
      this.longDateTimeFormat,
    );
    this.showBodySpinner = false;
  }

  handleCloseReloadWarning() {
    this.showReloadModal = false;
  }

  onSubmit(data) {
    this.handleCloseSuccessEvent();
    if (this.boxxUserForm.disabled) {
      return;
    }
    this.formSubmitted = true;
    this.hasError = false;

    // check boxx user form is invalid
    if (this.boxxUserForm.invalid) {
      return;
    }
    this.showSpinner = true;

    this.systemUser.UpdateSystemUser(this.userId, data).subscribe(
      (response) => {
        let currentSystemUserId = this.localStorageService.getSystemUserId();
        if (currentSystemUserId === +this.userId) {
          this.showReloadModal = true;
        } else {
          this.isSuccess = true;
        }
        this.message = 'common.saveSuccessMsg';
        this.showSpinner = false;
        this.formSubmitted = false;
        this.currentSystemUser = {
          ...this.currentSystemUser,
          ...response.data,
        };
        this.populateForm(this.currentSystemUser);
      },
      ({ error }) => {
        this.showSpinner = false;
        this.hasError = true;
        this.errorMessage = error.message ? error.message : error.error;
      },
    );
  }

  handleCloseSuccessEvent() {
    this.isSuccess = false;
    this.hasError = false;
  }

  searchUsers(name) {
    // reset form group's boxxUserId value
    if (name.length >= 3) {
      let limit = 10,
        page = 1,
        sort = '';
      let active = 1;
      this.boxxUser
        .GetAllUserByName(name, page, limit, sort, active)
        .subscribe({
          next: (response) => {
            const tableData = response.data?.map((dataObj) => ({
              key: `${dataObj.firstName} ${dataObj.lastName}`,
              value: dataObj.id,
            }));
            this.users = [...tableData];
            this.tUsers = arrayToObjet(response.data, 'id');
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    } else {
      this.users = [];
    }
  }

  boxxUserValueChanged(name) {
    const user = this.tUsers[name];
    this.boxxUserId = `${user.firstName} ${user.lastName} `;
  }

  boxxRoleValueChanged(event) {
    this.invalidRole = '';
    let id = this.roles.find((x) => x.value == event).id;
    const role = this.tRoles[id];
    this.roleName = `${role.name}`;
    this.boxxUserForm.get('roleId').setValue(role.id);
  }

  searchRoles(firstName) {
    // reset form group's boxxUserId value
    this.boxxUserForm.get('roleId').setValue(null);
    this.invalidRole = '';
    if (firstName.length >= 3) {
      this.roleService
        .GetByNameNsystemUserType(firstName, this.systemUserType)
        .subscribe({
          next: (response) => {
            this.setRoleSearchResult(response, firstName);
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    } else {
      this.roles = [];
    }
  }

  setRoleSearchResult(data: BoxxResponse<Role>, name = '') {
    const tableData = data.data?.map((dataObj) => ({
      key: `${dataObj.name}`,
      value: dataObj.name,
      id: dataObj.id,
    }));
    this.roles = [...tableData];
    this.tRoles = arrayToObjet(data.data, 'id');
    if (tableData.length > 0) {
      this.invalidRole = 'systemUser.error.invalidRole';
    } else {
      this.invalidRole = '';
    }
  }

  initProcessing() {
    this.processingActivation = true;
    this.isSuccess = false;
    this.hasError = false;
  }

  deActivateSystemUser() {
    this.handleCloseSuccessEvent();
    this.initProcessing();
    this.systemUser.DeleteSystemUser(this.userId).subscribe(
      (response) => {
        this.currentSystemUser['updatedDt'] = formatDateTime(
          response?.data?.updatedDt,
          this.longDateTimeFormat,
        );
        this.currentSystemUser['updatedBy'] = response?.data?.updatedBy;
        this.message = 'common.userDeActivateMsg';
        this.isSuccess = true;
        this.processingActivation = false;
        this.toggleActive = false;
        this.updateStatus(false);
        this.boxxUserForm['disable']({ emitEvent: false });
      },
      ({ error }) => {
        this.hasError = true;
        this.processingActivation = false;
        this.errorMessage = error.message ? error.message : error.error;
      },
    );
  }

  activateSystemUser() {
    this.handleCloseSuccessEvent();
    this.initProcessing();
    this.systemUser.RestoreSystemUser(this.userId).subscribe(
      (response) => {
        this.currentSystemUser['updatedDt'] = formatDateTime(
          response?.data?.updatedDt,
          this.longDateTimeFormat,
        );
        this.currentSystemUser['updatedBy'] = response?.data?.updatedBy;
        this.message = 'common.userActivateMsg';
        this.isSuccess = true;
        this.processingActivation = false;
        this.toggleActive = true;
        this.updateStatus(true);
        this.boxxUserForm['enable']({ emitEvent: false });
      },
      ({ error }) => {
        this.hasError = true;
        this.processingActivation = false;
        this.errorMessage = getErrorMessage(error);
      },
    );
  }
  handleCloseSuccessEventExt() {
    this.showErrorAlert = false;
  }
  checkUser() {
    return String(this.loggedUserId) != this.currentSystemUser?.boxxUser?.id;
  }
}
