import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.less'],
})
export class ButtonGroupComponent {
  @Input() buttons: { id: number; name: string; active: boolean }[];

  @Output() handleButtonClick = new EventEmitter<any>();

  handleClick(id) {
    this.handleButtonClick.emit(id);
  }
}
