import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, retry, catchError } from 'rxjs';
import { BoxxResponse } from '../entities/boxx-response';
import { BaseService } from './base-services';

@Injectable({
  providedIn: 'root',
})
export class PolicyQuoteAuditService extends BaseService<any> {
  constructor(http: HttpClient) {
    const baseurl =
      environment.msPolicyBaseUrl + environment.policyQuoteAuditUrl;
    super(http, baseurl);
  }

  getPolicyQuoteAuditData(
    auditTable: any,
    policyRiskTrxId: any,
    page: number = 1,
    limit = 100,
  ): Observable<any> {
    const url = `${this.baseurl}?auditTable=${auditTable}&policyRiskTrxId=${policyRiskTrxId}&page=${page}&limit=${limit}&sort=createdDt%3Adesc,id%3Adesc`;

    return this.http
      .get<BoxxResponse<any>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
