import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { CognitoService } from '../services/cognito-service';
import { LocalStorageService } from '../services/localstorage-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CognitoGuard {
  constructor(
    private cognitoService: CognitoService,
    private router: Router,
    private locationTest: Location,
    private localStorageService: LocalStorageService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      location.hostname === 'localhost' ||
      location.hostname === '127.0.0.1'
    ) {
      return true;
    }

    if (this.cognitoService.isAuthenticated()) {
      const routeUrl = state.url;
      if (
        location?.pathname.includes('/home') ||
        location?.pathname.includes('/cognito') ||
        routeUrl.includes('home')
      ) {
        return true;
      }
      let isMock = 'true';
      let bypassMaintenance = false;
      if (
        this.localStorageService.getMockExternalAPIs() === 'false' ||
        environment.mockExternalAPIs === false
      ) {
        isMock = 'false';
      }

      this.router.navigate(['dashboard/home'], {
        queryParams: {
          mock: isMock,
          bypassMaintenance: true,
        },
      });
      return true;
    }
    this.router.navigate(['unauthorized'], { skipLocationChange: true });
    return false;
  }
}
