import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner-load',
  templateUrl: './spinner-load.component.html',
  styleUrls: ['./spinner-load.component.less'],
})
export class SpinnerLoadComponent {
  @Input() isFetching: boolean = false;
  @Input() iconTopPosition: { [klass: string]: any };

  constructor() {}
}
