import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BoxxResponse } from '../entities/boxx-response';
import { BrokerageBranch } from '../entities/brokerage-branch';
import { BrokerageDirectory } from '../entities/brokerage-directory';

@Injectable({
  providedIn: 'root',
})
export class BrokerageDirectoryService {
  // Base url
  baseurl = environment.msBrokerBaseUrl + environment.brokerageDirectoryPathUrl;
  constructor(private http: HttpClient) {}
  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  // GET ALL
  GetBrokerageDirectories(
    page = 1,
    limit = 10,
    sort = '',
    brokerage = true,
    branch = true,
    producer = true,
    name = '',
    fullSearch = false,
    activeSearch = '',
  ): Observable<BoxxResponse<BrokerageDirectory>> {
    return this.http
      .get<BoxxResponse<BrokerageDirectory>>(
        this.baseurl +
          '?page=' +
          page +
          '&limit=' +
          limit +
          '&sort=' +
          sort +
          '&brokerage=' +
          brokerage +
          '&branch=' +
          branch +
          '&producer=' +
          producer +
          '&name=' +
          name +
          (fullSearch === true ? '&fullSearch=true' : '') +
          (activeSearch !== '' ? '&' + activeSearch : ''),
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  ExportBrokerageDirectoryDetails(
    page = 1,
    limit = 10,
    sort = '',
    brokerage = true,
    branch = true,
    producer = true,
    name = '',
    filters?: { [x: string]: any },
  ): Observable<ArrayBuffer> {
    let url =
      environment.msBrokerBaseUrl + environment.brokerageDriectoryExportUrl;

    return this.http
      .get(
        url +
          '?page=' +
          page +
          '&limit=' +
          limit +
          '&sort=' +
          sort +
          '&brokerage=' +
          brokerage +
          '&branch=' +
          branch +
          '&producer=' +
          producer +
          (name ? `&name=${name}` : '') +
          (filters?.['brokerageId']
            ? `&brokerageId=${filters['brokerageId']}`
            : '') +
          (filters?.['regionId'] ? `&regionId=${filters['regionId']}` : '') +
          (filters?.['active'] ? `&active=${filters['active']}` : ''),
        { responseType: 'arraybuffer' },
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  // Error handling
  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return throwError(() => {
      return errorMessage;
    });
  }
}
