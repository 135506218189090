import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import {
  formatDate,
  getFormatDate,
  getFormattedPeriod,
} from 'src/app/utils/formatDate';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BrokerageCommissionService } from 'src/app/services/brokerage-commissions.service';
import { BrokerageProducerCommissionService } from 'src/app/services/brokerage-producer-commission.service';

@Component({
  selector: 'app-modal-commission-change',
  templateUrl: './modal-commission-change.component.html',
  styleUrls: ['./modal-commission-change.component.less'],
})
export class ModalCommissionChangeWorkFlow3Component implements OnInit {
  @Input() showModal: boolean = false;
  @Input() existingQuoteOptionData;
  @Input() quoteTableData;
  @Input() commissionId;
  @Input() commissionData;
  @Input() totalCount;
  @Input() action;
  @Output() handleClose = new EventEmitter<any>();
  @Output() handleSave = new EventEmitter<any>();
  commissionChangeForm: FormGroup;
  sortableColumns: string[] = ['Effective date'];
  quoteList = [];
  shortDateFormat: string = '';
  longDateFormat: string = '';
  brokerageId;
  producerId;
  type;
  pageNumber = 1;
  itemsPerPage = 20;
  sortBy = 'effectiveDt:desc';
  pagesData: { [key: number]: any[] } = {};
  constructor(
    private store: Store,
    private brokerageCommissionService: BrokerageCommissionService,
    private brokerageProducerCommissionService: BrokerageProducerCommissionService,
    private fb: FormBuilder,
  ) {
    this.commissionChangeForm = this.fb.group({
      action: ['', [Validators.required]],
      saveAction: [''],
    });
  }

  async ngOnInit() {
    this.quoteList = this.quoteTableData;
    this.pagesData[1] = this.quoteList;
    this.handlesaveActionChange();
    if (this.existingQuoteOptionData) {
      this.brokerageId = this.existingQuoteOptionData?.['brokerageId'];
      this.producerId = this.existingQuoteOptionData?.['producerId'];
      this.type = this.existingQuoteOptionData?.['type'];
    }
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
  }
  closeModal() {
    this.showModal = false;
    this.handleClose.emit();
  }

  formatDate(date) {
    const dateValue: Date = new Date(
      getFormatDate(
        date?.effectiveDt,
        'YYYY-MM-DDTHH:mm:ss.sssZ',
        'MMM DD,YYYY',
      ),
    );
    if (dateValue.toString() !== 'Invalid Date') {
      return date
        ? getFormattedPeriod(date, 'YYYY-MM-DDTHH:mm:ss.sssZ', 'MMM DD,YYYY')
        : '';
    } else {
      return date
        ? getFormattedPeriod(date, this.shortDateFormat, 'MMM DD,YYYY')
        : '';
    }
  }
  // 'Effective date': formatDate(item.policyPeriod?.effectiveDt,
  //   this.longDateFormat
  // ),

  async getQuoteList(pageNumber) {
    this.pageNumber = pageNumber;
    if (this.brokerageId && this.type === 'Brokerage') {
      await Promise.all([
        await this.getBrokerQuoteList(this.commissionId, pageNumber, true),
      ]);
    } else if (this.producerId && this.type === 'Producer') {
      await Promise.all([
        await this.getProducerQuoteList(this.commissionId, pageNumber, true),
      ]);
    }
  }

  handleRadiodiscardCheck(param) {
    this.commissionChangeForm.controls['saveAction'].setValue('');
  }
  handlesaveActionChange() {
    this.commissionChangeForm.controls['action'].setValue('save');
    this.commissionChangeForm.controls['saveAction'].setValue('decline');
  }

  sortByDate(sortby): any[] {
    if (sortby === 'asc') {
      return this.quoteList.sort(
        (a, b) =>
          new Date(a['Effective date']).getTime() -
          new Date(b['Effective date']).getTime(),
      );
    } else {
      return this.quoteList.sort(
        (a, b) =>
          new Date(b['Effective date']).getTime() -
          new Date(a['Effective date']).getTime(),
      );
    }
  }
  async handleTableColSort(e) {
    this.pageNumber = 1;
    if (e.column === 'Effective date') {
      e.column = 'effectiveDt';
    } else {
      e.column = 'effectiveDt';
      e.sort = 'desc';
    }
    this.sortBy = e.column + ':' + e.sort;
    //this.sortByDate(e.sort);
    if (this.brokerageId && this.type === 'Brokerage') {
      await Promise.all([
        await this.getBrokerQuoteList(
          this.commissionId,
          this.pageNumber,
          false,
        ),
      ]);
    } else if (this.producerId && this.type === 'Producer') {
      await Promise.all([
        await this.getProducerQuoteList(
          this.commissionId,
          this.pageNumber,
          false,
        ),
      ]);
    }
  }

  async loadMoreQuotes(pageNumber) {
    if (this.totalCount > this.quoteList?.length) {
      await Promise.all([await this.getQuoteList(pageNumber)]);
    }
  }

  async getBrokerQuoteList(id, pageNumber, isLoadMore = false) {
    return new Promise<any>(async (resolve, reject) => {
      let queryParams = {};
      if (this.action === 'update') {
        if (this.commissionData.commissionMin) {
          queryParams['min'] = this.commissionData.commissionMin;
        }
        if (this.commissionData.commissionMax) {
          queryParams['max'] = this.commissionData.commissionMax;
        }
      }
      await this.brokerageCommissionService
        .GetAllActiveQuoteList(
          id,
          queryParams,
          pageNumber,
          this.itemsPerPage,
          this.sortBy,
        )
        .subscribe({
          next: async (response) => {
            if (response?.data?.length > 0) {
              const quoteList = response?.data?.map((item) => {
                return {
                  'Quote ID':
                    item?.policyRisk?.policyPeriod?.policy?.id.toString(),

                  'Effective date': getFormatDate(
                    item?.policyRisk?.policyPeriod?.effectiveDt,
                    'YYYY-MM-DDTHH:mm:ss.sssZ',
                    'MMM DD,YYYY',
                  ),
                  Insured:
                    item?.policyRisk?.policyPeriod?.policy?.insured
                      ?.companyName,
                };
              });
              // Store data by page number
              this.pagesData[pageNumber] = quoteList;
              this.totalCount = response?.pagination?.totalRecords;
              await Promise.all(this.quoteList);

              if (isLoadMore) {
                // Flatten the pagesData object into a sorted array based on page numbers
                this.quoteList = Object.keys(this.pagesData)
                  .sort((a, b) => Number(a) - Number(b))
                  .reduce(
                    (acc, key) => acc.concat(this.pagesData[Number(key)]),
                    [],
                  );
              } else {
                this.quoteList = quoteList;
              }
              resolve(true);
            } else {
              this.quoteList = [];
              resolve(true);
            }
          },
          error: (error) => {
            reject(true);
          },
        });
    });
  }
  async getProducerQuoteList(id, pageNumber, isLoadMore = false) {
    return new Promise<any>(async (resolve, reject) => {
      let queryParams = {};
      if (this.action === 'update') {
        if (this.commissionData.commissionMin) {
          queryParams['min'] = this.commissionData.commissionMin;
        }
        if (this.commissionData.commissionMax) {
          queryParams['max'] = this.commissionData.commissionMax;
        }
      }
      await this.brokerageProducerCommissionService
        .GetAllActiveQuoteList(
          id,
          queryParams,
          pageNumber,
          this.itemsPerPage,
          this.sortBy,
        )
        .subscribe({
          next: async (response) => {
            if (response?.data?.length > 0) {
              const quoteList = response?.data?.map((item) => {
                return {
                  'Quote ID':
                    item?.policyRisk?.policyPeriod?.policy?.id.toString(),

                  'Effective date': getFormatDate(
                    item?.policyRisk?.policyPeriod?.effectiveDt,
                    'YYYY-MM-DDTHH:mm:ss.sssZ',
                    'MMM DD,YYYY',
                  ),
                  Insured:
                    item?.policyRisk?.policyPeriod?.policy?.insured
                      ?.companyName,
                };
              });
              this.pagesData[pageNumber] = quoteList;
              this.totalCount = response?.pagination?.totalRecords;
              await Promise.all(this.quoteList);
              if (isLoadMore) {
                // Flatten the pagesData object into a sorted array based on page numbers
                this.quoteList = Object.keys(this.pagesData)
                  .sort((a, b) => Number(a) - Number(b))
                  .reduce(
                    (acc, key) => acc.concat(this.pagesData[Number(key)]),
                    [],
                  );
              } else {
                this.quoteList = quoteList;
              }
              resolve(true);
            } else {
              this.quoteList = [];
              resolve(true);
            }
          },
          error: (error) => {
            reject(true);
          },
        });
    });
  }
  saveContinue() {
    if (!this.commissionChangeForm.get('action').valid) {
      return;
    }
    let actionLabel = this.commissionChangeForm.value.saveAction
      ? this.commissionChangeForm.value.saveAction
      : this.commissionChangeForm.value.action;
    this.handleSave.emit({ action: this.action, subAction: actionLabel });
  }
  handleSetActionDefault() {
    this.commissionChangeForm.controls['action'].setValue('save');
  }
}
