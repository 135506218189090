import { FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { RegexHelpers } from '../entities/regex-validators';
import { CANADA, UNITED_STATES } from '../constants/location-constant';
import { CurrencyFormatPipe } from '../pipes/currency-format.pipe';
import { alertHeaders } from '../constants/security/systemUserType';
import { environment } from 'src/environments/environment';
import moment from 'moment';

export function removeDuplicatedKeys(array: any) {
  const uniqueIds = [];

  const unique = array.filter((element) => {
    const isDuplicate = uniqueIds.includes(element.key);

    if (!isDuplicate) {
      uniqueIds.push(element.key);

      return true;
    }

    return false;
  });
  return unique;
}

export function arrayToObjet(array, key) {
  const obj = array.reduce((accumulator, value) => {
    return { ...accumulator, [value[key]]: value };
  }, {});
  return obj;
}

export const getBoolean = (value: any): boolean | undefined => {
  if (!value) return undefined;
  const lowercasedValue = String(value).toLowerCase();
  switch (lowercasedValue) {
    case 'true':
    case '1':
    case 'on':
    case 'yes':
      return true;
    case 'false':
    case '0':
    case 'off':
    case 'no':
    default:
      return false;
  }
};

export function formatNumber(num, precision = 2) {
  const map = [
    { suffix: 'T', threshold: 1e12 },
    { suffix: 'B', threshold: 1e9 },
    { suffix: 'M', threshold: 1e6 },
    { suffix: 'K', threshold: 1e3 },
    { suffix: '', threshold: 1 },
  ];

  const found = map.find((x) => Math.abs(num) >= x.threshold);
  if (found) {
    const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
    return formatted;
  }

  return num;
}

export function valInDigits(num) {
  if (!num) {
    return 0;
  }
  let value = num;
  if (typeof num === 'string')
    if (num.includes('T')) {
      value = Number(num.replace(/[^0-9.]/g, '')) * 1000000000000;
    } else if (num.includes('B')) {
      value = Number(num.replace(/[^0-9.]/g, '')) * 1000000000;
    } else if (num.includes('M')) {
      value = Number(num.replace(/[^0-9.]/g, '')) * 1000000;
    } else if (num.includes('K')) {
      value = Number(num.replace(/[^0-9.]/g, '')) * 1000;
    } else {
      value = Number(num.replace(/[^0-9.]/g, ''));
    }
  return value;
}
// Return formatted phone number like (000) 000-0000
export function formatPhoneNumber(phoneNumber: string = '') {
  let formattedPhoneNumber = '';
  if (phoneNumber == null || phoneNumber.length == 0) {
  } else if (phoneNumber.length <= 6) {
    formattedPhoneNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
    )}`;
  } else {
    formattedPhoneNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6,
    )}-${phoneNumber.slice(6)}`;
  }
  return formattedPhoneNumber;
}

export function makeSortingCol(col, colHeader, order) {
  let sortKey = '';
  sortKey = colHeader[col].key + ':' + order;
  return sortKey;
}

export function makeSorting(col, colHeader, order) {
  let sortKey = '';

  const keys = colHeader[col];

  keys.forEach((i) => {
    sortKey += i.key + ':' + order + ',';
  });

  if (sortKey) return sortKey.slice(0, -1);
  return sortKey;
}

export const regexHelpers: RegexHelpers = {
  //The given regex pattern matches a string consisting of one or more alphanumeric characters or apostrophes, where words are separated by a single space.
  AlphaNumericSpace:
    /^(?![^a-zA-Z0-9])[-_'.&()a-zA-Z0-9]+(?:\s[-_'.&()a-zA-Z0-9]+)*$/,

  //The above regex pattern matches a standard email address format with alphanumeric characters, dots, hyphens, and underscores before the @ symbol, followed by a domain name containing alphanumeric characters, dots, and hyphens, and ending with a two to four letter top-level domain.
  MailId: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,

  //The above regex pattern matches a website URL that starts with an optional http:// or https://, followed by alphanumeric characters or hyphens for subdomains, then the domain name containing alphanumeric characters or hyphens, and ending with a two to six letter top-level domain.
  Website: new RegExp(/^(https?:\/\/)?([\w.-]+\.[a-zA-Z]{2,})(\/\S*)?$/, 'i'),

  //The above regex pattern matches one or more website URLs separated by a comma and optional whitespace, where each URL can start with an alphanumeric characters or hyphens for subdomains, then the domain name containing alphanumeric characters or hyphens, and ending with a two to six letter top-level domain , www,http://,https:// are not allowed now.
  MultiWebSite:
    /^(?!.*\b(www|http|https)\b)(?![-_.])((?!([-_.])\1)[a-zA-Z0-9-_]+\.)*[a-zA-Z0-9-_]{2,}\.[a-zA-Z]{2,}?(,\s?(?![-_.])((?!([-_.])\1)[a-zA-Z0-9-_]+\.)*[a-zA-Z0-9-_]{2,}\.[a-zA-Z]{2,})*$/,

  //The above regex pattern matches a string that consists of exactly 10 consecutive digits (0-9).
  PhoneNumber: /^[0-9]{10}$/,

  //The above regex pattern matches a string that can contain zero or more consecutive digits (0-9).
  Number: /^[0-9]*$/,

  //The above regex pattern matches a string that can contain zero or more digits, followed by an optional decimal point and one or more digits, allowing for positive numbers and positive floating-point numbers.
  FloatingNumber: /^\d*(\.\d+)?$/,

  //The given regex pattern matches a string consisting of 0 to 50 consecutive alphanumeric characters.
  AlphaNumeric: /^[a-zA-Z0-9]{0,50}$/,

  percentage: /^(100(\.0{1,2})?|([1-9]\d?(\.\d{1,2})?|0?(\.\d{1,2})?))$/,
};

export function postalCodeValidator(country): ValidatorFn {
  return (control: FormControl) => {
    if (!control || !control.parent) {
      return null;
    } else if (!control.value && control.value == '') {
      return null;
    }
    const postalCode = control.value;

    if (country === UNITED_STATES) {
      const usPostalCodePattern = /^\d{5}(?:[-\s]\d{4})?$/i;
      return usPostalCodePattern.test(postalCode)
        ? null
        : {
            customError: true,
          };
    } else if (country === CANADA) {
      const caPostalCodePattern =
        /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
      return caPostalCodePattern.test(postalCode)
        ? null
        : {
            customError: true,
          };
    }
    return null;
  };
}

export function encodeToBase64(input: string) {
  return btoa(input);
}

export function decodeFromBase64(input: string) {
  return atob(input);
}

export function replaceDoubleCurlyBracesText(
  inputString: string,
  replacement: string,
) {
  let regex = /\{\{.*?\}\}/g;
  return inputString.replace(regex, replacement);
}

export function extractTextInsideDoubleCurlyBracketsWithReplaceText(
  inputString: string,
) {
  let regex = /\{\{.*?\}\}/g;
  let matches = [...inputString.matchAll(regex)].map((match) => {
    return { target: match[0], replaceText: '' };
  });
  return matches;
}

export function extractTextInsideDoubleCurlyBrackets(inputText: string) {
  let regex = /\{\{(.*?)\}\}/;
  let match = inputText.match(regex);
  return match ? match[1] : '';
}

export function replacePlaceholders(
  inputString: string,
  replacements: { target: string; replaceText: string }[],
) {
  for (const { target, replaceText } of replacements) {
    inputString = inputString.replace(target, replaceText);
  }
  return inputString;
}

export function getAlertType(type: string) {
  switch (type) {
    case 'OPERATION_WARNING':
      return 'warn';
    case 'OPERATION_SUCCESS':
      return 'success';
    case 'OPERATION_ERROR':
      return 'error';
    case 'OPERATION_INFO':
      return 'info';

    default:
      return '';
  }
}

// custom validator function for pattern validation
export const patternValidator = (pattern: RegExp): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isValid = pattern.test(control.value);
    return isValid ? null : { pattern: { value: control.value } };
  };
};

export const getErrorMessage = (error) => {
  if (error?.error?.message) {
    return error?.error?.message;
  }
  if (error?.error?.error) {
    return error?.error?.error;
  }
  if (error?.error?.errors?.message) {
    return error?.error?.errors?.message;
  }
  if (error?.error) {
    return error?.error;
  }
  if (error?.message) {
    return error?.message;
  }
  if (error?.errors?.message) {
    return error?.errors?.message;
  }
  return;
};

export const getStatusMappingForStyling = () => {
  const statusMappingToType = {
    // 'NEW SUBMISSIONS': 'default',
    // REINSTATED: 'default',
    // BOUND: 'primary',
    // REFERRAL: 'primary',
    // QUOTE: 'secondary',
    // QUOTED: 'secondary',
    // RENEWED: 'warning',
    // 'RENEWAL QUOTE': 'warning',
    // 'RENEWAL APP': 'warning',
    // 'QUOTE-CLOSED': 'inactive',
    // CANCELLED: 'inactive',
    // LAPSED: 'inactive',
    // DECLINED: 'inactive',
    // CLOSED: 'inactive',
    // 'NOT TAKEN UP': 'inactive',

    'New submissions': 'default',
    'new submissions': 'default text-capitalize',
    referral: 'primary text-capitalize',
    Indicated: 'primary text-capitalize',
    quote: 'secondary text-capitalize',
    quoted: 'default text-capitalize',
    closed: 'inactive text-capitalize',
    cancelled: 'inactive text-capitalize',
    submission: 'default text-capitalize',
    declined: 'inactive text-capitalize',
    'not taken up': 'inactive text-capitalize',
    renewalquoted: 'default text-capitalize',
    renewalapp: 'default text-capitalize',
    'quote closed': 'inactive text-capitalize',
    renewed: 'warning text-capitalize',
    reinstated: 'default text-capitalize',
    bound: 'primary text-capitalize',
    'renewal quote': 'warning text-capitalize',
    'renewal app': 'warning text-capitalize',
    lapsed: 'inactive text-capitalize',
    'quote-closed': 'inactive text-capitalize',
    'ua decline': 'ua decline',
  };
  return statusMappingToType;
};

export function formatAmountWithCurrency(
  value: string | number,
  currencyCode?: string,
  display: string | boolean = 'symbol-narrow',
  digitsInfo: string = '1.0-0',
  locale: string = 'en-US',
): string {
  if (!value) value = '0';
  const currencyFormatPipe = new CurrencyFormatPipe('', '');
  return currencyFormatPipe.transform(
    value,
    currencyCode,
    display,
    digitsInfo,
    locale,
  );
}

export function getAlertHead(alertType: string) {
  return alertHeaders[alertType] ?? '';
}

export const excelFileDownload = (fileName: string, buffer: ArrayBuffer) => {
  const blob = new Blob([buffer], {
    type: 'application/application/octet-stream',
  });
  const url = window.URL.createObjectURL(blob);
  const element = document.createElement('a');
  element.href = url;
  element.download = fileName;
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
  window.URL.revokeObjectURL(url);
  return true;
};

export function validateEmail(email: string) {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return regex.test(email);
}

export function getLoginUrlByStep(step: string) {
  if (step === environment.defaultFlow) {
    return environment.loginUrl;
  } else if (step === '4') {
    return environment.cognito4LoginUrl;
  } else if (step === '7') {
    return environment.cognito7LoginUrl;
  } else if (step === 'workflow3') {
    return environment.workflow3LoginUrl;
  }
  return environment.loginUrl;
}

export function getDecimalPlace(num: number, place: number = 2): number {
  const mult = Math.pow(10, place);
  return Math.round(num * mult) / mult;
}

// date format "2024-12-24T00:00:00.000Z"
export function addDaysToDateString(
  dateString,
  numberOfDays,
  longDateFormat = 'MMMM DD, YYYY',
) {
  if (!dateString) {
    return '';
  }
  let formattedDateString = dateString
    ? dateString.includes('T')
      ? dateString.split('T')[0]
      : ''
    : '';
  const originalDate = moment(formattedDateString);
  const newDate = originalDate.add(numberOfDays, 'days');

  return newDate.format(longDateFormat);
}

export const generateIncrementedValues = (
  minValue: number,
  maxValue: number,
  increment: number,
): number[] => {
  const values: number[] = [];
  let currentValue: number = minValue;

  while (currentValue <= maxValue) {
    values.push(currentValue);
    currentValue += increment;
  }

  return values;
};

export function areObjectsEqual(obj1, obj2) {
  if (!obj1 || !obj2 || Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }

  for (const key in obj1) {
    if (Object.prototype.hasOwnProperty.call(obj1, key)) {
      if (obj1[key] != obj2[key]) {
        return false;
      }
    }
  }
  return true;
}

export function checkDomainMatch(email, domainList) {
  const domains = domainList.split(',').map((domain) => domain.trim());
  const emailDomain = email.split('@')[1];
  for (const domain of domains) {
    if (emailDomain === domain) {
      return true;
    }
  }
  return false;
}

export function isWithinDateRange(
  utcStartDatetime: string,
  utcExpiryDateTime: string,
): boolean {
  const startDateTime = moment.utc(utcStartDatetime);
  const expiryDateTime = moment.utc(utcExpiryDateTime);

  // local datetime
  const localDateTime = moment();
  return localDateTime.isBetween(
    startDateTime,
    expiryDateTime,
    undefined,
    '[]',
  );
}

export function calculateHourDifference(endDateTime: string): string {
  const end = moment.utc(endDateTime);
  const start = moment.utc();

  const difference = Math.abs(end.diff(start));

  const duration = moment.duration(difference);
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  // Format the result as HH:mm:ss
  const formattedDiff = `${String(hours).padStart(2, '0')}:${String(
    minutes,
  ).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  return formattedDiff;
}

export function getLocalDateTimeFromUtcString(utcString: string) {
  if (!utcString) return '';
  const date = moment.utc(utcString);

  // Format the date to the desired output format
  return date.local().format('MMMM Do, h:mm A');
}

export function isWithinNextHour(utcDateTimeString: string): boolean {
  if (!utcDateTimeString) {
    return false;
  }

  const givenDateTime = moment.utc(utcDateTimeString);

  const currentLocalTime = moment.utc();

  const diffInHours = givenDateTime.diff(currentLocalTime, 'hours', true);

  return diffInHours >= 0 && diffInHours <= 1;
}

export function isBetweenOneHourAndOneHourTenMinutes(
  utcDateTimeString: string,
): boolean {
  if (!utcDateTimeString) {
    return false;
  }

  const givenDateTime = moment.utc(utcDateTimeString);

  const currentLocalTime = moment.utc();

  const diffInHours = givenDateTime.diff(currentLocalTime, 'hours', true);

  return diffInHours >= 1 && diffInHours <= 1.1667;
}

export function checkIfCurrentDateTime(utcDateTimeString: string) {
  const currentLocalTime = moment.utc();
  const givenDateTime = moment.utc(utcDateTimeString);
  if (Math.abs(currentLocalTime.diff(givenDateTime, 'seconds')) <= 1) {
    return true;
  }
  return false;
}

export function isPastLocalDateTime(utcDateTimeString: string): boolean {
  if (!utcDateTimeString) {
    return false;
  }

  const givenDateTime = moment.utc(utcDateTimeString);
  const currentLocalTime = moment();

  return givenDateTime.isBefore(currentLocalTime);
}
function getStatusClass(key: string): string {
  let inactiveArray = ['inactive'];
  let primaryArray = ['referral'];
  let secondaryArray = ['endorsement', 'policy change'];
  let warningArray = [];
  let infoArray = ['info'];
  let successArray = [
    'active',
    'success',
    'submission',
    'quoted',
    'bound',
    'bind',
    'renewed',
    'renewal quote',
    'endorsement - invoiced',
    'policy change - executed',
  ];
  let dangerArray = ['declined', 'cancelled', 'closed', 'cancellation'];

  if (primaryArray.includes(key)) {
    return 'primary';
  }
  if (secondaryArray.includes(key)) {
    return 'secondary';
  }
  if (warningArray.includes(key)) {
    return 'warning';
  }
  if (inactiveArray.includes(key)) {
    return 'inactive';
  }
  if (infoArray.includes(key)) {
    return 'info';
  }
  if (successArray.includes(key)) {
    return 'success';
  }
  if (dangerArray.includes(key)) {
    return 'error';
  }

  return 'default';
}

export function getStatusBadgeClass(status: string) {
  let caseName = getStatusClass(status.toLowerCase());

  return caseName;
}
export function toSentenceCase(input: string): string {
  if (!input) return '';

  const sentence = input.toLowerCase();
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
}

export function pointerEventsToggle(event: boolean) {
  window.document.body.style.pointerEvents = event ? 'none' : 'auto';
}
export function removeEmptyProperties(obj) {
  // Iterate over all properties of the object
  for (const key in obj) {
    // Check if the property is actually the object's own property
    if (obj.hasOwnProperty(key)) {
      // Check for null or undefined
      if (obj[key] === null || obj[key] === undefined) {
        delete obj[key];
      }
      // Check for empty string
      else if (typeof obj[key] === 'string' && obj[key].trim() === '') {
        delete obj[key];
      }
      // Check for empty array
      else if (Array.isArray(obj[key]) && obj[key].length === 0) {
        delete obj[key];
      }
      // Check for empty object
      else if (
        typeof obj[key] === 'object' &&
        !Array.isArray(obj[key]) &&
        Object.keys(obj[key]).length === 0
      ) {
        delete obj[key];
      }
    }
  }
  return obj;
}

export function arraysEqual(array1, array2) {
  if (array1.length !== array2.length) {
    return false;
  }

  const sortFn = (a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b));

  const sortedArray1 = array1.slice().sort(sortFn);
  const sortedArray2 = array2.slice().sort(sortFn);

  for (let i = 0; i < sortedArray1.length; i++) {
    if (JSON.stringify(sortedArray1[i]) !== JSON.stringify(sortedArray2[i])) {
      return false;
    }
  }

  return true;
}

export function removeSpecialSymbols(input: string): string {
  // remove all non-digit characters
  const result = input.replace(/[^0-9]/g, '');
  return result;
}

export function websiteFormatting(url: string): string {
  if (url.includes('://')) {
    let [protocol, rest] = url.split('://');

    if (!/^www\./i.test(rest)) {
      rest = 'www.' + rest;
    }

    return `${protocol}://${rest}`;
  } else if (url) {
    if (url.trim().length > 0 && !/^www\./i.test(url)) {
      return 'www.' + url;
    }
    return url;
  } else return url;
}

export function getPositionToDisplayPopup(
  event: MouseEvent,
  approxHeightToReduce: number = 0,
): { top; left; isTopPosition } {
  const screenHeight = window.innerHeight;
  const offsetY = 10; // Offset to prevent overlap with the cursor
  const sectionHeight = screenHeight / 3; // Divide the screen into three equal sections
  let isTopPosition = false;
  let position = { top: '0px', left: '0px' };
  // Determine the tooltip position based on the Y-coordinate
  if (event.clientY <= sectionHeight) {
    // Mouse is in the top third of the screen: show tooltip below
    isTopPosition = false;
    position = {
      top: `${event.clientY + offsetY}px`,
      left: `${event.clientX + offsetY}px`,
    };
  } else if (event.clientY >= 2 * sectionHeight) {
    // Mouse is in the bottom third of the screen: show tooltip above
    isTopPosition = true;
    position = {
      top: `${event.clientY - approxHeightToReduce - offsetY}px`,
      left: `${event.clientX + offsetY}px`,
    };
  } else {
    // Mouse is in the middle third of the screen: show tooltip below
    isTopPosition = false;
    position = {
      top: `${event.clientY + offsetY}px`,
      left: `${event.clientX + offsetY}px`,
    };
  }
  return {
    top: position.top,
    left: position.left,
    isTopPosition: isTopPosition,
  };
}
