import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { BoxxResponse } from '../entities/boxx-response';

export class BaseService<T> {
  baseurl: string = '';
  cognitoToken = '';
  constructor(
    protected http: HttpClient,
    baseurl,
    private token?: string,
  ) {
    this.baseurl = baseurl;
    this.cognitoToken = token;
  }
  // Http Headers
  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  // POST
  Create(data): Observable<any> {
    const url = this.token ? this.baseurl : this.baseurl;
    return this.http
      .post<T>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  // GET
  Get(id): Observable<BoxxResponse<T>> {
    const url = this.baseurl + id;
    return this.http
      .get<BoxxResponse<T>>(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  // GET ALL
  GetAll(page = 1, limit = 10, sort = ''): Observable<BoxxResponse<T>> {
    const url =
      this.baseurl + '?page=' + page + '&limit=' + limit + '&sort=' + sort;
    return this.http
      .get<BoxxResponse<T>>(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  // PUT
  Update(id, data): Observable<T> {
    const url = this.baseurl + id;
    return this.http
      .put<T>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  UpdateWithoutId(data): Observable<T> {
    const url = this.baseurl;
    return this.http
      .put<T>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  Restore(id): Observable<T> {
    const url = this.baseurl + 'restore/' + id;
    return this.http
      .put<T>(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  // DELETE
  Delete(id) {
    const url = this.baseurl + id;
    return this.http
      .delete<T>(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  // Error handling
  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else if ([400, 404, 500, 413, 415].includes(error.status)) {
      errorMessage = error.error;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return throwError(() => {
      return errorMessage;
    });
  }
}
