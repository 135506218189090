import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TransactionRiskActions } from 'src/app/dashboard/constants/lifecycle-actions';
import { addSpaceBeforeCapitalLetter } from 'src/app/dashboard/utils/lifecycle-utils';
import { TranslateService } from '@ngx-translate/core';
import { PolicyRiskDocProcessService } from 'src/app/services/policy-risk-doc-process.service';
import { getAlertHead, getErrorMessage } from 'src/app/utils/utils';
import { getLifecycleSlideout } from 'src/app/store/lifecycle/lifecycle.select';
import { DocumentTemplateService } from 'src/app/services/doc-template.service';
import { AlertService } from 'src/app/services/alert.service';
import { PolicyDetail } from '../../policy-lifecycle-v2/lifecycle-v2-object';
import { DocTemplateSelectionV2Component } from '../doc-template-selection-v2/doc-template-selection-v2.component';

@Component({
  selector: 'app-regenerate-policy-document-popup-v2',
  templateUrl: './regenerate-policy-document-popup-v2.component.html',
  styleUrls: ['./regenerate-policy-document-popup-v2.component.less'],
})
export class RegeneratePolicyDocumentPopupV2Component
  implements OnInit, OnChanges
{
  @ViewChild(DocTemplateSelectionV2Component)
  docTemplateSelection: DocTemplateSelectionV2Component;
  @Input() showModal: boolean = false;
  @Input() details;
  @Input() insuredDetails: PolicyDetail;
  @Output() handleClose = new EventEmitter<any>();
  @Output() handleActionConfirm = new EventEmitter<{ action: any }>();
  @Output() handleSuccess = new EventEmitter<{ action: any }>();

  docOptions = [
    { value: 1, key: 'Certificate', disabled: false },
    { value: 2, key: 'Invoice', disabled: false },
    { value: 3, key: 'Quote', disabled: false },
  ];
  tempOptions = [
    {
      value: 1,
      key: 'Template 1',
      disabled: false,
      tempType: 1,
      dataInjection: true,
    },
    {
      value: 2,
      key: 'Template 2',
      disabled: false,
      tempType: 2,
      dataInjection: true,
    },
    {
      value: 3,
      key: 'Template 3',
      disabled: false,
      tempType: 3,
      dataInjection: true,
    },
  ];
  form: FormGroup;
  isSaveProcessing: boolean = false;
  buttonDisabled: boolean = true;
  templateDisabled: boolean = true;
  statusType: string;
  titleText: string;

  docTempOptions: {
    doc: any;
    docId: any;
    template: any;
    tempId: any;
    tempType: any;
    dataInjection: any;
  }[] = [];
  selectedDoc;
  selectedTemp;
  docLength = 0;
  docSelectedCount = 0;
  docRemainingCount = 0;
  documentTemplateData: any[];
  Document: {
    name: string;
    templates: [];
  };
  allDocuments: Document[] = [];
  policyRiskId;
  docPreviewLoader: boolean = false;
  showDocPreview: boolean = false;
  currentPreviewDocUrl: string = '';
  docTempSelectedOptions: any[][] = [];
  isAlloptionsSelected: { [key: string]: boolean } = {};

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private translate: TranslateService,
    private policyRiskDocProcessService: PolicyRiskDocProcessService,
    private DocumentTemplateService: DocumentTemplateService,
    private alertService: AlertService,
  ) {
    this.form = this.fb.group({
      templateType: [''],
    });
  }
  ngOnInit(): void {
    this.isSaveProcessing = false;

    this.store
      .select(getLifecycleSlideout)
      .pipe(take(1))
      .subscribe((state) => {
        this.policyRiskId = state?.policyRiskId;
      });
    this.documentTemplateData = this.details?.documentTemplate;
  }

  ngOnChanges(): void {
    this.documentTemplateData = this.details?.documentTemplate;
  }

  getActionName(action: TransactionRiskActions) {
    const name = TransactionRiskActions[action];
    return addSpaceBeforeCapitalLetter(name);
  }

  closeHandler() {
    this.resetForm();
    this.handleClose.emit();
  }
  //handle confrim submit action
  async actionConfirmHandler(event) {
    this.alertService.clearAlerts(-1);
    this.isSaveProcessing = true;

    if (this.buttonDisabled) {
      this.isSaveProcessing = false;
      return;
    }
    await Promise.all([this.regenerateDocumentTemplate()]);
    this.isSaveProcessing = false;
    this.handleSuccess.emit({
      action: this.details.action,
    });
    this.resetForm();
  }
  //Reset form
  private resetForm() {
    this.docOptions.forEach((item) => (item.disabled = false));
    this.docSelectedCount = this.docOptions.filter(
      (item) => item.disabled,
    ).length;
    this.docRemainingCount = this.docOptions.filter(
      (item) => !item.disabled,
    ).length;
    this.selectedDoc = '';
    this.selectedTemp = '';
    this.form.get('templateType').setValue('');
    this.buttonDisabled = true;
    this.docTempOptions = [];
    this.isSaveProcessing = false;
  }

  //regenerate DocumentTemplate
  regenerateDocumentTemplate() {
    return new Promise<any>(async (resolve, reject) => {
      let selectedDocTempOptions = this.docTempOptions;
      let existingTemplate =
        this.details.documentTemplate[0]?.templates?.filter(
          (single) => single.docProcessId,
        )[0];
      if (existingTemplate && existingTemplate.docProcessId) {
        if (selectedDocTempOptions.length > 0) {
          selectedDocTempOptions.forEach((p) => {
            const docTempOptionsData = {
              riskTemplateId: p.tempId,
              policyRiskId: this.policyRiskId,
              documentName: p.template,
              documentUniqueName: p.template,
              dataInjection: p.dataInjection,
              templateType: p.tempType,
            };

            const payloadForPolicyRiskDocCreate = {
              policyRiskDocs: [docTempOptionsData],
            };

            this.policyRiskDocProcessService
              .updatePolicyRiskDoc(
                existingTemplate.docProcessId,
                docTempOptionsData,
              )
              .subscribe({
                next: async (resp) => {
                  await this.stageHandler(this.details?.action);
                  resolve(true);
                },
                error: (error) => {
                  let notificationAlert = {
                    type: 'error',
                    headerText: getAlertHead('error'),
                    bodyText: getErrorMessage(error),
                  };
                  this.alertService.addAlert(notificationAlert);
                  this.isSaveProcessing = false;
                  reject(getErrorMessage(error));
                },
              });
          });
        } else {
          reject('error');
        }
      } else {
        reject('error');
      }
    });
  }
  //Genrerate Document for stage
  async generateStageDocument() {
    return new Promise<any>(async (resolve, reject) => {
      this.DocumentTemplateService.generateStageDocument(
        this.details?.policyPeriodId,
        this.details?.stageId,
      ).subscribe({
        next: (resp) => {
          this.buttonDisabled = true;
          resolve(true);
        },
        error: (error) => {
          let notificationAlert = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: getErrorMessage(error),
          };
          this.alertService.addAlert(notificationAlert);
          this.isSaveProcessing = false;
          reject(getErrorMessage(error));
        },
      });
    });
  }

  async stageHandler(action) {
    switch (action.toLowerCase()) {
      case 'policy':
        await this.generateStageDocument();
        break;
      case 'bind':
        break;
      case 'quote':
        break;
      default:
        break;
    }
  }

  handleDocTempSelectionOutput($event) {
    let selectedDocTempOptions = $event;
    let product = selectedDocTempOptions.productId;
    this.docTempSelectedOptions[product] =
      selectedDocTempOptions.selectedTempOptions;
    this.docTempOptions = selectedDocTempOptions.selectedTempOptions;
    this.isAlloptionsSelected[product] =
      selectedDocTempOptions.isAlloptionsSelected;
    if (
      Object.values(this.isAlloptionsSelected).every((value) => value === true)
    ) {
      this.buttonDisabled = false;
    } else {
      this.buttonDisabled = true;
    }
  }

  handleClearForm() {
    this.resetForm();
    this.docTemplateSelection.resetForm();
  }
}
