<div class="send-quote-modal" *ngIf="showModal">
  <div class="modal-container">
    <div class="loader" *ngIf="isLoader">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
      <p class="loading-msg">{{ "common.generatingDocument" | translate }}</p>
      <div class="doc-progress-section" *ngFor="let document of docData">
        <div class="individual-doc-progress-section">
          <boxxds-body
            class="doc-name"
            sz="sm"
            [text]="document?.documentName"
            [customStyle]="{ color: '#8F8F91' }"
          >
          </boxxds-body>
          <boxxds-body
            class="doc-name-progress-status-separator"
            sz="sm"
            [text]="'---'"
            [customStyle]="{ color: '#8F8F91' }"
          >
          </boxxds-body>
          <boxxds-body
            class="doc-progress-status"
            sz="sm"
            [text]="document?.status"
            [customStyle]="{ color: '#8F8F91' }"
          >
          </boxxds-body>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!isLoader">
      <div class="box">
        <span style="--i: 1"></span>
        <span style="--i: 2"></span>
        <span style="--i: 3"></span>
        <span style="--i: 4"></span>
        <span style="--i: 5"></span>
        <span style="--i: 6"></span>
        <span style="--i: 7"></span>
        <span style="--i: 8"></span>
        <span style="--i: 9"></span>
        <span style="--i: 10"></span>
      </div>
      <div class="header">
        <div class="icon">
          <img src="assets/img/mail-v2.svg" />
        </div>

        <div class="close">
          <button (click)="!this.isLoader && handleCloseModal()">
            <img src="assets/img/close-v2.svg" />
          </button>
        </div>
      </div>
      <div class="popup-title">
        <div class="heading">
          <boxxds-heading
            [sz]="'7'"
            [text]="title"
            type="bold"
          ></boxxds-heading>
        </div>
      </div>
      <div class="docPreviewLoader" *ngIf="docPreviewLoader && !isLoader">
        <div class="wrapper">
          <div class="close-btn">
            <button
              (click)="closeDocPreview()"
              [disabled]="docPreviewLoader && !isLoader"
            >
              <img src="assets/img/dashboard/card/close.png" />{{
                "common.close" | translate
              }}
            </button>
          </div>
          <div class="loader-section">
            <boxxds-spinner
              [containerStyle]="{
                width: '112px',
                height: '112px',
                '--b': '15px'
              }"
            ></boxxds-spinner>
            <p class="loading-msg">
              {{ "common.generatingDocumentPreview" | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="docPreviewContainer">
        <app-document-preview
          *ngIf="showDocPreview && !docPreviewLoader && currentPreviewDocUrl"
          [docUrl]="currentPreviewDocUrl"
          (handleClose)="closeDocPreview()"
        >
        </app-document-preview>
      </div>
      <div class="send-quote-body">
        <div class="form" [formGroup]="">
          <div class="form-group">
            <label
              >{{
                "workFlow3.components.policyConfiguration.label.sendto"
                  | translate
              }}
            </label>
            <div class="send-to">
              <div class="info-grid">
                <div class="info-item" *ngIf="emailData['insured'] !== ''">
                  <app-checkbox-v2
                    [isDisabled]="false"
                    [label]="
                      'Insured ' +
                      (isChecked('insured') ? 'included' : 'include')
                    "
                    [sublabel]="sendToNames['insuredName']"
                    [isDisabled]="sendToNames['isDisabled']"
                    (handleCheckbox)="handleCheckbox($event, 0)"
                    [containerStyle]="{ display: 'flex' }"
                    [checked]="isChecked('insured')"
                  >
                  </app-checkbox-v2>
                </div>
                <div
                  class="info-item"
                  *ngIf="
                    emailData['broker'] !== '' || emailData['producer'] !== ''
                  "
                >
                  <app-checkbox-v2
                    [isDisabled]="false"
                    [label]="
                      'CC Broker ' +
                      (isChecked('broker/producer') ? 'included' : 'include')
                    "
                    [sublabel]="sendToNames['broker']"
                    (handleCheckbox)="handleCheckbox($event, 2)"
                    [containerStyle]="{ display: 'flex' }"
                    [checked]="isChecked('broker/producer')"
                  >
                  </app-checkbox-v2>
                </div>
                <div class="info-item">
                  <app-checkbox-v2
                    [isDisabled]="false"
                    [label]="'Underwriter on risk insured'"
                    [sublabel]="sendToNames['underwriter']"
                    [checked]="isChecked('underwriter')"
                    [isDisabled]="true"
                    (handleCheckbox)="handleCheckbox($event, 1)"
                    [containerStyle]="{ display: 'flex' }"
                  >
                  </app-checkbox-v2>
                </div>
              </div>
            </div>
            <div class="template-select">
              <div class="temp-div">
                <boxxds-dropdown-v2
                  [id]="'productId'"
                  [form]=""
                  [control]="'productId'"
                  [options]="templateOptions"
                  [label]="
                    'workFlow3.components.policyConfiguration.label.selectEmailTemplate'
                      | translate
                  "
                  [btnStyle]="{
                    marginTop: '8px',
                    minWidth: '224px',
                    maxWidth: '230px'
                  }"
                  [dropdownContainerStyle]="{
                    minWidth: '224px',
                    maxWidth: '230px'
                  }"
                  class="input-fields"
                  [formSubmitted]=""
                  (currentValueChange)="handleEmailTemplate($event)"
                  [placeholder]="templateSelectionPlaceholder"
                ></boxxds-dropdown-v2>
                <!-- workflow3.components.policyConfiguration.label.QuotePackageTemplate,  -->
                <boxxds-textinput-v2
                  [form]="form"
                  [placeholder]="
                    'workFlow3.components.policyConfiguration.placeholder.emailSubject'
                      | translate
                  "
                  [label]="
                    'workFlow3.components.policyConfiguration.label.emailSubject'
                      | translate
                  "
                  control="subject"
                  [id]="'search'"
                  [width]="'367px'"
                >
                </boxxds-textinput-v2>
              </div>
              <div class="message-container" [formGroup]="form">
                <label> {{ "emailPopup.messageBody" | translate }}</label>
                <div>
                  <angular-editor
                    formControlName="message"
                    [config]="editorConfig"
                  ></angular-editor>
                </div>
              </div>
            </div>
          </div>
          <div class="documents">
            <div class="doc-header">
              <h1>
                {{
                  "workFlow3.components.policyConfiguration.label.documentsIncluded"
                    | translate
                }}
              </h1>
            </div>
            <div class="doc-body">
              <ng-container
                *ngFor="let doc of docsAttached; let selectedIdx = index"
              >
                <div class="doc">
                  <a
                    class="doc-name"
                    [title]="doc"
                    (click)="handleDocPreview(doc)"
                  >
                    {{ doc }}
                  </a>
                  <a
                    type="button"
                    aria-label="close"
                    class="close-btn"
                    (click)="handleDocRemove(doc)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M9 3L3 9M3 3L9 9"
                        stroke="#3BDF77"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </ng-container>
              <ng-container
                *ngFor="let doc of getUnselectedDocs(); let i = index"
              >
                <div class="rqd-doc">
                  <div class="d-flex">
                    <button (click)="handleAttachDoc(doc.value)">
                      <img [src]="'assets/img/plus-circle-dark.png'" />
                    </button>
                    <button
                      class="rq-doc-name"
                      [title]="doc.key"
                      [ngStyle]="{
                        overflowWrap: 'break-word',
                        maxWidth: '176px',
                        textAlign: 'left'
                      }"
                      (click)="handleDocPreview(doc.key)"
                    >
                      {{ doc.key + (doc.mandatory ? "*" : "") }}
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="action-buttons">
        <div class="modal-buttons">
          <boxxds-button-v2
            *ngIf="!isSending"
            buttonText="Send"
            [type]="'submitted'"
            [btnStyle]="{
              padding: '0 28px',
              height: '32px',
              marginLeft: 'auto'
            }"
            [isDisabled]="isSendDisabled()"
            [size]="'lg'"
            [btnStyle]="{ width: '187px' }"
            [btnClass]="'primary'"
            [btnType]="'default'"
            [isDisabled]="isSendDisabled()"
            (handleClick)="sendHandler()"
          >
          </boxxds-button-v2>
          <button
            *ngIf="isSending"
            class="btn-processing"
            tabindex="0"
            [ngStyle]="{
              border: '1px solid #FFBE3D',
              borderRadius: '8px',
              width: '88px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '4px 8px',
              background: '#FCF8EF',
              marginLeft: 'auto'
            }"
          >
            <boxxds-spinner></boxxds-spinner>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
