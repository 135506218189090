<div class="holder">
  <boxxds-heading
    sz="5"
    [customStyle]="{ marginBottom: '12px' }"
    [text]="'Product Summary' | translate"
    ><span></span>
  </boxxds-heading>
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>
  <div class="alert-container">
    <div *ngFor="let alert of alerts; let i = index">
      <boxxds-alert
        [containerStyle]="{ position: 'relative', top: 'unset' }"
        (handleCloseEvent)="handleNotificationAlertClose(i)"
        *ngIf="alert?.show"
        [type]="alert?.type"
        [headerText]="alert?.headerText | translate"
        [bodyText]="alert?.bodyText | translate"
      >
      </boxxds-alert>
    </div>
  </div>

  <ng-container *ngIf="!showBodySpinner">
    <div class="insured-name">
      {{ (form$ | async)?.ui.insuredName }} - {{ (form$ | async)?.ui.policyId }}
    </div>
    <div class="date-details-head">
      <boxxds-body
        [text]="'common.dateDetails' | translate"
        sz="md"
        [customStyle]="{ color: '#616162', marginBottom: '8px' }"
      ></boxxds-body>
      <boxxds-body
        sz="xs"
        [text]="'*' + ('REQUIRED' | translate)"
        [customStyle]="{ color: '#8F8F91' }"
      ></boxxds-body>
    </div>
    <div class="date-details">
      <ng-container *ngIf="form$ | async as asyncForm">
        <boxxds-datepicker
          [placeholder]="shortDateFormat"
          [dateFormat]="shortDateFormat"
          label="Select effective date*"
          [containerStyle]="{ width: '186px' }"
          [bsValue]="(form$ | async)?.startDate"
          (bsValueChange)="
            this.permissionList[this.currentScreen] &&
              this.currentQuoteStatus !== 'quote-closed' &&
              handleStartDateChange($event, asyncForm.endDate)
          "
          [minDate]="minStartDate"
          [maxDate]="maxStartDate"
          [showErr]="!isStartDateValid"
          [errMsg]="startDateErrMsg | translate"
          [isDisabled]="
            !this.permissionList[this.currentScreen] ||
            this.currentQuoteStatus === 'quote-closed'
          "
        >
        </boxxds-datepicker>
      </ng-container>
      <div class="end-date">
        <label class="lbl-end-date">Expired date*</label>
        <div class="text-date" *ngIf="!endDateEditable">
          <p>
            {{
              (form$ | async)?.endDate
                ? (form$ | async)?.endDate
                : shortDateFormat
            }}
          </p>
          <button
            *ngIf="
              this.permissionList[this.currentScreen] &&
              this.currentQuoteStatus !== 'quote-closed'
            "
            class="btn-edit"
            (click)="endDateEditable = !endDateEditable"
          >
            <img src="assets/img/edit-icon.png" alt="edit" />
          </button>
        </div>
        <ng-container *ngIf="form$ | async as asyncForm">
          <boxxds-datepicker
            *ngIf="endDateEditable"
            [bsValue]="(form$ | async)?.endDate"
            (bsValueChange)="handleEndDateChange($event, asyncForm.startDate)"
            [placeholder]="shortDateFormat"
            [dateFormat]="shortDateFormat"
            [containerStyle]="{ width: '186px' }"
            [minDate]="minEndDate"
            [maxDate]="maxEndDate"
            [showErr]="!isEndDateValid"
            [errMsg]="endDateErrMsg | translate"
          >
          </boxxds-datepicker>
        </ng-container>
      </div>
    </div>

    <div class="quote">
      <div
        class="product"
        *ngFor="let product of getSelectedQuote(quoteOptions); let i = index"
      >
        <div class="product-header">
          {{ products[i] }}
        </div>
        <div class="pdt-details">
          <app-document-preview
            *ngIf="showDocPreview"
            [docUrl]="currentPreviewDocUrl"
            (handleClose)="toggleDocPreview()"
          >
          </app-document-preview>
          <div class="underwriter-col">
            <boxxds-dropdown
              [disabled]="
                !this.permissionList[this.currentScreen] ||
                this.currentQuoteStatus === 'quote-closed' ||
                disabledDropdownList.includes(products[i])
              "
              [label]="'Underwriter assigned to risk:' | translate"
              [placeholder]="'Select' | translate"
              [options]="underWriterOptions"
              (currentValueChange)="
                this.permissionList[this.currentScreen] &&
                  this.currentQuoteStatus !== 'quote-closed' &&
                  underwriterChanged($event, i)
              "
              [currentValue]="underWriters[i]"
              (loadMoreOptions)="
                this.permissionList[this.currentScreen] &&
                  this.currentQuoteStatus !== 'quote-closed' &&
                  loadMoreUnderwriters($event)
              "
              [hasScrollablePagination]="true"
              [totalCount]="underwriterTotalCount"
              [totalPages]="underwriterTotalPages"
              [dropdownContainerStyle]="{ width: '186px' }"
              [lblStyle]="{ color: '#BDBDBF' }"
            >
            </boxxds-dropdown>
          </div>
        </div>
        <div class="grid-section gap-3 justify-content-between">
          <div class="grid-column">
            <div class="endorsement docs">
              <label class="lbl-grid-column">
                {{ "Endorsement" | translate }}
              </label>
              <ng-container
                *ngIf="
                  this.permissionList[this.currentScreen] &&
                  this.currentQuoteStatus !== 'quote-closed'
                "
              >
                <boxxds-searchable-dropdown
                  [label]="'Add additional endorsement' | translate"
                  [placeholder]="'Select' | translate"
                  [options]="endorsementOptions[products[i]]"
                  [dispatchTextInputChange]="true"
                  (textInputChangeEventEmmiter)="
                    handleEndorsementSearch($event, products[i])
                  "
                  (currentValueChange)="
                    handleAttachEndorsement($event, products[i])
                  "
                  [btnStyle]="{ height: '36px' }"
                  [hideSelectedValue]="true"
                  [dropdownContainerStyle]="{
                    width: '186px',
                    marginBottom: '19px',
                    marginLeft: '26px'
                  }"
                  class="search-dropdown"
                >
                </boxxds-searchable-dropdown>
              </ng-container>
              <div
                class="hdr-docs"
                *ngIf="selectedEndorsements[products[i]]?.length > 0"
              >
                {{ " List of predetermined endorsements" | translate }}
              </div>
              <div class="documents-holder">
                <div
                  class="rqd-doc"
                  *ngIf="selectedEndorsements[products[i]]?.length > 0"
                >
                  <div
                    class="doc d-flex"
                    *ngFor="
                      let doc of selectedEndorsements[products[i]];
                      let selectIdx = index
                    "
                  >
                    <button
                      [disabled]="
                        !this.permissionList[this.currentScreen] ||
                        this.currentQuoteStatus === 'quote-closed'
                      "
                      [class.noPointerEvents]="
                        !this.permissionList[this.currentScreen] ||
                        this.currentQuoteStatus === 'quote-closed'
                      "
                      (click)="
                        this.permissionList[this.currentScreen] &&
                          this.currentQuoteStatus !== 'quote-closed' &&
                          toggleEndorsementSelect(selectIdx, products[i])
                      "
                    >
                      <img
                        [src]="
                          doc.selected
                            ? 'assets/img/plus-circle-filled.png'
                            : 'assets/img/plus-circle.png'
                        "
                      />
                    </button>
                    <button
                      [ngStyle]="{
                        overflowWrap: 'break-word',
                        maxWidth: '300px',
                        textAlign: 'left'
                      }"
                      (click)="handleDocClick(doc)"
                    >
                      {{ doc.key + (doc.mandatory ? "*" : "") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="grid-column">
            <div class="subjectives docs">
              <label class="lbl-grid-column">
                {{ "Subjectivities" | translate }}
              </label>
              <ng-container
                *ngIf="
                  this.permissionList[this.currentScreen] &&
                  this.currentQuoteStatus !== 'quote-closed'
                "
              >
                <boxxds-create-dropdown
                  [label]="'Add additional subjectivities' | translate"
                  [placeholder]="'Select' | translate"
                  [options]="subjectivityOptns[products[i]]"
                  [dispatchTextInputChange]="true"
                  (textInputChangeEventEmmiter)="
                    handleSubjectivitySearch($event, products[i])
                  "
                  (currentValueChange)="
                    handleAddSubjectives($event, products[i])
                  "
                  (createNew)="createNewSubjectivity($event, products[i])"
                  [btnStyle]="{ height: '36px' }"
                  [hideSelectedValue]="true"
                  [dropdownArrow]="true"
                  [createUndefined]="true"
                  [createText]="'Add '"
                  [numberOfField]="1"
                  [dropdownContainerStyle]="{
                    width: 'calc(100% - 26px)',
                    marginBottom: '19px',
                    marginLeft: '26px'
                  }"
                  class="search-dropdown"
                >
                </boxxds-create-dropdown>
              </ng-container>
              <div
                class="hdr-docs"
                *ngIf="selectedSubjectivities[products[i]]?.length > 0"
              >
                {{ "List of subjectivities:" | translate }}
              </div>
              <div class="documents-holder">
                <div
                  class="rqd-doc"
                  *ngIf="selectedSubjectivities[products[i]]?.length > 0"
                >
                  <div
                    class="subjectivity-list"
                    *ngFor="
                      let sub of selectedSubjectivities[products[i]];
                      let selectedIdx = index
                    "
                  >
                    <button
                      [ngStyle]="{
                        overflowWrap: 'break-word',
                        maxWidth: '300px',
                        textDecoration: 'none',
                        textAlign: 'left'
                      }"
                      [disabled]="
                        !this.permissionList[this.currentScreen] ||
                        this.currentQuoteStatus === 'quote-closed'
                      "
                      [class.noPointerEvents]="
                        !this.permissionList[this.currentScreen] ||
                        this.currentQuoteStatus === 'quote-closed'
                      "
                      (click)="
                        this.permissionList[this.currentScreen] &&
                          this.currentQuoteStatus !== 'quote-closed' &&
                          handleToggleSubSelect(selectedIdx, products[i], sub)
                      "
                    >
                      <img
                        [src]="
                          sub.selected
                            ? 'assets/img/plus-circle-filled.png'
                            : 'assets/img/plus-circle.png'
                        "
                      />
                    </button>
                    <div class="listKey">
                      <ng-container *ngIf="!sub.edit; else subEdit">
                        {{
                          (sub.key | truncateText: 50 : "...") +
                            (sub.mandatory ? "*" : "")
                        }}
                      </ng-container>
                      <ng-template #subEdit>
                        <textarea
                          type="text"
                          class="listKey-textArea"
                          [(ngModel)]="editedSubjectivity"
                          [value]="editedSubjectivity"
                        ></textarea>
                      </ng-template>
                    </div>
                    <div class="manipulateIconContainer">
                      <ng-container *ngIf="!sub.edit; else subEditIcons">
                        <!-- <span
                          class="manipulateIcon"
                          *ngIf="!sub.mandatory"
                          (click)="
                            deleteSubjectivity(products[i], sub, selectedIdx)
                          "
                        >
                          <img src="assets/img/trash-2.png" />
                        </span> -->
                        <span
                          class="manipulateIcon editIcon"
                          *ngIf="isNewSubjectivity(products[i], sub)"
                          (click)="editSubjectivity(products[i], sub, 1)"
                        >
                          <img src="assets/img/edit-icon.png" />
                        </span>
                      </ng-container>
                      <ng-template #subEditIcons>
                        <span
                          class="manipulateIcon"
                          (click)="confirmEditSubjectivity(products[i], sub)"
                        >
                          <img src="assets/img/check.png" />
                        </span>
                        <span
                          class="manipulateIcon editIcon"
                          (click)="editSubjectivity(products[i], sub, 0)"
                        >
                          <img src="assets/img/cancel-edit.png" />
                        </span>
                      </ng-template>
                    </div>
                    <div class="radio-btn" *ngIf="!sub.edit">
                      <span class="radio-group-label"
                        >{{ "productSummary.label.comply" | translate }}?
                      </span>
                      <input
                        type="radio"
                        value="true"
                        [required]="true"
                        [checked]="sub.isCompliedWith === true"
                        [name]="sub.id + '_' + i + '_' + selectedIdx"
                        [id]="sub.id + '_' + i + '_' + selectedIdx"
                        (change)="
                          this.permissionList[this.currentScreen] &&
                            this.currentQuoteStatus !== 'quote-closed' &&
                            updateCompliance(sub, true)
                        "
                        [disabled]="
                          !this.permissionList[this.currentScreen] ||
                          this.currentQuoteStatus === 'quote-closed'
                        "
                      />
                      <label [for]="sub.id + '_' + i + '_' + selectedIdx">{{
                        "Yes" | translate
                      }}</label>
                      <input
                        type="radio"
                        value="false"
                        [required]="true"
                        [checked]="sub.isCompliedWith === false"
                        [name]="sub.id + '_' + i + '_' + selectedIdx"
                        [id]="sub.id + '_' + i + '_' + selectedIdx"
                        (change)="
                          this.permissionList[this.currentScreen] &&
                            this.currentQuoteStatus !== 'quote-closed' &&
                            updateCompliance(sub, false)
                        "
                        [disabled]="
                          !this.permissionList[this.currentScreen] ||
                          this.currentQuoteStatus === 'quote-closed'
                        "
                      />
                      <label [for]="sub.id + '_' + i + '_' + selectedIdx">{{
                        "No" | translate
                      }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="grid-column">
            <div class="docs">
              <div class="sub-section">
                <label class="lbl-grid-column">
                  {{ "Wording" | translate }}
                </label>
                <ng-container
                  *ngIf="
                    this.permissionList[this.currentScreen] &&
                    this.currentQuoteStatus !== 'quote-closed'
                  "
                >
                  <boxxds-searchable-dropdown
                    [label]="'Add wording selection' | translate"
                    [placeholder]="'Select' | translate"
                    [options]="wordingsOptions[products[i]]"
                    (currentValueChange)="
                      handleSelectWording($event, products[i])
                    "
                    [btnStyle]="{ height: '36px' }"
                    [dispatchTextInputChange]="true"
                    (textInputChangeEventEmmiter)="
                      handleWordingSearch($event, products[i])
                    "
                    [hideSelectedValue]="true"
                    [dropdownContainerStyle]="{
                      width: '186px',
                      marginLeft: '26px',
                      marginBottom: '19px'
                    }"
                    class="search-dropdown"
                  >
                  </boxxds-searchable-dropdown>
                </ng-container>
                <div
                  class="hdr-docs"
                  *ngIf="selectedWordings[products[i]]?.length > 0"
                >
                  {{ "List of wordings:" | translate }}
                </div>
                <div class="documents-holder">
                  <div
                    class="rqd-doc"
                    *ngIf="selectedWordings[products[i]]?.length > 0"
                  >
                    <div
                      class="doc d-flex"
                      *ngFor="
                        let doc of selectedWordings[products[i]];
                        let selectedIdx = index
                      "
                    >
                      <button
                        [disabled]="
                          !this.permissionList[this.currentScreen] ||
                          this.currentQuoteStatus === 'quote-closed'
                        "
                        [class.noPointerEvents]="
                          !this.permissionList[this.currentScreen] ||
                          this.currentQuoteStatus === 'quote-closed'
                        "
                        (click)="
                          this.permissionList[this.currentScreen] &&
                            this.currentQuoteStatus !== 'quote-closed' &&
                            toggleWordingSelect(selectedIdx, products[i])
                        "
                      >
                        <img
                          [src]="
                            doc.selected
                              ? 'assets/img/plus-circle-filled.png'
                              : 'assets/img/plus-circle.png'
                          "
                        />
                      </button>
                      <button
                        [ngStyle]="{
                          overflowWrap: 'break-word',
                          maxWidth: '300px',
                          textAlign: 'left'
                        }"
                        (click)="handleDocClick(doc)"
                      >
                        {{ doc.key + (doc.mandatory ? "*" : "") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sub-section">
                <label class="lbl-grid-column">
                  {{ "Declaration" | translate }}
                </label>
                <ng-container
                  *ngIf="
                    this.permissionList[this.currentScreen] &&
                    this.currentQuoteStatus !== 'quote-closed'
                  "
                >
                  <boxxds-searchable-dropdown
                    [label]="'Add declaration document' | translate"
                    [placeholder]="'Select' | translate"
                    [options]="declarationOptions[products[i]]"
                    (currentValueChange)="
                      handleSelectDeclaration($event, products[i])
                    "
                    [btnStyle]="{ height: '36px' }"
                    [dispatchTextInputChange]="true"
                    (textInputChangeEventEmmiter)="
                      handleDeclarationSearch($event, products[i])
                    "
                    [hideSelectedValue]="true"
                    [dropdownContainerStyle]="{
                      width: '186px',
                      marginLeft: '26px',
                      marginBottom: '19px'
                    }"
                    class="search-dropdown"
                  >
                  </boxxds-searchable-dropdown>
                </ng-container>
                <div
                  class="hdr-docs"
                  *ngIf="selectedDeclarations[products[i]]?.length > 0"
                >
                  {{ "List of declaration document:" | translate }}
                </div>
                <div class="documents-holder">
                  <div
                    class="rqd-doc"
                    *ngIf="selectedDeclarations[products[i]]?.length > 0"
                  >
                    <div
                      class="doc d-flex"
                      *ngFor="
                        let doc of selectedDeclarations[products[i]];
                        let selectedIdx = index
                      "
                    >
                      <button
                        [disabled]="
                          !this.permissionList[this.currentScreen] ||
                          this.currentQuoteStatus === 'quote-closed'
                        "
                        [class.noPointerEvents]="
                          !this.permissionList[this.currentScreen] ||
                          this.currentQuoteStatus === 'quote-closed'
                        "
                        (click)="
                          this.permissionList[this.currentScreen] &&
                            this.currentQuoteStatus !== 'quote-closed' &&
                            toggleDeclarationSelect(selectedIdx, products[i])
                        "
                      >
                        <img
                          [src]="
                            doc.selected
                              ? 'assets/img/plus-circle-filled.png'
                              : 'assets/img/plus-circle.png'
                          "
                        />
                      </button>
                      <button
                        [ngStyle]="{
                          overflowWrap: 'break-word',
                          maxWidth: '300px',
                          textAlign: 'left'
                        }"
                        (click)="handleDocClick(doc)"
                      >
                        {{ doc.key + (doc.mandatory ? "*" : "") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div
            class="addons"
            *ngIf="addons[products[i]] && addons[products[i]].length > 0"
          >
            <div class="addson-hdr">
              {{ "Selected Cyber add ons:" | translate }}
            </div>
            <div class="addson-holder d-flex justify-content-between gap-3">
              <ng-container
                *ngFor="let addon of addons[products[i]]; let addonIdx = index"
              >
                <div class="checkbox">
                  <div class="content">
                    <span class="label">{{ addon?.serviceName || "" }}</span>
                    <div class="description">
                      {{ addon?.description || "" }}
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="divider" *ngIf="!(quoteOptions.length - 1 === i)"></div>
      </div>
    </div>

    <div class="continue-btn-holder">
      <boxxds-button
        [buttonText]="'Back' | translate"
        btnType="secondary"
        [btnStyle]="{ width: '88px' }"
        type="submit"
        (handleClick)="handleBackBtn()"
        class="back-btn"
      >
      </boxxds-button>
      <div class="save-continue">
        <ng-container
          *ngIf="
            this.permissionList[this.currentScreen] &&
            this.currentQuoteStatus !== 'quote-closed'
          "
        >
          <boxxds-button
            *ngIf="!showSpinner"
            [buttonText]="'common.saveDraft' | translate"
            btnType="secondary"
            [btnStyle]="{ width: '122px', marginRight: '12px' }"
            type="submit"
            (handleClick)="handleSaveDraft($event)"
          >
          </boxxds-button>
        </ng-container>
        <button
          *ngIf="showSpinner"
          class="btn-processing"
          tabindex="0"
          [ngStyle]="{
            border: '1px solid #9090AF',
            borderRadius: '8px',
            width: '120px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '3px 6px',
            background: '#FCF8EF'
          }"
        >
          <boxxds-spinner></boxxds-spinner>
        </button>
        <boxxds-button
          *ngIf="!showContinueSpinner"
          [buttonText]="'submission.continueToStep6Of7' | translate"
          btnType="secondary-solid"
          (handleClick)="handleContinue($event)"
          [btnStyle]="{ width: '199px' }"
        >
        </boxxds-button>
        <button
          *ngIf="showContinueSpinner"
          class="btn-processing"
          tabindex="0"
          [ngStyle]="{
            border: '1px solid #9090AF',
            borderRadius: '8px',
            width: '120px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '3px 6px',
            background: '#FCF8EF'
          }"
        >
          <boxxds-spinner></boxxds-spinner>
        </button>
      </div>
    </div>
  </ng-container>
</div>
