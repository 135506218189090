import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from './base-services';
import { Region } from '../entities/region';
import { Observable, retry, catchError, lastValueFrom } from 'rxjs';
import { BoxxResponse } from '../entities/boxx-response';
import { Domains } from '../models/domains';
import { DropdownListDto } from '../dtos/dropdownList.dto';

@Injectable({
  providedIn: 'root',
})
export class DomainsService extends BaseService<Domains> {
  constructor(http: HttpClient) {
    const baseurl =
      environment.msConfigurationBaseUrl + environment.domainPathUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  public GetByDomainCode(
    domaincode,
    active?: boolean,
  ): Observable<BoxxResponse<Domains>> {
    return this.http
      .get<BoxxResponse<Domains>>(
        `${this.baseurl}?domaincode=${domaincode}${
          active !== undefined ? `&active=${active}` : ``
        }&page=1&limit=60&sort=id%3AASC`,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  async GetDomainListAsync(domaincode: string): Promise<DropdownListDto[]> {
    const service$ = this.GetByDomainCode(domaincode);
    const results = await lastValueFrom(service$);

    return results.data.map((data: Domains) => {
      const dto: DropdownListDto = {
        id: data.id,
        key: data.id.toString(),
        value: data.description,
      };
      return dto;
    });
  }
}
