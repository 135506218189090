<div class="holder">
  <boxxds-alert
    *ngIf="showErrorAlert"
    (handleCloseEvent)="handleCloseSuccessEvent()"
    type="error"
    headerText="error!"
    [bodyText]="msgErrorAlert"
  >
  </boxxds-alert>
  <app-filter
    [show]="showFilter"
    (handleFiltering)="handleFiltering($event)"
    [inputForm]="filterForm"
  ></app-filter>
  <boxxds-breadcrumb [items]="itemsMenu"></boxxds-breadcrumb>
  <div class="alert-holder">
    <boxxds-alert
      (handleCloseEvent)="producerCreatedAlertClose()"
      *ngIf="isNavigatedFromAddProducer"
      type="success"
      headerText="success!"
      bodyText="Producer saved successfully."
      [containerStyle]="{
        width: 'calc(100vw - 320px)',
        maxWidth: '1115px',
        position: 'fixed',
        top: '100px',
        zIndex: '2',
        marginTop: '8px'
      }"
    >
    </boxxds-alert>
    <boxxds-alert
      (handleCloseEvent)="handleNotificationAlertClose()"
      *ngIf="notificationAlert?.show"
      [type]="notificationAlert?.type"
      [headerText]="notificationAlert?.headerText"
      [bodyText]="notificationAlert?.bodyText"
    >
    </boxxds-alert>
  </div>
  <div class="header">
    <div class="profile-details d-flex">
      <div class="photo-holder">
        <ngx-avatars
          *ngIf="producerProfile?.headShotImage; else producerProfileNameAvatar"
          class="profile-photo"
          [ngStyle]="{
            'background-image': producerProfile?.headShotImage
              ? 'url(' + producerProfile?.headShotImage + ')'
              : 'none',
            'background-size': 'cover'
          }"
          [textSizeRatio]="1.5"
        ></ngx-avatars>
        <ng-template #producerProfileNameAvatar>
          <ngx-avatars
            *ngIf="this.producer"
            class="profile-photo"
            [name]="this.producer?.firstName + ' ' + this.producer?.lastName"
            [textSizeRatio]="1.5"
          ></ngx-avatars>
        </ng-template>
        <ng-container *ngIf="this.permissionList[this.currentScreen]">
          <div class="edit-details-container">
            <div
              role="link"
              class="edit-details"
              (click)="handleEditDetails($event)"
              *ngIf="editProducerUrl !== null"
            >
              edit details
            </div>
          </div>
        </ng-container>
      </div>
      <div class="name-holder" *ngIf="producer">
        <boxxds-heading
          sz="5"
          [text]="producer?.firstName + ' ' + producer?.lastName"
        ></boxxds-heading>
        <boxxds-body
          sz="xs"
          [text]="this.producer?.brokerageBranch?.brokerage?.name"
          [customStyle]="{
            color: '#8F8F91',
            marginTop: '4px',
            fontSize: '12px',
            lineHeight: '18px',
            textAlign: 'left'
          }"
        >
        </boxxds-body>
        <boxxds-body
          sz="xs"
          [text]="this.producer?.brokerageBranch?.name"
          [customStyle]="{
            color: '#8F8F91',
            marginTop: '2px',
            fontSize: '12px',
            lineHeight: '18px',
            textAlign: 'left'
          }"
        >
        </boxxds-body>
        <boxxds-body
          sz="xs"
          [text]="producer?.email"
          [customStyle]="{
            color: '#8F8F91',
            marginTop: '14px',
            fontSize: '12px',
            lineHeight: '18px',
            textAlign: 'left'
          }"
        >
        </boxxds-body>
        <boxxds-body
          sz="xs"
          [text]="formatPhoneNumber(producer?.telephone)"
          [customStyle]="{
            color: '#8F8F91',
            marginTop: '2px',
            fontSize: '12px',
            lineHeight: '18px',
            textAlign: 'left'
          }"
        >
        </boxxds-body>
      </div>
    </div>
    <div class="info-tiles d-flex">
      <app-info-tile
        [showRedirectLink]="false"
        *ngFor="let i of infoTile"
        [heading]="i.heading | translate"
        [count]="i.count"
      ></app-info-tile>
    </div>
  </div>

  <div class="content">
    <div class="d-flex justify-content-between">
      <div class="sub-nav">
        <ul>
          <li *ngFor="let nav of subNavData; let i = index">
            <button
              [ngClass]="{ active: nav.active }"
              (click)="handleNav($event, i)"
            >
              {{ nav.name }}
            </button>
          </li>
        </ul>
      </div>
      <div class="d-flex align-items-center">
        <boxxds-button
          [isBtnActive]="showFilter"
          [leftImage]="showFilter ? 'filter-filled' : 'filter'"
          btnType="unfilled"
          buttonText="FILTERS"
          [type]="'submitted'"
          [btnStyle]="{ width: '65px', marginRight: '24px' }"
          (handleClick)="handleFilter()"
        >
        </boxxds-button>
        <boxxds-button
          leftImage="download"
          btnType="unfilled"
          buttonText="EXPORT"
          [type]="'submitted'"
          [btnStyle]="{ width: '65px' }"
          (handleClick)="handleExport()"
        >
        </boxxds-button>
      </div>
    </div>
    <boxxds-table
      [data]="tableData"
      [showSpinner]="showTblSpinner"
      [totalCount]="totalDataCount"
      (pageChanged)="pageChangeHandler($event)"
      class="table"
      (sortUpClicked)="handleAscSort($event)"
      (sortDownClicked)="handleDescSort($event)"
      (rowClicked)="quoteClickHandler($event)"
      [addSortTo]="addSortTo"
      id="policy-table"
      [tableStyle]="{ marginBottom: '30px' }"
      [mapStatusBadge]="statusMappingToType"
      [showStatusAsBadge]="true"
      [showBadgeFieldName]="'Status'"
      [activePageNumber]="pageNumber"
      [isRowClickable]="true"
      [showStatusAsBadge]="true"
    >
    </boxxds-table>
    <div
      class="timestamp-container"
      [ngClass]="{ 'no-pagination': totalDataCount <= 10 }"
    >
      <app-timestamp
        label="Created by"
        [value]="createdBy"
        [valueStyle]="{ marginBottom: '0px' }"
      >
      </app-timestamp>
      <app-timestamp
        *ngIf="updatedBy"
        label="Updated last by"
        [value]="updatedBy"
        [valueStyle]="{ marginBottom: '0px' }"
      >
      </app-timestamp>
    </div>
  </div>
</div>
