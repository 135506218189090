import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription, forkJoin, take } from 'rxjs';
import { IBaseDto } from 'src/app/dtos/baseDto';
import { Breadcrumb } from 'src/app/models/breadcrumb.model';
import { IBrokerageModel } from 'src/app/models/brokerage.model';
import { CommissionState } from 'src/app/store/commission/commission.reducer';

import {
  getFormGroup,
  mapFormGroupToModel,
  checkRegexPatternMainWebsite,
  checkRegexPatternMainEmail,
  checkRegexPatternMultidomain,
} from 'src/app/pages/brokerage/new-brokerage/factorys/formGroup.factory';
import { BrokerageService } from 'src/app/services/brokerage.service';
import { ImageLogoSubjectService } from 'src/app/components/subjects/image-logo.service';
import { resetCommissions } from 'src/app/store/commission/commission.action';
import {
  resetBrokerageCreated,
  setBrokerageCreated,
} from 'src/app/store/brokerage/brokerage.action';
import { ParentCompanySubjectService } from 'src/app/components/subjects/parent-company.service';
import { ViewportScroller } from '@angular/common';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import {
  saveBrokerageParentCompany,
  saveBrokerageProfileAction,
} from 'src/app/store/brokerageProfile/brokerageProfile.action';
import { BrokerageParentCompany } from 'src/app/entities/brokerage-parent-company';
import { getBrokerageCompanyDetails } from 'src/app/store/brokerageProfile/brokerageProfile.select';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import * as DashboardActionTypes from 'src/app/store/dashboard/dashboard.action';
import {
  getErrorMessage,
  checkDomainMatch,
  getAlertHead,
} from 'src/app/utils/utils';
import { AlertService } from 'src/app/services/alert.service';
import { getBrokerageCreatedSelector } from 'src/app/store/brokerage/brokerage.select';
import { BrokerageRelationshipService } from 'src/app/services/brokerage-relationship';
import { BrokerageProfileService } from 'src/app/services/brokerage-profile.service';
import { environment } from 'src/environments/environment';
import { BrokerageProfile } from 'src/app/entities/brokerage-profile';
import { IBrokerageRelationship } from 'src/app/entities/brokerage-relationship';
import { formatDateTime } from 'src/app/utils/formatDate';
import { LocationService } from 'src/app/services/location.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-brokerage-profile',
  templateUrl: './brokerage-profile.component.html',
  styleUrls: ['./brokerage-profile.component.less'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BrokerageProfileComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  brokerageParents: any[] = [];
  handleTextInputChange($event: string) {
    throw new Error('Method not implemented.');
  }
  handleBusinessSector($event: any) {
    throw new Error('Method not implemented.');
  }
  addBranchForm: boolean = false;

  form: FormGroup;
  regions$: Observable<any>;
  private userId: number;
  locationRegionIdSelected: number = 0;

  hasError = false;
  operationSuccess = false;
  hasErrorProfilePic = false;
  showSpinner: boolean = false;
  formSubmitted: boolean = false;
  isNavigatedFromAlert: boolean = false;

  errorMessage = '';
  errorMessageProfilePic = '';
  parentCompanyInvalidErrMsg: string = '';
  requiredErrMsg: string = this.translate.instant(
    'workFlow3.commissions.error.requiredErrMsg',
  );
  invalidErrMsg: string = this.translate.instant('common.invalidErrorMessage');
  activeCategoryFilter: string = 'Brokerage information';

  private listCommissions = [];
  brokeragesCompanys: IBaseDto[] = [];
  categoryFilters: string[] = [
    'Brokerage information',
    'Commission rates',
    'Branches',
  ];
  brokerageId;
  producerId;
  commissionType: string = 'Brokerage';
  parentInitValue: string;

  itemsMenu: Breadcrumb[] = [
    { label: 'Members', path: '/dashboard/workflow3/brokerage/directory' },
    {
      label: 'Brokerage Directory',
      path: '/dashboard/workflow3/brokerage/directory',
    },
    { label: 'New Brokerage', path: null },
  ];

  // Below declarations are only for brokerage edit
  spinnerCounter: number;
  brokerageSelectedId: number = 0;
  locationOldSelection = null;
  brokerageSelected: any = null;
  msgErrorAlert: any;
  // locationService: any;
  notificationAlert: any;
  errorMessageProfile: any;
  errorMessageLocation: any;
  errorMessageBrokerage: any;
  errorMessageRelationship: any;
  initialLocationFormValue: any;
  initialBrokerageFormValue: any;
  isNavigatedFromAddBrokerage: unknown;

  createdBy: string;
  updatedBy: string;
  logoImage: string;
  currentScreen: string;
  longDateTimeFormat: string;
  shortDateTimeFormat: string;
  permissionList: { [x: string]: boolean };

  isFetching: boolean;
  existingError = false;
  brokerageActive = true;
  showErrorAlert: boolean;
  showBodySpinner: boolean;
  hasErrorProfile: boolean;
  hasErrorLocation: boolean;
  reloadBranchTable: boolean;
  hasErrorBrokerage: boolean;
  hasErrorRelationship: boolean;
  hasPermission: boolean = true;
  operationSuccessProfile: boolean;
  brokerageUpdatedSuccess: boolean;
  logoFieldsUpdated: boolean = false;
  operationSuccessLocations: boolean;
  otherFieldsUpdated: boolean = false;
  parentFieldsUpdated: boolean = false;
  isLocationFormDirty: boolean = false;
  isBrokerageFormDirty: boolean = false;
  operationSuccessRelationship: boolean;

  private brokerageFormSubscription: Subscription;
  private locationFormSubscription: Subscription;

  brokerageProfileOldSelected: BrokerageProfile = null;
  brokerageRelationshipOldSelected: IBrokerageRelationship = null;
  toCommissionRates: boolean;
  option: any;
  editable: boolean = true;
  createdAt: string;
  updatedAt: string;
  enableCategory: boolean = true;
  isRestrictedRoleSubmission: boolean = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private alertService: AlertService,
    private store: Store<CommissionState>,
    private activatedRoute: ActivatedRoute,
    private brokerageService: BrokerageService,
    private viewPortScroller: ViewportScroller,
    private localStorageService: LocalStorageService,
    private brokerageProfileService: BrokerageProfileService,
    private imageLogoSubjectService: ImageLogoSubjectService,
    private parentCompanySubjectService: ParentCompanySubjectService,
    private brokerageRelationshipService: BrokerageRelationshipService,
    private locationService: LocationService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.userId = this.localStorageService.getBoxxUserId();
    this.form = getFormGroup(this.fb);
    this.store.dispatch(new saveBrokerageProfileAction(null));
    setTimeout(() => {
      this.viewPortScroller.scrollToPosition([0, 0]);
    }, 0);
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.isNavigatedFromAlert = data?.isNavigatedFromAlert;
      this.isRestrictedRoleSubmission = data.isRestrictedRoleSubmission;
    });
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.isNavigatedFromAlert = data?.isNavigatedFromAlert;
      this.shortDateTimeFormat = data.shortDateTimeFormat;
      this.longDateTimeFormat = data.longDateTimeFormat;
    });
    this.permissionList[this.currentScreen]
      ? (this.hasPermission = true)
      : (this.hasPermission = false);

    // for brokerage edit
    this.activatedRoute.params.subscribe((params) => {
      this.brokerageSelectedId = params['brokerageId'] as number;
      this.option = params['option'] as string;

      // if brokerage id is passed through url
      if (this.brokerageSelectedId !== (0 || undefined)) {
        this.showBodySpinner = true;
        // check if navigated from add brokerage
        this.store
          .select(getBrokerageCreatedSelector)
          .pipe(take(1))
          .subscribe((value) => {
            this.isNavigatedFromAddBrokerage = value;
          });
        // check if navigated from add brokerage end

        this.store.dispatch(new resetBrokerageCreated());

        // set brokerage form data
        this.initLoadData(this.brokerageSelectedId);

        this.brokerageId = this.brokerageSelectedId;
        if (this.option === 'commission')
          this.handleCategoryFilter('Commission rates');
        if (this.option === 'viewonly') this.editable = false;
        if (this.option === 'new-brokerge') {
          const alertData = {
            type: 'success',
            headerText: 'success!',
            bodyText: this.translate.instant(
              'workFlow3.brokerageProfile.message.newBrokerageAdded',
            ),
          };
          this.alertService.clearAlerts(-1);
          this.alertService.addAlert(alertData);
        }
      }
    });
    // for brokerage edit end
  }

  ngAfterViewInit(): void {
    this.viewPortScroller.scrollToPosition([0, 0]);
  }

  ngOnDestroy(): void {
    this.store.dispatch(
      new DashboardActionTypes.updateDashboardConfigAction({
        isNavigatedFromAlert: null,
      }),
    );
    this.unSubscriptionForms();
  }
  handleAddBranch() {
    this.handleCategoryFilter('Branches');
    this.addBranchForm = true;
  }
  // method to handle tab menu clicks
  handleCategoryFilter(category: string): void {
    if (category === 'Branches') {
      this.addBranchForm = false;
    }
    this.activeCategoryFilter = category;
    const name = this.form.controls['brokerage'].get('name').value;
    if (this.brokerageSelectedId !== 0 && name === null) {
      if (this.activeCategoryFilter === 'Brokerage information') {
        this.enableCategory = false;
      }
    } else {
      this.enableCategory = true;
    }
  }

  // common method to reset the form
  handleReset() {
    if (this.brokerageSelectedId == undefined) {
      this.form.reset();
      this.logoImage = null;
      this.enableCategory = false;
      this.formSubmitted = false;
    } else {
      this.initLoadData(this.brokerageSelectedId);
    }
  }

  get brokerage() {
    return this.form.get('brokerage') as FormGroup;
  }

  get location() {
    return this.form.get('location') as FormGroup;
  }

  regionIdSelected(idregionSelected: number) {
    this.locationRegionIdSelected = idregionSelected;
  }

  // brokerage create and move to commission
  continueToCommissionRates(event: any) {
    this.toCommissionRates = true;
    this.handleSubmit(event);
  }

  // brokerage create
  handleSubmit(e: any) {
    this.isParentCompanyValid();
    this.formSubmitted = true;

    const mainWebsite = this.form.controls['brokerage']
      .get('website')
      ?.value?.toLowerCase();
    if (mainWebsite) {
      if (!checkRegexPatternMainWebsite(mainWebsite)) {
        this.form.controls['brokerage']
          .get('website')
          ?.setErrors({ pattern: true });
      }
    }
    const emailId = this.form.controls['brokerage']
      .get('email')
      ?.value?.toLowerCase();
    if (!checkRegexPatternMainEmail(emailId)) {
      this.form.controls['brokerage']
        ?.get('email')
        ?.setErrors({ pattern: true });
    }
    const availableDomains = this.form.controls['brokerage']
      ?.get('domains')
      ?.value?.toLowerCase();
    if (!checkRegexPatternMultidomain(availableDomains)) {
      this.form.controls['brokerage']
        ?.get('domains')
        ?.setErrors({ pattern: true });
    }
    if (this.form.valid) {
      this.showSpinner = true;
      this.showBodySpinner = true;
      const brokerage: IBrokerageModel = mapFormGroupToModel(this.form);

      if (brokerage.location) {
        brokerage.location.regionId = this.locationRegionIdSelected;
      }

      this.brokerageService.CreateBrokerage(brokerage).subscribe({
        next: (response) => {
          this.operationSuccess = true;
          this.form.reset();
          this.imageLogoSubjectService.dispatch(true);
          this.parentCompanySubjectService.dispatch(true);
          this.store.dispatch(new resetCommissions());
          this.viewPortScroller.scrollToPosition([0, 0]);
          this.formSubmitted = false;
          this.showSpinner = false;
          this.showBodySpinner = false;
          this.store.dispatch(new setBrokerageCreated());
          if (this.toCommissionRates) {
            this.brokerageSelectedId = response?.data?.id;
            this.initLoadData(this.brokerageSelectedId);
            this.brokerageId = response?.data?.id;
            this.handleCategoryFilter('Commission rates');
            const alertData = {
              type: 'success',
              headerText: 'success!',
              bodyText: this.translate.instant(
                'workFlow3.brokerageProfile.message.newBrokerageAdded',
              ),
            };
            this.alertService.clearAlerts(-1);
            this.alertService.addAlert(alertData);

            // this.router.navigate(
            //   [
            //     `/dashboard/workflow3/brokerage/profile/${response?.data?.id}/commission`,
            //   ],
            //   {
            //     relativeTo: this.activatedRoute,
            //     skipLocationChange: true,
            //   },
            // );
          } else {
            this.router.navigate(
              [
                `/dashboard/workflow3/brokerage/profile/${response?.data?.id}/new-brokerge`,
              ],
              {
                relativeTo: this.activatedRoute,
                skipLocationChange: true,
              },
            );
          }
        },
        error: (error) => {
          this.showSpinner = false;
          this.showBodySpinner = false;
          if (![500].includes(error?.status)) {
            this.hasError = true;
          }
          const alertData = {
            type: 'error',
            headerText: 'error!',
            bodyText: getErrorMessage(error),
          };
          this.alertService.clearAlerts(-1);
          this.alertService.addAlert(alertData);
        },
      });
    } else {
      const alertData = {
        show: true,
        type: 'error',
        headerText: 'common.errorMsg',
        bodyText: this.translate.instant(
          'workFlow3.brokerageProfile.message.brokerageCreateError',
        ),
      };
      this.alertService.clearAlerts(-1);
      this.alertService.addAlert(alertData);
    }
  }

  handleCloseSuccessEvent() {
    this.hasError = false;
    this.hasErrorProfilePic = false;
  }

  triggerProfilePicErrorToParent(msg: any) {
    if (msg) {
      this.hasErrorProfilePic = true;
      this.errorMessageProfilePic = msg;
      const alertData = {
        type: 'error',
        headerText: 'error!',
        bodyText: msg,
      };
      this.alertService.clearAlerts(-1);
      this.alertService.addAlert(alertData);
    } else {
      this.hasErrorProfilePic = false;
      this.errorMessageProfilePic = '';
    }
  }

  isParentCompanyValid() {
    let parentCompanyDetails: BrokerageParentCompany = {};
    this.store
      .select(getBrokerageCompanyDetails)
      .subscribe((parentCompany) => (parentCompanyDetails = parentCompany));
    if (
      parentCompanyDetails.searchText === '' ||
      parentCompanyDetails.searchText === parentCompanyDetails.name
    ) {
      this.parentCompanyInvalidErrMsg = '';
      return;
    }
    this.parentCompanyInvalidErrMsg = this.translate.instant(
      'common.invalidErrorMessage',
    );
    this.form.controls['brokerage'].get('parent').setErrors({ pattern: true });
  }
  // brokerage create end

  // Below code is only for brokerage edit and update
  async handleUpdate(e: any) {
    const email = this.form.controls['brokerage']
      .get('email')
      .value?.toLowerCase();

    const domains = this.form.controls['brokerage']
      .get('domains')
      .value?.toLowerCase();

    if (!checkDomainMatch(email, domains)) {
      const alertMsg = this.translate
        .instant('common.domainDosenotMatch')
        .replace('{domains}', domains);
      const alertData = {
        type: 'error',
        headerText: 'error!',
        bodyText: alertMsg,
      };
      this.alertService.clearAlerts(-1);
      this.alertService.addAlert(alertData);
      return;
    }

    let parentCompanyDetails: BrokerageParentCompany;
    this.enableCategory = true;
    this.isLocationFormDirty = !this.isFormUnchanged(
      'location',
      this.initialLocationFormValue,
    );
    this.isBrokerageFormDirty = !this.isFormUnchanged(
      'brokerage',
      this.initialBrokerageFormValue,
    );

    this.unSubscriptionForms();
    this.isParentCompanyValid();
    if (!!!this.isBrokerageFormDirty && !!!this.isLocationFormDirty) {
      const alertData = {
        type: 'info',
        headerText: getAlertHead('info'),
        bodyText: this.translate.instant(
          'workFlow3.brokerageProfile.message.noChangesDetected',
        ),
      };
      this.alertService.clearAlerts(-1);
      this.alertService.addAlert(alertData);
      return;
    } else if (
      this.existingError === true &&
      this.isBrokerageFormDirty &&
      this.isLocationFormDirty
    ) {
      this.otherFieldsUpdated = true;
    }

    this.onHandleCloseEvent();
    this.operationSuccess = false;
    this.hasError = false;
    this.hasErrorBrokerage = false;
    this.hasErrorProfile = false;
    this.operationSuccessProfile = false;

    this.formSubmitted = true;
    this.spinnerCounter = 0;
    this.showSpinner = true;
    this.showBodySpinner = true;
    const mainWebsite = this.form.controls['brokerage']
      .get('website')
      .value?.toLowerCase();
    if (mainWebsite) {
      if (!checkRegexPatternMainWebsite(mainWebsite)) {
        this.form.controls['brokerage']
          .get('website')
          .setErrors({ pattern: true });
        this.spinnerCounter = 0;
        this.showSpinner = false;
        this.showBodySpinner = false;
      }
    }
    const emailId = this.form.controls['brokerage']
      .get('email')
      .value?.toLowerCase();
    if (!checkRegexPatternMainEmail(emailId)) {
      this.form.controls['brokerage'].get('email').setErrors({ pattern: true });
      this.spinnerCounter = 0;
      this.showSpinner = false;
      this.showBodySpinner = false;
    }
    const availableDomains = this.form.controls['brokerage']
      .get('domains')
      .value?.toLowerCase();
    if (!checkRegexPatternMultidomain(availableDomains)) {
      this.form.controls['brokerage']
        .get('domains')
        .setErrors({ pattern: true });
      this.spinnerCounter = 0;
      this.showSpinner = false;
      this.showBodySpinner = false;
    }
    if (this.form.valid && this.form.enabled) {
      let brokerageForm = this.form.get('brokerage');
      if (brokerageForm.value.phoneNumber === '') {
        brokerageForm.value.phoneNumber = null;
      }

      const brokerageModel: IBrokerageModel = mapFormGroupToModel(this.form);

      if (this.otherFieldsUpdated == true || this.existingError == true) {
        this.spinnerCounter++;
        const brokerage = {
          name: brokerageModel.name,
          website: brokerageModel.website,
          domain: brokerageModel.domain,
          telephone: brokerageModel.telephone,
          email: brokerageModel.email,
        };
        this.brokerageService
          .UpdateBrokerage(this.brokerageSelected.id, brokerage)
          .subscribe({
            next: (response) => {
              this.itemsMenu[2] = { label: brokerage.name, path: null };
              this.brokerageSelected = {
                ...this.brokerageSelected,
                name: brokerage.name,
              };
              this.brokerageSelected.website = brokerage.website;
              this.brokerageUpdatedSuccess = true;
              this.viewPortScroller.scrollToPosition([0, 0]);
              const userDetails = response.data;
              this.updatedBy = userDetails.updatedBy;
              this.updatedAt = formatDateTime(
                userDetails.updatedDt,
                this.longDateTimeFormat,
              );
              const alertData = {
                type: 'success',
                headerText: getAlertHead('success'),
                bodyText: 'brokerage.success.updateMessage',
              };
              this.alertService.clearAlerts(-1);
              this.alertService.addAlert(alertData);
              this.otherFieldsUpdated = false;
              this.spinnerCounter--;
              if (this.spinnerCounter == 0) {
                this.showSpinner = false;
                this.showBodySpinner = false;
              }
              if (this.existingError === true) {
                this.existingError = false;
              }
              this.getBrokerage();
            },
            error: (error) => {
              const alertData = {
                type: 'error',
                headerText: 'error!',
                bodyText: getErrorMessage(error),
              };
              this.alertService.clearAlerts(-1);
              this.alertService.addAlert(alertData);
              this.otherFieldsUpdated = false;
              this.spinnerCounter--;
              if (this.spinnerCounter == 0) {
                this.showSpinner = false;
                this.showBodySpinner = false;
              }
            },
          });
      }

      const relationshipOldSelected =
        this.brokerageRelationshipOldSelected?.parentBroker.id || '';

      if (this.parentFieldsUpdated == true && !this.existingError) {
        if (brokerageModel.parentCompany || relationshipOldSelected) {
          if (
            !isNaN(brokerageModel.parentCompany) &&
            brokerageModel.parentCompany !== relationshipOldSelected
          ) {
            this.store
              .select(getBrokerageCompanyDetails)
              .subscribe(
                (parentCompany) => (parentCompanyDetails = parentCompany),
              );
            this.parentInitValue = parentCompanyDetails?.name;
            this.spinnerCounter++;
            const idRelationship = this.brokerageRelationshipOldSelected?.id;

            await this.brokerageRelationshipService
              .update(
                idRelationship,
                brokerageModel.parentCompany,
                this.brokerageSelectedId,
              )
              .subscribe({
                next: async (response) => {
                  const relationshipData = response?.data;
                  this.brokerageRelationshipOldSelected = {
                    ...relationshipData,
                  };

                  if (
                    this.brokerageRelationshipOldSelected == null ||
                    Object.keys(this.brokerageRelationshipOldSelected)
                      .length === 0
                  ) {
                    this.brokerageRelationshipService
                      .getAllByFilter({
                        childBrokerId: this.brokerageSelectedId,
                      })
                      .subscribe({
                        next: (data) => {
                          const relationship =
                            data.data.length > 0 ? data.data[0] : null;
                          if (relationship) {
                            this.brokerageRelationshipOldSelected = {
                              ...relationship,
                            };
                            this.form
                              .get('brokerage.parent')
                              .setValue(
                                this.brokerageRelationshipOldSelected
                                  .parentBroker.id,
                              );
                            this.initForm();
                          }
                        },
                        error: (error) => {
                          if (![500].includes(error?.status)) {
                            this.hasError = true;
                          }
                          const alertData = {
                            type: 'error',
                            headerText: 'error!',
                            bodyText: getErrorMessage(error),
                          };
                          this.alertService.clearAlerts(-1);
                          this.alertService.addAlert(alertData);
                          // this.errorMessage = getErrorMessage(error?.error);
                        },
                      });
                  } else {
                    this.brokerageRelationshipOldSelected.parentBroker.id =
                      brokerageModel.parentCompany;
                    this.form
                      .get('brokerage.parent')
                      .setValue(brokerageModel.parentCompany);
                    this.initForm();
                  }

                  if (relationshipData?.updatedBy) {
                    this.updatedBy = relationshipData?.updatedBy;
                    this.updatedAt = formatDateTime(
                      relationshipData?.updatedDt,
                      this.longDateTimeFormat,
                    );
                  }
                  this.hasErrorRelationship = false;
                  this.operationSuccessRelationship = true;
                  this.viewPortScroller.scrollToPosition([0, 0]);
                  this.parentFieldsUpdated = false;
                  this.spinnerCounter--;
                  if (this.spinnerCounter == 0) {
                    this.showSpinner = false;
                    this.showBodySpinner = false;
                  }
                },
                error: (error) => {
                  if (![500].includes(error?.status)) {
                    this.hasErrorRelationship = true;
                  }
                  this.operationSuccessRelationship = false;
                  const alertData = {
                    type: 'error',
                    headerText: 'error!',
                    bodyText: getErrorMessage(error),
                  };
                  this.alertService.clearAlerts(-1);
                  this.alertService.addAlert(alertData);
                  // this.errorMessageRelationship = getErrorMessage(error?.error);
                  this.viewPortScroller.scrollToPosition([0, 0]);
                  this.parentFieldsUpdated = false;
                  this.spinnerCounter--;
                  if (this.spinnerCounter == 0) {
                    this.showSpinner = false;
                    this.showBodySpinner = false;
                  }
                },
              });
          }
        }
      }

      if (
        this.logoFieldsUpdated == true &&
        brokerageModel.brokerageProfile.logo &&
        this.brokerageProfileOldSelected.logo !==
          brokerageModel.brokerageProfile.logo &&
        !this.existingError
      ) {
        this.spinnerCounter++;
        await this.brokerageProfileService
          .UpdateLogo(this.brokerageProfileOldSelected.id, {
            logo: brokerageModel.brokerageProfile.logo,
          })
          .subscribe({
            next: async (response) => {
              this.hasErrorProfile = false;
              this.operationSuccessProfile = true;
              this.viewPortScroller.scrollToPosition([0, 0]);
              const profileData = response?.data ?? {};
              if (profileData?.updatedBy) {
                this.updatedBy = profileData?.updatedBy;
                this.updatedAt = formatDateTime(
                  profileData?.updatedDt,
                  this.longDateTimeFormat,
                );
              }
              this.getBrokerageProfile();
              this.form
                .get('brokerage.logo')
                .setValue(brokerageModel.brokerageProfile.logo);
              this.logoFieldsUpdated = false;
              this.spinnerCounter--;
              if (this.spinnerCounter == 0) {
                this.showSpinner = false;
                this.showBodySpinner = false;
              }
              const alertData = {
                type: 'success',
                headerText: getAlertHead('success'),
                bodyText: 'brokerage.success.updateMessage',
              };
              this.alertService.addAlert(alertData);
            },
            error: (error) => {
              this.operationSuccessProfile = false;
              if (![500].includes(error?.status)) {
                this.hasErrorProfile = true;
              }
              const alertData = {
                type: 'error',
                headerText: 'error!',
                bodyText: getErrorMessage(error),
              };
              this.alertService.clearAlerts(-1);
              this.alertService.addAlert(alertData);
              // this.errorMessageProfile = getErrorMessage(error?.error);
              this.viewPortScroller.scrollToPosition([0, 0]);
              this.logoFieldsUpdated = false;
              this.spinnerCounter--;
              if (this.spinnerCounter == 0) {
                this.showSpinner = false;
                this.showBodySpinner = false;
              }
            },
          });
      }

      if (
        brokerageModel.location &&
        this.isLocationFormDirty &&
        !this.existingError
      ) {
        this.spinnerCounter++;
        brokerageModel.location.id = this.locationOldSelection.id;
        brokerageModel.location.regionId = this.locationRegionIdSelected;
        brokerageModel.location.addressLine2 =
          brokerageModel.location.addressLine2;
        await this.locationService
          .updateLocation(brokerageModel.location)
          .subscribe({
            next: (response) => {
              const locationDetails = response?.data;
              if (locationDetails?.updatedBy) {
                this.updatedBy = locationDetails?.updatedBy;
                this.updatedAt = formatDateTime(
                  locationDetails?.updatedDt,
                  this.longDateTimeFormat,
                );
              }
              this.initialLocationFormValue = this.form.get('location').value;
              this.operationSuccessLocations = true;
              this.hasErrorLocation = false;
              this.viewPortScroller.scrollToPosition([0, 0]);
              this.spinnerCounter--;
              if (this.spinnerCounter == 0) {
                this.showSpinner = false;
                this.showBodySpinner = false;
              }
              // this.getBrokerage();
              const alertData = {
                type: 'success',
                headerText: getAlertHead('success'),
                bodyText: 'brokerage.success.updateMessage',
              };
              this.alertService.addAlert(alertData);
            },
            error: (error) => {
              this.operationSuccessLocations = false;
              if (![500].includes(error?.status)) {
                this.hasErrorLocation = true;
              }
              const alertData = {
                type: 'error',
                headerText: 'error!',
                bodyText: getErrorMessage(error),
              };
              this.alertService.clearAlerts(-1);
              this.alertService.addAlert(alertData);
              // this.errorMessageLocation = getErrorMessage(error?.error);
              this.viewPortScroller.scrollToPosition([0, 0]);
              this.spinnerCounter--;
              if (this.spinnerCounter == 0) {
                this.showSpinner = false;
                this.showBodySpinner = false;
              }
            },
          });
      }
      if (this.spinnerCounter == 0) {
        this.showSpinner = false;
        this.showBodySpinner = false;
      }
    } else {
      this.spinnerCounter = 0;
      this.showSpinner = false;
      this.showBodySpinner = false;
      this.enableCategory = false;
    }
  }

  private initLoadData(brokerageSelectedId: number) {
    this.showBodySpinner = true;
    forkJoin({
      brokerage: this.brokerageService.GetBrokerage(brokerageSelectedId),
      brokerageRelationship: this.brokerageRelationshipService.getAllByFilter({
        childBrokerId: brokerageSelectedId,
      }),
      brokerageProfile: this.brokerageProfileService.Get(brokerageSelectedId),
    }).subscribe(({ brokerage, brokerageRelationship, brokerageProfile }) => {
      if (
        Object.entries(brokerage.data).length === 0 ||
        brokerageProfile.data.length === 0
      ) {
        let isMock = 'true';
        if (
          this.localStorageService.getMockExternalAPIs() === 'false' ||
          environment.mockExternalAPIs === false
        ) {
          isMock = 'false';
        }
        this.router.navigate(['dashboard/home'], {
          queryParams: {
            mock: isMock,
          },
          skipLocationChange: true,
        });
      }
      this.locationRegionIdSelected = brokerage?.data?.location?.region?.id;
      const relationship =
        brokerageRelationship.data.length > 0
          ? brokerageRelationship.data[0]
          : null;

      if (relationship) {
        let parentCompanyDetails: BrokerageParentCompany = {
          id: relationship?.parentBroker?.id ?? undefined,
          name: relationship?.parentBroker?.name ?? '',
          searchText: relationship?.parentBroker?.name ?? '',
        };
        this.store.dispatch(
          new saveBrokerageParentCompany(parentCompanyDetails),
        );
        this.brokerageRelationshipOldSelected = { ...relationship };
        this.parentInitValue = relationship.parentBroker.name;
        this.form
          .get('brokerage.parent')
          .setValue(relationship.parentBroker.id);
      }
      this.brokerageProfileOldSelected = brokerageProfile.data[0];
      if (brokerageProfile.data[0])
        this.form.get('brokerage.logo').setValue(brokerageProfile.data[0].logo);
      this.isFetching = false;
      this.brokerageSelected = brokerage.data;
      this.brokerageActive = this.brokerageSelected.active;
      this.form[this.brokerageSelected.active ? 'enable' : 'disable']({
        emitEvent: false,
      });
      this.itemsMenu[2] = { label: this.brokerageSelected.name, path: null };
      this.store.dispatch(
        new saveBrokerageProfileAction(brokerageProfile.data[0]),
      );
      this.initForm();
      setTimeout(() => {
        this.viewPortScroller.scrollToPosition([0, 0]);
      }, 0);
      this.showBodySpinner = false;
    });
  }

  private initForm() {
    this.unSubscriptionForms();
    if (this.brokerageSelected) {
      this.setSectionDate();

      this.isBrokerageFormDirty = this.isLocationFormDirty = false;
      this.form.controls['brokerage']
        .get('name')
        .setValue(this.brokerageSelected.name);
      this.form.controls['brokerage']
        .get('email')
        .setValue(this.brokerageSelected.email);
      this.form.controls['brokerage']
        .get('domains')
        .setValue(this.brokerageSelected.domain);
      this.form.controls['brokerage']
        .get('phoneNumber')
        .setValue(this.brokerageSelected.telephone ?? '');
      this.form.controls['brokerage']
        .get('website')
        .setValue(this.brokerageSelected.website);

      this.initialBrokerageFormValue = this.form.get('brokerage').value;
      this.brokerageFormSubscription = this.form
        .get('brokerage')
        .valueChanges.subscribe(() => {
          this.isBrokerageFormDirty = !this.isFormUnchanged(
            'brokerage',
            this.initialBrokerageFormValue,
          );
        });

      this.locationOldSelection = { ...this.brokerageSelected.location };
      this.form.controls['location']
        .get('city')
        .setValue(this.brokerageSelected.location.city);
      this.form.controls['location']
        .get('address')
        .setValue(this.brokerageSelected.location.address);
      this.form.controls['location']
        .get('addressLine2')
        .setValue(this.brokerageSelected.location.addressLine2 ?? '');
      this.form.controls['location']
        .get('country')
        .setValue(this.brokerageSelected.location.country);
      this.form.controls['location']
        .get('province')
        .setValue(this.brokerageSelected.location.region.name);
      this.form.controls['location']
        .get('zipPostalCode')
        .setValue(this.brokerageSelected.location.zipPostalCode);

      this.initialLocationFormValue = this.form.get('location').value;
      this.locationFormSubscription = this.form
        .get('location')
        .valueChanges.subscribe(() => {
          this.isLocationFormDirty = !this.isFormUnchanged(
            'location',
            this.initialLocationFormValue,
          );
        });
    }
  }

  private async setSectionDate() {
    this.createdBy = this.brokerageSelected?.createdBy;
    this.createdAt = formatDateTime(
      this.brokerageSelected?.createdDt,
      this.longDateTimeFormat,
    );
    if (
      this.brokerageSelected.updatedDt ||
      this.brokerageSelected?.location?.updatedDt ||
      this.brokerageRelationshipOldSelected?.updatedDt ||
      this.brokerageProfileOldSelected?.updatedDt
    ) {
      let latestUpdateDetails: {
        name: string;
        date: any;
      } = await this.getLatestUpdateDetails();
      this.updatedBy = latestUpdateDetails?.name;
      this.updatedAt = formatDateTime(
        latestUpdateDetails?.date,
        this.longDateTimeFormat,
      );
    }
  }

  private async getLatestUpdateDetails() {
    let latestUpdatedBy = '';
    const brokerageDate = new Date(this.brokerageSelected?.updatedDt);
    const locationDate = new Date(this.brokerageSelected?.location?.updatedDt);
    const relationshipDate = new Date(
      this.brokerageRelationshipOldSelected?.updatedDt,
    );
    const profileDate = new Date(this.brokerageProfileOldSelected?.updatedDt);

    // Check if any of the dates are null or undefined.
    if (!brokerageDate && !locationDate && !profileDate && !relationshipDate) {
      return {
        name: '',
        date: '',
      };
    }
    let latest: Date | null =
      brokerageDate || locationDate || relationshipDate || profileDate;

    // Compare each date with the latest date if it's not null.
    if (brokerageDate >= latest) {
      latest = brokerageDate;
      latestUpdatedBy = this.brokerageSelected?.updatedBy;
    }
    if (locationDate >= latest) {
      latest = locationDate;
      latestUpdatedBy = this.brokerageSelected?.location?.updatedBy;
    }
    if (relationshipDate >= latest) {
      latest = relationshipDate;
      latestUpdatedBy = this.brokerageRelationshipOldSelected?.updatedBy;
    }
    if (profileDate >= latest) {
      latest = profileDate;
      latestUpdatedBy = this.brokerageProfileOldSelected?.updatedBy;
    }

    return {
      name: latestUpdatedBy,
      date: latest,
    };
  }

  private unSubscriptionForms() {
    if (this.brokerageFormSubscription) {
      this.brokerageFormSubscription.unsubscribe();
    }
    if (this.locationFormSubscription) {
      this.locationFormSubscription.unsubscribe();
    }
  }

  private getBrokerage() {
    this.brokerageService.GetBrokerage(this.brokerageSelectedId).subscribe({
      next: (response) => {
        this.brokerageSelected = response.data;
        this.brokerageActive = this.brokerageSelected.active;
        this.form[this.brokerageSelected.active ? 'enable' : 'disable']({
          emitEvent: false,
        });
        this.itemsMenu[2] = {
          label: this.brokerageSelected.name,
          path: null,
        };
        this.initForm();
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        const alertData = {
          type: 'error',
          headerText: 'error!',
          bodyText: getErrorMessage(error),
        };
        this.alertService.clearAlerts(-1);
        this.alertService.addAlert(alertData);
        // this.msgErrorAlert = getErrorMessage(error);
      },
    });
  }

  private getBrokerageProfile() {
    this.brokerageProfileService.Get(this.brokerageSelectedId).subscribe({
      next: (response) => {
        this.brokerageProfileOldSelected = response.data[0];
        this.store.dispatch(new saveBrokerageProfileAction(response.data[0]));
        this.initForm();
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        const alertData = {
          type: 'error',
          headerText: 'error!',
          bodyText: getErrorMessage(error),
        };
        this.alertService.clearAlerts(-1);
        this.alertService.addAlert(alertData);
        // this.msgErrorAlert = getErrorMessage(error);
      },
    });
  }

  isFormUnchanged(formControlName: string, initialValues): boolean {
    let changedControls = [
      'name',
      'email',
      'domains',
      'phoneNumber',
      'website',
    ];
    if (formControlName === 'brokerage') {
      const controlNamesArray = Object.keys(initialValues);
      controlNamesArray.forEach((controlName) => {
        const initialValue = initialValues[controlName];
        const currentValue =
          this.form.controls[formControlName].get(controlName).value;
        if (JSON.stringify(currentValue) !== JSON.stringify(initialValue)) {
          if (controlName == 'logo') {
            this.logoFieldsUpdated = true;
          } else if (controlName == 'parent') {
            this.parentFieldsUpdated = true;
          } else if (changedControls.includes(controlName)) {
            this.otherFieldsUpdated = true;
          } else if (controlName == 'phoneNumber') {
            this.otherFieldsUpdated = true;
          }
        }
      });
    }

    return (
      JSON.stringify(this.form.get(formControlName).value) ===
      JSON.stringify(initialValues)
    );
  }

  onHandleCloseEvent() {
    this.hasError = false;
    this.operationSuccess = false;
    this.hasErrorBrokerage = false;
    this.brokerageUpdatedSuccess = false;
    this.operationSuccessRelationship = false;
    this.operationSuccessProfile = false;
    this.operationSuccessLocations = false;
    this.hasErrorRelationship = false;
    this.hasErrorLocation = false;
    // this.notificationAlert.show = false;
  }

  showSuccessBanner() {
    return (
      this.spinnerCounter == 0 &&
      (this.brokerageUpdatedSuccess ||
        this.operationSuccessProfile ||
        this.operationSuccessRelationship ||
        this.operationSuccessLocations)
    );
  }

  getBannerMsg() {
    let msg = [];
    if (this.brokerageUpdatedSuccess) {
      msg.push('Brokerage');
    }
    if (this.operationSuccessProfile) {
      msg.push('Profile Logo');
    }
    if (this.operationSuccessRelationship) {
      msg.push('Relationship');
    }
    if (this.operationSuccessLocations) {
      msg.push('Location');
    }
    return `${msg.join(', ')} saved successfully!`;
  }

  // deactivate brokerage
  handleDeactivateBrokerage() {
    this.notificationAlert = { show: false };
    this.showBodySpinner = true;
    this.brokerageService
      .DeleteBrokerage(this.brokerageSelectedId)
      .subscribe({
        next: (response: any) => {
          const alertData = {
            type: 'success',
            headerText: 'success!',
            bodyText: this.translate.instant(
              'workFlow3.brokerageProfile.message.brokerageDeactivated',
            ),
          };
          this.enableCategory = false;
          this.alertService.clearAlerts(-1);
          this.alertService.addAlert(alertData);
          this.brokerageSelected = { ...this.brokerageSelected, active: false };
          this.brokerageActive = false;
          const userDetails = response?.data;
          this.updatedBy = userDetails.updatedBy;
          this.updatedAt = formatDateTime(
            userDetails.updatedDt,
            this.longDateTimeFormat,
          );
        },
        error: (error) => {
          const errorMsg = getErrorMessage(error);
          if (![500].includes(error?.status)) {
            const alertData = {
              show: true,
              type: 'error',
              headerText: 'error!',
              bodyText: errorMsg,
            };
            this.alertService.addAlert(alertData);
          }
        },
      })
      .add(() => {
        this.showBodySpinner = false;
        this.form[this.brokerageSelected.active ? 'enable' : 'disable']({
          emitEvent: false,
        });
        this.viewPortScroller.scrollToPosition([0, 0]);
      });
  }

  // activate brokerage
  handleActivateBrokerage() {
    this.showBodySpinner = true;
    this.brokerageService
      .RestoreBrokerage(this.brokerageSelectedId)
      .subscribe({
        next: (response: any) => {
          this.brokerageSelected = { ...this.brokerageSelected, active: true };
          this.brokerageActive = true;
          const alertData = {
            type: 'success',
            headerText: 'success!',
            bodyText: this.translate.instant(
              'workFlow3.brokerageProfile.message.brokerageActivated',
            ),
          };
          this.enableCategory = true;
          this.alertService.clearAlerts(-1);
          this.alertService.addAlert(alertData);
          this.reloadBranchTable = true;
          const userDetails = response?.data;
          this.updatedBy = userDetails.updatedBy;
          this.updatedAt = formatDateTime(
            userDetails.updatedDt,
            this.longDateTimeFormat,
          );
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            const alertData = {
              type: 'error',
              headerText: 'error!',
              bodyText: getErrorMessage(error),
            };
            this.alertService.clearAlerts(-1);
            this.alertService.addAlert(alertData);
          }
        },
      })
      .add(() => {
        this.showBodySpinner = false;
        this.viewPortScroller.scrollToPosition([0, 0]);
        this.form[this.brokerageSelected.active ? 'enable' : 'disable']({
          emitEvent: false,
        });
      });
  }
  // Brokerage edit end

  handleGlobalSearch(object) {
    if (object.searchIn === 'Insured name') {
      let insuredType = 1;
      if (object?.type?.toLowerCase() == 'company') {
        insuredType = 2;
      }
      this.router.navigateByUrl(
        `dashboard/search/${insuredType}/${object.selectedSearch.value}/${object.selectedSearch.id}`,
        { skipLocationChange: true },
      );
    } else {
      if (this.isRestrictedRoleSubmission === true) {
        this.alertService.clearAlerts(-1);
        const alertData = {
          type: 'warn',
          headerText: getAlertHead('warning'),
          bodyText: this.translate.instant(
            'common.error.noAccessToPageErrorMsg',
          ),
        };
        this.alertService.addAlert(alertData);
        return;
      }
      this.router.navigateByUrl(
        `dashboard/workflow3/policies/${object.selectedSearch.value}`,
        { skipLocationChange: true },
      );
    }
  }
}
