import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ParentCompanySubjectService } from 'src/app/components/subjects/parent-company.service';
import { DropdownListDto } from 'src/app/dtos/dropdownList.dto';
import { Brokerage } from 'src/app/entities/brokerage';
import { getErrorMessage } from 'src/app/utils/utils';
import { BrokerageService } from 'src/app/services/brokerage.service';
import { getBrokerageSelector } from 'src/app/store/brokerage/brokerage.select';
import { getBrokerageProfileSelector } from 'src/app/store/brokerageProfile/brokerageProfile.select';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { BrokerageParentCompany } from 'src/app/entities/brokerage-parent-company';
import { saveBrokerageParentCompany } from 'src/app/store/brokerageProfile/brokerageProfile.action';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';

@Component({
  selector: 'app-brokerage-details-v2',
  templateUrl: './brokerage-details-v2.component.html',
  styleUrls: ['./brokerage-details-v2.component.less'],
})
export class BrokerageDetailsV2Component
  implements OnInit, OnDestroy, OnChanges
{
  @Input() form: FormGroup;
  @Input() requiredErrMsg: string;
  @Input() invalidErrMsg: string;
  @Input() parentCompanyInvalidErrMsg: string;
  @Input() formSubmitted: boolean = false;
  @Input() parentInitValue: string = '';
  @Input() isFetching: boolean;
  @Input() isActive: boolean;
  @Input() logoProfile: string | null;
  @Output() triggerProfilePicErrorToParent = new EventEmitter<string>();

  brokerageParents: DropdownListDto[] = [];
  brokerage: Brokerage = null;
  subscription: Subscription;
  private userId: number;
  skipChildrens: number;
  ignoredIds: string = '';
  showErrorAlert = false;
  msgErrorAlert = '';
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};

  constructor(
    private parentCompanySubjectService: ParentCompanySubjectService,
    private store: Store,
    private brokerageService: BrokerageService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
    });

    this.userId = this.localStorageService.getBoxxUserId();
    this.store.pipe(select(getBrokerageProfileSelector)).subscribe((item) => {
      this.logoProfile = item?.logo;
      this.ignoredIds = `${item?.brokerageId ?? ``}`;
    });

    this.store.pipe(select(getBrokerageSelector)).subscribe((item) => {
      this.brokerage = item;
    });

    this.subscription = this.parentCompanySubjectService
      .getObservavle()
      .subscribe(() => {
        this.parentInitValue = '';
        this.brokerageParents = [];
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.logoProfile === null) this.parentInitValue = '';
  }

  handleTextInputChange(event: string) {
    this.parentInitValue = event;

    if (event.length >= 3) {
      this.skipChildrens = 1;

      this.brokerageService
        .getActiveBrokerageIdName(
          event.toLowerCase(),
          this.skipChildrens,
          this.ignoredIds,
        )
        .subscribe({
          next: (response) => {
            if (response.data) {
              let parentCompanyDetails: BrokerageParentCompany = {
                searchText: event,
                searchResults: response.data,
              };
              this.store.dispatch(
                new saveBrokerageParentCompany(parentCompanyDetails),
              );
              const list = response.data;
              this.brokerageParents = list.map((data: any) => {
                const dto: DropdownListDto = {
                  id: data.id,
                  key: data.name,
                  value: data.name,
                };
                return dto;
              });
            } else {
              this.form.get('parent').setValue(null);
              this.brokerageParents = [];
            }
          },
          error: (error) => {
            const errorMsg = getErrorMessage(error);
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = errorMsg;
          },
        });
    } else {
      let parentCompanyDetails: BrokerageParentCompany = {
        searchText: event,
      };
      if (event === '') {
        parentCompanyDetails.id = undefined;
        parentCompanyDetails.name = '';
        parentCompanyDetails.searchResults = [];
      }
      this.store.dispatch(new saveBrokerageParentCompany(parentCompanyDetails));
      this.form.get('parent').setValue(null);
      this.brokerageParents = [];
    }
  }

  handleCloseSuccessEvent() {
    this.showErrorAlert = false;
  }

  handleBusinessSector(event: string) {
    const itemSelected = this.brokerageParents.find(
      (item: any) => item.id === event,
    );
    this.parentInitValue = itemSelected.value;
    this.form.get('parent').setValue(itemSelected.id);
    let parentCompanyDetails: BrokerageParentCompany = {
      id: itemSelected.id,
      name: itemSelected.value,
      searchText: itemSelected.value,
    };
    this.store.dispatch(new saveBrokerageParentCompany(parentCompanyDetails));
    this.parentCompanyInvalidErrMsg = '';
  }

  handleImageChange(event: string) {
    this.form.get('logo').setValue(event);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  triggerProfilePicError(event: any) {
    this.triggerProfilePicErrorToParent.emit(event);
  }
  handleImageLogoClose(image: string) {
    this.form.get('logo').setValue(image != 'assets/img/logo.png' ? image : '');
  }
}
