import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { InsuredService } from 'src/app/services/insured.service';
import { Insured } from 'src/app/entities/insured';
import {
  CREATE_INSURED_COMPANY,
  CREATE_INSURED_INDIVIDUAL,
  CREATE_LOCATION,
  GET_INSURED_BY_COMPANY,
  GET_INSURED_BY_INDIVIDUAL,
  GET_RISK_REGION_BY_VERSION,
  LOAD_INDUSTRY_BY_INDUSTRY_CLASS_ID,
  LOAD_INDUSTRY_CLASS,
  RETRIEVE_API_INSURED,
  creationInsuredSuccess,
  loadInsuredSuccess,
  loadProductsSuccess,
  GET_RISK_QUESTIONS,
  loadRiskQuestionsSuccess,
  loadRiskCoveragesSuccess,
  GET_RISK_COVERAGES,
} from './create-quote.action';
import { LocationService } from 'src/app/services/location.service';
import { IndustryService } from 'src/app/services/industry.service';
import { IndustryClassService } from 'src/app/services/industry-class.service';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import { RiskQuestionService } from 'src/app/services/risk-question.service';
import { ConfLimitService } from 'src/app/services/conf-limit.service';
import { STANDALONE_SELLABLE_TRUE } from 'src/app/constants/quote-constant';

@Injectable()
export class InsuredEffects {
  loadInsuredByIndividual1$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RETRIEVE_API_INSURED),
      mergeMap(() =>
        this.insuredService.GetAll(1, 10, '').pipe(
          map((item) => {
            return [...item.data].map((item: any) => {
              const insured: Insured = {
                id: item.id,
                firstName: item.firstName,
                insuredType: 0,
                createdDt: undefined,
                createdBy: 0,
              };
              return insured;
            });
          }),
          map((result) =>
            loadInsuredSuccess({
              insureds: result,
              pagination: {
                currentPage: 1,
                totalPages: 1,
                totalRecords: 34,
                hasNext: false,
                hasPrevious: false,
              },
            }),
          ),
          // map(item =>  (loadInsuredSuccess({ insureds: item.data, pagination: item.pagination }))),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  loadInsuredByCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_INSURED_BY_COMPANY),
      mergeMap((action: any) =>
        this.insuredService
          .GetAllByCompany(
            action.companyName,
            action.companyWebsite,
            action.address,
            action.page,
            10,
            action.sort,
            action.active || null,
          )
          .pipe(
            map((item) =>
              loadInsuredSuccess({
                insureds: item.data,
                pagination: item.pagination,
              }),
            ),
            catchError(() => EMPTY),
          ),
      ),
    ),
  );

  loadInsuredByIndividual$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_INSURED_BY_INDIVIDUAL),
      mergeMap((action: any) =>
        this.insuredService
          .GetAllByIndividual(
            action.name,
            action.email,
            action.address,
            action.page,
            10,
            action.sort,
            action?.active || null,
          )
          .pipe(
            map((item) =>
              loadInsuredSuccess({
                insureds: item.data,
                pagination: item.pagination,
              }),
            ),
            catchError(() => EMPTY),
          ),
      ),
    ),
  );

  createInsuredCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CREATE_INSURED_COMPANY),
      mergeMap((action: any) =>
        this.insuredService.Create(action.insured).pipe(
          map((item) => creationInsuredSuccess(item)),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );
  createIndividualCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CREATE_INSURED_INDIVIDUAL),
      mergeMap((action: any) =>
        this.insuredService.Create(action.insured).pipe(
          map((item) => creationInsuredSuccess(item)),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  createLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CREATE_LOCATION),
      mergeMap((action: any) =>
        this.locationService.Create(action.location).pipe(
          map((item) => creationInsuredSuccess(item)),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  loadIndustryClass$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_INDUSTRY_CLASS),
      mergeMap((action: any) =>
        this.industryClassService.GetAll().pipe(
          map((item) => creationInsuredSuccess(item)),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  loadIndustryByIndustryClassId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_INDUSTRY_BY_INDUSTRY_CLASS_ID),
      mergeMap((action: any) =>
        this.industryService
          .GetAllByIndustryClassId(action.industryClassId)
          .pipe(
            map((item) => creationInsuredSuccess(item)),
            catchError(() => EMPTY),
          ),
      ),
    ),
  );

  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_RISK_REGION_BY_VERSION),
      mergeMap((action: any) =>
        this.riskRegionService
          .getRiskRegionAndVersion(
            action.regionId,
            action.versionId,
            STANDALONE_SELLABLE_TRUE,
          )
          .pipe(
            map((item) => {
              return [...item.data].map((item: any, index, arr) => {
                const underwriterName =
                  item.defaultUnderwriter?.firstName +
                  ' ' +
                  item.defaultUnderwriter?.lastName;
                const riskRegion = {
                  key: item.risk.name,
                  value: item.risk.name,
                  description: item.risk.description,
                  checked: arr.length == 1,
                  active: true,
                  underwriter: underwriterName
                    ? underwriterName.toString()
                    : '',
                  underwriterId: item.defaultUnderwriter?.id,
                  riskId: item.risk.id,
                  standaloneSellable: item.risk?.standaloneSellable === 1,
                };
                return riskRegion;
              });
            }),
            map((result) => loadProductsSuccess({ products: result })),
            catchError(() => EMPTY),
          ),
      ),
    ),
  );

  loadRiskQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_RISK_QUESTIONS),
      mergeMap((action: any) =>
        this.riskQuestionService
          .GetByRegionId(
            action.regionId,
            action.languageId,
            action.insuredId,
            action.limit,
          )
          .pipe(
            map((item) => {
              return [...item.data].map((item: any, index, arr) => {
                return {
                  ...item,
                  value: null,
                };
              });
            }),
            map((result) =>
              loadRiskQuestionsSuccess({
                riskQuestions: { [action.product]: result },
              }),
            ),
            catchError(() => {
              return of({ type: 'LOAD_RISK_QUESTIONS_ERROR' });
            }),
          ),
      ),
    ),
  );

  loadRiskCoverages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_RISK_COVERAGES),
      mergeMap((action: any) =>
        this.ConfLimitService.GetByRiskRegionId(
          action.riskRegionId,
          action.limit,
        ).pipe(
          map((item) => {
            return [...item.data]
              .filter((item) => item.active)
              .map((item: any, index, arr) => {
                return {
                  ...item,
                  isIncluded: true,
                };
              });
          }),
          map((result) =>
            loadRiskCoveragesSuccess({
              riskCoverages: { [action.product]: result },
            }),
          ),
          catchError(() => {
            return of({ type: 'LOAD_RISK_COVERAGES_ERROR' });
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private insuredService: InsuredService,
    private locationService: LocationService,
    private industryService: IndustryService,
    private industryClassService: IndustryClassService,
    private riskRegionService: RiskRegionService,
    private riskQuestionService: RiskQuestionService,
    private ConfLimitService: ConfLimitService,
  ) {}
}
