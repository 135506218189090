import { Action } from '@ngrx/store';
import { RolePermission } from 'src/app/entities/role-permission';

export const SAVE_PERMISSIONS = 'SAVE_PERMISSIONS';
export const UPDATE_DASHBOARD_CONFIG = 'UPDATE_DASHBOARD_CONFIG';
export const UPDATE_QUOTE_FLOW_STEPS = 'UPDATE_QUOTE_FLOW_STEPS';
export const SAVE_USERTYPES = 'SAVE_USERTYPES';
export const SET_RESTRICTED_ROLE_SUBMISSION = 'SET_RESTRICTED_ROLE_SUBMISSION';

export class savePermissionsAction implements Action {
  readonly type = SAVE_PERMISSIONS;
  constructor(public permissions: RolePermission[]) {}
}

export class updateDashboardConfigAction implements Action {
  readonly type = UPDATE_DASHBOARD_CONFIG;
  constructor(public payload) {}
}

export class saveUserTypes implements Action {
  readonly type = SAVE_USERTYPES;
  constructor(public userTypes) {}
}
export class setRestrictedRoleSubmission implements Action {
  readonly type = SET_RESTRICTED_ROLE_SUBMISSION;
  constructor(public newValue: boolean) {}
}
