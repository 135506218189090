<div class="custom-modal" *ngIf="showModal">
  <div class="modal-content">
    <div class="close-btn">
      <button (click)="closeHandler()">
        <img src="assets/img/dashboard/card/close.png" />Close
      </button>
    </div>
    <div class="content">
      <div class="d-flex flex-column align-items-center">
        <img
          src="assets/img/alert-triangle.jpg"
          width="66px"
          height="66px"
          alt="alert"
        />
        <boxxds-body
          sz="md"
          [text]="titleText"
          [customStyle]="{ color: '#FE802C', marginTop: '12px' }"
        ></boxxds-body>
      </div>
      <div class="quote-details d-flex flex-column mt-1">
        <boxxds-body
          sz="md"
          [text]="details.quoteNumber"
          [customStyle]="{ color: '#616162', marginTop: '20px' }"
        ></boxxds-body>
        <div class="d-flex mt-1">
          <boxxds-body
            sz="xs"
            [text]="'Effective date:'"
            [customStyle]="{
              color: '#616162',
              marginRight: '4px',
              lineHeight: '14px'
            }"
          ></boxxds-body>
          <boxxds-body
            sz="xs"
            [text]="details.effectiveDate"
            [customStyle]="{ color: '#616162', lineHeight: '14px' }"
          ></boxxds-body>
        </div>
        <div class="d-flex mt-1">
          <boxxds-body
            sz="xs"
            [text]="'Insured:'"
            [customStyle]="{
              color: '#616162',
              marginRight: '4px',
              lineHeight: '14px'
            }"
          ></boxxds-body>
          <boxxds-body
            sz="xs"
            [text]="details.insured"
            [customStyle]="{ color: '#616162', lineHeight: '14px' }"
          ></boxxds-body>
        </div>
        <div class="d-flex mt-1">
          <boxxds-body
            sz="xs"
            [text]="details.brokerage + ', '"
            [customStyle]="{
              color: '#616162',
              marginRight: '4px',
              lineHeight: '14px'
            }"
          ></boxxds-body>
          <boxxds-body
            sz="xs"
            [text]="details.branch"
            [customStyle]="{ color: '#616162', lineHeight: '14px' }"
          ></boxxds-body>
        </div>
      </div>
      <div class="flex-column mb-5">
        <boxxds-body
          sz="sm"
          [text]="getReasonLabel(details.action)"
          [customStyle]="{ color: '#333334' }"
        ></boxxds-body>
        <boxxds-dropdown
          [form]="form"
          [control]="'reason'"
          [placeholder]="'Select'"
          [options]="reasonOptions"
          [showKeyasTitle]="true"
          [dropdownContainerStyle]="{ maxWidth: '250px', marginTop: '8px' }"
          [dropdownListStyle]="{ maxHeight: '88px', paddingBottom: '5px' }"
          (currentValueChange)="reasonHandler($event)"
        ></boxxds-dropdown>
      </div>
      <boxxds-button
        [isDisabled]="buttonDisabled"
        buttonText="Confirm"
        [type]="'submitted'"
        [btnStyle]="{ width: '107px', marginLeft: 'auto', marginTop: '40px' }"
        (handleClick)="actionConfirmHandler($event)"
      >
      </boxxds-button>
    </div>
  </div>
</div>
