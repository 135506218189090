import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { BoxxResponse, Pagination } from 'src/app/entities/boxx-response';
import { Role } from 'src/app/entities/role';
import { Breadcrumb } from 'src/app/models/breadcrumb.model';
import { Quote } from 'src/app/models/quote.model';
import { DomainsService } from 'src/app/services/domains.service';
import { RoleService } from 'src/app/services/roles.service';
import { arrayToObjet, getErrorMessage } from 'src/app/utils/utils';
import { Domains } from 'src/app/models/domains';
import { formatDateTime } from 'src/app/utils/formatDate';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { getUserTypes } from 'src/app/store/dashboard/dashboard.selector';

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.less'],
})
export class EditRoleComponent implements OnInit {
  itemsMenu: Breadcrumb[] = [
    { label: 'Roles & permissions', path: '/dashboard/security/roles' },
    {
      label: 'Role details',
      path: `/dashboard/security/roles/${this.activedRoute.snapshot.paramMap.get(
        'roleId',
      )}`,
    },
    { label: 'Edit role', path: null },
  ];

  userTypes: Array<any> = [];
  subNavData;
  options: Array<any> = [{ key: 'select', value: 'select' }];
  roleOptions: Array<any> = [{ key: 'Systems admin', value: 'Systems admin' }];
  docOptions: Array<any> = [
    { name: '0', key: 'permission 1' },
    { name: '1', key: 'permission 2' },
  ];
  form: FormGroup;
  form$: Observable<Quote>;
  searchKey;
  submitted: boolean = false;
  totalDataCount = 0;
  pagination: Observable<Pagination>;
  isSearchResult: boolean = false;
  roleForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    systemUserType: new FormControl(''),
  });
  requiredErrMsg: string = 'This field is required';
  invalidErrMsg: string = 'This field is invalid';
  isSuccess = false;
  hasError = false;
  errorMessage = '';
  formSubmitted: boolean = false;
  manager = '';
  title = '';
  showSpinner: boolean = false;
  showBodySpinner: boolean = false;
  roleId: string = '';
  role: any;
  systemUserType = '';
  systemUserTypes: any = [];
  showErrorAlert = false;
  msgErrorAlert = '';
  userID: number;
  longDateFormat: string = '';
  shortDateFormat: string = '';
  shortDateTimeFormat: string = '';
  longDateTimeFormat: string = '';

  constructor(
    private activedRoute: ActivatedRoute,
    private roleService: RoleService,
    private domainsService: DomainsService,
    private localStorageService: LocalStorageService,
    private store: Store,
  ) {
    this.roleId = this.activedRoute.snapshot.paramMap.get('roleId');
    this.fetchRole(this.roleId);
  }

  async ngOnInit(): Promise<void> {
    this.userID = this.localStorageService.getBoxxUserId();
    this.fetchSystemUserTypes();
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
      this.shortDateTimeFormat = data.shortDateTimeFormat;
      this.longDateTimeFormat = data.longDateTimeFormat;
    });
    this.store.pipe(select(getUserTypes)).subscribe(async (data) => {
      const dropdownData = data.map((dataObj) => ({
        key: dataObj.description,
        value: dataObj.id,
        id: dataObj.id,
      }));
      this.userTypes = dropdownData;
      if (dropdownData.length === 0) {
        await this.fetchSystemUserTypes();
      }
    });
    this.systemUserTypes = this.userTypes;
  }

  fetchSystemUserTypes() {
    this.domainsService.GetByDomainCode('SYSTEMUSERTYPE').subscribe({
      next: (response) => {
        this.showBodySpinner = false;
        this.setSystemUserTypesResult(response);
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });
  }

  setSystemUserTypesResult(data: BoxxResponse<Domains>) {
    const tableData = data.data?.map((dataObj) => ({
      key: `${dataObj.description}`,
      value: `${dataObj.id}`,
    }));
    this.systemUserTypes = [...tableData];
    this.userTypes = [...tableData];
  }

  fetchRole(roleId) {
    this.showBodySpinner = true;
    this.roleService.GetRole(roleId).subscribe({
      next: (response) => {
        this.showBodySpinner = false;
        this.setRoleSearchResult(response);
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });
  }

  setRoleSearchResult(data: BoxxResponse<Role>) {
    const { data: role } = data;
    this.role = role;
    this.populateForm(role);
  }

  populateForm(role) {
    this.roleForm.get('name').setValue(role?.name ?? '');
    this.roleForm.get('description').setValue(role.description);
    this.roleForm.get('systemUserType').setValue(role.systemUserType);
    const userTypes = arrayToObjet(this.userTypes, 'value');

    if (role.systemUserType) {
      this.systemUserType = userTypes[role.systemUserType].key;
    }
    this.roleForm[role.active ? 'enable' : 'disable']({ emitEvent: false });

    this.role['createdDt'] = formatDateTime(
      role.createdDt,
      this.longDateTimeFormat,
    );
    this.role['updatedDt'] = formatDateTime(
      role.updatedDt,
      this.longDateTimeFormat,
    );
  }

  resetRoleForm() {
    this.roleForm.reset();
  }

  onSubmit(data) {
    if (this.roleForm.disabled) {
      return;
    }
    this.formSubmitted = true;
    this.hasError = false;

    const { id: roleId } = this.role;
    // check boxx user form is invalid
    if (this.roleForm.invalid) {
      return;
    }
    this.showSpinner = true;
    this.roleService.UpdateRole(roleId, data).subscribe(
      (response) => {
        this.showSpinner = false;
        this.isSuccess = true;
        this.formSubmitted = false;
        this.role = {
          ...this.role,
          ...response.data,
        };
        this.populateForm(this.role);
      },
      ({ error }) => {
        this.showSpinner = false;
        this.hasError = true;
        this.errorMessage = error.message;
      },
    );
  }
  dropdownValueChanged() {}
  pageChangeHandler(pageNumber) {}

  handleAttachSubjectives(event, index) {}

  getSearchKeyWords(keywordObj = {}) {}

  addRole(event) {}

  handleNoOfPIIRecords(event) {}

  handleCloseSuccessEvent() {
    this.isSuccess = false;
    this.hasError = false;
  }
  handleCloseSuccessEventExt() {
    this.showErrorAlert = false;
  }
}
