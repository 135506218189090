import { HttpErrorResponse } from '@angular/common/http';
import moment from 'moment';
import { DropdownListDto } from 'src/app/dtos/dropdownList.dto';
import { KeyValueDto } from 'src/app/dtos/keyvalueList.dto';
import { Insured } from 'src/app/entities/insured';
import { Policy } from 'src/app/entities/policy';
import { getFormattedPolicyPeriod } from 'src/app/utils/formatDate';

export function mapList(data: DropdownListDto): KeyValueDto {
  const kv: KeyValueDto = { key: data.id.toString(), value: data.value };
  return kv;
}

export function mapListReverse(data: DropdownListDto): KeyValueDto {
  // NOTE: boxxds-dropdown has the key and value backwards
  const kv: KeyValueDto = { key: data.value, value: data.id.toString() };
  return kv;
}

export function calculateAnnualAmount(policyRiskTrx: any): number {
  const triaAmount: number = Number(policyRiskTrx.triaAmount);
  const policyFee: number = Number(policyRiskTrx.policyFee);
  const premium: number = Number(policyRiskTrx.premium);
  const servicesAmount: number = Number(policyRiskTrx.servicesAmount);
  const taxesPremiumAmount: number = Number(policyRiskTrx.taxesPremiumAmount);
  const taxesServicesAmount: number = Number(policyRiskTrx.taxesServicesAmount);

  return (
    premium +
    servicesAmount +
    taxesPremiumAmount +
    taxesServicesAmount +
    policyFee +
    triaAmount
  );
}

export function getFirstBindDate(policy: Policy): string {
  const invalidResponse: string = 'n/a';
  const firstBind: moment.Moment = moment(policy.firstBindDt, moment.ISO_8601);

  if (!firstBind.isValid()) {
    return invalidResponse;
  }

  return firstBind.year() < 2000
    ? invalidResponse
    : firstBind.format('MMM DD, YYYY');
}

export function getPolicyNumber(policy: Policy): string {
  return policy.pkgPolicyNumber ? policy.pkgPolicyNumber : policy.id.toString();
}

export function getInsuredName(insured: Insured): string {
  return insured.companyName
    ? insured.companyName
    : `${insured.firstName} ${insured.lastName}`;
}

export function getRiskPeriod(risk: any, dateFormat: string): string {
  return risk?.policyPeriod
    ? getFormattedPolicyPeriod(risk.policyPeriod, dateFormat)
    : '';
}

export function convertStatusType(status: string): string {
  switch (status?.toLowerCase()) {
    case 'new submissions':
    case 'reinstated':
      return 'default';
    case 'bound':
    case 'referral':
      return 'primary';
    case 'quote':
    case 'quoted':
      return 'secondary';
    case 'renewed':
    case 'renewal quote':
    case 'renewal app':
      return 'warning';
    case 'quote closed':
    case 'cancelled':
    case 'lapsed':
    case 'declined':
    case 'closed':
    case 'not taken up':
      return 'inactive';
  }
  return 'default';
}

export function getValueFromList(list: DropdownListDto[], id: number): string {
  if (list) {
    var found = list.find((x) => x.id === id);

    if (found) {
      return found.value;
    }
  }

  return `n/a`;
}

export function addSpaceBeforeCapitalLetter(value: string) {
  if (value && typeof value !== 'string') {
    value = String(value);
  }
  if (value) {
    return value.replace(/(?<!^)([A-Z])/g, ' $1'); // -> "Start Next Period"
  }
  return '';
}

export function handleApiError(response: HttpErrorResponse): string {
  const error = response.error;

  if (!error) {
    return 'Unknown error';
  }

  if (typeof error === 'string' || error instanceof String) {
    if (error?.toLowerCase().includes('error code: 404', 0)) {
      return 'Not found.'; // TODO: Translate
    } else {
      return error.toString(); // TODO: Translate?
    }
  }

  if (error.errors) {
    return error.errors.message;
  }

  if (error.error) {
    return error.error;
  }

  if (error.message) {
    return error.message;
  }

  return `${error.status}, ${error.code}`;
}

export function isNumeric(value: any) {
  return /^-?\d+$/.test(value);
}

export function isSuccessApiStatus(status: string): boolean {
  return status && (status == '200' || status == '201');
}
