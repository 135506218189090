import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Breadcrumb } from 'src/app/models/breadcrumb.model';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import {
  arrayToObjet,
  regexHelpers,
  getErrorMessage,
} from 'src/app/utils/utils';

@Component({
  selector: 'app-new-boxx-user',
  templateUrl: './new-boxx-user.component.html',
  styleUrls: ['./new-boxx-user.component.less'],
})
export class NewBoxxUserComponent {
  itemsMenu: Breadcrumb[] = [
    { label: 'Boxx users directory', path: '/dashboard/boxx-user-directory' },
    { label: 'Add new Boxx user', path: null },
  ];

  titles: any = [
    { key: 'Mr', value: 'mr' },
    { key: 'Mrs', value: 'mrs' },
    { key: 'Miss', value: 'miss' },
  ];

  managers: any = [];

  boxxUserForm = new FormGroup({
    firstName: new FormControl('', [
      Validators.required,
      Validators.pattern(regexHelpers.AlphaNumericSpace),
    ]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.pattern(regexHelpers.AlphaNumericSpace),
    ]),
    title: new FormControl('', [
      Validators.required,
      Validators.pattern(regexHelpers.AlphaNumericSpace),
    ]),
    manager: new FormControl('', [Validators.required]),
    // uwRole: new FormControl('string'),
  });

  requiredErrMsg: string = 'error.thisFieldIsRequired';
  invalidErrMsg: string = 'error.thisFieldIsInvalid';
  invalidManager: string = '';
  isSuccess = false;
  hasError = false;
  errorMessage = '';
  formSubmitted: boolean = false;
  manager = '';
  title = '';
  showSpinner: boolean = false;
  showBodySpinner: boolean = false;
  showErrorAlert = false;
  msgErrorAlert = '';

  constructor(private boxxUserService: BoxxUserService) {}

  searchManagers(name) {
    this.invalidManager = '';
    this.boxxUserForm.get('manager').setValue(null);
    if (name.length >= 3) {
      let limit = 10,
        page = 1,
        sort = '';
      let active = 1;
      this.boxxUserService
        .GetAllUserByName(name, page, limit, sort, active)
        .subscribe({
          next: (response) => {
            const tableData = response.data?.map((dataObj) => ({
              key: `${dataObj.name}`,
              value: dataObj.name,
              id: dataObj.id,
            }));
            this.showBodySpinner = false;
            this.managers = [...tableData];
            if (tableData.length > 0) {
              this.invalidManager = 'error.thisFieldIsRequired';
            } else {
              this.invalidManager = '';
            }
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    } else {
      this.managers = [];
    }
  }

  resetBoxxUserForm() {
    this.boxxUserForm.get('firstName').setValue(null);
    this.boxxUserForm.get('lastName').setValue(null);
    this.boxxUserForm.get('title').setValue(null);
    this.boxxUserForm.get('manager').setValue(null);
    this.manager = '';
    this.title = '';
  }

  onSubmit(data) {
    this.formSubmitted = true;
    this.hasError = false;

    // check boxx user form is invalid
    if (this.boxxUserForm.invalid) {
      return;
    }
    this.showBodySpinner = true;
    this.boxxUserService.CreateBoxxUser(data).subscribe(
      (response) => {
        this.showSpinner = false;
        this.showBodySpinner = false;
        this.isSuccess = true;
        this.formSubmitted = false;
        this.resetBoxxUserForm();
      },
      ({ error }) => {
        this.showBodySpinner = false;
        this.hasError = true;
        this.errorMessage = error.message;
      },
    );
  }

  titleChanged(value) {
    const titles = arrayToObjet(this.titles, 'value');
    const title = titles[value];
    this.title = title.key;
  }

  managerChanged(value) {
    const managers = arrayToObjet(this.managers, 'value');
    const manager = managers[value];
    this.manager = manager.key;
    this.boxxUserForm.get('manager').setValue(manager.id);
    this.invalidManager = '';
  }

  handleCloseSuccessEvent() {
    this.isSuccess = false;
    this.hasError = false;
  }
  handleCloseSuccessEventExt() {
    this.showErrorAlert = false;
  }
}
