<ng-content></ng-content>
<div class="panel-commission-details">
  <ng-container *ngIf="this.permissionList[this.currentScreen]">
    <app-add-commission
      [isActive]="isActive"
      (handleAddCommission)="handleAddCommission($event)"
      [resetAddFormSubject]="resetAddFormSubject"
    >
    </app-add-commission>
  </ng-container>
  <app-container-commission-detail
    [isActive]="isActive"
    [commissionData]="commissionData"
    (onPageChanged)="handleCommissionPageChange($event)"
    (onUpdated)="
      this.permissionList[this.currentScreen] && handleUpdateCommission($event)
    "
    (onDisabled)="
      this.permissionList[this.currentScreen] && handleDisableCommission($event)
    "
    (onEnabled)="
      this.permissionList[this.currentScreen] && handleEnableCommission($event)
    "
  >
  </app-container-commission-detail>
</div>
