import { IDashboard } from '../models/dashboard.model';
import { Insured } from '../models/insured.model';
import { Quote } from '../models/quote.model';
import {
  BrokerageState,
  createBrokerageReducer,
} from './brokerage/brokerage.reducer';
import {
  BrokerageProfileState,
  createBrokerageProfileReducer,
} from './brokerageProfile/brokerageProfile.reducer';
import {
  CommissionState,
  createCommissionReducer,
} from './commission/commission.reducer';
import { createQuoteReducer } from './create-quote/create-quote.reducer';
import { dashboardReducer } from './dashboard/dashboard.reducer';
import { insuredReducer } from './insured/insured.reducer';
import {
  LifecycleState,
  lifecycleReducer,
} from './lifecycle/lifecycle.reducer';
import {
  RiskRegionReducer,
  RiskRegionState,
} from './riskRegion/riskRegion.reducer';

export interface stores {
  stateCommission: CommissionState;
  stateQuote: Quote;
  stateRiskRegion: RiskRegionState;
  stateBrokerageProfile: BrokerageProfileState;
  stateBrokerage: BrokerageState;
  stateDashboard: IDashboard;
  stateLifecycle: LifecycleState;
  pathOptions: any;
  stateInsured: Insured;
}

export const featureReducers = {
  stateCommission: createCommissionReducer,
  stateQuote: createQuoteReducer,
  stateRiskRegion: RiskRegionReducer,
  stateBrokerageProfile: createBrokerageProfileReducer,
  stateBrokerage: createBrokerageReducer,
  stateDashboard: dashboardReducer,
  stateLifecycle: lifecycleReducer,
  stateInsured: insuredReducer,
};
