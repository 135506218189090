<div class="info-holder" *ngIf="showPageHeader">
  <div class="info-header">
    <h3>
      {{ pageName
      }}<ng-container *ngIf="progressBarData.activeStep === 3">{{
        (form$ | async)?.ui.policyId
      }}</ng-container>
    </h3>
    <app-progress-bar
      [showProgressBar]="progressBarData.showProgressBar"
      [activeStep]="progressBarData.activeStep"
      [completedSteps]="progressBarData.completedSteps"
      [totalSteps]="progressBarData.totalSteps"
      [steps]="progressBarData.steps"
    ></app-progress-bar>
  </div>
</div>
<div
  class="insured-holder"
  [ngClass]="{ 'premium-holder': showPremiumDetails }"
>
  <app-quote4-flow-header
    [insuredInfo]="insuredInfo"
    [brokerageInfo]="brokerageInfo"
    [showBrokerDetails]="showBrokerDetails"
    [showInsurerDetails]="showInsurerDetails"
  ></app-quote4-flow-header>
</div>
<router-outlet></router-outlet>
