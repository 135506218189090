<div class="holder">
  <div class="card">
    <div class="info-panel">
      <div class="content">
        <div class="heading-holder">
          <boxxds-heading
            [sz]="'1'"
            [text]="'401'"
            type="bold"
            [customStyle]="{
              fontSize: '50px',
              lineHeight: '80px',
              color: '#333334',
              textDecoration: 'underline 7px solid #FFBE3D',
              textUnderlineOffset: '6px',
              maxWidth: '75px',
              margin: '0 auto',
              whiteSpace: 'nowrap'
            }"
          ></boxxds-heading>
          <div class="strike-through"></div>
        </div>
        <boxxds-heading
          [sz]="'7'"
          [text]="'No authorization was found.'"
          [customStyle]="{ color: '#333334', marginTop: '12px' }"
        ></boxxds-heading>
        <boxxds-body
          sz="sm"
          [text]="
            'Your account does not have the authorization to access this page. Please check your login credentials.'
          "
          [customStyle]="{ color: '#56565C', marginTop: '15px' }"
        >
        </boxxds-body>
        <boxxds-button
          buttonText="Back to login"
          [ngStyle]="{ display: 'flex', justifyContent: 'center' }"
          [btnStyle]="{ width: '208px', marginTop: '83px' }"
          (handleClick)="handleNavigateToLogin()"
        >
        </boxxds-button>
      </div>
    </div>
    <div class="logo-panel">
      <div class="logo">
        <img src="assets/img/boxx.png" alt="boxx logo" />
      </div>
    </div>
  </div>
</div>
