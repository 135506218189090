<div class="maintenance-container">
  <div class="header">
    <img src="./assets/img/dashboard/boxx.png" alt="logo" />
  </div>
  <div class="maintenance-body">
    <div class="body-left">
      <div class="body-left-heading">
        {{ "maintenance.heading" | translate }}
      </div>
      <div class="body-left-content mb-3">
        {{ "maintenance.contentLine1" | translate }}
      </div>
      <div class="body-left-content">
        {{ "maintenance.contentLine2" | translate }}
      </div>
      <div class="availability-section">
        <div class="availability-time-label">
          {{ "maintenance.contentLine3" | translate }}
        </div>
        <div class="availability-time">
          {{ applicationAvailabilityDateTime }}
        </div>
      </div>
      <button class="refresh-btn" (click)="handleRefreshClick()">
        <span>{{ "maintenance.refreshButtonLabel" | translate }}</span>
        <img src="./assets/img/refresh.svg" alt="" />
      </button>
    </div>
    <div class="body-right">
      <img src="./assets/img/quotes-card.png" alt="" />
    </div>
  </div>
</div>
