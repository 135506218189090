import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-reload-modal',
  templateUrl: './reload-modal.component.html',
  styleUrls: ['./reload-modal.component.less'],
})
export class ReloadModalComponent {
  @Input() message: string = '';

  constructor() {}

  reloadWindow(): void {
    window.location.reload();
  }
}
