export enum TransactionRiskActions {
  None = 0,
  Close = 1,
  Decline,
  Delete,
  Endorse,
  Execute,
  Invoice,
  PolicyChange,
  Refer,
  RecalculatePremium,
}

export enum PolicyActions {
  None = 0,
  Bind = 1,
  Cancel,
  ReInstate,
  Renew,
  StartNextPeriod,
}
