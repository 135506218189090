import { Store } from '@ngrx/store';
import { setFetchCommissionsSuccess } from './commission.action';
import { Injectable } from '@angular/core';
import { BrokerageService } from 'src/app/services/brokerage.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, EMPTY, map, mergeMap } from 'rxjs';
import { FETCH_COMMISSIONS_BY_BROKERAGE_ID } from './commission.effects.type';
import { ICommission } from 'src/app/models/commission.model';

@Injectable()
export class CommissionEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private brokerageService: BrokerageService,
  ) {}

  loadCommissionsByBrokerageId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FETCH_COMMISSIONS_BY_BROKERAGE_ID),
      mergeMap((action: any) =>
        this.brokerageService
          .getActiveCommissionByBrokerageId(action.brokerageId)
          .pipe(
            map((response: any) => {
              return [...response.data].map((item: any) => {
                const commission: ICommission = {
                  id: item.id,
                  guiId: item.id,
                  isNew: false,
                  productId: item.riskRegion.risk.name,
                  productIdSelected: item.riskRegion.risk.id,
                  regionId: item.riskRegion.region.name,
                  regionIdSelected: item.riskRegion.region.id,
                  active: item.active,
                  maxPercentage: item.commissionMax,
                  minPercentage: item.commissionMin,
                  status: null,
                };
                return commission;
              });
            }),
            map((result) => new setFetchCommissionsSuccess({ items: result })),
            catchError(() => EMPTY),
          ),
      ),
    ),
  );
}
