import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-searchable-list',
  templateUrl: './searchablelist.component.html',
  styleUrls: ['./searchablelist.component.less'],
})
export class SearchableListComponent implements OnInit {
  @Input() options: Array<any>;
  @Input() currentValue: string = '';
  @Input() placeholder: string;
  @Input() label: string;
  @Input() invalidErrMsg: string = '';
  @Input() hideSelectedValue: boolean = false;
  @Input() insideSearch: boolean = false;
  @Input() btnStyle: { [klass: string]: any };
  @Input() dropdownContainerStyle: { [klass: string]: any };
  @Input() control: string;
  @Input() form: FormGroup;
  @Input() requiredErrMsg: string;
  @Input() formSubmitted: boolean;
  @Input() tooltiptext: string = '';
  @Input() showLabel: boolean = true;
  @Input() dispatchTextInputChange: boolean = false;
  @Output() currentValueChange = new EventEmitter();
  @Output() textInputChangeEventEmiter = new EventEmitter<string>();
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;

  filteredOptions: Array<any>;

  constructor(private elem: ElementRef) {}

  public dropdownOpen: boolean = false;
  public get dropdownElement(): Element {
    return this.elem.nativeElement.querySelector('.dropdown-list');
  }
  private currentIndex = -1;

  ngOnInit(): void {
    this.filteredOptions = this.options;
  }

  getDisplayValue(value) {
    if (!value || value == '') {
      return this.placeholder;
    }
    return value;
  }

  handleSearch(e: any) {
    const { value } = e.target;
    if (!this.dispatchTextInputChange)
      this.filteredOptions = this.options.filter((obj) =>
        obj.key.toLowerCase().startsWith(value.toLowerCase()),
      );
    else {
      this.textInputChangeEventEmiter.emit(value);
    }
  }

  handleKeyboardEvents($event: KeyboardEvent) {
    if (this.dropdownOpen) {
      $event.preventDefault();
    } else {
      return;
    }
    if ($event.code === 'ArrowUp') {
      if (this.currentIndex < 0) {
        this.currentIndex = 0;
      } else if (this.currentIndex > 0) {
        this.currentIndex--;
      }
      this.elem.nativeElement
        .querySelectorAll('li')
        .item(this.currentIndex)
        .focus();
    } else if ($event.code === 'ArrowDown') {
      if (this.currentIndex < 0) {
        this.currentIndex = 0;
      } else if (this.currentIndex < this.options.length - 1) {
        this.currentIndex++;
      }
      this.elem.nativeElement
        .querySelectorAll('li')
        .item(this.currentIndex)
        .focus();
    } else if (
      ($event.code === 'Enter' || $event.code === 'NumpadEnter') &&
      this.currentIndex >= 0
    ) {
      this.selectByIndex(this.currentIndex);
    } else if ($event.code === 'Escape') {
      this.closeDropdown();
    }
  }

  closeDropdown() {
    this.dropdownElement.setAttribute('aria-expanded', 'false');
    this.currentIndex = -1;
    this.dropdownOpen = false;
  }

  selectByIndex(i: number) {
    let value = this.options[i];
    this.select(value);
  }

  select(valueObj) {
    this.control &&
      this.form &&
      this.form?.controls &&
      this.form?.controls[this.control].setValue(valueObj.value, {
        emitEvent: false,
      });
    if (this.hideSelectedValue) {
      this.currentValue = '';
    } else {
      this.currentValue = valueObj.key;
    }
    this.filteredOptions = this.options;
    this.closeDropdown();
    this.currentValueChange.emit(valueObj.value);
  }

  toggleDropdown(e) {
    e.stopPropagation();
    this.dropdownOpen = !this.dropdownOpen;
    if (this.dropdownElement) {
      this.dropdownElement.setAttribute(
        'aria-expanded',
        this.dropdownOpen ? 'true' : 'false',
      );
    }
  }
}
