import { Injectable } from '@angular/core';
import { SystemUserService } from '../system-user.service';
import { Subject, firstValueFrom, BehaviorSubject } from 'rxjs';
import { UNDERWRITER } from 'src/app/constants/quote-constant';
import { PolicyRiskTrxService } from '../policy-risk-trx.service';

@Injectable({
  providedIn: 'root',
})
export class SharedFunctionService {
  constructor(
    private systemUserService: SystemUserService,
    private policyRiskTrxService: PolicyRiskTrxService,
  ) {}

  private isTeamsQueue = new BehaviorSubject<any>(null);
  currentData = this.isTeamsQueue.asObservable();

  public childEvent = new Subject<void>();

  async updateUnderwriter(
    currentUnderWriterId,
    transactionId,
    currentUnderWriterName = '',
  ) {
    try {
      const systemUsrId = localStorage.getItem('sid');
      const getLoggedInUserData = this.systemUserService.Get(systemUsrId);
      const loggedInUserData = await firstValueFrom(getLoggedInUserData);
      const loggedInUserId = loggedInUserData?.data?.boxxUser?.id;
      if (
        loggedInUserData?.data?.role?.name?.toUpperCase() === UNDERWRITER &&
        currentUnderWriterId !== loggedInUserId
      ) {
        const riskTrxData = {
          underwriterId: loggedInUserData?.data?.boxxUser?.id,
        };

        const updateUnderwriterAPI = this.policyRiskTrxService.Update(
          transactionId,
          riskTrxData,
        );
        const updateUnderwriterResponse =
          await firstValueFrom(updateUnderwriterAPI);
        return {
          underwriterId: loggedInUserId,
          underwriterName: loggedInUserData?.data?.name,
        };
      }
    } catch (e) {}
    return {
      underwriterId: currentUnderWriterId,
      underwriterName: currentUnderWriterName,
    };
  }

  triggerEvent(alert) {
    this.childEvent.next(alert);
  }

  updateMyQueueTeamsQueue(data: any) {
    this.isTeamsQueue.next(data);
  }
}
