<div class="holder">
  <boxxds-heading
    sz="5"
    [customStyle]="{ marginBottom: '12px' }"
    [text]="'New submission' | translate"
    ><span></span>
  </boxxds-heading>
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!showBodySpinner">
    <boxxds-alert
      *ngIf="uiData.errorLoadingRiskQns || showErrAlert"
      type="error"
      [headerText]="'policyPeriod.update.error.header' | translate"
      [bodyText]="alertErrMsg | translate"
      (handleCloseEvent)="alertErrCloseHandler()"
    >
    </boxxds-alert>
    <boxxds-alert
      (handleCloseEvent)="handleNotificationAlertClose()"
      *ngIf="
        notificationAlert?.show && !(uiData.errorLoadingRiskQns || showErrAlert)
      "
      [type]="notificationAlert?.type"
      [headerText]="notificationAlert?.headerText | translate"
      [bodyText]="notificationAlert?.bodyText | translate: { id: quoteId }"
    >
    </boxxds-alert>

    <boxxds-alert
      (handleCloseEvent)="alertSuccessCloseHandler()"
      *ngIf="showSuccessAlert"
      type="success"
      [headerText]="'policyPeriod.update.success.header' | translate"
      [bodyText]="alertSuccessMsg"
    >
    </boxxds-alert>
    <app-quote-action-popup
      [showModal]="showActionPopup"
      [details]="actionPopupDetails"
      (handleActionConfirm)="handleConfirmRiskAction($event)"
      (handleClose)="showActionPopup = false"
    ></app-quote-action-popup>
    <div class="insured-name">{{ (form$ | async)?.ui.insuredName }}</div>
    <div class="date-details-head">
      <boxxds-body
        [text]="'common.dateDetails' | translate"
        sz="md"
        [customStyle]="{ color: '#616162', marginBottom: '8px' }"
      ></boxxds-body>
      <boxxds-body
        sz="xs"
        [text]="'*' + ('REQUIRED' | translate)"
        [customStyle]="{ color: '#8F8F91' }"
      ></boxxds-body>
    </div>
    <div class="date-details">
      <ng-container *ngIf="form$ | async as asyncForm">
        <boxxds-datepicker
          [placeholder]="shortDateFormat"
          [dateFormat]="shortDateFormat"
          label="Select effective date*"
          [containerStyle]="{ width: '186px' }"
          [bsValue]="(form$ | async)?.startDate"
          (bsValueChange)="
            this.permissionList[this.currentScreen] &&
              this.currentQuoteStatus !== 'quote-closed' &&
              handleStartDateChange($event, asyncForm.endDate)
          "
          [minDate]="minStartDate"
          [maxDate]="maxStartDate"
          [showErr]="!isStartDateValid"
          [errMsg]="startDateErrMsg | translate"
          [isDisabled]="
            !this.permissionList[this.currentScreen] ||
            this.currentQuoteStatus === 'quote-closed'
          "
        >
        </boxxds-datepicker>
      </ng-container>
      <div class="end-date">
        <label class="lbl-end-date">Expired date*</label>
        <div class="text-date" *ngIf="!endDateEditable">
          <p>
            {{
              (form$ | async)?.endDate
                ? (form$ | async)?.endDate
                : shortDateFormat
            }}
          </p>
          <button
            class="btn-edit"
            (click)="endDateEditable = !endDateEditable"
            *ngIf="
              this.permissionList[this.currentScreen] &&
              this.currentQuoteStatus !== 'quote-closed'
            "
          >
            <img src="assets/img/edit-icon.png" alt="edit" />
          </button>
        </div>
        <ng-container *ngIf="form$ | async as asyncForm">
          <boxxds-datepicker
            *ngIf="endDateEditable"
            [bsValue]="(form$ | async)?.endDate"
            (bsValueChange)="handleEndDateChange($event, asyncForm.startDate)"
            [placeholder]="shortDateFormat"
            [dateFormat]="shortDateFormat"
            [containerStyle]="{ width: '186px' }"
            [minDate]="minEndDate"
            [maxDate]="maxEndDate"
            [showErr]="!isEndDateValid"
            [errMsg]="endDateErrMsg | translate"
          >
          </boxxds-datepicker>
        </ng-container>
      </div>
    </div>
    <div class="product-content">
      <div class="product-nav">
        <ul class="products">
          <li
            *ngFor="
              let product of selectedProductStandaloneOptions;
              let i = index
            "
            (click)="setCurentAsActive(product.value)"
            class="product"
          >
            <div
              class="content"
              [ngClass]="{
                'active-product':
                  (form$ | async).ui.currentProductDisplayed !== ''
                    ? product?.value ===
                      (form$ | async).ui.currentProductDisplayed
                    : i === 0
              }"
            >
              <p>{{ product.key }}</p>
            </div>
          </li>
        </ul>
      </div>
      <ng-container
        *ngFor="let product of selectedProductStandaloneOptions; let i = index"
      >
        <div
          class="pdt-risk"
          *ngIf="(form$ | async).ui.currentProductDisplayed === product.value"
        >
          <boxxds-dropdown
            *ngIf="form$ | async as formObj"
            label="Assign underwriter to risk:"
            placeholder="Select Underwriter"
            [options]="underWriterOptions"
            (currentValueChange)="
              this.permissionList[this.currentScreen] &&
                this.currentQuoteStatus !== 'quote-closed' &&
                underwriterChanged($event, formObj.products, product.value)
            "
            [currentValue]="
              getCurrentUnderWriter(formObj.products, product.value)
            "
            [dropdownContainerStyle]="{ width: '186px' }"
            (loadMoreOptions)="
              this.permissionList[this.currentScreen] &&
                this.currentQuoteStatus !== 'quote-closed' &&
                loadMoreUnderwriters($event)
            "
            [hasScrollablePagination]="true"
            [totalCount]="underwriterTotalCount"
            [totalPages]="underwriterTotalPages"
            [class.noPointerEvents]="
              !this.permissionList[this.currentScreen] ||
              this.currentQuoteStatus === 'quote-closed'
            "
          >
          </boxxds-dropdown>
          <div
            class="risk-questions"
            [class.noPointerEvents]="
              !this.permissionList[this.currentScreen] ||
              this.currentQuoteStatus === 'quote-closed'
            "
            *ngIf="
              (form$ | async)?.riskQuestions[product.value] as riskQuestions
            "
            [formGroup]="riskForm"
          >
            <div [formArrayName]="product.value">
              <div
                *ngFor="
                  let category of getCategories(riskQuestions);
                  let catIdx = index
                "
              >
                <div class="category-inline-row">
                  <boxxds-body
                    sz="md"
                    [text]="category + '*'"
                    [customStyle]="{
                      color: '#616162',
                      marginTop: '30px',
                      marginBottom: '8px',
                      textTransform: 'capitalize'
                    }"
                  >
                  </boxxds-body>
                  <div *ngIf="getQuestions(category, riskQuestions).length > 1">
                    <div class="radio-all-btn">
                      <boxxds-radio
                        [control]="category"
                        [items]="[
                          {
                            label: ('common.yesToAll' | translate),
                            currentValue:
                              'categoryProperties[product.value + catIdx]?.checkAllYesBtn === true',
                            value: 'true'
                          }
                        ]"
                        [form]="getCategoryProductFormArray(product.value)"
                        [formSubmitted]="false"
                        (click)="
                          checkAllYes(
                            product.value,
                            category,
                            catIdx,
                            getQuestions(category, riskQuestions)
                          )
                        "
                        [radioFill]="true"
                        *ngIf="
                          this.permissionList[this.currentScreen] &&
                          this.currentQuoteStatus !== 'quote-closed'
                        "
                      ></boxxds-radio>

                      <boxxds-radio
                        [control]="category"
                        [items]="[
                          {
                            label: ('common.noToAll' | translate),
                            currentValue:
                              'categoryProperties[product.value + catIdx]?.checkAllYesBtn === false',
                            value: 'false'
                          }
                        ]"
                        [form]="getCategoryProductFormArray(product.value)"
                        [formSubmitted]="false"
                        (click)="
                          checkAllNo(
                            product.value,
                            category,
                            catIdx,
                            getQuestions(category, riskQuestions)
                          )
                        "
                        [radioFill]="true"
                        *ngIf="
                          this.permissionList[this.currentScreen] &&
                          this.currentQuoteStatus !== 'quote-closed'
                        "
                      ></boxxds-radio>
                    </div>
                  </div>
                </div>

                <div class="category-holder">
                  <ng-container
                    *ngIf="getQuestions(category, riskQuestions) as riskQustns"
                  >
                    <div
                      class="question"
                      *ngFor="
                        let q of getQuestions(category, riskQuestions);
                        let questnIdx = index
                      "
                    >
                      <div
                        class="boolean-query"
                        *ngIf="q.dataType.description === 'Boolean'"
                        [formGroupName]="getFormName(riskQuestions, q)"
                      >
                        <div>{{ q.question }}</div>
                        <div class="explanation" *ngIf="q.explanation">
                          <ul
                            [ngClass]="{
                              'column-layout': isColumnLayout(q.explanation)
                            }"
                          >
                            <li
                              *ngFor="
                                let point of getExplanationArr(q.explanation)
                              "
                            >
                              {{ point }}
                            </li>
                          </ul>
                        </div>
                        <div class="radio-btn">
                          <ng-container
                            *ngIf="getProductFormArray(product.value)"
                          >
                            <boxxds-radio
                              [control]="q.id"
                              [items]="[
                                {
                                  label: ('common.yes' | translate),
                                  currentValue: q.value,
                                  value: 'true'
                                },
                                {
                                  label: ('common.no' | translate),
                                  currentValue: q.value,
                                  value: 'false'
                                }
                              ]"
                              [form]="getProductFormArray(product.value)"
                              [formSubmitted]="
                                this.permissionList[this.currentScreen] &&
                                this.currentQuoteStatus !== 'quote-closed' &&
                                validateRiskAnswers
                              "
                              [requiredErrMsg]="
                                'error.thisFieldIsRequired' | translate
                              "
                              (click)="
                                this.permissionList[this.currentScreen] &&
                                  this.currentQuoteStatus !== 'quote-closed' &&
                                  handleRadioCheck(
                                    product.value,
                                    category,
                                    catIdx
                                  )
                              "
                              [radioFill]="true"
                            ></boxxds-radio>
                          </ng-container>
                        </div>
                      </div>
                      <div
                        class="boolean-query"
                        *ngIf="q.dataType.description === 'Numeric'"
                        [formGroupName]="getFormName(riskQuestions, q)"
                      >
                        <div>{{ q.question }}</div>
                        <div class="explanation" *ngIf="q.explanation">
                          <ul
                            [ngClass]="{
                              'column-layout': q.explanation.length > 12
                            }"
                          >
                            <li *ngFor="let point of q.explanation">
                              {{ point }}
                            </li>
                          </ul>
                        </div>
                        <div class="radio-btn">
                          <input
                            [readOnly]="
                              !this.permissionList[this.currentScreen] ||
                              this.currentQuoteStatus === 'quote-closed'
                            "
                            class="no-stepper"
                            [id]="q.id"
                            [value]="q.value || 0"
                            type="number"
                            [required]="true"
                            autocomplete="off"
                            [formControlName]="q.id"
                            placeholder="Enter"
                            [ngStyle]="{
                              'border-color':
                                (validateRiskAnswers &&
                                  isContinue &&
                                  this.subRiskQuestionForm?.controls?.[q.id]
                                    ?.errors?.['required']) ||
                                (formSubmitted &&
                                  ((this.subRiskQuestionForm?.controls?.[q.id]
                                    ?.errors?.['pattern'] &&
                                    !this.subRiskQuestionForm?.controls?.[q.id]
                                      ?.errors?.['required']) ||
                                    (this.subRiskQuestionForm?.controls?.[q.id]
                                      ?.errors?.['required'] &&
                                      isContinue)))
                                  ? '#CA3434'
                                  : null
                            }"
                          />
                        </div>
                        <div
                          *ngIf="
                            this.subRiskQuestionForm?.controls?.[q.id]
                              ?.errors?.['required'] &&
                            isContinue &&
                            (validateRiskAnswers || formSubmitted)
                          "
                          class="error-msg"
                        >
                          {{ "error.thisFieldIsRequired" | translate }}
                        </div>
                        <div
                          *ngIf="
                            this.subRiskQuestionForm?.controls?.[q.id]
                              ?.errors?.['pattern'] &&
                            !this.subRiskQuestionForm?.controls?.[q.id]
                              ?.errors?.['required'] &&
                            (validateRiskAnswers || formSubmitted)
                          "
                          class="error-msg"
                        >
                          {{ "productForm.error.numberRiskAnswer" | translate }}
                        </div>
                      </div>
                      <div
                        class="boolean-query"
                        *ngIf="q.dataType.description === 'String'"
                        [formGroupName]="getFormName(riskQuestions, q)"
                      >
                        <div>{{ q.question }}</div>
                        <div class="explanation" *ngIf="q.explanation">
                          <ul
                            [ngClass]="{
                              'column-layout': q.explanation.length > 12
                            }"
                          >
                            <li *ngFor="let point of q.explanation">
                              {{ point }}
                            </li>
                          </ul>
                        </div>
                        <div class="radio-btn">
                          <input
                            type="text"
                            [id]="q.id"
                            [value]="q.value || ''"
                            [required]="true"
                            autocomplete="off"
                            [formControlName]="q.id"
                            placeholder="Enter"
                            [disabled]="
                              !this.permissionList[this.currentScreen] ||
                              this.currentQuoteStatus === 'quote-closed'
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div
      class="continue-btn-holder"
      [ngClass]="{ 'justify-content-end': isNavigatedFromQuoteCalc }"
    >
      <boxxds-button
        *ngIf="!isNavigatedFromQuoteCalc"
        [buttonText]="'Back' | translate"
        btnType="secondary"
        [btnStyle]="{ width: '88px' }"
        type="submit"
        (handleClick)="handleBackBtn()"
        class="back-btn"
      >
      </boxxds-button>
      <div class="save-continue">
        <ng-container
          *ngIf="
            this.permissionList[this.currentScreen] &&
            this.currentQuoteStatus !== 'quote-closed'
          "
        >
          <a
            *ngIf="!showSpinner && !uiData.isNavigatedFromQuoteCalc && quoteId"
            class="decline-link"
            href="javascript:;"
            (click)="handleRiskAction(transactionRiskActions.Decline)"
          >
            {{ "productForm.link.decline" | translate }}
          </a>
          <boxxds-button
            *ngIf="!showSpinner && !uiData.isNavigatedFromQuoteCalc"
            [buttonText]="'common.saveDraft' | translate"
            btnType="secondary"
            [btnStyle]="{ width: '120px', marginRight: '12px' }"
            type="submit"
            (handleClick)="handleSaveDraft($event)"
          >
          </boxxds-button>
          <button
            *ngIf="showSpinner"
            class="btn-processing"
            tabindex="0"
            [ngStyle]="{
              border: '1px solid #9090AF',
              borderRadius: '8px',
              width: '110px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '3px 6px',
              background: '#FCF8EF',
              marginRight: '12px'
            }"
          >
            <boxxds-spinner></boxxds-spinner>
          </button>
        </ng-container>
        <boxxds-button
          *ngIf="form$ | async as formData"
          [buttonText]="'submission.continueToStep4Of7' | translate"
          btnType="secondary-solid"
          (handleClick)="handleContinue($event, formData)"
          [btnStyle]="{ width: '199px' }"
        >
        </boxxds-button>
      </div>
    </div>
    <app-submission-recalculate-confirmation-popup
      *ngIf="showRecalcConfModal && form$ | async as formData"
      [showModal]="showRecalcConfModal"
      [existingQuoteOptionData]="actionPopupDetails"
      (handleClose)="closeModalRecalculateConf()"
      (handleSubmissionRecalculate)="
        handleSubmissionRecalculate($event, formData)
      "
      (handleDiscardRecalculate)="handleDiscardRecalculate($event)"
    >
    </app-submission-recalculate-confirmation-popup>
  </ng-container>
</div>
