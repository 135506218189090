<div class="holder">
  <boxxds-breadcrumb [items]="itemsMenu"></boxxds-breadcrumb>
  <boxxds-heading
    sz="5"
    [ngStyle]="{ marginBottom: '50px' }"
    [text]="'Edit permission' | translate"
  >
  </boxxds-heading>
  <boxxds-alert
    *ngIf="showErrorAlert"
    (handleCloseEvent)="handleCloseSuccessEventExt()"
    type="error"
    headerText="error!"
    [bodyText]="msgErrorAlert"
  >
  </boxxds-alert>
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>

  <ng-container *ngIf="!showBodySpinner">
    <div class="company-container">
      <boxxds-alert
        *ngIf="isSuccess"
        (handleCloseEvent)="handleCloseSuccessEvent()"
        type="success"
        headerText="success!"
        bodyText="Saved successfully."
      >
      </boxxds-alert>

      <boxxds-alert
        *ngIf="hasError"
        (handleCloseEvent)="handleCloseSuccessEvent()"
        type="error"
        headerText="error!"
        [bodyText]="errorMessage"
      >
      </boxxds-alert>

      <div class="create-permission-title-container">
        <boxxds-body
          sz="lg"
          [text]="'Edit permission details' | translate"
          [customStyle]="{
            color: '#616162',
            textAlign: 'left',
            marginBottom: '8px',
            fontSize: '16px'
          }"
        ></boxxds-body>

        <span>*REQUIRED</span>
      </div>

      <form [formGroup]="permissionForm">
        <div class="search-container">
          <div class="add-container">
            <div class="d-flex">
              <boxxds-textinput
                [placeholder]="'Enter name' | translate"
                [form]="permissionForm"
                [control]="'permissionName'"
                [id]="'permissionName'"
                [width]="'466px'"
                [label]="('Permission name' | translate) + '*'"
                [requiredErrMsg]="requiredErrMsg"
                [invalidErrMsg]="invalidErrMsg"
                [containerStyle]="{ marginRight: '40px' }"
                [formSubmitted]="formSubmitted"
                maxLength="150"
                [readonly]="!this.permissionList[this.currentScreen]"
              >
              </boxxds-textinput>
              <boxxds-dropdown
                [label]="('Select system user type' | translate) + '*'"
                [insideSearch]="false"
                [form]="permissionForm"
                [control]="'systemUserType'"
                [options]="systemUserTypes"
                placeholder="Select"
                (currentValueChange)="
                  this.permissionList[this.currentScreen] &&
                    systemUserTypeChanged($event)
                "
                [requiredErrMsg]="requiredErrMsg"
                [dropdownContainerStyle]="{
                  width: '176px',
                  marginRight: '40px'
                }"
                [currentValue]="systemUserType"
                [formSubmitted]="formSubmitted"
                [disabled]="true"
              >
              </boxxds-dropdown>
              <boxxds-dropdown
                [control]="'screen'"
                [placeholder]="'Select screen to add' | translate"
                [form]="permissionForm"
                [control]="'screen'"
                (currentValueChange)="
                  this.permissionList[this.currentScreen] &&
                    screenChanged($event)
                "
                [options]="screens"
                [label]="
                  ('Screens associated with new permission' | translate) + '*'
                "
                [btnStyle]="{ marginTop: '30px', width: '264px' }"
                [dropdownContainerStyle]="{ width: '264px' }"
                [requiredErrMsg]="requiredErrMsg"
                [currentValue]="screen"
                [formSubmitted]="formSubmitted"
                class="employees"
                [isReadonly]="!this.permissionList[this.currentScreen]"
              >
              </boxxds-dropdown>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="float-right" *ngIf="this.permissionList[this.currentScreen]">
      <div class="btn-container">
        <boxxds-button
          *ngIf="!showSpinner"
          [buttonText]="'Edit permission' | translate"
          btnType="secondary"
          [btnStyle]="{
            border: '1px solid #FFBE3D',
            bordeRadius: '8px',
            padding: '0 30px 0 30px'
          }"
          type="button"
          (click)="onSubmit(permissionForm.value)"
        >
        </boxxds-button>
        <button
          *ngIf="showSpinner"
          class="btn-processing"
          tabindex="0"
          [ngStyle]="{
            border: '1px solid #FFBE3D',
            borderRadius: '8px',
            width: '150px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4px 8px',
            background: '#FCF8EF'
          }"
        >
          <boxxds-spinner></boxxds-spinner>
        </button>
      </div>
    </div>
    <div class="float-left">
      <app-timestamp
        *ngIf="permission.createdBy"
        label="Created by"
        value="{{ permission.createdBy }}, {{ createdDt }}"
        [containerStyle]="{
          marginLeft: '8px',
          position: 'relative',
          top: '-6px',
          textTransform: 'uppercase'
        }"
      ></app-timestamp>
      <app-timestamp
        *ngIf="permission.updatedBy"
        label="Updated last by"
        value="{{ permission.updatedBy }}, {{ updatedDt }}"
        [containerStyle]="{
          marginLeft: '8px',
          position: 'relative',
          top: '-20px',
          textTransform: 'uppercase'
        }"
      ></app-timestamp>
    </div>
  </ng-container>
</div>
