<div
  class="info-container"
  tabindex="0"
  [ngClass]="{ 'show-redirect-link': showRedirectLink }"
>
  <boxxds-body
    sz="xs"
    [text]="heading"
    [customStyle]="{ color: '#8F8F91', marginBottom: '10px' }"
  ></boxxds-body>
  <div class="count-holder">
    <p class="count">{{ count }}</p>
    <a *ngIf="showRedirectLink"
      ><img
        [src]="
          redirectIconSrc ||
          'assets/img/dashboard/info-tile/arrow-up-circle.png'
        "
        (mouseout)="
          redirectIconSrc = 'assets/img/dashboard/info-tile/arrow-up-circle.png'
        "
        (mouseover)="
          redirectIconSrc =
            'assets/img/dashboard/info-tile/hover/arrow-up-circle.png'
        "
        alt="redirect"
    /></a>
  </div>
</div>
