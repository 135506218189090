import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Pagination } from 'src/app/entities/boxx-response';
import { Insured } from 'src/app/entities/insured';
import { InsuredCompanyPostRequest } from 'src/app/entities/insured-company';
import { InsuredIndividualPostRequest } from 'src/app/entities/insured-individual';
import { Domains } from 'src/app/models/domains';
import { Breadcrumb } from 'src/app/models/breadcrumb.model';
import { Quote } from 'src/app/models/quote.model';
import { DomainsService } from 'src/app/services/domains.service';
import { IndustryClassService } from 'src/app/services/industry-class.service';
import { IndustryService } from 'src/app/services/industry.service';
import { LocationService } from 'src/app/services/location.service';
import { RegionService } from 'src/app/services/region.service';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import {
  retrievedInsuredApi,
  retrievedInsuredsByCompany,
} from 'src/app/store/create-quote/create-quote.action';
import {
  getInsuredCompanySelector,
  getInsuredIndividualSelector,
  getPaginationSelector,
  getQuoteSelector,
} from 'src/app/store/create-quote/create-quote.selector';
import {
  arrayToObjet,
  removeDuplicatedKeys,
  getErrorMessage,
} from 'src/app/utils/utils';
import { BoxxResponse } from 'src/app/entities/boxx-response';
import { SYSTEM_USER_TYPES } from 'src/app/constants/security/systemUserType';
import { SystemUserService } from 'src/app/services/system-user.service';
import { SystemUser } from 'src/app/entities/system-user';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { getUserTypes } from 'src/app/store/dashboard/dashboard.selector';

@Component({
  selector: 'app-edit-system-user',
  templateUrl: './edit-system-user.component.html',
  styleUrls: ['./edit-system-user.component.less'],
})
export class EditSystemUserComponent implements OnInit {
  itemsMenu: Breadcrumb[] = [
    {
      label: 'System user directory',
      path: '/dashboard/system-user-directory',
    },
  ];
  dropdownOptions: Array<any> = [];
  options: Array<any> = [{ key: 'select', value: 'select' }];

  systemUserTypes: any = [];

  form: FormGroup;
  form$: Observable<Quote>;
  searchKey;

  companySearchData: Observable<any>;
  companyColumnsToSort: Array<string> = ['Name', 'Website'];
  noOfEmpOptns: any;
  numberOfEmp: any;
  noOfPIIRecordOptns: any;
  noOfPIIRecord: any;
  businessSectorOptns: any;
  businessIndustryClass = [];
  businessIndustry = [];
  requiredErrMsg: string = 'This field is required';
  invalidErrMsg: string = 'This field is invalid';
  submitted: boolean = false;
  totalDataCount = 0;
  showIndividualSearchRslt: boolean = false;
  addCustomerExpanded: boolean = false;
  householdMemberOptns: any;
  householdMember: any;
  individualSearchData: Observable<any>;
  customerColumnsToSort: Array<string> = ['Name', 'Email'];
  pagination: Observable<Pagination>;

  showBoxxUser = false;
  showProducer = false;
  showInsured = false;

  currentForm = '';
  currentSystemUserType = '';
  systemUserTypeId = '';

  userName = '';
  isActive = false;
  showBodySpinner: boolean = false;
  showErrorAlert = false;
  msgErrorAlert = '';
  userID: number;
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  userTypes: Array<any> = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activedRoute: ActivatedRoute,
    private store: Store,
    private regionService: RegionService,
    private industryService: IndustryService,
    private industryClassService: IndustryClassService,
    private domainsService: DomainsService,
    private locationSercice: LocationService,
    private systemUserService: SystemUserService,
    private localStorageService: LocalStorageService,
  ) {
    const route = this.activedRoute.snapshot.params;
    this.currentForm = route['type'];
  }

  updateUserName(title: string) {
    this.userName = title;
    this.itemsMenu[1] = { label: title, path: null };
  }

  updateUserStatus(status: boolean) {
    this.isActive = status;
  }

  ngOnInit() {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
    });

    this.userID = this.localStorageService.getBoxxUserId();
    this.showBodySpinner = true;
    this.store.pipe(select(getUserTypes)).subscribe(async (data) => {
      const dropdownData = data.map((dataObj) => ({
        key: dataObj.description,
        value: dataObj.id,
        id: dataObj.id,
      }));
      this.userTypes = dropdownData;
      this.systemUserTypes = this.userTypes;
      let selectedSystemUserType = this.userTypes.find(
        (item) => item.id === Number(this.currentForm),
      )?.key;
      this.showBodySpinner = false;
      if (dropdownData.length === 0) {
        this.fetchSystemUserTypes();
      } else {
        selectedSystemUserType = selectedSystemUserType
          ? selectedSystemUserType
          : this.currentForm;
        this.currentSystemUserType = this.getCurrentOption(this.currentForm);
        this.systemUserTypeId = this.getCurrentSystemId(this.currentForm);
        this.displayForm(selectedSystemUserType);
      }
    });
  }

  fetchSystemUserTypes() {
    this.domainsService.GetByDomainCode('SYSTEMUSERTYPE').subscribe({
      next: (response) => {
        this.setSystemUserTypesResult(response);

        this.currentSystemUserType = this.getCurrentOption(this.currentForm);
        this.systemUserTypeId = this.getCurrentSystemId(this.currentForm);
        this.displayForm(this.currentForm);
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });
  }

  setSystemUserTypesResult(data: BoxxResponse<Domains>) {
    this.showBodySpinner = true;
    const tableData = data.data?.map((dataObj) => ({
      key: `${dataObj.description}`,
      value: `${dataObj.id}`,
    }));
    this.showBodySpinner = false;
    this.systemUserTypes = [...tableData];
  }

  displayForm(form) {
    switch (form) {
      case SYSTEM_USER_TYPES.BoxxUser:
        this.showBoxxUserForm();
        break;
      case SYSTEM_USER_TYPES.BoxxUsers:
        this.showBoxxUserForm();
        break;
      case SYSTEM_USER_TYPES.Insured:
        this.showInsuredForm();
        break;
      case SYSTEM_USER_TYPES.Broker:
        this.showProducerForm();
        break;
      case SYSTEM_USER_TYPES.Brokers:
        this.showProducerForm();
        break;
      case SYSTEM_USER_TYPES.Producers:
        this.showProducerForm();
        break;
      case SYSTEM_USER_TYPES.Producer:
        this.showProducerForm();
        break;
    }
  }

  showBoxxUserForm() {
    this.showBoxxUser = true;
    this.showInsured = false;
    this.showProducer = false;
  }

  showProducerForm() {
    this.showBoxxUser = false;
    this.showInsured = false;
    this.showProducer = true;
  }

  showInsuredForm() {
    this.showBoxxUser = false;
    this.showInsured = true;
    this.showProducer = false;
  }

  getCurrentOption(value) {
    const options = arrayToObjet(this.systemUserTypes, 'value');
    return options[value].key;
  }

  getCurrentSystemId(value) {
    const options = arrayToObjet(this.systemUserTypes, 'value');
    return options[value].id;
  }

  systemUserTypeChanged(e) {
    let selectedSystemUserType = this.userTypes.find(
      (item) => item.id === Number(e),
    )?.key;
    selectedSystemUserType = selectedSystemUserType
      ? selectedSystemUserType
      : e;
    this.displayForm(selectedSystemUserType);
    this.currentSystemUserType = this.getCurrentOption(e);
    this.systemUserTypeId = this.getCurrentSystemId(e);
  }

  getSearchKeyWords(keywordObj = {}) {}

  addUser(event) {}

  handleNoOfPIIRecords(event) {}

  handleCloseSuccessEventExt() {
    this.showErrorAlert = false;
  }
}
