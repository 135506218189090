import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, firstValueFrom, take } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import {
  getPolicyId,
  getQuoteSelector,
} from 'src/app/store/create-quote/create-quote.selector';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { InsuredService } from 'src/app/services/insured.service';
import { LocationService } from 'src/app/services/location.service';
import { RiskQuestionService } from 'src/app/services/risk-question.service';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BrokerageService } from 'src/app/services/brokerage.service';
import { arrayToObjet, getErrorMessage } from 'src/app/utils/utils';
import { BrokerageBranchService } from 'src/app/services/brokerage-branch.service';
import { BrokerageProducerService } from 'src/app/services/brokerage-producer.service';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { STANDALONE_SELLABLE_TRUE } from 'src/app/constants/quote-constant';
import { NewQuoteService } from 'src/app/services/new-quote.service';
import { BrokerageCommissionService } from 'src/app/services/brokerage-commissions.service';
import { BrokerageProducerCommissionService } from 'src/app/services/brokerage-producer-commission.service';
import { PolicyService } from 'src/app/services/policy.service';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { getLifecycleState } from 'src/app/store/lifecycle/lifecycle.select';
import { AlertService } from 'src/app/services/alert.service';
@Component({
  selector: 'app-product-selection',
  templateUrl: './product-selection.component.html',
  styleUrls: ['./product-selection.component.less'],
})
export class ProductSelectionComponent implements OnInit, OnDestroy {
  form$: Observable<any>;
  showSpinner: boolean = false;
  brokerageForm: FormGroup;
  brokerageOptions: any = [];
  brokerages: any;
  branchOptions: any = [];
  branches: any;
  producerOptions: any = [];
  producers: any;
  requiredErrMsg: string = 'error.thisFieldIsRequired';
  noProducerErrMsg = 'error.noProducerErrMsg';
  brokerageListErrMsg: string = 'error.brokerageListErrMsg';
  submitted: boolean = false;
  broker = '';
  branch = '';
  selectedBroker: any;
  selectedBranch: any;
  selectedProducer: any;
  showBanner = false;
  alertMessage = '';
  userID: number;
  backButtonDisabled: boolean = false;
  isNavigatedFromQuoteCalc = false;
  invalidSearch = '';
  branchTotalCount = 0;
  branchTotalPages = 0;
  producerTotalCount = 0;
  producerTotalPages = 0;
  products;
  showBodySpinner = false;
  isProductSelectedTrue: boolean = false;
  noProductErrMsg = 'error.noProductErrMsg';
  insuredTimezone;
  insuredId;
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  currentQuoteStatus: string = '';

  constructor(
    private router: Router,
    private brokerageService: BrokerageService,
    private brokerageProducerService: BrokerageProducerService,
    private brokerageBranchService: BrokerageBranchService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private insuredService: InsuredService,
    public locationService: LocationService,
    public riskQuestionService: RiskQuestionService,
    private riskRegionService: RiskRegionService,
    private localStorageService: LocalStorageService,
    private sanitizer: DomSanitizer,
    private newQuoteService: NewQuoteService,
    private brokerageCommissionService: BrokerageCommissionService,
    private brokerageProducerCommissionService: BrokerageProducerCommissionService,
    private policyService: PolicyService,
    private alertService: AlertService,
  ) {
    this.brokerageForm = this.fb.group({
      broker: ['', Validators.required],
      branch: ['', Validators.required],
      producer: ['', Validators.required],
    });
  }
  getStandaloneSellablePdts(pdts) {
    return pdts.filter((pdt) => pdt.standaloneSellable);
  }
  @HostListener('window:popstate')
  canDeactivate(): Observable<boolean> | boolean {
    const navigationEvent = this.router.getCurrentNavigation();
    const initialUrl = navigationEvent?.initialUrl.toString();
    const toUrls = ['/dashboard/quote/new', '/dashboard/quotes'];
    if (toUrls.includes(initialUrl) && this.backButtonDisabled) {
      return !this.backButtonDisabled;
    }
    return true;
  }

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
    });
    this.store.pipe(select(getLifecycleState)).subscribe((data) => {
      this.currentQuoteStatus = data.quoteStatus;
    });

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.userID = this.localStorageService.getBoxxUserId();
    this.form$ = this.store.pipe(select(getQuoteSelector));
    this.showBodySpinner = true;
    let isInsuredTypeCompany,
      insuredName,
      versionId = 1,
      insuredId,
      products,
      regionId,
      ui,
      broker = '',
      branch = '',
      policyId: number | null,
      selectedInsuredId;
    this.form$.subscribe((event) => {
      ({
        isInsuredTypeCompany,
        insuredName,
        insuredId,
        regionId,
        selectedInsuredId,
        isNavigatedFromQuoteCalc: this.isNavigatedFromQuoteCalc,
      } = event.ui);
      ({ broker, branch, policyId, products } = event);
      if (products) {
        this.products = products;
      }
    });
    this.store.pipe(select(getQuoteSelector)).subscribe((item) => {
      if (item?.policyId) {
        this.backButtonDisabled = true;
      }
    });

    this.form$.subscribe((event) => (this.insuredId = event.ui?.insuredId));

    this.insuredService.GetByInsuredId(this.insuredId).subscribe({
      next: async (data) => {
        this.insuredTimezone =
          data.data?.insuredLocation.timeZoneCode ?? 'America/New_York';
        this.store.dispatch(
          new CreateQuoteAction.UpdateInsuredAction({
            timezone:
              data.data?.insuredLocation.timeZoneCode ?? 'America/New_York',
          }),
        );
      },
    });

    const { length } = products?.filter((obj) => obj.checked === true);
    if (length === 0 || selectedInsuredId !== insuredId) {
      if (insuredId && insuredId === 0) {
        insuredId = this.newQuoteService.getInsuredId();
      }
      this.newQuoteService.clearInsuredId();
      if (insuredId && insuredId > 0) {
        this.showSpinner = true;
        if (isInsuredTypeCompany) {
          versionId = 2;
        }
        this.insuredService.Get(insuredId).subscribe(async (data) => {
          const locationId = data.data.insuredLocation.id;
          await this.locationService.Get(locationId).subscribe(async (data) => {
            regionId = data.data.region.id;
            this.store.dispatch(
              new CreateQuoteAction.UpdateInsuredAction({
                regionId: data.data.region.id,
                versionId: versionId,
              }),
            );

            await this.riskRegionService
              .getRiskRegionAndVersion(
                regionId,
                versionId,
                STANDALONE_SELLABLE_TRUE,
              )
              .subscribe(async (data) => {
                const riskProducts = await this.mapDataToRiskProducts(data);
                this.loadRiskServices(riskProducts);
              });
          });
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.alertService.clearAlerts(-1);
  }

  async mapDataToRiskProducts(data): Promise<any[]> {
    const riskProducts = await Promise.all(
      data.data?.map(async ({ risk, id, defaultUnderwriter }: any) => {
        const underwriterName = defaultUnderwriter
          ? `${defaultUnderwriter.firstName} ${defaultUnderwriter.lastName}`
          : '';

        const { hasSubProduct, subProductName, subProductRiskId } =
          await this.getSubProductData(risk);
        const { hasParentProduct, parentRiskId } =
          await this.getParentProductData(risk);

        let isChecked =
          risk.standaloneSellable === 1 && data.data?.length === 1;
        if (this.products?.length > 0) {
          const productData = this.products.find(
            (product) => product.key === risk.name,
          );
          isChecked = productData?.checked || isChecked;
        }
        return {
          key: risk.name,
          value: risk.name,
          description: risk.description,
          checked: isChecked,
          active: true,
          addons: [],
          underwriter: underwriterName,
          underwriterId: defaultUnderwriter?.id || '',
          riskId: risk.id,
          standaloneSellable: risk.standaloneSellable === 1,
          hasSubProduct,
          subProductName,
          subProductRiskId,
          hasParentProduct,
          parentRiskId,
          riskRegionId: id,
        };
      }),
    );

    return riskProducts;
  }

  async getSubProductData(risk): Promise<{
    hasSubProduct: boolean;
    subProductName: string;
    subProductRiskId: any;
  }> {
    if (risk.standaloneSellable === 1) {
      const getSubProduct = this.riskRegionService.getBySellableWithRiskId(
        risk.id,
      );
      const getSubProductResponse = await firstValueFrom(getSubProduct);
      if (getSubProductResponse?.data?.length > 0) {
        return {
          hasSubProduct: true,
          subProductName: getSubProductResponse?.data[0]?.name,
          subProductRiskId: getSubProductResponse?.data[0]?.id,
        };
      }
    }
    return { hasSubProduct: false, subProductName: '', subProductRiskId: null };
  }

  async getParentProductData(
    risk,
  ): Promise<{ hasParentProduct: boolean; parentRiskId: any }> {
    if (risk.standaloneSellable !== 1) {
      const getParentProduct = this.riskRegionService.getRiskById(risk.id);
      const getParentProductResponse = await firstValueFrom(getParentProduct);
      if (
        getParentProductResponse?.data?.id &&
        getParentProductResponse?.data?.sellableWithRiskId
      ) {
        return {
          hasParentProduct: true,
          parentRiskId: getParentProductResponse?.data?.id,
        };
      }
    }
    return { hasParentProduct: false, parentRiskId: null };
  }

  loadRiskServices(riskProducts) {
    const riskIds = riskProducts.map(({ riskId }) => riskId).join(',');
    this.riskRegionService.getRiskServicesById(riskIds).subscribe({
      next: (data) => {
        const addons = data.data?.map((item: any) => ({
          name: item.description,
          id: item.id,
          value: item.serviceName,
          riskId: item.risk.id,
        }));

        riskProducts = riskProducts.map((item) => ({
          ...item,
          addons: addons.filter((a) => a.riskId === item.riskId),
        }));

        this.store.dispatch(
          CreateQuoteAction.loadProductsSuccess({ products: riskProducts }),
        );
        this.showSpinner = false;
        this.showBodySpinner = false;
      },
      error: (error) => {
        this.showSpinner = false;
        this.showBodySpinner = false;
      },
    });
  }
  getSanitizedHtmlContent(htmlContent) {
    const htmlWithParentElement = `<div class="product-description">${htmlContent}</div>`;
    const sanitizedHtmlContent = this.sanitizer.bypassSecurityTrustHtml(
      htmlWithParentElement,
    );
    return sanitizedHtmlContent;
  }

  handleBrokerageSearch(searchKeyword) {
    if (this.isNavigatedFromQuoteCalc) {
      return;
    }
    this.invalidSearch = '';
    if (searchKeyword === '') {
      this.brokerageForm.get('broker').setValue('');
    }
    this.brokerageForm.get('branch').setValue('');
    this.brokerageForm.get('producer').setValue('');
    this.selectedBroker = '';
    this.selectedBranch = '';
    this.selectedProducer = '';
    this.branchOptions = [];
    this.producerOptions = [];
    if (searchKeyword.length >= 3) {
      this.brokerageService
        .GetBrokerageListByName(searchKeyword, 1, 10)
        .subscribe({
          next: (response) => {
            const brokerageList = response.data?.map((broker) => ({
              key: `${broker.name}`,
              value: `${broker.name}`,
              id: broker.id,
            }));
            if (brokerageList.length > 0) {
              this.invalidSearch = 'common.invalidErrorMessage';
            } else {
              this.invalidSearch = '';
              this.branchOptions = [];
              this.producerOptions = [];
              this.brokerageForm.get('broker').setValue('');
              this.brokerageForm.controls['branch'].setValue('');
              this.brokerageForm.controls['producer'].setValue('');
              this.store.dispatch(
                new CreateQuoteAction.setBrokerageBranchProducerAction(
                  this.brokerageForm.value,
                ),
              );
            }
            this.brokerageOptions = [...brokerageList];
            this.brokerages = arrayToObjet(response.data, 'id');
          },
          error: (error) => {
            this.showBanner = true;
            this.alertMessage = 'error.brokerageListErrMsg';
          },
        });
    } else {
      this.brokerageOptions = [];
    }
  }

  brokerageValueChanged(value) {
    this.broker = value;
    let selectedId = this.brokerageOptions.find((x) => x.value == value).id;
    this.selectedBroker = this.brokerages[selectedId];
    this.getBranches(value);
    this.invalidSearch = '';
    this.brokerageForm.get('broker').setValue(value ?? '');
    this.brokerageForm.controls['branch'].setValue('');
    this.brokerageForm.controls['producer'].setValue('');
    this.producerOptions = [];
    this.store.dispatch(
      new CreateQuoteAction.setBrokerageBranchProducerAction(
        this.brokerageForm.value,
      ),
    );
  }
  getBranches(page = 1, isLoadMore = false) {
    this.brokerageBranchService
      .GetBranchByBrokerageId(this.selectedBroker.id, page, 10)
      .subscribe((data) => {
        const tableData = data.data
          ?.filter((branchObj) => branchObj.active)
          ?.map((dataObj) => ({
            key: dataObj.name,
            value: dataObj.name,
          }));
        if (isLoadMore) {
          this.branchOptions = [...this.branchOptions, ...tableData];
          this.branches = {
            ...this.branches,
            ...arrayToObjet(data.data, 'name'),
          };
        } else {
          this.branchOptions = tableData;
          this.branches = arrayToObjet(data.data, 'name');
        }
        this.branchTotalCount = data.pagination.totalRecords;
        this.branchTotalPages = data.pagination.totalPages;
      });
  }
  branchValueChanged(value) {
    this.selectedBranch = this.branches[value];
    this.brokerageForm.controls['producer'].setValue('');
    this.brokerageForm.controls['branch'].setValue(value ?? '');
    this.getProducer();
    this.store.dispatch(
      new CreateQuoteAction.setBrokerageBranchProducerAction(
        this.brokerageForm.value,
      ),
    );
  }

  getProducer(page = 1, isLoadMore = false) {
    this.brokerageProducerService
      .GetByBranchId(this.selectedBranch.id, page, 10)
      .subscribe((data) => {
        const tableData = data.data
          ?.filter((producerObj) => producerObj.active)
          ?.map((dataObj) => ({
            key: dataObj.firstName + ' ' + dataObj.lastName,
            value: dataObj.firstName + ' ' + dataObj.lastName,
            id: dataObj?.id,
          }));
        const objectData = data.data.reduce((accumulator, value) => {
          return {
            ...accumulator,
            [value.firstName + ' ' + value.lastName]: value,
          };
        }, {});
        if (isLoadMore) {
          this.producerOptions = [...this.producerOptions, ...tableData];
          this.producers = { ...this.producers, ...objectData };
        } else {
          this.producerOptions = tableData;
          this.producers = objectData;
        }
        this.producerTotalCount = data.pagination.totalRecords;
        this.producerTotalPages = data.pagination.totalPages;
      });
  }
  producerValueChanged(value) {
    this.brokerageForm.controls['producer'].setValue(value ?? '');
    this.selectedProducer = this.producers[value];
    this.store.dispatch(
      new CreateQuoteAction.setBrokerageBranchProducerAction(
        this.brokerageForm.value,
      ),
    );
  }
  loadMoreBranches(page) {
    if (this.branchTotalCount > this.branchOptions?.length) {
      this.getBranches(page, true);
    }
  }
  loadMoreProducers(page) {
    if (this.producerTotalCount > this.producerOptions?.length) {
      this.getProducer(page, true);
    }
  }
  getValue(name) {
    return this.brokerageForm.get(name).value;
  }

  isSelectAllChecked(products) {
    let isChecked = true;
    isChecked = products.filter((p) => p.checked == false).length == 0;
    return isChecked;
  }

  onCheckboxChange(event: any, index) {
    this.store.dispatch(new CreateQuoteAction.UpdateProductsAction(index));
  }

  onSelectAllCheck(changeCheckBoxTo) {
    this.store.dispatch(
      new CreateQuoteAction.SelectAllProductAction(changeCheckBoxTo),
    );
  }

  handleBackBtn() {
    this.router.navigate(['/dashboard/quote/new'], {
      relativeTo: this.activatedRoute,
      skipLocationChange: true,
    });
  }

  isProductSelected(productsArr) {
    this.isProductSelectedTrue =
      productsArr.findIndex((obj) => obj.checked) !== -1;
    return this.isProductSelectedTrue;
  }

  handleAlertClose() {
    this.showBanner = false;
  }

  async hasCommissionForSelectedBroker() {
    try {
      let brokerageId = this.selectedBroker?.id,
        producerId = this.selectedProducer?.id,
        regionId;
      let selectedProducts: any[];
      let riskIds = '';
      await this.form$.pipe(take(1)).subscribe({
        next: (event) => {
          if (!brokerageId) {
            brokerageId = event?.ui?.brokerageId;
          }

          if (!producerId) {
            producerId = event?.ui?.producerId;
          }
          regionId = event.ui.regionId;
          selectedProducts = event.products.filter((obj) => obj.checked);
          riskIds = selectedProducts.map((product) => product.riskId).join(',');
        },
      });

      const getProducerCommission =
        this.brokerageProducerCommissionService.GetByRegionIdAndRiskIdsAndProducerId(
          regionId,
          riskIds,
          producerId,
        );
      const producerCommissionResponse = await firstValueFrom(
        getProducerCommission,
      );

      let producerCommissionNotExistsFor = [];
      if (producerCommissionResponse.data[0]) {
        riskIds
          ?.split(',')
          .map(Number)
          .forEach((riskId) => {
            const brokerForRiskId = producerCommissionResponse.data.filter(
              (broker) =>
                broker.active && broker?.riskRegion.risk.id === riskId,
            );
            if (brokerForRiskId.length === 0) {
              producerCommissionNotExistsFor.push(riskId);
            }
          });
      } else {
        producerCommissionNotExistsFor.push(...riskIds.split(',').map(Number));
      }
      if (producerCommissionNotExistsFor.length > 0) {
        let riskIdWithoutCommission = [];

        const getBrokerCommission =
          this.brokerageCommissionService.GetByRegionIdAndRiskIdsAndBrokerageId(
            regionId,
            riskIds,
            brokerageId,
          );
        const brokerCommissionResponse =
          await firstValueFrom(getBrokerCommission);
        let riskIdsToAddCommission = [];
        if (brokerCommissionResponse.data.length === 0) {
          riskIdsToAddCommission = riskIds.split(',').map(Number);
          riskIdWithoutCommission = riskIdsToAddCommission;
        } else {
          riskIdsToAddCommission = producerCommissionNotExistsFor;
        }
        if (brokerCommissionResponse.data?.length > 0) {
          riskIdsToAddCommission.forEach((riskId) => {
            const commissionForRiskId = brokerCommissionResponse.data.filter(
              (broker) =>
                broker.active && broker?.riskRegion.risk.id === riskId,
            );
            if (commissionForRiskId.length === 0) {
              riskIdWithoutCommission.push(riskId);
            }
          });
        }
        if (riskIdWithoutCommission?.length > 0) {
          this.alertMessage = 'quoteCalculator.error.invalidBrokerCommission';
          this.showBanner = true;
          return false;
        }
        return true;
      }
      return true;
    } catch (error) {
      if (![500].includes(error?.status)) {
        this.alertMessage = getErrorMessage(error);
        this.showBanner = true;
      }
      return false;
    }
  }

  async handleContinue(event) {
    this.handleAlertClose();
    if (this.isNavigatedFromQuoteCalc) {
      this.router.navigate(['form'], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
    } else {
      this.submitted = true;

      const isProducerEmpty =
        !this.brokerageForm.valid &&
        this.brokerageForm.value['broker'] !== '' &&
        this.brokerageForm.value['branch'] !== '' &&
        this.brokerageForm.value['producer'] == '';
      if (isProducerEmpty) {
        this.alertMessage = 'error.noProducerErrMsg';
        this.showBanner = true;
        return;
      }
      if (!this.isProductSelectedTrue) {
        this.alertMessage = 'error.noProductErrMsg';
        this.showBanner = true;
        return;
      }

      if (!this.brokerageForm.valid) {
        this.alertMessage = 'error.notValidFormErrMsg';
        this.showBanner = true;
        return;
      }
      const hasCommission = await this.hasCommissionForSelectedBroker();
      if (this.brokerageForm.valid && hasCommission) {
        this.store.dispatch(
          new CreateQuoteAction.setBrokerageBranchProducerAction(
            this.brokerageForm.value,
          ),
        );
        this.store.dispatch(
          new CreateQuoteAction.updateUiContents({
            broker: this.brokerageForm.value['broker'] ?? '',
            producer: this.brokerageForm.value['producer'] ?? '',
            branch: this.brokerageForm.value['branch'] ?? '',
          }),
        );
        if (this.selectedProducer) {
          this.store.dispatch(
            new CreateQuoteAction.UpdateInsuredAction({
              brokerageId: this.selectedBroker.id,
              branchId: this.selectedBranch.id,
              producerId: this.selectedProducer.id,
              brokerageName: this.selectedBroker.name,
            }),
          );
        }
        // save broker, branch and producer
        this.store
          .select(getPolicyId)
          .pipe(take(1))
          .subscribe(async (policyId) => {
            if (policyId && this.selectedBroker) {
              const payload = {
                brokerageBORId: this.selectedBroker.id,
                brokerageBranchBORId: this.selectedBranch.id,
                brokerageProducerBORId: this.selectedProducer.id,
              };
              try {
                const updateBrokerProducer = this.policyService.Update(
                  policyId,
                  payload,
                );
                let response = await firstValueFrom(updateBrokerProducer);
                this.router.navigate(['form'], {
                  relativeTo: this.activatedRoute,
                  skipLocationChange: true,
                });
              } catch (error) {
                if (![500].includes(error?.status)) {
                  this.alertMessage = getErrorMessage(error);
                  this.showBanner = true;
                }
              }
            } else {
              this.router.navigate(['form'], {
                relativeTo: this.activatedRoute,
                skipLocationChange: true,
              });
            }
          });
      }
    }
  }
}
