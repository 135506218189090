<div class="modal-popup-outer" *ngIf="isPopupOpen">
  <div class="modal-popup-container">
    <button
      (click)="!isLoading && handleCloseClick()"
      class="modal-popup-close-button"
      [disabled]="isLoading"
      [class.noPointerEvents]="isLoading"
    >
      <img src="assets/img/dashboard/card/close.png" />
    </button>
    <ng-content></ng-content>
  </div>
</div>
