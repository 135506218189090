import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, take } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { Pagination } from 'src/app/entities/boxx-response';
import { Insured } from 'src/app/models/insured.model';
import { InsuredService } from 'src/app/services/insured.service';
import { getInsuredSelector } from 'src/app/store/insured/insured.selector';
import * as InsuredAction from 'src/app/store/insured/insured.action';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { AlertService } from 'src/app/services/alert.service';
import {
  excelFileDownload,
  getAlertHead,
  getErrorMessage,
  removeEmptyProperties,
} from 'src/app/utils/utils';

@Component({
  selector: 'app-insured-directory',
  templateUrl: './insured-directory.component.html',
  styleUrls: ['./insured-directory.component.less'],
})
export class InsuredDirectoryWF3Component implements OnInit {
  insuredForm: FormGroup;
  filterFormGroup: FormGroup;
  constructor(
    private fb: FormBuilder,
    private insuredService: InsuredService,
    private router: Router,
    private translateService: TranslateService,
    private store: Store,
    private alertService: AlertService,
  ) {
    this.insuredForm = this.fb.group({
      searchKey: ['', [Validators.pattern(/^.{3,}$/)]],
    });
  }
  filteredOptions = [];
  filterSelectedOptions: any;
  exportFilterOptions: any;
  showFilter: boolean = true;
  showFilterPopup: boolean = false;
  hasFilterOptions: boolean = false;
  filterInputOptions;
  form$: Observable<Insured>;
  isInsuredTypeCompany: boolean = true;
  private skipNgOnDestroy = false;
  pageNumber: number = 1;
  companyColumnsToSort: Array<string> = ['Insured Name', 'Contact', 'Status'];
  showTblSpinner: boolean = false;
  tableData: {
    'Insured Name': any;
    'Insured Type'?: any;
    Address: any;
    'Contact info': any;
    Status: any;
    edit: any;
  }[] = [
    {
      'Insured Name': '',
      Address: '',
      'Contact info': '',
      Status: '',
      edit: '',
    },
  ];
  multiContentColumns = {
    'Insured Name': ['insuredName', 'locationAndIndustryClass'],
    'Contact info': ['fullName', 'email'],
  };
  pagination: Pagination = {
    currentPage: 1,
    totalPages: 1,
    totalRecords: 0,
    hasNext: false,
    hasPrevious: false,
  };
  sortBy = 'createdDt:desc';
  sortedColumn = '';
  enteredOnSearch: boolean = false;
  searchResults = [];
  showSpinner = false;
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  noResultInfo = {
    label: 'test',
    redirectionPath: '',
    showButtonRightIcon: true,
    buttonRightIconImagePath: 'assets/img/plus-circle-v2.svg',
  };
  noResultContent = '';
  columnsToHide: string[] = [];
  columnHeadsToHide: string[] = ['edit'];
  sortableColumns: string[] = [];
  iconColumns: string[] = ['edit'];
  customColumnWidth: {
    columnName: string;
    minWidth: string;
    maxWidth: string;
  }[] = [
    { columnName: 'Insured Name', minWidth: '226px', maxWidth: '226px' },
    { columnName: 'Contact info', minWidth: '276px', maxWidth: '276px' },
  ];
  categoryFilters: string[] = ['View all', 'Businesses', 'Individuals'];
  activeCategoryFilter: string = 'View all';
  searchValue: string = '';
  isRestrictedRoleSubmission: boolean = false;

  async ngOnInit(): Promise<void> {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.isRestrictedRoleSubmission = data.isRestrictedRoleSubmission;
    });
    this.store.dispatch(new CreateQuoteAction.ResetState());
    this.form$ = this.store.select(getInsuredSelector);
    this.form$.pipe(take(1)).subscribe(async (event) => {
      let navigationTab = event.ui.insuredNavigationTab;
      if (navigationTab === '') {
        this.setInsuredNavigationTab(this.activeCategoryFilter);
      } else {
        this.activeCategoryFilter = navigationTab;
      }
      await this.populateInsured({ sort: this.sortBy });
    });

    this.insuredForm.setValue({
      searchKey: this.searchValue,
    });
  }

  handleSorting(data: any): void {
    let sortColumn = this.mapSortColumns(data.column);
    this.sortBy = `${
      sortColumn ? `${sortColumn}:${data.sort},id:asc` : `createdDt:desc`
    }`;
    this.populateInsured({
      insuredName: this.insuredForm.value['searchKey'] ?? '',
      page: this.pagination.currentPage,
      sort: this.sortBy,
    });
  }

  columnClickHandle(data: any): void {}

  handleCategoryFilter(category: string): void {
    this.activeCategoryFilter = category;
    this.searchValue = '';
    this.insuredForm.get('searchKey').setValue('');
    this.filteredOptions = [];
    this.exportFilterOptions = {};
    this.filterSelectedOptions = undefined;
    if (category == 'Businesses') {
      this.exportFilterOptions.insuredType = 2;
    } else if (category == 'Individuals') {
      this.exportFilterOptions.insuredType = 1;
    }

    //remove the temporary code, once having solution for 'view all' sort
    ///// temporary code //////
    if (category === 'View all') {
      this.sortableColumns.includes('Insured Name') &&
        this.sortableColumns.splice(
          this.sortableColumns.indexOf('Insured Name'),
          1,
        );
    } else {
      !this.sortableColumns.includes('Insured Name') &&
        this.sortableColumns.push('Insured Name');
    }
    ///// temporary code //////

    this.setInsuredNavigationTab(category);
    this.populateInsured({ sort: this.sortBy });
  }

  handleEditInsured(index: number): void {
    const insuredId = this.searchResults[index].id;
    let insuredType = this.searchResults[index].insuredType;

    if (insuredType === 'Company') {
      this.router.navigate(['/dashboard/workflow3/insured/form', insuredId], {
        skipLocationChange: true,
      });
    } else {
      this.router.navigate(
        ['/dashboard/workflow3/insured/form', insuredId, 0],
        {
          skipLocationChange: true,
        },
      );
    }
  }

  handleAddNewInsured() {
    if (this.isRestrictedRoleSubmission === true) {
      this.alertService.clearAlerts(-1);
      const alertData = {
        type: 'warn',
        headerText: getAlertHead('warning'),
        bodyText: this.translateService.instant(
          'common.error.noAccessToPageErrorMsg',
        ),
      };
      this.alertService.addAlert(alertData);
      return;
    }
    this.skipNgOnDestroy = true;
    this.router.navigate(['/dashboard/workflow3/insured/form'], {
      skipLocationChange: true,
    });
  }

  setIsNavigatedFromInsuredInStore(value: boolean) {
    this.store.dispatch(
      new InsuredAction.setIsNavigatedFromInsuredIndividual({
        isNavigatedFromIndividual: value,
      }),
    );
  }

  setInsuredNavigationTab(tab: string) {
    this.store.dispatch(
      new InsuredAction.setInsuredNavigationTab({
        navigationTab: tab,
      }),
    );
  }

  async handleSearch(event) {
    const searchValue = event.target.value;
    this.filteredOptions = [];
    this.hasFilterOptions = false;
    this.filterSelectedOptions = undefined;
    /* Handle Export */
    if (
      this.exportFilterOptions === undefined ||
      this.exportFilterOptions === null
    ) {
      this.exportFilterOptions = {};
    }
    this.exportFilterOptions.insuredName = searchValue;
    /* Handle Export */
    if (
      event.key == 'Enter' &&
      searchValue?.length >= 3 &&
      searchValue?.length <= 45
    ) {
      await this.populateInsured({
        insuredName: searchValue,
        sort: this.sortBy,
      });
    } else if (
      searchValue?.length === 0 ||
      (event.key == 'Enter' && searchValue?.length < 3)
    ) {
      await this.populateInsured({ insuredName: '', sort: this.sortBy });
    }
  }

  handleTablePageChange(page = 1) {
    if (this.filterSelectedOptions) {
      if (this.pagination.currentPage > 0) {
        this.makeFilter(page, this.filterSelectedOptions);
      }
    } else {
      const searchName = this.insuredForm.value['searchKey'] ?? '';
      this.populateInsured({
        insuredName: searchName,
        page,
        sort: this.sortBy,
      });
    }
  }

  mapSortColumns(key: string) {
    let sortColumn: string;
    switch (key) {
      case 'Insured Name':
        if (this.activeCategoryFilter === 'Business') {
          sortColumn = 'companyName';
        } else {
          sortColumn = 'firstName';
        }
        break;
      case 'Contact':
        sortColumn = 'email';
        break;
      case 'Status':
        sortColumn = 'active';
        break;
      default:
        sortColumn = 'createdDt';
        break;
    }
    return sortColumn;
  }

  getUpdatedRowData(
    currentRowData: { [x: string]: any },
    newKeyValue: { key: string; value: any },
    newKeyValuePosition: number,
  ) {
    const keys = Object.keys(currentRowData);
    const values = Object.values(currentRowData);

    const updatedRowData = keys.reduce((acc, key, index) => {
      if (index === newKeyValuePosition) {
        acc[newKeyValue.key] = newKeyValue.value;
      }
      acc[key] = values[index];
      return acc;
    }, {});

    return updatedRowData;
  }

  async populateInsured({
    insuredName = '',
    page = 1,
    limit = 10,
    sort = '',
  } = {}) {
    this.showTblSpinner = true;
    let insuredType =
      this.activeCategoryFilter === 'View all'
        ? -1
        : this.activeCategoryFilter === 'Businesses'
        ? 2
        : 1;

    this.insuredService
      .GetByInsuredNameAndType(
        encodeURIComponent(insuredName),
        insuredType,
        page,
        limit,
        sort,
      )
      .subscribe({
        next: (response) => {
          this.searchValue = insuredName;
          this.searchResults = response?.data;
          this.pagination = response?.pagination;
          if (response?.data?.length > 0) {
            this.tableData = response?.data.map((insuredData) => {
              const insuredStatusTranslationKey =
                insuredData.active === true
                  ? 'common.status.active'
                  : 'common.status.inactive';
              if (insuredData.insuredType === 'Company') {
                let contactInfo = {
                  fullName: '----',
                  email: '----',
                };
                if (insuredData?.BillingAndCompanyContacts?.length > 0) {
                  // TO DO - update this when we allow users to add multiple contact details for business
                  const contactData1 = insuredData.BillingAndCompanyContacts[0];
                  let name = '----';
                  if (contactData1.firstName && contactData1.lastName) {
                    name = `${contactData1.firstName} ${contactData1.lastName}`;
                  } else if (contactData1.firstName) {
                    name = `${contactData1.firstName}`;
                  } else if (contactData1.lastName) {
                    name = `${contactData1.lastName}`;
                  }
                  contactInfo.fullName = name;
                  contactInfo.email = contactData1.email ?? '----';
                }

                let rowData: any = {
                  'Insured Name': {
                    insuredName: insuredData?.companyName ?? '',
                    locationAndIndustryClass: `${
                      insuredData?.insuredLocation?.city ?? '-----'
                    }/${insuredData?.industryClass?.name ?? '-----'}`,
                  },
                  Address: insuredData?.insuredLocation?.fullAddress ?? '',
                  'Contact info': contactInfo,
                  Status: this.translateService.instant(
                    insuredStatusTranslationKey,
                  ),
                  edit: '',
                };
                if (insuredType === -1) {
                  let keyValue = {
                    key: 'Insured Type',
                    value: 'Business',
                  };
                  rowData = this.getUpdatedRowData(rowData, keyValue, 1);
                }
                return rowData;
              } else {
                let rowData: any = {
                  'Insured Name': {
                    insuredName: `${
                      insuredData.firstName && insuredData.lastName
                        ? `${insuredData.firstName} ${insuredData.lastName}`
                        : `--`
                    }`,
                    locationAndIndustryClass: `${
                      insuredData?.insuredLocation?.city ?? '----'
                    }`,
                  },
                  Address: insuredData?.insuredLocation?.fullAddress ?? '',
                  'Contact info': {
                    fullName: `${insuredData?.firstName ?? ''} ${
                      insuredData?.lastName ?? ''
                    }`,
                    email: insuredData?.email ?? '',
                  },
                  Status: this.translateService.instant(
                    insuredStatusTranslationKey,
                  ),
                  edit: '',
                };
                if (insuredType === -1) {
                  let keyValue = {
                    key: 'Insured Type',
                    value: 'Individual',
                  };
                  rowData = this.getUpdatedRowData(rowData, keyValue, 1);
                }
                return rowData;
              }
            });
          } else {
            this.tableData =
              insuredType === -1
                ? [
                    {
                      'Insured Name': '',
                      'Insured Type': '',
                      'Contact info': '',
                      Address: '',
                      Status: '',
                      edit: '',
                    },
                  ]
                : [
                    {
                      'Insured Name': '',
                      'Contact info': '',
                      Address: '',
                      Status: '',
                      edit: '',
                    },
                  ];
          }
          this.showTblSpinner = false;
        },
        error: (error) => {
          this.showTblSpinner = false;
        },
      });
  }

  populateFilterOptions() {
    let activeTab =
      this.activeCategoryFilter === 'View all'
        ? -1
        : this.activeCategoryFilter === 'Businesses'
        ? 2
        : this.activeCategoryFilter === 'Individuals'
        ? 1
        : -1;

    if (this.filterSelectedOptions) {
      const filteredOptions = this.filterInputOptions.map((option) => {
        const { value } = option;
        const selectedValue = this.filterSelectedOptions[value];

        if (selectedValue !== undefined && selectedValue !== '') {
          return { ...option, selectedValue, isDefault: true };
        }

        return { ...option, selectedValue, isDefault: true };
      });

      this.filterInputOptions = filteredOptions;
    } else {
      this.filterInputOptions = [
        {
          label: 'Type',
          value: 'insuredType',
          type: 'dropdown',
          disabled: false,
          isDefault: true,
          selectedValue: activeTab,
          dropdownOptions: [
            { id: -1, key: 'View all', value: 'View all' },
            { id: 1, key: 'Individuals', value: 'Individuals' },
            { id: 2, key: 'Businesses', value: 'Businesses' },
          ],
          isRequired: true,
          hasValidation: true,
        },
        {
          label: 'Name',
          value: 'insuredName',
          placeholder: 'ABC Company',
          type: 'text',
          disabled: false,
          isDefault: true,
          isRequired: false,
          selectedValue: '',
          hasValidation: false,
        },
        {
          label: 'Status',
          value: 'status',
          type: 'dropdown',
          disabled: false,
          isDefault: true,
          selectedValue: '',
          dropdownOptions: [
            { id: 1, key: 'Active', value: 'Active' },
            { id: 2, key: 'Inactive', value: 'Inactive' },
          ],
          isRequired: false,
          hasValidation: false,
        },
      ];
    }
  }

  handleFilter() {
    this.populateFilterOptions();
    this.createFilterFormGroup();
    this.showFilterPopup = true;
  }

  handleCloseFilterPopup(event) {
    this.showFilterPopup = false;
  }
  handleApplyFilter(event) {
    this.filterSelectedOptions = {};
    this.insuredForm.controls['searchKey'].setValue('');
    this.searchValue = '';
    this.showFilterPopup = false;
    this.filterSelectedOptions = event;
    if (event) {
      this.makeFilter(this.pagination.currentPage, this.filterSelectedOptions);
    } else {
      this.populateInsured({ insuredName: '', sort: this.sortBy });
    }
    this.getFilteredValues();
  }
  handleFilterClear(event) {
    this.filterSelectedOptions = {};
    this.filteredOptions = [];
    this.insuredForm.controls['searchKey'].setValue('');
    this.searchValue = '';
    this.filterSelectedOptions = event;
    /* Handle Export */
    if (
      this.exportFilterOptions === undefined ||
      this.exportFilterOptions === null
    ) {
      this.exportFilterOptions = {};
    }
    this.exportFilterOptions.insuredName = '';
    /* Handle Export */
    if (event) {
      this.makeFilter(this.pagination.currentPage, this.filterSelectedOptions);
    } else {
      this.populateInsured({ insuredName: '', sort: this.sortBy });
    }
    this.getFilteredValues();
  }

  makeFilter(pageNumber, filter) {
    let activeSearch;
    this.showTblSpinner = true;
    const queryParams = {};

    if (filter.insuredName) {
      queryParams['insuredName'] = filter.insuredName;
    }
    if (filter?.status !== '') {
      if (filter.status === 1) activeSearch = 1;
      else if (filter.status === 2) activeSearch = 0;

      queryParams['active'] = activeSearch;
    }
    if (filter.insuredType && filter.insuredType !== -1) {
      queryParams['insuredType'] = filter.insuredType;
    }
    this.pagination.currentPage = pageNumber;
    this.insuredService
      .getByInsuredFilter(
        queryParams,
        this.pagination.currentPage,
        10,
        this.sortBy,
      )
      .subscribe({
        next: (response) => {
          this.searchResults = response?.data;
          this.pagination = response?.pagination;
          if (response?.data?.length > 0) {
            this.tableData = response?.data.map((insuredData) => {
              const insuredStatusTranslationKey =
                insuredData.active === true
                  ? 'common.status.active'
                  : 'common.status.inactive';
              if (insuredData.insuredType === 'Company') {
                let rowData: any = {
                  'Insured Name': {
                    insuredName: insuredData?.companyName ?? '',
                    locationAndIndustryClass: `${
                      insuredData?.insuredLocation?.city ?? '-----'
                    }/${insuredData?.industryClass?.name ?? '-----'}`,
                  },
                  Address: insuredData?.insuredLocation?.fullAddress ?? '',
                  'Contact info': {
                    fullName: `${
                      insuredData.firstName && insuredData.lastName
                        ? `${insuredData.firstName} ${insuredData.lastName}`
                        : `-----`
                    }`,
                    email: insuredData?.email ?? '',
                  },
                  Status: this.translateService.instant(
                    insuredStatusTranslationKey,
                  ),
                  edit: '',
                };
                if (filter.insuredType === -1) {
                  let keyValue = {
                    key: 'Insured Type',
                    value: 'Business',
                  };
                  rowData = this.getUpdatedRowData(rowData, keyValue, 1);
                }
                return rowData;
              } else {
                let rowData: any = {
                  'Insured Name': {
                    insuredName: `${
                      insuredData.firstName && insuredData.lastName
                        ? `${insuredData.firstName} ${insuredData.lastName}`
                        : `-----`
                    }`,
                    locationAndIndustryClass: `${
                      insuredData?.insuredLocation?.city ?? '-----'
                    }`,
                  },
                  Address: insuredData?.insuredLocation?.fullAddress ?? '',
                  'Contact info': {
                    fullName: `${insuredData?.firstName ?? ''} ${
                      insuredData?.lastName ?? ''
                    }`,
                    email: insuredData?.email ?? '',
                  },
                  Status: this.translateService.instant(
                    insuredStatusTranslationKey,
                  ),
                  edit: '',
                };
                if (filter.insuredType === -1) {
                  let keyValue = {
                    key: 'Insured Type',
                    value: 'Individual',
                  };
                  rowData = this.getUpdatedRowData(rowData, keyValue, 1);
                }
                return rowData;
              }
            });
          } else {
            this.tableData =
              filter.insuredType === -1
                ? [
                    {
                      'Insured Name': '',
                      'Insured Type': '',
                      'Contact info': '',
                      Address: '',
                      Status: '',
                      edit: '',
                    },
                  ]
                : [
                    {
                      'Insured Name': '',
                      'Contact info': '',
                      Address: '',
                      Status: '',
                      edit: '',
                    },
                  ];
          }
          this.showTblSpinner = false;
        },
        error: (error) => {
          this.showTblSpinner = false;
        },
      });
  }

  getFilteredValues() {
    let filteredOptions = [];
    let filterText = '';

    if (this.filterSelectedOptions) {
      if (this.filterSelectedOptions.insuredName) {
        filterText = this.filterSelectedOptions?.insuredName;
        filteredOptions.push({ key: 'insuredName', value: filterText });
      }
      if (
        typeof this.filterSelectedOptions.status !== 'undefined' &&
        this.filterSelectedOptions.status !== ''
      ) {
        let status =
          this.filterSelectedOptions.status === 1 ? 'Active' : 'Inactive';
        filterText = status;
        filteredOptions.push({ key: 'status', value: filterText });
      }
      if (
        this.filterSelectedOptions?.insuredType &&
        this.filterSelectedOptions?.insuredType !== -1
      ) {
        filterText = this.filterSelectedOptions.insuredType;
      }
      this.filteredOptions = filteredOptions;
    }
    if (this.filteredOptions.length > 0) {
      this.hasFilterOptions = true;
    } else {
      this.hasFilterOptions = false;
    }

    return this.filteredOptions;
  }

  handleFilterOptnRemove(keyToRemove) {
    if (this.filterSelectedOptions.hasOwnProperty(keyToRemove)) {
      delete this.filterSelectedOptions[keyToRemove];
    }

    this.makeFilter(this.pagination.currentPage, this.filterSelectedOptions);
    this.getFilteredValues();
  }

  createFilterFormGroup() {
    const formGroup = {};
    this.filterInputOptions.forEach((control) => {
      const isRequired = control.hasValidation ? Validators.required : '';
      let selectedValue = control.selectedValue ? control.selectedValue : '';
      formGroup[control.value] = [selectedValue, isRequired];
    });
    this.filterFormGroup = this.fb.group(formGroup);
  }

  handleExport() {
    this.showTblSpinner = true;
    let exportFilter = Object.assign(
      {},
      removeEmptyProperties(this.exportFilterOptions),
      removeEmptyProperties(this.filterSelectedOptions),
    );

    this.insuredService
      .InsuredExport(exportFilter, this.sortBy, this.pagination.totalRecords)
      .subscribe({
        next: (data: ArrayBuffer) => {
          excelFileDownload('ExportedData.xlsx', data);
          this.showTblSpinner = false;
        },
        error: (error) => {
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error!'),
            bodyText: getErrorMessage(error),
          };
          this.alertService.addAlert(alertData);
          this.showTblSpinner = false;
        },
      });
  }
}
