import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { Pagination } from 'src/app/entities/boxx-response';
import { Breadcrumb } from 'src/app/models/breadcrumb.model';
import { Quote } from 'src/app/models/quote.model';
import { DomainsService } from 'src/app/services/domains.service';
import { PermissionService } from 'src/app/services/permission.service';
import { arrayToObjet, getErrorMessage } from 'src/app/utils/utils';
import { BoxxResponse } from 'src/app/entities/boxx-response';
import { Domains } from 'src/app/models/domains';
import { SYSTEM_USER_TYPES_SCREENS } from 'src/app/constants/security/systemUserType';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { getUserTypes } from 'src/app/store/dashboard/dashboard.selector';

@Component({
  selector: 'app-new-permission',
  templateUrl: './new-permission.component.html',
  styleUrls: ['./new-permission.component.less'],
})
export class NewPermissionComponent implements OnInit {
  itemsMenu: Breadcrumb[] = [
    { label: 'Roles & permissions', path: '/dashboard/security/roles' },
    { label: 'List of permissions', path: '/dashboard/security/permissions' },
    { label: 'Create new permissions', path: null },
  ];

  form: FormGroup;
  form$: Observable<Quote>;
  searchKey;
  submitted: boolean = false;
  totalDataCount = 0;
  pagination: Observable<Pagination>;
  isSearchResult: boolean = false;
  screens: Array<any> = [];
  systemUserTypes: Array<any> = [];

  permisionForm = new FormGroup({
    permissionName: new FormControl('', [Validators.required]),
    systemUserType: new FormControl('', [Validators.required]),
    screen: new FormControl('', [Validators.required]),
  });

  requiredErrMsg: string = 'This field is required';
  invalidErrMsg: string = 'This field is invalid';

  isSuccess = false;
  hasError = false;
  errorMessage = '';

  formSubmitted: boolean = false;

  showSpinner: boolean = false;

  systemUserType = '';
  screen = '';
  showBodySpinner: boolean = false;
  showErrorAlert = false;
  msgErrorAlert = '';
  userID: number;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activedRoute: ActivatedRoute,
    private permissionService: PermissionService,
    private domainService: DomainsService,
    private localStorageService: LocalStorageService,
    private store: Store,
  ) {}

  dropdownValueChanged() {}

  pageChangeHandler(pageNumber) {}

  ngOnInit(): void {
    this.userID = this.localStorageService.getBoxxUserId();
    this.store.pipe(select(getUserTypes)).subscribe((data) => {
      const tableData = data.map((dataObj) => ({
        key: dataObj.description,
        value: dataObj.id,
        id: dataObj.id,
      }));
      if (tableData.length === 0) {
        this.fetchSystemUserTypes();
      }
      this.systemUserTypes = [...tableData];
    });
  }

  fetchSystemUserTypes() {
    this.showBodySpinner = true;
    this.domainService.GetByDomainCode('SYSTEMUSERTYPE').subscribe({
      next: (response) => {
        this.showBodySpinner = false;
        this.setSystemUserTypesResult(response);
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });
  }

  setSystemUserTypesResult(data: BoxxResponse<Domains>) {
    const tableData = data.data?.map((dataObj) => ({
      key: `${dataObj.description}`,
      value: `${dataObj.id}`,
    }));
    this.systemUserTypes = [...tableData];
  }

  handleAttachSubjectives(event, index) {}

  getSearchKeyWords(keywordObj = {}) {}

  addPermission(event) {}

  systemUserTypeChanged(value) {
    // reset screen fields
    this.screens = [];
    this.screen = '';
    this.permisionForm.get('screen').setValue('');

    const screenDomainCode = SYSTEM_USER_TYPES_SCREENS[`SCREEN${value}`];
    this.domainService.GetByDomainCode(screenDomainCode).subscribe({
      next: (response) => {
        const tableData = response.data.map((dataObj) => ({
          key: dataObj.description,
          value: dataObj.id,
        }));
        this.screens = tableData;
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });
    const systemUserTypes = arrayToObjet(this.systemUserTypes, 'value');
    const systemUserType = systemUserTypes[value];
    this.systemUserType = systemUserType.key;
    this.permisionForm.get('systemUserType').setValue(systemUserType.value);
  }

  screenChanged(value) {
    const screens = arrayToObjet(this.screens, 'value');
    const screen = screens[value];
    this.screen = screen.key;
  }

  resetPermisionForm() {
    this.permisionForm.reset();

    this.systemUserType = '';
    this.screen = '';
  }

  onSubmit(data) {
    this.formSubmitted = true;
    this.hasError = false;

    // check boxx user form is invalid
    if (this.permisionForm.invalid) {
      return;
    }
    this.showSpinner = true;

    this.permissionService.CreatePermission(data).subscribe(
      (response) => {
        this.showSpinner = false;
        this.isSuccess = true;
        this.formSubmitted = false;
        this.resetPermisionForm();
      },
      ({ error }) => {
        this.showSpinner = false;
        this.hasError = true;
        this.errorMessage = getErrorMessage(error);
      },
    );
  }

  handleNoOfPIIRecords(event) {}

  handleCloseSuccessEvent() {
    this.isSuccess = false;
    this.hasError = false;
  }
  handleCloseSuccessEventExt() {
    this.showErrorAlert = false;
  }
}
