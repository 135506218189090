<div class="holder">
  <boxxds-alert
    *ngIf="showErrorAlert"
    (handleCloseEvent)="handleCloseSuccessEvent()"
    type="error"
    headerText="error!"
    [bodyText]="msgErrorAlert"
  >
  </boxxds-alert>
  <div class="heading-holder">
    <boxxds-heading
      sz="5"
      [text]="'BOXX users directory' | translate"
    ></boxxds-heading>
  </div>
  <ng-container
    *ngIf="
      this.permissionList[this.currentScreen] &&
      this.currentScreen === 'Boxx User'
    "
  >
    <boxxds-button
      [buttonText]="'Add boxx user'"
      btnType="secondary"
      type="button"
      leftImage="add"
      [btnStyle]="{
        height: '32px',
        width: '176px',
        marginRight: 'auto'
      }"
      (handleClick)="navigateToAddBoxxUser($event)"
    >
    </boxxds-button>
  </ng-container>
  <div class="d-flex justify-content-between align-items-center">
    <boxxds-textinput
      [form]="form"
      [placeholder]="'Search Boxx user' | translate"
      [control]="'searchKey'"
      [id]="'searchKey'"
      [leftIcon]="'assets/img/dashboard/search/search.png'"
      maxLength="45"
      [containerStyle]="{ marginTop: '24px', marginBottom: '12px' }"
      (handleKeyUp)="searchBoxxUser($event)"
    >
    </boxxds-textinput>
  </div>

  <boxxds-table
    [data]="data"
    [totalCount]="totalDataCount"
    (pageChanged)="handlePageChange($event)"
    class="table"
    (sortUpClicked)="handleAscSort($event)"
    (sortDownClicked)="handleDescSort($event)"
    id="user-table"
    addCopyBtnToField="Email"
    [addSortTo]="addSortTo"
    [styleInactive]="true"
    [showLinks]="true"
    [linkDetails]="[{ name: 'View' }]"
    [showSpinner]="showTblSpinner"
    [tableStyle]="{ marginBottom: isSearchResult ? '58px' : '27.5px' }"
    [activePageNumber]="pageNumber"
  >
  </boxxds-table>
</div>
