import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, retry, catchError } from 'rxjs';
import { BoxxResponse } from '../entities/boxx-response';
import { BaseService } from './base-services';

@Injectable({
  providedIn: 'root',
})
export class DocumentTemplateService extends BaseService<any> {
  constructor(http: HttpClient) {
    const baseurl =
      environment.msPolicyBaseUrl + environment.generateDocumentTemplateUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  generateStageDocument(
    id: Number,
    stageId: Number,
    policyRiskTrxId?: Number,
  ): Observable<BoxxResponse<any>> {
    let url = `${this.baseurl}${stageId}/${id}`;
    if (!!policyRiskTrxId) {
      url = url + `?policyRiskTrxId=${policyRiskTrxId}`;
    }
    return this.http
      .put<BoxxResponse<any>>(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  getDocumentTemplateList(
    policyRiskId: Number,
    stageId?: Number,
    policyRiskTrxId?: Number,
  ): Observable<BoxxResponse<any>> {
    let url = `${this.baseurl}templates/${policyRiskId}`;
    if (stageId !== undefined && stageId !== null) {
      url += `?stageId=${stageId}`;
      if (!!policyRiskTrxId) {
        url = url + `&policyRiskTrxId=${policyRiskTrxId}`;
      }
    } else {
      if (!!policyRiskTrxId) {
        url = url + `?policyRiskTrxId=${policyRiskTrxId}`;
      }
    }
    return this.http
      .get<BoxxResponse<any>>(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
