import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, retry, catchError } from 'rxjs';
import { BoxxResponse } from '../entities/boxx-response';
import { BaseService } from './base-services';

@Injectable({
  providedIn: 'root',
})
export class PolicyRiskDocService extends BaseService<any> {
  constructor(http: HttpClient) {
    const baseurl =
      environment.msPolicyBaseUrl + environment.policyRiskDocCoreUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getPolicyRiskDocCore(
    policyRiskId: number,
    processingStatus?: number,
    documentTypeId?: number,
    page: number = 1,
    limit = 100,
    documentName?: string,
    name?: string,
  ): Observable<any> {
    const url = `${this.baseurl}?policyRiskId=${policyRiskId}${
      documentTypeId ? `&documentType=${documentTypeId}` : ``
    }
    ${processingStatus ? `&processingStatus=${processingStatus}` : ``}        
    ${documentName ? `&documentName=${documentName}` : ``}
    ${
      name ? `&name=${encodeURIComponent(name)}` : ``
    }&page=${page}&limit=${limit}&sort=createdDt%3Adesc`;
    return this.http
      .get<BoxxResponse<any>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  getPolicyRiskGeneratedDocCore(
    policyPeriodId: number,
    stageId: number,
    policyRiskTrxId?: number,
  ): Observable<any> {
    let url = `${this.baseurl}status/${stageId}/${policyPeriodId}`;
    if (policyRiskTrxId) {
      url = url + `?policyRiskTrxId=${policyRiskTrxId}`;
    }
    return this.http
      .get<BoxxResponse<any>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  createPolicyRiskDoc(data): Observable<BoxxResponse<any>> {
    let url = `${this.baseurl}`;
    return this.http.post<BoxxResponse<any>>(
      url,
      JSON.stringify(data),
      this.httpOptions,
    );
  }
  updatePolicyRiskDoc(id, data): Observable<BoxxResponse<any>> {
    let url = `${this.baseurl}${id}`;
    return this.http.put<BoxxResponse<any>>(
      url,
      JSON.stringify(data),
      this.httpOptions,
    );
  }

  deletePolicyRiskDocCore(id: number, force: number): Observable<any> {
    const url = `${this.baseurl}${id}?force=${force}`;
    return this.http
      .delete<BoxxResponse<any>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  restorePolicyRiskDocCore(id: number): Observable<any> {
    const url = `${this.baseurl}restore/${id}`;
    return this.http
      .put<BoxxResponse<any>>(url, null, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
