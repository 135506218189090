<div class="custom-modal" *ngIf="showModal">
  <div class="modal-content">
    <ng-container *ngIf="showModalSpinner">
      <div class="loader-wrapper">
        <div class="modal-body-spinner">
          <boxxds-spinner
            [containerStyle]="{
              width: '112px',
              height: '112px',
              '--b': '15px'
            }"
          ></boxxds-spinner>
        </div>
      </div>
    </ng-container>
    <div *ngIf="!showModalSpinner">
      <div class="close-btn">
        <button (click)="handleCloseClk()">
          <img src="assets/img/dashboard/card/close.png" />{{
            "common.close" | translate
          }}
        </button>
      </div>

      <div class="header">
        <boxxds-body
          [text]="
            ('policy.policySlideOut.policyIdTxt' | translate) +
            ': ' +
            policyNumber
          "
          sz="xl"
          [customStyle]="{
            color: '#333334',
            height: '32px',
            marginRight: '40px'
          }"
        >
        </boxxds-body>
        <boxxds-status-badge
          [badgeStyle]="{ textTransform: 'capitalize' }"
          [status]="
            transactionRiskLevelAction === 'policy change' ||
            transactionRiskLevelAction === 'endorse'
              ? transactionRiskLevelAction
              : status
          "
          [statusType]="
            transactionRiskLevelAction === 'policy change' ||
            transactionRiskLevelAction === 'endorse'
              ? getStatusType(transactionRiskLevelAction)
              : getStatusType(status || '')
          "
        ></boxxds-status-badge>
      </div>

      <div class="sub-nav">
        <ul>
          <li *ngFor="let nav of subNavData; let i = index">
            <button
              [ngClass]="{ active: nav.active }"
              (click)="handleNav($event, i)"
            >
              {{ nav.name | translate }}
            </button>
          </li>
        </ul>
      </div>
      <ng-container *ngIf="showTabLoader">
        <div class="loader-wrapper">
          <div class="modal-body-spinner">
            <boxxds-spinner
              [containerStyle]="{
                width: '112px',
                height: '112px',
                '--b': '15px'
              }"
            ></boxxds-spinner>
          </div>
        </div>
      </ng-container>
      <div *ngIf="!showTabLoader">
        <ng-container *ngIf="isActive(0)">
          <form [formGroup]="form">
            <boxxds-body
              [text]="
                status + ' ' + ('policy.policySlideOut.details' | translate)
              "
              sz="md"
              [customStyle]="{
                color: '#BDBDBF',
                height: '24px',
                marginBottom: '8px'
              }"
            >
            </boxxds-body>
            <div class="endorsement-form">
              <div class="fields-holder">
                <div class="row-1 d-flex">
                  <boxxds-datepicker
                    [placeholder]="shortDateFormat"
                    [dateFormat]="shortDateFormat"
                    [label]="
                      ('policy.policySlideOut.transactionEffectiveDate'
                        | translate) + '*'
                    "
                    [containerStyle]="{
                      maxWidth: '186px',
                      marginRight: '40px'
                    }"
                    [isReadOnly]="
                      isFieldsDisabled() ||
                      !this.permissionList[this.currentScreen]
                    "
                    [class.noPointerEvents]="
                      isFieldsDisabled() ||
                      !this.permissionList[this.currentScreen]
                    "
                    [bsValue]="form.value['effectiveDate']"
                    (bsValueChange)="
                      this.permissionList[this.currentScreen] &&
                        handleTransactionEffDate($event)
                    "
                  >
                  </boxxds-datepicker>
                  <div class="item">
                    <boxxds-body
                      [text]="'policy.policySlideOut.issuanceDate' | translate"
                      sz="sm"
                      [customStyle]="{
                        color: '#56565C',
                        height: '20px',
                        marginBottom: '4px'
                      }"
                    >
                    </boxxds-body>

                    <boxxds-body
                      [text]="
                        form.controls['issuanceDate'].value
                          ? form.controls['issuanceDate'].value
                          : ('policy.policySlideOut.notApplicable' | translate)
                      "
                      sz="sm"
                      [customStyle]="{ color: '#8F8F91', height: '20px' }"
                    >
                    </boxxds-body>
                  </div>
                  <div
                    class="item"
                    *ngIf="
                      isFieldsDisabled() && form.controls['invoiceNumber'].value
                    "
                  >
                    <boxxds-body
                      [text]="
                        'policy.policySlideOut.invoiceNumberTxt' | translate
                      "
                      sz="sm"
                      [customStyle]="{
                        color: '#56565C',
                        height: '20px',
                        marginBottom: '4px'
                      }"
                    >
                    </boxxds-body>
                    <boxxds-body
                      [text]="
                        form.controls['invoiceNumber'].value &&
                        this.form.controls['invoiceNumber'].value !== -1
                          ? form.controls['invoiceNumber'].value
                          : ('policy.policySlideOut.notApplicable' | translate)
                      "
                      sz="sm"
                      [customStyle]="{ color: '#8F8F91', height: '20px' }"
                    >
                    </boxxds-body>
                  </div>
                  <div class="item">
                    <boxxds-body
                      [text]="'policy.policySlideOut.reason' | translate"
                      sz="sm"
                      [customStyle]="{
                        color: '#56565C',
                        height: '20px',
                        marginBottom: '4px'
                      }"
                    >
                    </boxxds-body>

                    <boxxds-body
                      *ngIf="status.toLowerCase() !== 'referral'"
                      sz="sm"
                      [text]="riskTrxReasons"
                      [customStyle]="{ color: '#595D63' }"
                    >
                    </boxxds-body>
                    <boxxds-body
                      *ngIf="1 > riskTrxReasons?.length || !riskTrxReasons"
                      [text]="'policy.policySlideOut.noReason' | translate"
                      sz="sm"
                      [customStyle]="{ color: '#8F8F91', height: '20px' }"
                    >
                    </boxxds-body>
                    <div
                      class="modal-holder"
                      (clickOutside)="closePopover()"
                      *ngIf="status.toLowerCase() === 'referral'"
                    >
                      <button
                        *ngIf="riskTrxReasons?.length > 0"
                        type="button"
                        class="view-reasons-link"
                        placement="bottom"
                        (click)="openPopover()"
                      >
                        {{ "policy.policySlideOut.referralReason" | translate }}
                      </button>
                      <div *ngIf="isPopoverOpen" class="popover-content">
                        <div class="body-spinner" *ngIf="!reasonsLoaded">
                          <boxxds-spinner
                            [containerStyle]="{
                              width: '30px',
                              height: '30px',
                              '--b': '4px',
                              marginTop: '4px'
                            }"
                          ></boxxds-spinner>
                        </div>
                        <div
                          class="d-flex align-items-start justify-content-between"
                          *ngIf="reasonsLoaded"
                        >
                          <div class="reasons">
                            <ul>
                              <li
                                *ngFor="
                                  let reason of riskTrxReasons;
                                  let i = index
                                "
                              >
                                {{ i + 1 }}. {{ reason }}
                              </li>
                            </ul>
                          </div>
                          <div class="close-btn">
                            <button (click)="closePopover()" aria-label="Close">
                              <img src="assets/img/dashboard/card/close.png" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row-2 d-flex">
                  <boxxds-dropdown
                    [form]="form"
                    [control]="'currency'"
                    [placeholder]="'common.select' | translate"
                    [label]="('common.currency' | translate) + '*'"
                    [options]="currencyOptions"
                    (currentValueChange)="currencyValueChanged($event)"
                    [currentValue]="form.controls['currency'].value"
                    [isReadonly]="true"
                    [dropdownContainerStyle]="{
                      width: '186px',
                      marginRight: '40px'
                    }"
                  >
                  </boxxds-dropdown>

                  <div class="limit">
                    <boxxds-dropdown
                      *ngIf="!showLimitCustomField()"
                      [form]="form"
                      [control]="'limit'"
                      [placeholder]="'common.select' | translate"
                      [label]="('common.limit' | translate) + '*'"
                      [options]="limitOptions"
                      (currentValueChange)="
                        this.permissionList[this.currentScreen] &&
                          handleLimitChange($event)
                      "
                      [invalidErrMsg]="getLimitErrMsg()"
                      [currentValue]="
                        form.controls['limit'].value
                          | currencyFormat: currency : 'symbol-narrow' : '1.0-0'
                      "
                      [isReadonly]="
                        isFieldsDisabled() ||
                        !this.permissionList[this.currentScreen]
                      "
                      [class.noPointerEvents]="
                        isFieldsDisabled() ||
                        !this.permissionList[this.currentScreen]
                      "
                      [dropdownContainerStyle]="{
                        width: '186px',
                        marginRight: '40px'
                      }"
                    >
                    </boxxds-dropdown>

                    <input
                      *ngIf="isLimitDisabledOnAddSubProduct(riskId)"
                      [value]="
                        getDeductibleValue()
                          ? (getDeductibleValue()
                            | currencyFormat: currency : 'symbol-narrow')
                          : ''
                      "
                      [attr.disabled]="true"
                      class="disabled-field"
                    />

                    <div class="custom-value" *ngIf="showLimitCustomField()">
                      <label class="text-input-label">
                        {{ ("common.limit" | translate) + "*" }}
                      </label>
                      <input
                        type="text"
                        mask="separator.0"
                        thousandSeparator=","
                        prefix="$"
                        maxlength="14"
                        (blur)="handleLimitCustomValue($event)"
                        [autofocus]="true"
                        width="186px"
                      />
                    </div>
                  </div>

                  <boxxds-textinput
                    [form]="form"
                    [control]="'aggregatedLimit'"
                    [placeholder]="'common.enterLimit' | translate"
                    [label]="('common.aggregatedLimit' | translate) + '*'"
                    [id]="'aggregate-limit'"
                    [readonly]="
                      isFieldsDisabled() ||
                      !this.permissionList[this.currentScreen]
                    "
                    [class.noPointerEvents]="
                      isFieldsDisabled() ||
                      !this.permissionList[this.currentScreen]
                    "
                    [containerStyle]="{ marginRight: '40px' }"
                    width="186px"
                    mask="separator.2"
                    maxLength="15"
                    thousandSeparator=","
                    prefix="$"
                  >
                  </boxxds-textinput>

                  <div class="deductables">
                    <boxxds-dropdown
                      *ngIf="!showDeductibleCustomField()"
                      [form]="form"
                      [control]="'deductible'"
                      [placeholder]="'common.select' | translate"
                      [label]="('common.deductible' | translate) + '*'"
                      [options]="deductibleOptions"
                      [invalidErrMsg]="getDeductibleErrMsg()"
                      (currentValueChange)="
                        this.permissionList[this.currentScreen] &&
                          handleDeductableChange($event)
                      "
                      [currentValue]="
                        form.controls['deductible'].value
                          | currencyFormat: currency : 'symbol-narrow' : '1.0-0'
                      "
                      [isReadonly]="
                        isFieldsDisabled() ||
                        !this.permissionList[this.currentScreen]
                      "
                      [class.noPointerEvents]="
                        isFieldsDisabled() ||
                        !this.permissionList[this.currentScreen]
                      "
                      [dropdownContainerStyle]="{ width: '186px' }"
                    >
                    </boxxds-dropdown>

                    <div
                      class="custom-value"
                      *ngIf="showDeductibleCustomField()"
                    >
                      <label class="text-input-label"
                        >{{ ("common.deductible" | translate) + "*" }}
                      </label>

                      <input
                        type="text"
                        mask="separator.0"
                        thousandSeparator=","
                        prefix="$"
                        maxlength="14"
                        (blur)="handleDeductableCustomValue($event)"
                        [autofocus]="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="d-flex row-3">
                  <div class="stepper-input" *ngIf="brokerCommissionPerc >= 0">
                    <boxxds-stepper-input
                      [inputStyle]="
                        !(
                          !isUnderwriter ||
                          isFieldsDisabled(status) ||
                          !this.permissionList[this.currentScreen] ||
                          isBrokerCommissionDisabled
                        ) && { background: 'transparent' }
                      "
                      [containerStyle]="{ width: '185px', marginRight: '40px' }"
                      [lblStyle]="{ display: 'flex' }"
                      [max]="brokerageCommissionMax"
                      [min]="brokerageCommissionMin"
                      (handleChange)="
                        this.permissionList[this.currentScreen] &&
                          handleBrokerCommision($event)
                      "
                      [label]="('common.brokerCommission' | translate) + '*'"
                      [form]="form"
                      [control]="'brokerCommissionPerc'"
                      [class.noPointerEvents]="
                        isFieldsDisabled() ||
                        !this.permissionList[this.currentScreen]
                      "
                      [isDisabled]="
                        !isUnderwriter ||
                        isFieldsDisabled() ||
                        !this.permissionList[this.currentScreen] ||
                        isBrokerCommissionDisabled
                      "
                      [value]="brokerCommissionPerc"
                    ></boxxds-stepper-input>
                    <p>{{ brokerageCommissionText }}</p>
                  </div>
                </div>
                <div class="d-flex row-4 align-items-center">
                  <div class="note-section">
                    <div
                      class="notes"
                      *ngIf="
                        !isFieldsEditable() &&
                        this.permissionList[this.currentScreen]
                      "
                    >
                      <boxxds-textarea
                        [label]="'common.notes' | translate"
                        [isDisabled]="isFieldsEditable()"
                        [placeholder]="'common.typeHere' | translate"
                        [form]="form"
                        control="note"
                        [lblStyle]="{
                          color: '#8F8F91',
                          height: '20px',
                          marginBottom: '4px',
                          textTransform: 'uppercase',
                          width: '556px'
                        }"
                      ></boxxds-textarea>
                    </div>
                    <div class="d-flex">
                      <boxxds-body
                        [text]="('common.currentTag' | translate) + ':'"
                        sz="sm"
                        [customStyle]="{
                          color: '#8F8F91',
                          height: '20px',
                          marginRight: '10px'
                        }"
                      >
                      </boxxds-body>
                      <div class="tag">
                        <boxxds-body
                          [text]="form.controls['tag'].value"
                          sz="sm"
                          [customStyle]="{
                            color: '#616162',
                            height: '20px',
                            marginRight: '4px'
                          }"
                        >
                        </boxxds-body>
                      </div>
                    </div>
                  </div>
                  <div class="edited-premium">
                    <boxxds-textinput
                      [form]="form"
                      [control]="'premium'"
                      [placeholder]="'common.enterAmount' | translate"
                      [readonly]="
                        isFieldsDisabled() ||
                        !this.permissionList[this.currentScreen]
                      "
                      [class.noPointerEvents]="
                        isFieldsDisabled() ||
                        !this.permissionList[this.currentScreen]
                      "
                      [label]="
                        ('policy.policySlideOut.editedPremium' | translate) +
                        '*'
                      "
                      [id]="'premium-amt'"
                      [allowNegativeNumbers]="true"
                      mask="separator.0"
                      thousandSeparator=","
                      prefix="$"
                      maxlength="14"
                      [containerStyle]="{
                        marginRight: '40px',
                        marginBottom: '20px'
                      }"
                      (handleKeyUp)="
                        this.permissionList[this.currentScreen] &&
                          handleChangePremium($event)
                      "
                      width="186px"
                    >
                    </boxxds-textinput>
                    <boxxds-textinput
                      [form]="form"
                      [control]="'taxes'"
                      [placeholder]="
                        '$' +
                        ('policy.policySlideOut.zeroPlaceholder' | translate)
                      "
                      [label]="
                        'policy.policySlideOut.taxesPremiumAmount' | translate
                      "
                      [id]="'slideout-tax-premium'"
                      [containerStyle]="{ marginRight: '40px' }"
                      [readonly]="true"
                      width="186px"
                    >
                    </boxxds-textinput>
                  </div>
                </div>
              </div>
              <div
                class="btn-row"
                *ngIf="this.permissionList[this.currentScreen]"
              >
                <boxxds-button
                  *ngIf="!isSaveProcessing"
                  btnType="primary-outlined"
                  [buttonText]="'common.save' | translate"
                  [type]="'submitted'"
                  [btnStyle]="{ width: '88px', marginLeft: 'auto' }"
                  [isDisabled]="!isSaveButtonValid"
                  (handleClick)="saveHandler()"
                >
                </boxxds-button>
                <button
                  *ngIf="isSaveProcessing"
                  class="btn-processing"
                  tabindex="0"
                  [ngStyle]="{
                    border: '1px solid #FFBE3D',
                    borderRadius: '8px',
                    width: '88px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '4px 8px',
                    background: '#FCF8EF',
                    marginLeft: 'auto'
                  }"
                >
                  <boxxds-spinner></boxxds-spinner>
                </button>
              </div>
            </div>
          </form>
        </ng-container>
        <ng-container *ngIf="isActive(1)">
          <app-slideout-document
            [policyRiskId]="policyRiskId"
            [permissionList]="permissionList"
            [currentScreen]="currentScreen"
            [showDocPreview]="showDocPreview"
            [showImagePreview]="showImagePreview"
            [policyPeriodId]="policyPeriodId"
            [policyStatus]="details.status"
            [closeModalClicked]="closeModalClicked"
            [isProductActive]="details?.active ?? false"
            (handleShowGenerateDocMsg)="showGenerateDocMsg()"
          ></app-slideout-document>
        </ng-container>
        <ng-container *ngIf="isActive(2)">
          <div class="history-tab">
            <boxxds-body
              [text]="'common.accountHistory' | translate"
              sz="lg"
              [customStyle]="{ color: '#333334', marginBottom: '4px' }"
            >
            </boxxds-body>
            <boxxds-body
              [text]="
                ('policy.policySlideOut.pastPolicyUnderBusinessName'
                  | translate) + ':'
              "
              sz="sm"
              [customStyle]="{ color: '#616162' }"
            >
            </boxxds-body>
            <div class="past-policies">
              <ul *ngIf="pastPolicies.length > 0">
                <li *ngFor="let policy of pastPolicies">
                  <div class="d-flex">
                    <boxxds-body
                      [text]="policy.policyNumber"
                      sz="sm"
                      [customStyle]="{
                        color: '#8F8F91',
                        textDecoration: 'underline'
                      }"
                    >
                    </boxxds-body>
                    <boxxds-body
                      [text]="policy.period"
                      sz="sm"
                      [customStyle]="{ color: '#8F8F91', marginLeft: '20px' }"
                    >
                    </boxxds-body>
                  </div>
                </li>
              </ul>
              <div class="no-records-holder" *ngIf="pastPolicies.length === 0">
                <div class="no-records-ellipse">
                  <img src="assets/img/no-records.png" alt="" />
                  <boxxds-body
                    [text]="'common.noRecords' | translate"
                    sz="md"
                    [customStyle]="{ color: '#616162' }"
                  >
                  </boxxds-body>
                </div>
              </div>
            </div>
            <boxxds-body
              [text]="'policy.policySlideOut.policyActivityLog' | translate"
              sz="lg"
              [customStyle]="{ color: '#333334', marginBottom: '12px' }"
            >
            </boxxds-body>
            <div class="audit-details" *ngIf="auditDetails">
              <div
                class="d-flex"
                *ngFor="
                  let data of auditDetails
                    | paginate
                      : {
                          id: 'activitylog-pagination',
                          itemsPerPage: itemsPerPage,
                          currentPage: currentPage,
                          totalItems: activityData?.pagination?.totalRecords
                        };
                  let i = index
                "
              >
                <div class="d-flex flex-column mr-2 timeline">
                  <div class="circle"></div>
                  <div
                    class="dashed-line"
                    *ngIf="i !== auditDetails.length - 1"
                  ></div>
                </div>
                <div class="activity">
                  <boxxds-body
                    [text]="data.date"
                    sz="xxs"
                    [customStyle]="{ color: '8F8F91', marginBottom: '4px' }"
                  >
                  </boxxds-body>
                  <boxxds-body
                    [text]="
                      getConnectionString(
                        data.tableDomain ? data.tableDomain : data.table,
                        data.type,
                        data.user
                      )
                    "
                    sz="sm"
                    [customStyle]="{ color: '8F8F91', marginBottom: '4px' }"
                  >
                  </boxxds-body>
                  <div
                    class="change-holder"
                    *ngIf="data.type.toLowerCase() === 'update'"
                  >
                    <div class="prev-value">{{ data?.changeFrom }}</div>
                    <img src="assets/img/arrow-left.png" alt="to" />
                    <div class="new-value">{{ data?.changeTo }}</div>
                  </div>
                </div>
              </div>

              <div class="audit-footer">
                <div class="total-count">
                  {{ "policy.policySlideOut.totalRecordsFound" | translate }}:
                  <span>{{ activityData?.pagination?.totalRecords }}</span>
                </div>
                <div
                  class="pagination-container"
                  *ngIf="this.activityData?.pagination?.totalPages > 1"
                >
                  <pagination-template
                    id="activitylog-pagination"
                    #p="paginationApi"
                    (pageChange)="populateActivityLog($event)"
                  >
                    <ul class="tbl-pagination">
                      <li class="tbl-pagination-prev tbl-page-item">
                        <a
                          (keyup.enter)="p.previous()"
                          (click)="p.previous()"
                          [class.disabled]="p.isFirstPage()"
                          class="tbl-page-link"
                          tabindex="0"
                          aria-label="Previous"
                          >{{ "common.previous" | translate }}</a
                        >
                      </li>
                      <li
                        *ngFor="let page of p.pages"
                        [class.active]="p.getCurrent() === page.value"
                        class="tbl-page-item"
                      >
                        <a
                          (keyup.enter)="p.setCurrent(page.value)"
                          (click)="p.setCurrent(page.value)"
                          class="tbl-page-link"
                          tabindex="0"
                        >
                          {{ page.label }}
                        </a>
                      </li>
                      <li
                        class="pagination-next tbl-page-item"
                        [class.disabled]="p.isLastPage()"
                      >
                        <a
                          (keyup.enter)="p.next()"
                          *ngIf="!p.isLastPage()"
                          (click)="p.next()"
                          class="tbl-page-link"
                          tabindex="0"
                          >{{ "common.next" | translate }}</a
                        >
                      </li>
                    </ul>
                  </pagination-template>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="isActive(3)">
          <div class="max-width-80 d-flex gap-3 justify-content-between">
            <div class="notes-holder">
              <app-notes
                (FormSubmit)="updateNotes($event)"
                [form]="form"
                [isPolicySlideOut]="true"
                textareaControl="inputNotes"
                [details]="details"
                [notesContainerStyle]="{ maxHeight: '288px' }"
                [actionPopupStyle]="{ width: '155px', left: '-148px' }"
              ></app-notes>
            </div>
            <div class="filter">
              <boxxds-body
                [text]="
                  ('policy.policySlideOut.filterNotesBy' | translate) + ':'
                "
                sz="xxs"
                [customStyle]="{ color: '#333334', textTransform: 'uppercase' }"
              >
              </boxxds-body>
              <boxxds-textinput
                [form]="notesSearchForm"
                [placeholder]="'common.search' | translate"
                [label]="'common.keyWords' | translate"
                [id]="'key-word'"
                [containerStyle]="{
                  marginRight: '40px',
                  marginBottom: '12px',
                  marginTop: '20px'
                }"
                width="261px"
                (handleKeyUp)="handleKeywordFilter($event)"
                (handleBlur)="handleKeywordFilter($event)"
                [control]="'noteKeyWordSearch'"
                [invalidErrMsg]="minLengthErrMsg | translate"
                [formSubmitted]="true"
              >
              </boxxds-textinput>

              <boxxds-datepicker
                [placeholder]="shortDateFormat"
                [dateFormat]="shortDateFormat"
                [label]="'notes.noteSearchStartDateLabel' | translate"
                [containerStyle]="{ maxWidth: '198px', marginBottom: '12px' }"
                [bsValue]="
                  form?.controls['notesSearchForm']?.value['noteFromDateSearch']
                "
                (bsValueChange)="
                  handleStartDateChange(
                    $event,
                    form?.controls['notesSearchForm']?.value['noteToDateSearch']
                  )
                "
                [showErr]="!isStartDateValid"
                [errMsg]="startDateErrMsg | translate"
              >
              </boxxds-datepicker>
              <boxxds-datepicker
                [placeholder]="shortDateFormat"
                [dateFormat]="shortDateFormat"
                [label]="'notes.noteSearchEndDateLabel' | translate"
                [containerStyle]="{ maxWidth: '198px', marginBottom: '12px' }"
                [bsValue]="
                  form?.controls['notesSearchForm']?.value['noteToDateSearch']
                "
                (bsValueChange)="
                  handleEndDateChange(
                    $event,
                    form?.controls['notesSearchForm']?.value[
                      'noteFromDateSearch'
                    ]
                  )
                "
                [showErr]="!isEndDateValid"
                [errMsg]="endDateErrMsg | translate"
              >
              </boxxds-datepicker>
              <boxxds-dropdown
                [form]="notesSearchForm"
                [control]="'noteWriterSearch'"
                [placeholder]="'common.select' | translate"
                [label]="'common.writer' | translate"
                [options]="options"
                (currentValueChange)="writerFilterHandler($event)"
                [dropdownContainerStyle]="{
                  maxWidth: '186px',
                  marginBottom: '12px'
                }"
                [currentValue]="
                  form?.controls['notesSearchForm']?.value['noteWriterSearch']
                "
              >
              </boxxds-dropdown>
              <ng-container>
                <div class="btn-container">
                  <button
                    type="button"
                    class="clear-btn"
                    (click)="resetFilterForm()"
                  >
                    {{ "common.clearAllFilters" | translate }}
                  </button>
                  <boxxds-button
                    [isDisabled]="!isNoteFormValid"
                    [buttonText]="'common.search' | translate"
                    btnType="secondary"
                    [ngStyle]="{ maxWidth: '100px' }"
                    type="submit"
                    (handleClick)="isNoteFormValid && handleNotesFilter($event)"
                  >
                  </boxxds-button>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
