import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TransactionRiskActions } from 'src/app/dashboard/constants/lifecycle-actions';
import { addSpaceBeforeCapitalLetter } from 'src/app/dashboard/utils/lifecycle-utils';
import { TranslateService } from '@ngx-translate/core';
import { PolicyRiskDocProcessService } from 'src/app/services/policy-risk-doc-process.service';
import { getAlertHead, getErrorMessage } from 'src/app/utils/utils';
import { getLifecycleSlideout } from 'src/app/store/lifecycle/lifecycle.select';
import { DocumentTemplateService } from 'src/app/services/doc-template.service';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-regenerate-policy-document-popup',
  templateUrl: './regenerate-policy-document-popup.component.html',
  styleUrls: ['./regenerate-policy-document-popup.component.less'],
})
export class RegeneratePolicyDocumentPopupComponent
  implements OnInit, OnChanges
{
  @Input() showModal: boolean = false;
  @Input() details;
  @Output() handleClose = new EventEmitter<any>();
  @Output() handleActionConfirm = new EventEmitter<{ action: any }>();
  @Output() handleSuccess = new EventEmitter<{ action: any }>();

  docOptions = [
    { value: 1, key: 'Certificate', disabled: false },
    { value: 2, key: 'Invoice', disabled: false },
    { value: 3, key: 'Quote', disabled: false },
  ];
  tempOptions = [
    {
      value: 1,
      key: 'Template 1',
      disabled: false,
      tempType: 1,
      dataInjection: true,
    },
    {
      value: 2,
      key: 'Template 2',
      disabled: false,
      tempType: 2,
      dataInjection: true,
    },
    {
      value: 3,
      key: 'Template 3',
      disabled: false,
      tempType: 3,
      dataInjection: true,
    },
  ];
  form: FormGroup;
  isSaveProcessing: boolean = false;
  buttonDisabled: boolean = true;
  templateDisabled: boolean = true;
  statusType: string;
  titleText: string;

  docTempOptions: {
    doc: any;
    docId: any;
    template: any;
    tempId: any;
    tempType: any;
    dataInjection: any;
  }[] = [];
  selectedDoc;
  selectedTemp;
  docLength = 0;
  docSelectedCount = 0;
  docRemainingCount = 0;
  documentTemplateData: any[];
  Document: {
    name: string;
    templates: [];
  };
  allDocuments: Document[] = [];
  policyRiskId;
  docPreviewLoader: boolean = false;
  showDocPreview: boolean = false;
  currentPreviewDocUrl: string = '';

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private translate: TranslateService,
    private policyRiskDocProcessService: PolicyRiskDocProcessService,
    private DocumentTemplateService: DocumentTemplateService,
    private alertService: AlertService,
  ) {
    this.form = this.fb.group({
      templateType: [''],
    });
  }
  ngOnInit(): void {
    this.isSaveProcessing = false;

    this.store
      .select(getLifecycleSlideout)
      .pipe(take(1))
      .subscribe((state) => {
        this.policyRiskId = state?.policyRiskId;
      });
    this.documentTemplateData = this.details?.documentTemplate;
    this.populateDocumentType();
    this.docLength = this.docOptions?.length;
    this.docSelectedCount = this.docOptions?.filter((item) => item.disabled)
      .length;
    this.docRemainingCount = this.docOptions?.filter((item) => !item.disabled)
      .length;
    this.statusType = this.translate.instant(
      'productForm.heading.quote',
      this.docLength,
    );
  }

  ngOnChanges(): void {
    this.documentTemplateData = this.details?.documentTemplate;
    this.populateDocumentType();
    this.docLength = this.docOptions?.length;
    this.docSelectedCount = this.docOptions?.filter((item) => item.disabled)
      .length;
    this.docRemainingCount = this.docOptions?.filter((item) => !item.disabled)
      .length;
    if (this.details?.statusType) {
      this.statusType = this.details?.statusType;
    }
    this.titleText =
      this.statusType +
      ' ' +
      this.translate.instant('productForm.heading.confirmation');
    this.titleText =
      this.getActionName(this.details?.action) + ' ' + this.titleText;
  }

  getActionName(action: TransactionRiskActions) {
    const name = TransactionRiskActions[action];
    return addSpaceBeforeCapitalLetter(name);
  }

  closeHandler() {
    this.resetForm();
    this.handleClose.emit();
  }
  //handle confrim submit action
  async actionConfirmHandler(event) {
    this.alertService.clearAlerts(-1);
    this.isSaveProcessing = true;

    if (this.buttonDisabled) {
      this.isSaveProcessing = false;
      return;
    }
    await Promise.all([this.regenerateDocumentTemplate()]);
    this.isSaveProcessing = false;
    this.handleSuccess.emit({
      action: this.details.action,
    });
    this.resetForm();
  }
  //Reset form
  private resetForm() {
    this.docOptions.forEach((item) => (item.disabled = false));
    this.docSelectedCount = this.docOptions.filter(
      (item) => item.disabled,
    ).length;
    this.docRemainingCount = this.docOptions.filter(
      (item) => !item.disabled,
    ).length;
    this.selectedDoc = '';
    this.selectedTemp = '';
    this.form.get('templateType').setValue('');
    this.buttonDisabled = true;
    this.docTempOptions = [];
    this.isSaveProcessing = false;
  }

  //Remove document-template from preview list

  handleDocTempRemove(doc, temp, index) {
    const docIndex = this.docOptions.findIndex((docObj) => docObj.key === doc);
    const tempIndex = this.tempOptions.findIndex(
      (docObj) => docObj.key === temp,
    );
    if (docIndex !== -1) {
      this.docOptions[docIndex].disabled = false;
    }
    if (index !== -1) {
      this.docTempOptions.splice(index, 1);
    }
    this.docSelectedCount = this.docOptions.filter(
      (item) => item.disabled,
    ).length;
    this.docRemainingCount = this.docOptions.filter(
      (item) => !item.disabled,
    ).length;

    this.buttonDisabled = this.docSelectedCount !== this.docLength;
  }
  //handle template-change section
  handleTemplateChange(value) {
    if (value) {
      const index = this.tempOptions.findIndex(
        (docObj) => docObj.value == value,
      );
      this.form.get('templateType').setValue(this.tempOptions[index].key);
      this.selectedTemp = value;
      this.updatePreview();
    }
  }
  //handle document-change section
  handleDocumentChange(value) {
    this.selectedTemp = '';
    this.form.get('templateType').setValue('');
    if (value) {
      this.templateDisabled = false;
      this.selectedDoc = '';

      this.selectedDoc = value;
      this.tempOptions = [];
      const selectedRecord = this.documentTemplateData.find(
        (record) => record.templateType.id === this.selectedDoc,
      );
      this.populatetemplateType(selectedRecord);
      this.updatePreview();
    } else {
      this.templateDisabled = true;
    }
  }

  //handle document-template preview section
  private updatePreview() {
    const docIndex = this.docOptions.findIndex(
      (docObj) => docObj.value == this.selectedDoc,
    );
    const tempIndex = this.tempOptions.findIndex(
      (docObj) => docObj.value == this.selectedTemp,
    );
    const docTempIndex = this.docTempOptions.findIndex(
      (docObj) => docObj.doc === this.docOptions[docIndex].key,
    );

    if (this.selectedDoc && this.selectedTemp) {
      if (docTempIndex === -1) {
        this.docTempOptions.push({
          doc: this.docOptions[docIndex]?.key,
          docId: this.docOptions[docIndex]?.value,
          template: this.tempOptions[tempIndex]?.key,
          tempId: this.tempOptions[tempIndex]?.value,
          tempType: this.tempOptions[tempIndex]?.tempType,
          dataInjection: this.tempOptions[tempIndex]?.dataInjection,
        });
        this.docOptions[docIndex].disabled = true;
        this.docSelectedCount = this.docOptions.filter(
          (item) => item.disabled,
        ).length;
        this.docRemainingCount = this.docOptions.filter(
          (item) => !item.disabled,
        ).length;
        this.buttonDisabled = this.docSelectedCount !== this.docLength;
      } else if (docTempIndex || docTempIndex === 0) {
        this.docTempOptions[docTempIndex].template =
          this.tempOptions[tempIndex]?.key;
        this.docTempOptions[docTempIndex].tempId =
          this.tempOptions[tempIndex]?.value;
      }
    }
  }
  //populate Document type in dropdown
  populateDocumentType() {
    this.docOptions = this.documentTemplateData?.map((record) => ({
      value: record?.templateType?.id,
      key: `${record?.documentType?.type}/${record?.templateType?.type}`,
      disabled: false,
    }));
  }
  //populate template type in dropdown
  populatetemplateType(selectedRecord) {
    this.tempOptions = selectedRecord?.templates.map((record) => ({
      value: record?.id,
      key: record?.templateDescription,
      disabled: false,
      tempType: selectedRecord?.templateType?.id,
      dataInjection: record?.dataInjection,
    }));
  }

  //regenerate DocumentTemplate
  regenerateDocumentTemplate() {
    return new Promise<any>(async (resolve, reject) => {
      let selectedDocTempOptions = this.docTempOptions;
      let existingTemplate =
        this.details.documentTemplate[0]?.templates?.filter(
          (single) => single.docProcessId,
        )[0];
      if (existingTemplate && existingTemplate.docProcessId) {
        selectedDocTempOptions.forEach((p) => {
          if (selectedDocTempOptions.length > 0) {
            const docTempOptionsData = {
              riskTemplateId: p.tempId,
              policyRiskId: this.policyRiskId,
              documentName: p.template,
              documentUniqueName: p.template,
              dataInjection: p.dataInjection,
              templateType: p.tempType,
            };

            const payloadForPolicyRiskDocCreate = {
              policyRiskDocs: [docTempOptionsData],
            };

            this.policyRiskDocProcessService
              .updatePolicyRiskDoc(
                existingTemplate.docProcessId,
                docTempOptionsData,
              )
              .subscribe({
                next: async (resp) => {
                  await this.stageHandler(this.details?.action);
                  resolve(true);
                },
                error: (error) => {
                  let notificationAlert = {
                    type: 'error',
                    headerText: getAlertHead('error'),
                    bodyText: getErrorMessage(error),
                  };
                  this.alertService.addAlert(notificationAlert);
                  this.isSaveProcessing = false;
                  reject(getErrorMessage(error));
                },
              });
          }
        });
      } else {
        reject('error');
      }
    });
  }
  //Genrerate Document for stage
  async generateStageDocument() {
    return new Promise<any>(async (resolve, reject) => {
      this.DocumentTemplateService.generateStageDocument(
        this.details?.policyPeriodId,
        this.details?.stageId,
      ).subscribe({
        next: (resp) => {
          this.buttonDisabled = true;
          resolve(true);
        },
        error: (error) => {
          let notificationAlert = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: getErrorMessage(error),
          };
          this.alertService.addAlert(notificationAlert);
          this.isSaveProcessing = false;
          reject(getErrorMessage(error));
        },
      });
    });
  }

  async stageHandler(action) {
    switch (action.toLowerCase()) {
      case 'policy':
        await this.generateStageDocument();
        break;
      case 'bind':
        break;
      case 'quote':
        break;
      default:
        break;
    }
  }
}
