<div class="custom-modal" *ngIf="showModal">
  <div class="modal-content">
    <div class="close-btn">
      <button (click)="closeHandler()">
        <img src="assets/img/dashboard/card/close.png" />Close
      </button>
    </div>
    <div class="content">
      <div class="d-flex flex-column align-items-center">
        <img
          src="assets/img/alert-triangle.jpg"
          width="66px"
          height="66px"
          alt="alert"
        />
        <boxxds-body
          sz="md"
          [text]="'Cancellation confirmation: Action can be undone'"
          [customStyle]="{ color: '#FE802C', marginTop: '12px' }"
        ></boxxds-body>
      </div>
      <div class="policy-details d-flex flex-column mt-1">
        <boxxds-body
          sz="md"
          [text]="details.id"
          [customStyle]="{ color: '#616162', marginTop: '20px' }"
        ></boxxds-body>
        <div class="d-flex mt-1">
          <boxxds-body
            sz="xs"
            [text]="'Policy Period:'"
            [customStyle]="{
              color: '#616162',
              marginRight: '4px',
              lineHeight: '14px'
            }"
          ></boxxds-body>
          <boxxds-body
            sz="xs"
            [text]="details.period"
            [customStyle]="{ color: '#616162', lineHeight: '14px' }"
          ></boxxds-body>
        </div>
        <div class="d-flex mt-1">
          <boxxds-body
            sz="xs"
            [text]="'Insured:'"
            [customStyle]="{
              color: '#616162',
              marginRight: '4px',
              lineHeight: '14px'
            }"
          ></boxxds-body>
          <boxxds-body
            sz="xs"
            [text]="details.insured"
            [customStyle]="{ color: '#616162', lineHeight: '14px' }"
          ></boxxds-body>
        </div>
        <div class="d-flex mt-1">
          <boxxds-body
            sz="xs"
            [text]="details.brokerage + ', '"
            [customStyle]="{
              color: '#616162',
              marginRight: '4px',
              lineHeight: '14px'
            }"
          ></boxxds-body>
          <boxxds-body
            sz="xs"
            [text]="details.branch"
            [customStyle]="{ color: '#616162', lineHeight: '14px' }"
          ></boxxds-body>
        </div>
      </div>
      <form [formGroup]="cancelPolicyForm" (ngSubmit)="handleConfirm($event)">
        <ng-container *ngIf="!showCancelConfirm">
          <div class="d-flex">
            <div class="mr-4">
              <boxxds-body
                sz="sm"
                [text]="'Select cancellation date'"
                [customStyle]="{ color: '#333334' }"
              ></boxxds-body>
              <boxxds-datepicker
                [placeholder]="shortDateFormat"
                [dateFormat]="shortDateFormat"
                [containerStyle]="{ maxWidth: '186px', marginTop: '8px' }"
                [bsValue]="cancelPolicyForm.value['date']"
                [minDate]="minDate"
                (bsValueChange)="handleCancelDate($event)"
              >
              </boxxds-datepicker>
            </div>
            <div class="flex-grow-1">
              <boxxds-body
                sz="sm"
                [text]="'Reason for cancellation'"
                [customStyle]="{ color: '#333334' }"
              ></boxxds-body>
              <boxxds-dropdown
                [form]="cancelPolicyForm"
                [control]="'reason'"
                [placeholder]="'Select'"
                [options]="details.reasonOptions"
                [showKeyasTitle]="true"
                [dropdownContainerStyle]="{
                  maxWidth: '250px',
                  marginTop: '8px'
                }"
                (currentValueChange)="reasonHandler($event)"
              ></boxxds-dropdown>
            </div>
          </div>
          <div class="d-flex">
            <div class="mr-4">
              <boxxds-body
                sz="sm"
                [text]="'Select cancellation type:'"
                [customStyle]="{
                  color: '#56565C',
                  marginTop: '20px',
                  marginBottom: '12px'
                }"
              ></boxxds-body>
              <div class="radio-input">
                <input
                  type="radio"
                  value="Void"
                  (change)="handleCancelType($event)"
                  name="type"
                />
                <label>Void</label>
              </div>
              <div class="radio-input">
                <input
                  type="radio"
                  value="ShortRate"
                  (change)="handleCancelType($event)"
                  name="type"
                />
                <label>Short rate</label>
              </div>
              <div class="radio-input">
                <input
                  type="radio"
                  value="ProRata"
                  (change)="handleCancelType($event)"
                  name="type"
                />
                <label>Pro-Rata</label>
              </div>
              <div class="radio-input">
                <input
                  type="radio"
                  value="CancelFlat"
                  (change)="handleCancelType($event)"
                  name="type"
                />
                <label>Cancel flat</label>
              </div>
            </div>
            <div class="flex-grow-1" *ngIf="showCancellationFee">
              <boxxds-textinput
                [form]="cancelPolicyForm"
                [control]="'cancellationFee'"
                [placeholder]="'Enter cancellation fee'"
                [label]="'Cancellation Fee:'"
                [id]="'cancellationFee'"
                (handleKeyUp)="handleCancellationFeeType($event)"
                [containerStyle]="{
                  marginTop: '20px',
                  marginLeft: '35px',
                  marginRight: '40px'
                }"
                width="186px"
                mask="separator.2"
                maxLength="15"
                thousandSeparator=","
                prefix="$"
              >
              </boxxds-textinput>
            </div>
          </div>
          <boxxds-button
            buttonText="Continue to confirm"
            [type]="'button'"
            btnType="primary-outlined"
            [btnStyle]="{
              width: '181px',
              marginLeft: 'auto',
              marginTop: '12px'
            }"
            [isDisabled]="buttonDisabled"
            (handleClick)="handleContinue($event)"
          >
          </boxxds-button>
          <boxxds-body
            sz="md"
            [text]="errorMessage"
            [customStyle]="{
              color: 'red',
              marginTop: '4px',
              marginBottom: '4px'
            }"
          >
          </boxxds-body>
        </ng-container>
        <ng-container *ngIf="showCancelConfirm">
          <boxxds-body
            sz="md"
            [text]="'Cancel Flat'"
            [customStyle]="{ color: '#616162', marginBottom: '4px' }"
          ></boxxds-body>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div class="d-flex mt-1">
                <boxxds-body
                  sz="xs"
                  [text]="'Cancellation date:'"
                  [customStyle]="{
                    color: '#616162',
                    marginRight: '4px',
                    lineHeight: '14px'
                  }"
                ></boxxds-body>
                <boxxds-body
                  sz="xs"
                  [text]="cancellationDate"
                  [customStyle]="{
                    color: '#616162',
                    lineHeight: '14px',
                    fontWeight: '700'
                  }"
                ></boxxds-body>
              </div>
              <div class="d-flex mb-1">
                <boxxds-body
                  sz="xs"
                  [text]="'Return premium:'"
                  [customStyle]="{
                    color: '#616162',
                    marginRight: '4px',
                    lineHeight: '14px'
                  }"
                ></boxxds-body>
                <boxxds-body
                  sz="xs"
                  [text]="
                    returnPremium
                      | currency: currency : 'symbol-narrow' : '1.0-0'
                  "
                  [customStyle]="{
                    color: '#616162',
                    lineHeight: '14px',
                    fontWeight: '700'
                  }"
                ></boxxds-body>
              </div>
              <div class="d-flex mb-1" *ngIf="showCancellationFee">
                <boxxds-body
                  sz="xs"
                  [text]="'Cancellation Fee:'"
                  [customStyle]="{
                    color: '#616162',
                    marginRight: '4px',
                    lineHeight: '14px'
                  }"
                ></boxxds-body>
                <boxxds-body
                  sz="xs"
                  [text]="
                    cancellationFee
                      | currency: currency : 'symbol-narrow' : '1.0-0'
                  "
                  [customStyle]="{
                    color: '#616162',
                    lineHeight: '14px',
                    fontWeight: '700'
                  }"
                ></boxxds-body>
              </div>
            </div>
            <boxxds-button
              buttonText="Cancel policy & invoice"
              [type]="'submit'"
              [btnStyle]="{
                width: '308px',
                marginLeft: 'auto',
                marginTop: '12px'
              }"
            >
            </boxxds-button>
          </div>
        </ng-container>
      </form>
    </div>
  </div>
</div>
