import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-tile',
  templateUrl: './info-tile.component.html',
  styleUrls: ['./info-tile.component.less'],
})
export class InfoTileComponent {
  @Input() heading: string;
  @Input() count: string;
  @Input() showRedirectLink = true;
  redirectIconSrc: string;

  constructor() {}
}
