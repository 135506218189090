<div class="endorse-outer-container">
  <ng-container *ngIf="showTabSpinner">
    <div class="loader-wrapper">
      <div class="tab-body-spinner">
        <boxxds-spinner
          [containerStyle]="{
            width: '112px',
            height: '112px',
            '--b': '15px'
          }"
        ></boxxds-spinner>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="showEndorseForm">
    <div
      class="endorsement-container"
      [ngStyle]="endorsementContainerStyle"
      *ngIf="!showTabSpinner"
    >
      <div class="endorsement-header">
        <div class="left">
          <h2 class="endorsement-title">
            {{ "workFlow3.plc.endorsement.label.endorseTitle" | translate }}
          </h2>
          <p class="endorsement-subtitle">
            {{ "workFlow3.plc.endorsement.label.endorseSubTitle" | translate }}
          </p>
        </div>
        <div class="right">
          <div>
            <boxxds-button-v2
              [isDisabled]="false"
              [size]="'lg'"
              [btnType]="'close'"
              (handleClick)="closeEndorseForm()"
              [btnClass]="'close-button'"
              [btnStyle]="{
                borderRadius: '31px',
                background: '#D0D5DD',
                width: '36px',
                height: '36px'
              }"
            >
            </boxxds-button-v2>
          </div>
        </div>
      </div>
      <form>
        <div class="endorse-row col-md-12">
          <div class="col-md-3 endorse-col">
            <div class="form col-md-12">
              <div class="form-group">
                <label for="name" class="col-md-6 col-form-label"
                  >{{
                    "workFlow3.plc.endorsement.label.trnxEffectiveDate"
                      | translate
                  }}
                  <span class="required-asterisk">*</span></label
                >
                <div class="col-md-6">
                  <boxxds-datepicker
                    [placeholder]="'11/11/1111'"
                    [dateFormat]="shortDateFormat"
                    [isReadOnly]="!isFieldEditable()"
                    [class.noPointerEvents]="!isFieldEditable()"
                    [bsValue]="EndorseForm.value['effectiveDate']"
                    (bsValueChange)="
                      isFieldEditable() && handleTransactionEffDate($event)
                    "
                    [minDate]="minEffectiveDate"
                    [maxDate]="maxEffectiveDate"
                    [showErr]="!isEffectiveDateValid"
                    [errMsg]="effectiveDateErrMsg | translate"
                  >
                  </boxxds-datepicker>
                </div>
              </div>
              <div class="form-group">
                <label for="email" class="col-md-6 col-form-label"
                  >{{ "workFlow3.plc.endorsement.label.currency" | translate }}
                  <span class="required-asterisk">*</span></label
                >
                <div class="col-md-6">
                  <boxxds-dropdown-v2
                    [hasOuterBorder]="false"
                    [placeholder]="'USD' | translate"
                    [options]="currencyOptions"
                    [dropdownContainerStyle]="{
                      maxWidth: '114px',
                      boxShadow: '0px 1px 0px #1018280d'
                    }"
                    [currentValue]="EndorseForm?.value?.currency"
                    [isReadonly]="true"
                    [isDisabled]="true"
                    [class.noPointerEvents]="true"
                  >
                  </boxxds-dropdown-v2>
                </div>
              </div>
              <div class="form-group">
                <label for="message" class="col-md-6 col-form-label"
                  >{{
                    "workFlow3.plc.endorsement.label.commissionRate" | translate
                  }}
                  <span class="required-asterisk">*</span></label
                >
                <div class="col-md-6">
                  <div class="commission cell">
                    <boxxds-dropdown-v2
                      *ngIf="!showCommissionCustomField()"
                      [hasOuterBorder]="false"
                      [form]="EndorseForm"
                      [control]="brokerCommissionPerc"
                      [formSubmitted]="!showInvalidCommissionErr"
                      [placeholder]="'%' | translate"
                      [options]="commissionOptions"
                      [showSubKeyInNextLine]="true"
                      [dropdownContainerStyle]="{
                        minWidth: '114px',
                        boxShadow: '0px 1px 0px #1018280d'
                      }"
                      [invalidErrMsg]="showInvalidCommissionErrMsg"
                      (currentValueChange)="
                        isFieldEditable() && handleBrokerCommissionPerc($event)
                      "
                      [currentValue]="
                        EndorseForm?.value?.brokerCommissionPerc + '%'
                      "
                      [isReadonly]="
                        !isFieldEditable() ||
                        isBrokerCommissionDisabled ||
                        brokerageCommissionMin[riskId]
                      "
                      [class.noPointerEvents]="!isFieldEditable()"
                    >
                    </boxxds-dropdown-v2>

                    <div
                      class="custom-value"
                      *ngIf="showCommissionCustomField()"
                    >
                      <input
                        class="border-less-input"
                        type="text"
                        mask="percent.2"
                        [suffix]="'%'"
                        maxlength="14"
                        [autofocus]="true"
                        (blur)="handleCommissionCustomChange($event)"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="message" class="col-md-6 col-form-label"
                  >{{
                    "workFlow3.plc.endorsement.label.issuanceDate" | translate
                  }}
                  <span class="required-asterisk">*</span></label
                >
                <div class="col-md-6">
                  <boxxds-textinput-v2
                    [form]="EndorseForm"
                    [control]="'issuanceDate'"
                    [placeholder]="'11/11/1111' | translate"
                    [id]="'issuanceDate'"
                    [readonly]="true"
                    [isDisabled]="true"
                    [class.noPointerEvents]="true"
                    [inputStyle]="{
                      border: 'none',
                      outline: 'none',
                      boxShadow: 'none',
                      borderBottom: '1px solid #717bbc',
                      borderRadius: '0',
                      maxWidth: '114px',
                      fontSize: '12px',
                      fontWeight: '400',
                      padding: '10px 4px'
                    }"
                  >
                  </boxxds-textinput-v2>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3 endorse-col">
            <div class="form col-md-12">
              <div class="form-group">
                <label for="name" class="col-md-6 col-form-label"
                  >{{ "workFlow3.plc.endorsement.label.limit" | translate }}
                  <span class="required-asterisk">*</span></label
                >
                <div class="col-md-6">
                  <div class="limit cell">
                    <boxxds-dropdown-v2
                      *ngIf="!showLimitCustomField()"
                      [form]="EndorseForm"
                      [formSubmitted]="!showInvalidLimitErr"
                      [control]="'limit'"
                      [hasOuterBorder]="false"
                      [leftSymbol]="'$'"
                      [placeholder]="'0' | translate"
                      [options]="limitOptions"
                      [dropdownContainerStyle]="{
                        minWidth: '114px',
                        boxShadow: '0px 1px 0px #1018280d'
                      }"
                      (currentValueChange)="
                        isFieldEditable() && handleLimitChange($event)
                      "
                      [invalidErrMsg]="showInvalidLimitErrMsg | translate"
                      [currentValue]="
                        EndorseForm.value?.limit
                          | currencyFormat: currency : 'symbol-narrow' : '1.0-0'
                      "
                      [isReadonly]="!isFieldEditable()"
                      [class.noPointerEvents]="!isFieldEditable()"
                    >
                    </boxxds-dropdown-v2>
                    <input
                      class="border-less-input"
                      *ngIf="isLimitDisabledOnAddSubProduct(riskId)"
                      [value]="
                        getDeductibleValue()
                          ? (getDeductibleValue()
                            | currencyFormat: currency : 'symbol-narrow')
                          : ''
                      "
                      [attr.disabled]="true"
                      class="disabled-field"
                    />

                    <div class="custom-value" *ngIf="showLimitCustomField()">
                      <input
                        class="border-less-input"
                        type="text"
                        mask="separator.0"
                        thousandSeparator=","
                        prefix="$ "
                        (blur)="handleLimitCustomValue($event)"
                        maxlength="14"
                        [autofocus]="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="email" class="col-md-6 col-form-label"
                  >{{
                    "workFlow3.plc.endorsement.label.aggregatedLimit"
                      | translate
                  }}
                  <span class="required-asterisk">*</span></label
                >
                <div class="col-md-6">
                  <boxxds-textinput-v2
                    [form]="EndorseForm"
                    [control]="'aggregatedLimit'"
                    [placeholder]="'$' | translate"
                    [id]="'aggregatedLimit'"
                    [readonly]="!isFieldEditable()"
                    [class.noPointerEvents]="!isFieldEditable()"
                    [inputStyle]="{
                      border: 'none',
                      outline: 'none',
                      boxShadow: 'none',
                      borderBottom: '1px solid #717bbc',
                      borderRadius: '0',
                      maxWidth: '114px',
                      fontSize: '12px',
                      fontWeight: '400',
                      padding: '10px 4px'
                    }"
                    mask="separator.2"
                    maxLength="15"
                    thousandSeparator=","
                    prefix="$ "
                    [requiredErrMsg]="
                      'workFlow3.plc.endorsement.error.requiredErrMsg'
                        | translate
                    "
                    [customErrMsg]="invalidAggregatedLimitErrMsg"
                    (handleBlur)="
                      handleLengthValidation($event, 'aggregatedLimit', 11)
                    "
                    (handleKeyUp)="this.invalidAggregatedLimitErrMsg = ''"
                    [formSubmitted]="true"
                  >
                  </boxxds-textinput-v2>
                </div>
              </div>
              <div class="form-group">
                <label for="message" class="col-md-6 col-form-label"
                  >{{
                    "workFlow3.plc.endorsement.label.retention" | translate
                  }}
                  <span class="required-asterisk">*</span></label
                >
                <div class="col-md-6">
                  <div class="retention cell">
                    <boxxds-dropdown-v2
                      *ngIf="!showDeductibleCustomField()"
                      [form]="EndorseForm"
                      [control]="'deductible'"
                      [formSubmitted]="!showInvalidDeductibleErr"
                      [hasOuterBorder]="false"
                      [leftSymbol]="'$'"
                      [placeholder]="'0' | translate"
                      [options]="deductibleOptions"
                      [dropdownContainerStyle]="{
                        minWidth: '114px',
                        boxShadow: '0px 1px 0px #1018280d'
                      }"
                      (currentValueChange)="
                        isFieldEditable() && handleDeductableChange($event)
                      "
                      [currentValue]="
                        EndorseForm?.value?.deductible
                          | currencyFormat: currency : 'symbol-narrow' : '1.0-0'
                      "
                      [invalidErrMsg]="showInvalidDeductibleErrMsg | translate"
                      [isReadonly]="!isFieldEditable()"
                      [class.noPointerEvents]="!isFieldEditable()"
                    >
                    </boxxds-dropdown-v2>

                    <div
                      class="custom-value"
                      *ngIf="showDeductibleCustomField()"
                    >
                      <input
                        class="border-less-input"
                        type="text"
                        mask="separator.0"
                        thousandSeparator=","
                        prefix="$ "
                        maxlength="14"
                        (blur)="handleDeductableCustomValue($event)"
                        [autofocus]="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="message" class="col-md-6 col-form-label"
                  >{{
                    "workFlow3.plc.endorsement.label.editedPremium" | translate
                  }}
                  <span class="required-asterisk">*</span></label
                >
                <div class="col-md-6">
                  <boxxds-textinput-v2
                    [form]="EndorseForm"
                    [control]="'premium'"
                    [placeholder]="'$' | translate"
                    [id]="'editedPremium'"
                    (handleBlur)="
                      isFieldEditable() && handleChangePremium($event)
                    "
                    (handleKeyUp)="
                      isFieldEditable() && handleChangePremium($event)
                    "
                    [readonly]="!isFieldEditable()"
                    [class.noPointerEvents]="!isFieldEditable()"
                    [inputStyle]="{
                      border: 'none',
                      outline: 'none',
                      boxShadow: 'none',
                      borderBottom: '1px solid #717bbc',
                      borderRadius: '0',
                      maxWidth: '114px',
                      fontSize: '12px',
                      fontWeight: '400',
                      padding: '10px 4px'
                    }"
                    mask="separator.2"
                    maxLength="11"
                    thousandSeparator=","
                    prefix="$ "
                    [requiredErrMsg]="
                      'workFlow3.plc.endorsement.error.requiredErrMsg'
                        | translate
                    "
                    [customErrMsg]="invalidEditedPremiumLimitErrMsg"
                    (handleBlur)="handleLengthValidation($event, 'premium', 8)"
                    (handleKeyUp)="this.invalidEditedPremiumLimitErrMsg = ''"
                    [formSubmitted]="true"
                  >
                  </boxxds-textinput-v2>
                </div>
              </div>
              <div class="form-group">
                <label for="message" class="col-md-6 col-form-label"
                  >{{
                    "workFlow3.plc.endorsement.label.premiumTax" | translate
                  }}
                  <span class="required-asterisk">*</span></label
                >
                <div class="col-md-6">
                  <boxxds-textinput-v2
                    [form]="EndorseForm"
                    [control]="'taxes'"
                    [placeholder]="'$'"
                    [id]="'tax-premium'"
                    [containerStyle]="{ marginRight: '40px' }"
                    [readonly]="true"
                    [isDisabled]="true"
                    [inputStyle]="{
                      border: 'none',
                      outline: 'none',
                      boxShadow: 'none',
                      borderBottom: '1px solid #717bbc',
                      borderRadius: '0',
                      maxWidth: '114px',
                      fontSize: '12px',
                      fontWeight: '400',
                      padding: '10px 4px'
                    }"
                    prefix="$ "
                    class="disabled"
                  >
                  </boxxds-textinput-v2>
                  <!-- mask="separator.2"
              thousandSeparator="," 
              decimalMarker="'.'"
              allowNegative="false"
              decimal="true" -->
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-5 endorse-col-6">
            <div class="form col-md-12">
              <div class="form-group">
                <div class="endorse-textarea">
                  <boxxds-textarea
                    [label]="
                      'workFlow3.plc.endorsement.label.endorseNoteLabel'
                        | translate
                    "
                    [placeholder]="
                      'workFlow3.components.notes.label.messageBody' | translate
                    "
                    [form]="EndorseForm"
                    [control]="'notes'"
                    [lblStyle]="{
                      color: '#667085',
                      fontFamily: 'Gibson',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: '20px',
                      height: '28px'
                    }"
                    [formSubmitted]="errorMsgValid"
                    [customErrMsg]="notesTextareaErrorMsg | translate"
                    (handleKeyUp)="
                      isFieldEditable() && onKeyUptextareaValidation($event)
                    "
                    maxLength="500"
                    [isDisabled]="!isFieldEditable()"
                  ></boxxds-textarea>
                </div>

                <div class="textarea-footer">
                  <div class="textarea-footer-label">
                    {{ remainingChars }}
                    {{
                      "workFlow3.plc.endorsement.label.characterLeftLabel"
                        | translate
                    }}
                  </div>
                  <div>
                    <boxxds-status-badge
                      [status]="trxType"
                      [statusType]="'secondary'"
                      [badgeStyle]="{
                        display: 'flex',
                        padding: '2px 8px',
                        aligItems: 'center',
                        borderRadius: '9999px',
                        border: '1px solid #B1F5C9',
                        background: '#F0F9FF',
                        width: 'fit-content',
                        color: '#008932',
                        textAlign: 'center',
                        fontFamily: 'Gibson',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '18px'
                      }"
                    ></boxxds-status-badge>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="endorsement-footer"
          [ngStyle]="hasEndorsed && { justifyContent: 'space-between' }"
        >
          <div class="endorsement-footer-switch-container" *ngIf="hasEndorsed">
            <boxxds-switch-v2
              [checked]="endorsementTemplateSelected"
              [disabled]="false"
              name="template-selection-endrs"
              (changed)="toggleEndorsementTemplateSelection($event)"
              [label]="
                'workFlow3.plc.endorsement.label.midtermEndorsementTemplate'
                  | translate
              "
              [mainLabelStyle]="{ minWidth: '250px' }"
            >
            </boxxds-switch-v2>
          </div>
          <div class="endorsement-footer-btn-container">
            <div class="right">
              <div class="button-wrapper">
                <div class="btn-wrapper">
                  <boxxds-button-v2
                    [isDisabled]="!isFieldEditable()"
                    [btnStyle]="{
                      color: '#2D2D39',
                      fontSize: '14px',
                      fontFamily: 'Gibson',
                      fontWeight: '600',
                      width: '124px',
                      background: '#fff',
                      height: '40px'
                    }"
                    [buttonText]="
                      'workFlow3.plc.endorsement.button.discard' | translate
                    "
                    [btnType]="'link gray'"
                    type="button"
                    (handleClick)="handleDiscard()"
                  >
                  </boxxds-button-v2>
                  <boxxds-button-v2
                    [isDisabled]="
                      !isFieldEditable() ||
                      this.invalidAggregatedLimitErrMsg !== '' ||
                      this.invalidEditedPremiumLimitErrMsg !== ''
                    "
                    [buttonText]="
                      'workFlow3.plc.endorsement.button.save' | translate
                    "
                    [type]="'submitted'"
                    [size]="'md'"
                    [btnClass]="'secondary gray'"
                    [btnType]="'default'"
                    [btnStyle]="{
                      width: '151px',
                      background: '#fff',
                      height: '40px',
                      color: '#B17806',
                      border: '1px solid #FCBD32',
                      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'
                    }"
                    (handleClick)="
                      this.invalidAggregatedLimitErrMsg === '' &&
                      this.invalidEditedPremiumLimitErrMsg === '' &&
                      isFieldEditable() &&
                      !hasEndorsed
                        ? handleEndorseTransaction()
                        : handleSaveTransaction()
                    "
                  >
                  </boxxds-button-v2>
                  <boxxds-button-v2
                    [isDisabled]="
                      !isFieldEditable() ||
                      !hasEndorsed ||
                      this.invalidAggregatedLimitErrMsg !== '' ||
                      this.invalidEditedPremiumLimitErrMsg !== ''
                    "
                    [buttonText]="
                      'workFlow3.plc.endorsement.button.endorse' | translate
                    "
                    (handleClick)="
                      this.invalidAggregatedLimitErrMsg === '' &&
                        this.invalidEditedPremiumLimitErrMsg === '' &&
                        hasEndorsed &&
                        isFieldEditable() &&
                        handleEndorsementInvoice()
                    "
                    [size]="'md'"
                    [btnClass]="'primary'"
                    [btnType]="'default'"
                    [btnStyle]="{ width: '151px', height: '40px' }"
                  >
                  </boxxds-button-v2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </ng-container>
</div>
