<div class="branch-information">
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>
  <div class="max-width-wrapper">
    <div class="alert-holder">
      <boxxds-alert-v2
        *ngIf="alertData?.show"
        [type]="alertData?.type"
        [headerText]="alertData?.header | translate"
        [bodyText]="alertData?.body | translate"
        (handleCloseEvent)="handleAlertClose()"
      ></boxxds-alert-v2>
    </div>
    <!-- brokerage information -->
    <form [formGroup]="form">
      <div class="branch-information-wrapper">
        <div class="form-title-wrapper">
          <h2>
            {{ "workFlow3.branchProfile.label.branchInformation" | translate }}
          </h2>
          <h6>
            {{
              "workFlow3.branchProfile.label.enterBranchInformation" | translate
            }}
          </h6>
        </div>
        <div class="form-wrapper">
          <boxxds-textinput-v2
            [form]="branch"
            [readonly]="!this.permissionList[this.currentScreen] || !isActive"
            [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
            [placeholder]="'ABC Company' | translate"
            [control]="'name'"
            [id]="'name'"
            [width]="'100%'"
            [label]="'workFlow3.branchProfile.label.name' | translate"
            [isRequired]="true"
            [requiredErrMsg]="requiredErrMsg"
            [invalidErrMsg]="invalidErrMsg"
            [formSubmitted]="submitted"
            [containerStyle]="{ marginBottom: '20px' }"
          >
          </boxxds-textinput-v2>
          <boxxds-textinput-v2
            [form]="branch"
            [readonly]="!this.permissionList[this.currentScreen] || !isActive"
            [isDisabled]="
              !this.permissionList[this.currentScreen] ||
              !branchSelected?.active
            "
            [placeholder]="'olivia@untitledui.com' | translate"
            [control]="'email'"
            [id]="'email'"
            [width]="'100%'"
            [label]="
              'workFlow3.branchProfile.label.mainEmailAddress' | translate
            "
            [isRequired]="true"
            [requiredErrMsg]="requiredErrMsg"
            [invalidErrMsg]="invalidErrMsg"
            [formSubmitted]="submitted"
            [containerStyle]="{ marginBottom: '20px' }"
          >
          </boxxds-textinput-v2>
          <boxxds-textinput-v2
            [form]="branch"
            [readonly]="!this.permissionList[this.currentScreen] || !isActive"
            [isDisabled]="
              !this.permissionList[this.currentScreen] ||
              !branchSelected?.active
            "
            [mask]="'(000) 000-0000'"
            [placeholder]="'111-111-11111' | translate"
            [control]="'phone'"
            [id]="'phoneNumber'"
            [width]="'100%'"
            [label]="'workFlow3.branchProfile.label.phoneNumber' | translate"
            [isRequired]="false"
            [requiredErrMsg]="requiredErrMsg"
            [invalidErrMsg]="invalidErrMsg"
            [formSubmitted]="submitted"
            [containerStyle]="{ marginBottom: '20px' }"
          >
          </boxxds-textinput-v2>
        </div>
      </div>
      <div class="branch-information-wrapper">
        <div class="form-title-wrapper">
          <h2>
            {{ "workFlow3.branchProfile.label.branchAddress" | translate }}
          </h2>
          <h6>
            {{ "workFlow3.branchProfile.label.enterBranchAddress" | translate }}
          </h6>
        </div>
        <div class="form-wrapper">
          <app-location-details-form-v2
            (eventEmitRegionIdSelected)="
              this.permissionList[this.currentScreen] &&
                isActive &&
                regionIdSelected($event)
            "
            [formSubmitted]="submitted"
            [form]="location"
            [requiredErrMsg]="requiredErrMsg"
            [isActive]="isActive"
          ></app-location-details-form-v2>
        </div>
      </div>
      <div class="branch-information-wrapper button-wrapper">
        <div class="btn-wrapper">
          <boxxds-button-v2
            [isDisabled]="
              !!!this.isBranchFormDirty && !!!this.isLocationFormDirty
            "
            [buttonText]="'workFlow3.branchProfile.label.discard' | translate"
            [size]="'md'"
            [btnClass]="'secondary gray'"
            [btnType]="'default'"
            [btnStyle]="{ width: '130px', background: '#fff', height: '40px' }"
            (handleClick)="handleDiscardClick()"
          >
          </boxxds-button-v2>
          <boxxds-button-v2
            [isDisabled]="
              !isActive ||
              (!!!this.isBranchFormDirty && !!!this.isLocationFormDirty)
            "
            [buttonText]="'workFlow3.branchProfile.label.save' | translate"
            [type]="'submitted'"
            [size]="'md'"
            [btnClass]="'secondary gray'"
            [btnType]="'default'"
            [btnStyle]="{ width: '130px', background: '#fff', height: '40px' }"
            (handleClick)="handleUpdateBranchClick($event)"
          >
          </boxxds-button-v2>
          <boxxds-button-v2
            *ngIf="!isActive"
            [isDisabled]="showBodySpinner"
            [buttonText]="'Activate'"
            [size]="'md'"
            [btnClass]="'secondary gray'"
            [btnType]="'default'"
            [btnStyle]="{ width: '130px', background: '#fff', height: '40px' }"
            (handleClick)="!showBodySpinner && handleActivateBranch($event)"
          >
          </boxxds-button-v2>
          <boxxds-button-v2
            *ngIf="isActive"
            [isDisabled]="showBodySpinner"
            [buttonText]="'Deactivate'"
            [size]="'md'"
            [btnClass]="'secondary gray'"
            [btnType]="'default'"
            [btnStyle]="{ width: '130px', background: '#fff', height: '40px' }"
            (handleClick)="!showBodySpinner && handleDeactivateBranch($event)"
          >
          </boxxds-button-v2>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="footer-section">
  <app-pagefooter-timestamp-v2
    *ngIf="createdBy && createdAt"
    [createdBy]="createdBy"
    [createdAt]="createdAt"
    [updatedBy]="updatedBy"
    [updatedAt]="updatedAt"
  >
  </app-pagefooter-timestamp-v2>
</div>
