<div
  class="holder"
  (clickOutside)="handleOutsideClick()"
  [ngClass]="{
    expanded: isExpanded,
    collapsed: !isExpanded
  }"
  (click)="toggleSidebar($event)"
>
  <div class="menu-icon-holder">
    <div class="menu-icon">
      <img
        [src]="menuIconSrc || 'assets/img/dashboard/sidemenu/menu.png'"
        (mouseout)="menuIconSrc = 'assets/img/dashboard/sidemenu/menu.png'"
        (mouseover)="
          menuIconSrc = 'assets/img/dashboard/sidemenu/hover/menu.png'
        "
        alt="menu"
      />
      <p
        class="menu-text collapsed"
        *ngIf="!isExpanded"
        (click)="toggleSidebar($event)"
      >
        Open menu
      </p>
      <p class="menu-text expanded" *ngIf="isExpanded">
        {{ "Menu" | translate }}
      </p>
    </div>
    <button *ngIf="isExpanded" (click)="closeSidebar($event)">
      {{ "Close" | translate }}
    </button>
  </div>
  <div class="search">
    <form [formGroup]="form" (ngSubmit)="handleSubmit($event)">
      <boxxds-textinput
        [form]="form"
        [placeholder]="isExpanded ? 'Search menu' : '...'"
        [label]="''"
        [control]="'search'"
        [id]="'search'"
        [leftIcon]="'assets/img/dashboard/search/search.png'"
        [width]="isExpanded ? '265.14px' : '71px'"
        (click)="stopMenuToggle($event)"
        (handleKeyUp)="onSearch($event)"
        [containerStyle]="{ marginBottom: '8px', marginTop: '16px' }"
      >
      </boxxds-textinput>
    </form>

    <button
      type="button"
      class="btn-expand"
      *ngIf="isExpanded"
      (click)="stopMenuToggle($event); handleExpandOrCollapseAll()"
    >
      {{
        isAllMenuExpanded
          ? "Collapse all"
          : "Expand
            all"
      }}
    </button>

    <div class="menu-items">
      <div class="menu-item" *ngFor="let m of menu; let i = index">
        <div
          class="menu"
          (click)="stopMenuToggle($event); toggleSubMenu($event, i)"
        >
          <a
            [href]="m.href"
            (mouseout)="m.imgSrc = m.src"
            (mouseover)="m.imgSrc = m.hoverSrc"
          >
            <img [src]="m.imgSrc || m.src" [alt]="m.name" />
            <span *ngIf="isExpanded">{{ m.name }}</span>
            <img
              class="expand-collapse"
              *ngIf="isExpanded && m.submenu && m.submenu.length > 0"
              [src]="
                m.active
                  ? 'assets/img/dashboard/sidemenu/chevron-up.png'
                  : 'assets/img/dashboard/sidemenu/chevron-down.png'
              "
            />
            <div
              class="badge"
              *ngIf="isExpanded && m.notification && m.notification.length > 0"
            >
              <app-notify-badge [content]="m.notification"></app-notify-badge>
            </div>
          </a>
        </div>
        <div
          class="submenus"
          *ngIf="isExpanded && m.submenu && m.submenu.length > 0 && m.active"
        >
          <div *ngFor="let submenu of m.submenu">
            <a [routerLink]="submenu.href" skipLocationChange="true">
              <span class="submenu">{{ submenu.name }}</span>
            </a>
          </div>
        </div>
      </div>

      <div
        class="logout-menu display-flex-row justify-content-between"
        (click)="stopMenuToggle($event)"
      >
        <button (click)="handleLogout($event)" class="logout">
          <img
            [src]="logoutIconSrc || 'assets/img/dashboard/sidemenu/logout.png'"
            (mouseout)="
              logoutIconSrc = 'assets/img/dashboard/sidemenu/logout.png'
            "
            (mouseover)="
              logoutIconSrc = 'assets/img/dashboard/sidemenu/hover/logout.png'
            "
            alt="logout"
          />
          <span *ngIf="isExpanded">{{ "Logout" | translate }}</span>
        </button>
        <button
          type="button"
          class="text-white"
          *ngIf="isExpanded"
          (click)="openPopover()"
        >
          {{ releaseVersion }}
        </button>
      </div>
      <div class="popup-holder" *ngIf="isExpanded">
        <div *ngIf="isPopoverOpen" class="popover-content">
          <div
            class="d-flex align-items-start justify-content-between my-1 pt-2"
          >
            <span class="heading"> {{ "copyrightInfo" | translate }}</span>
            <div class="close-btn justify-content-end align-items-end">
              <button (click)="closePopover()" aria-label="Close">
                <img src="assets/img/dashboard/card/close.png" />
              </button>
            </div>
          </div>
          <div class="d-flex align-items-start">
            <div class="reasons">
              {{ versionPopupContent | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
