import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { convertDate } from 'src/app/utils/formatDate';

@Component({
  selector: 'app-modal-submission-risk-analysis',
  templateUrl: './modal-submission-risk-analysis.component.html',
  styleUrls: ['./modal-submission-risk-analysis.component.less'],
})
export class ModalSubmissionRiskAnalysisComponent implements OnInit {
  @Input() showModal: boolean = false;
  @Input() existingQuoteOptionData;

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleSave = new EventEmitter<any>();
  @Output() handleDiscardChanges = new EventEmitter<any>();

  longDateFormat = 'MMMM DD, YYYY';

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store
      .select(getDashboardSelector)
      .pipe(take(1))
      .subscribe((data) => {
        this.longDateFormat = data.longDateFormat;
      });
  }

  handleSaveAndContinue() {
    this.handleSave.emit();
  }

  closeHandler() {
    this.handleClose.emit();
  }

  handleDiscard(event: any) {
    this.handleDiscardChanges.emit();
  }

  getFormattedDate(date: any, dateFormat: string) {
    return date ? convertDate(date, dateFormat) : '';
  }
}
