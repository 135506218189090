<div class="company-container">
  <boxxds-alert
    *ngIf="showReloadModal"
    (handleCloseEvent)="handleCloseReloadWarning()"
    type="warn"
    [headerText]="'common.warnMsg' | translate"
    [bodyText]="'common.reloadMessageRollChange' | translate"
  >
  </boxxds-alert>
  <boxxds-alert
    *ngIf="showErrorAlert"
    (handleCloseEvent)="handleCloseSuccessEventExt()"
    type="error"
    [headerText]="'common.errorMsg' | translate"
    [bodyText]="msgErrorAlert"
  >
  </boxxds-alert>
  <div class="spinner-container">
    <ng-container *ngIf="showBodySpinner">
      <div class="body-spinner">
        <boxxds-spinner
          [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
        ></boxxds-spinner>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="!showBodySpinner">
    <div class="alert-holder">
      <boxxds-alert
        *ngIf="isSuccess"
        (handleCloseEvent)="handleCloseSuccessEvent()"
        type="success"
        [headerText]="'common.successMsg' | translate"
        [bodyText]="message | translate"
      >
      </boxxds-alert>

      <boxxds-alert
        *ngIf="hasError"
        (handleCloseEvent)="handleCloseSuccessEvent()"
        type="error"
        [headerText]="'common.errorMsg' | translate"
        [bodyText]="errorMessage"
      >
      </boxxds-alert>
    </div>

    <div class="create-title-container">
      <boxxds-body
        sz="lg"
        [text]="'Insured details' | translate"
        [customStyle]="{
          color: '#616162',
          textAlign: 'left',
          marginBottom: '8px',
          fontSize: '16px'
        }"
      ></boxxds-body>
      <span>*REQUIRED</span>
    </div>

    <div class="search-container">
      <form [formGroup]="insuredUserForm">
        <div class="add-container">
          <div class="d-flex">
            <boxxds-dropdown
              [form]="insuredUserForm"
              [control]="'insuredType'"
              [placeholder]="'placeholder.select' | translate"
              [options]="insuredTypes"
              [label]="('systemUser.label.insuredType' | translate) + '*'"
              [btnStyle]="{ marginTop: '18px', width: '264px' }"
              [dropdownContainerStyle]="{ width: '264px', marginRight: '40px' }"
              class="employees"
              [requiredErrMsg]="requiredErrMsg | translate"
              [formSubmitted]="formSubmitted"
              [currentValue]="insuredType"
              (currentValueChange)="
                this.permissionList[this.currentScreen] &&
                  insureTypeValueChanged($event)
              "
              [isReadonly]="
                !this.permissionList[this.currentScreen] ||
                toggleActive === true
              "
              [disabled]="
                !this.permissionList[this.currentScreen] ||
                toggleActive === false
              "
            >
            </boxxds-dropdown>

            <boxxds-searchable-dropdown
              [form]="insuredUserForm"
              [control]="'insuredId'"
              [placeholder]="
                'systemUser.placeholder.enterInsuredName' | translate
              "
              [options]="insuredUsers"
              [label]="('systemUser.label.insured' | translate) + '*'"
              [btnStyle]="{ marginTop: '8px', width: '264px' }"
              [dropdownContainerStyle]="{ width: '264px', marginRight: '40px' }"
              class="employees"
              [requiredErrMsg]="requiredErrMsg | translate"
              [formSubmitted]="formSubmitted"
              [currentValue]="insuredUser"
              (currentValueChange)="
                this.permissionList[this.currentScreen] &&
                  insureUserValueChanged($event)
              "
              (textInputChangeEventEmmiter)="searchInsureds($event)"
              [dispatchTextInputChange]="true"
              [readonly]="
                !this.permissionList[this.currentScreen] ||
                toggleActive === true
              "
              [isDisabled]="
                !this.permissionList[this.currentScreen] ||
                toggleActive === false
              "
            >
            </boxxds-searchable-dropdown>
            <boxxds-searchable-dropdown
              [form]="insuredUserForm"
              [control]="'roleId'"
              [placeholder]="'placeholder.searchRole' | translate"
              [options]="roles"
              [label]="('systemUser.label.role' | translate) + '*'"
              [invalidErrMsg]="formSubmitted ? (invalidRole | translate) : ''"
              [btnStyle]="{ marginTop: '18px', width: '264px' }"
              [dropdownContainerStyle]="{ width: '264px' }"
              class="employees"
              [requiredErrMsg]="requiredErrMsg | translate"
              [formSubmitted]="formSubmitted"
              [currentValue]="roleName"
              (currentValueChange)="
                this.permissionList[this.currentScreen] &&
                  boxxRoleValueChanged($event)
              "
              (textInputChangeEventEmmiter)="searchRoles($event)"
              [dispatchTextInputChange]="true"
              [isDisabled]="
                !this.permissionList[this.currentScreen] ||
                toggleActive === false
              "
            >
            </boxxds-searchable-dropdown>
          </div>
          <div class="d-flex">
            <boxxds-textinput
              [placeholder]="'systemUser.placeholder.email' | translate"
              [form]="insuredUserForm"
              [control]="'loginEmail'"
              [id]="'email'"
              [width]="'380px'"
              [label]="('systemUser.label.loginEmail' | translate) + '*'"
              [requiredErrMsg]="requiredErrMsg | translate"
              [invalidErrMsg]="invalidErrMsg | translate"
              [containerStyle]="{ marginRight: '40px', marginTop: '30px' }"
              [formSubmitted]="formSubmitted"
              maxLength="150"
              [readonly]="
                !this.permissionList[this.currentScreen] ||
                toggleActive === true
              "
              [isDisabled]="
                !this.permissionList[this.currentScreen] ||
                toggleActive === false
              "
            >
            </boxxds-textinput>
          </div>
        </div>
        <div
          class="btn-container"
          *ngIf="this.permissionList[this.currentScreen]"
        >
          <ng-container *ngIf="checkUser()">
            <a
              *ngIf="toggleActive === true"
              class="reset-link"
              (click)="deActivateSystemUser()"
              [ngStyle]="{ 'margin-right': '32px', 'margin-top': '4px' }"
              href="javascript:void(0)"
              [ngClass]="processingActivation ? 'disabled' : ''"
              >{{
                ("common.deactivate" | translate) +
                  " " +
                  ("common.user" | translate)
              }}</a
            >
            <a
              *ngIf="toggleActive === false"
              class="reset-link"
              (click)="activateSystemUser()"
              [ngStyle]="{ 'margin-right': '32px', 'margin-top': '4px' }"
              href="javascript:void(0)"
              [ngClass]="processingActivation ? 'disabled' : ''"
              >{{
                ("common.reactivate" | translate) +
                  " " +
                  ("common.user" | translate)
              }}</a
            >
          </ng-container>
          <boxxds-button
            *ngIf="!showSpinner"
            [buttonText]="'Save changes' | translate"
            btnType="primary-outlined"
            type="submit"
            [sz]="'sm'"
            [btnStyle]="{ padding: '8px 28px' }"
            (handleClick)="onSubmit(insuredUserForm.value)"
            [isDisabled]="toggleActive === false"
          >
          </boxxds-button>
          <button
            *ngIf="showSpinner"
            class="btn-processing"
            tabindex="0"
            [ngStyle]="{
              border: '1px solid #FFBE3D',
              borderRadius: '8px',
              width: '150px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '4px 8px',
              background: '#FCF8EF'
            }"
          >
            <boxxds-spinner></boxxds-spinner>
          </button>
        </div>

        <div class="timestamp">
          <app-timestamp
            *ngIf="currentSystemUser"
            label="Created by"
            value="{{ currentSystemUser.createdBy }} , {{
              currentSystemUser.createdDt
            }}"
            [containerStyle]="{
              marginLeft: '8px',
              position: 'relative',
              top: '-6px',
              textTransform: 'uppercase'
            }"
          ></app-timestamp>
          <app-timestamp
            *ngIf="
              currentSystemUser &&
              currentSystemUser.updatedBy &&
              currentSystemUser.updatedDt
            "
            label="Updated last by"
            value="{{ currentSystemUser.updatedBy }} , {{
              currentSystemUser.updatedDt
            }}"
            [containerStyle]="{
              marginLeft: '8px',
              position: 'relative',
              top: '-20px',
              textTransform: 'uppercase'
            }"
          ></app-timestamp>
        </div>
      </form>
    </div>
  </ng-container>
</div>
