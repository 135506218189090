import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alert.service';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { NewQuoteService } from 'src/app/services/new-quote.service';
import {
  getAlertHead,
  getErrorMessage,
  removeDuplicatedKeys,
  toSentenceCase,
} from 'src/app/utils/utils';
import * as objects from '../policy-lifecycle-v2/lifecycle-v2-object';
import { ActivatedRoute, Router } from '@angular/router';
import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import {
  Observable,
  combineLatest,
  firstValueFrom,
  lastValueFrom,
  take,
} from 'rxjs';
import { DomainsService } from 'src/app/services/domains.service';
import { DropdownListDto } from 'src/app/dtos/dropdownList.dto';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { PolicyPeriodService } from 'src/app/services/policy-period.service';
import { PolicyDashboardService } from 'src/app/services/policy-dashboard.service';
import { BrokerageBranchService } from 'src/app/services/brokerage-branch.service';
import {
  formatDate,
  formatDateTime,
  getDateFormatForDatePicker,
  getFormatDate,
  getFormattedDateTime,
} from 'src/app/utils/formatDate';
import { PolicyService } from 'src/app/services/policy.service';
import { PolicyAnswerService } from 'src/app/services/policy-answer.service';
import { RiskQuestionService } from 'src/app/services/risk-question.service';
import {
  DOC_GENERATION_WAIT_TIME,
  GENERATE_DOC_SUCCESS_STATUS_CODE,
  STANDALONE_SELLABLE_TRUE,
  UNDERWRITER,
} from 'src/app/constants/quote-constant';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import { LocationService } from 'src/app/services/location.service';
import { LanguageService } from 'src/app/services/language.service';
import { InsuredService } from 'src/app/services/insured.service';
import { PolicyRiskService } from 'src/app/services/policy-risk.service';
import * as utils from '../../dashboard/utils/lifecycle-utils';
import {
  setQuoteStatus,
  setTransactionLvlQuoteStatus,
} from 'src/app/store/lifecycle/lifecycle.action';
import { TransactionRiskActions } from 'src/app/dashboard/constants/lifecycle-actions';
import { PolicyRiskTrxService } from 'src/app/services/policy-risk-trx-service';
import { BoxxUser } from 'src/app/entities/boxx-user';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import { getPolicyStatusTypeV2 } from 'src/app/constants/policyStatus';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Breadcrumb } from 'src/app/models/breadcrumb.model';
import {
  PolicyDeclineRequest,
  PolicyCloseRequest,
  PolicyReferRequest,
} from 'src/app/entities/policy-lifecycle';
import { PolicyLifecycleService } from 'src/app/services/policy-lifecycle-service';
import { DocumentTemplateService } from 'src/app/services/doc-template.service';
import { GenerateQuoteService } from 'src/app/services/doc-create.service';
import { PolicyRiskDocService } from 'src/app/services/policy-risk-doc.service';
import { ConfMessageTemplateService } from 'src/app/services/message-template-services';
import { MessageSendService } from 'src/app/services/message-send.service';
import { Quote } from 'src/app/models/quote.model';
import {
  getQuoteSelector,
  getUIData,
} from 'src/app/store/create-quote/create-quote.selector';
import { SharedFunctionService } from 'src/app/services/shared/shared-function.service';

@Component({
  selector: 'app-quote-lifecycle-v2',
  templateUrl: './quote-lifecycle-v2.component.html',
  styleUrls: ['./quote-lifecycle-v2.component.less'],
})
export class QuoteLifecycleV2Component implements OnInit {
  form$: Observable<Quote>;
  details: objects.PolicyDetail;
  showBodySpinner: boolean = false;
  policyId: string;
  currency = '';
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  policyRiskId;
  isQuote4Flow: boolean = false;
  isWorkFlow3: boolean = false;
  shortDateFormat: string = '';
  longDateFormat: string = '';
  shortDateTimeFormat: string = '';
  longDateTimeFormat: string = '';
  hasError: boolean = false;
  errorMessage: string = '';
  insuredData = {};
  private policyStatus: DropdownListDto[];
  private quoteStatus: DropdownListDto[];
  private riskTrkType: DropdownListDto[];
  private userId: number;
  dataFromAPI = [];
  options = [];
  subNavData = [];
  policyPeriodId: any;
  currentQuoteStatus: string = '';
  actionPopupDetails: {
    quoteNumber: string;
    effectiveDate: string;
    insured: string;
    brokerage: string;
    branch: string;
    reasonOptions: any[];
    action: TransactionRiskActions;
  };
  quoteOptionList = [];
  insuredDetail;

  transactionType: string = '';
  currentProductDetails: any = {};
  openFooter: boolean = false;
  riskTrxTypes: any[];
  showActionPopup: boolean = false;
  public transactionRiskActions = TransactionRiskActions;
  productForm: FormGroup;

  itemsMenu: Breadcrumb[] = [
    {
      label: 'Quote Directory',
      path: '/dashboard/workflow3/quotes',
    },
    { label: 'New Policy', path: null },
  ];
  showNotesPopup: boolean = false;
  showDocTempSelectionPopup: boolean = false;
  showSendModal: boolean = false;
  docPopupDetails: {
    statusType: string;
    action: any;
    documentTemplate: any;
    policyPeriodId: any;
    stageId: any;
    policyRiskId: any;
  };
  alertErrMsg = [];
  stageId: number;
  showErrorAlert: boolean = false;
  docDetails: { [x: string]: string | number | boolean }[] = [];
  rowDocDetails: { [x: string]: string | number | boolean } = {};
  messageTypeId: number;
  newTimeout: NodeJS.Timeout;
  isPolicyInvoiced;
  templateDetails: { [x: string]: string | number }[] = [];
  regionId: Number;
  isLoader: boolean = false;
  hasTemplatePopup: boolean = false;
  showSlideOut: boolean = false;
  quoteFlowSteps;
  showChangeProducerPopup: boolean = false;
  uiData: any;
  isLoading: boolean = true;
  createdAt: string = '';
  createdBy: string = '';

  constructor(
    private localStorageService: LocalStorageService,
    private policyRiskTrxService: PolicyRiskTrxService,
    private store: Store,
    private translate: TranslateService,
    private alertService: AlertService,
    private newQuoteService: NewQuoteService,
    private activatedRoute: ActivatedRoute,
    private domainsService: DomainsService,
    private policyPeriodService: PolicyPeriodService,
    private policyDashboardService: PolicyDashboardService,
    private brokerageBranchService: BrokerageBranchService,
    private policyService: PolicyService,
    private policyAnswerService: PolicyAnswerService,
    public riskQuestionService: RiskQuestionService,
    public locationService: LocationService,
    private riskRegionService: RiskRegionService,
    private languageService: LanguageService,
    private insuredService: InsuredService,
    private policyRiskService: PolicyRiskService,
    private boxxUserService: BoxxUserService,
    private fb: FormBuilder,
    private router: Router,
    private policyLifecycleService: PolicyLifecycleService,
    private DocumentTemplateService: DocumentTemplateService,
    private generateQuoteService: GenerateQuoteService,
    private policyRiskDocService: PolicyRiskDocService,
    private messageTemplateService: ConfMessageTemplateService,
    private messageSendService: MessageSendService,
    private sharedService: SharedFunctionService,
  ) {
    this.productForm = this.fb.group({
      product: [''],
    });
  }

  async ngOnInit() {
    let alertData;
    const closedAlertStatus = this.newQuoteService.getClosedAlertStatus();
    if (closedAlertStatus) {
      alertData = {
        type: 'success',
        headerText: getAlertHead('success'),
        bodyText: this.translate.instant('policy.declined.success.body'),
      };
      this.newQuoteService.setClosedAlertStatus(false);
    }

    this.showBodySpinner = true;
    this.policyId = this.activatedRoute.snapshot.paramMap.get('id');

    this.store.pipe(select(getDashboardSelector)).subscribe((data: any) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.isQuote4Flow =
        this.localStorageService.getQuoteFlowSteps() === '4' ? true : false;
      this.isWorkFlow3 =
        this.localStorageService.getQuoteFlowSteps() === 'workflow3';
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
      this.shortDateTimeFormat = data.shortDateTimeFormat;
      this.longDateTimeFormat = data.longDateTimeFormat;
    });
    this.itemsMenu[1] = {
      label: `${this.policyId}`,
      path: null,
    };
    if (this.policyId) {
      await this.getPolicyData(this.policyId);
    }
    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));
    this.userId = this.localStorageService.getBoxxUserId();

    this.docPopupDetails = {
      statusType: 'Document template selection',
      action: 'docTempSelection',
      documentTemplate: [],
      policyPeriodId: 0,
      stageId: 0,
      policyRiskId: 0,
    };
    this.getStageId();
    this.form$ = this.store.pipe(select(getQuoteSelector));
    this.form$
      .pipe(take(1))
      .subscribe(
        (event) => (this.policyPeriodId = Number(event.ui.policyPeriodId)),
      );
    let products;
    this.form$.subscribe((event) => {
      products = event.products;
      this.regionId = event.ui.regionId;
    });
    this.store.pipe(select(getUIData)).subscribe((data) => {
      this.uiData = data;
    });

    if (this.uiData?.isNavigatedFromChangeProducerPopup) {
      this.showChangeProducerPopup = true;
    }

    await this.populateDomainLists();

    await this.populateData();
    if (alertData) {
      this.alertService.addAlert(alertData);
    }
  }

  private async populateData() {
    this.showBodySpinner = true;
    const policy$ = this.policyService.GetByPolicyId(this.policyId);
    const risk$ = this.policyRiskService.GetByPolicyId(this.policyId);
    const peroid$ = this.policyPeriodService.GetAllByPolicyId(this.policyId);

    combineLatest([policy$, risk$, peroid$]).subscribe({
      next: ([policy, risk, period]) => {
        this.populateDataFromApi(policy.data, risk.data[0], period.data);
      },
      error: (error) => {
        this.handleApiError(error);
        this.showBodySpinner = false;
      },
    });
  }

  private async populateDataFromApi(policy: any, risk: any, peroid: any) {
    await this.getRegionId(policy?.insured?.id);
    const status = this.getPolicyStatus(policy.policyStatus);
    const firstBindDate = policy.firstBindDt
      ? getFormattedDateTime(policy.firstBindDt, this.longDateFormat)
      : 'n/a';
    const policyPeriod = risk?.policyPeriod?.effectiveExpiryDatesTBDFlag
      ? 'TBD'
      : utils.getRiskPeriod(risk, this.longDateFormat);
    const policyId = utils.getPolicyNumber(policy);
    const insuredName = utils.getInsuredName(policy.insured);
    this.currentQuoteStatus = status !== 'n/a' ? status.toLowerCase() : '';
    this.store.dispatch(new setQuoteStatus(this.currentQuoteStatus));
    const insuredId = policy?.insured?.id;

    let mailIdList = {
      broker: policy?.brokerageBOR?.email ?? '',
      branch: policy?.brokerageBranchBOR?.email ?? '',
      producer: policy?.brokerageProducerBOR?.email ?? '',
      insured: policy?.insured?.email ?? '',
    };
    this.store.dispatch(new CreateQuoteAction.setAllMailId(mailIdList));

    this.store.dispatch(
      new CreateQuoteAction.setPolicyPeriodId(risk?.policyPeriodId),
    );
    this.policyPeriodId = risk?.policyPeriodId;
    const brokerData = {
      branch: policy?.brokerageBranchBOR?.name,
      broker: policy?.brokerageBOR?.name,
      producer: `${policy?.brokerageProducerBOR?.firstName} ${policy?.brokerageProducerBOR?.lastName}`,
    };
    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({ ...brokerData }),
    );
    this.store.dispatch(
      new CreateQuoteAction.ResetIsNavigatedFromLifecycle(true),
    );
    const product = [risk.risk];

    this.details = {
      header: status,
      currentPeriod: this.getPeroid(risk.policyPeriod),
      id: policyId,
      policyRiskTrxId: 0,
      latestPolicyRiskTrxId: 0,
      policyRiskTrxIds: '',
      userId: this.userId,
      status: toSentenceCase(status),
      brokerage: policy.brokerageBOR.name,
      branch: policy.brokerageBranchBOR.name,
      producer: `${policy.brokerageProducerBOR.firstName} ${policy.brokerageProducerBOR.lastName}`,
      firstBindDate: firstBindDate,
      policyPeriod: policyPeriod,
      insuredName: insuredName,
      isUnreadNotesPresent: false, // TODO: If notes are created between today and 3 days ago = true
      isInvoiced: false,
      product: product,
      currentTagOptions: [],
      policyPeriodId: this.policyPeriodId,
      insuredId: insuredId,
      policyRiskId: this.policyRiskId,
      industry: this.insuredDetail.industry.name,
      revenue: this.insuredDetail.revenue,
    };

    // Policy Level Actions
    this.actionPopupDetails = {
      quoteNumber: policyId,
      effectiveDate: formatDate(
        risk.policyPeriod.effectiveDt,
        this.longDateFormat,
      ),
      insured: insuredName,
      brokerage: policy.brokerageBOR.name,
      branch: policy.brokerageBranchBOR.name,
      reasonOptions: [],
      action: TransactionRiskActions.None,
    };

    const latestTransactionTypeIndex = risk.PolicyRiskTrxes.length - 1;
    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        transactionType:
          risk.PolicyRiskTrxes[latestTransactionTypeIndex].policyRiskTrxType
            .description,
        transactionTypeId: Number(
          risk.PolicyRiskTrxes[latestTransactionTypeIndex].type,
        ),
      }),
    );
    this.quoteOptionList =
      risk.PolicyRiskTrxes[latestTransactionTypeIndex].QuoteOptions;

    this.dataFromAPI = risk.PolicyRiskTrxes.map((trx) => ({
      risk: risk.risk.name,
      active: risk.risk.active,
      quoteId: policyId,
      quoteOption:
        risk.PolicyRiskTrxes[latestTransactionTypeIndex].QuoteOptions,
      policyRiskTrxId: trx.id,
      limit: trx.limit,
      premium: trx.premium,
      deductible: trx.deductible,
      invoiceNumber: trx.invoiceNumber,
      trnxDate: formatDate(policy.createdDt, this.longDateFormat),
      underwriter: trx.underwriterId,
      annualPremium: utils.calculateAnnualAmount(trx),
      status: this.getQuoteOrRiskTrxStatus(trx),
      reason: trx.notes,
      trxReasons: trx?.PolicyRiskTrxReasons,
      policyRiskId: this.policyRiskId,
      triaAmount: trx?.triaAmount,
      policyFee: trx?.policyFee,
      taxesServicesAmount: trx?.taxesServicesAmount,
      servicesAmount: trx?.servicesAmount,
      taxesPremiumAmount: trx?.taxesPremiumAmount,
      transactionEffectiveDate: trx?.transactionEffectiveDate
        ? formatDate(trx?.transactionEffectiveDate, this.longDateFormat)
        : undefined,
      trxId: trx?.id,
    }));
    let startDate = formatDate(
      risk.policyPeriod.effectiveDt,
      this.longDateFormat,
    );
    this.store.dispatch(
      new setTransactionLvlQuoteStatus(this.dataFromAPI?.[0]?.status ?? ''),
    );

    let endDate = formatDate(risk.policyPeriod.expiryDt, this.longDateFormat);
    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        annualPremium: this.dataFromAPI?.[0]?.['annualPremium'] ?? null,
        deductible: this.dataFromAPI?.[0]?.['deductible'] ?? null,
        premium: this.dataFromAPI?.[0]?.['premium'] ?? null,
        startDate:
          getFormatDate(startDate, this.longDateFormat, this.shortDateFormat) ??
          '',
        endDate:
          getFormatDate(endDate, this.longDateFormat, this.shortDateFormat) ??
          '',
        quoteCreatedDate: peroid[0]?.createdDt ?? '',
      }),
    );

    for await (const item of this.dataFromAPI) {
      let underWriterId = item.underwriter;
      const underWriterData = await this.sharedService.updateUnderwriter(
        underWriterId,
        item?.trxId,
      );
      underWriterId = underWriterData?.underwriterId;
      item.underwriter = await this.getUnderwriter(item.underwriter);
    }

    // Populate Period drop down
    this.options = peroid.map((prd) => ({
      key: this.getPeroid(prd),
      value: this.getPeroid(prd),
    }));

    // Populate Tabs
    this.subNavData = this.dataFromAPI.map((product, index) => ({
      name: product.risk,
      policyRiskTrxId: product.policyRiskTrxId,
      active: index == 0,
      currentValue: 0,
      value: index,
    }));
    this.productForm.get('product').setValue(0);

    this.details.policyRiskTrxId = this.dataFromAPI[0].policyRiskTrxId;
    this.details.latestPolicyRiskTrxId = this.dataFromAPI[0].policyRiskTrxId;
    this.details.policyRiskTrxIds = risk.PolicyRiskTrxes.map((p) => p.id).join(
      ',',
    );
    const policyRiskTrxs = await this.getPolicyRiskTrxesFromApi(risk.id);

    this.createdAt = formatDateTime(
      policyRiskTrxs.data[0].createdDt,
      this.longDateTimeFormat,
    );
    this.createdBy = policyRiskTrxs.data[0].createdBy;

    let trxTypes = policyRiskTrxs.data?.map((dataObj) => ({
      key: dataObj.type,
      value: dataObj.type,
      id: dataObj.id,
    }));

    trxTypes = removeDuplicatedKeys(trxTypes);

    this.details.currentTagOptions = trxTypes;
    this.transactionType = this.dataFromAPI[0].status.toLowerCase();
    this.showBodySpinner = false;
    await this.getPdtDetails();
  }
  private async populateDomainLists() {
    try {
      this.policyStatus =
        await this.domainsService.GetDomainListAsync('POLICYSTATUS');
      this.quoteStatus =
        await this.domainsService.GetDomainListAsync('QUOTESTATUS');
      this.riskTrkType =
        await this.domainsService.GetDomainListAsync('POLICYRISKTRXTYPE');
    } catch (error) {
      this.handleApiError(error);
    }
  }
  async getPolicyData(quoteID) {
    const getPolicyByID = this.policyPeriodService.GetAllByPolicyId(quoteID);
    let dataPId = await firstValueFrom(getPolicyByID);
    const policyPeriodId = dataPId.data[0].id;
    const policyDashboardServiceObs =
      this.policyDashboardService.Get(policyPeriodId);
    const policyDashboardData = await firstValueFrom(policyDashboardServiceObs);
    const insured = policyDashboardData.data.policy.insured;
    const insuredServiceObs = this.insuredService.Get(insured.id);
    let insuredData = await firstValueFrom(insuredServiceObs);
    const locationId = insuredData.data.insuredLocation?.id;
    const getLocation = this.locationService.Get(locationId);
    let locationData = await firstValueFrom(getLocation);
    const getLanguage = this.languageService.getLanguageId();
    let langData = await firstValueFrom(getLanguage);
    const languageId = langData.data[0]?.id;
    const regionId = locationData.data.region.id;

    const getRiskVersion = this.riskRegionService.getRiskRegionAndVersion(
      regionId,
      insured.insuredType,
      STANDALONE_SELLABLE_TRUE,
    );
    let riskRegion = await firstValueFrom(getRiskVersion);
    const policyRisks = policyDashboardData.data.PolicyRisks;
    let products = await this.makeProducts(riskRegion.data, policyRisks);
    //UPDATE STORE UI with PRODUCTS
    this.store.dispatch(
      CreateQuoteAction.loadProductsSuccess({ products: products }),
    );

    riskRegion?.data.forEach(async (riskRegion) => {
      const getQuestions = this.riskQuestionService.GetByRegionId(
        riskRegion.id,
        languageId,
        insured.id,
        500,
      );
      let dataQuestions = await firstValueFrom(getQuestions);
      const questionAnswers = dataQuestions?.data;

      const policyRiskData = policyDashboardData.data.PolicyRisks.filter(
        (policyRisk) => policyRisk.risk.id == riskRegion.risk.id,
      );
      if (policyRiskData.length > 0) {
        const policyRiskId = policyRiskData[0].id;
        this.policyRiskId = policyRiskId;
        const policyRiskValue = policyRiskData[0].risk.name;
        const policyQuestionList =
          this.policyAnswerService.GetAllByQuestionIdAndPolicyRiskId(
            policyRiskId,
            1,
            500,
          );
        let dataPolicyQuestionList = await firstValueFrom(policyQuestionList);
        const policyQuestionLists = dataPolicyQuestionList?.data;
        const selectedQuestions = questionAnswers.map((questions) => {
          const policyQuestionList = policyQuestionLists.find(
            (policyQuestions) =>
              policyQuestions.riskQuestionId === questions.id,
          );

          return {
            ...questions,
            value: policyQuestionList?.riskAnswer
              ? policyQuestionList?.riskAnswer
              : null,
          };
        });
        //UPDATE STORE UI with Question Answers
        this.store.dispatch(
          CreateQuoteAction.loadRiskQuestionsSuccess({
            riskQuestions: { [policyRiskValue]: selectedQuestions },
          }),
        );
      }
    });

    const getPolicyServiceObs = this.policyService.Get(quoteID);
    const policyData = await firstValueFrom(getPolicyServiceObs);
    const policyRiskTrxId =
      policyDashboardData.data.PolicyRisks[0].PolicyRiskTrxes[0].id;

    const brokerData = {
      branch: policyData.data.brokerageBranchBOR.name,
      broker: policyDashboardData.data.policy.brokerageBOR.name,
      producer: this.getBrokerNames(
        policyDashboardData.data.policy.brokerageProducerBOR,
      ),
    };
    const startDate = getDateFormatForDatePicker(
      policyDashboardData.data.effectiveDt,
      this.shortDateFormat,
    );
    const endDate = getDateFormatForDatePicker(
      policyDashboardData.data.expiryDt,
      this.shortDateFormat,
    );
    //UPDATE STORE DROPDOWNS with DATES
    //UPDATE STORE DROPDOWNS with BROKER
    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        startDate: startDate,
        endDate: endDate,
        ...brokerData,
      }),
    );

    const branchObs = this.brokerageBranchService.GetBranchByBrokerageId(
      policyDashboardData.data.policy.brokerageBORId,
      1,
      10000000,
    );
    let branches = await firstValueFrom(branchObs);
    const branch = branches.data.filter(
      (b) => b.name === policyData.data.brokerageBranchBOR.name,
    )[0];

    this.insuredData = {
      brokerageId: Number(policyDashboardData.data.policy.brokerageBORId),
      branchId: Number(branch.id),
      producerId: Number(
        policyDashboardData.data.policy.brokerageProducerBORId,
      ),
      brokerageName: policyDashboardData.data.policy.brokerageBOR.name,
      regionId,
    };
    //UPDATE STORE UI with BROKER
    this.store.dispatch(
      new CreateQuoteAction.UpdateInsuredAction({
        brokerageId: Number(policyDashboardData.data.policy.brokerageBORId),
        branchId: Number(branch.id),
        producerId: Number(
          policyDashboardData.data.policy.brokerageProducerBORId,
        ),
        broker: policyDashboardData.data.policy.brokerageBOR.name,
        regionId,
      }),
    );

    //UPDATE STORE UI with PRODUCTS
    this.store.dispatch(
      CreateQuoteAction.loadProductsSuccess({ products: products }),
    );

    let isInsuredTypeCompany = true;
    if (insured.insuredType == 1) isInsuredTypeCompany = false;
    this.store.dispatch(
      new CreateQuoteAction.UpdateInsuredAction({
        insuredId: Number(insured.id),
        insuredName: this.getInsuredName(insured),
        policyId: Number(quoteID),
        saveAtomicTrx: true,
        policyRiskTrxId: Number(policyRiskTrxId),
        insuredEmail: insuredData.data.email,
        revenue: insuredData.data.revenue,
        noOfEmployees: insuredData.data?.numberOfEmployeesDomain?.description,
        industryClass: insuredData.data.industryClass.name,
        industry: insuredData.data.industry.name,
        address: insuredData.data.insuredLocation.fullAddress,
        isInsuredTypeCompany: isInsuredTypeCompany,
        policyPeriodId: Number(policyPeriodId),
      }),
    );

    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({ policyId: quoteID }),
    );
  }

  async makeProducts(data, policyRisk) {
    const tableDataPromises = data?.map(async (item, index, arr) => {
      const policyRiskData = policyRisk.filter(
        (policyRisk) => policyRisk.risk.id == item.risk.id,
      );
      let underwriter = '';
      let isChecked = false;
      let underwriterId;
      if (policyRiskData.length > 0) {
        const underwriterObj =
          policyRiskData[0]?.PolicyRiskTrxes[0]?.underwriter;
        underwriter =
          underwriterObj?.firstName + ' ' + underwriterObj?.lastName;
        underwriterId = underwriterObj?.id;
        isChecked = true;
      }

      let hasSubProduct = false,
        subProductName = '',
        subProductRiskId = null;
      let hasParentProduct = false,
        parentRiskId = null;
      if (item.risk?.standaloneSellable == 1) {
        const getSubProduct = this.riskRegionService.getBySellableWithRiskId(
          item.risk.id,
        );
        const getSubProductResponse = await firstValueFrom(getSubProduct);

        if (getSubProductResponse?.data?.length > 0) {
          hasSubProduct = true;
          subProductName = getSubProductResponse?.data[0]?.name;
          subProductRiskId = getSubProductResponse?.data[0]?.id;
        }
      } else {
        const getParentProduct = this.riskRegionService.getRiskById(
          item.risk.id,
        );
        const getParentProductResponse = await firstValueFrom(getParentProduct);

        if (
          getParentProductResponse?.data?.id &&
          getParentProductResponse?.data?.sellableWithRiskId
        ) {
          hasParentProduct = true;
          parentRiskId = getParentProductResponse?.data?.id;
        }
      }

      return {
        key: item.risk.name,
        value: item.risk.name,
        description: item.risk.description,
        checked: isChecked,
        active: true,
        addons: [],
        underwriter: underwriter,
        riskId: item.risk.id,
        underwriterId: underwriterId,
        standaloneSellable:
          item.risk?.standaloneSellable === STANDALONE_SELLABLE_TRUE,
        hasSubProduct: hasSubProduct,
        subProductName: subProductName,
        subProductRiskId: subProductRiskId,
        hasParentProduct: hasParentProduct,
        parentRiskId: parentRiskId,
      };
    });

    // Wait for all the promises to resolve
    const resolvedTableData = await Promise.all(tableDataPromises);

    return resolvedTableData;
  }

  private getPeroid(period: any): string {
    if (period.period) {
      return period.period;
    }
    if (period.effectiveDt) {
      return new Date(period.effectiveDt).getFullYear().toString();
    }
    return 'n/a';
  }

  getInsuredName(insured) {
    if (insured.insuredType == 2) {
      return insured.companyName;
    } else {
      let name = insured.firstName + ' ';
      name += insured.middleName ? insured.middleName : '';
      name += ' ' + insured.lastName;
      return name;
    }
  }

  getBrokerNames(dataObj) {
    return dataObj.firstName + ' ' + dataObj.lastName;
  }

  handleApiError(error: any) {
    if (![500].includes(error?.status)) {
      this.hasError = true;
    }

    if (typeof error === 'string' || error instanceof String) {
      if (error?.toLowerCase().includes('error code: 404', 0)) {
        this.errorMessage = this.translate.instant(
          'common.error.requestedQuoteNotFound',
        );
        const alertData = {
          show: true,
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: this.errorMessage,
        };
        this.alertService.addAlert(alertData);
        return;
      }
    }
    this.errorMessage = getErrorMessage(error);
    const alertData = {
      show: true,
      type: 'error',
      headerText: getAlertHead('error'),
      bodyText: this.errorMessage,
    };
    this.alertService.addAlert(alertData);
  }

  private getPolicyStatus(id: number): string {
    return utils.getValueFromList(this.policyStatus, id);
  }

  private getQuoteOrRiskTrxStatus(policyRiskTrx: any): string {
    return policyRiskTrx.quoteStatus
      ? this.getQuoteStatus(policyRiskTrx.quoteStatus)
      : this.getRiskTrkType(policyRiskTrx.type);
  }

  handleShowPeriod(value) {}

  getPdtDetailsByIndex(index) {
    return this.dataFromAPI[index];
  }

  async getPdtDetails() {
    const currentActiveIdx = this.getActiveTabIndex();
    this.currentProductDetails = this.dataFromAPI[currentActiveIdx];
  }
  private getActiveTabIndex(): number {
    return this.subNavData.findIndex((navObj) => navObj.active);
  }
  private async getUnderwriter(id: number): Promise<string> {
    const user: any = await this.getBoxxUserFromApi(id);
    if (user) {
      this.store.dispatch(
        new CreateQuoteAction.setAllMailId({
          underwriter: user?.systemUser?.loginEmail
            ? [user.systemUser.loginEmail]
            : [],
        }),
      );
      return `${user.firstName} ${user.lastName}`;
    }

    return Promise.resolve('n/a');
  }

  private async getBoxxUserFromApi(userId: number): Promise<BoxxUser> {
    try {
      const user$ = this.boxxUserService.GetById(userId);
      const user = await lastValueFrom(user$);
      return user.data;
    } catch (error) {
      return null;
    }
  }
  private getQuoteStatus(id: number): string {
    return utils.getValueFromList(this.quoteStatus, id);
  }

  private getRiskTrkType(id: number): string {
    return utils.getValueFromList(this.riskTrkType, id);
  }

  private async getPolicyRiskTrxesFromApi(policyRiskId: number): Promise<any> {
    const riskTrx$ = this.policyRiskTrxService.GetByPolicyRiskId(policyRiskId);
    const riskTrx = await lastValueFrom(riskTrx$);

    return riskTrx;
  }

  async handleNav(event, index) {
    const currentActiveIdx = this.getActiveTabIndex();
    this.subNavData[currentActiveIdx].active = false;
    this.subNavData[index].active = true;

    this.details.policyRiskTrxId = this.dataFromAPI[index].policyRiskTrxId;
    await this.getPdtDetails();
  }

  getPolicyStatuses() {
    return new Promise<any>(async (resolve, reject) => {
      this.domainsService.GetByDomainCode('POLICYRISKTRXTYPE').subscribe({
        next: (data) => {
          let riskTypeList = [];
          data.data.map((dataObj) => {
            if (
              dataObj.description !== 'QUOTE' &&
              dataObj.description !== 'QUOTE-CLOSED'
            ) {
              riskTypeList = [
                ...riskTypeList,
                {
                  description: dataObj.description,
                  status: getPolicyStatusTypeV2(dataObj.description),
                  seqId: dataObj.seqId,
                  id: dataObj.id,
                },
              ];
            }
          });
          this.riskTrxTypes = riskTypeList;
          resolve(true);
        },
        error: (error) => {
          reject(false);
        },
      });
    });
  }
  setPolicyStatusType(type) {
    const filteredArray = this.riskTrxTypes.filter((item) => item.id === type);

    const valuesOfRenewal = filteredArray.map((item) => item.description);

    return valuesOfRenewal[0];
  }

  handleRiskAction(action: TransactionRiskActions) {
    this.actionPopupDetails.action = action;
    this.showActionPopup = true;
  }
  getStatusName(status) {
    return objects.getStatusName(status);
  }
  getStatusType(status: string) {
    switch (status.toLowerCase()) {
      case 'new submissions':
      case 'submission':
      case 'reinstated':
      case 'bound':
      case 'renewed':
      case 'renewal quote':
      case 'renewal app':
      case 'quote':
      case 'quoted':
      case 'change-saved':
      case 'policy changed':
      case 'executed':
      case 'policy changed':
      case 'policy change':
      case 'change-saved':
      case 'executed':
        return 'success';
      case 'referral':
        return 'warning';
      case 'indicated':
        return 'warning-type-2';
      case 'endrs-saved':
      case 'not taken up':
        return 'secondary';
      case 'quote closed':
      case 'cancelled':
      case 'lapsed':
      case 'declined':
      case 'closed':
        return 'error';
    }
    return 'default';
  }
  async handleBind() {
    await this.getPdtDetails();
    let product = this.currentProductDetails;
    if (product?.['premium'] > 0) {
      if (this.isQuote4Flow) {
        this.router.navigate(['dashboard/quote4flow/policy-configuration'], {
          skipLocationChange: true,
        });
      } else if (this.isWorkFlow3) {
        this.router.navigate(['dashboard/workflow3/policy-configuration'], {
          skipLocationChange: true,
        });
      } else {
        this.router.navigate(['../summary/product/'], {
          relativeTo: this.activatedRoute,
          skipLocationChange: true,
        });
      }
    } else {
      if (this.isQuote4Flow) {
        this.router.navigate(
          ['dashboard/quote4flow/quote-calculator-and-options'],
          {
            skipLocationChange: true,
          },
        );
      } else if (this.isWorkFlow3) {
        this.router.navigate(['dashboard/workflow3/quoting'], {
          skipLocationChange: true,
        });
      } else {
        this.router.navigate(['../new/options/'], {
          relativeTo: this.activatedRoute,
          skipLocationChange: true,
        });
      }
    }
  }

  async getRegionId(insuredId) {
    return new Promise<any>(async (resolve, reject) => {
      this.insuredService.GetByInsuredId(insuredId).subscribe((insuredData) => {
        // this.regionId =
        //   insuredData?.data?.insuredLocation?.regionId ??
        //   insuredData?.data?.insuredLocation?.region?.id;
        this.insuredDetail = insuredData?.data;
        // this.store.dispatch(
        //   new CreateQuoteAction.UpdateInsuredAction({
        //     regionId: this.regionId,
        //     timezone:
        //       insuredData?.data?.insuredLocation?.timeZoneCode ??
        //       'America/New_York',
        //   }),
        // );
        resolve(true);
      });
    });
  }
  handleSeeQuoteOptions() {
    let url = '/dashboard/workflow3/quoting/' + this.details.id;

    this.router.navigate([url], {
      skipLocationChange: true,
    });
  }
  handleConfirmRiskAction(event: {
    action: TransactionRiskActions;
    selectedReasonId: number;
  }) {
    const PolicyDeclineRequest: PolicyDeclineRequest = {
      policyPeriodId: Number(this.policyPeriodId),
      declineReason: Number(event.selectedReasonId),
    };
    const policyCloseRequest: PolicyCloseRequest = {
      policyPeriodId: Number(this.policyPeriodId),
      closeReason: Number(event.selectedReasonId),
    };
    const policyReferRequest: PolicyReferRequest = {
      policyPeriodId: Number(this.policyPeriodId),
      referralReason: Number(event.selectedReasonId),
    };
    switch (event.action) {
      case TransactionRiskActions.Close:
        this.policyLifecycleService
          .TransactionRiskClose(policyCloseRequest)
          .subscribe({
            next: (response) => {
              this.handleRiskActionApiResponse(
                TransactionRiskActions.Close,
                response,
              );
            },
            error: (e) => {
              this.handleApiError(e);
            },
            complete: () => {
              this.handleRiskActionComplete();
            },
          });
        break;
      case TransactionRiskActions.Decline:
        this.policyLifecycleService
          .TransactionRiskDecline(PolicyDeclineRequest)
          .subscribe({
            next: (response) => {
              this.handleRiskActionApiResponse(
                TransactionRiskActions.Decline,
                response,
              );
            },
            error: (e) => {
              this.handleApiError(e);
            },
            complete: () => {
              this.handleRiskActionComplete();
            },
          });
        break;
      case TransactionRiskActions.Refer:
        this.policyLifecycleService
          .TransactionRiskRefer(policyReferRequest)
          .subscribe({
            next: (response) => {
              this.handleRiskActionApiResponse(
                TransactionRiskActions.Refer,
                response,
              );
            },
            error: (e) => {
              this.handleApiError(e);
              this.showActionPopup = false;
            },
            complete: () => {
              this.handleRiskActionComplete();
            },
          });
        break;
      default:
        const errorMessage = this.translate.instant('Invalid risk action.');
        this.handleApiError(errorMessage);
        this.showActionPopup = false;
        break;
    }
  }
  private handleRiskActionApiResponse(type, response: any) {
    this.showActionPopup = false;
    if (response && type === TransactionRiskActions.Close) {
      const alertData = {
        show: true,
        type: 'success',
        headerText: 'success!',
        bodyText: 'policy.closed.success.body',
      };
      this.alertService.addAlert(alertData);
    } else if (response && type === TransactionRiskActions.Decline) {
      const alertData = {
        show: true,
        type: 'success',
        headerText: 'success!',
        bodyText: 'policy.declined.success.body',
      };
      this.alertService.addAlert(alertData);
    } else if (response && type === TransactionRiskActions.Refer) {
      const alertData = {
        show: true,
        type: 'success',
        headerText: 'success!',
        bodyText: 'policy.referred.success.body',
      };
      this.alertService.addAlert(alertData);
    } else {
      const alertData = {
        show: true,
        type: 'error',
        headerText: 'error!',
        bodyText: this.translate.instant('error.actionIsNotImplementedYet'),
      };
      this.alertService.addAlert(alertData);
    }
  }

  private handleRiskActionComplete() {
    this.showActionPopup = false;
    // if (this.hasSuccess) {
    //   this.hasSuccess = true;
    //   this.hasError = false;
    // } else {
    //   this.hasError = true;
    //   this.hasSuccess = false;
    // }

    this.populateData();
  }
  handleNotesPopupClose($event) {
    this.showNotesPopup = false;
  }
  toggleNotesPopup(e) {
    this.showNotesPopup = !this.showNotesPopup;
    e.stopPropagation();
  }

  async getStageId(subDomain = 'DOCMERGESTAGE_QUOTE') {
    this.stageId = undefined;
    return new Promise<void>((resolve) => {
      this.domainsService.GetByDomainCode('DOCMERGESTAGE', true).subscribe({
        next: (response) => {
          let docStage = response.data.filter(
            (template) => template.subdomaincode === subDomain,
          )[0];
          this.stageId = docStage.id;
          resolve();
        },
        error: (error) => {
          this.isLoader = false;
          this.showSendModal = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
          }
        },
      });
    });
  }

  async handleSendQuote() {
    if (this.currentQuoteStatus == 'quote-closed') {
      this.showErrorAlert = true;
      this.alertErrMsg.push('error.noTemplateAvailable');
      return;
    }
    this.docDetails = [];
    this.docPopupDetails.stageId = this.stageId;
    this.docPopupDetails.policyPeriodId = this.details['policyPeriodId'];
    this.docPopupDetails.policyRiskId = this.details['policyRiskId'];
    if (this.stageId) {
      this.DocumentTemplateService.getDocumentTemplateList(
        this.policyRiskId,
        this.stageId,
      ).subscribe({
        next: (response) => {
          let templateList = response?.data.filter((item) => {
            return item.templates.map(
              (template) => template.docProcessId === null,
            );
          });
          if (templateList.length !== 0) {
            this.hasTemplatePopup = true;
            this.docPopupDetails.documentTemplate = templateList;
            this.showDocTempSelectionPopup = true;
            this.handleDocLevelAction(this.currentQuoteStatus);
            this.isLoader = false;
          } else {
            this.hasTemplatePopup = false;
            this.handleSendPackage();
          }
        },
        error: (error) => {
          this.isLoader = false;
          this.alertErrMsg.push(getErrorMessage(error.error));
        },
      });
    } else {
      return;
    }
  }

  handleDocLevelAction(action) {
    this.docPopupDetails.action = action;
    this.showDocTempSelectionPopup = true;
  }

  handleExit(event) {
    this.showDocTempSelectionPopup = false;
  }

  handleSuccessDocTemplate(event) {
    this.showDocTempSelectionPopup = false;
    this.showSendModal = true;
    this.handleSendPackage();
  }

  docGenFinishHandler() {
    this.isLoader = false;
  }

  async handleSendPackage() {
    this.closeAlert();
    this.docDetails = [];
    this.rowDocDetails = {};
    this.showSendModal = true;
    this.isLoader = !this.isLoader;
    const interval = 5000;
    const startTime = Date.now();
    if (this.showSendModal) {
      await this.populateMessageType();
      if (
        this.docDetails.length === 0 &&
        this.details.header.toLowerCase() === 'quote'
      ) {
        this.generateQuoteService
          .generateQuotePdf(this.details['policyPeriodId'])
          .subscribe({
            next: async (response) => {
              while (
                Date.now() - startTime < DOC_GENERATION_WAIT_TIME &&
                this.rowDocDetails?.['status'] !== 1 &&
                this.isLoader
                // this.rowDocDetails?.['status'] !== 2
                // (this.docDetails.length === 0 ||
                //   this.rowDocDetails?.['status'] === 0)
              ) {
                await this.getDocument();
                await new Promise(
                  (resolve) =>
                    (this.newTimeout = setTimeout(resolve, interval)),
                );
              }
              if (this.docDetails.length === 0) {
                this.showSendModal = false;
                this.isLoader = false;
                this.showErrorAlert = true;
                this.alertErrMsg.push('common.docCreateFailMsg');
              } else {
                await this.getMessageTemplates(this.details?.product[0]?.id);
              }
            },
            error: ({ error }) => {
              this.isLoader = false;
              clearTimeout(this.newTimeout);
              this.showSendModal = false;
              if (![500].includes(error?.status)) {
                this.showErrorAlert = true;
                this.alertErrMsg.push(getErrorMessage(error));
              }
            },
          });
      }
      this.details.header.toLowerCase() !== 'quote' &&
        (await this.getMessageTemplates(this.details?.product[0]?.id));
    }
  }

  closeAlert() {
    this.showNotesPopup = false;
    this.showErrorAlert = false;
  }

  async populateMessageType() {
    this.messageTypeId = undefined;
    let messageType;
    return new Promise<void>((resolve) => {
      this.domainsService.GetByDomainCode('MESSAGETYPE', true).subscribe({
        next: (response) => {
          messageType = response.data.filter(
            (template) => template.subdomaincode === 'MESSAGETYPE_QUOTE',
          )[0];
          this.messageTypeId = messageType.id;
          resolve();
        },
        error: (error) => {
          this.isLoader = false;
          this.showSendModal = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
            this.alertErrMsg.push(getErrorMessage(error));
          }
        },
      });
    });
  }

  async getDocument() {
    this.policyRiskDocService
      .getPolicyRiskGeneratedDocCore(
        this.details['policyPeriodId'],
        this.stageId,
      )
      .subscribe({
        next: (response) => {
          let { data } = response;
          if (Object.entries(data).length === 0) return;

          if (!data.PolicyRiskDocument || data.PolicyRiskDocument.length === 0)
            return;
          // if (response?.data?.status === GENERATE_DOC_SUCCESS_STATUS_CODE) {
          this.docDetails = data.PolicyRiskDocument;
          // } else {
          //   this.docDetails = [];
          // }
          this.rowDocDetails = data;
        },
        error: (error) => {
          this.isLoader = false;
          clearTimeout(this.newTimeout);
          this.showSendModal = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
            this.alertErrMsg.push(getErrorMessage(error));
          }
        },
      });
  }

  async getMessageTemplates(riskId: number) {
    this.messageTemplateService
      .GetAllMessageTemplates(this.messageTypeId, riskId)
      .subscribe({
        next: (response) => {
          this.templateDetails = response?.data ?? [];
          // this.isLoader = false;
        },
        error: ({ error }) => {
          this.isLoader = false;
          this.showSendModal = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
            this.alertErrMsg.push(getErrorMessage(error));
          }
        },
      });
  }

  handleBack() {
    this.showDocTempSelectionPopup = true;
    this.isLoader = false;
    this.showSendModal = false;
  }

  async handleSend(formData: any) {
    formData.append('merginStageId', this.stageId);
    this.messageSendService.sendMessage(formData).subscribe({
      next: (response) => {
        this.docDetails = [];
        this.templateDetails = [];
        this.showSendModal = !this.showSendModal;
        const alertData = {
          type: 'success',
          headerText: getAlertHead('success'),
          bodyText: this.translate.instant('quoteSummary.success.quoteSent'),
        };
        this.alertService.addAlert(alertData);
      },
      error: (error) => {
        this.isLoader = false;
        this.showSendModal = !this.showSendModal;
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
          this.alertErrMsg.push(getErrorMessage(error.error));
        }
      },
    });
  }

  closeSendPackage(isDocGenFailed: boolean = false) {
    if (isDocGenFailed) {
      this.alertService.addAlert({
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: 'common.documentGenerationFailed',
      });
    }
    clearTimeout(this.newTimeout);
    this.closeAlert();
    this.docDetails = [];
    this.templateDetails = [];
    this.showSendModal = false;
    this.isLoader = false;
  }

  handleSlideOutClose() {
    this.showSlideOut = false;
  }

  showQuoteSlideOut(event) {
    this.showSlideOut = true;
  }

  handleSearch(object) {
    if (object.searchIn === 'Insured name') {
      let insuredType = 1;
      if (object?.type?.toLowerCase() == 'company') {
        insuredType = 2;
      }
      this.router.navigate(
        [
          `../../search/${insuredType}/${object.selectedSearch.value}/${object.selectedSearch.id}`,
        ],
        { relativeTo: this.activatedRoute, skipLocationChange: true },
      );
    } else {
      this.router.navigateByUrl(
        `dashboard/workflow3/policies/${object.selectedSearch.value}`,
        { skipLocationChange: true },
      );
    }
  }

  handleStartNewQuote() {
    this.store.dispatch(new CreateQuoteAction.ResetState());
    if (this.quoteFlowSteps === 'workflow3') {
      this.newQuoteService.clearInsuredId();
      this.router.navigate(['../../workflow3'], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
      return;
    } else {
      this.router.navigate([`../../workflow3/new`], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
    }
  }

  changeProducer() {
    this.showChangeProducerPopup = true;
  }
  closeChangeProducer() {
    this.showChangeProducerPopup = false;
  }

  producerChanged(event) {
    this.details['branch'] = event.branch;
    this.details['brokerage'] = event.broker;
    this.details['producer'] = event.producer;
  }

  handleLoading(loaderEvent: any) {
    this.isLoading = loaderEvent.isLoading ? true : false;
  }
}
