import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BoxxUser } from '../entities/boxx-user';
import { BaseService } from './base-services';
import { Observable, retry, catchError } from 'rxjs';
import { BoxxResponse } from '../entities/boxx-response';
import { Policy } from '../entities/policy';
import { CognitoService } from './cognito-service';

@Injectable({
  providedIn: 'root',
})
export class BoxxUserService extends BaseService<BoxxUser> {
  constructor(
    http: HttpClient,
    private cognito: CognitoService,
  ) {
    const baseurl = environment.msSecurityBseUrl + environment.boxxUserPathUrl;
    const token = cognito.getToken();
    super(http, baseurl, token);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  GetUnderwriterlist(
    manager,
    page = 1,
    limit = 10,
    sort = '',
    name = '',
    active = -1,
    roleName = '',
    systemUserState = -1,
  ): Observable<BoxxResponse<BoxxUser>> {
    let url =
      this.baseurl + '?page=' + page + '&limit=' + limit + '&sort=' + sort;
    if (manager != '') {
      url = `${url}&manager=${manager}`;
    }
    if (name != '') {
      url = `${url}&name=${name}`;
    }
    if (active != -1) {
      url = `${url}&active=${active}`;
    }
    if (systemUserState != -1) {
      url = `${url}&systemUserState=${systemUserState}`;
    }
    if (roleName != '') {
      url = `${url}&roleName=${roleName}`;
    }
    return this.http
      .get<BoxxResponse<Policy>>(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetById(id): Observable<BoxxResponse<BoxxUser>> {
    let url = this.baseurl + id;
    return this.http
      .get<BoxxResponse<BoxxUser>>(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  // POST
  CreateBoxxUser(data): Observable<BoxxUser> {
    let url = `${this.baseurl}`;
    return this.http.post<BoxxUser>(url, data, this.httpOptions);
  }

  // PUT
  UpdateBoxxUser(id, data): Observable<BoxxUser> {
    let url = `${this.baseurl}${id}`;
    return this.http.put<BoxxUser>(url, data, this.httpOptions);
  }

  DeleteBoxxUser(id): Observable<BoxxUser> {
    let url = `${this.baseurl}${id}`;
    return this.http.delete<BoxxUser>(url, this.httpOptions);
  }
  RestoreBoxxUser(id, data = null): Observable<BoxxUser> {
    let url = `${this.baseurl}restore/${id}`;
    return this.http.put<BoxxUser>(url, data, this.httpOptions);
  }

  GetAllUserByName(
    name,
    page = 1,
    limit = 10,
    sort = '',
    active = null,
  ): Observable<BoxxResponse<BoxxUser>> {
    let url =
      this.baseurl + '?page=' + page + '&limit=' + limit + '&sort=' + sort;

    if (name) {
      url += '&name=' + name;
    }
    if (active) {
      url += '&active=' + active;
    }
    return this.http
      .get<BoxxResponse<BoxxUser>>(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetAllUsers(
    searchQuery = '',
    page = 1,
    limit = 10,
    sort = '',
  ): Observable<BoxxResponse<BoxxUser>> {
    let url =
      this.baseurl + '?page=' + page + '&limit=' + limit + '&sort=' + sort;

    if (searchQuery) {
      url += '&name=' + searchQuery;
    }

    return this.http
      .get<BoxxResponse<BoxxUser>>(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetAllActiveUsers(
    page = 1,
    limit = 1000,
  ): Observable<BoxxResponse<BoxxUser>> {
    let url = `${this.baseurl}?active=1&page=${page}&limit=${limit}`;

    return this.http
      .get<BoxxResponse<BoxxUser>>(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
