import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BoxxResponse } from '../entities/boxx-response';

@Injectable({
  providedIn: 'root',
})
export class RiskRegionService {
  constructor(private http: HttpClient) {}

  getRiskRegion(idRisk: number, limit = 100): Observable<any> {
    const { msProductriskBaseUrl, riskRegionPathUrl } = environment;

    const queryParams = new HttpParams();
    queryParams.append('riskId', idRisk);

    const url = `${msProductriskBaseUrl}${riskRegionPathUrl}?riskId=${idRisk}&limit=${limit}`;

    return this.http.get(url, { params: queryParams });
  }

  getRiskRegionAndVersion(
    regionId: number,
    versionId: number,
    standaloneSellable = null,
    prdtStatus?: number,
  ): Observable<BoxxResponse<any>> {
    const { msProductriskBaseUrl, riskRegionPathUrl } = environment;
    let url = `${msProductriskBaseUrl}${riskRegionPathUrl}?regionId=${regionId}&version=${versionId}${
      prdtStatus ? `&status=${prdtStatus}` : ``
    }`;
    if (standaloneSellable == 0 || standaloneSellable == 1) {
      url = `${msProductriskBaseUrl}${riskRegionPathUrl}?regionId=${regionId}&version=${versionId}&standaloneSellable=${standaloneSellable}${
        prdtStatus ? `&status=${prdtStatus}` : ``
      }`;
    }
    return this.http.get<BoxxResponse<any>>(url);
  }

  getRiskRegionByRegionId(
    regionId: number,
    riskId: number,
  ): Observable<BoxxResponse<any>> {
    const { msProductriskBaseUrl, riskRegionPathUrl } = environment;
    const url = `${msProductriskBaseUrl}${riskRegionPathUrl}?regionId=${regionId}&riskId=${riskId}`;

    return this.http.get<BoxxResponse<any>>(url);
  }

  getRiskServicesById(riskId: number | string): Observable<BoxxResponse<any>> {
    const { msProductriskBaseUrl, riskServicePathUrl } = environment;
    const url = `${msProductriskBaseUrl}${riskServicePathUrl}?riskId=${riskId}`;

    return this.http.get<BoxxResponse<any>>(url);
  }

  getAllRisk(): Observable<any> {
    const { msProductriskBaseUrl, riskPath } = environment;
    const url = `${msProductriskBaseUrl}${riskPath}`;

    return this.http.get(url);
  }

  getAllRiskByUser(id): Observable<any> {
    const { msProductriskBaseUrl, riskPath } = environment;
    const url = `${msProductriskBaseUrl}${riskPath}`;

    return this.http.get(url);
  }

  getBySellableWithRiskId(sellableWithRiskId): Observable<BoxxResponse<any>> {
    const { msProductriskBaseUrl, riskPath } = environment;
    const url = `${msProductriskBaseUrl}${riskPath}?sellableWithRiskId=${sellableWithRiskId}`;

    return this.http.get<BoxxResponse<any>>(url);
  }

  getRiskById(riskId): Observable<any> {
    const { msProductriskBaseUrl, riskPath } = environment;
    const url = `${msProductriskBaseUrl}${riskPath}${riskId}`;

    return this.http.get(url);
  }

  getRiskRegionsByRegion(regionId: number): Observable<any> {
    const { msProductriskBaseUrl, riskRegionPathUrl } = environment;
    const url = `${msProductriskBaseUrl}${riskRegionPathUrl}?regionId=${regionId}&active=1`;
    return this.http.get(url);
  }
}
