import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BoxxResponse } from '../entities/boxx-response';
import { Insured } from '../entities/insured';
import { BaseService } from './base-services';

@Injectable({
  providedIn: 'root',
})
export class InsuredService extends BaseService<Insured> {
  constructor(http: HttpClient) {
    const baseurl = environment.msInsuredBaseUrl + environment.insuredPathUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  GetAllByFirstName(
    firstName,
    page = 1,
    limit = 10,
    sort = '',
  ): Observable<BoxxResponse<Insured>> {
    return this.http
      .get<BoxxResponse<Insured>>(
        this.baseurl +
          '?firstName=' +
          firstName +
          '&page=' +
          page +
          '&limit=' +
          limit +
          '&sort=' +
          sort,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetAllByIndividual(
    firstName,
    email,
    address,
    page = 1,
    limit = 10,
    sort = '',
    active = null,
  ): Observable<BoxxResponse<Insured>> {
    let filter = '?';
    if (firstName) {
      filter += '&firstName=' + firstName;
    }
    if (email) {
      filter += '&email=' + email;
    }

    if (address) {
      filter += '&address=' + address;
    }
    if (active || active === 0) {
      filter += '&active=' + active;
    }
    filter = filter.replace('?&', '?');

    return this.http.get<BoxxResponse<Insured>>(
      this.baseurl +
        filter +
        '&insuredType=1' +
        '&page=' +
        page +
        '&limit=' +
        limit +
        '&sort=' +
        sort,
    );
  }

  InsuredExport(filter, sort = '', limit = 100): Observable<ArrayBuffer> {
    let url = environment.msInsuredBaseUrl + environment.insuredExportUrl;

    url += '?page=1';

    if (sort) {
      url += '&sort=' + sort;
    }
    if (limit) {
      url += '&limit=' + limit;
    }
    if (filter?.insuredType && filter?.insuredType != -1) {
      url += '&insuredType=' + filter?.insuredType;
    }
    if (filter?.firstName) {
      url += '&firstName=' + filter?.firstName;
    }
    if (filter?.insuredName) {
      url += '&insuredName=' + filter?.insuredName;
    }
    if (filter?.email) {
      url += '&email=' + filter?.email;
    }
    if (filter?.address) {
      url += '&address=' + filter.address;
    }
    if (filter?.active) {
      url += '&active=' + filter.active;
    }
    if (filter?.status) {
      url += '&active=' + filter.status;
    }
    if (filter?.companyName) {
      url += '&companyName=' + filter.companyName;
    }
    if (filter?.companyWebsite) {
      url += '&companyWebsite=' + filter.companyWebsite;
    }

    return this.http
      .get(url, { responseType: 'arraybuffer' })
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetAllByCompany(
    companyName,
    companyWebsite,
    address,
    page = 1,
    limit = 10,
    sort = '',
    active = null,
  ): Observable<BoxxResponse<Insured>> {
    let filter = '?';
    if (companyName) {
      filter += '&companyName=' + companyName;
    }
    if (companyWebsite) {
      filter += '&companyWebsite=' + companyWebsite;
    }

    if (address) {
      filter += '&address=' + address;
    }

    if (active || active === 0) {
      filter += '&active=' + active;
    }

    filter = filter.replace('?&', '?');

    return this.http.get<BoxxResponse<Insured>>(
      this.baseurl +
        filter +
        '&insuredType=2' +
        '&page=' +
        page +
        '&limit=' +
        limit +
        '&sort=' +
        sort,
    );
  }

  GetByCompanyName(
    companyName,
    page = 1,
    limit = 10,
    sort = '',
  ): Observable<BoxxResponse<Insured>> {
    let filter = '?';
    if (companyName) {
      filter += '&companyName=' + companyName;
    }

    filter = filter.replace('?&', '?');

    return this.http.get<BoxxResponse<Insured>>(
      this.baseurl +
        filter +
        '&page=' +
        page +
        '&limit=' +
        limit +
        '&sort=' +
        sort,
    );
  }

  GetAllByInsuredType(
    insuredType,
    name,
    page = 1,
    limit = 10,
    sort = '',
  ): Observable<BoxxResponse<Insured>> {
    let url =
      this.baseurl + '?page=' + page + '&limit=' + limit + '&sort=' + sort;

    if (name !== null) {
      if (insuredType === 1) {
        url += '&insuredName=' + name;
      } else {
        url += '&companyName=' + name;
      }
    }
    url += '&insuredType=' + insuredType;

    return this.http
      .get<BoxxResponse<Insured>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetByInsuredId(id, page = 1, limit = 10): Observable<BoxxResponse<Insured>> {
    const url = this.baseurl + id + '?page=' + page + '&limit=' + limit;
    return this.http.get<BoxxResponse<Insured>>(url);
  }

  GetByInsuredNameAndType(
    insuredName = '',
    insuredType = -1,
    page = 1,
    limit = 10,
    sort = '',
    active = -1,
  ): Observable<BoxxResponse<Insured>> {
    let url =
      insuredType === -1
        ? `${this.baseurl}?insuredName=${insuredName}&page=${page}&limit=${limit}&sort=${sort}`
        : `${this.baseurl}?insuredType=${insuredType}&insuredName=${insuredName}&page=${page}&limit=${limit}&sort=${sort}`;
    if (active !== -1) {
      url = url + `&active=${active}`;
    }
    return this.http
      .get<BoxxResponse<Insured>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  getByInsuredFilter(
    queryParams: any,
    page: number = 1,
    limit = 10,
    sort = 'createdDt%3Adesc',
  ): Observable<BoxxResponse<Insured>> {
    let url = `${this.baseurl}?page=${page}&limit=${limit}&sort=${sort}`;
    // Append additional queryParams to the URL
    for (const key in queryParams) {
      if (
        queryParams.hasOwnProperty(key) &&
        queryParams[key] !== undefined &&
        queryParams[key] !== null &&
        queryParams[key] !== ''
      ) {
        url += `&${key}=${queryParams[key]}`;
      }
    }

    return this.http
      .get<BoxxResponse<Insured>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
