import { Component } from '@angular/core';

@Component({
  selector: 'app-card-skeleton',
  templateUrl: './card-skeleton.component.html',
  styleUrls: ['./card-skeleton.component.less'],
})
export class CardSkeletonComponent {
  constructor() {}
}
