import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject, from, take } from 'rxjs';
import { getCurrencySelector } from 'src/app/store/dashboard/dashboard.selector';
import { ModalAlertService } from 'src/app/services/modal-alert.service';

@Component({
  selector: 'app-dynamic-filter-v2-modal',
  templateUrl: './dynamic-filter-v2-modal.component.html',
  styleUrls: ['./dynamic-filter-v2-modal.component.less'],
})
export class DynamicFilterv2ModalComponent implements OnInit, OnChanges {
  @Output() handleClose = new EventEmitter<any>();
  @Output() handleSubmit = new EventEmitter();
  @Output() handleSearch = new EventEmitter();
  @Output() handleLoadMore = new EventEmitter();
  @Output() handleFilterClear = new EventEmitter();

  @Input() filterFormGroup;
  @Input() filterContainerStyle: { [klass: string]: any };
  @Input() filterTitle = 'Filter Insured';
  @Input() filterTitleDescription =
    'Apply filters to find the insured you are looking for.';
  @Input() filterInputOptions: any[];
  // [{ label: string; type: string; value:string; disabled?:boolean; selectedValue?:any; dropdownOptions?:[]; isDefault: boolean }];
  @Input() resetAddFormSubject: Subject<boolean> = new Subject<boolean>();
  @Input() clearAllBtn: boolean = true;

  showModalFilterPopup: boolean = true;
  isFilterLoader: boolean = false;
  isCheckAddNewEnabled: boolean = false;
  //filterFormGroup: FormGroup;
  formSubmitted: boolean = false;
  alertInfo = {
    show: false,
    type: '',
    header: '',
    body: '',
  };
  form$: Observable<any>;
  selectedInputType: string = 'text'; // Default input type
  textInput: string = '';
  numberInput: number = null;
  dateInput: string = '';
  filterCriteria: any = {};
  rows: any[] = [];
  currency = '';
  dropdownValue;
  @ViewChild('textInputTemplate') textInputTemplate: TemplateRef<any>;
  @ViewChild('numberInputTemplate') numberInputTemplate: TemplateRef<any>;
  @ViewChild('dropdownInputTemplate') dropdownInputTemplate: TemplateRef<any>;
  @ViewChild('dropdownCustomInputTemplate')
  dropdownCustomInputTemplate: TemplateRef<any>;
  @ViewChild('dateRangeTemplate') dateRangeTemplate: TemplateRef<any>;
  @ViewChild('dropdownRangeTemplate') dropdownRangeTemplate: TemplateRef<any>;
  @ViewChild('multiDropdownTemplate') multiDropdownTemplate: TemplateRef<any>;
  @ViewChild('multiSearchTemplate') multiSearchTemplate: TemplateRef<any>;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private alertService: ModalAlertService,
  ) {}
  ngOnInit() {
    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));

    // Subscribe to form value changes
    this.filterFormGroup.valueChanges.subscribe((values) => {
      this.filterCriteria = values;
    });
    this.addDefaultRows();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filterInputOptions']) {
      for (let item of this.filterInputOptions) {
        if (item?.dropdownOptions) {
          let ind = this.rows.findIndex(
            (x) => x.selectedInputValue === item.value,
          );

          if (ind >= 0) {
            this.rows[ind].dropdownOptions = item?.dropdownOptions;
          }
        }
      }
    }
  }
  getTemplate(inputType): any {
    switch (inputType) {
      case 'text':
        return this.textInputTemplate;
      case 'number':
        return this.numberInputTemplate;
      case 'dropdown':
        return this.dropdownInputTemplate;
      case 'customDropdown':
        return this.dropdownCustomInputTemplate;
      case 'dateRange':
        return this.dateRangeTemplate;
      case 'dropdownRange':
        return this.dropdownRangeTemplate;
      case 'multiDropdown':
        return this.multiDropdownTemplate;
      case 'multiSearch':
        return this.multiSearchTemplate;
      default:
        return this.textInputTemplate;
    }
  }

  addDefaultRows() {
    const defaultRows = this.filterInputOptions.filter(
      (option) => option.isDefault,
    );
    defaultRows.forEach((defaultRow) => {
      let currentSelectedOption, placeholder;
      if (
        defaultRow.selectedValue !== undefined &&
        defaultRow.dropdownOptions
      ) {
        let selectedOption = defaultRow.dropdownOptions.find(
          (option) => option.id === defaultRow.selectedValue,
        );
        currentSelectedOption = selectedOption;
      }
      if (defaultRow.placeholder && defaultRow.placeholder !== undefined) {
        placeholder = defaultRow.placeholder;
      }

      const newRow: any = {
        selectedInputType: defaultRow.type,
        selectedInputValue: defaultRow.value,
        dropdownOptions: defaultRow?.dropdownOptions,
        currentSelectedOption: currentSelectedOption ?? '',
        currentValue: '',
        isRequired: defaultRow?.isRequired,
        isDefault: defaultRow?.isDefault,
        placeholder: placeholder ?? '',
        min: defaultRow.min,
        max: defaultRow.max,
        minMaxErrmsg: defaultRow.minMaxErrmsg,
        filterInputOptions: this.filterInputOptions.map((option) => {
          const isDisabled =
            option.value === defaultRow.value || option.isDefault;
          return { ...option, disabled: isDisabled };
        }),
      };
      newRow.currentValue = this.setDefaultValue(newRow);
      if (newRow.selectedInputType === 'dropdownRange') {
        let fromValue = defaultRow.dropdownOptions.find(
          (option) => option.id === defaultRow.selectedValue['from'],
        );
        if (!fromValue) {
          if (defaultRow.selectedValue['from'] != '') {
            fromValue = defaultRow.dropdownOptions.find(
              (option) => option.value === 'custom-amount',
            );
          } else {
            fromValue = '';
          }
        }
        let toValue = defaultRow.dropdownOptions.find(
          (option) => option.id === defaultRow.selectedValue['to'],
        );
        if (!toValue) {
          if (defaultRow.selectedValue['to'] != '') {
            toValue = defaultRow.dropdownOptions.find(
              (option) => option.value === 'custom-amount',
            );
          } else {
            toValue = '';
          }
        }
        currentSelectedOption = {
          from: fromValue,
          to: toValue,
        };
        newRow.currentSelectedOption = currentSelectedOption ?? {
          from: '',
          to: '',
        };
      }
      if (newRow.selectedInputType === 'customDropdown') {
        let fromValue = defaultRow.dropdownOptions.find(
          (option) => option.id === defaultRow.selectedValue,
        );
        if (!fromValue) {
          if (defaultRow.selectedValue != '') {
            fromValue = defaultRow.dropdownOptions.find(
              (option) => option.value === 'custom-amount',
            );
          } else {
            fromValue = '';
          }
        }
      }

      this.rows.push(newRow);
    });
    this.isCheckAddNewEnabled = this.checkAddNewOption();
  }

  checkAddNewOption() {
    const nextOption = this.filterInputOptions.find(
      (option) => !this.isOptionUsed(option.value),
    );
    return nextOption;
  }

  handleNewRowClick(event) {
    const nextOption = this.filterInputOptions.find(
      (option) => !this.isOptionUsed(option.value),
    );

    if (nextOption) {
      this.isCheckAddNewEnabled = true;
      // Disable the previously selected option
      const previouslySelectedOption = this.filterInputOptions.find(
        (option) => option.value === nextOption.value,
      );
      if (previouslySelectedOption) {
        previouslySelectedOption.disabled = false;
      }
      let currentSelectedOption, placeholder;
      if (
        nextOption.selectedValue !== undefined &&
        nextOption.dropdownOptions
      ) {
        let selectedOption = nextOption.dropdownOptions.find(
          (option) => option.id === nextOption.selectedValue,
        );
        currentSelectedOption = selectedOption;
      }
      if (nextOption.placeholder && nextOption.placeholder !== undefined) {
        placeholder = nextOption.placeholder;
      }

      // Create a new row with the next available option
      const newRow: any = {
        selectedInputType: nextOption.type,
        selectedInputValue: nextOption.value,
        dropdownOptions: nextOption?.dropdownOptions,
        currentSelectedOption: currentSelectedOption ?? '',
        currentValue: '',
        isRequired: nextOption?.isRequired,
        isDefault: nextOption?.isDefault,
        placeholder: placeholder ?? '',
        min: nextOption.min,
        max: nextOption.max,
        minMaxErrmsg: nextOption.minMaxErrmsg,
        filterInputOptions: this.filterInputOptions.map((option) => {
          const isDisabled = option.value === nextOption.value;
          return { ...option, disabled: isDisabled };
        }),
      };
      newRow.currentValue = this.setDefaultValue(newRow);
      if (newRow.selectedInputType === 'dropdownRange') {
        newRow.currentSelectedOption = currentSelectedOption ?? {
          from: '',
          to: '',
        };
      }

      this.rows.push(newRow);
      this.isCheckAddNewEnabled = this.checkAddNewOption();
    } else {
      this.isCheckAddNewEnabled = false;
    }
  }

  isOptionUsed(value: string): boolean {
    return this.rows.some((row) => row.selectedInputValue === value);
  }

  isSelected(value: string): boolean {
    return this.rows.some((row) => row.selectedInputValue === value);
  }

  closeModal() {
    this.showModalFilterPopup = false;
    this.isFilterLoader = false;
    this.filterFormGroup.reset();
    this.handleClose.emit(true);
    this.alertService.clearAllAlerts();
  }
  validateFormGroup() {
    for (let item of this.rows) {
      this.validateControl(item);
    }
  }
  validateControl(item) {
    switch (item.selectedInputType) {
      case 'text':
        break;
      case 'number':
        let numberForm = this.filterFormGroup.get(
          item.selectedInputValue,
        ) as FormGroup;

        if (numberForm?.value && numberForm.value <= 0) {
          numberForm.setErrors({ customError: true });
        } else {
          numberForm.setErrors(null);
        }
        break;
      case 'dropdown':
        break;
      case 'customDropdown':
        break;
      case 'dateRange':
        let form = this.filterFormGroup.get(
          item.selectedInputValue,
        ) as FormGroup;
        let valid = this.isValidDates(
          form?.value?.['from'],
          form?.value?.['to'],
        );
        if (!valid) {
          form.get('from').setErrors({ customError: true });
          form.get('to').setErrors({ customError: true });
          item.customErrMsg =
            'Enter a valid date. From date should be less than to date';
        } else {
          form.get('from').setErrors(null);
          form.get('to').setErrors(null);
        }
        break;
      case 'dropdownRange':
        let forms = this.filterFormGroup.get(
          item.selectedInputValue,
        ) as FormGroup;
        let validate: any = this.isValidRange(
          forms?.value?.['from'],
          forms?.value?.['to'],
          item.currentSelectedOption['from'],
          item.currentSelectedOption['to'],
          item,
        );

        if (!validate.valid) {
          forms.get('from').setErrors({ customError: true });
          forms.get('to').setErrors({ customError: true });
          item.customErrMsg = validate.msg;
        } else {
          forms.get('from').setErrors(null);
          forms.get('to').setErrors(null);
        }
        break;
      case 'multiDropdown':
        break;
      default:
        return null;
    }
  }
  isValidRange(fromValue, toValue, fromOption, toOption, item) {
    let msg = 'Enter a valid value. From value should be less than to value';
    if (fromValue === '' && toValue === '') {
      return { valid: true, msg: '' };
    }
    if (fromValue === '' || toValue === '') {
      return { valid: false, msg: msg };
    }
    if (fromOption.value !== 'custom-amount') {
      fromValue = fromOption.value;
    }
    if (toOption.value !== 'custom-amount') {
      toValue = toOption.value;
    }

    let minMax = true;
    if (
      item.min !== undefined &&
      item.min !== '' &&
      (fromValue < item.min || toValue < item.min)
    ) {
      minMax = false;
      msg = item.minMaxErrmsg;
    }

    if (
      item.max !== undefined &&
      item.min !== '' &&
      (fromValue > item.max || toValue > item.max)
    ) {
      minMax = false;
      msg = item.minMaxErrmsg;
    }
    if (minMax) {
      msg = 'Enter a valid value. From value should be less than to value';
    }

    return { valid: Number(fromValue) <= Number(toValue) && minMax, msg: msg };
  }
  isValidDates(dateFromStr, dateToStr) {
    let dateFrom: Date;
    let dateTo: Date;
    if (dateFromStr == '' && dateToStr == '') {
      return true;
    }
    if (dateFromStr == '' || dateToStr == '') {
      return false;
    }
    dateFrom = new Date(dateFromStr);
    dateTo = new Date(dateToStr);

    return dateTo >= dateFrom;
  }
  handleFilter() {
    this.validateFormGroup();

    this.formSubmitted = true;
    if (!this.filterFormGroup.valid) {
      return;
    }
    this.handleSubmit.emit(this.filterFormGroup.value);
  }

  inputTypeChanged(item) {
    const correspondingTypeOption = item.filterInputOptions.find(
      (option) => option.value === item.selectedInputValue,
    );
    if (correspondingTypeOption) {
      item.selectedInputType = correspondingTypeOption.type;
      item.dropdownOptions = correspondingTypeOption.dropdownOptions;
      item.min = correspondingTypeOption.min;
      item.max = correspondingTypeOption.max;
      item.minMaxErrmsg = correspondingTypeOption.minMaxErrmsg;
      correspondingTypeOption.disabled = true;

      item.currentSelectedOption = {
        id: '',
        value: '',
        key: '',
      };
    }
    item = this.setDefaultForm(item);
    this.filterFormValues();
  }

  clearSelection(row: any) {
    this.clearErrors(row.selectedInputValue);
    this.setDefaultForm(row);
    // this.filterFormGroup.controls[row.selectedInputValue].patchValue('');
  }

  deleteRow(index: number, item) {
    // if (
    //   item.selectedInputValue == 'createdDt' ||
    //   item.selectedInputValue == 'effectiveDt' ||
    //   item.selectedInputValue == 'premiumRange'
    // ) {
    //   this.filterFormGroup.get(item.selectedInputValue).setValue('');
    // }
    this.rows.splice(index, 1);
    this.isCheckAddNewEnabled = this.checkAddNewOption();
    this.setDefaultForm(item);
    this.filterFormValues();
  }

  getSelectedOptionLabel(selectedValue: string): string {
    const selectedOption = this.filterInputOptions.find(
      (option) => option.value === selectedValue,
    );
    return selectedOption ? selectedOption.label : '';
  }

  // Method to handle selection of an option from the dropdown menu
  selectOption(item: any, option: any): void {
    item.selectedInputValue = option.value;

    this.inputTypeChanged(item);
  }

  filterFormValues(): void {
    Object.keys(this.filterFormGroup.controls).forEach((controlName) => {
      const existsInArray = this.rows.some(
        (item) => item.selectedInputValue === controlName,
      );
      if (!existsInArray) {
        let item = this.filterInputOptions.find((x) => x.value === controlName);
        switch (item.type) {
          case 'dateRange':
          case 'dropdownRange':
            let dropdownForm = this.filterFormGroup.get(
              controlName,
            ) as FormGroup;
            dropdownForm.get('from').setValue('');
            dropdownForm.get('from').markAsPristine();
            dropdownForm.get('from').markAsUntouched();
            dropdownForm.get('from').setErrors(null);
            dropdownForm.get('to').setValue('');
            dropdownForm.get('to').markAsPristine();
            dropdownForm.get('to').markAsUntouched();
            dropdownForm.get('to').setErrors(null);
            break;
          case 'multiDropdown':
          case 'multiSearch':
            this.filterFormGroup.get(controlName).setValue([]);
            this.filterFormGroup.get(controlName).markAsPristine();
            this.filterFormGroup.get(controlName).markAsUntouched();
            this.filterFormGroup.get(controlName).setErrors(null);

            break;
          case 'text':
          case 'number':
          case 'dropdown':
          case 'customDropdown':
          default:
            this.filterFormGroup.get(controlName).setValue('');
            this.filterFormGroup.get(controlName).markAsPristine();
            this.filterFormGroup.get(controlName).markAsUntouched();
            this.filterFormGroup.get(controlName).setErrors(null);
        }
      }
    });
  }

  handledropdownValueChange(value, item) {
    this.clearErrors(item.selectedInputValue);
    this.filterFormGroup.controls[item.selectedInputValue].setValue(value);
    item.currentValue = value;
    const currentselected = item.dropdownOptions.find(
      (item) => item.id === value,
    );
    item.currentSelectedOption = currentselected;
  }

  handledropdownCustomValueChange(value, item) {
    this.clearErrors(item.selectedInputValue);
    let customOptions = {};
    let form =
      this.filterFormGroup.controls[item.selectedInputValue].setValue(value);
    item.currentValue = value;
    const currentselected = item.dropdownOptions.find(
      (item) => item.id === value,
    );
    if (currentselected.value === 'custom-amount') {
      this.filterFormGroup.controls[item.selectedInputValue].setValue('');
    }
    item.currentSelectedOption = currentselected;
  }

  handledropdownRangeValueChange(value, item, control) {
    this.clearErrors(item.selectedInputValue);
    let form = this.filterFormGroup.controls[
      item.selectedInputValue
    ] as FormGroup;
    form.controls[control].setValue(value);
    item.currentValue[control] = value;
    const currentselected = item.dropdownOptions.find(
      (item) => item.id === value,
    );
    if (currentselected.value === 'custom-amount') {
      form.controls[control].setValue('');
    }
    item.currentSelectedOption[control] = currentselected;
    form.get('from').setErrors(null);
    form.get('to').setErrors(null);
    this.validateControl(item);
  }
  handleDateRangeValueChange(value, item, control) {
    this.clearErrors(item.selectedInputValue);
    let form = this.filterFormGroup.controls[
      item.selectedInputValue
    ] as FormGroup;
    form.controls[control].setValue(value);
    item.currentValue[control] = value;
    form.get('from').setErrors(null);
    form.get('to').setErrors(null);
    this.validateControl(item);
  }
  handleTextInput(value, item) {
    item.currentValue = value;
  }
  handleSubTextInput(value, item, control) {
    item.currentValue[control] = value;
    let form = this.filterFormGroup.controls[
      item.selectedInputValue
    ] as FormGroup;
    form.get('from').setErrors(null);
    form.get('to').setErrors(null);

    if (item.min) {
      value >= item.min
        ? form.get(control).setErrors(null)
        : form.get(control).setErrors({ customError: true });
      item.customErrMsg = 'Enter a valid value between 0 and 1M';
    }
    if (item.max) {
      value <= item.max
        ? form.get(control).setErrors(null)
        : form.get(control).setErrors({ customError: true });
      item.customErrMsg = 'Enter a valid value between 0 and 1M';
    }
    this.validateControl(item);
  }
  handlemultiDropdownSelect(value, item) {
    this.clearErrors(item.selectedInputValue);
    this.filterFormGroup.controls[item.selectedInputValue].setValue(value);
    item.currentValue = value;
    const currentselected = item.dropdownOptions.find(
      (item) => item.id === value,
    );
    item.currentSelectedOption = currentselected;
  }
  searchOption(event, item) {
    this.handleSearch.emit({ search: event, item: item });
  }
  loadMoreOptions(item) {
    this.handleLoadMore.emit(item);
  }
  clearErrors(controlName: string) {
    if (this.filterFormGroup.get(controlName)) {
      this.filterFormGroup.get(controlName).setErrors(null);
    }
  }
  clearSubErrors(controlName: string, control: string) {
    let form = this.getFormGroup(controlName);
    if (form.get(control)) {
      form.get(control).setErrors(null);
    }
    form.get('from').setErrors(null);
    form.get('to').setErrors(null);
  }
  getFormGroup(controlName) {
    return this.filterFormGroup.get(controlName) as FormGroup;
  }
  setDefaultValue(item) {
    switch (item.selectedInputType) {
      case 'text':
        return '';
      case 'number':
        return '';
      case 'dropdown':
        return '';
      case 'customDropdown':
        return '';
      case 'dateRange':
        return { from: '', to: '' };
      case 'dropdownRange':
        return { from: '', to: '' };
      case 'multiDropdown':
        return [];
      case 'multiSearch':
        return [];
      default:
        return '';
    }
  }
  setDefaultForm(item) {
    switch (item.selectedInputType) {
      case 'dateRange':
      case 'dropdownRange':
        item.currentSelectedOption = {
          from: {
            id: '',
            value: '',
            key: '',
          },
          to: {
            id: '',
            value: '',
            key: '',
          },
        };
        let dropdownForm = this.filterFormGroup.get(
          item.selectedInputValue,
        ) as FormGroup;
        dropdownForm.get('from').setValue('');
        dropdownForm.get('from').markAsPristine();
        dropdownForm.get('from').markAsUntouched();
        dropdownForm.get('from').setErrors(null);
        dropdownForm.get('to').setValue('');
        dropdownForm.get('to').markAsPristine();
        dropdownForm.get('to').markAsUntouched();
        dropdownForm.get('to').setErrors(null);
        break;
      case 'multiDropdown':
      case 'multiSearch':
        this.filterFormGroup.get(item.selectedInputValue).setValue([]);
        this.filterFormGroup.get(item.selectedInputValue).markAsPristine();
        this.filterFormGroup.get(item.selectedInputValue).markAsUntouched();
        this.filterFormGroup.get(item.selectedInputValue).setErrors(null);

        break;
      case 'text':
      case 'number':
      case 'dropdown':
      case 'customDropdown':
      default:
        item.currentSelectedOption = {
          id: '',
          value: '',
          key: '',
        };
        this.filterFormGroup.get(item.selectedInputValue).setValue('');
        this.filterFormGroup.get(item.selectedInputValue).markAsPristine();
        this.filterFormGroup.get(item.selectedInputValue).markAsUntouched();
        this.filterFormGroup.get(item.selectedInputValue).setErrors(null);
    }
    return item;
  }
  handleClear(event) {
    for (let item of this.rows) {
      this.clearSelection(item);
    }
    this.handleFilterClear.emit();
  }
}
