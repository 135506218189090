import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';

@Component({
  selector: 'app-branch-details',
  templateUrl: './branch-details.component.html',
  styleUrls: ['./branch-details.component.less'],
})
export class BranchDetailsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() requiredErrMsg: string;
  @Input() invalidErrMsg: string;
  @Input() formSubmitted: boolean = false;
  @Input() isActive: boolean;
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
    });
  }
}
