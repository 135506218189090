<div class="holder">
  <div class="card">
    <div class="info-panel">
      <div class="content">
        <img class="mail" src="assets/img/mail.png" />
        <div class="header">
          <boxxds-heading
            [sz]="'6'"
            [text]="'Check your inbox'"
            type="bold"
          ></boxxds-heading>
          <boxxds-body
            [text]="'We have sent an email to confirm your account.'"
          ></boxxds-body>
        </div>
        <div class="divider"></div>
        <div class="link-holder">
          <span>Did not receive the email? Check your spam filter or try </span>
          <a class="resend-link" href="/login/reset">another email address</a>
        </div>
      </div>
    </div>
    <div class="logo-panel">
      <div class="logo">
        <img src="assets/img/boxx.png" alt="boxx logo" />
      </div>
    </div>
  </div>
</div>
