<ng-container>
  <form [formGroup]="form" [appConnectForm]="form$ | async">
    <div class="d-flex justify-content-center" *ngIf="showBodySpinner">
      <ng-container>
        <div class="body-spinner">
          <boxxds-spinner
            [containerStyle]="{
              width: '112px',
              height: '112px',
              '--b': '15px'
            }"
          ></boxxds-spinner>
        </div>
      </ng-container>
    </div>

    <boxxds-alert
      *ngIf="alertData?.show"
      [type]="alertData?.type"
      [headerText]="alertData?.header | translate"
      [bodyText]="alertData?.body | translate"
      (handleCloseEvent)="handleAlertClose()"
    ></boxxds-alert>
    <ng-container *ngIf="!showBodySpinner">
      <div class="add-company">
        <ng-container>
          <div class="add-form">
            <div class="form-input-hd">
              <boxxds-body
                sz="md"
                [text]="'insured.heading.businessInformation' | translate"
                [customStyle]="{ color: '#616162', marginBottom: '4px' }"
              ></boxxds-body>
              <boxxds-body
                sz="xs"
                [text]="'*' + ('common.required' | translate)"
                [customStyle]="{ color: '#8F8F91', textTransform: 'uppercase' }"
              >
              </boxxds-body>
            </div>
            <div class="form-inputs business-info">
              <boxxds-search-autocomplete
                *ngIf="fromSubmissionFlow === true"
                [form]="form"
                [isActive]="isActive"
                [formSubmitted]="checkValidation"
                [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
                [items]="insuredOptns"
                [title]="form.value?.businessName"
                [label]="'insured.label.businessName' | translate"
                [required]="true"
                [control]="'businessName'"
                [placeholder]="
                  'insured.placeholder.enterBusinessName' | translate
                "
                [autocapitalize]="'off'"
                [spellcheck]="'off'"
                [permissionStatus]="this.permissionList[this.currentScreen]"
                [containerStyle]="{ width: '500px' }"
                (searchQuery)="handleSearch($event)"
                (itemSelected)="
                  insuredOptns = []; populateInsuredDetails($event)
                "
                [maxLength]="'200'"
              ></boxxds-search-autocomplete>
              <boxxds-textinput
                *ngIf="fromSubmissionFlow === false"
                [form]="form"
                [placeholder]="
                  'insured.placeholder.enterBusinessName' | translate
                "
                [control]="'businessName'"
                [id]="'businessName'"
                [width]="'100%'"
                [containerStyle]="{ maxWidth: '500px' }"
                [label]="('insured.label.businessName' | translate) + '*'"
                class="business-name"
                [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
                [formSubmitted]="checkValidation"
                maxLength="200"
                [readonly]="
                  !this.permissionList[this.currentScreen] ||
                  (insuredData && !insuredData?.active)
                "
              >
              </boxxds-textinput>
              <boxxds-textinput
                [form]="form"
                [placeholder]="'insured.placeholder.enterWebsite' | translate"
                [control]="'website'"
                [id]="'website'"
                [width]="'100%'"
                [containerStyle]="{ maxWidth: '500px' }"
                [label]="'insured.label.website' | translate"
                class="website"
                [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
                [invalidErrMsg]="'error.thisFieldIsInvalid' | translate"
                [formSubmitted]="checkValidation"
                maxLength="100"
                [readonly]="
                  !this.permissionList[this.currentScreen] ||
                  (insuredData && !insuredData?.active)
                "
              >
              </boxxds-textinput>

              <boxxds-dropdown
                [form]="form"
                [control]="'noOfEmployees'"
                [placeholder]="'common.select' | translate"
                [options]="noOfEmpOptns"
                [currentValue]="form?.value['noOfEmployees']"
                (currentValueChange)="
                  this.permissionList[this.currentScreen] &&
                    handleNoOfEmployees($event)
                "
                [label]="'insured.label.numberOfEmployees' | translate"
                [btnStyle]="{ marginTop: '8px', width: '230px' }"
                [dropdownContainerStyle]="{ width: '230px' }"
                class="employees"
                [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
                [formSubmitted]="checkValidation"
                [isReadonly]="
                  !this.permissionList[this.currentScreen] ||
                  (insuredData && !insuredData?.active)
                "
              >
              </boxxds-dropdown>
              <boxxds-textinput
                [form]="form"
                [placeholder]="
                  'insured.placeholder.enterAmountInDollars' | translate
                "
                [control]="'revenue'"
                [id]="'revenue'"
                [width]="'100%'"
                [containerStyle]="{ maxWidth: '500px' }"
                [label]="('insured.label.annualRevenue' | translate) + '*'"
                class="revenue"
                [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
                [formSubmitted]="checkValidation"
                maxLength="15"
                [invalidErrMsg]="'error.thisFieldIsInvalid' | translate"
                mask="separator.0"
                [dropSpecialCharacters]="true"
                thousandSeparator=","
                prefix="$"
                [readonly]="
                  !this.permissionList[this.currentScreen] ||
                  (insuredData && !insuredData?.active)
                "
              >
              </boxxds-textinput>
              <div class="revenue-percentage">
                <boxxds-textinput
                  [validatePercentage]="true"
                  [form]="form"
                  [placeholder]="
                    'insured.placeholder.enterPercentage' | translate
                  "
                  [control]="'revenuePercentUS'"
                  [id]="'revenueUS'"
                  [width]="'100%'"
                  [containerStyle]="{ maxWidth: '140px' }"
                  [label]="('insured.label.fromUnitedStates' | translate) + '*'"
                  [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
                  [invalidErrMsg]="'error.thisFieldIsInvalid' | translate"
                  [customErrMsg]="'insured.error.sumOfPercentage' | translate"
                  [formSubmitted]="checkValidation"
                  maxLength="6"
                  [readonly]="
                    !this.permissionList[this.currentScreen] ||
                    (insuredData && !insuredData?.active)
                  "
                >
                </boxxds-textinput>
                <boxxds-textinput
                  [validatePercentage]="true"
                  [form]="form"
                  [placeholder]="
                    'insured.placeholder.enterPercentage' | translate
                  "
                  [control]="'revenuePercentCA'"
                  [id]="'revenueCA'"
                  [width]="'100%'"
                  [containerStyle]="{ maxWidth: '140px' }"
                  [label]="('insured.label.fromCanada' | translate) + '*'"
                  [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
                  [invalidErrMsg]="'error.thisFieldIsInvalid' | translate"
                  [customErrMsg]="'insured.error.sumOfPercentage' | translate"
                  [formSubmitted]="checkValidation"
                  maxLength="6"
                  [readonly]="
                    !this.permissionList[this.currentScreen] ||
                    (insuredData && !insuredData?.active)
                  "
                >
                </boxxds-textinput>
                <boxxds-textinput
                  [validatePercentage]="true"
                  [form]="form"
                  [placeholder]="
                    'insured.placeholder.enterPercentage' | translate
                  "
                  [control]="'revenuePercentOther'"
                  [id]="'revenueOT'"
                  [width]="'100%'"
                  [containerStyle]="{ maxWidth: '140px' }"
                  [label]="
                    ('insured.label.fromOtherCountries' | translate) + '*'
                  "
                  [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
                  [invalidErrMsg]="'error.thisFieldIsInvalid' | translate"
                  [customErrMsg]="'insured.error.sumOfPercentage' | translate"
                  [formSubmitted]="checkValidation"
                  maxLength="6"
                  [readonly]="
                    !this.permissionList[this.currentScreen] ||
                    (insuredData && !insuredData?.active)
                  "
                >
                </boxxds-textinput>
              </div>
            </div>
            <app-location-details-form
              (eventEmitRegionIdSelected)="
                onHandlercompanyRegionIdSelected($event)
              "
              (handleSelectedRegionCode)="handleRegionCode($event)"
              [form]="form"
              [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
              [formSubmitted]="checkValidation"
              [defaultValueObj]="form.value"
              [isActive]="
                !(insuredData && !insuredData?.active) &&
                this.permissionList[this.currentScreen]
              "
              [allowLoader]="allowLocationLoader"
              (eventEmitLoadedData)="completedLocationLoading($event)"
            >
            </app-location-details-form>
            <div class="industry-section">
              <button
                class="confirm-industry"
                (click)="handleGetIndustryPredictions()"
                [disabled]="!enableConfirmIndustry()"
              >
                <span>{{ "insured.button.confirmIndustry" | translate }}</span>
                <img
                  [src]="
                    enableConfirmIndustry()
                      ? 'assets/img/arrow-right.png'
                      : 'assets/img/arrow-right-disabled.png'
                  "
                />
              </button>
              <div *ngIf="confirmIndustryShow">
                <boxxds-body
                  sz="md"
                  [text]="
                    'insured.heading.industryClassAndIndustry' | translate
                  "
                  [customStyle]="{ color: '#616162', marginBottom: '4px' }"
                  *ngIf="showIndustrySection"
                ></boxxds-body>
                <div
                  class="form-inputs industry-container"
                  *ngIf="showIndustrySection"
                >
                  <div *ngIf="showPredictions">
                    <boxxds-body
                      sz="sm"
                      [text]="
                        'submission.subHeading.selectOneOfTheFollowing'
                          | translate
                      "
                      [customStyle]="{ color: '#333334', marginBottom: '24px' }"
                    ></boxxds-body>
                    <boxxds-radio
                      [control]="'industry'"
                      [items]="industryPredictions"
                      [form]="industryPredictionForm"
                      [formSubmitted]="checkValidation"
                      [lblContainerStyle]="{ flexDirection: 'column' }"
                      [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
                      (handleChange)="handleRadioCheck($event)"
                      [radioFill]="true"
                    ></boxxds-radio>
                  </div>
                  <div class="industry-text-box" *ngIf="showIndustryDropdowns">
                    <boxxds-searchable-dropdown
                      [form]="form"
                      [control]="'industryClass'"
                      [placeholder]="
                        'insured.placeholder.industryClass' | translate
                      "
                      [options]="businessSectorOptns"
                      [dispatchTextInputChange]="true"
                      (textInputChangeEventEmmiter)="
                        this.permissionList[this.currentScreen] &&
                          handleIndustryClassSearch($event)
                      "
                      (currentValueChange)="
                        this.permissionList[this.currentScreen] &&
                          handleBusinessSector($event)
                      "
                      [currentValue]="form.value?.industryClass"
                      [label]="
                        ('insured.label.industryClass' | translate) + '*'
                      "
                      [btnStyle]="{ marginTop: '8px', maxWidth: '804px' }"
                      [dropdownContainerStyle]="{
                        maxWidth: '804px',
                        marginBottom: '20px'
                      }"
                      class="industry-class"
                      [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
                      [formSubmitted]="checkValidation"
                      [readonly]="
                        !this.permissionList[this.currentScreen] ||
                        (insuredData && !insuredData?.active)
                      "
                    >
                    </boxxds-searchable-dropdown>
                    <boxxds-searchable-dropdown
                      [form]="form"
                      [control]="'industry'"
                      [placeholder]="'insured.placeholder.industry' | translate"
                      [options]="businessIndustrySectorOptns"
                      [dispatchTextInputChange]="true"
                      (textInputChangeEventEmmiter)="
                        this.permissionList[this.currentScreen] &&
                          handleIndustrySearch($event)
                      "
                      (currentValueChange)="
                        this.permissionList[this.currentScreen] &&
                          handleBusinessIndustrySector($event)
                      "
                      [currentValue]="form.value?.industry"
                      [label]="('insured.label.industry' | translate) + '*'"
                      [btnStyle]="{ marginTop: '8px', maxWidth: '804px' }"
                      [dropdownContainerStyle]="{ maxWidth: '804px' }"
                      class="industry"
                      [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
                      [formSubmitted]="checkValidation"
                      [readonly]="
                        !this.permissionList[this.currentScreen] ||
                        (insuredData && !insuredData?.active)
                      "
                    >
                    </boxxds-searchable-dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="contact-panel-title"
              *ngIf="
                fromSubmissionFlow && this.permissionList[this.currentScreen]
              "
            >
              <boxxds-switch
                [checked]="this.contactToggleEnabled"
                name="contact_details"
                (changed)="toggleChanged($event)"
                [label]="this.toggleLabel | translate"
                [disabled]="false"
                [ngStyle]="{ marginBottom: '8px', marginTop: '40px' }"
              >
              </boxxds-switch>
            </div>
            <ng-container
              *ngIf="
                this.showContactDetails ||
                (!fromSubmissionFlow && this.permissionList[this.currentScreen])
              "
            >
              <boxxds-body
                sz="md"
                [text]="'insured.heading.contactDetails' | translate"
                [customStyle]="{
                  color: '#616162',
                  marginBottom: '8px',
                  textAlign: 'left',
                  marginTop: '40px'
                }"
              >
              </boxxds-body>
              <div class="form-inputs contact-details">
                <boxxds-textinput
                  [form]="form"
                  [placeholder]="
                    'insured.placeholder.enterFirstName' | translate
                  "
                  [control]="'firstName'"
                  [id]="'firstName'"
                  [width]="'100%'"
                  [label]="'insured.label.firstName' | translate"
                  class="contact-field"
                  [formSubmitted]="checkValidation"
                  maxLength="200"
                  [readonly]="
                    !this.permissionList[this.currentScreen] ||
                    (insuredData && !insuredData?.active)
                  "
                >
                </boxxds-textinput>
                <boxxds-textinput
                  [form]="form"
                  [placeholder]="
                    'insured.placeholder.enterLastName' | translate
                  "
                  [control]="'lastName'"
                  [id]="'lastName'"
                  [width]="'100%'"
                  [label]="'insured.label.lastName' | translate"
                  class="contact-field"
                  [formSubmitted]="checkValidation"
                  maxLength="200"
                  [readonly]="
                    !this.permissionList[this.currentScreen] ||
                    (insuredData && !insuredData?.active)
                  "
                >
                </boxxds-textinput>
                <boxxds-textinput
                  [form]="form"
                  [placeholder]="
                    'insured.placeholder.enterEmailAddress' | translate
                  "
                  [control]="'email'"
                  [id]="'email'"
                  [width]="'100%'"
                  [containerStyle]="{ maxWidth: '281px' }"
                  [label]="'insured.label.emailAddress' | translate"
                  class="contact-field margin-horizontal"
                  [invalidErrMsg]="'error.thisFieldIsInvalid' | translate"
                  [formSubmitted]="checkValidation"
                  maxLength="150"
                  [readonly]="
                    !this.permissionList[this.currentScreen] ||
                    (insuredData && !insuredData?.active)
                  "
                >
                </boxxds-textinput>
              </div>
            </ng-container>
          </div>
          <app-footer-wrapper
            *ngIf="
              (quoteFlowSteps === '4' || quoteFlowSteps === 'workflow3') &&
              fromSubmissionFlow === true
            "
          >
            <div class="footer-button-list">
              <div class="button-section" *ngIf="fromSubmissionFlow === true">
                <button class="linkButton" (click)="handleExitFlow()">
                  Exit the flow
                </button>
              </div>
              <div class="button-section" *ngIf="showIndustrySection">
                <boxxds-button
                  [buttonText]="continueButtonLabel"
                  [btnType]="
                    fromSubmissionFlow === true
                      ? 'primary'
                      : 'primary--outlined'
                  "
                  (handleClick)="addOrUpdateCompanyInsured($event)"
                  [btnStyle]="{
                    width: fromSubmissionFlow === true ? '199px' : '145px'
                  }"
                  [isDisabled]="
                    insuredId
                      ? insuredData?.active === false
                        ? true
                        : false
                      : false
                  "
                >
                </boxxds-button>
              </div>
            </div>
          </app-footer-wrapper>
          <div
            class="continue-btn-holder"
            [ngClass]="{
              'add-new': !insuredId,
              'justify-content-end': fromSubmissionFlow === true
            }"
          >
            <div class="timestamp" *ngIf="fromSubmissionFlow === false">
              <app-timestamp
                *ngIf="insuredData"
                label="Created by"
                value="{{ insuredData.createdBy }}, {{ insuredData.createdDt }}"
                [containerStyle]="{
                  marginTop: '8px',
                  marginLeft: '8px',
                  position: 'relative',
                  top: '-6px',
                  textTransform: 'uppercase'
                }"
              ></app-timestamp>
              <app-timestamp
                *ngIf="
                  insuredData && insuredData.updatedBy && insuredData.updatedDt
                "
                label="Updated last by"
                value="{{ insuredData.updatedBy }}, {{ insuredData.updatedDt }}"
                [containerStyle]="{
                  marginLeft: '8px',
                  position: 'relative',
                  top: '-20px',
                  textTransform: 'uppercase'
                }"
              ></app-timestamp>
            </div>
            <ng-container *ngIf="this.permissionList[this.currentScreen]">
              <div class="link-button-holder">
                <boxxds-link
                  *ngIf="insuredId && fromSubmissionFlow === false"
                  [linkStyle]="{ marginRight: '49px' }"
                  (handleClick)="deactivateOrReactivateInsured()"
                  [name]="
                    (insuredData?.active
                      ? 'insured.label.deactivateInsured'
                      : 'insured.label.reactivateInsured'
                    ) | translate
                  "
                ></boxxds-link>
                <boxxds-button
                  *ngIf="
                    showIndustrySection &&
                    fromSubmissionFlow === false &&
                    (quoteFlowSteps === '4' || quoteFlowSteps === 'workflow3')
                  "
                  [buttonText]="
                    continueButtonLabel ||
                      (insuredId
                        ? 'common.saveChanges'
                        : 'insured.button.addNewInsured') | translate
                  "
                  [btnType]="
                    fromSubmissionFlow === false
                      ? 'primary-outlined'
                      : 'primary'
                  "
                  (handleClick)="addOrUpdateCompanyInsured($event)"
                  [btnStyle]="{
                    width: insuredId ? '145px' : '199px'
                  }"
                  [isDisabled]="
                    insuredId
                      ? !(hasFormValueChanged() && insuredData?.active)
                      : false
                  "
                >
                </boxxds-button>
                <boxxds-button
                  *ngIf="quoteFlowSteps === '7'"
                  [buttonText]="
                    continueButtonLabel ||
                      (insuredId && fromSubmissionFlow === false
                        ? 'common.saveChanges'
                        : 'insured.button.addNewInsured') | translate
                  "
                  [btnType]="
                    fromSubmissionFlow === false
                      ? 'primary-outlined'
                      : 'secondary-solid'
                  "
                  (handleClick)="addOrUpdateCompanyInsured($event)"
                  [btnStyle]="{
                    width:
                      fromSubmissionFlow === false
                        ? insuredId
                          ? '145px'
                          : '199px'
                        : '199px'
                  }"
                  [isDisabled]="
                    insuredId && fromSubmissionFlow === false
                      ? !(hasFormValueChanged() && insuredData?.active)
                      : false
                  "
                >
                </boxxds-button>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </form>
</ng-container>
