import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, Subject, filter, take, takeUntil } from 'rxjs';
import { SubmissionWorkflow3Component } from './submission/submission.component';
import { RiskAnalysisWorkflow3Component } from './risk-analysis/risk-analysis.component';
import { PolicyConfigurationWF3Component } from './policy-configuration/policy-configuration.component';
import { Store, select } from '@ngrx/store';
import {
  getQuoteSelector,
  getSharedData,
  getUIData,
} from '../store/create-quote/create-quote.selector';
import { InsuredService } from '../services/insured.service';
import { QuotingComponent } from './quoting/quoting.component';
import { QuoteLifecycleComponent } from '../dashboard/quote-lifecycle/quote-lifecycle.component';
import { getDashboardSelector } from '../store/dashboard/dashboard.selector';
import { MaintenanceService } from '../services/maintenance.service';
import { isWithinNextHour } from '../utils/utils';

@Component({
  selector: 'app-quote-workflow3',
  templateUrl: './quote-workflow3.component.html',
  styleUrls: ['./quote-workflow3.component.less'],
})
export class QuoteWorkflow3Component implements OnInit {
  @Input() showInfoBanner: boolean = false;
  @Input() infoTitle: string;
  @Input() insuredInfo: Array<any> = [];
  @Input() brokerageInfo: Array<any> = [];
  @Input() notesInfo: Array<any> = [];
  @Input() quoteId;
  @Input() hasNotifications: boolean = false;
  @Input() progressBarData;

  private destroy$ = new Subject<void>();
  quoteDetailSubscription;
  showHeader = false;

  insuredId;
  form$: Observable<any>;
  isSubmissionScreens = false;
  isRestrictedRoleSubmission: boolean = false;
  isMaintenanceWithinNextHour = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private insuredService: InsuredService,
    private maintenanceService: MaintenanceService,
  ) {
    this.router.events.subscribe((event: any) => {
      if (event?.routerEvent instanceof NavigationEnd) {
        this.updateHeaderData();
      }
    });
  }

  async ngOnInit() {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.isRestrictedRoleSubmission = data.isRestrictedRoleSubmission;
    });
    this.maintenanceService.isMaintenanceMode();
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.isRestrictedRoleSubmission = data.isRestrictedRoleSubmission;
    });
    if (this.route?.children[0]?.component !== QuoteLifecycleComponent) {
      this.isSubmissionScreens = true;
    } else {
      this.isSubmissionScreens = false;
    }
    this.quoteId = this.quoteId ?? '-';
    this.insuredInfo = [
      {
        companyName: 'ABC',
        industry: 'aaa',
        revenue: 111,
        industryClass: 'rty',
        numOfEmployees: 100 - 200,
        fullAddress: 'Ottawa',
      },
    ];

    this.brokerageInfo = [
      {
        producer: 'Riya',
        branch: 'Test',
      },
    ];
    this.form$ = this.store.select(getQuoteSelector);
    this.quoteDetailSubscription = this.form$
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        let test: any = event;
        this.quoteId = event.ui.policyId;
        this.insuredInfo = [
          {
            companyName: event.ui.insuredName,
            revenue: test.ui.revenue,
            industry: test.ui.industry,
            numOfEmployees: test.ui.noOfEmployees,
            industryClass: test.ui.industryClass,
            fullAddress: test.ui.address,
            insuredId: event.ui.insuredId,
          },
        ];

        this.brokerageInfo = [
          {
            brokerage: event.ui.broker,
            branch: event.ui.branch,
            producer: event.ui.producer,
          },
        ];
      });
  }

  updateHeaderData() {
    this.showHeader = false;
    this.progressBarData = {
      showProgressBar: true,
      activeStep: 1,
      completedSteps: 0,
      totalSteps: 4,
      steps: ['Submission', 'Risk Analysis', 'Quoting', 'Bind'],
    };
    const routeComponent = this.route.firstChild?.component;
    if (routeComponent === SubmissionWorkflow3Component) {
      this.infoTitle = 'Risk Analysis';
      this.showHeader = true;
      this.showInfoBanner = false;
    } else if (routeComponent === RiskAnalysisWorkflow3Component) {
      this.progressBarData = {
        ...this.progressBarData,
        showProgressBar: true,
        activeStep: 2,
        completedSteps: 1,
      };
      this.infoTitle = 'Risk Analysis';
      this.showHeader = true;
      this.showInfoBanner = true;
    } else if (routeComponent === QuotingComponent) {
      this.progressBarData = {
        ...this.progressBarData,
        showProgressBar: true,
        activeStep: 3,
        completedSteps: 2,
      };
      this.infoTitle = 'Quoting';
      this.showHeader = true;
      this.showInfoBanner = true;
    } else if (routeComponent === QuotingComponent) {
      this.progressBarData = {
        ...this.progressBarData,
        showProgressBar: true,
        activeStep: 3,
        completedSteps: 2,
      };
      this.infoTitle = 'Quote';
      this.showHeader = true;
      this.showInfoBanner = true;
    } else if (routeComponent === PolicyConfigurationWF3Component) {
      this.progressBarData = {
        ...this.progressBarData,
        showProgressBar: true,
        activeStep: 4,
        completedSteps: 3,
      };
      this.infoTitle = 'Configuration';
      this.showHeader = true;
      this.showInfoBanner = true;
    }
  }

  showMaintenanceBanner() {
    return this.maintenanceService.getIsMaintenanceWithinNextHour();
  }
}
