import { BrokerageBranch } from 'src/app/models/brokergeBranch';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { regexHelpers } from 'src/app/utils/utils';

export const mapFormGroupToModel = (
  form: FormGroup,
  brokerageId: number,
): BrokerageBranch => {
  const { name, email, phone } = form.value['branch'];
  const location = form.value['location'];

  const brokerageBranch: BrokerageBranch = {
    brokerageId,
    name,
    email,
    telephone: phone,
    location,
  };

  return brokerageBranch;
};

export const getFormGroup = (fb: FormBuilder): FormGroup => {
  const formBuilder = fb.group({
    branch: fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.pattern(regexHelpers.AlphaNumericSpace),
        ],
      ],
      email: ['', [Validators.email, Validators.pattern(regexHelpers.MailId)]],
      phone: ['', [Validators.pattern(regexHelpers.PhoneNumber)]],
    }),
    location: fb.group({
      country: ['', Validators.required],
      province: ['', Validators.required],
      city: ['', Validators.required],
      address: ['', Validators.required],
      zipPostalCode: ['', Validators.required],
      addressLine2: [''],
    }),
  });

  return formBuilder;
};
