<div class="scrollable-pagination-table-wrapper-container">
  <div
    id="scrollable-pagination-table-wrapper"
    class="scrollable-pagination-table-wrapper custom-scrollbar"
    [ngClass]="{
      'container-default-height': isDefaultHeight && !isExpanded,
      'container-expanded-height': !isDefaultHeight && isExpanded,
      'container-collapsed-height':
        tableData.length !== 0 && !isDefaultHeight && !isExpanded,
      'container-collapsed-empty-height':
        tableData.length === 0 && !isDefaultHeight && !isExpanded
    }"
  >
    <table
      class="scrollable-pagination-table"
      [ngStyle]="{ opacity: isLoading ? 0 : 1 }"
    >
      <thead>
        <tr>
          <th *ngIf="isTeamsQueue">
            <div
              class="head-col"
              [ngStyle]="{ cursor: 'default', width: '80px' }"
            >
              <p class="btn-text">UW</p>
            </div>
          </th>
          <ng-container *ngFor="let col of tableColumns">
            <th *ngIf="!tableColumnsExcluded.includes(col)">
              <div
                class="head-col"
                (click)="handleSort(col)"
                [ngStyle]="{
                  justifyContent: ['Status', 'Need by'].includes(col)
                    ? 'center'
                    : 'unset'
                }"
              >
                <p class="btn-text">{{ col }}</p>
                <div class="sort-icon" *ngIf="currentSortColumn === col">
                  <img
                    src="assets/img/arrow-down.svg"
                    alt="sort-icon"
                    [ngStyle]="{
                      transform:
                        sortOrder === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
                      transitionDuration: '0.4s'
                    }"
                  />
                </div>
              </div>
            </th>
          </ng-container>
          <th *ngIf="!isTeamsQueue">
            <div class="head-col" (click)="handleTableCollapseAndExpand()">
              <img
                src="assets/img/chevron-down-black.svg"
                alt="collapse-icon"
                class="table-collapse-expand-btn"
                [ngStyle]="{
                  transform: !isExpanded ? 'rotate(0deg)' : 'rotate(180deg)',
                  transitionDuration: '0.4s'
                }"
              />
            </div>
          </th>
        </tr>
      </thead>
      <tbody
        [ngClass]="{
          'default-height': !isExpanded,
          'collapsed-height': isExpanded
        }"
      >
        <ng-container *ngIf="tableData.length > 0">
          <tr *ngFor="let rowData of tableData; let tableDataIdx = index">
            <td *ngIf="isTeamsQueue">
              <div [ngStyle]="{ display: 'flex', justifyContent: 'center' }">
                <ngx-avatars
                  [name]="
                    getUWNameAvatarInitial(rowData?.['underwriterName'] ?? '')
                  "
                  size="32"
                  [round]="true"
                  [style]="{
                    fontFamily: 'Gibson',
                    fontSize: '14px',
                    fontWeight: '600',
                    fontStyle: 'normal',
                    backgroundColor: '#F2F4F7',
                    color: '#667085',
                    border: '0.5px solid rgba(0, 0, 0, 0.08)',
                    overflow: 'hidden'
                  }"
                  [ngStyle]="{ display: 'block', width: '32px' }"
                  (click)="
                    !isRestrictedRoleSubmission &&
                      handleReassignClick($event, tableDataIdx)
                  "
                ></ngx-avatars>
                <div
                  class="reassign-refer-popup"
                  *ngIf="
                    tableDataIdx === reAssignReferPopupId && filterTab !== 1
                  "
                  (clickOutside)="closeReAssignReferPopup()"
                  [ngStyle]="reAssignReferPopupPositionalStyles"
                >
                  <boxxds-search-autocomplete-v2
                    [maxLength]="'200'"
                    [form]="form"
                    [isActive]="true"
                    [formSubmitted]="uwSubmitted"
                    [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
                    [items]="underWriterOptions"
                    [label]="'dashboard.label.reAssignPopupTxt' | translate"
                    [placeholder]="'submission.select' | translate"
                    [required]="true"
                    [control]="'underWriterInfo'"
                    [autocapitalize]="'off'"
                    [spellcheck]="'off'"
                    [containerStyle]="{ width: '305px' }"
                    (searchQuery)="searchUnderWriter($event)"
                    (itemSelected)="selectUnderWriter($event)"
                    (textInputChangeEventEmmiter)="searchUnderWriter($event)"
                    (currentValueChange)="selectUnderWriter($event)"
                    [currentValue]="currentUnderWriterValue"
                    [dropdownContainerStyle]="{}"
                    [labelStyle]="{ marginBottom: '8px' }"
                    [showNoResultAction]="false"
                    [showNoResultRedirectButton]="false"
                    [permissionStatus]="!isRestrictedRoleSubmission"
                    class="search-dropdown"
                  ></boxxds-search-autocomplete-v2>
                  <div class="reassign-refer-popup-btn">
                    <boxxds-button-v2
                      *ngIf="!reAssignBtnClicked"
                      [isDisabled]="!selectedUnderWriterData?.id"
                      [size]="'sm'"
                      [btnClass]="'secondary gray'"
                      [btnType]="'default'"
                      [isDropdown]="false"
                      [buttonText]="'dashboard.label.reassign' | translate"
                      [btnStyle]="{ padding: '10px 14px' }"
                      (handleClick)="handleReassignQuote(tableDataIdx)"
                    >
                    </boxxds-button-v2>
                    <button
                      *ngIf="reAssignBtnClicked"
                      class="btn-processing"
                      tabindex="0"
                      [ngStyle]="{
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #D0D5DD',
                        boxShadow: '0px 1px 2px 0px #1018280D',
                        color: '#2D2D39',
                        borderRadius: '8px',
                        width: '87px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 'unset',
                        lineHeight: '20px'
                      }"
                    >
                      <boxxds-spinner></boxxds-spinner>
                    </button>
                    <boxxds-button-v2
                      *ngIf="!referBtnClicked"
                      [isDisabled]="
                        !(selectedUnderWriterData?.id && !disableReferBtn)
                      "
                      [size]="'sm'"
                      [btnClass]="'secondary gray'"
                      [btnType]="'default'"
                      [isDropdown]="false"
                      [buttonText]="'dashboard.label.refer' | translate"
                      [btnStyle]="{ padding: '10px 14px' }"
                      (handleClick)="handleReferQuote(tableDataIdx)"
                    >
                    </boxxds-button-v2>
                    <button
                      *ngIf="referBtnClicked"
                      class="btn-processing"
                      tabindex="0"
                      [ngStyle]="{
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #D0D5DD',
                        boxShadow: '0px 1px 2px 0px #1018280D',
                        color: '#2D2D39',
                        borderRadius: '8px',
                        width: '43px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 'unset',
                        lineHeight: '20px'
                      }"
                    >
                      <boxxds-spinner></boxxds-spinner>
                    </button>
                  </div>
                </div>
              </div>
            </td>
            <ng-container *ngFor="let col of tableColumns">
              <td
                *ngIf="!tableColumnsExcluded.includes(col)"
                [ngStyle]="{
                  width:
                    col === 'Need by'
                      ? '120px'
                      : col === 'Submission Date'
                      ? '150px'
                      : 'auto',
                  paddingTop: '12px',
                  paddingBottom: '12px',
                  paddingLeft: col === 'Applicant name' ? '24px' : '12px',
                  paddingRight: col === 'Applicant name' ? '24px' : '12px'
                }"
              >
                <div
                  class="body-col-data"
                  [ngStyle]="{
                    justifyContent: ['Status', 'Need by'].includes(col)
                      ? 'center'
                      : 'unset'
                  }"
                >
                  <ng-container [ngSwitch]="col">
                    <div
                      *ngSwitchCase="'Applicant name'"
                      class="text-sm-semibold ellipsis"
                      [ngStyle]="{
                        minWidth: '105px',
                        maxWidth: '105px',
                        cursor: 'pointer'
                      }"
                      [class.noPointerEvents]="isRestrictedRoleSubmission"
                      title="{{ rowData[col] }}"
                      (click)="
                        !isRestrictedRoleSubmission &&
                          handleRowClick(rowData, col, tableDataIdx)
                      "
                    >
                      {{ rowData[col] }}
                    </div>
                    <div
                      *ngSwitchCase="'Status'"
                      class="text-sm-regular"
                      [ngStyle]="{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '100px',
                        maxWidth: '100px'
                      }"
                    >
                      <app-status-badge-v2
                        (click)="handleStatusClick($event, tableDataIdx)"
                        [rowData]="rowData"
                        [columnName]="col"
                      ></app-status-badge-v2>
                      <div
                        class="status-change-popup"
                        *ngIf="tableDataIdx === statusChangePopupIdx"
                        (clickOutside)="closeStatusChangePopup()"
                        [ngStyle]="statusChangePositionalStyles"
                      >
                        <boxxds-dropdown-v2
                          [form]="form"
                          [control]="'reason'"
                          [placeholder]="'common.select' | translate"
                          (currentValueChange)="reasonHandler($event)"
                          [options]="pendingInfoReasons"
                          [label]="
                            'dashboard.label.pendingInfoReason' | translate
                          "
                          [hasScrollablePagination]="true"
                          [dropdownListStyle]="{ maxHeight: '130px' }"
                          [ngStyle]="{ width: '350px' }"
                        ></boxxds-dropdown-v2>
                        <boxxds-button-v2
                          [isDisabled]="buttonDisabled"
                          [size]="'sm'"
                          [btnClass]="'secondary gray'"
                          [btnType]="'only'"
                          [isDropdown]="false"
                          [leftImage]="'check'"
                          [ngStyle]="{
                            marginLeft: '20px',
                            alignSelf: 'flex-end'
                          }"
                          [btnStyle]="{ padding: '11px' }"
                          [leftImageStyle]="{
                            filter: buttonDisabled
                              ? 'brightness(0) saturate(100%) invert(73%) sepia(11%) saturate(405%) hue-rotate(179deg) brightness(87%) contrast(88%)'
                              : 'brightness(0) saturate(100%) invert(14%) sepia(8%) saturate(1361%) hue-rotate(202deg) brightness(98%) contrast(89%)'
                          }"
                          (handleClick)="handleStatusChange(tableDataIdx)"
                        >
                        </boxxds-button-v2>
                      </div>
                      <div
                        class="status-changed-badge"
                        *ngIf="
                          showStatusChangedBadge &&
                          statusChangedBadgeIndex === tableDataIdx
                        "
                      >
                        <img src="assets/img/check-circle.svg" alt="" />
                        <p>{{ "dashboard.info.statusChanged" | translate }}</p>
                      </div>
                    </div>
                    <div
                      *ngSwitchCase="'Source'"
                      class="text-xs-medium"
                      [ngStyle]="{
                        minWidth: '44px',
                        maxWidth: '44px',
                        borderRadius: '6px',
                        border: '1px solid #EAECF0',
                        backgroundColor: '#FFF',
                        padding: '2px 6px',
                        textAlign: 'center'
                      }"
                    >
                      {{ rowData[col] }}
                    </div>
                    <div
                      *ngSwitchCase="
                        ['Submission Date', 'Quote Date'].includes(col)
                          ? col
                          : null
                      "
                      class="multiline-data-col"
                      [ngStyle]="{
                        minWidth: '110px',
                        alignItems: 'flex-start'
                      }"
                    >
                      <p class="text-sm-regular">
                        {{ rowData[col].date ?? "-" }}
                      </p>
                      <p class="text-sm-regular" *ngIf="rowData[col]?.status">
                        <app-status-badge-v2
                          [rowData]="rowData"
                          [columnName]="col"
                        ></app-status-badge-v2>
                      </p>
                    </div>
                    <div
                      *ngSwitchCase="'Need by'"
                      class="text-sm-regular edit-cell-date"
                      [ngStyle]="{
                        width: '106px',
                        textAlign: 'center',
                        minWidth: '106px'
                      }"
                      [class.noPointerEvents]="isRestrictedRoleSubmission"
                    >
                      <p
                        *ngIf="editNeedByDatePopupIdx !== tableDataIdx"
                        (click)="needByDateClick($event, tableDataIdx, col)"
                        [style.cursor]="rowData?.[col] ? 'pointer' : 'default'"
                      >
                        {{ rowData?.[col] ?? "-" }}
                      </p>
                      <div
                        class="date-changed-badge"
                        *ngIf="
                          showDateChangedBadge &&
                          showDateChangedBadgeIndex === tableDataIdx
                        "
                      >
                        <img src="assets/img/check-circle.svg" alt="" />
                        <p>{{ "dashboard.info.dateChanged" | translate }}</p>
                      </div>
                      <boxxds-textinput-v2
                        *ngIf="editNeedByDatePopupIdx === tableDataIdx"
                        class="edit-needby-date"
                        [noBorder]="true"
                        [form]="form"
                        [control]="'needByDateInfo'"
                        [placeholder]="'common.enterDate' | translate"
                        [maxLength]="'13'"
                        [width]="'140px'"
                        [containerStyle]="{
                          borderBottom:
                            '4px solid ' +
                            (form.get('needByDateInfo')?.errors?.[
                              'invalidDate'
                            ] ||
                            form.errors?.['lessthanReceivedDateErr'] ||
                            form.errors?.['greaterthanExpiryDateErr'] ||
                            form.errors?.['lessthanEffectiveDateErr']
                              ? '#CA1329'
                              : '#0BA5EC')
                        }"
                        [inputStyle]="{ paddingLeft: '3px' }"
                        (clickOutside)="closeNeedDateInput()"
                        (click)="
                          form.get('needByDateInfo').value.length > 10
                            ? needByDateUpdate($event, tableDataIdx)
                            : ''
                        "
                      >
                      </boxxds-textinput-v2>
                      <p
                        class="footer-text"
                        *ngIf="
                          editNeedByDatePopupIdx === tableDataIdx &&
                          !(
                            form.errors?.['lessthanReceivedDateErr'] ||
                            form.errors?.['greaterthanExpiryDateErr'] ||
                            form.errors?.['lessthanEffectiveDateErr']
                          )
                        "
                        [ngStyle]="{
                          color: form.get('needByDateInfo')?.errors?.[
                            'invalidDate'
                          ]
                            ? '#CA1329'
                            : '#667085'
                        }"
                      >
                        {{
                          ("dashboard.label.format:" | translate) +
                            this.shortDateFormat_2
                        }}
                      </p>
                      <p
                        class="footer-text"
                        *ngIf="
                          editNeedByDatePopupIdx === tableDataIdx &&
                          (form.errors?.['lessthanReceivedDateErr'] ||
                            form.errors?.['greaterthanExpiryDateErr'] ||
                            form.errors?.['lessthanEffectiveDateErr']) &&
                          !form.get('needByDateInfo')?.errors?.['invalidDate']
                        "
                        [ngStyle]="{
                          color: '#CA1329'
                        }"
                      >
                        {{ getDateMismatchErrorMsg(form.errors) }}
                      </p>
                    </div>
                    <div
                      *ngSwitchCase="
                        ['Industry', 'Referred to'].includes(col) ? col : null
                      "
                      class="text-sm-regular ellipsis"
                      [ngStyle]="{ minWidth: '85px', maxWidth: '85px' }"
                      title="{{ rowData[col] }}"
                    >
                      {{ rowData[col] }}
                    </div>
                    <div
                      *ngSwitchCase="
                        ['Revenue', 'Limit', 'Premium'].includes(col)
                          ? col
                          : null
                      "
                      class="text-sm-regular"
                      [ngStyle]="{
                        minWidth: '65px',
                        maxWidth: '65px',
                        textAlign: 'right'
                      }"
                    >
                      {{
                        (
                          0 | currency: currency : "symbol-narrow" : "1.0-0"
                        ).charAt(0)
                      }}{{ rowData[col] | abbreviateNumber: 2 }}
                    </div>
                    <div *ngSwitchCase="'Broker'" class="multiline-data-col">
                      <p
                        class="text-sm-regular ellipsis"
                        [ngStyle]="{ minWidth: '80px', maxWidth: '80px' }"
                        title="{{ rowData?.[col]?.producer ?? '-' }}"
                      >
                        {{ rowData?.[col]?.producer ?? "-" }}
                      </p>
                      <div
                        [ngStyle]="{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '4px'
                        }"
                      >
                        <p
                          class="text-sm-regular-lite ellipsis"
                          [ngStyle]="{ minWidth: '90px', maxWidth: '90px' }"
                          title="{{ rowData?.[col]?.teamName ?? '-' }}"
                        >
                          {{ rowData?.[col]?.teamName ?? "-" }}
                        </p>
                        <div>
                          <img
                            src="assets/img/info-circle.svg"
                            alt="info-icon"
                            [ngStyle]="{
                              width: '12px',
                              height: '12px',
                              cursor: 'pointer'
                            }"
                            (mouseenter)="tooltipInfo.showTooltip($event)"
                          />
                        </div>
                        <app-info-tooltip-v2
                          #tooltipInfo
                          [tooltipObject]="setupTooltipBranch(rowData)"
                        ></app-info-tooltip-v2>
                      </div>
                    </div>
                    <div
                      *ngSwitchCase="'Brokerage'"
                      class="text-sm-regular ellipsis"
                      [ngStyle]="{ minWidth: '115px', maxWidth: '115px' }"
                      title="{{ rowData[col] }}"
                    >
                      {{ rowData[col] }}
                    </div>
                    <div
                      *ngSwitchCase="'Policy Period'"
                      class="text-sm-regular ellipsis"
                      [ngStyle]="{ minWidth: '250px', maxWidth: '250px' }"
                      title="{{ rowData[col] }}"
                    >
                      {{ rowData[col] }}
                    </div>
                    <div *ngSwitchDefault class="text-sm-regular">
                      {{ rowData[col] }}
                    </div>
                  </ng-container>
                </div>
              </td>
            </ng-container>
            <td *ngIf="!isTeamsQueue">
              <div
                class="reassign-btn"
                [class.noPointerEvents]="isRestrictedRoleSubmission"
                (click)="
                  !isRestrictedRoleSubmission &&
                    handleReassignClick($event, tableDataIdx)
                "
              >
                <ng-container *ngIf="isRestrictedRoleSubmission">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M10.0013 10.8333C10.4615 10.8333 10.8346 10.4602 10.8346 9.99992C10.8346 9.53968 10.4615 9.16659 10.0013 9.16659C9.54106 9.16659 9.16797 9.53968 9.16797 9.99992C9.16797 10.4602 9.54106 10.8333 10.0013 10.8333Z"
                      stroke="#98A2B3"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.0013 4.99992C10.4615 4.99992 10.8346 4.62682 10.8346 4.16659C10.8346 3.70635 10.4615 3.33325 10.0013 3.33325C9.54106 3.33325 9.16797 3.70635 9.16797 4.16659C9.16797 4.62682 9.54106 4.99992 10.0013 4.99992Z"
                      stroke="#98A2B3"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.0013 16.6666C10.4615 16.6666 10.8346 16.2935 10.8346 15.8333C10.8346 15.373 10.4615 14.9999 10.0013 14.9999C9.54106 14.9999 9.16797 15.373 9.16797 15.8333C9.16797 16.2935 9.54106 16.6666 10.0013 16.6666Z"
                      stroke="#98A2B3"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </ng-container>
                <ng-container *ngIf="!isRestrictedRoleSubmission">
                  {{
                    filterTab === 1
                      ? ("dashboard.label.followUp" | translate)
                      : ("dashboard.label.reassign" | translate)
                  }}
                </ng-container>
              </div>
              <div
                class="reassign-refer-popup"
                *ngIf="tableDataIdx === reAssignReferPopupId && filterTab !== 1"
                (clickOutside)="closeReAssignReferPopup()"
                [ngStyle]="reAssignReferPopupPositionalStyles"
              >
                <boxxds-search-autocomplete-v2
                  [maxLength]="'200'"
                  [form]="form"
                  [isActive]="true"
                  [formSubmitted]="uwSubmitted"
                  [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
                  [items]="underWriterOptions"
                  [label]="'dashboard.label.reAssignPopupTxt' | translate"
                  [placeholder]="'submission.select' | translate"
                  [required]="true"
                  [control]="'underWriterInfo'"
                  [autocapitalize]="'off'"
                  [spellcheck]="'off'"
                  [containerStyle]="{ width: '305px' }"
                  (searchQuery)="searchUnderWriter($event)"
                  (itemSelected)="selectUnderWriter($event)"
                  (textInputChangeEventEmmiter)="searchUnderWriter($event)"
                  (currentValueChange)="selectUnderWriter($event)"
                  [currentValue]="currentUnderWriterValue"
                  [dropdownContainerStyle]="{}"
                  [labelStyle]="{ marginBottom: '8px' }"
                  [showNoResultAction]="false"
                  [showNoResultRedirectButton]="false"
                  [permissionStatus]="!isRestrictedRoleSubmission"
                  class="search-dropdown"
                ></boxxds-search-autocomplete-v2>
                <div class="reassign-refer-popup-btn">
                  <boxxds-button-v2
                    *ngIf="!reAssignBtnClicked"
                    [isDisabled]="!selectedUnderWriterData?.id"
                    [size]="'sm'"
                    [btnClass]="'secondary gray'"
                    [btnType]="'default'"
                    [isDropdown]="false"
                    [buttonText]="'dashboard.label.reassign' | translate"
                    [btnStyle]="{ padding: '10px 14px' }"
                    (handleClick)="handleReassignQuote(tableDataIdx)"
                  >
                  </boxxds-button-v2>
                  <button
                    *ngIf="reAssignBtnClicked"
                    class="btn-processing"
                    tabindex="0"
                    [ngStyle]="{
                      backgroundColor: '#FFFFFF',
                      border: '1px solid #D0D5DD',
                      boxShadow: '0px 1px 2px 0px #1018280D',
                      color: '#2D2D39',
                      borderRadius: '8px',
                      width: '87px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: 'unset',
                      lineHeight: '20px'
                    }"
                  >
                    <boxxds-spinner></boxxds-spinner>
                  </button>
                  <boxxds-button-v2
                    *ngIf="!referBtnClicked"
                    [isDisabled]="
                      !(selectedUnderWriterData?.id && !disableReferBtn)
                    "
                    [size]="'sm'"
                    [btnClass]="'secondary gray'"
                    [btnType]="'default'"
                    [isDropdown]="false"
                    [buttonText]="'dashboard.label.refer' | translate"
                    [btnStyle]="{ padding: '10px 14px' }"
                    (handleClick)="handleReferQuote(tableDataIdx)"
                  >
                  </boxxds-button-v2>
                  <button
                    *ngIf="referBtnClicked"
                    class="btn-processing"
                    tabindex="0"
                    [ngStyle]="{
                      backgroundColor: '#FFFFFF',
                      border: '1px solid #D0D5DD',
                      boxShadow: '0px 1px 2px 0px #1018280D',
                      color: '#2D2D39',
                      borderRadius: '8px',
                      width: '43px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: 'unset',
                      lineHeight: '20px'
                    }"
                  >
                    <boxxds-spinner></boxxds-spinner>
                  </button>
                </div>
              </div>
            </td>
            <div
              class="unread-indicator ml-1"
              [ngStyle]="{
                top:
                  showStatusChangedBadge &&
                  statusChangedBadgeIndex === tableDataIdx
                    ? '64%'
                    : '50%'
              }"
              *ngIf="rowData?.['isUnread'] && !isRestrictedRoleSubmission"
            ></div>
          </tr>
        </ng-container>
        <ng-container *ngIf="tableData.length === 0">
          <tr>
            <td
              [colSpan]="tableColumns.length"
              [ngStyle]="{
                height: !isDefaultHeight && !isExpanded ? 'auto' : '504px'
              }"
            >
              <app-ripple-animation
                [message]="noDataMessage"
              ></app-ripple-animation>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="loader" *ngIf="isLoading"></div>
</div>
