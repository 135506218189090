import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserToken } from '../entities/user-token';
import jwt_decode from 'jwt-decode';
import { LocalStorageService } from 'src/app/services/localstorage-service';

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  private access_token = 'access_token';
  private refresh_token = 'refresh_token';
  private id_token = 'idtoken';
  private token: string;
  private baseurl = environment.msSecurityBseUrl;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
  ) {}

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
  };

  GetAuthToken(
    code,
    quoteFlowSteps = environment.defaultFlow,
    redirectUrl = environment.cognitoRedirectUrl.toString(),
  ): Observable<UserToken> {
    let body = new URLSearchParams();
    body.set('code', code);
    body.set('redirectUri', redirectUrl);

    return this.http
      .post<UserToken>(
        this.baseurl + environment.authenticationUrl + '?' + body.toString(),
        null,
        this.httpOptions,
      )
      .pipe(
        map((data: any) => {
          localStorage.clear();
          this.localStorageService.setQuoteFlowSteps(quoteFlowSteps);
          localStorage.setItem(this.access_token, data.data.access_token);
          localStorage.setItem(this.refresh_token, data.data.refresh_token);
          localStorage.setItem(this.id_token, data.data.id_token);
          localStorage.setItem('signedin', 'signedin');

          const tokenData = this.getDecodedAccessToken(data.data.id_token);
          const systemUserId = tokenData['custom:systemuserid'] ?? '';
          const boxxUserId = tokenData['custom:boxxuserid'] ?? '';
          localStorage.setItem('sid', systemUserId);
          localStorage.setItem('bid', boxxUserId);

          this.token = data.data.access_token;
          return data;
        }),
        catchError(this.errorHandl),
      );
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return {};
    }
  }

  async logout() {
    await this.handleClearStorage();
    window.location.href = environment.logoutUrl;

    this.token = null;
  }

  async handleClearStorage() {
    localStorage.removeItem(this.access_token);
    localStorage.removeItem(this.refresh_token);
    localStorage.removeItem(this.id_token);
    localStorage.removeItem('signedin');
    localStorage.removeItem('sid');
    localStorage.removeItem('bid');
  }

  isAuthenticated(): boolean {
    const signedin = localStorage.getItem('signedin');
    return !!signedin;
  }

  getToken(): string {
    this.token = localStorage.getItem(this.access_token);

    return this.token;
  }

  getIDToken(): string {
    return localStorage.getItem(this.id_token);
  }

  getRefreshToken(): string {
    return localStorage.getItem(this.refresh_token);
  }

  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return throwError(() => {
      return errorMessage;
    });
  }

  refreshAccessToken(refreshToken: string): Observable<UserToken> {
    return this.http.post<UserToken>(
      this.baseurl +
        environment.revalidateUrl +
        '?refreshToken=' +
        refreshToken,
      null,
      this.httpOptions,
    );
  }
}
