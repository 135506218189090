import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Pagination } from 'src/app/entities/boxx-response';
import { AlertService } from 'src/app/services/alert.service';
import { BrokerageProducerTeamsService } from 'src/app/services/brokerage-producer-team.service';
import { BrokerageProducerService } from 'src/app/services/brokerage-producer.service';
import { getAlertHead, getErrorMessage } from 'src/app/utils/utils';

@Component({
  selector: 'app-branch-teams-table',
  templateUrl: './branch-teams-table.component.html',
  styleUrls: ['./branch-teams-table.component.less'],
})
export class BranchTeamsTableComponent implements OnInit, OnChanges {
  @Input() tableData: { [key: string]: any }[] = [];
  @Input() tableColumns: string[] = [];
  @Input() tableColumnsExcluded: string[] = [];
  @Input() tableColumnsExcludedSort: string[] = [];
  @Input() noDataMessage: string = 'No data available.';
  @Input() isLoading: boolean = false;
  @Input() pagination: Pagination;
  @Input() branchId: number;

  @Output() getTableDataOnSort = new EventEmitter();
  @Output() getTableDataOnRowClick = new EventEmitter();
  @Output() handleUpdate = new EventEmitter();
  @Output() pageChanged = new EventEmitter();

  currentSortColumn: string = '';
  sortOrder: string = '';
  currentPage: number = 1;
  isEditableColumn: number = undefined;
  form: FormGroup;
  editNameTxt: string = '';
  paginationId = 'pagination';

  addProducerPopupId = null;
  addProducerPopupPositionalStyles = { top: '0px' };

  producerSubmitted: boolean = false;
  selectedProducerData: any;
  currentProducerValue = '';
  producerOptions: Array<any> = [];
  producerErrMsg = '';
  producerAddBtnClicked: boolean = false;
  producerAddBtn2Clicked: boolean = false;
  addedProducersArray: Array<any> = [];

  constructor(
    private fb: FormBuilder,
    private brokerageProducerService: BrokerageProducerService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private brokerageProducerTeamsService: BrokerageProducerTeamsService,
  ) {
    this.form = this.fb.group({
      producerInfo: [''],
    });
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.currentPage =
      changes?.['pagination']?.currentValue?.currentPage ?? this.currentPage;
  }

  async handleSort(tableColumn: string): Promise<void> {
    if (tableColumn === this.currentSortColumn) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.currentSortColumn = tableColumn;
      this.sortOrder = 'asc';
    }
    this.getTableDataOnSort.emit({
      sortColumn: this.currentSortColumn,
      sortOrder: this.sortOrder,
      page: this.currentPage,
    });
  }

  async handleRowClick(
    rowData: { [key: string]: any },
    column: string,
    rowIndex: number,
  ) {
    this.getTableDataOnRowClick.emit({ rowData, column });
  }

  handleClearSort() {
    this.currentSortColumn = '';
    this.sortOrder = '';
    this.getTableDataOnSort.emit({
      sortColumn: this.currentSortColumn,
      sortOrder: this.sortOrder,
      page: this.currentPage,
    });
  }

  handleEditName(rowIndex: number, colData: string) {
    this.editNameTxt = colData;
    this.isEditableColumn = rowIndex;
  }
  handleDeclineEditName() {
    this.editNameTxt = '';
    this.isEditableColumn = undefined;
  }
  handleSaveEditName() {
    this.handleUpdate.emit({
      index: this.isEditableColumn,
      name: this.editNameTxt,
    });
    this.isEditableColumn = undefined;
    this.editNameTxt = '';
  }

  handleNameChange(event: KeyboardEvent) {
    this.editNameTxt = event.target?.['value'];
  }

  onPageChange(pageNumber: number) {
    this.editNameTxt = '';
    this.isEditableColumn = undefined;
    this.currentPage = pageNumber;
    this.pageChanged.emit({
      sortColumn: this.currentSortColumn,
      sortOrder: this.sortOrder,
      page: this.currentPage,
    });
  }

  handleAddProducerClick(event, index) {
    event.stopPropagation();
    this.clearAddProducerPopupdata();
    this.form.controls['producerInfo'].setValue('');
    this.addProducerPopupId = index;
    let topValue = event.pageY;
    if (topValue > 500) {
      this.addProducerPopupPositionalStyles = {
        top: `${topValue - 50}px`,
      };
    } else {
      this.addProducerPopupPositionalStyles = {
        top: `${topValue}px`,
      };
    }
  }
  closeAddProducerPopup() {
    this.addProducerPopupId = null;
    this.clearAddProducerPopupdata();
    this.form.controls['producerInfo'].setValue('');
  }

  searchProducer(searchValue) {
    if (searchValue === '') {
      this.producerOptions = [];
      this.form.controls['producerInfo'].setValue('');
    }
    this.clearAddProducerPopupdata();
    if (searchValue.length >= 3) {
      const searchKey = searchValue.trim();
      this.brokerageProducerService
        .GetFilterBrokerageProducers(this.branchId, searchKey, true, 1, 20, '')
        .subscribe({
          next: (response) => {
            this.producerOptions = response?.data?.map((dataObj) => ({
              main_text: dataObj.firstName + ' ' + dataObj.lastName,
              firstName: dataObj.firstName,
              lastName: dataObj.lastName,
              id: dataObj.id,
            }));
          },
          error: (error) => {
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
          },
        });
    } else {
      this.producerOptions = [];
      return;
    }
  }

  async selectProducer(value) {
    let selectedValue = this.producerOptions.find((x) => x.id === value);
    this.selectedProducerData = selectedValue;
    this.currentProducerValue = selectedValue.main_text;
    this.form.controls['producerInfo'].setValue(this.currentProducerValue);
    this.producerErrMsg = '';
  }

  clearAddProducerPopupdata() {
    this.producerErrMsg = '';
    this.producerSubmitted = false;
    this.selectedProducerData = null;
    this.currentProducerValue = '';
    this.producerAddBtnClicked = false;
    this.producerAddBtn2Clicked = false;
  }

  handleAddProducer(index: number, continueAdding: boolean = false) {
    if (this.selectedProducerData) {
      // this.validateProducer() &&
      const payload = {
        brokerageBranchTeamId: this.tableData[index]?.['id'],
        brokerageProducerId: this.selectedProducerData?.id,
      };
      this.brokerageProducerTeamsService.addProducerTeam(payload).subscribe({
        next: (response) => {
          this.tableData[index]['Producers']?.push(
            this.selectedProducerData?.main_text,
          );
          this.tableData[index]['# of Producers'] =
            (Number(this.tableData[index]['# of Producers']) || 0) + 1;
          this.addedProducersArray.push(this.selectedProducerData);
          const alertData = {
            type: 'success',
            headerText: 'success!',
            bodyText: 'brokerage.branch.success.updateMessage',
          };
          this.alertService.clearAlerts(-1);
          this.alertService.addAlert(alertData);
          if (continueAdding === false) {
            this.closeAddProducerPopup();
          } else {
            this.clearAddProducerPopupdata();
            this.form.controls['producerInfo'].setValue('');
          }
        },
        error: (error) => {
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: getErrorMessage(error),
          };
          this.alertService.addAlert(alertData);
        },
      });
    } else {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: this.translateService.instant(
          'brokerage.producer.error.selectValidProducer',
        ),
      };
      this.alertService.addAlert(alertData);
    }
  }

  validateProducer(): boolean {
    if (
      (this.tableData[0]?.['AllBranchTeamList']?.length > 0 &&
        this.tableData[0]?.['AllBranchTeamList']?.some(
          (branchTeam) =>
            branchTeam.BrokerageProducerTeams?.some(
              (producerTeam) =>
                producerTeam?.brokerageProducer?.id ===
                this.selectedProducerData?.id,
            ),
        )) ||
      this.addedProducersArray.some(
        (prod) => prod.id === this.selectedProducerData?.id,
      )
    ) {
      return false;
    }
    return true;
  }
}
