import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import {
  SYSTEM_USER_TYPES,
  USER_ROLES,
  USER_ROLES_NAME,
} from 'src/app/constants/security/systemUserType';
import { BoxxUser } from 'src/app/entities/boxx-user';
import { Role } from 'src/app/entities/role';
import { RolePermission } from 'src/app/entities/role-permission';
import { SystemUser } from 'src/app/entities/system-user';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import { InsuredService } from 'src/app/services/insured.service';
import { PolicyDashboardService } from 'src/app/services/policy-dashboard.service';
import { RolePermissionService } from 'src/app/services/role-permissions.service';
import { RoleService } from 'src/app/services/roles.service';
import { SystemUserService } from 'src/app/services/system-user.service';
import { PolicyStatsService } from 'src/app/services/policy-stats.service';
import { PolicyQuoteCount } from 'src/app/entities/policy';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { DomainsService } from 'src/app/services/domains.service';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { getFormattedPolicyPeriod } from 'src/app/utils/formatDate';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { formatDateMoment } from 'src/app/utils/formatDate';
import { NewQuoteService } from 'src/app/services/new-quote.service';
import { AlertService } from 'src/app/services/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.less'],
})
export class DashboardHomeComponent implements OnInit {
  form: FormGroup;
  infoTile: any;
  cardData: any;
  underWriterOptions: any[] = [{ key: '', value: '', id: 0 }];
  loggedInUser: { name: string; photoUrl: string; title: string };
  systemUser: SystemUser;
  boxxUser: BoxxUser;
  underWriters: any;
  role: Role;
  permissions: RolePermission[];
  showSpinner: boolean = true;
  redirectPath: string = null;
  underWriterName: string = '';
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  permissionKeyList: string[] = [];
  quoteFlowSteps;
  shortDateFormat: string = '';
  longDateFormat: string = '';
  sendQuoteDropdownOptions = [
    { key: 'Quote Package', value: 'Quote Package' },
    { key: 'Selected option', value: 'Selected option' },
    { key: 'Comparison', value: 'Comparison' },
  ];
  startQuoteBtnTxt: string = '';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public policyService: PolicyDashboardService,
    public insuredService: InsuredService,
    public roleService: RoleService,
    public rolePermissionService: RolePermissionService,
    public systemUserService: SystemUserService,
    public boxxUserService: BoxxUserService,
    public policyStatsService: PolicyStatsService,
    private store: Store,
    private domainsService: DomainsService,
    private localStorageService: LocalStorageService,
    private newQuoteService: NewQuoteService,
    private alertService: AlertService,
    private translateService: TranslateService,
  ) {
    this.form = this.fb.group({
      underwriter: [''],
    });
  }

  ngOnInit(): void {
    const saveDraftMsg = this.newQuoteService.getQuoteMessage();
    this.newQuoteService.setquoteMessage([]);
    if (saveDraftMsg && saveDraftMsg.length > 0) {
      saveDraftMsg.forEach((msg) => {
        this.alertService.addAlert(msg);
      });
    }
    if (this.activatedRoute.snapshot.queryParams['mock'] === 'false') {
      this.localStorageService.setMockExternalAPIs('false');
    } else {
      this.localStorageService.setMockExternalAPIs('true');
    }

    this.quoteFlowSteps = this.localStorageService.getQuoteFlowSteps();
    this.localStorageService.removeTimeZone();
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.permissionKeyList = Object.keys(data.litePermissionList);
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    const systemUsrId = localStorage.getItem('sid');
    this.systemUserService.Get(systemUsrId).subscribe((data) => {
      this.systemUser = data.data;
      this.underWriterName = data.data.name;
      this.domainsService
        .GetByDomainCode('SYSTEMUSERTYPE')
        .subscribe((data) => {
          const domainData = data.data?.map((dataObj) => ({
            description: dataObj.description,
            id: dataObj.id,
          }));
          const getBoxxUserSysUsrId = domainData.filter(
            (type) =>
              type.description.toLowerCase() ==
              SYSTEM_USER_TYPES.BoxxUser.toLowerCase(),
          )[0]?.id;
          if (this.systemUser.SystemUserType.id === getBoxxUserSysUsrId) {
            this.boxxUser = this.systemUser.boxxUser;
            this.loggedInUser = {
              name: this.boxxUser.firstName + ' ' + this.boxxUser.lastName,
              photoUrl: '',
              title: this.boxxUser.title,
            };
            this.role = this.systemUser.role;
            this.startQuoteBtnTxt = this.translateService.instant(
              'common.startNewQuote',
            );
            if (this.checkRestrictedRoleSubmission() === true) {
              this.startQuoteBtnTxt = this.translateService.instant(
                'common.startNewSubmission',
              );
            }
          }
          const boxxUsrId = localStorage.getItem('bid');
          const sort = 'firstName:asc,lastName:asc';
          this.boxxUserService.Get(boxxUsrId).subscribe((boxxUsrData) => {
            this.boxxUserService
              .GetUnderwriterlist(
                boxxUsrId,
                1,
                10,
                sort,
                '',
                -1,
                USER_ROLES.Underwriter,
              )
              .subscribe((data) => {
                let tableData = [];
                const currentUnderWriter = [];
                data.data?.map((dataObject) => {
                  if (dataObject.id === +boxxUsrId) {
                    currentUnderWriter.push(dataObject);
                  }
                  tableData.push({
                    key: dataObject.firstName + ' ' + dataObject.lastName,
                    value: dataObject.firstName + ' ' + dataObject.lastName,
                    id: dataObject.id,
                  });
                });
                this.underWriters = data.data.reduce((accumulator, value) => {
                  return {
                    ...accumulator,
                    [value.firstName + ' ' + value.lastName]: value,
                  };
                }, {});

                if (currentUnderWriter.length > 0) {
                  this.form
                    .get('underwriter')
                    .setValue(currentUnderWriter[0].name);
                }
                this.underWriterOptions = tableData;
              });
          });

          this.rolePermissionService
            .GetByRoleId(this.role.id)
            .subscribe((data) => {
              this.permissions = data.data;
            });
          this.policyService
            .GetAllByUnderwriter(this.systemUser.boxxUser.id, 1, 12)
            .subscribe((data) => {
              this.showSpinner = false;
              const tableData = data.data?.map((dataObj) => ({
                businessName: dataObj.policy.insured.companyName
                  ? dataObj.policy.insured.companyName
                  : dataObj.policy.insured.firstName +
                    ' ' +
                    dataObj.policy.insured.lastName,
                status: dataObj.policy.PolicyStatus.description.toLowerCase(),
                quoteStatus: dataObj?.policyRiskTrxes?.quoteStatus?.description,
                policyPeriodId: dataObj?.policyPeriod?.id,
                id: dataObj.policy.pkgPolicyNumber,
                statusType: this.getStatusType(
                  dataObj.policy.PolicyStatus.description,
                ),
                policyPeriod: dataObj?.policyPeriod?.effectiveExpiryDatesTBDFlag
                  ? 'TBD'
                  : getFormattedPolicyPeriod(
                      dataObj?.policyPeriod,
                      this.longDateFormat,
                    ),

                premium: dataObj.policyRiskTrxes.policyRisk[0].premium,
                brokerage: dataObj.policy.brokerageBOR.name,
                producer:
                  dataObj.policy.brokerageProducerBOR.firstName +
                  ' ' +
                  dataObj.policy.brokerageProducerBOR.lastName,
                products: dataObj.policyRiskTrxes.policyRisk,
                underwriter:
                  dataObj.underwriter[0].firstName +
                  ' ' +
                  dataObj.underwriter[0].lastName,
                brokerageCommission:
                  (dataObj?.policyRiskTrxes?.['policyRisk'][0]
                    ?.brokerCommissionPerc ?? 0) * 100,
              }));

              this.cardData = tableData;
            });

          this.policyStatsService.Get().subscribe((data) => {
            const tableData: PolicyQuoteCount = data.data;

            this.infoTile = [];

            if (this.permissionKeyList.includes('Policy')) {
              this.infoTile.push({
                heading: 'Upcoming renewals',
                count: tableData.upcomingRenewals.toString(),
                permissionName: 'Policy',
              });
            }
            if (this.permissionKeyList.includes('Quote')) {
              this.infoTile.push(
                {
                  heading: 'Open quotes',
                  count: tableData.openQuotes.toString(),
                  permissionName: 'Quote',
                },
                {
                  heading: 'New referrals',
                  count: tableData.newReferrals.toString(),
                  permissionName: 'Quote',
                },
              );
            }
          });
        });
    });
  }

  ngOnDestroy(): void {
    this.alertService.clearAlerts(-1);
  }

  getStatusType(status) {
    switch (status.toLowerCase()) {
      case 'new submissions':
      case 'reinstated':
        return 'default';
      case 'bound':
      case 'referral':
        return 'primary';
      case 'quote':
      case 'quoted':
        return 'secondary';
      case 'renewed':
      case 'renewal quote':
      case 'renewal app':
        return 'warning';
      case 'quote closed':
      case 'cancelled':
      case 'lapsed':
      case 'declined':
      case 'closed':
      case 'not taken up':
        return 'inactive';
    }
    return 'default';
  }
  // handleSubmit(e: any) {}

  dropdownValueChanged(event) {
    const underwriter = this.underWriters[event];
    this.policyService
      .GetAllByUnderwriter(underwriter.id, 1, 12, '')
      .subscribe((data) => {
        const tableData = data.data?.map((dataObj) => ({
          businessName: dataObj.policy.insured.companyName
            ? dataObj.policy.insured.companyName
            : dataObj.policy.insured.firstName +
              ' ' +
              dataObj.policy.insured.lastName,
          status: dataObj.policy.PolicyStatus.description,
          quoteStatus: dataObj?.policyRiskTrxes?.quoteStatus?.description,
          policyPeriodId: dataObj?.policyPeriod?.id,
          id: dataObj.policy.pkgPolicyNumber,
          statusType: this.getStatusType(
            dataObj.policy.PolicyStatus.description,
          ),
          policyPeriod: getFormattedPolicyPeriod(
            dataObj.policyPeriod,
            this.longDateFormat,
          ),
          premium: dataObj.policyRiskTrxes.policyRisk[0].premium,
          brokerage: dataObj.policy.brokerageBOR.name,
          producer:
            dataObj.policy.brokerageProducerBOR.firstName +
            ' ' +
            dataObj.policy.brokerageProducerBOR.lastName,
          products: dataObj.policyRiskTrxes.policyRisk,
          underwriter:
            dataObj.underwriter[0].firstName +
            ' ' +
            dataObj.underwriter[0].lastName,
          brokerageCommission:
            (dataObj?.policyRiskTrxes?.['policyRisk'][0]
              ?.brokerCommissionPerc ?? 0) * 100,
        }));

        this.cardData = tableData;
      });
  }

  handleNewQuoteClick() {
    this.startQuoteBtnTxt = '';
    this.store.dispatch(new CreateQuoteAction.ResetState());
    if (this.quoteFlowSteps === '4') {
      this.router.navigate(['../quote4flow'], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
      return;
    } else if (this.quoteFlowSteps === 'workflow3') {
      this.newQuoteService.clearInsuredId();
      this.router.navigate(['../workflow3'], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
      return;
    }
    this.router.navigate(['../quote/new'], {
      relativeTo: this.activatedRoute,
      skipLocationChange: true,
    });
  }

  handleNavigation(heading) {
    let quoteFlowSteps = this.localStorageService.getQuoteFlowSteps();
    switch (heading.toLowerCase()) {
      case 'upcoming renewals':
        this.store.dispatch(
          new CreateQuoteAction.setDashboardPath({
            path: 'upcoming renewals',
            underwriter: this.underWriterName,
          }),
        );
        if (quoteFlowSteps === 'workflow3') {
          this.router.navigate([`/dashboard/workflow3/policies`], {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          });
        } else {
          this.router.navigate([`/dashboard/policies/`], {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          });
        }
        break;

      case 'open quotes':
        this.store.dispatch(
          new CreateQuoteAction.setDashboardPath({
            path: 'open quotes',
            underwriter: this.underWriterName,
          }),
        );
        if (quoteFlowSteps === 'workflow3') {
          this.router.navigate([`/dashboard/workflow3/quotes`], {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          });
        } else {
          this.router.navigate([`/dashboard/quotes`], {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          });
        }

        break;

      case 'new referrals':
        this.store.dispatch(
          new CreateQuoteAction.setDashboardPath({
            path: 'new referrals',
            underwriter: this.underWriterName,
          }),
        );
        if (quoteFlowSteps === 'workflow3') {
          this.router.navigate([`/dashboard/workflow3/quotes`], {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          });
        } else {
          this.router.navigate([`/dashboard/quotes`], {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          });
        }
        break;

      default:
        this.router.navigate([`/dashboard`], {
          relativeTo: this.activatedRoute,
          skipLocationChange: true,
        });
        break;
    }
    return this.redirectPath;
  }
  checkRestrictedRoleSubmission(): boolean {
    if (this.role) {
      let roleName = this.role?.name ? this.role?.name.toLowerCase() : '';
      if (
        roleName === USER_ROLES_NAME.UnderwriterAssistant ||
        roleName === USER_ROLES_NAME.UnderwriterAssistantShort ||
        roleName === USER_ROLES_NAME.TechinicalAssistant ||
        roleName === USER_ROLES_NAME.TechinicalAssistantShort
      )
        return true;
      else return false;
    } else return false;
  }
}
