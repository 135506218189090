import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { convertDate, getFormattedPeriod } from 'src/app/utils/formatDate';

@Component({
  selector: 'app-modal-exit-quoting-process',
  templateUrl: './modal-exit-quoting-process.component.html',
  styleUrls: ['./modal-exit-quoting-process.component.less'],
})
export class ModalExitQuotingProcessComponent {
  @Input() showModal: boolean = false;

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleCancel = new EventEmitter<any>();
  @Output() handleExit = new EventEmitter<any>();

  constructor(private store: Store) {}

  closeModal() {
    this.handleClose.emit();
  }
  handleExitClick() {
    this.handleExit.emit();
  }
  handleCancelClick() {
    this.handleCancel.emit();
  }
}
