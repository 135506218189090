import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BaseService } from './base-services';
import { QuoteOption } from '../entities/quote-option';

@Injectable({
  providedIn: 'root',
})
export class QuoteOptionServicesService extends BaseService<QuoteOption> {
  constructor(http: HttpClient) {
    const baseurl =
      environment.msPolicyBaseUrl + environment.quoteOptionServicesPathUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  CreateQuoteOptionServices(data): Observable<QuoteOption> {
    const url = this.baseurl;
    return this.http
      .post<QuoteOption>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  DeleteQuoteOptionServices(quoteOptionServicesId) {
    const url = this.baseurl + quoteOptionServicesId + '?force=1';
    return this.http
      .delete<QuoteOption>(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
