import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Renderer2,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Pagination } from 'src/app/entities/boxx-response';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import {
  StatusMapping,
  getPolicyStatusTypeV2,
  mapPolicy,
} from 'src/app/constants/policyStatus';

import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import { NewQuoteService } from 'src/app/services/new-quote.service';
import { getDashboardPath } from 'src/app/store/create-quote/create-quote.selector';
import { PolicyDashboardService } from 'src/app/services/policy-dashboard.service';
import {
  convertDate,
  formatDateMoment,
  getDateString,
  getFormattedDate,
  getFormattedPolicyPeriod,
} from 'src/app/utils/formatDate';
import {
  makeSorting,
  formatAmountWithCurrency,
  excelFileDownload,
  getAlertHead,
  getErrorMessage,
  valInDigits,
  removeDuplicatedKeys,
} from 'src/app/utils/utils';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import {
  MAX_DEDUCTIBLE,
  MAX_LIMIT,
  MAX_PREMIUM,
  MIN_DEDUCTIBLE,
  MIN_LIMIT,
  MIN_PREMIUM,
} from 'src/app/constants/filter-constant';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app//services/alert.service';
import { PolicyRiskTrxService } from 'src/app/services/policy-risk-trx.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { ConfRiskLimitsService } from 'src/app/services/conf-risk-limits.service';
import { DomainsService } from 'src/app/services/domains.service';
import { BrokerageDirectoryService } from 'src/app/services/brokerage-directory.service';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import { ConfDeductibleService } from 'src/app/services/conf-deductible.service';
import {
  isBranch,
  isBroker,
  isProducer,
} from 'src/app/constants/broker-constant';
import { USER_ROLES } from 'src/app/constants/security/systemUserType';
import { mapQuote } from 'src/app/constants/quoteStatus';

@Component({
  selector: 'app-policy-directory',
  templateUrl: './policy-directory.component.html',
  styleUrls: ['./policy-directory.component.less'],
})
export class PolicyDirectoryComponent implements OnInit {
  //breadcrumbs and search

  @Input() containerStyle: { [klass: string]: any } = {};
  @Output() linkClicked = new EventEmitter();

  //Banner
  @Input() btnRightImage: string = 'assets/img/plus-circle.png';
  @Input() mainHeading: string = 'Policy Directory';
  @Input() subHeading: string = 'Keep track of the policies and their status.';

  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  quoteFlowSteps;
  quoteFlowUrl = '';
  shortDateFormat: string = '';
  longDateFormat: string = '';
  placeholder = 'Search name';
  limitOptions = [];
  deductibleOptions = [];
  //Navigation
  subNavData = [
    { name: 'View all', active: true, value: 'All' },
    { name: 'New business', active: false, value: 'BOUND' },
    { name: 'Renewal', active: false, value: 'RENEWED' },
    { name: 'Cancelled', active: false, value: 'CANCELLED' },
  ];

  itemsMenu = [
    {
      label: 'Policy Directory',
      path: '/dashboard/workflow3/policy-directory',
    },
  ];

  colPolicyHeader = {
    'Policy ID': [{ key: 'policy.pkgPolicyNumber', order: '' }],
    Status: [{ key: 'policy.PolicyStatus.description', order: '' }],
    'Policy period': [{ key: 'effectiveDt', order: '' }],
    'Insured name': [
      { key: 'policy.insured.companyName', order: '' },
      { key: 'policy.insured.firstName', order: '' },
      { key: 'policy.insured.lastName', order: '' },
    ],
  };

  //Filter
  showFilter = false;
  pdtOptions = [];

  filterForm: FormGroup;
  userID: number;
  currency = '';

  //Table data
  tableData = [];
  trxData = [];
  tableHeaders = [
    {
      'Policy ID': '',
      Status: '',
      'Insured name': '',
      'Policy period': '',
      Risks: '',
      'Annual Premium': '',
    },
  ];

  statusMappingToType = {
    'NEW SUBMISSIONS': 'default',
    REINSTATED: 'default',
    BOUND: 'primary',
    REFERRAL: 'primary',
    QUOTE: 'secondary',
    QUOTED: 'secondary',
    RENEWED: 'warning',
    'RENEWAL QUOTE': 'warning',
    'RENEWAL APP': 'warning',
    'QUOTE CLOSED': 'inactive',
    CANCELLED: 'inactive',
    LAPSED: 'inactive',
    DECLINED: 'inactive',
    CLOSED: 'inactive',
    'NOT TAKEN UP': 'inactive',
  };

  addSortTo: string[] = [
    'Policy ID',
    'Policy period',
    'Status',
    'Insured name',
  ];
  totalDataCount = 0;
  pagination: Pagination;
  products;
  sortingKeys = 'followUp:desc, policy.pkgPolicyNumber:desc';
  pageNumber: number = 1;
  showTblSpinner: boolean = false;
  policyList: any[];
  leftIcon = 'assets/img/search-md.svg';
  insuredSearchFilterOn = false;
  searchQuery: any;
  underWriterOptions = [];
  brokerageBranchProducerOptions = [];
  premiumOptions = [];
  filteredOptions = [];
  filterSelectedOptions: any;
  filterInputOptions = [];

  brokerageBranchProdOptions = [];
  brokerageDirectorySearch = '';
  brokerageDirectorCurrentPage = 0;
  brokerageDirectoryTotalRecords = 0;
  underwriterSearch = '';
  underwriterCurrentPage = 0;
  underwriterTotalRecords = 0;
  underwriters = [];
  underwritersOptions = [];
  newlyCreatedPolicyId;

  statusOptions = [];
  showViewButton: boolean = true;
  isRestrictedRoleSubmission: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private fb: FormBuilder,
    private localStorageService: LocalStorageService,
    private newQuoteService: NewQuoteService,
    private policyService: PolicyDashboardService,
    private riskRegionService: RiskRegionService,
    private renderer: Renderer2,
    private alertService: AlertService,
    private policyRiskTrxService: PolicyRiskTrxService,
    private translateService: TranslateService,
    private confRiskLimitsService: ConfRiskLimitsService,
    private domainsService: DomainsService,
    public brokerageDirectoryService: BrokerageDirectoryService,
    public boxxUserService: BoxxUserService,
    private confDeductibleService: ConfDeductibleService,
  ) {
    this.filterForm = this.fb.group({
      effectiveDt: this.fb.group({
        from: [''],
        to: [''],
      }),
      expiryDt: this.fb.group({
        from: [''],
        to: [''],
      }),
      brokerageBranchProd: [[]],
      underwriter: [[]],
      status: [[]],
      risks: [[]],
      insuredName: [''],
      premiumRange: this.fb.group({
        from: [''],
        to: [''],
      }),
      limitRange: this.fb.group({
        from: [''],
        to: [''],
      }),
      deductibleRange: this.fb.group({
        from: [''],
        to: [''],
      }),
    });
  }

  async ngOnInit() {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
      this.isRestrictedRoleSubmission = data.isRestrictedRoleSubmission;
    });

    this.quoteFlowSteps = this.localStorageService.getQuoteFlowSteps();
    if (this.quoteFlowSteps === '7') {
      this.quoteFlowUrl = 'quote';
    } else if (this.quoteFlowSteps === '4') {
      this.quoteFlowUrl = 'quote4flow';
    } else {
      this.quoteFlowUrl = 'workflow3';
    }

    //side menu navigation
    const sideMenuNavigation = this.newQuoteService
      .getSideMenu()
      ?.toLowerCase();
    if (sideMenuNavigation !== '') {
      this.newQuoteService.clearSideMenu();

      switch (sideMenuNavigation) {
        case 'new business':
          this.handleNav(null, 1);
          break;
        case 'renewal':
          this.handleNav(null, 2);
          break;
        case 'cancelled':
          this.handleNav(null, 3);
          break;
        default:
          break;
      }
    }
    this.store.pipe(select(getDashboardPath)).subscribe((item) => {
      if (item?.pathOptions?.path) {
        if (item.pathOptions?.path == 'upcoming renewals') {
          this.filterForm
            .get('underwriter')
            .setValue([
              { id: this.userID, name: item?.pathOptions?.underwriter },
            ]);
          this.handleNav(event, 2);
        }
      }
    });

    this.loadResults(1, 10, this.sortingKeys);

    //Filter
    this.riskRegionService.getAllRiskByUser(this.userID).subscribe((data) => {
      this.products = data.data.reduce((accumulator, value) => {
        return { ...accumulator, [value.name]: value };
      }, {});
      this.pdtOptions = data.data?.map((dataObj) => ({
        key: dataObj.name,
        value: dataObj.name,
        name: dataObj.name,
        disabled: false,
        id: dataObj.id,
      }));
    });
    this.newlyCreatedPolicyId = this.newQuoteService.getPackagePolicyNumber();
    if (this.newlyCreatedPolicyId) {
      const policyWarningMsg = this.newQuoteService.getPolicyBindWarningMsg();
      let alertData: any = {};
      if (policyWarningMsg === '') {
        alertData = {
          show: true,
          type: 'success',
          headerText: this.translateService.instant('common.successMsg'),
          bodyText:
            this.translateService.instant('policy.bound.head') +
            ' ' +
            this.newlyCreatedPolicyId +
            ' ' +
            this.translateService.instant('policy.bound.successMsg'),
        };
      } else {
        alertData = {
          show: true,
          type: 'warn',
          headerText: this.translateService.instant('common.warnMsg'),
          bodyText: policyWarningMsg,
        };
      }
      this.alertService.addAlert(alertData);
    }
    this.newQuoteService.clearPackagePolicyNumber();
    if (this.isRestrictedRoleSubmission === true) this.showViewButton = false;
    this.populatePremiumOptions();
    await this.fetchQuoteStatusLists();
    await this.populateDeductibles();
    await this.populateLimitOptions();
  }

  handleStartNewQuote() {
    this.store.dispatch(new CreateQuoteAction.ResetState());
    if (this.quoteFlowSteps === 'workflow3') {
      this.newQuoteService.clearInsuredId();
      this.router.navigate(['../../workflow3'], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
      return;
    } else {
      this.router.navigate([`../${this.quoteFlowUrl}/new`], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
    }
  }

  //Navigation
  async handleNav(event, index) {
    await this.fetchQuoteStatusLists();
    const currentActiveIdx = this.subNavData.findIndex(
      (navObj: { name: string; active: boolean; value: string }) =>
        navObj.active,
    );
    if (currentActiveIdx != index) {
      let currentStatusFilters = this.filterForm.value['status'] ?? [];
      if (currentStatusFilters.includes(this.subNavData[index].value)) {
        this.filterForm.controls['status'].setValue([
          `${this.subNavData[index].value}`,
        ]);
      } else {
        this.filterForm.controls['status'].setValue([]);
      }

      this.subNavData[currentActiveIdx].active = false;
      this.subNavData[index].active = true;

      await this.populateFilterOptions();
      let statusFilter = this.filterInputOptions.find(
        (item) => item.value === 'status',
      );

      if (index != 0) {
        if (!this.filterSelectedOptions) {
          this.filterSelectedOptions = this.filterForm.value;
        }
        this.filterSelectedOptions.status = [
          {
            fromNav: true,
            ...this.statusOptions.find(
              (x) =>
                x.value.toLowerCase() ===
                this.subNavData[index].value.toLowerCase(),
            ),
          },
        ];

        statusFilter.isDefault = true;
        statusFilter.isRequired = true;
      } else {
        let ind = this.filterSelectedOptions.status.findIndex((x) => x.fromNav);
        if (ind >= 0) {
          this.filterSelectedOptions.status.splice(ind, 1);
        }
        statusFilter.isDefault = false;
        statusFilter.isRequired = false;
      }

      this.loadResults(1, 10, this.sortingKeys);
    }
  }

  //Load tabledata

  loadResults(pageNumber, limit, sort = '') {
    this.showTblSpinner = true;
    this.tableData = this.tableHeaders;
    const currentActiveIdx = this.subNavData.findIndex(
      (navObj) => navObj.active,
    );
    const policyStatus = mapPolicy(this.subNavData[currentActiveIdx].name);

    let filter = this.makeFilter();
    let insuredTimezone = '';
    if (policyStatus) {
      if (filter.policyStatus) {
        filter.policyStatus += ',' + policyStatus;
      } else {
        filter = Object.assign(filter, { policyStatus: policyStatus });
      }
    }
    if (filter.trxDays) {
      filter = Object.assign(filter, { type: 'Bind' });
    }

    this.pageNumber = pageNumber;
    this.policyService
      .GetAllPolicyFilter(filter, pageNumber, limit, sort, policyStatus)
      .subscribe(
        (data) => {
          this.trxData = data.data?.map((dataObj) => ({
            insuredId: dataObj.policy.insured?.id,
            policyRiskTrxId: dataObj.policyRiskTrxes?.id,
            status: dataObj.policy.PolicyStatus.description,
            policyPeriodId: dataObj.policyPeriod?.id,
            policyId: String(dataObj.policy.pkgPolicyNumber),
          }));

          this.policyList = [...data.data];
          const tableData = data.data?.map((dataObj) => {
            const filterdVal = this.formatPolicyRiskList(
              dataObj.policyRiskTrxes.policyRisk,
            );
            insuredTimezone =
              dataObj.policy.insured.insuredLocation.timeZoneCode ??
              'America/New_York';
            return {
              'Policy ID': String(dataObj.policy.pkgPolicyNumber),
              Status:
                StatusMapping[
                  dataObj.policy.PolicyStatus.description.toLowerCase()
                ],
              'Policy period': getFormattedPolicyPeriod(
                dataObj.policyPeriod,
                this.shortDateFormat,
              ),
              'Insured name': dataObj.policy.insured.companyName
                ? dataObj.policy.insured.companyName
                : dataObj.policy.insured.firstName +
                  ' ' +
                  dataObj.policy.insured.lastName,
              Risks: filterdVal.products,
              'Annual Premium':
                '$' +
                formatAmountWithCurrency(filterdVal.premium, this.currency),
              showStar: !!dataObj.policyRiskTrxes.followUp,
            };
          });

          this.tableData =
            tableData.length == 0 ? this.tableHeaders : tableData;
          this.totalDataCount = data.pagination.totalRecords;
          this.pagination = data.pagination;
          this.showTblSpinner = false;
        },
        (error) => {
          this.showTblSpinner = false;
        },
      );
  }

  //Filter

  makeFilter() {
    let insuredName =
      this.filterSelectedOptions?.insuredName ?? this.searchQuery;

    const brokerVal = this.filterSelectedOptions?.brokerageBranchProd ?? [];
    const brokerIds = brokerVal
      .filter((item) => item.type === 'Brokerage')
      .map((currentValue) => currentValue.id)
      .join(',');
    const branchIds = brokerVal
      .filter((item) => item.type === 'Branch')
      .map((currentValue) => currentValue.id)
      .join(',');
    const producersIds = brokerVal
      .filter((item) => item.type === 'Producer')
      .map((currentValue) => currentValue.id)
      .join(',');
    const productsVal = this.filterSelectedOptions?.risks ?? [];
    const productIds = productsVal.map((p) => p.id).join(',');
    const underwritersVal = this.filterSelectedOptions?.underwriter ?? [];
    const underwriterIds = underwritersVal
      .map((currentValue) => currentValue.id)
      .join(',');
    let statusArray = this.filterSelectedOptions?.status ?? [];
    const statusVal = statusArray.map((f) => mapPolicy(f.value)).join(',');
    const expiryDt = this.filterSelectedOptions?.expiryDt;
    const effectiveDt = this.filterSelectedOptions?.effectiveDt;
    const premium = this.filterSelectedOptions?.premiumRange;
    const limit = this.filterSelectedOptions?.limitRange;
    const deductables = this.filterSelectedOptions?.deductibleRange;
    let expiryRange = '';
    let effectiveRange = '';
    let premiumRange = '';
    let limitRange = '';
    let deductibleRange = '';
    if (expiryDt && expiryDt.from && expiryDt.to) {
      const formattedFromDate = getFormattedDate(expiryDt.from);
      const formattedToDate = getFormattedDate(expiryDt.to);
      expiryRange = `${formattedFromDate},${formattedToDate}`;
    }
    if (effectiveDt && effectiveDt.from && effectiveDt.to) {
      const formattedFromDate = getFormattedDate(effectiveDt.from);
      const formattedToDate = getFormattedDate(effectiveDt.to);
      effectiveRange = `${formattedFromDate},${formattedToDate}`;
    }
    if (premium && premium.from !== '' && premium.to !== '') {
      const formattedFrom =
        this.premiumOptions.find((x) => x.id == premium.from)?.value ??
        premium.from;
      const formattedTo =
        this.premiumOptions.find((x) => x.id == premium.to)?.value ??
        premium.to;
      premiumRange = `${formattedFrom},${formattedTo}`;
    }
    if (limit && limit.from !== '' && limit.to !== '') {
      const formattedFrom =
        this.limitOptions.find((x) => x.id == limit.from)?.value ?? limit.from;
      const formattedTo =
        this.limitOptions.find((x) => x.id == limit.to)?.value ?? limit.to;
      limitRange = `${formattedFrom},${formattedTo}`;
    }
    if (deductables && deductables.from !== '' && deductables.to !== '') {
      const formattedFrom =
        this.premiumOptions.find((x) => x.id == deductables.from)?.value ??
        deductables.from;
      const formattedTo =
        this.premiumOptions.find((x) => x.id == deductables.to)?.value ??
        deductables.to;
      deductibleRange = `${formattedFrom},${formattedTo}`;
    }
    const filter = {
      policyStatus: statusVal,
      effectiveDt: effectiveRange,
      trxDays: 0,
      underwriterId: underwriterIds,
      brokerageBORId: brokerIds,
      brokerageBranchBORId: branchIds,
      brokerageProducerBORId: producersIds,
      riskIds: productIds,
      expiryDt: expiryRange,
      premiumRange: premiumRange,
      limitRange: limitRange,
      deductibleRange: deductibleRange,
      insuredName: insuredName,
    };
    return filter;
  }
  formatPolicyRiskList(policyRiskList: any): {
    products: string;
    limit: string;
    premium: string;
  } {
    if (!policyRiskList || policyRiskList.length === 0) {
      return null;
    }
    let formattedObj: { products: string; limit: string; premium: string } = {
      products: '',
      limit: '',
      premium: '',
    };
    formattedObj.products = policyRiskList.map((item) => item.risk).join(', ');
    formattedObj.limit = policyRiskList
      .map((item) =>
        item.limit
          ? formatAmountWithCurrency(item.limit, this.currency)
          : formatAmountWithCurrency('0', this.currency),
      )
      .join(', ');
    formattedObj.premium = policyRiskList
      .map((item) =>
        item.premium
          ? formatAmountWithCurrency(item.premium, this.currency)
          : formatAmountWithCurrency('0', this.currency),
      )
      .join(', ');
    return formattedObj;
  }

  //Filter

  handleFilter() {
    this.populateFilterOptions();
    this.createFilterFormGroup();
    this.showFilter = true;
    this.renderer.addClass(document.body, 'no-scroll');
  }

  filterSelectedHasValue() {
    let flag = 0;
    if (this.filterSelectedOptions) {
      if (this.filterSelectedOptions.brokerageBranchProd) {
        flag +=
          this.filterSelectedOptions.brokerageBranchProd.length > 0 ? 1 : 0;
      }
      if (this.filterSelectedOptions.risks) {
        flag += this.filterSelectedOptions.risks.length > 0 ? 1 : 0;
      }
      if (this.filterSelectedOptions.status) {
        flag += this.filterSelectedOptions.status.length > 0 ? 1 : 0;
      }
      if (this.filterSelectedOptions.underwriter) {
        flag += this.filterSelectedOptions.underwriter.length > 0 ? 1 : 0;
      }
      if (this.filterSelectedOptions.insuredName) {
        flag += this.filterSelectedOptions.insuredName !== '' ? 1 : 0;
      }
      if (this.filterSelectedOptions.premiumRange) {
        if (
          this.filterSelectedOptions.premiumRange.from !== null &&
          this.filterSelectedOptions.premiumRange.from !== undefined &&
          this.filterSelectedOptions.premiumRange.from !== '' &&
          this.filterSelectedOptions.premiumRange.to !== null &&
          this.filterSelectedOptions.premiumRange.to !== undefined &&
          this.filterSelectedOptions.premiumRange.to !== ''
        ) {
          flag++;
        }
      }
      if (this.filterSelectedOptions.limitRange) {
        if (
          this.filterSelectedOptions.limitRange.from !== null &&
          this.filterSelectedOptions.limitRange.from !== undefined &&
          this.filterSelectedOptions.limitRange.from !== '' &&
          this.filterSelectedOptions.limitRange.to !== null &&
          this.filterSelectedOptions.limitRange.to !== undefined &&
          this.filterSelectedOptions.limitRange.to !== ''
        ) {
          flag++;
        }
      }
      if (this.filterSelectedOptions.deductibleRange) {
        if (
          this.filterSelectedOptions.deductibleRange.from !== null &&
          this.filterSelectedOptions.deductibleRange.from !== undefined &&
          this.filterSelectedOptions.deductibleRange.from !== '' &&
          this.filterSelectedOptions.deductibleRange.to !== null &&
          this.filterSelectedOptions.deductibleRange.to !== undefined &&
          this.filterSelectedOptions.deductibleRange.to !== ''
        ) {
          flag++;
        }
      }
      if (this.filterSelectedOptions.effectiveDt) {
        if (
          this.filterSelectedOptions.effectiveDt.from &&
          this.filterSelectedOptions.effectiveDt.from !== '' &&
          this.filterSelectedOptions.effectiveDt.to &&
          this.filterSelectedOptions.effectiveDt.to !== ''
        ) {
          flag++;
        }
      }
      if (this.filterSelectedOptions.expiryDt) {
        if (
          this.filterSelectedOptions.expiryDt.from &&
          this.filterSelectedOptions.expiryDt.from !== '' &&
          this.filterSelectedOptions.expiryDt.to &&
          this.filterSelectedOptions.expiryDt.to !== ''
        ) {
          flag++;
        }
      }
    }
    if (flag > 0) {
      return true;
    } else {
      return false;
    }
  }
  async populateFilterOptions() {
    let activeTab = this.subNavData.find((x) => x.active);
    if (this.filterSelectedHasValue()) {
      let defaultOptions = 0;
      let filteredOptions = this.filterInputOptions.map((option) => {
        const { value } = option;
        const selectedValue = this.filterSelectedOptions[value];
        if (option.type === 'dateRange' || option.type === 'dropdownRange') {
          if (
            selectedValue.from !== null &&
            selectedValue.from !== undefined &&
            selectedValue.from !== '' &&
            selectedValue.to !== null &&
            selectedValue.to !== undefined &&
            selectedValue.to !== ''
          ) {
            defaultOptions++;
            return { ...option, selectedValue, isDefault: true };
          }
        } else if (
          option.type === 'multiDropdown' ||
          option.type === 'multiSearch'
        ) {
          if (selectedValue && selectedValue.length > 0) {
            defaultOptions++;
            return { ...option, selectedValue, isDefault: true };
          }
        } else if (
          selectedValue !== null &&
          selectedValue !== undefined &&
          selectedValue !== ''
        ) {
          defaultOptions++;
          return { ...option, selectedValue, isDefault: true };
        }

        return { ...option, selectedValue, isDefault: false };
      });
      if (defaultOptions < 3) {
        filteredOptions = filteredOptions.map((option) => {
          if (defaultOptions < 3) {
            if (!option.isDefault) {
              defaultOptions++;
              return { ...option, isDefault: true };
            }
          }
          return { ...option };
        });
      }

      this.filterInputOptions = filteredOptions;
    } else {
      this.filterInputOptions = [
        {
          label: 'Policy effective date',
          value: 'effectiveDt',
          type: 'dateRange',
          disabled: false,
          isDefault: true,
          selectedValue: '',
          isRequired: false,
          hasValidation: true,
        },
        {
          label: 'Policy expiry Date',
          value: 'expiryDt',
          type: 'dateRange',
          disabled: false,
          isDefault: true,
          selectedValue: '',
          isRequired: false,
          hasValidation: true,
        },
        {
          label: 'Insured name',
          value: 'insuredName',
          type: 'text',
          disabled: false,
          selectedValue: '',
          isDefault: true,
          isRequired: false,
          hasValidation: false,
        },
        {
          label: 'Status',
          value: 'status',
          type: 'multiDropdown',
          disabled: false,
          isDefault: false,
          selectedValue: '',
          dropdownOptions: this.statusOptions,
          isRequired: false,
          hasValidation: false,
        },
        {
          label: 'Products',
          value: 'risks',
          type: 'multiDropdown',
          disabled: false,
          isDefault: false,
          selectedValue: '',
          dropdownOptions: this.pdtOptions,
          isRequired: false,
          hasValidation: false,
        },
        {
          label: 'Limit',
          value: 'limitRange',
          type: 'dropdownRange',
          disabled: false,
          isDefault: false,
          selectedValue: '',
          isRequired: false,
          hasValidation: true,
          dropdownOptions: this.limitOptions,
          min: 0,
          max: 10000000,
          minMaxErrmsg: 'Values should be between the range of 0 to 10M',
        },
        {
          label: 'Retention',
          value: 'deductibleRange',
          type: 'dropdownRange',
          disabled: false,
          isDefault: false,
          selectedValue: '',
          isRequired: false,
          hasValidation: true,
          dropdownOptions: this.deductibleOptions,
          min: 0,
          max: 1000000,
          minMaxErrmsg: 'Values should be between the range of 0 to 1M',
        },
        {
          label: 'Annual premium',
          value: 'premiumRange',
          type: 'dropdownRange',
          disabled: false,
          isDefault: false,
          selectedValue: '',
          isRequired: false,
          hasValidation: true,
          dropdownOptions: this.premiumOptions,
          min: 0,
          max: 1000000,
          minMaxErrmsg: 'Values should be between the range of 0 to 1M',
        },
        {
          label: 'Brokerage, branch, producer',
          value: 'brokerageBranchProd',
          type: 'multiSearch',
          disabled: false,
          isDefault: false,
          selectedValue: '',
          dropdownOptions: this.brokerageBranchProducerOptions,
          isRequired: false,
          hasValidation: false,
        },
        {
          label: 'Underwriter',
          value: 'underwriter',
          type: 'multiSearch',
          disabled: false,
          isDefault: false,
          selectedValue: '',
          dropdownOptions: this.underWriterOptions,
          isRequired: false,
          hasValidation: false,
        },
      ];
    }
  }
  createFilterFormGroup() {
    const formGroup = {};
    this.filterInputOptions.forEach((control) => {
      const isRequired = control.hasValidation ? Validators.required : '';
      let selectedValue;
      switch (control.type) {
        case 'text':
          selectedValue = control.selectedValue ? control.selectedValue : '';
          formGroup[control.value] = [selectedValue, isRequired];
          break;
        case 'number':
          selectedValue = control.selectedValue ? control.selectedValue : '';
          formGroup[control.value] = [selectedValue, isRequired];
          break;
        case 'dropdown':
          selectedValue = control.selectedValue ? control.selectedValue : '';
          formGroup[control.value] = [selectedValue, isRequired];
          break;
        case 'dateRange':
          selectedValue = control.selectedValue
            ? control.selectedValue
            : { from: '', to: '' };
          formGroup[control.value] = this.fb.group({
            from: [selectedValue.from],
            to: [selectedValue.to],
          });
          break;
        case 'dropdownRange':
          selectedValue = control.selectedValue
            ? control.selectedValue
            : { from: '', to: '' };
          formGroup[control.value] = this.fb.group({
            from: [selectedValue.from],
            to: [selectedValue.to],
          });
          break;
        case 'multiDropdown':
          selectedValue = control.selectedValue ? control.selectedValue : [];
          formGroup[control.value] = [selectedValue, isRequired];
          break;
        case 'multiSearch':
          selectedValue = control.selectedValue ? control.selectedValue : [];
          formGroup[control.value] = [selectedValue, isRequired];
          break;
        default:
          selectedValue = control.selectedValue ? control.selectedValue : '';
          formGroup[control.value] = [selectedValue, isRequired];
      }
    });
    this.filterForm = this.fb.group(formGroup);
  }
  handleFiltering(e) {
    this.filterSelectedOptions = {};
    this.filterSelectedOptions = e;

    this.showFilter = false;
    this.renderer.removeClass(document.body, 'no-scroll');
    this.loadResults(1, 10, this.sortingKeys);
  }
  handleFilterClear(e) {
    this.filterSelectedOptions = {};
    this.filterSelectedOptions = e;

    this.renderer.removeClass(document.body, 'no-scroll');
    this.loadResults(1, 10, this.sortingKeys);
  }

  getFilteredValues() {
    let filteredOptions = [];
    let filterText = '';

    if (this.filterSelectedOptions) {
      if (this.filterSelectedOptions.insuredName) {
        filterText = this.filterSelectedOptions?.insuredName;
        filteredOptions.push({ key: 'insuredName', value: filterText });
      }
      if (this.filterSelectedOptions.status.length > 0) {
        let status = this.filterSelectedOptions.status;
        status.forEach((b) => {
          if (b.fromNav) {
            return;
          }
          filteredOptions.push({ key: 'status', value: b.name });
        });
      }
      if (this.filterSelectedOptions?.brokerageBranchProd) {
        const brokerVal = this.filterSelectedOptions?.brokerageBranchProd;
        if (brokerVal?.length > 0) {
          brokerVal.forEach((b) => {
            filteredOptions.push({ key: 'brokerageBranchProd', value: b.name });
          });
        }
        filterText = this.filterSelectedOptions.insuredType;
      }
      if (this.filterSelectedOptions.underwriter) {
        const underWriter = this.filterSelectedOptions.underwriter;
        if (underWriter?.length > 0) {
          underWriter.forEach((uw) => {
            filteredOptions.push({ key: 'underwriter', value: uw.name });
          });
        }
      }
      if (this.filterSelectedOptions.risks) {
        const risks = this.filterSelectedOptions.risks;
        if (risks?.length > 0) {
          risks.forEach((uw) => {
            filteredOptions.push({ key: 'risks', value: uw.name });
          });
        }
      }
      if (this.filterSelectedOptions.expiryDt) {
        const createDt = this.filterSelectedOptions.expiryDt;
        let createRange = '';
        if (createDt.from && createDt.to) {
          const formattedFromDate = formatDateMoment(
            createDt.from,
            this.shortDateFormat,
          );
          const formattedToDate = formatDateMoment(
            createDt.to,
            this.shortDateFormat,
          );
          createRange = `${formattedFromDate} - ${formattedToDate}`;
          filteredOptions.push({ key: 'expiryDt', value: createRange });
        }
      }
      if (this.filterSelectedOptions.effectiveDt) {
        const effectiveDt = this.filterSelectedOptions.effectiveDt;
        let createRange = '';
        if (effectiveDt.from && effectiveDt.to) {
          const formattedFromDate = formatDateMoment(
            effectiveDt.from,
            this.shortDateFormat,
          );
          const formattedToDate = formatDateMoment(
            effectiveDt.to,
            this.shortDateFormat,
          );
          createRange = `${formattedFromDate} - ${formattedToDate}`;
          filteredOptions.push({ key: 'effectiveDt', value: createRange });
        }
      }
      if (this.filterSelectedOptions.premiumRange) {
        const premiumRange = this.filterSelectedOptions.premiumRange;
        let createRange = '';
        if (
          premiumRange.from !== null &&
          premiumRange.from !== undefined &&
          premiumRange.from !== '' &&
          premiumRange.to !== null &&
          premiumRange.to !== undefined &&
          premiumRange.to !== ''
        ) {
          const formattedFrom =
            this.premiumOptions.find((x) => x.id == premiumRange.from)?.value ??
            premiumRange.from;
          const formattedTo =
            this.premiumOptions.find((x) => x.id == premiumRange.to)?.value ??
            premiumRange.to;
          createRange = `${this.currency}${formattedFrom} - ${this.currency}${formattedTo}`;
          filteredOptions.push({ key: 'premiumRange', value: createRange });
        }
      }
      if (this.filterSelectedOptions.limitRange) {
        const limitRange = this.filterSelectedOptions.limitRange;
        let createRange = '';
        if (
          limitRange.from !== null &&
          limitRange.from !== undefined &&
          limitRange.from !== '' &&
          limitRange.to !== null &&
          limitRange.to !== undefined &&
          limitRange.to !== ''
        ) {
          const formattedFrom =
            this.limitOptions.find((x) => x.id == limitRange.from)?.value ??
            limitRange.from;
          const formattedTo =
            this.limitOptions.find((x) => x.id == limitRange.to)?.value ??
            limitRange.to;
          createRange = `${this.currency}${formattedFrom} - ${this.currency}${formattedTo}`;
          filteredOptions.push({ key: 'limitRange', value: createRange });
        }
      }
      if (this.filterSelectedOptions.deductibleRange) {
        const deductibleRange = this.filterSelectedOptions.deductibleRange;
        let createRange = '';
        if (
          deductibleRange.from !== null &&
          deductibleRange.from !== undefined &&
          deductibleRange.from !== '' &&
          deductibleRange.to !== null &&
          deductibleRange.to !== undefined &&
          deductibleRange.to !== ''
        ) {
          const formattedFrom =
            this.deductibleOptions.find((x) => x.id == deductibleRange.from)
              ?.value ?? deductibleRange.from;
          const formattedTo =
            this.deductibleOptions.find((x) => x.id == deductibleRange.to)
              ?.value ?? deductibleRange.to;
          createRange = `${this.currency}${formattedFrom} - ${this.currency}${formattedTo}`;
          filteredOptions.push({ key: 'deductibleRange', value: createRange });
        }
      }
      this.filteredOptions = filteredOptions;
    }

    return filteredOptions;
  }

  isMinMaxValid(minValuePropName, maxValuePropName) {
    return (control: FormControl) => {
      if (!control || !control.parent) {
        return null;
      }
      let maxValue = 0;
      let minValue = 0;
      if (minValuePropName) {
        minValue = Number(control.parent.value[minValuePropName]);
        maxValue = Number(control.value);
      } else {
        maxValue = Number(control.parent.value[maxValuePropName]);
        minValue = Number(control.value);
      }

      if (maxValue < minValue) {
        return {
          customError: true,
        };
      } else {
        if (minValuePropName) {
          control.parent.controls[minValuePropName].errors = null;
          control.parent.controls[minValuePropName].status = 'VALID';
        } else {
          control.parent.controls[maxValuePropName].errors = null;
          control.parent.controls[maxValuePropName].status = 'VALID';
        }
      }

      return {};
    };
  }

  handleFilterOptnRemove(option) {
    if (this.filterSelectedOptions.hasOwnProperty(option.key)) {
      switch (option.key) {
        case 'underwriter':
          let ind = this.filterSelectedOptions[option.key].findIndex(
            (x) => x.name === option.value,
          );
          if (ind >= 0) {
            this.filterSelectedOptions[option.key].splice(ind, 1);
          }
          break;
        case 'brokerageBranchProd':
          let ind1 = this.filterSelectedOptions[option.key].findIndex(
            (x) => x.name === option.value,
          );
          if (ind1 >= 0) {
            this.filterSelectedOptions[option.key].splice(ind1, 1);
          }
          break;
        case 'limitRange':
          this.filterSelectedOptions[option.key] = { from: '', to: '' };
          break;
        case 'deductibleRange':
          this.filterSelectedOptions[option.key] = { from: '', to: '' };
          break;
        case 'premiumRange':
          this.filterSelectedOptions[option.key] = { from: '', to: '' };
          break;
        case 'risks':
          let ind2 = this.filterSelectedOptions[option.key].findIndex(
            (x) => x.name === option.value,
          );
          if (ind2 >= 0) {
            this.filterSelectedOptions[option.key].splice(ind2, 1);
          }
          break;
        case 'status':
          let ind3 = this.filterSelectedOptions[option.key].findIndex(
            (x) => x.name === option.value,
          );
          if (ind3 >= 0) {
            this.filterSelectedOptions[option.key].splice(ind3, 1);
          }
          break;
        case 'expiryDt':
          this.filterSelectedOptions[option.key] = { from: '', to: '' };
          break;
        case 'insuredName':
          this.filterSelectedOptions[option.key] = '';
          this.filterForm.get('insuredName').setValue('');
          break;
        case 'effectiveDt':
          this.filterSelectedOptions[option.key] = { from: '', to: '' };
          break;
        default:
      }
    }

    this.loadResults(1, 10, this.sortingKeys);
    this.getFilteredValues();

    // this.handleFiltering(undefined);
  }

  pageChangeHandler(pageNumber) {
    if (pageNumber > 0) {
      this.loadResults(pageNumber, 10, this.sortingKeys);
    }
  }

  handleExport(): void {
    this.showTblSpinner = true;
    const currentActiveIdx = this.subNavData.findIndex(
      (navObj) => navObj.active,
    );
    const policyStatus = mapPolicy(this.subNavData[currentActiveIdx].name);

    let filter = this.makeFilter();
    if (policyStatus) {
      if (filter.policyStatus) {
        filter.policyStatus += ',' + policyStatus;
      } else {
        filter = Object.assign(filter, { policyStatus: policyStatus });
      }
    }
    if (filter.trxDays) {
      filter = Object.assign(filter, { type: 'Bind' });
    }
    filter = Object.assign(filter, { entryType: 'policy' });
    filter = Object.assign(filter, { limit: this.totalDataCount });
    filter = Object.assign(filter, { page: 1 });

    this.policyService.PolicyExport(filter, this.sortingKeys).subscribe(
      (data: ArrayBuffer) => {
        excelFileDownload('ExportedData.xlsx', data);
        this.showTblSpinner = false;
      },
      (error) => {
        this.showTblSpinner = false;
        const alertData = {
          show: true,
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: getErrorMessage(error),
        };
        this.alertService.addAlert(alertData);
      },
    );
  }

  starClickHandler(index) {
    this.alertService.clearAlerts(-1);
    //this.notificationAlert.show = false;
    const policyRiskTrxes = { ...this.policyList[index].policyRiskTrxes };
    const followUp = { followUp: policyRiskTrxes.followUp ? 0 : 1 };
    this.policyRiskTrxService
      .UpdateFollowUpByPolicyRiskTrxId(
        this.userID,
        policyRiskTrxes.id,
        followUp,
      )
      .subscribe({
        next: (_) => {
          this.tableData[index].showStar = !!!policyRiskTrxes.followUp;
          this.policyList[index].policyRiskTrxes.followUp =
            policyRiskTrxes.followUp ? 0 : 1;
          const alertData = {
            show: true,
            type: 'success',
            headerText: 'success!',
            bodyText: this.translateService.instant('policy.FollowUpSuccess'),
          };
          this.alertService.addAlert(alertData);
          this.loadResults(1, 10, this.sortingKeys);
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            const alertData = {
              show: true,
              type: 'error',
              headerText: 'error!',
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
          }
        },
      });
  }
  handleSearch(object) {
    if (object.searchIn === 'Insured name') {
      let insuredType = 1;
      if (object?.type?.toLowerCase() == 'company') {
        insuredType = 2;
      }
      this.router.navigate(
        [
          `../../search/${insuredType}/${object.selectedSearch.value}/${object.selectedSearch.id}`,
        ],
        { relativeTo: this.activatedRoute, skipLocationChange: true },
      );
    } else {
      if (this.isRestrictedRoleSubmission === true) {
        this.alertService.clearAlerts(-1);
        const alertData = {
          type: 'warn',
          headerText: getAlertHead('warning'),
          bodyText: this.translateService.instant(
            'common.error.noAccessToPageErrorMsg',
          ),
        };
        this.alertService.addAlert(alertData);
        return;
      }
      this.router.navigateByUrl(
        `dashboard/policy/${object.selectedSearch.value}`,
        { skipLocationChange: true },
      );
    }
  }

  handleTableColSortUp(e) {
    let order = 'asc';
    this.sortingKeys = `${makeSorting(e, this.colPolicyHeader, order)},id:asc`;
    this.loadResults(this.pageNumber, 10, this.sortingKeys);
  }

  handleTableColSortDown(e) {
    let order = 'desc';
    this.sortingKeys = `${makeSorting(e, this.colPolicyHeader, order)},id:desc`;
    this.loadResults(this.pageNumber, 10, this.sortingKeys);
  }

  policyClickHandler(index) {
    if (this.isRestrictedRoleSubmission === true) {
      this.alertService.clearAlerts(-1);
      const alertData = {
        type: 'warn',
        headerText: getAlertHead('warning'),
        bodyText: this.translateService.instant(
          'common.error.noAccessToPageErrorMsg',
        ),
      };
      this.alertService.addAlert(alertData);
      return;
    }
    const policyID = this.tableData[index]['Policy ID'];
    this.router.navigate([`./${policyID}`], {
      relativeTo: this.activatedRoute,
      skipLocationChange: true,
    });
  }

  removeColSort() {
    this.sortingKeys = 'followUp:desc, policyId:desc';
    this.loadResults(this.pageNumber, 10, this.sortingKeys);
  }

  async onInsuredSearch(event) {
    const value = event.target.value;
    this.searchQuery = event.target.value;
    this.filteredOptions = [];
    this.filterSelectedOptions = undefined;
    if (event.key == 'Enter' && value.length >= 3 && value.length <= 40) {
      this.showTblSpinner = true;
      this.insuredSearchFilterOn = true;
      await this.loadResults(1, 10, this.sortingKeys);
    } else if (
      value?.length === 0 ||
      (event.key == 'Enter' && value?.length < 3)
    ) {
      this.showTblSpinner = true;
      this.insuredSearchFilterOn = false;
      await this.loadResults(1, 10, this.sortingKeys);
    }
  }

  populateDeductibles() {
    return new Promise<any>(async (resolve, reject) => {
      this.confDeductibleService.GetAll().subscribe({
        next: (data) => {
          const tableData = data.data?.map((dataObj, index) => ({
            key: formatAmountWithCurrency(dataObj.value, this.currency),
            value: valInDigits(dataObj.value),
            id: index + 1,
          }));

          tableData.push({
            id: data.data?.length + 1,
            key: 'Custom',
            subKey: '( Between 0 to 1M only. )',
            value: 'custom-amount',
          });
          this.deductibleOptions = removeDuplicatedKeys(tableData);
          resolve(true);
        },
        error: (error) => {
          const deductableOptions = [
            {
              key: 'Custom',
              value: 'custom-amount',
              subKey: '( Between 0 to 1M only. )',
              id: 0,
            },
          ];
          this.deductibleOptions = deductableOptions;
          reject(error);
        },
      });
    });
  }
  populateLimitOptions() {
    return new Promise<any>(async (resolve, reject) => {
      this.confRiskLimitsService.GetAll().subscribe({
        next: (response) => {
          const limitOptions = response.data.map((dataObj, index) => ({
            key: formatAmountWithCurrency(dataObj.limit, this.currency),
            value: valInDigits(dataObj.limit),
            id: index + 1,
          }));
          limitOptions.push({
            key: 'Custom',
            value: 'custom-amount',
            subKey: '( Between 0 to 10M only. )',
            id: response.data?.length + 1,
          });
          this.limitOptions = limitOptions;
          resolve(true);
        },
        error: (error) => {
          const limitOptions = [
            {
              key: 'Custom',
              value: 'custom-amount',
              subKey: '( Between 0 to 10M only. )',
              id: 0,
            },
          ];
          this.limitOptions = limitOptions;
          reject(false);
        },
      });
    });
  }

  handleCloseFilterPopup(event) {
    this.showFilter = false;
  }
  private async fetchQuoteStatusLists() {
    return new Promise<any>(async (resolve, reject) => {
      this.domainsService.GetByDomainCode('POLICYSTATUS').subscribe({
        next: (data) => {
          let statusList = [];
          data.data.map((dataObj) => {
            if (
              dataObj.description !== 'QUOTE' &&
              dataObj.description !== 'QUOTE-CLOSED'
            ) {
              statusList = [
                ...statusList,
                {
                  key: dataObj.description,
                  value: dataObj.description,
                  name: dataObj.description,
                  status: getPolicyStatusTypeV2(dataObj.description),
                  seqId: dataObj.seqId,
                  id: dataObj.id,
                },
              ];
            }
          });
          this.statusOptions = statusList;
          // this.statusOptions = data.data.map((dataObj) => ({
          //   key: dataObj.description,
          //   value: dataObj.description,
          //   name: dataObj.description,
          //   status: getPolicyStatusTypeV2(dataObj.description),
          //   seqId: dataObj.seqId,
          //   id: dataObj.id,
          // }));
          resolve(true);
        },
        error: (error) => {
          reject(false);
        },
      });
    });
  }

  populatePremiumOptions() {
    const premiumOptions = [];
    for (let i = 0; i <= 10; i += 1) {
      premiumOptions.push({
        key: i * 100000,
        value: i * 100000,
        id: i,
      });
    }
    let length = premiumOptions.length;
    premiumOptions.push({
      key: 'Custom',
      subKey: '( Between 0 to 1M only. )',
      value: 'custom-amount',
      id: length + 1,
    });
    this.premiumOptions = premiumOptions;
  }
  dynamicSearchFilter(e) {
    switch (e.item.selectedInputValue) {
      case 'underwriter':
        this.searchUnderWriter(e.search);
        break;
      case 'brokerageBranchProd':
        this.searchBrokerageDirectories(e.search);
        break;
      case 'premiumRange':
        break;
      case 'risks':
        break;
      case 'status':
        break;
      case 'createdDt':
        break;
      case 'insuredName':
        break;
      case 'effectiveDt':
        break;
      default:
    }
  }
  dynamicLoadMore(e) {
    switch (e.selectedInputValue) {
      case 'underwriter':
        this.loadMoreUnderWriter();
        break;
      case 'brokerageBranchProd':
        this.loadMoreBrokerage();
        break;
      case 'premiumRange':
        break;
      case 'risks':
        break;
      case 'status':
        break;
      case 'createdDt':
        break;
      case 'insuredName':
        break;
      case 'effectiveDt':
        break;
      default:
    }
  }

  loadMoreBrokerage() {
    if (
      this.brokerageBranchProdOptions.length <
      this.brokerageDirectoryTotalRecords
    ) {
      let page = this.brokerageDirectorCurrentPage + 1;
      this.getBrokerageDirectories(this.brokerageDirectorySearch, page, true);
    }
  }
  searchBrokerageDirectories(value) {
    if (value.length >= 3) {
      this.getBrokerageDirectories(value);
    } else {
      this.brokerageBranchProdOptions = [];
    }
  }
  getBrokerageDirectories(value = '', page = 1, isLoadMore = false) {
    this.brokerageDirectoryService
      .GetBrokerageDirectories(page, 10, '', true, true, true, value)
      .subscribe((data) => {
        const tableData = data.data?.map((dataObj) => {
          if (isBroker(dataObj.entity)) {
            return {
              name: dataObj.brokerage.name,
              id: dataObj.id,
              type: dataObj.entity,
            };
          } else if (isBranch(dataObj.entity)) {
            return {
              name: dataObj.brokerageBranch.name,
              id: dataObj.id,
              type: dataObj.entity,
            };
          } else if (isProducer(dataObj.entity)) {
            return {
              name: dataObj.brokerageProducer.name,
              id: dataObj.id,
              type: dataObj.entity,
            };
          } else {
            return {
              name: '',
              id: '',
              type: '',
            };
          }
        });
        if (isLoadMore) {
          this.brokerageBranchProdOptions = [
            ...this.brokerageBranchProdOptions,
            ...tableData,
          ];
        } else {
          this.brokerageBranchProdOptions = tableData;
        }
        let ind = this.filterInputOptions.findIndex(
          (x) => x.value === 'brokerageBranchProd',
        );
        this.filterInputOptions[ind].dropdownOptions = [
          ...this.brokerageBranchProdOptions,
        ];
        this.filterInputOptions = [...this.filterInputOptions];

        this.brokerageDirectorySearch = value;
        this.brokerageDirectorCurrentPage = data.pagination.currentPage;
        this.brokerageDirectoryTotalRecords = data.pagination.totalRecords;
      });
  }
  loadMoreUnderWriter() {
    if (this.underwritersOptions.length < this.underwriterTotalRecords) {
      let page = this.underwriterCurrentPage + 1;
      this.getUnderWriter(this.underwriterSearch, page, true);
    }
  }
  searchUnderWriter(name) {
    if (name.length >= 3) {
      this.getUnderWriter(name);
    } else {
      this.underwriters = [];
      this.underwritersOptions = [];
    }
  }
  getUnderWriter(name = '', page = 1, isLoadMore = false) {
    this.boxxUserService
      .GetUnderwriterlist('', page, 10, '', name, -1, USER_ROLES.Underwriter)
      .subscribe((data) => {
        const tableData = data.data?.map((dataObj) => ({
          name: dataObj.firstName + ' ' + dataObj.lastName,
          id: dataObj.id,
        }));
        let dataObject = data.data.reduce((accumulator, value) => {
          return {
            ...accumulator,
            [value.firstName + ' ' + value.lastName]: value,
          };
        }, {});
        if (isLoadMore) {
          (this.underwriters = { ...this.underwriters, ...dataObject }),
            (this.underwritersOptions = [
              ...this.underwritersOptions,
              ...tableData,
            ]);
        } else {
          this.underwriters = dataObject;
          this.underwritersOptions = tableData;
        }
        let ind = this.filterInputOptions.findIndex(
          (x) => x.value === 'underwriter',
        );

        this.filterInputOptions[ind].dropdownOptions = [
          ...this.underwritersOptions,
        ];
        this.filterInputOptions = [...this.filterInputOptions];

        this.underwriterSearch = name;
        this.underwriterCurrentPage = data.pagination.currentPage;
        this.underwriterTotalRecords = data.pagination.totalRecords;
      });
  }
}
