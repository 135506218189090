import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { excelFileDownload, getErrorMessage } from 'src/app/utils/utils';
import { getFormGroup } from '../producer-profile.factory';
import { Store, select } from '@ngrx/store';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { BrokerageBranchService } from 'src/app/services/brokerage-branch.service';
import { BrokerageDirectoryService } from 'src/app/services/brokerage-directory.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination } from 'src/app/entities/boxx-response';
import { BrokerageProducerService } from 'src/app/services/brokerage-producer.service';

@Component({
  selector: 'app-view-producer-v2',
  templateUrl: './view-producer-v2.component.html',
  styleUrls: ['./view-producer-v2.component.less'],
})
export class ViewProducerV2Component implements OnInit {
  @Input() brokerageId: number;
  @Input() badgeData: string = '0 Records';
  @Input() branchId: number;
  @Input() showProducerForm: boolean = false;
  @Input() isBranchActive: boolean = false;
  @Input() isActiveBranch: boolean;
  @Input() addProducer: boolean;

  placeholder = 'Search name';
  leftIcon = 'assets/img/search-md.svg';
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  shortDateFormat: string = '';
  longDateFormat: string = '';
  userID: number;
  showTblSpinner: boolean = false;
  showErrorAlert = false;
  msgErrorAlert = '';
  data: any;
  pagination: Pagination;
  searchWord;
  mainHeading: string = '';
  showBodySpinner: boolean = false;

  tableHeader = [{ Name: '', Contact: '', Status: '', Actions: '' }];
  columnsToHide: string[] = ['id', 'branchId'];
  columnHeadsToHide: string[] = ['Actions'];
  sortableColumns: string[] = ['Name', 'Status'];
  iconColumns: string[] = ['Actions'];

  filterForm: FormGroup;
  totalRecords: number = 0;
  pageNumber = 1;
  sortBy = 'createdDt:desc,id:desc';

  btnLabel = 'Add Producer';
  showAddButton: boolean = true;
  btnClass;
  btnLeftImage = 'assets/img/plus-circle-dark.png';

  //Form
  //addBranchForm: boolean = false;
  editBranchForm: boolean = false;

  form: FormGroup;
  searchForm: FormGroup;
  submitted: boolean = false;
  operationSuccess = false;
  hasError = false;
  errorMessage = '';
  locationRegionIdSelected: number = 0;
  showSpinner = false;
  requiredErrMsg: string = 'This field is required';
  invalidErrMsg: string = 'This field is invalid';
  isActive: boolean;
  isBrokerActive: any;

  multiContentColumns = {
    Contact: ['email', 'phone'],
  };
  activeTab = 1;
  filterSelectedOptions: any;
  filterFormGroup: FormGroup;
  showFilter: boolean = false;
  filteredOptions = [];
  filterInputOptions: any = [
    {
      label: 'Name',
      value: 'name',
      type: 'text',
      disabled: false,
      isDefault: true,
      selectedValue: '',
      hasValidation: false,
    },
    {
      label: 'Status',
      value: 'status',
      type: 'dropdown',
      disabled: false,
      isDefault: false,
      selectedValue: '',
      dropdownOptions: [
        {
          id: 1,
          label: 'Active',
          value: 'active',
          key: 'Active',
          disabled: false,
          isDefault: true,
        },
        {
          id: 2,
          label: 'Inactive',
          value: 'inactive',
          key: 'Inactive',
          disabled: false,
          isDefault: true,
        },
      ],
      hasValidation: false,
    },
  ];
  // addProducer: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private fb: FormBuilder,
    public brokerageDirectoryService: BrokerageDirectoryService,
    public brokerageBranchService: BrokerageBranchService,
    private localStorageService: LocalStorageService,
    private brokerageProducerService: BrokerageProducerService,
  ) {
    this.searchForm = fb.group({
      searchBy: new FormArray([]),
      searchKey: [''],
    });
  }

  async ngOnInit(): Promise<void> {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    this.showBodySpinner = true;
    this.userID = this.localStorageService.getBoxxUserId();
    this.form = getFormGroup(this.fb);

    await this.loadBranches();
  }

  loadBranches() {
    return new Promise<any>(async (resolve, reject) => {
      this.brokerageProducerService
        .GetBrokerageProducers(this.branchId, this.pageNumber, 10, this.sortBy)
        .subscribe({
          next: (response) => {
            if (response.pagination.totalRecords > 0) {
              const tableData = response.data?.map((dataObj) => ({
                Name: `${dataObj.firstName} ${dataObj.lastName} `,
                Contact: {
                  email: `${dataObj.email ? `${dataObj.email}` : `-----`}`,
                  phone: dataObj.telephone ?? '',
                },
                id: dataObj.id,
                branchId: dataObj.brokerageBranch.id,
                Status: dataObj.active === true ? 'Active' : 'Inactive',
                Actions: '',
              }));
              resolve(true);
              this.data = tableData;
              this.showTblSpinner = false;
              this.showBodySpinner = false;
            } else {
              this.showBodySpinner = false;
              this.showAddButton = true;
            }
            this.showTblSpinner = false;
            this.totalRecords = response.pagination.totalRecords;
            this.pagination = response.pagination;
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            reject(getErrorMessage(error));
            this.showBodySpinner = false;
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    });
  }

  handleExport() {
    this.showTblSpinner = true;
    let active = '';
    let searchTerm = '';
    const searchKey = this.sanitizeSearchInput(
      this.searchForm.value['searchKey'],
    );
    this.searchWord = searchKey ?? this.searchWord;
    if (this.filterSelectedOptions) {
      active = this.filterSelectedOptions['status'] ?? '';
      if (!this.searchWord) {
        this.searchWord = this.filterSelectedOptions['producerName'] ?? '';
      }
    }
    this.brokerageProducerService
      .ExportBrokerageProducer(
        1,
        this.totalRecords,
        this.sortBy,
        active,
        this.searchWord,
        this.branchId,
      )
      .subscribe({
        next: (response) => {
          excelFileDownload('ExportedData.xlsx', response);
          this.showTblSpinner = false;
        },
        error: (error) => {
          this.showTblSpinner = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
  }

  async handleFilter(event) {
    this.filterSelectedOptions = {};
    this.searchForm.controls['searchKey'].setValue('');
    this.showFilter = false;
    this.filterSelectedOptions = event;
    if (event) {
      this.makeFilter(this.pagination.currentPage, this.filterSelectedOptions);
    }
  }
  async handleFilterClear(event) {
    this.filterSelectedOptions = {};
    this.filteredOptions = [];
    this.searchForm.controls['searchKey'].setValue('');
    this.filterSelectedOptions = event;
    this.makeFilter(this.pagination.currentPage, this.filterSelectedOptions);
  }

  handleCloseFilterPopup(event) {
    this.showFilter = false;
  }

  async onBranchSearch(event) {
    this.filterSelectedOptions = {};
    this.filteredOptions = [];
    const searchKey = this.sanitizeSearchInput(
      this.searchForm.value['searchKey'],
    );
    this.mainHeading = '';
    let active = '';
    const value = searchKey ?? event.target.value;
    if (this.filterSelectedOptions) {
      if (this.filterSelectedOptions.hasOwnProperty('producerName')) {
        delete this.filterSelectedOptions['producerName'];
      }
      active = this.filterSelectedOptions['status'] ?? '';
    }
    const filter = { brokerageId: this.brokerageId, name: searchKey };
    if (value) {
      if (event.key == 'Enter') {
        const searchKey = this.sanitizeSearchInput(value);
        this.showTblSpinner = true;
        await this.brokerageProducerService
          .GetFilterBrokerageProducers(
            this.branchId,
            value,
            active,
            this.pageNumber,
            10,
            this.sortBy,
          )
          .subscribe({
            next: (response) => {
              if (response.pagination.totalRecords > 0) {
                this.showAddButton = true;
                this.mainHeading = 'Search result : ' + searchKey;
                const tableData =
                  response.data.length === 0
                    ? this.tableHeader
                    : response.data?.map((dataObj) => ({
                        Name: `${dataObj.firstName} ${dataObj.lastName} `,
                        Contact: {
                          email: `${
                            dataObj.email ? `${dataObj.email}` : `-----`
                          }`,
                          phone: dataObj.telephone ?? '',
                        },
                        id: dataObj.id,
                        branchId: dataObj.brokerageBranchId,
                        Status: dataObj.active === true ? 'Active' : 'Inactive',
                        Actions: '',
                      }));
                this.data = tableData;
              } else {
                this.showAddButton = true;
                this.data = this.tableHeader;
              }
              this.showTblSpinner = false;
              this.totalRecords = response.pagination.totalRecords;
              this.pagination = response.pagination;
            },
            error: (error) => {
              if (![500].includes(error?.status)) {
                this.showErrorAlert = true;
              }
              this.msgErrorAlert = getErrorMessage(error);
              this.showTblSpinner = false;
            },
          });
      }
    } else {
      this.searchForm.controls['searchKey'].setValue([]);
      this.showAddButton = true;
      this.filterSelectedOptions = {};
      this.filteredOptions = [];
      this.mainHeading = '';
      this.loadBranches();
    }
  }

  sanitizeSearchInput(userInput: string): string {
    const specialCharacters = /[\^\$\\*+?.`/~()!=|[\]{}%#]/g;
    return userInput
      ? userInput.replace(specialCharacters, '').replace(/^[ _]/, '')
      : '';
  }

  private getFormatUrl(
    entity: string,
    id: number,
    brokerage: any,
    brokerageBranch?: any,
  ) {
    let url: string;
    url = `/dashboard/branch/view/${id}/${brokerage.id}`;
    return url;
  }

  branchClickHandler(index) {}

  pageChangeHandler(pageNumber) {
    this.pageNumber = pageNumber;
    this.loadBranches();
  }

  handleTableColSort(e) {
    if (e.column) {
      if (e.column === 'Status') {
        e.column = 'active';
      }
      if (e.column === 'Name') {
        e.column = 'firstName';
      }
      this.sortBy = e.column + ':' + e.sort;
    } else {
      this.sortBy = 'createdDt:desc,id:desc';
    }
    this.loadBranches();
  }

  branchEditClickHandler(index) {
    const producerId = this.data[index].id;
    const branchId = this.data[index].branchId;
    const status = this.data[index].Status;
    this.isBrokerActive = status === 'Active' ? true : false;
    this.showProducerForm = false;
    this.isActive = status === 'Active' ? true : false;
    this.router.navigate(
      [
        `/dashboard/workflow3/brokerage/producer/profile/${producerId}/${branchId}/${this.brokerageId}`,
      ],
      { relativeTo: this.activatedRoute, skipLocationChange: true },
    );
  }

  branchViewClickHandler(index) {
    const producerId = this.data[index].id;
    const branchId = this.data[index].branchId;
    this.editBranchForm = true;
    this.showProducerForm = false;
    this.isActive = false;
    this.router.navigate(
      [
        `/dashboard/workflow3/brokerage/producer/profile/${producerId}/${branchId}/${this.brokerageId}`,
      ],
      { relativeTo: this.activatedRoute, skipLocationChange: true },
    );
  }

  // get location() {
  //   return this.form.get('location') as FormGroup;
  // }

  regionIdSelected(idregionSelected: number) {
    this.locationRegionIdSelected = idregionSelected;
  }

  handleAddBranchButton(event) {
    // if (!this.isBrokerActive) {
    //   this.editBranchForm = false;
    //   return;
    // }
    this.showProducerForm = true;
    this.editBranchForm = false;
    this.addProducer = true;
  }

  handleDiscard(event) {
    this.showProducerForm = false;
    this.editBranchForm = false;
  }
  //Add Branch
  get branch() {
    return this.form.get('branch') as FormGroup;
  }

  //Add Producer
  get producer() {
    return this.form.get('producer') as FormGroup;
  }
  handleSaveChanges(event) {
    //this.showBodySpinner = event.showSpinner;
    if (event.reloadData) {
      this.showTblSpinner = true;
      this.showProducerForm = false;
      this.loadBranches();
    }
  }

  filterHandler() {
    this.showFilter = !this.showFilter;
    this.populateFilterOptions();
    this.createFilterFormGroup();
  }

  populateFilterOptions() {
    if (this.filterSelectedOptions) {
      let defaultOptions = 0;
      let filteredOptions = this.filterInputOptions.map((option) => {
        const { value } = option;
        const selectedValue = this.filterSelectedOptions[value];

        if (selectedValue !== undefined) {
          return { ...option, selectedValue, isDefault: true };
        }

        return { ...option, selectedValue, isDefault: false };
      });
      if (defaultOptions < 2) {
        filteredOptions = filteredOptions.map((option) => {
          if (defaultOptions < 2) {
            if (!option.isDefault) {
              defaultOptions++;
              return { ...option, isDefault: true };
            }
          }
          return { ...option };
        });
      }

      this.filterInputOptions = filteredOptions;
    } else {
      this.filterInputOptions = [
        {
          label: 'Name',
          value: 'producerName',
          placeholder: 'ABC Company',
          type: 'text',
          disabled: false,
          isDefault: true,
          selectedValue: '',
          hasValidation: false,
          isRequired: true,
        },
        {
          label: 'Status',
          value: 'status',
          type: 'dropdown',
          disabled: false,
          isDefault: true,
          selectedValue: '',
          dropdownOptions: [
            { id: 1, key: 'Active', value: 'Active' },
            { id: 2, key: 'Inactive', value: 'Inactive' },
          ],
          isRequired: false,
          hasValidation: false,
        },
      ];
    }
  }

  handleCloseFilter() {
    this.showFilter = false;
  }

  createFilterFormGroup() {
    const formGroup = {};
    this.filterInputOptions.forEach((control) => {
      const isRequired = control.hasValidation ? Validators.required : '';
      let selectedValue = control.selectedValue ? control.selectedValue : '';
      formGroup[control.value] = [selectedValue, isRequired];
    });
    this.filterFormGroup = this.fb.group(formGroup);
  }

  makeFilter(pageNumber, filter) {
    this.showTblSpinner = true;
    let fullName;
    let active;
    this.mainHeading = '';
    if (filter?.producerName && filter?.producerName !== 'undefined') {
      fullName = filter?.producerName;
    } else {
      fullName = '';
    }
    if (filter?.status && filter.status !== 'undefined') {
      active = filter.status;
    } else {
      active = '';
    }

    //API call
    this.searchForm.controls['searchKey'].setValue('');

    this.pagination.currentPage = pageNumber;

    this.brokerageProducerService
      .GetFilterBrokerageProducers(this.branchId, fullName, active)
      .subscribe({
        next: (response: any) => {
          if (response.pagination.totalRecords > 0) {
            this.showAddButton = false;
            const tableData =
              response.data.length === 0
                ? this.tableHeader
                : response.data?.map((dataObj) => ({
                    Name: `${dataObj.firstName} ${dataObj.lastName} `,
                    Contact: {
                      email: `${dataObj.email ? `${dataObj.email}` : `-----`}`,
                      phone: dataObj.telephone ?? '',
                    },
                    id: dataObj.id,
                    branchId: dataObj.brokerageBranchId,
                    Status: dataObj.active === true ? 'Active' : 'Inactive',
                    Actions: '',
                  }));
            this.data = tableData;
          } else {
            this.showAddButton = true;
            this.data = this.tableHeader;
          }
          this.showTblSpinner = false;
          this.totalRecords = response.pagination.totalRecords;
          this.pagination = response.pagination;
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
  }

  getFilteredValues() {
    let filteredOptions = [];
    let filterText = '';
    if (this.filterSelectedOptions) {
      if (this.filterSelectedOptions.producerName) {
        filterText = this.filterSelectedOptions?.producerName;
        filteredOptions.push({ key: 'producerName', value: filterText });
      }
      if (
        typeof this.filterSelectedOptions.status !== 'undefined' &&
        this.filterSelectedOptions.status !== ''
      ) {
        let status =
          this.filterSelectedOptions.status === 1 ? 'Active' : 'Inactive';
        filterText = status;
        filteredOptions.push({ key: 'status', value: filterText });
      }
      if (
        this.filterSelectedOptions?.insuredType &&
        this.filterSelectedOptions?.insuredType !== -1
      ) {
        filterText = this.filterSelectedOptions.insuredType;
      }
      this.filteredOptions = filteredOptions;
    }
    return this.filteredOptions;
  }

  handleFilterOptnRemove(keyToRemove) {
    if (this.filterSelectedOptions.hasOwnProperty(keyToRemove)) {
      delete this.filterSelectedOptions[keyToRemove];
    }
    this.makeFilter(this.pagination.currentPage, this.filterSelectedOptions);
    this.getFilteredValues();
  }
}
