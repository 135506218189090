<div class="popupWrapper" *ngIf="showModalNotesPopup">
  <section class="quoteNotesPopup" [style.height]="isLoader ? '446px' : 'auto'">
    <div style="position: relative">
      <app-modal-alert></app-modal-alert>
    </div>
    <div class="header-section" *ngIf="addNewNoteLoader">
      <div class="title">
        <span
          >Quote ID: <span>{{ policyId }}</span
          >/Option {{ details?.["quoteOptionNumber"] ?? "" }}/</span
        >
        <div class="product-name">
          <button
            [className]="
              addNewNoteLoader
                ? 'product-dropdown default-style'
                : 'product-dropdown active-style'
            "
            (click)="!addNewNoteLoader && toggleProductDropdown()"
            (blur)="!addNewNoteLoader && toggleProductDropdown()"
          >
            <span>{{ selectedProduct?.["product"] ?? "" }}*</span>
            <span class="dropdown-icon" *ngIf="!addNewNoteLoader">
              <img src="assets/img/chevron-down.png" alt="dropdown-icon" />
            </span>
          </button>
          <div
            class="product-list-dropdown"
            *ngIf="!addNewNoteLoader && showProductDropdown"
            (blur)="!addNewNoteLoader && toggleProductDropdown()"
          >
            <button
              *ngFor="let productDetail of details['products']"
              class="list-item"
              (click)="handleProductChange(productDetail)"
              [disabled]="productDetail.product === selectedProduct['product']"
              [class.noPointerEvents]="
                productDetail.product === selectedProduct['product']
              "
            >
              {{ productDetail.product }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="header-section" *ngIf="!isLoader && !addNewNoteLoader">
      <div class="title">
        <span
          >Quote ID: <span>{{ policyId }}</span
          >/Option {{ details?.["quoteOptionNumber"] ?? "" }}/</span
        >
        <div class="product-name">
          <button
            [className]="
              addNewNoteLoader
                ? 'product-dropdown default-style'
                : 'product-dropdown active-style'
            "
            (click)="!addNewNoteLoader && toggleProductDropdown()"
            (blur)="!addNewNoteLoader && toggleProductDropdown()"
          >
            <span>{{ selectedProduct?.["product"] ?? "" }}*</span>
            <span class="dropdown-icon" *ngIf="!addNewNoteLoader">
              <img src="assets/img/chevron-down.png" alt="dropdown-icon" />
            </span>
          </button>
          <div
            class="product-list-dropdown"
            *ngIf="!addNewNoteLoader && showProductDropdown"
            (blur)="!addNewNoteLoader && toggleProductDropdown()"
          >
            <button
              *ngFor="let productDetail of details['products']"
              class="list-item"
              (click)="handleProductChange(productDetail)"
              [disabled]="productDetail.product === selectedProduct['product']"
              [class.noPointerEvents]="
                productDetail.product === selectedProduct['product']
              "
            >
              {{ productDetail.product }}
            </button>
          </div>
        </div>
      </div>
      <div class="badge-wrapper" *ngIf="!addNewNoteLoader">
        <div class="badge">Quote</div>
      </div>
    </div>
    <div class="body-section" *ngIf="!isLoader && !addNewNoteLoader">
      <div class="category-wrapper">
        <boxxds-dropdown-v2
          [label]="
            'workFlow3.components.notes.label.selectCategory' | translate
          "
          [options]="notesCategory"
          [form]="notesFormGroup"
          [control]="'categoryId'"
          [requiredErrMsg]="
            'workFlow3.components.notes.error.thisFieldIsRequired' | translate
          "
          [formSubmitted]="submitted"
          [isRequired]="true"
          [dropdownContainerStyle]="{
            background: '#fff',
            borderRadius: '8px'
          }"
          (currentValueChange)="handleCategoryValueChange($event)"
          [currentSelectedOption]="currentCategorySelected"
          [style]="{ width: '100%' }"
          [currentValue]="currentCategorySelected.value"
        >
        </boxxds-dropdown-v2>
      </div>
      <div class="notes-wrapper">
        <boxxds-textarea
          [label]="
            'workFlow3.components.notes.label.noteInputLabel' | translate
          "
          [placeholder]="
            'workFlow3.components.notes.label.messageBody' | translate
          "
          [form]="notesFormGroup"
          control="note"
          [lblStyle]="{
            color: '#292933',
            fontFamily: 'Gibson',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '20px',
            height: '28px'
          }"
          [textareaStyle]="{
            background: '#fff',
            height: '149px !important',
            border: '1px solid #D0D5DD'
          }"
          [formSubmitted]="errorMsgValid"
          [customErrMsg]="customErrMsg | translate"
          (handleKeyUp)="onKeyUpNoteValidation($event)"
          [style]="{ width: '100%' }"
          [isRequired]="true"
        ></boxxds-textarea>
      </div>
      <div class="note-btn-wrapper">
        <boxxds-button-v2
          class="footer-button"
          [buttonText]="'workFlow3.components.notes.button.addNote' | translate"
          [size]="'md'"
          [btnClass]="'primary'"
          [btnType]="'submit'"
          [btnStyle]="{ padding: '8px 16px', width: '109px' }"
          [isDisabled]="errorMsgValid"
          (handleClick)="handleNewNoteAdd($event)"
        >
        </boxxds-button-v2>
      </div>
    </div>
    <section class="spinner-container" *ngIf="isLoader">
      <div class="spinner-and-message-container">
        <div class="spinner-icon">
          <img src="assets/img/dotted-spinner.svg" alt="spinner-icon" />
        </div>
        <div class="spinner-message" *ngIf="addNewNoteLoader">
          Adding note...
        </div>
      </div>
    </section>
    <boxxds-button-v2
      [isDisabled]="isLoader && addNewNoteLoader"
      [size]="'lg'"
      [btnType]="'close'"
      (handleClick)="!addNewNoteLoader && closeModal()"
      [style]="{ position: 'absolute', top: 0, right: 0 }"
      [class.noPointerEvents]="addNewNoteLoader"
    ></boxxds-button-v2>
  </section>
</div>
