<div class="submissionflow3-page" *ngIf="!showBodySpinner">
  <app-quote-action-popup-v2
    [showModal]="showActionPopup"
    [details]="actionPopupDetails"
    (handleActionConfirm)="handleConfirmRiskAction($event)"
    (handleClose)="showActionPopup = false"
  ></app-quote-action-popup-v2>
  <app-modal-premium-recalculate
    *ngIf="showRecalcConfModal && form$ | async as formData"
    [showModal]="showRecalcConfModal"
    [existingQuoteOptionData]="actionPopupDetails"
    (handleClose)="closeModalRecalculateConf()"
    (handleSubmissionRecalculate)="
      handleSubmissionRecalculate($event, formData)
    "
    (handleDiscardRecalculate)="handleDiscardRecalculate($event)"
  >
  </app-modal-premium-recalculate>
  <app-broker-commission-v2-modal
    *ngIf="showCommissionModal"
    [productsInfo]="productInfo"
    [regionInfo]="regionInfo"
    [showCommissionModal]="showCommissionModal"
    (handleClose)="handleClose()"
    (handleSubmit)="submitBrokerCommission($event)"
    [brokerageId]="selectedBroker?.id"
    [brokerage]="selectedBroker"
    (handleNotifMsg)="handleNotifMsg($event)"
    [commissionDisabled]="commissionDisabled"
    [commissionId]="commissionId"
  >
  </app-broker-commission-v2-modal>
</div>

<div
  class="spinner-container"
  *ngIf="
    showBodySpinner ||
    (((form$ | async)?.riskQuestions | json) === ({} | json) &&
      this.InsuredType === true)
  "
>
  <ng-container>
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{
          width: '112px',
          height: '112px',
          '--b': '15px'
        }"
      ></boxxds-spinner>
    </div>
  </ng-container>
</div>
<div
  class="riskAnalysisPageOuter submissionflow3-page"
  *ngIf="
    !(
      showBodySpinner ||
      (((form$ | async)?.riskQuestions | json) === ({} | json) &&
        this.InsuredType === true)
    )
  "
>
  <div class="alertContainer">
    <boxxds-alert-v2
      *ngIf="alertInfo && noCommission"
      [type]="alertInfo.alertType"
      [linkData]="{ label: alertInfo.alertLinkText, path: '' }"
      [headerText]="alertInfo.alertHeader | translate"
      [bodyText]="alertInfo.alertText | translate"
      (handleClick)="handleLinkAction()"
      (handleCloseEvent)="handleAlertClose()"
      [containerStyle]="{
        position: 'fixed',
        top: '180px',
        transition: 'all 0.3s ease-out',
        marginTop: '0',
        marginBottom: '8px',
        width: '100vw',
        paddingRight: '0px',
        left: '0'
      }"
    >
    </boxxds-alert-v2>
  </div>

  <div class="riskAnalysisPage">
    <section
      class="productContainer"
      *ngIf="getStandaloneSellablePdts((form$ | async)?.products).length === 1"
    >
      <div class="head">
        <boxxds-body
          [sz]="'xl'"
          [text]="
            'workFlow3.components.riskAnalysis.heading.featureProduct'
              | translate
          "
          [customStyle]="{
            color: '#292933',
            fontFamily: 'Gibson',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '30px'
          }"
        ></boxxds-body>
      </div>
      <div class="product-list">
        <div
          class="single-product"
          *ngFor="
            let option of getStandaloneSellablePdts((form$ | async)?.products);
            let i = index
          "
        >
          <div class="product-left">
            <div class="product-icon">
              <img src="assets/img/zap.svg" alt="zap" class="icon" />
            </div>
            <div class="product-details">
              <div class="productName">
                <boxxds-body
                  [sz]="'xl'"
                  [text]="option.key"
                  [customStyle]="{
                    fontFamily: 'Gibson',
                    fontSize: '24px',
                    fontWeight: '600',
                    lineHeight: '32px',
                    color: '#475467'
                  }"
                ></boxxds-body>
              </div>
              <div class="detail">
                <boxxds-body
                  [sz]="'md'"
                  [text]="getProductDescription(option.description)"
                  [customStyle]="{
                    fontFamily: 'Gibson',
                    fontWeight: '400',
                    color: '#475467'
                  }"
                ></boxxds-body>
              </div>
            </div>
          </div>
          <div class="product-right">
            <boxxds-button-v2
              [buttonText]="
                'workFlow3.components.riskAnalysis.button.coverage' | translate
              "
              [isDisabled]="false"
              [size]="'lg'"
              [btnClass]="'link productPopover'"
              [rightImage]="'arrow-narrow-up-right'"
              (handleClick)="openPopup($event, popOverContent.coverage)"
              [btnStyle]="{ customStyle: 'value' }"
              [btnTextStyle]="{ customStyle: 'value' }"
              [rightImageStyle]="{ customStyle: 'value' }"
            ></boxxds-button-v2>
            <boxxds-button-v2
              [buttonText]="
                'workFlow3.components.riskAnalysis.button.services' | translate
              "
              [isDisabled]="false"
              [size]="'lg'"
              [btnClass]="'link productPopover'"
              [rightImage]="'arrow-narrow-up-right'"
              (handleClick)="openPopup($event, popOverContent.service)"
              [btnStyle]="{ customStyle: 'value' }"
              [btnTextStyle]="{ customStyle: 'value' }"
              [rightImageStyle]="{ customStyle: 'value' }"
            ></boxxds-button-v2>
          </div>
        </div>
      </div>
    </section>
    <!-- update start -->
    <div
      class="riskAnalysis-container"
      *ngIf="getStandaloneSellablePdts((form$ | async)?.products).length > 0"
    >
      <section
        class="cRC-Product-Container"
        *ngIf="getStandaloneSellablePdts((form$ | async)?.products).length > 1"
      >
        <div class="head">
          <boxxds-body
            [sz]="'xl'"
            [text]="
              'workFlow3.components.riskAnalysis.heading.selectProduct'
                | translate
            "
            [customStyle]="{
              color: '#292933',
              fontFamily: 'Gibson',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '30px'
            }"
          ></boxxds-body>
        </div>
        <div class="crc-product-list">
          <div
            class="crc-product"
            *ngFor="
              let option of getStandaloneSellablePdts(
                (form$ | async)?.products
              );
              let i = index
            "
          >
            <app-product-card
              [detail]="option"
              (selectProduct)="selectProduct($event, i)"
              [selectedProduct]="getSelectedProduct()"
              [isDisabled]="!!quoteId || option.active === false"
            ></app-product-card>
          </div>
        </div>
      </section>

      <section
        class="securityControlsContainer"
        [ngClass]="{
          crc: getStandaloneSellablePdts((form$ | async)?.products).length > 1
        }"
      >
        <header class="securityControlsHeader">
          <div class="hederdiv">
            <div class="section-heading">
              <boxxds-body
                [sz]="'xl'"
                [text]="
                  'workFlow3.components.riskAnalysis.heading.securityControlsQuestions'
                    | translate
                "
                [customStyle]="{
                  color: '#292933',
                  fontFamily: 'Gibson',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '30px'
                }"
              ></boxxds-body>
              <boxxds-body
                [sz]="'sm'"
                [text]="
                  'workFlow3.components.riskAnalysis.subHeading.securityControlSubHeading'
                    | translate
                "
                [customStyle]="{
                  color: '#475467',
                  fontFamily: 'Gibson',
                  fontStyle: 'normal',
                  fontWeight: '400'
                }"
              ></boxxds-body>
            </div>

            <div class="underwriter-dropdown-holder">
              <boxxds-dropdown-v2
                [form]="riskForm"
                [control]="'underwriter'"
                [placeholder]="
                  'workFlow3.components.riskAnalysis.label.selectUnderwriter'
                    | translate
                "
                (currentValueChange)="
                  this.permissionList[this.currentScreen] &&
                    this.currentQuoteStatus !== 'quote-closed' &&
                    underwriterChanged($event)
                "
                [isRequired]="true"
                [options]="underWriterOptions"
                [label]="
                  'workFlow3.components.riskAnalysis.label.underwriter'
                    | translate
                "
                class="underwriter-input-fields"
                [requiredErrMsg]="'text msg'"
                [formSubmitted]="false"
                [currentValue]="
                  getStandaloneSellablePdts((form$ | async)?.products)[
                    getSelectedProductIndex()
                  ]?.underwriter
                "
                [currentSelectedOption]="{
                  id: 1,
                  value: 'string',
                  key: 'string'
                }"
                (loadMoreOptions)="
                  this.permissionList[this.currentScreen] &&
                    this.currentQuoteStatus !== 'quote-closed' &&
                    loadMoreUnderwriters($event)
                "
                [hasScrollablePagination]="true"
                [totalCount]="underwriterTotalCount"
                [totalPages]="underwriterTotalPages"
                [isSuggestedOption]="true"
                [hasOptionImage]="true"
                [isDisabled]="
                  !this.permissionList[this.currentScreen] ||
                  this.currentQuoteStatus === 'quote-closed' ||
                  disabledDropdownList.includes(
                    products[getSelectedProductIndex()]
                  )
                "
                [dropdownContainerStyle]="{
                  maxWidth: '249px',
                  minWidth: '249px',
                  backgroundColor: '#fff',
                  borderRadius: '8px'
                }"
                [dropdownListStyle]="{
                  zIndex: '1'
                }"
              ></boxxds-dropdown-v2>
            </div>
          </div>
        </header>
        <div
          class="product-nav-container"
          *ngIf="
            getStandaloneSellablePdts((form$ | async)?.products).length === 0
          "
        >
          <div
            class="product-nav"
            *ngFor="
              let product of selectedProductStandaloneOptions;
              let i = index
            "
            (click)="setCurentAsActive(product)"
            [ngClass]="{
              active: true
            }"
          >
            {{ product.key }}
          </div>
        </div>
        <ng-container
          *ngFor="
            let product of selectedProductStandaloneOptions;
            let i = index
          "
          ><!--product vise loop-->
          <div
            class="product-risk-container"
            *ngIf="(form$ | async).ui.currentProductDisplayed === product.value"
          >
            <!--active product check-->
            <div
              class="product-risk"
              [class.noPointerEvents]="
                !permissionList[currentScreen] ||
                currentQuoteStatus === 'quote-closed'
              "
              *ngIf="
                isRiskFormLoaded &&
                (form$ | async)?.riskQuestions[product.value] as riskQuestions
              "
              [formGroup]="riskForm"
            >
              <!--product vise risk Question-->
              <div
                class="product-riskQuestions-container"
                [formArrayName]="product.value"
              >
                <!--form array-->
                <div
                  class="risk-category-list"
                  *ngFor="
                    let category of getCategories(riskQuestions);
                    let catIdx = index
                  "
                >
                  <!--category loop-->
                  <div class="risk-category-container">
                    <boxxds-body
                      [sz]="'md'"
                      [text]="category"
                      [customStyle]="{
                        color: '#2D2D39',
                        fontFamily: 'Gibson',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        textTransform: 'capitalize'
                      }"
                      [isRequired]="true"
                    ></boxxds-body>
                    <div
                      class="category-radio"
                      *ngIf="getQuestions(category, riskQuestions).length > 1"
                    >
                      <!--check category has more than 1 question-->
                      <boxxds-radio-v2
                        [size]="'md'"
                        [items]="[
                          {
                            label:
                              ('workFlow3.components.riskAnalysis.label.yesToAll'
                              | translate),
                            subLabel: '',
                            currentValue:
                              'categoryProperties[product.value + catIdx]?.checkAllYesBtn === true',
                            value: 'true'
                          }
                        ]"
                        [control]="category"
                        [form]="getCategoryProductFormArray(product.value)"
                        [formSubmitted]="false"
                        [labelContainerStyle]="{ flexDirection: 'row' }"
                        [labelStyle]="{
                          color: '#2D2D39',
                          fontFamily: 'Gibson',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: '500',
                          lineHeight: '24px'
                        }"
                        (click)="
                          checkAllYes(
                            product.value,
                            category,
                            catIdx,
                            getQuestions(category, riskQuestions)
                          )
                        "
                      >
                      </boxxds-radio-v2>
                      <boxxds-radio-v2
                        [size]="'md'"
                        [items]="[
                          {
                            label:
                              ('workFlow3.components.riskAnalysis.label.noToAll'
                              | translate),
                            subLabel: '',
                            currentValue:
                              'categoryProperties[product.value + catIdx]?.checkAllYesBtn === false',
                            value: 'false'
                          }
                        ]"
                        [control]="category"
                        [form]="getCategoryProductFormArray(product.value)"
                        [formSubmitted]="false"
                        [labelContainerStyle]="{ flexDirection: 'row' }"
                        [labelStyle]="{
                          color: '#2D2D39',
                          fontFamily: 'Gibson',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: '500',
                          lineHeight: '24px'
                        }"
                        (click)="
                          checkAllNo(
                            product.value,
                            category,
                            catIdx,
                            getQuestions(category, riskQuestions)
                          )
                        "
                      >
                      </boxxds-radio-v2>
                    </div>
                  </div>
                  <div
                    class="risk-question-list"
                    *ngIf="getQuestions(category, riskQuestions) as riskQustns"
                  >
                    <!--if questions-->
                    <ng-container
                      *ngFor="
                        let q of getQuestions(category, riskQuestions);
                        let questnIdx = index
                      "
                    >
                      <!--question loop-->
                      <div
                        class="risk-question-outer"
                        *ngIf="
                          q.dataType.description === 'Boolean' ||
                          q.dataType.description === 'Numeric' ||
                          q.dataType.description === 'String'
                        "
                      >
                        <!--if question has descriptions-->
                        <div
                          class="risk-question-boolean-outer"
                          *ngIf="q.dataType.description === 'Boolean'"
                          [formGroupName]="getFormName(riskQuestions, q)"
                        >
                          <!--type boolean-->
                          <div class="risk-question-boolean">
                            <boxxds-body
                              [sz]="'sm'"
                              [text]="q.question"
                              [customStyle]="{
                                color: '#616162',
                                fontFamily: 'Gibson',
                                fontStyle: 'normal',
                                fontWeight: '500'
                              }"
                            ></boxxds-body>
                            <div
                              class="risk-question-radio-btn"
                              *ngIf="getProductFormArray(product.value)"
                            >
                              <boxxds-radio-v2
                                [control]="q.id"
                                [items]="[
                                  {
                                    label:
                                      ('workFlow3.components.riskAnalysis.label.yes'
                                      | translate),
                                    currentValue: q.value,
                                    value: 'true'
                                  },
                                  {
                                    label:
                                      ('workFlow3.components.riskAnalysis.label.no'
                                      | translate),
                                    currentValue: q.value,
                                    value: 'false'
                                  }
                                ]"
                                [form]="getProductFormArray(product.value)"
                                [formSubmitted]="
                                  permissionList[currentScreen] &&
                                  currentQuoteStatus !== 'quote-closed' &&
                                  validateRiskAnswers
                                "
                                [requiredErrMsg]="
                                  'workFlow3.components.riskAnalysis.error.thisFieldIsRequired'
                                    | translate
                                "
                                (click)="
                                  permissionList[currentScreen] &&
                                    currentQuoteStatus !== 'quote-closed' &&
                                    handleRadioCheck(
                                      product.value,
                                      category,
                                      catIdx
                                    )
                                "
                                [labelStyle]="{
                                  color: '#2D2D39',
                                  fontFamily: 'Gibson',
                                  fontSize: '14px',
                                  fontStyle: 'normal',
                                  fontWeight: '500',
                                  lineHeight: '20px'
                                }"
                              ></boxxds-radio-v2>
                            </div>
                          </div>
                          <div class="subQuestion" *ngIf="q.sub">
                            <ul>
                              <li
                                *ngFor="let point of getExplanationArr(q.sub)"
                              >
                                {{ point }}
                              </li>
                            </ul>
                          </div>
                          <div class="explanation" *ngIf="q.explanation">
                            <ul
                              [ngClass]="{
                                'column-layout': true
                              }"
                              type="none"
                            >
                              <li
                                *ngFor="
                                  let point of getExplanationArr(q.explanation)
                                "
                              >
                                {{ point }}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="risk-question-numeric-outer"
                          *ngIf="q.dataType.description === 'Numeric'"
                          [formGroupName]="getFormName(riskQuestions, q)"
                        >
                          <!--type numeric-->
                          <div class="risk-question-numeric">
                            <boxxds-textinput-v2
                              [type]="'number'"
                              [form]="getProductFormArray(product.value)"
                              [placeholder]="'Enter'"
                              [control]="q.id"
                              [id]="q.id"
                              [maxLength]="'200'"
                              [width]="'100%'"
                              [containerStyle]="{ maxWidth: '522px' }"
                              [requiredErrMsg]="
                                'workFlow3.components.riskAnalysis.error.thisFieldIsRequired'
                                  | translate
                              "
                              [invalidErrMsg]="
                                'workFlow3.components.riskAnalysis.error.thisFieldIsInvalid'
                                  | translate
                              "
                              [formSubmitted]="
                                permissionList[currentScreen] &&
                                currentQuoteStatus !== 'quote-closed' &&
                                validateRiskAnswers
                              "
                              maxLength="200"
                              [readonly]="
                                !permissionList[currentScreen] ||
                                currentQuoteStatus === 'quote-closed'
                              "
                              [isRequired]="true"
                              [isDisabled]="
                                !permissionList[currentScreen] ||
                                currentQuoteStatus === 'quote-closed'
                              "
                            ></boxxds-textinput-v2>
                            <div class="subQuestion" *ngIf="q.sub">
                              <ul>
                                <li
                                  *ngFor="let point of getExplanationArr(q.sub)"
                                >
                                  {{ point }}
                                </li>
                              </ul>
                            </div>
                            <div class="explanation" *ngIf="q.explanation">
                              <ul
                                [ngClass]="{
                                  'column-layout': true
                                }"
                                type="none"
                              >
                                <li
                                  *ngFor="
                                    let point of getExplanationArr(
                                      q.explanation
                                    )
                                  "
                                >
                                  {{ point }}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div
                          class="risk-question-text-outer"
                          *ngIf="q.dataType.description === 'String'"
                          [formGroupName]="getFormName(riskQuestions, q)"
                        >
                          <!--type text-->
                          <div class="risk-question-text">
                            <boxxds-body
                              [sz]="'sm'"
                              [text]="q.question"
                              [customStyle]="{
                                color: '#616162',
                                fontFamily: 'Gibson',
                                fontStyle: 'normal',
                                fontWeight: '500'
                              }"
                            ></boxxds-body>
                            <div class="subQuestion" *ngIf="q.sub">
                              <ul>
                                <li
                                  *ngFor="let point of getExplanationArr(q.sub)"
                                >
                                  {{ point }}
                                </li>
                              </ul>
                            </div>
                            <div class="explanation" *ngIf="q.explanation">
                              <ul
                                [ngClass]="{
                                  'column-layout': true
                                }"
                                type="none"
                              >
                                <li
                                  *ngFor="
                                    let point of getExplanationArr(
                                      q.explanation
                                    )
                                  "
                                >
                                  {{ point }}
                                </li>
                              </ul>
                            </div>
                            <div class="risk-question-input">
                              <boxxds-textarea
                                [form]="subRiskQuestionForm"
                                [placeholder]="'Enter'"
                                [control]="q.id"
                                [id]="q.id"
                                [containerStyle]="{ minHeight: '128px' }"
                                [label]="'Description'"
                                [requiredErrMsg]="
                                  'workFlow3.components.riskAnalysis.error.thisFieldIsRequired'
                                    | translate
                                "
                                [formSubmitted]="
                                  permissionList[currentScreen] &&
                                  currentQuoteStatus !== 'quote-closed' &&
                                  validateRiskAnswers
                                "
                                maxLength="200"
                                [isRequired]="true"
                                [isDisabled]="
                                  !permissionList[currentScreen] ||
                                  currentQuoteStatus === 'quote-closed'
                                "
                              ></boxxds-textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div
                class="indicated-quoted-switch-container"
                *ngIf="showSwitchIndicate()"
              >
                <div class="indicated-quoted-switch-label">
                  <div class="lbl-switch">
                    {{
                      "workFlow3.components.riskAnalysis.label.pricingDesignation"
                        | translate
                    }}
                    <span class="pricing-description-required">*</span>
                  </div>
                  <div class="sub-lbl-switch">
                    {{
                      "workFlow3.components.riskAnalysis.label.pricingDesignationSubLabel"
                        | translate
                    }}
                  </div>
                </div>
                <div class="indicated-quoted-switch">
                  <button
                    class="btn-indicated"
                    [ngClass]="{ inactive: isQuotedStatus() }"
                    (click)="handleQuoteStatus(true)"
                    [disabled]="isIndicatedStatus() ? true : null"
                  >
                    {{ "common.status.indicated" | translate }}
                  </button>
                  <button
                    class="btn-quoted"
                    [ngClass]="{ inactive: isIndicatedStatus() }"
                    (click)="handleQuoteStatus(false)"
                    [disabled]="isQuotedStatus() ? true : null"
                  >
                    {{ "common.status.quoted" | translate }}
                  </button>
                </div>
              </div>
              <div
                class="pending-info-reason-container"
                *ngIf="showPendingInfoReason"
              >
                <div class="pending-info-reason-label">
                  <div class="lbl-reason">
                    {{
                      "workFlow3.components.riskAnalysis.label.pendingInfoReason"
                        | translate
                    }}
                  </div>
                  <div class="sub-lbl-reason">
                    {{
                      "workFlow3.components.riskAnalysis.label.pendingInfoReasonSubLabel"
                        | translate
                    }}
                  </div>
                </div>
                <div class="pending-info-reason">
                  <boxxds-dropdown-v2
                    [form]="pendingInfoReasonForm"
                    [control]="'reason'"
                    [placeholder]="'common.select' | translate"
                    (currentValueChange)="selectPendingInfoReason($event)"
                    [options]="pendingInfoReasons"
                    [label]="
                      'workFlow3.components.riskAnalysis.label.listOfInfo'
                        | translate
                    "
                    [hasScrollablePagination]="true"
                    [dropdownListStyle]="{ maxHeight: '130px' }"
                    [ngStyle]="{ width: '350px' }"
                    [dropdownContainerStyle]="{ width: '350px' }"
                  ></boxxds-dropdown-v2>
                  <div class="selected-pending-info-reason">
                    <div class="reason-description">
                      {{ selectedPendingInfoReasonDescription }}
                    </div>
                    <div>
                      <button
                        *ngIf="!selectedPendingInfoReasonResolved"
                        class="btn-resolve"
                        (click)="resolvePendingInfoReason()"
                      >
                        {{
                          "workFlow3.components.riskAnalysis.label.resolve"
                            | translate
                        }}
                      </button>
                      <img
                        *ngIf="selectedPendingInfoReasonResolved"
                        src="assets/img/check-circle.svg"
                        alt="resolved"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </section>
    </div>
    <section class="riskAnalysisFooter">
      <div class="footerLeft">
        <boxxds-button-v2
          *ngIf="
            !isNavigatedFromQuoteCalc &&
            backButton === true &&
            !hideBackBtnOnQuoteFlow
          "
          [buttonText]="'workFlow3.components.shared.button.back' | translate"
          [isDisabled]="showSpinner"
          [size]="'sm'"
          [btnClass]="'link gray'"
          [leftImage]="'arrow-narrow-left'"
          (handleClick)="handleBackBtn()"
        ></boxxds-button-v2>
      </div>
      <div class="footerRight">
        <boxxds-button-v2
          *ngIf="
            !showPendingInfoReason &&
            permissionList[currentScreen] &&
            currentQuoteStatus !== 'quote-closed' &&
            !showSpinner &&
            !uiData?.isNavigatedFromQuoteCalc &&
            currentSelectedProduct['active']
          "
          [buttonText]="buttonLabel"
          [isDisabled]="
            showSpinner || products.length === 0 || !isUserActionDetected()
          "
          [class.noPointerEvents]="
            showSpinner || products.length === 0 || !isUserActionDetected()
          "
          [size]="'lg'"
          [btnClass]="'secondary gray'"
          [btnType]="'default'"
          [btnStyle]="{ backgroundColor: 'rgba(0,0,0,0)' }"
          (handleClick)="isUserActionDetected() && handleSaveAndExit($event)"
        ></boxxds-button-v2>
        <boxxds-button-v2
          *ngIf="
            permissionList[currentScreen] &&
            !showSpinner &&
            quoteId &&
            currentQuoteStatus !== 'quote-closed'
          "
          [buttonText]="
            'workFlow3.components.shared.button.decline' | translate
          "
          [size]="'lg'"
          [btnClass]="'secondary gray'"
          [btnType]="'default'"
          (handleClick)="handleRiskAction(transactionRiskActions.Decline)"
          [isDisabled]="showSpinner"
          [btnStyle]="{ width: '150px' }"
        ></boxxds-button-v2>
        <boxxds-button-v2
          *ngIf="
            showApproveBtn &&
              currentSelectedProduct['active'] &&
              !showSpinner &&
              form$ | async as formData
          "
          [buttonText]="
            'workFlow3.components.riskAnalysis.button.approve' | translate
          "
          [isDisabled]="showSpinner || products.length === 0"
          [size]="'lg'"
          [btnClass]="'secondary'"
          [btnType]="'default'"
          (handleClick)="handleApprove()"
          [btnStyle]="{ color: '#FFAA00', width: '150px' }"
        ></boxxds-button-v2>
        <boxxds-button-v2
          *ngIf="
            !showPendingInfoReason &&
              currentSelectedProduct['active'] &&
              !showSpinner &&
              form$ | async as formData
          "
          [buttonText]="
            'workFlow3.components.riskAnalysis.button.continueToQuoting'
              | translate
          "
          [isDisabled]="showSpinner || products.length === 0"
          [size]="'lg'"
          [btnClass]="'primary'"
          [btnType]="'default'"
          (handleClick)="handleContinue($event, formData)"
          [btnStyle]="{ width: '219px' }"
        ></boxxds-button-v2>
        <boxxds-button-v2
          *ngIf="
            showPendingInfoReason &&
              currentSelectedProduct['active'] &&
              !showSpinner &&
              form$ | async as formData
          "
          [buttonText]="'common.save' | translate"
          [isDisabled]="showSpinner || products.length === 0"
          [size]="'lg'"
          [btnClass]="'primary'"
          [btnType]="'default'"
          (handleClick)="handlePendingInfoReasonSave($event, formData)"
          [btnStyle]="{ width: '219px' }"
        ></boxxds-button-v2>
      </div>
    </section>
  </div>
</div>
<div id="popup-container"></div>
