import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ModalAlertService } from 'src/app/services/modal-alert.service';
import { PolicyRiskDocProcessService } from 'src/app/services/policy-risk-doc-process.service';
import { getAlertHead, getErrorMessage } from 'src/app/utils/utils';

@Component({
  selector: 'app-modal-email-quote-v2',
  templateUrl: './modal-email-quote-v2.component.html',
  styleUrls: ['./modal-email-quote-v2.component.less'],
})
export class ModalEmailQuoteV2Component implements OnInit, OnChanges {
  @Input() showModal: boolean = false;
  @Input() details;
  @Input() productId;
  @Input() policyRiskId;
  @Input() titleText = 'Select a document type and a corresponding template.';
  @Input() previewTitle = 'Selected documents';
  @Input() docTempContainerStyle: { [klass: string]: any };
  @Input() isLoader;
  @Input() popupHeading: string = '';

  @Output() handleOutput = new EventEmitter<any>();
  @Output() handleClose = new EventEmitter<any>();
  @Output() handleActionConfirm = new EventEmitter<{ action: any }>();
  @Output() handleSuccess = new EventEmitter<any>();

  docOptions = [];
  tempOptions = [];
  isAllSelected: boolean = true;
  isSelected: { [key: number]: boolean } = {};
  isTempSelected: { [key: number]: boolean } = {};
  selectedMenuid;
  docTempOptions: {
    doc: any;
    docId: any;
    template: any;
    tempId: any;
    tempType: any;
    dataInjection: any;
    docTempProcessId: any;
  }[] = [];
  selectedDoc;
  selectedTemp;
  docLength = 0;
  docSelectedCount = 0;
  documentTemplateData: any[];
  Document: {
    name: string;
    templates: [];
  };
  submenuOpen: { [key: number]: boolean } = {};
  submenuDirection: { [key: number]: string } = {};
  submenuDirectionLeft: { [key: number]: string } = {};

  constructor(
    private policyRiskDocProcessService: PolicyRiskDocProcessService,
    private alertService: ModalAlertService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.policyRiskId = this.details.policyRiskId;
    this.resetForm();
    this.documentTemplateData = this.details?.documentTemplate;
    this.populateDocumentType();
    this.docLength = this.docOptions?.length;
    this.docSelectedCount = this.docOptions?.filter((item) => item.disabled)
      .length;
    this.previewAlreadySelectedDocuments();
  }

  ngOnChanges(): void {
    this.documentTemplateData = this.details?.documentTemplate;
    this.populateDocumentType();
    this.docLength = this.docOptions?.length;
    this.docSelectedCount = this.docOptions?.filter((item) => item.disabled)
      .length;
    this.previewAlreadySelectedDocuments();
  }

  previewAlreadySelectedDocuments() {
    // Iterate over the 'documentTemplateData' array
    this.documentTemplateData.forEach((item) => {
      // Iterate over the 'templates' array within each item
      item.templates.forEach((template) => {
        // Check if the template is selected
        if (template.docProcessId) {
          const docIndex = this.docOptions.findIndex(
            (docObj) => docObj.value === item?.templateType?.id,
          );
          this.docOptions[docIndex].disabled = true;
          this.isSelected[item?.templateType?.id] = true;
          this.isTempSelected[template.id] = true;
          // Push the selected template details to the 'docTempOptions' array
          this.docTempOptions.push({
            doc: `${item?.documentType?.type}/${item?.templateType?.type}`,
            docId: item?.templateType?.id,
            template: template.templateDescription,
            tempId: template.id,
            tempType: item.templateType.id,
            dataInjection: template.dataInjection,
            docTempProcessId: template.docProcessId,
          });
        }
      });
    });
    this.docSelectedCount = this.docOptions.filter(
      (item) => item.disabled,
    ).length;

    this.isAllSelected = this.docSelectedCount !== this.docLength;
    let OutputDataToEmit: any = {
      selectedTempOptions: this.docTempOptions,
      isAlloptionsSelected: !this.isAllSelected,
      productId: this.productId,
    };
    this.handleDocTempSelectionOutput(OutputDataToEmit);
  }

  handleDocTempSelectionOutput(updatedData) {
    this.docTempOptions = updatedData.selectedTempOptions;
  }

  handleDocumentClick(value): void {
    this.isSelected[value] = true;
    this.selectedMenuid = value;
    Object.keys(this.submenuOpen).forEach((key) => {
      if (parseInt(key) !== value) {
        this.submenuOpen[parseInt(key)] = false;
      }
    });

    Object.keys(this.isSelected).forEach((key) => {
      const parsedKey = parseInt(key);
      if (parsedKey !== value) {
        // Check if the key is not in docTempOptions
        const keyNotInDocTempOptions = this.docTempOptions.every(
          (docObj) => docObj.docId !== parsedKey,
        );

        if (keyNotInDocTempOptions) {
          // If the key is not in docTempOptions, set isSelected to false
          this.isSelected[parsedKey] = false;
        }
      }
    });

    this.selectedDoc = value;
    const selectedRecord = this.documentTemplateData.find(
      (record) => record.templateType.id === this.selectedDoc,
    );
    this.populatetemplateType(selectedRecord);
    this.submenuOpen[value] = !this.submenuOpen[value];
    this.calculateSubmenuDirection(value);
  }
  //handle template-change section
  handleTemplateSelection(value, docid, e) {
    e.stopPropagation();
    this.isTempSelected[value] = true;

    Object.keys(this.isTempSelected).forEach((key) => {
      const parsedKey = parseInt(key);
      // Check if the tempId belongs to the same docid and is not the current tempId
      if (
        parsedKey !== value &&
        this.docTempOptions.find(
          (docObj) => docObj.docId === docid && docObj.tempId === parsedKey,
        )
      ) {
        // If the tempId belongs to the same docid and is not the current tempId, set it to false
        this.isTempSelected[parsedKey] = false;
      }
    });

    const index = this.tempOptions.findIndex((docObj) => docObj.value == value);
    this.selectedTemp = value;
    this.updatePreview();
  }

  isSubMenuOpen(index: number): boolean {
    return this.submenuOpen[index];
  }

  calculateSubmenuDirection(menuId: number) {
    const parentContainerElement = document.getElementById('listGroup');
    const submenuElement = document.getElementById(`submenu-${menuId}`);
    const menuItemElement = document.getElementById(`menu-item-${menuId}`);
    const rect = menuItemElement.getBoundingClientRect();
    const parentRect = parentContainerElement.getBoundingClientRect();
    const openTopmost = rect.top - parentRect.top === 1;
    const openUp = rect.top - parentRect.top > parentRect.height / 2; // Open upwards if the menu item is in the lower half of the parent container
    const openDown =
      rect.bottom - parentRect.top + submenuElement.offsetHeight <=
      parentRect.height / 2; // Open downwards if there's enough space below the menu item
    const submenuPosition = {
      top: rect.top - parentRect.top,
      left: parentRect.right - parentRect.left, // Position the submenu to the right of the menu item
    };

    if (this.docOptions.length < 2 || openTopmost) {
      this.submenuDirection[menuId] = 'open-down';
    } else if (openUp) {
      this.submenuDirection[menuId] = 'open-up';
    } else if (openDown) {
      this.submenuDirection[menuId] = 'open-down';
    } else {
      this.submenuDirection[menuId] = 'open-center';
    }
    this.submenuDirectionLeft[menuId] = submenuPosition.left + 'px';
  }
  //Reset form
  private resetForm() {
    this.docOptions.forEach((item) => (item.disabled = false));
    this.docSelectedCount = this.docOptions.filter(
      (item) => item.disabled,
    ).length;

    this.selectedDoc = '';
    this.selectedTemp = '';
    this.isAllSelected = true;
    this.docTempOptions = [];
  }

  //Remove document-template from preview list

  handleDocTempRemove(doc, temp, index) {
    const docIndex = this.docOptions.findIndex((docObj) => docObj.key === doc);
    if (docIndex !== -1) {
      this.docOptions[docIndex].disabled = false;
    }
    this.docSelectedCount = this.docOptions.filter(
      (item) => item.disabled,
    ).length;

    this.isSelected[this.docOptions[docIndex].value] = false;
    this.isTempSelected[this.docTempOptions[index].tempId] = false;
    if (index !== -1) {
      this.docTempOptions.splice(index, 1);
    }
    this.isAllSelected = this.docSelectedCount !== this.docLength;
    let OutputDataToEmit: any = {
      selectedTempOptions: this.docTempOptions,
      isAlloptionsSelected: !this.isAllSelected,
      productId: this.productId,
    };
    this.handleDocTempSelectionOutput(OutputDataToEmit);
  }
  //handle template-change section
  handleTemplateChange(value) {
    if (value) {
      const index = this.tempOptions.findIndex(
        (docObj) => docObj.value == value,
      );
      this.selectedTemp = value;
      this.updatePreview();
    }
  }
  //handle document-change section
  handleDocumentChange(value) {
    this.selectedTemp = '';
    if (value) {
      this.selectedDoc = '';

      this.selectedDoc = value;
      this.tempOptions = [];
      const selectedRecord = this.documentTemplateData.find(
        (record) => record.templateType.id === this.selectedDoc,
      );
      this.populatetemplateType(selectedRecord);
      this.updatePreview();
    }
  }

  //handle document-template preview section
  private updatePreview() {
    const docIndex = this.docOptions.findIndex(
      (docObj) => docObj.value == this.selectedDoc,
    );
    const tempIndex = this.tempOptions.findIndex(
      (docObj) => docObj.value == this.selectedTemp,
    );
    const docTempIndex = this.docTempOptions.findIndex(
      (docObj) => docObj.doc === this.docOptions[docIndex].key,
    );

    if (this.selectedDoc && this.selectedTemp) {
      if (docTempIndex === -1) {
        this.docTempOptions.push({
          doc: this.docOptions[docIndex]?.key,
          docId: this.docOptions[docIndex]?.value,
          template: this.tempOptions[tempIndex]?.key,
          tempId: this.tempOptions[tempIndex]?.value,
          tempType: this.tempOptions[tempIndex]?.tempType,
          dataInjection: this.tempOptions[tempIndex]?.dataInjection,
          docTempProcessId: this.tempOptions[tempIndex]?.docProcessId,
        });
        this.docOptions[docIndex].disabled = true;
        this.docSelectedCount = this.docOptions.filter(
          (item) => item.disabled,
        ).length;

        this.isAllSelected = this.docSelectedCount !== this.docLength;
      } else if (docTempIndex || docTempIndex === 0) {
        this.docTempOptions[docTempIndex].template =
          this.tempOptions[tempIndex]?.key;
        this.docTempOptions[docTempIndex].tempId =
          this.tempOptions[tempIndex]?.value;
      }
    }
    let OutputDataToEmit: any = {
      selectedTempOptions: this.docTempOptions,
      isAlloptionsSelected: !this.isAllSelected,
      productId: this.productId,
    };
    this.handleDocTempSelectionOutput(OutputDataToEmit);
  }
  //populate Document type in document section
  populateDocumentType() {
    this.docOptions = this.documentTemplateData?.map((record) => ({
      value: record?.templateType?.id,
      key: `${record?.documentType?.type}/${record?.templateType?.type}`,
      disabled: false,
    }));
  }
  //populate template type in template section
  populatetemplateType(selectedRecord) {
    this.tempOptions = selectedRecord?.templates.map((record) => ({
      value: record?.id,
      key: record?.templateDescription,
      disabled: false,
      tempType: selectedRecord?.templateType?.id,
      dataInjection: record?.dataInjection,
      docTempProcessId: record?.docProcessId,
    }));
  }

  handleDocumentTempSave() {
    return new Promise<any>(async (resolve, reject) => {
      try {
        if (this.docTempOptions && this.docTempOptions.length > 0) {
          let promises = this.docTempOptions.map(async (p) => {
            const docTempOptionsData = {
              riskTemplateId: p.tempId,
              policyRiskId: this.policyRiskId,
              documentName: p.template,
              documentUniqueName: p.template,
              dataInjection: p.dataInjection,
              templateType: p.tempType,
            };

            if (p.docTempProcessId) {
              return this.policyRiskDocProcessService
                .updatePolicyRiskDoc(p.docTempProcessId, docTempOptionsData)
                .toPromise();
            } else {
              const payloadForPolicyRiskDocCreate = {
                policyRiskDocs: [docTempOptionsData],
              };

              return this.policyRiskDocProcessService
                .createPolicyRiskDoc(payloadForPolicyRiskDocCreate)
                .toPromise();
            }
          });

          await Promise.all(promises);
          this.showModal = false;
        }
        resolve(true);
      } catch (error) {
        this.isLoader = false;
        const errorMessage = getErrorMessage(error);
        const notificationAlert = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: errorMessage,
        };
        this.alertService.addAlert(notificationAlert);
        reject(errorMessage);
      }
    });
  }

  closeHandler() {
    if (this.isLoader) {
      return;
    }
    this.alertService.clearAlert(-1);
    this.handleClose.emit();
  }

  async handleNext() {
    if (this.isAllSelected) {
      this.alertService.clearAlert(-1);
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: this.translate.instant(
          'workFlow3.components.policyConfiguration.error.allDocTempValidationError',
        ),
      };
      this.alertService.addAlert(alertData);
      return;
    }
    await this.handleDocumentTempSave();
    this.handleSuccess.emit();
  }
}
