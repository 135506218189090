import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getFormattedPeriod } from 'src/app/utils/formatDate';

@Component({
  selector: 'app-revenue-or-sanction-popup',
  templateUrl: './revenue-or-sanction-popup.component.html',
  styleUrls: ['./revenue-or-sanction-popup.component.less'],
})
export class RevenueOrSanctionPopupComponent implements OnInit {
  @Input() details: { [key: string]: any } = {};
  @Input() sanctionTableData: { [key: string]: any }[] = [];
  @Input() columnsToHide: string[] = [];
  @Input() sortableColumns: string[] = [];
  @Input() iconColumns: string[] = [];
  @Input() riskQuestions: any = [];
  @Input() customColumnWidth: {
    columnName: string;
    minWidth: string;
    maxWidth: string;
  }[] = [];

  @Output() handleClose = new EventEmitter();
  @Output() handleDecline = new EventEmitter();
  @Output() handleDiscardChanges = new EventEmitter();
  @Output() sortHandler = new EventEmitter();
  @Output() columnClickHandler = new EventEmitter();
  @Output() handleSave = new EventEmitter<any>();

  showPopOver = false;
  form: FormGroup;
  radioYesOrNoToAllForm: FormGroup;
  validateRiskAnswers: boolean = false;
  step = 0;
  isRestrictedIndustry = false;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    if (this.riskQuestions && this.sanctionTableData.length != 0) {
      this.step = 1;
    }

    this.sanctionTableData = this.sanctionTableData.map((sanction, index) => {
      if (index === 0 && this.isRestrictedIndustry) {
        return sanction;
      } else {
        const popOverData = [];
        popOverData.push(`Full Name: ${sanction['fullName']}`);
        popOverData.push(
          `Citizenship: ${
            sanction['citizenship']?.length > 0
              ? sanction['citizenship']?.join(', ')
              : '-'
          }`,
        );
        popOverData.push(`Remarks: ${sanction['remarks'] || '-'}`);
        popOverData.push(
          `Additional Sanctions: ${
            sanction['additionalSanctions']?.length > 0
              ? sanction['additionalSanctions'].join(', ')
              : '-'
          }`,
        );
        popOverData.push(
          `Programs: ${
            sanction['programs']?.length > 0
              ? sanction['programs'].join(', ')
              : '-'
          }`,
        );

        return {
          Type: 'OFAC',
          Description: 'Sanction is subject to secondary sanction',
          Reasons: popOverData,
          Source: sanction['source'] || 'SDN',
          Score: sanction['score']?.toString(),
          // popOverContent: popOverData,
        };
      }
    });
    const formGroupConfig = {};
    this.riskQuestions.forEach((riskQuestion) => {
      formGroupConfig[riskQuestion.id] = ['', [Validators.required]];
    });
    this.form = this.fb.group(formGroupConfig);

    this.radioYesOrNoToAllForm = this.fb.group({
      yesToAll: [''],
    });
  }

  handleRadioCheck() {
    this.radioYesOrNoToAllForm.controls['yesToAll'].setValue('');
  }

  checkYesOrNoToAll(event) {
    for (let question of this.riskQuestions) {
      if (this.form.contains(question.id)) {
        this.form.controls[`${question.id}`].setValue(event?.value);
      }
    }
  }

  toStepTwo() {
    this.step = 2;
  }

  handleSaveAndContinue() {
    this.validateRiskAnswers = true;
    if (!this.form.valid) {
      return;
    }
    const riskAnswerIds = Object.keys(this.form.value);
    const riskAnswers = riskAnswerIds.map((id) => ({
      riskQuestionId: id,
      riskAnswer: this.form.value[id],
    }));
    this.handleSave.emit(riskAnswers);
  }

  handleSorting(data: any): void {
    this.sortHandler.emit(data);
  }

  columnClickHandle(data: any): void {
    this.columnClickHandler.emit(data);
  }

  handleCloseHandler(data: any): void {
    this.handleClose.emit();
  }

  handleDeclineQuotes(data: any): void {
    this.handleDecline.emit();
  }

  handleDiscardChangesHandler(data: any): void {
    this.handleDiscardChanges.emit(data);
  }

  formatDate(date) {
    return date
      ? getFormattedPeriod(date, 'YYYY-MM-DDTHH:mm:ss.sssZ', 'MMM DD,YYYY')
      : '';
  }
}
