import { Action } from '@ngrx/store';

export const SET_INSURED_NAME = 'SET_INSURED_NAME';
export const SET_INSURED_ALERT = 'SET_INSURED_ALERT';
export const SET_INSURED_NAVIGATION_TAB = 'SET_INSURED_NAVIGATION_TAB';
export const RESET_IS_NAVIGATED_FROM_INDIVIDUAL_INSURED =
  'RESET_IS_NAVIGATED_FROM_INDIVIDUAL_INSURED';

export class setInsuredNameAction implements Action {
  readonly type = SET_INSURED_NAME;
  constructor(public name: string) {}
}

export class setInsuredAlertAction implements Action {
  readonly type = SET_INSURED_ALERT;
  constructor(public alertPayload: object) {}
}

export class setIsNavigatedFromInsuredIndividual implements Action {
  readonly type = RESET_IS_NAVIGATED_FROM_INDIVIDUAL_INSURED;
  constructor(public alertPayload: { [x: string]: any }) {}
}
export class setInsuredNavigationTab implements Action {
  readonly type = SET_INSURED_NAVIGATION_TAB;
  constructor(public payload: { navigationTab: string }) {}
}

export type Actions =
  | setInsuredNameAction
  | setInsuredAlertAction
  | setIsNavigatedFromInsuredIndividual;
