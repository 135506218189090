import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BoxxResponse } from '../entities/boxx-response';
import { Policy } from '../entities/policy';
import { BaseService } from './base-services';
import { QuoteOption } from '../entities/quote-option';

@Injectable({
  providedIn: 'root',
})
export class RiskRatingCalculateService extends BaseService<QuoteOption> {
  constructor(http: HttpClient) {
    const baseurl =
      environment.msRatingBaseUrl + environment.riskRatingCalculatePathUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  GetByQuoteOption(quoteOptionId): Observable<BoxxResponse<any>> {
    return this.http
      .get<BoxxResponse<any>>(this.baseurl + quoteOptionId)
      .pipe(catchError(this.errorHandl));
  }
}
