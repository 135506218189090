import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-bind-policy-button',
  templateUrl: './bind-policy-button.component.html',
  styleUrls: ['./bind-policy-button.component.less'],
})
export class BindPolicyButtonComponent {
  @Input() buttonText: string = 'Bind policy';
  @Input() btnStyle: { [klass: string]: any };
  @Input() status = 'default';

  @Output() handleClick = new EventEmitter();

  constructor() {}

  clickHandler(e) {
    this.handleClick.emit();
  }
}
