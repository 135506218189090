<div class="insured-directory-page">
  <section class="title-section">
    <div class="max-width-wrapper">
      <div class="title-description-container">
        <div class="title-container">
          <boxxds-heading
            class="title"
            [sz]="'6'"
            [text]="
              'workFlow3.insuredDirectory.label.insuredDirectory' | translate
            "
            type="bold"
          ></boxxds-heading>
          <!-- <p class="total-count-badge">
            {{ pagination.totalRecords + " Insured" }}
          </p> -->
        </div>
        <boxxds-body
          class="description"
          [text]="'workFlow3.insuredDirectory.label.keepTrackOf' | translate"
          [customStyle]="{
            fontFamily: 'Gibson',
            color: '#475467'
          }"
        >
        </boxxds-body>
      </div>
      <div class="button-container">
        <boxxds-button-v2
          *ngIf="!isRestrictedRoleSubmission"
          [buttonText]="
            'workFlow3.insuredDirectory.label.addInsured' | translate
          "
          [type]="'submit'"
          [isDisabled]="false"
          [size]="'md'"
          [btnClass]="'primary'"
          [btnType]="'default'"
          [rightImage]="'plus-circle'"
          [isDropdown]="false"
          (handleClick)="handleAddNewInsured()"
        ></boxxds-button-v2>
      </div>
    </div>
  </section>
  <section class="table-with-filters">
    <div class="max-width-wrapper">
      <div class="filter-section">
        <div class="category-filter">
          <ul>
            <ng-container *ngFor="let category of categoryFilters">
              <li
                (click)="
                  activeCategoryFilter !== category &&
                    handleCategoryFilter(category)
                "
                [class.active]="activeCategoryFilter === category"
              >
                {{ category }}
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
    <div class="max-width-wrapper">
      <div class="table-wrapper-container">
        <div class="table-header-banner">
          <div class="table-filter-title-container">
            <div
              class="result-section"
              *ngIf="hasFilterOptions || !searchValue"
            >
              <p class="title">
                <span class="title" type="bold">{{
                  "workFlow3.insuredDirectory.label.listOfInsured" | translate
                }}</span>
              </p>
              <p class="total-count-badge">
                {{ pagination.totalRecords + " Records" }}
              </p>
            </div>
            <div class="result-section">
              <p class="title" *ngIf="searchValue !== ''">
                Search result : &nbsp;<span [title]="searchValue">{{
                  searchValue
                }}</span>
              </p>
              <p class="total-count-badge" *ngIf="searchValue !== ''">
                {{ pagination.totalRecords + " Records" }}
              </p>
            </div>
          </div>
          <div class="filter-container">
            <div class="filter-optns-container">
              <div class="filter-optn" *ngFor="let filter of filteredOptions">
                <span class="option-name">{{ filter.value }}</span>
                <button
                  type="button"
                  aria-label="close"
                  class="close-btn"
                  (click)="handleFilterOptnRemove(filter.key)"
                >
                  <img src="assets/img/dashboard/quote/remove.png" />
                </button>
              </div>
            </div>
            <div class="filter-buttons-wrapper">
              <div class="search-input">
                <boxxds-textinput-v2
                  [form]="insuredForm"
                  [placeholder]="'Search insured'"
                  [control]="'searchKey'"
                  [id]="'search'"
                  [leftIcon]="'assets/img/workflow3/sidemenu/search.png'"
                  [width]="'200px'"
                  [maxLength]="'40'"
                  (handleKeyUp)="handleSearch($event)"
                >
                </boxxds-textinput-v2>
              </div>
              <div>
                <boxxds-button-v2
                  [isBtnActive]="showFilter"
                  [leftImage]="'assets/img/workflow3/filter-funnel.svg'"
                  btnType="unfilled"
                  [btnClass]="showFilter ? 'primary' : 'primary gray'"
                  [btnStyle]="{
                    width: '36px',
                    background: '#FFF',
                    height: '36px',
                    color: '#1018280D',
                    border: '1px solid #D0D5DD'
                  }"
                  (handleClick)="handleFilter()"
                  ><img src="assets/img/workflow3/filter-funnel.svg" />
                </boxxds-button-v2>
              </div>
              <boxxds-button-v2
                sz="sm"
                [isBtnActive]="true"
                [btnClass]="'secondary gray'"
                [buttonText]="''"
                [leftImage]="'download-01'"
                [type]="'submitted'"
                [btnStyle]="{
                  width: '36px',
                  height: '36px',
                  padding: '8px'
                }"
                (handleClick)="handleExport()"
              >
              </boxxds-button-v2>
            </div>
          </div>
        </div>

        <boxxds-table-v2
          id="insured-table"
          class="insured-table"
          [tableData]="tableData"
          [columnsToHide]="columnsToHide"
          [sortableColumns]="sortableColumns"
          [iconColumns]="iconColumns"
          [customColumnWidth]="customColumnWidth"
          (sortHandler)="handleSorting($event)"
          (columnClickHandler)="columnClickHandle($event)"
          [showPopOver]="true"
          [columnHeadsToHide]="columnHeadsToHide"
          [totalCount]="pagination.totalRecords"
          (pageChanged)="handleTablePageChange($event)"
          [activePageNumber]="pagination.currentPage"
          [showSpinner]="showTblSpinner"
          (editHandler)="handleEditInsured($event)"
          [tableContainerStyle]="{ maxHeight: '550px', minHeight: '550px' }"
          [multiContentColumns]="multiContentColumns"
          [sensitiveDataColumns]="['Insured Name', 'Address', 'Contact info']"
        >
        </boxxds-table-v2>
      </div>
    </div>
  </section>
</div>
<app-dynamic-filter-v2-modal
  *ngIf="showFilterPopup"
  (handleClose)="handleCloseFilterPopup($event)"
  [filterInputOptions]="filterInputOptions"
  [filterFormGroup]="filterFormGroup"
  (handleSubmit)="handleApplyFilter($event)"
  (handleFilterClear)="handleFilterClear($event)"
  [clearAllBtn]="true"
>
</app-dynamic-filter-v2-modal>
