import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Brokerage } from '../entities/brokerage';
import { environment } from 'src/environments/environment';
import { BoxxResponse } from '../entities/boxx-response';
import { IBaseDto } from '../dtos/baseDto';

@Injectable({
  providedIn: 'root',
})
export class BrokerageService {
  // Base url
  baseurl = environment.msBrokerBaseUrl + environment.brokeragePathUrl;
  constructor(private http: HttpClient) {}
  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  // POST
  CreateBrokerage(data): Observable<BoxxResponse<any>> {
    return this.http
      .post<BoxxResponse<any>>(
        `${environment.msBrokerBaseUrl}${environment.brokerageAtomicTrxPathUrl}`,
        JSON.stringify(data),
        this.httpOptions,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  // GET
  GetBrokerage(id): Observable<BoxxResponse<any>> {
    return this.http
      .get<BoxxResponse<any>>(this.baseurl + id)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  // GET ALL
  GetBrokerages(
    page = 1,
    limit = 10,
    sort = '',
  ): Observable<BoxxResponse<Brokerage>> {
    return this.http
      .get<BoxxResponse<Brokerage>>(
        this.baseurl + '?page=' + page + '&limit=' + limit + '&sort=' + sort,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }
  // PUT
  UpdateBrokerage(id, data): Observable<BoxxResponse<Brokerage>> {
    return this.http
      .put<BoxxResponse<Brokerage>>(
        this.baseurl + id,
        JSON.stringify(data),
        this.httpOptions,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }
  RestoreBrokerage(id): Observable<Brokerage> {
    return this.http
      .put<Brokerage>(this.baseurl + 'restore/' + id, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  // DELETE
  DeleteBrokerage(id) {
    return this.http
      .delete<Brokerage>(this.baseurl + id, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  // Error handling
  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else if (error.status == 400) {
      errorMessage = error.error;
    } else if (error.status == 500) {
      errorMessage = error.error;
    } else {
      // Get server-side error
      errorMessage = error.error.message;
    }

    return throwError(() => {
      return errorMessage;
    });
  }

  getActiveBrokerageIdName(
    name: string,
    skipChildrens: number,
    ignoredIds: string,
  ): Observable<BoxxResponse<IBaseDto>> {
    const { msBrokerBaseUrl, brokerageListCoreUrl } = environment;
    const url =
      `${msBrokerBaseUrl}${brokerageListCoreUrl}` +
      '?name=' +
      name +
      '&skipChildrens=' +
      skipChildrens +
      '&ignoredIds=' +
      ignoredIds;

    return this.http.get<BoxxResponse<IBaseDto>>(url);
  }

  getActiveCommissionByBrokerageId(brokerageId: number): Observable<any> {
    const { msBrokerBaseUrl, brokerageCommissionPathUrl } = environment;

    let params = new HttpParams();
    params = params.append('brokerageId', brokerageId);
    params = params.append('page', 1);
    params = params.append('limit', 100);

    const url = `${msBrokerBaseUrl}${brokerageCommissionPathUrl}`;
    return this.http.get<any>(url, { params: params });
  }

  GetBrokeragesByName(
    name = '',
    page = 1,
    limit = 10,
    sort = '',
    active = null,
  ): Observable<BoxxResponse<Brokerage>> {
    let url =
      this.baseurl + '?page=' + page + '&limit=' + limit + '&sort=' + sort;

    if (name) {
      url += '&name=' + name;
    }
    if (active) {
      url += '&active=' + active;
    }
    return this.http
      .get<BoxxResponse<Brokerage>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetBrokerageListByName(
    name,
    page = 1,
    limit = 10,
    sort = '',
  ): Observable<BoxxResponse<Brokerage>> {
    let url =
      environment.msBrokerBaseUrl +
      environment.brokerageListCoreUrl +
      '?name=' +
      name +
      '&page=' +
      page +
      '&limit=' +
      limit +
      '&sort=' +
      sort;

    return this.http
      .get<BoxxResponse<Brokerage>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
