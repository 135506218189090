import { Action } from '@ngrx/store';

import { Quote } from '../../models/quote.model';
import * as actionTypes from './create-quote.action';

const initialState = {
  companySearchForm: {
    companyBusinessName: '',
    website: '',
    address: '',
  },
  addCompanyForm: {
    businessName: '',
    initials: '',
    website: '',
    revenue: '',
    noOfEmployees: '',
    noOfPIIRecords: '',
    industry: '',
    industryClass: '',
    revenuePercentUS: '',
    revenuePercentCA: '',
    revenuePercentOther: '',
    fullName: '',
    email: '',
    phoneNumber: '',
    country: '',
    province: '',
    city: '',
    address: '',
    postalCode: '',
  },
  addIndividualForm: {
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    householdMembers: '',
    country: '',
    province: '',
    city: '',
    address: '',
    postalCode: '',
  },
  individualSearchForm: {
    customerName: '',
    email: '',
    address: '',
  },
  ui: {
    showCompanySearchRslt: false,
    isInsuredTypeCompany: true,
    addCompanyExpanded: false,
    showIndividualSearchRslt: false,
    addCustomerExpanded: false,
    brokerageName: '',
    insuredName: '',
    insuredId: 0,
    selectedInsuredId: 0,
    insuredEmail: '',
    currentProductDisplayed: '',
    brokerageId: 0,
    branchId: 0,
    producerId: 0,
    regionId: 0,
    versionId: 0,
    policyRiskTrxId: 0,
    errorLoadingRiskQns: false,
    errorLoadingRiskCoverages: false,
    policyId: 0,
    policyPeriodId: 0,
    saveAtomicTrx: false,
    isNavigatedFromLifecycle: false,
    isNavigatedFromSummary: false,
    isNavigatedFromQuoteCalc: false,
    isNavigatedFromRiskAnalysis: false,
    isNavigatedFromChangeProducerPopup: false,
    producerChangeData: {},
    industryId: 0,
    industryName: '',
    premium: '',
    startDate: '',
    endDate: '',
    pendingPriorDt: '',
    retroactiveDt: '',
    packagePolicyNumber: '',
    broker: '',
    producer: '',
    branch: '',
    timezone: '',
    latestPolicyRiskTrxId: 0,
    clearance: [],
    isRestricted: false,
    policyEffectiveDate: null,
    quoteCreatedDate: '',
    transactionType: '',
    transactionTypeId: 0,
  },
  mailIds: {},
  products: [],
  riskQuestions: {},
  riskCoverages: {},
  quoteOptions: [],
  insureds: [],
  pathOptions: '',
  pagination: {
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    hasNext: false,
    hasPrevious: false,
  },
  startDate: null,
  endDate: null,
  selectedQuote: null,
  brokerage: null,
  branch: null,
  producer: null,
  policyId: null,
  brokerageId: null,
  producerId: null,
  broker: null,
  shared: {
    activeStep: 1,
    completedSteps: 0,
    step: 'Insured Information',
  },
};

export function createQuoteReducer(state: Quote = initialState, action) {
  switch (action.type) {
    case actionTypes.RESET_PAGINATION:
      return {
        ...state,
        pagination: initialState.pagination,
      };
    case actionTypes.RESET_IS_NAVIGATED_FROM_LIFECYCLE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isNavigatedFromLifecycle: action.newState,
        },
      };
    case actionTypes.RESET_IS_NAVIGATED_FROM_SUMMARY:
      return {
        ...state,
        ui: {
          ...state.ui,
          isNavigatedFromSummary: !state?.ui?.isNavigatedFromSummary,
        },
      };
    case actionTypes.UPDATE_SHARED_DATA:
      return {
        ...state,
        shared: {
          ...state.shared,
          ...action.payload,
        },
      };
    case actionTypes.setSanctionAction:
      return {
        ...state,
        ui: {
          ...state.ui,
          clearance: action.payload.clearance,
          isRestricted: action.payload.isRestricted,
        },
      };
    case actionTypes.RESET_IS_NAVIGATED_FROM_QUOTE_CALC:
      return {
        ...state,
        ui: {
          ...state.ui,
          isNavigatedFromQuoteCalc: true,
        },
      };
    case actionTypes.SET_POLICY_PERIOD_ID:
      return {
        ...state,
        ui: {
          ...state.ui,
          policyPeriodId: action.id,
        },
      };

    case actionTypes.LOAD_INSURED:
      return {
        ...state,
        insureds: [...action.insureds],
        pagination: action.pagination,
      };

    case actionTypes.LOAD_RISK_REGION:
      return {
        ...state,
        products: [...action.products],
      };
    case actionTypes.LOAD_RISK_QUESTIONS:
      return {
        ...state,
        riskQuestions: {
          ...state.riskQuestions,
          ...action.riskQuestions,
        },
        ui: {
          ...state.ui,
          errorLoadingRiskQns: false,
        },
      };

    case actionTypes.CLEAR_RISK_QUESTIONS:
      return {
        ...state,
        riskQuestions: {},
      };
    case actionTypes.LOAD_RISK_QUESTIONS_ERROR:
      return {
        ...state,
        ui: {
          ...state.ui,
          errorLoadingRiskQns: true,
        },
      };
    case actionTypes.UPDATE_INSURED:
      return {
        ...state,
        ui: {
          ...state.ui,
          ...action.payload,
        },
      };
    case actionTypes.SEARCH_INSURED_COMPANY:
      return {
        ...state,
        companySearchForm: {
          ...state.companySearchForm,
          ...action.payload,
        },
      };
    case actionTypes.SEARCH_INSURED_CUSTOMER:
      return {
        ...state,
        individualSearchForm: {
          ...state.individualSearchForm,
          ...action.payload,
        },
      };
    case actionTypes.SET_SHOW_COMPANY_SEARCH_RESULT:
      return {
        ...state,
        ui: {
          ...state.ui,
          showCompanySearchRslt: true,
        },
      };
    case actionTypes.UNSET_SHOW_COMPANY_SEARCH_RESULT:
      return {
        ...state,
        ui: {
          ...state.ui,
          showCompanySearchRslt: false,
        },
        companySearchForm: {
          ...state.companySearchForm,
          ...initialState.companySearchForm,
        },
      };
    case actionTypes.SET_SHOW_CUSTOMER_SEARCH_RESULT:
      return {
        ...state,
        ui: {
          ...state.ui,
          showIndividualSearchRslt: true,
        },
      };
    case actionTypes.UNSET_SHOW_CUSTOMER_SEARCH_RESULT:
      return {
        ...state,
        ui: {
          ...state.ui,
          showIndividualSearchRslt: false,
        },
        individualSearchForm: {
          ...state.individualSearchForm,
          ...initialState.individualSearchForm,
        },
      };
    case actionTypes.ADD_INSURED_COMPANY:
      return {
        ...state,
        addCompanyForm: {
          ...state.addCompanyForm,
          ...action.payload,
        },
      };
    case actionTypes.ADD_INSURED_CUSTOMER:
      return {
        ...state,
        addIndividualForm: {
          ...state.addIndividualForm,
          ...action.payload,
        },
      };
    case actionTypes.RESET_INSURED_TYPE:
      const currentInsuredType = state.ui.isInsuredTypeCompany;
      return {
        ...state,
        companySearchForm: {
          ...state.companySearchForm,
          ...initialState.companySearchForm,
        },
        addCompanyForm: {
          ...state.addCompanyForm,
          ...initialState.addCompanyForm,
        },
        addIndividualForm: {
          ...state.addIndividualForm,
          ...initialState.addIndividualForm,
        },
        individualSearchForm: {
          ...state.individualSearchForm,
          ...initialState.individualSearchForm,
        },
        ui: {
          ...state.ui,
          ...initialState.ui,
          isInsuredTypeCompany: !currentInsuredType,
        },
        shared: {
          ...state.shared,
          ...initialState.shared,
        },
      };
    case actionTypes.SET_ADD_COMPANY_EXPANDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          addCompanyExpanded: true,
        },
      };
    case actionTypes.UNSET_ADD_COMPANY_EXPANDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          addCompanyExpanded: false,
        },
        addCompanyForm: {
          ...state.addCompanyForm,
          ...initialState.addCompanyForm,
        },
      };
    case actionTypes.SET_ADD_CUSTOMER_EXPANDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          addCustomerExpanded: true,
        },
      };
    case actionTypes.UNSET_ADD_CUSTOMER_EXPANDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          addCustomerExpanded: false,
        },
        addIndividualForm: {
          ...state.addIndividualForm,
          ...initialState.addIndividualForm,
        },
      };
    case actionTypes.UPDATE_PRODUCTS:
      const updatedProducts = state.products.map((pdtObj, index) =>
        index === action.index
          ? { ...pdtObj, checked: !state.products[action.index].checked }
          : pdtObj,
      );
      return {
        ...state,
        products: updatedProducts,
      };
    case actionTypes.SELECT_ALL_PRODUCTS_CLICKED:
      const updatedProductArr = state.products.map((pdtObj, index) =>
        pdtObj.active
          ? { ...pdtObj, checked: action.changeCheckBoxTo }
          : pdtObj,
      );
      return {
        ...state,
        products: updatedProductArr,
      };
    case actionTypes.UPDATE_START_DATE:
      return {
        ...state,
        startDate: action.date,
      };
    case actionTypes.UPDATE_END_DATE:
      return {
        ...state,
        endDate: action.date,
      };
    case actionTypes.UPDATE_CURRENT_PRODUCT:
      return {
        ...state,
        ui: {
          ...state.ui,
          currentProductDisplayed: action.product,
        },
      };
    case actionTypes.UPDATE_PRODUCT_UNDERWRITER:
      const newPdts = state.products.map((pdtObj, index) =>
        index === action.payload.index
          ? {
              ...pdtObj,
              underwriter: action.payload.underwriter,
              underwriterId: action.payload.underwriterId,
            }
          : pdtObj,
      );
      return {
        ...state,
        products: newPdts,
      };
    case actionTypes.UPDATE_PRODUCT_LIMIT:
      const newPdt = state.products.map((pdtObj, index) =>
        index === action.payload.index
          ? {
              ...pdtObj,
              limit: action.payload.limit,
              limitId: action.payload.limitId,
            }
          : pdtObj,
      );
      return {
        ...state,
        products: newPdt,
      };

    case actionTypes.UPDATE_PRODUCT_ADDONS:
      const newPdtsAddons = state.products.map((pdtObj, index) =>
        index === action.payload.index
          ? { ...pdtObj, addons: action.payload.addons }
          : pdtObj,
      );
      return {
        ...state,
        products: newPdtsAddons,
      };
    case actionTypes.UPDATE_RISK_ANSWER:
      let riskquestionArr;
      if (action.payload.id) {
        riskquestionArr = state.riskQuestions[action.payload.product].map(
          (riskObj) =>
            riskObj.id === action.payload.id
              ? { ...riskObj, value: action.payload.value }
              : riskObj,
        );
      } else {
        riskquestionArr = state.riskQuestions[action.payload.product].map(
          (riskObj) =>
            riskObj.category === action.payload.category
              ? { ...riskObj, value: action.payload.value }
              : riskObj,
        );
      }

      return {
        ...state,
        riskQuestions: {
          ...state.riskQuestions,
          [action.payload.product]: riskquestionArr,
        },
      };
    case actionTypes.ADD_QUOTE_OPTION:
      if (action.payload.duplicateIdx || action.payload.duplicateIdx == 0) {
        return {
          ...state,
          quoteOptions: [
            ...state.quoteOptions.slice(0, action.payload.duplicateIdx + 1),
            action.payload?.quote,
            ...state.quoteOptions.slice(action.payload.duplicateIdx + 1),
          ],
        };
      }
      return {
        ...state,
        quoteOptions: [...state.quoteOptions, action.payload.quote],
      };
    case actionTypes.CLEAR_QUOTE_OPTION:
      return {
        ...state,
        quoteOptions: [],
      };
    case actionTypes.UPDATE_QUOTE_OPTION:
      return {
        ...state,
        quoteOptions: [
          ...state.quoteOptions.slice(0, action.payload.index),
          action.payload.quote,
          ...state.quoteOptions.slice(action.payload.index + 1),
        ],
      };
    case actionTypes.DELETE_QUOTE_OPTION:
      return {
        ...state,
        quoteOptions: [
          ...state.quoteOptions.slice(0, action.payload.index),
          ...state.quoteOptions.slice(action.payload.index + 1),
        ],
      };
    case actionTypes.SET_IS_QUOTE_BOX_EXPANDED:
      const newQuoteOptions = state.quoteOptions.map((pdtObj) => ({
        ...pdtObj,
        ui: {
          ...pdtObj.ui,
          isQuoteBoxExpanded: true,
        },
      }));
      return {
        ...state,
        quoteOptions: newQuoteOptions,
      };
    case actionTypes.SET_DASHBOARD_PATH:
      return {
        ...state,
        pathOptions: action.payload,
      };
    case actionTypes.RESET_IS_QUOTE_BOX_EXPANDED:
      const newQuoteOptn = state.quoteOptions.map((pdtObj) => ({
        ...pdtObj,
        ui: {
          ...pdtObj.ui,
          isQuoteBoxExpanded: false,
        },
      }));
      return {
        ...state,
        quoteOptions: newQuoteOptn,
      };
    case actionTypes.SELECT_QUOTE:
      return {
        ...state,
        selectedQuote: action.payload,
      };
    case actionTypes.SET_BROKERAGE_BRANCH_PRODUCER:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.RESET_STATE:
      return {
        ...initialState,
      };
    case actionTypes.LOAD_RISK_COVERAGES:
      return {
        ...state,
        riskCoverages: {
          ...state.riskCoverages,
          ...action.riskCoverages,
        },
        ui: {
          ...state.ui,
          errorLoadingRiskCoverages: false,
        },
      };
    case actionTypes.LOAD_RISK_COVERAGES_ERROR:
      return {
        ...state,
        ui: {
          ...state.ui,
          errorLoadingRiskCoverages: true,
        },
      };
    case actionTypes.UPDATE_RISK_COVERAGES:
      let riskCoveragesArr;
      if (action.payload.id) {
        riskCoveragesArr = state.riskCoverages[action.payload.product].map(
          (riskObj) =>
            riskObj.id === action.payload.id
              ? { ...riskObj, isIncluded: action.payload.value }
              : riskObj,
        );
      }

      return {
        ...state,
        riskCoverages: {
          ...state.riskCoverages,
          [action.payload.product]: riskCoveragesArr,
        },
      };
    case actionTypes.SET_POLICY_ID:
      return {
        ...state,
        policyId: action.payload,
      };
    case actionTypes.SET_ALL_MAIL_ID:
      return {
        ...state,
        mailIds: {
          ...state.mailIds,
          ...action.payload,
        },
      };
    case actionTypes.UPDATE_UI_CONTENT:
      return {
        ...state,
        ui: {
          ...state.ui,
          ...action.payload,
        },
      };
    case actionTypes.RESET_IS_NAVIGATED_FROM_RISK_ANALYSIS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isNavigatedFromRiskAnalysis: true,
        },
      };
    default:
      return state;
  }
}
