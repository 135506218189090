<div class="custom-modal" [formGroup]="form" *ngIf="show">
  <div class="modal-content">
    <div class="grid-container">
      <div class="grid-item col-1">
        <div class="quote-period">
          <div>
            <input
              type="radio"
              (change)="handleRadioCheck($event)"
              formControlName="quotedPeriod"
              name="quotedPeriod"
              id="'quotedToday'"
              value="quotedToday"
            />
            <label for="'quotedToday'">{{ "Today" | translate }}</label>
          </div>
          <div>
            <input
              type="radio"
              (change)="handleRadioCheck($event)"
              formControlName="quotedPeriod"
              name="quotedPeriod"
              id="'last7days'"
              value="last7days"
            />
            <label for="'last7days'">{{ "Last 7 days" | translate }}</label>
          </div>
          <div>
            <input
              type="radio"
              (change)="handleRadioCheck($event)"
              formControlName="quotedPeriod"
              name="quotedPeriod"
              id="'last30days'"
              value="last30days"
            />
            <label for="'last30days'">{{ "Last 30 days" | translate }}</label>
          </div>
          <div>
            <input
              type="radio"
              (change)="handleRadioCheck($event)"
              formControlName="quotedPeriod"
              name="quotedPeriod"
              id="'custom'"
              value="custom"
            />
            <label for="'custom'">{{ "Custom Range" | translate }}</label>
          </div>
        </div>
        {{ "" }}
        <boxxds-datepicker
          [placeholder]="shortDateFormat"
          [dateFormat]="shortDateFormat"
          [label]="'filter.label.quotePeriodFrom' | translate"
          [showErr]="hasError"
          [errMsg]="createFromError | translate"
          [containerStyle]="{ maxWidth: '250px' }"
          [bsValue]="form.value['quoteFrom']"
          (bsValueChange)="handleQuoteFrom($event)"
          [hidden]="isPeriodHidden"
        >
        </boxxds-datepicker>
        <boxxds-datepicker
          [placeholder]="shortDateFormat"
          [dateFormat]="shortDateFormat"
          [label]="'filter.label.quotePeriodTo' | translate"
          [showErr]="hasError"
          [errMsg]="createToError | translate"
          [containerStyle]="{ maxWidth: '250px', marginTop: '12px' }"
          [bsValue]="form.value['quoteTo']"
          (bsValueChange)="handleQuoteTo($event)"
          [hidden]="isPeriodHidden"
        >
        </boxxds-datepicker>
      </div>
      <div class="grid-item col-2">
        <boxxds-multiselect-autocomplete
          [label]="'filter.label.brokerageBranchProducer' | translate"
          (handleSelect)="handleBrokerageBranchProd($event)"
          [containerStyle]="{ marginBottom: '20px' }"
          [options]="brokerageBranchProdOptions"
          [selectedOptions]="form.value['brokerageBranchProd']"
          (searchEvent)="searchBrokerageDirectories($event)"
          (loadMore)="loadMoreBrokerage()"
          [validationMsg]="'common.filterLimitInfo' | translate"
        >
        </boxxds-multiselect-autocomplete>
        <boxxds-multiselect-autocomplete
          [label]="'filter.label.underwriter' | translate"
          (handleSelect)="handleUnderwriter($event)"
          [containerStyle]="{ marginBottom: '20px' }"
          [selectedOptions]="form.value['underwriter']"
          [options]="underwritersOptions"
          (searchEvent)="searchUnderWriter($event)"
          (loadMore)="loadMoreUnderWriter()"
          [validationMsg]="'common.filterLimitInfo' | translate"
        >
        </boxxds-multiselect-autocomplete>
        <boxxds-dropdown
          [form]="form"
          [control]="'status'"
          [label]="'filter.label.status' | translate"
          [placeholder]="'common.select' | translate"
          [options]="statusOptions"
          (currentValueChange)="handleStatusSelect($event)"
          [btnStyle]="{ height: '36px' }"
          [dropdownContainerStyle]="{ width: '300px', marginBottom: '16px' }"
          [hideSelectedValue]="true"
        >
        </boxxds-dropdown>
        <div class="status-container">
          <div
            class="status"
            *ngFor="let status of getStatuses(form.value['status'])"
          >
            <boxxds-status-badge
              [showClose]="true"
              [status]="status"
              [statusType]="getStatusType(status)"
              (closeHandler)="handleStatusRemove(status)"
              [badgeStyle]="{ textTransform: 'capitalize' }"
            ></boxxds-status-badge>
            <div></div>
          </div>
        </div>
      </div>
      <div class="grid-item col-3">
        <boxxds-dropdown
          [form]="form"
          [control]="'products'"
          [label]="'common.products' | translate"
          [placeholder]="'common.select' | translate"
          [options]="pdtOptions"
          (currentValueChange)="handleProductsSelect($event)"
          [btnStyle]="{ height: '36px' }"
          [dropdownContainerStyle]="{ width: '300px', marginBottom: '16px' }"
          [hideSelectedValue]="true"
        >
        </boxxds-dropdown>
        <div class="pdt-container">
          <div
            class="pdt"
            *ngFor="let pdt of getProducts(form.value['products'])"
          >
            <div class="pdt-name">{{ pdt }}</div>
            <button
              type="button"
              aria-label="close"
              class="close-btn"
              (click)="handleProductRemove(pdt)"
            >
              <img src="assets/img/dashboard/quote/remove.png" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-container">
      <button type="button" class="clear-btn" (click)="clearfilters()">
        Clear all filters
      </button>
      <boxxds-button
        [buttonText]="'Done'"
        btnType="secondary"
        [btnStyle]="{ width: '88px' }"
        type="submit"
        (handleClick)="handleFilter($event)"
      >
      </boxxds-button>
    </div>
  </div>
</div>
