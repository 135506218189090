import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalAlertService {
  private alertSubject = new BehaviorSubject<any>(null);
  alertObservable$ = this.alertSubject.asObservable();

  // Function to add an alert
  addAlert(alert: any) {
    const currentAlerts = this.alertSubject.value || [];
    let ind = currentAlerts.findIndex((x) => x.bodyText === alert.bodyText);
    if (ind === -1) {
      // Add the new alert to the array of alerts
      this.alertSubject.next([...currentAlerts, alert]);
    }
  }

  // Function to clear a specific alert by index
  clearAlert(index: number) {
    const currentAlerts = this.alertSubject.value || [];
    currentAlerts.splice(index, 1);

    // Emit the updated array of alerts
    this.alertSubject.next(currentAlerts);
  }

  // Function to clear all alerts
  clearAllAlerts() {
    this.alertSubject.next(null);
  }
}
