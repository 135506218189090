<button
  *ngIf="status === 'default'"
  class="btn-default"
  tabindex="0"
  [ngStyle]="btnStyle"
  (click)="clickHandler($event)"
>
  <img class="left-img" src="assets/img/dashboard/quote/mail.png" />
  <span>{{ buttonText }}</span>
</button>

<button
  *ngIf="status !== 'default' && status !== 'done'"
  class="btn-processing"
  tabindex="0"
  [ngStyle]="btnStyle"
  (click)="clickHandler($event)"
>
  <boxxds-spinner></boxxds-spinner>
  <span>Binding policy</span>
</button>

<button
  *ngIf="status === 'done'"
  class="btn-done"
  tabindex="0"
  [ngStyle]="btnStyle"
  disabled="status === 'done"
>
  <img src="assets/img/dashboard/quote/tick.png" />
</button>
