import * as actionTypes from './lifecycle.action';

export interface LifecycleState {
  policyRiskTrxId: number | null;
  policyRiskTrxStatus: string;
  insuredId: number | null;
  riskId: number | null;
  policyPeriodId: number | null;
  policyId: string | null;
  brokerageId: number | null;
  producerId: number | null;
  packagePolicyNumber: string | null;
  riskRegionId: number | null;
  policyRiskId: number | null;
  regionId: Number | null;
  quoteStatus: string;
  transactionLevelQuoteStatus: string;
}

const initialState: LifecycleState = {
  policyRiskTrxId: null,
  policyRiskTrxStatus: '',
  insuredId: null,
  riskId: null,
  policyPeriodId: null,
  policyId: null,
  brokerageId: null,
  producerId: null,
  packagePolicyNumber: null,
  riskRegionId: null,
  policyRiskId: null,
  regionId: null,
  quoteStatus: '',
  transactionLevelQuoteStatus: '',
};

export function lifecycleReducer(
  state: LifecycleState = initialState,
  action: any,
): any {
  switch (action.type) {
    case actionTypes.SET_POLICY_RISK_TRX_ID:
      return {
        ...state,
        policyRiskTrxId: action.id,
      };
    case actionTypes.SET_POLICY_RISK_TRX_STATUS:
      return {
        ...state,
        policyRiskTrxStatus: action.status,
      };
    case actionTypes.SET_INSURED_ID:
      return {
        ...state,
        insuredId: action.id,
      };
    case actionTypes.SET_RISK_ID:
      return {
        ...state,
        riskId: action.id,
      };
    case actionTypes.SET_POLICY_PERIOD_ID:
      return {
        ...state,
        policyPeriodId: action.id,
      };
    case actionTypes.SET_POLICY_ID:
      return {
        ...state,
        policyId: action.id,
      };
    case actionTypes.SET_POLICY_SLIDEOUT_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_QUOTE_STATUS:
      return {
        ...state,
        quoteStatus: action.status,
      };
    case actionTypes.SET_TRANSACTION_LVL_QUOTE_STATUS:
      return {
        ...state,
        transactionLevelQuoteStatus: action.status,
      };
    case actionTypes.UPDATE_POLICY_SLIDEOUT_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.UPDATE_POLICY_RISK_ID_STATUS:
      return {
        ...state,
        policyRiskId: action.payload.policyRiskId,
        quoteStatus: action.payload.quoteStatus,
      };

    default:
      return state;
  }
}
