import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-logout-popup',
  templateUrl: './logout-popup.component.html',
  styleUrls: ['./logout-popup.component.less'],
})
export class LogoutPopupComponent {
  @Input() showModal: boolean = false;

  @Output() cancelClickHandler = new EventEmitter();
  @Output() logoutClickHandler = new EventEmitter();

  constructor() {}

  handleCancel() {
    this.cancelClickHandler.emit();
  }

  handleLogout() {
    this.logoutClickHandler.emit();
  }
}
