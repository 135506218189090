<boxxds-alert-v2
  *ngFor="let alert of alerts"
  [type]="alert.type"
  [linkData]="alert.linkData"
  [headerText]="alert.headerText | translate"
  [bodyText]="alert.bodyText | translate"
  (handleCloseEvent)="clearAlert(alert)"
  [containerStyle]="{
    position: 'relative',
    top: 'unset',
    transition: 'all 0.3s ease-out',
    margin: '8px 0px',
    paddingRight: '18px'
  }"
></boxxds-alert-v2>
