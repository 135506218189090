import { Component, Input, Output, EventEmitter } from '@angular/core';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';

@Component({
  selector: 'app-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.less'],
})
export class DocumentPreviewComponent {
  @Input() docUrl = '';

  @Output() handleClose = new EventEmitter<any>();

  constructor() {
    pdfDefaultOptions.assetsFolder = 'bleeding-edge';
  }

  handleCloseClk() {
    this.handleClose.emit();
  }
}
