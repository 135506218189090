<div class="holder">
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!showBodySpinner">
    <app-quote-grid-filter
      [show]="showFilter"
      (handleFiltering)="handleFiltering($event)"
      [inputForm]="filterForm"
      [pdtOptions]="pdtOptions"
    ></app-quote-grid-filter>
    <div class="header">
      <boxxds-heading [sz]="'5'" [text]="'common.quotes' | translate">
      </boxxds-heading>
      <ng-container
        *ngIf="
          this.permissionList[this.currentScreen] &&
          this.permissionList['Quote']
        "
      >
        <boxxds-button
          sz="lg"
          [buttonText]="startQuoteBtnTxt"
          [type]="'submitted'"
          [btnStyle]="{ width: '210px' }"
          (handleClick)="handleStartNewQuote()"
        >
        </boxxds-button>
      </ng-container>
    </div>
    <boxxds-alert
      (handleCloseEvent)="handleNotificationAlertClose()"
      *ngIf="notificationAlert?.show"
      [type]="notificationAlert?.type"
      [headerText]="notificationAlert?.headerText | translate"
      [bodyText]="notificationAlert?.bodyText | translate"
    >
    </boxxds-alert>
    <div class="insured-type">
      <boxxds-body
        sz="lg"
        [text]="'common.insuredType' | translate"
        [customStyle]="{
          color: '#616162',
          textAlign: 'left',
          marginBottom: '8px'
        }"
      ></boxxds-body>
      <button class="toggle" tab-index="0">
        <span
          class="toggle-menu"
          (click)="onInsuredTypeToggle(true)"
          [ngClass]="{ active: isInsuredTypeCompany }"
          >{{ "common.company" | translate }}</span
        >
        <span
          class="toggle-menu"
          (click)="onInsuredTypeToggle(false)"
          [ngClass]="{ active: !isInsuredTypeCompany }"
          >{{ "common.individual" | translate }}</span
        >
      </button>
    </div>
    <div class="content">
      <div class="d-flex justify-content-between">
        <div class="sub-nav">
          <ul>
            <li *ngFor="let nav of subNavData; let i = index">
              <button [ngClass]="{ active: nav.active }" (click)="handleNav(i)">
                {{ nav.name }}
              </button>
            </li>
          </ul>
        </div>
        <div class="d-flex align-items-center">
          <div class="filter-optns-container">
            <div class="filter-optn" *ngFor="let filter of getFilteredValues()">
              <div class="option-name">{{ filter }}</div>
              <button
                type="button"
                aria-label="close"
                class="close-btn"
                (click)="handleFilterOptnRemove(filter)"
              >
                <img src="assets/img/dashboard/quote/remove.png" />
              </button>
            </div>
          </div>
          <boxxds-button
            [isBtnActive]="showFilter"
            [leftImage]="showFilter ? 'filter-filled' : 'filter'"
            btnType="unfilled"
            [buttonText]="'common.filters' | translate | uppercase"
            [type]="'submitted'"
            [btnStyle]="{ width: '65px', marginRight: '24px' }"
            (handleClick)="handleFilter()"
          >
          </boxxds-button>
          <boxxds-button
            leftImage="download"
            btnType="unfilled"
            [buttonText]="'common.export' | translate | uppercase"
            [type]="'submitted'"
            [btnStyle]="{ width: '65px' }"
            (handleClick)="handleExport()"
          >
          </boxxds-button>
        </div>
      </div>
    </div>
    <boxxds-table
      [data]="tableData"
      [showSpinner]="showTblSpinner"
      [totalCount]="totalDataCount"
      (pageChanged)="pageChangeHandler($event)"
      class="table"
      [ngClass]="{
        'pointer-default': !this.permissionList[this.currentScreen]
      }"
      (sortUpClicked)="handleTableColSortUp($event)"
      (sortDownClicked)="handleTableColSortDown($event)"
      id="policy-table"
      [addSortTo]="addSortTo"
      [showStar]="true"
      [tableStyle]="{ marginBottom: '30px' }"
      [showBadgeFieldName]="'common.statusText' | translate"
      (starHandler)="
        this.permissionList[this.currentScreen] && starClickHandler($event)
      "
      [mapStatusBadge]="statusMappingToType"
      (rowClicked)="quoteClickHandler($event)"
      [isRowClickable]="true"
      [activePageNumber]="pageNumber"
      [showStatusAsBadge]="true"
    >
    </boxxds-table>
  </ng-container>
</div>
