<div class="custom-modal" *ngIf="showModal">
  <div style="position: relative">
    <app-modal-alert></app-modal-alert>
  </div>
  <div class="modal-content">
    <ng-container *ngIf="showSpinner">
      <div class="body-spinner">
        <boxxds-spinner
          [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
        ></boxxds-spinner>
      </div>
    </ng-container>

    <ng-container>
      <div class="close-btn">
        <button (click)="closeHandler()">
          <img src="assets/img/dashboard/card/close.png" />Close
        </button>
      </div>
      <div *ngIf="!showSpinner" class="content">
        <div class="d-flex flex-column align-items-center">
          <img
            src="assets/img/alert-triangle.jpg"
            width="66px"
            height="66px"
            alt="alert"
          />
          <boxxds-body
            sz="md"
            [text]="'Changing the producer: Action can be undone' | translate"
            [customStyle]="{ color: '#FE802C', marginTop: '12px' }"
          ></boxxds-body>
        </div>
        <div class="quote-details d-flex flex-column mt-1">
          <!-- quote number -->
          <div class="d-flex mt-5">
            <boxxds-body
              sz="md"
              [text]="'Quote ID:' | translate"
              [customStyle]="{
                color: '#616162',
                marginRight: '6px',
                lineHeight: '14px',
                fontWeight: '400'
              }"
            ></boxxds-body>
            <boxxds-body
              sz="md"
              [text]="details.id"
              [customStyle]="{
                color: '#616162',
                lineHeight: '14px',
                fontWeight: '400'
              }"
            ></boxxds-body>
          </div>
          <!-- quote number end -->

          <!-- effective date -->
          <div class="d-flex mt-3">
            <boxxds-body
              sz="md"
              [text]="'Effective date: ' | translate"
              [customStyle]="{
                color: '#616162',
                marginRight: '6px',
                lineHeight: 'normal',
                fontWeight: '400',
                fontSize: '12px'
              }"
            ></boxxds-body>
            <boxxds-body
              sz="md"
              [text]="effectiveDate"
              [customStyle]="{
                color: '#616162',
                lineHeight: '14px',
                fontSize: '12px'
              }"
            ></boxxds-body>
          </div>
          <!-- effective date end -->

          <!-- insured -->
          <div class="d-flex mt-2">
            <boxxds-body
              sz="md"
              [text]="'Insured: ' | translate"
              [customStyle]="{
                color: '#616162',
                marginRight: '6px',
                lineHeight: 'normal',
                fontWeight: '400',
                fontSize: '12px'
              }"
            ></boxxds-body>
            <boxxds-body
              sz="md"
              [text]="details.insuredName"
              [customStyle]="{
                color: '#616162',
                lineHeight: '14px',
                fontSize: '12px'
              }"
            ></boxxds-body>
          </div>
          <!-- insured end -->

          <!-- brokerage, branch -->
          <div class="d-flex mt-2">
            <boxxds-body
              sz="md"
              [text]="
                details.brokerage +
                ', ' +
                details.branch +
                ', ' +
                details.producer
              "
              [customStyle]="{
                color: '#616162',
                marginRight: '6px',
                lineHeight: 'normal',
                fontWeight: '400',
                fontSize: '12px'
              }"
            ></boxxds-body>
          </div>
          <!-- brokerage, branch end -->
        </div>
        <div class="flex-column mb-5">
          <div class="d-flex">
            <div>
              <boxxds-body
                sz="s"
                [text]="'Producer name' | translate"
                [customStyle]="{
                  color: '#333334',
                  lineHeight: '20px',
                  fontWeight: '400',
                  marginBottom: '20px',
                  fontSize: '14px'
                }"
              ></boxxds-body>
              <!-- producer search dropdown -->
              <div class="producer-search-container">
                <boxxds-search-autocomplete-v2
                  [maxLength]="'200'"
                  [form]="brokerageForm"
                  [isActive]="true"
                  [formSubmitted]="formSubmitted"
                  [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
                  [items]="brokerOptions"
                  [placeholder]="'submission.select' | translate"
                  [required]="true"
                  [control]="'brokerageinfo'"
                  [autocapitalize]="'off'"
                  [spellcheck]="'off'"
                  [containerStyle]="{}"
                  (searchQuery)="searchBroker($event)"
                  (itemSelected)="selectBroker($event)"
                  (textInputChangeEventEmmiter)="searchBroker($event)"
                  (currentValueChange)="selectBroker($event)"
                  (outsideClick)="dropdownFocusOut($event)"
                  [currentValue]="currentBrokerValue"
                  [containerStyle]="{ minWidth: '400px' }"
                  [dropdownContainerStyle]="{ width: '400px' }"
                  [showNoResultAction]="true"
                  [noResultContent]="noResultContent"
                  (handleNoResultButtonClick)="noResultButtonclick($event)"
                  [permissionStatus]="this.permissionList[this.currentScreen]"
                  class="search-dropdown"
                ></boxxds-search-autocomplete-v2>

                <boxxds-notification-v2
                  *ngIf="noCommission"
                  [alertType]="alertInfo.alertType"
                  [alertWidth]="'lg'"
                  [alertHeader]="alertInfo.alertHeader"
                  [alertDescription]="alertInfo.alertText"
                  [contentContainerStyle]="{ maxWidth: '68%' }"
                  [linkContainerStyle]="{ maxWidth: '30%' }"
                  [linkText]="alertInfo.alertLinkText"
                  (handleLinkClick)="handleLinkAction()"
                  class="notification-section"
                  [alertContainerStyle]="{
                    position: 'relative',
                    marginTop: '10px'
                  }"
                >
                </boxxds-notification-v2>
              </div>
              <!-- producer search dropdown end -->
            </div>
          </div>
        </div>
        <boxxds-button
          [isDisabled]="noCommission || currentBrokerValue === ''"
          [buttonText]="'Confirm' | translate"
          btnType="primary"
          [btnStyle]="{ width: '104px', marginLeft: 'auto', marginTop: '40px' }"
          (handleClick)="actionConfirmHandler($event)"
        >
        </boxxds-button>
      </div>
    </ng-container>
  </div>
</div>
