<div
  class="product-card-container-outer"
  *ngIf="detail"
  [ngClass]="{
    selected: isSelected,
    added: false,
    disabled: isDisabled
  }"
>
  <div
    class="product-card-container"
    [class]="'product-card-container ' + detail.riskId"
  >
    <header class="product-card-header">
      <h4 class="product-card-header-head">{{ detail.key }}</h4>

      <boxxds-radio-v2
        [items]="radioBusiness"
        [control]="'insured_type'"
        [form]="insuredTypeForm"
        [formSubmitted]="false"
        [radioStyle]="{ marginTop: '-6px' }"
        [containerStyle]="{ width: '16px' }"
        (click)="!isDisabled && handleInsuredTypeChange()"
        [class.noPointerEvents]="isDisabled"
      >
      </boxxds-radio-v2>
    </header>
    <main class="product-card-content-outer">
      <p class="main-text">{{ getProductDescription(detail.description) }}</p>
      <div class="sub-text">
        <span
          class="coverages productPopover"
          (click)="openPopup($event, popOverContent.both)"
          >Coverages and Services
          <img class="help-icon" src="assets/img/help-circle.svg"
        /></span>
      </div>
    </main>
  </div>

  <div id="popup-container"></div>

  <div class="product-card-baseline"></div>
</div>
