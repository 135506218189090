<ng-container
  *ngIf="
    (permissionKeyList.includes('Quote') && !isPolicy) ||
    (permissionKeyList.includes('Policy') && isPolicy)
  "
>
  <div
    class="card"
    tabindex="0"
    (click)="this.permissionList[this.currentScreen] && handleClick()"
    [class.noPointerEvents]="!this.permissionList[this.currentScreen]"
  >
    <div class="header">
      <boxxds-body
        text="Business name (premium)"
        sz="xxs"
        [customStyle]="{
          color: '#8F8F91',
          marginBottom: '2px',
          textTransform: 'uppercase',
          lineHeight: '14px'
        }"
      >
      </boxxds-body>
      <boxxds-body
        [text]="data?.businessName"
        class="company-name"
        sz="md"
        [customStyle]="{
          color: '#333334',
          height: '22px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis'
        }"
      >
      </boxxds-body>
      <boxxds-body
        [text]="
          (data?.premium ? data?.premium : '0')
            | currency: currency : 'symbol-narrow' : '1.0-0'
        "
        sz="md"
        [customStyle]="{ color: '#333334', height: '22px' }"
      >
      </boxxds-body>
    </div>
    <div class="content">
      <div class="data">
        <boxxds-body
          [text]="'ID NUMBER' | translate"
          sz="xxs"
          [customStyle]="{
            color: '#BDBDBF',
            marginBottom: '2px',
            textTransform: 'uppercase'
          }"
        ></boxxds-body>
        <boxxds-body
          class="id-number"
          [text]="data?.id"
          sz="sm"
          [customStyle]="{ color: '#56565C', marginBottom: '4px' }"
        >
        </boxxds-body>
      </div>
      <boxxds-status-badge
        [status]="data.status"
        [statusType]="data.statusType"
        [badgeStyle]="{ textTransform: 'capitalize' }"
      ></boxxds-status-badge>
      <div class="data">
        <boxxds-body
          [text]="'POLICY PERIOD' | translate"
          sz="xxs"
          [customStyle]="{
            color: '#BDBDBF',
            textTransform: 'uppercase',
            marginTop: '12px'
          }"
        >
        </boxxds-body>
        <boxxds-body
          [text]="data?.policyPeriod"
          sz="sm"
          [customStyle]="{ textAlign: 'left', marginBottom: '2px' }"
        >
        </boxxds-body>
      </div>
      <div class="data">
        <boxxds-body
          [text]="'BROKERAGE' | translate"
          sz="xxs"
          [customStyle]="{ color: '#BDBDBF', textTransform: 'uppercase' }"
        >
        </boxxds-body>
        <boxxds-body
          [text]="data?.brokerage"
          sz="sm"
          [customStyle]="{
            textAlign: 'left',
            marginBottom: '2px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }"
        >
        </boxxds-body>
      </div>
      <div class="data">
        <boxxds-body
          [text]="'Product (Limit)'"
          sz="xxs"
          [customStyle]="{ color: '#BDBDBF', textTransform: 'uppercase' }"
        >
        </boxxds-body>
        <boxxds-body
          [text]="getProductLimit(data.products)"
          sz="sm"
          [customStyle]="{ textAlign: 'left' }"
        >
        </boxxds-body>
      </div>

      <div class="profile">
        <div class="photo">
          <ngx-avatars
            [size]="24"
            [textSizeRatio]="3"
            [name]="data?.underwriter"
          ></ngx-avatars>
        </div>
        <div class="text">
          <p class="designation">{{ "UNDERWRITER" | translate }}</p>
          <boxxds-body
            [text]="data?.underwriter"
            sz="sm"
            [customStyle]="{ textAlign: 'left' }"
          ></boxxds-body>
        </div>
      </div>
    </div>
  </div>
</ng-container>
