<div class="doc-container">
  <ng-container *ngIf="!showBodySpinner">
    <app-document-preview
      *ngIf="showDocPreview"
      [docUrl]="currentPreviewDocUrl"
      (handleClose)="closeDocPreview()"
    >
    </app-document-preview>
    <app-image-doc-preview
      *ngIf="showImagePreview"
      [fileType]="currentFileType"
      [url]="currentPreviewDocUrl"
      (handleClose)="closeDocPreview()"
    >
    </app-image-doc-preview>
    <app-regenerate-policy-document-popup
      [showModal]="showDocTempSelectionPopup"
      [details]="docPopupDetails"
      (handleSuccess)="handleSuccessDocTemplate($event)"
      (handleActionConfirm)="
        this.permissionList[this.currentScreen] && handleDocLevelAction($event)
      "
      (handleClose)="handleCloseDocumentPopup()"
    ></app-regenerate-policy-document-popup>
  </ng-container>

  <ng-container *ngIf="showModalSpinner">
    <div class="loader-wrapper">
      <div class="modal-body-spinner">
        <boxxds-spinner
          [containerStyle]="{
            width: '112px',
            height: '112px',
            '--b': '15px'
          }"
        ></boxxds-spinner>
      </div>
    </div>
  </ng-container>
  <div *ngIf="!showModalSpinner">
    <app-delete-confirmation-popup
      *ngIf="showDeleteFileConfirm"
      [details]="currentFile"
      [isConfirmDisabled]="isButtonDisabled"
      (handleClose)="closeDocPreview()"
      (handleConfirm)="!isButtonDisabled && handleDeleteConfirm()"
    >
    </app-delete-confirmation-popup>
    <boxxds-drag-and-drop
      sz="sm"
      [placeholderText]="'policy.policySlideOut.dragAndDropTxt' | translate"
      [customPlaceholderStyle]="{ color: '#616162', marginTop: '8px' }"
      (fileDrop)="onFileDropped($event)"
    >
    </boxxds-drag-and-drop>
    <div class="pol-document-tblheader">
      <boxxds-body
        [text]="'policy.policySlideOut.currentFiles' | translate"
        sz="xxs"
        [customStyle]="{
          color: '#8F8F91',
          textTransform: 'uppercase',
          direction: 'ltr',
          backgroundColor: '#f8f8fa',
          marginTop: '40px',
          marginBottom: '20px'
        }"
        [ngStyle]="{ direction: 'ltr' }"
      >
      </boxxds-body>
      <boxxds-button-v2
        *ngIf="showDocRegenerateButton(policyStatus) && isProductActive"
        [size]="'lg'"
        [buttonText]="
          'policy.policySlideOut.regeneratePolicyDocuments' | translate
        "
        class="regenerate-doc"
        [btnStyle]="{
          border: '1px solid var(--Primary-300, #FFBE3D)',
          background: '#fff',
          color: 'var(--Text-700, #2D2D39)',
          fontFamily: 'Roboto',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '16px'
        }"
        (click)="loadPolicyDocumentTemplates()"
      >
      </boxxds-button-v2>
    </div>
    <div class="files-list custom-scrollbar" *ngIf="files.length > 0">
      <table>
        <thead>
          <tr>
            <th class="fixed-width-column"></th>
            <th class="fixed-width-column"></th>
            <th
              *ngFor="let key of tableHeaders; let indexOfHeaders = index"
              scope="col"
              [ngClass]="
                ['common.fileName', 'common.fileDescription'].includes(key)
                  ? 'large-column'
                  : 'column-min-style'
              "
            >
              <boxxds-body
                text="{{ key | translate }}"
                sz="sm"
                [customStyle]="{ color: '#616162' }"
              >
              </boxxds-body>
            </th>
            <th
              class="fixed-width-column"
              *ngIf="permissionList[currentScreen]"
            ></th>
          </tr>
        </thead>
        <tbody class="custom-scrollbar">
          <tr *ngFor="let file of files; let i = index">
            <td class="fixed-width-column">
              <button
                class="btn-preview"
                (click)="handleDocPreview(file)"
                [disabled]="isDisablePreview(file.name)"
              >
                <img
                  [src]="
                    isDisablePreview(file.name)
                      ? 'assets/img/preview-disable.svg'
                      : 'assets/img/preview.svg'
                  "
                />
              </button>
            </td>
            <td class="fixed-width-column">
              <button class="btn-download" (click)="handleDocDownload(file)">
                <img src="assets/img/download.svg" />
              </button>
            </td>
            <td class="large-column">
              <div class="file-name">
                <boxxds-body
                  [text]="file.name"
                  sz="sm"
                  [customStyle]="{
                    color: '#8F8F91',
                    textDecorationLine: 'none',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '400px',
                    minWidth: '320px'
                  }"
                  title="{{ file.name }}"
                >
                </boxxds-body>
              </div>
            </td>
            <td>
              <boxxds-body
                [text]="file.updatedBy"
                sz="sm"
                [customStyle]="{
                  color: '#8F8F91'
                }"
              >
              </boxxds-body>
            </td>
            <td>
              <boxxds-body
                [text]="file.dateUpdated"
                sz="sm"
                [customStyle]="{
                  color: '#8F8F91'
                }"
              >
              </boxxds-body>
            </td>
            <td>
              <boxxds-body
                [text]="file.createdBy"
                sz="sm"
                [customStyle]="{
                  color: '#8F8F91'
                }"
              >
              </boxxds-body>
            </td>
            <td>
              <boxxds-body
                [text]="file.dateCreated"
                sz="sm"
                [customStyle]="{
                  color: '#8F8F91'
                }"
              >
              </boxxds-body>
            </td>
            <td class="large-column">
              <div class="">
                <boxxds-body
                  [text]="file.description"
                  sz="sm"
                  [customStyle]="{
                    color: '#8F8F91',
                    textDecorationLine: 'none',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '400px',
                    minWidth: '320px'
                  }"
                  title="{{ file.description }}"
                >
                </boxxds-body>
              </div>
            </td>
            <td
              class="fixed-width-column"
              *ngIf="permissionList[currentScreen]"
            >
              <button
                class="btn-delete"
                (click)="handleDelete(i, file)"
                [disabled]="!file.deletable"
              >
                <img
                  class="delete-icon"
                  [src]="
                    !file.deletable
                      ? 'assets/img/trash-disable.svg'
                      : 'assets/img/trash.svg'
                  "
                  alt="delete"
                />
                <boxxds-body [text]="" sz="xxs" [customStyle]="{}">
                </boxxds-body>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="no-records-holder" *ngIf="files.length === 0">
      <div class="no-records-ellipse">
        <img src="assets/img/no-records.png" alt="" />
        <boxxds-body
          [text]="'common.noRecords' | translate"
          sz="md"
          [customStyle]="{ color: '#616162' }"
        >
        </boxxds-body>
      </div>
    </div>
    <div class="document-details">
      <div
        class="d-flex"
        *ngFor="
          let data of files
            | paginate
              : {
                  id: 'activitylog-pagination',
                  itemsPerPage: itemsPerPage,
                  currentPage: currentPage,
                  totalItems: filesData?.pagination?.totalRecords
                }
        "
      ></div>

      <div class="document-footer">
        <div class="total-count">
          {{ "policy.policySlideOut.totalRecordsFound" | translate }}:
          <span>{{ filesData.pagination.totalRecords }}</span>
        </div>
        <div
          class="pagination-container"
          *ngIf="filesData.pagination.totalPages > 1"
        >
          <pagination-template
            id="activitylog-pagination"
            #p="paginationApi"
            (pageChange)="populateDocuments($event)"
          >
            <ul class="tbl-pagination">
              <li class="tbl-pagination-prev tbl-page-item">
                <a
                  (keyup.enter)="p.previous()"
                  (click)="p.previous()"
                  *ngIf="!p.isFirstPage()"
                  [class.disabled]="p.isFirstPage()"
                  class="tbl-page-link"
                  tabindex="0"
                  aria-label="Previous"
                  >{{ "common.previous" | translate }}</a
                >
              </li>
              <li
                *ngFor="let page of p.pages"
                [class.active]="p.getCurrent() === page.value"
                class="tbl-page-item"
              >
                <a
                  (keyup.enter)="p.setCurrent(page.value)"
                  (click)="p.setCurrent(page.value)"
                  class="tbl-page-link"
                  tabindex="0"
                >
                  {{ page.label }}
                </a>
              </li>
              <li
                class="pagination-next tbl-page-item"
                [class.disabled]="p.isLastPage()"
              >
                <a
                  (keyup.enter)="p.next()"
                  *ngIf="!p.isLastPage()"
                  (click)="p.next()"
                  class="tbl-page-link"
                  tabindex="0"
                  >{{ "common.next" | translate }}</a
                >
              </li>
            </ul>
          </pagination-template>
        </div>
      </div>
    </div>
  </div>
</div>

<app-document-action-popup
  [showModal]="existingFiles.length > 0"
  [existingFileName]="existingFileName"
  [suggestedFileName]="suggestedFileName"
  (handleClose)="handleCloseExistingFilesUpload()"
  (handleActionConfirm)="
    !isUploadingProgress && handleDocumentActionConfirm($event)
  "
  [isUploadingProgress]="isUploadingProgress"
  [isGettingSuggestionProgress]="isGettingSuggestionProgress"
>
</app-document-action-popup>
