<div class="custom-modal" *ngIf="show" [formGroup]="form">
  <div class="modal-content">
    <div class="grid-container">
      <div class="grid-item col-1">
        <div class="quote-period">
          <div>
            <input
              type="radio"
              (change)="handleRadioCheck($event)"
              formControlName="quotedPeriod"
              name="quotedPeriod"
              id="'quotedToday'"
              value="quotedToday"
            />
            <label for="'quotedToday'">{{ "Today" | translate }}</label>
          </div>
          <div>
            <input
              type="radio"
              (change)="handleRadioCheck($event)"
              formControlName="quotedPeriod"
              name="quotedPeriod"
              id="'last7days'"
              value="last7days"
            />
            <label for="'last7days'">{{ "Last 7 days" | translate }}</label>
          </div>
          <div>
            <input
              type="radio"
              (change)="handleRadioCheck($event)"
              formControlName="quotedPeriod"
              name="quotedPeriod"
              id="'last30days'"
              value="last30days"
            />
            <label for="'last30days'">{{ "Last 30 days" | translate }}</label>
          </div>
          <div>
            <input
              type="radio"
              (change)="handleRadioCheck($event)"
              formControlName="quotedPeriod"
              name="quotedPeriod"
              id="'custom'"
              value="custom"
            />
            <label for="'custom'">{{ "Custom Range" | translate }}</label>
          </div>
        </div>
        <boxxds-datepicker
          [placeholder]="shortDateFormat"
          [dateFormat]="shortDateFormat"
          [label]="'common.from' | translate"
          [showErr]="hasError"
          [errMsg]="
            (showFromDateValidationMessage &&
              ('brokerage.producer.error.fromDateValidationMessage'
                | translate)) ||
            (showFromOrToDateEmptyErrorMsg &&
              ('brokerage.producer.error.fromOrToDateEmpty' | translate)) ||
            ''
          "
          [containerStyle]="{ maxWidth: '250px' }"
          [bsValue]="form.value['periodFrom']"
          (bsValueChange)="handlePolicyPeriodFrom($event)"
          [hidden]="isPeriodHidden"
        >
        </boxxds-datepicker>
        <boxxds-datepicker
          [placeholder]="shortDateFormat"
          [dateFormat]="shortDateFormat"
          [label]="'common.to' | translate"
          [showErr]="hasError"
          [errMsg]="
            (showToDateValidationMessage &&
              ('brokerage.producer.error.fromDateValidationMessage'
                | translate)) ||
            (showFromOrToDateEmptyErrorMsg &&
              ('brokerage.producer.error.fromOrToDateEmpty' | translate)) ||
            ''
          "
          [containerStyle]="{ maxWidth: '250px', marginTop: '12px' }"
          [bsValue]="form.value['periodTo']"
          (bsValueChange)="handlePolicyPeriodTo($event)"
          [hidden]="isPeriodHidden"
        >
        </boxxds-datepicker>
      </div>
      <div class="grid-item col-2">
        <app-min-max-range
          [form]="form"
          [min]="0"
          [max]="10000000"
          [step]="100000"
          [label]="'filter.label.limit' | translate"
          [customStyle]="{ marginBottom: '20px' }"
          [formSubmitted]="formSubmitted"
          minControl="minLimit"
          maxControl="maxLimit"
        ></app-min-max-range>
        <app-min-max-range
          [form]="form"
          [min]="0"
          [max]="1000000"
          [step]="100000"
          [label]="'common.deductible' | translate"
          [customStyle]="{ marginBottom: '20px' }"
          [formSubmitted]="formSubmitted"
          minControl="minDeductible"
          maxControl="maxDeductible"
        ></app-min-max-range>
        <app-min-max-range
          [form]="form"
          [min]="0"
          [max]="1000000"
          [step]="100000"
          [label]="'common.premium' | translate"
          [customStyle]="{ marginBottom: '20px' }"
          [formSubmitted]="formSubmitted"
          minControl="minPremium"
          maxControl="maxPremium"
        ></app-min-max-range>
      </div>
      <div class="grid-item col-3">
        <boxxds-multiselect-autocomplete
          [label]="'brokerage.producer.label.insuredName' | translate"
          [containerStyle]="{ marginBottom: '20px' }"
          [options]="insuredNameOptions"
          [selectedOptions]="form.value['insuredName']"
          (handleSelect)="handleInsuredSelected($event)"
          (searchEvent)="searchInsuredNameOption($event)"
          (loadMore)="loadMoreInsuredName()"
          [validationMsg]="'common.filterLimitInfo' | translate"
        >
        </boxxds-multiselect-autocomplete>
        <boxxds-dropdown
          [form]="form"
          [control]="'products'"
          [label]="'common.products' | translate"
          [placeholder]="'common.select' | translate"
          [options]="productOptions"
          (currentValueChange)="handleProductsSelect($event)"
          [btnStyle]="{ height: '36px' }"
          [dropdownContainerStyle]="{ width: '300px', marginBottom: '16px' }"
          [hideSelectedValue]="true"
        >
        </boxxds-dropdown>
        <div class="pdt-container">
          <div class="pdt" *ngFor="let pdt of form.value['products']">
            <div class="pdt-name">{{ pdt }}</div>
            <button
              type="button"
              aria-label="close"
              class="close-btn"
              (click)="handleProductRemove(pdt)"
            >
              <img src="assets/img/dashboard/quote/remove.png" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-container">
      <button type="button" class="clear-btn" (click)="resetFilterForm()">
        Clear all filters
      </button>
      <boxxds-button
        [buttonText]="'Done'"
        btnType="secondary"
        [ngStyle]="{ maxWidth: '100px' }"
        type="submit"
        (handleClick)="handleFilter($event)"
      >
      </boxxds-button>
    </div>
  </div>
</div>
