<div
  class="popover-Container"
  [style.left.px]="left"
  [style.top.px]="top"
  [style.width.px]="popupWidth"
  [ngStyle]="popupContainerStyle"
>
  <ng-container *ngIf="!isCoverageContent()">
    <div class="popover-head">
      <boxxds-body
        [sz]="'md'"
        [text]="
          'workFlow3.components.popoverV2.services.heading.cyberProtectionServices'
            | translate
        "
        [customStyle]="{
          color: '#E09600',
          fontFamily: 'Gibson',
          fontWeight: '600'
        }"
      ></boxxds-body>
    </div>
    <ul class="popover-content" type="none">
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.services.subHeading.virtualCISO"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.services.content.virtualCISOContent"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.services.subHeading.boxxMobileApp"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.services.content.boxxMobileAppContent"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.services.subHeading.breachResponseServices"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.services.content.breachResponseServicesContent"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.services.subHeading.employeeCyberAwarenessTraining"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.services.content.employeeCyberAwarenessTrainingContent"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.services.subHeading.managedFirewallMonitoringServices"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.services.content.managedFirewallMonitoringServicesContent"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.services.subHeading.dataBackUpRecovery"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.services.content.dataBackUpRecoveryContent"
            | translate
        }}</span>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngIf="!isServiceContent()">
    <div class="popover-head">
      <boxxds-body
        [sz]="'md'"
        [text]="
          'workFlow3.components.popoverV2.coverage.heading.cyberInsuranceCoverageforBusinesses'
            | translate
        "
        [customStyle]="{
          color: '#E09600',
          fontFamily: 'Gibson',
          fontWeight: '600'
        }"
      ></boxxds-body>
    </div>
    <ul class="popover-content" type="none">
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.coverage.subHeading.breachResponseWithTheHackbusters"
              | translate
          }}
        </span>
        <span class="content"
          >{{
            "workFlow3.components.popoverV2.coverage.content.breachResponseWithTheHackbusters"
              | translate
          }}
        </span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.coverage.subHeading.hackerDamages"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.coverage.content.hackerDamages"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.coverage.subHeading.insiderThreats"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.coverage.content.insiderThreats"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.coverage.subHeading.cyberDeception"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.coverage.content.cyberDeception"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.coverage.subHeading.reputationDamage"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.coverage.content.reputationDamage"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.coverage.subHeading.lossOfBusiness"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.coverage.content.lossOfBusiness"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.coverage.subHeading.legalRegulatoryCosts"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.coverage.content.legalRegulatoryCosts"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.coverage.subHeading.thirdPartyLiability"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.coverage.content.thirdPartyLiability"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.coverage.subHeading.onlineMediaLiability"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.coverage.content.onlineMediaLiability"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.coverage.subHeading.brickingCosts"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.coverage.content.brickingCosts"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.coverage.subHeading.cyberServicesToDataBreachVictims"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.coverage.content.cyberServicesToDataBreachVictims"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.coverage.subHeading.notificationCosts"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.coverage.content.notificationCosts"
            | translate
        }}</span>
      </li>
    </ul>
  </ng-container>
  <!-- <ng-container *ngIf="!isServiceContent() && !isCoverageContent()">
    <div class="popover-head">
      <boxxds-body
        [sz]="'md'"
        [text]="
          'workFlow3.components.popoverV2.services.heading.cyberProtectionServices'
            | translate
        "
        [customStyle]="{
          color: '#E09600',
          fontFamily: 'Gibson',
          fontWeight: '600'
        }"
      ></boxxds-body>
    </div>
    <ul class="popover-content" type="none">
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.services.subHeading.virtualCISO"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.services.content.virtualCISOContent"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.services.subHeading.boxxMobileApp"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.services.content.boxxMobileAppContent"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.services.subHeading.breachResponseServices"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.services.content.breachResponseServicesContent"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.services.subHeading.employeeCyberAwarenessTraining"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.services.content.employeeCyberAwarenessTrainingContent"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.services.subHeading.managedFirewallMonitoringServices"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.services.content.managedFirewallMonitoringServicesContent"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.services.subHeading.dataBackUpRecovery"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.services.content.dataBackUpRecoveryContent"
            | translate
        }}</span>
      </li>
    </ul>
    <div class="popover-head">
      <boxxds-body
        [sz]="'md'"
        [text]="
          'workFlow3.components.popoverV2.coverage.heading.cyberInsuranceCoverageforBusinesses'
            | translate
        "
        [customStyle]="{
          color: '#E09600',
          fontFamily: 'Gibson',
          fontWeight: '600'
        }"
      ></boxxds-body>
    </div>
    <ul class="popover-content" type="none">
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.coverage.subHeading.breachResponseWithTheHackbusters"
              | translate
          }}
        </span>
        <span class="content"
          >{{
            "workFlow3.components.popoverV2.coverage.content.breachResponseWithTheHackbusters"
              | translate
          }}
        </span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.coverage.subHeading.hackerDamages"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.coverage.content.hackerDamages"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.coverage.subHeading.insiderThreats"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.coverage.content.insiderThreats"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.coverage.subHeading.cyberDeception"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.coverage.content.cyberDeception"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.coverage.subHeading.reputationDamage"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.coverage.content.reputationDamage"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.coverage.subHeading.lossOfBusiness"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.coverage.content.lossOfBusiness"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.coverage.subHeading.legalRegulatoryCosts"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.coverage.content.legalRegulatoryCosts"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.coverage.subHeading.thirdPartyLiability"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.coverage.content.thirdPartyLiability"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.coverage.subHeading.onlineMediaLiability"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.coverage.content.onlineMediaLiability"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.coverage.subHeading.brickingCosts"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.coverage.content.brickingCosts"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.coverage.subHeading.cyberServicesToDataBreachVictims"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.coverage.content.cyberServicesToDataBreachVictims"
            | translate
        }}</span>
      </li>
      <li class="item">
        <span class="head"
          >{{
            "workFlow3.components.popoverV2.coverage.subHeading.notificationCosts"
              | translate
          }}
        </span>
        <span class="content">{{
          "workFlow3.components.popoverV2.coverage.content.notificationCosts"
            | translate
        }}</span>
      </li>
    </ul>
  </ng-container> -->
</div>
