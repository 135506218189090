<!-- image drag n drop -->
<div class="image-upload-wrapper">
  <div class="image-preview">
    <img [src]="croppedImage ? croppedImage : defaultImage" alt="Avatar" />
  </div>
  <div
    class="drag-n-drop-wrapper"
    dragDrop
    (fileDropped)="onFileDropped($event)"
  >
    <div class="drag-n-drop">
      <div class="upload-icon-holder">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M6.66602 13.3333L9.99935 10M9.99935 10L13.3327 13.3333M9.99935 10V17.5M16.666 13.9524C17.6839 13.1117 18.3327 11.8399 18.3327 10.4167C18.3327 7.88536 16.2807 5.83333 13.7493 5.83333C13.5673 5.83333 13.3969 5.73833 13.3044 5.58145C12.2177 3.73736 10.2114 2.5 7.91602 2.5C4.46424 2.5 1.66602 5.29822 1.66602 8.75C1.66602 10.4718 2.36222 12.0309 3.48847 13.1613"
            stroke="#2D2D39"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <h1>Click to upload <span>or drag and drop</span></h1>
      <h6>JPG, JPEG, PNG or GIF (max. 800x400px)</h6>
      <!-- file input for click -->
      <ng-container *ngIf="isActive">
        <input
          #fileInput
          type="file"
          [disabled]="!isActive"
          (click)="fileInput.value = ''"
          (change)="onImageSelected($event)"
          accept="image/*"
        />
      </ng-container>
      <!-- file input for click end -->
    </div>
  </div>
</div>
<!-- image drag n drop end -->

<!-- image cropper -->
<dialog id="myDialog" #mi>
  <form method="dialog">
    <header>
      <h2>{{ title }}</h2>
    </header>
    <main>
      <image-cropper
        [resizeToWidth]="256"
        [cropperMinWidth]="128"
        [imageChangedEvent]="imageChangedEvent"
        [aspectRatio]="4 / 4"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (loadImageFailed)="loadImageFailed()"
        *ngIf="imageChangedEvent"
      ></image-cropper>
    </main>
    <footer>
      <span class="profile-note">{{
        "workFlow3.brokerageProfile.message.profilePictureNoteV2" | translate
      }}</span>
      <menu>
        <button
          id="dialog-footer--confirm-btn"
          class="dialog-submit-btn"
          (click)="selectedImage()"
        >
          {{ "common.confirm" | translate }}
        </button>
        <button
          id="close_dialog"
          class="dialog-close-btn"
          (click)="closeImageModal(); mi.close()"
        >
          {{ "common.close" | translate }}
        </button>
      </menu>
    </footer>
  </form>
</dialog>
<!-- image cropper end -->
