import { Action } from '@ngrx/store';

export const SET_POLICY_RISK_TRX_ID = 'SET_POLICY_RISK_TRX_ID';
export const SET_POLICY_RISK_TRX_STATUS = 'SET_POLICY_RISK_TRX_STATUS';
export const SET_INSURED_ID = 'SET_INSURED_ID';
export const SET_RISK_ID = 'SET_RISK_ID';
export const SET_POLICY_PERIOD_ID = 'SET_POLICY_PERIOD_ID';
export const SET_POLICY_ID = 'SET_POLICY_ID';
export const SET_POLICY_SLIDEOUT_DATA = 'SET_POLICY_SLIDEOUT_DATA';
export const SET_QUOTE_STATUS = 'SET_QUOTE_STATUS';
export const SET_TRANSACTION_LVL_QUOTE_STATUS =
  'SET_TRANSACTION_LVL_QUOTE_STATUS';
export const UPDATE_POLICY_SLIDEOUT_DATA = 'UPDATE_POLICY_SLIDEOUT_DATA';
export const UPDATE_POLICY_RISK_ID_STATUS = 'UPDATE_POLICY_RISK_ID_STATUS';

export class setPolicyRiskTrxIdAction implements Action {
  readonly type = SET_POLICY_RISK_TRX_ID;
  constructor(public id: number) {}
}
export class setPolicyPeriodIdAction implements Action {
  readonly type = SET_POLICY_PERIOD_ID;
  constructor(public id: number) {}
}
export class setPolicyIdAction implements Action {
  readonly type = SET_POLICY_ID;
  constructor(public id: string) {}
}

export class setQuoteStatus implements Action {
  readonly type = SET_QUOTE_STATUS;
  constructor(public status: string) {}
}
export class setTransactionLvlQuoteStatus implements Action {
  readonly type = SET_TRANSACTION_LVL_QUOTE_STATUS;
  constructor(public status: string) {}
}

export class setPolicyRiskTrxStatusAction implements Action {
  readonly type = SET_POLICY_RISK_TRX_STATUS;
  constructor(public status: string) {}
}

export class setInsuredIdAction implements Action {
  readonly type = SET_INSURED_ID;
  constructor(public id: string) {}
}
export class setRiskIdAction implements Action {
  readonly type = SET_RISK_ID;
  constructor(public id: number) {}
}
export class setPolicySlideoutData implements Action {
  readonly type = SET_POLICY_SLIDEOUT_DATA;
  constructor(
    public payload: {
      packagePolicyNumber: string;
      brokerageId: number;
      riskId: number;
      producerId: string;
      riskRegionId: number;
      policyRiskId: number;
      regionId: Number;
    },
  ) {}
}
export class updatePolicySlideoutData implements Action {
  readonly type: string = UPDATE_POLICY_SLIDEOUT_DATA;
  constructor(public payload: { [x: string]: any }) {}
}

export class updatePolicyRiskIdAndStatus implements Action {
  readonly type: string = UPDATE_POLICY_RISK_ID_STATUS;
  constructor(public payload: { [x: string]: any }) {}
}

export type Actions =
  | setPolicyRiskTrxIdAction
  | setPolicyRiskTrxStatusAction
  | setInsuredIdAction
  | setPolicyPeriodIdAction
  | setPolicyIdAction
  | setPolicySlideoutData
  | setRiskIdAction
  | setQuoteStatus;
