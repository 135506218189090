import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ripple-animation',
  templateUrl: './ripple-animation.component.html',
  styleUrls: ['./ripple-animation.component.less'],
})
export class RippleAnimationComponent {
  @Input() message: string = 'Your Referred Task list is empty.';
}
