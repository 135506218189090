import { PolicyAtomicTrxService } from './policy-atomic.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BoxxResponse } from '../entities/boxx-response';
import { Policy } from '../entities/policy';
import { BaseService } from './base-services';

@Injectable({
  providedIn: 'root',
})
export class PolicyRiskService extends BaseService<any> {
  constructor(http: HttpClient) {
    const baseurl = environment.msPolicyBaseUrl + environment.policyRiskPathUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  GetAllByPolicyPeriodId(
    policyPeriodId,
    page = 1,
    limit = 10,
    sort = '',
  ): Observable<BoxxResponse<any>> {
    return this.http
      .get<BoxxResponse<any>>(
        this.baseurl +
          '?policyPeriodId=' +
          policyPeriodId +
          '&page=' +
          page +
          '&limit=' +
          limit +
          '&active=1',
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetByPolicyId(
    policyId: any,
    page = 1,
    limit = 10,
    sort = 'policyPeriodId%3Adesc',
  ): Observable<BoxxResponse<any>> {
    var url = `${this.baseurl}?policyId=${policyId}&page=${page}&limit=${limit}&sort=${sort}&active=1`;
    return this.http
      .get<BoxxResponse<any>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetByPolicyIdAndPolicyPeriodId(
    policyId: any,
    policyPeriodId: any,
    page = 1,
    limit = 10,
  ): Observable<BoxxResponse<any>> {
    var url = `${this.baseurl}?policyId=${policyId}&policyPeriodId=${policyPeriodId}&page=${page}&limit=${limit}&sort=policyPeriodId%3Adesc&active=1`;
    return this.http
      .get<BoxxResponse<any>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
