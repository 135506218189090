<div class="modal-outer" *ngIf="showModal">
  <div class="modal-container">
    <div class="modal-header-container">
      <div class="box">
        <span style="--i: 1"></span>
        <span style="--i: 2"></span>
        <span style="--i: 3"></span>
        <span style="--i: 4"></span>
        <span style="--i: 5"></span>
        <span style="--i: 6"></span>
        <span style="--i: 7"></span>
        <span style="--i: 8"></span>
        <span style="--i: 9"></span>
        <span style="--i: 10"></span>
      </div>
      <section class="modal-header">
        <div class="header-left">
          <div class="warning-icon">
            <svg
              class="icon-svg"
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <g clip-path="url(#clip0_29700_3791)">
                <path
                  d="M12.2478 9.50023V13.5002M12.2478 17.5002H12.2578M10.8631 4.39195L2.63823 18.5986C2.18203 19.3866 1.95393 19.7806 1.98764 20.1039C2.01705 20.386 2.16482 20.6423 2.39417 20.809C2.65713 21.0002 3.11239 21.0002 4.02292 21.0002H20.4727C21.3832 21.0002 21.8385 21.0002 22.1014 20.809C22.3308 20.6423 22.4786 20.386 22.508 20.1039C22.5417 19.7806 22.3136 19.3866 21.8574 18.5986L13.6325 4.39195C13.1779 3.60679 12.9506 3.21421 12.6541 3.08235C12.3954 2.96734 12.1002 2.96734 11.8415 3.08235C11.545 3.21421 11.3177 3.60679 10.8631 4.39195Z"
                  stroke="#D46B00"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_29700_3791">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0.25 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <h2 class="head">
            {{
              "workFlow3.components.commissionChangePopup.heading.actionRequired"
                | translate
            }}
          </h2>
        </div>
        <div class="header-right">
          <boxxds-button-v2
            [isDisabled]="false"
            [size]="'lg'"
            [btnType]="'close'"
            (handleClick)="closeModal()"
          >
          </boxxds-button-v2>
        </div>
      </section>
    </div>
    <section class="info-banner">
      <div class="info">
        <p class="info-header">
          {{
            "workFlow3.components.commissionChangePopup.label.brokerage"
              | translate
          }}
        </p>
        <p class="info-value">
          {{
            existingQuoteOptionData?.["brokerageName"]
              ? existingQuoteOptionData?.["brokerageName"]
              : ""
          }}
          {{
            existingQuoteOptionData?.["branchName"]
              ? "," + existingQuoteOptionData?.["branchName"]
              : ""
          }}
        </p>
      </div>
    </section>

    <section class="modal-content">
      <p class="paragh">
        {{
          "workFlow3.components.commissionChangePopup.description.commissionChangeDescription"
            | translate
        }}
      </p>
      <section class="table-section">
        <div class="table-container">
          <p class="table-title">
            <span class="table-title-description">
              {{
                "workFlow3.components.commissionChangePopup.heading.tableHeading"
                  | translate
              }}
            </span>
          </p>
          <div class="table-wrapper">
            <boxxds-table-v2
              *ngIf="quoteList"
              [tableData]="quoteList"
              [sortableColumns]="sortableColumns"
              (sortHandler)="handleTableColSort($event)"
              [hasScrollablePagination]="true"
              (loadMoreOptions)="loadMoreQuotes($event)"
              [itemsPerPage]="itemsPerPage"
              [totalCount]="totalCount"
              [showPagination]="false"
              [activePageNumber]="pageNumber"
            >
            </boxxds-table-v2>
          </div>
        </div>
      </section>
      <p class="action-title">
        <span class="action-title-description">
          {{
            "workFlow3.components.commissionChangePopup.heading.actionHeading"
              | translate
          }}
          :</span
        >
        <span class="required-asterisk">*</span>
      </p>
    </section>
    <section>
      <div class="action-container">
        <div class="action-save">
          <boxxds-radio-v2
            [control]="'action'"
            [items]="[
              {
                label:
                  ('workFlow3.components.commissionChangePopup.label.saveNewRecord'
                  | translate),
                currentValue: commissionChangeForm.value['action'] === 'save',
                value: 'save'
              }
            ]"
            [form]="commissionChangeForm"
            [formSubmitted]="false"
            [labelContainerStyle]="{ flexDirection: 'column' }"
            [labelStyle]="{
              color: '#292933',
              fontFamily: 'Gibson',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px',
              marginBottom: '10px'
            }"
            [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
            [radioStyle]="{ width: '20px', height: '20px', left: '-7px' }"
            (handleChange)="handlesaveActionChange()"
          ></boxxds-radio-v2>
          <div class="action-options">
            <boxxds-radio-v2
              [control]="'saveAction'"
              [items]="[
                {
                  label:
                    ('workFlow3.components.commissionChangePopup.label.declineAllQuotes'
                    | translate),
                  currentValue:
                    commissionChangeForm.value['saveAction'] === 'decline',
                  value: 'decline'
                }
              ]"
              [form]="commissionChangeForm"
              [formSubmitted]="false"
              [labelContainerStyle]="{ flexDirection: 'column' }"
              [labelStyle]="{
                color: '#292933',
                fontFamily: 'Gibson',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '24px',
                marginBottom: '5px'
              }"
              [radioStyle]="{ width: '16px', height: '16px', left: '-7px' }"
              (handleChange)="handleSetActionDefault()"
            ></boxxds-radio-v2>
            <boxxds-radio-v2
              [control]="'saveAction'"
              [items]="[
                {
                  label:
                    ('workFlow3.components.commissionChangePopup.label.keepExistingQuote'
                    | translate),
                  currentValue:
                    commissionChangeForm.value['saveAction'] === 'noChange',
                  value: 'noChange'
                }
              ]"
              [form]="commissionChangeForm"
              [formSubmitted]="false"
              [labelContainerStyle]="{ flexDirection: 'column' }"
              [labelStyle]="{
                color: '#292933',
                fontFamily: 'Gibson',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '24px',
                marginBottom: '5px'
              }"
              [radioStyle]="{ width: '16px', height: '16px', left: '-7px' }"
              (handleChange)="handleSetActionDefault()"
            ></boxxds-radio-v2>
            <boxxds-radio-v2
              [control]="'saveAction'"
              [items]="[
                {
                  label:
                    ('workFlow3.components.commissionChangePopup.label.updateCommissionLabel'
                    | translate),
                  currentValue:
                    commissionChangeForm.value['saveAction'] === 'updateChange',
                  value: 'updateChange'
                }
              ]"
              [form]="commissionChangeForm"
              [formSubmitted]="false"
              [labelContainerStyle]="{ flexDirection: 'column' }"
              [labelStyle]="{
                color: '#292933',
                fontFamily: 'Gibson',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '24px',
                marginBottom: '5px'
              }"
              [radioStyle]="{ width: '16px', height: '16px', left: '-7px' }"
              (handleChange)="handleSetActionDefault()"
            ></boxxds-radio-v2>
          </div>
        </div>
        <div class="action-save">
          <boxxds-radio-v2
            [control]="'action'"
            [items]="[
              {
                label:
                  ('workFlow3.components.commissionChangePopup.label.discardNewChanges'
                  | translate),
                currentValue:
                  commissionChangeForm.value['action'] === 'discard',
                value: 'discard'
              }
            ]"
            [form]="commissionChangeForm"
            [formSubmitted]="false"
            [labelContainerStyle]="{ flexDirection: 'column' }"
            [labelStyle]="{
              color: '#292933',
              fontFamily: 'Gibson',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px'
            }"
            (handleChange)="handleRadiodiscardCheck($event)"
            [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
            [radioStyle]="{ width: '20px', height: '20px', left: '-7px' }"
          ></boxxds-radio-v2>
        </div>
      </div>
    </section>
    <section class="modal-footer">
      <div class="footer-content">
        <boxxds-button-v2
          class="footer-button"
          [buttonText]="
            'workFlow3.components.commissionChangePopup.button.done' | translate
          "
          [isDisabled]="false"
          [size]="'lg'"
          [btnClass]="'primary'"
          [btnType]="'default'"
          (handleClick)="saveContinue()"
          [btnStyle]="{ width: '219px' }"
        >
        </boxxds-button-v2>
      </div>
    </section>
  </div>
</div>
