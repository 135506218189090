import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Pagination } from 'src/app/entities/boxx-response';
import { DomainsService } from 'src/app/services/domains.service';
import { PolicyRiskDocService } from 'src/app/services/policy-risk-doc.service';
import {
  excelFileDownload,
  getAlertHead,
  getErrorMessage,
  pointerEventsToggle,
} from 'src/app/utils/utils';
import { PolicyRiskDocPreviewService } from 'src/app/services/policy-risk-doc-preview.service';
import { formatDateMoment } from 'src/app/utils/formatDate';
import { PolicyRiskDocUploadService } from 'src/app/services/policy-risk-doc-upload.service';
import { Store, select } from '@ngrx/store';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalAlertService } from 'src/app/services/modal-alert.service';
import { DocumentTemplateService } from 'src/app/services/doc-template.service';
import {
  DOC_GENERATION_WAIT_TIME,
  GENERATE_DOC_SUCCESS_STATUS_CODE,
} from 'src/app/constants/quote-constant';
import { PolicyDetail } from '../../policy-lifecycle-v2/lifecycle-v2-object';
import { take } from 'rxjs';

@Component({
  selector: 'app-slideout-document-v2',
  templateUrl: './slideout-document-v2.component.html',
  styleUrls: ['./slideout-document-v2.component.less'],
})
export class SlideoutDocumentV2Component implements OnInit {
  isGettingSuggestionProgress: boolean;
  docForm: FormGroup;
  constructor(
    private domainsService: DomainsService,
    private policyRiskDocService: PolicyRiskDocService,
    private PolicyRiskDocPreviewService: PolicyRiskDocPreviewService,
    private translate: TranslateService,
    private policyRiskDocUploadService: PolicyRiskDocUploadService,
    private alertService: ModalAlertService,
    private store: Store,
    private fb: FormBuilder,
    private documentTemplateService: DocumentTemplateService,
  ) {
    this.docForm = this.fb.group({
      searchKey: ['', [Validators.pattern(/^.{3,}$/)]],
    });
  }
  @ViewChild('fileDropRef') fileDropRef!: ElementRef<HTMLInputElement>;

  @Input() policyRiskId: number;
  @Input() details: PolicyDetail;
  @Input() showDocPreview = false;
  @Input() showImagePreview = false;
  @Input() isPolicy: boolean = false;
  @Input() policyPeriodId: number = null;
  @Input() closeModalClicked: boolean = false;
  @Input() isProductActive: boolean = false;

  @Output() handleShowGenerateDocMsg = new EventEmitter<any>();
  @Output() handleLoading = new EventEmitter();

  permissionList: { [x: string]: boolean } = {};
  currentScreen: string = '';
  showTabLoader: boolean = false;
  showBodySpinner: boolean = true;
  showModalSpinner: boolean = true;
  showDocumentActionPopup: boolean = false;
  private documentTypeId: number;
  file: any;
  existingFileName: string;
  suggestedFileName: string;
  filesData: { pagination: Pagination | null } = {
    pagination: null,
  };

  currentPreviewDocUrl = '';
  currentFileType = '';
  formData: FormData;

  tableHeaders = [
    'common.fileName',
    'common.fileType',
    'common.dateUploaded',
    'common.uploadedBy',
    'common.fileDescription',
  ];
  existingAlertData: { [x: string]: any }[] = [];

  files: any[] = [];
  totalCount: number = 1;
  currentPage: number = 1;
  itemsPerPage = 10;
  totalPages: number = 1;
  docStageId = undefined;
  allowedFormats = ['jpg', 'jpeg', 'gif', 'txt', 'doc', 'docx', 'pdf', 'pptx'];
  previewAllowedFormats = ['jpg', 'jpeg', 'gif', 'txt', 'pdf'];
  maxFileSizeMB = 5;
  showDeleteFileConfirm = false;
  currentFile: { [x: string]: string } = {};
  currentFileIndex: number = undefined;
  isButtonDisabled: boolean = false;
  shortDateFormat: string = '';
  longDateFormat: string = '';
  existingFiles: any[] = [];
  isUploadingProgress: boolean = false;
  searchValue: string = null;
  stageIdPolicy: any = undefined;
  showDocTempSelectionPopup: boolean = false;
  docPopupDetails: {
    statusType: string;
    action: any;
    documentTemplate: any;
    policyPeriodId: any;
    stageId: any;
    policyRiskId: any;
  };
  loadDocApiCount: number = 0;
  newTimeout: NodeJS.Timeout;

  async ngOnInit(): Promise<void> {
    this.alertService.clearAllAlerts();
    this.showModalSpinner = true;
    await this.populateDocuments(this.currentPage).then(() => {
      this.handleLoaderEmitter(false);
    });
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });

    this.docForm.setValue({
      searchKey: this.searchValue,
    });

    this.docPopupDetails = {
      statusType: 'Document template selection',
      action: 'docTempSelection',
      documentTemplate: [],
      policyPeriodId: 0,
      stageId: 0,
      policyRiskId: 0,
    };
  }

  async populateDocuments(pageNumber) {
    if (!this.policyRiskId) {
      this.showTabLoader = false;
      this.showModalSpinner = false;
      this.handleLoaderEmitter(false);
      return;
    }
    let docName: string = this.searchValue ?? null;
    this.showModalSpinner = true;
    this.handleLoaderEmitter(true);
    await this.getStageId();
    this.policyRiskDocService
      .getPolicyRiskDocCore(
        this.policyRiskId,
        this.docStageId,
        this.documentTypeId,
        pageNumber,
        this.itemsPerPage,
        null,
        docName,
      )
      .subscribe({
        next: (docData) => {
          ({ pagination: this.filesData.pagination } = docData);
          this.files = docData?.data.map((doc) => ({
            ...doc,
            id: doc.id,
            name: this.getFileName(doc.documentPath),
            path: doc.documentPath,
            type: doc?.documentTypeDomain?.description ?? '',
            dateUpdated: formatDateMoment(
              doc?.updatedDt ?? doc.createdDt ?? '',
              this.shortDateFormat,
            ),
            updatedBy: doc.updatedBy ?? doc.createdBy ?? '',
            description: doc?.documentDescription ?? '',
          }));
          this.showTabLoader = false;
          this.showModalSpinner = false;
          this.handleLoaderEmitter(false);
          this.totalPages = this.filesData?.pagination?.totalPages;
          this.currentPage = pageNumber;
          this.totalCount = this.filesData?.pagination?.totalRecords;
          this.closeDocPreview();
        },
        error: (error) => {
          this.showTabLoader = false;
          this.handleLoaderEmitter(false);
          this.showModalSpinner = false;
          if (![500].includes(error?.status)) {
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
          }
        },
      });
  }

  async getStageId() {
    this.docStageId = undefined;
    return new Promise<void>((resolve, reject) => {
      this.domainsService.GetByDomainCode('PROCESSINGSTATUS', true).subscribe({
        next: (response) => {
          let docStage = response.data.filter(
            (template) =>
              template.subdomaincode === 'PROCESSINGSTATUS_COMPLETE',
          )[0];
          this.docStageId = docStage.id;
          resolve();
        },
        error: (error) => {
          this.showTabLoader = false;
          if (![500].includes(error?.status)) {
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
          }
          reject();
        },
      });
    });
  }

  handleDocPreview(file: any) {
    this.alertService.clearAllAlerts();
    let mimeType = this.getFileType(file.name);

    if (!this.previewAllowedFormats.includes(mimeType)) {
      return;
    }

    const documentId = file?.id;
    try {
      this.showBodySpinner = true;
      this.showModalSpinner = true;
      this.handleLoaderEmitter(true);
      if (documentId) {
        if (['jpg', 'jpeg', 'gif', 'txt'].includes(mimeType)) {
          this.showImagePreview = true;
        } else {
          this.showDocPreview = true;
        }
        this.PolicyRiskDocPreviewService.GetPolicyRiskDocToPreview(
          documentId,
          'document',
        ).subscribe({
          next: (documentData) => {
            if (documentData?.data) {
              if (['txt'].includes(mimeType)) {
                this.currentPreviewDocUrl = `data:text/plain;base64,${documentData.data.content}`;
                this.currentFileType = 'text';
              } else if (['jpg', 'jpeg', 'gif'].includes(mimeType)) {
                this.currentPreviewDocUrl = `data:image/${mimeType};base64,${documentData.data.content}`;
                this.currentFileType = 'image';
              } else {
                this.currentPreviewDocUrl = documentData.data.content;
              }
            } else {
              this.showDocPreview = false;
              this.showImagePreview = false;
              this.currentPreviewDocUrl = '';
              this.currentFileType = '';
            }
            this.showBodySpinner = false;
            this.showModalSpinner = false;
            this.handleLoaderEmitter(false);
          },
          error: (error) => {
            this.currentPreviewDocUrl = '';
            this.currentFileType = '';
            this.showBodySpinner = false;
            this.showModalSpinner = false;
            this.handleLoaderEmitter(false);
            this.showDocPreview = false;
            this.showImagePreview = false;
            if (![500].includes(error?.status)) {
              let notificationAlert = {
                type: 'error',
                headerText: getAlertHead('error'),
                bodyText: getErrorMessage(error),
              };
              this.alertService.addAlert(notificationAlert);
            }
          },
        });
      } else {
        this.showDocPreview = false;
        this.showImagePreview = false;
        this.currentPreviewDocUrl = '';
        this.currentFileType = '';
        this.showBodySpinner = false;
        this.showModalSpinner = false;
        this.handleLoaderEmitter(false);
      }
    } catch (error) {
      if (![500].includes(error?.status)) {
        let notificationAlert = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: error?.message ?? '',
        };
        this.alertService.addAlert(notificationAlert);
      }
      this.currentPreviewDocUrl = '';
      this.currentFileType = '';
      this.showBodySpinner = false;
      this.showModalSpinner = false;
      this.handleLoaderEmitter(false);
      this.showDocPreview = false;
      this.showImagePreview = false;
    }
  }

  handleDocDownload(file: any) {
    this.alertService.clearAllAlerts();
    const documentId = file?.id;
    try {
      this.showBodySpinner = true;
      this.showModalSpinner = true;
      this.handleLoaderEmitter(true);
      if (documentId) {
        this.PolicyRiskDocPreviewService.GetPolicyRiskDocToPreview(
          documentId,
          'document',
        ).subscribe({
          next: (documentData) => {
            if (documentData?.data) {
              const base64String = documentData?.data?.content;
              const binaryData = atob(base64String);
              const bufferData = new Uint8Array(
                binaryData.split('').map((char) => char.charCodeAt(0)),
              );
              excelFileDownload(file?.name ?? '', bufferData);
            }
            this.showBodySpinner = false;
            this.handleLoaderEmitter(false);
            this.showModalSpinner = false;
          },
          error: (error) => {
            this.showBodySpinner = false;
            this.handleLoaderEmitter(false);
            this.showModalSpinner = false;
            if (![500].includes(error?.status)) {
              const alertData = {
                type: 'error',
                headerText: getAlertHead('error'),
                bodyText: getErrorMessage(error),
              };
              this.alertService.addAlert(alertData);
            }
          },
        });
      } else {
        this.showBodySpinner = false;
        this.handleLoaderEmitter(false);
        this.showModalSpinner = false;
      }
    } catch (error) {
      if (![500].includes(error?.status)) {
        const alertData = {
          show: true,
          type: 'error',
          headerText: 'error!',
          bodyText: error?.message ?? '',
        };
        this.alertService.addAlert(alertData);
      }
      this.currentPreviewDocUrl = '';
      this.currentFileType = '';
      this.showBodySpinner = false;
      this.showModalSpinner = false;
      this.handleLoaderEmitter(false);
    }
  }

  getFileName(path) {
    const pathSplit = path.split('/');
    return pathSplit[pathSplit.length - 1];
  }

  handleDelete(index: number, file: any) {
    this.alertService.clearAllAlerts();
    if (!file.deletable) {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: this.translate.instant(
          'policy.document.error.fileDeleteErrorMsg',
        ),
      };
      this.alertService.addAlert(alertData);
      return;
    }
    this.currentFile = file;
    this.currentFileIndex = index;
    this.showBodySpinner = false;
    this.showModalSpinner = false;
    this.handleLoaderEmitter(false);
    this.showDeleteFileConfirm = true;
  }

  async handleDeleteConfirm() {
    this.isButtonDisabled = true;
    await this.deleteFile(this.currentFileIndex, this.currentFile);
    this.docForm.get('searchKey').setValue('');
    this.searchValue = null;
  }

  async deleteFile(index: number, file: any) {
    this.alertService.clearAllAlerts();
    if (!file.deletable) {
      this.isButtonDisabled = false;
      return;
    }
    this.policyRiskDocService.deletePolicyRiskDocCore(file.id, 1).subscribe({
      next: async (response) => {
        this.isButtonDisabled = false;
        this.files.splice(index, 1);
        let totalNumberOfFiles = this.filesData.pagination.totalRecords - 1;
        this.filesData.pagination.totalRecords = totalNumberOfFiles;
        this.totalPages = Math.ceil(totalNumberOfFiles / this.itemsPerPage);
        this.totalCount = totalNumberOfFiles;
        if (this.currentPage > this.totalPages) {
          this.currentPage = this.currentPage - 1;
        }
        await this.populateDocuments(this.currentPage);
        this.closeDocPreview();

        const alertData = {
          type: 'success',
          headerText: getAlertHead('success'),
          bodyText: 'policy.document.delete.success.body',
        };
        this.alertService.addAlert(alertData);
      },
      error: (error) => {
        this.isButtonDisabled = false;
        if (![500].includes(error?.status)) {
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: getErrorMessage(error),
          };
          this.alertService.addAlert(alertData);
          this.closeDocPreview();
        }
      },
    });
  }

  closeDocPreview() {
    this.currentPreviewDocUrl = '';
    this.currentFileType = '';
    this.showBodySpinner = false;
    this.showModalSpinner = false;
    this.handleLoaderEmitter(false);
    this.showDocPreview = false;
    this.showImagePreview = false;
    this.showDeleteFileConfirm = false;
    this.currentFile = {};
    this.currentFileIndex = undefined;
    this.isButtonDisabled = false;
  }

  handleAlertClear() {
    this.alertService.clearAllAlerts();
  }

  onFileDropped(event) {
    this.showModalSpinner = true;
    this.handleLoaderEmitter(true);
    this.existingAlertData = [];
    this.alertService.clearAllAlerts();
    this.fileBrowseHandler(event);
  }

  async fileBrowseHandler(e) {
    let files: any = e.target.files;
    await Promise.all([this.prepareFilesList(files)])
      .then(() => {
        if (this.existingFiles.length === 0) {
          this.existingAlertData.forEach((alert) => {
            this.alertService.addAlert(alert);
          });
          this.populateDocuments(this.currentPage);
        } else {
          this.showModalSpinner = false;
          this.handleLoaderEmitter(false);
        }
      })
      .catch((error) => {
        this.showModalSpinner = false;
        this.handleLoaderEmitter(false);
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: getErrorMessage(error),
        };
        this.alertService.addAlert(alertData);
      });
    this.docForm.get('searchKey').setValue('');
    this.searchValue = null;
  }

  async prepareFilesList(files: any): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        this.file = files;
        let fileUploadCounter = files.length;
        for (const item of files) {
          const fileSizeMB = item.size / (1024 * 1024);
          if (fileSizeMB <= this.maxFileSizeMB) {
            const fileExtension = this.getFileExtension(item);
            if (this.allowedFormats.includes(fileExtension)) {
              this.formData = new FormData();
              this.formData.append('file', item, item.name);
              this.formData.append(
                'policyRiskId',
                this.policyRiskId.toString(),
              );
              this.policyRiskDocUploadService
                .uploadPolicyRiskDoc(this.formData)
                .subscribe({
                  next: (response) => {
                    fileUploadCounter -= 1;
                    if (
                      fileUploadCounter === 0 &&
                      this.existingFiles.length !== 0
                    ) {
                      this.handleExistingFilesUpload();
                    } else {
                      const alertData = {
                        show: true,
                        type: 'success',
                        headerText: 'success!',
                        bodyText: 'policy.document.upload.success.body',
                      };
                      this.existingAlertData.push(alertData);
                    }
                    this.file = null;
                    this.clearFileInput();
                    fileUploadCounter === 0 && resolve();
                  },
                  error: (error) => {
                    fileUploadCounter -= 1;
                    let errMsg: string = '';
                    if (typeof error === 'string') {
                      if (
                        error.includes(
                          this.translate.instant(
                            'policy.document.upload.error.responseText',
                          ),
                        )
                      ) {
                        errMsg = this.translate.instant(
                          'policy.document.upload.error.filesize',
                        );
                      }
                    }
                    if (error.message === 'The document already exists') {
                      this.alertService.clearAllAlerts();
                      this.showDocumentActionPopup = true;
                      this.existingFileName = item.name;
                      this.existingFiles.push(item);
                    } else {
                      const alertData = {
                        show: true,
                        type: 'error',
                        headerText: 'error!',
                        bodyText: errMsg ? errMsg : getErrorMessage(error),
                      };
                      this.existingAlertData.push(alertData);
                      this.file = null;
                    }
                    fileUploadCounter === 0 &&
                      this.existingFiles.length !== 0 &&
                      this.handleExistingFilesUpload();
                    this.clearFileInput();
                    fileUploadCounter === 0 && resolve();
                  },
                });
            } else {
              const alertData = {
                type: 'error',
                headerText: getAlertHead('error'),
                bodyText: 'policy.document.upload.error.fileExtension',
              };
              this.existingAlertData.push(alertData);
              fileUploadCounter -= 1;
              fileUploadCounter === 0 &&
                this.existingFiles.length !== 0 &&
                this.handleExistingFilesUpload();
              fileUploadCounter === 0 && resolve();
            }
          } else {
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: 'policy.document.upload.error.filesize',
            };
            this.existingAlertData.push(alertData);
            fileUploadCounter -= 1;
            fileUploadCounter === 0 &&
              this.existingFiles.length !== 0 &&
              this.handleExistingFilesUpload();
            fileUploadCounter === 0 && resolve();
          }
        }
      } catch (error) {
        // this.showModalSpinner = false;
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: getErrorMessage(error),
        };
        this.alertService.addAlert(alertData);
        reject(error);
      }
    });
  }

  handleExistingFilesUpload() {
    this.checkAndGenerateUniqueFileName(this.existingFiles[0].name);
    this.existingFileName = this.existingFiles[0].name;
  }

  getExistingFilesUploadError(): string {
    let errorMsg: string = '';
    if (this.existingFiles.length > 1) {
      this.existingFiles.map((file, index) => {
        errorMsg = index === 0 ? `${file.name}` : `${errorMsg}, ${file.name}`;
      });
    } else {
      return `${this.existingFiles[0].name} ${this.translate.instant(
        'common.alreadyExists',
      )}`;
    }
    return `${errorMsg} ${this.translate.instant('common.alreadyExists')}`;
  }

  handleExistingFileUpload(file: any, isOverwrite: boolean = false) {
    this.existingAlertData = [];
    this.isUploadingProgress = true;
    this.formData = new FormData();
    this.formData.append('file', file, file.name);
    this.formData.append('policyRiskId', this.policyRiskId.toString());
    if (isOverwrite) {
      this.formData.append('force', '1');
    }
    this.policyRiskDocUploadService
      .uploadPolicyRiskDoc(this.formData)
      .subscribe({
        next: async (response) => {
          await this.handleCloseExistingFilesUpload(false);
          const alertData = {
            show: true,
            type: 'success',
            headerText: getAlertHead('success'),
            bodyText: 'policy.document.upload.success.body',
          };
          this.alertService.addAlert(alertData);
          this.existingFiles.length === 0 &&
            this.populateDocuments(this.currentPage);
        },
        error: async (error) => {
          this.showModalSpinner = false;
          this.handleLoaderEmitter(false);
          let errMsg: string = '';
          if (typeof error === 'string') {
            if (
              error.includes(
                this.translate.instant(
                  'policy.document.upload.error.responseText',
                ),
              )
            ) {
              errMsg = this.translate.instant(
                'policy.document.upload.error.filesize',
              );
            }
          }
          const alertData = {
            show: true,
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: errMsg ? errMsg : getErrorMessage(error),
          };
          this.alertService.addAlert(alertData);
          this.isUploadingProgress = false;
        },
      });
  }

  async handleCloseExistingFilesUpload(isUserAction: boolean = true) {
    this.existingAlertData = [];
    this.existingFiles.splice(0, 1);
    isUserAction && this.alertService.clearAllAlerts();
    this.isUploadingProgress = false;
    this.populateDocuments(this.currentPage);
    if (this.existingFiles.length > 0) {
      this.existingFileName = this.existingFiles[0].name;
      this.checkAndGenerateUniqueFileName(this.existingFiles[0].name);
    }
  }

  clearFileInput() {
    if (this.fileDropRef && this.fileDropRef.nativeElement)
      this.fileDropRef.nativeElement.value = '';
  }

  getFileExtension(file: File): string {
    let fileExtension = this.getFileType(file.name);
    return fileExtension;
  }

  getFileType(fileName: string): string {
    let fileExtension = fileName.split('.').pop() || '';
    return fileExtension.toLowerCase();
  }

  isDisablePreview(fileName: string): boolean {
    let fileExtension = this.getFileType(fileName);
    if (!this.previewAllowedFormats.includes(fileExtension)) {
      return true;
    }
    return false;
  }

  handleDocumentActionConfirm(event) {
    this.alertService.clearAllAlerts();
    if (event.overwrite) {
      this.handleExistingFileUpload(this.existingFiles[0], true);
    } else {
      const newFile = this.renameFile(this.existingFiles[0], event.newFileName);
      this.handleExistingFileUpload(newFile);
    }
  }

  renameFile(file: any, newFileName: string) {
    if (file) {
      const fileExtension = this.getFileType(file.name);
      const renamedFile = new File([file], newFileName + '.' + fileExtension, {
        type: file.type,
        lastModified: file.lastModified,
      });
      return renamedFile;
    }
    return null;
  }

  generateNewFileName(fileName: string): string {
    const fileExtension = fileName.match(/\.\w+$/) || [''];
    const baseFileName = fileName.replace(/\.[^/.]+$/, '');
    const regex = /^(.*?)( \((\d+)\))?$/;
    const match = baseFileName.match(regex);

    if (match) {
      const baseName = match[1] || baseFileName;
      const currentNumber = match[3] ? parseInt(match[3], 10) : 0;
      const newFileName = `${baseName} (${currentNumber + 1})${
        fileExtension[0]
      }`;
      return newFileName;
    }

    return `${baseFileName} (1)${fileExtension[0]}`;
  }

  checkAndGenerateUniqueFileName(originalFileName: string) {
    const generatedFileName = this.generateNewFileName(originalFileName);
    this.isGettingSuggestionProgress = true;
    this.policyRiskDocService
      .getPolicyRiskDocCore(
        this.policyRiskId,
        this.docStageId,
        null,
        null,
        null,
        generatedFileName,
      )
      .subscribe((res) => {
        this.isGettingSuggestionProgress = false;
        if (res.data.length === 0) {
          this.suggestedFileName =
            this.removeFileNameExtension(generatedFileName);
          return;
        } else {
          return this.checkAndGenerateUniqueFileName(generatedFileName);
        }
      });
  }

  removeFileNameExtension(fileName: string) {
    return fileName.replace(/\.[^/.]+$/, '');
  }

  async handleSearch(event) {
    this.alertService.clearAllAlerts();
    const searchValue = event.target.value;
    if (
      event.key == 'Enter' &&
      searchValue &&
      searchValue?.trim().length >= 3 &&
      searchValue?.trim().length <= 45
    ) {
      this.searchValue = searchValue.trim();
      this.populateDocuments(1);
    } else if (
      searchValue?.length === 0 ||
      (event.key == 'Enter' && searchValue && searchValue?.trim().length < 3)
    ) {
      this.searchValue = null;
      this.populateDocuments(this.currentPage);
    }
  }

  showDocumentGenerateErrorMsg(
    msg: string = 'policy.document.error.notRegeneratedErrorMsg',
  ) {
    let notificationAlert = {
      type: 'error',
      headerText: getAlertHead('error'),
      bodyText: this.translate.instant(msg),
    };
    this.alertService.addAlert(notificationAlert);
  }

  async getPolicyStageId() {
    this.stageIdPolicy = undefined;
    let stageCode = 'DOCMERGESTAGE_BIND';
    this.showModalSpinner = true;
    this.handleLoaderEmitter(true);
    return new Promise<void>((resolve, reject) => {
      this.domainsService.GetByDomainCode('DOCMERGESTAGE', true).subscribe({
        next: (response) => {
          let docStage = response.data.filter(
            (template) => template.subdomaincode === stageCode,
          )[0];
          this.stageIdPolicy = docStage.id;
          this.showModalSpinner = false;
          this.handleLoaderEmitter(false);
          resolve();
        },
        error: (error) => {
          this.showModalSpinner = false;
          this.handleLoaderEmitter(false);
          this.showDocumentGenerateErrorMsg(getErrorMessage(error));
          reject(getErrorMessage(error));
        },
      });
    });
  }

  handleDocLevelAction(action) {
    this.showBodySpinner = false;
    this.docPopupDetails.action = action;
    this.showDocTempSelectionPopup = true;
  }

  async loadPolicyDocumentTemplates() {
    this.alertService.clearAllAlerts();
    if (this.policyRiskId) {
      await this.getPolicyStageId();
      if (this.stageIdPolicy) {
        this.documentTemplateService
          .getDocumentTemplateList(this.policyRiskId, this.stageIdPolicy)
          .subscribe({
            next: (response) => {
              let templateList = response?.data.filter((item) => {
                return item.templates.map(
                  (template) => template.docProcessId === null,
                );
              });
              if (templateList.length !== 0) {
                this.docPopupDetails.documentTemplate = templateList;
                this.showDocTempSelectionPopup = true;
                this.handleDocLevelAction('bind');
              } else {
                this.showDocTempSelectionPopup = false;
                this.regeneratePolicyDocument();
              }
            },
            error: (error) => {
              this.showDocumentGenerateErrorMsg(getErrorMessage(error));
            },
          });
      } else {
        this.showDocumentGenerateErrorMsg();
      }
    } else {
      this.showDocumentGenerateErrorMsg();
    }
  }

  async regeneratePolicyDocument() {
    if (this.policyPeriodId) {
      this.searchValue = null;
      this.docForm.reset();
      await this.getPolicyStageId();
      if (this.stageIdPolicy) {
        pointerEventsToggle(true);
        this.showModalSpinner = true;
        this.handleLoaderEmitter(true);
        const interval = 5000;
        const startTime = Date.now();
        return new Promise<any>(async (resolve, reject) => {
          this.documentTemplateService
            .generateStageDocument(this.policyPeriodId, this.stageIdPolicy)
            .subscribe({
              next: async (resp) => {
                this.loadDocApiCount = 0;
                // allowing maximum api call count as 5
                while (
                  Date.now() - startTime < DOC_GENERATION_WAIT_TIME &&
                  this.closeModalClicked === false &&
                  this.loadDocApiCount < 5
                ) {
                  await this.checkDocumentStatus();
                  await this.listPolicyDocuments(1);
                  await new Promise(
                    (resolve) =>
                      (this.newTimeout = setTimeout(resolve, interval)),
                  );
                }
                if (this.loadDocApiCount === 5) {
                  this.showTabLoader = false;
                  this.showModalSpinner = false;
                  this.handleLoaderEmitter(false);
                  pointerEventsToggle(false);
                  this.showDocumentGenerateSuccessMsg();
                  resolve(true);
                }
                if (this.closeModalClicked === true) {
                  this.showTabLoader = false;
                  this.showModalSpinner = false;
                  this.handleLoaderEmitter(false);
                  pointerEventsToggle(false);
                  resolve(true);
                }
              },
              error: (error) => {
                clearTimeout(this.newTimeout);
                this.showModalSpinner = false;
                this.handleLoaderEmitter(false);
                pointerEventsToggle(false);
                this.showDocumentGenerateErrorMsg(getErrorMessage(error));
                reject(getErrorMessage(error));
              },
            });
        });
      } else {
        this.showDocumentGenerateErrorMsg();
      }
    } else {
      this.showDocumentGenerateErrorMsg();
    }
  }

  showDocumentGenerateSuccessMsg() {
    if (this.closeModalClicked === false) {
      this.handleShowGenerateDocMsg.emit();
    }
  }

  async listPolicyDocuments(pageNumber) {
    if (!this.policyRiskId) {
      this.showTabLoader = false;
      return;
    }
    this.showModalSpinner = true;
    this.handleLoaderEmitter(true);
    this.policyRiskDocService
      .getPolicyRiskDocCore(
        this.policyRiskId,
        null,
        this.documentTypeId,
        pageNumber,
        this.itemsPerPage,
      )
      .subscribe({
        next: (docData) => {
          ({ pagination: this.filesData.pagination } = docData);
          this.files = docData?.data
            .filter((doc) => doc.processingStatus === this.docStageId)
            .map((doc) => ({
              ...doc,
              id: doc.id,
              name: this.getFileName(doc.documentPath),
              path: doc.documentPath,
              type: doc?.documentTypeDomain?.description ?? '',
              dateUpdated: formatDateMoment(
                doc?.updatedDt ?? doc.createdDt ?? '',
                this.shortDateFormat,
              ),
              updatedBy: doc.updatedBy ?? doc.createdBy ?? '',
              description: doc?.documentDescription ?? '',
            }));
          this.totalPages = this.filesData?.pagination?.totalPages;
          this.currentPage = pageNumber;
          this.totalCount = this.filesData?.pagination?.totalRecords;
        },
        error: (error) => {
          this.showTabLoader = false;
          this.showModalSpinner = false;
          this.handleLoaderEmitter(false);
          clearTimeout(this.newTimeout);
          if (![500].includes(error?.status)) {
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
              wrapperStyle:
                this.existingFiles.length === 0
                  ? { margin: '30px 0px 0px -75px' }
                  : {},
            };
            this.alertService.addAlert(alertData);
          }
        },
      });
  }

  checkDocumentStatus() {
    return new Promise<void>((resolve, reject) => {
      if (this.policyPeriodId && this.stageIdPolicy) {
        this.policyRiskDocService
          .getPolicyRiskGeneratedDocCore(
            this.policyPeriodId,
            this.stageIdPolicy,
          )
          .subscribe({
            next: async (response) => {
              if (response?.data?.status === GENERATE_DOC_SUCCESS_STATUS_CODE) {
                this.loadDocApiCount = 5;
                resolve();
              } else {
                this.loadDocApiCount++;
                resolve();
              }
            },
            error: (error) => {
              this.loadDocApiCount = 5;
              clearTimeout(this.newTimeout);
              this.showModalSpinner = false;
              this.handleLoaderEmitter(false);
              this.showDocumentGenerateErrorMsg(getErrorMessage(error));
              reject(getErrorMessage(error));
              return;
            },
          });
      } else {
        this.showDocumentGenerateErrorMsg();
        reject('error');
      }
    });
  }

  async handleSuccessDocTemplate(event) {
    this.showDocTempSelectionPopup = false;
    this.regeneratePolicyDocument();
  }

  handleCloseDocumentPopup() {
    if (this.showBodySpinner) {
      return;
    }
    this.showDocTempSelectionPopup = false;
  }

  handleLoaderEmitter(isLoading: boolean): void {
    this.handleLoading.emit({ isLoading });
  }
}
