import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BoxxResponse } from '../entities/boxx-response';
import { BaseService } from './base-services';
import { BillingCompanyContact } from '../entities/insured-company';

@Injectable({
  providedIn: 'root',
})
export class BillingCompanyContactService extends BaseService<BillingCompanyContact> {
  constructor(http: HttpClient) {
    const baseurl =
      environment.msInsuredBaseUrl + environment.billingCompanyContact;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  GetByInsuredId(
    insuredId,
    page = 1,
    limit = 10,
    sort = '',
  ): Observable<BoxxResponse<BillingCompanyContact>> {
    return this.http.get<BoxxResponse<BillingCompanyContact>>(
      this.baseurl +
        '?insuredId=' +
        insuredId +
        '&page=' +
        page +
        '&limit=' +
        limit +
        '&sort=' +
        sort,
    );
  }
}
