import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { convertDate } from 'src/app/utils/formatDate';

@Component({
  selector: 'app-modal-sanction',
  templateUrl: './modal-sanction.component.html',
  styleUrls: ['./modal-sanction.component.less'],
})
export class ModalSanctionComponent implements OnInit {
  @Input() showModal: boolean = false;
  @Input() existingQuoteOptionData;
  @Input() data = [];
  @Input() isRestrictedIndustry = false;

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleProceed = new EventEmitter<any>();
  @Output() handleDiscard = new EventEmitter<any>();
  @Output() handleDeclineAndSaveChanges = new EventEmitter<any>();

  tableData = [];

  constructor() {}

  ngOnInit(): void {
    this.data = this.data.map((sanction, index) => {
      if (index === 0 && this.isRestrictedIndustry) {
        return sanction;
      } else {
        const popOverData = [];
        popOverData.push(`Full Name: ${sanction.fullName}`);
        popOverData.push(
          `Citizenship: ${
            sanction.citizenship?.length > 0
              ? sanction.citizenship?.join(', ')
              : '-'
          }`,
        );
        popOverData.push(`Remarks: ${sanction?.remarks || '-'}`);
        popOverData.push(
          `Additional Sanctions: ${
            sanction.additionalSanctions?.length > 0
              ? sanction.additionalSanctions.join(', ')
              : '-'
          }`,
        );
        popOverData.push(
          `Programs: ${
            sanction.programs?.length > 0 ? sanction.programs.join(', ') : '-'
          }`,
        );

        return {
          Type: 'OFAC',
          Description: 'Sanction is subject to secondary sanction',
          Reasons: 'Sanction reasons',
          Source: sanction?.source || 'SDN',
          Score: sanction?.score?.toString(),
          popOverContent: popOverData,
        };
      }
    });
  }

  closeHandler() {
    this.handleClose.emit();
  }
  actionProceedWithChanges(event: any) {
    if (this.isRestrictedIndustry) {
      return;
    }
    this.handleProceed.emit(true);
  }
  actionDiscard(event: any) {
    this.handleDiscard.emit(true);
  }
  actionDeclineAndSaveChanges() {
    this.handleDeclineAndSaveChanges.emit();
  }

  getDateFormated(date: any, dateFormat: string) {
    return date ? convertDate(date, dateFormat) : '';
  }
}
