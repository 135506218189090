import { BrokerageProfile } from 'src/app/entities/brokerage-profile';
import {
  SAVE_BROKERAGE_PROFILE,
  SAVE_BROKERAGE_PARENT_COMPANY,
} from './brokerageProfile.action';
import { BrokerageParentCompany } from 'src/app/entities/brokerage-parent-company';

export interface BrokerageProfileState {
  brokerageProfile: BrokerageProfile;
  brokerageCompany: BrokerageParentCompany;
}

const initialState: BrokerageProfileState = {
  brokerageProfile: null,
  brokerageCompany: {
    id: undefined,
    name: '',
    searchText: '',
  },
};

export function createBrokerageProfileReducer(
  state: BrokerageProfileState = initialState,
  action: any,
): any {
  switch (action.type) {
    case SAVE_BROKERAGE_PROFILE:
      return {
        ...state,
        brokerageProfile: { ...action.payload },
      };

    case SAVE_BROKERAGE_PARENT_COMPANY:
      return {
        ...state,
        brokerageCompany: { ...state.brokerageCompany, ...action.payload },
      };

    default:
      return state;
  }
}
