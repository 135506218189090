import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, retry } from 'rxjs/operators';
import { Brokerage } from '../entities/brokerage';
import { environment } from 'src/environments/environment';
import { BoxxResponse } from '../entities/boxx-response';
import { IBaseDto } from '../dtos/baseDto';

import { IBrokerageRelationship } from '../entities/brokerage-relationship';
import { IBrokerageRelationshipFilters } from '../filters/brokerageRelationshipFilters';

@Injectable({
  providedIn: 'root',
})
export class BrokerageRelationshipService {
  baseurl =
    environment.msBrokerBaseUrl + environment.brokerageRelationshipPathUrl;

  constructor(private http: HttpClient) {}

  public getAllByFilter(
    filters: IBrokerageRelationshipFilters,
  ): Observable<BoxxResponse<IBrokerageRelationship>> {
    let params = new HttpParams();

    if (filters.parentBrokerId)
      params = params.append('parentBrokerId', filters.parentBrokerId);

    if (filters.childBrokerId)
      params = params.append('childBrokerId', filters.childBrokerId);

    params = params.append('page', filters.page ? filters.page : 1);
    params = params.append('limit', filters.limit ? filters.limit : 10);

    const url = `${this.baseurl}`;
    return this.http.get<any>(url, { params: params });
  }

  public update(
    id: number | null,
    parentId: number,
    childId: number,
  ): Observable<any> {
    if (id === undefined || id === null) {
      const relationship = {
        type: '1',
        parentBrokerId: Number(parentId),
        childBrokerId: Number(childId),
      };
      const url = `${environment.msBrokerBaseUrl}${environment.brokerageRelationshipPathUrl}`;
      return this.http.post<any>(url, relationship);
    }

    const url = `${environment.msBrokerBaseUrl}${environment.brokerageRelationshipPathUrl}${id}`;
    return this.http.put<any>(url, {
      parentBrokerId: parentId ? Number(parentId) : null,
      childBrokerId: Number(childId),
    });
  }
}
