import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from './base-services';
import { Observable, retry, catchError } from 'rxjs';
import { BoxxResponse } from '../entities/boxx-response';

@Injectable({
  providedIn: 'root',
})
export class ConfMessageTemplateService extends BaseService<any> {
  constructor(http: HttpClient) {
    const baseurl =
      environment.msConfigurationBaseUrl + environment.messagingTemplatePathUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  GetAllMessageTemplates(
    msgType?: number,
    riskId?: number,
    active = true,
    page = 1,
    limit = 10,
    sort = 'id:ASC',
  ): Observable<BoxxResponse<any>> {
    return this.http
      .get<BoxxResponse<any>>(
        `${this.baseurl}?${msgType && `msgType=${msgType}`}${
          riskId ? `&riskId=${riskId}` : ``
        }&active=${active}&page=${page}&limit=${limit}&sort=${sort}`,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
