import { Component, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  StatusMapping,
  getPolicyStatusTypeV2,
} from 'src/app/constants/policyStatus';
import {
  DOMAIN_CODE_QUOTEFLOWSTATUS,
  SUBDOMAINCODE_QUOTEFLOWSTATUS_PENDINGINFO,
  SUB_DOMAIN_CODE_REASONTYPE_PENDINGINFO,
  SUB_DOMAIN_CODE_REASONTYPE_REFERRAL,
  SUBDOMAINCODE_QUOTEFLOWSTATUS_TOREVIEW,
} from 'src/app/constants/quote-constant';
import { QuoteFlowStatus } from 'src/app/constants/quoteStatus';
import { Pagination } from 'src/app/entities/boxx-response';
import { SystemUser } from 'src/app/entities/system-user';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { PolicyDashboardService } from 'src/app/services/policy-dashboard.service';
import { SharedFunctionService } from 'src/app/services/shared/shared-function.service';
import { SystemUserService } from 'src/app/services/system-user.service';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import {
  formatDateMoment,
  getDateString,
  getFormattedDateTime,
} from 'src/app/utils/formatDate';
import { AlertService } from 'src/app//services/alert.service';
import {
  excelFileDownload,
  getAlertHead,
  getErrorMessage,
} from 'src/app/utils/utils';
import { firstValueFrom, take } from 'rxjs';
import { DomainsService } from 'src/app/services/domains.service';
import { BrokerageDirectoryService } from 'src/app/services/brokerage-directory.service';
import { IndustryService } from 'src/app/services/industry.service';
import { AbbreviateNumberPipe } from 'src/app/pipes/abbreviate-number.pipe';

interface FilterTab {
  id: number;
  name: string;
  value: string;
  active: boolean;
  count: number;
  hasUnread: boolean;
  emptyMsg: string;
  taskTableHeaders: { [key: string]: string };
  tableColumnsExcluded: string[];
}

@Component({
  selector: 'app-dashboard-home-v2',
  templateUrl: './dashboard-home-v2.component.html',
  styleUrls: ['./dashboard-home-v2.component.less'],
  providers: [AbbreviateNumberPipe],
})
export class DashboardHomeV2Component implements OnInit {
  filterTabs: FilterTab[] = [
    {
      id: 1,
      name: 'New Business',
      value: 'NEW_BUSINESS',
      active: true,
      count: 0,
      hasUnread: false,
      emptyMsg: 'Your task list is empty.',
      taskTableHeaders: {
        'Applicant name':
          'policy.insured.companyName,policy.insured.firstName,policy.insured.lastName',
        Status: 'quoteFlowStatus',
        Source: 'apiCreated',
        'Submission Date': 'policy.createdDt',
        'Need by': 'policy.needByDt',
        Industry: 'policy.insured.industry.name',
        Revenue: 'policy.insured.revenue',
        Broker:
          'policy.brokerageProducerBOR.firstName,policy.brokerageProducerBOR.lastName,policy.brokerageBranchBOR.name',
        Brokerage: 'policy.brokerageBOR.name',
      },
      tableColumnsExcluded: [
        'isUnread',
        'toReviewReferredBy',
        'pendingInfoReason',
        'Quote ID',
        'underwriterName',
      ],
    },
    {
      id: 2,
      name: 'Referred Tasks',
      value: 'REFERRED_TASK',
      active: false,
      count: 0,
      hasUnread: false,
      emptyMsg: 'Your Referred Task list is empty.',
      taskTableHeaders: {
        'Applicant name':
          'policy.insured.companyName,policy.insured.firstName,policy.insured.lastName',
        'Referred to': 'refferedToUnderwriterId',
        Source: 'apiCreated',
        'Submission Date': 'policy.createdDt',
        'Quote Date': 'quoteCreatedDt',
        Industry: 'policy.insured.industry.name',
        Revenue: 'policy.insured.revenue',
        Broker:
          'policy.brokerageProducerBOR.firstName,policy.brokerageProducerBOR.lastName,policy.brokerageBranchBOR.name',
        Brokerage: 'policy.brokerageBOR.name',
      },
      tableColumnsExcluded: [
        'isUnread',
        'toReviewReferredBy',
        'pendingInfoReason',
        'Quote ID',
        'underwriterName',
      ],
    },
    {
      id: 3,
      name: 'Renewals',
      value: 'RENEWAL',
      active: false,
      count: 0,
      hasUnread: false,
      emptyMsg: 'No policies expiring in the next 30 days.',
      taskTableHeaders: {
        'Applicant name':
          'policy.insured.companyName,policy.insured.firstName,policy.insured.lastName',
        Status: 'quoteFlowStatus',
        'Policy Period': 'effectiveDt',
        Industry: 'policy.insured.industry.name',
        Limit: 'Limit',
        Premium: 'Premium',
        Broker:
          'policy.brokerageProducerBOR.firstName,policy.brokerageProducerBOR.lastName,policy.brokerageBranchBOR.name',
        Brokerage: 'policy.brokerageBOR.name',
      },
      tableColumnsExcluded: [
        'isUnread',
        'toReviewReferredBy',
        'pendingInfoReason',
        'Quote ID',
        'underwriterName',
      ],
    },
    {
      id: 4,
      name: 'Quoted',
      value: 'QUOTED',
      active: false,
      count: 0,
      hasUnread: false,
      emptyMsg: 'Your quotes list is empty.',
      taskTableHeaders: {
        'Applicant name':
          'policy.insured.companyName,policy.insured.firstName,policy.insured.lastName',
        Status: 'quoteFlowStatus',
        Source: 'apiCreated',
        'Submission Date': 'policy.createdDt',
        'Quote Date': 'quoteCreatedDt',
        Industry: 'policy.insured.industry.name',
        Revenue: 'policy.insured.revenue',
        Broker:
          'policy.brokerageProducerBOR.firstName,policy.brokerageProducerBOR.lastName,policy.brokerageBranchBOR.name',
        Brokerage: 'policy.brokerageBOR.name',
      },
      tableColumnsExcluded: [
        'isUnread',
        'toReviewReferredBy',
        'pendingInfoReason',
        'Quote ID',
        'underwriterName',
      ],
    },
  ];
  filterTabsCopy: FilterTab[] = [...this.filterTabs];
  taskTableData: { [key: string]: any }[] = [];
  taskTableHeaders: string[] = [];
  followupTableData: { [key: string]: any }[] = [];
  followupTableHeaders: string[] = [];
  followupTableHeadersCopy: string[] = [];

  newTableData: { [key: string]: any }[] = [];
  totalDataCount = 0;
  taskPagination: Pagination;
  followupPagination: Pagination;
  showTaskTblSpinner: boolean = true;
  showFollowupTblSpinner: boolean = true;
  filterTabsCountLoader: boolean = true;
  isInsuredTypeCompany: boolean = true;
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  shortDateFormat: string = '';
  shortDateFormat_2: string = 'MMM. DD, YYYY';
  longDateFormat: string = '';
  isRestrictedRoleSubmission: boolean = false;
  isUA: any = null;
  boxxUserId: string = '';
  currentFilterTabIndex: number = 0;
  searchQuery: string = '';
  filterForm: FormGroup;
  dashboardFilterForm: FormGroup;
  isSearchable: boolean = false;
  quoteFlowUrl: string = '';
  unFilteredQuotesData = [];
  quoteFlowSteps: string = '';
  followUpTableEmptyMsg: string = '';
  isTeamsQueue: boolean = false;
  filterParamsForExport: any;

  showFilter = false;
  filteredOptions = [];
  filterSelectedOptions: any;
  filterInputOptions = [];
  statusOptions = [];
  brokerageOptions = [];
  brokerageDirectorySearch = '';
  brokerageDirectorCurrentPage = 0;
  brokerageDirectoryTotalRecords = 0;
  revenueOptions = [];
  industryOptions = [];
  industrySearch = '';
  industryCurrentPage = 0;
  industryTotalRecords = 0;
  showingFilteredResults = false;
  systemUser: SystemUser;
  loggedInUserName: string = '';
  resetSort: boolean = false;
  noFilterResultsMessage = '';

  constructor(
    private policyDashboardService: PolicyDashboardService,
    private store: Store,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private sharedFunctionService: SharedFunctionService,
    private translateService: TranslateService,
    private alertService: AlertService,
    private domainsService: DomainsService,
    private brokerageDirectoryService: BrokerageDirectoryService,
    private industryService: IndustryService,
    private abbreviateNumber: AbbreviateNumberPipe,
    public systemUserService: SystemUserService,
  ) {
    this.filterForm = this.fb.group({
      effectiveDt: this.fb.group({
        from: [''],
        to: [''],
      }),
      createdDt: this.fb.group({
        from: [''],
        to: [''],
      }),
      brokerageBranchProd: [[]],
      underwriter: [[]],
      status: [[]],
      risks: [[]],
      insuredName: [''],
      premiumRange: this.fb.group({
        from: [''],
        to: [''],
      }),
    });

    this.dashboardFilterForm = this.fb.group({
      needByDt: this.fb.group({
        from: [''],
        to: [''],
      }),
      quoteProcessStatus: [[]],
      brokerage: [[]],
      submissionDt: this.fb.group({
        from: [''],
        to: [''],
      }),
      revenue: this.fb.group({
        from: [0],
        to: [0],
      }),
      industry: [[]],
    });
    this.followUpTableEmptyMsg = this.translateService.instant(
      'common.error.noAccessToPageErrorMsg',
    );

    this.noFilterResultsMessage = this.translateService.instant(
      'dashboard.info.noFilterResults',
    );
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.queryParams['mock'] === 'false') {
      this.localStorageService.setMockExternalAPIs('false');
    } else {
      this.localStorageService.setMockExternalAPIs('true');
    }
    this.boxxUserId = localStorage.getItem('bid');
    const systemUsrId = localStorage.getItem('sid');
    this.systemUserService.Get(systemUsrId).subscribe((data) => {
      this.systemUser = data.data;
      this.loggedInUserName = data.data.name;
    });
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.shortDateFormat_2 =
        data.shortDateFormat_2 && data.shortDateFormat_2 === 'MMM. D, YYYY'
          ? this.shortDateFormat_2
          : this.shortDateFormat_2;
      this.longDateFormat = data.longDateFormat;
      this.isRestrictedRoleSubmission = data.isRestrictedRoleSubmission;
      this.quoteFlowSteps = this.localStorageService.getQuoteFlowSteps();
      if (this.quoteFlowSteps === '7') {
        this.quoteFlowUrl = 'quote';
      } else if (this.quoteFlowSteps === '4') {
        this.quoteFlowUrl = 'quote4flow';
      } else {
        this.quoteFlowUrl = 'workflow3';
      }

      if (this.isRestrictedRoleSubmission) {
        this.filterTabs.length = 1;
      }
      if (this.isUA != this.isRestrictedRoleSubmission) {
        this.getTableData({
          tableType: 'task',
          pageNumber: 1,
          limit: 10,
          sort: '',
          scrollDirection: 'scrollingDown',
          isDashboardCount: true,
          isTeamsQueue: this.isTeamsQueue,
        });
        this.getTableData({
          tableType: 'task',
          pageNumber: 1,
          limit: 10,
          sort: '',
          scrollDirection: 'scrollingDown',
          isTeamsQueue: this.isTeamsQueue,
        });
        this.isUA = this.isRestrictedRoleSubmission;
      }
    });

    this.sharedFunctionService.currentData.subscribe((data) => {
      if (this.showingFilteredResults) {
        this.handleFilterClear();
      }
      if (data !== null) {
        if (data) {
          this.filterTabs.splice(1, 1);
          this.filterTabs = this.filterTabs.map(
            (tab: FilterTab, key: number) => {
              if (key === 0) {
                return { ...tab, active: true };
              } else {
                return { ...tab, active: false };
              }
            },
          );
        } else {
          this.filterTabs = [...this.filterTabsCopy];
        }
        if (this.isTeamsQueue !== data) {
          this.currentFilterTabIndex = 0;
          this.searchQuery = '';
          this.filterForm.reset();

          this.getTableData({
            tableType: 'task',
            pageNumber: 1,
            limit: 10,
            sort: '',
            scrollDirection: 'scrollingDown',
            isDashboardCount: true,
            isTeamsQueue: data,
          });
          this.getTableData({
            tableType: 'task',
            pageNumber: 1,
            limit: 10,
            sort: '',
            scrollDirection: 'scrollingDown',
            isTeamsQueue: data,
          });
        }
        this.isTeamsQueue = data;
      }
    });
  }

  handleStartNewQuote() {
    this.router.navigate([`../${this.quoteFlowUrl}/new`], {
      relativeTo: this.activatedRoute,
      skipLocationChange: true,
    });
  }

  handleSearchIconClick() {
    this.isSearchable = true;
    const timeout = setTimeout(() => {
      document.getElementById('search').focus();
      clearTimeout(timeout);
    }, 100);
  }

  async loadResults(filterParams: any, appendData: boolean = false) {
    let {
      tableType,
      pageNumber,
      limit,
      sort,
      scrollDirection,
      insuredName,
      isDashboardCount,
      isTeamsQueue,
    } = filterParams;
    if (this.searchQuery === '') {
      this.isSearchable = false;
    }

    const sortWithDefaultSort =
      sort && sort !== ''
        ? this.isRestrictedRoleSubmission
          ? `${sort}`
          : `isRead: asc,${sort}`
        : this.isRestrictedRoleSubmission
        ? `policyId: desc`
        : `isRead: asc, policyId: desc`;

    if (tableType == 'task') {
      this.showTaskTblSpinner = true;
    } else {
      this.showFollowupTblSpinner = true;
    }

    // const quoteStatus =
    //   StatusMapping[
    //     this.filterTabs[this.currentFilterTabIndex].value.toLowerCase()
    //   ];

    let insuredType = this.getInsuredType();

    let filter = this.makeFilter();

    // In BE added logic so no need to send the quote status from dashboard.
    // quoteStatus && (filter['quoteStatus'] = quoteStatus);

    filter['insuredName'] && delete filter['insuredName'];
    insuredName && (filter['insuredName'] = this.searchQuery);
    // if (quoteStatus) {
    //   if (filter.quoteStatus) {
    //     filter.quoteStatus += ',' + quoteStatus;
    //   } else {
    //     filter = Object.assign(filter, { quoteStatus: quoteStatus });
    //   }
    // }
    // if (filter.trxDays) {
    //   if (filter.quoteStatus) {
    //     let temp = filter.quoteStatus
    //       .split(',')
    //       .findIndex((x) => x == StatusMapping['quoted']);
    //     if (temp == -1) {
    //       filter.quoteStatus += ',' + StatusMapping['quoted'];
    //     }
    //   } else {
    //     filter = Object.assign(filter, { quoteStatus: StatusMapping['quoted'] });
    //   }
    // }

    const args = {
      insuredType,
      filter,
      page: pageNumber,
      limit,
      sort: sortWithDefaultSort,
      isDashboardCount,
      isTeamsQueue,
    };
    this.filterParamsForExport = args;
    this.policyDashboardService.GetAllQuoteByFilter(args).subscribe({
      next: (data) => {
        if (!isDashboardCount) {
          // to get policyRiskTrxId & status of quotes
          if (tableType === 'task' && appendData) {
            this.unFilteredQuotesData = [
              ...this.unFilteredQuotesData,
              ...data.data,
            ];
          } else if (tableType === 'task' && !appendData) {
            this.unFilteredQuotesData = data.data;
          }
          const tableData: { [key: string]: any }[] = data.data?.map(
            (dataObj, index: number) => {
              // if (!dataObj.policyPeriod.isRead) {
              //   this.filterTabs[this.currentFilterTabIndex].hasUnread = true;this.filterTabs[this.currentFilterTabIndex].hasUnread = true;
              // }
              const status = !dataObj.policyRiskTrxes?.quoteProcessStatus
                ? StatusMapping[
                    dataObj.policyRiskTrxes?.quoteStatus?.description.toLowerCase()
                  ]
                : dataObj.policyRiskTrxes?.quoteProcessStatus?.subdomaincode;
              let toReviewReferredBy = '';
              let pendingInfoReason = [];
              let warningArray = [];
              let londonReferralArray = [];
              if (status === QuoteFlowStatus.toReview) {
                toReviewReferredBy = this.getReferredBy(dataObj);
              } else if (status === QuoteFlowStatus.pendingInfo) {
                pendingInfoReason = this.getPendingInfoReasons(dataObj);
              }
              warningArray = this.getWarningArray(dataObj);

              let tableRowData = {};
              switch (this.filterTabs[this.currentFilterTabIndex].value) {
                case 'NEW_BUSINESS':
                  // Submission
                  tableRowData = {
                    'Quote ID': dataObj.policy.pkgPolicyNumber,
                    'Applicant name':
                      dataObj.policy.insured.InsuredType.subdomaincode ===
                      'ENTITYTYPE_COMPANY'
                        ? dataObj.policy.insured.companyName
                        : `${dataObj.policy.insured.firstName} ${dataObj.policy.insured.lastName}`,
                    Status: status,
                    Source: dataObj.policyPeriod.apiCreated ? 'API' : 'BOXX',
                    'Submission Date': {
                      date: getFormattedDateTime(
                        dataObj?.policy?.createdDt,
                        this.shortDateFormat_2,
                      ),
                      status:
                        warningArray.length > 0
                          ? QuoteFlowStatus.warning
                          : londonReferralArray.length > 0
                          ? QuoteFlowStatus.londonReferral
                          : '',
                      warningArray: warningArray,
                      londonReferralArray: londonReferralArray,
                    },
                    'Need by': !!dataObj.policy.needByDt
                      ? getFormattedDateTime(
                          dataObj.policy.needByDt,
                          this.shortDateFormat_2,
                        )
                      : !dataObj.policyPeriod.apiCreated
                      ? 'TBD'
                      : '-',
                    Industry: dataObj.policy.insured.industry.name ?? '',
                    Revenue: dataObj.policy.insured.revenue,
                    Broker: {
                      producer: `${dataObj.policy.brokerageProducerBOR.firstName} ${dataObj.policy.brokerageProducerBOR.lastName}`,
                      teamName:
                        dataObj?.policy?.brokerageProducerBOR
                          ?.BrokerageProducerTeams?.[0]?.brokerageBranchTeam
                          ?.name ?? '-',
                      branch: dataObj.policy.brokerageBranchBOR.name,
                    },
                    Brokerage: dataObj.policy.brokerageBOR.name,
                    isUnread: !dataObj.policyPeriod.isRead,
                    toReviewReferredBy: toReviewReferredBy,
                    pendingInfoReason: pendingInfoReason,
                    underwriterName:
                      dataObj?.underwriter[0]?.firstName +
                      ' ' +
                      dataObj?.underwriter[0]?.lastName,
                  };

                  break;
                case 'REFERRED_TASK':
                  // Referred
                  tableRowData = {
                    'Quote ID': dataObj.policy.pkgPolicyNumber,
                    'Applicant name':
                      dataObj.policy.insured.InsuredType.subdomaincode ===
                      'ENTITYTYPE_COMPANY'
                        ? dataObj.policy.insured.companyName
                        : `${dataObj.policy.insured.firstName} ${dataObj.policy.insured.lastName}`,
                    'Referred to': `${dataObj.policyPeriod.refferedTo.firstName} ${dataObj.policyPeriod.refferedTo.lastName}`,
                    Source: dataObj.policyPeriod.apiCreated ? 'API' : 'BOXX',
                    'Submission Date': {
                      date: getFormattedDateTime(
                        dataObj?.policy?.createdDt,
                        this.shortDateFormat_2,
                      ),
                      status:
                        warningArray.length > 0
                          ? QuoteFlowStatus.warning
                          : londonReferralArray.length > 0
                          ? QuoteFlowStatus.londonReferral
                          : '',
                      warningArray: warningArray,
                      londonReferralArray: londonReferralArray,
                    },
                    'Quote Date': {
                      date: getFormattedDateTime(
                        dataObj.policyRiskTrxes.quoteCreatedDt,
                        this.shortDateFormat_2,
                      ),
                    },
                    Industry: dataObj.policy.insured.industry.name ?? '',
                    Revenue: dataObj.policy.insured.revenue,
                    Broker: {
                      producer: `${dataObj.policy.brokerageProducerBOR.firstName} ${dataObj.policy.brokerageProducerBOR.lastName}`,
                      teamName:
                        dataObj?.policy?.brokerageProducerBOR
                          ?.BrokerageProducerTeams?.[0]?.brokerageBranchTeam
                          ?.name ?? '-',
                      branch: dataObj.policy.brokerageBranchBOR.name,
                    },
                    Brokerage: dataObj.policy.brokerageBOR.name,
                    isUnread: !dataObj.policyPeriod.isRead,
                    toReviewReferredBy: toReviewReferredBy,
                    pendingInfoReason: pendingInfoReason,
                    underwriterName:
                      dataObj?.underwriter[0]?.firstName +
                      ' ' +
                      dataObj?.underwriter[0]?.lastName,
                  };

                  break;
                case 'RENEWAL':
                  // Renewal
                  tableRowData = {
                    'Quote ID': dataObj.policy.pkgPolicyNumber,
                    'Applicant name':
                      dataObj.policy.insured.InsuredType.subdomaincode ===
                      'ENTITYTYPE_COMPANY'
                        ? dataObj.policy.insured.companyName
                        : `${dataObj.policy.insured.firstName} ${dataObj.policy.insured.lastName}`,
                    Status: status,
                    'Policy Period': `${getFormattedDateTime(
                      dataObj.policyPeriod.effectiveDt,
                      this.longDateFormat,
                    )}-${getFormattedDateTime(
                      dataObj.policyPeriod.expiryDt,
                      this.longDateFormat,
                    )}`,
                    Industry: dataObj.policy.insured.industry.name ?? '',
                    Limit: 'Limit',
                    Premium: 'Premium',
                    Broker: {
                      producer: `${dataObj.policy.brokerageProducerBOR.firstName} ${dataObj.policy.brokerageProducerBOR.lastName}`,
                      teamName:
                        dataObj?.policy?.brokerageProducerBOR
                          ?.BrokerageProducerTeams?.[0]?.brokerageBranchTeam
                          ?.name ?? '-',
                      branch: dataObj.policy.brokerageBranchBOR.name,
                    },
                    Brokerage: dataObj.policy.brokerageBOR.name,
                    isUnread: !dataObj.policyPeriod.isRead,
                    toReviewReferredBy: toReviewReferredBy,
                    pendingInfoReason: pendingInfoReason,
                    underwriterName:
                      dataObj?.underwriter[0]?.firstName +
                      ' ' +
                      dataObj?.underwriter[0]?.lastName,
                  };

                  break;
                case 'QUOTED':
                  // Quoted
                  const quoteTabStatus =
                    dataObj.policyRiskTrxes?.quoteProcessStatus &&
                    dataObj.policyRiskTrxes.quoteProcessStatus.description !==
                      'Pre Quote'
                      ? dataObj.policyRiskTrxes.quoteProcessStatus.subdomaincode
                      : StatusMapping[
                          dataObj.policyRiskTrxes?.quoteStatus?.description.toLowerCase()
                        ];

                  tableRowData = {
                    'Quote ID': dataObj.policy.pkgPolicyNumber,
                    'Applicant name':
                      dataObj.policy.insured.InsuredType.subdomaincode ===
                      'ENTITYTYPE_COMPANY'
                        ? dataObj.policy.insured.companyName
                        : `${dataObj.policy.insured.firstName} ${dataObj.policy.insured.lastName}`,
                    Status: quoteTabStatus,
                    Source: dataObj.policyPeriod.apiCreated ? 'API' : 'BOXX',
                    'Submission Date': {
                      date: getFormattedDateTime(
                        dataObj?.policy?.createdDt,
                        this.shortDateFormat_2,
                      ),
                      status:
                        warningArray.length > 0
                          ? QuoteFlowStatus.warning
                          : londonReferralArray.length > 0
                          ? QuoteFlowStatus.londonReferral
                          : '',
                      warningArray: warningArray,
                      londonReferralArray: londonReferralArray,
                    },
                    'Quote Date': {
                      date: getFormattedDateTime(
                        dataObj.policyRiskTrxes.quoteCreatedDt,
                        this.shortDateFormat_2,
                      ),
                    },
                    Industry: dataObj.policy.insured.industry.name ?? '',
                    Revenue: dataObj.policy.insured.revenue,
                    Broker: {
                      producer: `${dataObj.policy.brokerageProducerBOR.firstName} ${dataObj.policy.brokerageProducerBOR.lastName}`,
                      teamName:
                        dataObj?.policy?.brokerageProducerBOR
                          ?.BrokerageProducerTeams?.[0]?.brokerageBranchTeam
                          ?.name ?? '-',
                      branch: dataObj.policy.brokerageBranchBOR.name,
                    },
                    Brokerage: dataObj.policy.brokerageBOR.name,
                    isUnread: !dataObj.policyPeriod.isRead,
                    toReviewReferredBy: toReviewReferredBy,
                    pendingInfoReason: pendingInfoReason,
                    underwriterName:
                      dataObj?.underwriter[0]?.firstName +
                      ' ' +
                      dataObj?.underwriter[0]?.lastName,
                  };

                  break;
              }

              return tableRowData;
            },
          );

          if (pageNumber === 1) {
            this.filterTabs[this.currentFilterTabIndex].hasUnread =
              tableData.some((item) => item['isUnread']);
          }
          this.newTableData = tableData;

          if (tableType === 'task') {
            this.taskPagination = data.pagination;
          } else {
            this.followupPagination = data.pagination;
          }

          this.handleDataChange(scrollDirection, tableType, appendData);
        } else {
          this.filterTabs.map((tab: FilterTab, index: number) => {
            if (tab.value === 'NEW_BUSINESS') {
              const newBusinessData = data?.data?.new_business ?? {
                count: 0,
                isRead: true,
              };
              this.filterTabs[index].count = newBusinessData.count;
              this.filterTabs[index].hasUnread = !newBusinessData.isRead;
            } else if (tab.value === 'REFERRED_TASK') {
              const referredData = data?.data?.referred ?? {
                count: 0,
                isRead: true,
              };
              this.filterTabs[index].count = referredData.count;
              this.filterTabs[index].hasUnread = !referredData.isRead;
            } else if (tab.value === 'RENEWAL') {
              const renewalData = data?.data?.renewal ?? {
                count: 0,
                isRead: true,
              };
              this.filterTabs[index].count = renewalData.count;
              this.filterTabs[index].hasUnread = !renewalData.isRead;
            } else if (tab.value === 'QUOTED') {
              const quotedData = data?.data?.quoted ?? {
                count: 0,
                isRead: true,
              };
              this.filterTabs[index].count = quotedData.count;
              this.filterTabs[index].hasUnread = !quotedData.isRead;
            }
          });
          this.filterTabsCountLoader = false;
        }
      },
      error: (error) => {
        if (!isDashboardCount) {
          if (tableType === 'task') {
            this.showTaskTblSpinner = false;
            this.taskTableHeaders = Object.keys(
              this.filterTabs[this.currentFilterTabIndex].taskTableHeaders,
            );
          } else {
            this.showFollowupTblSpinner = false;
            this.followupTableHeaders = this.followupTableHeadersCopy;
          }
        }
        this.filterTabsCountLoader = false;

        this.sharedFunctionService.triggerEvent({
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: getErrorMessage(error),
        });
      },
    });
  }

  getInsuredType() {
    let insuredType = 1;
    if (this.isInsuredTypeCompany) {
      insuredType = 2;
    }
    return insuredType;
  }

  makeFilter() {
    let basicFilter = {};

    if (!this.showingFilteredResults) {
      basicFilter = {
        underwriterId: this.boxxUserId,
        isDashboard: this.filterTabs[this.currentFilterTabIndex].value,
      };
    } else {
      const filterOptions = this.filterSelectedOptions;

      basicFilter = {
        ...basicFilter,
        underwriterId: this.boxxUserId,
      };

      const needByDt = filterOptions?.needByDt;
      if (needByDt && needByDt?.from && needByDt?.to) {
        const formattedFromDate = getDateString(
          needByDt.from,
          this.shortDateFormat,
        );
        const formattedToDate = getDateString(
          needByDt.to,
          this.shortDateFormat,
        );
        const needByDtRange = `${formattedFromDate},${formattedToDate}`;
        basicFilter = {
          ...basicFilter,
          needByDt: needByDtRange,
        };
      }

      const quoteProcessStatus = filterOptions?.quoteProcessStatus;
      if (quoteProcessStatus && quoteProcessStatus.length > 0) {
        const quoteProcessStatusIds = filterOptions?.quoteProcessStatus
          .map((statusObj) => statusObj.id)
          .join(',');
        basicFilter = {
          ...basicFilter,
          quoteProcessStatus: quoteProcessStatusIds,
        };
      }

      const brokerage = filterOptions?.brokerage;
      if (brokerage && brokerage.length > 0) {
        const brokerageIds = filterOptions?.brokerage
          .map((brokerObj) => brokerObj.id)
          .join(',');
        basicFilter = {
          ...basicFilter,
          brokerageBORId: brokerageIds,
        };
      }

      const submissionDt = filterOptions?.submissionDt;
      if (submissionDt && submissionDt?.from && submissionDt?.to) {
        const formattedFromDate = getDateString(
          submissionDt.from,
          this.shortDateFormat,
        );
        const formattedToDate = getDateString(
          submissionDt.to,
          this.shortDateFormat,
        );
        const submissionDtRange = `${formattedFromDate},${formattedToDate}`;
        basicFilter = {
          ...basicFilter,
          createdDt: submissionDtRange,
        };
      }
      const revenue = filterOptions?.revenue;
      if (revenue && Number(revenue?.from) !== 0 && Number(revenue?.to) !== 0) {
        const formattedFrom =
          this.revenueOptions.find((x) => x.id == revenue.from)?.value ??
          revenue.from;
        const formattedTo =
          this.revenueOptions.find((x) => x.id == revenue.to)?.value ??
          revenue.to;
        const revenueRange = `${formattedFrom},${formattedTo}`;
        basicFilter = {
          ...basicFilter,
          revenue: revenueRange,
        };
      }

      const industry = filterOptions?.industry;
      if (industry && industry.length > 0) {
        const industryIds = filterOptions?.industry
          .map((industryObj) => industryObj.id)
          .join(',');
        basicFilter = {
          ...basicFilter,
          industryId: industryIds,
        };
      }
    }
    this.isRestrictedRoleSubmission &&
      basicFilter['underwriterId'] &&
      delete basicFilter['underwriterId'];

    return basicFilter;
  }

  handleFilterTab(filterTab: any): void {
    let disabledTabs = ['Renewals'];
    if (disabledTabs.includes(filterTab.name)) return;
    this.filterTabs = this.filterTabs.map((tab: FilterTab, key: number) => {
      if (tab.name === filterTab.name) {
        this.currentFilterTabIndex = key;
        return { ...tab, active: true };
      } else {
        return { ...tab, active: false };
      }
    });
    this.clearTaskTable();

    this.filterTabsCountLoader = true;

    let filter = {
      tableType: 'task',
      pageNumber: 1,
      limit: 10,
      sort: '',
      scrollDirection: 'scrollingDown',
      isTeamsQueue: this.isTeamsQueue,
    };
    filter['insuredName'] && delete filter['insuredName'];
    filter['insuredName'] = this.searchQuery;

    this.getTableData({ ...filter, isDashboardCount: true });
    this.getTableData(filter);
  }

  clearTaskTable() {
    this.taskTableData = [];
    this.taskPagination = {
      currentPage: 1,
      totalPages: 0,
      totalRecords: 0,
      hasNext: false,
      hasPrevious: false,
    };
  }

  getTableColumns(tableType: string): void {
    if (tableType === 'task') {
      this.taskTableHeaders =
        this.newTableData.length > 0
          ? Object.keys(this.newTableData[0])
          : Object.keys(
              this.filterTabs[this.currentFilterTabIndex].taskTableHeaders,
            );
    }
    if (tableType === 'followup') {
      this.followupTableHeaders =
        this.newTableData.length > 0
          ? Object.keys(this.newTableData[0])
          : this.followupTableHeadersCopy;
    }
  }

  async getTableData(
    filter: {
      tableType: string;
      pageNumber: number;
      limit: number;
      sort: string;
      scrollDirection: string;
      isDashboardCount?: boolean;
      isTeamsQueue: boolean;
    },
    appendData: boolean = false,
  ) {
    if (filter.tableType === 'task') {
      this.showTaskTblSpinner = true;
      if (filter?.isDashboardCount) {
        this.filterTabsCountLoader = true;
      }
      await this.loadResults(filter, appendData);
    }
    if (filter.tableType === 'followup') {
      this.showFollowupTblSpinner = true;
      await this.loadResults(filter, appendData);
    }
  }

  getSortKeyForColumn(column: string, sortOrder: string): string {
    if (!column || !sortOrder || column === '' || sortOrder === '') {
      return '';
    }
    let keySplit: string[] =
      this.filterTabs[this.currentFilterTabIndex].taskTableHeaders[
        column
      ].split(',');
    let sortingKey: string = '';
    const keySplitLength = keySplit.length - 1;
    keySplit.map((sortKey: string, key: number) => {
      const appendKey = keySplitLength === key ? '' : ',';
      sortingKey += `${sortKey}:${sortOrder}${appendKey}`;
    });

    return sortingKey;
  }

  getTaskTableDataOnSort(filter: {
    page: number;
    sortColumn: string;
    sortOrder: string;
  }): void {
    let sortKey: string = this.getSortKeyForColumn(
      filter.sortColumn,
      filter.sortOrder,
    );

    this.getTableData({
      tableType: 'task',
      pageNumber: 1,
      limit: 10,
      sort: sortKey,
      scrollDirection: 'scrollingDown',
      isTeamsQueue: this.isTeamsQueue,
    });
  }
  getFollowupTableDataOnSort(filter: any): void {}

  async getTaskTableDataOnScroll({
    sortColumn,
    sortOrder,
    page,
    scrollDirection,
  }: any): Promise<void> {
    let sortKey: string = this.getSortKeyForColumn(sortColumn, sortOrder);
    const filter = {
      tableType: 'task',
      pageNumber: page,
      limit: 10,
      sort: sortKey,
      scrollDirection,
      isTeamsQueue: this.isTeamsQueue,
    };
    await this.getTableData(filter, true);
  }
  async getFollowupTableDataOnScroll({
    sortColumn,
    sortOrder,
    page,
    scrollDirection,
  }: any): Promise<void> {
    let sortKey: string = this.getSortKeyForColumn(sortColumn, sortOrder);
    const filter = {
      tableType: 'followup',
      pageNumber: page,
      limit: 10,
      sort: sortKey,
      scrollDirection,
      isTeamsQueue: this.isTeamsQueue,
    };
    await this.getTableData(filter, true);
  }

  handleDataChange(
    scrollDirection: string,
    tableType: string,
    appendData: boolean,
  ): void {
    if (tableType === 'task') {
      this.getTableColumns('task');
      this.filterTabs = this.filterTabs.map((tab: FilterTab) => {
        if (tab.active) {
          return { ...tab, count: this.taskPagination.totalRecords };
        } else {
          return { ...tab, active: false };
        }
      });
    } else {
      this.getTableColumns('followup');
    }

    if (scrollDirection === 'scrollingDown') {
      if (!appendData) {
        let scrollTable = document.getElementById(
          'scrollable-pagination-table-wrapper',
        );
        if (scrollTable) {
          scrollTable.scrollTop = 0;
        }
      }
      // Append new data to the end
      this.taskTableData = appendData
        ? [...this.taskTableData, ...this.newTableData]
        : this.newTableData;
      tableType == 'task'
        ? (this.showTaskTblSpinner = false)
        : (this.showFollowupTblSpinner = false);

      // Ensure the data array length does not exceed 20 by removing the first 10 items
      // if (this.taskTableData.length > 20) {
      //   this.taskTableData = this.taskTableData.slice(10);
      // }
    }
    // else if (scrollDirection === 'scrollingUp') {
    //   // Prepend new data to the beginning
    //   this.taskTableData = [...newTableData, ...this.taskTableData];

    //   // Ensure the data array length does not exceed 20 by removing the last 10 items
    //   if (this.taskTableData.length > 20) {
    //     this.taskTableData = this.taskTableData.slice(0, 20);
    //   }
    // }
  }

  async onInsuredSearch(event) {
    const value = event.target.value;
    this.searchQuery = event.target.value;
    let filter = {
      tableType: 'task',
      pageNumber: 1,
      limit: 10,
      sort: '',
      scrollDirection: 'scrollingDown',
      isTeamsQueue: this.isTeamsQueue,
    };
    filter['insuredName'] && delete filter['insuredName'];
    this.searchQuery !== '' && (filter['insuredName'] = this.searchQuery);
    // this.filteredOptions = [];
    // this.filterSelectedOptions = undefined;
    if (event.key == 'Enter' && value.length >= 3 && value.length <= 40) {
      // this.showBodySpinner = true;
      // this.insuredSearchFilterOn = true;
      this.filterTabsCountLoader = true;
      await this.loadResults({ ...filter, isDashboardCount: true }, false);
      await this.loadResults(filter, false);
      // this.showBodySpinner = false;
    } else if (
      value?.length === 0 ||
      (event.key == 'Enter' && value?.length < 3)
    ) {
      // this.insuredSearchFilterOn = false;
      this.filterTabsCountLoader = true;
      this.loadResults({ ...filter, isDashboardCount: true }, false);
      this.loadResults(filter, false);
    }
  }

  getTableDataOnRowClick({ rowData, column }: any): void {
    const quoteID = `${rowData?.['Quote ID']}`;
    if (column === 'Applicant name') {
      this.router.navigate(
        [`../${this.quoteFlowUrl}/new/product/form/${quoteID}`],
        {
          relativeTo: this.activatedRoute,
          skipLocationChange: true,
        },
      );
    }
  }

  getReferredBy(quoteData: any): string {
    return quoteData?.policyPeriod?.refferedBy
      ? quoteData?.policyPeriod?.refferedBy.firstName +
          ' ' +
          quoteData?.policyPeriod?.refferedBy.lastName
      : '';
  }
  getPendingInfoReasons(quoteData: any): string[] {
    return (
      quoteData?.policyRiskTrxes?.policyRiskTrxReason
        ?.filter(
          (reason) =>
            reason.reasonTypeDto.subdomaincode ===
            SUB_DOMAIN_CODE_REASONTYPE_PENDINGINFO,
        )
        .map((reason) => reason.comments || reason.reasonCodeDto.description) ??
      []
    );
  }
  getWarningArray(quoteData: any): string[] {
    let resultArray = [];
    if (quoteData?.policy?.ofacSanction) {
      resultArray.push('OFAC');
    }
    if (
      quoteData?.policy?.insured?.industry?.IndustryRestrictedCodes?.length > 0
    ) {
      resultArray.push('Restricted industry');
    }
    if (
      ['referral', 'referred'].includes(
        quoteData?.policyRiskTrxes?.quoteStatus?.description.toLowerCase(),
      ) &&
      quoteData?.policyRiskTrxes?.policyRiskTrxReason?.length > 0
    ) {
      const referralReasons = quoteData?.policyRiskTrxes?.policyRiskTrxReason
        ?.filter(
          (reason: any) =>
            reason.reasonTypeDto?.subdomaincode ===
            SUB_DOMAIN_CODE_REASONTYPE_REFERRAL,
        )
        .map((referralReason: any) => referralReason?.comments);
      resultArray = [...resultArray, ...referralReasons];
    }
    return resultArray;
  }

  handleStatusChanged({
    rowIndex,
    selectedReasonDesc,
    toStatus,
    removeRecordUI,
  }) {
    if (toStatus === 'Reassign' || toStatus === 'Refer') {
      // to remove the re assigned/ referred record from dashboard
      if (rowIndex !== -1) {
        // no need to remove record from table when - refer happens on teams queue
        // & and reassign to some other uw in teams queue
        if (removeRecordUI === true) {
          this.taskTableData && this.taskTableData?.splice(rowIndex, 1);
          this.unFilteredQuotesData &&
            this.unFilteredQuotesData?.splice(rowIndex, 1);
          if (this.filterTabs[this.currentFilterTabIndex].count)
            this.filterTabs[this.currentFilterTabIndex].count =
              Number(this.filterTabs[this.currentFilterTabIndex].count) - 1;
        }
        this.sharedFunctionService.triggerEvent({
          type: 'success',
          headerText: this.translateService.instant('common.successMsg'),
          bodyText:
            toStatus === 'Reassign'
              ? this.translateService.instant(
                  'dashboard.label.quoteReassignSuccessfulMsg',
                )
              : this.translateService.instant(
                  'dashboard.label.quoteReferSuccessfulMsg',
                ),
        });
      }
    } else if (toStatus === SUBDOMAINCODE_QUOTEFLOWSTATUS_TOREVIEW) {
      // to review - from teams queue when refer happends
      this.taskTableData[rowIndex] = {
        ...this.taskTableData[rowIndex],
        Status: QuoteFlowStatus.toReview,
        toReviewReferredBy: this.loggedInUserName,
      };
      this.unFilteredQuotesData[
        rowIndex
      ].policyRiskTrxes.quoteProcessStatus.description =
        QuoteFlowStatus.toReview;
      this.unFilteredQuotesData[
        rowIndex
      ].policyRiskTrxes.quoteProcessStatus.subdomaincode =
        SUBDOMAINCODE_QUOTEFLOWSTATUS_TOREVIEW;
      this.unFilteredQuotesData[rowIndex].policyPeriod.refferedBy.id =
        this.boxxUserId;
      this.unFilteredQuotesData[rowIndex].policyPeriod.refferedBy.firstName =
        this.loggedInUserName;
    } else {
      // pending info
      this.taskTableData[rowIndex] = {
        ...this.taskTableData[rowIndex],
        Status: QuoteFlowStatus.pendingInfo,
      };

      this.unFilteredQuotesData[rowIndex].policyRiskTrxes.policyRiskTrxReason =
        [
          {
            comments: selectedReasonDesc,
            reasonTypeDto: {
              subdomaincode: SUB_DOMAIN_CODE_REASONTYPE_PENDINGINFO,
            },
          },
        ];
      this.unFilteredQuotesData[
        rowIndex
      ].policyRiskTrxes.quoteProcessStatus.description =
        QuoteFlowStatus.pendingInfo;
      this.unFilteredQuotesData[
        rowIndex
      ].policyRiskTrxes.quoteProcessStatus.subdomaincode =
        SUBDOMAINCODE_QUOTEFLOWSTATUS_PENDINGINFO;
    }
  }

  handleExport() {
    this.showTaskTblSpinner = false;
    //console.log(this.filterTabs[this.currentFilterTabIndex].count);
    this.filterParamsForExport.limit = this.filterTabs[
      this.currentFilterTabIndex
    ].count
      ? this.filterTabs[this.currentFilterTabIndex].count
      : 1000;
    this.policyDashboardService
      .dashboardExport(this.filterParamsForExport)
      .subscribe(
        (data: ArrayBuffer) => {
          excelFileDownload('ExportedData.xlsx', data);
          this.showTaskTblSpinner = false;
        },
        (error) => {
          this.showTaskTblSpinner = false;
          const alertData = {
            show: true,
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: getErrorMessage(error),
          };
          this.alertService.addAlert(alertData);
        },
      );
  }

  handleCloseFilterPopup(event) {
    this.showFilter = false;
    if (!this.showingFilteredResults) {
      this.dashboardFilterForm.reset();
    }
  }

  async handleDashboardFiltering(filterData) {
    this.resetSort = !this.resetSort;
    this.showingFilteredResults = true;
    this.currentFilterTabIndex = 0;
    this.filterSelectedOptions = filterData;
    let filter: any = {
      tableType: 'task',
      pageNumber: 1,
      limit: 10,
      sort: '',
      scrollDirection: 'scrollingDown',
    };

    filter = {
      ...filter,
      isTeamsQueue: this.isTeamsQueue ? true : false,
    };

    await this.loadResults(filter, false);
    this.resetSort = !this.resetSort;
    this.showFilter = false;
  }

  dynamicSearchFilter(e) {
    switch (e.item.selectedInputValue) {
      case 'brokerage':
        this.searchBrokerage(e.search);
        break;
      case 'revenue':
      case 'needByDt':
      case 'submissionDt':
      case 'quoteProcessStatus':
        break;
      case 'industry':
        this.searchIndustry(e.search);
        break;

      default:
        break;
    }
  }
  dynamicLoadMore(e) {
    switch (e.selectedInputValue) {
      case 'underwriter':
        // this.loadMoreUnderWriter();
        break;
      case 'brokerage':
        this.loadMoreBrokerage();
        break;
      case 'premiumRange':
        break;
      case 'industry':
        this.loadMoreIndustries();
        break;
      case 'status':
        break;
      case 'createdDt':
        break;
      case 'insuredName':
        break;
      case 'effectiveDt':
        break;
      default:
    }
  }

  async handleFilterClear() {
    this.resetSort = !this.resetSort;
    this.showTaskTblSpinner = true;
    this.filterTabsCountLoader = true;
    this.filterSelectedOptions = undefined;
    this.dashboardFilterForm.reset();
    this.showingFilteredResults = false;
    let dataFilter: any = {
      tableType: 'task',
      pageNumber: 1,
      limit: 10,
      sort: '',
      scrollDirection: 'scrollingDown',
      isTeamsQueue: this.isTeamsQueue,
    };
    const dataCountFilter = {
      tableType: 'task',
      pageNumber: 1,
      limit: 10,
      sort: '',
      scrollDirection: 'scrollingDown',
      isDashboardCount: true,
      isTeamsQueue: this.isTeamsQueue,
    };
    await this.loadResults(dataCountFilter, false);
    this.showTaskTblSpinner = true;
    this.filterTabsCountLoader = true;
    await this.loadResults(dataFilter, false);
    this.showTaskTblSpinner = false;

    // this.filterSelectedOptions = {};
    // this.filterSelectedOptions = e;
    // this.loadResults(1, 10, this.sortingKeys);
  }

  async handleFilter() {
    if (this.showTaskTblSpinner || this.filterTabsCountLoader) {
      return;
    }

    if (!this.showingFilteredResults) {
      await this.populateRevenueOptions();
      await this.populateFilterOptions();
      await this.createFilterFormGroup();
    }

    this.showFilter = true;
    // this.renderer.addClass(document.body, 'no-scroll');
  }
  filterSelectedHasValue() {
    let flag = 0;
    if (this.filterSelectedOptions) {
      if (this.filterSelectedOptions.brokerageBranchProd) {
        flag +=
          this.filterSelectedOptions.brokerageBranchProd.length > 0 ? 1 : 0;
      }
      if (this.filterSelectedOptions.risks) {
        flag += this.filterSelectedOptions.risks.length > 0 ? 1 : 0;
      }
      if (this.filterSelectedOptions.status) {
        flag += this.filterSelectedOptions.status.length > 0 ? 1 : 0;
      }
      if (this.filterSelectedOptions.underwriter) {
        flag += this.filterSelectedOptions.underwriter.length > 0 ? 1 : 0;
      }
      if (this.filterSelectedOptions.insuredName) {
        flag += this.filterSelectedOptions.insuredName !== '' ? 1 : 0;
      }
      if (this.filterSelectedOptions.premiumRange) {
        if (
          this.filterSelectedOptions.premiumRange.from !== null &&
          this.filterSelectedOptions.premiumRange.from !== undefined &&
          this.filterSelectedOptions.premiumRange.from !== '' &&
          this.filterSelectedOptions.premiumRange.to !== null &&
          this.filterSelectedOptions.premiumRange.to !== undefined &&
          this.filterSelectedOptions.premiumRange.to !== ''
        ) {
          flag++;
        }
      }
      if (this.filterSelectedOptions.effectiveDt) {
        if (
          this.filterSelectedOptions.effectiveDt.from &&
          this.filterSelectedOptions.effectiveDt.from !== '' &&
          this.filterSelectedOptions.effectiveDt.to &&
          this.filterSelectedOptions.effectiveDt.to !== ''
        ) {
          flag++;
        }
      }
      if (this.filterSelectedOptions.createdDt) {
        if (
          this.filterSelectedOptions.createdDt.from &&
          this.filterSelectedOptions.createdDt.from !== '' &&
          this.filterSelectedOptions.createdDt.to &&
          this.filterSelectedOptions.createdDt.to !== ''
        ) {
          flag++;
        }
      }
    }
    if (flag > 0) {
      return true;
    } else {
      return false;
    }
  }
  async populateFilterOptions() {
    // let activeTab = this.subNavData.find((x) => x.active);
    if (this.filterSelectedHasValue()) {
      let defaultOptions = 0;
      let filteredOptions = this.filterInputOptions.map((option) => {
        const { value } = option;
        const selectedValue = this.filterSelectedOptions[value];
        if (option.type === 'dateRange' || option.type === 'dropdownRange') {
          if (
            selectedValue.from !== null &&
            selectedValue.from !== undefined &&
            selectedValue.from !== '' &&
            selectedValue.to !== null &&
            selectedValue.to !== undefined &&
            selectedValue.to !== ''
          ) {
            defaultOptions++;
            return { ...option, selectedValue, isDefault: true };
          }
        } else if (
          option.type === 'multiDropdown' ||
          option.type === 'multiSearch'
        ) {
          if (selectedValue && selectedValue.length > 0) {
            defaultOptions++;
            return { ...option, selectedValue, isDefault: true };
          }
        } else if (
          selectedValue !== null &&
          selectedValue !== undefined &&
          selectedValue !== ''
        ) {
          defaultOptions++;
          return { ...option, selectedValue, isDefault: true };
        }

        return { ...option, selectedValue, isDefault: false };
      });
      if (defaultOptions < 3) {
        filteredOptions = filteredOptions.map((option) => {
          if (defaultOptions < 3) {
            if (!option.isDefault) {
              defaultOptions++;
              return { ...option, isDefault: true };
            }
          }
          return { ...option };
        });
      }

      this.filterInputOptions = filteredOptions;
    } else {
      this.filterInputOptions = [
        {
          label: 'Need by Date',
          value: 'needByDt',
          type: 'dateRange',
          disabled: false,
          isDefault: true,
          selectedValue: '',
          isRequired: false,
          hasValidation: true,
        },
        // {
        //   label: 'Insured name',
        //   value: 'insuredName',
        //   type: 'text',
        //   disabled: false,
        //   selectedValue: '',
        //   isDefault: true,
        //   isRequired: false,
        //   hasValidation: false,
        // },
        // {
        //   label: 'Date created',
        //   value: 'createdDt',
        //   type: 'dateRange',
        //   disabled: false,
        //   isDefault: true,
        //   selectedValue: '',
        //   isRequired: false,
        //   hasValidation: true,
        // },
        {
          label: 'Status',
          value: 'quoteProcessStatus',
          type: 'multiDropdown',
          disabled: false,
          isDefault: true,
          selectedValue: '',
          dropdownOptions: this.statusOptions,
          isRequired: false,
          hasValidation: false,
        },
        // {
        //   label: 'Products',
        //   value: 'risks',
        //   type: 'multiDropdown',
        //   disabled: false,
        //   isDefault: false,
        //   selectedValue: '',
        //   dropdownOptions: this.pdtOptions,
        //   isRequired: false,
        //   hasValidation: false,
        // },
        // {
        //   label: 'Annual premium',
        //   value: 'premiumRange',
        //   type: 'dropdownRange',
        //   disabled: false,
        //   isDefault: false,
        //   selectedValue: '',
        //   isRequired: false,
        //   hasValidation: true,
        //   dropdownOptions: this.premiumOptions,
        //   min: 0,
        //   max: 1000000,
        //   minMaxErrmsg: 'Values should be between the range of 0 to 1M',
        // },
        {
          label: 'Brokerage',
          value: 'brokerage',
          type: 'multiSearch',
          disabled: false,
          isDefault: true,
          selectedValue: '',
          dropdownOptions: this.brokerageOptions,
          isRequired: false,
          hasValidation: false,
        },
        {
          label: 'Submission Date',
          value: 'submissionDt',
          type: 'dateRange',
          disabled: false,
          isDefault: false,
          selectedValue: '',
          isRequired: false,
          hasValidation: true,
        },
        {
          label: 'Revenue',
          value: 'revenue',
          type: 'dropdownRange',
          disabled: false,
          isDefault: false,
          selectedValue: '',
          isRequired: false,
          hasValidation: true,
          dropdownOptions: this.revenueOptions,
          min: 0,
          max: 9999999999,
          minMaxErrmsg: 'Values should be between the range of 0 to 9999999999',
        },
        {
          label: 'Industry',
          value: 'industry',
          type: 'multiSearch',
          disabled: false,
          isDefault: false,
          selectedValue: '',
          dropdownOptions: this.industryOptions,
          isRequired: false,
          hasValidation: false,
        },
        // {
        //   label: 'Underwriter',
        //   value: 'underwriter',
        //   type: 'multiSearch',
        //   disabled: false,
        //   isDefault: false,
        //   selectedValue: '',
        //   dropdownOptions: this.underWriterOptions,
        //   isRequired: false,
        //   hasValidation: false,
        // },
      ];
    }
  }
  createFilterFormGroup() {
    const formGroup = {};
    this.filterInputOptions.forEach((control) => {
      const isRequired = control.hasValidation ? Validators.required : '';
      let selectedValue;
      switch (control.type) {
        case 'text':
          selectedValue = control.selectedValue ? control.selectedValue : '';
          formGroup[control.value] = [selectedValue, isRequired];
          break;
        case 'number':
          selectedValue = control.selectedValue ? control.selectedValue : '';
          formGroup[control.value] = [selectedValue, isRequired];
          break;
        case 'dropdown':
          selectedValue = control.selectedValue ? control.selectedValue : '';
          formGroup[control.value] = [selectedValue, isRequired];
          break;
        case 'dateRange':
          selectedValue = control.selectedValue
            ? control.selectedValue
            : { from: '', to: '' };
          formGroup[control.value] = this.fb.group({
            from: [selectedValue.from],
            to: [selectedValue.to],
          });
          break;
        case 'dropdownRange':
          selectedValue = control.selectedValue
            ? control.selectedValue
            : { from: '', to: '' };
          formGroup[control.value] = this.fb.group({
            from: [selectedValue.from],
            to: [selectedValue.to],
          });
          break;
        case 'multiDropdown':
          selectedValue = control.selectedValue ? control.selectedValue : [];
          formGroup[control.value] = [selectedValue, isRequired];
          break;
        case 'multiSearch':
          selectedValue = control.selectedValue ? control.selectedValue : [];
          formGroup[control.value] = [selectedValue, isRequired];
          break;
        default:
          selectedValue = control.selectedValue ? control.selectedValue : '';
          formGroup[control.value] = [selectedValue, isRequired];
      }
    });
    this.dashboardFilterForm = this.fb.group(formGroup);
  }

  async setQuoteStatusOptions() {
    const getDomainsAPI = this.domainsService.GetByDomainCode(
      DOMAIN_CODE_QUOTEFLOWSTATUS,
    );
    const quoteFlowStatusResponse = await firstValueFrom(getDomainsAPI);

    this.statusOptions = quoteFlowStatusResponse.data.map((dataObj) => ({
      key: dataObj.description,
      value: dataObj.description,
      name: dataObj.description,
      status: this.getStatusForQuoteStatus(dataObj.description),
      seqId: dataObj.seqId,
      id: dataObj.id,
    }));
  }

  getStatusForQuoteStatus(status) {
    switch (status?.toLowerCase()) {
      case 'pre quote':
      case 'approved':
        return 'info-style-2';
      case 'pending info':
      case 'to review':
        return 'warning-style-2';
      default:
        return status;
    }
  }

  async OpenFilter() {
    if (this.showFilter) {
      return;
    }
    if (!this.showingFilteredResults) {
      await this.setQuoteStatusOptions();
      await this.handleFilter();
    } else {
      this.showFilter = true;
    }
  }

  searchBrokerage(value) {
    if (value.length >= 3) {
      this.getBrokerageDirectories(value);
    } else {
      this.brokerageOptions = [];
    }
  }
  getBrokerageDirectories(value = '', page = 1, isLoadMore = false) {
    this.brokerageDirectoryService
      .GetBrokerageDirectories(page, 10, '', true, false, false, value)
      .subscribe((data) => {
        const tableData = data.data?.map((dataObj) => {
          return {
            name: dataObj.brokerage.name,
            id: dataObj.id,
            type: dataObj.entity,
          };
        });
        if (isLoadMore) {
          this.brokerageOptions = [...this.brokerageOptions, ...tableData];
        } else {
          this.brokerageOptions = tableData;
        }
        let ind = this.filterInputOptions.findIndex(
          (x) => x.value === 'brokerage',
        );
        this.filterInputOptions[ind].dropdownOptions = [
          ...this.brokerageOptions,
        ];
        this.filterInputOptions = [...this.filterInputOptions];

        this.brokerageDirectorySearch = value;
        this.brokerageDirectorCurrentPage = data.pagination.currentPage;
        this.brokerageDirectoryTotalRecords = data.pagination.totalRecords;
      });
  }

  loadMoreBrokerage() {
    if (this.brokerageOptions.length < this.brokerageDirectoryTotalRecords) {
      let page = this.brokerageDirectorCurrentPage + 1;
      this.getBrokerageDirectories(this.brokerageDirectorySearch, page, true);
    }
  }

  populateRevenueOptions() {
    const revenueOptions = [];
    for (let i = 0; i <= 10; i += 1) {
      revenueOptions.push({
        key: i * 100000,
        value: i * 100000,
        id: i,
      });
    }
    let length = revenueOptions.length;
    revenueOptions.push({
      key: 'Custom',
      subKey: '',
      value: 'custom-amount',
      id: length + 1,
    });
    this.revenueOptions = revenueOptions;
  }

  searchIndustry(value) {
    if (value.length >= 3) {
      this.getIndustries(value);
    } else {
      this.industryOptions = [];
    }
  }
  getIndustries(value = '', page = 1, isLoadMore = false) {
    this.industryService.GetByName(value, page, 10).subscribe((data) => {
      const tableData = data.data?.map((dataObj) => {
        return {
          name: dataObj.name,
          id: dataObj.id,
          // type: dataObj.entity,
        };
      });
      if (isLoadMore) {
        this.industryOptions = [...this.industryOptions, ...tableData];
      } else {
        this.industryOptions = tableData;
      }
      let ind = this.filterInputOptions.findIndex(
        (x) => x.value === 'industry',
      );
      this.filterInputOptions[ind].dropdownOptions = [...this.industryOptions];
      this.filterInputOptions = [...this.filterInputOptions];

      this.industrySearch = value;
      this.industryCurrentPage = data.pagination.currentPage;
      this.industryTotalRecords = data.pagination.totalRecords;
    });
  }

  loadMoreIndustries() {
    if (this.industryOptions.length < this.industryTotalRecords) {
      let page = this.industryCurrentPage + 1;
      this.getIndustries(this.industrySearch, page, true);
    }
  }

  getFilteredValues() {
    let filteredOptions = [];

    if (this.filterSelectedOptions) {
      if (this.filterSelectedOptions.quoteProcessStatus?.length > 0) {
        let status = this.filterSelectedOptions.quoteProcessStatus;
        status.forEach((b) => {
          if (b.fromNav) {
            return;
          }
          filteredOptions.push({ key: 'quoteProcessStatus', value: b.name });
        });
      }
      if (this.filterSelectedOptions?.brokerage) {
        const brokerVal = this.filterSelectedOptions?.brokerage;
        if (brokerVal?.length > 0) {
          const brokerageNames = brokerVal.map((b) => b.name);
          filteredOptions.push({
            key: 'brokerage',
            value: brokerageNames?.join(','),
            label: 'Brokerage:',
          });
        }
      }
      if (this.filterSelectedOptions.needByDt) {
        const needByDt = this.filterSelectedOptions.needByDt;
        let needByDtRange = '';
        if (!!needByDt.from && !!needByDt.to) {
          const formattedFromDate = formatDateMoment(
            needByDt.from,
            this.shortDateFormat,
          );
          const formattedToDate = formatDateMoment(
            needByDt.to,
            this.shortDateFormat,
          );
          needByDtRange = `${formattedFromDate} to ${formattedToDate}`;
          filteredOptions.push({
            key: 'needByDt',
            value: needByDtRange,
            label: 'Need by:',
          });
        }
      }
      if (this.filterSelectedOptions.industry) {
        const industry = this.filterSelectedOptions.industry;
        if (industry?.length > 0) {
          const industryNames = industry.map((industry) => industry.name);
          filteredOptions.push({
            key: 'industry',
            value: industryNames.join(','),
            label: 'Industry:',
          });
        }
      }
      if (this.filterSelectedOptions.submissionDt) {
        const submissionDt = this.filterSelectedOptions.submissionDt;
        let submissionDtRange = '';
        if (!!submissionDt.from && !!submissionDt.to) {
          const formattedFromDate = formatDateMoment(
            submissionDt.from,
            this.shortDateFormat,
          );
          const formattedToDate = formatDateMoment(
            submissionDt.to,
            this.shortDateFormat,
          );
          submissionDtRange = `${formattedFromDate} - ${formattedToDate}`;
          filteredOptions.push({
            key: 'submissionDt',
            value: submissionDtRange,
            label: 'Submission Date:',
          });
        }
      }
      if (this.filterSelectedOptions.revenue) {
        const revenue = this.filterSelectedOptions.revenue;
        let revenueRange = '';
        let formattedFrom = revenue?.from;
        let formattedTo = revenue?.to;
        if (!isNaN(revenue?.from)) {
          formattedFrom =
            this.revenueOptions.find((x) => x.id == revenue.from)?.value ??
            revenue.from;
          formattedTo =
            this.revenueOptions.find((x) => x.id == revenue.to)?.value ??
            revenue.to;
        } else {
          formattedFrom = Number(revenue?.from);
          formattedTo = Number(revenue?.to);
        }
        if (!!formattedFrom && !!formattedTo) {
          revenueRange = `${this.abbreviateNumber.transform(
            Number(formattedFrom),
            2,
          )} to ${this.abbreviateNumber.transform(Number(formattedTo), 2)}`;
          filteredOptions.push({
            key: 'revenue',
            value: revenueRange,
            label: 'Rev:',
          });
        }
      }

      this.filteredOptions = filteredOptions;
    }

    return filteredOptions;
  }

  async handleFilterOptionRemove(filterToRemove) {
    if (this.filterSelectedOptions.hasOwnProperty(filterToRemove.key)) {
      switch (filterToRemove.key) {
        case 'needByDt':
        case 'brokerage':
        case 'submissionDt':
        case 'revenue':
        case 'industry':
          const keyValue = filterToRemove.key;
          const { [keyValue]: _, ...newFilter } = this.filterSelectedOptions;
          this.filterSelectedOptions = newFilter;
          this.dashboardFilterForm.get(keyValue)?.reset();
          break;

        case 'quoteProcessStatus':
          let statusIndex = this.filterSelectedOptions[
            filterToRemove.key
          ].findIndex(
            (x) =>
              x.value?.toLowerCase() === filterToRemove.value?.toLowerCase(),
          );
          if (statusIndex >= 0) {
            this.filterSelectedOptions[filterToRemove.key].splice(
              statusIndex,
              1,
            );
          }
          break;
        default:
          break;
      }
      if (!this.checkIfFilteredOptionsHasValue(this.filterSelectedOptions)) {
        this.handleFilterClearAll();
      } else {
        this.handleDashboardFiltering(this.dashboardFilterForm.value);
      }
    }
    this.resetSort = !this.resetSort;
  }

  handleFilterClearAll() {
    this.resetSort = !this.resetSort;
    this.handleFilterClear();
  }

  checkIfFilteredOptionsHasValue(data: any): boolean {
    const hasValue = (obj: any): boolean => {
      if (Array.isArray(obj)) {
        return obj.some((item) => hasValue(item));
      } else if (typeof obj === 'object' && obj !== null) {
        return Object.values(obj).some((value) => hasValue(value));
      } else {
        return obj !== '' && obj !== null && obj !== undefined;
      }
    };
    return hasValue(data);
  }
}
