import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { BrokerageProfile } from '../entities/brokerage-profile';
import { environment } from 'src/environments/environment';
import { BoxxResponse } from '../entities/boxx-response';

@Injectable({
  providedIn: 'root',
})
export class BrokerageProfileService {
  // Base url
  baseurl = environment.msBrokerBaseUrl + environment.brokerageProfilePathUrl;
  constructor(private http: HttpClient) {}
  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  // POST
  Create(data): Observable<BrokerageProfile> {
    return this.http
      .post<BrokerageProfile>(
        this.baseurl,
        JSON.stringify(data),
        this.httpOptions,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }
  // GET
  Get(id): Observable<BoxxResponse<BrokerageProfile[]>> {
    return this.http
      .get<BoxxResponse<BrokerageProfile[]>>(
        this.baseurl + '?brokerageId=' + id,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }
  // GET
  GetALL(): Observable<BrokerageProfile> {
    return this.http
      .get<BrokerageProfile>(this.baseurl + '/bugtracking/')
      .pipe(retry(1), catchError(this.errorHandl));
  }
  // PUT
  Update(id, data): Observable<BrokerageProfile> {
    return this.http
      .put<BrokerageProfile>(
        this.baseurl + '/bugtracking/' + id,
        JSON.stringify(data),
        this.httpOptions,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }
  // DELETE
  Delete(id) {
    return this.http
      .delete<BrokerageProfile>(
        this.baseurl + '/bugtracking/' + id,
        this.httpOptions,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  UpdateLogo(
    brokerageProfileId: number,
    data: any,
  ): Observable<BoxxResponse<BrokerageProfile>> {
    const url = `${environment.msBrokerBaseUrl}${environment.brokerageProfilePathUrl}${brokerageProfileId}`;

    return this.http.put<BoxxResponse<BrokerageProfile>>(
      url,
      JSON.stringify(data),
      this.httpOptions,
    );
  }

  // Error handling
  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return throwError(() => {
      return errorMessage;
    });
  }
}
