import {
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  PopoverV2Component,
  popOverV2Content,
} from '../popover-v2/popover-v2.component';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.less'],
})
export class ProductCardComponent implements OnInit, OnChanges {
  @Input() detail: any;
  @Input() selectedProduct;
  @Input() isDisabled = false;
  @Output() selectProduct = new EventEmitter();

  isSelected: boolean = false;

  insuredTypeForm: FormGroup<any>;
  radioBusiness = [];

  private popupComponentRef: ComponentRef<PopoverV2Component>;
  openPopover = [];
  popOverContent = {
    coverage: popOverV2Content.COVERAGES,
    service: popOverV2Content.SERVICES,
    both: popOverV2Content.BOTH,
  };

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.insuredTypeForm = this.fb.group({
      insured_type: ['business'],
    });
  }

  ngOnInit(): void {
    if (this.selectedProduct !== undefined && this.selectedProduct !== null) {
      this.insuredTypeForm.controls['insured_type'].setValue(
        this.selectedProduct,
      );
    }
    this.radioBusiness = [
      {
        label: '',
        value: this.detail.riskId,
        disabled: this.isDisabled,
      },
    ];
  }
  ngOnChanges(changes: SimpleChanges): void {
    if ('selectedProduct' in changes) {
      this.isSelected = this.selectedProduct == this.detail.riskId;
    }
    if ('isDisabled' in changes) {
      if (this.radioBusiness?.[0]?.disabled) {
        this.radioBusiness[0].disabled = this.isDisabled;
      }
    }
  }

  handleInsuredTypeChange() {
    this.selectProduct.emit(this.detail);
  }
  openPopup(event: MouseEvent, content?: popOverV2Content): void {
    let target = event.target as HTMLElement;

    while (target && !target.matches('span.coverages')) {
      target = target.parentElement;
    }
    const buttonRect = target.getBoundingClientRect();
    const width = content === popOverV2Content.SERVICES ? 494 : 528;
    let position = this.calculatePopupPosition(buttonRect, width);
    const popupPosition = {
      left: position.left,
      top: position.top,
      content: content,
      width: `${width}`,
    };

    this.showPopup(popupPosition);
  }

  calculatePopupPosition(buttonRect: DOMRect, width: number) {
    const popupWidth = width; // Width of the popup
    const offsetX = 30; // Horizontal offset from the button
    const offsetY = 10; // Vertical offset from the button
    let containerRect = document
      .getElementsByClassName(String(this.detail.riskId))[0]
      .getBoundingClientRect();
    // Calculate left position
    let popupLeft = buttonRect.left - containerRect.left;
    if (buttonRect.left + popupWidth > window.innerWidth) {
      let customOffset =
        popupWidth - window.innerWidth + buttonRect.left + offsetX;
      popupLeft = containerRect.right - buttonRect.left - customOffset;
    }

    // Calculate top position
    let popupTop = buttonRect.bottom + offsetY - containerRect.top;
    if (containerRect.top) {
      // popupTop -= containerRect.top;
      // popupTop += 2*containerRect.height;
    }

    return { left: popupLeft, top: popupTop };
  }
  showPopup(popupPosition: {
    left: number;
    top: number;
    content: popOverV2Content;
    width: string;
  }): void {
    for (let componentRef of this.openPopover) {
      componentRef.destroy();
    }
    this.popupComponentRef =
      this.viewContainerRef.createComponent(PopoverV2Component);
    this.openPopover.push(this.popupComponentRef);
    this.popupComponentRef.instance.left = popupPosition.left;
    this.popupComponentRef.instance.top = popupPosition.top;
    this.popupComponentRef.instance.contentType = popupPosition.content;
    this.popupComponentRef.instance.popupWidth = popupPosition.width;

    const popupContainer = document.getElementById('popup-container');
    let containerRect = document.getElementsByClassName(
      String(this.detail.riskId),
    )[0];
    containerRect.appendChild(this.popupComponentRef.location.nativeElement);
    this.addClickOutsideListener();
  }
  addClickOutsideListener(): void {
    document.addEventListener('click', this.handleOutsideClick);
  }

  removeClickOutsideListener(): void {
    document.removeEventListener('click', this.handleOutsideClick);
  }

  handleOutsideClick = (event: MouseEvent): void => {
    let te = event.target instanceof Element ? event.target.parentElement : '';
    if (
      this.popupComponentRef &&
      this.popupComponentRef.location.nativeElement &&
      !this.popupComponentRef.location.nativeElement.contains(event.target) &&
      event.target instanceof Element &&
      !(
        event.target.classList.contains('productPopover') ||
        event.target.parentElement.classList.contains('productPopover')
      )
    ) {
      this.popupComponentRef.destroy();
      this.removeClickOutsideListener();
    }
  };

  getProductDescription(desc: string) {
    const index = desc.indexOf('<div');
    if (index !== -1) {
      desc = desc.substring(0, index);
    }
    return desc;
  }
}
