import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BoxxResponse } from '../entities/boxx-response';
import { Policy } from '../entities/policy';
import { BaseService } from './base-services';

@Injectable({
  providedIn: 'root',
})
export class PolicyService extends BaseService<Policy> {
  constructor(http: HttpClient) {
    const baseurl = environment.msPolicyBaseUrl + environment.policyPathUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  GetAllByPolicyNumber(
    pkgPolicyNumber,
    page = 1,
    limit = 10,
    sort = '',
  ): Observable<BoxxResponse<Policy>> {
    return this.http
      .get<BoxxResponse<Policy>>(
        this.baseurl +
          '?pkgPolicyNumber=' +
          pkgPolicyNumber +
          '&page=' +
          page +
          '&limit=' +
          limit +
          '&active=1',
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetAllByInsuredName(
    insuredName,
    page = 1,
    limit = 10,
    sort = '',
  ): Observable<BoxxResponse<Policy>> {
    return this.http
      .get<BoxxResponse<Policy>>(
        this.baseurl +
          '?insuredName=' +
          insuredName +
          '&page=' +
          page +
          '&limit=' +
          limit +
          '&active=1',
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetByPolicyId(id: any): Observable<BoxxResponse<Policy>> {
    var url = `${this.baseurl}${id}`;
    return this.http
      .get<BoxxResponse<Policy>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetByPolicyNumber(policyNumber: string): Observable<BoxxResponse<Policy[]>> {
    var url = `${this.baseurl}?pkgPolicyNumber=${policyNumber}&active=1&page=1&limit=1`;
    return this.http
      .get<BoxxResponse<Policy>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
