import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-pagefooter-timestamp-v2',
  templateUrl: './pagefooter-timestamp-v2.component.html',
  styleUrls: ['./pagefooter-timestamp-v2.component.less'],
})
export class PagefooterTimestampV2Component {
  @Input() createdBy: string = null;
  @Input() createdAt: string = null;
  @Input() updatedBy: string = null;
  @Input() updatedAt: string = null;
}
