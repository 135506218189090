export const commonEnv = {
  production: false,
  baseUrl:
    'http://a867d01a71f5c40a0b8a24e95064403d-56227339.ca-central-1.elb.amazonaws.com:4006', //'http://localhost:4000',
  apiVersion: '/api/v1',
  msBroker: '/ms-broker',
  msQuotePolicy: '/ms-quotepolicy',
  msSecurity: '/ms-security',

  //security-ms
  msSecurityBseUrl:
    'https://cadev-api-piboxx.boxxinsurance.com/ms-security/api/v1',
  rolePathUrl: '/role/core/',
  permissionPathUrl: '/permissions/core/',
  rolePermissionPathUrl: '/rolePermissions/core',
  systmenUserPathUrl: '/systemUser/core/',
  boxxUserPathUrl: '/boxxUser/core/',

  //insured-ms
  msInsuredBaseUrl:
    'https://cadev-api-piboxx.boxxinsurance.com/ms-insured/api/v1',
  insuredPathUrl: '/insured/core/',
  locationPathUrl: '/location/core/',
  industryClassPathUrl: '/industryClass/core/',
  industryPathUrl: '/industry/core/',
  industryRestrictedPathUrl: '/industryRestrictedCodes/core/',
  billingCompanyContact: '/billingCompanyContact/core/',
  ofac: '/ofac/search/',
  naicsPrediction: '/naicsCode/prediction/core',
  naics: '/naics/core',
  insuredExportUrl: '/insured/export/core',

  //quotepolicy-ms
  msPolicyBaseUrl:
    'https://cadev-api-piboxx.boxxinsurance.com/ms-quotepolicy/api/v1',
  policyPathUrl: '/policy/core/',
  dashboardPathUrl: '/policy/core/dashboard/',
  dashboardStatsPathUrl: '/policy/core/stats/',
  atomicTrxPathUrl: '/policy/core/atomicTrx/',
  duplicateQuote: '/policy/core/duplicateQuote/',
  marketReservationPathUrl: '/policy/core/marketplace/reservation/',
  policyPeriodPathUrl: '/policyPeriod/core/',
  policyRiskPathUrl: '/policyRisk/core/',
  policyQuoteOptionPathUrl: '/quoteOption/core/',
  quoteOptionServicesPathUrl: '/quoteOptionServices/core/',
  policyRiskLimitUrl: '/PolicyRiskCoverage/core/',
  policySubjectivityUrl: '/policyRiskSubjectivity/core/',
  policyRiskDocCoreUrl: '/policyRiskDocuments/core/',
  policyRiskDocProcessCoreUrl: '/policyRiskDocumentProcessing/core/',
  policyRiskTrxNotesUrl: '/policyRiskTrxNotes/core/',
  policyRiskTrxUrl: '/policyRiskTrx/core/',
  policyRiskTrxFollowUpUrl: 'followUp/',
  policyLifeCycleUrl: '/policyLifeCycle/core/',
  policyAnswerUrl: '/policyRiskAnswer/core/',
  policyBindUrl: '/policyLifeCycle/core/bind/',
  messageSendUrl: '/messaging/core/send/',
  messageStatusUrl: '/messaging/core/status/',
  generateQuoteUrl: '/policySend/core/',
  quoteOptionReasonsPathUrl: '/quoteOptionReasons/core/',
  previewRiskDocumentUrl: '/document/core/',
  policyRiskTrxReasonsUrl: '/policyRiskTrxReasons/core/',
  policyQuoteAuditUrl: '/policyQuoteAudit/core/',
  quoteOptionCoverageSublimits: '/quoteOptionCoverageSublimits/core/',
  quoteOptionCoverageCore: '/quoteOptionCoverage/core/',
  generateDocumentTemplateUrl: '/policySend/core/',
  policyExportUrl: '/policy/export/',
  previewRiskDocumentUploadUrl: '/document/core/upload',
  policyPeriodNotesUrl: '/policyPeriodNotes/core/',
  policyRiskEndorsementUrl: '/policyRiskEndorsement/core/',
  policyRiskTrxExport: '/policyRiskTrx/export/',
  policyRiskTrxQuoteFlowStatus: '/policyRiskTrx/core/quoteFlowStatus/',

  //broker-ms
  msBrokerBaseUrl:
    'https://cadev-api-piboxx.boxxinsurance.com/ms-broker/api/v1',
  brokerageProfilePathUrl: '/brokerageProfile/core/',
  brokeragePathUrl: '/brokerage/core/',
  brokerageAtomicTrxPathUrl: '/brokerage/atomicTrx/core/',
  brokerageListCoreUrl: '/brokerage/list/core/',
  brokerageProducerPathUrl: '/brokerageProducer/core/',
  brokerageProducerAtomicTrxPathUrl: '/brokerageProducer/atomicTrx/core/',
  brokerageProducerStatusPathUrl: '/brokerageProducer/stats/core/',
  brokerageBranchPathUrl: '/brokerageBranch/core/',
  brokerageBranchTeamPathUrl: '/brokerageBranchTeam/core/',
  brokerageDirectoryPathUrl: '/brokerageDirectory/core/',
  brokerageDriectoryExportUrl: '/brokerageDirectory/export/',
  brokerageRelationshipPathUrl: '/brokerageRelationship/core/',
  brokerageCommissionPathUrl: '/brokerageCommission/core/',
  brokerageCommissionAtomicTrxPathUrl: '/brokerageCommission/atomicTrx/core/',
  brokerageProducerCommissionPathUrl: '/brokerageProducerCommission/core/',
  brokerageProducerProfile: '/brokerageProducerProfile/core/',
  brokerageProducerExport: '/brokerageProducer/export/core',
  brokerageBranchExport: '/brokerageBranch/export/core/',
  brokerageProducerTeamPathUrl: '/brokerageProducerTeam/core/',

  //rating-ms
  msRatingBaseUrl:
    'https://cadev-api-piboxx.boxxinsurance.com/ms-rating/api/v1',
  riskRatingCalculatePathUrl: '/riskRating/core/calculate/',

  baseHref: '/portal-uwcore-ui/',

  //productrisk-ms
  msProductriskBaseUrl:
    'https://cadev-api-piboxx.boxxinsurance.com/ms-productrisk/api',
  riskQuestionPathUrl: '/riskQuestion/core/',
  riskServicePathUrl: '/riskService/core/',
  riskRegionPathUrl: '/riskRegion/core/',
  planStructureRiskPathUrl: '/planStructureRisk/core/',
  planStructureServicePathUrl: '/planStructureService/core/',
  riskPeriodPathUrl: '/riskPeriod/core/',
  riskPeriodCarrierSchedule: '/riskPeriodCarrierSchedule/core/',
  riskPath: '/risk/core/',
  riskPackagePathUrl: '/riskPackage/core/',
  productRiskAuditPathUrl: '/productRiskAudit/core/',
  confRevenuePathUrl: '/confRevenue/core/',
  confMinPremiumPathUrl: '/confMinPremium/core/',
  confLimitPathUrl: '/confCoverage/core/',
  confCoverageSubLimitsPathUrl: '/confCoverageSublimits/core/',
  confRiskLimitsUrl: '/confRiskLimits/core/',
  confDeductiblePathUrl: '/confDeductible/core/',
  riskTemplatePathUrl: '/riskTemplate/core/',
  riskTemplateContentPathUrl: '/riskTemplateContent/core/',
  riskRegionSubjectivity: '/riskRegionSubjectivity/core/',
  riskPolicyDocumentStructureUrl: '/riskRegionPolicyDocumentStructure/core/',

  //configuration-ms
  msConfigurationBaseUrl:
    'https://cadev-api-piboxx.boxxinsurance.com/ms-configuration/api',
  regionPathUrl: '/region/core/',
  domainPathUrl: '/domain/core/',
  languagePathUrl: '/language/core/',
  currencyPathUrl: '/currency/core/',
  messagingTemplatePathUrl: '/messagingTemplates/core/',
  uiContentPathUrl: '/uiContent/core/',
  taxPathUrl: '/tax/core/',

  // mock ofac and naics
  mockExternalAPIs: true,

  // cognito config
  cognitoBaseUrl: 'https://boxx-user-stg.auth.ca-central-1.amazoncognito.com',
  cognitoLoginPath: '/oauth2/token',
  cognitoAuthTokenPath: '/oauth2/token',
  cognitoUserInfoUrl: '/oauth2/userInfo',
  defaultFlow: 'workflow3',
  cognitoRedirectUrl: 'http://localhost:8080/cognito',
  cognitoBypassRedirectUrl:
    'http://localhost:8080/cognito?bypassMaintenance=true',
  cognito4FlowRedirectUrl: 'http://localhost:8080/cognito4flow',
  cognito7FlowRedirectUrl: 'http://localhost:8080/cognito7flow',
  workFlow3RedirectUrl: 'http://localhost:8080/workflow3',
  loginUrl:
    'https://boxx-user-dev.auth.ca-central-1.amazoncognito.com/oauth2/authorize?client_id=1cjo7jjd9oiebk36u6dguvk87q&response_type=code&scope=email+openid+phone+profile&redirect_uri=https://cadev-piboxx.boxxinsurance.com/portal-uwcore-ui/cognito',
  cognito4LoginUrl:
    'https://boxx-user-dev.auth.ca-central-1.amazoncognito.com/oauth2/authorize?client_id=1cjo7jjd9oiebk36u6dguvk87q&response_type=code&scope=email+openid+phone+profile&redirect_uri=https://cadev-piboxx.boxxinsurance.com/portal-uwcore-ui/cognito4flow',
  cognito7LoginUrl:
    'https://boxx-user-dev.auth.ca-central-1.amazoncognito.com/oauth2/authorize?client_id=1cjo7jjd9oiebk36u6dguvk87q&response_type=code&scope=email+openid+phone+profile&redirect_uri=https://cadev-piboxx.boxxinsurance.com/portal-uwcore-ui/cognito7flow',
  workflow3LoginUrl:
    'https://boxx-user-dev.auth.ca-central-1.amazoncognito.com/oauth2/authorize?client_id=1cjo7jjd9oiebk36u6dguvk87q&response_type=code&scope=email+openid+phone+profile&redirect_uri=https://cadev-piboxx.boxxinsurance.com/portal-uwcore-ui/workflow3',
  logoutUrl:
    'https://boxx-user-dev.auth.ca-central-1.amazoncognito.com/logout?client_id=1cjo7jjd9oiebk36u6dguvk87q&logout_uri=https://cadev-piboxx.boxxinsurance.com/portal-uwcore-ui/logout',
  authenticationUrl: '/authentication/core/login',
  revalidateUrl: '/authentication/core/refresh',

  googleMapsApiKey: 'AIzaSyDqXsGX17XbFWtolM-PqBOiuRSDKF0HyTg',
  googleMapsCountry: 'us',
  currency: 'USD',
  country: 'UNITED STATES',
  releaseVersion: require('../../package.json')['release-version'],
  policyNumberSuffixCode: 'PKC',
};
