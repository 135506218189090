import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, firstValueFrom, take } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Quote } from 'src/app/models/quote.model';
import {
  getAllMailIds,
  getQuoteSelector,
  getUIData,
} from 'src/app/store/create-quote/create-quote.selector';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { PolicyRiskService } from 'src/app/services/policy-risk.service';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import { RiskTemplateService } from 'src/app/services/risk-template';
import { DomainsService } from 'src/app/services/domains.service';
import { RiskRegionSubjectivityService } from 'src/app/services/risk-region-subjectivity.service';
import { PolicySubjectivityService } from 'src/app/services/policy-subjectivity.service';
import { PolicyRiskDocService } from 'src/app/services/policy-risk-doc.service';
import moment from 'moment';
import { PolicyPeriodService } from 'src/app/services/policy-period.service';
import {
  TEMPLATETYPE,
  TEMPLATETYPE_ENDORSEMENT,
  TEMPLATETYPE_POLICYWORDING,
  DOCUMENTTYPE,
  DOCUMENTTYPE_ENDORSEMENT,
  DOCUMENTTYPE_POLICY,
  TEMPLATETYPE_DECLARATION,
  DOCUMENTTYPE_DECLARATION,
  INSURED_COMPANY_TYPE,
  GENERATE_DOC_SUCCESS_STATUS_CODE,
  EFFECTIVE_DATE_MAX_RANGE_LIMIT,
  DOC_GENERATION_WAIT_TIME,
  ALLOWED_ENDORSEMENTS_COUNT,
} from 'src/app/constants/quote-constant';
import { PolicyRiskTrxService } from 'src/app/services/policy-risk-trx.service';
import { PolicyRiskDocProcessService } from 'src/app/services/policy-risk-doc-process.service';
import { PolicyRiskDocPreviewService } from 'src/app/services/policy-risk-doc-preview.service';
import {
  calculateDateAfterMonths,
  convertDate,
  formatDate,
  getCityTime,
  getDateFormatForDatePicker,
  getDayAfterSpecifiedDays,
  getDayBeforeSpecifiedDays,
  getFormatDate,
} from 'src/app/utils/formatDate';
import { InsuredService } from 'src/app/services/insured.service';
import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import { getLifecycleState } from 'src/app/store/lifecycle/lifecycle.select';
import { TranslateService } from '@ngx-translate/core';
import { getAlertHead, getErrorMessage } from 'src/app/utils/utils';
import { DocumentTemplateService } from 'src/app/services/doc-template.service';
import { NewQuoteService } from 'src/app/services/new-quote.service';
import { GenerateQuoteService } from 'src/app/services/doc-create.service';
import { ConfMessageTemplateService } from 'src/app/services/message-template-services';
import { PolicyBindService } from 'src/app/services/policy-bind.service';
import { TransactionRiskActions } from 'src/app/dashboard/constants/lifecycle-actions';
import { PolicyDeclineRequest } from 'src/app/entities/policy-lifecycle';
import { PolicyLifecycleService } from 'src/app/services/policy-lifecycle-service';
import { AlertService } from 'src/app/services/alert.service';
import { MessageSendService } from 'src/app/services/message-send.service';
import { USER_ROLES } from 'src/app/constants/security/systemUserType';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { PolicyRiskEndorsementService } from 'src/app/services/policy-risk-endorsement.service';

@Component({
  selector: 'app-policy-configuration',
  templateUrl: './policy-configuration.component.html',
  styleUrls: ['./policy-configuration.component.less'],
})
export class PolicyConfigurationWF3Component implements OnInit, OnDestroy {
  form$: Observable<Quote>;
  isContinueFormValid: boolean = true;
  isTBDValid: boolean = true;
  underWriters: string[] = [];
  underWriterOptions: any = [];
  wordSelectOptions = [
    { key: 'Word 1', value: 'Word 1' },
    { key: 'Word 2', value: 'Word 2' },
  ];
  showDocPreview = false;
  currentPreviewDocUrl = '';
  policyPeriodId: number = 0;
  quoteOptions = [];
  products = [];
  productsIds = [];
  riskRegionId = 0;
  templateCodeForEndorsement = 0;
  templateCodeForWordings = 0;
  templateCodeForDeclaration = 0;
  documentCodeForEndorsement = 0;
  documentCodeForWordings = 0;
  documentCodeForDeclaration = 0;
  riskTrnxs;
  underwriterTotalCount = 0;
  underwriterTotalPages = 1;
  disabledDropdownList: string[] = [];
  productList: { [x: string]: any }[] = [];

  endorsementOptions: any[] = [
    { key: '', value: '', disabled: false, url: '' },
  ];
  wordingsOptions: any[] = [{ key: '', value: '', disabled: false, url: '' }];
  subjectivityOptns: any[] = [{ key: '', value: '', disabled: false, url: '' }];
  declarationOptions: any[] = [
    { key: '', value: '', disabled: false, url: '' },
  ];
  subjectivityMandatory = [];
  selectedSubjectivities = [];
  endorsementsMandatory = [];
  selectedEndorsements = [];
  selectedWordings = [];
  selectedDeclarations = [];
  addons = [];
  policyRiskIds = [];
  showBodySpinner: boolean = false;
  policyRisks = [];
  underWritersList: any;
  wordingsDeclarationError = false;
  spinnerCounter = 0;
  endDateEditable: boolean = false;
  minStartDate = new Date();
  maxStartDate = new Date();
  defaultEffectiveDate;
  defaultExpiryDate;
  minEndDate;
  maxEndDate;
  isStartDateValid = true;
  isEndDateValid = true;
  isPendingPriorDtValid = true;
  isRetroactiveDtValid = true;
  startDateErrMsg;
  endDateErrMsg;
  pendingPriorDtErrMsg;
  retroactiveDtErrMsg;
  insuredTimezone;
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  addedSubjectivities = [];
  editedSubjectivity = '';
  editedEndorsement = '';
  currentQuoteStatus: string = '';
  transactionLvlQuoteStatus: string = '';
  stageId: number;
  isLoader: boolean = false;
  showSendModal = false;
  showErrorAlert = false;
  alertMessage = '';
  docTempDetails: {
    documentTemplate: [];
  };
  docTempSelectedOptions: any[][] = [];
  isAlloptionsSelected: { [key: number]: string } = {};
  isQuoteBounded = false;
  templateDetails: { [x: string]: string | number }[] = [];
  docDetails: { [x: string]: string | number | boolean }[] = [];
  rowDocDetails: { [x: string]: string | number | boolean } = {};
  messageTypeId: number;
  newTimeout: NodeJS.Timeout;
  isBindingPolicy = false;
  policyFeeEnabled = false;
  TRIAFeeEnabled = false;
  policyPremium: number;
  triaAmount: number;
  policyAmount: number;
  bindingStatus = 'default';
  packagePolicyNumber: string = '';
  currency = '';
  actionPopupDetails: {
    quoteNumber: string;
    effectiveDate: string;
    insured: string;
    brokerage: string;
    branch: string;
    reasonOptions: any[];
    action: TransactionRiskActions;
  };
  showActionPopup: boolean = false;
  public transactionRiskActions = TransactionRiskActions;
  policyRiskFee = [];
  totalBilledAnnually = 0;
  quoteId = null;
  mailData: { [x: string]: string | string[] } = {};
  brokerCommission = 0;
  tbdOptions = [];
  shortDateFormat: string = '';
  longDateFormat: string = '';
  policyConfigurationForm: FormGroup;
  docConfigForm: FormGroup;
  activeProductNavTab: number = 0;
  activeProductDetails: { [x: string]: any } = {};
  selectUnderwriterDetails: { [x: string]: any } = {};
  isActionPopupEnabled: boolean = false;
  isActionPopupIndex: number = undefined;
  quoteOptionsLoaded: boolean = false;
  quoteSelectionData: Array<any> = [];
  policyRiskId;
  sendQuoteDropdownOptions = [
    { key: 'Single option', value: 'Single option' },
    { key: 'Multiple option', value: 'Multiple option' },
    { key: 'Comparison', value: 'Comparison' },
  ];
  isDropdownActive: boolean = true;
  sendButtonDisabled: boolean = false;
  modalPopupType: string = 'Single option';
  showSendCompareModal = false;
  isQuoteSelected: boolean = false;
  docPopupDetails: {
    statusType: string;
    action: any;
    documentTemplate: any;
    policyPeriodId: any;
    stageId: any;
  };
  tempType = 1;
  triaAmountDefault = 0;
  policyFeeDefault = 0;
  isTriaFeeChange: boolean = false;
  insuredId;
  modalInitiated: boolean = false;

  //quoteOptions
  data = {
    products: [],
    total: {
      premium: 0,
      premiumCalculated: 0,
      policyFee: 0,
      triaAmount: 0,
      netAmount: 0,
      taxesAmount: 0,
      totalAmount: 0,
      servicesAmount: 0,
      taxesPremiumAmount: 0,
      taxesServicesAmount: 0,
    },
    ui: {
      selectedForCompare: false,
      isQuoteBoxExpanded: true,
      optionSelected: false,
    },
  };

  docConfigOptions = [
    { id: 1, value: 'Subjectivity', key: 'Subjectivity', disabled: false },
    { id: 2, value: 'Endorsement', key: 'Endorsement', disabled: false },
    { id: 3, value: 'Wording', key: 'Wording', disabled: false },
    { id: 4, value: 'Declaration', key: 'Declaration', disabled: false },
  ];

  docConfigButtons = [
    { id: 1, name: 'All', active: true },
    { id: 2, name: 'Subjectivities', active: false },
    { id: 3, name: 'Endorsements', active: false },
  ];

  subjectivitiesComplyCheckbox = [
    {
      name: 'Comply',
      value: true,
      checked: false,
      id: 1,
    },
  ];
  selectedQuoteOptionIds: number[] = [];
  selectedQuoteOptionsNumbers: number[] = [];
  policyRiskTrxId: number = undefined;
  selectedQuoteIndex: number = undefined;

  showModalSubjectivities = false;
  showModalEndorsements = false;
  showModalDeclarations = false;
  showModalWordings = false;
  globalComplyChecked = false;
  selectAllComply: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private policyRiskService: PolicyRiskService,
    private boxxUserService: BoxxUserService,
    private riskRegionService: RiskRegionService,
    private riskTemplateService: RiskTemplateService,
    private domainsService: DomainsService,
    private riskRegionSubjectivityService: RiskRegionSubjectivityService,
    private policySubjectivityService: PolicySubjectivityService,
    private policyRiskDocService: PolicyRiskDocService,
    private policyRiskTrxService: PolicyRiskTrxService,
    private policyRiskDocProcessService: PolicyRiskDocProcessService,
    private PolicyRiskDocPreviewService: PolicyRiskDocPreviewService,
    private datePipe: DatePipe,
    private policyPeriodService: PolicyPeriodService,
    private insuredService: InsuredService,
    private translateService: TranslateService,
    private translate: TranslateService,
    private DocumentTemplateService: DocumentTemplateService,
    private newQuoteService: NewQuoteService,
    private generateQuoteService: GenerateQuoteService,
    private messageTemplateService: ConfMessageTemplateService,
    private policyBindService: PolicyBindService,
    private policyLifecycleService: PolicyLifecycleService,
    private alertService: AlertService,
    private messageSendService: MessageSendService,
    private fb: FormBuilder,
    private localStorageService: LocalStorageService,
    private policyRiskEndorsementService: PolicyRiskEndorsementService,
  ) {
    this.policyConfigurationForm = this.fb.group({
      policy_period_control: 'TBD',
    });
  }

  async ngOnInit(): Promise<void> {
    this.docConfigForm = this.fb.group({
      document: ['Subjectivity'],
    });

    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    this.store.pipe(select(getLifecycleState)).subscribe((data) => {
      this.currentQuoteStatus = data.quoteStatus;
      this.transactionLvlQuoteStatus = data.transactionLevelQuoteStatus;
    });

    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));

    this.docTempDetails = {
      documentTemplate: [],
    };
    this.docPopupDetails = {
      statusType: 'Document template selection',
      action: 'docTempSelection',
      documentTemplate: [],
      policyPeriodId: 0,
      stageId: 0,
    };
    this.form$ = this.store.pipe(select(getQuoteSelector));
    this.form$.subscribe((event) => {
      (this.policyPeriodId = Number(event.ui.policyPeriodId)),
        (this.policyRiskTrxId = Number(event.ui.policyRiskTrxId));
    });

    await this.populateQuoteOptions();
    this.tbdOptions = [{ name: 'TBD', checked: this.isTBDValid }];
    this.form$.subscribe((event) => (this.productList = event.products));
    this.populateUnderwriterOptions();
    await this.loadPageData();
  }

  ngOnDestroy(): void {
    this.alertService.clearAlerts(-1);
    this.showBodySpinner = false;
  }

  async loadPageData() {
    return new Promise<any>(async (resolve, reject) => {
      this.spinnerCounter = 0;
      this.showBodySpinner = false;
      this.spinnerCounter++;
      let policyFee = 0;
      this.policyFeeDefault = 0;
      let triaAmount = 0;
      this.triaAmountDefault = 0;
      let policyPremium = 0;
      let latestPolicyRiskFee = [];

      this.policyRiskService
        .GetAllByPolicyPeriodId(this.policyPeriodId)
        .subscribe(async (quoteData) => {
          this.policyRisks = quoteData?.data;

          this.policyRiskId =
            this.policyRisks[0].PolicyRiskTrxes[0].policyRiskId;
          await this.populateDocumentTemplates();
          this.quoteOptions = quoteData.data.flatMap((dataObj) => {
            latestPolicyRiskFee.push(...dataObj?.PolicyRiskTrxes);
            const quoteOptions = dataObj.PolicyRiskTrxes[0]?.QuoteOptions || [];
            resolve(true);
            if (!this.quoteOptionsLoaded) {
              this.quoteOptionsLoaded = true;
            }
            return quoteOptions;
          });
          this.policyRiskFee = latestPolicyRiskFee;
          policyFee = Number(this.policyRiskFee[0].policyFee);
          triaAmount = Number(this.policyRiskFee[0].triaAmount);
          this.brokerCommission = Number(
            this.policyRiskFee[0].brokerCommissionPerc * 100,
          );
          this.policyRiskFee.forEach((product) => {
            policyPremium = policyPremium + Number(product.premium);
          });
          // todo
          // default valued for tria and policy fee are calculating here
          // need to do rework for multiple products
          const riskObj = quoteData.data[0].risk;
          if (riskObj.policyFeeEnabled === 1) {
            this.policyFeeDefault = Number(riskObj.policyFeeAmount) ?? 0;
          }
          if (riskObj.triaEnabled === true) {
            this.triaAmountDefault =
              (Number(policyPremium) *
                (Number(riskObj.triaPremiumPerc) * 100)) /
                100 || 0;
          }

          this.policyAmount = policyFee;
          this.triaAmount = triaAmount;
          this.policyPremium = policyPremium;
          this.TRIAFeeEnabled = Number(this.triaAmount || 0) !== 0;
          this.policyFeeEnabled = Number(this.policyAmount || 0) !== 0;
          const serviceAmount = this.getServices();
          const taxAmount = this.getTaxes();
          this.totalBilledAnnually = await this.getTotalBilledAnnual();
          this.store.dispatch(
            new CreateQuoteAction.updateUiContents({
              annualPremium: this.totalBilledAnnually,
              quote4FlowPremiumDetails: {
                policyAmount: this.policyAmount,
                triaAmount: this.triaAmount,
                triaAmountDefault: this.triaAmountDefault,
                policyPremium: this.policyPremium,
                policyFeeDefault: this.policyFeeDefault,
                TRIAFeeEnabled: this.TRIAFeeEnabled,
                policyFeeEnabled: this.policyFeeEnabled,
                totalBilledAnnually: this.totalBilledAnnually,
                serviceAmount: serviceAmount,
                taxAmount: taxAmount,
              },
            }),
          );
          const insuredRespObj =
            quoteData?.data[0]?.policyPeriod?.policy?.insured;
          const insuredId = quoteData?.data[0]?.policyPeriod?.policy?.insuredId;
          this.quoteId = quoteData?.data[0]?.policyPeriod?.policy?.id;

          const insuredDetails = {
            name:
              insuredRespObj?.insuredType == INSURED_COMPANY_TYPE
                ? insuredRespObj?.companyName
                : insuredRespObj?.firstName + insuredRespObj?.lastName,
          };

          this.insuredService.GetByInsuredId(insuredId).subscribe({
            next: async (data) => {
              this.insuredTimezone = data.data.insuredLocation.timezone;
            },
          });

          let currentDate = getCityTime(this.insuredTimezone);

          this.store.dispatch(
            new CreateQuoteAction.UpdateInsuredAction({
              insuredName: insuredDetails.name,
              insuredEmail: null,
              insuredId,
              policyId: Number(this.quoteId),
            }),
          );
          //Date details
          const effectiveExpiryDatesTBDFlag =
            quoteData?.data[0]?.policyPeriod?.effectiveExpiryDatesTBDFlag;
          this.tbdOptions[0].checked = effectiveExpiryDatesTBDFlag;
          this.isTBDValid = effectiveExpiryDatesTBDFlag;

          this.policyConfigurationForm = this.fb.group({
            policy_period_control: effectiveExpiryDatesTBDFlag ? 'TBD' : '',
          });

          const effectiveDate = quoteData?.data[0]?.policyPeriod?.effectiveDt;
          const expiryDate = quoteData?.data[0]?.policyPeriod?.expiryDt;
          const litigationDate =
            quoteData?.data[0]?.policyPeriod?.pendingPriorDt;
          const retroactiveDate =
            quoteData?.data[0]?.policyPeriod?.retroactiveDt;
          let startDate = convertDate(
            moment(effectiveDate).toDate(),
            this.shortDateFormat,
          );

          let endDate = convertDate(
            moment(expiryDate).toDate(),
            this.shortDateFormat,
          );
          if (litigationDate && litigationDate !== '') {
            let pendingPriorDt = convertDate(
              moment(litigationDate).toDate(),
              this.shortDateFormat,
            );
            this.store.dispatch(
              new CreateQuoteAction.updateUiContents({
                pendingPriorDt: pendingPriorDt,
              }),
            );
          }

          if (retroactiveDate && retroactiveDate !== '') {
            let retroactiveDt = convertDate(
              moment(retroactiveDate).toDate(),
              this.shortDateFormat,
            );
            this.store.dispatch(
              new CreateQuoteAction.updateUiContents({
                retroactiveDt: retroactiveDt,
              }),
            );
          }

          if (!this.isTBDValid) {
            if (effectiveDate) {
              this.store.dispatch(
                new CreateQuoteAction.updateUiContents({
                  startDate: startDate,
                }),
              );
            }
            if (expiryDate) {
              this.store.dispatch(
                new CreateQuoteAction.updateUiContents({
                  endDate: endDate,
                }),
              );
            }
          }

          let defaultStartDate;
          let defaultEndDate;

          if (!effectiveDate) {
            defaultStartDate = currentDate.currentDate ?? this.getTomorrow();
            let startDate = this.getFormattedDate(defaultStartDate);
            if (!this.isTBDValid) {
              this.store.dispatch(
                new CreateQuoteAction.updateUiContents({
                  startDate: startDate,
                }),
              );
            }
          } else {
            defaultStartDate = new Date(effectiveDate);
          }
          this.defaultEffectiveDate = this.datePipe.transform(
            new Date(effectiveDate),
            this.shortDateFormat,
          );
          if (!expiryDate) {
            // TODO check new date it will fail if user login from different location
            defaultEndDate =
              currentDate.endDate ??
              this.datePipe.transform(
                new Date(
                  new Date().getTime() + 24 * 60 * 60 * 1000,
                ).setFullYear(
                  new Date(
                    new Date().getTime() + 24 * 60 * 60 * 1000,
                  ).getFullYear() + 1,
                ),
                this.shortDateFormat,
              );
            if (!this.isTBDValid) {
              this.store.dispatch(
                new CreateQuoteAction.updateUiContents({
                  endDate: defaultEndDate,
                }),
              );
            }
          } else {
            defaultEndDate = new Date(expiryDate);
          }
          this.defaultExpiryDate = this.datePipe.transform(
            new Date(expiryDate),
            this.shortDateFormat,
          );
          this.minStartDate = new Date();
          this.maxStartDate = new Date();
          this.minStartDate.setDate(this.minStartDate.getDate() - 30);
          this.maxStartDate.setDate(
            this.maxStartDate.getDate() + EFFECTIVE_DATE_MAX_RANGE_LIMIT,
          );

          const effectiveDateFormatted = getDateFormatForDatePicker(
            effectiveDate,
            this.shortDateFormat,
          );
          const effectiveDateObject = moment(effectiveDateFormatted).toDate();
          this.minEndDate = calculateDateAfterMonths(
            effectiveDateObject,
            3,
            this.shortDateFormat,
            true,
          );
          this.maxEndDate = calculateDateAfterMonths(
            effectiveDateObject,
            18,
            this.shortDateFormat,
            true,
          );

          this.riskTrnxs = quoteData.data.flatMap((dataObj) => {
            const riskTrnxs = dataObj.PolicyRiskTrxes || {};
            return riskTrnxs;
          });
          let latestUnderwriterList = [];
          let latestUnderwriterMailIdList = [];
          this.policyRiskIds = [];
          this.riskTrnxs.forEach((trnx) => {
            this.policyRiskIds.push(trnx.policyRiskId);
            this.spinnerCounter++;
            this.boxxUserService
              .GetById(trnx.underwriterId)
              .subscribe((dataUW) => {
                this.selectUnderwriterDetails = {
                  id: dataUW.data?.id,
                  key: dataUW.data?.name,
                  value: dataUW.data?.name,
                };
                latestUnderwriterList = [
                  ...latestUnderwriterList,
                  dataUW.data?.firstName + ' ' + dataUW.data?.lastName,
                ];
                latestUnderwriterMailIdList = [
                  ...latestUnderwriterMailIdList,
                  dataUW.data?.systemUser?.loginEmail ?? '',
                ];
                this.underWriters = latestUnderwriterList;
                const emailIds = this.store.pipe(select(getAllMailIds));
                emailIds
                  .pipe(take(1))
                  .subscribe((email) => (this.mailData = email));
                let mailIdList = {
                  ...this.mailData,
                  underwriter: [...latestUnderwriterMailIdList],
                };
                this.store.dispatch(
                  new CreateQuoteAction.setAllMailId(mailIdList),
                );

                if (this.underWriters.length === this.riskTrnxs.length) {
                  this.productList.map((product: any) => {
                    if (product.hasSubProduct) {
                      this.disabledDropdownList = [
                        ...this.disabledDropdownList,
                        product.subProductName,
                      ];
                    }
                  });
                }
                this.spinnerCounter--;
              });
          });

          this.products = quoteData.data.flatMap((dataObj) => {
            const products = dataObj.risk.name || '';
            return products;
          });
          this.productsIds = quoteData.data.flatMap((dataObj) => {
            const productsIds = dataObj.risk.id || '';
            return productsIds;
          });

          const selectedQuoteOption =
            this.quoteOptions.filter(
              (a) => a.optionSelected == 1 && a.QuoteOptionServices.length > 0,
            ) ?? [];
          this.activeProductDetails =
            this.quoteOptions.filter(
              (option) => option.optionSelected === 1,
            )?.[0] ?? {};
          this.selectedQuoteIndex =
            this.activeProductDetails['optionNumber'] - 1;
          if (
            !this.selectedQuoteOptionIds.includes(
              this.activeProductDetails['id'],
            )
          ) {
            this.selectedQuoteOptionIds.push(this.activeProductDetails['id']);
            this.selectedQuoteOptionsNumbers.push(
              this.activeProductDetails['optionNumber'],
            );
          }
          if (selectedQuoteOption.length > 0) {
            const riskIds = this.productsIds.join(',');
            let addons = [];
            this.spinnerCounter++;
            this.riskRegionService
              .getRiskServicesById(riskIds)
              .subscribe((dataAddons) => {
                addons = dataAddons.data?.map((item: any, index, arr) => ({
                  description: item.description,
                  id: item.id,
                  serviceName: item.serviceName,
                  riskId: item.risk.id,
                  riskName: item.risk.name,
                }));

                if (addons.length > 0) {
                  this.productList.forEach((item: any) => {
                    this.addons[item.key] = [];
                    var addonArr =
                      addons.filter((a) => a.riskId == item.riskId) ?? [];
                    addonArr.forEach((addon: any) => {
                      var riskServInd =
                        selectedQuoteOption[0].QuoteOptionServices.findIndex(
                          (a) => a.riskServicesId == addon.id,
                        );
                      if (riskServInd != -1) {
                        this.addons[item.key].push(addon);
                      }
                    });
                  });
                }
                this.spinnerCounter--;
              });
          }
          this.spinnerCounter++;
          this.domainsService
            .GetByDomainCode(TEMPLATETYPE)
            .subscribe((dataTemplate) => {
              this.templateCodeForEndorsement =
                dataTemplate.data.filter(
                  (d) => d.subdomaincode == TEMPLATETYPE_ENDORSEMENT,
                )[0]?.id ?? -1;
              this.templateCodeForWordings =
                dataTemplate.data.filter(
                  (d) => d.subdomaincode == TEMPLATETYPE_POLICYWORDING,
                )[0]?.id ?? -1;
              this.templateCodeForDeclaration =
                dataTemplate.data.filter(
                  (d) => d.subdomaincode == TEMPLATETYPE_DECLARATION,
                )[0]?.id ?? -1;
              this.spinnerCounter++;
              this.domainsService
                .GetByDomainCode(DOCUMENTTYPE)
                .subscribe((dataDoc) => {
                  this.documentCodeForEndorsement =
                    dataDoc.data.filter(
                      (d) => d.subdomaincode == DOCUMENTTYPE_ENDORSEMENT,
                    )[0]?.id ?? -1;
                  this.documentCodeForWordings =
                    dataDoc.data.filter(
                      (d) => d.subdomaincode == DOCUMENTTYPE_POLICY,
                    )[0]?.id ?? -1;
                  this.documentCodeForDeclaration =
                    dataDoc.data.filter(
                      (d) => d.subdomaincode == DOCUMENTTYPE_DECLARATION,
                    )[0]?.id ?? -1;

                  for (const [indexval, obj] of quoteData.data.entries()) {
                    this.spinnerCounter++;
                    this.riskRegionService
                      .getRiskRegionByRegionId(
                        obj.policyPeriod.policy.insured.insuredLocation
                          .regionId,
                        obj.risk.id,
                      )
                      .subscribe((dataRiskreg) => {
                        dataRiskreg.data.forEach((objRegion, indexReg) => {
                          this.riskRegionId = objRegion.id;
                          for (const [
                            index,
                            product,
                          ] of this.products.entries()) {
                            this.spinnerCounter++;
                            // get subjectivities for each product
                            this.getAllLatestSubjectivities(product, index);
                            this.spinnerCounter++;
                            // get endorsement
                            this.riskTemplateService
                              .getRiskTemplate(
                                1,
                                this.riskRegionId,
                                this.templateCodeForEndorsement,
                              )
                              .subscribe((dataEnd) => {
                                this.endorsementOptions[product] =
                                  dataEnd.data.map((endorsementDoc) => {
                                    return {
                                      key: endorsementDoc.templateDescription,
                                      value: endorsementDoc.templateDescription,
                                      disabled: !!endorsementDoc.mandatory,
                                      mandatory: endorsementDoc.mandatory,
                                      url: endorsementDoc.templatePath,
                                      templateType: endorsementDoc.templateType,
                                      policyRiskId: this.policyRiskIds[index],
                                      riskTemplateId: endorsementDoc.id,
                                      documentTblEndorsId: -1,
                                      dataInjection:
                                        endorsementDoc.dataInjection,
                                      ignoreTemplate:
                                        endorsementDoc.ignoreTemplate,
                                    };
                                  });
                                this.selectedEndorsements[product] =
                                  this.endorsementOptions[product]
                                    .filter(
                                      (endorsement) => endorsement.mandatory,
                                    )
                                    .map((endorsement) => ({
                                      ...endorsement,
                                      selected: true,
                                    }));
                                let distinctEndArray: any[] =
                                  this.selectedEndorsements[product].filter(
                                    (obj, index, array) =>
                                      array.findIndex(
                                        (o) =>
                                          obj !== null &&
                                          o?.riskTemplateId ===
                                            obj?.riskTemplateId,
                                      ) === index,
                                  );
                                this.selectedEndorsements[product] =
                                  distinctEndArray;
                                this.spinnerCounter++;
                                this.policyRiskDocProcessService
                                  .getPolicyRiskDocCore(
                                    this.policyRiskIds[index],
                                  )
                                  .subscribe((dataRiskTempExist) => {
                                    // get policy endorsement docs
                                    let selectedEndorsementDocs =
                                      dataRiskTempExist.data.filter(
                                        (doc) =>
                                          doc.templateType ==
                                          this.templateCodeForEndorsement,
                                      );
                                    let selectedEndorseDocs = {
                                      [product]: selectedEndorsementDocs.map(
                                        (doc) => {
                                          let endorsementDocDetails =
                                            this.endorsementOptions[
                                              product
                                            ].filter(
                                              (optn) =>
                                                optn.riskTemplateId ==
                                                doc?.riskTemplate.id,
                                            );

                                          return {
                                            key:
                                              endorsementDocDetails[0]?.key ||
                                              '',
                                            value:
                                              endorsementDocDetails[0]?.value ||
                                              '',
                                            disabled:
                                              !!endorsementDocDetails[0]
                                                ?.mandatory,
                                            mandatory:
                                              endorsementDocDetails[0]
                                                ?.mandatory,
                                            templateType:
                                              endorsementDocDetails[0]
                                                ?.templateType,
                                            policyRiskId:
                                              this.policyRiskIds[index],
                                            url:
                                              endorsementDocDetails[0]?.url ??
                                              '',
                                            selected: true,
                                            documentTblEndorsId: doc?.id || '',
                                            riskTemplateId:
                                              endorsementDocDetails[0]
                                                ?.riskTemplateId ?? null,
                                            ignoreTemplate:
                                              endorsementDocDetails[0]
                                                ?.ignoreTemplate ?? false,
                                          };
                                        },
                                      ),
                                    };

                                    if (
                                      selectedEndorseDocs[product].length > 0
                                    ) {
                                      this.selectedEndorsements[product] = [
                                        ...selectedEndorseDocs[product],
                                      ];
                                      let distinctEndArray: any[] =
                                        this.selectedEndorsements[
                                          product
                                        ].filter(
                                          (obj, index, array) =>
                                            array.findIndex(
                                              (o) =>
                                                obj !== null &&
                                                o?.riskTemplateId ===
                                                  obj?.riskTemplateId,
                                            ) === index,
                                        );
                                      this.selectedEndorsements[product] =
                                        distinctEndArray;
                                    }

                                    // get custom endorsement list
                                    this.spinnerCounter++;
                                    this.policyRiskEndorsementService
                                      .getPolicyRiskEndorsementCore(
                                        this.policyRiskIds[index],
                                      )
                                      .subscribe((dataRiskEndorsement) => {
                                        // get policy - custome endorsement
                                        if (
                                          dataRiskEndorsement?.data?.length > 0
                                        ) {
                                          let selectedEndorseDocs = {
                                            [product]:
                                              dataRiskEndorsement.data.map(
                                                (doc) => {
                                                  return {
                                                    key: doc?.description || '',
                                                    value:
                                                      doc?.description || '',
                                                    disabled: !!doc.active,
                                                    mandatory: false,
                                                    templateType: null,
                                                    policyRiskId:
                                                      this.policyRiskIds[index],
                                                    url: '',
                                                    selected: true,
                                                    policyRiskEndorsId:
                                                      doc?.id || '',
                                                    documentTblEndorsId: 0, // 0 indicates custom endorsement
                                                    riskTemplateId: null,
                                                    ignoreTemplate: true,
                                                  };
                                                },
                                              ),
                                          };

                                          if (
                                            selectedEndorseDocs[product]
                                              .length > 0
                                          ) {
                                            const customEndDocs =
                                              selectedEndorseDocs[product];
                                            let distinctEndArray: any[] =
                                              customEndDocs.filter(
                                                (obj, index, array) =>
                                                  array.findIndex(
                                                    (o) =>
                                                      obj !== null &&
                                                      o?.policyRiskEndorsId ===
                                                        obj?.policyRiskEndorsId &&
                                                      o?.key === obj?.key,
                                                  ) === index,
                                              );
                                            this.selectedEndorsements[product] =
                                              [
                                                ...this.selectedEndorsements[
                                                  product
                                                ],
                                                ...distinctEndArray,
                                              ];
                                          }
                                        }
                                        this.spinnerCounter--;
                                      });
                                    this.spinnerCounter--;
                                  });
                                this.spinnerCounter--;
                              });
                            this.spinnerCounter++;
                            // get wordings
                            this.riskTemplateService
                              .getRiskTemplate(
                                1,
                                this.riskRegionId,
                                this.templateCodeForWordings,
                              )
                              .subscribe((dataWord) => {
                                this.wordingsOptions[product] =
                                  dataWord.data.map((wordings) => {
                                    return {
                                      key: wordings.templateDescription,
                                      value: wordings.templateDescription,
                                      disabled: !!wordings.mandatory,
                                      mandatory: wordings.mandatory,
                                      url: wordings.templatePath,
                                      templateType: wordings.templateType,
                                      policyRiskId: this.policyRiskIds[index],
                                      riskTemplateId: wordings.id,
                                      dataInjection: wordings.dataInjection,
                                    };
                                  });

                                this.selectedWordings[product] = this
                                  .wordingsOptions[product]
                                  ? this.wordingsOptions[product]
                                      .filter((wording) => wording.mandatory)
                                      .map((word, key) => ({
                                        ...word,
                                        selected: true,
                                      }))
                                  : [];
                                let distinctWordArray: any[] =
                                  this.selectedWordings[product].filter(
                                    (obj, index, array) =>
                                      array.findIndex(
                                        (o) =>
                                          obj !== null &&
                                          o?.riskTemplateId ===
                                            obj?.riskTemplateId,
                                      ) === index,
                                  );
                                this.selectedWordings[product] =
                                  distinctWordArray;
                                this.spinnerCounter++;
                                this.policyRiskDocProcessService
                                  .getPolicyRiskDocCore(
                                    this.policyRiskIds[index],
                                  )
                                  .subscribe((dataRiskTempExist) => {
                                    // get policy wordings docs
                                    let selectedWordingsDocs =
                                      dataRiskTempExist.data.filter(
                                        (doc) =>
                                          doc?.templateType ==
                                          this.templateCodeForWordings,
                                      );

                                    let selectedWordingDocs = {
                                      [product]: selectedWordingsDocs.map(
                                        (doc) => {
                                          let wordingDocDetails =
                                            this.wordingsOptions[
                                              product
                                            ].filter(
                                              (optn) =>
                                                optn.riskTemplateId ==
                                                doc?.riskTemplate.id,
                                            );

                                          return {
                                            key:
                                              wordingDocDetails[0]?.key || '',
                                            value:
                                              wordingDocDetails[0]?.value || '',
                                            disabled:
                                              !!wordingDocDetails[0]?.mandatory,
                                            mandatory:
                                              wordingDocDetails[0]?.mandatory,
                                            templateType:
                                              wordingDocDetails[0]
                                                ?.templateType,
                                            policyRiskId:
                                              this.policyRiskIds[index],
                                            url:
                                              wordingDocDetails[0]?.url ?? '',
                                            selected: true,
                                            documentTblWordId: doc?.id,
                                            riskTemplateId:
                                              wordingDocDetails[0]
                                                ?.riskTemplateId ?? null,
                                          };
                                        },
                                      ),
                                    };

                                    if (
                                      selectedWordingDocs[product].length > 0
                                    ) {
                                      this.selectedWordings[product] =
                                        selectedWordingDocs[product];
                                      let distinctWordArray: any[] =
                                        this.selectedWordings[product].filter(
                                          (obj, index, array) =>
                                            array.findIndex(
                                              (o) =>
                                                obj !== null &&
                                                o?.riskTemplateId ===
                                                  obj?.riskTemplateId,
                                            ) === index,
                                        );
                                      this.selectedWordings[product] =
                                        distinctWordArray;
                                    }
                                    this.spinnerCounter--;
                                  });
                                this.spinnerCounter--;
                              });
                            this.spinnerCounter++;
                            // get Declarations
                            this.riskTemplateService
                              .getRiskTemplate(
                                1,
                                this.riskRegionId,
                                this.templateCodeForDeclaration,
                              )
                              .subscribe((dataWord) => {
                                this.declarationOptions[product] =
                                  dataWord.data.map((declarations) => {
                                    return {
                                      key: declarations.templateDescription,
                                      value: declarations.templateDescription,
                                      disabled: !!declarations.mandatory,
                                      mandatory: declarations.mandatory,
                                      url: declarations.templatePath,
                                      templateType: declarations.templateType,
                                      policyRiskId: this.policyRiskIds[index],
                                      riskTemplateId: declarations.id,
                                      dataInjection: declarations.dataInjection,
                                    };
                                  });

                                this.selectedDeclarations[product] = this
                                  .declarationOptions[product]
                                  ? this.declarationOptions[product]
                                      .filter((wording) => wording.mandatory)
                                      .map((word, key) => ({
                                        ...word,
                                        selected: true,
                                      }))
                                  : [];
                                let distinctDecArray: any[] =
                                  this.selectedDeclarations[product].filter(
                                    (obj, index, array) =>
                                      array.findIndex(
                                        (o) =>
                                          obj !== null &&
                                          o?.riskTemplateId ===
                                            obj?.riskTemplateId,
                                      ) === index,
                                  );
                                this.selectedDeclarations[product] =
                                  distinctDecArray;
                                this.spinnerCounter++;
                                this.policyRiskDocProcessService
                                  .getPolicyRiskDocCore(
                                    this.policyRiskIds[index],
                                  )
                                  .subscribe((dataRiskTempExist) => {
                                    // get policy declaration docs
                                    let selectedDeclarationsDocs =
                                      dataRiskTempExist?.data.filter(
                                        (doc) =>
                                          doc.templateType ==
                                          this.templateCodeForDeclaration,
                                      );

                                    let selectedDeclarationDocs = {
                                      [product]: selectedDeclarationsDocs.map(
                                        (doc) => {
                                          let declarationDocDetails =
                                            this.declarationOptions[
                                              product
                                            ].filter(
                                              (optn) =>
                                                optn.riskTemplateId ==
                                                doc?.riskTemplate.id,
                                            );

                                          return {
                                            key:
                                              declarationDocDetails[0]?.key ||
                                              '',
                                            value:
                                              declarationDocDetails[0]?.value ||
                                              '',
                                            disabled:
                                              !!declarationDocDetails[0]
                                                ?.mandatory,
                                            mandatory:
                                              declarationDocDetails[0]
                                                ?.mandatory,
                                            templateType:
                                              declarationDocDetails[0]
                                                ?.templateType,
                                            policyRiskId:
                                              this.policyRiskIds[index],
                                            url:
                                              declarationDocDetails[0]?.url ??
                                              '',
                                            selected: true,
                                            documentTblDeclarationId: doc?.id,
                                            riskTemplateId:
                                              declarationDocDetails[0]
                                                ?.riskTemplateId ?? null,
                                          };
                                        },
                                      ),
                                    };

                                    if (
                                      selectedDeclarationDocs[product].length >
                                      0
                                    ) {
                                      this.selectedDeclarations[product] =
                                        selectedDeclarationDocs[product];
                                      let distinctDecArray: any[] =
                                        this.selectedDeclarations[
                                          product
                                        ].filter(
                                          (obj, index, array) =>
                                            array.findIndex(
                                              (o) =>
                                                obj !== null &&
                                                o?.riskTemplateId ===
                                                  obj?.riskTemplateId,
                                            ) === index,
                                        );
                                      this.selectedDeclarations[product] =
                                        distinctDecArray;
                                    }
                                    this.spinnerCounter--;
                                  });
                                this.spinnerCounter--;
                              });
                          }
                        });
                        this.spinnerCounter--;
                      });
                  }
                  this.spinnerCounter--;
                });
              this.spinnerCounter--;
            });
          this.spinnerCounter--;
        });
    });
  }

  getTotalPremium() {
    let total = 0;
    this.policyRiskFee.map((product) => {
      total += Number(product.premium);
    });
    return total;
  }
  getServices() {
    let total = 0;
    total += Number(this.policyRiskFee[0].servicesAmount);
    return total || 0.0;
  }
  getTaxes() {
    let total = 0;
    total +=
      Number(this.policyRiskFee[0].taxesPremiumAmount) +
      Number(this.policyRiskFee[0].taxesServicesAmount);

    return total || 0.0;
  }

  async getTotalBilledAnnual() {
    const premium = this.getTotalPremium();
    const service = this.getServices();
    const tax = this.getTaxes();
    const tria = this.getTRIA();
    const policy = this.getPolicyFee();
    return (
      Number(premium) +
      Number(service) +
      Number(tax) +
      Number(tria) +
      Number(policy)
    );
  }

  async loadEndorsements(product, index) {
    const getEndorsements =
      this.policyRiskDocProcessService.getPolicyRiskDocCore(
        this.policyRiskIds[index],
      );
    const dataRiskTempExist = await firstValueFrom(getEndorsements);
    let selectedEndorsementDocs = dataRiskTempExist.data.filter(
      (doc) => doc.templateType == this.templateCodeForEndorsement,
    );
    let selectedEndorseDocs = {
      [product]: selectedEndorsementDocs.map((doc) => {
        let endorsementDocDetails = this.endorsementOptions[product].filter(
          (optn) => optn.riskTemplateId == doc?.riskTemplate.id,
        );

        return {
          key: endorsementDocDetails[0]?.key || '',
          value: endorsementDocDetails[0]?.value || '',
          disabled: !!endorsementDocDetails[0]?.mandatory,
          mandatory: endorsementDocDetails[0]?.mandatory,
          templateType: endorsementDocDetails[0]?.templateType,
          policyRiskId: this.policyRiskIds[index],
          url: endorsementDocDetails[0]?.url ?? '',
          selected: true,
          documentTblEndorsId: doc?.id || '',
          riskTemplateId: endorsementDocDetails[0]?.riskTemplateId ?? null,
          ignoreTemplate: endorsementDocDetails[0]?.ignoreTemplate ?? false,
        };
      }),
    };

    if (selectedEndorseDocs[product].length > 0) {
      this.selectedEndorsements[product] = [...selectedEndorseDocs[product]];
      let distinctEndArray: any[] = this.selectedEndorsements[product].filter(
        (obj, index, array) =>
          array.findIndex(
            (o) => obj !== null && o?.riskTemplateId === obj?.riskTemplateId,
          ) === index,
      );
      this.selectedEndorsements[product] = distinctEndArray;
    }

    const getCustomeEndorsements =
      this.policyRiskEndorsementService.getPolicyRiskEndorsementCore(
        this.policyRiskIds[index],
      );
    const customEndorsementList = await firstValueFrom(getCustomeEndorsements);

    if (customEndorsementList && customEndorsementList?.data.length > 0) {
      let selectedCustomEndorseDocs = {
        [product]: customEndorsementList?.data.map((doc) => {
          return {
            key: doc?.description || '',
            value: doc?.description || '',
            disabled: !!doc.active,
            mandatory: false,
            templateType: null,
            policyRiskId: this.policyRiskIds[index],
            url: '',
            selected: true,
            policyRiskEndorsId: doc?.id || '',
            documentTblEndorsId: 0, // 0 indicates custom endorsement
            riskTemplateId: null,
            ignoreTemplate: true,
          };
        }),
      };

      if (selectedCustomEndorseDocs[product].length > 0) {
        const customEndDocs = selectedCustomEndorseDocs[product];
        let distinctEndArray: any[] = customEndDocs.filter(
          (obj, index, array) =>
            array.findIndex(
              (o) =>
                obj !== null &&
                o?.policyRiskEndorsId === obj?.policyRiskEndorsId &&
                o?.key === obj?.key,
            ) === index,
        );
        this.selectedEndorsements[product] = [
          ...this.selectedEndorsements[product],
          ...distinctEndArray,
        ];
      }
    }
  }
  loadMoreUnderwriters(pageNumber) {
    if (this.underwriterTotalCount > this.underWriterOptions?.length) {
      this.populateUnderwriterOptions(pageNumber);
    }
  }

  populateUnderwriterOptions(page = 1) {
    const sortOrder = 'firstName:asc,lastName:asc';
    const systemUserState = 1;
    this.boxxUserService
      .GetUnderwriterlist(
        '',
        page,
        10,
        sortOrder,
        '',
        1,
        USER_ROLES.Underwriter,
        systemUserState,
      )
      .subscribe((data) => {
        const tableData = data.data?.map((dataObj) => ({
          key: dataObj.firstName + ' ' + dataObj.lastName,
          value: dataObj.firstName + ' ' + dataObj.lastName,
          id: dataObj.id,
        }));
        this.underWritersList = {
          ...this.underWritersList,
          ...data.data.reduce((accumulator, value) => {
            return {
              ...accumulator,
              [value.firstName + ' ' + value.lastName]: value,
            };
          }, {}),
        };
        this.underwriterTotalCount = data?.pagination?.totalRecords;
        this.underwriterTotalPages = data?.pagination?.totalPages;
        this.underWriterOptions = [...this.underWriterOptions, ...tableData];
      });
  }
  async updateCompliance(
    selectedIndex: number,
    product: string,
    isDelete: boolean = false,
  ) {
    let selectedSubjectivityList = this.selectedSubjectivities[product];
    if (!isDelete) {
      let selectedSubjectivity = selectedSubjectivityList[selectedIndex];
      this.selectedSubjectivities[product][selectedIndex].isCompliedWith =
        !selectedSubjectivity.isCompliedWith;
      await this.updateSubjectivity(
        this.selectedSubjectivities[product][selectedIndex],
        product,
      );
    }
    const complyFalseSubjectivities = selectedSubjectivityList.filter(
      (subjectivity) => subjectivity.isCompliedWith === false,
    );
    this.subjectivitiesComplyCheckbox = [
      {
        ...this.subjectivitiesComplyCheckbox[0],
        checked: complyFalseSubjectivities?.length > 0 ? false : true,
      },
    ];
  }
  handleBackBtn() {
    if (this.isBindingPolicy) {
      return;
    }
    this.newQuoteService.setIsNavigatedFromPolicyConfig(true);
    this.store.dispatch(new CreateQuoteAction.ResetIsNavigatedFromSummary());
    this.router.navigate(['dashboard/workflow3/quoting'], {
      skipLocationChange: true,
    });
  }

  getSelectedQuote(options) {
    return options.filter((option) => option.optionSelected == 1);
  }

  handleSubjectivitySearch(searchKey, product) {
    this.riskRegionSubjectivityService
      .getSubjectivity(this.riskRegionId, searchKey)
      .subscribe((data) => {
        this.subjectivityOptns[product] = data.data.map((sub) => {
          let selected = this.selectedSubjectivities[product].find(
            (x) =>
              x.riskRegionSubjectivitiesId &&
              x.riskRegionSubjectivitiesId === sub.id,
          )
            ? true
            : false;

          return {
            key: sub.description,
            value: sub.description,
            disabled: selected ? true : false,
            mandatory: sub.mandatory,
            riskRegionSubjectivitiesId: sub.id,
            isCompliedWith: !!sub.isCompliedWith,
            documentTblSubId: null,
          };
        });
      });
  }

  handleWordingSearch(searchKey, product) {
    const index = this.products.findIndex((p) => p == product);
    this.riskTemplateService
      .getRiskTemplate(
        1,
        this.riskRegionId,
        this.templateCodeForWordings,
        searchKey,
      )
      .subscribe((data) => {
        this.wordingsOptions[product] = data.data.map((doc) => {
          let selected = this.selectedWordings[product].find(
            (x) => x.riskTemplateId == doc.id,
          )
            ? true
            : false;

          return {
            key: doc.templateDescription,
            value: doc.templateDescription,
            disabled: selected ? true : false,
            mandatory: doc.mandatory,
            url: doc.templatePath,
            templateType: doc.templateType,
            policyRiskId: this.policyRiskIds[index],
            riskTemplateId: doc.id,
            documentTblWordId: null,
            dataInjection: doc.dataInjection,
          };
        });
      });
  }

  handleDeclarationSearch(searchKey, product) {
    const index = this.products.findIndex((p) => p == product);
    this.riskTemplateService
      .getRiskTemplate(
        1,
        this.riskRegionId,
        this.templateCodeForDeclaration,
        searchKey,
      )
      .subscribe((data) => {
        this.declarationOptions[product] = data.data.map((doc) => {
          let selected = this.selectedDeclarations[product].find(
            (x) => x.riskTemplateId == doc.id,
          )
            ? true
            : false;

          return {
            key: doc.templateDescription,
            value: doc.templateDescription,
            disabled: selected ? true : false,
            mandatory: doc.mandatory,
            url: doc.templatePath,
            templateType: doc.templateType,
            policyRiskId: this.policyRiskIds[index],
            riskTemplateId: doc.id,
            documentTblDeclarationId: null,
            dataInjection: doc.dataInjection,
          };
        });
      });
  }
  handleEndorsementSearch(searchKey, product) {
    const index = this.products.findIndex((p) => p == product);
    this.riskTemplateService
      .getRiskTemplate(
        1,
        this.riskRegionId,
        this.templateCodeForEndorsement,
        searchKey,
      )
      .subscribe((data) => {
        this.endorsementOptions[product] = data.data.map((endorsementDoc) => {
          let selected = this.selectedEndorsements[product].find(
            (x) => x.riskTemplateId && x.riskTemplateId == endorsementDoc.id,
          )
            ? true
            : false;

          return {
            key: endorsementDoc.templateDescription,
            value: endorsementDoc.templateDescription,
            disabled: selected ? true : false,
            mandatory: endorsementDoc.mandatory,
            url: endorsementDoc.templatePath,
            templateType: endorsementDoc.templateType,
            policyRiskId: this.policyRiskIds[index],
            riskTemplateId: endorsementDoc.id,
            documentTblEndorsId: -1,
            dataInjection: endorsementDoc.dataInjection,
            ignoreTemplate: endorsementDoc.ignoreTemplate,
          };
        });
      });
  }

  deleteEndorsementSelect(
    product: string,
    endorsement: { [x: string]: any },
    index: number,
  ) {
    this.alertService.clearAlerts(-1);
    this.showBodySpinner = true;
    let endorseId = endorsement['documentTblEndorsId'];
    if (endorseId > 0) {
      this.policyRiskDocProcessService
        .deletePolicyRiskDocCore(endorseId)
        .subscribe({
          next: () => {
            this.selectedEndorsements[product].splice(index, 1);
            this.showBodySpinner = false;
            setTimeout(() => {
              const alertData = {
                type: 'success',
                headerText: getAlertHead('success'),
                bodyText:
                  'workFlow3.components.policyConfiguration.message.endorsementDeletedSuccessfully',
              };
              this.alertService.addAlert(alertData);
            }, 100);
          },
          error: (error) => {
            this.showBodySpinner = false;
            if (![500].includes(error?.status)) {
              const alertData = {
                type: 'error',
                headerText: getAlertHead('error'),
                bodyText: error ?? '' ?? error?.message ?? '',
              };
              this.alertService.addAlert(alertData);
            }
          },
        });
    } else if (endorseId === 0) {
      // custom endorsements
      if (endorsement['policyRiskEndorsId']) {
        this.policyRiskEndorsementService
          .deletePolicyRiskEndorsementCore(endorsement['policyRiskEndorsId'])
          .subscribe({
            next: () => {
              this.selectedEndorsements[product].splice(index, 1);
              this.showBodySpinner = false;
              setTimeout(() => {
                const alertData = {
                  type: 'success',
                  headerText: getAlertHead('success'),
                  bodyText:
                    'workFlow3.components.policyConfiguration.message.endorsementDeletedSuccessfully',
                };
                this.alertService.addAlert(alertData);
              }, 100);
            },
            error: (error) => {
              this.showBodySpinner = false;
              if (![500].includes(error?.status)) {
                const alertData = {
                  type: 'error',
                  headerText: getAlertHead('error'),
                  bodyText: error ?? '' ?? error?.message ?? '',
                };
                this.alertService.addAlert(alertData);
              }
            },
          });
      } else {
        this.showBodySpinner = false;
        this.endorsementOptions[product].map((x) => {
          if (x.key == endorsement['value']) {
            x.disabled = false;
            x.selected = false;
          }
        });
        this.selectedEndorsements[product].splice(index, 1);
        const alertData = {
          type: 'success',
          headerText: getAlertHead('success'),
          bodyText:
            'workFlow3.components.policyConfiguration.message.endorsementDeletedSuccessfully',
        };
        this.alertService.addAlert(alertData);
      }
    } else {
      this.showBodySpinner = false;
      this.endorsementOptions[product].map((x) => {
        if (x.key == endorsement['value']) {
          x.disabled = false;
          x.selected = false;
        }
      });
      this.selectedEndorsements[product].splice(index, 1);
      const alertData = {
        type: 'success',
        headerText: getAlertHead('success'),
        bodyText:
          'workFlow3.components.policyConfiguration.message.endorsementDeletedSuccessfully',
      };
      this.alertService.addAlert(alertData);
    }
  }

  toggleWordingSelect(index, product) {
    this.selectedWordings[product].forEach((item, ind) => {
      if (index == ind) {
        this.selectedWordings[product][ind].selected = true;
      } else {
        this.selectedWordings[product][ind].selected = false;
      }
    });
  }

  toggleDeclarationSelect(index, product) {
    this.selectedDeclarations[product].forEach((item, ind) => {
      if (index == ind) {
        this.selectedDeclarations[product][ind].selected = true;
      } else {
        this.selectedDeclarations[product][ind].selected = false;
      }
    });
  }
  handleToggleSubSelect(index, product, data) {
    try {
      const isMandatory = this.selectedSubjectivities[product][index].mandatory;
      if (!isMandatory) {
        const value = !this.selectedSubjectivities[product][index]?.selected;
        const id = this.selectedSubjectivities[product][index].documentTblSubId;
        if (value) {
          if (this.numOfSubjectivity(product) < 10) {
            this.selectedSubjectivities[product][index] = {
              ...this.selectedSubjectivities[product][index],
              selected: !this.selectedSubjectivities[product][index]?.selected,
            };
          } else {
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: 'productSummary.error.subjectivityExceeded',
            };
            this.alertService.addAlert(alertData);
            return;
          }
        } else {
          this.selectedSubjectivities[product][index] = {
            ...this.selectedSubjectivities[product][index],
            selected: !this.selectedSubjectivities[product][index]?.selected,
          };
        }
        if (id) {
          if (value === true) {
            this.policySubjectivityService
              .restorePolicyRiskSubjectivity(id)
              .subscribe(
                (resp) => {},
                (error) => {
                  const alertData = {
                    type: 'error',
                    headerText: getAlertHead('error'),
                    bodyText: error ?? '' ?? error?.message ?? '',
                  };
                  this.alertService.addAlert(alertData);
                },
              );
          } else if (this.isNewSubjectivity(product, data)) {
            this.deleteSubjectivity(product, data, index);
          } else {
            this.policySubjectivityService
              .deletePolicyRiskSubjectivity(id)
              .subscribe(
                (resp) => {},
                (error) => {
                  const alertData = {
                    type: 'error',
                    headerText: getAlertHead('error'),
                    bodyText: error ?? '' ?? error?.message ?? '',
                  };
                  this.alertService.addAlert(alertData);
                },
              );
          }
        }
      }
    } catch (error) {
      if (![500].includes(error?.status)) {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: error ?? '' ?? error?.message ?? '',
        };
        this.alertService.addAlert(alertData);
      }
    }
  }
  docConfigChanged(id: number) {}
  closeSubjectivityPopup() {
    this.showModalSubjectivities = false;
  }
  closeEndorsementPopup() {
    this.showModalEndorsements = false;
  }
  closeDeclarationPopup() {
    this.showModalDeclarations = false;
  }
  closeWordingPopup() {
    this.showModalWordings = false;
  }
  handlePolicyConfigSelect(product) {
    if (
      !this.permissionList[this.currentScreen] ||
      this.currentQuoteStatus === 'quote-closed'
    ) {
      return;
    }
    const selectedtoAdd = this.docConfigForm.value['document'];
    switch (selectedtoAdd) {
      case 'Subjectivity':
        if (this.selectedSubjectivities[product]?.length < 10) {
          this.showModalSubjectivities = true;
        } else {
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: 'productSummary.error.subjectivityExceeded',
          };
          this.alertService.addAlert(alertData);
        }

        return;
      case 'Endorsement':
        if (
          this.selectedEndorsements[product]?.length <
          ALLOWED_ENDORSEMENTS_COUNT
        ) {
          this.showModalEndorsements = true;
        } else {
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText:
              'workFlow3.components.policyConfiguration.error.endorsementExceeded',
          };
          this.alertService.addAlert(alertData);
        }

        return;
      case 'Declaration':
        this.showModalDeclarations = true;
        return;
      case 'Wording':
        this.showModalWordings = true;
        return;
      default:
        return;
    }
  }

  handleDocConfigBtnClick(buttonId) {
    this.docConfigButtons = this.docConfigButtons.map((button) => {
      if (button.id === buttonId) {
        return {
          ...button,
          active: true,
        };
      } else {
        return {
          ...button,
          active: false,
        };
      }
    });
  }

  handleSubjectivitiesComplyCheckbox(event, selectedSubjectivities) {
    const checkedValue = event?.checked;
    if (!!checkedValue) {
      selectedSubjectivities.forEach((sub) => {
        const prevComplyValue = sub.isCompliedWith;
        sub.isCompliedWith = true;
        if (!prevComplyValue) {
          this.updateSubjectivity(sub);
        }
      });
      this.globalComplyChecked = true;
    } else {
      selectedSubjectivities.forEach((sub) => {
        const prevComplyValue = sub.isCompliedWith;
        sub.isCompliedWith = false;
        if (prevComplyValue) {
          this.updateSubjectivity(sub);
        }
      });
      this.globalComplyChecked = false;
    }
  }

  getSubjectivityComplyCheckbox(isCompliedWith, id) {
    return [
      {
        name: '',
        value: true,
        checked: isCompliedWith,
        id: id,
      },
    ];
  }

  underwriterChanged(id: number, index) {
    let selectedUnderwriter =
      this.underWriterOptions.filter(
        (underwriter) => underwriter.id === id,
      )?.[0] ?? {};
    this.productList.map((product: any) => {
      if (product.hasSubProduct) {
        let subProductIndex = this.products.findIndex(
          (prod) => prod === product.subProductName,
        );
        this.underWriters[index] = `${selectedUnderwriter?.value ?? ''}`;
        this.underWriters[subProductIndex] = `${
          selectedUnderwriter?.value ?? ''
        }`;
      } else {
        this.underWriters[index] = `${selectedUnderwriter?.value ?? ''}`;
      }
    });
    let underwriterId;
    let underwriterName;
    const policyRisk = this.policyRisks.find(
      (policyRisk) => policyRisk.riskId == this.productsIds[index],
    );
    if (policyRisk) {
      const policyRiskTrx = policyRisk.PolicyRiskTrxes[0];
    }
    underwriterId = id;
    underwriterName = selectedUnderwriter?.value ?? '';
    this.selectUnderwriterDetails = {
      id: underwriterId,
      key: underwriterName,
      value: underwriterName,
    };
    this.store.dispatch(
      new CreateQuoteAction.UpdateUnderwriterForPdtAction({
        index,
        underwriter: underwriterName,
        underwriterId,
      }),
    );
    this.saveUnderwriter(index);
  }

  async getUnderwriter(i: string | number) {
    return this.underWriters[i];
  }

  handleAttachEndorsement(docName, product) {
    this.alertService.clearAlerts(-1);
    if (
      this.selectedEndorsements[product]?.length < ALLOWED_ENDORSEMENTS_COUNT
    ) {
      if (docName) docName = docName.trim();
      else docName = '';
      if (docName.length < 3 || docName.length > 100) {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText:
            'workFlow3.components.policyConfiguration.error.endorsementLength',
        };
        this.alertService.addAlert(alertData);
        return;
      }
      let endorsToAdd: any = {};
      endorsToAdd = this.endorsementOptions[product].filter(
        (sub) => sub.key == docName,
      )[0];
      if (!endorsToAdd) endorsToAdd = {};
      endorsToAdd.disabled = true;
      if (Object.entries(endorsToAdd).length > 0) {
        endorsToAdd = {
          ...endorsToAdd,
          selected: true,
        };
      }
      if (endorsToAdd.riskTemplateId) {
        var findInd = this.selectedEndorsements[product].findIndex(
          (a) => a.riskTemplateId == endorsToAdd.riskTemplateId,
        );
        if (findInd == -1)
          this.selectedEndorsements[product] = [
            ...this.selectedEndorsements[product],
            endorsToAdd,
          ];
      } else {
        // custom endorsement
        const index = this.products.findIndex((p) => p == product);
        endorsToAdd.key = docName;
        endorsToAdd.value = docName;
        endorsToAdd.disabled = false;
        endorsToAdd.mandatory = false;
        endorsToAdd.templateType = null;
        endorsToAdd.policyRiskId = this.policyRiskIds[index];
        (endorsToAdd.url = ''),
          (endorsToAdd.selected = true),
          (endorsToAdd.documentTblEndorsId = 0); // 0 indicates custom endorsement
        endorsToAdd.riskTemplateId = null;
        endorsToAdd.ignoreTemplate = true;
        var findInd = this.selectedEndorsements[product].findIndex(
          (a) => a.key == endorsToAdd.key,
        );
        if (findInd == -1) {
          this.selectedEndorsements[product] = [
            ...this.selectedEndorsements[product],
            endorsToAdd,
          ];
        } else {
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText:
              'workFlow3.components.policyConfiguration.error.endorsementDuplicated',
          };
          this.alertService.addAlert(alertData);
        }
      }
    } else {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText:
          'workFlow3.components.policyConfiguration.error.endorsementExceeded',
      };
      this.alertService.addAlert(alertData);
    }
  }

  async handleAddSubjectivitiesFromPopup(data) {
    const subjectivitiesToAdd = data.subjectivitiesToAdd;
    const product = data.product;
    const subjectivitiesCreatedToAdd = data.subjectivitiesCreatedToAdd;
    for (let sub of subjectivitiesToAdd) {
      await this.handleAddSubjectives(sub.key, product);
    }
    await this.createNewSubjectivitiesFromPopup(
      subjectivitiesCreatedToAdd,
      product,
    );
    this.subjectivitiesComplyCheckbox = [
      {
        ...this.subjectivitiesComplyCheckbox[0],
        checked: false,
      },
    ];
    const productIndex = this.products.findIndex(
      (productName) => productName === product,
    );
    await this.saveSubjectivities(product, productIndex);
    this.showModalSubjectivities = false;
  }

  async handleAddEndorsementsFromPopup(data) {
    const endorsementsToAdd = data.endorsementsToAdd;
    const product = data.product;
    for (let endorsement of endorsementsToAdd) {
      await this.handleAttachEndorsement(endorsement.key, product);
    }
    const productIndex = this.products.findIndex(
      (productName) => productName === product,
    );
    await this.saveEndorsements(product, productIndex);
    this.showModalEndorsements = false;
  }

  async handleAddDeclarationFromPopup(data) {
    const product = data.product;
    await this.handleSelectDeclaration(data.declaration, data.product);
    const productIndex = this.products.findIndex(
      (productName) => productName === product,
    );
    await this.saveDeclarations(product, productIndex);
    this.showModalDeclarations = false;
  }

  async handleAddWordingFromPopup(data) {
    const product = data.product;
    await this.handleSelectWording(data.wording, data.product);
    const productIndex = this.products.findIndex(
      (productName) => productName === product,
    );
    await this.saveWordings(product, productIndex);
    this.showModalWordings = false;
  }

  handleAddSubjectives(name, product) {
    this.alertService.clearAlerts(-1);
    if (name.length < 3 || name.length > 1000) {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText:
          'workFlow3.components.policyConfiguration.error.subjectivityLength',
      };
      this.alertService.addAlert(alertData);
      return;
    }
    if (this.selectedSubjectivities[product].length < 10) {
      let subToAdd = this.subjectivityOptns[product].filter(
        (sub) => sub.key == name,
      )[0];
      subToAdd.disabled = true;
      if (Object.entries(subToAdd).length > 0) {
        subToAdd = {
          ...subToAdd,
          selected: true,
        };
      }
      var findInd = this.selectedSubjectivities[product].findIndex(
        (a) =>
          a.riskRegionSubjectivitiesId == subToAdd.riskRegionSubjectivitiesId,
      );
      if (findInd == -1)
        this.selectedSubjectivities[product] = [
          ...this.selectedSubjectivities[product],
          subToAdd,
        ];
    } else {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: 'productSummary.error.subjectivityExceeded',
      };
      this.alertService.addAlert(alertData);
      return;
    }
  }

  handleSelectWording(word, product) {
    let wordToAdd = this.wordingsOptions[product].filter(
      (w) => w.key == word,
    )[0];
    wordToAdd.disabled = true;
    if (Object.entries(wordToAdd).length > 0) {
      wordToAdd = {
        ...wordToAdd,
        selected: true,
      };
    }
    var findInd = this.selectedWordings[product].findIndex(
      (a) => a.riskTemplateId == wordToAdd.riskTemplateId,
    );
    if (findInd == -1) {
      this.selectedWordings[product] = this.selectedWordings[product].map(
        (item) => {
          return { ...item, selected: false };
        },
      );
      if (this.selectedWordings[product][0].documentTblWordId)
        // Since there will be only one wording can be selected under each quote so that 0 is given.
        wordToAdd.documentTblWordId =
          this.selectedWordings[product][0].documentTblWordId;
      this.selectedWordings[product] = [wordToAdd];
    }
  }

  handleSelectDeclaration(declaration, product) {
    let declarationToAdd = this.declarationOptions[product].filter(
      (w) => w.key == declaration,
    )[0];
    declarationToAdd.disabled = true;
    if (Object.entries(declarationToAdd).length > 0) {
      declarationToAdd = {
        ...declarationToAdd,
        selected: true,
      };
    }
    var findInd = this.selectedDeclarations[product].findIndex(
      (a) => a.riskTemplateId == declarationToAdd.riskTemplateId,
    );
    if (findInd == -1) {
      this.selectedDeclarations[product] = this.selectedDeclarations[
        product
      ].map((item) => {
        return { ...item, selected: false };
      });
      if (this.selectedDeclarations[product][0].documentTblDeclarationId)
        // Since there will be only one declaration can be selected under each quote so that 0 is given.
        declarationToAdd.documentTblDeclarationId =
          this.selectedDeclarations[product][0].documentTblDeclarationId;
      this.selectedDeclarations[product] = [declarationToAdd];
    }
  }

  async saveSubjectivities(product, index) {
    return new Promise<any>(async (resolve, reject) => {
      if (this.selectedSubjectivities[product]?.length === 0) {
        resolve(true);
        return;
      }
      let dataSubCreate = [];
      let dataSubUpdate = [];
      let selectedSubjCreate = this.selectedSubjectivities[product].filter(
        (s) => s.selected == true && !s.documentTblSubId,
      );
      let selectedSubjUpdate = this.selectedSubjectivities[product].filter(
        (s) => s.selected == true && s.documentTblSubId,
      );
      dataSubCreate = selectedSubjCreate.map((sub) => ({
        policyRiskId: this.policyRiskIds[index],
        riskRegionSubjectivitiesId: sub.riskRegionSubjectivitiesId,
        description: sub.key,
        isCompliedWith: !!sub.isCompliedWith,
      }));
      dataSubUpdate = selectedSubjUpdate.map((sub) => ({
        documentTblSubId: sub.documentTblSubId,
        isCompliedWith: !!sub.isCompliedWith,
        key: sub.key,
        riskRegionSubjectivitiesId: sub.riskRegionSubjectivitiesId,
      }));
      const payloadForSubjectivityCreate = {
        policyRiskSubjectivity: dataSubCreate,
      };

      if (dataSubCreate.length > 0) {
        await this.policySubjectivityService
          .createPolicyRiskSubjectivity(payloadForSubjectivityCreate)
          .subscribe({
            next: (resp) => {
              this.isContinueFormValid = true;
              resolve(true);
              for (const [index, product] of this.products.entries()) {
                this.spinnerCounter++;
                // get subjectivities for each product
                this.getAllLatestSubjectivities(product, index);
              }
            },
            error: (error) => {
              this.isContinueFormValid = false;
              const alertData = {
                type: 'error',
                headerText: getAlertHead('error'),
                bodyText: getErrorMessage(error),
              };
              this.alertService.addAlert(alertData);
            },
          });
      } else {
        this.isContinueFormValid = true;
        resolve(true);
      }
    });
  }

  async updateSubjectivity(subjectivityData, product = null) {
    let dataSub = {
      description: subjectivityData.key,
      isCompliedWith: subjectivityData.isCompliedWith,
    };

    await this.policySubjectivityService
      .updatePolicyRiskSubjectivity(subjectivityData.documentTblSubId, dataSub)
      .subscribe({
        next: (resp) => {
          if (subjectivityData.riskRegionSubjectivitiesId && product) {
            var findInd = this.selectedSubjectivities[product].findIndex(
              (a) =>
                a.riskRegionSubjectivitiesId ==
                subjectivityData.riskRegionSubjectivitiesId,
            );
            if (findInd != -1)
              this.selectedSubjectivities[product][findInd] = {
                ...this.selectedSubjectivities[product][findInd],
                riskRegionSubjectivitiesId: null,
              };
          }
          this.isContinueFormValid = true;
          // resolve(true);
        },
        error: (error) => {
          this.isContinueFormValid = false;
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: getErrorMessage(error),
          };
          this.alertService.addAlert(alertData);
        },
      });
  }

  saveEndorsements(product, index) {
    return new Promise<any>(async (resolve, reject) => {
      let endorsementDataCreate = [];
      let endorsementDataUpdate = [];
      let customEndorsementDataCreate = [];
      let customEndorsementDataUpdate = [];

      let selectEndorsementsCreate = this.selectedEndorsements[product].filter(
        (s) => s.selected == true && s.documentTblEndorsId === -1,
      );
      let selectEndorsementsUpdate = this.selectedEndorsements[product].filter(
        (s) => s.selected == true && s.documentTblEndorsId > 0,
      );
      let selectCustomEndorsementsCreate = this.selectedEndorsements[
        product
      ].filter(
        (s) =>
          s.selected == true &&
          s.documentTblEndorsId === 0 &&
          !s.policyRiskEndorsId,
      );
      let selectCustomEndorsementsUpdate = this.selectedEndorsements[
        product
      ].filter(
        (s) =>
          s.selected == true &&
          s.documentTblEndorsId === 0 &&
          s.policyRiskEndorsId,
      );

      endorsementDataCreate = selectEndorsementsCreate.map((endorsement) => ({
        riskTemplateId: endorsement.riskTemplateId,
        policyRiskId: this.policyRiskIds[index],
        documentName: endorsement.key,
        templateType: this.templateCodeForEndorsement,
        dataInjection: endorsement.dataInjection,
      }));
      endorsementDataUpdate = selectEndorsementsUpdate.map((endorsement) => ({
        riskTemplateId: endorsement.riskTemplateId,
        policyRiskId: this.policyRiskIds[index],
        documentName: endorsement.key,
        templateType: this.templateCodeForEndorsement,
        documentTblEndorsId: endorsement.documentTblEndorsId,
        dataInjection: endorsement.dataInjection,
      }));

      customEndorsementDataCreate = selectCustomEndorsementsCreate.map(
        (endorsement) => ({
          policyRiskId: this.policyRiskIds[index],
          description: endorsement.key,
        }),
      );
      customEndorsementDataUpdate = selectCustomEndorsementsUpdate.map(
        (endorsement) => ({
          description: endorsement.key,
          policyRiskId: this.policyRiskIds[index],
          policyRiskEndorsId: endorsement.policyRiskEndorsId,
        }),
      );

      const payloadForPolicyRiskDocCreate = {
        policyRiskDocs: endorsementDataCreate,
      };

      const payloadForCustomEndorsementCreate = {
        policyRiskEndorsement: customEndorsementDataCreate,
      };
      // let apiCount = 0;
      // if (endorsementDataCreate.length > 0) apiCount++;
      //if (endorsementDataUpdate.length > 0) apiCount++;
      // if (customEndorsementDataCreate.length > 0) apiCount++;
      //if (customEndorsementDataUpdate.length > 0) apiCount++;
      let apiCallStartCount = 0;
      if (endorsementDataCreate.length > 0) {
        apiCallStartCount++;
        await this.policyRiskDocProcessService
          .createPolicyRiskDoc(payloadForPolicyRiskDocCreate)
          .subscribe({
            next: async (resp) => {
              apiCallStartCount--;
              if (apiCallStartCount === 0) {
                await this.loadEndorsements(product, index);
                this.isContinueFormValid = true;
                resolve(true);
              }
            },
            error: (error) => {
              this.isContinueFormValid = false;
              const alertData = {
                type: 'error',
                headerText: getAlertHead('error'),
                bodyText: getErrorMessage(error),
              };
              this.alertService.addAlert(alertData);
              reject('error');
            },
          });
      }
      if (customEndorsementDataCreate.length > 0) {
        apiCallStartCount++;
        await this.policyRiskEndorsementService
          .createPolicyRiskEndorsement(payloadForCustomEndorsementCreate)
          .subscribe({
            next: async (resp) => {
              apiCallStartCount--;
              if (apiCallStartCount === 0) {
                await this.loadEndorsements(product, index);
                this.isContinueFormValid = true;
                resolve(true);
              }
            },
            error: (error) => {
              this.isContinueFormValid = false;
              const alertData = {
                type: 'error',
                headerText: getAlertHead('error'),
                bodyText: getErrorMessage(error),
              };
              this.alertService.addAlert(alertData);
              reject('error');
            },
          });
      }
      if (apiCallStartCount === 0) {
        this.isContinueFormValid = true;
        resolve(true);
      }
    });
  }

  async updateCustomEndorsement(customEndorsementData, product) {
    const index = this.products.findIndex(
      (productName) => productName === product,
    );
    const payload = {
      description: customEndorsementData.key,
      policyRiskId: this.policyRiskIds[index],
      policyRiskEndorsId: customEndorsementData.policyRiskEndorsId,
    };
    await this.policyRiskEndorsementService
      .updatePolicyRiskEndorsement(
        customEndorsementData.policyRiskEndorsId,
        payload,
      )
      .subscribe({
        next: (resp) => {
          this.isContinueFormValid = true;
        },
        error: (error) => {
          this.isContinueFormValid = false;
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: getErrorMessage(error),
          };
          this.alertService.addAlert(alertData);
        },
      });
  }

  saveWordings(product, index) {
    return new Promise<any>(async (resolve, reject) => {
      let selectWordings = this.selectedWordings[product].filter(
        (w) => w.selected == true,
      );
      let selectWordingsUpdate = this.selectedWordings[product].filter(
        (w) => w.documentTblWordId,
      );
      // req: there will be only one wording at a time to each product under quote
      if (selectWordings.length === 1) {
        const wordingsData = {
          riskTemplateId: selectWordings[0].riskTemplateId,
          policyRiskId: this.policyRiskIds[index],
          documentName: selectWordings[0].key,
          templateType: this.templateCodeForWordings,
          documentTblWordId: selectWordings[0].documentTblWordId,
          dataInjection: selectWordings[0].dataInjection,
        };
        const payloadForPolicyRiskDocCreate = {
          policyRiskDocs: [wordingsData],
        };

        if (selectWordingsUpdate.length === 0) {
          await this.policyRiskDocProcessService
            .createPolicyRiskDoc(payloadForPolicyRiskDocCreate)
            .subscribe({
              next: (resp) => {
                this.isContinueFormValid = true;
                resolve(true);
              },
              error: (error) => {
                this.isContinueFormValid = false;
                const alertData = {
                  type: 'error',
                  headerText: getAlertHead('error'),
                  bodyText: getErrorMessage(error),
                };
                this.alertService.addAlert(alertData);
                resolve(true);
              },
            });
        }
        if (selectWordingsUpdate.length === 1) {
          await this.policyRiskDocProcessService
            .updatePolicyRiskDoc(
              selectWordingsUpdate[0].documentTblWordId,
              wordingsData,
            )
            .subscribe({
              next: (resp) => {
                this.isContinueFormValid = true;
                resolve(true);
              },
              error: (error) => {
                this.isContinueFormValid = false;
                const alertData = {
                  type: 'error',
                  headerText: getAlertHead('error'),
                  bodyText: getErrorMessage(error),
                };
                this.alertService.addAlert(alertData);
                resolve(true);
              },
            });
        }
      } else {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: 'workFlow3.components.shared.error.wordingsError',
        };
        this.alertService.addAlert(alertData);
        resolve(true);
      }
    });
  }

  saveDeclarations(p, i) {
    return new Promise<any>(async (resolve, reject) => {
      let selectDeclarations = this.selectedDeclarations[p].filter(
        (w) => w.selected == true,
      );
      let selectDeclarationsUpdate = this.selectedDeclarations[p].filter(
        (w) => w.documentTblDeclarationId,
      );

      // req: there will be only one Declaration at a time to each product under quote
      if (selectDeclarations.length == 1) {
        const declarationsData = {
          riskTemplateId: selectDeclarations[0].riskTemplateId,
          policyRiskId: this.policyRiskIds[i],
          documentName: selectDeclarations[0].key,
          templateType: this.templateCodeForDeclaration,
          documentTblDeclarationId:
            selectDeclarations[0].documentTblDeclarationId,
          dataInjection: selectDeclarations[0].dataInjection,
        };

        const payloadForPolicyRiskDocCreate = {
          policyRiskDocs: [declarationsData],
        };
        if (selectDeclarationsUpdate.length === 0) {
          await this.policyRiskDocProcessService
            .createPolicyRiskDoc(payloadForPolicyRiskDocCreate)
            .subscribe({
              next: (resp) => {
                this.isContinueFormValid = true;
                resolve(true);
              },
              error: (error) => {
                this.isContinueFormValid = false;
                const alertData = {
                  type: 'error',
                  headerText: getAlertHead('error'),
                  bodyText: getErrorMessage(error),
                };
                this.alertService.addAlert(alertData);
              },
            });
        }
        if (selectDeclarationsUpdate.length === 1) {
          await this.policyRiskDocProcessService
            .updatePolicyRiskDoc(
              selectDeclarationsUpdate[0].documentTblDeclarationId,
              declarationsData,
            )
            .subscribe({
              next: (resp) => {
                this.isContinueFormValid = true;
                resolve(true);
              },
              error: (error) => {
                this.isContinueFormValid = false;
                const alertData = {
                  type: 'error',
                  headerText: getAlertHead('error'),
                  bodyText: getErrorMessage(error),
                };
                this.alertService.addAlert(alertData);
              },
            });
        }
      } else {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: 'workFlow3.components.shared.error.declarationsError',
        };
        this.alertService.addAlert(alertData);
        resolve(true);
      }
    });
  }

  async saveDateDetails() {
    let formData: any;
    this.form$.subscribe((event) => (formData = event));

    // save dates
    const policyPeriodData = {
      effectiveDt: getFormatDate(
        formData.ui.startDate,
        this.shortDateFormat,
        'YYYY-MM-DD',
      ),
      expiryDt: getFormatDate(
        formData.ui.endDate,
        this.shortDateFormat,
        'YYYY-MM-DD',
      ),
      pendingPriorDt:
        formData.ui.pendingPriorDt && formData.ui.pendingPriorDt !== ''
          ? getFormatDate(
              formData.ui.pendingPriorDt,
              this.shortDateFormat,
              'YYYY-MM-DD',
            )
          : null,
      retroactiveDt:
        formData.ui.retroactiveDt && formData.ui.retroactiveDt !== ''
          ? getFormatDate(
              formData.ui.retroactiveDt,
              this.shortDateFormat,
              'YYYY-MM-DD',
            )
          : null,
      effectiveExpiryDatesTBDFlag: this.isTBDValid ? 1 : 0,
    };
    this.policyPeriodService
      .Update(this.policyPeriodId, policyPeriodData)
      .pipe(take(1))
      .subscribe({
        next: (resp) => {
          this.isContinueFormValid = true;
        },
        error: (error) => {
          this.isContinueFormValid = false;
          this.showBodySpinner = false;
          if (![500].includes(error?.status)) {
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
            return;
          }
        },
      });
  }

  saveUnderwriter(i) {
    return new Promise<any>(async (resolve, reject) => {
      const policyRisk = this.policyRisks.find(
        (policyRisk) => policyRisk.riskId == this.productsIds[i],
      );
      if (policyRisk) {
        const policyRiskTrx = policyRisk.PolicyRiskTrxes[0];
        const underwriter = await this.getUnderwriter(i);
        const riskTrxData = {
          underwriterId:
            this.underWritersList[underwriter]?.id ??
            policyRiskTrx?.underwriterId,
        };

        this.policyRiskTrxService
          .Update(policyRiskTrx.id, riskTrxData)
          .subscribe({
            next: (resp) => {
              this.isContinueFormValid = true;
              resolve(true);
            },
            error: (error) => {
              if (![500].includes(error.status)) {
                const alertData = {
                  type: 'error',
                  headerText: getAlertHead('error'),
                  bodyText: getErrorMessage(error),
                };
                this.alertService.addAlert(alertData);
              }
              this.isContinueFormValid = false;
              this.showBodySpinner = false;
            },
          });
      }
    });
  }

  async handleSaveDraft(isExitFlow = true, skipPolicyFeeValidation = false) {
    if (this.isBindingPolicy) {
      return;
    }
    await this.saveDetails(true, skipPolicyFeeValidation);
    if (this.isContinueFormValid) {
      await Promise.all([this.handleDocumentTempSave()]);

      await this.loadPageData();
      if (isExitFlow) {
        this.handleExitFlow();
      }
    } else this.showBodySpinner = false;
  }

  async handleProductSummaryAndBindPolicy() {
    this.showBodySpinner = true;
    if (this.isTBDValid) {
      this.showBodySpinner = false;
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: 'error.tbdValidationError',
      };
      this.alertService.addAlert(alertData);
      return;
    }
    if (!this.isQuoteSelected) {
      this.showBodySpinner = false;
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: 'error.selectQuoteOption',
      };
      this.alertService.addAlert(alertData);
      return;
    }

    if (!this.checkIfAllTemplateSelected()) {
      this.showBodySpinner = false;
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: this.translate.instant(
          'workFlow3.components.policyConfiguration.error.allDocTempValidationError',
        ),
      };
      this.alertService.addAlert(alertData);
      return;
    }
    await this.saveDetails();
    if (this.isContinueFormValid) {
      await this.handleBindPolicy();
    }
  }

  async saveDetails(
    showSaveDraftSuccessMessage: boolean = false,
    skipTriaPolicyFeeValidation = false,
  ) {
    this.alertService.clearAlerts(-1);
    if (!skipTriaPolicyFeeValidation) {
      let isValid: boolean = await this.handlePolicyAndTriaValidation();
      if (!isValid) {
        this.isContinueFormValid = false;
        this.isDropdownActive = true;
        return;
      }
    }

    try {
      if (!this.isEndDateValid || !this.isStartDateValid) {
        this.showBodySpinner = false;
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: 'error.pleaseSelectDateFields',
        };
        this.alertService.addAlert(alertData);
        this.isContinueFormValid = false;
        return;
      }

      if (!this.isPendingPriorDtValid || !this.isRetroactiveDtValid) {
        this.showBodySpinner = false;
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: 'error.validationError',
        };
        this.alertService.addAlert(alertData);
        this.isContinueFormValid = false;
        return;
      }

      this.showBodySpinner = true;

      await this.saveDateDetails();

      for (const [i, p] of this.products.entries()) {
        if (
          this.selectedDeclarations[p].length == 0 &&
          this.selectedWordings.length == 0
        ) {
          this.wordingsDeclarationError = true;
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: 'productSummary.error.wordingDeclarationMandatory',
          };
          this.alertService.addAlert(alertData);

          // this.showBodySpinner = false;
          this.isContinueFormValid = false;
          return;
        } else {
          this.wordingsDeclarationError = false;
          try {
            await Promise.all([
              this.saveEndorsements(p, i),
              this.saveWordings(p, i),
              this.saveDeclarations(p, i),
              this.saveSubjectivities(p, i),
            ]);
            if (this.wordingsDeclarationError || !this.isContinueFormValid) {
              // Need to add error message
              this.showBodySpinner = false;
              this.isContinueFormValid = false;
              return;
            }

            if (showSaveDraftSuccessMessage) {
              const alertData = {
                type: 'success',
                headerText: getAlertHead('success'),
                bodyText: 'productSummary.success.policyRiskUpdated',
              };
              this.alertService.addAlert(alertData);
            }
          } catch (error) {
            this.showBodySpinner = false;
            this.isContinueFormValid = false;
            return;
          }
        }
      }
    } catch (error) {
      if (![500].includes(error?.status)) {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: getErrorMessage(error),
        };
        this.alertService.addAlert(alertData);
      }
      this.showBodySpinner = false;
    }
  }

  toggleDocPreview() {
    this.showDocPreview = !this.showDocPreview;
  }

  handleDocClick(obj: any) {
    this.alertService.clearAlerts(-1);
    if (obj.ignoreTemplate === true) {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: this.translate.instant('common.docNoPreviewMsg'),
      };
      this.alertService.addAlert(alertData);
      return;
    }
    const templateId = obj?.riskTemplateId;
    try {
      this.showBodySpinner = true;
      if (templateId) {
        this.PolicyRiskDocPreviewService.GetPolicyRiskDocToPreview(
          templateId,
          'template',
        ).subscribe({
          next: (documentData) => {
            if (documentData?.data) {
              this.currentPreviewDocUrl = documentData.data.content;
              this.toggleDocPreview();
              this.showBodySpinner = false;
            } else {
              this.showDocPreview = false;
              this.currentPreviewDocUrl = '';
              this.showBodySpinner = false;
            }
          },
          error: (error) => {
            this.currentPreviewDocUrl = '';
            this.showBodySpinner = false;
            if (![500].includes(error?.status)) {
              const alertData = {
                type: 'error',
                headerText: getAlertHead('error'),
                bodyText: getErrorMessage(error),
              };
              this.alertService.addAlert(alertData);
            }
          },
        });
      } else {
        this.showDocPreview = false;
        this.currentPreviewDocUrl = '';
        this.showBodySpinner = false;
      }
    } catch (error) {
      if (![500].includes(error?.status)) {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: getErrorMessage(error),
        };
        this.alertService.addAlert(alertData);
      }
      this.currentPreviewDocUrl = '';
      this.showBodySpinner = false;
    }
  }
  checkIfStartDateValid(date) {
    const upperLimit = new Date(
      getDayAfterSpecifiedDays(new Date(), 60, this.shortDateFormat),
    );
    const lowerLimit = new Date(
      getDayBeforeSpecifiedDays(new Date(), 30, this.shortDateFormat),
    );

    if (!date) {
      this.isStartDateValid = false;
      this.startDateErrMsg = 'error.thisFieldIsRequired';
    } else if (date > upperLimit) {
      this.isStartDateValid = false;

      this.startDateErrMsg = 'error.policyMaxEffectiveErrorMessage';
    } else if (date < lowerLimit) {
      this.isStartDateValid = false;

      this.startDateErrMsg = 'error.policyMinEffectiveDateErrorMessage';
    } else {
      this.isStartDateValid = true;
      this.startDateErrMsg = null;
    }
  }
  getTomorrow() {
    return new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  }
  handleStartDateChange(date, endDate) {
    if (this.isTBDValid) {
      return;
    }
    this.checkIfStartDateValid(date);
    if (!date) {
      return;
    }
    this.minEndDate = calculateDateAfterMonths(
      date,
      3,
      this.shortDateFormat,
      true,
    );
    this.maxEndDate = calculateDateAfterMonths(
      date,
      18,
      this.shortDateFormat,
      true,
    );
    this.checkIfEndDateValid(endDate, date);
    if (date) {
      const formattedStartDate = formatDate(
        new Date(date),
        this.shortDateFormat,
      );

      let expiryDate = new Date(date).setFullYear(date.getFullYear() + 1);
      const formattedEndDate = formatDate(
        new Date(expiryDate),
        this.shortDateFormat,
      );
      this.store.dispatch(
        new CreateQuoteAction.updateUiContents({
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        }),
      );
      this.isEndDateValid = true;
      this.endDateErrMsg = null;
    } else {
      this.store.dispatch(
        new CreateQuoteAction.updateUiContents({
          startDate: this.defaultEffectiveDate,
        }),
      );
    }
  }

  checkIfEndDateValid(date, startDate) {
    if (!startDate) {
      startDate = this.getTomorrow();
    }

    let lowerLimit = calculateDateAfterMonths(
      startDate,
      3,
      this.shortDateFormat,
      true,
    );
    let upperLimit = calculateDateAfterMonths(
      startDate,
      18,
      this.shortDateFormat,
      true,
    );
    if (!date) {
      this.isEndDateValid = false;
      this.endDateErrMsg = 'error.thisFieldIsRequired';
    } else if (date > upperLimit) {
      this.isEndDateValid = false;

      this.endDateErrMsg = 'error.policyMaxExpiryDateErrorMessage';
    } else if (date < lowerLimit) {
      this.isEndDateValid = false;

      this.endDateErrMsg = 'error.policyMinExpiryDateErrorMessage';
    } else {
      this.isEndDateValid = true;
      this.endDateErrMsg = null;
    }
  }

  isDateWithinRange(date, effectiveDate, expiryDate) {
    const givenDate = new Date(
      formatDate(new Date(date), this.shortDateFormat),
    );
    const startDate = new Date(
      formatDate(new Date(effectiveDate), this.shortDateFormat),
    );
    const endDate = new Date(
      formatDate(new Date(expiryDate), this.shortDateFormat),
    );

    return givenDate >= startDate && givenDate <= endDate;
  }

  handleEndDateChange(endDate, startDate) {
    if (this.isTBDValid) {
      return;
    }
    if (startDate) {
      startDate = new Date(
        formatDate(new Date(startDate), this.shortDateFormat),
      );
    } else {
      startDate = new Date();
    }

    this.checkIfEndDateValid(endDate, startDate);
    if (!endDate) {
      return;
    }
    if (endDate) {
      const formattedEndDate = formatDate(
        new Date(endDate),
        this.shortDateFormat,
      );
      this.store.dispatch(
        new CreateQuoteAction.updateUiContents({
          endDate: formattedEndDate,
        }),
      );
    } else {
      this.store.dispatch(
        new CreateQuoteAction.updateUiContents({
          endDate: this.defaultExpiryDate,
        }),
      );
    }
  }

  getDateObject(date) {
    return new Date(formatDate(new Date(date), this.shortDateFormat));
  }

  handlePendingPriorDateChange(pendingPriorDate, effectiveDate, expiryDate) {
    if (this.isTBDValid) {
      return;
    }

    if (!pendingPriorDate || pendingPriorDate === '') {
      this.store.dispatch(
        new CreateQuoteAction.updateUiContents({
          pendingPriorDt: '',
        }),
      );
    } else {
      const pendingPriorDt = formatDate(
        new Date(pendingPriorDate),
        this.shortDateFormat,
      );
      this.store.dispatch(
        new CreateQuoteAction.updateUiContents({
          pendingPriorDt: pendingPriorDt,
        }),
      );
    }
  }

  handleRetroactiveDateChange(retroactiveDate, effectiveDate, expiryDate) {
    if (this.isTBDValid) {
      return;
    }

    if (!retroactiveDate || retroactiveDate === '') {
      this.store.dispatch(
        new CreateQuoteAction.updateUiContents({
          retroactiveDt: '',
        }),
      );
      this.isRetroactiveDtValid = true;
      this.retroactiveDtErrMsg = null;
    } else {
      const retroactiveDt = formatDate(
        new Date(retroactiveDate),
        this.shortDateFormat,
      );
      this.store.dispatch(
        new CreateQuoteAction.updateUiContents({
          retroactiveDt: retroactiveDt,
        }),
      );
    }
  }

  getFormattedDate(date) {
    return formatDate(date, this.shortDateFormat);
  }
  createNewSubjectivity(data, product) {
    this.alertService.clearAlerts(-1);
    if (data.length < 3 || data.length > 1000) {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText:
          'workFlow3.components.policyConfiguration.error.subjectivityLength',
      };
      this.alertService.addAlert(alertData);
      return;
    }

    if (this.numOfSubjectivity(product) < 10) {
      const isSubjectivityInDropdown = this.subjectivityOptns[product].filter(
        (subInDropdown) =>
          subInDropdown?.key?.toLowerCase() === data.toLowerCase(),
      );
      if (isSubjectivityInDropdown.length > 0) {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: 'productSummary.error.subjectivityAlreadyInDropdown',
        };
        this.alertService.addAlert(alertData);
        return;
      }
      let subToAdd = {
        disabled: true,
        documentTblSubId: null,
        isCompliedWith: false,
        key: data,
        mandatory: false,
        riskRegionSubjectivitiesId: null,
        selected: true,
        value: data,
        edit: false,
      };
      var findAd = this.selectedSubjectivities[product].findIndex(
        (a) => a.key === subToAdd.key,
      );
      if (findAd == -1) {
        if (
          this.addedSubjectivities[product] &&
          this.addedSubjectivities[product].length > 0
        ) {
          var findInd = this.addedSubjectivities[product].findIndex(
            (a) => a.key === subToAdd.key,
          );
          if (findInd == -1) {
            this.selectedSubjectivities[product] = [
              ...this.selectedSubjectivities[product],
              subToAdd,
            ];
            this.addedSubjectivities[product] = [
              ...this.addedSubjectivities[product],
              subToAdd,
            ];
          } else {
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText:
                'workFlow3.components.policyConfiguration.error.subjectivityExists',
            };
            this.alertService.addAlert(alertData);
          }
        } else {
          this.selectedSubjectivities[product] = [
            ...this.selectedSubjectivities[product],
            subToAdd,
          ];
          this.addedSubjectivities[product] = [subToAdd];
        }
      } else {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText:
            'workFlow3.components.policyConfiguration.error.subjectivityExists',
        };
        this.alertService.addAlert(alertData);
      }
    } else {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: 'productSummary.error.subjectivityExceeded',
      };
      this.alertService.addAlert(alertData);
    }
  }

  async createNewSubjectivitiesFromPopup(createdSubjectivities, product) {
    for (let sub of createdSubjectivities) {
      await this.createNewSubjectivity(sub, product);
    }
  }
  editSubjectivity(product, data, active) {
    this.handleActionPopup(undefined);
    this.selectedSubjectivities[product] = this.selectedSubjectivities[
      product
    ].map((x) => {
      x.edit = false;
      if (active == 1) {
        if (x.key == data.key) {
          x.edit = true;
          this.editedSubjectivity = data.key;
        }
      }
      return x;
    });
  }
  deleteSubjectivity(product, data, index) {
    this.alertService.clearAlerts(-1);
    this.handleActionPopup(undefined);
    this.showBodySpinner = true;

    if (data.documentTblSubId) {
      this.policySubjectivityService
        .deletePolicyRiskSubjectivity(data.documentTblSubId)
        .subscribe({
          next: async (resp) => {
            await this.removeFromSubjectivityList(product, data);
          },
          error: (error) => {
            this.showBodySpinner = false;
            this.alertService.addAlert({
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
            });
          },
        });
    } else {
      this.removeFromSubjectivityList(product, data);
    }
    this.updateCompliance(index, product, true);
  }

  async removeFromSubjectivityList(product, data) {
    var findAd = this.selectedSubjectivities[product].findIndex(
      (a) => a.key === data.key,
    );
    if (findAd >= 0) {
      this.selectedSubjectivities[product].splice(findAd, 1);
    }
    if (
      this.addedSubjectivities[product] &&
      this.addedSubjectivities[product].length > 0
    ) {
      var findInd = this.addedSubjectivities[product].findIndex(
        (x) => x.key == data.key,
      );
      if (findInd >= 0) {
        this.addedSubjectivities[product].splice(findInd, 1);
      }
    }
    this.subjectivityOptns[product].map((x) => {
      if (x.key == data.key) {
        x.disabled = false;
      }
    });
    this.showBodySpinner = false;
    setTimeout(() => {
      const alertData = {
        type: 'success',
        headerText: getAlertHead('success'),
        bodyText:
          'workFlow3.components.policyConfiguration.message.subjectivityDeletedSuccessfully',
      };
      this.alertService.addAlert(alertData);
    }, 100);
  }
  confirmEditSubjectivity(product, data) {
    this.alertService.clearAlerts(-1);
    if (!this.editedSubjectivity || this.editedSubjectivity === '') {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: 'productSummary.error.subjectivityEmpty',
      };
      this.alertService.addAlert(alertData);
      return;
    }
    if (
      this.editedSubjectivity.length < 3 ||
      this.editedSubjectivity.length > 1000
    ) {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText:
          'workFlow3.components.policyConfiguration.error.subjectivityLength',
      };
      this.alertService.addAlert(alertData);
      return;
    }
    this.selectedSubjectivities[product].some((x) => {
      if (x.key == this.editedSubjectivity) {
        if (x.key == data.key) {
          x.key = this.editedSubjectivity;
          x.value = this.editedSubjectivity;
          this.updateSubjectivity(x, product);
          x.edit = false;
        } else {
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: 'productSummary.error.sameSubjectivity',
          };
          this.alertService.addAlert(alertData);
          return true;
        }
      } else {
        if (x.key == data.key) {
          x.key = this.editedSubjectivity;
          x.value = this.editedSubjectivity;
          this.updateSubjectivity(x, product);
          x.edit = false;
        }
      }
      return false;
    });
  }
  isNewSubjectivity(product, data) {
    if (data.riskRegionSubjectivitiesId) {
      return false;
    } else {
      return true;
    }
  }
  numOfSubjectivity(product) {
    let selectedNum = 0;
    this.selectedSubjectivities[product].map((x) => {
      if (x.selected) {
        selectedNum++;
      }
    });
    return selectedNum;
  }

  handleExitFlow(): void {
    if (this.isBindingPolicy) {
      return;
    }
    let isMock = 'true';
    if (
      this.localStorageService.getMockExternalAPIs() === 'false' ||
      environment.mockExternalAPIs === false
    ) {
      isMock = 'false';
    }
    this.router.navigate(['dashboard/home'], {
      queryParams: {
        mock: isMock,
      },
      skipLocationChange: true,
    });
  }

  async getStageId(subDomain = ['DOCMERGESTAGE_QUOTE']) {
    this.stageId = undefined;
    return new Promise<void>((resolve) => {
      this.domainsService.GetByDomainCode('DOCMERGESTAGE', true).subscribe({
        next: (response) => {
          let docStageIds = response.data
            .filter((template) => subDomain.includes(template.subdomaincode))
            .map((template) => template.id);
          this.stageId = docStageIds.join(',');
          resolve();
        },
        error: (error) => {
          this.isLoader = false;
          this.showSendModal = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
          }
        },
      });
    });
  }

  translatesText(text: string): string {
    return this.translate.instant(text);
  }

  redirectToPolicies(message: string): void {
    if (this.isQuoteBounded) {
      this.router.navigate(['dashboard/workflow3/policies'], {
        skipLocationChange: true,
      });
    } else {
      this.sendButtonDisabled = false;
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: message,
      };
      message !== '' && this.alertService.addAlert(alertData);
    }
  }

  generateWarningMessageForErrorAfterQuoteBind(
    defaultMsg: string,
    isSendMailFailed: boolean = false,
  ) {
    let packagePolicyNumber = null;
    let errMsg = defaultMsg;
    if (this.isQuoteBounded) {
      packagePolicyNumber = this.newQuoteService.getPackagePolicyNumber();
      errMsg =
        defaultMsg === ''
          ? ``
          : `${this.translatesText(
              'error.errorAfterBindQuote.policyTxt',
            )} ${packagePolicyNumber} ${this.translatesText(
              'error.errorAfterBindQuote.msg',
            )} ${
              isSendMailFailed
                ? `${this.translatesText(
                    'error.errorAfterBindQuote.processSendMail',
                  )}`
                : `${this.translatesText(
                    'error.errorAfterBindQuote.processGeneratingDocument',
                  )}`
            } ${this.translatesText('error.errorAfterBindQuote.retryText')}`;
      this.newQuoteService.setPolicyBindWarningMsg(errMsg);
    }
    this.redirectToPolicies(errMsg);
  }

  closeAlert() {
    this.showErrorAlert = false;
    this.alertMessage = '';
  }

  async populateMessageType(subDomain: string) {
    this.messageTypeId = undefined;
    return new Promise<void>((resolve) => {
      this.domainsService.GetByDomainCode('MESSAGETYPE', true).subscribe({
        next: (response) => {
          let messageType = response.data.filter(
            (template) => template.subdomaincode === subDomain,
          )[0];
          this.messageTypeId = messageType.id;
          resolve();
        },
        error: (error) => {
          this.isLoader = false;
          this.showSendModal = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
          }
        },
      });
    });
  }

  getUpdatedQuoteDocList(docList: { [key: string]: any }[]) {
    let updatedDocList = [];
    docList.forEach((doc) => {
      if (doc['documentType'] !== 'Quote') {
        updatedDocList.push(doc);
      } else {
        const docNameSplit = doc['documentName'].split('.pdf')[0].split('-');
        const docOptionNumber = +docNameSplit.pop();
        if (this.selectedQuoteOptionsNumbers.includes(docOptionNumber)) {
          updatedDocList.push(doc);
        }
      }
    });
    return updatedDocList;
  }

  async getDocument() {
    if (!this.policyPeriodId || !this.stageId) {
      return;
    }
    this.policyRiskDocService
      .getPolicyRiskGeneratedDocCore(this.policyPeriodId, this.stageId)
      .subscribe({
        next: (response) => {
          let { data } = response;

          if (Object.entries(data).length === 0) return;

          if (!data.PolicyRiskDocument || data.PolicyRiskDocument.length === 0)
            return;
          // if (response?.data?.status === GENERATE_DOC_SUCCESS_STATUS_CODE) {
          let docList = data.PolicyRiskDocument;
          let updatedDocList = this.getUpdatedQuoteDocList(docList);
          this.docDetails = updatedDocList;
          // this.docDetails = data.PolicyRiskDocument;
          // } else {
          //   this.docDetails = [];
          // }
          this.rowDocDetails = data;
        },
        error: (error) => {
          clearTimeout(this.newTimeout);
          this.isLoader = false;
          this.showSendModal = false;
          this.generateWarningMessageForErrorAfterQuoteBind(
            getErrorMessage(error),
          );
        },
      });
  }

  async getMessageTemplates() {
    const riskId = this.productsIds[0] ?? 1;
    this.messageTemplateService
      .GetAllMessageTemplates(this.messageTypeId, riskId)
      .subscribe({
        next: (response) => {
          this.templateDetails = response?.data ?? [];
          // this.isLoader = false;
        },
        error: (error) => {
          this.isLoader = false;
          this.showSendModal = false;
          this.generateWarningMessageForErrorAfterQuoteBind(
            getErrorMessage(error),
          );
        },
      });
  }

  docGenFinishHandler() {
    this.isLoader = false;
  }

  async handleCreateDocument(messageSubDomain: string) {
    this.closeAlert();
    this.docDetails = [];
    this.rowDocDetails = {};
    this.showSendModal = !this.showSendModal;
    this.isLoader = !this.isLoader;
    this.showBodySpinner = false;

    const interval = 5000;
    const startTime = Date.now();

    if (this.showSendModal) {
      await this.populateMessageType(messageSubDomain);
      if (this.docDetails.length === 0) {
        this.generateQuoteService
          .generateQuotePdf(this.policyPeriodId)
          .subscribe({
            next: async (response) => {
              while (
                Date.now() - startTime < DOC_GENERATION_WAIT_TIME &&
                this.rowDocDetails?.['status'] !== 1 &&
                this.isLoader
                // this.rowDocDetails?.['status'] !== 2 &&
                // (this.docDetails.length === 0 ||
                //   this.rowDocDetails?.['status'] === 0)
              ) {
                await this.getDocument();
                await new Promise((resolve) => {
                  this.newTimeout = setTimeout(resolve, interval);
                });
              }
              if (this.docDetails.length === 0) {
                this.isLoader = false;
                this.showSendModal = false;
                this.showErrorAlert = true;
                this.showBodySpinner = false;
                const alertData = {
                  type: 'error',
                  headerText: getAlertHead('error'),
                  bodyText: this.translate.instant('common.docCreateFailMsg'),
                };
                this.alertService.addAlert(alertData);
              } else {
                await this.getMessageTemplates();
              }
            },
            error: (error) => {
              clearTimeout(this.newTimeout);
              this.isLoader = false;
              this.showSendModal = false;
              this.generateWarningMessageForErrorAfterQuoteBind(
                getErrorMessage(error),
              );
            },
          });
      }
      this.docDetails.length !== 0 && (await this.getMessageTemplates());
    }
  }
  async handleSendQuoteAfterSaving(quoteSelectionType: string) {
    this.alertService.clearAlerts(-1);
    if (!this.checkIfAllTemplateSelected()) {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: this.translate.instant(
          'workFlow3.components.policyConfiguration.error.allDocTempValidationError',
        ),
      };
      this.alertService.addAlert(alertData);
      return;
    }

    if (this.isBindingPolicy) {
      return;
    }
    let getQuoteOptions;
    if (this.quoteOptions.length === 0) {
      this.form$.subscribe((event) => (getQuoteOptions = event.quoteOptions));
    }
    this.isDropdownActive = false;
    await this.saveDetails();
    if (this.isContinueFormValid) {
      this.modalInitiated = true;
      if (quoteSelectionType === 'Single option') {
        await this.handleSendQuote(quoteSelectionType);
      } else if (quoteSelectionType === 'Multiple option') {
        // either we can use the same same function or we can use a dedicated function
        await this.handleSendQuote(quoteSelectionType);
      } else {
        await this.handleSendQuoteComparison(this.quoteOptions);
      }
    }
  }

  async handleSendQuote(quoteSelectionType: string) {
    this.showBodySpinner = true;
    this.sendButtonDisabled = true;
    this.alertService.clearAlerts(-1);
    this.docDetails = [];
    await this.getStageId();
    this.docPopupDetails.stageId = this.stageId;
    this.docPopupDetails.policyPeriodId = this.policyPeriodId;

    if (quoteSelectionType === 'Single option') {
      let selectedQuoteValid = true;
      this.form$.pipe(take(1)).subscribe((data) => {
        let selectedQuoteProductsData;
        try {
          selectedQuoteProductsData = data?.quoteOptions.filter(
            (quote) => quote.ui.optionSelected,
          )[0]?.products;
        } catch (error) {
          selectedQuoteProductsData = data?.quoteOptions.filter(
            (quote) => quote.optionSelected === 1,
          );
        }

        selectedQuoteProductsData?.forEach((product) => {
          const policyPremium = Number(product?.premium);
          const brokerageCommissionPerc = Number(product?.brokerCommissionPerc);
          if (policyPremium === 0 || brokerageCommissionPerc === 0) {
            selectedQuoteValid = false;
            return;
          }
        });
      });

      if (!selectedQuoteValid) {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: this.translate.instant(
            'quoteCalculator.error.invalidSelectedQuote',
          ),
        };
        this.showBodySpinner = false;
        this.alertService.addAlert(alertData);
        this.sendButtonDisabled = false;
        return;
      }
    }

    if (this.stageId) {
      if (
        this.docTempDetails.documentTemplate &&
        this.docTempDetails.documentTemplate.length > 0
      ) {
        await Promise.all([this.handleDocumentTempSave()]);
      }
      this.showBodySpinner = false;
      await this.handleMultiSendPackage();
      // quoteSelectionType === 'Single option'
      //   ? await this.handleSendPackage()
      //   : await this.handleMultiSendPackage();
    } else {
      this.showBodySpinner = false;
      this.sendButtonDisabled = false;
      return;
    }
  }

  isPolicyFeeValid(policyFee) {
    if (
      this.policyFeeEnabled &&
      (!policyFee || policyFee === '0' || policyFee < 1 || policyFee > 100000)
    ) {
      return false;
    }
    return true;
  }

  isTriaAmountValid(triaAmount) {
    if (
      this.TRIAFeeEnabled &&
      (!triaAmount ||
        triaAmount === '0' ||
        triaAmount < 1 ||
        triaAmount > this.policyPremium)
    ) {
      return false;
    }
    return true;
  }

  getPolicyFee() {
    if (this.policyFeeEnabled) {
      return this.policyAmount || '0';
    }
    return '0';
  }

  getTRIA() {
    if (this.TRIAFeeEnabled) {
      return this.triaAmount || '0';
    }
    return '0';
  }

  async callBindAPI() {
    try {
      const bindData = {
        policyPeriodId: this.policyPeriodId,
      };

      this.alertService.addAlert({
        type: 'info',
        headerText: getAlertHead('info'),
        bodyText: 'common.bindProcessMsg',
      });

      const createBindServiceObs =
        this.policyBindService.UpdateWithoutId(bindData);
      const bindResponse = await firstValueFrom(createBindServiceObs);
      this.packagePolicyNumber = bindResponse.data.pkgPolicyNumber;
      this.bindingStatus = 'done';
      this.newQuoteService.setPackagePolicyNumber(this.packagePolicyNumber);
      this.store.dispatch(
        new CreateQuoteAction.updateUiContents({
          packagePolicyNumber: this.packagePolicyNumber ?? '',
        }),
      );
      this.isBindingPolicy = false;
      this.isQuoteBounded = true;
    } catch (error) {
      if (![500].includes(error?.status)) {
        this.showErrorAlert = true;
        this.alertMessage = error?.message ?? error?.error?.message ?? '';
      }
      this.isLoader = false;
      this.showSendModal = false;
      this.bindingStatus = 'default';
      this.isBindingPolicy = false;
      this.isQuoteBounded = false;
    }
  }

  async handleCreateDocumentAfterBind(messageSubDomain: string) {
    await this.getStageId(['DOCMERGESTAGE_BIND']);
    this.closeAlert();
    this.docDetails = [];
    this.rowDocDetails = {};
    this.showSendModal = !this.showSendModal;
    this.tempType = 2;
    this.isLoader = !this.isLoader;
    const interval = 5000;
    const startTime = Date.now();

    if (this.showSendModal) {
      await this.populateMessageType(messageSubDomain);
      await this.getDocument();
      if (this.docDetails.length === 0) {
        while (
          Date.now() - startTime < DOC_GENERATION_WAIT_TIME &&
          this.rowDocDetails?.['status'] !== 1 &&
          this.isLoader
          // this.rowDocDetails?.['status'] !== 2 &&
          // (this.docDetails.length === 0 || this.rowDocDetails?.['status'] === 0)
        ) {
          await this.getDocument();
          await new Promise((resolve) => {
            this.newTimeout = setTimeout(resolve, interval);
          });
        }
        if (this.docDetails.length === 0) {
          this.isLoader = false;
          this.showSendModal = false;
          this.generateWarningMessageForErrorAfterQuoteBind(
            `common.docCreateFailMsg`,
          );
        } else {
          await this.getMessageTemplates();
        }
      }
      this.docDetails.length !== 0 && (await this.getMessageTemplates());
    }
  }

  async handleBindPolicy() {
    if (this.isBindingPolicy) {
      return;
    }
    this.isBindingPolicy = true;
    this.bindingStatus = 'process-pay';
    try {
      // this.showBodySpinner = false;
      if (!this.isQuoteSelected) {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: 'error.selectQuoteOption',
        };
        this.alertService.addAlert(alertData);
        this.showBodySpinner = false;
        return;
      }
      await Promise.all([this.handleDocTempSelectionBindClick()]);
    } catch (error) {
      this.showBodySpinner = false;
      if (![500].includes(error?.status)) {
        this.alertMessage = 'error.somethingWentWrong';
      }
    }
  }

  async handlePolicyAndTriaValidation() {
    let quote4FlowPremiumDetails: { [x: string]: any } =
      await this.getQuote4FlowPremiumDetails();
    if (Object.values(quote4FlowPremiumDetails).length > 0) {
      this.TRIAFeeEnabled =
        quote4FlowPremiumDetails?.['TRIAFeeEnabled'] ?? false;
      this.policyFeeEnabled =
        quote4FlowPremiumDetails?.['policyFeeEnabled'] ?? false;
      if (
        this.isPolicyFeeValid(+quote4FlowPremiumDetails['policyAmount']) ===
        false
      ) {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: 'quoteSummary.errors.policyFee',
        };
        this.alertService.addAlert(alertData);

        this.showBodySpinner = false;
        return false;
      }
      if (
        this.isTriaAmountValid(+quote4FlowPremiumDetails['triaAmount']) ===
        false
      ) {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: 'quoteSummary.errors.triaAmount',
        };
        this.alertService.addAlert(alertData);

        this.showBodySpinner = false;
        return false;
      }

      return true;
    }

    return false;
  }

  async getQuote4FlowPremiumDetails(): Promise<any> {
    let fetchResult: { [x: string]: any } = {};
    this.store.select(getUIData).subscribe((data: any) => {
      fetchResult = data?.quote4FlowPremiumDetails ?? {};
    });

    return fetchResult;
  }

  handleRiskAction(action: TransactionRiskActions) {
    if (this.isBindingPolicy) {
      return;
    }
    let uiData: any;
    this.store
      .pipe(select(getQuoteSelector))
      .subscribe((event) => (uiData = event.ui));
    this.actionPopupDetails = {
      quoteNumber: uiData?.policyId,
      effectiveDate: uiData?.policyPeriod,
      insured: uiData?.insuredName,
      brokerage: uiData?.brokerageName
        ? uiData?.brokerageName !== ''
          ? uiData?.brokerageName
          : uiData.broker
        : uiData.broker,
      branch: uiData?.branch,
      reasonOptions: [],
      action: this.transactionRiskActions.None,
    };
    this.actionPopupDetails.action = action;
    this.showActionPopup = true;
  }

  handleConfirmRiskAction(event: {
    action: TransactionRiskActions;
    selectedReasonId: number;
  }) {
    this.alertService.clearAlerts(-1);
    const PolicyDeclineRequest: PolicyDeclineRequest = {
      policyPeriodId: Number(this.policyPeriodId),
      declineReason: Number(event.selectedReasonId),
    };

    this.policyLifecycleService
      .TransactionRiskDecline(PolicyDeclineRequest)
      .subscribe({
        next: (response) => {
          this.handleRiskActionApiResponse(
            TransactionRiskActions.Decline,
            response,
          );
        },
        error: (e) => {
          this.handleApiError(e);
        },
        complete: () => {
          this.handleRiskActionComplete();
        },
      });
  }

  private handleRiskActionApiResponse(type, response: any) {
    this.showActionPopup = false;
    const alertData = {
      type: 'success',
      headerText: getAlertHead('success'),
      bodyText: this.translate.instant('policy.declined.success.body'),
    };
    this.alertService.addAlert(alertData);
  }

  handleApiError(error: any) {
    if (![500].includes(error?.status)) {
      let errorMessage = '';
      if (typeof error === 'string' || error instanceof String) {
        if (error?.toLowerCase().includes('error code: 404', 0)) {
          errorMessage = this.translate.instant(
            'quoteDetails.requestedQuoteNotFound',
          );
        }
      } else {
        errorMessage = getErrorMessage(error);
      }

      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: errorMessage,
      };
      this.alertService.addAlert(alertData);
    }
  }

  private handleRiskActionComplete() {
    this.showActionPopup = false;
    this.newQuoteService.setClosedAlertStatus(true);
    this.router.navigate([`/dashboard/quote4flow/${this.quoteId}`], {
      skipLocationChange: true,
    });
  }

  handleSend(formData: any) {
    formData.append('merginStageId', this.stageId);
    this.messageSendService.sendMessage(formData).subscribe({
      next: (response) => {
        this.docDetails = [];
        this.templateDetails = [];
        this.generateWarningMessageForErrorAfterQuoteBind('');
        this.newQuoteService.setPolicyBindWarningMsg('');
        this.isDropdownActive = true;
        this.showSendModal = !this.showSendModal;
        this.modalInitiated = false;
        const alertData = {
          type: 'success',
          headerText: getAlertHead('success'),
          bodyText: this.translateService.instant(
            'workFlow3.emailPopupV2.success.quoteSent',
          ),
        };
        this.alertService.addAlert(alertData);
      },
      error: (error) => {
        this.isDropdownActive = true;
        this.generateWarningMessageForErrorAfterQuoteBind(
          getErrorMessage(error),
          true,
        );
        this.showSendModal = !this.showSendModal;
      },
    });
  }

  handleTBDCheckbox(index, options) {
    options[index].checked = !options[index].checked;

    if (!options[index].checked) {
      this.isTBDValid = false;
    } else {
      this.isTBDValid = true;
    }
  }

  // get subjectivities for each product
  getAllLatestSubjectivities(product, index) {
    this.riskRegionSubjectivityService
      .getSubjectivity(this.riskRegionId)
      .subscribe((dataSub) => {
        if (dataSub.data) {
          this.subjectivityOptns[product] = dataSub.data.map((sub) => {
            return {
              key: sub.description,
              value: sub.description,
              disabled: !!sub.mandatory,
              mandatory: sub.mandatory,
              riskRegionSubjectivitiesId: sub.id ?? '',
              isCompliedWith: !!sub.isCompliedWith,
              selected: !!sub.mandatory,
              documentTblSubId: null,
            };
          });
          this.subjectivityMandatory[product] = this.subjectivityOptns[
            product
          ].filter((sub) => sub.mandatory);
          this.selectedSubjectivities[product] = this.subjectivityMandatory[
            product
          ].map((sub) => ({
            ...sub,
            selected: true,
          }));
          let distinctSubArray: any[] = this.selectedSubjectivities[
            product
          ].filter(
            (obj, index, array) =>
              obj?.riskRegionSubjectivitiesId === '' ||
              array.findIndex(
                (o) =>
                  obj !== null &&
                  o?.riskRegionSubjectivitiesId ===
                    obj?.riskRegionSubjectivitiesId,
              ) === index,
          );
          this.selectedSubjectivities[product] = distinctSubArray;
          this.spinnerCounter++;
          // to get existing subjectivities
          this.policySubjectivityService
            .getPolicyRiskSubjectivity(this.policyRiskIds[index])
            .subscribe((dataSubExist) => {
              let selectedPolicySubj = {
                [product]: dataSubExist.data.map((policySub) => {
                  let subjOptn = this.subjectivityOptns[product].filter(
                    (subOptn) =>
                      subOptn.riskRegionSubjectivitiesId ==
                      policySub.riskRegionSubjectivitiesId,
                  );
                  if (!policySub.riskRegionSubjectivitiesId) {
                    subjOptn = [...subjOptn, policySub];
                  }
                  if (subjOptn.length > 0) {
                    // subjOptn[0].disabled = true;
                    return {
                      key: subjOptn[0]?.key || policySub?.description,
                      value: subjOptn[0]?.key || policySub?.description,
                      disabled: true,
                      mandatory: subjOptn[0]?.mandatory ?? false,
                      selected: true,
                      documentTblSubId: policySub?.id,
                      riskRegionSubjectivitiesId:
                        subjOptn[0]?.riskRegionSubjectivitiesId ?? '',
                      isCompliedWith: !!policySub?.isCompliedWith,
                    };
                  } else {
                    return null;
                  }
                }),
              };
              if (selectedPolicySubj[product]?.length > 0) {
                this.selectedSubjectivities[product] = [
                  ...selectedPolicySubj[product],
                ];
                let distinctSubArray: any[] = this.selectedSubjectivities[
                  product
                ].filter(
                  (obj, index, array) =>
                    obj?.riskRegionSubjectivitiesId === '' ||
                    array.findIndex(
                      (o) =>
                        obj !== null &&
                        o?.riskRegionSubjectivitiesId ===
                          obj?.riskRegionSubjectivitiesId,
                    ) === index,
                );
                this.selectedSubjectivities[product] = distinctSubArray;
                this.enalbleSelectAllComply(
                  this.selectedSubjectivities[product],
                );
                this.subjectivityOptns[product] = this.subjectivityOptns[
                  product
                ].map((subjOptn) => {
                  let selectedOptn = this.selectedSubjectivities[product].find(
                    (x) =>
                      x.riskRegionSubjectivitiesId ===
                      subjOptn.riskRegionSubjectivitiesId,
                  );
                  if (selectedOptn) {
                    return {
                      ...subjOptn,
                      disabled: true,
                      selected: true,
                    };
                  } else {
                    return { ...subjOptn };
                  }
                });
              }
              this.spinnerCounter--;
            });
        }
        this.spinnerCounter--;
      });
  }

  toggleTbdAndPolicyPeriod(selected: string) {
    if (
      !this.permissionList[this.currentScreen] ||
      this.currentQuoteStatus === 'quote-closed'
    ) {
      return;
    }
    this.isTBDValid = selected === 'TBD' ? true : false;
    this.policyConfigurationForm = this.fb.group({
      policy_period_control: selected === 'TBD' ? 'TBD' : '',
    });
  }

  handleActionPopup(index: number) {
    if (this.isActionPopupIndex === index || index === undefined) {
      this.isActionPopupEnabled = false;
      this.isActionPopupIndex = undefined;
    } else if (index !== undefined && this.isActionPopupEnabled) {
      this.isActionPopupIndex = index;
      this.isActionPopupEnabled = true;
    } else {
      this.isActionPopupIndex = index;
      this.isActionPopupEnabled = true;
    }
  }

  async handleSendDropdown(value: string) {
    if (!this.isQuoteSelected) {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: 'error.selectQuoteOption',
      };
      this.alertService.addAlert(alertData);
      return;
    }
    this.modalInitiated = true;
    this.modalPopupType = value;
  }

  handleComparisonSend() {
    this.handleSendQuoteAfterSaving('Comparison');
  }

  async handleSendComparison(quoteOptions) {
    await this.updateSelectedPremium();
    this.closeAlert();
    this.docDetails = [];
    this.rowDocDetails = {};
    this.showSendCompareModal = !this.showSendCompareModal;
    this.showBodySpinner = false;
    this.isLoader = !this.isLoader;
    const interval = 5000;
    const startTime = Date.now();

    // TO DO - update later to get all quote options to show in comparison doc
    let selectedQuoteId;
    let selectedQuote;
    selectedQuote = quoteOptions.filter(
      (quote) => quote?.ui?.optionSelected || quote.optionSelected === 1,
    )[0];
    try {
      selectedQuoteId =
        selectedQuote?.products[0]?.quoteOptionId ?? selectedQuote?.id;
    } catch (e) {
      selectedQuoteId = selectedQuote?.id;
    }

    let payload = [
      {
        id: selectedQuoteId,
      },
    ];
    quoteOptions.map((option) => {
      this.selectedQuoteOptionIds.map((id) => {
        if (option.id === id && id !== selectedQuoteId) {
          payload.push({
            id: option.id,
          });
        }
      });
    });

    if (this.showSendCompareModal) {
      await this.getStageId(['DOCMERGESTAGE_QUOTECOMPARISON']);
      let subdomain = 'MESSAGETYPE_QUOTE';
      await this.populateMessageType(subdomain);
      if (this.docDetails.length === 0) {
        this.generateQuoteService.generateQuoteComparePdf(payload).subscribe({
          next: async (response) => {
            while (
              this.showSendCompareModal &&
              Date.now() - startTime < DOC_GENERATION_WAIT_TIME &&
              this.rowDocDetails?.['status'] !== 1 &&
              this.isLoader
              // this.rowDocDetails?.['status'] !== 2 &&
              // (this.docDetails.length === 0 ||
              //   this.rowDocDetails?.['status'] === 0)
            ) {
              await this.getDocument();
              await new Promise(
                (resolve) => (this.newTimeout = setTimeout(resolve, interval)),
              );
            }
            if (!this.showSendCompareModal) {
              return;
            }
            if (this.docDetails.length === 0) {
              this.isLoader = false;
              this.showSendCompareModal = false;
              this.showErrorAlert = true;
              const alertData = {
                type: 'error',
                headerText: getAlertHead('error'),
                bodyText: 'common.docCreateFailMsg',
              };
              this.isDropdownActive = true;
              this.sendButtonDisabled = false;
              this.alertService.addAlert(alertData);
            } else {
              await this.getMessageTemplates();
            }
          },
          error: ({ error }) => {
            clearTimeout(this.newTimeout);
            this.isDropdownActive = true;
            this.isLoader = false;
            this.showSendCompareModal = false;
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
              const alertData = {
                type: 'error',
                headerText: getAlertHead('error'),
                bodyText: getErrorMessage(error),
              };
              this.alertService.addAlert(alertData);
            }
          },
        });
      }
      this.docDetails.length !== 0 && (await this.getMessageTemplates());
    }
  }

  async handleSendQuoteComparison(quoteOptions) {
    this.showBodySpinner = true;
    this.alertService.clearAlerts(-1);
    this.docDetails = [];
    await this.getStageId(['DOCMERGESTAGE_QUOTECOMPARISON']);
    this.docPopupDetails.stageId = this.stageId;
    this.docPopupDetails.policyPeriodId = this.policyPeriodId;

    let zeroBrokerCommission = false;
    let zeroPremuim = false;
    quoteOptions.forEach((quote) => {
      if (Number(quote?.premium) === 0) {
        zeroPremuim = true;
      }
      if (Number(quote?.brokerCommissionPerc) === 0) {
        zeroBrokerCommission = true;
      }
    });

    if (zeroBrokerCommission || zeroPremuim) {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: this.translate.instant(
          'quoteCalculator.error.invalidSelectedQuote',
        ),
      };
      this.showBodySpinner = false;
      this.alertService.addAlert(alertData);
      return;
    }

    if (this.stageId) {
      if (
        this.docTempDetails.documentTemplate &&
        this.docTempDetails.documentTemplate.length > 0
      ) {
        await Promise.all([await this.handleDocumentTempSave()]);
      }
      this.showBodySpinner = false;
      await this.handleSendComparison(quoteOptions);
    } else {
      this.showBodySpinner = false;
      return;
    }
  }

  handleSendCompare(formData: any) {
    formData.append('merginStageId', this.stageId);
    this.messageSendService.sendMessage(formData).subscribe({
      next: (response) => {
        this.docDetails = [];
        this.templateDetails = [];
        this.showSendCompareModal = !this.showSendCompareModal;
        const alertData = {
          type: 'success',
          headerText: getAlertHead('success'),
          bodyText: 'quoteSummary.success.quoteCompareSent',
        };
        this.alertService.addAlert(alertData);
        this.isDropdownActive = true;
        this.modalInitiated = false;
      },
      error: (error) => {
        this.isDropdownActive = true;
        this.showSendCompareModal = !this.showSendCompareModal;
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: getErrorMessage(error),
          };
          this.alertService.addAlert(alertData);
        }
      },
    });
  }

  async populateDocumentTemplates() {
    if (this.policyRiskId) {
      await this.getStageId(['DOCMERGESTAGE_BIND', 'DOCMERGESTAGE_QUOTE']);
      await this.DocumentTemplateService.getDocumentTemplateList(
        this.policyRiskId,
        this.stageId,
      ).subscribe({
        next: (response) => {
          if (response?.data.length !== 0) {
            this.docTempDetails.documentTemplate = response.data;
          } else {
            this.docTempDetails = {
              documentTemplate: [],
            };
          }
        },
        error: (error) => {
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: getErrorMessage(error.error),
          };
          this.showBodySpinner = false;
          this.alertService.addAlert(alertData);
        },
      });
    } else {
      this.showBodySpinner = false;
      return;
    }
  }

  async updateSelectedPremium() {
    let quoteOptions = [];
    this.form$.pipe(take(1)).subscribe({
      next: (response) => {
        quoteOptions = response.quoteOptions;
        let selectedQuote = [];
        try {
          selectedQuote = quoteOptions?.filter(
            (quoteOption) => quoteOption?.ui.optionSelected,
          );
        } catch (e) {
          selectedQuote = quoteOptions?.filter(
            (quoteOption) => quoteOption?.optionSelected === 1,
          );
        }
        if (selectedQuote?.length > 0) {
          const premium = selectedQuote[0]?.premium || 0;
          const annualPremium = selectedQuote[0]?.totalInvoiceAmount || 0;
          const deductible = selectedQuote[0]?.deductible || 0;
          if (premium && premium > 0) {
            this.store.dispatch(
              new CreateQuoteAction.updateUiContents({
                premium,
                annualPremium,
                deductible,
              }),
            );
          }
        }
      },
      error: (error) => {},
    });
  }

  async handleDocTempSelectionBindClick() {
    return new Promise<any>(async (resolve, reject) => {
      try {
        if (
          this.docTempDetails.documentTemplate &&
          this.docTempDetails.documentTemplate.length > 0
        ) {
          const allTempSelected = this.policyRiskIds.every(
            (product) => this.isAlloptionsSelected[product],
          );
          if (allTempSelected) {
            await Promise.all([this.handleDocumentTempSave()]);
          } else {
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: this.translate.instant(
                'workFlow3.components.policyConfiguration.error.allDocTempValidationError',
              ),
            };
            this.alertService.addAlert(alertData);
            this.showBodySpinner = false;
            reject('true');
            return null;
          }
          await this.callBindAPI();
          this.handleCreateDocumentAfterBind('MESSAGETYPE_POLICY');
          resolve('true');
        } else {
          await this.callBindAPI();
          this.handleCreateDocumentAfterBind('MESSAGETYPE_POLICY');
          reject('true');
        }
      } catch (error) {
        if (![500].includes(error?.status)) {
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: error ?? '' ?? error?.message ?? '',
          };
          this.alertService.addAlert(alertData);
        }
      }
    });
  }

  handleDocumentTempSave() {
    return new Promise<any>(async (resolve, reject) => {
      try {
        for (const product of this.policyRiskIds) {
          let selectedDocTempOptions = this.docTempSelectedOptions[product];
          if (!selectedDocTempOptions || selectedDocTempOptions.length === 0) {
            resolve('true');
            return;
          }
          if (selectedDocTempOptions && selectedDocTempOptions.length > 0) {
            let promises = selectedDocTempOptions.map(async (p) => {
              const docTempOptionsData = {
                riskTemplateId: p.tempId,
                policyRiskId: this.policyRiskId,
                documentName: p.template,
                documentUniqueName: p.template,
                dataInjection: p.dataInjection,
                templateType: p.tempType,
              };

              if (p.docTempProcessId) {
                return this.policyRiskDocProcessService
                  .updatePolicyRiskDoc(p.docTempProcessId, docTempOptionsData)
                  .toPromise();
              } else {
                const payloadForPolicyRiskDocCreate = {
                  policyRiskDocs: [docTempOptionsData],
                };

                return this.policyRiskDocProcessService
                  .createPolicyRiskDoc(payloadForPolicyRiskDocCreate)
                  .toPromise();
              }
            });

            await Promise.all(promises);
            this.docTempDetails = {
              documentTemplate: [],
            };
            await Promise.all([await this.populateDocumentTemplates()]);
            // this.docTempSelectedOptions = [];
          }
        }
        resolve(true);
      } catch (error) {
        this.showBodySpinner = false;
        const errorMessage = getErrorMessage(error);
        const notificationAlert = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: errorMessage,
        };
        this.alertService.addAlert(notificationAlert);
        reject(errorMessage);
      }
    });
  }

  handleDocTempSelectionOutput($event) {
    let selectedDocTempOptions = $event;
    let product = selectedDocTempOptions.productId;
    this.docTempSelectedOptions[product] =
      selectedDocTempOptions.selectedTempOptions;
    this.isAlloptionsSelected[product] =
      selectedDocTempOptions.isAlloptionsSelected;
  }

  async handleSendPackage() {
    this.sendButtonDisabled = false;
    await this.updateSelectedPremium();
    this.closeAlert();
    this.docDetails = [];
    this.rowDocDetails = {};
    this.showSendModal = !this.showSendModal;
    this.isLoader = !this.isLoader;
    const interval = 5000;
    const startTime = Date.now();
    if (this.showSendModal) {
      await this.getStageId();
      let subdomain = 'MESSAGETYPE_QUOTE';
      await this.populateMessageType(subdomain);
      if (this.docDetails.length === 0) {
        this.generateQuoteService
          .generateQuotePdf(this.policyPeriodId)
          .subscribe({
            next: async (response) => {
              while (
                this.showSendModal &&
                Date.now() - startTime < DOC_GENERATION_WAIT_TIME &&
                this.rowDocDetails?.['status'] !== 1 &&
                this.isLoader
                // this.rowDocDetails?.['status'] !== 2 &&
                // (this.docDetails.length === 0 ||
                //   this.rowDocDetails?.['status'] === 0)
              ) {
                await this.getDocument();
                await new Promise(
                  (resolve) =>
                    (this.newTimeout = setTimeout(resolve, interval)),
                );
              }
              if (!this.showSendModal) {
                this.isDropdownActive = true;
                return;
              }
              if (this.docDetails.length === 0) {
                this.isLoader = false;
                this.showSendModal = false;
                this.showErrorAlert = true;
                const alertData = {
                  type: 'error',
                  headerText: getAlertHead('error'),
                  bodyText: 'common.docCreateFailMsg',
                };
                this.isDropdownActive = true;
                this.sendButtonDisabled = false;
                this.alertService.addAlert(alertData);
              } else {
                await this.getMessageTemplates();
              }
            },
            error: ({ error }) => {
              clearTimeout(this.newTimeout);
              this.isDropdownActive = true;
              this.isLoader = false;
              this.showSendModal = false;
              if (![500].includes(error?.status)) {
                this.showErrorAlert = true;
                const alertData = {
                  type: 'error',
                  headerText: getAlertHead('error'),
                  bodyText: getErrorMessage(error),
                };
                this.alertService.addAlert(alertData);
              }
            },
          });
      }
      this.docDetails.length !== 0 && (await this.getMessageTemplates());
    }
  }

  async handleMultiSendPackage() {
    this.sendButtonDisabled = false;
    // await this.updateSelectedPremium();
    this.closeAlert();
    this.docDetails = [];
    this.rowDocDetails = {};
    this.showSendModal = !this.showSendModal;
    this.isLoader = !this.isLoader;
    const interval = 5000;
    const startTime = Date.now();
    if (this.showSendModal) {
      await this.getStageId();
      let subdomain = 'MESSAGETYPE_QUOTE';
      await this.populateMessageType(subdomain);
      if (this.docDetails.length === 0) {
        this.generateQuoteService
          .generateMultiQuotePdf(
            this.policyRiskTrxId,
            this.selectedQuoteOptionIds,
          )
          .subscribe({
            next: async (response) => {
              while (
                this.showSendModal &&
                Date.now() - startTime < DOC_GENERATION_WAIT_TIME &&
                this.rowDocDetails?.['status'] !== 1 &&
                this.isLoader
                // this.rowDocDetails?.['status'] !== 2 &&
                // (this.docDetails.length === 0 ||
                //   this.rowDocDetails?.['status'] === 0)
              ) {
                await this.getDocument();
                await new Promise(
                  (resolve) =>
                    (this.newTimeout = setTimeout(resolve, interval)),
                );
              }
              if (!this.showSendModal) {
                this.isDropdownActive = true;
                return;
              }
              if (this.docDetails.length === 0) {
                this.isLoader = false;
                this.showSendModal = false;
                this.showErrorAlert = true;
                const alertData = {
                  type: 'error',
                  headerText: getAlertHead('error'),
                  bodyText: 'common.docCreateFailMsg',
                };
                this.isDropdownActive = true;
                this.sendButtonDisabled = false;
                this.alertService.addAlert(alertData);
              } else {
                await this.getMessageTemplates();
              }
            },
            error: ({ error }) => {
              clearTimeout(this.newTimeout);
              this.isDropdownActive = true;
              this.isLoader = false;
              this.showSendModal = false;
              if (![500].includes(error?.status)) {
                this.showErrorAlert = true;
                const alertData = {
                  type: 'error',
                  headerText: getAlertHead('error'),
                  bodyText: getErrorMessage(error),
                };
                this.alertService.addAlert(alertData);
              }
            },
          });
      }
      this.docDetails.length !== 0 && (await this.getMessageTemplates());
    }
  }

  closeSendPackage(isDocGenFailed: boolean = false) {
    clearTimeout(this.newTimeout);
    this.closeAlert();
    this.modalInitiated = false;
    this.sendButtonDisabled = false;
    this.showSendModal = false;
    this.showSendCompareModal = false;
    this.isLoader = false;
    this.isDropdownActive = true;
    this.docDetails = [];
    this.templateDetails = [];
    if (isDocGenFailed) {
      this.alertService.addAlert({
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: 'common.documentGenerationFailed',
      });
      return;
    }
    this.generateWarningMessageForErrorAfterQuoteBind('');
  }

  handleQuoteSelected(event) {
    this.alertService.clearAlerts(-1);
    let selectedQuote = event;
    this.isQuoteSelected = true;
    if (this.isAlloptionsSelected && this.isQuoteSelected) {
      this.sendButtonDisabled = false;
    }
    this.activeProductDetails = selectedQuote;
    this.policyPremium = selectedQuote?.premium ?? this.policyPremium;
    this.quoteOptions.map((option, key) => {
      if (option.optionNumber === selectedQuote.optionNumber) {
        this.quoteOptions[key] = { ...option, optionSelected: 1 };
      } else {
        this.quoteOptions[key] = { ...option, optionSelected: 0 };
      }
    });
  }

  sendDropdownDynamicUpdate({
    selectedQuoteOptionIds,
    selectedQuoteOptionsNumbers,
  }) {
    this.selectedQuoteOptionIds = selectedQuoteOptionIds;
    this.selectedQuoteOptionsNumbers = selectedQuoteOptionsNumbers;
    if (selectedQuoteOptionIds.length === 1) {
      this.handleSendDropdown(this.sendQuoteDropdownOptions[0].value);
    } else {
      this.handleSendDropdown(this.sendQuoteDropdownOptions[1].value);
    }
  }

  async handleToggleChange(event) {
    this.policyFeeEnabled = event.policyFeeEnabled;
    this.TRIAFeeEnabled = event.triaEnabled;
    await this.loadPageData();
  }

  async loadPremiumValues() {
    await this.loadPageData();
  }

  checkIfAllTemplateSelected() {
    if (
      this.docTempDetails.documentTemplate &&
      this.docTempDetails.documentTemplate.length > 0
    ) {
      const allTempSelected = this.policyRiskIds.every(
        (product) => this.isAlloptionsSelected[product],
      );
      if (allTempSelected) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  handleTriaFeeChange(event) {
    this.isTriaFeeChange = event;
  }

  //  fetch quote options
  async populateQuoteOptions() {
    const resp = await firstValueFrom(
      this.policyRiskService.GetAllByPolicyPeriodId(this.policyPeriodId),
    );

    this.policyRiskId = resp.data[0]?.id;
    let quoteOptions = [];
    resp.data?.forEach((risk) => {
      const policyRiskTrxId = risk?.PolicyRiskTrxes[0].id;
      risk?.PolicyRiskTrxes[0]?.QuoteOptions.forEach((quoteOption) => {
        quoteOption.policyRiskTrxId = policyRiskTrxId;
        quoteOption.risk = risk?.risk;
        quoteOptions.push(quoteOption);
      });
    });
  }

  getCopy(dataObj) {
    return {
      products: dataObj.products.map((object) => ({ ...object })),
      total: { ...dataObj.total },
      ui: { ...dataObj.ui },
    };
  }
  handleSaveForTriaPolicy() {
    this.handleSaveDraft(false, true);
  }

  enalbleSelectAllComply(subjectivities) {
    let selectedNum = 0;
    subjectivities.map((x) => {
      if (!x.isCompliedWith) {
        selectedNum++;
      }
    });
    if (selectedNum > 0) {
      this.selectAllComply = false;
    } else {
      this.selectAllComply = true;
    }
    return selectedNum;
  }

  isCustomEndorsement(product, data) {
    if (data.documentTblEndorsId === 0) {
      return true;
    } else {
      return false;
    }
  }
  editEndorsement(product, data, active) {
    this.handleActionPopup(undefined);
    this.selectedEndorsements[product] = this.selectedEndorsements[product].map(
      (x) => {
        x.edit = false;
        if (active == 1) {
          if (x.key == data.key) {
            x.edit = true;
            this.editedEndorsement = data.key;
          }
        }
        return x;
      },
    );
  }
  confirmEditEndorsement(product, data) {
    this.alertService.clearAlerts(-1);
    if (!this.editedEndorsement || this.editedEndorsement === '') {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText:
          'workFlow3.components.policyConfiguration.error.endorsementEmpty',
      };
      this.alertService.addAlert(alertData);
      return;
    }
    if (
      this.editedEndorsement.length < 3 ||
      this.editedEndorsement.length > 100
    ) {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText:
          'workFlow3.components.policyConfiguration.error.endorsementLength',
      };
      this.alertService.addAlert(alertData);
      return;
    }
    this.selectedEndorsements[product].some((x) => {
      if (x.key == this.editedEndorsement) {
        if (x.key == data.key) {
          x.key = this.editedEndorsement;
          x.value = this.editedEndorsement;
          this.updateCustomEndorsement(x, product);
          x.edit = false;
        } else {
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText:
              'workFlow3.components.policyConfiguration.error.endorsementDuplicated',
          };
          this.alertService.addAlert(alertData);
          return true;
        }
      } else {
        if (x.key == data.key) {
          x.key = this.editedEndorsement;
          x.value = this.editedEndorsement;
          this.updateCustomEndorsement(x, product);
          x.edit = false;
        }
      }
      return false;
    });
  }
}
