import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, firstValueFrom, take } from 'rxjs';
import { Quote, UISpecificValues } from 'src/app/models/quote.model';
import {
  getQuoteSelector,
  getSelectedQuoteOption,
  getRiskCoverages,
} from 'src/app/store/create-quote/create-quote.selector';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { ConfDeductibleService } from 'src/app/services/conf-deductible.service';
import { BrokerageProducerCommissionService } from 'src/app/services/brokerage-producer-commission.service';
import { BrokerageCommissionService } from 'src/app/services/brokerage-commissions.service';
import {
  formatAmountWithCurrency,
  getAlertHead,
  getDecimalPlace,
  getErrorMessage,
  removeDuplicatedKeys,
  valInDigits,
} from 'src/app/utils/utils';
import { PolicyQuoteOptionService } from 'src/app/services/policy-quote-option.service';
import { RiskRatingCalculateService } from 'src/app/services/risk-rating-calculate.service';
import { DomainsService } from 'src/app/services/domains.service';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import { PolicyRiskService } from 'src/app/services/policy-risk.service';
import {
  BROKER_COMMISSION_STEP_VALUE,
  CYBER_COMMERCIAL_PRODUCT_NAME,
  CYBER_EXCESS_PRODUCT_NAME,
  CYBER_EXCESS_RISK_ID,
  DOC_GENERATION_WAIT_TIME,
  GENERATE_DOC_SUCCESS_STATUS_CODE,
  REFERRAL_ERROR_CODE,
  STANDALONE_SELLABLE_TRUE,
} from 'src/app/constants/quote-constant';
import { QuoteOptionServicesService } from 'src/app/services/quote-option-services.service';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { InsuredService } from 'src/app/services/insured.service';
import { LocationService } from 'src/app/services/location.service';
import { DatePipe } from '@angular/common';
import { ConfRiskLimitsService } from 'src/app/services/conf-risk-limits.service';
import { ConfMessageTemplateService } from 'src/app/services/message-template-services';
import { PolicyRiskDocService } from 'src/app/services/policy-risk-doc.service';
import { MessageSendService } from 'src/app/services/message-send.service';
import { GenerateQuoteService } from 'src/app/services/doc-create.service';
import { PolicyService } from 'src/app/services/policy.service';
import { NewQuoteService } from 'src/app/services/new-quote.service';
import {
  QUOTESTATUS,
  QUOTESTATUS_REFERRAL,
} from 'src/app/constants/config-constant';
import { TranslateService } from '@ngx-translate/core';
import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import { AbbreviateNumberPipe } from 'src/app/pipes/abbreviate-number.pipe';
import { takeUntil, Subject } from 'rxjs';
import { getLifecycleState } from 'src/app/store/lifecycle/lifecycle.select';
import { DocumentTemplateService } from 'src/app/services/doc-template.service';
import { TransactionRiskActions } from 'src/app/dashboard/constants/lifecycle-actions';
import { PolicyDeclineRequest } from 'src/app/entities/policy-lifecycle';
import { PolicyLifecycleService } from 'src/app/services/policy-lifecycle-service';
import { updatePolicyRiskIdAndStatus } from 'src/app/store/lifecycle/lifecycle.action';
import { AlertService } from 'src/app/services/alert.service';
import { getDateFormatForDatePicker } from 'src/app/utils/formatDate';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-quote-calculator-and-options',
  templateUrl: './quote-calculator-and-options.component.html',
  styleUrls: ['./quote-calculator-and-options.component.less'],
  providers: [AbbreviateNumberPipe],
})
export class QuoteCalculatorAndOptionsComponent implements OnInit, OnDestroy {
  showDocTempSelectionPopup: boolean = false;
  docPopupDetails: {
    statusType: string;
    action: any;
    documentTemplate: any;
    policyPeriodId: any;
    stageId: any;
  };
  policyId;
  selectedQuoteOptionId;
  private destroy$ = new Subject<void>();
  form$: Observable<Quote>;
  templateDetails: { [x: string]: string | number }[] = [];
  docDetails: { [x: string]: string | number | boolean }[] = [];
  rowDocDetails: { [x: string]: string | number | boolean } = {};
  isLoader: boolean = false;
  stageId: number;
  messageTypeId: number;
  showCalc: boolean = true;
  showInputDataList: boolean = false;
  isReasonModalPopupOpen: boolean = false;
  tableHeaders = [
    '',
    'Limit',
    'Deductibles',
    'Broker commissions',
    'Premium',
    'Edited premium',
  ];
  deductibleOptions = [
    { key: '$1K', value: '$1K' },
    { key: '$2K', value: '$2K' },
    { key: '$3K', value: '$3K' },
    { key: '$4K', value: '$4K' },
    { key: '$5K', value: '$5K' },
    { key: 'Custom amount', value: 'custom-amount' },
  ];
  editedPremiumOptions = [
    { key: '- 20%', value: '-20%' },
    { key: '+ 20%', value: '+20%' },
    { key: 'Custom amount', value: 'custom-amount' },
  ];
  data = {
    products: [],
    total: {
      premium: 0,
      premiumCalculated: 0,
      policyFee: 0,
      triaAmount: 0,
      netAmount: 0,
      taxesAmount: 0,
      totalAmount: 0,
      servicesAmount: 0,
      taxesPremiumAmount: 0,
      taxesServicesAmount: 0,
    },
    ui: {
      selectedForCompare: false,
      isQuoteBoxExpanded: true,
      optionSelected: false,
    },
  };
  addExcessErrMsg = this.translateService.instant(
    'quoteCalculator.error.addExcessErrMsg',
  );
  removeExcessErrMsg = this.translateService.instant(
    'quoteCalculator.error.removeExcessErrMsg',
  );
  initialSelectedPdts = [];
  isEditMode = false;
  currentEditQuoteOptnNumber = '';
  isEditedPremiumErr = [];
  editIndex;
  isQuoteSelected = false;
  isCollapseAllEnabled = true;
  showSendModal = false;
  showDocPreview = false;
  currentPreviewDocUrl;
  selectedQuoteDetails;
  noProducerErrMsg = this.translateService.instant(
    'quoteCalculator.error.noProducerErrMsg',
  );
  brokerageCommissionMax = {};
  brokerageCommissionMin = {};
  brokerageCommissionDefault = {};
  brokerageCommissionText = {};
  brokerCommissionStep = BROKER_COMMISSION_STEP_VALUE;
  cyberExcessLimit;
  cyberExcessPdtName = CYBER_EXCESS_PRODUCT_NAME;
  showSuccessAlert = false;
  alertMsg = '';
  alertErrMsg = [];
  showErrorAlert = false;
  showInfoAlert = false;
  limitExceedErrMsg = this.translateService.instant(
    'quoteCalculator.error.limitExceedErrMsg',
  );
  limitMinimumErrMsg = this.translateService.instant(
    'quoteCalculator.error.limitMinimumErrMsg',
  );
  deductibleExceedErrMsg = this.translateService.instant(
    'quoteCalculator.error.deductibleExceedErrMsg',
  );
  deductibleMinimumErrMsg = this.translateService.instant(
    'quoteCalculator.error.deductibleMinimumErrMsg',
  );
  limitErr;
  brokerageProducerCommissionMax = 0;
  brokerageProducerCommissionMin = 0;
  brokerageCommissionMaxRaw = 0;
  brokerageCommissionMinRaw = 0;
  showBanner = false;
  userID: number;
  policyPeriodId: number = null;
  policyRiskId: number = null;
  versionId = 1;
  regionId;
  showBodySpinner: boolean = false;
  showSpinner: boolean = false;
  calledCalculate: number = 0;
  limitOptionsForProducts = {};
  deductibleOptionsForProducts = {};
  productsAdded = [];
  underWriters = [];
  underWritersIds = [];
  updatedProductsWithId = [];
  isEditedPremiumSaving = false;
  domainIdForReferral;
  duplicateButtonClicked: boolean = false;
  selectedRiskIds = '';
  newTimeout: NodeJS.Timeout;
  currency = '';
  clearingCalc = false;
  showSublimitModal = false;
  riskCoverageSubscription;
  coverageDetails: any[];
  productDetails: any;
  coverageForm;
  insuredDetails: any;
  title;
  deductibles;
  coverageDeductibles;
  showCoverageLimitCustomField: boolean = false;
  showCoverageDeductibleCustomField: boolean = false;

  occurrenceTypeDropdownValues;
  measuresDropdownValues;
  quoteOptionIdForSublimitSlideout;
  sublimitOptionsForFinancialCoverage;
  sublimitAmountsForFinancialCoverage = [
    25000, 50000, 100000, 150000, 200000, 250000,
  ];
  sublimitIconSrc =
    'assets/img/dashboard/quote/lifecycle/arrow-right-circle.png';

  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  currentQuoteStatus: string = '';
  insuredTimezone;
  insuredId;
  toggle;
  productsSelected = [];
  actionPopupDetails: {
    quoteNumber: string;
    effectiveDate: string;
    insured: string;
    brokerage: string;
    branch: string;
    reasonOptions: any[];
    action: TransactionRiskActions;
  };
  sendQuoteDropdownOptions = [
    { key: 'Send quote', value: 'Send quote' },
    { key: 'Send comparison', value: 'Send comparison' },
  ];
  showActionPopup: boolean = false;
  public transactionRiskActions = TransactionRiskActions;
  showSendCompareModal = false;
  isReferralAlertShown = false;
  brokerageCommissionPercentage: number = 0;
  modalPopupType: string = '';
  sendButtonDisabled = false;
  isDropdownActive: boolean = true;
  selectedQuoteOptionNumber: number = undefined;
  shortDateFormat: string = '';
  longDateFormat: string = '';

  constructor(
    private router: Router,
    private store: Store,
    private confDeductibleService: ConfDeductibleService,
    private brokerageCommissionService: BrokerageCommissionService,
    private brokerageProducerCommissionService: BrokerageProducerCommissionService,
    private riskRatingCalculateService: RiskRatingCalculateService,
    private domainsService: DomainsService,
    private policyQuoteOptionService: PolicyQuoteOptionService,
    private riskRegionService: RiskRegionService,
    private policyRiskService: PolicyRiskService,
    private localStorageService: LocalStorageService,
    private quoteOptionServicesService: QuoteOptionServicesService,
    private insuredService: InsuredService,
    private locationService: LocationService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private confRiskLimitsService: ConfRiskLimitsService,
    private messageTemplateService: ConfMessageTemplateService,
    private policyRiskDocService: PolicyRiskDocService,
    private messageSendService: MessageSendService,
    private generateQuoteService: GenerateQuoteService,
    private policyService: PolicyService,
    private newQuoteService: NewQuoteService,
    private translateService: TranslateService,
    private DocumentTemplateService: DocumentTemplateService,
    private policyLifecycleService: PolicyLifecycleService,
    private translate: TranslateService,
    private alertService: AlertService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });

    this.store.pipe(select(getLifecycleState)).subscribe((data) => {
      this.currentQuoteStatus = data.quoteStatus;
    });

    this.userID = this.localStorageService.getBoxxUserId();
    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));
    this.toggle = { expandFeeSection: true, expandPremiumSection: true };
    const defaultQuoteCreateError =
      this.newQuoteService.getCreateDefaultQuoteError() || '';
    if (defaultQuoteCreateError !== '') {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: defaultQuoteCreateError,
      };
      this.alertService.addAlert(alertData);
      this.newQuoteService.clearCreateDefaultQuote();
    }
    this.populateSublimitOptions();
    this.form$ = this.store.pipe(select(getQuoteSelector));
    this.form$.pipe().subscribe((data) => {
      let quoteOptions = data?.quoteOptions ?? [];
      if (quoteOptions.length > 0) {
        let selectedQuoteOption = quoteOptions.filter(
          (quoteOption) => quoteOption.ui.optionSelected === true,
        );
        let selectedQuoteOptionDetails =
          selectedQuoteOption.length > 0 ? selectedQuoteOption[0] : {};
        this.selectedQuoteOptionNumber =
          selectedQuoteOptionDetails?.['products']?.[0]?.['optionNumber'] ??
          undefined;
      }
    });
    this.form$
      .pipe(take(1))
      .subscribe(
        (event) => (
          (this.policyPeriodId = Number(event.ui.policyPeriodId)),
          (this.policyId = Number(event.ui.policyId))
        ),
      );
    let products;
    let brokerageId;
    let producerId;
    let uiData: UISpecificValues;

    this.form$.pipe(take(1)).subscribe((event) => {
      products = event.products;
      this.regionId = event.ui.regionId;
      uiData = event.ui;
      ({ brokerageId, producerId } = event.ui);
    });
    const riskIds = products
      .map((currentValue) => currentValue.riskId)
      .join(',');
    this.selectedRiskIds = products
      .filter((product) => product.checked)
      .map((currentValue) => currentValue.riskId)
      .join(',');
    products?.forEach((uw) => {
      this.underWriters.push(uw?.underwriter);
      this.underWritersIds.push(uw?.underwriterId);
    });
    let policyId = null;
    await this.getDomainIdForReferral();
    this.docPopupDetails = {
      statusType: 'Document template selection',
      action: 'docTempSelection',
      documentTemplate: [],
      policyPeriodId: 0,
      stageId: 0,
    };
    // prepopulate data when navigated from quote lifecycle screen
    if (
      this.policyPeriodId &&
      this.policyPeriodId !== 0 &&
      (uiData?.isNavigatedFromLifecycle || uiData?.isNavigatedFromSummary)
    ) {
      const isNavigatedFromSummary = uiData.isNavigatedFromSummary;
      if (isNavigatedFromSummary) {
        this.store.dispatch(
          new CreateQuoteAction.ResetIsNavigatedFromSummary(),
        );
        this.store.dispatch(new CreateQuoteAction.clearQuoteOptions());
      }
      this.showBodySpinner = true;
      if (uiData?.isNavigatedFromLifecycle) {
        this.store.dispatch(
          new CreateQuoteAction.ResetIsNavigatedFromLifecycle(false),
        );
      }

      // Fetch policy risk trx data
      this.policyRiskService
        .GetAllByPolicyPeriodId(this.policyPeriodId)
        .subscribe({
          next: async (resp) => {
            this.policyRiskId = resp.data[0]?.id;
            let quoteStatusLabel =
              this.currentQuoteStatus !== null
                ? this.currentQuoteStatus
                : 'quote';
            this.store.dispatch(
              new updatePolicyRiskIdAndStatus({
                policyRiskId: resp.data[0]?.id,
                quoteStatus: quoteStatusLabel,
              }),
            );
            const insuredRespObj = resp?.data[0]?.policyPeriod?.policy?.insured;
            const insuredId = resp?.data[0]?.policyPeriod?.policy?.insuredId;
            this.insuredId = insuredId;
            policyId = resp?.data[0]?.policyPeriod?.policy?.id;

            this.store.dispatch(
              new CreateQuoteAction.updateSharedDataAction({
                activeStep: 3,
                completedSteps: 2,
                step:
                  this.translateService.instant('quoteSummary.quoteID') +
                  ': ' +
                  Number(policyId),
              }),
            );

            if (policyId) {
              this.saveEmailsForPopup(policyId);
            }

            const insuredDetails = {
              name:
                insuredRespObj?.insuredType == 2
                  ? insuredRespObj?.companyName
                  : insuredRespObj?.firstName + insuredRespObj?.lastName,
            };
            const policy = resp?.data[0]?.policyPeriod?.policy;
            const brokerageData = {
              brokerageId: policy?.brokerageBORId,
              producerId: policy?.brokerageProducerBORId,
            };
            let startDate = getDateFormatForDatePicker(
              resp?.data[0]?.policyPeriod?.effectiveDt,
              this.shortDateFormat,
            );
            let endDate = getDateFormatForDatePicker(
              resp?.data[0]?.policyPeriod?.expiryDt,
              this.shortDateFormat,
            );
            this.store.dispatch(
              new CreateQuoteAction.UpdateInsuredAction({
                insuredName: insuredDetails.name,
                insuredEmail: null,
                insuredId,
                policyId: Number(policyId),
                quoteCreatedDate: resp?.data[0]?.policyPeriod.createdDt,
                startDate: startDate,
                endDate: endDate,
                ...brokerageData,
              }),
            );

            const productsSelected = resp.data.map((risk) => {
              return risk?.risk?.name;
            });

            this.selectedRiskIds = resp.data
              .map((risk) => risk?.risk?.id)
              .join(',');

            this.form$.subscribe((event) => (products = event.products));

            this.populateProducts(insuredId, productsSelected, brokerageData);

            // adding quote option list

            // add risk name, policyRiskTrxId on each quoteoption

            let quoteOptions = [];
            resp.data?.forEach((risk) => {
              const policyRiskTrxId = risk?.PolicyRiskTrxes[0].id;
              risk?.PolicyRiskTrxes[0]?.QuoteOptions.forEach((quoteOption) => {
                quoteOption.policyRiskTrxId = policyRiskTrxId;
                quoteOption.risk = risk?.risk;
                quoteOptions.push(quoteOption);
              });
            });
            const optionNumbers = quoteOptions
              .map((quoteOption) => quoteOption?.optionNumber)
              .filter((val, idx, arr) => arr.indexOf(val) === idx);
            optionNumbers.forEach((optionNumber) => {
              let quoteOptionGroup = {
                products: [],
                total: {
                  premium: 0,
                  premiumCalculated: 0,
                  policyFee: 0,
                  triaAmount: 0,
                  netAmount: 0,
                  taxesAmount: 0,
                  totalAmount: 0,
                  servicesAmount: 0,
                  taxesPremiumAmount: 0,
                  taxesServicesAmount: 0,
                },
                ui: {
                  selectedForCompare: false,
                  isQuoteBoxExpanded: true,
                  optionSelected: false,
                },
              };
              quoteOptionGroup.products = quoteOptions
                ?.filter(
                  (quoteOption) => quoteOption.optionNumber == optionNumber,
                )
                .map((quoteOption) => {
                  const policyFee = quoteOption.policyFee
                    ? Number(quoteOption.policyFee)
                    : 0;
                  const triaAmount = quoteOption.triaAmount
                    ? Number(quoteOption.triaAmount)
                    : 0;
                  const netAmount =
                    Number(quoteOption.premium ? quoteOption.premium : 0) +
                    Number(
                      quoteOption.servicesAmount
                        ? quoteOption.servicesAmount
                        : 0,
                    );
                  const taxesAmount =
                    Number(
                      quoteOption.taxesServicesAmount
                        ? quoteOption.taxesServicesAmount
                        : 0,
                    ) +
                    Number(
                      quoteOption.taxesPremiumAmount
                        ? quoteOption.taxesPremiumAmount
                        : 0,
                    );
                  const totalAmount =
                    Number(policyFee) +
                    Number(netAmount) +
                    Number(taxesAmount) +
                    Number(triaAmount);
                  const servicesAmount = Number(
                    quoteOption.servicesAmount ? quoteOption.servicesAmount : 0,
                  );
                  const taxesPremiumAmount = Number(
                    quoteOption.taxesPremiumAmount
                      ? quoteOption.taxesPremiumAmount
                      : 0,
                  );

                  return {
                    ...quoteOption,
                    key: quoteOption?.risk.name,
                    value: quoteOption?.risk.name,
                    description: 'description',
                    checked: true,
                    active: true,
                    addons: [],
                    underwriter: '',
                    riskId: quoteOption?.risk.id,
                    limit: quoteOption.limit,
                    brokerCommissionPerc: quoteOption.brokerCommissionPerc
                      ? getDecimalPlace(
                          Number(quoteOption.brokerCommissionPerc) * 100,
                          2,
                        )
                      : 0,
                    deductables: quoteOption.deductible,
                    standaloneSellable:
                      resp.data[0].risk?.standaloneSellable == 1,
                    showLimitCustomInput: false,
                    showDeductibleCustomInput: false,
                    showPremiumCalcBtn: false,
                    premium: quoteOption.premium,
                    showEditedPremiumCustomField: false,
                    premiumCalculated: quoteOption.premiumCalculated,
                    editedPremiumDeviation: '',
                    expandAddOns: false,
                    quoteOptionId: quoteOption.id,
                    optionNumber: quoteOption.optionNumber,
                    showInvalidLimitErr: false,
                    showInvalidLimitErrMsg: '',
                    pdtAddOns: [],
                    policyFee: policyFee,
                    triaAmount: triaAmount,
                    netAmount: netAmount,
                    taxesAmount: taxesAmount,
                    totalAmount: totalAmount,
                    servicesAmount: servicesAmount,
                    taxesPremiumAmount: taxesPremiumAmount,
                    policyRiskTrxId: quoteOption?.policyRiskTrxId,
                    quoteStatus: quoteOption?.quoteStatus,
                  };
                });

              quoteOptionGroup?.products.forEach((product) => {
                quoteOptionGroup.total.premium += Number(product.premium);
                quoteOptionGroup.total.premiumCalculated += Number(
                  product.premiumCalculated,
                );
                quoteOptionGroup.total.triaAmount += Number(product.triaAmount);
                quoteOptionGroup.total.policyFee += Number(product.policyFee);
                quoteOptionGroup.total.taxesAmount += Number(
                  product.taxesAmount,
                );
                quoteOptionGroup.total.servicesAmount += Number(
                  product.servicesAmount,
                );
                quoteOptionGroup.total.taxesPremiumAmount += Number(
                  product.taxesPremiumAmount,
                );
                quoteOptionGroup.total.taxesServicesAmount += Number(
                  product.taxesServicesAmount,
                );
                quoteOptionGroup.total.netAmount += Number(product.netAmount);
                quoteOptionGroup.total.totalAmount += Number(
                  product.totalAmount,
                );
              });
              quoteOptionGroup.ui.optionSelected =
                quoteOptionGroup?.products[0]?.optionSelected == 1;
              if (quoteOptionGroup?.products[0]?.optionSelected === 1) {
                this.brokerageCommissionPercentage = Number(
                  quoteOptionGroup?.products[0]?.brokerCommissionPerc,
                );
              }
              const quoteOptionCopy = this.getCopy(quoteOptionGroup);
              this.store.dispatch(
                new CreateQuoteAction.AddQuoteOption({
                  quote: { ...quoteOptionCopy },
                }),
              );
              if (products?.length > 0) {
                this.updatedProductsWithId = products.map((product) => {
                  const policyRiskTrxId = quoteOptionCopy.products.filter(
                    (pdt) => pdt.key == product.key,
                  )[0]?.policyRiskTrxId;
                  return {
                    ...product,
                    policyRiskTrxId,
                  };
                });

                this.store.dispatch(
                  CreateQuoteAction.loadProductsSuccess({
                    products: this.updatedProductsWithId,
                  }),
                );
              }
            });
          },
          error: (e) => {
            this.showBodySpinner = false;
            if (![500].includes(e?.status)) {
              const alertData = {
                type: 'error',
                headerText: getAlertHead('error'),
                bodyText: getErrorMessage(e),
              };
              this.alertService.addAlert(alertData);
            }
          },
        });
    } else {
      if (this.policyPeriodId && this.policyPeriodId !== 0) {
        this.policyRiskService
          .GetAllByPolicyPeriodId(this.policyPeriodId)
          .subscribe({
            next: (res) => {
              policyId = res?.data[0]?.policyPeriod?.policy?.id;
              this.store.dispatch(
                new CreateQuoteAction.UpdateInsuredAction({
                  quoteCreatedDate: res?.data[0]?.policyPeriod.createdDt,
                }),
              );
              if (policyId) {
                this.saveEmailsForPopup(policyId);

                this.store.dispatch(
                  new CreateQuoteAction.updateSharedDataAction({
                    activeStep: 3,
                    completedSteps: 2,
                    step:
                      this.translateService.instant('quoteSummary.quoteID') +
                      ': ' +
                      Number(policyId),
                  }),
                );
              }
              let quoteStatusLabel =
                this.currentQuoteStatus !== null
                  ? this.currentQuoteStatus
                  : 'quote';
              this.policyRiskId = res.data[0].id;
              this.store.dispatch(
                new updatePolicyRiskIdAndStatus({
                  policyRiskId: res.data[0].id,
                  quoteStatus: quoteStatusLabel,
                }),
              );
            },
            error: (err) => {},
          });
      }

      await this.populateBrokerageCommission(
        this.selectedRiskIds,
        brokerageId,
        producerId,
      );
      this.populateDeductibles(riskIds);

      products.forEach((product) => {
        this.populateLimitOptions(product.riskId, product.key);
      });
    }
    this.populateCyberExcessLimit();
    this.getOccurrenceTypeDropdownValues();
    this.getMeasuredDropdownValues();
    this.getTimeZoneOfInsured();
  }

  ngOnDestroy(): void {
    this.alertService.clearAlerts(-1);
  }

  async saveEmailsForPopup(policyId) {
    const getPolicyByID = this.policyService.GetByPolicyId(policyId);
    const policyInfo = await firstValueFrom(getPolicyByID);

    let mailIdList = {
      broker: policyInfo?.data?.brokerageBOR?.email ?? '',
      branch: policyInfo?.data?.brokerageBranchBOR?.email ?? '',
      producer: policyInfo?.data?.brokerageProducerBOR?.email ?? '',
      insured: policyInfo?.data?.insured?.email ?? '',
    };
    this.store.dispatch(new CreateQuoteAction.setAllMailId(mailIdList));
  }

  changeIconSrc(iconPath: string) {
    this.sublimitIconSrc = iconPath;
  }

  populateSublimitOptions() {
    const sublimitOptions = this.sublimitAmountsForFinancialCoverage.map(
      (amount) => ({
        key: formatAmountWithCurrency(amount, this.currency),
        value: valInDigits(amount),
      }),
    );
    this.sublimitOptionsForFinancialCoverage = sublimitOptions;
  }

  getOccurrenceTypeDropdownValues() {
    this.domainsService.GetByDomainCode('OCCURRENCETYPE').subscribe((data) => {
      this.occurrenceTypeDropdownValues = data.data.map((dataObj) => ({
        key: dataObj?.description,
        value: dataObj?.description,
        id: dataObj.id,
      }));
    });
  }

  getMeasuredDropdownValues() {
    this.domainsService.GetByDomainCode('TIMEMEASSURE').subscribe((data) => {
      this.measuresDropdownValues = data.data.map((dataObj) => ({
        key: dataObj?.description,
        value: dataObj?.description,
        id: dataObj.id,
      }));
    });
  }

  formatDate(date) {
    if (date) {
      return this.datePipe.transform(new Date(date), this.shortDateFormat);
    }
    return null;
  }
  populateLimitOptions(riskId, riskName) {
    this.confRiskLimitsService
      .GetByRegionIdAndRiskIds(this.regionId, riskId)
      .subscribe({
        next: (response) => {
          const limitOptions = response.data.map((dataObj) => ({
            key: formatAmountWithCurrency(dataObj.limit, this.currency),
            value: valInDigits(dataObj.limit),
            id: dataObj.id,
          }));
          limitOptions.push({ key: 'Custom amount', value: 'custom-amount' });
          this.limitOptionsForProducts[riskName] = limitOptions;
        },
        error: (error) => {
          const limitOptions = [
            { key: 'Custom amount', value: 'custom-amount' },
          ];
          this.limitOptionsForProducts[riskName] = limitOptions;
        },
      });
  }
  populateProducts(insuredId, productsSelected, brokerageData) {
    try {
      this.insuredService.Get(insuredId).subscribe((data) => {
        if (data.data?.insuredType.toLowerCase() == 'company') {
          this.versionId = 2;
        }
        const locationId = data.data.insuredLocation.id;
        this.locationService.Get(locationId).subscribe((data) => {
          this.regionId = data.data.region.id;
          this.store.dispatch(
            new CreateQuoteAction.UpdateInsuredAction({
              regionId: data.data.region.id,
              versionId: this.versionId,
            }),
          );

          this.riskRegionService
            .getRiskRegionAndVersion(
              this.regionId,
              this.versionId,
              STANDALONE_SELLABLE_TRUE,
            )
            .subscribe(async (data) => {
              let tableData = await Promise.all(
                data.data?.map(async (item: any, index, arr) => {
                  this.populateLimitOptions(item.risk.id, item.risk.name);
                  let hasSubProduct = false,
                    subProductName = '',
                    subProductRiskId = null;
                  let hasParentProduct = false,
                    parentRiskId = null;
                  if (item.risk?.standaloneSellable == 1) {
                    const getSubProduct =
                      this.riskRegionService.getBySellableWithRiskId(
                        item.risk.id,
                      );
                    const getSubProductResponse =
                      await firstValueFrom(getSubProduct);
                    if (getSubProductResponse?.data?.length > 0) {
                      hasSubProduct = true;
                      subProductName = getSubProductResponse?.data[0]?.name;
                      subProductRiskId = getSubProductResponse?.data[0]?.id;
                    }
                  } else {
                    const getParentProduct = this.riskRegionService.getRiskById(
                      item.risk.id,
                    );
                    const getParentProductResponse =
                      await firstValueFrom(getParentProduct);
                    if (
                      getParentProductResponse?.data?.id &&
                      getParentProductResponse?.data?.sellableWithRiskId
                    ) {
                      hasParentProduct = true;
                      parentRiskId = getParentProductResponse?.data?.id;
                    }
                  }

                  const policyRiskTrxId = this.updatedProductsWithId.filter(
                    (product) => product.key == item.risk.name,
                  )[0]?.policyRiskTrxId;

                  return {
                    key: item.risk.name,
                    value: item.risk.name,
                    description: item.risk.description,
                    checked: productsSelected.includes(item.risk.name),
                    active: true,
                    addons: [],
                    underwriter: this.underWriters[index],
                    underwriterId: this.underWritersIds[index],
                    riskId: item.risk.id,
                    standaloneSellable:
                      item.risk?.standaloneSellable ===
                      STANDALONE_SELLABLE_TRUE,
                    hasSubProduct,
                    subProductName,
                    subProductRiskId,
                    hasParentProduct,
                    parentRiskId,
                    policyRiskTrxId,
                  };
                }),
              );
              const riskIds = tableData
                .map((currentValue) => currentValue.riskId)
                .join(',');
              let addons = [];
              this.riskRegionService
                .getRiskServicesById(riskIds)
                .subscribe(async (data) => {
                  addons = data.data?.map((item: any, index, arr) => ({
                    name: item.description,
                    id: item.id,
                    value: item.serviceName,
                    riskId: item.risk.id,
                  }));

                  tableData = tableData.map((item) => {
                    return {
                      ...item,
                      addons: addons.filter((a) => a.riskId === item.riskId),
                    };
                  });

                  this.productsAdded = tableData;
                  this.selectedRiskIds = tableData
                    ?.filter((product) => product.checked)
                    .map((product) => product?.riskId)
                    .join(',');
                  this.store.dispatch(
                    CreateQuoteAction.loadProductsSuccess({
                      products: tableData,
                    }),
                  );
                  await this.populateBrokerageCommission(
                    this.selectedRiskIds,
                    brokerageData?.brokerageId,
                    brokerageData?.producerId,
                  );
                  this.populateDeductibles(riskIds);
                  this.showBodySpinner = false;
                });
            });
        });
      });
    } catch (error) {
      if (![500].includes(error?.status)) {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: getErrorMessage(error),
        };
        this.alertService.addAlert(alertData);
      }
    }
  }

  populateCyberExcessLimit() {
    this.domainsService
      .GetByDomainCode('CYBEREXCESSLIMIT')
      .subscribe((data) => {
        const tableData = data.data.map((dataObj) => ({
          key: valInDigits(dataObj.valueInt1),
          value: valInDigits(dataObj.valueInt1),
          id: dataObj.id,
        }));
        this.cyberExcessLimit = valInDigits(tableData[0].value);
        this.addExcessErrMsg = this.translateService
          .instant('quoteCalculator.error.addExcessErrMsgEditable')
          ?.replace('{limit}', tableData[0].value);
      });
  }

  async populateBrokerageCommission(riskIds, brokerageId, producerId) {
    this.brokerageProducerCommissionService
      .GetByRegionIdAndRiskIdsAndProducerId(this.regionId, riskIds, producerId)
      .subscribe(async (data) => {
        let producerCommissionNotExistsFor = [];
        // GET COMMISSIONS FROM PRODUCER
        if (data.data[0]) {
          riskIds
            ?.split(',')
            .map(Number)
            .forEach((riskId) => {
              const brokerForRiskId = data.data.filter(
                (broker) =>
                  broker.active && broker?.riskRegion.risk.id === riskId,
              );
              if (brokerForRiskId.length > 0) {
                this.brokerageCommissionMax[riskId] = Number(
                  getDecimalPlace(
                    Number(brokerForRiskId[0].commissionMax) * 100,
                    2,
                  ),
                );
                this.brokerageCommissionMin[riskId] = Number(
                  getDecimalPlace(
                    Number(brokerForRiskId[0].commissionMin) * 100,
                    2,
                  ),
                );
                this.brokerageCommissionDefault[riskId] = Number(
                  getDecimalPlace(
                    Number(brokerForRiskId[0].commissionDefault) * 100,
                    2,
                  ),
                );
                let rangeText = this.translateService.instant('common.range');
                this.brokerageCommissionText[
                  riskId
                ] = `${rangeText} ${this.brokerageCommissionMin[riskId]}% - ${this.brokerageCommissionMax[riskId]}%`;
              } else {
                producerCommissionNotExistsFor.push(riskId);
              }
            });
        } else {
          producerCommissionNotExistsFor.push(
            ...riskIds.split(',').map(Number),
          );
        }
        if (producerCommissionNotExistsFor.length > 0) {
          let riskIdWithoutCommission = [];
          await this.brokerageCommissionService
            .GetByRegionIdAndRiskIdsAndBrokerageId(
              this.regionId,
              riskIds,
              brokerageId,
            )
            .subscribe((data) => {
              let riskIdsToAddCommission = [];
              if (data.data.length === 0) {
                riskIdsToAddCommission = riskIds.split(',').map(Number);
                riskIdWithoutCommission = riskIdsToAddCommission;
              } else {
                riskIdsToAddCommission = producerCommissionNotExistsFor;
              }
              // GET COMMISSIONS FROM BROKERAGE
              if (data.data?.length > 0) {
                riskIdsToAddCommission.forEach((riskId) => {
                  const commissionForRiskId = data.data.filter(
                    (broker) =>
                      broker.active && broker?.riskRegion.risk.id === riskId,
                  );
                  if (commissionForRiskId.length > 0) {
                    const brokerCommissionMax = Number(
                      getDecimalPlace(
                        Number(data.data[0].commissionMax) * 100,
                        2,
                      ),
                    );
                    const brokerCommissionMin = Number(
                      getDecimalPlace(
                        Number(data.data[0].commissionMin) * 100,
                        2,
                      ),
                    );
                    this.brokerageCommissionMax[riskId] = brokerCommissionMax;
                    this.brokerageCommissionMin[riskId] = brokerCommissionMin;
                    this.brokerageCommissionDefault[riskId] = Number(
                      getDecimalPlace(
                        Number(commissionForRiskId[0].commissionDefault) * 100,
                        2,
                      ),
                    );
                    let rangeText =
                      this.translateService.instant('common.range');
                    this.brokerageCommissionText[
                      riskId
                    ] = `${rangeText} ${this.brokerageCommissionMin[riskId]}% - ${this.brokerageCommissionMax[riskId]}%`;
                  } else {
                    riskIdWithoutCommission.push(riskId);
                  }
                });
              }

              if (!this.showErrorAlert && riskIdWithoutCommission?.length > 0) {
                let commissionLink = {
                  label: this.translateService.instant(
                    'common.addCommissionLinkLabel',
                  ),
                  path: `/dashboard/brokerage/view/${brokerageId}`,
                };
                const alertData = {
                  type: 'error',
                  headerText: getAlertHead('error'),
                  bodyText: this.translateService.instant(
                    'quoteCalculator.error.invalidBrokerCommission',
                  ),
                  linkData: commissionLink,
                };
                this.alertService.addAlert(alertData);
                this.showErrorAlert = true;
              }
            });
        }
      });
  }

  populateDeductibles(riskIds) {
    this.confDeductibleService
      .GetByRegionIdAndRiskIds(this.regionId, riskIds)
      .subscribe((data) => {
        const tableData = data.data?.map((dataObj) => ({
          key: formatAmountWithCurrency(dataObj.value, this.currency),
          value: valInDigits(dataObj.value),
          id: dataObj.id,
        }));

        tableData.push({ key: 'Custom amount', value: 'custom-amount' });
        this.deductibleOptions = removeDuplicatedKeys(tableData);
      });
  }

  closeAlert() {
    this.showSuccessAlert = false;
    this.showErrorAlert = false;
    this.showInfoAlert = false;
    this.alertMsg = '';
    this.alertErrMsg = [];
  }

  handleCalcToggle() {
    this.showCalc = !this.showCalc;
  }

  showLimitCustomField(product) {
    return this.data?.products?.filter((p) => p.value == product)[0]
      ?.showLimitCustomInput;
  }

  getLimitErrMsg(product) {
    const currentProduct = this.data?.products?.filter(
      (p) => p.key == product,
    )[0];
    return currentProduct?.showInvalidLimitErr
      ? currentProduct?.showInvalidLimitErrMsg
      : '';
  }

  getDeductibleErrMsg(product) {
    const currentProduct = this.data?.products?.filter(
      (p) => p.key == product,
    )[0];
    return currentProduct?.showInvalidDeductibleErr
      ? currentProduct?.showInvalidDeductibleErrMsg
      : '';
  }

  checkIfAddExcessErr(value) {
    value = valInDigits(value);
    if (
      value > this.cyberExcessLimit &&
      this.data.products.filter(
        (p) => p.key?.toLowerCase() == CYBER_EXCESS_PRODUCT_NAME,
      ).length == 0
    ) {
    } else if (value < this.cyberExcessLimit) {
      if (
        this.data.products.filter(
          (p) => p.key?.toLowerCase() == CYBER_EXCESS_PRODUCT_NAME,
        ).length > 0
      ) {
        if (this.isEditMode) {
          let quoteOptions;
          this.form$.subscribe((event) => (quoteOptions = event.quoteOptions));
          const currentQuoteOptionIdx = quoteOptions.findIndex(
            (quoteOption) =>
              quoteOption?.products[0]?.optionNumber ==
              this.currentEditQuoteOptnNumber,
          );
          if (currentQuoteOptionIdx !== -1) {
            const isExcessPdtPresent =
              quoteOptions[currentQuoteOptionIdx]?.products.filter(
                (pdt) => pdt.key.toLowerCase() == CYBER_EXCESS_PRODUCT_NAME,
              )?.length > 0;
            if (isExcessPdtPresent) {
            } else {
              this.removeExcess();
            }
          }
        } else {
          this.removeExcess();
        }
      }
    } else if (
      value > this.cyberExcessLimit &&
      this.data.products.filter(
        (p) => p.key?.toLowerCase() == CYBER_EXCESS_PRODUCT_NAME,
      ).length > 0
    ) {
    }
  }

  isExcessPresent() {
    const excessProductData = this.data.products.findIndex(
      (p) => p.key?.toLowerCase() == CYBER_EXCESS_PRODUCT_NAME,
    );
    if (excessProductData >= 0) {
      return true;
    }
    return false;
  }

  handleLimitChange(value, pdt) {
    const currentProduct = this.data.products.filter((p) => p.value == pdt)[0];
    this.resetPremiumOnValChange(pdt);
    if (value == 'custom-amount') {
      currentProduct.showLimitCustomInput = true;
      this.showCoverageLimitCustomField = true;
    } else {
      currentProduct.limit = value;
      currentProduct.showInvalidLimitErr = false;
      currentProduct.showInvalidLimitErrMsg = '';
    }
  }

  handleLimitCustomValue(event, pdt) {
    this.resetPremiumOnValChange(pdt);
    const formattedLimit = event.target.value
      ? event.target.value?.replace(/[$,]/g, '')
      : 0;
    this.data.products.filter((p) => p.value == pdt)[0].limit = formattedLimit;
    let currentProduct = this.data.products.filter((p) => p.value == pdt)[0];
    const limitOptionsArr = this.limitOptionsForProducts[currentProduct?.key]
      ?.filter(
        (limit) =>
          this.isNumber(valInDigits(limit.key)) && limit.key != 'Custom amount',
      )
      .map((limit) => valInDigits(limit.key));
    const minLimit = Math.min(...limitOptionsArr);
    const maxLimit = Math.max(...limitOptionsArr);
    if (valInDigits(formattedLimit) > maxLimit) {
      currentProduct.showInvalidLimitErr = true;
      currentProduct.showInvalidLimitErrMsg = this.limitExceedErrMsg
        .replace('[productName]', currentProduct?.key)
        .replace(
          '[maximumLimit]',
          formatAmountWithCurrency(maxLimit, this.currency)?.toString(),
        );
    } else if (valInDigits(formattedLimit) < minLimit) {
      currentProduct.showInvalidLimitErr = true;
      currentProduct.showInvalidLimitErrMsg = this.limitMinimumErrMsg
        .replace('[productName]', currentProduct?.key)
        .replace(
          '[minimumLimit]',
          formatAmountWithCurrency(minLimit, this.currency)?.toString(),
        );
    } else {
      currentProduct.showInvalidLimitErr = false;
      currentProduct.showInvalidLimitErrMsg = '';
    }
    currentProduct.showLimitCustomInput = false;
  }

  getLimitValue(riskId) {
    return this.data?.products.filter((p) => p.riskId == riskId)[0].limit;
  }

  isNumber(number) {
    return !isNaN(number);
  }

  showDeductibleCustomField(riskId) {
    return this.data?.products?.filter((p) => p.riskId == riskId)[0]
      ?.showDeductibleCustomInput;
  }

  handleDeductableChange(value, pdt) {
    const isExcessPresent = this.isExcessPresent();
    this.resetPremiumOnValChange(pdt);
    if (value == 'custom-amount') {
      this.data.products.filter(
        (p) => p.value == pdt,
      )[0].showDeductibleCustomInput = true;
      this.showCoverageDeductibleCustomField = true;
    } else {
      this.data.products.filter((p) => p.value == pdt)[0].deductables = value;
      if (isExcessPresent) {
        this.data.products.filter(
          (p) => p.key?.toLowerCase() == CYBER_EXCESS_PRODUCT_NAME,
        )[0].deductables = value;
      }
      this.data.products.filter(
        (p) => p.value == pdt,
      )[0].showInvalidDeductibleErr = false;
      this.data.products.filter(
        (p) => p.value == pdt,
      )[0].showInvalidDeductibleErrMsg = '';
    }
  }

  handleDeductableCustomValue(event, pdt) {
    this.resetPremiumOnValChange(pdt);
    const formattedAmount = event.target.value
      ? event.target.value?.replace(/[$,]/g, '')
      : 0;
    this.data.products.filter((p) => p.value == pdt)[0].deductables =
      formattedAmount;

    this.data.products.filter(
      (p) => p.value == pdt,
    )[0].showDeductibleCustomInput = false;
    if (this.isExcessPresent()) {
      this.data.products.filter(
        (p) => p.key?.toLowerCase() == CYBER_EXCESS_PRODUCT_NAME,
      )[0].deductables = formattedAmount;
    }

    let currentProduct = this.data.products.filter((p) => p.value == pdt)[0];
    let deductibleOptionsObj = {};
    deductibleOptionsObj[currentProduct?.key] = this.deductibleOptions;
    let deductibleOptionsArr = deductibleOptionsObj[currentProduct?.key]
      ?.filter(
        (deductible) =>
          this.isNumber(valInDigits(deductible.key)) &&
          deductible.key != 'Custom amount',
      )
      .map((deductible) => valInDigits(deductible.key));
    const minDeductible = Math.min(...deductibleOptionsArr);
    const maxDeductible = Math.max(...deductibleOptionsArr);
    if (valInDigits(formattedAmount) > maxDeductible) {
      currentProduct.showInvalidDeductibleErr = true;
      currentProduct.showInvalidDeductibleErrMsg = this.deductibleExceedErrMsg
        .replace('[productName]', currentProduct?.key)
        .replace(
          '[maximumDeductible]',
          formatAmountWithCurrency(maxDeductible, this.currency)?.toString(),
        );
    } else if (valInDigits(formattedAmount) < minDeductible) {
      currentProduct.showInvalidDeductibleErr = true;
      currentProduct.showInvalidDeductibleErrMsg = this.deductibleMinimumErrMsg
        .replace('[productName]', currentProduct?.key)
        .replace(
          '[minimumDeductible]',
          formatAmountWithCurrency(minDeductible, this.currency)?.toString(),
        );
    } else {
      currentProduct.showInvalidDeductibleErr = false;
      currentProduct.showInvalidDeductibleErrMsg = '';
    }
    currentProduct.showDeductibleCustomInput = false;
  }

  getDeductibleValue(productRiskId) {
    return this.data?.products.filter((p) => p.riskId == productRiskId)[0]
      ?.deductables;
  }
  getSelectedPdtOptions(productList) {
    const selectedProductList = productList.filter(
      (pdt) => pdt.checked && pdt.active,
    );
    if (this.data.products.length == 0) {
      this.data.products = selectedProductList.map((product) => {
        let newAddons = [];
        if (product.addons && product.addons.length > 0) {
          newAddons = product.addons.map((pdtObj) => ({ ...pdtObj }));
        }
        return {
          ...product,
          limit: '',
          deductables: '',
          brokerCommissionPerc: null,
          showLimitCustomInput: false,
          showDeductibleCustomInput: false,
          showPremiumCalcBtn:
            product?.key?.toLowerCase() != CYBER_EXCESS_PRODUCT_NAME,
          premiumCalculated: 0,
          showEditedPremiumCustomField: false,
          premium: '',
          editedPremiumDeviation: '',
          expandAddOns: false,
          showInvalidLimitErr: false,
          showInvalidLimitErrMsg: '',
          addons: newAddons,
          pdtAddOns: [],
        };
      });
    } else if (this.isEditMode) {
      return this.data.products;
    }
    return selectedProductList;
  }

  handleBrokerCommissionPerc(value, product: any) {
    this.resetPremiumOnValChange(product.value);
    this.data.products.filter(
      (p) => p.value == product.value,
    )[0].brokerCommissionPerc = value;
    if (this.isExcessPresent()) {
      this.updateBrokerCommission(value);
    }
    this.handleBrokerCommissionValidate(value, product);
  }

  handleBrokerCommissionValidate(event: any, product: any) {
    const brokerCommsionValue = event?.target?.value
      ? event.target.value
      : event;
    if (
      brokerCommsionValue >=
        Number(this.brokerageCommissionMin[product.riskId]) &&
      brokerCommsionValue <= Number(this.brokerageCommissionMax[product.riskId])
    ) {
      this.data.products.filter(
        (p) => p.value == product.value,
      )[0].brokerCommissionPerc = brokerCommsionValue;
      this.data.products.filter(
        (p) => p.value == product.value,
      )[0].hideCalculateBtn = false;
      this.isCalcBtnEnabled(product.value, this.data.products);
    } else {
      this.data.products.filter(
        (p) => p.value == product.value,
      )[0].brokerCommissionPerc =
        this.brokerageCommissionDefault[product.riskId];
      this.data.products.filter(
        (p) => p.value == product.value,
      )[0].hideCalculateBtn = true;
      this.isCalcBtnEnabled(product.value, this.data.products);
    }
  }

  updateBrokerCommission(value: number) {
    const updatedProducts = this.data.products.map((p) => {
      if (p.key?.toLowerCase() === CYBER_EXCESS_PRODUCT_NAME) {
        return { ...p, brokerCommissionPerc: value };
      }
      return p;
    });
    this.data.products = updatedProducts;
    this.cdr.detectChanges();
  }

  resetPremiumOnValChange(pdt) {
    let showPremiumCalcBtn = this.data.products.filter((p) => p.value == pdt)[0]
      ?.showPremiumCalcBtn;
    if (!showPremiumCalcBtn) {
      const currentPdt = this.data.products.filter((p) => p.value == pdt)[0];
      currentPdt.premium = 0;
      currentPdt.premiumCalculated = 0;
      currentPdt.showPremiumCalcBtn = true;
      this.data.total.premiumCalculated = Math.round(
        this.data.products.reduce((acc, currentPdtObj) => {
          return acc + Number(currentPdtObj.premiumCalculated);
        }, 0),
      );
      this.data.total.premium = Math.round(
        this.data.products.reduce((acc, currentPdtObj) => {
          return acc + Number(currentPdtObj.premium);
        }, 0),
      );
    }
  }

  async handlePremiumCalculateFromSublimit(obj) {
    await this.handlePremiumCalc(obj.product, 1, obj.updated);
  }

  async handlePremiumCalc(
    currentPdtValue,
    noOfQuoteOptns = 1,
    isLimitAndDeductUpdated = true,
  ) {
    this.closeAlert();
    if (!this.isEditMode && noOfQuoteOptns >= 10) {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: this.translateService.instant(
          'quoteCalculator.error.maxLimit',
        ),
      };
      this.alertService.addAlert(alertData);
      return;
    }
    let uiData: UISpecificValues;
    this.calledCalculate = 1;
    this.showSpinner = false;
    this.form$.subscribe((event) => (uiData = event.ui));
    const totals = {
      policyFee: 0,
      triaAmount: 0,
      netAmount: 0,
      taxesAmount: 0,
      totalAmount: 0,
      servicesAmount: 0,
      taxesPremiumAmount: 0,
      premium: 0,
      premiumCalculated: 0,
    };
    // get product where calculate is clicked
    const currentProduct = this.data.products.filter(
      (product) => product.value == currentPdtValue,
    )[0];
    currentProduct.brokerCommissionPerc = getDecimalPlace(
      currentProduct?.brokerCommissionPerc,
      2,
    );
    if (!currentProduct?.policyRiskTrxId) {
      let products;
      this.form$
        .pipe(take(1))
        .subscribe((event) => (products = event.products));
      const selectedProductData = products.filter(
        (product) => product.value == currentPdtValue,
      )[0];
      if (selectedProductData?.policyRiskTrxId) {
        currentProduct.policyRiskTrxId = selectedProductData?.policyRiskTrxId;
      } else {
        let policyRiskTrxId;
        this.form$
          .pipe(take(1))
          .subscribe((event) => (policyRiskTrxId = event.ui?.policyRiskTrxId));
        if (policyRiskTrxId) {
          currentProduct.policyRiskTrxId = policyRiskTrxId;
        }
      }
    }

    // to get selected add ons
    let quoteOptionServices = currentProduct.pdtAddOns
      .filter((pdt) => pdt.checked)
      .map((dataObj) => ({
        riskServicesId: dataObj.id,
      }));
    let data;
    let cyberExcessPresent = false;
    let cyberExcessQuoteNumber = null;
    try {
      // for standalone product
      // If entry on quote option table already exists
      if (currentProduct.quoteOptionId) {
        // Already created
        if (isLimitAndDeductUpdated) {
          const quoteOptionUpdate = {
            policyRiskTrxId: Number(currentProduct?.policyRiskTrxId ?? 0),
            limit: valInDigits(currentProduct.limit),
            deductible: valInDigits(currentProduct.deductables),
            brokerCommissionPerc: getDecimalPlace(
              Number(currentProduct?.brokerCommissionPerc) / 100,
              4,
            ),
            quoteOptionServices: quoteOptionServices,
            premiumCalculated: Number(currentProduct.editedPremium ?? 0),
          };
          const createOption = this.policyQuoteOptionService.Update(
            currentProduct.quoteOptionId,
            quoteOptionUpdate,
          );
          data = await firstValueFrom(createOption);
          if (currentProduct?.pdtAddOns?.length > 0) {
            const selectedAddOns = currentProduct?.pdtAddOns
              ?.filter((addons) => addons.checked)
              .map((addonsObj) => addonsObj);
            let quoteOptions;
            this.form$.subscribe(
              (event) => (quoteOptions = event.quoteOptions),
            );
            const quoteOptionForSelected = quoteOptions.filter(
              (quoteOption) =>
                quoteOption?.products[0]?.optionNumber ==
                currentProduct?.optionNumber,
            )[0];
            const prevSelectedAddOns = quoteOptionForSelected?.products
              .filter((pdt) => pdt.key == currentProduct?.key)[0]
              ?.pdtAddOns?.filter((addons) => addons.checked)
              .map((addonsObj) => addonsObj);
            const quoteOptionServicesToRemove = prevSelectedAddOns?.filter(
              (prevObj) =>
                !selectedAddOns.some(
                  (selectedObj) => selectedObj?.id == prevObj?.id,
                ),
            );
            const quoteOptionServicesToAdd = selectedAddOns?.filter(
              (addons) =>
                !prevSelectedAddOns.some(
                  (selectedObj) => selectedObj?.id == addons?.id,
                ),
            );
            let quoteOptionServicesResponse;
            if (quoteOptionServicesToRemove.length > 0) {
              quoteOptionServicesToRemove.forEach(async (addons) => {
                const quoteOptionServices =
                  this.quoteOptionServicesService.DeleteQuoteOptionServices(
                    addons?.quoteOptionServicesId,
                  );
                quoteOptionServicesResponse =
                  await firstValueFrom(quoteOptionServices);
              });
            }
            if (quoteOptionServicesToAdd.length > 0) {
              quoteOptionServicesToAdd.forEach(async (addons) => {
                const quoteOptionServicesPayload = {
                  riskServicesId: addons?.id,
                  quoteOptionId: currentProduct?.quoteOptionId,
                };
                const quoteOptionServices =
                  this.quoteOptionServicesService.CreateQuoteOptionServices(
                    quoteOptionServicesPayload,
                  );
                quoteOptionServicesResponse =
                  await firstValueFrom(quoteOptionServices);
              });
            }
          }
        }
      } else {
        // Entry on quote option table on calculate click
        let quoteOption: any = {
          policyRiskTrxId: Number(currentProduct?.policyRiskTrxId ?? 0),
          limit: valInDigits(currentProduct.limit),
          deductible: valInDigits(currentProduct.deductables),
          brokerCommissionPerc: getDecimalPlace(
            Number(currentProduct?.brokerCommissionPerc) / 100,
            4,
          ),
          quoteOptionServices: quoteOptionServices,
        };
        currentProduct.limit = valInDigits(currentProduct.limit);
        const createOption = this.policyQuoteOptionService.Create(quoteOption);
        data = await firstValueFrom(createOption);
        const quoteOptionId = data.data.id;
        const quoteNumber = data.data.optionNumber;
        this.currentEditQuoteOptnNumber = quoteNumber;
        currentProduct.quoteOptionId = quoteOptionId;
        currentProduct.optionNumber = quoteNumber;
      }

      try {
        // Premium Calculation
        const riskRating = this.riskRatingCalculateService.GetByQuoteOption(
          currentProduct.quoteOptionId,
        );
        const ratingData = await firstValueFrom(riskRating);
        currentProduct.premiumCalculated = Number(ratingData.data[0].premium);
      } catch (error) {
        if (![500].includes(error?.status)) {
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: getErrorMessage(error),
          };
          this.alertService.addAlert(alertData);
          this.showErrorAlert = true;
        } else {
          this.isReferralAlertShown = true;
        }

        currentProduct.premiumCalculated = 0;
        if (error.code === REFERRAL_ERROR_CODE) {
          currentProduct.quoteStatus = this.domainIdForReferral;
        }
      }
      currentProduct.premium = currentProduct.premiumCalculated;
      currentProduct.editedPremiumDeviation = '0% deviation';

      this.data.total.premiumCalculated = Math.round(
        this.data.products.reduce((acc, currentPdtObj) => {
          return acc + Number(currentPdtObj.premiumCalculated);
        }, 0),
      );

      this.data.total.premium = Math.round(
        this.data.products.reduce((acc, currentPdtObj) => {
          return acc + Number(currentPdtObj.premium);
        }, 0),
      );
      currentProduct.showPremiumCalcBtn = false;

      // for standaloneSellable false
      if (this.isExcessPresent()) {
        let cyberExcessData;
        cyberExcessPresent = true;
        const cyberExcessPdt = this.data.products.filter(
          (pdt) => pdt.key?.toLowerCase() == CYBER_EXCESS_PRODUCT_NAME,
        )[0];
        if (cyberExcessPdt) {
          // check if Excess already added in current quote
          const policyRisk = this.policyRiskService.GetAllByPolicyPeriodId(
            uiData.policyPeriodId,
          );
          const riskTrxData = await firstValueFrom(policyRisk);
          const excessPdtIdx = riskTrxData.data.findIndex(
            (policy) => policy.riskId === CYBER_EXCESS_RISK_ID,
          );
          if (excessPdtIdx == -1) {
            let quoteOptionExcess: any = {
              policyPeriodId: uiData.policyPeriodId,
              riskId: cyberExcessPdt.riskId,
              limit: valInDigits(cyberExcessPdt.limit),
              deductible: valInDigits(currentProduct.deductables),
              brokerCommissionPerc: getDecimalPlace(
                Number(currentProduct.brokerCommissionPerc) / 100,
                4,
              ),
            };
            if (currentProduct?.optionNumber) {
              quoteOptionExcess = {
                ...quoteOptionExcess,
                optionNumber: currentProduct?.optionNumber,
              };
            }
            const createOptionExcess =
              this.policyQuoteOptionService.CreateExcess(quoteOptionExcess);
            cyberExcessData = await firstValueFrom(createOptionExcess);
          } else {
            const cyberExcessPolicyRiskTrxId = riskTrxData.data?.filter(
              (riskTrx) => riskTrx.riskId == CYBER_EXCESS_RISK_ID,
            )[0]?.PolicyRiskTrxes[0]?.id;
            let quoteOptionExcess: any = {
              policyRiskTrxId: cyberExcessPolicyRiskTrxId,
              limit: valInDigits(cyberExcessPdt.limit),
              deductible: valInDigits(currentProduct.deductables),
              brokerCommissionPerc: getDecimalPlace(
                Number(currentProduct?.brokerCommissionPerc) / 100,
                4,
              ),
            };
            if (currentProduct?.optionNumber) {
              quoteOptionExcess = {
                ...quoteOptionExcess,
                optionNumber: currentProduct?.optionNumber,
              };
            }

            if (!this.isEditMode) {
              const createOptionExcess =
                this.policyQuoteOptionService.Create(quoteOptionExcess);
              cyberExcessData = await firstValueFrom(createOptionExcess);
            } else {
              let quoteOptions;
              this.form$.subscribe(
                (event) => (quoteOptions = event.quoteOptions),
              );
              const currentQuoteOptionIdx = quoteOptions.findIndex(
                (quoteOption) =>
                  quoteOption?.products[0]?.optionNumber ==
                  this.currentEditQuoteOptnNumber,
              );
              if (currentQuoteOptionIdx !== -1) {
                const isExcessPdtPresent =
                  quoteOptions[currentQuoteOptionIdx]?.products.filter(
                    (pdt) => pdt.key.toLowerCase() == CYBER_EXCESS_PRODUCT_NAME,
                  )?.length > 0;
                if (isExcessPdtPresent) {
                  const createOptionExcess =
                    this.policyQuoteOptionService.Update(
                      cyberExcessPdt.quoteOptionId,
                      quoteOptionExcess,
                    );
                  cyberExcessData = await firstValueFrom(createOptionExcess);
                } else {
                  const createOptionExcess =
                    this.policyQuoteOptionService.Create(quoteOptionExcess);
                  cyberExcessData = await firstValueFrom(createOptionExcess);
                }
              }
            }
          }
          const quoteOptionId = cyberExcessData?.data?.id;
          cyberExcessQuoteNumber = cyberExcessData?.data?.optionNumber;
          this.currentEditQuoteOptnNumber =
            cyberExcessQuoteNumber ?? cyberExcessPdt.optionNumber;
          cyberExcessPdt.quoteOptionId =
            quoteOptionId ?? cyberExcessPdt.quoteOptionId;
          cyberExcessPdt.optionNumber =
            cyberExcessQuoteNumber ?? cyberExcessPdt.optionNumber;

          try {
            // Premium Calculation
            const riskRating = this.riskRatingCalculateService.GetByQuoteOption(
              cyberExcessPdt.quoteOptionId,
            );
            const ratingData = await firstValueFrom(riskRating);
            (cyberExcessPdt.premiumCalculated = Number(
              ratingData.data[0].premium,
            )),
              (cyberExcessPdt.premium = cyberExcessPdt.premiumCalculated);
          } catch (error) {
            if (![500].includes(error?.status)) {
              const alertData = {
                type: 'error',
                headerText: getAlertHead('error'),
                bodyText: getErrorMessage(error),
              };
              this.alertService.addAlert(alertData);
              this.showErrorAlert = true;
            } else {
              this.isReferralAlertShown = true;
            }

            cyberExcessPdt.premiumCalculated = 0;
            if (error.code === REFERRAL_ERROR_CODE) {
              cyberExcessPdt.quoteStatus = this.domainIdForReferral;
            }
          }

          cyberExcessPdt.premium = cyberExcessPdt.premiumCalculated;
          cyberExcessPdt.editedPremiumDeviation = '0% deviation';

          cyberExcessPdt.editedPremiumDeviation = '0% deviation';
          this.data.total.premiumCalculated = Math.round(
            this.data.products.reduce((acc, currentPdtObj) => {
              return acc + Number(currentPdtObj.premiumCalculated);
            }, 0),
          );
          this.data.total.premium = Math.round(
            this.data.products.reduce((acc, currentPdtObj) => {
              return acc + Number(currentPdtObj.premium);
            }, 0),
          );

          cyberExcessPdt.showPremiumCalcBtn = false;
        }
      }
      // Fetch policy risk trx data
      const policyRisk = this.policyRiskService.GetAllByPolicyPeriodId(
        uiData.policyPeriodId,
      );
      const riskTrxData = await firstValueFrom(policyRisk);
      const quoteOptions = [];
      riskTrxData.data?.forEach((risk) => {
        const policyRiskTrxId = risk?.PolicyRiskTrxes[0].id;
        risk?.PolicyRiskTrxes[0]?.QuoteOptions.forEach((quoteOption) => {
          quoteOption.policyRiskTrxId = policyRiskTrxId;
          quoteOption.risk = risk?.risk;
          quoteOptions.push(quoteOption);
        });
      });

      if (cyberExcessPresent) {
        const cyberExcessPdt = this.data.products.filter(
          (pdt) => pdt.key?.toLowerCase() == CYBER_EXCESS_PRODUCT_NAME,
        )[0];
        const selectedCyberExcessQO = quoteOptions.filter(
          (qo) => qo.id === cyberExcessPdt.quoteOptionId,
        )[0];
        cyberExcessPdt.quoteStatus = selectedCyberExcessQO.quoteStatus;
        cyberExcessPdt.triaAmount = selectedCyberExcessQO.triaAmount
          ? Number(selectedCyberExcessQO.triaAmount)
          : 0;
        cyberExcessPdt.policyFee = selectedCyberExcessQO.policyFee
          ? Number(selectedCyberExcessQO.policyFee)
          : 0;
        cyberExcessPdt.servicesAmount = Number(
          selectedCyberExcessQO.servicesAmount
            ? selectedCyberExcessQO.servicesAmount
            : 0,
        );
        cyberExcessPdt.taxesPremiumAmount = Number(
          selectedCyberExcessQO.taxesPremiumAmount
            ? selectedCyberExcessQO.taxesPremiumAmount
            : 0,
        );
        cyberExcessPdt.taxesServicesAmount = Number(
          selectedCyberExcessQO.taxesServicesAmount ?? 0,
        );
        cyberExcessPdt.netAmount =
          Number(selectedCyberExcessQO.premium) +
          Number(
            selectedCyberExcessQO.servicesAmount
              ? selectedCyberExcessQO.servicesAmount
              : 0,
          );
        cyberExcessPdt.taxesAmount =
          Number(
            selectedCyberExcessQO.taxesServicesAmount
              ? selectedCyberExcessQO.taxesServicesAmount
              : 0,
          ) +
          Number(
            selectedCyberExcessQO.taxesPremiumAmount
              ? selectedCyberExcessQO.taxesPremiumAmount
              : 0,
          );
        cyberExcessPdt.totalAmount =
          Number(cyberExcessPdt.policyFee) +
          Number(cyberExcessPdt.triaAmount) +
          Number(cyberExcessPdt.netAmount) +
          Number(cyberExcessPdt.taxesAmount);
        this.currentEditQuoteOptnNumber = cyberExcessPdt.optionNumber;
      }

      const selectedQO = quoteOptions.filter(
        (qo) => qo.id === currentProduct.quoteOptionId,
      )[0];

      if (currentProduct?.pdtAddOns?.length > 0) {
        currentProduct.pdtAddOns = currentProduct?.pdtAddOns.map((addons) => {
          return {
            ...addons,
            quoteOptionServicesId: selectedQO?.QuoteOptionServices?.filter(
              (quoteOptnService) =>
                quoteOptnService.riskServicesId == addons.id,
            )[0]?.id,
          };
        });
      }
      currentProduct.premium = selectedQO.premium ?? 0;
      currentProduct.premiumCalculated = selectedQO.premium ?? 0;
      currentProduct.policyFee = selectedQO.policyFee
        ? Number(selectedQO.policyFee)
        : 0;
      currentProduct.quoteStatus = selectedQO.quoteStatus;
      currentProduct.triaAmount = selectedQO.triaAmount
        ? Number(selectedQO.triaAmount)
        : 0;
      currentProduct.servicesAmount = Number(
        selectedQO.servicesAmount ? selectedQO.servicesAmount : 0,
      );
      currentProduct.taxesPremiumAmount = Number(
        selectedQO.taxesPremiumAmount ? selectedQO.taxesPremiumAmount : 0,
      );
      currentProduct.taxesServicesAmount = Number(
        selectedQO.taxesServicesAmount ?? 0,
      );
      currentProduct.taxesAmount =
        Number(
          selectedQO.taxesServicesAmount ? selectedQO.taxesServicesAmount : 0,
        ) +
        Number(
          selectedQO.taxesPremiumAmount ? selectedQO.taxesPremiumAmount : 0,
        );
      currentProduct.netAmount =
        Number(selectedQO.premium) +
        Number(selectedQO.servicesAmount ? selectedQO.servicesAmount : 0);
      currentProduct.totalAmount =
        Number(currentProduct.policyFee) +
        Number(currentProduct.triaAmount) +
        Number(currentProduct.netAmount) +
        Number(currentProduct.taxesAmount);
      this.currentEditQuoteOptnNumber = currentProduct.optionNumber;
      // total premium calculation for the quote option
      let quoteOptionPolicyFee = 0;
      let quoteOptionTriaAmount = 0;
      let quoteOptionNetAmount = 0;
      let quoteOptionTaxesAmount = 0;
      let quoteOptionTotalAmount = 0;
      let quoteOptionServicesAmount = 0;
      let quoteOptionTaxesPremiumAmount = 0;
      let quoteOptionTaxesServicesAmount = 0;
      let quoteOptionPremiumAmount = 0;
      let quoteOptionPremiumCalculated = 0;
      this.data.products.forEach((pdt) => {
        quoteOptionPolicyFee += Number(pdt.policyFee);
        quoteOptionTriaAmount += Number(pdt.triaAmount);
        quoteOptionNetAmount +=
          Number(pdt.premium) + Number(pdt.servicesAmount);
        quoteOptionServicesAmount += Number(pdt.servicesAmount);
        quoteOptionTaxesPremiumAmount += Number(pdt.taxesPremiumAmount);
        quoteOptionTaxesServicesAmount += Number(pdt.taxesServicesAmount);
        quoteOptionTaxesAmount +=
          Number(pdt.taxesPremiumAmount) + Number(pdt.taxesServicesAmount);
        quoteOptionPremiumAmount += Number(pdt.premium);
        quoteOptionPremiumCalculated += Number(pdt.premiumCalculated);
      });
      quoteOptionTotalAmount =
        Number(quoteOptionPolicyFee) +
        Number(quoteOptionTriaAmount) +
        Number(quoteOptionNetAmount) +
        Number(quoteOptionTaxesAmount);
      this.data.total.triaAmount = Number(quoteOptionTriaAmount);
      this.data.total.policyFee = Number(quoteOptionPolicyFee);
      this.data.total.netAmount = Number(quoteOptionNetAmount);
      this.data.total.taxesAmount = Number(quoteOptionTaxesAmount);
      this.data.total.totalAmount = Number(quoteOptionTotalAmount);
      this.data.total.servicesAmount = Number(quoteOptionServicesAmount);
      this.data.total.taxesPremiumAmount = Number(
        quoteOptionTaxesPremiumAmount,
      );
      this.data.total.taxesServicesAmount = Number(
        quoteOptionTaxesServicesAmount,
      );
      this.data.total.premium = Number(quoteOptionPremiumAmount);
      this.data.total.premiumCalculated = Number(quoteOptionPremiumCalculated);
      this.data.total.premiumCalculated = Math.round(
        this.data.products.reduce((acc, currentPdtObj) => {
          return acc + Number(currentPdtObj.premiumCalculated);
        }, 0),
      );
      this.data.total.premium = Math.round(
        this.data.products.reduce((acc, currentPdtObj) => {
          return acc + Number(currentPdtObj.premium);
        }, 0),
      );
      if (this.isEditMode) {
        this.closeAlert();
        this.store.dispatch(
          new CreateQuoteAction.UpdateQuoteOption({
            quote: { ...this.getCopy(this.data) },
            index: this.editIndex,
          }),
        );
        this.closeSublimitModal();
        const alertData = {
          type: 'success',
          headerText: getAlertHead('success'),
          bodyText: this.translateService
            .instant('quoteCalculator.success.quoteOptionUpdate')
            ?.replace('{number}', this.data?.products[0]?.optionNumber),
        };
        this.alertService.addAlert(alertData);
        this.showSuccessAlert = true;
      } else {
        this.store.dispatch(
          new CreateQuoteAction.AddQuoteOption({
            quote: { ...this.getCopy(this.data) },
          }),
        );
        this.editIndex = noOfQuoteOptns;
        this.isEditMode = true;
        this.showSuccessAlert = true;
        const alertData = {
          type: 'success',
          headerText: getAlertHead('success'),
          bodyText: this.translateService
            .instant('quoteCalculator.success.quoteOptionCreate')
            ?.replace('{number}', this.data?.products[0]?.optionNumber),
        };
        this.alertService.addAlert(alertData);
      }
      this.showSpinner = false;
      this.calledCalculate = 0;
    } catch (error) {
      if (![500].includes(error?.status)) {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: getErrorMessage(error),
        };
        this.alertService.addAlert(alertData);
        this.showErrorAlert = true;
      }
      this.duplicateButtonClicked = false;
      this.showSpinner = false;
      this.showSublimitModal = false;
      this.calledCalculate = 0;
    }
  }

  async saveEditedPremiumHandler(noOfQuotesAdded) {
    if (this.isEditedPremiumSaving) {
      return;
    }
    this.isEditedPremiumSaving = true;
    this.closeAlert();
    if (noOfQuotesAdded <= 10) {
      let uiData: UISpecificValues;
      this.form$.subscribe((event) => (uiData = event.ui));
      const totals = {
        policyFee: 0,
        triaAmount: 0,
        netAmount: 0,
        taxesAmount: 0,
        totalAmount: 0,
        servicesAmount: 0,
        taxesPremiumAmount: 0,
        premium: 0,
        premiumCalculated: 0,
      };

      for (const currentProduct of this.data.products) {
        if (
          Number(currentProduct.premium) !== 0 ||
          (Number(currentProduct.premium) === 0 &&
            Number(currentProduct.premiumCalculated) !== 0)
        ) {
          try {
            if (
              Number(currentProduct.premium) !== 0 ||
              (Number(currentProduct.premium) === 0 &&
                Number(currentProduct.premiumCalculated) !== 0)
            ) {
              const quoteOption = {
                premium: Number(currentProduct.premium),
                premiumCalculated: Number(currentProduct.premiumCalculated),
              };
              try {
                const createOption = this.policyQuoteOptionService.Update(
                  currentProduct.quoteOptionId,
                  quoteOption,
                );
                await firstValueFrom(createOption);
                this.showSuccessAlert = true;
                const alertData = {
                  type: 'success',
                  headerText: getAlertHead('success'),
                  bodyText: this.translateService
                    .instant('quoteCalculator.success.quoteOptionUpdate')
                    ?.replace('{number}', currentProduct.optionNumber),
                };
                this.alertService.addAlert(alertData);
              } catch (error) {
                if (![500].includes(error?.status)) {
                  this.showErrorAlert = true;
                  const alertData = {
                    type: 'error',
                    headerText: getAlertHead('error'),
                    bodyText: getErrorMessage(error),
                  };
                  this.alertService.addAlert(alertData);
                }
              }
            }
            const policyRisk = this.policyRiskService.GetAllByPolicyPeriodId(
              uiData.policyPeriodId,
            );
            const riskTrxData = await firstValueFrom(policyRisk);

            let quoteOptions =
              riskTrxData.data[0]?.PolicyRiskTrxes[0]?.QuoteOptions;
            let policyRiskTrxForCurrentProduct = riskTrxData.data.filter(
              (riskTrx) => riskTrx.risk.name == currentProduct?.key,
            );
            if (policyRiskTrxForCurrentProduct?.length > 0) {
              quoteOptions =
                policyRiskTrxForCurrentProduct[0]?.PolicyRiskTrxes[0]
                  ?.QuoteOptions;
            }

            const selectedQO = quoteOptions.filter(
              (qo) => qo.id === currentProduct.quoteOptionId,
            )[0];
            (currentProduct.policyFee = selectedQO.policyFee
              ? Number(selectedQO.policyFee)
              : 0),
              (currentProduct.triaAmount = selectedQO.triaAmount
                ? Number(selectedQO.triaAmount)
                : 0),
              (currentProduct.netAmount =
                Number(currentProduct.premium) +
                Number(
                  selectedQO.servicesAmount ? selectedQO.servicesAmount : 0,
                ));
            currentProduct.taxesAmount =
              Number(
                selectedQO.taxesServicesAmount
                  ? selectedQO.taxesServicesAmount
                  : 0,
              ) +
              Number(
                selectedQO.taxesPremiumAmount
                  ? selectedQO.taxesPremiumAmount
                  : 0,
              );
            currentProduct.totalAmount =
              Number(currentProduct.policyFee) +
              Number(currentProduct.triaAmount) +
              Number(currentProduct.netAmount) +
              Number(currentProduct.taxesAmount);
            currentProduct.servicesAmount = Number(
              selectedQO.servicesAmount ? selectedQO.servicesAmount : 0,
            );
            currentProduct.taxesPremiumAmount = Number(
              selectedQO.taxesPremiumAmount ? selectedQO.taxesPremiumAmount : 0,
            );
            totals.policyFee += Number(currentProduct.policyFee);
            totals.triaAmount += Number(currentProduct.triaAmount);
            totals.netAmount += Number(currentProduct.netAmount);
            totals.taxesAmount += Number(currentProduct.taxesAmount);
            totals.totalAmount += Number(currentProduct.totalAmount);
            totals.servicesAmount += Number(currentProduct.servicesAmount);
            totals.taxesPremiumAmount += Number(
              currentProduct.taxesPremiumAmount,
            );
            totals.premium += Number(currentProduct.premium ?? 0);
            totals.premiumCalculated += Number(
              currentProduct.premiumCalculated ?? 0,
            );
          } catch (error) {}
        }
      }
      this.data.total.policyFee = Number(totals.policyFee);
      this.data.total.triaAmount = Number(totals.triaAmount);
      this.data.total.netAmount = Number(totals.netAmount);
      this.data.total.taxesAmount = Number(totals.taxesAmount);
      this.data.total.totalAmount = Number(totals.totalAmount);
      this.data.total.servicesAmount = Number(totals.servicesAmount);
      this.data.total.taxesPremiumAmount = Number(totals.taxesPremiumAmount);
      this.data.total.premium = Number(totals.premium);
      this.data.total.premiumCalculated = Number(totals.premiumCalculated);
      this.store.dispatch(
        new CreateQuoteAction.UpdateQuoteOption({
          quote: { ...this.getCopy(this.data) },
          index: this.editIndex,
        }),
      );
      this.isEditMode = false;
      this.isEditedPremiumSaving = false;
      this.editIndex = null;
      this.handleClear();
    }
  }

  handleRecalculate(event) {
    this.calledCalculate = 2;

    this.data.products.map((p) => {
      this.handlePremiumCalc(p.value);
    });
  }

  getOptionNumber() {}

  showPremiumCalcBtn(productRiskId) {
    const currentProduct = this.data.products.filter(
      (p) => p.riskId == productRiskId,
    )[0];
    if (currentProduct?.hasSubProduct) {
      const subProduct = this.data.products.filter(
        (p) => p.riskId == currentProduct.subProductRiskId,
      );
      if (subProduct.length > 0) {
        return (
          this.data &&
          (currentProduct.showPremiumCalcBtn ||
            subProduct[0].showPremiumCalcBtn)
        );
      }
    }
    return this.data && currentProduct.showPremiumCalcBtn;
  }

  isReferral(productRiskId) {
    const currentProduct = this.data.products.filter(
      (p) => p.riskId == productRiskId,
    )[0];
    if (currentProduct?.quoteStatus == this.domainIdForReferral) {
      return true;
    }
    return false;
  }

  getQuoteOptionId(productRiskId) {
    const currentProduct = this.data.products.filter(
      (p) => p.riskId == productRiskId,
    )[0];
    return currentProduct?.quoteOptionId;
  }

  getPremiumValue(pdt) {
    return this.data?.products.filter((p) => p.value == pdt)[0]
      .premiumCalculated;
  }

  isCalcBtnEnabled(pdt, data) {
    const pdtDetails = data.filter((p) => p.key?.toLowerCase() == pdt)[0];

    if (!pdtDetails || pdtDetails?.hideCalculateBtn) {
      return false;
    }

    const pdtLimit = valInDigits(pdtDetails.limit);
    let cyberExcessDataValid = true;
    const cyberExcessData = this.data.products.filter(
      (p) => p.key?.toLowerCase() == CYBER_EXCESS_PRODUCT_NAME,
    );
    if (pdtLimit > this.cyberExcessLimit && cyberExcessData?.length == 0) {
      return false;
    } else if (pdtDetails.showInvalidDeductibleErr) {
      return false;
    } else if (this.isExcessPresent()) {
      cyberExcessDataValid =
        cyberExcessData[0]?.limit != '' &&
        cyberExcessData[0]?.deductables != '' &&
        cyberExcessData[0]?.brokerCommissionPerc >= 0.5 &&
        !cyberExcessData[0]?.showInvalidLimitErr;
    }
    return (
      pdtDetails.limit != '' &&
      valInDigits(pdtDetails.limit) <= this.cyberExcessLimit &&
      !pdtDetails?.showInvalidLimitErr &&
      pdtDetails.deductables != '' &&
      pdtDetails.brokerCommissionPerc >= 0.5 &&
      pdtDetails.brokerCommissionPerc >=
        this.brokerageCommissionMin[pdtDetails.riskId] &&
      pdtDetails.brokerCommissionPerc <=
        this.brokerageCommissionMax[pdtDetails.riskId] &&
      pdt !== CYBER_EXCESS_PRODUCT_NAME &&
      cyberExcessDataValid
    );
  }

  isRecalcEnabled() {
    if (this.data && this.data.products.length > 0 && this.isEditMode) {
      return (
        this.data.products
          .map((pdt) => {
            if (
              pdt.limit &&
              pdt.limit != '' &&
              pdt.deductables &&
              pdt.deductables != '' &&
              pdt.brokerCommissionPerc > 0
            ) {
              return 'true';
            }
            return 'false';
          })
          .filter((bool) => bool == 'false').length == 0
      );
    }
    return false;
  }

  showEditedPremCustomField(pdt) {
    return (
      this.data &&
      this.data.products.filter((p) => p.value == pdt)[0]
        .showEditedPremiumCustomField
    );
  }
  handleEditedPremium(val, pdt) {
    const currentPdtObj = this.data.products.filter((p) => p.value == pdt)[0];
    if (val == '-20%') {
      currentPdtObj.editedPremiumDeviation = '20% decreased deviation';
      currentPdtObj.premium = null;
      currentPdtObj.premium = Math.round(
        (Number(currentPdtObj.premiumCalculated) * 80) / 100,
      );
    } else if (val == '+20%') {
      currentPdtObj.editedPremiumDeviation = '20% increased deviation';
      currentPdtObj.premium = null;
      currentPdtObj.premium = Math.round(
        (Number(currentPdtObj.premiumCalculated) * 120) / 100,
      );
    } else if (val == 'custom-amount') {
      currentPdtObj.editedPremiumDeviation = '';
      currentPdtObj.showEditedPremiumCustomField = true;
    }
    this.data.total.premium = Math.round(
      this.data.products.reduce((acc, currentPdtObj) => {
        return acc + Number(currentPdtObj.premium);
      }, 0),
    );
  }

  getDomainIdForReferral() {
    this.domainsService.GetByDomainCode(QUOTESTATUS, true).subscribe((data) => {
      if (data.data.length > 0) {
        this.domainIdForReferral = data.data.filter(
          (domain) => domain.subdomaincode === QUOTESTATUS_REFERRAL,
        )[0].id;
      }
    });
  }

  getEditedPremium(pdt) {
    const premium = this.data?.products.filter((p) => p.value == pdt)[0]
      .premium;
    return premium;
  }

  getDeviationText(pdt) {
    return this.data?.products.filter((p) => p.value == pdt)[0]
      ?.editedPremiumDeviation;
  }

  getEditedPremiumErr(pdt) {
    return (
      this.data?.products.filter((p) => p.value == pdt)[0]?.editedPremiumErr ||
      ''
    );
  }

  handleEditedPremiumCustVal(event, pdt) {
    const pdtObj = this.data.products.filter((p) => p.value == pdt)[0];
    pdtObj.editedPremiumErr = '';
    const maxVal = Math.round(
      (Number(
        pdtObj.premiumCalculated.toString()?.replace('$', '').replace(',', ''),
      ) *
        120) /
        100,
    );
    const minVal = Math.round(
      (Number(
        pdtObj.premiumCalculated.toString()?.replace('$', '').replace(',', ''),
      ) *
        80) /
        100,
    );
    event.target.value = event.target.value
      ? event.target.value?.replace(/[$,]/g, '')
      : 0;
    // if (event.target.value >= minVal && event.target.value <= maxVal) {
    pdtObj.premium = event.target.value;
    pdtObj.editedPremiumDeviation = this.calculatePercentageDeviation(
      pdtObj.premium,
      pdtObj.premiumCalculated,
    );
    pdtObj.editedPremiumErr = '';
    event.target.value = '';
    pdtObj.showEditedPremiumCustomField = false;
    // } else {
    //   pdtObj.editedPremiumErr = 'Amount must be between -20% to +20% range';
    // }
    this.data.total.premium = Math.round(
      this.data.products.reduce((acc, currentPdtObj) => {
        return acc + Number(currentPdtObj.premium);
      }, 0),
    );
  }

  handleReferralEditedPremium(event, pdt) {
    if (!this.isNumber(event.target.value?.replace(/[$,]/g, ''))) {
      event.target.value = '';
    }
    const pdtObj = this.data.products.filter((p) => p.value == pdt)[0];
    pdtObj.premium = event.target.value?.replace(/[$,]/g, '');
    this.data.total.premium = Math.round(
      this.data.products.reduce((acc, currentPdtObj) => {
        return acc + Number(currentPdtObj.premium);
      }, 0),
    );
  }

  calculatePercentageDeviation(premium, premiumCalculated) {
    let percentageDeviationText = '';
    const percentDeviation = getDecimalPlace(
      ((Number(premium) - Number(premiumCalculated)) /
        Number(premiumCalculated)) *
        100,
      2,
    );
    if (percentDeviation > 0) {
      this.translateService
        .get('quoteCalculator.info.increasedDeviation')
        .subscribe((translatedText: string) => {
          percentageDeviationText =
            Math.abs(percentDeviation) + ' ' + translatedText;
        });
    } else if (percentDeviation < 0) {
      this.translateService
        .get('quoteCalculator.info.decreasedDeviation')
        .subscribe((translatedText: string) => {
          percentageDeviationText =
            Math.abs(percentDeviation) + '' + translatedText;
        });
    } else if (percentDeviation == 0) {
      this.translateService
        .get('quoteCalculator.info.zeroDeviation')
        .subscribe((translatedText: string) => {
          percentageDeviationText = translatedText;
        });
    }
    return percentageDeviationText;
  }

  handleBackBtn() {
    this.getTimeZoneOfInsured();
    this.store.dispatch(new CreateQuoteAction.setIsNavigatedFromQuoteCalc());
    if (this.selectedQuoteDetails) {
      this.store.dispatch(
        new CreateQuoteAction.SelectQuoteOption(this.selectedQuoteDetails),
      );
    }
    this.router.navigate(
      ['dashboard/quote4flow/new/product/form/' + this.policyId],
      {
        skipLocationChange: true,
      },
    );
  }

  handleExitFlow(): void {
    let isMock = 'true';
    if (
      this.localStorageService.getMockExternalAPIs() === 'false' ||
      environment.mockExternalAPIs === false
    ) {
      isMock = 'false';
    }
    this.router.navigate(['dashboard/home'], {
      queryParams: {
        mock: isMock,
      },
      skipLocationChange: true,
    });
  }

  handleRiskAction(action: TransactionRiskActions) {
    let uiData: any;
    this.store
      .pipe(select(getQuoteSelector))
      .subscribe((event) => (uiData = event.ui));
    this.actionPopupDetails = {
      quoteNumber: uiData?.policyId,
      effectiveDate: uiData?.policyPeriod ? uiData?.policyPeriod : 'n/a',
      insured: uiData?.insuredName,
      brokerage: uiData?.broker,
      branch: uiData?.branch,
      reasonOptions: [],
      action: this.transactionRiskActions.None,
    };
    this.actionPopupDetails.action = action;
    this.showActionPopup = true;
  }

  handleConfirmRiskAction(event: {
    action: TransactionRiskActions;
    selectedReasonId: number;
  }) {
    this.alertService.clearAlerts(-1);
    const PolicyDeclineRequest: PolicyDeclineRequest = {
      policyPeriodId: Number(this.policyPeriodId),
      declineReason: Number(event.selectedReasonId),
    };

    this.policyLifecycleService
      .TransactionRiskDecline(PolicyDeclineRequest)
      .subscribe({
        next: (response) => {
          this.handleRiskActionApiResponse(
            TransactionRiskActions.Decline,
            response,
          );

          //Clear the "quote options" on store after decline action.
          this.store.dispatch(new CreateQuoteAction.clearQuoteOptions());
        },
        error: (e) => {
          this.handleApiError(e);
        },
        complete: () => {
          this.handleRiskActionComplete();
        },
      });
  }

  private handleRiskActionApiResponse(type, response: any) {
    this.showActionPopup = false;
    this.showSuccessAlert = true;
    const alertData = {
      type: 'success',
      headerText: getAlertHead('success'),
      bodyText: this.translate.instant('policy.declined.success.body'),
    };
    this.alertService.addAlert(alertData);
  }

  handleApiError(error: any) {
    if (![500].includes(error?.status)) {
      let errorMessage = '';
      if (typeof error === 'string' || error instanceof String) {
        if (error?.toLowerCase().includes('error code: 404', 0)) {
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: this.translate.instant(
              'quoteDetails.requestedQuoteNotFound',
            ),
          };
          this.alertService.addAlert(alertData);
        }
      } else {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: this.translate.instant(
            'quoteDetails.requestedQuoteNotFound',
          ),
        };
        this.alertService.addAlert(alertData);
      }
      this.alertMsg = errorMessage;
      this.showErrorAlert = true;
    }
  }

  private handleRiskActionComplete() {
    this.showActionPopup = false;
    this.newQuoteService.setClosedAlertStatus(true);
    this.router.navigate([`/dashboard/quote4flow/${this.policyId}`], {
      skipLocationChange: true,
    });
  }

  async handleSendComparison(quoteOptions) {
    await this.updateSelectedPremium();
    this.closeAlert();
    this.docDetails = [];
    this.rowDocDetails = {};
    this.showSendCompareModal = !this.showSendCompareModal;
    this.isLoader = !this.isLoader;
    const interval = 5000;
    const startTime = Date.now();

    // TO DO - update later to get all quote options to show in comparison doc
    const selectedQuoteId = quoteOptions.filter(
      (quote) => quote.ui.optionSelected,
    )[0]?.products[0]?.quoteOptionId;
    let payload = [
      {
        id: selectedQuoteId,
      },
    ];
    quoteOptions.forEach((quote) => {
      if (payload.length <= 3) {
        const isAlreadyAdded =
          payload.filter((id) => id.id == quote.products[0]?.quoteOptionId)
            ?.length > 0;
        if (!isAlreadyAdded) {
          payload.push({
            id: quote.products[0]?.quoteOptionId,
          });
        }
      }
    });

    if (this.showSendCompareModal) {
      await this.getStageId('DOCMERGESTAGE_QUOTECOMPARISON');
      await this.populateMessageType();
      if (this.docDetails.length === 0) {
        this.generateQuoteService.generateQuoteComparePdf(payload).subscribe({
          next: async (response) => {
            while (
              this.showSendCompareModal &&
              Date.now() - startTime < DOC_GENERATION_WAIT_TIME &&
              this.rowDocDetails?.['status'] !== 1 &&
              this.rowDocDetails?.['status'] !== 2 &&
              (this.docDetails.length === 0 ||
                this.rowDocDetails?.['status'] === 0)
            ) {
              await this.getDocument();
              await new Promise(
                (resolve) => (this.newTimeout = setTimeout(resolve, interval)),
              );
            }
            if (!this.showSendCompareModal) {
              return;
            }
            if (this.docDetails.length === 0) {
              this.isDropdownActive = true;
              this.isLoader = false;
              this.showSendCompareModal = false;
              this.showErrorAlert = true;
              const alertData = {
                type: 'error',
                headerText: getAlertHead('error'),
                bodyText: 'common.docCreateFailMsg',
              };
              this.isDropdownActive = true;
              this.sendButtonDisabled = false;
              this.alertService.addAlert(alertData);
            } else {
              await this.getMessageTemplates();
            }
          },
          error: ({ error }) => {
            clearTimeout(this.newTimeout);
            this.isDropdownActive = true;
            this.isLoader = false;
            this.showSendCompareModal = false;
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
              const alertData = {
                type: 'error',
                headerText: getAlertHead('error'),
                bodyText: getErrorMessage(error),
              };
              this.alertService.addAlert(alertData);
            }
          },
        });
      }
      this.docDetails.length !== 0 && (await this.getMessageTemplates());
    }
  }

  async handleSendQuoteComparison(quoteOptions) {
    this.showBodySpinner = true;
    this.alertService.clearAlerts(-1);
    this.docDetails = [];
    await this.getStageId('DOCMERGESTAGE_QUOTECOMPARISON');
    this.docPopupDetails.stageId = this.stageId;
    this.docPopupDetails.policyPeriodId = this.policyPeriodId;

    let zeroBrokerCommission = false;
    let zeroPremuim = false;
    quoteOptions.forEach((quote) => {
      if (Number(quote?.products[0]?.premium) === 0) {
        zeroPremuim = true;
      }
      if (Number(quote?.products[0]?.brokerCommissionPerc) === 0) {
        zeroBrokerCommission = true;
      }
    });

    if (zeroBrokerCommission || zeroPremuim) {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: this.translate.instant(
          'quoteCalculator.error.invalidSelectedQuote',
        ),
      };
      this.showBodySpinner = false;
      this.alertService.addAlert(alertData);
      return;
    }

    if (this.stageId) {
      this.DocumentTemplateService.getDocumentTemplateList(
        this.policyRiskId,
        this.stageId,
      ).subscribe({
        next: async (response) => {
          if (response?.data.length !== 0) {
            this.showBodySpinner = false;
            this.docPopupDetails.documentTemplate = response?.data;
            this.handleDocLevelAction(this.currentQuoteStatus);
          } else {
            this.showBodySpinner = false;
            await this.handleSendComparison(quoteOptions);
          }
        },
        error: (error) => {
          this.isDropdownActive = true;
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: getErrorMessage(error.error),
          };
          this.showBodySpinner = false;
          this.alertService.addAlert(alertData);
        },
      });
    } else {
      this.showBodySpinner = false;
      return;
    }
  }

  async handleSendDropdown(value, quoteOptions) {
    this.isDropdownActive = false;
    if (value === 'Send quote') {
      await this.handleSendQuote();
    } else {
      await this.handleSendQuoteComparison(quoteOptions);
    }
  }

  isZeroNullOrStringZero(value) {
    return (
      value === 0 || value === null || value === '0' || Number(value) === 0
    );
  }

  removeDuplicatesFromArray(arrayWithDuplicates) {
    return arrayWithDuplicates.filter(
      (item, index, arr) => arr.indexOf(item) === index,
    );
  }

  isSelectedQuoteOptionValid() {
    let selectedQuoteOption;
    this.store
      .pipe(select(getSelectedQuoteOption))
      .pipe(take(1))
      .subscribe((quoteOption) => (selectedQuoteOption = quoteOption));
    const invalidPremium = selectedQuoteOption['products']?.findIndex(
      (product) =>
        this.isZeroNullOrStringZero(product?.brokerCommissionPerc) ||
        this.isZeroNullOrStringZero(product?.premium),
    );
    if (invalidPremium == -1) {
      return true;
    }
    return false;
  }

  async handleContinue(e) {
    this.alertService.clearAlerts(-1);
    if (!this.isSelectedQuoteOptionValid()) {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: this.translateService.instant(
          'quoteCalculator.error.invalidSelectedQuote',
        ),
      };
      this.alertService.addAlert(alertData);
      this.showErrorAlert = true;
      return;
    }
    await this.updateSelectedPremium();
    this.store.dispatch(
      new CreateQuoteAction.SelectQuoteOption(this.selectedQuoteDetails),
    );
    this.router.navigate(['dashboard/quote4flow/policy-configuration'], {
      skipLocationChange: true,
    });
  }

  toggleProduct(product) {
    this.toggle = {
      ...this.toggle,
      [product]: !this.toggle[product],
    };
  }

  getProducts(quoteOptions) {
    quoteOptions.forEach((quote) => {
      quote['products']?.forEach((productObj) => {
        if (this.productsSelected.indexOf(productObj.key) === -1) {
          this.productsSelected.push(productObj.key);
          this.toggle = {
            ...this.toggle,
            [productObj.key]: !this.toggle[productObj.key],
          };
        }
      });
    });

    return this.productsSelected;
  }

  // getProductValuesOfQuote(index, product, quoteOptions) {
  //   return quoteOptions[index].products.filter(
  //     (pdtObj) => pdtObj.key == product,
  //   );
  // }

  getProductsToSelect(products) {
    const selectedProducts = products.filter(
      (pdtObj) => pdtObj.checked && pdtObj.active,
    );
    if (this.initialSelectedPdts.length == 0) {
      this.initialSelectedPdts = selectedProducts.map((p) => ({ ...p }));
    }
    if (this.isEditMode) {
      return this.initialSelectedPdts.filter(
        (initPdtArr) =>
          !this.data.products.some(
            (dataPdtArr) => initPdtArr.value === dataPdtArr.value,
          ),
      );
    }

    const pdtsToShow = this.initialSelectedPdts.filter((selectedPdts) => {
      if (
        products.filter((p) => p.value == selectedPdts.value)[0].checked !=
        selectedPdts.checked
      ) {
        return selectedPdts;
      }
    });
    return pdtsToShow;
  }

  isLimitDisabledOnAddSubProduct(riskId) {
    const currentProduct = this.data?.products.filter(
      (product) => product.riskId == riskId,
    )[0];
    if (currentProduct?.hasSubProduct && currentProduct?.subProductRiskId) {
      const subProducts = this.data?.products.filter(
        (product) => product.riskId == currentProduct?.subProductRiskId,
      );
      if (subProducts.length > 0) {
        return true;
      }
    }
    return false;
  }

  addExcess(pdtList, pdt) {
    const cyberCommercialIdx = this.data?.products.findIndex(
      (obj) => obj.key?.toLowerCase() === CYBER_COMMERCIAL_PRODUCT_NAME,
    );
    const cyberExcessIdx = this.data?.products.findIndex(
      (obj) => obj.key?.toLowerCase() === CYBER_EXCESS_PRODUCT_NAME,
    );
    if (cyberExcessIdx !== -1) {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: this.translateService.instant(
          'quoteCalculator.error.excessAdded',
        ),
      };
      this.alertService.addAlert(alertData);
      this.showErrorAlert = true;
      return;
    }
    if (
      cyberCommercialIdx >= 0 &&
      valInDigits(this.data?.products[cyberCommercialIdx]?.limit) <
        this.cyberExcessLimit
    ) {
      this.showInfoAlert = true;
      const alertData = {
        type: 'info',
        headerText: getAlertHead('info'),
        bodyText: this.translateService.instant(
          'quoteCalculator.info.excessInfo',
        ),
      };
      this.alertService.addAlert(alertData);
      setTimeout(() => {
        this.closeAlert();
      }, 6000);
    } else if (
      cyberCommercialIdx >= 0 &&
      valInDigits(this.data?.products[cyberCommercialIdx]?.limit) ==
        this.cyberExcessLimit
    ) {
      const index = pdtList.findIndex((obj) =>
        obj.key.toLowerCase().includes(pdt.toLowerCase()),
      );
      if (index < 0) {
        this.riskRegionService
          .getAllRiskByUser(this.userID)
          .subscribe(async (data) => {
            let tableData = await Promise.all(
              data.data
                ?.filter((p) =>
                  p.name.toLowerCase().includes(pdt.toLowerCase()),
                )
                .map(async (item: any, index, arr) => {
                  this.populateLimitOptions(item.id, item.name);
                  let hasSubProduct = false,
                    subProductName = '',
                    subProductRiskId = null;
                  let hasParentProduct = false,
                    parentRiskId = null;
                  if (item?.standaloneSellable == 1) {
                    const getSubProduct =
                      this.riskRegionService.getBySellableWithRiskId(item.id);
                    const getSubProductResponse =
                      await firstValueFrom(getSubProduct);
                    if (getSubProductResponse?.data?.length > 0) {
                      hasSubProduct = true;
                      subProductName = getSubProductResponse?.data[0]?.name;
                      subProductRiskId = getSubProductResponse?.data[0]?.id;
                    }
                  } else {
                    const getParentProduct = this.riskRegionService.getRiskById(
                      item.id,
                    );
                    const getParentProductResponse =
                      await firstValueFrom(getParentProduct);
                    if (
                      getParentProductResponse?.data?.id &&
                      getParentProductResponse?.data?.sellableWithRiskId
                    ) {
                      hasParentProduct = true;
                      parentRiskId = getParentProductResponse?.data?.id;
                    }
                  }
                  const underwriterName = item.defaultUnderwriter
                    ? item.defaultUnderwriter?.firstName +
                      ' ' +
                      item.defaultUnderwriter?.lastName
                    : null;
                  return {
                    key: item.name,
                    value: 'Product2',
                    description: item.description,
                    checked: true,
                    active: true,
                    addons: [],
                    showPremiumCalcBtn: false,
                    underwriter: underwriterName ? underwriterName : '',
                    underwriterId: underwriterName
                      ? item.defaultUnderwriterId?.id
                      : -1,
                    riskId: item.id,
                    standaloneSellable: false,
                    editedPremiumDeviation: '',
                    premiumCalculated: 0,
                    premium: 0,
                    hasSubProduct,
                    subProductName,
                    subProductRiskId,
                    hasParentProduct: true,
                    parentRiskId,
                  };
                }),
            );
            const riskIds = tableData
              .map((currentValue) => currentValue.riskId)
              .join(',');
            let addons = [];
            this.riskRegionService
              .getRiskServicesById(riskIds)
              .subscribe((data) => {
                addons = data.data?.map((item: any, index, arr) => ({
                  name: item.description,
                  id: item.id,
                  value: item.serviceName,
                  riskId: item.risk.id,
                }));
                tableData = tableData.map((item) => {
                  return {
                    ...item,
                    addons: addons.filter((a) => a.riskId === item.riskId),
                  };
                });
                pdtList = [...pdtList, tableData[0]];
                this.productsAdded = pdtList;
                this.store.dispatch(
                  CreateQuoteAction.loadProductsSuccess({ products: pdtList }),
                );
                this.switchProduct(pdtList, pdt);
                this.updateExcessData();
              });
          });
      } else {
        this.switchProduct(pdtList, pdt);
        this.updateExcessData();
      }
    } else {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: this.translateService.instant(
          'quoteCalculator.error.limitError',
        ),
      };
      this.alertService.addAlert(alertData);
      this.showErrorAlert = true;
    }
  }

  updateExcessData() {
    const cyberCommercialData = this.data?.products.filter(
      (obj) => obj.key?.toLowerCase() === CYBER_COMMERCIAL_PRODUCT_NAME,
    )[0];
    const cyberExcessIdx = this.data?.products.findIndex(
      (obj) => obj.key?.toLowerCase() === CYBER_EXCESS_PRODUCT_NAME,
    );
    if (cyberExcessIdx >= 0) {
      this.data.products[cyberExcessIdx].deductables =
        cyberCommercialData?.deductables;
      this.data.products[cyberExcessIdx].brokerCommissionPerc =
        cyberCommercialData?.brokerCommissionPerc;
    }
  }

  removeExcess() {
    let pdtList;
    this.form$.subscribe((event) => (pdtList = event.products));
    const index = pdtList.findIndex(
      (obj) => obj.key?.toLowerCase() === CYBER_EXCESS_PRODUCT_NAME,
    );
    if (index > 0) {
      pdtList = [...pdtList];
      pdtList.splice(index, 1);
      this.productsAdded = pdtList;
      this.store.dispatch(
        CreateQuoteAction.loadProductsSuccess({ products: pdtList }),
      );
    }
    const indexData = this.data.products.findIndex(
      (obj) => obj.key?.toLowerCase() === CYBER_EXCESS_PRODUCT_NAME,
    );
    if (indexData > 0) {
      this.data.products = [...this.data.products];
      this.data.products.splice(indexData, 1);
    }
  }

  switchProduct(pdtList, pdtValue, isDelete = false) {
    const index = pdtList.findIndex((obj) =>
      obj.key.toLowerCase().includes(pdtValue.toLowerCase()),
    );
    const isPresentInData =
      this.data.products.filter((p) =>
        p.key.toLowerCase().includes(pdtValue.toLowerCase()),
      ).length > 0;
    if (!isDelete && !isPresentInData) {
      this.data.products = [
        ...this.data.products,
        {
          ...pdtList[index],
          checked: true,
          limit: '',
          deductables: '',
          brokerCommissionPerc: null,
          showLimitCustomInput: false,
          showDeductibleCustomInput: false,
          showPremiumCalcBtn: true,
          premium: '',
          showEditedPremiumCustomField: false,
          editedPremium: '',
          editedPremiumDeviation: '',
          expandAddOns: false,
          showInvalidLimitErr: false,
          showInvalidLimitErrMsg: '',
          pdtAddOns: [],
          premiumCalculated: 0,
        },
      ];
    }
    if (isDelete) {
      const parentPdt = this.data.products.filter((p) => p.hasSubProduct);
      if (parentPdt.length > 0) {
        this.checkIfAddExcessErr(parentPdt[0].limit);
      }
    }
  }

  getEditedPremiumMaxVal(pdt) {
    const pdtObj = this.data?.products.filter((p) => p.value == pdt)[0];
    return Math.round(
      (Number(pdtObj.premium.toString()?.replace('$', '').replace(',', '')) *
        120) /
        100,
    );
  }

  getEditedPremiumMinVal(pdt) {
    const pdtObj = this.data?.products.filter((p) => p.value == pdt)[0];
    return Math.round(
      (Number(pdtObj.premium.toString()?.replace('$', '').replace(',', '')) *
        80) /
        100,
    );
  }

  getBrokerageCommissionPerc(productRiskId) {
    if (this.data.products.length > 0) {
      if (
        this.data.products?.filter((p) => p.riskId == productRiskId)[0]
          ?.brokerCommissionPerc
      ) {
        let brokerCommission = this.data.products?.filter(
          (p) => p.riskId == productRiskId,
        )[0].brokerCommissionPerc;
        return getDecimalPlace(Number(brokerCommission), 2);
      } else {
        this.data.products.filter(
          (p) => p.riskId == productRiskId,
        )[0].brokerCommissionPerc =
          this.brokerageCommissionDefault[productRiskId];
        return this.brokerageCommissionDefault[productRiskId];
      }
    }
  }

  handleClear() {
    this.clearingCalc = true;
    this.removeExcess();
    this.data.products = this.data.products.map((pdtObj) => ({
      ...pdtObj,
      limit: '',
      deductables: '',
      brokerCommissionPerc: getDecimalPlace(
        Number(this.brokerageCommissionDefault[pdtObj.riskId]),
        2,
      ),
      showLimitCustomInput: false,
      showDeductibleCustomInput: false,
      showPremiumCalcBtn:
        pdtObj?.key?.toLowerCase() != CYBER_EXCESS_PRODUCT_NAME,
      premium: '',
      showEditedPremiumCustomField: false,
      editedPremium: '',
      editedPremiumDeviation: '',
      expandAddOns: false,
      showInvalidLimitErr: false,
      showInvalidLimitErrMsg: '',
      pdtAddOns: [],
      quoteOptionId: '',
      premiumCalculated: 0,
      optionNumber: null,
      netAmount: 0,
      taxesAmount: 0,
      taxesPremiumAmount: 0,
      totalAmount: 0,
      hideCalculateBtn: false,
    }));
    this.cdr.detectChanges();
    this.data.total.premium = 0;
    this.data.total.premiumCalculated = 0;
    this.data.total.policyFee = 0;
    this.data.total.triaAmount = 0;
    this.data.total.netAmount = 0;
    this.data.total.taxesAmount = 0;
    this.data.total.totalAmount = 0;
    this.data.total.servicesAmount = 0;
    this.data.total.taxesPremiumAmount = 0;
    this.data.total.taxesServicesAmount = 0;
    this.isEditMode = false;
    this.data.ui.selectedForCompare = false;
    this.data.ui.optionSelected = false;
    this.currentEditQuoteOptnNumber = '';
    this.clearingCalc = false;
  }

  handleCheckbox(index, pdt) {
    this.resetPremiumOnValChange(pdt);
    const addOns = this.data?.products.filter((p) => p.value == pdt)[0]
      .pdtAddOns;
    this.data.products.filter((p) => p.value == pdt)[0].pdtAddOns = addOns.map(
      (addon, i) => {
        if (i == index) {
          return {
            ...addon,
            checked: !addon.checked,
          };
        }
        return addon;
      },
    );
  }

  toggleAddOnsView(pdt) {
    const productObj = this.data.products.filter((p) => p.value == pdt)[0];
    productObj.expandAddOns = !productObj.expandAddOns;
  }
  getExpandAddOnsVal(pdt) {
    return this.data?.products.filter((p) => p.value == pdt)[0].expandAddOns;
  }

  getAddOns(pdt, addons) {
    const addOns = this.data?.products.filter((p) => p.value == pdt)[0]
      .pdtAddOns;
    if (addOns.length <= 0) {
      this.data.products.filter((p) => p.value == pdt)[0].pdtAddOns =
        addons.map((addon, index) => {
          return {
            ...addon,
            checked: false,
            id: index,
          };
        });
    }
    return this.data.products.filter((p) => p.value == pdt)[0].pdtAddOns;
  }

  getCopy(dataObj) {
    return {
      products: dataObj.products.map((object) => ({ ...object })),
      total: { ...dataObj.total },
      ui: { ...dataObj.ui },
    };
  }

  editedPremiumErrExists() {
    const editedPremiumErrCheck = this.data?.products.findIndex(
      (p) => p.editedPremiumErr && p.editedPremiumErr !== '',
    );
    if (editedPremiumErrCheck !== -1) {
      return true;
    }
    return false;
  }

  isSaveEditedPremiumBtnEnabled(noOfQuotesAdded) {
    if (
      noOfQuotesAdded <= 10 &&
      this.data.products?.length > 0 &&
      this.isEditMode &&
      !this.editedPremiumErrExists()
    ) {
      return (
        this.data?.products.filter(
          (p) =>
            p.key?.toLowerCase() !== CYBER_EXCESS_PRODUCT_NAME &&
            p.showPremiumCalcBtn == true,
        ).length == 0
      );
    }
    return false;
  }

  handleEdit(event) {
    const quote = event.quoteData;
    const idx = event.quoteIndex;
    this.data = this.getCopy(quote);
    this.data.products = this.data.products.map((product) => ({
      ...product,
      editedPremiumDeviation: this.calculatePercentageDeviation(
        product.premium,
        product.premiumCalculated,
      ),
    }));
    if (this.productsAdded.length > 0) {
      this.data.products = this.data?.products.map((productInQuote) => {
        const product = this.productsAdded.filter(
          (product) => product.riskId == productInQuote.riskId,
        );
        if (product.length > 0) {
          return {
            ...productInQuote,
            ...product[0],
          };
        }
        return productInQuote;
      });
    }
    this.currentEditQuoteOptnNumber = this.data?.products[0]?.optionNumber;
    this.editIndex = idx;
    this.isEditMode = true;
  }

  handleQuoteDelete(event) {
    const index = event.index;
    const quote = event.quote;
    this.closeAlert();
    if (quote?.ui.optionSelected) {
      this.showErrorAlert = true;
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: this.translateService.instant(
          'quoteCalculator.error.cannotDelete',
        ),
      };
      this.alertService.addAlert(alertData);
    } else {
      quote?.products.forEach((option) => {
        const quoteOptionId = option.quoteOptionId;

        this.policyQuoteOptionService
          .DeleteQuoteOption(quoteOptionId)
          .subscribe({
            next: (_) => {
              this.store.dispatch(
                new CreateQuoteAction.DeleteQuoteOption({ index }),
              );
              this.showSuccessAlert = true;
              const alertData = {
                type: 'success',
                headerText: getAlertHead('success'),
                bodyText: this.translateService
                  .instant('quoteCalculator.success.quoteOptionDelete')
                  ?.replace('{number}', option?.optionNumber),
              };

              this.alertService.addAlert(alertData);
              this.handleClear();
            },
            error: (error) => {
              if (![500].includes(error?.status)) {
                const alertData = {
                  type: 'error',
                  headerText: getAlertHead('error'),
                  bodyText: getErrorMessage(error),
                };
                this.alertService.addAlert(alertData);
                this.showErrorAlert = true;
              }
            },
          });
      });
    }
  }

  isMaxQuotesSelected(quotes) {
    return quotes.filter((quote) => quote.ui.selectedForCompare).length >= 4;
  }

  handleCompareSelected(index, quote, quotes) {
    this.closeAlert();
    if (quotes.filter((quote) => quote.ui.selectedForCompare)?.length == 0) {
      this.showInfoAlert = true;
      const alertData = {
        type: 'info',
        headerText: getAlertHead('info'),
        bodyText: this.translateService.instant(
          'quoteCalculator.info.compareOptions',
        ),
      };
      this.alertService.addAlert(alertData);
    }
    const quoteCpy = this.getCopy(quote);
    const maxQuotesSelected = this.isMaxQuotesSelected(quotes);
    let updatedQuote = {
      ...quoteCpy,
      ui: {
        ...quoteCpy.ui,
        selectedForCompare: maxQuotesSelected
          ? false
          : !quoteCpy.ui.selectedForCompare,
      },
    };
    this.store.dispatch(
      new CreateQuoteAction.UpdateQuoteOption({ quote: updatedQuote, index }),
    );
  }

  handleToggle(index, quote) {
    const quoteCpy = this.getCopy(quote);
    let updatedQuote = {
      ...quoteCpy,
      ui: {
        ...quoteCpy.ui,

        isQuoteBoxExpanded: !quoteCpy.ui.isQuoteBoxExpanded,
      },
    };
    this.store.dispatch(
      new CreateQuoteAction.UpdateQuoteOption({ quote: updatedQuote, index }),
    );
  }

  handleToggleAll() {
    if (this.isCollapseAllEnabled) {
      this.store.dispatch(new CreateQuoteAction.ResetIsQuoteBoxExpanded());
    } else {
      this.store.dispatch(new CreateQuoteAction.SetIsQuoteBoxExpanded());
    }
    this.isCollapseAllEnabled = !this.isCollapseAllEnabled;
  }

  async handleDuplicateClick(event) {
    const index = event.index;
    const optionNumber = event.optionNumber;
    this.closeAlert();
    this.handleClear();
    let quoteOptions;
    this.duplicateButtonClicked = true;

    this.form$.subscribe((event) => (quoteOptions = event.quoteOptions));
    if (quoteOptions.length >= 10) {
      this.showErrorAlert = true;
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: this.translateService.instant(
          'quoteCalculator.error.maxQuoteOption',
        ),
      };
      this.alertService.addAlert(alertData);
    } else {
      try {
        this.data.products = quoteOptions[index]?.products?.map((pdtObj) => ({
          ...pdtObj,
          limit: pdtObj.limit,
          deductables: pdtObj.deductables,
          brokerCommissionPerc: pdtObj.brokerCommissionPerc,
          showLimitCustomInput: false,
          showDeductibleCustomInput: false,
          showPremiumCalcBtn:
            pdtObj?.key?.toLowerCase() != CYBER_EXCESS_PRODUCT_NAME,
          showEditedPremiumCustomField: false,
          expandAddOns: false,
          showInvalidLimitErr: false,
          showInvalidLimitErrMsg: '',
          pdtAddOns: [],
          quoteOptionId: '',
          premiumCalculated: 0,
          optionNumber: 0,
          netAmount: 0,
          taxesAmount: 0,
          taxesPremiumAmount: 0,
          totalAmount: 0,
        }));
        this.data.total.premiumCalculated = 0;
        this.data.total.policyFee = 0;
        this.data.total.triaAmount = 0;
        this.data.total.netAmount = 0;
        this.data.total.taxesAmount = 0;
        this.data.total.totalAmount = 0;
        this.data.total.servicesAmount = 0;
        this.data.total.taxesPremiumAmount = 0;
        this.data.total.taxesServicesAmount = 0;
        this.isEditMode = false;
        this.data.ui.selectedForCompare = false;
        this.data.ui.optionSelected = false;
        this.currentEditQuoteOptnNumber = '';

        const duplicateQuote =
          this.policyQuoteOptionService.duplicateQuoteOption({
            policyPeriodId: this.policyPeriodId,
            optionNumber: optionNumber,
          });
        const duplicateQuoteResponse = await firstValueFrom(duplicateQuote);
        const newQuoteOptionNumber =
          duplicateQuoteResponse?.data[0]?.optionNumber;
        // Fetch policy risk trx data
        const policyRisk = this.policyRiskService.GetAllByPolicyPeriodId(
          this.policyPeriodId,
        );
        const riskTrxData = await firstValueFrom(policyRisk);
        let updatedQuoteOptions = [];
        riskTrxData.data?.forEach((risk) => {
          const policyRiskTrxId = risk?.PolicyRiskTrxes[0].id;
          risk?.PolicyRiskTrxes[0]?.QuoteOptions.filter(
            (quoteOption) => quoteOption.optionNumber == newQuoteOptionNumber,
          ).forEach((quoteOption) => {
            quoteOption.policyRiskTrxId = policyRiskTrxId;
            quoteOption.risk = risk?.risk;
            updatedQuoteOptions.push(quoteOption);
          });
        });

        this.data.products = this.data.products.map((product) => {
          const productDataFromApi = updatedQuoteOptions.filter(
            (pdt) => pdt.policyRiskTrxId == product.policyRiskTrxId,
          )[0];

          return {
            ...product,
            pdtAddOns: [],
            quoteOptionId: productDataFromApi?.id,
            premium: productDataFromApi?.premium,
            premiumCalculated: productDataFromApi?.premiumCalculated,
            optionNumber: productDataFromApi?.optionNumber,
            quoteStatus: productDataFromApi?.quoteStatus,
            policyFee: productDataFromApi.policyFee
              ? Number(productDataFromApi.policyFee)
              : 0,
            triaAmount: productDataFromApi.triaAmount
              ? Number(productDataFromApi.triaAmount)
              : 0,
            netAmount:
              Number(productDataFromApi.premium) +
              Number(
                productDataFromApi.servicesAmount
                  ? productDataFromApi.servicesAmount
                  : 0,
              ),
            servicesAmount: Number(
              productDataFromApi.servicesAmount
                ? productDataFromApi.servicesAmount
                : 0,
            ),
            taxesServicesAmount: Number(
              productDataFromApi.taxesServicesAmount ?? 0,
            ),
            taxesAmount:
              Number(
                productDataFromApi.taxesServicesAmount
                  ? productDataFromApi.taxesServicesAmount
                  : 0,
              ) +
              Number(
                productDataFromApi.taxesPremiumAmount
                  ? productDataFromApi.taxesPremiumAmount
                  : 0,
              ),
            taxesPremiumAmount: 0,
            totalAmount:
              Number(productDataFromApi.policyFee) +
              Number(productDataFromApi.triaAmount) +
              Number(productDataFromApi.netAmount) +
              Number(productDataFromApi.taxesAmount),
          };
        });

        // total premium calculation for the quote option
        let quoteOptionPolicyFee = 0;
        let quoteOptionTriaAmount = 0;
        let quoteOptionNetAmount = 0;
        let quoteOptionTaxesAmount = 0;
        let quoteOptionTotalAmount = 0;
        let quoteOptionServicesAmount = 0;
        let quoteOptionTaxesPremiumAmount = 0;
        let quoteOptionTaxesServicesAmount = 0;
        let quoteOptionPremiumAmount = 0;
        let quoteOptionPremiumCalculated = 0;
        this.data.products.forEach((pdt) => {
          quoteOptionPolicyFee += Number(pdt.policyFee);
          quoteOptionTriaAmount += Number(pdt.triaAmount);
          quoteOptionNetAmount +=
            Number(pdt.premium) + Number(pdt.servicesAmount);
          quoteOptionServicesAmount += Number(pdt.servicesAmount);
          quoteOptionTaxesPremiumAmount += Number(pdt.taxesPremiumAmount);
          quoteOptionTaxesServicesAmount += Number(pdt.taxesServicesAmount);
          quoteOptionTaxesAmount +=
            Number(pdt.taxesPremiumAmount) + Number(pdt.taxesServicesAmount);
          quoteOptionPremiumAmount += Number(pdt.premium);
          quoteOptionPremiumCalculated += Number(pdt.premiumCalculated);
        });
        quoteOptionTotalAmount =
          Number(quoteOptionPolicyFee) +
          Number(quoteOptionTriaAmount) +
          Number(quoteOptionNetAmount) +
          Number(quoteOptionTaxesAmount);
        this.data.total.triaAmount = Number(quoteOptionTriaAmount);
        this.data.total.policyFee = Number(quoteOptionPolicyFee);
        this.data.total.netAmount = Number(quoteOptionNetAmount);
        this.data.total.taxesAmount = Number(quoteOptionTaxesAmount);
        this.data.total.totalAmount = Number(quoteOptionTotalAmount);
        this.data.total.servicesAmount = Number(quoteOptionServicesAmount);
        this.data.total.taxesPremiumAmount = Number(
          quoteOptionTaxesPremiumAmount,
        );
        this.data.total.taxesServicesAmount = Number(
          quoteOptionTaxesServicesAmount,
        );
        this.data.total.premium = Number(quoteOptionPremiumAmount);
        this.data.total.premiumCalculated = Number(
          quoteOptionPremiumCalculated,
        );
        this.data.total.premiumCalculated = Math.round(
          this.data.products.reduce((acc, currentPdtObj) => {
            return acc + Number(currentPdtObj.premiumCalculated);
          }, 0),
        );
        this.data.total.premium = Math.round(
          this.data.products.reduce((acc, currentPdtObj) => {
            return acc + Number(currentPdtObj.premium);
          }, 0),
        );

        this.store.dispatch(
          new CreateQuoteAction.AddQuoteOption({
            quote: { ...this.getCopy(this.data) },
            duplicateIdx: index,
          }),
        );

        this.handleClear();
        this.showSuccessAlert = true;
        const alertData = {
          type: 'success',
          headerText: getAlertHead('success'),
          bodyText: this.translateService
            .instant('quoteCalculator.success.quoteOptionCreateDuplicate')
            ?.replace('{newNumber}', newQuoteOptionNumber)
            .replace('{number}', optionNumber ?? ''),
        };
        this.alertService.addAlert(alertData);
        this.duplicateButtonClicked = false;
      } catch (error) {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: getErrorMessage(error),
          };
          this.alertService.addAlert(alertData);
        }
      }
    }
  }

  async handleQuoteSelected(event) {
    this.handleClear();
    const optionNumber = event.optionNumber;
    const quote = event.quote;
    this.alertService.clearAlerts(-1);
    this.isQuoteSelected = true;
    let quoteOptions;
    this.form$.subscribe((event) => (quoteOptions = event.quoteOptions));
    const selectedQuoteIdx = quoteOptions.findIndex(
      (quoteOptn) => quoteOptn?.products[0]?.optionNumber == optionNumber,
    );
    if (selectedQuoteIdx !== -1) {
      this.selectedQuoteDetails = { index: selectedQuoteIdx, quote };
      const previousSelectedQuoteOptionIdx = quoteOptions.findIndex(
        (quoteOptn) => quoteOptn?.ui?.optionSelected,
      );
      if (previousSelectedQuoteOptionIdx !== -1) {
        const previousQuoteOption =
          quoteOptions[previousSelectedQuoteOptionIdx];
        let updatedQuote = {
          ...previousQuoteOption,
          ui: { ...previousQuoteOption.ui, optionSelected: false },
        };
        this.store.dispatch(
          new CreateQuoteAction.UpdateQuoteOption({
            quote: updatedQuote,
            index: previousSelectedQuoteOptionIdx,
          }),
        );
      }
      const selectedQuoteOption = quoteOptions[selectedQuoteIdx];
      if (selectedQuoteOption) {
        let updatedQuote = {
          ...selectedQuoteOption,
          ui: { ...selectedQuoteOption.ui, optionSelected: true },
        };
        this.store.dispatch(
          new CreateQuoteAction.UpdateQuoteOption({
            quote: updatedQuote,
            index: selectedQuoteIdx,
          }),
        );
      }

      let quoteOption = {
        optionSelected: 1,
      };

      for (const currentProduct of quote.products) {
        try {
          this.showBodySpinner = true;
          // Update quote option in the backend as times as products has the quote
          const quoteUpdate = this.policyQuoteOptionService.Update(
            currentProduct.quoteOptionId,
            quoteOption,
          );
          this.selectedQuoteOptionNumber = currentProduct.optionNumber;
          await firstValueFrom(quoteUpdate);
          this.alertService.addAlert({
            type: 'success',
            headerText: getAlertHead('success'),
            bodyText: this.translateService
              .instant('quoteCalculator.success.quoteOptionSelect')
              ?.replace('{number}', currentProduct.optionNumber),
          });
          this.showBodySpinner = false;
          // Update in the front the Selected quote option object
          this.store.dispatch(
            new CreateQuoteAction.SelectQuoteOption(this.selectedQuoteDetails),
          );
        } catch (error) {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
            };
            this.showBodySpinner = false;
            this.alertService.addAlert(alertData);
          }
        }
      }
    }
  }

  handleCompareOptions(index) {
    this.closeAlert();
    this.router.navigate(['dashboard/quote/new/options/compare'], {
      skipLocationChange: true,
    });
  }

  activateCompareOptionsBtn(quotes) {
    return quotes.filter((quote) => quote.ui.selectedForCompare).length > 1;
  }

  async getStageId(stage = 'DOCMERGESTAGE_QUOTE') {
    this.stageId = undefined;
    return new Promise<void>((resolve) => {
      this.domainsService.GetByDomainCode('DOCMERGESTAGE', true).subscribe({
        next: (response) => {
          let docStage = response.data.filter(
            (template) => template.subdomaincode === stage,
          )[0];
          this.stageId = docStage.id;
          resolve();
        },
        error: (error) => {
          this.isLoader = false;
          this.showSendModal = false;
          this.showSendCompareModal = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
          }
        },
      });
    });
  }

  async populateMessageType() {
    this.messageTypeId = undefined;
    return new Promise<void>((resolve) => {
      this.domainsService.GetByDomainCode('MESSAGETYPE', true).subscribe({
        next: (response) => {
          let messageType = response.data.filter(
            (template) => template.subdomaincode === 'MESSAGETYPE_QUOTE',
          )[0];
          this.messageTypeId = messageType.id;
          resolve();
        },
        error: (error) => {
          this.isLoader = false;
          this.showSendModal = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
          }
        },
      });
    });
  }

  async getDocument() {
    if (!this.policyPeriodId || !this.stageId) {
      return;
    }
    this.policyRiskDocService
      .getPolicyRiskGeneratedDocCore(this.policyPeriodId, this.stageId)
      .subscribe({
        next: (response) => {
          let { data } = response;
          if (Object.entries(data).length === 0) return;

          if (!data.PolicyRiskDocument || data.PolicyRiskDocument.length === 0)
            return;
          if (response?.data?.status === GENERATE_DOC_SUCCESS_STATUS_CODE) {
            this.docDetails = data.PolicyRiskDocument;
          } else {
            this.docDetails = [];
          }
          this.rowDocDetails = data;
        },
        error: (error) => {
          clearTimeout(this.newTimeout);
          this.isLoader = false;
          this.showSendModal = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
          }
        },
      });
  }

  async updateSelectedPremium() {
    let quoteOptions = [];
    this.form$.pipe(take(1)).subscribe({
      next: (response) => {
        quoteOptions = response.quoteOptions;
        const selectedQuote = quoteOptions?.filter(
          (quoteOption) => quoteOption?.ui.optionSelected,
        );
        if (selectedQuote?.length > 0) {
          const premium = selectedQuote[0]?.total?.premium || 0;
          const annualPremium = selectedQuote[0]?.total?.totalAmount || 0;
          const deductible = selectedQuote[0]?.products[0]?.deductables || 0;
          if (premium && premium > 0) {
            this.store.dispatch(
              new CreateQuoteAction.updateUiContents({
                premium,
                annualPremium,
                deductible,
              }),
            );
          }
        }
      },
      error: (error) => {},
    });
  }

  async getMessageTemplates() {
    this.messageTemplateService
      .GetAllMessageTemplates(this.messageTypeId)
      .subscribe({
        next: (response) => {
          this.templateDetails = response?.data ?? [];
          this.isLoader = false;
        },
        error: ({ error }) => {
          this.isLoader = false;
          this.showSendModal = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
          }
        },
      });
  }

  async handleSendPackage() {
    this.sendButtonDisabled = false;
    await this.updateSelectedPremium();
    this.closeAlert();
    this.docDetails = [];
    this.rowDocDetails = {};
    this.showSendModal = !this.showSendModal;
    this.isLoader = !this.isLoader;
    const interval = 5000;
    const startTime = Date.now();
    if (this.showSendModal) {
      await this.getStageId();
      await this.populateMessageType();
      if (this.docDetails.length === 0) {
        this.generateQuoteService
          .generateQuotePdf(this.policyPeriodId)
          .subscribe({
            next: async (response) => {
              while (
                this.showSendModal &&
                Date.now() - startTime < DOC_GENERATION_WAIT_TIME &&
                this.rowDocDetails?.['status'] !== 1 &&
                this.rowDocDetails?.['status'] !== 2 &&
                (this.docDetails.length === 0 ||
                  this.rowDocDetails?.['status'] === 0)
              ) {
                await this.getDocument();
                await new Promise(
                  (resolve) =>
                    (this.newTimeout = setTimeout(resolve, interval)),
                );
              }
              if (!this.showSendModal) {
                this.isDropdownActive = true;
                return;
              }
              if (this.docDetails.length === 0) {
                this.isDropdownActive = true;
                this.isLoader = false;
                this.showSendModal = false;
                this.showErrorAlert = true;
                const alertData = {
                  type: 'error',
                  headerText: getAlertHead('error'),
                  bodyText: 'common.docCreateFailMsg',
                };
                this.isDropdownActive = true;
                this.sendButtonDisabled = false;
                this.alertService.addAlert(alertData);
              } else {
                await this.getMessageTemplates();
              }
            },
            error: ({ error }) => {
              clearTimeout(this.newTimeout);
              this.isDropdownActive = true;
              this.isLoader = false;
              this.showSendModal = false;
              if (![500].includes(error?.status)) {
                this.showErrorAlert = true;
                const alertData = {
                  type: 'error',
                  headerText: getAlertHead('error'),
                  bodyText: getErrorMessage(error),
                };
                this.alertService.addAlert(alertData);
              }
            },
          });
      }
      this.docDetails.length !== 0 && (await this.getMessageTemplates());
    }
  }

  closeSendPackage() {
    clearTimeout(this.newTimeout);
    this.closeAlert();
    this.sendButtonDisabled = false;
    this.isDropdownActive = true;
    this.docDetails = [];
    this.templateDetails = [];
    this.showSendModal = false;
    this.showSendCompareModal = false;
    this.isLoader = false;
    this.showDocTempSelectionPopup = false;
  }

  isContinueDisabled(quoteOptions) {
    const isQuoteSelected = quoteOptions.filter(
      (quote) => quote.ui.optionSelected,
    );
    return isQuoteSelected.length <= 0;
  }

  handleSendCompare(formData: any) {
    formData.append('merginStageId', this.stageId);
    this.messageSendService.sendMessage(formData).subscribe({
      next: (response) => {
        this.docDetails = [];
        this.templateDetails = [];
        this.showSendCompareModal = !this.showSendCompareModal;
        const alertData = {
          type: 'success',
          headerText: getAlertHead('success'),
          bodyText: this.translateService.instant(
            'quoteSummary.success.quoteCompareSent',
          ),
        };
        this.alertService.addAlert(alertData);
      },
      error: (error) => {
        this.showSendCompareModal = !this.showSendCompareModal;
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: getErrorMessage(error),
          };
          this.alertService.addAlert(alertData);
        }
      },
    });
  }

  handleSend(formData: any) {
    this.isDropdownActive = true;
    this.sendButtonDisabled = false;
    formData.append('merginStageId', this.stageId);
    this.messageSendService.sendMessage(formData).subscribe({
      next: (response) => {
        this.docDetails = [];
        this.templateDetails = [];
        this.showSendModal = !this.showSendModal;
        const alertData = {
          type: 'success',
          headerText: getAlertHead('success'),
          bodyText: this.translateService.instant(
            'quoteSummary.success.quoteSent',
          ),
        };
        this.alertService.addAlert(alertData);
      },
      error: (error) => {
        this.showSendModal = !this.showSendModal;
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: getErrorMessage(error),
          };
          this.alertService.addAlert(alertData);
        }
      },
    });
  }

  handleSublimitAction(
    event,
    productName,
    policyPeriodId,
    quote,
    quoteIndex,
    quoteData = null,
  ) {
    this.alertService.clearAlerts(-1);
    this.sublimitIconSrc =
      'assets/img/dashboard/quote/lifecycle/arrow-right-circle-clicked.png';
    let riskCoverages, quoteOptions, quoteOptionId;
    let uiData: UISpecificValues;
    this.title = productName;
    quoteOptionId = event.quoteOptionId ?? quote.quoteOptionId;
    this.showSublimitModal = !this.showSublimitModal;
    if (quoteData) {
      this.handleEdit({ quoteData: quoteData, quoteIndex: quoteIndex });
    }

    this.quoteOptionIdForSublimitSlideout = quoteOptionId;

    this.form$.subscribe((event) => (uiData = event.ui));
    this.form$.subscribe((event) => (quoteOptions = event.quoteOptions));

    this.riskCoverageSubscription = this.store
      .select(getRiskCoverages)
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        riskCoverages = event[this.title];
      });

    //Deductibles
    let riskId = event.product?.risk?.id ?? quote.riskId;
    this.confDeductibleService
      .GetByRegionIdAndRiskIds(this.regionId, riskId)
      .subscribe((data) => {
        const tableData = data.data?.map((dataObj) => ({
          key: formatAmountWithCurrency(dataObj.value, this.currency),
          value: valInDigits(dataObj.value),
          id: dataObj.id,
        }));

        tableData.push({ key: 'Custom amount', value: 'custom-amount' });
        this.coverageDeductibles = removeDuplicatedKeys(tableData);
      });

    this.coverageDetails = riskCoverages;
    this.productDetails = event.product ?? quote;
    this.insuredDetails = uiData.insuredName + ' - ' + policyPeriodId;
  }

  closeSublimitModal() {
    clearTimeout(this.newTimeout);
    this.closeAlert();
    this.showSublimitModal = false;
  }

  handleSubmitCoverageDetails(event) {}

  async getTimeZoneOfInsured() {
    let uiData;
    this.form$.pipe(take(1)).subscribe((event) => {
      uiData = event.ui;
      this.insuredTimezone = event.ui?.timezone;
    });

    if (!this.insuredTimezone && this.insuredId) {
      await this.insuredService.GetByInsuredId(uiData?.insuredId).subscribe({
        next: async (data) => {
          this.insuredTimezone = data.data.insuredLocation.timezone;
          this.store.dispatch(
            new CreateQuoteAction.UpdateInsuredAction({
              timezone: data.data?.insuredLocation.timezone,
            }),
          );
        },
      });
    } else {
      this.insuredTimezone = 'America/New_York';
    }
  }

  async handleSendQuote() {
    this.showBodySpinner = true;
    this.isDropdownActive = true;
    this.sendButtonDisabled = true;
    this.alertService.clearAlerts(-1);
    this.docDetails = [];
    await this.getStageId();
    this.docPopupDetails.stageId = this.stageId;
    this.docPopupDetails.policyPeriodId = this.policyPeriodId;

    let selectedQuoteValid = true;
    this.form$.pipe(take(1)).subscribe((data) => {
      const selectedQuoteProductsData = data?.quoteOptions.filter(
        (quote) => quote.ui.optionSelected,
      )[0]?.products;
      selectedQuoteProductsData.forEach((product) => {
        const policyPremium = Number(product?.premium);
        const brokerageCommissionPerc = Number(product?.brokerCommissionPerc);
        if (policyPremium === 0 || brokerageCommissionPerc === 0) {
          selectedQuoteValid = false;
          return;
        }
      });
    });

    if (!selectedQuoteValid) {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: this.translate.instant(
          'quoteCalculator.error.invalidSelectedQuote',
        ),
      };
      this.showBodySpinner = false;
      this.alertService.addAlert(alertData);
      this.sendButtonDisabled = false;
      return;
    }

    if (this.stageId) {
      this.DocumentTemplateService.getDocumentTemplateList(
        this.policyRiskId,
        this.stageId,
      ).subscribe({
        next: async (response) => {
          if (response?.data.length !== 0) {
            this.showBodySpinner = false;
            this.docPopupDetails.documentTemplate = response?.data;
            this.handleDocLevelAction(this.currentQuoteStatus);
          } else {
            this.showBodySpinner = false;
            await this.handleSendPackage();
          }
        },
        error: (error) => {
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: getErrorMessage(error.error),
          };
          this.showBodySpinner = false;
          this.alertService.addAlert(alertData);
        },
      });
    } else {
      this.showBodySpinner = false;
      this.sendButtonDisabled = false;
      return;
    }
  }
  handleDocLevelAction(action) {
    this.docPopupDetails.action = action;
    this.showDocTempSelectionPopup = true;
  }
  handleSuccessDocTemplate(event) {
    this.showDocTempSelectionPopup = false;
    this.handleSendPackage();
  }
  openReasonModalPopup(quoteOption) {
    this.selectedQuoteOptionId = quoteOption;
    this.isReasonModalPopupOpen = true;
  }
  handleReasonModalClosePopup() {
    this.selectedQuoteOptionId = null;
    this.isReasonModalPopupOpen = false;
  }
}
