import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from './base-services';
import { Observable, retry, catchError } from 'rxjs';
import { BoxxResponse } from '../entities/boxx-response';

@Injectable({
  providedIn: 'root',
})
export class ConfTaxService extends BaseService<any> {
  constructor(http: HttpClient) {
    const baseurl = environment.msConfigurationBaseUrl + environment.taxPathUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getTaxByRegionId(
    regionId,
    limit = 10,
    page = 1,
  ): Observable<BoxxResponse<any>> {
    return this.http
      .get<BoxxResponse<any>>(
        this.baseurl +
          '?regionId=' +
          regionId +
          '&page=' +
          page +
          '&limit=' +
          limit,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
