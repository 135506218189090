<div class="holder">
  <div class="card">
    <div class="info-panel">
      <div class="header">
        <boxxds-heading
          [sz]="'6'"
          [text]="'Create new password'"
          type="bold"
        ></boxxds-heading>
        <boxxds-body
          [text]="
            'Your new password must be different from your
                previous used passwords.'
          "
          [ngStyle]="{ textAlign: 'left' }"
        ></boxxds-body>
      </div>

      <form [formGroup]="form" (ngSubmit)="handleSubmit($event)">
        <boxxds-textinput
          [form]="form"
          [type]="'password'"
          [placeholder]="'Enter new password'"
          [label]="'Password'"
          [control]="'password'"
          [toggleVisibility]="true"
          [id]="'password'"
          (handleBlur)="handleBlur($event)"
          [containerStyle]="{ marginBottom: '8px', marginTop: '16px' }"
        >
        </boxxds-textinput>
        <boxxds-textinput
          [form]="form"
          [type]="'password'"
          [placeholder]="'Confirm new password'"
          [label]="'Confirm password'"
          [control]="'passwordConfirm'"
          [toggleVisibility]="true"
          [id]="'passwordConfirm'"
          [containerStyle]="{ marginBottom: '8px', marginTop: '16px' }"
        >
        </boxxds-textinput>
        <div class="button-container">
          <boxxds-button
            [buttonText]="'Reset password'"
            [type]="'submit'"
            [isDisabled]="isBtnDisabled()"
          >
          </boxxds-button>
        </div>
      </form>
    </div>
    <div class="logo-panel">
      <div class="logo">
        <img src="assets/img/boxx.png" alt="boxx logo" />
      </div>
    </div>
  </div>
</div>
