import { BoxxResponse } from './../../entities/boxx-response';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, firstValueFrom, lastValueFrom, take } from 'rxjs';
import {
  formatDate,
  getFormattedDateTime,
  getFormatDate,
  getFormattedPolicyPeriod,
} from 'src/app/utils/formatDate';
import { DomainsService } from 'src/app/services/domains.service';
import { DropdownListDto } from '../../dtos/dropdownList.dto';
import { PolicyService } from 'src/app/services/policy.service';
import { PolicyRiskService } from 'src/app/services/policy-risk.service';
import { PolicyPeriodService } from 'src/app/services/policy-period.service';
import { KeyValueDto } from 'src/app/dtos/keyvalueList.dto';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import { BoxxUser } from 'src/app/entities/boxx-user';
import { Policy, PolicyPeriod } from 'src/app/entities/policy';
import { PolicyRiskTrxService } from 'src/app/services/policy-risk-trx-service';
import { Pagination } from 'src/app/entities/boxx-response';
import {
  setPolicyRiskTrxIdAction,
  setPolicyRiskTrxStatusAction,
  setInsuredIdAction,
  setRiskIdAction,
  setPolicySlideoutData,
} from 'src/app/store/lifecycle/lifecycle.action';
import {
  PolicyChangeResponse,
  PolicyTransactionEndorsementRequestBody,
  PolicyTransactionInvoicesRequestBody,
} from 'src/app/entities/policy-lifecycle';
import * as objects from './objects/policy-lifecycle-objects';
import * as utils from '../utils/lifecycle-utils';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { InsuredService } from 'src/app/services/insured.service';
import { Quote, UISpecificValues } from 'src/app/models/quote.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  getQuoteSelector,
  getSelectedQuoteOption,
} from 'src/app/store/create-quote/create-quote.selector';
import {
  getLifecycleState,
  getLifecycleSlideout,
} from 'src/app/store/lifecycle/lifecycle.select';
import { LocationService } from 'src/app/services/location.service';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import { STANDALONE_SELLABLE_TRUE } from 'src/app/constants/quote-constant';
import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import { getErrorMessage, removeDuplicatedKeys } from 'src/app/utils/utils';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { PolicyLifecycleService } from 'src/app/services/policy-lifecycle-service';
import { handleApiError } from '../utils/lifecycle-utils';
import { UNDERWRITER_CLEARANCE } from 'src/app/constants/systemType';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-policy-lifecycle',
  templateUrl: './policy-lifecycle.component.html',
  styleUrls: ['./policy-lifecycle.component.less'],
})
export class PolicyLifecycleComponent implements OnInit {
  private policyId: number;
  private packagePolicyNumber: string;
  private userId: number;
  private policyStatus: DropdownListDto[];
  private cancelReasons: KeyValueDto[];
  private reinstateReasons: KeyValueDto[];

  private policyRisks: Array<objects.PolicyRiskItem> = [];
  private policyRiskTrxs: Array<objects.PolicyRiskTrxItem> = [];
  private policyRiskTrxId: number;
  DocRiskActions;

  private insuredId: number;
  form$: Observable<Quote>;
  isSuccess: boolean = false;
  successMessage: string = '';
  hasError: boolean = false;
  errorMessage: string = '';
  selectedPolicyPeriodId: number = 0;
  isTileExpanded: boolean = true;
  showActionPopup: boolean = false;
  showSlideOut: boolean = false;
  isInvoiced: boolean = false;
  sortByNewAsc: boolean = true;
  showCancelModal: boolean = false;
  showReinstateModal: boolean = false;
  shwStrtNewPeriodModal: boolean = false;

  periodOptions: KeyValueDto[] = [];
  subNavData: Array<objects.PolicySubNavItem> = [];
  policyData: Array<objects.PolicyDataItem> = [];
  timeLineData: Array<objects.PolicyTimeLineItem> = [];
  collapsedTimelineData: number[] = [];
  isAllCollapsed: boolean = false;

  details: objects.PolicyDetail;
  actionPopupDetails: objects.PolicyActionPopupDetail;
  totalCount: number = 0;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalPages: number = 0;

  transactionType: string = '';
  showDocPreview: boolean = false;
  currentPreviewDocUrl = '';
  showBodySpinner: boolean = false;
  currency = '';
  regionId: Number;
  form: FormGroup;
  policyPeriodId;
  status: string;
  riskId: number;
  versionId = 1;
  brokerageData;
  brokerageId;
  producerId;
  policyLifeCycleData;
  transactionRiskLevelAction: string = '';

  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  isUnderwriter = false;
  sortDirection: string = 'desc';
  policyRiskId;
  firstPolicyRiskTrx;
  shortDateFormat: string = '';
  longDateFormat: string = '';

  constructor(
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private domainsService: DomainsService,
    private policyService: PolicyService,
    private policyRiskService: PolicyRiskService,
    private policyRiskTrxService: PolicyRiskTrxService,
    private policyPeriodService: PolicyPeriodService,
    private boxxUserService: BoxxUserService,
    private localStorageService: LocalStorageService,
    private translate: TranslateService,
    private insuredService: InsuredService,
    private locationService: LocationService,
    private riskRegionService: RiskRegionService,
    private policyLifecycleService: PolicyLifecycleService,
    private alertService: AlertService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.showBodySpinner = true;
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      const underWriterPermission = data.permissions?.filter(
        (permissionData: any) =>
          permissionData?.permission?.permissionName?.toLowerCase() ===
          UNDERWRITER_CLEARANCE,
      );
      if (underWriterPermission?.length > 0) {
        this.isUnderwriter = underWriterPermission[0].readWrite ? true : false;
      } else {
        this.isUnderwriter = false;
      }
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });

    this.userId = this.localStorageService.getBoxxUserId();
    this.packagePolicyNumber = this.activatedRoute.snapshot.paramMap.get('id');
    this.policyId = 0;

    this.form$ = this.store.pipe(select(getQuoteSelector));
    this.form$
      .pipe(take(1))
      .subscribe(
        (event) => (this.policyPeriodId = Number(event.ui.policyPeriodId)),
      );
    let products;
    let uiData: UISpecificValues;
    this.form$.subscribe((event) => (products = event.products));
    this.form$.subscribe((event) => (this.regionId = event.ui.regionId));
    this.form$.subscribe((event) => (uiData = event.ui));

    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));

    this.intiValues();

    await this.populateDomainLists();

    this.populateData();
  }

  /**
   * Pre-populate the variables so the HTML will not complain
   */
  private intiValues() {
    this.showBodySpinner = true;
    this.details = {
      header: '',
      currentPeriod: '',
      id: '',
      policyRiskTrxId: 0,
      latestPolicyRiskTrxId: 0,
      policyRiskTrxIds: '',
      userId: 0,
      status: '',
      brokerage: '',
      branch: '',
      producer: '',
      firstBindDate: '',
      policyPeriod: '',
      insuredName: '',
      isUnreadNotesPresent: false,
      isInvoiced: false,
      product: '',
      active: false,
      currentTagOptions: [],
      policyPeriodId: undefined,
      policyRiskId: 0,
    };
  }

  /**
   * Populate lists used for the drop downs or lookups
   */
  private async populateDomainLists() {
    try {
      this.policyStatus =
        await this.domainsService.GetDomainListAsync('POLICYSTATUS');

      const cancel =
        await this.domainsService.GetDomainListAsync('CANCELLATIONREASON');
      this.cancelReasons = cancel.map((data) => utils.mapListReverse(data));

      const reinstate =
        await this.domainsService.GetDomainListAsync('REINSTATEREASON');
      this.reinstateReasons = reinstate.map((dataObj) =>
        utils.mapListReverse(dataObj),
      );
    } catch (error) {
      this.showBodySpinner = false;
      if (![500].includes(error?.status)) {
        this.handleApiError(error);
      }
    }
  }

  /**
   * Make the required API calls to fetch the policy data
   */
  private async populateData() {
    this.showBodySpinner = true;
    try {
      const policy = await this.getPolicyFromApi();

      const getRegionId = await this.getRegionId(policy?.data[0]?.insured?.id);
      if (policy?.data.length == 0) {
        this.handleApiError('error code: 404');
        return;
      }

      const periods = await this.getPolicyPeriodsFromApi();
      let products;

      this.packagePolicyNumber = policy?.data[0]?.pkgPolicyNumber;
      this.brokerageId = periods?.data[0]?.brokerageId;
      // this.riskId = 1;
      this.producerId = periods?.data[0]?.brokerageProducerId;

      ////////////////////
      this.brokerageData = {
        brokerageId: policy?.brokerageBORId,
        producerId: policy?.brokerageProducerBORId,
      };

      this.store.dispatch(
        new CreateQuoteAction.updateUiContents({
          ...this.brokerageData,
        }),
      );
      this.form$.subscribe((event) => (products = event.products));
      this.store
        .select(getLifecycleState)
        .pipe(take(1))
        .subscribe((state) => {
          this.policyRiskTrxId = state?.policyRiskTrxId;
          this.status = state?.policyRiskTrxStatus;
          this.insuredId = state?.insuredId;
          this.riskId = state?.riskId;
        });

      /////////////////////////

      this.populateDataFromApi(policy?.data[0], periods?.data);
    } catch (error) {
      this.showBodySpinner = false;
      if (![500].includes(error?.status)) {
        this.handleApiError(error);
      }
    }
  }

  private async populateDataFromApi(policy: any, periods: any[]) {
    this.populatePeriodDropDown(periods);

    if (!policy) {
      return;
    }
    const status = this.getPolicyStatus(policy.policyStatus);
    const firstBindDate = getFormattedDateTime(
      policy.firstBindDt,
      this.longDateFormat,
    );
    const currentPeriod = this.periodOptions[0].key;
    const policyId = utils.getPolicyNumber(policy);
    const insuredName = utils.getInsuredName(policy.insured);
    const getPolicyByID = this.policyRiskService.GetByPolicyId(this.policyId);
    const risk = await firstValueFrom(getPolicyByID);
    const insuredId = policy?.insured?.id;

    let mailIdList = {
      broker: policy?.brokerageBOR?.email ?? '',
      branch: policy?.brokerageBranchBOR?.email ?? '',
      producer: policy?.brokerageProducerBOR?.email ?? '',
      insured: policy?.insured?.email ?? '',
    };
    this.store.dispatch(new CreateQuoteAction.setAllMailId(mailIdList));

    this.store.dispatch(new setInsuredIdAction(policy?.insured?.id));
    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({ policyId: policy?.id }),
    );

    this.transactionType = this.getPdtDetails()?.status;
    this.details = {
      header: this.translate.instant('Policy'),
      currentPeriod: currentPeriod,
      id: policyId,
      policyRiskTrxId: 0,
      latestPolicyRiskTrxId: 0,
      policyRiskTrxIds: '',
      userId: this.userId,
      status: status,
      brokerage: policy.brokerageBOR.name,
      branch: policy.brokerageBranchBOR.name,
      producer: `${policy.brokerageProducerBOR.firstName} ${policy.brokerageProducerBOR.lastName}`,
      firstBindDate: firstBindDate ? firstBindDate : 'n/a',
      policyPeriod: '',
      insuredName: insuredName,
      isUnreadNotesPresent: false, // TODO: Where does this come from?
      isInvoiced: false,
      product: risk.data[0]?.risk.name ?? '',
      active: risk.data[0]?.risk.active ?? false,
      currentTagOptions: [],
      policyPeriodId: periods[0]?.id,
      policyRiskId: risk.data[0].id,
      insuredId: insuredId,
      riskId: risk.data[0].risk?.id,
    };

    this.actionPopupDetails = {
      id: policyId,
      policyPeriodId: undefined,
      userId: this.userId,
      period: '',
      insured: insuredName,
      brokerage: policy.brokerageBOR.name,
      branch: policy.brokerageBranchBOR.name,
      action: '',
      reasonOptions: [],
    };

    await this.populateAfterPeriodChange();
  }

  private populatePeriodDropDown(periods: PolicyPeriod[]) {
    // Sort periods by sequence value in descending order
    // Should be using the period value, but it is null in may cases.
    const sortedPeriod = periods.sort((a, b) => b.sequence - a.sequence);

    this.periodOptions = sortedPeriod.map((data) => {
      // NOTE: boxxds-dropdown has the key and value backwards

      // TODO: Remove once the "Start next period" API call is fixed!!!!!
      const peroid = data.period
        ? data.period.toString()
        : new Date().getFullYear().toString();
      const kv: KeyValueDto = { key: peroid, value: data.id?.toString() };

      //const kv: KeyValueDto = { key: data.period.toString(), value: data.id?.toString() };
      return kv;
    });

    if (this.periodOptions.length == 0) {
      return;
    }

    this.selectedPolicyPeriodId = Number(this.periodOptions[0].value);
  }

  private createPolicyDataTabs(): void {
    this.policyData = this.policyRisks.map((data) => ({
      risk: utils.addSpaceBeforeCapitalLetter(data.name),
      policyId: data.policyNumber,
      policyRiskTrxId: 0,
      limit: 0,
      premium: 0,
      deductible: 0,
      invoiceNumber: '',
      trnxDate: '',
      underwriter: '',
      annualPremium: 0,
      status: '',
      reason: '',
      policyPeriod: '',
      policyChange: false,
      isPopulated: false,
    }));

    this.subNavData = this.policyData.map((product, index) => ({
      name: product.risk,
      policyRiskTrxId: product.policyRiskTrxId,
      active: index == 0,
    }));

    this.populateActiveTab(0);
  }

  private async populateAfterPeriodChange(): Promise<void> {
    const risks = await this.getPolicyRisksFromApi(this.selectedPolicyPeriodId);
    if (risks.data && risks.data.length > 0) {
      const policyPeriod = utils.getRiskPeriod(
        risks.data[0],
        this.longDateFormat,
      );

      this.isInvoiced = this.isPolicyInvoiced(
        risks.data[0].PolicyRiskTrxes.map((invoice) => {
          if (invoice.invoiceNumber) {
            this.isInvoiced = true;
          }
        }),
      );
      this.policyRiskTrxs = [];
      this.details.policyPeriod = policyPeriod;
      this.details.isInvoiced = this.isInvoiced;
      this.actionPopupDetails.period = policyPeriod;

      this.policyRisks = risks.data.map((data) => ({
        id: data.id,
        name: data.risk.name,
        policyNumber: data.policyNumber,
        period: data.policyPeriod.period,
        effectiveDt: data.policyPeriod.effectiveDt,
      }));
      let startDate = formatDate(
        risks.data[0].policyPeriod.effectiveDt,
        this.longDateFormat,
      );
      let endDate = formatDate(
        risks.data[0].policyPeriod.expiryDt,
        this.longDateFormat,
      );
      this.store.dispatch(
        new CreateQuoteAction.updateUiContents({
          startDate:
            getFormatDate(
              startDate,
              this.longDateFormat,
              this.shortDateFormat,
            ) ?? '',
          endDate:
            getFormatDate(endDate, this.longDateFormat, this.shortDateFormat) ??
            '',
        }),
      );

      this.createPolicyDataTabs();
    }
  }

  private async populateActiveTab(index: number): Promise<void> {
    this.showBodySpinner = true;
    const policyRisk = this.policyRisks[index];
    const policyRiskId = policyRisk.id;
    this.policyRiskId = policyRiskId;
    this.sortByNewAsc = this.sortDirection === 'asc' ? true : false;

    this.store.dispatch(
      new setPolicySlideoutData({
        packagePolicyNumber: this.packagePolicyNumber,
        brokerageId: this.brokerageId,
        riskId: this.riskId,
        producerId: this.producerId,
        riskRegionId: 1,
        policyRiskId: policyRiskId,
        regionId: this.regionId,
      }),
    );

    if (this.policyData[index].isPopulated) {
      // Tab has already been populated, update the timeline.
      this.populateTimelineData(index);
      return;
    }
    const effectiveDate = getFormattedPolicyPeriod(
      policyRisk.period,
      'MMMM DD, YYYY',
    );
    const policyRiskTrxs = await this.getPolicyRiskTrxesFromApi(policyRiskId);

    this.firstPolicyRiskTrx = policyRiskTrxs.data[0];
    const remainingPolicyRiskTrx = policyRiskTrxs.data.slice(1);

    const policyRiskTrxPagination: Pagination = {
      ...policyRiskTrxs.pagination,
      totalRecords: policyRiskTrxs.pagination.totalRecords - 1, // to decrease the latest transaction from the total count
    };

    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        annualPremium: this.firstPolicyRiskTrx?.['totalInvoiceAmount'] ?? null,
        deductible: this.firstPolicyRiskTrx?.['deductible'] ?? null,
        premium: this.firstPolicyRiskTrx?.['premium'] ?? null,
        policyEffectiveDate:
          this.firstPolicyRiskTrx?.['transactionEffectiveDate'] ?? null,
      }),
    );

    this.policyRiskTrxs[index] = {
      RiskTrxs: remainingPolicyRiskTrx,
      Pagination: policyRiskTrxPagination,
    };

    const status = this.firstPolicyRiskTrx.quoteStatus
      ? this.firstPolicyRiskTrx.quoteStatus
      : this.firstPolicyRiskTrx.type;

    this.policyData[index].policyId = this.details.id;
    this.policyData[index].policyRiskTrxId = this.firstPolicyRiskTrx.id;
    this.policyData[index].limit = this.firstPolicyRiskTrx.limit;
    this.policyData[index].premium = this.firstPolicyRiskTrx.premium;
    this.policyData[index].deductible = this.firstPolicyRiskTrx.deductible;
    this.policyData[index].invoiceNumber =
      this.firstPolicyRiskTrx.invoiceNumber;
    this.policyData[index].trnxDate = formatDate(
      this.firstPolicyRiskTrx.createdDt,
      this.longDateFormat,
    );
    this.policyData[index].underwriter = await this.getUnderwriter(
      this.firstPolicyRiskTrx.underwriterId,
    );
    this.policyData[index].annualPremium =
      this.firstPolicyRiskTrx.totalInvoiceAmount;

    this.policyData[index].status = utils.addSpaceBeforeCapitalLetter(status);

    this.policyData[index].reason = this.firstPolicyRiskTrx.notes;
    this.policyData[index].policyPeriod = this.populatePolicyPeriod(
      this.firstPolicyRiskTrx.transactionEffectiveDate,
      policyRisk.effectiveDt,
    );
    this.policyData[index].policyChange =
      this.policyData[index].status?.toLowerCase() === 'policy change'
        ? true
        : false;
    this.policyData[index].isPopulated = true;

    this.subNavData[index].policyRiskTrxId =
      this.policyData[index].policyRiskTrxId;

    this.isInvoiced = this.isPolicyInvoiced(
      this.policyData[index].invoiceNumber,
    );
    this.policyData[index].trxReasons =
      this.firstPolicyRiskTrx?.PolicyRiskTrxReasons;

    // Updating the properties does not trigger ngOnChanges() on the lifecycle-details component
    // because the object reference does not change, only the properties changed.
    this.details.policyRiskTrxId = this.policyData[index].policyRiskTrxId;
    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        latestPolicyRiskTrxId: this.details?.['policyRiskTrxId'] ?? '',
      }),
    );

    this.details.latestPolicyRiskTrxId = this.policyData[index].policyRiskTrxId;
    this.details.isInvoiced = this.isInvoiced;
    this.details.policyRiskTrxIds = policyRiskTrxs.data
      .map((p) => p.id)
      .join(',');

    let trxTypes = policyRiskTrxs.data?.map((dataObj) => ({
      key: dataObj.type,
      value: dataObj.type,
      id: dataObj.id,
    }));
    trxTypes = removeDuplicatedKeys(trxTypes);

    this.details.currentTagOptions = trxTypes;
    // Hack to update the properties to force ngOnChanges()
    // Clone the whole object which updates the object reference.
    this.details = Object.assign({}, this.details);

    this.populateTimelineData(index);
    this.showBodySpinner = false;
  }

  private populatePolicyPeriod(
    transactionEffectiveDate: any,
    policyRiskEffectiveDate: any,
  ) {
    return transactionEffectiveDate
      ? formatDate(transactionEffectiveDate, this.longDateFormat)
      : formatDate(policyRiskEffectiveDate, this.longDateFormat);
  }

  private populateTimelineData(index: number) {
    const data = this.policyRiskTrxs[index];
    const policyRisk = this.policyRisks[index];
    const remainingPolicyRiskTrx = data.RiskTrxs;
    const pagination = data.Pagination;

    this.timeLineData = remainingPolicyRiskTrx.map((trx) => ({
      policyId: this.details.id,
      limit: trx.limit,
      premium: trx.premium,
      deductible: trx.deductible,
      invoiceNumber: trx.invoiceNumber,
      trnxDate: formatDate(trx.createdDt, this.longDateFormat),
      annualPremium: utils.calculateAnnualAmount(trx),
      status: utils.addSpaceBeforeCapitalLetter(
        trx.quoteStatus ? trx.quoteStatus : trx.type,
      ),
      type: utils.addSpaceBeforeCapitalLetter(trx.type),
      policyPeriod: this.populatePolicyPeriod(
        trx.transactionEffectiveDate,
        policyRisk.effectiveDt,
      ),
      isTileExpanded: true,
      policyRiskTrxId: trx.id,
      trxReasons: trx?.PolicyRiskTrxReasons,
    }));

    this.currentPage = pagination.currentPage;
    this.totalCount = pagination.totalRecords;
    this.totalPages = pagination.totalPages;
    this.showBodySpinner = false;
  }

  private async getPolicyFromApi(): Promise<any> {
    if (utils.isNumeric(this.packagePolicyNumber)) {
      this.router.navigate([`../../quote/${this.packagePolicyNumber}`], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });

      return;
    }

    const policy$ = this.policyService.GetByPolicyNumber(
      this.packagePolicyNumber,
    );
    const policy = await lastValueFrom(policy$);

    if (policy.data.length > 0) {
      this.policyId = policy.data[0].id;
    }

    return policy;
  }

  private async getPolicyPeriodsFromApi(): Promise<any> {
    const period$ = this.policyPeriodService.GetAllByPolicyId(this.policyId);
    const period = await lastValueFrom(period$);

    return period;
  }

  private async getPolicyRisksFromApi(policyPeriodId: number): Promise<any> {
    const risk$ = this.policyRiskService.GetByPolicyIdAndPolicyPeriodId(
      this.policyId,
      policyPeriodId,
    );
    const risk = await lastValueFrom(risk$);

    this.store.dispatch(new setRiskIdAction(risk.data[0]?.riskId));
    this.riskId = risk.data[0]?.riskId;
    return risk;
  }

  private async getPolicyRiskTrxesFromApi(policyRiskId: number): Promise<any> {
    this.sortDirection = 'desc';
    this.sortByNewAsc = false;
    const riskTrx$ = this.policyRiskTrxService.GetByPolicyRiskId(
      policyRiskId,
      this.currentPage,
      11, // to get 10 trxns excluding the latest one
      this.sortDirection,
    );
    const riskTrx = await lastValueFrom(riskTrx$);

    return riskTrx;
  }

  private async getBoxxUserFromApi(userId: number): Promise<BoxxUser> {
    try {
      const user$ = this.boxxUserService.GetById(userId);
      const user = await lastValueFrom(user$);
      return user.data;
    } catch (error) {
      return null;
    }
  }

  private async getUnderwriter(id: number): Promise<string> {
    var user: any = await this.getBoxxUserFromApi(id);

    if (user) {
      this.store.dispatch(
        new CreateQuoteAction.setAllMailId({
          underwriter: user?.systemUser?.loginEmail
            ? [`${user.systemUser.loginEmail}`]
            : [],
        }),
      );
      return `${user.firstName} ${user.lastName}`;
    }

    return Promise.resolve('n/a');
  }

  private getPolicyStatus(id: number): string {
    return utils.getValueFromList(this.policyStatus, id);
  }

  private isPolicyInvoiced(invoiceNumber: string): boolean {
    return !!invoiceNumber;
  }

  handleNav(event, index) {
    const currentActiveIdx = this.getActiveTabIndex();
    this.subNavData[currentActiveIdx].active = false;
    this.subNavData[index].active = true;
    this.details.policyRiskTrxId = this.policyData[index].policyRiskTrxId;

    this.populateActiveTab(index);
  }

  handleShowPeriod(value: string) {
    const id = Number(value);
    if (this.selectedPolicyPeriodId == id) {
      return;
    }

    this.selectedPolicyPeriodId = id;
    this.populateAfterPeriodChange();
  }

  getPdtDetails() {
    const currentActiveIdx = this.getActiveTabIndex();

    return this.policyData[currentActiveIdx];
  }

  private getActiveTabIndex(): number {
    return this.subNavData.findIndex((navObj) => navObj.active);
  }

  getStatusType(status) {
    return utils.convertStatusType(status);
  }

  getTransLevelActions(transactionType: string, isInvoiced: boolean) {
    switch (transactionType.toLowerCase()) {
      case 'bind':
      case 'renewal':
        if (isInvoiced) {
          return ['Endorse', 'Policy change'];
        } else {
          return ['Invoice'];
        }
      case 'reinstated':
      case 'endorsement':
        if (isInvoiced) {
          return ['Endorse', 'Policy change'];
        } else {
          return ['Invoice', 'Delete trnx'];
        }
      case 'policy change':
        if (isInvoiced) {
          return ['Endorse', 'Policy change'];
        }
        return ['Execute', 'Delete trnx'];
      case 'cancellation':
        if (!isInvoiced) {
          return ['Invoice'];
        } else return [];
      default:
        return [];
    }
  }

  showReason(status) {
    return (
      status.toLowerCase() == 'closed' ||
      status.toLowerCase() == 'declined' ||
      status.toLowerCase() == 'cancellation' ||
      status.toLowerCase() == 're instate'
    );
  }

  handleTileExpandCollapse() {
    this.isTileExpanded = !this.isTileExpanded;
  }

  handleTileExpandCollapseAll() {
    this.isAllCollapsed = !this.isAllCollapsed;
    let updatedTimeLineData = [];
    this.timeLineData.map((tile, index) => {
      updatedTimeLineData.push({
        ...tile,
        isTileExpanded: this.isAllCollapsed ? false : true,
      });
      this.isAllCollapsed
        ? this.collapsedTimelineData.push(index)
        : (this.collapsedTimelineData = []);
    });
    this.timeLineData = updatedTimeLineData;
  }

  handleTimelineTileToggle(timeLineDataIndex: number) {
    if (!this.collapsedTimelineData.includes(timeLineDataIndex)) {
      this.collapsedTimelineData.push(timeLineDataIndex);
    } else {
      this.collapsedTimelineData.splice(
        this.collapsedTimelineData.indexOf(timeLineDataIndex),
        1,
      );
    }
    this.timeLineData[timeLineDataIndex].isTileExpanded =
      !this.timeLineData[timeLineDataIndex].isTileExpanded;
    this.isAllCollapsed =
      this.collapsedTimelineData.length === this.timeLineData.length;
  }

  async handleTransLevelAction(action, product) {
    this.actionPopupDetails.action = action;
    this.transactionRiskLevelAction = action.toLowerCase();
    switch (action.toLowerCase()) {
      case 'policy change':
        await this.handleEllipsisClick(
          product.policyRiskTrxId,
          this.details.status,
        );
        break;
      case 'execute':
        await this.executeTransaction(product);
        break;
      case 'delete trnx':
        await this.deleteTransaction(product);
        break;
      case 'endorse':
        await this.handleEllipsisClick(
          product.policyRiskTrxId,
          this.details.status,
        );
        break;
      case 'invoice':
        await this.handleEllipsisClick(
          product.policyRiskTrxId,
          this.details.status,
        );
        break;
      default:
    }
  }

  async handleEllipsisClick(id, status) {
    this.store.dispatch(new setPolicyRiskTrxIdAction(id));
    this.store.dispatch(new setPolicyRiskTrxStatusAction(status));
    this.policyLifeCycleData = [this.timeLineData, this.details];
    this.store
      .select(getLifecycleSlideout)
      .pipe(take(1))
      .subscribe((state) => {
        this.brokerageId = state?.brokerageId;
        this.producerId = state?.producerId;
      });
    this.store
      .select(getLifecycleState)
      .pipe(take(1))
      .subscribe((state) => {
        this.policyRiskTrxId = state?.policyRiskTrxId;
        this.status = state?.policyRiskTrxStatus;
        this.insuredId = state?.insuredId;
        this.riskId = state?.riskId;
      });
    this.brokerageData = {
      brokerageId: this.brokerageId,
      producerId: this.producerId,
    };

    //this.populateProducts(this.insuredId, this.riskId, this.brokerageData);
    this.showSlideOut = true;
  }

  handleSlideOutSave(event) {
    if (event) {
      switch (event.action) {
        case 'POLICY_CHANGE':
          this.handleTrxPolicyChange(event.data);
          break;
        case 'ENDORSE':
          this.endorseTransaction(event.data);
          break;
        case 'INVOICE':
          this.invoiceTransaction(event.data);
          break;
        default:
          this.handleSlideOutClose();
      }
    } else {
      this.handleSlideOutClose();
    }
  }

  handleSlideOutClose() {
    this.showSlideOut = false;
    this.showBodySpinner = true;
    Promise.all([this.populatePolicyTileData()]);
    this.transactionRiskLevelAction = '';
  }

  async populatePolicyTileData() {
    await this.createPolicyDataTabs();
  }

  handleTrxPolicyChange(request) {
    this.policyLifecycleService.TransactionRiskPolicyChange(request).subscribe({
      next: (response) => {
        this.handleSlideOutClose();
        const alertData = {
          show: true,
          type: 'success',
          headerText: 'success!',
          bodyText: this.translate.instant('policy.change.success.body'),
        };
        this.alertService.addAlert(alertData);
        // this.populateData();
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          const alertData = {
            show: true,
            type: 'error',
            headerText: 'error!',
            bodyText: handleApiError(error),
            wrapperStyle: { margin: '30px 0px 0px -75px' },
          };
          this.alertService.addAlert(alertData);
        }
      },
    });
  }
  async executeTransaction(product) {
    let request = {
      policyRiskTrxId: product.policyRiskTrxId,
    };
    this.policyLifecycleService.TransactionRiskExecute(request).subscribe({
      next: (response) => {
        const alertData = {
          show: true,
          type: 'success',
          headerText: 'success!',
          bodyText: this.translate.instant('policy.update.success.body'),
        };
        this.alertService.addAlert(alertData);
        this.populateData();
      },
      error: (error) => {
        const message = handleApiError(error);
        if (![500].includes(error?.status)) {
          const alertData = {
            show: true,
            type: 'error',
            headerText: 'error!',
            bodyText: message,
          };
          this.alertService.addAlert(alertData);
        }
      },
    });
  }

  async deleteTransaction(product) {
    let request = {
      policyRiskTrxId: product.policyRiskTrxId,
    };
    this.policyLifecycleService.TransactionRiskDelete(request).subscribe({
      next: (response) => {
        const alertData = {
          show: true,
          type: 'success',
          headerText: 'success!',
          bodyText: this.translate.instant('policy.delete.success.body'),
        };
        this.alertService.addAlert(alertData);
        this.populateData();
      },
      error: (error) => {
        const message = handleApiError(error);
        if (![500].includes(error?.status)) {
          const alertData = {
            show: true,
            type: 'error',
            headerText: 'error!',
            bodyText: message,
          };
          this.alertService.addAlert(alertData);
        }
      },
    });
  }

  endorseTransaction(requestBody: PolicyTransactionEndorsementRequestBody) {
    this.policyLifecycleService.TransactionRiskEndorse(requestBody).subscribe({
      next: (response) => {
        this.handleSlideOutClose();
        const alertData = {
          show: true,
          type: 'success',
          headerText: 'success!',
          bodyText: this.translate.instant('policy.endorse.success.body'),
        };
        this.alertService.addAlert(alertData);
      },
      error: (error) => {
        this.handleSlideOutClose();
        const message = handleApiError(error);
        if (![500].includes(error?.status)) {
          const alertData = {
            show: true,
            type: 'error',
            headerText: 'error!',
            bodyText: message,
          };
          this.alertService.addAlert(alertData);
        }
      },
    });
  }

  invoiceTransaction(requestBody: PolicyTransactionInvoicesRequestBody) {
    this.policyLifecycleService.TransactionRiskInvoice(requestBody).subscribe({
      next: (response) => {
        this.handleSlideOutClose();
        const alertData = {
          show: true,
          type: 'success',
          headerText: 'success!',
          bodyText: this.translate.instant('policy.invoice.success.body'),
        };
        this.alertService.addAlert(alertData);
      },
      error: (error) => {
        this.handleSlideOutClose();
        const message = handleApiError(error);
        if (![500].includes(error?.status)) {
          const alertData = {
            show: true,
            type: 'error',
            headerText: 'error!',
            bodyText: message,
          };
          this.alertService.addAlert(alertData);
        }
      },
    });
  }

  handleBind() {
    this.router.navigate(['../summary/product/', this.details.id], {
      relativeTo: this.activatedRoute,
      skipLocationChange: true,
    });
  }

  handleShowRenew() {
    this.actionPopupDetails.action = 'Renew';
    this.actionPopupDetails.reasonOptions = [];

    // TODO: Call API to make changes
    const alertData = {
      show: true,
      type: 'error',
      headerText: 'error!',
      bodyText: 'Action is not implemented yet.',
    };
    this.alertService.addAlert(alertData);
  }

  handleShowCancel() {
    this.actionPopupDetails.action = 'Cancel';
    this.actionPopupDetails.policyPeriodId = this.selectedPolicyPeriodId;
    this.actionPopupDetails.reasonOptions = this.cancelReasons;

    this.showCancelModal = !this.showCancelModal;
  }

  handleCancelConfirm(event: PolicyChangeResponse) {
    this.showCancelModal = false;
    this.handlePolicyChangeEvent(event);
  }

  handleShowReinstate() {
    this.actionPopupDetails.action = 'Reinstate';
    this.actionPopupDetails.policyPeriodId = this.selectedPolicyPeriodId;
    this.actionPopupDetails.reasonOptions = this.reinstateReasons;
    this.showReinstateModal = !this.showReinstateModal;
  }

  handleReinstateConfirm(event: PolicyChangeResponse) {
    this.showReinstateModal = false;
    this.handlePolicyChangeEvent(event);
  }

  handleShowStartNextPeriod() {
    this.actionPopupDetails.action = 'StartNextPeriod';
    this.actionPopupDetails.policyPeriodId = this.selectedPolicyPeriodId;
    this.actionPopupDetails.reasonOptions = [];
    this.shwStrtNewPeriodModal = !this.shwStrtNewPeriodModal;
  }

  handleStartNewPeriodConfirm(event: PolicyChangeResponse) {
    this.shwStrtNewPeriodModal = false;
    this.handlePolicyChangeEvent(event);
  }

  handleTimelineTileSort() {
    if (this.sortDirection === 'desc') {
      this.sortDirection = 'asc';
      this.sortByNewAsc = true;
    } else {
      this.sortDirection = 'desc';
      this.sortByNewAsc = false;
    }

    this.onPageChange(1);
  }

  handleApiError(error: any) {
    if (![500].includes(error?.status)) {
      let errorMessage = '';
      if (typeof error === 'string' || error instanceof String) {
        if (error?.toLowerCase().includes('error code: 404', 0)) {
          errorMessage = this.translate.instant('Requested policy not found.');
        } else {
          errorMessage = getErrorMessage(error);
        }
      } else {
        errorMessage = getErrorMessage(error);
      }
      const alertData = {
        show: true,
        type: 'error',
        headerText: 'error!',
        bodyText: errorMessage,
      };
      this.alertService.addAlert(alertData);
    }
  }

  handlePolicyChangeEvent(event: PolicyChangeResponse) {
    this.handleCloseSuccessEvent();
    this.handleCloseErrorEvent();

    if (event.success) {
      const alertData = {
        show: true,
        type: 'success',
        headerText: 'success!',
        bodyText: event.message,
      };
      this.alertService.addAlert(alertData);
      this.populateData();
      return;
    }

    const alertData = {
      show: true,
      type: 'error',
      headerText: 'common.errorHeader',
      bodyText: event.message,
    };
    this.alertService.addAlert(alertData);
  }

  handleCloseSuccessEvent() {
    this.isSuccess = false;
    this.successMessage = '';
  }

  handleCloseErrorEvent() {
    this.hasError = false;
    this.errorMessage = '';
  }

  toggleDocPreview() {
    this.showDocPreview = !this.showDocPreview;
  }

  handleDocDownload() {
    this.currentPreviewDocUrl = 'assets/docs/sampledocument.pdf';
    this.toggleDocPreview();
  }

  async onPageChange(pageNumber: number) {
    let index = 0;
    this.policyRiskTrxs = [];
    let formattedRiskTrx;
    this.totalPages = Math.ceil(this.totalCount / this.itemsPerPage);
    this.currentPage = pageNumber;
    this.itemsPerPage = 10;

    if (!this.sortByNewAsc && this.currentPage === 1) {
      this.sortDirection = 'desc';
      this.itemsPerPage = 10;
    }
    if (this.sortByNewAsc) {
      this.sortDirection = 'asc';
    }

    this.currentPage = pageNumber;
    const riskTrx$ = this.policyRiskTrxService.GetByPolicyRiskId(
      this.policyRiskId,
      pageNumber,
      this.itemsPerPage,
      this.sortDirection,
      true,
    );
    let riskTrx = await lastValueFrom(riskTrx$);

    this.policyRiskTrxs.push({
      RiskTrxs: formattedRiskTrx ?? riskTrx.data,
      Pagination: riskTrx.pagination,
    });

    this.populateTimelineData(index);
  }

  async getRegionId(insuredId) {
    await this.insuredService
      .GetByInsuredId(insuredId)
      .subscribe((insuredData) => {
        this.regionId =
          insuredData?.data?.insuredLocation?.regionId ??
          insuredData?.data?.insuredLocation?.region?.id;
        this.store.dispatch(
          new CreateQuoteAction.UpdateInsuredAction({
            regionId: this.regionId,
            timezone:
              insuredData?.data?.insuredLocation?.timeZoneCode ??
              'America/New_York',
          }),
        );
      });
  }

  populateProducts(insuredId, productsSelected, brokerageData) {
    try {
      this.insuredService.Get(insuredId).subscribe((data) => {
        if (data.data?.insuredType.toLowerCase() == 'company') {
          this.versionId = 2;
        }
        const locationId = data.data.insuredLocation.id;
        this.locationService.Get(locationId).subscribe((data) => {
          let regionID = data.data.region.id;
          this.store.dispatch(
            new CreateQuoteAction.UpdateInsuredAction({
              regionId: data.data.region.id,
              versionId: this.versionId,
            }),
          );

          this.riskRegionService
            .getRiskRegionAndVersion(
              regionID,
              this.versionId,
              STANDALONE_SELLABLE_TRUE,
            )
            .subscribe(async (data) => {});
        });
      });
    } catch (error) {}
  }
}
