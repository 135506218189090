import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from './base-services';
import { Observable, retry, catchError } from 'rxjs';
import { BoxxResponse } from '../entities/boxx-response';

@Injectable({
  providedIn: 'root',
})
export class ConfRiskLimitsService extends BaseService<any> {
  constructor(http: HttpClient) {
    const baseurl =
      environment.msProductriskBaseUrl + environment.confRiskLimitsUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  GetByRegionIdAndRiskIds(regionId, riskId): Observable<BoxxResponse<any>> {
    return this.http
      .get<BoxxResponse<any>>(
        this.baseurl + '?regionId=' + regionId + '&riskId=' + riskId,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
