import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { BaseService } from '../base-services';
import {
  BrokerageBranchTeamPost,
  BrokerageBranchTeamPut,
} from 'src/app/models/brokerageBranchTeam';
@Injectable({
  providedIn: 'root',
})
export class BrokerageBranchTeamsService extends BaseService<any> {
  constructor(http: HttpClient) {
    const baseurl =
      environment.msBrokerBaseUrl + environment.brokerageBranchTeamPathUrl;
    super(http, baseurl);
  }

  addBranchTeams(model: BrokerageBranchTeamPost): Observable<any> {
    const url = `${this.baseurl}`;

    return this.http.post(url, model);
  }
  deleteBranchTeams(id: number): Observable<any> {
    const url = `${this.baseurl}${id}`;

    return this.http.delete(url);
  }

  updateBranchTeams(model: BrokerageBranchTeamPut): Observable<any> {
    const url = `${this.baseurl}`;

    return this.http
      .put(url, model)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  getBranchTeams(
    brokerageBranchId: number,
    name: string = '',
    page: number = 1,
    limit: number = 10,
    sort: string = '',
  ): Observable<any> {
    let url =
      this.baseurl + '?page=' + page + '&limit=' + limit + '&sort=' + sort;
    if (brokerageBranchId) {
      url = `${url}&brokerageBranchId=${brokerageBranchId}`;
    }
    if (name != '') {
      url = `${url}&name=${name}`;
    }
    return this.http
      .get(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  getBranchTeamsWithFilter({
    brokerageBranchId,
    name = '',
    page = 1,
    limit = 10,
    sort = 'id:desc',
  }): Observable<any> {
    const url = `${this.baseurl}?brokerageBranchId=${brokerageBranchId}&name=${name}&page=${page}&limit=${limit}&sort=${sort}`;

    return this.http.get(url);
  }
}
