<ng-container *ngIf="showBodySpinner">
  <div class="body-spinner">
    <boxxds-spinner
      [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
    ></boxxds-spinner>
  </div>
</ng-container>
<ng-container *ngIf="!showBodySpinner">
  <app-navigation-v2
    [showHeader]="true"
    [showHeaderBanner]="true"
    (suggestedSearchSelected)="handleSearch($event)"
    [items]="itemsMenu"
    [badgeData]="'0 Records'"
    (buttonClicked)="handleStartNewQuote()"
  ></app-navigation-v2>
  <div class="lifecycleHeader">
    <app-lifecycle-header
      [header]="'Policy'"
      [policyId]="packagePolicyNumber"
      [details]="details"
      [transactionType]="transactionType"
      (handleShowPeriod)="handleShowPeriod($event)"
      (handleBind)="this.permissionList[this.currentScreen] && handleBind()"
      (handleCancel)="
        this.permissionList[this.currentScreen] && handleShowCancel()
      "
      (handleStartNextPeriod)="
        this.permissionList[this.currentScreen] && handleShowStartNextPeriod()
      "
      (handleReinstate)="
        this.permissionList[this.currentScreen] && handleShowReinstate()
      "
      (handleRenew)="
        this.permissionList[this.currentScreen] && handleShowRenew()
      "
      [isProductActive]="currentProductDetails.active"
    ></app-lifecycle-header>
  </div>
  <div *ngIf="form$ | async as form">
    <div class="lifecycleContainer">
      <app-lifecycle-information
        [details]="details"
      ></app-lifecycle-information>
      <div class="LatestTransactionConatiner">
        <div class="header">
          <div class="left">
            <h2 class="head">Policy current state</h2>
            <boxxds-status-badge-v2
              [status]="getStatusName(details.status)"
              [statusType]="getStatusType(details.status)"
            ></boxxds-status-badge-v2>
          </div>
          <div class="right">
            <boxxds-button-v2
              [isDisabled]="false"
              [size]="'sm'"
              [btnClass]="'secondary gray'"
              [btnType]="'only'"
              [isDropdown]="false"
              [leftImage]="'edit-05'"
              (handleClick)="toggleNotesPopup($event)"
            >
            </boxxds-button-v2>
            <boxxds-button-v2
              [isDisabled]="
                !this.permissionList[this.currentScreen] ||
                !currentProductDetails.active ||
                ['quote-closed'].includes(details.status)
              "
              [class.noPointerEvents]="
                !this.permissionList[this.currentScreen] ||
                !currentProductDetails.active ||
                ['quote-closed'].includes(details.status)
              "
              [size]="'sm'"
              [btnClass]="'secondary gray'"
              [btnType]="'only'"
              [isDropdown]="false"
              [leftImage]="'send-01'"
              (handleClick)="
                this.permissionList[this.currentScreen] &&
                  currentProductDetails.active &&
                  !['quote-closed'].includes(details.status) &&
                  handleSendQuote()
              "
            >
            </boxxds-button-v2>
            <boxxds-button-v2
              [isDisabled]="false"
              [size]="'sm'"
              [btnClass]="'secondary gray'"
              [btnType]="'only'"
              [isDropdown]="false"
              [leftImage]="'dots-vertical'"
              (handleClick)="showQuoteSlideOut($event)"
            >
            </boxxds-button-v2>
          </div>
        </div>
        <div class="content">
          <table class="transactionTable">
            <thead>
              <tr class="tableHeader">
                <th class="head">Product</th>
                <th class="head">Status</th>
                <th class="head">Premium</th>
                <th class="head"><span class="sub">Annual </span>Premium</th>
                <th class="head">Limit</th>
                <th class="head">Retention</th>
                <th class="head">Effective date</th>
                <th class="head">Invoice number</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let nav of subNavData; let i = index">
                <ng-container *ngIf="getPdtDetailsByIndex(i) as product">
                  <tr class="tableContent">
                    <td>
                      <boxxds-radio-v2
                        [control]="'product'"
                        [items]="[subNavData[i]]"
                        [form]="productForm"
                        (handleChange)="handleNav($event, i)"
                        [labelStyle]="{
                          color: '#2D2D39',
                          fontFamily: 'Gibson',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: '500',
                          lineHeight: '20px'
                        }"
                      ></boxxds-radio-v2>
                      <!-- <p class="productName">{{nav.name}}</p> -->
                    </td>
                    <td>
                      <boxxds-status-badge-v2
                        [status]="toSentenceCase(product.status)"
                        [statusType]="getStatusType(product.status)"
                      ></boxxds-status-badge-v2>
                    </td>
                    <td>
                      {{
                        product.premium
                          | currency: currency : "symbol-narrow" : "1.0-0"
                      }}
                    </td>
                    <td>
                      {{
                        product.annualPremium
                          | currency: currency : "symbol-narrow" : "1.0-0"
                      }}
                    </td>
                    <td>
                      {{
                        product.limit
                          | currency: currency : "symbol-narrow" : "1.0-0"
                      }}
                    </td>
                    <td>
                      {{
                        product.deductible
                          | currency: currency : "symbol-narrow" : "1.0-0"
                      }}
                    </td>
                    <td>{{ product.policyPeriod }}</td>
                    <td>
                      {{
                        !product.invoiceNumber || +product.invoiceNumber < 0
                          ? "N/A"
                          : product.invoiceNumber
                      }}
                    </td>
                    <td>
                      <div
                        class="button-container"
                        *ngIf="
                          getTransLevelActions(
                            product?.status,
                            isInvoiced
                          ) as transActions
                        "
                      >
                        <boxxds-button-v2
                          *ngFor="let action of transActions"
                          [buttonText]="action"
                          [isDisabled]="
                            showPolicyChange ||
                            showForm ||
                            !currentProductDetails.active
                          "
                          [size]="'sm'"
                          [btnClass]="'link'"
                          [isDropdown]="false"
                          (handleClick)="
                            currentProductDetails.active &&
                              this.permissionList[this.currentScreen] &&
                              handleTransLevelAction(action, product)
                          "
                        >
                        </boxxds-button-v2>
                      </div>
                    </td>
                  </tr>
                  <tr class="transactionForm" *ngIf="showForm">
                    <td colspan="9">
                      <app-plc-endorsement-section-v2
                        [isEdit]="
                          this.actionPopupDetails.action.toLowerCase() ===
                            'edit' && this.showForm
                            ? true
                            : false
                        "
                        [policyRiskTrxId]="product.policyRiskTrxId"
                        [product]="nav.name"
                        [showEndorseForm]="showForm"
                        [trxTypesList]="riskTrxTypes"
                        [details]="docPopupDetails"
                        [policyEffectiveDate]="policyEffectiveDate"
                        [policyExpiryDate]="policyExpiryDate"
                        (handleEndorseSuccess)="handleFormSuccess()"
                        (handleClose)="handleEndorseClose()"
                        (handleInvoiceSuccess)="handleFormSuccess()"
                        (handleSave)="handleFormSuccess()"
                      >
                      </app-plc-endorsement-section-v2>
                    </td>
                  </tr>
                  <!-- policy change -->
                  <tr class="transactionForm" *ngIf="showPolicyChange">
                    <td colspan="9">
                      <app-plc-change-section-v2
                        [policyRiskTrxId]="this.details.policyRiskTrxId"
                        [policyPeriodId]="policyPeriodId"
                        [isTrxPolicyChange]="isTrxPolicyChange"
                        (handleClose)="closePolicyChange($event)"
                        (handleExecute)="handleExecuteTransaction($event)"
                        (handlePolicyChangeSuccess)="
                          handlePolicyChangeSuccess($event)
                        "
                      >
                      </app-plc-change-section-v2>
                    </td>
                  </tr>
                  <!-- policy change end -->
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div class="footer" [ngClass]="{ expandedFooter: openFooter }">
          <div class="footer-head">
            <div class="footer-dropdown" (click)="openFooter = !openFooter">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M5 7.5L10 12.5L15 7.5"
                  stroke="#98A2B3"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p class="text">Annual Premium</p>
            </div>
            <div class="mainContent">
              {{
                firstPolicyRiskTrx?.totalAnnualPremium ?? 0
                  | currency: currency : "symbol-narrow" : "1.0-0"
              }}
            </div>
          </div>
          <div class="footer-content">
            <div class="label">Sales tax</div>
            <div class="content">
              {{
                firstPolicyRiskTrx.totalSalesTax ?? 0
                  | currency: currency : "symbol-narrow" : "1.0-0"
              }}
            </div>
            <div class="label">Insurance tax</div>
            <div class="content">
              {{
                firstPolicyRiskTrx.totalInsuranceTax ?? 0
                  | currency: currency : "symbol-narrow" : "1.0-0"
              }}
            </div>
            <div class="label">Services</div>
            <div class="content">
              {{
                firstPolicyRiskTrx.totalServicesAmount ?? 0
                  | currency: currency : "symbol-narrow" : "1.0-0"
              }}
            </div>
            <div class="label">TRIA</div>
            <div class="content">
              {{
                firstPolicyRiskTrx.totalTRIA ?? 0
                  | currency: currency : "symbol-narrow" : "1.0-0"
              }}
            </div>
            <div class="label">Policy fee</div>
            <div class="content">
              {{
                firstPolicyRiskTrx.totalPolicyFee ?? 0
                  | currency: currency : "symbol-narrow" : "1.0-0"
              }}
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <app-plc-timeline
          [policyRiskId]="policyRiskId"
          [details]="details"
          [regionId]="regionId"
          [riskId]="riskId"
          [isProductActive]="currentProductDetails.active"
        ></app-plc-timeline>
      </div>
    </div>

    <app-notes-v2
      *ngIf="showNotesPopup"
      [showModalNotesPopup]="showNotesPopup"
      [policyPeriodId]="this.selectedPolicyPeriodId"
      (handleClose)="handleNotesPopupClose($event)"
    ></app-notes-v2>
    <app-policy-cancel-popup
      *ngIf="this.permissionList[this.currentScreen] && showCancelModal"
      [showModal]="this.permissionList[this.currentScreen] && showCancelModal"
      [details]="actionPopupDetails"
      (handleActionConfirm)="
        this.permissionList[this.currentScreen] && handleCancelConfirm($event)
      "
      (handleClose)="showCancelModal = false"
    ></app-policy-cancel-popup>
    <app-policy-reinstate-popup
      *ngIf="this.permissionList[this.currentScreen] && showReinstateModal"
      [showModal]="
        this.permissionList[this.currentScreen] && showReinstateModal
      "
      [details]="actionPopupDetails"
      (handleActionConfirm)="
        this.permissionList[this.currentScreen] &&
          handleReinstateConfirm($event)
      "
      (handleClose)="showReinstateModal = false"
      [details]="actionPopupDetails"
    ></app-policy-reinstate-popup>
    <app-policy-new-period-popup-v2
      *ngIf="this.permissionList[this.currentScreen] && shwStrtNewPeriodModal"
      [details]="actionPopupDetails"
      (handleActionConfirm)="handleStartNewPeriodConfirm($event)"
      (handleClose)="shwStrtNewPeriodModal = false"
    ></app-policy-new-period-popup-v2>
    <app-modal-email-quote-v2
      *ngIf="showDocTempSelectionPopup"
      [showModal]="showDocTempSelectionPopup"
      [isLoader]="isLoader"
      [docTempContainerStyle]="{}"
      [details]="docPopupDetails"
      [productId]="policyRiskId"
      [popupHeading]="
        'workFlow3.emailPopupV2.heading.sendingPolicyDocuments' | translate
      "
      (handleClose)="handleExit($event)"
      (handleSuccess)="handleSuccessDocTemplate($event)"
    >
    </app-modal-email-quote-v2>
    <app-modal-send-package-workflow3
      *ngIf="showSendModal"
      [showModal]="showSendModal"
      [isLoader]="
        isLoader === true || this.templateDetails.length === 0 ? true : false
      "
      [templateDetails]="templateDetails"
      [docDetails]="docDetails"
      [title]="
        'workFlow3.emailPopupV2.heading.sendingPolicyDocuments' | translate
      "
      (handleSend)="handleSend($event)"
      (docGenFinishHandler)="docGenFinishHandler()"
      (handleBackButton)="handleBack()"
      (handleClose)="closeSendPackage($event)"
      [formDetails]="form.ui"
      [disableBackButton]="hasTemplatePopup"
      [tempType]="2"
    >
    </app-modal-send-package-workflow3>
    <app-slideout-popup-base
      *ngIf="showSlideOut"
      [isPopupOpen]="showSlideOut"
      [isLoading]="isLoading"
      (handleClose)="handleSlideOutClose()"
    >
      <app-policy-slideout-v2
        [details]="details"
        [riskTrxTypes]="riskTrxTypes"
        [isProductActive]="currentProductDetails.active"
        (handleLoading)="handleLoading($event)"
      ></app-policy-slideout-v2>
    </app-slideout-popup-base>
  </div>
</ng-container>
