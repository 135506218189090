<div class="search-input-v3">
  <section class="search-input-type">
    <button
      class="dropdown-btn"
      [title]="currentSearchType"
      (click)="toggleSearchType($event)"
      (clickOutside)="isSearchTypeMenuOpen = false"
    >
      <span class="dropdown-text">{{ currentSearchType }}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M5 7.5L10 12.5L15 7.5"
          stroke="#667085"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
    <div class="dropdown-list" *ngIf="isSearchTypeMenuOpen">
      <ul>
        <li
          *ngFor="let type of searchTypes"
          [class.selected]="currentSearchType === type.key"
          [title]="type.key"
          (click)="selectSearchType(type)"
        >
          <p class="dropdown-type-text">{{ type.value }}</p>
        </li>
      </ul>
    </div>
  </section>
  <section class="search-area sensitive-data">
    <div class="search-input">
      <input
        type="text"
        id="search-input-field"
        name="search-input-field"
        placeholder="Search"
        autocomplete="off"
        (keyup)="handleSearch($event)"
        (focus)="handleSearchFocused()"
      />
    </div>
    <div class="search-icon">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="search-lg">
          <path
            id="Icon"
            d="M17.5 17.5L14.5834 14.5833M16.6667 9.58333C16.6667 13.4954 13.4954 16.6667 9.58333 16.6667C5.67132 16.6667 2.5 13.4954 2.5 9.58333C2.5 5.67132 5.67132 2.5 9.58333 2.5C13.4954 2.5 16.6667 5.67132 16.6667 9.58333Z"
            stroke="#FFAA00"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </svg>
    </div>
    <div
      class="dropdown-list custom-scrollbar"
      *ngIf="searchSuggestions.length > 0 && searchSuggestionsMenuOpen"
      (clickOutside)="handleClickOutsideSearchSuggestionsMenu()"
    >
      <ul>
        <li
          *ngFor="let suggestion of searchSuggestions"
          class="search-result"
          (click)="selectSuggestion(suggestion)"
        >
          <div class="search-result-item">
            <div
              class="result-type-icon"
              *ngIf="currentSearchType === 'Insured'"
            >
              <ng-container *ngIf="suggestion.type === 'company'">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M6.25033 9.16667H3.83366C3.36695 9.16667 3.13359 9.16667 2.95533 9.25749C2.79853 9.33739 2.67105 9.46487 2.59115 9.62167C2.50033 9.79993 2.50033 10.0333 2.50033 10.5V17.5M13.7503 9.16667H16.167C16.6337 9.16667 16.8671 9.16667 17.0453 9.25749C17.2021 9.33739 17.3296 9.46487 17.4095 9.62167C17.5003 9.79993 17.5003 10.0333 17.5003 10.5V17.5M13.7503 17.5V5.16667C13.7503 4.23325 13.7503 3.76654 13.5687 3.41002C13.4089 3.09641 13.1539 2.84144 12.8403 2.68166C12.4838 2.5 12.0171 2.5 11.0837 2.5H8.91699C7.98357 2.5 7.51686 2.5 7.16034 2.68166C6.84674 2.84144 6.59177 3.09641 6.43198 3.41002C6.25033 3.76654 6.25033 4.23325 6.25033 5.16667V17.5M18.3337 17.5H1.66699M9.16699 5.83333H10.8337M9.16699 9.16667H10.8337M9.16699 12.5H10.8337"
                    stroke="#667085"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </ng-container>
              <ng-container *ngIf="suggestion.type === 'individual'">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M16.6663 17.5C16.6663 16.337 16.6663 15.7555 16.5228 15.2824C16.1996 14.217 15.366 13.3834 14.3006 13.0602C13.8275 12.9167 13.246 12.9167 12.083 12.9167H7.91634C6.75337 12.9167 6.17189 12.9167 5.69872 13.0602C4.63339 13.3834 3.79971 14.217 3.47654 15.2824C3.33301 15.7555 3.33301 16.337 3.33301 17.5M13.7497 6.25C13.7497 8.32107 12.0707 10 9.99967 10C7.92861 10 6.24967 8.32107 6.24967 6.25C6.24967 4.17893 7.92861 2.5 9.99967 2.5C12.0707 2.5 13.7497 4.17893 13.7497 6.25Z"
                    stroke="#667085"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </ng-container>
            </div>
            <div
              class="result-info"
              [ngStyle]="{
                maxWidth: currentSearchType === 'Insured' ? '130px' : '160px'
              }"
              title="{{ suggestion?.value ?? '' }}"
            >
              {{ suggestion?.value ?? "" }}
            </div>
            <div class="select-icon">
              <ng-container
                *ngIf="
                  selectedResult.length > 0 &&
                  selectedResult[0].value === suggestion.value
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M16.6663 5L7.49967 14.1667L3.33301 10"
                    stroke="#FCBD32"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </ng-container>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div
      class="dropdown-list no-result"
      *ngIf="searchSuggestions.length === 0 && searchSuggestionsMenuOpen"
      (clickOutside)="searchSuggestionsMenuOpen = false"
    >
      {{ "common.noRecordsFound" | translate }}
    </div>
  </section>
</div>
