<div class="btn-group-class" *ngIf="buttons.length > 0">
  <ul>
    <li *ngFor="let button of buttons">
      <button
        class="btn"
        [ngClass]="{ 'btn-active': button.active }"
        (click)="handleClick(button.id)"
      >
        {{ button.name }}
      </button>
    </li>
  </ul>
</div>
