import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl,
  Validators,
} from '@angular/forms';
import {
  isBranch,
  isBroker,
  isProducer,
} from 'src/app/constants/broker-constant';
import { getPolicyStatusType } from 'src/app/constants/policyStatus';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import { BrokerageDirectoryService } from 'src/app/services/brokerage-directory.service';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import { DomainsService } from 'src/app/services/domains.service';
import {
  MAX_DEDUCTIBLE,
  MAX_LIMIT,
  MAX_PREMIUM,
  MIN_DEDUCTIBLE,
  MIN_LIMIT,
  MIN_PREMIUM,
} from 'src/app/constants/filter-constant';
import { USER_ROLES } from 'src/app/constants/security/systemUserType';
import { Store, select } from '@ngrx/store';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { formatDate } from 'src/app/utils/formatDate';

@Component({
  selector: 'app-policy-grid-filter',
  templateUrl: './policy-grid-filter.component.html',
  styleUrls: ['./policy-grid-filter.component.less'],
})
export class PolicyGridFilterComponent implements OnInit {
  effectiveHasError = false;
  expiryHasError = false;
  disableDates = false;
  effectiveFrmErrMsg = 'filter.error.policyEffectiveFrmErr';
  effectiveToErrMsg = 'filter.error.policyEffectiveToErr';
  expiryFrmErrMsg = 'filter.error.policyExpiryFrmErr';
  expiryToErrMsg = 'filter.error.policyExpiryToErr';
  products = [
    { name: 'All', value: 'brokerage', checked: false },
    { name: 'Cyber', value: 'branch', checked: false },
    { name: 'Excess', value: 'producer', checked: false },
    { name: 'E&O', value: 'producer', checked: false },
  ];
  form: FormGroup;
  pdtSelected = [];
  statusSelected = [];
  formSubmitted = false;
  userID: number;
  @Input() show: boolean = false;
  @Input() inputForm: FormGroup;

  @Output() handleClearAllFilters = new EventEmitter<any>();
  @Output() handleFiltering = new EventEmitter<any>();

  @Input() pdtOptions = [];
  statusOptions: { key: string; value: string; disabled: boolean }[];
  brokerageBranchProdOptions = [];
  brokerageDirectorySearch = '';
  brokerageDirectorCurrentPage = 0;
  brokerageDirectoryTotalRecords = 0;

  underwriterSearch = '';
  underwriterCurrentPage = 0;
  underwriterTotalRecords = 0;
  underwriters = [];
  underwritersOptions = [];
  shortDateFormat: string = '';
  longDateFormat: string = '';

  constructor(
    private datePipe: DatePipe,
    public brokerageDirectoryService: BrokerageDirectoryService,
    public boxxUserService: BoxxUserService,
    private store: Store,
    private domainsService: DomainsService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    if (this.inputForm) {
      this.form = this.inputForm;
    }
    this.userID = this.localStorageService.getBoxxUserId();

    this.fetchQuoteStatusLists();

    this.form.get('status').valueChanges.subscribe((nv) => {
      if (!nv || nv.length == 0) {
        this.statusSelected = [];
      }
    });
  }
  loadMoreBrokerage() {
    if (
      this.brokerageBranchProdOptions.length <
      this.brokerageDirectoryTotalRecords
    ) {
      let page = this.brokerageDirectorCurrentPage + 1;
      this.getBrokerageDirectories(this.brokerageDirectorySearch, page, true);
    }
  }
  searchBrokerageDirectories(value) {
    if (value.length >= 3) {
      this.getBrokerageDirectories(value);
    } else {
      this.brokerageBranchProdOptions = [];
    }
  }
  getBrokerageDirectories(value = '', page = 1, isLoadMore = false) {
    this.brokerageDirectoryService
      .GetBrokerageDirectories(page, 10, '', true, true, true, value)
      .subscribe((data) => {
        const tableData = data.data?.map((dataObj) => {
          if (isBroker(dataObj.entity)) {
            return {
              name: dataObj.brokerage.name,
              id: dataObj.id,
              type: dataObj.entity,
            };
          } else if (isBranch(dataObj.entity)) {
            return {
              name: dataObj.brokerageBranch.name,
              id: dataObj.id,
              type: dataObj.entity,
            };
          } else if (isProducer(dataObj.entity)) {
            return {
              name: dataObj.brokerageProducer.name,
              id: dataObj.id,
              type: dataObj.entity,
            };
          } else {
            return {
              name: '',
              id: '',
              type: '',
            };
          }
        });
        if (isLoadMore) {
          this.brokerageBranchProdOptions = [
            ...this.brokerageBranchProdOptions,
            ...tableData,
          ];
        } else {
          this.brokerageBranchProdOptions = tableData;
        }
        this.brokerageDirectorySearch = value;
        this.brokerageDirectorCurrentPage = data.pagination.currentPage;
        this.brokerageDirectoryTotalRecords = data.pagination.totalRecords;
      });
  }
  loadMoreUnderWriter() {
    if (this.underwritersOptions.length < this.underwriterTotalRecords) {
      let page = this.underwriterCurrentPage + 1;
      this.getUnderWriter(this.underwriterSearch, page, true);
    }
  }
  searchUnderWriter(value) {
    if (value.length >= 3) {
      this.getUnderWriter(value);
    } else {
      (this.underwriters = []), (this.underwritersOptions = []);
    }
  }
  getUnderWriter(value = '', page = 1, isLoadMore = false) {
    this.boxxUserService
      .GetUnderwriterlist('', page, 10, '', value, -1, USER_ROLES.Underwriter)
      .subscribe((data) => {
        const tableData = data.data?.map((dataObj) => ({
          name: dataObj.firstName + ' ' + dataObj.lastName,
          id: dataObj.id,
        }));
        let dataObject = data.data.reduce((accumulator, value) => {
          return {
            ...accumulator,
            [value.firstName + ' ' + value.lastName]: value,
          };
        }, {});
        if (isLoadMore) {
          (this.underwriters = { ...this.underwriters, ...dataObject }),
            (this.underwritersOptions = [
              ...this.underwritersOptions,
              ...tableData,
            ]);
        } else {
          this.underwriters = dataObject;
          this.underwritersOptions = tableData;
        }
        this.underwriterSearch = value;
        this.underwriterCurrentPage = data.pagination.currentPage;
        this.underwriterTotalRecords = data.pagination.totalRecords;
      });
  }
  getStatusType(status) {
    return getPolicyStatusType(status);
  }

  handleEffectiveFrom(date) {
    const formattedDate = date
      ? formatDate(new Date(date), this.shortDateFormat)
      : '';
    this.form.controls['effectiveFrm'].setValue(formattedDate);
    this.effectiveHasError = this.isValidDate('effective');
  }

  handleEffectiveTo(date) {
    const value = date ? formatDate(new Date(date), this.shortDateFormat) : '';
    this.form.controls['effectiveTo'].setValue(value);
    this.effectiveHasError = this.isValidDate('effective');
    this.setDateErrMsg('effective');
  }
  handleExpiryFrom(date) {
    const formattedDate = date
      ? formatDate(new Date(date), this.shortDateFormat)
      : '';
    this.form.controls['expiryFrm'].setValue(formattedDate);
    this.expiryHasError = this.isValidDate('expiry');
    this.setDateErrMsg('expiry');
  }

  handleExpiryTo(date) {
    const value = date ? formatDate(new Date(date), this.shortDateFormat) : '';
    this.form.controls['expiryTo'].setValue(value);
    this.expiryHasError = this.isValidDate('expiry');
  }
  isValidDate(e) {
    let dateFromStr = '';
    let dateToStr = '';
    let dateFrom: Date;
    let dateTo: Date;
    switch (e) {
      case 'effective':
        dateFromStr = this.form?.value?.['effectiveFrm'];
        dateToStr = this.form.value?.['effectiveTo'];
        break;
      case 'expiry':
        dateFromStr = this.form?.value?.['expiryFrm'];
        dateToStr = this.form.value?.['expiryTo'];
        break;
    }
    if (dateFromStr === '' || dateToStr === '') {
      return false;
    }

    const dateFromParts = dateFromStr.split('/');
    if (dateFromParts.length !== 3) {
      return false;
    }
    dateFrom = new Date(dateFromStr);

    const dateToParts = dateToStr.split('/');
    if (dateToParts.length !== 3) {
      return false;
    }
    dateTo = new Date(dateToStr);
    return dateFrom > dateTo;
  }
  handleRadioCheck() {
    this.effectiveHasError = false;
    this.expiryHasError = false;
  }

  handleProductsSelect(value) {
    const index = this.pdtOptions.findIndex((docObj) => docObj.value == value);
    this.pdtSelected = [...this.pdtSelected, this.pdtOptions[index].key];
    this.form.controls['products'].setValue(this.pdtSelected);
  }

  handleProductRemove(key) {
    const index = this.pdtOptions.findIndex((docObj) => docObj.key == key);
    this.pdtOptions[index].disabled = false;
    var indexOfKey = this.pdtSelected.indexOf(key);
    if (index !== -1) {
      this.pdtSelected.splice(indexOfKey, 1);
    }
    this.form.controls['products'].setValue(this.pdtSelected);
  }

  clearfilters() {
    this.form.reset();
    this.expiryHasError = false;
    this.effectiveHasError = false;
    this.form.controls['products'].setValue([]);
    this.form.controls['brokerageBranchProd'].setValue([]);
    this.form.controls['effectiveFrm'].setValue('');
    this.form.controls['effectiveTo'].setValue('');
    this.form.controls['expiryTo'].setValue('');
    this.form.controls['expiryFrm'].setValue('');
    this.form.controls['policyPeriod'].setValue('');
    this.form.controls['underwriter'].setValue([]);
    this.form.controls['status'].setValue([]);
    this.form.controls['minLimit'].setValue(MIN_LIMIT);
    this.form.controls['maxLimit'].setValue(MAX_LIMIT);
    this.form.controls['minDeductible'].setValue(MIN_DEDUCTIBLE);
    this.form.controls['maxDeductible'].setValue(MAX_DEDUCTIBLE);
    this.form.controls['minPremium'].setValue(MIN_PREMIUM);
    this.form.controls['maxPremium'].setValue(MAX_PREMIUM);
    this.pdtSelected = [];
    this.handleClearAllFilters.emit(this.form.value);
    this.disableDates = false;
  }
  setDateErrMsg(e) {
    switch (e) {
      case 'effective':
        this.effectiveFrmErrMsg = 'filter.error.policyEffectiveFrmErr';
        this.effectiveToErrMsg = 'filter.error.policyEffectiveToErr';

        break;
      case 'expiry':
        this.expiryFrmErrMsg = 'filter.error.policyExpiryFrmErr';
        this.expiryToErrMsg = 'filter.error.policyExpiryToErr';
        break;
    }
  }

  handleFilter(e) {
    this.formSubmitted = true;
    if (!this.form.valid) {
      return;
    }

    let effectiveFromStr = this.form?.value?.['effectiveFrm'];
    let effectiveToStr = this.form.value?.['effectiveTo'];
    let expiryFromStr = this.form?.value?.['expiryFrm'];
    let expiryToStr = this.form.value?.['expiryTo'];
    if (!(effectiveFromStr == '' && effectiveToStr == '')) {
      if (effectiveFromStr == '' || effectiveToStr == '') {
        this.effectiveHasError = true;
        this.effectiveFrmErrMsg = this.effectiveToErrMsg =
          'filter.error.policyEffectiveErr';
        return;
      }
    }
    if (!(expiryFromStr == '' && expiryToStr == '')) {
      if (expiryFromStr == '' || expiryToStr == '') {
        this.expiryHasError = true;
        this.expiryFrmErrMsg = this.expiryToErrMsg =
          'filter.error.policyExpiryErr';
        return;
      }
    }
    if (this.effectiveHasError || this.expiryHasError) {
      return;
    }

    this.handleFiltering.emit(this.form.value);
  }

  handleBrokerageBranchProd(event) {
    this.form.controls['brokerageBranchProd'].setValue(event);
    this.brokerageBranchProdOptions = [];
  }

  handleUnderwriter(event) {
    this.form.controls['underwriter'].setValue(event);
    this.underwriters = [];
    this.underwritersOptions = [];
  }

  handleStatusSelect(val) {
    const index = this.statusOptions.findIndex((docObj) => docObj.value == val);
    // this.statusOptions[index].disabled = true;
    this.statusSelected = [
      ...this.statusSelected,
      this.statusOptions[index].key,
    ];
    this.form.controls['status'].setValue(this.statusSelected);
  }

  handleStatusRemove(key) {
    const index = this.statusOptions.findIndex((docObj) => docObj.key == key);
    this.statusOptions[index].disabled = false;
    var indexOfKey = this.statusSelected.indexOf(key);
    if (index !== -1) {
      this.statusSelected.splice(indexOfKey, 1);
    }
    this.form.controls['status'].setValue(this.statusSelected);
  }

  getProducts(event) {
    this.pdtOptions.forEach((p) => {
      const index = event.findIndex((docObj) => docObj === p.value);
      p.disabled = index > -1;
    });
    return event;
  }

  getStatuses(event) {
    this.statusOptions.forEach((p) => {
      const index = event.findIndex((docObj) => docObj === p.value);
      p.disabled = index > -1;
    });
    return event;
  }

  private fetchQuoteStatusLists() {
    this.domainsService.GetByDomainCode('POLICYSTATUS').subscribe((data) => {
      let statusList = [];
      data.data.map((dataObj) => {
        if (
          dataObj.description !== 'QUOTE' &&
          dataObj.description !== 'QUOTE-CLOSED'
        ) {
          statusList = [
            ...statusList,
            {
              key: dataObj.description,
              value: dataObj.description,
              seqId: dataObj.seqId,
            },
          ];
        }
      });
      this.statusOptions = statusList;
    });
  }
}
