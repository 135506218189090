import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { Observable, firstValueFrom, take } from 'rxjs';
import { Quote, UISpecificValues } from 'src/app/models/quote.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
  getAlertHead,
  getErrorMessage,
  valInDigits,
} from 'src/app/utils/utils';
import { AlertService } from 'src/app/services/alert.service';

import { RiskRegionService } from 'src/app/services/risk-region.service';
import { getLifecycleState } from 'src/app/store/lifecycle/lifecycle.select';
import {
  getQuoteSelector,
  getUIData,
} from 'src/app/store/create-quote/create-quote.selector';
import { PolicyTRIAUpdate } from 'src/app/entities/policy';
import { PolicyRiskTrxService } from 'src/app/services/policy-risk-trx.service';
import { isSuccessApiStatus } from 'src/app/dashboard/utils/lifecycle-utils';
import { PolicyRiskService } from 'src/app/services/policy-risk.service';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { PolicyQuoteOptionService } from 'src/app/services/policy-quote-option.service';
import { TranslateService } from '@ngx-translate/core';
import { NewQuoteService } from 'src/app/services/new-quote.service';

@Component({
  selector: 'app-quote-selection',
  templateUrl: './quote-selection.component.html',
  styleUrls: ['./quote-selection.component.less'],
})
export class QuoteSelectionComponent implements OnInit {
  [x: string]: any;
  @Input() headerText: string = 'Select to send the quote package or to bind.';
  @Input() policyPeriodId: number = 0;
  @Input() quoteOptions;
  @Input() currency = '';
  @Input() triaAmountDefault = 0;
  @Input() policyFeeDefault = 0;
  @Input() isReadOnly: boolean = false;
  @Input() currentQuoteStatus: string;
  @Input() products = [];
  @Input() selectedQuoteOptions: number[] = [];
  @Input() selectedQuoteOptionsNumbers: number[] = [];
  @Input() selectedQuoteIndex: number = undefined;
  @Input() policyPremium: number;

  @Output() handleQuoteSelected = new EventEmitter<any>();
  @Output() handleComparisonSend = new EventEmitter<any>();
  @Output() sendDropdownDynamicUpdate = new EventEmitter<any>();
  @Output() handleToggleChange = new EventEmitter<any>();
  @Output() handleTriaFeeChange = new EventEmitter<any>();
  @Output() loadPage = new EventEmitter<any>();
  @Output() handleSaveAndReload = new EventEmitter<any>();

  selectedQuoteInfo;
  //currentQuoteStatus: string = '';
  userID: number;
  form$: Observable<Quote>;
  policyFeeForm: FormGroup;
  sortedQuoteOption: Array<any> = [];
  selectedQuoteOptionNumber: number = undefined;
  otherFeeData: Array<any> = [];
  policyFeeEnabled: boolean = false;
  triaEnabled: boolean = false;
  isTRIAAmountEditMode: boolean = false;
  isPolicyFeeEditMode: boolean = false;
  selectedQuoteOptionId: number = 0;
  showErrorAlert = false;
  alertMessage = '';
  policyRiskId: number = null;
  selectedQuoteDetails: { index: any; quote: any };
  quoteOptionsAPI;
  policyId;
  isBindingPolicy: boolean;
  triaAmount;
  policyFee;
  triaAmountCpy;
  policyFeeCpy;
  enableScrollQuotes: boolean = false;
  isTriaPolicyValid: boolean = false;
  servicesAmount;
  taxesPremiumAmount;
  isQuoteOptionUpdating: boolean = false;
  enteredTriaAmount;
  enteredPolicyAmount;
  totalAmount;
  taxesServicesAmount;
  comparisonEnabled: boolean = false;

  constructor(
    private riskRegionService: RiskRegionService,
    private store: Store,
    private localStorageService: LocalStorageService,
    private fb: FormBuilder,
    private policyRiskTrxService: PolicyRiskTrxService,
    private policyRiskService: PolicyRiskService,
    private policyQuoteOptionService: PolicyQuoteOptionService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private newQuoteService: NewQuoteService,
  ) {
    this.policyFeeForm = this.fb.group({
      policyFee: ['0'],
      triaAmount: ['0'],
    });
  }

  async ngOnInit(): Promise<void> {
    this.userID = this.localStorageService.getBoxxUserId();
    this.form$ = this.store.pipe(select(getQuoteSelector));
    this.store
      .select(getUIData)
      .pipe(take(1))
      .subscribe(async (data: any) => {
        this.triaEnabled =
          data?.quote4FlowPremiumDetails?.TRIAFeeEnabled ?? false;
        this.policyFeeEnabled =
          data?.quote4FlowPremiumDetails?.policyFeeEnabled ?? false;
        this.policyFeeForm.controls['policyFee'].setValue(this.policyFee);
        this.policyFeeForm.controls['triaAmount'].setValue(this.triaAmount);
      });

    let quoteOptionList = this.quoteOptions;
    let selectedObject;

    this.form$.subscribe(
      (event) => (
        (this.policyPeriodId = Number(event.ui.policyPeriodId)),
        (this.policyId = Number(event.ui.policyId))
      ),
    );

    const selectedQuoteId = quoteOptionList.filter(
      (quote) => quote.optionSelected === 1,
    )[0]?.id;

    //scroll for quote options
    if (this.quoteOptions.length > 3) {
      this.enableScrollQuotes = true;
    }
    this.sortedQuoteOption = this.quoteOptions?.map(
      (quote: any, index, arr) => {
        let optionSelected = false;

        const policyFee = quote.policyFee ? Number(quote.policyFee) : 0;
        const triaAmount = quote.triaAmount ? Number(quote.triaAmount) : 0;

        const netAmount =
          Number(quote.premium ? quote.premium : 0) +
          Number(quote.servicesAmount ? quote.servicesAmount : 0);
        const taxesAmount =
          Number(quote.taxesServicesAmount ? quote.taxesServicesAmount : 0) +
          Number(quote.taxesPremiumAmount ? quote.taxesPremiumAmount : 0);
        const totalAmount =
          Number(policyFee) +
          Number(netAmount) +
          Number(taxesAmount) +
          Number(triaAmount);

        if (selectedQuoteId === quote.id) {
          this.totalAmount = totalAmount;
          this.servicesAmount = quote.servicesAmount;
          this.taxesServicesAmount = quote.taxesServicesAmount;
          this.taxesPremiumAmount = quote.taxesPremiumAmount;
          optionSelected = true;
          selectedObject = quote;
          // this.selectedQuoteIndex = index;
          this.triaAmount = triaAmount;
          this.policyFee = policyFee;
          this.triaAmountCpy = triaAmount;
          this.policyFeeCpy = policyFee;
          this.policyFeeForm.controls['triaAmount'].setValue(triaAmount);
          this.policyFeeForm.controls['policyFee'].setValue(policyFee);
          this.handleQuoteSelected.emit(selectedObject);
        }

        return {
          id: quote.id,
          quoteOptionId: quote.id,
          policyRiskTrxId: quote.policyRiskTrxId,
          optionNumber: quote.optionNumber,
          totalAmount: totalAmount,
          premium: quote.premium,
          taxesServicesAmount: quote.taxesServicesAmount,
          taxesPremiumAmount: quote.taxesPremiumAmount,
          servicesAmount: quote.servicesAmount,
          triaAmount: quote.triaAmount,
          policyFee: quote.policyFee,
          selectedOption: optionSelected,
          limit: quote.limit,
          deductible: quote.deductible,
        };
      },
    );

    this.quoteSelected(selectedObject, true);
    this.otherFeeData = selectedObject;
  }

  //Quote Selection -----------------------------------------
  async quoteSelected(selectedQuoteOption, isCalledOnLoad = false) {
    this.alertService.clearAlerts(-1);
    if (
      this.selectedQuoteOptions.length === 4 &&
      !this.selectedQuoteOptions.includes(selectedQuoteOption.id) &&
      !isCalledOnLoad
    ) {
      this.alertService.addAlert({
        type: 'info',
        headerText: getAlertHead('info'),
        bodyText: "Can't select more than 4 quote options",
      });
    } else {
      if (
        !this.selectedQuoteOptions.includes(selectedQuoteOption.id) &&
        !isCalledOnLoad
      ) {
        this.selectedQuoteOptions.push(selectedQuoteOption.id);
        this.selectedQuoteOptionsNumbers.push(selectedQuoteOption.optionNumber);
      } else {
        if (this.selectedQuoteOptions.length > 1 && !isCalledOnLoad) {
          this.selectedQuoteOptions.splice(
            this.selectedQuoteOptions.indexOf(selectedQuoteOption.id),
            1,
          );
          this.selectedQuoteOptionsNumbers.splice(
            this.selectedQuoteOptionsNumbers.indexOf(
              selectedQuoteOption.optionNumber,
            ),
            1,
          );
        }
      }

      if (this.selectedQuoteOptions.length === 1 && isCalledOnLoad) {
        let newSelectedQuoteIndex = this.sortedQuoteOption.findIndex(
          (quote) => quote.id === this.selectedQuoteOptions[0],
        );
        if (this.selectedQuoteIndex !== newSelectedQuoteIndex) {
          this.selectedQuoteIndex = newSelectedQuoteIndex;
        }
      }

      !isCalledOnLoad &&
        this.sendDropdownDynamicUpdate.emit({
          selectedQuoteOptionIds: this.selectedQuoteOptions,
          selectedQuoteOptionsNumbers: this.selectedQuoteOptionsNumbers,
        });

      if (this.selectedQuoteOptions.length === 1 && !isCalledOnLoad) {
        let newSelectedQuoteIndex = this.sortedQuoteOption.findIndex(
          (quote) => quote.id === this.selectedQuoteOptions[0],
        );
        this.comparisonEnabled = false;

        if (this.selectedQuoteIndex !== newSelectedQuoteIndex) {
          this.selectedQuoteIndex = newSelectedQuoteIndex;
          let latestSelectedQuoteOption =
            this.sortedQuoteOption[newSelectedQuoteIndex];

          let latestTria = latestSelectedQuoteOption.triaAmount;
          let latestPolicyFee = latestSelectedQuoteOption.policyFee;
          this.triaEnabled = this.isNonZeroValue(+latestTria);
          this.policyFeeEnabled = this.isNonZeroValue(+latestPolicyFee);
          this.triaAmount = latestTria;
          this.triaAmountCpy = latestTria;
          this.policyFee = latestPolicyFee;
          this.policyFeeCpy = latestPolicyFee;
          this.otherFeeData = latestSelectedQuoteOption;
          this.policyFeeForm.controls['triaAmount'].setValue(latestTria);
          this.policyFeeForm.controls['policyFee'].setValue(latestPolicyFee);

          // Update quote selection API call to backend
          this.handleQuoteSelected.emit(latestSelectedQuoteOption);
          this.isQuoteOptionUpdating = true;

          try {
            // Update quote option in the backend as times as products has the quote
            const quoteUpdate = this.policyQuoteOptionService.Update(
              this.selectedQuoteOptions[0],
              { optionSelected: 1 },
            );
            await firstValueFrom(quoteUpdate).then(() => {
              this.isQuoteOptionUpdating = false;
            });
            this.newQuoteService.setPremiumForSendQuote(
              Number(this.policyPremium),
            );
            this.sortedQuoteOption = this.sortedQuoteOption.map(
              (quoteOption, index) => {
                if (quoteOption.id === this.selectedQuoteOptions[0]) {
                  return {
                    ...quoteOption,
                    selectedOption: true,
                  };
                } else {
                  return {
                    ...quoteOption,
                    selectedOption: false,
                  };
                }
              },
            );
            this.alertService.addAlert({
              type: 'success',
              headerText: getAlertHead('success'),
              bodyText: this.translateService
                .instant('quoteCalculator.success.quoteOptionSelect')
                ?.replace('{number}', this.selectedQuoteIndex + 1),
            });
          } catch (error) {
            this.isQuoteOptionUpdating = false;
            if (![500].includes(error?.status)) {
              const alertData = {
                type: 'error',
                headerText: getAlertHead('error'),
                bodyText: getErrorMessage(error),
              };
              this.alertService.addAlert(alertData);
            }
          }
        }
      }
      if (this.selectedQuoteOptions.length > 1) {
        this.comparisonEnabled = true;
      } else {
        this.comparisonEnabled = false;
      }
    }
  }

  isNonZeroValue(value: number): boolean {
    return value > 0;
  }

  sendComparison() {
    this.handleComparisonSend.emit();
  }

  // TRIA Fee-------------------------------------------------
  getTRIA() {
    if (this.triaEnabled) {
      return this.triaAmount || '0';
    }
    return '0';
  }

  async triaToggle() {
    if (this.isBindingPolicy || this.currentQuoteStatus === 'quote-closed') {
      return;
    }
    this.triaEnabled = !this.triaEnabled;
    this.alertService.clearAlerts(-1);
    try {
      this.triaAmount = this.triaAmountDefault;
      this.policyFeeForm.controls['triaAmount'].setValue(this.triaAmount);
      if (
        this.triaEnabled &&
        (this.triaAmountDefault == 0 || Number(this.triaAmountDefault) === 0)
      ) {
        return;
      }
      await this.saveTriaOrPolicyFee();
    } catch (error) {
      if (![500].includes(error?.status)) {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: getErrorMessage(error),
        };
        this.alertService.addAlert(alertData);
      }
      this.triaEnabled = !this.triaEnabled;
    }
  }

  toggleTRIAAmountEdit(event, fromInput = false) {
    let value = valInDigits(event.target.value);

    event.stopPropagation();
    if (this.isBindingPolicy || this.currentQuoteStatus === 'quote-closed') {
      return;
    }
    this.handleTriaFeeChange.emit(true);
    if (this.isTRIAAmountEditMode) {
      if (fromInput) {
        if (!this.isTriaAmountValid(value)) {
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: 'workFlow3.components.quoteSelection.errors.triaAmount',
          };
          this.alertService.addAlert(alertData);
          this.policyFeeForm.controls['triaAmount'].setValue(value);
          this.isTRIAAmountEditMode = true;
          return;
        }
        this.triaAmount = value;
        if (this.triaAmount !== +this.triaAmountCpy)
          this.handleFeeChange(event, false);
        else this.isTRIAAmountEditMode = false;
      }
    } else {
      this.isTRIAAmountEditMode = true;
    }
  }

  isTriaAmountValid(triaAmount) {
    if (
      this.triaEnabled &&
      (!triaAmount ||
        triaAmount === '0' ||
        triaAmount < 1 ||
        triaAmount > valInDigits(this.policyPremium))
    ) {
      return false;
    }
    return true;
  }

  // Policy fee -----------------------------------------------
  getPolicyFee() {
    if (this.policyFeeEnabled) {
      return this.policyFee || '0';
    }
    return '0';
  }

  async togglePolicyFeeEdit(event, fromInput = false) {
    let value = valInDigits(event.target.value);

    event.stopPropagation();
    if (this.isBindingPolicy || this.currentQuoteStatus === 'quote-closed') {
      return;
    }
    this.handleTriaFeeChange.emit(true);
    if (this.isPolicyFeeEditMode) {
      if (fromInput) {
        if (!this.isPolicyFeeValid(value)) {
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: 'workFlow3.components.quoteSelection.errors.policyFee',
          };
          this.alertService.addAlert(alertData);
          this.policyFeeForm.controls['policyFee'].setValue(value);
          this.isPolicyFeeEditMode = true;
          return;
        }
        this.policyFee = value;
        if (this.policyFee !== +this.policyFeeCpy)
          this.handleFeeChange(event, true);
        else this.isPolicyFeeEditMode = false;
      }
    } else {
      this.isPolicyFeeEditMode = true;
    }
  }

  async policyFeeToggle() {
    if (this.isBindingPolicy || this.currentQuoteStatus === 'quote-closed') {
      return;
    }
    this.policyFeeEnabled = !this.policyFeeEnabled;
    this.alertService.clearAlerts(-1);
    try {
      this.policyFee = this.policyFeeDefault;
      this.policyFeeForm.controls['policyFee'].setValue(this.policyFee);
      if (
        this.policyFeeEnabled &&
        (this.policyFeeDefault == 0 || Number(this.policyFeeDefault) === 0)
      ) {
        return;
      }
      if (this.policyFee !== this.policyFeeCpy)
        await this.saveTriaOrPolicyFee();
      else this.isPolicyFeeEditMode = false;
    } catch (error) {
      if (![500].includes(error?.status)) {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: getErrorMessage(error),
        };
        this.alertService.addAlert(alertData);
      }
      this.policyFeeEnabled = !this.policyFeeEnabled;
    }
  }

  isPolicyFeeValid(policyFee) {
    if (
      this.policyFeeEnabled &&
      (!policyFee || policyFee === '0' || policyFee < 1 || policyFee > 100000)
    ) {
      return false;
    }
    return true;
  }

  // Save TRIA or Policy Fee -----------------------------------------
  async saveTriaOrPolicyFee() {
    //
    const bindData: PolicyTRIAUpdate = {
      policyPeriodId: this.policyPeriodId,
      policyFee: this.policyFeeEnabled ? Number(this.policyFee || 0) ?? 0 : 0,
      triaAmount: this.triaEnabled ? Number(this.triaAmount || 0) ?? 0 : 0,
    };

    const updatedInsuranceCharges = this.policyRiskTrxService.PolicyTRIAUpdate(
      this.policyPeriodId,
      bindData,
    );
    const updateAPIResponse = await firstValueFrom(updatedInsuranceCharges);

    this.handleTriaFeeChange.emit(false);
    this.handleSaveAndReload.emit();
  }

  async handleFeeChange(event, isPolicyFeeChange) {
    let newValue = valInDigits(event.target.value);
    let policyFee = 0;
    let triaAmount = 0;
    //TRIA/ Policy Fee validation
    if (!isPolicyFeeChange && !this.isTriaAmountValid(newValue)) {
      this.triaAmount = `${newValue}`;
      this.isTRIAAmountEditMode = true;

      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: 'workFlow3.components.quoteSelection.errors.triaAmount',
      };
      this.alertService.addAlert(alertData);
      this.policyFeeForm.controls['triaAmount'].setValue(newValue);
      return;
    }

    if (isPolicyFeeChange && !this.isPolicyFeeValid(newValue)) {
      this.policyFee = `${newValue}`;
      this.isPolicyFeeEditMode = true;

      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: 'workFlow3.components.quoteSelection.errors.policyFee',
      };
      this.alertService.addAlert(alertData);
      this.policyFeeForm.controls['policyFee'].setValue(newValue);
      return;
    }

    // API
    try {
      let payload: any = {
        policyPeriodId: this.policyPeriodId,
      };
      if (isPolicyFeeChange) {
        policyFee = Number(newValue || 0) ?? 0;
        triaAmount = Number(this.triaAmount || this.otherFeeData['triaAmount']);

        payload = {
          ...payload,
          policyFee: Number(newValue || 0) ?? 0,
          triaAmount: Number(
            this.triaAmount || this.otherFeeData['triaAmount'],
          ),
        };
      } else {
        policyFee = this.policyFee ?? this.otherFeeData['policyFee'];
        triaAmount = Number(newValue || 0) ?? 0;
        payload = {
          ...payload,
          triaAmount: Number(newValue || 0) ?? 0,
          policyFee: Number(this.policyFee ?? this.otherFeeData['policyFee']),
        };
      }
      const editTriaFeeObj = this.policyRiskTrxService
        .PolicyTRIAUpdate(this.policyPeriodId, payload)
        .subscribe({
          next: (response) => {
            this.triaAmount = triaAmount;
            this.policyFee = policyFee;
            this.isTriaPolicyValid = false;
            this.handleTriaFeeChange.emit(false);
            this.handleSaveAndReload.emit();

            if (isSuccessApiStatus(response.status)) {
              const alertData = {
                type: 'success',
                headerText: getAlertHead('success'),
                bodyText:
                  'workFlow3.components.quoteSelection.success.policyUpdated',
              };
              this.alertService.addAlert(alertData);
            } else {
              const alertData = {
                type: 'error',
                headerText: getAlertHead('error'),
                bodyText: 'error.somethingWentWrong',
              };
              this.alertService.addAlert(alertData);
            }
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
          },
        });
    } catch (error) {
      if (![500].includes(error?.status)) {
        const alertData = {
          type: 'success',
          headerText: getAlertHead('success'),
          bodyText: 'error.somethingWentWrong',
        };
        this.alertService.addAlert(alertData);
      }
    }
  }

  valueInDigits(value: any): any {
    if (value) return valInDigits(value);
    else return 0;
  }
}
