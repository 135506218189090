import { Component, Input, OnInit } from '@angular/core';

export enum popOverV2Content {
  SERVICES = 'services',
  COVERAGES = 'coverages',
  BOTH = 'both',
}

@Component({
  selector: 'app-popover-v2',
  templateUrl: './popover-v2.component.html',
  styleUrls: ['./popover-v2.component.less'],
})
export class PopoverV2Component {
  @Input() left: number = 0;
  @Input() top: number = 0;
  @Input() popupContainerStyle: { [klass: string]: any } = {};
  @Input() contentType: popOverV2Content;
  @Input() popupWidth: string;

  constructor() {}

  isServiceContent() {
    return this.contentType === popOverV2Content.SERVICES;
  }
  isCoverageContent() {
    return this.contentType === popOverV2Content.COVERAGES;
  }
}
