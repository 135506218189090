<div class="panel-brokerage-details panel-details-container">
  <boxxds-alert
    *ngIf="showErrorAlert"
    (handleCloseEvent)="handleCloseSuccessEvent()"
    type="error"
    headerText="error!"
    [bodyText]="msgErrorAlert"
  >
  </boxxds-alert>
  <div>
    <div class="form-group-cl-2">
      <boxxds-textinput
        [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
        [form]="form"
        [placeholder]="'Enter full name' | translate"
        [control]="'name'"
        [id]="'name'"
        [width]="'100%'"
        [label]="('Name' | translate) + '*'"
        [isRequired]="true"
        [requiredErrMsg]="requiredErrMsg"
        [invalidErrMsg]="invalidErrMsg"
        [formSubmitted]="formSubmitted"
      >
      </boxxds-textinput>

      <boxxds-textinput
        [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
        [form]="form"
        [placeholder]="'Enter website' | translate"
        [control]="'website'"
        [id]="'website'"
        [width]="'100%'"
        [label]="'Main website' | translate"
        [invalidErrMsg]="invalidErrMsg"
        [formSubmitted]="formSubmitted"
      >
      </boxxds-textinput>
    </div>

    <div class="form-group-cl-2">
      <boxxds-textinput
        [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
        [form]="form"
        [placeholder]="'Enter email address' | translate"
        [control]="'email'"
        [id]="'address'"
        [width]="'100%'"
        [label]="('Main email address' | translate) + '*'"
        [isRequired]="true"
        [requiredErrMsg]="requiredErrMsg"
        [formSubmitted]="formSubmitted"
        [invalidErrMsg]="'Please enter valid email'"
      >
      </boxxds-textinput>

      <boxxds-textinput
        [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
        [form]="form"
        [placeholder]="'brokerage.placeholder.emailDomains' | translate"
        [control]="'domains'"
        [id]="'domains'"
        [width]="'100%'"
        [label]="('brokerage.label.otherDomains' | translate) + '*'"
        [isRequired]="true"
        [requiredErrMsg]="requiredErrMsg"
        [invalidErrMsg]="invalidErrMsg"
        [formSubmitted]="formSubmitted"
        maxLength="250"
      >
      </boxxds-textinput>
    </div>

    <div class="form-group-cl-2">
      <boxxds-textinput
        [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
        [form]="form"
        [mask]="'(000) 000-0000'"
        [placeholder]="'Enter phone number' | translate"
        [control]="'phoneNumber'"
        [id]="'phoneNumber'"
        [width]="'100%'"
        [label]="'Phone number' | translate"
        [isRequired]="false"
        [requiredErrMsg]="requiredErrMsg"
        [invalidErrMsg]="invalidErrMsg"
        [formSubmitted]="formSubmitted"
      >
      </boxxds-textinput>

      <boxxds-searchable-dropdown
        [label]="'Assign Parent Brokerage/Company' | translate"
        [form]="form"
        [control]="'parent'"
        [requiredErrMsg]="requiredErrMsg"
        [formSubmitted]="formSubmitted"
        [placeholder]="'Enter name' | translate"
        [options]="brokerageParents"
        [dispatchTextInputChange]="true"
        (textInputChangeEventEmmiter)="handleTextInputChange($event)"
        (currentValueChange)="handleBusinessSector($event)"
        [btnStyle]="{ height: '36px' }"
        [hideSelectedValue]="false"
        [currentValue]="parentInitValue"
        [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
        [invalidErrMsg]="parentCompanyInvalidErrMsg"
      >
      </boxxds-searchable-dropdown>
    </div>
  </div>

  <div class="image-logo-container">
    <app-image-logo
      [title]="'Brokerage'"
      [isActive]="this.permissionList[this.currentScreen] && isActive"
      (handleImageChange)="
        this.permissionList[this.currentScreen] && handleImageChange($event)
      "
      (triggerProfilePicError)="triggerProfilePicError($event)"
      [croppedImage]="logoProfile"
      [previousImage]="logoProfile"
      (clickImageLogoClose)="
        this.permissionList[this.currentScreen] && handleImageLogoClose($event)
      "
    ></app-image-logo>
  </div>
</div>
