import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-main-contact',
  templateUrl: './main-contact.component.html',
  styleUrls: ['./main-contact.component.less'],
})
export class MainContactComponent {
  @Input() form: FormGroup;
  @Input() direction?: string = 'row' || 'column';

  constructor() {}
}
