<boxxds-body
  sz="md"
  [text]="'Location details' | translate"
  [customStyle]="{
    color: '#616162',
    marginBottom: '8px',
    'text-align': 'left',
    marginTop: '40px'
  }"
>
</boxxds-body>

<div class="form-inputs locations-detail">
  <div class="spinner-holder" *ngIf="!isLoaded && allowLoader">
    <div class="body-spinner-location">
      <div class="spinner-body-wrapper">
        <boxxds-spinner
          [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
        ></boxxds-spinner>
      </div>
    </div>
  </div>
  <ng-container>
    <boxxds-search-autocomplete
      [maxLength]="'50'"
      [form]="form"
      [isActive]="isActive"
      [formSubmitted]="formSubmitted"
      [requiredErrMsg]="requiredErrMsg"
      [items]="locations"
      [label]="'location.label.addressLine1' | translate"
      [required]="true"
      [control]="'address'"
      [placeholder]="'location.label.searchForLocation' | translate"
      [autocapitalize]="'off'"
      [spellcheck]="'off'"
      [permissionStatus]="this.permissionList[this.currentScreen]"
      [containerStyle]="{ width: '376px' }"
      (searchQuery)="getPlaces($event)"
      (itemSelected)="selectPlaceById($event)"
    ></boxxds-search-autocomplete>

    <boxxds-textinput
      [form]="form"
      [placeholder]="'Enter apartment/suite/building number' | translate"
      [control]="'addressLine2'"
      [id]="'address'"
      [width]="'100%'"
      [containerStyle]="{ maxWidth: '300px' }"
      [label]="'location.label.addressLine2(optional)' | translate"
      class="input-fields"
      class="address2"
      maxLength="10"
      [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
      [formSubmitted]="formSubmitted"
    >
    </boxxds-textinput>

    <boxxds-textinput
      [form]="form"
      [placeholder]="'location.placeholder.city' | translate"
      [control]="'city'"
      [id]="'city'"
      [width]="'100%'"
      [containerStyle]="{ maxWidth: '280px' }"
      [label]="('City' | translate) + '*'"
      class="input-fields"
      [requiredErrMsg]="requiredErrMsg"
      [formSubmitted]="formSubmitted"
      class="city"
      maxLength="25"
      [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
    >
    </boxxds-textinput>
    <boxxds-textinput
      [form]="form"
      [control]="'country'"
      [placeholder]="'Select' | translate"
      [label]="('Country' | translate) + '*'"
      maxLength="250"
      class="input-fields"
      [requiredErrMsg]="requiredErrMsg"
      [formSubmitted]="formSubmitted"
      class="country"
      [readonly]="true"
      [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
    >
    </boxxds-textinput>

    <boxxds-dropdown
      [form]="form"
      [control]="'province'"
      [placeholder]="'Select' | translate"
      (currentValueChange)="
        this.permissionList[this.currentScreen] && handleProvinceChange($event)
      "
      [options]="provinceOrStateList"
      [label]="(province ?? '' | translate) + '*'"
      [btnStyle]="{ marginTop: '8px', maxWidth: '186px' }"
      class="input-fields"
      [requiredErrMsg]="requiredErrMsg"
      [formSubmitted]="formSubmitted"
      class="province"
      [currentValue]="form?.value?.province"
      [disabled]="!this.permissionList[this.currentScreen] || !isActive"
      (loadMoreOptions)="
        this.permissionList[this.currentScreen] && loadMoreRegion($event)
      "
      [hasScrollablePagination]="true"
      [totalCount]="regionTotalRecords"
      [totalPages]="regionTotalPages"
    >
    </boxxds-dropdown>

    <boxxds-textinput
      [form]="form"
      [placeholder]="
        ('common.enter' | translate) + ' ' + (zip ? zip.toLowerCase() : '')
      "
      [control]="'zipPostalCode'"
      [id]="'postalCode'"
      [width]="'100%'"
      [containerStyle]="{ maxWidth: '186px' }"
      [label]="(zip ?? '' | translate) + '*'"
      class="input-fields"
      [customErrMsg]="'common.invalidErrorMessage' | translate"
      [requiredErrMsg]="requiredErrMsg"
      [formSubmitted]="formSubmitted"
      class="postal-code"
      maxLength="10"
      [mask]="mask"
      [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
      [dropSpecialCharacters]="false"
    >
    </boxxds-textinput>
  </ng-container>
</div>
