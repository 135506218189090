import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { firstValueFrom, Observable, Subject, take, takeUntil } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Quote, UISpecificValues } from 'src/app/models/quote.model';
import {
  getQuoteSelector,
  getRiskQuestions,
} from 'src/app/store/create-quote/create-quote.selector';
import { TransactionRiskActions } from '../../constants/lifecycle-actions';
import { PolicyDeclineRequest } from 'src/app/entities/policy-lifecycle';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import { PolicyAtomicTrxService } from 'src/app/services/policy-atomic.service';
import { PolicyDashboardService } from 'src/app/services/policy-dashboard.service';
import { InsuredService } from 'src/app/services/insured.service';
import { LocationService } from 'src/app/services/location.service';
import { RiskQuestionService } from 'src/app/services/risk-question.service';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import { LanguageService } from 'src/app/services/language.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { PolicyPeriodService } from 'src/app/services/policy-period.service';
import { PolicyRiskService } from 'src/app/services/policy-risk.service';
import { patternValidator, getErrorMessage } from 'src/app/utils/utils';
import { PolicyQuoteOptionService } from 'src/app/services/policy-quote-option.service';
import { PolicyAnswerService } from 'src/app/services/policy-answer.service';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { RiskRatingCalculateService } from 'src/app/services/risk-rating-calculate.service';
import { PolicyRiskTrxService } from 'src/app/services/policy-risk-trx.service';
import { NewQuoteService } from 'src/app/services/new-quote.service';
import { PolicyLifecycleService } from 'src/app/services/policy-lifecycle-service';
import { Validators } from 'ngx-editor';
import { TranslateService } from '@ngx-translate/core';
import {
  getCityTime,
  getFormattedDateTime,
  getDateFormatForDatePicker,
  getFormatDate,
  formatDate,
} from 'src/app/utils/formatDate';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { getLifecycleState } from 'src/app/store/lifecycle/lifecycle.select';
import {
  EFFECTIVE_DATE_MAX_RANGE_LIMIT,
  STANDALONE_SELLABLE_TRUE,
} from 'src/app/constants/quote-constant';
import { PolicyService } from 'src/app/services/policy.service';
import { BrokerageBranchService } from 'src/app/services/brokerage-branch.service';
import { updatePolicySlideoutData } from 'src/app/store/lifecycle/lifecycle.action';
import { AlertService } from 'src/app/services/alert.service';
import { USER_ROLES } from 'src/app/constants/security/systemUserType';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.less'],
})
export class ProductFormComponent implements OnInit, OnDestroy {
  coverageSelectedIds: { [productId: string]: any } = {};
  updatedCoverageList: { [productId: string]: any } = {};
  private destroy$ = new Subject<void>();

  @Output() coverageList = new EventEmitter<any>();
  @Output() productId = new EventEmitter<any>();

  public transactionRiskActions = TransactionRiskActions;

  form$: Observable<Quote>;
  riskCategoryForm: FormGroup;
  insuredData = {};
  riskForm: FormGroup<any>;
  subRiskQuestionForm;
  endDateEditable: boolean = false;
  minStartDate;
  maxStartDate;
  endDate;
  minEndDate;
  maxEndDate;
  isStartDateValid = true;
  isEndDateValid = true;
  showActionPopup: boolean = false;
  errorMessage: string = '';
  policyPeriodId: any;
  startDateErrMsg;
  endDateErrMsg;
  underWriters: any;
  underWriterOptions: any = [];
  underwriterTotalCount = 0;
  underwriterTotalPages = 1;
  policy: any;
  uiData: UISpecificValues;
  showSpinner: boolean = false;
  questions = [];
  showErrAlert: boolean = false;
  alertErrMsg: string = 'An error occurred in loading risk questions.';
  showSuccessAlert: boolean = false;
  alertSuccessMsg: string = '';
  eventData: any;
  boxxUserId;
  showBodySpinner: boolean = false;
  notificationAlert: {
    show?: boolean;
    bodyText?: string;
    type?: string;
    headerText?: string;
  } = { show: false };
  policyRisks = null;
  riskQuestionSubscription;
  isNavigatedFromQuoteCalc = false;
  selectedProductList;
  selectedProductStandaloneOptions = [];
  quoteId: any;
  formSubmitted = false;
  validateRiskAnswers = false;
  isContinue: boolean = false;
  categoryProperties: {
    [key: string]: { checkAllYesBtn: boolean };
  } = {};
  riskQuestionsRef;
  categoryExcluded: boolean = false;
  excludedCategories = [];
  excludedCategoriesIds = [];

  actionPopupDetails: {
    quoteNumber: string;
    effectiveDate: string;
    insured: string;
    brokerage: string;
    branch: string;
    reasonOptions: any[];
    action: TransactionRiskActions;
    statusType: string;
  };
  initialFormValue: any = {};
  showRecalcConfModal: boolean = false;
  insuredTimezone;
  insuredlocation;
  currentDateTimezone;
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  currentQuoteStatus: string = '';
  quoteFlowSteps;
  shortDateFormat: string = '';
  longDateFormat: string = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private datePipe: DatePipe,
    public boxxUserService: BoxxUserService,
    private policyAnswerService: PolicyAnswerService,
    private policyAtomicTrxService: PolicyAtomicTrxService,
    private policyDashboardService: PolicyDashboardService,
    private insuredService: InsuredService,
    private fb: FormBuilder,
    public locationService: LocationService,
    public riskQuestionService: RiskQuestionService,
    private riskRegionService: RiskRegionService,
    private languageService: LanguageService,
    private policyPeriodService: PolicyPeriodService,
    private policyRiskService: PolicyRiskService,
    private policyQuoteOptionService: PolicyQuoteOptionService,
    private localStorageService: LocalStorageService,
    private riskRatingCalculateService: RiskRatingCalculateService,
    private policyRiskTrxService: PolicyRiskTrxService,
    private newQuoteService: NewQuoteService,
    private policyLifecycleService: PolicyLifecycleService,
    private translate: TranslateService,
    private policyService: PolicyService,
    private brokerageBranchService: BrokerageBranchService,
    private alertService: AlertService,
  ) {
    this.riskForm = this.fb.group({});
    this.riskCategoryForm = this.fb.group({});
  }

  async ngOnInit(): Promise<void> {
    this.showBodySpinner = true;
    this.quoteFlowSteps = this.localStorageService.getQuoteFlowSteps();
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    this.store.pipe(select(getLifecycleState)).subscribe((data) => {
      this.currentQuoteStatus = data.quoteStatus;
    });

    const quoteID = this.activatedRoute.snapshot.paramMap.get('id');
    if (quoteID) {
      await this.getPolicyData(quoteID);
    }

    let insuredId,
      regionId,
      versionId = 1,
      languageId = 1,
      products = [],
      isInsuredTypeCompany,
      riskQuestions = {},
      startDate,
      policyPeriodId,
      insuredName,
      branch = '',
      endDate;
    this.form$ = this.store.select(getQuoteSelector);
    this.boxxUserId = this.localStorageService.getBoxxUserId();

    this.form$.pipe(take(1)).subscribe(async (event) => {
      ({
        insuredId,
        isInsuredTypeCompany,
        regionId,
        policyPeriodId,
        insuredName,
      } = event.ui);
      ({ products, startDate, endDate } = event);
      this.uiData = event.ui;
      this.quoteId = this.uiData.policyId;
      this.eventData = event;
      this.isNavigatedFromQuoteCalc = event.ui.isNavigatedFromQuoteCalc;

      this.selectedProductList = event.products.filter(
        (obj) => obj.checked === true,
      );
      this.selectedProductStandaloneOptions =
        this.getSelectedPdtStandaloneOptions(products);

      if (!this.insuredTimezone) {
        await this.insuredService
          .GetByInsuredId(event.ui?.insuredId)
          .subscribe({
            next: async (data) => {
              this.insuredTimezone =
                data.data.insuredLocation.timeZoneCode ?? 'America/New_York';
              this.store.dispatch(
                new CreateQuoteAction.UpdateInsuredAction({
                  timezone:
                    data.data?.insuredLocation.timeZoneCode ??
                    'America/New_York',
                }),
              );
            },
          });
      }
      this.form$.subscribe(
        (event) => (this.insuredTimezone = event.ui?.timezone),
      );

      this.initialFormValue['startDate'] = event.startDate;
      this.initialFormValue['endDate'] = event.endDate;
      this.initialFormValue['products'] = products.map((prod) => ({
        key: prod.key,
        underwriter: prod.underwriter,
        underwriterId: prod.underwriterId,
        value: prod.value,
      }));
    });

    this.riskQuestionSubscription = this.store
      .select(getRiskQuestions)
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        riskQuestions = event;

        let defaultStartDate;
        let defaultEndDate;
        let currentDate = getCityTime(this.insuredTimezone);
        this.currentDateTimezone = currentDate;

        if (!startDate) {
          defaultStartDate = currentDate.currentDate ?? this.getTomorrow();
          if (currentDate.currentDate) {
            this.store.dispatch(
              new CreateQuoteAction.UpdateStartDateAction(defaultStartDate),
            );
          } else {
            this.store.dispatch(
              new CreateQuoteAction.UpdateStartDateAction(
                this.getFormattedDate(defaultStartDate),
              ),
            );
          }
        } else {
          defaultStartDate = new Date(startDate);
        }
        if (!endDate) {
          // TODO check new date it will fail if user login from different location
          defaultEndDate =
            currentDate.oneYearLater ??
            this.datePipe.transform(
              new Date(new Date().getTime() + 24 * 60 * 60 * 1000).setFullYear(
                new Date(
                  new Date().getTime() + 24 * 60 * 60 * 1000,
                ).getFullYear() + 1,
              ),
              this.shortDateFormat,
            );
          this.store.dispatch(
            new CreateQuoteAction.UpdateEndDateAction(defaultEndDate),
          );
        } else {
          defaultEndDate = new Date(endDate);
        }
        if (
          currentDate.eightMonthsLater &&
          currentDate.endDate &&
          currentDate.thirtyDaysAgo &&
          currentDate.thirtyDaysLater
        ) {
          this.minStartDate = currentDate.thirtyDaysAgo;
          this.maxStartDate = currentDate.thirtyDaysLater;
          this.minEndDate = currentDate.eightMonthsLater;
          this.maxEndDate = currentDate.endDate;
        } else {
          this.minStartDate = new Date();
          this.maxStartDate = new Date();
          this.minStartDate.setDate(this.minStartDate.getDate() - 30);
          this.maxStartDate.setDate(
            this.maxStartDate.getDate() + EFFECTIVE_DATE_MAX_RANGE_LIMIT,
          );

          this.minEndDate = new Date(defaultStartDate);
          this.maxEndDate = new Date(defaultStartDate);
          this.minEndDate.setMonth(defaultStartDate.getMonth() + 8);
          this.maxEndDate.setMonth(defaultStartDate.getMonth() + 18);
        }
        const selectedProducts = products.filter((obj) => obj.checked === true);
        this.loadRiskQuestions(
          riskQuestions,
          selectedProducts,
          isInsuredTypeCompany,
          versionId,
          languageId,
          insuredId,
          regionId,
        );
        this.initialFormValue.riskQuestions = {};
        this.initialFormValue.riskCategory = {};
        if (Object.keys(riskQuestions).length > 0) {
          Object.keys(riskQuestions).forEach((product) => {
            const numberAnswerQuestions = riskQuestions[product].filter(
              (riskQuestion) =>
                riskQuestion?.dataType?.description?.toLowerCase() == 'numeric',
            );
            this.initialFormValue.riskQuestions[product] = riskQuestions[
              product
            ].map(({ id, category, value }) => ({ id, category, value }));
            this.riskQuestionsRef = riskQuestions;
            // TODO - update the below logic later to use mandatory field from backend for each question
            const mandatoryQuestions = riskQuestions[product].filter(
              (riskQuestion) => riskQuestion?.active,
            );
            this.riskForm.addControl(product, new FormArray([]));
            const formattedRiskQnArr = riskQuestions[product].map((risk) => ({
              [risk.id]: risk.value,
            }));
            const formCntrls = formattedRiskQnArr.reduce(
              (r, c) => ({ ...r, ...c }),
              {},
            );
            let pdtFormCntrl = this.riskForm.controls[product] as FormArray;
            pdtFormCntrl.push(this.fb.group(formCntrls));
            if (pdtFormCntrl.length > 1) {
              for (let i in Array.from(
                { length: pdtFormCntrl.length },
                (v, i) => i,
              )) {
                if (i != '0') {
                  pdtFormCntrl.removeAt(Number(i));
                }
              }
            }
            //All no or All yes radio input in category wise for each product
            this.riskCategoryForm.addControl(product, new FormArray([]));

            const categoryControlArray = this.getCategories(
              riskQuestions[product],
            ).map((category) => ({
              [category]: null,
            }));
            const categoryControls = categoryControlArray.reduce(
              (r, c) => ({ ...r, ...c }),
              {},
            );
            this.initialFormValue.riskCategory[product] = categoryControlArray;
            let pdtCategoryCntrls = this.riskCategoryForm.controls[
              product
            ] as FormArray;
            pdtCategoryCntrls.push(this.fb.group(categoryControls));

            this.subRiskQuestionForm = this.riskForm.get(`${product}.0`);
            if (mandatoryQuestions.length > 0) {
              this.updateMandatoryQuestionsValidation(
                mandatoryQuestions,
                product,
              );
            }
            if (numberAnswerQuestions.length > 0) {
              this.updateNumericQuestionsValidation(
                numberAnswerQuestions,
                product,
              );
            }
          });
        }
        if (!this.uiData.currentProductDisplayed) {
          this.store.dispatch(
            new CreateQuoteAction.UpdateCurrentproductAction(
              selectedProducts[0]?.value,
            ),
          );
        }
      });

    this.loadGetAllByPolicyPeriodId(this.uiData.policyPeriodId);
    this.populateUnderwriterOptions();
  }

  private clearAndSetValidators(control, validators) {
    control.clearValidators();
    control.setValidators(validators);
    control.updateValueAndValidity();
  }

  private updateNumericQuestionsValidation(numericQuestions, product) {
    numericQuestions.forEach((question) => {
      const questionControl = this.riskForm.get(`${product}.0.${question.id}`);
      const numericPattern = /^(?:[1-8]\d{0,6}|9000000|[9]\d{0,5})$/;

      this.clearAndSetValidators(questionControl, [
        Validators.required,
        patternValidator(numericPattern),
      ]);
    });
  }

  private updateMandatoryQuestionsValidation(numericQuestions, product) {
    numericQuestions.forEach((question) => {
      const questionControl = this.riskForm.get(`${product}.0.${question.id}`);
      this.clearAndSetValidators(questionControl, [Validators.required]);
    });
  }

  private loadRiskQuestions(
    riskQuestions: {},
    selectedProducts: any[],
    isInsuredTypeCompany,
    versionId: number,
    languageId: number,
    insuredId,
    regionId,
  ) {
    const riskQuestionsKey = Object.keys(riskQuestions);

    if (
      riskQuestionsKey?.length <= 0 ||
      (riskQuestionsKey?.length > 0 &&
        !selectedProducts.every((product) =>
          riskQuestionsKey.includes(product?.value),
        ))
    ) {
      if (isInsuredTypeCompany) {
        versionId = 2;
      }

      const riskQnsNotPresentFor = selectedProducts.filter(
        (product) => !riskQuestionsKey.includes(product?.value),
      );
      if (riskQnsNotPresentFor?.length > 0) {
        riskQnsNotPresentFor.forEach((productObj) => {
          this.riskRegionService
            .getRiskRegionAndVersion(regionId, versionId)
            .subscribe((data) => {
              const riskRegionId = data?.data[0].id;
              this.store.dispatch(
                CreateQuoteAction.loadRiskQuestions({
                  userId: this.boxxUserId,
                  regionId: riskRegionId,
                  languageId,
                  insuredId,
                  product: productObj?.value,
                  limit: 500,
                }),
              );
            });
        });
      }
    }
    return versionId;
  }

  private loadGetAllByPolicyPeriodId(policyPeriodId) {
    this.policyRiskService
      .GetAllByPolicyPeriodId(policyPeriodId)
      .subscribe((data) => {
        if (data?.data[0]) {
          this.policyRisks = data?.data;
          this.store.dispatch(
            new CreateQuoteAction.setPolicyPeriodId(
              this.policyRisks[0].policyPeriodId,
            ),
          );
          this.store.dispatch(
            new CreateQuoteAction.UpdateInsuredAction({
              policyPeriodId: this.policyRisks[0].policyPeriodId,
            }),
          );
        }
      });
  }

  getProductFormArray(value) {
    return this.riskForm.get(`${value}.0`) as FormGroup;
  }
  getCategoryProductFormArray(value) {
    return this.riskCategoryForm.get(`${value}.0`) as FormGroup;
  }

  loadMoreUnderwriters(pageNumber) {
    if (this.underwriterTotalCount > this.underWriterOptions?.length) {
      this.populateUnderwriterOptions(pageNumber);
    }
  }

  populateUnderwriterOptions(page = 1) {
    const sort = 'firstName:asc,lastName:asc';
    const systemUserState = 1;
    this.boxxUserService
      .GetUnderwriterlist(
        '',
        page,
        10,
        sort,
        '',
        1,
        USER_ROLES.Underwriter,
        systemUserState,
      )
      .subscribe((data) => {
        const tableData = data.data?.map((dataObj) => ({
          key: dataObj.firstName + ' ' + dataObj.lastName,
          value: dataObj.firstName + ' ' + dataObj.lastName,
          id: dataObj.id,
        }));
        this.underWriters = {
          ...this.underWriters,
          ...data.data.reduce((accumulator, value) => {
            return {
              ...accumulator,
              [value.firstName + ' ' + value.lastName]: value,
            };
          }, {}),
        };
        this.underwriterTotalCount = data?.pagination?.totalRecords;
        this.underwriterTotalPages = data?.pagination?.totalPages;
        this.underWriterOptions = [...this.underWriterOptions, ...tableData];
        this.showBodySpinner = false;
      });
  }

  getInitialRskFrmVal(riskQnId) {}

  getFormName(riskArr, id) {
    return 0;
  }

  getTomorrow() {
    let currentDate = new Date();
    return new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
  }

  isColumnLayout(explanation) {
    return explanation.split('<br>').length > 1;
  }

  getExplanationArr(explanation) {
    if (explanation.split('<br>').length > 1) {
      // <br> for grid styling
      return explanation.split('<br>');
    } else {
      // <li> for bullet point styling
      return explanation.split('<li>');
    }
  }
  getFormattedDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return day + '/' + month + '/' + year;
  }

  switchProduct(pdtList, pdtValue, isDelete = false) {
    const index = pdtList.findIndex((obj) => obj.value == pdtValue);
    this.store.dispatch(new CreateQuoteAction.UpdateProductsAction(index));
    if (isDelete) {
      this.store.dispatch(new CreateQuoteAction.UpdateCurrentproductAction(''));
    }
  }

  getProductsToSelect(products) {
    return products.filter((pdtObj) => !pdtObj.checked && pdtObj.active);
  }

  underwriterChanged(underwriter, products, productVal) {
    let index = products.findIndex((obj) => obj.value == productVal);
    const underwriterId =
      this.underWriters[underwriter]?.id ?? products[index]?.underwriterId;
    this.store.dispatch(
      new CreateQuoteAction.UpdateUnderwriterForPdtAction({
        index,
        underwriter,
        underwriterId,
      }),
    );
  }

  getCurrentUnderWriter(products, productVal) {
    return products.filter((obj) => obj.value == productVal)[0].underwriter;
  }

  getCategories(riskQuestions) {
    let filteredQuestions = riskQuestions;
    if (this.categoryExcluded) {
      filteredQuestions = riskQuestions.filter((questionObj) => {
        const category = questionObj.category
          ?.toLowerCase()
          .replace(/.*\./, '')
          .replace(/\s/g, '');
        return !this.excludedCategories.includes(category);
      });
    }
    return filteredQuestions
      .map((questionObj) => questionObj.category?.toLowerCase())
      .filter((val, idx, arr) => arr.indexOf(val) === idx);
  }

  getQuestions(category, riskQuestions) {
    return riskQuestions.filter(
      (qObj) => qObj.category?.toLowerCase() === category.toLowerCase(),
    );
  }

  handleRadioCheck(product, category: any, categoryNo) {
    if (!this.categoryProperties.hasOwnProperty(product + categoryNo)) {
      this.categoryProperties[product + categoryNo] = {
        checkAllYesBtn: null,
      };
    } else {
      this.categoryProperties[product + categoryNo].checkAllYesBtn = false;

      let riskCategoryFormGroup = (
        this.riskCategoryForm.get(product) as FormArray
      ).at(0) as FormGroup;
      riskCategoryFormGroup.controls[`${category}`].setValue(null);
    }
  }

  checkAllYes(product, category, categoryNo, questions: any) {
    let pdtFormCntrlss = (this.riskForm.get(product) as FormArray).at(
      0,
    ) as FormGroup;

    for (let question of questions) {
      if (pdtFormCntrlss.contains(question.id)) {
        pdtFormCntrlss.controls[`${question.id}`].setValue('true');
      }
    }
    let riskCategoryFormGroup = (
      this.riskCategoryForm.get(product) as FormArray
    ).at(0) as FormGroup;
    riskCategoryFormGroup.controls[`${category}`].setValue('true');

    if (!this.categoryProperties.hasOwnProperty(product + categoryNo)) {
      this.categoryProperties[product + categoryNo] = {
        checkAllYesBtn: true,
      };
    } else {
      this.categoryProperties[product + categoryNo].checkAllYesBtn = true;
    }
  }

  checkAllNo(
    product,
    category,
    categoryNo,
    questions: any,
    value: any = 'false',
  ) {
    let pdtFormCntrlss = (this.riskForm.get(product) as FormArray).at(
      0,
    ) as FormGroup;

    for (let question of questions) {
      if (pdtFormCntrlss.contains(question.id)) {
        pdtFormCntrlss.controls[`${question.id}`].setValue('false');
      }
    }
    let riskCategoryFormGroup = (
      this.riskCategoryForm.get(product) as FormArray
    ).at(0) as FormGroup;
    riskCategoryFormGroup.controls[`${category}`].setValue(value);
    if (!this.categoryProperties.hasOwnProperty(product + categoryNo)) {
      this.categoryProperties[product + categoryNo] = {
        checkAllYesBtn: false,
      };
    } else {
      this.categoryProperties[product + categoryNo].checkAllYesBtn = false;
    }
  }

  checkIfStartDateValid(date) {
    let getStartDate = getCityTime(this.insuredTimezone);

    let upperLimit, lowerLimit;
    if (getStartDate.eightMonthsLater && getStartDate.endDate) {
      upperLimit = getStartDate.thirtyDaysLater;
      lowerLimit = getStartDate.thirtyDaysAgo;
    } else {
      upperLimit = new Date().getTime() + 60 * 24 * 60 * 60 * 1000;
      lowerLimit = new Date().getTime() - 30 * 24 * 60 * 60 * 1000;
    }
    if (!date) {
      this.isStartDateValid = false;
      this.startDateErrMsg = 'error.thisFieldIsRequired';
    } else if (date > upperLimit) {
      this.isStartDateValid = false;

      this.startDateErrMsg = 'error.policyMaxEffectiveErrorMessage';
    } else if (date < lowerLimit) {
      this.isStartDateValid = false;

      this.startDateErrMsg = 'error.policyMinEffectiveDateErrorMessage';
    } else {
      this.isStartDateValid = true;
      this.startDateErrMsg = null;
    }
  }

  handleStartDateChange(date, endDate) {
    this.checkIfStartDateValid(date);

    this.minEndDate = date ? new Date(date) : new Date();
    this.maxEndDate = date ? new Date(date) : new Date();

    this.minEndDate.setMonth(this.minEndDate.getMonth() + 8);
    this.maxEndDate.setMonth(this.maxEndDate.getMonth() + 18);

    this.checkIfEndDateValid(endDate, date);
    if (date) {
      const formattedStartDate = this.datePipe.transform(
        new Date(date),
        this.shortDateFormat,
      );
      this.store.dispatch(
        new CreateQuoteAction.UpdateStartDateAction(formattedStartDate),
      );
      const formattedEndDate = this.datePipe.transform(
        new Date(date).setFullYear(date.getFullYear() + 1),
        this.shortDateFormat,
      );
      this.store.dispatch(
        new CreateQuoteAction.UpdateEndDateAction(formattedEndDate),
      );
      this.isEndDateValid = true;
      this.endDateErrMsg = null;
    } else {
      this.store.dispatch(new CreateQuoteAction.UpdateStartDateAction(null));
    }
  }

  checkIfEndDateValid(date, startDate) {
    let getStartDate = getCityTime(this.insuredTimezone);
    let upperLimit, lowerLimit;
    if (!startDate) {
      startDate = getStartDate.currentDate ?? this.getTomorrow();
    }
    if (getStartDate.eightMonthsLater && getStartDate.endDate && !date) {
      upperLimit = getStartDate.endDate;
      lowerLimit = getStartDate.eightMonthsLater;
    } else {
      upperLimit = new Date(startDate);
      lowerLimit = new Date(startDate);
      lowerLimit.setMonth(startDate.getMonth() + 8);
      upperLimit.setMonth(startDate.getMonth() + 18);
    }

    if (!date) {
      this.isEndDateValid = false;
      this.endDateErrMsg = 'error.thisFieldIsRequired';
    } else if (date > upperLimit) {
      this.isEndDateValid = false;

      this.endDateErrMsg = 'error.policyMaxExpiryDateErrorMessage';
    } else if (date < lowerLimit) {
      this.isEndDateValid = false;

      this.endDateErrMsg = 'error.policyMinExpiryDateErrorMessage';
    } else {
      this.isEndDateValid = true;
      this.endDateErrMsg = null;
    }
  }

  handleEndDateChange(endDate, startDate) {
    if (startDate) {
      const startDateParts = startDate.split('/');
      startDate = new Date(startDate);
    } else {
      startDate = new Date();
    }

    this.checkIfEndDateValid(endDate, startDate);
    if (endDate) {
      const formattedEndDate = formatDate(
        new Date(endDate),
        this.shortDateFormat,
      );
      this.store.dispatch(
        new CreateQuoteAction.UpdateEndDateAction(formattedEndDate),
      );
    } else {
      this.store.dispatch(new CreateQuoteAction.UpdateEndDateAction(null));
    }
  }

  setCurentAsActive(productVal) {
    this.store.dispatch(
      new CreateQuoteAction.UpdateCurrentproductAction(productVal),
    );
  }

  handleBackBtn() {
    if (this.quoteFlowSteps === '4') {
      this.router.navigate(['/dashboard/quote4flow/new'], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
    } else if (this.quoteFlowSteps === 'workflow3') {
      this.router.navigate(['/dashboard/workflow3/new'], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
      return;
    } else {
      this.router.navigate(['/dashboard/quote/new/product'], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
    }
  }

  isFormValid(form) {
    if (
      form.products.findIndex((obj) => obj.checked === true) !== -1 &&
      this.isStartDateValid &&
      this.isEndDateValid &&
      this.riskForm.status == 'VALID'
    ) {
      const selectedProducts = form.products.filter(
        (obj) => obj.checked === true,
      );
      const selectedQuestions = selectedProducts.filter(
        (obj) => obj.checked !== '',
      );
      const selectedUnderwritters = selectedProducts.filter(
        (obj) => obj.underwriter !== '',
      );

      if (!selectedQuestions && !selectedUnderwritters) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  hasPatternErrorsInRiskAnswers() {
    const controls = this.subRiskQuestionForm?.controls;
    let hasPatternError = false;

    if (controls) {
      for (const controlName in controls) {
        if (controls.hasOwnProperty(controlName)) {
          const control = controls[controlName];
          if (control?.errors?.pattern?.value) {
            hasPatternError = true;
            break;
          }
        }
      }
    }

    if (hasPatternError) {
      return true;
    }
    return false;
  }

  async handleSaveDraft(event) {
    this.isContinue = false;
    this.formSubmitted = true;
    if (!this.isEndDateValid || !this.isStartDateValid) {
      this.alertErrMsg = 'error.pleaseSelectDateFields';
      this.showErrAlert = true;
      return;
    }
    if (this.hasPatternErrorsInRiskAnswers()) {
      this.validateRiskAnswers = true;
      return;
    }
    this.validateRiskAnswers = false;
    this.destroy$.next();
    this.destroy$.complete();
    this.showSpinner = true;
    this.showErrAlert = false;
    this.alertErrMsg = '';
    this.notificationAlert.show = false;
    let products, selectedPdts, ui, formData: any;

    await this.form$.subscribe((event) => (formData = event));
    ui = formData.ui;
    products = formData.products;
    this.loadGetAllByPolicyPeriodId(ui.policyPeriodId);
    selectedPdts = await this.getSelectedPdtStandaloneOptions(products);
    const pdtValues = selectedPdts.map((p) => p.value);

    pdtValues.forEach((p) => {
      const riskQnIds = this.riskForm?.value[p][0];
      for (let id in riskQnIds) {
        this.store.dispatch(
          new CreateQuoteAction.UpdateRiskAnswerAction({
            product: p,
            id: Number(id),
            value: riskQnIds[id],
          }),
        );
      }
    });

    if (ui.saveAtomicTrx && (ui.policyPeriodId > 0 || ui.policyId > 0)) {
      if (!ui.isNavigatedFromQuoteCalc) {
        await this.saveRisk(ui, selectedPdts, formData);
      }
      // save dates
      const policyPeriodData = {
        effectiveDt: getFormatDate(
          formData.startDate,
          this.shortDateFormat,
          this.longDateFormat,
        ),
        expiryDt: getFormatDate(
          formData.endDate,
          this.shortDateFormat,
          this.longDateFormat,
        ),
      };
      if (ui.policyPeriodId > 0) {
        this.policyPeriodService
          .Update(ui.policyPeriodId, policyPeriodData)
          .subscribe({
            next: (_) => {
              this.showSpinner = false;
              this.notificationAlert = {
                show: true,
                type: 'success',
                headerText: 'common.successHeader',
                bodyText: 'policy.update.success.body',
              };
            },
            error: (error) => {
              this.showSpinner = false;
              if (![500].includes(error?.status)) {
                this.notificationAlert = {
                  show: true,
                  type: 'error',
                  headerText: 'common.errorHeader',
                  bodyText: error,
                };
              }
            },
          });
      }
      // save underwriter

      if (this.policyRisks) {
        selectedPdts.forEach((product) => {
          const policyRisk = this.policyRisks.find(
            (policyRisk) => policyRisk.riskId == product.riskId,
          );
          if (policyRisk) {
            const policyRiskTrx = policyRisk.PolicyRiskTrxes[0];
            const riskTrxData = {
              underwriterId: this.isNumeric(product.underwriter)
                ? Number(product.underwriter)
                : this.underWriters[product.underwriter]?.id ||
                  product?.underwriterId,
            };
            this.policyRiskTrxService
              .Update(policyRiskTrx.id, riskTrxData)
              .subscribe({
                next: (_) => {
                  this.showSpinner = false;
                  this.notificationAlert = {
                    show: true,
                    type: 'success',
                    headerText: 'common.successHeader',
                    bodyText: 'policy.update.success.body',
                  };
                },
                error: (error) => {
                  this.showSpinner = false;
                  if (![500].includes(error?.status)) {
                    this.notificationAlert = {
                      show: true,
                      type: 'error',
                      headerText: 'common.errorHeader',
                      bodyText: error,
                    };
                  }
                },
              });
          }
        });
      }
      this.showSpinner = false;
    } else {
      const risk = selectedPdts.map((dataObj) => ({
        id: dataObj.riskId,

        underwriterId: this.isNumeric(dataObj.underwriter)
          ? Number(dataObj.underwriter)
          : this.underWriters[dataObj.underwriter]?.id ||
            dataObj?.underwriterId,
        riskAnswer: formData.riskQuestions[dataObj.value].map((item) => ({
          riskQuestionId: item.id,
          riskAnswer: item?.value?.toString() || '',
        })),
      }));

      const data = {
        policy: {
          brokerageBORId: this.uiData.brokerageId,
          brokerageBranchBORId: this.uiData.branchId,
          brokerageProducerBORId: this.uiData.producerId,
          insuredId: this.uiData.insuredId,
        },

        policyPeriod: {
          effectiveDt: getFormatDate(
            formData.startDate,
            this.shortDateFormat,
            this.longDateFormat,
          ),
          expiryDt: getFormatDate(
            formData.endDate,
            this.shortDateFormat,
            this.longDateFormat,
          ),
        },

        risk: risk,
        quoteOption: false,
      };
      try {
        this.policyAtomicTrxService.CreateQuote(data).subscribe({
          next: (data) => {
            const policyId = data.data.id;
            this.quoteId = policyId;
            this.notificationAlert = {
              show: true,

              type: 'success',

              headerText: 'common.successHeader',
              bodyText: `policy.create.success.body`,
            };
            this.store.dispatch(
              new CreateQuoteAction.setPolicyId(data.data?.id),
            );

            this.store.dispatch(
              new CreateQuoteAction.UpdateInsuredAction({
                saveAtomicTrx: true,
                policyId: policyId,
              }),
            );
            this.policyPeriodService
              .GetAllByPolicyId(policyId)
              .subscribe((dataPId) => {
                const policyPeriodId = dataPId.data[0].id;
                this.policyRiskService
                  .GetAllByPolicyPeriodId(policyPeriodId)
                  .subscribe((dataPPId) => {
                    this.store.dispatch(
                      new CreateQuoteAction.setPolicyPeriodId(policyPeriodId),
                    );
                    this.store.dispatch(
                      new CreateQuoteAction.UpdateInsuredAction({
                        policyPeriodId: policyPeriodId,
                      }),
                    );
                  });
              });
            this.showSpinner = false;
          },
          error: (error) => {
            this.showSpinner = false;
            if (![500].includes(error?.status)) {
              this.notificationAlert = {
                show: true,
                type: 'error',
                headerText: 'common.errorHeader',
                bodyText: getErrorMessage(error),
              };
            }
          },
        });
      } catch (error) {
        if (![500].includes(error?.status)) {
          this.notificationAlert = {
            show: true,
            type: 'error',
            headerText: 'common.errorHeader',
            bodyText: getErrorMessage(error),
          };
        }
        this.showSpinner = false;
      }
    }
  }

  private async saveRisk(
    ui: any,
    selectedPdts: any,
    formData: any,
    isCalledFromContinue = false,
  ) {
    try {
      if (ui.policyPeriodId === 0) {
        return;
      }

      const policyDashboardServiceObs = this.policyDashboardService.Get(
        ui.policyPeriodId,
      );
      const policyDashboardData = await firstValueFrom(
        policyDashboardServiceObs,
      );

      for (let dataObj of policyDashboardData?.data?.PolicyRisks) {
        const policyRiskId = dataObj?.id;
        const answers =
          this.policyAnswerService.GetAllByQuestionIdAndPolicyRiskId(
            policyRiskId,
            1,
            500,
          );
        let dataAnswers = await firstValueFrom(answers);
        const policyQuestionsLists = dataAnswers?.data;

        const updatedQuestions = formData.riskQuestions[dataObj?.risk?.name]
          .map((questions) => {
            const policyQuestionsList = policyQuestionsLists.find(
              (answer) => answer?.riskQuestionId === questions.id,
            );
            if (policyQuestionsList) {
              const riskAnswerChanged =
                policyQuestionsList?.riskAnswer !== questions?.value;

              if (
                riskAnswerChanged &&
                policyQuestionsList?.id &&
                questions?.value
              ) {
                return {
                  id: Number(policyQuestionsList?.id),
                  riskAnswer: questions?.value?.toString() || '',
                };
              }
            }
            // Return null for rows with unchanged answer values
            return null;
          })
          .filter((updatedQuestion) => updatedQuestion !== null);

        if (updatedQuestions.length !== 0) {
          const riskAnswerArray = {
            policyRiskId: policyRiskId,
            answers: updatedQuestions,
          };
          const policyRiskQuestions =
            this.policyAnswerService.UpdateBulkQuestionAnswers(riskAnswerArray);
          let dataPolicyRiskQuestions =
            await firstValueFrom(policyRiskQuestions);

          if (dataPolicyRiskQuestions) {
            if (!isCalledFromContinue) {
              this.showSpinner = false;
            }
            this.notificationAlert = {
              show: true,
              type: 'success',
              headerText: 'common.successHeader',
              bodyText: 'policy.update.success.body',
            };
          }
        }
        this.store.dispatch(
          CreateQuoteAction.loadRiskQuestionsSuccess({
            riskQuestions: {
              [dataObj?.risk?.name]:
                formData.riskQuestions[dataObj?.risk?.name],
            },
          }),
        );
        this.store.dispatch(
          CreateQuoteAction.loadRiskCoveragesSuccess({
            riskCoverages: {
              [dataObj?.risk?.name]:
                this.updatedCoverageList[dataObj?.risk?.name],
            },
          }),
        );
      }
    } catch (error) {
      if (!isCalledFromContinue) {
        this.showSpinner = false;
      } else {
        this.showBodySpinner = false;
      }
      if (![500].includes(error?.status)) {
        this.showErrAlert = true;
        this.notificationAlert = {
          show: true,
          type: 'error',
          headerText: 'common.errorHeader',
          bodyText: getErrorMessage(error),
        };
      } else {
        this.showErrAlert = true;
        this.notificationAlert = {
          show: true,
          type: 'error',
          headerText: 'common.errorHeader',
          bodyText: getErrorMessage(error?.error),
        };
      }
    }
  }

  alertErrCloseHandler() {
    this.showErrAlert = false;
    this.alertErrMsg = '';
  }

  alertSuccessCloseHandler() {
    this.showSuccessAlert = false;
  }

  getDateObj(date) {
    if (date) {
      const dateParts = date.split('/');
      return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    }
    return null;
  }

  populateDefaultQuoteOption(response) {
    let quoteOptions = [];

    response.data?.forEach((risk) => {
      const policyRiskTrxId = risk?.PolicyRiskTrxes[0].id;

      risk?.PolicyRiskTrxes[0]?.QuoteOptions.forEach((quoteOption) => {
        quoteOption.policyRiskTrxId = policyRiskTrxId;

        quoteOption.risk = risk?.risk;
        quoteOptions.push(quoteOption);
      });
    });

    const optionNumbers = quoteOptions
      .map((quoteOption) => quoteOption?.optionNumber)
      .filter((val, idx, arr) => arr.indexOf(val) === idx);
    optionNumbers.forEach((optionNumber) => {
      let quoteOptionGroup = {
        products: [],
        total: {
          premium: 0,
          premiumCalculated: 0,
          policyFee: 0,
          triaAmount: 0,
          netAmount: 0,
          taxesAmount: 0,
          totalAmount: 0,
          servicesAmount: 0,
          taxesPremiumAmount: 0,
          taxesServicesAmount: 0,
        },
        ui: {
          selectedForCompare: false,
          isQuoteBoxExpanded: true,
          optionSelected: false,
        },
      };
      quoteOptionGroup.products = quoteOptions
        ?.filter((quoteOption) => quoteOption.optionNumber == optionNumber)
        .map((quoteOption) => {
          const policyFee = quoteOption.policyFee
            ? Number(quoteOption.policyFee)
            : 0;
          const triaAmount = quoteOption.triaAmount
            ? Number(quoteOption.triaAmount)
            : 0;
          const netAmount =
            Number(quoteOption.premium ? quoteOption.premium : 0) +
            Number(quoteOption.servicesAmount ? quoteOption.servicesAmount : 0);
          const taxesAmount =
            Number(
              quoteOption.taxesServicesAmount
                ? quoteOption.taxesServicesAmount
                : 0,
            ) +
            Number(
              quoteOption.taxesPremiumAmount
                ? quoteOption.taxesPremiumAmount
                : 0,
            );
          const totalAmount =
            Number(policyFee) +
            Number(triaAmount) +
            Number(netAmount) +
            Number(taxesAmount);
          const servicesAmount = Number(
            quoteOption.servicesAmount ? quoteOption.servicesAmount : 0,
          );
          const taxesPremiumAmount = Number(
            quoteOption.taxesPremiumAmount ? quoteOption.taxesPremiumAmount : 0,
          );
          return {
            ...quoteOption,
            key: quoteOption?.risk.name,
            value: quoteOption?.risk.name,
            description: 'description',
            checked: true,
            active: true,
            addons: [],
            underwriter: '',
            riskId: quoteOption?.risk.id,
            limit: quoteOption.limit,
            brokerCommissionPerc: quoteOption.brokerCommissionPerc
              ? Number(quoteOption.brokerCommissionPerc * 100).toFixed(2)
              : 0,
            deductables: quoteOption.deductible,
            standaloneSellable: response.data[0].risk?.standaloneSellable == 1,
            showLimitCustomInput: false,
            showDeductibleCustomInput: false,
            showPremiumCalcBtn: false,
            premium: quoteOption.premium,
            showEditedPremiumCustomField: false,
            premiumCalculated: quoteOption.premium,
            editedPremiumDeviation: '',
            expandAddOns: false,
            quoteOptionId: quoteOption.id,
            optionNumber: quoteOption.optionNumber,
            showInvalidLimitErr: false,
            showInvalidLimitErrMsg: '',
            pdtAddOns: [],
            policyFee: policyFee,
            triaAmount: triaAmount,
            netAmount: netAmount,
            taxesAmount: taxesAmount,
            totalAmount: totalAmount,
            servicesAmount: servicesAmount,
            taxesPremiumAmount: taxesPremiumAmount,
            policyRiskTrxId: quoteOption?.policyRiskTrxId,
          };
        });

      quoteOptionGroup?.products.forEach((product) => {
        quoteOptionGroup.total.premium += Number(product.premium);

        quoteOptionGroup.total.premiumCalculated += Number(
          product.premiumCalculated,
        );
        quoteOptionGroup.total.triaAmount += Number(product.triaAmount);
        quoteOptionGroup.total.policyFee += Number(product.policyFee);
        quoteOptionGroup.total.taxesAmount += Number(product.taxesAmount);
        quoteOptionGroup.total.servicesAmount += Number(product.servicesAmount);

        quoteOptionGroup.total.taxesPremiumAmount += Number(
          product.taxesPremiumAmount,
        );
        quoteOptionGroup.total.taxesServicesAmount += Number(
          product.taxesServicesAmount,
        );
        quoteOptionGroup.total.netAmount += Number(product.netAmount);
        quoteOptionGroup.total.totalAmount += Number(product.totalAmount);
      });

      this.store.dispatch(
        new CreateQuoteAction.updateUiContents({
          premium: quoteOptionGroup.total.premium,
        }),
      );

      quoteOptionGroup.ui.optionSelected =
        quoteOptionGroup?.products[0]?.optionSelected == 1;
      const quoteOptionCopy = this.getCopy(quoteOptionGroup);
      this.store.dispatch(
        new CreateQuoteAction.AddQuoteOption({ quote: { ...quoteOptionCopy } }),
      );
      let products = [];
      let updatedProductsWithId = [];
      this.form$
        .pipe(take(1))
        .subscribe((event) => (products = event.products));
      if (products?.length > 0) {
        updatedProductsWithId = products.map((product) => {
          const policyRiskTrxId = quoteOptionCopy.products.filter(
            (pdt) => pdt.key == product.key,
          )[0]?.policyRiskTrxId;
          return {
            ...product,
            policyRiskTrxId,
          };
        });

        this.store.dispatch(
          CreateQuoteAction.loadProductsSuccess({
            products: updatedProductsWithId,
          }),
        );
      }
    });
  }

  isAllFormsFilled(selectedProducts) {
    const pdtValues = selectedProducts.map((pd) => pd.value);
    for (let p of pdtValues) {
      const riskQnIds = Object.values(this.riskForm?.value[p][0]);
      const hasNullOrEmptyValue = riskQnIds.some(
        (value) => value === null || value === '',
      );
      if (hasNullOrEmptyValue) {
        return false;
      }
    }
    return true;
  }
  async handleContinue(e, formDetails) {
    if (this.permissionList[this.currentScreen]) {
      if (this.isNavigatedFromQuoteCalc) {
        if (this.formChanged(formDetails)) {
          this.updateRiskActionData();
          this.showRecalcConfModal = true;
        } else {
          this.navigateToNextPage();
        }
      } else {
        this.proceedToContinue(e, formDetails);
      }
    } else {
      this.navigateToNextPage();
    }
  }
  async proceedToContinue(e, formDetails) {
    this.isContinue = true;
    this.formSubmitted = true;
    this.validateRiskAnswers = true;
    this.showBodySpinner = true;
    const selectedProducts = formDetails.products.filter(
      (obj) => obj.checked === true,
    );
    if (
      !this.isFormValid(formDetails) ||
      !this.isAllFormsFilled(selectedProducts)
    ) {
      this.alertErrMsg = 'error.unansweredQuestionsErrMessage';
      this.showErrAlert = true;
      this.showBodySpinner = false;
      return;
    } else if (!this.isEndDateValid || !this.isStartDateValid) {
      this.alertErrMsg = 'error.pleaseSelectDateFields';
      this.showErrAlert = true;
      this.showBodySpinner = false;
      return;
    }

    this.destroy$.next();
    this.destroy$.complete();
    this.alertErrCloseHandler();
    let products;
    let selectedPdts;

    this.form$.pipe(take(1)).subscribe((event) => {
      products = event.products;
    });

    selectedPdts = this.getSelectedPdtStandaloneOptions(products);

    const pdtValues = selectedPdts.map((p) => p.value);

    pdtValues.forEach((p) => {
      const riskQnIds = this.riskForm?.value[p][0];

      if (
        this.excludedCategoriesIds.length > 0 &&
        this.categoryExcluded &&
        this.excludedCategories.length > 0
      ) {
        for (const index in riskQnIds) {
          if (this.excludedCategoriesIds.includes(Number(index))) {
            delete riskQnIds[index];
          }
        }
      }
      for (let id in riskQnIds) {
        this.store.dispatch(
          new CreateQuoteAction.UpdateRiskAnswerAction({
            product: p,
            id: Number(id),
            value: riskQnIds[id],
          }),
        );
      }
    });

    let formData: any;

    this.form$.subscribe((event) => (formData = event));

    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        startDate:
          getFormattedDateTime(formData.startDate, this.longDateFormat) ?? '',
        endDate:
          getFormattedDateTime(formData.endDate, this.longDateFormat) ?? '',
      }),
    );

    let ui;

    this.form$.pipe(take(1)).subscribe((event) => (ui = event.ui));
    const risk = selectedPdts.map((dataObj) => ({
      id: dataObj.riskId,

      underwriterId: this.isNumeric(dataObj.underwriter)
        ? Number(dataObj.underwriter)
        : this.underWriters[dataObj.underwriter]?.id || dataObj?.underwriterId,

      riskAnswer: formData.riskQuestions[dataObj.value].map((item) => ({
        riskQuestionId: item.id,
        riskAnswer: item?.value?.toString() || '',
      })),
    }));

    const atomicTrxBody = {
      policy: {
        brokerageBORId: this.uiData.brokerageId,
        brokerageBranchBORId: this.uiData.branchId,
        brokerageProducerBORId: this.uiData.producerId,
        insuredId: this.uiData.insuredId,
      },

      policyPeriod: {
        effectiveDt: getFormatDate(
          formData.startDate,
          this.shortDateFormat,
          this.longDateFormat,
        ),
        expiryDt: getFormatDate(
          formData.endDate,
          this.shortDateFormat,
          this.longDateFormat,
        ),
      },

      risk: risk,
      quoteOption: true,
    };

    let policyId;
    let quoteOption;
    try {
      if (!this.isQuoteRecordExists(ui)) {
        try {
          const createAtomicTrx =
            this.policyAtomicTrxService.CreateQuote(atomicTrxBody);
          let dataATrx = await firstValueFrom(createAtomicTrx);
          policyId = dataATrx.data.id;

          this.store.dispatch(new CreateQuoteAction.setPolicyId(policyId));

          const getPolicyByID =
            this.policyPeriodService.GetAllByPolicyId(policyId);
          let dataPId = await firstValueFrom(getPolicyByID);

          const policyPeriodId = dataPId.data[0].id;
          const getPolicyRiskData =
            this.policyRiskService.GetAllByPolicyPeriodId(policyPeriodId);
          const initialRiskData = await firstValueFrom(getPolicyRiskData);

          this.store.dispatch(
            new CreateQuoteAction.setPolicyPeriodId(
              initialRiskData.data[0].policyPeriodId,
            ),
          );
          this.store.dispatch(
            new updatePolicySlideoutData({
              policyRiskId: initialRiskData?.data[0]?.id,
              quoteStatus: 'quote',
            }),
          );
          await this.calculatePremiumForDefaultQuote(initialRiskData.data);

          const getPolicyRisk =
            this.policyRiskService.GetAllByPolicyPeriodId(policyPeriodId);
          const dataPPId = await firstValueFrom(getPolicyRisk);

          await this.populateDefaultQuoteOption(dataPPId);
          this.store.dispatch(
            new CreateQuoteAction.UpdateInsuredAction({
              policyRiskTrxId: dataPPId.data[0].PolicyRiskTrxes[0].id,
              policyId: policyId,
              saveAtomicTrx: true,
              policyPeriodId: policyPeriodId,
            }),
          );
          this.store.dispatch(
            new CreateQuoteAction.UpdateCurrentproductAction(''),
          );
          this.showBodySpinner = false;
          this.showSpinner = false;
          this.router.navigate(['/dashboard/quote/new/options'], {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          });
        } catch (error) {
          if (![500].includes(error?.status)) {
            this.notificationAlert = {
              show: true,
              type: 'error',
              headerText: 'common.errorHeader',
              bodyText: getErrorMessage(error),
            };
          }
          this.showBodySpinner = false;
          this.showSpinner = false;
        }
      } else {
        // TODO handle risk question changes after quote is in Quoted status
        await this.saveRisk(ui, selectedPdts, formData, true);
        // save dates
        const policyPeriodData = {
          effectiveDt: getFormatDate(
            formData.startDate,
            this.shortDateFormat,
            this.longDateFormat,
          ),
          expiryDt: getFormatDate(
            formData.endDate,
            this.shortDateFormat,
            this.longDateFormat,
          ),
        };
        this.policyPeriodService
          .Update(ui.policyPeriodId, policyPeriodData)
          .subscribe({
            next: (_) => {
              this.notificationAlert = {
                show: true,

                type: 'success',
                headerText: 'common.successHeader',
                bodyText: 'policy.update.success.body',
              };
            },
            error: (error) => {
              this.showBodySpinner = false;
              if (![500].includes(error?.status)) {
                this.notificationAlert = {
                  show: true,
                  type: 'error',
                  headerText: 'common.errorHeader',
                  bodyText: getErrorMessage(error),
                };
              }
            },
          });

        // save underwriter
        if (this.policyRisks) {
          selectedPdts.forEach((product) => {
            const policyRisk = this.policyRisks?.find(
              (policyRisk) => policyRisk.riskId == product.riskId,
            );

            if (policyRisk) {
              const policyRiskTrx = policyRisk.PolicyRiskTrxes[0];

              const riskTrxData = {
                underwriterId: this.isNumeric(product.underwriter)
                  ? Number(product.underwriter)
                  : this.underWriters[product.underwriter]?.id ||
                    product?.underwriterId,
              };
              this.policyRiskTrxService
                .Update(policyRiskTrx.id, riskTrxData)
                .subscribe({
                  next: (_) => {
                    this.notificationAlert = {
                      show: true,
                      type: 'success',
                      headerText: 'common.successHeader',
                      bodyText: 'policy.update.success.body',
                    };
                  },
                  error: (error) => {
                    this.showBodySpinner = false;
                    if (![500].includes(error?.status)) {
                      this.notificationAlert = {
                        show: true,
                        type: 'error',
                        headerText: 'common.errorHeader',
                        bodyText: error,
                      };
                    }
                  },
                });
            }
          });
        }

        policyId = ui.policyId;
        const dataDefault = {
          policyPeriodId: ui.policyPeriodId,
        };
        if (!ui.isNavigatedFromQuoteCalc) {
          this.policyQuoteOptionService.CreateDefault(dataDefault).subscribe({
            next: async (data) => {
              const getPolicyRiskByPolicyPeriodID =
                this.policyRiskService.GetAllByPolicyPeriodId(
                  ui.policyPeriodId,
                );
              let initialRiskData = await firstValueFrom(
                getPolicyRiskByPolicyPeriodID,
              );
              this.store.dispatch(
                new CreateQuoteAction.setPolicyPeriodId(
                  initialRiskData.data[0].policyPeriodId,
                ),
              );
              try {
                await this.calculatePremiumForDefaultQuote(
                  initialRiskData.data,
                );

                const getPolicyRisk =
                  this.policyRiskService.GetAllByPolicyPeriodId(
                    initialRiskData.data[0].policyPeriodId,
                  );
                const dataPPId = await firstValueFrom(getPolicyRisk);
                await this.populateDefaultQuoteOption(dataPPId);

                this.store.dispatch(
                  new CreateQuoteAction.UpdateInsuredAction({
                    policyRiskTrxId: dataPPId.data[0].PolicyRiskTrxes[0].id,
                    policyId: policyId,
                    saveAtomicTrx: true,
                    policyPeriodId: ui.policyPeriodId,
                  }),
                );
                this.showBodySpinner = false;
                this.router.navigate(['/dashboard/quote/new/options'], {
                  relativeTo: this.activatedRoute,
                  skipLocationChange: true,
                });
              } catch (error) {
                this.showBodySpinner = false;
                const errorMessage = getErrorMessage(error);
                this.newQuoteService.setCreateDefaultQuoteError(errorMessage);
                this.router.navigate(['/dashboard/quote/new/options'], {
                  relativeTo: this.activatedRoute,
                  skipLocationChange: true,
                });
              }

              this.categoryExcluded = false;
              this.excludedCategories = [];
              this.excludedCategoriesIds = [];
            },
            error: (error) => {
              this.showBodySpinner = false;
              this.alertErrMsg = getErrorMessage(error);
              if (![500].includes(error?.status)) {
                this.showErrAlert = true;
              }
            },
          });
        } else {
          let policyRiskList;
          this.policyRiskService
            .GetAllByPolicyPeriodId(ui.policyPeriodId)
            .subscribe(async (data) => {
              if (data?.data[0]) {
                policyRiskList = data?.data;

                const resultArray = policyRiskList.flatMap((item) =>
                  item.PolicyRiskTrxes.flatMap((trx) =>
                    trx.QuoteOptions.map((option) => option.id),
                  ),
                );

                this.store.dispatch(new CreateQuoteAction.clearQuoteOptions());
                for (const element of resultArray) {
                  try {
                    const riskRating =
                      await this.riskRatingCalculateService.GetByQuoteOption(
                        element,
                      );
                    const ratingData = await firstValueFrom(riskRating);
                  } catch (error) {
                    this.newQuoteService.setCreateDefaultQuoteError(
                      error?.error,
                    );
                  }
                }

                const getPolicyRisk =
                  await this.policyRiskService.GetAllByPolicyPeriodId(
                    ui.policyPeriodId,
                  );
                const dataPPId = await firstValueFrom(getPolicyRisk);
                await this.populateDefaultQuoteOption(dataPPId);

                this.showBodySpinner = false;
                this.router.navigate(['/dashboard/quote/new/options'], {
                  relativeTo: this.activatedRoute,
                  skipLocationChange: true,
                });
              }
            });
        }
      }
    } catch (error) {
      this.showBodySpinner = false;
      if (![500].includes(error?.status)) {
        this.alertErrMsg = getErrorMessage(error);
        this.showErrAlert = true;
      }
    }
  }

  ngOnDestroy(): void {
    this.alertService.clearAlerts(-1);
    this.riskQuestionSubscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private isQuoteRecordExists(ui: any) {
    return (
      (ui.saveAtomicTrx && ui.policyId > 0 && ui.policyPeriodId > 0) ||
      ui.isNavigatedFromQuoteCalc
    );
  }

  async calculatePremiumForDefaultQuote(risks) {
    try {
      for (const risk of risks) {
        // Premium Calculation

        const riskRating =
          await this.riskRatingCalculateService.GetByQuoteOption(
            risk.PolicyRiskTrxes[0].QuoteOptions[0]?.id,
          );
        const ratingData = await firstValueFrom(riskRating);
      }
    } catch (error) {
      this.showBodySpinner = false;
      this.newQuoteService.setCreateDefaultQuoteError(error?.error);
    }
  }

  isNumeric(val: string) {
    return !isNaN(Number(val));
  }

  getCopy(dataObj) {
    return {
      products: dataObj.products.map((object) => ({ ...object })),
      total: { ...dataObj.total },

      ui: { ...dataObj.ui },
    };
  }

  getSelectedPdtStandaloneOptions(productList) {
    return productList.filter(
      (pdt) => pdt.checked && pdt.active && pdt.standaloneSellable,
    );
  }

  handleCoverageSelectedIds(event: any, product) {
    let riskCoverages, riskQuestions;
    riskQuestions = this.riskQuestionsRef[product];
    this.coverageSelectedIds[product] = event.selectedIds;
    this.updatedCoverageList[product] = event.updatedCoverageList;

    this.store.dispatch(
      new CreateQuoteAction.UpdateRiskCoverageAction({
        product,
        id: Number(event.updatedCoverageList[event.coverageIndex].id),
        value: event.updatedCoverageList[event.coverageIndex].isIncluded,
      }),
    );

    const coveragesArray = this.updatedCoverageList[product];
    coveragesArray.forEach((element, id) => {
      this.coverageSelectedIds[product].map((selected) => {
        if (selected.id === element.id && element.active === true) {
          //For excluding from the list
          if (
            selected.active === 0 &&
            element.isIncluded === false &&
            element?.questionnaireCategoryName
          ) {
            this.categoryExcluded = true;
            this.excludedCategories = [
              ...this.excludedCategories,
              element?.questionnaireCategoryName
                .toLowerCase()
                .replace(/\s/g, ''),
            ];
            let category = element?.questionnaireCategoryName
              .toLowerCase()
              .replace(/^\s+/, '');
            const resQuestions = riskQuestions.filter(
              (qObj) => qObj.category?.toLowerCase() === category,
            );
            riskQuestions.filter((qObj, idx) => {
              if (qObj.category?.toLowerCase() === category) {
                this.excludedCategoriesIds = [
                  ...this.excludedCategoriesIds,
                  qObj.id,
                ].filter((value, index, self) => self.indexOf(value) === index);
              }
            });
            this.checkAllNo(product, category, id, resQuestions, false);
          } else {
            // for including into the list
            if (element?.questionnaireCategoryName) {
              let category = element?.questionnaireCategoryName
                .toLowerCase()
                .replace(/\s/g, '');

              if (
                this.excludedCategories.includes(category) &&
                selected.active === 1
              ) {
                this.excludedCategories = this.excludedCategories.filter(
                  (questionObj) => {
                    !this.excludedCategories.includes(category);
                  },
                );

                let pdtFormCntrlss = (
                  this.riskForm.get(product) as FormArray
                ).at(0) as FormGroup;
                //while including to the list, setting the radio value as not selected.
                for (let question of this.excludedCategoriesIds) {
                  pdtFormCntrlss.controls[`${question}`].setValue('');
                }
              }
            }
          }
        }
      });
    });

    if (this.excludedCategories.length === 0) {
      this.categoryExcluded = false;
      this.excludedCategories = [];
      this.excludedCategoriesIds = [];
    }
  }

  handleNotificationAlertClose() {
    this.notificationAlert.show = false;
  }

  handleRiskAction(action: TransactionRiskActions) {
    this.updateRiskActionData();
    this.actionPopupDetails.action = action;
    this.showActionPopup = true;
  }

  handleConfirmRiskAction(event: {
    action: TransactionRiskActions;
    selectedReasonId: number;
  }) {
    const PolicyDeclineRequest: PolicyDeclineRequest = {
      policyPeriodId: Number(this.eventData.ui.policyPeriodId),
      declineReason: Number(event.selectedReasonId),
    };

    switch (event.action) {
      case TransactionRiskActions.Decline:
        this.policyLifecycleService
          .TransactionRiskDecline(PolicyDeclineRequest)
          .subscribe({
            next: (response) => {
              this.handleRiskActionApiResponse(
                TransactionRiskActions.Decline,
                response,
              );
            },
            error: (e) => {
              this.handleApiError(e);
            },
            complete: () => {
              this.handleRiskActionComplete();
            },
          });
        break;
      default:
        const alertErrMsg = this.translate.instant('Invalid risk action.');
        this.handleApiError(alertErrMsg);
        this.showActionPopup = false;
        break;
    }
  }

  private handleRiskActionApiResponse(type, response: any) {
    this.showActionPopup = false;
    if (response && type === TransactionRiskActions.Decline) {
      this.showSuccessAlert = true;
      this.alertSuccessMsg = this.translate.instant(
        'policy.declined.success.body',
      );
      let path = 'dashboard/quotes';
      let quoteFlowSteps = this.localStorageService.getQuoteFlowSteps();
      if (quoteFlowSteps === 'workflow3') {
        path = 'dashboard/workflow3/quotes';
      }
      this.router
        .navigate([path], {
          skipLocationChange: true,
        })
        .then(() => {
          const alertData = {
            show: true,
            type: 'success',
            headerText: 'Success!',
            bodyText: this.translate.instant('policy.declined.success.body'),
          };
          this.policyPeriodService.setAlertData(alertData);
        });
    } else {
      this.showErrAlert = true;
      this.alertErrMsg = this.translate.instant(
        'error.actionIsNotImplementedYet',
      );
    }
  }

  private handleRiskActionComplete() {
    this.showActionPopup = false;
    if (this.showSuccessAlert) {
      this.showSuccessAlert = true;
      this.showErrAlert = false;
    } else {
      this.showErrAlert = true;
      this.showSuccessAlert = false;
    }
  }

  handleApiError(error: any) {
    if (![500].includes(error?.status)) {
      this.showErrAlert = true;
    }
    this.showActionPopup = false;
    if (typeof error === 'string' || error instanceof String) {
      if (error?.toLowerCase().includes('error code: 404', 0)) {
        this.alertErrMsg = this.translate.instant('Requested quote not found.');
        return;
      }
    }
    this.alertErrMsg = error?.error?.message
      ? error?.error?.message
      : error?.error?.errors?.message;
  }

  updateRiskActionData() {
    this.form$ = this.store.select(getQuoteSelector);
    this.form$.pipe(take(1)).subscribe((event) => {
      this.eventData = event;
    });
    this.actionPopupDetails = {
      quoteNumber:
        this.eventData.policyId == null || this.eventData.policyId == ''
          ? this.eventData.ui.policyId
          : this.eventData.policyId,
      effectiveDate: this.eventData.startDate,
      insured: this.eventData.ui.insuredName,
      brokerage: this.eventData.ui.brokerageName,
      branch: this.eventData.branch,
      reasonOptions: [],
      action: TransactionRiskActions.None,
      statusType: this.translate.instant('productForm.heading.submission'),
    };
  }

  closeModalRecalculateConf() {
    this.showRecalcConfModal = false;
    this.actionPopupDetails = {
      quoteNumber: null,
      effectiveDate: null,
      insured: null,
      brokerage: null,
      branch: null,
      reasonOptions: null,
      action: null,
      statusType: null,
    };
  }

  handleSubmissionRecalculate(action: any, formData: any) {
    this.closeModalRecalculateConf();
    this.proceedToContinue(action, formData);
  }

  handleDiscardRecalculate(action: any) {
    this.resetFormData();
    this.closeModalRecalculateConf();
  }
  resetFormData() {
    this.store.dispatch(
      new CreateQuoteAction.UpdateStartDateAction(
        this.initialFormValue.startDate,
      ),
    );
    this.store.dispatch(
      new CreateQuoteAction.UpdateEndDateAction(this.initialFormValue.endDate),
    );
    for (let products of this.initialFormValue.products) {
      this.underwriterChanged(
        products.underwriter,
        this.initialFormValue.products,
        products.value,
      );
    }
    for (let product of Object.keys(this.initialFormValue.riskQuestions)) {
      let pdtFormCntrlss = (this.riskForm.get(product) as FormArray).at(
        0,
      ) as FormGroup;
      for (let question of this.initialFormValue.riskQuestions[product]) {
        if (pdtFormCntrlss.contains(question.id)) {
          pdtFormCntrlss.controls[`${question.id}`].setValue(question.value);
        }
      }
      let riskCategoryFormGroup = (
        this.riskCategoryForm.get(product) as FormArray
      ).at(0) as FormGroup;
      for (
        let categoryNo = 0;
        categoryNo < this.initialFormValue.riskCategory[product].length;
        categoryNo++
      ) {
        let category = Object.keys(
          this.initialFormValue.riskCategory[product][categoryNo],
        )[0];
        riskCategoryFormGroup.controls[`${category}`].setValue(
          this.initialFormValue.riskCategory[product][category],
        );
        if (!this.categoryProperties.hasOwnProperty(product + categoryNo)) {
          this.categoryProperties[product + categoryNo] = {
            checkAllYesBtn: null,
          };
        } else {
          this.categoryProperties[product + categoryNo].checkAllYesBtn = false;
        }
      }
    }
  }
  formChanged(formValue): boolean {
    if (formValue.startDate !== this.initialFormValue.startDate) {
      return true;
    }
    if (formValue.endDate !== this.initialFormValue.endDate) {
      return true;
    }
    for (let products of this.initialFormValue.products) {
      let currentProduct = formValue.products.find(
        (x) => x.value === products.value,
      );
      if (
        currentProduct.underwriterId !== products.underwriterId ||
        currentProduct.underwriter !== products.underwriter
      ) {
        return true;
      }
    }
    for (let product of Object.keys(this.initialFormValue.riskQuestions)) {
      let pdtFormCntrlss = (this.riskForm.get(product) as FormArray).at(
        0,
      ) as FormGroup;
      for (let question of this.initialFormValue.riskQuestions[product]) {
        if (pdtFormCntrlss.contains(question.id)) {
          if (
            typeof pdtFormCntrlss.controls[`${question.id}`].value === 'number'
          ) {
            if (
              pdtFormCntrlss.controls[`${question.id}`].value !==
              +question.value
            ) {
              return true;
            }
          } else {
            if (
              pdtFormCntrlss.controls[`${question.id}`].value !== question.value
            ) {
              return true;
            }
          }
        } else {
          return true;
        }
      }
    }
    return false;
  }
  navigateToNextPage() {
    this.router.navigate(['/dashboard/quote/new/options'], {
      relativeTo: this.activatedRoute,
      skipLocationChange: true,
    });
  }

  async getPolicyData(quoteID) {
    const getPolicyByID = this.policyPeriodService.GetAllByPolicyId(quoteID);
    let dataPId = await firstValueFrom(getPolicyByID);
    const policyPeriodId = dataPId.data[0].id;
    const policyDashboardServiceObs =
      this.policyDashboardService.Get(policyPeriodId);
    const policyDashboardData = await firstValueFrom(policyDashboardServiceObs);
    const insured = policyDashboardData.data.policy.insured;
    const insuredServiceObs = this.insuredService.Get(insured.id);
    let insuredData = await firstValueFrom(insuredServiceObs);
    const locationId = insuredData.data.insuredLocation?.id;
    const getLocation = this.locationService.Get(locationId);
    let locationData = await firstValueFrom(getLocation);
    const getLanguage = this.languageService.getLanguageId();
    let langData = await firstValueFrom(getLanguage);
    const languageId = langData.data[0]?.id;
    const regionId = locationData.data.region.id;

    const getRiskVersion = this.riskRegionService.getRiskRegionAndVersion(
      regionId,
      insured.insuredType,
      STANDALONE_SELLABLE_TRUE,
    );
    let riskRegion = await firstValueFrom(getRiskVersion);
    const policyRisks = policyDashboardData.data.PolicyRisks;
    let products = await this.makeProducts(riskRegion.data, policyRisks);
    //UPDATE STORE UI with PRODUCTS
    this.store.dispatch(
      CreateQuoteAction.loadProductsSuccess({ products: products }),
    );

    riskRegion?.data.forEach(async (riskRegion) => {
      const getQuestions = this.riskQuestionService.GetByRegionId(
        riskRegion.id,
        languageId,
        insured.id,
        500,
      );
      let dataQuestions = await firstValueFrom(getQuestions);
      const questionAnswers = dataQuestions?.data;

      const policyRiskData = policyDashboardData.data.PolicyRisks.filter(
        (policyRisk) => policyRisk.risk.id == riskRegion.risk.id,
      );
      if (policyRiskData.length > 0) {
        const policyRiskId = policyRiskData[0].id;
        const policyRiskValue = policyRiskData[0].risk.name;
        const policyQuestionList =
          this.policyAnswerService.GetAllByQuestionIdAndPolicyRiskId(
            policyRiskId,
            1,
            500,
          );
        let dataPolicyQuestionList = await firstValueFrom(policyQuestionList);
        const policyQuestionLists = dataPolicyQuestionList?.data;
        const selectedQuestions = questionAnswers.map((questions) => {
          const policyQuestionList = policyQuestionLists.find(
            (policyQuestions) =>
              policyQuestions.riskQuestionId === questions.id,
          );

          return {
            ...questions,
            value: policyQuestionList?.riskAnswer
              ? policyQuestionList?.riskAnswer
              : null,
          };
        });
        //UPDATE STORE UI with Question Answers
        this.store.dispatch(
          CreateQuoteAction.loadRiskQuestionsSuccess({
            riskQuestions: { [policyRiskValue]: selectedQuestions },
          }),
        );
      }
    });
    const getPolicyServiceObs = this.policyService.Get(quoteID);
    const policyData = await firstValueFrom(getPolicyServiceObs);
    const policyRiskTrxId =
      policyDashboardData.data.PolicyRisks[0].PolicyRiskTrxes[0].id;
    const brokerData = {
      branch: policyData.data.brokerageBranchBOR.name,
      broker: policyDashboardData.data.policy.brokerageBOR.name,
      producer: this.getBrokerNames(
        policyDashboardData.data.policy.brokerageProducerBOR,
      ),
    };
    const startDate = getDateFormatForDatePicker(
      policyDashboardData.data.effectiveDt,
      this.shortDateFormat,
    );
    const endDate = getDateFormatForDatePicker(
      policyDashboardData.data.expiryDt,
      this.shortDateFormat,
    );
    //UPDATE STORE DROPDOWNS with DATES
    this.store.dispatch(new CreateQuoteAction.UpdateStartDateAction(startDate));
    this.store.dispatch(new CreateQuoteAction.UpdateEndDateAction(endDate));
    //UPDATE STORE DROPDOWNS with BROKER
    this.store.dispatch(
      new CreateQuoteAction.setBrokerageBranchProducerAction(brokerData),
    );
    const branchObs = this.brokerageBranchService.GetBranchByBrokerageId(
      policyDashboardData.data.policy.brokerageBORId,
      1,
      10000000,
    );
    let branches = await firstValueFrom(branchObs);
    const branch = branches.data.filter(
      (b) => b.name === policyData.data.brokerageBranchBOR.name,
    )[0];
    this.insuredData = {
      brokerageId: Number(policyDashboardData.data.policy.brokerageBORId),
      branchId: Number(branch.id),
      producerId: Number(
        policyDashboardData.data.policy.brokerageProducerBORId,
      ),
      brokerageName: policyDashboardData.data.policy.brokerageBOR.name,
      regionId,
    };
    //UPDATE STORE UI with BROKER
    this.store.dispatch(
      new CreateQuoteAction.UpdateInsuredAction({
        brokerageId: Number(policyDashboardData.data.policy.brokerageBORId),
        branchId: Number(branch.id),
        producerId: Number(
          policyDashboardData.data.policy.brokerageProducerBORId,
        ),
        brokerageName: policyDashboardData.data.policy.brokerageBOR.name,
        regionId,
      }),
    );
    //UPDATE STORE UI with PRODUCTS
    this.store.dispatch(
      CreateQuoteAction.loadProductsSuccess({ products: products }),
    );
    let isInsuredTypeCompany = true;
    if (insured.insuredType == 1) isInsuredTypeCompany = false;
    this.store.dispatch(
      new CreateQuoteAction.UpdateInsuredAction({
        insuredId: Number(insured.id),
        insuredName: this.getInsuredName(insured),
        policyId: Number(quoteID),
        saveAtomicTrx: true,
        policyRiskTrxId: Number(policyRiskTrxId),
        insuredEmail: insuredData.data.email,
        revenue: insuredData.data.revenue,
        noOfEmployees: insuredData.data.numOfEmployees,
        industryClass: insuredData.data.industryClass.name,
        industry: insuredData.data.industry.name,
        address: insuredData.data.insuredLocation.fullAddress,
      }),
    );
    this.store.dispatch(
      new CreateQuoteAction.UpdateInsuredAction({
        isInsuredTypeCompany: isInsuredTypeCompany,
        policyPeriodId: Number(policyPeriodId),
      }),
    );
    this.store.dispatch(new CreateQuoteAction.setPolicyId(quoteID));
  }
  async makeProducts(data, policyRisk) {
    const tableDataPromises = data?.map(async (item, index, arr) => {
      const policyRiskData = policyRisk.filter(
        (policyRisk) => policyRisk.risk.id == item.risk.id,
      );
      let underwriter = '';
      let isChecked = false;
      let underwriterId;
      if (policyRiskData.length > 0) {
        const underwriterObj =
          policyRiskData[0]?.PolicyRiskTrxes[0]?.underwriter;
        underwriter =
          underwriterObj?.firstName + ' ' + underwriterObj?.lastName;
        underwriterId = underwriterObj?.id;
        isChecked = true;
      }

      let hasSubProduct = false,
        subProductName = '',
        subProductRiskId = null;
      let hasParentProduct = false,
        parentRiskId = null;
      if (item.risk?.standaloneSellable == 1) {
        const getSubProduct = this.riskRegionService.getBySellableWithRiskId(
          item.risk.id,
        );
        const getSubProductResponse = await firstValueFrom(getSubProduct);

        if (getSubProductResponse?.data?.length > 0) {
          hasSubProduct = true;
          subProductName = getSubProductResponse?.data[0]?.name;
          subProductRiskId = getSubProductResponse?.data[0]?.id;
        }
      } else {
        const getParentProduct = this.riskRegionService.getRiskById(
          item.risk.id,
        );
        const getParentProductResponse = await firstValueFrom(getParentProduct);

        if (
          getParentProductResponse?.data?.id &&
          getParentProductResponse?.data?.sellableWithRiskId
        ) {
          hasParentProduct = true;
          parentRiskId = getParentProductResponse?.data?.id;
        }
      }

      return {
        key: item.risk.name,
        value: item.risk.name,
        description: item.risk.description,
        checked: isChecked,
        active: true,
        addons: [],
        underwriter: underwriter,
        riskId: item.risk.id,
        underwriterId: underwriterId,
        standaloneSellable:
          item.risk?.standaloneSellable === STANDALONE_SELLABLE_TRUE,
        hasSubProduct: hasSubProduct,
        subProductName: subProductName,
        subProductRiskId: subProductRiskId,
        hasParentProduct: hasParentProduct,
        parentRiskId: parentRiskId,
      };
    });

    // Wait for all the promises to resolve
    const resolvedTableData = await Promise.all(tableDataPromises);

    return resolvedTableData;
  }

  getInsuredName(insured) {
    if (insured.insuredType == 2) {
      return insured.companyName;
    } else {
      let name = insured.firstName + ' ';
      name += insured.middleName ? insured.middleName : '';
      name += ' ' + insured.lastName;
      return name;
    }
  }
  getBrokerNames(dataObj) {
    return dataObj.firstName + ' ' + dataObj.lastName;
  }
}
