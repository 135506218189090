import { createFeatureSelector, createSelector } from '@ngrx/store';
import { stores } from '..';

export const createInsuredSelector = createFeatureSelector<stores>('stores');

export const getInsuredSelector = createSelector(
  createInsuredSelector,
  (state: stores) => state.stateInsured,
);

export const getInsuredNameSelector = createSelector(
  createInsuredSelector,
  (state: stores) => state.stateInsured.name,
);

export const getInsuredAlertSelector = createSelector(
  createInsuredSelector,
  (state: stores) => state.stateInsured.alert,
);
