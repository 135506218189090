import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IBaseDto } from 'src/app/dtos/baseDto';
import { Breadcrumb } from 'src/app/models/breadcrumb.model';
import { IBrokerageModel } from 'src/app/models/brokerage.model';
import { CommissionState } from 'src/app/store/commission/commission.reducer';
import { getCommissionSelector } from 'src/app/store/commission/commission.select';
import { CommissionService } from '../services/commisssion.service';
import {
  getFormGroup,
  mapFormGroupToModel,
  checkRegexPatternMainWebsite,
  checkRegexPatternMainEmail,
  checkRegexPatternMultidomain,
} from './factorys/formGroup.factory';
import { BrokerageService } from 'src/app/services/brokerage.service';
import { ICommission, ICommissionModel } from 'src/app/models/commission.model';
import { ImageLogoSubjectService } from 'src/app/components/subjects/image-logo.service';
import { resetCommissions } from 'src/app/store/commission/commission.action';
import { setBrokerageCreated } from 'src/app/store/brokerage/brokerage.action';
import { ParentCompanySubjectService } from 'src/app/components/subjects/parent-company.service';
import { ViewportScroller } from '@angular/common';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { saveBrokerageProfileAction } from 'src/app/store/brokerageProfile/brokerageProfile.action';
import { BrokerageParentCompany } from 'src/app/entities/brokerage-parent-company';
import { getBrokerageCompanyDetails } from 'src/app/store/brokerageProfile/brokerageProfile.select';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import * as DashboardActionTypes from 'src/app/store/dashboard/dashboard.action';
import { getErrorMessage } from 'src/app/utils/utils';

@Component({
  selector: 'app-new-brokerage',
  templateUrl: './new-brokerage.component.html',
  styleUrls: ['./new-brokerage.component.less'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NewBrokerageComponent implements OnInit, AfterViewInit {
  form: FormGroup;
  formSubmitted: boolean = false;
  requiredErrMsg: string = 'This field is required';
  invalidErrMsg: string = 'This field is invalid';
  parentCompanyInvalidErrMsg: string = '';
  showSpinner: boolean = false;
  itemsMenu: Breadcrumb[] = [
    { label: 'Brokerage directory', path: '/dashboard/brokerage' },
    { label: 'New Brokerage', path: null },
  ];

  regions$: Observable<any>;
  brokeragesCompanys: IBaseDto[] = [];
  operationSuccess = false;
  hasError = false;
  errorMessage = '';
  locationRegionIdSelected: number = 0;
  private listCommissions = [];
  private userId: number;

  hasErrorProfilePic = false;
  errorMessageProfilePic = '';
  isNavigatedFromAlert: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private parentCompanySubjectService: ParentCompanySubjectService,
    private imageLogoSubjectService: ImageLogoSubjectService,
    private brokerageService: BrokerageService,
    private store: Store<CommissionState>,
    private fb: FormBuilder,
    private viewPortScroller: ViewportScroller,
    private localStorageService: LocalStorageService,
  ) {}

  ngAfterViewInit(): void {
    this.viewPortScroller.scrollToPosition([0, 0]);
  }

  ngOnInit(): void {
    this.userId = this.localStorageService.getBoxxUserId();
    this.form = getFormGroup(this.fb);
    this.store.dispatch(new saveBrokerageProfileAction(null));
    setTimeout(() => {
      this.viewPortScroller.scrollToPosition([0, 0]);
    }, 0);
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.isNavigatedFromAlert = data?.isNavigatedFromAlert;
    });
  }

  get brokerage() {
    return this.form.get('brokerage') as FormGroup;
  }

  get location() {
    return this.form.get('location') as FormGroup;
  }

  regionIdSelected(idregionSelected: number) {
    this.locationRegionIdSelected = idregionSelected;
  }

  handleSubmit(e: any) {
    this.isParentCompanyValid();
    this.formSubmitted = true;

    const mainWebsite = this.form.controls['brokerage']
      .get('website')
      .value.toLowerCase();
    if (mainWebsite) {
      if (!checkRegexPatternMainWebsite(mainWebsite)) {
        this.form.controls['brokerage']
          .get('website')
          .setErrors({ pattern: true });
      }
    }
    const emailId = this.form.controls['brokerage']
      .get('email')
      .value.toLowerCase();
    if (!checkRegexPatternMainEmail(emailId)) {
      this.form.controls['brokerage'].get('email').setErrors({ pattern: true });
    }
    const availableDomains = this.form.controls['brokerage']
      .get('domains')
      .value.toLowerCase();
    if (!checkRegexPatternMultidomain(availableDomains)) {
      this.form.controls['brokerage']
        .get('domains')
        .setErrors({ pattern: true });
    }
    if (this.form.valid) {
      this.showSpinner = true;
      const brokerage: IBrokerageModel = mapFormGroupToModel(this.form);

      if (brokerage.location) {
        brokerage.location.regionId = this.locationRegionIdSelected;
      }

      this.brokerageService.CreateBrokerage(brokerage).subscribe({
        next: (response) => {
          this.operationSuccess = true;
          this.form.reset();
          this.imageLogoSubjectService.dispatch(true);
          this.parentCompanySubjectService.dispatch(true);
          this.store.dispatch(new resetCommissions());
          this.viewPortScroller.scrollToPosition([0, 0]);
          this.formSubmitted = false;
          this.showSpinner = false;
          this.store.dispatch(new setBrokerageCreated());
          this.router.navigate([`../view/${response?.data?.id}`], {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          });
        },
        error: (error) => {
          this.showSpinner = false;
          if (![500].includes(error?.status)) {
            this.hasError = true;
          }
          this.errorMessage = getErrorMessage(error);
        },
      });
    }
  }

  handleCloseSuccessEvent() {
    this.hasError = false;
    this.hasErrorProfilePic = false;
  }

  triggerProfilePicErrorToParent(msg: any) {
    if (msg) {
      this.hasErrorProfilePic = true;
      this.errorMessageProfilePic = msg;
    } else {
      this.hasErrorProfilePic = false;
      this.errorMessageProfilePic = '';
    }
  }
  isParentCompanyValid() {
    let parentCompanyDetails: BrokerageParentCompany = {};
    this.store
      .select(getBrokerageCompanyDetails)
      .subscribe((parentCompany) => (parentCompanyDetails = parentCompany));
    if (
      parentCompanyDetails.searchText === '' ||
      parentCompanyDetails.searchText === parentCompanyDetails.name
    ) {
      this.parentCompanyInvalidErrMsg = '';
      return;
    }
    this.parentCompanyInvalidErrMsg = 'This field is invalid';
    this.form.controls['brokerage'].get('parent').setErrors({ pattern: true });
  }
}
