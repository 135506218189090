import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { PolicyDetail } from 'src/app/dashboard/policy-lifecycle/objects/policy-lifecycle-objects';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { PolicyRiskTrxNotesService } from 'src/app/services/policy-risk-trx-notes-service';
import { formatDate } from 'src/app/utils/formatDate';
import { isRead, checkRegexPatternNotes } from 'src/app/utils/notesUtils';
import { TranslateService } from '@ngx-translate/core';
import { Store, select } from '@ngrx/store';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import { Pagination } from 'src/app/entities/boxx-response';
import { UNDERWRITER_CLEARANCE } from '../../constants/systemType';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.less'],
})
export class NotesComponent implements OnInit, OnChanges {
  @Input() form;
  @Input() textareaControl;
  @Input() isPolicySlideOut: boolean = false;
  @Input() showTag: boolean = false;
  @Input() currentTagOptions = [];
  @Input() notesContainerStyle: { [klass: string]: any };
  @Input() actionPopupStyle: { [klass: string]: any };
  @Input() details!: PolicyDetail;
  @Output() FormSubmit = new EventEmitter<any>();
  @Output() handleCurrentTag = new EventEmitter();
  customErrMsg: any;
  errorMsgValid: boolean = true;
  errorMsgUpdateNoteValid: boolean = false;
  dropdownOpenIdx = -1;
  dropdownOptions = [this.translate.instant('common.edit')];
  editMode = [];
  currentEditNodeIdx = -1;
  editForm: FormGroup;
  userID: number;
  options;
  notesEditPermissionRoles = [
    'Operations Manager',
    'Sr Manager',
    'Delivery Manager',
    'Underwriter',
  ];
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  currentUnderWriter;
  underWriterEditNotesPermission;
  totalCount;
  currentPage: number = 1;
  itemsPerPage = 10;
  totalPages;
  filterData: any;
  notesData: { pagination: Pagination | null; filterData: {} } = {
    pagination: null,
    filterData: {},
  };
  notes = [];
  notesSearchKeyWord = '';
  dateSearchKeyWord = '';
  writerSearchKeyWord: number;
  notesCreateTrxId;
  disableAddButton: boolean = false;
  underWriterClearencePermission;
  underWriterEditPermission;
  showModalSpinner: boolean = false;
  shortDateFormat: string = '';
  longDateFormat: string = '';

  constructor(
    private fb: FormBuilder,
    private localStorageService: LocalStorageService,
    private policyRiskTrxNotesService: PolicyRiskTrxNotesService,
    private translate: TranslateService,
    private store: Store,
    private boxxUserService: BoxxUserService,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.form = this.fb.group({
      inputNotes: ['', [Validators.required, Validators.maxLength(500)]],
      writerOptions: [''],
      notes: [''], //Notes tab
      currentTag: [''],
    });
  }
  ngOnChanges(): void {
    this.changeDetector.detectChanges();
  }
  async ngOnInit(): Promise<void> {
    this.showModalSpinner = true;
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.underWriterClearencePermission = data?.permissions.some(
        (obj) =>
          obj.permission.permissionName.toLowerCase() === UNDERWRITER_CLEARANCE,
      );
      this.underWriterEditPermission = data?.permissions.some(
        (obj) =>
          obj.permission.permissionName.toLowerCase() ===
            UNDERWRITER_CLEARANCE && obj?.readWrite === true,
      );
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    this.editForm = this.fb.group({
      note: ['', [Validators.required, Validators.maxLength(500)]],
    });
    let tag;
    this.notesCreateTrxId = this.details.policyRiskTrxId;
    if (this.currentTagOptions?.length > 0) {
      tag = this.currentTagOptions?.filter((p) => p.value)[0].key;
      this.form.controls['currentTag'].setValue(tag);
      this.notesCreateTrxId = this.currentTagOptions?.filter((p) => p.value)[0]
        .id;
    }
    this.userID = this.localStorageService.getBoxxUserId();
    await this.getUnderWriterEditNotesPermissionListFromApi();
  }

  concat(...words) {
    return words.join('- ');
  }
  private async getUnderWriterEditNotesPermissionListFromApi(): Promise<any> {
    try {
      const boxxUsrId = localStorage.getItem('bid');
      this.boxxUserService
        .GetUnderwriterlist(boxxUsrId, 1, 250)
        .subscribe((data) => {
          const tableData = data.data?.map((dataObj) => ({
            key: dataObj.firstName + ' ' + dataObj.lastName,
            value: dataObj.firstName + ' ' + dataObj.lastName,
            id: Number(dataObj.id),
            role: dataObj.systemUser?.role?.name,
          }));

          const foundCurrentUnderWriter = tableData.filter(
            (uw) => uw.id === Number(boxxUsrId),
          );
          this.currentUnderWriter = foundCurrentUnderWriter[0];
          this.underWriterEditNotesPermission = tableData;
          this.showModalSpinner = false;
        });
    } catch (error) {
      this.showModalSpinner = false;
      return null;
    }
  }
  doesHavePermissionForEditNotes(underwriter, updatedUnderwriter) {
    const underWriterRoleCheck = this.underWriterClearencePermission
      ? this.underWriterClearencePermission
      : '';
    // If the role is underwriter
    if (underWriterRoleCheck) {
      if (this.underWriterEditPermission) {
        return this.underWriterEditNotesPermission?.some(
          (option) => option.value === underwriter,
        );
      } else {
        if (underwriter === this.currentUnderWriter?.value) {
          return true;
        } else {
          return false;
        }
      }
    } else if (!underWriterRoleCheck) {
      // If the role is non-underwriter
      if (updatedUnderwriter) {
        if (underwriter === this.currentUnderWriter?.value) {
          // Check the condition for edit permission for non-underwriter
          if (this.currentUnderWriter?.value !== updatedUnderwriter) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return this.underWriterEditNotesPermission?.some(
          (option) => option.value === underwriter,
        );
      }
    } else {
      return false;
    }
  }

  handleEllipsisClick(e, index) {
    this.closeDropdown();
    this.dropdownOpenIdx = index;
    e.stopPropagation();
  }

  async select(action, index) {
    switch (action) {
      case this.dropdownOptions[0]:
        this.editForm.controls['note'].setValue(
          this.form.value['notes'][index]?.content,
        );
        this.cancelEditMode();
        this.currentEditNodeIdx = index;
        return;
      case this.dropdownOptions[1]:
        await this.updateNoteAPI(this.form.value['notes'][index]);
        this.editMode[index] = false;
        return;
    }
  }

  closeDropdown() {
    this.dropdownOpenIdx = -1;
  }

  cancelEditMode() {
    this.currentEditNodeIdx = -1;
  }

  async updateNoteAPI(note) {
    this.errorMsgUpdateNoteValid = false;
    let noteContent = note.content.trim();
    try {
      const updateReq = {
        note: checkRegexPatternNotes(noteContent),
      };
      const notesUpdateObs = this.policyRiskTrxNotesService.Update(
        note.id,
        updateReq,
      );
      await firstValueFrom(notesUpdateObs);
    } catch (error) {}
  }

  async filterNotesList(pageNumber: number) {
    this.errorMsgValid = false;
    this.errorMsgUpdateNoteValid = false;
    let policyRiskTrxIds = this.details.policyRiskTrxId.toString();
    if (!this.isPolicySlideOut) {
      if (this.currentTagOptions?.length > 0) {
        let currentTagData = this.currentTagOptions.find(
          (entry) => entry.key === this.form.value['currentTag'],
        );
        policyRiskTrxIds = currentTagData.id;
      }
    }

    const product = this.details.product;
    this.policyRiskTrxNotesService
      .getByPolicyRiskTrxId(policyRiskTrxIds, pageNumber, this.itemsPerPage)
      .subscribe({
        next: (resp) => {
          ({ pagination: this.notesData.pagination } = resp);
          const notes = resp.data.map((data) => ({
            content: data.note,
            writer: data.createdBy,
            writerPermission: data?.updatedBy ? data?.updatedBy : '',
            product: product,
            tag: data.tags,
            date: formatDate(data.createdDt, this.longDateFormat),
            id: data.id,
            updatedDt: data?.updatedDt
              ? formatDate(data.updatedDt, this.longDateFormat)
              : '',
            read: isRead(data.updatedDt ? data.updatedDt : data.createdDt),
            pagination: this.notesData?.pagination,
          }));
          this.details.isUnreadNotesPresent =
            notes.filter((p) => !p.read).length > 0;
          this.form.controls['notes'].setValue(notes);
          this.form.controls['notesPagination'].setValue(this.notesData);

          this.FormSubmit.emit(notes);
        },
        error: (e) => {},
      });
    this.totalPages = this.notesData?.pagination?.totalPages;
    this.currentPage = pageNumber;
    // TO DO - update during api integration
    this.totalCount = this.notesData?.pagination?.totalRecords;
  }

  async createNoteAPI(tag, newNote, policyRiskTrxId) {
    let noteContent = newNote.trim();

    const createReq = {
      policyRiskTrxId: policyRiskTrxId,
      tags: tag,
      noteParentId: 1,
      note: checkRegexPatternNotes(noteContent),
    };
    const notesCreateObs = this.policyRiskTrxNotesService.Create(createReq);
    const policyNoteCreateData = await firstValueFrom(notesCreateObs);
    const note = {
      content: noteContent,
      writer: policyNoteCreateData?.data?.createdBy,
      writerPermission: policyNoteCreateData?.data?.updatedBy
        ? policyNoteCreateData?.data?.updatedBy
        : '',
      id: policyNoteCreateData?.data.id,
      tag: tag,
      date: formatDate(
        policyNoteCreateData?.data?.createdDt,
        this.longDateFormat,
      ),
      updatedDt: policyNoteCreateData?.updatedDt
        ? formatDate(policyNoteCreateData?.data?.updatedDt, this.longDateFormat)
        : '',
    };
    return note;
  }

  async handleSave(index, event) {
    if (!this.editForm.value['note']) {
      this.errorMsgUpdateNoteValid = true;
      this.customErrMsg = 'error.thisFieldIsRequired';
      return;
    }
    if (this.editForm.invalid || this.editForm.value['note'].trim() === '') {
      this.errorMsgUpdateNoteValid = true;
      this.customErrMsg = 'common.invalidErrorMessage';
      return;
    }
    let notes = this.form.value['notes'];
    notes[index].content = this.editForm.value['note'];
    if (!this.checkFormValid(notes[index].content)) {
      this.errorMsgUpdateNoteValid = true;
      this.customErrMsg = 'error.maxLengthValidationNotesErrMsg';
      return;
    }
    this.form.controls['notes'].setValue(notes);
    await this.updateNoteAPI(notes[index]);
    await this.PolicyRiskTrxIdNotesFilter(this.currentPage);

    this.currentEditNodeIdx = -1;
    this.editForm.controls['note'].reset();
    event.stopPropagation();
  }

  handleCancel() {
    this.currentEditNodeIdx = -1;
    this.editForm.controls['note'].reset();
  }
  async currentTagChanged(e) {
    this.form.controls['currentTag'].setValue(e);
    if (this.currentTagOptions?.length > 0) {
      let currentTagData = this.currentTagOptions.find(
        (entry) => entry.key === e,
      );
      this.notesCreateTrxId = currentTagData.id;
    }
    await this.filterNotesList(1);
    this.handleCurrentTag.emit(e);
  }
  checkFormValid(newNote) {
    if (newNote.length > 500) {
      return false;
    }
    return true;
  }

  handleNewNoteINputChanged(event) {
    this.errorMsgValid = false;
    this.disableAddButton = null;
    if (
      this.form.value['inputNotes'] &&
      this.form.value['inputNotes'].trim() === ''
    ) {
      this.errorMsgValid = true;
      this.customErrMsg = 'common.invalidErrorMessage';
      return;
    }

    if (!this.checkFormValid(this.form.value['inputNotes'])) {
      this.errorMsgValid = true;
      this.customErrMsg = 'error.maxLengthValidationNotesErrMsg';
      return;
    }
  }

  async handleNewNoteAdd(e) {
    this.disableAddButton = true;
    try {
      this.errorMsgValid = false;

      let newNote = this.form.value['inputNotes'];
      if (!newNote) {
        this.errorMsgValid = true;
        this.customErrMsg = 'error.thisFieldIsRequired';
        return;
      }
      if (newNote && newNote.trim() === '') {
        this.errorMsgValid = true;
        this.customErrMsg = 'common.invalidErrorMessage';
        return;
      }
      if (!this.checkFormValid(newNote)) {
        this.errorMsgValid = true;
        this.customErrMsg = 'error.maxLengthValidationNotesErrMsg';
        return;
      }

      let tag = this.form.controls['currentTag']?.value;

      if (!tag) {
        if (this.form.controls['tag']?.value) {
          tag = this.form.controls['tag']?.value;
        } else {
          tag = this.currentTagOptions?.filter((p) => p.value)[0].key;
        }
        this.notesCreateTrxId = this.details.policyRiskTrxId;
      }
      const note = await this.createNoteAPI(
        tag,
        newNote,
        this.notesCreateTrxId,
      );
      this.form.controls['inputNotes'].setValue('');
      this.disableAddButton = false;
      let notes = this.form.value['notes'];
      this.form.value['notes'] = [...notes, note];
      await this.filterNotesList(1);
    } catch (error) {}
  }

  async PolicyRiskTrxIdNotesFilter(pageNumber: number) {
    this.cancelEditMode();
    this.closeDropdown();
    let policyRiskTrxIds = this.details.policyRiskTrxId.toString();
    if (!this.isPolicySlideOut) {
      if (this.details.currentTagOptions?.length > 0) {
        let currentTagData = this.details.currentTagOptions.find(
          (entry) => entry.key === this.form.value['currentTag'],
        );
        policyRiskTrxIds = currentTagData.id;
      }
    }
    let queryParams = {};
    queryParams = this.form.value['notesPagination']?.filterData;
    const product = this.details.product;
    this.policyRiskTrxNotesService
      .getByPolicyRiskTrxIdNotesFilter(
        policyRiskTrxIds,
        queryParams,
        pageNumber,
        this.itemsPerPage,
      )
      .subscribe({
        next: (resp) => {
          ({ pagination: this.notesData.pagination } = resp);
          const notes = resp.data.map((data) => ({
            content: data.note,
            writer: data.createdBy,
            writerPermission: data?.updatedBy ? data?.updatedBy : '',
            product: product,
            tag: data.tags,
            date: formatDate(data.createdDt, this.longDateFormat),
            id: data.id,
            updatedDt: data?.updatedDt
              ? formatDate(data.updatedDt, this.longDateFormat)
              : '',
            read: isRead(data.updatedDt ? data.updatedDt : data.createdDt),
          }));
          this.details.isUnreadNotesPresent =
            notes.filter((p) => !p.read).length > 0;

          this.form.controls['notes'].setValue(notes);
          this.notesData.filterData = queryParams;
          this.form.controls['notesPagination'].setValue(this.notesData);
        },
        error: (e) => {},
      });

    this.currentPage = pageNumber;
  }
  onKeyUpNoteValidation(event) {
    this.errorMsgUpdateNoteValid = false;

    if (
      this.editForm.value['note'] &&
      this.editForm.value['note'].trim() === ''
    ) {
      this.errorMsgUpdateNoteValid = true;
      this.customErrMsg = 'common.invalidErrorMessage';
      return;
    }

    if (!this.checkFormValid(this.editForm.value['note'])) {
      this.errorMsgUpdateNoteValid = true;
      this.customErrMsg = 'error.maxLengthValidationNotesErrMsg';
      return;
    }
  }
}
