import { Component, Input, OnInit } from '@angular/core';
import { Commission } from 'src/app/entities/commission';

@Component({
  selector: 'app-view-commission',
  templateUrl: './view-commission.component.html',
  styleUrls: ['./view-commission.component.less'],
})
export class ViewCommissionComponent implements OnInit {
  @Input() commission: Commission;

  commissionItem: Commission;
  constructor() {}

  ngOnInit(): void {
    if (this.commission) {
      this.commissionItem = { ...this.commission };
      const commissionMinFormatted: number = Number(
        (
          (this.commissionItem.commissionMin
            ? this.commissionItem.commissionMin
            : 0) * 100
        ).toFixed(2),
      );
      const commissionMaxFormatted: number = Number(
        (
          (this.commissionItem.commissionMax
            ? this.commissionItem.commissionMax
            : 0) * 100
        ).toFixed(2),
      );
      const commissionDefaultFormatted: number = Number(
        (
          (this.commissionItem.commissionDefault
            ? this.commissionItem.commissionDefault
            : 0) * 100
        ).toFixed(2),
      );
      this.commissionItem.commissionMin = commissionMinFormatted;
      this.commissionItem.commissionMax = commissionMaxFormatted;
      this.commissionItem.commissionDefault = commissionDefaultFormatted;
    }
  }
}
