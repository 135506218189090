const isType = (value: string, type: BrokerageTypes): Boolean => {
  try {
    if (value.toLowerCase() === type) return true;
    else return false;
  } catch (error) {
    return false;
  }
};

export const isBroker = (value: string): Boolean => {
  return isType(value, BrokerageTypes.BROKERAGE);
};
export const isBranch = (value: string): Boolean => {
  return isType(value, BrokerageTypes.BRANCH);
};

export const isProducer = (value: string): Boolean => {
  return isType(value, BrokerageTypes.PRODUCER);
};

enum BrokerageTypes {
  BROKERAGE = 'brokerage',
  BRANCH = 'branch',
  PRODUCER = 'producer',
}
