import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { ICommission } from 'src/app/models/commission.model';
import { Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';

@Component({
  selector: 'app-commission-details',
  templateUrl: './commission-details.component.html',
  styleUrls: ['./commission-details.component.less'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CommissionDetailsComponent implements OnInit {
  @Input() isActive: boolean;
  /* eslint-disable */
  @Output() onCommissionChange = new EventEmitter<any>();
  @Output() onCommissionPageChange = new EventEmitter<any>();
  /* eslint-disable */
  @Input() resetAddFormSubject: Subject<boolean> = new Subject<boolean>();
  @Input() commissionData: any;

  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
    });
  }

  handleAddCommission(commission) {
    this.onCommissionChange.emit({ commission: commission, method: 'create' });
  }
  handleUpdateCommission(commission) {
    this.onCommissionChange.emit({ commission: commission, method: 'update' });
  }
  handleDisableCommission(commission) {
    this.onCommissionChange.emit({ commission: commission, method: 'disable' });
  }
  handleEnableCommission(commission) {
    this.onCommissionChange.emit({ commission: commission, method: 'enable' });
  }
  handleCommissionPageChange($event) {
    this.onCommissionPageChange.emit($event);
  }
}
