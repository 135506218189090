import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalAlertService } from 'src/app/services/modal-alert.service';

@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.less'],
})
export class ModalAlertComponent {
  alerts: any[] = [];
  private alertSubscription: Subscription;

  constructor(private alertService: ModalAlertService) {
    // Subscribe to the alert service to update the alerts array
    this.alertSubscription = this.alertService.alertObservable$.subscribe(
      (alerts) => {
        this.alerts = alerts || [];
      },
    );
  }

  ngOnDestroy() {
    // Unsubscribe from the alert service to avoid memory leaks
    this.alertSubscription.unsubscribe();
  }

  clearAlert(alert: any) {
    // Find the index of the clicked alert and clear it
    const index = this.alerts.indexOf(alert);
    if (index !== -1) {
      this.alertService.clearAlert(index);
    }
  }
}
