<section class="popup-container">
  <section class="popup">
    <section class="title">
      <div class="icon-popup-title-wrapper">
        <div class="icon-box-wrapper">
          <div class="icon-box">
            <div class="icon-container">
              <svg
                class="icon-svg"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <g clip-path="url(#clip0_29700_3791)">
                  <path
                    d="M12.2478 9.50023V13.5002M12.2478 17.5002H12.2578M10.8631 4.39195L2.63823 18.5986C2.18203 19.3866 1.95393 19.7806 1.98764 20.1039C2.01705 20.386 2.16482 20.6423 2.39417 20.809C2.65713 21.0002 3.11239 21.0002 4.02292 21.0002H20.4727C21.3832 21.0002 21.8385 21.0002 22.1014 20.809C22.3308 20.6423 22.4786 20.386 22.508 20.1039C22.5417 19.7806 22.3136 19.3866 21.8574 18.5986L13.6325 4.39195C13.1779 3.60679 12.9506 3.21421 12.6541 3.08235C12.3954 2.96734 12.1002 2.96734 11.8415 3.08235C11.545 3.21421 11.3177 3.60679 10.8631 4.39195Z"
                    stroke="#D46B00"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_29700_3791">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(0.25 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div class="rings-wrapper">
            <div class="rings">
              <span style="--i: 1"></span>
              <span style="--i: 2"></span>
              <span style="--i: 3"></span>
              <span style="--i: 4"></span>
              <span style="--i: 5"></span>
              <span style="--i: 6"></span>
            </div>
          </div>
        </div>
        <h1>
          {{ "common.producerChange" | translate }}
        </h1>
      </div>
      <boxxds-button-v2
        [isDisabled]="false"
        [size]="'lg'"
        [btnType]="'close'"
        (handleClick)="closeHandler()"
      >
      </boxxds-button-v2>
    </section>
    <section class="producer-change-body">
      <ng-container *ngIf="showSpinner">
        <div class="body-spinner">
          <boxxds-spinner
            [containerStyle]="{
              width: '112px',
              height: '112px',
              '--b': '15px'
            }"
          ></boxxds-spinner>
        </div>
      </ng-container>
      <div
        class="details-wrapper"
        [ngStyle]="{ opacity: showSpinner ? '0' : '1' }"
      >
        <div class="details-banner">
          <div class="details-container">
            <p class="details-head">
              {{ "common.quote" | translate }}
            </p>
            <p class="details" [title]="details?.['id'] ?? ''">
              {{ details?.["id"] ?? "" }}
            </p>
          </div>
          <div class="details-container">
            <p class="details-head">
              {{ "common.insured" | translate }}
            </p>
            <p class="details" [title]="details?.['insuredName'] ?? ''">
              {{ details?.["insuredName"] ?? "" }}
            </p>
          </div>
          <div class="details-container">
            <p class="details-head">
              {{ "common.brokerage" | translate }}
            </p>
            <p
              class="details"
              [title]="
                (details?.['brokerage'] ?? '') +
                ', ' +
                (details?.['branch'] ?? '')
              "
            >
              {{ details?.["brokerage"] ?? "" }},
              {{ details?.["branch"] ?? "" }}
            </p>
          </div>
          <div class="details-container">
            <p class="details-head">
              {{ "common.risk" | translate }}
            </p>
            <p class="details" [title]="details?.product?.[0]?.name ?? ''">
              {{ details?.product?.[0]?.name ?? "" }}
            </p>
          </div>
        </div>
        <div class="producer-update-section">
          <p class="body-head">
            {{ "common.pleaseSelectTheProducer" | translate }}
          </p>
          <div class="producer-search-container">
            <boxxds-search-autocomplete-v2
              [maxLength]="'200'"
              [form]="brokerageForm"
              [isActive]="true"
              [formSubmitted]="formSubmitted"
              [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
              [items]="brokerOptions"
              [placeholder]="'submission.select' | translate"
              [required]="true"
              [control]="'brokerageinfo'"
              [autocapitalize]="'off'"
              [spellcheck]="'off'"
              [containerStyle]="{}"
              (searchQuery)="searchBroker($event)"
              (itemSelected)="selectBroker($event)"
              (textInputChangeEventEmmiter)="searchBroker($event)"
              (currentValueChange)="selectBroker($event)"
              (outsideClick)="dropdownFocusOut($event)"
              [currentValue]="currentBrokerValue"
              [containerStyle]="{ minWidth: '400px' }"
              [dropdownContainerStyle]="{ width: '400px' }"
              [showNoResultAction]="true"
              [noResultContent]="noResultContent"
              (handleNoResultButtonClick)="noResultButtonclick($event)"
              [permissionStatus]="this.permissionList[this.currentScreen]"
              class="search-dropdown"
            ></boxxds-search-autocomplete-v2>
            <input
              type="text"
              name="dummy-input"
              id="dummy-input"
              autocomplete="off"
              [style]="{
                opacity: '0',
                height: '0',
                width: '0',
                cursor: 'default'
              }"
            />

            <boxxds-notification-v2
              *ngIf="noCommission"
              [alertType]="alertInfo.alertType"
              [alertWidth]="'lg'"
              [alertHeader]="alertInfo.alertHeader"
              [alertDescription]="alertInfo.alertText"
              [contentContainerStyle]="{ maxWidth: '68%' }"
              [linkContainerStyle]="{ maxWidth: '30%' }"
              [linkText]="alertInfo.alertLinkText"
              (handleLinkClick)="handleLinkAction()"
              class="notification-section"
              [alertContainerStyle]="{
                position: 'relative',
                marginTop: '10px'
              }"
              [showLinkIcon]="this.commissionDisabled.length > 0 ? false : true"
            >
            </boxxds-notification-v2>
          </div>
        </div>
        <div class="btn-container">
          <boxxds-button-v2
            class="change-button"
            [buttonText]="'common.changeTxt' | translate"
            [isDisabled]="noCommission || currentBrokerValue === ''"
            [size]="'lg'"
            [btnClass]="'primary'"
            [btnType]="'default'"
            (handleClick)="actionConfirmHandler($event)"
            [btnStyle]="{ width: '219px' }"
          >
          </boxxds-button-v2>
        </div>
      </div>
    </section>
  </section>
</section>
