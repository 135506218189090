import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PolicyActionButtonStatus } from '../../policy-lifecycle-v2/lifecycle-v2-object';

@Component({
  selector: 'app-lifecycle-header',
  templateUrl: './lifecycle-header.component.html',
  styleUrls: ['./lifecycle-header.component.less'],
})
export class LifecycleHeaderComponent implements OnChanges {
  @Input() header: string = 'Policy';
  @Input() isProductActive: boolean = false;
  @Input() policyId;
  @Input() transactionType: string;
  @Input() details;
  isPolicyActionBtns: PolicyActionButtonStatus;

  @Output() handleShowPeriod = new EventEmitter();
  @Output() handleBind = new EventEmitter();
  @Output() handleRenew = new EventEmitter();
  @Output() handleCancel = new EventEmitter();
  @Output() handleReinstate = new EventEmitter();
  @Output() handleStartNextPeriod = new EventEmitter();
  @Output() handleDecline = new EventEmitter();
  @Output() handleClose = new EventEmitter();
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.details) {
      this.isPolicyActionBtns = this.isActionBtnsDisabled(
        this.transactionType,
        this.details.isInvoiced,
      );
    }
  }
  isActionBtnsDisabled(
    transactionType: string = 'renewal',
    isInvoiced: boolean,
  ) {
    let refinedTransactionType: string = transactionType.trim().toLowerCase();
    const defaultIsDisabled: PolicyActionButtonStatus = {
      cancel: false,
      close: false,
      decline: false,
      bind: false,
      renew: false,
      reinstate: false,
      startNewPeriod: false,
    };

    switch (refinedTransactionType) {
      case 'quoted':
      case 'quote':
      case 'referral':
        return {
          ...defaultIsDisabled,
          bind: true,
          close: true,
          decline: true,
        };
      case 'renewalQuote':
      case 'renewal quote':
        return {
          ...defaultIsDisabled,
          startNewPeriod: true,
          cancel: true,
        };
      case 'bind':
      case 'bound':
      case 'renewal':
      case 'renewed':
      case 'endorsement':
      case 'reinstated':
      case 'policy change':
        let actionsObj = {
          ...defaultIsDisabled,
          startNewPeriod: true,
        };
        if (isInvoiced) {
          actionsObj = {
            ...actionsObj,
            cancel: true,
          };
        }
        return actionsObj;
      case 'cancelled':
      case 'cancellation':
        return {
          ...defaultIsDisabled,
          reinstate: true,
        };
      default:
        return defaultIsDisabled;
    }
  }

  closePolicy(e) {
    this.handleClose.emit(e);
  }
  declinePolicy(e) {
    this.handleDecline.emit(e);
  }
  cancelPolicy(e) {
    this.handleCancel.emit(e);
  }
  bindPolicy(e) {
    this.handleBind.emit(e);
  }
  reinstatePolicy(e) {
    this.handleReinstate.emit(e);
  }
  renewPolicy(e) {
    this.handleRenew.emit(e);
  }
  startNewPeriodPolicy(e) {
    this.handleStartNextPeriod.emit(e);
  }
}
