import { BoxxResponse } from 'src/app/entities/boxx-response';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  PolicyBindRequest,
  PolicyCancelRequest,
  PolicyChangeResponse,
  PolicyNewPeriodRequest,
  PolicyReinstateRequest,
  PolicyDeclineRequest,
  PolicyReturnPremiumCancelledRequest,
  PolicyCloseRequest,
  PolicyReferRequest,
  PolicyTransactionChange,
  PolicyTransactionDelete,
  PolicyTransactionExecute,
  PolicyTransactionEndorsementRequestBody,
  PolicyTransactionInvoicesRequestBody,
} from '../entities/policy-lifecycle';

@Injectable({
  providedIn: 'root',
})
export class PolicyLifecycleService {
  private readonly baseurl: string = '';

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {
    this.baseurl = environment.msPolicyBaseUrl + environment.policyLifeCycleUrl;
  }

  PolicyBind(
    userId: number,
    request: PolicyBindRequest,
  ): Observable<BoxxResponse<any>> {
    let url = `${this.baseurl}bind/`;
    return this.http.put<BoxxResponse<any>>(url, request, this.httpOptions);
  }

  PolicyCancel(
    userId: number,
    request: PolicyCancelRequest,
  ): Observable<BoxxResponse<any>> {
    let url = `${this.baseurl}cancel/`;
    return this.http.put<BoxxResponse<any>>(url, request, this.httpOptions);
  }

  PolicyReinstate(
    userId: number,
    request: PolicyReinstateRequest,
  ): Observable<BoxxResponse<any>> {
    let url = `${this.baseurl}reInstate/`;
    return this.http.put<BoxxResponse<any>>(url, request, this.httpOptions);
  }

  PolicyStartNewPeriod(
    request: PolicyNewPeriodRequest,
  ): Observable<BoxxResponse<any>> {
    let url = `${this.baseurl}newPeriod/`;
    return this.http.put<BoxxResponse<any>>(url, request, this.httpOptions);
  }

  PolicyReturnPremiumCancelledPolicy(
    userId: number,
    request: PolicyReturnPremiumCancelledRequest,
  ): Observable<BoxxResponse<any>> {
    const params = this.ToQueryString(request);
    const url = `${this.baseurl}cancel/premium/?${params}`;

    return this.http.get<BoxxResponse<any>>(url, this.httpOptions);
  }

  TransactionRiskClose(
    request: PolicyCloseRequest,
  ): Observable<BoxxResponse<any>> {
    const url = `${this.baseurl}close/`;
    return this.http.put<BoxxResponse<any>>(url, request, this.httpOptions);
  }

  TransactionRiskDecline(
    request: PolicyDeclineRequest,
  ): Observable<BoxxResponse<any>> {
    let url = `${this.baseurl}decline/`;
    return this.http.put<BoxxResponse<any>>(url, request, this.httpOptions);
  }

  TransactionRiskRefer(
    request: PolicyReferRequest,
  ): Observable<BoxxResponse<any>> {
    let url = `${this.baseurl}referral/`;
    return this.http.put<BoxxResponse<any>>(url, request, this.httpOptions);
  }

  TransactionRiskPolicyChange(
    request: PolicyTransactionChange,
  ): Observable<BoxxResponse<any>> {
    let url = `${this.baseurl}policyChange/`;
    return this.http.put<BoxxResponse<any>>(url, request, this.httpOptions);
  }

  TransactionRiskDelete(
    request: PolicyTransactionDelete,
  ): Observable<BoxxResponse<any>> {
    let url = `${this.baseurl}delete/`;
    return this.http.put<BoxxResponse<any>>(url, request, this.httpOptions);
  }
  TransactionRiskEndorse(
    requestBody: PolicyTransactionEndorsementRequestBody,
  ): Observable<BoxxResponse<any>> {
    let url = `${this.baseurl}endorsement/`;
    return this.http.put<BoxxResponse<any>>(url, requestBody, this.httpOptions);
  }
  TransactionRiskInvoice(
    requestBody: PolicyTransactionInvoicesRequestBody,
  ): Observable<BoxxResponse<any>> {
    let url = `${this.baseurl}invoice/`;
    return this.http.put<BoxxResponse<any>>(url, requestBody, this.httpOptions);
  }

  TransactionRiskExecute(
    request: PolicyTransactionExecute,
  ): Observable<BoxxResponse<any>> {
    let url = `${this.baseurl}execute/`;
    return this.http.put<BoxxResponse<any>>(url, request, this.httpOptions);
  }
  private ToHttpParams(request: any): HttpParams {
    let httpParams = new HttpParams();

    Object.keys(request).forEach(function (key) {
      httpParams = httpParams.append(key, request[key]);
    });

    return httpParams;
  }

  private ToQueryString(request: any): string {
    const parts = [];

    for (let property in request) {
      let value = request[property];

      if (value != null) {
        if (value instanceof Date) {
          value = value.toISOString(); // TODO: Is this correct?  Converts date to UTC.
        }

        parts.push(
          encodeURIComponent(property) + '=' + encodeURIComponent(value),
        );
      }
    }

    return parts.join('&');
  }

  private fakeResponse(): Observable<BoxxResponse<any>> {
    //return EMPTY.pipe(startWith());
    return Observable.create((observer) => {
      observer.next(true);
      observer.complete();
    });
  }

  SwitchIndicate(body): Observable<BoxxResponse<any>> {
    let url = `${this.baseurl}indicate`;
    return this.http.put<BoxxResponse<any>>(url, body, this.httpOptions);
  }
}
