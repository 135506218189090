import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { getLoginUrlByStep } from 'src/app/utils/utils';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.less'],
})
export class LogoutComponent {
  constructor(private localStorageService: LocalStorageService) {}

  quoteFlowSteps = '7';

  handleNavigateToLogin() {
    this.quoteFlowSteps = this.localStorageService.getQuoteFlowSteps();
    localStorage.removeItem('quoteFlowSteps');
    const loginUrl = getLoginUrlByStep(this.quoteFlowSteps);
    window.location.href = loginUrl;
  }
}
