import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import moment from 'moment';
import {
  handleApiError,
  isSuccessApiStatus,
} from 'src/app/dashboard/utils/lifecycle-utils';
import {
  PolicyChangeResponse,
  PolicyNewPeriodRequest,
} from 'src/app/entities/policy-lifecycle';
import { PolicyLifecycleService } from 'src/app/services/policy-lifecycle-service';
import { TranslateService } from '@ngx-translate/core';
import { Store, select } from '@ngrx/store';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';

@Component({
  selector: 'app-policy-new-period-popup',
  templateUrl: './policy-new-period-popup.component.html',
  styleUrls: ['./policy-new-period-popup.component.less'],
})
export class PolicyNewPeriodPopupComponent implements OnInit {
  @Input() showModal: boolean = false;
  @Input() details;

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleActionConfirm = new EventEmitter<PolicyChangeResponse>();

  private results: PolicyNewPeriodRequest = this.createDefaultRequest();

  newPeriodPolicyForm: FormGroup;
  buttonDisabled: boolean = true;
  isDateValid: boolean = false;
  minDate: Date;
  shortDateFormat: string = '';
  longDateFormat: string = '';

  constructor(
    private fb: FormBuilder,
    private policyLifecycleService: PolicyLifecycleService,
    private translate: TranslateService,
    private store: Store,
  ) {
    this.newPeriodPolicyForm = this.fb.group({
      date: [''],
    });

    this.minDate = moment().add(1, 'days').toDate();
  }

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
  }

  closeHandler() {
    this.handleClose.emit();
    this.resetForm();
  }

  handleDate(value) {
    this.isDateValid = !isNaN(new Date(value).getDate());

    if (this.isFormValid()) {
      this.results.startDate = new Date(value);
    }
  }

  handleSubmit(event) {
    if (!this.isFormValid()) {
      return;
    }

    const request: PolicyNewPeriodRequest = {
      policyPeriodId: this.details.policyPeriodId,
      startDate: this.results.startDate,
    };

    this.policyLifecycleService.PolicyStartNewPeriod(request).subscribe({
      next: (response) => {
        let result: PolicyChangeResponse = undefined;

        if (isSuccessApiStatus(response.status)) {
          result = {
            success: true,
            message: this.translate.instant(
              'New period was successfully started',
            ),
          };
        } else {
          result = {
            success: false,
            message:
              this.translate.instant('Error starting new policy peroid: ') +
              response.code?.toString(),
          };
        }

        this.handleActionConfirm.emit(result);
        this.resetForm();
      },
      error: (error) => {
        const result: PolicyChangeResponse = {
          success: false,
          message: handleApiError(error),
        };

        this.handleActionConfirm.emit(result);
        this.resetForm();
      },
    });
  }

  private isFormValid(): boolean {
    const isValid = this.isDateValid;
    this.buttonDisabled = !isValid;

    return isValid;
  }

  private resetForm() {
    this.buttonDisabled = true;
    this.isDateValid = false;
    this.newPeriodPolicyForm.reset();
    this.results = this.createDefaultRequest();
  }

  private createDefaultRequest(): PolicyNewPeriodRequest {
    let request: PolicyNewPeriodRequest = {
      policyPeriodId: 0,
      startDate: undefined,
    };

    return request;
  }
}
