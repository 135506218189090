import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import {
  convertDate,
  getFormatDate,
  getFormattedPeriod,
} from 'src/app/utils/formatDate';

@Component({
  selector: 'app-modal-premium-recalculate',
  templateUrl: './modal-premium-recalculate.component.html',
  styleUrls: ['./modal-premium-recalculate.component.less'],
})
export class ModalPremiumRecalculateWorkFlow3Component implements OnInit {
  @Input() showModal: boolean = false;
  @Input() existingQuoteOptionData;
  @Input() isSubmissionFlow = true;
  @Input() isTbd = true;

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleSubmissionRecalculate = new EventEmitter<any>();
  @Output() handleDiscardRecalculate = new EventEmitter<any>();

  shortDateFormat: string = '';
  longDateFormat: string = '';

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
  }
  closeModal() {
    this.handleClose.emit();
  }
  saveContinue() {
    this.handleSubmissionRecalculate.emit(true);
  }
  discardChanges() {
    this.handleDiscardRecalculate.emit(true);
  }

  formatDate(date) {
    const dateValue: Date = new Date(
      getFormatDate(
        date?.effectiveDt,
        'YYYY-MM-DDTHH:mm:ss.sssZ',
        'MMM DD,YYYY',
      ),
    );
    if (dateValue.toString() !== 'Invalid Date') {
      return date
        ? getFormattedPeriod(date, 'YYYY-MM-DDTHH:mm:ss.sssZ', 'MMM DD,YYYY')
        : '';
    } else {
      return date
        ? getFormattedPeriod(date, this.shortDateFormat, 'MMM DD,YYYY')
        : '';
    }
  }
}
