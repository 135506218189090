import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Breadcrumb } from 'src/app/models/breadcrumb.model';
import { BrokerageBranch } from 'src/app/models/brokergeBranch';
import { BrokerageBranchService } from 'src/app/services/brokerage-branch/brokerage-branch.service';
import {
  getFormGroup,
  mapFormGroupToModel,
} from '../factorys/formGroup.factory';
import { BrokerageService } from 'src/app/services/brokerage.service';
import { Store, select } from '@ngrx/store';
import { getErrorMessage } from 'src/app/utils/utils';
import { setBranchCreated } from 'src/app/store/brokerage/brokerage.action';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import { AlertService } from 'src/app/services/alert.service';
import { NewQuoteService } from 'src/app/services/new-quote.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-add-branch-v2',
  templateUrl: './add-branch-v2.component.html',
  styleUrls: ['./add-branch-v2.component.less'],
})
export class AddBranchV2Component implements OnInit {
  @Input() form: FormGroup;
  @Input() requiredErrMsg: string;
  @Input() invalidErrMsg: string;
  @Input() formSubmitted: boolean = false;
  @Input() isActive: boolean;
  @Input() brokerageId: number;

  @Output() handleDiscard = new EventEmitter();
  @Output() handleSaveAndAddProducer = new EventEmitter();
  @Output() handleSaveBranch = new EventEmitter();
  @Output() handleSaveChanges = new EventEmitter();

  submitted: boolean = false;
  userID: number;
  activeCategoryFilter: string = 'Branches';

  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  shortDateFormat: string = '';
  longDateFormat: string = '';

  itemsMenu: Breadcrumb[] = [
    { label: 'Brokerage', path: `/dashboard/workflow3/brokerage/directory` },
  ];
  operationSuccess = false;
  hasError = false;
  errorMessage = '';
  locationRegionIdSelected: number = 0;
  showSpinner = false;
  branchSaveClicked: boolean = false;

  autoFillForm: FormGroup;
  autoFillOptions = [
    {
      label: 'Yes',
      subLabel: '',
      currentValue: 'autoFillForm.value?.autoFill',
      value: 'yes',
    },
    {
      label: 'No',
      subLabel: '',
      currentValue: 'autoFillForm.value?.autoFill',
      value: 'no',
    },
  ];
  autoFilledBrokerageData = {
    name: '',
    email: '',
    phone: '',
    address: {
      address: '',
      addressLine2: '',
      city: '',
      province: '',
      country: '',
      zipPostalCode: '',
    },
  };

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private brokerageBranchService: BrokerageBranchService,
    private store: Store,
    private brokerageService: BrokerageService,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private newQuoteService: NewQuoteService,
  ) {
    this.autoFillForm = this.fb.group({
      autoFill: ['no'],
    });
  }

  ngOnInit(): void {
    this.userID = this.localStorageService.getBoxxUserId();
    this.form = getFormGroup(this.fb);

    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    this.brokerageService.GetBrokerage(this.brokerageId).subscribe({
      next: (brokerage) => {
        this.itemsMenu[1] = {
          label: brokerage.data.name,
          path: `/dashboard/workflow3/brokerage/profile/${this.brokerageId}`,
        };
        this.itemsMenu[2] = { label: 'New branch', path: null };
      },
      error: (error) => {
        this.handlerErrorBranch(error);
      },
    });
  }

  get location() {
    return this.form.get('location') as FormGroup;
  }

  get branch() {
    return this.form.get('branch') as FormGroup;
  }

  async handleAutoFill(data) {
    if (data?.value === 'yes') {
      try {
        const getBrokerage = this.brokerageService.GetBrokerage(
          this.brokerageId,
        );
        const getBrokerageResponse = await firstValueFrom(getBrokerage);
        const brokerageData = getBrokerageResponse?.data;
        const brokerageLocation = getBrokerageResponse?.data?.location;
        if (brokerageData?.id) {
          this.branch.controls['name'].setValue(brokerageData['name']);
          this.branch.controls['email'].setValue(brokerageData['email']);
          this.branch.controls['phone'].setValue(brokerageData['telephone']);
          this.location.controls['address'].setValue(
            brokerageLocation['address'],
          );
          this.location.controls['addressLine2'].setValue(
            brokerageLocation['addressLine2'],
          );
          this.location.controls['city'].setValue(brokerageLocation['city']);
          this.location.controls['province'].setValue(
            brokerageLocation?.region?.name,
          );
          this.location.controls['country'].setValue(
            brokerageLocation['country'],
          );
          this.location.controls['zipPostalCode'].setValue(
            brokerageLocation['zipPostalCode'],
          );
          this.autoFilledBrokerageData = {
            name: brokerageData['name'],
            email: brokerageData['email'],
            phone: brokerageData['telephone'],
            address: {
              address: brokerageLocation['address'],
              addressLine2: brokerageLocation['addressLine2'],
              city: brokerageLocation['city'],
              province: brokerageLocation?.region?.name,
              country: brokerageLocation['country'],
              zipPostalCode: brokerageLocation['zipPostalCode'],
            },
          };
          this.regionIdSelected(brokerageLocation?.region?.id);
        } else {
          const alertData = {
            show: true,
            type: 'error',
            headerText: 'common.errorHeader',
            bodyText: 'workFlow3.branchProfile.error.brokerageNotRetrieved',
          };
          this.autoFillForm.controls['autoFill'].setValue('no');
          this.alertService.clearAlerts(-1);
          this.alertService.addAlert(alertData);
        }
      } catch (error) {
        const alertData = {
          show: true,
          type: 'error',
          headerText: 'common.errorHeader',
          bodyText: getErrorMessage(error),
        };
        this.autoFillForm.controls['autoFill'].setValue('no');
        this.alertService.clearAlerts(-1);
        this.alertService.addAlert(alertData);
      }
    } else {
      this.branch.controls['name'].setValue('');
      this.branch.controls['email'].setValue('');
      this.branch.controls['phone'].setValue('');
      this.location.controls['address'].setValue('');
      this.location.controls['addressLine2'].setValue('');
      this.location.controls['city'].setValue('');
      this.location.controls['province'].setValue('');
      this.location.controls['country'].setValue('');
      this.location.controls['zipPostalCode'].setValue('');
      this.autoFilledBrokerageData = {
        name: '',
        email: '',
        phone: '',
        address: {
          address: '',
          addressLine2: '',
          city: '',
          province: '',
          country: '',
          zipPostalCode: '',
        },
      };
      this.regionIdSelected(0);
    }
  }

  isAutoFilled(newValue, prevValue = '') {
    if (!newValue) {
      return false;
    } else if (newValue == prevValue) {
      return true;
    }
    return false;
  }

  onHandleCloseEvent() {
    this.hasError = false;
  }

  regionIdSelected(idregionSelected: number) {
    this.locationRegionIdSelected = idregionSelected;
    this.autoFilledBrokerageData = {
      ...this.autoFilledBrokerageData,
      address: {
        address: this.location.value['address'],
        addressLine2: this.location.value['addressLine2'],
        city: this.location.value['city'],
        province: this.location.value['province'],
        country: this.location.value['country'],
        zipPostalCode: this.location.value['zipPostalCode'],
      },
    };
  }

  handleSubmit(e: any) {
    this.submitted = true;
    let branchForm = this.form.get('branch');
    if (branchForm.value.email === '') {
      delete branchForm.value.email;
    }
    if (this.form.valid) {
      this.showSpinner = true;

      const brokerageBranch: BrokerageBranch = mapFormGroupToModel(
        this.form,
        Number(this.brokerageId),
      );

      if (brokerageBranch.location) {
        brokerageBranch.location.regionId = this.locationRegionIdSelected;
      }

      this.brokerageBranchService.addBranch(brokerageBranch).subscribe({
        next: (response) => {
          this.operationSuccess = true;
          this.form.reset();
          this.submitted = false;
          this.showSpinner = false;
          this.store.dispatch(new setBranchCreated());
          this.router.navigate(
            [`../../view/${response?.data?.id}/${this.brokerageId}`],
            { relativeTo: this.activatedRoute, skipLocationChange: true },
          );
        },
        error: (error) => {
          this.handlerErrorBranch(error);
          this.showSpinner = false;
        },
      });
    }
  }

  private handlerErrorBranch(error: any) {
    if (![500].includes(error?.status)) {
      this.hasError = true;
    }
    this.errorMessage = getErrorMessage(error?.error);
  }

  handleDiscardClick(event) {
    this.handleDiscard.emit(event);
  }

  handleSaveProducerClick(event) {
    this.handleSaveBranchClick(event, true);
    this.handleSaveAndAddProducer.emit(event);
  }

  handleSaveBranchClick(event, saveProducer = false) {
    this.submitted = true;
    let branchForm = this.form;
    if (branchForm.value.email === '') {
      delete branchForm.value.email;
    }
    if (this.form.valid) {
      this.showSpinner = true;
      this.branchSaveClicked = true;
      const brokerageBranch: BrokerageBranch = mapFormGroupToModel(
        this.form,
        Number(this.brokerageId),
      );

      if (brokerageBranch.location) {
        brokerageBranch.location.regionId = this.locationRegionIdSelected;
      }
      this.brokerageBranchService.addBranch(brokerageBranch).subscribe({
        next: (response) => {
          this.operationSuccess = true;
          this.form.reset();
          this.submitted = false;
          this.showSpinner = false;
          this.branchSaveClicked = false;
          this.store.dispatch(new setBranchCreated());
          const alertData = {
            show: true,
            type: 'success',
            headerText: 'common.successHeader',
            bodyText: 'workFlow3.branchProfile.success.branchAdded',
          };
          this.alertService.clearAlerts(-1);
          this.alertService.addAlert(alertData);
          if (saveProducer) {
            this.newQuoteService.setBranchMsg(alertData);
            //navigation to add producer
            this.router.navigate(
              [
                `/dashboard/workflow3/brokerage/branch/producer/profile/${response?.data.id}`,
              ],
              {
                relativeTo: this.activatedRoute,
                skipLocationChange: true,
                queryParams: { producer: true },
              },
            );
          }

          let changes = { showSpinner: true, reloadData: true };

          this.handleSaveChanges.emit(changes);
        },
        error: (error) => {
          const alertData = {
            show: true,
            type: 'error',
            headerText: 'common.errorHeader',
            bodyText: getErrorMessage(error),
          };
          this.alertService.clearAlerts(-1);
          this.alertService.addAlert(alertData);
          this.showSpinner = false;
          this.branchSaveClicked = false;
        },
      });
    } else {
      const alertData = {
        show: true,
        type: 'error',
        headerText: 'common.errorMsg',
        bodyText: 'workFlow3.branchProfile.error.addressError',
      };
      this.alertService.clearAlerts(-1);
      this.alertService.addAlert(alertData);
    }
  }
}
