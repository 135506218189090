import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { find, findIndex, Observable, Subject } from 'rxjs';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import { BrokerageCommissionService } from 'src/app/services/brokerage-commissions.service';
import { BrokerageProducerCommissionService } from 'src/app/services/brokerage-producer-commission.service';
import { TranslateService } from '@ngx-translate/core';
import { ActionsCommission } from 'src/app/pages/brokerage/new-brokerage/constants/action-commission';
import { ICommission, ICommissionModel } from 'src/app/models/commission.model';
import { Commission } from 'src/app/entities/commission';
import { Pagination } from 'src/app/entities/boxx-response';
import { getErrorMessage } from 'src/app/utils/utils';
import { Store, select } from '@ngrx/store';
import { getQuoteSelector } from 'src/app/store/create-quote/create-quote.selector';

@Component({
  selector: 'app-broker-commission-v2-modal',
  templateUrl: './broker-commission-v2-modal.component.html',
  styleUrls: ['./broker-commission-v2-modal.component.less'],
})
export class BrokerCommissionv2ModalComponent implements OnInit {
  @Input() commissionSelected?: ICommission;
  @Input() showCommissionModal: boolean = false;
  @Input() headerText: string = "Add or update broker's new commission rate!";
  @Input() brokerInfo: Array<any> = [];
  @Input() stepperInputsInfo: Array<any> = [];
  @Input() productsInfo: Array<any> = [];
  @Input() regionInfo: Array<any> = [];
  @Input() brokerageId;
  @Input() brokerage;
  @Input() insured;
  @Input() commissionDisabled: Array<any> = [];
  @Input() commissionId;

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleNotifMsg = new EventEmitter<any>();
  @Output() handleAddCommission = new EventEmitter<any>();
  @Input() resetAddFormSubject: Subject<boolean> = new Subject<boolean>();

  actionCommission: ActionsCommission;
  formCommissionPopup: FormGroup;
  plusCircle: string = 'assets/img/plus-circle-v2.svg';
  maxPercentage?: number = 0;
  minPercentage?: number = 0;
  defaultPercentage?: number = 0;
  minRangePercentage: number = 1;
  maxRangePercentage: number = 50;
  isActive: boolean = true;
  products = [];
  regions = [];
  selectedProductOption;
  selectedRegionOption;
  submitted: boolean = false;
  regionId = '';
  productId: string;
  hasErrors: boolean = false;
  showSpinner: boolean = false;
  brokerageCommissions: { data: Commission[]; pagination: Pagination | null } =
    { data: [], pagination: null };
  commissionConfirmationData: any;
  showConfirmationModal: boolean = false;
  disableAddCommBtn: boolean = false;
  commissionOverride: boolean = false;
  alertInfo = {
    show: false,
    type: '',
    header: '',
    body: '',
  };
  form$: Observable<any>;
  showEnableCommissionFlag = true;

  constructor(
    private fb: FormBuilder,
    private riskRegionService: RiskRegionService,
    private translate: TranslateService,
    private brokerageCommissionService: BrokerageCommissionService,
    private brokerageProducerCommissionService: BrokerageProducerCommissionService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.formCommissionPopup = this.fb.group({
      productId: ['', [Validators.required]],
      regionId: ['', [Validators.required]],
      maxPercentage: [
        0,
        [
          Validators.max(this.maxRangePercentage),
          Validators.min(this.minRangePercentage),
        ],
      ],
      minPercentage: [
        0,
        [
          (control: AbstractControl) =>
            Validators.max(this.maxPercentage)(control),
          Validators.max(this.maxRangePercentage),
          Validators.min(this.minRangePercentage),
        ],
      ],
      defaultPercentage: [
        0,
        [
          Validators.max(this.maxPercentage),
          Validators.min(this.minPercentage),
        ],
      ],
    });
    this.initLoadStore();

    this.form$ = this.store.select(getQuoteSelector);
    this.form$.subscribe(
      (event) => (
        (this.regionId = this.regionId ?? event.ui.regionId),
        (this.insured = this.insured ?? event.ui.insuredName),
        (this.brokerage = this.brokerage
          ? this.brokerage.name
          : event.ui.broker)
      ),
    );

    this.regions = [
      {
        value: this.translate.instant('common.selectAll'),
        key: this.translate.instant('common.selectAll'),
        id: -1,
      },
    ];
    // this.riskRegionService.getRiskRegion(Number(this.productId)).subscribe({
    //   next: (response) => {
    //     const regions = response.data.map((item) => {
    //       return {
    //         value: item.region.name,
    //         key: item.region.name,
    //         id: item.id,
    //       };
    //     });
    //     this.regions = [...this.regions, ...regions];
    //   },
    // });
  }

  private initLoadStore = () => {
    this.riskRegionService.getAllRisk().subscribe({
      next: (response) => {
        this.products = response.data.map((product) => {
          return { value: product.name, key: product.name, id: product.id };
        });
      },
    });
  };

  onChangeProduct(value) {
    if (this.productId !== value) {
      this.productId = value;
      const productSelected = this.products.find((item) => item.id === value);
      this.regionId = '';
      this.formCommissionPopup.get('regionId').setValue('');
      this.regions = [
        {
          value: this.translate.instant('common.selectAll'),
          key: this.translate.instant('common.selectAll'),
          id: -1,
        },
      ];
      this.riskRegionService.getRiskRegion(productSelected.id).subscribe({
        next: (response) => {
          const regions = response.data.map((item) => {
            return {
              value: item.region.name,
              key: item.region.name,
              id: item.id,
            };
          });
          this.regions = [...this.regions, ...regions];
        },
      });
    }
  }

  async onChangeRegion(value) {
    this.regionId = value;
    if (Number(this.regionId) === -1) {
      await this.getAllCommission();
      if (this.brokerageCommissions.data?.length > 0) {
        this.disableAddCommBtn = true;
      } else {
        this.disableAddCommBtn = false;
      }
    } else {
      this.disableAddCommBtn = false;
    }
  }

  onChangeMinPercentage(value) {
    this.minPercentage = value;
    this.handleMinPercentageValidate(value);
    this.handleDefaultPercentageValidate(this.defaultPercentage);
  }

  handleMinPercentageValidate(event) {
    const minPercentage = event?.target?.value ? event?.target?.value : event;

    if (Number(minPercentage) >= 1) {
      this.formCommissionPopup.controls['minPercentage'].setErrors(null);
    } else {
      this.formCommissionPopup.controls['minPercentage'].setErrors({
        message: this.translate.instant('error.minValueValidationErrMsg'),
      });
    }
  }

  onChangeMaxPercentage(value) {
    this.maxPercentage = value;
    this.handleMaxPercentageValidate(value);
    this.handleDefaultPercentageValidate(this.defaultPercentage);
  }

  handleMaxPercentageValidate(event) {
    const maxPercentage = event?.target?.value ? event?.target?.value : event;

    if (
      Number(maxPercentage) >= 1 &&
      Number(maxPercentage) > this.minPercentage
    ) {
      this.formCommissionPopup.controls['maxPercentage'].setErrors(null);
    } else {
      this.formCommissionPopup.controls['maxPercentage'].setErrors({
        message: this.translate.instant('error.minValueValidationErrMsg'),
      });
    }
  }

  onChangeDefaultPercentage(value: number) {
    this.defaultPercentage = value;
    this.handleDefaultPercentageValidate(value);
  }

  handleDefaultPercentageValidate(event) {
    const defaultPercentage = event?.target?.value
      ? event?.target?.value
      : event;
    if (
      defaultPercentage >= this.minPercentage &&
      defaultPercentage <= this.maxPercentage
    ) {
      this.formCommissionPopup.controls['defaultPercentage'].setErrors(null);
    } else {
      this.formCommissionPopup.controls['defaultPercentage'].setErrors({
        message: this.translate.instant('error.defaultValueValidationErrMsg'),
      });
    }
  }

  handleSubmitBroker(event, isSelectAllRegion = false) {
    if (!this.isActive) {
      return;
    }
    const { commission, method } = event;
    this.submitted = true;
    this.formCommissionPopup.get('maxPercentage').setValue(this.maxPercentage);
    this.formCommissionPopup.get('minPercentage').setValue(this.minPercentage);
    this.formCommissionPopup
      .get('defaultPercentage')
      .setValue(this.defaultPercentage);

    if (this.formCommissionPopup.controls['maxPercentage'].hasError('max')) {
      this.formCommissionPopup.controls['maxPercentage'].setErrors({
        message: this.translate.instant('error.maxValueValidationErrMsg'),
      });
    }

    if (this.formCommissionPopup.controls['maxPercentage'].hasError('min')) {
      this.formCommissionPopup.controls['maxPercentage'].setErrors({
        message: this.translate.instant('error.minValueValidationErrMsg'),
      });
    }

    if (this.formCommissionPopup.controls['minPercentage'].hasError('min')) {
      this.formCommissionPopup.controls['minPercentage'].setErrors({
        message: this.translate.instant('error.minValueValidationErrMsg'),
      });
    }

    if (this.formCommissionPopup.controls['minPercentage'].hasError('max')) {
      this.formCommissionPopup.controls['minPercentage'].setErrors({
        message: this.translate.instant('error.minValueGreaterErrMsg'),
      });
    }

    if (
      this.formCommissionPopup.controls['defaultPercentage'].value &&
      this.formCommissionPopup.controls['defaultPercentage'].value >=
        this.formCommissionPopup.controls['minPercentage'].value &&
      this.formCommissionPopup.controls['defaultPercentage'].value <=
        this.formCommissionPopup.controls['maxPercentage'].value
    ) {
      this.formCommissionPopup.controls['defaultPercentage'].setErrors(null);
    } else {
      this.formCommissionPopup.controls['defaultPercentage'].setErrors({
        message: this.translate.instant('error.defaultValueValidationErrMsg'),
      });
    }

    if (this.formCommissionPopup.valid) {
      this.showSpinner = true;
      const regionIdSelected = this.regions.find(
        (item) => item.id === this.regionId,
      ).id;

      const commission: ICommissionModel = {
        active: true,
        brokerageId: this.brokerageId,
        commissionMax: Number(this.maxPercentage) / 100 || 0,
        commissionMin: Number(this.minPercentage) / 100 || 0,
        commissionDefault: Number(this.defaultPercentage) / 100 || 0,
        riskRegionId: Number(regionIdSelected),
        type: 1,
        override: this.commissionOverride,
        riskId: Number(this.productId),
      };
      if (!isSelectAllRegion) {
        this.saveCommissionAction(commission);
      } else {
        this.saveCommissionForSelectAll(commission);
      }
    }
  }

  handleCloseModal() {
    this.showCommissionModal = true;
    this.handleClose.emit();
  }

  async getAllCommission(pageNumber: number = 1) {
    const riskId = Number(this.productId);
    return new Promise<any>(async (resolve, reject) => {
      await this.brokerageCommissionService
        .GetAllCommissions(
          { brokerageId: this.brokerageId, riskId },
          pageNumber,
        )
        .subscribe({
          next: (response) => {
            ({
              data: this.brokerageCommissions.data,
              pagination: this.brokerageCommissions.pagination,
            } = response);
            resolve(true);
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.alertInfo = {
                show: true,
                type: 'error',
                header: 'Error',
                body: getErrorMessage(error),
              };
              this.handleNotifMsg.emit(this.alertInfo);
            }
          },
        });
    });
  }

  closeModalConfirmation() {
    this.showConfirmationModal = false;
    this.commissionConfirmationData = null;
  }

  handleCommissionOverride(event) {
    this.commissionOverride = true;
    this.handleSubmitBroker(event, true);
  }

  handleConfirmNoOverride(event) {
    this.commissionOverride = false;
    this.handleSubmitBroker(event, true);
  }

  async saveCommissionAction(commission) {
    let restoreCommission = false;
    let index = -1;
    if (this.commissionDisabled.length > 0) {
      index = this.commissionDisabled.findIndex(
        (a) =>
          a?.riskRegion?.risk?.id === commission.riskId &&
          a?.riskRegion?.id === commission.riskRegionId,
      );
      if (index != -1) restoreCommission = true;
    }
    if (restoreCommission) {
      let isSuccess = 0;
      delete commission.active;
      commission.type = 1;
      let commissionId = this.commissionDisabled[index]?.id;
      if (commissionId) {
        await new Promise<any>(async (resolve, reject) => {
          this.brokerageCommissionService
            .restoreCommission(commissionId)
            .subscribe({
              next: (_) => {
                this.alertInfo = {
                  show: true,
                  type: 'success',
                  header: 'success!',
                  body: this.translate.instant(
                    'workFlow3.components.brokerCommissionPopup.success.commissionEnabled',
                  ),
                };
                this.handleNotifMsg.emit(this.alertInfo);
                resolve(true);
                isSuccess = 1;
              },
              error: (error) => {
                if (![500].includes(error?.status)) {
                  this.alertInfo = {
                    show: true,
                    type: 'error',
                    header: 'error!',
                    body: getErrorMessage(error),
                  };
                  this.handleNotifMsg.emit(this.alertInfo);
                }
                reject(false);
              },
            });
        });

        this.brokerageCommissionService
          .updateCommission(commission, commissionId)
          .subscribe({
            next: (_) => {
              this.alertInfo = {
                show: true,
                type: 'success',
                header: 'success!',
                body: this.translate.instant(
                  'workFlow3.components.brokerCommissionPopup.success.commissionUpdated',
                ),
              };
              this.handleNotifMsg.emit(this.alertInfo);
              this.resetForm();
              this.showSpinner = false;
              this.showCommissionModal = false;
              this.handleClose.emit();
              this.commissionOverride = false;
            },
            error: (error) => {
              if (![500].includes(error?.status)) {
                this.alertInfo = {
                  show: true,
                  type: 'error',
                  header: 'error!',
                  body: getErrorMessage(error),
                };
                this.handleNotifMsg.emit(this.alertInfo);
                this.showCommissionModal = false;
                this.handleClose.emit();
              }
            },
          });

        if (isSuccess == 2) {
          this.showSpinner = false;
        }
      }
    } else {
      this.brokerageCommissionService.createCommission(commission).subscribe({
        next: (response) => {
          this.showSpinner = false;
          this.alertInfo = {
            show: true,
            type: 'success',
            header: 'Success',
            body: this.translate.instant(
              'workFlow3.components.brokerCommissionPopup.success.CommissionAdded',
            ),
          };
          this.handleNotifMsg.emit(this.alertInfo);
          this.resetForm();
          this.showSpinner = false;
          this.showCommissionModal = false;
          this.handleClose.emit();
          this.commissionOverride = false;
        },
        error: (error) => {
          this.alertInfo = {
            show: true,
            type: 'error',
            header: 'Error',
            body: getErrorMessage(error),
          };
          this.showCommissionModal = false;
          this.handleClose.emit();
          this.showSpinner = false;
          this.commissionOverride = false;
          this.handleNotifMsg.emit(this.alertInfo);
        },
      });
    }
  }

  async saveCommissionForSelectAll(commission) {
    this.brokerageCommissionService.createCommission(commission).subscribe({
      next: (response) => {
        this.showSpinner = false;
        this.alertInfo = {
          show: true,
          type: 'success',
          header: 'Success',
          body: 'Commission added.',
        };
        this.handleNotifMsg.emit(this.alertInfo);
        this.resetForm();
        this.showSpinner = false;
        this.showCommissionModal = false;
        this.handleClose.emit();
        this.commissionOverride = false;
      },
      error: (error) => {
        this.alertInfo = {
          show: true,
          type: 'error',
          header: 'Error',
          body: getErrorMessage(error),
        };
        this.showCommissionModal = false;
        this.handleClose.emit();
        this.handleNotifMsg.emit(this.alertInfo);
      },
    });
  }

  resetForm() {
    this.submitted = false;
    this.formCommissionPopup.reset();
    this.maxPercentage = 0;
    this.minPercentage = 0;
    this.defaultPercentage = 0;
    this.productId = '';
    this.regionId = '';
  }

  showEnableCommissionSwitch() {
    if (this.commissionDisabled.length > 0) {
      let index = this.commissionDisabled.findIndex(
        (a) =>
          a.riskRegion.id === this.regionId &&
          a.riskRegion.risk.id === this.productId,
      );
      if (index != -1) {
        return true;
      }
    }
    return false;
  }
  switchChangeEnableCommission() {
    this.showEnableCommissionFlag = !this.showEnableCommissionFlag;
  }
}
