import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BoxxResponse } from '../entities/boxx-response';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private http: HttpClient) {}

  getLanguageId(): Observable<BoxxResponse<any>> {
    const { msConfigurationBaseUrl, languagePathUrl } = environment;
    const url = `${msConfigurationBaseUrl}${languagePathUrl}`;

    return this.http.get<BoxxResponse<any>>(url);
  }
}
