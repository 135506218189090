<div class="brokerage-profile-page">
  <app-navigation-v2
    [showHeaderBanner]="true"
    [mainHeading]="''"
    [subHeading]="''"
    [isNeeded]="false"
    [showHeader]="true"
    [items]="breadcrumbsMenu"
    (suggestedSearchSelected)="handleGlobalSearch($event)"
  >
  </app-navigation-v2>
  <!-- title header -->
  <div class="alert-holder">
    <boxxds-alert-v2
      *ngIf="alertData?.show"
      [type]="alertData?.type"
      [headerText]="alertData?.header | translate"
      [bodyText]="alertData?.body | translate"
      (handleCloseEvent)="handleAlertClose()"
    ></boxxds-alert-v2>
  </div>
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>
  <section class="title-section">
    <div class="max-width-wrapper">
      <div class="title-description-container">
        <div class="title-container">
          <boxxds-heading
            class="title"
            [sz]="'6'"
            [text]="branchName"
            type="bold"
          ></boxxds-heading>
          <p *ngIf="!isActiveBranch" class="danger-badge">Inactive</p>
          <p *ngIf="isActiveBranch" class="active-badge">Active</p>
        </div>
        <boxxds-body
          class="description"
          [text]="brokerageName"
          [customStyle]="{
            fontFamily: 'Gibson',
            color: '#475467'
          }"
        >
        </boxxds-body>
      </div>
      <div class="button-container">
        <boxxds-button-v2
          *ngIf="!isActiveBranch"
          [isDisabled]="!isBrokerageActive"
          [buttonText]="'workFlow3.branchProfile.label.activate' | translate"
          [size]="'md'"
          [btnClass]="'secondary gray'"
          [btnType]="'default'"
          (handleClick)="isBrokerageActive && handleActivateBranch($event)"
        >
        </boxxds-button-v2>
        <boxxds-button-v2
          *ngIf="isActiveBranch"
          [isDisabled]="!isBrokerageActive"
          [buttonText]="'workFlow3.branchProfile.label.deactivate' | translate"
          [size]="'md'"
          [btnClass]="'secondary gray'"
          [btnType]="'default'"
          (handleClick)="isBrokerageActive && handleDeactivateBranch($event)"
        >
        </boxxds-button-v2>
        <boxxds-button-v2
          *ngIf="activeCategoryFilter === 'Branch information'"
          [buttonText]="'workFlow3.branchProfile.label.addProducer' | translate"
          [type]="'submit'"
          [isDisabled]="!isActiveBranch"
          [size]="'md'"
          [btnClass]="'primary'"
          [btnType]="'default'"
          [isDropdown]="false"
          (handleClick)="handleAddProducer()"
        ></boxxds-button-v2>
      </div>
    </div>
  </section>
  <section class="content-section">
    <div class="max-width-wrapper">
      <div class="filter-section">
        <div class="category-filter">
          <ul>
            <ng-container *ngFor="let category of categoryFilters">
              <li
                (click)="
                  activeCategoryFilter !== category &&
                    handleCategoryFilter(category)
                "
                [class.active]="activeCategoryFilter === category"
              >
                {{ category }}
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
    <div class="max-width-wrapper">
      <div
        class="dynamic-content-wrapper brokerage-information"
        *ngIf="activeCategoryFilter === 'Branch information'"
      >
        <div class="edit-branch-form">
          <app-view-branch-v2
            [brokerageId]="brokerageId"
            [branchId]="branchId"
            [isActive]="isActiveBranch"
            (handleDiscard)="handleDiscard($event)"
            (handleDeactivateBranchChild)="handleDeactivateBranchChild($event)"
          ></app-view-branch-v2>
        </div>
      </div>
      <div
        class="dynamic-content-wrapper commission-rates"
        *ngIf="activeCategoryFilter === 'Producer'"
      >
        <app-view-producer-v2
          [brokerageId]="brokerageId"
          [branchId]="branchId"
          [showProducerForm]="showProducerForm"
          [isBranchActive]="isActiveBranch"
          [isActiveBranch]="isActiveBranch"
          [addProducer]="addProducer"
        ></app-view-producer-v2>
      </div>
      <!-- commission rates end -->
    </div>
    <div class="footer-section" *ngIf="activeCategoryFilter === 'Producer'">
      <app-pagefooter-timestamp-v2
        *ngIf="createdBy && createdAt"
        [createdBy]="createdBy"
        [createdAt]="createdAt"
        [updatedBy]="updatedBy"
        [updatedAt]="updatedAt"
      >
      </app-pagefooter-timestamp-v2>
    </div>
  </section>
  <!-- content-section end -->
</div>
