<div class="holder">
  <div class="alert-holder">
    <boxxds-alert
      *ngIf="hasError"
      (handleCloseEvent)="handleCloseSuccessEvent()"
      type="error"
      headerText="error!"
      [bodyText]="errorMessage"
    >
    </boxxds-alert>
    <boxxds-alert
      (handleCloseEvent)="handleCloseSuccessEvent()"
      *ngIf="hasErrorProfilePic"
      type="error"
      headerText="error!"
      [bodyText]="errorMessageProfilePic"
    >
    </boxxds-alert>
  </div>

  <boxxds-breadcrumb [items]="itemsMenu"></boxxds-breadcrumb>
  <boxxds-heading sz="5" [text]="'New Brokerage' | translate"> </boxxds-heading>

  <div class="form-panel-title">
    <boxxds-body
      sz="lg"
      [text]="'Brokerage details' | translate"
      [ngStyle]="{ color: '#BDBDBF', textAlign: 'left' }"
    >
    </boxxds-body>

    <boxxds-body
      sz="xs"
      [text]="'*REQUIRED'"
      [ngStyle]="{ color: '#BDBDBF', textAlign: 'right', 'margin-top': '5px' }"
    >
    </boxxds-body>
  </div>

  <form [formGroup]="form">
    <app-brokerage-details
      [isActive]="true"
      [form]="brokerage"
      [requiredErrMsg]="requiredErrMsg"
      [invalidErrMsg]="invalidErrMsg"
      [parentCompanyInvalidErrMsg]="parentCompanyInvalidErrMsg"
      [formSubmitted]="formSubmitted"
      (triggerProfilePicErrorToParent)="triggerProfilePicErrorToParent($event)"
    >
    </app-brokerage-details>

    <app-location-details-form
      (eventEmitRegionIdSelected)="regionIdSelected($event)"
      [form]="location"
      [requiredErrMsg]="requiredErrMsg"
      [formSubmitted]="formSubmitted"
    >
    </app-location-details-form>

    <div class="footer-add-branch">
      <button
        *ngIf="showSpinner"
        class="btn-processing"
        tabindex="0"
        [ngStyle]="{
          border: '1px solid #FFBE3D',
          borderRadius: '8px',
          width: '150px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '4px 8px',
          background: '#FCF8EF'
        }"
      >
        <boxxds-spinner></boxxds-spinner>
      </button>
      <boxxds-button
        *ngIf="!showSpinner"
        [buttonText]="'Add new brokerage'"
        (handleClick)="handleSubmit($event)"
        [type]="'button'"
        btnType="primary-outlined"
        [btnStyle]="{
          background: 'none',
          border: '1px solid #FFBE3D',
          padding: '0 30px'
        }"
      >
      </boxxds-button>
    </div>
  </form>
</div>
