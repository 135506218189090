import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICommissionModel } from '../models/commission.model';
import { BoxxResponse } from '../entities/boxx-response';
import { Commission } from 'src/app/entities/commission';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BrokerageCommissionService {
  // Base url
  baseurl =
    environment.msBrokerBaseUrl + environment.brokerageCommissionPathUrl;

  constructor(private http: HttpClient) {}
  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  createTrsCommission(commissions: ICommissionModel[]): Observable<any> {
    const {
      baseUrl,
      apiVersion,
      msBroker,
      brokerageCommissionAtomicTrxPathUrl,
    } = environment;
    const url = `${baseUrl}${msBroker}${apiVersion}${brokerageCommissionAtomicTrxPathUrl}`;

    return this.http.post(url, { commissions });
  }

  createCommission(commission: ICommissionModel): Observable<any> {
    const url = `${this.baseurl}`;

    return this.http
      .post(url, JSON.stringify(commission), this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  updateCommission(
    commission: ICommissionModel,
    brokerageCommissionId: number,
  ): Observable<any> {
    const url = `${this.baseurl}${brokerageCommissionId}`;

    return this.http
      .put(url, JSON.stringify(commission), this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  deleteCommission(
    brokerageCommissionId: number,
    queryParams?: any,
  ): Observable<any> {
    let url = `${this.baseurl}${brokerageCommissionId}`;
    let isFirstQueryParam = true;
    for (const key in queryParams) {
      if (
        queryParams.hasOwnProperty(key) &&
        queryParams[key] !== undefined &&
        queryParams[key] !== null &&
        queryParams[key] !== ''
      ) {
        if (isFirstQueryParam) {
          url += `?${key}=${queryParams[key]}`;
          isFirstQueryParam = false;
        } else {
          url += `&${key}=${queryParams[key]}`;
        }
      }
    }
    return this.http
      .delete(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  restoreCommission(brokerageCommissionId: number): Observable<any> {
    const url = `${this.baseurl}restore/${brokerageCommissionId}`;

    return this.http
      .put(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetByRegionIdAndRiskIdsAndBrokerageId(
    regionId,
    riskId,
    brokerageId,
  ): Observable<BoxxResponse<any>> {
    const { baseUrl, apiVersion, msBroker, brokerageCommissionPathUrl } =
      environment;
    const url = `${baseUrl}${msBroker}${apiVersion}${brokerageCommissionPathUrl}?regionId=${regionId}&riskId=${riskId}&brokerageId=${brokerageId}`;

    return this.http.get<BoxxResponse<any>>(url);
  }

  GetAllCommissions(
    filter: { brokerageId: number; riskId?: number } = null,
    page = 1,
    limit = 10,
    sort = 'id:desc',
  ): Observable<BoxxResponse<Commission>> {
    let queryParams = '';
    if (filter !== null) {
      queryParams =
        '&' +
        Object.entries(filter)
          .map(
            ([key, value]) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
          )
          .join('&');
    }
    let url = `${this.baseurl}?&page=${page}&limit=${limit}&sort=${sort}${queryParams}`;
    return this.http
      .get<BoxxResponse<Commission>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  GetAllActiveQuoteList(
    brokerageCommissionId: number,
    queryParams,
    page = 1,
    limit = 10,
    sort = 'effectiveDt:desc',
  ): Observable<BoxxResponse<Commission>> {
    let url = `${this.baseurl}activeQuotes/${brokerageCommissionId}?&page=${page}&limit=${limit}&sort=${sort}`;

    // Append additional queryParams to the URL
    for (const key in queryParams) {
      if (
        queryParams.hasOwnProperty(key) &&
        queryParams[key] !== undefined &&
        queryParams[key] !== null &&
        queryParams[key] !== ''
      ) {
        url += `&${key}=${queryParams[key]}`;
      }
    }
    return this.http
      .get<BoxxResponse<Commission>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  // Error handling
  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else if (error.status == 400) {
      errorMessage = error.error;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return throwError(() => {
      return errorMessage;
    });
  }
}
