<div
  class="holder"
  [ngClass]="{ 'alert-visible': isBindingPolicy }"
  *ngIf="quoteOptions.length > 0"
>
  <div class="alert-holder">
    <boxxds-alert
      *ngIf="isBindingPolicy"
      [headerText]="alertMessage | translate"
      [containerStyle]="{
        width: 'calc(100vw - 320px)',
        maxWidth: '1115px',
        marginBottom: '0'
      }"
    ></boxxds-alert>
    <boxxds-alert
      *ngIf="showErrorAlert && !isQuoteBounded"
      type="error"
      [headerText]="'common.errorMsg' | translate"
      [bodyText]="alertMessage | translate"
      (handleCloseEvent)="closeAlert()"
    >
    </boxxds-alert>
  </div>
  <div class="header" *ngIf="form$ | async as form">
    <div class="quote-head-wraper">
      <boxxds-heading
        [sz]="'5'"
        [text]="'quoteSummary.quoteSummary' | translate"
      >
      </boxxds-heading>
    </div>
    <div
      class="btns-holder d-flex"
      *ngIf="
        this.permissionList[this.currentScreen] &&
        this.currentQuoteStatus !== 'quote-closed'
      "
    >
      <boxxds-button
        *ngIf="!isSendingQuote"
        rightImage="send"
        [buttonText]="'quoteSummary.sendQuote' | translate"
        [type]="'submitted'"
        btnType="primary-outlined"
        [btnStyle]="{ width: '141px' }"
        (handleClick)="handleSendQuote()"
        [isDisabled]="showBodySpinner || isBindingPolicy"
      >
      </boxxds-button>
      <button
        *ngIf="isSendingQuote"
        class="btn-processing"
        tabindex="0"
        [ngStyle]="{
          border: '1px solid #FFBE3D',
          borderRadius: '8px',
          width: '141px',
          height: '32px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '4px 8px',
          background: '#FCF8EF',
          marginLeft: 'auto'
        }"
      >
        <boxxds-spinner></boxxds-spinner>
      </button>
    </div>
    <app-modal-send
      *ngIf="showSendModal"
      [isLoader]="isLoader"
      [showModal]="showSendModal"
      (handleClose)="closeSendPackage()"
      [formDetails]="form.ui"
      [templateDetails]="templateDetails"
      [docDetails]="docDetails"
      [title]="
        isQuoteBounded
          ? 'Sending quote payment summary'
          : 'Sending quote summary'
      "
      (handleSend)="handleSend($event)"
    >
    </app-modal-send>
  </div>
  <div class="insured-name">
    {{ "quoteSummary.quoteID" | translate }}: {{ (form$ | async)?.ui.policyId }}
  </div>
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!showBodySpinner">
    <div class="content">
      <div *ngIf="isBindingPolicy" class="binding-overlay"></div>
      <boxxds-body
        sz="lg"
        [text]="'quoteSummary.insurerDetails' | translate"
        [customStyle]="{
          color: '#BDBDBF',
          marginBottom: '4px',
          textAlign: 'left'
        }"
      ></boxxds-body>
      <div class="details-box">
        <div class="first-col">
          <div class="insured-name">
            <boxxds-body
              sz="xs"
              [text]="'quoteSummary.insuredName' | translate"
              [customStyle]="{
                color: '#8F8F91',
                marginBottom: '2px',
                textAlign: 'left',
                textTransform: 'uppercase'
              }"
            >
            </boxxds-body>
            <boxxds-body
              sz="lg"
              [text]="insured.name"
              [customStyle]="{ color: '#333334', textAlign: 'left' }"
            >
            </boxxds-body>
          </div>
          <div class="address">
            <boxxds-body
              sz="xs"
              [text]="'quoteSummary.address' | translate"
              [customStyle]="{
                color: '#8F8F91',
                marginBottom: '2px',
                textAlign: 'left',
                textTransform: 'uppercase'
              }"
            >
            </boxxds-body>
            <boxxds-body
              sz="lg"
              [text]="insured.address"
              [customStyle]="{
                color: '#333334',
                textAlign: 'left',
                maxWidth: '310px',
                whiteSpace: 'pre-line'
              }"
            >
            </boxxds-body>
          </div>
        </div>
        <div class="sec-col">
          <div class="industry">
            <boxxds-body
              sz="xs"
              [text]="'quoteSummary.industry' | translate"
              [customStyle]="{
                color: '#8F8F91',
                marginBottom: '2px',
                textAlign: 'left',
                textTransform: 'uppercase'
              }"
            >
            </boxxds-body>
            <boxxds-body
              sz="lg"
              [text]="insured.industry"
              [customStyle]="{ color: '#333334', textAlign: 'left' }"
            >
            </boxxds-body>
          </div>
          <div class="address">
            <boxxds-body
              sz="xs"
              [text]="'quoteSummary.industryClass' | translate"
              [customStyle]="{
                color: '#8F8F91',
                marginBottom: '2px',
                textAlign: 'left',
                textTransform: 'uppercase'
              }"
            >
            </boxxds-body>
            <boxxds-body
              sz="lg"
              [text]="insured.industryClass"
              [customStyle]="{ color: '#333334', textAlign: 'left' }"
            >
            </boxxds-body>
          </div>
        </div>
      </div>
      <div class="summary-content d-flex">
        <div class="left-col">
          <boxxds-heading
            [sz]="'44'"
            [text]="totalBilledAnnually | currency: currency"
            type="bold"
            [customStyle]="{ color: '#333334' }"
          >
          </boxxds-heading>
          <div class="border-content">
            <boxxds-body
              sz="xs"
              [text]="'quoteSummary.totalBilledAnnually' | translate"
              [customStyle]="{
                color: '#8F8F91',
                textAlign: 'left',
                textTransform: 'uppercase'
              }"
            >
            </boxxds-body>
            <div class="quote-optn">
              <boxxds-body
                sz="xs"
                [text]="
                  'Quote Option ' +
                  getSelectedQuote(quoteOptions)[0].optionNumber
                "
                type="bold"
                [customStyle]="{
                  color: '#8F8F91',
                  fontSize: '20px',
                  fontWeight: '600',
                  lineHeight: '32px',
                  textAlign: 'left',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
              <boxxds-body
                sz="xs"
                [text]="'quoteSummary.coverageDates' | translate"
                [customStyle]="{
                  color: '#8F8F91',
                  marginTop: '8px',
                  marginBottom: '8px',
                  textAlign: 'left',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
              <boxxds-body
                sz="md"
                [text]="
                  (effDate | date: 'MMM dd, yyyy') +
                  ' - ' +
                  (expDate | date: 'MMM dd, yyyy')
                "
                [customStyle]="{ color: '#8F8F91', textAlign: 'left' }"
              >
              </boxxds-body>
            </div>
            <div class="amt-details d-flex">
              <boxxds-body
                sz="xs"
                [text]="'quoteSummary.subtotal' | translate"
                [customStyle]="{
                  color: '#8F8F91',
                  textAlign: 'left',
                  width: '60px',
                  marginRight: '8px',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
              <boxxds-body
                sz="lg"
                [text]="getSubTotal() | currency: currency : 'symbol-narrow'"
                [customStyle]="{
                  color: '#333334',
                  textAlign: 'left',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
            </div>
            <div class="amt-details d-flex">
              <boxxds-body
                sz="xs"
                [text]="'quoteSummary.services' | translate"
                [customStyle]="{
                  color: '#8F8F91',
                  textAlign: 'left',
                  width: '60px',
                  marginRight: '8px',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
              <boxxds-body
                sz="lg"
                [text]="getServices() | currency: currency"
                [customStyle]="{
                  color: '#333334',
                  textAlign: 'left',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
            </div>

            <div class="amt-details d-flex" *ngIf="TRIAFeeEnabled">
              <boxxds-body
                sz="xs"
                [text]="'quoteSummary.tria' | translate"
                [customStyle]="{
                  color: '#8F8F91',
                  textAlign: 'left',
                  width: '60px',
                  marginRight: '8px',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
              <div class="policy-fee-val d-flex">
                <boxxds-body
                  *ngIf="!isTRIAAmountEditMode"
                  sz="lg"
                  [text]="
                    getTRIA() | currency: currency : 'symbol-narrow' : '1.0-0'
                  "
                  [customStyle]="{
                    color: '#333334',
                    textAlign: 'left',
                    textTransform: 'uppercase'
                  }"
                >
                </boxxds-body>
                <div
                  class="d-flex"
                  *ngIf="
                    this.permissionList[this.currentScreen] &&
                    this.currentQuoteStatus !== 'quote-closed'
                  "
                >
                  <button
                    *ngIf="!isTRIAAmountEditMode"
                    class="btn-edit"
                    (click)="toggleTRIAAmountEdit($event)"
                  >
                    <img src="assets/img/edit-icon.png" alt="edit" />
                  </button>
                  <ng-container *ngIf="isTRIAAmountEditMode">
                    <boxxds-textinput
                      [validatePositiveInteger]="true"
                      [inputAutoFocus]="true"
                      [form]="policyFeeForm"
                      [placeholder]="'quoteSummary.enterTria' | translate"
                      [control]="'triaAmount'"
                      [id]="'triaAmount'"
                      [width]="'100%'"
                      mask="separator.2"
                      maxLength="10"
                      thousandSeparator=","
                      [containerStyle]="{ maxWidth: '125px' }"
                      [invalidErrMsg]="
                        'quoteSummary.errors.triaAmount' | translate
                      "
                      (handleBlur)="toggleTRIAAmountEdit($event)"
                    >
                    </boxxds-textinput>
                  </ng-container>
                </div>
              </div>
            </div>

            <div class="amt-details d-flex" *ngIf="policyFeeEnabled">
              <boxxds-body
                sz="xs"
                [text]="'quoteSummary.policyFees' | translate"
                [customStyle]="{
                  color: '#8F8F91',
                  textAlign: 'left',
                  width: '60px',
                  marginRight: '8px',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
              <div class="policy-fee-val d-flex">
                <boxxds-body
                  *ngIf="!isPolicyFeeEditMode"
                  sz="lg"
                  [text]="
                    getPolicyFee()
                      | currency: currency : 'symbol-narrow' : '1.0-0'
                  "
                  [customStyle]="{
                    color: '#333334',
                    textAlign: 'left',
                    textTransform: 'uppercase'
                  }"
                >
                </boxxds-body>
                <div
                  class="d-flex"
                  *ngIf="
                    this.permissionList[this.currentScreen] &&
                    this.currentQuoteStatus !== 'quote-closed'
                  "
                >
                  <button
                    *ngIf="!isPolicyFeeEditMode"
                    class="btn-edit"
                    (click)="togglePolicyFeeEdit($event)"
                  >
                    <img src="assets/img/edit-icon.png" alt="edit" />
                  </button>
                  <ng-container *ngIf="isPolicyFeeEditMode">
                    <boxxds-textinput
                      [validatePositiveInteger]="true"
                      [inputAutoFocus]="true"
                      [form]="policyFeeForm"
                      [placeholder]="'quoteSummary.enterPolicyFees' | translate"
                      [control]="'policyFee'"
                      [id]="'policyFee'"
                      [width]="'100%'"
                      mask="separator.2"
                      maxLength="10"
                      thousandSeparator=","
                      [containerStyle]="{ maxWidth: '125px' }"
                      [invalidErrMsg]="
                        'quoteSummary.errors.policyFee' | translate
                      "
                      (handleBlur)="togglePolicyFeeEdit($event)"
                    >
                    </boxxds-textinput>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="amt-details d-flex">
              <boxxds-body
                sz="xs"
                [text]="'quoteSummary.taxes' | translate"
                [customStyle]="{
                  color: '#8F8F91',
                  textAlign: 'left',
                  width: '60px',
                  marginRight: '8px',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
              <boxxds-body
                sz="lg"
                [text]="getTaxes() | currency: currency"
                [customStyle]="{
                  color: '#333334',
                  textAlign: 'left',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
            </div>
            <ng-container
              *ngIf="
                this.permissionList[this.currentScreen] &&
                this.currentQuoteStatus !== 'quote-closed'
              "
            >
              <boxxds-switch
                name="tria-fee"
                (changed)="triaSwitchHandler(TRIAFeeEnabled)"
                [label]="
                  TRIAFeeEnabled
                    ? ('quoteSummary.includedTria' | translate)
                    : ('quoteSummary.includeTria' | translate)
                "
                [ngStyle]="{ marginBottom: '0px', marginTop: '125px' }"
                [checked]="TRIAFeeEnabled"
              >
              </boxxds-switch>
              <boxxds-switch
                name="policy-fee"
                (changed)="policyFeeSwitchHandler(policyFeeEnabled)"
                [label]="
                  policyFeeEnabled
                    ? ('quoteSummary.includedPolicyFees' | translate)
                    : ('quoteSummary.includePolicyFees' | translate)
                "
                [ngStyle]="{ marginBottom: '30px', marginTop: '20px' }"
                [checked]="policyFeeEnabled"
              >
              </boxxds-switch>
            </ng-container>
            <ng-container
              *ngIf="
                !this.permissionList[this.currentScreen] ||
                this.currentQuoteStatus === 'quote-closed'
              "
            >
              <boxxds-body
                *ngIf="TRIAFeeEnabled"
                [text]="'quoteSummary.includedTria' | translate"
                [ngStyle]="{ marginBottom: '0px', marginTop: '125px' }"
                class="switch-label"
              ></boxxds-body>
              <boxxds-body
                *ngIf="policyFeeEnabled"
                [text]="'quoteSummary.includedPolicyFees' | translate"
                [ngStyle]="{ marginBottom: '30px', marginTop: '20px' }"
                class="switch-label"
              ></boxxds-body>
            </ng-container>
          </div>
        </div>
        <div class="right-col">
          <app-product-detail-accordion
            [policyPeriodId]="policyPeriodId"
            [productList]="productList"
            [policyRiskId]="policyRiskId"
            [quoteOptions]="quoteOptions"
            [products]="products"
            [riskTrnxs]="riskTrnxs"
            [productsIds]="productsIds"
            [riskId]="riskId"
            [regionId]="regionId"
            [policyRiskIds]="policyRiskIds"
            [underWriters]="underWriters"
          >
          </app-product-detail-accordion>
        </div>
      </div>

      <div class="continue-btn-holder">
        <boxxds-button
          [buttonText]="'Back' | translate"
          btnType="secondary"
          [btnStyle]="{ width: '88px', zIndex: '2' }"
          type="submit"
          (handleClick)="handleBackBtn()"
          class="back-btn"
        >
        </boxxds-button>
        <div
          class="btn-bind"
          *ngIf="
            this.permissionList[this.currentScreen] &&
            this.currentQuoteStatus !== 'quote-closed'
          "
        >
          <app-bind-policy-button
            [btnStyle]="{ zIndex: '2' }"
            (handleClick)="handleBindPolicy()"
            [status]="bindingStatus"
          >
          </app-bind-policy-button>
        </div>
      </div>
      <!-- Document Template selection-->

      <app-doc-template-selection-popup
        *ngIf="showDocTempSelectionPopup"
        [showModal]="showDocTempSelectionPopup"
        [details]="docPopupDetails"
        (handleSuccess)="handleSuccessDocTemplate($event)"
        (handleActionConfirm)="
          this.permissionList[this.currentScreen] &&
            handleDocLevelAction($event)
        "
        (handleClose)="handleCloseDocumentPopup()"
      ></app-doc-template-selection-popup>
      <!-- Document Template selection-->
    </div>
  </ng-container>
</div>
