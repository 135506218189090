import { Component, Input, OnInit, ElementRef } from '@angular/core';
import { TooltipV2Model } from 'src/app/models/tooltip.V2.model ';
import { getPositionToDisplayPopup } from 'src/app/utils/utils';

@Component({
  selector: 'app-info-tooltip-v2',
  templateUrl: './info-tooltip-v2.component.html',
  styleUrls: ['./info-tooltip-v2.component.less'],
})
export class InfoTooltipV2Component implements OnInit {
  @Input() tooltipObject: TooltipV2Model = {
    type: '',
    hiddenHead: false,
    hiddenHeadTxt: true,
    tooltipHeaderTxt: '',
    tooltipContentTxt: '',
    tooltipContentList: [],
  };

  tooltipShow: boolean = false;
  isTopPosition: boolean = false;
  position = { top: '0px', left: '0px' };

  constructor(private el: ElementRef) {}

  ngOnInit(): void {}

  showTooltip(event: MouseEvent): void {
    let approxHeightToReduce = 30;
    if (this.tooltipObject?.tooltipContentList?.length > 2)
      approxHeightToReduce = 60;
    else approxHeightToReduce = 1;
    const positionObj = getPositionToDisplayPopup(event, approxHeightToReduce);
    this.position = { top: positionObj.top, left: positionObj.left };
    this.isTopPosition = positionObj.isTopPosition;
    this.tooltipShow = true;
  }

  hideTooltip(): void {
    this.tooltipShow = false;
  }
}
