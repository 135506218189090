import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Input,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  firstValueFrom,
  map,
  Observable,
  Subject,
  take,
  takeUntil,
} from 'rxjs';
import { ViewportScroller } from '@angular/common';
import { Actions, ofType } from '@ngrx/effects';
import { Pagination } from 'src/app/entities/boxx-response';
import { InsuredCompanyPostRequest } from 'src/app/entities/insured-company';
import { InsuredIndividualPostRequest } from 'src/app/entities/insured-individual';
import { Quote } from 'src/app/models/quote.model';
import { InsuredService } from 'src/app/services/insured.service';
import * as InsuredAction from 'src/app/store/insured/insured.action';
import { LocationService } from 'src/app/services/location.service';
import { PolicyMarketReservationService } from 'src/app/services/policy-market.service';
import { RegionService } from 'src/app/services/region.service';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { retrievedInsuredsByCompany } from 'src/app/store/create-quote/create-quote.action';
import {
  getInsuredCompanySelector,
  getInsuredIndividualSelector,
  getPaginationSelector,
  getQuoteSelector,
  getUIData,
} from 'src/app/store/create-quote/create-quote.selector';
import {
  arrayToObjet,
  getErrorMessage,
  makeSortingCol,
  postalCodeValidator,
  regexHelpers,
  removeDuplicatedKeys,
  websiteFormatting,
} from 'src/app/utils/utils';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { NewQuoteService } from 'src/app/services/new-quote.service';
import { BillingCompanyContactService } from 'src/app/services/billing-company-contact.service';
import { PolicyRiskService } from 'src/app/services/policy-risk.service';
import { RiskRatingCalculateService } from 'src/app/services/risk-rating-calculate.service';
import { PolicyPeriodService } from 'src/app/services/policy-period.service';
import { OFACService } from 'src/app/services/ofac.service';
import { PolicyLifecycleService } from 'src/app/services/policy-lifecycle-service';
import {
  calculateDateAfterMonths,
  formatDateMoment,
  formatDateTime,
  getCityTime,
  getFormatDate,
} from 'src/app/utils/formatDate';
import { AlertService } from 'src/app/services/alert.service';
import {
  getCountrySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import { CANADA, UNITED_STATES } from 'src/app/constants/location-constant';
import { IndustryClassService } from 'src/app/services/industry-class.service';
import { IndustryService } from 'src/app/services/industry.service';
import { DomainsService } from 'src/app/services/domains.service';
import { MapService } from 'src/app/services/map.service';
import {
  DOMAIN_CODE_RANGENUMEMPLOYEES,
  DOMAIN_CODE_RANGENUMHOUSEMEMBERS,
} from 'src/app/constants/quote-constant';
import { LanguageService } from 'src/app/services/language.service';
import { UiContentService } from 'src/app/services/uiContent.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { PolicyDashboardService } from 'src/app/services/policy-dashboard.service';
import { isStatusDisabled } from 'src/app/constants/quoteStatus';
import { REVENUE_MARGIN_VALUE } from 'src/app/constants/submission-constant';
import { BrokerageProducerService } from 'src/app/services/brokerage-producer.service';
import { alertDetails } from 'src/app/entities/common';
import { PolicyStatus } from 'src/app/constants/policyStatus';
import { getAlertHead } from 'src/app/utils/utils';
import { PolicyDuplicateQuoteService } from 'src/app/services/policy-duplicate-quote.service';
import { insuredContact } from 'src/app/entities/insured-contact';
import { PolicyAtomicTrxService } from 'src/app/services/policy-atomic.service';
import { QUOTESTATUS_SUBMISSION } from 'src/app/constants/config-constant';
import { NAICSService } from 'src/app/services/naics.service';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import {
  setQuoteStatus,
  setTransactionLvlQuoteStatus,
  updatePolicyRiskIdAndStatus,
} from 'src/app/store/lifecycle/lifecycle.action';

@Component({
  selector: 'app-submission',
  templateUrl: './submission.component.html',
  styleUrls: ['./submission.component.less'],
})
export class SubmissionWorkflow3Component
  implements OnInit, AfterContentChecked, AfterViewInit, OnDestroy
{
  @Input() regionId: number;
  @Input() alertInfo;

  @Output() toggleEvent = new EventEmitter<any>();

  form: FormGroup;
  form$: Observable<Quote>;
  searchKey;
  companySearchData: Observable<any>;
  companyColumnsToSort: Array<string> = ['Name', 'Website'];
  noOfEmpOptns: any;
  numberOfEmp: any;
  businessSectorOptns: any = [];
  businessIndustrySectorOptns = [];
  businessIndustryClass = null;
  businessIndustry;
  requiredErrMsg: string = 'error.thisFieldIsRequired';
  invalidErrMsg: string = 'error.thisFieldIsInvalid';
  customErrMsg: string = 'error.customSumPercentageErrMsg';
  minLengthErrMsg: string = '';
  submitted: boolean = false;
  searchSubmitted: boolean = false;
  totalDataCount = 0;
  addCustomerExpanded: boolean = false;
  householdMemberOptns: any;
  householdMember: any;
  individualSearchData: Observable<any>;
  customerColumnsToSort: Array<string> = ['Name', 'Email'];
  pagination: Observable<Pagination>;
  isInsuredTypeCompany: boolean;
  companyLocationRegionIdSelected: number = 0;
  individuaLocationlRegionIdSelected: number = 0;
  isFormLoaded = false;
  showBanner = false;
  bannerMsg = '';
  errorAPIMsg = 'error.errorAPIMsg';
  colCompanyHeader = {
    Name: { key: 'companyName', order: '' },
    Website: { key: 'companyWebsite', order: '' },
    Address: { key: 'address', order: '' },
  };
  sortingCompanyKeys = '';
  colIndividualHeader = {
    Name: { key: 'firstName', order: '' },
    Email: { key: 'email', order: '' },
    Address: { key: 'address', order: '' },
  };
  sortingIndividualKeys = '';
  boxxUserId;
  activeCompanySearchPgNo = 1;
  activeIndividualSearchPgNo = 1;
  hasMinimumCharacter: boolean = false;
  showTblSpinner: boolean = false;
  showBodySpinner: boolean = false;
  showBodySpinnerLocation: boolean = false;
  showSpinner: boolean = false;
  isLoaded: boolean = false;
  percentageRegex = regexHelpers.percentage;
  industryClass = {};
  selectedIndustryId = 0;
  linkIconSrc: string = '';
  sanctionPayload = [];
  isRestrictedIndustry = false;
  private ngUnsubscribe$ = new Subject<void>();
  insuredId = null;
  isEditMode = false;
  insuredName = '';
  quoteExist: number = 0; // 0- No, 1-exist with selected producer, 2-exist with different producer
  actionPopupDetails: {
    quoteNumber: string;
    effectiveDate: string;
    insured?: string;
    quotePeriod?: {
      effectiveDt: string;
      expiryDt: string;
    };
    producerId?: number;
    producerName?: string;
    branchId?: number;
    branchName?: string;
    brokerageId?: number;
    brokerageName?: string;
    revenue?: number;
    industryClass?: string;
    isSubmissionUpdate?: boolean;
    isRestrictedRoleSubmission?: boolean;
  };
  formDataAddCompanyForm: any;
  formDataAddIndividualForm: any;
  policyPeriodId;
  showRecalcConfModal: boolean = false;
  isQuoteInSubmission: boolean = false;
  latestFormData;
  showSanctionModal = false;
  formDataBack: any;
  fromSubmissionFlow: boolean = false;
  showContactDetails: boolean = false;
  contactToggleEnabled: boolean = false;
  isOfacError = false;
  shortDateFormat: string = '';
  longDateFormat: string = '';

  showMarketReservationModal = false;
  showStateChangeModal = false;
  showRiskAnalysisUpdateModal = false;

  columnsToHide = ['currentState'];
  sortableColumns = [];
  iconColumns: string[] = ['Reasons'];
  customColumnWidth: {
    columnName: string;
    minWidth: string;
    maxWidth: string;
  }[] = [
    {
      columnName: 'Type',
      minWidth: '112px',
      maxWidth: '120px',
    },
    {
      columnName: 'Description',
      minWidth: '250px',
      maxWidth: '250px',
    },
  ];
  revenueMarginValue = REVENUE_MARGIN_VALUE;
  breadCrumbArray = [
    {
      label: 'Submission',
      path: null,
    },
  ];
  showSubmissionSubmittedModal: boolean = false;
  insuredTimezone;
  products = [];
  //************************************************************* */

  checkValidation: boolean = false;
  continueButtonLabel;
  isActive: boolean = true;
  allowLocationLoader: boolean = true;
  insuredOptns: any = [];
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  country: string = '';
  shortDateTimeFormat: string = '';
  longDateTimeFormat: string = '';
  confirmIndustryShow: boolean = true;
  showPredictions = true;
  showIndustrySection = false;
  industryPredictionForm: FormGroup;
  isNavigatedFromRiskAnalysis = false;
  isRestrictedRoleSubmission: boolean = false;
  saveBtnTxt: string = 'workFlow3.components.newSubmission.button.continue';
  insuredData = null;
  insuredDataIndividual = null;
  initialFormData = null;
  initialFormDataIndividual = null;
  billingCompanyContactId = null;
  toggleLabel: string = 'insured.heading.showContactDetails';
  industryPredictions = [];
  enableConfirmButton = false;
  predictionsReturned = [];
  showIndustryDropdowns = true;
  regionCode = '';
  alertData = {
    show: false,
    type: '',
    header: '',
    body: '',
  };
  showRevenueField = true;
  showAddressField = true;
  //************************************************************* */

  //*****location*********************************************** */
  formSubmitted: boolean = false;

  listRegions: any[] = [];
  regionTotalRecords = 0;
  regionTotalPages = 0;
  countrySelected = '';
  address: string;

  provinceOrStateList = [];
  userID: number;
  province: string;
  zip: string;
  mask: string = 'S0S 0S0';
  locations: any;
  currentProvinceObj: {
    id: number;
    value: string;
    key: string;
    subKey?: string;
    img?: string;
    code?: string;
  };
  //**************************************************** */

  insuredTypeForm: FormGroup<any>;
  typeBusinessInsured: boolean = true;
  mockExternalAPIs = true;
  enableProducerInfo: boolean = false;
  isReferral: boolean = false;
  quoteFlowUrl = '';
  showExistingQuoteModal = false;
  activeQuoteId = null;
  selectedFromSuggestion = false;
  isSuggestedOption = false;
  riskId = 0;
  hasActivePolicy: boolean = false;
  radioIndividual = [];
  radioBusiness = [];
  isAtLeastOneFieldFilled: boolean = false;
  checkContactValidation: boolean = false;
  initialContactFormData: any;
  initialContactFormDataObject: any;
  isExistingInsuredFormEdit: boolean = false;
  productId;
  productName;
  disableProduct: boolean = false;
  naicsData = {
    insuredId: 0,
    industryIdPredictionA: 0,
    accuracyPredictionA: 0,
    industryIdPredictionB: 0,
    accuracyPredictionB: 0,
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private viewportScroller: ViewportScroller,
    private regionService: RegionService,
    private actions$: Actions,
    private insuredService: InsuredService,
    private locationService: LocationService,
    private policyMarketReservationService: PolicyMarketReservationService,
    private policyDuplicateQuoteService: PolicyDuplicateQuoteService,
    private changeDetector: ChangeDetectorRef,
    private localStorageService: LocalStorageService,
    private newQuoteService: NewQuoteService,
    private billingCompanyContactService: BillingCompanyContactService,
    private policyPeriodService: PolicyPeriodService,
    private policyRiskService: PolicyRiskService,
    private riskRatingCalculateService: RiskRatingCalculateService,
    private ofacService: OFACService,
    private policyLifecycleService: PolicyLifecycleService,
    private alertService: AlertService,
    private industryClassService: IndustryClassService,
    private industryService: IndustryService,
    private domainsService: DomainsService,
    private mapService: MapService,
    private languageService: LanguageService,
    private uiContentService: UiContentService,
    private translateService: TranslateService,
    private policyDashboardService: PolicyDashboardService,
    private brokerageProducerService: BrokerageProducerService,
    private policyAtomicTrxService: PolicyAtomicTrxService,
    private naicsService: NAICSService,
    private riskRegionService: RiskRegionService,
  ) {
    (this.insuredTypeForm = this.fb.group({
      insured_type: ['business'],
    })),
      (this.form = this.fb.group({
        addCompanyForm: this.fb.group({
          businessName: ['', Validators.required],
          website: ['', [Validators.pattern(regexHelpers.Website)]],
          revenue: [
            '',
            [
              Validators.required,
              Validators.pattern('^([1-9][0-9]{0,9}|10000000000)$'),
            ],
          ],
          noOfEmployees: [''],
          industry: ['', Validators.required],
          industryClass: ['', Validators.required],
          revenuePercentUS: [
            '',
            [
              Validators.pattern(this.percentageRegex),
              this.isRevenuePercentageValid(
                'revenuePercentCA',
                'revenuePercentOther',
              ),
            ],
          ],
          revenuePercentCA: [
            '',
            [
              Validators.pattern(this.percentageRegex),
              this.isRevenuePercentageValid(
                'revenuePercentUS',
                'revenuePercentOther',
              ),
            ],
          ],
          revenuePercentOther: [
            '',
            [
              Validators.pattern(this.percentageRegex),
              this.isRevenuePercentageValid(
                'revenuePercentUS',
                'revenuePercentCA',
              ),
            ],
          ],
          country: ['', Validators.required],
          province: ['', Validators.required],
          city: ['', Validators.required],
          address: ['', Validators.required],
          zipPostalCode: ['', Validators.required],
          addressLine2: [''],
          insuredId: [''],
          insuredLocationId: [''],
          industryClassId: [''],
          industryId: [''],
          numOfEmployees: [''],
          brokerageinfo: [''],
          productId: [''],
          productName: [''],
        }),
        contactForm: this.fb.group({
          contacts: this.fb.array([]),
        }),
        addIndividualForm: this.fb.group({
          firstName: ['', Validators.required],
          middleName: [''],
          lastName: ['', Validators.required],
          phone: ['', Validators.required],
          email: [
            '',
            [Validators.required, Validators.pattern(regexHelpers.MailId)],
          ],
          householdMembers: [''],
          country: ['', Validators.required],
          province: ['', Validators.required],
          city: ['', Validators.required],
          address: ['', Validators.required],
          zipPostalCode: ['', Validators.required],
          addressLine2: [''],
          numberOfHouseholdMembersId: [''],
          insuredId: [''],
          insuredLocationId: [''],
          brokerageinfo: [''],
          productId: [''],
          productName: [''],
        }),
      }));
    this.industryPredictionForm = this.fb.group({
      industry: [''],
    });
    // for initializing contact form for first time
    this.addContact();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  ngAfterViewInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  get addCompanyForm() {
    return this.form.get('addCompanyForm') as FormGroup;
  }

  get contactForm() {
    return this.form.get('contactForm') as FormGroup;
  }

  get addIndividualForm() {
    return this.form.get('addIndividualForm') as FormGroup;
  }

  hasEmptyValueOrInvalid(...values) {
    return values.some((value) => {
      return value === '' || !this.percentageRegex.test(value);
    });
  }

  setNAICSData(data) {
    this.naicsData = {
      ...this.naicsData,
      ...data,
    };
  }

  isRevenuePercentageValid(revenue1, revenue2) {
    return (control: FormControl) => {
      if (!control || !control.parent) {
        return null;
      } else if (control.value == 100) {
        control.parent.controls[revenue1].setValue(0);
        control.parent.controls[revenue2].setValue(0);
        return null;
      } else if (
        this.hasEmptyValueOrInvalid(
          control.parent.value[revenue1],
          control.parent.value[revenue2],
          control.value,
        )
      ) {
        return {
          customError: true,
        };
      }
      if (
        Number(control.parent.value[revenue1]) +
          Number(control.parent.value[revenue2]) +
          Number(control.value) !=
        100
      ) {
        return {
          customError: true,
        };
      } else {
        control.parent.controls[revenue1].errors = null;
        control.parent.controls[revenue2].errors = null;
        control.parent.controls[revenue1].status = 'VALID';
        control.parent.controls[revenue2].status = 'VALID';
      }

      return {};
    };
  }

  async ngOnInit(): Promise<void> {
    if (
      this.localStorageService.getMockExternalAPIs() === 'false' ||
      environment.mockExternalAPIs === false
    ) {
      this.mockExternalAPIs = false;
    }
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.isActive = this.permissionList[this.currentScreen] ?? false;
      this.country = data.country;
      this.shortDateTimeFormat = data.shortDateTimeFormat;
      this.longDateTimeFormat = data.longDateTimeFormat;
      this.isRestrictedRoleSubmission = data.isRestrictedRoleSubmission;
    });
    this.form$ = this.store.select(getQuoteSelector);
    this.form$.subscribe((event) => {
      ({ isNavigatedFromRiskAnalysis: this.isNavigatedFromRiskAnalysis } =
        event.ui);
      this.isNavigatedFromRiskAnalysis = event.ui.isNavigatedFromRiskAnalysis;
    });
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    this.boxxUserId = this.localStorageService.getBoxxUserId();
    this.actionPopupDetails = {
      ...this.actionPopupDetails,
      isSubmissionUpdate: false,
      isRestrictedRoleSubmission: this.isRestrictedRoleSubmission,
    };
    this.linkIconSrc = 'assets/img/plus-circle.png';
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.boxxUserId = this.localStorageService.getBoxxUserId();
    this.actions$
      .pipe(
        ofType(
          CreateQuoteAction.SET_ADD_COMPANY_EXPANDED,
          CreateQuoteAction.SET_ADD_CUSTOMER_EXPANDED,
        ),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => {
        setTimeout(() => {
          this.viewportScroller.scrollToAnchor('btn-add');
        }, 0);
      });
    if (this.isRestrictedRoleSubmission === true) {
      this.saveBtnTxt = 'workFlow3.components.newSubmission.button.submit';
      if (this.typeBusinessInsured) {
        this.form.controls['addCompanyForm']
          .get('productName')
          .setValidators([Validators.required]);
      } else {
        this.form.controls['addIndividualForm']
          .get('productName')
          .setValidators([Validators.required]);
      }
    }

    this.form$ = this.store.pipe(select(getQuoteSelector));

    this.form$.subscribe(
      (event) => (this.isInsuredTypeCompany = event.ui.isInsuredTypeCompany),
    );
    if (this.isInsuredTypeCompany) {
      this.companySearchData = this.store.pipe(
        select(getInsuredCompanySelector),
      );
    } else {
      this.individualSearchData = this.store.pipe(
        select(getInsuredIndividualSelector),
      );
    }
    this.pagination = this.store.pipe(select(getPaginationSelector));
    this.pagination.subscribe((pagination) => {
      if (this.isInsuredTypeCompany) {
        this.activeCompanySearchPgNo = pagination.currentPage;
      } else {
        this.activeIndividualSearchPgNo = pagination.currentPage;
      }
    });

    let isInsuredTypeCompany;

    this.form$.subscribe(
      (event) => (isInsuredTypeCompany = event.ui.isInsuredTypeCompany),
    );

    if (isInsuredTypeCompany) {
      this.typeBusinessInsured = true;
      this.insuredTypeForm.get('insured_type').setValue('business');
    } else {
      this.typeBusinessInsured = false;
      this.insuredTypeForm.get('insured_type').setValue('individual');
    }

    this.radioIndividual = [
      {
        label: this.translateService.instant(
          'workFlow3.components.newSubmission.label.individual',
        ),
        value: 'individual',
      },
    ];
    this.radioBusiness = [
      {
        label: this.translateService.instant(
          'workFlow3.components.newSubmission.label.business',
        ),
        value: 'business',
      },
    ];
    await this.domainsService
      .GetByDomainCode(DOMAIN_CODE_RANGENUMEMPLOYEES)
      .subscribe(async (data) => {
        const tableData = data.data.map((dataObj) => ({
          key: dataObj.description,
          value: dataObj.description,
          id: dataObj.id,
        }));
        this.noOfEmpOptns = tableData;
        this.numberOfEmp = arrayToObjet(data.data, 'description');
        const insuredId = this.newQuoteService.getInsuredId();
        if (insuredId) {
          this.insuredId = insuredId;
        }
        if (this.insuredId && isInsuredTypeCompany) {
          await this.populateInsuredDetails(this.insuredId);
          // await Promise.all([this.getInsuredTimeZone()]);
          this.newQuoteService.clearInsuredId();
        }
      });
    await this.domainsService
      .GetByDomainCode(DOMAIN_CODE_RANGENUMHOUSEMEMBERS)
      .subscribe(async (data) => {
        const tableData = data.data?.map((dataObj) => ({
          key: dataObj.description,
          value: dataObj.description,
          id: dataObj.id,
        }));

        this.householdMemberOptns = tableData;
        this.householdMember = arrayToObjet(data.data, 'description');
        if (this.insuredId && !isInsuredTypeCompany) {
          await this.populateInsuredDetailsIndividual(this.insuredId);
          //  await Promise.all([this.getInsuredTimeZone()]);
        }
      });

    this.contactForm.valueChanges.subscribe((value) => {
      this.checkIfAtLeastOneFieldFilled();
    });
    if (this.isRestrictedRoleSubmission === true) {
      this.initLoadStore();
    }
  }

  DoCheck() {
    this.form$ = this.store.select(getQuoteSelector);
    let producerId;
    this.form$.subscribe((event) => {
      ({ producerId } = event.ui);
      producerId = event.ui.producerId;
    });
    if (!producerId) return false;
    else {
      if (this.typeBusinessInsured) {
        if (this.form.get('addCompanyForm').valid) {
          this.checkValidation = false;
          this.formSubmitted = false;
          return true;
        } else {
          this.checkValidation = true;
          this.formSubmitted = true;
          return false;
        }
      } else {
        if (this.form.get('addIndividualForm').valid) {
          this.checkValidation = false;
          this.formSubmitted = false;
          return true;
        } else {
          this.checkValidation = true;
          this.formSubmitted = true;
          return false;
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.alertService.clearAlerts(-1);
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  closeSanctionPopup() {
    this.showSanctionModal = false;
    this.sanctionPayload = [];
    this.quoteExist = 0;
  }

  handleSanctionProceed() {
    let doRecalculate = false;
    if (this.initialFormData) {
      doRecalculate =
        this.checkForRecalculate('addCompanyForm', this.initialFormData) ===
        true;
    }
    this.showSanctionModal = false;
    this.sanctionPayload = [];
    this.updateInsuredCompany(
      this.formDataAddCompanyForm ?? this.form.get('addCompanyForm').value,
      doRecalculate,
      true,
    );
  }
  handleSort(data: any): void {}

  onChangeProduct(productId) {
    const productName = this.products.filter(
      (product) => product?.id == productId,
    )[0]?.value;
    this.form.controls['addCompanyForm'].get('productId').setValue(productId);
    this.form.controls['addCompanyForm']
      .get('productName')
      .setValue(productName);
  }

  columnClickHandler(data: any): void {}

  async checkIfRestrictiveIndustry(
    industryId,
    isNewCompanyAdd = false,
    formValue,
  ) {
    this.isRestrictedIndustry = false;
    this.newQuoteService.setSanction({
      clearance: this.sanctionPayload,
      isRestricted: false,
    });
    this.store.dispatch(
      new CreateQuoteAction.setSanctionAction({
        clearance: this.sanctionPayload,
        isRestricted: false,
      }),
    );
    this.setLoader(false, 'unset');
    if (this.sanctionPayload?.length > 0) {
      this.showSanctionModal = true;
    } else {
      if (this.isOfacError) {
        this.alertData = {
          show: true,
          type: 'error',
          header: 'common.errorHeader',
          body: 'submission.error.ofacError',
        };
        return;
      } else if (this.quoteExist === 1 && !this.isNavigatedFromRiskAnalysis) {
        this.showExistingQuoteModal = true;
      } else if (this.quoteExist === 2 && !this.isNavigatedFromRiskAnalysis) {
        await this.createDuplicateQuote();
        this.showMarketReservationModal = true;
      } else {
        if (this.activeQuoteId) {
          if (this.isRestrictedRoleSubmission === true) {
            if (isNewCompanyAdd === true) {
              try {
                const createQuote = await this.createQuote(formValue);
                this.showSubmissionSubmittedModal = true;
              } catch (error) {
                if (![500].includes(error?.status)) {
                  const errAlert = {
                    show: true,
                    type: 'error',
                    headerText: 'common.errorHeader',
                    bodyText: getErrorMessage(error),
                  };
                  if (this.alertService.addAlert(errAlert))
                    this.setLoader(false, 'unset');
                  this.showSpinner = false;
                } else {
                  this.setLoader(false, 'unset');
                  this.showSpinner = false;
                }
              }
            } else {
              this.actionPopupDetails = {
                ...this.actionPopupDetails,
                quoteNumber: this.activeQuoteId,
                industryClass: formValue.industryClass,
                revenue: Number(formValue.revenue),
                isSubmissionUpdate: true,
              };
              this.showSubmissionSubmittedModal = true;
            }
          } else {
            this.router.navigate(
              ['/dashboard/workflow3/new/product/form', this.activeQuoteId],
              {
                relativeTo: this.activatedRoute,
                skipLocationChange: true,
              },
            );
          }
        } else {
          if (this.isRestrictedRoleSubmission === true) {
            try {
              const createQuote = await this.createQuote(formValue);
              this.showSubmissionSubmittedModal = true;
            } catch (error) {
              if (![500].includes(error?.status)) {
                const errAlert = {
                  show: true,
                  type: 'error',
                  headerText: 'common.errorHeader',
                  bodyText: getErrorMessage(error),
                };
                if (this.alertService.addAlert(errAlert))
                  this.setLoader(false, 'unset');
                this.showSpinner = false;
              } else {
                this.setLoader(false, 'unset');
                this.showSpinner = false;
              }
            }
          } else {
            this.router.navigate(['/dashboard/workflow3/new/product/form'], {
              relativeTo: this.activatedRoute,
              skipLocationChange: true,
            });
          }
        }
      }
    }
  }

  handleRevenueBlur() {
    const initialRevenue = this.initialFormData?.revenue;
    if (initialRevenue) {
      const formValue = this.form.get('addCompanyForm').value;
      const updatedRevenue = formValue?.revenue;
      if (
        (initialRevenue < REVENUE_MARGIN_VALUE &&
          updatedRevenue >= REVENUE_MARGIN_VALUE) ||
        (initialRevenue >= REVENUE_MARGIN_VALUE &&
          updatedRevenue < REVENUE_MARGIN_VALUE)
      ) {
        this.store.dispatch(new CreateQuoteAction.clearRiskQuestions());
      }
    }
  }

  async addCompanyContinue(
    formValue,
    contactFormValue,
    isCalledFromAddProducer = false,
  ) {
    this.setLoader(true, 'hidden');
    try {
      const getInsuredByName = this.insuredService.GetByCompanyName(
        formValue.businessName,
      );
      const getInsuredResponse = await firstValueFrom(getInsuredByName);
      if (getInsuredResponse.data.length > 0) {
        const ifInsuredExists =
          getInsuredResponse.data.filter(
            (result) => formValue.businessName === result.companyName,
          )?.length > 0;
        if (ifInsuredExists) {
          this.alertData = {
            show: true,
            type: 'error',
            header: 'common.errorHeader',
            body: 'insured.error.duplicateInsuredCompany',
          };
          this.setLoader(false, 'unset');
          this.showIndustrySection = true;
          return;
        }
      }
    } catch (error) {
      this.setLoader(false, 'unset');
    }

    this.regionService.GetByName(formValue.province).subscribe((data) => {
      let regionId;
      if (data.data?.length > 1) {
        const exactMatchRegionData = data.data.find(
          (region) =>
            region.name?.toLowerCase() === formValue.province?.toLowerCase(),
        );
        if (exactMatchRegionData) {
          regionId = exactMatchRegionData.id;
        } else {
          const regionData = data.data.find(
            (region) =>
              region.name
                ?.toLowerCase()
                .includes(formValue.province?.toLowerCase()),
          );
          if (regionData) {
            regionId = regionData.id;
          }
        }
      } else {
        regionId = data.data[0].id;
      }

      let location = {
        address: !!formValue.address ? formValue.address : null,
        city: !!formValue.city ? formValue.city : null,
        country: !!formValue.country ? formValue.country : null,
        zipPostalCode: !!formValue.zipPostalCode
          ? formValue.zipPostalCode
          : null,
        addressLine2: !!formValue.addressLine2 ? formValue.addressLine2 : null,
        geoLocationLatitude: 'string',
        geoLocationLongitude: ' string',
        source: 0,
        regionId: regionId,
      };
      try {
        this.locationService
          .Create(location)
          .subscribe(async (locationData) => {
            let insured: InsuredCompanyPostRequest;

            insured = {
              companyName: formValue.businessName,
              companyWebsite: formValue.website
                ? websiteFormatting(formValue.website)
                : '',
              industryClassId: formValue?.industryClassId,
              industryId: formValue?.industryId,
              insuredLocationId: locationData.data?.id,
              insuredType: 2,
              revenue: Number(formValue.revenue),
              webPressence: 'true',
              revenueUSPerc: Number(formValue.revenuePercentUS),
              revenueCAPerc: Number(formValue.revenuePercentCA),
              revenueOtherPerc: Number(formValue.revenuePercentOther),
              ofac: true,
              isMock: this.mockExternalAPIs,
            };

            if (formValue?.numOfEmployees) {
              insured.numOfEmployees = formValue?.numOfEmployees;
            }
            this.productId = formValue.productId;
            this.productName = formValue.productName;
            this.insuredService.Create(insured).subscribe({
              next: async (data) => {
                this.form.controls['addCompanyForm']
                  .get('insuredLocationId')
                  .setValue(data?.data?.insuredLocationId);
                this.form.controls['addCompanyForm']
                  .get('insuredId')
                  .setValue(data?.data?.id);
                this.form.controls['addCompanyForm']
                  .get('industryClassId')
                  .setValue(data?.data?.industryClassId);
                this.form.controls['addCompanyForm']
                  .get('industryId')
                  .setValue(data?.data?.industryId);
                if (
                  data?.data?.numOfEmployees &&
                  data?.data?.numOfEmployees !== ''
                ) {
                  this.form.controls['addCompanyForm']
                    .get('numOfEmployees')
                    .setValue(Number(data?.data?.numOfEmployees));
                } else {
                  this.form.controls['addCompanyForm']
                    .get('numOfEmployees')
                    .setValue(Number(null));
                }
                const insuredId = data.data.id;
                this.insuredId = data.data.id;

                // Add NAICS entry

                if (
                  this.naicsData.industryIdPredictionA &&
                  this.naicsData.industryIdPredictionA > 0
                ) {
                  const naicsPostPayload = {
                    insuredId: this.insuredId,
                    industryIdPredictionA: this.naicsData.industryIdPredictionA,
                    accuracyPredictionA: this.naicsData.accuracyPredictionA,
                    industryIdPredictionB: this.naicsData.industryIdPredictionB,
                    accuracyPredictionB: this.naicsData.accuracyPredictionB,
                  };
                  try {
                    const naicsPost =
                      this.naicsService.createNaics(naicsPostPayload);
                    let naicsPostResponse = await firstValueFrom(naicsPost);
                  } catch (e) {}
                }

                await Promise.all([this.getInsuredTimeZone()]);
                const ofac = data.data?.ofac;
                if (ofac?.length > 0) {
                  this.sanctionPayload = [...this.sanctionPayload, ...ofac];
                } else if (!(ofac?.error === false)) {
                  if ([400, 401, 500].includes(ofac?.status)) {
                    this.isOfacError = true;
                  }
                }

                // creating multiple contact payload
                let billingContactPayload = contactFormValue;
                billingContactPayload['contact'] =
                  this.removeEmptyContactForms();
                delete billingContactPayload['contacts'];

                try {
                  if (billingContactPayload.contact.length !== 0) {
                    billingContactPayload = {
                      ...billingContactPayload,
                      insuredId: insuredId,
                    };
                    this.billingCompanyContactService
                      .Create(billingContactPayload)
                      .subscribe((res) => {
                        this.initialContactFormDataObject =
                          this.form.get('contactForm').value;
                      });
                  } else {
                    this.addContact();
                    this.initialContactFormDataObject =
                      this.form.get('contactForm').value;
                  }

                  if (isCalledFromAddProducer) {
                    this.router.navigate(
                      ['/dashboard/workflow3/brokerage/directory'],
                      {
                        skipLocationChange: true,
                      },
                    );
                    return;
                  }

                  this.store.dispatch(
                    new CreateQuoteAction.AddInsuredCompanyAction(formValue),
                  );
                  this.store.dispatch(
                    new CreateQuoteAction.UpdateInsuredAction({
                      insuredName: formValue.businessName,
                      insuredEmail: formValue.email,
                      insuredId: insuredId,
                      regionId: regionId,
                      revenue: formValue.revenue,
                      noOfEmployees: formValue.noOfEmployees,
                      industryClass: formValue.industryClass,
                      industry: formValue.industry,
                      address:
                        locationData?.data?.fullAddress ??
                        `${formValue.address}, ${
                          formValue.addressLine2 != ''
                            ? formValue.addressLine2 + ', '
                            : ''
                        }${formValue.city}, ${this.regionCode}, ${
                          formValue.zipPostalCode
                        }, ${formValue.country}`,
                      productId: formValue.productId,
                      productName: formValue.productName,
                    }),
                  );
                  this.store.dispatch(
                    new CreateQuoteAction.updateUiContents({
                      regionId: this.regionId,
                    }),
                  );
                  let isInsuredTypeCompany;
                  let versionId = 1;
                  this.form$
                    .pipe(take(1))
                    .subscribe(
                      (event) =>
                        (isInsuredTypeCompany = event.ui.isInsuredTypeCompany),
                    );
                  if (isInsuredTypeCompany) {
                    versionId = 2;
                  }
                  this.store.dispatch(
                    CreateQuoteAction.loadProducts({
                      userId: this.boxxUserId,
                      regionId: regionId,
                      versionId: versionId,
                    }),
                  );
                  this.store.dispatch(
                    new CreateQuoteAction.updateUiContents({
                      regionId: regionId,
                    }),
                  );
                  this.selectedIndustryId = formValue?.industryId;
                  await this.checkIfRestrictiveIndustry(
                    this.selectedIndustryId,
                    true,
                    formValue,
                  );
                } catch (error) {
                  if (![500].includes(error?.status)) {
                    this.alertData = {
                      show: true,
                      type: 'error',
                      header: 'common.errorHeader',
                      body: getErrorMessage(error),
                    };
                  }
                }
              },
              error: (error) => {
                if (![500].includes(error?.status)) {
                  this.alertData = {
                    show: true,
                    type: 'error',
                    header: 'common.errorHeader',
                    body: getErrorMessage(error),
                  };
                }
              },
            });
          });
      } catch (error) {
        if (![500].includes(error?.status)) {
          this.alertData = {
            show: true,
            type: 'error',
            header: 'common.errorHeader',
            body: getErrorMessage(error),
          };
        }
      }
    });
  }

  // Individual
  getCustomerName(form) {
    return form.firstName + ' ' + form.middleName + ' ' + form.lastName;
  }

  clearIndividualResults() {
    // this.form.controls['individualSearchForm'].reset();
  }
  handleNotificationAlertClose() {
    this.showBanner = false;
    this.bannerMsg = '';
  }

  setLoader(status, type) {
    this.showBodySpinner = status;
    window.document.body.style.overflow = type;
  }

  hasLocationDataChanged(latest, previous) {
    const formname =
      this.typeBusinessInsured === true
        ? 'addCompanyForm'
        : 'addIndividualForm';

    const latestLocationData = {
      address: latest?.address,
      addressLine2: latest?.addressLine2,
      city: latest?.city,
      country: latest?.country,
      province: this.form.controls[formname].value.province,
      zipPostalCode: latest?.zipPostalCode,
    };

    const previousLocationData = {
      address: previous?.address,
      addressLine2: previous?.addressLine2,
      city: previous?.city,
      country: previous?.country,
      province: previous?.province,
      zipPostalCode: previous?.zipPostalCode,
    };

    if (
      JSON.stringify(latestLocationData) !==
      JSON.stringify(previousLocationData)
    ) {
      return true;
    }
    return false;
  }

  ofacCheckRequired(newForm, oldForm) {
    if (
      !(
        newForm?.businessName == oldForm?.businessName &&
        newForm.website == oldForm.website &&
        newForm.address == oldForm.address &&
        newForm.province == oldForm.province &&
        newForm.city == oldForm.city &&
        newForm.zipPostalCode == oldForm.zipPostalCode &&
        newForm.country == oldForm.country
      )
    ) {
      return true;
    }
    return false;
  }

  async addOrUpdateCompanyInsured(isCalledFromAddProducer = false) {
    this.isOfacError = false;
    this.alertService.clearAlerts(-1);

    this.checkContactValidation = true;
    if (
      this.form.get('addCompanyForm').valid &&
      this.form.get('contactForm').valid
    ) {
      const formValue = this.form.get('addCompanyForm').value;
      const contactFormValue = this.form.get('contactForm').value;

      this.insuredId = formValue?.insuredId
        ? formValue?.insuredId
        : this.insuredId ?? null;
      this.isEditMode = formValue?.insuredId
        ? true
        : this.insuredId
        ? true
        : false;
      this.productId = formValue.productId;
      this.productName = formValue.productName;
      const predictionIndustryData = this.getIndustryData(
        this.selectedIndustryId,
      );

      if (this.insuredId !== null)
        await Promise.all([this.checkForActiveQuote(this.insuredId)]);

      if (this.isEditMode) {
        if (this.quoteExist !== 0) {
          this.insuredName = this.initialFormData?.businessName ?? '';
          this.store.dispatch(
            new InsuredAction.setInsuredNameAction(this.insuredName ?? ''),
          );
          this.store.dispatch(
            new CreateQuoteAction.updateUiContents({
              insuredName: this.insuredName ?? '',
            }),
          );
          this.actionPopupDetails = {
            ...this.actionPopupDetails,
            insured: this.insuredName || formValue?.businessName,
            industryClass: formValue.industryClass,
            revenue: Number(formValue.revenue),
            isSubmissionUpdate: true,
          };
          if (
            this.checkForStateChange('addCompanyForm', this.initialFormData) ===
            true
          ) {
            this.formDataAddCompanyForm = null;
            this.formDataAddCompanyForm = formValue;
            this.showStateChangeModal = true;
            return;
          }
          // skip saving any changes & show market reservation when producer selected is not the producer of active quote
          if (this.quoteExist === 2 && !isCalledFromAddProducer) {
            await this.createDuplicateQuote();
            this.showMarketReservationModal = true;
            return;
          }

          if (
            (this.initialFormData &&
              this.ofacCheckRequired(formValue, this.initialFormData)) ||
            this.sanctionPayload.length > 0
          ) {
            const entitySearchData = {
              cases: [
                {
                  name: formValue?.businessName,
                  type: 'entity',
                  ids: [
                    {
                      id: formValue?.website,
                    },
                  ],
                  address: {
                    address1: formValue.address,
                    city: formValue?.city,
                    country: formValue?.country,
                    postalCode: formValue?.zipPostalCode,
                  },
                },
              ],
              isMock: this.mockExternalAPIs,
            };
            const ofacSearchData = this.ofacService.Create(entitySearchData);
            const ofacResponse = await firstValueFrom(ofacSearchData);
            const ofacData = ofacResponse?.data;

            if (ofacData?.length > 0) {
              this.sanctionPayload = [...ofacData];
            } else if (!(ofacData?.error === false)) {
              if ([400, 401, 500].includes(ofacData?.status)) {
                this.isOfacError = true;
                this.alertData = {
                  show: true,
                  header: 'common.errorHeader',
                  body: 'submission.error.ofacError',
                  type: 'error',
                };
                return;
              }
            } else {
              this.sanctionPayload = [];
            }
          }

          this.isRestrictedIndustry = false;
          if (this.sanctionPayload.length > 0) {
            this.formDataAddCompanyForm = formValue;
            this.showSanctionModal = true;
            return;
          } else {
            if (
              this.checkIfRiskAnalysisUpdateRequired(
                this.initialFormData?.revenue,
                Number(formValue.revenue),
              )
            ) {
              this.formDataAddCompanyForm = formValue;
              this.showRiskAnalysisUpdateModal = true;
              return;
            } else if (
              !this.isQuoteInSubmission &&
              (this.checkForRecalculate(
                'addCompanyForm',
                this.initialFormData,
              ) === true ||
                this.checkIfUSRevenuePercTriggerRecalc(
                  this.initialFormData?.revenuePercentUS,
                  Number(formValue?.revenuePercentUS),
                ))
            ) {
              this.formDataAddCompanyForm = null;
              this.formDataAddCompanyForm = formValue;
              this.showRecalcConfModal = true;
            } else {
              this.updateInsuredCompany(
                formValue,
                false,
                false,
                false,
                isCalledFromAddProducer,
              );
            }
          }
        } else {
          this.updateInsuredCompany(
            formValue,
            false,
            false,
            false,
            isCalledFromAddProducer,
          );
        }
      } else {
        this.form$ = this.store.select(getQuoteSelector);
        let producerId,
          producerName,
          branchId,
          branchName,
          brokerageId,
          brokerageName;
        this.form$.subscribe((event) => {
          ({ producerId } = event.ui);
          producerId = event.ui.producerId;
          producerName = event.ui.producer;
          branchId = event.ui.branchId;
          branchName = event.ui.branch;
          brokerageId = event.ui.brokerageId;
          brokerageName = event.ui.broker;
        });
        this.actionPopupDetails = {
          ...this.actionPopupDetails,
          producerId: producerId,
          producerName: producerName,
          branchId: branchId,
          branchName: branchName,
          brokerageId: brokerageId,
          brokerageName: brokerageName,
          insured: formValue?.businessName,
        };
        this.addCompanyContinue(
          formValue,
          contactFormValue,
          isCalledFromAddProducer,
        );
      }
    } else {
      const formName = 'addCompanyForm';
      if (this.form.controls[formName].get(['website']).hasError('maxlength')) {
        this.setMaxLengthErrorMessage(formName, 'website');
      } else if (
        this.form.controls[formName].get(['address']).hasError('maxlength')
      ) {
        this.setMaxLengthErrorMessage(formName, 'address');
      } else if (
        this.form.controls[formName].get(['addressLine2']).hasError('maxlength')
      ) {
        this.setMaxLengthErrorMessage(formName, 'addressLine2');
      } else if (
        this.form.controls[formName].get(['city']).hasError('maxlength')
      ) {
        this.setMaxLengthErrorMessage(formName, 'city');
      } else if (
        this.form.controls[formName]
          .get(['zipPostalCode'])
          .hasError('maxlength')
      ) {
        this.setMaxLengthErrorMessage(formName, 'zipPostalCode');
      }
    }
  }

  getIndustryData(industryId) {
    const prediction = this.predictionsReturned.filter(
      (industryObj) => industryObj.industry.id === industryId,
    );
    if (prediction.length > 0) {
      return prediction[0];
    }
    return null;
  }

  updateInsuredCompany(
    formValue: any,
    isRecalculate: boolean = false,
    proceedWithSanctions = false,
    isStateChange = false,
    isCalledFromAddProducer = false,
  ) {
    if (formValue.insuredLocationId) {
      this.setLoader(true, 'hidden');
      const initialFormData = this.initialFormData;
      let initialContactFormData = this.initialContactFormData;
      this.regionService
        .GetByName(formValue.province)
        .subscribe(async (data) => {
          const regionId = data.data[0].id;

          let location = {
            address: !!formValue.address ? formValue.address : null,
            city: !!formValue.city ? formValue.city : null,
            country: !!formValue.country ? formValue.country : null,
            zipPostalCode: !!formValue.zipPostalCode
              ? formValue.zipPostalCode
              : null,
            addressLine2: !!formValue.addressLine2
              ? formValue.addressLine2
              : null,
            geoLocationLatitude: 'string',
            geoLocationLongitude: ' string',
            source: 0,
            regionId: regionId,
            id: formValue.insuredLocationId,
          };
          this.store.dispatch(
            new CreateQuoteAction.updateUiContents({ regionId: regionId }),
          );

          const hasLocationChanged = initialFormData
            ? this.hasLocationDataChanged(location, initialFormData)
            : false;

          // multiple contact form update
          // current contact form data
          const currentContactsFormData = this.contactForm.get(
            'contacts',
          ) as FormArray;

          const newContacts = this.findNewContacts(
            this.initialContactFormDataObject,
            this.form.get('contactForm').value,
          );

          // TODO need cleanup
          if (newContacts === false) {
            // initial contact form data
            this.billingCompanyContactService
              .GetByInsuredId(this.insuredId)
              .subscribe((res: any) => {
                this.compareAndUpdateInsuredContact(
                  res.data,
                  currentContactsFormData.value,
                  this.insuredId,
                  [],
                );
              });
          } else {
            this.compareAndUpdateInsuredContact(
              [],
              [],
              this.insuredId,
              newContacts,
            );
            this.initialContactFormDataObject =
              this.form.get('contactForm').value;
            this.billingCompanyContactService
              .GetByInsuredId(this.insuredId)
              .subscribe((res: any) => {
                this.compareAndUpdateInsuredContact(
                  res.data,
                  currentContactsFormData.value,
                  this.insuredId,
                  [],
                );
              });
          }

          try {
            if (hasLocationChanged) {
              const updateLocation =
                this.locationService.updateLocation(location);
              const locationUpdated = await firstValueFrom(updateLocation);
            }

            let insuredPayload: any = {
              companyName: formValue.businessName,
              companyWebsite: formValue.website
                ? websiteFormatting(formValue.website)
                : '',
              industryClassId: formValue.industryClassId,
              industryId: formValue.industryId,
              insuredLocationId: formValue.insuredLocationId,
              insuredType: 2,
              revenue: Number(formValue.revenue),
              webPressence: 'true',
              revenueUSPerc: Number(formValue.revenuePercentUS),
              revenueCAPerc: Number(formValue.revenuePercentCA),
              revenueOtherPerc: Number(formValue.revenuePercentOther),
              ofac: true,
              isMock: this.mockExternalAPIs,
            };
            if (formValue?.numOfEmployees) {
              insuredPayload.numOfEmployees = formValue?.numOfEmployees;
            }

            try {
              const updateInsuredDetails = await this.insuredService.Update(
                this.insuredId,
                insuredPayload,
              );
              const insuredUpdated: any =
                await firstValueFrom(updateInsuredDetails);

              // NAICS
              if (
                this.naicsData.industryIdPredictionA &&
                this.naicsData.industryIdPredictionA > 0
              ) {
                const naicsPostPayload = {
                  insuredId: this.insuredId,
                  industryIdPredictionA: this.naicsData.industryIdPredictionA,
                  accuracyPredictionA: this.naicsData.accuracyPredictionA,
                  industryIdPredictionB: this.naicsData.industryIdPredictionB,
                  accuracyPredictionB: this.naicsData.accuracyPredictionB,
                };
                const getNAICSByInsured = this.naicsService.GetByInsuredId(
                  this.insuredId,
                );
                let naicsByInsured = await firstValueFrom(getNAICSByInsured);
                if (
                  naicsByInsured?.data?.length === 0 ||
                  this.checkIfPredictionsDifferent(
                    naicsPostPayload,
                    naicsByInsured?.data[0],
                  )
                ) {
                  try {
                    const naicsPost =
                      this.naicsService.createNaics(naicsPostPayload);
                    let naicsPostResponse = await firstValueFrom(naicsPost);
                  } catch (e) {}
                }
              }

              if (isCalledFromAddProducer) {
                this.router.navigate(
                  ['/dashboard/workflow3/brokerage/directory'],
                  {
                    skipLocationChange: true,
                  },
                );
                return;
              }
              const ofac = insuredUpdated?.data?.ofac;
              if (!proceedWithSanctions && ofac.length > 0) {
                this.sanctionPayload = [...this.sanctionPayload, ...ofac];
              } else if (!(ofac?.error === false)) {
                if ([400, 401, 500].includes(ofac?.status)) {
                  this.isOfacError = true;
                }
              } else if (proceedWithSanctions) {
                this.sanctionPayload = [];
              }

              this.store.dispatch(
                new CreateQuoteAction.AddInsuredCompanyAction(formValue),
              );

              this.store.dispatch(
                new CreateQuoteAction.UpdateInsuredAction({
                  insuredName: formValue.businessName,
                  insuredEmail: formValue.email,
                  insuredId: this.insuredId,
                  regionId: regionId,
                  revenue: formValue.revenue,
                  noOfEmployees: formValue.noOfEmployees,
                  industryClass: formValue.industryClass,
                  industry: formValue.industry,
                  address: `${formValue.address}, ${
                    formValue.addressLine2 != ''
                      ? formValue.addressLine2 + ', '
                      : ''
                  }${formValue.city}, ${this.regionCode}, ${
                    formValue.zipPostalCode
                  }, ${formValue.country}`,
                  productId: formValue.productId,
                  productName: formValue.productName,
                }),
              );
              let isInsuredTypeCompany;
              let versionId = 1;
              this.form$
                .pipe(take(1))
                .subscribe(
                  (event) =>
                    (isInsuredTypeCompany = event.ui.isInsuredTypeCompany),
                );
              if (isInsuredTypeCompany) {
                versionId = 2;
              }
              this.store.dispatch(
                CreateQuoteAction.loadProducts({
                  userId: this.boxxUserId,
                  regionId: regionId,
                  versionId: versionId,
                }),
              );
              this.selectedIndustryId = formValue.industryId;
              if (!proceedWithSanctions) {
                await this.checkIfRestrictiveIndustry(
                  this.selectedIndustryId,
                  false,
                  formValue,
                );
              }

              this.store.dispatch(
                new InsuredAction.setInsuredNameAction(
                  formValue?.businessName ?? '',
                ),
              );

              this.setLoader(false, 'unset');

              if (isRecalculate === true) this.doRecalculate();
              if (proceedWithSanctions) {
                this.newQuoteService.setInsuredId(this.insuredId);
                if (isStateChange == true) {
                  this.showStateChangeModal = false;
                  let path = 'dashboard/quotes';
                  let quoteFlowSteps =
                    this.localStorageService.getQuoteFlowSteps();
                  if (quoteFlowSteps === 'workflow3') {
                    path = 'dashboard/workflow3/quotes';
                  }
                  this.router.navigate([path], {
                    skipLocationChange: true,
                  });
                } else {
                  if (
                    this.quoteExist === 1 &&
                    !this.isNavigatedFromRiskAnalysis
                  ) {
                    this.handleSeeExistingQuote();
                  } else if (
                    this.quoteExist === 2 &&
                    !this.isNavigatedFromRiskAnalysis
                  ) {
                    this.handleSeeExistingQuote();
                  } else {
                    if (this.isRestrictedRoleSubmission === true) {
                      const createQuote = await this.createQuote(formValue);
                      this.showSubmissionSubmittedModal = true;
                    } else {
                      this.newQuoteService.setInsuredId(this.insuredId);
                      this.router.navigate(
                        ['/dashboard/workflow3/new/product/form'],
                        {
                          relativeTo: this.activatedRoute,
                          skipLocationChange: true,
                        },
                      );
                    }
                  }
                }
              }
            } catch (error) {
              if (![500].includes(error?.status)) {
                this.alertData = {
                  show: true,
                  header: 'common.errorHeader',
                  body: getErrorMessage(error),
                  type: 'error',
                };
              }
              this.setLoader(false, 'unset');
            }
          } catch (error) {
            if (![500].includes(error?.status)) {
              this.alertData = {
                show: true,
                header: 'common.errorHeader',
                body: getErrorMessage(error),
                type: 'error',
              };
            }
            this.setLoader(false, 'unset');
          }
        });
    }
  }

  checkIfPredictionsDifferent(prediction1, prediction2) {
    const propertiesToCheck = [
      'industryIdPredictionA',
      'accuracyPredictionA',
      'industryIdPredictionB',
      'accuracyPredictionB',
    ];
    for (let property of propertiesToCheck) {
      if (
        Number(prediction1[property] ?? 0) !==
        Number(prediction2[property] ?? 0)
      ) {
        return true;
      }
    }
    return false;
  }

  checkIfRiskAnalysisUpdateRequired(initialRevenue, updatedRevenue) {
    if (
      initialRevenue === updatedRevenue ||
      (initialRevenue < this.revenueMarginValue &&
        updatedRevenue < this.revenueMarginValue) ||
      (initialRevenue >= this.revenueMarginValue &&
        updatedRevenue >= this.revenueMarginValue)
    ) {
      return false;
    } else {
      return true;
    }
  }

  checkIfUSRevenuePercTriggerRecalc(
    initialUSRevenuePerc,
    updatedUSRevenuePerc,
  ) {
    const usRevenuePercMargin = 10;
    if (
      initialUSRevenuePerc === updatedUSRevenuePerc ||
      (initialUSRevenuePerc <= usRevenuePercMargin &&
        updatedUSRevenuePerc <= usRevenuePercMargin) ||
      (initialUSRevenuePerc > usRevenuePercMargin &&
        updatedUSRevenuePerc > usRevenuePercMargin)
    ) {
      return false;
    } else {
      return true;
    }
  }

  checkForRecalculate(formControlName: string, initialValues): boolean {
    let changedControls = ['industry', 'industryClass', 'revenue'];
    let triggerRecalculate = false;
    if (formControlName === 'addCompanyForm') {
      triggerRecalculate = this.getChangedFieldsStatus(
        this.form.controls[formControlName].value,
        initialValues,
        changedControls,
      );
    }
    return triggerRecalculate;
  }

  getChangedFieldsStatus(
    obj1: any,
    obj2: any,
    fieldsToCheck: string[],
  ): boolean {
    const stringifyAndSort = (obj: any): string =>
      JSON.stringify(obj, Object.keys(obj).sort());

    const normalizeObject = (obj: any): any =>
      JSON.parse(JSON.stringify(obj), (key, value) =>
        typeof value === 'string' && !isNaN(Number(value))
          ? this.roundNumber(Number(value), 2)
          : value,
      );

    const normalizedObj1 = normalizeObject(obj1);
    const normalizedObj2 = normalizeObject(obj2);

    const stringifiedObj1 = stringifyAndSort(normalizedObj1);
    const stringifiedObj2 = stringifyAndSort(normalizedObj2);

    if (stringifiedObj1 === stringifiedObj2) {
      return false;
    }

    const changedFields: string[] = [];
    fieldsToCheck.forEach((field) => {
      if (normalizedObj1[field] !== normalizedObj2[field]) {
        changedFields.push(field);
      }
    });

    return changedFields.length === 0 ? false : true;
  }

  roundNumber(value: number, precision: number): number {
    const factor = Math.pow(10, precision);
    return Math.round(value * factor) / factor;
  }

  doRecalculate() {
    let policyRiskList;
    if (this.policyPeriodId) {
      this.setLoader(true, 'hidden');
      this.policyRiskService
        .GetAllByPolicyPeriodId(this.policyPeriodId)
        .subscribe(async (data) => {
          if (data?.data[0]) {
            policyRiskList = data?.data;

            const resultArray = policyRiskList.flatMap((item) =>
              item.PolicyRiskTrxes.flatMap((trx) =>
                trx.QuoteOptions.map((option) => option.id),
              ),
            );

            for (const element of resultArray) {
              try {
                const riskRating =
                  await this.riskRatingCalculateService.GetByQuoteOption(
                    element,
                  );
                const ratingData = await firstValueFrom(riskRating);
              } catch (error) {}
            }

            this.closeModalRecalculateConf();
            this.setLoader(false, 'unset');

            this.newQuoteService.setInsuredId(this.insuredId);

            if (this.quoteExist === 1 && !this.isNavigatedFromRiskAnalysis) {
              this.showExistingQuoteModal = true;
            } else if (
              this.quoteExist === 2 &&
              !this.isNavigatedFromRiskAnalysis
            ) {
              await this.createDuplicateQuote();
              this.showMarketReservationModal = true;
            }
          }
        });
    }
  }
  closeModalRecalculateConf() {
    this.showRecalcConfModal = false;
    this.isQuoteInSubmission = false;
  }

  handleSubmissionRecalculate(action: any, formData: any) {
    this.updateInsuredCompany(this.formDataAddCompanyForm, true, true);
  }

  handleRiskAnalysisChange() {
    this.updateInsuredCompany(this.formDataAddCompanyForm, false, true);
  }

  handleDiscardRecalculate(action: any) {
    const producerInfo = this.addCompanyForm.value['brokerageinfo'];
    this.addCompanyForm.patchValue(this.initialFormData);
    this.addCompanyForm.get('brokerageinfo').setValue(producerInfo);
    this.showRecalcConfModal = false;
    this.isQuoteInSubmission = false;
    this.showRiskAnalysisUpdateModal = false;
    this.formSubmitted = false;
    this.closeSanctionPopup();
  }

  checkForStateChange(formControlName: string, initialValues): boolean {
    return (
      this.form.controls[formControlName].value.province !==
      initialValues?.province
    );
  }

  closeModalMarketReservation() {
    this.showMarketReservationModal = false;
    this.quoteExist = 0;
  }

  closeExistingQuote() {
    this.showExistingQuoteModal = false;
    this.quoteExist = 0;
  }

  handleSeeExistingQuote() {
    if (this.isRestrictedRoleSubmission === true) {
      this.handleCreateNewInsured();
      return;
    }
    this.showBodySpinner = true;
    this.policyDashboardService
      .GetExistingPoliciesByInsuredId(this.insuredId, 'Quote')
      .subscribe(async (data) => {
        const quoteStatus =
          data.data[0]?.policyRiskTrxes.quoteStatus.description;
        const quoteID = data?.data[0]?.policy?.id;
        const policyPeriodId = data.data[0]?.policyPeriod?.id;
        this.store.dispatch(
          new CreateQuoteAction.setPolicyPeriodId(policyPeriodId),
        );
        let brokerageCommission =
          data?.data[0]?.policyRiskTrxes?.['policyRisk'][0]
            ?.brokerCommissionPerc ?? 0;
        brokerageCommission = brokerageCommission * 100;
        this.newQuoteService.setInsuredId(this.insuredId);
        if (quoteStatus.toLowerCase() == 'submission' && policyPeriodId) {
          this.router.navigate(
            [`/dashboard/workflow3/new/product/form/${quoteID}`],
            {
              relativeTo: this.activatedRoute,
              skipLocationChange: true,
            },
          );
          this.showBodySpinner = false;
        } else if (isStatusDisabled(quoteStatus)) {
          this.router.navigate([`/dashboard/workflow3/${quoteID}`], {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          });
          this.showBodySpinner = false;
        } else if (brokerageCommission === 0) {
          this.router.navigate(
            [`/dashboard/workflow3/new/product/form/${quoteID}`],
            {
              relativeTo: this.activatedRoute,
              skipLocationChange: true,
            },
          );
          this.showBodySpinner = false;
        } else if (quoteStatus.toLowerCase() == 'quoted' && quoteID) {
          this.router.navigate([`/dashboard/workflow3/${quoteID}`], {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          });
          this.showBodySpinner = false;
        } else if (quoteStatus.toLowerCase() == 'referral' && quoteID) {
          this.isReferral = true;
          this.router.navigate([`/dashboard/workflow3/${quoteID}`], {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          });
          this.showBodySpinner = false;
        } else {
          this.router.navigate([`/dashboard/workflow3/${quoteID}`], {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          });
          this.showBodySpinner = false;
        }
      });
  }

  closeModalInsuredStateChange() {
    this.showStateChangeModal = false;
    this.quoteExist = 0;
  }

  closeModalRiskAnalysisUpdate() {
    this.showRiskAnalysisUpdateModal = false;
    this.quoteExist = 0;
  }

  handleCreateNewInsured() {
    this.showBodySpinner = true;
    this.radioIndividual = [
      {
        label: this.translateService.instant(
          'workFlow3.components.newSubmission.label.individual',
        ),
        value: 'individual',
      },
    ];
    this.radioBusiness = [
      {
        label: this.translateService.instant(
          'workFlow3.components.newSubmission.label.business',
        ),
        value: 'business',
      },
    ];
    this.form.get('addCompanyForm').reset();
    this.store.dispatch(new CreateQuoteAction.ResetState());
    this.enableProducerInfo = false;
    this.checkValidation = false;
    this.formSubmitted = false;
    this.insuredId = null;
    this.insuredData = null;
    this.initialFormData = null;
    this.showStateChangeModal = false;
    this.showIndustrySection = false;
    this.quoteExist = 0;
    this.activeQuoteId = null;
    this.disableProduct = false;
    this.showMarketReservationModal = false;
    this.showExistingQuoteModal = false;
    this.showRiskAnalysisUpdateModal = false;
    this.showRecalcConfModal = false;
    this.showSanctionModal = false;
    this.isQuoteInSubmission = false;
    setTimeout(() => {
      this.showBodySpinner = false;
    }, 0);
  }

  handleDeclineQuote(isStateChange = false) {
    if (this.isRestrictedRoleSubmission === true) {
      this.handleCreateNewInsured();
      return;
    }
    if (this.policyPeriodId) {
      const PolicyDeclineRequest = {
        policyPeriodId: Number(this.policyPeriodId),
        declineReason: 173,
      };

      this.policyLifecycleService
        .TransactionRiskDecline(PolicyDeclineRequest)
        .subscribe({
          next: (response) => {
            this.updateInsuredCompany(
              this.formDataAddCompanyForm,
              false,
              true,
              true,
            );
          },
          error: (error) => {
            this.showSanctionModal = false;
            this.alertData = {
              show: true,
              header: 'common.errorHeader',
              body: getErrorMessage(error),
              type: 'error',
            };
          },
        });
    } else {
      this.updateInsuredCompany(
        this.form.get('addCompanyForm').value,
        false,
        true,
        true,
      );
    }
  }

  checkForActiveQuote(insuredId: any) {
    this.setLoader(true, 'hidden');
    return new Promise<any>(async (resolve, reject) => {
      this.policyMarketReservationService.GetByInsuredId(insuredId).subscribe({
        next: async (resp) => {
          const quoteDetails = resp?.data;
          this.activeQuoteId = quoteDetails?.policyId;
          this.isQuoteInSubmission =
            quoteDetails?.quoteStatus === QUOTESTATUS_SUBMISSION;
          if (quoteDetails?.policyId) {
            this.policyPeriodService
              .GetAllByPolicyId(quoteDetails?.policyId)
              .subscribe(async (data) => {
                const brokerageProducerId = data.data[0]?.brokerageProducerId;
                this.policyPeriodId = data.data[0]?.id;

                if (this.isRestrictedRoleSubmission === true) {
                  const policyDashboardServiceObs =
                    this.policyDashboardService.Get(this.policyPeriodId);
                  const policyDashboardData = await firstValueFrom(
                    policyDashboardServiceObs,
                  );
                  this.productId =
                    policyDashboardData?.data?.PolicyRisks[0]?.risk.id ?? '';
                  this.productName =
                    policyDashboardData?.data?.PolicyRisks[0]?.risk.name ?? '';
                }

                this.brokerageProducerService
                  .GetBrokerageProducerId(brokerageProducerId ?? 0)
                  .subscribe(async (dataProd) => {
                    this.form$ = this.store.select(getQuoteSelector);
                    let producerId;
                    this.form$.subscribe((event) => {
                      ({ producerId } = event.ui);
                      producerId = event.ui.producerId;
                    });
                    const selectedProducer =
                      this.isRestrictedRoleSubmission === true &&
                      producerId === 0
                        ? brokerageProducerId
                        : producerId;

                    if (quoteDetails?.activeQuote) {
                      this.disableProduct = true;
                      if (brokerageProducerId === selectedProducer) {
                        this.quoteExist = 1;
                      } else {
                        this.quoteExist = 2;
                      }
                    } else {
                      this.quoteExist = 0;
                    }
                    this.actionPopupDetails = {
                      ...this.actionPopupDetails,
                      quoteNumber: null,
                      effectiveDate: null,
                      insured: null,
                    };
                    this.actionPopupDetails = {
                      ...this.actionPopupDetails,
                      quoteNumber: quoteDetails?.policyId,
                      effectiveDate: formatDateMoment(
                        quoteDetails?.effectiveDt,
                        this.shortDateFormat,
                      ),
                      quotePeriod: {
                        effectiveDt: quoteDetails?.effectiveDt,
                        expiryDt: quoteDetails?.expiryDt,
                      },
                      producerId: dataProd?.data?.id,
                      producerName: `${dataProd?.data?.firstName} ${dataProd?.data?.lastName}`,
                      branchId: dataProd?.data?.brokerageBranch.id,
                      branchName: dataProd?.data?.brokerageBranch.name,
                      brokerageId: dataProd?.data?.brokerageBranch.brokerage.id,
                      brokerageName:
                        dataProd?.data?.brokerageBranch.brokerage.name,
                    };
                    if (producerId === 0)
                      this.store.dispatch(
                        new CreateQuoteAction.updateUiContents({
                          broker:
                            dataProd?.data?.brokerageBranch.brokerage.name ??
                            '',
                          producer: `${dataProd?.data?.firstName} ${dataProd?.data?.lastName}`,
                          branch: dataProd?.data?.brokerageBranch.name ?? '',
                          brokerageId:
                            dataProd?.data?.brokerageBranch.brokerage.id,
                          branchId: dataProd?.data?.brokerageBranch.id,
                          producerId: dataProd?.data?.id,
                        }),
                      );
                    this.setLoader(false, 'unset');
                    resolve(true);
                  });
              });
          } else {
            this.form$ = this.store.select(getQuoteSelector);
            let producerId,
              producerName,
              branchId,
              branchName,
              brokerageId,
              brokerageName;
            this.form$.subscribe((event) => {
              ({ producerId } = event.ui);
              producerId = event.ui.producerId;
              producerName = event.ui.producer;
              branchId = event.ui.branchId;
              branchName = event.ui.branch;
              brokerageId = event.ui.brokerageId;
              brokerageName = event.ui.broker;
            });
            this.actionPopupDetails = {
              ...this.actionPopupDetails,
              producerId: producerId,
              producerName: producerName,
              branchId: branchId,
              branchName: branchName,
              brokerageId: brokerageId,
              brokerageName: brokerageName,
            };
            this.store.dispatch(new setQuoteStatus(''));
            this.store.dispatch(new setTransactionLvlQuoteStatus(''));

            this.store.dispatch(
              new updatePolicyRiskIdAndStatus({
                policyRiskId: null,
                quoteStatus: '',
              }),
            );
            this.setLoader(false, 'unset');
            resolve(true);
          }
        },
        error: (error) => {
          this.setLoader(false, 'unset');
          reject(getErrorMessage(error));
        },
      });
    });
  }

  async populateInsuredDetails(insuredId) {
    // commented the below as to revisit the logic  - related ticket 2730
    // this.isExistingInsuredFormEdit = true;
    this.showPredictions = false;
    this.showIndustrySection = true;
    this.confirmIndustryShow = true;
    this.showBodySpinner = true;
    this.enableProducerInfo = true;
    this.formSubmitted = false;
    this.isSuggestedOption = true;
    this.selectedFromSuggestion = true;
    try {
      const getInsuredData = this.insuredService.GetByInsuredId(insuredId);
      let insuredData = await firstValueFrom(getInsuredData);
      const getBillingContactData =
        this.billingCompanyContactService.GetByInsuredId(insuredId);
      let insuredBillingContactData = await firstValueFrom(
        getBillingContactData,
      );

      if (insuredData?.data) {
        if (insuredBillingContactData.data.length !== 0) {
          // for multiple contact prepopulate
          const contactsFormArray = this.contactForm.get(
            'contacts',
          ) as FormArray;
          contactsFormArray.clear();
          insuredBillingContactData?.data.forEach((contact) => {
            if (!this.areAllContactFieldsEmpty(contact, true)) {
              contactsFormArray.push(this.createContact(contact));
            }
          });
        }
        this.initialContactFormDataObject = this.form.get('contactForm').value;

        const insured = insuredData?.data;
        this.regionCode = insured?.insuredLocation?.region?.regionCode;

        if (this.isRestrictedRoleSubmission === true)
          await Promise.all([this.checkForActiveQuote(insuredId)]);

        this.form.get('addCompanyForm').setValue({
          businessName: insured?.companyName ?? '',
          website: insured?.companyWebsite ?? '',
          revenue: insured?.revenue ? Number(insured?.revenue) : '',
          noOfEmployees: this.getNumberOfEmployees(
            insured?.numOfEmployees ?? '',
          ),
          industry: insured?.industry?.name ?? '',
          industryClass: insured?.industryClass?.name ?? '',
          revenuePercentUS: insured?.revenueUSPerc ?? '',
          revenuePercentCA: insured?.revenueCAPerc ?? '',
          revenuePercentOther: insured?.revenueOtherPerc ?? '',
          country: insured?.insuredLocation?.country ?? '',
          province: insured?.insuredLocation?.region?.name ?? '',
          city: insured?.insuredLocation?.city ?? '',
          address: insured?.insuredLocation?.address ?? '',
          zipPostalCode: insured?.insuredLocation?.zipPostalCode ?? '',
          addressLine2: insured?.insuredLocation?.addressLine2 ?? '',
          insuredId: insured?.id,
          insuredLocationId: insured?.insuredLocation?.id,
          industryClassId: insured?.industryClass?.id,
          industryId: insured?.industry?.id,
          numOfEmployees: insured?.numOfEmployees ?? '',
          brokerageinfo: insured?.brokerageinfo ?? null,
          productId: this.productId ?? '',
          productName: this.productName ?? '',
        });

        this.insuredName = insured?.companyName ?? '';
        this.store.dispatch(
          new InsuredAction.setInsuredNameAction(this.insuredName ?? ''),
        );
        this.store.dispatch(
          new CreateQuoteAction.updateUiContents({
            insuredName: this.insuredName ?? '',
          }),
        );
        this.actionPopupDetails = {
          ...this.actionPopupDetails,
          insured: this.insuredName,
        };
        if (
          insuredBillingContactData?.data[0]?.firstName ||
          insuredBillingContactData?.data[0]?.lastName ||
          insuredBillingContactData?.data[0]?.email ||
          insuredBillingContactData?.data[0]?.title ||
          insuredBillingContactData?.data[0]?.phone
        ) {
          this.showContactDetails = true;
          this.contactToggleEnabled = true;
        }
        this.initialFormData = this.form.get('addCompanyForm').value;
        this.initialContactFormData = insuredBillingContactData?.data;
        this.insuredData = insured;
        this.insuredId = insured?.id;
        this.radioBusiness[0].disabled = true;
        this.radioIndividual[0].disabled = true;
        // await Promise.all([this.getInsuredTimeZone()]);
        this.checkValidation = true;
        this.formSubmitted = true;
      }
      this.showBodySpinner = false;
    } catch (error) {
      if (
        ![500].includes(error?.status) &&
        !error.message.includes('NG01002')
      ) {
        this.alertData = {
          show: true,
          type: 'error',
          header: 'common.errorMsg',
          body: getErrorMessage(error),
        };
      }
    }
    this.showBodySpinner = false;
  }

  getNumberOfEmployees(id) {
    let numberOfEmployees = '';
    if (id) {
      const selectedNumberOfEmployeesIdx = this.noOfEmpOptns?.findIndex(
        (numOfEmployee) => numOfEmployee?.id === id,
      );
      if (selectedNumberOfEmployeesIdx !== -1) {
        numberOfEmployees =
          this.noOfEmpOptns[selectedNumberOfEmployeesIdx]?.value ?? '';
      }
    }
    return numberOfEmployees;
  }

  handleExitFlow() {
    let isMock = 'true';
    if (
      this.localStorageService.getMockExternalAPIs() === 'false' ||
      environment.mockExternalAPIs === false
    ) {
      isMock = 'false';
    }
    this.router.navigate(['/dashboard/home'], {
      queryParams: {
        mock: isMock,
      },
      skipLocationChange: true,
    });
  }
  hasFormValueChanged(): boolean {
    const stringifyAndSort = (obj: any): string =>
      JSON.stringify(obj, Object.keys(obj).sort());

    const normalizeObject = (obj: any): any =>
      JSON.parse(JSON.stringify(obj), (key, value) =>
        typeof value === 'string' && !isNaN(Number(value))
          ? this.roundNumber(Number(value), 2)
          : value,
      );
    if (this.form?.value && this.initialFormData) {
      const normalizedObj1 = normalizeObject(this.form.value);
      const normalizedObj2 = normalizeObject(this.initialFormData);

      const stringifiedObj1 = stringifyAndSort(normalizedObj1);
      const stringifiedObj2 = stringifyAndSort(normalizedObj2);

      return stringifiedObj1 !== stringifiedObj2;
    } else return false;
  }

  handleInsuredTypeChange() {
    this.insuredId = undefined;
    if (this.isExistingInsuredFormEdit) {
      this.clearInsuredForm();
    }

    this.typeBusinessInsured = !this.typeBusinessInsured;
    this.store.dispatch(new CreateQuoteAction.ResetInsuredTypeAction());
  }

  async addOrUpdateInsurer(isCalledFromAddProducer = false) {
    this.checkValidation = true;
    this.formSubmitted = true;
    this.form$ = this.store.select(getQuoteSelector);
    if (!isCalledFromAddProducer) {
      let producerId;
      this.form$.subscribe((event) => {
        ({ producerId } = event.ui);
        producerId = event?.ui?.producerId;
      });
      if (!producerId) return;
    }

    //Check whether the insurer has an existing policy for the same product
    if (this.insuredId) {
      await this.handleSeeExistingPolicy();
      if (this.hasActivePolicy) {
        return;
      }
    }

    if (this.typeBusinessInsured === true) {
      this.addOrUpdateCompanyInsured(isCalledFromAddProducer);
    } else {
      this.addOrUpdateIndividualInsured(isCalledFromAddProducer);
    }
  }

  async addOrUpdateIndividualInsured(isCalledFromAddProducer) {
    if (this.form.get('addIndividualForm').valid) {
      this.isOfacError = false;
      this.alertService.clearAlerts(-1);

      const individualForm = this.form.controls['addIndividualForm'].value;

      this.insuredId = individualForm.insuredId
        ? individualForm.insuredId
        : null;
      this.isEditMode = individualForm.insuredId ? true : false;
      this.productId = individualForm.productId;
      this.productName = individualForm.productName;
      if (this.insuredId !== null)
        await Promise.all([this.checkForActiveQuote(this.insuredId)]);
      if (this.isEditMode) {
        if (this.quoteExist !== 0) {
          if (
            this.checkForStateChange(
              'addIndividualForm',
              this.initialFormDataIndividual,
            ) === true
          ) {
            this.formDataAddIndividualForm = null;
            this.formDataAddIndividualForm = individualForm;
            this.showStateChangeModal = true;
            return;
          }
        } else {
          this.updateInsuredIndividual(individualForm);
        }
      } else {
        this.addIndividualContinue(individualForm, isCalledFromAddProducer);
      }
    } else {
      const formName = 'addIndividualForm';
      if (
        this.form.controls[formName].get(['firstName']).hasError('maxlength')
      ) {
        this.setMaxLengthErrorMessage(formName, 'firstName');
      } else if (
        this.form.controls[formName].get(['middleName']).hasError('maxlength')
      ) {
        this.setMaxLengthErrorMessage(formName, 'middleName');
      } else if (
        this.form.controls[formName].get(['lastName']).hasError('maxlength')
      ) {
        this.setMaxLengthErrorMessage(formName, 'lastName');
      } else if (
        this.form.controls[formName].get(['email']).hasError('maxlength')
      ) {
        this.setMaxLengthErrorMessage(formName, 'email');
      } else if (
        this.form.controls[formName].get(['address']).hasError('maxlength')
      ) {
        this.setMaxLengthErrorMessage(formName, 'address');
      } else if (
        this.form.controls[formName].get(['addressLine2']).hasError('maxlength')
      ) {
        this.setMaxLengthErrorMessage(formName, 'addressLine2');
      } else if (
        this.form.controls[formName].get(['city']).hasError('maxlength')
      ) {
        this.setMaxLengthErrorMessage(formName, 'city');
      } else if (
        this.form.controls[formName]
          .get(['zipPostalCode'])
          .hasError('maxlength')
      ) {
        this.setMaxLengthErrorMessage(formName, 'zipPostalCode');
      }
    }
  }

  setMaxLengthErrorMessage(formName: string, fieldName: string) {
    const charLimit =
      this.form.controls[formName].get([fieldName]).errors['maxlength']
        ?.requiredLength ?? 0;
    const errMsg =
      charLimit !== 0
        ? this.translateService
            .instant('error.maximumAllowedCharErroMsg')
            ?.replace('{charLimit}', charLimit)
        : this.translateService.instant(
            'error.maximumAllowedCharDefaultErroMsg',
          );
    this.form.controls[formName].get([fieldName]).setErrors({
      message: errMsg,
    });
  }

  addIndividualContinue(individualForm, isCalledFromAddProducer) {
    this.setLoader(true, 'hidden');

    if (this.form.get('addIndividualForm').valid) {
      try {
        this.regionService
          .GetByName(individualForm.province)
          .subscribe((data) => {
            const regionId = data.data[0].id;

            let location = {
              address: !!individualForm.address ? individualForm.address : null,
              city: !!individualForm.city ? individualForm.city : null,
              country: !!individualForm.country ? individualForm.country : null,
              zipPostalCode: !!individualForm.zipPostalCode
                ? individualForm.zipPostalCode
                : null,
              addressLine2: !!individualForm.addressLine2
                ? individualForm.addressLine2
                : null,
              geoLocationLatitude: 'string',
              geoLocationLongitude: ' string',
              source: 0,
              regionId: regionId,
            };

            this.locationService.Create(location).subscribe((data) => {
              let insured: InsuredIndividualPostRequest;
              insured = {
                insuredType: 1,
                insuredLocationId: data.data?.id,
                email: individualForm.email,
                phone: individualForm.phone,
                firstName: individualForm.firstName,
                lastName: individualForm.lastName,
                middleName: individualForm.middleName,
                numOfHouseholdMem: individualForm?.numberOfHouseholdMembersId,
                ofac: true,
                isMock: this.mockExternalAPIs,
              };
              this.productId = individualForm.productId;
              this.productName = individualForm.productName;
              this.insuredService.Create(insured).subscribe({
                next: async (data) => {
                  this.form.controls['addIndividualForm']
                    .get('insuredLocationId')
                    .setValue(data?.data?.insuredLocationId);
                  this.form.controls['addIndividualForm']
                    .get('insuredId')
                    .setValue(data?.data?.id);
                  const insuredId = data.data.id;
                  this.insuredId = data.data.id;
                  await Promise.all([this.getInsuredTimeZone()]);
                  const ofac = data.data.ofac;
                  if (ofac?.length > 0) {
                    this.sanctionPayload = [...this.sanctionPayload, ...ofac];
                  } else if (!(ofac?.error === false)) {
                    if ([400, 401, 500].includes(ofac?.status)) {
                      this.isOfacError = true;
                    }
                  }

                  if (isCalledFromAddProducer) {
                    this.router.navigate(
                      ['/dashboard/workflow3/brokerage/directory'],
                      {
                        skipLocationChange: true,
                      },
                    );
                    return;
                  }
                  this.newQuoteService.setSanction({
                    clearance: this.sanctionPayload,
                    isRestricted: false,
                  });
                  this.store.dispatch(
                    new CreateQuoteAction.setSanctionAction({
                      clearance: this.sanctionPayload,
                      isRestricted: false,
                    }),
                  );
                  this.store.dispatch(
                    new CreateQuoteAction.AddInsuredCustomerAction(
                      individualForm,
                    ),
                  );
                  let isInsuredTypeCompany;
                  let versionId = 1;
                  this.form$.subscribe(
                    (event) =>
                      (isInsuredTypeCompany = event.ui.isInsuredTypeCompany),
                  );
                  if (isInsuredTypeCompany) {
                    versionId = 2;
                  }

                  this.store.dispatch(
                    new CreateQuoteAction.UpdateInsuredAction({
                      insuredName: this.getCustomerName(individualForm),
                      insuredEmail: individualForm.email,
                      insuredId: insuredId,
                      regionId: regionId,
                    }),
                  );
                  this.store.dispatch(
                    CreateQuoteAction.loadProducts({
                      userId: this.boxxUserId,
                      regionId: regionId,
                      versionId: versionId,
                    }),
                  );
                  this.setLoader(false, 'unset');
                  if (ofac.length > 0) {
                    this.showSanctionModal = true;
                    return;
                  } else {
                    if (this.isRestrictedRoleSubmission === true) {
                      try {
                        const createQuote =
                          await this.createQuote(individualForm);
                        this.showSubmissionSubmittedModal = true;
                      } catch (error) {
                        if (![500].includes(error?.status)) {
                          const errAlert = {
                            show: true,
                            type: 'error',
                            headerText: 'common.errorHeader',
                            bodyText: getErrorMessage(error),
                          };
                          if (this.alertService.addAlert(errAlert))
                            this.setLoader(false, 'unset');
                          this.showSpinner = false;
                        } else {
                          this.setLoader(false, 'unset');
                          this.showSpinner = false;
                        }
                      }
                    } else {
                      this.newQuoteService.setInsuredId(insuredId);
                      this.router.navigate(
                        ['/dashboard/workflow3/new/product/form'],
                        {
                          relativeTo: this.activatedRoute,
                          skipLocationChange: true,
                        },
                      );
                      this.handleExitFlow();
                    }
                  }
                  if (this.isRestrictedRoleSubmission === true) {
                    try {
                      const createQuote =
                        await this.createQuote(individualForm);
                      this.showSubmissionSubmittedModal = true;
                    } catch (error) {
                      if (![500].includes(error?.status)) {
                        const errAlert = {
                          show: true,
                          type: 'error',
                          headerText: 'common.errorHeader',
                          bodyText: getErrorMessage(error),
                        };
                        if (this.alertService.addAlert(errAlert))
                          this.setLoader(false, 'unset');
                        this.showSpinner = false;
                      } else {
                        this.setLoader(false, 'unset');
                        this.showSpinner = false;
                      }
                    }
                  } else {
                    this.newQuoteService.setInsuredId(insuredId);
                    this.router.navigate(
                      ['/dashboard/workflow3/new/product/form'],
                      {
                        relativeTo: this.activatedRoute,
                        skipLocationChange: true,
                      },
                    );
                  }
                },
                error: (error) => {
                  if (![500].includes(error?.status)) {
                    this.alertData = {
                      show: true,
                      type: 'error',
                      header: 'common.errorHeader',
                      body: getErrorMessage(error),
                    };
                  }
                },
              });
            });
          });
      } catch (error) {
        if (![500].includes(error?.status)) {
          this.alertData = {
            show: true,
            type: 'error',
            header: 'common.errorHeader',
            body: getErrorMessage(error),
          };
        }
      }
    }
  }

  updateInsuredIndividual(individualForm) {
    if (individualForm.insuredLocationId) {
      this.setLoader(true, 'hidden');
      this.regionService
        .GetByName(individualForm.province)
        .subscribe(async (data) => {
          const regionId = data.data[0].id;

          let location = {
            address: !!individualForm.address ? individualForm.address : null,
            city: !!individualForm.city ? individualForm.city : null,
            country: !!individualForm.country ? individualForm.country : null,
            zipPostalCode: !!individualForm.zipPostalCode
              ? individualForm.zipPostalCode
              : null,
            addressLine2: !!individualForm.addressLine2
              ? individualForm.addressLine2
              : null,
            geoLocationLatitude: 'string',
            geoLocationLongitude: ' string',
            source: 0,
            regionId: regionId,
            id: individualForm.insuredLocationId,
          };
          this.store.dispatch(
            new CreateQuoteAction.updateUiContents({ regionId: regionId }),
          );

          const hasLocationChanged = this.hasLocationDataChanged(
            location,
            this.initialFormDataIndividual,
          );

          try {
            if (hasLocationChanged) {
              const updateLocation =
                this.locationService.updateLocation(location);
              const locationUpdated = await firstValueFrom(updateLocation);
            }

            const insuredPayload = {
              insuredType: 1,
              insuredLocationId: individualForm.insuredLocationId,
              email: individualForm.email,
              phone: individualForm.phone,
              firstName: individualForm.firstName,
              lastName: individualForm.lastName,
              middleName: individualForm.middleName,
              numOfHouseholdMem: individualForm?.numberOfHouseholdMembersId,
            };
            try {
              const updateInsuredDetails = this.insuredService.Update(
                this.insuredId,
                insuredPayload,
              );
              const insuredUpdated = await firstValueFrom(updateInsuredDetails);
              // await Promise.all([this.getInsuredTimeZone()]);
              this.store.dispatch(
                new CreateQuoteAction.AddInsuredCustomerAction(individualForm),
              );
              let isInsuredTypeCompany;
              let versionId = 1;
              this.form$.subscribe(
                (event) =>
                  (isInsuredTypeCompany = event.ui.isInsuredTypeCompany),
              );
              if (isInsuredTypeCompany) {
                versionId = 2;
              }

              this.store.dispatch(
                new CreateQuoteAction.UpdateInsuredAction({
                  insuredName: this.getCustomerName(individualForm),
                  insuredEmail: individualForm.email,
                  insuredId: this.insuredId,
                  regionId: regionId,
                }),
              );
              this.store.dispatch(
                CreateQuoteAction.loadProducts({
                  userId: this.boxxUserId,
                  regionId: regionId,
                  versionId: versionId,
                }),
              );
              this.store.dispatch(
                new InsuredAction.setInsuredNameAction(
                  this.getCustomerName(individualForm),
                ),
              );
              this.newQuoteService.setInsuredId(this.insuredId);
              this.setLoader(false, 'unset');
              if (this.quoteExist === 1 && !this.isNavigatedFromRiskAnalysis) {
                this.showExistingQuoteModal = true;
              } else if (
                this.quoteExist === 2 &&
                !this.isNavigatedFromRiskAnalysis
              ) {
                await this.createDuplicateQuote();
                this.showMarketReservationModal = true;
              } else {
                if (this.isRestrictedRoleSubmission === true) {
                  const createQuote = await this.createQuote(individualForm);
                  this.showSubmissionSubmittedModal = true;
                } else {
                  this.newQuoteService.setInsuredId(this.insuredId);
                  this.router.navigate(
                    ['/dashboard/workflow3/new/product/form'],
                    {
                      relativeTo: this.activatedRoute,
                      skipLocationChange: true,
                    },
                  );
                }
              }
            } catch (error) {
              if (![500].includes(error?.status)) {
                this.alertData = {
                  show: true,
                  header: 'common.errorHeader',
                  body: getErrorMessage(error),
                  type: 'error',
                };
              }
              this.setLoader(false, 'unset');
            }
          } catch (error) {
            if (![500].includes(error?.status)) {
              this.alertData = {
                show: true,
                header: 'common.errorHeader',
                body: getErrorMessage(error),
                type: 'error',
              };
            }
            this.setLoader(false, 'unset');
          }
        });
    }
  }

  async populateInsuredDetailsIndividual(insuredId) {
    // commented the below as to revisit the logic  - related ticket 2730
    // this.isExistingInsuredFormEdit = true;
    this.showBodySpinner = true;
    this.insuredDataIndividual = null;
    this.initialFormDataIndividual = null;
    this.enableProducerInfo = true;
    this.formSubmitted = false;
    this.selectedFromSuggestion = true;
    try {
      const getInsuredData = this.insuredService.GetByInsuredId(insuredId);
      let insuredData = await firstValueFrom(getInsuredData);
      const insured = insuredData?.data;
      if (insured) {
        const numOfHouseHoldMembers = this.householdMemberOptns.filter(
          (data) => data.id === insured?.numOfHouseholdMem,
        )?.[0]?.key;

        if (this.isRestrictedRoleSubmission === true)
          await Promise.all([this.checkForActiveQuote(insuredId)]);

        this.form.get('addIndividualForm').setValue({
          firstName: insured?.firstName ?? '',
          middleName: insured?.middleName ?? '',
          lastName: insured?.lastName ?? '',
          email: insured?.email ?? '',
          phone: insured?.phone ?? '',
          householdMembers: numOfHouseHoldMembers ?? '',
          country: insured?.insuredLocation?.country ?? '',
          province: insured?.insuredLocation?.region?.name ?? '',
          city: insured?.insuredLocation?.city ?? '',
          address: insured?.insuredLocation?.address ?? '',
          zipPostalCode: insured?.insuredLocation?.zipPostalCode ?? '',
          addressLine2: insured?.insuredLocation?.addressLine2 ?? '',
          insuredLocationId: insured?.insuredLocation?.id,
          numberOfHouseholdMembersId: insured?.numOfHouseholdMem ?? '',
          insuredId: insured?.id,
          brokerageinfo: insured?.brokerageinfo ?? null,
          productId: this.productId ?? '',
          productName: this.productName ?? '',
        });
        this.initialFormDataIndividual =
          this.form.get('addIndividualForm').value;
        this.insuredDataIndividual = insured;
        this.insuredId = insured?.id;
        this.radioBusiness[0].disabled = true;
        this.radioIndividual[0].disabled = true;
        this.checkValidation = true;
        this.formSubmitted = true;
      }
      this.showBodySpinner = false;
    } catch (error) {
      if (![500].includes(error?.status)) {
        this.alertData = {
          show: true,
          type: 'error',
          header: 'common.errorMsg',
          body: getErrorMessage(error),
        };
      }
    }
    this.showBodySpinner = false;
  }

  handleAlertClose() {
    this.alertData = {
      show: false,
      type: '',
      header: '',
      body: '',
    };
  }

  setRegionID(id) {
    this.regionId = id;
  }

  handleAlertInfo(event) {
    this.alertData = {
      type: event.type,
      header: event.header,
      body: event.body,
      show: event?.show,
    };
  }

  clickRevenueDetais() {
    this.showRevenueField = false;
    this.enableProducerInfo = true;
    setTimeout(() => {
      this.showRevenueField = true;
    }, 0);
  }

  clickAddressDetais() {
    this.showAddressField = false;
    this.enableProducerInfo = true;
    setTimeout(() => {
      this.showAddressField = true;
    }, 0);
  }

  showProducerInfo(event) {
    this.enableProducerInfo = event;
  }

  private initLoadStore = () => {
    this.riskRegionService.getAllRisk().subscribe({
      next: (response) => {
        this.products = response.data.map((product) => {
          return { value: product.name, key: product.name, id: product.id };
        });
      },
    });
  };

  handleFormChange(form) {
    if (this.typeBusinessInsured) {
      this.form.controls['addCompanyForm']
        .get('brokerageinfo')
        .setValue(form?.value);

      // BOXXINS-3790 hardcoding the default value of product to be CRC(id: 2) if the brokerage is CRC(id: )
      if (
        !this.disableProduct &&
        !!form?.value &&
        this.isRestrictedRoleSubmission &&
        form?.value?.toUpperCase().includes('CRC')
      ) {
        const crcProductId = 2;
        this.form.controls['addCompanyForm']
          .get('productId')
          .setValue(crcProductId);
        const productName = this.products.filter(
          (product) => product?.id == crcProductId,
        )[0]?.value;
        this.form.controls['addCompanyForm']
          .get('productName')
          .setValue(productName);
      } else if (
        !this.disableProduct &&
        !!form?.value &&
        this.isRestrictedRoleSubmission &&
        !form?.value?.toUpperCase().includes('CRC')
      ) {
        const cyberboxxProductId = 1;
        this.form.controls['addCompanyForm']
          .get('productId')
          .setValue(cyberboxxProductId);
        const productName = this.products.filter(
          (product) => product?.id == cyberboxxProductId,
        )[0]?.value;
        this.form.controls['addCompanyForm']
          .get('productName')
          .setValue(productName);
      }
    } else {
      this.form.controls['addIndividualForm']
        .get('brokerageinfo')
        .setValue(form?.value);
    }
  }

  handleSeeExistingPolicy() {
    return new Promise<any>(async (resolve, reject) => {
      this.showBodySpinner = true;
      this.policyDashboardService
        .GetAllPoliciesByInsuredId(this.insuredId, PolicyStatus.BOUND)
        .subscribe({
          next: (response) => {
            if (response.data.length > 0) {
              let riskIdResp =
                response.data[0].policyRiskTrxes.policyRisk[0].riskId;
              if (this.isRestrictedRoleSubmission === true) {
                let formValue;
                if (this.typeBusinessInsured === true) {
                  formValue = this.form.get('addCompanyForm').value;
                } else {
                  formValue = this.form.controls['addIndividualForm'].value;
                }
                this.riskId =
                  formValue && formValue.productId
                    ? formValue.productId
                    : this.productId ?? 0;
              }
              if (this.riskId !== 0 && riskIdResp === this.riskId) {
                const alertData = {
                  type: 'error',
                  headerText: getAlertHead('error'),
                  bodyText:
                    'workFlow3.components.newSubmission.error.existingPolicy',
                };
                this.alertService.addAlert(alertData);
                this.hasActivePolicy = true;
                this.showBodySpinner = false;
              }
              resolve(true);
            } else {
              resolve(true);
              this.hasActivePolicy = false;
              this.showBodySpinner = false;
            }
          },
          error: (error) => {
            this.showBodySpinner = false;
            reject(getErrorMessage(error));
          },
        });
    });
  }
  async selectInsured(insuredId) {
    if (insuredId)
      this.typeBusinessInsured === true
        ? this.populateInsuredDetails(insuredId)
        : this.populateInsuredDetailsIndividual(insuredId);
  }
  clearInsuredForm() {
    this.isExistingInsuredFormEdit = false;
    this.form.controls?.['addCompanyForm']?.reset();
    this.form.controls?.['addIndividualForm']?.reset();
    this.form.controls?.['contactForm']?.reset();
    this.enableProducerInfo = false;
  }
  async createDuplicateQuote() {
    return new Promise<any>(async (resolve, reject) => {
      this.form$ = this.store.select(getQuoteSelector);
      let producerId, branchId, brokerageId;
      this.form$.subscribe((event) => {
        ({ producerId } = event.ui);
        producerId = event.ui.producerId;
        branchId = event.ui.branchId;
        brokerageId = event.ui.brokerageId;
      });
      let data = {
        quoteId: this.policyPeriodId,
        branchId: branchId,
        brokerageId: brokerageId,
        producerId: producerId,
        isSubmission: 1,
      };
      this.policyDuplicateQuoteService.CreateQuote(data).subscribe({
        next: (response) => {
          resolve(response);
        },
        error: (error) => {
          reject(getErrorMessage(error));
        },
      });
    });
  }

  // for dynamic contact information form
  addContact(): void {
    const contactsArray = this.contactForm.get('contacts') as FormArray;
    contactsArray.push(this.createContact());
    this.checkIfAtLeastOneFieldFilled();
  }

  createContact(initialValues?: any): FormGroup {
    return this.fb.group({
      firstName: [
        initialValues ? initialValues.firstName : '',
        [Validators.pattern(/^[a-zA-Z\s]*$/)],
      ],
      lastName: [
        initialValues ? initialValues.lastName : '',
        [Validators.pattern(/^[a-zA-Z\s]*$/)],
      ],
      email: [
        initialValues ? initialValues.email : '',
        [
          Validators.pattern(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          ),
        ],
      ],
      title: [
        initialValues ? initialValues.title : '',
        [Validators.pattern(/^[a-zA-Z\s]*$/)],
      ],
      phone: [
        initialValues ? initialValues.phone : '',
        [Validators.pattern(/^.{10,}$/)],
      ],
      id: [initialValues && initialValues.id ? initialValues.id : ''],
    });
  }

  removeContact(index: number): void {
    const contactsArray = this.contactForm.get('contacts') as FormArray;
    contactsArray.removeAt(index);
    this.checkIfAtLeastOneFieldFilled();
  }

  checkIfAtLeastOneFieldFilled(): void {
    const contactsArray = this.contactForm.get('contacts') as FormArray;
    const isAtLeastOneFormEmpty = contactsArray.value.some((contactData) =>
      Object.values(contactData).every((value) => !!!value),
    );
    this.isAtLeastOneFieldFilled = !isAtLeastOneFormEmpty;
  }

  removeEmptyContactForms() {
    const contactsArray = this.contactForm.get('contacts') as FormArray;
    for (let i = contactsArray.length - 1; i >= 0; i--) {
      const contactFormGroup = contactsArray.at(i) as FormGroup;
      const isAllEmpty = Object.values(contactFormGroup.value).every(
        (value) => value === '',
      );
      if (isAllEmpty) {
        contactsArray.removeAt(i);
      } else {
        // Remove empty fields from the form group
        Object.keys(contactFormGroup.controls).forEach((key) => {
          if (contactFormGroup.get(key)?.value === '') {
            contactFormGroup.removeControl(key);
          }
        });
      }
    }
    return contactsArray.value;
  }

  async compareAndUpdateInsuredContact(
    initial: insuredContact[],
    current: insuredContact[],
    insuredId,
    newContact: any[],
  ) {
    if (newContact.length === 0) {
      for (const currentPayload of current) {
        if (initial.length !== 0) {
          const initialPayload = initial.find(
            (p) => p.id === currentPayload.id,
          );
          if (initialPayload) {
            const propertiesToCompare: (keyof insuredContact)[] = [
              'firstName',
              'lastName',
              'email',
              'phone',
              'title',
            ];
            const hasChanges = propertiesToCompare.some(
              (prop) => initialPayload[prop] !== currentPayload[prop],
            );

            if (hasChanges) {
              const { id, ...payloadWithoutId } = currentPayload;
              const isPayloadWithoutIdEmpty = Object.values(
                payloadWithoutId,
              ).every((value) => !!!value);
              if (isPayloadWithoutIdEmpty) {
                const deleteContact = this.billingCompanyContactService.Delete(
                  currentPayload?.id,
                );
                const deleteResponse = await firstValueFrom(deleteContact);
              } else {
                this.billingCompanyContactService
                  .Update(currentPayload.id, currentPayload)
                  .subscribe((res: any) => {
                    this.billingCompanyContactService
                      .GetByInsuredId(insuredId)
                      .subscribe((res) => {
                        const contactsFormArray = this.contactForm.get(
                          'contacts',
                        ) as FormArray;
                        contactsFormArray.clear();
                        res?.data.forEach((contact) => {
                          if (!this.areAllContactFieldsEmpty(contact, true)) {
                            contactsFormArray.push(this.createContact(contact));
                          }
                        });
                        this.initialContactFormDataObject =
                          this.form.get('contactForm').value;
                      });
                  });
              }
            }
          }
        }
      }
    } else {
      this.removeEmptyFieldsFromArray(newContact);
      const currentPayload = { contact: newContact };
      this.createInsuredContact(currentPayload, insuredId);
    }
  }

  createInsuredContact(billingContactPayload, insuredId) {
    billingContactPayload = {
      ...billingContactPayload,
      insuredId: insuredId,
    };
    this.billingCompanyContactService
      .Create(billingContactPayload)
      .subscribe((res: any) => {
        this.billingCompanyContactService
          .GetByInsuredId(insuredId)
          .subscribe((res) => {
            const contactsFormArray = this.contactForm.get(
              'contacts',
            ) as FormArray;
            contactsFormArray.clear();
            res?.data.forEach((contact) => {
              if (!this.areAllContactFieldsEmpty(contact, true)) {
                contactsFormArray.push(this.createContact(contact));
              }
            });
            this.initialContactFormDataObject =
              this.form.get('contactForm').value;
          });
      });
  }

  compareObjectsWithContacts(obj1: any, obj2: any): boolean {
    // Check if both objects have the same number of contacts
    if (obj1.contacts.length !== obj2.contacts.length) {
      return false;
    }

    // Sort the contact arrays based on id property to ensure consistent comparison
    const sortedContacts1 = obj1.contacts.slice().sort((a, b) => a.id - b.id);
    const sortedContacts2 = obj2.contacts.slice().sort((a, b) => a.id - b.id);

    // Compare each contact in the arrays
    for (let i = 0; i < sortedContacts1.length; i++) {
      const contact1 = sortedContacts1[i];
      const contact2 = sortedContacts2[i];

      // Compare properties of each contact
      if (
        (contact1.firstName || '') !== (contact2.firstName || '') ||
        (contact1.lastName || '') !== (contact2.lastName || '') ||
        (contact1.email || '') !== (contact2.email || '') ||
        (contact1.title || '') !== (contact2.title || '') ||
        (contact1.phone || '') !== (contact2.phone || '') ||
        (contact1.id || '') !== (contact2.id || '')
      ) {
        return false;
      }
    }

    // If all contacts are the same, return true
    return true;
  }

  areAllContactFieldsEmpty(obj: any, skipId: boolean = false): boolean {
    const checkValue = (value: any) => {
      return value === null || value?.trim() === '';
    };

    if (skipId) {
      return (
        checkValue(obj.firstName) &&
        checkValue(obj.lastName) &&
        checkValue(obj.email) &&
        checkValue(obj.title) &&
        checkValue(obj.phone)
      );
    } else {
      return (
        checkValue(obj.firstName) &&
        checkValue(obj.lastName) &&
        checkValue(obj.email) &&
        checkValue(obj.title) &&
        checkValue(obj.phone) &&
        checkValue(obj.id.toString())
      );
    }
  }

  findNewContacts(obj1: any, obj2: any): any[] | false {
    const contacts1 = obj1.contacts;
    const contacts2 = obj2.contacts;

    const newContacts: any[] = contacts2.filter((contact2) => {
      return (
        !contacts1.some(
          (contact1) =>
            contact1.firstName === contact2.firstName &&
            contact1.lastName === contact2.lastName &&
            contact1.email === contact2.email &&
            contact1.title === contact2.title &&
            contact1.phone === contact2.phone &&
            contact1.id === contact2.id,
        ) &&
        ((contacts1.length === 1 &&
          this.areAllContactFieldsEmpty(contacts1[0]) &&
          !this.areAllContactFieldsEmpty(contact2)) ||
          (!this.areAllContactFieldsEmpty(contact2) &&
            !this.isIdInContacts(contact2.id, contacts1)))
      );
    });

    return newContacts.length > 0 ? newContacts : false;
  }

  isIdInContacts(id: string, contacts: any): boolean {
    return !isNaN(Number(id)) && contacts.some((contact) => contact.id === id);
  }

  removeEmptyFieldsFromArray(array: any[]): void {
    array.forEach((obj) => {
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (typeof value === 'string' && value?.trim() === '') {
          delete obj[key];
        }
      });
    });
  }
  closeModalSubmissionSubmitted() {
    this.handleCreateNewInsured();
    this.showSubmissionSubmittedModal = false;
  }
  handleStartSubmission(action: any) {
    this.handleCreateNewInsured();
    this.showSubmissionSubmittedModal = false;
  }
  handleGotoDashboard(action: any) {
    this.showSubmissionSubmittedModal = false;
    this.handleExitFlow();
  }
  getInsuredTimeZone() {
    return new Promise<any>(async (resolve, reject) => {
      await this.insuredService.GetByInsuredId(this.insuredId).subscribe({
        next: async (data) => {
          this.insuredTimezone =
            data.data.insuredLocation.timeZoneCode ?? 'America/New_York';
          this.store.dispatch(
            new CreateQuoteAction.UpdateInsuredAction({
              timezone:
                data.data?.insuredLocation.timeZoneCode ?? 'America/New_York',
            }),
          );
          resolve(true);
        },
        error: (error) => {
          reject(true);
        },
      });
    });
  }
  getTomorrow() {
    let currentDate = new Date();
    return new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
  }
  async createQuote(formValue) {
    return new Promise((resolve, reject) => {
      if (!formValue.productId) {
        formValue.productId = this.productId;
        formValue.productName = this.productName;
      }
      const risk = [{ id: formValue.productId }];
      let producerId, branchId, brokerageId;
      this.form$.subscribe((event) => {
        ({ producerId } = event.ui);
        producerId = event?.ui?.producerId;
        branchId = event?.ui?.branchId;
        brokerageId = event?.ui?.brokerageId;
      });
      let currentDate = getCityTime(this.insuredTimezone);
      let defaultStartDate = currentDate.currentDate
        ? getFormatDate(
            currentDate.currentDate,
            'DD/MM/YYYY',
            this.shortDateFormat,
          )
        : this.getTomorrow();
      let defaultEndDate = currentDate.oneYearLater
        ? getFormatDate(
            currentDate.oneYearLater,
            'DD/MM/YYYY',
            this.shortDateFormat,
          )
        : calculateDateAfterMonths(new Date(), 18, this.shortDateFormat);
      const data = {
        policy: {
          brokerageBORId: brokerageId,
          brokerageBranchBORId: branchId,
          brokerageProducerBORId: producerId,
          insuredId: this.insuredId,
        },

        policyPeriod: {
          effectiveDt: getFormatDate(
            defaultStartDate.toString(),
            this.shortDateFormat,
            'YYYY-MM-DD',
          ),
          expiryDt: getFormatDate(
            defaultEndDate.toString(),
            this.shortDateFormat,
            'YYYY-MM-DD',
          ),
          effectiveExpiryDatesTBDFlag: 1, // true
        },

        risk: risk,
        quoteOption: false,
      };
      this.policyAtomicTrxService.CreateQuote(data).subscribe({
        next: (data) => {
          const policyId = data.data.id;
          this.actionPopupDetails = {
            ...this.actionPopupDetails,
            quoteNumber: policyId,
            industryClass: formValue.industryClass,
            revenue: Number(formValue.revenue),
          };
          resolve(true);
          this.setLoader(false, 'unset');
          this.showSpinner = false;
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            const errAlert = {
              type: 'error',
              headerText: 'common.errorHeader',
              bodyText: getErrorMessage(error),
            };
            if (this.alertService.addAlert(errAlert)) this.showSpinner = false;
            this.setLoader(false, 'unset');
          } else {
            this.setLoader(false, 'unset');
            this.showSpinner = false;
          }
          reject(error);
        },
      });
    });
  }
  handleGlobalSearch(object) {
    if (object.searchIn === 'Insured name') {
      let insuredType = 1;
      if (object?.type?.toLowerCase() == 'company') {
        insuredType = 2;
      }
      this.router.navigateByUrl(
        `dashboard/search/${insuredType}/${object.selectedSearch.value}/${object.selectedSearch.id}`,
        { skipLocationChange: true },
      );
    } else {
      if (this.isRestrictedRoleSubmission === true) {
        this.alertService.clearAlerts(-1);
        const alertData = {
          type: 'warn',
          headerText: getAlertHead('warning'),
          bodyText: this.translateService.instant(
            'common.error.noAccessToPageErrorMsg',
          ),
        };
        this.alertService.addAlert(alertData);
        return;
      }
      this.router.navigateByUrl(
        `dashboard/workflow3/policies/${object.selectedSearch.value}`,
        { skipLocationChange: true },
      );
    }
  }
}
