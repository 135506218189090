<div class="custom-modal">
  <button class="close-btn" aria-label="close" (click)="handleCloseClk()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      class="bi bi-arrow-left"
      viewBox="0 0 16 16"
    >
      <path
        fill-rule="evenodd"
        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
      />
    </svg>
  </button>
  <div class="modal-content">
    <ngx-extended-pdf-viewer
      [base64Src]="docUrl"
      [height]="'90vh'"
      [useBrowserLocale]="false"
      [showHandToolButton]="false"
      [showPresentationModeButton]="false"
      [showToolbar]="true"
      [ignoreKeyboard]="false"
      [enableDragAndDrop]="false"
      [disableForms]="true"
      [showDrawEditor]="false"
      [showOpenFileButton]="false"
      [showSecondaryToolbarButton]="false"
      [showTextEditor]="false"
      [showStampEditor]="false"
      zoom="100%"
    ></ngx-extended-pdf-viewer>
  </div>
</div>
