import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, retry, catchError } from 'rxjs';
import { BoxxResponse } from '../entities/boxx-response';
import { BaseService } from './base-services';
import { UpdatePolicyLifeCycle } from '../entities/policy';
import { PolicyTRIAUpdate } from '../entities/policy';
import { Policy } from '../entities/policy';

@Injectable({
  providedIn: 'root',
})
export class PolicyRiskTrxService extends BaseService<any> {
  exportUrl = environment.msPolicyBaseUrl + environment.policyRiskTrxExport;
  constructor(http: HttpClient) {
    const baseurl = environment.msPolicyBaseUrl + environment.policyRiskTrxUrl;
    super(http, baseurl);
  }

  getByPolicyRiskTrxId(policyRiskTrxId: number): Observable<any> {
    const url = `${this.baseurl}${policyRiskTrxId}`;

    return this.http
      .get<BoxxResponse<any>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  UpdateFollowUpByPolicyRiskTrxId(
    userId: number,
    policyRiskTrxId: number,
    followUp: { followUp: number },
  ): Observable<any> {
    const url = `${this.baseurl}${environment.policyRiskTrxFollowUpUrl}${policyRiskTrxId}`;

    return this.http
      .put(url, JSON.stringify(followUp), this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  updateByPolicyRiskTrxId(
    policyRiskTrxId: number,
    request: UpdatePolicyLifeCycle,
  ): Observable<any> {
    const url = `${this.baseurl}${policyRiskTrxId}`;

    return this.http
      .put<BoxxResponse<any>>(url, request, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  PolicyTRIAUpdate(
    id: number,
    request: PolicyTRIAUpdate,
  ): Observable<BoxxResponse<any>> {
    let url = `${this.baseurl}policyFeeTria/${id}`;
    return this.http.put<BoxxResponse<any>>(url, request, this.httpOptions);
  }

  GetBypolicyRiskIdFilter(
    filter,
    limit = 10,
    skipLatestTrx: boolean = false,
  ): Observable<BoxxResponse<Policy>> {
    var url = `${this.baseurl}?limit=${limit}&active=1`;

    if (filter.sort) {
      url += '&sort=' + filter.sort;
    }
    if (filter.page) {
      url += '&page=' + filter.page;
    }
    if (filter.policyRiskId) {
      url += '&policyRiskId=' + filter.policyRiskId;
    }
    if (filter?.trxDt) {
      url += '&trxDt=' + filter.trxDt;
    }
    if (filter?.createdDt) {
      url += '&createdDt=' + filter.createdDt;
    }
    if (filter?.premiumRange) {
      url += '&premiumRange=' + filter.premiumRange;
    }
    if (filter?.deductible) {
      url += '&deductible=' + filter.deductible;
    }
    if (filter?.limitRange) {
      url += '&limitAmount=' + filter.limitRange;
    }
    if (filter?.invoiceNumber) {
      url += '&invoiceNumber=' + filter.invoiceNumber;
    }
    if (filter?.type) {
      url += '&type=' + filter.type;
    }
    if (skipLatestTrx) {
      url += '&skipLatestTrx=true';
    }
    return this.http
      .get<BoxxResponse<any>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  ExportPolicyRiskTrxDetails(filter, limit = 10): Observable<ArrayBuffer> {
    var url = `${this.exportUrl}?limit=${limit}&active=1`;

    if (filter.page) {
      url += '&page=' + filter.page;
    }
    if (filter.policyRiskId) {
      url += '&policyRiskId=' + filter.policyRiskId;
    }
    if (filter?.trxDt) {
      url += '&trxDt=' + filter.trxDt;
    }
    if (filter?.createdDt) {
      url += '&createdDt=' + filter.createdDt;
    }
    if (filter?.premiumRange) {
      url += '&premiumRange=' + filter.premiumRange;
    }
    if (filter?.deductible) {
      url += '&deductible=' + filter.deductible;
    }
    if (filter?.limitRange) {
      url += '&limitAmount=' + filter.limitRange;
    }
    if (filter?.invoiceNumber) {
      url += '&invoiceNumber=' + filter.invoiceNumber;
    }
    if (filter?.type) {
      url += '&type=' + filter.type;
    }
    if (filter?.sort) {
      url += '&sort=' + filter.sort;
    }

    return this.http
      .get(url, { responseType: 'arraybuffer' })
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
