import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { PolicyDashboardService } from 'src/app/services/policy-dashboard.service';
import { Pagination } from 'src/app/entities/boxx-response';
import {
  convertDate,
  getDateString,
  getFormattedDateTime,
  getFormattedPolicyPeriod,
} from 'src/app/utils/formatDate';
import {
  QuoteStatus,
  isStatusDisabled,
  mapQuote,
} from 'src/app/constants/quoteStatus';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import {
  makeSorting,
  formatAmountWithCurrency,
  excelFileDownload,
  getAlertHead,
  getErrorMessage,
  getStatusMappingForStyling,
} from 'src/app/utils/utils';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { PolicyPeriodService } from 'src/app/services/policy-period.service';
import { take } from 'rxjs';
import { LocationService } from 'src/app/services/location.service';
import { RiskQuestionService } from 'src/app/services/risk-question.service';
import { PolicyRiskTrxService } from 'src/app/services/policy-risk-trx.service';
import { PolicyDashboard } from 'src/app/entities/policy-dashboard';
import { getDashboardPath } from 'src/app/store/create-quote/create-quote.selector';
import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import {
  setQuoteStatus,
  setTransactionLvlQuoteStatus,
} from 'src/app/store/lifecycle/lifecycle.action';
import { AlertService } from 'src/app//services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { NewQuoteService } from 'src/app/services/new-quote.service';

@Component({
  selector: 'app-quotes-grid',
  templateUrl: './quotes-grid.component.html',
  styleUrls: ['./quotes-grid.component.less'],
})
export class QuotesGridComponent implements OnInit {
  statusMappingToType = getStatusMappingForStyling();

  subNavData;
  tableData = [];
  tableHeaders = [
    {
      'Quotes ID': '',
      'Effective dates': '',
      'Insured name': '',
      Product: '',
      Limit: '',
      Premium: '',
      'Date created': '',
      Status: '',
    },
  ];
  policyPeriodIds = [];
  pdtOptions = [];
  hasError: boolean = false;
  errorMessage: string = '';
  hasSuccess: boolean = false;
  successMessage: string = '';
  showTblSpinner: boolean = false;
  showBodySpinner: boolean = false;

  colQuoteHeader = {
    'Quotes ID': [{ key: 'policy.id', order: '' }],
    Status: [{ key: 'quoteStatus', order: '' }],
    'Effective dates': [{ key: 'effectiveDt', order: '' }],
    'Insured name': [
      { key: 'policy.insured.companyName', order: '' },
      { key: 'policy.insured.firstName', order: '' },
      { key: 'policy.insured.lastName', order: '' },
    ],
  };
  colQuoteHeaderIndividual = {
    'Quotes ID': [{ key: 'policy.id', order: '' }],
    Status: [{ key: 'quoteStatus', order: '' }],
    'Effective dates': [{ key: 'effectiveDt', order: '' }],
    'Insured name': [
      { key: 'policy.insured.companyName', order: '' },
      { key: 'policy.insured.firstName', order: '' },
      { key: 'policy.insured.lastName', order: '' },
    ],
  };

  statusBtnActions = [
    { key: 'Trigger refferal', value: 'Trigger refferal' },
    { key: 'Duplicate quote', value: 'Duplicate quote' },
  ];
  showFilter = false;
  isInsuredTypeCompany = true;
  totalDataCount = 0;
  pagination: Pagination;
  products;
  sortingKeys = 'followUp:desc, policyId:desc';
  filterForm: FormGroup;
  addSortTo: string[] = [
    'Quotes ID',
    'Effective dates',
    'Status',
    'Insured name',
  ];
  sortBy: string = '';
  pageNumber: number = 1;
  userID: number;
  underwriterNameNav: string = '';
  insuredData = {};

  quotesList: PolicyDashboard[];

  notificationAlert: {
    show?: boolean;
    bodyText?: string;
    type?: string;
    headerText?: string;
  } = { show: false };
  currency = '';
  insuredTimezone;
  quoteFlowSteps;
  quoteFlowUrl = '';

  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  shortDateFormat: string = '';
  longDateFormat: string = '';
  isRestrictedRoleSubmission: boolean = false;
  startQuoteBtnTxt: string = '';

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private policyDashboardService: PolicyDashboardService,
    private riskRegionService: RiskRegionService,
    private policyPeriodService: PolicyPeriodService,
    public locationService: LocationService,
    public riskQuestionService: RiskQuestionService,
    private store: Store,
    private localStorageService: LocalStorageService,
    private policyRiskTrxService: PolicyRiskTrxService,
    private renderer: Renderer2,
    private alertService: AlertService,
    private translateService: TranslateService,
    private newQuoteService: NewQuoteService,
  ) {
    this.filterForm = this.fb.group({
      quoteFrom: [''],
      quoteTo: [''],
      quotedPeriod: [''],
      brokerageBranchProd: [[]],
      underwriter: [[]],
      status: [[]],
      products: [[]],
    });
  }

  ngOnInit(): void {
    localStorage.removeItem('timezone');
    this.localStorageService.removeTimeZone();
    this.startQuoteBtnTxt = this.translateService.instant(
      'common.startNewQuote',
    );
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
      this.isRestrictedRoleSubmission = data.isRestrictedRoleSubmission;
    });
    this.store.dispatch(new setQuoteStatus(''));
    this.store.dispatch(new setTransactionLvlQuoteStatus(''));
    this.quoteFlowSteps = this.localStorageService.getQuoteFlowSteps();
    if (this.quoteFlowSteps === '7') {
      this.quoteFlowUrl = 'quote';
    } else if (this.quoteFlowSteps === '4') {
      this.quoteFlowUrl = 'quote4flow';
    } else {
      this.quoteFlowUrl = 'workflow3';
    }

    this.subNavData = [
      { name: 'All', active: true, value: 'All' },
      { name: 'Open', active: false, value: 'Submission' },
      { name: 'Declined', active: false, value: 'Declined' },
      { name: 'Closed', active: false, value: 'Closed' },
    ];
    this.userID = this.localStorageService.getBoxxUserId();
    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));
    const sideMenuNavigation = this.newQuoteService
      ?.getSideMenu()
      ?.toLowerCase();
    if (sideMenuNavigation !== '') {
      this.newQuoteService.clearSideMenu();
      switch (sideMenuNavigation) {
        case 'open':
          this.handleNav(1);
          break;
        case 'declined':
          this.handleNav(2);
          break;
        case 'closed':
          this.handleNav(3);
          break;
        default:
          break;
      }
    }
    this.store
      .select(getDashboardPath)
      .pipe(take(1))
      .subscribe((item) => {
        if (item?.pathOptions?.path) {
          if (item.pathOptions?.path == 'open quotes') {
            this.filterForm
              .get('underwriter')
              .setValue([
                { id: this.userID, name: item.pathOptions?.underwriter },
              ]);
            this.handleNav(1);
            this.store.dispatch(
              new CreateQuoteAction.setDashboardPath({
                path: '',
                underwriter: '',
              }),
            );
          } else if (item.pathOptions?.path == 'new referrals') {
            this.filterForm.get('status').setValue(['Referral']);
            this.filterForm
              .get('underwriter')
              .setValue([
                { id: this.userID, name: item.pathOptions?.underwriter },
              ]);
            this.loadResults(1, 10, this.sortingKeys);
            this.store.dispatch(
              new CreateQuoteAction.setDashboardPath({
                path: '',
                underwriter: '',
              }),
            );
          }
        } else {
          this.store.dispatch(new CreateQuoteAction.ResetState());
          this.loadResults(1, 10, this.sortingKeys);
        }
      });
    if (this.isRestrictedRoleSubmission === true) {
      this.startQuoteBtnTxt = this.translateService.instant(
        'common.startNewSubmission',
      );
    }
    this.riskRegionService.getAllRiskByUser(this.userID).subscribe((data) => {
      this.products = data.data.reduce((accumulator, value) => {
        return { ...accumulator, [value.name]: value };
      }, {});
      this.pdtOptions = data.data?.map((dataObj) => ({
        key: dataObj.name,
        value: dataObj.name,
        disabled: false,
        id: dataObj.id,
      }));
    });

    this.policyPeriodService.alertData$.pipe(take(1)).subscribe((alertData) => {
      if (alertData) {
        this.policyPeriodService.setAlertData('');
        this.alertService.addAlert(alertData);
      }
    });
  }

  getFilteredValues() {
    let filteredOptions = [];
    const quoteFrm = this.filterForm.value['quoteFrom'];
    const quoteTo = this.filterForm.value['quoteTo'];
    if (quoteFrm && quoteTo) {
      const quotePeriodVal = this.filterForm.value['quotedPeriod'];
      if (quotePeriodVal === 'custom') {
        filteredOptions.push(
          `${convertDate(quoteFrm, this.shortDateFormat)} - ${convertDate(
            quoteTo,
            this.shortDateFormat,
          )}`,
        );
      } else {
        let filterText = '';
        if (quotePeriodVal === 'quotedToday') {
          filterText = 'Today';
        } else if (quotePeriodVal === 'last7days') {
          filterText = 'Last 7 days';
        } else if (quotePeriodVal === 'last30days') {
          filterText = 'Last 30 days';
        }
        filteredOptions.push(filterText);
      }
    }

    const brokerVal = this.filterForm.value['brokerageBranchProd'];
    if (brokerVal?.length > 0) {
      brokerVal.forEach((b) => {
        filteredOptions.push(b.name);
      });
    }
    const underWriter = this.filterForm.value['underwriter'];
    if (underWriter?.length > 0) {
      underWriter.forEach((uw) => {
        filteredOptions.push(uw.name);
      });
    }
    filteredOptions = [
      ...filteredOptions,
      ...this.filterForm.value['products'],
      ...this.filterForm.value['status'],
    ];
    return filteredOptions;
  }

  handleFilterOptnRemove(option) {
    if (
      option === 'Today' ||
      option === 'Last 7 days' ||
      option === 'Last 30 days' ||
      option ===
        `${convertDate(
          this.filterForm.value['quoteFrom'],
          this.shortDateFormat,
        )} - ${convertDate(
          this.filterForm.value['quoteTo'],
          this.shortDateFormat,
        )}`
    ) {
      this.filterForm.controls['quotedPeriod'].setValue('');
      this.filterForm.controls['quoteFrom'].setValue('');
      this.filterForm.controls['quoteTo'].setValue('');
    } else if (
      this.filterForm.value['brokerageBranchProd'].findIndex(
        (b) => b.name == option,
      ) !== -1
    ) {
      let updatedBrokVal = this.filterForm.value['brokerageBranchProd'].filter(
        (b) => b.name !== option,
      );
      this.filterForm.controls['brokerageBranchProd'].setValue(updatedBrokVal);
    } else if (
      this.filterForm.value['underwriter'].findIndex(
        (uw) => uw.name == option,
      ) !== -1
    ) {
      let updatedUWVal = this.filterForm.value['underwriter'].filter(
        (b) => b.name !== option,
      );
      this.filterForm.controls['underwriter'].setValue(updatedUWVal);
    } else if (
      this.filterForm.value['products'].findIndex((p) => p == option) !== -1
    ) {
      var index = this.filterForm.value['products'].indexOf(option);
      if (index !== -1) {
        this.filterForm.value['products'].splice(index, 1);
      }
    } else if (
      this.filterForm.value['status'].findIndex((s) => s == option) !== -1
    ) {
      var index = this.filterForm.value['status'].indexOf(option);
      if (index !== -1) {
        this.filterForm.value['status'].splice(index, 1);
      }
    }

    this.handleFiltering(undefined);
  }

  handleStartNewQuote() {
    this.store.dispatch(new CreateQuoteAction.ResetState());
    if (this.quoteFlowSteps === 'workflow3') {
      this.newQuoteService.clearInsuredId();
      this.router.navigate(['../workflow3'], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
      return;
    } else {
      this.router.navigate([`../${this.quoteFlowUrl}/new`], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
    }
  }

  handleNav(index: number) {
    const currentActiveIdx = this.subNavData.findIndex(
      (navObj: { name: string; active: boolean; value: string }) =>
        navObj.active,
    );
    if (currentActiveIdx !== index) {
      let currentStatusFilters = this.filterForm.value['status'] ?? [];
      if (currentStatusFilters.includes(this.subNavData[index].value)) {
        this.filterForm.controls['status'].setValue([
          `${this.subNavData[index].value}`,
        ]);
      } else {
        this.filterForm.controls['status'].setValue([]);
      }
      this.subNavData[currentActiveIdx].active = false;
      this.subNavData[index].active = true;
      this.loadResults(1, 10, this.sortingKeys);
    }
  }

  loadResults(pageNumber, limit, sort = '') {
    this.showTblSpinner = true;
    this.tableData = this.tableHeaders;

    const currentActiveIdx = this.subNavData.findIndex(
      (navObj) => navObj.active,
    );
    let insuredType = this.getInsuredType();
    const quoteStatus = mapQuote(this.subNavData[currentActiveIdx].name);

    let filter = this.makeFilter();
    if (quoteStatus) {
      if (filter.quoteStatus) {
        filter.quoteStatus += ',' + quoteStatus;
      } else {
        filter = Object.assign(filter, { quoteStatus: quoteStatus });
      }
    }
    if (filter.trxDays) {
      if (filter.quoteStatus) {
        let temp = filter.quoteStatus
          .split(',')
          .findIndex((x) => x == mapQuote('Quoted'));
        if (temp == -1) {
          filter.quoteStatus += ',' + mapQuote('Quoted');
        }
      } else {
        filter = Object.assign(filter, { quoteStatus: mapQuote('Quoted') });
      }
    }
    this.policyPeriodIds = [];
    this.pageNumber = pageNumber;
    const args = {
      insuredType,
      filter,
      page: pageNumber,
      limit,
      sort,
      entryType: quoteStatus,
    };
    this.policyDashboardService.GetAllQuoteByFilter(args).subscribe(
      (data) => {
        this.showTblSpinner = false;
        this.quotesList = [...data.data];
        const tableData = data.data?.map((dataObj) => {
          this.insuredTimezone =
            dataObj.policy.insured.insuredLocation.timeZoneCode ??
            'America/New_York';
          this.policyPeriodIds.push(dataObj.policyPeriod.id);
          const filterdVal = this.formatPolicyRiskList(
            dataObj.policyRiskTrxes.policyRisk,
          );
          return {
            'Quotes ID': String(dataObj.policy.pkgPolicyNumber),
            'Effective dates': dataObj?.policyPeriod
              ?.effectiveExpiryDatesTBDFlag
              ? 'TBD'
              : getFormattedPolicyPeriod(
                  dataObj?.policyPeriod,
                  this.shortDateFormat,
                ),
            'Insured name': dataObj.policy.insured.companyName
              ? dataObj.policy.insured.companyName
              : dataObj.policy.insured.firstName +
                ' ' +
                dataObj.policy.insured.lastName,
            Products: filterdVal.products,
            Limit: formatAmountWithCurrency(filterdVal.limit, this.currency),
            Premium: formatAmountWithCurrency(
              filterdVal.premium,
              this.currency,
            ),
            'Date created': getFormattedDateTime(
              dataObj?.policy?.createdDt,
              this.shortDateFormat,
            ),
            Status:
              dataObj.policyRiskTrxes?.quoteStatus?.description.toLowerCase(),
            showStar: !!dataObj.policyRiskTrxes.followUp,
          };
        });

        this.tableData = tableData.length == 0 ? this.tableHeaders : tableData;
        this.totalDataCount = data.pagination.totalRecords;
        this.pagination = data.pagination;
        this.showTblSpinner = false;
      },
      (error) => {
        this.showTblSpinner = false;
      },
    );
  }

  pageChangeHandler(pageNumber) {
    if (pageNumber > 0) {
      this.loadResults(pageNumber, 10, this.sortingKeys);
    }
  }

  handleTableColSortUp(e) {
    let order = 'asc';
    if (this.isInsuredTypeCompany) {
      this.sortingKeys = makeSorting(e, this.colQuoteHeader, order);
      this.sortingKeys = `${this.sortingKeys},id:asc`;
    } else {
      this.sortingKeys = makeSorting(e, this.colQuoteHeaderIndividual, order);
      this.sortingKeys = `${this.sortingKeys},id:asc`;
    }
    this.loadResults(this.pageNumber, 10, this.sortingKeys);
  }

  handleTableColSortDown(e) {
    let order = 'desc';

    if (this.isInsuredTypeCompany) {
      this.sortingKeys = makeSorting(e, this.colQuoteHeader, order);
      this.sortingKeys = `${this.sortingKeys},id:asc`;
    } else {
      this.sortingKeys = makeSorting(e, this.colQuoteHeaderIndividual, order);
      this.sortingKeys = `${this.sortingKeys},id:asc`;
    }
    this.loadResults(this.pageNumber, 10, this.sortingKeys);
  }

  starClickHandler(index) {
    this.alertService.clearAlerts(-1);
    const policyRiskTrxes = { ...this.quotesList[index].policyRiskTrxes };
    const followUp = { followUp: policyRiskTrxes.followUp ? 0 : 1 };
    this.policyRiskTrxService
      .UpdateFollowUpByPolicyRiskTrxId(
        this.userID,
        policyRiskTrxes.id,
        followUp,
      )
      .subscribe({
        next: (_) => {
          this.tableData[index].showStar = !!!policyRiskTrxes.followUp;
          this.quotesList[index].policyRiskTrxes.followUp =
            policyRiskTrxes.followUp ? 0 : 1;
          const alertData = {
            show: true,
            type: 'success',
            headerText: 'success!',
            bodyText: this.translateService.instant('policy.FollowUpSuccess'),
          };
          this.alertService.addAlert(alertData);
          this.loadResults(1, 10, this.sortingKeys);
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            const alertData = {
              show: true,
              type: 'error',
              headerText: 'error!',
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
          }
        },
      });
  }
  ellipsisClickHandler(index) {}

  handleFilter() {
    this.showFilter = true;
    this.renderer.addClass(document.body, 'no-scroll');
  }

  makeFilter() {
    const brokerVal = this.filterForm.value['brokerageBranchProd'];
    const brokerIds = brokerVal
      .filter((item) => item.type === 'Brokerage')
      .map((currentValue) => currentValue.id)
      .join(',');
    const branchIds = brokerVal
      .filter((item) => item.type === 'Branch')
      .map((currentValue) => currentValue.id)
      .join(',');
    const producersIds = brokerVal
      .filter((item) => item.type === 'Producer')
      .map((currentValue) => currentValue.id)
      .join(',');
    const productsVal = this.filterForm.value['products'];
    const productIds = productsVal.map((p) => this.products[p].id).join(',');
    const underwritersVal = this.filterForm.value['underwriter'];
    const underwriterIds = underwritersVal
      .map((currentValue) => currentValue.id)
      .join(',');
    const statusVal = this.filterForm.value['status']
      .map((f) => mapQuote(f))
      .join(',');
    const quoteFrm = this.filterForm.value['quoteFrom'];
    const quoteTo = this.filterForm.value['quoteTo'];

    let range = '';
    if (quoteFrm && quoteTo) {
      const formattedFromDate = getDateString(quoteFrm, this.shortDateFormat);
      const formattedToDate = getDateString(quoteTo, this.shortDateFormat);
      range = `${formattedFromDate},${formattedToDate}`;
    }
    const quotePeriodVal = this.filterForm.value['quotedPeriod'];
    let queotePeriod = 0;
    if (quotePeriodVal == 'quotedToday') {
      queotePeriod = 1;
    } else if (quotePeriodVal == 'quotedlast7') {
      queotePeriod = 7;
    } else if (quotePeriodVal == 'quotedlast30') {
      queotePeriod = 30;
    }

    const filter = {
      quoteStatus: statusVal,
      trxDays: queotePeriod,
      underwriterId: underwriterIds,
      brokerageBORId: brokerIds,
      brokerageBranchBORId: branchIds,
      brokerageProducerBORId: producersIds,
      riskIds: productIds,
      periodDt: range,
    };
    return filter;
  }

  getInsuredType() {
    let insuredType = 1;
    if (this.isInsuredTypeCompany) {
      insuredType = 2;
    }
    return insuredType;
  }

  handleFiltering(e) {
    this.loadResults(1, 10, this.sortingKeys);
    this.showFilter = false;
    this.renderer.removeClass(document.body, 'no-scroll');
  }

  handleExport() {
    this.showTblSpinner = true;
    const currentActiveIdx = this.subNavData.findIndex(
      (navObj) => navObj.active,
    );
    let insuredType = this.getInsuredType();
    let filter = this.makeFilter();
    const quoteStatus = mapQuote(this.subNavData[currentActiveIdx].name);
    if (quoteStatus) {
      if (filter.quoteStatus) {
        filter.quoteStatus += ',' + quoteStatus;
      } else {
        filter = Object.assign(filter, { quoteStatus: quoteStatus });
      }
    }
    filter = Object.assign(filter, { entryType: 'quote' });
    filter = Object.assign(filter, { insuredType: insuredType });
    filter = Object.assign(filter, { limit: this.totalDataCount });
    filter = Object.assign(filter, { page: 1 });

    this.policyDashboardService
      .PolicyExport(filter, this.sortingKeys)
      .subscribe(
        (data: ArrayBuffer) => {
          excelFileDownload('ExportedData.xlsx', data);
          this.showTblSpinner = false;
        },
        (error) => {
          this.showTblSpinner = false;
          const alertData = {
            show: true,
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: getErrorMessage(error),
          };
          this.alertService.addAlert(alertData);
        },
      );
  }
  formatPolicyRiskList(policyRiskList: any): {
    products: string;
    limit: string;
    premium: string;
  } {
    if (!policyRiskList || policyRiskList.length === 0) {
      return null;
    }
    let formattedObj: { products: string; limit: string; premium: string } = {
      products: '',
      limit: '',
      premium: '',
    };
    formattedObj.products = policyRiskList.map((item) => item.risk).join(', ');
    formattedObj.limit = policyRiskList
      .map((item) =>
        item.limit
          ? formatAmountWithCurrency(item.limit, this.currency)
          : formatAmountWithCurrency('0', this.currency),
      )
      .join(', ');
    formattedObj.premium = policyRiskList
      .map((item) =>
        item.premium
          ? formatAmountWithCurrency(item.premium, this.currency)
          : formatAmountWithCurrency('0', this.currency),
      )
      .join(', ');
    return formattedObj;
  }
  onInsuredTypeToggle(isCompany) {
    if (isCompany == this.isInsuredTypeCompany) {
      return;
    }
    this.isInsuredTypeCompany = isCompany;
    this.loadResults(1, 10, this.sortingKeys);
  }

  async quoteClickHandler(index) {
    let quoteTimeZone =
      this.quotesList[index].policy.insured?.['insuredLocation']?.[
        'timeZoneCode'
      ] ?? 'America/New_York';
    localStorage.setItem('timezone', quoteTimeZone);

    this.showBodySpinner = true;
    this.store.dispatch(
      new CreateQuoteAction.UpdateInsuredAction(this.insuredData),
    );
    this.store.dispatch(
      new CreateQuoteAction.setPolicyPeriodId(this.policyPeriodIds[index]),
    );

    const quoteID = this.tableData[index]['Quotes ID'];
    this.router.navigate([`../${this.quoteFlowUrl}/${quoteID}`], {
      relativeTo: this.activatedRoute,
      skipLocationChange: true,
    });

    let brokerageCommission =
      this.quotesList[index].policyRiskTrxes?.['policyRisk'][0]
        ?.brokerCommissionPerc ?? 0;
    brokerageCommission = brokerageCommission * 100;

    let quoteStatusCase =
      this.quotesList[index]?.policyRiskTrxes?.quoteStatus?.description;
    const quoteStatus = this.tableData[index].Status;

    if (quoteStatus.toLowerCase() == QuoteStatus.Submission.toLowerCase()) {
      this.showBodySpinner = false;
      this.router.navigate(
        [`../${this.quoteFlowUrl}/new/product/form/${quoteID}`],
        {
          relativeTo: this.activatedRoute,
          skipLocationChange: true,
        },
      );
    } else if (isStatusDisabled(quoteStatusCase)) {
      //Checking whether the quote status included in disabled statuses, status should not  lowercase.
      this.showBodySpinner = false;
      this.router.navigate([`../${this.quoteFlowUrl}/${quoteID}`], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
    } else if (brokerageCommission === 0) {
      //Checking whether the commission is zero or not,
      this.showBodySpinner = false;
      this.router.navigate(
        [`../${this.quoteFlowUrl}/new/product/form/${quoteID}`],
        {
          relativeTo: this.activatedRoute,
          skipLocationChange: true,
        },
      );
    } else {
      this.showBodySpinner = false;
      this.router.navigate([`../${this.quoteFlowUrl}/${quoteID}`], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
    }
  }

  handleNotificationAlertClose() {
    this.notificationAlert.show = false;
  }
  handleCloseErrorEvent() {
    this.hasError = false;
    this.errorMessage = '';
  }
  handleCloseSuccessEvent() {
    this.hasSuccess = false;
    this.successMessage = '';
  }
}
