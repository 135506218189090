import { Pagination } from 'src/app/entities/boxx-response';

export interface PolicyActionButtonStatus {
  cancel: boolean;
  close: boolean;
  decline: boolean;
  bind: boolean;
  renew: boolean;
  reinstate: boolean;
  startNewPeriod: boolean;
}

export interface PolicyDetail {
  header: string;
  currentPeriod: string;
  id: string;
  policyRiskTrxId: number;
  policyRiskTrxIds: string;
  latestPolicyRiskTrxId: number;
  userId: number;
  status: string;
  brokerage: string;
  branch: string;
  producer: string;
  firstBindDate: string;
  policyPeriod: string;
  insuredName: string;
  isUnreadNotesPresent: boolean;
  isInvoiced: boolean;
  product: any[];
  currentTagOptions: any;
  policyPeriodId?: number;
  policyRiskId?: number;
  insuredId?: number;
  industry?: string;
  revenue?: string;
}

export interface PolicyActionPopupDetail {
  id: string;
  policyPeriodId: number;
  userId: number;
  period: string;
  insured: string;
  brokerage: string;
  branch: string;
  reasonOptions: any[];
  action: string;
}

export interface PolicyDataItem {
  risk: string;
  policyId: string;
  policyRiskTrxId: number;
  limit: number;
  premium: number;
  deductible: number;
  invoiceNumber: string;
  trnxDate: string;
  underwriter: string;
  annualPremium: number;
  status: string;
  reason: string;
  policyPeriod: string;
  policyChange: boolean;
  isPopulated: boolean;
  trxReasons?: any[];
  totalAnnualPremium?: number;
}

export interface PolicySubNavItem {
  name: string;
  policyRiskTrxId: number;
  active: boolean;
  currentValue: number;
  value: number;
}

export interface PolicyRiskItem {
  id: number;
  name: string;
  policyNumber: string;
  period: string;
  effectiveDt: Date;
}

export interface PolicyTimeLineItem {
  policyId: string;
  limit: number;
  premium: number;
  deductible: number;
  invoiceNumber: string;
  trnxDate: string;
  annualPremium: number;
  status: string;
  type: string;
  policyPeriod: string;
  isTileExpanded: boolean;
  policyRiskTrxId: number;
  trxReasons?: any[];
}
export interface PolicyRiskTrxItem {
  RiskTrxs: any[];
  Pagination: Pagination;
}

export function getStatusType(status) {
  switch (status.toLowerCase()) {
    case 'new submissions':
    case 'reinstated':
      return 'default';
    case 'bound':
    case 'referral':
      return 'primary';
    case 'quote':
    case 'quoted':
      return 'secondary';
    case 'renewed':
    case 'renewal quote':
    case 'renewal app':
      return 'warning';
    case 'quote closed':
    case 'cancelled':
    case 'lapsed':
    case 'declined':
    case 'closed':
    case 'not taken up':
      return 'inactive';
  }
  return 'default';
}

export function getStatusName(status, isInvoiced?: null | boolean) {
  switch (status.toLowerCase()) {
    case 'quote':
    case 'quoted':
      return 'Quoted';
    case 'new submissions':
    case 'submission':
      return 'Submission';
    case 'reinstated':
      return 'Reinstated';
    case 'bound':
    case 'bind':
      return 'Bound';
    case 'renewed':
    case 'renewal':
      return 'Renewed';
    case 'renewal quote':
      return 'Renewal quote';
    case 'renewal app':
      return 'Renewal app';
    case 'cancelled':
    case 'canceled':
    case 'cancellation':
      return 'Cancelled';
    case 'lapsed':
      return 'Lapsed';
    case 'declined':
      return 'Declined';
    case 'closed':
      return 'Closed';
    case 'quote closed':
      return 'Quote closed';
    case 'not taken up':
      return 'Not taken up';
    case 'expired':
      return 'Expired';
    case 'referred':
    case 'referral':
      return 'Referred';
    case 'policy changed':
    case 'policy change':
      if (isInvoiced !== null || isInvoiced !== undefined) {
        if (!isInvoiced) {
          return 'Change-Saved';
        }
      }
      return 'Policy changed';
    case 'endorsement':
    case 'endorsed':
      if (isInvoiced !== null || isInvoiced !== undefined) {
        if (!isInvoiced) {
          return 'Endrs-Saved';
        } else {
          return 'Endorsed';
        }
      }
      return 'Endorsed';
    case 'indicated':
      return 'Indicated';
    default:
      return '';
  }
  return 'default';
}
