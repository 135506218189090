import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { convertDate } from 'src/app/utils/formatDate';
@Component({
  selector: 'app-submission-recalculate-confirmation-popup',
  templateUrl: './modal-submissionRecalculateConf.component.html',
  styleUrls: ['./modal-submissionRecalculateConf.component.less'],
})
export class SubmissionRecalculateConfComponent implements OnInit {
  @Input() showModal: boolean = false;
  @Input() existingQuoteOptionData;

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleSubmissionRecalculate = new EventEmitter<any>();
  @Output() handleDiscardRecalculate = new EventEmitter<any>();

  shortDateFormat: string = '';
  longDateFormat: string = '';

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
  }

  closeHandler() {
    this.handleClose.emit();
  }
  actionRecalculatePremium(event: any) {
    this.handleSubmissionRecalculate.emit(true);
  }
  actionDiscardRecalculatePremium(event: any) {
    this.handleDiscardRecalculate.emit(true);
  }

  getDateFormated(date: any, dateFormat: string) {
    return date ? convertDate(date, dateFormat) : '';
  }
}
