<div class="holder">
  <boxxds-breadcrumb [items]="itemsMenu"></boxxds-breadcrumb>
  <boxxds-heading
    sz="5"
    [ngStyle]="{ marginBottom: '50px' }"
    [text]="fullName | translate"
    ><span></span>
  </boxxds-heading>
  <boxxds-alert
    *ngIf="showErrorAlert"
    (handleCloseEvent)="handleCloseSuccessEventExt()"
    type="error"
    headerText="error!"
    [bodyText]="msgErrorAlert"
  >
  </boxxds-alert>
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!showBodySpinner">
    <div class="company-container">
      <boxxds-alert
        *ngIf="alterDetails.isActive"
        (handleCloseEvent)="resetBanner()"
        [type]="alterDetails.type"
        [headerText]="alterDetails.headerText | translate"
        [bodyText]="alterDetails.bodyText | translate"
      >
      </boxxds-alert>

      <div class="create-permission-title-container">
        <boxxds-body
          sz="lg"
          [text]="'BOXX user details' | translate"
          [customStyle]="{
            color: '#616162',
            textAlign: 'left',
            marginBottom: '8px'
          }"
        ></boxxds-body>
        <span>*REQUIRED</span>
      </div>

      <div *ngIf="isEditMode">
        <form [formGroup]="editForm">
          <div class="search-container">
            <div class="add-container">
              <div class="d-flex">
                <boxxds-textinput
                  [isDisabled]="
                    !this.permissionList[this.currentScreen] || !active
                  "
                  [placeholder]="'placeholder.enterFirstName' | translate"
                  [form]="editForm"
                  [control]="'firstName'"
                  [id]="'firstName'"
                  [width]="'264px'"
                  [label]="'boxxUser.label.firstName' | translate"
                  [requiredErrMsg]="requiredErrMsg | translate"
                  [invalidErrMsg]="invalidErrMsg | translate"
                  [containerStyle]="{ marginRight: '40px' }"
                  [formSubmitted]="formSubmitted"
                  maxLength="45"
                >
                </boxxds-textinput>
                <boxxds-textinput
                  [isDisabled]="
                    !this.permissionList[this.currentScreen] || !active
                  "
                  [placeholder]="'placeholder.enterLastName' | translate"
                  [form]="editForm"
                  [control]="'lastName'"
                  [id]="'lastName'"
                  [width]="'264px'"
                  [label]="'boxxUser.label.lastName' | translate"
                  [requiredErrMsg]="requiredErrMsg | translate"
                  [invalidErrMsg]="invalidErrMsg | translate"
                  [formSubmitted]="formSubmitted"
                  maxLength="45"
                >
                </boxxds-textinput>
              </div>
              <div class="d-flex">
                <boxxds-textinput
                  [isDisabled]="
                    !this.permissionList[this.currentScreen] || !active
                  "
                  [placeholder]="'boxxUser.placeholder.enterTitle' | translate"
                  [form]="editForm"
                  [control]="'title'"
                  [id]="'title'"
                  [label]="('boxxUser.label.title' | translate) + '*'"
                  [requiredErrMsg]="requiredErrMsg | translate"
                  [invalidErrMsg]="invalidErrMsg | translate"
                  [containerStyle]="{ width: '264px', marginRight: '40px' }"
                  [formSubmitted]="formSubmitted"
                  maxLength="150"
                >
                </boxxds-textinput>
                <boxxds-searchable-dropdown
                  [isDisabled]="
                    !this.permissionList[this.currentScreen] || !active
                  "
                  [form]="editForm"
                  [control]="'manager'"
                  [placeholder]="'placeholder.searchManager' | translate"
                  [options]="managers"
                  [invalidErrMsg]="
                    formSubmitted ? (invalidManager | translate) : ''
                  "
                  [label]="'boxxUser.label.manager' | translate"
                  [btnStyle]="{ width: '264px' }"
                  [dropdownContainerStyle]="{ width: '264px' }"
                  class="employees"
                  [currentValue]="manager"
                  [requiredErrMsg]="requiredErrMsg | translate"
                  [formSubmitted]="formSubmitted"
                  (currentValueChange)="
                    this.permissionList[this.currentScreen] &&
                      managerChanged($event)
                  "
                  (textInputChangeEventEmmiter)="
                    this.permissionList[this.currentScreen] &&
                      searchManagers($event)
                  "
                  [dispatchTextInputChange]="true"
                >
                </boxxds-searchable-dropdown>
              </div>
            </div>
            <div
              class="btn-container"
              *ngIf="this.permissionList[this.currentScreen]"
            >
              <ng-container *ngIf="checkUser()">
                <a
                  *ngIf="active === true"
                  class="reset-link"
                  (click)="deActivateSystemUser()"
                  [ngStyle]="{ 'margin-right': '32px', 'margin-top': '4px' }"
                  href="javascript:void(0)"
                  [ngClass]="processingActivation ? 'disabled' : ''"
                  >{{
                    ("common.deactivate" | translate) +
                      " " +
                      ("common.user" | translate)
                  }}</a
                >
                <a
                  *ngIf="active === false"
                  class="reset-link"
                  (click)="activateSystemUser()"
                  [ngStyle]="{ 'margin-right': '32px', 'margin-top': '4px' }"
                  href="javascript:void(0)"
                  [ngClass]="processingActivation ? 'disabled' : ''"
                  >{{
                    ("common.reactivate" | translate) +
                      " " +
                      ("common.user" | translate)
                  }}</a
                >
              </ng-container>
              <boxxds-button
                *ngIf="!showSpinner"
                [isDisabled]="!active"
                [buttonText]="'Save changes' | translate"
                btnType="primary-outlined"
                [sz]="'sm'"
                [btnStyle]="{ padding: '0 30px 0 30px' }"
                type="submit"
                (handleClick)="onSubmitEdit(editForm.value)"
              >
              </boxxds-button>
              <button
                *ngIf="showSpinner"
                class="btn-processing"
                tabindex="0"
                [ngStyle]="{
                  border: '1px solid #FFBE3D',
                  borderRadius: '8px',
                  width: '150px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '4px 8px',
                  background: '#FCF8EF'
                }"
              >
                <boxxds-spinner></boxxds-spinner>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="timestamp">
      <app-timestamp
        *ngIf="user"
        label="Created by"
        value="{{ user.createdBy }}, {{ user.createdDt }}"
        [containerStyle]="{
          marginTop: '8px',
          marginLeft: '8px',
          position: 'relative',
          top: '-6px',
          textTransform: 'uppercase'
        }"
      ></app-timestamp>
      <app-timestamp
        *ngIf="user && user.updatedBy && user.updatedDt"
        label="Updated last by"
        value="{{ user.updatedBy }}, {{ user.updatedDt }}"
        [containerStyle]="{
          marginLeft: '8px',
          position: 'relative',
          top: '-20px',
          textTransform: 'uppercase'
        }"
      ></app-timestamp>
    </div>
  </ng-container>
</div>
