<div class="holder">
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!showBodySpinner">
    <div class="alert-holder">
      <boxxds-alert
        *ngIf="isSuccess"
        type="success"
        headerText="success!"
        [bodyText]="successMessage"
        (handleCloseEvent)="handleCloseSuccessEvent()"
        [containerStyle]="{
          width: 'calc(100vw - 320px)',
          maxWidth: '1115px',
          marginBottom: '2'
        }"
        [bodyStyle]="{ textDecoration: 'underline' }"
      >
      </boxxds-alert>
      <boxxds-alert
        *ngIf="hasError"
        type="error"
        headerText="error!"
        [bodyText]="errorMessage"
        (handleCloseEvent)="handleCloseErrorEvent()"
        [containerStyle]="{
          width: 'calc(100vw - 320px)',
          maxWidth: '1115px',
          marginBottom: '0'
        }"
      >
      </boxxds-alert>
    </div>

    <app-document-preview
      *ngIf="showDocPreview"
      [docUrl]="currentPreviewDocUrl"
      (handleClose)="toggleDocPreview()"
    >
    </app-document-preview>
    <app-policy-cancel-popup
      [showModal]="this.permissionList[this.currentScreen] && showCancelModal"
      [details]="actionPopupDetails"
      (handleActionConfirm)="
        this.permissionList[this.currentScreen] && handleCancelConfirm($event)
      "
      (handleClose)="showCancelModal = false"
    ></app-policy-cancel-popup>
    <app-policy-reinstate-popup
      [showModal]="
        this.permissionList[this.currentScreen] && showReinstateModal
      "
      [details]="actionPopupDetails"
      (handleActionConfirm)="
        this.permissionList[this.currentScreen] &&
          handleReinstateConfirm($event)
      "
      (handleClose)="showReinstateModal = false"
      [details]="actionPopupDetails"
    ></app-policy-reinstate-popup>
    <app-policy-new-period-popup
      [showModal]="
        this.permissionList[this.currentScreen] && shwStrtNewPeriodModal
      "
      [details]="actionPopupDetails"
      (handleActionConfirm)="
        this.permissionList[this.currentScreen] &&
          handleStartNewPeriodConfirm($event)
      "
      (handleClose)="shwStrtNewPeriodModal = false"
    ></app-policy-new-period-popup>
    <app-policy-slideout
      *ngIf="showSlideOut"
      [isUnderwriter]="isUnderwriter"
      [showModal]="showSlideOut"
      [details]="details"
      [transactionRiskLevelAction]="transactionRiskLevelAction"
      (handleSave)="
        this.permissionList[this.currentScreen] &&
          this.details.active &&
          handleSlideOutSave($event)
      "
      (handleClose)="handleSlideOutClose()"
      (handleDownload)="handleDocDownload()"
    >
    </app-policy-slideout>
    <app-lifecycle-details
      [transactionType]="transactionType"
      [details]="details"
      [showPeriodOptions]="periodOptions"
      [currentProductDetails]="details"
      (handleShowPeriod)="handleShowPeriod($event)"
      (handleBind)="this.permissionList[this.currentScreen] && handleBind()"
      (handleCancel)="
        this.permissionList[this.currentScreen] && handleShowCancel()
      "
      (handleStartNextPeriod)="
        this.permissionList[this.currentScreen] && handleShowStartNextPeriod()
      "
      (handleReinstate)="
        this.permissionList[this.currentScreen] && handleShowReinstate()
      "
      (handleRenew)="
        this.permissionList[this.currentScreen] && handleShowRenew()
      "
    >
    </app-lifecycle-details>
    <div class="products">
      <div class="sub-nav">
        <ul>
          <li *ngFor="let nav of subNavData; let i = index">
            <button
              [ngClass]="{ active: nav.active }"
              (click)="handleNav($event, i)"
            >
              {{ nav.name }}
            </button>
          </li>
        </ul>
      </div>
      <div class="underwriter-container" *ngIf="getPdtDetails() as product">
        <div class="profile">
          <div class="photo">
            <ngx-avatars
              [size]="24"
              [textSizeRatio]="3"
              [name]="product.underwriter"
            ></ngx-avatars>
          </div>
          <div class="text">
            <p class="designation">{{ "UNDERWRITER" | translate }}</p>
            <boxxds-body
              [text]="product.underwriter"
              sz="sm"
              [customStyle]="{ textAlign: 'left' }"
            ></boxxds-body>
          </div>
        </div>
      </div>
      <div class="policy-details" *ngIf="getPdtDetails() as product">
        <div class="d-flex align-items-center mb-1">
          <div class="header">
            {{ product.status }}
            <span [ngStyle]="{ fontWeight: '500' }" *ngIf="isInvoiced">
              {{
                product.status?.toLowerCase() === "policy change"
                  ? " - Executed"
                  : " - Invoiced"
              }}</span
            >
          </div>
          <div class="d-flex align-items-center ml-auto">
            <boxxds-body
              sz="xxs"
              [text]="'TRNX DATE'"
              [customStyle]="{ color: '#BDBDBF' }"
            ></boxxds-body>
            <boxxds-body
              sz="sm"
              [text]="product.trnxDate"
              [customStyle]="{ color: '#56565C', marginLeft: '6px' }"
            >
            </boxxds-body>
            <button
              class="btn-expand-collapse"
              (click)="handleTileExpandCollapse()"
            >
              <img
                [ngClass]="!isTileExpanded && 'rotate'"
                class="mb-1 expand-collapse"
                [src]="'assets/img/dashboard/quote/chevron-up.png'"
              />
            </button>
          </div>
        </div>
        <div
          class="policy d-flex justify-content-between"
          *ngIf="isTileExpanded"
        >
          <div>
            <div>
              <div class="d-flex align-items-center mb-4">
                <div class="tile-grid">
                  <boxxds-body
                    sz="xxs"
                    [text]="'Policy number'"
                    [customStyle]="{
                      color: '#BDBDBF',
                      marginRight: '26px',
                      textTransform: 'uppercase',
                      marginBottom: '4px'
                    }"
                  >
                  </boxxds-body>
                  <boxxds-body
                    sz="sm"
                    [text]="product.policyId"
                    [customStyle]="{ color: '#56565C', marginRight: '10px' }"
                  >
                  </boxxds-body>
                </div>
                <boxxds-status-badge
                  [status]="product.status.toLowerCase()"
                  [statusType]="getStatusType(product.status)"
                  [badgeStyle]="{ textTransform: 'capitalize' }"
                ></boxxds-status-badge>
              </div>
              <div class="d-flex gap-5">
                <div class="d-flex flex-column gap-3">
                  <div class="tile-grid">
                    <boxxds-body
                      sz="xxs"
                      [text]="'Premium'"
                      [customStyle]="{
                        color: '#BDBDBF',
                        marginRight: '2px',
                        textTransform: 'uppercase',
                        marginBottom: '4px'
                      }"
                    >
                    </boxxds-body>
                    <boxxds-body
                      sz="sm"
                      [text]="
                        product.premium
                          | currency: currency : 'symbol-narrow' : '1.0-0'
                      "
                      [customStyle]="{ color: '#56565C' }"
                    >
                    </boxxds-body>
                  </div>
                  <div class="tile-grid">
                    <boxxds-body
                      sz="xxs"
                      [text]="'Annual premium'"
                      [customStyle]="{
                        color: '#BDBDBF',
                        marginRight: '2px',
                        textTransform: 'uppercase',
                        marginBottom: '4px'
                      }"
                    >
                    </boxxds-body>
                    <boxxds-body
                      sz="sm"
                      [text]="
                        product.annualPremium
                          | currency: currency : 'symbol-narrow' : '1.2-2'
                      "
                      [customStyle]="{ color: '#56565C' }"
                    >
                    </boxxds-body>
                  </div>
                </div>
                <div class="d-flex flex-column gap-3">
                  <div class="tile-grid">
                    <boxxds-body
                      sz="xxs"
                      [text]="'Deductible'"
                      [customStyle]="{
                        color: '#BDBDBF',
                        marginRight: '2px',
                        textTransform: 'uppercase',
                        marginBottom: '4px'
                      }"
                    >
                    </boxxds-body>
                    <boxxds-body
                      sz="sm"
                      [text]="
                        product.deductible
                          | currency: currency : 'symbol-narrow' : '1.0-0'
                      "
                      [customStyle]="{ color: '#56565C' }"
                    >
                    </boxxds-body>
                  </div>
                  <div class="tile-grid">
                    <boxxds-body
                      sz="xxs"
                      [text]="'Limit'"
                      [customStyle]="{
                        color: '#BDBDBF',
                        marginRight: '2px',
                        textTransform: 'uppercase',
                        marginBottom: '4px'
                      }"
                    >
                    </boxxds-body>
                    <boxxds-body
                      sz="sm"
                      [text]="
                        product.limit
                          | currency: currency : 'symbol-narrow' : '1.0-0'
                      "
                      [customStyle]="{ color: '#56565C' }"
                    >
                    </boxxds-body>
                  </div>
                </div>
                <div class="d-flex flex-colum align-self-end">
                  <div class="mt-auto">
                    <boxxds-body
                      sz="xxs"
                      [text]="'Effective date'"
                      [customStyle]="{
                        color: '#BDBDBF',
                        marginRight: '2px',
                        textTransform: 'uppercase',
                        marginBottom: '4px'
                      }"
                    >
                    </boxxds-body>
                    <boxxds-body
                      sz="sm"
                      [text]="product.policyPeriod"
                      [customStyle]="{ color: '#56565C' }"
                    >
                    </boxxds-body>
                  </div>
                </div>

                <div
                  class="d-flex flex-column gap-2"
                  [ngClass]="{ 'align-self-end': product.policyChange }"
                >
                  <div *ngIf="!product.policyChange && product.invoiceNumber">
                    <boxxds-body
                      sz="xxs"
                      [text]="'Invoice number'"
                      [customStyle]="{
                        color: '#BDBDBF',
                        marginLeft: '22px',
                        marginRight: '2px',
                        textTransform: 'uppercase',
                        marginBottom: '4px'
                      }"
                    >
                    </boxxds-body>
                    <div class="d-flex" *ngIf="product.invoiceNumber">
                      <img
                        src="assets/img/dashboard/quote/lifecycle/download.png"
                        width="18px"
                        height="18px"
                      />
                      <boxxds-body
                        sz="sm"
                        [text]="product.invoiceNumber"
                        [customStyle]="{ color: '#56565C', marginLeft: '4px' }"
                      >
                      </boxxds-body>
                    </div>
                  </div>
                  <div *ngIf="product.policyChange" class="mt-auto">
                    <boxxds-body
                      sz="xxs"
                      [text]="'Policy change'"
                      [customStyle]="{
                        color: '#BDBDBF',
                        marginRight: '2px',
                        textTransform: 'uppercase',
                        marginBottom: '4px'
                      }"
                    >
                    </boxxds-body>
                    <boxxds-body
                      sz="sm"
                      [text]="
                        (product.invoiceNumber
                          ? 'common.confirmed'
                          : 'common.pending'
                        ) | translate
                      "
                      [customStyle]="{ color: '#56565C' }"
                    >
                    </boxxds-body>
                  </div>
                  <div
                    *ngIf="
                      showReason(product.status) &&
                      product.trxReasons &&
                      product.trxReasons.length > 0
                    "
                  >
                    <div class="mt-auto">
                      <boxxds-body
                        sz="xxs"
                        [text]="'quoteDetails.reasonDescription' | translate"
                        [customStyle]="{
                          color: '#BDBDBF',
                          marginRight: '2px',
                          textTransform: 'uppercase',
                          marginBottom: '3px',
                          marginTop: '6px'
                        }"
                      >
                      </boxxds-body>

                      <boxxds-body
                        sz="sm"
                        [text]="
                          product.trxReasons?.[product.trxReasons.length - 1]
                            ?.comments
                        "
                        [customStyle]="{ color: '#595D63' }"
                      >
                      </boxxds-body>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <ng-container
              *ngIf="
                getTransLevelActions(
                  getPdtDetails()?.status,
                  isInvoiced
                ) as transActions
              "
            >
              <div
                class="risk-actions d-flex flex-column align-items-start"
                *ngIf="transActions.length > 0"
              >
                <boxxds-body
                  sz="xs"
                  [text]="'common.transactionRiskLevelActions' | translate"
                  [customStyle]="{ color: '#8F8F91' }"
                ></boxxds-body>

                <button
                  *ngFor="let action of transActions"
                  class="btn-action mt-2 ml-2"
                  (click)="
                    this.permissionList[this.currentScreen] &&
                      this.details.active &&
                      handleTransLevelAction(action, product)
                  "
                  [disabled]="
                    !this.permissionList[this.currentScreen] ||
                    !this.details.active
                  "
                  [class.noPointerEvents]="
                    !this.permissionList[this.currentScreen] ||
                    !this.details.active
                  "
                >
                  <img
                    class="mr-1"
                    src="assets/img/dashboard/quote/lifecycle/arrow-right-circle.png"
                    width="16px"
                    height="16px"
                  />{{ action }}
                </button>
              </div>
            </ng-container>
            <button
              class="ellipsis"
              (click)="
                handleEllipsisClick(
                  product.policyRiskTrxId,
                  details.status || 'bound'
                )
              "
            >
              <div class="ellipsis-container">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </button>
          </div>
        </div>

        <div
          class="tile-collapsed d-flex gap-3 align-items-center"
          *ngIf="!isTileExpanded"
        >
          <div class="tile-grid">
            <boxxds-body
              sz="xxs"
              [text]="'Policy number'"
              [customStyle]="{
                color: '#BDBDBF',
                textTransform: 'uppercase',
                marginBottom: '4px'
              }"
            >
            </boxxds-body>
            <boxxds-body
              sz="sm"
              [text]="product.policyId"
              [customStyle]="{ color: '#56565C' }"
            >
            </boxxds-body>
          </div>
          <boxxds-status-badge
            [status]="product.status.toLowerCase()"
            [statusType]="getStatusType(product.status)"
            [badgeStyle]="{ textTransform: 'capitalize' }"
          ></boxxds-status-badge>
          <div class="tile-grid">
            <boxxds-body
              sz="xxs"
              [text]="'Premium'"
              [customStyle]="{
                color: '#BDBDBF',
                marginRight: '2px',
                textTransform: 'uppercase',
                marginBottom: '4px'
              }"
            >
            </boxxds-body>
            <boxxds-body
              sz="sm"
              [text]="
                product.premium | currency: currency : 'symbol-narrow' : '1.0-0'
              "
              [customStyle]="{ color: '#56565C' }"
            >
            </boxxds-body>
          </div>
          <div class="tile-grid">
            <boxxds-body
              sz="xxs"
              [text]="'Annual premium'"
              [customStyle]="{
                color: '#BDBDBF',
                marginRight: '2px',
                textTransform: 'uppercase',
                marginBottom: '4px'
              }"
            >
            </boxxds-body>
            <boxxds-body
              sz="sm"
              [text]="
                product.annualPremium
                  | currency: currency : 'symbol-narrow' : '1.2-2'
              "
              [customStyle]="{ color: '#56565C' }"
            >
            </boxxds-body>
          </div>
          <div class="tile-grid">
            <boxxds-body
              sz="xxs"
              [text]="'Deductible'"
              [customStyle]="{
                color: '#BDBDBF',
                marginRight: '2px',
                textTransform: 'uppercase',
                marginBottom: '4px'
              }"
            >
            </boxxds-body>
            <boxxds-body
              sz="sm"
              [text]="
                product.deductible
                  | currency: currency : 'symbol-narrow' : '1.0-0'
              "
              [customStyle]="{ color: '#56565C' }"
            >
            </boxxds-body>
          </div>
          <div class="tile-grid">
            <boxxds-body
              sz="xxs"
              [text]="'Limit'"
              [customStyle]="{
                color: '#BDBDBF',
                marginRight: '2px',
                textTransform: 'uppercase',
                marginBottom: '4px'
              }"
            >
            </boxxds-body>
            <boxxds-body
              sz="sm"
              [text]="
                product.limit | currency: currency : 'symbol-narrow' : '1.0-0'
              "
              [customStyle]="{ color: '#56565C' }"
            >
            </boxxds-body>
          </div>
          <div *ngIf="product.policyChange">
            <div class="mt-auto">
              <boxxds-body
                sz="xxs"
                [text]="'Policy change'"
                [customStyle]="{
                  color: '#BDBDBF',
                  marginRight: '2px',
                  textTransform: 'uppercase',
                  marginBottom: '4px'
                }"
              >
              </boxxds-body>
              <boxxds-body
                sz="sm"
                [text]="
                  (product.invoiceNumber
                    ? 'common.confirmed'
                    : 'common.pending'
                  ) | translate
                "
                [customStyle]="{ color: '#56565C' }"
              >
              </boxxds-body>
            </div>
          </div>
          <button
            class="ellipsis btn-slideout"
            (click)="
              handleEllipsisClick(product.policyRiskTrxId, product.status)
            "
          >
            <div class="ellipsis-container">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </button>
        </div>
      </div>
      <div class="timeline-details">
        <div class="d-flex justify-content-between mb-2">
          <button class="timeline-sort-btn" (click)="handleTimelineTileSort()">
            <span>{{ "common.sortByNewest" | translate }}</span>
            <img
              [ngClass]="sortByNewAsc && 'rotate'"
              [src]="'assets/img/dashboard/quote/chevron-up.png'"
            />
          </button>
          <button
            class="expand-collapse-btn"
            (click)="handleTileExpandCollapseAll()"
          >
            {{
              isAllCollapsed
                ? ("common.expandBtnLabel" | translate)
                : ("common.collapseBtnLabel" | translate)
            }}
          </button>
        </div>
        <div
          class="tile-container"
          *ngFor="
            let tile of timeLineData
              | paginate
                : {
                    itemsPerPage: itemsPerPage,
                    currentPage: currentPage,
                    totalItems: totalCount
                  };
            let i = index
          "
        >
          <div class="d-flex flex-column mr-2 timeline">
            <div class="circle"></div>
            <div
              class="dashed-line"
              *ngIf="i !== timeLineData.length - 1"
            ></div>
          </div>
          <div class="timeline-tile">
            <div class="tile">
              <div class="d-flex align-items-center mb-1">
                <div class="header" [ngStyle]="{ color: '#8F8F91' }">
                  {{ tile.type
                  }}<span
                    [ngStyle]="{ fontWeight: '500', color: '#8F8F91' }"
                    *ngIf="tile.invoiceNumber && tile.invoiceNumber !== ''"
                    >{{
                      tile.status?.toLowerCase() === "policy change"
                        ? " - Executed"
                        : " - Invoiced"
                    }}</span
                  >
                </div>
                <div class="d-flex align-items-center ml-auto">
                  <boxxds-body
                    sz="xxs"
                    [text]="'TRNX DATE'"
                    [customStyle]="{ color: '#BDBDBF' }"
                  ></boxxds-body>
                  <boxxds-body
                    sz="sm"
                    [text]="tile.trnxDate"
                    [customStyle]="{ color: '#56565C', marginLeft: '6px' }"
                  >
                  </boxxds-body>
                  <button
                    class="btn-expand-collapse"
                    (click)="handleTimelineTileToggle(i)"
                  >
                    <img
                      [ngClass]="!tile.isTileExpanded && 'rotate'"
                      class="mb-1 expand-collapse"
                      [src]="'assets/img/dashboard/quote/chevron-up.png'"
                    />
                  </button>
                </div>
              </div>
              <div class="policy d-flex justify-content-between">
                <div>
                  <div class="d-flex gap-4">
                    <div
                      class="d-flex justify-content-between"
                      [ngClass]="
                        tile.isTileExpanded
                          ? 'flex-column'
                          : 'align-items-end gap-3'
                      "
                    >
                      <div [ngClass]="tile.isTileExpanded && 'flex-column'">
                        <boxxds-body
                          sz="xxs"
                          [text]="'Policy number'"
                          [customStyle]="{
                            color: '#BDBDBF',
                            marginRight: '26px',
                            textTransform: 'uppercase',
                            marginBottom: '4px'
                          }"
                        >
                        </boxxds-body>
                        <boxxds-body
                          sz="sm"
                          [text]="tile.policyId"
                          [customStyle]="{ color: '#56565C' }"
                        >
                        </boxxds-body>
                      </div>
                      <boxxds-status-badge
                        *ngIf="tile.status && tile.status !== ''"
                        [status]="tile.status.toLowerCase()"
                        [statusType]="getStatusType(tile.status)"
                        [badgeStyle]="{ textTransform: 'capitalize' }"
                      ></boxxds-status-badge>
                    </div>
                    <div
                      class="d-flex gap-2 justify-content-between"
                      [ngClass]="tile.isTileExpanded && 'flex-column'"
                    >
                      <div class="tile-grid">
                        <boxxds-body
                          sz="xxs"
                          [text]="'Premium'"
                          [customStyle]="{
                            color: '#BDBDBF',
                            marginRight: '2px',
                            textTransform: 'uppercase',
                            marginBottom: '4px'
                          }"
                        >
                        </boxxds-body>
                        <boxxds-body
                          sz="sm"
                          [text]="
                            tile.premium
                              | currency: currency : 'symbol-narrow' : '1.0-0'
                          "
                          [customStyle]="{ color: '#56565C' }"
                        >
                        </boxxds-body>
                      </div>
                      <div class="tile-grid">
                        <boxxds-body
                          sz="xxs"
                          [text]="'Annual premium'"
                          [customStyle]="{
                            color: '#BDBDBF',
                            marginRight: '2px',
                            textTransform: 'uppercase',
                            marginBottom: '4px'
                          }"
                        >
                        </boxxds-body>
                        <boxxds-body
                          sz="sm"
                          [text]="
                            tile.annualPremium
                              | currency: currency : 'symbol-narrow' : '1.2-2'
                          "
                          [customStyle]="{ color: '#56565C' }"
                        >
                        </boxxds-body>
                      </div>
                    </div>
                    <div
                      class="d-flex gap-3"
                      [ngClass]="tile.isTileExpanded && 'flex-column'"
                    >
                      <div class="tile-grid">
                        <boxxds-body
                          sz="xxs"
                          [text]="'Deductible'"
                          [customStyle]="{
                            color: '#BDBDBF',
                            marginRight: '2px',
                            textTransform: 'uppercase',
                            marginBottom: '4px'
                          }"
                        >
                        </boxxds-body>
                        <boxxds-body
                          sz="sm"
                          [text]="
                            tile.deductible
                              | currency: currency : 'symbol-narrow' : '1.0-0'
                          "
                          [customStyle]="{ color: '#56565C' }"
                        >
                        </boxxds-body>
                      </div>
                      <div class="tile-grid">
                        <boxxds-body
                          sz="xxs"
                          [text]="'Limit'"
                          [customStyle]="{
                            color: '#BDBDBF',
                            marginRight: '2px',
                            textTransform: 'uppercase',
                            marginBottom: '4px'
                          }"
                        >
                        </boxxds-body>
                        <boxxds-body
                          sz="sm"
                          [text]="
                            tile.limit
                              | currency: currency : 'symbol-narrow' : '1.0-0'
                          "
                          [customStyle]="{ color: '#56565C' }"
                        >
                        </boxxds-body>
                      </div>
                    </div>
                    <div class="align-self-end" *ngIf="tile.isTileExpanded">
                      <boxxds-body
                        sz="xxs"
                        [text]="'Effective date'"
                        [customStyle]="{
                          color: '#BDBDBF',
                          marginRight: '2px',
                          textTransform: 'uppercase',
                          marginBottom: '4px'
                        }"
                      >
                      </boxxds-body>
                      <boxxds-body
                        sz="sm"
                        [text]="tile.policyPeriod"
                        [customStyle]="{ color: '#56565C' }"
                      >
                      </boxxds-body>
                    </div>
                    <div
                      *ngIf="
                        !!tile.invoiceNumber &&
                        tile.invoiceNumber?.toString() !== '-1'
                      "
                      class="align-self-end"
                    >
                      <boxxds-body
                        sz="xxs"
                        [text]="'Invoice number'"
                        [customStyle]="{
                          color: '#BDBDBF',
                          marginLeft: '22px',
                          marginRight: '2px',
                          textTransform: 'uppercase',
                          marginBottom: '4px'
                        }"
                      >
                      </boxxds-body>
                      <div class="d-flex">
                        <img
                          src="assets/img/dashboard/quote/lifecycle/download.png"
                          width="18px"
                          height="18px"
                        />
                        <boxxds-body
                          sz="sm"
                          [text]="tile.invoiceNumber"
                          [customStyle]="{
                            color: '#56565C',
                            marginLeft: '4px'
                          }"
                        >
                        </boxxds-body>
                      </div>
                    </div>
                    <div
                      class="align-self-end"
                      *ngIf="
                        showReason(tile.status) &&
                        tile.trxReasons &&
                        tile.trxReasons.length > 0
                      "
                    >
                      <boxxds-body
                        sz="xxs"
                        [text]="'quoteDetails.reasonDescription' | translate"
                        [customStyle]="{
                          color: '#BDBDBF',
                          marginRight: '2px',
                          textTransform: 'uppercase',
                          marginBottom: '3px',
                          marginTop: '6px'
                        }"
                      >
                      </boxxds-body>

                      <boxxds-body
                        sz="sm"
                        [text]="
                          tile.trxReasons?.[tile.trxReasons.length - 1]
                            ?.comments
                        "
                        [customStyle]="{ color: '#595D63' }"
                      >
                      </boxxds-body>
                    </div>
                  </div>
                </div>
                <div class="d-flex">
                  <button
                    class="ellipsis"
                    (click)="
                      handleEllipsisClick(tile.policyRiskTrxId, tile.status)
                    "
                  >
                    <div class="ellipsis-container">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tile-footer">
          <div class="total-count">
            Total records found: <span>{{ totalCount }}</span>
          </div>
          <div class="pagination-container" *ngIf="totalPages > 1">
            <pagination-template
              #p="paginationApi"
              (pageChange)="onPageChange($event)"
            >
              <ul class="tbl-pagination">
                <li class="tbl-pagination-prev tbl-page-item">
                  <a
                    (keyup.enter)="p.previous()"
                    (click)="p.previous()"
                    [class.disabled]="p.isFirstPage()"
                    class="tbl-page-link"
                    tabindex="0"
                    aria-label="Previous"
                    >Previous</a
                  >
                </li>
                <li
                  *ngFor="let page of p.pages"
                  [class.active]="p.getCurrent() === page.value"
                  class="tbl-page-item"
                >
                  <a
                    (keyup.enter)="p.setCurrent(page.value)"
                    (click)="p.setCurrent(page.value)"
                    class="tbl-page-link"
                    tabindex="0"
                  >
                    {{ page.label }}
                  </a>
                </li>
                <li
                  class="pagination-next tbl-page-item"
                  [class.disabled]="p.isLastPage()"
                >
                  <a
                    (keyup.enter)="p.next()"
                    *ngIf="!p.isLastPage()"
                    (click)="p.next()"
                    class="tbl-page-link"
                    tabindex="0"
                    >Next</a
                  >
                </li>
              </ul>
            </pagination-template>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
