<div class="custom-modal">
  <button class="close-btn" aria-label="close" (click)="handleCloseClk()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      class="bi bi-x-circle"
      viewBox="0 0 16 16"
    >
      <path
        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.646 4.646a.5.5 0 0 0-.708.708L7.293 8l-3.355 3.646a.5.5 0 1 0 .708.708L8 8.707l3.646 3.647a.5.5 0 0 0 .708-.708L8.707 8l3.647-3.646a.5.5 0 1 0-.708-.708L8 7.293 4.646 4.646z"
      />
    </svg>
  </button>
  <div class="modal-content">
    <ngx-extended-pdf-viewer
      [base64Src]="docUrl"
      [height]="'90vh'"
      [useBrowserLocale]="false"
      [showHandToolButton]="false"
      [showPresentationModeButton]="false"
      [showToolbar]="true"
      [ignoreKeyboard]="false"
      [enableDragAndDrop]="false"
      [disableForms]="true"
      [showDrawEditor]="false"
      [showOpenFileButton]="false"
      [showSecondaryToolbarButton]="false"
      [showTextEditor]="false"
      [showStampEditor]="false"
      zoom="100%"
    ></ngx-extended-pdf-viewer>
  </div>
</div>
