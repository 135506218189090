import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BoxxResponse } from '../entities/boxx-response';
import { Policy } from '../entities/policy';
import { BaseService } from './base-services';

@Injectable({
  providedIn: 'root',
})
export class PolicyAnswerService extends BaseService<Policy> {
  constructor(http: HttpClient) {
    const baseurl = environment.msPolicyBaseUrl + environment.policyAnswerUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  GetAllByQuestionIdAndPolicyRiskId(
    policyRiskId,
    page = 1,
    limit = 10,
    sort = '',
  ): Observable<BoxxResponse<Policy>> {
    return this.http
      .get<BoxxResponse<Policy>>(
        this.baseurl +
          '?policyRiskId=' +
          policyRiskId +
          '&page=' +
          page +
          '&limit=' +
          limit +
          '&active=1',
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  // PUT
  UpdateBulkQuestionAnswers(data): Observable<BoxxResponse<Policy>> {
    const url = this.baseurl + 'bulk/';
    return this.http
      .put<BoxxResponse<any>>(url, data)
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
