import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, forkJoin, take } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { Pagination } from 'src/app/entities/boxx-response';
import {
  getCurrencySelector,
  getDashboardSelector,
  getUserTypes,
} from 'src/app/store/dashboard/dashboard.selector';
import { PolicyDashboardService } from 'src/app/services/policy-dashboard.service';
import {
  excelFileDownload,
  formatAmountWithCurrency,
  getAlertHead,
  getErrorMessage,
  valInDigits,
} from 'src/app/utils/utils';
import {
  dateTimeWithUserName,
  formatDate,
  formatDateMoment,
  formatDateTime,
  getDateString,
  getFormatDate,
  getFormattedDate,
} from 'src/app/utils/formatDate';
import {
  EntryType,
  QuoteStatus,
  mapQuote,
} from 'src/app/constants/quoteStatus';
import { getFormGroup, mapFormGroupToModel } from './producer-profile.factory';
import { BrokerageService } from 'src/app/services/brokerage.service';
import { BrokerageBranchService } from 'src/app/services/brokerage-branch.service';
import { BrokerageProducerService } from 'src/app/services/brokerage-producer.service';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { environment } from 'src/environments/environment';
import {
  BrokerageProducer,
  BrokerageProducerProfile,
} from 'src/app/entities/brokerage-producer';
import { DatePipe, ViewportScroller } from '@angular/common';
import { CropperSettings, ImageCroppedEvent } from 'ngx-image-cropper';
import { AlertService } from 'src/app/services/alert.service';
import { SystemUserService } from 'src/app/services/system-user.service';
import { ProducerModel } from 'src/app/models/producer.model';
import {
  SYSTEM_USER_TYPES,
  USER_ROLES,
} from 'src/app/constants/security/systemUserType';
import { RoleService } from 'src/app/services/roles.service';
import { BrokerageProducerProfileService } from 'src/app/services/brokerage-producer-profile.service';
import { Breadcrumb } from 'src/app/models/breadcrumb.model';
import { DomainsService } from 'src/app/services/domains.service';
import {
  PolicyStatus,
  StatusMapping,
  getPolicyStatusTypeV2,
  mapPolicy,
} from 'src/app/constants/policyStatus';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import { ConfRiskLimitsService } from 'src/app/services/conf-risk-limits.service';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import { Quote } from 'src/app/models/quote.model';
import { getUIData } from 'src/app/store/create-quote/create-quote.selector';

@Component({
  selector: 'app-producer-profile',
  templateUrl: './producer-profile.component.html',
  styleUrls: ['./producer-profile.component.less'],
})
export class ProducerComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  insuredForm: FormGroup;
  producerId: number = undefined;
  branchId: number = undefined;
  brokerageId: number = undefined;
  commissionType: string = 'Producer';
  itemsMenu: Breadcrumb[] = [];
  activeCategoryFilter: string = 'Transactions overview';

  showFilter = false;
  filterSelectedOptions: any;
  filterInputOptions = [];
  statusOptions = [];
  userID: number;
  products;
  pdtOptions = [];
  filterForm: FormGroup;
  premiumOptions = [];
  limitOptions = [];
  filteredOptions = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private translateService: TranslateService,
    private store: Store,
    private policyDashboardService: PolicyDashboardService,
    private activatedRoute: ActivatedRoute,
    private brokerageService: BrokerageService,
    private brokerageBranchService: BrokerageBranchService,
    private brokerageProducerService: BrokerageProducerService,
    private brokerageProducerProfileService: BrokerageProducerProfileService,
    private localStorageService: LocalStorageService,
    private datePipe: DatePipe,
    private alertService: AlertService,
    private systemUserService: SystemUserService,
    private viewPortScroller: ViewportScroller,
    private roleService: RoleService,
    private renderer: Renderer2,
    private domainsService: DomainsService,
    private riskRegionService: RiskRegionService,
    private confRiskLimitsService: ConfRiskLimitsService,
    public boxxUserService: BoxxUserService,
  ) {
    this.insuredForm = this.fb.group({
      searchKey: ['', [Validators.pattern(/^.{3,}$/)]],
    });

    activatedRoute.params.subscribe((params) => {
      this.branchId = params['branchId'] as number;
      this.brokerageId = params['brokerageId'] as number;
      this.producerId = params['producerId'] as number;
      if (params['option']) {
        if (params['option'] === 'commission') {
          this.activeCategoryFilter = 'Commission rates';
        }
      }
    });

    this.filterForm = this.fb.group({
      createdDt: this.fb.group({
        from: [''],
        to: [''],
      }),
      status: [[]],
      risks: [[]],
      insuredName: [''],
      premiumRange: this.fb.group({
        from: [''],
        to: [''],
      }),
      limitRange: this.fb.group({
        from: [''],
        to: [''],
      }),
    });
  }

  form: FormGroup;
  isInsuredTypeCompany: boolean = true;
  showTblSpinner: boolean = false;
  tableHeaders = [
    {
      Status: '',
      StatusReason: '',
      ID: '',
      'Date created': '',
      'Insured name': '',
      Products: '',
      Limit: '',
      Premium: '',
    },
  ];
  tableData: {
    Status: any;
    StatusReason: string;
    ID: any;
    'Date created': any;
    'Insured name': any;
    Products: any;
    Limit: any;
    Premium: any;
  }[] = [
    {
      Status: '',
      StatusReason: '',
      ID: '',
      'Date created': '',
      'Insured name': '',
      Products: '',
      Limit: '',
      Premium: '',
    },
  ];
  multiContentColumns = {};
  pagination: Pagination = {
    currentPage: 1,
    totalPages: 1,
    totalRecords: 0,
    hasNext: false,
    hasPrevious: false,
  };
  sortBy = 'createdDt:desc';
  sortedColumn = '';
  enteredOnSearch: boolean = false;
  searchResults = [];
  showSpinner = false;
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  noResultInfo = {
    label: 'test',
    redirectionPath: '',
    showButtonRightIcon: true,
    buttonRightIconImagePath: 'assets/img/plus-circle-v2.svg',
  };
  noResultContent = '';
  columnsToHide: string[] = ['StatusReason'];
  columnHeadsToHide: string[] = [];
  sortableColumns: string[] = ['ID', 'Date created', 'Insured name', 'Status'];
  iconColumns: string[] = [];
  customColumnWidth: {
    columnName: string;
    minWidth: string;
    maxWidth: string;
  }[] = [{ columnName: 'Insured Name', minWidth: '226px', maxWidth: '226px' }];
  // quoteStates: string[] = ['All', 'Submissions', 'Closed Quotes', 'Policies'];
  quoteStates = [
    // {
    //   name: 'All',
    //   value:'All',
    //   quoteStatus:'',
    //   policyStatus: '',
    //   entryType:''
    // },
    // {
    //   name: 'Submissions',
    //   value:QuoteStatus['Submission'],
    //   quoteStatus:QuoteStatus['Submission'],
    //   policyStatus: '',
    //   entryType:'quote'
    // },
    // {
    //   name: 'Closed Quotes',
    //   value:PolicyStatus.QUOTECLOSED,
    //   quoteStatus:'',
    //   policyStatus: PolicyStatus.QUOTECLOSED,
    //   entryType:'quote'
    // },
    {
      name: 'Quotes',
      value: 'Quote',
      quoteStatus: '',
      policyStatus: '',
      entryType: EntryType.Quote,
    },
    {
      name: 'Policies',
      value: 'Policies',
      quoteStatus: '',
      policyStatus: '',
      entryType: EntryType.Policy,
    },
  ];
  activeQuoteStatus = this.quoteStates[0];
  categoryFilters: string[] = [
    'Transactions overview',
    'Producer information',
    'Commission rates',
  ];
  searchValue: string = '';
  currency = '';
  shortDateFormat: string = '';
  longDateFormat: string = '';
  shortDateTimeFormat: string = '';
  longDateTimeFormat: string = '';
  quoteList = [];
  brokerageProducerOldSelected: BrokerageProducer;
  brokerageData: any;
  brokerageBranchData: any;
  createdBy: string | null;
  updatedBy: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  producerSelected: BrokerageProducer;
  producerProfileOldSelected: BrokerageProducerProfile;
  croppedImage: any = 'assets/img/default-photo.png';
  previousImage: string;
  producerProfileId: number;
  isFormDirty: boolean = false;
  showBodySpinner: boolean = false;
  initialFormValue: any;
  private formSubscription: Subscription;
  formUpdated: boolean = false;
  submitted: boolean = false;
  hasSystemAccess: boolean = false;
  parentCompanyList = [];
  parentCompanyInvalidErrMsg: string = '';
  parentInitValue: string = '';
  contentLoader: boolean = true;
  cropperSettings: CropperSettings;
  allowedFormats = ['.jpg', '.jpeg', '.png', '.gif'];
  maxFileSizeMB = 2; // Maximum file size in MB
  imageChangedEvent: any = '';
  showCropper = false;
  systemUserId: number;
  isSuccess = false;
  hasError: boolean = false;
  customCommission: boolean = false;
  checked: boolean = false;
  systemUserTypeDetails: { description: string; id: number }[] = [];
  rollId: number = undefined;
  isRestrictedRoleSubmission: boolean = false;
  uiData: any;

  async ngOnInit(): Promise<void> {
    this.userID = this.localStorageService.getBoxxUserId();
    this.form = getFormGroup(this.fb);
    this.store
      .pipe(select(getCurrencySelector))
      .subscribe((value) => (this.currency = value));

    this.store.pipe(select(getUserTypes)).subscribe((data) => {
      this.systemUserTypeDetails = data.filter(
        (systemUser) => systemUser.description === SYSTEM_USER_TYPES.Producer,
      );
    });

    this.roleService
      .GetByNameNsystemUserType(
        this.systemUserTypeDetails[0].description,
        this.systemUserTypeDetails[0].id,
      )
      .subscribe({
        next: (response) => {
          this.rollId = response.data?.[0]?.id ?? undefined;
        },
        error: (error) => {},
      });

    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
      this.shortDateTimeFormat = data.shortDateTimeFormat;
      this.longDateTimeFormat = data.longDateTimeFormat;
      this.isRestrictedRoleSubmission = data.isRestrictedRoleSubmission;
    });
    this.store.pipe(select(getUIData)).subscribe((data) => {
      this.uiData = data;
    });
    this.initialProducerFormData();
    this.handleCategoryFilter(this.activeCategoryFilter);

    this.insuredForm.setValue({
      searchKey: this.searchValue,
    });

    this.populatePremiumOptions();
    await this.fetchQuoteStatusLists();
    await this.populateLimitOptions();
    await this.getProductList();
  }

  handleBackToQlc() {
    this.router.navigate([`/dashboard/workflow3/${this.uiData.policyId}`], {
      relativeTo: this.activatedRoute,
      skipLocationChange: true,
    });
  }

  handleSorting(data: any): void {
    let sortColumn = this.mapSortColumns(data.column);
    this.sortBy = `${
      sortColumn ? `${sortColumn}:${data.sort},id:asc` : `createdDt:desc`
    }`;
    if (data.column === 'Status') {
      if (this.activeQuoteStatus.entryType === EntryType.Quote) {
        this.sortBy = `quoteStatus:${data.sort},id:asc`;
      }
      if (this.activeQuoteStatus.entryType === EntryType.Policy) {
        this.sortBy = `policy.PolicyStatus.description:${data.sort},id:asc`;
      }
    }
    this.populateTransactionData({
      insuredName: this.insuredForm.value['searchKey'] ?? '',
      page: this.pagination.currentPage,
      sort: this.sortBy,
    });
  }

  columnClickHandle(data: any): void {}

  handleCategoryFilter(category: string): void {
    this.contentLoader = true;
    this.activeCategoryFilter = category;
    this.searchValue = '';
    this.filterSelectedOptions = undefined;
    this.defaultFilterForm();
    this.insuredForm.get('searchKey').setValue('');

    if (this.activeCategoryFilter === 'Transactions overview') {
      this.populateTransactionData({ sort: this.sortBy });
    } else if (this.activeCategoryFilter === 'Producer information') {
      this.initialProducerFormData();
    } else if (this.activeCategoryFilter === 'Commission rates') {
      this.contentLoader = false;
    }
  }

  handleEditInsured(index: number): void {}

  handleAddNewInsured() {}

  async handleSearch(event) {
    if (event.key === 'Tab') {
      return;
    }
    const searchValue = event.target.value;
    this.filteredOptions = [];
    this.filterSelectedOptions = undefined;
    this.defaultFilterForm();

    if (
      event.key == 'Enter' &&
      searchValue?.length >= 3 &&
      searchValue?.length <= 45
    ) {
      this.searchValue = searchValue;
      await this.populateTransactionData({
        sort: this.sortBy,
      });
    } else if (
      searchValue?.length === 0 ||
      (event.key == 'Enter' && searchValue?.length < 3)
    ) {
      this.searchValue = '';
      await this.populateTransactionData({
        sort: this.sortBy,
      });
    }
  }

  handleTablePageChange(page = 1) {
    const searchName = this.insuredForm.value['searchKey'] ?? '';
    this.populateTransactionData({
      page,
      sort: this.sortBy,
    });
  }

  mapSortColumns(key: string) {
    let sortColumn: string;
    switch (key) {
      case 'ID':
        sortColumn = 'id';
        break;

      case 'Date created':
        sortColumn = 'createdDt';
        break;

      case 'Insured name':
        sortColumn = 'insuredName';
        break;

      case 'Status':
        sortColumn = 'quoteStatus';
        break;
    }
    return sortColumn;
  }

  getUpdatedRowData(
    currentRowData: { [x: string]: any },
    newKeyValue: { key: string; value: any },
    newKeyValuePosition: number,
  ) {
    const keys = Object.keys(currentRowData);
    const values = Object.values(currentRowData);

    const updatedRowData = keys.reduce((acc, key, index) => {
      if (index === newKeyValuePosition) {
        acc[newKeyValue.key] = newKeyValue.value;
      }
      acc[key] = values[index];
      return acc;
    }, {});

    return updatedRowData;
  }

  makeFilter() {
    let insuredName = this.filterSelectedOptions?.insuredName
      ? this.filterSelectedOptions?.insuredName
      : this.searchValue
      ? this.searchValue
      : this.insuredForm.value['searchKey']
      ? this.insuredForm.value['searchKey']
      : '';

    const productsVal = this.filterSelectedOptions?.risks ?? [];
    const productIds = productsVal.map((p) => p.id).join(',');
    const underwritersVal = this.filterSelectedOptions?.underwriter ?? [];
    const underwriterIds = underwritersVal
      .map((currentValue) => currentValue.id)
      .join(',');
    let statusArray = this.filterSelectedOptions?.status ?? [];
    // if(statusArray.length>0){
    //   if(this.activeQuoteStatus.value!=='All' && this.activeQuoteStatus.value!=='Policies'){
    //     let fromNav = statusArray.filter((x)=>x.fromNav);
    //     if(fromNav.length==1 && statusArray.length>1){
    //       this.filterSelectedOptions.status=this.filterSelectedOptions.status.map((option)=>{
    //         if(option.fromNav){
    //           return {...option, fromNav:false}
    //         }else{
    //           return {...option}
    //         }
    //       })
    //       this.activeQuoteStatus=this.quoteStates[0]
    //     }
    //   }else if(this.activeQuoteStatus.value==='Policies'){
    //     let quoteArray = statusArray.filter((x)=>x.type==='quote')
    //     let policyArray = statusArray.filter((x)=>x.type==='policy')

    //     if(quoteArray.length>0 && policyArray.length==0){
    //       this.filterSelectedOptions.status.push(...this.statusOptions.filter((item)=>item.type==='policy'))
    //       this.activeQuoteStatus=this.quoteStates[0]
    //     }
    //   }
    //   statusArray = this.filterSelectedOptions?.status ?? []
    // }

    let policyStatusArray = statusArray.filter(
      (x) => x.type != EntryType.Quote,
    );
    let quoteStatusArray = statusArray.filter(
      (x) => x.type === EntryType.Quote,
    );

    // filter['quoteStatus'] = this.getQuoteStatus(this.activeQuoteStatus);
    let policyStatusVal = policyStatusArray
      .map((f) => mapPolicy(f.value))
      .join(',');
    policyStatusVal = policyStatusVal
      ? policyStatusVal
      : this.activeQuoteStatus.policyStatus;
    let quoteStatusVal = quoteStatusArray
      .map((f) => mapQuote(f.value))
      .join(',');
    quoteStatusVal = quoteStatusVal
      ? quoteStatusVal
      : this.activeQuoteStatus.quoteStatus;
    // const expiryDt = this.filterSelectedOptions?.expiryDt;
    // const effectiveDt = this.filterSelectedOptions?.effectiveDt;
    const createDt = this.filterSelectedOptions?.createdDt;
    const premium = this.filterSelectedOptions?.premiumRange;
    const limit = this.filterSelectedOptions?.limitRange;
    // const deductables = this.filterSelectedOptions?.deductibleRange;
    // let expiryRange = '';
    // let effectiveRange = '';
    let createRange = '';
    let premiumRange = '';
    let limitRange = '';
    // let deductibleRange = '';
    // if (expiryDt && expiryDt.from && expiryDt.to) {
    //   const formattedFromDate = getFormattedDate(expiryDt.from);
    //   const formattedToDate = getFormattedDate(expiryDt.to);
    //   expiryRange = `${formattedFromDate},${formattedToDate}`;
    // }
    if (createDt && createDt?.from && createDt?.to) {
      const formattedFromDate = getDateString(
        createDt.from,
        this.shortDateFormat,
      );
      const formattedToDate = getDateString(createDt.to, this.shortDateFormat);
      createRange = `${formattedFromDate},${formattedToDate}`;
    }
    // if (effectiveDt && effectiveDt.from && effectiveDt.to) {
    //   const formattedFromDate = getFormattedDate(effectiveDt.from);
    //   const formattedToDate = getFormattedDate(effectiveDt.to);
    //   effectiveRange = `${formattedFromDate},${formattedToDate}`;
    // }
    if (
      premium &&
      premium.from !== null &&
      premium.to !== null &&
      premium.from !== '' &&
      premium.to !== ''
    ) {
      const formattedFrom =
        this.premiumOptions.find((x) => x.id == premium.from)?.value ??
        premium.from;
      const formattedTo =
        this.premiumOptions.find((x) => x.id == premium.to)?.value ??
        premium.to;
      premiumRange = `${formattedFrom},${formattedTo}`;
    }
    if (
      limit &&
      limit.from !== null &&
      limit.to !== null &&
      limit.from !== '' &&
      limit.to !== ''
    ) {
      const formattedFrom =
        this.limitOptions.find((x) => x.id == limit.from)?.value ?? limit.from;
      const formattedTo =
        this.limitOptions.find((x) => x.id == limit.to)?.value ?? limit.to;
      limitRange = `${formattedFrom},${formattedTo}`;
    }
    // if (deductables && deductables.from !== '' && deductables.to !== '') {
    //   const formattedFrom =
    //     this.premiumOptions.find((x) => x.id == deductables.from)?.value ??
    //     deductables.from;
    //   const formattedTo =
    //     this.premiumOptions.find((x) => x.id == deductables.to)?.value ??
    //     deductables.to;
    //   deductibleRange = `${formattedFrom},${formattedTo}`;
    // }

    const filter = {
      policyStatus: policyStatusVal,
      quoteStatus: quoteStatusVal,
      createdDt: createRange,
      brokerageProducerBORId: this.producerId,
      riskIds: productIds,
      premiumRange: premiumRange,
      limitRange: limitRange,
      insuredName: insuredName,
    };

    return filter;
  }

  formatPolicyRiskList(policyRiskList: any): {
    products: string;
    limit: string;
    premium: string;
  } {
    if (!policyRiskList || policyRiskList.length === 0) {
      return null;
    }
    let formattedObj: { products: string; limit: string; premium: string } = {
      products: '',
      limit: '',
      premium: '',
    };
    formattedObj.products = policyRiskList.map((item) => item.risk).join(', ');
    formattedObj.limit = policyRiskList
      .map((item) =>
        item.limit
          ? formatAmountWithCurrency(item.limit, this.currency)
          : formatAmountWithCurrency('0', this.currency),
      )
      .join(', ');
    formattedObj.premium = policyRiskList
      .map((item) =>
        item.premium
          ? formatAmountWithCurrency(item.premium, this.currency)
          : formatAmountWithCurrency('0', this.currency),
      )
      .join(', ');
    return formattedObj;
  }

  getQuoteStatus(quoteState: string): string {
    switch (quoteState) {
      case 'Submissions':
        return QuoteStatus['Submission'];
      case 'Closed Quotes':
        return QuoteStatus['Closed'];
      case 'Policies':
        return QuoteStatus['Policy'];

      default:
        return '';
    }
  }

  getEntryType(quoteState: string): string {
    if (quoteState === 'Bound') {
      return EntryType.Policy;
    }
    if (['Submission', 'Closed'].includes(quoteState)) {
      return EntryType.Quote;
    }
    return '';
  }

  async handleQuoteState(quoteState) {
    if (this.activeQuoteStatus.value !== quoteState.value) {
      this.showTblSpinner = true;
      this.activeQuoteStatus = quoteState;
      await this.fetchQuoteStatusLists();
      let currentStatusFilters = this.filterForm.value['status'] ?? [];
      // if (currentStatusFilters.includes(this.activeQuoteStatus.value)) {
      //   this.filterForm.controls['status'].setValue([
      //     `${this.activeQuoteStatus.policyStatus?this.activeQuoteStatus.policyStatus:this.activeQuoteStatus.quoteStatus}`,
      //   ]);
      // } else {
      this.filterForm.controls['status'].setValue([]);
      if (!this.filterSelectedOptions) {
        this.filterSelectedOptions = this.filterForm.value;
      }
      this.filterSelectedOptions.status = [];
      // }
      await this.populateFilterOptions();
      let statusFilter = this.filterInputOptions.findIndex(
        (item) => item.value === 'status',
      );
      this.filterInputOptions[statusFilter].dropdownOptions =
        this.statusOptions;

      // if (quoteState.value !== 'Quote' && quoteState.value !== 'Policies') {
      //   if (!this.filterSelectedOptions) {
      //     this.filterSelectedOptions = this.filterForm.value;
      //   }
      //   this.filterSelectedOptions.status = [
      //     {
      //       fromNav: true,
      //       ...this.statusOptions.find(
      //         (x) =>
      //           x.value.toLowerCase() ===
      //           this.activeQuoteStatus.value.toLowerCase(),
      //       ),
      //     },
      //   ];

      //   statusFilter.isDefault = true;
      //   // statusFilter.isRequired = true;
      // } else {
      //   let ind = this.filterSelectedOptions.status.findIndex((x) => x.fromNav);
      //   if (ind >= 0) {
      //     this.filterSelectedOptions.status.splice(ind, 1);
      //   }
      //   statusFilter.isDefault = false;
      //   statusFilter.isRequired = false;
      // }
      // if(this.activeQuoteStatus.value==='Policies'){
      //   let quoteStatusOptions = this.filterSelectedOptions.status.filter(x=>x.type==='quote');
      //   for(let i=0; i<quoteStatusOptions.length;i++){
      //     let ind = this.filterSelectedOptions.status.findIndex((x) => x.type==='quote');
      //     if (ind >= 0) {
      //       this.filterSelectedOptions.status.splice(ind, 1);
      //     }
      //   }
      //   if(this.filterSelectedOptions.status.length==0){
      //     statusFilter.isDefault = false;
      //     statusFilter.isRequired = false;
      //   }
      // }

      this.showTblSpinner = false;
      this.populateTransactionData({ sort: this.sortBy });
      // }
    }
  }

  async populateTransactionData({
    insuredName = '',
    page = 1,
    limit = 10,
    sort = '',
  } = {}) {
    this.showTblSpinner = true;
    let filter = this.makeFilter();

    let insuredType = -1;
    // // filter['insuredName'] = insuredName;
    // filter['quoteStatus'] = this.getQuoteStatus(this.activeQuoteStatus);
    let filterQuote = filter['quoteStatus'];
    let filterPolicy = filter['policyStatus'];
    let entryType = this.activeQuoteStatus.entryType;
    // if(this.activeQuoteStatus.entryType!==''){
    //   entryType = this.activeQuoteStatus.entryType;
    // }else if(filterQuote!==''&&filterPolicy===''){
    //   entryType = EntryType.Quote
    // }else if(filterPolicy!==''&&filterQuote===''){
    //   entryType = EntryType.Policy
    // }else{
    //   entryType = ''
    // }
    // if(!filter['policyStatus']){
    //   entryType = this.getEntryType(
    //     this.getQuoteStatus(this.activeQuoteStatus),
    //   );
    // }
    this.policyDashboardService
      .GetAllQuoteByFilter(insuredType, filter, page, limit, sort, entryType)
      .subscribe({
        next: (response) => {
          this.contentLoader = false;
          this.searchResults = response?.data;
          this.pagination = response?.pagination;
          // this.searchValue = insuredName;
          if (response?.data?.length > 0) {
            this.tableData = response.data?.map((dataObj) => {
              const filteredVal = this.formatPolicyRiskList(
                dataObj.policyRiskTrxes.policyRisk,
              );

              return {
                Status:
                  StatusMapping[
                    dataObj.policyRiskTrxes.quoteStatus?.description?.toLowerCase()
                      ? dataObj.policyRiskTrxes.quoteStatus?.description?.toLowerCase()
                      : dataObj.policy.PolicyStatus?.description?.toLowerCase()
                  ],
                StatusReason: dataObj.policyRiskTrxes?.reason ?? '',
                ID: String(dataObj.policy.pkgPolicyNumber),
                'Date created': formatDate(
                  dataObj.policy.createdDt,
                  this.shortDateFormat,
                ),
                'Insured name': dataObj.policy.insured.companyName
                  ? dataObj.policy.insured.companyName
                  : dataObj.policy.insured.firstName +
                    ' ' +
                    dataObj.policy.insured.lastName,
                Products: filteredVal.products,
                Limit: formatAmountWithCurrency(
                  filteredVal.limit,
                  this.currency,
                ),
                Premium: formatAmountWithCurrency(
                  filteredVal.premium,
                  this.currency,
                ),
              };
            });
          } else {
            this.tableData = this.tableHeaders;
          }
          this.showTblSpinner = false;
        },
        error: (error) => {
          this.showTblSpinner = false;
        },
      });
  }

  getCaseName(key: string): string {
    let primaryArray = [];
    let infoArray = ['info'];
    let successArray = ['active', 'success'];
    let dangerArray = ['inactive'];

    if (primaryArray.includes(key)) {
      return 'primary';
    }
    if (infoArray.includes(key)) {
      return 'info';
    }
    if (successArray.includes(key)) {
      return 'success';
    }
    if (dangerArray.includes(key)) {
      return 'danger';
    }
    return 'default';
  }

  getBadgeClass(key: string): string {
    let caseName = this.getCaseName(key.toLowerCase());

    switch (caseName) {
      case 'primary':
        return 'primary-badge';
      case 'info':
        return 'info-badge';
      case 'success':
        return 'success-badge';
      case 'danger':
        return 'danger-badge';

      default:
        return 'default-badge';
    }
  }

  initialProducerFormData() {
    this.contentLoader = true;
    forkJoin({
      brokerage: this.brokerageService.GetBrokerage(this.brokerageId),
      brokerageBranch: this.brokerageBranchService.GetBrokerageBranch(
        this.branchId,
      ),
      brokerageProducer: this.brokerageProducerService.GetBrokerageProducer(
        this.producerId,
      ),
    }).subscribe(({ brokerage, brokerageBranch, brokerageProducer }) => {
      if (
        Object.entries(brokerage.data).length === 0 ||
        brokerageBranch.data === null ||
        brokerageProducer.data === null
      ) {
        let isMock = 'true';
        if (
          this.localStorageService.getMockExternalAPIs() === 'false' ||
          environment.mockExternalAPIs === false
        ) {
          isMock = 'false';
        }
        this.router.navigate(['dashboard/home'], {
          queryParams: {
            mock: isMock,
          },
          skipLocationChange: true,
        });
      }

      this.brokerageData = brokerage.data;
      this.brokerageBranchData = brokerageBranch.data;
      this.brokerageProducerOldSelected = { ...brokerageProducer.data };

      this.itemsMenu = [
        {
          label: 'Members',
          path: '/dashboard/workflow3/brokerage/directory',
        },
        {
          label: 'Brokerage directory',
          path: '/dashboard/workflow3/brokerage/directory',
        },
        {
          label: `${this.brokerageData?.name}`,
          path: `/dashboard/workflow3/brokerage/profile/${this.brokerageId}`,
        },
        {
          label: `${this.brokerageBranchData?.name}`,
          path: `/dashboard/workflow3/brokerage/branch/producer/profile/${this.branchId}`,
        },
        {
          label: `${this.brokerageProducerOldSelected?.firstName} ${this.brokerageProducerOldSelected?.lastName}`,
          path: null,
        },
      ];

      this.hasSystemAccess = this.brokerageProducerOldSelected?.systemUserId
        ? this.brokerageProducerOldSelected.producerSystemUser?.['active']
        : false;

      this.createdBy = this.brokerageProducerOldSelected.createdBy;
      this.updatedBy = this.brokerageProducerOldSelected.updatedBy;

      this.createdAt = formatDateTime(
        new Date(this.brokerageProducerOldSelected.createdDt),
        this.longDateTimeFormat,
      );
      this.updatedAt = formatDateTime(
        new Date(this.brokerageProducerOldSelected.updatedDt),
        this.longDateTimeFormat,
      );

      this.checked =
        this.brokerageProducerOldSelected.customCommission ?? false;
      this.customCommission = this.checked;

      this.producerSelected = { ...brokerageProducer.data };
      this.form[this.producerSelected.active ? 'enable' : 'disable']();

      // this.brokerageProducerService
      //   .GetProducerProfile(this.producerSelected.id)
      this.brokerageProducerProfileService
        .GetBrokerageAllProducerProfile({
          brokerageProducerId: this.producerSelected.id,
          sort: '',
        })
        .subscribe({
          next: (response) => {
            if (response?.data && response?.data.length > 0) {
              this.producerProfileOldSelected = {
                ...response.data[0],
              };
              this.croppedImage =
                this.producerProfileOldSelected?.headShotImage ??
                'assets/img/default-photo.png';
              this.previousImage =
                this.producerProfileOldSelected?.headShotImage ??
                'assets/img/default-photo.png';
              this.producerProfileId = this.producerProfileOldSelected?.id;
              if (
                new Date(this.producerProfileOldSelected?.updatedDt) >
                new Date(this.brokerageProducerOldSelected?.updatedDt)
              ) {
                this.updatedBy = this.producerProfileOldSelected?.updatedBy;
                this.updatedAt = formatDateTime(
                  new Date(this.producerProfileOldSelected?.updatedDt),
                  this.longDateTimeFormat,
                );
              }
            }
            this.initProducerForm();
          },
          error: (error) => {},
        });
    });
  }

  initProducerForm() {
    this.unSubscriptionForms();
    this.isFormDirty = false;
    this.form.get('firstName').setValue(this.producerSelected.firstName);
    this.form.get('lastName').setValue(this.producerSelected.lastName);
    this.form.get('phone').setValue(this.producerSelected.telephone);
    this.form.get('email').setValue(this.producerSelected.email);
    this.form
      .get('customCommission')
      .setValue(this.producerSelected.customCommission);
    this.form
      .get('licenseNumber')
      .setValue(this.producerSelected.licenseNumber ?? '');
    this.form
      .get('licenseExpiry')
      .setValue(
        this.producerSelected.licenseExpiry
          ? formatDateMoment(
              this.producerSelected.licenseExpiry?.split('T')?.[0],
              this.shortDateFormat,
            )
          : '',
      );
    this.showBodySpinner = false;
    this.initialFormValue = this.form.value;
    this.formSubscription = this.form.valueChanges.subscribe(() => {
      this.isFormDirty = !this.isFormUnchanged(this.initialFormValue);
    });
    this.contentLoader = false;
  }

  private unSubscriptionForms() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  isFormUnchanged(initialValues): boolean {
    let formUnchanged = false;
    if (this.previousImage != this.croppedImage) {
      this.formUpdated = true;
      formUnchanged = false;
    } else {
      this.formUpdated = false;
      formUnchanged = true;
    }
    if (JSON.stringify(this.form.value) === JSON.stringify(initialValues)) {
      this.formUpdated = false;
      formUnchanged = true;
    } else {
      this.formUpdated = true;
      formUnchanged = false;
    }
    return formUnchanged;
  }

  errorExpiry() {
    let error = { show: false, msg: '' };
    if (this.producerSelected?.active) {
      let show = !this.form.controls['licenseExpiry'].valid;
      error.show = show;
      error.msg = show
        ? this.form.controls['licenseExpiry'].getError('date')
        : '';
    }
    return error;
  }

  handleExpiryDate(date: Date | undefined) {
    const formattedDate = date
      ? formatDateTime(date, this.shortDateFormat)
      : '';
    this.form.controls['licenseExpiry'].setValue(formattedDate);
    this.form.controls['licenseExpiry'].markAsDirty();
    this.form.controls['licenseNumber'].updateValueAndValidity();
  }

  handleSystemAccess() {
    this.hasSystemAccess = !this.hasSystemAccess;
  }

  isSystemAccessUpdated() {
    let isUpdated: boolean = false;
    if (this.brokerageProducerOldSelected.systemUserId) {
      isUpdated =
        this.hasSystemAccess !==
        this.brokerageProducerOldSelected.producerSystemUser?.['active'];
    } else {
      isUpdated = this.hasSystemAccess;
    }
    return isUpdated;
  }

  onImageSelected(event: any): void {
    this.alertService.clearAlerts(-1);
    const file = event.target?.files[0];
    if (file) {
      const fileSizeMB = file.size / (1024 * 1024);
      if (fileSizeMB > this.maxFileSizeMB) {
        this.clearFileInput();
        const errorMsg = this.translateService.instant(
          'brokerage.producer.error.imageSizeError',
        );
        let alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: errorMsg,
        };
        this.alertService.addAlert(alertData);
        return;
      }

      const fileExtension = this.getFileExtension(file);
      if (!this.allowedFormats.includes(fileExtension.toLowerCase())) {
        this.clearFileInput();
        const errorMsg = this.translateService.instant(
          'workFlow3.brokerageProfile.message.imageFormatErrorV2',
        );
        let alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: errorMsg,
        };
        this.alertService.addAlert(alertData);
        return;
      }

      const reader = new FileReader();
      reader.onload = (fileEvent) => {
        this.imageChangedEvent = fileEvent.target?.result;
      };
      reader.readAsDataURL(file);
      this.imageChangedEvent = event;
      const favDialog = document.getElementById(
        'myDialog',
      ) as HTMLDialogElement;
      favDialog.showModal();
    }
  }

  closeImageModal() {
    if (!this.producerProfileOldSelected?.headShotImage)
      this.croppedImage = this.previousImage ?? 'assets/img/default-photo.png';
    else
      this.croppedImage = this.producerProfileOldSelected?.headShotImage
        ? this.previousImage
        : 'assets/img/default-photo.png';
    this.clearFileInput();
    this.isFormDirty = !this.formUpdated ? false : true;
  }

  selectedImage() {
    if (this.croppedImage) this.previousImage = this.croppedImage;
    if (
      this.producerProfileOldSelected?.headShotImage != this.croppedImage ||
      this.isFormDirty
    ) {
      this.isFormDirty = true;
    } else {
      this.isFormDirty = false;
    }
  }

  clearFileInput() {
    this.fileInput.nativeElement.value = null;
  }

  getFileExtension(file: File): string {
    const filename = file.name;
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1) {
      return '';
    }
    return filename.substr(lastDotIndex).toLowerCase();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.showCropper = true;
  }

  loadImageFailed() {}

  handleProducerChangeStatus() {
    this.handleDiscardChanges();
    this.alertService.clearAlerts(-1);
    if (this.producerSelected?.active) {
      this.showBodySpinner = true;
      this.contentLoader = true;
      this.brokerageProducerService
        .DeleteBrokerageProducer(this.producerSelected?.id)
        .subscribe({
          next: (response: any) => {
            const msg = this.translateService.instant(
              'brokerage.producer.success.producerDeactivated',
            );
            let alertData = {
              type: 'success',
              headerText: getAlertHead('success'),
              bodyText: msg,
            };
            this.alertService.addAlert(alertData);
            this.contentLoader = false;
            this.producerSelected.active = false;

            if (
              this.producerSelected.systemUserId !== null &&
              this.brokerageProducerOldSelected.producerSystemUser?.['active']
            ) {
              this.systemUserId = this.producerSelected.systemUserId;
              this.systemUserService
                .DeleteSystemUser(this.systemUserId)
                .subscribe((response) => {
                  this.hasSystemAccess = false;
                  this.brokerageProducerOldSelected.producerSystemUser[
                    'active'
                  ] = false;
                  this.form['disable']({
                    emitEvent: false,
                  });
                });
            } else {
              const msg = this.translateService.instant(
                'brokerage.producer.success.producerDeactivated',
              );
              let alertData = {
                type: 'success',
                headerText: getAlertHead('success'),
                bodyText: msg,
              };
              this.alertService.addAlert(alertData);
            }
            this.showBodySpinner = false;
            this.contentLoader = false;
            let updateDetails = response?.data ?? {};
            this.updatedBy = updateDetails.updatedBy;
            this.updatedAt = formatDateTime(
              new Date(updateDetails.updatedDt),
              this.longDateTimeFormat,
            );
          },
          error: (error) => {
            const msg = getErrorMessage(error);
            if (![500].includes(error?.status)) {
              let alertData = {
                type: 'error',
                headerText: getAlertHead('error'),
                bodyText: msg,
              };
              this.alertService.addAlert(alertData);
            }
            this.showBodySpinner = false;
            this.contentLoader = false;
          },
        })
        .add(() => {
          this.form[this.producerSelected.active ? 'enable' : 'disable']({
            emitEvent: false,
          });
          this.viewPortScroller.scrollToPosition([0, 0]);
        });
    } else {
      this.showBodySpinner = true;
      this.contentLoader = true;
      this.brokerageProducerService
        .RestoreBrokerageProducer(this.producerSelected?.id)
        .subscribe({
          next: (response: any) => {
            if (this.producerSelected.systemUserId != null) {
              const msg = this.translateService.instant(
                'brokerage.producer.success.producerActivatedWithoutAccess',
              );
              let alertData = {
                type: 'success',
                headerText: getAlertHead('success'),
                bodyText: msg,
              };
              this.alertService.addAlert(alertData);
              this.contentLoader = false;
            } else {
              const msg = this.translateService.instant(
                'brokerage.producer.success.producerActivated',
              );
              let alertData = {
                type: 'success',
                headerText: getAlertHead('success'),
                bodyText: msg,
              };
              this.alertService.addAlert(alertData);
              this.contentLoader = false;
            }
            this.producerSelected.active = !this.producerSelected?.active;
            this.showBodySpinner = false;
            let updateDetails = response?.data ?? {};
            this.updatedBy = updateDetails.updatedBy;
            this.updatedAt = formatDateTime(
              new Date(updateDetails.updatedDt),
              this.longDateTimeFormat,
            );
          },
          error: (error) => {
            const msg = getErrorMessage(error);
            if (![500].includes(error?.status)) {
              let alertData = {
                type: 'error',
                headerText: getAlertHead('error'),
                bodyText: msg,
              };
              this.alertService.addAlert(alertData);
            }
            this.showBodySpinner = false;
            this.contentLoader = false;
          },
        })
        .add(() => {
          this.form[this.producerSelected.active ? 'enable' : 'disable']({
            emitEvent: false,
          });
          this.viewPortScroller.scrollToPosition([0, 0]);
        });
    }
  }

  async updateSystemUserStatus(userDetails: any) {
    let systemUserId = userDetails.systemUserId;

    if (this.isSystemAccessUpdated()) {
      if (!this.hasSystemAccess) {
        this.systemUserService.DeleteSystemUser(systemUserId).subscribe({
          next: (response: any) => {
            this.getBrokerageProducer();
            let alertData = {
              type: 'success',
              headerText: getAlertHead('success'),
              bodyText: 'system user deactivated successfully',
            };
            this.alertService.addAlert(alertData);
          },
          error: (error: any) => {
            let alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
          },
        });
      } else {
        this.systemUserService.RestoreSystemUser(systemUserId).subscribe({
          next: (response: any) => {
            this.getBrokerageProducer();
            let alertData = {
              type: 'success',
              headerText: getAlertHead('success'),
              bodyText: 'system user activated successfully',
            };
            this.alertService.addAlert(alertData);
          },
          error: (error: any) => {
            let alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
          },
        });
      }
    }
  }

  async createSystemUser(userDetails: any) {
    this.systemUserService.CreateSystemUser(userDetails).subscribe({
      next: (response) => {
        let alertData = {
          type: 'success',
          headerText: getAlertHead('success'),
          bodyText: 'New System User created',
        };
        this.alertService.addAlert(alertData);
        this.getBrokerageProducer();
      },
      error: (error) => {
        this.hasSystemAccess = false;
        let errorMessage = getErrorMessage(error);
        let alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: errorMessage,
        };
        this.alertService.addAlert(alertData);
      },
    });
  }

  handleSubmit(e: any) {
    this.alertService.clearAlerts(-1);
    if (!!!this.isFormDirty && !this.isSystemAccessUpdated()) {
      return;
    }

    this.contentLoader = true;
    this.submitted = true;
    let alertMessage = true;

    if (this.form.valid && this.form.enabled) {
      let spinnerCounter = 0;
      let imageUpdated = false;
      if (
        this.producerProfileOldSelected?.headShotImage != this.croppedImage &&
        this.producerProfileId
      ) {
        imageUpdated = true;
      }
      const producerModel = mapFormGroupToModel(
        this.form,
        this.customCommission,
        this.croppedImage,
        this.branchId,
      );
      this.showSpinner = true;
      spinnerCounter++;

      if (this.formUpdated || this.isSystemAccessUpdated()) {
        alertMessage = false;
        this.brokerageProducerService
          .UpdateBrokerageProducer(this.producerId, producerModel)
          .subscribe({
            next: async (response: any) => {
              let updateDetails = response?.data ?? {};
              if (alertMessage === false) {
                const successMsg = this.translateService.instant(
                  'brokerage.producer.success.producerUpdate',
                );
                let alertData = {
                  type: 'success',
                  headerText: getAlertHead('success'),
                  bodyText: successMsg,
                };
                this.alertService.addAlert(alertData);
              }

              let systemUserDetails = {
                systemUserType: this.systemUserTypeDetails[0].id,
                producerId: this.producerId,
                branchId: this.branchId,
                brokerId: this.brokerageId,
                loginEmail: producerModel.email,
                apiUser: true,
                roleId: this.rollId,
                systemUserId: this.brokerageProducerOldSelected.systemUserId,
                status: 'string',
              };

              if (this.brokerageProducerOldSelected.systemUserId) {
                // call for update system user
                await this.updateSystemUserStatus(systemUserDetails);
              } else if (this.hasSystemAccess) {
                // call for create system user
                await this.createSystemUser(systemUserDetails);
              }
              this.updateProducerForm(producerModel);

              // update the breadcrumbs if producer name is changed, need to add code for that.

              this.producerSelected.firstName = updateDetails.firstName;
              this.producerSelected.lastName = updateDetails.lastName;
              this.updatedBy = updateDetails.updatedBy;
              this.updatedAt = formatDateTime(
                new Date(updateDetails.updatedDt),
                this.longDateTimeFormat,
              );
              if (!this.brokerageProducerOldSelected.systemUserId) {
                this.getBrokerageProducer();
              }
            },
            error: (error) => {
              const errorMsg = getErrorMessage(error);
              if (![500].includes(error?.status)) {
                this.hasError = true;
                let alertData = {
                  type: 'error',
                  headerText: getAlertHead('error'),
                  bodyText: errorMsg,
                };
                this.alertService.addAlert(alertData);
              }
              this.contentLoader = false;
            },
          })
          .add(() => {
            this.showSpinner = false;
            this.contentLoader = imageUpdated ? true : false;
            this.viewPortScroller.scrollToPosition([0, 0]);
          });
        this.formUpdated = false;
      }

      if (imageUpdated) {
        if (
          this.producerProfileOldSelected?.headShotImage != this.croppedImage &&
          this.producerProfileId
        ) {
          this.showSpinner = true;
          spinnerCounter++;
          this.brokerageProducerService
            .updateProducerProfile(+this.producerProfileId, this.croppedImage)
            .subscribe({
              next: (response: any) => {
                if (alertMessage !== false) {
                  const successMsg = this.translateService.instant(
                    'brokerage.producer.success.profileUpdate',
                  );
                  let alertData = {
                    type: 'success',
                    headerText: getAlertHead('success'),
                    bodyText: successMsg,
                  };
                  this.alertService.addAlert(alertData);
                }

                let updateDetails = response?.data ?? {};
                this.updatedBy = updateDetails.updatedBy;
                this.updatedAt = formatDateTime(
                  new Date(updateDetails.updatedDt),
                  this.longDateTimeFormat,
                );

                if (this.producerProfileOldSelected)
                  this.producerProfileOldSelected.headShotImage =
                    this.croppedImage;
                this.unSubscriptionForms();
                this.resetFormChanges();
              },
              error: (error) => {
                const errorMsg = getErrorMessage(error);
                if (![500].includes(error?.status)) {
                  this.contentLoader = false;
                  let alertData = {
                    type: 'error',
                    headerText: getAlertHead('error'),
                    bodyText: errorMsg,
                  };
                  this.alertService.addAlert(alertData);
                  this.hasError = true;
                }
              },
            })
            .add(() => {
              this.showSpinner = false;
              this.contentLoader = false;
              this.viewPortScroller.scrollToPosition([0, 0]);
            });
        }
        this.isFormDirty = false;
      }

      if (this.hasError) {
        this.isFormDirty = true;
      }

      if (spinnerCounter == 0) this.showSpinner = false;
    } else {
      this.contentLoader = false;
    }
  }

  handleDiscardChanges() {
    if (this.isFormDirty) {
      this.initialProducerFormData();
    }
  }

  getBrokerageProducer() {
    this.brokerageProducerService
      .GetBrokerageProducer(this.producerId)
      .subscribe({
        next: (response) => {
          this.brokerageProducerOldSelected = { ...response.data };
          this.contentLoader = false;
        },
        error: (error) => {
          const errorMsg = getErrorMessage(error);
          if (![500].includes(error?.status)) {
            let alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: errorMsg,
            };
            this.alertService.addAlert(alertData);
          }
        },
      });
  }

  updateProducerForm(producerModel: ProducerModel) {
    this.unSubscriptionForms();
    this.isFormDirty = false;
    this.form.get('firstName').setValue(producerModel.firstName);
    this.form.get('lastName').setValue(producerModel.lastName);
    this.form.get('phone').setValue(producerModel.telephone);
    this.form.get('email').setValue(producerModel.email);
    this.form.get('customCommission').setValue(producerModel.customCommission);
    this.form.get('licenseNumber').setValue(producerModel.licenseNumber);
    this.form
      .get('licenseExpiry')
      .setValue(
        producerModel.licenseExpiry
          ? getFormatDate(
              producerModel.licenseExpiry,
              'YYYY-MM-DD',
              this.shortDateFormat,
            )
          : '',
      );
    this.resetFormChanges();
  }

  private resetFormChanges() {
    this.initialFormValue = this.form.value;
    this.formSubscription = this.form.valueChanges.subscribe(() => {
      this.isFormDirty = !this.isFormUnchanged(this.initialFormValue);
    });
  }

  handleExport() {
    let filter = this.makeFilter();

    // let insuredType = -1;
    filter['page'] = 1;
    filter['limit'] = this.pagination.totalRecords;
    // filter['insuredName'] = this.searchValue;
    // filter['quoteStatus'] = this.getQuoteStatus(this.activeQuoteStatus);

    let entryType = this.activeQuoteStatus.entryType;
    filter['entryType'] = entryType;

    this.policyDashboardService.PolicyExport(filter, this.sortBy).subscribe({
      next: (data: ArrayBuffer) => {
        excelFileDownload('ExportedData.xlsx', data);
      },
      error: (error) => {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error!'),
          bodyText: getErrorMessage(error),
        };
        this.alertService.addAlert(alertData);
      },
    });
  }

  handleFilter() {
    this.populateFilterOptions();
    this.createFilterFormGroup();
    this.showFilter = true;
    this.renderer.addClass(document.body, 'no-scroll');
  }
  handleFiltering(e) {
    this.filterSelectedOptions = {};
    this.showFilter = false;
    this.filterSelectedOptions = e;
    if (this.filterSelectedHasValue) {
      if (this.filterSelectedOptions.status.length > 0) {
        let fromNav = this.filterSelectedOptions.status.filter(
          (x) => x.fromNav,
        );
        if (fromNav.length > 0 && this.filterSelectedOptions.length > 1) {
          this.searchValue = '';
          this.insuredForm.get('searchKey').setValue('');
        }
      }
      if (this.filterSelectedHasValueExceptStatus) {
        this.searchValue = '';
        this.insuredForm.get('searchKey').setValue('');
      }
    }
    this.showFilter = false;
    this.renderer.removeClass(document.body, 'no-scroll');
    this.populateTransactionData({ page: 1, limit: 10, sort: this.sortBy });
  }

  handleFilterClear(e) {
    this.filterSelectedOptions = {};
    this.filterSelectedOptions = e;
    this.renderer.removeClass(document.body, 'no-scroll');
    this.populateTransactionData({ page: 1, limit: 10, sort: this.sortBy });
  }
  dynamicSearchFilter(e) {
    switch (e.item.selectedInputValue) {
      // case 'underwriter':
      //   this.searchUnderWriter(e.search);
      //   break;
      case 'premiumRange':
        break;
      case 'risks':
        break;
      case 'status':
        break;
      case 'createdDt':
        break;
      case 'insuredName':
        break;
      case 'effectiveDt':
        break;
      default:
    }
  }
  dynamicLoadMore(e) {
    switch (e.selectedInputValue) {
      // case 'underwriter':
      //   this.loadMoreUnderWriter();
      //   break;
      case 'premiumRange':
        break;
      case 'risks':
        break;
      case 'status':
        break;
      case 'createdDt':
        break;
      case 'insuredName':
        break;
      case 'effectiveDt':
        break;
      default:
    }
  }
  handleCloseFilterPopup(event) {
    this.showFilter = false;
  }
  filterSelectedHasValueExceptStatus() {
    let flag = 0;
    if (this.filterSelectedOptions) {
      if (this.filterSelectedOptions.risks) {
        flag += this.filterSelectedOptions.risks.length > 0 ? 1 : 0;
      }
      if (this.filterSelectedOptions.underwriter) {
        flag += this.filterSelectedOptions.underwriter.length > 0 ? 1 : 0;
      }
      if (this.filterSelectedOptions.insuredName) {
        flag += this.filterSelectedOptions.insuredName !== '' ? 1 : 0;
      }
      if (this.filterSelectedOptions.premiumRange) {
        if (
          this.filterSelectedOptions.premiumRange.from !== undefined &&
          this.filterSelectedOptions.premiumRange.from !== null &&
          this.filterSelectedOptions.premiumRange.from !== '' &&
          this.filterSelectedOptions.premiumRange.to !== undefined &&
          this.filterSelectedOptions.premiumRange.to !== null &&
          this.filterSelectedOptions.premiumRange.to !== ''
        ) {
          flag++;
        }
      }
      if (this.filterSelectedOptions.limitRange) {
        if (
          this.filterSelectedOptions.limitRange.from !== undefined &&
          this.filterSelectedOptions.limitRange.from !== null &&
          this.filterSelectedOptions.limitRange.from !== '' &&
          this.filterSelectedOptions.limitRange.to !== undefined &&
          this.filterSelectedOptions.limitRange.to !== null &&
          this.filterSelectedOptions.limitRange.to !== ''
        ) {
          flag++;
        }
      }
      if (this.filterSelectedOptions.createdDt) {
        if (
          this.filterSelectedOptions.createdDt.from !== '' &&
          this.filterSelectedOptions.createdDt.to !== ''
        ) {
          flag++;
        }
      }
    }
    if (flag > 0) {
      return true;
    } else {
      return false;
    }
  }
  filterSelectedHasValue() {
    let flag = 0;
    if (this.filterSelectedOptions) {
      if (this.filterSelectedOptions.risks) {
        flag += this.filterSelectedOptions.risks.length > 0 ? 1 : 0;
      }
      if (this.filterSelectedOptions.status) {
        flag += this.filterSelectedOptions.status.length > 0 ? 1 : 0;
      }
      if (this.filterSelectedOptions.underwriter) {
        flag += this.filterSelectedOptions.underwriter.length > 0 ? 1 : 0;
      }
      if (this.filterSelectedOptions.insuredName) {
        flag += this.filterSelectedOptions.insuredName !== '' ? 1 : 0;
      }
      if (this.filterSelectedOptions.premiumRange) {
        if (
          this.filterSelectedOptions.premiumRange.from !== undefined &&
          this.filterSelectedOptions.premiumRange.from !== null &&
          this.filterSelectedOptions.premiumRange.from !== '' &&
          this.filterSelectedOptions.premiumRange.to !== undefined &&
          this.filterSelectedOptions.premiumRange.to !== null &&
          this.filterSelectedOptions.premiumRange.to !== ''
        ) {
          flag++;
        }
      }
      if (this.filterSelectedOptions.limitRange) {
        if (
          this.filterSelectedOptions.limitRange.from !== undefined &&
          this.filterSelectedOptions.limitRange.from !== null &&
          this.filterSelectedOptions.limitRange.from !== '' &&
          this.filterSelectedOptions.limitRange.to !== undefined &&
          this.filterSelectedOptions.limitRange.to !== null &&
          this.filterSelectedOptions.limitRange.to !== ''
        ) {
          flag++;
        }
      }
      if (this.filterSelectedOptions.createdDt) {
        if (
          this.filterSelectedOptions.createdDt.from !== '' &&
          this.filterSelectedOptions.createdDt.to !== ''
        ) {
          flag++;
        }
      }
    }
    if (flag > 0) {
      return true;
    } else {
      return false;
    }
  }
  async populateFilterOptions() {
    if (this.filterSelectedHasValue()) {
      let defaultOptions = 0;
      let filteredOptions = this.filterInputOptions.map((option) => {
        const { value } = option;
        const selectedValue = this.filterSelectedOptions[value];
        if (option.type === 'dateRange' || option.type === 'dropdownRange') {
          if (
            selectedValue.from !== null &&
            selectedValue.from !== undefined &&
            selectedValue.from !== '' &&
            selectedValue.to !== null &&
            selectedValue.to !== undefined &&
            selectedValue.to !== ''
          ) {
            defaultOptions++;
            return { ...option, selectedValue, isDefault: true };
          }
        } else if (
          option.type === 'multiDropdown' ||
          option.type === 'multiSearch'
        ) {
          if (selectedValue && selectedValue.length > 0) {
            defaultOptions++;
            return { ...option, selectedValue, isDefault: true };
          }
        } else if (
          selectedValue !== null &&
          selectedValue !== undefined &&
          selectedValue !== ''
        ) {
          defaultOptions++;
          return { ...option, selectedValue, isDefault: true };
        }

        return { ...option, selectedValue, isDefault: false };
      });
      if (defaultOptions < 3) {
        filteredOptions = filteredOptions.map((option) => {
          if (defaultOptions < 3) {
            if (!option.isDefault) {
              defaultOptions++;
              return { ...option, isDefault: true };
            }
          }
          return { ...option };
        });
      }

      this.filterInputOptions = filteredOptions;
    } else {
      this.filterInputOptions = [
        // {
        //   label: 'Policy period',
        //   value: 'effectiveDt',
        //   type: 'dateRange',
        //   disabled: false,
        //   isDefault: true,
        //   selectedValue: '',
        //   isRequired: false,
        //   hasValidation: true,
        // },
        {
          label: 'Insured name',
          value: 'insuredName',
          type: 'text',
          disabled: false,
          selectedValue: '',
          isDefault: true,
          isRequired: false,
          hasValidation: false,
        },
        {
          label: 'Date created',
          value: 'createdDt',
          type: 'dateRange',
          disabled: false,
          isDefault: true,
          selectedValue: '',
          isRequired: false,
          hasValidation: true,
        },
        {
          label: 'Status',
          value: 'status',
          type: 'multiDropdown',
          disabled: false,
          isDefault: true,
          selectedValue: '',
          dropdownOptions: this.statusOptions,
          isRequired: false,
          hasValidation: false,
        },
        {
          label: 'Risks',
          value: 'risks',
          type: 'multiDropdown',
          disabled: false,
          isDefault: false,
          selectedValue: '',
          dropdownOptions: this.pdtOptions,
          isRequired: false,
          hasValidation: false,
        },
        {
          label: 'Annual premium',
          value: 'premiumRange',
          type: 'dropdownRange',
          disabled: false,
          isDefault: false,
          selectedValue: '',
          isRequired: false,
          hasValidation: true,
          dropdownOptions: this.premiumOptions,
          min: 0,
          max: 1000000,
          minMaxErrmsg: 'Values should be between the range of 0 to 1M',
        },
        {
          label: 'Limit',
          value: 'limitRange',
          type: 'dropdownRange',
          disabled: false,
          isDefault: false,
          selectedValue: '',
          isRequired: false,
          hasValidation: true,
          dropdownOptions: this.limitOptions,
          min: 0,
          max: 10000000,
          minMaxErrmsg: 'Values should be between the range of 0 to 10M',
        },
      ];
    }
  }
  createFilterFormGroup() {
    const formGroup = {};
    this.filterInputOptions.forEach((control) => {
      const isRequired = control.hasValidation ? Validators.required : '';
      let selectedValue;
      switch (control.type) {
        case 'text':
          selectedValue = control.selectedValue ? control.selectedValue : '';
          formGroup[control.value] = [selectedValue, isRequired];
          break;
        case 'number':
          selectedValue = control.selectedValue ? control.selectedValue : '';
          formGroup[control.value] = [selectedValue, isRequired];
          break;
        case 'dropdown':
          selectedValue = control.selectedValue ? control.selectedValue : '';
          formGroup[control.value] = [selectedValue, isRequired];
          break;
        case 'dateRange':
          selectedValue = control.selectedValue
            ? control.selectedValue
            : { from: '', to: '' };
          formGroup[control.value] = this.fb.group({
            from: [selectedValue.from],
            to: [selectedValue.to],
          });
          break;
        case 'dropdownRange':
          selectedValue = control.selectedValue
            ? control.selectedValue
            : { from: '', to: '' };
          formGroup[control.value] = this.fb.group({
            from: [selectedValue.from],
            to: [selectedValue.to],
          });
          break;
        case 'multiDropdown':
          selectedValue = control.selectedValue ? control.selectedValue : [];
          formGroup[control.value] = [selectedValue, isRequired];
          break;
        case 'multiSearch':
          selectedValue = control.selectedValue ? control.selectedValue : [];
          formGroup[control.value] = [selectedValue, isRequired];
          break;
        default:
          selectedValue = control.selectedValue ? control.selectedValue : '';
          formGroup[control.value] = [selectedValue, isRequired];
      }
    });
    this.filterForm = this.fb.group(formGroup);
  }
  private async getProductList() {
    return new Promise<any>(async (resolve, reject) => {
      this.riskRegionService.getAllRiskByUser(this.userID).subscribe((data) => {
        this.products = data.data.reduce((accumulator, value) => {
          return { ...accumulator, [value.name]: value };
        }, {});

        this.pdtOptions = data.data?.map((dataObj) => ({
          key: dataObj.name,
          value: dataObj.name,
          name: dataObj.name,
          disabled: false,
          id: dataObj.id,
        }));
      });
    });
  }
  private async fetchQuoteStatusLists() {
    this.statusOptions = [];

    return new Promise<any>(async (resolve, reject) => {
      if (this.activeQuoteStatus.entryType === EntryType.Policy) {
        this.domainsService.GetByDomainCode('POLICYSTATUS').subscribe({
          next: (data) => {
            let statusList = [];
            data.data.map((dataObj) => {
              if (
                dataObj.description !== 'QUOTE' &&
                dataObj.description !== 'QUOTE-CLOSED'
              ) {
                statusList = [
                  ...statusList,
                  {
                    key: dataObj.description,
                    value: dataObj.description,
                    name: dataObj.description,
                    status: getPolicyStatusTypeV2(dataObj.description),
                    seqId: dataObj.seqId,
                    id: dataObj.id,
                  },
                ];
              }
            });
            this.statusOptions = statusList;
            // this.statusOptions = [
            //   ...data.data.map((dataObj) => ({
            //     key: dataObj.description.toUpperCase(),
            //     value: dataObj.description.toUpperCase(),
            //     name: dataObj.description.toUpperCase(),
            //     status: getPolicyStatusTypeV2(dataObj.description),
            //     seqId: dataObj.seqId,
            //     id: dataObj.id,
            //     type: EntryType.Policy,
            //   })),
            //   ...this.statusOptions,
            // ];
            resolve(true);
          },
          error: (error) => {
            reject(false);
          },
        });
      } else if (this.activeQuoteStatus.entryType === EntryType.Quote) {
        this.domainsService.GetByDomainCode('QUOTESTATUS').subscribe({
          next: (data) => {
            this.statusOptions = [
              ...data.data.map((dataObj) => ({
                key: dataObj.description.toUpperCase(),
                value: dataObj.description.toUpperCase(),
                name: dataObj.description.toUpperCase(),
                status: getPolicyStatusTypeV2(dataObj.description),
                seqId: dataObj.seqId,
                id: dataObj.id,
                type: EntryType.Quote,
              })),
              ...this.statusOptions,
            ];
            resolve(true);
          },
          error: (error) => {
            reject(false);
          },
        });
      } else {
        resolve(true);
      }
    });
  }
  populatePremiumOptions() {
    const premiumOptions = [];
    for (let i = 0; i <= 10; i += 1) {
      premiumOptions.push({
        key: i * 100000,
        value: i * 100000,
        id: i,
      });
    }
    let length = premiumOptions.length;
    premiumOptions.push({
      key: 'Custom',
      subKey: '( Between 0 to 1M only. )',
      value: 'custom-amount',
      id: length + 1,
    });
    this.premiumOptions = premiumOptions;
  }
  populateLimitOptions() {
    return new Promise<any>(async (resolve, reject) => {
      this.confRiskLimitsService.GetAll().subscribe({
        next: (response) => {
          const limitOptions = response.data.map((dataObj, index) => ({
            key: formatAmountWithCurrency(dataObj.limit, this.currency),
            value: valInDigits(dataObj.limit),
            id: index + 1,
          }));
          limitOptions.push({
            key: 'Custom',
            value: 'custom-amount',
            subKey: '( Between 0 to 10M only. )',
            id: response.data?.length + 1,
          });
          limitOptions.sort((a, b) => a.value - b.value);
          this.limitOptions = limitOptions;
          resolve(true);
        },
        error: (error) => {
          const limitOptions = [
            {
              key: 'Custom',
              value: 'custom-amount',
              subKey: '( Between 0 to 10M only. )',
              id: 0,
            },
          ];
          this.limitOptions = limitOptions;
          reject(false);
        },
      });
    });
  }

  getFilteredValues() {
    let filteredOptions = [];
    let filterText = '';

    if (this.filterSelectedOptions) {
      if (this.filterSelectedOptions.insuredName) {
        filterText = this.filterSelectedOptions?.insuredName;
        filteredOptions.push({ key: 'insuredName', value: filterText });
      }
      if (this.filterSelectedOptions.status.length > 0) {
        let status = this.filterSelectedOptions.status;
        status.forEach((b) => {
          if (b.fromNav) {
            return;
          }
          filteredOptions.push({ key: 'status', value: b.name });
        });
      }
      if (this.filterSelectedOptions.risks) {
        const risks = this.filterSelectedOptions.risks;
        if (risks?.length > 0) {
          risks.forEach((uw) => {
            filteredOptions.push({ key: 'risks', value: uw.name });
          });
        }
      }
      if (this.filterSelectedOptions.createdDt) {
        const createDt = this.filterSelectedOptions.createdDt;
        let createRange = '';
        if (createDt.from && createDt.to) {
          const formattedFromDate = formatDateMoment(
            createDt.from,
            this.shortDateFormat,
          );
          const formattedToDate = formatDateMoment(
            createDt.to,
            this.shortDateFormat,
          );
          createRange = `${formattedFromDate} - ${formattedToDate}`;
          filteredOptions.push({ key: 'createdDt', value: createRange });
        }
      }
      if (this.filterSelectedOptions.premiumRange) {
        const premiumRange = this.filterSelectedOptions.premiumRange;
        let createRange = '';
        if (
          premiumRange.from !== undefined &&
          premiumRange.from !== null &&
          premiumRange.from !== '' &&
          premiumRange.to !== undefined &&
          premiumRange.to !== null &&
          premiumRange.to !== ''
        ) {
          const formattedFrom =
            this.premiumOptions.find((x) => x.id == premiumRange.from)?.value ??
            premiumRange.from;
          const formattedTo =
            this.premiumOptions.find((x) => x.id == premiumRange.to)?.value ??
            premiumRange.to;
          createRange = `${formatAmountWithCurrency(
            formattedFrom,
            this.currency,
          )} - ${formatAmountWithCurrency(formattedTo, this.currency)}`;
          filteredOptions.push({ key: 'premiumRange', value: createRange });
        }
      }
      if (this.filterSelectedOptions.limitRange) {
        const limitRange = this.filterSelectedOptions.limitRange;
        let createRange = '';
        if (
          limitRange.from !== undefined &&
          limitRange.from !== null &&
          limitRange.from !== '' &&
          limitRange.to !== undefined &&
          limitRange.to !== null &&
          limitRange.to !== ''
        ) {
          const formattedFrom =
            this.limitOptions.find((x) => x.id == limitRange.from)?.value ??
            limitRange.from;
          const formattedTo =
            this.limitOptions.find((x) => x.id == limitRange.to)?.value ??
            limitRange.to;
          createRange = `${this.currency}${formattedFrom} - ${this.currency}${formattedTo}`;
          filteredOptions.push({ key: 'limitRange', value: createRange });
        }
      }
      this.filteredOptions = filteredOptions;
    }

    return filteredOptions;
  }

  handleFilterOptnRemove(option) {
    if (this.filterSelectedOptions.hasOwnProperty(option.key)) {
      switch (option.key) {
        case 'premiumRange':
          this.filterSelectedOptions[option.key] = { from: '', to: '' };
          break;
        case 'limitRange':
          this.filterSelectedOptions[option.key] = { from: '', to: '' };
          break;
        case 'risks':
          let ind2 = this.filterSelectedOptions[option.key].findIndex(
            (x) => x.name === option.value,
          );
          if (ind2 >= 0) {
            this.filterSelectedOptions[option.key].splice(ind2, 1);
          }
          break;
        case 'status':
          let ind3 = this.filterSelectedOptions[option.key].findIndex(
            (x) => x.name === option.value,
          );
          if (ind3 >= 0) {
            this.filterSelectedOptions[option.key].splice(ind3, 1);
          }
          break;
        case 'createdDt':
          this.filterSelectedOptions[option.key] = { from: '', to: '' };
          break;
        case 'insuredName':
          this.filterSelectedOptions[option.key] = '';
          this.filterForm.get('insuredName').setValue('');
          break;
        default:
      }
    }

    this.populateTransactionData({
      page: 1,
      limit: 10,
      sort: this.sortBy,
    });
    this.getFilteredValues();

    // this.handleFiltering(undefined);
  }
  defaultFilterForm() {
    this.filterForm = this.fb.group({
      createdDt: this.fb.group({
        from: [''],
        to: [''],
      }),
      underwriter: [[]],
      status: [[]],
      risks: [[]],
      insuredName: [''],
      premiumRange: this.fb.group({
        from: [''],
        to: [''],
      }),
      limitRange: this.fb.group({
        from: [''],
        to: [''],
      }),
    });
  }
  handleGlobalSearch(object) {
    if (object.searchIn === 'Insured name') {
      let insuredType = 1;
      if (object?.type?.toLowerCase() == 'company') {
        insuredType = 2;
      }
      this.router.navigateByUrl(
        `dashboard/search/${insuredType}/${object.selectedSearch.value}/${object.selectedSearch.id}`,
        { skipLocationChange: true },
      );
    } else {
      if (this.isRestrictedRoleSubmission === true) {
        this.alertService.clearAlerts(-1);
        const alertData = {
          type: 'warn',
          headerText: getAlertHead('warning'),
          bodyText: this.translateService.instant(
            'common.error.noAccessToPageErrorMsg',
          ),
        };
        this.alertService.addAlert(alertData);
        return;
      }
      this.router.navigateByUrl(
        `dashboard/workflow3/policies/${object.selectedSearch.value}`,
        { skipLocationChange: true },
      );
    }
  }
}
