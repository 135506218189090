<div class="history-container">
  <ng-container
    *ngIf="
      isPolicy ? showModalSpinner || pastPoliciesLoading : showModalSpinner
    "
  >
    <div class="loader-wrapper">
      <div class="modal-body-spinner">
        <boxxds-spinner
          [containerStyle]="{
            width: '112px',
            height: '112px',
            '--b': '15px'
          }"
        ></boxxds-spinner>
      </div>
    </div>
  </ng-container>
  <div
    class="history-tab"
    *ngIf="
      isPolicy ? !showModalSpinner && !pastPoliciesLoading : !showModalSpinner
    "
  >
    <ng-container *ngIf="isPolicy">
      <div class="renewal-history-head">
        <boxxds-body
          [text]="'Renewal history' | translate"
          [customStyle]="{
            color: '#475467',
            fontFamily: 'Gibson',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '20px'
          }"
        >
        </boxxds-body>
        <div class="past-policies">
          <ul *ngIf="pastPolicies.length > 0">
            <li *ngFor="let policy of pastPolicies">
              <boxxds-body
                [text]="policy.period"
                [customStyle]="{
                  color: '#667085',
                  fontFamily: 'Gibson',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '20px'
                }"
              >
              </boxxds-body>
            </li>
          </ul>
          <div class="no-records-holder" *ngIf="pastPolicies.length === 0">
            <div class="no-records-ellipse">
              <img src="assets/img/no-records.png" alt="" />
              <boxxds-body
                [text]="'common.noRecords' | translate"
                sz="md"
                [customStyle]="{ color: '#616162' }"
              >
              </boxxds-body>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <boxxds-body
      [text]="'Keep track of the the activities on this quote.' | translate"
      sz="lg"
      [customStyle]="{
        color: '#475467',
        fontFamily: 'Gibson',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '20px',
        paddingBottom: '18px',
        paddingTop: '16px',
        paddingLeft: '38px'
      }"
    >
    </boxxds-body>
    <div class="audit-details">
      <div
        class="d-flex"
        *ngFor="
          let data of auditDetails
            | paginate
              : {
                  id: 'activitylog-pagination',
                  itemsPerPage: itemsPerPage,
                  currentPage: currentPage,
                  totalItems: activityData?.pagination?.totalRecords
                };
          let i = index
        "
      >
        <div class="d-flex flex-column mr-2 timeline">
          <div class="circle"></div>
          <div class="dashed-line" *ngIf="i !== auditDetails.length - 1"></div>
        </div>
        <div class="activity">
          <boxxds-body
            [text]="data.date"
            sz="xxs"
            [customStyle]="{
              color: '#2D2D39',
              fontFamily: 'Gibson',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '24px',
              paddingBottom: '8px'
            }"
          >
          </boxxds-body>
          <boxxds-body
            [text]="
              getConnectionString(
                data.tableDomain ? data.tableDomain : data.table,
                data.type,
                data.user
              )
            "
            sz="sm"
            [customStyle]="{
              color: '#667085',
              paddingBottom: '4px',
              fontFamily: 'Gibson',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px'
            }"
          >
          </boxxds-body>
          <div
            class="change-holder"
            *ngIf="data?.type?.toLowerCase() === 'update'"
          >
            <div class="prev-value">
              {{
                getFilteredData(data?.changeFrom ?? "")
                  ? getFilteredData(data?.changeFrom ?? "")
                  : "None"
              }}
            </div>
            <img src="assets/img/arrow-left.png" alt="to" />
            <div class="new-value">
              {{ getFilteredData(data?.changeTo ?? "") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-footer" *ngIf="activityData.pagination.totalPages > 1">
      <pagination-template
        id="activitylog-pagination"
        #p="paginationApi"
        (pageChange)="populateActivityLog($event)"
      >
        <div class="pagination-container">
          <div
            class="previous-btn-section"
            [ngClass]="{ 'prev-btn-disabled': p.isFirstPage() }"
          >
            <button
              class="pagination-prev-btn"
              [attr.disabled]="p.isFirstPage() ? true : null"
              (keyup.enter)="p.previous()"
              (click)="!p.isFirstPage() && p.previous()"
              tabindex="0"
            >
              <img src="assets/img/arrow-right.svg" alt="next-arrow" />
              <a class="tbl-page-link" aria-label="Previous">Previous</a>
            </button>
          </div>
          <div class="page-number-section">
            <button
              *ngFor="let page of p.pages"
              [class.active]="p.getCurrent() === page.value"
              class="tbl-page-item"
              (keyup.enter)="p.setCurrent(page.value)"
              (click)="p.setCurrent(page.value)"
              tabindex="0"
            >
              <a
                [class.active]="p.getCurrent() === page.value"
                class="tbl-page-link"
              >
                {{ page.label }}
              </a>
            </button>
          </div>
          <div
            class="next-btn-section"
            [ngClass]="{ 'next-btn-disabled': p.isLastPage() }"
          >
            <button
              class="pagination-next-btn"
              [attr.disabled]="p.isLastPage() ? true : null"
              (keyup.enter)="p.next()"
              (click)="!p.isLastPage() && p.next()"
              tabindex="0"
            >
              <a class="tbl-page-link">Next</a>
              <img src="assets/img/arrow-right.svg" alt="next-arrow" />
            </button>
          </div>
        </div>
      </pagination-template>
    </div>
  </div>
</div>
