import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BoxxResponse } from '../entities/boxx-response';
import { Policy } from '../entities/policy';
import { BaseService } from './base-services';

@Injectable({
  providedIn: 'root',
})
export class OFACService extends BaseService<any> {
  constructor(http: HttpClient) {
    const baseurl = environment.msInsuredBaseUrl + environment.ofac;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
}
