<div class="quote-option">
  <label [for]="'quote' + idxOfQuote">
    <div class="select-holder">
      <ng-container
        *ngIf="
          this.permissionList[this.currentScreen] &&
          this.currentQuoteStatus !== 'quote-closed'
        "
      >
        <boxxds-radio
          [control]="'quote'"
          [items]="[
            {
              label: ('common.select' | translate),
              currentValue: !!quote.ui.optionSelected,
              value: true,
              id: 'quote' + idxOfQuote
            }
          ]"
          [form]="form"
          [formSubmitted]="false"
          (handleChange)="
            this.permissionList[this.currentScreen] &&
              handleQuoteSelect(quote?.products[0]?.optionNumber)
          "
          [class.noPointerEvents]="!this.permissionList[this.currentScreen]"
        ></boxxds-radio>
      </ng-container>
    </div>
    <div class="d-flex">
      <div
        class="box-option"
        [ngClass]="{ selected: !!quote.ui.optionSelected }"
        *ngIf="quote.ui.isQuoteBoxExpanded"
      >
        <button class="collapse-btn mr-xl-4 mr-lg-3" (click)="handleToggle()">
          <img src="assets/img/dashboard/quote/chevron-up.png" />
        </button>
        <div class="premium-container">
          <div class="hdr-quote">
            {{
              ("quoteCalculator.label.quoteOption" | translate) +
                " " +
                +quote?.products[0]?.optionNumber
            }}
          </div>
          <div class="premium-value" [ngClass]="{ 'ml-4': !showTotalAmount() }">
            {{
              !showTotalAmount()
                ? "-"
                : (getFinalPremium() | currency: currency : "symbol-narrow")
            }}
          </div>
          <div class="disclaimer">
            {{ "quoteOption.info.totalAmountIncludes" | translate }}
          </div>
        </div>
        <div class="divider"></div>
        <div
          class="product-holder d-flex"
          *ngFor="let product of quote.products; let i = index"
        >
          <div class="product px-lg-2 px-xl-4">
            <div class="hdr-product">{{ product.key }}</div>

            <div class="grid-container mt-2">
              <div class="grid-item">
                <div class="label">{{ "LIMIT" | translate }}</div>
                <boxxds-body
                  [text]="
                    product.limit
                      ? (product.limit
                        | currencyFormat: currency : 'symbol-narrow' : '1.0-0')
                      : ('0'
                        | currencyFormat: currency : 'symbol-narrow' : '1.0-0')
                  "
                  [customStyle]="{ textAlign: 'left' }"
                >
                </boxxds-body>
              </div>
              <div class="grid-item">
                <div class="label">{{ "PREMIUM" | translate }}</div>
                <boxxds-body
                  [text]="
                    product.premium
                      | currency: currency : 'symbol-narrow' : '1.0-0'
                  "
                  [customStyle]="{ textAlign: 'left' }"
                >
                </boxxds-body>
              </div>

              <div class="grid-item">
                <div class="label">DEDUCTIBLE</div>
                <boxxds-body
                  [text]="
                    isNumber(product.deductables)
                      ? (product.deductables
                        | currency: currency : 'symbol-narrow' : '1.0-0')
                      : product.deductables
                  "
                  [customStyle]="{ textAlign: 'left' }"
                >
                </boxxds-body>
              </div>
              <div class="grid-item">
                <div class="label">COMMISSIONS</div>
                <boxxds-body
                  [text]="product.brokerCommissionPerc?.toString() + '%'"
                  [customStyle]="{ textAlign: 'left' }"
                >
                </boxxds-body>
              </div>
            </div>
            <div class="grid-container mt-2">
              <button
                class="grid-item sublimit d-flex"
                tabindex="0"
                (click)="handleSublimitAction(product.quoteOptionId, product)"
                (mouseout)="
                  sublimitIconSrc =
                    'assets/img/dashboard/quote/lifecycle/arrow-right-circle.png'
                "
                (mouseover)="
                  sublimitIconSrc =
                    'assets/img/dashboard/quote/lifecycle/arrow-right-circle-hover.png'
                "
                (focus)="
                  changeIconSrc(
                    'assets/img/dashboard/quote/lifecycle/arrow-right-circle-focus.png'
                  )
                "
                (blur)="
                  changeIconSrc(
                    'assets/img/dashboard/quote/lifecycle/arrow-right-circle.png'
                  )
                "
              >
                <div class="label">
                  {{ "common.sublimits" | translate | uppercase }}
                </div>
                <img [src]="sublimitIconSrc" width="12px" height="12px" />
              </button>
            </div>
          </div>
          <div class="divider"></div>
        </div>
        <div class="coverage-dates pl-xl-4 pl-lg-2">
          <boxxds-body
            [text]="'Coverage dates:'"
            [customStyle]="{
              textAlign: 'left',
              color: '#333334',
              whiteSpace: 'nowrap'
            }"
          >
          </boxxds-body>
          <div class="coverage-date">
            {{ (format(startDate) | date: "MMM.dd/yyyy") + " -" }}
          </div>
          <div class="coverage-date">
            {{ format(expiryDate) | date: "MMM.dd/yyyy" }}
          </div>
        </div>
      </div>
      <div
        class="box-collapsed"
        [ngClass]="{ selected: !!quote.ui.optionSelected }"
        *ngIf="!quote.ui.isQuoteBoxExpanded"
      >
        <button class="expand-btn" (click)="handleToggle()">
          <img src="assets/img/dashboard/quote/chevron-up.png" />
        </button>
        <div class="hdr-quote">
          {{
            ("quoteCalculator.label.quoteOption" | translate) +
              " " +
              quote?.products[0]?.optionNumber
          }}
        </div>
        <div class="premium-value" [ngClass]="{ 'ml-4': !showTotalAmount() }">
          {{
            !showTotalAmount()
              ? "-"
              : (getFinalPremium() | currency: currency : "symbol-narrow")
          }}
        </div>
        <div
          class="product-holder d-flex"
          *ngFor="let product of quote.products; let i = index"
        >
          <div class="hdr-product">{{ product.key }}</div>
        </div>
      </div>

      <div
        class="d-flex flex-column"
        *ngIf="
          this.permissionList[this.currentScreen] &&
          this.currentQuoteStatus !== 'quote-closed'
        "
      >
        <button class="trash-btn" (click)="handleDelete()">
          <img src="assets/img/dashboard/quote/trash.png" />
        </button>
        <button class="trash-btn mt-3" (click)="handleQuoteOptnEdit()">
          <img src="assets/img/calc-edit.png" />
        </button>
      </div>
    </div>
  </label>
  <div class="compare-row">
    <boxxds-checkbox
      [options]="[
        { name: 'Compare', value: 'true', checked: quote.ui.selectedForCompare }
      ]"
      [lblStyle]="{ color: '#56565C' }"
      (handleCheckbox)="handleCheckbox($event, '')"
      [containerStyle]="{ display: 'flex' }"
      [id]="'compare' + idxOfQuote"
    >
    </boxxds-checkbox>
    <button
      *ngIf="!isDuplicateDisabled"
      class="duplicate-btn"
      (click)="handleDuplicate()"
    >
      duplicate below
    </button>
  </div>
</div>
