import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import { Commission } from 'src/app/entities/commission';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-update-commission',
  templateUrl: './update-commission.component.html',
  styleUrls: ['./update-commission.component.less'],
})
export class UpdateCommissionComponent implements OnInit {
  @Input() commission: Commission;

  @Output() handleCancelEdit = new EventEmitter();
  @Output() handleUpdating = new EventEmitter<any>();

  formCommission: FormGroup;
  submitted: boolean = false;
  requiredErrMsg: string = 'This field is required';
  maxPercentage: number = 0;
  minPercentage: number = 0;
  defaultPercentage: number = 0;
  emptyValue = '';
  products = [];
  regions = [];
  productId: string = '';
  regionId: string = '';
  minRangePercentage: number = 1;
  maxRangePercentage: number = 50;

  constructor(
    private fb: FormBuilder,
    private riskRegionService: RiskRegionService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.formCommission = this.fb.group({
      productId: ['', [Validators.required]],
      regionId: ['', [Validators.required]],
      maxPercentage: [
        0,
        [
          Validators.max(this.maxRangePercentage),
          Validators.min(this.minRangePercentage),
        ],
      ],
      minPercentage: [
        0,
        [
          (control: AbstractControl) =>
            Validators.max(this.maxPercentage)(control),
          Validators.max(this.maxRangePercentage),
          Validators.min(this.minRangePercentage),
        ],
      ],
      defaultPercentage: [
        0,
        [
          Validators.max(this.maxPercentage),
          Validators.min(this.minPercentage),
        ],
      ],
    });
    this.maxPercentage = Number(
      (
        (this.commission.commissionMax ? this.commission.commissionMax : 0) *
        100
      ).toFixed(2),
    );
    this.minPercentage = Number(
      (
        (this.commission.commissionMin ? this.commission.commissionMin : 0) *
        100
      ).toFixed(2),
    );
    this.defaultPercentage = Number(
      (
        (this.commission.commissionDefault
          ? this.commission.commissionDefault
          : 0) * 100
      ).toFixed(2),
    );

    this.productId = this.commission.riskRegion.risk.name;
    this.regionId = this.commission.riskRegion.region.name;
    this.initLoadStore();
    this.initValuesForm();
  }

  private initValuesForm() {
    this.formCommission
      .get('productId')
      .setValue(this.commission.riskRegion.risk.id);
    this.formCommission
      .get('regionId')
      .setValue(this.commission.riskRegion.region.name);
    this.formCommission
      .get('maxPercentage')
      .setValue(this.commission.commissionMax);
    this.formCommission
      .get('minPercentage')
      .setValue(this.commission.commissionMin);
    this.formCommission
      .get('defaultPercentage')
      .setValue(this.commission.commissionDefault);
  }

  private initLoadStore() {
    this.riskRegionService.getAllRisk().subscribe({
      next: (response) => {
        this.products = response.data.map((product) => {
          return { value: product.name, key: product.name, id: product.id };
        });
      },
    });
    this.getRegionByProductId(this.commission.riskRegion.risk.id);
  }
  private getRegionByProductId(productId) {
    this.riskRegionService.getRiskRegion(productId).subscribe({
      next: (response) => {
        this.regions = response.data.map((item) => {
          return {
            value: item.region.name,
            key: item.region.name,
            id: item.id,
          };
        });
      },
    });
  }

  onChangeMinPersentage(value: number) {
    this.minPercentage = value;
    this.handleDefaultPercentageValidate(this.defaultPercentage);
  }

  onChangeMaxPersentage(value) {
    this.maxPercentage = value;
    this.handleDefaultPercentageValidate(this.defaultPercentage);
  }
  onChangeDefaultPercentage(value: number) {
    this.defaultPercentage = value;
  }
  handleDefaultPercentageValidate(event) {
    const defaultPercentage = event?.target?.value
      ? event?.target?.value
      : event;
    const defaultPercentageControl =
      this.formCommission.controls['defaultPercentage'];

    if (
      defaultPercentage >= this.minPercentage &&
      defaultPercentage <= this.maxPercentage
    ) {
      this.formCommission.controls['defaultPercentage'].setErrors(null);
    } else {
      this.formCommission.controls['defaultPercentage'].setErrors({
        message: this.translate.instant('error.defaultValueValidationErrMsg'),
      });
    }
    defaultPercentageControl.updateValueAndValidity();
  }

  handleOnSave() {
    this.submitted = true;

    this.formCommission.get('maxPercentage').setValue(this.maxPercentage);
    this.formCommission.get('minPercentage').setValue(this.minPercentage);
    this.formCommission
      .get('defaultPercentage')
      .setValue(this.defaultPercentage);

    if (this.formCommission.controls['maxPercentage'].hasError('max')) {
      this.formCommission.controls['maxPercentage'].setErrors({
        message: this.translate.instant('error.maxValueValidationErrMsg'),
      });
    }

    if (this.formCommission.controls['maxPercentage'].hasError('min')) {
      this.formCommission.controls['maxPercentage'].setErrors({
        message: this.translate.instant('error.minValueValidationErrMsg'),
      });
    }

    if (this.formCommission.controls['minPercentage'].hasError('min')) {
      this.formCommission.controls['minPercentage'].setErrors({
        message: this.translate.instant('error.minValueValidationErrMsg'),
      });
    }

    if (this.formCommission.controls['minPercentage'].hasError('max')) {
      this.formCommission.controls['minPercentage'].setErrors({
        message: this.translate.instant('error.minValueGreaterErrMsg'),
      });
    }

    if (
      this.formCommission.controls['defaultPercentage'].value &&
      this.formCommission.controls['defaultPercentage'].value >=
        this.formCommission.controls['minPercentage'].value &&
      this.formCommission.controls['defaultPercentage'].value <=
        this.formCommission.controls['maxPercentage'].value
    ) {
      this.formCommission.controls['defaultPercentage'].setErrors(null);
    } else {
      this.formCommission.controls['defaultPercentage'].setErrors({
        message: this.translate.instant('error.defaultValueValidationErrMsg'),
      });
    }
    if (this.formCommission.valid) {
      const commission: any = {
        id: this.commission.id,
        riskRegionId: this.regions.find((item) => item.value === this.regionId)
          .id,
        commissionMax: Number(this.maxPercentage) / 100,
        commissionMin: Number(this.minPercentage) / 100,
        commissionDefault: Number(this.defaultPercentage) / 100 ?? 0,
        active: this.commission.active,
      };
      this.handleUpdating.emit(commission);
    }
  }

  handleOnCancel() {
    this.handleCancelEdit.emit(this.commission);
  }

  handleOnChangeProductId(event: string) {
    const productSelected = this.products.find((item) => item.value === event);
    this.formCommission.get('regionId').setValue('');
    this.regionId = '';
    this.productId = event;
    this.getRegionByProductId(productSelected.id);
  }

  handleOnChangeRegionId(event: string) {
    this.regionId = event;
  }
}
