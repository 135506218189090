<div
  class="popup"
  [style.left.px]="left"
  [style.top.px]="top"
  [ngStyle]="popupContainerStyle"
>
  <p>
    <span class="popupTitle" [ngStyle]="titleStyle" [innerHTML]="title"></span>
    <span
      class="popupContain"
      [ngStyle]="contentStyle"
      [innerHTML]="content"
    ></span>
  </p>
  <!-- <button (click)="closePopup()">Close</button> -->
</div>
