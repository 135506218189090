<div class="coverage-panel">
  <div class="panel-title">{{ "coverageAndSublimit" | translate }}</div>
  <div class="coverage-container">
    <div class="coverage-scroll-container" *ngIf="coverageList" id="productId">
      <div
        class="coverage-section col-lg-12"
        *ngFor="let coverageItem of coverageList; let coverageIndex = index"
      >
        <div class="coverage-item">
          <button class="toggle" tab-index="0" type="button">
            <span
              class="toggle-menu"
              [ngClass]="{ active: coverageItem.isIncluded }"
              (click)="onToggleClick(coverageIndex, true)"
              id="coverageItem.id"
              >{{ "Include" | translate }}</span
            >
            <span
              class="toggle-menu"
              [ngClass]="{ active: !coverageItem.isIncluded }"
              (click)="onToggleClick(coverageIndex, false)"
              id="coverageItem.id"
              >{{ "Exclude" | translate }}</span
            >
          </button>
        </div>
        <div class="coverage-item col-lg-4">
          <div class="coverage-title">{{ coverageItem.coverageName }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
