<div class="custom-modal">
  <div class="modal-content">
    <div class="close-btn">
      <button (click)="closeHandler()">
        <img src="assets/img/dashboard/card/close.png" />Close
      </button>
    </div>
    <div class="content">
      <div class="d-flex flex-column align-items-center">
        <img
          src="assets/img/alert-triangle.jpg"
          width="66px"
          height="66px"
          alt="alert"
        />
        <boxxds-body
          sz="md"
          [text]="'policy.policySlideOut.deleteConfirmationTitle' | translate"
          [customStyle]="{ color: '#FE802C', marginTop: '12px' }"
        ></boxxds-body>
      </div>
      <div class="details">
        <boxxds-body
          sz="xs"
          [text]="
            ('common.fileName' | translate) +
              ': ' +
              details?.['documentName'] ?? ''
          "
          [title]="details?.['documentName'] ?? ''"
          [customStyle]="{
            color: '#616162',
            maxWidth: '400px',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }"
        ></boxxds-body>
        <boxxds-body
          sz="xs"
          [text]="
            ('common.createdBy' | translate) + ': ' + details?.['createdBy'] ??
            ''
          "
          [customStyle]="{ color: '#616162' }"
        ></boxxds-body>
        <boxxds-body
          sz="xs"
          [text]="
            ('common.dateCreated' | translate) +
              ': ' +
              details?.['dateCreated'] ?? ''
          "
          [customStyle]="{ color: '#616162' }"
        ></boxxds-body>
        <boxxds-body
          *ngIf="details['updatedBy'] && details['dateUpdated']"
          sz="xs"
          [text]="
            ('common.updatedBy' | translate) + ': ' + details?.['updatedBy'] ??
            ''
          "
          [customStyle]="{ color: '#616162' }"
        ></boxxds-body>
        <boxxds-body
          *ngIf="details['updatedBy'] && details['dateUpdated']"
          sz="xs"
          [text]="
            ('common.dateUpdated' | translate) +
              ': ' +
              details?.['dateUpdated'] ?? ''
          "
          [customStyle]="{ color: '#616162' }"
        ></boxxds-body>
      </div>
      <boxxds-button
        buttonText="Confirm"
        [type]="'submitted'"
        [btnStyle]="{ width: '107px', marginLeft: 'auto', marginTop: '40px' }"
        [isDisabled]="isConfirmDisabled"
        [class.noPointerEvents]="isConfirmDisabled"
        (handleClick)="!isConfirmDisabled && actionConfirmHandler()"
      >
      </boxxds-button>
    </div>
  </div>
</div>
