import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Pagination } from 'src/app/entities/boxx-response';
import { AlertService } from 'src/app/services/alert.service';
import { BrokerageBranchTeamsService } from 'src/app/services/brokerage-branch/brokerage-branch-teams.service';
import { getAlertHead, getErrorMessage } from 'src/app/utils/utils';

interface teamsFilter {
  brokerageBranchId: number;
  name: string;
  page: number;
  limit: number;
  sort: string;
}

@Component({
  selector: 'app-branch-teams',
  templateUrl: './branch-teams.component.html',
  styleUrls: ['./branch-teams.component.less'],
})
export class BranchTeamsComponent {
  @Input() branchId: number;
  @Input() isActive: boolean = false;

  filterForm: FormGroup;
  tableData: { [key: string]: any }[] = [];
  tableColumnsWithSortKey: { [key: string]: string } = {
    Name: 'name',
    '# of Producers': 'numberOfProducers',
  };
  tableHeaders: string[] = ['Name', '# of Producers', 'Producers'];
  tableColumnsExcluded: string[] = ['id', 'AllBranchTeamList'];
  tableColumnsExcludedSort: string[] = ['Producers'];
  noDataMessage: string = 'No teams to display';
  isLoading: boolean = true;
  pagination: Pagination;
  filters: teamsFilter;
  showAddBranchTeamPopup: boolean = false;
  branchTeamSubmitted: boolean = false;
  addBranchTeamBtnClicked: boolean = false;
  form: FormGroup;
  debounceTimer: any;

  constructor(
    private fb: FormBuilder,
    public brokerageBranchTeamsService: BrokerageBranchTeamsService,
    private alertService: AlertService,
    private translateService: TranslateService,
  ) {
    this.filterForm = this.fb.group({
      teamName: [''],
    });
    this.form = this.fb.group({
      branchTeamName: [''],
    });
  }

  ngOnInit() {
    this.getData();
  }

  getProducersList(data: { [key: string]: any }[]): string[] {
    let producersList = [];
    if (data.length === 0) {
      return producersList;
    }
    data.forEach((element) => {
      producersList.push(
        `${element?.['brokerageProducer']?.firstName ?? ''} ${
          element?.['brokerageProducer']?.lastName ?? ''
        }`,
      );
    });
    return producersList;
  }

  makeFilter(filterParams: { [key: string]: any } = {}) {
    this.filters = {
      brokerageBranchId: this.branchId,
      name: filterParams?.['name'] ?? '',
      page: filterParams?.['page'] ?? 1,
      limit: filterParams?.['limit'] ?? 10,
      sort: filterParams?.['sort']
        ? `${filterParams['sort']},id:desc`
        : 'id:desc',
    };
  }

  getData(filter: { [key: string]: any } = {}) {
    this.isLoading = true;
    this.makeFilter(filter);
    this.brokerageBranchTeamsService
      .getBranchTeamsWithFilter(this.filters)
      .subscribe({
        next: (data) => {
          let tempTableData = data.data.map((team) => {
            let producers = this.getProducersList(
              team?.BrokerageProducerTeams ?? [],
            );
            return {
              id: team.id,
              Name: team?.name ?? '',
              '# of Producers': producers.length,
              Producers: producers,
              AllBranchTeamList: data.data ?? [],
            };
          });
          this.tableData = tempTableData;
          this.pagination = data.pagination;
          this.isLoading = false;
        },
        error: (error) => {
          console.log(error);
          this.isLoading = false;
        },
      });
  }

  handleSearch(event: any) {
    let filterParams = {
      name: event.target.value.trim(),
    };
    if (filterParams.name === '') {
      delete filterParams['name'];
    }
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      this.getData(filterParams);
    }, 500);
  }

  getTableDataOnSort(event: any) {
    let filterParams = {
      page: event.page,
      sort: `${this.tableColumnsWithSortKey[event.sortColumn]}:${
        event.sortOrder
      }`,
    };
    if (
      !event.sortColumn ||
      event.sortColumn === '' ||
      !event.sortOrder ||
      event.sortOrder === ''
    ) {
      delete filterParams['sort'];
    }

    this.getData(filterParams);
  }

  getTableDataOnRowClick(event: any) {}

  handleAddTeamClick(event: any) {
    this.showAddBranchTeamPopup = true;
  }
  hideAddTeamPopup() {
    this.showAddBranchTeamPopup = false;
    this.clearBranchTeamPopupdata();
  }

  clearBranchTeamPopupdata() {
    this.form.controls['branchTeamName'].setValue('');
    this.branchTeamSubmitted = false;
    this.addBranchTeamBtnClicked = false;
  }

  handleAddBranchTeam() {
    this.branchTeamSubmitted = true;
    this.addBranchTeamBtnClicked = true;
    const teamName = this.form.controls['branchTeamName'].value;
    if (teamName?.trim()?.length > 2) {
      const teamArray = [];
      teamArray.push(teamName);
      this.brokerageBranchTeamsService
        .addBranchTeams({
          brokerageBranchId: +this.branchId,
          teamNames: teamArray,
        })
        .subscribe({
          next: (response) => {
            this.getData();
            const alertData = {
              type: 'success',
              headerText: 'success!',
              bodyText: 'brokerage.branch.success.updateMessage',
            };
            this.alertService.clearAlerts(-1);
            this.alertService.addAlert(alertData);
          },
          error: (error) => {
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
          },
        });
    } else {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: this.translateService.instant(
          'brokerage.producer.error.minimum3CharErrMsg',
        ),
      };
      this.alertService.addAlert(alertData);
      return;
    }
  }
  handleUpdate({ name, index }) {
    this.alertService.clearAlerts(-1);
    this.isLoading = true;
    const teamId = this.tableData[index]['id'];
    let data = {
      brokerageBranchId: +this.branchId,
      teams: [{ id: teamId, name: name }],
    };
    this.brokerageBranchTeamsService.updateBranchTeams(data).subscribe({
      next: (data) => {
        let tempTableData = [...this.tableData];

        tempTableData[index] = {
          ...tempTableData[index],
          Name: data.data?.[0]?.name ?? '',
        };
        this.tableData = tempTableData;
        this.isLoading = false;
        const alertData = {
          type: 'success',
          headerText: getAlertHead('success'),
          bodyText: 'workFlow3.branchProfile.success.teamUpdateSuccess',
        };
        this.alertService.addAlert(alertData);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
      },
    });
  }

  pageChanged(event: any) {
    let filterParams = {
      page: event.page,
      sort: `${this.tableColumnsWithSortKey[event.sortColumn]}:${
        event.sortOrder
      }`,
    };
    if (
      !event.sortColumn ||
      event.sortColumn === '' ||
      !event.sortOrder ||
      event.sortOrder === ''
    ) {
      delete filterParams['sort'];
    }

    this.getData(filterParams);
  }
}
