<div class="holder">
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!showBodySpinner">
    <app-dynamic-filter-v2-modal
      *ngIf="showFilter"
      (handleClose)="handleCloseFilterPopup($event)"
      [filterInputOptions]="filterInputOptions"
      [filterFormGroup]="filterForm"
      [filterTitle]="'Filter Quote'"
      [filterTitleDescription]="
        'Apply filters to find the quote you are looking for.'
      "
      (handleSubmit)="handleFiltering($event)"
      (handleSearch)="dynamicSearchFilter($event)"
      (handleLoadMore)="dynamicLoadMore($event)"
      (handleFilterClear)="handleFilterClear($event)"
    >
    </app-dynamic-filter-v2-modal>
    <div class="header">
      <div class="header-content">
        <div class="heading">
          <div class="d-flex align-items-center">
            <boxxds-heading
              [sz]="'5'"
              [text]="'Quote directory' | translate"
              [customStyle]="{
                color: '#292933',
                fontFamily: 'Gibson',
                fontSize: '30px',
                fontWeight: '600',
                lineHeight: '38px'
              }"
            >
            </boxxds-heading>
          </div>
        </div>
        <ng-container
          *ngIf="
            this.permissionList[this.currentScreen] &&
            this.permissionList['Quote']
          "
        >
          <boxxds-button-v2
            [size]="'lg'"
            [buttonText]="startQuoteBtnTxt"
            [type]="'submitted'"
            [btnClass]="'primary'"
            class="start-quote"
            (handleClick)="handleStartNewQuote()"
            [btnStyle]="{ marginTop: '14pxs' }"
          >
          </boxxds-button-v2>
        </ng-container>
      </div>
    </div>
    <boxxds-alert
      (handleCloseEvent)="handleNotificationAlertClose()"
      *ngIf="notificationAlert?.show"
      [type]="notificationAlert?.type"
      [headerText]="notificationAlert?.headerText | translate"
      [bodyText]="notificationAlert?.bodyText | translate"
    >
    </boxxds-alert>
    <div class="content-holder">
      <div class="content">
        <div class="d-flex justify-content-between">
          <div class="sub-nav">
            <ul>
              <li *ngFor="let nav of subNavData; let i = index">
                <button
                  [ngClass]="{ active: nav.active }"
                  (click)="handleNav(i)"
                >
                  {{ nav.name }}
                </button>
              </li>
            </ul>
          </div>
          <div></div>
        </div>
      </div>
      <div class="tbl-container">
        <div class="table-header-container">
          <div class="table-header-header">
            <h4 *ngIf="insuredSearchFilterOn" class="header-text">
              Search results: {{ searchQuery }}
              <span class="total-records">
                {{ totalDataCount + " " + "records" }}
              </span>
            </h4>
            <h4 *ngIf="!insuredSearchFilterOn" class="header-text">
              List of all the Quotes
            </h4>
          </div>
          <div>
            <div class="filter-container d-flex align-items-center">
              <div class="filter-optns-container">
                <div
                  class="filter-optn"
                  *ngFor="let filter of getFilteredValues()"
                >
                  <div class="option-name">{{ filter.value }}</div>
                  <button
                    type="button"
                    aria-label="close"
                    class="close-btn"
                    (click)="handleFilterOptnRemove(filter)"
                  >
                    <img src="assets/img/dashboard/quote/remove.png" />
                  </button>
                </div>
              </div>
              <boxxds-textinput-v2
                [form]="filterForm"
                [placeholder]="
                  ('workFlow3.components.shared.placeholder.search'
                    | translate) + ' name'
                "
                [control]="'insuredName'"
                [id]="'search'"
                [leftIcon]="'assets/img/workflow3/sidemenu/search.png'"
                [width]="'200px'"
                [maxLength]="'40'"
                (handleKeyUp)="onInsuredSearch($event)"
              >
              </boxxds-textinput-v2>
              <boxxds-button-v2
                sz="sm"
                [isBtnActive]="showFilter"
                [btnClass]="'secondary gray'"
                [buttonText]="''"
                [leftImage]="'filter-funnel-02'"
                [type]="'submitted'"
                [btnStyle]="{
                  width: '36px',
                  margin: '8px 0px 0 16px',
                  height: '44px'
                }"
                (handleClick)="handleFilter()"
              >
              </boxxds-button-v2>
              <boxxds-button-v2
                sz="sm"
                [isBtnActive]="showFilter"
                [btnClass]="'secondary gray'"
                [buttonText]="''"
                [leftImage]="'download-01'"
                [type]="'submitted'"
                [btnStyle]="{
                  width: '36px',
                  margin: '8px 0px 0 16px',
                  height: '44px'
                }"
                (handleClick)="handleExport()"
              >
              </boxxds-button-v2>
              <!-- <boxxds-button leftImage="download" btnType="unfilled" [buttonText]="'common.export' | translate | uppercase"
            [type]="'submitted'" [btnStyle]="{ width: '65px' }" (handleClick)="handleExport()">
          </boxxds-button> -->
            </div>
          </div>
        </div>
        <boxxds-table-v3
          [data]="tableData"
          [showSpinner]="showTblSpinner"
          [totalCount]="totalDataCount"
          (pageChanged)="pageChangeHandler($event)"
          class="table"
          [ngClass]="{
            'pointer-default': !this.permissionList[this.currentScreen]
          }"
          (sortUpClicked)="handleTableColSortUp($event)"
          (sortDownClicked)="handleTableColSortDown($event)"
          id="policy-table"
          [addSortTo]="addSortTo"
          [showStar]="true"
          [tableStyle]="{ marginBottom: '30px' }"
          [showBadgeFieldName]="'common.statusText' | translate"
          [reasonIconColumn]="'common.reasonsText' | translate"
          [showReasonIcon]="showReasonIcon"
          (starHandler)="
            this.permissionList[this.currentScreen] && starClickHandler($event)
          "
          [showEllipsis]="true"
          [mapStatusBadge]="statusMappingToType"
          (viewClickHandler)="quoteClickHandler($event)"
          [isRowClickable]="true"
          [activePageNumber]="pageNumber"
          [showStatusAsBadge]="true"
          [showViewButton]="showViewButton"
          (removeSortHandler)="removeColSort()"
          [showWithBorder]="true"
          [showWithBorderFieldName]="'Risks'"
          (ellipsisHandler)="handleEllipsisClick($event)"
        >
          <ng-template #actionButtonTemplate let-idx="idx">
            <div
              class="actions-button-popup"
              *ngIf="showActionButtons"
              (clickOutside)="hideActionsButtonTemplate()"
            >
              <button
                class="btn-indicated"
                [disabled]="!showIndicatedButton"
                (click)="
                  hideActionsButtonTemplate(); handleQuoteStatus(true, idx)
                "
              >
                {{ "common.status.indicated" | translate }}
              </button>
              <button
                class="btn-quoted"
                [disabled]="showIndicatedButton"
                (click)="
                  hideActionsButtonTemplate(); handleQuoteStatus(false, idx)
                "
              >
                {{ "common.status.quoted" | translate }}
              </button>
            </div>
          </ng-template>
        </boxxds-table-v3>
      </div>
    </div>
  </ng-container>
</div>
