export enum PolicyStatus {
  QUOTE = 'QUOTE',
  QUOTECLOSED = 'QUOTE-CLOSED',
  BOUND = 'BOUND',
  RENEWED = 'RENEWED',
  CANCELLED = 'CANCELLED',
  REINSTATED = 'REINSTATED',
  LAPSED = 'LAPSED',
}

export const PolicyMapping = {
  all: undefined,
  'new submissions': PolicyStatus.BOUND,
  'new business': PolicyStatus.BOUND,
  renewal: PolicyStatus.RENEWED,
  cancelled: PolicyStatus.CANCELLED,
  quote: PolicyStatus.QUOTE,
  'quote-closed': PolicyStatus.QUOTECLOSED,
  bound: PolicyStatus.BOUND,
  renewed: PolicyStatus.RENEWED,
  reinstated: PolicyStatus.REINSTATED,
  lapsed: PolicyStatus.LAPSED,
};

export const mapPolicy = (value: string): string => {
  let status;
  status = PolicyMapping[value.toLowerCase()];
  return status;
};

export enum BrokerageTypes {
  BROKERAGE = 'brokerage',
  BRANCH = 'branch',
  PRODUCER = 'producer',
}

export function getPolicyStatusType(status) {
  switch (status.toLowerCase()) {
    case 'new submissions':
    case 'reinstated':
      return 'default';
    case 'referral':
    case 'bound':
    case 'referral':
      return 'primary';
    case 'quote':
    case 'quoted':
      return 'secondary';
    case 'renewed':
    case 'renewal quote':
    case 'renewal app':
      return 'warning';
    case 'quote closed':
    case 'cancelled':
    case 'lapsed':
    case 'declined':
    case 'closed':
    case 'not taken up':
      return 'inactive';
  }
  return 'default';
}

export function getPolicyStatusTypeV2(status) {
  switch (status.toLowerCase()) {
    case 'new submissions':
    case 'submission':
    case 'reinstated':
    case 'bound':
    case 'renewed':
    case 'renewal quote':
    case 'renewal app':
    case 'renewalapp':
    case 'renewalquoted':
    case 'quote':
    case 'quoted':
    case 'endorsed':
      return 'success';
    case 'referral':
    case 'referred':
      return 'warning';
    case 'quote closed':
    case 'quote-closed':
    case 'cancelled':
    case 'declined':
    case 'closed':
    case 'expired':
    case 'lapsed':
      return 'error';
    case 'endrs-saved':
    case 'not taken up':
      return 'info';
    case 'indicated':
      return 'warning-type-2';
  }
  return 'default';
}
export enum QuotePolicyStatusV2 {
  Submission = 'Submission',
  Quoted = 'Quoted',
  RenewalApp = 'RenewalApp',
  RenewalQuoted = 'RenewalQuoted',
  Declined = 'Declined',
  Closed = 'Closed',
  NotTakenUp = 'Not Taken Up',
  Referral = 'Referral',
  Policy = 'Bound',
  QUOTE = 'Quote',
  QUOTECLOSED = 'Quote-Closed',
  BOUND = 'Bound',
  RENEWED = 'Renewed',
  CANCELLED = 'Cancelled',
  REINSTATED = 'Reinstated',
  LAPSED = 'Lapsed',
  UADECLINE = 'Submission warn',
  INDICATED = 'Indicated',
}

export const StatusMapping = {
  all: undefined,
  open: QuotePolicyStatusV2.Submission,
  quoted: QuotePolicyStatusV2.Quoted,
  declined: QuotePolicyStatusV2.Declined,
  closed: QuotePolicyStatusV2.Closed,
  submission: QuotePolicyStatusV2.Submission,
  referral: QuotePolicyStatusV2.Referral,
  renewalapp: QuotePolicyStatusV2.RenewalApp,
  renewalquoted: QuotePolicyStatusV2.RenewalQuoted,
  'not taken up': QuotePolicyStatusV2.NotTakenUp,
  'new submissions': QuotePolicyStatusV2.BOUND,
  'new business': QuotePolicyStatusV2.BOUND,
  renewal: QuotePolicyStatusV2.RENEWED,
  cancelled: QuotePolicyStatusV2.CANCELLED,
  quote: QuotePolicyStatusV2.QUOTE,
  'quote-closed': QuotePolicyStatusV2.QUOTECLOSED,
  bound: QuotePolicyStatusV2.BOUND,
  renewed: QuotePolicyStatusV2.RENEWED,
  reinstated: QuotePolicyStatusV2.REINSTATED,
  lapsed: QuotePolicyStatusV2.LAPSED,
  'ua decline': QuotePolicyStatusV2.UADECLINE,
  indicated: QuotePolicyStatusV2.INDICATED,
};
