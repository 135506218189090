import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, retry, catchError } from 'rxjs';
import { BoxxResponse } from '../entities/boxx-response';
import { BaseService } from './base-services';
import { UpdatePolicyLifeCycle } from '../entities/policy';
import { PolicyTRIAUpdate } from '../entities/policy';
import { Policy } from '../entities/policy';

@Injectable({
  providedIn: 'root',
})
export class PolicyRiskTrxQuoteFlowStatusService extends BaseService<any> {
  constructor(http: HttpClient) {
    const baseurl =
      environment.msPolicyBaseUrl + environment.policyRiskTrxQuoteFlowStatus;
    super(http, baseurl);
  }

  UpdateQuoteFlowStatus(reasonId: number, request: any): Observable<any> {
    const url = `${this.baseurl}${reasonId}`;

    return this.http
      .put<BoxxResponse<any>>(url, request, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
