import { Action } from '@ngrx/store';
import { BrokerageParentCompany } from 'src/app/entities/brokerage-parent-company';
import { BrokerageProfile } from 'src/app/entities/brokerage-profile';

export const SAVE_BROKERAGE_PROFILE = 'SAVE_BROKERAGE_PROFILE';
export const SAVE_BROKERAGE_PARENT_COMPANY = 'SAVE_BROKERAGE_PARENT_COMPANY';

export class saveBrokerageProfileAction implements Action {
  readonly type = SAVE_BROKERAGE_PROFILE;
  constructor(public payload: BrokerageProfile) {}
}

export class saveBrokerageParentCompany implements Action {
  readonly type = SAVE_BROKERAGE_PARENT_COMPANY;
  constructor(public payload: BrokerageParentCompany) {}
}

export type Actions = saveBrokerageProfileAction | saveBrokerageParentCompany;
