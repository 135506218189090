import { ViewportScroller } from '@angular/common';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  FINANCIAL_COVERAGE,
  FINANCIAL_COVERAGE_LIMIT,
  FINANCIAL_COVERAGE_RULE_MIN_DEDUCTIBLE,
} from 'src/app/constants/quote-constant';
import { QuoteOptionCoverageSublimitsService } from 'src/app/services/quote-option-coverage-sublimits-service';
import { QuoteOptionCoverageService } from 'src/app/services/quote-option-coverage.service';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { getLifecycleState } from 'src/app/store/lifecycle/lifecycle.select';
import { formatAmountWithCurrency, valInDigits } from 'src/app/utils/utils';
import { CANADA, UNITED_STATES } from 'src/app/constants/location-constant';

@Component({
  selector: 'app-modal-sublimit',
  templateUrl: './modal-sublimitSlideOut.component.html',
  styleUrls: ['./modal-sublimitSlideOut.component.less'],
})
export class ModalSublimitSlideOutComponent implements OnInit {
  @Input() showModal: boolean = false;
  @Input() coverageDetails;
  @Input() productDetails;
  @Input() title;
  @Input() insuredDetals;
  @Input() coverageEditables;
  @Input() coverageDeductibles;
  @Input() formDetails;
  @Input() limitOptionsForProducts;
  @Input() deductibleOptions;
  @Input() showRetentionCustomField;
  @Input() occurrenceTypeDropdownValues;
  @Input() measuresDropdownValues;
  @Input() quoteOptionId;
  @Input() showDeductibleCustomField;
  @Input() sublimitOptionsForFinancialCoverage;
  @Input() currency;

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleSubmitCoverageDetails = new EventEmitter<any>();
  @Output() handlesetCurrentTabActive = new EventEmitter<any>();
  @Output() handleToggleClick = new EventEmitter<any>();
  @Output() handleSaveCoverageData = new EventEmitter<any>();
  @Output() handleBackBtn = new EventEmitter<any>();
  @Output() handleLimitCustomValue = new EventEmitter<any>();
  @Output() handleRetentionCustomValue = new EventEmitter<any>();
  @Output() handleCoverageDeductableChange = new EventEmitter<any>();
  @Output() handleQuoteOptionLimitChange = new EventEmitter<any>();
  @Output() handlePremiumRecalc = new EventEmitter<any>();
  @Output() handleQuoteOptionDeductibleChange = new EventEmitter<any>();
  @Output() handleQuestionnaireNav = new EventEmitter<any>();

  form: FormGroup;
  sublimitForm: FormGroup;
  notificationAlert: {
    show?: boolean;
    bodyText?: string;
    type?: string;
    headerText?: string;
  } = { show: false };
  coverageActiveIdx: number = 0;
  coverageEditable: boolean = false;
  defaultMsg: boolean = true;
  errorMsg: boolean = false;
  fieldData;
  coverages;
  sublimits = [];
  coverageSublimits = [];
  coverageSublimitsInit = [];
  payload = [];
  coverageIncludeExcludePayload = [];
  isButtonDisabled = true;
  prevButtonDisabledValue = true;
  showCoverageLimitCustomField = false;
  currentQuoteOptionDeductible;
  subLimitOptionsForFinancial = [];
  currentCoverageName = '';

  quoteOptionLimit;
  quoteOptionDeductible;
  quoteOptionCustomLimit;
  quoteOptionCustomDeductible;
  initialLimit;
  initialDeductible;
  showQuoteOptionLimitCustomField = false;
  showQuoteOptionDeductibleCustomField = false;
  showInvalidLimitErr = false;
  showInvalidLimitErrMsg = '';
  showInvalidDeductibleErr = false;
  showInvalidDeductibleErrMsg = '';

  limitExceedErrMsg = this.translateService.instant(
    'quoteCalculator.error.limitExceedErrMsg',
  );
  limitMinimumErrMsg = this.translateService.instant(
    'quoteCalculator.error.limitMinimumErrMsg',
  );
  deductibleExceedErrMsg = this.translateService.instant(
    'quoteCalculator.error.deductibleExceedErrMsg',
  );
  deductibleMinimumErrMsg = this.translateService.instant(
    'quoteCalculator.error.deductibleMinimumErrMsg',
  );

  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  currentQuoteStatus: string = '';
  country: string = '';
  setMinDeductibleValidation: boolean = true;

  financialCoverageMatchString = FINANCIAL_COVERAGE;
  financialCoverageLimit = FINANCIAL_COVERAGE_LIMIT;
  financialCoverageRuleMinDeductible = FINANCIAL_COVERAGE_RULE_MIN_DEDUCTIBLE;
  showBodySpinner: boolean = false;

  constructor(
    private fb: FormBuilder,
    private quoteOptionCoverageSublimitsService: QuoteOptionCoverageSublimitsService,
    private quoteOptionCoverageService: QuoteOptionCoverageService,
    private cdr: ChangeDetectorRef,
    private viewportScroller: ViewportScroller,
    private translateService: TranslateService,
    private store: Store,
  ) {
    this.form = fb.group({
      sublimitAmount: ['0', [Validators.required]],
      retentionAmount: ['0', [Validators.required]],
    });
    this.sublimitForm = fb.group({
      sublimitAmount: ['', [Validators.required]],
      occurenceType: [],
      retentionAmount: ['', [Validators.required]],
      occurenceTypeDeductible: [],
      waitingPeriod: [null, [Validators.pattern('^(1000|([1-9][0-9]{0,2}))$')]],
      waitingPeriodMeasure: [],
      indemnityPeriod: [
        null,
        [Validators.pattern('^(1000|([1-9][0-9]{0,2}))$')],
      ],
      indemnityPeriodMeasure: [],
      included: [''],
    });
  }

  async ngOnInit() {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.country = data.country;
    });
    if (this.country.toUpperCase() == UNITED_STATES) {
      this.setMinDeductibleValidation = false;
    }

    this.store.pipe(select(getLifecycleState)).subscribe((data) => {
      this.currentQuoteStatus = data.quoteStatus;
    });

    this.viewportScroller.scrollToPosition([0, 0]);
    this.currentQuoteOptionDeductible = valInDigits(
      this.productDetails?.deductables,
    );
    this.quoteOptionLimit = this.productDetails.limit;
    this.quoteOptionDeductible = this.productDetails.deductables;
    this.initialLimit = this.productDetails.limit;
    this.initialDeductible = this.productDetails.deductables;
    await this.populateCoverages();
  }

  sortAlphabetically(coveragesSublimitsArray) {
    coveragesSublimitsArray.sort((a, b) => {
      const objectA = a.coverage?.coverageName?.toLowerCase();
      const objectB = b.coverage?.coverageName?.toLowerCase();

      if (objectA < objectB) {
        return -1;
      }
      if (objectA > objectB) {
        return 1;
      }
      return 0;
    });
  }

  sortSublimit(sublimit) {
    sublimit.sort((a, b) => {
      const prefixA = a.confCoverageSublimit?.subLimitDescription
        ?.toLowerCase()
        .split('.')[0];
      const prefixB = b.confCoverageSublimit?.subLimitDescription
        ?.toLowerCase()
        .split('.')[0];

      // sort alphabets
      if (prefixA < prefixB) return -1;
      if (prefixA > prefixB) return 1;

      // compare the numbers if alphabet is same
      const numA = parseInt(
        a.confCoverageSublimit?.subLimitDescription
          ?.toLowerCase()
          .split(' ')[0]
          .split('.')[1],
      );
      const numB = parseInt(
        b.confCoverageSublimit?.subLimitDescription
          ?.toLowerCase()
          .split(' ')[0]
          .split('.')[1],
      );

      return numA - numB;
    });
  }

  disableSaveAndContinue() {
    this.prevButtonDisabledValue = this.isButtonDisabled;
    this.isButtonDisabled = true;
    this.handleCancel();
  }
  enableSaveAndContinue() {
    this.isButtonDisabled = this.prevButtonDisabledValue;
  }

  async populateCoverages() {
    this.showBodySpinner = true;
    const response = await this.quoteOptionCoverageService
      .getQuoteOptionCoverage(this.quoteOptionId)
      .toPromise();

    this.coverages = response?.data;
    const coveragesData = response?.data?.map((coverage) => ({
      coverageId: coverage?.id,
      coverageName: coverage?.confCoverage?.coverageName ?? '', //to update
      coverageIncluded: coverage?.included,
      coverageReadOnly: coverage?.readOnly,
      confCoverageId: coverage?.confCoverageId,
      quoteOptionId: coverage?.quoteOptionId,
      questionnaireCategoryName:
        coverage?.confCoverage?.questionnaireCategoryName,
    }));

    await Promise.all(
      coveragesData.map(async (coverage) => {
        const sublimitResponse = await this.quoteOptionCoverageSublimitsService
          .getquoteOptionCoverageSublimits(coverage?.coverageId)
          .toPromise();
        const subLimitIds = [];
        this.sortSublimit(sublimitResponse?.data);
        const sublimitsData = sublimitResponse?.data?.map((sublimit) => {
          if (!sublimit?.readOnly) {
            subLimitIds.push(sublimit?.id);
          } else {
            subLimitIds.push(null);
          }
          return {
            Coverage: sublimit?.confCoverageSublimit?.subLimitDescription,
            Sublimit: sublimit?.limit,
            'Occurrence type': this.getOcurrenceTypeById(
              sublimit?.occurrenceType,
            ),
            Deductibles: sublimit?.deductible,
            'Occurrence type ': this.getOcurrenceTypeById(
              sublimit?.deductibleOccurrenceType,
            ),
            'Indemnity period': sublimit?.indemnityPeriod,
            'Indemnity period measure': this.getMeasuresById(
              sublimit?.indemnityPeriodMeasure,
            ),
            'Waiting period': sublimit?.waitingPeriod,
            'Waiting period measure': this.getMeasuresById(
              sublimit?.waitingPeriodMeasure,
            ),
            isReadOnly: sublimit?.readOnly,
            allowOverwriteDefault:
              sublimit?.confCoverageSublimit?.allowOverwriteDefault,
            factorForLimit: Number(
              sublimit?.confCoverageSublimit?.factorForLimit || 0,
            ),
            included: sublimit?.included,
            defaultLimit: sublimit?.confCoverageSublimit?.defaultLimit,
            defaultDeductible:
              sublimit?.confCoverageSublimit?.defaultDeductible,
          };
        });

        this.sublimits.push(...sublimitResponse?.data);

        this.coverageSublimits.push({
          coverage: coveragesData.find(
            (c) => c.coverageId === coverage?.coverageId,
          ),
          sublimit: sublimitsData,
          subLimitIds,
        });
        this.sortAlphabetically(this.coverageSublimits);

        this.coverageSublimitsInit.push({
          coverage: coveragesData.find(
            (c) => c.coverageId === coverage?.coverageId,
          ),
          sublimit: sublimitsData.map((s) => ({ ...s })),
          subLimitIds: [...subLimitIds],
        });
        this.sortAlphabetically(this.coverageSublimitsInit);
      }),
    );
    this.showBodySpinner = false;
  }

  isAllSublimitDisabled(coverageId) {
    if (!coverageId) {
      return true;
    }
    const coverageData = this.coverageSublimits.filter(
      (coverage) => coverage.coverage?.coverageId === coverageId,
    )[0];
    const isAnyEditable =
      coverageData?.sublimit.filter(
        (sublimit) =>
          !(sublimit?.isReadOnly || sublimit?.allowOverwriteDefault),
      ).length > 0;
    if (isAnyEditable) {
      return false;
    }
    return true;
  }
  handleQuoteOptionLimitCustomValue(event, product) {
    const value = event?.target?.value;
    const limitOptionsArr = this.limitOptionsForProducts[
      this.productDetails.key
    ]
      ?.map((val) => val.value)
      .filter((value) => !isNaN(value));
    const minLimit = Math.min(...limitOptionsArr);
    const maxLimit = Math.max(...limitOptionsArr);
    const formattedLimit = value ? value?.replace(/[$,]/g, '') : 0;
    if (valInDigits(formattedLimit) > maxLimit) {
      this.showQuoteOptionLimitCustomField = false;
      this.showInvalidLimitErr = true;
      this.showInvalidLimitErrMsg = this.limitExceedErrMsg
        .replace('[productName]', this.productDetails?.key)
        .replace(
          '[maximumLimit]',
          formatAmountWithCurrency(maxLimit, this.currency)?.toString(),
        );
      return;
    } else if (valInDigits(formattedLimit) < minLimit) {
      this.showQuoteOptionLimitCustomField = false;
      this.showInvalidLimitErr = true;
      this.showInvalidLimitErrMsg = this.limitMinimumErrMsg
        .replace('[productName]', this.productDetails?.key)
        .replace(
          '[minimumLimit]',
          formatAmountWithCurrency(minLimit, this.currency)?.toString(),
        );
      return;
    }
    this.showInvalidLimitErr = false;
    this.showInvalidLimitErrMsg = '';
    this.quoteOptionLimit = '';
    this.quoteOptionCustomLimit = value;
    this.isButtonDisabled = false;
    this.coverageSublimits = this.coverageSublimits.map((coverage) => {
      if (!coverage?.coverage?.coverageReadOnly) {
        return {
          ...coverage,
          sublimit: coverage.sublimit.map((sublimit) => {
            if (
              sublimit?.included &&
              sublimit?.allowOverwriteDefault &&
              !coverage?.coverage?.coverageName
                ?.toLowerCase()
                ?.includes(this.financialCoverageMatchString)
            ) {
              if (sublimit['factorForLimit'] === 0) {
                return {
                  ...sublimit,
                  Sublimit: value,
                };
              }
              return {
                ...sublimit,
                Sublimit: value * sublimit['factorForLimit'],
              };
            }
            return sublimit;
          }),
        };
      }
      return coverage;
    });
  }
  quoteOptionLimitChange(value, product) {
    this.form;
    if (value == 'custom-amount') {
      this.showQuoteOptionLimitCustomField = true;
    } else {
      this.showInvalidLimitErr = false;
      this.showInvalidLimitErrMsg = '';
      this.quoteOptionLimit = value;
      this.quoteOptionCustomLimit = '';
      this.isButtonDisabled = false;

      this.coverageSublimits = this.coverageSublimits.map((coverage) => {
        if (!coverage?.coverage?.coverageReadOnly) {
          return {
            ...coverage,
            sublimit: coverage.sublimit.map((sublimit) => {
              if (
                sublimit.included &&
                sublimit?.allowOverwriteDefault &&
                !coverage?.coverage?.coverageName
                  ?.toLowerCase()
                  ?.includes(this.financialCoverageMatchString)
              ) {
                if (sublimit['factorForLimit'] === 0) {
                  return {
                    ...sublimit,
                    Sublimit: value,
                  };
                }
                return {
                  ...sublimit,
                  Sublimit: value * sublimit['factorForLimit'],
                };
              }
              return sublimit;
            }),
          };
        }
        return coverage;
      });
    }
  }

  getOcurrenceTypeById(id) {
    return this.occurrenceTypeDropdownValues.filter(
      (occurrenceType) => occurrenceType?.id === id,
    )[0]?.value;
  }
  getMeasuresById(id) {
    return this.measuresDropdownValues.filter(
      (measures) => measures?.id === id,
    )[0]?.value;
  }

  getOccurrenceTypeId(type) {
    return this.occurrenceTypeDropdownValues.filter(
      (occurrenceType) => occurrenceType?.value === type,
    )[0]?.id;
  }

  getMeasuresId(measure) {
    return this.measuresDropdownValues.filter(
      (measures) => measures?.value === measure,
    )[0]?.id;
  }
  private initValuesForm() {
    this.form.get('sublimitAmount').setValue(this.productDetails?.limit);
    this.form.get('occurenceType').setValue(this.fieldData?.occurrenceType);
    this.form.get('indemnityPeriod').setValue(this.fieldData?.indemnityPeriod);
    this.form
      .get('indemnityPeriodMeasure')
      .setValue(this.fieldData?.indemnityPeriodMeasure);
    this.form
      .get('waitingPeriodMeasure')
      .setValue(this.fieldData?.waitingPeriodMeasure);
    this.form.get('retentionAmount').setValue(this.productDetails?.deductables);
    this.form
      .get('occurenceTypeDeductible')
      .setValue(this.productDetails?.deductables);
  }

  handleNavigation() {
    this.handleQuestionnaireNav.emit();
  }
  getQuoteOptnCoverageSublimits() {
    let coverageId = 1;
    let indemnityPeriod,
      indemnityPeriodMeasure,
      occurrenceType,
      waitingPeriodMeasure;

    this.quoteOptionCoverageSublimitsService
      .getquoteOptionCoverageSublimits(coverageId)
      .subscribe({
        next: async (resp) => {
          indemnityPeriod = resp.data[0].indemnityPeriod;
          indemnityPeriodMeasure = resp?.data[0]?.indemnityPeriodMeasure;
          occurrenceType = resp?.data[0]?.occurrenceType;
          waitingPeriodMeasure = resp?.data[0]?.waitingPeriodMeasure;
        },
        error: (e) => {
          console.log(e);
        },
      });

    this.fieldData = [
      indemnityPeriod,
      indemnityPeriodMeasure,
      occurrenceType,
      waitingPeriodMeasure,
    ];
  }

  handleCancel() {
    this.coverageEditable = false;
    this.showCoverageLimitCustomField = false;
    this.showDeductibleCustomField = false;
    this.form.get('sublimitAmount').setValue('0');
    this.form.get('retentionAmount').setValue('0');
  }

  handleValueChange(value, control) {
    this.form.get(control).setValue(value);
  }
  handleNotificationAlertClose() {
    this.notificationAlert.show = false;
  }

  closeHandler() {
    this.handleClose.emit();
  }

  showCoverageDetails(event) {
    this.handleSubmitCoverageDetails.emit(this.coverageDetails);
  }

  onToggleClick(coverage, value, data) {
    const coverageId = coverage?.coverageId;
    if (coverage?.coverageReadOnly) {
      return;
    }
    this.isButtonDisabled = false;
    this.coverageSublimits = this.coverageSublimits.map((sublimit) => {
      if (sublimit?.coverage?.coverageId === coverageId) {
        return {
          ...sublimit,
          coverage: {
            ...sublimit?.coverage,
            coverageIncluded: !sublimit?.coverage?.coverageIncluded,
          },
        };
      }
      return sublimit;
    });
    const isAlreadyUpdated =
      this.coverageIncludeExcludePayload.filter(
        (coverage) => coverage?.coverage?.coverageId == coverageId,
      ).length > 0;
    if (isAlreadyUpdated) {
      this.coverageIncludeExcludePayload =
        this.coverageIncludeExcludePayload.map((coverage, index) => {
          if (coverage.id === coverageId) {
            return {
              ...this.coverageIncludeExcludePayload[index],
              included: value ? 1 : 0,
              confCoverageId: coverage?.confCoverageId,
              quoteOptionId: coverage?.quoteOptionId,
            };
          }
          return coverage;
        });
    } else {
      this.coverageIncludeExcludePayload.push({
        id: coverageId,
        included: value ? 1 : 0,
        confCoverageId: data?.confCoverageId,
        quoteOptionId: data?.quoteOptionId,
      });
    }
  }

  handleDeductibleChange(value, product) {
    this.handleCancel();
    if (value == 'custom-amount') {
      this.showQuoteOptionDeductibleCustomField = true;
    } else {
      this.showInvalidDeductibleErr = true;
      this.showInvalidDeductibleErrMsg = '';
      this.currentQuoteOptionDeductible = valInDigits(value);
      this.quoteOptionDeductible = value;
      this.quoteOptionCustomDeductible = '';
      this.isButtonDisabled = false;
      this.coverageSublimits = this.coverageSublimits.map((coverage) => {
        if (!coverage?.coverage?.coverageReadOnly) {
          return {
            ...coverage,
            sublimit: coverage.sublimit.map((sublimit) => {
              if (sublimit?.included && sublimit?.allowOverwriteDefault) {
                return {
                  ...sublimit,
                  Deductibles: value,
                };
              }
              return sublimit;
            }),
          };
        }
        return coverage;
      });
    }
  }

  setCurrentTabActive(index) {
    this.handleCancel();
    this.coverageActiveIdx = index;
    this.currentCoverageName =
      this.coverageSublimits[index]?.coverage?.coverageName;
    this.coverageEditable = false;
    this.handlesetCurrentTabActive.emit();
  }

  saveCoverageData(coverageIdx) {
    if (!this.form.valid) {
      return;
    }
    this.isButtonDisabled = false;
    const formValue = this.form.value;
    const sublimitsToBeUpdated =
      this.coverageSublimits[coverageIdx].subLimitIds;

    const updatedFormValue = {
      Sublimit: formValue?.sublimitAmount,
      Deductibles: formValue?.retentionAmount,
    };
    const isFinancialCoverage = this.coverageSublimits[
      coverageIdx
    ]?.coverage?.coverageName
      ?.toLowerCase()
      .includes(this.financialCoverageMatchString);
    this.coverageSublimits[coverageIdx].sublimit = this.coverageSublimits[
      coverageIdx
    ].sublimit.map((sublimit) => {
      const deductible =
        this.quoteOptionDeductible || this.quoteOptionCustomDeductible;
      if (sublimit?.isReadOnly) {
        return sublimit;
      } else if (!sublimit?.included) {
        return {
          ...sublimit,
          Sublimit: 0,
          Deductibles: 0,
        };
      } else if (
        isFinancialCoverage &&
        Number(updatedFormValue['Deductibles']) <
          this.financialCoverageRuleMinDeductible
      ) {
        return {
          ...sublimit,
          Sublimit: this.financialCoverageLimit,
          Deductibles: updatedFormValue['Deductibles'],
        };
      } else if (sublimit?.allowOverwriteDefault) {
        return {
          ...sublimit,
          Sublimit: this.quoteOptionLimit,
          Deductibles: this.quoteOptionDeductible,
        };
      }
      return {
        ...sublimit,
        ...updatedFormValue,
      };
    });

    sublimitsToBeUpdated
      .filter((id) => id)
      ?.forEach((sublimitIdToUpdate) => {
        const sublimitData = this.sublimits.filter(
          (sublimit) => sublimit.id === sublimitIdToUpdate,
        )[0];
        const isAlreadyAdded =
          this.payload.filter((data) => data.id === sublimitIdToUpdate).length >
          0;
        if (isAlreadyAdded) {
          this.payload = this.payload.map((data) => {
            if (data.included && data.id === sublimitIdToUpdate) {
              return {
                ...data,
                id: sublimitData?.id,
                quoteOptionCoverageId: sublimitData?.quoteOptionCoverageId,
                confCoverageSublimitsId: sublimitData?.confCoverageSublimitsId,
                limit: sublimitData?.included
                  ? Number(formValue?.sublimitAmount)
                  : 0,
                deductible: sublimitData?.included
                  ? Number(formValue?.retentionAmount)
                  : 0,
                included: sublimitData?.included,
              };
            }
            return data;
          });
        } else {
          const deductible =
            this.quoteOptionDeductible || this.quoteOptionCustomDeductible;
          if (
            isFinancialCoverage &&
            Number(formValue?.retentionAmount) <
              this.financialCoverageRuleMinDeductible
          ) {
            this.payload.push({
              id: sublimitData?.id,
              quoteOptionCoverageId: sublimitData?.quoteOptionCoverageId,
              confCoverageSublimitsId: sublimitData?.confCoverageSublimitsId,
              limit: sublimitData?.included ? this.financialCoverageLimit : 0,
              deductible: sublimitData?.included
                ? Number(formValue?.retentionAmount)
                : 0,
              included: sublimitData?.included,
            });
          } else {
            this.payload.push({
              id: sublimitData?.id,
              quoteOptionCoverageId: sublimitData?.quoteOptionCoverageId,
              confCoverageSublimitsId: sublimitData?.confCoverageSublimitsId,
              limit: sublimitData?.included
                ? Number(formValue?.sublimitAmount)
                : 0,
              deductible: sublimitData?.included
                ? Number(formValue?.retentionAmount)
                : 0,
              included: sublimitData?.included,
            });
          }
        }
      });
    this.coverageEditable = false;
    this.form.reset();
    this.cdr.detectChanges();
  }

  getNumber(str) {
    return Number(str) || 0;
  }

  populateFinancialCoveragePayload() {
    const financialCoverage = this.coverageSublimits.filter(
      (coverage) =>
        coverage?.coverage?.coverageName
          ?.toLowerCase()
          ?.includes(this.financialCoverageMatchString),
    );
    let coverageIdx = null;
    if (financialCoverage?.length > 0) {
      coverageIdx = financialCoverage[0]?.coverage?.coverageId;
    }
    if (!coverageIdx) {
      return;
    }
    const sublimitsToBeUpdated = financialCoverage[0]?.subLimitIds;

    sublimitsToBeUpdated
      .filter((id) => id)
      ?.forEach((sublimitIdToUpdate) => {
        const sublimitData = this.sublimits.filter(
          (sublimit) => sublimit.id === sublimitIdToUpdate,
        )[0];

        const sublimit = financialCoverage[0]?.sublimit.filter(
          (sublimit) =>
            sublimit['Coverage']?.toLowerCase() ===
            sublimitData?.confCoverageSublimit?.subLimitDescription?.toLowerCase(),
        )[0];

        const deductible =
          this.quoteOptionDeductible || this.quoteOptionCustomDeductible;

        const isAlreadyAdded =
          this.payload.filter((data) => data.id === sublimitIdToUpdate).length >
          0;
        if (isAlreadyAdded) {
          this.payload = this.payload.map((data) => {
            if (data.id === sublimitIdToUpdate) {
              if (deductible < this.financialCoverageRuleMinDeductible) {
                return {
                  ...data,
                  limit: this.financialCoverageLimit,
                };
              }
              const expectedLimitValues =
                this.sublimitOptionsForFinancialCoverage.map(
                  (value) => value.value,
                );
              if (expectedLimitValues.indexOf(sublimit['Sublimit']) !== -1) {
                return data;
              }
              return {
                ...data,
                limit: this.financialCoverageLimit,
              };
            }
            return data;
          });
        } else {
          if (Number(deductible) < this.financialCoverageRuleMinDeductible) {
            this.payload.push({
              id: sublimitData?.id,
              quoteOptionCoverageId: sublimitData?.quoteOptionCoverageId,
              confCoverageSublimitsId: sublimitData?.confCoverageSublimitsId,
              limit: this.financialCoverageLimit,
              deductible: sublimit['Deductibles'],
              included: sublimitData?.included,
            });
          } else if (
            Number(deductible) >= this.financialCoverageRuleMinDeductible
          ) {
            const expectedLimitValues =
              this.sublimitOptionsForFinancialCoverage.map(
                (value) => value.value,
              );

            if (expectedLimitValues.indexOf(sublimit['Sublimit']) !== -1) {
              this.payload.push({
                id: sublimitData?.id,
                quoteOptionCoverageId: sublimitData?.quoteOptionCoverageId,
                confCoverageSublimitsId: sublimitData?.confCoverageSublimitsId,
                limit: sublimit['Sublimit'],
                deductible: sublimit['Deductibles'],
                included: sublimitData?.included,
              });
            } else {
              this.payload.push({
                id: sublimitData?.id,
                quoteOptionCoverageId: sublimitData?.quoteOptionCoverageId,
                confCoverageSublimitsId: sublimitData?.confCoverageSublimitsId,
                limit: this.financialCoverageLimit,
                deductible: sublimit['Deductibles'],
                included: sublimitData?.included,
              });
            }
          }
        }
      });
  }

  onCoverageEdit(data) {
    this.form
      .get('sublimitAmount')
      .setValue(data['Sublimit'] || this.productDetails?.limit);
    this.form
      .get('retentionAmount')
      .setValue(data['Deductibles'] || this.productDetails?.deductables);

    this.coverageEditable = !this.coverageEditable;
    this.isButtonDisabled = true;
  }
  handleBackButton() {
    this.handleBackBtn.emit();
  }

  handleLimitCustomValueClick(event) {
    const value = event?.target.value?.replace(/[$,]/g, '');
    this.form.get('sublimitAmount').setValue(value);
    this.showCoverageLimitCustomField = false;
  }

  handleRetentionCustomValueClick(event, isFinancialCoverage = false) {
    const value = event?.target.value?.replace(/[$,]/g, '');
    this.form.get('retentionAmount').setValue(value);
    if (
      isFinancialCoverage &&
      Number(value) < this.financialCoverageRuleMinDeductible
    ) {
      this.form.get('sublimitAmount').setValue(this.financialCoverageLimit);
    }
    this.showDeductibleCustomField = false;
  }

  handleCoverageDeductableOnChange(value, isFinancialCoverage = false) {
    if (value == 'custom-amount') {
      this.showDeductibleCustomField = true;
    } else {
      this.showDeductibleCustomField = false;
      this.form.get('retentionAmount').setValue(value);
      if (
        isFinancialCoverage &&
        Number(value) < this.financialCoverageRuleMinDeductible
      ) {
        this.form.get('sublimitAmount').setValue(this.financialCoverageLimit);
      }
    }
  }

  handleDeductibleCustValue(event, product) {
    this.handleCancel();
    const value = event?.target?.value;
    const deductibleOptionsArr = this.deductibleOptions
      ?.map((val) => val.value)
      .filter((value) => !isNaN(value));
    const minLimit = Math.min(...deductibleOptionsArr);
    const maxLimit = Math.max(...deductibleOptionsArr);
    const formattedValue = value ? value?.replace(/[$,]/g, '') : 0;
    if (valInDigits(formattedValue) > maxLimit) {
      this.showQuoteOptionDeductibleCustomField = false;
      this.showInvalidDeductibleErr = true;
      this.showInvalidDeductibleErrMsg = this.deductibleExceedErrMsg
        .replace('[productName]', this.productDetails?.key)
        .replace(
          '[maximumDeductible]',
          formatAmountWithCurrency(maxLimit, this.currency)?.toString(),
        );
      return;
    } else if (valInDigits(formattedValue) < minLimit) {
      this.showQuoteOptionDeductibleCustomField = false;
      this.showInvalidDeductibleErr = true;
      this.showInvalidDeductibleErrMsg = this.deductibleMinimumErrMsg
        .replace('[productName]', this.productDetails?.key)
        .replace(
          '[minimumDeductible]',
          formatAmountWithCurrency(minLimit, this.currency)?.toString(),
        );
      return;
    }

    this.currentQuoteOptionDeductible = valInDigits(value);
    this.quoteOptionDeductible = '';
    this.quoteOptionCustomDeductible = value;
    this.isButtonDisabled = false;
    this.coverageSublimits = this.coverageSublimits.map((coverage) => {
      if (!coverage?.coverage?.coverageReadOnly) {
        return {
          ...coverage,
          sublimit: coverage.sublimit.map((sublimit) => {
            if (sublimit?.included && sublimit?.allowOverwriteDefault) {
              return {
                ...sublimit,
                Deductibles: value,
              };
            }
            return sublimit;
          }),
        };
      }
      return coverage;
    });
  }

  handleCoverageLimitOnChange(value) {
    if (value == 'custom-amount') {
      this.showCoverageLimitCustomField = true;
    } else {
      this.showCoverageLimitCustomField = false;
      this.form.get('sublimitAmount').setValue(value);
    }
  }

  handleReset() {
    if (this.isButtonDisabled) {
      return;
    }
    this.coverageSublimits = this.coverageSublimitsInit;
    this.payload = [];
  }

  async handleRecalculate() {
    this.showBodySpinner = true;
    if (this.showInvalidLimitErrMsg || this.showInvalidDeductibleErrMsg) {
      return;
    }
    if (this.quoteOptionCustomLimit) {
      this.handleLimitCustomValue.emit(this.quoteOptionCustomLimit);
    } else if (this.quoteOptionLimit) {
      this.handleQuoteOptionLimitChange.emit(this.quoteOptionLimit);
    }

    if (this.quoteOptionDeductible) {
      this.handleQuoteOptionDeductibleChange.emit(this.quoteOptionDeductible);
    } else if (this.quoteOptionCustomDeductible) {
      this.handleDeductibleCustValue(
        this.quoteOptionDeductible,
        this.productDetails.key,
      );
    }

    try {
      if (this.payload.length > 0) {
        const coverageSublimitPayload = {
          quoteOptionCoverageSublimits: this.payload,
        };
        const sublimitResponse = await this.quoteOptionCoverageSublimitsService
          .updateQuoteOptionCoverageSublimits(coverageSublimitPayload)
          .toPromise();
      }

      if (this.coverageIncludeExcludePayload.length > 0) {
        this.showBodySpinner = false;
        for (const coverage of this.coverageIncludeExcludePayload) {
          const data = {
            included: coverage?.included,
            confCoverageId: coverage?.confCoverageId,
            quoteOptionId: coverage?.quoteOptionId,
          };
          const updateResponse = await this.quoteOptionCoverageService
            .Update(coverage?.id, data)
            .toPromise();
          this.closeHandler();
          let updated = true;
          if (
            (this.initialLimit == this.quoteOptionLimit ||
              this.initialLimit == this.quoteOptionCustomLimit) &&
            (this.initialDeductible == this.quoteOptionDeductible ||
              this.initialDeductible == this.quoteOptionCustomDeductible)
          ) {
            updated = false;
          }
          this.handlePremiumRecalc.emit({
            product: this.productDetails.key,
            updated,
          });
        }
      } else {
        let updated = true;
        if (
          (this.initialLimit == this.quoteOptionLimit ||
            this.initialLimit == this.quoteOptionCustomLimit) &&
          (this.initialDeductible == this.quoteOptionDeductible ||
            this.initialDeductible == this.quoteOptionCustomDeductible)
        ) {
          updated = false;
        }
        this.handlePremiumRecalc.emit({
          product: this.productDetails.key,
          updated,
        });
      }
    } catch (error) {
      // Handle any errors here
    }
  }

  handleSublimitRowSave(value, coverageIdx) {
    this.isButtonDisabled = false;
    const formValue = value.form;
    const updatedFormValue = {
      Sublimit: Number(formValue?.sublimitAmount),
      Deductibles: Number(formValue?.retentionAmount),
      'Occurrence type ': formValue?.occurenceTypeDeductible,
      'Occurrence type': formValue?.occurenceType,
      'Indemnity period': formValue?.indemnityPeriod
        ? Number(formValue?.indemnityPeriod)
        : null,
      'Indemnity period measure': formValue?.indemnityPeriodMeasure,
      'Waiting period': formValue?.waitingPeriod
        ? Number(formValue?.waitingPeriod)
        : null,
      'Waiting period measure': formValue?.waitingPeriodMeasure,
      included: formValue?.included,
    };
    const indexUpdated = value.index;
    this.coverageSublimits[coverageIdx].sublimit[indexUpdated] = {
      ...this.coverageSublimits[coverageIdx].sublimit[indexUpdated],
      ...updatedFormValue,
    };
    const sublimitIdToUpdate =
      this.coverageSublimits[coverageIdx].subLimitIds[indexUpdated];
    const sublimitData = this.sublimits.filter(
      (sublimit) => sublimit.id === sublimitIdToUpdate,
    )[0];
    const isAlreadyAdded =
      this.payload.filter((data) => data.id === sublimitIdToUpdate).length > 0;
    if (isAlreadyAdded) {
      this.payload = this.payload.map((data) => {
        if (data.id === sublimitIdToUpdate) {
          return {
            ...data,
            id: sublimitData?.id,
            quoteOptionCoverageId: sublimitData?.quoteOptionCoverageId,
            confCoverageSublimitsId: sublimitData?.confCoverageSublimitsId,
            limit: formValue?.included ? formValue?.sublimitAmount : 0,
            occurrenceType: this.getOccurrenceTypeId(formValue?.occurenceType),
            deductible: formValue?.included ? formValue?.retentionAmount : 0,
            deductibleOccurrenceType: this.getOccurrenceTypeId(
              formValue?.occurenceTypeDeductible,
            ),
            waitingPeriod: formValue?.waitingPeriod
              ? formValue?.waitingPeriod
              : null,
            waitingPeriodMeasure: this.getMeasuresId(
              formValue?.waitingPeriodMeasure,
            ),
            indemnityPeriod: formValue?.indemnityPeriod
              ? formValue?.indemnityPeriod
              : null,
            indemnityPeriodMeasure: this.getMeasuresId(
              formValue?.indemnityPeriodMeasure,
            ),
            included: formValue?.included,
          };
        }
        return data;
      });
    } else {
      this.payload.push({
        id: sublimitData?.id,
        quoteOptionCoverageId: sublimitData?.quoteOptionCoverageId,
        confCoverageSublimitsId: sublimitData?.confCoverageSublimitsId,
        limit: formValue?.included ? formValue?.sublimitAmount : 0,
        occurrenceType: this.getOccurrenceTypeId(formValue?.occurenceType),
        deductible: formValue?.included ? formValue?.retentionAmount : 0,
        deductibleOccurrenceType: this.getOccurrenceTypeId(
          formValue?.occurenceTypeDeductible,
        ),
        waitingPeriod: formValue?.waitingPeriod
          ? formValue?.waitingPeriod
          : null,
        waitingPeriodMeasure: this.getMeasuresId(
          formValue?.waitingPeriodMeasure,
        ),
        indemnityPeriod: formValue?.indemnityPeriod
          ? formValue?.indemnityPeriod
          : null,
        indemnityPeriodMeasure: this.getMeasuresId(
          formValue?.indemnityPeriodMeasure,
        ),
        included: formValue?.included,
      });
    }
  }
}
