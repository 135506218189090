import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BoxxResponse, Pagination } from 'src/app/entities/boxx-response';
import { BoxxUser } from 'src/app/entities/boxx-user';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { getErrorMessage } from 'src/app/utils/utils';

@Component({
  selector: 'app-boxx-user-directory',
  templateUrl: './boxx-user-directory.component.html',
  styleUrls: ['./boxx-user-directory.component.less'],
})
export class BoxxUserDirectoryComponent implements OnInit {
  form: FormGroup;
  data: any[] = [];
  managers: any[] = [];
  totalDataCount = 0;
  isSearchResult: boolean = false;
  addSortTo: string | Array<string> = ['Name', 'Manager', 'Title', 'Status'];
  pagination: Pagination;
  tManagers: any;
  tManager = '';
  searchQuery = '';
  managerId = '';
  sortBy: string;
  sortColumn: string = 'id';
  sortDirection: string = 'desc';
  pageNumber: number = 1;
  showTblSpinner: boolean = false;
  showErrorAlert = false;
  msgErrorAlert = '';
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private boxxUserService: BoxxUserService,
    private store: Store,
  ) {
    this.form = fb.group({
      searchBy: new FormArray([]),
      searchKey: [''],
    });
    this.sortBy = this.sortColumn + ':' + this.sortDirection;
  }

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
    });

    this.showTblSpinner = true;
    this.fetchBoxxUsers();
  }

  fetchBoxxUsers() {
    this.boxxUserService
      .GetAllUsers(this.searchQuery, this.pageNumber, 10, this.sortBy)
      .subscribe({
        next: (response) => {
          this.showTblSpinner = false;
          this.setSearchResult(response);
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
  }

  setSearchResult(data: BoxxResponse<BoxxUser>) {
    this.showTblSpinner = true;
    const tableData = data.data?.map((dataObj) => {
      const { firstName, lastName, boxxManager, title, uwRole } = dataObj;

      return {
        Name: `${firstName} ${lastName}`,
        Manager: boxxManager
          ? `${boxxManager.firstName} ${boxxManager.lastName}`
          : '',
        Title: title ?? '',
        ActionView: `dashboard/boxx-user-directory/edit/${dataObj.id}`,
        Status: dataObj.active ? 'Active' : 'Inactive',
      };
    });
    this.showTblSpinner = false;
    this.data = tableData.length == 0 ? this.getTableHeaders() : tableData;
    this.totalDataCount = data.pagination.totalRecords;
    this.pagination = data.pagination;
  }

  getTableHeaders() {
    return [{ Name: '', Manager: '', Title: '', Status: '' }];
  }

  handlePageChange(pageNumber) {
    this.showTblSpinner = true;
    this.searchQuery = this.form.value['searchKey'];
    if (this.pageNumber > 0) {
      this.pageNumber = pageNumber;
      this.boxxUserService
        .GetAllUsers(this.searchQuery, pageNumber, 10, this.sortBy)
        .subscribe({
          next: (response) => {
            this.showTblSpinner = false;
            this.setSearchResult(response);
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    }
  }

  navigateToAddBoxxUser(event) {
    this.router.navigate(['dashboard/boxx-user-directory/new'], {
      skipLocationChange: true,
    });
  }

  sanitizeSearchInput(userInput: string): string {
    // Define the list of special characters that you want to remove
    const specialCharacters = /[\^\$\\*+?.()|[\]{}%#]/g;

    // Remove special characters and underscore from the user input
    // Return the sanitized input
    return userInput.replace(specialCharacters, '').replace('_', '');
  }

  searchBoxxUser(event) {
    if (event.key == 'Enter') {
      this.showTblSpinner = true;
      this.isSearchResult = true;
      this.pageNumber = 1;
      this.searchQuery = this.form.value['searchKey'];
      this.boxxUserService
        .GetAllUsers(this.searchQuery, 1, 10, this.sortBy)
        .subscribe({
          next: (response) => {
            this.showTblSpinner = false;
            this.setSearchResult(response);
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    } else {
      let sanitizedText = this.sanitizeSearchInput(
        this.form.value['searchKey'],
      );
      this.form.patchValue({
        searchKey: sanitizedText,
      });
    }
  }

  mapSortColumns(key, dir) {
    switch (key) {
      case 'Title':
        this.sortColumn = 'title:' + dir;
        break;
      case 'Name':
        this.sortColumn = 'firstName:' + dir + ',lastName:' + dir;
        break;
      case 'Manager':
        this.sortColumn =
          'boxxManager.firstName:' + dir + ',boxxManager.lastName:' + dir;
        break;
      case 'Status':
        this.sortColumn = 'active:' + dir;
        break;
      default:
        this.sortColumn = 'id:' + dir;
        break;
    }
  }
  handleAscSort(key) {
    if (key) {
      this.mapSortColumns(key, 'asc');
    }
    this.showTblSpinner = true;
    this.sortDirection = 'asc';
    this.sortBy = this.sortColumn;
    this.fetchBoxxUsers();
  }

  handleDescSort(key) {
    if (key) {
      this.mapSortColumns(key, 'desc');
    }
    this.showTblSpinner = true;
    this.sortDirection = 'desc';
    this.sortBy = this.sortColumn;
    this.fetchBoxxUsers();
  }
  handleCloseSuccessEvent() {
    this.showErrorAlert = false;
  }
}
