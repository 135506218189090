import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BoxxResponse } from '../entities/boxx-response';
import { Insured } from '../entities/insured';
import { BaseService } from './base-services';
import { Industry } from '../entities/industry';

@Injectable({
  providedIn: 'root',
})
export class IndustryRestrictedService extends BaseService<Industry> {
  constructor(http: HttpClient) {
    const baseurl =
      environment.msInsuredBaseUrl + environment.industryRestrictedPathUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  GetByIndustryId(
    industryId,
    page = 1,
    limit = 10,
    active = 1,
    sort = '',
  ): Observable<BoxxResponse<Industry>> {
    return this.http
      .get<BoxxResponse<Insured>>(
        this.baseurl +
          '?industryId=' +
          industryId +
          '&page=' +
          page +
          '&limit=' +
          limit +
          '&active=' +
          active +
          '&sort=' +
          sort,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
