import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NewQuoteService } from 'src/app/services/new-quote.service';
import { Pagination } from 'src/app/entities/boxx-response';
import { PolicyDashboardService } from 'src/app/services/policy-dashboard.service';
import {
  convertDate,
  getDateString,
  getFormattedPolicyPeriod,
} from 'src/app/utils/formatDate';
import { mapPolicy } from 'src/app/constants/policyStatus';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import { Store, select } from '@ngrx/store';
import {
  setInsuredIdAction,
  setPolicyRiskTrxIdAction,
  setPolicyPeriodIdAction,
  setPolicyIdAction,
  setPolicyRiskTrxStatusAction,
} from 'src/app/store/lifecycle/lifecycle.action';
import {
  makeSorting,
  formatAmountWithCurrency,
  excelFileDownload,
  getAlertHead,
  getErrorMessage,
} from 'src/app/utils/utils';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { getDashboardPath } from 'src/app/store/create-quote/create-quote.selector';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import {
  MAX_DEDUCTIBLE,
  MAX_LIMIT,
  MAX_PREMIUM,
  MIN_DEDUCTIBLE,
  MIN_LIMIT,
  MIN_PREMIUM,
} from 'src/app/constants/filter-constant';
import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import { take } from 'rxjs';
import { PolicyRiskTrxService } from 'src/app/services/policy-risk-trx.service';
import { AlertService } from 'src/app//services/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-policy-grid',
  templateUrl: './policy-grid.component.html',
  styleUrls: ['./policy-grid.component.less'],
})
export class PolicyGridComponent implements OnInit {
  subNavData;
  tableData = [];
  tableHeaders = [
    {
      'Policy ID': '',
      Status: '',
      'Policy dates': '',
      'Insured name': '',
      Product: '',
      Limit: '',
      Premium: '',
    },
  ];
  pdtOptions = [];
  trxData = [];
  statusMappingToType = {
    'NEW SUBMISSIONS': 'default',
    REINSTATED: 'default',
    BOUND: 'primary',
    REFERRAL: 'primary',
    QUOTE: 'secondary',
    QUOTED: 'secondary',
    RENEWED: 'warning',
    'RENEWAL QUOTE': 'warning',
    'RENEWAL APP': 'warning',
    'QUOTE CLOSED': 'inactive',
    CANCELLED: 'inactive',
    LAPSED: 'inactive',
    DECLINED: 'inactive',
    CLOSED: 'inactive',
    'NOT TAKEN UP': 'inactive',
  };
  colPolicyHeader = {
    'Policy ID': [{ key: 'policy.pkgPolicyNumber', order: '' }],
    Status: [{ key: 'policy.PolicyStatus.description', order: '' }],
    'Policy dates': [{ key: 'effectiveDt', order: '' }],
    'Insured name': [
      { key: 'policy.insured.companyName', order: '' },
      { key: 'policy.insured.firstName', order: '' },
      { key: 'policy.insured.lastName', order: '' },
    ],
  };
  addSortTo: string[] = ['Policy ID', 'Policy dates', 'Status', 'Insured name'];
  showFilter = false;
  isNavFrmPolicyCreate = false;
  newlyCreatedPolicyId;
  policyWarningMsg: string = '';
  showSlideOut = false;
  totalDataCount = 0;
  pagination: Pagination;
  filterForm: FormGroup;
  products;
  sortingKeys = 'followUp:desc, policy.pkgPolicyNumber:desc';
  pageNumber: number = 1;
  showTblSpinner: boolean = false;
  showDocPreview: boolean = false;
  currentPreviewDocUrl = '';
  userID: number;
  underwriterNameNav: string = '';
  currency = '';

  notificationAlert: {
    show?: boolean;
    bodyText?: string;
    type?: string;
    headerText?: string;
  } = { show: false };
  policyList: any[];
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  quoteFlowSteps;
  quoteFlowUrl = '';
  shortDateFormat: string = '';
  longDateFormat: string = '';
  isRestrictedRoleSubmission: boolean = false;
  showEllipsis: boolean = true;
  startQuoteBtnTxt: string = '';

  constructor(
    private router: Router,
    private store: Store,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private newQuoteService: NewQuoteService,
    private policyService: PolicyDashboardService,
    private riskRegionService: RiskRegionService,
    private localStorageService: LocalStorageService,
    private renderer: Renderer2,
    private policyRiskTrxService: PolicyRiskTrxService,
    private alertService: AlertService,
    private translateService: TranslateService,
  ) {
    this.filterForm = this.fb.group({
      effectiveFrm: [''],
      effectiveTo: [''],
      expiryFrm: [''],
      expiryTo: [''],
      policyPeriod: [''],
      minLimit: [MIN_LIMIT, [this.isMinMaxValid(null, 'maxLimit')]],
      maxLimit: [MAX_LIMIT, [this.isMinMaxValid('minLimit', null)]],
      minDeductible: [
        MIN_DEDUCTIBLE,
        [this.isMinMaxValid(null, 'maxDeductible')],
      ],
      maxDeductible: [
        MAX_DEDUCTIBLE,
        [this.isMinMaxValid('minDeductible', null)],
      ],
      minPremium: [MIN_PREMIUM, [this.isMinMaxValid(null, 'maxPremium')]],
      maxPremium: [MAX_PREMIUM, [this.isMinMaxValid('minPremium', null)]],
      status: [[]],
      brokerageBranchProd: [[]],
      underwriter: [[]],
      products: [[]],
    });
  }

  ngOnInit(): void {
    this.quoteFlowSteps = this.localStorageService.getQuoteFlowSteps();
    if (this.quoteFlowSteps === '7') {
      this.quoteFlowUrl = 'quote';
    } else if (this.quoteFlowSteps === '4') {
      this.quoteFlowUrl = 'quote4flow';
    } else {
      this.quoteFlowUrl = 'workflow3';
    }
    this.startQuoteBtnTxt = this.translateService.instant(
      'common.startNewQuote',
    );
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
      this.isRestrictedRoleSubmission = data.isRestrictedRoleSubmission;
    });

    this.subNavData = [
      { name: 'All', active: true, value: 'All' },
      { name: 'New business', active: false, value: 'New' },
      { name: 'Renewal', active: false, value: 'RENEWED' },
      { name: 'Cancelled', active: false, value: 'CANCELLED' },
    ];
    this.userID = this.localStorageService.getBoxxUserId();
    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));
    const sideMenuNavigation = this.newQuoteService
      .getSideMenu()
      ?.toLowerCase();
    if (sideMenuNavigation !== '') {
      this.newQuoteService.clearSideMenu();
      switch (sideMenuNavigation) {
        case 'new business':
          this.handleNav(null, 1);
          break;
        case 'renewal':
          this.handleNav(null, 2);
          break;
        case 'cancelled':
          this.handleNav(null, 3);
          break;
        default:
          break;
      }
    }
    this.store.pipe(select(getDashboardPath)).subscribe((item) => {
      if (item?.pathOptions?.path) {
        if (item.pathOptions?.path == 'upcoming renewals') {
          this.filterForm
            .get('underwriter')
            .setValue([
              { id: this.userID, name: item?.pathOptions?.underwriter },
            ]);
          this.handleNav(event, 2);
        }
      }
    });
    this.store.dispatch(new CreateQuoteAction.ResetState());
    this.newlyCreatedPolicyId = this.newQuoteService.getPackagePolicyNumber();
    if (this.newlyCreatedPolicyId) {
      const policyWarningMsg = this.newQuoteService.getPolicyBindWarningMsg();
      let alertData: any = {};
      if (policyWarningMsg === '') {
        alertData = {
          show: true,
          type: 'success',
          headerText: this.translateService.instant('common.successMsg'),
          bodyText:
            this.translateService.instant('policy.bound.head') +
            ' ' +
            this.newlyCreatedPolicyId +
            ' ' +
            this.translateService.instant('policy.bound.successMsg'),
        };
      } else {
        alertData = {
          show: true,
          type: 'warn',
          headerText: this.translateService.instant('common.warnMsg'),
          bodyText: policyWarningMsg,
        };
      }
      this.alertService.addAlert(alertData);
    }
    this.newQuoteService.clearPackagePolicyNumber();
    if (this.isRestrictedRoleSubmission === true) {
      this.showEllipsis = false;
      this.startQuoteBtnTxt = this.translateService.instant(
        'common.startNewSubmission',
      );
    }
    this.loadResults(1, 10, this.sortingKeys);

    this.riskRegionService.getAllRiskByUser(this.userID).subscribe((data) => {
      this.products = data.data.reduce((accumulator, value) => {
        return { ...accumulator, [value.name]: value };
      }, {});
      this.pdtOptions = data.data?.map((dataObj) => ({
        key: dataObj.name,
        value: dataObj.name,
        disabled: false,
        id: dataObj.id,
      }));
    });
  }

  isMinMaxValid(minValuePropName, maxValuePropName) {
    return (control: FormControl) => {
      if (!control || !control.parent) {
        return null;
      }
      let maxValue = 0;
      let minValue = 0;
      if (minValuePropName) {
        minValue = Number(control.parent.value[minValuePropName]);
        maxValue = Number(control.value);
      } else {
        maxValue = Number(control.parent.value[maxValuePropName]);
        minValue = Number(control.value);
      }

      if (maxValue < minValue) {
        return {
          customError: true,
        };
      } else {
        if (minValuePropName) {
          control.parent.controls[minValuePropName].errors = null;
          control.parent.controls[minValuePropName].status = 'VALID';
        } else {
          control.parent.controls[maxValuePropName].errors = null;
          control.parent.controls[maxValuePropName].status = 'VALID';
        }
      }

      return {};
    };
  }

  getFilteredValues() {
    let filteredOptions = [];
    const effectiveFrm = this.filterForm.value['effectiveFrm'];
    const effectiveTo = this.filterForm.value['effectiveTo'];
    const expiryFrm = this.filterForm.value['expiryFrm'];
    const expiryTo = this.filterForm.value['expiryTo'];
    if (effectiveFrm && effectiveTo) {
      filteredOptions.push(
        `${convertDate(effectiveFrm, this.shortDateFormat)} - ${convertDate(
          effectiveTo,
          this.shortDateFormat,
        )}`,
      );
    }
    if (expiryFrm) {
      filteredOptions.push(
        `${convertDate(expiryFrm, this.shortDateFormat)} - ${convertDate(
          expiryTo,
          this.shortDateFormat,
        )}`,
      );
    }
    const policyPeriodVal = this.filterForm.value['policyPeriod'];
    if (policyPeriodVal == 'boundToday') {
      filteredOptions.push('Bound today');
    } else if (policyPeriodVal == 'last7days') {
      filteredOptions.push('Last 7 days');
    } else if (policyPeriodVal == 'last30days') {
      filteredOptions.push('Last 30 days');
    }
    const brokerVal = this.filterForm.value['brokerageBranchProd'];
    if (brokerVal?.length > 0) {
      brokerVal.forEach((b) => {
        filteredOptions.push(b.name);
      });
    }
    const underWriter = this.filterForm.value['underwriter'];
    if (underWriter?.length > 0) {
      underWriter.forEach((uw) => {
        filteredOptions.push(uw.name);
      });
    }
    if (
      this.filterForm.value['minLimit'] != MIN_LIMIT ||
      this.filterForm.value['maxLimit'] != MAX_LIMIT
    ) {
      filteredOptions.push(
        `${this.filterForm.value['minLimit']} - ${this.filterForm.value['maxLimit']} LIMIT`,
      );
    }
    if (
      this.filterForm.value['minDeductible'] != MIN_DEDUCTIBLE ||
      this.filterForm.value['maxDeductible'] != MAX_DEDUCTIBLE
    ) {
      filteredOptions.push(
        `${this.filterForm.value['minDeductible']} - ${this.filterForm.value['maxDeductible']} DEDUCTIBLE`,
      );
    }
    if (
      this.filterForm.value['minPremium'] != MIN_PREMIUM ||
      this.filterForm.value['maxPremium'] != MAX_PREMIUM
    ) {
      filteredOptions.push(
        `${this.filterForm.value['minPremium']} - ${this.filterForm.value['maxPremium']} PREMIUM`,
      );
    }
    filteredOptions = [
      ...filteredOptions,
      ...this.filterForm.value['products'],
      ...this.filterForm.value['status'],
    ];
    return filteredOptions;
  }

  handleFilterOptnRemove(option) {
    if (
      option ==
      `${convertDate(
        this.filterForm.value['effectiveFrm'],
        this.shortDateFormat,
      )} - ${convertDate(
        this.filterForm.value['effectiveTo'],
        this.shortDateFormat,
      )}`
    ) {
      this.filterForm.controls['effectiveFrm'].setValue('');
      this.filterForm.controls['effectiveTo'].setValue('');
    } else if (
      option ==
      `${convertDate(
        this.filterForm.value['expiryFrm'],
        this.shortDateFormat,
      )} - ${convertDate(
        this.filterForm.value['expiryTo'],
        this.shortDateFormat,
      )}`
    ) {
      this.filterForm.controls['expiryFrm'].setValue('');
      this.filterForm.controls['expiryTo'].setValue('');
    } else if (
      option == 'Bound today' ||
      option == 'Last 7 days' ||
      option == 'Last 30 days'
    ) {
      this.filterForm.controls['policyPeriod'].setValue('');
    } else if (
      this.filterForm.value['brokerageBranchProd'].findIndex(
        (b) => b.name == option,
      ) !== -1
    ) {
      let updatedBrokVal = this.filterForm.value['brokerageBranchProd'].filter(
        (b) => b.name !== option,
      );
      this.filterForm.controls['brokerageBranchProd'].setValue(updatedBrokVal);
    } else if (
      this.filterForm.value['underwriter'].findIndex(
        (uw) => uw.name == option,
      ) !== -1
    ) {
      let updatedUWVal = this.filterForm.value['underwriter'].filter(
        (b) => b.name !== option,
      );
      this.filterForm.controls['underwriter'].setValue(updatedUWVal);
    } else if (
      this.filterForm.value['products'].findIndex((p) => p == option) !== -1
    ) {
      var index = this.filterForm.value['products'].indexOf(option);
      if (index !== -1) {
        this.filterForm.value['products'].splice(index, 1);
      }
    } else if (
      this.filterForm.value['status'].findIndex((st) => st == option) !== -1
    ) {
      var index = this.filterForm.value['status'].indexOf(option);
      if (index !== -1) {
        this.filterForm.value['status'].splice(index, 1);
      }
    } else if (option.includes('LIMIT')) {
      this.filterForm.controls['minLimit'].setValue(MIN_LIMIT);
      this.filterForm.controls['maxLimit'].setValue(MAX_LIMIT);
    } else if (option.includes('DEDUCTIBLE')) {
      this.filterForm.controls['minDeductible'].setValue(MIN_DEDUCTIBLE);
      this.filterForm.controls['maxDeductible'].setValue(MAX_DEDUCTIBLE);
    } else if (option.includes('PREMIUM')) {
      this.filterForm.controls['minPremium'].setValue(MIN_PREMIUM);
      this.filterForm.controls['maxPremium'].setValue(MAX_PREMIUM);
    }

    this.handleFiltering();
  }

  handleStartNewQuote() {
    this.store.dispatch(new CreateQuoteAction.ResetState());
    if (this.quoteFlowSteps === 'workflow3') {
      this.newQuoteService.clearInsuredId();
      this.router.navigate(['../workflow3'], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
      return;
    } else {
      this.router.navigate([`../${this.quoteFlowUrl}/new`], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
    }
  }

  handleNav(event, index) {
    const currentActiveIdx = this.subNavData.findIndex(
      (navObj: { name: string; active: boolean; value: string }) =>
        navObj.active,
    );
    if (currentActiveIdx != index) {
      let currentStatusFilters = this.filterForm.value['status'] ?? [];
      if (currentStatusFilters.includes(this.subNavData[index].value)) {
        this.filterForm.controls['status'].setValue([
          `${this.subNavData[index].value}`,
        ]);
      } else {
        this.filterForm.controls['status'].setValue([]);
      }

      this.subNavData[currentActiveIdx].active = false;
      this.subNavData[index].active = true;
      this.loadResults(1, 10, this.sortingKeys);
    }
  }

  pageChangeHandler(pageNumber) {
    if (pageNumber > 0) {
      this.loadResults(pageNumber, 10, this.sortingKeys);
    }
  }

  handleTableColSortUp(e) {
    let order = 'asc';
    this.sortingKeys = `${makeSorting(e, this.colPolicyHeader, order)},id:asc`;
    this.loadResults(this.pageNumber, 10, this.sortingKeys);
  }

  handleTableColSortDown(e) {
    let order = 'desc';
    this.sortingKeys = `${makeSorting(e, this.colPolicyHeader, order)},id:desc`;
    this.loadResults(this.pageNumber, 10, this.sortingKeys);
  }

  starClickHandler(index) {
    this.alertService.clearAlerts(-1);
    this.notificationAlert.show = false;
    const policyRiskTrxes = { ...this.policyList[index].policyRiskTrxes };
    const followUp = { followUp: policyRiskTrxes.followUp ? 0 : 1 };
    this.policyRiskTrxService
      .UpdateFollowUpByPolicyRiskTrxId(
        this.userID,
        policyRiskTrxes.id,
        followUp,
      )
      .subscribe({
        next: (_) => {
          this.tableData[index].showStar = !!!policyRiskTrxes.followUp;
          this.policyList[index].policyRiskTrxes.followUp =
            policyRiskTrxes.followUp ? 0 : 1;
          const alertData = {
            show: true,
            type: 'success',
            headerText: 'success!',
            bodyText: this.translateService.instant('policy.FollowUpSuccess'),
          };
          this.alertService.addAlert(alertData);
          this.loadResults(1, 10, this.sortingKeys);
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            const alertData = {
              show: true,
              type: 'error',
              headerText: 'error!',
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
          }
        },
      });
  }

  ellipsisClickHandler(index) {
    if (this.isRestrictedRoleSubmission === true) {
      this.alertService.clearAlerts(-1);
      const alertData = {
        type: 'warn',
        headerText: getAlertHead('warning'),
        bodyText: this.translateService.instant(
          'common.error.noAccessToPageErrorMsg',
        ),
      };
      this.alertService.addAlert(alertData);
      return;
    }
    const policyRiskTrxId = this.trxData[index]?.policyRiskTrxId;
    const policyPeriodId = this.trxData[index]?.policyPeriodId;
    const status = this.trxData[index]?.status;
    const insuredId = this.trxData[index]?.insuredId;
    const policyId = this.trxData[index]?.policyId;

    this.store.dispatch(new setInsuredIdAction(insuredId));
    this.store.dispatch(new setPolicyRiskTrxIdAction(policyRiskTrxId));
    this.store.dispatch(new setPolicyPeriodIdAction(policyPeriodId));
    this.store.dispatch(new setPolicyRiskTrxStatusAction(status));
    this.store.dispatch(new setPolicyIdAction(policyId));
    this.showSlideOut = true;
  }

  handleFilter() {
    this.showFilter = true;
    this.renderer.addClass(document.body, 'no-scroll');
  }

  makeFilter() {
    const brokerVal = this.filterForm.value['brokerageBranchProd'];
    const brokerIds = brokerVal
      .filter((item) => item.type.toLowerCase() === 'brokerage')
      .map((currentValue) => currentValue.id)
      .join(',');
    const branchIds = brokerVal
      .filter((item) => item.type.toLowerCase() === 'branch')
      .map((currentValue) => currentValue.id)
      .join(',');
    const producersIds = brokerVal
      .filter((item) => item.type.toLowerCase() === 'producer')
      .map((currentValue) => currentValue.id)
      .join(',');
    const productsVal = this.filterForm.value['products'];
    const productIds = productsVal.map((p) => this.products[p].id).join(',');
    const underwritersVal = this.filterForm.value['underwriter'];
    const underwriterIds = underwritersVal
      .map((currentValue) => currentValue.id)
      .join(',');
    const statusVal = this.filterForm.value['status']
      .map((f) => mapPolicy(f))
      .join(',');
    const effectiveFrm = this.filterForm.value['effectiveFrm'];
    const effectiveTo = this.filterForm.value['effectiveTo'];
    const expiryFrm = this.filterForm.value['expiryFrm'];
    const expiryTo = this.filterForm.value['expiryTo'];
    let rangeEffectiveDt;
    if (effectiveFrm && effectiveTo) {
      rangeEffectiveDt =
        getDateString(effectiveFrm, this.shortDateFormat) +
        ',' +
        getDateString(effectiveTo, this.shortDateFormat);
    }

    let rangeExpiryDt;
    if (expiryFrm && expiryTo) {
      rangeExpiryDt =
        getDateString(expiryFrm, this.shortDateFormat) +
        ',' +
        getDateString(expiryTo, this.shortDateFormat);
    }
    const quotePeriodVal = this.filterForm.value['policyPeriod'];
    let queotePeriod = 0;
    if (quotePeriodVal == 'boundToday') {
      queotePeriod = 1;
    } else if (quotePeriodVal == 'last7days') {
      queotePeriod = 7;
    } else if (quotePeriodVal == 'last30days') {
      queotePeriod = 30;
    }
    let limitRange;
    limitRange = `${this.filterForm.value[
      'minLimit'
    ].toString()},${this.filterForm.value['maxLimit'].toString()}`;

    let deductibleRange;
    deductibleRange = `${this.filterForm.value[
      'minDeductible'
    ].toString()},${this.filterForm.value['maxDeductible'].toString()}`;

    let premiumRange;
    premiumRange = `${this.filterForm.value[
      'minPremium'
    ].toString()},${this.filterForm.value['maxPremium'].toString()}`;

    const filter = {
      policyStatus: statusVal,
      trxDays: queotePeriod,
      underwriterId: underwriterIds,
      brokerageBORId: brokerIds,
      brokerageBranchBORId: branchIds,
      brokerageProducerBORId: producersIds,
      riskIds: productIds,
      effectiveDt: rangeEffectiveDt,
      expiryDt: rangeExpiryDt,
      limitRange: limitRange,
      deductibleRange: deductibleRange,
      premiumRange: premiumRange,
    };
    return filter;
  }

  handleFiltering() {
    this.loadResults(1, 10, this.sortingKeys);

    this.showFilter = false;
    this.renderer.removeClass(document.body, 'no-scroll');
  }

  handleSave() {
    this.showSlideOut = false;
  }

  handleClose() {
    this.showSlideOut = false;
  }

  loadResults(pageNumber, limit, sort = '') {
    this.showTblSpinner = true;
    this.tableData = this.tableHeaders;
    const currentActiveIdx = this.subNavData.findIndex(
      (navObj) => navObj.active,
    );
    const policyStatus = mapPolicy(this.subNavData[currentActiveIdx].name);

    let filter = this.makeFilter();
    let insuredTimezone = '';
    if (policyStatus) {
      if (filter.policyStatus) {
        filter.policyStatus += ',' + policyStatus;
      } else {
        filter = Object.assign(filter, { policyStatus: policyStatus });
      }
    }
    if (filter.trxDays) {
      filter = Object.assign(filter, { type: 'Bind' });
    }
    this.pageNumber = pageNumber;
    this.policyService
      .GetAllPolicyFilter(filter, pageNumber, limit, sort, policyStatus)
      .subscribe(
        (data) => {
          this.trxData = data.data?.map((dataObj) => ({
            insuredId: dataObj.policy.insured?.id,
            policyRiskTrxId: dataObj.policyRiskTrxes?.id,
            status: dataObj.policy.PolicyStatus.description,
            policyPeriodId: dataObj.policyPeriod?.id,
            policyId: String(dataObj.policy.pkgPolicyNumber),
          }));

          this.policyList = [...data.data];
          const tableData = data.data?.map((dataObj) => {
            const filterdVal = this.formatPolicyRiskList(
              dataObj.policyRiskTrxes.policyRisk,
            );
            insuredTimezone =
              dataObj.policy.insured.insuredLocation.timeZoneCode ??
              'America/New_York';
            return {
              'Policy ID': String(dataObj.policy.pkgPolicyNumber),
              Status: dataObj.policy.PolicyStatus.description,
              'Insured name': dataObj.policy.insured.companyName
                ? dataObj.policy.insured.companyName
                : dataObj.policy.insured.firstName +
                  ' ' +
                  dataObj.policy.insured.lastName,
              'Policy period': getFormattedPolicyPeriod(
                dataObj.policyPeriod,
                this.shortDateFormat,
              ),
              Risks: filterdVal.products,
              'Annual Premium': formatAmountWithCurrency(
                filterdVal.premium,
                this.currency,
              ),
            };
          });

          this.tableData =
            tableData.length == 0 ? this.tableHeaders : tableData;
          this.totalDataCount = data.pagination.totalRecords;
          this.pagination = data.pagination;
          this.showTblSpinner = false;
        },
        (error) => {
          this.showTblSpinner = false;
        },
      );
  }

  handleExport(): void {
    this.showTblSpinner = true;
    const currentActiveIdx = this.subNavData.findIndex(
      (navObj) => navObj.active,
    );
    const policyStatus = mapPolicy(this.subNavData[currentActiveIdx].name);

    let filter = this.makeFilter();
    if (policyStatus) {
      if (filter.policyStatus) {
        filter.policyStatus += ',' + policyStatus;
      } else {
        filter = Object.assign(filter, { policyStatus: policyStatus });
      }
    }
    if (filter.trxDays) {
      filter = Object.assign(filter, { type: 'Bind' });
    }
    filter = Object.assign(filter, { entryType: 'policy' });
    filter = Object.assign(filter, { limit: this.totalDataCount });
    filter = Object.assign(filter, { page: 1 });

    this.policyService.PolicyExport(filter, this.sortingKeys).subscribe(
      (data: ArrayBuffer) => {
        excelFileDownload('ExportedData.xlsx', data);
        this.showTblSpinner = false;
      },
      (error) => {
        this.showTblSpinner = false;
        const alertData = {
          show: true,
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: getErrorMessage(error),
        };
        this.alertService.addAlert(alertData);
      },
    );
  }

  formatPolicyRiskList(policyRiskList: any): {
    products: string;
    limit: string;
    premium: string;
  } {
    if (!policyRiskList || policyRiskList.length === 0) {
      return null;
    }
    let formattedObj: { products: string; limit: string; premium: string } = {
      products: '',
      limit: '',
      premium: '',
    };
    formattedObj.products = policyRiskList.map((item) => item.risk).join(', ');
    formattedObj.limit = policyRiskList
      .map((item) =>
        item.limit
          ? formatAmountWithCurrency(item.limit, this.currency)
          : formatAmountWithCurrency('0', this.currency),
      )
      .join(', ');
    formattedObj.premium = policyRiskList
      .map((item) =>
        item.premium
          ? formatAmountWithCurrency(item.premium, this.currency)
          : formatAmountWithCurrency('0', this.currency),
      )
      .join(', ');
    return formattedObj;
  }
  policyClickHandler(index) {
    if (this.isRestrictedRoleSubmission === true) {
      this.alertService.clearAlerts(-1);
      const alertData = {
        type: 'warn',
        headerText: getAlertHead('warning'),
        bodyText: this.translateService.instant(
          'common.error.noAccessToPageErrorMsg',
        ),
      };
      this.alertService.addAlert(alertData);
      return;
    }
    const policyID = this.tableData[index]['Policy ID'];
    this.router.navigate([`../policy/${policyID}`], {
      relativeTo: this.activatedRoute,
      skipLocationChange: true,
    });
  }

  toggleDocPreview() {
    this.showDocPreview = !this.showDocPreview;
  }

  handleDocDownload() {
    this.currentPreviewDocUrl = 'assets/docs/sampledocument.pdf';
    this.toggleDocPreview();
  }

  handleNotificationAlertClose() {
    this.notificationAlert.show = false;
  }
}
