<div class="wf3" [ngClass]="{ 'background-wf3': isSubmissionScreens }">
  <div
    class="header-container"
    *ngIf="showHeader && !isRestrictedRoleSubmission"
  >
    <div class="progressbar-header">
      <boxxds-progress-bar-v2
        [showProgressBar]="progressBarData.showProgressBar"
        [activeStep]="progressBarData.activeStep"
        [completedSteps]="progressBarData.completedSteps"
        [totalSteps]="progressBarData.totalSteps"
        [steps]="progressBarData.steps"
      ></boxxds-progress-bar-v2>
    </div>
    <ng-container *ngIf="showMaintenanceBanner()">
      <div class="maintenance-banner-holder">
        <app-maintenance-banner></app-maintenance-banner>
      </div>
    </ng-container>
    <div class="info-header" *ngIf="showInfoBanner">
      <app-info-banner-v2
        [insuredInfo]="insuredInfo"
        [brokerageInfo]="brokerageInfo"
        [showInfoBanner]="showInfoBanner"
        [quoteId]="quoteId"
        [hasNotifications]="false"
        [infoTitle]="infoTitle"
      >
      </app-info-banner-v2>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
