<div class="activity-info" *ngIf="createdBy && createdAt">
  <div class="activity" *ngIf="!!createdBy">
    <div class="title">{{ "common.createdBy" | translate }}:</div>
    <div class="details">
      <div class="user-details">{{ createdBy }}</div>
      <div class="date-and-time-details">{{ createdAt }}</div>
    </div>
  </div>
  <div class="activity" *ngIf="!!updatedBy">
    <div class="title">{{ "common.updatedBy" | translate }}:</div>
    <div class="details">
      <div class="user-details">{{ updatedBy }}</div>
      <div class="date-and-time-details">{{ updatedAt }}</div>
    </div>
  </div>
</div>
