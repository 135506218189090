<ng-container
  *ngFor="
    let item of commissionData?.data
      | paginate
        : {
            id: 'commission-pagination',
            itemsPerPage: 10,
            currentPage: currentPage,
            totalItems: commissionData?.pagination?.totalRecords
          };
    let indice = index
  "
>
  <div class="container-row" [ngStyle]="!isActive && { color: '#bdbdbf' }">
    <ng-container *ngIf="item.status !== 3">
      <app-view-commission [commission]="item"></app-view-commission>
    </ng-container>

    <ng-container *ngIf="item.status === 3">
      <app-update-commission
        [commission]="item"
        (handleCancelEdit)="handleCancelEdit($event)"
        (handleUpdating)="handleUpdating($event)"
      >
      </app-update-commission>
    </ng-container>
    <ng-container *ngIf="this.permissionList[this.currentScreen]">
      <ng-container *ngIf="item.status !== 3">
        <app-actions-commission
          [isActive]="isActive"
          [commission]="item"
          (handleEdit)="handleEditing($event)"
          (handleDisable)="handleDisabling($event)"
          (handleEnable)="handleEnabling($event)"
        >
        </app-actions-commission>
      </ng-container>
    </ng-container>
  </div>
  <hr />
</ng-container>
<div class="table-footer" *ngIf="commissionData?.pagination">
  <div class="total-count">
    Total records found:
    <span>{{ commissionData.pagination.totalRecords }}</span>
  </div>
  <div
    class="pagination-container"
    *ngIf="commissionData.pagination.totalPages > 1"
  >
    <pagination-template
      id="commission-pagination"
      #p1="paginationApi"
      (pageChange)="handlePageChange($event)"
    >
      <ul class="tbl-pagination">
        <li class="tbl-pagination-prev tbl-page-item">
          <a
            (keyup.enter)="p1.previous()"
            (click)="p1.previous()"
            [class.disabled]="p1.isFirstPage()"
            class="tbl-page-link"
            *ngIf="!p1.isFirstPage()"
            tabindex="0"
            aria-label="Previous"
            >Previous</a
          >
        </li>
        <li
          *ngFor="let page of p1.pages"
          [class.active]="p1.getCurrent() === page.value"
          class="tbl-page-item"
        >
          <a
            (keyup.enter)="p1.setCurrent(page.value)"
            (click)="p1.setCurrent(page.value)"
            class="tbl-page-link"
            tabindex="0"
          >
            {{ page.label }}
          </a>
        </li>
        <li
          class="pagination-next tbl-page-item"
          [class.disabled]="p1.isLastPage()"
        >
          <a
            (keyup.enter)="p1.next()"
            *ngIf="!p1.isLastPage()"
            (click)="p1.next()"
            class="tbl-page-link"
            tabindex="0"
            >Next</a
          >
        </li>
      </ul>
    </pagination-template>
  </div>
</div>
