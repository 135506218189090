import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private bid: string = 'bid';
  private sid: string = 'sid';
  private token: string = 'access_token';
  private signedin: string = 'signedin';
  private timeZone: string = 'timezone';
  private quoteFlowSteps: string = 'quoteFlowSteps';
  private mockExternalAPIs: string = 'mockExternalAPIs';

  clear(): void {
    localStorage.clear();
  }

  getBoxxUserId(): number {
    const value = localStorage.getItem(this.bid);
    if (value) {
      return Number(value);
    }

    return null;
  }

  setBoxxUserId(boxxUserId: string): void {
    localStorage.setItem(this.bid, boxxUserId);
  }

  removeBoxxUserId(): void {
    localStorage.removeItem(this.bid);
  }

  getSystemUserId(): number {
    const value = localStorage.getItem(this.sid);
    if (value) {
      return Number(value);
    }

    return null;
  }

  setSystemUserId(systemUserId: string): void {
    localStorage.setItem(this.sid, systemUserId);
  }

  removeSystemUserId(): void {
    localStorage.removeItem(this.sid);
  }

  getAccessToken(): string {
    const value = localStorage.getItem(this.token);
    return value ? value : null;
  }

  setAccessToken(token: string): void {
    localStorage.setItem(this.token, token);
  }

  removeAccessToken(): void {
    localStorage.removeItem(this.token);
  }

  getSignedin(): string {
    const value = localStorage.getItem(this.signedin);
    return value ? value : '';
  }

  setSignedin(isSignedIn: string): void {
    localStorage.setItem(this.signedin, isSignedIn);
  }

  removeSignedin(): void {
    localStorage.removeItem(this.signedin);
  }

  setTimeZone(timeZone: string): void {
    localStorage.setItem(this.timeZone, timeZone);
  }

  getTimeZone(): string {
    return localStorage.getItem(this.timeZone);
  }

  removeTimeZone(): void {
    this.getTimeZone() && localStorage.removeItem(this.timeZone);
  }

  setQuoteFlowSteps(quoteFlowSteps: string): void {
    localStorage.setItem(this.quoteFlowSteps, quoteFlowSteps);
  }

  getQuoteFlowSteps(): string {
    return localStorage.getItem(this.quoteFlowSteps);
  }

  removeQuoteFlowSteps(): void {
    return localStorage.removeItem(this.quoteFlowSteps);
  }

  setMockExternalAPIs(isMock: string): void {
    localStorage.setItem(this.mockExternalAPIs, isMock);
  }

  getMockExternalAPIs(): string {
    return localStorage.getItem(this.mockExternalAPIs);
  }
}
