import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-dropdown-menu-button-v2',
  templateUrl: './dropdown-menu-button-v2.component.html',
  styleUrls: ['./dropdown-menu-button-v2.component.less'],
})
export class DropdownMenuButtonV2Component {
  @Input() options: Array<any> | any;
  @Input() placeholder: string | any;
  @Input() btnStyle: { [klass: string]: any } | any;
  @Input() dropdownBtnStyle: { [klass: string]: any } | any;
  @Input() dropdownContainerStyle: { [klass: string]: any } | any;
  @Input() disabled: boolean = false;

  @Output() handleValueSelection = new EventEmitter();
  @Output() handleButtonClick = new EventEmitter();

  constructor(private elem: ElementRef) {}
  public dropdownOpen: boolean = false;
  public get dropdownElement(): Element {
    return this.elem.nativeElement.querySelector('.dropdown-list');
  }
  private currentIndex = -1;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvents($event: KeyboardEvent) {
    if (this.dropdownOpen) {
      $event.preventDefault();
    } else {
      return;
    }
    if ($event.code === 'ArrowUp') {
      if (this.currentIndex < 0) {
        this.currentIndex = 0;
      } else if (this.currentIndex > 0) {
        this.currentIndex--;
      }
      this.elem.nativeElement
        .querySelectorAll('li')
        .item(this.currentIndex)
        .focus();
    } else if ($event.code === 'ArrowDown') {
      if (this.currentIndex < 0) {
        this.currentIndex = 0;
      } else if (this.currentIndex < this.options.length - 1) {
        this.currentIndex++;
      }
      this.elem.nativeElement
        .querySelectorAll('li')
        .item(this.currentIndex)
        .focus();
    } else if (
      ($event.code === 'Enter' || $event.code === 'NumpadEnter') &&
      this.currentIndex >= 0
    ) {
      this.selectByIndex(this.currentIndex);
    } else if ($event.code === 'Escape') {
      this.closeDropdown();
    }
  }

  closeDropdown() {
    this.dropdownElement.setAttribute('aria-expanded', 'false');
    this.currentIndex = -1;
    this.dropdownOpen = false;
  }

  selectByIndex(i: number) {
    let value = this.options[i];
    this.select(value);
  }

  select(valueObj: any) {
    this.closeDropdown();
    this.handleValueSelection.emit(valueObj.value);
  }

  toggleDropdown(event: any = null) {
    if (event) {
      event.stopPropagation();
    }

    this.dropdownOpen = !this.dropdownOpen;
    this.dropdownElement.setAttribute(
      'aria-expanded',
      this.dropdownOpen ? 'true' : 'false',
    );
  }

  handleBtnClick() {
    this.handleButtonClick.emit();
  }
}
