<div class="holder-wrapper">
  <ng-container *ngIf="form$ | async as form">
    <div
      class="holder"
      [ngClass]="{ 'spinner-on': showBodySpinner || spinnerCounter > 0 }"
    >
      <ng-container *ngIf="showBodySpinner || spinnerCounter > 0">
        <div class="body-spinner-policy-configuration">
          <boxxds-spinner
            [containerStyle]="{
              width: '112px',
              height: '112px',
              '--b': '15px'
            }"
          ></boxxds-spinner>
        </div>
      </ng-container>

      <app-modal-send-quote
        *ngIf="showSendModal"
        [isLoader]="
          isLoader === true || this.templateDetails.length === 0 ? true : false
        "
        [tempType]="tempType"
        [showModal]="showSendModal"
        (handleClose)="closeSendPackage($event)"
        [formDetails]="form.ui"
        [templateDetails]="templateDetails"
        [docDetails]="docDetails"
        [title]="isQuoteBounded"
        (handleSend)="handleSend($event)"
        (docGenFinishHandler)="docGenFinishHandler()"
        [templateSelectionPlaceholder]="
          !isQuoteBounded
            ? ('workFlow3.components.policyConfiguration.placeholder.quoteTemplate'
              | translate)
            : ('workFlow3.components.policyConfiguration.placeholder.policyTemplate'
              | translate)
        "
        [title]="
          !isQuoteBounded
            ? ('workFlow3.components.policyConfiguration.label.sendingQuoteSummary'
              | translate)
            : ('workFlow3.components.policyConfiguration.label.sendingBindingPackage'
              | translate)
        "
      >
      </app-modal-send-quote>
      <app-modal-send-quote
        *ngIf="showSendCompareModal"
        [isLoader]="
          isLoader === true || this.templateDetails.length === 0 ? true : false
        "
        [showModal]="showSendCompareModal"
        (handleClose)="closeSendPackage()"
        [formDetails]="form.ui"
        [templateDetails]="templateDetails"
        [docDetails]="docDetails"
        [templateSelectionPlaceholder]="
          'workFlow3.components.policyConfiguration.placeholder.quoteTemplate'
            | translate
        "
        [title]="
          'workFlow3.components.policyConfiguration.label.sendingQuoteOptionComparison'
            | translate
        "
        (handleSend)="handleSendCompare($event)"
        (docGenFinishHandler)="docGenFinishHandler()"
      >
      </app-modal-send-quote>

      <ng-container *ngIf="!showBodySpinner && spinnerCounter === 0">
        <app-quote-action-popup
          [showModal]="showActionPopup"
          [details]="actionPopupDetails"
          (handleActionConfirm)="
            this.permissionList[this.currentScreen] &&
              handleConfirmRiskAction($event)
          "
          (handleClose)="showActionPopup = false"
        >
        </app-quote-action-popup>

        <app-document-preview-v2
          *ngIf="showDocPreview"
          [docUrl]="currentPreviewDocUrl"
          (handleClose)="toggleDocPreview()"
        >
        </app-document-preview-v2>
        <!-- Policy configuration wf3 -->
        <div class="wf3-container-wrapper submissionflow3-page">
          <section class="policy-period-underwriter">
            <div class="policy-period-switch-row">
              <div class="policy-period-switch-frame">
                <button
                  class="button-switch-set-policy-period"
                  [ngClass]="{
                    'btn-active':
                      policyConfigurationForm.value.policy_period_control === ''
                  }"
                  (click)="toggleTbdAndPolicyPeriod('')"
                >
                  {{
                    "workFlow3.components.policyConfiguration.button.setPolicyPeriod"
                      | translate
                  }}
                </button>
                <button
                  class="button-switch-set-tbd"
                  [ngClass]="{
                    'btn-active':
                      policyConfigurationForm.value.policy_period_control ===
                      'TBD'
                  }"
                  (click)="toggleTbdAndPolicyPeriod('TBD')"
                >
                  {{
                    "workFlow3.components.policyConfiguration.button.policyPeriodTBD"
                      | translate
                  }}
                </button>
              </div>
              <div class="underwriter-dropdown-holder">
                <boxxds-dropdown-v2
                  [form]="policyConfigurationForm"
                  [control]="'underwriter'"
                  [placeholder]="'Select underwriter' | translate"
                  (currentValueChange)="
                    this.permissionList[this.currentScreen] &&
                      this.currentQuoteStatus !== 'quote-closed' &&
                      underwriterChanged($event, activeProductNavTab)
                  "
                  [isRequired]="true"
                  [options]="underWriterOptions"
                  [label]="'Underwriter' | translate"
                  class="underwriter-input-fields"
                  [requiredErrMsg]="'text msg'"
                  [formSubmitted]="false"
                  [currentValue]="underWriters[activeProductNavTab]"
                  [currentSelectedOption]="{
                    id: 1,
                    value: 'string',
                    key: 'string'
                  }"
                  (loadMoreOptions)="
                    this.permissionList[this.currentScreen] &&
                      this.currentQuoteStatus !== 'quote-closed' &&
                      loadMoreUnderwriters($event)
                  "
                  [hasScrollablePagination]="true"
                  [totalCount]="underwriterTotalCount"
                  [totalPages]="underwriterTotalPages"
                  [isSuggestedOption]="true"
                  [hasOptionImage]="true"
                  [isDisabled]="
                    !this.permissionList[this.currentScreen] ||
                    this.currentQuoteStatus === 'quote-closed' ||
                    disabledDropdownList.includes(products[activeProductNavTab])
                  "
                  [dropdownContainerStyle]="{
                    maxWidth: '249px',
                    minWidth: '249px',
                    backgroundColor: '#fff',
                    borderRadius: '8px'
                  }"
                  [dropdownListStyle]="{
                    zIndex: '1'
                  }"
                ></boxxds-dropdown-v2>
              </div>
            </div>
            <div
              class="policy-period-dates"
              *ngIf="policyConfigurationForm.value.policy_period_control === ''"
            >
              <div class="policy-period-date-pickers">
                <ng-container *ngIf="form$ | async as asyncForm">
                  <boxxds-datepicker-v2
                    [placeholder]="shortDateFormat"
                    [dateFormat]="shortDateFormat"
                    [label]="'Effective date' | translate"
                    [isRequired]="true"
                    [containerStyle]="{ width: '200px' }"
                    [bsValue]="isTBDValid ? '' : (form$ | async)?.ui.startDate"
                    (bsValueChange)="
                      !isTBDValid &&
                        this.permissionList[this.currentScreen] &&
                        this.currentQuoteStatus !== 'quote-closed' &&
                        handleStartDateChange($event, asyncForm?.ui.endDate)
                    "
                    [minDate]="minStartDate"
                    [maxDate]="maxStartDate"
                    [showErr]="!isStartDateValid"
                    [errMsg]="startDateErrMsg | translate"
                    [isDisabled]="
                      isTBDValid ||
                      !this.permissionList[this.currentScreen] ||
                      this.currentQuoteStatus === 'quote-closed'
                    "
                  >
                  </boxxds-datepicker-v2>
                  <boxxds-datepicker-v2
                    [bsValue]="isTBDValid ? '' : (form$ | async)?.ui.endDate"
                    [label]="'common.expiredDate' | translate"
                    (bsValueChange)="
                      !isTBDValid &&
                        this.permissionList[this.currentScreen] &&
                        this.currentQuoteStatus !== 'quote-closed' &&
                        handleEndDateChange($event, asyncForm?.ui.startDate)
                    "
                    [placeholder]="shortDateFormat"
                    [dateFormat]="shortDateFormat"
                    [containerStyle]="{ width: '200px' }"
                    [isRequired]="true"
                    [minDate]="minEndDate"
                    [maxDate]="maxEndDate"
                    [showErr]="!isEndDateValid"
                    [errMsg]="endDateErrMsg | translate"
                    [isDisabled]="isTBDValid"
                  >
                  </boxxds-datepicker-v2>

                  <!-- P &P -->

                  <boxxds-datepicker-v2
                    [bsValue]="
                      isTBDValid ? '' : (form$ | async)?.ui.pendingPriorDt
                    "
                    [label]="
                      'workFlow3.components.policyConfiguration.label.litigationDate'
                        | translate
                    "
                    (bsValueChange)="
                      !isTBDValid &&
                        this.permissionList[this.currentScreen] &&
                        this.currentQuoteStatus !== 'quote-closed' &&
                        handlePendingPriorDateChange(
                          $event,
                          asyncForm?.ui.startDate,
                          asyncForm?.ui.endDate
                        )
                    "
                    [placeholder]="shortDateFormat"
                    [dateFormat]="shortDateFormat"
                    [containerStyle]="{ width: '200px' }"
                    [showErr]="!isPendingPriorDtValid"
                    [errMsg]="pendingPriorDtErrMsg | translate"
                    [isDisabled]="isTBDValid"
                  >
                  </boxxds-datepicker-v2>

                  <!-- retro -->
                  <boxxds-datepicker-v2
                    [bsValue]="
                      isTBDValid ? '' : (form$ | async)?.ui.retroactiveDt
                    "
                    [label]="
                      'workFlow3.components.policyConfiguration.label.retroactiveDate'
                        | translate
                    "
                    (bsValueChange)="
                      !isTBDValid &&
                        this.permissionList[this.currentScreen] &&
                        this.currentQuoteStatus !== 'quote-closed' &&
                        handleRetroactiveDateChange(
                          $event,
                          asyncForm?.ui.startDate,
                          asyncForm?.ui.endDate
                        )
                    "
                    [placeholder]="shortDateFormat"
                    [dateFormat]="shortDateFormat"
                    [containerStyle]="{ width: '200px' }"
                    [showErr]="!isRetroactiveDtValid"
                    [errMsg]="retroactiveDtErrMsg | translate"
                    [isDisabled]="isTBDValid"
                  >
                  </boxxds-datepicker-v2>
                </ng-container>
              </div>
            </div>
          </section>

          <section class="products-and-documents">
            <div class="title">
              <p>
                Manage policy documents for each product.<span
                  class="required-star"
                  >*</span
                >
              </p>
            </div>
            <div class="products-and-documents-container">
              <ul class="product-navbar">
                <ng-container
                  *ngFor="let product of products; let productIndex = index"
                >
                  <li
                    class="product-tab"
                    [class.activeTab]="activeProductNavTab === productIndex"
                  >
                    <p class="product-name">{{ product }}</p>
                  </li>
                </ng-container>
              </ul>
              <div class="product-details-container">
                <div class="policy-configuration">
                  <div class="sub-header">
                    <span>{{
                      "workFlow3.components.policyConfiguration.heading.policyConfiguration"
                        | translate
                    }}</span>
                  </div>
                  <div class="policy-config-section-sub-header">
                    {{
                      "workFlow3.components.policyConfiguration.heading.policyConfigSubHeading"
                        | translate
                    }}
                  </div>
                  <div class="doc-tabs-holder">
                    <div class="doc-config-btn-container">
                      <boxxds-dropdown-v2
                        [showLabel]="false"
                        [form]="docConfigForm"
                        [control]="'document'"
                        [placeholder]="'Select' | translate"
                        (currentValueChange)="
                          this.permissionList[this.currentScreen] &&
                            this.currentQuoteStatus !== 'quote-closed' &&
                            docConfigChanged($event)
                        "
                        [options]="docConfigOptions"
                        class="doc-config-dropdown"
                        [formSubmitted]="false"
                        [currentValue]="docConfigForm.value['document']"
                        [isDisabled]="
                          !this.permissionList[this.currentScreen] ||
                          this.currentQuoteStatus === 'quote-closed' ||
                          disabledDropdownList.includes(
                            products[activeProductNavTab]
                          )
                        "
                        [dropdownContainerStyle]="{
                          width: '159px'
                        }"
                        [btnStyle]="{
                          backgroundColor: '#FFF',
                          borderRadius: '8px 0px 0px 8px',
                          borderTop: '1px solid v#D0D5DD',
                          borderBottom: '1px solid #D0D5DD',
                          borderLeft: '1px solid #D0D5DD',
                          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'
                        }"
                        [dropdownListStyle]="{
                          borderRadius: '8px 0px 8px 8px'
                        }"
                        [dropdownListStyle]="{ margin: '0px' }"
                      ></boxxds-dropdown-v2>
                      <boxxds-button-v2
                        *ngIf="!showBodySpinner"
                        [buttonText]="''"
                        [leftImage]="'search-lg'"
                        btnType="secondary gray"
                        [btnStyle]="{
                          width: '44px',
                          height: '44px',
                          marginRight: '12px',
                          fontWeight: '600',
                          fontColor: '#2D2D39',
                          fontSize: '16px',
                          padding: '10px 16px',
                          border: '1px solid #D0D5DD',
                          borderRadius: '0px 8px 8px 0px',
                          backgroundColor: '#FFF',
                          borderLeft: '0px'
                        }"
                        type="submit"
                        (handleClick)="
                          handlePolicyConfigSelect(
                            products[activeProductNavTab]
                          )
                        "
                        [isDisabled]="
                          !this.permissionList[this.currentScreen] ||
                          this.currentQuoteStatus === 'quote-closed'
                        "
                      >
                      </boxxds-button-v2>
                    </div>
                    <div class="doc-config-buttons">
                      <app-button-group
                        [buttons]="docConfigButtons"
                        (handleButtonClick)="handleDocConfigBtnClick($event)"
                      ></app-button-group>
                    </div>
                  </div>
                  <app-modal-subjectivities
                    *ngIf="showModalSubjectivities"
                    [showModal]="showModalSubjectivities"
                    [riskRegionId]="riskRegionId"
                    (handleClose)="closeSubjectivityPopup()"
                    [product]="products[activeProductNavTab]"
                    [selectedSubjectivities]="selectedSubjectivities"
                    (addHandler)="handleAddSubjectivitiesFromPopup($event)"
                  >
                  </app-modal-subjectivities>
                  <app-modal-endorsements
                    *ngIf="showModalEndorsements"
                    [showModal]="showModalEndorsements"
                    [riskRegionId]="riskRegionId"
                    (handleClose)="closeEndorsementPopup()"
                    [policyRiskIds]="policyRiskIds"
                    [products]="products"
                    [product]="products[activeProductNavTab]"
                    [selectedEndorsements]="selectedEndorsements"
                    (addHandler)="handleAddEndorsementsFromPopup($event)"
                    [templateCodeForEndorsement]="templateCodeForEndorsement"
                  >
                  </app-modal-endorsements>
                  <app-modal-declarations
                    *ngIf="showModalDeclarations"
                    [showModal]="showModalDeclarations"
                    [riskRegionId]="riskRegionId"
                    (handleClose)="closeDeclarationPopup()"
                    [policyRiskIds]="policyRiskIds"
                    [products]="products"
                    [product]="products[activeProductNavTab]"
                    [selectedDeclarations]="selectedDeclarations"
                    (addHandler)="handleAddDeclarationFromPopup($event)"
                    [templateCodeForDeclaration]="templateCodeForDeclaration"
                  >
                  </app-modal-declarations>
                  <app-modal-wordings
                    *ngIf="showModalWordings"
                    [showModal]="showModalWordings"
                    [riskRegionId]="riskRegionId"
                    (handleClose)="closeWordingPopup()"
                    [policyRiskIds]="policyRiskIds"
                    [products]="products"
                    [product]="products[activeProductNavTab]"
                    [selectedWordings]="selectedWordings"
                    (addHandler)="handleAddWordingFromPopup($event)"
                    [templateCodeForWording]="templateCodeForWordings"
                  >
                  </app-modal-wordings>
                  <div
                    class="list-container"
                    [ngStyle]="
                      !(
                        docTempDetails.documentTemplate &&
                        docTempDetails.documentTemplate.length > 0
                      ) && { borderBottom: 'none' }
                    "
                  >
                    <div
                      class="subjectivities-container"
                      *ngIf="
                        docConfigButtons[0].active || docConfigButtons[1].active
                      "
                    >
                      <div class="subjectivities-header">
                        <div class="heading">
                          <div class="mr-2">
                            {{ "Subjectivities" | translate }}
                          </div>
                          <div class="badge">
                            {{
                              selectedSubjectivities[
                                products[activeProductNavTab]
                              ]?.length
                            }}
                          </div>
                        </div>
                        <div class="comply">
                          <boxxds-checkbox-v2
                            [options]="subjectivitiesComplyCheckbox"
                            (handleCheckbox)="
                              handleSubjectivitiesComplyCheckbox(
                                $event,
                                selectedSubjectivities[
                                  products[activeProductNavTab]
                                ]
                              )
                            "
                            [containerStyle]="{ display: 'flex' }"
                            [lblStyle]="{
                              color: '#475467',
                              fontSize: '12px',
                              fontStyle: 'normal',
                              fontWeight: '500',
                              lineHeight: '18px',
                              fontFamily: 'Gibson'
                            }"
                          ></boxxds-checkbox-v2>
                        </div>
                      </div>
                      <div
                        class="subjectivities-list"
                        [ngStyle]="
                          docConfigButtons[1].active && { maxHeight: '534px' }
                        "
                      >
                        <div
                          class="subjectivity"
                          *ngFor="
                            let sub of selectedSubjectivities[
                              products[activeProductNavTab]
                            ];
                            let selectedIdx = index
                          "
                        >
                          <div
                            class="subjectivity-name"
                            [ngClass]="{ 'edit-mode': sub.edit }"
                          >
                            <ng-container *ngIf="!sub.edit">
                              <span
                                [ngClass]="{ 'non-mandatory': !sub.mandatory }"
                                >{{ sub.key }}</span
                              >
                              <span
                                class="mandatory-asterik"
                                *ngIf="sub.mandatory"
                                >*</span
                              >
                            </ng-container>
                            <textarea
                              *ngIf="sub.edit"
                              type="text"
                              class="listKey-textArea"
                              [(ngModel)]="editedSubjectivity"
                              [value]="editedSubjectivity"
                              maxLength="1000"
                            ></textarea>
                          </div>
                          <div
                            class="subjectivity-comply"
                            [ngClass]="{
                              'mandatory-subjectivity': sub.mandatory,
                              'non-mandatory': !sub.mandatory
                            }"
                          >
                            <boxxds-checkbox-v2
                              [options]="[
                                {
                                  name: '',
                                  value: true,
                                  checked: sub.isCompliedWith,
                                  id: sub.id
                                }
                              ]"
                              (handleCheckbox)="
                                this.permissionList[this.currentScreen] &&
                                  this.currentQuoteStatus !== 'quote-closed' &&
                                  updateCompliance(
                                    selectedIdx,
                                    products[activeProductNavTab]
                                  )
                              "
                              [containerStyle]="{ display: 'flex' }"
                              [lblStyle]="{
                                color: '#475467',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '18px',
                                fontFamily: 'Gibson'
                              }"
                            ></boxxds-checkbox-v2>
                            <div class="btn-container" *ngIf="!sub.mandatory">
                              <ng-container *ngIf="!sub.edit">
                                <button
                                  class="edit-button"
                                  (click)="
                                    editSubjectivity(
                                      products[activeProductNavTab],
                                      sub,
                                      1
                                    )
                                  "
                                >
                                  <img src="assets/img/edit.svg" alt="edit" />
                                </button>
                                <button
                                  class="delete-button ml-2"
                                  (click)="
                                    deleteSubjectivity(
                                      products[activeProductNavTab],
                                      sub,
                                      selectedIdx
                                    )
                                  "
                                >
                                  <img
                                    src="assets/img/trash-01.svg"
                                    alt="delete"
                                  />
                                </button>
                              </ng-container>
                              <ng-container *ngIf="sub.edit">
                                <button
                                  class="edit-confirm"
                                  (click)="
                                    confirmEditSubjectivity(
                                      products[activeProductNavTab],
                                      sub
                                    )
                                  "
                                >
                                  <img
                                    src="assets/img/check-01.svg"
                                    alt="confirm"
                                  />
                                </button>
                                <button
                                  class="delete-button ml-2"
                                  (click)="
                                    editSubjectivity(
                                      products[activeProductNavTab],
                                      sub,
                                      0
                                    )
                                  "
                                >
                                  <img
                                    src="assets/img/x-close-01.svg"
                                    alt="close"
                                  />
                                </button>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="endorsements-container"
                      *ngIf="
                        docConfigButtons[0].active || docConfigButtons[2].active
                      "
                    >
                      <div class="endorsements-header">
                        <div class="endorsements-heading">
                          <div class="mr-2">
                            {{ "Endorsements" | translate }}
                          </div>
                          <div class="badge">
                            {{
                              selectedEndorsements[
                                products[activeProductNavTab]
                              ]?.length
                            }}
                          </div>
                        </div>
                      </div>
                      <div
                        class="endorsements-list"
                        [ngStyle]="
                          docConfigButtons[2].active && { maxHeight: '534px' }
                        "
                      >
                        <div
                          class="endorsement"
                          *ngFor="
                            let doc of selectedEndorsements[
                              products[activeProductNavTab]
                            ];
                            let selectedIdx = index
                          "
                        >
                          <div
                            class="endorsement-name"
                            [ngClass]="{ 'edit-mode': doc.edit }"
                          >
                            <ng-container *ngIf="!doc.edit">
                              <button
                                class="preview-button ml-2"
                                (click)="handleDocClick(doc)"
                              >
                                <img
                                  *ngIf="doc.ignoreTemplate === false"
                                  src="assets/img/eye.svg"
                                  alt="preview"
                                />
                                <img
                                  *ngIf="doc.ignoreTemplate === true"
                                  src="assets/img/eye-off.svg"
                                  alt="preview"
                                />
                              </button>
                              <div class="end-name-text">
                                <span
                                  [ngClass]="{
                                    'non-mandatory': !doc.mandatory
                                  }"
                                  >{{ doc.key }}</span
                                >
                              </div>
                              <span
                                class="mandatory-asterik"
                                *ngIf="doc.mandatory"
                                >*</span
                              >
                            </ng-container>
                            <textarea
                              *ngIf="doc.edit"
                              type="text"
                              class="listKey-textArea"
                              [(ngModel)]="editedEndorsement"
                              [value]="editedEndorsement"
                              maxLength="100"
                            ></textarea>
                          </div>

                          <div class="btn-container" *ngIf="!doc.mandatory">
                            <ng-container *ngIf="!doc.edit">
                              <button
                                class="edit-button"
                                *ngIf="
                                  isCustomEndorsement(
                                    products[activeProductNavTab],
                                    doc
                                  )
                                "
                                (click)="
                                  editEndorsement(
                                    products[activeProductNavTab],
                                    doc,
                                    1
                                  )
                                "
                              >
                                <img src="assets/img/edit.svg" alt="edit" />
                              </button>
                              <button
                                class="delete-button ml-2"
                                (click)="
                                  deleteEndorsementSelect(
                                    products[activeProductNavTab],
                                    doc,
                                    selectedIdx
                                  )
                                "
                              >
                                <img
                                  src="assets/img/trash-01.svg"
                                  alt="delete"
                                />
                              </button>
                            </ng-container>
                            <ng-container *ngIf="doc.edit">
                              <button
                                class="edit-confirm"
                                (click)="
                                  confirmEditEndorsement(
                                    products[activeProductNavTab],
                                    doc
                                  )
                                "
                              >
                                <img
                                  src="assets/img/check-01.svg"
                                  alt="confirm"
                                />
                              </button>
                              <button
                                class="delete-button ml-2"
                                (click)="
                                  editEndorsement(
                                    products[activeProductNavTab],
                                    doc,
                                    0
                                  )
                                "
                              >
                                <img
                                  src="assets/img/x-close-01.svg"
                                  alt="close"
                                />
                              </button>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="wording-declaration-container"
                      *ngIf="docConfigButtons[0].active"
                    >
                      <div class="wording-declaration-header">
                        <div class="wording-declaration-heading">
                          <div class="mr-2">
                            {{ "Wording/Declaration" | translate }}
                          </div>
                          <div class="badge">
                            {{
                              selectedWordings[products[activeProductNavTab]]
                                ?.length +
                                selectedDeclarations[
                                  products[activeProductNavTab]
                                ]?.length
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="wording-declaration-list">
                        <div
                          class="wording"
                          *ngFor="
                            let doc of selectedWordings[
                              products[activeProductNavTab]
                            ];
                            let selectedWordingsIdx = index
                          "
                          (click)="handleDocClick(doc)"
                        >
                          <div class="name">
                            <span
                              [ngClass]="{ 'non-mandatory': !doc.mandatory }"
                              >{{ doc.key }}</span
                            >
                            <span
                              class="mandatory-asterik"
                              *ngIf="doc.mandatory"
                              >*</span
                            >
                          </div>
                        </div>
                        <div
                          class="declaration"
                          *ngFor="
                            let doc of selectedDeclarations[
                              products[activeProductNavTab]
                            ];
                            let selectedWordingsIdx = index
                          "
                          (click)="handleDocClick(doc)"
                        >
                          <div class="name">
                            <span
                              [ngClass]="{ 'non-mandatory': !doc.mandatory }"
                              >{{ doc.key }}</span
                            >
                            <span
                              class="mandatory-asterik"
                              *ngIf="doc.mandatory"
                              >*</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="document-templates"
                  *ngIf="
                    docTempDetails.documentTemplate &&
                    docTempDetails.documentTemplate.length > 0
                  "
                >
                  <div class="sub-header">
                    <span>{{
                      "workFlow3.components.policyConfiguration.heading.templates"
                        | translate
                    }}</span>
                  </div>
                  <div class="templates-section-sub-header">
                    {{
                      "workFlow3.components.policyConfiguration.heading.templatesSubHeading"
                        | translate
                    }}
                  </div>
                  <app-doc-template-selection
                    *ngIf="
                      docTempDetails.documentTemplate &&
                      docTempDetails.documentTemplate.length > 0
                    "
                    [docTempContainerStyle]="{}"
                    [details]="docTempDetails"
                    [productId]="policyRiskId"
                    (handleOutput)="handleDocTempSelectionOutput($event)"
                  ></app-doc-template-selection>
                </div>
              </div>
            </div>
            <!-- Policy document template selection wf3 -->

            <!-- Policy document template selection wf3 -->
          </section>

          <!-- Quote selection section -->
          <section class="quote-selection-section">
            <app-quote-selection
              *ngIf="quoteOptionsLoaded"
              [products]="products"
              [policyPremium]="policyPremium"
              (loadPage)="loadPremiumValues()"
              [triaAmountDefault]="triaAmountDefault"
              [currentQuoteStatus]="currentQuoteStatus"
              [policyFeeDefault]="policyFeeDefault"
              [policyPeriodId]="policyPeriodId"
              [quoteOptions]="quoteOptions"
              [selectedQuoteOptions]="selectedQuoteOptionIds"
              [selectedQuoteOptionsNumbers]="selectedQuoteOptionsNumbers"
              [selectedQuoteIndex]="selectedQuoteIndex"
              (handleQuoteSelected)="handleQuoteSelected($event)"
              (handleComparisonSend)="handleComparisonSend()"
              (handleToggleChange)="handleToggleChange($event)"
              [currency]="currency"
              (handleTriaFeeChange)="handleTriaFeeChange($event)"
              (handleSaveAndReload)="handleSaveForTriaPolicy()"
              (sendDropdownDynamicUpdate)="sendDropdownDynamicUpdate($event)"
              [isReadOnly]="
                !this.permissionList[this.currentScreen] ||
                this.currentQuoteStatus === 'quote-closed'
              "
            ></app-quote-selection>
          </section>
          <!-- Quote selection section -->

          <!-- Footer -->
          <ng-container *ngIf="!showBodySpinner && spinnerCounter === 0">
            <app-footer-wrapper>
              <div class="footer-button-list">
                <div class="button-section">
                  <boxxds-button-v2
                    class="linkButton"
                    (click)="handleBackBtn()"
                    [size]="'sm'"
                    [btnClass]="'link gray'"
                    [leftImage]="'arrow-narrow-left'"
                    [buttonText]="
                      'workFlow3.components.shared.button.back' | translate
                    "
                    [leftImage]="'arrow-narrow-left'"
                  >
                  </boxxds-button-v2>
                </div>
                <div class="button-section">
                  <ng-container
                    *ngIf="
                      this.permissionList[this.currentScreen] &&
                      this.currentQuoteStatus !== 'quote-closed'
                    "
                  >
                    <boxxds-button-v2
                      *ngIf="!showBodySpinner"
                      [buttonText]="
                        'workFlow3.components.policyConfiguration.label.saveAndExit'
                          | translate
                      "
                      btnType="secondary gray"
                      [btnStyle]="{
                        width: '145px',
                        height: '44px',
                        marginRight: '12px',
                        fontWeight: '600',
                        fontColor: '#2D2D39',
                        fontSize: '16px',
                        padding: '10px 16px',
                        border: '1px solid #D0D5DD'
                      }"
                      type="submit"
                      (handleClick)="handleSaveDraft()"
                    >
                    </boxxds-button-v2>
                  </ng-container>
                  <div
                    class="btns-holder d-flex"
                    *ngIf="
                      this.permissionList[this.currentScreen] &&
                      this.currentQuoteStatus !== 'quote-closed'
                    "
                  >
                    <div
                      class="btns-holder d-flex"
                      *ngIf="
                        this.permissionList[this.currentScreen] &&
                        this.currentQuoteStatus !== 'quote-closed'
                      "
                    >
                      <ng-container *ngIf="quoteOptions">
                        <boxxds-button-v2
                          type="button"
                          [btnType]="'secondary'"
                          (click)="($event)"
                          [buttonText]="'Send'"
                          [btnStyle]="{
                            width: '219px',
                            height: '44px',
                            marginRight: '12px',
                            fontWeight: '600',
                            fontSize: '16px',
                            padding: '10px 16px'
                          }"
                          (handleClick)="
                            handleSendQuoteAfterSaving(modalPopupType)
                          "
                        >
                        </boxxds-button-v2>
                      </ng-container>
                    </div>
                  </div>
                  <ng-container
                    *ngIf="
                      this.permissionList[this.currentScreen] &&
                      this.currentQuoteStatus !== 'quote-closed'
                    "
                  >
                    <boxxds-button-v2
                      [isDisabled]="
                        isTBDValid ||
                        !isStartDateValid ||
                        !isEndDateValid ||
                        isTriaFeeChange ||
                        modalPopupType !== 'Single option' ||
                        this.transactionLvlQuoteStatus === 'Not Taken Up'
                      "
                      [class.noPointerEvents]="
                        isTBDValid ||
                        !isStartDateValid ||
                        !isEndDateValid ||
                        isTriaFeeChange ||
                        modalPopupType !== 'Single option' ||
                        this.transactionLvlQuoteStatus === 'Not Taken Up'
                      "
                      *ngIf="!showBodySpinner"
                      [buttonText]="'common.bindPolicy' | translate"
                      btnType="primary"
                      [btnStyle]="{
                        width: '219px',
                        padding: '11px 16px'
                      }"
                      type="submit"
                      (handleClick)="
                        this.transactionLvlQuoteStatus !== 'Not Taken Up' &&
                          handleProductSummaryAndBindPolicy()
                      "
                    >
                    </boxxds-button-v2>
                  </ng-container>
                </div>
              </div>
            </app-footer-wrapper>
          </ng-container>
          <!-- Footer end -->
        </div>
        <!-- Policy configuration wf3 -->
      </ng-container>
    </div>
  </ng-container>
</div>
