import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { take, Observable } from 'rxjs';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { PolicyRiskService } from 'src/app/services/policy-risk.service';
import { LocationService } from 'src/app/services/location.service';
import { InsuredService } from 'src/app/services/insured.service';
import { getQuoteSelector } from 'src/app/store/create-quote/create-quote.selector';
import { Quote } from 'src/app/models/quote.model';
import { getLifecycleState } from 'src/app/store/lifecycle/lifecycle.select';
import { AbbreviateNumberPipe } from 'src/app/pipes/abbreviate-number.pipe';
import { formatDate } from 'src/app/utils/formatDate';

import {
  addSpaceBeforeCapitalLetter,
  convertStatusType,
} from 'src/app/dashboard/utils/lifecycle-utils';

import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';

@Component({
  selector: 'app-policy-grid-slideout',
  templateUrl: './policy-grid-slideout.component.html',
  styleUrls: ['./policy-grid-slideout.component.less'],
  providers: [AbbreviateNumberPipe],
})
export class PolicyGridSlideoutComponent
  implements OnInit, AfterContentChecked
{
  form$: Observable<Quote>;
  showBodySpinner: boolean = false;
  policyNumber: number;
  status: string;
  form: FormGroup;
  @Input() showModal: boolean = false;

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleSave = new EventEmitter<any>();
  @Output() handleDownload = new EventEmitter<any>();
  files: any[] = [];

  policyPeriodId: number;
  boxxUserId: number;
  templateDetails: { [x: string]: string | number }[] = [];
  docDetails: { [x: string]: string | number | boolean }[] = [];
  isLoader: boolean = false;
  packagePolicyNumber: string = '';
  showSendModal = false;
  showDocPreview = false;
  currentPreviewDocUrl;
  policyFeeForm: FormGroup;
  bindingStatus = 'default';
  isBindingPolicy = false;
  showErrorAlert = false;
  alertMessage = '';
  policyRiskId: number = null;
  policyId: Number = 0;
  quoteOptions = [];
  effDate = '';
  expDate = '';
  products = [];
  productsIds = [];
  riskId = 0;
  regionId = 0;
  insured = {
    name: '',
    industry: '',
    address: '',
    industryClass: '',
  };
  riskTrnxs = [];
  underWriters = [];
  policyRiskIds = [];
  isQuoteBounded = false;
  documentId: number;
  messageTypeId: number;
  addonsFromPolicyServ = [];
  addons = [];
  totalBilledAnnually = 0;

  showAllCards: boolean = false;
  openCardList = [];
  riskRegionId = 0;
  domainCodeForEndorsement = 144;
  domainCodeForWordings = 144;
  domainCodeForDeclarations = 144;

  endorsementOptions: any[] = [
    { key: '', value: '', disabled: false, url: '' },
  ];
  wordingsOptions: any[] = [{ key: '', value: '', disabled: false, url: '' }];
  subjectivityOptns: any[] = [{ key: '', value: '', disabled: false, url: '' }];
  subjectivityMandatory = [];
  selectedSubjectivities = [];
  endorsementsMandatory = [];
  selectedEndorsements = [];
  selectedWordings = [];
  selectedDeclarations = [];
  declarationOptions: any[] = [
    { key: '', value: '', disabled: false, url: '' },
  ];

  productList: { [x: string]: any }[] = [];

  pdtDescription = [];
  cyberPdtDesc = [];

  currency = '';
  shortDateFormat: string = '';
  longDateFormat: string = '';

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private localStorageService: LocalStorageService,
    private policyRiskService: PolicyRiskService,
    private insuredService: InsuredService,
    private locationService: LocationService,
    private changeDetector: ChangeDetectorRef,
    private boxxUserService: BoxxUserService,
    private abbreviateNumber: AbbreviateNumberPipe,
  ) {
    this.form = this.fb.group({});
  }

  ngOnInit(): void {
    this.showBodySpinner = true;
    this.form$ = this.store.pipe(select(getQuoteSelector));
    this.boxxUserId = this.localStorageService.getBoxxUserId();
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });

    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));

    this.store
      .select(getLifecycleState)
      .pipe(take(1))
      .subscribe((state) => {
        this.status = state?.policyRiskTrxStatus;
        this.policyPeriodId = state?.policyPeriodId;
        this.packagePolicyNumber = state?.policyId;
      });
    let underwriter;
    this.policyRiskService
      .GetAllByPolicyPeriodId(this.policyPeriodId)
      .pipe(take(1))
      .subscribe((data) => {
        this.policyRiskId = data.data[0].id;
        data.data?.forEach((risk, index) => {
          this.quoteOptions.push(...risk?.PolicyRiskTrxes[0]?.QuoteOptions);
          this.products.push(risk?.risk.name);

          if (this.products[index]?.underwriterId) {
            underwriter = this.products[index]?.underwriterId;
          } else {
            underwriter = risk?.PolicyRiskTrxes[0].underwriterId;
          }
          this.underWriters.push(this.getUnderWriter(underwriter));
        });
        this.effDate = formatDate(
          data?.data[0]?.policyPeriod?.effectiveDt,
          this.longDateFormat,
        );
        this.expDate = formatDate(
          data?.data[0]?.policyPeriod?.expiryDt,
          this.longDateFormat,
        );

        this.regionId =
          data.data[0]?.policyPeriod.policy.insured.insuredLocation.regionId;
        this.riskId = data.data[0]?.risk.id;

        this.riskTrnxs = data.data[0].PolicyRiskTrxes;
        this.riskTrnxs.forEach((trnx) => {
          this.policyRiskIds.push(trnx.policyRiskId);
        });

        this.productsIds = data.data.flatMap((dataObj) => {
          const productsIds = dataObj.risk.id || '';
          return productsIds;
        });

        this.showBodySpinner = false;
      });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  getStatusType(status) {
    return convertStatusType(status);
  }
  handleCloseClk() {
    this.showModal = false;
    this.handleClose.emit();
  }
  getTotalBilledAnnual() {
    const subTotal = this.getSubTotal();
    const service = this.getServices();
    const tax = this.getTaxes();
    return (
      Number(subTotal) +
      Number(service) +
      Number(tax) +
      Number(this.getPolicyFee()) +
      Number(this.getTriaAmount())
    );
  }
  getSubTotal() {
    let total = 0;
    this.quoteOptions
      .filter((option) => option.optionSelected == 1)
      .forEach((optn) => {
        total = total + (Number(optn.premium) || 0);
      });
    return total;
  }
  getServices() {
    let total = 0;
    this.quoteOptions
      .filter((option) => option.optionSelected == 1)
      .forEach((optn) => {
        total = total + Number(optn.servicesAmount);
      });
    return total || 0.0;
  }

  getUnderWriter(id) {
    let uw = '';
    this.boxxUserService
      .GetById(id)
      .pipe(take(1))
      .subscribe((data) => {
        uw = data.data?.firstName + data.data?.lastName || '';
      });
    return uw;
  }
  getTaxes() {
    let total = 0;
    this.quoteOptions
      .filter((option) => option.optionSelected == 1)
      .forEach((optn) => {
        total =
          total +
          Number(optn.taxesPremiumAmount) +
          Number(optn.taxesServicesAmount);
      });
    return total || 0.0;
  }
  getPolicyFee() {
    let total = 0;
    this.quoteOptions
      .filter((option) => option.optionSelected == 1)
      .forEach((optn) => {
        total = total + Number(optn.policyFee);
      });
    return total || 0.0;
  }

  getTriaAmount() {
    let total = 0;
    this.quoteOptions
      .filter((option) => option.optionSelected == 1)
      .forEach((optn) => {
        total = total + Number(optn.triaAmount);
      });
    return total || 0.0;
  }
  getSelectedQuote(options) {
    return options.filter((option) => option.optionSelected == 1);
  }
}
