<div class="wrapper">
  <div class="holder">
    <ng-container *ngIf="showBodySpinner">
      <div class="body-spinner">
        <boxxds-spinner
          [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
        ></boxxds-spinner>
      </div>
    </ng-container>
    <ng-container *ngIf="!showBodySpinner">
      <div class="alert-holder">
        <boxxds-alert
          *ngIf="hasError"
          type="error"
          (handleCloseEvent)="handleCloseErrorEvent()"
          headerText="error!"
          [bodyText]="errorMessage"
          [containerStyle]="{
            width: 'calc(100vw - 320px)',
            maxWidth: '1115px',
            marginBottom: '0'
          }"
        >
        </boxxds-alert>
        <boxxds-alert
          *ngIf="hasSuccess"
          type="success"
          (handleCloseEvent)="handleCloseSuccessEvent()"
          headerText="success!"
          [bodyText]="successMessage | translate"
          [containerStyle]="{
            width: 'calc(100vw - 320px)',
            maxWidth: '1115px',
            marginBottom: '0'
          }"
        >
        </boxxds-alert>
      </div>
      <app-quote-action-popup
        [showModal]="showActionPopup"
        [details]="actionPopupDetails"
        (handleActionConfirm)="
          this.permissionList[this.currentScreen] &&
            handleConfirmRiskAction($event)
        "
        (handleClose)="showActionPopup = false"
      ></app-quote-action-popup>
      <app-quote-slideout
        *ngIf="showSlideOut"
        [showModal]="showSlideOut"
        [details]="details"
        (handleSave)="
          this.permissionList[this.currentScreen] &&
            currentProductDetails.active &&
            handleSlideOutSave()
        "
        (handleClose)="handleSlideOutClose()"
      >
      </app-quote-slideout>
      <!-- <app-slideout-popup-base
        *ngIf="showSlideOut"
        [isPopupOpen]="showSlideOut"
        (handleClose)="handleSlideOutClose()"
      >
        <app-quote-slideout-v2 [details]="details"></app-quote-slideout-v2>
      </app-slideout-popup-base> -->
      <app-lifecycle-details
        [transactionType]="transactionType"
        [details]="details"
        [regionId]="regionId"
        [effectiveDate]="effectiveDate"
        [showPeriodOptions]="options"
        [currentProductDetails]="currentProductDetails"
        (handleShowPeriod)="
          this.permissionList[this.currentScreen] && handleShowPeriod($event)
        "
        (handleBind)="this.permissionList[this.currentScreen] && handleBind()"
      >
      </app-lifecycle-details>
      <div class="products">
        <div class="sub-nav">
          <ul>
            <li *ngFor="let nav of subNavData; let i = index">
              <button
                [ngClass]="{ active: nav.active }"
                (click)="handleNav($event, i)"
              >
                {{ nav.name }}
              </button>
            </li>
          </ul>
        </div>
        <div class="underwriter-container" *ngIf="currentProductDetails as pdt">
          <div class="profile">
            <div class="photo">
              <ngx-avatars
                [size]="24"
                [textSizeRatio]="3"
                [name]="pdt.underwriter"
              ></ngx-avatars>
            </div>
            <div class="text">
              <p class="designation">{{ "UNDERWRITER" | translate }}</p>
              <boxxds-body
                [text]="pdt.underwriter"
                sz="sm"
                [customStyle]="{ textAlign: 'left' }"
              ></boxxds-body>
            </div>
          </div>
        </div>
        <div class="quote-details" *ngIf="currentProductDetails as product">
          <div class="d-flex align-items-center mb-1">
            <div class="header">{{ "common.quote" | translate }}</div>
            <a
              *ngIf="
                quoteOptionList &&
                quoteOptionList.length > 0 &&
                currentProductDetails.active
              "
              [routerLink]="
                '/dashboard/' +
                (isQuote4Flow
                  ? 'quote4flow/quote-calculator-and-options/'
                  : isWorkFlow3
                  ? 'workflow3/quoting/'
                  : 'quote/new/options/') +
                details.id
              "
              skipLocationChange="true"
              >{{ "quoteDetails.seeQuoteOptions" | translate }}</a
            >
            <div class="d-flex align-items-center ml-auto">
              <boxxds-body
                sz="xxs"
                [text]="'TRNX DATE'"
                [customStyle]="{ color: '#BDBDBF' }"
              ></boxxds-body>
              <boxxds-body
                sz="sm"
                [text]="product.trnxDate"
                [customStyle]="{ color: '#56565C', marginLeft: '6px' }"
              >
              </boxxds-body>
              <button
                class="btn-expand-collapse"
                (click)="handleTileExpandCollapse()"
              >
                <img
                  [ngClass]="!isTileExpanded && 'rotate'"
                  class="mb-1 expand-collapse"
                  [src]="'assets/img/dashboard/quote/chevron-up.png'"
                />
              </button>
            </div>
          </div>
          <div
            class="quote d-flex justify-content-between"
            *ngIf="isTileExpanded"
          >
            <div>
              <div>
                <div class="d-flex align-items-center mb-4">
                  <div>
                    <boxxds-body
                      sz="xxs"
                      [text]="'quoteDetails.quoteNumber' | translate"
                      [customStyle]="{
                        color: '#BDBDBF',
                        marginRight: '26px',
                        textTransform: 'uppercase'
                      }"
                    >
                    </boxxds-body>
                    <boxxds-body
                      sz="sm"
                      [text]="product.quoteId"
                      [customStyle]="{ color: '#595D63' }"
                    >
                    </boxxds-body>
                  </div>
                  <boxxds-status-badge
                    [status]="product.status.toLowerCase()"
                    [statusType]="getStatusType(product.status)"
                    [badgeStyle]="{ textTransform: 'capitalize' }"
                  ></boxxds-status-badge>
                </div>

                <div class="d-flex gap-4">
                  <div>
                    <div>
                      <boxxds-body
                        sz="xxs"
                        [text]="'quoteSummary.premium' | translate"
                        [customStyle]="{
                          color: '#BDBDBF',
                          marginRight: '2px',
                          textTransform: 'uppercase'
                        }"
                      >
                      </boxxds-body>
                      <boxxds-body
                        sz="sm"
                        [text]="
                          product.premium
                            | currency: currency : 'symbol-narrow' : '1.0-0'
                        "
                        [customStyle]="{ color: '#595D63' }"
                      >
                      </boxxds-body>
                    </div>
                    <div class="mt-2">
                      <boxxds-body
                        sz="xxs"
                        [text]="'quoteDetails.annualPremium' | translate"
                        [customStyle]="{
                          color: '#BDBDBF',
                          marginRight: '2px',
                          textTransform: 'uppercase'
                        }"
                      >
                      </boxxds-body>
                      <boxxds-body
                        sz="sm"
                        [text]="
                          product.annualPremium
                            | currency: currency : 'symbol-narrow' : '1.2-2'
                        "
                        [customStyle]="{ color: '#595D63' }"
                      >
                      </boxxds-body>
                    </div>
                  </div>

                  <div>
                    <div>
                      <boxxds-body
                        sz="xxs"
                        [text]="'quoteSummary.deductible' | translate"
                        [customStyle]="{
                          color: '#BDBDBF',
                          marginRight: '2px',
                          textTransform: 'uppercase'
                        }"
                      >
                      </boxxds-body>
                      <boxxds-body
                        sz="sm"
                        [text]="
                          product.deductible
                            | currency: currency : 'symbol-narrow' : '1.0-0'
                        "
                        [customStyle]="{ color: '#595D63' }"
                      >
                      </boxxds-body>
                    </div>
                    <div class="d-flex gap-4 mt-2">
                      <div>
                        <boxxds-body
                          sz="xxs"
                          [text]="'common.limit' | translate"
                          [customStyle]="{
                            color: '#BDBDBF',
                            marginRight: '2px',
                            textTransform: 'uppercase'
                          }"
                        >
                        </boxxds-body>
                        <boxxds-body
                          sz="sm"
                          [text]="
                            product.limit
                              | currency: currency : 'symbol-narrow' : '1.0-0'
                          "
                          [customStyle]="{ color: '#595D63' }"
                        >
                        </boxxds-body>
                      </div>
                      <div>
                        <boxxds-body
                          sz="xxs"
                          [text]="'quoteDetails.effectiveDate' | translate"
                          [customStyle]="{
                            color: '#BDBDBF',
                            marginRight: '2px',
                            textTransform: 'uppercase'
                          }"
                        >
                        </boxxds-body>
                        <boxxds-body
                          sz="sm"
                          [text]="details.policyPeriod"
                          [customStyle]="{ color: '#595D63' }"
                        >
                        </boxxds-body>
                      </div>
                      <div>
                        <!-- <boxxds-body sz="xxs" [text]="'Invoice number'"
                        [customStyle]="{color: '#BDBDBF', marginLeft: '22px', marginRight: '2px', textTransform: 'uppercase'}">
                      </boxxds-body>
                      <div class="d-flex" *ngIf="product.invoiceNumber">
                        <img src="assets/img/dashboard/quote/lifecycle/download.png" width="18px" height="18px" />
                        <boxxds-body sz="sm" [text]="product.invoiceNumber"
                          [customStyle]="{color: '#595D63', marginLeft: '4px'}">
                        </boxxds-body>
                      </div> -->
                      </div>

                      <div
                        *ngIf="
                          showReason(product.status) &&
                          product?.trxReasons.length > 0
                        "
                      >
                        <boxxds-body
                          sz="xxs"
                          [text]="'quoteDetails.reasonDescription' | translate"
                          [customStyle]="{
                            color: '#BDBDBF',
                            marginRight: '2px',
                            textTransform: 'uppercase'
                          }"
                        >
                        </boxxds-body>

                        <boxxds-body
                          *ngIf="product?.status.toLowerCase() !== 'referral'"
                          sz="sm"
                          [text]="
                            product?.trxReasons?.[
                              product?.trxReasons.length - 1
                            ]?.comments
                          "
                          [customStyle]="{ color: '#595D63' }"
                        >
                        </boxxds-body>
                        <div
                          *ngIf="product?.status.toLowerCase() === 'referral'"
                        >
                          <app-view-referral-reasons
                            [quoteOptionId]="product?.quoteId"
                            [reasonData]="product?.trxReasons"
                          ></app-view-referral-reasons>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex">
              <div
                class="risk-actions d-flex flex-column align-items-start"
                *ngIf="showRiskLevelActions(product.status)"
              >
                <boxxds-body
                  sz="xs"
                  [text]="
                    'quoteDetails.transactionRiskLevelActions' | translate
                  "
                  [customStyle]="{ color: '#8F8F91' }"
                ></boxxds-body>
                <button
                  class="btn-action mt-2 ml-2"
                  [class.noPointerEvents]="
                    !this.permissionList[this.currentScreen] ||
                    !currentProductDetails.active
                  "
                  [disabled]="
                    !this.permissionList[this.currentScreen] ||
                    !currentProductDetails.active
                  "
                  (click)="
                    this.permissionList[this.currentScreen] &&
                      currentProductDetails.active &&
                      handleRiskAction(transactionRiskActions.Close)
                  "
                >
                  <img
                    class="mr-1"
                    src="assets/img/dashboard/quote/lifecycle/arrow-right-circle.png"
                    width="16px"
                    height="16px"
                  />{{ "common.close" | translate }}
                </button>
                <button
                  class="btn-action mt-2 ml-2"
                  [class.noPointerEvents]="
                    !this.permissionList[this.currentScreen]
                  "
                  [disabled]="!this.permissionList[this.currentScreen]"
                  (click)="
                    this.permissionList[this.currentScreen] &&
                      handleRiskAction(transactionRiskActions.Decline)
                  "
                >
                  <img
                    class="mr-1"
                    src="assets/img/dashboard/quote/lifecycle/arrow-right-circle.png"
                    width="16px"
                    height="16px"
                  />{{ "common.decline" | translate }}
                </button>
                <button
                  *ngIf="product.status.toLowerCase() !== 'referral'"
                  class="btn-action mt-2 ml-2"
                  [class.noPointerEvents]="
                    !this.permissionList[this.currentScreen] ||
                    !currentProductDetails.active
                  "
                  [disabled]="
                    !this.permissionList[this.currentScreen] ||
                    !currentProductDetails.active
                  "
                  (click)="
                    this.permissionList[this.currentScreen] &&
                      currentProductDetails.active &&
                      handleRiskAction(transactionRiskActions.Refer)
                  "
                >
                  <img
                    class="mr-1"
                    src="assets/img/dashboard/quote/lifecycle/arrow-right-circle.png"
                    width="16px"
                    height="16px"
                  />{{ "common.refer" | translate }}
                </button>
              </div>
              <div class="d-flex">
                <button
                  class="ellipsis"
                  (click)="
                    handleEllipsisClick(product.policyRiskTrxId, product.status)
                  "
                >
                  <div class="ellipsis-container">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div
            class="tile-collapsed d-flex gap-4 align-items-center"
            *ngIf="!isTileExpanded"
          >
            <div>
              <boxxds-body
                sz="xxs"
                [text]="'quoteDetails.quoteNumber' | translate"
                [customStyle]="{ color: '#BDBDBF', textTransform: 'uppercase' }"
              >
              </boxxds-body>
              <boxxds-body
                sz="sm"
                [text]="product.quoteId"
                [customStyle]="{ color: '#595D63' }"
              >
              </boxxds-body>
            </div>
            <boxxds-status-badge
              [status]="product.status.toLowerCase()"
              [statusType]="getStatusType(product.status)"
              [badgeStyle]="{ textTransform: 'capitalize' }"
            ></boxxds-status-badge>
            <div>
              <boxxds-body
                sz="xxs"
                [text]="'common.premium' | translate"
                [customStyle]="{
                  color: '#BDBDBF',
                  marginRight: '2px',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
              <boxxds-body
                sz="sm"
                [text]="
                  product.premium
                    | currency: currency : 'symbol-narrow' : '1.0-0'
                "
                [customStyle]="{ color: '#595D63' }"
              >
              </boxxds-body>
            </div>
            <div>
              <boxxds-body
                sz="xxs"
                [text]="'quoteDetails.annualPremium' | translate"
                [customStyle]="{
                  color: '#BDBDBF',
                  marginRight: '2px',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
              <boxxds-body
                sz="sm"
                [text]="
                  product.annualPremium
                    | currency: currency : 'symbol-narrow' : '1.2-2'
                "
                [customStyle]="{ color: '#595D63' }"
              >
              </boxxds-body>
            </div>
            <div>
              <boxxds-body
                sz="xxs"
                [text]="'quoteSummary.deductible' | translate"
                [customStyle]="{
                  color: '#BDBDBF',
                  marginRight: '2px',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
              <boxxds-body
                sz="sm"
                [text]="
                  product.deductible
                    | currency: currency : 'symbol-narrow' : '1.0-0'
                "
                [customStyle]="{ color: '#595D63' }"
              >
              </boxxds-body>
            </div>
            <div>
              <boxxds-body
                sz="xxs"
                [text]="'common.limit' | translate"
                [customStyle]="{
                  color: '#BDBDBF',
                  marginRight: '2px',
                  textTransform: 'uppercase'
                }"
              >
              </boxxds-body>
              <boxxds-body
                sz="sm"
                [text]="
                  product.limit | currency: currency : 'symbol-narrow' : '1.0-0'
                "
                [customStyle]="{ color: '#595D63' }"
              >
              </boxxds-body>
            </div>
            <button
              class="ellipsis btn-slideout"
              (click)="
                handleEllipsisClick(product.policyRiskTrxId, product.status)
              "
            >
              <div class="ellipsis-container">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
