<div class="holder">
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!showBodySpinner">
    <div class="header" *ngIf="form$ | async as form">
      <div class="quote-name-wraper">
        <boxxds-heading
          [sz]="'5'"
          [text]="'Quote - ' + (form$ | async)?.ui.insuredName"
          [customStyle]="{ marginTop: '2px' }"
        >
        </boxxds-heading>
        <boxxds-body
          class="quote-id"
          [text]="('common.quoteId' | translate) + ': ' + this.policyId"
        ></boxxds-body>
      </div>
      <div
        class="btns-holder d-flex"
        *ngIf="(form$ | async)?.quoteOptions as quoteOptions"
      >
        <ng-container
          *ngIf="
            this.permissionList[this.currentScreen] &&
            this.currentQuoteStatus !== 'quote-closed'
          "
        >
          <boxxds-button
            *ngIf="!isSendingQuote"
            rightImage="send"
            [buttonText]="'quoteSummary.sendQuote' | translate"
            [type]="'submitted'"
            btnType="primary-outlined"
            [btnStyle]="{ width: '162px' }"
            [isDisabled]="quoteOptions.length <= 0"
            (handleClick)="handleSendQuote()"
          >
          </boxxds-button>
          <button
            *ngIf="isSendingQuote"
            class="btn-processing"
            tabindex="0"
            [ngStyle]="{
              border: '1px solid #FFBE3D',
              borderRadius: '8px',
              width: '162px',
              height: '32px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '4px 8px',
              background: '#FCF8EF',
              marginLeft: 'auto'
            }"
          >
            <boxxds-spinner></boxxds-spinner>
          </button>
        </ng-container>
        <app-modal-send
          *ngIf="showSendModal"
          [isLoader]="isLoader"
          [showModal]="showSendModal"
          (handleClose)="closeSendPackage()"
          [formDetails]="form.ui"
          [templateDetails]="templateDetails"
          [docDetails]="docDetails"
          [title]="'Sending ' + quoteOptions.length + ' quote option package'"
          (handleSend)="handleSend($event)"
        >
        </app-modal-send>

        <app-modal-sublimit
          *ngIf="showSublimitModal"
          [showModal]="showSublimitModal"
          (handleClose)="closeSublimitModal()"
          [title]="
            ('quoteCalculator.modal.sublimitsModalHeading' | translate) +
            ' ' +
            title
          "
          (handleSubmitCoverageDetails)="handleSubmitCoverageDetails($event)"
          [sublimitOptionsForFinancialCoverage]="
            sublimitOptionsForFinancialCoverage
          "
          [coverageDetails]="coverageDetails"
          [coverageDeductibles]="coverageDeductibles"
          [productDetails]="productDetails"
          [insuredDetals]="insuredDetails"
          [formDetails]="coverageForm"
          [limitOptionsForProducts]="limitOptionsForProducts"
          [occurrenceTypeDropdownValues]="occurrenceTypeDropdownValues"
          [measuresDropdownValues]="measuresDropdownValues"
          [deductibleOptions]="deductibleOptions"
          [showDeductibleCustomField]="showCoverageDeductibleCustomField"
          [quoteOptionId]="quoteOptionIdForSublimitSlideout"
          (handleBackBtn)="handleBackBtn()"
          (handleLimitCustomValue)="
            this.permissionList[this.currentScreen] &&
              this.currentQuoteStatus !== 'quote-closed' &&
              handleLimitCustomValue($event, title)
          "
          (handleRetentionCustomValue)="
            this.permissionList[this.currentScreen] &&
              this.currentQuoteStatus !== 'quote-closed' &&
              handleDeductableCustomValue($event, title)
          "
          (handleQuoteOptionDeductibleChange)="
            this.permissionList[this.currentScreen] &&
              this.currentQuoteStatus !== 'quote-closed' &&
              handleDeductableChange($event, title)
          "
          (handleQuoteOptionLimitChange)="
            this.permissionList[this.currentScreen] &&
              this.currentQuoteStatus !== 'quote-closed' &&
              handleLimitChange($event, title)
          "
          (handlePremiumRecalc)="
            this.permissionList[this.currentScreen] &&
              this.currentQuoteStatus !== 'quote-closed' &&
              handlePremiumCalculateFromSublimit($event)
          "
          (handleQuestionnaireNav)="handleBackBtn()"
          [currency]="currency"
        >
        </app-modal-sublimit>
      </div>
    </div>
    <div class="quote-calc">
      <div class="body-spinner spinner-body-fade" *ngIf="calledCalculate === 1">
        <boxxds-spinner
          [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
        ></boxxds-spinner>
      </div>
      <div *ngIf="calledCalculate === 1" class="calc-overlay"></div>
      <div class="calc-header">
        <div class="d-flex">
          <div class="header-text">
            {{
              isEditMode
                ? ("quoteCalculator.editQuoteOption" | translate) +
                  currentEditQuoteOptnNumber
                : ("quoteCalculator.createQuoteOptions" | translate)
            }}
          </div>
          <ng-container
            *ngIf="
              this.permissionList[this.currentScreen] &&
              this.currentQuoteStatus !== 'quote-closed'
            "
          >
            <button
              class="clear-values"
              *ngIf="showCalc"
              (click)="handleClear()"
            >
              {{ "common.clear" | translate }}
            </button>
          </ng-container>
        </div>
        <button class="btn-toggle-calc" (click)="handleCalcToggle()">
          {{ showCalc ? "Hide" : "Show" }}
        </button>
      </div>
      <div class="calc-content" *ngIf="showCalc">
        <table id="tbl-quote-calc">
          <thead>
            <tr class="tbl-hd">
              <th
                *ngFor="let key of tableHeaders; let indexOfHeaders = index"
                scope="col"
                class="th"
              >
                <div class="tbl-header">
                  {{ key }}
                </div>
              </th>
            </tr>
          </thead>
          <tr
            *ngFor="
              let product of getSelectedPdtOptions((form$ | async)?.products);
              let i = index
            "
          >
            <td>
              <div class="d-flex align-items-center">
                <ng-container
                  *ngIf="!product?.standaloneSellable && !isEditMode"
                >
                  <button class="close-button" (click)="removeExcess()">
                    <img
                      class="btn-del"
                      [src]="'assets/img/dashboard/product/close.png'"
                      alt="close"
                    />
                  </button>
                </ng-container>
                <p class="pdt-name">{{ product.key }}</p>
              </div>
              <div
                class="add-ons"
                *ngIf="product.addons && product.addons.length > 0"
              >
                <div class="add-ons-hdr">
                  <p>
                    {{ "quoteCalculator.heading.additionalAddons" | translate }}
                  </p>
                  <button
                    class="expand-button"
                    (click)="toggleAddOnsView(product.value)"
                  >
                    <img
                      class="btn-del"
                      [ngClass]="{
                        expanded: getExpandAddOnsVal(product.value)
                      }"
                      src="assets/img/dashboard/quote/arrow-down-circle.png"
                      alt="expand"
                    />
                  </button>
                </div>
                <div *ngIf="getExpandAddOnsVal(product.value)">
                  <boxxds-checkbox
                    [options]="getAddOns(product.value, product.addons)"
                    (handleCheckbox)="handleCheckbox($event, product.value)"
                    [containerStyle]="{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: '25px',
                      marginLeft: '25px',
                      marginBottom: '26px'
                    }"
                    [lblStyle]="{ color: '#56565C', marginRight: '0px' }"
                  >
                  </boxxds-checkbox>
                </div>
              </div>

              <!-- Add excess link -->
              <ng-container *ngIf="(form$ | async)?.products as products">
                <button
                  class="add-excess"
                  *ngIf="product.hasSubProduct"
                  (click)="addExcess(products, product.subProductName)"
                >
                  {{
                    "Want to add " +
                      product.subProductName +
                      " for $" +
                      (cyberExcessLimit | abbreviateNumber) +
                      "+ limit?"
                  }}
                </button>
              </ng-container>
            </td>
            <td class="limit">
              <boxxds-dropdown
                *ngIf="
                  !showLimitCustomField(product.value) &&
                  !isLimitDisabledOnAddSubProduct(product.riskId) &&
                  brokerageCommissionMin[product.riskId]
                "
                [placeholder]="'common.select' | translate"
                [options]="limitOptionsForProducts[product.key]"
                [dropdownContainerStyle]="{ maxWidth: '150px' }"
                (currentValueChange)="
                  this.permissionList[this.currentScreen] &&
                    this.currentQuoteStatus !== 'quote-closed' &&
                    handleLimitChange($event, product.value)
                "
                [invalidErrMsg]="getLimitErrMsg(product.key)"
                [currentValue]="
                  getLimitValue(product.riskId)
                    ? (getLimitValue(product.riskId)
                      | currencyFormat: currency : 'symbol-narrow' : '1.0-0')
                    : ''
                "
                [isReadonly]="
                  !this.permissionList[this.currentScreen] ||
                  this.currentQuoteStatus === 'quote-closed'
                "
                [class.noPointerEvents]="
                  !this.permissionList[this.currentScreen] ||
                  this.currentQuoteStatus === 'quote-closed'
                "
              >
              </boxxds-dropdown>
              <input
                *ngIf="
                  product.standaloneSellable &&
                  isLimitDisabledOnAddSubProduct(product.riskId)
                "
                [value]="
                  getLimitValue(product.riskId)
                    ? (getLimitValue(product.riskId)
                      | currencyFormat: currency : 'symbol-narrow')
                    : ''
                "
                [attr.disabled]="true"
                class="disabled-field"
              />

              <div
                class="custom-value"
                *ngIf="showLimitCustomField(product.value)"
              >
                <input
                  type="text"
                  mask="separator.0"
                  thousandSeparator=","
                  prefix="$"
                  (blur)="handleLimitCustomValue($event, product.value)"
                  maxlength="14"
                  [autofocus]="true"
                />
              </div>
            </td>
            <td class="deductables">
              <boxxds-dropdown
                *ngIf="
                  !showDeductibleCustomField(product.riskId) &&
                  product.standaloneSellable &&
                  brokerageCommissionMin[product.riskId]
                "
                [placeholder]="'common.select' | translate"
                [options]="deductibleOptions"
                [dropdownContainerStyle]="{ maxWidth: '150px' }"
                (currentValueChange)="
                  this.permissionList[this.currentScreen] &&
                    this.currentQuoteStatus !== 'quote-closed' &&
                    handleDeductableChange($event, product.value)
                "
                [invalidErrMsg]="getDeductibleErrMsg(product.key)"
                [currentValue]="
                  getDeductibleValue(product.riskId)
                    ? (getDeductibleValue(product.riskId)
                      | currencyFormat: currency : 'symbol-narrow' : '1.0-0')
                    : ''
                "
                [disabled]="!product.standaloneSellable"
                [isReadonly]="
                  !this.permissionList[this.currentScreen] ||
                  this.currentQuoteStatus === 'quote-closed'
                "
              >
              </boxxds-dropdown>
              <input
                *ngIf="!product.standaloneSellable"
                [value]="
                  getDeductibleValue(product?.riskId)
                    ? (getDeductibleValue(product?.riskId)
                      | currencyFormat: currency : 'symbol-narrow')
                    : ''
                "
                [attr.disabled]="true"
                class="disabled-field"
              />
              <div
                class="custom-value"
                *ngIf="
                  showDeductibleCustomField(product.riskId) &&
                  product.standaloneSellable
                "
              >
                <input
                  type="text"
                  mask="separator.0"
                  thousandSeparator=","
                  prefix="$"
                  maxlength="14"
                  (blur)="handleDeductableCustomValue($event, product.value)"
                  [autofocus]="true"
                />
              </div>
            </td>
            <td class="broker-commission">
              <div
                class="stepper-input"
                [ngClass]="{ 'disabled-input': !product.standaloneSellable }"
              >
                <boxxds-stepper-input
                  *ngIf="
                    !clearingCalc &&
                    product.standaloneSellable &&
                    brokerageCommissionMin[product.riskId]
                  "
                  [containerStyle]="{ maxWidth: '110px' }"
                  [max]="brokerageCommissionMax[product.riskId]"
                  [min]="brokerageCommissionMin[product.riskId]"
                  [step]="brokerCommissionStep"
                  (handleChange)="
                    this.permissionList[this.currentScreen] &&
                      this.currentQuoteStatus !== 'quote-closed' &&
                      handleBrokerCommissionPerc($event, product)
                  "
                  (handleKeyPress)="
                    this.permissionList[this.currentScreen] &&
                      this.currentQuoteStatus !== 'quote-closed' &&
                      handleBrokerCommissionValidate($event, product)
                  "
                  [value]="getBrokerageCommissionPerc(product.riskId)"
                  [isDisabled]="
                    !product.standaloneSellable ||
                    !this.permissionList[this.currentScreen] ||
                    this.currentQuoteStatus === 'quote-closed'
                  "
                  [readonly]="
                    !this.permissionList[this.currentScreen] ||
                    this.currentQuoteStatus === 'quote-closed'
                  "
                  [class.noPointerEvents]="
                    !this.permissionList[this.currentScreen] ||
                    this.currentQuoteStatus === 'quote-closed'
                  "
                ></boxxds-stepper-input>
                <input
                  *ngIf="!product.standaloneSellable"
                  [value]="
                    getBrokerageCommissionPerc(product?.riskId)
                      ? getBrokerageCommissionPerc(product?.riskId) + '%'
                      : '0.0%'
                  "
                  [attr.disabled]="true"
                  class="disabled-field"
                  [ngStyle]="{ maxWidth: '110px' }"
                />

                <div
                  [ngClass]="{
                    'sublimit-col d-flex justify-content-around': isEditMode
                  }"
                >
                  <p>{{ brokerageCommissionText[product.riskId] }}</p>
                  <button
                    *ngIf="isEditMode"
                    class="grid-item sublimit d-flex"
                    tabindex="0"
                    (click)="
                      handleSublimitAction(
                        $event,
                        product.key,
                        policyPeriodId,
                        product,
                        i
                      )
                    "
                    (mouseout)="
                      sublimitIconSrc =
                        'assets/img/dashboard/quote/lifecycle/arrow-right-circle.png'
                    "
                    (mouseover)="
                      sublimitIconSrc =
                        'assets/img/dashboard/quote/lifecycle/arrow-right-circle-hover.png'
                    "
                    (focus)="
                      changeIconSrc(
                        'assets/img/dashboard/quote/lifecycle/arrow-right-circle-focus.png'
                      )
                    "
                    (blur)="
                      changeIconSrc(
                        'assets/img/dashboard/quote/lifecycle/arrow-right-circle.png'
                      )
                    "
                  >
                    <div class="label">
                      {{ "common.sublimits" | translate | uppercase }}
                    </div>
                    <img [src]="sublimitIconSrc" width="12px" height="12px" />
                  </button>
                </div>
              </div>
            </td>
            <td class="btn-calc">
              <ng-container *ngIf="(form$ | async)?.quoteOptions as quoteOptns">
                <ng-container
                  *ngIf="
                    this.permissionList[this.currentScreen] &&
                    this.currentQuoteStatus !== 'quote-closed'
                  "
                >
                  <boxxds-button
                    *ngIf="
                      product.standaloneSellable &&
                      showPremiumCalcBtn(product.riskId)
                    "
                    [buttonText]="'quoteCalculator.label.calculate' | translate"
                    btnType="secondary"
                    [isDisabled]="
                      !isCalcBtnEnabled(
                        product.key?.toLowerCase(),
                        data.products
                      )
                    "
                    [ngStyle]="{ maxWidth: '115px' }"
                    (handleClick)="
                      handlePremiumCalc(product.value, quoteOptns.length)
                    "
                  >
                  </boxxds-button>
                </ng-container>

                <input
                  type="text"
                  *ngIf="!showPremiumCalcBtn(product.riskId)"
                  [value]="
                    getPremiumValue(product.value)
                      | currency: currency : 'symbol-narrow' : '1.0-0'
                  "
                  disabled
                />
                <div
                  *ngIf="
                    !showPremiumCalcBtn(product.riskId) &&
                    isReferral(product.riskId)
                  "
                >
                  <app-view-referral-reasons
                    [quoteOptionId]="getQuoteOptionId(product.riskId)"
                  ></app-view-referral-reasons>
                </div>
              </ng-container>
            </td>
            <td
              class="edited-premium"
              [ngStyle]="
                getDeviationText(product.value) !== '' && { paddingBottom: '0' }
              "
            >
              <div *ngIf="!showPremiumCalcBtn(product.riskId)">
                <boxxds-dropdown
                  *ngIf="!showEditedPremCustomField(product.value)"
                  placeholder="'common.select' | translate"
                  [options]="editedPremiumOptions"
                  [dropdownContainerStyle]="{ maxWidth: '150px' }"
                  (currentValueChange)="
                    this.permissionList[this.currentScreen] &&
                      this.currentQuoteStatus !== 'quote-closed' &&
                      handleEditedPremium($event, product.value)
                  "
                  [hideSelectedValue]="true"
                  [currentValue]="
                    isNumber(getEditedPremium(product.value))
                      ? (getEditedPremium(product.value)
                        | currency: currency : 'symbol-narrow' : '1.0-0')
                      : getEditedPremium(product.value)
                  "
                  [isReadonly]="
                    !this.permissionList[this.currentScreen] ||
                    this.currentQuoteStatus === 'quote-closed'
                  "
                  [class.noPointerEvents]="
                    !this.permissionList[this.currentScreen] ||
                    this.currentQuoteStatus === 'quote-closed'
                  "
                >
                </boxxds-dropdown>
                <p *ngIf="getDeviationText(product.value) !== ''">
                  {{ getDeviationText(product.value) | translate }}
                </p>
                <div
                  class="custom-value"
                  *ngIf="showEditedPremCustomField(product.value)"
                >
                  <input
                    type="text"
                    [ngStyle]="
                      getEditedPremiumErr(product.value) !== '' && {
                        border: '1px solid #CA3434'
                      }
                    "
                    mask="separator.0"
                    thousandSeparator=","
                    prefix="$"
                    maxlength="11"
                    (blur)="handleEditedPremiumCustVal($event, product.value)"
                    [autofocus]="true"
                    [min]="getEditedPremiumMinVal(product.value)"
                    [max]="getEditedPremiumMaxVal(product.value)"
                  />
                  <div
                    *ngIf="getEditedPremiumErr(product.value) !== ''"
                    class="error-msg"
                  >
                    {{ getEditedPremiumErr(product.value) }}
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </table>
        <div class="subtotal">
          <div class="subtotal-container">
            <div class="amt-row">
              <div class="subtotal-header">Subtotal premium</div>
              <div class="premium-amt">
                <div class="amt" *ngIf="!data.total.premiumCalculated">
                  $_____.__
                </div>
                <div
                  class="calculated-amt"
                  *ngIf="data.total.premiumCalculated"
                >
                  {{
                    data.total.premiumCalculated
                      | currency: currency : "symbol-narrow" : "1.0-0"
                  }}
                </div>
                <div class="amt-info">
                  {{ "quoteCalculator.totalAmountInfo" | translate }}
                </div>
              </div>
              <div class="amt-edited-premium">
                <div class="amt-edited">
                  {{
                    data.total.premium
                      ? (data.total.premium
                        | currency: currency : "symbol-narrow" : "1.0-0")
                      : "$____.__"
                  }}
                </div>
                <div class="amt-info-edited">
                  {{ "quoteCalculator.label.editedPremium" | translate }}
                </div>
              </div>
            </div>
            <ng-container
              *ngIf="
                this.permissionList[this.currentScreen] &&
                this.currentQuoteStatus !== 'quote-closed'
              "
            >
              <div
                class="btn-row"
                *ngIf="(form$ | async)?.quoteOptions as quoteOptions"
              >
                <boxxds-button
                  leftImage="refresh"
                  [buttonText]="
                    'quoteCalculator.label.recalculateAll' | translate
                  "
                  [type]="'submitted'"
                  btnType="primary-outlined"
                  [btnStyle]="{ width: '162px' }"
                  [isDisabled]="
                    !this.permissionList[this.currentScreen] ||
                    !isRecalcEnabled()
                  "
                  (handleClick)="
                    this.permissionList[this.currentScreen] &&
                      handleRecalculate($event)
                  "
                >
                </boxxds-button>
                <boxxds-button
                  [buttonText]="
                    'quoteCalculator.label.saveEditedPremium' | translate
                  "
                  [type]="'submitted'"
                  [leftImage]="isEditMode ? 'edit' : ''"
                  [btnStyle]="{
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    marginLeft: '24px'
                  }"
                  [isDisabled]="
                    !this.permissionList[this.currentScreen] ||
                    !isSaveEditedPremiumBtnEnabled(quoteOptions.length)
                  "
                  (handleClick)="
                    this.permissionList[this.currentScreen] &&
                      saveEditedPremiumHandler(quoteOptions.length)
                  "
                >
                </boxxds-button>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div
      class="quotes-holder"
      *ngIf="(form$ | async)?.quoteOptions as quoteOptions"
    >
      <div
        class="toggle-all-btn-holder"
        *ngIf="(form$ | async)?.quoteOptions.length >= 2"
      >
        <button class="toggle-all-btn" (click)="handleToggleAll()">
          {{ isCollapseAllEnabled ? "Collapse all" : "Expand all" }}
        </button>
      </div>
      <div
        class="quote-options"
        *ngFor="let quote of (form$ | async)?.quoteOptions; let i = index"
      >
        <app-quote-option
          [quote]="quote"
          [startDate]="(form$ | async)?.startDate"
          [expiryDate]="(form$ | async)?.endDate"
          [idxOfQuote]="i"
          [domainIdForReferral]="domainIdForReferral"
          (handleEdit)="
            this.permissionList[this.currentScreen] && handleEdit(quote, i)
          "
          (handleQuoteDelete)="
            this.permissionList[this.currentScreen] && handleQuoteDelete($event)
          "
          (handleCompareSelected)="
            handleCompareSelected($event, quote, quoteOptions)
          "
          (handleDuplicateClick)="
            this.permissionList[this.currentScreen] &&
              handleDuplicateClick($event)
          "
          (handleQuoteSelected)="
            this.permissionList[this.currentScreen] &&
              handleQuoteSelected($event, quote)
          "
          [isDuplicateDisabled]="
            quoteOptions.length >= 10 ||
            duplicateButtonClicked ||
            !this.permissionList[this.currentScreen] ||
            this.currentQuoteStatus === 'quote-closed'
          "
          (handleToggleBtn)="handleToggle($event, quote)"
          (handleSublimitClick)="
            handleSublimitAction(
              $event,
              quote.products[0]?.key,
              policyPeriodId,
              quote.products[0],
              i,
              quote
            )
          "
        >
        </app-quote-option>
      </div>
    </div>

    <div class="continue-btn-holder">
      <boxxds-button
        [buttonText]="'common.back' | translate"
        btnType="secondary"
        [btnStyle]="{ width: '88px' }"
        type="submit"
        (handleClick)="handleBackBtn()"
        class="back-btn"
      >
      </boxxds-button>
      <div class="save-continue">
        <boxxds-button
          [isDisabled]="
            !activateCompareOptionsBtn((form$ | async)?.quoteOptions)
          "
          [buttonText]="'submission.compareOptions' | translate"
          btnType="secondary"
          (handleClick)="handleCompareOptions($event)"
          [ngStyle]="{ width: '164px', marginRight: '12px' }"
        >
        </boxxds-button>
        <boxxds-button
          [isDisabled]="isContinueDisabled((form$ | async)?.quoteOptions)"
          [buttonText]="'submission.continueToStep5Of7' | translate"
          btnType="secondary-solid"
          (handleClick)="handleContinue($event)"
          [ngStyle]="{ width: '199px' }"
        >
        </boxxds-button>
      </div>
    </div>

    <!-- Document Template selection-->

    <app-doc-template-selection-popup
      *ngIf="showDocTempSelectionPopup"
      [showModal]="showDocTempSelectionPopup"
      [details]="docPopupDetails"
      (handleSuccess)="handleSuccessDocTemplate($event)"
      (handleActionConfirm)="
        this.permissionList[this.currentScreen] && handleDocLevelAction($event)
      "
      (handleClose)="showDocTempSelectionPopup = false"
    ></app-doc-template-selection-popup>
    <!-- Document Template selection-->
  </ng-container>
</div>
