<div class="holder">
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!showBodySpinner">
    <app-dynamic-filter-v2-modal
      *ngIf="showFilterPopup"
      (handleClose)="handleCloseFilterPopup($event)"
      [filterInputOptions]="filterInputOptions"
      [filterFormGroup]="filterFormGroup"
      [filterTitle]="'workFlow3.brokerageFilter.heading.filter' | translate"
      [filterTitleDescription]="
        'workFlow3.brokerageFilter.subHeading.filterSubHeading' | translate
      "
      (handleSubmit)="handleApplyFilter($event)"
      (handleFilterClear)="handleFilterClear($event)"
    ></app-dynamic-filter-v2-modal>
    <app-navigation-v2
      [showHeader]="true"
      [showHeaderBanner]="true"
      [items]="breadCrumbArray"
      (suggestedSearchSelected)="handleGlobalSearch($event)"
      [badgeData]="'0 Records'"
    ></app-navigation-v2>
    <div class="header">
      <div class="header-content">
        <div class="heading">
          <div class="d-flex align-items-center">
            <boxxds-heading
              [sz]="'5'"
              [text]="
                'workFlow3.brokerage.brokerageDirectory.heading.brokerageDirectory'
                  | translate
              "
              [customStyle]="{
                color: '#292933',
                fontFamily: 'Gibson',
                fontSize: '30px',
                fontWeight: '600',
                lineHeight: '38px'
              }"
            >
            </boxxds-heading>
          </div>
          <div class="sub-header">
            <boxxds-body
              class="description"
              [text]="
                'workFlow3.brokerage.brokerageDirectory.subheading.main'
                  | translate
              "
              [customStyle]="{
                fontFamily: 'Gibson',
                color: '#475467'
              }"
            >
            </boxxds-body>
          </div>
        </div>
        <ng-container
          *ngIf="
            this.permissionList[this.currentScreen] &&
            this.permissionList['Brokerage Directory']
          "
        >
          <boxxds-button-v2
            [size]="'lg'"
            [buttonText]="
              'workFlow3.brokerage.brokerageDirectory.button.addBrokerage'
                | translate
            "
            [type]="'submitted'"
            [btnClass]="'primary'"
            class="start-quote"
            (handleClick)="handleAddNewBrokerage()"
            [btnStyle]="{ marginTop: '14px' }"
          >
          </boxxds-button-v2>
        </ng-container>
      </div>
    </div>
    <boxxds-alert
      (handleCloseEvent)="handleNotificationAlertClose()"
      *ngIf="notificationAlert?.show"
      [type]="notificationAlert?.type"
      [headerText]="notificationAlert?.headerText | translate"
      [bodyText]="notificationAlert?.bodyText | translate"
    >
    </boxxds-alert>
    <div class="content-holder">
      <div class="content">
        <div class="d-flex justify-content-between">
          <div class="sub-nav">
            <ul>
              <li *ngFor="let nav of subNavData; let i = index">
                <button
                  [ngClass]="{ active: nav.active }"
                  (click)="handleNav(i)"
                >
                  {{ nav.name }}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="tbl-container">
        <div class="table-div">
          <div class="title-div d-flex align-items-center">
            <boxxds-heading
              *ngIf="!searchWord"
              [sz]="'5'"
              [text]="
                'workFlow3.brokerage.brokerageDirectory.heading.brokerageList'
                  | translate
              "
              [customStyle]="{
                color: '#2D2D39',
                fontFamily: 'Gibson',
                fontSize: '18px',
                fontWeight: '600',
                lineHeight: '28px'
              }"
            >
            </boxxds-heading>
            <boxxds-heading
              *ngIf="searchWord"
              [sz]="'5'"
              [text]="
                ('workFlow3.brokerage.brokerageDirectory.heading.searchResult'
                  | translate) + searchWord
              "
              [customStyle]="{
                color: '#2D2D39',
                fontFamily: 'Gibson',
                fontSize: '18px',
                fontWeight: '600',
                lineHeight: '28px'
              }"
            >
            </boxxds-heading>
            <div class="total-records">
              {{ pagination.totalRecords + " " + "records" }}
            </div>
          </div>
          <div class="filter-container d-flex">
            <div class="filter-optns-container">
              <div
                class="filter-optn"
                *ngFor="let filter of getFilteredValues()"
              >
                <span class="option-name">{{ filter.value }}</span>
                <button
                  type="button"
                  aria-label="close"
                  class="close-btn"
                  (click)="handleFilterOptnRemove(filter.key)"
                >
                  <img src="assets/img/dashboard/quote/remove.png" />
                </button>
              </div>
            </div>
            <boxxds-textinput-v2
              [form]="form"
              [placeholder]="
                ('workFlow3.components.shared.placeholder.search' | translate) +
                ' name'
              "
              [control]="'searchKey'"
              [id]="'search'"
              [leftIcon]="'assets/img/workflow3/sidemenu/search.png'"
              [width]="'200px'"
              [maxLength]="'40'"
              (handleKeyUp)="handleSearch($event)"
            >
            </boxxds-textinput-v2>
            <boxxds-button-v2
              sz="sm"
              [isBtnActive]="showFilter"
              [btnClass]="'secondary gray'"
              [buttonText]="''"
              [leftImage]="'filter-funnel-02'"
              [type]="'submitted'"
              [btnStyle]="{
                width: '36px',
                margin: '8px 0px 0 16px',
                height: '44px'
              }"
              (handleClick)="handleFilter()"
            >
            </boxxds-button-v2>
            <boxxds-button-v2
              sz="sm"
              [isBtnActive]="showFilter"
              [btnClass]="'secondary gray'"
              [buttonText]="''"
              [leftImage]="'download-01'"
              [type]="'submitted'"
              [btnStyle]="{
                width: '36px',
                margin: '8px 0px 0 16px',
                height: '44px'
              }"
              (handleClick)="handleExport()"
            >
            </boxxds-button-v2>
          </div>
        </div>
        <boxxds-table-v2
          [tableData]="data"
          [totalCount]="pagination.totalRecords"
          (pageChanged)="pageChangeHandler($event)"
          class="table"
          (sortHandler)="handleSorting($event)"
          id="brokerage-table"
          [isRowClickable]="true"
          [showSpinner]="showTblSpinner"
          [activePageNumber]="pagination.currentPage"
          (editHandler)="handleEditBrokerage($event)"
          (viewHandler)="handleViewBrokerage($event)"
          [ngClass]="{
            'pointer-default': !this.permissionList[this.currentScreen]
          }"
          [tableContainerStyle]="{ maxHeight: '650px', minHeight: '550px' }"
          [iconColumns]="iconColumns"
          [columnsToHide]="columnsToHide"
          [sortableColumns]="sortableColumns"
          [showPopOver]="true"
          [columnHeadsToHide]="columnHeadsToHide"
          [showViewIcon]="!this.permissionList[this.currentScreen]"
          [showEditIcon]="this.permissionList[this.currentScreen]"
          [sensitiveDataColumns]="['Name', 'Parent']"
        >
        </boxxds-table-v2>
      </div>
    </div>
  </ng-container>
</div>
