<form class="form-container" [formGroup]="formCommission">
  <div class="edit-form-group-cl-5">
    <boxxds-dropdown
      [currentValue]="productId"
      [showLabel]="false"
      [id]="'productId'"
      [form]="formCommission"
      [control]="'productId'"
      [placeholder]="'placeholder.select' | translate"
      [options]="products"
      [label]="'common.products' | translate"
      [btnStyle]="{ marginTop: '8px', maxWidth: '250px' }"
      [dropdownContainerStyle]="{ maxWidth: '250px' }"
      class="input-fields"
      [requiredErrMsg]="requiredErrMsg | translate"
      [formSubmitted]="submitted"
      (currentValueChange)="handleOnChangeProductId($event)"
    ></boxxds-dropdown>

    <boxxds-dropdown
      [currentValue]="regionId"
      [isReadonly]="!productId?.length"
      [showLabel]="false"
      [placeholder]="'placeholder.select' | translate"
      [form]="formCommission"
      [control]="'regionId'"
      [options]="regions"
      [label]="'common.regions' | translate"
      [btnStyle]="{ marginTop: '8px', maxWidth: '250px' }"
      [dropdownContainerStyle]="{ maxWidth: '250px' }"
      class="input-fields"
      [requiredErrMsg]="requiredErrMsg | translate"
      [formSubmitted]="submitted"
      (currentValueChange)="handleOnChangeRegionId($event)"
    >
    </boxxds-dropdown>

    <div class="margin-stepper-input">
      <boxxds-stepper-input
        [max]="100"
        [inputStyle]="{ background: 'transparent' }"
        (handleChange)="onChangeMinPersentage($event)"
        [value]="minPercentage"
        [formSubmitted]="submitted"
        [form]="formCommission"
        [control]="'minPercentage'"
        [containerStyle]="{ width: '83%', 'margin-top': '0px' }"
      ></boxxds-stepper-input>
    </div>

    <div class="margin-stepper-input">
      <boxxds-stepper-input
        [max]="100"
        [inputStyle]="{ background: 'transparent' }"
        (handleChange)="onChangeMaxPersentage($event)"
        [value]="maxPercentage"
        [formSubmitted]="submitted"
        [form]="formCommission"
        [control]="'maxPercentage'"
        [containerStyle]="{ width: '83%', 'margin-top': '0px' }"
      ></boxxds-stepper-input>
    </div>
    <div class="margin-stepper-input">
      <boxxds-stepper-input
        [min]="minPercentage ?? 0"
        [max]="maxPercentage ?? 100"
        [inputStyle]="{ background: 'transparent' }"
        (handleChange)="onChangeDefaultPercentage($event)"
        (handleKeyPress)="handleDefaultPercentageValidate($event)"
        [value]="defaultPercentage"
        [formSubmitted]="submitted"
        [form]="formCommission"
        [control]="'defaultPercentage'"
        [containerStyle]="{ width: '83%', 'margin-top': '0px' }"
      ></boxxds-stepper-input>
    </div>
  </div>
  <div class="actions-container-save-cancel-btn">
    <div>
      <a
        class="link-actions"
        href="javascript:void(0)"
        (click)="handleOnSave()"
        >{{ "common.save" | translate | uppercase }}</a
      >
    </div>
    <div>
      <a
        class="link-actions"
        href="javascript:void(0)"
        (click)="handleOnCancel()"
        >{{ "common.cancel" | translate | uppercase }}</a
      >
    </div>
  </div>
</form>
