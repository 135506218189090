<section class="popup-container">
  <section class="popup">
    <section class="title">
      <div class="icon-popup-title-wrapper">
        <div class="icon-box-wrapper">
          <div class="icon-box">
            <div class="icon-container">
              <svg
                class="icon-svg"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <g clip-path="url(#clip0_29700_3791)">
                  <path
                    d="M12.2478 9.50023V13.5002M12.2478 17.5002H12.2578M10.8631 4.39195L2.63823 18.5986C2.18203 19.3866 1.95393 19.7806 1.98764 20.1039C2.01705 20.386 2.16482 20.6423 2.39417 20.809C2.65713 21.0002 3.11239 21.0002 4.02292 21.0002H20.4727C21.3832 21.0002 21.8385 21.0002 22.1014 20.809C22.3308 20.6423 22.4786 20.386 22.508 20.1039C22.5417 19.7806 22.3136 19.3866 21.8574 18.5986L13.6325 4.39195C13.1779 3.60679 12.9506 3.21421 12.6541 3.08235C12.3954 2.96734 12.1002 2.96734 11.8415 3.08235C11.545 3.21421 11.3177 3.60679 10.8631 4.39195Z"
                    stroke="#D46B00"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_29700_3791">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(0.25 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div class="rings-wrapper">
            <div class="rings">
              <span style="--i: 1"></span>
              <span style="--i: 2"></span>
              <span style="--i: 3"></span>
              <span style="--i: 4"></span>
              <span style="--i: 5"></span>
              <span style="--i: 6"></span>
            </div>
          </div>
        </div>
        <h1>
          {{
            step !== 0
              ? ("workFlow3.components.revenueSanctionPopup.heading.actionRequired"
                | translate)
              : ("workFlow3.components.riskAnalysisPopup.label.riskAnalysisUpdateRequired"
                | translate)
          }}
        </h1>
      </div>
      <boxxds-button-v2
        [isDisabled]="false"
        [size]="'lg'"
        [btnType]="'close'"
        (handleClick)="handleCloseHandler($event)"
      >
      </boxxds-button-v2>
    </section>
    <section class="insured-broker-details">
      <div class="details-container">
        <p class="details-head">
          {{ "workFlow3.components.shared.label.quote" | translate }}
        </p>
        <p class="details">{{ details?.["quoteNumber"] ?? "" }}</p>
      </div>
      <div class="details-container">
        <p class="details-head">
          {{ "workFlow3.components.sanctionPopup.label.insured" | translate }}
        </p>
        <p class="details">{{ details?.["insured"] ?? "" }}</p>
      </div>
      <div class="details-container">
        <p class="details-head">
          {{ "workFlow3.components.sanctionPopup.label.brokerage" | translate }}
        </p>
        <p class="details">
          {{ details?.["brokerageName"] ?? "" }},
          {{ details?.["branchName"] ?? "" }}
        </p>
      </div>
      <div class="details-container">
        <p class="details-head">
          {{ "workFlow3.components.shared.label.effectiveDate" | translate }}
        </p>
        <p class="details">
          {{
            details?.["effectiveExpiryDatesTBDFlag"]
              ? "TBD"
              : formatDate(details?.["quotePeriod"] ?? "")
          }}
        </p>
      </div>
    </section>

    <!-- modal body -->
    <section class="modal-body">
      <!-- description -->
      <div class="description-wrapper">
        <p class="popup-description">
          {{
            step !== 0
              ? ("workFlow3.components.revenueSanctionPopup.description.revenueAndSanctionChangeDescription"
                | translate)
              : ("workFlow3.components.revenueSanctionPopup.description.revenueChangeDescription"
                | translate)
          }}
        </p>
      </div>
      <!-- description end -->

      <!-- saction table section -->
      <section *ngIf="step === 1" class="table-section">
        <div class="table-container">
          <p class="table-title">
            <span class="table-title-description">
              {{
                "workFlow3.components.revenueSanctionPopup.steps.step1"
                  | translate
              }}
            </span>
          </p>
          <div class="table-wrapper">
            <boxxds-table-v2
              [tableData]="sanctionTableData"
              [columnsToHide]="columnsToHide"
              [sortableColumns]="sortableColumns"
              [iconColumns]="iconColumns"
              [customColumnWidth]="customColumnWidth"
              (sortHandler)="handleSorting($event)"
              [columnsToHide]="['currentState']"
              (columnClickHandler)="columnClickHandle($event)"
              [iconColumns]="['Reasons']"
              [showPopOver]="true"
            >
            </boxxds-table-v2>
          </div>
          <div class="buttons-wrapper">
            <boxxds-button-v2
              [buttonText]="'Decline quotes' | translate"
              [type]="'submit'"
              [isDisabled]="false"
              [size]="'lg'"
              [btnClass]="'secondary  gray'"
              [btnType]="'default'"
              [isDropdown]="false"
              (handleClick)="handleDeclineQuotes($event)"
            >
            </boxxds-button-v2>
          </div>
        </div>
      </section>
      <!-- saction table section end -->

      <!-- risk questions section -->
      <section
        *ngIf="step === 2 || (sanctionTableData.length === 0 && riskQuestions)"
        class="risk-questions-section"
      >
        <div class="section-header">
          <h2 class="section-title">
            <span *ngIf="!(sanctionTableData.length === 0 && riskQuestions)"
              >{{
                "workFlow3.components.revenueSanctionPopup.steps.step2Label"
                  | translate
              }}
            </span>
            {{
              "workFlow3.components.revenueSanctionPopup.steps.step2"
                | translate
            }}
          </h2>
        </div>
        <div class="product-toggle-container">
          <a class="product-item" [class.product-item-selected]="true">{{
            details?.["riskName"]
          }}</a>
          <!-- <a class="product-item">E&0</a>
          <a class="product-item">Product 3</a> -->
        </div>
        <div class="questions-container">
          <div class="questions-header">
            <div class="header-radio-container">
              <boxxds-radio-v2
                [control]="'yesToAll'"
                class="radio-all"
                [form]="radioYesOrNoToAllForm"
                [formSubmitted]="false"
                (handleChange)="checkYesOrNoToAll($event)"
                [radioStyle]="{ width: '20px', height: '20px', left: '-7px' }"
                [labelStyle]="{
                  color: '#2D2D39',
                  fontFamily: 'Gibson',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  marginLeft: '25px'
                }"
                [items]="[
                  {
                    label: ('common.yesToAll' | translate),
                    currentValue:
                      radioYesOrNoToAllForm.value['yesToAll'] === 'true',
                    value: 'true'
                  },
                  {
                    label: ('common.noToAll' | translate),
                    currentValue:
                      radioYesOrNoToAllForm.value['yesToAll'] === 'false',
                    value: 'false'
                  }
                ]"
              ></boxxds-radio-v2>
            </div>
          </div>
          <div class="questions-body">
            <div
              class="question-item"
              *ngFor="let q of riskQuestions; let questnIdx = index"
            >
              <div class="question-paragraph">
                <p>
                  {{ q.question }}
                </p>
              </div>
              <div class="question-radio">
                <ng-container *ngIf="form">
                  <boxxds-radio-v2
                    [control]="q.id"
                    [form]="form"
                    [formSubmitted]="validateRiskAnswers"
                    [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
                    [labelStyle]="{
                      color: '#2D2D39',
                      fontFamily: 'Gibson',
                      fontSize: '14px',
                      fontWeight: '500'
                    }"
                    [items]="[
                      {
                        label: ('common.yes' | translate),
                        currentValue: q.value,
                        value: 'true'
                      },
                      {
                        label: ('common.no' | translate),
                        currentValue: q.value,
                        value: 'false'
                      }
                    ]"
                    (click)="handleRadioCheck()"
                  ></boxxds-radio-v2>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- risk questions section end -->
    </section>
    <!-- modal body end -->

    <!-- modal footer -->
    <div class="modal-footer">
      <boxxds-button-v2
        *ngIf="step === 2 || (sanctionTableData.length === 0 && riskQuestions)"
        [buttonText]="
          'workFlow3.components.sanctionPopup.button.discardChanges' | translate
        "
        [type]="'submit'"
        [size]="'lg'"
        [btnClass]="'tertiary gray'"
        [btnType]="'default'"
        [isDropdown]="false"
        (handleClick)="handleDiscardChangesHandler($event)"
      >
      </boxxds-button-v2>
      <boxxds-button-v2
        *ngIf="step === 2"
        [buttonText]="
          'workFlow3.components.revenueSanctionPopup.button.declineQuotes'
            | translate
        "
        [type]="'submit'"
        [size]="'lg'"
        [btnClass]="'secondary  gray'"
        [btnType]="'default'"
        [isDropdown]="false"
        (handleClick)="handleDeclineQuotes($event)"
      >
      </boxxds-button-v2>
      <boxxds-button-v2
        *ngIf="step === 1"
        [buttonText]="'workFlow3.components.shared.button.nextStep' | translate"
        [type]="'submit'"
        [size]="'lg'"
        [btnClass]="'primary'"
        [btnType]="'default'"
        [btnStyle]="{ padding: '10px 0px', width: '196px' }"
        [isDropdown]="false"
        (handleClick)="toStepTwo()"
      >
      </boxxds-button-v2>
      <boxxds-button-v2
        *ngIf="step === 2 || (sanctionTableData.length === 0 && riskQuestions)"
        [buttonText]="'workFlow3.components.shared.button.save' | translate"
        [type]="'submit'"
        [size]="'lg'"
        [btnClass]="'primary'"
        [btnType]="'default'"
        [btnStyle]="{ padding: '10px 0px', width: '196px' }"
        [isDropdown]="false"
        (click)="handleSaveAndContinue()"
      >
      </boxxds-button-v2>
    </div>
    <!-- modal footer -->
  </section>
</section>
