<div class="custom-modal" *ngIf="showModal">
  <div class="modal-content">
    <ng-container *ngIf="showSpinner || isUploadingProgress">
      <div class="body-spinner">
        <boxxds-spinner
          [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
        ></boxxds-spinner>
      </div>
    </ng-container>

    <ng-container>
      <div class="close-btn">
        <button
          (click)="!isUploadingProgress && closeHandler(true)"
          [disabled]="isUploadingProgress"
          [class.noPointerEvents]="isUploadingProgress"
        >
          <img src="assets/img/dashboard/card/close.png" />Close
        </button>
      </div>
      <div *ngIf="!showSpinner && !isUploadingProgress" class="content">
        <div style="position: relative">
          <app-modal-alert></app-modal-alert>
        </div>
        <div class="d-flex flex-column align-items-center">
          <img
            src="assets/img/alert-triangle.jpg"
            width="66px"
            height="66px"
            alt="alert"
          />
          <boxxds-body
            sz="md"
            [text]="'policyDocument.documentOverwritePopupTitle' | translate"
            [customStyle]="{ color: '#FE802C', marginTop: '12px' }"
          ></boxxds-body>
        </div>
        <div class="quote-details d-flex flex-column mt-1">
          <div class="d-flex mt-5">
            <boxxds-body
              sz="md"
              [text]="'policyDocument.existingFileName' | translate"
              [customStyle]="{
                color: '#616162',
                marginRight: '6px',
                lineHeight: '14px',
                fontWeight: '500'
              }"
            ></boxxds-body>
            <boxxds-body
              sz="md"
              [text]="existingFileName"
              [customStyle]="{ color: '#616162', lineHeight: '14px' }"
            ></boxxds-body>
          </div>
        </div>
        <div class="flex-column mb-5">
          <div class="d-flex">
            <div>
              <boxxds-body
                sz="s"
                [text]="'policyDocument.selectUploadingType' | translate"
                [customStyle]="{
                  color: '#616162',
                  lineHeight: '14px',
                  marginBottom: '20px',
                  fontSize: '14px'
                }"
              ></boxxds-body>
              <form [formGroup]="form">
                <boxxds-radio
                  [control]="'uploading_type'"
                  [items]="[
                    {
                      label: ('policyDocument.overwriteAndUpload' | translate),
                      value: 'overwrite',
                      id: 'quote' + 1
                    }
                  ]"
                  [form]="form"
                  [formSubmitted]="false"
                  [lblStyle]="getOverwriteLabelStyle()"
                  (handleChange)="radioChanged()"
                ></boxxds-radio>
                <boxxds-radio
                  [control]="'uploading_type'"
                  [items]="[
                    {
                      label: ('policyDocument.renameAndUpload' | translate),
                      value: 'rename',
                      id: 'quote' + 2
                    }
                  ]"
                  [form]="form"
                  [formSubmitted]="false"
                  [containerStyle]="{ marginTop: '10px' }"
                  [lblStyle]="getRenameLabelStyle()"
                  (handleChange)="radioChanged()"
                ></boxxds-radio>
              </form>
            </div>
            <div *ngIf="!overwrite && suggestedFileName" class="input-holder">
              <boxxds-textinput
                [form]="form"
                [type]="'text'"
                [placeholder]="'abc_file_123 COPY'"
                [label]="'policyDocument.renameTheExistingFile' | translate"
                [control]="'file_name'"
                [id]="'file_name'"
                [containerStyle]="{ marginTop: '16px' }"
                [formSubmitted]="checkValidation"
                [inputAutoFocus]="false"
                [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
                [invalidErrMsg]="'error.thisFieldIsInvalid' | translate"
              >
              </boxxds-textinput>
            </div>
          </div>
        </div>
        <boxxds-button
          [isDisabled]="isUploadingProgress"
          [class.noPointerEvents]="isUploadingProgress"
          [buttonText]="'policyDocument.confirmAndContinue' | translate"
          btnType="primary-outlined"
          [btnStyle]="{ width: '181px', marginLeft: 'auto', marginTop: '40px' }"
          (handleClick)="!isUploadingProgress && actionConfirmHandler($event)"
        >
        </boxxds-button>
      </div>
    </ng-container>
  </div>
</div>
