import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { BoxxResponse } from '../entities/boxx-response';
import { BrokerageProducerProfile } from '../entities/brokerage-producer-profile';

@Injectable({
  providedIn: 'root',
})
export class BrokerageProducerProfileService {
  // Base url
  baseurl = environment.msBrokerBaseUrl + environment.brokerageProducerProfile;
  constructor(private http: HttpClient) {}
  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  //GET
  GetBrokerageProduceCalcStatus(
    filter: {
      brokerageProducerBORId: number;
      brokerageBranchBORId: number;
      brokerageBORId: number;
    } = null,
  ): Observable<BoxxResponse<BrokerageProducerProfile>> {
    let queryParams = '';
    if (filter !== null) {
      queryParams =
        '?' +
        Object.entries(filter)
          .map(
            ([key, value]) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
          )
          .join('&');
    }
    return this.http
      .get<BoxxResponse<BrokerageProducerProfile>>(
        `${environment.msBrokerBaseUrl}${environment.brokerageProducerStatusPathUrl}${queryParams}`,
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  // GET
  GetBrokerageProducerProfile(
    brokerageProducerProfileId: number,
  ): Observable<BoxxResponse<BrokerageProducerProfile>> {
    return this.http
      .get<BoxxResponse<BrokerageProducerProfile>>(
        `${this.baseurl}${brokerageProducerProfileId}`,
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  // GET ALL
  GetBrokerageAllProducerProfile(
    filter: { brokerageProducerId: number; sort: string } = null,
    page = 1,
    limit = 10,
  ): Observable<BoxxResponse<BrokerageProducerProfile>> {
    let queryParams = '';
    if (filter !== null) {
      queryParams =
        '&' +
        Object.entries(filter)
          .map(
            ([key, value]) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
          )
          .join('&');
    }

    return this.http
      .get<BoxxResponse<BrokerageProducerProfile>>(
        `${this.baseurl}?page=${page}&limit=${limit}${queryParams}`,
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  // Error handling
  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => {
      return errorMessage;
    });
  }
}
