import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TransactionRiskActions } from 'src/app/dashboard/constants/lifecycle-actions';
import { addSpaceBeforeCapitalLetter } from 'src/app/dashboard/utils/lifecycle-utils';
import * as utils from 'src/app/dashboard/utils/lifecycle-utils';
import { KeyValueDto } from 'src/app/dtos/keyvalueList.dto';
import { DomainsService } from 'src/app/services/domains.service';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { getFormattedPeriod } from 'src/app/utils/formatDate';

@Component({
  selector: 'app-quote-action-popup-v2',
  templateUrl: './quote-action-popup-v2.component.html',
  styleUrls: ['./quote-action-popup-v2.component.less'],
})
export class QuoteActionPopupV2Component implements OnInit, OnChanges {
  @Input() showModal: boolean = false;
  @Input() details;

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleActionConfirm = new EventEmitter<{
    action: TransactionRiskActions;
    selectedReasonId: number;
  }>();

  private userId: number;
  reasonOptions: KeyValueDto[];
  closedReasons: KeyValueDto[];
  declineReasons: KeyValueDto[];
  referralReasons: KeyValueDto[];

  form: FormGroup;
  buttonDisabled: boolean = true;
  selectedReasonId: number = 0;
  statusType: string;
  titleText: string;

  constructor(
    private fb: FormBuilder,
    private domainsService: DomainsService,
    private localStorageService: LocalStorageService,
    private translate: TranslateService,
    private store: Store,
  ) {
    this.form = this.fb.group({
      reason: [''],
    });
  }

  shortDateFormat: string = 'MM/DD/YYYY';
  longDateFormat: string = 'MMM DD, YYYY';

  ngOnInit(): void {
    this.userId = this.localStorageService.getBoxxUserId();
    this.statusType = this.translate.instant('productForm.heading.quote');
  }

  ngOnChanges(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    this.populateReasonOptions(this.details?.action);
    if (this.details?.statusType) {
      this.statusType = this.details?.statusType;
    }
    this.titleText = this.statusType + '!';
    this.titleText =
      this.getActionName(this.details?.action) + ' ' + this.titleText;
  }

  getActionName(action: TransactionRiskActions) {
    const name = TransactionRiskActions[action];
    return addSpaceBeforeCapitalLetter(name);
  }

  getReasonLabel(action: TransactionRiskActions) {
    switch (action) {
      case TransactionRiskActions.Decline:
        return this.translate.instant('Reason for decline');
      case TransactionRiskActions.Close:
        return this.translate.instant('Reason for closing quote');
      case TransactionRiskActions.Refer:
        return this.translate.instant('Reason for referral');
      default:
        return this.translate.instant('Reason');
    }
  }

  /**
   * Populate the reason drop down list value based on the TransactionRiskActions type.
   * The values are loaded only one from the DomainsService per type
   */
  async populateReasonOptions(action: TransactionRiskActions) {
    let optionKey: string = '';
    switch (action) {
      case TransactionRiskActions.Decline:
        optionKey = 'DECLINEREASON';
        this.reasonOptions = this.declineReasons
          ? this.declineReasons
          : (this.declineReasons = await this.getReasonsFromApi(optionKey));
        break;
      case TransactionRiskActions.Close:
        optionKey = 'CLOSEDREASONS';
        this.reasonOptions = this.closedReasons
          ? this.closedReasons
          : (this.closedReasons = await this.getReasonsFromApi(optionKey));
        break;
      case TransactionRiskActions.Refer:
        optionKey = 'REFERRALREASON';
        this.reasonOptions = this.referralReasons
          ? this.referralReasons
          : (this.referralReasons = await this.getReasonsFromApi(optionKey));
        break;
      default:
        this.reasonOptions = undefined;
        return;
    }
  }

  async getReasonsFromApi(optionKey: string): Promise<KeyValueDto[]> {
    const reasons = await this.domainsService.GetDomainListAsync(optionKey);
    return reasons.map((data) => {
      return { key: data.value, value: data.id.toString(), id: data.id };
    });
  }

  closeHandler() {
    this.handleClose.emit();
    this.resetForm();
  }

  reasonHandler(value: number) {
    this.selectedReasonId = value;
    this.buttonDisabled = !this.isValidSelection(value);
  }

  actionConfirmHandler(event) {
    if (this.isValidSelection(this.selectedReasonId)) {
      this.handleActionConfirm.emit({
        action: this.details.action,
        selectedReasonId: this.selectedReasonId,
      });

      this.resetForm();
    }
  }

  private isValidSelection(value: number): boolean {
    return value && value > 0;
  }

  private resetForm() {
    this.selectedReasonId = 0;
    this.buttonDisabled = true;
    this.reasonOptions = undefined;
  }

  formatDate(date) {
    return date
      ? getFormattedPeriod(date, this.shortDateFormat, 'MMM DD, YYYY')
      : '';
  }
}
