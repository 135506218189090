<div
  class="modal-outer-subjectivities"
  *ngIf="showModal"
  (click)="handleBackDropClick()"
>
  <div class="modal-container" (click)="handleModalClick($event)">
    <div class="modal-content-list">
      <boxxds-textinput-v2
        [showClearButton]="true"
        (handleClearValue)="clearValue()"
        [form]="form"
        [placeholder]="
          'workFlow3.components.modalSearchDocuments.placeholder.wordings'
            | translate
        "
        [label]="''"
        [control]="'filterKeyWord'"
        [id]="'search'"
        [leftIcon]="'assets/img/workflow3/sidemenu/search.png'"
        [width]="'517px'"
        (handleKeyUp)="onSearch($event)"
        maxLength="500"
      >
      </boxxds-textinput-v2>

      <div class="subjectivities-list" (scroll)="onScroll($event)">
        <ng-container
          *ngFor="let sub of wordingOptions[product]; let i = index"
        >
          <div
            class="list-item"
            [ngClass]="{
              'filtered-result': form.value['filterKeyWord']?.length >= 3
            }"
          >
            <button
              class="list-item-name"
              (click)="addClickHandler(sub)"
              [attr.disabled]="sub.disabled ? true : null"
              [innerHtml]="
                form.value['filterKeyWord']?.length >= 3
                  ? getFilteredResult(sub.key)
                  : sub.key
              "
            ></button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
