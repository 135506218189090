import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-notify-badge',
  templateUrl: './notification-badge.component.html',
  styleUrls: ['./notification-badge.component.less'],
})
export class NotificationBadgeComponent {
  @Input() content: string;
  constructor() {}
}
