<div class="holder">
  <boxxds-heading sz="5" [ngStyle]="{ marginBottom: '50px' }" [text]="fullNme">
  </boxxds-heading>
  <boxxds-alert
    *ngIf="showErrorAlert"
    (handleCloseEvent)="handleCloseSuccessEvent()"
    type="error"
    headerText="error!"
    [bodyText]="msgErrorAlert"
  >
  </boxxds-alert>
  <div *ngIf="boxxuser || insured">
    <div [ngStyle]="{ marginTop: '32px' }" class="d-flex gap-col-150 flex-wrap">
      <div>
        <div class="d-flex my-1 align-items-center">
          <boxxds-body
            sz="xxs"
            [text]="'TYPE'"
            [customStyle]="{
              color: '#8F8F91',
              textTransform: 'uppercase',
              marginRight: '20px'
            }"
          >
          </boxxds-body>
          <boxxds-body
            sz="sm"
            [text]="type"
            [customStyle]="{ color: '#56565C' }"
          >
          </boxxds-body>
        </div>
        <div class="d-flex mb-1 align-items-center" *ngIf="boxxuser">
          <boxxds-body
            sz="xxs"
            [text]="'TITLE'"
            [customStyle]="{
              color: '#8F8F91',
              textTransform: 'uppercase',
              marginRight: '30px'
            }"
          >
          </boxxds-body>
          <boxxds-body
            sz="sm"
            [text]="title"
            [customStyle]="{ color: '#56565C' }"
          ></boxxds-body>
        </div>
        <div class="d-flex mb-1 align-items-center" *ngIf="insured">
          <boxxds-body
            sz="xxs"
            [text]="'INSURED TYPE'"
            [customStyle]="{
              color: '#8F8F91',
              textTransform: 'uppercase',
              marginRight: '30px'
            }"
          >
          </boxxds-body>
          <boxxds-body
            sz="sm"
            [text]="insuredType"
            [customStyle]="{ color: '#56565C' }"
          ></boxxds-body>
        </div>
        <div class="d-flex mb-1 align-items-center">
          <boxxds-body
            sz="5"
            [text]="form.value['roleName']"
            sz="xxs"
            [text]="'ROLE'"
            [customStyle]="{
              color: '#8F8F91',
              textTransform: 'uppercase',
              marginRight: '20px'
            }"
          >
          </boxxds-body>
          <ng-container *ngIf="isRoleNameEditMode">
            <boxxds-textinput
              [inputAutoFocus]="true"
              [form]="form"
              [placeholder]="'Enter role name'"
              [control]="'roleName'"
              [id]="'roleName'"
              [width]="'100%'"
              [containerStyle]="{ maxWidth: '250px' }"
              (handleBlur)="togglePolicyFeeEdit($event, isRoleNameEditMode)"
            >
            </boxxds-textinput>
          </ng-container>
          <div
            *ngIf="!isRoleNameEditMode"
            class="btn-edit d-flex align-items-center"
            (click)="togglePolicyFeeEdit($event, isRoleNameEditMode)"
          >
            <boxxds-body
              sz="xs"
              [text]="'System admin'"
              [customStyle]="{
                color: '#616162',
                lineHeight: '14px',
                marginLeft: '4px',
                marginRight: '4px'
              }"
            ></boxxds-body>
            <img
              *ngIf="!isRoleNameEditMode"
              src="assets/img/edit-icon.png"
              alt="edit"
              class="img-icon"
            />
          </div>
        </div>
      </div>
      <div>
        <div class="d-flex my-1" *ngIf="boxxuser">
          <boxxds-body
            sz="xxs"
            [text]="'Manager'"
            [customStyle]="{
              color: '#8F8F91',
              textTransform: 'uppercase',
              marginRight: '20px'
            }"
          >
          </boxxds-body>
          <boxxds-body
            sz="sm"
            [text]="managerName"
            [customStyle]="{ color: '#56565C' }"
          >
          </boxxds-body>
        </div>
        <div class="d-flex mb-1">
          <boxxds-body
            sz="xxs"
            [text]="'Login Email'"
            [customStyle]="{
              color: '#8F8F91',
              textTransform: 'uppercase',
              marginRight: '30px'
            }"
          >
          </boxxds-body>
          <div class="d-flex">
            <boxxds-body
              sz="sm"
              [text]="loginEmail"
              [customStyle]="{ color: '#56565C' }"
            >
            </boxxds-body>
            <a class="reset-link" href="#" tabindex="0"
              >Send password reset link</a
            >
          </div>
        </div>
        <div class="d-flex mb-1">
          <boxxds-body
            sz="xxs"
            [text]="'Status'"
            [customStyle]="{
              color: '#8F8F91',
              textTransform: 'uppercase',
              marginRight: '20px'
            }"
          >
          </boxxds-body>
          <boxxds-body
            sz="sm"
            [text]="active"
            [customStyle]="{ color: '#56565C' }"
          >
          </boxxds-body>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="producer">
    <div [ngStyle]="{ marginTop: '32px' }" class="d-flex gap-col-150 flex-wrap">
      <div>
        <div class="d-flex my-1 align-items-center">
          <boxxds-body
            sz="xxs"
            [text]="'TYPE'"
            [customStyle]="{
              color: '#8F8F91',
              textTransform: 'uppercase',
              marginRight: '20px'
            }"
          >
          </boxxds-body>
          <boxxds-body
            sz="sm"
            [text]="type"
            [customStyle]="{ color: '#56565C' }"
          >
          </boxxds-body>
        </div>
        <div class="d-flex mb-1 align-items-center">
          <boxxds-body
            sz="xxs"
            [text]="'Brokerage'"
            [customStyle]="{
              color: '#8F8F91',
              textTransform: 'uppercase',
              marginRight: '30px'
            }"
          >
          </boxxds-body>
          <boxxds-body
            sz="sm"
            [text]="brokerageName"
            [customStyle]="{ color: '#56565C' }"
          ></boxxds-body>
        </div>
        <div class="d-flex mb-1 align-items-center">
          <boxxds-body
            sz="xxs"
            [text]="'Branch'"
            [customStyle]="{
              color: '#8F8F91',
              textTransform: 'uppercase',
              marginRight: '30px'
            }"
          >
          </boxxds-body>
          <boxxds-body
            sz="sm"
            [text]="branchName"
            [customStyle]="{ color: '#56565C' }"
          ></boxxds-body>
        </div>
      </div>
      <div>
        <div class="d-flex mb-1 align-items-center">
          <boxxds-body
            sz="5"
            [text]="form.value['roleName']"
            sz="xxs"
            [text]="'ROLE'"
            [customStyle]="{
              color: '#8F8F91',
              textTransform: 'uppercase',
              marginRight: '20px'
            }"
          >
          </boxxds-body>
          <ng-container *ngIf="isRoleNameEditMode">
            <boxxds-textinput
              [inputAutoFocus]="true"
              [form]="form"
              [placeholder]="'Enter role name'"
              [control]="'roleName'"
              [id]="'roleName'"
              [width]="'100%'"
              [containerStyle]="{ maxWidth: '250px' }"
              (handleBlur)="togglePolicyFeeEdit($event, isRoleNameEditMode)"
            >
            </boxxds-textinput>
          </ng-container>
          <div
            *ngIf="!isRoleNameEditMode"
            class="btn-edit d-flex align-items-center"
            (click)="togglePolicyFeeEdit($event, isRoleNameEditMode)"
          >
            <boxxds-body
              sz="xs"
              [text]="'System admin'"
              [customStyle]="{
                color: '#616162',
                lineHeight: '14px',
                marginLeft: '4px',
                marginRight: '4px'
              }"
            ></boxxds-body>
            <img
              *ngIf="!isRoleNameEditMode"
              src="assets/img/edit-icon.png"
              alt="edit"
              class="img-icon"
            />
          </div>
        </div>
        <div class="d-flex mb-1">
          <boxxds-body
            sz="xxs"
            [text]="'Login Email'"
            [customStyle]="{
              color: '#8F8F91',
              textTransform: 'uppercase',
              marginRight: '30px'
            }"
          >
          </boxxds-body>
          <div class="d-flex">
            <boxxds-body
              sz="sm"
              [text]="loginEmail"
              [customStyle]="{ color: '#56565C' }"
            >
            </boxxds-body>
            <a class="reset-link" href="#" tabindex="0"
              >Send password reset link</a
            >
          </div>
        </div>
        <div class="d-flex mb-1">
          <boxxds-body
            sz="xxs"
            [text]="'Status'"
            [customStyle]="{
              color: '#8F8F91',
              textTransform: 'uppercase',
              marginRight: '20px'
            }"
          >
          </boxxds-body>
          <boxxds-body
            sz="sm"
            [text]="active"
            [customStyle]="{ color: '#56565C' }"
          >
          </boxxds-body>
        </div>
      </div>
    </div>
  </div>
  <boxxds-table
    [data]="permissions"
    *ngIf="permissions.length > 0"
    [totalCount]="permissionsDataCount"
    (pageChanged)="permissionPageChangeHandler($event)"
    class="table"
    id="user-table"
    addCopyBtnToField="Email"
    [addSortTo]="addSortTo"
    [styleInactive]="true"
    [showLinks]="false"
    [tableStyle]="{ marginTop: '52px' }"
  >
  </boxxds-table>
</div>
