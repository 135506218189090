import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, Subject, filter, takeUntil } from 'rxjs';
import { ProductInfoComponent } from './product-info/product-info.component';
import { Store } from '@ngrx/store';
import { getQuoteSelector } from '../store/create-quote/create-quote.selector';
import { NewSubmissionQuote4FlowComponent } from './new-submission/new-submission.component';
import {
  getSharedData,
  getUIData,
} from '../store/create-quote/create-quote.selector';
import { take } from 'rxjs';
import { InsuredService } from '../services/insured.service';
import { QuoteCalculatorAndOptionsComponent } from './quote-calculator-and-options/quote-calculator-and-options.component';
import { PolicyConfigurationComponent } from './policy-configuration/policy-configuration.component';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-quote4-flow',
  templateUrl: './quote4-flow.component.html',
  styleUrls: ['./quote4-flow.component.less'],
})
export class Quote4FlowComponent implements OnInit, AfterViewInit, OnDestroy {
  pageName = 'Test Data';
  showPageHeader = false;
  private destroy$ = new Subject<void>();
  quoteDetailSubscription;
  progressBarData = {
    showProgressBar: false,
    activeStep: 1,
    completedSteps: 1,
    totalSteps: 4,
    steps: [
      'Insured information',
      'Product information',
      'Quote options',
      'Configure and bind',
    ],
  };
  insuredId;
  insuredInfo = [
    {
      companyName: 'Bank of America',
      revenue: '$2M',
      industry: 'Manufacturing',
      numOfEmployees: '100-200',
      industryClass: 'Automobiles',
      fullAddress:
        'Johnathon Smith, Jsmith@email.com <br/>123 Toronto Ave, Toronto, ON, Canada M1A 2B4',
    },
  ];

  brokerageInfo = [
    {
      brokerage: 'BrokerageName',
      branch: 'BranchName',
      producer: 'ProducerName',
    },
  ];

  showPremiumDetails: boolean = false;
  showBrokerDetails: boolean = false;
  showInsurerDetails: boolean = false;
  stepHeader = 'Insured Information';
  quoteId;
  form$: Observable<any>;
  showInfoBanner: boolean = true;
  infoTitle: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private insuredService: InsuredService,
    private viewPortScroller: ViewportScroller,
  ) {
    this.router.events.subscribe((event: any) => {
      if (event?.routerEvent instanceof NavigationEnd) {
        this.updateHeaderData();
      }
    });
  }

  async ngOnInit() {
    await this.store
      .select(getSharedData)
      .pipe(take(1))
      .subscribe((value: any) => {
        this.stepHeader = value.step;

        if ([3, 4].includes(this.progressBarData.activeStep)) {
          this.store
            .select(getUIData)
            .pipe(take(1))
            .subscribe(async (value: any) => {
              this.insuredId = value.insuredId;

              this.brokerageInfo[0] = {
                brokerage: value.broker,
                branch: value.branch,
                producer: value.producer,
              };
              if (!!this.insuredId) {
                await this.insuredService
                  .GetByInsuredId(this.insuredId)
                  .subscribe((insuredData) => {
                    const insured: any = insuredData?.data;
                    this.insuredInfo[0] = {
                      companyName: insured?.companyName,
                      revenue: insured?.revenue,
                      industry: insured?.industry?.name,
                      numOfEmployees: insured?.numOfEmployees,
                      industryClass: insured?.industryClass?.name,
                      fullAddress:
                        insuredData?.data?.insuredLocation?.fullAddress,
                    };
                  });
              }
            });
        }
      });
    const parent = document.querySelector('.content');

    const optionalChild = parent.querySelector('.info-holder');

    if (optionalChild) {
      parent.classList.add('optional-info-holder');
    } else {
      parent.classList.remove('optional-info-holder');
    }
    this.form$ = this.store.select(getQuoteSelector);
    this.quoteDetailSubscription = this.form$
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        let test: any = event;
        this.quoteId = event.ui.policyId;
        this.insuredInfo = [
          {
            companyName: event.ui.insuredName,
            revenue: test.ui.revenue,
            industry: test.ui.industry,
            numOfEmployees: test.ui.noOfEmployees,
            industryClass: test.ui.industryClass,
            fullAddress: test.ui.address,
          },
        ];

        this.brokerageInfo = [
          {
            brokerage: event.ui.broker,
            branch: event.ui.branch,
            producer: event.ui.producer,
          },
        ];
      });
  }
  ngAfterViewInit(): void {
    this.viewPortScroller.scrollToPosition([0, 0]);
  }
  ngOnDestroy(): void {
    this.quoteDetailSubscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }
  updateHeaderData() {
    const routeComponent = this.route.firstChild?.component;
    this.showPageHeader = false;
    this.progressBarData.showProgressBar = false;
    this.showInsurerDetails = false;
    this.showBrokerDetails = false;
    this.showPremiumDetails = false;
    this.showInfoBanner = false;
    if (routeComponent === NewSubmissionQuote4FlowComponent) {
      this.showPageHeader = true;
      this.pageName = 'Insured Information';
      this.progressBarData.activeStep = 1;
      this.progressBarData.completedSteps = 0;
      this.progressBarData.showProgressBar = true;
    } else if (routeComponent === ProductInfoComponent) {
      this.showPageHeader = true;
      this.pageName = 'Product Information';
      this.progressBarData.showProgressBar = true;
      this.progressBarData.activeStep = 2;
      this.progressBarData.completedSteps = 1;
      this.showInsurerDetails = true;
      this.showInfoBanner = true;
      this.infoTitle = 'Risk Analysis';
    } else if (routeComponent === QuoteCalculatorAndOptionsComponent) {
      this.showPageHeader = true;
      this.pageName = 'Quote ID: ';
      this.progressBarData.showProgressBar = true;
      this.progressBarData.activeStep = 3;
      this.progressBarData.completedSteps = 2;
      this.showInsurerDetails = true;
      this.showBrokerDetails = true;
      this.showInfoBanner = true;
      this.infoTitle = 'Quote';
    } else if (routeComponent === PolicyConfigurationComponent) {
      this.showPageHeader = true;
      this.pageName = 'Policy configuration';
      this.progressBarData.showProgressBar = true;
      this.progressBarData.activeStep = 4;
      this.progressBarData.completedSteps = 3;
      this.showInsurerDetails = true;
      this.showBrokerDetails = true;
      this.showPremiumDetails = true;
      this.showInfoBanner = true;
      this.infoTitle = 'Configuration';
    }
  }
}
