import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Pagination } from 'src/app/entities/boxx-response';
import { AlertService } from 'src/app/services/alert.service';
import { DomainsService } from 'src/app/services/domains.service';
import { PolicyRiskDocService } from 'src/app/services/policy-risk-doc.service';
import {
  excelFileDownload,
  getAlertHead,
  getErrorMessage,
} from 'src/app/utils/utils';
import { PolicyRiskDocPreviewService } from 'src/app/services/policy-risk-doc-preview.service';
import { formatDateMoment } from 'src/app/utils/formatDate';
import { PolicyRiskDocUploadService } from 'src/app/services/policy-risk-doc-upload.service';
import { Store, select } from '@ngrx/store';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { DocumentTemplateService } from 'src/app/services/doc-template.service';
import * as objects from 'src/app/dashboard/policy-lifecycle/objects/policy-lifecycle-objects';
import {
  DOC_GENERATION_WAIT_TIME,
  GENERATE_DOC_SUCCESS_STATUS_CODE,
} from 'src/app/constants/quote-constant';

@Component({
  selector: 'app-slideout-document',
  templateUrl: './slideout-document.component.html',
  styleUrls: ['./slideout-document.component.less'],
})
export class SlideoutDocumentComponent implements OnInit {
  isGettingSuggestionProgress: boolean;
  constructor(
    private domainsService: DomainsService,
    private policyRiskDocService: PolicyRiskDocService,
    private PolicyRiskDocPreviewService: PolicyRiskDocPreviewService,
    private translate: TranslateService,
    private policyRiskDocUploadService: PolicyRiskDocUploadService,
    private alertService: AlertService,
    private store: Store,
    private documentTemplateService: DocumentTemplateService,
  ) {}
  @ViewChild('fileDropRef') fileDropRef!: ElementRef<HTMLInputElement>;

  @Input() policyRiskId: number;
  @Input() permissionList: { [x: string]: boolean } = {};
  @Input() currentScreen;
  @Input() showDocPreview = false;
  @Input() showImagePreview = false;
  @Input() policyPeriodId: number;
  @Input() policyStatus: string = '';
  @Input() closeModalClicked: boolean = false;
  @Input() isProductActive = false;

  @Output() handleShowGenerateDocMsg = new EventEmitter<any>();

  showTabLoader: boolean = false;
  showBodySpinner: boolean = true;
  showModalSpinner: boolean = true;
  showDocumentActionPopup: boolean = false;
  private documentTypeId: number;
  file: any;
  existingFileName: string;
  suggestedFileName: string;
  filesData: { pagination: Pagination | null } = {
    pagination: null,
  };

  currentPreviewDocUrl = '';
  currentFileType = '';
  formData: FormData;

  tableHeaders = [
    'common.fileName',
    'common.updatedBy',
    'common.dateUpdated',
    'common.createdBy',
    'common.dateCreatedDoc',
    'common.fileDescription',
  ];

  files: any[] = [];
  totalCount: number = 1;
  currentPage: number = 1;
  itemsPerPage = 10;
  totalPages: number = 1;
  docStageId = undefined;
  allowedFormats = [
    '.jpg',
    '.jpeg',
    '.gif',
    '.txt',
    '.doc',
    '.docx',
    '.pdf',
    '.pptx',
  ];
  maxFileSizeMB = 5;
  showDeleteFileConfirm = false;
  currentFile: { [x: string]: string } = {};
  currentFileIndex: number = undefined;
  isButtonDisabled: boolean = false;
  shortDateFormat: string = '';
  longDateFormat: string = '';
  existingFiles: any[] = [];
  isUploadingProgress: boolean = false;
  stageIdPolicy: number = undefined;
  newTimeout: NodeJS.Timeout;
  loadDocApiCount: number = 0;
  showDocTempSelectionPopup: boolean = false;
  docPopupDetails: {
    statusType: string;
    action: any;
    documentTemplate: any;
    policyPeriodId: any;
    stageId: any;
    policyRiskId: any;
  };
  docDetails: { [x: string]: string | number | boolean }[] = [];
  rowDocDetails: { [x: string]: string | number | boolean } = {};
  details: objects.PolicyDetail;

  ngOnInit(): void {
    this.showModalSpinner = true;
    this.populateDocuments(this.currentPage);
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    this.docPopupDetails = {
      statusType: 'Document template selection',
      action: 'docTempSelection',
      documentTemplate: [],
      policyPeriodId: 0,
      stageId: 0,
      policyRiskId: 0,
    };
  }

  async populateDocuments(pageNumber) {
    if (!this.policyRiskId) {
      this.showTabLoader = false;
      return;
    }
    this.showModalSpinner = true;
    if (!this.docStageId) await this.getStageId();
    this.policyRiskDocService
      .getPolicyRiskDocCore(
        this.policyRiskId,
        this.docStageId,
        this.documentTypeId,
        pageNumber,
        this.itemsPerPage,
      )
      .subscribe({
        next: (docData) => {
          ({ pagination: this.filesData.pagination } = docData);
          this.files = docData?.data.map((doc) => ({
            ...doc,
            id: doc.id,
            name: this.getFileName(doc.documentPath),
            path: doc.documentPath,
            dateCreated: formatDateMoment(doc?.createdDt, this.shortDateFormat),
            createdBy: doc.createdBy || '',
            dateUpdated: formatDateMoment(doc?.updatedDt, this.shortDateFormat),
            updatedBy: doc.updatedBy || '',
            description: doc?.documentDescription ?? '',
          }));
          this.showTabLoader = false;
          this.showModalSpinner = false;
          this.totalPages = this.filesData?.pagination?.totalPages;
          this.currentPage = pageNumber;
          this.totalCount = this.filesData?.pagination?.totalRecords;
          this.closeDocPreview();
        },
        error: (error) => {
          this.showTabLoader = false;
          this.showModalSpinner = false;
          clearTimeout(this.newTimeout);
          if (![500].includes(error?.status)) {
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
              wrapperStyle:
                this.existingFiles.length === 0
                  ? { margin: '30px 0px 0px -75px' }
                  : {},
            };
            this.alertService.addAlert(alertData);
          }
        },
      });
  }

  async getStageId() {
    this.docStageId = undefined;
    return new Promise<void>((resolve, reject) => {
      this.domainsService.GetByDomainCode('PROCESSINGSTATUS', true).subscribe({
        next: (response) => {
          let docStage = response.data.filter(
            (template) =>
              template.subdomaincode === 'PROCESSINGSTATUS_COMPLETE',
          )[0];
          this.docStageId = docStage.id;
          resolve();
        },
        error: (error) => {
          this.showTabLoader = false;
          if (![500].includes(error?.status)) {
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
              wrapperStyle:
                this.existingFiles.length === 0
                  ? { margin: '30px 0px 0px -75px' }
                  : {},
            };
            this.alertService.addAlert(alertData);
          }
          reject();
        },
      });
    });
  }

  handleDocPreview(file: any) {
    this.alertService.clearAlerts(-1);
    const fileNameArrayLength = file.name.split('.')?.length;
    const lastElementIdx = fileNameArrayLength - 1;
    let mimeType = (file.name.split('.')?.[lastElementIdx]).toLowerCase();

    if (!['pdf', 'jpg', 'jpeg', 'gif', 'txt'].includes(mimeType)) {
      return;
    }

    const documentId = file?.id;
    try {
      this.showBodySpinner = true;
      this.showModalSpinner = true;
      if (documentId) {
        if (['jpg', 'jpeg', 'gif', 'txt'].includes(mimeType)) {
          this.showImagePreview = true;
        } else {
          this.showDocPreview = true;
        }
        this.PolicyRiskDocPreviewService.GetPolicyRiskDocToPreview(
          documentId,
          'document',
        ).subscribe({
          next: (documentData) => {
            if (documentData?.data) {
              if (['txt'].includes(mimeType)) {
                this.currentPreviewDocUrl = `data:text/plain;base64,${documentData.data.content}`;
                this.currentFileType = 'text';
              } else if (['jpg', 'jpeg', 'gif'].includes(mimeType)) {
                this.currentPreviewDocUrl = `data:image/${mimeType};base64,${documentData.data.content}`;
                this.currentFileType = 'image';
              } else {
                this.currentPreviewDocUrl = documentData.data.content;
              }
            } else {
              this.showDocPreview = false;
              this.showImagePreview = false;
              this.currentPreviewDocUrl = '';
              this.currentFileType = '';
            }
            this.showBodySpinner = false;
            this.showModalSpinner = false;
          },
          error: (error) => {
            this.currentPreviewDocUrl = '';
            this.currentFileType = '';
            this.showBodySpinner = false;
            this.showModalSpinner = false;
            this.showDocPreview = false;
            this.showImagePreview = false;
            if (![500].includes(error?.status)) {
              let notificationAlert = {
                type: 'error',
                headerText: getAlertHead('error'),
                bodyText: getErrorMessage(error),
                wrapperStyle:
                  this.existingFiles.length === 0
                    ? { margin: '30px 0px 0px -75px' }
                    : {},
              };
              this.alertService.addAlert(notificationAlert);
            }
          },
        });
      } else {
        this.showDocPreview = false;
        this.showImagePreview = false;
        this.currentPreviewDocUrl = '';
        this.currentFileType = '';
        this.showBodySpinner = false;
        this.showModalSpinner = false;
      }
    } catch (error) {
      if (![500].includes(error?.status)) {
        let notificationAlert = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: error?.message ?? '',
          wrapperStyle:
            this.existingFiles.length === 0
              ? { margin: '30px 0px 0px -75px' }
              : {},
        };
        this.alertService.addAlert(notificationAlert);
      }
      this.currentPreviewDocUrl = '';
      this.currentFileType = '';
      this.showBodySpinner = false;
      this.showModalSpinner = false;
      this.showDocPreview = false;
      this.showImagePreview = false;
    }
  }

  handleDocDownload(file: any) {
    this.alertService.clearAlerts(-1);
    const documentId = file?.id;
    try {
      this.showBodySpinner = true;
      this.showModalSpinner = true;
      if (documentId) {
        this.PolicyRiskDocPreviewService.GetPolicyRiskDocToPreview(
          documentId,
          'document',
        ).subscribe({
          next: (documentData) => {
            if (documentData?.data) {
              const base64String = documentData?.data?.content;
              const binaryData = atob(base64String);
              const bufferData = new Uint8Array(
                binaryData.split('').map((char) => char.charCodeAt(0)),
              );
              excelFileDownload(file?.name ?? '', bufferData);
            }
            this.showBodySpinner = false;
            this.showModalSpinner = false;
          },
          error: (error) => {
            this.showBodySpinner = false;
            this.showModalSpinner = false;
            if (![500].includes(error?.status)) {
              const alertData = {
                type: 'error',
                headerText: getAlertHead('error'),
                bodyText: getErrorMessage(error),
                wrapperStyle:
                  this.existingFiles.length === 0
                    ? { margin: '30px 0px 0px -75px' }
                    : {},
              };
              this.alertService.addAlert(alertData);
            }
          },
        });
      } else {
        this.showBodySpinner = false;
        this.showModalSpinner = false;
      }
    } catch (error) {
      if (![500].includes(error?.status)) {
        const alertData = {
          show: true,
          type: 'error',
          headerText: 'error!',
          bodyText: error?.message ?? '',
          wrapperStyle:
            this.existingFiles.length === 0
              ? { margin: '30px 0px 0px -75px' }
              : {},
        };
        this.alertService.addAlert(alertData);
      }
      this.currentPreviewDocUrl = '';
      this.currentFileType = '';
      this.showBodySpinner = false;
      this.showModalSpinner = false;
    }
  }

  getFileName(path) {
    const pathSplit = path.split('/');
    return pathSplit[pathSplit.length - 1];
  }

  handleDelete(index: number, file: any) {
    this.alertService.clearAlerts(-1);
    if (!file.deletable) {
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: this.translate.instant(
          'policy.document.error.fileDeleteErrorMsg',
        ),
        wrapperStyle:
          this.existingFiles.length === 0
            ? { margin: '30px 0px 0px -75px' }
            : {},
      };
      this.alertService.addAlert(alertData);
      return;
    }
    this.currentFile = file;
    this.currentFileIndex = index;
    this.showBodySpinner = false;
    this.showModalSpinner = false;
    this.showDeleteFileConfirm = true;
  }

  handleDeleteConfirm() {
    this.isButtonDisabled = true;
    this.deleteFile(this.currentFileIndex, this.currentFile);
  }

  deleteFile(index: number, file: any) {
    this.alertService.clearAlerts(-1);
    if (!file.deletable) {
      this.isButtonDisabled = false;
      return;
    }
    this.policyRiskDocService.deletePolicyRiskDocCore(file.id, 1).subscribe({
      next: async (response) => {
        this.isButtonDisabled = false;
        this.files.splice(index, 1);
        let totalNumberOfFiles = this.filesData.pagination.totalRecords - 1;
        this.filesData.pagination.totalRecords = totalNumberOfFiles;
        this.totalPages = Math.ceil(totalNumberOfFiles / this.itemsPerPage);
        this.totalCount = totalNumberOfFiles;
        if (this.currentPage > this.totalPages) {
          this.currentPage = this.currentPage - 1;
          await this.populateDocuments(this.currentPage);
        } else {
          this.closeDocPreview();
        }
        const alertData = {
          type: 'success',
          headerText: getAlertHead('success'),
          bodyText: 'policy.document.delete.success.body',
          wrapperStyle:
            this.existingFiles.length === 0
              ? { margin: '30px 0px 0px -75px' }
              : {},
        };
        this.alertService.addAlert(alertData);
      },
      error: (error) => {
        this.isButtonDisabled = false;
        if (![500].includes(error?.status)) {
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: getErrorMessage(error),
            wrapperStyle:
              this.existingFiles.length === 0
                ? { margin: '30px 0px 0px -75px' }
                : {},
          };
          this.alertService.addAlert(alertData);
          this.closeDocPreview();
        }
      },
    });
  }

  closeDocPreview() {
    this.currentPreviewDocUrl = '';
    this.currentFileType = '';
    this.showBodySpinner = false;
    this.showModalSpinner = false;
    this.showDocPreview = false;
    this.showImagePreview = false;
    this.showDeleteFileConfirm = false;
    this.currentFile = {};
    this.currentFileIndex = undefined;
    this.isButtonDisabled = false;
  }

  onFileDropped(event) {
    this.prepareFilesList(event);
  }

  fileBrowseHandler(e) {
    let files: any = e.target.files;
    this.prepareFilesList(files);
  }

  prepareFilesList(files: Array<any>) {
    this.alertService.clearAlerts(-1);
    try {
      this.file = files;
      let fileUploadCounter = files.length;
      for (const item of files) {
        this.showModalSpinner = true;
        const fileSizeMB = item.size / (1024 * 1024);
        if (fileSizeMB <= this.maxFileSizeMB) {
          const fileExtension = this.getFileExtension(item);
          if (this.allowedFormats.includes(fileExtension.toLowerCase())) {
            this.formData = new FormData();
            this.formData.append('file', item, item.name);
            this.formData.append('policyRiskId', this.policyRiskId.toString());
            this.policyRiskDocUploadService
              .uploadPolicyRiskDoc(this.formData)
              .subscribe({
                next: (response) => {
                  fileUploadCounter -= 1;
                  fileUploadCounter === 0 &&
                    this.existingFiles.length !== 0 &&
                    this.handleExistingFilesUpload();
                  const alertData = {
                    show: true,
                    type: 'success',
                    headerText: 'success!',
                    bodyText: 'policy.document.upload.success.body',
                    wrapperStyle:
                      this.existingFiles.length === 0
                        ? { margin: '30px 0px 0px -75px' }
                        : {},
                  };
                  this.file = null;
                  this.alertService.addAlert(alertData);
                  this.showModalSpinner = false;
                  this.clearFileInput();
                  this.populateDocuments(this.currentPage);
                },
                error: (error) => {
                  fileUploadCounter -= 1;
                  let errMsg: string = '';
                  if (typeof error === 'string') {
                    if (
                      error.includes(
                        this.translate.instant(
                          'policy.document.upload.error.responseText',
                        ),
                      )
                    ) {
                      errMsg = this.translate.instant(
                        'policy.document.upload.error.filesize',
                      );
                    }
                  }
                  if (error.message === 'The document already exists') {
                    this.alertService.clearAlerts(-1);
                    this.showDocumentActionPopup = true;
                    this.existingFileName = item.name;
                    this.existingFiles.push(item);
                  } else {
                    const alertData = {
                      show: true,
                      type: 'error',
                      headerText: 'error!',
                      bodyText: errMsg ? errMsg : getErrorMessage(error),
                      wrapperStyle:
                        this.existingFiles.length === 0
                          ? { margin: '30px 0px 0px -75px' }
                          : {},
                    };
                    this.alertService.addAlert(alertData);
                    this.file = null;
                  }
                  fileUploadCounter === 0 &&
                    this.existingFiles.length !== 0 &&
                    this.handleExistingFilesUpload();
                  this.showModalSpinner = false;
                  this.clearFileInput();
                },
              });
          } else {
            this.showModalSpinner = false;
            fileUploadCounter -= 1;
            fileUploadCounter === 0 &&
              this.existingFiles.length !== 0 &&
              this.handleExistingFilesUpload();
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: 'policy.document.upload.error.fileExtension',
              wrapperStyle:
                this.existingFiles.length === 0
                  ? { margin: '30px 0px 0px -75px' }
                  : {},
            };
            this.alertService.addAlert(alertData);
          }
        } else {
          this.showModalSpinner = false;
          fileUploadCounter -= 1;
          fileUploadCounter === 0 &&
            this.existingFiles.length !== 0 &&
            this.handleExistingFilesUpload();
          const alertData = {
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: 'policy.document.upload.error.filesize',
            wrapperStyle:
              this.existingFiles.length === 0
                ? { margin: '30px 0px 0px -75px' }
                : {},
          };
          this.alertService.addAlert(alertData);
        }
      }
    } catch (error) {
      this.showModalSpinner = false;
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: getErrorMessage(error),
        wrapperStyle:
          this.existingFiles.length === 0
            ? { margin: '30px 0px 0px -75px' }
            : {},
      };
      this.alertService.addAlert(alertData);
    }
  }

  handleExistingFilesUpload() {
    const alertData = {
      show: true,
      type: 'error',
      headerText: getAlertHead('error'),
      bodyText: this.getExistingFilesUploadError(),
      wrapperStyle:
        this.existingFiles.length === 0 ? { margin: '30px 0px 0px -75px' } : {},
    };
    this.alertService.addAlert(alertData);
    this.checkAndGenerateUniqueFileName(this.existingFiles[0].name);
    this.existingFileName = this.existingFiles[0].name;
  }

  getExistingFilesUploadError(): string {
    let errorMsg: string = '';
    if (this.existingFiles.length > 1) {
      this.existingFiles.map((file, index) => {
        errorMsg = index === 0 ? `${file.name}` : `${errorMsg}, ${file.name}`;
      });
    } else {
      return `${this.existingFiles[0].name} ${this.translate.instant(
        'common.alreadyExists',
      )}`;
    }
    return `${errorMsg} ${this.translate.instant('common.alreadyExists')}`;
  }

  handleExistingFileUpload(file: any, isOverwrite: boolean = false) {
    this.isUploadingProgress = true;
    this.formData = new FormData();
    this.formData.append('file', file, file.name);
    this.formData.append('policyRiskId', this.policyRiskId.toString());
    if (isOverwrite) {
      this.formData.append('force', '1');
    }
    this.policyRiskDocUploadService
      .uploadPolicyRiskDoc(this.formData)
      .subscribe({
        next: async (response) => {
          await this.handleCloseExistingFilesUpload(false);
          const alertData = {
            show: true,
            type: 'success',
            headerText: getAlertHead('success'),
            bodyText: 'policy.document.upload.success.body',
            wrapperStyle:
              this.existingFiles.length === 0
                ? { margin: '30px 0px 0px -75px' }
                : {},
          };
          this.alertService.addAlert(alertData);
          this.existingFiles.length === 0 &&
            this.populateDocuments(this.currentPage);
        },
        error: async (error) => {
          let errMsg: string = '';
          if (typeof error === 'string') {
            if (
              error.includes(
                this.translate.instant(
                  'policy.document.upload.error.responseText',
                ),
              )
            ) {
              errMsg = this.translate.instant(
                'policy.document.upload.error.filesize',
              );
            }
          }
          const alertData = {
            show: true,
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: errMsg ? errMsg : getErrorMessage(error),
            wrapperStyle:
              this.existingFiles.length === 0
                ? { margin: '30px 0px 0px -75px' }
                : {},
          };
          this.alertService.addAlert(alertData);
          this.isUploadingProgress = false;
        },
      });
  }

  async handleCloseExistingFilesUpload(isUserAction: boolean = true) {
    this.existingFiles.splice(0, 1);
    isUserAction && this.alertService.clearAlerts(-1);
    this.isUploadingProgress = false;
    this.populateDocuments(this.currentPage);
    if (this.existingFiles.length > 0) {
      this.existingFileName = this.existingFiles[0].name;
      this.checkAndGenerateUniqueFileName(this.existingFiles[0].name);
    }
  }

  clearFileInput() {
    if (this.fileDropRef && this.fileDropRef.nativeElement)
      this.fileDropRef.nativeElement.value = '';
  }

  getFileExtension(file: File): string {
    const filename = file.name;
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1) {
      return '';
    }
    return filename.substr(lastDotIndex).toLowerCase();
  }

  isDisablePreview(fileName: string): boolean {
    const fileNameArrayLength = fileName.split('.')?.length;
    const lastElementIdx = fileNameArrayLength - 1;
    if (
      !['pdf', 'jpg', 'jpeg', 'gif', 'txt'].includes(
        (fileName.split('.')?.[lastElementIdx]).toLowerCase(),
      )
    ) {
      return true;
    }
    return false;
  }

  handleDocumentActionConfirm(event) {
    this.alertService.clearAlerts(-1);
    if (event.overwrite) {
      this.handleExistingFileUpload(this.existingFiles[0], true);
    } else {
      const newFile = this.renameFile(this.existingFiles[0], event.newFileName);
      this.handleExistingFileUpload(newFile);
    }
  }

  renameFile(file: any, newFileName: string) {
    if (file) {
      const fileExtension = file.name.split('.').pop() || '';
      const renamedFile = new File([file], newFileName + '.' + fileExtension, {
        type: file.type,
        lastModified: file.lastModified,
      });
      return renamedFile;
    }
    return null;
  }

  generateNewFileName(fileName: string): string {
    const fileExtension = fileName.match(/\.\w+$/) || [''];
    const baseFileName = fileName.replace(/\.[^/.]+$/, '');
    const regex = /^(.*?)( \((\d+)\))?$/;
    const match = baseFileName.match(regex);

    if (match) {
      const baseName = match[1] || baseFileName;
      const currentNumber = match[3] ? parseInt(match[3], 10) : 0;
      const newFileName = `${baseName} (${currentNumber + 1})${
        fileExtension[0]
      }`;
      return newFileName;
    }

    return `${baseFileName} (1)${fileExtension[0]}`;
  }

  checkAndGenerateUniqueFileName(originalFileName: string) {
    const generatedFileName = this.generateNewFileName(originalFileName);
    this.isGettingSuggestionProgress = true;
    this.policyRiskDocService
      .getPolicyRiskDocCore(
        this.policyRiskId,
        this.docStageId,
        null,
        null,
        null,
        generatedFileName,
      )
      .subscribe((res) => {
        this.isGettingSuggestionProgress = false;
        if (res.data.length === 0) {
          this.suggestedFileName =
            this.removeFileNameExtension(generatedFileName);
          return;
        } else {
          return this.checkAndGenerateUniqueFileName(generatedFileName);
        }
      });
  }

  removeFileNameExtension(fileName: string) {
    return fileName.replace(/\.[^/.]+$/, '');
  }

  async loadPolicyDocumentTemplates() {
    this.alertService.clearAlerts(-1);
    if (this.policyRiskId) {
      await this.getPolicyStageId();
      if (this.stageIdPolicy) {
        this.documentTemplateService
          .getDocumentTemplateList(this.policyRiskId, this.stageIdPolicy)
          .subscribe({
            next: (response) => {
              let templateList = response?.data.filter((item) => {
                return item.templates.map(
                  (template) => template.docProcessId === null,
                );
              });
              if (templateList.length !== 0) {
                this.docPopupDetails.documentTemplate = templateList;
                this.showDocTempSelectionPopup = true;
                this.handleDocLevelAction('bind');
              } else {
                this.showDocTempSelectionPopup = false;
                this.regeneratePolicyDocument();
              }
            },
            error: (error) => {
              this.showDocumentGenerateErrorMsg(getErrorMessage(error));
            },
          });
      } else {
        this.showDocumentGenerateErrorMsg();
      }
    } else {
      this.showDocumentGenerateErrorMsg();
    }
  }

  async getPolicyStageId() {
    this.stageIdPolicy = undefined;
    let stageCode = 'DOCMERGESTAGE_BIND';
    this.showModalSpinner = true;
    return new Promise<void>((resolve, reject) => {
      this.domainsService.GetByDomainCode('DOCMERGESTAGE', true).subscribe({
        next: (response) => {
          let docStage = response.data.filter(
            (template) => template.subdomaincode === stageCode,
          )[0];
          this.stageIdPolicy = docStage.id;
          this.showModalSpinner = false;
          resolve();
        },
        error: (error) => {
          this.showModalSpinner = false;
          this.showDocumentGenerateErrorMsg(getErrorMessage(error));
          reject(getErrorMessage(error));
        },
      });
    });
  }
  showDocumentGenerateSuccessMsg() {
    if (this.closeModalClicked === false) {
      this.handleShowGenerateDocMsg.emit();
    }
  }

  showDocumentGenerateErrorMsg(
    msg: string = 'policy.document.error.notRegeneratedErrorMsg',
  ) {
    let notificationAlert = {
      type: 'error',
      headerText: getAlertHead('error'),
      bodyText: this.translate.instant(msg),
    };
    this.alertService.addAlert(notificationAlert);
  }

  // only policy status bound is handled now, need to setup subdomain code for cancel/renewed and other policy statuses
  showDocRegenerateButton(policyStatus): boolean {
    return policyStatus ? policyStatus.toLowerCase() === 'bound' : false;
  }

  async listPolicyDocuments(pageNumber) {
    if (!this.policyRiskId) {
      this.showTabLoader = false;
      return;
    }
    this.showModalSpinner = true;
    this.policyRiskDocService
      .getPolicyRiskDocCore(
        this.policyRiskId,
        null,
        this.documentTypeId,
        pageNumber,
        this.itemsPerPage,
      )
      .subscribe({
        next: (docData) => {
          ({ pagination: this.filesData.pagination } = docData);
          this.files = docData?.data
            .filter((doc) => doc.processingStatus === this.docStageId)
            .map((doc) => ({
              ...doc,
              id: doc.id,
              name: this.getFileName(doc.documentPath),
              path: doc.documentPath,
              dateCreated: formatDateMoment(
                doc?.createdDt,
                this.shortDateFormat,
              ),
              createdBy: doc.createdBy || '',
              dateUpdated: formatDateMoment(
                doc?.updatedDt,
                this.shortDateFormat,
              ),
              updatedBy: doc.updatedBy || '',
              description: doc?.documentDescription ?? '',
            }));
          this.totalPages = this.filesData?.pagination?.totalPages;
          this.currentPage = pageNumber;
          this.totalCount = this.filesData?.pagination?.totalRecords;
        },
        error: (error) => {
          this.showTabLoader = false;
          this.showModalSpinner = false;
          clearTimeout(this.newTimeout);
          if (![500].includes(error?.status)) {
            const alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
              wrapperStyle:
                this.existingFiles.length === 0
                  ? { margin: '30px 0px 0px -75px' }
                  : {},
            };
            this.alertService.addAlert(alertData);
          }
        },
      });
  }

  async handleSuccessDocTemplate(event) {
    this.showDocTempSelectionPopup = false;
    this.regeneratePolicyDocument();
  }

  handleDocLevelAction(action) {
    this.showBodySpinner = false;
    this.docPopupDetails.action = action;
    this.showDocTempSelectionPopup = true;
  }

  handleCloseDocumentPopup() {
    if (this.showBodySpinner) {
      return;
    }
    this.showDocTempSelectionPopup = false;
  }

  async regeneratePolicyDocument() {
    if (this.policyPeriodId) {
      await this.getPolicyStageId();
      if (this.stageIdPolicy) {
        this.showModalSpinner = true;
        const interval = 5000;
        const startTime = Date.now();
        return new Promise<any>(async (resolve, reject) => {
          this.documentTemplateService
            .generateStageDocument(this.policyPeriodId, this.stageIdPolicy)
            .subscribe({
              next: async (resp) => {
                this.loadDocApiCount = 0;
                // allowing maximum api call count as 5
                while (
                  Date.now() - startTime < DOC_GENERATION_WAIT_TIME &&
                  this.closeModalClicked === false &&
                  this.loadDocApiCount < 5
                ) {
                  await this.checkDocumentStatus();
                  await this.listPolicyDocuments(1);
                  await new Promise(
                    (resolve) =>
                      (this.newTimeout = setTimeout(resolve, interval)),
                  );
                }
                if (this.loadDocApiCount === 5) {
                  this.showTabLoader = false;
                  this.showModalSpinner = false;
                  this.showDocumentGenerateSuccessMsg();
                  resolve(true);
                }
                if (this.closeModalClicked === true) {
                  this.showTabLoader = false;
                  this.showModalSpinner = false;
                  resolve(true);
                }
              },
              error: (error) => {
                clearTimeout(this.newTimeout);
                this.showModalSpinner = false;
                this.showDocumentGenerateErrorMsg(getErrorMessage(error));
                reject(getErrorMessage(error));
              },
            });
        });
      } else {
        this.showDocumentGenerateErrorMsg();
      }
    } else {
      this.showDocumentGenerateErrorMsg();
    }
  }
  checkDocumentStatus() {
    return new Promise<void>((resolve, reject) => {
      if (this.policyPeriodId && this.stageIdPolicy) {
        this.policyRiskDocService
          .getPolicyRiskGeneratedDocCore(
            this.policyPeriodId,
            this.stageIdPolicy,
          )
          .subscribe({
            next: async (response) => {
              if (response?.data?.status === GENERATE_DOC_SUCCESS_STATUS_CODE) {
                this.loadDocApiCount = 5;
                resolve();
              } else {
                this.loadDocApiCount++;
                resolve();
              }
            },
            error: (error) => {
              this.loadDocApiCount = 5;
              clearTimeout(this.newTimeout);
              this.showModalSpinner = false;
              this.showDocumentGenerateErrorMsg(getErrorMessage(error));
              reject(getErrorMessage(error));
              return;
            },
          });
      } else {
        this.showDocumentGenerateErrorMsg();
        reject('error');
      }
    });
  }
}
