import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BoxxResponse } from '../entities/boxx-response';
import { SystemUser } from '../entities/system-user';
import { BaseService } from './base-services';
import { CognitoService } from './cognito-service';

@Injectable({
  providedIn: 'root',
})
export class SystemUserService extends BaseService<SystemUser> {
  constructor(
    http: HttpClient,
    private cognito: CognitoService,
  ) {
    const baseurl =
      environment.msSecurityBseUrl + environment.systmenUserPathUrl;
    const token = cognito.getToken();
    super(http, baseurl, token);
  }
  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  GetByLoginEmail(loginEmail): Observable<BoxxResponse<SystemUser>> {
    return this.http
      .get<BoxxResponse<SystemUser>>(
        this.baseurl + '?loginEmail=' + loginEmail,
        this.httpOptions,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  // GET ALL
  GetSystemUsers(
    page = 1,
    limit = 10,
    sort = '',
    systemUserType = null,
    name = '',
    active = '',
  ): Observable<BoxxResponse<SystemUser>> {
    let url =
      this.baseurl + '?page=' + page + '&limit=' + limit + '&sort=' + sort;
    if (systemUserType) {
      url += '&systemUserType=' + systemUserType;
    }
    if (active) {
      url += '&active=' + active;
    }
    url += '&name=' + name;
    return this.http
      .get<BoxxResponse<SystemUser>>(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  // POST
  CreateSystemUser(data): Observable<SystemUser> {
    let url = `${this.baseurl}`;
    return this.http.post<SystemUser>(url, data, this.httpOptions);
  }

  GetSystemUserProfile(
    systemUserType = null,
  ): Observable<BoxxResponse<SystemUser>> {
    let url = this.baseurl + '?systemUserType=' + systemUserType;
    return this.http
      .get<BoxxResponse<SystemUser>>(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetSystemUsersByRole(
    roleId,
    sort = '',
    page = 1,
    limit = 10,
  ): Observable<BoxxResponse<SystemUser>> {
    let url =
      this.baseurl +
      '?page=' +
      page +
      '&roleId=' +
      roleId +
      '&limit=' +
      limit +
      '&sort=' +
      sort;
    return this.http
      .get<BoxxResponse<SystemUser>>(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  UpdateSystemUser(systemUserid, data): Observable<SystemUser> {
    let url = `${this.baseurl}${systemUserid}`;
    return this.http.put<SystemUser>(url, data, this.httpOptions);
  }

  GetSystemUser(systemUserId): Observable<BoxxResponse<SystemUser>> {
    let url = this.baseurl + systemUserId;
    return this.http
      .get<BoxxResponse<SystemUser>>(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  DeleteSystemUser(systemUserid): Observable<SystemUser> {
    let url = `${this.baseurl}${systemUserid}`;
    return this.http.delete<SystemUser>(url, this.httpOptions);
  }

  RestoreSystemUser(systemUserid, data = null): Observable<SystemUser> {
    let url = `${this.baseurl}restore/${systemUserid}`;
    return this.http.put<SystemUser>(url, data, this.httpOptions);
  }
}
