import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-commission-confirmation',
  templateUrl: './modal-commissionConfirmation.component.html',
  styleUrls: ['./modal-commissionConfirmation.component.less'],
})
export class ModalCommissionConfirmationComponent {
  @Input() showModal: boolean = false;
  @Input() commissionData;

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleSubmitCommissionOverride = new EventEmitter<any>();

  constructor() {}

  closeHandler() {
    this.handleClose.emit();
  }

  actionConfirmNoOverride(event) {
    this.commissionData.override = false;
    this.handleSubmitCommissionOverride.emit(this.commissionData);
  }
  actionConfirmOverride(event) {
    this.commissionData.override = true;
    this.handleSubmitCommissionOverride.emit(this.commissionData);
  }
}
