import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  formatDateMoment,
  getFormattedDateTime,
} from 'src/app/utils/formatDate';
import { Pagination } from 'src/app/entities/boxx-response';
import { PolicyDashboardService } from 'src/app/services/policy-dashboard.service';
import {
  getAlertHead,
  getErrorMessage,
  getStatusMappingForStyling,
  makeSorting,
} from 'src/app/utils/utils';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { Store, select } from '@ngrx/store';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { getFormattedPolicyPeriod } from 'src/app/utils/formatDate';
import { QuoteStatus, isStatusDisabled } from 'src/app/constants/quoteStatus';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-search-result-v2',
  templateUrl: './search-result-v2.component.html',
  styleUrls: ['./search-result-v2.component.less'],
})
export class SearchResultV2Component implements OnInit {
  data = [];
  tableData = [];
  totalDataCount = 0;
  searchKey: string;
  insuredType: string;
  insuredId: number;
  insuredNavigationUrl = '';
  searchType: string;
  pagination: Pagination;
  notificationAlert: {
    show?: boolean;
    bodyText?: string;
    type?: string;
    headerText?: string;
  } = { show: false };
  showBodySpinner: boolean = false;
  showTblSpinner: boolean = false;
  colHeader = {
    'ID number': [{ key: 'policyId', order: '' }],
    'Policy period': [{ key: 'effectiveDt', order: '' }],
    Brokerage: [{ key: 'policy.brokerageBOR.name', order: '' }],
    Status: [{ key: 'policy.PolicyStatus.description', order: '' }],
  };

  addSortTo: string[] = ['ID number', 'Policy period', 'Brokerage', 'Status'];
  sortingKeys = 'policyId:desc';
  pageNumber: number = 1;
  // TODO - modify value of statusMappingToType after API integration
  statusMappingToType = getStatusMappingForStyling();
  quoteFlowSteps;
  quoteFlowUrl = '';
  shortDateFormat: string = '';
  longDateFormat: string = '';
  mockExternalAPIs = 'true';
  isRestrictedRoleSubmission: boolean = false;

  constructor(
    activatedRoute: ActivatedRoute,
    public policyDashboardService: PolicyDashboardService,
    private router: Router,
    private store: Store,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    private alertService: AlertService,
  ) {
    activatedRoute.params.subscribe((params) => {
      this.insuredType = params['type'] == 1 ? 'individual' : 'company';
      this.searchKey = params['key'];
      this.insuredId = params['id'];
    });
    this.loadData(this.insuredId, 1, 10, '');
  }

  ngOnInit(): void {
    if (
      this.localStorageService.getMockExternalAPIs() === 'false' ||
      environment.mockExternalAPIs === false
    ) {
      this.mockExternalAPIs = 'false';
    } else {
      this.mockExternalAPIs = 'true';
    }
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
      this.isRestrictedRoleSubmission = data.isRestrictedRoleSubmission;
    });
    this.quoteFlowSteps = this.localStorageService.getQuoteFlowSteps();
    if (this.quoteFlowSteps === '7') {
      this.quoteFlowUrl = 'quote';
    } else if (this.quoteFlowSteps === '4') {
      this.quoteFlowUrl = 'quote4flow';
    } else {
      this.quoteFlowUrl = 'workflow3';
    }

    if (this.insuredId) {
      if (this.insuredType === 'individual') {
        this.insuredNavigationUrl = `/dashboard/workflow3/insured/form/${this.insuredId}/0`;
      } else {
        this.insuredNavigationUrl = `/dashboard/workflow3/insured/form/${this.insuredId}`;
      }
    }
  }

  loadData(insuredId, page, limit, sort) {
    this.showTblSpinner = true;
    this.policyDashboardService
      .GetAllByInsuredId(insuredId, page, limit, sort)
      .subscribe({
        next: (data) => {
          this.showTblSpinner = false;
          const tableData = data.data?.map((dataObj) => ({
            'ID number': dataObj.policy.pkgPolicyNumber.toString(),
            'Policy period': dataObj?.policyPeriod?.effectiveExpiryDatesTBDFlag
              ? 'TBD'
              : getFormattedPolicyPeriod(
                  dataObj?.policyPeriod,
                  this.shortDateFormat,
                ),
            Brokerage: dataObj.policy.brokerageBOR.name,
            Product: dataObj.policyRiskTrxes.policyRisk[0]?.risk || '',
            Limit: dataObj.policyRiskTrxes.policyRisk[0]?.limit || '',
            'Date created': getFormattedDateTime(
              dataObj?.policy?.createdDt,
              this.shortDateFormat,
            ),
            Status: dataObj?.policy?.PolicyStatus?.description?.toLowerCase(),
          }));
          this.data = data.data;
          this.tableData = tableData;
          this.totalDataCount = data.pagination.totalRecords; //Need total records founf from api. for now, hardcoding by * 10
          this.pagination = data.pagination;
          this.pageNumber = page;
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.notificationAlert = {
              show: true,
              type: 'error',
              headerText: 'error!',
              bodyText: getErrorMessage(error),
            };
          }
          this.showTblSpinner = false;
        },
      });
  }

  handlePageChange(pageNumber) {
    const insuredName = this.searchKey;
    if (pageNumber > 0) {
      this.loadData(this.insuredId, pageNumber, 10, this.sortingKeys);
    }
  }

  handleRowClick(e) {
    if (this.isRestrictedRoleSubmission === true) {
      this.alertService.clearAlerts(-1);
      const alertData = {
        type: 'warn',
        headerText: getAlertHead('warning'),
        bodyText: this.translateService.instant(
          'common.error.noAccessToPageErrorMsg',
        ),
      };
      this.alertService.addAlert(alertData);
      return;
    }
    this.showBodySpinner = true;
    if (!Number(this.tableData[e]['ID number'])) {
      this.router.navigateByUrl(
        `dashboard/policy/${this.tableData[e]['ID number']}`,
        {
          skipLocationChange: true,
        },
      );
      this.showBodySpinner = false;
    } else {
      const quoteStatus = this.data[e].policyRiskTrxes.quoteStatus.description;
      const quoteID = this.data[e]?.policy?.id;
      const policyPeriodId = this.data[e]?.policyPeriod?.id;
      this.store.dispatch(
        new CreateQuoteAction.setPolicyPeriodId(policyPeriodId),
      );
      let brokerageCommission =
        this.data[e].policyRiskTrxes?.['policyRisk'][0]?.brokerCommissionPerc ??
        0;
      brokerageCommission = brokerageCommission * 100;

      if (quoteStatus.toLowerCase() == QuoteStatus.Submission.toLowerCase()) {
        this.router.navigateByUrl(
          `/dashboard/${this.quoteFlowUrl}/new/product/form/${quoteID}`,
          {
            skipLocationChange: true,
          },
        );
        this.showBodySpinner = false;
      } else if (isStatusDisabled(quoteStatus)) {
        //Checking whether the quote status included in disabled statuses,

        this.router.navigateByUrl(`dashboard/${this.quoteFlowUrl}/${quoteID}`, {
          skipLocationChange: true,
        });
        this.showBodySpinner = false;
      } else if (brokerageCommission === 0) {
        //Checking whether the commission is zero or not,
        this.router.navigate(
          [`/dashboard/${this.quoteFlowUrl}/new/product/form/${quoteID}`],
          {
            skipLocationChange: true,
          },
        );
        this.showBodySpinner = false;
      } else {
        this.router.navigateByUrl(`dashboard/${this.quoteFlowUrl}/${quoteID}`, {
          skipLocationChange: true,
        });
        this.showBodySpinner = false;
      }
    }
  }

  handleTableColSortUp(e) {
    let order = 'asc';
    this.sortingKeys = makeSorting(e, this.colHeader, order);
    this.sortingKeys = `${this.sortingKeys},id:asc`;
    this.loadData(this.insuredId, this.pageNumber, 10, this.sortingKeys);
  }
  handleTableColSortDown(e) {
    let order = 'desc';
    this.sortingKeys = makeSorting(e, this.colHeader, order);
    this.sortingKeys = `${this.sortingKeys},id:asc`;
    this.loadData(this.insuredId, this.pageNumber, 10, this.sortingKeys);
  }
  handleNotificationAlertClose() {
    this.notificationAlert.show = false;
  }
}
