import { Injectable } from '@angular/core';
import { ICommission } from 'src/app/models/commission.model';

@Injectable({
  providedIn: 'root',
})
export class CommissionService {
  public commissionList: ICommission[] = [];

  public updateCommissionList = (list: ICommission[]): void => {
    this.commissionList = list;
  };
}
