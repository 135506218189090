import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs';
import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import { getLifecycleState } from 'src/app/store/lifecycle/lifecycle.select';

@Component({
  selector: 'app-quote-options',
  templateUrl: './quote-options.component.html',
  styleUrls: ['./quote-options.component.less'],
})
export class QuoteOptionsComponent implements OnInit {
  @Input() quote;
  @Input() idxOfQuote: number;
  @Input() domainIdForReferral: number;
  @Input() selectedQuoteOptionId: number;
  @Input() toggle: any;
  @Input() product;

  @Output() edit = new EventEmitter();
  @Output() quoteDelete = new EventEmitter();
  @Output() duplicateClick = new EventEmitter();
  @Output() quoteSelected = new EventEmitter();
  @Output() openReasonModalPopup = new EventEmitter();
  @Output() reasonModalClosePopup = new EventEmitter();
  @Output() handleSublimitClick = new EventEmitter();

  currency = '';
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  currentQuoteStatus: string = '';
  sublimitIconSrc =
    'assets/img/dashboard/quote/lifecycle/arrow-right-circle.png';

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
    });
    this.store.pipe(select(getLifecycleState)).subscribe((data) => {
      this.currentQuoteStatus = data.quoteStatus;
    });

    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));
  }

  changeIconSrc(iconPath: string) {
    this.sublimitIconSrc = iconPath;
  }

  handleEdit(quoteData, quoteIndex) {
    this.edit.emit({ quoteData: quoteData, quoteIndex: quoteIndex });
  }

  isNumber(number) {
    return !isNaN(number);
  }

  handleQuoteDelete(index, quote) {
    this.quoteDelete.emit({ index: index, quote: quote });
  }

  handleDuplicateClick(optionNumber, index) {
    this.duplicateClick.emit({
      optionNumber: optionNumber,
      index: index,
    });
  }

  handleQuoteSelect(optionNumber, quote) {
    this.quoteSelected.emit({ optionNumber: optionNumber, quote: quote });
  }

  handleOpenReasonModalPopup(quoteOption) {
    this.openReasonModalPopup.emit(quoteOption);
  }

  handleReasonModalClosePopup(event) {
    this.reasonModalClosePopup.emit(event);
  }
  handleSublimitAction(optionNumber, product) {
    this.sublimitIconSrc =
      'assets/img/dashboard/quote/lifecycle/arrow-right-circle-clicked.png';
    this.handleSublimitClick.emit({
      optionNumber,
      product,
      quote: this.quote,
    });
  }
}
