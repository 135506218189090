<div class="holder">
  <boxxds-breadcrumb [items]="itemsMenu"></boxxds-breadcrumb>
  <boxxds-heading
    sz="5"
    [ngStyle]="{ marginBottom: '50px' }"
    [text]="'Adding system user' | translate"
  >
  </boxxds-heading>
  <boxxds-alert
    *ngIf="showErrorAlert"
    (handleCloseEvent)="handleCloseSuccessEventExt()"
    type="error"
    headerText="error!"
    [bodyText]="msgErrorAlert"
  >
  </boxxds-alert>
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!showBodySpinner">
    <boxxds-body
      sz="lg"
      [text]="'Select system user type' | translate"
      [customStyle]="{
        color: '#616162',
        textAlign: 'left',
        marginBottom: '8px',
        fontSize: '14px',
        marginTop: '50px'
      }"
    ></boxxds-body>
    <boxxds-dropdown
      [insideSearch]="false"
      [options]="systemUserTypes"
      placeholder=""
      (currentValueChange)="systemUserTypeChanged($event)"
      [dropdownContainerStyle]="{ width: '176px' }"
      [currentValue]="currentSystemUserType"
    >
    </boxxds-dropdown>

    <app-new-system-boxx-user
      *ngIf="showBoxxUser"
      [systemUserType]="systemUserTypeId"
    ></app-new-system-boxx-user>
    <app-new-system-producer-user
      *ngIf="showProducer"
      [systemUserType]="systemUserTypeId"
    ></app-new-system-producer-user>
    <app-new-system-insured-user
      *ngIf="showInsured"
      [systemUserType]="systemUserTypeId"
    ></app-new-system-insured-user>
  </ng-container>
</div>
