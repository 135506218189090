<div class="login-container">
  <div class="login-card">
    <div class="login-info-panel">
      <div class="login-header">
        <boxxds-heading
          [sz]="'5'"
          [text]="'Welcome back'"
          type="bold"
        ></boxxds-heading>
        <boxxds-body [text]="'Please enter details below'"></boxxds-body>
      </div>
      <form [formGroup]="loginForm" (ngSubmit)="handleSubmit($event)">
        <boxxds-textinput
          [form]="loginForm"
          [placeholder]="'Enter your email'"
          [label]="'Email'"
          [control]="'email'"
          [id]="'email'"
          [containerStyle]="{ marginBottom: '8px', marginTop: '16px' }"
        >
        </boxxds-textinput>
        <boxxds-textinput
          [form]="loginForm"
          [type]="'password'"
          [placeholder]="'Enter your password'"
          [label]="'Password'"
          [control]="'password'"
          [toggleVisibility]="true"
          [id]="'password'"
          [containerStyle]="{ marginBottom: '8px', marginTop: '16px' }"
        >
        </boxxds-textinput>
        <div class="forgot-pas-container">
          <a class="forgot-password" href="/login/reset" tabindex="0"
            >Forgot password?</a
          >
        </div>
        <div class="button-container">
          <boxxds-button
            [buttonText]="'Sign in'"
            [type]="'submit'"
            [isDisabled]="isSignInBtnDisabled()"
          >
          </boxxds-button>
        </div>
      </form>
    </div>
    <div class="login-logo-panel">
      <div class="logo">
        <img src="assets/img/boxx.png" alt="boxx logo" />
      </div>
    </div>
  </div>
</div>
