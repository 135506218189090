<div class="holder">
  <boxxds-breadcrumb [items]="itemsMenu"></boxxds-breadcrumb>
  <boxxds-heading sz="5" [text]="'brokerage.producer.newProducer' | translate">
  </boxxds-heading>

  <div class="alert-holder">
    <boxxds-alert
      *ngFor="let i of alertList; let x = index"
      (handleCloseEvent)="removeAlert(x)"
      [type]="i.type"
      [headerText]="i.headerText"
      [bodyText]="i.bodyText"
    >
    </boxxds-alert>
  </div>

  <form [formGroup]="form" (ngSubmit)="handleSubmit($event)">
    <div class="form-panel-title">
      <boxxds-body
        sz="lg"
        [text]="'brokerage.producer.producerDetails' | translate"
        [customStyle]="{ color: '#BDBDBF', textAlign: 'left' }"
      >
      </boxxds-body>

      <boxxds-body
        sz="xs"
        [text]="'*' + ('common.required' | translate)"
        [customStyle]="{
          color: '#BDBDBF',
          textAlign: 'right',
          textTransform: 'uppercase'
        }"
      >
      </boxxds-body>
    </div>

    <div class="form-panel">
      <boxxds-textinput
        [form]="form"
        [placeholder]="
          'brokerage.producer.placeholder.enterFirstName' | translate
        "
        [control]="'firstName'"
        [id]="'first-name'"
        [width]="'100%'"
        [label]="('brokerage.producer.label.firstName' | translate) + '*'"
        [isRequired]="true"
        [requiredErrMsg]="'brokerage.producer.error.requiredErrMsg' | translate"
        [invalidErrMsg]="'brokerage.producer.error.invalidErrMsg' | translate"
        [formSubmitted]="submitted"
      >
      </boxxds-textinput>

      <boxxds-textinput
        [form]="form"
        [placeholder]="
          'brokerage.producer.placeholder.enterLastName' | translate
        "
        [control]="'lastName'"
        [id]="'last-name'"
        [width]="'100%'"
        [label]="('brokerage.producer.label.lastName' | translate) + '*'"
        [isRequired]="true"
        [requiredErrMsg]="'brokerage.producer.error.requiredErrMsg' | translate"
        [invalidErrMsg]="'brokerage.producer.error.invalidErrMsg' | translate"
        [formSubmitted]="submitted"
      >
      </boxxds-textinput>

      <boxxds-textinput
        [form]="form"
        [placeholder]="
          'brokerage.producer.placeholder.enterEmailAddress' | translate
        "
        [control]="'email'"
        [id]="'email'"
        [width]="'100%'"
        [label]="('brokerage.producer.label.email' | translate) + '*'"
        [requiredErrMsg]="'brokerage.producer.error.requiredErrMsg' | translate"
        [isRequired]="true"
        [invalidErrMsg]="
          'brokerage.producer.error.enterAValidEmail' | translate
        "
        [formSubmitted]="submitted"
      >
      </boxxds-textinput>

      <boxxds-textinput
        [form]="form"
        [mask]="'(000) 000-0000'"
        [placeholder]="'brokerage.producer.enterPhoneNumber' | translate"
        [control]="'phone'"
        [id]="'phoneNumber'"
        [width]="'100%'"
        [label]="('brokerage.producer.enterPhoneNumber' | translate) + '*'"
        [requiredErrMsg]="'brokerage.producer.error.requiredErrMsg' | translate"
        [isRequired]="true"
        [invalidErrMsg]="'brokerage.producer.error.invalidErrMsg' | translate"
        [formSubmitted]="submitted"
      >
      </boxxds-textinput>

      <boxxds-textinput
        [form]="form"
        [placeholder]="'brokerage.producer.placeholder.enterNumber' | translate"
        [control]="'licenseNumber'"
        [id]="'licenseNumber'"
        [width]="'100%'"
        [customErrMsg]="'brokerage.producer.error.noLicenseNumber' | translate"
        [label]="'brokerage.producer.label.licenseNumber' | translate"
        [invalidErrMsg]="
          'brokerage.producer.error.invalidLicenseNumber' | translate
        "
        [formSubmitted]="submitted"
      >
      </boxxds-textinput>

      <boxxds-datepicker
        [placeholder]="'brokerage.producer.placeholder.selectDate' | translate"
        [label]="'brokerage.producer.label.licenseExpiryDate' | translate"
        [bsValue]="form.value['licenseExpiry']"
        [dateFormat]="'MMM DD, YYYY'"
        [containerStyle]="{ width: '306px', marginTop: '-4px' }"
        [showErr]="errorExpiry().show"
        [errMsg]="errorExpiry().msg | translate"
        (bsValueChange)="handleExpiryDate($event)"
      >
      </boxxds-datepicker>

      <div class="item">
        <boxxds-body
          sz="md"
          [text]="'producerPhoto' | translate"
          [customStyle]="{
            color: '#2D2D39',
            textAlign: 'left',
            marginBottom: '8px'
          }"
          [ngStyle]="{ maxWidth: '179px' }"
        ></boxxds-body>
        <div class="d-flex">
          <img [src]="croppedImage" alt="Avatar" class="avatar" />

          <label class="fileContainer">
            <input
              #fileInput
              type="file"
              (click)="fileInput.value = ''"
              (change)="onImageSelected($event)"
              accept="image/*"
            />
            <img width="14" height="14" src="assets/img/upload.png" />
          </label>
        </div>
      </div>

      <dialog id="myDialog" #mi>
        <form method="dialog">
          <header>
            <h2>{{ "newProducer" | translate }}</h2>
          </header>
          <main>
            <image-cropper
              [resizeToWidth]="256"
              [cropperMinWidth]="128"
              [imageChangedEvent]="imageChangedEvent"
              [aspectRatio]="4 / 4"
              format="png"
              (imageCropped)="imageCropped($event)"
              (imageLoaded)="imageLoaded()"
              (loadImageFailed)="loadImageFailed()"
              *ngIf="imageChangedEvent"
            ></image-cropper>
          </main>
          <footer>
            <span class="profile-note">{{
              "common.profilePictureNote" | translate
            }}</span>
            <menu>
              <button
                id="dialog-footer--confirm-btn"
                class="dialog-submit-btn"
                (click)="selectedImage()"
              >
                {{ "common.confirm" | translate }}
              </button>
              <button
                id="close_dialog"
                class="dialog-close-btn"
                (click)="closeImageModal(); mi.close()"
              >
                {{ "common.close" | translate }}
              </button>
            </menu>
          </footer>
        </form>
      </dialog>
    </div>

    <div class="form-panel-footer">
      <boxxds-switch
        [checked]="checked"
        [disabled]="false"
        name="skip_notification"
        (changed)="toggleChanged($event)"
        [label]="'brokerage.producer.customCommissions' | translate"
      >
      </boxxds-switch>
    </div>

    <div class="footer-add-branch">
      <boxxds-button
        [buttonText]="'brokerage.producer.addProducer' | translate"
        [type]="'submitted'"
        btnType="primary-outlined"
        [btnStyle]="{ padding: '0 30px' }"
        (handleClick)="handleSubmit($event)"
      >
      </boxxds-button>
    </div>
  </form>
</div>
