import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateText',
})
export class TruncateTextPipe implements PipeTransform {
  transform(value: string, limit: number, alternateText: string = ''): string {
    if (!value || value.length <= limit) {
      return value;
    }

    let lastSpace = value.substr(0, limit).lastIndexOf(' ');
    if (limit - lastSpace >= 5) {
      lastSpace = limit;
    }
    return (
      value.substr(0, lastSpace > 0 ? lastSpace : limit) + ' ' + alternateText
    );
  }
}
