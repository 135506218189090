<div class="custom-modal">
  <div class="modal-content">
    <div class="preview-container">
      <button class="close-btn" aria-label="close" (click)="handleCloseClk()">
        <img src="assets/img/dashboard/card/close.png" />
      </button>
      <div class="preview" *ngIf="fileType === 'image'">
        <img [src]="url" />
      </div>
      <div class="preview" *ngIf="fileType === 'text'">
        <iframe [src]="url | safe" width="100%" height="100%"></iframe>
      </div>
    </div>
  </div>
</div>
