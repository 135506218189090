import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CognitoService } from '../services/cognito-service';
import { RolePermissionService } from 'src/app/services/role-permissions.service';
import { RoleService } from 'src/app/services/roles.service';
import { Role } from 'src/app/entities/role';
import { RolePermission } from 'src/app/entities/role-permission';
import { SystemUser } from '../entities/system-user';
import { BoxxUser } from '../entities/boxx-user';
import {
  SYSTEM_USER_TYPES,
  USER_ROLES_NAME,
  screensAndAssociatedRoutes,
} from '../constants/security/systemUserType';
import { SystemUserService } from '../services/system-user.service';
import * as DashboardActionTypes from '../store/dashboard/dashboard.action';
import { IDashboard } from '../models/dashboard.model';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { ViewportScroller } from '@angular/common';
import { MENU_SCREENS } from '../constants/security/systemUserType';
import { DomainsService } from '../services/domains.service';
import { LocalStorageService } from '../services/localstorage-service';
import {
  INSTANCE_CONFIG,
  INSTANCE_CONFIG_COUNTRY,
  INSTANCE_CONFIG_CURRENCY,
} from '../constants/config-constant';
import { ConfCurrencyService } from '../services/conf-currency.service';
import { environment } from 'src/environments/environment';
import {
  setQuoteStatus,
  setTransactionLvlQuoteStatus,
} from '../store/lifecycle/lifecycle.action';
import { UNDERWRITER_CLEARANCE } from '../constants/systemType';
import { alertDetails } from '../entities/common';
import { AlertService } from '../services/alert.service';
import { getDashboardSelector } from '../store/dashboard/dashboard.selector';
import { QuoteWorkflow3Component } from '../quote-workflow3/quote-workflow3.component';
import { QuoteLifecycleComponent } from './quote-lifecycle/quote-lifecycle.component';
import { QuotesGridV2Component } from '../quote-workflow3/components/quotes-grid-v2/quotes-grid-v2.component';
import { PolicyDirectoryComponent } from '../quote-workflow3/policy-directory/policy-directory.component';
import { NewQuoteService } from '../services/new-quote.service';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { InsuredWF3Component } from '../quote-workflow3/insured/insured.component';
import { InsuredDirectoryWF3Component } from '../quote-workflow3/insured/insured-directory/insured-directory.component';
import { BrokerageModuleComponent } from '../quote-workflow3/brokerage-module/brokerage-module.component';
import { BrokerageDirectoryWorkflow3Component } from '../quote-workflow3/brokerage-module/brokerage-directory/brokerage-directory-workflow3.component';
import { BranchProfileComponent } from '../quote-workflow3/brokerage-module/branch-profile/branch-profile.component';
import { BrokerageProfileComponent } from '../quote-workflow3/brokerage-module/brokerage-profile/brokerage-profile.component';
import { BranchProducerComponent } from '../quote-workflow3/brokerage-module/branch-profile/branch-producer/branch-producer.component';
import { SubmissionWorkflow3Component } from '../quote-workflow3/submission/submission.component';
import { MaintenanceService } from '../services/maintenance.service';
import { PolicyLifecycleV2Component } from '../quote-workflow3/policy-lifecycle-v2/policy-lifecycle-v2.component';
import { getAlertHead, isWithinNextHour } from '../utils/utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less'],
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  private alertSubscription: Subscription;
  form$: Observable<IDashboard>;
  sideMenuExpanded = true;
  infoTile = [
    { heading: 'Upcoming renewals', count: '24' },
    { heading: 'Open quotes', count: '6' },
  ];
  showLogoutPopup: boolean = false;

  role: Role;
  systemUser: SystemUser;
  boxxUser: BoxxUser;
  permissions: RolePermission[];
  boxxUserId: number;
  isStartNewQuoteMenuDisabled = false;
  isWorkflow3 = false;
  isQuotesGridV2 = false;
  isSubmissionFirstStep = false;
  isInsuredOrBrokerModule = false;

  menus: any = [];
  memberMenuObj = {
    src: 'assets/img/workflow3/sidemenu/members.svg',
    hoverSrc: 'assets/img/dashboard/sidemenu/hover/member.png',
    href: '/dashboard/workflow3/insured/directory',
    name: 'Member',
  };
  boxxUserMenuObj = {
    name: 'Boxx users',
    href: '/dashboard/boxx-user-directory',
  };
  brokerageMenuObj = { name: 'Brokerages', href: '/dashboard/brokerage' };
  brokerageMenuObjWf3 = {
    name: 'Brokerages',
    href: '/dashboard/workflow3/brokerage/directory',
  };
  insuredMenuObj = { name: 'Insured', href: '/dashboard/insured/directory' };
  insuredMenuObjWf3 = {
    name: 'Insured',
    href: '/dashboard/workflow3/insured/directory',
  };
  rolesPermissionsMenuObj = {
    name: 'Roles & permissions',
    href: '/dashboard/security/roles',
  };
  systemUsermenuObj = {
    name: 'System user',
    href: '/dashboard/system-user-directory',
  };
  configMenuObj = {
    src: 'assets/img/workflow3/sidemenu/config.svg',
    hoverSrc: 'assets/img/dashboard/sidemenu/hover/config.png',
    href: '#',
    name: 'Configurations',
  };
  quoteMenuObj = {
    src: 'assets/img/workflow3/sidemenu/quote.svg',
    name: 'Quotes',
    href: '/dashboard/workflow3/quotes',
    submenu: [
      {
        name: 'Start new quote',
        href: '/dashboard/workflow3',
        disabled: this.isStartNewQuoteMenuDisabled,
      },
      {
        name: 'New business',
        href: '/dashboard/workflow3/quotes',
      },
      {
        name: 'Closed',
        href: '/dashboard/workflow3/quotes',
      },
      {
        name: 'Declined',
        href: '/dashboard/workflow3/quotes',
      },
    ],
  };
  policiesMenuObj = {
    src: 'assets/img/workflow3/sidemenu/policy.svg',
    name: 'Policies',
    href: '/dashboard/workflow3/policies',
    submenu: [
      {
        name: 'New business',
        href: '/dashboard/workflow3/policies',
      },
      {
        name: 'Renewal',
        href: '/dashboard/workflow3/policies',
      },
      {
        name: 'Cancelled',
        href: '/dashboard/workflow3/policies',
      },
    ],
  };
  carriersMenuObj = { name: 'Carriers' };
  currencyMenuObj = { name: 'Currency/Exchange Rates' };
  domainsMenuObj = { name: 'Domains' };
  industryMenuObj = { name: 'Industry' };
  languageMenuObj = { name: 'Languages' };
  regionMenuObj = { name: 'Region' };
  taxesMenuObj = { name: 'Taxes' };
  productMenuObj = {
    src: 'assets/img/workflow3/sidemenu/products.svg',
    hoverSrc: 'assets/img/dashboard/sidemenu/hover/product.png',
    href: '#',
    name: 'Product',
  };
  pdtRiskMenuObj = { name: 'Product risk' };
  ratingsMenuObj = { name: 'Ratings' };
  claimsMenuObj = {
    src: 'assets/img/workflow3/sidemenu/claims.svg',
    hoverSrc: 'assets/img/dashboard/sidemenu/hover/claims.png',
    href: '#',
    name: 'Claims',
    notification: '0',
  };
  reportsMenuObj = {
    src: 'assets/img/workflow3/sidemenu/reports.svg',
    hoverSrc: 'assets/img/dashboard/sidemenu/hover/reports.png',
    href: '#',
    name: 'Reports',
  };
  alerts: alertDetails[] = [];
  longDateFormat: string = '';
  shortDateFormat: string = '';
  shortDateTimeFormat: string = '';
  longDateTimeFormat: string = '';
  quoteFlowSteps;
  workflow3Active: boolean = false;
  showWorkFlow3navigation = false;
  mockExternalAPIs = 'true';
  isRestrictedRoleSubmission: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cognitoService: CognitoService,
    private store: Store,
    public roleService: RoleService,
    public rolePermissionService: RolePermissionService,
    public systemUserService: SystemUserService,
    private viewPortScroller: ViewportScroller,
    private domainsService: DomainsService,
    private localStorageService: LocalStorageService,
    private confCurrencyService: ConfCurrencyService,
    private alertService: AlertService,
    private newQuoteService: NewQuoteService,
    private maintenanceService: MaintenanceService,
    private translateService: TranslateService,
  ) {
    if (
      this.activatedRoute?.children[0]?.firstChild?.firstChild?.component ===
      SubmissionWorkflow3Component
    ) {
      this.isSubmissionFirstStep = true;
    }
    if (
      ((this.activatedRoute?.children[0]?.firstChild?.component ===
        QuoteWorkflow3Component ||
        this.activatedRoute?.children[0]?.component ===
          QuotesGridV2Component) &&
        this.activatedRoute?.children[0]?.firstChild?.firstChild?.component !==
          QuoteLifecycleComponent) ||
      this.activatedRoute?.children[0]?.component ===
        PolicyDirectoryComponent ||
      this.activatedRoute?.children[0]?.component === BranchProfileComponent ||
      this.activatedRoute?.children[0]?.component === PolicyLifecycleV2Component
    ) {
      this.isWorkflow3 = true;
    }
    if (
      this.activatedRoute?.children[0]?.firstChild?.children[0]?.children[0]
        ?.component === InsuredDirectoryWF3Component ||
      this.activatedRoute?.children[0]?.component === QuotesGridV2Component ||
      this.activatedRoute?.children[0]?.component ===
        PolicyDirectoryComponent ||
      this.activatedRoute?.children[0]?.firstChild?.children[0]?.component ===
        BrokerageModuleComponent ||
      this.activatedRoute?.children[0]?.firstChild?.firstChild?.firstChild
        ?.component === BranchProfileComponent ||
      this.activatedRoute?.children[0]?.firstChild?.children[0]?.children[0]
        ?.component === BrokerageProfileComponent ||
      this.activatedRoute?.children[0]?.firstChild?.children[0]?.children[0]
        ?.component === BranchProducerComponent
    ) {
      this.showWorkFlow3navigation = true;
    } else {
      this.showWorkFlow3navigation = false;
    }
    if (this.activatedRoute?.children[0]?.component === QuotesGridV2Component) {
      this.isQuotesGridV2 = true;
      this.isStartNewQuoteMenuDisabled = false;
    } else if (
      this.activatedRoute?.children[0]?.component === PolicyDirectoryComponent
    ) {
      this.isQuotesGridV2 = true;
      this.isStartNewQuoteMenuDisabled = false;
    } else if (
      this.activatedRoute?.firstChild?.children[0]?.children[0]?.component ===
        InsuredWF3Component ||
      this.activatedRoute?.firstChild?.children[0]?.children[0]?.component ===
        BrokerageModuleComponent
    ) {
      this.isInsuredOrBrokerModule = true;
    } else {
      this.isQuotesGridV2 = false;
      this.isStartNewQuoteMenuDisabled = true;
      this.isInsuredOrBrokerModule = false;
    }
    this.alertSubscription = this.alertService
      .getAlerts()
      .subscribe((alerts) => {
        this.alerts = alerts;
      });
  }

  async ngOnInit(): Promise<void> {
    this.maintenanceService.isMaintenanceMode();
    if (
      this.localStorageService.getMockExternalAPIs() === 'false' ||
      environment.mockExternalAPIs === false
    ) {
      this.mockExternalAPIs = 'false';
    } else {
      this.mockExternalAPIs = 'true';
    }
    if (!this.isWorkflow3) {
      this.menus = [
        {
          src: 'assets/img/dashboard/sidemenu/profile.png',
          hoverSrc: 'assets/img/dashboard/sidemenu/hover/profile.png',
          href: 'dashboard',
          name: 'Profile',
        },
      ];
      this.configMenuObj.src = 'assets/img/dashboard/sidemenu/config.png';
      this.memberMenuObj.src = 'assets/img/dashboard/sidemenu/member.png';
      this.reportsMenuObj.src = 'assets/img/dashboard/sidemenu/reports.png';
    }
    this.boxxUserId = this.localStorageService.getBoxxUserId();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.quoteFlowSteps = this.localStorageService.getQuoteFlowSteps();
    if (this.quoteFlowSteps === 'workflow3') {
      this.workflow3Active = true;
    }

    this.getDefaultCurrencyCode();
    const systemUserId = localStorage.getItem('sid');
    this.systemUserService.Get(systemUserId).subscribe((data) => {
      this.systemUser = data.data;
      this.domainsService
        .GetByDomainCode('SYSTEMUSERTYPE')
        .subscribe(async (data) => {
          const domainData = data.data?.map((dataObj) => ({
            description: dataObj.description,
            id: dataObj.id,
          }));
          const getBoxxUserSysUsrId = domainData.filter(
            (type) =>
              type.description.toLowerCase() ==
              SYSTEM_USER_TYPES.BoxxUser.toLowerCase(),
          )[0]?.id;

          if (this.systemUser.SystemUserType.id === getBoxxUserSysUsrId) {
            this.boxxUser = this.systemUser.boxxUser;
            this.role = this.systemUser.role;
            this.quoteMenuObj.submenu[0].name = this.translateService.instant(
              'common.startNewQuote',
            );
            if (this.checkRestrictedRoleSubmission() === true) {
              // update store with submission flow restriction
              this.isRestrictedRoleSubmission = true;
              this.store.dispatch(
                new DashboardActionTypes.setRestrictedRoleSubmission(
                  this.isRestrictedRoleSubmission,
                ),
              );
              this.quoteMenuObj.submenu[0].name = this.translateService.instant(
                'common.startNewSubmission',
              );
            }
          }
          await this.getAndUpdatePermissions();
          this.store.dispatch(
            new DashboardActionTypes.saveUserTypes({
              userTypes: domainData,
            }),
          );
        });
    });

    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
      this.shortDateTimeFormat = data.shortDateTimeFormat;
      this.longDateTimeFormat = data.longDateTimeFormat;
    });

    if (this.shortDateFormat === '' || this.longDateFormat === '') {
      this.domainsService
        .GetByDomainCode('INSTANCECONFIG')
        .subscribe((domainList) => {
          domainList.data.map((domain: any) => {
            if (domain.subdomaincode === 'INSTANCECONFIG_SHORTDATEMASK') {
              this.shortDateFormat = domain?.valueString1 ?? '';
            }
            if (domain.subdomaincode === 'INSTANCECONFIG_LONGDATEMASK') {
              this.longDateFormat = domain?.valueString1 ?? '';
            }
            if (domain.subdomaincode === 'INSTANCECONFIG_SHORTDATETIMEMASK') {
              this.shortDateTimeFormat = domain?.valueString1 ?? '';
            }
            if (domain.subdomaincode === 'INSTANCECONFIG_LONGDATETIMEMASK') {
              this.longDateTimeFormat = domain?.valueString1 ?? '';
            }
          });
          this.store.dispatch(
            new DashboardActionTypes.updateDashboardConfigAction({
              shortDateFormat: this.shortDateFormat,
              longDateFormat: this.longDateFormat,
              shortDateTimeFormat: this.shortDateTimeFormat,
              longDateTimeFormat: this.longDateTimeFormat,
            }),
          );
        });
    }
  }

  ngOnDestroy(): void {
    if (this.alertSubscription) {
      this.alertSubscription.unsubscribe();
    }
    this.alertService.clearAlerts(-1);
  }

  showMaintenanceBanner() {
    return this.maintenanceService.getIsMaintenanceWithinNextHour();
  }

  async getAndUpdatePermissions() {
    this.rolePermissionService
      .GetAllPermissions(this.role.id, '', 1, 100)
      .subscribe(async (data) => {
        this.permissions = data.data;

        this.store.dispatch(
          new DashboardActionTypes.savePermissionsAction(this.permissions),
        );

        this.getMenus(this.permissions);
        await this.updatePermissionListAndCurrentScreen(this.permissions);
      });
  }

  getCrumbs() {
    if (this.activatedRoute?.children[0].component === QuotesGridV2Component) {
      return [
        { label: 'Quote Directory', path: '/dashboard/workflow3/quotes' },
      ];
    } else if (
      this.activatedRoute?.children[0].component === PolicyDirectoryComponent
    ) {
      return [
        {
          label: 'Policy Directory',
          path: '/dashboard/workflow3/policy-directory',
        },
      ];
    } else if (
      this.activatedRoute?.children[0]?.firstChild?.children[0].children[0]
        .component === BrokerageProfileComponent ||
      this.activatedRoute?.children[0]?.firstChild?.children[0]?.children[0]
        ?.component === BranchProducerComponent
    ) {
      return [
        {
          label: 'Brokerage Directory',
          path: '/dashboard/workflow3/brokerage/directory',
        },
      ];
    } else if (
      this.activatedRoute?.children[0]?.firstChild?.children[0]?.children[0]
        ?.component === InsuredDirectoryWF3Component
    ) {
      return [
        {
          label: 'Insured Directory',
          path: '/dashboard/workflow3/insured/directory',
        },
      ];
    } else {
      return [];
    }
  }

  async updatePermissionListAndCurrentScreen(
    permissions: { [key: string]: any }[],
  ) {
    let permissionList: { [x: string]: boolean } = {};
    if (permissions.length > 0) {
      permissions.map((permission) => {
        if (
          permission['permission'].Screen.description === MENU_SCREENS.Policy &&
          permission['permission'].permissionName.toLowerCase() ===
            UNDERWRITER_CLEARANCE
        ) {
          permissionList = {
            ...permissionList,
          };
        } else {
          permissionList = {
            ...permissionList,
            [permission['permission'].Screen.description]:
              permission['readWrite'],
          };
        }
      });
    }
    let currentScreen = await this.getCurrentScreen();
    this.store.dispatch(
      new DashboardActionTypes.updateDashboardConfigAction({
        litePermissionList: permissionList,
        currentScreenDescription: currentScreen,
        userRollId: this.role.id,
      }),
    );
    if (currentScreen !== 'Quote') {
      this.store.dispatch(new setQuoteStatus(''));
      this.store.dispatch(new setTransactionLvlQuoteStatus(''));
      localStorage.removeItem('timezone');
    }
  }

  async getCurrentScreen() {
    let url = this.router.url;
    let currentScreen: string = '';
    Object.entries(screensAndAssociatedRoutes).map((subUrl: any) => {
      if (url.startsWith(subUrl[0])) {
        currentScreen = subUrl[1];
      }
    });
    return currentScreen;
  }

  ngAfterViewInit(): void {
    this.viewPortScroller.scrollToPosition([0, 0]);
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload($event: any) {
    const idToken = this.cognitoService.getIDToken();
    if (this.showLogoutPopup || location.hostname === 'localhost' || !idToken) {
      return null;
    }
    const message =
      'Are you sure you want to leave this page? Any unsaved changes will be lost.';
    $event.returnValue = message;
    return message;
  }

  handleStartNewQuote() {
    this.store.dispatch(new CreateQuoteAction.ResetState());
    if (this.quoteFlowSteps === 'workflow3') {
      this.newQuoteService.clearInsuredId();
      this.router.navigate(['../../workflow3'], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
      return;
    } else {
      let quoteFlowUrl = 'workflow3';
      if (this.quoteFlowSteps === '7') {
        quoteFlowUrl = 'quote';
      } else if (this.quoteFlowSteps === '4') {
        quoteFlowUrl = 'quote4flow';
      } else {
        quoteFlowUrl = 'workflow3';
      }
      this.router.navigate([`../${quoteFlowUrl}/new`], {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      });
    }
  }

  getDefaultCurrencyCode() {
    this.domainsService.GetByDomainCode(INSTANCE_CONFIG, true).subscribe({
      next: (response) => {
        const country = response.data?.filter(
          (instanceConfig) =>
            instanceConfig?.subdomaincode === INSTANCE_CONFIG_COUNTRY,
        )[0]?.valueString1;
        const currencyId = response.data?.filter(
          (instanceConfig) =>
            instanceConfig?.subdomaincode === INSTANCE_CONFIG_CURRENCY,
        )[0]?.valueInt1;
        this.confCurrencyService.GetCurrencyById(currencyId).subscribe({
          next: (response) => {
            const currencyCode = response.data?.currencyCode;
            this.store.dispatch(
              new DashboardActionTypes.updateDashboardConfigAction({
                country,
                currency: currencyCode,
              }),
            );
          },
          error: (error) => {
            this.store.dispatch(
              new DashboardActionTypes.updateDashboardConfigAction({
                country,
                currency: environment.currency,
              }),
            );
          },
        });
      },
      error: (error) => {
        this.store.dispatch(
          new DashboardActionTypes.updateDashboardConfigAction({
            country: environment.country,
            currency: environment.currency,
          }),
        );
      },
    });
  }

  getMenus(permissions) {
    permissions.forEach((permission) => {
      switch (permission.permission.Screen.description) {
        case MENU_SCREENS.Quote:
          if (this.isWorkflow3) {
            this.menus = [...this.menus, { ...this.quoteMenuObj }];
          }
          return;
        case MENU_SCREENS.Policy:
          if (
            this.isWorkflow3 &&
            permission?.permission?.permissionName?.toLowerCase() !==
              'underwriter clearance'
          ) {
            this.menus = [...this.menus, { ...this.policiesMenuObj }];
          }
          return;
        case MENU_SCREENS.Claims:
          if (!this.checkIfParentMenuPresent('Claims')) {
            this.menus = [...this.menus, { ...this.claimsMenuObj }];
          }
          return;
        case MENU_SCREENS.Risk:
          if (this.checkIfParentMenuPresent('Product')) {
            let parentMenu = this.menus.filter((m) => m.name == 'Product');
            if (
              parentMenu[0].submenu.findIndex(
                (submenu) => submenu.name == 'Product risk',
              ) == -1
            ) {
              parentMenu[0].submenu = [
                ...parentMenu[0].submenu,
                this.pdtRiskMenuObj,
              ];
            }
          } else {
            this.menus = [
              ...this.menus,
              { ...this.productMenuObj, submenu: [{ ...this.pdtRiskMenuObj }] },
            ];
          }
          return;
        case MENU_SCREENS.BoxxUser:
          if (this.checkIfParentMenuPresent('Member')) {
            let parentMenu = this.menus.filter((m) => m.name == 'Member');
            if (
              parentMenu[0].submenu.findIndex(
                (submenu) => submenu.name == 'Boxx users',
              ) == -1
            ) {
              parentMenu[0].submenu = [
                ...parentMenu[0].submenu,
                this.boxxUserMenuObj,
              ];
            }
          } else {
            this.menus = [
              ...this.menus,
              { ...this.memberMenuObj, submenu: [{ ...this.boxxUserMenuObj }] },
            ];
          }
          return;
        case MENU_SCREENS.Brokerage_Directory:
          const isParentMenuPresent = this.checkIfParentMenuPresent('Member');
          if (isParentMenuPresent) {
            let parentMenu = this.menus.filter((m) => m.name == 'Member');
            if (
              parentMenu[0].submenu.findIndex(
                (submenu) => submenu.name == 'Brokerages',
              ) == -1
            ) {
              if (this.workflow3Active) {
                parentMenu[0].href = '/dashboard/workflow3/brokerage/directory';
              }
              parentMenu[0].submenu = [
                ...parentMenu[0].submenu,
                this.workflow3Active
                  ? this.brokerageMenuObjWf3
                  : this.brokerageMenuObj,
              ];
            }
          } else {
            this.menus = [
              ...this.menus,
              {
                ...this.memberMenuObj,
                submenu: [
                  this.workflow3Active
                    ? { ...this.brokerageMenuObjWf3 }
                    : { ...this.brokerageMenuObj },
                ],
              },
            ];
          }
          return;

        case MENU_SCREENS.Insured:
          const isParentPresent = this.checkIfParentMenuPresent('Member');
          if (isParentPresent) {
            let parentMenu = this.menus.filter((m) => m.name == 'Member');
            if (
              parentMenu[0].submenu.findIndex(
                (submenu) => submenu.name == 'Insured',
              ) == -1
            ) {
              if (this.workflow3Active) {
                parentMenu[0].href = '/dashboard/workflow3/insured/directory';
              }
              parentMenu[0].submenu = [
                ...parentMenu[0].submenu,
                this.workflow3Active
                  ? this.insuredMenuObjWf3
                  : this.insuredMenuObj,
              ];
            }
          } else {
            this.menus = [
              ...this.menus,
              {
                ...this.memberMenuObj,
                submenu: [
                  this.workflow3Active
                    ? { ...this.insuredMenuObjWf3 }
                    : { ...this.insuredMenuObj },
                ],
              },
            ];
          }
          return;
        case MENU_SCREENS.Role_Permissions:
          if (this.checkIfParentMenuPresent('Member')) {
            let parentMenu = this.menus.filter((m) => m.name == 'Member');
            if (
              parentMenu[0].submenu.findIndex(
                (submenu) => submenu.name == 'Roles & permissions',
              ) == -1
            ) {
              parentMenu[0].submenu = [
                ...parentMenu[0].submenu,
                this.rolesPermissionsMenuObj,
              ];
            }
          } else {
            this.menus = [
              ...this.menus,
              {
                ...this.memberMenuObj,
                submenu: [{ ...this.rolesPermissionsMenuObj }],
              },
            ];
          }
          break;
        case MENU_SCREENS.SystemUser:
          if (this.checkIfParentMenuPresent('Member')) {
            let parentMenu = this.menus.filter((m) => m.name == 'Member');
            if (
              parentMenu[0].submenu.findIndex(
                (submenu) => submenu.name == 'System user',
              ) == -1
            ) {
              parentMenu[0].submenu = [
                ...parentMenu[0].submenu,
                this.systemUsermenuObj,
              ];
            }
          } else {
            this.menus = [
              ...this.menus,
              {
                ...this.memberMenuObj,
                submenu: [{ ...this.systemUsermenuObj }],
              },
            ];
          }
          return;
        case MENU_SCREENS.Currency:
          if (this.checkIfParentMenuPresent('Configurations')) {
            let parentMenu = this.menus.filter(
              (m) => m.name == 'Configurations',
            );
            if (
              parentMenu[0].submenu.findIndex(
                (submenu) => submenu.name == 'Currency/Exchange Rates',
              ) == -1
            ) {
              parentMenu[0].submenu = [
                ...parentMenu[0].submenu,
                this.currencyMenuObj,
              ];
            }
          } else {
            this.menus = [
              ...this.menus,
              { ...this.configMenuObj, submenu: [{ ...this.currencyMenuObj }] },
            ];
          }
          return;
        case MENU_SCREENS.Domains:
          if (this.checkIfParentMenuPresent('Configurations')) {
            let parentMenu = this.menus.filter(
              (m) => m.name == 'Configurations',
            );
            if (
              parentMenu[0].submenu.findIndex(
                (submenu) => submenu.name == 'Domains',
              ) == -1
            ) {
              parentMenu[0].submenu = [
                ...parentMenu[0].submenu,
                this.domainsMenuObj,
              ];
            }
          } else {
            this.menus = [
              ...this.menus,
              { ...this.configMenuObj, submenu: [{ ...this.domainsMenuObj }] },
            ];
          }
          return;
        case MENU_SCREENS.Industry_Class:
          if (this.checkIfParentMenuPresent('Configurations')) {
            let parentMenu = this.menus.filter(
              (m) => m.name == 'Configurations',
            );
            if (
              parentMenu[0].submenu.findIndex(
                (submenu) => submenu.name == 'Industry',
              ) == -1
            ) {
              parentMenu[0].submenu = [
                ...parentMenu[0].submenu,
                this.industryMenuObj,
              ];
            }
          } else {
            this.menus = [
              ...this.menus,
              { ...this.configMenuObj, submenu: [{ ...this.industryMenuObj }] },
            ];
          }
          return;
        case MENU_SCREENS.Language:
          if (this.checkIfParentMenuPresent('Configurations')) {
            let parentMenu = this.menus.filter(
              (m) => m.name == 'Configurations',
            );
            if (
              parentMenu[0].submenu.findIndex(
                (submenu) => submenu.name == 'Languages',
              ) == -1
            ) {
              parentMenu[0].submenu = [
                ...parentMenu[0].submenu,
                this.languageMenuObj,
              ];
            }
          } else {
            this.menus = [
              ...this.menus,
              { ...this.configMenuObj, submenu: [{ ...this.languageMenuObj }] },
            ];
          }
          return;
        case MENU_SCREENS.Risk_Region:
          if (this.checkIfParentMenuPresent('Configurations')) {
            let parentMenu = this.menus.filter(
              (m) => m.name == 'Configurations',
            );
            if (
              parentMenu[0].submenu.findIndex(
                (submenu) => submenu.name == 'Region',
              ) == -1
            ) {
              parentMenu[0].submenu = [
                ...parentMenu[0].submenu,
                this.regionMenuObj,
              ];
            }
          } else {
            this.menus = [
              ...this.menus,
              { ...this.configMenuObj, submenu: [{ ...this.regionMenuObj }] },
            ];
          }
          return;
        case MENU_SCREENS.Rating:
          if (this.checkIfParentMenuPresent('Product')) {
            let parentMenu = this.menus.filter((m) => m.name == 'Product');
            if (
              parentMenu[0].submenu.findIndex(
                (submenu) => submenu.name == 'Ratings',
              ) == -1
            ) {
              parentMenu[0].submenu = [
                ...parentMenu[0].submenu,
                this.ratingsMenuObj,
              ];
            }
          } else {
            this.menus = [
              ...this.menus,
              { ...this.productMenuObj, submenu: [{ ...this.ratingsMenuObj }] },
            ];
          }
          return;
        default:
          return;
      }
    });
  }

  checkIfParentMenuPresent(parentMenuName) {
    const parentMenu = this.menus.findIndex((m) => m.name == parentMenuName);
    return parentMenu != -1;
  }

  handleSubmit(e: Event) {}

  handleSearch(object) {
    if (['Insured', 'Insured name'].includes(object.searchIn)) {
      let insuredType = 1;
      if (object?.type?.toLowerCase() == 'company') {
        insuredType = 2;
      }
      this.router.navigate(
        [
          `search/${insuredType}/${object.selectedSearch.value}/${object.selectedSearch.id}`,
        ],
        { relativeTo: this.activatedRoute, skipLocationChange: true },
      );
    } else {
      if (this.isRestrictedRoleSubmission === true) {
        this.alertService.clearAlerts(-1);
        const alertData = {
          type: 'warn',
          headerText: getAlertHead('warning'),
          bodyText: this.translateService.instant(
            'common.error.noAccessToPageErrorMsg',
          ),
        };
        this.alertService.addAlert(alertData);
        return;
      }
      if (this.quoteFlowSteps === 'workflow3') {
        this.router.navigateByUrl(
          `dashboard/workflow3/policies/${object.selectedSearch.value}`,
          { skipLocationChange: true },
        );
      } else {
        this.router.navigateByUrl(
          `dashboard/policy/${object.selectedSearch.value}`,
          { skipLocationChange: true },
        );
      }
    }
  }

  toggleLogoutPopup() {
    this.showLogoutPopup = !this.showLogoutPopup;
  }

  logoutClickHandler() {
    this.cognitoService.logout();
    this.router.navigateByUrl('/logout', { skipLocationChange: true });
  }

  cancelClickHandler() {
    this.showLogoutPopup = false;
  }
  handleAlertClose(index) {
    this.alertService.clearAlerts(index);
  }
  handleAlertLinkClickEvent(event: boolean, index) {
    const isNavigationStatus = event;
    if (isNavigationStatus) {
      this.store.dispatch(
        new DashboardActionTypes.updateDashboardConfigAction({
          isNavigatedFromAlert: isNavigationStatus,
        }),
      );
      this.alertService.clearAlerts(index);
    }
  }

  checkRestrictedRoleSubmission(): boolean {
    if (this.role) {
      let roleName = this.role?.name ? this.role?.name.toLowerCase() : '';
      if (
        roleName === USER_ROLES_NAME.UnderwriterAssistant ||
        roleName === USER_ROLES_NAME.UnderwriterAssistantShort ||
        roleName === USER_ROLES_NAME.TechinicalAssistant ||
        roleName === USER_ROLES_NAME.TechinicalAssistantShort
      )
        return true;
      else return false;
    } else return false;
  }
}
