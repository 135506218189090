import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs';
import { PolicyDetail } from 'src/app/quote-workflow3/policy-lifecycle-v2/lifecycle-v2-object';
import { addSpaceBeforeCapitalLetter } from 'src/app/dashboard/utils/lifecycle-utils';
import { Pagination } from 'src/app/entities/boxx-response';
import { DomainsService } from 'src/app/services/domains.service';
import { ModalAlertService } from 'src/app/services/modal-alert.service';
import { PolicyRiskDocService } from 'src/app/services/policy-risk-doc.service';
import { PolicyRiskTrxReasons } from 'src/app/services/policy-risk-trx-reasons.service';
import { PolicyRiskTrxService } from 'src/app/services/policy-risk-trx.service';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { getLifecycleState } from 'src/app/store/lifecycle/lifecycle.select';
import { formatDate } from 'src/app/utils/formatDate';
import {
  getAlertHead,
  getErrorMessage,
  getStatusBadgeClass,
} from 'src/app/utils/utils';

@Component({
  selector: 'app-quote-slideout-v2',
  templateUrl: './quote-slideout-v2.component.html',
  styleUrls: ['./quote-slideout-v2.component.less'],
})
export class QuoteSlideoutV2Component implements OnInit {
  @Input() details: PolicyDetail;
  @Input() isProductActive: boolean = false;

  @Output() handleLoading = new EventEmitter();

  sectionTitles: string[] = ['Quote documents', 'History'];
  policyRiskId: number;
  activityData: { pagination: Pagination | null } = {
    pagination: null,
  };

  activeSectionFilter: string = 'Quote documents';
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  shortDateFormat: string = '';
  longDateFormat: string = '';
  currentQuoteStatus: string = '';
  policyRiskTrxId: number;
  policyNumber: string;
  private insuredId: number;
  policyId: number;
  status: string;
  riskId: number;
  docStageId = undefined;
  files: any[] = [];
  riskTrxReasons: any;
  showDocPreview = false;
  showImagePreview = false;

  totalCount: number = 0;
  currentPage: number = 1;
  itemsPerPage = 10;
  totalPages: number = 1;
  isLoading: boolean = true;

  constructor(
    private store: Store,
    private alertService: ModalAlertService,
    private domainsService: DomainsService,
    private policyRiskDocService: PolicyRiskDocService,
    private PolicyRiskTrxReasons: PolicyRiskTrxReasons,
    private policyRiskTrxService: PolicyRiskTrxService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });

    this.currentPage = this.activityData?.pagination?.currentPage || 1;

    this.store
      .select(getLifecycleState)
      .pipe(take(1))
      .subscribe((state) => {
        this.currentQuoteStatus = state.quoteStatus;
        this.policyRiskTrxId = state?.policyRiskTrxId;
        this.status = state?.policyRiskTrxStatus;
        this.insuredId = state?.insuredId;
        this.riskId = state?.riskId;
      });

    this.policyRiskTrxId = this.policyRiskTrxId ?? this.details.policyRiskTrxId;
    await Promise.all([
      await this.populateFormDetails(),
      await this.populateReferralReasons(),
    ]);
  }

  private async populateFormDetails() {
    if (!this.policyRiskTrxId) {
      return;
    }
    this.policyRiskTrxService
      .getByPolicyRiskTrxId(this.policyRiskTrxId)
      .subscribe({
        next: (response) => {
          const data = response.data;
          this.policyId = data?.policyRisk?.policyPeriod?.policy?.id;
          const status = data?.quoteStatus ? data.quoteStatus : data.type;

          this.status = addSpaceBeforeCapitalLetter(status);
          this.policyRiskId = data?.policyRisk?.id;
          this.policyNumber = this.details.id;
        },
        error: (error) => {},
      });
  }

  getStatusType() {
    let statusType = getStatusBadgeClass(this.status);

    return statusType;
  }

  handleSectionFilter(section: string): void {
    this.alertService.clearAllAlerts();
    this.activeSectionFilter = section;
  }

  async populateReferralReasons() {
    return new Promise<any>(async (resolve, reject) => {
      if (!this.policyRiskTrxId) {
        return;
      }
      try {
        this.PolicyRiskTrxReasons.getPolicyRiskTrxReasons(
          this.policyRiskTrxId,
        ).subscribe({
          next: async (reasonsData) => {
            const quoteOptionReasons = reasonsData?.data;

            if (quoteOptionReasons?.length > 0) {
              this.riskTrxReasons = quoteOptionReasons?.map((reason) =>
                reason?.comments
                  ? reason?.comments
                  : reason?.reasonCodeDto?.description,
              );
            }
            resolve('true');
          },
          error: (error) => {
            reject(getErrorMessage(error));
          },
        });
      } catch (error) {}
    });
  }

  handleLoader(loaderEvent: any) {
    this.isLoading = loaderEvent.isLoading ? true : false;
    this.handleLoading.emit({ isLoading: this.isLoading });
  }
}
