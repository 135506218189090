import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BrokerageBranch } from 'src/app/entities/brokerage-branch';
import { getErrorMessage } from 'src/app/utils/utils';
import { BrokerageBranchService } from 'src/app/services/brokerage-branch.service';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';

@Component({
  selector: 'app-branch-table',
  templateUrl: './branch-table.component.html',
  styleUrls: ['./branch-table.component.less'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BranchTableComponent implements OnInit, OnChanges {
  @Input() brokerageId: number;
  @Input() isActive: boolean = false;
  @Input() reload: boolean = false;
  addSortTo: string | Array<string> = ['Name'];
  underWriterOptions = [{ key: 'Add one', value: 'Add_one' }];
  tableHeader = [{ Name: '', Contact: '', Location: '' }];
  data: Array<any> = [];
  private readonly userId: number;
  totalRecords = 0;
  pageNumber = 1;
  showErrorAlert = false;
  msgErrorAlert = '';
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};

  constructor(
    private brokerageBranchService: BrokerageBranchService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private store: Store,
  ) {
    this.userId = this.localStorageService.getBoxxUserId();
  }

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
    });

    const filter = { userId: this.userId, brokerageId: this.brokerageId };
    if (filter.brokerageId) this.getBranchList(filter);
  }

  private getBranchList(filter, sort = '') {
    this.brokerageBranchService
      .GetBrokerageBranchesByFilter(filter, sort)
      .subscribe({
        next: (response) => {
          this.formatData(response.data);
          this.totalRecords = response.pagination.totalRecords;
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.brokerageBranchService
      .GetBrokerageBranchesByFilter({
        userId: this.userId,
        brokerageId: this.brokerageId,
      })
      .subscribe({
        next: (response) => {
          this.formatData(response.data);
          this.totalRecords = response.pagination.totalRecords;
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
  }
  onHandlerClick() {
    if (!this.isActive) {
      return;
    }
    this.router.navigate(['/dashboard/branch/new/' + this.brokerageId], {
      skipLocationChange: true,
    });
  }

  handlePageChange(pageNumber) {
    this.pageNumber = pageNumber;
    this.brokerageBranchService
      .GetBrokerageBranchesByFilter({
        userId: this.userId,
        brokerageId: this.brokerageId,
        page: pageNumber,
      })
      .subscribe({
        next: (response) => {
          this.formatData(response.data);
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
  }

  formatData(items: BrokerageBranch[]) {
    this.data =
      items.length === 0
        ? this.tableHeader
        : items.map((item) => {
            let row = {
              Name: item.name,
              Contact: this.displayContactDetails(item),
              Location: item.location?.region?.name,
              Status: item.active === true ? 'Active' : 'Inactive',
              ActionView: `dashboard/branch/view/${item.id}/${item.brokerage.id}`,
            };

            if (!item.active) {
              row['recordStatus'] = 'INACTIVE';
            }
            return row;
          });
  }
  handleAscSort(key: string) {
    const filter = { userId: this.userId, brokerageId: this.brokerageId };
    this.getBranchList(filter, 'name:asc');
  }

  handleDescSort(key: string) {
    const filter = { userId: this.userId, brokerageId: this.brokerageId };
    this.getBranchList(filter, 'name:desc');
  }

  displayContactDetails(item: BrokerageBranch) {
    let email = item.email !== null ? item.email : '';
    let telephone = item.telephone !== null ? item.telephone : '';
    return ` ${email} <br> ${telephone} `;
  }
  handleCloseSuccessEvent() {
    this.showErrorAlert = false;
  }
}
