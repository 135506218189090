import moment from 'moment';
// var cityTimezones = require('city-timezones');

export const getYearName = (date: Date): string => {
  return moment(date).format('MMM');
};

export const getDayOfMonth = (date: Date): string => {
  return moment(date).format('D');
};

export const getYear = (date: Date): string => {
  return moment(date).format('Y');
};

export const getHours = (date: Date): string => {
  return moment(date).format('HH:mm A');
};
export const getDateFormatForDatePicker = (date: any, formatDate: string) => {
  // date format "2024-12-24T00:00:00.000Z"
  let newDate = date ? (date.includes('T') ? date.split('T')[0] : date) : '';
  return moment(newDate).format(formatDate);
};

export const getFormattedDateTime = (date: any, dateTimeFormat: string) => {
  // date format "2024-12-24T00:00:00.000Z"
  let newDate = date ? (date.includes('T') ? date.split('T')[0] : '') : '';
  return moment(newDate).format(dateTimeFormat);
};

export const getFormattedPolicyPeriod = (
  policyPeriod,
  dateTimeFormat: string = '',
) => {
  //Effective and Expiry date format "2024-12-24T00:00:00.000Z"
  const formattedEffectiveDt = getFormattedDateTime(
    policyPeriod.effectiveDt,
    dateTimeFormat,
  );
  const formattedExpiryDt = getFormattedDateTime(
    policyPeriod.expiryDt,
    dateTimeFormat,
  );

  return `${formattedEffectiveDt} - ${formattedExpiryDt}`;
};

export const getFormattedPeriod = (
  policyPeriod,
  oldFormat: string = '',
  newFormat: string = '',
) => {
  const formattedEffectiveDt = getFormatDate(
    policyPeriod.effectiveDt,
    oldFormat,
    newFormat,
  );
  const formattedExpiryDt = getFormatDate(
    policyPeriod.expiryDt,
    oldFormat,
    newFormat,
  );

  return `${formattedEffectiveDt} - ${formattedExpiryDt}`;
};

export const formatDate = (date: Date, dateTimeFormat: string): string => {
  const momentObj = moment.utc(date).local();
  if (momentObj.isValid()) {
    return momentObj.format(dateTimeFormat);
  }
  return '';
};

// convert DD/MM/YYYY date format to MMM DD, YYYY
export const convertDate = (dateString: Date, dateFormat: string): string => {
  const momentDate = moment.utc(dateString);
  if (momentDate.isValid()) {
    return momentDate.format(dateFormat);
  }
  return '';
};

export const getDate = (date: Date): string => {
  moment.locale('en-US');
  return moment(date).format('L');
};
export const getDateString = (
  dateString: string,
  dateFormat: string,
): string => {
  return moment(dateString, dateFormat).format('YYYY-MM-DD');
};
export const getFormatDate = (
  dateString: string,
  oldFormat: string,
  newFormat: string,
): string => {
  return moment(dateString, oldFormat).format(newFormat);
};
//remove default value on dateFormat
export const formatDateTime = (date: Date, dateFormat: string): string => {
  const momentObj = moment(
    moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss'),
  );
  if (momentObj.isValid()) {
    return momentObj.format(dateFormat);
  }
  return '';
};

export const dateTimeWithUserName = (
  user: string,
  date: string | Date,
  dateFormat: string,
) => {
  if (!user || !date || user === null || date === null) {
    return null;
  }
  const formatedDateAndName = `${user}, ${formatDateTime(
    new Date(date),
    dateFormat,
  )}`;
  return formatedDateAndName;
};

export const formatDateToDay = (
  dateVal: string,
  dateFormat: string,
): string => {
  let date = new Date(dateVal);
  let currentDate = moment().format('LL');
  let yesterDay = moment().subtract(1, 'days').format('LL');
  let label = '';

  if (currentDate === moment(date).format('LL')) {
    label = 'Today';
  } else if (yesterDay === moment(date).format('LL')) {
    label = 'Yesterday';
  } else {
    label = formatDate(date, dateFormat);
  }
  return label;
};

export const getFormattedDate = (timestamp: string): string => {
  const formattedDate: string = moment(timestamp).format('YYYY-MM-DD');
  return formattedDate;
};

export const getCityTime = (timeZone: string) => {
  let options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: timeZone,
  };
  const formatter = new Intl.DateTimeFormat('en-GB', options);
  const formatterNew = new Intl.DateTimeFormat('en-US', options);

  // Next day
  const targetDay = new Date();
  targetDay.setDate(targetDay.getDate() + 1);
  const currentDate = formatter.format(targetDay);

  // Add 1 year to currentDate
  const afterOneYear = targetDay;
  afterOneYear.setFullYear(afterOneYear.getFullYear() + 1);
  const oneYearLater = formatter.format(afterOneYear);

  // Subtract 30 days from startDate
  const beforeTargetDay = new Date();
  beforeTargetDay.setDate(beforeTargetDay.getDate() - 30);
  const thirtyDaysAg = formatterNew.format(beforeTargetDay);
  const thirtyDaysAgo = beforeTargetDay; //new Date(thirtyDaysAg);

  // Add 30 days to startDate
  const afterTargetDay = new Date();
  afterTargetDay.setDate(afterTargetDay.getDate() + 1);
  afterTargetDay.setDate(afterTargetDay.getDate() + 30);
  const thirtyDaysLate = formatterNew.format(afterTargetDay);
  const thirtyDaysLater = afterTargetDay; //new Date(thirtyDaysLate);

  // Add 8 months to startDate
  const targetDayAfter8 = new Date();
  targetDayAfter8.setDate(targetDayAfter8.getDate() + 1);
  targetDayAfter8.setMonth(targetDayAfter8.getMonth() + 8);
  const eightMonthsLate = formatter.format(targetDayAfter8);
  const eightMonthsLater = targetDayAfter8;

  //Date after 18 months
  const targetDayAfter18 = new Date();
  targetDayAfter18.setDate(targetDayAfter18.getDate() + 1);
  targetDayAfter18.setMonth(targetDayAfter18.getMonth() + 18);
  formatter.format(targetDayAfter18);
  const endDate = targetDayAfter18;

  return {
    currentDate,
    endDate,
    oneYearLater,
    thirtyDaysAgo,
    thirtyDaysLater,
    eightMonthsLater,
  };
};

export const formatDateMoment = (date: string, format: string) => {
  if (date) return moment(date).format(format);
  else return '';
};

export const convertToISODate = (inputDate) => {
  //inputDate format 'MMM DD, YYYY' eg: 'Dec 09, 2023'
  const outputFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
  const isoString = moment.utc(inputDate, 'MMM DD, YYYY').toISOString();
  const convertedDate = moment.utc(isoString).format(outputFormat);
  return convertedDate;
};

// add timestamp without changing the date
// input date format - DD/MM/YYYY output - YYYY-MM-DDTHH:mm:ss.sssZ
export function appendTimeStamp(dateString, dateFormat: string) {
  const date = getDateString(dateString, dateFormat);
  return date + 'T00:00:00.000Z';
}

export function calculateDateAfterMonths(
  startDate: Date,
  months: number,
  dateFormat: string,
  returnDateObject: boolean = false,
): string | Date {
  if (returnDateObject) {
    return moment(startDate).add(months, 'months').toDate();
  }
  return moment(startDate).add(months, 'months').format(dateFormat);
}

export function getDayBeforeSpecifiedDays(
  date: Date,
  numberOfDays: number,
  dateFormat: string,
) {
  const inputDate = moment(date);
  const resultDate = inputDate.subtract(numberOfDays, 'days');
  const formattedResult = resultDate.format(dateFormat);
  return formattedResult;
}

export function getDayAfterSpecifiedDays(
  date: Date,
  numberOfDays: number,
  dateFormat: string,
) {
  const inputDate = moment(date);
  const resultDate = inputDate.add(numberOfDays, 'days');
  const formattedResult = resultDate.format(dateFormat);
  return formattedResult;
}

export const getMonthAndYear = (date: Date): string => {
  const month = moment(date).format('MMMM');
  const year = moment(date).format('Y');
  return month + ' ' + year;
};
