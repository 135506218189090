import { Component, OnInit, Input } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import { RoleService } from 'src/app/services/roles.service';
import { SystemUserService } from 'src/app/services/system-user.service';
import { arrayToObjet } from 'src/app/utils/utils';
import { BoxxResponse } from 'src/app/entities/boxx-response';
import { Role } from 'src/app/entities/role';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { regexHelpers, getErrorMessage } from 'src/app/utils/utils';
import { getUserTypes } from 'src/app/store/dashboard/dashboard.selector';
import { Store, select } from '@ngrx/store';
import { SYSTEM_USER_TYPES } from 'src/app/constants/security/systemUserType';
@Component({
  selector: 'app-new-system-boxx-user',
  templateUrl: './new-system-boxx-user.component.html',
  styleUrls: ['./new-system-boxx-user.component.less'],
})
export class NewSystemBoxxUserComponent implements OnInit {
  @Input() systemUserType: string;

  users: Array<any> = [];

  roles: Array<any> = [];

  tUsers: any;
  tRoles: any;

  boxxUserForm = new FormGroup({
    boxxUserId: new FormControl('', [Validators.required]),
    roleId: new FormControl('', [Validators.required]),
    loginEmail: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(regexHelpers.MailId),
    ]),
    password: new FormControl(''),
    systemUserType: new FormControl(0),
    status: new FormControl('string'),
    apiUser: new FormControl(true),
  });

  requiredErrMsg: string = 'error.thisFieldIsRequired';
  invalidErrMsg: string = 'error.thisFieldIsInvalid';
  invalidUser: string = '';
  invalidRole: string = '';

  isSuccess = false;
  hasError = false;
  errorMessage = '';

  formSubmitted: boolean = false;

  boxxUserId = '';
  roleId = '';

  showSpinner: boolean = false;

  showErrorAlert = false;
  msgErrorAlert = '';
  userID: number;
  selectedSystemUserType: number = 0;

  constructor(
    private router: Router,
    private boxxUser: BoxxUserService,
    private roleService: RoleService,
    private systemUser: SystemUserService,
    private localStorageService: LocalStorageService,
    private store: Store,
  ) {}

  getValue(name) {
    return name;
  }

  ngOnInit(): void {
    this.userID = this.localStorageService.getBoxxUserId();
    this.store.pipe(select(getUserTypes)).subscribe(async (data) => {
      this.selectedSystemUserType = data.find(
        (item) =>
          this.toLowerWithoutSpace(item.description) ===
          this.toLowerWithoutSpace(SYSTEM_USER_TYPES.BoxxUser),
      )?.id;
    });
    this.boxxUserForm
      .get('systemUserType')
      .setValue(this.selectedSystemUserType);
  }

  toLowerWithoutSpace(input) {
    let result = input.replace(/[A-Z]/g, (match) => ' ' + match.toLowerCase());
    result = result.replace(/\s+/g, '');
    return result;
  }

  onSubmit(data) {
    this.handleCloseSuccessEvent();
    this.formSubmitted = true;
    this.hasError = false;

    // check boxx user form is invalid
    if (this.boxxUserForm.invalid) {
      return;
    }
    this.showSpinner = true;

    this.systemUser.CreateSystemUser(data).subscribe(
      (response) => {
        this.showSpinner = false;
        this.isSuccess = true;
        this.formSubmitted = false;
        this.resetBoxxUserForm();
      },
      ({ error }) => {
        this.showSpinner = false;
        this.hasError = true;
        this.errorMessage = error.message ? error.message : error.error;
      },
    );
  }

  handleCloseSuccessEvent() {
    this.isSuccess = false;
    this.hasError = false;
  }

  resetBoxxUserForm() {
    // this.boxxUserForm.reset();
    this.boxxUserForm.get('boxxUserId').setValue(null);
    this.boxxUserForm.get('roleId').setValue(null);
    this.boxxUserForm.get('loginEmail').setValue(null);
    this.boxxUserForm.get('password').setValue(null);

    this.boxxUserId = '';
    this.roleId = '';
  }

  searchUsers(name) {
    // reset form group's boxxUserId value
    this.boxxUserForm.get('boxxUserId').setValue(null);
    this.invalidUser = '';
    // if(name!=''){
    //   this.boxxUserForm.get('boxxUserId').setErrors({ pattern: true });
    //   this.invalidUser='systemUser.error.invalidBoxxUser';
    // }
    if (name.length >= 3) {
      let limit = 10,
        page = 1,
        sort = '';
      let active = 1;
      this.boxxUser
        .GetAllUserByName(name, page, limit, sort, active)
        .subscribe({
          next: (response) => {
            const tableData = response.data?.map((dataObj) => ({
              key: `${dataObj.firstName} ${dataObj.lastName}`,
              value: `${dataObj.firstName} ${dataObj.lastName}`,
            }));
            if (tableData.length > 0) {
              this.invalidUser = 'systemUser.error.invalidBoxxUser';
            } else {
              this.invalidUser = '';
            }
            this.users = [...tableData];
            this.tUsers = arrayToObjet(response.data, 'name');
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    } else {
      this.users = [];
    }
  }

  boxxUserValueChanged(name) {
    const user = this.tUsers[name];
    this.boxxUserId = `${user.firstName} ${user.lastName} `;
    this.invalidUser = '';
    this.boxxUserForm.get('boxxUserId').setValue(user.id);
  }

  boxxRoleValueChanged(event) {
    this.invalidRole = '';
    let id = this.roles.find((x) => x.value == event).id;
    const role = this.tRoles[id];
    this.roleId = `${role.name}`;
    this.boxxUserForm.get('roleId').setValue(role.id);
  }

  searchRoles(firstName) {
    // reset form group's boxxUserId value
    this.boxxUserForm.get('roleId').setValue(null);
    this.invalidRole = '';
    if (firstName.length >= 3) {
      this.roleService
        .GetByNameNsystemUserType(firstName, this.systemUserType)
        .subscribe({
          next: (response) => {
            this.setRoleSearchResult(response, firstName);
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    } else {
      this.roles = [];
    }
  }

  setRoleSearchResult(data: BoxxResponse<Role>, name = '') {
    const tableData = data.data?.map((dataObj) => ({
      key: `${dataObj.name}`,
      value: `${dataObj.name}`,
      id: dataObj.id,
    }));
    if (tableData.length > 0) {
      this.invalidRole = 'systemUser.error.invalidRole';
    } else {
      this.invalidRole = '';
    }
    this.roles = [...tableData];
    this.tRoles = arrayToObjet(data.data, 'id');
  }
  handleCloseSuccessEventExt() {
    this.showErrorAlert = false;
  }
}
