import { DatePipe, ViewportScroller } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CropperSettings, ImageCroppedEvent } from 'ngx-image-cropper';
import {
  BrokerageProducer,
  BrokerageProducerProfile,
} from 'src/app/entities/brokerage-producer';
import { AlertService } from 'src/app/services/alert.service';
import { BrokerageProducerService } from 'src/app/services/brokerage-producer.service';
import { SystemUserService } from 'src/app/services/system-user.service';
import { getAlertHead, getErrorMessage } from 'src/app/utils/utils';
import { getFormGroup, mapFormGroupToModel } from '../producer-profile.factory';
import { Store, select } from '@ngrx/store';
import {
  getDashboardSelector,
  getUserTypes,
} from 'src/app/store/dashboard/dashboard.selector';
import { SYSTEM_USER_TYPES } from 'src/app/constants/security/systemUserType';
import { RoleService } from 'src/app/services/roles.service';
import { Subscription } from 'rxjs';
import { ProducerModel } from 'src/app/models/producer.model';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  formatDateMoment,
  formatDateTime,
  getFormatDate,
} from 'src/app/utils/formatDate';
import { BrokerageBranchTeamsService } from 'src/app/services/brokerage-branch/brokerage-branch-teams.service';

@Component({
  selector: 'app-add-producer',
  templateUrl: './add-producer.component.html',
  styleUrls: ['./add-producer.component.less'],
})
export class AddProducerComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() requiredErrMsg: string;
  @Input() invalidErrMsg: string;
  @Input() formSubmitted: boolean = false;
  @Input() isActive: boolean;
  @Input() brokerageId: number;
  @Input() branchId: number;
  @Input() addProducer: boolean;

  @Output() handleDiscard = new EventEmitter();
  @Output() handleSaveAndAddProducer = new EventEmitter();
  @Output() handleSaveBranch = new EventEmitter();
  @Output() handleSaveChanges = new EventEmitter();

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  initialFormValue: any;
  private formSubscription: Subscription;
  producerId: number = undefined;
  producerSelected: BrokerageProducer;
  producerProfileOldSelected: BrokerageProducerProfile;
  hasSystemAccess: boolean = false;
  brokerageProducerOldSelected: BrokerageProducer;
  cropperSettings: CropperSettings;
  croppedImage: any = 'assets/img/default-photo.png';
  previousImage: string;
  allowedFormats = ['.jpg', '.jpeg', '.png', '.gif'];
  maxFileSizeMB = 2; // Maximum file size in MB
  imageChangedEvent: any = '';
  isFormDirty: boolean = false;
  showBodySpinner: boolean = false;
  formUpdated: boolean = false;
  showCropper = false;
  systemUserId: number;
  submitted: boolean = false;
  contentLoader: boolean = true;
  producerProfileId: number;
  customCommission: boolean = false;
  showSpinner = false;
  hasError: boolean = false;
  systemUserTypeDetails: { description: string; id: number }[] = [];
  rollId: number = undefined;

  userID: number;

  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  shortDateFormat: string = '';
  longDateFormat: string = '';
  alertData = {
    show: false,
    type: '',
    header: '',
    body: '',
  };
  producerSaveClicked: boolean = false;

  selectedTeamData: any;
  currentTeamValue = '';
  producerTeamOptions: Array<any> = [];
  producerTeamErrMsg = '';

  constructor(
    private store: Store,
    private datePipe: DatePipe,
    private alertService: AlertService,
    private translateService: TranslateService,
    private brokerageProducerService: BrokerageProducerService,
    private systemUserService: SystemUserService,
    private viewPortScroller: ViewportScroller,
    private roleService: RoleService,
    private localStorageService: LocalStorageService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public brokerageBranchTeamsService: BrokerageBranchTeamsService,
  ) {}

  ngOnInit(): void {
    this.userID = this.localStorageService.getBoxxUserId();
    this.form = getFormGroup(this.fb);
    this.initialProducerSelected();
    this.initProducerForm();
    this.brokerageProducerOldSelected = this.producerSelected;
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    this.store.pipe(select(getUserTypes)).subscribe((data) => {
      this.systemUserTypeDetails = data.filter(
        (systemUser) => systemUser.description === SYSTEM_USER_TYPES.Producer,
      );
    });

    this.roleService
      .GetByNameNsystemUserType(
        this.systemUserTypeDetails[0].description,
        this.systemUserTypeDetails[0].id,
      )
      .subscribe({
        next: (response) => {
          this.rollId = response.data?.[0]?.id ?? undefined;
        },
        error: (error) => {},
      });
  }

  errorExpiry() {
    let error = { show: false, msg: '' };
    if (this.producerSelected?.active) {
      let show = !this.form.controls['licenseExpiry'].valid;
      error.show = show;
      error.msg = show
        ? this.form.controls['licenseExpiry'].getError('date')
        : '';
    }
    return error;
  }

  handleExpiryDate(date: Date | undefined) {
    const formattedDate = date
      ? formatDateTime(date, this.shortDateFormat)
      : '';
    this.form.controls['licenseExpiry'].setValue(formattedDate);
    this.form.controls['licenseExpiry'].markAsDirty();
    this.form.controls['licenseNumber'].updateValueAndValidity();
  }

  handleSystemAccess() {
    this.hasSystemAccess = !this.hasSystemAccess;
  }

  isSystemAccessUpdated() {
    return (
      this.hasSystemAccess !==
      this.brokerageProducerOldSelected.producerSystemUser?.['active']
    );
  }

  onImageSelected(event: any): void {
    this.alertService.clearAlerts(-1);
    const file = event.target?.files[0];
    if (file) {
      const fileSizeMB = file.size / (1024 * 1024);
      if (fileSizeMB > this.maxFileSizeMB) {
        this.clearFileInput();
        const errorMsg = this.translateService.instant(
          'brokerage.producer.error.imageSizeError',
        );
        let alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: errorMsg,
        };
        this.alertService.addAlert(alertData);
        return;
      }

      const fileExtension = this.getFileExtension(file);
      if (!this.allowedFormats.includes(fileExtension.toLowerCase())) {
        this.clearFileInput();
        const errorMsg = this.translateService.instant(
          'workFlow3.brokerageProfile.message.imageFormatErrorV2',
        );
        let alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: errorMsg,
        };
        this.alertService.addAlert(alertData);
        return;
      }

      const reader = new FileReader();
      reader.onload = (fileEvent) => {
        this.imageChangedEvent = fileEvent.target?.result;
      };
      reader.readAsDataURL(file);
      this.imageChangedEvent = event;
      const favDialog = document.getElementById(
        'myDialog',
      ) as HTMLDialogElement;
      favDialog.showModal();
    }
  }

  closeImageModal() {
    if (!this.producerProfileOldSelected?.headShotImage)
      this.croppedImage = this.previousImage ?? 'assets/img/default-photo.png';
    else
      this.croppedImage = this.producerProfileOldSelected?.headShotImage
        ? this.previousImage
        : 'assets/img/default-photo.png';
    this.clearFileInput();
    this.isFormDirty = !this.formUpdated ? false : true;
  }

  selectedImage() {
    if (this.croppedImage) this.previousImage = this.croppedImage;
    if (
      this.producerProfileOldSelected?.headShotImage != this.croppedImage ||
      this.isFormDirty
    ) {
      this.isFormDirty = true;
    } else {
      this.isFormDirty = false;
    }
  }

  clearFileInput() {
    this.fileInput.nativeElement.value = null;
  }

  getFileExtension(file: File): string {
    const filename = file.name;
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1) {
      return '';
    }
    return filename.substr(lastDotIndex).toLowerCase();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.showCropper = true;
  }

  loadImageFailed() {}

  async handleProducerChangeStatus(e) {
    await this.handleSubmit(e);
    this.submitted = true;
    if (this.form.valid) {
      this.router.navigate(
        [
          `/dashboard/workflow3/brokerage/producer/profile/${this.producerId}/${this.branchId}/${this.brokerageId}/commission`,
        ],
        { relativeTo: this.activatedRoute, skipLocationChange: true },
      );
    }
  }

  updateSystemUserStatus(userDetails: any) {
    let systemUserId = userDetails.systemUserId;

    if (this.isSystemAccessUpdated()) {
      if (!this.hasSystemAccess) {
        this.systemUserService.DeleteSystemUser(systemUserId).subscribe({
          next: (response: any) => {
            let alertData = {
              type: 'success',
              headerText: getAlertHead('success'),
              bodyText: 'system user deactivated successfully',
            };
            this.alertService.addAlert(alertData);
          },
          error: (error: any) => {
            this.hasSystemAccess = true;
            let alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
          },
        });
      } else {
        this.systemUserService.RestoreSystemUser(systemUserId).subscribe({
          next: (response: any) => {
            let alertData = {
              type: 'success',
              headerText: getAlertHead('success'),
              bodyText: 'system user activated successfully',
            };
            this.alertService.addAlert(alertData);
          },
          error: (error: any) => {
            this.hasSystemAccess = false;
            let alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
          },
        });
      }
    }
  }

  createSystemUser(userDetails: any) {
    this.systemUserService.CreateSystemUser(userDetails).subscribe({
      next: (response) => {
        let alertData = {
          type: 'success',
          headerText: getAlertHead('success'),
          bodyText: 'New System User created',
        };
        this.alertService.addAlert(alertData);
      },
      error: (error) => {
        this.hasSystemAccess = false;
        let errorMessage = getErrorMessage(error);
        let alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: errorMessage,
        };
        this.alertService.addAlert(alertData);
      },
    });
  }

  async handleSubmit(e: any) {
    return new Promise<any>(async (resolve, reject) => {
      this.alertService.clearAlerts(-1);

      if (!!!this.isFormDirty) {
        resolve(false);
        return;
      }
      this.contentLoader = true;
      this.submitted = true;
      this.producerSaveClicked = true;
      let alertMessage = true;
      if (this.form.valid && this.form.enabled) {
        let spinnerCounter = 0;
        let imageUpdated = false;
        if (
          this.producerProfileOldSelected?.headShotImage != this.croppedImage
        ) {
          imageUpdated = true;
        }
        if (this.form.get('brokerageBranchTeamName').value?.trim() === '') {
          this.form.get('brokerageBranchTeamName').setValue('');
          this.form.get('brokerageBranchTeamId').setValue('');
        }
        const producerModel = mapFormGroupToModel(
          this.form,
          this.customCommission,
          this.croppedImage,
          this.branchId,
        );
        this.showSpinner = true;
        spinnerCounter++;
        this.brokerageProducerService.prodId$.subscribe((id) => {
          this.producerId = id;
        });
        if (this.producerId && !this.addProducer) {
          if (
            (imageUpdated && this.formUpdated) ||
            this.formUpdated ||
            this.hasError
          ) {
            alertMessage = false;
            this.brokerageProducerService
              .UpdateBrokerageProducer(this.producerId, producerModel)
              .subscribe({
                next: (response: any) => {
                  let updateDetails = response?.data ?? {};
                  if (alertMessage === false) {
                    const successMsg = this.translateService.instant(
                      'brokerage.producer.success.producerUpdate',
                    );
                    let alertData = {
                      type: 'success',
                      headerText: getAlertHead('success'),
                      bodyText: successMsg,
                    };
                    this.alertService.addAlert(alertData);
                    this.form.reset();
                    this.submitted = false;
                    this.producerSaveClicked = false;
                  }

                  let systemUserDetails = {
                    systemUserType: this.systemUserTypeDetails[0].id,
                    producerId: this.producerId,
                    branchId: this.branchId,
                    brokerId: this.brokerageId,
                    loginEmail: producerModel.email,
                    apiUser: true,
                    roleId: this.rollId,
                    systemUserId:
                      this.brokerageProducerOldSelected.systemUserId,
                    status: 'string',
                  };
                  if (this.brokerageProducerOldSelected.systemUserId) {
                    // call for update system user
                    this.updateSystemUserStatus(systemUserDetails);
                  } else if (this.hasSystemAccess) {
                    // call for create system user
                    this.createSystemUser(systemUserDetails);
                  }

                  this.updateProducerForm(producerModel);

                  // update the breadcrumbs if producer name is changed, need to add code for that.

                  this.producerSelected.firstName = updateDetails.firstName;
                  this.producerSelected.lastName = updateDetails.lastName;
                  // this.updatedBy = updateDetails.updatedBy;
                  // this.updatedAt = formatDateTime(
                  //   new Date(updateDetails.updatedDt),
                  //   this.longDateTimeFormat,
                  // );
                  this.getBrokerageProducer();
                  this.handleSaveChanges.emit({
                    showSpinner: true,
                    reloadData: true,
                  });
                  resolve(true);
                },
                error: (error) => {
                  const errorMsg = getErrorMessage(error);
                  this.producerSaveClicked = false;
                  if (![500].includes(error?.status)) {
                    this.hasError = true;
                    let alertData = {
                      type: 'error',
                      headerText: getAlertHead('error'),
                      bodyText: errorMsg,
                    };
                    this.alertService.addAlert(alertData);
                    reject(false);
                  }
                },
              })
              .add(() => {
                this.showSpinner = false;
                this.contentLoader = imageUpdated ? true : false;
                this.viewPortScroller.scrollToPosition([0, 0]);
              });
            this.formUpdated = false;
          } else {
            reject(false);
          }

          if (imageUpdated) {
            if (
              this.producerProfileOldSelected?.headShotImage !=
                this.croppedImage &&
              this.producerProfileId
            ) {
              this.showSpinner = true;
              spinnerCounter++;
              this.brokerageProducerService
                .updateProducerProfile(
                  +this.producerProfileId,
                  this.croppedImage,
                )
                .subscribe({
                  next: (response: any) => {
                    if (alertMessage !== false) {
                      const successMsg = this.translateService.instant(
                        'brokerage.producer.success.profileUpdate',
                      );
                      let alertData = {
                        type: 'success',
                        headerText: getAlertHead('success'),
                        bodyText: successMsg,
                      };
                      this.alertService.addAlert(alertData);
                    }

                    // let updateDetails = response?.data ?? {};
                    // this.updatedBy = updateDetails.updatedBy;
                    // this.updatedAt = formatDateTime(
                    //   new Date(updateDetails.updatedDt),
                    //   this.longDateTimeFormat,
                    // );

                    if (this.producerProfileOldSelected)
                      this.producerProfileOldSelected.headShotImage =
                        this.croppedImage;
                    this.unSubscriptionForms();
                    this.resetFormChanges();
                    resolve(true);
                  },
                  error: (error) => {
                    const errorMsg = getErrorMessage(error);
                    if (![500].includes(error?.status)) {
                      let alertData = {
                        type: 'error',
                        headerText: getAlertHead('error'),
                        bodyText: errorMsg,
                      };
                      this.alertService.addAlert(alertData);
                      this.hasError = true;
                    }
                    reject(false);
                  },
                })
                .add(() => {
                  this.showSpinner = false;
                  this.contentLoader = false;
                  this.viewPortScroller.scrollToPosition([0, 0]);
                });
            } else {
              reject(false);
            }
            this.isFormDirty = false;
          } else {
            reject(false);
          }
        } else {
          alertMessage = false;
          this.brokerageProducerService
            .CreateAtomicBrokerageProducer(producerModel)
            .subscribe({
              next: (response: any) => {
                this.producerId = response.data.id;
                this.brokerageProducerService.setProducerId(this.producerId);
                let updateDetails = response?.data ?? {};
                if (alertMessage === false) {
                  const successMsg = this.translateService.instant(
                    'workFlow3.brokerage.producerProfile.success.producerCreate',
                  );
                  let alertData = {
                    type: 'success',
                    headerText: getAlertHead('success'),
                    bodyText: successMsg,
                  };
                  this.alertService.addAlert(alertData);
                  this.producerSaveClicked = false;
                }

                let systemUserDetails = {
                  systemUserType: this.systemUserTypeDetails[0].id,
                  producerId: this.producerId,
                  branchId: this.branchId,
                  brokerId: this.brokerageId,
                  loginEmail: producerModel.email,
                  apiUser: true,
                  roleId: this.rollId,
                  systemUserId: this.brokerageProducerOldSelected.systemUserId,
                  status: 'string',
                };
                if (this.hasSystemAccess) {
                  // call for create system user
                  this.createSystemUser(systemUserDetails);
                }

                this.updateProducerForm(producerModel);

                // update the breadcrumbs if producer name is changed, need to add code for that.

                this.producerSelected.firstName = updateDetails.firstName;
                this.producerSelected.lastName = updateDetails.lastName;
                // this.updatedBy = updateDetails.updatedBy;
                // this.updatedAt = formatDateTime(
                //   new Date(updateDetails.updatedDt),
                //   this.longDateTimeFormat,
                // );
                this.getBrokerageProducer();
                this.handleSaveChanges.emit({
                  showSpinner: true,
                  reloadData: true,
                });

                if (imageUpdated) {
                  if (
                    this.producerProfileOldSelected?.headShotImage !=
                      this.croppedImage &&
                    this.producerId
                  ) {
                    this.showSpinner = true;
                    spinnerCounter++;
                    this.brokerageProducerService
                      .createProducerProfile(
                        +this.producerId,
                        this.croppedImage,
                      )
                      .subscribe({
                        next: (response: any) => {
                          const successMsg = this.translateService.instant(
                            'brokerage.producer.success.profileCreate',
                          );
                          let alertData = {
                            type: 'success',
                            headerText: getAlertHead('success'),
                            bodyText: successMsg,
                          };
                          this.alertService.addAlert(alertData);
                          this.form.reset();
                          this.submitted = false;
                          this.producerSaveClicked = false;

                          if (this.producerProfileOldSelected)
                            this.producerProfileOldSelected.headShotImage =
                              this.croppedImage;
                          this.unSubscriptionForms();
                          this.resetFormChanges();
                          resolve(true);
                        },
                        error: (error) => {
                          const errorMsg = getErrorMessage(error);
                          if (![500].includes(error?.status)) {
                            let alertData = {
                              type: 'error',
                              headerText: getAlertHead('error'),
                              bodyText: errorMsg,
                            };
                            this.alertService.addAlert(alertData);
                            this.hasError = true;
                          }
                          this.producerSaveClicked = false;
                          reject(false);
                        },
                      })
                      .add(() => {
                        this.showSpinner = false;
                        this.contentLoader = false;
                        this.viewPortScroller.scrollToPosition([0, 0]);
                      });
                  } else {
                    reject(false);
                  }
                  this.isFormDirty = false;
                } else {
                  this.contentLoader = false;
                  resolve(true);
                }
              },
              error: (error) => {
                const errorMsg = getErrorMessage(error);
                if (![500].includes(error?.status)) {
                  this.hasError = true;
                  let alertData = {
                    type: 'error',
                    headerText: getAlertHead('error'),
                    bodyText: errorMsg,
                  };
                  this.alertService.addAlert(alertData);
                }
                this.producerSaveClicked = false;
                reject(false);
              },
            })
            .add(() => {
              this.showSpinner = false;
              this.viewPortScroller.scrollToPosition([0, 0]);
              resolve(true);
            });
          this.formUpdated = false;
        }
        if (this.hasError) {
          this.isFormDirty = true;
        }

        if (spinnerCounter == 0) this.showSpinner = false;
      } else {
        resolve(true);
      }
    });
  }

  handleDiscardChanges(event) {
    this.handleDiscard.emit(event);
  }
  // handleDiscardChanges() {
  //   if (this.isFormDirty) {
  //     this.initialProducerFormData();
  //   }
  // }

  // initialProducerFormData() {
  //   this.contentLoader = true;
  //   this.producerSelected= this.brokerageProducerOldSelected={
  //     id:null,
  //     firstName:'',
  //     lastName: '',
  //     telephone: '',
  //     email: '',
  //     gender: '',
  //     group: '',
  //     brokerageBranch: {
  //       id: this.branchId,
  //       name: this.branchName;
  //     },
  //     firstAccess: false,
  //     serviceAccount: null,
  //     createdDt: '',
  //     createdBy: '',
  //     updatedDt: '',
  //     updatedBy: string | null;
  //     active?: boolean;
  //     customCommission?: boolean;
  //     systemUserId?: number | null;
  //     licenseExpiry: string | null;
  //     licenseNumber: string | null;
  //     producerSystemUser?: { [x: string]: any };
  //   }
  //   forkJoin({
  //     brokerage: this.brokerageService.GetBrokerage(this.brokerageId),
  //     brokerageBranch: this.brokerageBranchService.GetBrokerageBranch(
  //       this.branchId,
  //     ),
  //     brokerageProducer: this.brokerageProducerService.GetBrokerageProducer(
  //       this.producerId,
  //     ),
  //   }).subscribe(({ brokerage, brokerageBranch, brokerageProducer }) => {

  //     this.brokerageProducerOldSelected = { ...brokerageProducer.data };

  //     this.hasSystemAccess =
  //       this.brokerageProducerOldSelected.systemUserId &&
  //       this.brokerageProducerOldSelected.producerSystemUser?.['active']
  //         ? true
  //         : false;

  //     this.producerSelected = { ...brokerageProducer.data };
  //     this.form[this.producerSelected.active ? 'enable' : 'disable']();

  //     this.brokerageProducerService
  //       .GetProducerProfile(this.producerSelected.id)
  //       .subscribe({
  //         next: (response) => {
  //           if (
  //             Object.keys(response?.data) &&
  //             Object.keys(response?.data).length > 0
  //           ) {
  //             this.producerProfileOldSelected = {
  //               ...response?.data,
  //             };
  //             this.croppedImage =
  //               this.producerProfileOldSelected?.headShotImage ??
  //               'assets/img/default-photo.png';
  //             this.previousImage =
  //               this.producerProfileOldSelected?.headShotImage ??
  //               'assets/img/default-photo.png';
  //             this.producerProfileId = this.producerProfileOldSelected?.id;
  //           }
  //           this.initProducerForm();
  //         },
  //         error: (error) => {},
  //       });
  //   });
  // }
  initialProducerSelected() {
    this.producerSelected = {
      id: null,
      firstName: '',
      lastName: '',
      telephone: '',
      email: '',
      gender: '',
      group: '',
      brokerageBranch: { id: this.branchId, name: '' },
      firstAccess: null,
      serviceAccount: null,
      createdDt: '',
      createdBy: '',
      updatedDt: '',
      updatedBy: '',
      active: false,
      customCommission: null,
      systemUserId: null,
      licenseExpiry: null,
      licenseNumber: null,
      BrokerageProducerTeams: null,
    };
  }
  initProducerForm() {
    this.unSubscriptionForms();
    this.isFormDirty = false;
    this.form.get('firstName').setValue(this.producerSelected.firstName);
    this.form.get('lastName').setValue(this.producerSelected.lastName);
    this.form.get('phone').setValue(this.producerSelected.telephone);
    this.form.get('email').setValue(this.producerSelected.email);
    this.form
      .get('customCommission')
      .setValue(this.producerSelected.customCommission);
    this.form
      .get('licenseNumber')
      .setValue(this.producerSelected.licenseNumber ?? '');
    this.form
      .get('licenseExpiry')
      .setValue(
        this.producerSelected.licenseExpiry
          ? formatDateMoment(
              this.producerSelected.licenseExpiry?.split('T')?.[0],
              this.shortDateFormat,
            )
          : '',
      );
    if (this.producerSelected?.BrokerageProducerTeams?.length > 0) {
      this.form
        .get('brokerageBranchTeamName')
        .setValue(
          this.producerSelected?.BrokerageProducerTeams[0]?.[
            'brokerageBranchTeam'
          ]?.['name'],
        );
      this.form
        .get('brokerageBranchTeamId')
        .setValue(
          this.producerSelected?.BrokerageProducerTeams[0]?.[
            'brokerageBranchTeam'
          ]?.['id'],
        );
    } else {
      this.form.get('brokerageBranchTeamName').setValue('');
      this.form.get('brokerageBranchTeamId').setValue('');
    }
    this.showBodySpinner = false;
    this.initialFormValue = this.form.value;
    this.formSubscription = this.form.valueChanges.subscribe(() => {
      this.isFormDirty = !this.isFormUnchanged(this.initialFormValue);
    });
    this.contentLoader = false;
  }
  private unSubscriptionForms() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }
  private resetFormChanges() {
    this.initialFormValue = this.form.value;
    this.formSubscription = this.form.valueChanges.subscribe(() => {
      this.isFormDirty = !this.isFormUnchanged(this.initialFormValue);
    });
  }

  isFormUnchanged(initialValues): boolean {
    let formUnchanged = false;
    if (this.previousImage != this.croppedImage) {
      this.formUpdated = true;
      formUnchanged = false;
    } else {
      this.formUpdated = false;
      formUnchanged = true;
    }
    if (JSON.stringify(this.form.value) === JSON.stringify(initialValues)) {
      this.formUpdated = false;
      formUnchanged = true;
    } else {
      this.formUpdated = true;
      formUnchanged = false;
    }
    return formUnchanged;
  }
  getBrokerageProducer() {
    this.brokerageProducerService
      .GetBrokerageProducer(this.producerId)
      .subscribe({
        next: (response) => {
          this.brokerageProducerOldSelected = { ...response.data };
          this.contentLoader = false;
        },
        error: (error) => {
          const errorMsg = getErrorMessage(error);
          if (![500].includes(error?.status)) {
            let alertData = {
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: errorMsg,
            };
            this.alertService.addAlert(alertData);
          }
        },
      });
  }

  updateProducerForm(producerModel: ProducerModel) {
    this.unSubscriptionForms();
    this.isFormDirty = false;
    this.form.get('firstName').setValue(producerModel.firstName);
    this.form.get('lastName').setValue(producerModel.lastName);
    this.form.get('phone').setValue(producerModel.telephone);
    this.form.get('email').setValue(producerModel.email);
    this.form.get('customCommission').setValue(producerModel.customCommission);
    this.form.get('licenseNumber').setValue(producerModel.licenseNumber);
    this.form
      .get('licenseExpiry')
      .setValue(
        producerModel.licenseExpiry
          ? getFormatDate(
              producerModel.licenseExpiry,
              'YYYY-MM-DD',
              this.shortDateFormat,
            )
          : '',
      );
    this.resetFormChanges();
  }
  handleAlertClose() {
    this.alertData = {
      show: false,
      type: '',
      header: '',
      body: '',
    };
  }
  searchProducerTeam(searchValue) {
    if (searchValue === '') {
      this.producerTeamOptions = [];
    }
    if (searchValue.length >= 3) {
      const searchKey = searchValue.trim();
      this.brokerageBranchTeamsService
        .getBranchTeams(this.branchId, searchKey, 1, 20, '')
        .subscribe((data) => {
          this.producerTeamOptions = data.data?.map((dataObj) => ({
            main_text: dataObj.name,
            id: dataObj.id,
          }));
        });
    } else {
      this.producerTeamOptions = [];
      return;
    }
  }

  async selectProducerTeam(value) {
    let selectedValue = this.producerTeamOptions.find((x) => x.id === value);
    this.selectedTeamData = selectedValue;
    this.currentTeamValue = selectedValue.main_text;
    this.form.controls['brokerageBranchTeamName'].setValue(
      this.currentTeamValue,
    );
    this.form.controls['brokerageBranchTeamId'].setValue(selectedValue.id);
    this.producerTeamErrMsg = '';
  }
}
