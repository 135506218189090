<form
  id="formContact"
  [ngClass]="
    direction && direction === 'row'
      ? 'form-main-contact'
      : 'form-main-contact-column'
  "
  [formGroup]="form"
>
  <label class="text-input-label">Main contact</label>
  <div
    [ngClass]="
      direction && direction === 'row'
        ? 'container-radio-button'
        : 'container-radio-button-column'
    "
  >
    <div class="container-radio-button-flex">
      <input
        class="container-radio-button-margin-top"
        type="radio"
        value="email"
        formControlName="mainContact"
      />
      <label class="text-input-label">Email</label>
    </div>
    <div class="container-radio-button-flex">
      <input
        class="container-radio-button-margin-top"
        type="radio"
        value="phone"
        formControlName="mainContact"
      />
      <label class="text-input-label">Phone</label>
    </div>
  </div>
</form>
