<div class="holder">
  <boxxds-alert
    *ngFor="let i of alertList; let x = index"
    (handleCloseEvent)="removeAlert(x)"
    [type]="i.type"
    [headerText]="i.headerText | translate"
    [bodyText]="i.bodyText"
  >
  </boxxds-alert>
  <boxxds-breadcrumb [items]="itemsMenu"></boxxds-breadcrumb>
  <header class="head-wraper">
    <boxxds-heading
      *ngIf="producerSelected"
      sz="5"
      [text]="producerSelected.firstName + ' ' + producerSelected?.lastName"
    >
    </boxxds-heading>
    <boxxds-link
      [url]="'/dashboard/branch/profile/producer/' + producerId"
      [name]="'brokerage.producer.viewProfile' | translate"
    ></boxxds-link>
  </header>
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!showBodySpinner">
    <form [formGroup]="form" (ngSubmit)="handleSubmit($event)">
      <div class="form-panel-title">
        <boxxds-body
          sz="lg"
          [text]="'brokerage.producer.producerDetails' | translate"
          [customStyle]="{ color: '#BDBDBF', textAlign: 'left' }"
        >
        </boxxds-body>

        <boxxds-body
          sz="xs"
          [text]="'*' + ('common.required' | translate)"
          [customStyle]="{
            color: '#BDBDBF',
            textAlign: 'right',
            textTransform: 'uppercase'
          }"
        >
        </boxxds-body>
      </div>

      <div class="form-panel">
        <boxxds-textinput
          [form]="form"
          [placeholder]="
            'brokerage.producer.placeholder.enterFirstName' | translate
          "
          [control]="'firstName'"
          [id]="'first-name'"
          [width]="'100%'"
          [label]="('brokerage.producer.label.firstName' | translate) + '*'"
          [isRequired]="true"
          [requiredErrMsg]="
            'brokerage.producer.error.requiredErrMsg' | translate
          "
          [invalidErrMsg]="'brokerage.producer.error.invalidErrMsg' | translate"
          [formSubmitted]="submitted"
          [isDisabled]="!producerSelected?.active"
        >
        </boxxds-textinput>

        <boxxds-textinput
          [form]="form"
          [placeholder]="
            'brokerage.producer.placeholder.enterLastName' | translate
          "
          [control]="'lastName'"
          [id]="'last-name'"
          [width]="'100%'"
          [label]="('brokerage.producer.label.lastName' | translate) + '*'"
          [isRequired]="true"
          [requiredErrMsg]="
            'brokerage.producer.error.requiredErrMsg' | translate
          "
          [invalidErrMsg]="'brokerage.producer.error.invalidErrMsg' | translate"
          [formSubmitted]="submitted"
          [isDisabled]="!producerSelected?.active"
        >
        </boxxds-textinput>

        <boxxds-textinput
          [form]="form"
          [placeholder]="
            'brokerage.producer.placeholder.enterEmailAddress' | translate
          "
          [control]="'email'"
          [id]="'email'"
          [width]="'100%'"
          [label]="('brokerage.producer.label.email' | translate) + '*'"
          [requiredErrMsg]="
            'brokerage.producer.error.requiredErrMsg' | translate
          "
          [isRequired]="true"
          [invalidErrMsg]="
            'brokerage.producer.error.enterAValidEmail' | translate
          "
          [formSubmitted]="submitted"
          [isDisabled]="!producerSelected?.active"
        >
        </boxxds-textinput>

        <boxxds-textinput
          [form]="form"
          [mask]="'(000) 000-0000'"
          [placeholder]="'brokerage.producer.enterPhoneNumber' | translate"
          [control]="'phone'"
          [id]="'phoneNumber'"
          [width]="'100%'"
          [label]="('brokerage.producer.enterPhoneNumber' | translate) + '*'"
          [requiredErrMsg]="
            'brokerage.producer.error.requiredErrMsg' | translate
          "
          [invalidErrMsg]="'brokerage.producer.error.invalidErrMsg' | translate"
          [isRequired]="true"
          [formSubmitted]="submitted"
          [isDisabled]="!producerSelected?.active"
        >
        </boxxds-textinput>

        <boxxds-textinput
          [form]="form"
          [placeholder]="
            'brokerage.producer.placeholder.enterNumber' | translate
          "
          [control]="'licenseNumber'"
          [id]="'licenseNumber'"
          [width]="'100%'"
          [label]="'brokerage.producer.label.licenseNumber' | translate"
          [invalidErrMsg]="
            'brokerage.producer.error.invalidLicenseNumber' | translate
          "
          [formSubmitted]="submitted"
          [isDisabled]="!producerSelected?.active"
          [customErrMsg]="
            'brokerage.producer.error.noLicenseNumber' | translate
          "
        >
        </boxxds-textinput>

        <boxxds-datepicker
          [placeholder]="
            'brokerage.producer.placeholder.selectDate' | translate
          "
          [label]="'brokerage.producer.label.licenseExpiryDate' | translate"
          [showErr]="errorExpiry().show"
          [errMsg]="errorExpiry().msg | translate"
          [bsValue]="form.value['licenseExpiry']"
          [dateFormat]="'MMM DD, YYYY'"
          [isDisabled]="!producerSelected?.active"
          [containerStyle]="{ width: '306px', marginTop: '-4px' }"
          (bsValueChange)="handleExpiryDate($event)"
        >
        </boxxds-datepicker>

        <div class="item">
          <boxxds-body
            sz="md"
            [text]="'producerPhoto' | translate"
            [customStyle]="{
              color: '#2D2D39',
              textAlign: 'left',
              marginBottom: '8px'
            }"
            [ngStyle]="{ maxWidth: '179px' }"
          ></boxxds-body>
          <div class="d-flex">
            <img [src]="croppedImage" alt="Avatar" class="avatar" />

            <label class="fileContainer" *ngIf="producerSelected?.active">
              <input
                #fileInput
                type="file"
                (click)="fileInput.value = ''"
                (change)="onImageSelected($event)"
                accept="image/*"
              />
              <img width="14" height="14" src="assets/img/upload.png" />
            </label>
          </div>
        </div>

        <dialog id="myDialog" #mi>
          <form method="dialog">
            <header>
              <h2>{{ "producerPhoto" | translate }}</h2>
            </header>
            <main>
              <image-cropper
                [resizeToWidth]="256"
                [cropperMinWidth]="128"
                [imageChangedEvent]="imageChangedEvent"
                [aspectRatio]="4 / 4"
                format="png"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()"
                (loadImageFailed)="loadImageFailed()"
                *ngIf="imageChangedEvent"
              ></image-cropper>
            </main>
            <footer>
              <span class="profile-note">{{
                "common.profilePictureNote" | translate
              }}</span>
              <menu>
                <button
                  id="dialog-footer--confirm-btn"
                  class="dialog-submit-btn"
                  (click)="selectedImage()"
                >
                  {{ "common.confirm" | translate }}
                </button>
                <button
                  id="close_dialog"
                  class="dialog-close-btn"
                  (click)="closeImageModal(); mi.close()"
                >
                  {{ "common.close" | translate }}
                </button>
              </menu>
            </footer>
          </form>
        </dialog>
      </div>

      <div class="footer-add-branch">
        <div class="footer-left-container">
          <app-timestamp
            [label]="'common.createdBy' | translate"
            [value]="createdBy"
            [containerStyle]="{ marginLeft: '10px', top: '-10px' }"
          ></app-timestamp>
          <app-timestamp
            *ngIf="updatedBy"
            [label]="'common.updatedLastBy' | translate"
            [value]="updatedBy"
            [containerStyle]="{
              marginLeft: '10px',
              position: 'relative',
              top: '-10px'
            }"
          ></app-timestamp>
        </div>
        <div class="footer-rigth-container">
          <a
            href="javascript:void(0)"
            (click)="handleProducerChangeStatus()"
            [ngStyle]="{ 'margin-right': '64px', 'margin-top': '12px' }"
          >
            {{
              (producerSelected?.active
                ? "common.deactivate"
                : "common.reactivate"
              ) | translate
            }}
            {{ "common.producer" | translate }}</a
          >

          <boxxds-button
            *ngIf="!showSpinner; else spinnerTemplate"
            [buttonText]="'common.saveChanges' | translate"
            [isDisabled]="
              (producerSelected && !producerSelected.active) ||
              !!!this.isFormDirty
            "
            [type]="'submitted'"
            btnType="primary-outlined"
            [btnStyle]="{ padding: '0 30px' }"
            [sz]="'sm'"
            (handleClick)="handleSubmit($event)"
          >
          </boxxds-button>
          <ng-template #spinnerTemplate>
            <button
              class="btn-processing"
              [ngStyle]="{
                height: '32px',
                border: '1px solid #FFBE3D',
                borderRadius: '8px',
                width: '149px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '4px 8px',
                background: '#FCF8EF'
              }"
            >
              <boxxds-spinner></boxxds-spinner>
            </button>
          </ng-template>
        </div>
      </div>
    </form>
    <div class="add-commissions-panel">
      <div class="neutral-gradient">
        <div class="commission-panel-title">
          <boxxds-body
            sz="lg"
            [text]="'brokerage.producer.producerCommissionsDetails' | translate"
          ></boxxds-body>
          <boxxds-switch
            [checked]="checked"
            name="skip_notification"
            (changed)="toggleChanged($event)"
            [label]="'brokerage.producer.customCommissions' | translate"
            [disabled]="!producerSelected?.active"
          >
          </boxxds-switch>
        </div>
        <div class="commission-panel-form">
          <app-commission-details
            *ngIf="
              brokerageProducerCommissions.data.length > 0
                ? true
                : customCommission
            "
            [isActive]="producerSelected?.active ? customCommission : false"
            (onCommissionChange)="
              handleBrokerageProducerCommissionModification($event)
            "
            [commissionData]="brokerageProducerCommissions"
            [resetAddFormSubject]="resetAddCommissionFormSubject"
            (onCommissionPageChange)="handleCommissionPageChange($event)"
          >
          </app-commission-details>
          <app-modal-commission-confirmation
            *ngIf="showConfirmationModal"
            [showModal]="showConfirmationModal"
            [commissionData]="commissionConfirmationData"
            (handleClose)="closeModalConfirmation()"
            (handleSubmitCommissionOverride)="handleCommissionOverride($event)"
          >
          </app-modal-commission-confirmation>
        </div>
      </div>
    </div>
  </ng-container>
</div>
