import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DomainsService } from 'src/app/services/domains.service';
import { InsuredService } from 'src/app/services/insured.service';
import { RoleService } from 'src/app/services/roles.service';
import { SystemUserService } from 'src/app/services/system-user.service';
import { arrayToObjet } from 'src/app/utils/utils';
import { BoxxResponse } from 'src/app/entities/boxx-response';
import { Role } from 'src/app/entities/role';
import { SystemUser } from 'src/app/entities/system-user';
import { formatDateTime } from 'src/app/utils/formatDate';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { regexHelpers, getErrorMessage } from 'src/app/utils/utils';
import { Store, select } from '@ngrx/store';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';

@Component({
  selector: 'app-edit-system-insured-user',
  templateUrl: './edit-system-insured-user.component.html',
  styleUrls: ['./edit-system-insured-user.component.less'],
})
export class EditSystemInsuredUserComponent implements OnInit {
  @Output() updateTitleEvent = new EventEmitter<string>();
  @Output() updateStatusEvent = new EventEmitter<boolean>();

  @Input() systemUserType: string;

  insuredUsers: Array<any> = [];

  insuredTypes: Array<any> = [
    { key: 'Company', value: 'company' },
    { key: 'Person', value: 'Person' },
  ];
  roles: Array<any> = [];
  tInsuredUsers: any;
  tInsuredTypes: any;
  tRoles: any;
  showBodySpinner: boolean = false;

  insuredUserForm = new FormGroup({
    insuredId: new FormControl('', [Validators.required]),
    insuredType: new FormControl('', [Validators.required]),
    roleId: new FormControl('', [Validators.required]),
    loginEmail: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(regexHelpers.MailId),
    ]),
  });

  requiredErrMsg: string = 'error.thisFieldIsRequired';
  invalidErrMsg: string = 'error.thisFieldIsInvalid';
  isSuccess = false;
  hasError = false;
  errorMessage = '';
  message = '';

  formSubmitted: boolean = false;
  insuredUser = '';
  insuredType = '';
  roleName = '';
  insuredTypeId: number;
  showSpinner: boolean = false;
  currentSystemUser: any;
  userId = '';
  toggleActive = false;
  processingActivation = false;
  showErrorAlert = false;
  msgErrorAlert = '';
  loggedUserId: number;
  invalidRole = '';
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  showReloadModal: boolean = false;
  shortDateTimeFormat: string = '';
  longDateTimeFormat: string = '';

  constructor(
    private roleService: RoleService,
    private activedRoute: ActivatedRoute,
    private systemUser: SystemUserService,
    private insuredService: InsuredService,
    private domainService: DomainsService,
    private localStorageService: LocalStorageService,
    private store: Store,
  ) {
    const route = this.activedRoute.snapshot.params;
    this.userId = route['id'];
  }

  updateTitle(value: string) {
    this.updateTitleEvent.emit(value);
  }

  updateStatus(status: boolean) {
    this.updateStatusEvent.emit(status);
  }

  handleCloseReloadWarning() {
    this.showReloadModal = false;
  }

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateTimeFormat = data.shortDateTimeFormat;
      this.longDateTimeFormat = data.longDateTimeFormat;
    });

    this.loggedUserId = this.localStorageService.getBoxxUserId();
    this.showBodySpinner = true;
    this.fetchInsureTypes();
    this.fetchCurrentSystemUser(this.userId);
  }

  fetchCurrentSystemUser(userId) {
    this.systemUser.GetSystemUser(userId).subscribe({
      next: (response) => {
        this.setCurrentSystemUserResult(response);
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });
    this.insuredUserForm[this.toggleActive ? 'enable' : 'disable']({
      emitEvent: false,
    });
  }

  setCurrentSystemUserResult(data: BoxxResponse<SystemUser>) {
    const { data: SystemUser } = data;
    this.currentSystemUser = SystemUser;
    this.populateForm();
  }

  populateForm() {
    const {
      insured: { id: insuredId, insuredType, companyName, firstName, lastName },
      role,
      loginEmail,
      active,
    } = this.currentSystemUser;

    const tInsured = this.tInsuredTypes[insuredType];
    this.insuredType = `${tInsured.description}`;
    if (insuredType == 1) {
      this.insuredUser = firstName + ' ' + lastName;
    } else {
      this.insuredUser = companyName;
    }
    this.roleName = role.name;

    this.insuredUserForm.get('insuredType').setValue(insuredType);
    this.insuredUserForm.get('insuredId').setValue(insuredId);
    this.insuredUserForm.get('roleId').setValue(role.id);
    this.insuredUserForm.get('loginEmail').setValue(loginEmail);

    this.toggleActive = !!active;
    this.insuredUserForm[this.toggleActive ? 'enable' : 'disable']({
      emitEvent: false,
    });

    this.updateTitle(this.insuredUser);
    this.updateStatus(active);
    this.currentSystemUser['createdDt'] = formatDateTime(
      this.currentSystemUser.createdDt,
      this.longDateTimeFormat,
    );
    this.currentSystemUser['updatedDt'] = formatDateTime(
      this.currentSystemUser.updatedDt,
      this.longDateTimeFormat,
    );
    this.showBodySpinner = false;
  }

  searchInsureds(name) {
    // this.insuredUserForm.get('insuredId').setValue(null);
    if (name.length >= 3) {
      this.fetchInsureds(this.insuredTypeId, name);
    } else {
      this.insuredUsers = [];
    }
  }

  fetchInsureds(insuredTypeId, name = null) {
    this.insuredService.GetAllByInsuredType(insuredTypeId, name).subscribe({
      next: (response) => {
        const tableData = response.data?.map((dataObj) => {
          if (this.insuredTypeId == 1) {
            return {
              key: `${dataObj.firstName} ${dataObj.lastName}`,
              value: dataObj.id,
            };
          } else {
            return {
              key: `${dataObj.companyName}`,
              value: dataObj.id,
            };
          }
        });
        this.insuredUsers = [...tableData];
        this.tInsuredUsers = arrayToObjet(response.data, 'id');
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });
  }

  fetchInsureTypes() {
    this.domainService.GetByDomainCode('ENTITYTYPE').subscribe({
      next: (response) => {
        const tableData = response.data?.map((dataObj) => ({
          key: `${dataObj.description}`,
          value: dataObj.id,
        }));
        this.tInsuredTypes = arrayToObjet(response.data, 'id');
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });
  }

  onSubmit(data) {
    this.handleCloseSuccessEvent();
    if (this.insuredUserForm.disabled) {
      return;
    }
    this.formSubmitted = true;
    this.hasError = false;
    // check boxx user form is invalid
    if (this.insuredUserForm.invalid) {
      return;
    }

    this.showSpinner = true;

    this.systemUser.UpdateSystemUser(this.userId, data).subscribe(
      (response) => {
        let currentSystemUserId = this.localStorageService.getSystemUserId();
        if (currentSystemUserId === +this.userId) {
          this.showReloadModal = true;
        } else {
          this.isSuccess = true;
        }
        this.currentSystemUser['updatedDt'] = formatDateTime(
          response?.data?.updatedDt,
          this.longDateTimeFormat,
        );
        this.currentSystemUser['updatedBy'] = response?.data?.updatedBy;
        this.message = 'common.saveSuccessMsg';
        this.showSpinner = false;
        this.formSubmitted = false;
      },
      ({ error }) => {
        this.showSpinner = false;
        this.hasError = true;
        this.errorMessage = error.message ? error.message : error.error;
      },
    );
  }

  handleCloseSuccessEvent() {
    this.isSuccess = false;
    this.hasError = false;
  }

  // resetinsuredForm(){
  //   // this.insuredUserForm.reset();
  //   this.insuredUserForm.get('insuredId').setValue(null);
  //   this.insuredUserForm.get('insuredType').setValue(null);
  //   this.insuredUserForm.get('roleId').setValue(null);
  //   this.insuredUserForm.get('loginEmail').setValue(null);
  //   this.insuredUserForm.get('password').setValue(null);

  //   this.insuredUser = '';
  //   this.insuredType = '';
  //   this.roleId = '';
  // }

  insureUserValueChanged(event) {
    const user = this.tInsuredUsers[event];
    if (this.insuredTypeId == 1)
      this.insuredUser = `${user.firstName} ${user.lastName}`;
    else this.insuredUser = `${user.companyName}`;
  }

  insureTypeValueChanged(event) {
    // this.insuredUserForm.get('insuredId').setValue(null);
    this.insuredUsers = [];
    this.insuredUser = '';
    const insuredType = this.tInsuredTypes[event];
    this.insuredType = `${insuredType.description}`;
    this.insuredTypeId = event;
  }

  boxxRoleValueChanged(event) {
    this.invalidRole = '';
    let id = this.roles.find((x) => x.value == event).id;
    const role = this.tRoles[id];
    this.roleName = `${role.name}`;
    this.insuredUserForm.get('roleId').setValue(role.id);
  }

  searchRoles(firstName) {
    // reset form group's boxxUserId value
    this.insuredUserForm.get('roleId').setValue(null);
    this.invalidRole = '';
    if (firstName.length >= 3) {
      this.roleService
        .GetByNameNsystemUserType(firstName, this.systemUserType)
        .subscribe({
          next: (response) => {
            this.setRoleSearchResult(response, firstName);
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    } else {
      this.roles = [];
    }
  }

  setRoleSearchResult(data: BoxxResponse<Role>, name = '') {
    const tableData = data.data?.map((dataObj) => ({
      key: `${dataObj.name}`,
      value: `${dataObj.name}`,
      id: dataObj.id,
    }));
    this.roles = [...tableData];
    this.tRoles = arrayToObjet(data.data, 'id');
    if (tableData.length > 0) {
      this.invalidRole = 'This feild is invalid';
    } else {
      this.invalidRole = '';
    }
  }

  initProcessing() {
    this.processingActivation = true;
    this.isSuccess = false;
    this.hasError = false;
  }

  deActivateSystemUser() {
    this.handleCloseSuccessEvent();
    this.initProcessing();
    this.systemUser.DeleteSystemUser(this.userId).subscribe(
      (response) => {
        this.currentSystemUser['updatedDt'] = formatDateTime(
          response?.data?.updatedDt,
          this.longDateTimeFormat,
        );
        this.currentSystemUser['updatedBy'] = response?.data?.updatedBy;
        this.message = 'common.userDeActivateMsg';
        this.isSuccess = true;
        this.processingActivation = false;
        this.toggleActive = false;
        this.updateStatus(false);
        this.insuredUserForm[this.toggleActive ? 'enable' : 'disable']({
          emitEvent: false,
        });
      },
      ({ error }) => {
        this.hasError = true;
        this.processingActivation = false;
        this.errorMessage = error.message ? error.message : error.error;
      },
    );
  }

  activateSystemUser() {
    this.handleCloseSuccessEvent();
    this.initProcessing();
    this.systemUser.RestoreSystemUser(this.userId).subscribe(
      (response) => {
        this.currentSystemUser['updatedDt'] = formatDateTime(
          response?.data?.updatedDt,
          this.longDateTimeFormat,
        );
        this.currentSystemUser['updatedBy'] = response?.data?.updatedBy;
        this.message = 'common.userActivateMsg';
        this.isSuccess = true;
        this.processingActivation = false;
        this.toggleActive = true;
        this.updateStatus(true);
        this.insuredUserForm[this.toggleActive ? 'enable' : 'disable']({
          emitEvent: false,
        });
      },
      ({ error }) => {
        this.hasError = true;
        this.processingActivation = false;
        this.errorMessage = error.message ? error.message : error.error;
      },
    );
  }
  handleCloseSuccessEventExt() {
    this.showErrorAlert = false;
  }
  checkUser() {
    return String(this.loggedUserId) != this.userId;
  }
}
