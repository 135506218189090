import { Component, OnInit, Input } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import { BrokerageService } from 'src/app/services/brokerage.service';
import { BrokerageBranchService } from 'src/app/services/brokerage-branch.service';
import { BrokerageProducerService } from 'src/app/services/brokerage-producer.service';
import { DomainsService } from 'src/app/services/domains.service';
import { InsuredService } from 'src/app/services/insured.service';
import { RoleService } from 'src/app/services/roles.service';
import { SystemUserService } from 'src/app/services/system-user.service';
import { arrayToObjet } from 'src/app/utils/utils';
import { BoxxResponse } from 'src/app/entities/boxx-response';
import { Role } from 'src/app/entities/role';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { regexHelpers, getErrorMessage } from 'src/app/utils/utils';
import { getUserTypes } from 'src/app/store/dashboard/dashboard.selector';
import { Store, select } from '@ngrx/store';
import { SYSTEM_USER_TYPES } from 'src/app/constants/security/systemUserType';

@Component({
  selector: 'app-new-system-producer-user',
  templateUrl: './new-system-producer-user.component.html',
  styleUrls: ['./new-system-producer-user.component.less'],
})
export class NewSystemProducerUserComponent implements OnInit {
  @Input() systemUserType: string;

  brokers: Array<any> = [];

  branches: Array<any> = [];

  producers: Array<any> = [];

  roles: Array<any> = [];

  tBrokers: any;
  tBranches: any;
  tProducers: any;
  tRoles: any;

  producerUserForm = new FormGroup({
    brokerId: new FormControl('', [Validators.required]),
    branchId: new FormControl('', [Validators.required]),
    producerId: new FormControl('', [Validators.required]),
    roleId: new FormControl('', [Validators.required]),
    loginEmail: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(regexHelpers.MailId),
    ]),
    password: new FormControl(''),
    systemUserType: new FormControl(0),
    status: new FormControl('string'),
    apiUser: new FormControl(true),
  });

  requiredErrMsg: string = 'error.thisFieldIsRequired';
  invalidErrMsg: string = 'error.thisFieldIsInvalid';

  invalidProducer: string = '';
  invalidRole: string = '';

  isSuccess = false;
  hasError = false;
  errorMessage = '';

  formSubmitted: boolean = false;

  broker = '';
  branch = '';
  producer = '';
  role = '';

  showSpinner: boolean = false;

  showErrorAlert = false;
  msgErrorAlert = '';
  userID: number;

  producerListTotalCount = 1;
  producerListTotalPages = 1;
  branchListTotalCount = 1;
  branchListTotalPages = 1;
  selectedSystemUserType: number = 0;

  constructor(
    private brokerageService: BrokerageService,
    private brokerageBranchService: BrokerageBranchService,
    private brokerageProducer: BrokerageProducerService,
    private roleService: RoleService,
    private systemUser: SystemUserService,
    private localStorageService: LocalStorageService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.userID = this.localStorageService.getBoxxUserId();
    this.store.pipe(select(getUserTypes)).subscribe(async (data) => {
      this.selectedSystemUserType = data.find(
        (item) =>
          this.toLowerWithoutSpace(item.description) ===
          SYSTEM_USER_TYPES.Producer.toLowerCase(),
      )?.id;
    });
    this.producerUserForm
      .get('systemUserType')
      .setValue(this.selectedSystemUserType);
  }

  toLowerWithoutSpace(input) {
    let result = input.replace(/[A-Z]/g, (match) => ' ' + match.toLowerCase());
    result = result.replace(/\s+/g, '');
    return result;
  }

  searchBrokers(name) {
    this.brokers = [];
    this.invalidProducer = '';
    this.producerUserForm.get('brokerId').setValue(null);
    this.branch = '';
    this.branches = [];
    this.producer = '';
    this.producers = [];

    if (name.length >= 3) {
      let limit = 10,
        page = 1,
        sort = '';
      let active = 1;
      this.brokerageService
        .GetBrokeragesByName(name, page, limit, sort, active)
        .subscribe({
          next: (response) => {
            const tableData = response.data?.map((dataObj) => ({
              key: `${dataObj.name}`,
              value: `${dataObj.name}`,
              id: dataObj.id,
            }));
            this.brokers = [...tableData];
            this.tBrokers = arrayToObjet(response.data, 'id');
            if (tableData.length > 0) {
              this.invalidProducer = 'systemUser.error.invalidProducer';
            } else {
              this.invalidProducer = '';
            }
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    } else {
      this.brokers = [];
    }
  }

  loadMoreBranchOptions(pageNumber) {
    let brokerageName = this.producerUserForm.controls['brokerId'].value;
    let brokerageId = this.brokers.find((obj) => obj.value == brokerageName).id;
    if (this.branchListTotalCount > this.branches?.length) {
      this.fetchBranches(brokerageId, pageNumber, true);
    }
  }

  fetchBranches(brokerageId, page = 1, isLoadMore = false) {
    this.producerUserForm.get('branchId').setValue(null);
    let limit = 10,
      sort = '';
    let active = 1;
    this.brokerageBranchService
      .GetBranchByBrokerageId(brokerageId, page, limit, sort, active)
      .subscribe({
        next: (response) => {
          const tableData = response.data?.map((dataObj) => ({
            key: `${dataObj.name}`,
            value: dataObj.id,
          }));
          if (isLoadMore) {
            this.branches = [...this.branches, ...tableData];
            this.tBranches = {
              ...this.tBranches,
              ...arrayToObjet(response.data, 'id'),
            };
          } else {
            this.branches = [...tableData];
            this.tBranches = arrayToObjet(response.data, 'id');
          }
          this.branchListTotalCount = response?.pagination?.totalRecords;
          this.branchListTotalPages = response?.pagination?.totalPages;
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
  }

  loadMoreProducerOptions(pageNumber) {
    let branchId = this.producerUserForm.controls['branchId'].value;
    if (this.producerListTotalCount > this.producers?.length) {
      this.fetchProducers(branchId, pageNumber, true);
    }
  }

  fetchProducers(brokerageBranchId, page = 1, isLoadMore = false) {
    this.producerUserForm.get('producerId').setValue(null);
    let limit = 10,
      sort = '';
    let active = 1;
    this.brokerageProducer
      .GetByBranchId(brokerageBranchId, page, limit, sort, active)
      .subscribe({
        next: (response) => {
          const tableData = response.data?.map((dataObj) => ({
            key: `${dataObj.firstName} ${dataObj.lastName}`,
            value: dataObj.id,
          }));
          if (isLoadMore) {
            this.producers = [...this.producers, ...tableData];
            this.tProducers = {
              ...this.tProducers,
              ...arrayToObjet(response.data, 'id'),
            };
          } else {
            this.producers = [...tableData];
            this.tProducers = arrayToObjet(response.data, 'id');
          }
          this.producerListTotalCount = response?.pagination?.totalRecords;
          this.producerListTotalPages = response?.pagination?.totalPages;
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
  }

  brokerValueChanged(event) {
    let id = this.brokers.find((obj) => obj.value == event).id;
    const broker = this.tBrokers[id];
    this.broker = `${broker.name}`;
    this.fetchBranches(id);
    this.invalidProducer = '';
    this.branch = '';
    this.branches = [];
    this.producer = '';
    this.producers = [];
  }

  branchValueChanged(event) {
    const branch = this.tBranches[event];
    this.branch = `${branch.name}`;
    this.fetchProducers(event);
    this.producer = '';
    this.producers = [];
  }

  producerValueChanged(event) {
    const producer = this.tProducers[event];
    this.producer = `${producer.firstName} ${producer.lastName}`;
  }

  roleValueChanged(event) {
    let id = this.roles.find((x) => x.value == event).id;
    const role = this.tRoles[id];
    this.role = `${role.name}`;
    this.invalidRole = '';
    this.producerUserForm.get('roleId').setValue(role.id);
  }

  handleCloseSuccessEvent() {
    this.isSuccess = false;
    this.hasError = false;
  }

  resetProducerForm() {
    // this.producerUserForm.reset();
    this.producerUserForm.get('brokerId').setValue(null);
    this.producerUserForm.get('branchId').setValue(null);
    this.producerUserForm.get('producerId').setValue(null);
    this.producerUserForm.get('roleId').setValue(null);
    this.producerUserForm.get('loginEmail').setValue(null);
    this.producerUserForm.get('password').setValue(null);

    this.broker = '';
    this.branch = '';
    this.producer = '';
    this.role = '';
  }

  onSubmit(data) {
    this.handleCloseSuccessEvent();
    this.formSubmitted = true;
    this.hasError = false;
    // check boxx user form is invalid
    if (this.producerUserForm.invalid) {
      return;
    }
    this.showSpinner = true;

    this.systemUser.CreateSystemUser(data).subscribe(
      (response) => {
        this.showSpinner = false;
        this.isSuccess = true;
        this.formSubmitted = false;
        this.resetProducerForm();
      },
      ({ error }) => {
        this.showSpinner = false;
        this.hasError = true;
        this.errorMessage = error.message ? error.message : error.error;
      },
    );
  }

  searchRoles(firstName) {
    // reset form group's boxxUserId value
    this.producerUserForm.get('roleId').setValue(null);
    this.invalidRole = '';
    if (firstName.length >= 3) {
      this.roleService
        .GetByNameNsystemUserType(firstName, this.systemUserType)
        .subscribe({
          next: (response) => {
            this.setRoleSearchResult(response, firstName);
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    } else {
      this.roles = [];
    }
  }

  setRoleSearchResult(data: BoxxResponse<Role>, name = '') {
    const tableData = data.data?.map((dataObj) => ({
      key: `${dataObj.name}`,
      value: `${dataObj.name}`,
      id: dataObj.id,
    }));
    this.roles = [...tableData];
    this.tRoles = arrayToObjet(data.data, 'id');
    if (tableData.length > 0) {
      this.invalidRole = 'systemUser.error.invalidRole';
    } else {
      this.invalidRole = '';
    }
  }

  handleCloseSuccessEventExt() {
    this.showErrorAlert = false;
  }
}
