<ng-container *ngIf="showBodySpinner">
  <div class="body-spinner">
    <boxxds-spinner
      [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
    ></boxxds-spinner>
  </div>
</ng-container>
<ng-container *ngIf="!showBodySpinner">
  <div
    class="marketReservation-outer-container"
    [ngStyle]="{
      padding: this.quoteFlowSteps === '7' ? '0' : '0 211px 0 114px'
    }"
  >
    <div class="holder">
      <boxxds-heading
        sz="5"
        [customStyle]="{ marginBottom: '12px' }"
        [text]="'submission.newSubmission' | translate"
        ><span></span>
      </boxxds-heading>
      <div class="insured-name">{{ (form$ | async)?.ui.insuredName }}</div>
      <div class="content">
        <div class="heading-mb-6">
          {{ "submission.marketReservation" | translate }}
        </div>
        <boxxds-body
          [text]="'submission.marketReservationContent' | translate"
          sz="sm"
          [customStyle]="{ color: '#616162', width: '585px', height: '78px' }"
        >
        </boxxds-body>
        <div class="redirect-quote">
          <boxxds-link
            *ngIf="redirectPath"
            [url]="redirectPath"
            [name]="
              (isReferral
                ? 'submission.existingQuoteReferral'
                : 'submission.existingQuote'
              ) | translate
            "
            [linkStyle]="{ cursor: 'pointer' }"
          ></boxxds-link>
        </div>
      </div>
      <boxxds-button
        [buttonText]="'Back' | translate"
        btnType="secondary"
        [btnStyle]="{ width: '88px' }"
        type="submit"
        (handleClick)="handleBackBtn()"
        class="back-btn"
      >
      </boxxds-button>
    </div>
  </div>
</ng-container>
