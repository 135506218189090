<div class="branch-container">
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>

  <!-- (handleClose) = "handleCloseFilter($event)") -->
  <div class="filter">
    <app-dynamic-filter-v2-modal
      *ngIf="showFilter"
      [filterTitle]="'workFlow3.branchProfile.label.filter' | translate"
      [filterTitleDescription]="
        'workFlow3.branchProfile.label.filterDesc' | translate
      "
      [filterInputOptions]="filterInputOptions"
      (handleSubmit)="handleFilter($event)"
      [filterFormGroup]="filterFormGroup"
      (handleFilterClear)="handleFilterClear($event)"
    >
    </app-dynamic-filter-v2-modal>
  </div>

  <div class="branch-forms" *ngIf="!showBodySpinner">
    <div class="new-branch-form" *ngIf="showProducerForm">
      <app-add-producer
        [form]="producer"
        [branchId]="branchId"
        [brokerageId]="brokerageId"
        [addProducer]="addProducer"
        [isActive]="true"
        [requiredErrMsg]="requiredErrMsg"
        [invalidErrMsg]="invalidErrMsg"
        [formSubmitted]="submitted"
        (handleDiscard)="handleDiscard($event)"
        (handleSaveChanges)="handleSaveChanges($event)"
      ></app-add-producer>
    </div>
  </div>
  <!-- Table Listing  -->
  <div class="branch-listing" *ngIf="!showBodySpinner">
    <div class="list-nav">
      <div class="list-head">
        <div class="main-heading" [ngClass]="{ 'search-results': mainHeading }">
          <boxxds-heading
            [sz]="'5'"
            [text]="mainHeading"
            type="bold"
          ></boxxds-heading>
          <div class="badge-heading">
            <span>{{ totalRecords + " Records" }}</span>
          </div>
        </div>
      </div>

      <div class="nav-actions">
        <div class="filter-optns-container">
          <div class="filter-optn" *ngFor="let filter of getFilteredValues()">
            <span class="option-name">{{ filter.value }}</span>
            <button
              type="button"
              aria-label="close"
              class="close-btn"
              (click)="handleFilterOptnRemove(filter.key)"
            >
              <img src="assets/img/dashboard/quote/remove.png" />
            </button>
          </div>
        </div>
        <div class="search">
          <boxxds-textinput-v2
            [id]="'search'"
            [placeholder]="placeholder"
            [width]="'100%'"
            [containerStyle]="{ maxWidth: '200px', marginBottom: '10px' }"
            class="search"
            [requiredErrMsg]="
              'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                | translate
            "
            [invalidErrMsg]="
              'workFlow3.components.newSubmission.error.invalidErrMsg'
                | translate
            "
            [formSubmitted]="true"
            maxLength="100"
            [form]="searchForm"
            [control]="'searchKey'"
            [readonly]="!this.permissionList[this.currentScreen]"
            [leftIcon]="leftIcon"
            (handleKeyUp)="onBranchSearch($event)"
          >
          </boxxds-textinput-v2>
        </div>
        <div class="filter">
          <boxxds-button-v2
            [isBtnActive]="showFilter"
            [leftImage]="'assets/img/workflow3/filter-funnel.svg'"
            btnType="unfilled"
            [btnClass]="showFilter ? 'secondary' : 'secondary gray'"
            [btnStyle]="{ width: '36px', background: '#fff', height: '36px' }"
            (handleClick)="filterHandler()"
          >
            <img src="assets/img/workflow3/filter-funnel.svg" />
          </boxxds-button-v2>
          <boxxds-button-v2
            sz="sm"
            [isBtnActive]="showFilter"
            [btnClass]="'secondary gray'"
            [buttonText]="''"
            [leftImage]="'download-01'"
            [type]="'submitted'"
            [btnStyle]="{
              width: '36px',
              margin: '0px 0px 0 16px',
              height: '36px'
            }"
            (handleClick)="handleExport()"
          >
          </boxxds-button-v2>
        </div>
        <div
          class="button-holder"
          *ngIf="showAddButton && !showProducerForm && isBranchActive"
        >
          <boxxds-button-v2
            [size]="'lg'"
            [buttonText]="btnLabel"
            [btnClass]="'secondary'"
            [leftImage]="btnLeftImage"
            [isDisabled]="isActiveBranch === false"
            [rightImageStyle]="{ marginLeft: '5px' }"
            class="start-quote"
            [btnStyle]="{
              margin: '0px 0px 0 8px',
              height: '36px'
            }"
            (handleClick)="handleAddBranchButton($event)"
          >
          </boxxds-button-v2>
        </div>
      </div>
    </div>
    <div class="listing-table">
      <boxxds-table-v2
        id="branch-table"
        class="branch-table"
        [tableData]="data"
        [columnsToHide]="columnsToHide"
        [sortableColumns]="sortableColumns"
        [iconColumns]="iconColumns"
        (sortHandler)="handleTableColSort($event)"
        (columnClickHandler)="($event)"
        [showPopOver]="true"
        [isRowClickable]="true"
        [columnHeadsToHide]="columnHeadsToHide"
        [totalCount]="pagination.totalRecords"
        (pageChanged)="pageChangeHandler($event)"
        [activePageNumber]="pagination.currentPage"
        [showSpinner]="showTblSpinner"
        (editHandler)="branchEditClickHandler($event)"
        (viewHandler)="branchViewClickHandler($event)"
        [tableContainerStyle]="{ maxHeight: '550px', minHeight: '550px' }"
        [ngClass]="{
          'pointer-default': !this.permissionList[this.currentScreen]
        }"
        [iconColumns]="iconColumns"
        [showViewIcon]="!this.permissionList[this.currentScreen]"
        [showEditIcon]="this.permissionList[this.currentScreen]"
        [multiContentColumns]="multiContentColumns"
      >
      </boxxds-table-v2>
    </div>
  </div>
</div>
