import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICommission, ICommissionModel } from 'src/app/models/commission.model';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';

import { Subject } from 'rxjs';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import { ActionsCommission } from 'src/app/pages/brokerage/new-brokerage/constants/action-commission';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-commission',
  templateUrl: './add-commission.component.html',
  styleUrls: ['./add-commission.component.less'],
})
export class AddCommissionComponent implements OnInit {
  @Input() commissionSelected?: ICommission;
  @Input() isActive: boolean;
  @Output() handleAddCommission = new EventEmitter<any>();
  @Input() resetAddFormSubject: Subject<boolean> = new Subject<boolean>();

  maxPercentage?: number = 0;
  minPercentage?: number = 0;
  defaultPercentage?: number = 0;
  productId: string = '';
  regionId: string = '';

  actionCommission: ActionsCommission;
  formCommission: FormGroup;
  isFetchingRegions: boolean = false;
  submitted: boolean = false;
  requiredErrMsg: string = 'This field is required';
  products = [];
  regions = [];

  minRangePercentage: number = 1;
  maxRangePercentage: number = 50;

  constructor(
    private fb: FormBuilder,
    private riskRegionService: RiskRegionService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.formCommission = this.fb.group({
      productId: ['', [Validators.required]],
      regionId: ['', [Validators.required]],
      maxPercentage: [
        0,
        [
          Validators.max(this.maxRangePercentage),
          Validators.min(this.minRangePercentage),
        ],
      ],
      minPercentage: [
        0,
        [
          (control: AbstractControl) =>
            Validators.max(this.maxPercentage)(control),
          Validators.max(this.maxRangePercentage),
          Validators.min(this.minRangePercentage),
        ],
      ],
      defaultPercentage: [
        0,
        [
          Validators.max(this.maxPercentage),
          Validators.min(this.minPercentage),
        ],
      ],
    });
    this.initLoadStore();
    this.resetAddFormSubject.subscribe((response) => {
      if (response) {
        this.resetForm();
      }
    });
  }

  private initLoadStore = () => {
    this.riskRegionService.getAllRisk().subscribe({
      next: (response) => {
        this.products = response.data.map((product) => {
          return { value: product.name, key: product.name, id: product.id };
        });
      },
    });
  };

  onSubmit() {
    if (!this.isActive) {
      return;
    }
    this.submitted = true;
    this.formCommission.get('maxPercentage').setValue(this.maxPercentage);
    this.formCommission.get('minPercentage').setValue(this.minPercentage);
    this.formCommission
      .get('defaultPercentage')
      .setValue(this.defaultPercentage);

    if (this.formCommission.controls['maxPercentage'].hasError('max')) {
      this.formCommission.controls['maxPercentage'].setErrors({
        message: this.translate.instant('error.maxValueValidationErrMsg'),
      });
    }

    if (this.formCommission.controls['maxPercentage'].hasError('min')) {
      this.formCommission.controls['maxPercentage'].setErrors({
        message: this.translate.instant('error.minValueValidationErrMsg'),
      });
    }

    if (this.formCommission.controls['minPercentage'].hasError('min')) {
      this.formCommission.controls['minPercentage'].setErrors({
        message: this.translate.instant('error.minValueValidationErrMsg'),
      });
    }

    if (this.formCommission.controls['minPercentage'].hasError('max')) {
      this.formCommission.controls['minPercentage'].setErrors({
        message: this.translate.instant('error.minValueGreaterErrMsg'),
      });
    }

    if (
      this.formCommission.controls['defaultPercentage'].value &&
      this.formCommission.controls['defaultPercentage'].value >=
        this.formCommission.controls['minPercentage'].value &&
      this.formCommission.controls['defaultPercentage'].value <=
        this.formCommission.controls['maxPercentage'].value
    ) {
      this.formCommission.controls['defaultPercentage'].setErrors(null);
    } else {
      this.formCommission.controls['defaultPercentage'].setErrors({
        message: this.translate.instant('error.defaultValueValidationErrMsg'),
      });
    }

    if (this.formCommission.valid) {
      const regionIdSelected = this.regions.find(
        (item) => item.value === this.regionId,
      ).id;
      const commission: ICommissionModel = {
        active: true,
        commissionMax: Number(this.maxPercentage) / 100 ?? 0,
        commissionMin: Number(this.minPercentage) / 100 ?? 0,
        commissionDefault: Number(this.defaultPercentage) / 100 ?? 0,
        riskRegionId: Number(regionIdSelected),
        type: 1,
      };
      this.handleAddCommission.emit(commission);
    }
  }

  private resetForm() {
    this.submitted = false;
    this.formCommission.reset();
    this.maxPercentage = 0;
    this.minPercentage = 0;
    this.defaultPercentage = 0;
    this.productId = '';
    this.regionId = '';
    this.regions = [];
  }

  onChangeMinPercentage(value: number) {
    this.minPercentage = value;
    this.handleMinPercentageValidate(value);
    this.handleDefaultPercentageValidate(this.defaultPercentage);
  }

  onChangeMaxPercentage(value) {
    this.maxPercentage = value;
    this.handleMaxPercentageValidate(value);
    this.handleDefaultPercentageValidate(this.defaultPercentage);
  }
  onChangeDefaultPercentage(value: number) {
    this.defaultPercentage = value;
    this.handleDefaultPercentageValidate(value);
  }

  checkDefaultPercentageValidation() {
    if (
      this.defaultPercentage >= this.minPercentage &&
      this.defaultPercentage <= this.maxPercentage
    ) {
      this.formCommission.controls['defaultPercentage'].setErrors(null);
    } else {
      this.formCommission.controls['defaultPercentage'].setErrors({
        message: this.translate.instant('error.defaultValueValidationErrMsg'),
      });
    }
  }

  handleMinPercentageValidate(event) {
    const minPercentage = event?.target?.value ? event?.target?.value : event;

    if (Number(minPercentage) >= 1) {
      this.formCommission.controls['minPercentage'].setErrors(null);
    } else {
      this.formCommission.controls['minPercentage'].setErrors({
        message: this.translate.instant('error.minValueValidationErrMsg'),
      });
    }
  }

  handleMaxPercentageValidate(event) {
    const maxPercentage = event?.target?.value ? event?.target?.value : event;

    if (Number(maxPercentage) >= 1) {
      this.formCommission.controls['maxPercentage'].setErrors(null);
    } else {
      this.formCommission.controls['maxPercentage'].setErrors({
        message: this.translate.instant('error.minValueValidationErrMsg'),
      });
    }
  }

  handleDefaultPercentageValidate(event) {
    const defaultPercentage = event?.target?.value
      ? event?.target?.value
      : event;

    if (
      defaultPercentage >= this.minPercentage &&
      defaultPercentage <= this.maxPercentage
    ) {
      this.formCommission.controls['defaultPercentage'].setErrors(null);
    } else {
      this.formCommission.controls['defaultPercentage'].setErrors({
        message: this.translate.instant('error.defaultValueValidationErrMsg'),
      });
    }
  }

  handleOnChangeProductId(event: string) {
    const productSelected = this.products.find((item) => item.value === event);
    this.productId = event;
    this.regionId = '';
    this.formCommission.get('regionId').setValue('');
    this.regions = [
      {
        value: this.translate.instant('common.selectAll'),
        key: this.translate.instant('common.selectAll'),
        id: -1,
      },
    ];
    this.riskRegionService.getRiskRegion(productSelected.id).subscribe({
      next: (response) => {
        const regions = response.data.map((item) => {
          return {
            value: item.region.name,
            key: item.region.name,
            id: item.id,
          };
        });
        this.regions = [...this.regions, ...regions];
      },
    });
  }

  handleOnChangeRegionId(event: string) {
    this.regionId = event;
  }

  handleOnSaveChanges() {
    this.onSubmit();
  }
}
