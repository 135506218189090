<div class="policies-container">
  <app-navigation-v2
    [showHeader]="false"
    [showHeaderBanner]="true"
    (suggestedSearchSelected)="handleSearch($event)"
    [items]="itemsMenu"
    (buttonClicked)="handleStartNewQuote()"
    [isNeeded]="true"
    [subHeading]="''"
    [badgeData]="''"
    [bannerStyle]="{ paddingBottom: '34px' }"
  ></app-navigation-v2>

  <div class="policy-directory-holder">
    <!-- Policy directory -->

    <!-- Filter modal -->
    <app-dynamic-filter-v2-modal
      *ngIf="showFilter"
      (handleClose)="handleCloseFilterPopup($event)"
      [filterInputOptions]="filterInputOptions"
      [filterFormGroup]="filterForm"
      [filterTitle]="'Filter Policy'"
      [filterTitleDescription]="
        'Apply filters to find the policy you are looking for.'
      "
      (handleSubmit)="handleFiltering($event)"
      (handleSearch)="dynamicSearchFilter($event)"
      (handleLoadMore)="dynamicLoadMore($event)"
      (handleFilterClear)="handleFilterClear($event)"
    >
    </app-dynamic-filter-v2-modal>
    <!-- Filter modal -->

    <div class="policy-directory">
      <!-- policy Navigation -->
      <div class="directory-nav">
        <div class="nav-items">
          <ng-container *ngFor="let nav of subNavData; let i = index">
            <div class="nav-item active" [ngClass]="{ active: nav.active }">
              <button
                class="nav-label"
                [ngClass]="{ active: nav.active }"
                (click)="handleNav($event, i)"
              >
                {{ nav.name }}
              </button>
            </div>
          </ng-container>
        </div>
        <div></div>
      </div>

      <!-- Policy Table -->
      <div class="policy-table">
        <div class="table-header-container">
          <div class="table-header-header">
            <h4 *ngIf="insuredSearchFilterOn" class="header-text">
              Search results: {{ searchQuery }}
              <span class="total-records">
                {{ totalDataCount + " " + "records" }}
              </span>
            </h4>
            <h4 *ngIf="!insuredSearchFilterOn" class="header-text">
              List of all the policies
            </h4>
          </div>
          <div>
            <div class="filter-container d-flex align-items-center">
              <div class="filter-optns-container">
                <div
                  class="filter-optn"
                  *ngFor="let filter of getFilteredValues()"
                >
                  <div class="option-name">{{ filter.value }}</div>
                  <button
                    type="button"
                    aria-label="close"
                    class="close-btn"
                    (click)="handleFilterOptnRemove(filter)"
                  >
                    <img src="assets/img/dashboard/quote/remove.png" />
                  </button>
                </div>
              </div>
              <boxxds-textinput-v2
                [form]="filterForm"
                [control]="'insuredName'"
                [id]="'search'"
                [placeholder]="placeholder"
                [width]="'100%'"
                [containerStyle]="{ maxWidth: '200px', marginBottom: '10px' }"
                class="search"
                [requiredErrMsg]="
                  'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                    | translate
                "
                [invalidErrMsg]="
                  'workFlow3.components.newSubmission.error.invalidErrMsg'
                    | translate
                "
                [formSubmitted]="true"
                maxLength="100"
                [readonly]="!this.permissionList[this.currentScreen]"
                [leftIcon]="leftIcon"
                (handleKeyUp)="onInsuredSearch($event)"
              >
              </boxxds-textinput-v2>
              <boxxds-button-v2
                [isBtnActive]="showFilter"
                [btnClass]="'secondary gray'"
                [buttonText]="''"
                [leftImage]="'filter-funnel-02'"
                btnType="unfilled"
                [btnStyle]="{
                  width: '36px',
                  background: '#fff',
                  height: '36px',
                  marginLeft: '10px'
                }"
                (handleClick)="handleFilter()"
              >
                <img src="assets/img/workflow3/filter-funnel.svg" />
              </boxxds-button-v2>
              <boxxds-button-v2
                sz="sm"
                [isBtnActive]="showFilter"
                [btnClass]="'secondary gray'"
                [buttonText]="''"
                [leftImage]="'download-01'"
                [type]="'submitted'"
                [btnStyle]="{
                  width: '36px',
                  margin: '0px 0px 0 16px',
                  height: '36px'
                }"
                (handleClick)="handleExport()"
              >
              </boxxds-button-v2>
            </div>
          </div>
        </div>
        <boxxds-table-v3
          [data]="tableData"
          [showSpinner]="showTblSpinner"
          [totalCount]="totalDataCount"
          class="table"
          [ngClass]="{
            'pointer-default': !this.permissionList[this.currentScreen]
          }"
          id="policy-table"
          [addSortTo]="addSortTo"
          [showStar]="true"
          [tableStyle]="{ marginBottom: '30px' }"
          [showStatusAsBadge]="true"
          [showBadgeFieldName]="'common.statusText' | translate"
          (starHandler)="
            this.permissionList[this.currentScreen] && starClickHandler($event)
          "
          (viewClickHandler)="policyClickHandler($event)"
          [showViewButton]="showViewButton"
          [mapStatusBadge]="statusMappingToType"
          (rowClicked)="($event)"
          [isRowClickable]="true"
          [activePageNumber]="pageNumber"
          [showWithBorder]="true"
          (pageChanged)="pageChangeHandler($event)"
          (starHandler)="
            this.permissionList[this.currentScreen] && starClickHandler($event)
          "
          (sortUpClicked)="handleTableColSortUp($event)"
          (sortDownClicked)="handleTableColSortDown($event)"
          (removeSortHandler)="removeColSort()"
          [showWithBorderFieldName]="'Risks'"
          [sensitiveDataColumns]="['Policy ID', 'Insured name']"
        >
        </boxxds-table-v3>
      </div>
    </div>
  </div>
</div>
