import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  ActivatedRoute,
} from '@angular/router';
import { Observable } from 'rxjs';
import { MaintenanceService } from '../services/maintenance.service';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceGuard implements CanActivate {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private maintenanceService: MaintenanceService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.maintenanceService
      .isMaintenanceMode()
      .then((maintenanceMode) => {
        const queryParams = this.activatedRoute.snapshot.queryParams;
        const bypassMaintenance = queryParams['bypassMaintenance'] === 'true';
        if (maintenanceMode && !bypassMaintenance) {
          return this.router.navigate(['/maintenance']);
        }
        return true;
      });
  }
}
