<div class="form-panel-title">
  <boxxds-body
    sz="lg"
    [text]="'Branch details'"
    [customStyle]="{ color: '#616162', textAlign: 'left' }"
  >
  </boxxds-body>

  <boxxds-body
    sz="xs"
    [text]="'*REQUIRED'"
    [customStyle]="{ color: '#616162', textAlign: 'right' }"
  >
  </boxxds-body>
</div>

<div class="form-panel">
  <div>
    <boxxds-textinput
      [readonly]="!this.permissionList[this.currentScreen] || !isActive"
      [form]="form"
      [placeholder]="'Enter full name'"
      [control]="'name'"
      [id]="'name'"
      [width]="'100%'"
      [label]="'Name*'"
      [isRequired]="true"
      [requiredErrMsg]="requiredErrMsg"
      [invalidErrMsg]="'This field is invalid'"
      [formSubmitted]="formSubmitted"
      [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
    >
    </boxxds-textinput>
  </div>

  <div>
    <boxxds-textinput
      [readonly]="!this.permissionList[this.currentScreen] || !isActive"
      [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
      [form]="form"
      [placeholder]="'Enter email'"
      [control]="'email'"
      [id]="'email'"
      [width]="'100%'"
      [label]="'Email address'"
      [requiredErrMsg]="requiredErrMsg"
      [isRequired]="false"
      [invalidErrMsg]="'Enter a valid email'"
      [formSubmitted]="formSubmitted"
    >
    </boxxds-textinput>
  </div>

  <div>
    <boxxds-textinput
      [readonly]="!this.permissionList[this.currentScreen] || !isActive"
      [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
      [form]="form"
      [mask]="'(000) 000-0000'"
      [isRequired]="false"
      [placeholder]="'Enter phone number'"
      [control]="'phone'"
      [id]="'phoneNumber'"
      [width]="'100%'"
      [label]="'Phone number'"
      [requiredErrMsg]="requiredErrMsg"
      [invalidErrMsg]="'This field is invalid'"
      [formSubmitted]="formSubmitted"
    >
    </boxxds-textinput>
  </div>
  <div></div>
</div>
