import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BrokerageBranch } from 'src/app/models/brokergeBranch';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { BaseService } from '../base-services';
@Injectable({
  providedIn: 'root',
})
export class BrokerageBranchService extends BaseService<any> {
  constructor(http: HttpClient) {
    const baseurl =
      environment.msBrokerBaseUrl + environment.brokerageBranchPathUrl;
    super(http, baseurl);
  }

  addBranch(model: BrokerageBranch): Observable<any> {
    const url = `${this.baseurl}`;

    return this.http.post(url, model);
  }

  updateBranch(model: BrokerageBranch, branchId: number): Observable<any> {
    const url = `${this.baseurl}${branchId}`;

    return this.http
      .put(url, model)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  getBranchById(branchId: number): Observable<any> {
    const url = `${this.baseurl}${branchId}`;

    return this.http.get(url);
  }

  deleteBranch(branchId: number) {
    const url = `${this.baseurl}${branchId}`;

    return this.http
      .delete<BrokerageBranch>(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  restoreBranch(branchId: number) {
    const url = `${this.baseurl}restore/${branchId}`;

    return this.http.put(url, null);
  }
}
