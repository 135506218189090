import { ICommission } from 'src/app/models/commission.model';
import * as actionTypes from './commission.action';

export interface CommissionState {
  commissions: ICommission[];
  isFetching: boolean;
  isFetchingRegionAddCommission: boolean;
  isFetchingRegionUpdateCommission: boolean;
}

const initialState: CommissionState = {
  commissions: [],
  isFetching: false,
  isFetchingRegionAddCommission: false,
  isFetchingRegionUpdateCommission: false,
};

export function createCommissionReducer(
  state: CommissionState = initialState,
  action: any,
): any {
  switch (action.type) {
    case actionTypes.CREATE_COMMISSION:
      return {
        ...state,
        commissions: [...state.commissions, action.payload],
      };

    case actionTypes.UPDATE_COMMISSION:
      const updateCommissionsEnables = state.commissions.map((old: any) => {
        const newCommissionDesable = { ...old } as ICommission;
        if (newCommissionDesable.guiId === action.payload.item.guiId) {
          newCommissionDesable.productId = action.payload.item.productId;
          newCommissionDesable.productIdSelected =
            action.payload.item.productIdSelected;
          newCommissionDesable.regionId = action.payload.item.regionId;
          newCommissionDesable.regionIdSelected =
            action.payload.item.regionIdSelected;
          newCommissionDesable.maxPercentage =
            action.payload.item.maxPercentage;
          newCommissionDesable.minPercentage =
            action.payload.item.minPercentage;
          newCommissionDesable.status = null;
          newCommissionDesable.updated = true;
        }
        return newCommissionDesable as ICommission;
      });

      return {
        ...state,
        commissions: [...updateCommissionsEnables],
      };

    case actionTypes.DESABLE_COMMISSION:
      const newCommissions = state.commissions.map((old: any) => {
        const newCommission = { ...old };
        if (newCommission.guiId === action.payload.guiId) {
          newCommission.active = false;
          newCommission.updated = true;
        }
        return newCommission as ICommission;
      });

      return {
        ...state,
        commissions: [...newCommissions],
      };

    case actionTypes.ENABLE_COMMISSION:
      const newCommissionsEnables = state.commissions.map((old: any) => {
        const newCommissionDesable = { ...old };
        if (newCommissionDesable.guiId === action.payload.guiId) {
          newCommissionDesable.active = !newCommissionDesable.active;
          newCommissionDesable.updated = true;
        }
        return newCommissionDesable as ICommission;
      });

      return {
        ...state,
        commissions: [...newCommissionsEnables],
      };

    case actionTypes.UPDATE_STATE_COMMISSION:
      const commissionsStatus = state.commissions.map((old: ICommission) => {
        const newCommissionDesable = { ...old };

        if (newCommissionDesable.guiId === action.payload.guId) {
          newCommissionDesable.status = action.payload.state;
        }

        return newCommissionDesable as ICommission;
      });

      return {
        ...state,
        commissions: [...commissionsStatus],
      };

    case actionTypes.SET_FETCH_REGION_ADD_COMMISSION_LOADER:
      return {
        ...state,
        isFetchingRegionAddCommisssion: action.payload.isFetching,
      };

    case actionTypes.SET_FETCH_REGION_UPDATE_COMMISSION_LOADER:
      return {
        ...state,
        isFetchingRegionUpdateCommisssion: action.payload.isFetching,
      };

    case actionTypes.SET_FETCH_COMMISSION_SUCCESS:
      return {
        ...state,
        commissions: [...action.payload.items],
      };

    case actionTypes.RESET_COMMISSIONS:
      return {
        ...state,
        commissions: [],
      };

    default:
      return state;
  }
}
