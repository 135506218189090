<div class="holder">
  <boxxds-alert
    *ngIf="showBanner"
    type="error"
    [headerText]="'common.requiredAlertHeaderText' | translate"
    [bodyText]="bannerMsg | translate"
    (handleCloseEvent)="handleNotificationAlertClose()"
  ></boxxds-alert>

  <ng-container *ngIf="!childSpinnerChanged && !showBodySpinner">
    <boxxds-heading
      sz="5"
      [customStyle]="{ marginBottom: '20px' }"
      [text]="'submission.newSubmission' | translate"
      ><span></span>
    </boxxds-heading>
    <boxxds-body
      sz="md"
      [text]="'submission.insuredType' | translate"
      [customStyle]="{
        color: '#616162',
        textAlign: 'left',
        marginBottom: '8px'
      }"
    ></boxxds-body>
    <button class="toggle" tab-index="0" *ngIf="form$ | async as formData">
      <span
        class="toggle-menu"
        (click)="onToggle(true, formData?.ui?.isInsuredTypeCompany)"
        [ngClass]="{ active: (form$ | async)?.ui.isInsuredTypeCompany }"
        >{{ "submission.company" | translate }}</span
      >
      <span
        class="toggle-menu"
        (click)="onToggle(false, formData?.ui?.isInsuredTypeCompany)"
        [ngClass]="{ active: !(form$ | async)?.ui.isInsuredTypeCompany }"
        >{{ "submission.individual" | translate }}</span
      >
    </button>
  </ng-container>

  <div
    *ngIf="(form$ | async)?.ui.isInsuredTypeCompany"
    class="company-container"
  >
    <!-- <boxxds-body
      sz="md"
      [text]="'submission.searchExistingCompany' | translate"
      [customStyle]="{
        color: '#616162',
        textAlign: 'left',
        marginBottom: '8px'
      }"
    ></boxxds-body> -->
    <!-- <div class="search-container">
      <form [formGroup]="form" [connectForm]="form$ | async"> -->
    <!-- <div class="search-inputs">
          <boxxds-textinput
            [form]="companySearchForm"
            [placeholder]="'submission.enterBusinessName' | translate"
            [control]="'companyBusinessName'"
            [id]="'search-businessName'"
            [width]="'100%'"
            minLength="3"
            [invalidErrMsg]="minLengthErrMsg | translate"
            [formSubmitted]="searchSubmitted"
            [requiredErrMsg]="requiredErrMsg | translate"
            [label]="'submission.searchBusinessName' | translate"
            maxLength="200"
          >
          </boxxds-textinput>
          <boxxds-textinput
            [form]="companySearchForm"
            [placeholder]="'submission.enterWebsite' | translate"
            [control]="'website'"
            [id]="'company-website'"
            [width]="'100%'"
            [invalidErrMsg]="minLengthErrMsg | translate"
            [formSubmitted]="searchSubmitted"
            minLength="3"
            [label]="'submission.searchBusinessWebsite' | translate"
            maxLength="100"
          >
          </boxxds-textinput>
          <boxxds-textinput
            [form]="companySearchForm"
            [placeholder]="'submission.searchLocation' | translate"
            [control]="'address'"
            [id]="'company-location'"
            [width]="'100%'"
            [invalidErrMsg]="minLengthErrMsg | translate"
            [formSubmitted]="searchSubmitted"
            minLength="3"
            [label]="'submission.searchAddress' | translate"
            maxLength="250"
          >
          </boxxds-textinput>
        </div> -->
    <!-- <div class="btn-container">
          <button
            type="button"
            class="clear-btn"
            (click)="clearCompanyResults()"
            *ngIf="(form$ | async)?.ui?.showCompanySearchRslt"
          >
            {{ "submission.clearResults" | translate }}
          </button>
          <boxxds-button
            [buttonText]="'submission.search' | translate"
            btnType="secondary"
            [btnStyle]="{ width: '100px' }"
            type="submit"
            (handleClick)="handleCompanySearch($event)"
          >
          </boxxds-button>
        </div> -->
    <!-- 
        <div
          class="add-company"
          *ngIf="
            (form$ | async)?.ui.isInsuredTypeCompany &&
            !(form$ | async)?.ui?.showCompanySearchRslt
          "
        >
          <div class="divider">
            <div class="line"></div>
            <div class="divider-text">{{ "or" | translate }}</div>
            <div class="line"></div>
          </div>-->
    <div class="spinner-container">
      <ng-container *ngIf="showBodySpinner">
        <div class="body-spinner">
          <boxxds-spinner
            [containerStyle]="{
              width: '112px',
              height: '112px',
              '--b': '15px'
            }"
          ></boxxds-spinner>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="!showBodySpinner">
      <!--  <button
              type="button"
              id="btn-add"
              class="add-btn"
              (click)="handleAddCompanyBtn($event)"
            >
              <img
                [src]="
                  (form$ | async)?.ui.addCompanyExpanded
                    ? 'assets/img/plus-circle-filled.png'
                    : 'assets/img/plus-circle.png'
                "
              /><span>{{ "submission.addInsuredCompany" | translate }}</span>
            </button>
            <div
              *ngIf="(form$ | async)?.ui.addCompanyExpanded"
              class="add-form"
            > -->
      <app-company-insured-form
        [form]="addCompanyForm"
        [form$]="form$"
        [checkValidation]="submitted"
        (handleContinue)="addOrUpdateCompanyInsured($event)"
        (handleErrorOrSuccess)="handleErrorOrSuccess($event)"
        (spinnerChanged)="handleSpinnerChange($event)"
        [continueButtonLabel]="'submission.continueToStep2Of7' | translate"
        [fromSubmissionFlow]="true"
        (toggleEvent)="toggleChange($event)"
      ></app-company-insured-form>
      <!-- </div> -->
    </ng-container>
    <!--  </div> -->
    <!-- <div class="search-results" *ngIf="form$ | async as formData">
          <boxxds-heading
            sz="7"
            [text]="
              ('submission.searchResults' | translate) +
              ': ' +
              getSearchKeyWords((form$ | async)?.companySearchForm)
            "
            [customStyle]="{ marginBottom: '21px' }"
            *ngIf="(form$ | async)?.ui.showCompanySearchRslt"
          >
          </boxxds-heading>
          <boxxds-table
            *ngIf="(form$ | async)?.ui.showCompanySearchRslt"
            [data]="companySearchData | async"
            [totalCount]="(pagination | async)?.totalRecords"
            (pageChanged)="companyPageChange($event)"
            class="table"
            (sortUpClicked)="handleCompanyTableColSortUp($event)"
            [activePageNumber]="activeCompanySearchPgNo"
            (sortDownClicked)="handleCompanyTableColSortDown($event)"
            (rowClicked)="handleCompanyRowClick($event, formData.insureds)"
            [isRowClickable]="true"
            id="brokerage-table"
            addCopyBtnToField="Email"
            [addSortTo]="companyColumnsToSort"
            [tableStyle]="{ marginBottom: '30px' }"
            [showSpinner]="showTblSpinner"
            [showAddBtn]="true"
            (addBtnHandler)="handleAddCompanyBtn($event)"
            [linkButtonLabel]="'submission.addInsuredCompany' | translate"
            [linkIconSrc]="linkIconSrc"
          >
          </boxxds-table>
        </div> -->
    <!-- </form>
    </div> -->
  </div>
  <!-- Individual -->
  <div
    *ngIf="!(form$ | async)?.ui.isInsuredTypeCompany"
    class="company-container"
  >
    <!-- <boxxds-body
      sz="md"
      [text]="'submission.searchExistingIndividual' | translate"
      [customStyle]="{
        color: '#616162',
        textAlign: 'left',
        marginBottom: '8px'
      }"
    ></boxxds-body> -->

    <!-- <div class="search-container">
      <form [formGroup]="form" [connectForm]="form$ | async"> -->
    <!-- <div class="search-inputs">
          <boxxds-textinput
            [form]="individualSearchForm"
            [placeholder]="'submission.enterCustomerName' | translate"
            [control]="'customerName'"
            [id]="'customerName'"
            [width]="'100%'"
            [invalidErrMsg]="minLengthErrMsg | translate"
            [formSubmitted]="searchSubmitted"
            minLength="3"
            [label]="'submission.searchCustomerName' | translate"
            maxLength="200"
          >
          </boxxds-textinput>
          <boxxds-textinput
            [form]="individualSearchForm"
            [placeholder]="'submission.enterEmail' | translate"
            [control]="'email'"
            [id]="'email'"
            [width]="'100%'"
            [invalidErrMsg]="minLengthErrMsg | translate"
            [formSubmitted]="searchSubmitted"
            minLength="3"
            [label]="'submission.searchCustomerEmail' | translate"
            maxLength="150"
          >
          </boxxds-textinput>
          <boxxds-textinput
            [form]="individualSearchForm"
            [placeholder]="'submission.searchLocation' | translate"
            [control]="'address'"
            [id]="'searchCustomerAddress'"
            [width]="'100%'"
            [invalidErrMsg]="minLengthErrMsg | translate"
            [formSubmitted]="searchSubmitted"
            minLength="3"
            [label]="'submission.searchAddress' | translate"
            maxLength="250"
          >
          </boxxds-textinput>
        </div> -->
    <!-- <div class="btn-container">
          <button
            type="button"
            class="clear-btn"
            (click)="clearIndividualResults()"
            *ngIf="(form$ | async)?.ui.showIndividualSearchRslt"
          >
            {{ "submission.clearResults" | translate }}
          </button>
          <boxxds-button
            [buttonText]="'submission.search' | translate"
            btnType="secondary"
            [btnStyle]="{ width: '100px' }"
            type="submit"
            (handleClick)="handleIndividualSearch($event)"
          >
          </boxxds-button>
        </div> -->
    <!-- <div
          class="add-customer"
          *ngIf="
            !(
              (form$ | async)?.ui.isInsuredTypeCompany ||
              (form$ | async)?.ui.showIndividualSearchRslt
            )
          "
        > -->
    <!-- <div class="divider">
            <div class="line"></div>
            <div class="divider-text">{{ "or" | translate }}</div>
            <div class="line"></div>
          </div> -->
    <div class="spinner-container">
      <ng-container *ngIf="showBodySpinner">
        <div class="body-spinner">
          <boxxds-spinner
            [containerStyle]="{
              width: '112px',
              height: '112px',
              '--b': '15px'
            }"
          ></boxxds-spinner>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="!showBodySpinner">
      <!--  <button
              type="button"
              id="btn-add"
              class="add-btn"
              (click)="handleAddCustomerBtn($event)"
            >
              <img
                [src]="
                  (form$ | async)?.ui.addCustomerExpanded
                    ? 'assets/img/plus-circle-filled.png'
                    : 'assets/img/plus-circle.png'
                "
              /><span>{{ "submission.addNewIndividual" | translate }}</span>
            </button> -->
      <app-individual-insured-form
        [checkValidation]="submitted"
        [continueButtonLabel]="'submission.continueToStep2Of7' | translate"
        [form]="addIndividualForm"
        (handleErrorOrSuccess)="handleErrorOrSuccess($event)"
        (handleContinue)="addOrUpdateIndividualInsured($event)"
        [fromSubmissionFlow]="true"
      ></app-individual-insured-form>
    </ng-container>
    <!-- </div> -->
    <!-- <div class="search-results" *ngIf="form$ | async as formData">
          <boxxds-heading
            sz="7"
            [text]="
              ('submission.searchResults' | translate) +
              ': ' +
              getSearchKeyWords((form$ | async)?.individualSearchForm)
            "
            [customStyle]="{ marginBottom: '21px', color: '#56565C' }"
            *ngIf="(form$ | async)?.ui.showIndividualSearchRslt"
          >
          </boxxds-heading>
          <boxxds-table
            *ngIf="(form$ | async)?.ui.showIndividualSearchRslt"
            [data]="individualSearchData | async"
            [totalCount]="(pagination | async)?.totalRecords"
            (pageChanged)="customerPageChange($event)"
            class="table"
            (sortUpClicked)="handleIndividualTableColSortUp($event)"
            (sortDownClicked)="handleIndividualTableColSortDown($event)"
            (rowClicked)="handleIndividualRowClick($event, formData.insureds)"
            [isRowClickable]="true"
            id="brokerage-table"
            addCopyBtnToField="Email"
            [addSortTo]="customerColumnsToSort"
            [showSpinner]="showTblSpinner"
            [tableStyle]="{ marginBottom: '30px' }"
            [activePageNumber]="activeIndividualSearchPgNo"
            [showAddBtn]="true"
            (addBtnHandler)="handleAddCustomerBtn($event)"
            [linkButtonLabel]="'submission.addNewIndividual' | translate"
            [linkIconSrc]="linkIconSrc"
          >
          </boxxds-table>
        </div> -->
    <!-- </form>
    </div> -->
  </div>
  <app-submission-recalculate-confirmation-popup
    *ngIf="showRecalcConfModal"
    [showModal]="showRecalcConfModal"
    [existingQuoteOptionData]="actionPopupDetails"
    (handleClose)="closeModalRecalculateConf()"
    (handleSubmissionRecalculate)="handleSubmissionRecalculate($event, null)"
    (handleDiscardRecalculate)="handleDiscardRecalculate($event)"
  >
  </app-submission-recalculate-confirmation-popup>
  <app-modal-sanction
    *ngIf="showSanctionModal"
    [showModal]="showSanctionModal"
    [existingQuoteOptionData]="actionPopupDetails"
    (handleClose)="closeSanctionPopup()"
    [data]="sanctionPayload"
    [isRestrictedIndustry]="isRestrictedIndustry"
    (handleProceed)="handleSanctionProceed()"
    (handleDiscard)="handleDiscardRecalculate($event)"
    (handleDeclineAndSaveChanges)="handleDeclineAndSave()"
  ></app-modal-sanction>
</div>
