<div
  class="nav-container"
  [ngStyle]="{ marginLeft: marginLeft ? marginLeft : '0px' }"
>
  <nav>
    <ul>
      <li *ngFor="let nav of navData">
        <ng-container *ngIf="permissionKeyList.includes(nav.permissionName)">
          <a
            tabindex="0"
            routerLinkActive="active"
            [routerLink]="nav.routerLink"
            skipLocationChange="true"
            >{{ nav.name }}</a
          >
          <div class="badge" *ngIf="nav.badge">
            <app-notify-badge [content]="nav.badge"></app-notify-badge>
          </div>
        </ng-container>
      </li>
    </ul>
  </nav>
</div>
