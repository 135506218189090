<div class="text-input-container">
  <boxxds-alert
    *ngIf="showErrorAlert"
    (handleCloseEvent)="handleCloseSuccessEvent()"
    type="error"
    headerText="error!"
    [bodyText]="msgErrorAlert"
  >
  </boxxds-alert>
  <label [for]="control" class="text-input-label">{{ label }}</label>
  <div
    class="input-holder"
    [ngClass]="{ 'icon-input': isLeftIcon }"
    [ngStyle]="{ 'maxWidth.px': maxWidth.length === 0 ? '280' : maxWidth }"
  >
    <div class="dropdown-container">
      <boxxds-dropdown
        [insideSearch]="true"
        [options]="dropdownOptions"
        (currentValueChange)="dropdownValueChanged($event)"
      >
      </boxxds-dropdown>
    </div>
    <img *ngIf="isLeftIcon" [src]="getIconSrc()" />
    <input
      [type]="type"
      class="text-input"
      [ngClass]="{ 'icon-input': isLeftIcon }"
      [placeholder]="placeholder"
      [id]="id"
      [required]="isRequired"
      [(ngModel)]="searchKey"
      autocomplete="off"
      (keyup)="onKeyUp($event)"
      (clickOutside)="closeSearchDropdown()"
      maxlength="255"
    />

    <img
      class="close-btn"
      src="assets/img/close.png"
      alt="close"
      (click)="clearSearchValue()"
    />

    <ul
      class="search-list"
      role="listbox"
      aria-expanded="false"
      [ngClass]="{ 'dropdown-open': searchListOpen }"
    >
      <li *ngIf="inputListHdr !== ''" class="list-header">
        {{ inputListHdr }}
      </li>
      <li
        *ngFor="let o of inputOptions"
        class="search-list-item"
        tabindex="0"
        (click)="selectSuggested(o)"
        role="option"
      >
        <div>
          <span class="suggestion">{{
            getSuggestedValue(o?.name || "")[0]
          }}</span
          ><span *ngIf="hasSuggestedResults(o?.name || '')">{{
            searchKey
          }}</span
          ><span class="suggestion">{{
            getSuggestedValue(o?.name || "")[1]
          }}</span>
        </div>
        <boxxds-body
          sz="xxs"
          [text]="o?.type"
          [customStyle]="{
            textTransform: 'uppercase',
            color: '#8F8F91',
            marginLeft: '2px'
          }"
        ></boxxds-body>
      </li>
    </ul>

    <!-- <div *ngIf="showClose" class="btn-holder"><button type="button" class="show-hide-btn"
        (click)="handleCloseBtnClick($event)" [ngStyle]="{'color':form.value[control] === '' ? '#DFDFE2' : '#A1A1A8' }"
        tabindex="0">X</button></div> -->
    <!-- <div *ngIf="form.controls[control]?.errors && form.controls[control].errors['message']" class="error-msg">
      {{form.controls[control].errors['message']}}
    </div> -->
  </div>
</div>
