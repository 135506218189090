import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-existing-quote',
  templateUrl: './modal-existing-quote.component.html',
  styleUrls: ['./modal-existing-quote.component.less'],
})
export class ModalExistingQuoteWorkFlow3Component {
  @Input() showModal: boolean = false;
  @Input() existingQuoteOptionData;

  @Output() handleClose = new EventEmitter<any>();
  @Output() seeExistingQuote = new EventEmitter<any>();

  constructor() {}

  closeModal() {
    this.handleClose.emit(true);
  }
  redirectQuote() {
    this.seeExistingQuote.emit(true);
  }
}
