import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-delete-confirmation-popup',
  templateUrl: './delete-confirmation-popup.component.html',
  styleUrls: ['./delete-confirmation-popup.component.less'],
})
export class DeleteConfirmationPopupComponent {
  @Input() details = {};
  @Input() isConfirmDisabled: boolean = false;

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleConfirm = new EventEmitter<any>();
  constructor() {}

  closeHandler() {
    this.handleClose.emit();
  }

  actionConfirmHandler() {
    this.handleConfirm.emit();
  }
}
