import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { TranslateService } from '@ngx-translate/core';
import { getLifecycleState } from 'src/app/store/lifecycle/lifecycle.select';
import { PolicyRiskTrxService } from 'src/app/services/policy-risk-trx.service';
import { take } from 'rxjs';
import {
  addSpaceBeforeCapitalLetter,
  handleApiError,
} from 'src/app/dashboard/utils/lifecycle-utils';
import { AlertService } from 'src/app/services/alert.service';
import { PolicyLifecycleService } from 'src/app/services/policy-lifecycle-service';
import { PolicyPeriodNotesService } from 'src/app/services/policy-period-notes.service';
import { checkRegexPatternNotes } from 'src/app/utils/notesUtils';
import { getAlertHead, getErrorMessage } from 'src/app/utils/utils';
import { DomainsService } from 'src/app/services/domains.service';

@Component({
  selector: 'app-plc-change-section-v2',
  templateUrl: './plc-change-section-v2.component.html',
  styleUrls: ['./plc-change-section-v2.component.less'],
})
export class PlcChangeSectionV2Component implements OnInit, OnChanges {
  @Input() policyRiskTrxId;
  @Input() details: any;
  @Input() policyPeriodId: any;
  @Input() isTrxPolicyChange: boolean = false;
  @Input() policyChangeContainerStyle: { [klass: string]: any };

  @Output() handlePolicyChangeSuccess = new EventEmitter<any>();
  @Output() handleClose = new EventEmitter<any>();
  @Output() handleExecute = new EventEmitter<any>();

  showTabSpinner: boolean = true;
  message: string = '';
  errorMsgValid;
  maxLength: number = 500;
  remainingChars: number = this.maxLength;
  trxType = 'Policy Change';
  PolicyChangeForm: FormGroup;
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  policyRiskId: any;
  policyPeriodNoteId: number;
  status;
  initialFormValue: [];
  isExecute: boolean = false;
  scopeType;
  trxTypeId;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private translate: TranslateService,
    private policyRiskTrxService: PolicyRiskTrxService,
    private policyLifecycleService: PolicyLifecycleService,
    private alertService: AlertService,
    private policyPeriodNotesService: PolicyPeriodNotesService,
    private domainsService: DomainsService,
  ) {
    this.PolicyChangeForm = this.fb.group({
      notes: [''],
      tag: [],
    });
  }

  async ngOnInit() {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
    });
    this.store
      .select(getLifecycleState)
      .pipe(take(1))
      .subscribe((state) => {
        this.policyRiskId = state?.policyRiskId;
      });
    this.showTabSpinner = false;
    this.policyChangeCheck();
    this.fetchNotesCategoryList();
    this.getPolicyRiskTrxDetails();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.policyChangeCheck();
  }

  handleSave() {
    if (this.isTrxPolicyChange == true) {
      this.updateNote();
    } else {
      this.handlePolicyChange();
    }
  }

  policyChangeCheck() {
    if (this.isTrxPolicyChange == true) {
      this.populateNotes();
    } else {
      this.clearForm();
    }
  }

  handlePolicyChange() {
    this.alertService.clearAlerts(-1);
    let note = this.PolicyChangeForm.controls['notes']?.value
      ? this.PolicyChangeForm.controls['notes']?.value?.trim()
      : '';
    if (note && note != '' && note.length >= 3) {
      let data = {
        policyRiskTrxId: this.policyRiskTrxId,
        note: note,
      };
      this.handleTrxPolicyChange(data);
    } else {
      const alertData = {
        show: true,
        type: 'error',
        headerText: 'error!',
        bodyText: 'policy.policySlideOut.errorNotes',
        wrapperStyle: { margin: '30px 0px 0px -75px' },
      };
      this.alertService.addAlert(alertData);
    }
  }

  handleTrxPolicyChange(request) {
    this.policyLifecycleService.TransactionRiskPolicyChange(request).subscribe({
      next: (response) => {
        this.handlePolicyChangeSuccess.emit(true);
        if (this.isTrxPolicyChange === false && this.isExecute === true) {
          this.handleExecute.emit('directExecute');
          this.isExecute = false;
        } else {
          const alertData = {
            show: true,
            type: 'success',
            headerText: 'success!',
            bodyText: this.translate.instant('policy.change.success.body'),
          };
          this.alertService.addAlert(alertData);
        }
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          const alertData = {
            show: true,
            type: 'error',
            headerText: 'error!',
            bodyText: handleApiError(error),
            wrapperStyle: { margin: '30px 0px 0px -75px' },
          };
          this.alertService.addAlert(alertData);
        }
      },
    });
  }

  onKeyUptextareaValidation(event) {
    this.errorMsgValid = false;
    this.remainingChars = this.maxLength - event.target.value.length;
  }

  closePolicyChange() {
    this.clearForm();
    this.handleClose.emit(true);
  }

  clearForm() {
    this.PolicyChangeForm.reset();
  }

  handleDiscard() {
    if (this.isTrxPolicyChange == true) {
      if (this.isCheckFormchange(this.initialFormValue)) {
        this.PolicyChangeForm.reset(this.initialFormValue);
        const event = {
          target: { value: this.PolicyChangeForm.controls['notes'].value },
        };
        this.onKeyUptextareaValidation(event);
      } else {
        this.showNoChangeAlert();
      }
    } else {
      if (this.PolicyChangeForm.value.notes !== null) {
        this.PolicyChangeForm.reset();
        this.remainingChars = this.maxLength;
      } else {
        this.showNoChangeAlert();
      }
    }
  }

  isCheckFormchange(initialValues): boolean {
    let formchange;
    if (
      JSON.stringify(this.PolicyChangeForm.value) ===
      JSON.stringify(initialValues)
    ) {
      formchange = false;
    } else {
      formchange = true;
    }
    return formchange;
  }

  populateNotes() {
    this.showTabSpinner = true;
    this.policyPeriodNotesService
      .getByPolicyPeriodId(this.policyPeriodId)
      .subscribe((res) => {
        this.policyPeriodNoteId = res.data[0].id;
        this.PolicyChangeForm.controls['notes'].setValue(res.data[0].note);
        const event = { target: { value: res.data[0].note } };
        this.onKeyUptextareaValidation(event);
        this.initialFormValue = this.PolicyChangeForm.value;
        this.showTabSpinner = false;
      });
  }

  updateNote() {
    if (this.initialFormValue === this.PolicyChangeForm.value) {
      this.showNoChangeAlert();
    } else {
      this.showTabSpinner = true;
      const obj = {
        note: this.PolicyChangeForm.controls['notes'].value,
      };
      this.policyPeriodNotesService
        .Update(this.policyPeriodNoteId, obj)
        .subscribe({
          next: (res) => {
            this.showTabSpinner = false;
            this.initialFormValue = this.PolicyChangeForm.value;
            const alertData = {
              show: true,
              type: 'success',
              headerText: 'success!',
              bodyText: this.translate.instant(
                'workFlow3.plc.policyChange.success.noteChanged',
              ),
            };
            this.alertService.addAlert(alertData);
          },
          error: (error) => {
            this.showTabSpinner = false;
            if (![500].includes(error?.status)) {
              const errAlert = {
                show: true,
                type: 'error',
                headerText: getAlertHead('error'),
                bodyText: getErrorMessage(error),
              };
              this.alertService.addAlert(errAlert);
            }
          },
        });
    }
  }

  createNote() {
    this.showTabSpinner = true;
    const createReq = {
      policyPeriodId: this.policyPeriodId,
      trxType: this.trxTypeId,
      scopeType: this.scopeType,
      note: checkRegexPatternNotes(
        this.PolicyChangeForm.controls['notes'].value,
      ),
    };
    this.policyPeriodNotesService.Create(createReq).subscribe({
      next: (res) => {
        this.showTabSpinner = false;
        this.initialFormValue = this.PolicyChangeForm.value;
      },
      error: (error) => {
        this.showTabSpinner = false;
        if (![500].includes(error?.status)) {
          const errAlert = {
            show: true,
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: getErrorMessage(error),
          };
          this.alertService.addAlert(errAlert);
        }
      },
    });
  }

  getScopeCategory(
    key: string,
    compareKey: string,
    list: { [x: string]: any }[],
  ): { [x: string]: any } {
    let category: { [x: string]: any } = {};
    list.forEach((item) => {
      if (item[compareKey] === key) {
        category = item;
      }
    });
    return category;
  }

  async fetchNotesCategoryList() {
    this.domainsService.GetByDomainCode('NOTESSCOPETYPE').subscribe({
      next: (response) => {
        if (response.data.length > 0) {
          const scopeCategory = response.data.map((item) => {
            return {
              value: item.description,
              key: item.description,
              id: item.id,
            };
          });
          let category = this.getScopeCategory(
            'Transaction changes and extra information',
            'key',
            scopeCategory,
          );
          this.scopeType = category['id'];
        }
      },
      error: (error) => {
        this.showTabSpinner = false;
        if (![500].includes(error?.status)) {
          const errAlert = {
            show: true,
            type: 'error',
            headerText: getAlertHead('error'),
            bodyText: getErrorMessage(error),
          };
          this.alertService.addAlert(errAlert);
        }
      },
    });
  }

  getPolicyRiskTrxDetails() {
    this.policyRiskTrxService
      .getByPolicyRiskTrxId(this.policyRiskTrxId)
      .subscribe({
        next: (response) => {
          const data = response.data;
          this.trxTypeId = data.type;
        },
        error: (error) => {
          this.showTabSpinner = false;
          if (![500].includes(error?.status)) {
            const errAlert = {
              show: true,
              type: 'error',
              headerText: getAlertHead('error'),
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(errAlert);
          }
        },
      });
  }

  handleTrxExecute() {
    this.isExecute = true;
    if (this.isTrxPolicyChange == true) {
      this.createNote();
      this.handleExecute.emit('execute');
      this.isExecute = false;
    } else {
      this.handlePolicyChange();
    }
  }

  showNoChangeAlert() {
    this.alertService.clearAlerts(-1);
    const alertData = {
      show: true,
      type: 'error',
      headerText: 'error!',
      bodyText: this.translate.instant(
        'workFlow3.plc.policyChange.error.nothingChanged',
      ),
      wrapperStyle: { margin: '30px 0px 0px -75px' },
    };
    this.alertService.addAlert(alertData);
  }
}
