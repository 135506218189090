<div class="product-info">
  <app-broker-commission-v2-modal
    *ngIf="showCommissionModal"
    [productsInfo]="productInfo"
    [regionInfo]="regionInfo"
    [showCommissionModal]="showCommissionModal"
    (handleClose)="handleClose()"
    (handleSubmit)="submitBrokerCommission($event)"
    (handleNotifMsg)="handleNotifMsg($event)"
    [brokerageId]="brokerageId"
    [insured]="insuredName"
    [brokerage]="selectedBroker"
    [commissionDisabled]="commissionDisabled"
    [commissionId]="commissionId"
  >
  </app-broker-commission-v2-modal>
  <app-brokerage-redirect-modal
    *ngIf="showBrokerRedirectModal"
    [showBrokerRedirectModal]="showBrokerRedirectModal"
    (saveInsured)="handleInsuredSave()"
    [insured]="insuredName"
    (closeModal)="handleInsuredSaveModalClose()"
  >
  </app-brokerage-redirect-modal>
  <div class="container">
    <div class="left-div">
      <p>
        {{
          "workFlow3.components.producerInformationComponent.label.producerInformation"
            | translate
        }}
      </p>
      <span>{{
        "workFlow3.components.producerInformationComponent.subHeader.searchBroker"
          | translate
      }}</span>
    </div>
    <div class="right-div">
      <div class="search-broker-container">
        <boxxds-search-autocomplete-v2
          [maxLength]="'200'"
          [form]="brokerageForm"
          [isActive]="isActive"
          [formSubmitted]="formSubmitted"
          [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
          [items]="brokerOptions"
          [label]="
            'workFlow3.components.producerInformationComponent.label.producer'
              | translate
          "
          [placeholder]="'submission.select' | translate"
          [required]="true"
          [control]="'brokerageinfo'"
          [autocapitalize]="'off'"
          [spellcheck]="'off'"
          [containerStyle]="{}"
          (searchQuery)="searchBroker($event)"
          (itemSelected)="selectBroker($event)"
          (textInputChangeEventEmmiter)="searchBroker($event)"
          (currentValueChange)="selectBroker($event)"
          (outsideClick)="dropdownFocusOut($event)"
          [currentValue]="currentBrokerValue"
          [dropdownContainerStyle]="{ width: '475.5px' }"
          [labelStyle]="{ marginBottom: '8px' }"
          [showNoResultAction]="true"
          [showNoResultRedirectButton]="true"
          [noResultRedirectButtonAction]="noResultInfo"
          [noResultContent]="noResultContent"
          (handleNoResultButtonClick)="noResultButtonclick($event)"
          [permissionStatus]="this.permissionList[this.currentScreen]"
          class="search-dropdown"
        ></boxxds-search-autocomplete-v2>

        <boxxds-notification-v2
          *ngIf="
            (isSubmission && productId && noCommission) ||
            (!isSubmission && noCommission)
          "
          [alertType]="alertInfo.alertType"
          [alertWidth]="'lg'"
          [alertHeader]="alertInfo.alertHeader"
          [alertDescription]="alertInfo.alertText"
          [contentContainerStyle]="{ maxWidth: '68%' }"
          [linkContainerStyle]="{ maxWidth: '30%' }"
          [linkText]="alertInfo.alertLinkText"
          (handleLinkClick)="handleLinkAction()"
          class="notification-section"
          [alertContainerStyle]="{ position: 'relative' }"
        >
        </boxxds-notification-v2>
      </div>
    </div>
  </div>
</div>
