import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Pagination } from 'src/app/entities/boxx-response';
import { Commission } from 'src/app/entities/commission';
import { AlertService } from 'src/app/services/alert.service';
import { BrokerageCommissionService } from 'src/app/services/brokerage-commissions.service';
import { BrokerageProducerCommissionService } from 'src/app/services/brokerage-producer-commission.service';
import { BrokerageProducerService } from 'src/app/services/brokerage-producer.service';
import { getFormatDate } from 'src/app/utils/formatDate';
import { getErrorMessage, makeSorting } from 'src/app/utils/utils';

@Component({
  selector: 'app-commission-rate-workflow3',
  templateUrl: './commission-rate-workflow3.component.html',
  styleUrls: ['./commission-rate-workflow3.component.less'],
})
export class CommissionRateWorkflow3Component implements OnInit {
  @Input() brokerageId;
  @Input() producerId;
  @Input() type;
  @Input() isProducerActive: boolean = true;
  @Input() isBrokerActive: boolean = true;

  existingQuoteOptionData: any;
  totalCount;
  showCommissionchangePopup: boolean = false;
  showBodySpinner: boolean = false;
  showTblSpinner: boolean = false;
  tableData = [];
  tableHeaders = [
    {
      Product: '',
      State: '',
      'Min.range %': '',
      'Max.range %': '',
      'Default %': '',
      Active: '',
      id: '',
      riskRegionId: '',
      regionId: '',
      riskId: '',
      '': '',
    },
  ];
  columnsToHide: string[] = [
    'Active',
    'id',
    'riskRegionId',
    'regionId',
    'riskId',
  ];
  columnHeadsToHide: string[] = [
    'Active',
    'id',
    'riskRegionId',
    'regionId',
    'riskId',
  ];
  pagination: Pagination;
  brokerageCommissions: { data: Commission[]; pagination: Pagination | null } =
    { data: [], pagination: null };
  showErrorAlert = false;
  msgErrorAlert = '';
  pageNumber: number = 1;
  colQuoteHeader = {
    Product: [{ key: 'product', order: '' }],
  };
  sortingKeys;
  addSortTo: string[] = ['Product'];
  totalDataCount = 0;
  isActive: boolean = true;
  editColumns: any = [];
  currentPageNumber: number;
  quoteList = [];
  commissionId: number;
  commissionAction;
  commissionData: any;
  isProducer: boolean = false;

  constructor(
    private brokerageCommissionService: BrokerageCommissionService,
    private brokerageProducerCommissionService: BrokerageProducerCommissionService,
    private translate: TranslateService,
    private alertService: AlertService,
    private brokerageProducerService: BrokerageProducerService,
  ) {
    this.editColumns = [
      { column: 'Product', type: 'dropdown' },
      { column: 'State', type: 'dropdown' },
      { column: 'Min.range %', type: 'stepper-input' },
      { column: 'Max.range %', type: 'stepper-input' },
      { column: 'Default %', type: 'stepper-input' },
    ];
  }

  ngOnInit(): void {
    if (this.brokerageId && this.type === 'Brokerage') {
      this.getAllBrokerageCommission();
    } else if (this.producerId && this.type === 'Producer') {
      this.isProducer = true;

      this.brokerageProducerService
        .GetProducerProfile(this.producerId)
        .subscribe({
          next: (response) => {
            this.existingQuoteOptionData = {
              brokerageName:
                response?.data?.brokerageProducer?.brokerageBranch?.brokerage
                  ?.name ?? '',
              branchName:
                response?.data?.brokerageProducer?.brokerageBranch?.name ?? '',
              producerId: this.producerId,
              type: this.type,
            };
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
      this.getAllProducerCommission();
    }
  }

  private getAllBrokerageCommission(pageNumber: number = 1) {
    this.showBodySpinner = true;
    this.brokerageCommissionService
      .GetAllCommissions({ brokerageId: this.brokerageId }, pageNumber)
      .subscribe({
        next: (response) => {
          this.showBodySpinner = false;
          if (response && response.data) {
            this.existingQuoteOptionData = {
              brokerageName: response.data[0].brokerage.name,
              brokerageId: this.brokerageId,
              type: this.type,
            };

            this.tableData = response.data.map((commissionItem) => {
              const commissionMinFormatted: number = Number(
                (
                  (commissionItem.commissionMin
                    ? commissionItem.commissionMin
                    : 0) * 100
                ).toFixed(2),
              );
              const commissionMaxFormatted: number = Number(
                (
                  (commissionItem.commissionMax
                    ? commissionItem.commissionMax
                    : 0) * 100
                ).toFixed(2),
              );
              const commissionDefaultFormatted: number = Number(
                (
                  (commissionItem.commissionDefault
                    ? commissionItem.commissionDefault
                    : 0) * 100
                ).toFixed(2),
              );

              return {
                Product: commissionItem?.riskRegion?.risk?.name,
                State: commissionItem?.riskRegion?.region?.name,
                'Min.range %': commissionMinFormatted.toString(),
                'Max.range %': commissionMaxFormatted.toString(),
                'Default %': commissionDefaultFormatted.toString(),
                Active: commissionItem?.active,
                id: commissionItem?.id,
                riskRegionId: commissionItem?.riskRegion?.id,
                regionId: commissionItem?.riskRegion?.region?.id,
                riskId: commissionItem?.riskRegion?.risk?.id,
              };
            });
            this.totalDataCount = response.pagination.totalRecords;
            this.pagination = response.pagination;
            this.pageNumber = response.pagination.currentPage;
          }
        },
        error: (error) => {
          this.showBodySpinner = false;
          if (![500].includes(error?.status)) {
            let alertData = {
              type: 'error',
              headerText: 'error!',
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
          }
        },
      });
  }

  getAllProducerCommission(pageNumber: number = 1) {
    this.showBodySpinner = true;
    this.brokerageProducerCommissionService
      .GetAllBrokerageProducerCommission(
        { brokerageProducerId: this.producerId },
        pageNumber,
      )
      .subscribe({
        next: (response) => {
          this.showBodySpinner = false;
          if (response && response.data) {
            this.tableData = response.data.map((commissionItem) => {
              const commissionMinFormatted: number = Number(
                (
                  (commissionItem.commissionMin
                    ? commissionItem.commissionMin
                    : 0) * 100
                ).toFixed(2),
              );
              const commissionMaxFormatted: number = Number(
                (
                  (commissionItem.commissionMax
                    ? commissionItem.commissionMax
                    : 0) * 100
                ).toFixed(2),
              );
              const commissionDefaultFormatted: number = Number(
                (
                  (commissionItem.commissionDefault
                    ? commissionItem.commissionDefault
                    : 0) * 100
                ).toFixed(2),
              );

              return {
                Product: commissionItem?.riskRegion?.risk?.name,
                State: commissionItem?.riskRegion?.region?.name,
                'Min.range %': commissionMinFormatted.toString(),
                'Max.range %': commissionMaxFormatted.toString(),
                'Default %': commissionDefaultFormatted.toString(),
                Active: commissionItem?.active,
                id: commissionItem?.id,
                riskRegionId: commissionItem?.riskRegion?.id,
                regionId: commissionItem?.riskRegion?.region?.id,
                riskId: commissionItem?.riskRegion?.risk?.id,
              };
            });
            this.totalDataCount = response.pagination.totalRecords;
            this.pagination = response.pagination;
            this.pageNumber = response.pagination.currentPage;
          }
        },
        error: (error) => {
          this.showBodySpinner = false;
          if (![500].includes(error?.status)) {
            let alertData = {
              type: 'error',
              headerText: 'error!',
              bodyText: getErrorMessage(error),
            };
            this.alertService.addAlert(alertData);
          }
        },
      });
  }

  handleCommissionPageChange(pageNumber: number) {
    this.currentPageNumber = pageNumber;
    if (this.type === 'Brokerage') {
      this.getAllBrokerageCommission(pageNumber);
    } else if (this.type === 'Producer') {
      this.getAllProducerCommission(pageNumber);
    }
  }

  async handleEnableDisable(
    event,
    isCommissionChange = false,
    isDeclineQuote = false,
  ) {
    this.commissionAction = 'disable';
    const { commission, index } = event;
    if (commission) {
      this.alertService.clearAlerts(-1);
      this.showBodySpinner = true;
      if (commission.Active === true) {
        let queryParams = {};
        if (isCommissionChange) {
          commission.isUpdateQuote = 1;
          queryParams['isUpdateQuote'] = commission.isUpdateQuote;
        } else if (isDeclineQuote) {
          commission.isDeclineQuote = 1;
          queryParams['isDeclineQuote'] = commission.isDeclineQuote;
        }
        // disable
        if (this.type === 'Brokerage') {
          this.brokerageCommissionService
            .deleteCommission(commission.id, queryParams)
            .subscribe({
              next: (_) => {
                this.showBodySpinner = false;
                let alertData = {
                  type: 'success',
                  headerText: 'success!',
                  bodyText: 'Commission disabled successfully!',
                };
                this.alertService.addAlert(alertData);
                this.getAllBrokerageCommission(this.currentPageNumber);
              },
              error: (error) => {
                this.showBodySpinner = false;
                if (![500].includes(error?.status)) {
                  let alertData = {
                    type: 'error',
                    headerText: 'error!',
                    bodyText: getErrorMessage(error),
                  };
                  this.alertService.addAlert(alertData);
                }
              },
            });
        } else if (this.type === 'Producer') {
          this.brokerageProducerCommissionService
            .DeleteCommission(commission.id, queryParams)
            .subscribe({
              next: (_) => {
                this.showBodySpinner = false;
                const msg = this.translate.instant(
                  'brokerage.producer.success.commissionDisabled',
                );
                let alertData = {
                  type: 'success',
                  headerText: 'common.successMsg',
                  bodyText: msg,
                };
                this.alertService.addAlert(alertData);
                this.getAllProducerCommission(this.currentPageNumber);
              },
              error: (error) => {
                this.showBodySpinner = false;
                const msg = getErrorMessage(error);
                if (![500].includes(error?.status)) {
                  let alertData = {
                    type: 'error',
                    headerText: 'common.errorMsg',
                    bodyText: msg,
                  };
                  this.alertService.addAlert(alertData);
                }
              },
            });
        }
      } else {
        // enable
        if (this.type === 'Brokerage') {
          this.brokerageCommissionService
            .restoreCommission(commission.id)
            .subscribe({
              next: (_) => {
                this.showBodySpinner = false;
                let alertData = {
                  type: 'success',
                  headerText: 'success!',
                  bodyText: 'Commission enabled successfully!',
                };
                this.alertService.addAlert(alertData);
                this.getAllBrokerageCommission(this.currentPageNumber);
              },
              error: (error) => {
                this.showBodySpinner = false;
                if (![500].includes(error?.status)) {
                  let alertData = {
                    type: 'error',
                    headerText: 'error!',
                    bodyText: getErrorMessage(error),
                  };
                  this.alertService.addAlert(alertData);
                }
              },
            });
        } else if (this.type === 'Producer') {
          this.brokerageProducerCommissionService
            .RestoreCommission(commission.id)
            .subscribe({
              next: (_) => {
                this.showBodySpinner = false;
                const msg = this.translate.instant(
                  'brokerage.producer.success.commissionEnabled',
                );
                let alertData = {
                  type: 'success',
                  headerText: 'common.successMsg',
                  bodyText: msg,
                };
                this.alertService.addAlert(alertData);
                this.getAllProducerCommission(this.currentPageNumber);
              },
              error: (error) => {
                this.showBodySpinner = false;
                const msg = getErrorMessage(error);
                if (![500].includes(error?.status)) {
                  let alertData = {
                    type: 'error',
                    headerText: 'common.errorMsg',
                    bodyText: msg,
                  };
                  this.alertService.addAlert(alertData);
                }
              },
            });
        }
      }
    }
  }

  handleEditCommission(event) {
    const { commission, index } = event;
  }

  handleTableColSortUp(e) {
    let order = 'asc';
    this.sortingKeys = makeSorting(e, this.colQuoteHeader, order);
    this.sortingKeys = `${this.sortingKeys},id:asc`;
  }

  handleTableColSortDown(e) {
    let order = 'desc';
    this.sortingKeys = makeSorting(e, this.colQuoteHeader, order);
    this.sortingKeys = `${this.sortingKeys},id:asc`;
  }

  saveBrokerageCommission(event) {
    this.showBodySpinner = true;
    const { commission } = event;
    delete commission.active;
    this.brokerageCommissionService.createCommission(commission).subscribe({
      next: (response) => {
        this.showBodySpinner = false;
        let alertData = {
          type: 'success',
          headerText: 'success!',
          bodyText: 'Commission added successfully!',
        };
        this.alertService.addAlert(alertData);
        this.getAllBrokerageCommission();
      },
      error: (error) => {
        this.showBodySpinner = false;
        const msg = getErrorMessage(error);
        if (![500].includes(error?.status)) {
          let alertData = {
            type: 'error',
            headerText: 'common.errorMsg',
            bodyText: msg,
          };
          this.alertService.addAlert(alertData);
        }
      },
    });
  }

  async updateBrokerageCommission(
    event,
    isCommissionChange = false,
    isDeclineQuote = false,
  ) {
    this.showBodySpinner = true;
    const { commission, index } = event;
    if (isCommissionChange) {
      commission.isUpdateQuote = 1;
    } else if (isDeclineQuote) {
      commission.isDeclineQuote = 1;
    }
    delete commission.active;
    this.brokerageCommissionService
      .updateCommission(commission, commission.id)
      .subscribe({
        next: (response) => {
          this.showBodySpinner = false;
          let alertData = {
            type: 'success',
            headerText: 'success!',
            bodyText: 'Commission updated successfully!',
          };
          this.alertService.addAlert(alertData);
          this.getAllBrokerageCommission(this.pageNumber);
        },
        error: (error) => {
          this.showBodySpinner = false;
          const msg = getErrorMessage(error);
          if (![500].includes(error?.status)) {
            let alertData = {
              type: 'error',
              headerText: 'common.errorMsg',
              bodyText: msg,
            };
            this.alertService.addAlert(alertData);
          }
        },
      });
  }

  saveProducerCommission(event) {
    this.showBodySpinner = true;
    const { commission } = event;
    delete commission.active;
    this.brokerageProducerCommissionService
      .CreateCommission(commission)
      .subscribe({
        next: (response) => {
          this.showBodySpinner = false;
          let alertData = {
            type: 'success',
            headerText: 'success!',
            bodyText: 'Commission added successfully!',
          };
          this.alertService.addAlert(alertData);
          this.getAllProducerCommission();
        },
        error: (error) => {
          this.showBodySpinner = false;
          const msg = getErrorMessage(error);
          if (![500].includes(error?.status)) {
            let alertData = {
              type: 'error',
              headerText: 'common.errorMsg',
              bodyText: msg,
            };
            this.alertService.addAlert(alertData);
          }
        },
      });
  }

  async updateProducerCommission(
    event,
    isCommissionChange = false,
    isDeclineQuote = false,
  ) {
    this.showBodySpinner = true;
    const { commission } = event;
    if (isCommissionChange) {
      commission.isUpdateQuote = 1;
    } else if (isDeclineQuote) {
      commission.isDeclineQuote = 1;
    }
    delete commission.active;
    this.brokerageProducerCommissionService
      .UpdateCommission(commission, commission.id)
      .subscribe({
        next: (response) => {
          this.showBodySpinner = false;
          let alertData = {
            type: 'success',
            headerText: 'success!',
            bodyText: 'Commission updated successfully!',
          };
          this.alertService.addAlert(alertData);
          this.getAllProducerCommission(this.pageNumber);
        },
        error: (error) => {
          this.showBodySpinner = false;
          const msg = getErrorMessage(error);
          if (![500].includes(error?.status)) {
            let alertData = {
              type: 'error',
              headerText: 'common.errorMsg',
              bodyText: msg,
            };
            this.alertService.addAlert(alertData);
          }
        },
      });
  }
  handleCloseCommissionPopup() {
    this.showCommissionchangePopup = false;
    this.handleCommissionPageChange(this.pageNumber);
  }
  async getBrokerQuoteList(commission) {
    return new Promise<any>(async (resolve, reject) => {
      let queryParams = {};
      if (this.commissionAction === 'update') {
        if (commission.commissionMin) {
          queryParams['min'] = commission.commissionMin;
        }
        if (commission.commissionMax) {
          queryParams['max'] = commission.commissionMax;
        }
      }
      await this.brokerageCommissionService
        .GetAllActiveQuoteList(commission.id, queryParams, 1, 20)
        .subscribe({
          next: async (response) => {
            if (response?.data?.length > 0) {
              this.quoteList = response?.data?.map((item) => {
                return {
                  'Quote ID':
                    item?.policyRisk?.policyPeriod?.policy?.id.toString(),

                  'Effective date': getFormatDate(
                    item?.policyRisk?.policyPeriod?.effectiveDt,
                    'YYYY-MM-DDTHH:mm:ss.sssZ',
                    'MMM DD,YYYY',
                  ),
                  Insured:
                    item?.policyRisk?.policyPeriod?.policy?.insured
                      ?.companyName,
                };
              });
              this.totalCount = response?.pagination?.totalRecords;
              await Promise.all(this.quoteList);
              resolve(true);
            } else {
              this.quoteList = [];
              resolve(true);
            }
          },
          error: (error) => {
            reject(true);
          },
        });
    });
  }
  async getProducerQuoteList(commission) {
    return new Promise<any>(async (resolve, reject) => {
      let queryParams = {};
      if (this.commissionAction === 'update') {
        if (commission.commissionMin) {
          queryParams['min'] = commission.commissionMin;
        }
        if (commission.commissionMax) {
          queryParams['max'] = commission.commissionMax;
        }
      }

      await this.brokerageProducerCommissionService
        .GetAllActiveQuoteList(commission.id, queryParams, 1, 20)
        .subscribe({
          next: async (response) => {
            if (response?.data?.length > 0) {
              this.quoteList = response?.data?.map((item) => {
                return {
                  'Quote ID':
                    item?.policyRisk?.policyPeriod?.policy?.id.toString(),

                  'Effective date': getFormatDate(
                    item?.policyRisk?.policyPeriod?.effectiveDt,
                    'YYYY-MM-DDTHH:mm:ss.sssZ',
                    'MMM DD,YYYY',
                  ),
                  Insured:
                    item?.policyRisk?.policyPeriod?.policy?.insured
                      ?.companyName,
                };
              });
              this.totalCount = response?.pagination?.totalRecords;
              await Promise.all(this.quoteList);
              resolve(true);
            } else {
              this.quoteList = [];
              resolve(true);
            }
          },
          error: (error) => {
            reject(true);
          },
        });
    });
  }
  async handleUpdateCommission(event) {
    this.commissionAction = 'update';
    const { commission } = event;
    this.commissionData = event;
    this.commissionId = commission.id;
    await Promise.all([await this.handleCommissionChangePopup(commission)]);
    if (this.showCommissionchangePopup) {
      this.showBodySpinner = false;
    } else {
      if (this.brokerageId && this.type === 'Brokerage') {
        await this.updateBrokerageCommission(event);
      } else if (this.producerId && this.type === 'Producer') {
        await this.updateProducerCommission(event);
      }
    }
  }

  async handleCommissionChangePopup(commission) {
    this.commissionId = commission.id;
    if (this.brokerageId && this.type === 'Brokerage') {
      await Promise.all([await this.getBrokerQuoteList(commission)]);
    } else if (this.producerId && this.type === 'Producer') {
      await Promise.all([await this.getProducerQuoteList(commission)]);
    }

    this.showCommissionchangePopup = this.quoteList.length > 0 ? true : false;
  }

  async handleEnableDisableAction(event) {
    this.showBodySpinner = true;
    const { commission } = event;
    this.commissionData = event;
    if (commission.Active === true) {
      this.commissionAction = 'disable';
      await Promise.all([await this.handleCommissionChangePopup(commission)]);
      if (this.showCommissionchangePopup) {
        this.showBodySpinner = false;
      } else {
        this.handleEnableDisable(event);
      }
    } else {
      this.handleEnableDisable(event);
    }
  }
  async handleSaveCommissionChangePopup(event) {
    this.showCommissionchangePopup = false;
    switch (event.action.toLowerCase()) {
      case 'disable':
        switch (event.subAction.toLowerCase()) {
          case 'decline':
            await this.handleEnableDisable(this.commissionData, false, true);
            break;
          case 'nochange':
            await this.handleEnableDisable(this.commissionData);
            break;
          case 'updatechange':
            await this.handleEnableDisable(this.commissionData, true, false);
            break;
          case 'discard':
            this.isProducer
              ? this.getAllProducerCommission(this.pageNumber)
              : await this.getAllBrokerageCommission(this.pageNumber);
            break;
          default:
            break;
        }
        break;
      case 'update':
        switch (event.subAction.toLowerCase()) {
          case 'decline':
            this.isProducer
              ? await this.updateProducerCommission(
                  this.commissionData,
                  false,
                  true,
                )
              : await this.updateBrokerageCommission(
                  this.commissionData,
                  false,
                  true,
                );
            // Handle update decline
            break;
          case 'nochange':
            this.isProducer
              ? this.updateProducerCommission(this.commissionData)
              : await this.updateBrokerageCommission(this.commissionData);
            break;
          case 'updatechange':
            this.isProducer
              ? await this.updateProducerCommission(this.commissionData, true)
              : await this.updateBrokerageCommission(this.commissionData, true);
            break;
          case 'discard':
            this.isProducer
              ? this.getAllProducerCommission(this.pageNumber)
              : await this.getAllBrokerageCommission(this.pageNumber);
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }
}
