import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import {
  DOC_GENERATION_WAIT_TIME,
  GENERATE_DOC_SUCCESS_STATUS_CODE,
} from 'src/app/constants/quote-constant';
import {
  PolicyActionButtonsStatus,
  PolicyDetail,
} from 'src/app/dashboard/policy-lifecycle/objects/policy-lifecycle-objects';
import { KeyValueDto } from 'src/app/dtos/keyvalueList.dto';
import { Quote } from 'src/app/models/quote.model';
import { GenerateQuoteService } from 'src/app/services/doc-create.service';
import { DomainsService } from 'src/app/services/domains.service';
import { MessageSendService } from 'src/app/services/message-send.service';
import { ConfMessageTemplateService } from 'src/app/services/message-template-services';
import { PolicyRiskDocService } from 'src/app/services/policy-risk-doc.service';
import { PolicyRiskTrxNotesService } from 'src/app/services/policy-risk-trx-notes-service';
import { getQuoteSelector } from 'src/app/store/create-quote/create-quote.selector';
import { formatDate } from 'src/app/utils/formatDate';
import { isRead } from 'src/app/utils/notesUtils';
import { getErrorMessage } from 'src/app/utils/utils';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { Pagination } from 'src/app/entities/boxx-response';
import { getLifecycleState } from 'src/app/store/lifecycle/lifecycle.select';
import { InsuredService } from 'src/app/services/insured.service';
import { DocumentTemplateService } from 'src/app/services/doc-template.service';
import {
  setQuoteStatus,
  updatePolicySlideoutData,
} from 'src/app/store/lifecycle/lifecycle.action';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-lifecycle-details',
  templateUrl: './lifecycle-details.component.html',
  styleUrls: ['./lifecycle-details.component.less'],
})
export class LifecycleDetailsComponent implements OnInit, OnChanges {
  storeData$: Observable<Quote>;

  @Input() details!: PolicyDetail;
  @Input() showPeriodOptions: KeyValueDto[];
  @Input() transactionType!: string;
  @Input() notes = [];
  @Input() regionId;
  @Input() effectiveDate;
  @Input() currentProductDetails: any = {};

  @Output() handleShowPeriod = new EventEmitter();
  @Output() handleBind = new EventEmitter();
  @Output() handleRenew = new EventEmitter();
  @Output() handleCancel = new EventEmitter();
  @Output() handleReinstate = new EventEmitter();
  @Output() handleStartNextPeriod = new EventEmitter();
  showDocTempSelectionPopup: boolean = false;
  docPopupDetails: {
    statusType: string;
    action: any;
    documentTemplate: any;
    policyPeriodId: any;
    stageId: any;
  };
  bindIconSrc: string;
  renewIconSrc: string;
  cancelIconSrc: string;
  reinstateIconSrc: string;
  startNewPeriodIconSrc: string;
  sendPackageIconSrc: string;
  form: FormGroup;
  showNotesPopup: boolean = false;
  isPolicyActionBtnsDisabled: PolicyActionButtonsStatus;
  isInvoiced: boolean = false;
  showSendModal = false;
  templateDetails: { [x: string]: string | number }[] = [];
  docDetails: { [x: string]: string | number | boolean }[] = [];
  rowDocDetails: { [x: string]: string | number | boolean } = {};
  isLoader: boolean = false;
  policyPeriodId: number = null;
  newTimeout: NodeJS.Timeout;
  alertErrMsg = [];
  stageId: number;
  showErrorAlert: boolean = false;
  messageTypeId: number;
  alertMsg = '';
  // TODO: Notes tags dropdown, Where does this come from?
  // NOTE: boxxds-dropdown has the key and value backwards
  currentTagOptions: any = [];
  showInfoAlert = false;
  currentPage: number = 1;
  itemsPerPage = 10;
  totalPages: number = 1;
  notesData: { pagination: Pagination | null; filterData: {} } = {
    pagination: null,
    filterData: {},
  };
  isQuote: Boolean = false;

  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  currentQuoteStatus: string = '';
  insuredTimezone;
  insuredId;
  isPolicyInvoiced;
  namePopoverOpen = false;
  shortDateFormat: string = '';
  longDateFormat: string = '';
  policyRiskId;
  showChangeProducerPopup: boolean = false;

  constructor(
    private fb: FormBuilder,
    private policyRiskTrxNotesService: PolicyRiskTrxNotesService,
    private policyRiskDocService: PolicyRiskDocService,
    private domainsService: DomainsService,
    private generateQuoteService: GenerateQuoteService,
    private messageTemplateService: ConfMessageTemplateService,
    private store: Store,
    private messageSendService: MessageSendService,
    private insuredService: InsuredService,
    private DocumentTemplateService: DocumentTemplateService,
  ) {
    this.form = this.fb.group({
      notes: [''],
      inputNotes: [''],
      currentTag: [''],
      notesPagination: [],
    });
  }

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    this.docPopupDetails = {
      statusType: 'Document template selection',
      action: 'docTempSelection',
      documentTemplate: [],
      policyPeriodId: 0,
      stageId: 0,
    };
    this.getStageId();
    if (!this.stageId) {
      this.isPolicyActionBtnsDisabled.sendPackage = false;
    }
    this.store.pipe(select(getLifecycleState)).subscribe((data) => {
      this.currentQuoteStatus = data.quoteStatus;
    });
    this.details.header.toLowerCase() !== 'quote'
      ? this.store.dispatch(
          new setQuoteStatus(this.details.header.toLowerCase()),
        )
      : '';
    this.form.controls['notes'].setValue(this.notes);
    this.storeData$ = this.store.pipe(select(getQuoteSelector));
    if (this.details.header.toLowerCase() !== 'policy') {
      this.isQuote = true;
    }
    this.policyPeriodId =
      this.details.header.toLowerCase() !== 'policy'
        ? +this.details['id']
        : +this.details['policyPeriodId'];
    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        broker: this.details?.['brokerage'] ?? '',
        producer: this.details?.['producer'] ?? '',
        branch: this.details?.['branch'] ?? '',
        insuredName: this.details?.['insuredName'] ?? '',
        policyPeriodId:
          this.details.header.toLowerCase() === 'policy'
            ? this.policyPeriodId
            : this.details['policyPeriodId'],
        packagePolicyNumber:
          this.details.header.toLowerCase() === 'policy'
            ? this.details?.['id'] ?? ''
            : '',
        policyPeriod: this.details?.['policyPeriod'] ?? '',
        latestPolicyRiskTrxId: this.details?.['latestPolicyRiskTrxId'] ?? '',
      }),
    );
    this.isInvoiced = this.details.isInvoiced;
    this.isPolicyInvoiced = this.details.isInvoiced;
    this.currentTagOptions = this.details.currentTagOptions;
    this.insuredId = this.details.insuredId;
    this.policyRiskId = this.details?.policyRiskId;
    this.getTimeZoneOfInsured();
  }

  ngOnChanges(): void {
    this.filterNotesList(this.currentPage);
    this.isInvoiced = this.details.isInvoiced;
    this.isPolicyActionBtnsDisabled = this.isActionBtnsDisabled(
      this.details.status,
      this.isInvoiced,
    );
    this.store.dispatch(
      new updatePolicySlideoutData({
        policyRiskId: this.details?.policyRiskId,
      }),
    );
  }

  async filterNotesList(pageNumber: number) {
    let policyRiskTrxIds = this.details.policyRiskTrxIds;
    if (!policyRiskTrxIds) {
      return;
    }
    this.currentTagOptions = this.details.currentTagOptions;

    if (this.currentTagOptions?.length > 0) {
      policyRiskTrxIds = this.currentTagOptions?.filter((p) => p.value)[0].id;
    }

    const product = this.details.product;
    this.policyRiskTrxNotesService
      .getByPolicyRiskTrxId(policyRiskTrxIds, pageNumber, this.itemsPerPage)
      .subscribe({
        next: (resp) => {
          ({ pagination: this.notesData.pagination } = resp);
          const notes = resp.data.map((data) => ({
            content: data.note,
            writer: data.createdBy,
            writerPermission: data?.updatedBy ? data?.updatedBy : '',
            product: product,
            tag: data.tags,
            date: formatDate(data.createdDt, this.longDateFormat),
            id: data.id,
            updatedDt: data?.updatedDt
              ? formatDate(data.updatedDt, this.longDateFormat)
              : '',
            read: isRead(data.updatedDt ? data.updatedDt : data.createdDt),
          }));
          this.details.isUnreadNotesPresent =
            notes.filter((p) => !p.read).length > 0;

          this.form.controls['notes']?.setValue(notes);
          this.form.controls['notesPagination']?.setValue(this.notesData);
        },
        error: (e) => {},
      });

    this.currentPage = pageNumber;
    // TO DO - update during api integration
  }

  isActionBtnsDisabled(
    transactionType: string = 'renewal',
    isInvoiced: boolean,
  ) {
    const defaultIsDisabled: PolicyActionButtonsStatus = {
      bind: true,
      renew: true,
      cancel: true,
      reinstate: true,
      startNewPeriod: true,
      sendPackage: false,
    };

    switch (transactionType?.toLowerCase()) {
      case 'quoted':
      case 'quote':
      case 'referral':
        return {
          ...defaultIsDisabled,
          bind: false,
        };
      case 'renewalQuote':
        return {
          ...defaultIsDisabled,
          renew: true,
        };
      case 'bind':
      case 'bound':
      case 'renewal':
      case 'renewed':
      case 'endorsement':
      case 'reinstated':
      case 'policy change':
        let actionsObj = {
          ...defaultIsDisabled,
          startNewPeriod: false,
        };
        if (isInvoiced) {
          actionsObj = {
            ...actionsObj,
            cancel: false,
          };
        }
        return actionsObj;
      case 'cancelled':
        return {
          ...defaultIsDisabled,
          reinstate: false,
          sendPackage: true,
        };
      default:
        return defaultIsDisabled;
    }
  }

  getStatusType(status: string) {
    switch (status.toLowerCase()) {
      case 'new submissions':
      case 'reinstated':
        return 'default';
      case 'bound':
      case 'referral':
        return 'primary';
      case 'quote':
      case 'quoted':
        return 'secondary';
      case 'renewed':
      case 'renewal quote':
      case 'renewal app':
        return 'warning';
      case 'quote closed':
      case 'cancelled':
      case 'lapsed':
      case 'declined':
      case 'closed':
      case 'not taken up':
        return 'inactive';
    }
    return 'default';
  }

  showPeriodChanged(value) {
    this.handleShowPeriod.emit(value);
  }

  bindHandler(e) {
    this.handleBind.emit();
  }

  renewHandler(e) {
    this.handleRenew.emit();
  }

  cancelHandler(e) {
    this.handleCancel.emit();
  }

  reinstateHandler(e) {
    this.handleReinstate.emit();
  }

  startNextPeriodHandler(e) {
    this.handleStartNextPeriod.emit();
  }

  toggleNotesPopup(e) {
    this.showNotesPopup = !this.showNotesPopup;
    this.filterNotesList(this.currentPage);
    e.stopPropagation();
  }

  hanldeCurrentTag(value) {}

  closeSendPackage() {
    clearTimeout(this.newTimeout);
    this.closeAlert();
    this.docDetails = [];
    this.templateDetails = [];
    this.showSendModal = false;
    this.isLoader = false;
  }

  removeDuplicatesFromArray(arrayWithDuplicates) {
    return arrayWithDuplicates.filter(
      (item, index, arr) => arr.indexOf(item) === index,
    );
  }

  async getStageId() {
    this.stageId = undefined;
    let stageCode =
      this.details.header.toLowerCase() === 'quote'
        ? 'DOCMERGESTAGE_QUOTE'
        : 'DOCMERGESTAGE_BIND';
    return new Promise<void>((resolve) => {
      this.domainsService.GetByDomainCode('DOCMERGESTAGE', true).subscribe({
        next: (response) => {
          let docStage = response.data.filter(
            (template) => template.subdomaincode === stageCode,
          )[0];
          this.stageId = docStage.id;
          resolve();
        },
        error: (error) => {
          this.isLoader = false;
          this.showSendModal = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
            this.alertErrMsg.push(getErrorMessage(error));
          }
        },
      });
    });
  }

  async populateMessageType() {
    this.messageTypeId = undefined;
    let messageType;
    return new Promise<void>((resolve) => {
      this.domainsService.GetByDomainCode('MESSAGETYPE', true).subscribe({
        next: (response) => {
          if (this.details.header.toLowerCase() !== 'policy') {
            messageType = response.data.filter(
              (template) => template.subdomaincode === 'MESSAGETYPE_QUOTE',
            )[0];
          } else {
            messageType = response.data.filter(
              (template) => template.subdomaincode === 'MESSAGETYPE_POLICY',
            )[0];
          }
          this.messageTypeId = messageType.id;
          resolve();
        },
        error: (error) => {
          this.isLoader = false;
          this.showSendModal = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
            this.alertErrMsg.push(getErrorMessage(error));
          }
        },
      });
    });
  }

  async getDocument() {
    if (!this.isPolicyInvoiced) {
      this.policyRiskDocService
        .getPolicyRiskGeneratedDocCore(
          this.details['policyPeriodId'],
          this.stageId,
        )
        .subscribe({
          next: (response) => {
            let { data } = response;
            if (Object.entries(data).length === 0) return;

            if (
              !data.PolicyRiskDocument ||
              data.PolicyRiskDocument.length === 0
            )
              return;
            if (response?.data?.status === GENERATE_DOC_SUCCESS_STATUS_CODE) {
              this.docDetails = data.PolicyRiskDocument;
            } else {
              this.docDetails = [];
            }
            this.rowDocDetails = data;
          },
          error: (error) => {
            clearTimeout(this.newTimeout);
            this.isLoader = false;
            this.showSendModal = false;
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
              this.alertErrMsg.push(getErrorMessage(error));
            }
          },
        });
    } else {
      await this.policyRiskDocService
        .getPolicyRiskGeneratedDocCore(
          this.details['policyPeriodId'],
          this.stageId,
        )
        .subscribe({
          next: (docData) => {
            let files = docData?.data?.PolicyRiskDocument?.map((doc) => ({
              id: doc.id,
              documentName: this.getFileName(doc.documentPath),
              documentPath: doc.documentPath,
              documentType: doc.documentType,
            }));
            let filteredNames = files.filter((name) =>
              name.documentName.startsWith(environment.policyNumberSuffixCode),
            );
            this.docDetails = filteredNames;
          },
          error: (error) => {
            clearTimeout(this.newTimeout);
            this.isLoader = false;
            this.showSendModal = false;
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
              this.alertErrMsg.push(getErrorMessage(error));
            }
          },
        });
    }
  }

  async getMessageTemplates() {
    this.messageTemplateService
      .GetAllMessageTemplates(this.messageTypeId, this.details?.riskId)
      .subscribe({
        next: (response) => {
          this.templateDetails = response?.data ?? [];
          this.isLoader = false;
        },
        error: ({ error }) => {
          this.isLoader = false;
          this.showSendModal = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
            this.alertErrMsg.push(getErrorMessage(error));
          }
        },
      });
  }
  async handleSendQuote() {
    if (this.currentQuoteStatus == 'quote-closed') {
      this.showErrorAlert = true;
      this.alertErrMsg.push('error.noTemplateAvailable');
      return;
    }
    this.docDetails = [];
    this.docPopupDetails.stageId = this.stageId;
    this.docPopupDetails.policyPeriodId = this.details['policyPeriodId'];
    if (this.stageId) {
      this.DocumentTemplateService.getDocumentTemplateList(
        this.policyRiskId,
        this.stageId,
      ).subscribe({
        next: (response) => {
          let templateList = response?.data.filter((item) => {
            return item.templates.map(
              (template) => template.docProcessId === null,
            );
          });

          if (templateList.length !== 0) {
            this.docPopupDetails.documentTemplate = templateList;
            this.handleDocLevelAction(this.currentQuoteStatus);
          } else {
            this.handleSendPackage();
          }
        },
        error: (error) => {
          this.alertErrMsg.push(getErrorMessage(error.error));
        },
      });
    } else {
      return;
    }
  }
  async handleSendPackage() {
    this.closeAlert();
    this.docDetails = [];
    this.rowDocDetails = {};
    this.showSendModal = !this.showSendModal;
    this.isLoader = !this.isLoader;
    const interval = 5000;
    const startTime = Date.now();
    if (this.showSendModal) {
      await this.populateMessageType();
      await this.getDocument();
      if (
        this.docDetails.length === 0 &&
        this.details.header.toLowerCase() === 'quote'
      ) {
        this.generateQuoteService
          .generateQuotePdf(this.details['policyPeriodId'])
          .subscribe({
            next: async (response) => {
              while (
                Date.now() - startTime < DOC_GENERATION_WAIT_TIME &&
                this.rowDocDetails?.['status'] !== 1 &&
                this.rowDocDetails?.['status'] !== 2 &&
                (this.docDetails.length === 0 ||
                  this.rowDocDetails?.['status'] === 0)
              ) {
                await this.getDocument();
                await new Promise(
                  (resolve) =>
                    (this.newTimeout = setTimeout(resolve, interval)),
                );
              }
              if (this.docDetails.length === 0) {
                this.isLoader = false;
                this.showSendModal = false;
                this.showErrorAlert = true;
                this.alertErrMsg.push('common.docCreateFailMsg');
              } else {
                await this.getMessageTemplates();
              }
            },
            error: ({ error }) => {
              clearTimeout(this.newTimeout);
              this.isLoader = false;
              this.showSendModal = false;
              if (![500].includes(error?.status)) {
                this.showErrorAlert = true;
                this.alertErrMsg.push(getErrorMessage(error));
              }
            },
          });
      }
      this.details.header.toLowerCase() !== 'quote' &&
        (await this.getMessageTemplates());
    }
  }

  async handleSend(formData: any) {
    formData.append('merginStageId', this.stageId);
    this.messageSendService.sendMessage(formData).subscribe({
      next: (response) => {
        this.docDetails = [];
        this.templateDetails = [];
        this.showSendModal = !this.showSendModal;
      },
      error: (error) => {
        this.showSendModal = !this.showSendModal;
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
          this.alertErrMsg.push(getErrorMessage(error.error));
        }
      },
    });
  }

  closeAlert() {
    this.showNotesPopup = false;
    this.showErrorAlert = false;
  }
  handleCloseClk() {
    this.showNotesPopup = false;
  }
  getFileName(path) {
    const pathSplit = path.split('/');
    return pathSplit[pathSplit.length - 1];
  }

  async getTimeZoneOfInsured() {
    let uiData;
    let form = this.store.pipe(select(getQuoteSelector));
    form
      .pipe(take(1))
      .subscribe(
        (event) => (
          (uiData = event.ui),
          (this.insuredTimezone = event.ui?.timezone),
          (this.insuredId = this.insuredId ?? event.ui?.insuredId)
        ),
      );
    if (!this.insuredTimezone && this.insuredId) {
      await this.insuredService.GetByInsuredId(this.insuredId).subscribe({
        next: async (data) => {
          this.insuredTimezone =
            data.data.insuredLocation.timeZoneCode ?? 'America/New_York';
          this.store.dispatch(
            new CreateQuoteAction.UpdateInsuredAction({
              timezone:
                data.data?.insuredLocation.timeZoneCode ?? 'America/New_York',
            }),
          );
        },
      });
    } else {
      this.insuredTimezone = 'America/New_York';
    }
  }

  handleDocLevelAction(action) {
    this.docPopupDetails.action = action;
    this.showDocTempSelectionPopup = true;
  }
  handleSuccessDocTemplate(event) {
    this.showDocTempSelectionPopup = false;
    this.handleSendPackage();
  }
  handleNotesPopupClose($event) {
    this.showNotesPopup = false;
  }

  showChangeProducerLink() {
    if (this.currentQuoteStatus == 'quote-closed') return false;
    else if (this.isQuote === true) return true;
    else return false;
  }

  changeProducer() {
    this.showChangeProducerPopup = true;
  }
  closeChangeProducer() {
    this.showChangeProducerPopup = false;
  }

  producerChanged(event) {
    this.details['branch'] = event.branch;
    this.details['brokerage'] = event.broker;
    this.details['producer'] = event.producer;
  }
}
