<div class="branch-information">
  <div class="max-width-wrapper">
    <!-- brokerage information -->
    <form [formGroup]="form">
      <div class="autofill-container">
        <div class="autofill-label">
          <boxxds-body
            sz="md"
            [text]="
              'workFlow3.branchProfile.label.branchAndBrokerageSame' | translate
            "
            [customStyle]="{
              color: '#2D2D39',
              fontFamily: 'Gibson',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '20px'
            }"
            [isRequired]="true"
          ></boxxds-body>
          <boxxds-body
            sz="md"
            [text]="'workFlow3.branchProfile.label.weCanAutoFill' | translate"
            [customStyle]="{
              color: '#475467',
              fontFamily: 'Gibson',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px'
            }"
          ></boxxds-body>
        </div>
        <div class="autofill-radio">
          <boxxds-radio-v2
            [size]="'md'"
            [items]="autoFillOptions"
            control="autoFill"
            [form]="autoFillForm"
            [formSubmitted]="false"
            [labelContainerStyle]="{ flexDirection: 'row' }"
            [labelStyle]="{
              color: '#2D2D39',
              fontFamily: 'Gibson',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px'
            }"
            (handleChange)="handleAutoFill($event)"
          >
          </boxxds-radio-v2>
        </div>
      </div>
      <div class="branch-information-wrapper">
        <div class="form-title-wrapper">
          <h2>
            {{ "workFlow3.branchProfile.label.branchInformation" | translate }}
          </h2>
          <h6>
            {{
              "workFlow3.branchProfile.label.enterBranchInformation" | translate
            }}
          </h6>
        </div>
        <div class="form-wrapper">
          <boxxds-textinput-v2
            [form]="branch"
            [readonly]="!this.permissionList[this.currentScreen] || !isActive"
            [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
            [placeholder]="'ABC Company' | translate"
            [control]="'name'"
            [id]="'name'"
            [width]="'100%'"
            [label]="'workFlow3.branchProfile.label.name' | translate"
            [isRequired]="true"
            [requiredErrMsg]="requiredErrMsg"
            [invalidErrMsg]="invalidErrMsg"
            [formSubmitted]="submitted"
            [containerStyle]="{ marginBottom: '20px' }"
            [inputAutoFocus]="true"
            [selectedFromSuggestion]="
              isAutoFilled(branch.value['name'], autoFilledBrokerageData.name)
            "
          >
          </boxxds-textinput-v2>
          <boxxds-textinput-v2
            [form]="branch"
            [readonly]="!this.permissionList[this.currentScreen] || !isActive"
            [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
            [placeholder]="'olivia@untitledui.com' | translate"
            [control]="'email'"
            [id]="'email'"
            [width]="'100%'"
            [label]="
              'workFlow3.branchProfile.label.mainEmailAddress' | translate
            "
            [isRequired]="true"
            [requiredErrMsg]="requiredErrMsg"
            [invalidErrMsg]="invalidErrMsg"
            [formSubmitted]="submitted"
            [containerStyle]="{ marginBottom: '20px' }"
            [selectedFromSuggestion]="
              isAutoFilled(branch.value['email'], autoFilledBrokerageData.email)
            "
          >
          </boxxds-textinput-v2>
          <boxxds-textinput-v2
            [form]="branch"
            [readonly]="!this.permissionList[this.currentScreen] || !isActive"
            [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
            [mask]="'(000) 000-0000'"
            [placeholder]="'111-111-11111' | translate"
            [control]="'phone'"
            [id]="'phoneNumber'"
            [width]="'100%'"
            [label]="'workFlow3.branchProfile.label.phoneNumber' | translate"
            [isRequired]="false"
            [requiredErrMsg]="requiredErrMsg"
            [invalidErrMsg]="invalidErrMsg"
            [formSubmitted]="submitted"
            [containerStyle]="{ marginBottom: '20px' }"
            [selectedFromSuggestion]="
              isAutoFilled(branch.value['phone'], autoFilledBrokerageData.phone)
            "
          >
          </boxxds-textinput-v2>
        </div>
      </div>
      <div class="branch-information-wrapper">
        <div class="form-title-wrapper">
          <h2>
            {{ "workFlow3.branchProfile.label.branchAddress" | translate }}
          </h2>
          <h6>
            {{ "workFlow3.branchProfile.label.enterBranchAddress" | translate }}
          </h6>
        </div>
        <div class="form-wrapper">
          <app-location-details-form-v2
            (eventEmitRegionIdSelected)="regionIdSelected($event)"
            [formSubmitted]="submitted"
            [form]="location"
            [requiredErrMsg]="requiredErrMsg"
            [isActive]="this.permissionList[this.currentScreen] && isActive"
            [initialValues]="autoFilledBrokerageData.address"
            [showPrefilledStyleFromInitialValue]="true"
          >
          </app-location-details-form-v2>
        </div>
      </div>
      <div class="branch-information-wrapper button-wrapper">
        <div class="btn-wrapper">
          <boxxds-button-v2
            [isDisabled]="branchSaveClicked"
            [buttonText]="'workFlow3.branchProfile.label.discard' | translate"
            [size]="'md'"
            [btnClass]="'secondary gray'"
            [btnType]="'default'"
            [btnStyle]="{ width: '130px', background: '#fff', height: '40px' }"
            (handleClick)="handleDiscardClick($event)"
          >
          </boxxds-button-v2>
          <boxxds-button-v2
            [isDisabled]="branchSaveClicked"
            [buttonText]="
              'workFlow3.branchProfile.label.saveAddProducer' | translate
            "
            [type]="'submitted'"
            [size]="'md'"
            [btnClass]="'secondary gray'"
            [btnType]="'default'"
            [btnStyle]="{ width: '210px', background: '#fff', height: '40px' }"
            (handleClick)="handleSaveProducerClick($event)"
          >
          </boxxds-button-v2>
          <boxxds-button-v2
            [isDisabled]="branchSaveClicked"
            [buttonText]="'workFlow3.branchProfile.label.save' | translate"
            [size]="'md'"
            [btnClass]="'primary'"
            [btnType]="'default'"
            [btnStyle]="{ width: '115px', height: '40px' }"
            (handleClick)="handleSaveBranchClick($event)"
          >
          </boxxds-button-v2>
        </div>
      </div>
    </form>
  </div>
</div>
