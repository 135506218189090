import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BoxxResponse } from '../entities/boxx-response';
import { RolePermission } from '../entities/role-permission';
import { BaseService } from './base-services';
import { CognitoService } from './cognito-service';

@Injectable({
  providedIn: 'root',
})
export class RolePermissionService extends BaseService<RolePermission> {
  constructor(
    http: HttpClient,
    private cognito: CognitoService,
  ) {
    const baseurl =
      environment.msSecurityBseUrl + environment.rolePermissionPathUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  GetByRoleId(roleId, limit = 10): Observable<BoxxResponse<RolePermission>> {
    return this.http
      .get<BoxxResponse<RolePermission>>(
        this.baseurl + '?roleId=' + roleId + '&limit=' + limit,
        this.httpOptions,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetAllPermissions(
    roleId,
    sort = '',
    page = 1,
    limit = 10,
  ): Observable<BoxxResponse<RolePermission>> {
    return this.http
      .get<BoxxResponse<RolePermission>>(
        this.baseurl +
          '?roleId=' +
          roleId +
          '&page=' +
          page +
          '&limit=' +
          limit +
          '&sort=' +
          sort +
          '&active=1',
        this.httpOptions,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  CreateRolePermission(data): Observable<BoxxResponse<RolePermission>> {
    let url = `${this.baseurl}`;
    return this.http.post<BoxxResponse<RolePermission>>(
      url,
      data,
      this.httpOptions,
    );
  }

  UpdateRolePermission(
    permissionId,
    data,
  ): Observable<BoxxResponse<RolePermission>> {
    let url = `${this.baseurl}/${permissionId}`;
    return this.http.put<BoxxResponse<RolePermission>>(
      url,
      data,
      this.httpOptions,
    );
  }

  deleteRolePermission(permissionId: number) {
    return this.http
      .delete<RolePermission>(
        this.baseurl + '/' + permissionId + '/?force=1',
        this.httpOptions,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
