import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';

@Component({
  selector: 'app-checkbox-v3',
  templateUrl: './checkbox-v3.component.html',
  styleUrls: ['./checkbox-v3.component.less'],
})
export class CheckboxV3Component {
  @Input() lblStyle: { [klass: string]: any };
  @Input() containerStyle: { [klass: string]: any };
  @Input() checkMarkStyle: { [klass: string]: any };
  @Input() isDisabled: boolean = false;
  @Input() isCombinedSubLabel: boolean = false;
  @Input() label: string;
  @Input() sublabel: any;
  @Input() checked: boolean = false;
  @Input() formGroupStyle: { [klass: string]: any };
  @Input() checkboxStyle: { [klass: string]: any };
  @Input() subLabelStyle: { [klass: string]: any };

  @Output() handleCheckbox = new EventEmitter();

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  onCheckboxChange(index) {
    this.handleCheckbox.emit(index);
  }
}
