<boxxds-textinput-v2
  [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
  [form]="form"
  [placeholder]="'Enter full name' | translate"
  [control]="'name'"
  [id]="'name'"
  [width]="'100%'"
  [label]="'Name' | translate"
  [isRequired]="true"
  [requiredErrMsg]="requiredErrMsg"
  [invalidErrMsg]="invalidErrMsg"
  [formSubmitted]="formSubmitted"
  [containerStyle]="{ marginBottom: '20px' }"
  [inputAutoFocus]="true"
>
</boxxds-textinput-v2>

<boxxds-textinput-v2
  [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
  [form]="form"
  [placeholder]="'Enter the company website' | translate"
  [control]="'website'"
  [id]="'website'"
  [width]="'100%'"
  [label]="'Website' | translate"
  [invalidErrMsg]="invalidErrMsg"
  [formSubmitted]="formSubmitted"
  [containerStyle]="{ marginBottom: '20px' }"
>
</boxxds-textinput-v2>

<boxxds-textinput-v2
  [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
  [form]="form"
  [placeholder]="'olivia@untitledui.com' | translate"
  [control]="'email'"
  [id]="'address'"
  [width]="'100%'"
  [label]="'Main email address' | translate"
  [leftIcon]="'assets/img/email.svg'"
  [isRequired]="true"
  [requiredErrMsg]="requiredErrMsg"
  [formSubmitted]="formSubmitted"
  [invalidErrMsg]="'Please enter valid email'"
  [containerStyle]="{ marginBottom: '20px' }"
>
</boxxds-textinput-v2>

<boxxds-textinput-v2
  [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
  [form]="form"
  [placeholder]="'domain1.com, domain2.com,...' | translate"
  [control]="'domains'"
  [id]="'domains'"
  [width]="'100%'"
  [label]="'Valid email domains' | translate"
  [isRequired]="true"
  [requiredErrMsg]="requiredErrMsg"
  [invalidErrMsg]="invalidErrMsg"
  [formSubmitted]="formSubmitted"
  maxLength="250"
  [containerStyle]="{ marginBottom: '20px' }"
>
</boxxds-textinput-v2>

<boxxds-textinput-v2
  [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
  [form]="form"
  [mask]="'(000) 000-0000'"
  [placeholder]="'111-111-1111' | translate"
  [control]="'phoneNumber'"
  [id]="'phoneNumber'"
  [width]="'100%'"
  [label]="'Phone' | translate"
  [isRequired]="false"
  [requiredErrMsg]="requiredErrMsg"
  [invalidErrMsg]="invalidErrMsg"
  [formSubmitted]="formSubmitted"
  [containerStyle]="{ marginBottom: '20px' }"
>
</boxxds-textinput-v2>

<boxxds-searchable-dropdown-v2
  [label]="'Assign parent company' | translate"
  [form]="form"
  [control]="'parent'"
  [requiredErrMsg]="requiredErrMsg"
  [formSubmitted]="formSubmitted"
  [placeholder]="'Search parent company' | translate"
  [options]="brokerageParents"
  [dispatchTextInputChange]="true"
  (textInputChangeEventEmmiter)="handleTextInputChange($event)"
  (currentValueChange)="handleBusinessSector($event)"
  [btnStyle]="{ height: '36px' }"
  [hideSelectedValue]="false"
  [currentValue]="parentInitValue"
  [isDisabled]="!this.permissionList[this.currentScreen] || !isActive"
  [invalidErrMsg]="parentCompanyInvalidErrMsg"
>
</boxxds-searchable-dropdown-v2>

<app-image-logo-v2
  [title]="'Brokerage'"
  [isActive]="this.permissionList[this.currentScreen] && isActive"
  (handleImageChange)="
    this.permissionList[this.currentScreen] && handleImageChange($event)
  "
  (triggerProfilePicError)="triggerProfilePicError($event)"
  [croppedImage]="logoProfile"
  [previousImage]="logoProfile"
  (clickImageLogoClose)="
    this.permissionList[this.currentScreen] && handleImageLogoClose($event)
  "
></app-image-logo-v2>
