import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { QuoteOptionReasonsService } from 'src/app/services/quote-option-reasons.service ';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-reasons-popup',
  templateUrl: './modal-reasons-popup.component.html',
  styleUrls: ['./modal-reasons-popup.component.less'],
})
export class ModalReasonsPopupComponent implements OnInit {
  @Input() quoteOptionId: number;
  @Input() reasonData: [];
  @Output() handleClose = new EventEmitter<any>();

  isPopoverOpen: boolean = false;
  userID: number;
  reasonsLoaded: boolean = false;

  reasons = [];

  constructor(
    private quoteOptionReasonsService: QuoteOptionReasonsService,
    private localStorageService: LocalStorageService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.userID = this.localStorageService.getBoxxUserId();
    this.openPopover();
  }

  async populateQuoteOptionReasons(quoteOptionId) {
    try {
      this.reasonsLoaded = false;
      if (Array.isArray(this.reasonData) && this.reasonData.length > 0) {
        this.handlePolicyRskTrxReason(this.reasonData);
      } else {
        this.handleQuoteOptionReason(quoteOptionId);
      }
    } catch (error) {
      this.reasonsLoaded = true;
    }
  }

  handlePolicyRskTrxReason(reasonData) {
    if (reasonData?.length > 0) {
      this.reasons = reasonData.map((reason) => reason?.comments);
      this.reasonsLoaded = true;
    }
  }

  async handleQuoteOptionReason(quoteOptionId) {
    const getQuoteOptionReasons =
      this.quoteOptionReasonsService.getByQuoteOptionId(quoteOptionId);
    const response = await firstValueFrom(getQuoteOptionReasons);
    const quoteOptionReasons = response?.data;
    if (quoteOptionReasons?.length > 0) {
      this.reasons = quoteOptionReasons.map((reason) => reason?.comments);
      this.reasonsLoaded = true;
    } else {
      this.closePopover();
    }
  }

  openPopover() {
    if (!this.isPopoverOpen) {
      this.populateQuoteOptionReasons(this.quoteOptionId);
      this.isPopoverOpen = true;
    }
  }

  closePopover() {
    this.isPopoverOpen = false;
    this.handleClose.emit();
  }
}
