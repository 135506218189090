import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, Subscription, firstValueFrom, take } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { BrokerageDirectoryService } from 'src/app/services/brokerage-directory.service';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { getQuoteSelector } from 'src/app/store/create-quote/create-quote.selector';
import { BrokerageProducerCommissionService } from 'src/app/services/brokerage-producer-commission.service';
import { BrokerageCommissionService } from 'src/app/services/brokerage-commissions.service';
import * as DashboardActionTypes from 'src/app/store/dashboard/dashboard.action';
import { getErrorMessage } from 'src/app/utils/utils';
import { TranslateService } from '@ngx-translate/core';
import { RiskRegionService } from 'src/app/services/risk-region.service';

@Component({
  selector: 'app-producer-information',
  templateUrl: './producer-information.component.html',
  styleUrls: ['./producer-information.component.less'],
})
export class ProducerInformationComponent implements OnInit {
  plusCircle: string = 'assets/img/plus-circle-v2.svg';
  @Input() form: FormGroup;
  @Input() isActive: boolean = true;
  @Input() productId: string;
  @Input() regionId;
  @Input() insuredName;
  @Input() brokerOptions: Array<any> = [];
  @Input() formSubmitted;
  @Input() requiredErrMsg;
  @Input() isSubmission: boolean = false;

  @Output() handleAddCommission = new EventEmitter<any>();
  @Output() handleAlertInfo = new EventEmitter<any>();
  @Output() formChange = new EventEmitter<any>();
  @Output() handleSaveInsured = new EventEmitter<any>();

  brokerageForm: FormGroup;
  currentBrokerValue = '';
  currentQuoteStatus: string = '';
  brokerageDetails;
  selectedBroker;
  selectedBranch;
  selectedProducer;
  brokerageErrMsg = '';
  form$: Observable<any>;
  isNavigatedFromRiskAnalysis: boolean = false;
  initialFormValue: any = {};
  noCommission: boolean = false;
  alertInfo;
  productInfo;
  regionInfo;
  showCommissionModal: boolean = false;
  showBrokerRedirectModal: boolean = false;
  private subscription: Subscription;

  permissionList: { [x: string]: boolean } = {};
  currentScreen: string = '';
  noResultInfo = {
    label: this.translate.instant(
      'workFlow3.components.producerInformationComponent.label.newProducer',
    ),
    redirectionPath: '',
    showButtonRightIcon: true,
    buttonRightIconImagePath: 'assets/img/plus-circle-v2.svg',
  };
  noResultContent = this.translate.instant(
    'workFlow3.components.producerInformationComponent.label.noResult',
  );
  brokerageId;
  showAlert;
  commissionDisabled: Array<any> = [];
  commissionId;
  selectedBrokerData: any;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    public brokerageDirectoryService: BrokerageDirectoryService,
    private brokerageProducerCommissionService: BrokerageProducerCommissionService,
    private brokerageCommissionService: BrokerageCommissionService,
    private translate: TranslateService,
    private riskRegionService: RiskRegionService,
  ) {
    this.brokerageForm = this.fb.group({
      broker: ['', Validators.required],
      branch: ['', Validators.required],
      producer: ['', Validators.required],
      brokerageinfo: [''],
    });
  }

  async ngOnInit() {
    const quoteID = this.activatedRoute.snapshot.paramMap.get('id');
    this.form$ = this.store.select(getQuoteSelector);
    this.store?.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
    });

    let insuredId,
      regionId,
      products = [],
      policyId: number | null,
      broker = '',
      producer = '',
      branch = '';

    this.subscription = this.form$.subscribe(async (event) => {
      ({
        branch,
        insuredId,
        regionId,
        isNavigatedFromRiskAnalysis: this.isNavigatedFromRiskAnalysis,
        policyId,
      } = event.ui);
      ({ products } = event);
      products = event.products;
      broker = event.ui.broker;
      producer = event.ui.producer;
      branch == event.ui.branch;
      this.regionId = event.ui.regionId ?? this.regionId;

      if (broker && producer && branch) {
        this.brokerageDetails = {
          branch: branch,
          broker: broker,
          producer: producer,
        };
        this.selectedBranch = { id: event.ui.branchId, name: branch };
        this.selectedBroker = {
          id: event.ui.brokerageId,
          name: broker,
        };
        this.selectedProducer = {
          id: event.ui.producerId,
          name: producer,
        };
        this.brokerageId = event.ui.brokerageId;
        this.currentBrokerValue = broker + ' / ' + branch + ' / ' + producer;
        this.form.get('brokerageinfo').setValue(this.currentBrokerValue);
        this.formChange.emit({
          value: this.currentBrokerValue,
          brokerId: event.ui.brokerageId,
        });
        this.brokerageForm.setValue({
          branch: branch,
          broker: broker,
          producer: producer,
          brokerageinfo: this.currentBrokerValue,
        });
        const selectedValue = {
          branch: this.selectedBranch,
          brokerage: this.selectedBroker,
          producer: this.selectedProducer,
          main_text: this.currentBrokerValue,
          id: event.ui.producerId,
        };

        const hasCommission =
          await this.hasCommissionForSelectedBroker(selectedValue);
        if (
          !hasCommission &&
          selectedValue.main_text === this.currentBrokerValue
        ) {
          this.setAlertInfoCommission();
        }
      }
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (
      changes?.['productId'] &&
      changes['productId'].previousValue !== changes['productId'].currentValue
    ) {
      const hasCommission = await this.hasCommissionForSelectedBroker(
        this.selectedBrokerData,
      );
      if (
        !hasCommission &&
        this.currentBrokerValue === this.currentBrokerValue
      ) {
        this.setAlertInfoCommission();
      } else {
        this.noCommission = false;
      }
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  handleAddProducerBroker(event) {}
  dropdownFocusOut(event) {
    if (
      !this.form.controls['brokerageinfo'].value ||
      this.form.controls['brokerageinfo'].value === ''
    ) {
      this.brokerageForm.controls['brokerageinfo'].setValue('');
      this.brokerageForm.get('broker').setValue('');
      this.brokerageForm.controls['branch'].setValue('');
      this.brokerageForm.controls['producer'].setValue('');
      this.form.get('brokerageinfo').setValue('');
      this.brokerageForm.controls['brokerageinfo'].setValue('');
      this.formChange.emit({ value: '', brokerId: '' });
      this.selectedBroker = '';
      this.selectedBranch = '';
      this.selectedProducer = '';
      this.brokerageId = '';
      this.currentBrokerValue = '';
    }
  }
  searchBroker(searchValue) {
    if (searchValue.trim() === this.form.get('brokerageinfo').value) {
      return;
    }

    this.brokerageErrMsg = '';
    this.noCommission = false;
    this.showCommissionModal = false;
    this.brokerageForm.get('broker').setValue('');
    this.brokerageForm.controls['branch'].setValue('');
    this.brokerageForm.controls['producer'].setValue('');
    this.form.get('brokerageinfo').setValue('');
    this.formChange.emit({ value: '', brokerId: '' });
    this.selectedBroker = '';
    this.selectedBranch = '';
    this.selectedProducer = '';
    this.brokerageId = '';
    this.currentBrokerValue = '';
    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        broker: this.brokerageForm.value['broker'] ?? '',
        producer: this.brokerageForm.value['producer'] ?? '',
        branch: this.brokerageForm.value['branch'] ?? '',
        brokerageId: '',
        branchId: '',
        producerId: '',
      }),
    );
    if (searchValue === '') {
      this.brokerOptions = [];
    }
    if (searchValue.length >= 3) {
      const searchKey = searchValue.trim();
      this.brokerageDirectoryService
        .GetBrokerageDirectories(1, 50, '', true, true, true, searchKey, true)
        .subscribe({
          next: (response) => {
            const tableData =
              response.data.length === 0
                ? []
                : response.data?.map((dataObj) => ({
                    main_text:
                      dataObj?.brokerage?.name +
                      ' / ' +
                      dataObj?.brokerageBranch?.name +
                      ' / ' +
                      dataObj?.brokerageProducer?.name,
                    id: dataObj?.id,
                    disabled: !dataObj.active,
                    brokerage: dataObj?.brokerage,
                    branch: dataObj?.brokerageBranch,
                    producer: dataObj?.brokerageProducer,
                  }));
            this.brokerOptions = tableData
              .filter((x) => x.brokerage && x.branch && x.producer)
              .splice(0, 10);

            this.formSubmitted = false;
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.alertInfo = {
                alertType: 'error',
                alertHeader: this.translate.instant('common.errorMsg'),
                alertText: getErrorMessage(error),
              };
            } else {
              this.alertInfo = {
                alertType: 'error',
                alertHeader: this.translate.instant('common.errorMsg'),
                alertText: error ?? '' ?? error?.message ?? '',
              };
            }
          },
        });
    } else {
      return;
    }
  }

  async selectBroker(value) {
    let selectedValue = this.brokerOptions.find((x) => x.id === value);
    this.selectedBrokerData = selectedValue;
    this.currentBrokerValue = selectedValue.main_text;
    this.brokerageForm.controls['brokerageinfo'].setValue(
      this.currentBrokerValue,
    );
    this.form.controls['brokerageinfo'].setValue(this.currentBrokerValue);
    this.formChange.emit({ value: this.currentBrokerValue, brokerId: '' });
    this.brokerageDetails = {
      branch: selectedValue.branch.name,
      broker: selectedValue.brokerage.name,
      producer: selectedValue.producer.name,
      branchId: selectedValue.branch.id,
      brokerageId: selectedValue.brokerage.id,
      producerId: selectedValue.producer.id,
    };
    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        broker: this.brokerageDetails?.broker ?? '',
        producer: this.brokerageDetails?.producer ?? '',
        branch: this.brokerageDetails?.branch ?? '',
        brokerageId: this.brokerageDetails?.brokerageId,
        branchId: this.brokerageDetails?.branchId,
        producerId: this.brokerageDetails?.producerId,
      }),
    );

    this.selectedBranch = selectedValue.branch;
    this.selectedBroker = selectedValue.brokerage;
    this.selectedProducer = selectedValue.producer;
    this.brokerageId = selectedValue?.brokerage.id;

    this.brokerageForm.setValue({
      branch: selectedValue.branch.name,
      broker: selectedValue.brokerage.name,
      producer: selectedValue.producer.name,
      brokerageinfo: this.currentBrokerValue,
    });
    this.form.controls['brokerageinfo'].setValue(this.currentBrokerValue);
    this.formChange.emit({ value: this.currentBrokerValue, brokerId: '' });
    this.brokerageErrMsg = '';
    //check broker has commission
    const hasCommission =
      await this.hasCommissionForSelectedBroker(selectedValue);
    if (!hasCommission && selectedValue.main_text === this.currentBrokerValue) {
      this.setAlertInfoCommission();
    }
  }

  async hasCommissionForSelectedBroker(selectedBroker) {
    try {
      let brokerageId = selectedBroker?.brokerage?.id;
      let producerId = selectedBroker?.producer?.id;
      let regionId = this.regionId;
      let riskIds = '';
      this.commissionDisabled = [];

      if (!!!brokerageId || !!!producerId || !!!regionId) {
        await this.form$?.pipe(take(1)).subscribe((event) => {
          if (!brokerageId) {
            brokerageId = event?.ui?.brokerageId;
          }
          if (!producerId) {
            producerId = event?.ui?.producerId;
          }
          regionId = event.ui.regionId;
        });
      }
      if (this.productId) {
        riskIds = this.productId;
      } else {
        const getRiskRegions = this.riskRegionService.getRiskRegionsByRegion(
          this.regionId,
        );
        const riskRegions = await firstValueFrom(getRiskRegions);
        const activeRisks = riskRegions?.data.map(
          (riskRegion) => riskRegion?.risk.id,
        );
        riskIds = activeRisks?.length > 0 ? activeRisks?.join(',') : '';
      }

      const getProducerCommission =
        this.brokerageProducerCommissionService.GetByRegionIdAndRiskIdsAndProducerId(
          regionId,
          riskIds,
          producerId,
          1,
        );
      const producerCommissionResponse = await firstValueFrom(
        getProducerCommission,
      );
      if (producerCommissionResponse.data[0]) {
        this.commissionDisabled = producerCommissionResponse.data.filter(
          (broker) => !broker.active,
        );
        return this.commissionDisabled.length > 0 ? false : true;
      } else {
        const getBrokerCommission =
          this.brokerageCommissionService.GetByRegionIdAndRiskIdsAndBrokerageId(
            regionId,
            riskIds,
            brokerageId,
          );
        const brokerCommissionResponse =
          await firstValueFrom(getBrokerCommission);

        if (brokerCommissionResponse.data.length === 0) {
          return false;
        } else {
          this.commissionDisabled = [
            ...this.commissionDisabled,
            ...brokerCommissionResponse.data.filter((broker) => !broker.active),
          ];
          return this.commissionDisabled.length > 0 ? false : true;
        }
      }
    } catch (error) {
      if (![500].includes(error?.status)) {
        this.alertInfo = {
          alertType: 'error',
          alertHeader: this.translate.instant('common.errorMsg'),
          alertText: getErrorMessage(error),
        };
      }
      return false;
    }
  }

  // Broker Commission Popup
  handleLinkAction() {
    this.showCommissionModal = true;
  }

  handleClose() {
    this.showCommissionModal = false;
  }

  async handleNotifMsg(alert) {
    this.handleAlertInfo.emit(alert);
    if (alert.type === 'success') {
      this.noCommission = false;
      this.showCommissionModal = false;
      const hasCommission = await this.hasCommissionForSelectedBroker(
        this.selectedBrokerData,
      );
      if (
        !hasCommission &&
        this.selectedBrokerData.main_text === this.currentBrokerValue
      ) {
        this.setAlertInfoCommission();
      }
    }
  }

  submitBrokerCommission(event) {}

  noResultButtonclick(event) {
    const isNavigationStatus = event;
    this.showBrokerRedirectModal = true;
    if (isNavigationStatus) {
      this.store.dispatch(
        new DashboardActionTypes.updateDashboardConfigAction({
          isNavigatedFromAlert: isNavigationStatus,
        }),
      );
    }
  }

  handleInsuredSave() {
    this.handleSaveInsured.emit(true);
  }

  handleInsuredSaveModalClose() {
    this.showBrokerRedirectModal = false;
  }

  setAlertInfoCommission() {
    this.noCommission = true;
    this.alertInfo = {
      alertType: 'error',
      alertHeader: this.translate.instant(
        `workFlow3.components.producerInformationComponent.error.${
          this.commissionDisabled.length > 0
            ? 'disabledCommissionHead'
            : 'noCommissionHead'
        }`,
      ),
      alertText: this.translate.instant(
        `workFlow3.components.producerInformationComponent.error.${
          this.commissionDisabled.length > 0
            ? 'disabledCommissionContent'
            : 'noCommissionContent'
        }`,
      ),
      alertLinkText: this.translate.instant(
        `workFlow3.components.producerInformationComponent.error.${
          this.commissionDisabled.length > 0
            ? 'enableAndUpdateCommission'
            : 'addCommission'
        }`,
      ),
    };
  }
}
