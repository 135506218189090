import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, firstValueFrom, take } from 'rxjs';
import { PolicyTRIAUpdate } from 'src/app/entities/policy';
import { PolicyRiskTrxService } from 'src/app/services/policy-risk-trx.service';
import {
  getQuoteSelector,
  getUIData,
} from 'src/app/store/create-quote/create-quote.selector';
import { getCurrencySelector } from 'src/app/store/dashboard/dashboard.selector';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Quote } from 'src/app/models/quote.model';
import { AlertService } from 'src/app/services/alert.service';
import {
  getAlertHead,
  getErrorMessage,
  valInDigits,
} from 'src/app/utils/utils';
import { getLifecycleState } from 'src/app/store/lifecycle/lifecycle.select';

@Component({
  selector: 'app-quote4-flow-header',
  templateUrl: './quote4-flow-header.component.html',
  styleUrls: ['./quote4-flow-header.component.less'],
})
export class Quote4FlowHeaderComponent implements OnInit {
  @Input() insuredInfo: Array<any> = [];
  @Input() brokerageInfo: Array<any> = [];
  quoteInfo: {
    premium?: string;
    triaAmount?: string;
    triaAmountDefault?: string;
    policyFee?: string;
    policyFeeDefault?: string;
    totalInvoiceAmount?: string;
    servicesAmount?: string;
    taxesPremiumAmount?: string;
  } = {};
  namePopoverOpen = false;
  industryPopoverOpen = false;
  form$: Observable<Quote>;
  showPremiumDetails: boolean = false;
  @Input() showBrokerDetails: boolean = true;
  @Input() showInsurerDetails: boolean = true;
  @Input() showInfoBanner: boolean = false;

  policyFeeEnabled: boolean = true;
  TRIAFeeEnabled: boolean = true;
  isBrokerExpand: boolean = false;
  isPremiumExpand: boolean = false;
  isTRIAAmountEditMode: boolean = false;
  isPolicyFeeEditMode: boolean = false;
  policyFeeForm: FormGroup;
  isBindingPolicy: boolean = false;
  policyPeriodId: number = undefined;
  policyPremium: number = undefined;
  currency = '';
  currentQuoteStatus: string = '';

  constructor(
    private policyRiskTrxService: PolicyRiskTrxService,
    private router: Router,
    private store: Store,
    private fb: FormBuilder,
    private alertService: AlertService,
  ) {
    this.policyFeeForm = this.fb.group({
      policyFee: ['0'],
      triaAmount: ['0'],
    });
  }

  ngOnInit(): void {
    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));
    if (
      this.router.url.startsWith('/dashboard/quote4flow/policy-configuration')
    ) {
      this.store.select(getUIData).subscribe(async (data: any) => {
        this.quoteInfo = data?.quote4FlowPremiumDetails
          ? {
              premium: `${
                data?.quote4FlowPremiumDetails?.policyPremium ?? `0`
              }`,
              triaAmount: `${
                data?.quote4FlowPremiumDetails?.triaAmount ?? `0`
              }`,
              policyFee: `${
                data?.quote4FlowPremiumDetails?.policyAmount ?? `0`
              }`,
              triaAmountDefault: `${
                data?.quote4FlowPremiumDetails?.triaAmountDefault ?? `0`
              }`,
              policyFeeDefault: `${
                data?.quote4FlowPremiumDetails?.policyFeeDefault ?? `0`
              }`,
              totalInvoiceAmount: `${
                data?.quote4FlowPremiumDetails?.totalBilledAnnually ?? `0`
              }`,
              servicesAmount: `${
                data?.quote4FlowPremiumDetails?.serviceAmount ?? `0`
              }`,
              taxesPremiumAmount: `${
                data?.quote4FlowPremiumDetails?.taxAmount ?? `0`
              }`,
            }
          : {};
        this.TRIAFeeEnabled =
          data?.quote4FlowPremiumDetails?.TRIAFeeEnabled ?? false;
        this.policyFeeEnabled =
          data?.quote4FlowPremiumDetails?.policyFeeEnabled ?? false;
        this.showPremiumDetails =
          Object.values(this.quoteInfo).length > 0 ? true : false;
        this.policyFeeForm.controls['policyFee'].setValue(
          this.quoteInfo.policyFee,
        );
        this.policyFeeForm.controls['triaAmount'].setValue(
          this.quoteInfo.triaAmount,
        );
      });
    }

    this.store.pipe(select(getLifecycleState)).subscribe((data) => {
      this.currentQuoteStatus = data.quoteStatus;
    });
    this.form$ = this.store.pipe(select(getQuoteSelector));
    this.form$.subscribe(
      (event) => (this.policyPeriodId = Number(event.ui.policyPeriodId)),
    );
  }

  toggleShowCard(option) {
    if (option === 1) {
      this.isBrokerExpand = !this.isBrokerExpand;
    } else {
      this.isPremiumExpand = !this.isPremiumExpand;
    }
  }

  togglePolicyFeeEdit(event, fromInput = false) {
    event.stopPropagation();
    if (this.isBindingPolicy || this.currentQuoteStatus === 'quote-closed') {
      return;
    }
    if (this.isPolicyFeeEditMode) {
      if (fromInput) {
        this.handleFeeChange(event, true);
      }
    } else {
      this.isPolicyFeeEditMode = true;
    }
  }

  toggleTRIAAmountEdit(event, fromInput = false) {
    event.stopPropagation();
    if (this.isBindingPolicy || this.currentQuoteStatus === 'quote-closed') {
      return;
    }
    if (this.isTRIAAmountEditMode) {
      if (fromInput) {
        this.handleFeeChange(event, false);
      }
    } else {
      this.isTRIAAmountEditMode = true;
    }
  }

  isPolicyFeeValid(policyFee) {
    if (
      this.policyFeeEnabled &&
      (!policyFee || policyFee === '0' || policyFee < 1 || policyFee > 100000)
    ) {
      return false;
    }
    return true;
  }

  isTriaAmountValid(triaAmount) {
    if (
      this.TRIAFeeEnabled &&
      (!triaAmount ||
        triaAmount === '0' ||
        triaAmount < 1 ||
        triaAmount > valInDigits(this.quoteInfo.premium))
    ) {
      return false;
    }
    return true;
  }

  async handleFeeChange(event, isPolicyFeeChange: boolean) {
    let newValue = valInDigits(event.target.value);

    //TRIA/ Policy Fee validation
    if (!isPolicyFeeChange && !this.isTriaAmountValid(newValue)) {
      this.quoteInfo.triaAmount = `${newValue}`;
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: 'quoteSummary.errors.triaAmount',
      };
      this.alertService.addAlert(alertData);
      this.policyFeeForm.controls['policyFee'].setValue(newValue);
      await this.updateQuote4FlowPremiumDetails();
      return;
    }

    if (isPolicyFeeChange && !this.isPolicyFeeValid(newValue)) {
      this.quoteInfo.policyFee = `${newValue}`;
      const alertData = {
        type: 'error',
        headerText: getAlertHead('error'),
        bodyText: 'quoteSummary.errors.policyFee',
      };
      this.alertService.addAlert(alertData);
      this.policyFeeForm.controls['policyFee'].setValue(newValue);
      await this.updateQuote4FlowPremiumDetails();
      return;
    }

    this.alertService.clearAlerts(-1);
    if (isPolicyFeeChange) {
      this.quoteInfo.policyFee = `${newValue}`;
    } else {
      this.quoteInfo.triaAmount = `${newValue}`;
    }
    await this.saveTriaOrPolicyFee(isPolicyFeeChange);
  }

  async saveTriaOrPolicyFee(edited = null) {
    const policyFee = this.quoteInfo.policyFee;
    const triaAmount = this.quoteInfo.triaAmount;
    const bindData: PolicyTRIAUpdate = {
      policyPeriodId: this.policyPeriodId,
      policyFee: this.policyFeeEnabled ? Number(policyFee || 0) ?? 0 : 0,
      triaAmount: this.TRIAFeeEnabled ? Number(triaAmount || 0) ?? 0 : 0,
    };

    const updatedInsuranceCharges = this.policyRiskTrxService.PolicyTRIAUpdate(
      this.policyPeriodId,
      bindData,
    );

    const updateAPIResponse = await firstValueFrom(updatedInsuranceCharges);

    let totalBilledAnnually = await this.getTotalBilledAnnual(
      this.policyFeeEnabled,
      this.TRIAFeeEnabled,
    );
    this.quoteInfo = {
      ...this.quoteInfo,
      totalInvoiceAmount: `${totalBilledAnnually}`,
    };
    if (this.isTRIAAmountEditMode) {
      this.policyFeeForm.controls['triaAmount'].setValue(triaAmount);
      this.quoteInfo = {
        ...this.quoteInfo,
        triaAmount: `${triaAmount}`,
      };
    }
    if (this.isPolicyFeeEditMode) {
      this.policyFeeForm.controls['policyFee'].setValue(policyFee);
      this.quoteInfo = {
        ...this.quoteInfo,
        policyFee: `${policyFee}`,
      };
    }
    if (edited == true && !this.isTRIAAmountEditMode) {
      this.isPolicyFeeEditMode = false;
    } else if (edited == false && !this.isPolicyFeeEditMode) {
      this.isTRIAAmountEditMode = false;
    } else if (
      edited &&
      this.isPolicyFeeEditMode &&
      this.isTRIAAmountEditMode
    ) {
      this.isPolicyFeeEditMode = false;
      this.isTRIAAmountEditMode = false;
    } else if (edited == null) {
      this.isPolicyFeeEditMode = false;
      this.isTRIAAmountEditMode = false;
    }
    await this.updateQuote4FlowPremiumDetails();
  }

  async updateQuote4FlowPremiumDetails() {
    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        quote4FlowPremiumDetails: {
          policyAmount: +this.quoteInfo.policyFee,
          policyFeeDefault: +this.quoteInfo.policyFeeDefault,
          triaAmount: +this.quoteInfo.triaAmount,
          triaAmountDefault: +this.quoteInfo.triaAmountDefault,
          policyPremium: +this.quoteInfo.premium,
          TRIAFeeEnabled: this.TRIAFeeEnabled,
          policyFeeEnabled: this.policyFeeEnabled,
          totalBilledAnnually: +this.quoteInfo.totalInvoiceAmount,
          serviceAmount: +this.quoteInfo.servicesAmount,
          taxAmount: +this.quoteInfo.taxesPremiumAmount,
        },
      }),
    );
  }

  async getTotalBilledAnnual(
    policyFeeEnabled: boolean,
    TRIAFeeEnabled: boolean,
  ) {
    const totalPremium = +this.quoteInfo.premium;
    const serviceAmount = +this.quoteInfo.servicesAmount;
    const taxAmount = +this.quoteInfo.taxesPremiumAmount;
    const policyFee = policyFeeEnabled ? +this.quoteInfo.policyFee : 0;
    const triaAmount = TRIAFeeEnabled ? +this.quoteInfo.triaAmount : 0;

    return totalPremium + serviceAmount + taxAmount + policyFee + triaAmount;
  }

  async triaSwitchHandler(value: boolean) {
    if (this.isBindingPolicy || this.currentQuoteStatus === 'quote-closed') {
      return;
    }
    this.alertService.clearAlerts(-1);
    this.TRIAFeeEnabled = !value;
    try {
      this.quoteInfo.triaAmount = this.quoteInfo.triaAmountDefault;
      this.policyFeeForm.controls['triaAmount'].setValue(
        this.quoteInfo.triaAmount,
      );
      await this.saveTriaOrPolicyFee();
    } catch (error) {
      if (![500].includes(error?.status)) {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: getErrorMessage(error),
        };
        this.alertService.addAlert(alertData);
      }
      this.TRIAFeeEnabled = value;
    }
  }

  async policyFeeSwitchHandler(value: boolean) {
    if (this.isBindingPolicy || this.currentQuoteStatus === 'quote-closed') {
      return;
    }
    this.alertService.clearAlerts(-1);

    this.policyFeeEnabled = !value;
    try {
      this.quoteInfo.policyFee = this.quoteInfo.policyFeeDefault;
      this.policyFeeForm.controls['policyFee'].setValue(
        this.quoteInfo.policyFee,
      );
      await this.saveTriaOrPolicyFee();
    } catch (error) {
      if (![500].includes(error?.status)) {
        const alertData = {
          type: 'error',
          headerText: getAlertHead('error'),
          bodyText: getErrorMessage(error),
        };
        this.alertService.addAlert(alertData);
      }
      this.policyFeeEnabled = value;
    }
  }

  valueInDigits(value: any): any {
    if (value) return valInDigits(value);
    else return 0;
  }
  getTRIA() {
    if (this.TRIAFeeEnabled) {
      return this.quoteInfo.triaAmount || '0';
    }
    return '0';
  }

  getPolicyFee() {
    if (this.policyFeeEnabled) {
      return this.quoteInfo.policyFee || '0';
    }
    return '0';
  }
}
