<div
  class="modal-outer-subjectivities"
  *ngIf="showModal"
  (click)="handleBackDropClick()"
>
  <div class="modal-container" (click)="handleModalClick($event)">
    <div class="modal-content-list">
      <div class="text-input-container">
        <boxxds-textinput-v2
          [showClearButton]="true"
          (handleClearValue)="clearValue()"
          [form]="form"
          [placeholder]="
            'workFlow3.components.modalSearchDocuments.placeholder.subjectivities'
              | translate
          "
          [label]="''"
          [control]="'filterKeyWord'"
          [id]="'search'"
          [leftIcon]="'assets/img/workflow3/sidemenu/search.png'"
          [width]="'768px'"
          (handleKeyUp)="onSearch($event)"
          maxLength="1000"
        >
        </boxxds-textinput-v2>
      </div>

      <div
        class="maximum-count-message"
        *ngIf="
          !(form.value['filterKeyWord'] && form.value['filterKeyWord'] !== '')
        "
      >
        {{
          "Please select up to " +
            remainingCount +
            " subjectivities from the list."
        }}
      </div>
      <div
        class="create-btn-container"
        *ngIf="
          form.value['filterKeyWord'] && form.value['filterKeyWord'] !== ''
        "
      >
        <button
          class="create-subjectivity-btn"
          (click)="
            subjectivitiesSelectedToAdd?.length < 10 && createSubjectivity()
          "
          [attr.disabled]="
            subjectivitiesSelectedToAdd?.length >= remainingCount ? true : null
          "
        >
          <span
            ><img
              class="create-subjectivity-img"
              src="assets/img/plus-circle.svg"
              alt=""
          /></span>
          <span class="create-btn-text">{{ "Create" | translate }}</span>
          <span
            class="create-text"
            *ngIf="subjectivitiesSelectedToAdd?.length < remainingCount"
            >{{ form.value["filterKeyWord"] }}</span
          >
        </button>
      </div>
      <div #list class="subjectivities-list" (scroll)="onScroll($event)">
        <ng-container
          *ngFor="let sub of subjectivitiesCreatedToAdd; let i = index"
        >
          <div
            class="list-item selected"
            *ngIf="editedCustomSubjectivityIndex !== i"
          >
            <button
              class="list-item-name"
              (click)="removeCreatedSubjectivity(sub)"
              [attr.disabled]="sub.disabled ? true : null"
            >
              {{ sub }}
            </button>
            <button
              class="edit-button"
              (click)="editCustomSubjectivity(i)"
              [attr.disabled]="sub.disabled ? true : null"
            >
              <img src="assets/img/edit.svg" alt="edit" />
            </button>
          </div>
          <ng-container *ngIf="editedCustomSubjectivityIndex === i">
            <div
              class="list-item edit-mode"
              [ngClass]="{
                'editing-mode': editedCustomSubjectivityIndex === i
              }"
            >
              <textarea
                *ngIf="editedCustomSubjectivityIndex === i"
                type="text"
                class="listKey-textArea"
                [(ngModel)]="editedSubjectivity"
                [value]="editedSubjectivity"
                maxLength="1000"
              ></textarea>
              <div class="edit-buttons">
                <button
                  class="check-button"
                  (click)="confirmEditCustomSubjectivity(i); list.scrollTop = 0"
                  [disabled]="isConfirmDisabled()"
                >
                  <img src="assets/img/check-01.svg" alt="edit" />
                </button>
                <button class="delete-button ml-2" (click)="cancelEdit()">
                  <img src="assets/img/close.svg" alt="close" />
                </button>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container
          *ngFor="let sub of subjectivityOptns[product]; let i = index"
        >
          <div
            class="list-item"
            *ngIf="!sub.edit"
            [ngClass]="{
              selected: isSelected(sub),
              'filtered-result': form.value['filterKeyWord']?.length >= 3
            }"
          >
            <button
              class="list-item-name"
              (click)="handleSubjectivitySelect(sub)"
              [attr.disabled]="sub.disabled ? true : null"
              [innerHtml]="
                form.value['filterKeyWord']?.length >= 3
                  ? getFilteredResult(sub.key)
                  : sub.key
              "
            ></button>

            <button
              class="edit-button"
              (click)="editSubjectivity(i)"
              [attr.disabled]="sub.disabled ? true : null"
            >
              <img src="assets/img/edit.svg" alt="edit" />
            </button>
          </div>

          <ng-container *ngIf="sub.edit">
            <div
              class="list-item edit-mode"
              [ngClass]="{ 'editing-mode': sub.edit }"
            >
              <textarea
                *ngIf="sub.edit"
                type="text"
                class="listKey-textArea"
                [(ngModel)]="editedSubjectivity"
                [value]="editedSubjectivity"
                maxLength="1000"
              ></textarea>
              <div class="edit-buttons">
                <button
                  class="check-button"
                  (click)="confirmEditSubjectivity(i); list.scrollTop = 0"
                  [disabled]="isConfirmDisabled()"
                >
                  <img src="assets/img/check-01.svg" alt="edit" />
                </button>
                <button class="delete-button ml-2" (click)="cancelEdit()">
                  <img src="assets/img/close.svg" alt="close" />
                </button>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="add-btn-holder">
      <div class="badge">
        {{
          subjectivitiesSelectedToAdd?.length +
            subjectivitiesCreatedToAdd?.length +
            " " +
            ("common.selected" | translate)
        }}
      </div>
      <boxxds-button-v2
        *ngIf="!addBtnClicked"
        [buttonText]="'common.add' | translate"
        [btnClass]="'primary'"
        [btnType]="'default'"
        [size]="'lg'"
        (handleClick)="
          (subjectivitiesCreatedToAdd?.length > 0 ||
            subjectivitiesSelectedToAdd?.length > 0) &&
            addClickHandler()
        "
        [isDisabled]="
          subjectivitiesSelectedToAdd?.length === 0 &&
          subjectivitiesCreatedToAdd?.length === 0
        "
        [btnStyle]="{ width: '87px' }"
      ></boxxds-button-v2>
      <button
        *ngIf="addBtnClicked"
        class="btn-processing"
        tabindex="0"
        [ngStyle]="{
          border: '1px solid #FFBE3D',
          borderRadius: '8px',
          width: '87px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px 16px',
          background: '#FCF8EF',
          marginLeft: 'auto',
          lineHeight: '24px'
        }"
      >
        <boxxds-spinner></boxxds-spinner>
      </button>
    </div>
  </div>
</div>
