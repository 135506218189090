<div class="actions-container">
  <ng-container *ngIf="commission.active && commission.status !== 3">
    <div>
      <img src="assets/img/update.png" />
      <a
        href="javascript:void(0)"
        (click)="handleEditFunciton($event, commission)"
        [class.link-disabled]="!isActive"
        >{{ "EDIT" | translate }}</a
      >
    </div>
    <div>
      <img src="assets/img/disable.png" />
      <a
        href="javascript:void(0)"
        (click)="handleDisableFunction($event, commission)"
        [class.link-disabled]="!isActive"
        >{{ "DISABLE" | translate }}</a
      >
    </div>
  </ng-container>

  <ng-container *ngIf="!commission.active && commission.status !== 3">
    <div class="enableBtnPadding">
      <a
        href="javascript:void(0)"
        (click)="handleEnableFunction($event, commission)"
        [class.link-disabled]="!isActive"
        >{{ "ENABLE" | translate }}</a
      >
    </div>
  </ng-container>
</div>
