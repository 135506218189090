import { Component } from '@angular/core';

@Component({
  selector: 'app-check-inbox',
  templateUrl: './check-inbox.component.html',
  styleUrls: ['./check-inbox.component.less'],
})
export class CheckInboxComponent {
  constructor() {}
}
