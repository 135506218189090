<div class="spinner-container">
  <div
    class="spinner-holder-submission"
    *ngIf="showBodySpinnerLocation || showBodySpinner"
  >
    <div class="body-spinner-submission">
      <div class="body-spinner-wrapper">
        <boxxds-spinner
          [containerStyle]="{
            width: '112px',
            height: '112px',
            '--b': '15px'
          }"
        ></boxxds-spinner>
      </div>
    </div>
  </div>
</div>
<section
  class="newSubmissionWrapper"
  [style.display]="!showBodySpinnerLocation ? 'block' : 'none'"
>
  <div class="holder" *ngIf="!showBodySpinner">
    <boxxds-alert
      *ngIf="showBanner"
      type="error"
      [headerText]="'common.requiredAlertHeaderText' | translate"
      [bodyText]="bannerMsg | translate"
      (handleCloseEvent)="handleNotificationAlertClose()"
    ></boxxds-alert>
    <ng-container *ngIf="!childSpinnerChanged && !showBodySpinner">
      <boxxds-body
        sz="md"
        [text]="'submission.insuredType' | translate"
        [customStyle]="{
          color: '#616162',
          textAlign: 'left',
          marginBottom: '8px'
        }"
      ></boxxds-body>
      <button class="toggle" tab-index="0" *ngIf="form$ | async as formData">
        <span
          class="toggle-menu"
          (click)="onToggle(true, formData?.ui?.isInsuredTypeCompany)"
          [ngClass]="{ active: (form$ | async)?.ui.isInsuredTypeCompany }"
          >{{ "submission.company" | translate }}</span
        >
        <span
          class="toggle-menu"
          (click)="onToggle(false, formData?.ui?.isInsuredTypeCompany)"
          [ngClass]="{ active: !(form$ | async)?.ui.isInsuredTypeCompany }"
          >{{ "submission.individual" | translate }}</span
        >
      </button>
    </ng-container>
    <div
      *ngIf="(form$ | async)?.ui.isInsuredTypeCompany"
      class="company-container"
    >
      <ng-container>
        <app-company-insured-form
          [form]="addCompanyForm"
          [form$]="form$"
          [checkValidation]="submitted"
          (handleContinue)="addOrUpdateCompanyInsured($event)"
          (handleErrorOrSuccess)="handleErrorOrSuccess($event)"
          (handleCompletedLocationLoading)="completedLocationLoading($event)"
          [continueButtonLabel]="'submission.continueToStep2Of4' | translate"
          [fromSubmissionFlow]="true"
          [allowLocationLoader]="false"
          [insuredId]="insuredId"
        ></app-company-insured-form>
      </ng-container>
    </div>
    <!-- Individual -->
    <div
      *ngIf="!(form$ | async)?.ui.isInsuredTypeCompany"
      class="company-container"
    >
      <ng-container>
        <app-individual-insured-form
          [checkValidation]="submitted"
          [continueButtonLabel]="'submission.continueToStep2Of4' | translate"
          [form]="addIndividualForm"
          (handleErrorOrSuccess)="handleErrorOrSuccess($event)"
          (handleContinue)="addOrUpdateIndividualInsured($event)"
          (handleCompletedLocationLoading)="completedLocationLoading($event)"
          [fromSubmissionFlow]="true"
          [allowLocationLoader]="false"
        ></app-individual-insured-form>
      </ng-container>
    </div>
    <app-submission-recalculate-confirmation-popup
      *ngIf="showRecalcConfModal"
      [showModal]="showRecalcConfModal"
      [existingQuoteOptionData]="actionPopupDetails"
      (handleClose)="closeModalRecalculateConf()"
      (handleSubmissionRecalculate)="handleSubmissionRecalculate($event, null)"
      (handleDiscardRecalculate)="handleDiscardRecalculate($event)"
    >
    </app-submission-recalculate-confirmation-popup>
    <app-modal-submission-risk-analysis
      *ngIf="showModalRiskAnalysis"
      [showModal]="showModalRiskAnalysis"
      [existingQuoteOptionData]="actionPopupDetails"
      (handleClose)="closeModalRecalculateConf()"
      (handleSave)="handleSaveRevenueChanges()"
      (handleDiscardChanges)="handleDiscardRecalculate($event)"
    >
    </app-modal-submission-risk-analysis>

    <app-modal-insured-state-update
      *ngIf="showModalStateChange"
      [showModal]="showModalStateChange"
      [existingQuoteOptionData]="actionPopupDetails"
      (handleClose)="closeModalRecalculateConf()"
      (handleDecline)="handleDeclineAndSave()"
      (handleDiscardChanges)="handleDiscardRecalculate($event)"
    >
    </app-modal-insured-state-update>
    <app-modal-sanction
      *ngIf="showSanctionModal"
      [showModal]="showSanctionModal"
      [existingQuoteOptionData]="actionPopupDetails"
      (handleClose)="closeSanctionPopup()"
      [data]="sanctionPayload"
      [isRestrictedIndustry]="isRestrictedIndustry"
      (handleProceed)="handleSanctionProceed()"
      (handleDiscard)="handleDiscardRecalculate($event)"
      (handleDeclineAndSaveChanges)="handleDeclineAndSave()"
    ></app-modal-sanction>
  </div>
</section>
