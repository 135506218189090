<form [formGroup]="formCommission" novalidate>
  <div class="form-group-cl-6">
    <boxxds-dropdown
      [disabled]="!isActive"
      [currentValue]="productId"
      [id]="'productId'"
      [form]="formCommission"
      [control]="'productId'"
      [placeholder]="'placeholder.select' | translate"
      [options]="products"
      [label]="('common.products' | translate) + '*'"
      [btnStyle]="{ marginTop: '8px', maxWidth: '250px' }"
      [dropdownContainerStyle]="{ maxWidth: '250px' }"
      class="input-fields"
      [requiredErrMsg]="requiredErrMsg | translate"
      [formSubmitted]="submitted"
      (currentValueChange)="handleOnChangeProductId($event)"
    ></boxxds-dropdown>

    <app-spinner-load
      [isFetching]="isFetchingRegions"
      [iconTopPosition]="{ top: '54%' }"
    >
      <boxxds-dropdown
        [isReadonly]="!(isActive && productId.length)"
        [currentValue]="regionId"
        [placeholder]="'placeholder.select' | translate"
        [form]="formCommission"
        [control]="'regionId'"
        [options]="regions"
        [label]="('common.regions' | translate) + '*'"
        [btnStyle]="{ marginTop: '8px', maxWidth: '250px' }"
        [dropdownContainerStyle]="{ maxWidth: '250px' }"
        class="input-fields"
        [requiredErrMsg]="requiredErrMsg | translate"
        [formSubmitted]="submitted"
        (currentValueChange)="handleOnChangeRegionId($event)"
      ></boxxds-dropdown>
    </app-spinner-load>

    <div>
      <label class="text-input-label">{{
        "common.minRangePercentage" | translate
      }}</label>
      <boxxds-stepper-input
        [isDisabled]="!isActive"
        [max]="100"
        [value]="minPercentage"
        [inputStyle]="isActive && { background: 'transparent' }"
        (handleChange)="onChangeMinPercentage($event)"
        (handleKeyPress)="handleMinPercentageValidate($event)"
        [formSubmitted]="submitted"
        [form]="formCommission"
        [control]="'minPercentage'"
        [containerStyle]="{ width: '83%', 'margin-top': '0px' }"
      ></boxxds-stepper-input>
    </div>

    <div [ngClass]="{ 'percentage-container': actionCommission === 3 }">
      <label class="text-input-label">{{
        "common.maxRangePercentage" | translate
      }}</label>
      <boxxds-stepper-input
        [isDisabled]="!isActive"
        [max]="100"
        [inputStyle]="isActive && { background: 'transparent' }"
        (handleChange)="onChangeMaxPercentage($event)"
        (handleKeyPress)="handleMaxPercentageValidate($event)"
        [value]="maxPercentage"
        [formSubmitted]="submitted"
        [form]="formCommission"
        [control]="'maxPercentage'"
        [containerStyle]="{ width: '83%', 'margin-top': '0px' }"
      >
      </boxxds-stepper-input>
    </div>
    <div>
      <label class="text-input-label">{{
        "common.defaultPercentage" | translate
      }}</label>

      <boxxds-stepper-input
        [isDisabled]="!isActive"
        [min]="minPercentage ?? 0"
        [max]="maxPercentage ?? 100"
        [inputStyle]="isActive && { background: 'transparent' }"
        (handleChange)="onChangeDefaultPercentage($event)"
        (handleKeyPress)="handleDefaultPercentageValidate($event)"
        [value]="defaultPercentage"
        [formSubmitted]="submitted"
        [form]="formCommission"
        [control]="'defaultPercentage'"
        [containerStyle]="{ width: '83%', 'margin-top': '0px' }"
      >
      </boxxds-stepper-input>
    </div>

    <boxxds-button
      [isDisabled]="!isActive"
      (handleClick)="onSubmit()"
      [buttonText]="'common.add' | translate"
      [type]="'button'"
      btnType="secondary"
      [ngStyle]="{ 'margin-top': '30px' }"
      [btnStyle]="{ background: 'none', padding: '0 44px' }"
    >
    </boxxds-button>
  </div>
</form>
<hr />
