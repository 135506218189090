import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { getAlertHead, getErrorMessage } from 'src/app/utils/utils';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import {
  getQuoteSelector,
  getUIData,
} from 'src/app/store/create-quote/create-quote.selector';
import { Observable, take, firstValueFrom } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { BrokerageProducerCommissionService } from 'src/app/services/brokerage-producer-commission.service';
import { BrokerageCommissionService } from 'src/app/services/brokerage-commissions.service';
import { TranslateService } from '@ngx-translate/core';
import * as DashboardActionTypes from 'src/app/store/dashboard/dashboard.action';
import { BrokerageDirectoryService } from 'src/app/services/brokerage-directory.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PolicyService } from 'src/app/services/policy.service';
import { AlertService } from 'src/app/services/alert.service';
import { LocalStorageService } from 'src/app/services/localstorage-service';

@Component({
  selector: 'app-change-producer-popup-v2',
  templateUrl: './change-producer-popup-v2.component.html',
  styleUrls: ['./change-producer-popup-v2.component.less'],
})
export class ChangeProducerPopupV2Component {
  @Input() formSubmitted;
  @Input() form: FormGroup;
  @Input() regionId;
  @Input() details;

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleActionConfirm = new EventEmitter<any>();

  buttonDisabled: boolean = true;
  overwrite: boolean = true;
  checkValidation: boolean = false;
  showSpinner: boolean = false;

  policyId;
  brokerOptions: Array<any> = [];
  brokerageForm: FormGroup;
  brokerageErrMsg: string;
  noCommission: boolean = false;
  formChange: any;
  selectedBroker: string;
  selectedBranch: string;
  selectedProducer: string;
  brokerageId: string;
  public currentBrokerValue: string = '';
  alertInfo;
  selectedBrokerData: any;
  brokerageDetails: {
    branch: any;
    broker: any;
    producer: any;
    branchId: any;
    brokerageId: any;
    producerId: any;
  };
  form$: Observable<any>;
  currentScreen: any;
  permissionList: any;
  commissionDisabled: boolean = false;
  commissionId;
  noResultInfo = {
    label: this.translate.instant(
      'workFlow3.components.producerInformationComponent.label.newProducer',
    ),
    redirectionPath: '',
    showButtonRightIcon: true,
    buttonRightIconImagePath: 'assets/img/plus-circle-v2.svg',
  };
  noResultContent = this.translate.instant(
    'workFlow3.components.producerInformationComponent.label.noResult',
  );
  showBrokerRedirectModal: boolean = false;
  quoteFlowSteps: any;
  quoteFlowUrl: string;
  uiData: any;
  selectedPopupData: { [x: string]: any };

  constructor(
    private fb: FormBuilder,
    public brokerageDirectoryService: BrokerageDirectoryService,
    private brokerageProducerCommissionService: BrokerageProducerCommissionService,
    private brokerageCommissionService: BrokerageCommissionService,
    private translate: TranslateService,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private policyService: PolicyService,
    private alertService: AlertService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private elem: ElementRef,
  ) {
    this.brokerageForm = this.fb.group({
      brokerageBORId: ['', Validators.required],
      brokerageBranchBORId: ['', Validators.required],
      brokerageProducerBORId: ['', Validators.required],
      brokerageinfo: [''],
    });
  }

  async ngOnInit(): Promise<void> {
    this.policyId = this.activatedRoute.snapshot.paramMap.get('id');
    this.form$ = this.store.select(getQuoteSelector);
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
    });
    this.quoteFlowSteps = this.localStorageService.getQuoteFlowSteps();
    if (this.quoteFlowSteps === '7') {
      this.quoteFlowUrl = 'quote';
    } else if (this.quoteFlowSteps === '4') {
      this.quoteFlowUrl = 'quote4flow';
    } else {
      this.quoteFlowUrl = 'workflow3';
    }
    this.store.pipe(select(getUIData)).subscribe((data) => {
      this.uiData = data;
    });

    if (this.uiData?.isNavigatedFromChangeProducerPopup) {
      this.store.dispatch(
        new CreateQuoteAction.updateUiContents({
          isNavigatedFromChangeProducerPopup: false,
        }),
      );
      await this.handleStoreUpdateAndCommissionCheck(
        this.uiData?.producerChangeData ?? {},
      );
    }
  }

  closeHandler() {
    this.alertService.clearAlerts(-1);
    this.brokerageForm.reset();
    this.currentBrokerValue = '';
    this.noCommission = false;
    this.handleClose.emit();
  }

  actionConfirmHandler(event) {
    this.alertService.clearAlerts(-1);
    this.policyService
      .Update(this.policyId, this.brokerageForm.value)
      .subscribe((res) => {
        this.alertService.clearAlerts(-1);
        this.handleActionConfirm.emit(this.brokerageDetails);
        this.closeHandler();
        this.alertService.addAlert({
          type: 'success',
          headerText: getAlertHead('success'),
          bodyText: this.translate.instant('Producer updated successfully.'),
        });
      });
  }

  searchBroker(searchValue) {
    this.selectedPopupData = {};
    this.brokerageErrMsg = '';
    this.noCommission = false;
    this.brokerageForm.get('brokerageBORId').setValue('');
    this.brokerageForm.controls['brokerageBranchBORId'].setValue('');
    this.brokerageForm.controls['brokerageProducerBORId'].setValue('');
    this.selectedBroker = '';
    this.selectedBranch = '';
    this.selectedProducer = '';
    this.brokerageId = '';
    this.currentBrokerValue = '';
    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        broker: this.brokerageForm.value['broker'] ?? '',
        producer: this.brokerageForm.value['producer'] ?? '',
        branch: this.brokerageForm.value['branch'] ?? '',
        brokerageId: '',
        branchId: '',
        producerId: '',
      }),
    );
    if (searchValue === '') {
      this.brokerOptions = [];
    }
    if (searchValue.length >= 3) {
      const searchKey = searchValue.trim();
      this.brokerageDirectoryService
        .GetBrokerageDirectories(1, 50, '', true, true, true, searchKey, true)
        .subscribe({
          next: (response) => {
            const tableData =
              response.data.length === 0
                ? []
                : response.data?.map((dataObj) => ({
                    main_text:
                      dataObj?.brokerage?.name +
                      ' / ' +
                      dataObj?.brokerageBranch?.name +
                      ' / ' +
                      dataObj?.brokerageProducer?.name,
                    id: dataObj?.id,
                    disabled: !dataObj.active,
                    brokerage: dataObj?.brokerage,
                    branch: dataObj?.brokerageBranch,
                    producer: dataObj?.brokerageProducer,
                  }));
            this.brokerOptions = tableData
              .filter((x) => x.brokerage && x.branch && x.producer)
              .splice(0, 10);

            this.formSubmitted = false;
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.alertInfo = {
                alertType: 'error',
                alertHeader: this.translate.instant('common.errorMsg'),
                alertText: getErrorMessage(error),
              };
            } else {
              this.alertInfo = {
                alertType: 'error',
                alertHeader: this.translate.instant('common.errorMsg'),
                alertText: error ?? '' ?? error?.message ?? '',
              };
            }
          },
        });
    } else {
      return;
    }
  }

  removeFocus() {
    setTimeout(() => {
      document.getElementById('dummy-input').focus();
    }, 0);
  }

  async selectBroker(value) {
    /**
     * need to set values to the from while returning from producer profile screen.
     * there is a function in init() to load the values, need to write it properly.
     * clear the store once back to qlc screen.
     */
    let selectedValue = this.brokerOptions.find((x) => x.id === value);
    await this.handleStoreUpdateAndCommissionCheck(selectedValue);
  }

  async handleStoreUpdateAndCommissionCheck(selectedValue) {
    this.selectedPopupData = { ...selectedValue };
    this.selectedBrokerData = selectedValue;
    this.currentBrokerValue = selectedValue.main_text;
    this.brokerageForm.controls['brokerageinfo'].setValue(
      this.currentBrokerValue,
    );
    this.brokerageDetails = {
      branch: selectedValue.branch.name,
      broker: selectedValue.brokerage.name,
      producer: selectedValue.producer.name,
      branchId: selectedValue.branch.id,
      brokerageId: selectedValue.brokerage.id,
      producerId: selectedValue.producer.id,
    };
    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        broker: this.brokerageDetails?.broker ?? '',
        producer: this.brokerageDetails?.producer ?? '',
        branch: this.brokerageDetails?.branch ?? '',
        brokerageId: this.brokerageDetails?.brokerageId,
        branchId: this.brokerageDetails?.branchId,
        producerId: this.brokerageDetails?.producerId,
      }),
    );

    this.selectedBranch = selectedValue.branch;
    this.selectedBroker = selectedValue.brokerage;
    this.selectedProducer = selectedValue.producer;
    this.brokerageId = selectedValue?.brokerage.id;

    this.brokerageForm.setValue({
      brokerageBORId: selectedValue.brokerage.id,
      brokerageBranchBORId: selectedValue.branch.id,
      brokerageProducerBORId: selectedValue.producer.id,
      brokerageinfo: this.currentBrokerValue,
    });

    await this.handleCommissionCheck(selectedValue);
    this.removeFocus();
  }

  async handleCommissionCheck(selectedValue) {
    this.brokerageErrMsg = '';
    // check broker has commission
    const hasCommission =
      await this.hasCommissionForSelectedBroker(selectedValue);
    if (!hasCommission && selectedValue.main_text === this.currentBrokerValue) {
      this.noCommission = true;
      this.alertInfo = {
        alertType: 'error',
        alertHeader: this.translate.instant(
          'workFlow3.components.producerInformationComponent.error.noCommissionHead',
        ),
        alertText: this.translate.instant(
          'workFlow3.components.producerInformationComponent.error.noCommissionContent',
        ),
        alertLinkText: this.translate.instant(
          'workFlow3.components.producerInformationComponent.error.addCommission',
        ),
      };
    } else {
      this.noCommission = false;
    }
  }

  async hasCommissionForSelectedBroker(selectedBroker) {
    try {
      this.showSpinner = true;
      let brokerageId = selectedBroker?.brokerage?.id;
      let producerId = selectedBroker?.producer?.id;
      let regionId = this.regionId;
      let selectedProducts: any[];
      let riskIds = '';

      await this.form$.pipe(take(1)).subscribe({
        next: (event) => {
          if (!brokerageId) {
            brokerageId = event?.ui?.brokerageId;
          }
          if (!producerId) {
            producerId = event?.ui?.producerId;
          }
          regionId = event.ui.regionId;
          selectedProducts = event.products.filter((obj) => obj.checked);
          riskIds = selectedProducts.map((product) => product.riskId).join(',');
          // riskIds = '1'; //selectedProducts.map((product) => product.riskId).join(',');
        },
      });

      const getProducerCommission =
        this.brokerageProducerCommissionService.GetByRegionIdAndRiskIdsAndProducerId(
          regionId,
          riskIds,
          producerId,
          1,
        );
      const producerCommissionResponse = await firstValueFrom(
        getProducerCommission,
      );

      let producerCommissionNotExistsFor = [];
      if (producerCommissionResponse.data[0]) {
        riskIds
          ?.split(',')
          .map(Number)
          .forEach((riskId) => {
            const brokerForRiskId = producerCommissionResponse.data.filter(
              (broker) =>
                broker.active && broker?.riskRegion.risk.id === riskId,
            );
            this.commissionDisabled = producerCommissionResponse.data.some(
              (broker) => !broker.active,
            );
            this.commissionId =
              producerCommissionResponse.data.find((broker) => broker.id)?.id ||
              null;

            if (brokerForRiskId.length === 0) {
              producerCommissionNotExistsFor.push(riskId);
            }
          });
      } else {
        producerCommissionNotExistsFor.push(...riskIds.split(',').map(Number));
      }

      if (producerCommissionNotExistsFor.length > 0) {
        let riskIdWithoutCommission = [];
        const getBrokerCommission =
          this.brokerageCommissionService.GetByRegionIdAndRiskIdsAndBrokerageId(
            regionId,
            riskIds,
            brokerageId,
          );
        const brokerCommissionResponse =
          await firstValueFrom(getBrokerCommission);

        let riskIdsToAddCommission = [];
        if (brokerCommissionResponse.data.length === 0) {
          riskIdsToAddCommission = riskIds.split(',').map(Number);
          riskIdWithoutCommission = riskIdsToAddCommission;
        } else {
          riskIdsToAddCommission = producerCommissionNotExistsFor;
        }

        if (brokerCommissionResponse.data?.length > 0) {
          riskIdsToAddCommission.forEach((riskId) => {
            const commissionForRiskId = brokerCommissionResponse.data.filter(
              (broker) =>
                broker.active && broker?.riskRegion.risk.id === riskId,
            );
            this.commissionDisabled = brokerCommissionResponse.data.some(
              (broker) => !broker.active,
            );
            this.commissionId =
              brokerCommissionResponse.data.find((broker) => broker.id)?.id ||
              null;
            if (commissionForRiskId.length === 0) {
              riskIdWithoutCommission.push(riskId);
            }
          });
        }

        if (
          riskIdWithoutCommission?.length > 0 &&
          selectedBroker.main_text === this.currentBrokerValue
        ) {
          this.noCommission = true;
          this.showSpinner = false;
          return false;
        }
        this.showSpinner = false;
        return true;
      }
      this.showSpinner = false;
      return true;
    } catch (error) {
      if (![500].includes(error?.status)) {
        this.showSpinner = false;
        this.alertService.clearAlerts(-1);
        this.alertInfo = {
          alertType: 'error',
          alertHeader: this.translate.instant('common.errorMsg'),
          alertText: getErrorMessage(error),
        };
        this.alertService.addAlert(this.alertInfo);
      }
      return false;
    }
  }

  dropdownFocusOut(event) {
    this.selectedBroker = '';
    this.selectedBranch = '';
    this.selectedProducer = '';
    this.brokerageId = '';
  }

  noResultButtonclick(event) {
    const isNavigationStatus = event;
    this.showBrokerRedirectModal = true;
    if (isNavigationStatus) {
      this.store.dispatch(
        new DashboardActionTypes.updateDashboardConfigAction({
          isNavigatedFromAlert: isNavigationStatus,
        }),
      );
    }
  }

  handleLinkAction() {
    this.alertService.clearAlerts(-1);
    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        isNavigatedFromChangeProducerPopup: true,
        producerChangeData: this.selectedPopupData,
      }),
    );
    const url = `${this.brokerageForm.value['brokerageProducerBORId']}/${this.brokerageForm.value['brokerageBranchBORId']}/${this.brokerageForm.value['brokerageBORId']}/commission`;
    this.router.navigate(
      [`/dashboard/${this.quoteFlowUrl}/brokerage/producer/profile/${url}`],
      {
        relativeTo: this.activatedRoute,
        skipLocationChange: true,
      },
    );
  }
}
