<div class="dropdown-collection" [ngStyle]="dropdownContainerStyle">
  <button
    [ngStyle]="btnStyle"
    class="dropdown-button"
    aria-haspopup="listbox"
    aria-labelledby="dropdown-label"
    (click)="toggleDropdown($event)"
    (clickOutside)="dropdownOpen = false"
    [disabled]="disabled"
  >
    <ng-container class="dropdown-options">
      <span class="label-text">{{ placeholder }}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M5.26953 7.5L10.0783 12.5L14.887 7.5"
          stroke="#98A2B3"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </ng-container>
    <div class="drop-down-btn-wrapper">
      <button
        (click)="handleBtnClick()"
        [disabled]="disabled"
        [ngStyle]="dropdownBtnStyle"
      >
        Send
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
        >
          <path
            d="M9.24987 10.0003H4.66653M4.59599 10.2432L2.65022 16.0555C2.49736 16.5121 2.42093 16.7404 2.47578 16.881C2.52341 17.0031 2.62571 17.0957 2.75195 17.1309C2.89731 17.1715 3.11687 17.0727 3.55598 16.8751L17.4822 10.6083C17.9108 10.4154 18.1251 10.319 18.1913 10.185C18.2489 10.0686 18.2489 9.93203 18.1913 9.81564C18.1251 9.68168 17.9108 9.58524 17.4822 9.39237L3.55112 3.12342C3.11334 2.92641 2.89444 2.82791 2.74922 2.86832C2.62311 2.90341 2.52082 2.99573 2.47302 3.1176C2.41798 3.25793 2.4936 3.48574 2.64482 3.94137L4.59653 9.82159C4.6225 9.89984 4.63549 9.93897 4.64062 9.97899C4.64517 10.0145 4.64512 10.0504 4.64048 10.0859C4.63525 10.1259 4.62216 10.165 4.59599 10.2432Z"
            stroke="#B17806"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
  </button>
  <ul
    class="dropdown-list"
    role="listbox"
    aria-expanded="false"
    [ngClass]="{ 'dropdown-open': dropdownOpen }"
  >
    <li
      *ngFor="let op of options"
      id="dropdown-selected"
      tabindex="0"
      (click)="select(op)"
      role="option"
      [innerHTML]="op.key"
    ></li>
  </ul>
</div>
