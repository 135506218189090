<div class="branch-team-table-wrapper">
  <table class="branch-team-table" [ngStyle]="{ opacity: isLoading ? 0 : 1 }">
    <thead>
      <ng-container *ngFor="let col of tableColumns">
        <th *ngIf="!tableColumnsExcluded.includes(col)">
          <div class="head-col">
            <button
              class="clear-sort-btn"
              (click)="handleClearSort()"
              [ngStyle]="{
                visibility: currentSortColumn !== col ? 'hidden' : 'visible'
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M2.91602 7H11.0827"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <div
              class="col-sort-wrapper"
              [class.noPointerEvents]="tableColumnsExcludedSort.includes(col)"
              (click)="
                !tableColumnsExcludedSort.includes(col) && handleSort(col)
              "
            >
              <p class="btn-text">{{ col }}</p>
              <div
                class="sort-icon"
                [ngStyle]="{
                  visibility: currentSortColumn !== col ? 'hidden' : 'visible'
                }"
              >
                <img
                  src="assets/img/arrow-down.svg"
                  alt="sort-icon"
                  [ngStyle]="{
                    transform:
                      sortOrder === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
                    transitionDuration: '0.4s'
                  }"
                />
              </div>
            </div>
          </div>
        </th>
      </ng-container>
      <th>
        <div class="head-col"></div>
      </th>
    </thead>
    <tbody>
      <ng-container *ngIf="tableData.length > 0">
        <tr
          *ngFor="
            let rowData of tableData
              | paginate
                : {
                    id: paginationId,
                    itemsPerPage: 10,
                    currentPage: currentPage,
                    totalItems: pagination.totalRecords
                  };
            let tableDataIdx = index
          "
        >
          <ng-container *ngFor="let col of tableColumns">
            <td
              *ngIf="!tableColumnsExcluded.includes(col)"
              class="table-row"
              [ngStyle]="{
                width:
                  col === 'Name'
                    ? '320px'
                    : col === '# of Producers'
                    ? '150px'
                    : 'auto'
              }"
            >
              <div class="body-col-data">
                <ng-container [ngSwitch]="col">
                  <div *ngSwitchCase="'Name'" class="text-sm-medium ellipsis">
                    <ng-container *ngIf="isEditableColumn !== tableDataIdx">
                      <div class="text-with-edit-btn">
                        <p class="nameTxt ellipsis" title="{{ rowData[col] }}">
                          {{ rowData[col] }}
                        </p>
                        <button
                          class="edit-btn"
                          (click)="handleEditName(tableDataIdx, rowData[col])"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M2.39735 15.0973C2.43564 14.7527 2.45478 14.5804 2.50691 14.4194C2.55316 14.2765 2.61851 14.1405 2.70118 14.0152C2.79436 13.8739 2.91694 13.7513 3.16209 13.5061L14.1673 2.5009C15.0878 1.58043 16.5802 1.58043 17.5007 2.5009C18.4211 3.42138 18.4211 4.91376 17.5007 5.83424L6.49542 16.8395C6.25027 17.0846 6.1277 17.2072 5.98639 17.3004C5.86102 17.383 5.72506 17.4484 5.58219 17.4946C5.42115 17.5468 5.24887 17.5659 4.90429 17.6042L2.08398 17.9176L2.39735 15.0973Z"
                              stroke="#98A2B3"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="isEditableColumn === tableDataIdx">
                      <div class="edit-name-input-wrapper">
                        <input
                          type="text"
                          class="name-input"
                          [value]="editNameTxt"
                          (keyup)="handleNameChange($event)"
                        />
                        <div class="edit-action-btns">
                          <button
                            class="action-btn decline"
                            (click)="handleDeclineEditName()"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M15 5L5 15M5 5L15 15"
                                stroke="#98A2B3"
                                stroke-width="1.66667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </button>
                          <button
                            class="action-btn save"
                            (click)="handleSaveEditName()"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M16.6673 5L7.50065 14.1667L3.33398 10"
                                stroke="#98A2B3"
                                stroke-width="1.66667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div
                    *ngSwitchCase="'# of Producers'"
                    class="text-sm-regular ellipsis"
                    [ngStyle]="{
                      minWidth: '140px',
                      maxWidth: '140px',
                      textAlign: 'center'
                    }"
                    title="{{ rowData[col] }}"
                  >
                    {{ rowData[col] }}
                  </div>
                  <div
                    *ngSwitchCase="'Producers'"
                    class="text-xs-medium"
                    [ngStyle]="{
                      textAlign: 'start'
                    }"
                    title="{{ rowData[col].join(', ') }}"
                  >
                    {{
                      rowData[col].length > 0 ? rowData[col].join(", ") : "-"
                    }}
                  </div>
                </ng-container>
              </div>
            </td>
          </ng-container>
          <td [ngStyle]="{ width: '150px', textAlign: 'center' }">
            <button
              class="add-producer-btn text-sm-semibold"
              (click)="handleAddProducerClick($event, tableDataIdx)"
            >
              {{ "brokerage.producer.addProducer" | translate }}
            </button>
            <div
              class="add-producer-popup"
              *ngIf="tableDataIdx === addProducerPopupId"
              (clickOutside)="closeAddProducerPopup()"
              [ngStyle]="addProducerPopupPositionalStyles"
            >
              <boxxds-search-autocomplete-v2
                [maxLength]="'200'"
                [form]="form"
                [isActive]="true"
                [formSubmitted]="producerSubmitted"
                [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
                [items]="producerOptions"
                [label]="
                  'brokerage.producer.label.searchExistingProducer' | translate
                "
                [placeholder]="
                  'workFlow3.components.shared.placeholder.search' | translate
                "
                [required]="false"
                [control]="'producerInfo'"
                [autocapitalize]="'off'"
                [spellcheck]="'off'"
                [containerStyle]="{ width: '410px', textAlign: 'left' }"
                (searchQuery)="searchProducer($event)"
                (itemSelected)="selectProducer($event)"
                (textInputChangeEventEmmiter)="searchProducer($event)"
                (currentValueChange)="selectProducer($event)"
                [currentValue]="currentProducerValue"
                [dropdownContainerStyle]="{}"
                [labelStyle]="{ marginBottom: '8px' }"
                [showNoResultAction]="false"
                [showNoResultRedirectButton]="false"
                [permissionStatus]="true"
                class="search-dropdown"
              ></boxxds-search-autocomplete-v2>
              <div class="add-producer-popup-btn">
                <boxxds-button-v2
                  *ngIf="!producerAddBtnClicked"
                  [isDisabled]="
                    !selectedProducerData?.id || producerAddBtn2Clicked
                  "
                  [size]="'sm'"
                  [btnClass]="'secondary gray'"
                  [btnType]="'default'"
                  [isDropdown]="false"
                  [buttonText]="
                    'brokerage.producer.label.addNewProducer' | translate
                  "
                  [btnStyle]="{ padding: '10px 14px', width: '152px' }"
                  (handleClick)="handleAddProducer(tableDataIdx, true)"
                >
                </boxxds-button-v2>
                <button
                  *ngIf="producerAddBtnClicked"
                  class="btn-processing"
                  tabindex="0"
                  [ngStyle]="{
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #D0D5DD',
                    boxShadow: '0px 1px 2px 0px #1018280D',
                    color: '#2D2D39',
                    borderRadius: '8px',
                    width: '152px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px 14px',
                    lineHeight: '20px'
                  }"
                >
                  <boxxds-spinner></boxxds-spinner>
                </button>
                <boxxds-button-v2
                  *ngIf="!producerAddBtn2Clicked"
                  [isDisabled]="
                    !selectedProducerData?.id || producerAddBtnClicked
                  "
                  [size]="'sm'"
                  [btnClass]="'secondary gray'"
                  [btnType]="'default'"
                  [isDropdown]="false"
                  [buttonText]="'common.add' | translate"
                  [btnStyle]="{ padding: '10px 14px', width: '100px' }"
                  (handleClick)="handleAddProducer(tableDataIdx, false)"
                >
                </boxxds-button-v2>
                <button
                  *ngIf="producerAddBtn2Clicked"
                  class="btn-processing"
                  tabindex="0"
                  [ngStyle]="{
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #D0D5DD',
                    boxShadow: '0px 1px 2px 0px #1018280D',
                    color: '#2D2D39',
                    borderRadius: '8px',
                    width: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px 14px',
                    lineHeight: '20px'
                  }"
                >
                  <boxxds-spinner></boxxds-spinner>
                </button>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="tableData.length === 0">
        <tr>
          <td
            [colSpan]="tableColumns.length"
            [ngStyle]="{
              height: '504px'
            }"
          >
            <app-ripple-animation
              [message]="noDataMessage"
            ></app-ripple-animation>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <div
    class="table-footer"
    *ngIf="tableData.length !== 0 && !isLoading && pagination.totalPages > 1"
  >
    <div class="pagination-container">
      <pagination-template
        [id]="paginationId"
        #p="paginationApi"
        (pageChange)="onPageChange($event)"
      >
        <div class="tbl-pagination">
          <div
            class="tbl-pagination-prev"
            [ngClass]="{ 'prev-btn-disabled': p.isFirstPage() }"
          >
            <img src="assets/img/arrow-down-1.svg" />
            <a
              (keyup.enter)="p.previous()"
              (click)="p.previous()"
              [class.disabled]="p.isFirstPage()"
              class="tbl-page-link"
              tabindex="0"
              aria-label="Previous"
              >Previous</a
            >
          </div>
          <ul class="page-numbers-container">
            <li
              *ngFor="let page of p.pages"
              [class.active]="p.getCurrent() === page.value"
              class="tbl-page-item"
            >
              <a
                (keyup.enter)="p.setCurrent(page.value)"
                (click)="p.setCurrent(page.value)"
                class="tbl-page-link"
                tabindex="0"
              >
                {{ page.label }}
              </a>
            </li>
          </ul>
          <div
            class="pagination-next"
            [ngClass]="{ 'next-btn-disabled': p.isLastPage() }"
          >
            <a
              (keyup.enter)="p.next()"
              [class.disabled]="p.isLastPage()"
              (click)="p.next()"
              class="tbl-page-link"
              tabindex="0"
              >Next</a
            >
            <img src="assets/img/arrow-up-1.svg" />
          </div>
        </div>
      </pagination-template>
    </div>
  </div>
</div>
