import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Brokerage } from '../entities/brokerage';
import { environment } from 'src/environments/environment';
import { BoxxResponse } from '../entities/boxx-response';
import { BrokerageBranch } from '../entities/brokerage-branch';
import { IBrokerageBranchFilter } from '../filters/brokerageBranchFilters';

@Injectable({
  providedIn: 'root',
})
export class BrokerageBranchService {
  // Base url
  baseurl = environment.msBrokerBaseUrl + environment.brokerageBranchPathUrl;
  exportUrl = environment.msBrokerBaseUrl + environment.brokerageBranchExport;
  constructor(private http: HttpClient) {}
  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  // POST
  CreateBrokerageBranch(data): Observable<BrokerageBranch> {
    return this.http
      .post<BrokerageBranch>(
        this.baseurl,
        JSON.stringify(data),
        this.httpOptions,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetBranchByBrokerageId(
    id,
    page = 1,
    limit = 10,
    sort = '',
    active = null,
  ): Observable<BoxxResponse<BrokerageBranch>> {
    let url =
      this.baseurl +
      '?brokerageId=' +
      id +
      '&page=' +
      page +
      '&limit=' +
      limit +
      '&sort=' +
      sort;
    if (active) {
      url += '&active=' + active;
    }
    return this.http
      .get<BoxxResponse<BrokerageBranch>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  // GET
  GetBrokerageBranch(id): Observable<BoxxResponse<BrokerageBranch>> {
    return this.http
      .get<BoxxResponse<BrokerageBranch>>(`${this.baseurl}${id}`)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  // GET ALL
  GetBrokerageBranches(
    page = 1,
    limit = 10,
    sort = '',
  ): Observable<BoxxResponse<BrokerageBranch>> {
    return this.http
      .get<BoxxResponse<BrokerageBranch>>(
        this.baseurl + '?page=' + page + '&limit=' + limit + '&sort=' + sort,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }
  // PUT
  UpdateBrokerageBranch(id, data): Observable<BrokerageBranch> {
    return this.http
      .put<BrokerageBranch>(
        this.baseurl + id,
        JSON.stringify(data),
        this.httpOptions,
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }
  RestoreBrokerageBranch(id): Observable<BrokerageBranch> {
    return this.http
      .put<BrokerageBranch>(this.baseurl + 'restore/' + id, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  // DELETE
  DeleteBrokerageBranch(id) {
    return this.http
      .delete<BrokerageBranch>(this.baseurl + id, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  // Error handling
  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return throwError(() => {
      return errorMessage;
    });
  }

  GetBrokerageBranchesByFilter(
    filter: IBrokerageBranchFilter,
    sort = '',
  ): Observable<BoxxResponse<BrokerageBranch>> {
    let params = new HttpParams();

    if (filter.brokerageId) {
      params = params.append('brokerageId', filter.brokerageId);
    }

    if (filter.locationId) {
      params = params.append('locationId', filter.locationId);
    }

    if (filter.regionId) {
      params = params.append('regionId', filter.regionId);
    }

    if (filter.name) {
      params = params.append('name', filter.name);
    }

    params = params.append('page', filter.page ? filter.page : 1);
    params = params.append('limit', filter.limit ? filter.limit : 10); // limit was hardcoded here as 100
    if (sort != '') {
      params = params.append('sort', sort);
    }

    if (filter.active) {
      params = params.append('active', filter.active);
    }

    return this.http
      .get<BoxxResponse<BrokerageBranch>>(`${this.baseurl}`, {
        params: params,
      })
      .pipe(retry(1), catchError(this.errorHandl));
  }

  //branch export
  ExportBrokerageBranchDetails(
    filter,
    limit = 10,
    sort = '',
  ): Observable<ArrayBuffer> {
    var url = `${this.exportUrl}?limit=${limit}`;
    if (filter.page) {
      url += '&page=' + filter.page;
    }
    if (filter.brokerageId) {
      url += '&brokerageId=' + filter.brokerageId;
    }
    if (filter?.locationId) {
      url += '&locationId=' + filter.locationId;
    }
    if (filter?.createdDt) {
      url += '&createdDt=' + filter.createdDt;
    }
    if (filter?.regionId) {
      url += '&regionId=' + filter.regionId;
    }
    if (filter?.name) {
      url += '&name=' + filter.name;
    }
    if (filter?.page) {
      url += '&page=' + filter.page;
    }
    if (sort) {
      url += '&sort=' + sort;
    }
    if (filter.active) {
      url += '&active=' + filter.active;
    }
    return this.http
      .get(url, { responseType: 'arraybuffer' })
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
