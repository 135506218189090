import { Component } from '@angular/core';

@Component({
  selector: 'app-insured',
  templateUrl: './insured.component.html',
  styleUrls: ['./insured.component.less'],
})
export class InsuredComponent {
  constructor() {}
}
