<div class="form-group-cl-5">
  <span
    class="span-margin"
    [ngClass]="!commissionItem.active ? 'text-disable' : ''"
    >{{ commissionItem.riskRegion.risk.name }}</span
  >

  <span
    class="span-margin"
    [ngClass]="!commissionItem.active ? 'text-disable' : ''"
    >{{ commissionItem.riskRegion.region.name }}</span
  >

  <span
    class="span-margin"
    [ngClass]="!commissionItem.active ? 'text-disable' : ''"
    >{{ commissionItem.commissionMin }}%</span
  >

  <span
    class="span-margin"
    [ngClass]="!commissionItem.active ? 'text-disable' : ''"
    >{{ commissionItem.commissionMax }}%</span
  >
  <span
    class="span-margin"
    [ngClass]="!commissionItem.active ? 'text-disable' : ''"
    >{{ commissionItem.commissionDefault }}%</span
  >
</div>
