import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-info-popup',
  templateUrl: './info-popup.component.html',
  styleUrls: ['./info-popup.component.less'],
})
export class InfoPopupComponent {
  constructor() {}

  @Input() left: number = 0;
  @Input() top: number = 0;
  @Input() content: any = '';
  @Input() title: string = '';
  @Output() closed = new EventEmitter<string>();
  @Input() popupContainerStyle: { [klass: string]: any } = {};
  @Input() titleStyle: { [klass: string]: any } = {};
  @Input() contentStyle: { [klass: string]: any } = {};

  closePopup(): void {
    this.closed.emit();
  }
}
