import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Breadcrumb } from 'src/app/models/breadcrumb.model';
import { BoxxResponse, Pagination } from 'src/app/entities/boxx-response';
import { RoleService } from 'src/app/services/roles.service';
import { Role } from 'src/app/entities/role';
import { RolePermissionService } from 'src/app/services/role-permissions.service';
import { SystemUserService } from 'src/app/services/system-user.service';
import { Permission } from 'src/app/entities/permission';
import { PermissionService } from 'src/app/services/permission.service';
import { getBoolean, getErrorMessage } from 'src/app/utils/utils';
import { formatDateTime } from 'src/app/utils/formatDate';
import { SystemUser } from 'src/app/entities/system-user';
import {
  getDashboardSelector,
  getUserTypes,
} from 'src/app/store/dashboard/dashboard.selector';
import { Store, select } from '@ngrx/store';
import { SYSTEM_USER_TYPES } from 'src/app/constants/security/systemUserType';

@Component({
  selector: 'app-role-details',
  templateUrl: './role-details.component.html',
  styleUrls: ['./role-details.component.less'],
  providers: [],
})
export class RoleDetailsComponent implements OnInit {
  formatDateTime = formatDateTime;
  isRoleNameEditMode = false;
  subNavData;
  tableData = [];
  role: any;
  itemsMenu: Breadcrumb[] = [
    { label: 'Roles & permissions', path: '/dashboard/security/roles' },
  ];
  showFilter = false;
  isInsuredTypeCompany = true;
  totalDataCount = 0;
  pagination: Pagination;
  type: 0;
  filterForm: FormGroup;
  isSearchResult: boolean = false;
  roleId: string = '';
  permissions: any = [];
  members: any = [];
  permissionsDataCount = 0;
  permissionsPagination: Pagination;
  membersDataCount = 0;
  membersPagination: Pagination;
  suggestedPermissions: any = [];
  suggestedMembers: any = [];
  showPermissionSpinner: boolean = false;
  addPermissionInProgress: boolean = false;
  editColumns: any = [];
  submitted: boolean = false;
  docOptions: Array<any> = [
    { key: '1', value: 'Permission 1', isAdded: true },
    { key: '2', value: 'Permission 2', isAdded: false },
  ];
  form = new FormGroup({
    readWrite: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
  });
  searchQuery: '';

  requiredErrMsg: string = 'This field is required';
  invalidErrMsg: string = 'This field is invalid';
  isSuccess = false;
  hasError = false;
  errorMessage: any;
  showSpinner: boolean = false;
  formSubmitted: boolean = false;
  addSortTo: string | Array<string> = '';
  addMemberSort: string | Array<string> = '';
  sortBy: string;
  sortColumn: string = 'id';
  sortDirection: string = 'desc';
  pageNumber: number = 1;
  expandableData: any;
  expandableDataMember: any;
  createdDt: any;
  updatedDt: any;
  showTblSpinner: boolean = false;
  showErrorAlert = false;
  msgErrorAlert = '';
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  currentUserRollId: number = undefined;
  showReloadModal: boolean = false;
  shortDateTimeFormat: string = '';
  longDateTimeFormat: string = '';
  userTypes: Array<any> = [];

  constructor(
    private router: Router,
    private activedRoute: ActivatedRoute,
    private roleService: RoleService,
    private rolePermissionService: RolePermissionService,
    private systemUserService: SystemUserService,
    private permissionService: PermissionService,
    private store: Store,
  ) {
    this.editColumns = [
      { column: 'Write access', type: 'checkbox' },
      { column: 'Permission description', type: 'text' },
    ];
    this.addSortTo = ['Permission name'];
    this.addMemberSort = ['Member name', 'Status', 'Type'];
    this.sortBy = this.sortColumn + ':' + this.sortDirection;
  }

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.currentUserRollId = data.userRollId;
      this.shortDateTimeFormat = data.shortDateTimeFormat;
      this.longDateTimeFormat = data.longDateTimeFormat;
    });

    this.subNavData = [
      { name: `${'Permissions( )'}`, active: true },
      { name: `${'Members( )'}`, active: false },
    ];
    this.roleId = this.activedRoute.snapshot.paramMap.get('roleId');

    this.store.pipe(select(getUserTypes)).subscribe(async (data) => {
      const dropdownData = data.map((dataObj) => ({
        key: dataObj.description,
        value: dataObj.id,
        id: dataObj.id,
      }));
      this.userTypes = dropdownData;
    });

    this.handleNav('click', 0);
    this.fetchRole(this.roleId);
    this.fetchPermissions(this.roleId);
    this.fetchMembers(this.roleId);
  }

  fetchRole(roleId) {
    this.roleService.GetRole(roleId).subscribe({
      next: (response) => {
        this.setRoleSearchResult(response);
      },
      error: (error) => {
        if (![500].includes(error?.status)) {
          this.showErrorAlert = true;
        }
        this.msgErrorAlert = getErrorMessage(error);
      },
    });
  }

  setRoleSearchResult(data: BoxxResponse<Role>) {
    const { data: role } = data;
    this.role = role;
    this.createdDt = formatDateTime(
      this.role.createdDt,
      this.longDateTimeFormat,
    );
    this.updatedDt = formatDateTime(
      this.role.updatedDt,
      this.longDateTimeFormat,
    );
    this.itemsMenu.push({ label: this.role.name, path: null });
  }

  async fetchPermissions(roleId, isRemovePermission = false) {
    this.showTblSpinner = true;
    if (isRemovePermission) {
      if (this.permissions.length === 1 && this.pageNumber !== 1) {
        this.pageNumber -= 1;
      }
    }
    this.rolePermissionService
      .GetAllPermissions(roleId, this.sortBy, this.pageNumber, 10)
      .subscribe({
        next: (response) => {
          this.showTblSpinner = false;
          this.setPermissionsResult(response);
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
  }

  setPermissionsResult(data: BoxxResponse<Permission>) {
    const tableData = data.data?.map((dataObj) => {
      const { id, permission, readWrite, description } = dataObj;
      return {
        'Permission name': permission.permissionName,
        Screen: `${permission.Screen.description}`,
        'Write access': readWrite ? 'Yes' : 'No',
        'Permission description': description ?? '',
      };
    });
    const expandableData = data.data?.map((dataObj) => {
      return {
        id: `${dataObj.id}`,
        createdDt: `${dataObj.createdDt}`,
        createdBy: dataObj.createdBy,
        updatedBy: dataObj.updatedBy,
        updatedDt: dataObj.updatedDt,
      };
    });
    this.expandableData = expandableData;
    this.permissions =
      tableData.length == 0 ? this.getPermissionHeaders() : tableData;
    this.permissionsDataCount = data.pagination.totalRecords;
    this.permissionsPagination = data.pagination;
    this.subNavData[0].name = `Permissions (${this.permissionsDataCount})`;
  }
  getViewUrl(id) {
    return `/dashboard/security/roles/${id}`;
  }

  getPermissionHeaders() {
    return [
      {
        'Permission name': '',
        Screen: '',
        'Write access': '',
        'Permission description': '',
      },
    ];
  }

  searchPermisions(name) {
    this.searchQuery = name;
    const { systemUserType } = this.role;
    if (name.length >= 3) {
      this.permissionService
        .GetByNameNsystemUserType(
          1,
          10,
          'permissionName:asc',
          name,
          systemUserType,
        )
        .subscribe({
          next: (response) => {
            const tableData = response.data?.map((dataObj) => ({
              key: `${dataObj.permissionName}`,
              value: dataObj.id,
              isAdded: false,
            }));
            this.suggestedPermissions =
              tableData.length == 0
                ? this.returnEmptyPermission()
                : [...tableData];
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    } else {
      this.suggestedPermissions = [];
    }
  }

  returnEmptyPermission() {
    return [{ key: 'not found', value: 'fds', isAdded: true }];
  }

  handleAddPermission(key) {
    this.hasError = false;
    const data = {
      roleId: this.roleId,
      permissionsId: key,
      readWrite: 0,
      description: 'sample description',
      active: 1,
    };
    this.rolePermissionService.CreateRolePermission(data).subscribe(
      async (response) => {
        if (this.currentUserRollId === +this.roleId) {
          this.showReloadModal = true;
        } else {
          this.isSuccess = true;
        }
        await this.fetchPermissions(this.roleId, false);
      },
      ({ error }) => {
        this.hasError = true;
        if (error.message) this.errorMessage = error.message;
        else this.errorMessage = error;
      },
    );
  }

  permissionPageChangeHandler(pageNumber) {
    this.pageNumber = pageNumber;
    if (pageNumber > 0) {
      this.rolePermissionService
        .GetAllPermissions(this.roleId, this.sortBy, pageNumber)
        .subscribe({
          next: (response) => {
            this.setPermissionsResult(response);
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    }
  }

  fetchMembers(roleId) {
    this.showTblSpinner = true;
    this.systemUserService
      .GetSystemUsersByRole(roleId, this.sortBy, this.pageNumber)
      .subscribe({
        next: (response) => {
          this.showTblSpinner = false;
          this.setMembersResult(response);
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = error?.message;
        },
      });
  }

  setMembersResult(data: BoxxResponse<SystemUser>) {
    const tableData = data.data?.map((dataObj) => {
      const { permission } = dataObj;
      return {
        'Member name': this.getName(dataObj),
        Type: this.getType(dataObj.SystemUserType.id),
        Status: dataObj.active ? 'Active' : 'Inactive',
        ActionView: `${this.userProfileUrl(
          dataObj.id,
          dataObj.SystemUserType.id,
        )}`,
      };
    });
    const expandableDataMember = data.data?.map((dataObj) => {
      return {
        id: `${dataObj.id}`,
        createdDt: `${dataObj.createdDt}`,
        createdBy: dataObj.createdBy,
        updatedBy: dataObj.updatedBy,
        updatedDt: dataObj.updatedDt,
      };
    });
    this.expandableDataMember = expandableDataMember;
    this.members = tableData.length == 0 ? this.getMembersHeaders() : tableData;
    this.membersDataCount = data.pagination.totalRecords;
    this.membersPagination = data.pagination;

    this.subNavData[1].name = `Members (${this.membersDataCount})`;
  }

  userProfileUrl(id, type) {
    return `/dashboard/security/user/${id}/type/${type}`;
  }

  getMembersHeaders() {
    return [
      {
        'Member name': '',
        Type: '',
        Status: '',
      },
    ];
  }

  getType(systemUserType: number) {
    let type: string = '';
    type = this.userTypes.find((item) => item.id === systemUserType)?.key;
    return type;
  }

  getName(user): string {
    let name: string = '';
    this.userTypes.find((item) => item.id === user.SystemUserType.id)?.key;
    let filteredUser = this.userTypes.filter(
      (item) => item.id === user.SystemUserType.id,
    );
    let userType = this.convertToCamelCase(filteredUser[0].key.toLowerCase());
    if (userType === SYSTEM_USER_TYPES.Insured.toLowerCase()) {
      return (name = this.getInsuredName(user[userType]));
    } else {
      return (name = this.getFullName(user[userType]));
    }
  }

  getFullName(role): string {
    if (role !== null) {
      return role.firstName + ' ' + role.lastName;
    }
    return '';
  }

  convertToCamelCase(userType) {
    return userType
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, '');
  }

  getInsuredName(role): string {
    if (role !== null) {
      return role.insuredType === 1
        ? role.firstName + ' ' + role.lastName
        : role.companyName;
    }
    return '';
  }

  searchMembers(name) {
    this.searchQuery = name;
    const { systemUserType } = this.role;
    this.systemUserService
      .GetSystemUsers(1, 10, this.sortBy, systemUserType, name, '1')
      .subscribe({
        next: (response) => {
          const tableData = response.data?.map((dataObj) => ({
            key: this.getName(dataObj),
            value: dataObj.id,
            isAdded: false,
          }));
          this.suggestedMembers =
            tableData.length == 0 ? this.returnEmptyMember() : tableData;
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
  }

  returnEmptyMember() {
    return [{ key: 'not found', value: 'fds', isAdded: true }];
  }

  handleAddMember(key) {
    const data = {
      roleId: this.roleId,
    };
    this.systemUserService.UpdateSystemUser(key, data).subscribe(
      (response) => {
        this.fetchMembers(this.roleId);
      },
      ({ error }) => {
        this.hasError = true;
        if (error.message) this.errorMessage = error.message;
        else this.errorMessage = error;
      },
    );
  }

  memberPageChangeHandler(pageNumber) {
    if (pageNumber > 0) {
      this.pageNumber = pageNumber;
      this.systemUserService
        .GetSystemUsersByRole(this.roleId, this.sortBy, pageNumber)
        .subscribe({
          next: (response) => {
            this.setMembersResult(response);
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    }
  }

  handleAttachSubjectives(event, index) {}

  handleNav(event, index) {
    this.pageNumber = 1;
    this.sortBy = 'id:desc';
    this.type = index;
    const currentActiveIdx = this.subNavData.findIndex(
      (navObj) => navObj.active,
    );
    this.subNavData[currentActiveIdx].active = false;
    this.subNavData[index].active = true;
    index === 1
      ? this.systemUserService
          .GetSystemUsersByRole(this.roleId, this.sortBy, 1)
          .subscribe({
            next: (response) => {
              this.setMembersResult(response);
            },
            error: (error) => {
              if (![500].includes(error?.status)) {
                this.showErrorAlert = true;
              }
              this.msgErrorAlert = getErrorMessage(error);
            },
          })
      : this.rolePermissionService
          .GetAllPermissions(this.roleId, this.sortBy, 1)
          .subscribe({
            next: (response) => {
              this.setPermissionsResult(response);
            },
            error: (error) => {
              if (![500].includes(error?.status)) {
                this.showErrorAlert = true;
              }
              this.msgErrorAlert = getErrorMessage(error);
            },
          });
  }
  handleSearch(event) {
    if (event.key == 'Enter') {
    }
  }
  pageChangeHandler(pageNumber) {}
  statusActionBtnClicked(e) {}

  togglePolicyFeeEdit(event, isRoleNameEditMode) {
    return this.router.navigate(
      [`/dashboard/security/roles/edit/${this.roleId}`],
      { skipLocationChange: true },
    );
  }

  savePermission(data) {
    this.formSubmitted = true;
    this.hasError = false;
    const permissionId = this.expandableData[data.index].id;
    const requestData = {
      description: `${data.form['Permission description']}`,
      readWrite: this.formatReadwriteText(`${data.form['Write access']}`),
    };
    this.showSpinner = true;
    this.rolePermissionService
      .UpdateRolePermission(permissionId, requestData)
      .subscribe(
        (response) => {
          if (this.currentUserRollId === +this.roleId) {
            this.showReloadModal = true;
          } else {
            this.isSuccess = true;
          }
          this.permissions[data.index]['Permission description'] =
            data.form['Permission description'];
          this.permissions[data.index]['Write access'] = data.form[
            'Write access'
          ]
            ? 'Yes'
            : 'No';
          this.showSpinner = false;
          this.formSubmitted = false;
          this.hasError = false;
        },
        ({ error }) => {
          this.showSpinner = false;
          this.hasError = true;
          this.errorMessage = "Write access should be 'Yes' or 'No'";
        },
      );
  }

  formatReadwriteText(input: string) {
    if (input) {
      if (getBoolean(input)) return '1';
      else return '0';
    } else return '0';
  }

  handleCloseSuccessEvent() {
    this.isSuccess = false;
    this.hasError = false;
  }
  handleCloseSuccessEventExt() {
    this.showErrorAlert = false;
  }

  handleCloseReloadWarning() {
    this.showReloadModal = false;
  }

  deletePermission(data: any) {
    const permissionId = this.expandableData[data.index].id;
    this.rolePermissionService.deleteRolePermission(permissionId).subscribe({
      next: (_) => {
        if (this.currentUserRollId === +this.roleId) {
          this.showReloadModal = true;
        } else {
          this.isSuccess = true;
        }
        this.formSubmitted = false;
        this.hasError = false;
        this.fetchPermissions(this.roleId, true);
      },
      error: (error) => {
        this.showSpinner = false;
        if (![500].includes(error?.status)) {
          this.hasError = true;
        }
        this.errorMessage = getErrorMessage(error);
      },
    });
  }

  mapSortColumns(key) {
    switch (key) {
      case 'Permission name':
        this.sortColumn = 'permission.permissionName';
        break;
      default:
        this.sortColumn = 'id';
        break;
    }
  }

  handleAscSort(key) {
    if (key) {
      this.mapSortColumns(key);
    }
    this.sortDirection = 'asc';
    this.sortBy = this.sortColumn + ':' + this.sortDirection;
    this.fetchPermissions(this.roleId);
  }

  handleDescSort(key) {
    if (key) {
      this.mapSortColumns(key);
    }
    this.sortDirection = 'desc';
    this.sortBy = this.sortColumn + ':' + this.sortDirection;
    this.fetchPermissions(this.roleId);
  }

  mapMemberSortColumns(key) {
    switch (key) {
      case 'Member name':
        this.sortColumn = 'name';
        break;
      case 'Status':
        this.sortColumn = 'active';
        break;
      case 'Type':
        this.sortColumn = 'SystemUserType.description';
        break;
      default:
        this.sortColumn = 'id';
        break;
    }
  }

  handleAscMemberSort(key) {
    if (key) {
      this.mapMemberSortColumns(key);
    }
    this.sortDirection = 'asc';
    this.sortBy = this.sortColumn + ':' + this.sortDirection;
    this.fetchMembers(this.roleId);
  }

  handleDescMemberSort(key) {
    if (key) {
      this.mapMemberSortColumns(key);
    }
    this.sortDirection = 'desc';
    this.sortBy = this.sortColumn + ':' + this.sortDirection;
    this.fetchMembers(this.roleId);
  }
}
