<div
  class="status-badge-wrapper"
  [ngClass]="getBadgeStyle()"
  [ngStyle]="{
    display: getStatusDisplayText() === '' ? 'none' : 'flex'
  }"
>
  <p class="status-text">{{ getStatusDisplayText() }}</p>
  <div
    class="status-icon-wrapper"
    *ngIf="isStatusHavingIcon()"
    (mouseenter)="tooltipInfo.showTooltip($event)"
  >
    <img class="status-icon" [src]="getIcon()" />
    <div class="reason-tooltip">
      <app-info-tooltip-v2
        #tooltipInfo
        [tooltipObject]="setupTooltip(rowData, columnName)"
      ></app-info-tooltip-v2>
    </div>
  </div>
</div>
