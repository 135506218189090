import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BoxxResponse } from '../entities/boxx-response';
import { Policy } from '../entities/policy';
import { BaseService } from './base-services';

@Injectable({
  providedIn: 'root',
})
export class PolicyDuplicateQuoteService extends BaseService<any> {
  constructor(http: HttpClient) {
    const baseurl = environment.msPolicyBaseUrl + environment.duplicateQuote;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  CreateQuote(data): Observable<any> {
    const url = this.baseurl;
    return this.http
      .post<Policy>(url, JSON.stringify(data), this.httpOptions)
      .pipe(catchError(this.errorHandl));
  }
}
