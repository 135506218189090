import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-restricted-industry-warning',
  templateUrl: './restricted-industry-warning.component.html',
  styleUrls: ['./restricted-industry-warning.component.less'],
})
export class RestrictedIndustryWarningComponent {
  @Input() containerStyle: { [klass: string]: any };
  @Input() isUASubmission: boolean = false;
}
