<div class="holder">
  <boxxds-breadcrumb [items]="itemsMenu"></boxxds-breadcrumb>
  <boxxds-alert
    *ngIf="showErrorAlert"
    (handleCloseEvent)="handleCloseSuccessEventExt()"
    type="error"
    headerText="error!"
    [bodyText]="msgErrorAlert"
  >
  </boxxds-alert>
  <div class="d-flex justify-content-between">
    <boxxds-heading
      sz="5"
      [ngStyle]="{ marginBottom: '50px' }"
      [text]="'Roles & Permissions' | translate"
    >
    </boxxds-heading>
  </div>
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>

  <ng-container *ngIf="!showBodySpinner">
    <div class="company-container">
      <boxxds-alert
        *ngIf="isSuccess"
        (handleCloseEvent)="handleCloseSuccessEvent()"
        type="success"
        headerText="success!"
        bodyText="Saved successfully."
      >
      </boxxds-alert>

      <boxxds-alert
        *ngIf="hasError"
        (handleCloseEvent)="handleCloseSuccessEvent()"
        type="error"
        headerText="error!"
        [bodyText]="errorMessage"
      >
      </boxxds-alert>
      <div class="create-title-container">
        <boxxds-body
          sz="lg"
          [text]="'New role details' | translate"
          [customStyle]="{
            color: '#616162',
            textAlign: 'left',
            marginBottom: '8px',
            fontSize: '16px'
          }"
        ></boxxds-body>
        <span>*REQUIRED</span>
      </div>

      <form [formGroup]="roleForm">
        <div class="search-container">
          <div class="add-container">
            <div class="d-flex">
              <boxxds-textinput
                [form]="roleForm"
                [control]="'name'"
                [placeholder]="'Enter name' | translate"
                [id]="'roleName'"
                [width]="'250px'"
                [label]="('Role name' | translate) + '*'"
                [containerStyle]="{ marginRight: '40px', marginBottom: '10px' }"
                [requiredErrMsg]="requiredErrMsg"
                [invalidErrMsg]="invalidErrMsg"
                [formSubmitted]="formSubmitted"
                maxLength="150"
              >
              </boxxds-textinput>
              <boxxds-dropdown
                [form]="roleForm"
                [control]="'systemUserType'"
                [label]="'Select system user type*'"
                [insideSearch]="false"
                [options]="systemUserTypes"
                placeholder="Select"
                (currentValueChange)="dropdownValueChanged($event)"
                [requiredErrMsg]="requiredErrMsg"
                [formSubmitted]="formSubmitted"
                [dropdownContainerStyle]="{ width: '250px' }"
                [currentValue]="systemUserType"
              >
              </boxxds-dropdown>
            </div>
            <boxxds-textinput
              [form]="roleForm"
              [control]="'description'"
              [placeholder]="'Enter description' | translate"
              [id]="'description'"
              [width]="'755px'"
              [label]="'Enter role description*' | translate"
              [containerStyle]="{ marginTop: '30px', marginBottom: '10px' }"
              [requiredErrMsg]="requiredErrMsg"
              [invalidErrMsg]="invalidErrMsg"
              [formSubmitted]="formSubmitted"
              maxLength="200"
            >
            </boxxds-textinput>
          </div>
        </div>
      </form>
    </div>
    <div class="btn-container float-right">
      <boxxds-button
        *ngIf="!showSpinner"
        [buttonText]="'Add role' | translate"
        btnType="secondary"
        [btnStyle]="{
          border: '1px solid #FFBE3D',
          bordeRadius: '8px',
          padding: '0 30px 0 30px'
        }"
        type="button"
        (click)="onSubmit(roleForm.value)"
      >
      </boxxds-button>
      <button
        *ngIf="showSpinner"
        class="btn-processing"
        tabindex="0"
        [ngStyle]="{
          border: '1px solid #FFBE3D',
          borderRadius: '8px',
          width: '150px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '4px 8px',
          background: '#FCF8EF'
        }"
      >
        <boxxds-spinner></boxxds-spinner>
      </button>
    </div>
  </ng-container>
</div>
