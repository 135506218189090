<div class="table-pagination-holder">
  <div [ngStyle]="tblContainerStyle">
    <div *ngIf="tblHeading !== ''" class="tbl-heading">{{ tblHeading }}</div>
    <table [id]="id" [ngStyle]="tableStyle">
      <thead>
        <tr class="tbl-hd" [ngStyle]="headingStyle">
          <th *ngIf="showStar"></th>
          <ng-container
            *ngFor="let key of tableHeaders; let indexOfHeaders = index"
          >
            <ng-container *ngIf="!columnHeadsToHide.includes(key)">
              <th
                scope="col"
                *ngIf="
                  !(
                    (showLinks && key === 'ActionView') ||
                    key === 'showStar' ||
                    key === 'showViewButton' ||
                    key === 'popOverContent' ||
                    key === 'showEditIcon'
                  )
                "
              >
                <div
                  class="header"
                  *ngIf="
                    !(
                      showLinks &&
                      (key === 'recordStatus' || key === 'ActionView')
                    )
                  "
                >
                  <div
                    class="d-flex"
                    [ngClass]="{ 'sort-col': isColumnSortable(key) }"
                  >
                    <button
                      class="remove-sort-btn"
                      *ngIf="key !== '' && key === activeSort"
                      (click)="handleSortRemove()"
                    >
                      <img
                        class="remove-sort-img"
                        [src]="'assets/img/minus.svg'"
                      />
                    </button>
                    <span
                      (click)="
                        isColumnSortable(key) &&
                          handleSort($event, indexOfHeaders, key)
                      "
                    >
                      <label
                        [ngClass]="{ 'th-label': isColumnSortable(key) }"
                        >{{ key }}</label
                      >
                      <img
                        *ngIf="isColumnSortable(key) && key === activeSort"
                        class="table-sort"
                        [src]="
                          isAscending
                            ? 'assets/img/arrow-down-1.svg'
                            : 'assets/img/arrow-up-1.svg'
                        "
                      />
                      <img
                        *ngIf="isColumnSortable(key) && key !== activeSort"
                        class="table-sort sort-img"
                        [src]="'assets/img/arrow-up-1.svg'"
                      />
                    </span>
                  </div>
                </div>
              </th>
            </ng-container>
          </ng-container>
          <th *ngIf="showToggle"></th>
          <th *ngIf="showEllipsis || isRowExpandable"></th>
          <th *ngIf="showEditLink"></th>
          <ng-container *ngIf="showLinks">
            <th *ngFor="let l of linkDetails"></th>
          </ng-container>
          <ng-container *ngIf="inlineEditProps.length > 0">
            <th *ngFor="let col of [1, 2]"></th>
          </ng-container>
          <th *ngIf="showViewButton"></th>
          <th *ngIf="showEditIcon"></th>
        </tr>
      </thead>
      <tbody>
        <ng-container>
          <tr>
            <td>
              <span>
                <!-- <boxxds-dropdown-v2
                  [form]="form"
                  [control]="'province'"
                  [placeholder]="
                    'workFlow3.components.newSubmission.placeholder.state'
                      | translate
                  "
                  [options]="provinceOrStateList"
                  [label]="
                    province ?? 'workFlow3.components.newSubmission.label.state'
                      | translate
                  "
                  [btnStyle]="{ marginTop: '8px', maxWidth: '186px' }"
                  [dropdownContainerStyle]="{
                    width: '177px',
                    fontFamily: 'Gibson'
                  }"
                  class="input-fields"
                  [requiredErrMsg]="
                    'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                      | translate
                  "
                  [isRequired]="true"
                  [formSubmitted]="checkValidation"
                  class="province"
                  [currentValue]="form?.value?.province"
                  [currentSelectedOption]="currentProvinceObj"
                  [isDisabled]="
                    !this.permissionList[this.currentScreen] || !isActive
                  "
                  [hasScrollablePagination]="true"
                  [totalCount]="regionTotalRecords"
                  [totalPages]="regionTotalPages"
                  (loadMoreOptions)="
                    this.permissionList[this.currentScreen] &&
                      loadMoreRegion($event)
                  "
                  (currentValueChange)="
                    this.permissionList[this.currentScreen] &&
                      handleProvinceChange($event)
                  "
                  [isSuggestedOption]="selectedFromSuggestion"
                  [isReadonly]="insuredData && !insuredData?.active"
                >
                </boxxds-dropdown-v2> -->

                <boxxds-dropdown-v2
                  [placeholder]="
                    'workFlow3.commissions.placeholder.product' | translate
                  "
                  [options]="products"
                  [form]="formCommission"
                  [control]="'productName'"
                  class="input-fields"
                  [formSubmitted]="submitted"
                  [isRequired]="true"
                  [isDisabled]="
                    brokerageId ? !isBrokerActive : !isProducerActive
                  "
                  [currentValue]="formCommission?.value?.productName"
                  (currentValueChange)="handleAddProductChange($event)"
                  [requiredErrMsg]="
                    'workFlow3.commissions.error.requiredErrMsg' | translate
                  "
                  [currentSelectedOption]="currentProductObj"
                  [isDisabled]="activeStatus === false"
                >
                </boxxds-dropdown-v2>
              </span>
            </td>
            <td>
              <span>
                <boxxds-dropdown-v2
                  [placeholder]="
                    'workFlow3.commissions.placeholder.state' | translate
                  "
                  [options]="regions"
                  [form]="formCommission"
                  [control]="'regionName'"
                  class="input-fields"
                  [formSubmitted]="submitted"
                  [isRequired]="true"
                  [isDisabled]="
                    brokerageId ? !isBrokerActive : !isProducerActive
                  "
                  [currentValue]="formCommission?.value?.regionName"
                  (currentValueChange)="handleAddRegionChange($event)"
                  [requiredErrMsg]="
                    'workFlow3.commissions.error.requiredErrMsg' | translate
                  "
                  [currentSelectedOption]="currentRegionObj"
                  [isDisabled]="activeStatus === false"
                >
                </boxxds-dropdown-v2>
              </span>
            </td>
            <td>
              <span>
                <boxxds-stepper-input-v2
                  [control]="'minPercentage'"
                  [isRequired]="true"
                  [isDisabled]="
                    brokerageId ? !isBrokerActive : !isProducerActive
                  "
                  [isDoubleDigitInput]="true"
                  [hasOuterBorder]="true"
                  [isDoubleDigitInput]="true"
                  [isGridRowAlign]="true"
                  [showEffects]="true"
                  [max]="100"
                  [value]="minPercentage"
                  [formSubmitted]="submitted"
                  [form]="formCommission"
                  [inputStyle]="{ background: 'transparent' }"
                  (handleChange)="onChangeMinPercentage($event)"
                  (handleKeyPress)="handleMinPercentageValidate($event)"
                  [style]="{ display: 'block' }"
                  [isDisabled]="activeStatus === false"
                >
                </boxxds-stepper-input-v2>
              </span>
            </td>
            <td>
              <span>
                <boxxds-stepper-input-v2
                  [control]="'maxPercentage'"
                  [isRequired]="true"
                  [isDisabled]="
                    brokerageId ? !isBrokerActive : !isProducerActive
                  "
                  [hasOuterBorder]="true"
                  [isGridRowAlign]="true"
                  [isDoubleDigitInput]="true"
                  [max]="100"
                  [formSubmitted]="submitted"
                  [value]="maxPercentage"
                  [form]="formCommission"
                  (handleChange)="onChangeMaxPercentage($event)"
                  (handleKeyPress)="handleMaxPercentageValidate($event)"
                  [isDisabled]="activeStatus === false"
                >
                </boxxds-stepper-input-v2>
              </span>
            </td>
            <td>
              <span>
                <boxxds-stepper-input-v2
                  [control]="'defaultPercentage'"
                  [isRequired]="true"
                  [isDisabled]="
                    brokerageId ? !isBrokerActive : !isProducerActive
                  "
                  [hasOuterBorder]="true"
                  [isGridRowAlign]="true"
                  [isDoubleDigitInput]="true"
                  [min]="minPercentage ?? 0"
                  [max]="maxPercentage ?? 100"
                  [value]="defaultPercentage"
                  [formSubmitted]="submitted"
                  [form]="formCommission"
                  (handleChange)="onChangeDefaultPercentage($event)"
                  (handleKeyPress)="handleDefaultPercentageValidate($event)"
                  [isDisabled]="activeStatus === false"
                >
                </boxxds-stepper-input-v2>
              </span>
            </td>
            <td>
              <span>
                <boxxds-button-v2
                  [buttonText]="'Add' | translate"
                  [type]="'submit'"
                  [isDisabled]="
                    brokerageId ? !isBrokerActive : !isProducerActive
                  "
                  [size]="'md'"
                  [btnClass]="'secondary'"
                  [btnType]="'default'"
                  [isDropdown]="false"
                  (handleClick)="
                    (brokerageId ? isBrokerActive : isProducerActive) &&
                      handleSubmitCommission()
                  "
                  [btnStyle]="{ padding: '10px 14px', width: '104px' }"
                ></boxxds-button-v2>
              </span>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="!(isNoRecords(data) || showSpinner)">
          <ng-container
            *ngFor="
              let item of data
                | paginate
                  : {
                      id: paginationId,
                      itemsPerPage: 10,
                      currentPage: currentPage,
                      totalItems: totalCount
                    };
              let indexOfelement = index
            "
          >
            <tr
              tabindex="1"
              class="tbl-data"
              [ngClass]="{
                'row-inactive':
                  styleInactive &&
                  (item['recordStatus'] === 'INACTIVE' ||
                    item['Status']?.toLowerCase() === 'inactive')
              }"
            >
              <td *ngIf="showStar">
                <button class="star" (click)="handleStarClick(indexOfelement)">
                  <img
                    [src]="
                      item['showStar']
                        ? 'assets/img/star-filled-1.svg'
                        : 'assets/img/star-1.svg'
                    "
                  />
                </button>
              </td>
              <!-- <ng-container *ngIf="!isInlineEdit && indexOfelement !== currentEditingIdx"> -->

              <ng-container
                *ngFor="let key of tableHeaders; let tblIdx = index"
              >
                <ng-container *ngIf="!columnHeadsToHide.includes(key)">
                  <td
                    scope="col"
                    (click)="handleRowClick($event, indexOfelement)"
                    *ngIf="
                      !(
                        (showLinks && key === 'ActionView') ||
                        key === 'showViewButton' ||
                        key === 'showStar' ||
                        key === 'popOverContent' ||
                        key === 'showEditIcon'
                      )
                    "
                    [ngClass]="{
                      inactive: key === 'recordStatus',
                      'row-clickable': isRowClickable,
                      'show-width-border':
                        showWithBorder && showWithBorderFieldName === key
                    }"
                    [ngStyle]="
                      ((!(key === 'popOverContent' || key === popOverColumn) &&
                        item[key]?.split('<br>')?.length > 1) ||
                        (addCopyBtnToField && key === addCopyBtnToField)) && {
                        display: 'flex'
                      }
                    "
                  >
                    <ng-container
                      *ngIf="
                        !(isInlineEdit && indexOfelement === currentEditingIdx)
                      "
                    >
                      <span
                        *ngIf="
                          (key === 'recordStatus' &&
                            item['recordStatus'] === 'INACTIVE') ||
                          (key !== 'recordStatus' &&
                            ((!showStatusAsBadge &&
                              !(
                                showStatusBadgeWithActions &&
                                key === showBadgeFieldName
                              )) ||
                              (showStatusAsBadge &&
                                key !== showBadgeFieldName)))
                        "
                      >
                        <ng-container
                          *ngIf="
                            key !== 'ActionView' &&
                            !(key === 'popOverContent' || key === popOverColumn)
                          "
                        >
                          <div
                            *ngFor="let word of item[key]?.split('<br>')"
                            class="set-text-overflow"
                          >
                            <ng-container
                              *ngIf="
                                showEmptyCellIndicator && word === 'no-data'
                              "
                            >
                              <div
                                class="empty-cell-indicator"
                                aria-label="No data"
                              ></div>
                            </ng-container>
                            <ng-container
                              *ngIf="
                                !(
                                  (showEmptyCellIndicator &&
                                    word === 'no-data') ||
                                  (showPopOver && key === popOverColumn)
                                )
                              "
                            >
                              {{ word }}
                            </ng-container>
                          </div>
                        </ng-container>
                      </span>
                    </ng-container>
                    <!-- <boxxds-textinput
                      *ngIf="
                        isInlineEdit &&
                        isEditableCol(key) &&
                        showEditTextBox(key) &&
                        indexOfelement === currentEditingIdx
                      "
                      [form]="editingForm"
                      [placeholder]="'Enter value'"
                      [control]="key"
                      maxLength="250"
                    >
                    </boxxds-textinput> -->
                    <boxxds-dropdown-v2
                      *ngIf="
                        isInlineEdit &&
                        indexOfelement === currentEditingIdx &&
                        key === 'Product'
                      "
                      [options]="products"
                      [form]="editingForm"
                      [control]="'productName'"
                      class="input-fields"
                      [formSubmitted]="editSubmitted"
                      [isRequired]="true"
                      [currentValue]="editingForm?.value?.productName"
                      (currentValueChange)="handleEditProductChange($event)"
                      [requiredErrMsg]="
                        'workFlow3.commissions.error.requiredErrMsg' | translate
                      "
                    >
                    </boxxds-dropdown-v2>
                    <boxxds-dropdown-v2
                      *ngIf="
                        isInlineEdit &&
                        indexOfelement === currentEditingIdx &&
                        key === 'State'
                      "
                      [options]="regionsEdit"
                      [form]="editingForm"
                      [control]="'regionName'"
                      class="input-fields"
                      [formSubmitted]="editSubmitted"
                      [isRequired]="true"
                      [currentValue]="editingForm?.value?.regionName"
                      (currentValueChange)="handleEditRegionChange($event)"
                      [requiredErrMsg]="
                        'workFlow3.commissions.error.requiredErrMsg' | translate
                      "
                    >
                    </boxxds-dropdown-v2>
                    <boxxds-stepper-input-v2
                      *ngIf="
                        isInlineEdit &&
                        indexOfelement === currentEditingIdx &&
                        key === 'Min.range %'
                      "
                      [control]="'minPercentage'"
                      [isRequired]="true"
                      [isDoubleDigitInput]="true"
                      [hasOuterBorder]="true"
                      [isDoubleDigitInput]="true"
                      [isGridRowAlign]="true"
                      [showEffects]="true"
                      [max]="100"
                      [value]="minPercentageEdit"
                      [formSubmitted]="editSubmitted"
                      [form]="editingForm"
                      [inputStyle]="{ background: 'transparent' }"
                      (handleChange)="onChangeMinPercentageEdit($event)"
                      (handleKeyPress)="handleMinPercentageValidateEdit($event)"
                    >
                    </boxxds-stepper-input-v2>
                    <boxxds-stepper-input-v2
                      *ngIf="
                        isInlineEdit &&
                        indexOfelement === currentEditingIdx &&
                        key === 'Max.range %'
                      "
                      [control]="'maxPercentage'"
                      [isRequired]="true"
                      [hasOuterBorder]="true"
                      [isGridRowAlign]="true"
                      [isDoubleDigitInput]="true"
                      [max]="100"
                      [formSubmitted]="editSubmitted"
                      [value]="maxPercentageEdit"
                      [form]="editingForm"
                      (handleChange)="onChangeMaxPercentageEdit($event)"
                      (handleKeyPress)="handleMaxPercentageValidateEdit($event)"
                    >
                    </boxxds-stepper-input-v2>
                    <boxxds-stepper-input-v2
                      *ngIf="
                        isInlineEdit &&
                        indexOfelement === currentEditingIdx &&
                        key === 'Default %'
                      "
                      [control]="'defaultPercentage'"
                      [isRequired]="true"
                      [hasOuterBorder]="true"
                      [isGridRowAlign]="true"
                      [isDoubleDigitInput]="true"
                      [min]="minPercentageEdit ?? 0"
                      [max]="maxPercentageEdit ?? 100"
                      [value]="defaultPercentageEdit"
                      [formSubmitted]="editSubmitted"
                      [form]="editingForm"
                      (handleChange)="onChangeDefaultPercentageEdit($event)"
                      (handleKeyPress)="
                        handleDefaultPercentageValidateEdit($event)
                      "
                    >
                    </boxxds-stepper-input-v2>
                  </td>
                </ng-container>
              </ng-container>
              <td
                *ngIf="
                  showToggle &&
                  indexOfelement !== currentEditingIdx &&
                  activeStatus === true
                "
              >
                <boxxds-switch-v2
                  [checked]="item['Active']"
                  name="enable_commission"
                  [disabled]="false"
                  [ngStyle]="{ marginBottom: '8px', marginTop: '40px' }"
                  (changed)="handleEnableDisable(item, indexOfelement)"
                  [title]="
                    item['Active'] === true
                      ? 'Disable commission'
                      : 'Enable commission'
                  "
                >
                </boxxds-switch-v2>
              </td>
              <ng-container *ngIf="isInlineEdit && activeStatus === true">
                <ng-container
                  *ngIf="
                    !(
                      currentEditingIdx !== null &&
                      indexOfelement === currentEditingIdx
                    )
                  "
                >
                  <td>
                    <button
                      *ngIf="item['Active'] === true"
                      class="btn-editing"
                      (click)="startEditing(item, indexOfelement)"
                    >
                      <img src="assets/img/edit-01.svg" alt="edit-icon" />
                    </button>
                  </td>
                  <!-- <td>
                    <button
                      class="btn-editing"
                      (click)="deleteRow(indexOfelement)"
                    >
                      <img src="assets/img/remove.svg" alt="remove-icon" />
                    </button>
                  </td> -->
                </ng-container>
                <ng-container
                  *ngIf="
                    currentEditingIdx !== null &&
                    indexOfelement === currentEditingIdx
                  "
                >
                  <td>
                    <button
                      class="btn-editing"
                      (click)="cancelEditing(indexOfelement)"
                    >
                      <img src="assets/img/remove.svg" alt="cancel-icon" />
                    </button>
                  </td>
                  <td>
                    <button
                      class="btn-editing"
                      (click)="saveEditing(item, indexOfelement)"
                    >
                      <img src="assets/img/check-02.svg" alt="save-icon" />
                    </button>
                  </td>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="showLinks">
                <td
                  [ngClass]="{ inactive: item['recordStatus'] === 'INACTIVE' }"
                  *ngFor="let linkObj of linkDetails; let i = index"
                >
                  <a
                    class="link"
                    [ngStyle]="linkObj.linkStyle"
                    href="javascript:void(0)"
                    (click)="
                      redirectTo(
                        linkObj.href ? linkObj.href : item['ActionView']
                      )
                    "
                    ><img
                      *ngIf="linkObj.icon"
                      [ngStyle]="linkObj.imgStyle"
                      [src]="getIconSrc(linkObj.icon)"
                      [alt]="linkObj.iconAlt"
                    />
                    <span class="edit-link" [ngStyle]="linkObj.lblStyle">{{
                      linkObj.name
                    }}</span></a
                  >
                </td>
              </ng-container>
              <td *ngIf="showEllipsis || isRowExpandable">
                <button
                  class="ellipsis"
                  (click)="handleEllipsisClick(indexOfelement)"
                >
                  <div class="ellipsis-container">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </button>
              </td>
              <td *ngIf="showViewButton">
                <button class="star" (click)="handleViewClick(indexOfelement)">
                  <img src="assets/img/eye.svg" />
                </button>
              </td>
              <div class="action-panel">
                <td *ngIf="showEditIcon">
                  <ng-container>
                    <ng-container>
                      <td class="td-action-btn">
                        <button
                          *ngIf="showEditIcon === true"
                          (click)="handleEditClick(item, indexOfelement)"
                          class="edit-btn"
                          title="'Edit commission'"
                        >
                          <img src="assets/img/edit-01.svg" alt="edit-icon" />
                        </button>
                      </td>
                    </ng-container>
                  </ng-container>
                </td>
              </div>
            </tr>
            <ng-container *ngIf="isRowExpandable">
              <tr *ngIf="isExpanded[indexOfelement]">
                <ng-container
                  *ngTemplateOutlet="
                    templateOutlet;
                    context: {
                      $implicit: expandRowContext,
                      idx: indexOfelement,
                      data
                    }
                  "
                ></ng-container>
              </tr>
            </ng-container>
          </ng-container>
        </ng-container>
        <!-- </ng-container> -->
        <ng-container *ngIf="isNoRecords(data) && !showSpinner">
          <tr>
            <td [attr.colspan]="tableHeaders?.length" class="td-no-records">
              <div class="no-records-holder">
                <div class="no-records-ellipse">
                  <boxxds-body
                    text="No data to display"
                    sz="md"
                    [customStyle]="{
                      color: '#292933',
                      fontFamily: 'Gibson',
                      fontWeight: '400',
                      fontSize: '18px',
                      lineHeight: '28px'
                    }"
                  >
                  </boxxds-body>
                </div>
              </div>
            </td>
          </tr>
          <tr *ngIf="showAddBtn">
            <td [attr.colspan]="tableHeaders?.length" class="td-no-records">
              <div class="add-insured-btn">
                <button
                  type="secondary"
                  id="btn-add"
                  class="add-btn"
                  (click)="handleAddCompanyBtn($event)"
                >
                  <img [src]="linkIconSrc" /><span>{{ linkButtonLabel }}</span>
                </button>
              </div>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="showSpinner">
          <tr>
            <td [attr.colspan]="tableHeaders?.length">
              <div class="spinner-holder">
                <boxxds-spinner
                  [containerStyle]="{
                    width: '112px',
                    height: '112px',
                    '--b': '15px'
                  }"
                ></boxxds-spinner>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div
    class="table-footer"
    *ngIf="(!isNoRecords(data) || !showSpinner) && showPagination"
  >
    <div class="pagination-container" *ngIf="totalPages > 1">
      <pagination-template
        [id]="paginationId"
        #p="paginationApi"
        (pageChange)="onPageChange($event)"
      >
        <div class="tbl-pagination">
          <div
            class="tbl-pagination-prev"
            [ngClass]="{ 'prev-btn-disabled': p.isFirstPage() }"
          >
            <img src="assets/img/arrow-down-1.svg" />
            <a
              (keyup.enter)="p.previous()"
              (click)="p.previous()"
              [class.disabled]="p.isFirstPage()"
              class="tbl-page-link"
              tabindex="0"
              aria-label="Previous"
              >Previous</a
            >
          </div>
          <ul class="page-numbers-container">
            <li
              *ngFor="let page of p.pages"
              [class.active]="p.getCurrent() === page.value"
              class="tbl-page-item"
            >
              <a
                (keyup.enter)="p.setCurrent(page.value)"
                (click)="p.setCurrent(page.value)"
                class="tbl-page-link"
                tabindex="0"
              >
                {{ page.label }}
              </a>
            </li>
          </ul>
          <div
            class="pagination-next"
            [ngClass]="{ 'next-btn-disabled': p.isLastPage() }"
          >
            <a
              (keyup.enter)="p.next()"
              [class.disabled]="p.isLastPage()"
              (click)="p.next()"
              class="tbl-page-link"
              tabindex="0"
              >Next</a
            >
            <img src="assets/img/arrow-up-1.svg" />
          </div>
        </div>
      </pagination-template>
    </div>
  </div>
</div>
<app-modal-commission-confirmation-v2
  *ngIf="showConfirmationModal"
  [showModal]="showConfirmationModal"
  [commissionData]="commission"
  (handleSubmitCommissionOverride)="handleCommissionOverride($event)"
  (handleClose)="closeModalConfirmation()"
>
</app-modal-commission-confirmation-v2>
