<div class="spinner-container">
  <div class="spinner-holder-submission" *ngIf="showBodySpinner !== 0">
    <div class="body-spinner-submission">
      <div class="body-spinner-wrapper">
        <boxxds-spinner
          [containerStyle]="{
            width: '112px',
            height: '112px',
            '--b': '15px'
          }"
        ></boxxds-spinner>
      </div>
    </div>
  </div>
</div>

<form [formGroup]="form">
  <div class="main-card" *ngIf="showBodySpinner === 0">
    <ng-container *ngIf="showBodySpinner === 0">
      <!-- insured information -->
      <div class="rowInsurer">
        <div class="columnInsurerLeft">
          <boxxds-body
            sz="md"
            [text]="
              'workFlow3.components.newSubmission.heading.insuredInfo'
                | translate
            "
            [customStyle]="{
              fontFamily: 'Gibson',
              color: '#2D2D39',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '20px'
            }"
          ></boxxds-body>
          <p class="subhead-container">
            <span *ngIf="!isExistingInsuredFormEdit">{{
              "workFlow3.components.newSubmission.subHeading.insureInfo"
                | translate
            }}</span>
            <span *ngIf="isExistingInsuredFormEdit">{{
              "workFlow3.components.newSubmission.subHeading.insureInfoEdit"
                | translate
            }}</span>
            <button
              class="edit-button"
              *ngIf="isExistingInsuredFormEdit"
              (click)="handleClearForm()"
            >
              <img src="assets/img/edit-01.svg" alt="edit-icon" />
            </button>
          </p>
        </div>
        <div class="columnInsurerRight">
          <div class="form-inputs business-info">
            <div class="form-inputs contact-details">
              <div class="d-flex first-middle-name-holder">
                <boxxds-search-autocomplete-v2
                  *ngIf="fromSubmissionFlow === true"
                  [inputAutoFocus]="firstNameAutoFocus"
                  [form]="form"
                  [isActive]="true"
                  [formSubmitted]="checkValidation"
                  [requiredErrMsg]="
                    'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                      | translate
                  "
                  [items]="insuredOptns"
                  [title]="form?.value?.firstName"
                  [label]="
                    'workFlow3.components.newSubmission.label.firstName'
                      | translate
                  "
                  [required]="true"
                  [control]="'firstName'"
                  [placeholder]="
                    'workFlow3.components.newSubmission.placeholder.firstName'
                      | translate
                  "
                  [autocapitalize]="'off'"
                  [spellcheck]="'off'"
                  [permissionStatus]="this.permissionList[this.currentScreen]"
                  [containerStyle]="{ width: '242px', marginBottom: '20px' }"
                  [labelStyle]="{ fontFamily: 'Gibson', marginBottom: '6px' }"
                  (searchQuery)="handleBusinessNameSearch($event, 1)"
                  (itemSelected)="
                    insuredOptns = [];
                    populateInsuredDetailsIndividual($event);
                    selectInsuredSearch($event)
                  "
                  [maxLength]="'60'"
                  [noResultContent]="'No result'"
                  [showNoResultAction]="true"
                  [readonly]="isExistingInsuredFormEdit ? true : undefined"
                  [class.noPointerEvents]="isExistingInsuredFormEdit"
                ></boxxds-search-autocomplete-v2>
                <boxxds-textinput-v2
                  *ngIf="fromSubmissionFlow === false"
                  [form]="form"
                  [placeholder]="
                    'workFlow3.components.newSubmission.placeholder.firstName'
                      | translate
                  "
                  [control]="'firstName'"
                  [id]="'firstName'"
                  [label]="
                    'workFlow3.components.newSubmission.label.firstName'
                      | translate
                  "
                  class="contact-field"
                  [formSubmitted]="checkValidation"
                  [width]="'100%'"
                  maxLength="60"
                  [readonly]="
                    (insuredDataIndividual && !insuredDataIndividual?.active) ||
                    !this.permissionList[this.currentScreen] ||
                    isExistingInsuredFormEdit
                  "
                  [containerStyle]="{ width: '235px' }"
                  [leftIcon]="'assets/img/search-md.svg'"
                  [leftIconStyle]="{
                    filter:
                      'invert(45%) sepia(20%) saturate(410%) hue-rotate(182deg) brightness(92%) contrast(88%)',
                    width: '20px',
                    height: '20px'
                  }"
                  [isRequired]="true"
                  [requiredErrMsg]="
                    'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                      | translate
                  "
                >
                </boxxds-textinput-v2>
                <boxxds-textinput-v2
                  [form]="form"
                  [placeholder]="
                    'workFlow3.components.newSubmission.placeholder.middleName'
                      | translate
                  "
                  [control]="'middleName'"
                  [id]="'middleName'"
                  [label]="
                    'workFlow3.components.newSubmission.label.middleName'
                      | translate
                  "
                  class="contact-field"
                  [formSubmitted]="checkValidation"
                  [width]="'100%'"
                  maxLength="60"
                  [readonly]="
                    (insuredDataIndividual && !insuredDataIndividual?.active) ||
                    !this.permissionList[this.currentScreen] ||
                    isExistingInsuredFormEdit
                  "
                  [containerStyle]="{ width: '235px' }"
                  [leftIcon]="'assets/img/search-md.svg'"
                  [leftIconStyle]="{
                    filter:
                      'invert(45%) sepia(20%) saturate(410%) hue-rotate(182deg) brightness(92%) contrast(88%)',
                    width: '20px',
                    height: '20px'
                  }"
                >
                </boxxds-textinput-v2>
              </div>

              <boxxds-textinput-v2
                [form]="form"
                [placeholder]="
                  'workFlow3.components.newSubmission.placeholder.lastName'
                    | translate
                "
                [control]="'lastName'"
                [id]="'lastName'"
                [width]="'100%'"
                [label]="
                  'workFlow3.components.newSubmission.label.lastName'
                    | translate
                "
                class="contact-field"
                [formSubmitted]="checkValidation"
                maxLength="60"
                [readonly]="
                  (insuredDataIndividual && !insuredDataIndividual?.active) ||
                  !this.permissionList[this.currentScreen] ||
                  isExistingInsuredFormEdit
                "
                [containerStyle]="{ marginBottom: '20px' }"
                [isRequired]="true"
                [requiredErrMsg]="
                  'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                    | translate
                "
                [leftIcon]="'assets/img/search-md.svg'"
                [leftIconStyle]="{
                  filter:
                    'invert(45%) sepia(20%) saturate(410%) hue-rotate(182deg) brightness(92%) contrast(88%)',
                  width: '20px',
                  height: '20px'
                }"
              >
              </boxxds-textinput-v2>
              <boxxds-textinput-v2
                [form]="form"
                [placeholder]="
                  'workFlow3.components.newSubmission.placeholder.emailAddress'
                    | translate
                "
                [control]="'email'"
                [id]="'email'"
                [width]="'100%'"
                [label]="
                  'workFlow3.components.newSubmission.label.emailAddress'
                    | translate
                "
                class="contact-field margin-horizontal"
                [invalidErrMsg]="
                  'workFlow3.components.newSubmission.error.invalidErrMsg'
                    | translate
                "
                [formSubmitted]="checkValidation"
                maxLength="150"
                [readonly]="
                  (insuredDataIndividual && !insuredDataIndividual?.active) ||
                  !this.permissionList[this.currentScreen] ||
                  isExistingInsuredFormEdit
                "
                [containerStyle]="{ marginBottom: '20px' }"
                [isRequired]="true"
                [requiredErrMsg]="
                  'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                    | translate
                "
              >
              </boxxds-textinput-v2>
              <boxxds-textinput-v2
                (click)="showAddressSection()"
                [form]="form"
                [mask]="'(000) 000-0000'"
                [placeholder]="
                  'workFlow3.components.newSubmission.placeholder.phone'
                    | translate
                "
                [control]="'phone'"
                [id]="'phone'"
                [width]="'100%'"
                [label]="
                  'workFlow3.components.newSubmission.label.phone' | translate
                "
                class="contact-field margin-horizontal"
                [formSubmitted]="checkValidation"
                maxLength="150"
                [readonly]="
                  (insuredDataIndividual && !insuredDataIndividual?.active) ||
                  !this.permissionList[this.currentScreen] ||
                  isExistingInsuredFormEdit
                "
                [containerStyle]="{ marginBottom: '20px' }"
                [isRequired]="true"
                [requiredErrMsg]="
                  'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                    | translate
                "
                (keypress)="showAddressSection()"
              >
              </boxxds-textinput-v2>
              <boxxds-dropdown-v2
                [form]="form"
                [control]="'householdMembers'"
                [placeholder]="
                  'workFlow3.components.newSubmission.placeholder.householdMembers'
                    | translate
                "
                [options]="householdMemberOptns"
                [currentValue]="form?.value['householdMembers']"
                (currentValueChange)="handleNoOfEmployees($event)"
                [label]="
                  'workFlow3.components.newSubmission.label.householdMembers'
                    | translate
                "
                [dropdownContainerStyle]="{ marginBottom: '20px' }"
                class="employees"
                [requiredErrMsg]="
                  'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                    | translate
                "
                [formSubmitted]="checkValidation"
                [isReadonly]="
                  (insuredDataIndividual && !insuredDataIndividual?.active) ||
                  !this.permissionList[this.currentScreen] ||
                  isExistingInsuredFormEdit
                "
                [class.noPointerEvents]="isExistingInsuredFormEdit"
              >
              </boxxds-dropdown-v2>
            </div>
          </div>
        </div>
      </div>
      <!-- insured information end -->
      <div class="lineDivider" *ngIf="showAddress"></div>

      <!--individual insured contact information -->

      <div class="rowInsurer" *ngIf="showAddress">
        <div class="columnInsurerLeft">
          <boxxds-body
            sz="md"
            [text]="
              'workFlow3.components.newSubmission.heading.insuredAddress'
                | translate
            "
            [customStyle]="{
              fontFamily: 'Gibson',
              color: '#2D2D39',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '20px'
            }"
          ></boxxds-body>
          <boxxds-body
            sz="md"
            [text]="
              'workFlow3.components.newSubmission.subHeading.insureAddress'
                | translate
            "
            [customStyle]="{
              fontFamily: 'Gibson',
              color: '#475467',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px'
            }"
          ></boxxds-body>
        </div>
        <div class="columnInsurerRight">
          <div class="form-inputs business-info">
            <div class="d-flex justify-content-between">
              <boxxds-search-autocomplete-v2
                [inputAutoFocus]="addressAutoFocus"
                [maxLength]="'50'"
                [form]="form"
                [isActive]="true"
                [formSubmitted]="checkValidation"
                [requiredErrMsg]="
                  'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                    | translate
                "
                [items]="locations"
                [label]="
                  'workFlow3.components.newSubmission.label.addressLine1'
                    | translate
                "
                [required]="true"
                [control]="'address'"
                [placeholder]="
                  'workFlow3.components.newSubmission.placeholder.searchLocation1'
                    | translate
                "
                [autocapitalize]="'off'"
                [spellcheck]="'off'"
                [permissionStatus]="this.permissionList[this.currentScreen]"
                [containerStyle]="{ width: '500px', marginBottom: '20px' }"
                [labelStyle]="{ fontFamily: 'Gibson', marginBottom: '6px' }"
                (searchQuery)="getPlaces($event)"
                (itemSelected)="selectPlaceById($event)"
                [selectedFromSuggestion]="selectedFromSuggestion"
                [readonly]="isExistingInsuredFormEdit"
                [class.noPointerEvents]="isExistingInsuredFormEdit"
              ></boxxds-search-autocomplete-v2>
            </div>

            <div class="d-flex justify-content-between">
              <div class="d-flex address-city-holder">
                <boxxds-textinput-v2
                  [form]="form"
                  [placeholder]="
                    'workFlow3.components.newSubmission.placeholder.searchLocation2'
                      | translate
                  "
                  [control]="'addressLine2'"
                  [id]="'address'"
                  [width]="'100%'"
                  [containerStyle]="{
                    marginBottom: '20px',
                    width: '242px',
                    fontFamily: 'Gibson'
                  }"
                  [label]="
                    'workFlow3.components.newSubmission.label.addressLine2'
                      | translate
                  "
                  class="input-fields"
                  class="address2"
                  maxLength="10"
                  [isDisabled]="
                    !this.permissionList[this.currentScreen] || !isActive
                  "
                  [formSubmitted]="checkValidation"
                  [selectedFromSuggestion]="selectedFromSuggestion"
                  [readonly]="
                    (insuredDataIndividual && !insuredDataIndividual?.active) ||
                    isExistingInsuredFormEdit
                  "
                >
                </boxxds-textinput-v2>

                <boxxds-textinput-v2
                  [form]="form"
                  [placeholder]="
                    'workFlow3.components.newSubmission.placeholder.city'
                      | translate
                  "
                  [control]="'city'"
                  [id]="'city'"
                  [width]="'100%'"
                  [containerStyle]="{
                    width: '240px',
                    marginBottom: '20px',
                    fontFamily: 'Gibson'
                  }"
                  [label]="
                    'workFlow3.components.newSubmission.label.city' | translate
                  "
                  class="input-fields"
                  [isRequired]="true"
                  [requiredErrMsg]="
                    'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                      | translate
                  "
                  [formSubmitted]="checkValidation"
                  class="city"
                  maxLength="25"
                  [isDisabled]="
                    !this.permissionList[this.currentScreen] || !isActive
                  "
                  [selectedFromSuggestion]="selectedFromSuggestion"
                  [readonly]="
                    (insuredDataIndividual && !insuredDataIndividual?.active) ||
                    isExistingInsuredFormEdit
                  "
                >
                </boxxds-textinput-v2>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div class="d-flex justify-content-between city-state-holder">
                <boxxds-dropdown-v2
                  [form]="form"
                  [control]="'province'"
                  [placeholder]="
                    'workFlow3.components.newSubmission.placeholder.state'
                      | translate
                  "
                  [options]="provinceOrStateList"
                  [label]="
                    province ?? 'workFlow3.components.newSubmission.label.state'
                      | translate
                  "
                  [btnStyle]="{ marginTop: '8px', maxWidth: '186px' }"
                  [dropdownContainerStyle]="{
                    width: '150px',
                    fontFamily: 'Gibson'
                  }"
                  class="input-fields"
                  [requiredErrMsg]="
                    'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                      | translate
                  "
                  [isRequired]="true"
                  [formSubmitted]="checkValidation"
                  class="province"
                  [currentValue]="form?.value?.province"
                  [currentSelectedOption]="currentProvinceObj"
                  [isDisabled]="
                    !this.permissionList[this.currentScreen] ||
                    !isActive ||
                    isExistingInsuredFormEdit
                  "
                  [class.noPointerEvents]="
                    !this.permissionList[this.currentScreen] ||
                    !isActive ||
                    isExistingInsuredFormEdit
                  "
                  [hasScrollablePagination]="true"
                  [totalCount]="regionTotalRecords"
                  [totalPages]="regionTotalPages"
                  (loadMoreOptions)="
                    this.permissionList[this.currentScreen] &&
                      loadMoreRegion($event)
                  "
                  (currentValueChange)="
                    this.permissionList[this.currentScreen] &&
                      !isExistingInsuredFormEdit &&
                      handleProvinceChange($event)
                  "
                  [isSuggestedOption]="selectedFromSuggestion"
                  [isReadonly]="
                    insuredDataIndividual && !insuredDataIndividual?.active
                  "
                >
                </boxxds-dropdown-v2>

                <boxxds-textinput-v2
                  [form]="form"
                  [width]="'100%'"
                  [placeholder]="
                    'workFlow3.components.newSubmission.placeholder.zipCode'
                      | translate
                  "
                  [control]="'zipPostalCode'"
                  [id]="'postalCode'"
                  [containerStyle]="{ width: '155px', marginLeft: '20px' }"
                  [label]="
                    zip ?? 'workFlow3.components.newSubmission.label.zipCode'
                      | translate
                  "
                  class="input-fields"
                  [customErrMsg]="
                    'workFlow3.components.newSubmission.error.invalidErrMsg'
                      | translate
                  "
                  [isRequired]="true"
                  [requiredErrMsg]="
                    'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                      | translate
                  "
                  [formSubmitted]="checkValidation"
                  class="postal-code"
                  maxLength="10"
                  [mask]="mask"
                  [isDisabled]="
                    !this.permissionList[this.currentScreen] || !isActive
                  "
                  [dropSpecialCharacters]="false"
                  [selectedFromSuggestion]="selectedFromSuggestion"
                  [readonly]="
                    (insuredDataIndividual && !insuredDataIndividual?.active) ||
                    isExistingInsuredFormEdit
                  "
                >
                </boxxds-textinput-v2>
              </div>
              <boxxds-textinput-v2
                [form]="form"
                [placeholder]="
                  'workFlow3.components.newSubmission.placeholder.country'
                    | translate
                "
                [control]="'country'"
                [id]="'country'"
                [width]="'100%'"
                [containerStyle]="{
                  width: '155px',
                  marginBottom: '20px',
                  fontFamily: 'Gibson',
                  marginLeft: '20px'
                }"
                [label]="
                  'workFlow3.components.newSubmission.label.country' | translate
                "
                class="input-fields"
                [isRequired]="true"
                [requiredErrMsg]="
                  'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                    | translate
                "
                [formSubmitted]="checkValidation"
                class="city"
                maxLength="250"
                [isDisabled]="
                  !this.permissionList[this.currentScreen] ||
                  !isActive ||
                  isExistingInsuredFormEdit
                "
                [readonly]="true"
                [selectedFromSuggestion]="selectedFromSuggestion"
                [readonly]="
                  insuredDataIndividual && !insuredDataIndividual?.active
                "
              >
              </boxxds-textinput-v2>
            </div>
          </div>
        </div>
      </div>
      <!-- product details -->
      <div
        class="rowInsurer"
        *ngIf="fromSubmissionFlow && isRestrictedRoleSubmission && showAddress"
      >
        <div class="columnInsurerLeft">
          <boxxds-body
            sz="md"
            [text]="
              'workFlow3.components.newSubmission.heading.riskInfo' | translate
            "
            [customStyle]="{
              color: '#2D2D39',
              fontFamily: 'Gibson',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '20px'
            }"
          ></boxxds-body>
        </div>

        <div class="columnInsurerRight">
          <boxxds-dropdown-v2
            [label]="
              'workFlow3.components.newSubmission.label.risk' | translate
            "
            [placeholder]="'placeholder.select' | translate"
            [options]="products"
            [isReadonly]="disableProduct"
            [form]="form"
            [control]="'productName'"
            class="input-fields"
            [requiredErrMsg]="
              'workFlow3.components.brokerCommissionPopup.error.requiredErrMsg'
                | translate
            "
            [formSubmitted]="checkValidation"
            [currentValue]="form?.value['productName']"
            (currentValueChange)="onChangeProduct($event)"
            [isDisabled]="disableProduct"
            [isRequired]="true"
          >
          </boxxds-dropdown-v2>
        </div>
      </div>
      <div
        class="lineDivider"
        *ngIf="fromSubmissionFlow && isRestrictedRoleSubmission && showAddress"
      ></div>
    </ng-container>
  </div>
</form>
