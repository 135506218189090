import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-restricted-industry-or-sanction-popup',
  templateUrl: './restricted-industry-or-sanction-popup.component.html',
  styleUrls: ['./restricted-industry-or-sanction-popup.component.less'],
})
export class RestrictedIndustryOrSanctionPopupComponent {
  @Input() details: { [key: string]: any } = {};
  @Input() tableData: { [key: string]: any }[];
  @Input() columnsToHide: string[] = [];
  @Input() sortableColumns: string[] = [];
  @Input() iconColumns: string[] = [];
  @Input() customColumnWidth: {
    columnName: string;
    minWidth: string;
    maxWidth: string;
  }[] = [];
  @Input() isRestrictedIndustry: boolean = false;
  @Input() hasQuote: boolean = false;

  @Output() handleClose = new EventEmitter();
  @Output() handleSubmit = new EventEmitter();
  @Output() handleDeclineAndSaveChanges = new EventEmitter();
  @Output() handleDiscardChanges = new EventEmitter();
  @Output() sortHandler = new EventEmitter();
  @Output() columnClickHandler = new EventEmitter();
  @Input() isOfacError: boolean = false;

  showPopOver = false;

  constructor() {}

  ngOnInit(): void {
    this.tableData = this.tableData.map((sanction, index) => {
      if (index === 0 && this.isRestrictedIndustry) {
        return sanction;
      } else {
        const popOverData = [];
        popOverData.push(`Full Name: ${sanction['fullName']}`);
        popOverData.push(
          `Citizenship: ${
            sanction['citizenship']?.length > 0
              ? sanction['citizenship']?.join(', ')
              : '-'
          }`,
        );
        popOverData.push(`Remarks: ${sanction['remarks'] || '-'}`);
        popOverData.push(
          `Additional Sanctions: ${
            sanction['additionalSanctions']?.length > 0
              ? sanction['additionalSanctions'].join(', ')
              : '-'
          }`,
        );
        popOverData.push(
          `Programs: ${
            sanction['programs']?.length > 0
              ? sanction['programs'].join(', ')
              : '-'
          }`,
        );

        return {
          Type: 'OFAC',
          Description: 'Sanction is subject to secondary sanction',
          Reasons: popOverData,
          Source: sanction['source'] || 'SDN',
          Score: sanction['score']?.toString(),
          // popOverContent: popOverData,
        };
      }
    });
  }

  handleSorting(data: any): void {
    this.sortHandler.emit(data);
  }

  columnClickHandle(data: any): void {
    this.columnClickHandler.emit(data);
  }

  handleCloseHandler(data: any): void {
    this.handleClose.emit();
  }

  handleSubmitHandler(data: any): void {
    this.handleSubmit.emit();
  }

  handleDeclineAndSaveChangesHandler(data: any): void {
    this.handleDeclineAndSaveChanges.emit();
  }

  handleDiscardChangesHandler(data: any): void {
    this.handleDiscardChanges.emit(data);
  }
}
