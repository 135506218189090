import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs';
import { PolicyDetail } from 'src/app/quote-workflow3/policy-lifecycle-v2/lifecycle-v2-object';
import { addSpaceBeforeCapitalLetter } from 'src/app/dashboard/utils/lifecycle-utils';
import { Pagination } from 'src/app/entities/boxx-response';
import { DomainsService } from 'src/app/services/domains.service';
import { ModalAlertService } from 'src/app/services/modal-alert.service';
import { PolicyRiskDocService } from 'src/app/services/policy-risk-doc.service';
import { PolicyRiskTrxReasons } from 'src/app/services/policy-risk-trx-reasons.service';
import { PolicyRiskTrxService } from 'src/app/services/policy-risk-trx.service';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { getLifecycleState } from 'src/app/store/lifecycle/lifecycle.select';
import { formatDate } from 'src/app/utils/formatDate';
import {
  getAlertHead,
  getErrorMessage,
  getStatusBadgeClass,
} from 'src/app/utils/utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-policy-slideout-v2',
  templateUrl: './policy-slideout-v2.component.html',
  styleUrls: ['./policy-slideout-v2.component.less'],
})
export class PolicySlideoutV2Component implements OnInit {
  @Input() details: PolicyDetail;
  @Input() riskTrxTypes;
  @Input() isProductActive: boolean = false;

  @Output() handleLoading = new EventEmitter();

  sectionTitles: string[] = ['Policy documents', 'History'];
  policyRiskId: number;
  activityData: { pagination: Pagination | null } = {
    pagination: null,
  };

  activeSectionFilter: string = 'Policy documents';
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  shortDateFormat: string = '';
  longDateFormat: string = '';
  currentQuoteStatus: string = '';
  policyRiskTrxId: number;
  policyNumber: string;
  private insuredId: number;
  policyId: number;
  status: string;
  riskId: number;
  docStageId = undefined;
  files: any[] = [];
  riskTrxReasons: any;
  showDocPreview = false;
  showImagePreview = false;

  totalCount: number = 0;
  currentPage: number = 1;
  itemsPerPage = 10;
  totalPages: number = 1;
  policyPeriodId: number = null;
  closeModalClicked: boolean = false;
  isInvoicedTransaction = false;
  isLoading: boolean = true;

  constructor(
    private store: Store,
    private alertService: ModalAlertService,
    private domainsService: DomainsService,
    private policyRiskDocService: PolicyRiskDocService,
    private PolicyRiskTrxReasons: PolicyRiskTrxReasons,
    private policyRiskTrxService: PolicyRiskTrxService,
    private translate: TranslateService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });

    this.currentPage = this.activityData?.pagination?.currentPage || 1;

    this.store
      .select(getLifecycleState)
      .pipe(take(1))
      .subscribe((state) => {
        this.currentQuoteStatus = state.quoteStatus;
        this.policyRiskTrxId =
          state?.policyRiskTrxId ?? this.details.policyRiskTrxId;
        this.status = this.getStatus(state?.policyRiskTrxStatus);
        this.insuredId = state?.insuredId;
        this.riskId = state?.riskId;
      });

    await Promise.all([
      await this.populateFormDetails(),
      await this.populateReferralReasons(),
    ]);
  }

  handleCloseClk() {
    this.closeModalClicked = true;
  }

  handleLoader(loaderEvent: any) {
    this.isLoading = loaderEvent.isLoading ? true : false;
    this.handleLoading.emit({ isLoading: this.isLoading });
  }

  showGenerateDocMsg() {
    if (this.closeModalClicked === false) {
      let notificationAlert = {
        type: 'success',
        headerText: getAlertHead('success'),
        bodyText: this.translate.instant(
          'policy.document.success.regenerageSuccessMsg',
        ),
        wrapperStyle: { margin: '30px 0px 0px -75px' },
      };
      this.alertService.addAlert(notificationAlert);
    }
  }

  private async populateFormDetails() {
    if (!this.policyRiskTrxId) {
      return;
    }
    this.policyRiskTrxService
      .getByPolicyRiskTrxId(this.policyRiskTrxId)
      .subscribe({
        next: (response) => {
          const data = response.data;
          this.policyId = data?.policyRisk?.policyPeriod?.policy?.id;
          this.policyPeriodId = data?.policyRisk?.policyPeriod?.id;
          let statusValue = data?.type ?? data.quoteStatus;
          const status = this.getStatus(statusValue);
          this.status = addSpaceBeforeCapitalLetter(status);
          this.policyRiskId = data?.policyRisk?.id;
          this.policyNumber = this.details.id;
          this.isInvoicedTransaction = !!data?.invoiceNumber ? true : false;
        },
        error: (error) => {},
      });
  }

  getStatusType() {
    let statusType;
    if (this.status) {
      if (
        (this.status.toLowerCase() === 'endorsement' ||
          this.status.toLowerCase() === 'policy change') &&
        this.isInvoicedTransaction
      ) {
        const formattedStatus =
          this.status.toLowerCase() === 'endorsement'
            ? 'endorsement - invoiced'
            : 'policy change - executed';
        statusType = getStatusBadgeClass(formattedStatus);
      } else {
        statusType = getStatusBadgeClass(this.status);
      }
    }
    return statusType;
  }

  handleSectionFilter(section: string): void {
    this.activeSectionFilter = section;
  }

  async populateReferralReasons() {
    return new Promise<any>(async (resolve, reject) => {
      if (!this.policyRiskTrxId) {
        return;
      }
      try {
        this.PolicyRiskTrxReasons.getPolicyRiskTrxReasons(
          this.policyRiskTrxId,
        ).subscribe({
          next: async (reasonsData) => {
            const quoteOptionReasons = reasonsData?.data;

            if (quoteOptionReasons?.length > 0) {
              this.riskTrxReasons = quoteOptionReasons?.map((reason) =>
                reason?.comments
                  ? reason?.comments
                  : reason?.reasonCodeDto?.description,
              );
            }
            resolve('true');
          },
          error: (error) => {
            reject(getErrorMessage(error));
          },
        });
      } catch (error) {}
    });
  }

  getStatus(status) {
    let valuesOfRenewal;
    if (status) {
      const filteredArray = this.riskTrxTypes.filter(
        (item) => item.id === status,
      );

      valuesOfRenewal = filteredArray.map((item) => item.description);
    }
    return valuesOfRenewal;
  }
}
