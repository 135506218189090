import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-min-max-range',
  templateUrl: './min-max-range.component.html',
  styleUrls: ['./min-max-range.component.less'],
})
export class MinMaxRangeComponent {
  @Input() label: string = '';
  @Input() min: number = 0;
  @Input() max: number = 1000000;
  @Input() step: number = 100;
  @Input() form;
  @Input() customStyle: { [klass: string]: any };
  @Input() formSubmitted: boolean = false;
  @Input() minControl: string;
  @Input() maxControl: string;

  constructor() {}

  handleMinValueChange(value) {
    this.form.controls[this.minControl].setValue(value);
  }

  handleMaxValueChange(value) {
    this.form.controls[this.maxControl].setValue(value);
  }
}
