<div class="holder">
  <div class="card">
    <div class="info-panel">
      <div class="content">
        <img class="mail" src="assets/img/shield.png" />
        <div class="header">
          <boxxds-heading
            [sz]="'6'"
            [text]="'Authenticate your account'"
            type="bold"
          ></boxxds-heading>
          <div class="subheader-container">
            <boxxds-body
              [text]="
                'Please confirm your account by entering the authroization code sent to your email.'
              "
            >
            </boxxds-body>
          </div>
        </div>

        <form [formGroup]="form" (ngSubmit)="handleSubmit($event)">
          <div class="input-otp">
            <input
              type="text"
              maxlength="1"
              [ngStyle]="{
                'border-color': form.value.digit1 === '' ? '#9090AF' : '#E9E9EC'
              }"
              id="digit1"
              maxlength="1"
              [formControlName]="'digit1'"
              autocomplete="off"
              (keyup)="onDigitInput($event)"
            />
            <input
              type="text"
              maxlength="1"
              [ngStyle]="{
                'border-color': form.value.digit2 === '' ? '#9090AF' : '#E9E9EC'
              }"
              id="digit2"
              maxlength="1"
              [formControlName]="'digit2'"
              autocomplete="off"
              (keyup)="onDigitInput($event)"
            />
            <input
              type="text"
              maxlength="1"
              [ngStyle]="{
                'border-color': form.value.digit3 === '' ? '#9090AF' : '#E9E9EC'
              }"
              id="digit3"
              [formControlName]="'digit3'"
              autocomplete="off"
              (keyup)="onDigitInput($event)"
            />
            <input
              type="text"
              maxlength="1"
              [ngStyle]="{
                'border-color': form.value.digit4 === '' ? '#9090AF' : '#E9E9EC'
              }"
              id="digit4"
              [formControlName]="'digit4'"
              autocomplete="off"
              (keyup)="onDigitInput($event)"
            />
            <input
              type="text"
              maxlength="1"
              [ngStyle]="{
                'border-color': form.value.digit5 === '' ? '#9090AF' : '#E9E9EC'
              }"
              id="digit5"
              maxlength="1"
              [formControlName]="'digit5'"
              autocomplete="off"
              (keyup)="onDigitInput($event)"
            />
            <input
              type="text"
              maxlength="1"
              [ngStyle]="{
                'border-color': form.value.digit6 === '' ? '#9090AF' : '#E9E9EC'
              }"
              id="digit6"
              [formControlName]="'digit6'"
              autocomplete="off"
              (keyup)="onDigitInput($event)"
            />
          </div>
          <div class="button-container">
            <boxxds-button
              [buttonText]="'Submit'"
              [type]="'submit'"
              [isDisabled]="isBtnDisabled()"
            >
            </boxxds-button>
          </div>
        </form>

        <div class="link-holder">
          <div>It may take a minute to receive your code.</div>
          <span> Haven't received it? </span>
          <a class="resend-link" type="button">Resend a new code.</a>
        </div>
      </div>
    </div>
    <div class="logo-panel">
      <div class="logo">
        <img src="assets/img/boxx.png" alt="boxx logo" />
      </div>
    </div>
  </div>
</div>
