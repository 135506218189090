<app-quote-action-popup
  [showModal]="showActionPopup"
  [details]="actionPopupDetails"
  (handleActionConfirm)="handleConfirmRiskAction($event)"
  (handleClose)="showActionPopup = false"
></app-quote-action-popup>
<app-submission-recalculate-confirmation-popup
  *ngIf="showRecalcConfModal && form$ | async as formData"
  [showModal]="showRecalcConfModal"
  [existingQuoteOptionData]="actionPopupDetails"
  (handleClose)="closeModalRecalculateConf()"
  (handleSubmissionRecalculate)="handleSubmissionRecalculate($event, formData)"
  (handleDiscardRecalculate)="handleDiscardRecalculate($event)"
>
</app-submission-recalculate-confirmation-popup>
<div
  class="spinner-container"
  *ngIf="
    showBodySpinner || ((form$ | async)?.riskQuestions | json) === ({} | json)
  "
>
  <ng-container>
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{
          width: '112px',
          height: '112px',
          '--b': '15px'
        }"
      ></boxxds-spinner>
    </div>
  </ng-container>
</div>
<div
  class="product-info-Outer-container"
  *ngIf="
    !(
      showBodySpinner || ((form$ | async)?.riskQuestions | json) === ({} | json)
    )
  "
>
  <div class="search-broker-container">
    <boxxds-create-dropdown
      [label]="('brokerage.label.searchBroker' | translate) + '*'"
      [lblStyle]="{
        color: '#333334',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '28px',
        marginLeft: '0px'
      }"
      [inputId]="'searchBroker'"
      [showLabel]="true"
      [placeholder]="'Select' | translate"
      [options]="BrokerOptions"
      [dispatchTextInputChange]="true"
      [requiredErrMsg]="'error.thisFieldIsRequired' | translate"
      (textInputChangeEventEmmiter)="searchBroker($event)"
      (currentValueChange)="selectBroker($event)"
      [btnStyle]="{ height: '36px' }"
      [hideSelectedValue]="false"
      [dropdownArrow]="true"
      [createUndefined]="false"
      [numberOfField]="1"
      [currentValue]="currentBrokerValue"
      [isDisabled]="
        !this.permissionList[this.currentScreen] ||
        this.currentQuoteStatus === 'quote-closed'
      "
      [dropdownContainerStyle]="{
        width: 'calc(50% + 32px)',
        marginBottom: '19px'
      }"
      class="search-dropdown"
      [form]="brokerageForm"
      [invalidErrMsg]="brokerageErrMsg"
    >
    </boxxds-create-dropdown>
  </div>
  <div class="productSelection" *ngIf="form$ | async as formDetails">
    <ng-container
      *ngIf="
        getStandaloneSellablePdts((form$ | async)?.products).length <= 1;
        else multipleProduct
      "
    >
      <div class="availableProducts-single">
        <div
          class="singleProductContainer"
          *ngFor="
            let option of getStandaloneSellablePdts((form$ | async)?.products);
            let i = index
          "
        >
          <h3 class="availableProductHead-single">
            {{ "common.availableProducts" | translate }}
          </h3>
          <div class="productItem">
            <boxxds-checkbox
              [isDisabled]="true"
              [options]="getProductCheckboxIndex(option)"
              (handleCheckbox)="handleCheckbox($event, productCheckbox)"
              [containerStyle]="{ display: 'flex' }"
              [lblStyle]="{
                color: '#475467',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '30px'
              }"
              [checkBoxStyle]="{
                background: 'none',
                width: '18px',
                height: '18px',
                border: '2px solid #667085'
              }"
              [checkMarkStyle]="{
                height: '10px',
                width: '5px',
                top: '8px',
                borderColor: '#667085',
                left: '5px',
                borderWidth: '2px'
              }"
            ></boxxds-checkbox>
            <p class="productDesc">
              <span
                [innerHTML]="getSanitizedHtmlContent(option.description)"
              ></span>
            </p>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #multipleProduct>
      <div
        class="availableProducts-multiple"
        *ngIf="(form$ | async)?.products as pdts"
      >
        <div class="carousal-outer-container">
          <button (click)="prevSlide()">
            {{ "common.previous" | translate }}
          </button>
          <div class="carousel-container">
            <div
              class="singleProductContainer carousel-wrapper"
              [style.transform]="'translateX(' + translateValue + 'px)'"
            >
              <div *ngFor="let slide of slides" class="carousel-slide">
                <div
                  class="productItem"
                  *ngIf="
                    getStandaloneSellablePdts(
                      (form$ | async)?.products
                    )[0] as option
                  "
                >
                  <boxxds-checkbox
                    [isDisabled]="true"
                    [options]="getProductCheckboxIndex(option)"
                    (handleCheckbox)="handleCheckbox($event, productCheckbox)"
                    [containerStyle]="{ display: 'flex' }"
                    [lblStyle]="{
                      color: '#475467',
                      fontSize: '20px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: '30px'
                    }"
                    [checkBoxStyle]="{
                      background: 'none',
                      width: '18px',
                      height: '18px',
                      border: '2px solid #667085'
                    }"
                    [checkMarkStyle]="{
                      height: '10px',
                      width: '5px',
                      top: '8px',
                      borderColor: '#667085',
                      left: '5px',
                      borderWidth: '2px'
                    }"
                  ></boxxds-checkbox>
                  <span
                    (click)="
                      openPopup(
                        $event,
                        getSanitizedHtmlContent(option.description),
                        option.key + ' :'
                      )
                    "
                    class="truncateButton"
                    [id]="option.key"
                    ><img src="assets/img/exclamation-circle.png" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <button (click)="nextSlide()">{{ "common.next" | translate }}</button>
        </div>
      </div>
    </ng-template>
    <div id="popup-container"></div>
  </div>
  <div class="product-content">
    <div class="product-nav">
      <ul class="products">
        <li
          *ngFor="
            let product of selectedProductStandaloneOptions;
            let i = index
          "
          (click)="setCurentAsActive(product.value)"
          class="product"
        >
          <div
            class="content"
            [ngClass]="{
              'active-product': true
            }"
          >
            <p>{{ product.key }}</p>
          </div>
        </li>
      </ul>
    </div>
    <ng-container
      *ngFor="let product of selectedProductStandaloneOptions; let i = index"
    >
      <div
        class="pdt-risk"
        *ngIf="(form$ | async).ui.currentProductDisplayed === product.value"
      >
        <div class="requestedLimit-Container">
          <boxxds-dropdown
            *ngIf="form$ | async as formObj"
            [label]="'common.requestedLimits' | translate"
            [lblStyle]="{ color: '#616162', fontSize: '14px' }"
            [placeholder]="'common.select' | translate"
            [options]="limitOptionsForProducts[product.key]"
            [dropdownContainerStyle]="{ width: '210px' }"
            [disabled]="isNavigatedFromQuoteCalc"
            (currentValueChange)="
              this.permissionList[this.currentScreen] &&
                this.currentQuoteStatus !== 'quote-closed' &&
                handleLimitChange($event, formObj.products, product.value)
            "
            [invalidErrMsg]="getLimitErrMsg(product.key)"
            [currentValue]="
              getLimitValue(product.riskId)
                ? (getLimitValue(product.riskId)
                  | currencyFormat: currency : 'symbol-narrow' : '1.0-0')
                : ''
            "
            [isReadonly]="
              !this.permissionList[this.currentScreen] ||
              this.currentQuoteStatus === 'quote-closed'
            "
            [class.noPointerEvents]="
              !this.permissionList[this.currentScreen] ||
              this.currentQuoteStatus === 'quote-closed'
            "
          >
          </boxxds-dropdown>
        </div>
        <div
          class="risk-questions"
          [class.noPointerEvents]="
            !this.permissionList[this.currentScreen] ||
            this.currentQuoteStatus === 'quote-closed'
          "
          *ngIf="
            isRiskFormLoaded &&
            (form$ | async)?.riskQuestions[product.value] as riskQuestions
          "
          [formGroup]="riskForm"
        >
          <div [formArrayName]="product.value">
            <div
              class="categoryContainer"
              *ngFor="
                let category of getCategories(riskQuestions);
                let catIdx = index
              "
            >
              <div class="category-inline-row">
                <boxxds-body
                  sz="md"
                  [text]="category + '*'"
                  [customStyle]="{
                    color: '#616162',
                    textTransform: 'capitalize',
                    fontSize: '18px'
                  }"
                  class="category-label"
                >
                </boxxds-body>
                <div *ngIf="getQuestions(category, riskQuestions).length > 1">
                  <div class="radio-all-btn">
                    <boxxds-radio
                      [control]="category"
                      class="radio-all"
                      [items]="[
                        {
                          label: ('common.yesToAll' | translate),
                          currentValue:
                            'categoryProperties[product.value + catIdx]?.checkAllYesBtn === true',
                          value: 'true'
                        }
                      ]"
                      [form]="getCategoryProductFormArray(product.value)"
                      [formSubmitted]="false"
                      (click)="
                        checkAllYes(
                          product.value,
                          category,
                          catIdx,
                          getQuestions(category, riskQuestions)
                        )
                      "
                      [radioFill]="true"
                      [lblStyle]="{
                        color: '#56565C',
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '24px'
                      }"
                    ></boxxds-radio>

                    <boxxds-radio
                      [control]="category"
                      class="radio-all"
                      [items]="[
                        {
                          label: ('common.noToAll' | translate),
                          currentValue:
                            'categoryProperties[product.value + catIdx]?.checkAllYesBtn === false',
                          value: 'false'
                        }
                      ]"
                      [form]="getCategoryProductFormArray(product.value)"
                      [formSubmitted]="false"
                      (click)="
                        checkAllNo(
                          product.value,
                          category,
                          catIdx,
                          getQuestions(category, riskQuestions)
                        )
                      "
                      [radioFill]="true"
                      [lblStyle]="{
                        color: '#56565C',
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '24px'
                      }"
                    ></boxxds-radio>
                  </div>
                </div>
              </div>

              <div class="category-holder">
                <ng-container
                  *ngIf="getQuestions(category, riskQuestions) as riskQustns"
                >
                  <div
                    class="question"
                    *ngFor="
                      let q of getQuestions(category, riskQuestions);
                      let questnIdx = index
                    "
                  >
                    <div
                      class="boolean-query"
                      *ngIf="q.dataType.description === 'Boolean'"
                      [formGroupName]="getFormName(riskQuestions, q)"
                    >
                      <div class="boolean-question-container">
                        <div class="boolean-question">{{ q.question }}</div>
                        <div class="radio-btn">
                          <ng-container
                            *ngIf="getProductFormArray(product.value)"
                          >
                            <boxxds-radio
                              [control]="q.id"
                              [items]="[
                                {
                                  label: ('common.yes' | translate),
                                  currentValue: q.value,
                                  value: 'true'
                                },
                                {
                                  label: ('common.no' | translate),
                                  currentValue: q.value,
                                  value: 'false'
                                }
                              ]"
                              [form]="getProductFormArray(product.value)"
                              [formSubmitted]="
                                this.permissionList[this.currentScreen] &&
                                this.currentQuoteStatus !== 'quote-closed' &&
                                validateRiskAnswers
                              "
                              [requiredErrMsg]="
                                'error.thisFieldIsRequired' | translate
                              "
                              (click)="
                                this.permissionList[this.currentScreen] &&
                                  this.currentQuoteStatus !== 'quote-closed' &&
                                  handleRadioCheck(
                                    product.value,
                                    category,
                                    catIdx
                                  )
                              "
                              [radioFill]="true"
                            ></boxxds-radio>
                          </ng-container>
                        </div>
                      </div>
                      <div class="subQuestion" *ngIf="q.sub">
                        <ul>
                          <li *ngFor="let point of getExplanationArr(q.sub)">
                            {{ point }}
                          </li>
                        </ul>
                      </div>

                      <div class="explanation" *ngIf="q.explanation">
                        <ul
                          [ngClass]="{
                            'column-layout': true
                          }"
                        >
                          <li
                            *ngFor="
                              let point of getExplanationArr(q.explanation)
                            "
                          >
                            {{ point }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      class="boolean-query"
                      *ngIf="q.dataType.description === 'Numeric'"
                      [formGroupName]="getFormName(riskQuestions, q)"
                    >
                      <div>{{ q.question }}</div>
                      <div class="explanation" *ngIf="q.explanation">
                        <ul
                          [ngClass]="{
                            'column-layout': q.explanation.length > 12
                          }"
                        >
                          <li *ngFor="let point of q.explanation">
                            {{ point }}
                          </li>
                        </ul>
                      </div>
                      <div class="radio-btn">
                        <input
                          [readOnly]="
                            !this.permissionList[this.currentScreen] ||
                            this.currentQuoteStatus === 'quote-closed'
                          "
                          class="no-stepper"
                          [id]="q.id"
                          [value]="q.value || 0"
                          type="number"
                          [required]="true"
                          autocomplete="off"
                          [formControlName]="q.id"
                          placeholder="Enter"
                          [ngStyle]="{
                            'border-color':
                              (validateRiskAnswers &&
                                isContinue &&
                                this.subRiskQuestionForm?.controls?.[q.id]
                                  ?.errors?.['required']) ||
                              (formSubmitted &&
                                ((this.subRiskQuestionForm?.controls?.[q.id]
                                  ?.errors?.['pattern'] &&
                                  !this.subRiskQuestionForm?.controls?.[q.id]
                                    ?.errors?.['required']) ||
                                  (this.subRiskQuestionForm?.controls?.[q.id]
                                    ?.errors?.['required'] &&
                                    isContinue)))
                                ? '#CA3434'
                                : null
                          }"
                        />
                      </div>
                      <div
                        *ngIf="
                          this.subRiskQuestionForm?.controls?.[q.id]?.errors?.[
                            'required'
                          ] &&
                          isContinue &&
                          (validateRiskAnswers || formSubmitted)
                        "
                        class="error-msg"
                      >
                        {{ "error.thisFieldIsRequired" | translate }}
                      </div>
                      <div
                        *ngIf="
                          this.subRiskQuestionForm?.controls?.[q.id]?.errors?.[
                            'pattern'
                          ] &&
                          !this.subRiskQuestionForm?.controls?.[q.id]?.errors?.[
                            'required'
                          ] &&
                          (validateRiskAnswers || formSubmitted)
                        "
                        class="error-msg"
                      >
                        {{ "productForm.error.numberRiskAnswer" | translate }}
                      </div>
                    </div>
                    <div
                      class="boolean-query"
                      *ngIf="q.dataType.description === 'String'"
                      [formGroupName]="getFormName(riskQuestions, q)"
                    >
                      <div>{{ q.question }}</div>
                      <div class="explanation" *ngIf="q.explanation">
                        <ul
                          [ngClass]="{
                            'column-layout': q.explanation.length > 12
                          }"
                        >
                          <li *ngFor="let point of q.explanation">
                            {{ point }}
                          </li>
                        </ul>
                      </div>
                      <div class="radio-btn">
                        <input
                          type="text"
                          [id]="q.id"
                          [value]="q.value || ''"
                          [required]="true"
                          autocomplete="off"
                          [formControlName]="q.id"
                          placeholder="Enter"
                          [disabled]="
                            !this.permissionList[this.currentScreen] ||
                            this.currentQuoteStatus === 'quote-closed'
                          "
                        />
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="continue-btn-holder-continue">
    <div class="continue-btn-holder">
      <div class="back-button-container">
        <boxxds-link
          *ngIf="!isNavigatedFromQuoteCalc"
          [name]="'Back' | translate"
          [linkStyle]="{ fontWeight: '500px' }"
          btnType="secondary"
          type="submit"
          (handleClick)="handleBackBtn()"
          class="back-btn"
          [class.noPointerEvents]="showSpinner"
        >
        </boxxds-link>
        <boxxds-link
          [linkStyle]="{ fontWeight: '500px' }"
          (handleClick)="handleExitFlow()"
          [name]="'submission.link.exitTheFlow' | translate"
          [class.noPointerEvents]="showSpinner"
        ></boxxds-link>
        <ng-container
          *ngIf="
            this.permissionList[this.currentScreen] &&
            this.currentQuoteStatus !== 'quote-closed'
          "
        >
          <boxxds-button
            *ngIf="!showSpinner && !uiData.isNavigatedFromQuoteCalc"
            [buttonText]="'common.saveDraft' | translate"
            btnType="secondary"
            [btnStyle]="{ width: '120px', marginRight: '12px' }"
            type="submit"
            (handleClick)="handleSaveDraft($event)"
            [isDisabled]="showSpinner"
          >
          </boxxds-button>
          <button
            *ngIf="showSpinner && !uiData.isNavigatedFromQuoteCalc"
            class="btn-processing"
            tabindex="0"
            [ngStyle]="{
              border: '1px solid #9090AF',
              borderRadius: '8px',
              width: '110px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '3px 6px',
              background: '#FCF8EF',
              marginRight: '12px'
            }"
          >
            <boxxds-spinner></boxxds-spinner>
          </button>
        </ng-container>
      </div>
      <div class="save-continue">
        <ng-container
          *ngIf="
            this.permissionList[this.currentScreen] &&
            this.currentQuoteStatus !== 'quote-closed'
          "
        >
          <boxxds-button
            *ngIf="!showSpinner && !uiData.isNavigatedFromQuoteCalc && quoteId"
            [buttonText]="'productForm.link.decline' | translate"
            btnType="secondary"
            [btnStyle]="{ width: '120px', marginRight: '12px' }"
            (handleClick)="handleRiskAction(transactionRiskActions.Decline)"
            [isDisabled]="showSpinner"
          >
          </boxxds-button>
        </ng-container>
        <boxxds-button
          *ngIf="!showSpinner && form$ | async as formData"
          [buttonText]="'submission.continueToStep3Of4' | translate"
          btnType="secondary-solid"
          (handleClick)="handleContinue($event, formData)"
          [btnStyle]="{
            width: '199px',
            backgroundColor: '#FFBE3D',
            color: '#2D2D39'
          }"
          [isDisabled]="showSpinner"
        >
        </boxxds-button>
        <button
          *ngIf="showSpinner"
          class="btn-processing"
          tabindex="0"
          [ngStyle]="{
            border: '1px solid #9090AF',
            borderRadius: '8px',
            width: '199px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '3px 6px',
            background: '#FCF8EF',
            marginRight: '12px'
          }"
        >
          <boxxds-spinner></boxxds-spinner>
        </button>
      </div>
    </div>
  </div>
</div>
