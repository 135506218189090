import { Component, EventEmitter, Output } from '@angular/core';
import { PolicyDashboardService } from 'src/app/services/policy-dashboard.service';

@Component({
  selector: 'app-search-v2',
  templateUrl: './search-v2.component.html',
  styleUrls: ['./search-v2.component.less'],
})
export class SearchV2Component {
  constructor(public policyService: PolicyDashboardService) {}

  @Output() suggestedSearchSelected = new EventEmitter();

  searchTypes: { key: string; value: string }[] = [
    { key: 'Insured', value: 'Insured' },
    { key: 'Policy number', value: 'Policy number' },
  ];

  isSearchTypeMenuOpen: boolean = false;
  searchSuggestionsMenuOpen: boolean = false;
  searchSuggestions: {
    id: number;
    name: string;
    value: string;
    type: string;
  }[] = [];
  currentSearchType: string = this.searchTypes[0].value;
  selectedResult: { id: number; name: string; value: string; type: string }[] =
    [];
  debounceTimer: any;

  toggleSearchType(event: Event): void {
    event.preventDefault();
    this.isSearchTypeMenuOpen = !this.isSearchTypeMenuOpen;
  }

  selectSearchType({ key, value }: { key: string; value: string }) {
    this.currentSearchType = value;
    this.isSearchTypeMenuOpen = false;
    this.searchSuggestions = [];
    this.selectedResult = [];
    const searchInputField = document.getElementById('search-input-field');
    searchInputField['value'] = '';
  }

  handleSearchFocused() {
    setTimeout(() => {
      this.searchSuggestionsMenuOpen =
        this.searchSuggestions.length > 0 ? true : false;
    }, 100);
  }

  handleClickOutsideSearchSuggestionsMenu() {
    const searchInputField = document.getElementById('search-input-field');
    const isFocused = document.activeElement === searchInputField;
    if (!isFocused) {
      this.searchSuggestionsMenuOpen = false;
    }
  }

  selectSuggestion(suggestion: {
    id: number;
    name: string;
    value: string;
    type: string;
  }) {
    let selectedSuggestion = [];
    selectedSuggestion.push(suggestion);
    this.selectedResult = selectedSuggestion;
    this.searchSuggestionsMenuOpen = false;
    this.suggestedSearchSelected.emit({
      selectedSearch: this.selectedResult[0],
      searchIn: this.currentSearchType,
      type: this.selectedResult[0].type,
    });
  }

  searchCall(event: any) {
    let searchKey = event.target.value;
    const page = 1;
    const limit = 5;
    const sort = '';
    if (this.currentSearchType === this.searchTypes[1].value) {
      if (searchKey.length > 0) {
        const pkgPolicyNumber = encodeURIComponent(searchKey);

        this.policyService
          .GetAllByPolicyNumber(pkgPolicyNumber, page, limit, sort)
          .subscribe({
            next: (response: any) => {
              const suggestions = response.data?.map((dataObj) => ({
                name: dataObj.policy.pkgPolicyNumber,
                value: dataObj.policy.pkgPolicyNumber,
              }));
              this.searchSuggestions = suggestions;
              this.searchSuggestionsMenuOpen = true;
            },
            error: (error: any) => {
              if (![500].includes(error?.status)) {
                // add alert service
              }
            },
          });
      } else {
        this.searchSuggestions = [];
        this.selectedResult = [];
        this.searchSuggestionsMenuOpen = false;
      }
    } else {
      if (searchKey.length > 2) {
        this.policyService
          .GetAllByInsuredName(
            encodeURIComponent('%' + searchKey),
            page,
            limit,
            sort,
          )
          .subscribe({
            next: (response) => {
              const suggestions = response.data?.map((dataObj) => {
                const name = this.makeSuggest(dataObj.policy.insured);
                return {
                  name: name,
                  value: name,
                  id: dataObj.policy?.insured?.id,
                  type:
                    dataObj.policy.insured.insuredType === 2
                      ? 'company'
                      : 'individual',
                };
              });

              this.searchSuggestions = suggestions.filter(
                (obj, index, self) =>
                  index === self.findIndex((o) => o.id === obj.id),
              );
              this.searchSuggestionsMenuOpen = true;
            },
            error: (error) => {
              if (![500].includes(error?.status)) {
              }
            },
          });
      } else {
        this.searchSuggestions = [];
        this.selectedResult = [];
        this.searchSuggestionsMenuOpen = false;
      }
    }
  }

  makeSuggest(insured) {
    if (insured.insuredType === 2) return insured.companyName;
    return insured.firstName + ' ' + insured.lastName;
  }

  handleSearch(event: any) {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      this.searchCall(event);
    }, 300);
  }
}
