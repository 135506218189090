<div class="spinner-container">
  <div class="spinner-holder-submission" *ngIf="showBodySpinner">
    <div class="body-spinner-submission">
      <div class="body-spinner-wrapper">
        <boxxds-spinner
          [containerStyle]="{
            width: '112px',
            height: '112px',
            '--b': '15px'
          }"
        ></boxxds-spinner>
      </div>
    </div>
  </div>
</div>

<app-navigation-v2
  [showHeaderBanner]="true"
  [mainHeading]="headerName"
  [subHeading]="headerProvince + (headerDetail ? '/' + headerDetail : '')"
  [isNeeded]="false"
  [showHeader]="true"
  [items]="breadCrumbArray"
  (suggestedSearchSelected)="handleGlobalSearch($event)"
>
</app-navigation-v2>
<section class="title-section">
  <div class="max-width-wrapper">
    <div class="title-description-container">
      <div class="title-container">
        <boxxds-heading
          class="title"
          [sz]="'6'"
          [text]="headerName"
          type="bold"
        ></boxxds-heading>
        <boxxds-body
          class="description"
          [text]="headerProvince + (headerDetail ? '/' + headerDetail : '')"
          [customStyle]="{
            fontFamily: 'Gibson',
            color: '#475467'
          }"
        >
        </boxxds-body>
      </div>
    </div>

    <div class="button-container" *ngIf="insuredId">
      <boxxds-button-v2
        [size]="'lg'"
        [buttonText]="'workFlow3.insuredForm.button.edit' | translate"
        [type]="'submitted'"
        [btnClass]="'secondary gray'"
        class="edit-details"
        [isDisabled]="true"
        [btnStyle]="{ width: '154px' }"
      >
      </boxxds-button-v2>
      <boxxds-button-v2
        [size]="'lg'"
        [buttonText]="
          (isRestrictedRoleSubmission
            ? 'workFlow3.insuredForm.button.createSubmission'
            : 'workFlow3.insuredForm.button.createQuote'
          ) | translate
        "
        [type]="'submitted'"
        [btnClass]="'primary'"
        class="start-quote"
        (handleClick)="handleStartNewQuote()"
        [isDisabled]="(quoteExist === 0 ? false : true) || !isInsuredActive"
      >
      </boxxds-button-v2>
    </div>
  </div>
</section>

<section
  class="newSubmissionWrapperWF3"
  [ngStyle]="{ opacity: !showBodySpinner ? '1' : '0' }"
>
  <div class="submissionSection">
    <div
      class="main-card insured-module"
      [ngStyle]="{ opacity: !showBodySpinner ? '1' : '0' }"
    >
      <ng-container>
        <div class="rowInsurer">
          <div class="columnInsurerLeft">
            <boxxds-body
              sz="md"
              [text]="
                'workFlow3.components.newSubmission.heading.insuredType'
                  | translate
              "
              [customStyle]="{
                fontFamily: 'Gibson',
                color: '#2D2D39',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '20px'
              }"
              [isRequired]="true"
            ></boxxds-body>
            <boxxds-body
              sz="md"
              [text]="
                'workFlow3.components.newSubmission.subHeading.insureType'
                  | translate
              "
              [customStyle]="{
                fontFamily: 'Gibson',
                color: '#475467',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '20px'
              }"
            ></boxxds-body>
          </div>

          <div class="columnInsurerRight d-flex">
            <boxxds-radio-v2
              [items]="radioBusiness"
              [control]="'insured_type'"
              [form]="insuredTypeForm"
              [formSubmitted]="false"
              [radioStyle]="{ marginTop: '1px' }"
              [containerStyle]="{ marginRight: '10px' }"
              [labelStyle]="{
                fontFamily: 'Gibson',
                color: '#2D2D39',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '20px'
              }"
              (handleChange)="handleInsuredTypeChange()"
            >
            </boxxds-radio-v2>
            <boxxds-radio-v2
              [items]="radioIndividual"
              [control]="'insured_type'"
              [form]="insuredTypeForm"
              [formSubmitted]="false"
              [radioStyle]="{ marginTop: '1px' }"
              [labelStyle]="{
                fontFamily: 'Gibson',
                color: '#2D2D39',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '20px'
              }"
              (handleChange)="handleInsuredTypeChange()"
            >
            </boxxds-radio-v2>
          </div>
        </div>

        <div class="lineDivider"></div>

        <div *ngIf="typeBusinessInsured === false">
          <ng-container>
            <app-individual-insured-form-workflow3
              [form]="addIndividualForm"
              [form$]="form$"
              [checkValidation]="checkValidation"
              [insuredId]="insuredId"
              [fromSubmissionFlow]="false"
              (populateLatestInsured)="populateLatestInsuredData($event)"
            >
            </app-individual-insured-form-workflow3>
          </ng-container>
        </div>

        <div *ngIf="typeBusinessInsured === true">
          <ng-container>
            <app-business-insured-form-workflow3
              [form]="addCompanyForm"
              [contactForm]="contactForm"
              [form$]="form$"
              [checkValidation]="checkValidation"
              [fromSubmissionFlow]="false"
              [insuredId]="insuredId"
              [checkContactValidation]="checkContactValidation"
              [isAtLeastOneFieldFilled]="isAtLeastOneFieldFilled"
              (addContactEvent)="addContact()"
              (populateLatestInsured)="populateLatestInsuredData($event)"
              (setNAICSData)="setNAICSData($event)"
            >
            </app-business-insured-form-workflow3>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <div
      class="submission-footer d-flex justify-content-end"
      [ngStyle]="{ opacity: !showBodySpinner ? '1' : '0' }"
    >
      <boxxds-button-v2
        *ngIf="insuredId && fromSubmissionFlow === false"
        [size]="'lg'"
        [type]="'submit'"
        [btnStyle]="{ padding: '12px' }"
        [buttonText]="activateButtonTxt | translate"
        [btnTextStyle]="{ marginRight: '12px', textDecoration: 'underline' }"
        [btnClass]="'link gray'"
        (click)="deactivateOrReactivateInsured()"
      >
      </boxxds-button-v2>
      <boxxds-button-v2
        [size]="'md'"
        [type]="'submit'"
        [btnStyle]="{ padding: '14px 10px', width: '106px', height: '40px' }"
        [buttonText]="'workFlow3.insuredForm.button.discard' | translate"
        [btnClass]="'secondary gray'"
        [btnTextStyle]="{ fontFamily: 'Gibson' }"
        (click)="discardInsuredFormChanges($event)"
        [isDisabled]="!isInsuredActive"
      >
      </boxxds-button-v2>
      <boxxds-button-v2
        *ngIf="insuredId && !!submissionDetailUrl"
        [size]="'md'"
        [type]="'submit'"
        [btnStyle]="{ padding: '14px 10px', width: '218px', height: '40px' }"
        [buttonText]="'workFlow3.common.viewSubmissionDetails' | translate"
        [btnClass]="'secondary'"
        [btnTextStyle]="{ fontFamily: 'Gibson', color: '#FFAA00' }"
        (handleClick)="openSubmissionDetailsUrl()"
        [isDisabled]="!!!submissionDetailUrl"
      >
      </boxxds-button-v2>
      <boxxds-button-v2
        [size]="'md'"
        [type]="'submit'"
        [buttonText]="saveButtonTxt | translate"
        [btnClass]="'primary'"
        (click)="addOrUpdateInsurer($event)"
        [isDisabled]="!DoCheck() || !isInsuredActive"
        [btnStyle]="{ width: '230px', height: '40px' }"
      >
      </boxxds-button-v2>
    </div>
    <div class="footer-timestamp">
      <app-pagefooter-timestamp-v2
        *ngIf="createdBy && createdAt"
        [createdBy]="createdBy"
        [createdAt]="createdAt"
        [updatedBy]="updatedBy"
        [updatedAt]="updatedAt"
      >
      </app-pagefooter-timestamp-v2>
    </div>
  </div>
</section>
<app-modal-market-reservation
  *ngIf="showMarketReservationModal"
  [showModal]="showMarketReservationModal"
  [existingQuoteOptionData]="actionPopupDetails"
  (handleClose)="closeModalMarketReservation()"
  (seeExistingQuote)="handleSeeExistingQuote()"
>
</app-modal-market-reservation>

<app-modal-insured-state-change
  *ngIf="showStateChangeModal"
  [showModal]="showStateChangeModal"
  [existingQuoteOptionData]="actionPopupDetails"
  (handleClose)="closeModalInsuredStateChange()"
  (handleDecline)="handleDeclineQuote(true)"
  (handleNewInsured)="handleCreateNewInsured()"
  [isSubmissionFlow]="false"
  [isTbd]="isTBD"
>
</app-modal-insured-state-change>
<app-modal-risk-analysis
  *ngIf="showRiskAnalysisUpdateModal"
  [showModal]="showRiskAnalysisUpdateModal"
  [existingQuoteOptionData]="actionPopupDetails"
  (handleClose)="closeModalRiskAnalysisUpdate()"
  (handleDiscard)="handleDiscardRecalculate($event)"
  (handleContinue)="handleRiskAnalysisChange()"
  [isSubmissionFlow]="false"
  [isTbd]="isTBD"
>
</app-modal-risk-analysis>

<app-revenue-or-sanction-popup
  *ngIf="showRevenueRiskQuestionUpdateModal"
  [riskQuestions]="riskQuestions"
  [sanctionTableData]="sanctionPayload"
  [details]="actionPopupDetails"
  (handleClose)="closeModalRevenueRiskQuestion()"
  (handleDiscardChanges)="handleDiscardRecalculate($event)"
  (handleSave)="handleSaveRevenueRiskQuestions($event)"
  (handleDecline)="handleDeclineQuoteSanction()"
>
</app-revenue-or-sanction-popup>

<app-modal-premium-recalculate
  *ngIf="showRecalcConfModal"
  [showModal]="showRecalcConfModal"
  [existingQuoteOptionData]="actionPopupDetails"
  (handleClose)="closeModalRecalculateConf()"
  (handleSubmissionRecalculate)="handleSubmissionRecalculate($event, null)"
  (handleDiscardRecalculate)="handleDiscardRecalculate($event)"
  [isSubmissionFlow]="false"
  [isTbd]="isTBD"
>
</app-modal-premium-recalculate>
<app-modal-existing-quote
  *ngIf="showExistingQuoteModal"
  [showModal]="showExistingQuoteModal"
  [existingQuoteOptionData]="actionPopupDetails"
  (handleClose)="closeExistingQuote()"
  (seeExistingQuote)="handleSeeExistingQuote()"
>
</app-modal-existing-quote>
<app-restricted-industry-or-sanction-popup
  *ngIf="showSanctionModal"
  [details]="actionPopupDetails"
  [tableData]="sanctionPayload"
  (handleClose)="closeSanctionPopup()"
  (handleSubmit)="handleSanctionProceed()"
  (handleDeclineAndSaveChanges)="handleDeclineQuote()"
  (handleDiscardChanges)="handleDiscardRecalculate($event)"
  [columnsToHide]="columnsToHide"
  [sortableColumns]="sortableColumns"
  [iconColumns]="iconColumns"
  [customColumnWidth]="customColumnWidth"
  (sortHandler)="handleSort($event)"
  (columnClickHandler)="columnClickHandler($event)"
  [isRestrictedIndustry]="isRestrictedIndustry"
  [hasQuote]="quoteExist === 1 || quoteExist === 2"
  [isOfacError]="isOfacError"
>
</app-restricted-industry-or-sanction-popup>
