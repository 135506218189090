<div class="holder">
  <boxxds-alert
    *ngIf="showReloadModal"
    (handleCloseEvent)="handleCloseReloadWarning()"
    type="warn"
    [headerText]="'common.warnMsg' | translate"
    [bodyText]="'common.reloadMessagePermissionChange' | translate"
  >
  </boxxds-alert>
  <boxxds-breadcrumb [items]="itemsMenu"></boxxds-breadcrumb>
  <boxxds-alert
    *ngIf="showErrorAlert"
    (handleCloseEvent)="handleCloseSuccessEventExt()"
    type="error"
    [headerText]="'common.errorMsg' | translate"
    [bodyText]="msgErrorAlert"
  >
  </boxxds-alert>

  <boxxds-alert
    *ngIf="isSuccess"
    type="success"
    [headerText]="'common.successMsg' | translate"
    bodyText="Saved successfully"
    (handleCloseEvent)="handleCloseSuccessEvent()"
  >
  </boxxds-alert>

  <boxxds-alert
    *ngIf="hasError"
    (handleCloseEvent)="handleCloseSuccessEvent()"
    type="error"
    [headerText]="'common.errorMsg' | translate"
    [bodyText]="errorMessage"
  >
  </boxxds-alert>

  <div class="heading-holder d-flex align-items-center">
    <boxxds-heading
      *ngIf="!isRoleNameEditMode"
      sz="5"
      [text]="role?.name"
      [customStyle]="{ marginRight: '24px' }"
    ></boxxds-heading>
    <ng-container *ngIf="isRoleNameEditMode">
      <boxxds-textinput
        [inputAutoFocus]="true"
        [form]="form"
        [placeholder]="'Enter role name'"
        [control]="'role.roleName'"
        [id]="'roleName'"
        [width]="'100%'"
        [containerStyle]="{ maxWidth: '250px' }"
        (handleBlur)="togglePolicyFeeEdit($event, isRoleNameEditMode)"
      >
      </boxxds-textinput>
    </ng-container>
    <div
      *ngIf="!isRoleNameEditMode && this.permissionList[this.currentScreen]"
      class="btn-edit d-flex align-items-center"
      (click)="togglePolicyFeeEdit($event, isRoleNameEditMode)"
    >
      <img
        *ngIf="!isRoleNameEditMode"
        src="assets/img/edit-icon.png"
        alt="edit"
        class="img-icon"
      />
      <boxxds-body
        sz="xs"
        [text]="'Edit details'"
        [customStyle]="{
          color: '#616162',
          lineHeight: '14px',
          marginLeft: '4px',
          textTransform: 'uppercase',
          cursor: 'pointer'
        }"
      ></boxxds-body>
    </div>
  </div>
  <boxxds-body
    sz="xs"
    [text]="'Role description'"
    [customStyle]="{
      color: '#616162',
      lineHeight: '14px',
      marginLeft: '4px',
      textTransform: 'uppercase',
      marginTop: '12px',
      marginBottom: '10px'
    }"
  ></boxxds-body>
  <boxxds-body
    *ngIf="role"
    sz="sm"
    [text]="role.description"
    [customStyle]="{
      color: '#56565c',
      lineHeight: '14px',
      marginLeft: '4px',
      marginTop: '4px',
      width: '50%'
    }"
  ></boxxds-body>

  <div class="content">
    <div class="d-flex justify-content-between">
      <div class="sub-nav">
        <ul>
          <li *ngFor="let nav of subNavData; let i = index">
            <button
              [ngClass]="{ active: nav.active }"
              (click)="handleNav($event, i)"
            >
              {{ nav.name }}
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div *ngIf="addPermissionInProgress">
      <boxxds-spinner></boxxds-spinner>
    </div>
    <div *ngIf="!type" class="d-flex float-right">
      <ng-container *ngIf="this.permissionList[this.currentScreen]">
        <boxxds-searchable-dropdown
          label=""
          placeholder="Search permissions to add"
          (currentValueChange)="handleAttachSubjectives($event, 1)"
          [btnStyle]="{ height: '36px' }"
          [hideSelectedValue]="true"
          [dropdownContainerStyle]="{
            marginLeft: '10px',
            width: '260px',
            marginBottom: '19px',
            paddingLeft: '0px'
          }"
          rightIcon="add"
          (textInputChangeEventEmmiter)="searchPermisions($event)"
          [dispatchTextInputChange]="true"
          [ngContentList]="true"
          [options]="suggestedPermissions || []"
        >
          <div *ngIf="searchQuery">
            <li class="tab-li" *ngFor="let option of suggestedPermissions">
              <div class="d-flex justify-content-between">
                <div>
                  <boxxds-body
                    sz="sm"
                    [text]="option.key"
                    [customStyle]="{ color: '#56565c' }"
                  ></boxxds-body>
                </div>
                <div>
                  <button
                    *ngIf="!option.isAdded"
                    class="add-button"
                    (click)="handleAddPermission(option.value)"
                  >
                    Add
                  </button>
                </div>
              </div>
            </li>
          </div>
        </boxxds-searchable-dropdown>
      </ng-container>
    </div>
    <boxxds-table
      *ngIf="!type"
      [data]="permissions"
      [totalCount]="permissionsDataCount"
      (pageChanged)="permissionPageChangeHandler($event)"
      class="table"
      [addSortTo]="addSortTo"
      [showStar]="false"
      (sortUpClicked)="handleAscSort($event)"
      (sortDownClicked)="handleDescSort($event)"
      [tableStyle]="{ marginBottom: '30px' }"
      [linkDetails]="[{ name: 'edit' }, { name: 'delete' }]"
      [inlineEditProps]="
        this.permissionList[this.currentScreen] ? editColumns : []
      "
      (saveEditHandler)="
        this.permissionList[this.currentScreen] && savePermission($event)
      "
      (deleteHandler)="
        this.permissionList[this.currentScreen] && deletePermission($event)
      "
      [showSpinner]="showTblSpinner"
      [tableStyle]="{ marginBottom: isSearchResult ? '58px' : '27.5px' }"
      [activePageNumber]="pageNumber"
    >
    </boxxds-table>

    <boxxds-table
      *ngIf="type"
      [data]="members"
      [totalCount]="membersDataCount"
      (pageChanged)="memberPageChangeHandler($event)"
      class="table"
      [addSortTo]="addMemberSort"
      [showStar]="false"
      (sortUpClicked)="handleAscMemberSort($event)"
      (sortDownClicked)="handleDescMemberSort($event)"
      [showSpinner]="showTblSpinner"
      [tableStyle]="{ marginBottom: '30px' }"
      [linkDetails]="[{ name: 'view' }]"
      [showLinks]="true"
      [styleInactive]="true"
      [tableStyle]="{ marginBottom: isSearchResult ? '58px' : '27.5px' }"
      [activePageNumber]="pageNumber"
    >
    </boxxds-table>
  </div>
  <app-timestamp
    *ngIf="role"
    label="Created by"
    value="{{ role.createdBy }} , {{ createdDt }}"
    [containerStyle]="{
      marginLeft: '8px',
      position: 'relative',
      top: '-6px',
      textTransform: 'uppercase'
    }"
  ></app-timestamp>
  <app-timestamp
    *ngIf="role && updatedDt"
    label="Updated last by"
    value="{{ role.updatedBy }} , {{ updatedDt }}"
    [containerStyle]="{
      marginLeft: '8px',
      position: 'relative',
      top: '-20px',
      textTransform: 'uppercase'
    }"
  ></app-timestamp>
</div>
