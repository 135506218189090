<div class="scrollable-tbl-container">
  <div
    class="scroll-range"
    #scrollContainer
    [ngClass]="{ 'no-scroll': !isScrollable }"
  >
    <table class="scrollable-table">
      <thead>
        <tr class="header-row">
          <ng-container *ngFor="let tblHeader of tableHeaders">
            <th
              *ngIf="
                tblHeader !== 'isReadOnly' &&
                tblHeader !== 'allowOverwriteDefault' &&
                tblHeader !== 'factorForLimit' &&
                tblHeader !== 'included' &&
                tblHeader !== 'defaultLimit' &&
                tblHeader !== 'defaultDeductible'
              "
              class="tbl-hd fixed-side"
            >
              {{ tblHeader }}
            </th>
          </ng-container>
          <th class="tbl-hd fixed-last-col">
            <button
              *ngIf="isScrollable"
              class="icon-button"
              [ngClass]="{ 'chevron-left': !scrollToRight }"
              (click)="scrollRight()"
            >
              <img src="assets/img/scroll-table/chevron-down.png" />
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of data; let indexOfelement = index">
          <ng-container *ngIf="!isExpanded[indexOfelement]">
            <tr
              [ngClass]="{
                'read-only-row': item['isReadOnly'] || showAsReadOnly,
                'excluded-row': !!!item['included']
              }"
            >
              <ng-container
                *ngFor="let key of tableHeaders; let tblIdx = index"
              >
                <td
                  class="table-data"
                  *ngIf="
                    key !== 'isReadOnly' &&
                    key !== 'allowOverwriteDefault' &&
                    key !== 'factorForLimit' &&
                    key !== 'included' &&
                    key !== 'defaultLimit' &&
                    key !== 'defaultDeductible'
                  "
                  [attr.title]="item[key] ?? null"
                  [ngClass]="{ 'fixed-side': tblIdx === 0 }"
                >
                  <span
                    *ngIf="
                      (key === 'Sublimit' || key === 'Deductibles') &&
                      tblIdx !== 0
                    "
                  >
                    <!-- eslint-disable @angular-eslint/template/eqeqeq -->
                    <span
                      class="edited-indicator"
                      *ngIf="item[key] != initialData[indexOfelement][key]"
                    ></span>
                    {{
                      isNumber(item[key])
                        ? (item[key]
                          | currency: currency : "symbol-narrow" : "1.0-0")
                        : item[key]
                    }}</span
                  >
                  <span
                    *ngIf="
                      key !== 'Sublimit' &&
                      key !== 'Deductibles' &&
                      tblIdx !== 0
                    "
                  >
                    <span
                      class="edited-indicator"
                      *ngIf="item[key] != initialData[indexOfelement][key]"
                    ></span>
                    {{ item[key] }}</span
                  >
                  <!-- eslint-enable @angular-eslint/template/eqeqeq -->
                  <div
                    class="d-flex align-items-center mt-2"
                    *ngIf="tblIdx === 0"
                  >
                    <boxxds-switch-v2
                      *ngIf="
                        this.permissionList[this.currentScreen] &&
                        this.currentQuoteStatus !== 'quote-closed' &&
                        tblIdx === 0
                      "
                      name="sublimit-included"
                      (changed)="
                        !['quote-closed'].includes(this.currentQuoteStatus) &&
                          handleSublimitIncludeToggle($event, indexOfelement)
                      "
                      [ngStyle]="{ marginBottom: '0px' }"
                      [checked]="item['included']"
                      [switchStyle]="
                        item['included'] && {
                          backgroundColor: !!item['isReadOnly']
                            ? '#9090AF'
                            : '#FCBD32'
                        }
                      "
                      [disabled]="
                        !!item['isReadOnly'] ||
                        ['quote-closed'].includes(this.currentQuoteStatus)
                      "
                      [class.noPointerEvents]="
                        !!item['isReadOnly'] ||
                        ['quote-closed'].includes(this.currentQuoteStatus)
                      "
                    >
                    </boxxds-switch-v2>
                    <span
                      class="sublimit-name"
                      [attr.title]="item[key] ?? null"
                    >
                      {{ item[key] }}</span
                    >
                  </div>
                </td>
              </ng-container>
              <td
                class="fixed-last-col"
                *ngIf="
                  this.permissionList[this.currentScreen] &&
                  this.currentQuoteStatus !== 'quote-closed'
                "
              >
                <button
                  *ngIf="
                    item['included'] && !showAsReadOnly && !item['isReadOnly']
                  "
                  class="icon-button"
                  (click)="handleEdit(indexOfelement)"
                >
                  <img src="assets/img/scroll-table/edit.png" />
                </button>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="isExpanded[indexOfelement]">
            <tr class="edited-row">
              <td
                class="table-data"
                [attr.title]="item['Coverage'] ?? null"
                class="fixed-side"
              >
                {{ item["Coverage"] }}
              </td>

              <td>
                <boxxds-dropdown-v2
                  [leftSymbol]="'$'"
                  [hasOuterBorder]="false"
                  *ngIf="!showLimitCustomInput"
                  [currentValue]="
                    form.value.sublimitAmount
                      | currencyFormat: currency : 'symbol-narrow' : '1.0-0'
                  "
                  [placeholder]="
                    'quoteCalculator.modal.sublimitAmount' | translate
                  "
                  [label]="'Sublimit' | translate"
                  [options]="
                    currentCoverageName
                      ?.toLowerCase()
                      ?.includes(financialCoverageMatchString)
                      ? sublimitOptionsForFinancialCoverage
                      : limitOptionsForProducts
                  "
                  [isDisabled]="
                    (currentCoverageName
                      ?.toLowerCase()
                      ?.includes(financialCoverageMatchString) &&
                      setFinancialSublimitValidation &&
                      form.value.retentionAmount <
                        financialCoverageMinDeductible) ||
                    (item['allowOverwriteDefault'] &&
                      item['Coverage'] !== b10SublimitDescription)
                  "
                  [control]="'sublimitAmount'"
                  [dropdownContainerStyle]="{ maxWidth: '150px' }"
                  class="input-fields"
                  (currentValueChange)="
                    handleSublimitChange(
                      $event,
                      currentCoverageName
                        ?.toLowerCase()
                        ?.includes(financialCoverageMatchString)
                        ? sublimitOptionsForFinancialCoverage
                        : limitOptionsForProducts
                    )
                  "
                >
                </boxxds-dropdown-v2>
                <boxxds-textinput-v2
                  [hasOuterBorder]="false"
                  *ngIf="showLimitCustomInput"
                  [isDisabled]="item['allowOverwriteDefault']"
                  [placeholder]="
                    'quoteCalculator.modal.sublimitAmount' | translate
                  "
                  [control]="'sublimitAmount'"
                  [label]="'Sublimit' | translate"
                  [id]="'sublimitAmount'"
                  [width]="'100%'"
                  minLength="3"
                  maxLength="14"
                  [form]="form"
                  mask="separator.0"
                  thousandSeparator=","
                  prefix="$"
                >
                </boxxds-textinput-v2>
              </td>
              <td>
                <boxxds-dropdown-v2
                  [leftSymbol]="'$'"
                  [label]="'Occurrence type' | translate"
                  [hasOuterBorder]="false"
                  [currentValue]="form.value.occurenceType"
                  [placeholder]="'common.select' | translate"
                  [options]="occurrenceTypeDropdownValues"
                  [control]="'occurenceType'"
                  [dropdownContainerStyle]="{ width: '150px' }"
                  class="input-fields"
                  (currentValueChange)="
                    handleValueChange(
                      $event,
                      'occurenceType',
                      occurrenceTypeDropdownValues
                    )
                  "
                >
                </boxxds-dropdown-v2>
              </td>
              <td>
                <boxxds-dropdown-v2
                  [label]="'Retention' | translate"
                  [leftSymbol]="'$'"
                  [hasOuterBorder]="false"
                  *ngIf="!showDeductibleCustomField"
                  [isDisabled]="item['allowOverwriteDefault']"
                  [currentValue]="
                    form.value.retentionAmount
                      | currencyFormat: currency : 'symbol-narrow' : '1.0-0'
                  "
                  [placeholder]="'common.select' | translate"
                  [options]="coverageDeductibles"
                  (currentValueChange)="
                    handleDeductibleChange(
                      $event,
                      currentCoverageName
                        ?.toLowerCase()
                        ?.includes(financialCoverageMatchString)
                    )
                  "
                  [control]="'retentionAmount'"
                  [dropdownContainerStyle]="{ width: '150px' }"
                  class="input-fields retentionAmount-field"
                >
                </boxxds-dropdown-v2>
                <boxxds-textinput-v2
                  [hasOuterBorder]="false"
                  *ngIf="showDeductibleCustomField"
                  [label]="'Retention' | translate"
                  [placeholder]="
                    'quoteCalculator.modal.deductiblesAmount' | translate
                  "
                  [control]="'retentionAmount'"
                  [id]="'retentionAmount'"
                  [width]="'100%'"
                  minLength="3"
                  maxLength="14"
                  mask="separator.0"
                  thousandSeparator=","
                  prefix="$"
                  [form]="form"
                  [isDisabled]="item['allowOverwriteDefault']"
                  (handleBlur)="
                    handleCustomDeductible(
                      $event,
                      currentCoverageName
                        ?.toLowerCase()
                        ?.includes(financialCoverageMatchString)
                    )
                  "
                >
                </boxxds-textinput-v2>
              </td>
              <td>
                <boxxds-dropdown-v2
                  [label]="'Occurrence type' | translate"
                  [hasOuterBorder]="false"
                  [placeholder]="'common.select' | translate"
                  [currentValue]="form.value.occurenceTypeDeductible"
                  [control]="'occurenceTypeDeductible'"
                  [dropdownContainerStyle]="{ maxWidth: '150px' }"
                  class="input-fields"
                  [options]="occurrenceTypeDropdownValues"
                  (currentValueChange)="
                    handleValueChange(
                      $event,
                      'occurenceTypeDeductible',
                      occurrenceTypeDropdownValues
                    )
                  "
                >
                </boxxds-dropdown-v2>
              </td>
              <td class="fixed-last-col">
                <div class="d-flex flex-column">
                  <!-- <button
                    class="icon-button mb-2"
                    (click)="handleEditSave(indexOfelement)"
                  >
                    <img src="assets/img/scroll-table/tick.png" />
                  </button>
                  <button
                    class="icon-button mt-2"
                    (click)="handleEditCancel(indexOfelement)"
                  >
                    <img src="assets/img/scroll-table/cancel.png" />
                  </button> -->
                </div>
              </td>
              <td class="fixed-last-col"></td>
            </tr>
            <tr
              class="edited-row"
              [ngClass]="{
                'last-edit-column':
                  indexOfelement === data.length - 1 ||
                  indexOfelement === data.length - 2
              }"
            >
              <td class="fixed-side"></td>
              <!-- <td class="edit-label">
                {{ "quoteCalculator.modal.indemnityPeriod" | translate }}
              </td> -->
              <td>
                <boxxds-textinput-v2
                  [hasOuterBorder]="false"
                  placeholder="00"
                  [label]="'quoteCalculator.modal.waitingPeriod' | translate"
                  [control]="'waitingPeriod'"
                  [id]="'waitingPeriod'"
                  [width]="'100%'"
                  minLength="0"
                  maxLength="4"
                  [containerStyle]="{ maxWidth: '150px' }"
                  [formSubmitted]="!!form.value.waitingPeriod"
                  [invalidErrMsg]="'error.thisFieldIsInvalid' | translate"
                  mask="separator.0"
                  [form]="form"
                  class="input-fields"
                >
                </boxxds-textinput-v2>
              </td>
              <td>
                <boxxds-dropdown-v2
                  [label]="
                    'quoteCalculator.modal.waitingPeriodMeasure' | translate
                  "
                  [hasOuterBorder]="false"
                  [currentValue]="form.value.waitingPeriodMeasure"
                  [placeholder]="'common.select' | translate"
                  [control]="'waitingPeriodMeasure'"
                  [dropdownContainerStyle]="{ maxWidth: '150px' }"
                  class="input-fields"
                  [options]="measuresDropdownValues"
                  (currentValueChange)="handleWPMeasureChange($event)"
                >
                </boxxds-dropdown-v2>
              </td>
              <td>
                <boxxds-textinput-v2
                  [hasOuterBorder]="false"
                  placeholder="00"
                  [label]="'Indemnity period' | translate"
                  [control]="'indemnityPeriod'"
                  [id]="'indemnityPeriod'"
                  [width]="'100%'"
                  minLength="3"
                  maxLength="100"
                  [form]="form"
                  class="input-fields"
                  minLength="0"
                  maxLength="4"
                  [containerStyle]="{ maxWidth: '150px' }"
                  [formSubmitted]="!!form.value.indemnityPeriod"
                  [invalidErrMsg]="'error.thisFieldIsInvalid' | translate"
                  mask="separator.0"
                >
                </boxxds-textinput-v2>
              </td>
              <!-- <td class="edit-label">
                {{ "quoteCalculator.modal.waitingPeriod" | translate }}
              </td> -->

              <!-- </tr>
            <tr class="edited-row"> -->
              <!-- <td class="fixed-side"></td> -->
              <!-- <td class="edit-label">
                {{ "quoteCalculator.modal.indemnityPeriodMeasure" | translate }}
              </td> -->
              <td>
                <boxxds-dropdown-v2
                  [label]="
                    'quoteCalculator.modal.indemnityPeriodMeasure' | translate
                  "
                  [hasOuterBorder]="false"
                  [currentValue]="form.value.indemnityPeriodMeasure"
                  [placeholder]="'common.select' | translate"
                  [control]="'indemnityPeriodMeasure'"
                  [dropdownContainerStyle]="{ maxWidth: '150px' }"
                  class="input-fields"
                  [options]="measuresDropdownValues"
                  (currentValueChange)="handleIPMeasureChange($event)"
                >
                </boxxds-dropdown-v2>
              </td>
              <!-- <td class="edit-label">
                {{ "quoteCalculator.modal.waitingPeriodMeasure" | translate }}
              </td> -->
              <td class="fixed-last-col"></td>
            </tr>

            <tr class="edited-row btns-container">
              <td class="fixed-side"></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <boxxds-button-v2
                  (handleClick)="handleEditCancel(indexOfelement)"
                  [leftImage]="'slash-circle-01'"
                  type="button"
                  [btnType]="'secondary'"
                  [leftImageStyle]="{
                    filter:
                      'invert(31%) sepia(14%) saturate(868%) hue-rotate(176deg) brightness(91%) contrast(85%)'
                  }"
                ></boxxds-button-v2>
                <boxxds-button-v2
                  (handleClick)="handleEditSave(indexOfelement)"
                  [leftImage]="'save-01'"
                  type="button"
                  [btnStyle]="{ marginLeft: '23px' }"
                  [btnType]="'secondary'"
                  [leftImageStyle]="{
                    filter:
                      'invert(31%) sepia(14%) saturate(868%) hue-rotate(176deg) brightness(91%) contrast(85%)'
                  }"
                ></boxxds-button-v2>

                <!-- <button
                class="icon-button mb-2"
                (click)="handleEditSave(indexOfelement)"
              >
                <img src="assets/img/scroll-table/tick.png" />
              </button> -->
                <!-- <button
                class="icon-button mt-2"
                (click)="handleEditCancel(indexOfelement)"
              >
                <img src="assets/img/scroll-table/cancel.png" />
              </button> -->
              </td>
              <td class="fixed-last-col"></td>
              <!-- <td class="edit-label">
                {{ "quoteCalculator.modal.indemnityPeriodMeasure" | translate }}
              </td> -->
              <!-- <td>
                <boxxds-dropdown-v2 [label]="'quoteCalculator.modal.indemnityPeriodMeasure' | translate"
                  [hasOuterBorder]="false"
                  [currentValue]="form.value.indemnityPeriodMeasure"
                  [placeholder]="'common.select' | translate"
                  [control]="'indemnityPeriodMeasure'"
                  [dropdownContainerStyle]="{ maxWidth: '150px' }"
                  class="input-fields"
                  [options]="measuresDropdownValues"
                  (currentValueChange)="handleIPMeasureChange($event)"
                >
                </boxxds-dropdown-v2>
              </td> -->
              <!-- <td class="edit-label">
                {{ "quoteCalculator.modal.waitingPeriodMeasure" | translate }}
              </td> -->
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
