import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'abbreviateNumber',
})
export class AbbreviateNumberPipe implements PipeTransform {
  transform(value: any, ...args: any): string {
    let exp,
      suffixes = ['K', 'M', 'G', 'T', 'P', 'E'];

    if (Number.isNaN(value)) {
      return null;
    }

    if (value < 1000) {
      return value;
    }

    exp = Math.trunc(Math.log(value) / Math.log(1000));

    return (
      parseFloat((value / Math.pow(1000, exp)).toFixed(args)) +
      suffixes[exp - 1]
    );
  }

  transformBack(value: string, magnitude: string = ''): number {
    const suffixes = ['K', 'M', 'G', 'T', 'P', 'E'];

    if (!value || typeof value !== 'string') {
      return null;
    }
    if (!magnitude) magnitude = 'M';
    const magnitudeValue = suffixes.indexOf(magnitude) + 1;
    if (magnitudeValue === 0) {
      // No valid suffix found, return the original value as a number
      return parseFloat(value);
    }

    const numericalPart = parseFloat(value);

    return numericalPart * Math.pow(1000, magnitudeValue);
  }
}
