import { Component, Input, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { QuoteOptionReasonsService } from 'src/app/services/quote-option-reasons.service ';

@Component({
  selector: 'app-referral-badge',
  templateUrl: './referral-badge.component.html',
  styleUrls: ['./referral-badge.component.less'],
})
export class ReferralBadgeComponent {
  constructor() {}
}
