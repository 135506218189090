<div class="branch-teams-tab">
  <section class="teams-tab-head">
    <div class="left-side">
      <p>{{ (pagination?.totalRecords ?? 0) + " Records" }}</p>
    </div>
    <div class="right-side">
      <boxxds-textinput-v2
        [form]="filterForm"
        [placeholder]="
          ('workFlow3.components.shared.placeholder.search' | translate) +
          ' name'
        "
        [control]="'teamName'"
        [id]="'search'"
        [leftIcon]="'assets/img/workflow3/sidemenu/search.png'"
        [width]="'200px'"
        [maxLength]="'40'"
        (handleKeyUp)="handleSearch($event)"
        [inputStyle]="{ marginTop: '0' }"
        [leftIconStyle]="{ transform: 'translateY(-50%)' }"
      >
      </boxxds-textinput-v2>
      <div (clickOutside)="hideAddTeamPopup()">
        <button class="add-team-btn" (click)="handleAddTeamClick($event)">
          <div class="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clip-path="url(#clip0_51146_3764)">
                <path
                  d="M9.99935 6.66602V13.3327M6.66602 9.99935H13.3327M18.3327 9.99935C18.3327 14.6017 14.6017 18.3327 9.99935 18.3327C5.39698 18.3327 1.66602 14.6017 1.66602 9.99935C1.66602 5.39698 5.39698 1.66602 9.99935 1.66602C14.6017 1.66602 18.3327 5.39698 18.3327 9.99935Z"
                  stroke="#FFAA00"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_51146_3764">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <p>{{ "workFlow3.branchProfile.label.addTeam" | translate }}</p>
        </button>
        <div class="add-branchTeam-popup" *ngIf="showAddBranchTeamPopup">
          <boxxds-textinput-v2
            [form]="form"
            [readonly]="false"
            [isDisabled]="false"
            [placeholder]="'Alferez Team' | translate"
            [control]="'branchTeamName'"
            [id]="'branchTeamName'"
            [width]="'410px'"
            [label]="'workFlow3.branchProfile.label.teamsName' | translate"
            [isRequired]="false"
            [requiredErrMsg]="
              'brokerage.producer.error.requiredErrMsg' | translate
            "
            [invalidErrMsg]="
              'brokerage.producer.error.invalidErrMsg' | translate
            "
            [formSubmitted]="branchTeamSubmitted"
            [containerStyle]="{ marginBottom: '20px' }"
            [inputAutoFocus]="true"
            [selectedFromSuggestion]="false"
            [maxLength]="'50'"
          >
          </boxxds-textinput-v2>
          <div class="add-branchTeam-popup-btn">
            <boxxds-button-v2
              *ngIf="!addBranchTeamBtnClicked"
              [isDisabled]="false"
              [size]="'sm'"
              [btnClass]="'secondary gray'"
              [btnType]="'default'"
              [isDropdown]="false"
              [buttonText]="'common.add' | translate"
              [btnStyle]="{ padding: '10px 14px', width: '100px' }"
              (handleClick)="handleAddBranchTeam()"
            >
            </boxxds-button-v2>
            <button
              *ngIf="addBranchTeamBtnClicked"
              class="btn-processing"
              tabindex="0"
              [ngStyle]="{
                backgroundColor: '#FFFFFF',
                border: '1px solid #D0D5DD',
                boxShadow: '0px 1px 2px 0px #1018280D',
                color: '#2D2D39',
                borderRadius: '8px',
                width: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                lineHeight: '20px',
                padding: '8px 12px'
              }"
            >
              <boxxds-spinner></boxxds-spinner>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="teams-tab-table-wrapper">
    <app-branch-teams-table
      [tableData]="tableData"
      [tableColumns]="tableHeaders"
      [tableColumnsExcluded]="tableColumnsExcluded"
      [tableColumnsExcludedSort]="tableColumnsExcludedSort"
      [noDataMessage]="noDataMessage"
      [isLoading]="isLoading"
      [pagination]="pagination"
      [branchId]="branchId"
      (getTableDataOnSort)="getTableDataOnSort($event)"
      (handleUpdate)="handleUpdate($event)"
      (pageChanged)="pageChanged($event)"
    ></app-branch-teams-table>
  </section>
</div>
