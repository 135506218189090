<div class="content">
  <div class="content-row">
    <div class="greeting">
      <ngx-avatars
        *ngIf="!loggedInUser?.photoUrl"
        class="profile-photo"
        [textSizeRatio]="1.5"
        [name]="loggedInUser?.name"
      ></ngx-avatars>
      <img
        *ngIf="loggedInUser?.photoUrl"
        class="profile-photo"
        artkw
        [src]="loggedInUser?.photoUrl || 'assets/img/default-photo.png'"
      />
      <div *ngIf="loggedInUser?.name" class="text">
        <p class="greeting-text">
          <span>{{ "Hello, " | translate }}</span
          >{{ loggedInUser.name }}
        </p>
        <p class="designation">{{ loggedInUser.title }}</p>
      </div>
    </div>
    <ng-container
      *ngIf="
        this.permissionList[this.currentScreen] && this.permissionList['Quote']
      "
    >
      <boxxds-button
        *ngIf="startQuoteBtnTxt"
        [buttonText]="startQuoteBtnTxt"
        sz="lg"
        class="new-quote-btn"
        (click)="handleNewQuoteClick()"
      >
      </boxxds-button>
    </ng-container>
  </div>
  <div class="info-tiles">
    <app-info-tile
      (click)="
        this.permissionList[this.currentScreen] && handleNavigation(i.heading)
      "
      [class.noPointerEvents]="!this.permissionList[this.currentScreen]"
      *ngFor="let i of infoTile"
      [heading]="i.heading"
      [count]="i.count"
    ></app-info-tile>
  </div>
  <div class="quotes">
    <p class="heading">{{ "RECENT ACTIVITY" | translate }}</p>
    <boxxds-dropdown
      [form]="form"
      [control]="'underwriter'"
      [currentValue]="form.get('underwriter').value"
      [placeholder]="'Select Underwriter' | translate"
      [options]="underWriterOptions"
      (currentValueChange)="dropdownValueChanged($event)"
      [dropdownContainerStyle]="{ width: '186px' }"
    >
    </boxxds-dropdown>
  </div>
  <div class="divider"></div>
  <div class="no-records-holder" *ngIf="!showSpinner && cardData.length === 0">
    <div class="no-records-ellipse">
      <img src="assets/img/no-records.png" alt="" />
      <boxxds-body
        text="No records"
        sz="md"
        [customStyle]="{ color: '#616162' }"
      >
      </boxxds-body>
    </div>
  </div>
  <ng-container
    *ngIf="
      permissionKeyList.includes('Quote') ||
      permissionKeyList.includes('Policy')
    "
  >
    <div class="card-container">
      <app-card [data]="card" *ngFor="let card of cardData"></app-card>
    </div>
  </ng-container>

  <div class="card-container" *ngIf="showSpinner">
    <app-card-skeleton
      *ngFor="let card of [].constructor(12)"
    ></app-card-skeleton>
  </div>
</div>
