<section class="quoting-wrapper submissionflow3-page">
  <app-quote-action-popup-v2
    [showModal]="showActionPopup"
    [details]="actionPopupDetails"
    (handleActionConfirm)="handleConfirmRiskAction($event)"
    (handleClose)="showActionPopup = false"
  ></app-quote-action-popup-v2>
  <app-sublimit-slideout
    *ngIf="showSublimitModal"
    [showModal]="showSublimitModal"
    (handleClose)="closeSublimitModal()"
    [title]="
      ('quoteCalculator.modal.sublimitsModalHeading' | translate) + ' ' + title
    "
    (handleSubmitCoverageDetails)="handleSubmitCoverageDetails($event)"
    [sublimitOptionsForFinancialCoverage]="sublimitOptionsForFinancialCoverage"
    [coverageDetails]="coverageDetails"
    [coverageDeductibles]="coverageDeductibles"
    [productDetails]="productDetails"
    [insuredDetals]="insuredDetails"
    [formDetails]="coverageForm"
    [limitOptionsForProducts]="limitOptionsForProducts"
    [occurrenceTypeDropdownValues]="occurrenceTypeDropdownValues"
    [measuresDropdownValues]="measuresDropdownValues"
    [deductibleOptions]="deductibleOptions"
    [quoteOptionId]="quoteOptionIdForSublimitSlideout"
    (handleBackBtn)="handleBackBtn()"
    (handleLimitCustomValue)="
      this.permissionList[this.currentScreen] &&
        this.currentQuoteStatus !== 'quote-closed' &&
        handleLimitCustomValue($event, title)
    "
    (handleRetentionCustomValue)="
      this.permissionList[this.currentScreen] &&
        this.currentQuoteStatus !== 'quote-closed' &&
        handleDeductableCustomValue($event, title)
    "
    (handleQuoteOptionDeductibleChange)="
      this.permissionList[this.currentScreen] &&
        this.currentQuoteStatus !== 'quote-closed' &&
        handleDeductableChange($event, title, 1, true)
    "
    (handleQuoteOptionLimitChange)="
      this.permissionList[this.currentScreen] &&
        this.currentQuoteStatus !== 'quote-closed' &&
        handleLimitChange($event, title, 1, true)
    "
    (handlePremiumRecalc)="
      this.permissionList[this.currentScreen] &&
        this.currentQuoteStatus !== 'quote-closed' &&
        handlePremiumCalculateFromSublimit($event)
    "
    (handleQuestionnaireNav)="handleBackBtn()"
    [currency]="currency"
  >
  </app-sublimit-slideout>

  <app-broker-commission-v2-modal
    *ngIf="showCommissionModal"
    [productsInfo]="productInfo"
    [regionInfo]="regionInfo"
    [showCommissionModal]="showCommissionModal"
    (handleClose)="handleClose()"
    (handleSubmit)="submitBrokerCommission($event)"
    (handleNotifMsg)="handleNotifMsg($event)"
    [brokerageId]="selectedBroker?.id"
    [brokerage]="selectedBroker"
    [commissionDisabled]="commissionDisabled"
    [commissionId]="commissionId"
  >
  </app-broker-commission-v2-modal>

  <boxxds-notification-v2
    *ngIf="noCommission"
    [alertType]="alertInfo.alertType"
    [alertWidth]="'md'"
    [alertHeader]="alertInfo.alertHeader"
    [alertDescription]="alertInfo.alertText"
    [contentContainerStyle]="{ maxWidth: '68%' }"
    [linkContainerStyle]="{ maxWidth: '30%' }"
    [linkText]="alertInfo.alertLinkText"
    (handleLinkClick)="handleLinkAction()"
    class="notification-section"
    [alertContainerStyle]="{ position: 'fixed' }"
  >
  </boxxds-notification-v2>

  <div class="quote-options-holder" *ngIf="!showBodySpinner">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <boxxds-heading
          [sz]="'7'"
          [text]="'workFlow3.quoting.heading.quoteOptions' | translate"
          type="bold"
          [customStyle]="{
            color: '#292933',
            lineHeight: '30px',
            fontSize: '20px'
          }"
        ></boxxds-heading>

        <boxxds-heading
          [sz]="'7'"
          [text]="'workFlow3.quoting.subHeading.quoteOptionActions' | translate"
          [customStyle]="{
            color: '#475467',
            lineHeight: '20px',
            fontSize: '14px',
            marginTop: '4px'
          }"
        ></boxxds-heading>
      </div>
      <div
        class="indicated-quoted-switch-container"
        *ngIf="showSwitchIndicate()"
      >
        <div class="indicated-quoted-switch">
          <button
            class="btn-indicated"
            [ngClass]="{ inactive: isQuotedStatus() }"
            (click)="handleQuoteStatus(true)"
            [disabled]="isIndicatedStatus() ? true : null"
          >
            {{ "common.status.indicated" | translate }}
          </button>
          <button
            class="btn-quoted"
            [ngClass]="{ inactive: isIndicatedStatus() }"
            (click)="handleQuoteStatus(false)"
            [disabled]="isQuotedStatus() ? true : null"
          >
            {{ "common.status.quoted" | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="quote-options-table-holder">
      <div id="tbl-quote-options" role="table">
        <div class="flex-row header-row">
          <div
            *ngFor="let key of tableHeaders; let indexOfHeaders = index"
            scope="col"
            class="header cell"
          >
            <div class="tbl-header" *ngIf="key?.split(' ')?.length === 1">
              {{ key }}
            </div>
            <div
              class="tbl-header split-words"
              *ngIf="key?.split(' ')?.length > 1"
            >
              <div *ngFor="let word of key.split(' '); let i = index" [ngClass]>
                {{ word }}
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="(form$ | async)?.quoteOptions as options">
          <div
            class="mb-4"
            *ngFor="
              let quote of (form$ | async)?.quoteOptions;
              let quoteIdx = index
            "
          >
            <div
              class="flex-row body-row"
              [ngStyle]="
                isEditMode &&
                editIndex === quoteIdx && { border: '1px solid #FBB00E' }
              "
            >
              <div
                class="quote-option-number cell flex-column"
                [id]="'quote' + quoteIdx"
              >
                <div class="option-label">
                  {{ "workFlow3.quoting.label.option" | translate }}
                </div>
                <div class="option-number">
                  {{ quoteIdx + 1 }}
                </div>
              </div>
              <div class="product cell">
                <div
                  *ngFor="let product of quote.products; let productIdx = index"
                >
                  {{ product.value }}
                  <div class="quote-option-id">
                    {{ "ID " + product?.quoteOptionId }}
                  </div>
                  <div
                    class="d-flex justify-content-center"
                    *ngIf="product.quoteStatus === domainIdForReferral"
                  >
                    <app-referral-badge></app-referral-badge>
                  </div>
                </div>
              </div>
              <div class="limit cell">
                <div
                  [ngClass]="{
                    'edit-mode-cell': editIndex === quoteIdx,
                    'edit-mode-err': getLimitErrMsg(product.key) !== '',
                    'edit-indicate': product?.initialLimit !== product?.limit
                  }"
                  *ngFor="let product of quote.products; let productIdx = index"
                >
                  <ng-container *ngIf="!(isEditMode && editIndex === quoteIdx)">
                    <span
                      class="edited-indicator"
                      *ngIf="product?.initialLimit !== product?.limit"
                    ></span
                    >{{
                      "$" + (product?.limit | number: "1.0-0")
                    }}</ng-container
                  >
                  <div
                    class="limit-edit"
                    *ngIf="isEditMode && editIndex === quoteIdx"
                    autofocus="true"
                  >
                    <boxxds-dropdown-v2
                      [hasOuterBorder]="false"
                      *ngIf="
                        !showLimitCustomField(product.value) &&
                        !isLimitDisabledOnAddSubProduct(product.riskId) &&
                        brokerageCommissionMin[product.riskId]
                      "
                      [leftSymbol]="'$'"
                      [placeholder]="'5,0000,000' | translate"
                      [options]="limitOptionsForProducts[product.key]"
                      [dropdownContainerStyle]="{ minWidth: '114px' }"
                      (currentValueChange)="
                        this.permissionList[this.currentScreen] &&
                          this.currentQuoteStatus !== 'quote-closed' &&
                          handleLimitChange(
                            $event,
                            product.value,
                            options?.length
                          )
                      "
                      [invalidErrMsg]="getLimitErrMsg(product.key)"
                      [currentValue]="
                        getLimitValue(product.riskId)
                          ? (getLimitValue(product.riskId)
                            | currencyFormat
                              : currency
                              : 'symbol-narrow'
                              : '1.0-0')
                          : ''
                      "
                      [isReadonly]="
                        !this.permissionList[this.currentScreen] ||
                        this.currentQuoteStatus === 'quote-closed'
                      "
                      [class.noPointerEvents]="
                        !this.permissionList[this.currentScreen] ||
                        this.currentQuoteStatus === 'quote-closed'
                      "
                    >
                    </boxxds-dropdown-v2>
                    <input
                      class="border-less-input"
                      *ngIf="
                        product.standaloneSellable &&
                        isLimitDisabledOnAddSubProduct(product.riskId)
                      "
                      [value]="
                        getLimitValue(product.riskId)
                          ? (getLimitValue(product.riskId)
                            | currencyFormat: currency : 'symbol-narrow')
                          : ''
                      "
                      [attr.disabled]="true"
                      class="disabled-field"
                    />

                    <div
                      class="custom-value"
                      *ngIf="showLimitCustomField(product.value)"
                    >
                      <input
                        class="border-less-input"
                        type="text"
                        mask="separator.0"
                        thousandSeparator=","
                        prefix="$"
                        (blur)="
                          handleLimitCustomValue(
                            $event,
                            product.value,
                            options?.length
                          )
                        "
                        maxlength="14"
                        [autofocus]="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="retention cell">
                <div
                  [ngClass]="{
                    'edit-mode-cell': editIndex === quoteIdx,
                    'edit-mode-err': getDeductibleErrMsg(product.key) !== '',
                    'edit-indicate':
                      product?.initialDeductible !== product?.deductables
                  }"
                  *ngFor="let product of quote.products; let productIdx = index"
                >
                  <ng-container *ngIf="!(isEditMode && editIndex === quoteIdx)">
                    <span
                      class="edited-indicator"
                      *ngIf="
                        product?.initialDeductible !== product?.deductables
                      "
                    ></span>
                    {{
                      "$" + (product?.deductables | number: "1.0-0")
                    }}</ng-container
                  >
                  <div
                    class="retention-edit"
                    *ngIf="isEditMode && editIndex === quoteIdx"
                  >
                    <boxxds-dropdown-v2
                      [hasOuterBorder]="false"
                      [leftSymbol]="'$'"
                      *ngIf="
                        !showDeductibleCustomField(product.riskId) &&
                        product.standaloneSellable &&
                        brokerageCommissionMin[product.riskId]
                      "
                      [placeholder]="'5,000' | translate"
                      [options]="deductibleOptions"
                      [dropdownContainerStyle]="{ minWidth: '114px' }"
                      (currentValueChange)="
                        this.permissionList[this.currentScreen] &&
                          this.currentQuoteStatus !== 'quote-closed' &&
                          handleDeductableChange(
                            $event,
                            product.value,
                            options?.length
                          )
                      "
                      [invalidErrMsg]="getDeductibleErrMsg(product.key)"
                      [currentValue]="
                        getDeductibleValue(product.riskId)
                          ? (getDeductibleValue(product.riskId)
                            | currencyFormat
                              : currency
                              : 'symbol-narrow'
                              : '1.0-0')
                          : ''
                      "
                      [isReadonly]="
                        !this.permissionList[this.currentScreen] ||
                        this.currentQuoteStatus === 'quote-closed'
                      "
                    >
                      <!--  to do - [disabled]="!product.standaloneSellable" -->
                    </boxxds-dropdown-v2>
                    <input
                      class="border-less-input"
                      *ngIf="!product.standaloneSellable"
                      [value]="
                        getDeductibleValue(product?.riskId)
                          ? (getDeductibleValue(product?.riskId)
                            | currencyFormat: currency : 'symbol-narrow')
                          : ''
                      "
                      [attr.disabled]="true"
                      class="disabled-field"
                    />
                    <div
                      class="custom-value"
                      *ngIf="
                        showDeductibleCustomField(product.riskId) &&
                        product.standaloneSellable
                      "
                    >
                      <input
                        class="border-less-input"
                        type="text"
                        mask="separator.0"
                        thousandSeparator=","
                        prefix="$"
                        maxlength="14"
                        (blur)="
                          handleDeductableCustomValue(
                            $event,
                            product.value,
                            options?.length
                          )
                        "
                        [autofocus]="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="commission cell">
                <div
                  [ngClass]="{
                    'edit-mode-cell': true,
                    'edit-mode-err': getCommissionErrMsg(product.key) !== '',
                    'edit-indicate':
                      product?.initialBrokerCommissionPerc !==
                      product?.brokerCommissionPerc
                  }"
                  *ngFor="let product of quote.products; let productIdx = index"
                >
                  <ng-container *ngIf="!(isEditMode && editIndex === quoteIdx)">
                    <span
                      class="edited-indicator"
                      *ngIf="
                        product?.initialBrokerCommissionPerc !==
                        product?.brokerCommissionPerc
                      "
                    ></span
                    >{{ product?.brokerCommissionPerc + "%" }}</ng-container
                  >
                  <ng-container *ngIf="isEditMode && editIndex === quoteIdx">
                    <boxxds-dropdown-v2
                      *ngIf="
                        !showCommissionCustomField(product.riskId) &&
                        product.standaloneSellable &&
                        brokerageCommissionMin[product.riskId]
                      "
                      [hasOuterBorder]="false"
                      [placeholder]="'0,000' | translate"
                      [showSubKeyInNextLine]="true"
                      [options]="commissionOptions[product.riskId]"
                      [dropdownContainerStyle]="{ minWidth: '90px' }"
                      (currentValueChange)="
                        this.permissionList[this.currentScreen] &&
                          this.currentQuoteStatus !== 'quote-closed' &&
                          handleBrokerCommissionPerc(
                            $event,
                            product,
                            options?.length
                          )
                      "
                      [currentValue]="
                        getBrokerageCommissionPerc(product.riskId) + '%'
                      "
                      [isReadonly]="
                        !this.permissionList[this.currentScreen] ||
                        this.currentQuoteStatus === 'quote-closed'
                      "
                      [invalidErrMsg]="getCommissionErrMsg(product.key)"
                      [class.noPointerEvents]="
                        !this.permissionList[this.currentScreen] ||
                        this.currentQuoteStatus === 'quote-closed'
                      "
                    >
                    </boxxds-dropdown-v2>

                    <div
                      class="custom-value"
                      *ngIf="showCommissionCustomField(product.riskId)"
                    >
                      <input
                        class="border-less-input"
                        type="text"
                        mask="percent.2"
                        [suffix]="'%'"
                        (blur)="
                          handleCommissionCustomChange(
                            $event,
                            product,
                            options?.length
                          )
                        "
                        maxlength="14"
                        [autofocus]="true"
                      />
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="premium cell">
                <div
                  [ngClass]="{
                    'edit-indicate':
                      product?.initialPremiumCalculated !==
                      product?.premiumCalculated
                  }"
                  *ngFor="let product of quote.products; let productIdx = index"
                >
                  <span
                    class="edited-indicator"
                    *ngIf="
                      product?.initialPremiumCalculated !==
                      product?.premiumCalculated
                    "
                  ></span>
                  {{ "$" + (product?.premiumCalculated | number: "1.0-0") }}
                </div>
              </div>
              <div class="edited-premium cell">
                <div
                  [ngClass]="{
                    'edit-indicate':
                      product?.initialPremium !== product?.premium
                  }"
                  *ngFor="let product of quote.products; let productIdx = index"
                >
                  <ng-container *ngIf="!(isEditMode && editIndex === quoteIdx)">
                    <span
                      class="edited-indicator"
                      *ngIf="product?.initialPremium !== product?.premium"
                    ></span>
                    {{
                      "$" + (product?.premium | number: "1.0-0")
                    }}</ng-container
                  >
                  <ng-container *ngIf="isEditMode && editIndex === quoteIdx">
                    <boxxds-dropdown-v2
                      [hasOuterBorder]="false"
                      [leftSymbol]="'$'"
                      *ngIf="!showEditedPremCustomField(product.value)"
                      [placeholder]="'0,000' | translate"
                      [options]="editedPremiumOptions"
                      [dropdownContainerStyle]="{ minWidth: '114px' }"
                      (currentValueChange)="
                        this.permissionList[this.currentScreen] &&
                          this.currentQuoteStatus !== 'quote-closed' &&
                          handleEditedPremium($event, product.value)
                      "
                      [currentValue]="
                        isNumber(getEditedPremium(product.value))
                          ? (getEditedPremium(product.value)
                            | currency: currency : 'symbol-narrow' : '1.0-0')
                          : getEditedPremium(product.value)
                      "
                      [isReadonly]="
                        !this.permissionList[this.currentScreen] ||
                        this.currentQuoteStatus === 'quote-closed'
                      "
                      [class.noPointerEvents]="
                        !this.permissionList[this.currentScreen] ||
                        this.currentQuoteStatus === 'quote-closed'
                      "
                      [hideSelectedValue]="true"
                    >
                    </boxxds-dropdown-v2>
                    <div
                      class="custom-value"
                      *ngIf="showEditedPremCustomField(product.value)"
                    >
                      <input
                        class="border-less-input"
                        type="text"
                        [ngStyle]="
                          getEditedPremiumErr(product.value) !== '' && {
                            border: '1px solid #CA3434'
                          }
                        "
                        mask="separator.0"
                        thousandSeparator=","
                        prefix="$"
                        maxlength="11"
                        (blur)="
                          handleEditedPremiumCustVal($event, product.value)
                        "
                        [autofocus]="true"
                        [min]="getEditedPremiumMinVal(product.value)"
                        [max]="getEditedPremiumMaxVal(product.value)"
                      />
                      <div
                        *ngIf="getEditedPremiumErr(product.value) !== ''"
                        class="error-msg"
                      >
                        {{ getEditedPremiumErr(product.value) }}
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div
                class="sublimit cell"
                [ngClass]="{
                  'edit-mode-sublimit-link':
                    isEditMode && editIndex === quoteIdx
                }"
                *ngFor="let product of quote.products; let productIdx = index"
              >
                <button
                  (click)="
                    handleSublimitAction(
                      product,
                      product.key,
                      policyPeriodId,
                      product,
                      quoteIdx,
                      quote
                    )
                  "
                >
                  <img
                    class="sublimit-link"
                    src="assets/img/workflow3/arrow-circle-right.svg"
                    alt="sublimit"
                  />
                </button>
              </div>
              <div class="subtotal cell">
                <div class="subtotal-amount">
                  {{
                    isNumber(quote?.total?.premium)
                      ? (quote?.total?.premium
                        | currency: currency : "symbol-narrow")
                      : quote?.total?.premium
                  }}
                </div>
                <div class="subtotal-info">
                  {{ "workFlow3.quoting.content.taxExcluded" | translate }}
                </div>
              </div>
              <div
                class="actions cell"
                [ngClass]="{
                  'justify-content-center': isEditMode && editIndex === quoteIdx
                }"
              >
                <ng-container
                  *ngIf="
                    this.permissionList[this.currentScreen] &&
                    this.currentQuoteStatus !== 'quote-closed' &&
                    !(isEditMode && editIndex === quoteIdx)
                  "
                >
                  <boxxds-button-v2
                    (handleClick)="
                      handleEdit({ quoteData: quote, quoteIndex: quoteIdx })
                    "
                    [leftImage]="'edit-02'"
                    type="button"
                    [btnType]="'secondary'"
                    [leftImageStyle]="{
                      filter:
                        'invert(31%) sepia(14%) saturate(868%) hue-rotate(176deg) brightness(91%) contrast(85%)'
                    }"
                  ></boxxds-button-v2>
                  <boxxds-button-v2
                    [isDropdown]="true"
                    [options]="quoteOptionActionsList"
                    (handleValueSelection)="
                      handleQuoteOptionActionsClick(
                        $event,
                        quote,
                        quoteIdx,
                        options
                      )
                    "
                    [leftImage]="'dots-vertical'"
                    type="button"
                    [btnType]="'secondary'"
                    [leftImageStyle]="{
                      filter:
                        'invert(31%) sepia(14%) saturate(868%) hue-rotate(176deg) brightness(91%) contrast(85%)'
                    }"
                  ></boxxds-button-v2>
                </ng-container>
                <ng-container *ngIf="isEditMode && editIndex === quoteIdx">
                  <boxxds-button-v2
                    (handleClick)="handleClear()"
                    [leftImage]="addOption ? 'plus-circle' : 'save-01'"
                    type="button"
                    [btnType]="'secondary'"
                    [leftImageStyle]="{
                      filter:
                        'invert(31%) sepia(14%) saturate(868%) hue-rotate(176deg) brightness(91%) contrast(85%)'
                    }"
                  ></boxxds-button-v2>
                </ng-container>
              </div>
            </div>
            <div
              class="d-flex"
              *ngIf="quote.products[0].quoteStatus === domainIdForReferral"
            >
              <!-- supports only single product -->
              <div class="referral-label">
                <img class="left-img" [src]="'assets/img/warn.svg'" />
                <p class="text-nowrap">
                  {{ ("quoteDetails.referralReason" | translate) + ":" }}
                </p>
              </div>
              <p class="referral-reasons">
                {{ formatReferralReasons(quote.products[0].referralReasons) }}
              </p>
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="
            getSelectedPdtOptions((form$ | async)?.products) as selectedProducts
          "
        >
          <ng-container *ngIf="(form$ | async)?.quoteOptions as options">
            <div
              class="flex-row body-row"
              *ngIf="addOption && !isEditMode"
              [ngStyle]="{ border: '1px solid #FBB00E' }"
            >
              <div class="quote-option-number cell flex-column">
                <div class="option-label">
                  {{ "workFlow3.quoting.label.option" | translate }}
                </div>
                <div class="option-number">
                  {{ options?.length + 1 }}
                </div>
              </div>
              <div class="product cell">
                <div
                  *ngFor="
                    let product of selectedProducts;
                    let productIdx = index
                  "
                >
                  {{ product.value }}
                </div>
              </div>
              <div class="limit cell">
                <div
                  [ngClass]="{
                    'edit-mode-cell': true,
                    'edit-mode-err': getLimitErrMsg(product.key) !== ''
                  }"
                  *ngFor="
                    let product of selectedProducts;
                    let productIdx = index
                  "
                >
                  <div class="limit-edit">
                    <boxxds-dropdown-v2
                      [hasOuterBorder]="false"
                      *ngIf="
                        !showLimitCustomField(product.value) &&
                        !isLimitDisabledOnAddSubProduct(product.riskId) &&
                        brokerageCommissionMin[product.riskId]
                      "
                      [leftSymbol]="'$'"
                      [placeholder]="'5,000,000' | translate"
                      [options]="limitOptionsForProducts[product.key]"
                      [dropdownContainerStyle]="{ minWidth: '114px' }"
                      (currentValueChange)="
                        this.permissionList[this.currentScreen] &&
                          this.currentQuoteStatus !== 'quote-closed' &&
                          handleLimitChange(
                            $event,
                            product.value,
                            options?.length
                          )
                      "
                      [invalidErrMsg]="getLimitErrMsg(product.key)"
                      [currentValue]="
                        getLimitValue(product.riskId)
                          ? (getLimitValue(product.riskId)
                            | currencyFormat
                              : currency
                              : 'symbol-narrow'
                              : '1.0-0')
                          : ''
                      "
                      [isReadonly]="
                        !this.permissionList[this.currentScreen] ||
                        this.currentQuoteStatus === 'quote-closed'
                      "
                      [class.noPointerEvents]="
                        !this.permissionList[this.currentScreen] ||
                        this.currentQuoteStatus === 'quote-closed'
                      "
                    >
                    </boxxds-dropdown-v2>
                    <input
                      class="border-less-input"
                      *ngIf="
                        product.standaloneSellable &&
                        isLimitDisabledOnAddSubProduct(product.riskId)
                      "
                      [value]="
                        getLimitValue(product.riskId)
                          ? (getLimitValue(product.riskId)
                            | currencyFormat: currency : 'symbol-narrow')
                          : ''
                      "
                      [attr.disabled]="true"
                      class="disabled-field"
                    />

                    <div
                      class="custom-value"
                      *ngIf="showLimitCustomField(product.value)"
                    >
                      <input
                        class="border-less-input"
                        type="text"
                        mask="separator.0"
                        thousandSeparator=","
                        prefix="$"
                        (blur)="
                          handleLimitCustomValue(
                            $event,
                            product.value,
                            options?.length
                          )
                        "
                        maxlength="14"
                        [autofocus]="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="retention cell">
                <div
                  [ngClass]="{
                    'edit-mode-cell': true,
                    'edit-mode-err': getDeductibleErrMsg(product.key) !== ''
                  }"
                  *ngFor="
                    let product of selectedProducts;
                    let productIdx = index
                  "
                >
                  <div class="retention-edit">
                    <boxxds-dropdown-v2
                      [hasOuterBorder]="false"
                      [leftSymbol]="'$'"
                      *ngIf="
                        !showDeductibleCustomField(product.riskId) &&
                        product.standaloneSellable &&
                        brokerageCommissionMin[product.riskId]
                      "
                      [placeholder]="'5,000' | translate"
                      [options]="deductibleOptions"
                      [dropdownContainerStyle]="{ minWidth: '114px' }"
                      (currentValueChange)="
                        this.permissionList[this.currentScreen] &&
                          this.currentQuoteStatus !== 'quote-closed' &&
                          handleDeductableChange(
                            $event,
                            product.value,
                            options?.length
                          )
                      "
                      [invalidErrMsg]="getDeductibleErrMsg(product.key)"
                      [currentValue]="
                        getDeductibleValue(product.riskId)
                          ? (getDeductibleValue(product.riskId)
                            | currencyFormat
                              : currency
                              : 'symbol-narrow'
                              : '1.0-0')
                          : ''
                      "
                      [isReadonly]="
                        !this.permissionList[this.currentScreen] ||
                        this.currentQuoteStatus === 'quote-closed'
                      "
                    >
                      <!---- to do - [disabled]="!product.standaloneSellable" -->
                    </boxxds-dropdown-v2>
                    <input
                      class="border-less-input"
                      *ngIf="!product.standaloneSellable"
                      [value]="
                        getDeductibleValue(product?.riskId)
                          ? (getDeductibleValue(product?.riskId)
                            | currencyFormat: currency : 'symbol-narrow')
                          : ''
                      "
                      [attr.disabled]="true"
                      class="disabled-field"
                    />
                    <div
                      class="custom-value"
                      *ngIf="
                        showDeductibleCustomField(product.riskId) &&
                        product.standaloneSellable
                      "
                    >
                      <input
                        class="border-less-input"
                        type="text"
                        mask="separator.0"
                        thousandSeparator=","
                        prefix="$"
                        maxlength="14"
                        (blur)="
                          handleDeductableCustomValue(
                            $event,
                            product.value,
                            options?.length
                          )
                        "
                        [autofocus]="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="commission cell">
                <div
                  *ngFor="
                    let product of selectedProducts;
                    let productIdx = index
                  "
                >
                  <ng-container>
                    <boxxds-dropdown-v2
                      *ngIf="
                        !showCommissionCustomField(product.riskId) &&
                        product.standaloneSellable &&
                        brokerageCommissionMin[product.riskId]
                      "
                      [hasOuterBorder]="false"
                      [placeholder]="'0%' | translate"
                      [showSubKeyInNextLine]="true"
                      [options]="commissionOptions[product.riskId]"
                      [dropdownContainerStyle]="{ minWidth: '90px' }"
                      (currentValueChange)="
                        this.permissionList[this.currentScreen] &&
                          this.currentQuoteStatus !== 'quote-closed' &&
                          handleBrokerCommissionPerc(
                            $event,
                            product,
                            options?.length
                          )
                      "
                      [currentValue]="
                        getBrokerageCommissionPerc(product.riskId) + '%'
                      "
                      [isReadonly]="
                        !this.permissionList[this.currentScreen] ||
                        this.currentQuoteStatus === 'quote-closed'
                      "
                      [invalidErrMsg]="getCommissionErrMsg(product.key)"
                      [class.noPointerEvents]="
                        !this.permissionList[this.currentScreen] ||
                        this.currentQuoteStatus === 'quote-closed'
                      "
                    >
                    </boxxds-dropdown-v2>

                    <div
                      class="custom-value"
                      *ngIf="showCommissionCustomField(product.riskId)"
                    >
                      <input
                        class="border-less-input"
                        type="text"
                        mask="percent.2"
                        [suffix]="'%'"
                        (blur)="
                          handleCommissionCustomChange(
                            $event,
                            product,
                            options?.length
                          )
                        "
                        maxlength="14"
                        [autofocus]="true"
                      />
                      <p>{{ brokerageCommissionText[product.riskId] }}</p>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="premium cell">
                <div
                  *ngFor="
                    let product of selectedProducts;
                    let productIdx = index
                  "
                >
                  {{ "$" + (product?.premiumCalculated ?? "0.00") }}
                </div>
              </div>
              <div class="edited-premium cell">
                <div
                  *ngFor="
                    let product of selectedProducts;
                    let productIdx = index
                  "
                >
                  <ng-container>
                    <boxxds-dropdown-v2
                      [hasOuterBorder]="false"
                      [leftSymbol]="'$'"
                      *ngIf="!showEditedPremCustomField(product.value)"
                      placeholder="0,000"
                      [options]="editedPremiumOptions"
                      [dropdownContainerStyle]="{ minWidth: '114px' }"
                      (currentValueChange)="
                        this.permissionList[this.currentScreen] &&
                          this.currentQuoteStatus !== 'quote-closed' &&
                          handleEditedPremium($event, product.value)
                      "
                      [currentValue]="
                        isNumber(getEditedPremium(product.value))
                          ? (getEditedPremium(product.value)
                            | currency: currency : 'symbol-narrow' : '1.0-0')
                          : getEditedPremium(product.value)
                      "
                      [isReadonly]="
                        !this.permissionList[this.currentScreen] ||
                        this.currentQuoteStatus === 'quote-closed'
                      "
                      [class.noPointerEvents]="
                        !this.permissionList[this.currentScreen] ||
                        this.currentQuoteStatus === 'quote-closed'
                      "
                      [hideSelectedValue]="true"
                    >
                    </boxxds-dropdown-v2>
                    <div
                      class="custom-value"
                      *ngIf="showEditedPremCustomField(product.value)"
                    >
                      <input
                        class="border-less-input"
                        type="text"
                        [ngStyle]="
                          getEditedPremiumErr(product.value) !== '' && {
                            border: '1px solid #CA3434'
                          }
                        "
                        mask="separator.0"
                        thousandSeparator=","
                        prefix="$"
                        maxlength="11"
                        (blur)="
                          handleEditedPremiumCustVal($event, product.value)
                        "
                        [autofocus]="true"
                        [min]="getEditedPremiumMinVal(product.value)"
                        [max]="getEditedPremiumMaxVal(product.value)"
                      />
                      <div
                        *ngIf="getEditedPremiumErr(product.value) !== ''"
                        class="error-msg"
                      >
                        {{ getEditedPremiumErr(product.value) }}
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div
                class="sublimit cell"
                [ngClass]="{ 'edit-mode-sublimit-link': true }"
                *ngFor="let product of selectedProducts; let productIdx = index"
              >
                <button
                  [attr.disabled]="true"
                  (click)="handleSublimitAddOption()"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      d="M10.7083 13.3337L14.0417 10.0003M14.0417 10.0003L10.7083 6.66699M14.0417 10.0003H7.375M19.0417 10.0003C19.0417 14.6027 15.3107 18.3337 10.7083 18.3337C6.10596 18.3337 2.375 14.6027 2.375 10.0003C2.375 5.39795 6.10596 1.66699 10.7083 1.66699C15.3107 1.66699 19.0417 5.39795 19.0417 10.0003Z"
                      stroke="#98A2B3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div class="subtotal cell">
                <div class="subtotal-amount">
                  {{
                    isNumber(data.total.premium)
                      ? (data.total.premium
                        | currency: currency : "symbol-narrow")
                      : data.total.premium
                  }}
                </div>
                <div class="subtotal-info">
                  {{ "workFlow3.quoting.content.taxExcluded" | translate }}
                </div>
              </div>
              <div
                class="actions cell"
                [ngClass]="{ 'justify-content-center': true }"
                *ngIf="
                  this.permissionList[this.currentScreen] ||
                  this.currentQuoteStatus !== 'quote-closed'
                "
              >
                <boxxds-button-v2
                  (handleClick)="handleClear()"
                  [leftImage]="'plus-circle'"
                  type="button"
                  [btnType]="'secondary'"
                  [leftImageStyle]="{
                    filter:
                      'invert(31%) sepia(14%) saturate(868%) hue-rotate(176deg) brightness(91%) contrast(85%)'
                  }"
                ></boxxds-button-v2>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div
      class="add-option-btn-holder"
      *ngIf="(form$ | async)?.quoteOptions as options"
    >
      <boxxds-button-v2
        *ngIf="
          this.permissionList[this.currentScreen] &&
          this.currentQuoteStatus !== 'quote-closed'
        "
        (handleClick)="handleAddOption(options)"
        [buttonText]="
          ('Add option' | translate) +
          ' ' +
          ((form$ | async)?.quoteOptions?.length + 1)
        "
        [rightImage]="'plus-circle'"
        type="button"
        [isDisabled]="
          options?.length >= 10 || (addOption && !isEditMode) || isEditMode
        "
        [btnType]="'link'"
        [btnStyle]="
          options?.length >= 10 || (addOption && !isEditMode) || isEditMode
            ? { color: '#98A2B3' }
            : { color: '#2D2D39' }
        "
        [rightImageStyle]="
          options.length >= 10 || (addOption && !isEditMode) || isEditMode
            ? {
                filter:
                  'invert(73%) sepia(5%) saturate(888%) hue-rotate(179deg) brightness(89%) contrast(85%)'
              }
            : {
                filter:
                  'invert(15%) sepia(3%) saturate(4935%) hue-rotate(202deg) brightness(89%) contrast(87%)'
              }
        "
      ></boxxds-button-v2>
    </div>
  </div>
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>

  <!-- <quote-notes-popup> -->
  <app-quote-notes-v2
    *ngIf="showQuoteNotesPopup"
    [showModalNotesPopup]="showQuoteNotesPopup"
    [policyPeriodId]="policyPeriodId"
    [details]="currentQuoteProductsDetails"
    (handleClose)="handleQuoteNotesClose()"
  >
  </app-quote-notes-v2>
  <!-- </quote-notes-popup> -->

  <section class="footer-btns" *ngIf="!showBodySpinner">
    <boxxds-button-v2
      [buttonText]="'workFlow3.components.shared.button.back' | translate"
      [isDisabled]="showSpinner"
      [size]="'sm'"
      [btnClass]="'link gray'"
      [leftImage]="'arrow-narrow-left'"
      (handleClick)="handleBackBtn()"
    ></boxxds-button-v2>
    <div class="d-flex">
      <boxxds-button-v2
        *ngIf="
          !showSpinner &&
          this.permissionList[this.currentScreen] &&
          this.currentQuoteStatus !== 'quote-closed'
        "
        [buttonText]="'workFlow3.components.shared.button.decline' | translate"
        [btnClass]="'secondary gray'"
        [btnType]="'default'"
        [size]="'lg'"
        (handleClick)="handleRiskAction(transactionRiskActions.Decline)"
        [isDisabled]="showSpinner"
        [btnStyle]="{ width: '170px' }"
      ></boxxds-button-v2>
      <boxxds-button-v2
        *ngIf="!showSpinner && form$ | async as formData"
        [buttonText]="'workFlow3.quoting.button.continueToBind' | translate"
        [isDisabled]="showSpinner"
        [size]="'lg'"
        [btnClass]="'primary'"
        [btnType]="'default'"
        (handleClick)="handleContinue($event)"
        [btnStyle]="{ width: '219px', marginLeft: '6px' }"
      ></boxxds-button-v2>
    </div>
  </section>
</section>
