import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Pagination } from 'src/app/entities/boxx-response';
import { BrokerageBranchService } from 'src/app/services/brokerage-branch.service';
import { BrokerageDirectoryService } from 'src/app/services/brokerage-directory.service';
import { BrokerageProducerService } from 'src/app/services/brokerage-producer.service';
import { BrokerageService } from 'src/app/services/brokerage.service';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { Store, select } from '@ngrx/store';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { getErrorMessage, excelFileDownload } from 'src/app/utils/utils';

@Component({
  selector: 'app-brokerage-directory',
  templateUrl: './brokerage-directory.component.html',
  styleUrls: ['./brokerage-directory.component.less'],
})
export class BrokerageDirectoryComponent implements OnInit {
  form: FormGroup;
  quickSort = [
    { name: '0 to 9', value: '[0-9]', active: false },
    { name: 'A to E', value: '[a-e]', active: false },
    { name: 'F to K', value: '[f-k]', active: false },
    { name: 'L to P', value: '[l-p]', active: false },
    { name: 'Q to U', value: '[q-u]', active: false },
    { name: 'V to Z', value: '[v-z]', active: false },
  ];
  searchOptions: Array<any> = [
    { name: 'Brokerage', value: 'brokerage', checked: true, id: 'brokerage' },
    { name: 'Branch', value: 'branch', checked: true, id: 'branch' },
    { name: 'Producer', value: 'producer', checked: true, id: 'producer' },
  ];
  isAnyCheckboxChecked: any;
  data: any;
  totalDataCount = 0;
  isSearchResult: boolean = false;
  addSortTo: string | Array<string> = ['Name', 'Type', 'Status'];
  pagination: Pagination;
  linkDetails: any[];
  tableHeader = [{ Name: '', Type: '', Path: '', Status: '' }];
  sortBy = 'createdDt:desc,id:asc';
  pageNumber = 1;
  searchWord = '';
  userID: number;
  showTblSpinner: boolean = false;
  showErrorAlert = false;
  msgErrorAlert = '';
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};

  constructor(
    private fb: FormBuilder,
    public brokerageService: BrokerageService,
    private router: Router,
    public brokerageProducerService: BrokerageProducerService,
    public brokerageBranchService: BrokerageBranchService,
    public brokerageDirectoryService: BrokerageDirectoryService,
    private localStorageService: LocalStorageService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
    });

    this.showTblSpinner = true;
    this.userID = this.localStorageService.getBoxxUserId();
    this.form = this.fb.group({
      searchBy: new FormArray([]),
      searchKey: [''],
    });

    this.brokerageDirectoryService
      .GetBrokerageDirectories(this.pageNumber, 10, this.sortBy)
      .subscribe({
        next: (response) => {
          const tableData = response.data?.map((dataObj) => ({
            Name: this.getFormatName(dataObj),
            Type: dataObj?.entity,
            Path: this.getPathColumn(dataObj),
            Status: dataObj.active === true ? 'Active' : 'Inactive',
            ActionView: this.getFormatUrl(
              dataObj.entity,
              dataObj.id,
              dataObj.brokerage,
              dataObj.brokerageBranch,
            ),
          }));
          this.showTblSpinner = false;
          this.data = tableData;
          this.totalDataCount = response.pagination.totalRecords;
          this.pagination = response.pagination;
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
  }

  handleExportExcel(): void {
    this.showTblSpinner = true;
    const searchKey = this.sanitizeSearchInput(this.form.value['searchKey']);
    this.searchWord = this.searchWord ?? searchKey;

    this.brokerageDirectoryService
      .ExportBrokerageDirectoryDetails(
        1,
        this.totalDataCount,
        this.sortBy,
        this.searchOptions[0].checked,
        this.searchOptions[1].checked,
        this.searchOptions[2].checked,
        this.searchWord,
      )
      .subscribe({
        next: (response) => {
          excelFileDownload('ExportedData.xlsx', response);
          this.showTblSpinner = false;
        },
        error: (error) => {
          this.showTblSpinner = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
  }

  isSearchInpReadOnly() {
    const isAnyCheckboxChecked = this.searchOptions.findIndex(
      (obj) => obj.checked === true,
    );
    return isAnyCheckboxChecked === -1;
  }

  handleAddNewBrokerage() {
    if (this.form.disabled) {
      return;
    }
    this.router.navigate(['/dashboard/brokerage/new'], {
      skipLocationChange: true,
    });
  }
  handleCheckbox(index, options) {
    this.showTblSpinner = true;
    options[index].checked = !options[index].checked;
    this.isAnyCheckboxChecked = options.findIndex(
      (obj) => obj.checked === true,
    );
    this.clearQuickSortOption();
    const searchKey =
      this.form.value['searchKey'].trim().length !== 0
        ? this.form.value['searchKey'].trim()
        : this.getActiveQuickSortName();
    this.isSearchResult = this.form.value['searchKey'].trim().length !== 0;
    this.pageNumber = 1; // resetting page number when search changes
    if (this.isAnyCheckboxChecked >= 0) {
      this.brokerageDirectoryService
        .GetBrokerageDirectories(
          1,
          10,
          this.sortBy,
          options[0].checked,
          options[1].checked,
          options[2].checked,
          searchKey,
        )
        .subscribe({
          next: (response) => {
            const tableData =
              response?.data?.length === 0
                ? this.tableHeader
                : response?.data?.map((dataObj) => ({
                    Name: this.getFormatName(dataObj),
                    Type: dataObj?.entity,
                    Path: this.getPathColumn(dataObj),
                    Status: dataObj.active === true ? 'Active' : 'Inactive',
                    ActionView: this.getFormatUrl(
                      dataObj.entity,
                      dataObj.id,
                      dataObj.brokerage,
                      dataObj.brokerageBranch,
                    ),
                  }));
            if (this.isAnyCheckboxChecked >= 0) {
              this.showTblSpinner = false;
              this.data = tableData;
              this.totalDataCount = response?.pagination.totalRecords;
              this.pagination = response?.pagination;
            } else {
              this.returnEmptyResponse();
            }
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    } else {
      this.returnEmptyResponse();
    }
  }

  returnEmptyResponse() {
    this.data = [{ Name: '', Type: '', Path: '', Status: '' }];
    this.totalDataCount = 0;
    this.showTblSpinner = false;
  }

  handleQuickSort(i, buttonRef) {
    buttonRef.blur();
    this.showTblSpinner = true;
    const currentActiveIdx = this.quickSort.findIndex(
      (obj) => obj.active == true,
    );
    if (currentActiveIdx !== -1) {
      this.quickSort[currentActiveIdx].active = false;
    }
    this.quickSort[i].active = currentActiveIdx != i;
    this.pageNumber = 1; // resetting page number when search changes

    this.brokerageDirectoryService
      .GetBrokerageDirectories(
        1,
        10,
        this.sortBy,
        this.searchOptions[0].checked,
        this.searchOptions[1].checked,
        this.searchOptions[2].checked,
        this.getActiveQuickSortName(),
      )
      .subscribe({
        next: (response) => {
          const tableData =
            response?.data?.length === 0
              ? this.tableHeader
              : response?.data?.map((dataObj) => ({
                  Name: this.getFormatName(dataObj),
                  Type: dataObj?.entity,
                  Path: this.getPathColumn(dataObj),
                  Status: dataObj.active === true ? 'Active' : 'Inactive',
                  ActionView: this.getFormatUrl(
                    dataObj.entity,
                    dataObj.id,
                    dataObj.brokerage,
                    dataObj.brokerageBranch,
                  ),
                }));
          this.showTblSpinner = false;
          this.data = tableData;
          this.totalDataCount = response?.pagination.totalRecords;
          this.pagination = response?.pagination;
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
  }

  handlePageChange(pageNumber) {
    const searchKey =
      this.form.value['searchKey'].trim().length !== 0
        ? this.form.value['searchKey'].trim()
        : this.getActiveQuickSortName();

    if (pageNumber > 0) {
      this.showTblSpinner = true;
      this.pageNumber = pageNumber;
      this.brokerageDirectoryService
        .GetBrokerageDirectories(
          pageNumber,
          10,
          this.sortBy,
          this.searchOptions[0].checked,
          this.searchOptions[1].checked,
          this.searchOptions[2].checked,
          searchKey,
        )
        .subscribe({
          next: (response) => {
            const tableData =
              response?.data.length === 0
                ? this.tableHeader
                : response?.data?.map((dataObj) => ({
                    Name: this.getFormatName(dataObj),
                    Type: dataObj?.entity,
                    Path: this.getPathColumn(dataObj),
                    Status: dataObj.active === true ? 'Active' : 'Inactive',
                    ActionView: this.getFormatUrl(
                      dataObj.entity,
                      dataObj.id,
                      dataObj.brokerage,
                      dataObj.brokerageBranch,
                    ),
                  }));
            this.showTblSpinner = false;
            this.data = tableData;
            this.totalDataCount = response?.pagination.totalRecords;
            this.pagination = response?.pagination;
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    }
  }

  private getFormatUrl(
    entity: string,
    id: number,
    brokerage: any,
    brokerageBranch?: any,
  ) {
    let url: string;
    switch (entity) {
      case 'Brokerage':
        url = `/dashboard/brokerage/view/${id}`;
        break;

      case 'Branch':
        url = `/dashboard/branch/view/${id}/${brokerage.id}`;
        break;

      case 'Producer':
        url = `/dashboard/branch/profile/producer/${id}`;
    }
    return url;
  }

  handleAscSort(key: string) {
    const sortBy = this.mapSortColumns(key);
    this.sortBy = sortBy + ':asc,id:asc';
    this.listBrokerageDirectories(
      this.userID,
      this.pageNumber,
      10,
      this.sortBy,
      this.searchOptions[0].checked,
      this.searchOptions[1].checked,
      this.searchOptions[2].checked,
      this.getActiveQuickSortName(),
    );
  }

  handleDescSort(key: string) {
    const sortBy = this.mapSortColumns(key);
    this.sortBy = sortBy + ':desc,id:asc';
    this.listBrokerageDirectories(
      this.userID,
      this.pageNumber,
      10,
      this.sortBy,
      this.searchOptions[0].checked,
      this.searchOptions[1].checked,
      this.searchOptions[2].checked,
      this.getActiveQuickSortName(),
    );
  }
  mapSortColumns(key: string) {
    let sortColumn: string;
    switch (key) {
      case 'Name':
        sortColumn = 'name';
        break;

      case 'Type':
        sortColumn = 'entity';
        break;

      case 'Status':
        sortColumn = 'active';
        break;

      default:
        sortColumn = 'id';
        break;
    }
    return sortColumn;
  }

  handleSearch(event) {
    if (event.key == 'Enter') {
      this.showTblSpinner = true;
      // TODO - call API for search results
      this.isSearchResult = this.form.value['searchKey'].trim().length !== 0;
      this.clearQuickSortOption();
      this.addSortTo = ['Type', 'Name', 'Status'];
      const searchKey = this.sanitizeSearchInput(this.form.value['searchKey']);
      this.pageNumber = 1;
      this.searchWord = searchKey;
      this.brokerageDirectoryService
        .GetBrokerageDirectories(
          1,
          10,
          this.sortBy,
          this.searchOptions[0].checked,
          this.searchOptions[1].checked,
          this.searchOptions[2].checked,
          searchKey,
        )
        .subscribe({
          next: (response) => {
            const tableData =
              response.data.length === 0
                ? this.tableHeader
                : response.data?.map((dataObj) => ({
                    Name: this.getFormatName(dataObj),
                    Type: dataObj?.entity,
                    Path: this.getPathColumn(dataObj),
                    Status: dataObj.active === true ? 'Active' : 'Inactive',
                    ActionView: this.getFormatUrl(
                      dataObj.entity,
                      dataObj.id,
                      dataObj.brokerage,
                      dataObj.brokerageBranch,
                    ),
                  }));
            this.showTblSpinner = false;
            this.data = tableData;
            this.totalDataCount = response.pagination.totalRecords;
            this.pagination = response.pagination;
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    } else {
      let sanitizedtext = this.sanitizeSearchInput(
        this.form.value['searchKey'],
      );
      this.form.patchValue({
        searchKey: sanitizedtext,
      });
    }
  }

  handleCloseSuccessEvent() {
    this.showErrorAlert = false;
  }

  clearQuickSortOption() {
    const currentActiveIdx = this.quickSort.findIndex(
      (obj) => obj.active == true,
    );
    if (currentActiveIdx !== -1) {
      this.quickSort[currentActiveIdx].active = false;
    }
  }
  getActiveQuickSortName() {
    let name = '';
    const currentActiveIdx = this.quickSort.findIndex(
      (obj) => obj.active == true,
    );
    if (currentActiveIdx !== -1) {
      name = this.quickSort[currentActiveIdx].value;
    }
    if (!this.isSearchResult) {
      this.searchWord = name;
    }
    return name;
  }

  listBrokerageDirectories(
    userId,
    page = 1,
    limit = 10,
    sort = this.sortBy,
    brokerage = true,
    branch = true,
    producer = true,
    name = '',
  ) {
    var nameSort = this.searchWord ? this.searchWord : name;
    this.brokerageDirectoryService
      .GetBrokerageDirectories(
        page,
        limit,
        sort,
        brokerage,
        branch,
        producer,
        nameSort,
      )
      .subscribe({
        next: (response) => {
          this.data = response?.data?.map((dataObj) => ({
            Name: this.getFormatName(dataObj),
            Type: dataObj?.entity,
            Path: this.getPathColumn(dataObj),
            Status: dataObj.active === true ? 'Active' : 'Inactive',
            ActionView: this.getFormatUrl(
              dataObj.entity,
              dataObj.id,
              dataObj.brokerage,
              dataObj.brokerageBranch,
            ),
          }));
          this.totalDataCount = response?.pagination.totalRecords;
          this.pagination = response?.pagination;
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
  }

  getPathColumn(dataObj) {
    let path = '';

    switch (dataObj?.entity) {
      case 'Brokerage':
        path = '';
        break;

      case 'Branch':
        path = dataObj?.brokerage?.name;
        break;

      case 'Producer':
        path =
          dataObj?.brokerage?.name + ' / ' + dataObj?.brokerageBranch?.name;
        break;

      default:
        break;
    }

    return path;
  }

  getFormatName(dataObj) {
    let name: string = '';
    switch (dataObj?.entity) {
      case 'Brokerage':
        name = dataObj?.brokerage?.name;
        break;

      case 'Branch':
        name = dataObj.brokerageBranch?.name;
        break;
      case 'Producer':
        name = dataObj.brokerageProducer?.name;
        break;

      default:
        break;
    }

    return name;
  }

  sanitizeSearchInput(userInput: string): string {
    // Define the list of special characters that you want to remove
    const specialCharacters = /[\^\$\\*+?.`/~()!=|[\]{}%#]/g;

    // Remove special characters and underscore from the user input
    // Return the sanitized input
    return userInput.replace(specialCharacters, '').replace(/^[ _]/, '');
  }
}
