<div class="custom-modal" *ngIf="showModal">
  <div class="modal-content">
    <div class="close-btn">
      <button (click)="closeHandler()" [disabled]="isSaveProcessing">
        <img src="assets/img/dashboard/card/close.png" />{{
          "common.close" | translate
        }}
      </button>
    </div>
    <div class="content">
      <div class="d-flex flex-column align-items-center">
        <img
          src="assets/img/alert-triangle.jpg"
          width="66px"
          height="66px"
          alt="alert"
        />
        <boxxds-body
          sz="md"
          [text]="titleText"
          [customStyle]="{ color: '#FE802C', marginTop: '12px' }"
        ></boxxds-body>
      </div>
      <div class="quote-details d-flex flex-column mt-1">
        <boxxds-body
          sz="md"
          [text]="
            'common.docTempSectionSubHeading' | translate: { count: docLength }
          "
          [customStyle]="{
            color: '#616162',
            marginTop: '20px',
            fontSize: '16px'
          }"
        ></boxxds-body>
      </div>
      <div class="d-flex flex-row doc-selection-container">
        <div class="flex-column mb-5 doc-dropdown-options-list">
          <ul>
            <li>
              <div class="d-flex">
                <div class="slno">1.</div>
                <div class="full-width">
                  <boxxds-body
                    sz="sm"
                    [text]="'common.docTypeLabelDescription' | translate"
                    [customStyle]="{ color: '#333334', fontSize: '16px' }"
                  ></boxxds-body>
                  <div class="d-flex justify-content-between">
                    <div>
                      <boxxds-body
                        sz="sm"
                        [text]="'common.documentType' | translate"
                        [customStyle]="{ color: '#333334', marginTop: '20px' }"
                      >
                      </boxxds-body>
                    </div>
                    <div class="label-right-content">
                      {{ docRemainingCount }}/{{ docLength }}
                      {{ "common.remaining" | translate }}
                    </div>
                  </div>

                  <boxxds-dropdown
                    [form]="form"
                    [control]="'documentType'"
                    [placeholder]="'common.select' | translate"
                    [options]="docOptions"
                    [showKeyasTitle]="true"
                    [dropdownContainerStyle]="{
                      maxWidth: '250px',
                      marginTop: '8px'
                    }"
                    [dropdownListStyle]="{
                      maxHeight: '500px',
                      paddingBottom: '5px'
                    }"
                    (currentValueChange)="handleDocumentChange($event)"
                  ></boxxds-dropdown>
                </div>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <div class="slno">2.</div>
                <div class="full-width">
                  <boxxds-body
                    sz="sm"
                    [text]="'common.tempTypeLabelDescription' | translate"
                    [customStyle]="{ color: '#333334', fontSize: '16px' }"
                  ></boxxds-body>
                  <boxxds-body
                    sz="sm"
                    [text]="'common.templateType' | translate"
                    [customStyle]="{ color: '#333334', marginTop: '20px' }"
                  ></boxxds-body>
                  <boxxds-dropdown
                    [disabled]="templateDisabled"
                    [form]="form"
                    [control]="'templateType'"
                    [placeholder]="'common.select' | translate"
                    [options]="tempOptions"
                    [showKeyasTitle]="true"
                    [dropdownContainerStyle]="{
                      maxWidth: '250px',
                      marginTop: '8px'
                    }"
                    [dropdownListStyle]="{
                      maxHeight: '500px',
                      paddingBottom: '5px'
                    }"
                    [currentValue]="form.value?.templateType"
                    (currentValueChange)="handleTemplateChange($event)"
                  ></boxxds-dropdown>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="flex-column mb-5 equal-div doc-preview-container">
          <boxxds-body
            sz="sm"
            [text]="'common.yourSelection' | translate"
            [customStyle]="{ color: '#8F8F91', fontSize: '16px' }"
          ></boxxds-body>
          <div class="pdt-container">
            <div
              class="d-flex"
              *ngFor="let pdt of docTempOptions; let i = index"
            >
              <div class="pdt">
                <div class="pdt-name">
                  {{ pdt.doc ? pdt.doc : "" }}
                  {{ pdt.template ? ", " + pdt.template : "" }}
                </div>
                <button
                  type="button"
                  aria-label="close"
                  class="close-btn"
                  (click)="handleDocTempRemove(pdt.doc, pdt.template, i)"
                >
                  <img src="assets/img/dashboard/quote/remove.png" />
                </button>
              </div>
            </div>
          </div>
          <div class="footer-content">
            {{ docSelectedCount }}/{{ docLength }}
            {{ "common.selected" | translate }}
          </div>
        </div>
      </div>

      <boxxds-button
        *ngIf="!isSaveProcessing"
        [isDisabled]="buttonDisabled"
        [buttonText]="'common.confirmSelection' | translate"
        [type]="'submitted'"
        [btnStyle]="{ marginLeft: 'auto', marginTop: '40px' }"
        (handleClick)="actionConfirmHandler($event)"
      >
      </boxxds-button>
      <button
        *ngIf="isSaveProcessing"
        class="btn-processing"
        tabindex="0"
        [ngStyle]="{
          border: '1px solid #FFBE3D',
          borderRadius: '8px',
          width: '88px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '4px 8px',
          background: '#FCF8EF',
          marginLeft: 'auto'
        }"
      >
        <boxxds-spinner></boxxds-spinner>
      </button>
    </div>
  </div>
</div>
