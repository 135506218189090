import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Quote } from 'src/app/models/quote.model';
import { getQuoteSelector } from 'src/app/store/create-quote/create-quote.selector';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { PolicyRiskService } from 'src/app/services/policy-risk.service';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import { RiskTemplateService } from 'src/app/services/risk-template';
import { DomainsService } from 'src/app/services/domains.service';
import { RiskRegionSubjectivityService } from 'src/app/services/risk-region-subjectivity.service';
import { PolicySubjectivityService } from 'src/app/services/policy-subjectivity.service';
import { PolicyPeriodService } from 'src/app/services/policy-period.service';
import {
  TEMPLATETYPE,
  TEMPLATETYPE_ENDORSEMENT,
  TEMPLATETYPE_POLICYWORDING,
  DOCUMENTTYPE,
  DOCUMENTTYPE_ENDORSEMENT,
  DOCUMENTTYPE_POLICY,
  TEMPLATETYPE_DECLARATION,
  DOCUMENTTYPE_DECLARATION,
  INSURED_COMPANY_TYPE,
  EFFECTIVE_DATE_MAX_RANGE_LIMIT,
} from 'src/app/constants/quote-constant';
import { PolicyRiskTrxService } from 'src/app/services/policy-risk-trx.service';
import { PolicyRiskDocProcessService } from 'src/app/services/policy-risk-doc-process.service';
import { PolicyRiskDocPreviewService } from 'src/app/services/policy-risk-doc-preview.service';
import {
  convertDate,
  formatDate,
  formatDateMoment,
  getCityTime,
  getFormatDate,
} from 'src/app/utils/formatDate';
import { InsuredService } from 'src/app/services/insured.service';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { getLifecycleState } from 'src/app/store/lifecycle/lifecycle.select';
import { TranslateService } from '@ngx-translate/core';
import { getErrorMessage } from 'src/app/utils/utils';
import { AlertService } from 'src/app/services/alert.service';
import { alertDetails } from 'src/app/entities/common';
import { USER_ROLES } from 'src/app/constants/security/systemUserType';
@Component({
  selector: 'app-product-summary',
  templateUrl: './product-summary.component.html',
  styleUrls: ['./product-summary.component.less'],
})
export class ProductSummaryComponent implements OnInit, OnDestroy {
  alerts: {
    show?: boolean;
    bodyText?: string;
    type?: string;
    headerText?: string;
  }[] = [];
  form$: Observable<Quote>;
  isContinueFormValid: boolean = true;
  underWriters: string[] = [];
  underWriterOptions: any = [];
  docOptions = [
    {
      key: 'Document 3',
      value: 'Document 3',
      disabled: false,
      url: 'assets/docs/sampledocument.pdf',
    },
    {
      key: 'Document 4',
      value: 'Document 4',
      disabled: false,
      url: 'assets/docs/sampledocument.pdf',
    },
    {
      key: 'Insured Submission Form_2023',
      value: 'Insured Submission Form_2023',
      disabled: false,
      url: 'assets/docs/sampledocument.pdf',
    },
  ];
  wordSelectOptions = [
    { key: 'Word 1', value: 'Word 1' },
    { key: 'Word 2', value: 'Word 2' },
  ];
  showDocPreview = false;
  currentPreviewDocUrl = '';
  policyPeriodId: Number = 0;
  quoteOptions = [];
  products = [];
  productsIds = [];
  riskRegionId = 0;
  templateCodeForEndorsement = 0;
  templateCodeForWordings = 0;
  templateCodeForDeclaration = 0;
  documentCodeForEndorsement = 0;
  documentCodeForWordings = 0;
  documentCodeForDeclaration = 0;
  riskTrnxs;
  underwriterTotalCount = 0;
  underwriterTotalPages = 1;
  disabledDropdownList: string[] = [];
  productList: { [x: string]: any }[] = [];

  endorsementOptions: any[] = [
    { key: '', value: '', disabled: false, url: '' },
  ];
  wordingsOptions: any[] = [{ key: '', value: '', disabled: false, url: '' }];
  subjectivityOptns: any[] = [{ key: '', value: '', disabled: false, url: '' }];
  declarationOptions: any[] = [
    { key: '', value: '', disabled: false, url: '' },
  ];
  subjectivityMandatory = [];
  selectedSubjectivities = [];
  endorsementsMandatory = [];
  selectedEndorsements = [];
  selectedWordings = [];
  selectedDeclarations = [];
  addons = [];
  policyRiskIds = [];
  showBodySpinner: boolean = false;
  showSpinner: boolean = false;
  showContinueSpinner: boolean = false;
  notificationAlert: alertDetails;
  policyRisks = [];
  underWritersList: any;
  wordingsDeclarationError = false;
  spinnerCounter = 0;
  endDateEditable: boolean = false;
  minStartDate = new Date();
  maxStartDate = new Date();
  defaultEffectiveDate;
  defaultExpiryDate;
  minEndDate;
  maxEndDate;
  isStartDateValid = true;
  isEndDateValid = true;
  startDateErrMsg;
  endDateErrMsg;
  insuredTimezone;
  currentDateTimezone;
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  addedSubjectivities = [];
  editedSubjectivity = '';
  currentQuoteStatus: string = '';
  shortDateFormat: string = '';
  longDateFormat: string = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private policyRiskService: PolicyRiskService,
    private boxxUserService: BoxxUserService,
    private riskRegionService: RiskRegionService,
    private riskTemplateService: RiskTemplateService,
    private domainsService: DomainsService,
    private riskRegionSubjectivityService: RiskRegionSubjectivityService,
    private policySubjectivityService: PolicySubjectivityService,
    private policyRiskTrxService: PolicyRiskTrxService,
    private policyRiskDocProcessService: PolicyRiskDocProcessService,
    private PolicyRiskDocPreviewService: PolicyRiskDocPreviewService,
    private datePipe: DatePipe,
    private policyPeriodService: PolicyPeriodService,
    private insuredService: InsuredService,
    private translate: TranslateService,
    private alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    this.store.pipe(select(getLifecycleState)).subscribe((data) => {
      this.currentQuoteStatus = data.quoteStatus;
    });

    this.form$ = this.store.pipe(select(getQuoteSelector));
    this.form$.subscribe(
      (event) => (this.policyPeriodId = event.ui.policyPeriodId),
    );
    this.form$.subscribe((event) => (this.productList = event.products));
    this.populateUnderwriterOptions();
    this.loadPageData();
  }

  ngOnDestroy(): void {
    this.alertService.clearAlerts(-1);
  }

  loadPageData() {
    let policyId = null;
    this.spinnerCounter = 0;
    this.showBodySpinner = true;
    this.spinnerCounter++;
    this.policyRiskService
      .GetAllByPolicyPeriodId(this.policyPeriodId)
      .subscribe((quoteData) => {
        this.policyRisks = quoteData?.data;
        this.quoteOptions = quoteData.data.flatMap((dataObj) => {
          const quoteOptions = dataObj.PolicyRiskTrxes[0]?.QuoteOptions || [];
          return quoteOptions;
        });
        const insuredRespObj =
          quoteData?.data[0]?.policyPeriod?.policy?.insured;
        const insuredId = quoteData?.data[0]?.policyPeriod?.policy?.insuredId;
        policyId = quoteData?.data[0]?.policyPeriod?.policy?.id;

        const insuredDetails = {
          name:
            insuredRespObj?.insuredType == INSURED_COMPANY_TYPE
              ? insuredRespObj?.companyName
              : insuredRespObj?.firstName + insuredRespObj?.lastName,
        };

        this.insuredService.GetByInsuredId(insuredId).subscribe({
          next: async (data) => {
            this.insuredTimezone =
              data.data.insuredLocation.timeZoneCode ?? 'America/New_York';
          },
        });

        let currentDate = getCityTime(this.insuredTimezone);
        this.currentDateTimezone = currentDate;

        this.store.dispatch(
          new CreateQuoteAction.UpdateInsuredAction({
            insuredName: insuredDetails.name,
            insuredEmail: null,
            insuredId,
            policyId: Number(policyId),
          }),
        );
        //Date details
        const effectiveDate = quoteData?.data[0]?.policyPeriod?.effectiveDt;
        const expiryDate = quoteData?.data[0]?.policyPeriod?.expiryDt;
        if (effectiveDate) {
          this.store.dispatch(
            new CreateQuoteAction.UpdateStartDateAction(
              formatDateMoment(effectiveDate, this.shortDateFormat),
            ),
          );
        }
        if (expiryDate) {
          this.store.dispatch(
            new CreateQuoteAction.UpdateEndDateAction(
              formatDateMoment(expiryDate, this.shortDateFormat),
            ),
          );
        }

        let defaultStartDate;
        let defaultEndDate;

        if (!effectiveDate) {
          defaultStartDate = currentDate.currentDate ?? this.getTomorrow();

          this.store.dispatch(
            new CreateQuoteAction.UpdateStartDateAction(
              formatDate(defaultStartDate, this.shortDateFormat),
            ),
          );
        } else {
          defaultStartDate = new Date(effectiveDate);
        }
        this.defaultEffectiveDate = this.datePipe.transform(
          new Date(effectiveDate),
          this.shortDateFormat,
        );
        if (!expiryDate) {
          // TODO check new date it will fail if user login from different location
          defaultEndDate =
            currentDate.endDate ??
            this.datePipe.transform(
              new Date(new Date().getTime() + 24 * 60 * 60 * 1000).setFullYear(
                new Date(
                  new Date().getTime() + 24 * 60 * 60 * 1000,
                ).getFullYear() + 1,
              ),
              this.shortDateFormat,
            );
          this.store.dispatch(
            new CreateQuoteAction.UpdateEndDateAction(defaultEndDate),
          );
        } else {
          defaultEndDate = new Date(expiryDate);
        }
        this.defaultExpiryDate = this.datePipe.transform(
          new Date(expiryDate),
          this.shortDateFormat,
        );
        this.minStartDate = new Date();
        this.maxStartDate = new Date();
        this.minStartDate.setDate(this.minStartDate.getDate() - 30);
        this.maxStartDate.setDate(
          this.maxStartDate.getDate() + EFFECTIVE_DATE_MAX_RANGE_LIMIT,
        );

        this.minEndDate = new Date(defaultStartDate);
        this.maxEndDate = new Date(defaultStartDate);
        this.minEndDate.setMonth(defaultStartDate.getMonth() + 8);
        this.maxEndDate.setMonth(defaultStartDate.getMonth() + 18);

        this.riskTrnxs = quoteData.data.flatMap((dataObj) => {
          const riskTrnxs = dataObj.PolicyRiskTrxes || {};
          return riskTrnxs;
        });
        this.riskTrnxs.forEach((trnx) => {
          this.policyRiskIds.push(trnx.policyRiskId);
          this.spinnerCounter++;
          this.boxxUserService
            .GetById(trnx.underwriterId)
            .subscribe((dataUW) => {
              this.underWriters = [
                ...this.underWriters,
                dataUW.data?.firstName + ' ' + dataUW.data?.lastName,
              ];
              if (this.underWriters.length === this.riskTrnxs.length) {
                this.productList.map((product: any) => {
                  if (product.hasSubProduct) {
                    this.disabledDropdownList = [
                      ...this.disabledDropdownList,
                      product.subProductName,
                    ];
                  }
                });
              }
              this.spinnerCounter--;
              if (this.spinnerCounter == 0) this.showBodySpinner = false;
            });
        });

        this.products = quoteData.data.flatMap((dataObj) => {
          const products = dataObj.risk.name || '';
          return products;
        });
        this.productsIds = quoteData.data.flatMap((dataObj) => {
          const productsIds = dataObj.risk.id || '';
          return productsIds;
        });

        const selectedQuoteOption =
          this.quoteOptions.filter(
            (a) => a.optionSelected == 1 && a.QuoteOptionServices.length > 0,
          ) ?? [];
        if (selectedQuoteOption.length > 0) {
          const riskIds = this.productsIds.join(',');
          let addons = [];
          this.spinnerCounter++;
          this.riskRegionService
            .getRiskServicesById(riskIds)
            .subscribe((dataAddons) => {
              addons = dataAddons.data?.map((item: any, index, arr) => ({
                description: item.description,
                id: item.id,
                serviceName: item.serviceName,
                riskId: item.risk.id,
                riskName: item.risk.name,
              }));

              if (addons.length > 0) {
                this.productList.forEach((item: any) => {
                  this.addons[item.key] = [];
                  var addonArr =
                    addons.filter((a) => a.riskId == item.riskId) ?? [];
                  addonArr.forEach((addon: any) => {
                    var riskServInd =
                      selectedQuoteOption[0].QuoteOptionServices.findIndex(
                        (a) => a.riskServicesId == addon.id,
                      );
                    if (riskServInd != -1) {
                      this.addons[item.key].push(addon);
                    }
                  });
                });
              }
              this.spinnerCounter--;
              if (this.spinnerCounter == 0) this.showBodySpinner = false;
            });
        }
        this.spinnerCounter++;
        this.domainsService
          .GetByDomainCode(TEMPLATETYPE)
          .subscribe((dataTemplate) => {
            this.templateCodeForEndorsement =
              dataTemplate.data.filter(
                (d) => d.subdomaincode == TEMPLATETYPE_ENDORSEMENT,
              )[0]?.id ?? -1;
            this.templateCodeForWordings =
              dataTemplate.data.filter(
                (d) => d.subdomaincode == TEMPLATETYPE_POLICYWORDING,
              )[0]?.id ?? -1;
            this.templateCodeForDeclaration =
              dataTemplate.data.filter(
                (d) => d.subdomaincode == TEMPLATETYPE_DECLARATION,
              )[0]?.id ?? -1;
            this.spinnerCounter++;
            this.domainsService
              .GetByDomainCode(DOCUMENTTYPE)
              .subscribe((dataDoc) => {
                this.documentCodeForEndorsement =
                  dataDoc.data.filter(
                    (d) => d.subdomaincode == DOCUMENTTYPE_ENDORSEMENT,
                  )[0]?.id ?? -1;
                this.documentCodeForWordings =
                  dataDoc.data.filter(
                    (d) => d.subdomaincode == DOCUMENTTYPE_POLICY,
                  )[0]?.id ?? -1;
                this.documentCodeForDeclaration =
                  dataDoc.data.filter(
                    (d) => d.subdomaincode == DOCUMENTTYPE_DECLARATION,
                  )[0]?.id ?? -1;

                quoteData.data.forEach((obj, indexval) => {
                  this.spinnerCounter++;
                  this.riskRegionService
                    .getRiskRegionByRegionId(
                      obj.policyPeriod.policy.insured.insuredLocation.regionId,
                      obj.risk.id,
                    )
                    .subscribe((dataRiskreg) => {
                      dataRiskreg.data.forEach((objRegion, indexReg) => {
                        this.riskRegionId = objRegion.id;

                        this.products.forEach((product, index) => {
                          // get subjectivities for each product
                          this.riskRegionSubjectivityService
                            .getSubjectivity(this.riskRegionId)
                            .subscribe((dataSub) => {
                              if (dataSub.data) {
                                this.subjectivityOptns[product] =
                                  dataSub.data.map((sub) => {
                                    return {
                                      key: sub.description,
                                      value: sub.description,
                                      disabled: !!sub.mandatory,
                                      mandatory: sub.mandatory,
                                      riskRegionSubjectivitiesId: sub.id ?? '',
                                      isCompliedWith: !!sub.isCompliedWith,
                                      selected: !!sub.mandatory,
                                      documentTblSubId: null,
                                    };
                                  });
                                this.subjectivityMandatory[product] =
                                  this.subjectivityOptns[product].filter(
                                    (sub) => sub.mandatory,
                                  );
                                this.selectedSubjectivities[product] =
                                  this.subjectivityMandatory[product].map(
                                    (sub) => ({
                                      ...sub,
                                      selected: true,
                                    }),
                                  );
                                this.spinnerCounter++;
                                // to get existing subjectivities
                                this.policySubjectivityService
                                  .getPolicyRiskSubjectivity(
                                    this.policyRiskIds[index],
                                  )
                                  .subscribe((dataSubExist) => {
                                    let selectedPolicySubj = {
                                      [product]: dataSubExist.data.map(
                                        (policySub) => {
                                          let subjOptn = this.subjectivityOptns[
                                            product
                                          ].filter(
                                            (subOptn) =>
                                              subOptn.riskRegionSubjectivitiesId ==
                                              policySub.riskRegionSubjectivitiesId,
                                          );
                                          if (
                                            !policySub.riskRegionSubjectivitiesId
                                          ) {
                                            subjOptn = [...subjOptn, policySub];
                                          }
                                          if (subjOptn.length > 0) {
                                            // subjOptn[0].disabled = true;
                                            return {
                                              key:
                                                subjOptn[0]?.key ||
                                                policySub?.description,
                                              value:
                                                subjOptn[0]?.key ||
                                                policySub?.description,
                                              disabled: true,
                                              mandatory: subjOptn[0]?.mandatory,
                                              selected: true,
                                              documentTblSubId: policySub?.id,
                                              riskRegionSubjectivitiesId:
                                                subjOptn[0]
                                                  ?.riskRegionSubjectivitiesId ??
                                                '',
                                              isCompliedWith:
                                                !!policySub?.isCompliedWith,
                                            };
                                          } else {
                                            return null;
                                          }
                                        },
                                      ),
                                    };
                                    if (
                                      selectedPolicySubj[product]?.length > 0
                                    ) {
                                      this.selectedSubjectivities[product] = [
                                        ...selectedPolicySubj[product],
                                      ];
                                    }
                                    this.spinnerCounter--;
                                    if (this.spinnerCounter == 0)
                                      this.showBodySpinner = false;
                                  });
                              }
                              this.spinnerCounter--;
                              if (this.spinnerCounter == 0)
                                this.showBodySpinner = false;
                            });
                          this.spinnerCounter++;
                          // get endorsement
                          this.riskTemplateService
                            .getRiskTemplate(
                              1,
                              this.riskRegionId,
                              this.templateCodeForEndorsement,
                            )
                            .subscribe((dataEnd) => {
                              this.endorsementOptions[product] =
                                dataEnd.data.map((endorsementDoc) => {
                                  return {
                                    key: endorsementDoc.templateDescription,
                                    value: endorsementDoc.templateDescription,
                                    disabled: !!endorsementDoc.mandatory,
                                    mandatory: endorsementDoc.mandatory,
                                    url: endorsementDoc.templatePath,
                                    templateType: endorsementDoc.templateType,
                                    policyRiskId: this.policyRiskIds[index],
                                    riskTemplateId: endorsementDoc.id,
                                    dataInjection: endorsementDoc.dataInjection,
                                  };
                                });
                              this.selectedEndorsements[product] =
                                this.endorsementOptions[product]
                                  .filter(
                                    (endorsement) => endorsement.mandatory,
                                  )
                                  .map((endorsement) => ({
                                    ...endorsement,
                                    selected: true,
                                  }));
                              this.spinnerCounter++;
                              this.policyRiskDocProcessService
                                .getPolicyRiskDocCore(this.policyRiskIds[index])
                                .subscribe((dataRiskTempExist) => {
                                  // get policy endorsement docs
                                  let selectedEndorsementDocs =
                                    dataRiskTempExist.data.filter(
                                      (doc) =>
                                        doc.templateType ==
                                        this.templateCodeForEndorsement,
                                    );
                                  let selectedEndorseDocs = {
                                    [product]: selectedEndorsementDocs.map(
                                      (doc) => {
                                        let endorsementDocDetails =
                                          this.endorsementOptions[
                                            product
                                          ].filter(
                                            (optn) =>
                                              optn.riskTemplateId ==
                                              doc?.riskTemplate.id,
                                          );

                                        return {
                                          key:
                                            endorsementDocDetails[0]?.key || '',
                                          value:
                                            endorsementDocDetails[0]?.value ||
                                            '',
                                          disabled:
                                            !!endorsementDocDetails[0]
                                              ?.mandatory,
                                          mandatory:
                                            endorsementDocDetails[0]?.mandatory,
                                          templateType:
                                            endorsementDocDetails[0]
                                              ?.templateType,
                                          policyRiskId:
                                            this.policyRiskIds[index],
                                          url:
                                            endorsementDocDetails[0]?.url ?? '',
                                          selected: true,
                                          documentTblEndorsId: doc?.id || '',
                                          riskTemplateId:
                                            endorsementDocDetails[0]
                                              ?.riskTemplateId ?? null,
                                        };
                                      },
                                    ),
                                  };

                                  if (selectedEndorseDocs[product].length > 0) {
                                    this.selectedEndorsements[product] = [
                                      ...selectedEndorseDocs[product],
                                    ];
                                  }
                                  this.spinnerCounter--;
                                  if (this.spinnerCounter == 0)
                                    this.showBodySpinner = false;
                                });
                              this.spinnerCounter--;
                              if (this.spinnerCounter == 0)
                                this.showBodySpinner = false;
                            });
                          this.spinnerCounter++;
                          // get wordings
                          this.riskTemplateService
                            .getRiskTemplate(
                              1,
                              this.riskRegionId,
                              this.templateCodeForWordings,
                            )
                            .subscribe((dataWord) => {
                              this.wordingsOptions[product] = dataWord.data.map(
                                (wordings) => {
                                  return {
                                    key: wordings.templateDescription,
                                    value: wordings.templateDescription,
                                    disabled: !!wordings.mandatory,
                                    mandatory: wordings.mandatory,
                                    url: wordings.templatePath,
                                    templateType: wordings.templateType,
                                    policyRiskId: this.policyRiskIds[index],
                                    riskTemplateId: wordings.id,
                                    dataInjection: wordings.dataInjection,
                                  };
                                },
                              );

                              this.selectedWordings[product] = this
                                .wordingsOptions[product]
                                ? this.wordingsOptions[product]
                                    .filter((wording) => wording.mandatory)
                                    .map((word, key) => ({
                                      ...word,
                                      selected: true,
                                    }))
                                : [];
                              this.spinnerCounter++;
                              this.policyRiskDocProcessService
                                .getPolicyRiskDocCore(this.policyRiskIds[index])
                                .subscribe((dataRiskTempExist) => {
                                  // get policy wordings docs
                                  let selectedWordingsDocs =
                                    dataRiskTempExist.data.filter(
                                      (doc) =>
                                        doc?.templateType ==
                                        this.templateCodeForWordings,
                                    );

                                  let selectedWordingDocs = {
                                    [product]: selectedWordingsDocs.map(
                                      (doc) => {
                                        let wordingDocDetails =
                                          this.wordingsOptions[product].filter(
                                            (optn) =>
                                              optn.riskTemplateId ==
                                              doc?.riskTemplate.id,
                                          );

                                        return {
                                          key: wordingDocDetails[0]?.key || '',
                                          value:
                                            wordingDocDetails[0]?.value || '',
                                          disabled:
                                            !!wordingDocDetails[0]?.mandatory,
                                          mandatory:
                                            wordingDocDetails[0]?.mandatory,
                                          templateType:
                                            wordingDocDetails[0]?.templateType,
                                          policyRiskId:
                                            this.policyRiskIds[index],
                                          url: wordingDocDetails[0]?.url ?? '',
                                          selected: true,
                                          documentTblWordId: doc?.id,
                                          riskTemplateId:
                                            wordingDocDetails[0]
                                              ?.riskTemplateId ?? null,
                                        };
                                      },
                                    ),
                                  };

                                  if (selectedWordingDocs[product].length > 0) {
                                    this.selectedWordings[product] =
                                      selectedWordingDocs[product];
                                  }
                                  this.spinnerCounter--;
                                  if (this.spinnerCounter == 0)
                                    this.showBodySpinner = false;
                                });
                              this.spinnerCounter--;
                              if (this.spinnerCounter == 0)
                                this.showBodySpinner = false;
                            });
                          this.spinnerCounter++;
                          // get Declarations
                          this.riskTemplateService
                            .getRiskTemplate(
                              1,
                              this.riskRegionId,
                              this.templateCodeForDeclaration,
                            )
                            .subscribe((dataWord) => {
                              this.declarationOptions[product] =
                                dataWord.data.map((declarations) => {
                                  return {
                                    key: declarations.templateDescription,
                                    value: declarations.templateDescription,
                                    disabled: !!declarations.mandatory,
                                    mandatory: declarations.mandatory,
                                    url: declarations.templatePath,
                                    templateType: declarations.templateType,
                                    policyRiskId: this.policyRiskIds[index],
                                    riskTemplateId: declarations.id,
                                    dataInjection: declarations.dataInjection,
                                  };
                                });

                              this.selectedDeclarations[product] = this
                                .declarationOptions[product]
                                ? this.declarationOptions[product]
                                    .filter((wording) => wording.mandatory)
                                    .map((word, key) => ({
                                      ...word,
                                      selected: true,
                                    }))
                                : [];
                              this.spinnerCounter++;
                              this.policyRiskDocProcessService
                                .getPolicyRiskDocCore(this.policyRiskIds[index])
                                .subscribe((dataRiskTempExist) => {
                                  // get policy declaration docs
                                  let selectedDeclarationsDocs =
                                    dataRiskTempExist?.data.filter(
                                      (doc) =>
                                        doc.templateType ==
                                        this.templateCodeForDeclaration,
                                    );

                                  let selectedDeclarationDocs = {
                                    [product]: selectedDeclarationsDocs.map(
                                      (doc) => {
                                        let declarationDocDetails =
                                          this.declarationOptions[
                                            product
                                          ].filter(
                                            (optn) =>
                                              optn.riskTemplateId ==
                                              doc?.riskTemplate.id,
                                          );

                                        return {
                                          key:
                                            declarationDocDetails[0]?.key || '',
                                          value:
                                            declarationDocDetails[0]?.value ||
                                            '',
                                          disabled:
                                            !!declarationDocDetails[0]
                                              ?.mandatory,
                                          mandatory:
                                            declarationDocDetails[0]?.mandatory,
                                          templateType:
                                            declarationDocDetails[0]
                                              ?.templateType,
                                          policyRiskId:
                                            this.policyRiskIds[index],
                                          url:
                                            declarationDocDetails[0]?.url ?? '',
                                          selected: true,
                                          documentTblDeclarationId: doc?.id,
                                          riskTemplateId:
                                            declarationDocDetails[0]
                                              ?.riskTemplateId ?? null,
                                        };
                                      },
                                    ),
                                  };

                                  if (
                                    selectedDeclarationDocs[product].length > 0
                                  ) {
                                    this.selectedDeclarations[product] =
                                      selectedDeclarationDocs[product];
                                  }
                                  this.spinnerCounter--;
                                  if (this.spinnerCounter == 0)
                                    this.showBodySpinner = false;
                                });
                              this.spinnerCounter--;
                              if (this.spinnerCounter == 0)
                                this.showBodySpinner = false;
                            });
                        });
                      });
                      this.spinnerCounter--;
                      if (this.spinnerCounter == 0)
                        this.showBodySpinner = false;
                    });
                });
                this.spinnerCounter--;
                if (this.spinnerCounter == 0) this.showBodySpinner = false;
              });
            this.spinnerCounter--;
            if (this.spinnerCounter == 0) this.showBodySpinner = false;
          });
        this.spinnerCounter--;
        if (this.spinnerCounter == 0) this.showBodySpinner = false;
      });
  }

  loadMoreUnderwriters(pageNumber) {
    if (this.underwriterTotalCount > this.underWriterOptions?.length) {
      this.populateUnderwriterOptions(pageNumber);
    }
  }

  populateUnderwriterOptions(page = 1) {
    const sortOrder = 'firstName:asc,lastName:asc';
    const systemUserState = 1;
    this.boxxUserService
      .GetUnderwriterlist(
        '',
        page,
        10,
        sortOrder,
        '',
        1,
        USER_ROLES.Underwriter,
        systemUserState,
      )
      .subscribe((data) => {
        const tableData = data.data?.map((dataObj) => ({
          key: dataObj.firstName + ' ' + dataObj.lastName,
          value: dataObj.firstName + ' ' + dataObj.lastName,
          id: dataObj.id,
        }));
        this.underWritersList = {
          ...this.underWritersList,
          ...data.data.reduce((accumulator, value) => {
            return {
              ...accumulator,
              [value.firstName + ' ' + value.lastName]: value,
            };
          }, {}),
        };
        this.underwriterTotalCount = data?.pagination?.totalRecords;
        this.underwriterTotalPages = data?.pagination?.totalPages;
        this.underWriterOptions = [...this.underWriterOptions, ...tableData];
      });
  }
  updateCompliance(sub: any, value: boolean) {
    sub.isCompliedWith = value;
  }
  handleBackBtn() {
    this.store.dispatch(new CreateQuoteAction.ResetIsNavigatedFromSummary());
    this.router.navigate(['/dashboard/quote/new/options'], {
      relativeTo: this.activatedRoute,
      skipLocationChange: true,
    });
  }

  getSelectedQuote(options) {
    return options.filter((option) => option.optionSelected == 1);
  }

  handleSubjectivitySearch(searchKey, product) {
    this.riskRegionSubjectivityService
      .getSubjectivity(this.riskRegionId, searchKey)
      .subscribe((data) => {
        this.subjectivityOptns[product] = data.data.map((sub) => {
          let selected = this.selectedSubjectivities[product].find(
            (x) =>
              x.riskRegionSubjectivitiesId &&
              x.riskRegionSubjectivitiesId === sub.id,
          )
            ? true
            : false;

          return {
            key: sub.description,
            value: sub.description,
            disabled: selected ? true : false,
            mandatory: sub.mandatory,
            riskRegionSubjectivitiesId: sub.id,
            isCompliedWith: !!sub.isCompliedWith,
            documentTblSubId: null,
          };
        });
      });
  }

  handleWordingSearch(searchKey, product) {
    const index = this.products.findIndex((p) => p == product);
    this.riskTemplateService
      .getRiskTemplate(
        1,
        this.riskRegionId,
        this.templateCodeForWordings,
        searchKey,
      )
      .subscribe((data) => {
        this.wordingsOptions[product] = data.data.map((doc) => {
          let selected = this.selectedWordings[product].find(
            (x) => x.riskTemplateId == doc.id,
          )
            ? true
            : false;

          return {
            key: doc.templateDescription,
            value: doc.templateDescription,
            disabled: selected ? true : false,
            mandatory: doc.mandatory,
            url: doc.templatePath,
            templateType: doc.templateType,
            policyRiskId: this.policyRiskIds[index],
            riskTemplateId: doc.id,
            documentTblWordId: null,
            dataInjection: doc.dataInjection,
          };
        });
      });
  }

  handleDeclarationSearch(searchKey, product) {
    const index = this.products.findIndex((p) => p == product);
    this.riskTemplateService
      .getRiskTemplate(
        1,
        this.riskRegionId,
        this.templateCodeForDeclaration,
        searchKey,
      )
      .subscribe((data) => {
        this.declarationOptions[product] = data.data.map((doc) => {
          let selected = this.selectedDeclarations[product].find(
            (x) => x.riskTemplateId == doc.id,
          )
            ? true
            : false;

          return {
            key: doc.templateDescription,
            value: doc.templateDescription,
            disabled: selected ? true : false,
            mandatory: doc.mandatory,
            url: doc.templatePath,
            templateType: doc.templateType,
            policyRiskId: this.policyRiskIds[index],
            riskTemplateId: doc.id,
            documentTblDeclarationId: null,
            dataInjection: doc.dataInjection,
          };
        });
      });
  }
  handleEndorsementSearch(searchKey, product) {
    const index = this.products.findIndex((p) => p == product);
    this.riskTemplateService
      .getRiskTemplate(
        1,
        this.riskRegionId,
        this.templateCodeForEndorsement,
        searchKey,
      )
      .subscribe((data) => {
        this.endorsementOptions[product] = data.data.map((endorsementDoc) => {
          let selected = this.selectedEndorsements[product].find(
            (x) => x.riskTemplateId == endorsementDoc.id,
          )
            ? true
            : false;

          return {
            key: endorsementDoc.templateDescription,
            value: endorsementDoc.templateDescription,
            disabled: selected ? true : false,
            mandatory: endorsementDoc.mandatory,
            url: endorsementDoc.templatePath,
            templateType: endorsementDoc.templateType,
            policyRiskId: this.policyRiskIds[index],
            riskTemplateId: endorsementDoc.id,
            documentTblEndorsId: null,
            dataInjection: endorsementDoc.dataInjection,
          };
        });
      });
  }

  toggleEndorsementSelect(index, product) {
    try {
      const isMandatory = this.selectedEndorsements[product][index].mandatory;
      if (!isMandatory) {
        const value = !this.selectedEndorsements[product][index]?.selected;
        const id =
          this.selectedEndorsements[product][index].documentTblEndorsId;
        this.selectedEndorsements[product][index] = {
          ...this.selectedEndorsements[product][index],
          selected: value,
        };
        if (id) {
          if (value === true) {
            this.policyRiskDocProcessService
              .restorePolicyRiskDocCore(id)
              .subscribe((resp) => {});
          } else {
            this.policyRiskDocProcessService
              .deletePolicyRiskDocCore(id)
              .subscribe((resp) => {});
          }
        }
      }
    } catch (error) {
      if (![500].includes(error?.status)) {
        this.notificationAlert = {
          type: 'error',
          headerText: 'error!',
          bodyText: error ?? '' ?? error?.message ?? '',
        };

        this.alertService.addAlert(this.notificationAlert);
      }
    }
  }

  toggleWordingSelect(index, product) {
    this.selectedWordings[product].forEach((item, ind) => {
      if (index == ind) {
        this.selectedWordings[product][ind].selected = true;
      } else {
        this.selectedWordings[product][ind].selected = false;
      }
    });
  }

  toggleDeclarationSelect(index, product) {
    this.selectedDeclarations[product].forEach((item, ind) => {
      if (index == ind) {
        this.selectedDeclarations[product][ind].selected = true;
      } else {
        this.selectedDeclarations[product][ind].selected = false;
      }
    });
  }
  handleToggleSubSelect(index, product, data) {
    try {
      const isMandatory = this.selectedSubjectivities[product][index].mandatory;
      if (!isMandatory) {
        const value = !this.selectedSubjectivities[product][index]?.selected;
        const id = this.selectedSubjectivities[product][index].documentTblSubId;
        if (value) {
          if (this.numOfSubjectivity(product) < 10) {
            this.selectedSubjectivities[product][index] = {
              ...this.selectedSubjectivities[product][index],
              selected: !this.selectedSubjectivities[product][index]?.selected,
            };
          } else {
            this.notificationAlert = {
              type: 'error',
              headerText: 'error!',
              bodyText: 'productSummary.error.subjectivityExceeded',
            };
            this.alertService.addAlert(this.notificationAlert);
            return;
          }
        } else {
          this.selectedSubjectivities[product][index] = {
            ...this.selectedSubjectivities[product][index],
            selected: !this.selectedSubjectivities[product][index]?.selected,
          };
        }
        if (id) {
          if (value === true) {
            this.policySubjectivityService
              .restorePolicyRiskSubjectivity(id)
              .subscribe(
                (resp) => {},
                (error) => {
                  this.notificationAlert = {
                    type: 'error',
                    headerText: 'error!',
                    bodyText: error ?? '' ?? error?.message ?? '',
                  };
                  this.alertService.addAlert(this.notificationAlert);
                },
              );
          } else if (this.isNewSubjectivity(product, data)) {
            this.deleteSubjectivity(product, data, index);
          } else {
            this.policySubjectivityService
              .deletePolicyRiskSubjectivity(id)
              .subscribe(
                (resp) => {},
                (error) => {
                  this.notificationAlert = {
                    type: 'error',
                    headerText: 'error!',
                    bodyText: error ?? '' ?? error?.message ?? '',
                  };
                  this.alertService.addAlert(this.notificationAlert);
                },
              );
          }
        }
      }
    } catch (error) {
      if (![500].includes(error?.status)) {
        this.notificationAlert = {
          type: 'error',
          headerText: 'error!',
          bodyText: error ?? '' ?? error?.message ?? '',
        };
        this.alertService.addAlert(this.notificationAlert);
      }
    }
  }

  underwriterChanged(underwriter: string, index) {
    this.productList.map((product: any) => {
      if (product.hasSubProduct) {
        let subProductIndex = this.products.findIndex(
          (prod) => prod === product.subProductName,
        );
        this.underWriters[index] = underwriter;
        this.underWriters[subProductIndex] = underwriter;
      } else {
        this.underWriters[index] = underwriter;
      }
    });
    let underwriterId;
    const policyRisk = this.policyRisks.find(
      (policyRisk) => policyRisk.riskId == this.productsIds[index],
    );
    if (policyRisk) {
      const policyRiskTrx = policyRisk.PolicyRiskTrxes[0];
      underwriterId =
        this.underWritersList[underwriter].id ?? policyRiskTrx?.underwriterId;
    }
    this.store.dispatch(
      new CreateQuoteAction.UpdateUnderwriterForPdtAction({
        index,
        underwriter,
        underwriterId,
      }),
    );
  }

  async getUnderwriter(i: string | number) {
    return this.underWriters[i];
  }

  handleAttachEndorsement(docName, product) {
    let endorsToAdd = this.endorsementOptions[product].filter(
      (sub) => sub.key == docName,
    )[0];
    endorsToAdd.disabled = true;
    if (Object.entries(endorsToAdd).length > 0) {
      endorsToAdd = {
        ...endorsToAdd,
        selected: true,
      };
    }
    var findInd = this.selectedEndorsements[product].findIndex(
      (a) => a.riskTemplateId == endorsToAdd.riskTemplateId,
    );
    if (findInd == -1)
      this.selectedEndorsements[product] = [
        ...this.selectedEndorsements[product],
        endorsToAdd,
      ];
  }

  handleAddSubjectives(name, product) {
    if (name.length < 3 || name.length > 500) {
      this.notificationAlert = {
        type: 'error',
        headerText: 'error!',
        bodyText: 'productSummary.error.subjectivityLength',
      };
      this.alertService.addAlert(this.notificationAlert);
      return;
    }
    if (this.numOfSubjectivity(product) < 10) {
      let subToAdd = this.subjectivityOptns[product].filter(
        (sub) => sub.key == name,
      )[0];
      subToAdd.disabled = true;
      if (Object.entries(subToAdd).length > 0) {
        subToAdd = {
          ...subToAdd,
          selected: true,
        };
      }
      var findInd = this.selectedSubjectivities[product].findIndex(
        (a) =>
          a.riskRegionSubjectivitiesId == subToAdd.riskRegionSubjectivitiesId,
      );
      if (findInd == -1)
        this.selectedSubjectivities[product] = [
          ...this.selectedSubjectivities[product],
          subToAdd,
        ];
    } else {
      this.notificationAlert = {
        type: 'error',
        headerText: 'error!',
        bodyText: 'productSummary.error.subjectivityExceeded',
      };
      this.alertService.addAlert(this.notificationAlert);
      return;
    }
  }

  handleSelectWording(word, product) {
    let wordToAdd = this.wordingsOptions[product].filter(
      (w) => w.key == word,
    )[0];
    wordToAdd.disabled = true;
    if (Object.entries(wordToAdd).length > 0) {
      wordToAdd = {
        ...wordToAdd,
        selected: true,
      };
    }
    var findInd = this.selectedWordings[product].findIndex(
      (a) => a.riskTemplateId == wordToAdd.riskTemplateId,
    );
    if (findInd == -1) {
      this.selectedWordings[product] = this.selectedWordings[product].map(
        (item) => {
          return { ...item, selected: false };
        },
      );
      if (this.selectedWordings[product][0].documentTblWordId)
        // Since there will be only one wording can be selected under each quote so that 0 is given.
        wordToAdd.documentTblWordId =
          this.selectedWordings[product][0].documentTblWordId;
      this.selectedWordings[product] = [wordToAdd];
    }
  }

  handleSelectDeclaration(declaration, product) {
    let declarationToAdd = this.declarationOptions[product].filter(
      (w) => w.key == declaration,
    )[0];
    declarationToAdd.disabled = true;
    if (Object.entries(declarationToAdd).length > 0) {
      declarationToAdd = {
        ...declarationToAdd,
        selected: true,
      };
    }
    var findInd = this.selectedDeclarations[product].findIndex(
      (a) => a.riskTemplateId == declarationToAdd.riskTemplateId,
    );
    if (findInd == -1) {
      this.selectedDeclarations[product] = this.selectedDeclarations[
        product
      ].map((item) => {
        return { ...item, selected: false };
      });
      if (this.selectedDeclarations[product][0].documentTblDeclarationId)
        // Since there will be only one declaration can be selected under each quote so that 0 is given.
        declarationToAdd.documentTblDeclarationId =
          this.selectedDeclarations[product][0].documentTblDeclarationId;
      this.selectedDeclarations[product] = [declarationToAdd];
    }
  }

  async saveSubjetivities(product, index) {
    return new Promise<any>(async (resolve, reject) => {
      let dataSubCreate = [];
      let dataSubUpdate = [];
      let selectedSubjCreate = this.selectedSubjectivities[product].filter(
        (s) => s.selected == true && !s.documentTblSubId,
      );
      let selectedSubjUpdate = this.selectedSubjectivities[product].filter(
        (s) => s.selected == true && s.documentTblSubId,
      );
      dataSubCreate = selectedSubjCreate.map((sub) => ({
        policyRiskId: this.policyRiskIds[index],
        riskRegionSubjectivitiesId: sub.riskRegionSubjectivitiesId,
        description: sub.key,
        isCompliedWith: !!sub.isCompliedWith,
      }));
      dataSubUpdate = selectedSubjUpdate.map((sub) => ({
        documentTblSubId: sub.documentTblSubId,
        isCompliedWith: !!sub.isCompliedWith,
      }));
      const payloadForSubjectivityCreate = {
        policyRiskSubjectivity: dataSubCreate,
      };

      if (dataSubCreate.length > 0) {
        this.policySubjectivityService
          .createPolicyRiskSubjectivity(payloadForSubjectivityCreate)
          .subscribe({
            next: (resp) => {
              this.isContinueFormValid = true;
              resolve(true);
            },
            error: (error) => {
              this.isContinueFormValid = false;
              this.notificationAlert = {
                type: 'error',
                headerText: 'error!',
                bodyText: getErrorMessage(error),
              };
              this.alertService.addAlert(this.notificationAlert);
              reject(getErrorMessage(error));
            },
          });
      }

      if (dataSubUpdate.length > 0) {
        dataSubUpdate.forEach((s) => {
          let dataSub = {
            isCompliedWith: s.isCompliedWith,
          };
          this.policySubjectivityService
            .updatePolicyRiskSubjectivity(s.documentTblSubId, dataSub)
            .subscribe({
              next: (resp) => {
                this.isContinueFormValid = true;
                resolve(true);
              },
              error: (error) => {
                this.isContinueFormValid = false;
                this.notificationAlert = {
                  type: 'error',
                  headerText: 'error!',
                  bodyText: getErrorMessage(error),
                };
                this.alertService.addAlert(this.notificationAlert);
                reject(getErrorMessage(error));
              },
            });
        });
      }
    });
  }

  saveEndorsements(product, index) {
    return new Promise<any>(async (resolve, reject) => {
      let endorsementDataCreate = [];
      let endorsementDataUpdate = [];

      let selectEndorsementsCreate = this.selectedEndorsements[product].filter(
        (s) => s.selected == true && !s.documentTblEndorsId,
      );
      let selectEndorsementsUpdate = this.selectedEndorsements[product].filter(
        (s) => s.selected == true && s.documentTblEndorsId,
      );
      endorsementDataCreate = selectEndorsementsCreate.map((endorsement) => ({
        riskTemplateId: endorsement.riskTemplateId,
        policyRiskId: this.policyRiskIds[index],
        documentName: endorsement.key,
        templateType: this.templateCodeForEndorsement,
        dataInjection: endorsement.dataInjection,
      }));
      endorsementDataUpdate = selectEndorsementsUpdate.map((endorsement) => ({
        riskTemplateId: endorsement.riskTemplateId,
        policyRiskId: this.policyRiskIds[index],
        documentName: endorsement.key,
        templateType: this.templateCodeForEndorsement,
        documentTblEndorsId: endorsement.documentTblEndorsId,
        dataInjection: endorsement.dataInjection,
      }));

      const payloadForPolicyRiskDocCreate = {
        policyRiskDocs: endorsementDataCreate,
      };

      if (endorsementDataCreate.length > 0) {
        this.policyRiskDocProcessService
          .createPolicyRiskDoc(payloadForPolicyRiskDocCreate)
          .subscribe({
            next: (resp) => {
              this.isContinueFormValid = true;
              resolve(true);
            },
            error: (error) => {
              this.isContinueFormValid = false;
              this.notificationAlert = {
                type: 'error',
                headerText: 'error!',
                bodyText: getErrorMessage(error),
              };
              this.alertService.addAlert(this.notificationAlert);
              reject(getErrorMessage(error));
            },
          });
      }
      if (endorsementDataUpdate.length > 0) {
        endorsementDataUpdate.forEach((e) => {
          this.policyRiskDocProcessService
            .updatePolicyRiskDoc(e.documentTblEndorsId, e)
            .subscribe({
              next: (resp) => {
                this.isContinueFormValid = true;
                resolve(true);
              },
              error: (error) => {
                this.isContinueFormValid = false;
                this.notificationAlert = {
                  type: 'error',
                  headerText: 'error!',
                  bodyText: getErrorMessage(error),
                };
                this.alertService.addAlert(this.notificationAlert);
                reject(getErrorMessage(error));
              },
            });
        });
      }
    });
  }

  saveWordings(product, index) {
    return new Promise<any>(async (resolve, reject) => {
      let selectWordings = this.selectedWordings[product].filter(
        (w) => w.selected == true,
      );
      let selectWordingsUpdate = this.selectedWordings[product].filter(
        (w) => w.documentTblWordId,
      );
      // req: there will be only one wording at a time to each product under quote
      if (selectWordings.length === 1) {
        const wordingsData = {
          riskTemplateId: selectWordings[0].riskTemplateId,
          policyRiskId: this.policyRiskIds[index],
          documentName: selectWordings[0].key,
          templateType: this.templateCodeForWordings,
          documentTblWordId: selectWordings[0].documentTblWordId,
          dataInjection: selectWordings[0].dataInjection,
        };
        const payloadForPolicyRiskDocCreate = {
          policyRiskDocs: [wordingsData],
        };

        if (selectWordingsUpdate.length === 0) {
          this.policyRiskDocProcessService
            .createPolicyRiskDoc(payloadForPolicyRiskDocCreate)
            .subscribe({
              next: (resp) => {
                this.isContinueFormValid = true;
                resolve(true);
              },
              error: (error) => {
                this.isContinueFormValid = false;
                this.notificationAlert = {
                  type: 'error',
                  headerText: 'error!',
                  bodyText: getErrorMessage(error),
                };
                this.alertService.addAlert(this.notificationAlert);
                reject(getErrorMessage(error));
              },
            });
        }
        if (selectWordingsUpdate.length === 1) {
          this.policyRiskDocProcessService
            .updatePolicyRiskDoc(
              selectWordingsUpdate[0].documentTblWordId,
              wordingsData,
            )
            .subscribe({
              next: (resp) => {
                this.isContinueFormValid = true;
                resolve(true);
              },
              error: (error) => {
                this.isContinueFormValid = false;
                this.notificationAlert = {
                  type: 'error',
                  headerText: 'error!',
                  bodyText: getErrorMessage(error),
                };
                this.alertService.addAlert(this.notificationAlert);
                reject(getErrorMessage(error));
              },
            });
        }
      }
    });
  }

  saveDeclarations(p, i) {
    return new Promise<any>(async (resolve, reject) => {
      let selectDeclarations = this.selectedDeclarations[p].filter(
        (w) => w.selected == true,
      );
      let selectDeclarationsUpdate = this.selectedDeclarations[p].filter(
        (w) => w.documentTblDeclarationId,
      );

      // req: there will be only one Declaration at a time to each product under quote
      if (selectDeclarations.length == 1) {
        const declarationsData = {
          riskTemplateId: selectDeclarations[0].riskTemplateId,
          policyRiskId: this.policyRiskIds[i],
          documentName: selectDeclarations[0].key,
          templateType: this.templateCodeForDeclaration,
          documentTblDeclarationId:
            selectDeclarations[0].documentTblDeclarationId,
          dataInjection: selectDeclarations[0].dataInjection,
        };

        const payloadForPolicyRiskDocCreate = {
          policyRiskDocs: [declarationsData],
        };
        if (selectDeclarationsUpdate.length === 0) {
          this.policyRiskDocProcessService
            .createPolicyRiskDoc(payloadForPolicyRiskDocCreate)
            .subscribe({
              next: (resp) => {
                this.isContinueFormValid = true;
                resolve(true);
              },
              error: (error) => {
                this.isContinueFormValid = false;
                this.notificationAlert = {
                  type: 'error',
                  headerText: 'error!',
                  bodyText: getErrorMessage(error),
                };
                this.alertService.addAlert(this.notificationAlert);
                reject(getErrorMessage(error));
              },
            });
        }
        if (selectDeclarationsUpdate.length === 1) {
          this.policyRiskDocProcessService
            .updatePolicyRiskDoc(
              selectDeclarationsUpdate[0].documentTblDeclarationId,
              declarationsData,
            )
            .subscribe({
              next: (resp) => {
                this.isContinueFormValid = true;
                resolve(true);
              },
              error: (error) => {
                this.isContinueFormValid = false;
                this.notificationAlert = {
                  type: 'error',
                  headerText: 'error!',
                  bodyText: getErrorMessage(error),
                };
                this.alertService.addAlert(this.notificationAlert);
                reject(getErrorMessage(error));
              },
            });
        }
      }
    });
  }

  async saveDrafUnderwriter(product, index) {
    const policyRisk = this.policyRisks.find(
      (policyRisk) => policyRisk.riskId == this.productsIds[index],
    );
    if (policyRisk) {
      const policyRiskTrx = policyRisk.PolicyRiskTrxes[0];
      const underwriter = await this.getUnderwriter(index);

      const riskTrxData = {
        underwriterId:
          this.underWritersList[underwriter]?.id ??
          policyRiskTrx?.underwriterId,
      };
      this.policyRiskTrxService
        .Update(policyRiskTrx.id, riskTrxData)
        .subscribe({
          next: (_) => {
            this.loadPageData();
          },
          error: (error) => {
            this.showSpinner = false;
            if (![500].includes(error?.status)) {
              this.notificationAlert = {
                type: 'error',
                headerText: 'error!',
                bodyText: getErrorMessage(error),
              };
              this.alertService.addAlert(this.notificationAlert);
            }
          },
        });
    }
  }
  async saveDateDetails() {
    let formData: any;
    this.form$.subscribe((event) => (formData = event));
    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        startDate: convertDate(formData.startDate, this.shortDateFormat) ?? '',
        endDate: convertDate(formData.endDate, this.shortDateFormat) ?? '',
      }),
    );

    if (
      this.defaultEffectiveDate !== formData.startDate ||
      this.defaultExpiryDate !== formData.endDate
    ) {
      // save dates
      const policyPeriodData = {
        effectiveDt: getFormatDate(
          formData.startDate,
          this.shortDateFormat,
          this.longDateFormat,
        ),
        expiryDt: getFormatDate(
          formData.endDate,
          this.shortDateFormat,
          this.longDateFormat,
        ),
      };
      this.policyPeriodService
        .Update(this.policyPeriodId, policyPeriodData)
        .pipe(take(1))
        .subscribe({
          next: (resp) => {
            this.isContinueFormValid = true;
            this.loadPageData();
          },
          error: (error) => {
            this.isContinueFormValid = false;
            this.showSpinner = false;
            if (![500].includes(error?.status)) {
              this.notificationAlert = {
                type: 'error',
                headerText: 'error!',
                bodyText: getErrorMessage(error),
              };
              this.alertService.addAlert(this.notificationAlert);
              return;
            }
          },
        });
    }
  }

  saveUnderwriter(i) {
    return new Promise<any>(async (resolve, reject) => {
      const policyRisk = this.policyRisks.find(
        (policyRisk) => policyRisk.riskId == this.productsIds[i],
      );
      if (policyRisk) {
        const policyRiskTrx = policyRisk.PolicyRiskTrxes[0];
        const underwriter = await this.getUnderwriter(i);
        const riskTrxData = {
          underwriterId:
            this.underWritersList[underwriter]?.id ??
            policyRiskTrx?.underwriterId,
        };

        this.policyRiskTrxService
          .Update(policyRiskTrx.id, riskTrxData)
          .subscribe({
            next: (resp) => {
              this.isContinueFormValid = true;
              resolve(true);
            },
            error: (error) => {
              if (![500].includes(error.status)) {
                this.notificationAlert = {
                  type: 'error',
                  headerText: 'error!',
                  bodyText: getErrorMessage(error),
                };
                this.alertService.addAlert(this.notificationAlert);
              }
              this.isContinueFormValid = false;
              this.showSpinner = false;
              reject(getErrorMessage(error));
            },
          });
      }
    });
  }

  async handleSaveDraft(e) {
    this.alerts = [];
    try {
      if (!this.isEndDateValid || !this.isStartDateValid) {
        this.notificationAlert = {
          type: 'error',
          headerText: 'error!',
          bodyText: 'error.pleaseSelectDateFields',
        };
        this.alertService.addAlert(this.notificationAlert);
        return;
      }
      //save date details
      this.saveDateDetails();

      this.products.forEach((p, i) => {
        this.showSpinner = true;
        // save subjectivities
        this.saveSubjetivities(p, i);
        // save endorsements
        this.saveEndorsements(p, i);
        // save wordings
        this.saveWordings(p, i);
        // save Declaration
        this.saveDeclarations(p, i);
        //save underwriter
        this.saveDrafUnderwriter(p, i);
      });
      this.showSpinner = false;
      this.notificationAlert = {
        type: 'success',
        headerText: 'success!',
        bodyText: 'productSummary.success.policyRiskUpdated',
      };
      this.alertService.addAlert(this.notificationAlert);
    } catch (error) {
      if (![500].includes(error?.status)) {
        this.notificationAlert = {
          type: 'error',
          headerText: 'error!',
          bodyText: error ?? '' ?? error?.message ?? '',
        };
        this.alertService.addAlert(this.notificationAlert);
      }
      this.showSpinner = false;
    }
  }

  handleNotificationAlertClose(index) {
    if (index >= 0 && index < this.alerts.length) {
      this.alerts.splice(index, 1);
    }
  }

  async handleContinue(e) {
    this.alerts = [];
    try {
      if (!this.isEndDateValid || !this.isStartDateValid) {
        this.notificationAlert = {
          type: 'error',
          headerText: 'error!',
          bodyText: 'error.pleaseSelectDateFields',
        };
        this.alertService.addAlert(this.notificationAlert);
        return;
      }
      //save date details
      await this.saveDateDetails();

      for (const [i, p] of this.products.entries()) {
        this.showContinueSpinner = true;
        if (
          this.selectedDeclarations[p].length == 0 &&
          this.selectedWordings.length == 0
        ) {
          this.wordingsDeclarationError = true;
          this.notificationAlert = {
            type: 'error',
            headerText: 'error!',
            bodyText: this.translate.instant(
              'productSummary.error.wordingDeclarationMandatory',
            ),
          };
          this.alertService.addAlert(this.notificationAlert);
          return;
        } else {
          this.wordingsDeclarationError = false;
          try {
            await Promise.all([
              this.saveEndorsements(p, i),
              this.saveUnderwriter(i),
              this.saveWordings(p, i),
              this.saveDeclarations(p, i),
              this.saveSubjetivities(p, i),
            ]);
            this.showContinueSpinner = false;

            if (this.wordingsDeclarationError || !this.isContinueFormValid) {
              return;
            }

            if (this.isContinueFormValid) {
              this.notificationAlert = {
                type: 'success',
                headerText: 'success!',
                bodyText: 'productSummary.success.policyRiskUpdated',
              };
              this.alertService.addAlert(this.notificationAlert);

              this.router.navigate(['../'], {
                relativeTo: this.activatedRoute,
                skipLocationChange: true,
              });
            }
          } catch (error) {
            this.showContinueSpinner = false;
            return;
          }
        }
      }
    } catch (error) {
      if (![500].includes(error?.status)) {
        this.notificationAlert = {
          type: 'error',
          headerText: 'error!',
          bodyText: error ?? '' ?? error?.message ?? '',
        };
        this.alertService.addAlert(this.notificationAlert);
      }
      this.showSpinner = false;
    }
  }

  toggleDocPreview() {
    this.showDocPreview = !this.showDocPreview;
  }

  handleDocClick(obj: any) {
    const templateId = obj?.riskTemplateId;
    try {
      this.showBodySpinner = true;
      if (templateId) {
        this.PolicyRiskDocPreviewService.GetPolicyRiskDocToPreview(
          templateId,
          'template',
        ).subscribe({
          next: (documentData) => {
            if (documentData?.data) {
              this.currentPreviewDocUrl = documentData.data.content;
              this.toggleDocPreview();
              this.showBodySpinner = false;
            } else {
              this.showDocPreview = false;
              this.currentPreviewDocUrl = '';
              this.showBodySpinner = false;
            }
          },
          error: (error) => {
            this.showSpinner = false;
            this.currentPreviewDocUrl = '';
            this.showBodySpinner = false;
            if (![500].includes(error?.status)) {
              this.notificationAlert = {
                type: 'error',
                headerText: 'error!',
                bodyText: getErrorMessage(error),
              };
              this.alertService.addAlert(this.notificationAlert);
            }
          },
        });
      } else {
        this.showDocPreview = false;
        this.currentPreviewDocUrl = '';
        this.showBodySpinner = false;
      }
    } catch (error) {
      if (![500].includes(error?.status)) {
        this.notificationAlert = {
          type: 'error',
          headerText: 'error!',
          bodyText: error?.message ?? '',
        };
        this.alertService.addAlert(this.notificationAlert);
      }
      this.showSpinner = false;
      this.currentPreviewDocUrl = '';
      this.showBodySpinner = false;
    }
  }
  checkIfStartDateValid(date) {
    const upperLimit = new Date().getTime() + 60 * 24 * 60 * 60 * 1000;
    const lowerLimit = new Date().getTime() - 30 * 24 * 60 * 60 * 1000;
    if (!date) {
      this.isStartDateValid = false;
      this.startDateErrMsg = 'error.thisFieldIsRequired';
    } else if (date > upperLimit) {
      this.isStartDateValid = false;

      this.startDateErrMsg = 'error.policyMaxEffectiveErrorMessage';
    } else if (date < lowerLimit) {
      this.isStartDateValid = false;

      this.startDateErrMsg = 'error.policyMinEffectiveDateErrorMessage';
    } else {
      this.isStartDateValid = true;
      this.startDateErrMsg = null;
    }
  }
  getTomorrow() {
    return new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  }
  handleStartDateChange(date, endDate) {
    this.checkIfStartDateValid(date);
    let getStartDate = getCityTime(this.insuredTimezone);

    if (getStartDate.eightMonthsLater && getStartDate.endDate) {
      this.minEndDate = getStartDate.eightMonthsLater;
      this.maxEndDate = getStartDate.endDate;
    } else {
      this.minEndDate = date ? new Date(date) : new Date();
      this.maxEndDate = date ? new Date(date) : new Date();

      this.minEndDate.setMonth(this.minEndDate.getMonth() + 8);
      this.maxEndDate.setMonth(this.maxEndDate.getMonth() + 18);
    }
    this.checkIfEndDateValid(endDate, date);
    if (date) {
      const formattedStartDate = this.datePipe.transform(
        new Date(date),
        this.shortDateFormat,
      );
      this.store.dispatch(
        new CreateQuoteAction.UpdateStartDateAction(formattedStartDate),
      );
      const formattedEndDate = this.datePipe.transform(
        new Date(date).setFullYear(date.getFullYear() + 1),
        this.shortDateFormat,
      );
      this.store.dispatch(
        new CreateQuoteAction.UpdateEndDateAction(formattedEndDate),
      );
      this.isEndDateValid = true;
      this.endDateErrMsg = null;
    } else {
      this.store.dispatch(
        new CreateQuoteAction.UpdateStartDateAction(this.defaultEffectiveDate),
      );
    }
  }

  checkIfEndDateValid(date, startDate) {
    if (!startDate) {
      startDate = this.getTomorrow();
    }

    let upperLimit = new Date(startDate);
    let lowerLimit = new Date(startDate);
    lowerLimit.setMonth(startDate.getMonth() + 8);
    upperLimit.setMonth(startDate.getMonth() + 18);
    if (!date) {
      this.isEndDateValid = false;
      this.endDateErrMsg = 'error.thisFieldIsRequired';
    } else if (date > upperLimit) {
      this.isEndDateValid = false;

      this.endDateErrMsg = 'error.policyMaxExpiryDateErrorMessage';
    } else if (date < lowerLimit) {
      this.isEndDateValid = false;

      this.endDateErrMsg = 'error.policyMinExpiryDateErrorMessage';
    } else {
      this.isEndDateValid = true;
      this.endDateErrMsg = null;
    }
  }

  handleEndDateChange(endDate, startDate) {
    if (startDate) {
      const startDateParts = startDate.split('/');
      startDate = new Date(startDate);
    } else {
      startDate = new Date();
    }

    this.checkIfEndDateValid(endDate, startDate);
    if (endDate) {
      const formattedEndDate = this.datePipe.transform(
        new Date(endDate),
        this.shortDateFormat,
      );
      this.store.dispatch(
        new CreateQuoteAction.UpdateEndDateAction(formattedEndDate),
      );
    } else {
      this.store.dispatch(
        new CreateQuoteAction.UpdateEndDateAction(this.defaultExpiryDate),
      );
    }
  }
  getFormattedDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return day + '/' + month + '/' + year;
  }
  createNewSubjectivity(data, product) {
    if (data.length < 3 || data.length > 500) {
      this.notificationAlert = {
        type: 'error',
        headerText: 'error!',
        bodyText: 'productSummary.error.subjectivityLength',
      };
      this.alertService.addAlert(this.notificationAlert);
      return;
    }

    if (this.numOfSubjectivity(product) < 10) {
      const isSubjectivityInDropdown = this.subjectivityOptns[product].filter(
        (subInDropdown) =>
          subInDropdown?.key?.toLowerCase() === data.toLowerCase(),
      );
      if (isSubjectivityInDropdown.length > 0) {
        this.notificationAlert = {
          type: 'error',
          headerText: 'error!',
          bodyText: 'productSummary.error.subjectivityAlreadyInDropdown',
        };
        this.alertService.addAlert(this.notificationAlert);
        return;
      }
      let subToAdd = {
        disabled: true,
        documentTblSubId: null,
        isCompliedWith: false,
        key: data,
        mandatory: false,
        riskRegionSubjectivitiesId: null,
        selected: true,
        value: data,
        edit: false,
      };
      var findAd = this.selectedSubjectivities[product].findIndex(
        (a) => a.key === subToAdd.key,
      );
      if (findAd == -1) {
        if (
          this.addedSubjectivities[product] &&
          this.addedSubjectivities[product].length > 0
        ) {
          var findInd = this.addedSubjectivities[product].findIndex(
            (a) => a.key === subToAdd.key,
          );
          if (findInd == -1) {
            this.selectedSubjectivities[product] = [
              ...this.selectedSubjectivities[product],
              subToAdd,
            ];
            this.addedSubjectivities[product] = [
              ...this.addedSubjectivities[product],
              subToAdd,
            ];
          } else {
            this.notificationAlert = {
              type: 'error',
              headerText: 'error!',
              bodyText: 'productSummary.error.subjectivityExists',
            };
            this.alertService.addAlert(this.notificationAlert);
          }
        } else {
          this.selectedSubjectivities[product] = [
            ...this.selectedSubjectivities[product],
            subToAdd,
          ];
          this.addedSubjectivities[product] = [subToAdd];
        }
      } else {
        this.notificationAlert = {
          type: 'error',
          headerText: 'error!',
          bodyText: 'productSummary.error.subjectivityExists',
        };
        this.alertService.addAlert(this.notificationAlert);
      }
    } else {
      this.notificationAlert = {
        type: 'error',
        headerText: 'error!',
        bodyText: 'productSummary.error.subjectivityExceeded',
      };
      this.alertService.addAlert(this.notificationAlert);
    }
  }
  editSubjectivity(product, data, active) {
    this.selectedSubjectivities[product] = this.selectedSubjectivities[
      product
    ].map((x) => {
      x.edit = false;
      if (active == 1) {
        if (x.key == data.key) {
          x.edit = true;
          this.editedSubjectivity = data.key;
        }
      }
      return x;
    });
  }
  deleteSubjectivity(product, data, index) {
    this.showBodySpinner = true;
    if (data.documentTblSubId) {
      this.policySubjectivityService
        .deletePolicyRiskSubjectivity(data.documentTblSubId)
        .subscribe(
          (resp) => {
            this.removeFromSubjectivityList(product, data);
          },
          (error) => {
            this.showBodySpinner = false;
            this.notificationAlert = {
              type: 'error',
              headerText: 'error!',
              bodyText: error ?? '' ?? error?.message ?? '',
            };
            this.alertService.addAlert(this.notificationAlert);
          },
        );
    } else {
      this.removeFromSubjectivityList(product, data);
    }
  }
  removeFromSubjectivityList(product, data) {
    var findAd = this.selectedSubjectivities[product].findIndex(
      (a) => a.key === data.key,
    );
    if (findAd >= 0) {
      this.selectedSubjectivities[product].splice(findAd, 1);
    }
    if (
      this.addedSubjectivities[product] &&
      this.addedSubjectivities[product].length > 0
    ) {
      var findInd = this.addedSubjectivities[product].findIndex(
        (x) => x.key == data.key,
      );
      if (findInd >= 0) {
        this.addedSubjectivities[product].splice(findInd, 1);
      }
    }
    this.subjectivityOptns[product].map((x) => {
      if (x.key == data.key) {
        x.disabled = false;
      }
    });
    this.showBodySpinner = false;
  }
  confirmEditSubjectivity(product, data) {
    if (!this.editedSubjectivity || this.editedSubjectivity === '') {
      this.notificationAlert = {
        type: 'error',
        headerText: 'error!',
        bodyText: 'productSummary.error.subjectivityEmpty',
      };
      this.alertService.addAlert(this.notificationAlert);
      return;
    }
    if (
      this.editedSubjectivity.length < 3 ||
      this.editedSubjectivity.length > 500
    ) {
      this.notificationAlert = {
        type: 'error',
        headerText: 'error!',
        bodyText: 'productSummary.error.subjectivityLength',
      };
      this.alertService.addAlert(this.notificationAlert);
      return;
    }
    this.selectedSubjectivities[product].some((x) => {
      if (x.key == this.editedSubjectivity) {
        if (x.key == data.key) {
          x.key = this.editedSubjectivity;
          x.value = this.editedSubjectivity;
          x.edit = false;
        } else {
          this.notificationAlert = {
            type: 'error',
            headerText: 'error!',
            bodyText: 'productSummary.error.sameSubjectivity',
          };
          this.alertService.addAlert(this.notificationAlert);
          return true;
        }
      } else {
        if (x.key == data.key) {
          x.key = this.editedSubjectivity;
          x.value = this.editedSubjectivity;
          x.edit = false;
        }
      }
      return false;
    });
  }
  isNewSubjectivity(product, data) {
    if (data.riskRegionSubjectivitiesId) {
      return false;
    } else {
      return true;
    }
  }
  numOfSubjectivity(product) {
    let selectedNum = 0;
    this.selectedSubjectivities[product].map((x) => {
      if (x.selected) {
        selectedNum++;
      }
    });
    return selectedNum;
  }
}
