import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { ViewportScroller } from '@angular/common';
import { Quote } from 'src/app/models/quote.model';
import { DomainsService } from 'src/app/services/domains.service';
import { InsuredService } from 'src/app/services/insured.service';
import { getQuoteSelector } from 'src/app/store/create-quote/create-quote.selector';
import * as InsuredAction from 'src/app/store/insured/insured.action';
import {
  arrayToObjet,
  getErrorMessage,
  regexHelpers,
} from 'src/app/utils/utils';
import { formatDateTime } from 'src/app/utils/formatDate';
import { DOMAIN_CODE_RANGENUMHOUSEMEMBERS } from 'src/app/constants/quote-constant';
import { Router } from '@angular/router';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-individual-insured-form',
  templateUrl: './individual-insured-form.component.html',
  styleUrls: ['./individual-insured-form.component.less'],
})
export class IndividualInsuredFormComponent
  implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy
{
  @Input() form: FormGroup;
  @Input() form$: Observable<Quote>;
  @Input() checkValidation: boolean = false;
  @Input() insuredId;
  @Input() continueButtonLabel;
  @Input() isActive: boolean = true;
  @Input() fromSubmissionFlow: boolean = false;
  @Input() allowLocationLoader: boolean = true;

  @Output() handleContinue = new EventEmitter<any>();
  @Output() updateBreadCrumbs = new EventEmitter<any>();
  @Output() handleErrorOrSuccess = new EventEmitter<any>();
  @Output() handleCompletedLocationLoading = new EventEmitter<any>();

  householdMemberOptns: any;
  householdMember: any;

  isInsuredTypeCompany: boolean = false;

  percentageRegex = regexHelpers.percentage;
  industryClass = {};
  selectedIndustryId = 0;
  insuredLocationId = null;
  insuredData = null;
  initialFormData = null;
  billingCompanyContactId = null;
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  insuredOptns: any = [];
  showBodySpinner: boolean = false;
  quoteFlowSteps;
  private ngUnsubscribe$ = new Subject<void>();
  shortDateTimeFormat: string = '';
  longDateTimeFormat: string = '';

  constructor(
    private store: Store,
    private viewportScroller: ViewportScroller,
    private insuredService: InsuredService,
    private domainsService: DomainsService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.isActive = this.permissionList[this.currentScreen] ?? false;
      this.shortDateTimeFormat = data.shortDateTimeFormat;
      this.longDateTimeFormat = data.longDateTimeFormat;
    });
    this.form$ = this.store.pipe(select(getQuoteSelector));

    this.form$.subscribe(
      (event) => (this.isInsuredTypeCompany = event.ui.isInsuredTypeCompany),
    );
    this.quoteFlowSteps = this.localStorageService.getQuoteFlowSteps();
    await this.domainsService
      .GetByDomainCode(DOMAIN_CODE_RANGENUMHOUSEMEMBERS)
      .subscribe(async (data) => {
        const tableData = data.data?.map((dataObj) => ({
          key: dataObj.description,
          value: dataObj.description,
          id: dataObj.id,
        }));

        this.householdMemberOptns = tableData;
        this.householdMember = arrayToObjet(data.data, 'description');
        if (this.insuredId) {
          await this.populateInsuredDetails(this.insuredId);
        }
      });

    this.store.dispatch(
      new InsuredAction.setIsNavigatedFromInsuredIndividual({
        isNavigatedFromIndividual: true,
      }),
    );
    if (this.fromSubmissionFlow === true) {
      this.form$.subscribe((event) => (this.insuredId = event.ui?.insuredId));
      if (this.insuredId) {
        this.populateInsuredDetails(this.insuredId);
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  async populateInsuredDetails(insuredId) {
    this.showBodySpinner = true;
    try {
      const getInsuredData = this.insuredService.GetByInsuredId(insuredId);
      let insuredData = await firstValueFrom(getInsuredData);
      const insured = insuredData?.data;
      if (insured) {
        const numOfHouseHoldMembers = this.householdMemberOptns.filter(
          (data) => data.id === insured?.numOfHouseholdMem,
        )?.[0]?.key;
        this.form.setValue({
          firstName: insured?.firstName ?? '',
          middleName: insured?.middleName ?? '',
          lastName: insured?.lastName ?? '',
          email: insured?.email ?? '',
          householdMembers: numOfHouseHoldMembers ?? '',
          country: insured?.insuredLocation?.country ?? '',
          province: insured?.insuredLocation?.region?.name ?? '',
          city: insured?.insuredLocation?.city ?? '',
          address: insured?.insuredLocation?.address ?? '',
          zipPostalCode: insured?.insuredLocation?.zipPostalCode ?? '',
          addressLine2: insured?.insuredLocation?.addressLine2 ?? '',
        });
        this.initialFormData = this.form.value;
        this.insuredData = insured;
        // format date for timestamp
        this.insuredData = {
          ...this.insuredData,
          updatedDt: formatDateTime(
            this.insuredData?.updatedDt,
            this.longDateTimeFormat,
          ),
          createdDt: formatDateTime(
            this.insuredData?.createdDt,
            this.longDateTimeFormat,
          ),
        };
        const insuredIndividualName =
          (insured?.firstName ?? '') + ' ' + (insured?.lastName ?? '');
        this.updateBreadCrumbs.emit(insuredIndividualName);
        this.insuredLocationId = insured?.insuredLocation?.id;
      }
      this.showBodySpinner = false;
    } catch (error) {
      if (![500].includes(error?.status)) {
        this.handleErrorOrSuccess.emit({
          show: true,
          type: 'error',
          header: 'common.errorMsg',
          body: getErrorMessage(error),
        });
      }
    }
    this.showBodySpinner = false;
  }

  hasFormValueChanged(): boolean {
    const stringifyAndSort = (obj: any): string =>
      JSON.stringify(obj, Object.keys(obj).sort());

    const normalizeObject = (obj: any): any =>
      JSON.parse(JSON.stringify(obj), (key, value) =>
        typeof value === 'string' && !isNaN(Number(value))
          ? this.roundNumber(Number(value), 2)
          : value,
      );

    const normalizedObj1 = normalizeObject(this.form.value);
    const normalizedObj2 = normalizeObject(this.initialFormData);

    const stringifiedObj1 = stringifyAndSort(normalizedObj1);
    const stringifiedObj2 = stringifyAndSort(normalizedObj2);

    return stringifiedObj1 !== stringifiedObj2;
  }
  roundNumber(value: number, precision: number): number {
    const factor = Math.pow(10, precision);
    return Math.round(value * factor) / factor;
  }

  onHandlerIndividualRegionIdSelected(regionId: number) {}
  completedLocationLoading(loadStatus: boolean) {
    this.handleCompletedLocationLoading.emit(loadStatus);
  }

  handleNoOfEmployees($event) {}

  async deactivateOrReactivateInsured() {
    if (this.insuredData.active) {
      try {
        const deleteInsured = this.insuredService.Delete(this.insuredId);
        const getDeleteResponse = await firstValueFrom(deleteInsured);
        this.handleErrorOrSuccess.emit({
          show: true,
          type: 'success',
          header: 'common.successHeader',
          body: 'insured.success.insuredDeactivated',
        });
      } catch (error) {
        if (![500].includes(error?.status)) {
          this.handleErrorOrSuccess.emit({
            show: true,
            type: 'error',
            header: 'common.errorMsg',
            body: getErrorMessage(error),
          });
        }
      }
    } else {
      try {
        const restoreInsured = this.insuredService.Restore(this.insuredId);
        const getRestoreResponse = await firstValueFrom(restoreInsured);
        this.handleErrorOrSuccess.emit({
          show: true,
          type: 'success',
          header: 'common.successHeader',
          body: 'insured.success.insuredReactivated',
        });
      } catch (error) {
        if (![500].includes(error?.status)) {
          this.handleErrorOrSuccess.emit({
            show: true,
            type: 'error',
            header: 'common.errorMsg',
            body: getErrorMessage(error),
          });
        }
      }
    }
    this.populateInsuredDetails(this.insuredId);
  }

  addOrUpdateIndividualInsured(event) {
    if (
      this.insuredId &&
      this.fromSubmissionFlow === false &&
      !(this.insuredData.active && this.hasFormValueChanged())
    ) {
      return;
    }
    this.checkValidation = true;
    if (this.form.valid) {
      const houseHoldMembersId = this.householdMemberOptns.filter(
        (data) => data.key === this.form.value?.householdMembers,
      )?.[0]?.id;
      const insuredId = this.insuredData?.id ?? 0;
      this.handleContinue.emit({
        numberOfHouseholdMembersId: houseHoldMembersId ?? '',
        initialFormData: this.initialFormData,
        insuredLocationId: this.insuredLocationId,
        insuredId,
      });
    } else {
      if (this.form.controls['firstName'].hasError('maxlength')) {
        this.setMaxLengthErrorMessage('firstName');
      } else if (this.form.controls['middleName'].hasError('maxlength')) {
        this.setMaxLengthErrorMessage('middleName');
      } else if (this.form.controls['lastName'].hasError('maxlength')) {
        this.setMaxLengthErrorMessage('lastName');
      } else if (this.form.controls['email'].hasError('maxlength')) {
        this.setMaxLengthErrorMessage('email');
      } else if (this.form.controls['address'].hasError('maxlength')) {
        this.setMaxLengthErrorMessage('address');
      } else if (this.form.controls['addressLine2'].hasError('maxlength')) {
        this.setMaxLengthErrorMessage('addressLine2');
      } else if (this.form.controls['city'].hasError('maxlength')) {
        this.setMaxLengthErrorMessage('city');
      } else if (this.form.controls['zipPostalCode'].hasError('maxlength')) {
        this.setMaxLengthErrorMessage('zipPostalCode');
      }
    }
  }
  setMaxLengthErrorMessage(fieldName: string) {
    const charLimit =
      this.form.controls[fieldName].errors['maxlength']?.requiredLength ?? 0;
    const errMsg =
      charLimit !== 0
        ? this.translateService
            .instant('error.maximumAllowedCharErroMsg')
            ?.replace('{charLimit}', charLimit)
        : this.translateService.instant(
            'error.maximumAllowedCharDefaultErroMsg',
          );
    this.form.controls[fieldName].setErrors({
      message: errMsg,
    });
  }
  handleExitFlow() {
    let isMock = 'true';
    if (
      this.localStorageService.getMockExternalAPIs() === 'false' ||
      environment.mockExternalAPIs === false
    ) {
      isMock = 'false';
    }
    this.router.navigate(['/dashboard/home'], {
      queryParams: {
        mock: isMock,
      },
      skipLocationChange: true,
    });
  }
  handleSearch(query: string) {
    if (query) query = query.trim();
    if (query.length >= 3) {
      const page = 1;
      const limit = 10;
      const sort = '';
      let insuredType = 1;
      this.insuredService
        .GetByInsuredNameAndType(query, insuredType, page, limit, sort)
        .subscribe((response: any) => {
          this.insuredOptns = response.data.map((dataObj) => ({
            main_text: dataObj.firstName,
            id: dataObj.id,
          }));
        });
    } else {
      this.insuredOptns = [];
    }
  }
}
