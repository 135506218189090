<div class="stepper" *ngIf="steps && showProgressBar">
  <div
    *ngFor="let step of steps; let i = index"
    class="step"
    [ngClass]="{ completed: i < completedSteps, active: i === activeStep - 1 }"
  >
    <span class="label"> {{ step }}</span>
  </div>
  <div class="progress-bar">
    <div
      class="progress"
      [ngClass]="'completed-steps-' + completedSteps"
      [style.--progress-percentage]="progressPercentage"
    ></div>
  </div>
</div>
