import { createFeatureSelector, createSelector } from '@ngrx/store';
import { stores } from '..';
import { state } from '@angular/animations';

export const createQuoteSelector = createFeatureSelector<stores>('stores');

export const getQuoteSelector = createSelector(
  createQuoteSelector,
  (state: stores) => state.stateQuote,
);
export const getInsuredCompanySelector = createSelector(
  createQuoteSelector,
  (state: stores) =>
    state.stateQuote.insureds.map((item) => {
      return {
        Name: item.companyName,
        Website: item.companyWebsite,
        Address: item.insuredLocation ? item.insuredLocation.fullAddress : '',
      };
    }),
);

export const getInsuredIndividualSelector = createSelector(
  createQuoteSelector,
  (state: stores) =>
    state.stateQuote.insureds.map((item) => {
      return {
        Name: item.firstName + ' ' + item.lastName,
        Email: item.email ? item.email : '',
        Address: item.insuredLocation ? item.insuredLocation.fullAddress : '',
      };
    }),
);

export const getPaginationSelector = createSelector(
  createQuoteSelector,
  (state: stores) => state.stateQuote.pagination,
);

export const getDashboardPath = createSelector(
  createQuoteSelector,
  (state: stores) => state.stateQuote,
);

export const getAllMailIds = createSelector(
  createQuoteSelector,
  (state: stores) => state.stateQuote.mailIds,
);

export const getRiskQuestions = createSelector(
  createQuoteSelector,
  (state: stores) => state.stateQuote.riskQuestions,
);
export const getRiskCoverages = createSelector(
  createQuoteSelector,
  (state: stores) => state.stateQuote.riskCoverages,
);

export const getSelectedQuoteOption = createSelector(
  createQuoteSelector,
  (state: stores) => {
    const selectedQuoteOption = state.stateQuote.quoteOptions.filter(
      (quoteOption) => quoteOption?.ui?.optionSelected,
    );
    if (selectedQuoteOption.length > 0) {
      return selectedQuoteOption[0];
    }
    return state.stateQuote.quoteOptions[0];
  },
);

export const getSanctionsData = createSelector(
  createQuoteSelector,
  (state: stores) => {
    return {
      industry: state.stateQuote.ui?.industryName ?? '',
      insured: state.stateQuote.ui?.insuredName ?? '',
      clearance: state.stateQuote.ui?.clearance ?? [],
      isRestrictedIndustry: state.stateQuote.ui?.isRestricted ?? false,
    };
  },
);

export const getPolicyId = createSelector(
  createQuoteSelector,
  (state: stores) => state.stateQuote.policyId,
);
export const getUiPolicyID = createSelector(
  createQuoteSelector,
  (state: stores) => state.stateQuote.ui.policyId,
);
export const getSharedData = createSelector(
  createQuoteSelector,
  (state: stores) => state.stateQuote.shared,
);

export const getUIData = createSelector(
  createQuoteSelector,
  (state: stores) => state.stateQuote.ui,
);
