<div class="branch-information">
  <div class="max-width-wrapper">
    <!-- <div class="alert-holder">
      <boxxds-alert-v2
        *ngIf="alertData?.show"
        [type]="alertData?.type"
        [headerText]="alertData?.header | translate"
        [bodyText]="alertData?.body | translate"
        (handleCloseEvent)="handleAlertClose()"
      ></boxxds-alert-v2>
    </div> -->
    <!-- brokerage information -->
    <form class="producer-form-container" [formGroup]="form">
      <div class="producer-details-section">
        <div class="left-container">
          <div class="title-description-container">
            <boxxds-body
              [text]="'Producer information' | translate"
              [customStyle]="{
                color: '#292933',
                fontFamily: 'Gibson',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '20px'
              }"
            >
            </boxxds-body>
            <boxxds-body
              [text]="'Enter the producer information.' | translate"
              [customStyle]="{
                color: '#475467',
                fontFamily: 'Gibson',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '20px'
              }"
            >
            </boxxds-body>
          </div>
        </div>
        <div class="right-container">
          <div class="producer-details-form">
            <boxxds-textinput-v2
              [inputAutoFocus]="true"
              [form]="form"
              [placeholder]="
                'brokerage.producer.placeholder.enterFirstName' | translate
              "
              [control]="'firstName'"
              [id]="'first-name'"
              [width]="'100%'"
              [label]="'Name' | translate"
              [isRequired]="true"
              [requiredErrMsg]="
                'brokerage.producer.error.requiredErrMsg' | translate
              "
              [invalidErrMsg]="
                'brokerage.producer.error.invalidErrMsg' | translate
              "
              [formSubmitted]="submitted"
              [style]="{ width: '100%' }"
              [readonly]="!this.permissionList[this.currentScreen] || !isActive"
              [isDisabled]="
                !this.permissionList[this.currentScreen] || !isActive
              "
            >
            </boxxds-textinput-v2>

            <boxxds-textinput-v2
              [form]="form"
              [placeholder]="
                'brokerage.producer.placeholder.enterLastName' | translate
              "
              [control]="'lastName'"
              [id]="'last-name'"
              [width]="'100%'"
              [label]="'brokerage.producer.label.lastName' | translate"
              [isRequired]="true"
              [requiredErrMsg]="
                'brokerage.producer.error.requiredErrMsg' | translate
              "
              [invalidErrMsg]="
                'brokerage.producer.error.invalidErrMsg' | translate
              "
              [formSubmitted]="submitted"
              [style]="{ width: '100%' }"
              [readonly]="!this.permissionList[this.currentScreen] || !isActive"
              [isDisabled]="
                !this.permissionList[this.currentScreen] || !isActive
              "
            >
            </boxxds-textinput-v2>

            <boxxds-textinput-v2
              [form]="form"
              [placeholder]="'olivia@untitledui.com'"
              [control]="'email'"
              [id]="'email'"
              [width]="'100%'"
              [label]="'brokerage.producer.label.email' | translate"
              [leftIcon]="'assets/img/email.svg'"
              [requiredErrMsg]="
                'brokerage.producer.error.requiredErrMsg' | translate
              "
              [isRequired]="true"
              [invalidErrMsg]="
                'brokerage.producer.error.enterAValidEmail' | translate
              "
              [formSubmitted]="submitted"
              [style]="{ width: '100%' }"
              [readonly]="!this.permissionList[this.currentScreen] || !isActive"
              [isDisabled]="
                !this.permissionList[this.currentScreen] || !isActive
              "
            >
            </boxxds-textinput-v2>

            <boxxds-textinput-v2
              [form]="form"
              [mask]="'000-000-0000'"
              [placeholder]="'111-111-1111'"
              [control]="'phone'"
              [id]="'phoneNumber'"
              [width]="'100%'"
              [label]="'Phone' | translate"
              [requiredErrMsg]="
                'brokerage.producer.error.requiredErrMsg' | translate
              "
              [invalidErrMsg]="
                'brokerage.producer.error.invalidErrMsg' | translate
              "
              [isRequired]="true"
              [formSubmitted]="submitted"
              [style]="{ width: '100%' }"
              [readonly]="!this.permissionList[this.currentScreen] || !isActive"
              [isDisabled]="
                !this.permissionList[this.currentScreen] || !isActive
              "
            >
            </boxxds-textinput-v2>

            <boxxds-textinput-v2
              [form]="form"
              [placeholder]="'Enter license number' | translate"
              [control]="'licenseNumber'"
              [id]="'licenseNumber'"
              [width]="'100%'"
              [label]="'brokerage.producer.label.licenseNumber' | translate"
              [invalidErrMsg]="
                'brokerage.producer.error.invalidLicenseNumber' | translate
              "
              [formSubmitted]="submitted"
              [customErrMsg]="
                'brokerage.producer.error.noLicenseNumber' | translate
              "
              [style]="{ width: '100%' }"
              [readonly]="!this.permissionList[this.currentScreen] || !isActive"
              [isDisabled]="
                !this.permissionList[this.currentScreen] || !isActive
              "
            >
            </boxxds-textinput-v2>

            <boxxds-datepicker-v2
              [placeholder]="
                'brokerage.producer.placeholder.selectDate' | translate
              "
              [label]="'brokerage.producer.label.licenseExpiryDate' | translate"
              [showErr]="errorExpiry().show"
              [errMsg]="errorExpiry().msg | translate"
              [bsValue]="form.value['licenseExpiry']"
              [dateFormat]="shortDateFormat"
              (bsValueChange)="handleExpiryDate($event)"
              [style]="{ width: '100%' }"
              [isDisabled]="
                !this.permissionList[this.currentScreen] || !isActive
              "
            >
            </boxxds-datepicker-v2>

            <div class="profile-image">
              <div class="image-container">
                <img [src]="croppedImage" alt="Avatar" class="avatar" />
              </div>
              <div class="image-handler-section">
                <label class="drag-n-drop-wrapper">
                  <div class="drag-n-drop">
                    <div class="upload-icon-holder">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M6.66602 13.3333L9.99935 10M9.99935 10L13.3327 13.3333M9.99935 10V17.5M16.666 13.9524C17.6839 13.1117 18.3327 11.8399 18.3327 10.4167C18.3327 7.88536 16.2807 5.83333 13.7493 5.83333C13.5673 5.83333 13.3969 5.73833 13.3044 5.58145C12.2177 3.73736 10.2114 2.5 7.91602 2.5C4.46424 2.5 1.66602 5.29822 1.66602 8.75C1.66602 10.4718 2.36222 12.0309 3.48847 13.1613"
                          stroke="#2D2D39"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <h1>Click to upload <span>or drag and drop</span></h1>
                    <h6>JPG, JPEG, PNG or GIF (max. 800x400px)</h6>
                  </div>
                  <input
                    #fileInput
                    type="file"
                    (click)="fileInput.value = ''"
                    (change)="onImageSelected($event)"
                    accept="image/*"
                  />
                </label>
              </div>
            </div>
            <dialog id="myDialog" #mi>
              <form method="dialog">
                <header>
                  <h2>{{ "producerPhoto" | translate }}</h2>
                </header>
                <main>
                  <image-cropper
                    [resizeToWidth]="256"
                    [cropperMinWidth]="128"
                    [imageChangedEvent]="imageChangedEvent"
                    [aspectRatio]="4 / 4"
                    format="png"
                    (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()"
                    (loadImageFailed)="loadImageFailed()"
                    *ngIf="imageChangedEvent"
                  ></image-cropper>
                </main>
                <footer>
                  <span class="profile-note">{{
                    "workFlow3.brokerageProfile.message.profilePictureNoteV2"
                      | translate
                  }}</span>
                  <menu>
                    <button
                      id="dialog-footer--confirm-btn"
                      class="dialog-submit-btn"
                      (click)="selectedImage()"
                    >
                      {{ "common.confirm" | translate }}
                    </button>
                    <button
                      id="close_dialog"
                      class="dialog-close-btn"
                      (click)="closeImageModal(); mi.close()"
                    >
                      {{ "common.close" | translate }}
                    </button>
                  </menu>
                </footer>
              </form>
            </dialog>
          </div>
        </div>
      </div>
      <div class="producer-access-toggle-button-section">
        <div class="left-container"></div>
        <div class="right-container">
          <div class="access-button-wrapper">
            <boxxds-switch-v2
              [disabled]="false"
              name="system-access-switch"
              id="system-access-switch"
              label="This producer will have system access"
              (changed)="handleSystemAccess()"
              [checked]="hasSystemAccess"
              [disabled]="
                (!producerSelected.active &&
                  !this.permissionList[this.currentScreen]) ||
                !isActive
              "
            >
            </boxxds-switch-v2>
          </div>
        </div>
      </div>
      <div class="producer-action-buttons-section">
        <div class="left-container"></div>
        <div class="right-container">
          <div class="action-buttons-container">
            <boxxds-button-v2
              [buttonText]="'Discard' | translate"
              [type]="'submit'"
              [isDisabled]="!!!this.isFormDirty && !isSystemAccessUpdated()"
              [size]="'md'"
              [btnClass]="'link gray'"
              [btnType]="'default'"
              [isDropdown]="false"
              (handleClick)="
                (!!!!this.isFormDirty || isSystemAccessUpdated()) &&
                  handleDiscardChanges($event)
              "
              [btnStyle]="{ padding: '10px 30px' }"
            ></boxxds-button-v2>

            <boxxds-button-v2
              [buttonText]="'Save' | translate"
              [type]="'submit'"
              [isDisabled]="!!!this.isFormDirty && !isSystemAccessUpdated()"
              [size]="'md'"
              [btnClass]="'secondary gray'"
              [btnType]="'default'"
              [isDropdown]="false"
              (handleClick)="
                (!!!!this.isFormDirty || isSystemAccessUpdated()) &&
                  handleSubmit($event)
              "
              [btnStyle]="{ padding: '10px 30px' }"
            ></boxxds-button-v2>

            <boxxds-button-v2
              [buttonText]="'Continue to add commission ' | translate"
              [type]="'submit'"
              [isDisabled]="false"
              [size]="'md'"
              [btnClass]="'primary'"
              [btnType]="'default'"
              [isDropdown]="false"
              (handleClick)="handleProducerChangeStatus($event)"
              [btnStyle]="{ padding: '10px 30px' }"
              [isDisabled]="producerSaveClicked"
            ></boxxds-button-v2>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
