import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Pagination } from 'src/app/entities/boxx-response';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  excelFileDownload,
  getAlertHead,
  getErrorMessage,
  getStatusMappingForStyling,
} from 'src/app/utils/utils';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { take } from 'rxjs';
import { LocationService } from 'src/app/services/location.service';
import { RiskQuestionService } from 'src/app/services/risk-question.service';
import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import { BrokerageService } from 'src/app/services/brokerage.service';
import { BrokerageProducerService } from 'src/app/services/brokerage-producer.service';
import { BrokerageBranchService } from 'src/app/services/brokerage-branch.service';
import { BrokerageDirectoryService } from 'src/app/services/brokerage-directory.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-brokerage-directory-workflow3',
  templateUrl: './brokerage-directory-workflow3.component.html',
  styleUrls: ['./brokerage-directory-workflow3.component.less'],
})
export class BrokerageDirectoryWorkflow3Component implements OnInit {
  form: FormGroup;
  filterFormGroup: FormGroup;
  searchOptions: Array<any> = [
    { name: 'Brokerage', value: 'brokerage', checked: true, id: 'brokerage' },
    { name: 'Branch', value: 'branch', checked: true, id: 'branch' },
    { name: 'Producer', value: 'producer', checked: true, id: 'producer' },
  ];
  data: {
    Name: any;
    Parent?: any;
    Type?: any;
    Status: any;
    brokerageId: number | string;
    branchId: number | string;
    producerId: number | string;
    Actions: any;
  }[] = [
    {
      Name: '',
      Parent: '',
      Type: '',
      Status: '',
      brokerageId: '',
      branchId: '',
      producerId: '',
      Actions: '',
    },
  ];
  totalDataCount = 0;
  isSearchResult: boolean = false;
  addSortTo: string = 'Name';
  tableHeader = [
    {
      Name: '',
      Parent: '',
      Type: '',
      Status: '',
      brokerageId: '',
      branchId: '',
      producerId: '',
      Actions: '',
    },
  ];
  linkDetails: any[];
  sortBy = 'createdDt:desc,id:asc';
  pageNumber = 1;
  searchWord = '';
  userID: number;
  showTblSpinner: boolean = false;
  showErrorAlert = false;
  msgErrorAlert = '';
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  statusMappingToType = getStatusMappingForStyling();
  subNavData;
  pdtOptions;
  tableData = [];
  hasError: boolean = false;
  errorMessage: string = '';
  hasSuccess: boolean = false;
  successMessage: string = '';
  showBodySpinner: boolean = false;
  showFilter = false;
  products;
  filterForm: FormGroup;
  notificationAlert: {
    show?: boolean;
    bodyText?: string;
    type?: string;
    headerText?: string;
  } = { show: false };
  currency = '';
  sortableColumns: string[] = ['Name'];
  searchValue: string = '';
  columnsToHide: string[] = ['brokerageId', 'branchId', 'producerId'];
  columnHeadsToHide: string[] = [
    'brokerageId',
    'branchId',
    'producerId',
    'Actions',
  ];
  iconColumns: string[] = ['Actions'];
  pagination: Pagination = {
    currentPage: 1,
    totalPages: 1,
    totalRecords: 0,
    hasNext: false,
    hasPrevious: false,
  };
  breadCrumbArray = [
    { label: 'Members', path: '/dashboard/workflow3/brokerage/directory' },
    {
      label: 'Brokerage Directory',
      path: '/dashboard/workflow3/brokerage/directory',
    },
  ];
  searchEnabled: boolean = false;
  showFilterPopup: boolean = false;
  filterInputOptions;
  filterSelectedOptions: any;
  filteredOptions = [];
  isRestrictedRoleSubmission: boolean = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public locationService: LocationService,
    public riskQuestionService: RiskQuestionService,
    private store: Store,
    private localStorageService: LocalStorageService,
    public brokerageService: BrokerageService,
    public brokerageProducerService: BrokerageProducerService,
    public brokerageBranchService: BrokerageBranchService,
    public brokerageDirectoryService: BrokerageDirectoryService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private alertService: AlertService,
  ) {
    this.filterForm = this.fb.group({
      quoteFrom: [''],
      quoteTo: [''],
      quotedPeriod: [''],
      brokerageBranchProd: [[]],
      underwriter: [[]],
      status: [[]],
      products: [[]],
      insuredName: [''],
    });
  }

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.isRestrictedRoleSubmission = data.isRestrictedRoleSubmission;
    });

    this.showTblSpinner = true;
    this.userID = this.localStorageService.getBoxxUserId();
    this.form = this.fb.group({
      searchBy: new FormArray([]),
      searchKey: [''],
    });
    this.subNavData = [
      { name: 'View all', active: true, value: 'All' },
      { name: 'Brokerages', active: false, value: 'Brokerages' },
      { name: 'Branches', active: false, value: 'Branches' },
      { name: 'Producers', active: false, value: 'Producers' },
    ];
    this.userID = this.localStorageService.getBoxxUserId();
    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));

    this.listBrokerageDirectories();
  }

  async handleSearch(event) {
    this.searchEnabled = true;
    this.isSearchResult = this.form.value['searchKey'].trim().length !== 0;
    this.addSortTo = 'Name';
    const searchKey = this.sanitizeSearchInput(this.form.value['searchKey']);
    this.pageNumber = 1;
    this.filteredOptions = [];
    this.filterSelectedOptions = undefined;
    if (
      event.key == 'Enter' &&
      searchKey?.length >= 3 &&
      searchKey?.length <= 45
    ) {
      this.showTblSpinner = true;
      this.searchWord = searchKey;
      this.listBrokerageDirectories();
    } else if (searchKey?.length === 0 && event.key !== 'Tab') {
      this.showTblSpinner = true;
      this.searchWord = searchKey;
      this.listBrokerageDirectories();
    } else {
      this.showTblSpinner = false;
      let sanitizedtext = this.sanitizeSearchInput(
        this.form.value['searchKey'],
      );
      this.form.patchValue({
        searchKey: sanitizedtext,
      });
    }
  }
  listBrokerageDirectories(page = 1, limit = 10) {
    this.showTblSpinner = true;
    let filter = this.makeFilter();
    this.brokerageDirectoryService
      .GetBrokerageDirectories(
        page,
        limit,
        this.sortBy,
        filter.brokerageSelected,
        filter.branchSelected,
        filter.producerSelected,
        filter.name,
        false,
        filter.activeSearch,
      )
      .subscribe({
        next: (response) => {
          const tableData =
            response.data.length === 0
              ? this.tableHeader
              : response.data?.map((dataObj) => ({
                  Name: this.getFormatName(dataObj),
                  Parent: this.getPathColumn(dataObj),
                  Type: dataObj?.entity,
                  Status: dataObj?.active === true ? 'Active' : 'Inactive',
                  brokerageId: this.getIdValue(dataObj, 'Brokerage'),
                  branchId: this.getIdValue(dataObj, 'Branch'),
                  producerId: this.getIdValue(dataObj, 'Producer'),
                  Actions: '',
                }));
          this.showTblSpinner = false;
          this.data = tableData;
          this.totalDataCount = response.pagination.totalRecords;
          this.pagination = response.pagination;
        },
        error: (error) => {
          this.showTblSpinner = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
  }

  handleEditBrokerage(selectedindex): void {
    const brokerageId = this.data[selectedindex]?.brokerageId ?? '';
    const branchId = this.data[selectedindex]?.branchId ?? '';
    const producerId = this.data[selectedindex]?.producerId ?? '';
    const type = this.data[selectedindex].Type ?? '';
    if (type === '') {
      return;
    } else {
      if (type === 'Brokerage' && brokerageId !== '') {
        this.router.navigate(
          [`/dashboard/workflow3/brokerage/profile/${brokerageId}`],
          {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          },
        );
        return;
      } else if (type === 'Branch' && branchId !== '') {
        this.router.navigate(
          [
            `/dashboard/workflow3/brokerage/branch/producer/profile/${branchId}`,
          ],
          {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          },
        );
        return;
      } else if (
        type === 'Producer' &&
        producerId !== '' &&
        branchId !== '' &&
        brokerageId !== ''
      ) {
        this.router.navigate(
          [
            `/dashboard/workflow3/brokerage/producer/profile/${producerId}/${branchId}/${brokerageId}`,
          ],
          {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          },
        );
        return;
      } else return;
    }
  }
  handleViewBrokerage(selectedindex): void {
    const brokerageId = this.data[selectedindex]?.brokerageId ?? '';
    const branchId = this.data[selectedindex]?.branchId ?? '';
    const producerId = this.data[selectedindex]?.producerId ?? '';
    const type = this.data[selectedindex].Type ?? '';
    if (type === '') {
      return;
    } else {
      if (type === 'Brokerage' && brokerageId !== '') {
        this.router.navigate(
          [`/dashboard/workflow3/brokerage/profile/${brokerageId}`],
          {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          },
        );
        return;
      } else if (type === 'Branch' && branchId !== '') {
        this.router.navigate(
          [
            `/dashboard/workflow3/brokerage/branch/producer/profile/${branchId}`,
          ],
          {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          },
        );
        return;
      } else if (
        type === 'Producer' &&
        producerId !== '' &&
        branchId !== '' &&
        brokerageId !== ''
      ) {
        this.router.navigate(
          [
            `/dashboard/workflow3/brokerage/producer/profile/${producerId}/${branchId}/${brokerageId}`,
          ],
          {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          },
        );
        return;
      } else return;
    }
  }

  getFilteredValues() {
    let filteredOptions = [];
    let filterText = '';

    if (this.filterSelectedOptions) {
      if (this.filterSelectedOptions.brokerageName) {
        filterText = this.filterSelectedOptions?.brokerageName;
        filteredOptions.push({ key: 'brokerageName', value: filterText });
      }
      if (
        this.filterSelectedOptions?.brokerageType &&
        this.filterSelectedOptions?.brokerageType !== -1
      ) {
        filterText = this.filterSelectedOptions?.brokerageType;
      }
      if (
        typeof this.filterSelectedOptions.status !== 'undefined' &&
        this.filterSelectedOptions.status !== ''
      ) {
        let status =
          this.filterSelectedOptions.status === 1 ? 'Active' : 'Inactive';
        filterText = status;
        filteredOptions.push({ key: 'status', value: filterText });
      }
      this.filteredOptions = filteredOptions;
    }
    return this.filteredOptions;
  }

  handleFilterOptnRemove(option) {
    if (
      this.filterSelectedOptions &&
      this.filterSelectedOptions.hasOwnProperty(option)
    ) {
      delete this.filterSelectedOptions[option];
    }
    // this.listBrokerageDirectories(1, 10, this.sortBy);
    this.listBrokerageDirectories();
    this.getFilteredValues();
  }

  handleAddNewBrokerage() {
    this.router.navigate(['/dashboard/workflow3/brokerage/profile'], {
      skipLocationChange: true,
    });
  }

  handleNav(index: number) {
    const currentActiveIdx = this.subNavData.findIndex(
      (navObj: { name: string; active: boolean; value: string }) =>
        navObj.active,
    );
    if (currentActiveIdx !== index) {
      let currentStatusFilters =
        this.filterForm.value['brokerageBranchProd'] ?? [];
      if (currentStatusFilters.includes(this.subNavData[index].value)) {
        this.filterForm.controls['brokerageBranchProd'].setValue([
          `${this.subNavData[index].value}`,
        ]);
      } else {
        this.filterForm.controls['brokerageBranchProd'].setValue([]);
      }
      this.subNavData[currentActiveIdx].active = false;
      this.subNavData[index].active = true;
      this.filteredOptions = [];
      this.filterSelectedOptions = undefined;
      this.setSearchOptions();
      this.listBrokerageDirectories();
    }
  }

  setSearchOptions(): any {
    const currentActiveIdx = this.subNavData.findIndex(
      (navObj: { name: string; active: boolean; value: string }) =>
        navObj.active,
    );
    this.searchOptions[0].checked =
      currentActiveIdx == 0 || currentActiveIdx == 1;
    this.searchOptions[1].checked =
      currentActiveIdx == 0 || currentActiveIdx == 2;
    this.searchOptions[2].checked =
      currentActiveIdx == 0 || currentActiveIdx == 3;
  }

  pageChangeHandler(pageNumber = 1) {
    if (pageNumber > 0) {
      this.showTblSpinner = true;
      this.pageNumber = pageNumber;
      this.setSearchOptions();
      this.listBrokerageDirectories(pageNumber);
    }
  }

  mapSortColumns(key: string) {
    let sortColumn: string;
    switch (key) {
      case 'Name':
        sortColumn = 'name';
        break;

      case 'Type':
        sortColumn = 'entity';
        break;

      case 'Status':
        sortColumn = 'active';
        break;

      default:
        sortColumn = 'id';
        break;
    }
    return sortColumn;
  }

  handleFilter() {
    this.searchWord = null;
    this.showFilterPopup = true;
    this.populateFilterOptions();
    this.createFilterFormGroup();
  }

  populateFilterOptions() {
    const currentActiveIdx = this.subNavData.findIndex(
      (navObj: { name: string; active: boolean; value: string }) =>
        navObj.active,
    );

    let activeTab =
      currentActiveIdx === 0
        ? -1
        : currentActiveIdx === 1
        ? 1
        : currentActiveIdx === 2
        ? 2
        : currentActiveIdx === 3
        ? 3
        : -1;

    if (this.filterSelectedOptions) {
      let defaultOptions = 0;
      let filteredOptions = this.filterInputOptions.map((option) => {
        const { value } = option;
        const selectedValue = this.filterSelectedOptions[value];

        if (selectedValue !== undefined && selectedValue !== '') {
          return { ...option, selectedValue, isDefault: true };
        }

        return { ...option, selectedValue, isDefault: false };
      });
      if (defaultOptions < 3) {
        filteredOptions = filteredOptions.map((option) => {
          if (defaultOptions < 3) {
            if (!option.isDefault) {
              defaultOptions++;
              return { ...option, isDefault: true };
            }
          }
          return { ...option };
        });
      }
      this.filterInputOptions = filteredOptions;
    } else {
      this.filterInputOptions = [
        {
          label: 'Name',
          value: 'brokerageName',
          placeholder: 'ABC Company',
          type: 'text',
          disabled: false,
          isDefault: true,
          selectedValue: '',
          hasValidation: false,
          isRequired: false,
        },
        {
          label: 'Type',
          value: 'brokerageType',
          type: 'dropdown',
          disabled: false,
          isDefault: true,
          selectedValue: activeTab,
          dropdownOptions: [
            { id: -1, key: 'View All', value: 'View All' },
            { id: 1, key: 'Brokerages', value: 'Brokerages' },
            { id: 2, key: 'Branches', value: 'Branches' },
            { id: 3, key: 'Producers', value: 'Producers' },
          ],
          isRequired: true,
          hasValidation: true,
        },
        {
          label: 'Status',
          value: 'status',
          type: 'dropdown',
          disabled: false,
          isDefault: true,
          selectedValue: '',
          dropdownOptions: [
            { id: 1, key: 'Active', value: 'Active' },
            { id: 2, key: 'Inactive', value: 'Inactive' },
          ],
          isRequired: false,
          hasValidation: false,
        },
      ];
    }
  }

  createFilterFormGroup() {
    const formGroup = {};
    this.filterInputOptions.forEach((control) => {
      const isRequired = control.hasValidation ? Validators.required : '';
      let selectedValue = control.selectedValue ? control.selectedValue : '';
      formGroup[control.value] = [selectedValue, isRequired];
    });
    this.filterFormGroup = this.fb.group(formGroup);
  }

  handleExport(): void {
    this.showTblSpinner = true;
    const searchKey = this.sanitizeSearchInput(this.form.value['searchKey']);
    this.searchWord = this.searchWord ?? searchKey;

    let filters = this.makeFilter();
    this.brokerageDirectoryService
      .ExportBrokerageDirectoryDetails(
        1,
        this.totalDataCount,
        this.sortBy,
        this.searchOptions[0].checked,
        this.searchOptions[1].checked,
        this.searchOptions[2].checked,
        filters.name,
        filters,
      )
      .subscribe({
        next: (response) => {
          excelFileDownload('ExportedData.xlsx', response);
          this.showTblSpinner = false;
        },
        error: (error) => {
          this.showTblSpinner = false;
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
  }

  handleNotificationAlertClose() {
    this.notificationAlert.show = false;
  }
  handleCloseErrorEvent() {
    this.hasError = false;
    this.errorMessage = '';
  }
  handleCloseSuccessEvent() {
    this.hasSuccess = false;
    this.successMessage = '';
  }
  getFormatName(dataObj) {
    let name: string = '';
    switch (dataObj?.entity) {
      case 'Brokerage':
        name = dataObj?.brokerage?.name;
        break;

      case 'Branch':
        name = dataObj.brokerageBranch?.name;
        break;
      case 'Producer':
        name = dataObj.brokerageProducer?.name;
        break;

      default:
        break;
    }
    return name;
  }

  getIdValue(dataObj, entity) {
    let id: number | string = '';
    switch (entity) {
      case 'Brokerage':
        id = dataObj?.brokerage?.id;
        break;

      case 'Branch':
        id = dataObj?.brokerageBranch?.id;
        break;
      case 'Producer':
        id = dataObj?.brokerageProducer?.id;
        break;

      default:
        break;
    }
    return id;
  }

  getPathColumn(dataObj) {
    let path = '';
    switch (dataObj?.entity) {
      case 'Brokerage':
        path = 'Main';
        break;
      case 'Branch':
        path = dataObj?.brokerage?.name;
        break;
      case 'Producer':
        path =
          dataObj?.brokerage?.name + ' / ' + dataObj?.brokerageBranch?.name;
        break;
      default:
        break;
    }
    return path;
  }

  sanitizeSearchInput(userInput: string): string {
    const specialCharacters = /[\^\$\\*+?.`/~()!=|[\]{}%#]/g;
    return userInput.replace(specialCharacters, '').replace(/^[ _]/, '');
  }

  handleSorting(key: any) {
    this.sortBy = `${this.mapSortColumns(key.column)}:${key.sort},id:asc`;
    this.setSearchOptions();
    this.listBrokerageDirectories();
  }

  handleApplyFilter(event) {
    this.filterSelectedOptions = {};
    this.form.controls['searchKey'].setValue('');
    this.searchWord = '';
    this.filterSelectedOptions = event;
    this.showFilterPopup = false;
    if (event) {
      // this.makeFilter(this.pagination.currentPage, this.filterSelectedOptions);
      this.listBrokerageDirectories();
    }
  }
  handleFilterClear(event) {
    this.filterSelectedOptions = {};
    this.filteredOptions = [];
    this.form.controls['searchKey'].setValue('');
    this.searchWord = '';
    this.filterSelectedOptions = event;
    this.listBrokerageDirectories();
  }
  makeFilter() {
    let name = this.searchWord
      ? this.searchWord
      : this.filterSelectedOptions?.brokerageName?.trim();
    let brokerageSelected =
      this.filterSelectedOptions?.brokerageType === 1 ||
      this.filterSelectedOptions?.brokerageType === -1 ||
      this.searchOptions[0].checked;
    let branchSelected =
      this.filterSelectedOptions?.brokerageType === 2 ||
      this.filterSelectedOptions?.brokerageType === -1 ||
      this.searchOptions[1].checked;
    let producerSelected =
      this.filterSelectedOptions?.brokerageType === 3 ||
      this.filterSelectedOptions?.brokerageType === -1 ||
      this.searchOptions[2].checked;
    let activeSearch = '',
      status = '';
    // todo: need to filter record based on status when name field have some valid input
    // if (name && name.length === 0)
    if (this.filterSelectedOptions?.status === 1) {
      activeSearch = 'active=true';
      status = 'true';
    } else if (this.filterSelectedOptions?.status === 2) {
      activeSearch = 'active=false';
      status = 'false';
    }

    return {
      brokerageSelected: brokerageSelected,
      branchSelected: branchSelected,
      producerSelected: producerSelected,
      name: name,
      activeSearch: activeSearch,
      active: status,
    };
  }

  handleCloseFilterPopup(event) {
    this.showFilterPopup = false;
  }

  handleGlobalSearch(object) {
    if (object.searchIn === 'Insured name') {
      let insuredType = 1;
      if (object?.type?.toLowerCase() == 'company') {
        insuredType = 2;
      }
      this.router.navigateByUrl(
        `dashboard/search/${insuredType}/${object.selectedSearch.value}/${object.selectedSearch.id}`,
        { skipLocationChange: true },
      );
    } else {
      if (this.isRestrictedRoleSubmission === true) {
        this.alertService.clearAlerts(-1);
        const alertData = {
          type: 'warn',
          headerText: getAlertHead('warning'),
          bodyText: this.translateService.instant(
            'common.error.noAccessToPageErrorMsg',
          ),
        };
        this.alertService.addAlert(alertData);
        return;
      }
      this.router.navigateByUrl(
        `dashboard/workflow3/policies/${object.selectedSearch.value}`,
        { skipLocationChange: true },
      );
    }
  }
}
