import { Component, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Pagination } from 'src/app/entities/boxx-response';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { Router, ActivatedRoute } from '@angular/router';
import { BrokerageDirectoryService } from 'src/app/services/brokerage-directory.service';
import { BrokerageBranchService } from 'src/app/services/brokerage-branch.service';
import { getErrorMessage, excelFileDownload } from 'src/app/utils/utils';
import { getFormGroup } from './factorys/formGroup.factory';
import { AlertService } from 'src/app/services/alert.service';
import { LocationService } from 'src/app/services/location.service';
import { BrokerageService } from 'src/app/services/brokerage.service';

@Component({
  selector: 'app-branch-profile',
  templateUrl: './branch-profile.component.html',
  styleUrls: ['./branch-profile.component.less'],
})
export class BranchProfileComponent {
  @Input() brokerageId: number;
  @Input() badgeData: string = '0 Records';
  @Input() addBranchForm: boolean = false;
  @Input() isBrokerageActive: boolean = true;

  placeholder = 'Search name';
  leftIcon = 'assets/img/search-md.svg';
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  shortDateFormat: string = '';
  longDateFormat: string = '';
  userID: number;
  showTblSpinner: boolean = false;
  showErrorAlert = false;
  msgErrorAlert = '';
  data: any;
  pagination: Pagination;
  searchWord;
  mainHeading: string = '';
  showBodySpinner: boolean = false;

  //Navigation
  subNavData = [
    { name: 'Brokerage information', active: false, value: 'Information' },
    { name: 'Commission rates', active: false, value: 'Commission' },
    { name: 'Branches', active: true, value: 'Branches' },
  ];
  tableHeader = [{ Name: '', Contact: '', State: '', Status: '', Actions: '' }];
  columnsToHide: string[] = ['id', 'brokerageId'];
  columnHeadsToHide: string[] = ['Actions'];
  sortableColumns: string[] = ['Name', 'Status'];
  iconColumns: string[] = ['Actions'];

  showFilter: boolean = false;
  filterForm: FormGroup;
  totalRecords: number = 0;
  pageNumber = 1;
  sortBy = 'createdDt:desc,id:desc';

  btnLabel = 'Add branch';
  showAddButton: boolean = true;
  btnClass;
  btnLeftImage = 'assets/img/plus-circle-dark.png';

  //Form
  //addBranchForm: boolean = false;
  editBranchForm: boolean = false;

  form: FormGroup;
  filterFormGroup: FormGroup;
  searchForm: FormGroup;
  submitted: boolean = false;
  operationSuccess = false;
  hasError = false;
  errorMessage = '';
  locationRegionIdSelected: number = 0;
  showSpinner = false;
  requiredErrMsg: string = 'This field is required';
  invalidErrMsg: string = 'This field is invalid';
  isActive: boolean;
  isBrokerActive: any;

  multiContentColumns = {
    Contact: ['email', 'phone'],
  };
  activeTab = 1;
  filterInputOptions;
  filterSelectedOptions: any;
  stateList = [];
  regionTotalRecords = 0;
  regionTotalPages = 0;
  filteredOptions = [];
  searchKey = '';
  filters: { [x: string]: any } = {};
  activeStatus: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private fb: FormBuilder,
    public brokerageDirectoryService: BrokerageDirectoryService,
    public brokerageBranchService: BrokerageBranchService,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private locationService: LocationService,
    private brokerageService: BrokerageService,
  ) {
    this.searchForm = fb.group({
      searchBy: new FormArray([]),
      searchKey: [''],
    });
  }

  async ngOnInit(): Promise<void> {
    this.brokerageService.GetBrokerage(this.brokerageId).subscribe((data) => {
      this.activeStatus = data.data.active;
    });
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    this.showBodySpinner = true;
    this.userID = this.localStorageService.getBoxxUserId();
    this.form = getFormGroup(this.fb);

    await Promise.all([await this.loadBranches()]);
    await this.getRegionList();
  }

  getRegionList(page = 1, limit = 100) {
    return new Promise<void>((resolve) => {
      this.locationService.getAllRegions(page, limit).subscribe((region) => {
        const regions = region.data.map((item: any) => {
          const data = { id: item.id, name: item.name, code: item.regionCode };
          return data;
        });
        const stateList = regions.map((item: any) => {
          return { key: item.name, value: item.code, id: item.id };
        });
        this.stateList = [...stateList];
        resolve();
      });
    });
  }

  loadBranches(page = 1, limit = 10) {
    return new Promise<any>(async (resolve, reject) => {
      let filter = this.makeFilter(page, limit);
      this.brokerageBranchService
        .GetBrokerageBranchesByFilter(filter, this.sortBy)
        .subscribe({
          next: (response) => {
            if (response.pagination.totalRecords > 0) {
              const tableData =
                response.data.length === 0
                  ? this.tableHeader
                  : response.data?.map((dataObj) => ({
                      Name: dataObj.name,
                      Contact: {
                        email: `${
                          dataObj.email ? `${dataObj.email}` : `-----`
                        }`,
                        phone: dataObj.telephone ?? '',
                      },
                      State: dataObj.location.region.name,
                      id: dataObj.id,
                      brokerageId: dataObj.brokerage.id,
                      Status: dataObj.active === true ? 'Active' : 'Inactive',
                      Actions: '',
                    }));

              this.data = tableData;
              this.showTblSpinner = false;
              this.showBodySpinner = false;
              // resolve(true);
            } else {
              this.showBodySpinner = false;
              this.showAddButton = true;
              this.data = this.tableHeader;
            }
            this.showTblSpinner = false;
            this.totalRecords = response.pagination.totalRecords;
            this.pagination = response.pagination;
            this.pageNumber = this.pagination.currentPage;
            resolve(true);
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.showBodySpinner = false;
            this.msgErrorAlert = getErrorMessage(error);
            reject(getErrorMessage(error));
          },
        });
    });
  }

  //Navigation
  handleNav(event, index) {
    this.getRegionList();
    const currentActiveIdx = this.subNavData.findIndex(
      (navObj: { name: string; active: boolean; value: string }) =>
        navObj.active,
    );
    if (currentActiveIdx != index) {
      let currentStatusFilters = this.filterForm.value['status'] ?? [];
      if (currentStatusFilters.includes(this.subNavData[index].value)) {
        this.filterForm.controls['status'].setValue([
          `${this.subNavData[index].value}`,
        ]);
      } else {
        this.filterForm.controls['status'].setValue([]);
      }
      this.subNavData[currentActiveIdx].active = false;
      this.subNavData[index].active = true;
      //this.loadResults(1, 10, this.sortingKeys);
    }
  }

  handleExport() {
    this.showTblSpinner = true;
    let filters: { [x: string]: any } = this.filters;

    filters['brokerageId'] = this.brokerageId;

    const searchKey = this.sanitizeSearchInput(
      this.searchForm.value['searchKey'],
    );
    this.searchWord = searchKey ?? this.filters['name'] ?? '';

    if (this.totalRecords > 0) {
      this.brokerageBranchService
        .ExportBrokerageBranchDetails(filters, this.totalRecords, this.sortBy)
        .subscribe({
          next: (response) => {
            excelFileDownload('ExportedData.xlsx', response);
            this.showTblSpinner = false;
          },
          error: (error) => {
            this.showTblSpinner = false;
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    } else {
      const alertData = {
        type: 'info',
        headerText: 'Info',
        bodyText: 'No records to export',
      };
      this.alertService.addAlert(alertData);
      this.showTblSpinner = false;
    }
  }

  async handleFilter() {
    this.searchWord = null;
    await this.getRegionList();
    this.showFilter = true;
    this.populateFilterOptions();
    this.createFilterFormGroup();
  }

  filterSelectedHasValue() {
    let flag = 0;
    if (this.filterSelectedOptions) {
      if (this.filterSelectedOptions.branchName) {
        flag +=
          this.filterSelectedOptions.branchName !== undefined &&
          this.filterSelectedOptions.branchName !== ''
            ? 1
            : 0;
      }
      if (this.filterSelectedOptions.branchState) {
        flag +=
          this.filterSelectedOptions.branchState !== undefined &&
          this.filterSelectedOptions.branchState !== ''
            ? 1
            : 0;
      }
      if (this.filterSelectedOptions.status) {
        flag +=
          this.filterSelectedOptions.status !== undefined &&
          this.filterSelectedOptions.status !== ''
            ? 1
            : 0;
      }
    }
    if (flag > 0) {
      return true;
    } else {
      return false;
    }
  }

  populateFilterOptions() {
    if (this.filterSelectedHasValue()) {
      let defaultOptions = 0;
      let filteredOptions = this.filterInputOptions.map((option) => {
        const { value } = option;
        const selectedValue = this.filterSelectedOptions[value];

        if (selectedValue !== undefined && selectedValue !== '') {
          defaultOptions++;
          return { ...option, selectedValue, isDefault: true };
        }

        return { ...option, selectedValue, isDefault: false };
      });
      if (defaultOptions < 3) {
        filteredOptions = filteredOptions.map((option) => {
          if (defaultOptions < 3) {
            if (!option.isDefault) {
              defaultOptions++;
              return { ...option, isDefault: true };
            }
          }
          return { ...option };
        });
      }

      this.filterInputOptions = filteredOptions;
    } else {
      this.filterInputOptions = [
        {
          label: 'Name',
          value: 'branchName',
          type: 'text',
          disabled: false,
          isDefault: true,
          selectedValue: '',
          hasValidation: false,
          isRequired: false,
        },
        {
          label: 'State',
          value: 'branchState',
          type: 'dropdown',
          disabled: false,
          isDefault: true,
          selectedValue: '',
          dropdownOptions: this.stateList,
          isRequired: false,
          hasValidation: false,
        },
        {
          label: 'Status',
          value: 'status',
          type: 'dropdown',
          disabled: false,
          isDefault: true,
          selectedValue: '',
          dropdownOptions: [
            { id: 1, key: 'Active', value: 'Active' },
            { id: 2, key: 'Inactive', value: 'Inactive' },
          ],
          isRequired: false,
          hasValidation: false,
        },
      ];
    }
  }

  createFilterFormGroup() {
    const formGroup = {};
    this.filterInputOptions.forEach((control) => {
      const isRequired = control.hasValidation ? Validators.required : '';
      let selectedValue = control.selectedValue ? control.selectedValue : '';
      formGroup[control.value] = [selectedValue, isRequired];
    });
    this.filterFormGroup = this.fb.group(formGroup);
  }

  makeFilter(page = 1, limit = 10) {
    // const searchKey = this.sanitizeSearchInput(
    //   this.searchForm.value['searchKey'],
    // );
    if (this.searchKey && this.searchKey !== '') {
      this.mainHeading = 'Search result : ' + this.searchKey;
    } else {
      this.mainHeading = '';
    }
    const value = this.searchKey
      ? this.searchKey
      : this.filterSelectedOptions?.branchName
      ? this.filterSelectedOptions?.branchName
      : '';
    const status = this.filterSelectedOptions?.status ?? '';
    const regionId = this.filterSelectedOptions?.branchState ?? '';
    const filter = {
      brokerageId: this.brokerageId,
      regionId: regionId,
      name: value,
      active: status,
      page: page,
      limit: limit,
    };
    this.filters = filter;

    return filter;
  }

  async handleApplyFilter(event) {
    this.showFilter = false;
    this.filterSelectedOptions = {};
    this.filterSelectedOptions = event;
    this.searchForm.get('searchKey').setValue('');
    this.searchKey = '';
    this.loadBranches(1, 10);
  }

  async handleFilterClear(event) {
    this.filterSelectedOptions = {};
    this.filteredOptions = [];
    this.filterSelectedOptions = event;
    this.searchForm.get('searchKey').setValue('');
    this.searchKey = '';
    this.loadBranches(1, 10);
  }

  async onBranchSearch(event) {
    const searchKey = this.sanitizeSearchInput(
      this.searchForm.value['searchKey'],
    );
    const value = searchKey ?? event.target.value;
    const filter = { brokerageId: this.brokerageId, name: searchKey };
    this.filteredOptions = [];
    this.filterSelectedOptions = undefined;
    if (value) {
      if (event.key == 'Enter') {
        this.searchKey = this.sanitizeSearchInput(value);
        this.loadBranches(1, 10);
      }
    } else {
      this.searchForm.controls['searchKey'].setValue([]);
      this.showAddButton = true;
      this.mainHeading = '';
      this.searchKey = '';
      this.loadBranches(1, 10);
    }
  }

  getFilteredValues() {
    let filteredOptions = [];
    let filterText = '';

    if (this.filterSelectedOptions) {
      if (this.filterSelectedOptions.branchName) {
        filterText = this.filterSelectedOptions?.branchName;
        filteredOptions.push({ key: 'branchName', value: filterText });
      }
      if (
        typeof this.filterSelectedOptions.branchState !== 'undefined' &&
        this.filterSelectedOptions.branchState !== ''
      ) {
        filterText =
          this.stateList[this.filterSelectedOptions?.branchState - 1].key;
        filteredOptions.push({ key: 'branchState', value: filterText });
      }
      if (
        typeof this.filterSelectedOptions.status !== 'undefined' &&
        this.filterSelectedOptions.status !== ''
      ) {
        let status =
          this.filterSelectedOptions.status === 1 ? 'Active' : 'Inactive';
        filterText = status;
        filteredOptions.push({ key: 'status', value: filterText });
      }
      this.filteredOptions = filteredOptions;
    }
    return this.filteredOptions;
  }

  handleFilterOptnRemove(option) {
    if (this.filterSelectedOptions.hasOwnProperty(option)) {
      delete this.filterSelectedOptions[option];
    }
    this.loadBranches(this.pageNumber, 10);
    this.getFilteredValues();
  }

  sanitizeSearchInput(userInput: string): string {
    userInput = userInput ?? '';
    const specialCharacters = /[\^\$\\*+?.`/~()!=|[\]{}%#]/g;
    return userInput.replace(specialCharacters, '').replace(/^[ _]/, '');
  }

  private getFormatUrl(
    entity: string,
    id: number,
    brokerage: any,
    brokerageBranch?: any,
  ) {
    let url: string;
    url = `/dashboard/branch/view/${id}/${brokerage.id}`;
    return url;
  }

  branchClickHandler(index) {}

  pageChangeHandler(pageNumber) {
    this.pageNumber = pageNumber;
    this.loadBranches(pageNumber, 10);
  }

  handleTableColSort(e) {
    if (e.column === 'Status') {
      e.column = 'active';
    }
    if (e.column === 'Name') {
      e.column = 'name';
    }
    this.sortBy = e.column + ':' + e.sort;
    this.loadBranches(this.pageNumber, 10);
  }

  branchEditClickHandler(index) {
    const branchId = this.data[index].id;
    const brokerageId = this.data[index].brokerageId;
    const status = this.data[index].Status;
    this.isBrokerActive = status === 'Active' ? true : false;
    this.addBranchForm = false;
    this.isActive = status === 'Active' ? true : false;
    this.router.navigate(
      [`/dashboard/workflow3/brokerage/branch/producer/profile/${branchId}`],
      { relativeTo: this.activatedRoute, skipLocationChange: true },
    );
  }

  branchViewClickHandler(index) {
    const branchId = this.data[index].id;
    const brokerageId = this.data[index].brokerageId;
    this.editBranchForm = true;
    this.addBranchForm = false;
    this.isActive = false;
    this.router.navigate(
      [`/dashboard/workflow3/brokerage/branch/producer/profile/${branchId}`],
      { relativeTo: this.activatedRoute, skipLocationChange: true },
    );
  }

  get location() {
    return this.form.get('location') as FormGroup;
  }

  regionIdSelected(idregionSelected: number) {
    this.locationRegionIdSelected = idregionSelected;
  }

  handleAddBranchButton(event) {
    // if (!this.isBrokerActive) {
    //   this.editBranchForm = false;
    //   return;
    // }
    this.addBranchForm = true;
    this.editBranchForm = false;
  }

  handleDiscard(event) {
    this.addBranchForm = false;
    this.editBranchForm = false;
  }
  //Add Branch
  get branch() {
    return this.form.get('branch') as FormGroup;
  }

  handleSaveChanges(event) {
    //this.showBodySpinner = event.showSpinner;
    if (event.reloadData) {
      this.showTblSpinner = true;
      this.addBranchForm = false;
      this.showAddButton = true;
      this.loadBranches(this.pageNumber, 10);
    }
  }

  handleCloseFilterPopup(event) {
    this.showFilter = false;
  }
}
