import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { map, mergeMap, catchError, switchMap } from 'rxjs/operators';
import { RegionModel, RiskModel } from 'src/app/models/riskRegion.model';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import {
  addRiskSuccess,
  RETRIEVE_API_RISK,
  RETRIEVE_API_REGIONS_BY_RISK,
  addRegions,
} from './riskRegion.actions';

@Injectable()
export class RiskRegionEffects {
  constructor(
    private actions$: Actions,
    private riskRegionService: RiskRegionService,
    private store: Store,
  ) {}

  loadRisk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RETRIEVE_API_RISK),
      mergeMap(() =>
        this.riskRegionService.getAllRisk().pipe(
          map((item) => {
            return [...item.data].map((item: any) => {
              const risk: RiskModel = { id: item.id, name: item.name };
              return risk;
            });
          }),
          map((result) => addRiskSuccess({ items: result })),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  loadRegions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RETRIEVE_API_REGIONS_BY_RISK),
      switchMap((action: any) =>
        this.riskRegionService.getRiskRegion(action.riskId).pipe(
          map((item) => {
            return [...item.data].map((item: any) => {
              const region: RegionModel = {
                id: item.id,
                name: item.region.name,
                regionCode: item.region.regionCode,
              };
              return region;
            });
          }),
          map((result) => addRegions({ items: result })),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );
}
