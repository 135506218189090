import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { convertDate, getFormattedPeriod } from 'src/app/utils/formatDate';

@Component({
  selector: 'app-modal-logout',
  templateUrl: './modal-logout.component.html',
  styleUrls: ['./modal-logout.component.less'],
})
export class ModalLogoutComponent {
  @Input() showModal: boolean = false;

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleCancel = new EventEmitter<any>();
  @Output() handleLogout = new EventEmitter<any>();

  constructor(private store: Store) {}

  closeModal() {
    this.handleClose.emit();
  }
  handleLogoutClick() {
    this.handleLogout.emit();
  }
  handleCancelClick() {
    this.handleCancel.emit();
  }
}
