import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MapService } from 'src/app/services/map.service';
import { Store, select } from '@ngrx/store';
import { Subject, firstValueFrom, merge, take, takeUntil } from 'rxjs';
import { CANADA, UNITED_STATES } from 'src/app/constants/location-constant';
import { DomainsService } from 'src/app/services/domains.service';
import { LanguageService } from 'src/app/services/language.service';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { LocationService } from 'src/app/services/location.service';
import { UiContentService } from 'src/app/services/uiContent.service';
import {
  getCountrySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import { arrayToObjet, postalCodeValidator } from 'src/app/utils/utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-location-details-form',
  templateUrl: './location-details-form.component.html',
  styleUrls: ['./location-details-form.component.less'],
})
export class LocationDetailsFormComponent
  implements OnInit, OnDestroy, OnChanges
{
  @ViewChild('searchAddress') searchElementRef!: ElementRef;

  private unsubscribe: Subject<void> = new Subject();

  @Input() form;
  @Input() requiredErrMsg;
  @Input() formSubmitted;
  @Input() defaultValueObj;
  @Input() isActive: boolean = true;
  @Input() allowLoader: boolean = true;
  listRegions: any[] = [];
  regionTotalRecords = 0;
  regionTotalPages = 0;
  countrySelected = '';
  address: string;
  isLoaded: boolean = false;
  @Output() eventEmitRegionIdSelected? = new EventEmitter<number>();
  @Output() eventEmitLoadedData? = new EventEmitter<boolean>();
  @Output() handleSelectedRegionCode? = new EventEmitter<any>();

  provinceOrStateList = [];
  userID: number;
  province: string;
  zip: string;
  country: string = CANADA;
  mask: string = 'S0S 0S0';
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  locations: any;
  regionId: number;

  debounceTimer: any;

  constructor(
    private mapService: MapService,
    private locationService: LocationService,
    private uiContentService: UiContentService,
    private domainsService: DomainsService,
    private localStorageService: LocalStorageService,
    private languageService: LanguageService,
    private store: Store,
    private translateService: TranslateService,
  ) {}

  getPlaces(query: string) {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      this.getPlacesApiHandler(query);
    }, 500);
  }

  getPlacesApiHandler(query: string) {
    this.mapService.getPlaces(query).subscribe((response: any) => {
      if (response?.data && response?.data instanceof Array) {
        this.locations = response?.data?.map((obj) => {
          const { place_id, ...rest } = obj;
          return { ...rest, id: place_id };
        });
      }
    });
  }

  selectPlaceById(placeId: string) {
    this.mapService.getPlaceById(placeId).subscribe((response: any) => {
      if (response.data.target === 'address') {
        const addressArray = response.data.address.split(',');
        const extractedAddress = addressArray[0].trim();
        this.form.get('address').setValue(extractedAddress);
        this.form.get('city').setValue(response.data.city);
        this.form.get('zipPostalCode').setValue(response.data.postalCode);
        this.form.get('country').setValue(response.data.country);
        this.form.get('province').setValue(response.data.province);
        this.regionId = response.data.regionId;
        this.handleSelectedRegionCode.emit(response?.data?.regionCode);
        this.eventEmitRegionIdSelected.emit(this.regionId);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
    });
    this.isLoaded = false;
    window.document.body.style.overflow = 'hidden';
    this.eventEmitLoadedData.emit(this.isLoaded);
    // Update the validation for the postal code control based on the selected country
    this.store
      .select(getCountrySelector)
      .pipe(take(1))
      .subscribe((country) => {
        this.country = country?.toUpperCase();
        if (this.country === UNITED_STATES) {
          this.mask = '00000||00000-0000';
        }
        this.updatePostalCodeValidation(this.country);
      });
    this.userID = this.localStorageService.getBoxxUserId();
    this.getRegionList();
    this.domainsService.GetByDomainCode('INSTANCECONFIG').subscribe((data) => {
      if (data.data.length > 0) {
        const tableData = data.data.map((dataObj) => ({
          key: dataObj.description,
          value: dataObj.description,
        }));
      }
    });

    this.languageService.getLanguageId().subscribe((data) => {
      const languageId = data.data[0]?.id;
      this.uiContentService
        .GetByLangAdnKey(languageId, 'LOCATIONPROVSTATE')
        .subscribe((data) => {
          this.province = data.data[0].value;
          this.uiContentService
            .GetByLangAdnKey(languageId, 'LOCATIONZIPPOSTAL')
            .subscribe((data) => {
              this.zip = data.data[0].value;
              this.isLoaded = true;
              window.document.body.style.overflow = 'unset';
              this.eventEmitLoadedData.emit(this.isLoaded);
            });
        });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.formSubmitted) {
      this.setMaxLengthErrorMessage('addressLine2');
      this.form.get('addressLine2')?.valueChanges.subscribe(() => {
        this.setMaxLengthErrorMessage('addressLine2');
      });
    }
  }

  getRegionList(page = 1, isLoadMore = false) {
    this.locationService.getAllRegions(page).subscribe((region) => {
      const regions = region.data.map((item: any) => {
        const data = { id: item.id, name: item.name, code: item.regionCode };
        return data;
      });
      const stateList = regions.map((item: any) => {
        return { key: item.name, value: item.name };
      });
      if (isLoadMore) {
        this.listRegions = [...this.listRegions, ...regions];
        this.provinceOrStateList = [...this.provinceOrStateList, ...stateList];
      } else {
        this.listRegions = regions;
        this.provinceOrStateList = stateList;
      }
      this.regionTotalRecords = region.pagination.totalRecords;
      this.regionTotalPages = region.pagination.totalPages;
    });
  }
  loadMoreRegion(page) {
    if (this.regionTotalRecords > this.listRegions.length) {
      this.getRegionList(page, true);
    }
  }
  async getUiContentValue(key) {
    try {
      const getLanguage = this.languageService.getLanguageId();
      let langData = await firstValueFrom(getLanguage);
      const languageId = langData.data[0]?.id;

      let getUiContent = this.uiContentService.GetByLangAdnKey(languageId, key);
      let getUiContentData = await firstValueFrom(getUiContent);
      return getUiContentData.data[0].value;
    } catch (error) {
      this.isLoaded = true;
    }
  }

  getProvinces(form) {
    const country = this.form?.value?.country || '';
    const provinceOrStateLst = this.provinceOrStateList[country] || [];
    return provinceOrStateLst;
  }

  handleCountryChange(value) {
    this.form.controls['country'].setValue(value);
  }

  handleProvinceChange(provinceName) {
    this.form.controls['province'].setValue(provinceName);
    const selectedRegion = this.listRegions.find(
      (item) => item.name === provinceName,
    );
    const idSelectedRegion = selectedRegion?.id;
    this.handleSelectedRegionCode.emit(selectedRegion?.code);
    this.eventEmitRegionIdSelected.emit(idSelectedRegion);
  }

  private updatePostalCodeValidation(country: string) {
    const zipPostalCodeControl = this.form.get('zipPostalCode');

    // Add new validator based on the selected country
    if (
      country?.toUpperCase() === UNITED_STATES ||
      country?.toUpperCase() === CANADA
    ) {
      // Clear previous validators for the postal code control
      zipPostalCodeControl.clearValidators();
      zipPostalCodeControl.updateValueAndValidity();

      zipPostalCodeControl.setValidators([
        Validators.required,
        postalCodeValidator(country), // Custom validator for US postal code
      ]);
    }

    // Update the postal code control's validation status
    zipPostalCodeControl.updateValueAndValidity();
  }

  setMaxLengthErrorMessage(fieldName: string) {
    if (
      this.form?.controls[fieldName]?.errors?.maxlength?.requiredLength ??
      0
    ) {
      const charLimit =
        this.form?.controls[fieldName]?.errors['maxlength']?.requiredLength ??
        0;
      const errMsg =
        charLimit !== 0
          ? this.translateService
              .instant('error.maximumAllowedCharErroMsg')
              ?.replace('{charLimit}', charLimit)
          : this.translateService.instant(
              'error.maximumAllowedCharDefaultErroMsg',
            );
      this.form?.controls[fieldName]?.setErrors({
        message: errMsg,
      });
    }
  }
}
