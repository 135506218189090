export enum QuoteStatus {
  Submission = 'Submission',
  Quoted = 'Quoted',
  RenewalApp = 'RenewalApp',
  RenewalQuoted = 'RenewalQuoted',
  Declined = 'Declined',
  Closed = 'Closed',
  NotTakenUp = 'Not Taken Up',
  Referral = 'Referral',
  Policy = 'Bound',
  Indicated = 'Indicated',
}

export const QuoteMapping = {
  all: undefined,
  open: QuoteStatus.Submission,
  quoted: QuoteStatus.Quoted,
  declined: QuoteStatus.Declined,
  closed: QuoteStatus.Closed,
  submission: QuoteStatus.Submission,
  referral: QuoteStatus.Referral,
  renewalapp: QuoteStatus.RenewalApp,
  renewalquoted: QuoteStatus.RenewalQuoted,
  'not taken up': QuoteStatus.NotTakenUp,
  'ua decline': 'ua decline',
  indicated: QuoteStatus.Indicated,
};

export const mapQuote = (value: string): string => {
  return QuoteMapping[value.toLowerCase()];
};

//Define  if current status included in this disabled statuses,  then block the fields and buttons editable,

export const DisabledStatuses: Set<QuoteStatus> = new Set([
  QuoteStatus.NotTakenUp,
  QuoteStatus.Declined,
  QuoteStatus.Closed,
  // Add more statuses as needed
]);

export const isStatusDisabled = (status): boolean => {
  return DisabledStatuses.has(status);
};

export enum EntryType {
  Policy = 'policy',
  Quote = 'quote',
}
